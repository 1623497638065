import request from '@/utils/request'
import store from '@/store'

/**
 * keyword 搜索关键字/手机号/用户名
 */
export function userSearch(data) {
  return request({
    url:  '/company/employee/list',
    method: 'POST',
    data
  })
}


/**
 * 获取 群目录树
 */
export function userWorkGroupTree(data) {
  return request({
    url:  '/user/workGroup/tree',
    method: 'POST',
    data
  })
}
/**
 * 新建目录
 * title=目录名称(必填)&parentId=父目录ID(选填,添加子目录时必填)&id目录ID(选填,编辑时必填)
 */
export function userWorkGroupSave(data) {
  return request({
    url:  '/user/workGroup/save',
    method: 'POST',
    data
  })
}
/**
 * 目录和目录下资源的移动（移入和移出）
 *id=当前目录或资源id(必填)&parentId=父目录ID(必填,最外层为0)
 */
export function userWorkGroupMove(data) {
  return request({
    url:  '/user/workGroup/move',
    method: 'POST',
    data
  })
}
/**
 * 目录和目录下的资源移动（移入 移出）(
 *?recordType=协同类型(必填)"&recordId=协同记录ID(必填)&groupId=目标分组目录ID(必填)
 */
export function userWorkGroupMoveByRecordId(data) {
  return request({
    url:  '/user/workGroup/moveByRecordId',
    method: 'POST',
    data
  })
}
/**
 删除分组目录和目录下的工作群(其实就是移出，因为删除后会跑到顶层目录下)：(ok)
 id=目录id值(必填)
 */
export function userWorkGroupDelete(data) {
  return request({
    url:  '/user/workGroup/delete',
    method: 'POST',
    data
  })
}
/**
 * \重命名：(ok)
 title=新目录名称(必填)&id=目录ID(必填)
 */
export function userWorkGroupRename(data) {
  return request({
    url:  '/user/workGroup/rename',
    method: 'POST',
    data
  })
}
/**
 *排序，只针对 目录，因为工作群是按照 更新时间来排序的。(
 id=当前目录id(必填)&preId=前一目录id值(必填,如果是排在第一位那么为0)
 */
export function userWorkGroupSort(data) {
  return request({
    url:  '/user/workGroup/sort',
    method: 'POST',
    data
  })
}

/**
 * 根据recordId和recordType获取树数据节点
 *recordId=&recordType= 返回 model对象，返回的对象内容跟
 * @param data
 * @returns {*}
 */
export function userWorkGroupInfo(data) {
  return request({
    url:  '/user/workGroup/info',
    method: 'POST',
    data
  })
}


//*******************单位文件夹*********************
/**
 /app/workDir/tree?companyId=单位id值(选填)&query=json串,传入 {fld1:v1,fld2:v2}(选填)
 * @param data
 * @returns {*}
 */
export function workDirTree(data) {
  return request({
    url:  '/app/workDir/tree',
    method: 'POST',
    data:{
      ...data,
      companyId:store.getters.currentCompanyId
    }
  })
}
/**
 5，新建单位文件夹接口，由单位管理员新建，仅可以建在单位文件夹下 (ok)
 /app/workDir/register?title=文件夹名称(必填)&parentId=父文件夹ID,顶级就为空&type=文件夹类型,类型 10:公有,即单位的 20:私有,即普通的(必填，这里传10)&companyId=选择的单位ID
 6，新建个人文件夹接口，人人都可以建，但是不能建在任何单位文件夹下 (ok)
 /app/workDir/register?title=文件夹名称(必填)&parentId=父文件夹ID,顶级就为空&type=文件夹类型,类型 10:公有,即单位的 20:私有,即普通的(必填，这里传20)&companyId=选择的单位ID
 */
export function workDirRegister(data) {
  return request({
    url:  '/app/workDir/register',
    method: 'POST',
    data:{
      ...data,
      companyId:store.getters.currentCompanyId
    }
  })
}
/**
 删除文件夹接口，单位文件夹由单位管理员操作，个人文件夹由个人操作，删除后，文件夹下的内容移动到上一级文件夹下 (ok)
 dirId
 */
export function workDirDelete(data) {
  return request({
    url:  '/app/workDir/delete',
    method: 'POST',
    data
  })
}
/**
 重命名文件夹接口
 dirId
 */
export function workDirRename(data) {
  return request({
    url:  '/app/workDir/rename',
    method: 'POST',
    data
  })
}
/**
 移动资源到某个文件下(ok)
 ?recordType=协同类型(必填)&recordId=协同记录ID(必填)&dirId=文件夹id值(顶级0)(必填)
 */
export function workDirMoveResourceByRecordId(data) {
  return request({
    url:  '/app/workDir/moveResByRecordId',
    method: 'POST',
    data
  })
}
/**
 移动资源到某个文件下(ok)
 resId=资源id值(必填)&dirId=文件夹id值(顶级0)(必填)
 */
export function workDirMoveResource(data) {
  return request({
    url:  '/app/workDir/moveRes',
    method: 'POST',
    data
  })
}
/**
 移动文件夹接口，单位文件夹之间相互移动，个人文件夹之间相互移动，但是不能 单位文件夹和个人文件夹之间移动 (ok)
 ?dirId=文件夹id值(必填)&parentId=父文件夹id值,顶级就是0(必填)
 */
export function workDirMove(data) {
  return request({
    url:  '/app/workDir/move',
    method: 'POST',
    data
  })
}
/**
 * 文件夹排序
 /app/workDir/sort?dirId=文件夹id值(必填)&preDirId=前一文件夹id值&parentId=父文件夹id值
 */
export function workDirSort(data) {
  return request({
    url:  '/app/workDir/sort',
    method: 'POST',
    data
  })
}
/**
 * 资源排序
 /app/workDir/sort?dirId=文件夹id值(必填)&preDirId=前一文件夹id值&parentId=父文件夹id值
 */
export function workDirSortRes(data) {
  return request({
    url:  '/app/workDir/sortRes',
    method: 'POST',
    data
  })
}
/**
 * 获取文件夹下资源详情接口 (ok)
 ?recordType=协同类型(必填)&recordId=协同ID(必填)&companyId=选择的单位ID
 返回 model 对象
 */
export function workDirResourceInfo(data) {
  return request({
    url:  '/app/workDir/resInfo',
    method: 'POST',
    data
  })
}
/**
 *?title=新资源名称(必填)&recordType=协同类型(必填)&recordId=协同记录ID(必填)
 */
export function workDirRenameResource(data) {
  return request({
    url:  '/app/workDir/renameRes',
    method: 'POST',
    data
  })
}
/**
 * 私有群转化为公司群
 *??resId=资源id值(必填)&companyId=选择的单位ID(必填)&dirId=目标文件夹id值(根目录为0)
 */
export function workDirTransferResTo(data) {
  return request({
    url:  '/app/workDir/transferResTo',
    method: 'POST',
    data
  })
}

/**
 * 保存是否隐藏单位文件夹设置
 * @param data
 * @returns {*}
 */
export function workDirSaveFolderSetting(data){
  return request({
    url:   '/app/workDir/saveSetting',
    method: 'POST',
    data
  })
}

/**
 * 读取是否隐藏单位文件夹设置
 * @param data
 * @returns {*}
 */
export function workDirLoadFolderSetting(data){
  return request({
    url:   '/app/workDir/loadSetting',
    method: 'POST',
    data
  })
}
