<template>
  <div>
    <el-popover
      popper-class="grid-pop"
      placement="bottom"
      width="200"
      trigger="hover"
      v-model="visible"
    >
      <div class="ppop-scrollp">
        <div
          @click="checkStyle(val)"
          class="line-item"
          v-for="val in styleArray"
          :key="val"
        >
          <div class="border" :style="`border-style:${val}`"></div>
        </div>
      </div>
      <div slot="reference" class="form-item">
        <div class="border" :style="`border-style:${value}`"></div>
      </div>
    </el-popover>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default() {
        return "solid";
      },
    },
  },
  data() {
    return {
      visible:false,
      styleArray: [
        "solid",
        "dashed",
        "dotted",
        "double",
        "groove",
        "ridge",
        "inset",
        "outset",
      ],
    };
  },
  methods: {
    checkStyle(val) {
      this.$emit("input", val);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.border {
  border-width: 1px 0 0 0;
  border-color: #333;
}
.pop-scroll {
  max-height: 320px;
  overflow: auto;
  .empty-text {
    font-size: 12px;
    text-align: center;
    line-height: 28px;
  }
}
.form-item {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 12px 10px;
  box-sizing: border-box;
}
.line-item {
  padding: 10px 5px;
  box-sizing: border-box;
  border: solid 1px transparent;
  border-radius: 4px;
  &:hover {
    border: solid 1px #409eff;
  }
}
</style>
<style lang="scss">
.grid-pop {
  &.el-popover {
    padding: 2px !important;
  }
}
</style>