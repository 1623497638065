<template>
  <el-dialog
    title="节点名称和说明"
    :visible.sync="visible"
    width="500px"
    :append-to-body="true"
    destroy-on-close
    @closed="closed"
  >
    <el-form
      v-if="visible"
      ref="form"
      :model="form"
      style="padding: 20px"
      size="small"
    >
      <el-form-item
        label="名称"
        prop="title"
        :rules="[{ required: true, message: '请输入名称' }]"
      >
        <el-input v-model="form.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="说明" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注说明"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    node: Object,
  },
  data() {
    return {
      visible: false,
      form: null,
    };
  },
  methods: {
    closed() {
      this.form = null;
    },
    open() {
      this.form = {
        title: this.node.title,
        remark: this.node.remark,
      };
      this.visible = true;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$set(this.node, "title", this.form.title);
        this.$set(this.node, "remark", this.form.remark);
        this.visible = false;
      });
    },
  },
};
</script>
