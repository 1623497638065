<template>
  <el-drawer
    :visible.sync="drawer"
    :with-header="false"
    :append-to-body="true"
    :destroy-on-close="true"
    :size="size"
    :modal="false"
    custom-class="rpa-drawer"
    @open="open"
    @opened="opened"
    @closed="closed"
  >
    <div class="rpa-dw-header bg_blue">
      <div style="flex: 1; margin-left: 10px">
        <div class="node-title">选择一个动作</div>
      </div>
      <i @click="drawer = false" class="el-icon-close pointer font-24"></i>
    </div>
    <div class="rpa-scroll">
      <div class="rpa-body">
        <div class="rpa-action-item" v-for="item in actions" :key="item.label">
          <div class="font-title" v-text="item.label"></div>
          <!-- 新增记录、更新记录、删除记录、获取单条数据、获取多条数据 -->
          <ul class="ul-row">
            <li
              @click="addNode(opt)"
              class="li-item"
              v-for="opt in item.children"
              :key="opt.value"
            >
              <i class="li-icon" :class="opt.bg + ' ' + opt.icon"></i>
              <div class="li-text" v-text="opt.label"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog
      title="分支下方的节点整体放置在"
      :visible.sync="branchVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      @closed="closedBranch"
    >
      <div style="padding: 20px">
        <el-radio-group v-if="!flowSecondStep" v-model="flowRule">
          <div style="margin-bottom: 8px">
            <el-radio label="condition_all">添加普通分支</el-radio>
            <div
              style="
                font-size: 13px;
                color: #757575;
                margin-left: 25px;
                line-height: 1.5;
              "
            >
              对查找到的数据进行分支处理。未查找到数据时，流程中止
            </div>
          </div>
          <div>
            <el-radio label="query_result">添加查找结果分支</el-radio>
            <div
              style="
                font-size: 13px;
                color: #757575;
                margin-left: 25px;
                line-height: 1.5;
              "
            >
              分支固定为“查找到数据”和“未查找到数据”。如果你需要在“未查找到”数据的情况下继续执行流程，请选择此分支
            </div>
          </div>
        </el-radio-group>
        <el-radio-group v-else v-model="position">
          <div style="margin-bottom: 8px">
            <el-radio label="left"
              >左侧{{
                flowRule == "query_result" ? "（有数据分支）" : ""
              }}</el-radio
            >
          </div>
          <div v-if="flowRule == 'query_result'" style="margin-bottom: 8px">
            <el-radio label="right">右侧（无数据分支）</el-radio>
          </div>
          <div>
            <el-radio label="bottom">不移动</el-radio>
            <div
              style="
                font-size: 13px;
                color: #757575;
                margin-left: 25px;
                line-height: 1.5;
              "
            >
              等待分支汇集后再执行下方节点
            </div>
          </div>
        </el-radio-group>
      </div>
      <div slot="footer">
        <el-button @click="branchVisible = false">取 消</el-button>
        <el-button
          v-if="!flowSecondStep"
          type="primary"
          @click="flowSecond = true"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="saveBranch">确 定</el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>
<script>
import { uuid } from "@zgg-core-utils/utils";
import { nodeTypeList } from "./loopTypeEnums";
export default {
  props: {
    visible: Boolean,
    node: Object,
    addBrach: Function,
    add: Function,
    zIndex: Number,
  },
  computed: {
    size() {
      if (document.documentElement.clientWidth >= 640) {
        return 640;
      } else if (document.documentElement.clientWidth < 300) {
        return 300;
      } else {
        return document.documentElement.clientWidth;
      }
    },
    drawer: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    flowSecondStep() {
      if (this.node.type != "query_data_one") {
        return true;
      }
      return this.flowSecond;
    },
  },
  inject: {
    getTrigger: {
      default: () => {
        return null;
      },
    },
    getToNodes: {
      default: () => {
        return [];
      },
    },
    getFromNodes: {
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      position: "left",
      branchVisible: false,
      actions: [],

      isEditTitle: false,
      title: "",
      flowRule: "condition_all",
      flowSecond: false,
      form: {},
    };
  },
  methods: {
    open() {
      let actions = [
        {
          label: "数据处理",
          children: nodeTypeList.filter((item) => item.type == "data"),
        },
        {
          label: "通知",
          children: nodeTypeList.filter((item) => item.type == "notice"),
        },
        {
          label: "构建",

          children: nodeTypeList.filter((item) => item.type == "part"),
        },
        {
          label: "开发者",
          children: nodeTypeList.filter((item) => item.type == "developer"),
        },
        {
          label: "待办",
          children: nodeTypeList.filter((item) => item.type == "agenda"),
        },
      ];
      if (
        typeof this.getTrigger === "function" &&
        typeof this.getToNodes === "function"
      ) {
        let trigger = this.getTrigger();
        let list = this.getToNodes();
        if (trigger.type == "trigger_click" && list.length == 0) {
          actions.splice(2, 0, {
            label: "渲染",
            children: nodeTypeList.filter((item) => item.type == "render"),
          });
        }
      }
      this.actions = actions;
    },
    opened() {
      let $dom = document.getElementById("copyAction");
      if ($dom) {
        let { width, left } = $dom.getBoundingClientRect();
        if (document.documentElement.clientWidth - left - width > this.size) {
          let zIndex = this.$el.style.zIndex;
          zIndex = parseInt(zIndex, 10) + 1;
          this.$emit("update:zIndex", zIndex);
        }
      }
    },
    closed() {
      this.$emit("update:zIndex", 1000);
    },
    closedBranch() {
      this.flowSecond = false;
      this.position = "left";
      this.flowRule = "condition_all";
    },
    saveBranch() {
      // 保存分支
      let obj = {
        key: uuid(),
        type: "branch",
        title: "分支",
        flows: [],
        flowRule: this.flowRule,
      };

      let list = [
        {
          nodes: [
            {
              key: uuid(),
              type: "condition",
              title: "分支",
              condition: { advanceQuery: [], rel: "and" },
            },
          ],
          lines: [],
        },
        {
          nodes: [
            {
              key: uuid(),
              type: "condition",
              title: "分支",
              condition: { advanceQuery: [], rel: "and" },
            },
          ],
          lines: [],
        },
      ];
      if (this.flowRule == "query_result") {
        list[0].nodes[0].queryDataExist = true;
        list[1].nodes[0].queryDataExist = false;
      }
      obj.flows = list;
      this.addBrach(obj, this.node.key, this.position);
      this.branchVisible = false;
      this.drawer = false;
    },
    addNode(opt) {
      /**
       * @type Array
       */
      let toNodes = this.getToNodes();

      let node = toNodes.find((item) =>
        ["render_notice", "render_form", "render_url"].includes(item.type),
      );
      if (node && ["subflow_collection"].includes(opt.value)) {
        let title = nodeTypeList.find((item) => item.value == node.type)?.label;
        this.$message.error(
          `节点中存在【${title}】渲染类型节点，不能添加【${opt.label}】`,
        );
        return;
      }

      /**
       * @type Array
       */
      let fromNodes = this._.cloneDeep(this.getFromNodes());
      fromNodes.push(this._.cloneDeep(this.node));

      let node2 = fromNodes.find((item) => item.type == "subflow_collection");

      if (
        ["render_notice", "render_form", "render_url"].includes(opt.value) &&
        node2
      ) {
        let title = nodeTypeList.find(
          (item) => item.value == node2.type,
        )?.label;
        this.$message.error(
          `节点中存在【${title}】，不能添加【${opt.label}】渲染类型节点`,
        );
        return;
      }

      if (opt.value == "branch") {
        // 分支
        this.flowSecond = false;
        this.position = "left";
        this.flowRule = "condition_all";
        this.branchVisible = true;
      } else {
        let obj = { key: uuid(), type: opt.value, title: opt.label };
        if (opt.value == "action_form_data_create") {
          // 新增记录
          obj = {
            ...obj,
            formId: "",
            formTitle: "",
            createFieldValues: [],
            nodeKey: "",
          };
        } else if (opt.value == "action_form_data_update") {
          // 更新记录
          obj = {
            ...obj,
            formId: "",
            formTitle: "",
            limit: 100,
            dataFilter: {
              rel: "and",
              advanceQuery: [],
            },
            updateFieldValues: [],
            nodeKey: "",
          };
        } else if (opt.value == "action_form_data_delete") {
          // 删除记录
          obj = {
            ...obj,
            formId: "",
            formTitle: "",
            dataFilter: {
              rel: "and",
              advanceQuery: [],
            },
          };
        } else if (opt.value == "action_form_data_save") {
          // 新增或修改记录
          obj = {
            ...obj,
            formId: "",
            formTitle: "",
            dataFilter: {
              rel: "and",
              advanceQuery: [],
            },
            updateFieldValues: [],
            createFieldValues: [],
            nodeKey: "",
          };
        } else if (opt.value == "query_data_one") {
          // 获取单条数据
          obj = {
            ...obj,
            dataSource: "form",
            collection: "",
            formTitle: "",
            fetchNodeDataFailWay: "keep",
            dataFilter: {
              rel: "and",
              advanceQuery: [],
            },
            nodeKey: "",
            nodeField: null,
          };
        } else if (opt.value == "query_data_list") {
          // 获取多条数据
          obj = {
            ...obj,
            dataSource: "form",
            collection: "",
            formTitle: "",
            fetchNodeDataWay: "nodeTimeData",
            fetchNodeDataFailWay: "keep",
            dataFilter: {
              rel: "and",
              advanceQuery: [],
            },
            limitSize: 100,
            nodeKey: "",
            nodeField: null,
          };
        } else if (opt.value == "notice_sms") {
          // 发送短信通知
          obj = {
            ...obj,
            userRange: {
              userIds: [],
              nodeUserFields: [],
              userInfoList: [],
            },
            msgNodeFields: [],
            smsPlatForm: "aliyun",
            smsTemplateId: "",
          };
        } else if (opt.value == "notice_wechat") {
          // 发送公众号通知
          obj = {
            ...obj,
            userRange: {
              userIds: [],
              nodeUserFields: [],
            },
            msgTemplate: "",
            miniProgramNodeKey: "",
            msgTemplate2: "",
            msgTemplate3: "",
          };
        } else if (opt.value == "developer_plugin") {
          // 插件
          obj = {
            ...obj,
            pluginInstallId: "",
            pluginTitle: "",
            functionId: "",
            requestParams: [],
            responseParams: [],
          };
        } else if (opt.value == "action_form_data_commit") {
          // 流程审批
          obj = {
            ...obj,
            commitNodeKey: "",
            commitUser: {
              userIds: [],
              nodeUserFields: [],
              userInfoList: [],
            },
            companyWorkflowId: "",
            companyWorkflowTitle: "",
          };
        } else if (opt.value == "subflow_collection") {
          // 子流程
          obj = {
            ...obj,
            baseNodeKey: "",
            robotId: "",
            collection: "",
            collectionTitle: "",
            triggerOneTimes: true,
            syncExec: false,
            executeType: "",
            flowParamValues: [],
          };
        } else if (opt.value == "flow_param_update") {
          // obj = {
          //   ...obj,
          //   flowParamValues: [],
          // };
        } else if (opt.value == "render_notice") {
          // 通知
          obj = {
            ...obj,
            renderNoticeType: "tip",
            tipType: "success",
            noticeTitle: "",
            noticeContent: "",
            nodeFields: [],
            duration: 0,
          };
        } else if (opt.value == "render_url") {
          obj = {
            ...obj,
            url: "",
            nodeFields: [],
            displayMode: "popup",
          };
        } else if (opt.value == "render_form") {
          obj = {
            ...obj,
            formPageType: "detail",
            formId: "",
            formTitle: "",
            formDataNodeKey: "",
          };
        } else if (opt.value == "json_parser") {
          obj = {
            ...obj,
            jsonDataNodeKey: "",
            outputFields: [],
            failConfig: {
              failMsg: "",
              failMsgNodeFields: [],
              failWay: "keep",
              failCondition: {
                rel: "and",
                advanceQuery: [],
              },
            },
          };
        } else if (opt.value == "http_request") {
          obj = {
            ...obj,
            method: "GET",
            url: "",
            urlNodeFields: [],
            header: [],
            responseDataFormat: "json",
            responseExample: "",
            responseRemark: "",
            failConfig: {
              successCodes: [],
              failCodeMsg: {},
              defaultFailMsg: "",
              failWay: "keep",
            },
          };
        }
        this.add(obj, this.node.key);
        this.drawer = false;
      }
    },
  },
};
</script>
<style lang="scss">
.rpa-drawer {
  .el-drawer__body {
    display: flex;
    flex-direction: column;
  }
}
</style>
<style lang="scss" scoped>
.rpa-scroll {
  flex: 1;
  overflow: auto;
}
.rpa-body {
  padding: 20px 24px;
  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
      &.is-error {
        .rpa-checkbox-group {
          .el-checkbox-button__inner {
            border-color: #f56c6c !important;
          }
        }
      }
    }
    .el-form-item__label {
      padding: 0px;
      line-height: 30px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
.bg_yellow {
  background-color: #ffa340;
}
.bg_blue {
  background-color: #2196f3;
}
.bg_gray {
  background-color: #4c7d9e;
}
.node-title {
  padding: 8px;
  // &:hover {
  //   background-color: rgba($color: #ffffff, $alpha: 0.1);
  // }
}
.rpa-dw-header {
  display: flex;
  align-items: center;
  color: #fff;
  height: 55px;
  padding: 0 20px;
}
.font-24 {
  font-size: 24px;
}
.pointer {
  cursor: pointer;
}
.trigger-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height: 150px;
  justify-content: center;
}
.rpa-radius {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 68px;
  width: 68px;
  justify-content: center;
  pointer-events: none;
  border-radius: 68px;
  box-sizing: border-box;
}
.drawer-btns {
  padding: 10px;
  background-color: #f4f5f7;
}
.font-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.rpa-action-item {
  margin-bottom: 20px;
  .ul-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 20px;
    padding: 0;
    .li-item {
      width: 50%;
      display: flex;
      align-items: center;
      margin: 0 0 5px;
      padding: 10px 5px;
      cursor: pointer;
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
      .li-text {
        font-size: 14px;
      }
    }
  }
}
.li-icon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  font-size: 18px;
  color: #ffffff;
}
</style>
