var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":_vm.click}},[_vm._t("reference")],2),_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"600px","custom-class":"dialog-field","append-to-body":true},on:{"open":_vm.open,"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticStyle:{"padding":"10px 30px","max-height":"400px","overflow":"auto"}},[(_vm.componentList.length == 0)?_c('div',[_vm._v("没有可用字段")]):_vm._e(),_vm._l((_vm.componentList),function(item){return _c('div',{key:item.key},[_c('div',{staticClass:"form-item",on:{"click":function($event){return _vm.chkItem(item)}}},[_c('div',{staticClass:"item-title",domProps:{"textContent":_vm._s(item.title)}}),(
              !(
                _vm.componentName == 'summary_data' &&
                item.componentName == 'json_form'
              )
            )?_c('el-checkbox',{attrs:{"value":_vm.isChk(item),"indeterminate":_vm.isIndeter(item),"disabled":item.disabled},on:{"change":function($event){return _vm.changeChk($event, item)}}}):_vm._e()],1),(
            (_vm.includeJsonForm && item.componentName == 'json_form') ||
            item.componentName == 'reference_data'
          )?_vm._l((item.components),function(sub){return _c('div',{key:sub.key,staticClass:"form-item",staticStyle:{"padding-left":"22px"}},[_c('div',{staticClass:"item-title",domProps:{"textContent":_vm._s(sub.title)},on:{"click":function($event){return _vm.chkItem(item, sub)}}}),_c('el-checkbox',{attrs:{"value":_vm.isChk(item, sub),"disabled":sub.disabled},on:{"change":function($event){return _vm.changeChk($event, item, sub)}}})],1)}):_vm._e()],2)})],2),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.save}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }