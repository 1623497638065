<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 68px;
    "
  >
    <div class="label">跨单位复制</div>
    <div class="description">将本单位的功能复制到其他单位</div>
    <div class="content" style="display: flex; align-items: center">
      <div style="height: 44px; line-height: 44px">复制到其他单位</div>
      <el-button
        plain
        style="margin-left: 15px"
        @click="editConfig"
        :loading="loading"
        >复制</el-button
      >
    </div>
    <div v-if="dialogFormVisible" class="dialog-copy-form">
      <el-dialog
        title="复制到"
        :visible.sync="dialogFormVisible"
        append-to-body
      >
        <company-select-list
          v-if="dialogFormVisible"
          :currentCompanyId="this.companyId"
          ref="companySelectList"
        ></company-select-list>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelCopy">取 消</el-button>
          <el-button type="primary" @click="copyData">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog
      title="检查错误，请修改后再复制"
      :visible.sync="dialogErrorVisible"
      width="30%"
      append-to-body
    >
      <div class="error-body">
        <div v-for="(item, index) in errorInfoList" :key="index">
          {{ item }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogErrorVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { checkError, duplicateCompanyProject } from "@/api/operationCenter";
import Pagination from "@/components/Pagination/index.vue";
import CompanySelectList from "@/components/companySelectList.vue";

export default {
  components: { Pagination, CompanySelectList },
  computed: {
    companyName() {
      return this.$store.getters.currentCompanyName;
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
  },
  data() {
    return {
      errorInfoList: [],
      loading: false,
      dialogFormVisible: false,
      dialogErrorVisible: false,
    };
  },
  created() {},
  methods: {
    cancelCopy() {
      this.dialogFormVisible = false;
      if (
        this.$refs.companySelectList &&
        this.$refs.companySelectList.checkedCompany
      ) {
        this.$refs.companySelectList.checkedCompany = "";
        this.$refs.companySelectList.page.pageNumber = 1;
      }
    },
    copyData() {
      if (
        this.$refs.companySelectList &&
        this.$refs.companySelectList.checkedCompany
      ) {
        duplicateCompanyProject({
          fromCompanyId: this.companyId,
          toCompanyId: this.$refs.companySelectList.checkedCompany,
          enabledCopyData: this.$refs.companySelectList.enabledCopyData,
          enabledCopyFormTemplate:
            this.$refs.companySelectList.enabledCopyFormTemplate,
        })
          .then(() => {
            this.cancelCopy();
            this.$message.success("复制成功,数据正在复制，请稍后查看");
          })
          .catch(() => {});
      } else {
        this.$message.warning("请选择复制单位");
      }
    },
    editConfig() {
      this.loading = true;
      checkError({
        companyId: this.companyId,
      })
        .then((res) => {
          this.errorInfoList = res.data.errorInfoList;
          this.loading = false;
          if (this.errorInfoList && this.errorInfoList.length > 0) {
            this.dialogErrorVisible = true;
          } else {
            this.$confirm("检查完成，是否开始复制?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                this.dialogFormVisible = true;
              })
              .catch(() => {
                this.$message.info("取消复制");
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--font-text-primary, #172b4d);
}
.content {
  margin-top: 12px;
  padding: 16px;
  border-radius: 10px;
  background: #fff;
}
.description {
  font-size: 14px;
  line-height: 24px;
  color: #6b778c;
}
.dialog-copy-form {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 10px;
  }

  ::v-deep .el-dialog__body .el-table {
    min-height: 40vh;
    max-height: 50vh;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #d6d6d6;
    margin-top: 10px;
  }

  ::v-deep .el-table::before {
    z-index: inherit;
  }
}
.error-body {
  padding: 5px 15px;
  height: 400px;
  overflow: auto;
  color: red;
}
</style>
