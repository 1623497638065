<template>
  <el-dialog
    title="公式编辑器"
    width="890px"
    :visible.sync="visible"
    @closed="closed"
    destroy-on-close
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="ag-row">
      <!-- 可用变量 -->
      <field-list
        @insert="nodeClick"
        :isJsonForm="isJsonForm"
        :columns="columns"
      ></field-list>
      <div class="rely-container">
        <slot name="header"></slot>
        <div class="formula-body">
          <div v-if="title" class="formula-header">{{ title }}=</div>
          <formula
            v-if="visible"
            ref="formula"
            :height="150"
            :relyType="relyType"
            :getCollectionTitle="getCollectionTitle"
            :getFieldByName="getFieldByName"
            :buildOriginalFormula="buildOriginalFormula"
          ></formula>
        </div>
        <slot name="footer"></slot>
      </div>
    </div>
    <div slot="footer">
      <el-button
        type="default"
        @click="visible = false"
        style="margin-right: 10px"
        >取消</el-button
      >
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { checkFieldAbility } from "@/zgg-core/component-white-list";
import { getContentByOriginalFormula } from "./formula";
// 聚合表公式

import formula from "./index";
import fieldList from "./field-list";
import { isEmpty, isNumber } from "@/zgg-core/utils";
import formula_mixin from "./formula_mixin";
import { getArgComponentNames } from "@/zgg-core/relyFuns";

export default {
  name: "AggregateFormula",
  components: { formula, fieldList },
  mixins: [formula_mixin],
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    }, // 设置公式的表单字段

    isJsonForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      visible: false,
      columns: [],
      treeNodeList: [],
      relyType: "",
    };
  },
  methods: {
    closed() {
      this.$emit("closed");
    },
    getCollectionTitle(collection) {
      let obj = this.columns.find((item) => item.collection == collection);
      if (obj) {
        return obj.title;
      }
      return "";
    },
    async open(treeNodeList, originalFormula, relyType) {
      this.relyType = relyType;
      this.visible = true;
      this.treeNodeList = treeNodeList;
      if (originalFormula) {
        originalFormula.forEach((rows) => {
          rows.forEach((item) => {
            if (item.type == "val") {
              if (isNumber(item.value)) {
                item.componentName = "input_number";
                item.value = parseFloat(item.value);
              } else if (typeof item.value === "string") {
                item.componentName = "input";
              }
            }
          });
        });
      }

      if (this.relyType == "unionRely") {
        this.buildUnionComponents();
      } else {
        let columns = await this.buildFormComponents(treeNodeList);
        this.$set(this, "columns", columns);
      }
      let content = getContentByOriginalFormula(originalFormula, this.relyType);
      this.$nextTick(() => {
        this.$refs.formula.setContent(content);
      });
    },
    close() {
      this.visible = false;
    },
    save() {
      if (this.$refs.formula.error) {
        this.$message.error("请先处理错误信息");
        return;
      }

      let { originalFormula, tree } = this.buildOriginalFormula();
      if (isEmpty(originalFormula)) {
        this.$message.error("请输入公式");
        return;
      }
      let componentNames = getArgComponentNames({
        type: "rely",
        children: tree,
      });
      this.$emit("save", originalFormula, componentNames);
    },
    nodeClick(data, node) {
      if (node.isLeaf) {
        this.$refs.formula.insert({
          ...data,
          type: "field",
        });
      }
    },

    /** 构建聚合表公式 */
    buildUnionComponents() {
      let map = [];
      let buildComponents = (components, collection, parent) => {
        let list = [];
        components.forEach((item) => {
          if (checkFieldAbility(item, "inAggregateRely")) {
            let obj = this._.cloneDeep(item);
            obj.collection = collection;
            if (parent && parent.componentName == "json_form") {
              obj.name = parent.name + "_" + obj.name;
              obj.title = parent.title + "." + obj.title;
              obj.parentName = parent.name;
            }
            list.push(obj);
          } else if (item.container) {
            list = list.concat(
              buildComponents(item.components, collection, item)
            );
          }
        });
        return list;
      };

      this.treeNodeList.forEach((value) => {
        let list = buildComponents(value.components, value.id);

        map.push({
          collection: value.id,
          title: value.title,
          components: list,
          show: true,
          type: "table",
        });
      });

      this.$set(this, "columns", map);
    },
  },
};
</script>
<style lang="scss" scoped>
.formula-body {
  border: 1px solid #e0e0e0;
  .formula-header {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    background-color: #f3f8fb;
    padding: 0 10px;
  }
}

.ag-row {
  display: flex;
  height: 300px;
  overflow: hidden;
  margin: 0 10px;
}
.weui-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.rely-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
</style>