<template>
  <div>
    <!-- 为了点击弹出框标题可以关闭图标选择 -->
    <div
      @click="showSelect = false"
      style="
        width: 400px;
        height: 40px;
        background-color: transparent;
        top: 0px;
        position: absolute;
      "
    ></div>
    <div class="add-folder-dialog" @click="showSelect = false">
      <div class="select-icon">
        <!-- <p>图标</p> -->
        <div class="icon-container vue-hover-mask">
          <!--hover 遮罩-->
          <span
            @click.stop="showSelect = !showSelect"
            class="vue-hover-mask_action"
          >
            <i
              class="iconfont icon-bianji_fill"
              style="color: #fff; font-size: 16px; margin-right: 0"
            ></i>
          </span>

          <template v-if="iconStyle && iconStyle.type !== null">
            <div
              class="image-icon"
              v-if="iconStyle.type === 'ICON_URL'"
              :style="{
                'background-image': 'url(' + iconStyle.icon + ')',
              }"
            ></div>

            <div class="icon-div" v-else-if="iconStyle.type == 'EMOJI'">
              <div
                @click.stop="showSelect = !showSelect"
                style="font-size: 16px; z-index: 1; cursor: pointer"
              >
                {{ iconStyle.icon }}
              </div>
            </div>
            <div class="icon-div" v-else :style="setBgColor">
              <i
                :class="
                  !iconStyle.className.includes('fa')
                    ? 'iconfont ' + iconStyle.className
                    : iconStyle.className
                "
                style="font-size: 16px; margin-right: 0"
                :style="setIconColor"
              ></i>
            </div>
          </template>

          <template v-else>
            <div class="icon-div">
              <svg-icon
                v-if="folderData.dataType === 'folder'"
                iconClass="wenjianjia"
                style="margin-right: 5px; font-size: 16px"
              ></svg-icon>
              <i
                v-else-if="
                  folderData.dataType === 'resource' &&
                  folderData.param.componentName === 'table'
                "
                style="color: rgb(178, 154, 232)"
                class="iconfont icon-biaodanqun"
              ></i>
              <i
                v-else-if="
                  folderData.dataType === 'resource' &&
                  folderData.param.componentName === 'plan_form_view'
                "
                style="color: rgb(74, 18, 199)"
                class="iconfont icon-biaodanqun"
              ></i>
              <i
                v-else-if="
                  folderData.dataType === 'resource' &&
                  folderData.param.componentName === 'dashboard'
                "
                class="iconfont icon-yibiaopan"
                style="color: #52c41a"
              ></i>
            </div>
          </template>
        </div>
        <style-select
          :iconStyle="iconStyle"
          :enabledNewLayout="enabledNewLayout"
          :dataType="folderData.dataType"
          @updateIcon="updateIcon"
          v-if="showSelect"
        ></style-select>
      </div>
      <div v-if="!isHomepage">
        <!-- <p>名称</p> -->
        <el-input
          @blur="updateName"
          required
          size="small"
          style="width: 360px; margin-left: 10px"
          v-model="name"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import styleSelect from "./styleSelect.vue";
export default {
  props: {
    folderData: Object,
    isHomepage: {
      type: Boolean,
      default: false,
    },
    enabledNewLayout: {
      type: Boolean,
      default: false,
    },
    opName: String,
  },
  components: { styleSelect },
  data() {
    return {
      name: "",
      iconStyle: {
        type: null,
        color: "",
        className: "",
        shape: "",
        icon: "",
      },
      showSelect: false,
    };
  },
  created() {
    console.log(Object.keys(this.folderData).length, "伙委会如 ");
    if (Object.keys(this.folderData).length != 0) {
      if (
        Object.keys(this.folderData.param).length != 0 &&
        this.folderData.param.iconStyle != null
      ) {
        this.iconStyle = this.folderData.param.iconStyle;
      }
      this.name = this.folderData.title;
    } else {
      if (
        (this.enabledNewLayout && this.folderData.dataType == "folder") ||
        this.opName == "add"
      ) {
        this.iconStyle = {
          type: "CLASS",
          className: "fas fa-briefcase fa-fw",
          color: "#3870EA",
          shape: "SQUARE",
        };
      }
      this.name = "";
      this.$emit("updateName", this.iconStyle, this.name);
    }
  },
  computed: {
    setBgColor() {
      let style = "background-color:" + this.iconStyle.color;
      if (this.iconStyle.shape == "FILLED") {
        style = "";
      }
      return style;
    },
    setIconColor() {
      let style = "color:#fff";
      if (this.iconStyle.shape == "FILLED") {
        style = "color:" + this.iconStyle.color;
      }
      return style;
    },
  },
  methods: {
    handleClick() {
      this.showSelect = true;
    },
    updateIcon(iconStyle, type) {
      if (type == "reset") {
        this.$set(this, "iconStyle", iconStyle);
        this.$emit("chageIcon", this.iconStyle, this.name);
        return;
      }
      switch (iconStyle.type) {
        case "ICON_URL":
          this.$set(this.iconStyle, "type", iconStyle.type);
          this.$set(this.iconStyle, "icon", iconStyle.icon);
          break;
        case "CLASS":
          this.$set(this.iconStyle, "color", iconStyle.color);
          this.$set(this.iconStyle, "className", iconStyle.className);
          this.$set(this.iconStyle, "shape", iconStyle.shape);
          break;
        case "EMOJI":
          this.$set(this.iconStyle, "icon", iconStyle.icon);
          this.$set(this.iconStyle, "type", iconStyle.type);
          break;
      }
      this.$set(this.iconStyle, "type", iconStyle.type);
      this.$emit("chageIcon", this.iconStyle, this.name);
    },
    updateName() {
      this.$emit("updateName", this.iconStyle, this.name);
    },
    handleNodeClick(data, node, el) {
      console.log(data);
      this.$emit("change", data.id);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.add-folder-dialog {
  display: flex;
  flex-direction: row;
  padding: 20px;

  .icon-container {
    .icon-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;

      border-radius: 2px;
    }

    .image-icon {
      width: 32px;
      height: 32px;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 5px;
    }
  }
}
.vue-hover-mask {
  overflow: hidden;
  position: relative;
  line-height: 1;
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}
.vue-hover-mask_action {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s;
  background: rgba(27, 27, 27, 0.6);
  border-radius: 5px;
}
.vue-hover-mask_action::after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}
.vue-hover-mask_action:hover {
  opacity: 1;
}
.select-icon {
  position: relative;
}
</style>
