<template>
  <div>
    <TEditor v-model="value"></TEditor>
  </div>
</template>

<script>
import TEditor from "@/components/TEditor.vue";

export default {
  name: 'html-input',
  components:{
    TEditor
  },
  props:{

  },
  data(){
    return{
        value:""
    }
  },
  methods:{

  },
}
</script>

<style scoped>

</style>
