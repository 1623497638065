<template>
  <div style="display: flex; flex-direction: row; height: 100%">
    <div
      style="width: 220px; height: 100%; background: #fff; padding: 24px 16px"
    >
      <el-menu ref="menu" class="el-menu-vertical-demo" @select="changePath">
        <el-menu-item index="unit-info">
          <i class="el-icon-menu"></i>
          <span slot="title">企业信息</span>
        </el-menu-item>
        <el-menu-item index="flow-setting">
          <i class="el-icon-menu"></i>
          <span slot="title">流程设置</span>
        </el-menu-item>
        <el-menu-item index="ding-user-sync-config">
          <i class="el-icon-menu"></i>
          <span slot="title">钉钉人员同步</span>
        </el-menu-item>
        <el-menu-item index="unit-copy">
          <i class="el-icon-menu"></i>
          <span slot="title">跨单位复制</span>
        </el-menu-item>
        <el-menu-item index="pay-setting">
          <i class="el-icon-menu"></i>
          <span slot="title">支付设置</span>
        </el-menu-item>
        <el-menu-item index="sms-setting">
          <i class="el-icon-menu"></i>
          <span slot="title">短信平台设置</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div style="flex: 1; height: 100%; overflow: auto">
      <transition>
        <router-view v-if="!isRootRoute" />
        <el-empty
          v-else
          description="左侧选择配置"
          image="/static/empty-img/view-empty.png"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        ></el-empty>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isRootRoute() {
      return this.$route.path == "/lowCode/unit-config";
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
  },
  watch: {
    companyId(val) {
      this.$router.replace("/lowCode/unit-config");
    },
  },
  created() {},
  methods: {
    changePath(val) {
      this.$router.push({
        path: `/lowCode/unit-config/${val}`,
      });
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.el-menu-vertical-demo {
  border-right: unset;
  .el-menu-item:hover,
  .el-menu-item:focus {
    border-radius: 5px;
  }
}
</style>
