<template>
  <div>
    <!-- 统计图表 -->
    <el-drawer
      title="统计图表"
      class="add-chart"
      :visible.sync="drawer"
      size="600px"
      :before-close="handleDrawerClose"
    >
      <div
        v-if="dashboard && dashboard.components && dashboard.components.length"
      >
        <template v-for="(item, index) in dashboard.components">
          <div
            class="dash-item"
            @click="currentKey = item.key"
            @mouseover="mouseOver(item.key)"
            @mouseleave="mouseLeave"
            :key="index"
          >
            <div
              class="dash-container"
              :class="{ active: item.key == currentKey }"
              :style="calDashItemContainerStyle(item)"
            >
              <div
                class="dash-header"
                :class="isShowHeader(item) ? 'no-header' : ''"
              >
                <div class="title" v-if="isShowTitle(item)">
                  {{ item.extParam.title }}
                </div>
                <div
                  v-show="isShowEdit == item.key"
                  :class="isShowHeaderEdit(item) ? 'no-header-edit' : ''"
                  style="cursor: default; z-index: 999"
                >
                  <i
                    @click="editItem(index, $event)"
                    class="el-icon-edit-outline"
                    style="color: rgba(9, 30, 66, 0.66)"
                  ></i>
                  <!-- todo 产品讨论暂时隐藏 -->
                  <!-- <el-popover placement="bottom" trigger="click">
                    <div
                      class="dashboard-item-edit__wrap"
                      v-if="item.refreshConfig"
                    >
                      <div class="row">
                        <span>自动刷新</span>
                        <el-switch
                          v-model="item.refreshConfig.autoRefresh"
                          :active-value="true"
                          :inactive-value="false"
                        ></el-switch>
                      </div>
                      <div class="row">
                        <span>每隔</span>
                        <el-input-number
                          v-model="item.refreshConfig.autoRefreshSeconds"
                          style="width: 100px"
                          size="mini"
                          controls-position="right"
                          :min="1"
                        ></el-input-number>
                        <span>秒，前台自动刷新当前组件</span>
                      </div>
                    </div>
                    <i
                      v-if="hasAutoRefreshComponent(item.componentName)"
                      slot="reference"
                      class="el-icon-s-tools"
                      style="color: rgba(9, 30, 66, 0.66)"
                    ></i>
                  </el-popover> -->
                  <i
                    @click="copyItem(item, $event)"
                    class="el-icon-copy-document"
                    style="color: rgba(9, 30, 66, 0.66)"
                  ></i>
                  <i
                    @click="delItem(index, $event)"
                    class="el-icon-delete"
                    style="color: rgba(9, 30, 66, 0.66)"
                  ></i>
                </div>
              </div>
              <div class="dash-body">
                <chart-component :view="item" :ref="'chart_' + item.key">
                </chart-component>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        class="drawer-body"
        :style="{
          marginTop:
            dashboard && dashboard.components && dashboard.components.length
              ? '0'
              : '100px',
        }"
      >
        <span class="description">创建本群的图表</span>
        <el-button
          type="primary"
          size="medium"
          class="addBtn"
          @click="addChart"
          round
          >新增图表</el-button
        >
      </div>
    </el-drawer>

    <!-- 图表配置 -->
    <el-drawer
      title="图表配置"
      :with-header="false"
      :visible.sync="chartEditVisible"
      size="100%"
      :before-close="handleChartEditClose"
    >
      <div>
        <chart-edit
          v-if="chartEditVisible && chart"
          :appView="chart"
          :switchAble="false"
          :closeFun="closeFun"
          @saveChart="saveChart"
        ></chart-edit>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getViewDetail, saveViewComponent, dashboardViewCreate } from "./api";
import {
  COL_NUM,
  defaultModuleLayoutGridBundleSize,
  isChartComponent,
  MAX_H,
  MIN_H,
  MIN_W,
  ROW_HEIGHT,
} from "@/views/lowCode/dashboard/dashboard-util";
import { isEmpty, isEmptyByKey } from "@zgg-core-utils/utils";
import chartEdit from "@/views/lowCode/view/chartEdit";
import { dashboardMixin } from "@/views/lowCode/dashboard/dashboardMixin";
import chartComponent from "@/views/lowCode/dashboard/chart-component.vue";
export default {
  mixins: [dashboardMixin],
  props: {
    drawer: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dashboardViewId: {
      type: String,
      default() {
        return "";
      },
    },
    view: Object,
    dashboard: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    chartEdit,
    chartComponent,
  },
  data() {
    return {
      currentKey: "",
      isShowEdit: "",
      index: 0,
      chart: null,
      chartEditVisible: false,
    };
  },
  mounted() {
    if (this.dashboard && this.dashboard.components) {
      this.fetchDashboardData(this.dashboard.components);
    }
  },
  methods: {
    //是否支持设置自动刷新
    hasAutoRefreshComponent(componentName) {
      return isChartComponent(componentName);
    },
    /**
     * 是否展示顶部编辑按钮
     * @param item
     * @returns {boolean}
     *
     */
    isShowHeaderEdit(item) {
      return (
        item.componentName === "metric_table" ||
        item.componentName === "html" ||
        item.componentName === "title_view" ||
        item.componentName === "carousel_view" ||
        item.componentName === "include" ||
        item.componentName === "button_group"
      );
    },
    isShowTitle(item) {
      return !(
        (item.componentName == "metric_table" && item.hiddenTitle) ||
        (item.componentName == "button_group" && item.hiddenTitle) ||
        item.componentName == "html" ||
        item.componentName == "title_view" ||
        item.componentName == "carousel_view" ||
        item.componentName == "include"
      );
    },
    /**
     * 获取区域树
     */
    isShowHeader(item) {
      return (
        (item.componentName == "metric_table" && item.hiddenTitle) ||
        (item.componentName == "button_group" && item.hiddenTitle) ||
        item.componentName == "html" ||
        item.componentName == "title_view" ||
        item.componentName == "carousel_view" ||
        item.componentName == "include"
      );
    },
    /**
     * 计算仪表盘组件的容器样式
     * @param item
     * @returns {{}}
     */
    calDashItemContainerStyle(item) {
      let style = {};
      if (item.componentName == "metric_table") {
        style = {
          ...style,
        };
        if (item.hiddenTitle) {
          style = {
            ...style,
            "padding-bottom": "0px",
          };
        }
      }
      if (item.componentName == "title_view") {
        style = {
          ...style,
          padding: "0",
          "background-color": "transparent",
        };
      }
      if (item.componentName == "html") {
        style = {
          ...style,
          padding: "12px",
        };
      }
      if (item.componentName == "carousel_view") {
        style = {
          ...style,
          padding: "0",
        };
      }
      if (item.componentName == "include") {
        style = {
          ...style,
          padding: "0",
        };
      }
      if (item.componentName == "button_group") {
        style = {
          ...style,
          padding: "2px 12px 12px 12px",
        };
        if (item.hiddenTitle) {
          style = {
            ...style,
            paddingTop: "12px",
          };
        }
      }
      if (item.backgroundSet) {
        style = Object.assign(item.backgroundSet, style);
      }
      return style;
    },
    getBlankPosition(obj) {
      let view = this.addPosition(obj);
      let layout = this.dashboard.components;
      // 确定边界
      let maxY = 0,
        edgeX = 0,
        edgeY = 0;
      layout.forEach((item) => {
        if (item.y + item.h > maxY) {
          maxY = item.y + item.h;
        }
      });
      edgeX = this.colNumber;
      edgeY = maxY;
      // 使用二维数组生成地图
      let gridMap = new Array();
      for (let x = 0; x < edgeX; x++) {
        gridMap[x] = new Array();
        for (let y = 0; y < edgeY; y++) {
          gridMap[x][y] = 0;
        }
      }
      // 标记占位
      layout.map((item) => {
        // 将layout中卡片所占区域标记为1
        for (let x = item.x; x < item.x + item.w; x++) {
          for (let y = item.y; y < item.y + item.h; y++) {
            gridMap[x][y] = 1;
          }
        }
      });
      // 遍历地图，申请位置
      for (let y = 0; y < edgeY; y++) {
        for (let x = 0; x < edgeX; x++) {
          // 申请所需空间
          if (edgeX - x >= view.w && edgeY - y >= view.h) {
            if (gridMap[x][y] < 1) {
              let itemSignArr = [];
              for (let a = x; a < x + view.w; a++) {
                for (let b = y; b < y + view.h; b++) {
                  itemSignArr.push(gridMap[a][b]);
                }
              }
              if (itemSignArr.indexOf(1) < 0) {
                view.x = x;
                view.y = y;
                return view;
              }
            }
          }
        }
      }
      return view;
    },
    addPosition(obj) {
      let maxY = 0;
      this.dashboard.components.forEach((item) => {
        if (item.y + item.h > maxY) {
          maxY = item.y + item.h;
        }
      });
      obj.x = 0;
      obj.y = maxY;
      obj.w = obj.w || this.minW;
      obj.h = obj.h || 6;
      obj.i = obj.key;
      obj = {
        ...obj,
        ...defaultModuleLayoutGridBundleSize[obj.componentName],
      };
      return obj;
    },
    beforeAddChart() {
      // 默认新增指标图
      let obj = {
        key: this.getUuid(),
        container: false,
        componentName: "metric_table",
        dataSource: this.view.component.dataSource,
        extParam: {
          title: this.view.title,
        },
        form: false,
        left: "",
        top: "",
        height: "",
        width: "",
        metrics: [],
        xFields: [],
        yFields: [],
      };
      this.index = this.dashboard.components.length;
      obj = this.getBlankPosition(obj);
      this.chart = obj;
      this.dashboard.components.push(obj);
    },
    addChart() {
      if (this.dashboardViewId) {
        // 已经创建过
        this.beforeAddChart();
        this.chartEditVisible = true;
      } else {
        dashboardViewCreate({
          parentViewId: this.view.id,
        }).then((res) => {
          if (!isEmptyByKey(res, "data.view.id")) {
            this.dashboard.components = [];
            this.$emit("update:dashboardViewId", res.data.view.id);
            this.beforeAddChart();
            this.chartEditVisible = true;
          }
        });
      }
    },
    handleDrawerClose() {
      this.$emit("update:drawer", false);
    },
    handleChartEditClose() {
      this.chartEditVisible = false;
    },
    closeFun() {
      this.chart = null;
      this.chartEditVisible = false;
    },
    copyItem(item, event) {
      event.preventDefault();
      event.stopPropagation();
      let view = this._.cloneDeep(item);
      view.key = this.getUuid();
      view.extParam.title = "拷贝_" + view.extParam.title;
      this.currentKey = view.key;
      this.index = this.dashboard.components.length;
      view = this.getBlankPosition(view);
      this.dashboard.components.push(view);
      this.index++;
      this.$nextTick(() => {
        this.$refs["chart_" + view.key][0].getData(view);
      });
      this.save();
    },
    editItem(index, event) {
      event.preventDefault();
      event.stopPropagation();
      this.index = index;
      let currentComponent = this.dashboard.components[index];
      let { key, componentName } = currentComponent;
      this.currentKey = key;
      this.chart = this._.cloneDeep(currentComponent);
      // 临时处理透视表旧数据，不存在列维度增补属性 后续后端会进行数据修复
      if (currentComponent.componentName == "pivot_table") {
        if (!this.chart.yFields) {
          this.chart.yFields = [];
        }
      }
      this.chartEditVisible = true;
    },
    delItem(index, event) {
      event.preventDefault();
      event.stopPropagation();
      this.dashboard.components.splice(index, 1);
      this.save();
    },
    save() {
      saveViewComponent({
        viewId: this.dashboardViewId,
        componentName: this.dashboard.componentName,
        component: this.dashboard,
      }).then((res) => {
        this.$message.success("保存成功!");
      });
    },
    saveChart({ componentName, view, callback, review }) {
      this.$set(this.dashboard.components, this.index, this._.cloneDeep(view));
      let $el = this.$refs["chart_" + view.key][0];
      if (typeof $el.getData === "function") {
        this.$refs["chart_" + view.key][0].getData(view);
      }
      this.dashboard.componentName = "dashboard";
      saveViewComponent({
        viewId: this.dashboardViewId,
        componentName: "dashboard",
        component: this.dashboard,
      }).then((res) => {
        this.$message.success("保存成功!");
      });
    },
    // 移入
    mouseOver(key) {
      this.isShowEdit = key;
    },
    // 移出
    mouseLeave() {
      this.isShowEdit = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .container {
  height: calc(100vh);
}
::v-deep .el-drawer__header {
  margin-bottom: 15px;
  .el-drawer__close-btn .el-icon-close {
    &:hover {
      background-color: #d4d7da;
    }
  }
}

.add-chart ::v-deep .el-drawer__body {
  border-top: #f5f6f8 solid 1px;
  background-color: #f5f6f8;
}

.drawer-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .description {
    color: #838892;
    font-size: 14px;
    margin: 10px 0px;
    text-align: center;
  }
  .addBtn {
    margin: auto;
  }
}

.dash-item {
  position: relative;
  padding: 10px;
  height: 300px;
  &:hover {
    .dash-container {
      outline: solid 2px rgba($color: #1890ff, $alpha: 0.6);
    }
  }

  .drag-right {
    width: 4px;
    position: absolute;
    right: 8px;
    top: 10px;
    bottom: 15px;
    cursor: ew-resize;
  }

  .drag-bottom {
    height: 4px;
    position: absolute;
    left: 10px;
    right: 15px;
    bottom: 8px;
    cursor: ns-resize;
  }

  .drag {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: nwse-resize;

    &::after {
      display: block;
      content: " ";
      width: 10px;
      height: 10px;
      border-color: #e0e0e0;
      border-style: solid;
      border-width: 0 1px 1px 0;
    }
  }

  .dash-container {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 2px 12px 12px 12px;
    border-radius: 4px;

    &.active {
      outline: solid 2px rgba($color: #1890ff, $alpha: 0.6);
    }
  }

  .dash-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;

    .title {
      font-size: 16px;
      flex: 1;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba($color: #091e42, $alpha: 0.95);
      font-weight: 600;
    }
    i {
      cursor: pointer;
    }
  }
  .no-header {
    justify-content: flex-end;
    height: 20px;
    position: absolute;
    right: 22px;
    top: 20px;
  }
  .no-header-edit {
    background: #fff;
    border-radius: 4px;
    padding: 2px;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
    white-space: nowrap;
  }
  .dash-body {
    flex: 1;
    overflow: auto;
    box-sizing: border-box;
  }
}

.dashboard-item-edit__wrap {
  width: 315px;
  font-size: 14px;
  line-height: 22px;
  color: #172b4d;
  .row:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
