<template>
  <div>
    <el-button size="small" style="width: 100%" @click="visible = true"
      >设置</el-button
    >
    <el-dialog
      title="数据格式"
      append-to-body
      destroy-on-close
      :visible.sync="visible"
      @open="open"
    >
      <el-form
        :model="form"
        label-position="top"
        style="padding: 20px 20px 0"
        class="form-number"
      >
        <el-form-item label="显示格式" prop="numberFormatType">
          <el-radio-group :value="form.numberFormatType">
            <el-radio
              label="number"
              @click.native.prevent="changeNumberFormatType('number')"
              >数值</el-radio
            >
            <el-radio
              label="percentage"
              @click.native.prevent="changeNumberFormatType('percentage')"
              >百分比</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="显示设置" style="margin-bottom: 0">
          <div class="num-weui">
            <el-checkbox
              :disabled="!form.numberFormatType"
              v-model="form.thousandsSeparator"
              >千分符</el-checkbox
            >
          </div>
          <div class="num-weui">
            <el-checkbox
              v-model="decimal"
              :disabled="!form.numberFormatType"
              @change="changeDecimal"
              >小数位数</el-checkbox
            >
            <el-input-number
              :disabled="!decimal"
              class="ml-10"
              v-model="form.decimal"
              :min="0"
              :max="6"
              placeholder=""
              size="small"
            ></el-input-number>
          </div>
          <template v-if="form.numberFormatType != 'percentage'">
            <div class="num-weui">
              <el-checkbox
                v-model="numberMagnitude"
                @change="changeNumberMagnitude"
                :disabled="!form.numberFormatType"
                >数值量级</el-checkbox
              >
              <el-select
                v-model="form.numberMagnitude"
                placeholder=""
                size="small"
                class="ml-10"
                :disabled="!numberMagnitude"
              >
                <el-option
                  v-for="item in list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="num-weui">
              <el-checkbox
                v-model="unit"
                @change="changeUnit"
                :disabled="!form.numberFormatType"
                >单位</el-checkbox
              >
              <el-input
                v-model="form.unit"
                size="small"
                placeholder=""
                style="width: 200px"
                class="ml-10"
                :disabled="!unit"
              ></el-input>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div style="margin: 0 20px">
        <div class="title">预览</div>
        <div class="preview">{{ text }}</div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-button type="primary" size="small" @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getStrByNumberFormat, isEmpty } from "@/zgg-core/utils";
/**
 * @typedef NumberFormat
 * @property {string} numberFormatType 数据格式
 * @property {boolean} thousandsSeparator  显示千分位
 * @property {string} numberMagnitude 数量级
 * @property {number} decimal 小数位数
 * @property {string} unit 单元
 */
export default {
  props: {
    numberFormat: Object,
  },

  data() {
    return {
      visible: false,
      /**
       * @type {NumberFormat}
       */
      form: {},
      unit: false,
      decimal: false,
      numberMagnitude: false,
      list: [
        {
          value: "empty",
          label: "无",
        },
        {
          label: "千",
          value: "thousand",
        },
        {
          label: "万",
          value: "tenThousand",
        },
        {
          label: "百万",
          value: "million",
        },
        {
          label: "亿",
          value: "hundredMillion",
        },
        {
          label: "十亿",
          value: "billion",
        },
      ],
    };
  },
  computed: {
    text() {
      let num = 888888888888;
      return getStrByNumberFormat(num, this.form);
    },
  },
  methods: {
    open() {
      /**
       * @type {NumberFormat}
       */
      let form = this._.cloneDeep(this.numberFormat);
      if (isEmpty(form)) {
        form = {
          numberFormatType: "number",
        };
      }

      this.$set(this, "form", form);
      this.unit = !isEmpty(this.form.unit);
      this.decimal = !isEmpty(this.form.decimal);
      this.numberMagnitude = !isEmpty(this.form.numberMagnitude);
    },
    changeNumberFormatType(val) {
      if (this.form?.numberFormatType == val) {
        this.decimal = false;
        this.unit = false;
        this.numberMagnitude = false;

        this.$set(this, "form", {});
      } else {
        let form = this._.cloneDeep(this.form);
        form.numberFormatType = val;
        form.thousandsSeparator = true;

        if (val == "percentage") {
          this.unit = false;
          this.numberMagnitude = false;
          form.unit = "";
          form.numberMagnitude = "";
        }
        this.$set(this, "form", form);
      }
    },
    changeDecimal(val) {
      if (!val) {
        this.$set(this.form, "decimal", undefined);
      } else {
        this.$set(this.form, "decimal", 2);
      }
    },
    changeNumberMagnitude(val) {
      if (!val) {
        this.$set(this.form, "numberMagnitude", "");
      } else {
        this.$set(this.form, "numberMagnitude", "empty");
      }
    },
    changeUnit(val) {
      if (!val) {
        this.$set(this.form, "unit", "");
      }
    },
    save() {
      let form = this.form;

      if (form?.numberFormatType) {
        if (this.decimal && isEmpty(this.form.decimal)) {
          this.$message.error("小数位数不能为空");
          return;
        }
        if (this.unit && isEmpty(this.form.unit)) {
          this.$message.error("单位不能为空");
          return;
        }
        let form = this._.cloneDeep(this.form);

        this.$emit("update:numberFormat", form);
      } else {
        this.$emit("update:numberFormat", null);
      }
      this.$nextTick(() => {
        this.$emit("refershData");
      });
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-number {
  ::v-deep {
    .el-form-item__label,
    .el-form-item__content {
      line-height: 20px;
    }
  }
}
.num-weui {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.title {
  color: #172b4d;

  /* Medium/H4-正文-Base-14\22 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
.preview {
  background: #f2f6fc;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
