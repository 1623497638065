<template>
  <div>
    <el-form ref="form" :model="node" :rules="rules" label-position="top">
      <el-form-item label="指定通知人" prop="userRange">
        <rpa-notice-users
          :userRange="node.userRange"
          :disabled="disabled"
        ></rpa-notice-users>
      </el-form-item>
      <div style="color: #e54f4f; padding-top: 10px">
        提示：由于微信公众平台规则修改，每个通知字段限制为20个字符，如果通知内容过长，请分开配置。
      </div>
      <el-form-item
        v-for="content in noticeContentList"
        :label="content.label"
        :key="content.label"
        :prop="content.template"
        :rules="[{ required: content.required, message: '请编辑通知内容' }]"
      >
        <rpa-codemirror
          :ref="content.ref"
          :fieldList="fieldList"
          @change="changeCode($event, content)"
          :disabled="disabled"
        ></rpa-codemirror>
        <div v-if="isComplete && !disabled">
          <el-popover v-model="content.visible" trigger="click">
            <el-button type="text" icon="el-icon-plus" slot="reference"
              >添加字段</el-button
            >
            <div style="max-height: 320px; overflow: auto">
              <el-tree
                :props="treeProps"
                @node-click="
                  (data, node, item) => nodeClick(data, node, item, content)
                "
                :load="loadNode"
                :lazy="true"
              >
              </el-tree>
            </div>
          </el-popover>
        </div>
      </el-form-item>
      <el-form-item
        label="链接表单"
        prop="miniProgramNodeKey"
        :class="isDelete ? 'is-error' : ''"
      >
        <el-radio v-model="jumpOptionRadio" label="node">选择节点</el-radio>
        <el-radio v-model="jumpOptionRadio" label="url">自定义链接</el-radio>
        <el-select
          v-if="jumpOptionRadio === 'node'"
          v-model="miniProgramNodeKey"
          placeholder="请选择节点"
          size="small"
          :clearable="true"
        >
          <el-option
            v-for="item in nodeList"
            :key="item.key"
            :label="item.title"
            :value="item.key"
          ></el-option>
        </el-select>
        <template v-if="jumpOptionRadio === 'url'">
          <el-input
            v-model="miniProgramUrl"
            placeholder="请输入链接"
          ></el-input>
          <span style="color: red; font-size: 12px"
            >操作方式：需要跳转到空表单，到前台复制分享填表链接粘贴进来。请勿输入非智管工平台的链接</span
          >
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import rpaNoticeUsers from "./rpa-notice-users";
import rpaCodemirror from "./rpa-codemirror";
import {
  buildNodeComponentsMap,
  fromNodesMixins,
  loadNodeLeaf,
} from "./rpa-utils";
import { getFormComponents, isEmpty } from "@zgg-core-utils/utils";
import { getFormDetail } from "../form/api";
import _ from "lodash";
import { getRobotWorkflow } from "./api";
export default {
  name: "rpa-notice-wechat",
  components: { rpaNoticeUsers, rpaCodemirror },
  props: {
    node: Object,
    disabled: Boolean,
  },
  mixins: [fromNodesMixins],
  inject: {
    getRootParams: {
      default: () => [],
    },
  },
  computed: {
    isComplete() {
      return !isEmpty(this.componentsMap);
    },
    isDelete() {
      if (!isEmpty(this.node.miniProgramNodeKey)) {
        return (
          this.nodeList.findIndex(
            (item) => item.key == this.node.miniProgramNodeKey,
          ) == -1
        );
      }
      return false;
    },
    miniProgramNodeKey: {
      get() {
        if (this.isDelete) {
          return "节点已被删除";
        }
        return this.node.miniProgramNodeKey;
      },
      set(val) {
        this.$set(this.node, "miniProgramNodeKey", val);
      },
    },
    miniProgramUrl: {
      get() {
        return this.node.miniProgramUrl;
      },
      set(val) {
        this.$set(this.node, "miniProgramUrl", val);
      },
    },
  },
  data() {
    return {
      jumpOptionRadio: "node",
      noticeContentList: [
        {
          ref: "rpaCode1",
          label: "通知内容1",
          template: "msgTemplate",
          visible: false,
          required: true,
        },
        {
          ref: "rpaCode2",
          label: "通知内容2",
          template: "msgTemplate2",
          visible: false,
          required: false,
        },
        {
          ref: "rpaCode3",
          label: "通知内容3",
          template: "msgTemplate3",
          visible: false,
          required: false,
        },
      ],
      treeProps: {
        label: (data) => {
          let str = data.title;
          if (data.formTitle) {
            str += `【${data.formTitle}】`;
          } else if (data.collectionTitle) {
            str += `【${data.collectionTitle}】`;
          }
          return str;
        },
        isLeaf: "leaf",
      },
      componentsMap: {},
      fieldList: [],
      rules: {
        userRange: [
          {
            required: true,
            validator(rule, value, callback) {
              if (isEmpty(value.userIds) && isEmpty(value.nodeUserFields)) {
                callback(new Error("请选择通知人"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      nodeList: [],
      rootFlowParams: [],
    };
  },
  watch: {
    "node.userRange": {
      deep: true,
      handler(val) {
        this.$refs.form.validateField("userRange");
      },
    },
    "node.msgTemplate": {
      deep: true,
      handler() {
        this.$refs.form.validateField("msgTemplate");
      },
    },
  },
  async created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
    let parentNodes = this.getParentNodes();
    let componentsMap = await buildNodeComponentsMap.bind(this)(parentNodes);

    if (this.rootFlowParams?.length) {
      componentsMap["root"] = this.rootFlowParams;
    }
    this.buildFieldList("root", componentsMap["root"]);

    let nodeList = [];

    parentNodes.forEach((item) => {
      let dataSource = item.dataSource ?? "form";
      let collection = item.collection || item.formId;
      if (
        [
          "query_data_one",
          "trigger_form_data_change",
          "trigger_subflow_collection",
        ].includes(item.type) &&
        dataSource == "form" &&
        collection
      ) {
        if (
          item.type == "trigger_form_data_change" &&
          item.dataChangeType == "delete"
        ) {
          return;
        }
        let formTitle = item.formTitle || item.collectionTitle;
        nodeList.push({
          title: item.title + "【" + formTitle + "】",
          key: item.key,
        });
      }
    });
    this.$set(this, "nodeList", nodeList);
    this.buildDeleteField();
    this.parentNodes = parentNodes;

    this.$set(this, "componentsMap", componentsMap);

    this.$nextTick(() => {
      this.noticeContentList.forEach((item) => {
        if (this.$refs[item.ref][0] && !isEmpty(this.node[item.template])) {
          this.$refs[item.ref][0].init(this.node[item.template]);
        }
      });
    });

    this.jumpOptionRadioInit();
  },

  methods: {
    changeCode(e, content) {
      this.$set(this.node, content.template, e);
      let code = this.node.msgTemplate.concat(
        this.node.msgTemplate2 || "",
        this.node.msgTemplate3 || "",
      );
      // eslint-disable-next-line no-useless-escape
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }
      this.$set(this.node, "msgNodeFields", arr);
    },
    /** 构建编辑器用的组件列表 */
    buildFieldList(nodeKey, list) {
      if (list) {
        let tempList = list.map((item) => {
          return {
            nodeKey: nodeKey,
            fieldName: item.name,
            componentName: item.componentName,
            name: nodeKey + "_" + item.name,
            title: item.title,
          };
        });
        this.fieldList = this.fieldList.concat(tempList);
      }
    },

    /** 在fieldList插入已删除的字段列表用于编辑器展示提示 */
    buildDeleteField() {
      if (this.node && this.node.msgNodeFields) {
        this.node.msgNodeFields.forEach((item) => {
          let name = item.nodeKey + "_" + item.name;
          let isDelete =
            this.fieldList.findIndex((row) => row.name == name) == -1;
          if (isDelete) {
            this.fieldList.push({
              nodeKey: item.nodeKey,
              fieldName: item.name,
              componentName: item.componentName,
              name: item.nodeKey + "_" + item.name,
              title: item.title,
              delete: true,
            });
          }
        });
      }
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let index = this.node.msgNodeFields.findIndex(
          (item) =>
            this.fieldList.findIndex(
              (row) =>
                row.delete &&
                item.name == row.fieldName &&
                item.nodeKey == row.nodeKey,
            ) >= 0,
        );

        if (index >= 0) {
          this.$message.error("通知内容存在被删除字段，请删除被删除字段");
          return;
        }
        callback(valid);
      });
    },
    loadNode: loadNodeLeaf,
    nodeClick(data, node, item, content) {
      if (node.isLeaf && node.level > 1) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }

        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs[content.ref][0].insert(obj);
        this.$refs[content.ref][0].onBlur();
        content.visible = false;
      }
    },
    // 加这个radio字段要修复数据库, 后端认为有风险, 前端自己处理单选框的取值
    // 规则: 有url跳转链接优先选自定义链接, 没有url链接选选择节点
    // 无奈之举
    jumpOptionRadioInit() {
      if (this.node.miniProgramUrl) {
        this.jumpOptionRadio = "url";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-border {
  border: 1px solid #dcdfe6;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 10px;
}

.codeEdit {
  min-width: 100%;
  overflow-y: hidden !important;
  overflow-x: auto !important;

  height: 20px;

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  ::v-deep {
    pre {
      white-space: nowrap !important;
      word-wrap: normal !important;
      width: max-content;
    }
  }
}
</style>
