<template>
  <div>
    <template v-if="this.component?.tableName">
        <div class="attr-row">
            <div class="row-between">
                <div class="attr-label">关联表：</div>
            </div>
            <div>
                <el-input v-model="tableName" placeholder="" size="mini" disabled></el-input>
            </div>
        </div>
    </template>
    <template v-else>
        <attr-include-form
            :component="component"
            :params="params"
            :disabled="disabled"
            @changeSource="changeSource"
        ></attr-include-form>
    </template>

    <div class="attr-row">
      <div class="row-between">
        <div class="attr-label">数据字段：</div>
      </div>
      <el-select
        v-model="component.referenceFieldName"
        size="mini"
        placeholder="请选择关联表中的关联数据字段"
        style="width: 100%"
      >
        <el-option 
            v-for="item in referenceFields" 
            :key="item.name" 
            :label="item.title"       
            :value="item.name">
        </el-option>
      </el-select>
    </div>
    <div class="attr-row">
      <div class="row-between">
        <div class="attr-label">下拉框显示字段：</div>
      </div>
      <el-select
        v-model="field"
        size="mini"
        placeholder="请选择字段"
        style="width: 100%"
      >
        <el-option 
            v-for="item in columns" 
            :key="item.name" 
            :label="item.title"       
            :value="item.name">
        </el-option>
      </el-select>
    </div>
    <div class="attr-row">
      <div class="row-between">
        <div class="attr-label">下拉框样式：</div>
      </div>
      <el-radio-group v-model="component.chooseMode">
        <el-radio :label="'cascade'">级联菜单</el-radio>
        <el-radio :label="'tree'">树形菜单</el-radio>
    </el-radio-group>
    </div>
    <div class="attr-row">
      <div class="row-between">
        <div class="attr-label">选项设置：</div>
      </div>
      <el-checkbox v-model="component.selectedLastLevel" v-if="component.chooseMode == 'cascade'">
        <span style="font-size: 12px">必须选择到最后一级</span>
      </el-checkbox>
      <el-checkbox v-model="component.showAllPath">
        <span style="font-size: 12px">选择结果显示层级路径</span>
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import { componentProps } from "./util";
import { getFormDetail } from "../api";
import { deepClone } from "@/utils";
import attrIncludeForm from "@/views/lowCode/form/attribute/attr-include-form";
// 数据字段需要排除的组件字段
const exitDataTitleArr = [
  "json_form",
  "image_uploader",
  "attachment_uploader",
  "sign_input",
  "parent_data",
  "reference_data_list",
  "include_form",
  "position_input",
  "sign_list_input",
  "html_input",
  "text_area",
  "address_input",
  "reference_data",
  "cascade_data",
];

export default {
    name: "attr-cascade-data",
    props: componentProps,
    components: {
        attrIncludeForm,
    },
    data() {
        return {
            tableName: "", // 关联表名称
            componentList: [], // 关联表组件集合
            referenceFields: [], // 数据字段集合
            fields: [], // 下拉框显示字段集合
        };
    },
    computed: {
        disabled(){
            return this.component?.tableName ? true : false;
        },
        field: {
            get() {
                return this.component?.field?.name || "";
            },
            set(val) {
                if(val){
                    let components = this.columns.filter(item => item.name == val);
                    if(components?.length){
                        this.$set(this.component, "field", components[0]);
                    }
                }
            },
        },
        columns() {
            let columns = [];
            let exitArr = exitDataTitleArr;
            let buildColumns = (componentList, exitComponents, isReferenceData) => {
                let list = [];
                let component;
                let components = deepClone(componentList);
                components.forEach((item) => {
                    if (isReferenceData) {
                        component = item.field.component;
                    } else {
                        component = item;
                    }
                    if (component.form) {
                        if (!exitComponents.includes(component.componentName)) {
                        list.push(item);
                        }
                    } else if (component.container) {
                        list = list.concat(buildColumns(component.components, exitArr));
                    }
                });
                return list;
            };
            columns = buildColumns(this.componentList, exitArr);
            columns = columns.concat([
                {
                componentName: "input",
                container: false,
                form: true,
                array: false,
                json: false,
                required: false,
                name: "createrId",
                title: "创建者",
                },
                {
                componentName: "date_picker",
                picker: "datetime",
                container: false,
                form: true,
                array: false,
                json: false,
                required: false,
                name: "createdTime",
                title: "创建时间",
                },
                {
                componentName: "date_picker",
                picker: "datetime",
                container: false,
                form: true,
                array: false,
                json: false,
                required: false,
                name: "updatedTime",
                title: "更新时间",
                },
            ]);
            console.log(columns);
            return columns;
        },
    },
    created() {
        if(this.component?.tableName){
            this.getFormDetail(this.component.tableName)
        }
    },
    methods: {
        changeSource(item) {
            this.$set(this.component, "tableName", item.id);
            this.getFormDetail(item.id)
        },
        getFormDetail(tableName){
            getFormDetail(tableName).then(resp => {
                this.tableName = resp?.data?.form?.title;
                let components = resp.data?.form?.component?.components
                if(components && components.length) {
                    this.componentList = this._.cloneDeep(components);
                    this.referenceFields = components.filter(item => item.componentName === "reference_data" && item?.tableName == this.component?.tableName)
                }
            })
        }
    },
}

</script>
<style lang="scss" scoped>

</style>