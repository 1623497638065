import request from '@/utils/request'
import qs from "qs"
const SERVICE_PREFIX = "/app"

/*-------------------------------看板api-------------------*/
export function bulletinList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletin/list?_version=2.0",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinPage(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletin/paging",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletin/save",
        method: "post",
        data: qs.stringify(data)
    })
}
export function bulletinSaveWithUser(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletin/saveWithUser",
        method: "post",
        data: qs.stringify(data)
    })
}
export function bulletinDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletin/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinDetail(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletin/detail",
        method: "post",
        data: qs.stringify(data)
    })
}

/*----------------------------------看板列api---------------------------*/
export function bulletinColumnList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/list",
        method: "post",
        data: qs.stringify(data)
    })
}

export function fetchColumnArchivePaging(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/archivePaging",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinColumnSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/save",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinColumnDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinColumnDetail(id) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/detail",
        method: "post",
        data: qs.stringify({ id })
    })
}
export function bulletinColumnSort(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/sort",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinColumnArchive(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/archive",
        method: "post",
        data: qs.stringify(data)
    })
}
export function bulletinColumnCopy(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/copy",
        method: "post",
        data: qs.stringify(data)
    })
}

/**
 * 删除栏
 * @param {*} id
 * @returns
 */
export function delColumn(id) {
    return request({
        url: SERVICE_PREFIX + "/bulletinColumn/delete",
        method: 'post',
        data: { id }
    })
}
/*-----------------------看板卡片api----------------------*/
export function bulletinCardList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/list",
        method: "post",
        data: qs.stringify(data)
    })
}

/**
 * 删除卡片
 * @param {*} id
 * @returns
 */
export function delCard(id) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/delete',
        method: 'post',
        data: { id }
    })
}

/**
 * 获取最近任务卡片
 */
export function fetchBulletinCardList(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/paging',
        method: 'POST',
        data: qs.stringify(data)
    })
};

/**
 * 获取最近任务卡片
 */
export function fetchCardArchivePaging(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/archivePaging',
        method: 'POST',
        data: qs.stringify(data)
    })
};


export function bulletinCardSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/save",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinCardSaveWithUser(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/saveWithUser",
        method: "post",
        data: qs.stringify(data)
    })
}
export function bulletinCardDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinCardDetail(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/detail",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinCardSort(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/sort",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinCardArchive(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/archive",
        method: "post",
        data: qs.stringify(data)
    })
}
export function bulletinCardCopy(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/copy",
        method: "post",
        data: qs.stringify(data)
    })
}
/*----------------------看板成员api-------------------------*/
export function bulletinUserList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinUser/list",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinUserSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinUser/save",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinUserDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinUser/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinUserSaveBatch(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinUser/saveBatch",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinUserDetail(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinUser/detail",
        method: "post",
        data: qs.stringify(data)
    })
}

/*-------------------看板栏卡片成员api-------------------------*/

export function bulletinCardUserList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCardUser/list",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinCardUserSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCardUser/save",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinCardUserSaveBatch(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCardUser/saveBatch",
        method: "post",
        data: qs.stringify(data)
    })
}
export function bulletinCardUserDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCardUser/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinCardUserDetail(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCardUser/detail",
        method: "post",
        data: qs.stringify(data)
    })
}

/**
 * 批量删除成员
 */
export function bulletinCardUserDeleteBatch(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/deleteUser",
        method: 'post',
        data: qs.stringify(data)
    })
}

export function bulletinCardDeleteAttachment(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinCard/deleteAttachment",
        method: "post",
        data: qs.stringify(data)
    })
}
/*-------------------看板栏卡片标签api-------------------------*/

export function bulletinTagList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinTag/list",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinTagSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinTag/save",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinTagDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinTag/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinTagDetail(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinTag/detail",
        method: "post",
        data: qs.stringify(data)
    })
}

/*-------------------看板栏卡片日志api-------------------------*/

export function bulletinLogList(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinLog/list",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinLogSave(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinLog/save",
        method: "post",
        data: qs.stringify(data)
    })
}

export function bulletinLogDelete(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinLog/delete",
        method: "post",
        data: qs.stringify(data)
    })
}


export function bulletinLogDetail(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinLog/detail",
        method: "post",
        data: qs.stringify(data)
    })
}


/**
 * 看板实例化接口
 */
export function bulletinInstantiate(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletin/instantiate',
        method: 'post',
        data: qs.stringify(data)
    })
}


/**
 * 设置看板是否有列
 */
export function resetBulletinColumn(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletin/resetHasColumn',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 给卡片设置标签
 * @param {*} data
 */
export function saveBulletinTag(data) {
    return request({
        url: SERVICE_PREFIX + "/bulletinTag/save",
        method: 'post',
        data: qs.stringify(data)
    })
}

/**删除标签 */
export function deleteBulletinTag(id) {
    return request({
        url: SERVICE_PREFIX + "/bulletinTag/delete",
        method: 'post',
        data: qs.stringify({ id })
    });
}

/**
 * 看板封面
 */
export function setCardCoverImage(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/setCoverImage',
        method: 'post',
        data: qs.stringify(data)
    })
}



/**
 * 添加评论
 * @param {*} data
 * @returns
 */
export function saveComment(data) {
    return request({
        url: '/comment/save',
        method: 'POST',
        data
    })
}

/**
 * 获取评论列表
 * @param {*} data
 * @returns
 */
export function fetchCommentList(data) {
    return request({
        url: '/comment/paging',
        method: 'POST',
        data
    })
}

/**
 * 删除评论
 */
export function deleteComment(id) {
    return request({
        url: `/comment/delete?id=${id}`,
        method: 'POST'
    })
}

/**
 * 添加卡片附件
 */
export function addCardAttachment(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/addAttachment',
        method: 'POST',
        data: qs.stringify(data)
    })
}


/**
 * 卡片撤销归档
 */
export function cancleCardArchive(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/cancelArchive',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 组撤销归档
 */
export function cancleColumnArchive(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinColumn/cancelArchive',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 获取卡片动态列表
 * @param {*} data
 * @returns
 */
export function fetchBulletinCardLogs(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinLog/paging',
        method: 'POST',
        data
    })
}

/**
 * 附件重命名
 */
export function renameAttachment(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/renameAttachment',
        method: 'POST',
        data: qs.stringify(data)
    })
}
/**
 * 获取看板表单设置列表
 */
export function bulletinPluginFormList(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinPluginForm/list',
        method: 'POST',
        data
    })
}
/**
 * 获取看板表单详情
 */
export function bulletinPluginFormDetail(id) {
    return request({
        url: SERVICE_PREFIX + '/bulletinPluginForm/detail',
        method: 'POST',
        data: { id }
    })
}
/**
 * 看板设置提醒时间
 */
export function bulletinCardSetTime(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/setTime',
        method: 'POST',
        data
    })
}
/**
 * 看板清除设置提醒时间
 */
export function bulletinCardClearTime(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/clearTime',
        method: 'POST',
        data
    })
}
/**
 * 修复看板卡片
 */
export function bulletinCardSwitchStatus(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/switchStatus',
        method: 'POST',
        data
    })
}
/**
 * 看板重命名
 */
export function bulletinRename(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletin/rename',
        method: 'POST',
        data
    })
}
/**
 * 卡片关联其他组件 eg:整编、看板、表单
 */
export function bulletinCardRelationRegister(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCardRelation/register',
        method: 'POST',
        data
    })
}
/**
 * ，删除 卡片里面关联的 整编 和 看板 和 表单 接口
 */
export function bulletinCardRelationDelete(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCardRelation/delete',
        method: 'POST',
        data
    })
}

/**
 * 保存 卡片表格接口
 * ?id=卡片ID(必填)&excelId=表格ID(必填)&title=表格名称(必填)
 */
export function bulletinCardAddExcel(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/addExcel',
        method: 'POST',
        data
    })
}
/**
 * 获取 表格列表
 * id=卡片ID(必填)
 */
export function bulletinCardListExcel(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/listExcel',
        method: 'POST',
        data
    })
}
/**
 * 删除表格列表
 *id=卡片ID(必填)&excelId=表格ID(必填)
 */
export function bulletinCardDeleteExcel(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/deleteExcel',
        method: 'POST',
        data
    })
}
/**
 * 保存 卡片表单接口
 *id=卡片ID(必填)&formId=表单ID(必填)&title=表单名称(必填)&documentId=表单数据ID(必填)
 */
export function bulletinCardAddForm(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/addForm',
        method: 'POST',
        data
    })
}
/**
 * 获取 表单列表
 * id=卡片ID(必填)
 */
export function bulletinCardListForm(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/listForm',
        method: 'POST',
        data
    })
}
/**
 * 删除表格列表
 *id=卡片ID(必填)&documentId=表单数据ID(必填)
 */
export function bulletinCardDeleteForm(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/deleteForm',
        method: 'POST',
        data
    })
}
/**
    看板下拉框列表，
 */
export function bulletinSelect(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletin/select',
        method: 'POST',
        data
    })
}
/**
    看板卡片下拉框列表，
 */
export function bulletinCardSelect(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/select',
        method: 'POST',
        data
    })
}
/**
    看板列下拉框列表，
 */
export function bulletinColumnSelect(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinColumn/select',
        method: 'POST',
        data
    })
}
/**
    复制看板，
 */
export function bulletinCopy(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletin/copy',
        method: 'POST',
        data
    })
}

/**
 * 根据卡片模板创建卡片
 * @param {*} data
 * @returns
 */
export function addCardByTpl(data) {
    return request({
        url: SERVICE_PREFIX + '/bulletinCard/instantiate',
        method: 'POST',
        data: qs.stringify(data)
    })
}
/**
 * 加载看板设置数据
 * @param {*} data
 * @returns
 */
export function bulletinLoadSetting(data) {
    return request({
        url: '/app/bulletin/loadSetting',
        method: 'POST',
        data
    })
}
/**
 * 保存看板设置数据
 * @param {*} data
 * @returns
 */
export function bulletinSaveSetting(data) {
    return request({
        url: '/app/bulletin/saveSetting',
        method: 'POST',
        data
    })
}