import { getAdvanceQuerysByDataFilter, isEmpty } from "@/zgg-core/utils";
import sourceList from "./sourceList";
import _ from "lodash";
/**
 * 获取组件值类型
 * @param {*} obj
 * @returns
 */
export function getComponentClass(componentName) {
  if (componentName == "text_area") {
    return "textarea-tip";
  } else if (componentName == "input_number") {
    return "number-tip";
  } else if (componentName == "date_picker") {
    return "date-tip";
  } else if (["address_input", "position_input"].includes(componentName)) {
    return "address-tip";
  } else if (["user_select", "user_list_select"].includes(componentName)) {
    return "member-tip";
  } else if (
    ["department_select", "department_list_select"].includes(componentName)
  ) {
    return "dept-tip";
  } else if (["checkbox_group", "select_checkbox"].includes(componentName)) {
    return "array-tip";
  } else if (["reference_data", "cascade_data"].includes(componentName)) {
    return "reference-tip";
  } else if (componentName == "id") {
    return "id-tip";
  } else {
    return "text-tip";
  }
}

/**
 * 字段对应的文本类型名称
 */
export const fieldClassMap = {
  "textarea-tip": "多行文本",
  "number-tip": "数字",
  "date-tip": "日期",
  "address-tip": "地址",
  "member-tip": "成员",
  "dept-tip": "部门",
  "array-tip": "数组",
  "text-tip": "文本",
  "reference-tip": "关联数据",
  "id-tip": "当前数据",
};

/**
 * 检查节点配置是否正确
 * @param {*} node
 * @returns
 */
export function chkNodeConfig(node, etlList, etlLines) {
  if (node.stageType == "input") {
    if (isEmpty(node.dataSource)) {
      return {
        label: "请先完成节点配置",
        value: "config",
      };
    }
  }

  let fromKeys = etlLines
    .filter((item) => item.toKey == node.key)
    .map((item) => item.fromKey);

  let count;
  if (["union", "join"].includes(node.stageType)) {
    // 追加合并、横向连接
    count = 2;
  } else {
    count = 1;
  }

  let item2 = sourceList.find((item) => item.stageType == node.stageType);

  // 节点连接个数判断
  if (fromKeys.length < count && node.stageType != "input") {
    let label;
    if (node.stageType == "union") {
      label = "请将 2-10 个节点连接至本节点";
    } else if (node.stageType == "join") {
      label = "请将 2 个节点连接至本节点";
    } else {
      label = "请将 1 个节点连接至本节点";
    }
    return {
      label,
      value: "count",
    };
  }

  // 依赖节点配置检查
  let fromNodes = etlList.filter((item) => fromKeys.includes(item.key));
  for (let index = 0; index < fromNodes.length; index++) {
    const fromNode = fromNodes[index];
    let error = chkNodeConfig(fromNode, etlList, etlLines);
    if (error) {
      return {
        label: "请先完成依赖节点的配置",
        value: "prevConfig",
      };
    }
  }

  // 横向连接不允许统一来源数据进行连接合并
  if (["join"].includes(node.stageType)) {
    // 横向连接
    let nodeList = [];
    fromKeys.forEach((key) => {
      let fromNode;
      do {
        if (fromNode) {
          let fromKey = etlLines.find(
            (item) => item.toKey == fromNode.key,
          ).fromKey;
          fromNode = etlList.find((item) => item.key == fromKey);
        } else {
          fromNode = etlList.find((item) => item.key == key);
        }
      } while (fromNode && fromNode.stageType == "filter");
      if (fromNode) {
        nodeList.push(fromNode);
      }
    });

    let inputCount = nodeList.filter(
      (item) => item.stageType == "input",
    ).length;
    if (inputCount == 2) {
      const result = Array.from(
        new Set(nodeList.map((item) => item.dataSource.formId)),
      );
      if (result.length < nodeList.length) {
        return {
          label: " 同一来源的数据无法进行" + item2.title,
          value: "same",
        };
      }
    } else if (inputCount == 0) {
      if (nodeList[0].key == nodeList[1].key) {
        return {
          label: " 同一来源的数据无法进行" + item2.title,
          value: "same",
        };
      }
    }
  }

  // 横向连接——同一类型的字段才能做为连接字段
  if (node.stageType == "join") {
    let isError;
    for (let index = 0; index < node.joinFields.length; index++) {
      const item = node.joinFields[index];
      if (!isEmpty(item.leftField) && !isEmpty(item.rightField)) {
        let leftClass = getComponentClass(item.leftField.componentName);
        let rightClass = getComponentClass(item.rightField.componentName);
        isError = !(
          leftClass == rightClass ||
          (["id", "reference_data", "cascade_data"].includes(
            item.leftField.componentName,
          ) &&
            ["id", "reference_data", "cascade_data"].includes(
              item.rightField.componentName,
            ))
        );

        if (isError) {
          break;
        }
      }
    }
    if (isError) {
      return {
        label: "连接字段的类型不一致",
        value: "diffFieldType",
      };
    }

    let joinCount = 0;
    let hasEmptyField = false;
    let hasDeleteField = false;
    node.joinFields.forEach((field) => {
      // 左右连接字段的存在判断依据是：左右两个字段都存在并且在输入源之中也存在相同的字段
      if (!isEmpty(field.leftField) && !isEmpty(field.rightField)) {
        let leftNode = etlList.find(
          (item) => item.key == field.leftField.collection,
        );
        let rightNode = etlList.find(
          (item) => item.key == field.rightField.collection,
        );
        let leftFieldNames = [];
        let rightFieldNames = [];
        if (leftNode) {
          leftFieldNames = leftNode.fields.map((item) => item.name);
        }
        if (rightNode) {
          rightFieldNames = rightNode.fields.map((item) => item.name);
        }
        if (
          leftFieldNames.includes(field.leftField.name) &&
          rightFieldNames.includes(field.rightField.name)
        ) {
          joinCount++;
        } else {
          hasDeleteField = true;
        }
      } else {
        hasEmptyField = true;
      }
    });
    if (!joinCount) {
      return {
        label: "横向连接至少要有一组连接字段",
        value: "joinEmpty",
      };
    }

    if (hasEmptyField) {
      return {
        label: "请完善横向连接",
        value: "emptyField",
      };
    }
    if (hasDeleteField) {
      return {
        label: "存在被删除字段",
        value: "delete",
      };
    }
  }

  // 分组汇总、行转列节点配置检查
  if (["group", "rowToCol"].includes(node.stageType)) {
    let line = etlLines.find((item) => item.toKey == node.key);
    let names = [];
    if (line) {
      let collection = line.fromKey;
      let fromNode = etlList.find((item) => item.key == collection);
      if (fromNode) {
        names = fromNode.fields.map((item) => item.name);
      }
    }

    if (isEmpty(node.xfields)) {
      if (node.stageType == "rowToCol") {
        return {
          label: "请至少添加一组分组字段",
          value: "xFieldsEmpty",
        };
      }
    } else {
      let xFieldCount = node.xfields.filter((item) =>
        names.includes(item.field.name),
      ).length;
      let deleteCount = node.xfields.filter(
        (item) => !names.includes(item.field.name),
      ).length;
      if (deleteCount) {
        return {
          label: "存在被删除的分组字段，请完善分组字段配置",
          value: "delete",
        };
      }

      if (xFieldCount == 0) {
        //  维度字段已经从来源节点删除

        return {
          label: "请至少添加一组分组字段",
          value: "xFieldsEmpty",
        };
      }

      let error;
      for (let index = 0; index < node.xfields.length; index++) {
        const element = node.xfields[index];
        if (isEmpty(element.title)) {
          error = {
            label: "分组字段的标题不能为空",
            value: "xFieldTitleEmpty",
          };
          break;
        }
      }
      if (error) {
        return error;
      }
    }

    let metrics = [];
    if (node.stageType == "group") {
      metrics = node.metrics;
    } else {
      if (!isEmpty(node.metric)) {
        metrics.push(node.metric);
      }
    }
    if (isEmpty(metrics)) {
      // 指标字段已经从来源中删除
      return {
        label: "请至少添加一组汇总字段",
        value: "metricsEmpty",
      };
    } else {
      let metricsCount = metrics.filter((item) =>
        names.includes(item.field.name),
      ).length;
      let deleteCount = metrics.filter(
        (item) => !names.includes(item.field.name),
      ).length;

      if (deleteCount) {
        return {
          label: "存在被删除的汇总字段，请完善汇总字段配置",
          value: "delete",
        };
      }

      if (metricsCount == 0) {
        return {
          label: "请至少添加一组汇总字段",
          value: "metricsEmpty",
        };
      }

      let error;
      for (let index = 0; index < metrics.length; index++) {
        const element = metrics[index];
        if (isEmpty(element.title)) {
          error = {
            label: "汇总字段标题不能为空",
            value: "metricTitleEmpty",
          };
          break;
        }
      }
      if (error) {
        return error;
      }
    }

    if (node.stageType == "rowToCol") {
      if (isEmpty(node.colField)) {
        return {
          label: "请选择列字段",
          value: "colEmpty",
        };
      } else if (!names.includes(node.colField.name)) {
        return {
          label: "列字段被删除",
          value: "delete",
        };
      }
      if (isEmpty(node.metric)) {
        return {
          label: "请选择值字段",
          value: "valueEmpty",
        };
      } else if (!names.includes(node.metric.field.name)) {
        return {
          label: "值字段被删除",
          value: "delete",
        };
      }
    }
  }

  // 过滤条件
  if (node.stageType == "filter") {
    let advanceQuery = getAdvanceQuerysByDataFilter(node.dataFilter);
    if (node.dataFilter && !isEmpty(advanceQuery)) {
      let line = etlLines.find((item) => item.toKey == node.key);
      let fieldNames = [];
      if (line) {
        let fromKey = line.fromKey;
        let fromNode = etlList.find((item) => item.key == fromKey);
        if (fromNode) {
          fieldNames = fromNode.fields.map((item) => item.name);
        }
      }

      let deleteCount = advanceQuery.filter(
        (item) => !fieldNames.includes(item.key),
      ).length;
      if (deleteCount) {
        return {
          label: "存在被删除字段",
          value: "delete",
        };
      }

      if (
        advanceQuery.filter(
          (item) => !["nil", "nnil"].includes(item.oper) && isEmpty(item.value),
        ).length
      ) {
        return {
          label: "请完善过滤条件查询值",
          value: "empty",
        };
      }
    }

    if (node.stageType == "union") {
      let hasDelete = false;
      node.unionFields.forEach((row) => {
        row.stageFields.forEach((item) => {
          let fromNode = etlList.find((col) => col.key == item.collection);
          if (
            !(
              fromNode &&
              fromNode.fields.findIndex((col) => col.name == item.name) >= 0
            )
          ) {
            hasDelete = true;
          }
        });
      });
      if (hasDelete) {
        return {
          label: "存在被删除字段",
          value: "delete",
        };
      }
    }
  }

  if (node.stageType == "map") {
    if (isEmpty(node.mapFields)) {
      return {
        label: "请先完成节点的配置",
        value: "config",
      };
    }
    let list = node.mapFields.filter((item) => item.fieldType == "formField");
    if (list.length) {
      let hasDelete = false;
      let collection = list[0].collection;
      let fromNode = etlList.find((col) => col.key == collection);
      list.forEach((item) => {
        if (
          !(
            fromNode &&
            fromNode.fields.findIndex((col) => col.name == item.name) >= 0
          )
        ) {
          hasDelete = true;
        }
      });
      if (hasDelete) {
        return {
          label: "存在被删除字段",
          value: "delete",
        };
      }
    }
  }
}

/**
 * 根据节点获取可用字段列表
 * @param {*} node
 * @param {*} etlList
 * @returns
 */
export function getFieldListByNode(node, etlList) {
  return _.cloneDeep(node.fields);
}

/**
 * 生成节点的输入字段
 * @param {*} node
 * @param {*} etlList
 * @param {*} etlLines
 */

export function buildInputFields(node, etlList, etlLines) {
  if (node.stageType == "input") {
    return;
  }
  let fromKeys = etlLines
    .filter((item) => item.toKey == node.key)
    .map((item) => item.fromKey);

  etlList.forEach((item) => {
    if (fromKeys.includes(item.key)) {
      buildNodeFields(item, etlList, etlLines);
    }
  });
  if (node.stageType == "map" && fromKeys.length && isEmpty(node.mapFields)) {
    let fromNode = etlList.find((item) => item.key == fromKeys[0]);
    let fields = _.cloneDeep(fromNode.fields);
    fields.forEach((item) => {
      item.fieldType = "formField";
      item.key = item.name;
      item.title = item.comment;
    });
    node.mapFields = fields;
  }
}

/**
 * 生成节点输出字段
 * @param {*} node 节点
 * @param {*} etlList 节点列表
 * @param {*} etlLines 关系列表
 */
export function buildNodeFields(node, etlList, etlLines) {
  if (chkNodeConfig(node, etlList, etlLines)) {
    // 当前节点未完善不能生成输出字段
    node.fields = [];
    return;
  }

  if (node.stageType == "input") {
    // 输入节点
    let fieldList = _.cloneDeep(node.selectFields);
    if (node.selectSubField && node.selectSubField.jsonFields) {
      fieldList = fieldList.concat(node.selectSubField.jsonFields);
    }

    fieldList.forEach((item) => {
      item.collection = node.key;
      item.component = {
        key: item.component.key,
        name: item.component.name,
        componentName: item.component.componentName,
        title: item.component.title,
        picker: item.component.picker,
        format: item.component.format,
        decimalPrecision: item.component.decimalPrecision,
        form: true,
        tableName: item.component.tableName,
      };
      if (item.component.componentName == "id") {
        item.component.tableName = node.dataSource.formId;
      }
    });
    node.fields = fieldList;
  } else {
    let fromNodes = [];
    etlLines
      .filter((item) => item.toKey == node.key)
      .forEach((item) => {
        let obj = etlList.find((element) => element.key == item.fromKey);
        fromNodes.push(obj);
      });

    if (node.stageType == "group") {
      // 分组节点
      let fieldList = [];
      node.xfields.forEach((item) => {
        let component = _.cloneDeep(item.field.component);

        let picker;
        let format;
        if (item.datePrecision) {
          picker = item.datePrecision.picker;
          format = item.datePrecision.format;
        } else if (item.addressPrecision) {
          picker = item.addressPrecision.picker;
          format = item.addressPrecision.format;
        }

        fieldList.push({
          collection: node.key,
          name: item.field.name,
          comment: item.title,
          componentName: item.field.componentName,
          picker: picker,
          format,
          component: {
            componentName: component.componentName,
            name: item.field.name,
            key: item.field.name,
            picker: picker,
            format,
            title: item.title,
            form: true,
            tableName: component.tableName,
          },
        });
      });
      node.metrics.forEach((item) => {
        fieldList.push({
          collection: node.key,
          name: item.name,
          comment: item.title,
          componentName: "input_number",
          component: {
            key: item.name,
            name: item.name,
            componentName: "input_number",
            title: item.title,
            form: true,
          },
        });
      });

      node.fields = fieldList;
    } else if (node.stageType == "join") {
      // 横向连接
      let fieldList = [];
      let mergedFields = [];
      let joinFields = node.joinFields.filter(
        (item) => !isEmpty(item.leftField) && !isEmpty(item.rightField),
      );
      let buildJoinField = (item) => {
        let key = item.collection + "_" + item.name;
        let obj = {
          collection: node.key,
          name: key,
          comment: item.comment,
          componentName: item.componentName,
          picker: item.picker,
          format: item.format,
          decimalPrecision: item.component.decimalPrecision,
          component: {
            componentName: item.componentName,
            name: key,
            key: key,
            title: item.comment,
            picker: item.picker,
            format: item.format,
            form: true,
            tableName: item.component.tableName,
            decimalPrecision: item.component.decimalPrecision,
          },
        };

        return obj;
      };
      let leftNode = fromNodes[0];
      let rightNoe = fromNodes[1];

      leftNode.fields.forEach((item) => {
        let isJoinField =
          joinFields.findIndex((field) => field.leftField.name == item.name) >=
          0;
        if (!(node.mergeJoinFields && isJoinField)) {
          // 不合并链接或者不是连接字段可以直接加入输出源
          fieldList.push(buildJoinField(item));
        }
      });
      rightNoe.fields.forEach((item) => {
        let isJoinField =
          joinFields.findIndex((field) => field.rightField.name == item.name) >=
          0;
        if (!(node.mergeJoinFields && isJoinField)) {
          // 不合并链接或者不是连接字段可以直接加入输出源
          fieldList.push(buildJoinField(item));
        }
      });

      if (node.mergeJoinFields) {
        let joinType = node.join;

        joinFields.forEach((item) => {
          if (joinType == "right") {
            // 右连接
            mergedFields.push(buildJoinField(item.rightField));
          } else {
            // 内连接
            mergedFields.push(buildJoinField(item.leftField));
          }
        });
        if (joinType == "right") {
          fieldList = fieldList.concat(mergedFields);
        } else {
          fieldList = mergedFields.concat(fieldList);
        }
      }

      // 系统字段_id 当前数据不作为输出字段在横向链接中输出
      fieldList = fieldList.filter((item) => item.componentName != "id");

      node.fields = fieldList;
    } else if (node.stageType == "filter") {
      // 数据过滤节点
      let fromKey = etlLines.find((item) => item.toKey == node.key).fromKey;
      let fromNode = etlList.find((item) => item.key == fromKey);
      let fields = _.cloneDeep(fromNode.fields);
      fields.forEach((item) => {
        item.collection = node.key;
      });
      node.fields = fields;
    } else if (node.stageType == "union") {
      // 追加合并
      let fields = [];
      node.unionFields.forEach((item) => {
        let field = _.cloneDeep(item.field);
        field.collection = node.key;
        field.component.key = field.name;
        field.component.name = field.name;
        fields.push(field);
      });

      fields = fields.filter((item) => item.componentName != "id");
      node.fields = fields;
    } else if (node.stageType == "rowToCol") {
      // 行转列节点
      let fieldList = [];
      node.xfields.forEach((item) => {
        let component = _.cloneDeep(item.field.component);

        let picker;
        let format;
        if (item.datePrecision) {
          picker = item.datePrecision.picker;
          format = item.datePrecision.format;
        } else if (item.addressPrecision) {
          picker = item.addressPrecision.picker;
          format = item.addressPrecision.format;
        }

        fieldList.push({
          collection: node.key,
          name: item.field.name,
          comment: item.title,
          componentName: item.field.componentName,
          picker: picker,
          format,
          decimalPrecision: component.decimalPrecision,
          component: {
            componentName: component.componentName,
            name: item.field.name,
            key: item.field.name,
            picker: picker,
            format,
            title: item.title,
            form: true,
            tableName: component.tableName,
            decimalPrecision: component.decimalPrecision,
          },
        });
      });

      node.colValues.forEach((item) => {
        fieldList.push({
          collection: node.key,
          name: item.name,
          componentName: item.componentName,
          comment: item.comment,
          component: {
            key: item.name,
            name: item.name,
            componentName: item.componentName,
            title: item.comment,
            form: true,
          },
        });
      });
      node.fields = fieldList;
    } else if (node.stageType == "map") {
      // 字段映射
      // let fromKey = etlLines.find(item => item.toKey == node.key).fromKey;
      // let fromNode = etlList.find(item => item.key == fromKey);
      // let fields = _.cloneDeep(fromNode.fields);

      let fields = _.cloneDeep(node.mapFields);
      fields.forEach((item) => {
        item.comment = item.title;
        item.collection = node.key;
        item.component.title = item.title;
        delete item.title;
        delete item.hidden;
        delete item.key;
        delete item.fieldType;
        delete item.rely;
      });
      node.fields = fields;
    } else if (node.stageType == "output") {
      let fromNode = fromNodes[0];
      if (fromNode) {
        let fields = _.cloneDeep(fromNode.fields);
        fields.forEach((item) => {
          item.collection = node.key;
          item.component.title = item.comment;
        });
        fields = fields.filter(
          (item) =>
            !["id", "reference_data", "cascade_data"].includes(
              item.componentName,
            ),
        );
        fields.forEach((item) => {
          item.component.title = item.comment;
        });

        node.fields = fields;
      }
    }
  }
}

/**
 * 清空子节点配置
 * @param {*} node 节点
 * @param {*} etlList 节点数组
 * @param {*} etlLines 节点关系连线
 */
export function clearChildrenConfig(node, etlList, etlLines) {
  let toKeys = etlLines
    .filter((item) => item.fromKey == node.key)
    .map((item) => item.toKey);
  do {
    etlList.forEach((item) => {
      if (toKeys.includes(item.key)) {
        if (item.stageType == "join") {
          // 横向连接
          item.join = "inner";
          item.joinFields = [];
          item.mergeJoinFields = true;
        } else if (item.stageType == "union") {
          // 追加合并
          item.unionFields = [];
        } else if (item.stageType == "group") {
          item.xfields = [];
          item.metrics = [];
        } else if (item.stageType == "filter") {
          // 数据筛选
          item.dataFilter = {
            rel: "and",
            advanceQuery: [],
          };
        } else if (item.stageType == "map") {
          item.mapFields = [];
        } else if (item.stageType == "rowToCol") {
          // 行转列
          item.xfields = [];
          item.colField = null;
          item.colValues = [];
          item.metric = null;
        }
        item.fields = [];
      }
    });
    toKeys = etlLines
      .filter((item) => toKeys.includes(item.fromKey))
      .map((item) => item.toKey);
  } while (toKeys && toKeys.length);
}

/**
 * 修改子节点数据（配置信息）
 * @param {*} node 节点
 * @param {*} etlList 节点数组
 * @param {*} etlLines 节点关系连线
 */
export function changeChildrenData(nodes, etlList, etlLines) {
  let buildKeys = [];
  let allKeys = nodes.map((item) => item.key);
  let insertKeys = (key) => {
    if (buildKeys.includes(key)) {
      return;
    }
    buildKeys.push(key);
  };

  let isBuilded = (key) => {
    if (!allKeys.includes(key)) {
      return true;
    }
    return buildKeys.includes(key);
  };

  allKeys.forEach((key) => {
    insertKeys(key);
    let node = etlList.find((item) => item.key == key);
    if (node) {
      buildNodeFields(node, etlList, etlLines);
    }
  });

  let toKeys = etlLines
    .filter((item) => allKeys.includes(item.fromKey))
    .map((item) => item.toKey);
  do {
    allKeys = allKeys.concat(toKeys);
    toKeys = etlLines
      .filter((item) => toKeys.includes(item.fromKey))
      .map((item) => item.toKey);
  } while (toKeys.length);

  allKeys = [...new Set(allKeys)];

  toKeys = etlLines
    .filter((item) => allKeys.includes(item.fromKey))
    .map((item) => item.toKey);
  do {
    toKeys.forEach((key) => {
      let toNode = etlList.find((item) => item.key == key);
      if (toNode) {
        let fromKeys = etlLines
          .filter((item) => item.toKey == toNode.key)
          .map((item) => item.fromKey);
        let isAllBuild = fromKeys.every(isBuilded);
        if (isAllBuild && !isBuilded(toNode.key)) {
          // 来源节点都是新的配置、并且本节点还未生成过
          buildNodeFields(toNode, etlList, etlLines);
          insertKeys(toNode.key);
        }
      }
    });
    toKeys = etlLines
      .filter((item) => toKeys.includes(item.fromKey))
      .map((item) => item.toKey);
  } while (toKeys && toKeys.length);
}

export const deleteMixins = {
  computed: {
    fieldNames() {
      let names = [];
      let line = this.etlLines.find((item) => item.toKey == this.node.key);
      if (line) {
        let collection = line.fromKey;
        let fromNode = this.etlList.find((item) => item.key == collection);
        if (fromNode) {
          names = fromNode.fields.map((item) => item.name);
        }
      }
      return names;
    },
  },
  methods: {
    isDelete(name) {
      return !this.fieldNames.includes(name);
    },
  },
};

/**
 * 是否已删除
 * @param {*} field
 */
export function isDeleteField(field) {
  if (!(field && field.name)) {
    return false;
  }
  let node = this.etlList.find((item) => item.key == field.collection);
  let names = [];
  if (node) {
    names = node.fields.map((item) => item.name);
  }
  return !names.includes(field.name);
}

/**
 * 检查provide是否有提供inject注入的方法
 * @param {*} name 方法名
 * @param {*} funDesc 方法描述
 * @param {*} popTip 是否弹窗提示
 */
export function isExistFun(name, funDesc, popTip) {
  let type = typeof this[name];
  if (typeof this[name] !== "function") {
    let str;
    if (type === "undefined") {
      str = `请使用provide提供【${name}】${funDesc}`;
    } else {
      str = `【${name}】是【${type}】，请使用provide提供【${name}】${funDesc}，并调整同名的参数`;
    }

    if (popTip) {
      // let error = popMsg;
      // if (isEmpty(str)) {
      //   error = str;
      // }
      this.$message.error(str);
    }
    throw str;
  }
}
