<template>
  <el-dialog
    title="排序规则设置"
    :visible.sync="visible"
    width="600px"
    append-to-body
    :before-close="handleClose()"
    @open="handleDialogOpened"
  >
    <div
      class="has-padding user-list-dialog__content"
      style="padding: 10px; overflow: auto"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 10px;
          padding-top: 5px;
        "
      >
        <el-popover
          placement="bottom-start"
          width="275"
          trigger="click"
          @show="popShow"
        >
          <div>
            <el-input
              size="mini"
              placeholder="搜索"
              prefix-icon="el-icon-search"
              v-model="searchKeyWord"
              clearable
            ></el-input>
            <ul class="field-list_ul" v-if="filterFieldList.length != 0">
              <li
                v-for="field in filterFieldList"
                :class="orderMap[field.name] ? 'added' : ''"
                @click="handleFieldClick(field)"
              >
                {{ field.title }}
              </li>
            </ul>
            <el-empty v-else description="列表为空，换个关键字"></el-empty>
          </div>
          <el-link type="primary" icon="el-icon-plus" slot="reference"
            >添加排序规则</el-link
          >
        </el-popover>

        <el-link type="primary" @click="cleanSort">清空排序</el-link>
      </div>

      <ul class="f-ul" v-if="innerOrderList.length != 0">
        <draggable
          :list="innerOrderList"
          animation="300"
          handle=".el-icon-rank"
          scroll-speed="20"
          fallback-tolerance="5"
          force-fallback="false"
        >
          <li
            v-for="(field, index) in innerOrderList"
            :key="field.orderColumn"
            v-if="field.orderState"
          >
            <i
              class="el-icon-rank"
              style="cursor: pointer; margin-right: 10px"
            ></i>
            <span> {{ field.label }}</span>
            <el-radio-group
              v-model="field.orderState"
              @change="handleFieldOrderChange(field, index)"
              size="small"
            >
              <el-radio-button label="asc">升序</el-radio-button>
              <el-radio-button label="desc">降序</el-radio-button>
            </el-radio-group>
            <el-link
              icon="el-icon-delete"
              :underline="false"
              type="danger"
              @click="deleteOrder(index)"
              style="margin-left: 10px"
            ></el-link>
          </li>
        </draggable>
      </ul>
      <el-empty v-else description="排序规则为空，请添加排序规则"></el-empty>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Draggable from "vuedraggable";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";

export default {
  name: "sort",
  inject: ["getRelationComponentList"],
  components: { Draggable },
  mounted() {},
  computed: {
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
    orderMap() {
      let map = {};
      this.innerOrderList.map((item) => {
        map[item.orderColumn] = item.orderState;
      });
      return map;
    },
    filterFieldList() {
      return this.searchKeyWord == ""
        ? this.innerFieldList
        : this.innerFieldList.filter(
            (order) => order.title.indexOf(this.searchKeyWord) != -1
          );
    },
  },
  props: {
    orderList: {
      type: [Array],
      default() {
        return [];
      },
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fieldList: {
      type: [Array],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      innerOrderList: [],
      selectField: [],
      innerFieldList: [],
      searchKeyWord: "",
    };
  },
  methods: {
    popShow() {
      this.searchKeyWord = "";
    },
    handleFieldClick(field) {
      if (
        this.innerOrderList.findIndex(
          (item) => item.orderColumn == field.name
        ) == -1
      ) {
        this.innerOrderList.push({
          orderColumn: field.name,
          orderState: "asc",
          label: field.title,
        });
      } else {
        this.$message.warning("已存在");
      }
    },
    deleteOrder(index) {
      this.innerOrderList.splice(index, 1);
    },
    cleanSort() {
      this.innerOrderList = [];
    },
    handleFieldOrderChange(val, index) {
      this.$set(this.innerOrderList, index, val);
    },
    handleDialogOpened() {
      let fieldList = this.getRelationComponentList();
      this.innerOrderList = this._.cloneDeep(this.orderList);
      this.innerFieldList = fieldList.filter((item) =>
        checkFieldAbility(item, "isSortable")
      );
    },
    handleClose() {},
    handleConfirm() {
      this.$emit("change", this.innerOrderList);
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.f-ul {
  margin: 0px;
  padding: 1px;
  overflow: auto;
  height: 400px;

  li {
    border-radius: 4px;
    font-size: 14px;
    margin: 0px;
    padding: 0px 10px 0px 10px;
    list-style: none;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
    }

    &.select {
      background-color: #e6f6f4;
    }

    span {
      flex: 1;
    }
  }
}

.field-list_ul {
  max-height: 250px;
  padding: 4px;
  overflow-y: auto;

  li {
    border-radius: 4px;
    font-size: 14px;
    margin: 0px;
    padding: 5px 10px;
    list-style: none;
    // height: 30px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.added {
      cursor: unset;
      color: #c3cdda;

      &:hover {
        box-shadow: unset;
      }
    }

    &:hover {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
