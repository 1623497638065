<template>
  <el-form
    ref="form"
    :model="node"
    :disabled="disabled"
    :rules="rules"
    label-position="top"
  >
    <el-form-item label="选择工作表" prop="formId">
      <el-input
        v-model="node.formTitle"
        @focus="visible = true"
        readonly
      ></el-input>
    </el-form-item>
    <el-form-item label="触发方式" prop="dataChangeType">
      <el-radio-group
        v-model="node.dataChangeType"
        @change="changeDataType"
        style="width: 100%"
      >
        <div style="margin-bottom: 15px">
          <el-radio label="createOrUpdate">数据新增或修改</el-radio>
          <div
            v-if="node.dataChangeType == 'createOrUpdate' && node.formId"
            style="margin-left: 20px; margin-top: 8px"
          >
            <el-select
              :value="triggerFields"
              placeholder="请选择触发字段"
              size="small"
              @change="changeFields"
              :multiple="true"
              style="width: 1005"
            >
              <el-option
                v-for="opt in triggerComponentList"
                :key="opt.name"
                :label="opt.title"
                :value="opt.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <el-radio label="create">数据新增</el-radio>
        </div>
        <div style="margin-bottom: 15px">
          <el-radio label="update">数据修改</el-radio>
          <div
            v-if="node.dataChangeType == 'update' && node.formId"
            style="margin-left: 20px; margin-top: 8px"
          >
            <el-select
              :value="triggerFields"
              placeholder="请选择触发字段"
              size="small"
              @change="changeFields"
              :multiple="true"
              style="width: 1005"
            >
              <el-option
                v-for="opt in triggerComponentList"
                :key="opt.name"
                :label="opt.title"
                :value="opt.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-radio label="delete">数据删除</el-radio>
        </div>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      v-if="['create', 'update'].includes(node.dataChangeType)"
      prop="enableBatchOperator"
    >
      <el-checkbox v-model="node.enableBatchOperator"
        >允许批量操作触发(导入)</el-checkbox
      >
    </el-form-item>
    <div>
      <field-condition
        v-if="node.formId"
        :componentList="componentList"
        :condition.sync="node.condition"
        btnText="添加触发条件"
        abilityKey="inRpaFilter"
        :formId="node.formId"
        :lockValueType="false"
        valueType="custom"
      ></field-condition>
    </div>

    <data-source
      :visible.sync="visible"
      :menus="['form']"
      @changeSource="changeForm"
    ></data-source>
  </el-form>
</template>
<script>
import dataSource from "@/components/data-source";
import { fetchMetaComponents } from "@/api/form";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { mapState } from "vuex";
import {
  isCompleteDataFilter,
  isEmpty,
  isEmptyByKey,
  isIDEmpty,
} from "@zgg-core-utils/utils";
import fieldCondition from "@/components/AdvanceQuery/field-condition";
export default {
  name: "rpa-trigger",
  components: {
    dataSource,
    fieldCondition,
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    triggerFields() {
      if (isEmpty(this.node.triggerFields)) {
        return [];
      }
      return this.node.triggerFields.map((item) => item.fieldName);
    },
  },
  props: {
    node: Object,
    disabled: Boolean,
  },
  data() {
    return {
      visible: false,
      componentList: [],
      triggerComponentList: [],
      rules: {
        formId: [{ required: true, message: "请选择表单" }],
        dataChangeType: [{ required: true, message: "请选择触发方式" }],
      },
    };
  },

  created() {
    if (isEmpty(this.node.condition)) {
      this.$set(this.node, "condition", {
        rel: "and",
        advanceQuery: [],
      });
    }
    if (!isIDEmpty(this.node.formId)) {
      this.fetchMetaComponents(this.node.formId);
    }
  },
  methods: {
    changeDataType(value) {
      if (value == "delete") {
        this.$set(this.node, "enableBatchOperator", false);
      }
      this.$set(this.node, "triggerFields", []);
    },
    changeFields(list) {
      let triggerFields = [];
      list.forEach((name) => {
        let field = this.triggerComponentList.find((item) => item.name == name);
        if (field) {
          triggerFields.push({
            collection: this.node.formId,
            fieldName: field.name,
            fieldTitle: field.title,
            componentName: field.componentName,
            picker: field.picker,
          });
        }
      });
      this.$set(this.node, "triggerFields", triggerFields);
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (
          !isEmptyByKey(this.node, "condition.advanceQuery") &&
          !isCompleteDataFilter(this.node.condition.advanceQuery)
        ) {
          this.$message.error("请完善过滤条件值");
          return;
        }
        callback(valid);
      });
    },
    changeForm({ tableName, tableTitle }) {
      if (this.node.formId != tableName && !isIDEmpty(this.node.formId)) {
        this.$confirm("切换表单将会清除已设置的配置", "提示")
          .then(() => {
            // todo 数据变动触发器，数据变动时其他节点也一起变化
            this.$set(this.node, "formId", tableName);
            this.$set(this.node, "formTitle", tableTitle);
            this.$set(this.node, "triggerFields", []);
            this.$set(this.node, "condition", {
              advanceQuery: [],
              rel: "and",
            });
            this.fetchMetaComponents(tableName);
          })
          .catch(() => {});
      } else {
        this.$set(this.node, "formId", tableName);
        this.$set(this.node, "formTitle", tableTitle);
        this.fetchMetaComponents(tableName);
      }
    },
    fetchMetaComponents(tableName) {
      fetchMetaComponents(tableName).then((res) => {
        this.componentList = res.data.list.filter(
          (item) =>
            checkFieldAbility(item, "inRpaFilter") &&
            item.componentName != "reference_data",
        );

        this.triggerComponentList = res.data.list.filter(
          (item) =>
            (checkFieldAbility(item, "inRpaFilter") &&
              item.componentName != "reference_data") ||
            item.componentName == "json_form",
        );
      });
    },
  },
};
</script>
