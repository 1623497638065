import { conponents } from "@/views/lowCode/form/const";

/**
 * 属性顺序
 */
export const attributeSorts = [
  "attr-title", // 标题
  "attr-description", // 描述信息
  "attr-search", // 搜索设置
  "attr-field-width", // 字段宽度
  "attr-field-width-json-form", // 子表单字段宽度
  "attr-attribute", // 属性
  "attr-date-picker", // 日期选择器类型
  "attr-address-picker", // 地址类型
  "attr-child-form", // 子表单的子字段
  "attr-value", // 默认值
  "attr-number-check", //校验范围
  "attr-placeholder", // 占位内容
  "attr-number-format", // 数字格式化
  "attr-option", // 选项配置(下拉，单选、复选、下拉复选)
  "attr-divider", // 分割线配置
  "attr-tabs", // 选项卡配置
  "attr-sn-rule", // 流水号规则
  "attr-position", //定位
  "attr-grid", // 栅格
  "attr-grid-col", // 单元格
  "attr-reference-data", // 关联数据
  "attr-form-data", // 关联查询
  "attr-include-form", // 内嵌表单
  "attr-parent-data", // 父级组件
  "attr-tabs", // 选项卡
  "attr-options", // 单选、复选、下拉、下拉复选配置
  "attr-json-form", // 子表单
  "attr-button", // 按钮相关设置
  "attr-cascade-data", // 级联组件
];

/**
 * 组件配置信息map
 */
export const componentMap = {
  input: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
    "attr-scan-config",
  ],
  text_area: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
  ],
  input_number: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-number-check",
    "attr-placeholder",
    "attr-number-format",
  ],
  date_picker: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-date-picker",
    "attr-value",
    "attr-placeholder",
  ],
  address_input: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-address-picker",
    "attr-value",
    "attr-placeholder",
  ],
  mobile_input: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
  ],
  divider: ["attr-title", "attr-description", "attr-divider"],
  html: ["attr-title", "attr-description", "attr-attribute"],
  sign_input: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
  ],
  sign_list_input: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
  ],
  sn_input: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-placeholder",
    "attr-sn-rule",
  ],
  image_uploader: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
  ],
  attachment_uploader: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
  ],
  user_select: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
  ],
  user_list_select: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
  ],
  department_select: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
  ],
  department_list_select: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
  ],
  position_input: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-value",
    "attr-placeholder",
    "attr-position",
  ],
  grid: ["attr-title", "attr-description", "attr-grid"],
  grid_col: ["attr-title", "attr-description", "attr-grid-col"],
  include_form: ["attr-title", "attr-description", "attr-include-form"],
  parent_data: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-placeholder",
    "attr-parent-data",
  ],
  tab: ["attr-tabs"],
  radio_group: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-options",
    "attr-value",
  ],
  checkbox_group: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-options",
    "attr-value",
  ],
  select: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-options",
    "attr-value",
  ],
  select_checkbox: [
    "attr-title",
    "attr-description",
    "attr-search",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-options",
    "attr-value",
  ],
  form_data: [
    "attr-title",
    "attr-description",
    "attr-form-data",
    "attr-permission-type",
    "attr-reference-more-config",
  ],
  reference_data: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-reference-data",
    "attr-permission-type",
    "attr-reference-more-config",
  ],
  html_input: [
    "attr-title",
    "attr-description",
    // "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-placeholder",
  ],
  json_form: [
    "attr-title",
    "attr-description",
    "attr-attribute",
    "attr-json-form",
  ],
  reference_data_list: [
    "attr-title",
    "attr-description",
    // "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-reference-data-list",
    "attr-permission-type",
    "attr-reference-more-config",
  ],
  button: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-button",
    // "attr-permission-type",
  ],
  summary_data: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-field-width-json-form",
    "attr-attribute",
    "attr-summary-data",
  ],
  cascade_data: [
    "attr-title",
    "attr-description",
    "attr-field-width",
    "attr-attribute",
    "attr-cascade-data",
  ],
};

/**
 * 组件props内容
 */
export const componentProps = {
  component: Object, // 组件
  params: Object, // 其他参数
  form: Object,
  areaTree: Array,
  dataMode: String, //是否集团数据源
};

/**
 * 根据组件名称数组获取组件对象数组
 * @param names
 * @returns {({container: boolean, extParam: {customWidth: string}, uniqueValue: boolean, defaultValue: string, label: string, title: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, rely: null, array: boolean, json: boolean, componentName: string}|{container: boolean, extParam: {customWidth: string}, defaultValue: string, label: string, title: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, rely: null, array: boolean, json: boolean, componentName: string}|{container: boolean, extParam: {customWidth: string, num: number, format: string}, defaultValue: string, format: string, label: string, title: string, decimalPrecision: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, rely: null, array: boolean, json: boolean, componentName: string}|{container: boolean, form: boolean, readonly: boolean, extParam: {customWidth: string}, array: boolean, json: boolean, label: string, componentName: string, picker: string, title: string, required: boolean}|{container: boolean, optionSource: string, extParam: {customWidth: string}, customOptions: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], label: string, title: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, array: boolean, json: boolean, componentName: string})[]}
 */
export function getCompObjsByNames(names) {
  return conponents.filter((comp) => names.includes(comp.componentName));
}

/**
 * 根据名称获取组件对象
 * @param name
 * @returns {{container: boolean, extParam: {customWidth: string}, uniqueValue: boolean, defaultValue: string, label: string, title: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, rely: null, array: boolean, json: boolean, componentName: string} | {container: boolean, extParam: {customWidth: string}, defaultValue: string, label: string, title: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, rely: null, array: boolean, json: boolean, componentName: string} | {container: boolean, extParam: {customWidth: string, num: number, format: string}, defaultValue: string, format: string, label: string, title: string, decimalPrecision: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, rely: null, array: boolean, json: boolean, componentName: string} | {container: boolean, form: boolean, readonly: boolean, extParam: {customWidth: string}, array: boolean, json: boolean, label: string, componentName: string, picker: string, title: string, required: boolean} | {container: boolean, optionSource: string, extParam: {customWidth: string}, customOptions: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], label: string, title: string, required: boolean, keywordSearched: boolean, form: boolean, readonly: boolean, array: boolean, json: boolean, componentName: string}}
 */
export function getCompObjByName(name) {
  return conponents.find((comp) => name === comp.componentName);
}
