<template>
  <div class="attr-row">
    <div class="attr-label" style="width: 100%">下拉列表中显示字段</div>
    <fields-select
      title="选择在下拉列表中显示的字段"
      v-model="component.tableColumns"
      :columns="componentList"
      :includeJsonForm="false"
    >
      <el-button
        size="mini"
        style="width: 100%; margin-bottom: 5px"
        slot="reference"
        icon="el-icon-plus"
        >添加
      </el-button>
    </fields-select>

    <div
      v-if="component.tableColumns && component.tableColumns.length"
      style="margin: 0px 5px"
    >
      <draggable v-model="component.tableColumns"  handle=".icon-yidong"  animation="300">
      <transition-group>
      <div v-for="(item, index) in component.tableColumns" :key="item.field.name">
        <div class="weui-col">
          <i
            @click="item.showAll = !item.showAll"
            v-if="item.field.componentName == 'json_form'"
            :class="item.showAll ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
            style="font-size: 12px; margin-right: 5px"
          ></i>
          <div class="weui-title">{{ item.title }}</div>
          <div class="weui-col" style="font-size: 12px">
            <i class="iconfont icon-yidong" style="font-size: 12px"></i>
            <i @click="deleteTabelColumn(index, -1)" class="el-icon-delete"></i>
          </div>
        </div>
        <!-- 子表单 -->
        <div
          v-if="item.field.componentName == 'json_form'"
          v-show="item.showAll"
          style="margin-left: 30px"
        >
          <draggable v-model="item.subColumns"  handle=".icon-yidong"  animation="300">
          <transition-group>
          <div
            v-for="(child, cIndex) in item.subColumns"
            :key="child.field.name"
            class="weui-col"
          >
            <div class="weui-title">{{ child.title }}</div>
            <div class="weui-col" style="font-size: 12px">
              <i class="iconfont icon-yidong" style="font-size: 12px"></i>
              <i
                @click="deleteTabelColumn(index, cIndex)"
                class="el-icon-delete"
              ></i>
            </div>
          </div>
          </transition-group>
          </draggable>
        </div>
      </div>
      </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import FieldsSelect from "@/components/FieldsSelect";
import Draggable from "vuedraggable";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
export default {
  name: "attr-select-fields", // 下拉列表中显示字段
  components: { FieldsSelect ,Draggable },
  props: {
    component: Object,
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    componentList() {
      let list = [];
      let columns = this._.cloneDeep(this.columns);
      columns.forEach((item) => {
        if (checkFieldAbility(item, "isSelectField")) {
          if (["form_data", "reference_data"].includes(item.componentName)) {
            item.columns = item.columns.filter(
              (child) =>
                checkFieldAbility(child.field.component, "isSelectField") &&
                child.field.component.componentName != "json_form"
            );
          } else if (item.componentName == "json_form") {
            item.components = item.components.filter(
              (child) =>
                checkFieldAbility(child, "isSelectField") &&
                child.componentName != "reference_data"
            );
          }
          list.push(item);
        }
      });
      return list;
    },
  },
  methods: {
    deleteTabelColumn(index, cIndex) {
      if (cIndex == -1) {
        this.component.tableColumns.splice(index, 1);
      } else {
        this.focomponentrm.tableColumns[index].subColumns.splice(cIndex, 1);
        this.$nextTick(() => {
          if (this.component.tableColumns[index].subColumns.length == 0) {
            this.component.tableColumns.splice(index, 1);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-col {
  display: flex;
  align-items: center;

  .weui-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5;
  }
}

.icon-yidong {
  cursor: move;
}
</style>
