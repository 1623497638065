import { handleDataFilterSet } from "@/views/lowCode/dashboard/dashboard-util";
import { isEmpty } from "@/zgg-core/utils";

export const dashboardMixin = {
  computed: {
    //所有的非容器组件
    componentList() {
      let components = [];
      if (this.dashboard?.components) {
        this.dashboard.components.forEach((row) => {
          if (row.componentName == "dashboard") {
            components = components.concat(row.components);
          } else if (row.componentName == "tab") {
            row.components.forEach((tab) => {
              components = components.concat(tab.components);
            });
          } else {
            components.push(row);
          }
        });
      }
      return components;
    },
  },
  methods: {
    refreshChart(view, parent, dataFilter) {
      if (parent) {
        //仪表盘刷新
        let ref = "chart_container";
        if (parent != "dashboard") {
          this.$refs[ref + parent]?.buildChart(view, dataFilter);
        } else {
          //todo 待优化
          //如果父组件是dashboard，则刷新全部仪表盘
          this.dashboard.components.forEach((item) => {
            if (["dashboard", "tab"].includes(item.componentName)) {
              this.$refs[ref + item.key]?.buildChart(view, dataFilter);
            }
          });
          this.$refs[ref]?.buildChart(view, dataFilter);
        }
      } else if (this.$refs["chart_" + view.key]) {
        this.$refs["chart_" + view.key][0].getData(view, dataFilter);
      }
    },
    fetchDashboardData(components, parent, containerKeys) {
      this.$nextTick(() => {
        let dataFilterSet = handleDataFilterSet(this.componentList);
        components.forEach((item) => {
          if (containerKeys && !containerKeys.includes(item.key)) return;
          if (
            item.dataSource &&
            ![
              "dashboard_filter",
              "html",
              "count_down",
              "title_view",
              "carousel_view",
              "include",
              "button_group",
            ].includes(item.componentName)
          ) {
            let { formId } = item.dataSource;
            let chartDataFilter = dataFilterSet[formId];
            if (chartDataFilter && chartDataFilter[item.key]) {
              this.refreshChart(item, parent, chartDataFilter[item.key]);
            } else {
              this.refreshChart(item, parent);
            }
          }
          if (item.componentName == "count_down") {
            this.refreshChart(item, parent);
          }
        });
      });
    },
  },
};
