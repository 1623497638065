<template>
  <div>
    <template v-if="node.type == 'query_data_one'">
      <div style="font-size: 13px; color: #757575; line-height: 1.2">
        当查找到多个数据时，将按照以下排序规则获得第一条数据。如果未设置规则，返回最近更新的一条数据
      </div>
      <div class="fixed-sort">
        <el-checkbox v-model="ignoreSort"
          >忽略排序规则，随机获取一条</el-checkbox
        >
      </div>
    </template>
    <div>
      <el-select
        v-model="key"
        placeholder="选择字段"
        size="mini"
        style="width: 240px"
      >
        <el-option
          v-for="item in componentList"
          :key="item.name"
          :label="item.title"
          :value="item.name"
        ></el-option>
      </el-select>
      <el-select
        v-model="value"
        :disabled="!key"
        placeholder="选择规则"
        size="mini"
        style="width: 240px; margin-left: 10px"
      >
        <el-option label="升序" value="asc"></el-option>
        <el-option label="降序" value="desc"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
export default {
  props: {
    componentList: Array,
    node: Object,
  },
  computed: {
    ignoreSort: {
      get() {
        return this.node.ignoreSort;
      },
      set(val) {
        this.$set(this.node, "ignoreSort", val);
      },
    },
    key: {
      get() {
        if (!isEmpty(this.node.sortParam)) {
          let arr = Object.keys(this.node.sortParam);
          return arr[0];
        } else {
          return "";
        }
      },
      set(val) {
        let sortParam = {};
        sortParam[val] = "asc";
        this.$set(this.node, "sortParam", sortParam);
      },
    },
    value: {
      get() {
        if (!isEmpty(this.node.sortParam)) {
          let arr = Object.values(this.node.sortParam);
          return arr[0];
        } else {
          return "";
        }
      },
      set(val) {
        let sortParam = {};
        sortParam[this.key] = val;
        this.$set(this.node, "sortParam", sortParam);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.fixed-sort {
  position: absolute;
  top: -30px;
  right: 0;
  line-height: 1;
}
</style>
