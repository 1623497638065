import { render, staticRenderFns } from "./dashboard-carousel-edit.vue?vue&type=template&id=33fcd1d2&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./dashboard-carousel-edit.vue?vue&type=script&lang=js"
export * from "./dashboard-carousel-edit.vue?vue&type=script&lang=js"
import style0 from "./dashboard-carousel-edit.vue?vue&type=style&index=0&id=33fcd1d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33fcd1d2",
  null
  
)

export default component.exports