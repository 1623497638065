<template>
  <div>
    <div v-if="false" class="mb">
      <el-popover
        v-model="filterVisible"
        width="260"
        popper-class="pop-trigger"
      >
        <div class="pop-scroll">
          <template v-if="componentList && componentList.length">
            <div
              class="form-item"
              v-for="item in componentList.filter(
                (item) => item.componentName !== 'reference_data',
              )"
              :key="item.key"
              v-text="item.title"
              :title="item.title"
              @click="checkComponent(item)"
            ></div>
          </template>
          <div v-else class="pop-empty">表单没有组件</div>
        </div>
        <el-button
          slot="reference"
          type="text"
          style="padding: 5px 0px"
          icon="el-icon-plus"
          >添加触发条件
        </el-button>
      </el-popover>
    </div>
    <div v-if="value.condition.advanceQuery.length && false" class="mb">
      <div
        v-for="(item, index) in value.condition.advanceQuery"
        :key="`${item.collection}_${item.key}_${index}`"
        class="weui-row"
      >
        <div style="width: 74px; margin-right: 10px">
          <div v-if="index == 0" class="el-input el-input--small">
            <div class="el-input__inner" style="text-align: center">当</div>
          </div>
          <el-select
            v-else-if="index == 1"
            v-model="value.condition.rel"
            size="small"
            placeholder=""
          >
            <el-option label="并且" value="and"></el-option>
            <el-option label="或者" value="or"></el-option>
          </el-select>
          <div v-else class="el-input el-input--small">
            <div class="el-input__inner" style="text-align: center">
              {{
                value.condition.rel == "and"
                  ? "并且"
                  : value.condition.rel == "or"
                  ? "或者"
                  : value.condition.rel
              }}
            </div>
          </div>
        </div>
        <el-input
          size="small"
          readonly
          :value="renderTitle(item)"
          style="width: 200px"
        ></el-input>
        <!-- 操作符start -->
        <el-select
          v-model="item.oper"
          placeholder="操作符"
          size="small"
          style="margin-left: 10px; width: 140px; margin-right: 10px"
          @change="changeOper(item, $event)"
        >
          <el-option
            v-for="opt in getDisplayQueryOpers(item)"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>
        <!-- 操作符end -->
        <div v-if="item.oper != 'nil' && item.oper != 'nnil'" class="flex">
          <el-cascader
            v-if="item.componentName == 'address_input'"
            :options="areaList(item)"
            style="width: 100%"
            placeholder="请选择地址"
            :props="areaTreeProp"
            :value="getAddr(item.value)"
            @change="changeAddr(item, $event)"
            :key="item.oper"
            size="small"
          >
          </el-cascader>

          <!--          <el-date-picker-->
          <!--            v-else-if="item.componentName == 'date_picker'"-->
          <!--            v-model="item.value"-->
          <!--            placeholder="请选择日期"-->
          <!--            :type="getDatePicker(item)"-->
          <!--            size="small"-->
          <!--            style="width: 100%"-->
          <!--          ></el-date-picker>-->
          <date-picker-interface
            v-else-if="item.componentName == 'date_picker'"
            v-model="item.value"
            placeholder="请选择日期"
            :type="getDatePicker(item)"
            size="small"
            style="width: 100%"
            :picker="item.picker"
            :field="item"
            :hasNow="false"
          >
          </date-picker-interface>

          <template v-else>
            <template
              v-if="
                customsOpts.includes(item.componentName) &&
                item.optionSource == 'custom'
              "
            >
              <el-select
                v-model="item.value"
                size="small"
                style="width: 100%"
                :key="item.key + item.oper"
                :multiple="
                  !(
                    item.oper != 'in' &&
                    item.oper != 'nin' &&
                    item.oper != 'containAny' &&
                    item.oper != 'contain'
                  ) ||
                  ['checkbox_group', 'select_checkbox'].includes(
                    item.componentName,
                  )
                "
                placeholder=""
              >
                <el-option
                  v-for="item in item.customOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
            <template v-else>
              <div
                class="tag-container"
                v-if="
                  !(
                    item.oper != 'in' &&
                    item.oper != 'nin' &&
                    item.oper != 'containAny' &&
                    item.oper != 'contain'
                  ) || item.componentName == 'checkbox_group'
                "
              >
                <div
                  class="tag-scroll"
                  :ref="`tagScroll_${index}`"
                  @mousedown="mousedown"
                >
                  <el-tag
                    type="success"
                    closable
                    v-for="(tag, index2) in item.value"
                    :key="index2"
                    size="mini"
                    @close="closeTag(item.value, index2)"
                    >{{ tag }}
                  </el-tag>
                </div>

                <el-button
                  type="text"
                  @click="addModal(item, index)"
                  size="mini"
                  icon="el-icon-plus"
                  style="margin-left: 5px"
                  >添加筛选值
                </el-button>
              </div>
              <el-input
                v-else-if="item.componentName != ''"
                v-model="item.value"
                class="flex"
                placeholder="自定义值"
                size="small"
              ></el-input>
            </template>
          </template>
        </div>

        <i
          @click="deleteItem(index)"
          style="margin-left: 10px"
          class="el-icon-delete"
        ></i>
      </div>
    </div>
    <zgg-filter
      :componentList="
        componentList.filter((item) => item.componentName !== 'reference_data')
      "
      :dataFilter="value.condition"
    >
      <template slot-scope="{ field }">
        <div class="ass-row">
          <el-tooltip
            v-if="isDelete(field)"
            effect="dark"
            content="字段已删除"
            placement="top"
          >
            <div
              class="el-input el-input--small delete-row"
              style="margin-left: 10px; width: 180px"
            >
              <div
                class="el-input__inner"
                style="text-align: center"
                v-text="renderTitle(field)"
              ></div>
            </div>
          </el-tooltip>

          <el-select
            v-else
            style="margin-left: 10px; width: 180px"
            :value="field.key"
            placeholder=""
            size="small"
            class="overflow"
            @change="checkComponent(field, $event)"
          >
            <el-option
              v-for="opt in componentList.filter(
                (item) => item.componentName !== 'reference_data',
              )"
              :key="opt.name"
              :label="opt.title"
              :value="opt.name"
            >
            </el-option>
          </el-select>
          <!-- 操作符start -->
          <el-select
            v-model="field.oper"
            placeholder="操作符"
            size="small"
            style="margin-left: 10px; width: 140px; margin-right: 10px"
            @change="changeOper(field, $event)"
          >
            <el-option
              v-for="opt in getDisplayQueryOpers(field)"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            >
            </el-option>
          </el-select>
          <!-- 操作符end -->
          <div v-if="field.oper != 'nil' && field.oper != 'nnil'" class="flex">
            <el-cascader
              v-if="field.componentName == 'address_input'"
              :options="areaList(field)"
              style="width: 100%"
              placeholder="请选择地址"
              :props="areaTreeProp"
              :value="getAddr(field.value)"
              @change="changeAddr(field, $event)"
              :key="field.oper"
              size="small"
            >
            </el-cascader>

            <date-picker-interface
              v-else-if="field.componentName == 'date_picker'"
              v-model="field.value"
              placeholder="请选择日期"
              :type="getDatePicker(field)"
              size="small"
              style="width: 100%"
              :picker="field.picker"
              :field="field"
              :hasNow="false"
            >
            </date-picker-interface>

            <template v-else>
              <template
                v-if="
                  customsOpts.includes(field.componentName) &&
                  field.optionSource == 'custom'
                "
              >
                <el-select
                  v-model="field.value"
                  size="small"
                  style="width: 100%"
                  :key="field.key + field.oper"
                  :multiple="
                    !(
                      field.oper != 'in' &&
                      field.oper != 'nin' &&
                      field.oper != 'containAny' &&
                      field.oper != 'contain'
                    ) ||
                    ['checkbox_group', 'select_checkbox'].includes(
                      field.componentName,
                    )
                  "
                  placeholder=""
                >
                  <el-option
                    v-for="opt in field.customOptions"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                <div
                  class="tag-container"
                  v-if="
                    !(
                      field.oper != 'in' &&
                      field.oper != 'nin' &&
                      field.oper != 'containAny' &&
                      field.oper != 'contain'
                    ) || field.componentName == 'checkbox_group'
                  "
                >
                  <div
                    class="tag-scroll"
                    :ref="`tagScroll_${index}`"
                    @mousedown="mousedown"
                  >
                    <el-tag
                      type="success"
                      closable
                      v-for="(tag, index2) in field.value"
                      :key="index2"
                      size="mini"
                      @close="closeTag(field.value, index2)"
                      >{{ tag }}
                    </el-tag>
                  </div>

                  <el-button
                    type="text"
                    @click="addModal(field, index)"
                    size="mini"
                    icon="el-icon-plus"
                    style="margin-left: 5px"
                    >添加筛选值
                  </el-button>
                </div>
                <el-input
                  v-else-if="field.componentName != ''"
                  v-model="field.value"
                  class="flex"
                  placeholder="自定义值"
                  size="small"
                ></el-input>
              </template>
            </template>
          </div>
        </div>
      </template>
    </zgg-filter>
  </div>
</template>

<script>
import test from "@/utils/test";
import { getComponentQueryOper } from "@zgg-core-utils/whiteList";
import { isEmpty } from "@zgg-core-utils/utils";
export default {
  name: "assistant-data-filter",
  props: {
    value: Object,
    componentList: Array,
    areaTree: Array,
  },
  data() {
    return {
      filterVisible: false,
      customsOpts: [
        "radio_group",
        "checkbox_group",
        "select",
        "select_checkbox",
      ],
      visible: false,
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
    };
  },
  methods: {
    isDelete(field) {
      if (isEmpty(field.key)) {
        return false;
      }
      return (
        this.componentList.findIndex((item) => item.name == field.key) == -1
      );
    },
    checkComponent(field, val) {
      let item = this.componentList.find((row) => row.name == val);
      let list = getComponentQueryOper(item.componentName);
      if (item.name == "currentFlowNodeTitles") {
        list = list.filter((row) => row.value != "eq");
      }
      this.$set(field, "key", item.name);
      this.$set(field, "oper", list[0].valuxe);
      this.$set(field, "value", "");
      this.$set(field, "componentName", item.componentName);
      this.$set(field, "optionSource", item.optionSource);
      this.$set(field, "customOptions", item.customOptions);
      this.$set(field, "picker", item.picker);

      this.filterVisible = false;
    },
    changeAddr(item, value) {
      let obj = {};
      let provinceId = value[0];
      let cityId = value[1];
      let countyId = value[2];
      let provinceItem = this.areaTree.find((item) => item.id == provinceId);
      obj.provinceId = provinceId;
      obj.province = provinceItem.name;
      if (cityId) {
        let cityItem = provinceItem.children.find((item) => item.id == cityId);
        obj.cityId = cityId;
        obj.city = cityItem.name;
        if (countyId) {
          let countyItem = cityItem.children.find(
            (item) => item.id == countyId,
          );
          obj.countyId = countyId;
          obj.county = countyItem.name;
        }
      }
      this.$set(item, "value", obj);
    },
    areaList(obj) {
      let list = [];
      let $item = this.componentList.find((item) => item.name == obj.key);
      if (!$item) {
        return list;
      }
      let picker = $item.picker;
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
    getAddr(value) {
      let arr = [];
      if (value) {
        if (value.provinceId) {
          arr.push(value.provinceId);
        }
        if (value.cityId) {
          arr.push(value.cityId);
        }
        if (value.countyId) {
          arr.push(value.countyId);
        }
      }
      return arr;
    },
    getDisplayQueryOpers(field) {
      if (field.componentName) {
        let list = getComponentQueryOper(field.componentName);
        if (field.key == "currentFlowNodeTitles") {
          list = list.filter((row) => row.value != "eq");
        }
        return list;
      }
      return [];
    },
    changeOper(item) {
      if (
        !(
          item.oper != "in" &&
          item.oper != "nin" &&
          item.oper != "containAny" &&
          item.oper != "contain" &&
          item.oper != "between"
        ) ||
        ["checkbox_group", "select_checkbox"].includes(item.componentName)
      ) {
        this.$set(item, "value", []);
        item.value = [];
      } else if (item.oper == "dynamic") {
        this.$set(item, "value", {
          timeMode: "now",
        });
      } else {
        this.$set(item, "value", "");
      }
    },
    getDatePicker(item) {
      let obj = this.componentList.find((a) => a.name == item.key);
      if (obj && obj.picker != "date_minute") {
        return obj.picker;
      }
      return "datetime";
    },
    renderTitle(item) {
      let $item = this.componentList.find((obj) => obj.name == item.key);
      if ($item) {
        return $item.title;
      }

      return item.key;
    },
    mousedown(event) {
      event.preventDefault();
      document.addEventListener("mousemove", this.mousemove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.mouseup, {
        passive: false,
      });
      let clientWidth = event.currentTarget.clientWidth;
      let scrollWidth = event.currentTarget.scrollWidth;

      this.down = scrollWidth > clientWidth;
      if (this.down) {
        this.scrollPosition = {
          startX: event.clientX,
          scrollLeft: event.currentTarget.scrollLeft,
          maxLeft: scrollWidth - clientWidth,
          $el: event.currentTarget,
        };
      }
    },
    mouseup(event) {
      if (event) {
        event.preventDefault();
      }
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.down = false;
    },
    mousemove(event) {
      event.preventDefault();
      if (!this.down) {
        console.log("jjkk");
        return;
      }

      let endX = event.clientX;
      let scrollLeft =
        this.scrollPosition.scrollLeft + this.scrollPosition.startX - endX;
      if (scrollLeft > this.scrollPosition.maxLeft) {
        scrollLeft = this.scrollPosition.maxLeft;
      } else if (scrollLeft < 0) {
        scrollLeft = 0;
      }

      this.scrollPosition.$el.scrollLeft = scrollLeft;
    },
    deleteItem(index) {
      this.value.condition.advanceQuery.splice(index, 1);
    },
    closeTag(list, index) {
      this.$delete(list, index);
    },
    addModal(item, index) {
      this.$prompt("请输入筛选值", "筛选值", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (value) => {
          return !test.isEmpty(value);
        },
        inputErrorMessage: "请输入筛选值",
      })
        .then(({ value }) => {
          if (!item.value) {
            item.value = [];
          }
          item.value.push(value);
          this.$nextTick(() => {
            let $tagScroll = this.$refs["tagScroll_" + index][0];
            let scrollLeft = $tagScroll.scrollWidth - $tagScroll.clientWidth;
            $tagScroll.scrollLeft = scrollLeft;
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger-title {
  line-height: 30px;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .trigger-edit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.05);
    display: none;
  }

  &:hover {
    color: rgba($color: #333, $alpha: 0.45);

    .trigger-edit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      cursor: pointer;
    }
  }
}

.weui {
  display: flex;
  align-items: center;
}

.trigger-label {
  color: #5e6d82;
  font-size: 14px;
}

.trigger-value {
  background-color: #f1f1f1;
  padding: 5px 10px;
  font-size: 14px;
  margin-left: 10px;
}

.replace {
  font-size: 14px;
  color: #409eff;
  cursor: pointer;
  margin: 0px 5px;
}

.trigger-select {
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  overflow: hidden;

  .inner-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    font-size: 14px;
  }
}

.mb {
  margin-bottom: 12px;
}

.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .form-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background-color: rgba($color: #409eff, $alpha: 0.5);
      color: #ffffff;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.2);
    }
  }
}

.weui-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}

.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.flex {
  flex: 1;
  overflow: hidden;
}

.footer {
  border-top: solid 1px #e0e0e0;
  padding: 10px;
}
.ass-row {
  display: flex;
  flex: 1;
}
</style>
<style lang="scss">
.pop-trigger {
  &.el-popover {
    padding: 0;
  }
}
</style>
