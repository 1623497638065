<template>
  <div
    class="flow-item"
    :class="node.nodeClass"
    :style="`left:${node.left}px;top:${node.top}px;`"
  >
    <start-icon v-if="node.nodeName == 'start'"></start-icon>
    <end-icon v-else-if="node.nodeName == 'end'"></end-icon>
    <copy-icon v-else-if="node.nodeName == 'cc'"></copy-icon>
    <child-icon v-else-if="node.nodeName == 'subflow'"></child-icon>
    <flow-icon v-else></flow-icon>
    <div class="title" v-text="node.title"></div>
    <div v-if="isShowMore" class="node-detail">
      <div>
        <span class="title">节点名称</span
        ><span class="cont">{{ node.title }}</span>
      </div>
      <div>
        <span class="title">{{
          node.nodeName == "cc" ? "抄送人" : "责任人"
        }}</span
        ><span class="user-name cont" v-text="userList"></span>
      </div>
    </div>
  </div>
</template>
<script>
import startIcon from "./startIcon.vue";
import endIcon from "./endIcon.vue";
import flowIcon from "./flowIcon.vue";
import copyIcon from "./copyIcon.vue";
import childIcon from "./childIcon.vue";
import { mapGetters } from "vuex";
export default {
  components: { startIcon, endIcon, flowIcon, copyIcon, childIcon },
  props: {
    node: Object,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    isShowMore() {
      if (this.node) {
        return (
          this.node.nodeClass == "is-ing" ||
          this.node.nodeName == "work" ||
          this.node.nodeName == "cc"
        );
      }
      return false;
    },
    userList() {
      if (
        this.node.nodeClass == "is-ing" ||
        this.node.nodeName == "work" ||
        this.node.nodeName == "cc"
      ) {
        if (this.node.nodeName == "start") {
          return this.currentUser.name;
        } else {
          let str = "";
          if (this.node.nodeUsers.userInfoList) {
            this.node.nodeUsers.userInfoList.forEach((user) => {
              if (str != "") {
                str += "，";
              }
              str += user.name;
            });
          }
          if (this.node.nodeUsers.roleInfoList) {
            this.node.nodeUsers.roleInfoList.forEach((user) => {
              if (str != "") {
                str += "，";
              }
              str += user.name;
            });
          }
          return str;
        }
      }
      return;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.flow-item {
  padding: 5px;
  width: 150px;
  box-sizing: border-box;
  border: solid 1px #e6e6e6;
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  z-index: 5;
  height: 34px;
  &:hover {
    z-index: 10;
    .node-detail {
      display: block;
    }
  }
  .node-detail {
    position: absolute;
    top: 35px;
    left: -10px;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 1px #e6e6e6;
    min-width: 170px;
    max-width: 280px;
    padding: 8px;
    font-size: 13px;
    line-height: 1.5;
    color: #333;
    display: none;
    .user-name {
      color: #333;
    }
    .title {
      color: #a5adba;
      padding-right: 6px;
    }
    .cont {
      color: #172b4d;
    }
  }
  &:hover {
    background-color: rgba($color: var(--zgg-primary-color), $alpha: 0.2);
    .more {
      visibility: visible;
    }
  }
  &.is-passed {
    border-color: #0db3a6;
    background-color: #eefffe;
    &:hover {
      background-color: #eefffe;
    }
    ::v-deep {
      svg {
        path {
          fill: #666666;
        }
      }
    }
  }
  &.is-lose {
    border-color: #dcdcdc;
    background-color: #f0f0f0;
    &:hover {
      background-color: #f0f0f0;
    }
    ::v-deep {
      svg {
        path {
          fill: #333333;
        }
      }
    }
  }
  &.is-ing {
    border-color: #248af9;
    background-color: #248af9;
    color: #ffffff;
    &:hover {
      background-color: #248af9;
    }
    ::v-deep {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }

  .title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
  }
}
.more {
  transform: rotate(90deg);
  visibility: hidden;
  margin-left: 5px;
}
.action-menu {
  .action-item {
    text-align: center;
    cursor: pointer;
    line-height: 1.5;
    padding: 0px 10px;
    &:hover {
      background-color: rgba($color: var(--zgg-primary-color), $alpha: 0.2);
    }
  }
}
</style>
<style lang="scss">
.pop-over {
  min-width: auto !important;
  padding: 5px 0;
}
</style>
