/**
 * @typedef rpaVersionStatus
 * @property {string} design 设计中
 * @property {string} enable 使用中
 * @property {string} history 历史
 */
/**
 * 智能助手2.0流程状态-map对象 key:title 对应中文名称
 * @type {rpaVersionStatus}
 */
export const rpaVersionStatusMap = {
  /**设计中 */
  design: "设计",
  /**使用中 */
  enable: "使用",
  /**历史 */
  history: "历史",
};

/**
 * 智能助手流程版本状态-枚举
 * @type {rpaVersionStatus}
 */
let rpaVersionStatus = {};
Object.keys(rpaVersionStatusMap).forEach((key) => {
  rpaVersionStatus[key] = key;
});

/**
 * 智能助手流程状态-key:value 对应keyValue值
 * @type {rpaVersionStatus}
 */
export const RPA_VERSION_STATUS = rpaVersionStatus;

/**
 * 智能助手2.0节点类型
 * @typedef rpaNodeType
 * @property {string} rigger_form_data_change 数据变动触发器
 * @property {string} trigger_timer_once 定时单次触发器
 * @property {string} trigger_timer_cron 定时重复触发器
 * @property {string} trigger_timer_field 按日期字段定时触发器
 * @property {string} trigger_webhook webhook触发
 * @property {string} trigger_click 按钮点击触发器
 * @property {string} trigger_subflow_collection 表单子流程触发器
 *
 *
 */

/**
 * 智能助手2.0节点类型map对象-key:title中文名称对应值
 * @type {rpaNodeType}
 */
export const rpaNodeTypeMap = {
  /**数据变动触发器 */
  rigger_form_data_change: "数据变动",
  /**定时单次触发器 */
  trigger_timer_once: "定时单次",
  /**定时重复触发器 */
  trigger_timer_cron: "定时重复",
  /**按日期字段定时 */
  trigger_timer_field: "按日期字段定时",
  /**webhook触发 */
  trigger_webhook: "Webhook",
  /**点击触发(表单数据) */
  trigger_click: "点击触发",
  /**表单子流程 */
  trigger_subflow_collection: "表单子流程",
};
/**
 * @type {rpaNodeType}
 */
let rpaNodeType = {};
Object.keys(rpaNodeTypeMap).forEach((key) => {
  rpaNodeType[key] = key;
});

/**
 * 智能助手2.0-节点类型key-value
 * @type {rpaNodeType}
 *
 */
export const RPA_NODE_TYPE = rpaNodeType;

/**
 * 流程参数
 * @typedef flowParam
 * @property {string} componentName 组件类型
 * @property {string} name 参数名称 主键
 * @property {string} title 参数标题
 * @property {string} [picker] 组件精度，适用于address date_picker
 *
 */

/**
 * 流程配置对象
 * @typedef flowConfig
 * @property {number} limitChainCount 限制递归调用次数,不超过1000次，默认10次
 * @property {boolean} loopTypeEnablePerMinute 定时循环最小间隔开放到每分钟
 * @property {number} limitUpdateSize 更新数据节点限制记录数,不超过10000条,默认1000条
 * @property {Array<flowParam>} flowParams 流程参数列表
 */

/**
 * 节点连接线
 * @typedef flowLine
 * @property {string} fromKey 起始节点
 * @property {string} toKey 终止节点
 */

/**
 * @typedef executeType 节点运行方式-智能助手2.0 不传默认并行
 * @property {string} serial 串行
 * @property {string} parallel 并行
 */

/**
 * 节点运行方式map对象-key:title中文名称
 * @type {executeType}
 */
export const executeTypeMap = {
  serial: "串行",
  parallel: "并行",
};

/**
 * @type {executeType}
 */
let executeType = {};
Object.keys(executeTypeMap).forEach((key) => {
  executeType[key] = key;
});

/**
 * @type {executeType} 节点运行方式
 */
export const EXECUTE_TYPE = executeType;

/**
 * @typedef executeTypeEnum
 * @type {string}
 * @enum {executeType}
 */

/**
 * 智能助手2.0-节点
 * @typedef rpaNode
 * @property {string} key 节点id
 * @property {string} title 节点名称
 * @property {string} type 节点类型
 * @property {executeTypeEnum} executeType 运行方式
 */

/**
 * 智能助手2.0流程flow对象
 * @typedef rpaFlow
 * @property {rpaNode} trigger 触发器
 * @property {Array<rpaNode>} nodes 动作节点
 * @property {Array<flowLine>} lines 节点连线
 * @property {flowConfig} [config] 流程配置
 *
 */

/**
 * 智能助手2.0流程对象
 * @typedef robotWorkflow
 * @property {string} id 流程id
 * @property {string} robotId 精灵id
 * @property {rpaFlow} rpaFlow 流程对象
 * @property {string} triggerType 触发器类型
 * @property {string} version 版本
 * @property {string} versionDesc 版本描述
 * @property {string} versionStatus 版本状态
 *
 */

/**
 * @typedef workflow 流程data数据返回对象
 * @property {robotWorkflow} workflow
 */

/**
 * @template T
 * @typedef Page
 * @property {number} pageNumber 当前页码
 * @property {number} pageSize 每页记录数
 * @property {number} totalRow 总记录
 * @property {number} totalPage 总页数
 * @property {Array<T>} list 列表数据
 */

/**
 * 智能助手分页对象
 * @typedef robotPage
 * @property {Page<robotWorkflow>} page
 */
