<template>
  <div class="dialog" v-show="visible">
    <div class="d-header">
      <div style="font-size: 14px">执行日志</div>
      <i @click="close" style="cursor: pointer" class="el-icon-close"></i>
    </div>
    <div class="d-log">
      <el-table :data="list" highlight-current-row style="width: 100%">
        <el-table-column
          header-align="center"
          align="center"
          prop="operateTime"
          width="180"
          label="操作时间"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="flowInTime"
          width="180"
          label="流入节点时间"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="userName"
          width="180"
          label="执行人"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="actionType"
          label="执行类型"
        >
          <template slot-scope="scope">
            <span v-text="getTitle(scope.row)"></span>
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          align="center"
          prop="success"
          label="执行结果"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.success">执行成功</span>
            <span v-else>执行失败</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadMore"
      />
    </div>
  </div>
</template>
<script>
import { robotWorkflowLogList } from "./api";

import Pagination from "@/components/Pagination/index";
import { nodeTypeList } from "./loopTypeEnums";
export default {
  components: { Pagination },

  data() {
    return {
      visible: false,
      list: [],
      page: {
        pageNumber: 1,
        pageSize: 20,
        totalRow: 0,
        totalPage: 0,
      },
    };
  },
  methods: {
    getTitle(row) {
      let item = nodeTypeList.find((item) => item.value == row.nodeType);
      if (item) {
        return item.label;
      }
    },
    close() {
      this.visible = false;
    },
    open(workflowDataId) {
      this.workflowDataId = workflowDataId;
      this.visible = true;
      this.loadingList(1);
    },
    loadMore({ page, limit }) {
      this.page.pageSize = limit;
      this.loadingList(page);
    },
    loadingList(pageNumber = 1) {
      robotWorkflowLogList({
        pageNumber: pageNumber,
        pageSize: this.page.pageSize,
        masterRobotWorkflowDataId: this.workflowDataId,
      }).then((res) => {
        let page = res.data.page;
        this.page.pageNumber = page.pageNumber;
        this.page.pageSize = page.pageSize;
        this.page.totalRow = page.totalRow;
        this.page.totalPage = page.totalPage;
        this.list = page.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1010;
  width: 100%;
  height: 100%;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
  .d-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 40px;
    background-color: #ffffff;
  }
  .d-log {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
</style>
