<template>
  <div>
    <el-table
        v-if="relationList.length!=0"
        :data="relationList"
        :show-header="false"
        size="mini"
        style="width: 100%">

      <el-table-column
      >
        <template slot-scope="scope">
          <div style="width: 100%;display: flex" @click.stop="handleRelationClick(scope.row)">
            <span style="color: #5f6e8e;font-size: 14px;font-weight: 600;width: 45px">
              {{ scope.row.typeLabel }}：
            </span>
            <div style="flex: 1">
              <el-link type="primary" :disabled="!isEdit" @click.stop="handleRelationClick(scope.row)">
                {{ scope.row.title }}
              </el-link>
            </div>
            <span v-if="scope.row.type=='form' || scope.row.type=='excel'">
                 <i class="el-icon-finished"
                    :style="scope.row.data.filled?{color: '#67C23A'}:{color:'#C8C8C8'}"
                    style="font-size: 20px"
                 ></i>
            </span>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :fullscreen="true"
        :title="relationDialogTitle"
        append-to-body
        :visible.sync="relationDialogVisible"
        @close="updateCard"
    >
      <iframe :src="relationIframeSrc" frameborder="0" width="100%" style="height:calc(100vh - 50px)"></iframe>
    </el-dialog>
    <el-dialog :title="previewExcelTitle" :fullscreen="true"
               :close-on-click-modal="false"
               append-to-body
               :visible.sync="excelPreviewDialogVisible"
               @close="handleExcelDestroy"
               destroy-on-close
    >
      <div style="height:calc(100vh - 50px - 70px)">
        <excel-render ref="excelRender"/>
      </div>
      <div slot="footer">
        <el-button-group style="margin-bottom: 5px">
          <el-button :loading="excelSaveLoading" type="primary" @click="handleExcelSave()">
            保存
          </el-button>
          <el-button type="warning" @click="handleExcelClose()">
            关闭
          </el-button>
        </el-button-group>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {excelSaveContent} from "@/api/excelRepository";
import axios from "axios";
import ExcelRender from "./excelRender";
import {bulletinCardDetail} from "@/api/boardManage";

export default {
  name: "relationList",
  components: {
    ExcelRender,
  },
  props: {
    cardId: String | Number,
    formList: Array,
    excelList: Array,
    bulletinAndPlanList: Array,
    videoList: Array,
    isEdit: {
      type: Boolean,
      default() {
        return true;
      },
    },

  },
  mounted() {
    let self = this;
    window.addEventListener('message', function (e) {
      if (e.data.status === "success") {
        self.relationDialogVisible = false
      }
    })
  },
  data() {
    return {
      currentVideo: {},
      previewVideoDialogVisible: false,
      typeEnums: {
        "bulletin": "看板",
        "plan": "整编",
        "video": "视频",

      },
      relationDialogVisible: false,
      relationIframeSrc: "",
      relationDialogTitle: "提示",
      excelPreviewDialogVisible: false,
      currentExcel: {},
      previewExcelTitle: "",
      excelSaveLoading: false
    }
  },
  computed: {
    relationList() {
      let tempRelationList = []

      let tempFormList = this.formList && this.formList.map(item => {
        return {
          title: item.title,
          type: "form",
          typeLabel: "表单",
          data: item
        }
      }) || []
      let tempExcelList = this.excelList && this.excelList.map(item => {
        return {
          title: item.title,
          type: "excel",
          typeLabel: "表格",
          data: item
        }
      }) || []
      let tempBulletinAndFormList = this.bulletinAndPlanList && this.bulletinAndPlanList.map(item => {
        return {
          title: item.title,
          type: item.recordType,
          typeLabel: this.typeEnums[item.recordType],
          data: item
        }
      }) || []
      tempRelationList = tempRelationList.concat(tempFormList, tempExcelList)
      return tempRelationList
    }
  },
  methods: {
    updateCard() {
      this.$emit("updateCard")
    },
    handleRelationExcelClick(row) {
      this.currentExcel = row
      this.previewExcelTitle = row.title
      axios.get(row.content).then(resp => {
        // this.$refs["excelRender"].handleReadExcel(resp.data)
        this.excelPreviewDialogVisible = true
        this.$nextTick(() => {
          this.$refs["excelRender"].handleReadExcel(resp.data)
        })
      }).catch(err => {

      })
    },
    /**
     * 处理表格关闭的事件回调
     * 1、让表格渲染器推出编辑模式，否则会出现填写框残留
     * 2、调用表格渲染器的销毁方法
     * 3、将当前容器中的表格视图模式调整为false
     */
    handleExcelClose() {

      this.$refs.excelRender.exitEditMode()
      this.$refs.excelRender.handleDestroy()
      this.excelPreviewDialogVisible = false
      // this.excelViewMode = false
    },
    //处理表格保存
    handleExcelSave() {
      this.excelSaveLoading = true
      let content = this.$refs.excelRender.getExcelData()
      let params = {
        content: JSON.stringify(content),
        id: this.currentExcel.id,
        cellProp: []
      }
      excelSaveContent(params).then(resp => {
        this.$message.success("保存成功")
        this.excelPreviewDialogVisible = false
        this.excelSaveLoading = false
        this.updateCard()
      }).catch(err => {
        this.excelSaveLoading = false
      })
    },
    handleExcelDestroy() {
      this.$refs.excelRender.exitEditMode()
      this.$refs.excelRender.handleDestroy()
    },
    handleRelationClick(row) {
      console.log(row)
      if (!this.isEdit) return
      let type = row.type
      let title = row.title
      this.relationDialogTitle = title
      if (type == 'bulletin') {
        const {href} = this.$router.resolve({
          path: "/redirect/board",
          query: {
            bulletinId: row.data.recordId,
          },
        });
        // window.open(href, "_blank")
        this.relationIframeSrc = href
        this.relationDialogVisible = true

      }
      if (type == 'plan') {
        const {href} = this.$router.resolve({
          path: "/redirect/plan",
          query: {
            planId: row.data.recordId,
            type: "view"
          },
        });
        // window.open(href, "_blank")
        this.relationIframeSrc = href
        this.relationDialogVisible = true
      }
      if (type == 'form') {
        const {href} = this.$router.resolve({
          path: `/redirect/online/${row.data.formId}/edit`,
          query: {
            cardId: this.cardId,
            id: row.data.documentId,
            routeNotBack: true
          }
        });
        this.relationIframeSrc = href
        this.relationDialogVisible = true
      }
      if (type == 'excel') {
        this.handleRelationExcelClick(row.data)
      }
      if (type == 'video') {
        this.handleRelationVideoClick(row.data)
      }
    },
    //todo 表单点击
    handleRelationFormClick(row) {

    },
    handleRelationVideoClick(video) {
      this.currentVideo = video
      this.previewVideoDialogVisible = true
    }
  }
}
</script>

<style scoped>

</style>
