<template>
  <el-popover v-model="visible" popper-class="special-pop">
    <el-button slot="reference" type="text" size="small" icon="el-icon-plus"
      >添加字段</el-button
    >
    <div>
      <div style="padding: 10px 10px 0">
        <el-input
          v-model="keyword"
          placeholder="关键字"
          prefix-icon="el-icon-search"
          size="small"
        ></el-input>
      </div>
      <div class="special-list">
        <div
          v-for="item in components"
          :key="item.key"
          class="field-item"
          :class="{ disabled: disabled(item.name) }"
          v-text="item.title"
          @click="rowClick(item.name)"
        ></div>
      </div>
    </div>
  </el-popover>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
export default {
  props: {
    componentList: Array,
    specialFieldValueRule: Object,
    displayFieldType: String,
  },
  computed: {
    components() {
      let list = [];
      let callback = (componentList) => {
        componentList.forEach((item) => {
          if (item.form) {
            list.push(item);
          } else if (item.container) {
            callback(item.components);
          }
        });
      };
      callback(this.componentList);

      if (!isEmpty(this.keyword)) {
        return list.filter((item) => item.title.indexOf(this.keyword) >= 0);
      }
      return list;
    },
  },
  data() {
    return {
      visible: false,
      keyword: "",
    };
  },
  methods: {
    rowClick(name) {
      if (this.disabled(name)) {
        return;
      }
      let specialFieldValueRule = this._.cloneDeep(this.specialFieldValueRule);
      if (isEmpty(specialFieldValueRule)) {
        specialFieldValueRule = {};
      }
      if (isEmpty(specialFieldValueRule[this.displayFieldType])) {
        specialFieldValueRule[this.displayFieldType] = [];
      }
      specialFieldValueRule[this.displayFieldType].push(name);
      this.$emit("update:specialFieldValueRule", specialFieldValueRule);
    },
    disabled(name) {
      let disabled = false;
      if (!isEmpty(this.specialFieldValueRule)) {
        Object.values(this.specialFieldValueRule).forEach((list) => {
          if (list.includes(name)) {
            disabled = true;
          }
        });
      }
      return disabled;
    },
  },
};
</script>
<style lang="scss" scoped>
.special-list {
  max-height: 300px;
  overflow: auto;
}
.field-item {
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  &.disabled {
    color: #a4a1a1;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
<style lang="scss">
.special-pop {
  padding: 0;
}
</style>