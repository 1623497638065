<template>
  <div>
    <rpaFieldItem
      v-for="(item, index) in fields"
      :key="item.tableField.fieldName"
      :field="item"
      :componentList="fieldList"
      :type="type"
      :formId="formId"
      :abilityKey="abilityKey"
      :fieldConfigKey="fieldConfigKey"
      @del="del(index)"
      :disabled="disabled"
      :node="node"
    >
    </rpaFieldItem>

    <div>
      <el-popover
        popper-class="pop-trigger"
        v-model="visible"
        width="260"
        trigger="click"
        :disabled="!isEdit"
      >
        <div class="pop-scroll" v-clickoutside="handleClose">
          <template v-if="fieldList && fieldList.length">
            <div
              v-for="item in fieldList"
              :key="item.key"
              v-text="item.title"
              class="form-item"
              :class="{ disabled: isDisabeld(item.name) }"
              @click="checkComponent(item)"
            ></div>
          </template>
          <div v-else class="pop-empty">表单没有组件</div>
        </div>
        <el-button
          @click.stop=""
          slot="reference"
          type="text"
          icon="el-icon-plus"
          :disabled="!isEdit || disabled"
          >{{ btnText }}</el-button
        >
      </el-popover>
    </div>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import rpaFieldItem from "./rpa-field-item";
import { isEmpty, isIDEmpty } from "@/zgg-core/utils";
export default {
  directives: { Clickoutside },
  props: {
    abilityKey: String,
    fieldConfigKey: String,
    formId: String,
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return "filter"; // filter-过滤,value-赋值 ,
      },
    },

    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    /** 添加按钮文件 */
    btnText: {
      type: String,
      default() {
        return "添加条件";
      },
    },
    disabled: Boolean,
    node: Object,
  },
  computed: {
    fieldList() {
      return this.componentList.filter(
        (item) =>
          checkFieldAbility(item, this.abilityKey) ||
          item.componentName == "json_form",
      );
    },
    isEdit() {
      if (this.node.dataSource == "node_data") {
        return !isEmpty(this.node.nodeKey);
      } else {
        return !isIDEmpty(this.formId);
      }
    },
  },
  components: { rpaFieldItem },
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    del(index) {
      this.$delete(this.fields, index);
      // this.fields.splice(index, 1);
    },
    checkComponent(item) {
      if (this.isDisabeld(item.name)) {
        return;
      }
      let referenceTableName;
      if (item.componentName == "json_form") {
        referenceTableName = item.tableName;
      }

      // todo 需要根据关联类型来赋值
      if (
        ["reference_data", "json_form", "cascade_data"].includes(
          item.componentName,
        ) &&
        item.tableName
      ) {
        referenceTableName = item.tableName;
      }

      let tableField = {
        collection: this.formId,
        fieldName: item.name,
        fieldTitle: item.title,
        componentName: item.componentName,
        optionSource: item.optionSource,
        customOptions: item.customOptions,
        referenceTableName,
      };
      if (item.picker) {
        tableField.picker = item.picker;
      }
      if (item.extParam) {
        tableField.extParam = item.extParam;
      }

      // todo 需要根据关联类型来赋值
      if (item.componentName == "reference_data" && item.tableName) {
        tableField.referenceTableName = item.tableName;
      }
      let fields = this.fields;

      fields.push({
        tableField,
        valueType: "nodeField",
        value: "",
        nodeField: {
          nodeKey: "",
          collection: "", //这个节点的字段来自某个表单
          name: "",
          title: "",
          componentName: "",
        },
      });
      this.visible = false;
    },
    handleClose() {
      this.visible = false;
    },
    isDisabeld(name) {
      return (
        this.fields.findIndex((item) => item.tableField.fieldName == name) >= 0
      );
    },
    datePicker(item) {
      if (!["date_minute", "date_hour"].includes(item.tableField.picker)) {
        return item.tableField.picker;
      }
      return "datetime";
    },

    changeValueType(field, value) {
      field.valueType = value;
      field.value = "";
      this.$set(field, "nodeField", undefined);

      if (value === "dynamic") {
        this.$set(field, "value", { timeMode: "", range: ["", ""] });
      } else if (value == "nodeField") {
        this.$set(field, "nodeField", {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
          picker: "",
        });
        field.value = "";
      } else {
        field.value = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
}
.pop-trigger {
  &.el-popover {
    padding: 0;
  }

  .pop-scroll {
    max-height: 320px;
    overflow: auto;

    .form-item {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &.active {
        background-color: rgba($color: #409eff, $alpha: 0.5);
        color: #ffffff;
      }

      &:hover {
        background-color: rgba($color: #409eff, $alpha: 0.2);
      }
    }
  }
}
.form-item {
  line-height: 25px;
  &.disabled {
    color: #c3cdda;
    cursor: not-allowed !important;
    &:hover {
      background-color: transparent !important;
      color: #c3cdda;
    }
  }
}
</style>
