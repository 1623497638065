export const conponents = [
  {
    title: "单行文本",
    label: "单行文本",
    componentName: "input",
    container: false,
    form: true,
    rely: null,
    required: false,
    readonly: false,
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    keywordSearched: true,
    defaultValue: "",
    array: false,
    json: false,
    // 字数限制
    wordNumberLimit: {
      numberLimit: false,
      minimum: 1,
      maximum: undefined,
    },
    validatorType: "custom", // 格式选择、默认自定义
    regex: "", // 正则表达式
    tips: "", // 正则错误提示
  },
  {
    title: "多行文本",
    label: "多行文本",
    componentName: "text_area",
    container: false,
    form: true,
    required: false,
    rely: null,
    readonly: false,
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
    defaultValue: "",
    array: false,
    json: false,
  },
  {
    title: "数字",
    label: "数字",
    componentName: "input_number",
    container: false,
    form: true,
    required: false,
    rely: null,
    extParam: {
      format: "value",
      num: 0,
      customWidth: "1/1",
    },
    readonly: false,
    keywordSearched: true,
    defaultValue: "",
    format: "",
    decimalPrecision: "",
    array: false,
    json: false,
  },
  {
    title: "日期时间",
    label: "日期时间",
    componentName: "date_picker",
    container: false,
    form: true,
    picker: "date",
    required: false,
    readonly: false,
    extParam: { customWidth: "1/1" },
    array: false,
    json: false,
  },
  {
    title: "单选框组",
    label: "单选框组",
    componentName: "radio_group",
    container: false,
    form: true,
    required: false,
    customOptions: [
      {
        label: "选项1",
        value: "选项1",
      },
      {
        label: "选项2",
        value: "选项2",
      },
      {
        label: "选项3",
        value: "选项3",
      },
    ],
    optionSource: "custom",
    readonly: false,
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
    array: false,
    json: false,
  },
  {
    title: "多选框组",
    label: "多选框组",
    componentName: "checkbox_group",
    container: false,
    form: true,
    array: true,
    json: false,
    defaultValue: [],
    required: false,
    customOptions: [
      {
        label: "选项1",
        value: "选项1",
      },
      {
        label: "选项2",
        value: "选项2",
      },
      {
        label: "选项3",
        value: "选项3",
      },
    ],
    optionSource: "custom",
    readonly: false,
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
  },
  {
    title: "下拉单选",
    label: "下拉单选",
    componentName: "select",
    container: false,
    form: true,
    required: false,
    customOptions: [
      {
        label: "选项1",
        value: "选项1",
      },
      {
        label: "选项2",
        value: "选项2",
      },
      {
        label: "选项3",
        value: "选项3",
      },
    ],
    optionSource: "custom",
    readonly: false,
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    array: false,
    json: false,
  },
  {
    title: "下拉多选",
    label: "下拉多选",
    componentName: "select_checkbox",
    container: false,
    form: true,
    required: false,
    array: true,
    json: false,
    customOptions: [
      {
        label: "选项1",
        value: "选项1",
      },
      {
        label: "选项2",
        value: "选项2",
      },
      {
        label: "选项3",
        value: "选项3",
      },
    ],
    optionSource: "custom",
    readonly: false,
    defaultValue: [],
    extParam: { customWidth: "1/1" },
  },
  {
    title: "子表单",
    label: "子表单",
    componentName: "json_form",
    container: true,
    form: true,
    required: false,
    array: true,
    json: false,
    components: [],
    allowActionEvents: ["add", "edit", "delete"],
    extParam: { customWidth: "1/1" },
    dataMode: "sub_form", // 默认子表原始模式(空白表)
  },
  {
    title: "标签页",
    label: "标签页",
    componentName: "tab",
    form: false,
    container: true,
    required: false,
    array: false,
    json: false,
    activeName: 0,
    components: [
      {
        componentName: "tab_pane",
        title: "标签页一",
        components: [],
      },
      {
        componentName: "tab_pane",
        title: "标签页二",
        components: [],
      },
      {
        componentName: "tab_pane",
        title: "标签页三",
        components: [],
      },
    ],
    extParam: { customWidth: "1/1" },
  },
  {
    title: "图片上传",
    label: "图片上传",
    componentName: "image_uploader",
    container: false,
    form: true,
    required: false,
    array: true,
    json: false,
    limitFileCount: 0,
    limitFileSize: 0,
    onlyCameraUpload: false, // 仅允许拍照上传 默认fasle
    allowCameras: [], // 允许拍照类型 SYSTEM 代表系统相机  WATERMARK 代表水印相机
    defaultValue: [],
    readonly: false,
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
  },
  {
    title: "附件上传",
    label: "附件上传",
    componentName: "attachment_uploader",
    container: false,
    form: true,
    required: false,
    array: true,
    json: false,
    limitFileCount: 0,
    limitFileSize: 0,
    onlyCameraUpload: false, // 仅允许拍照上传 默认fasle
    allowCameras: [], // 允许拍照类型 SYSTEM 代表系统相机  WATERMARK 代表水印相机
    defaultValue: [],
    readonly: false,
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
  },
  {
    title: "标题",
    label: "分割线",
    description: "",
    lineType: "horizontal", //horizontal 水平，vertical 垂直//改成样式类型
    componentName: "divider",
    container: false,
    form: false,
    required: false,
    lineColor: "#D8DAE5",
    dashed: false,
    titleAlign: "#101840", // left | center | right 改成标题颜色
    extParam: {},
  },
  {
    title: "手写签名",
    label: "手写签名",
    componentName: "sign_input",
    form: true,
    container: false,
    required: false,
    array: false,
    json: true,
    readonly: false,
    extParam: { customWidth: "1/1" },
  },
  {
    title: "会签",
    label: "会签",
    componentName: "sign_list_input",
    form: true,
    container: false,
    required: false,
    array: true,
    json: false,
    readonly: false,
    extParam: { customWidth: "1/1" },
  },
  {
    title: "关联查询",
    label: "关联查询",
    componentName: "form_data",
    form: false,
    container: false,
    required: false,
    array: false,
    json: false,
    tableName: "",
    columns: [],
    relationQuery: [],
    queryObject: {
      pageNumber: 1,
      pageSize: 1,
      sortParam: {},
      advanceQuery: [],
    },
    extParam: { customWidth: "1/1" },
    permissionType: "ALL",
    inlineEdit: true, // 默认允许行内编辑
    dataMode: "", //是否集团数据源reference_form:普通表单【目前指当前单位的表单】company_link_form:集团表单【跨单位表单目前来自集团分享】
  },
  {
    title: "关联数据",
    label: "关联数据",
    componentName: "reference_data",
    form: true,
    chooseMode: "popup",
    container: false,
    required: false,
    array: false,
    json: false,
    tableName: "",
    columns: [],
    tableColumns: [],
    relationQuery: [],
    queryObject: {
      pageNumber: 1,
      pageSize: 1,
      sortParam: {},
      advanceQuery: [],
    },
    filledRule: [],
    allowActionEvents: [],
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    permissionType: "ALL",
    dataMode: "", //是否集团数据源reference_form:普通表单【目前指当前单位的表单】company_link_form:集团表单【跨单位表单目前来自集团分享】
  },
  {
    title: "关联多条",
    label: "关联多条",
    componentName: "reference_data_list",
    form: true,
    chooseMode: "popup",
    container: false,
    required: false,
    array: true,
    json: false,
    tableName: "",
    columns: [],
    tableColumns: [],
    relationQuery: [],
    queryObject: {
      pageNumber: 1,
      pageSize: 1,
      sortParam: {},
      advanceQuery: [],
    },
    filledRule: [],
    allowActionEvents: [],
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    permissionType: "ALL",
  },
  {
    title: "汇总组件",
    label: "汇总组件",
    componentName: "summary_data",
    form: false,
    container: false,
    required: false,
    array: false,
    json: false,
    tableName: "",
    relationQuery: [],
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    metric: {
      field: null,
      name: "",
      title: "",
      oper: "",
      decimalPrecision: 0,
    },
  },
  {
    title: "流水号",
    label: "流水号",
    componentName: "sn_input",
    container: false,
    form: true,
    rely: null,
    required: false,
    readonly: true,
    hiddenTitle: false,
    autoValue: true,
    json: false,
    rules: [
      {
        digitsNum: 5,
        fixedLength: true,
        ruleType: "incNumber",
        startValue: 1,
        resetDuration: "none",
      },
    ],
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
  },
  {
    title: "用户单选",
    label: "用户单选",
    componentName: "user_select",
    container: false,
    form: true,
    rely: null,
    required: false,
    readonly: false,
    hiddenTitle: false,
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    defaultCurrentUser: false,
  },
  {
    title: "用户多选",
    label: "用户多选",
    componentName: "user_list_select",
    container: false,
    form: true,
    rely: null,
    required: false,
    readonly: false,
    hiddenTitle: false,
    array: true,
    extParam: { customWidth: "1/1" },
    defaultCurrentUser: false,
    defaultValue: [],
  },
  {
    title: "部门单选",
    label: "部门单选",
    componentName: "department_select",
    container: false,
    form: true,
    rely: null,
    required: false,
    readonly: false,
    hiddenTitle: false,
    extParam: {},
    uniqueValue: false,
    defaultCurrentUserDepartment: false,
    dataLinkage: null,
  },
  {
    title: "部门多选",
    label: "部门多选",
    componentName: "department_list_select",
    container: false,
    form: true,
    rely: null,
    required: false,
    readonly: false,
    hiddenTitle: false,
    array: true,
    extParam: { customWidth: "1/1" },
    defaultCurrentUserDepartment: false,
    dataLinkage: null,
    defaultValue: [],
  },
  {
    title: "文本组件",
    label: "文本组件",
    description: "文本组件",
    componentName: "html",
    form: false,
    container: false,
    array: false,
    json: false,
    required: false,
    readonly: true,
    hidden: false,
    extParam: { customWidth: "1/1" },
  },
  {
    title: "栅格",
    label: "栅格",
    width: 0,
    componentName: "grid",
    form: false,
    array: false,
    json: false,
    required: false,
    container: true,
    readonly: false,
    border: {
      width: 1,
      color: "#333333",
      style: "solid",
    },
    columns: [
      {
        width: 150,
      },
    ],
    components: [
      {
        title: "栅格行",
        label: "栅格行",
        componentName: "grid_row",
        form: false,
        array: false,
        json: false,
        required: false,
        container: true,
        readonly: false,
        components: [
          {
            title: "单元格",
            label: "单元格",
            width: 0,
            height: 0,
            componentName: "grid_col",
            form: false,
            array: false,
            json: false,
            required: false,
            container: true,
            readonly: false,
            components: [],
          },
        ],
      },
    ],
    extParam: { customWidth: "1/1" },
  },
  {
    title: "地址",
    label: "地址",
    componentName: "address_input",
    container: false,
    form: true,
    required: false,
    picker: "address", // province(省)  city(省-市)  county(省-市-县)  address(省-市-县-详细地址)
    array: false,
    json: true,
    defaultValue: {
      province: "",
      provinceId: "",
      city: "",
      cityId: "",
      county: "",
      countyId: "",
      address: "",
    },
    readonly: false,
    extParam: { customWidth: "1/1" },
  },
  {
    title: "手机号",
    label: "手机号",
    componentName: "mobile_input",
    container: false,
    form: true,
    required: false,
    rely: null,
    extParam: {
      format: "value",
      num: 0,
      customWidth: "1/1",
    },
    readonly: false,
    uniqueValue: false,
    keywordSearched: true,
  },
  {
    title: "定位",
    label: "定位",
    componentName: "position_input",
    container: false,
    form: true,
    required: false,
    array: false,
    json: true,
    defaultValue: "",
    limitRange: "none",
    fixedAreas: [],
    readonly: false,
    defaultCurrentPosition: "",
    displayLnglat: false,
    radius: 500,
    otherFieldRadius: 100,
    extParam: { customWidth: "1/1" },
  },
  {
    title: "内嵌表单",
    label: "内嵌表单",
    componentName: "include_form",
    form: true,
    container: false,
    array: false,
    json: true,
    required: false,
    rely: null,
    readonly: false,
    formId: "",
    displayMode: "inline", // inline 内嵌,popup 弹窗
    extParam: { customWidth: "1/1" },
    disabled: true,
  },
  {
    title: "父级组件",
    label: "父级组件",
    componentName: "parent_data",
    form: true,
    container: false,
    array: false,
    json: false,
    required: false,
    rely: null,
    readonly: false,
    extParam: { customWidth: "1/1" },
    uniqueValue: false,
    chooseMode: "select",
    relationQuery: [],
    columns: [],
    tableColumns: [],
    allowActionEvents: [],
    disabled: true,
  },
  {
    title: "富文本",
    label: "富文本",
    componentName: "html_input",
    container: false,
    form: true,
    required: false,
    rely: null,
    readonly: false,
    extParam: { customWidth: "1/1" },
    keywordSearched: true,
  },
  {
    title: "按钮",
    label: "按钮",
    componentName: "button",
    container: false,
    hiddenTitle: false,
    form: false,
    buttonText: "新增数据",
    buttonStyle: {
      color: "#3870ea", // 默认主色调
    },
    buttonEvent: "formEvent", // 表单事件
    eventConfig: {
      formId: "",
      actionEvent: "add", // 目前只有新增
      initFieldValues: [], // 新增时的初始化字段值设置
      permissionType: "ALL",
    },
    confirm: false, // 是否二次确认默认false
    confirmConfig: {
      cancelText: "取消",
      okText: "确认",
      title: "提示",
      description: "确定要执行该操作吗？",
    },
    extParam: { customWidth: "1/1" },
  },
  {
    title: "级联组件",
    label: "级联组件",
    componentName: "cascade_data",
    form: true,
    container: false,
    array: false,
    json: false,
    required: false,
    tableName: "", // 关联表id
    referenceFieldName: "", // 数据字段
    chooseMode: "cascade", // 选择数据的方式 cascade:级联菜单 tree:树形选择
    field: {}, // 用于显示选择内容的表单字段
    showAllPath: false, // 选择结果显示层级路径
    selectedLastLevel: false, // 必须选择到最后一级  (树形选择不存在此属性)
    extParam: { customWidth: "1/1" },
  },
];

// 可相互转换的组件组
export const CONVERT_COMPONENT_GROUPS = [
  ["input", "select", "radio_group"],
  ["select_checkbox", "checkbox_group"],
];
