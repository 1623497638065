import request from '@/utils/request.js'

/**
 * 获取公司列表
 */
export function fetchCompanyList() {
    return request({
        url:  '/oa/processSetting/listCompany',
        method: 'post'
    })
}

/**
 * 获取单位下流程列表
 */
export function fetchProcessOfCompanyList(companyId) {
    return request({
        url:  '/oa/processSetting/list',
        method: 'post',
        data: {companyId}
    })
}

/**
 * 获取流程详情
 */
export function getProcessDetail(id) {
    return request({
        url:  '/oa/processSetting/detail',
        method: 'post',
        data: {id}
    })
}

/**
 * 获取流程实例列表
 */
export function fetchProcessPaging(data) {
    return request({
        url:  '/oa/processInstance/paging',
        method: 'post',
        data
    })
}

/**
 * 保存流程实例
 */
export function saveProcess(data) {
    return request({
        url:  '/oa/processInstance/save',
        method: "post",
        data
    })
}

/**
 * 获取实例
 */
export function getProcessInstance(id) {
    return request({
        url:  "/oa/processInstance/detail",
        method: 'post',
        data: {id}
    });
}

/**
 * 删除卡片附件
 */
export function delProcessAtt(data) {
    return request({
        url:  '/oa/processInstance/deleteAttachment',
        method: 'post',
        data
    })
}

/**
 * 增加卡片附件
 */
export function addProcessAtt(data) {
    return request({
        url:  "/oa/processInstance/addAttachment",
        method: "post",
        data
    })
}

/**
 * 获取实例附件列表
 */
export function fetchProcessAttList(id) {
    return request({
        url:  '/oa/processInstance/listAttachment',
        method: 'post',
        data: {id}
    });
}
/**
 * 重命名oa附件
 */
export function oaProcessInstanceRenameAttachment(data) {
    return request({
        url:  '/oa/processInstance/renameAttachment',
        method: 'post',
        data
    });
}

/**
 * 附件重命名
 */
export function renameProcessAtt(data) {
    return request({
        url:  '/attachment/rename',
        method: 'post',
        data
    })
}

/**
 * 根据formId获取表单
 */
export function formGetById(formId) {
    return request({
        url:  '/form/detail',
        method: 'POST',
        data: {formId}
    })
}
/**
 * 根据id获取表单
 */
export function formGetById2(id) {
    return request({
        url:  '/form/detail',
        method: 'POST',
        data: {id}
    })
}

///oa/processUser/list

/**
 * 获取流程的用户列表
 */
export function fetchOaProcessUserlist(processSettingId) {
    return request({
        url:  '/oa/processUser/list',
        method: "post",
        data: {processSettingId}
    })
}

///form/submit

/**
 * 保存表单
 */
export function saveForm(data) {
    return request({
        url:  '/form/submit',
        method: "post",
        data
    })
}


/**
 * 添加评论
 * @param {*} data
 * @returns
 */
export function saveComment(data) {
    return request({
        url:  '/comment/save',
        method: 'POST',
        data
    })
}

/**
 * 获取评论列表
 * @param {*} data
 * @returns
 */
export function fetchCommentList(data) {
    return request({
        url:  '/comment/paging',
        method: 'POST',
        data
    })
}


/**
 * 删除评论
 */
export function deleteComment(id) {
    return request({
        url:  `/comment/delete?id=${id}`,
        method: 'POST'
    })
}


/**
 * 添加卡片成员
 * @param {*} data
 */
export function addOACardUser(data) {
    return request({
        url:  '/record/userAdd',
        method: 'POST',
        data
    })
}

/**
 * 移除卡片成员
 * @param {*} data
 * @returns
 */
export function removeOACardUser(data) {
    return request({
        url:  '/record/userRemove',
        method: 'POST',
        data
    })
}
