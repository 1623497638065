<template>
  <div v-if="form">
    <div class="row">
      <div class="label">标题：</div>
      <div class="flex">
        <el-input v-model="form.title" placeholder="" size="mini"></el-input>
      </div>
    </div>
    <div class="row">
      <div class="label">宽度：</div>
      <div class="flex">
        <el-input
          v-model="form.width"
          placeholder=""
          size="mini"
          @input="changeNumber('width', $event)"
        ></el-input>
      </div>
    </div>
    <div class="row">
      <div class="label">对齐方式：</div>
      <div class="flex">
        <el-select v-model="form.align" size="mini">
          <el-option label="左对齐" value="left"></el-option>
          <el-option label="右对齐" value="right"></el-option>
          <el-option label="居中对齐" value="center"></el-option>
        </el-select>
      </div>
    </div>
    <div class="row" v-if="form.field.componentName == 'json_form'">
      <div class="label">子表单字段配置：</div>
      <el-checkbox-group class="custom-radio" v-model="value">
        <draggable :list="form.components" animation="300">
          <div
            class="weui"
            v-for="(item, index) in form.components"
            :key="index"
          >
            <el-checkbox :label="item.field.name"></el-checkbox>
            <el-input
              v-model="item.title"
              placeholder=""
              size="small"
            ></el-input>
            <i
              class="el-icon-rank ml"
              style="font-size: 16px; cursor: pointer"
            ></i>
          </div>
        </draggable>
      </el-checkbox-group>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  components: { Draggable },
  props: {
    form: Object,
  },

  computed: {
    value: {
      set(val) {
        this.form.components.forEach((item) => {
          this.$set(item, "hidden", !val.includes(item.field.name));
        });
      },
      get() {
        let arr = [];
        if (this.form.components) {
          this.form.components.forEach((item) => {
            if (!item.hidden) {
              arr.push(item.field.name);
            }
          });
        }

        return arr;
      },
    },
  },
  created() {},
  methods: {
    changeNumber(key, value) {
      console.log(key);
      let num = value.replace(/[^\d]/g, "");
      setTimeout(() => {
        this.form[key] = num;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 10px;
  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.flex {
  flex: 1;
}
.weui {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.ml {
  margin-left: 10px;
}

.custom-radio {
  width: 100%;
  ::v-deep {
    .el-radio,
    .el-checkbox {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    .el-radio__label,
    .el-checkbox__label {
      padding-left: 0px;
      flex: 1;
      margin-left: 5px;
      border: solid 1px #e6e6e6;
      padding: 5px 10px;
      border-radius: 5px;
      display: none;
    }
  }
}
</style>
