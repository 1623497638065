<template>
  <div>
    <!--    该功能暂未实现, 暂时隐藏-->
    <!--    <div class="attr-row">-->
    <!--      <el-checkbox v-model="component.defaultCurrentPosition"-->
    <!--        ><span style="font-size: 12px">默认当前位置</span></el-checkbox-->
    <!--      >-->
    <!--    </div>-->
    <div class="attr-row">
      <div class="attr-label">定位设置</div>
      <div class="item">
        <el-checkbox v-model="component.displayLnglat"
          ><span style="font-size: 12px">显示经纬度坐标</span></el-checkbox
        >
      </div>
      <div class="item">
        <el-checkbox v-model="component.enabledFineTuning"
          ><span style="font-size: 12px">允许微调</span></el-checkbox
        >
      </div>
      <div class="item">
        <el-select
          v-model="component.radius"
          placeholder=""
          size="mini"
          style="width: 100%"
          :disabled="!component.enabledFineTuning"
        >
          <el-option label="100米" :value="100"></el-option>
          <el-option label="500米" :value="500"></el-option>
          <el-option label="1000米" :value="1000"></el-option>
          <el-option label="1500米" :value="1500"></el-option>
          <el-option label="3000米" :value="3000"></el-option>
          <el-option label="5000米" :value="5000"></el-option>
          <el-option label="不限" :value="0"></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import { componentProps } from "./util";
export default {
  name: "attr-position",
  props: componentProps,
};
</script>
