var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-filter__container selectTextarea"},[_c('div',{staticClass:"selectTextarea",staticStyle:{"width":"100%","height":"100%","overflow":"hidden"}},[(_vm.showToolbar)?_c('div',{staticClass:"note-toolbar selectTextarea",attrs:{"role":"toolbar"}},[_c('div',{staticClass:"note-btn-group note-font selectTextarea"},[_c('div',{staticClass:"note-btn selectTextarea",class:_vm.dashboardTopicModel.extParam.weight == 'bold' ? 'active' : '',attrs:{"title":"粗体"},on:{"click":function($event){return _vm.setBold()}}},[_c('img',{staticClass:"selectTextarea",attrs:{"src":require("@/assets/add-component/font/icon1.png"),"alt":""}})]),_c('div',{staticClass:"note-btn selectTextarea",class:_vm.dashboardTopicModel.extParam.italic == 'italic' ? 'active' : '',attrs:{"title":"斜体"},on:{"click":function($event){return _vm.setItalic()}}},[_c('img',{staticClass:"selectTextarea",attrs:{"src":require("@/assets/add-component/font/icon2.png"),"alt":""}})]),_c('div',{staticClass:"note-btn selectTextarea",class:_vm.dashboardTopicModel.extParam.underline == 'underline'
              ? 'active'
              : '',attrs:{"title":"下划线"},on:{"click":function($event){return _vm.setUnderline()}}},[_c('img',{staticClass:"selectTextarea",attrs:{"src":require("@/assets/add-component/font/icon3.png"),"alt":""}})]),_c('div',{staticClass:"note-btn selectTextarea",attrs:{"title":"段落"}},[_c('el-dropdown',{staticClass:"selectTextarea",attrs:{"trigger":"click"},on:{"command":_vm.setParagraph}},[_c('img',{staticClass:"selectTextarea",attrs:{"src":require("@/assets/add-component/font/icon5.png"),"alt":""}}),_c('el-dropdown-menu',{staticClass:"selectTextarea",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{staticClass:"selectTextarea",class:{
                  selected: _vm.dashboardTopicModel.extParam.align == 'left'
                },attrs:{"command":"left"}},[_vm._v("左")]),_c('el-dropdown-item',{staticClass:"selectTextarea",class:{
                  selected: _vm.dashboardTopicModel.extParam.align == 'center'
                },attrs:{"command":"center"}},[_vm._v("中")]),_c('el-dropdown-item',{staticClass:"selectTextarea",class:{
                  selected: _vm.dashboardTopicModel.extParam.align == 'right'
                },attrs:{"command":"right"}},[_vm._v("右")])],1)],1)],1),_c('div',{staticClass:"note-btn selectTextarea",attrs:{"title":"字体颜色"},on:{"click":function($event){return _vm.showSelect()}}},[_c('img',{staticClass:"selectTextarea",attrs:{"src":require("@/assets/add-component/font/icon4.png"),"alt":""}}),(_vm.show)?_c('div',{staticClass:"cont-div selectTextarea"},_vm._l((_vm.colorList),function(item){return _c('div',{key:item,staticClass:"content-item selectTextarea",style:('text-align:center;background-color:' + item),on:{"click":function($event){return _vm.selectVal(item)}}},[(_vm.dashboardTopicModel.extParam.color == item)?_c('i',{staticClass:"el-icon-check",style:(`color:${
                    item == '#FFFFFF' ? '#000000' : '#FFFFFF'
                  };line-height:30px;`)}):_vm._e()])}),0):_vm._e()]),_c('div',{staticClass:"note-btn selectTextarea",attrs:{"title":"字号"}},[_c('el-dropdown',{staticClass:"selectTextarea",attrs:{"trigger":"click"},on:{"command":_vm.setSize}},[_c('img',{staticClass:"selectTextarea",staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/add-component/font/icon7.png"),"alt":""}}),_c('el-dropdown-menu',{staticClass:"selectTextarea",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '12px'
                },attrs:{"command":"12px"}},[_vm._v("12")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '14px'
                },attrs:{"command":"14px"}},[_vm._v("14")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '16px'
                },attrs:{"command":"16px"}},[_vm._v("16")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '18px'
                },attrs:{"command":"18px"}},[_vm._v("18")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '20px'
                },attrs:{"command":"20px"}},[_vm._v("20")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '22px'
                },attrs:{"command":"22px"}},[_vm._v("22")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '24px'
                },attrs:{"command":"24px"}},[_vm._v("24")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '28px'
                },attrs:{"command":"28px"}},[_vm._v("28")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '32px'
                },attrs:{"command":"32px"}},[_vm._v("32")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '36px'
                },attrs:{"command":"36px"}},[_vm._v("36")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '48px'
                },attrs:{"command":"48px"}},[_vm._v("48")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '52px'
                },attrs:{"command":"52px"}},[_vm._v("52")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '56px'
                },attrs:{"command":"56px"}},[_vm._v("56")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '64px'
                },attrs:{"command":"64px"}},[_vm._v("64")]),_c('el-dropdown-item',{class:{
                  selected: _vm.dashboardTopicModel.extParam.size == '128px'
                },attrs:{"command":"128px"}},[_vm._v("128")])],1)],1)],1),_c('div',{staticClass:"note-btn selectTextarea",attrs:{"title":"链接"},on:{"click":_vm.setLink}},[_c('img',{staticClass:"selectTextarea",attrs:{"src":require("@/assets/add-component/font/icon6.png"),"alt":""}})])])]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.dashboardTopicModel.title),expression:"dashboardTopicModel.title"}],staticClass:"selectTextarea",staticStyle:{"resize":"none"},style:(`background-image: url(${_vm.url});line-height: 50%;
   font-weight:${_vm.dashboardTopicModel.extParam.weight};color:${
          _vm.dashboardTopicModel.extParam.color
        };
   text-align:${_vm.dashboardTopicModel.extParam.align};text-decoration:${
          _vm.dashboardTopicModel.extParam.underline
        };
   font-size:${_vm.dashboardTopicModel.extParam.size};font-style:${
          _vm.dashboardTopicModel.extParam.italic
        }`),domProps:{"value":(_vm.dashboardTopicModel.title)},on:{"click":_vm.onClick,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dashboardTopicModel, "title", $event.target.value)}}})]),_c('el-dialog',{attrs:{"title":"链接","append-to-body":"","destroy-on-close":"","visible":_vm.dialogVisible,"width":"600px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogVisible)?_c('div',{staticStyle:{"padding":"24px","display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"form-item__wrap"},[_c('span',{staticClass:"form-item__wrap-title titlespan"},[_vm._v("链接")]),_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)]):_vm._e(),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveForm}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }