import { hashFile } from "@/utils/tools";
import { attachmentSave } from "@/api/attachment";
import { ossLoadCredential } from "@/api/oss";
import OSS from "ali-oss";
import {
    getFileOriginalName,
    getUploadPathByName,
} from "@/utils/fileNameUtil";
const partSize = 1 * 1024 * 1024; // 每个分片大小
const parallel = 10; // 同时上传的分片数
const checkpoints = {}; // 所有分片上传文件的检查点

export async function upload(param, callback) {
    let { file } = param;
    hashFile(file, async (hash) => {
        // this.fileOssObjectList[file.uid].md5 = hash
        param.file.md5 = hash;

        console.log("不符合秒传");
        //初始化上传客户端链接
        //判断是否需要断点上传
        file.ossClient = await initOSSClient();
        if (file.size < partSize) {
            commonUpload(param, callback);
        } else {
            multipartUpload(param, callback);
        }

    });
}

async function initOSSClient() {
    let resp = await ossLoadCredential();
    let credentials = resp.data;
    const { accessKeyId, accessKeySecret, securityToken } = credentials;
    const bucket = "yunguangong-file"; // bucket名称
    const region = "oss-cn-beijing"; // oss服务区域名称
    return new OSS({
        accessKeyId: accessKeyId,
        accessKeySecret: accessKeySecret,
        stsToken: securityToken,
        bucket,
        region,
    });
}


async function commonUpload(param, callback) {
    let { file } = param;
    let path = getUploadPathByName(file.name);
    return file.ossClient
        .put(path, file)
        .then(async (result) => {

            let data = { type: "common", param: { md5: file.md5, path, file } };
            await handleUploadAttachmentSave(data, callback);
        })
        .catch((err) => { });
}

async function multipartUpload(param, callback) {
    let { file } = param;
    let path = getUploadPathByName(file.name);
    return file.ossClient
        .multipartUpload(path, file, {
            parallel,
            partSize,
            progress: (progress, checkpoint) => {
                file.checkpoint = {
                    uploadId: checkpoint.uploadId,
                    name: checkpoint.name,
                };
                onMultipartUploadProgress(progress, checkpoint, param);
            },
        })
        .then(async (result) => {
            let data = {
                type: "multipart",
                param: { md5: file.md5, path, file },
            };

            await handleUploadAttachmentSave(data, callback);
        })
        .catch((err) => {
            console.log(err);
        });
}
// 分片上传进度改变回调
async function onMultipartUploadProgress(progress, checkpoint, param) {
    let { file } = param;
    let percent = (progress * 100).toFixed(0) * 1;
    console.log(`${checkpoint.file.name} 上传进度 ${percent}`);
    file.percent = percent;
    file.status = "normal";
    param.onProgress(file);
    // this.updateFileStatus(file)
    checkpoints[file.uid] = checkpoint;
}


//处理附件上传到oss之后保存到智管工附件表
async function handleUploadAttachmentSave(data, callback) {
    let { type, param } = data;
    let attachmentSaveParam = {
        "attachment.originalName": getFileOriginalName(param.file.name),
        "attachment.md5": param.md5,
        "attachment.path": param.path,
        "attachment.size": param.file.size,
    };
    let attachmentSaveResp = await attachmentSave(attachmentSaveParam);
    if (typeof callback === 'function') {
        callback(attachmentSaveResp.data.attachment)
    }
}