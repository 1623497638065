<template>
  <div>
    <div class="row">
      <!-- 列数 -->
      <div class="label">列数</div>
      <div class="flex">
        <!--        <el-input-->
        <!--          v-model="form.cols"-->
        <!--          placeholder=""-->
        <!--          @input="changeNumber('cols', $event)"-->
        <!--          size="mini"-->
        <!--        ></el-input>-->
        <el-radio-group
          v-model="form.cols"
          size="mini"
          @change="changeNumber('cols', $event)"
        >
          <el-radio-button :label="1">单列</el-radio-button>
          <el-radio-button :label="2">双列</el-radio-button>
          <el-radio-button :label="3">三列</el-radio-button>
          <el-radio-button :label="4">四列</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!-- 标题位置 -->
    <div class="row">
      <div class="label">标题位置</div>
      <div class="flex">
        <el-select v-model="form.labelPosition" placeholder="" size="mini">
          <el-option label="顶部对齐" value="top"></el-option>
          <el-option label="左对齐" value="left"></el-option>
          <el-option label="右对齐" value="right"></el-option>
        </el-select>
      </div>
    </div>
    <!-- 标题宽度 -->
    <div class="row">
      <div class="label">标题宽度</div>
      <div class="flex">
        <el-input
          v-model="form.labelWidth"
          placeholder=""
          @input="changeNumber('labelWidth', $event)"
          size="mini"
        ></el-input>
      </div>
    </div>

    <!-- 表单提交校验 -->
    <div class="row">
      <div class="label">表单提交校验</div>
      <div class="flex">
        <div
          class="v-row"
          v-for="(item, index) in form.formCommitValidates"
          :key="index"
        >
          <div
            class="v-title"
            :title="renderText(item.originalFormula)"
            v-text="renderText(item.originalFormula)"
          ></div>
          <div class="v-icon">
            <i class="el-icon-edit" @click="editValidate(index, item)"></i>
            <i class="el-icon-delete" @click="delValidate(index)"></i>
          </div>
        </div>
      </div>
      <div class="flex">
        <el-button @click="addValidate" style="width: 100%" size="mini"
          >添加校验条件</el-button
        >
      </div>
    </div>
    <!-- 组合字段唯一 -->
    <div class="row unique-group">
      <div class="label">组合字段唯一值</div>
      <div class="flex">
        <el-select
          class="unique-group-select"
          popper-class="unique-group-popper"
          size="mini"
          v-model="form.uniqueLabels"
          multiple
          filterable
          placeholder=""
          @change="changeUniqueGroup"
        ></el-select>
      </div>
      <el-dropdown trigger="click" @command="addUniqueGroup">
        <span class="el-dropdown-link">+ 添加字段</span>
        <el-dropdown-menu
          slot="dropdown"
          style="max-height: 400px; overflow: auto"
        >
          <el-dropdown-item
            v-for="item in allowUniqueFields"
            :key="item.key"
            :command="item"
            :disabled="isSelection(item)"
            >{{ item.title }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 唯一重复提示 -->
    <div class="row unique-group">
      <div class="label">唯一重复提示</div>
      <div class="flex">
        <el-input
          type="textarea"
          placeholder="请输入唯一值重复时的提示"
          v-model="form.dataIndexs[0].tips"
          maxlength="30"
          show-word-limit
        ></el-input>
      </div>
    </div>
    <!-- 字段显隐规则 -->
    <div class="row">
      <div class="label">字段显隐规则</div>
      <div class="flex">
        <el-button @click="editFieldVisible" style="width: 100%" size="mini">{{
          form.fieldDisplayRules && form.fieldDisplayRules.length
            ? `已设置${form.fieldDisplayRules.length}个显隐规则`
            : "添加显隐规则"
        }}</el-button>
      </div>
    </div>
    <div class="row">
      <div class="label">不可见字段赋值</div>
      <div class="flex" style="display: flex">
        <el-select
          :value="form.displayFieldType"
          size="mini"
          @change="changeDisplayFieldType"
          style="flex: 1"
        >
          <el-option label="保持原值" value="keepOriginValue"> </el-option>
          <el-option label="空值" value="empty"> </el-option>
          <el-option label="始终重新计算" value="keep"></el-option>
        </el-select>
        <el-button
          type="default"
          size="mini"
          icon="el-icon-setting"
          style="margin-left: 8px"
          @click="specialFieldVisible = true"
        ></el-button>
      </div>
      <div
        v-if="isSpecialField"
        style="
          background-color: #f0f1f4;
          border: solid 1px #e6e8ed;
          border-radius: 3px;
          margin-top: 10px;
        "
      >
        <div class="weui-between" style="padding: 8px">
          <div style="font-size: 12px">已设置特殊字段赋值规则</div>
          <div
            style="color: var(--zgg-brand-color-6); cursor: pointer"
            @click="isShowAll = !isShowAll"
          >
            <span style="font-size: 12px">查看详情</span
            ><i
              :class="isShowAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            ></i>
          </div>
        </div>
        <div
          v-show="isShowAll"
          style="border-top: solid 1px #e6e8ed; margin: 0 8px"
        >
          <!-- 空值 -->
          <div
            v-if="emptyFields && emptyFields.length"
            style="margin-top: 10px"
          >
            <div style="font-size: 13px">空值</div>
            <div
              class="spec-field-list"
              style="font-size: 12px; margin-top: 8px"
            >
              <div
                v-for="item in emptyFields"
                :key="item.name"
                v-text="item.title"
                style="margin: 0px 8px 8px 0px; color: #333333"
              ></div>
            </div>
          </div>

          <!-- 始终重新计算 -->
          <div v-if="keepFields && keepFields.length" style="margin-top: 10px">
            <div style="font-size: 13px">始终重新计算</div>
            <div
              class="spec-field-list"
              style="font-size: 12px; margin-top: 8px"
            >
              <div
                v-for="item in keepFields"
                :key="item.name"
                v-text="item.title"
                style="margin: 0px 8px 8px 0px; color: #333333"
              ></div>
            </div>
          </div>

          <!-- 保持原值 -->
          <div
            v-if="keepOriginValueFields && keepOriginValueFields.length"
            style="margin-top: 10px"
          >
            <div style="font-size: 13px">保持原值</div>
            <div
              class="spec-field-list"
              style="font-size: 12px; margin-top: 8px"
            >
              <div
                v-for="item in keepOriginValueFields"
                :key="item.name"
                v-text="item.title"
                style="margin: 0px 8px 8px 0px; color: #333333"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 12px; padding: 4px 0px">
        <span>{{
          form.displayFieldType == "empty"
            ? "不可见字段提交为空值"
            : form.displayFieldType == "keepOriginValue"
            ? "不可见字段的原有值保持不变"
            : "不可见字段的原有值保持不变"
        }}</span>
        <span v-if="isSpecialField">（特殊字段按照设置的对应规则赋值）</span>
      </div>
    </div>

    <!-- <div class="row weui-between">
      <div class="label">设计移动视图</div>
      <el-switch v-model="form.wapDesign"></el-switch>
    </div> -->
    <div class="row">
      <div class="label">数据标题</div>
      <div style="margin-bottom: 5px">
        <el-radio-group
          size="small"
          @change="changeDataTile"
          v-model="form.dataTitle.type"
        >
          <el-radio label="default">默认</el-radio>
          <el-radio label="custom">自定义</el-radio>
        </el-radio-group>
      </div>
      <el-input
        v-if="form.dataTitle.type == 'default'"
        readonly
        size="small"
        :value="tplTitle"
        placeholder=""
      ></el-input>
      <div v-else>
        <div class="input-border">
          <code-edit
            class="codeEdit"
            :columns="columns"
            textType="text"
            ref="codeEdit"
            v-model="formulas"
          ></code-edit>
        </div>
        <el-popover
          popper-class="pop-trigger"
          width="320"
          v-model="columnVisible"
        >
          <div v-clickoutside="handleClose" class="pop-scroll">
            <el-tree
              :data="columns"
              :props="defaultProps"
              @node-click="addColumn"
            >
              <template v-slot:default="{ data, node }">
                <span
                  :class="{ disabled: isDisabled(data, node) }"
                  class="el-tree-node__label"
                  >{{ node.label }}</span
                >
              </template>
            </el-tree>
          </div>
          <el-button
            slot="reference"
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop=""
            >添加字段</el-button
          >
        </el-popover>
      </div>
    </div>
    <div class="row" v-if="hasFrontEventAuth()">
      <div class="label">前端事件</div>
      <div class="flex">
        <el-button @click="setEvent" style="width: 100%" size="mini">{{
          this.formEvents.length < 1
            ? "设置"
            : `已设置${this.formEvents.length}个前端事件`
        }}</el-button>
      </div>
    </div>
    <frontEvent
      :drawer.sync="drawer"
      :formEvents.sync="formEvents"
      :areaTree="areaTree"
      :formComponent="formComponent"
    ></frontEvent>
    <!-- <rely-dialog
      title="公式"
      :dialogVisible.sync="relyVisible"
      :originComponents="originComponents"
      name=""
      relyType="formValidate"
      :isJsonForm="false"
      jsonFormName=""
      :originalFormula="originalFormula"
      :errMessage="errMessage"
      :fetchMetaFieldComponentList="fetchMetaFieldComponentList"
      @save="saveRely"
    ></rely-dialog> -->
    <formula-dialog
      :originalFormula="originalFormula"
      :treeNodeList="originComponents"
      :fetchMetaFieldComponentList="fetchMetaFieldComponentList"
      @save="saveRely"
      ref="relyDialog"
      @closed="closeRely"
    >
      <div slot="header" class="weui">
        <div style="margin-right: 10px">不满足校验条件时提示文字</div>
        <el-input
          v-model="errMessage"
          placeholder=""
          style="flex: 1"
        ></el-input>
      </div>
    </formula-dialog>
    <display-field
      :dialogVisible.sync="fieldVisible"
      :originComponents="originComponents"
      :areaTree="areaTree"
      :fieldDisplayRules.sync="form.fieldDisplayRules"
    ></display-field>
    <el-dialog
      title="特殊字段赋值规则"
      :visible.sync="specialFieldVisible"
      width="600px"
      @open="open"
    >
      <div style="padding: 20px">
        <div style="font-size: 12px">
          此处设置的字段，不可见时按照下方对应规则赋值，不受默认赋值规则的影响
        </div>
        <div v-if="form.displayFieldType != 'empty'">
          <div class="weui-between">
            <div style="font-size: 14px">空值</div>
            <form-special-fields
              :componentList="componentList"
              :specialFieldValueRule.sync="specialFieldValueRule"
              displayFieldType="emptyFields"
            ></form-special-fields>
          </div>

          <div v-if="specialFieldValueRule.emptyFields" class="spec-field-list">
            <el-tag
              v-for="(item, index) in emptyFields2"
              :type="item.delete ? 'danger' : 'info'"
              :closable="true"
              :key="item.name"
              style="margin: 0 8px 8px 0px"
              @close="closeTag(index, 'emptyFields')"
              >{{ item.title }}</el-tag
            >
          </div>
        </div>
        <div v-if="form.displayFieldType != 'keep'">
          <div class="weui-between">
            <div style="font-size: 14px">始终重新计算</div>
            <form-special-fields
              :componentList="componentList"
              :specialFieldValueRule.sync="specialFieldValueRule"
              displayFieldType="keepFields"
            ></form-special-fields>
          </div>
          <div v-if="specialFieldValueRule.keepFields" class="spec-field-list">
            <el-tag
              v-for="(item, index) in keepFields2"
              :type="item.delete ? 'danger' : 'info'"
              :closable="true"
              :key="item.name"
              style="margin: 0 8px 8px 0px"
              @close="closeTag(index, 'keepFields')"
              >{{ item.title }}</el-tag
            >
          </div>
        </div>
        <div v-if="form.displayFieldType != 'keepOriginValue'">
          <div class="weui-between">
            <div style="font-size: 14px">保持原值</div>
            <form-special-fields
              :componentList="componentList"
              :specialFieldValueRule.sync="specialFieldValueRule"
              displayFieldType="keepOriginValueFields"
            ></form-special-fields>
          </div>
          <div
            v-if="specialFieldValueRule.keepOriginValueFields"
            class="spec-field-list"
          >
            <el-tag
              v-for="(item, index) in keepOriginValueFields2"
              :type="item.delete ? 'danger' : 'info'"
              :closable="true"
              :key="item.name"
              style="margin: 0 8px 8px 0px"
              @close="closeTag(index, 'keepOriginValueFields')"
              >{{ item.title }}</el-tag
            >
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="default" @click="specialFieldVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="saveSpecial">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import displayField from "@/views/lowCode/form/component/displayField";
import codeEdit from "@/components/codeEdit";
import Clickoutside from "element-ui/src/utils/clickoutside";
import test from "@/utils/test";
import { getFormDetail } from "../api";
import { fetchMetaFieldComponentList } from "@/api/form";
import frontEvent from "./front-event.vue";
import { deepClone } from "@/utils";
import { getComponentByName, isEmpty } from "@/zgg-core/utils";
import formSpecialFields from "./form-special-fields";
import { hasRoleAuth } from "@/utils/roleAuth";
import { getFormComponents } from "@zgg-core-utils/utils";

const exitDataTitleArr = [
  "json_form",
  "image_uploader",
  "attachment_uploader",
  "sign_input",
  "parent_data",
  "reference_data_list",
  "include_form",
  "position_input",
  "sign_list_input",
  "html_input",
  "text_area",
  "address_input",
  "cascade_data",
];

export default {
  components: { displayField, codeEdit, frontEvent, formSpecialFields },
  directives: { Clickoutside },
  props: {
    form: Object,
    areaTree: Array,
    componentList: Array,
  },

  data() {
    return {
      isShowAll: false,
      columnVisible: false,
      fieldVisible: false,
      relyVisible: false,
      specialFieldVisible: false, //  特殊字段赋值规则
      originalFormula: [],
      errMessage: "",
      index: -1,
      drawer: false,
      formEvents: [],
      specialFieldValueRule: {},
      defaultProps: {
        children: "columns",
        label: "title",
      },
    };
  },
  created() {
    if (this.form.formEvents) {
      this.formEvents = this._.cloneDeep(this.form.formEvents);
    }
  },
  computed: {
    formComponent() {
      let form = this._.cloneDeep(this.form) || {};
      form.components = getFormComponents(this.form.components);
      return form;
    },
    tplTitle() {
      let title = "";

      let tpl = this.getTpl(this.originComponents);
      if (tpl) {
        title = tpl.title;
      } else {
        return "创建者";
      }
      return title;
    },
    columns() {
      let columns = [];
      let exitArr = exitDataTitleArr;
      let buildColumns = (componentList, exitComponents, isReferenceData) => {
        let list = [];
        let component;
        let components = deepClone(componentList);
        components.forEach((item) => {
          if (isReferenceData) {
            component = item.field.component;
          } else {
            component = item;
          }
          if (component.form) {
            if (!exitComponents.includes(component.componentName)) {
              list.push(item);
            }
          } else if (component.container) {
            list = list.concat(buildColumns(component.components, exitArr));
          }
          if (
            component.componentName == "reference_data" &&
            component.columns
          ) {
            component.columns = buildColumns(
              component.columns,
              [...exitArr, "reference_data"],
              true,
            );
          }
        });
        return list;
      };
      columns = buildColumns(this.originComponents, exitArr);
      columns = columns.concat([
        {
          componentName: "input",
          container: false,
          form: true,
          array: false,
          json: false,
          required: false,
          name: "createrId",
          title: "创建者",
        },
        {
          componentName: "date_picker",
          picker: "datetime",
          container: false,
          form: true,
          array: false,
          json: false,
          required: false,
          name: "createdTime",
          title: "创建时间",
        },
        {
          componentName: "date_picker",
          picker: "datetime",
          container: false,
          form: true,
          array: false,
          json: false,
          required: false,
          name: "updatedTime",
          title: "更新时间",
        },
      ]);
      return columns;
    },
    formulas: {
      get() {
        if (
          this.form.dataTitle.type == "custom" &&
          this.form.dataTitle.template
        ) {
          let patt1 = /#{[a-zA-Z0-9.]+}/gm;
          let arr = this.form.dataTitle.template.match(patt1);
          if (!arr) {
            arr = [];
          }
          arr = arr.map((val) => val.substring(2, val.length - 1));
          let arr2 = this.form.dataTitle.template.split(patt1);
          let formulas = [];
          let i = 0;
          arr2.forEach((val) => {
            if (!test.isEmpty(val)) {
              formulas.push({
                type: "val",
                value: val,
              });
            }
            if (arr[i]) {
              let title;
              let opt = this.columns.find((l) => {
                if (l.componentName == "reference_data" && l.columns) {
                  let column = l.columns.find((column) => {
                    let name = `${l.name}.${column.field.name}`;
                    return name == arr[i];
                  });
                  if (column) {
                    title = `${l.title}.${column.title}`;
                    return true;
                  }
                  return false;
                }
                return l.name == arr[i];
              });
              formulas.push({
                name: arr[i],
                value: opt ? title || opt.title : arr[i],
                type: "field",
              });
              i++;
            }
          });
          return [formulas];
        }
      },
      set(spans) {
        let template = "";
        spans.forEach((rows) => {
          rows.forEach((col) => {
            if (col.type == "field") {
              template += `#{${col.name}}`;
            } else {
              template += col.value;
            }
          });
        });
        this.form.dataTitle.template = template;
      },
    },
    originComponents() {
      if (this.form && this.form.components) {
        return this.form.components;
      }
      return [];
    },
    allowUniqueFields() {
      const fields = [
        "input",
        "select",
        "mobile_input",
        "department_select",
        "user_select",
        "reference_data",
      ];
      return this.form.components.filter((item) =>
        fields.includes(item.componentName),
      );
    },
    isSpecialField() {
      if (isEmpty(this.form.specialFieldValueRule)) {
        return false;
      }
      return Object.values(this.form.specialFieldValueRule).some(
        (val) => val.length,
      );
    },
    emptyFields() {
      if (isEmpty(this.form.specialFieldValueRule)) {
        return [];
      }
      if (isEmpty(this.form.specialFieldValueRule.emptyFields)) {
        return [];
      }
      let list = [];
      this.form.specialFieldValueRule.emptyFields.forEach((val) => {
        let component = getComponentByName(val, this.componentList);
        list.push({
          name: val,
          title: component ? component.title : val,
          delete: !component,
        });
      });
      return list;
    },
    keepFields() {
      if (isEmpty(this.form.specialFieldValueRule)) {
        return [];
      }
      if (isEmpty(this.form.specialFieldValueRule.keepFields)) {
        return [];
      }
      let list = [];
      this.form.specialFieldValueRule.keepFields.forEach((val) => {
        let component = getComponentByName(val, this.componentList);
        list.push({
          name: val,
          title: component ? component.title : val,
          delete: !component,
        });
      });
      return list;
    },
    keepOriginValueFields() {
      if (isEmpty(this.form.specialFieldValueRule)) {
        return [];
      }
      if (isEmpty(this.form.specialFieldValueRule.keepOriginValueFields)) {
        return [];
      }
      let list = [];
      this.form.specialFieldValueRule.keepOriginValueFields.forEach((val) => {
        let component = getComponentByName(val, this.componentList);
        list.push({
          name: val,
          title: component ? component.title : val,
          delete: !component,
        });
      });
      return list;
    },
    emptyFields2() {
      if (isEmpty(this.specialFieldValueRule)) {
        return [];
      }
      if (isEmpty(this.specialFieldValueRule.emptyFields)) {
        return [];
      }
      let list = [];
      this.specialFieldValueRule.emptyFields.forEach((val) => {
        let component = getComponentByName(val, this.componentList);
        list.push({
          name: val,
          title: component ? component.title : val,
          delete: !component,
        });
      });
      return list;
    },
    keepFields2() {
      if (isEmpty(this.specialFieldValueRule)) {
        return [];
      }
      if (isEmpty(this.specialFieldValueRule.keepFields)) {
        return [];
      }
      let list = [];
      this.specialFieldValueRule.keepFields.forEach((val) => {
        let component = getComponentByName(val, this.componentList);
        list.push({
          name: val,
          title: component ? component.title : val,
          delete: !component,
        });
      });
      return list;
    },
    keepOriginValueFields2() {
      if (isEmpty(this.specialFieldValueRule)) {
        return [];
      }
      if (isEmpty(this.specialFieldValueRule.keepOriginValueFields)) {
        return [];
      }
      let list = [];
      this.specialFieldValueRule.keepOriginValueFields.forEach((val) => {
        let component = getComponentByName(val, this.componentList);
        list.push({
          name: val,
          title: component ? component.title : val,
          delete: !component,
        });
      });
      return list;
    },
  },
  methods: {
    hasFrontEventAuth() {
      return hasRoleAuth("ops_auth_form_front_event");
    },
    closeTag(index, key) {
      this.specialFieldValueRule[key].splice(index, 1);
    },
    open() {
      if (!isEmpty(this.form.specialFieldValueRule)) {
        this.specialFieldValueRule = this._.cloneDeep(
          this.form.specialFieldValueRule,
        );
      } else {
        this.$set(this, "specialFieldValueRule", {});
      }
    },
    saveSpecial() {
      this.$set(
        this.form,
        "specialFieldValueRule",
        this._.cloneDeep(this.specialFieldValueRule),
      );
      this.specialFieldVisible = false;
    },
    changeDisplayFieldType(val) {
      if (this.isSpecialField) {
        this.$confirm(
          "更换默认赋值规则后，将清空已设置的特殊字段赋值规则",
          "确定要更换默认赋值规则吗？",
        )
          .then(() => {
            this.$set(this.form, "displayFieldType", val);
            this.$set(this.form, "specialFieldValueRule", {});
          })
          .catch(() => {});
      } else {
        this.$set(this.form, "displayFieldType", val);
      }
    },
    closeRely() {
      this.errMessage = "";
    },
    addUniqueGroup(component) {
      this.form.uniqueLabels.push(component.title);
      this.form.dataIndexs[0].value.push(component.key);
    },
    changeUniqueGroup() {
      this.form.dataIndexs[0].value.pop();
    },
    isSelection(component) {
      return this.form.dataIndexs[0].value.some((key) => component.key === key);
    },
    setEvent() {
      this.drawer = true;
    },
    getFormDetail,
    fetchMetaFieldComponentList,
    handleClose() {
      this.columnVisible = false;
    },
    isDisabled(data, node) {
      let name = data.name;
      if (
        node.parent &&
        node.parent.data &&
        node.parent.data.componentName == "reference_data"
      ) {
        name = `${node.parent.data.name}.${data.field.name}`;
      }
      let patt1 = /#{[a-zA-Z0-9.]+}/gm;
      let arr = this.form.dataTitle.template.match(patt1);
      if (!arr) {
        arr = [];
      }
      arr = arr.map((val) => val.substring(2, val.length - 1));
      return arr.includes(name);
    },
    changeDataTile(value) {
      this.form.dataTitle.template = "";
    },
    addColumn(data, node) {
      if (
        this.isDisabled(data, node) ||
        data.componentName == "reference_data"
      ) {
        return;
      }
      let item = deepClone(data);
      if (
        node.parent &&
        node.parent.data &&
        node.parent.data.componentName == "reference_data"
      ) {
        item.title = `${node.parent.data.title}.${data.title}`;
        item.name = `${node.parent.data.name}.${data.field.name}`;
      }
      this.columnVisible = false;
      this.$refs.codeEdit.addColumn(item);

      console.log(this.columnVisible);
    },
    getTpl(componentList) {
      if (!componentList) return;
      let obj;
      let exitArr = [...exitDataTitleArr, "reference_data"];
      for (let i = 0; i < componentList.length; i++) {
        let element = componentList[i];
        if (element.form) {
          if (!exitArr.includes(element.componentName)) {
            obj = element;
            break;
          }
        } else if (element.container) {
          let tpl = this.getTpl(element.components);
          if (tpl) {
            obj = tpl;
            break;
          }
        }
      }
      return obj;
    },
    editFieldVisible() {
      this.fieldVisible = true;
    },
    editValidate(index, item) {
      this.index = index;
      this.errMessage = item.errMessage;
      this.originalFormula = this._.cloneDeep(item.originalFormula);
      this.$refs.relyDialog.open();
    },
    delValidate(index) {
      this.$confirm("确定要删除这条校验条件吗?", "提示").then(() => {
        this.form.formCommitValidates.splice(index, 1);
      });
    },
    addValidate() {
      this.index = -1;
      this.errMessage = "";
      this.originalFormula = [];
      this.$refs.relyDialog.open();
    },
    saveRely(originalFormula) {
      if (isEmpty(this.errMessage)) {
        this.$message.error("请输入不满足校验条件的提示");
        return;
      }
      let errMessage = this.errMessage;
      if (!this.form.formCommitValidates) {
        this.$set(this.form, "formCommitValidates", []);
      }
      if (this.index == -1) {
        this.form.formCommitValidates.push({
          errMessage,
          originalFormula,
        });
      } else {
        this.$set(this.form.formCommitValidates, this.index, {
          errMessage,
          originalFormula,
        });
      }
      this.$refs.relyDialog.close();
    },
    renderText(originalFormula) {
      let str = "";
      originalFormula.forEach((rows) => {
        rows.forEach((col) => {
          str += col.value;
        });
      });
      return str;
    },
    changeNumber(key, value) {
      let num = value;
      setTimeout(() => {
        this.$set(this.form, key, num);
        if (key == "cols") {
          this.originComponents.map((component) => {
            if (
              ["json_form", "tab", "grid", "reference_data"].includes(
                component.componentName,
              )
            ) {
              if (!component.extParam) {
                this.$set(component, "extParam", {
                  customWidth: 1 + "/" + 1,
                });
              } else {
                this.$set(component.extParam, "customWidth", 1 + "/" + 1);
              }
            } else {
              if (!component.extParam) {
                this.$set(component, "extParam", {
                  customWidth: 1 + "/" + value,
                });
              } else {
                this.$set(component.extParam, "customWidth", 1 + "/" + value);
              }
            }
          });
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 24px 20px;
  .label {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}
.flex {
  flex: 1;
}
.v-row {
  display: flex;
  align-items: center;
  padding: 4px 0px;
  .v-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
  .v-icon {
    display: flex;
    align-items: center;
    i {
      margin-left: 8px;
    }
  }
}
.weui {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.weui-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-border {
  border: 1px solid #dcdfe6;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 10px;
}
.codeEdit {
  min-width: 100%;
  overflow-y: hidden !important;
  overflow-x: auto !important;

  height: 20px;
  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
  ::v-deep {
    pre {
      white-space: nowrap !important;
      word-wrap: normal !important;
      width: max-content;
    }
  }
}

.unique-group {
  .unique-group-select {
    width: 100%;
  }

  .unique-group-select {
    ::v-deep .el-input__suffix,
    ::v-deep .el-tag__close {
      display: none;
    }

    ::v-deep .el-tag.el-tag--info {
      color: #3870ea;
      background-color: #eaf5ffff;
    }
  }

  .unique-group-popper {
    display: none;
  }

  ::v-deep .el-dropdown {
    margin-top: 5px;
    right: 0;
  }

  .el-dropdown-link {
    font-size: 12px;
    color: #3870ea;
    cursor: pointer;
    user-select: none;
  }
}
.spec-field-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
<style lang="scss">
.pop-trigger {
  &.el-popover {
    padding: 0;
  }
  .pop-scroll {
    max-height: 320px;
    overflow: auto;

    .el-tree-node__content {
      .el-tree-node__label {
        line-height: 30px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      width: 100%;
      height: 30px;
      cursor: pointer;
      &.active {
        background-color: rgba($color: #409eff, $alpha: 0.5);
        color: #ffffff;
      }
      .disabled {
        color: rgba($color: #000000, $alpha: 0.28);
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        background-color: rgba($color: #409eff, $alpha: 0.2);
      }
    }
  }
}
</style>
