var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.data.componentName == 'input' ||
      _vm.data.componentName == 'input_number' ||
      _vm.data.componentName == 'mobile_input'
    )?_c('el-input',{attrs:{"placeholder":_vm.data.placeHolder,"disabled":""}},[(_vm.data.componentName == 'mobile_input')?_c('i',{staticClass:"el-icon-mobile",attrs:{"slot":"prepend"},slot:"prepend"}):_vm._e()]):(_vm.data.componentName == 'text_area')?_c('el-input',{attrs:{"type":"textarea","placeholder":_vm.data.placeHolder,"disabled":""}}):(_vm.data.componentName == 'date_picker')?_c('el-date-picker',{attrs:{"type":"data.picker","placeholder":_vm.data.placeHolder,"disabled":""}}):(_vm.data.componentName == 'select')?_c('el-select',{attrs:{"placeholder":_vm.data.placeHolder,"disabled":""},nativeOn:{"click":function($event){return _vm.clickItem.apply(null, arguments)}},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},[(_vm.data.optionSource == 'custom')?_vm._l((_vm.data.customOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}):_vm._e()],2):(_vm.data.componentName == 'radio_group')?_c('el-radio-group',{attrs:{"disabled":""}},[(_vm.data.optionSource == 'custom')?_vm._l((_vm.data.customOptions),function(item){return _c('el-radio',{key:item.value,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label)+" ")])}):_vm._e()],2):(_vm.data.componentName == 'checkbox_group')?_c('el-checkbox-group',{attrs:{"disabled":""},model:{value:(_vm.checkboxValue),callback:function ($$v) {_vm.checkboxValue=$$v},expression:"checkboxValue"}},[(_vm.data.optionSource == 'custom')?_vm._l((_vm.data.customOptions),function(item){return _c('el-checkbox',{key:item.value,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label)+" ")])}):_vm._e()],2):(_vm.data.componentName == 'address_input')?_c('my-address',{attrs:{"picker":_vm.data.picker,"disabled":""}}):(
      _vm.data.componentName == 'attachment_uploader' ||
      _vm.data.componentName == 'image_uploader'
    )?_c('div',{staticClass:"sub-upload"},[_c('i',{staticClass:"el-icon-upload"})]):(
      _vm.data.componentName == 'sign_input' ||
      _vm.data.componentName == 'sign_list_input'
    )?_c('sign'):(_vm.data.componentName == 'sn_input')?_c('div',[_c('el-input',{attrs:{"placeholder":"自动生成无需填写","disabled":"","readonly":""}})],1):(_vm.data.componentName == 'user_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap",staticStyle:{"height":"32px","width":"100%"}},[_vm._v(" 选择成员 ")]):(_vm.data.componentName == 'user_list_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap",staticStyle:{"width":"100%","height":"32px"}},[_vm._v(" 选择成员 ")]):(_vm.data.componentName == 'department_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap",staticStyle:{"height":"32px","width":"100%"}},[_vm._v(" 选择部门 ")]):(_vm.data.componentName == 'department_list_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap",staticStyle:{"width":"100%","height":"32px"}},[_vm._v(" 选择部门 ")]):(_vm.data.componentName == 'summary_data')?_c('div',[_c('el-input',{attrs:{"placeholder":"汇总数据","disabled":"","readonly":""}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }