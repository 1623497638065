<template>
  <div class="rpa-page">
    <div v-if="workflow" class="rpa-header">
      <div class="weui rpa-flex">
        <div v-if="robot" style="min-width: 300px; width: 50%" @click="open">
          <div class="robot-title" v-text="robot.title"></div>
          <div
            v-if="robot.remark"
            class="robot-remark"
            v-text="robot.remark"
          ></div>
        </div>
      </div>
      <div class="weui" style="height: 100%">
        <div
          class="rpa-menu-item"
          :class="activeName == 'flow' ? 'active' : ''"
          @click="activeName = 'flow'"
        >
          流程
        </div>
        <div
          class="rpa-menu-item"
          :class="activeName == 'config' ? 'active' : ''"
          @click="activeName = 'config'"
        >
          配置
        </div>
      </div>

      <div v-if="workflow" class="rpa-header-btns rpa-flex">
        <rpa-version
          ref="rpaVersion"
          :key="workflow.id"
          :workflow.sync="workflow"
          style="width: max-content"
        ></rpa-version>
        <el-button
          v-if="workflow.versionStatus == 'design'"
          style="margin-left: 10px"
          @click="save"
          >保存流程</el-button
        >
        <el-button
          v-if="workflow.versionStatus == 'design'"
          style="margin-left: 10px"
          @click="publish"
          type="primary"
          >发布流程</el-button
        >

        <div
          v-if="workflow.versionStatus !== 'design'"
          @click="changeStatus"
          class="rpa-status"
          :class="workflow.versionStatus == 'enable' ? 'enable' : 'disabled'"
        >
          <div class="rpa-status-text">运行中</div>
          <div class="rpa-status-icon"><i class="el-icon-check"></i></div>
        </div>
      </div>
    </div>
    <template v-if="workflow">
      <div
        v-show="activeName == 'flow'"
        class="rpa-container"
        :style="paddingRight"
      >
        <div v-if="workflow.versionStatus != 'design'" class="rpa-ab">
          流程已启用，如需修改，请
          <el-button @click="addVersion" type="text">添加新版本</el-button>
        </div>
        <template v-if="workflow">
          <!-- 起始触发器节点 -->
          <rpa-node
            ref="rpaNode"
            :key="workflow.id"
            :node="workflow.rpaFlow.trigger"
            :lines.sync="workflow.rpaFlow.lines"
            :nodes.sync="workflow.rpaFlow.nodes"
            :isPaddingRight.sync="isPaddingRight"
            :add="add"
            :changeNode="changeNode"
            :isCopying.sync="isCopying"
            :checCopyNode="checCopyNode"
            :copyKeys="copyKeys"
            :disabled="disabled"
          >
            <!-- 结束节点 -->
            <div slot="end" class="rpa-flex-column">
              <div class="workflow-end-box">
                <div class="workflow-end"></div>
                <div class="workflow-end-text">流程结束</div>
              </div>
            </div>
          </rpa-node>
        </template>
      </div>

      <rpa-flow-config
        v-if="activeName == 'config'"
        :key="workflow.id"
        :config.sync="workflow.rpaFlow.config"
        :activeName.sync="activeName"
        :disabled="workflow.versionStatus != 'design'"
        @save="save"
      ></rpa-flow-config>
    </template>
    <rpa-edit-robot
      ref="rpaEditRobot"
      @change="editRobotTitle"
    ></rpa-edit-robot>
  </div>
</template>
<script>
import rpaNode from "./rpa-node";
import {
  createRobotWorkflow,
  disableRobotWorkflow,
  enableRobotWorkflow,
  fetchRobotWorkflowList,
  getRobotDetail,
  getRobotWorkflow,
  saveRobotWorkflow,
} from "./api";
import rpaVersion from "./rpa-version";
import rpaFlowConfig from "./rpa-flow-config";
import { RPA_VERSION_STATUS } from "./rpa-type";
import rpaEditRobot from "./rpa-edit-robot";

export default {
  components: { rpaNode, rpaVersion, rpaFlowConfig, rpaEditRobot },
  provide() {
    return {
      getTrigger: () => {
        return this.workflow.rpaFlow.trigger;
      },
      getRobotId: () => {
        return this.workflow.robotId;
      },
      getWorkflowConfig: () => {
        return this.workflow.rpaFlow.config;
      },
      /**
       * 获取本流程参数
       */
      getRootParams: () => {
        let flowParams = this.workflow?.rpaFlow?.config?.flowParams;

        if (flowParams) {
          return flowParams;
        }
        return [];
      },
    };
  },
  data() {
    return {
      activeName: "flow",
      /**@type {import("./rpa-type").robotWorkflow} 流程对象 */
      workflow: null,
      isPaddingRight: false,
      isCopying: false,
      copyKeys: [],
      workflowList: [],
      robot: null,
    };
  },

  computed: {
    disabled() {
      if (this.workflow) {
        return this.workflow.versionStatus != "design";
      }
      return false;
    },
    paddingRight() {
      if (!this.isPaddingRight) {
        return "";
      }
      let width = document.documentElement.clientWidth;
      if (width >= 640) {
        width = 640;
      } else if (width < 300) {
        width = 300;
      }

      return `padding-right:${width}px;`;
    },
  },
  async created() {
    let res = await fetchRobotWorkflowList({
      robotId: this.$route.query.id,
      pageSize: 1,
      pageNumber: 1,
    });
    let list = res.data.page.list;
    getRobotDetail({ robotId: this.$route.query.id }).then((res) => {
      this.robot = res.data.robot;
    });
    if (list.length == 0) {
      let resCreate = await createRobotWorkflow({
        robotId: this.$route.query.id,
      });
      this.workflow = resCreate.data.workflow;
    } else {
      getRobotWorkflow({ robotId: this.$route.query.id }).then((res) => {
        this.workflow = res.data.workflow;
      });
    }
  },
  destroyed() {
    this.$store.commit("rpaNodeFields/clearAll");
  },
  methods: {
    open() {
      this.$refs.rpaEditRobot.open(this.robot.id);
    },
    editRobotTitle({ id, title, remark }) {
      this.$set(this.robot, "title", title);
      this.$set(this.robot, "remark", remark);
      this.$emit("changeTreeTitleRemark", { id, title });
    },
    updateRobot() {
      getRobotDetail({ robotId: this.$route.query.id }).then((res) => {
        this.robot = res.data.robot;
      });
    },
    addVersion() {
      const loading = this.$loading({
        lock: true,
        text: "新建...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      createRobotWorkflow({
        robotId: this.$route.query.id,
        duplicateWorkflowId: this.workflow.id,
      })
        .then((res) => {
          this.$set(this, "workflow", res.data.workflow);
          this.$refs.rpaVersion.fetchWorkflowList(1);
        })
        .finally(() => {
          loading.close();
        });
    },
    changeStatus() {
      if (this.workflow.versionStatus == RPA_VERSION_STATUS.enable) {
        // 停用
        this.workflow.versionStatus = RPA_VERSION_STATUS.history; // "history";
        disableRobotWorkflow(this.workflow.id).then(() => {
          getRobotWorkflow({ workflowId: this.workflow.id }).then((res) => {
            this.$set(this, "workflow", res.data.workflow);
          });
        });
        this.updateTree({ id: this.workflow.robotId, enable: false });
      } else {
        // 启用
        this.workflow.versionStatus = RPA_VERSION_STATUS.enable; // "enable";
        this.publish(RPA_VERSION_STATUS.enable);
      }
    },
    async publish(type = "publish") {
      // 发布
      let error = await this.checkNode();
      if (error) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "发布中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      saveRobotWorkflow({
        workflowId: this.workflow.id,
        rpaFlow: this.workflow.rpaFlow,
      })
        .then(() => {
          enableRobotWorkflow(this.workflow.id)
            .then((res) => {
              getRobotWorkflow({ workflowId: this.workflow.id }).then((res) => {
                this.$set(this, "workflow", res.data.workflow);
                this.updateTree({
                  id: this.workflow.robotId,
                  enable: true,
                });
              });
              if (type == "publish") {
                this.$message.success("发布成功");
              }
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {
          loading.close();
        });
    },
    updateTree({ id, enable }) {
      console.log(this.workflow.robotId, id);
      this.$emit("changeTree", { id, enable });
    },

    async save() {
      // 普通保存
      let error = await this.checkNode();
      if (error) {
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "保存中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      saveRobotWorkflow({
        workflowId: this.workflow.id,
        rpaFlow: this.workflow.rpaFlow,
      })
        .then((res) => {
          this.$message.success("保存成功");
        })
        .finally(() => {
          loading.close();
        });
    },
    async checkNode() {
      /**
       * 错误节点列表
       * @type Array
       */
      let r = await this.$refs.rpaNode.validateNode();

      if (r && r.length) {
        const h = this.$createElement;
        let list = [];
        let len = r.filter((item) => item.value == "setting").length;
        if (len) {
          list.push(h("div", { class: "msg-item" }, `${len}个节点配置不完善`));
        }
        let len2 = r.filter((item) => item.value == "deleteNode").length;
        if (len2) {
          list.push(
            h("div", { class: "msg-item" }, `${len2}个节点指定的对象已删除`),
          );
        }
        let len3 = r.filter((item) => item.value == "notSameForm").length;
        if (len3) {
          list.push(
            h("div", { class: "msg-item" }, `${len3}个节点和关联的节点非同源`),
          );
        }
        let len4 = r.filter((item) => item.value == "deleteField").length;
        if (len4) {
          list.push(
            h("div", { class: "msg-item" }, `${len4}个节点中存在删除字段`),
          );
        }

        let len5 = r.filter((item) => item.value == "errorType").length;
        if (len5) {
          list.push(
            h("div", { class: "msg-item" }, `${len5}个节点中存在类型错误`),
          );
        }

        let len6 = r.filter((item) => item.value == "noneWorkflow").length;
        if (len6) {
          list.push(
            h("div", { class: "msg-item" }, `${len6}个节点中流程不存在`),
          );
        }

        this.$msgbox({
          title: "消息",
          message: h("div", null, list),
          showCancelButton: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then((action) => {});
        return r;
      }
      if (this.workflow.rpaFlow.lines.length == 0) {
        this.$message.error("请设置流程");
        return true;
      }
      return false;
    },
    checCopyNode(key) {
      let index = this.copyKeys.findIndex((val) => val == key);
      if (index >= 0) {
        this.copyKeys.splice(index, 1);
      } else {
        this.copyKeys.push(key);
      }
    },
    changeNode(node) {
      if (node.key == this.workflow.rpaFlow.trigger.key) {
        // 触发器节点
        this.$set(this.workflow.rpaFlow, "trigger", node);
      } else {
        let index = this.workflow.rpaFlow.nodes.findIndex(
          (item) => item.key == node.key,
        );
        if (index >= 0) {
          this.$set(this.workflow.rpaFlow.nodes, index, node);
        }
      }
    },
    add(obj, fromKey) {
      this.workflow.rpaFlow.nodes.push(obj);

      // 插入节点时候，修改以fromKey为起始点的线，改为新节点的key,fromKey和新节点产生新的线，
      this.workflow.rpaFlow.lines.forEach((item) => {
        if (item.fromKey == fromKey) {
          item.fromKey = obj.key;
        }
      });

      this.workflow.rpaFlow.lines.push({
        fromKey,
        toKey: obj.key,
      });
    },
  },
};
</script>
<style lang="scss">
.rpa-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  margin: 0 auto;
  background-color: #f4f5f7;
}
</style>
<style lang="scss" scoped>
.robot-title {
  font-size: 13px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.robot-remark {
  font-size: 12px;
  line-height: 1.2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #757575;
  cursor: pointer;
}
.weui {
  display: flex;
  align-items: center;
}
.rpa-menu-item {
  padding: 0 10px;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: max-content;
  &.active {
    &::after {
      content: " ";
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: 2px;
      background-color: #01ca83;
    }
  }
}
.workflow-end-box {
  margin: 0 50px 120px;
  .workflow-end {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAABACAQAAABokzttAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiCAkRLQN+59ZwAAAAfUlEQVRo3u3ZwQ2AIBAAQbFn69ce7qEumWng2BDC49Z1H1s7vz6AQIEC4c/WPfwH13Dg9Nudztv+DQqsE1gnsE5gncA6gXUC6wTWCawTWCewTmCdwLo1XRa8vWOYztv+BgXWCawTWCewTmCdwDqBdQLrBNYJrBNYJ7BOYN0Dws4H6yyzXK8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDgtMDlUMTc6NDU6MDMrMDg6MDBVMAcOAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTA4LTA5VDE3OjQ1OjAzKzA4OjAwJG2/sgAAAABJRU5ErkJggg==)
      no-repeat;
    background-size: 56px 32px;
    height: 32px;
    margin: 0 auto;
    width: 56px;
  }
  .workflow-end-text {
    color: #9e9e9e;
    font-size: 13px;
    text-align: center;
  }
}
.rpa-page {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .rpa-container {
    padding: 20px;
    background-color: #f4f5f7;
    flex: 1;
    overflow: auto;
    position: relative;
  }
  .rpa-header {
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    box-sizing: border-box;
    height: 55px;
    padding: 0 20px;
    width: 100%;
    z-index: 1;
    .rpa-flex {
      flex: 1;
      height: 100%;
    }
    .rpa-header-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.rpa-status {
  margin-left: 10px;
  display: flex;
  align-items: center;
  background-color: #bdbdbd;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  width: 100px;
  &.enable {
    background-color: #01ca83;
    i {
      color: #01ca83;
    }
  }
  &.disabled {
    i {
      color: #bdbdbd;
    }
    .rpa-status-text {
      transform: translateX(40px);
    }
    .rpa-status-icon {
      transform: translateX(-62px);
    }
  }
  .rpa-status-text {
    padding: 10px;
    font-size: 12px;
    transition: all 0.5s;
  }
  .rpa-status-icon {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.3),
      0 0 2px rgba($color: #000000, $alpha: 0.3);
    display: flex;
    height: 34px;
    justify-content: center;
    margin: 2px;
    transition: all 0.5s;
    width: 34px;
    font-size: 20px;
    transition: all 0.5s;
  }
}
.msg-item {
  line-height: 25px;
  font-size: 13px;
}
.rpa-ab {
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 20px;
  padding: 0px 10px;
  background: rgba($color: #fff, $alpha: 0.6);
  border-radius: 2px;
  z-index: 10;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.16);
}
</style>
