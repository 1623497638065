<template>
  <div>
    <div class="data-item">
      <query-filter
        v-for="(item, index) in relationQuery"
        :key="index"
        :queryField="item"
        :index="index"
        :componentList="componentList"
        :fieldList="currentComponentList"
        @deleteRow="deleteRow"
        :isJsonForm="isJsonForm"
        :parentName="parentName"
        :formId="formId"
        :rel.sync="new_rel"
      ></query-filter>

      <div>
        <el-button
          @click="addRelation"
          type="text"
          style="padding: 5px 0px"
          icon="el-icon-plus"
          >添加条件</el-button
        >
      </div>
    </div>
    <!-- data-item end -->
    <slot></slot>
  </div>
</template>
<script>
import QueryFilter from "@/components/AdvanceQuery/QueryFilter";
import { getFormComponents } from "../utils";

export default {
  components: { QueryFilter },
  props: {
    formId: String,
    areaTree: Array,
    form: Object, // 当前组件
    label: {
      type: String,
      default() {
        return "满足以下所有条件";
      },
    },
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
    relationQuery: {
      type: Array,
      default() {
        return [];
      },
    },
    rel: {
      type: String,
      default() {
        return "and";
      },
    },
  },
  computed: {
    new_rel: {
      get() {
        return this.rel;
      },
      set(value) {
        this.$emit("update:rel", value);
      },
    },
    isJsonForm() {
      let isJson = false;
      let components = getFormComponents(this.currentComponentList);

      components.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.form.name) {
              isJson = true;
            }
          });
        }
      });
      return isJson;
    },

    parentName() {
      let name = "";
      let components = getFormComponents(this.currentComponentList);

      components.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.form.name) {
              name = item.name;
            }
          });
        }
      });
      return name;
    },
  },

  data() {
    return {
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
    };
  },
  methods: {
    deleteRow(index) {
      this.$delete(this.relationQuery, index);
    },

    addRelation() {
      this.relationQuery.push({
        key: "",
        oper: "",
        valueType: "tableField",
        value: "",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.data-item {
  margin-bottom: 20px;
  .data-label {
    margin-bottom: 10px;
  }
  .data-sel {
    width: 100%;
  }
}

.row-center {
  display: flex;
  align-items: center;
}

.weui {
  display: flex;
  align-items: center;
}
.icon-item {
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e6f7f6;
  }
  &.disabled {
    color: #c3cdda;
  }
}

.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}
.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
.icon-text {
  font-size: 14px;
}
.flex {
  flex: 1;
}
.el-value {
  flex: 1;
  overflow: hidden;
  ::v-deep {
    .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.el-prefix {
  border: 1px solid #dcdfe6;
  border-right: 0;
  height: 32px;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}
.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
