/**
 * 文件路径：/src/tinymce/plugins/images/index.js
 */
import request from "@/utils/request"; 
tinymce.PluginManager.add('images', function (editor) {
  let pluginName = '多图上传'

  let uploadImages = function () {
        var input = document.createElement('input');
      const filetype=".png,.gif,.jpg,.jpeg"
        input.setAttribute('type', 'file');
      input.setAttribute('multiple', 'multiple');
      input.setAttribute('accept', filetype);
        input.click();
        input.onchange = function(event) {
            var files = event.target.files;
            saveImages(files);
        }
  }
  let saveImages = function (files) {

    var files_sum = files.length;
 
      for (let i = 0; i < files_sum; i++) {
           var html = '';
        let file = files[i];
            if ( file.size / 1024 / 1024 > 2) {
              alert(file.name+'上传失败，图片大小请控制在 2M 以内')
              return
            }
              let params = new FormData();
            params.append("attachmentFile", file);
            let config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
            request({
              url: "/attachment/upload",
              method: "post",
              headers: config.headers,
              data: params,
              onUploadProgress: (progressEvent) => {},
            })
              .then((resp) => {
                if (resp.code == 0) {
                  html = '<img src="' + resp.data.attachment.url + '" />	';
                  editor.insertContent(html)
                  //imgList.push(resp.data.attachment.url); //上传成功，在成功函数里填入图片路径
                } else {
                  alert("上传失败");
                }
              })
              .catch((error) => {
                alert("上传出错，服务器开小差了呢");
              });

        }

  }
  //添加图标
  editor.ui.registry.getAll().icons.images || editor.ui.registry.addIcon('images', '<svg width="24" height="24" focusable="false"><path d="M5 15.7l3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7zM5 18V19h3l2.8-2.9-2-2L5 17.9zm14-3l-2.5-2.4-6.4 6.5H19v-4zM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 01-1-1V4c0-.6.4-1 1-1zm6 8a2 2 0 100-4 2 2 0 000 4z" fill-rule="nonzero"></path></svg>')

  //注册toolbar按钮
  editor.ui.registry.addButton('images', {
    icon: 'images',
    tooltip: pluginName,
    onAction: function () {
      uploadImages()

    }
  })
  //注册菜单选项
  editor.ui.registry.addMenuItem('images', {
    icon: 'images',
    text: pluginName,
    onAction: function () {
      uploadImages

    }
  })
  return {
    getMetadata: function () {
      return {
        name: pluginName,
        url: "https://dotatong.cn",
      }
    }
  }
})