<template>
  <div class="etl-attribute-container">
    <template v-if="tab == 'attribute'">
      <div class="container-left">
        <div style="font-size: 14px">分组字段</div>
        <etl-group-fields
          title="添加分组字段"
          :fieldList="xFeildOptions"
          @addItem="addXFields"
        ></etl-group-fields>
        <div class="scroll-body">
          <draggable
            :list="node.xfields"
            animation="300"
            handle=".icon-pc-drag"
            @end="changeChildrenData"
          >
            <etl-group-x-field
              v-for="(item, index) in node.xfields"
              :key="index"
              :node="item"
              :xfields="node.xfields"
              :index="index"
              :isDelete="isDelete"
              @changeChildrenData="changeChildrenData"
            ></etl-group-x-field>
          </draggable>
        </div>
      </div>
      <div class="container-right">
        <div class="pivot-header">
          <div class="weui">
            <div class="label">列字段</div>
            <el-select
              v-model="colFiled"
              placeholder=""
              size="small"
              style="margin-right: 10px"
            >
              <el-option
                v-for="item in colFields"
                :key="item.name"
                :label="item.comment"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <field-value-select
              v-if="colFiled && componentName"
              :fieldName="colFiled"
              size="small"
              :fetchParams="fetchParams"
              :componentName="componentName"
              :multi="true"
              :hasCheckAll="true"
              :allShow="false"
              v-model="colValue"
            ></field-value-select>
          </div>
          <div>
            <el-button @click="addCol" type="text" icon="el-icon-plus"
              >添加列</el-button
            >
            <el-button
              type="text"
              style="margin-left: 8px"
              @click="dialogVisible = true"
              >批量编辑</el-button
            >
          </div>
        </div>
        <el-scrollbar ref="scrollBody" class="el-scroll-container">
          <draggable
            :list="node.colValues"
            animation="300"
            handle=".pivot-drag"
            @end="changeChildrenData"
            class="col-values"
            v-if="node.colValues"
          >
            <etl-row-edit
              v-for="(item, index) in node.colValues"
              :key="item.name"
              :index="index"
              :node="item"
              :editColIndex.sync="editColIndex"
              :values="node.colValues"
              @deleteValues="deleteValues"
              @changeChildrenData="changeChildrenData"
            ></etl-row-edit>
          </draggable>
        </el-scrollbar>
        <div class="pivot-bottom">
          <div class="label">值字段</div>
          <el-select
            v-model="metricField"
            placeholder=""
            size="small"
            style="margin-right: 10px"
          >
            <el-option
              v-for="item in metricOptions"
              :key="item.name"
              :label="item.comment"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="node.metric"
            v-model="node.metric.oper"
            placeholder=""
            size="small"
          >
            <el-option
              v-for="item in operOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </template>
    <template v-else>
      <preview-error v-if="isError"></preview-error>
      <etl-preview
        v-else
        :node="node"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-preview>
    </template>

    <el-dialog
      title="批量编辑"
      :visible.sync="dialogVisible"
      width="700px"
      @closed="closed"
      @open="open"
    >
      <div style="padding: 20px">
        <div style="font-size: 14px; margin-bottom: 8px">每行对应每一列</div>
        <el-input
          type="textarea"
          v-model="textarea"
          style="width: 100%; height: 336px"
          :rows="15"
          placeholder=""
        ></el-input>
      </div>
      <div slot="footer">
        <el-button
          @click="dialogVisible = false"
          style="margin-right: 10px"
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveForm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import { pivotColFields, groupXFeildFields } from "@/zgg-core/whiteList";
import nodeMixin from "./nodeMixin";
import { changeChildrenData, deleteMixins, getFieldListByNode } from "./util";
import EtlGroupXField from "./EtlGroupXField";
import EtlGroupFields from "./EtlGroupFields";
import { isEmpty, uuid } from "@/zgg-core/utils";
import EtlRowEdit from "./EtlRowEdit";
import EtlPreview from "./EtlPreview";
import PreviewError from "./PreviewError";

export default {
  mixins: [nodeMixin, deleteMixins],
  components: {
    EtlGroupXField,
    EtlGroupFields,
    Draggable,
    EtlRowEdit,
    EtlPreview,
    PreviewError,
  },
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },
  computed: {
    componentName() {
      let componentName = "";
      if (this.node.colField) {
        componentName = this.node.colField.componentName;
      }
      return componentName;
    },
    fetchParams() {
      let line = this.etlLines.find((item) => item.toKey == this.node.key);
      let componentName = "";
      if (this.node.colField) {
        componentName = this.node.colField.componentName;
      }
      if (line) {
        return {
          queryObject: {
            distinctFieldName: this.colFiled,
            advanceQuery: [
              {
                key: this.colFiled,
                oper: "like",
                componentName,
                value: "",
              },
            ],
          },
          stageKey: line.fromKey,
          stageFlow: {
            stages: this.etlList,
            lines: this.etlLines,
          },
        };
      }
      return {};
    },

    operOptions() {
      if (
        this.node.metric &&
        this.node.metric.field.componentName == "input_number"
      ) {
        return [
          {
            label: "求和",
            value: "sum",
          },
          {
            label: "平均值",
            value: "avg",
          },
          {
            label: "最大值",
            value: "max",
          },
          {
            label: "最小值",
            value: "min",
          },
          {
            label: "计数",
            value: "count",
          },
          {
            label: "去重计数",
            value: "countDistinct",
          },
        ];
      } else {
        return [
          {
            label: "计数",
            value: "count",
          },
          {
            label: "去重计数",
            value: "countDistinct",
          },
        ];
      }
    },
    metricField: {
      get() {
        if (this.node.metric) {
          return this.node.metric.field.name;
        }
      },
      set(val) {
        let field = this.metricOptions.find((item) => item.name == val);
        let metric = {
          field: this.buildField(field),
          name: uuid(),
          title: field.comment,
          oper: field.componentName == "input_number" ? "sum" : "count",
        };
        this.$set(this.node, "metric", metric);
        this.changeChildrenData();
      },
    },
    componentList() {
      let fromKey = this.etlLines.find(
        (item) => item.toKey == this.node.key,
      ).fromKey;
      let fromNode = this.etlList.find((item) => item.key == fromKey);

      return getFieldListByNode(fromNode, this.etlList);
    },
    colFields() {
      return this.componentList.filter((item) =>
        pivotColFields.includes(item.componentName),
      );
    },
    colFiled: {
      get() {
        if (this.node.colField) {
          return this.node.colField.name;
        }
        return "";
      },
      set(val) {
        let field = this.colFields.find((item) => item.name == val);
        this.$set(this.node, "colField", this._.cloneDeep(field));
        this.$set(this.node, "colValues", []);
        this.changeChildrenData();
      },
    },
    xFeildOptions() {
      let list = this._.cloneDeep(this.componentList);

      list = list.filter((item) =>
        groupXFeildFields.includes(item.componentName),
      );
      list.forEach((field) => {
        let key = field.name;
        if (this.node.xfields) {
          field.disabled =
            this.node.xfields.findIndex((item) => item.field.name == key) >= 0;
        } else {
          field.disabled = false;
        }
      });

      return list;
    },

    metricOptions() {
      let list = this._.cloneDeep(this.componentList);
      list = list.filter((item) => !item.array);
      return list;
    },
    colValue: {
      get() {
        let list = [];
        this.node.colValues.forEach((item) => {
          list.push(item.comment);
        });
        return list;
      },
      set(val) {
        let list = [];
        if (!isEmpty(val)) {
          list = val;
        }
        let colValues = this.node.colValues.filter((item) =>
          list.includes(item.comment),
        );
        let values = colValues.map((item) => item.comment);
        list
          .filter((value) => !values.includes(value))
          .forEach((value) => {
            colValues.push({
              componentName: "input_number",
              comment: value,
              name: uuid(),
            });
          });
        this.node.colValues = colValues;
      },
    },
  },
  data() {
    return {
      editColIndex: -1,
      textarea: "",
      dialogVisible: false,
    };
  },
  methods: {
    closed() {
      this.textarea = "";
    },
    open() {
      let textarea = "";
      this.node.colValues.forEach((item) => {
        if (textarea != "") {
          textarea += "\n";
        }
        textarea += item.comment;
      });
      this.textarea = textarea;
    },
    saveForm() {
      let rows = [];
      if (!isEmpty(this.textarea)) {
        rows = this.textarea.split("\n");
      }
      let oldValues = this._.cloneDeep(this.node.colValues);
      let colValues = [];
      if (rows.length) {
        rows
          .filter((item) => !isEmpty(item))
          .forEach((val) => {
            let old = oldValues.find((item) => item.comment == val);
            let obj = {
              componentName: "input_number",
              comment: val,
            };
            if (old) {
              obj.name = old.name;
            } else {
              obj.name = uuid();
            }
            colValues.push(obj);
          });
      }
      this.node.colValues = colValues;
      this.dialogVisible = false;
    },
    deleteValues(index) {
      this.$delete(this.node.colValues, index);
    },
    addCol() {
      this.editColIndex = this.node.colValues.length;
      this.node.colValues.push({
        name: uuid(),
        componentName: "input_number",
        comment: "",
      });
    },
    changeChildrenData() {
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
    buildField(item) {
      let field = {
        name: item.name,
        comment: item.comment,
        componentName: item.componentName,
        collection: item.collection,
        picker: item.picker,
        component: {
          ...this._.cloneDeep(item.component),
          title: item.comment,
          key: item.name,
          name: item.name,
          componentName: item.componentName,
          picker: item.picker,
        },
      };
      return field;
    },
    addXFields(item) {
      // 添加维度
      let node = {};
      node.title = item.comment;
      if (["address_input", "position_input"].includes(item.componentName)) {
        node.addressPrecision = {
          picker: item.picker == "address" ? "county" : item.picker,
        };
      } else if (item.componentName == "date_picker") {
        node.datePrecision = {
          picker: ["year", "month", "date"].includes(item.picker)
            ? item.picker
            : "date",
        };
      }

      node.field = this.buildField(item);

      if (!this.node.xfields) {
        this.$set(this.node, "xfields", []);
      }
      this.node.xfields.push(node);
      this.changeChildrenData();
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-body {
  flex: 1;
  overflow: auto;
}
.container-left {
  width: 310px;
  height: 100%;
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.container-right {
  flex: 1;
  height: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.el-scroll-container {
  flex: 1;
  overflow: hidden;
  width: 100%;
}
.pivot-header {
  height: 60px;
  border-bottom: solid 1px #e0e0e0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
}
.pivot-bottom {
  height: 60px;
  border-top: solid 1px #e0e0e0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.weui {
  display: flex;
  align-items: center;
}
.label {
  font-size: 14px;
  margin-right: 8px;
}
.col-values {
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  height: 220px;
}
</style>
