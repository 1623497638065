<template>
  <div ref="chart" class="chart-column"></div>
</template>
<script>
import test from "@/utils/test";
import { getAggreation } from "../api";
import {
  chartColors,
  dealWithChartValue,
  chartColorList,
  chartFontSize,
  chartLineHeight,
  chartItemWidth,
  getChartColorConfig,
} from "./chartUtil";
import { getTextWidth } from "@/utils/tools";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";
import { format } from "mathjs";
export default {
  mixins: [chartResize],
  data() {
    return {
      list: [],
      view: null,
      option: null,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
    componentName: {
      type: String,
      default: "chart_pie",
    },
  },
  destroyed() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  created() {},
  methods: {
    getData(view, dataFilter) {
      this.$set(this, "view", view);

      if (!view.metrics.length) {
        return;
      }
      if (!view.xFields.length) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        if (view.dataSource.queryObject.pageSize) {
          queryObject.pageSize = view.dataSource.queryObject.pageSize;
        }
        if (view.dataSource.queryObject.advanceQuery) {
          queryObject.advanceQuery = view.dataSource.queryObject.advanceQuery;
        }
      }
      queryObject.xFields = view.xFields;
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        // console.log("chart data");
        // console.log(res);
        this.list = res.data.list;
        this.buildChart(view);
      });
    },
    buildChart(view) {
      const result = getChartColorConfig(view, this.isH5);
      this.remindColor = result.remindColor;
      let textStyle = result.textStyle;
      let list = this.list;
      // 基于准备好的dom，初始化echarts实例
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(this.$refs.chart);

      let option = {
        textStyle: textStyle,
        color: this.remindColor ? this.remindColor : chartColors,
        tooltip: {
          show: true,
        },
        legend: {
          icon: "circle",
          textStyle: {
            fontSize: chartFontSize,
            lineHeight: chartLineHeight,
            ...textStyle,
          },
          itemWidth: chartItemWidth,
        },
      };

      let series = [];

      //   if (lengedData.findIndex((v) => v === item.title) == -1) {
      //     lengedData.push(item.title);
      //   }
      let data = [];
      let xField = view.xFields[0].field.name;
      let valueField = view.metrics[0].name;

      let xfieldComponent = this._.cloneDeep(view.xFields[0].field.component);
      // 当维度为日期且存在汇总方式时
      let datePrecision = view.xFields[0].datePrecision;
      if (xfieldComponent.componentName == "address_input") {
        if (xfieldComponent.picker == "address") {
          xfieldComponent.picker = "county";
        }
      }

      list.forEach((row) => {
        let formatter = "";
        if (view.chartLabel.displayXField) {
          formatter = "{b}";
        }
        if (view.chartLabel.displayMetric) {
          if (formatter != "") {
            formatter += "  ";
          }
          if (view.metrics[0].numberFormat) {
            formatter += row[valueField + "Format"];
          } else {
            formatter += "{c}";
          }
        }
        if (view.chartLabel.displayMetricPercent) {
          if (formatter != "") {
            formatter += "  ";
          }
          formatter += "\n({d}%)";
        }
        data.push({
          value: row[valueField],
          name: dealWithChartValue(row, xfieldComponent, { datePrecision }),
          label: {
            formatter,
          },
          tooltip: {
            valueFormatter(v) {
              if (view.metrics[0].numberFormat) {
                return row[valueField + "Format"];
              }
              return v;
            },
          },
        });
      });
      let obj = {
        id: "pie",
        //   name: item.title,
        type: "pie",
        data,
      };
      if (view.chartLabel.enable) {
        obj.label = {
          show: true,
          fontSize: 13,
          overflow: "none",
          textStyle: textStyle,
        };
      } else {
        obj.label = {
          show: false,
        };
      }
      if (view.chartLegend && view.chartLegend.enable) {
        let legend = {
          type: "scroll",
          show: true,
          textStyle: {
            fontSize: chartFontSize,
            ...textStyle,
          },
        };
        if (view.chartLegend.position == "top") {
          legend.x = "center";
          legend.y = "top";
        }
        if (view.chartLegend.position == "bottom") {
          legend.x = "center";
          legend.y = "bottom";
        }
        if (view.chartLegend.position == "left") {
          legend.x = "left";
          legend.y = "center";
          legend.orient = "vertical";
          legend.textStyle.width = 50;
          legend.textStyle.overflow = "breakAll";
        }
        if (view.chartLegend.position == "right") {
          legend.x = "right";
          legend.y = "center";
          legend.orient = "vertical";
          legend.textStyle.width = 50;
          legend.textStyle.overflow = "breakAll";
        }
        Object.assign(option.legend, legend);
      } else {
        option.legend.show = false;
      }
      this.resizePieChart(obj);
      series.push(obj);
      option.series = series;

      // 使用刚指定的配置项和数据显示图表。

      this.myChart.setOption(option);
      this.setChartResize();
      this.resizeChart();
    },
    resizePieChart(item) {
      if (this.myChart) {
        let domWidth = this.myChart.getWidth();
        let domHeight = this.myChart.getHeight();
        let minSide = domWidth > domHeight ? domHeight : domHeight;
        let lineHeight = 16;
        let obj = item ? item : { id: "pie", center: ["50%", "50%"] };
        if (this.view.chartLegend && this.view.chartLegend.enable) {
          if (this.view.chartLegend.position == "top") {
            obj.center = [domWidth / 2, domHeight / 2 + lineHeight / 2];
            domHeight = domHeight - lineHeight;
            minSide = domWidth >= domHeight ? domHeight : domWidth;
          }
          if (this.view.chartLegend.position == "bottom") {
            obj.center = [domWidth / 2, domHeight / 2 - lineHeight / 2];
            domHeight = domHeight - lineHeight;
            minSide = domWidth >= domHeight ? domHeight : domWidth;
          }
          if (this.view.chartLegend.position == "left") {
            let legendWidth = 75;
            obj.center = [domWidth / 2 + legendWidth / 2, domHeight / 2];
            domWidth = domWidth - legendWidth;
            minSide = domHeight >= domWidth ? domWidth : domHeight;
          }
          if (this.view.chartLegend.position == "right") {
            let legendWidth = 75;
            obj.center = [domWidth / 2 - legendWidth / 2, domHeight / 2];
            domWidth = domWidth - legendWidth;
            minSide = domHeight >= domWidth ? domWidth : domHeight;
          }
        }
        let radius = (75 * minSide) / 100 / 2;
        if (this.view.hasOwnProperty("piePercentage")) {
          radius = (this.view.piePercentage * minSide) / 100 / 2;
        }
        if (this.view.pieType == "solid") {
          obj.radius = radius;
        } else if (this.view.pieType == "thick") {
          obj.radius = [radius * 0.5, radius];
        } else {
          obj.radius = [radius * 0.8, radius];
        }
        if (item) return;
        this.myChart.setOption({ series: [obj] }, { replaceMerge: "series" });
      }
    },
    // 设置图例所占的空间, 防止与图表重叠
    resetLegendSpace() {
      if (
        (this.view.chartLegend.enable &&
          this.view.chartLegend.position === "left") ||
        this.view.chartLegend.position === "right"
      ) {
        let offsetWidth = this.myChart.getWidth();
        let longestStr = "";
        this.list.forEach((item) => {
          if (item._id.length > longestStr.length) {
            longestStr = item._id;
          }
        });
        this.whiteSpace =
          getTextWidth(longestStr, "normal", 3) + offsetWidth / 2;
        if (this.view.chartLegend.position === "left") {
          this.myChart.setOption({
            series: {
              center: [this.whiteSpace, "50%"],
            },
          });
        } else {
          this.myChart.setOption({
            series: {
              center: [offsetWidth - this.whiteSpace, "50%"],
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-column {
  height: 100%;
}
</style>
