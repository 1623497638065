<template>
  <div class="left-item">
    <i class="iconfont-fx-pc icon-pc-form mr"></i>
    <span v-text="node.title"></span>
    <i
      @click.prevent.stop="showPopup"
      class="el-icon-circle-plus"
      style="
        font-size: 16px;
        position: absolute;
        right: 10px;
        top: 18px;
        color: #409eff;
      "
    ></i>
    <div
      v-if="visible"
      ref="popSelect"
      class="pop-select"
      :style="`bottom:${bottom}px;left:${left}px;`"
      v-clickoutside="clickoutside"
    >
      <div class="pop-arrow"></div>
      <el-input
        prefix-icon="el-icon-search"
        v-model="keyword"
        size="small"
        placeholder=""
        style="margin-bottom: 10px"
      ></el-input>
      <el-checkbox v-model="allCheck" :indeterminate="indeterminate"
        >全选</el-checkbox
      >
      <div class="field-scroll">
        <div v-for="opt in componentList" :key="opt.name">
          <el-checkbox
            :label="opt.name"
            :value="hasCheck(opt.name)"
            @change="changeOpt($event, opt)"
            >{{ opt.comment }}</el-checkbox
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { isEmpty, uuid } from "@/zgg-core/utils";
export default {
  directives: { Clickoutside },
  props: {
    node: Object,
    unionFields: Array,
  },
  data() {
    return {
      visible: false,
      keyword: "",
      show: false,
    };
  },
  beforeDestroy() {
    if (this.$refs.popSelect) {
      this.$refs.popSelect.remove();
    }
    this.visible = false;
    this.show = false;
  },

  computed: {
    indeterminate() {
      let allCount = this.componentList.length;
      let count = 0;
      let names = this.componentList.map((item) => item.name);
      this.unionFields.forEach((item) => {
        let isExist =
          item.stageFields.findIndex(
            (row) =>
              row.collection == this.node.key && names.includes(row.name),
          ) >= 0;
        if (isExist) {
          count++;
        }
      });

      return count > 0 && count < allCount;
    },
    allCheck: {
      get() {
        let allCount = this.componentList.length;
        let count = 0;
        let names = this.componentList.map((item) => item.name);
        this.unionFields.forEach((item) => {
          let isExist =
            item.stageFields.findIndex(
              (row) =>
                row.collection == this.node.key && names.includes(row.name),
            ) >= 0;
          if (isExist) {
            count++;
          }
        });

        return count > 0 && count == allCount;
      },
      set(val) {
        let collection = this.node.key;
        if (val) {
          // 全选
          let list = [];
          this.unionFields.forEach((item) => {
            let obj = item.stageFields.find(
              (row) => row.collection == collection,
            );
            if (obj) {
              list.push(obj.name);
            }
          });
          this.componentList
            .filter((item) => !list.includes(item.name))
            .forEach((field) => {
              let obj = {
                field: {
                  collection: collection,
                  name: uuid(),
                  componentName: field.componentName,
                  picker: field.picker,
                  comment: field.comment,
                  component: this._.cloneDeep(field.component),
                },
                stageFields: [],
              };
              if (field.name == "flowStatus") {
                obj.field.name = uuid() + "flowStatus";
              }
              obj.stageFields.push(this._.cloneDeep(field));
              this.unionFields.push(obj);
            });
        } else {
          // 取消全选
          this.unionFields.forEach((item) => {
            let index = item.stageFields.findIndex(
              (child) => child.collection == collection,
            );
            if (index >= 0) {
              this.$delete(item.stageFields, index);
            }
          });
          let unionFields = this.unionFields.filter(
            (item) => item.stageFields.length > 0,
          );
          this.$emit("update:unionFields", unionFields);
        }
        this.$emit("changeChildrenData");
      },
    },
    componentList() {
      let list = this.node.fields.filter(
        (item) => !["id"].includes(item.componentName),
      );
      if (isEmpty(this.keyword)) {
        return list;
      }
      return list.filter((item) => item.comment.indexOf(this.keyword) >= 0);
    },
  },
  methods: {
    hasCheck(name) {
      let isCheck = false;
      isCheck =
        this.unionFields.findIndex(
          (item) =>
            item.stageFields.findIndex(
              (field) =>
                field.collection == this.node.key && field.name == name,
            ) >= 0,
        ) >= 0;
      return isCheck;
    },
    changeOpt(val, field) {
      if (val) {
        // 选中
        let obj = {
          field: {
            collection: this.node.key,
            name: uuid(),
            componentName: field.componentName,
            picker: field.picker,
            comment: field.comment,
            component: this._.cloneDeep(field.component),
          },
          stageFields: [],
        };
        if (field.name == "flowStatus") {
          obj.field.name = uuid() + "flowStatus";
        }
        obj.stageFields.push(this._.cloneDeep(field));
        this.unionFields.push(obj);
      } else {
        // 取消
        this.unionFields.forEach((item) => {
          let index = item.stageFields.findIndex(
            (row) => row.collection == this.node.key && row.name == field.name,
          );
          if (index >= 0) {
            this.$delete(item.stageFields, index);
          }
        });
        let unionFields = this.unionFields.filter(
          (item) => item.stageFields.length > 0,
        );
        this.$emit("update:unionFields", unionFields);
      }
      this.$emit("changeChildrenData");
    },
    clickoutside() {
      this.timer = setTimeout(() => {
        this.visible = false;
      }, 100);
    },
    showPopup() {
      this.bottom =
        document.documentElement.clientHeight -
        this.$el.getBoundingClientRect().top +
        12;
      this.left = this.$el.getBoundingClientRect().left;
      clearTimeout(this.timer);
      this.visible = !this.visible;

      this.$nextTick(() => {
        if (this.visible) {
          document.body.append(this.$refs.popSelect);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.left-item {
  width: 160px;
  height: 50px;
  padding: 0 8px;
  line-height: 50px;
  background: #fff;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
}
.mr {
  margin-right: 5px;
}
.pop-select {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  .pop-arrow {
    bottom: 1px;
    left: 10%;
    margin-right: 3px;
    border-width: 6px 6px 0;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
    width: 0;
    height: 0;
    border-color: transparent transparent;
    border-style: solid;
    position: absolute;
    &::after {
      content: " ";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 6px 6px 0;
      top: 1px;
      margin-left: -6px;
    }
  }
}
.field-scroll {
  max-height: 360px;
  overflow: auto;
}
</style>
