<template>
  <div class="row">
    <div
      @click="visible = true"
      v-if="initFieldValues && initFieldValues.length"
      class="filter-edit"
    >
      <span>已设置关联表默认值</span> <i class="el-icon-edit-outline"></i>
    </div>
    <div
      @click="visible = true"
      v-else
      class="label row-between"
      style="width: 100%"
    >
      <span>关联表默认值设置</span>
      <i class="el-icon-plus"></i>
    </div>
    <div v-if="componentName == 'json_form'" style="padding: 8px 0 0; font-size: 12px; color: #999;">
      设置之后子表新增时无法触发公式和数据联动
    </div>
    <el-dialog
      @open="open"
      title="设置关联表默认值"
      :visible.sync="visible"
      width="600px"
    >
      <div style="padding: 10px 20px">
        <div v-for="(item, index) in list" :key="item.key" class="weui mb">
          <template>
            <el-tooltip
              v-if="isDelete(item.key)"
              effect="dark"
              content="字段已删除"
              placement="top"
            >
              <div
                class="el-input el-input--small delete-row"
                style="margin-left: 10px; width: 180px"
              >
                <div
                  class="el-input__inner"
                  style="text-align: center"
                  v-text="item.key"
                ></div>
              </div>
            </el-tooltip>
            <el-select
              v-model="item.key"
              placeholder="请选择初始化字段"
              size="small"
              @change="change($event, item)"
            >
              <el-option
                v-for="opt in columns"
                :key="opt.name"
                :label="opt.title"
                :value="opt.name"
                :disabled="list.findIndex((col) => col.key == opt.name) >= 0"
              >
              </el-option>
            </el-select>
          </template>

          <div style="margin-left: 10px">等于</div>
          <div class="weui el-value" style="margin-left: 10px">
            <div
              v-if="isManual(item.key)"
              class="weui el-prefix"
              style="width: 49px"
            >
              <div v-if="item.valueType == 'tableField'" class="icon-form">
                A
              </div>
              <i v-else class="el-icon-edit-outline icon-edit"></i>
              <i
                class="el-icon-caret-bottom"
                style="color: #91a1b7; margin-left: 2px"
              ></i>
            </div>
            <el-popover
              popper-class="pop-over"
              placement="bottom-start"
              v-model="item.popverVisible"
              trigger="click"
              v-else
            >
              <div slot="reference" class="weui el-prefix" style="width: 49px">
                <div v-if="item.valueType == 'tableField'" class="icon-form">
                  A
                </div>
                <i v-else class="el-icon-edit-outline icon-edit"></i>
                <i
                  class="el-icon-caret-bottom"
                  style="color: #91a1b7; margin-left: 2px"
                ></i>
              </div>
              <div style="padding: 5px 0">
                <div
                  @click="checkItemType('tableField', item)"
                  class="weui icon-item"
                >
                  <div class="icon-form">A</div>
                  <div>当前表单</div>
                </div>
                <div
                  @click="checkItemType('custom', item)"
                  class="weui icon-item"
                >
                  <i class="el-icon-edit-outline icon-edit"></i>
                  <div>自定义</div>
                </div>
              </div>
            </el-popover>

            <!-- 当前表单字段 -->
            <table-field
              v-if="item.valueType == 'tableField'"
              :fieldList="currentComponentList"
              :field="item"
              style="flex: 1"
              :parentName="parentName"
              :isJsonForm="isJsonForm"
            ></table-field>
            <query-input
              v-else
              :field="item"
              :areaTree="areaTree"
              :areaProps="areaProps"
              style="flex: 1"
              v-model="item.value"
              :componentList="componentList"
            ></query-input>
          </div>
          <i
            style="margin-left: 10px"
            @click="deleteRow(index)"
            class="el-icon-delete"
          ></i>
        </div>
        <div
          v-if="
            columns.filter(
              (item) => list.findIndex((col) => col.key == item.name) == -1,
            ).length
          "
          @click="addFields"
          style="margin-top: 10px"
        >
          <el-button type="text" style="padding: 5px 0px" icon="el-icon-plus"
            >添加字段</el-button
          >
        </div>
      </div>
      <div slot="footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { referenceDataInitFields } from "@zgg-core-utils/whiteList";
import { mapState } from "vuex";
import { getFormComponents } from "@zgg-core-utils/utils";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
export default {
  props: {
    name: String, // 当前组件的name
    componentName: {
      type: String,
      default() {
        return "";
      },
    },
    initFieldValues: {
      type: Array,
      default() {
        return [];
      },
    },
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    columns() {
      let list = this.componentList.filter((item) =>
        checkFieldAbility(item, "setDefaultOnAdd"),
      );
      return list;
    },
    isJsonForm() {
      let isJson = false;
      let componentList = getFormComponents(this.currentComponentList);
      componentList.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.name) {
              isJson = true;
            }
          });
        }
      });
      return isJson;
    },

    parentName() {
      let name = "";
      let componentList = getFormComponents(this.currentComponentList);
      componentList.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.name) {
              name = item.name;
            }
          });
        }
      });
      return name;
    },
  },

  data() {
    return {
      visible: false,
      list: [],
    };
  },

  methods: {
    save() {
      this.$emit("update:initFieldValues", this._.cloneDeep(this.list));
      this.visible = false;
    },
    deleteRow(index) {
      let row = this._.cloneDeep(this.list[index]);
      this.$delete(this.list, index);
    },
    change(val, item) {
      let node = this.columns.find((item) => item.name == val);
      this.$set(item, "valueType", "tableField");
      this.$set(item, "value", "");
      this.$set(item, "componentName", node.componentName);
      this.$set(item, "picker", node.picker);
      if (node.componentName == "reference_data") {
        this.$set(item, "collection", node.tableName);
      }
    },
    checkItemType(valueType, item) {
      this.$set(item, "popverVisible", false);

      if (item.valueType == valueType) {
        return;
      }

      this.$set(item, "value", "");
      this.$set(item, "valueType", valueType);
    },
    isManual(key) {
      let component = this.columns.find((item) => item.name == key);
      let arr = [
        "user_select",
        "user_list_select",
        "department_select",
        "department_list_select",
        "reference_data",
        "attachment_uploader",
        "image_uploader",
      ];
      if (component) {
        return arr.includes(component.componentName);
      }
      return false;
    },
    addFields() {
      let node = this.columns.find(
        (item) => this.list.findIndex((col) => col.key == item.name) == -1,
      );
      if (node) {
        let collection;
        if (node.componentName == "reference_data") {
          collection = node.tableName;
        }
        this.list.push({
          key: node.name,
          oper: "eq",
          valueType: "tableField",
          componentName: node.componentName,
          picker: node.picker,
          collection,
          value: "",
        });
      } else {
        this.$message.error("没有可以初始化的字段了");
      }
    },
    open() {
      this.list = this._.cloneDeep(this.initFieldValues);
    },
    isDelete(fieldName) {
      this.columns.findIndex((item) => item.key == fieldName) == -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-edit {
  border: solid 1px #dcdfe6;
  border-radius: 5px;
  padding: 8px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
}
.row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row {
  margin: 10px;

  .label {
    width: 100px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}

.delete-row {
  ::v-deep {
    .el-input__inner {
      color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
.weui {
  display: flex;
  align-items: center;
}
.mb {
  margin-bottom: 10px;
}
.el-value {
  flex: 1;
  overflow: hidden;
  ::v-deep {
    .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.el-prefix {
  border: 1px solid #dcdfe6;
  border-right: 0;
  height: 32px;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 49px;
}

.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}
.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
.icon-text {
  font-size: 14px;
}
</style>
