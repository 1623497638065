<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="1000px"
    append-to-body
    destroy-on-close
  >
    <form-edit
      v-if="visible"
      :formComponent="formComponent"
      :areaTree="areaTree"
      ref="form"
    ></form-edit>

    <div style="height: 20px"></div>
  </el-dialog>
</template>
<script>
import FormEdit from "./FormEdit";

export default {
  components: { FormEdit },
  props: {
    areaTree: Array,
    title: {
      type: String,
      default: "表单预览",
    },
  },
  data() {
    return {
      visible: false,
      formComponent: {},
    };
  },
  methods: {
    init(formComponent) {
      this.formComponent = this._.cloneDeep(formComponent);

      this.formComponent.components.forEach(async (item, index) => {
        if (item.componentName == "tab") {
          this.$set(this.activeName, "tab_" + index, 0);
        }
        if (!item.name) {
          item.name = "random_" + index;
        }
      });
      this.visible = true;
    },
  },
};
</script>
