<template>
  <div class="etl-attribute">
    <div class="etl-header">
      <div class="weui" style="flex: 1">
        <div class="etl-icon">
          <i class="iconfont-fx-pc" :class="`icon-data-${node.stageType}`"></i>
        </div>
        <div class="etl-name" v-text="nodeTitle"></div>
        <template
          v-if="node.stageType != 'input' && node.stageType != 'output'"
        >
          <div
            class="etl-tab"
            @click="tab = 'attribute'"
            :class="tab == 'attribute' ? 'active' : ''"
          >
            节点配置
          </div>
          <div
            class="etl-tab"
            @click="tab = 'preview'"
            :class="tab == 'preview' ? 'active' : ''"
          >
            数据预览
          </div>
        </template>
        <div
          v-if="
            !['count', 'prevConfig'].includes(errorType) &&
            node.stageType != 'input'
          "
          class="error-tip"
          v-text="errorText"
        ></div>
      </div>
      <div class="weui">
        <div style="font-size: 14px; width: 90px">节点名称</div>
        <el-input
          v-model="node.title"
          size="small"
          placeholder="请输入节点名称"
          :disabled="node.stageType == 'input'"
        ></el-input>
      </div>
    </div>
    <div class="etl-attr-body">
      <div
        v-if="
          node.stageType != 'input' &&
          ['count', 'prevConfig'].includes(errorType)
        "
        class="error-info"
      >
        <el-image style="width: 225px" :src="image"></el-image>
        <div class="error-text" v-text="errorText"></div>
      </div>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>
<script>
import sourceList from "./sourceList";
import t1 from "./assets/t1.png";
import t2 from "./assets/t2.png";
import t3 from "./assets/t3.png";
import t4 from "./assets/t4.png";
import { chkNodeConfig } from "./util";
export default {
  props: {
    node: Object,
    etlLines: Array,
    etlList: Array,
  },
  computed: {
    nodeTitle() {
      let item = sourceList.find(
        (item) => item.stageType == this.node.stageType,
      );
      return item.title;
    },
    image() {
      if (!["count", "prevConfig"].includes(this.errorType)) {
        return;
      }
      if (this.errorType == "count") {
        if (this.node.stageType == "union") {
          return t3;
        } else if (this.node.stageType == "join") {
          return t2;
        } else {
          return t1;
        }
      } else {
        return t4;
      }
    },
    errorType() {
      return this.errorInfo.value;
    },
    errorText() {
      return this.errorInfo.label;
    },
    errorInfo() {
      let obj = chkNodeConfig(this.node, this.etlList, this.etlLines);
      if (obj) {
        return obj;
      }
      return {};
    },
  },

  data() {
    return {
      tab: "attribute",
    };
  },
};
</script>
<style lang="scss" scoped>
.etl-attribute {
  height: 380px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .weui {
    display: flex;
    align-items: center;
  }
  .etl-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 12px;
    line-height: 40px;
    border-bottom: 1px solid #e9e9e9;

    .etl-icon {
      width: 25px;
      height: 25px;
      margin: 0 10px;
      color: #fff;
      line-height: 25px;
      text-align: center;
      background: #248af9;
      border-radius: 4px;
    }
    .etl-name {
      font-size: 14px;
      margin-right: 25px;
    }
    .etl-tab {
      position: relative;
      margin-left: 30px;
      padding: 0 5px;
      cursor: pointer;
      font-size: 14px;
      &.active {
        &::before {
          position: absolute;
          left: 0px;
          right: 0px;
          height: 4px;
          bottom: 0px;
          content: " ";
          background-color: #0db3a6;
        }
      }
    }
  }
  .etl-attr-body {
    flex: 1;
    overflow: hidden;
  }
  .error-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  .error-text {
    color: #e64340;
    font-size: 12px;
    text-align: center;
    padding: 13px 0 30px;
  }
}
.error-tip {
  color: #f04134;
  font-size: 14px;
  flex: 1;
  text-align: center;
}
</style>
