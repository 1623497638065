<template>
  <div>
    <div class="group-item">
      <div style="flex: 1; margin-right: 10px">
        <el-input
          :class="isDelete(node.field.name) ? 'etl-error' : ''"
          v-model="node.title"
          size="small"
          placeholder="请输入"
          style="width: 100%"
          @blur="saveTitle"
          @keydown.native.enter="saveTitle"
          :readonly="!isEdit"
          ref="input"
        ></el-input>
      </div>
      <el-select
        v-model="node.oper"
        placeholder=""
        size="small"
        style="margin-right: 10px; width: 90px"
        @change="change"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <i
        @click="edit"
        class="iconfont-fx-pc icon-pc-edit"
        style="rgba(9, 30, 66, 0.66)"
      ></i>
      <i
        class="iconfont-fx-pc icon-pc-drag"
        style="rgba(9, 30, 66, 0.66);margin:0 5px"
      ></i>
      <i
        @click="deleteRow"
        class="iconfont-fx-pc icon-trash"
        style="rgba(9, 30, 66, 0.66)"
      ></i>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
export default {
  props: {
    node: Object,
    metrics: Array,
    index: Number,
    isDelete: Function,
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    options() {
      if (this.node.field.componentName == "input_number") {
        return [
          {
            label: "求和",
            value: "sum",
          },
          {
            label: "平均值",
            value: "avg",
          },
          {
            label: "最大值",
            value: "max",
          },
          {
            label: "最小值",
            value: "min",
          },
          {
            label: "计数",
            value: "count",
          },
          {
            label: "去重计数",
            value: "countDistinct",
          },
          {
            label: "方差",
            value: "variance",
          },
          {
            label: "标准差",
            value: "standardDeviation",
          },
          {
            label: "中位数",
            value: "median",
          },
        ];
      } else if (this.node.field.componentName == "date_picker") {
        return [
          {
            label: "计数",
            value: "count",
          },
          {
            label: "去重计数",
            value: "countDistinct",
          },
          {
            label: "最早",
            value: "earliest",
          },
          {
            label: "最晚",
            value: "latest",
          },
        ];
      } else {
        return [
          {
            label: "计数",
            value: "count",
          },
          {
            label: "去重计数",
            value: "countDistinct",
          },
        ];
      }
    },
  },
  methods: {
    change() {
      this.$emit("changeChildrenData");
    },
    saveTitle() {
      if (isEmpty(this.node.title)) {
        this.$message.error("请输入汇总字段标题");
        return;
      }
      this.isEdit = false;
      this.$emit("changeChildrenData");
    },

    edit() {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    deleteRow() {
      this.$delete(this.metrics, this.index);
      this.$emit("changeChildrenData");
    },
  },
};
</script>
<style lang="scss" scoped>
.group-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
