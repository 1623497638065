<template>
  <div>
    <attr-table-source
      v-model="component.tableName"
      :tableTitle="tableTitle"
      :componentName="component.componentName"
      @changeSource="changeSource"
    ></attr-table-source>
    <!--  汇总字段-->
    <div class="attr-row">
      <div class="attr-label">汇总字段</div>

      <fields-select
        title="选择汇总字段"
        v-model="metricField"
        :columns="formColumns"
        :componentName="component.componentName"
        :is-multi-select="false"
        :includeJsonForm="includeJsonForm"
      >
        <el-button
          style="width: 100%; margin-bottom: 5px"
          slot="reference"
          size="mini"
          >选择汇总字段
        </el-button>
      </fields-select>
      <div v-if="metricField && metricField.length" style="margin: 0px 5px">
        <div v-for="(item, index) in metricField" :key="index">
          <div class="weui-col">
            <div
              class="weui-title"
              :style="`color:${item.delete ? 'red' : ''}`"
            >
              {{ item.field.title }} <span v-if="item.delete">(不存在)</span>
            </div>
            <div
              class="weui-col"
              style="font-size: 12px"
              v-show="item.field.componentName != 'json_form'"
            >
              <i @click="deleteCol(index, -1)" class="el-icon-delete"></i>
            </div>
          </div>
          <!-- 子表单 -->
          <div
            v-if="
              item.field &&
              ['json_form', 'reference_data'].includes(item.field.componentName)
            "
            style="margin-left: 30px"
          >
            <div
              v-for="(child, cIndex) in item.subColumns"
              :key="cIndex"
              class="weui-col"
            >
              <div class="weui-title">{{ child.title }}</div>
              <div class="weui-col" style="font-size: 12px">
                <i @click="deleteCol(index, cIndex)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    汇总方式-->
    <div class="attr-row">
      <div class="attr-label">汇总方式</div>
      <el-select
        v-model="component.metric.oper"
        placeholder="请选择"
        size="mini"
        style="width: 100%"
      >
        <el-option
          v-for="item in summaryOperation"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <!-- 过滤条件 -->
    <attr-filter
      :component="component"
      :params="params"
      :tableColumns="componentList"
      :key="component.tableName + '_filter'"
    ></attr-filter>
    <div class="attr-row">
      <div class="attr-label">保留小数位</div>
      <el-input-number
        size="mini"
        v-model="component.metric.decimalPrecision"
        controls-position="right"
        :min="0"
        :max="10"
      ></el-input-number>
    </div>
  </div>
</template>
<script>
import AttrTableAction from "./attr-table-action.vue";
import AttrTableSource from "./attr-table-source.vue";
import attrViewList from "@/views/lowCode/form/attribute/attr-view-list";
import { componentProps } from "./util";
import { getFormDetail, getDataflow } from "../api";
import attrFilter from "@/views/lowCode/form/attribute/attr-filter";
import FieldsSelect from "@/components/FieldsSelect";
import { getFormComponents } from "../utils";
import { isEmpty } from "@zgg-core-utils/utils";
import FilledRule from "../component/FilledRule";
import ReferenceDataInit from "@/views/lowCode/form/component/ReferenceDataInit";
import { fetchMetaFieldComponentList } from "@/api/form";
import { aggregateDetail } from "../../view/api";

const summaryOperation = [
  { label: "计数", value: "count" },
  { label: "计数(去重)", value: "countDistinct" },
  { label: "已填计数", value: "filledCount" },
  { label: "未填计数", value: "notFilledCount" },
  { label: "求和", value: "sum" },
  { label: "求平均值", value: "avg" },
  { label: "求最大值", value: "max" },
  { label: "求最小值", value: "min" },
  { label: "最晚", value: "latest" },
  { label: "最早", value: "earliest" },
];

const componentNameSummaryOperationEnums = {
  input_number: [
    "count",
    "countDistinct",
    "filledCount",
    "notFilledCount",
    "sum",
    "avg",
    "max",
    "min",
  ],
  date_picker: [
    "count",
    "countDistinct",
    "filledCount",
    "notFilledCount",
    "latest",
    "earliest",
  ],
  input: ["count", "countDistinct", "filledCount", "notFilledCount"],
  radio_group: ["count", "countDistinct", "filledCount", "notFilledCount"],
  checkbox_group: ["count", "countDistinct", "filledCount", "notFilledCount"],
  select: ["count", "countDistinct", "filledCount", "notFilledCount"],
  select_checkbox: ["count", "countDistinct", "filledCount", "notFilledCount"],
  image_uploader: ["count", "filledCount", "notFilledCount"],
  attachment_uploader: ["count", "filledCount", "notFilledCount"],
  address_input: ["count", "countDistinct", "filledCount", "notFilledCount"],
  mobile_input: ["count", "countDistinct", "filledCount", "notFilledCount"],
  html_input: ["count", "filledCount", "notFilledCount"],
  sign_input: ["count", "filledCount", "notFilledCount"],
  sign_list_input: ["count", "filledCount", "notFilledCount"],
  user_select: ["count", "countDistinct", "filledCount", "notFilledCount"],
  user_list_select: ["count", "countDistinct", "filledCount", "notFilledCount"],
  department_select: [
    "count",
    "countDistinct",
    "filledCount",
    "notFilledCount",
  ],
  department_list_select: [
    "count",
    "countDistinct",
    "filledCount",
    "notFilledCount",
  ],
};
const defaultSummaryOperation = [
  "count",
  "countDistinct",
  "filledCount",
  "notFilledCount",
];
export default {
  name: "attr-summary-data",

  inject: ["getComponents"],
  props: componentProps,
  components: {
    AttrTableSource,
    AttrTableAction,
    attrFilter,
    FieldsSelect,
    attrViewList,
    FilledRule,
    ReferenceDataInit,
  },
  computed: {
    includeJsonForm() {
      // 关联表是本表时 才可选择关联表内的字表单字段
      return (
        this.componentList.some((item) => item.componentName == "json_form") &&
        this.component.tableName == this.params.formId
      );
    },
    jsonFormName() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      let parentField = components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key
            ) >= 0
        );

      if (parentField) {
        return parentField.name;
      }
      return;
    },
    isDataflow() {
      return (
        !isEmpty(this.component.tableName) &&
        this.component.tableName.indexOf("dataflow_") == 0
      );
    },
    formColumns() {
      let list = this._.clone(this.componentList); // 重构流程状态

      // 关联多条暂不放在汇总选择字段中
      return list.filter((item) => {
        if (item.componentName != "reference_data_list") {
          return item.form;
        }
      });
    },
    /**
     * 共用组件，原组件是多选，此处只需要单选，故做适配
     */
    metricField: {
      get() {
        if (this.component.metric.field) {
          this.component.metric.field.title =
            this.component.metric.field.comment;
          return [
            {
              field: this.component.metric.field,
              subColumns: this.component.metric.subColumns,
            },
          ];
        }
        return [];
      },
      set(val) {
        console.log(val);
        if (val.length > 0) {
          this.$set(this.component, "metric", val[0]);
          if (this.component.metric.field) {
            this.component.metric.field.comment =
              this.component.metric.field.title;
          }
        } else {
          this.component.metric.field = null;
        }
      },
    },
    summaryOperation() {
      let componentName = "";
      //获取非子表单字段componentName
      if (
        this.component.metric.field &&
        this.component.metric.field.componentName
      ) {
        componentName = this.component.metric.field.componentName;
      }

      // 如果汇总字段选择字表单字段，则获取子表单字段的componentName
      if (
        this.component.metric.subColumns &&
        this.component.metric.subColumns.length &&
        this.component.metric.subColumns[0].field.componentName
      ) {
        componentName = this.component.metric.subColumns[0].field.componentName;
      }

      let summaryOperationArray =
        componentNameSummaryOperationEnums[componentName];
      //如果没有获取到枚举，则使用默认枚举
      if (!summaryOperationArray) {
        summaryOperationArray = defaultSummaryOperation;
      }
      return summaryOperation.filter((item) => {
        return summaryOperationArray.includes(item.value) > 0;
      });
    },
  },
  watch: {
    component: {
      handler(val) {
        this.component.metric.name = val.name;
        this.component.metric.title = val.title;
      },
      deep: true,
    },
  },
  data() {
    return {
      tableTitle: "",
      componentList: [],
    };
  },
  created() {
    if (this.component.tableName) {
      this.getDetail(this.component.tableName);
    }
  },
  methods: {
    deleteCol() {
      this.component.metric.field = null;
    },
    changeFilledRule(list) {
      this.$set(this.component, "filledRule", list);
    },
    getDetail(tableName) {
      if (tableName.indexOf("dataflow_") == 0) {
        // 数据流
        this.component.allowActionEvents = ["detail"];
        getDataflow(tableName).then((res) => {
          this.tableTitle = res.data.dataflow.title;
          let output = res.data.dataflow.flow.stages.find(
            (item) => item.stageType == "output"
          );

          let componentList = output.fields.map((item) => item.component);
          this.$set(this, "componentList", componentList);
        });
      } else if (tableName.indexOf("at_") == 0) {
        // 聚合表
        Promise.all([
          aggregateDetail({ collection: tableName, removeComponent: true }),
          fetchMetaFieldComponentList(tableName, true),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.aggregateTable.title;
          this.$set(this, "componentList", resp[1].data.list);
        });
      } else{
        Promise.all([
          getFormDetail(tableName, true),
          fetchMetaFieldComponentList(tableName, ["flowStatus", "createrId", "createdTime", "updatedTime"]),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.form.title;
          this.$set(this, "componentList", resp[1].data.list);
        });
      }
    },
    changeSource(tableName) {
      this.component.tableName = tableName;
      this.component.dataFilter = { rel: "and", advanceQuery: [] };
      this.component.metric.field = null;
      this.getDetail(tableName);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-col {
  display: flex;
  align-items: center;

  .weui-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5;
  }
}
</style>
