<template>
  <div class="zgg-include-container" style="pointer-events: none">
    <iframe
      ref="includeFrame"
      frameborder="0"
      style="height: 100%;width: 100%;border-radius: 4px"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "dashboard-include",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    "config.url": {
      handler() {
        this.$nextTick(() => {
          this.$refs.includeFrame.src = this.config.url;
        });
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    getData() {},
    resizeChart() {}
  },
  mounted() {
    console.log(this.config);
    this.$nextTick(() => {
      this.$refs.includeFrame.src = this.config.url;
    });
  }
};
</script>

<style scoped lang="scss">
.zgg-include-container {
  height: 100%;
  overflow: hidden;
}
</style>
