import { render, staticRenderFns } from "./background-config.vue?vue&type=template&id=120b9c4f&scoped=true"
import script from "./background-config.vue?vue&type=script&lang=js"
export * from "./background-config.vue?vue&type=script&lang=js"
import style0 from "./background-config.vue?vue&type=style&index=0&id=120b9c4f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120b9c4f",
  null
  
)

export default component.exports