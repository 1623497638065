<template>
  <div>
    <div class="row-end">
       <el-tag size="small">标签</el-tag>
    </div>
    <draggable
      :list="component.components"
      animation="300"
      handle=".el-icon-rank"
      style="margin: 10px"
    >
      <div
        class="weui"
        v-for="(item, index) in component.components"
        :key="index"
      >
        <el-input v-model="item.title" size="small"></el-input>
        <i class="el-icon-rank ml" style="font-size: 16px; cursor: pointer"></i>

        <i
          class="el-icon-remove ml"
          style="color: red; font-size: 16px"
          @click="delComponent(index)"
        ></i>
      </div>
    </draggable>
    <el-button
      @click="addTabItem"
      type="text"
      style="margin: 0px 10px"
      icon="el-icon-plus"
      >添加标签页
    </el-button>
  </div>
</template>
<script>
import { componentProps } from "./util";
import Draggable from "vuedraggable";
export default {
  name: "attr-tabs",
  props: componentProps,
  components: { Draggable },
  methods: {
    delComponent(index) {
      this.component.components.splice(index, 1);
    },
    addTabItem() {
      this.component.components.push({
        componentName: "tab_pane",
        title: "标签页",
        components: [],
        key: this.getUuid(),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ml {
  margin-left: 5px;
}
.weui{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.row-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px;

}
</style>