<!--
 * @Description: 
 * @Version: 0.1
 * @Autor: zhengxn
 * @Date: 2022-12-07 09:59:08
-->
<template>
  <el-dialog
    title="自定义文本"
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    width="90%"
    @open="openDialog"
    @close="handleClose"
    :close-on-click-modal="false"
    :show-close="true"
  >
    <div style="height: 100%">
      <div
        style="padding: 24px; display: flex; flex-direction: column"
        v-if="dialogVisible"
      >
        <!-- <div class="form-item__wrap">
        <span class="form-item__wrap-title titlespan">组件名称</span>
        <el-input
          v-model="freeTextModel.extParam.title"
          placeholder="请输入"
          size="small"
        ></el-input>
      </div> -->
        <div class="form-item__wrap">
          <!-- <span class="form-item__wrap-title">描述</span> -->
          <TEditor
            v-if="dialogVisible"
            v-model="freeTextModel.description"
            :height="height"
          ></TEditor>
        </div>
      </div>
      <div
        slot="footer"
        style="
          position: sticky;
          bottom: 0px;
          text-align: right;
          background: #fff;
          padding: 10px 20px;
        "
      >
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import TEditor from "@/components/TEditor.vue";
//文本类型的筛选器
import { fetchFormDataList } from "@/views/lowCode/form/api";
import { isEmpty } from "@zgg-core-utils/relyUtil";

export default {
  name: "DashboardFreeText",
  components: { TEditor },
  props: {
    dataComponentList: Array,
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      title: "",
      components: [
        {
          label: "自定义文本组件",
          value: "html",
        },
      ],
      freeTextModel: {
        extParam: { title: "自定义文本", imgWidth: "", imgHeight: "" },
        title: "",
        description: "",
        componentName: "html",
      },
      height: 300,
    };
  },
  watch: {},
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        const dialogHeight = window.innerHeight;
        this.height = dialogHeight * 0.7;
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    const dialogHeight = window.innerHeight;
    this.height = dialogHeight * 0.7;
    window.addEventListener("resize", this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      //  this.dialogVisible = false;
      this.$nextTick(() => {
        const dialogHeight = window.innerHeight;
        const height = dialogHeight * 0.7;
        this.$set(this, "height", height);
        //this.dialogVisible = true;
      });
    },
    /**
     * 校验要提交的数据
     */
    validate() {
      const { extParam } = this.freeTextModel;
      return new Promise((resolve, reject) => {
        if (isEmpty(extParam.title)) {
          this.$message.warning("标题不能为空");
          return reject();
        }

        resolve();
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },

    saveForm() {
      console.log(this.freeTextModel);
      this.validate()
        .then((resp) => {
          this.freeTextModel.title = this.freeTextModel.extParam.title;
          this.$emit("save", this.freeTextModel);
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      Object.assign(this.$data, this.$options.data());
    },
    openDialog() {},

    manualInit(dataFilterModel) {
      this.dialogVisible = true;
      this.$set(this, "freeTextModel", this._.cloneDeep(dataFilterModel));
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item__wrap {
  margin-top: 57px;

  &:first-child {
    margin-top: 0;
  }

  .form-item__wrap-title {
    margin-bottom: 16px;
    color: #1f2d3d;
    font-size: 14px;
    line-height: 40px;
  }
}
.titlespan::before {
  content: "* ";
  color: red;
}
::v-deep .el-dialog {
  height: 90%;
  overflow: hidden;
}
</style>
<style>
.el-dialog__header {
  padding-bottom: 15px;
}
</style>
