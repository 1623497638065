<template>
  <div class="app-container">
    <el-form ref="fileForm" label-width="0px">
      <el-form-item label="" prop="attachmentIds">
        <el-upload
          ref="upload"
          class="upload-container"
          :show-file-list="showFileList"
          :list-type="listType"
          :file-list.sync="fileList2"
          :http-request="upload"
          :on-change="handleChange"
          :before-upload="beforeAvatarUpload"
          :on-preview="handlePreview"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :on-exceed="handleExceed"
          :on-progress="handleProgress"
          :accept="accept"
          :limit="limit ? limit : 0"
          drag
          action
          :multiple="multiple"
        >
          <div v-if="!$slots.default">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </div>
          <slot></slot>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import request from "@/utils/request";
import { ossLoadCredential } from "@/api/oss";
import OSS from "ali-oss";
import { hashFile } from "@/utils/tools";
import { attachmentCheckExits, attachmentSave } from "@/api/attachment";

import {
  getFileOriginalName,
  getUploadPathByName,
} from "@/utils/fileNameUtil";

let credentials = null; // STS凭证
let ossClient = null; // oss客户端实例
const bucket = "yunguangong-file"; // bucket名称
const region = "oss-cn-beijing"; // oss服务区域名称
const partSize = 1 * 1024 * 1024; // 每个分片大小
const parallel = 10; // 同时上传的分片数
const checkpoints = {}; // 所有分片上传文件的检查点

export default {
  props: {
    showFileList: {
      type: Boolean,
      default() {
        return true;
      },
    },
    limit: {
      type: Number,
      default() {
        return 0;
      },
    },
    listType: {
      type: String,
      default() {
        return "text";
      },
    },
    accept: {
      type: String,
      default() {
        return "";
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  components: {},
  data() {
    return {
      isUploading: false,
      fileList: [],
      fileList2: [],
      successCount: 0,
      fileOssObjectList: [],
    };
  },
  computed: {},

  methods: {
    clearFiles() {
      this.$refs.upload.clearFiles();
      this.fileList = [];
    },
    //处理上传组件的上传列表的变化的回调
    //【需求】优化附件上传时，文件主题默认为第一个附件的名称
    handleChange(file, fileList) {
      // this.fileList = fileList;
      this.$emit("change", fileList)
    },
    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(`每次只能上传 ${this.limit} 个文件`);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      // let rootSrc = ""
      // let filePreview = rootSrc + file.response.url
      // window.open(filePreview, "_blank")
    },
    // 删除文件之前的钩子
    beforeRemove(file, fileList) {
      console.log(file);

      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      // file.raw && file.raw.source && file.raw.source.cancel("中止上传！")
      // this.fileList = fileList
      this.$emit("change", fileList)
      //
      // if (file.status === 'uploading') {
      //   this.isUploading = this.successCount !== fileList.length;
      // } else if (file.status === 'success') {
      //   this.successCount -= 1
      //   this.isUploading = this.successCount !== fileList.length;
      // }
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      // file.id = response.id
      // this.successCount += 1
      // this.isUploading = this.successCount !== fileList.length;
    },
    handleProgress(file, param, fileList) {
      // this.isUploading = this.successCount !== fileList.length;
      // console.log(file)
      // file.percent = 0
      // param.onProgress(file)
      this.$emit("onProgress", file);
    },
    //文件上传前的校验
    beforeAvatarUpload(file) {},
    //todo 未来需要添加删除附件的功能
    Upload(params) {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      let form = new FormData();
      form.append("attachmentFile", params.file);
      form.append(
        "originalName",
        params.file.name.substring(0, params.file.name.lastIndexOf("."))
      );

      request({
        url: "/attachment/upload",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: source.token,
        data: form,
        onUploadProgress: (progressEvent) => {
          params.file.percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          params.file.source = source;
          params.onProgress(params.file);
        },
      })
        .then((resp) => {
          params.onSuccess(resp.data.attachment);
        })
        .catch((error) => {
          // this.$message.info('取消')
        });
    },
    //更新文件进度
    updateFileStatus(file) {
      let index = this._.findIndex(this.fileList, ["uid", file.uid]);
      this.$set(this.fileList, index, file);
    },
    //获取sts临时授权token
    getCredential() {
      return ossLoadCredential().then((resp) => {
        credentials = resp.data;
      });
    },
    //初始化oss-client
    async initOSSClient() {
      await this.getCredential();
      const { accessKeyId, accessKeySecret, securityToken } = credentials;
      return new OSS({
        accessKeyId: accessKeyId,
        accessKeySecret: accessKeySecret,
        stsToken: securityToken,
        bucket,
        region,
      });
    },
    //处理附件上传到oss之后保存到智管工附件表
    async handleUploadAttachmentSave(data) {
      let { type, param } = data;
      let attachmentSaveParam = {
        "attachment.originalName": getFileOriginalName(param.file.name),
        "attachment.md5": param.md5,
        "attachment.path": param.path,
        "attachment.size": param.file.size,
      };
      let attachmentSaveResp = await attachmentSave(attachmentSaveParam);
      this.fileList.push(attachmentSaveResp.data.attachment);
      this.$nextTick(() => {
        this.$emit("uploadSuccess", attachmentSaveResp.data.attachment);
      });
    },
    // 普通上传
    async commonUpload(param) {
      let { file } = param;
      let path = getUploadPathByName(file.name);
      return file.ossClient
        .put(path, file)
        .then(async (result) => {
          file.percent = 100;
          file.status = "normal";
          param.onSuccess(file);
          // this.updateFileStatus(file)
          let data = { type: "common", param: { md5: file.md5, path, file } };
          await this.handleUploadAttachmentSave(data);
        })
        .catch((err) => {});
    },
    // 分片上传
    async multipartUpload(param) {
      let { file } = param;
      let path = getUploadPathByName(file.name);
      return file.ossClient
        .multipartUpload(path, file, {
          parallel,
          partSize,
          progress: (progress, checkpoint) => {
            file.checkpoint = {
              uploadId: checkpoint.uploadId,
              name: checkpoint.name,
            };
            this.onMultipartUploadProgress(progress, checkpoint, param);
          },
        })
        .then(async (result) => {
          let data = {
            type: "multipart",
            param: { md5: file.md5, path, file },
          };
          param.onSuccess(file);
          await this.handleUploadAttachmentSave(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 分片上传进度改变回调
    async onMultipartUploadProgress(progress, checkpoint, param) {
      let { file } = param;
      let percent = (progress * 100).toFixed(0) * 1;
      console.log(`${checkpoint.file.name} 上传进度 ${percent}`);
      file.percent = percent;
      file.status = "normal";
      param.onProgress(file);
      // this.updateFileStatus(file)
      checkpoints[file.uid] = checkpoint;
    },
    //上传分拨
    async upload(param) {
      let { file } = param;
      //将新文件添加到列表
      setTimeout(() => {
        file.percent = 0;
        param.onProgress(file);
      }, 0);
      hashFile(file, async (hash) => {
        // this.fileOssObjectList[file.uid].md5 = hash
        param.file.md5 = hash;
        let resp = await attachmentCheckExits({ md5: hash });
        resp = undefined;
        if (resp && resp.data && resp.data.attachmentId != "0") {
          console.log("符合秒传");
          file.percent = 100;
          file.status = "quickDone";
          this.updateFileStatus(file);
          let data = {
            type: "quick",
            param: { md5: hash, file, attachmentId: resp.data.attachmentId },
          };
          // this.$emit("change", data)
          // await this.handleUploadFileListChange(data)
          // this.$bus.$emit('addTodo', data)
        } else {
          console.log("不符合秒传");
          //初始化上传客户端链接
          //判断是否需要断点上传
          file.ossClient = await this.initOSSClient();
          if (file.size < partSize) {
            await this.commonUpload(param);
          } else {
            await this.multipartUpload(param);
          }
        }
      });
    },
  },
  async mounted() {
    // await this.getCredential()
    // this.initOSSClient()
  },
};
</script>

<style lang="less">
.hide-upload{
  display: none;
}
</style>

