<template>
  <div v-if="view.xFields.length">
    <template v-if="view.metrics.length">
      <div>
        <div class="m-row">
          <div class="m-bar">共计</div>
          <div>{{ total }}</div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="m-row">
          <div class="m-title">{{ getFieldValue(item) }}</div>
          <div>{{ item[metricsName] }}</div>
        </div>
      </div>
    </template>
  </div>
  <div v-else class="xfield">
    <div
      ref="number"
      class="number-div"
      :style="`align-items:${
        view?.style?.textAlign == 'left' ? 'flex-start' : 'center;'
      };`"
    >
      <div
        v-if="!isEmptyDesc"
        class="desc"
        :style="descStyle"
        :title="view.description"
      >
        {{ view.description }}
      </div>
      <div class="number" :style="numStyle">
        <span v-text="formatValue"></span>
        <span
          v-if="unit"
          style="font-size: 0.5em; margin-left: 5px"
          v-text="unit"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty, uuid } from "@zgg-core-utils/utils";
import { getAggreation } from "../api";
import { dealWithChartValue } from "./chartUtil";
import { getNumberFormat, getStrByNumberFormat } from "@/zgg-core/utils";
export default {
  props: {
    view: Object,
  },
  data() {
    return {
      list: [],
      scale: 1,
      descFontSize: 40,
      numFontSize: 120,
      key: "",
    };
  },

  computed: {
    isEmptyDesc() {
      return isEmpty(this.view.description);
    },
    signValue() {
      if (this.list.length && this.view.metrics.length) {
        return this.list[0][this.view.metrics[0].name];
      }
      return "";
    },

    formatValue() {
      let num = this.signValue;
      if (isEmpty(num)) {
        return "";
      }
      if (!this.view.metrics.length) {
        return num;
      }
      let numberFormat = this._.cloneDeep(this.view.metrics[0]?.numberFormat);
      if (numberFormat) {
        numberFormat.unit = "";
      }

      return getStrByNumberFormat(num, numberFormat);
    },
    unit() {
      if (!this.view.metrics.length) {
        return "";
      }
      let numberFormat = this.view?.metrics[0]?.numberFormat;
      let str = "";
      if (numberFormat?.numberMagnitude) {
        let numberMap = {
          thousand: "千",
          tenThousand: "万",
          million: "百万",
          hundredMillion: "亿",
          billion: "十亿",
        };
        let a = numberMap[numberFormat.numberMagnitude];
        if (a) {
          str += a;
        }
      }

      if (numberFormat?.numberFormatType === "number" && numberFormat?.unit) {
        str += numberFormat.unit;
      }
      return str;
    },
    metricsName() {
      if (!this.view.metrics.length) {
        return "";
      }
      let numberFormat = this.view.metrics[0].numberFormat;
      if (isEmpty(numberFormat)) {
        return this.view.metrics[0].name;
      } else {
        return this.view.metrics[0].name + "Format";
      }
    },

    total() {
      let result = 0;
      if (this.view.metrics.length) {
        this.list.forEach((item) => {
          result += item[this.view.metrics[0].name];
        });
        let numberFormat = this.view.metrics[0].numberFormat;

        return getStrByNumberFormat(result, numberFormat);
      }

      return result;
    },
    descStyle() {
      if (this.view.style && this.view.style.fontStyleEnum == "fixed") {
        this.descFontSize = this.view.style.lableFontSize;
      }
      let paddingLeft = "0px";
      // if (
      //   this.view.style.lableFontSize <= this.view.style.valueFontSize &&
      //   this.view.style.lableFontSize >= 30
      // ) {
      //   paddingLeft = "20px";
      // }
      return `font-size:${this.descFontSize}px;color:${this.view.style?.descriptionColor};text-align:${this.view?.style?.textAlign};padding-left:${paddingLeft}`;
    },
    numStyle() {
      if (this.view.style && this.view.style.fontStyleEnum == "fixed") {
        this.numFontSize = this.view.style.valueFontSize;
      }

      return `font-size:${this.numFontSize}px;color:${this.view.colorConfig?.contentColor};text-align:${this.view?.style?.textAlign}`;
    },
  },
  created() {},
  methods: {
    resizeChart() {
      this.setFontSize();
    },
    setFontSize() {
      if (this.view.xFields.length) {
        return;
      }

      let descFontSize = 0;
      let numFontSize;
      let clientHeight = this.$refs.number?.clientHeight;
      let clientWidth = this.$refs.number?.clientWidth;
      // if (!this.view.hiddenTitle) {
      //   clientHeight += 48;
      // }

      let min = Math.min(clientHeight, clientWidth);

      if (min < 120) {
        numFontSize = min * 0.4;
      } else {
        numFontSize = min * 0.5 * 0.55;
      }

      if (!this.isEmptyDesc) {
        descFontSize = numFontSize * 0.4;
      }

      let text = "";
      text = this.formatValue;
      if (this.unit) {
        text += this.unit;
      }
      let length = text.length;
      if (length) {
        let font = (clientWidth / length) * 1.5;

        if (font < numFontSize) {
          numFontSize = font;
          descFontSize = numFontSize * 0.4;
        }
      }

      if (numFontSize > 120) {
        numFontSize = 120;
        descFontSize = numFontSize * 0.4;
      }

      if (descFontSize < 13) {
        descFontSize = 13;
      }
      if (numFontSize < 24) {
        numFontSize = 24;
      }
      if (numFontSize - descFontSize < 8) {
        numFontSize = descFontSize + 8;
      }

      this.numFontSize = numFontSize;
      this.descFontSize = descFontSize;
    },
    getFieldValue(row) {
      let field = this._.cloneDeep(this.view.xFields[0].field);
      // 当维度为日期且存在汇总方式时
      let datePrecision = this.view.xFields[0].datePrecision;
      if (field.component.componentName == "address_input") {
        if (field.component.picker == "address") {
          field.component.picker = "county";
        }
      }

      return dealWithChartValue(row, field.component, { datePrecision });
    },
    getData(view, dataFilter) {
      if (!view.metrics.length) {
        return;
      }
      if (view.metrics.length > 2 && view.xFields.length == 0) {
        return;
      }
      let queryObject = {};
      if (view.dataSource.queryObject) {
        if (view.dataSource.queryObject.pageSize) {
          queryObject.pageSize = view.dataSource.queryObject.pageSize;
        }
        if (view.dataSource.queryObject.advanceQuery) {
          queryObject.advanceQuery = view.dataSource.queryObject.advanceQuery;
        }
      }
      queryObject.xFields = view.xFields;
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        this.list = res.data.list;
        this.$nextTick(() => {
          this.setFontSize();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.number-div {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.desc {
  text-align: center;
  font-weight: normal;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  // color: #505d6f;
}
.number {
  text-align: center;
  font-weight: 500;
  max-height: 200px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // color: #343d50;
}
.m-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 1.5;
  border-bottom: solid 1px #f4f5f7;
  padding: 10px 0px;
  .m-bar {
    color: #248af9;
  }
}
.xfield {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  justify-content: center;
}
</style>
