<template>
  <div class="attr-row">
    <div class="attr-label">格式</div>
    <el-select
      v-model="component.format"
      placeholder="请选择数据格式"
      size="mini"
      style="margin-bottom: 10px; width: 100%"
    >
      <el-option label="数值" value="value"></el-option>
      <el-option label="百分比" value="percent"></el-option>
    </el-select>
    <div class="weui">
      <el-checkbox v-model="decimalPrecision" style="margin-right: 10px"
        >保留小数位数
      </el-checkbox>
      <el-input
        v-if="!decimalPrecision"
        :value="component.decimalPrecision"
        disabled
        placeholder=""
        size="mini"
        style="width: 130px"
      ></el-input>
      <el-input-number
        v-else
        :value="component.decimalPrecision"
        controls-position="right"
        :min="0"
        :max="10"
        size="mini"
        @change="changeValue"
        style="width: 130px"
      ></el-input-number>
    </div>
    <div>
      <el-checkbox v-model="thousandsSeparator">显示千分符</el-checkbox>
      <div v-if="thousandsSeparator" class="thousand">99,999</div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import { componentProps } from "./util";
export default {
  name: "attr-number-format",
  props: componentProps,
  computed: {
    decimalPrecision: {
      get() {
        return !isEmpty(this.component.decimalPrecision);
      },
      set(val) {
        if (val) {
          this.$set(this.component, "decimalPrecision", 2);
        } else {
          this.$set(this.component, "decimalPrecision", "");
        }
      },
    },
    thousandsSeparator: {
      get() {
        return Boolean(this.component.thousandsSeparator);
      },
      set(val) {
        this.$set(this.component, "thousandsSeparator", val);
      },
    },
  },

  methods: {
    changeValue(e) {
      this.$set(this.component, "decimalPrecision", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui {
  display: flex;
  align-items: center;
}
.thousand {
  line-height: 40px;
  text-align: center;
  background: #f5f6f8;
  font-size: 14px;
  margin-top: 5px;
}
</style>
