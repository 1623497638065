<template>
  <div style="display: flex; flex-direction: column; height: 100%">
    <div class="tab-header-box">
      <div class="tab-header-wrapper">
        <div class="tab-item" :class="curPath == item.path ? 'active' : ''" @click="changeTab(item)" v-for="(item, index) in tabData" :key="index">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div style="flex: 1; height: calc(100vh - 50px); overflow: auto">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
      curPath: this.$route.path,
      tabData:[
        {
          name: '插件管理',
          path: '/lowCode/open-platform/unit'
        },
        {
          name: '插件市场',
          path: '/lowCode/open-platform/market'
        },
        // {
        //   name: '开发文档',
        //   path: '/lowCode/open-platform/doc'
        // },
        {
          name: '密钥管理',
          path: '/lowCode/open-platform/apikey'
        }
      ]
    }
  },
  components: {},
  computed: {},
  created(){
    console.log(this.$route.path)
  },
  mounted(){},
  methods: {
    changeTab(item){
      this.curPath = item.path
      this.$router.push(item.path);
    }
  }
}

</script>
<style lang="scss" scoped>
.tab-header-box{
  width: 100%;
  height: 48px;
  text-align: center;
  background: #fff;
  border-bottom: solid 2px #EBECF0;
  .tab-header-wrapper{
    display: flex;
    justify-content: center;
    .tab-item{
      padding:0 20px;
      height: 46px;
      line-height: 46px;
      font-size: 16px;
      cursor: pointer;
      &.active{
        color: #3870EA;
        border-bottom: 2px solid #3870EA;
      }
    }
  }
}
</style>
