<template>
  <div ref="flexBody" class="group_container">
    <div
      class="header"
      :class="{
        'no-header': !showTitle && config.componentName != 'tab',
      }"
    >
      <div
        class="title"
        v-if="config.componentName != 'tab'"
        :style="`color:${titleColor()}`"
      >
        {{ showTitle ? config.extParam.title : "" }}
      </div>
      <el-tabs
        v-model="activeName"
        @tab-click="handleTabClick"
        v-else
        style="flex: 1; width: 0"
        :style="`color:${titleColor()}`"
      >
        <template v-for="tab in config.components">
          <el-tab-pane
            :label="tab.title"
            :name="tab.key"
            :key="tab.key"
          ></el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div class="header-edit">
      <div class="edit-btns">
        <i
          @click="showColorConfig"
          class="iconfont icon-paint-fill"
          style="
            color: rgba(9, 30, 66, 0.66);
            padding: 2px;
            font-size: 16px;
            margin-right: 0;
          "
        ></i>
        <el-popover placement="bottom" width="300" trigger="click">
          <el-form :model="config">
            <el-form-item label="标题">
              <el-input v-model="config.extParam.title" size="small"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="showTitle">显示标题</el-checkbox>
            </el-form-item>
            <el-form-item>
              <div style="display: flex; justify-content: space-between">
                <span>标签页</span>
                <el-switch
                  v-model="showTab"
                  style="height: 40px"
                  @change="handleTab"
                >
                </el-switch>
              </div>
              <template v-if="showTab">
                <draggable
                  v-model="config.components"
                  handle=".icon-yidong"
                  animation="300"
                  style="max-height: 150px; overflow: auto"
                >
                  <transition-group>
                    <template v-for="tab in config.components">
                      <div
                        :key="tab.key"
                        style="display: flex; justify-content: space-between"
                      >
                        <el-input v-model="tab.title" size="small"></el-input>
                        <div class="operator-btns">
                          <i class="iconfont icon-yidong"></i>
                          <i class="el-icon-delete" @click="deleteTab(tab)"></i>
                        </div>
                      </div>
                    </template>
                  </transition-group>
                </draggable>
                <el-button type="text" icon="el-icon-plus" @click="handleAddTab"
                  >添加标签页</el-button
                >
              </template>
            </el-form-item>
          </el-form>
          <i
            slot="reference"
            class="el-icon-edit"
            style="color: rgba(9, 30, 66, 0.66); padding: 2px"
          ></i>
        </el-popover>
        <i
          @click="handleDelete(config.key, $event)"
          class="el-icon-delete"
          :class="{ disabled }"
          style="color: rgba(9, 30, 66, 0.66); padding: 2px"
        ></i>
      </div>
    </div>
    <div style="overflow: auto; height: 100%">
      <slot :components="showComponents" name="group"></slot>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  data() {
    return {
      list: [],
      view: null,
      activeName: "",
    };
  },
  components: {
    Draggable,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    components: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    "config.colorConfig.titleColor": {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.changeTabLineColor(val);
        });
      },
    },
  },
  computed: {
    showTab: {
      get() {
        return this.config.componentName == "tab";
      },
      set(val) {},
    },
    showTitle: {
      get() {
        return !this.config.hiddenTitle;
      },
      set(val) {
        this.$set(this.config, "hiddenTitle", !val);
      },
    },
    disabled() {
      if (this.config.componentName == "dashboard") {
        return this.components.length > 0;
      } else {
        return this.components.some((row) => {
          return row.components.length > 0;
        });
      }
    },
    showComponents: {
      get() {
        if (this.config.componentName == "tab") {
          let tab = this.config.components.find(
            (row) => row.key == this.activeName,
          );
          if (tab) {
            return tab.components;
          }
          return [];
        } else {
          return this.components;
        }
      },
      set(val) {
        if (this.config.componentName == "tab") {
          let index = this.config.components.findIndex(
            (row) => row.key == this.activeName,
          );
          if (index > -1) {
            this.$set(this.config.components[index], "components", val);
          }
        } else {
          this.$set(this.config, "components", val);
        }
      },
    },
  },
  mounted() {
    if (this.config.componentName == "tab") {
      this.activeName = this.config.components?.[0]?.key;
    }
  },
  methods: {
    titleColor() {
      if (this.config) {
        return this.config?.colorConfig?.titleColor;
      }
    },
    changeTabLineColor(val) {
      const activeBar = this.$el.querySelector("div.el-tabs__active-bar");
      if (activeBar && val) {
        activeBar.style.backgroundColor = val;
      }
    },
    handleTabClick() {
      this.$emit("fetchDashboardData", this.showComponents, this.config.key);
      this.changeTabLineColor(this.titleColor());
    },
    handleAddTab() {
      let num = this.config.components.length;
      if (num > 9) {
        this.$message.error("最多添加10个标签");
        return;
      }
      this.config.components.push({
        componentName: "tab_pane",
        key: this.getUuid(),
        title: `标签页${num - -1}`,
        components: [],
      });
    },
    deleteTab(tab) {
      if (tab.components.length > 0) {
        this.$message.error("标签内容不为空");
        return;
      }
      let num = this.config.components.length;
      if (num < 2) {
        this.$message.error("至少有1个标签");
        return;
      }
      this.$set(
        this.config,
        "components",
        this.config.components.filter((row) => row.key != tab.key),
      );
    },
    handleTab(val) {
      if (val) {
        let firstTab = {
          componentName: "tab_pane",
          key: this.getUuid(),
          title: "标签页1",
          components: [...this.components],
        };
        this.$set(this, "activeName", firstTab.key);
        this.$set(this.config, "components", [
          firstTab,
          {
            componentName: "tab_pane",
            key: this.getUuid(),
            title: "标签页2",
            components: [],
          },
          {
            componentName: "tab_pane",
            key: this.getUuid(),
            title: "标签页3",
            components: [],
          },
        ]);
        this.$set(this.config, "componentName", "tab");
        this.$nextTick(() => {
          this.changeTabLineColor(this.titleColor());
        });
      } else {
        let components = [];
        this.components.forEach((row) => {
          components = components.concat(row.components);
        });
        this.$set(this.config, "components", components);
        this.$set(this.config, "componentName", "dashboard");
      }
    },
    showColorConfig() {
      this.$emit("showColorConfig", this.config, { key: "dashboard" });
    },
    handleDelete(key, e) {
      if (this.disabled) return;
      this.$emit("delete", key, e);
    },
    getData(view, dataFilter) {
      console.log("容器组件");
    },
  },
};
</script>
<style lang="scss" scoped>
.group_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding-top: 10px;
  &:hover {
    .edit-btns {
      display: block !important;
    }
  }
  .header-edit {
    position: absolute;
    top: 0px;
    right: 0;
    width: 64px;
    z-index: 2;
  }
  .edit-btns {
    display: none;
    background: #fff;
    border-radius: 4px;
    padding: 2px;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
    white-space: nowrap;
    line-height: normal;
  }
  i {
    cursor: pointer;
  }
  .disabled {
    color: #a0a3a8 !important;
    cursor: not-allowed !important;
  }
}
.no-header {
  height: 0px !important;
  .title {
    display: none;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 36px;
  padding: 0 10px;

  .title {
    font-size: 16px;
    flex: 1;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba($color: #091e42, $alpha: 0.95);
    font-weight: 600;
  }
}
.el-form-item {
  margin-bottom: 0;
}
.operator-btns {
  display: flex;
  margin-left: 5px;
  i {
    line-height: 32px;
  }
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item {
  font-size: 16px !important;
  height: 36px !important;
  line-height: 36px !important;
  font-weight: 600 !important;
  color: unset;
  &:hover {
    color: unset;
  }
}
::v-deep .el-tabs__item.is-active {
  color: unset;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.04);
}
::v-deep .el-tabs__active-bar {
  background-color: #172b4d;
}
</style>
