import { isEmpty, uuid } from "@/zgg-core/utils";

/**
 * 获取父级标题
 * @param {*} parent
 */
export function getParentTitle(parent) {
  let title = parent.title;
  if (parent.parent) {
    title = getParentTitle(parent.parent) + "." + title;
  }
  return title;
}

/**
 * 构建公式的入库对象
 * @param {*} data
 * @returns
 */
export function buildFormulaObj(data) {
  let obj = {
    name: data.name,
    value: data.title,
    componentName: data.componentName,
    type: "field",
  };
  if (data.collection) {
    obj.collection = data.collection;
  }
  if (data.nodeKey) {
    obj.nodeKey = data.nodeKey;
  }

  if (obj.componentName == "cascade_data") {
    obj.valueKey = `${obj.name}CascadeDataPath`;
  }

  if (!isEmpty(data.parent)) {
    obj.parent = data.parent;

    let title = getParentTitle(data.parent);
    obj.value = title + "." + obj.value;
  }
  return obj;
}

/**
 * 获取字段名和id
 * @param {*} str
 * @returns
 */
export function getFieldNameAndId(str) {
  let fieldName;
  let id;
  let list = /\$field_[a-z0-9A-Z]+_/gm.exec(str);
  let start = list[0];
  fieldName = str.substring(start.length, str.length - 1);
  id = start.substring(7, start.length - 1);

  return {
    fieldName,
    id,
  };
}

/**
 * 获取函数名和id
 * @param {*} str
 * @returns
 */
export function getFunNameAndId(str) {
  let funName;
  let id;
  let list = /\$fun_[a-z0-9A-Z]+_/gm.exec(str);
  let start = list[0];
  funName = str.substring(start.length, str.length - 1);
  id = start.substring(5, start.length - 1);
  return {
    funName,
    id,
  };
}

/**
 * 判断是否数组类型
 * @param {*} obj
 * @returns
 */
export function isArrayField(obj) {
  if (!obj) {
    return false;
  }
  if (obj.componentName == "json_form") {
    return true;
  }
  let is = false;
  let parent = obj.parent;
  while (parent) {
    if (parent.componentName == "json_form") {
      is = true;
      break;
    }
    parent = parent.parent;
  }
  return is;
}

/**
 * 获取样式名称
 * @param {*} obj
 * @returns
 */
export function getClass(obj) {
  if (isArrayField(obj.parent) || obj.componentName == "checkbox_group") {
    return "array-tip";
  } else if (obj.componentName == "input_number") {
    return "number-tip";
  } else if (obj.componentName == "date_picker") {
    return "date-tip";
  } else if (obj.componentName == "address_input") {
    return "address-tip";
  } else {
    return "text-tip";
  }
}

export function getClassText(obj, isJsonForm) {
  if (
    (!isJsonForm && isArrayField(obj.parent)) ||
    obj.componentName == "checkbox_group"
  ) {
    return "数组";
  } else if (obj.componentName == "input_number") {
    return "数字";
  } else if (obj.componentName == "date_picker") {
    return "日期";
  } else if (obj.componentName == "address_input") {
    return "地址";
  } else if (obj.componentName == "summary_data") {
    // 汇总字段:特殊处理
    if (obj.metric.subColumns && obj.metric.subColumns.length) {
      if (obj.metric.subColumns[0].field.componentName == "date_picker") {
        return "日期";
      } else {
        return "数字";
      }
    } else {
      if (obj.metric.field && obj.metric.field.componentName == "date_picker") {
        return "日期";
      } else {
        return "数字";
      }
    }
  } else {
    let map = {
      user_select: "用户",
      user_list_select: "用户多选",
      department_select: "部门",
      department_list_select: "部门多选",
      image_uploader: "图片",
      attachment_uploader: "附件",
    };

    let text = map[obj.componentName];
    if (!text) {
      text = "文本";
    }
    return text;
  }
}

export function getContentByOriginalFormula(originalFormula, relyType) {
  let content = "";
  if (originalFormula && originalFormula.length) {
    originalFormula.forEach((row) => {
      if (content != "") {
        content += "\n";
      }
      row.forEach((item) => {
        if (item.type == "fun") {
          content += `$fun_${uuid()}_${item.value}$`;
        } else if (item.type == "field") {
          let fieldName = "";
          if (relyType == "unionRely") {
            fieldName += "collection_" + item.collection;
          }

          let parent = item.parent;
          while (parent) {
            if (
              ["json_form", "reference_data"].includes(parent.componentName)
            ) {
              if (fieldName != "") {
                fieldName = "_" + fieldName;
              }
            }

            if (parent.componentName == "json_form") {
              fieldName = `jsonForm_${parent.name}` + fieldName;
            } else if (parent.componentName == "reference_data") {
              fieldName = `referenceData_${parent.name}` + fieldName;
            }

            parent = parent.parent;
          }

          if (!isEmpty(fieldName)) {
            fieldName += "_";
          }
          fieldName += `fieldName_${item.name}`;

          if (item.nodeKey) {
            fieldName = `nodeKey_${item.nodeKey}_${fieldName}`;
          }

          content += `$field_${uuid()}_${fieldName}$`;
        } else {
          content += item.value;
        }
      });
    });
  }
  return content;
}

/**
 * 字段正则
 */
export const fieldRex = /\$field_[a-zA-Z0-9_]+\$/gm;
/**
 *  函数正则
 */
export const funRex = /\$fun_[a-zA-Z0-9_]+\$/gm;
