<template>
  <div>
    <el-popover
      v-model="visible"
      popper-class="rpa-pop-version"
      trigger="hover"
      width="220px"
    >
      <div>
        <div style="max-height: 200px; overflow: auto">
          <div
            class="weui version-item"
            v-for="item in list"
            :key="item.id"
            @click="editVersion(item.id)"
          >
            <div style="width: 20px">
              <i
                v-if="item.id == workflow.id"
                style="color: var(--zgg-primary-color)"
                class="el-icon-check"
              ></i>
            </div>
            <div class="flex">流程版本(v{{ item.version }})</div>
            <div class="v-status" :class="`status-${item.versionStatus}`">
              {{ getVersionStatus(item.versionStatus) }}
            </div>
          </div>
        </div>
        <div class="footer" @click="mgrWorkflow">
          <i
            class="el-icon-s-tools"
            style="color: var(--zgg-primary-color); margin-right: 5px"
          ></i>
          <span>管理流程版本</span>
        </div>
      </div>
      <div slot="reference">
        <span>流程版本</span>
        <span style="color: #f0ad4e">v({{ workflow.version }})</span>
        <span
          class="v-status"
          :class="`status-${workflow.versionStatus}`"
          v-text="versionStatus"
        ></span>
        <i class="el-icon-arrow-down"></i>
      </div>
    </el-popover>

    <el-dialog
      title="管理流程版本"
      :visible.sync="mgrVisible"
      width="600px"
      append-to-body
      destroy-on-close
      :close-on-click-modal="false"
    >
      <div style="padding: 20px">
        <div style="margin-bottom: 10px">
          <el-button
            @click="addNewVersion"
            type="primary"
            size="small"
            icon="el-icon-plus"
            >添加新版本</el-button
          >
        </div>
        <div
          class="flow-list"
          :infinite-scroll-immediate="false"
          :infinite-scroll-distance="50"
          v-infinite-scroll="loadMore"
        >
          <div
            class="workflow-item"
            v-for="(item, index) in page.list"
            :key="item.id"
          >
            <div>流程版本v({{ item.version }})</div>
            <div class="flex" style="margin-left: 10px">
              <span class="v-status" :class="`status-${item.versionStatus}`">
                {{ getVersionStatus(item.versionStatus) }}
              </span>
            </div>
            <div>
              <template v-if="item.versionStatus != 'enable'">
                <el-button @click="useWorkflow(item.id)" type="text"
                  >启用</el-button
                >
                <el-divider direction="vertical"></el-divider>
                <el-button @click="editVersion(item.id)" type="text"
                  >编辑</el-button
                >
                <el-divider direction="vertical"></el-divider>
              </template>

              <template>
                <el-button
                  @click="delWorkflow(index)"
                  type="text"
                  style="color: #f56c6c"
                  >删除</el-button
                >
              </template>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  createRobotWorkflow,
  delRobotWorkflow,
  enableRobotWorkflow,
  fetchRobotWorkflowList,
  getRobotWorkflow,
} from "./api";
export default {
  props: {
    workflow: Object,
  },
  computed: {
    versionStatus() {
      if (!this.workflow) {
        return "";
      }
      if (this.workflow.versionStatus == "design") {
        return "设计";
      } else if (this.workflow.versionStatus == "enable") {
        return "启用中";
      } else if (this.workflow.versionStatus == "history") {
        return "历史";
      }
    },
  },
  watch: {
    "workflow.versionStatus"(val, old) {
      let node = this.list.find((item) => item.id == this.workflow.id);
      if (node) {
        node.versionStatus = val;
      }
    },
  },
  data() {
    return {
      mgrVisible: false,
      visible: false,
      list: [],
      page: {
        list: [],
        pageSize: 10,
        pageNumber: 1,
        totalPage: 0,
        totalRow: 0,
      },
    };
  },
  created() {
    this.fetchWorkflowList(1);
  },
  methods: {
    useWorkflow(workflowId) {
      enableRobotWorkflow(workflowId).then(() => {
        getRobotWorkflow({ workflowId }).then((res) => {
          this.$emit("update:workflow", res.data.workflow);
          this.mgrVisible = false;
          this.fetchWorkflowList(1);
        });
      });
    },
    addNewVersion() {
      const loading = this.$loading({
        lock: true,
        text: "新建...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      createRobotWorkflow({
        robotId: this.workflow.robotId,
        duplicateWorkflowId: this.workflow.id,
      })
        .then((res) => {
          this.$emit("update:workflow", res.data.workflow);
          this.mgrVisible = false;
        })
        .finally(() => {
          loading.close();
        });
    },
    mgrWorkflow() {
      this.mgrVisible = true;
      this.visible = false;
    },
    loadMore() {
      if (this.page.pageNumber >= this.page.totalPage) {
        return;
      }
      this.fetchWorkflowList(this.page.pageNumber + 1);
    },
    getVersionStatus(versionStatus) {
      return versionStatus == "design"
        ? "设计"
        : versionStatus == "history"
        ? "历史"
        : versionStatus == "enable"
        ? "启用中"
        : versionStatus;
    },
    editVersion(workflowId) {
      getRobotWorkflow({ workflowId }).then((res) => {
        this.$emit("update:workflow", res.data.workflow);
        this.visible = false;
        this.mgrVisible = false;
      });
    },
    fetchWorkflowList(pageNumber = 1) {
      fetchRobotWorkflowList({
        robotId: this.workflow.robotId,
        pageSize: this.page.pageSize,
        pageNumber: pageNumber,
      }).then((res) => {
        if (res.data.page.pageNumber == 1) {
          this.list = this._.cloneDeep(res.data.page.list);
          this.$set(this, "page", res.data.page);
        } else {
          this.page.pageNumber = res.data.page.pageNumber;
          this.page.totalPage = res.data.page.totalPage;
          this.page.totalRow = res.data.page.totalRow;
          this.page.list = this.page.list.concat(res.data.page.list);
        }
      });
    },
    delWorkflow(index) {
      if (this.page.list.length == 1) {
        this.$confirm(
          "删除唯一的流程，将会自动创建新的流程，确定要删除吗?",
          "提示"
        )
          .then(() => {
            delRobotWorkflow(this.page.list[index].id).then(() => {
              createRobotWorkflow({
                robotId: this.workflow.robotId,
              }).then((res) => {
                this.$emit("update:workflow", res.data.workflow);

                this.mgrVisible = false;
                this.fetchWorkflowList(1);
              });
            });
          })
          .catch(() => {});
      } else {
        this.$confirm("确定要删除这个版本的流程吗?", "提示")
          .then(() => {
            try {
            } catch (error) {}
            let hasRest = this.page.list[index].id == this.workflow.id;
            delRobotWorkflow(this.page.list[index].id).then(() => {
              this.fetchWorkflowList(1);
            });
            this.$delete(this.page.list, index);
            if (hasRest) {
              let workflowId = this.page.list[0].id;
              getRobotWorkflow({ workflowId }).then((res) => {
                this.$emit("update:workflow", res.data.workflow);
              });
            }
            this.mgrVisible = false;
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.status-design {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background: rgba(240, 173, 78, 0.1);
}
.status-history {
  color: #989898;
  border-color: #989898;
  background: rgba(152, 152, 152, 0.1);
}
.status-enable {
  color: #0db3a6;
  border-color: #0db3a6;
  background: rgba(13, 179, 166, 0.1);
}
.v-status {
  font-size: 12px;
  text-align: center;
  border: 1px solid;
  padding: 1px 5px;
  border-radius: 4px;
}
.weui {
  display: flex;
  align-items: center;
}
.flex {
  flex: 1;
}
.version-item {
  justify-content: space-between;
  padding: 8px;
  width: 200px;
  cursor: pointer;
  &:hover {
    background-color: rgba(var(--zgg-primary-color), 0.1);
  }
}
.footer {
  border-top: 1px solid #e9e9e9;
  height: 42px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.flow-list {
  max-height: 500px;
  overflow: auto;
  .workflow-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: solid 1px #f4f5f7;
  }
}
</style>
<style lang="scss">
.rpa-pop-version {
  padding: 0;
}
</style>