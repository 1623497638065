<template>
  <div>
    <el-input
      :value="title"
      placeholder=""
      size="small"
      suffix-icon="el-icon-edit-outline"
      @focus="handleFocus"
      readonly
    ></el-input>
    <el-dialog title="设置子表单默认值" :visible.sync="visible" width="800px" v-if="visible">
      <div style="padding: 20px">
        <json-form
          :item="node"
          :form="form"
          :area-tree="areaTree"
          @changeFormData="changeFormData"
          :isInitValue="true"
        ></json-form>
      </div>
      <div slot="footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import JsonForm from "@/views/lowCode/form/component/JsonForm";
export default {
  components: { JsonForm },

  props: {
    node: Object,
    areaTree: Array,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    title() {
      if (this._.isEmpty(this.node.defaultValue)) {
        return "设置";
      } else {
        return "已设置";
      }
    },
  },
  data() {
    return {
      visible: false,
      form: {},
    };
  },
  created() {
    let list = this._.cloneDeep(this.value);
    if (this._.isEmpty(list)) {
      list = [];
    } else if (typeof list === "string") {
      list = JSON.parse(list);
    }
    this.$set(this.form, this.node.name, list);
  },
  methods: {
    handleFocus() {
      if(this.node.components.length > 0) {
        this.visible = true
      } else {
        this.$message.warning('子表单未设置子字段无法设置默认值')
      }
    },
    saveForm() {
      let list = this._.cloneDeep(this.form[this.node.name]);
      if (this._.isEmpty(list)) {
        this.$emit("input", []);
      } else {
        this.$emit("input", list);
      }
      this.visible = false;
    },
    changeFormData({ name, value, type, callback }) {
      this.$set(this.form, name, value);
      if (typeof callback === "function") {
        callback();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
