<template>
  <div v-if="form.componentName == 'reference_data'">
    <div class="row">
      <div @click="editFill" class="filter-edit" v-if="form.filledRule.length">
        <span :class="{ 'del-span': deleteField }">{{
          deleteField ? "字段已删除" : "已设置填充规则"
        }}</span>
        <i class="el-icon-edit-outline"></i>
      </div>
      <div
        @click="setFill"
        v-else
        class="label row-between"
        style="width: 100%"
      >
        <span>填充规则设置</span>
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <el-dialog
      title="填充规则设置"
      :visible.sync="visible"
      @open="open"
      width="600px"
    >
      <div
        class="row-between"
        style="height: 380px; padding: 0 20px"
        v-if="step == 1"
      >
        <div class="flex column">
          <div class="tip">1.选择关联表字段</div>
          <div class="flex" style="overflow: auto">
            <el-checkbox
              class="chk-item"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              >全选</el-checkbox
            >
            <el-checkbox-group v-model="list" class="check-group">
              <template v-for="item in sourceComponents">
                <el-checkbox
                  v-if="item.componentName != 'json_form'"
                  :label="item.name"
                  :key="item.name"
                  :title="item.title"
                  class="chk-item"
                  @change="(checked) => dataChange(checked, item)"
                  >{{ item.title }}</el-checkbox
                >
                <template v-else>
                  <el-checkbox
                    v-for="child in item.components"
                    :label="`${item.name}.${child.name}`"
                    :key="child.name"
                    :title="`${item.title}.${child.title}`"
                    class="chk-item"
                    >{{ item.title }}.{{ child.title }}</el-checkbox
                  >
                </template>
              </template>
            </el-checkbox-group>
          </div>
        </div>
        <div class="column-line"></div>
        <div class="flex" style="padding-left: 20px; height: 100%">
          <div class="tip">2.字段如何处理</div>
          <div v-if="list.length">
            <el-select
              v-model="addType"
              placeholder=""
              size="small"
              style="width: 100%; margin-bottom: 10px"
            >
              <el-option label="填充到新字段" value="addToNew"> </el-option>
              <el-option label="填充到已有字段" value="addToOld"> </el-option>
            </el-select>
            <div class="tip" style="color: #91a1b7; line-height: 1.5">
              <span v-if="addType == 'addToNew'"
                >自动在表单中添加新的字段来处理关联表字段的值</span
              >
              <span v-else
                >将关联表字段值填充到表单已有字段中，需要设置对应关系</span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else style="height: 380px">
        <div style="padding: 0 20px">
          <div class="tip" style="color: #91a1b7; line-height: 1.5">
            选择数据后，将按以下规则将关联表字段的值填充到当前表单字段。
          </div>
          <div>
            <el-popover
              placement="bottom-start"
              popper-class="popover"
              v-model="overVisible"
            >
              <div v-clickoutside="handleClose">
                <div class="pop-scroll">
                  <div
                    class="form-item"
                    v-for="(item, index) in sourceComponents"
                    :key="index"
                  >
                    <div
                      v-if="item.componentName != 'json_form'"
                      @click="chkDisplayColumn(item.name)"
                      class="item-title"
                      :class="{ disabled: list.includes(item.name) }"
                    >
                      {{ item.title }}
                    </div>
                    <template v-else>
                      <div
                        class="item-title"
                        :class="{
                          disabled: list.includes(`${item.name}.${col.name}`),
                        }"
                        v-for="(col, cIndex) in item.components"
                        :key="cIndex"
                        @click="chkDisplayColumn(`${item.name}.${col.name}`)"
                      >
                        {{ item.title }}.{{ col.title }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div
                @click.stop=""
                slot="reference"
                style="color: #409eff; margin-bottom: 10px"
              >
                <i class="el-icon-plus"></i>
                <span>选择关联表字段</span>
              </div>
            </el-popover>
          </div>
        </div>

        <div style="height: 330px; padding: 0 20px; overflow: auto">
          <div
            v-for="(item, index) in filledRule"
            :key="item.relyFieldName"
            class="weui"
          >
            <div
              class="flex field-item"
              :class="{ delete: item.relyFieldDelete }"
              :title="item.relyFieldTitle"
              v-text="item.relyFieldTitle"
            ></div>
            <div style="font-size: 12px; margin-right: 10px">的值填充到</div>

            <el-select
              class="flex"
              :class="{ 'del-sel': item.fieldDelete }"
              size="small"
              v-model="item.fieldName"
              placeholder=""
              style="margin-right: 10px"
              @change="changeField(item, $event)"
            >
              <el-option
                v-for="opt in getOptions(item.relyFieldName)"
                :key="opt.name"
                :label="opt.title"
                :value="opt.name"
                :disabled="disabledOption(opt, item)"
              >
              </el-option>
            </el-select>
            <i class="el-icon-delete" @click="deleteRow(index)"></i>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="visible = false" size="small">取消</el-button>
        <el-button @click="save" type="primary" size="small">{{
          addType == "addToOld" && step == 1 ? "下一步" : "确定"
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { getFormComponents } from "@zgg-core-utils/utils";
let inputArr = ["radio_group"];
const multiSelectArr = ["select_checkbox", "checkbox_group"];
export default {
  directives: { Clickoutside },
  props: {
    form: Object,
    jsonFormName: String,
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
    componentList: Array,
  },
  inject: ["getFormEvents"],
  computed: {
    sourceComponents() {
      let list = [];
      let callback = (components) => {
        components
          .filter((item) => checkFieldAbility(item, "isFillReference"))
          .forEach((item) => {
            if (item.form) {
              if (this.jsonFormName) {
                if (item.componentName != "json_form") {
                  list.push(this._.cloneDeep(item));
                }
              } else {
                list.push(this._.cloneDeep(item));
              }
            } else if (item.container) {
              callback(item.components);
            }
          });
      };

      callback(this.fieldList);

      return list;
    },
    isIndeterminate() {
      if (this.checkAll) {
        return false;
      }
      return this.list.length > 0;
    },
    currComponentList() {
      let list = [];
      let callback = (components) => {
        components
          .filter(
            (item) =>
              // item.componentName != "checkbox_group" &&
              item.componentName != "reference_data",
          )
          .forEach((item) => {
            if (item.form) {
              list.push(item);
            } else if (item.container) {
              callback(item.components);
            }
          });
      };

      callback(this.componentList);

      return list;
    },
    checkAll: {
      get() {
        let length = 0;
        length = this.sourceComponents.filter(
          (item) => item.componentName != "json_form",
        ).length;
        this.sourceComponents
          .filter((item) => item.componentName == "json_form")
          .forEach((item) => {
            length += item.components.length;
          });
        return length > 0 && length == this.list.length;
      },
      set(val) {
        let list = [];
        if (val) {
          this.sourceComponents.forEach((item) => {
            if (item.componentName != "json_form") {
              list.push(item.name);
            } else {
              item.components.forEach((child) => {
                list.push(`${item.name}.${child.name}`);
              });
            }
            if (item.componentName == "sn_input") {
              this.hasSnInput = true;
            }
          });
        } else {
          this.hasSnInput = false;
        }
        this.$set(this, "list", list);
      },
    },
    list: {
      get() {
        return this.filledRule.map((item) => item.relyFieldName);
      },
      set(val) {
        let list = [];
        val.forEach((value) => {
          let fieldRule = this.filledRule.find(
            (item) => item.relyFieldName == value,
          );
          if (fieldRule) {
            list.push(this._.cloneDeep(fieldRule));
            return;
          }
          let obj = {
            relyFieldName: value,
            relyFieldTitle: "",
            relyFieldDelete: false,
            fieldName: "",
            fieldTitle: "",
            fieldDelete: false,
          };
          if (this.step == 2 && this.addType == "addToOld") {
            let field = this.getCurrentItem(value);
            if (field) {
              for (const [key, val] of Object.entries(field)) {
                obj[key] = val;
              }
            }
          }

          if (obj) {
            list.push(obj);
          }
        });

        this.$set(this, "filledRule", list);
      },
    },
    deleteField() {
      // 判断是否有已删除的字段，关联表和当前表
      let result = false;
      this.form.filledRule.forEach((item) => {
        if (!item.relyFieldName) {
          return;
        }
        if (item.relyFieldName.indexOf(".") == -1) {
          // 非子表单

          if (
            this.sourceComponents.findIndex(
              (row) => row.name == item.relyFieldName,
            ) == -1
          ) {
            result = true;
          }

          if (this.jsonFormName) {
            let names = item.fieldName.split(".");
            if (
              this.currComponentList.findIndex(
                (item) =>
                  item.name == names[0] &&
                  item.components.findIndex(
                    (child) => child.name == names[1],
                  ) >= 0,
              ) == -1
            ) {
              result = true;
            }
          } else {
            if (
              this.currComponentList.findIndex(
                (row) => row.name == item.fieldName,
              ) == -1
            ) {
              result = true;
            }
          }
        } else {
          // 子表单
          let names = item.relyFieldName.split(".");
          if (
            this.sourceComponents.findIndex(
              (item) =>
                item.name == names[0] &&
                item.components.findIndex((child) => child.name == names[1]) >=
                  0,
            ) == -1
          ) {
            result = true;
          }
          names = item.fieldName.split(".");
          if (
            this.currComponentList.findIndex(
              (item) =>
                item.name == names[0] &&
                item.components.findIndex((child) => child.name == names[1]) >=
                  0,
            ) == -1
          ) {
            result = true;
          }
        }
      });
      return result;
    },
  },
  data() {
    return {
      visible: false,
      addType: "addToNew",
      step: 1,
      overVisible: false,
      filledRule: [],
      hasSnInput: false, //选择的关联表字段是否有流水号
    };
  },
  methods: {
    hasRelyOrFrontEvent(item) {
      let component = this.getOptions(item.relyFieldName).find(
        (row) => row.name == item.fieldName,
      );
      if (component) {
        if (component.rely || component.dataLinkage) {
          this.$message.error(
            `${component.title}已设置公式联动，无法设置关联数据填充`,
          );
          return true;
        }
        if (typeof this.getFormEvents === "function") {
          let formEvents = this.getFormEvents();
          if (formEvents && formEvents.length) {
            let item = formEvents.find((event) => {
              let index = event.action.findIndex((action) => {
                return action.field == component.name;
              });
              if (index > -1) return event;
            });
            if (item) {
              this.$message.error(
                `${component.title}已设置前端事件，无法设置关联数据填充`,
              );
              return true;
            }
          }
        }
      }
      return false;
    },
    changeField(item, fieldName) {
      this.hasRelyOrFrontEvent(item);
      if (fieldName.indexOf(".") == -1) {
        item.fieldDelete = false;
        return;
      }
      let name = fieldName.split(".")[0];
      let list = [];
      this.filledRule
        .filter((row) => row.fieldName.indexOf(name) == 0)
        .forEach((row) => {
          if (row.relyFieldName.indexOf(".") >= 0) {
            let firstName = row.relyFieldName.split(".")[0];
            list.push(firstName);
          } else {
            list.push("");
          }
        });
      let set = new Set(list);
      let arr = [...set];

      if (arr.length > 1) {
        this.$confirm(
          "单个子表单不能使用不同子表单字段赋值。此操作会清除部分已设置的字段赋值方式，无法还原。",
          "确定要将值填充到此子表单字段？",
        )
          .then(() => {
            item.fieldDelete = false;
            this.filledRule.forEach((row) => {
              if (
                row.fieldName.indexOf(name) == 0 &&
                row.fieldName != fieldName
              ) {
                row.fieldName = "";
                row.fieldTitle = "";
                row.fieldDelete = false;
              }
            });
          })
          .catch(() => {
            item.fieldName = "";
            item.fieldTitle = "";
            item.fieldDelete = false;
          });
      } else {
        item.fieldDelete = false;
      }
    },
    disabledOption(opt, item) {
      return (
        this.filledRule.findIndex(
          (row) => row.fieldName == opt.name && row.fieldName != item.fieldName,
        ) >= 0
      );
    },
    handleClose() {
      this.overVisible = false;
    },
    deleteRow(index) {
      this.$delete(this.filledRule, index);
    },
    getFieldTitle(fieldName) {
      // 获取关联表字段的标题
      let obj = {
        isDelete: true,
        title: "关联表字段已被删除",
      };
      if (fieldName.indexOf(".") >= 0) {
        let names = fieldName.split(".");
        let parent = this.sourceComponents.find(
          (item) => item.name == names[0],
        );
        if (parent) {
          let child = parent.components.find((item) => item.name == names[1]);
          if (child) {
            obj.title = `${parent.title}.${child.title}`;
            obj.isDelete = false;
          }
        }
      } else {
        let field = this.sourceComponents.find(
          (item) => item.name == fieldName,
        );
        if (field) {
          obj.title = field.title;
          obj.isDelete = false;
        }
      }
      return obj;
    },
    getComponentTitle(fieldName) {
      // 获取当前表单字段的标题
      let obj = {
        title: "填充字段已被删除",
        isDelete: true,
      };
      if (fieldName.indexOf(".") >= 0) {
        // 子表单
        let names = fieldName.split(".");
        let parent = this.currComponentList.find(
          (item) => item.name == names[0],
        );
        if (parent) {
          let child = parent.components.find((item) => item.name == names[1]);
          if (child) {
            obj.title = `${parent.title}.${child.title}`;
            obj.isDelete = false;
          }
        }
      } else {
        // 非子表单
        let field = this.currComponentList.find(
          (item) => item.name == fieldName,
        );
        if (field) {
          obj.title = field.title;
          obj.isDelete = false;
        }
      }
      return obj;
    },
    open() {
      let filledRule = this._.cloneDeep(this.form.filledRule);
      filledRule.forEach((item) => {
        let relyObj = this.getFieldTitle(item.relyFieldName);
        item.relyFieldTitle = relyObj.title;
        item.relyFieldDelete = relyObj.isDelete;
        let obj = this.getComponentTitle(item.fieldName);
        item.fieldTitle = obj.title;
        item.fieldDelete = obj.isDelete;
        if (relyObj.isDelete) {
          item.fieldName = obj.title;
        } else if (obj.isDelete) {
          item.fieldName = obj.title;
        }
      });
      this.filledRule = filledRule;
    },
    editFill() {
      this.step = 2;
      this.addType = "addToOld";
      this.visible = true;
    },
    setFill() {
      if (!this.sourceComponents.length) {
        this.$message.error("请选择关联表");
        return;
      }
      this.visible = true;
      this.step = 1;
      this.addType = "addToNew";
    },
    save() {
      if (this.addType == "addToOld" && this.step == 1) {
        // 下一步
        this.buildNextStep();
        this.step = 2;
      } else if (this.addType == "addToNew") {
        // 添加到新的组件
        this.addToNew();
      } else {
        // 添加到已有组件保存
        if (this.filledRule.findIndex((item) => item.relyFieldDelete) >= 0) {
          this.$message.error("存在已删除的关联表字段，请重新配置");
          return;
        }
        if (this.filledRule.findIndex((item) => item.fieldDelete) >= 0) {
          this.$message.error("存在已删除的当前表字段，请重新配置");
          return;
        }
        let list = [];
        this.filledRule.forEach((item) => {
          list.push({
            relyFieldName: item.relyFieldName,
            fieldName: item.fieldName,
          });
        });
        let index = this.filledRule.findIndex((item) => {
          return this.hasRelyOrFrontEvent(item);
        });
        if (index > -1) return;
        // this.$set(this.form, "filledRule", list);
        this.$emit("changComponent", list);
        this.visible = false;
      }
    },
    buildNextStep() {
      this.filledRule.forEach((fillItem) => {
        let obj = this.getCurrentItem(fillItem.relyFieldName);

        if (obj) {
          for (const [key, value] of Object.entries(obj)) {
            fillItem[key] = value;
          }
        }
      });
    },
    getCurrentItem(fieldName) {
      let currentItem = {};

      if (this.jsonFormName) {
        let parent = this.currComponentList.find(
          (item) => item.name == this.jsonFormName,
        );
        if (parent) {
          let child = this.sourceComponents.find(
            (item) => item.name == fieldName,
          );
          currentItem.relyFieldTitle = `${child.title}`;

          let childItem = parent.components.find(
            (item) =>
              item.componentName == child.componentName &&
              !inputArr.includes(item.componentName) &&
              item.title == child.title &&
              !item.dataLinkage &&
              !item.rely,
          );

          if (childItem) {
            currentItem.fieldName = `${parent.name}.${childItem.name}`;
            currentItem.fieldTitle = `${parent.title}.${childItem.title}`;
          }
        }
      } else {
        if (fieldName.indexOf(".") == -1) {
          // 非子表单

          let field = this.sourceComponents.find(
            (item) => item.name == fieldName,
          );
          if (field) {
            currentItem.relyFieldTitle = field.title;
            let obj = this.currComponentList.find(
              (item) =>
                item.componentName == field.componentName &&
                !inputArr.includes(item.componentName) &&
                item.title == field.title &&
                !item.rely &&
                !item.dataLinkage,
            );
            if (obj) {
              currentItem.fieldName = obj.name;
              currentItem.fieldTitle = obj.title;
            }
          }
        } else {
          // 子表单
          let names = fieldName.split(".");
          let jsonForm = this.sourceComponents.find(
            (item) =>
              item.componentName == "json_form" && item.name == names[0],
          );
          if (jsonForm) {
            let child = jsonForm.components.find(
              (item) => item.name == names[1],
            );
            if (child) {
              currentItem.relyFieldTitle = `${jsonForm.title}.${child.title}`;
              let parent = this.currComponentList.find(
                (item) =>
                  item.componentName == "json_form" &&
                  item.title == jsonForm.title &&
                  !item.dataLinkage,
              );
              if (parent) {
                let childItem = parent.components.find(
                  (item) =>
                    item.componentName == child.componentName &&
                    !inputArr.includes(item.componentName) &&
                    item.title == child.title &&
                    !item.dataLinkage &&
                    !item.rely,
                );
                if (childItem) {
                  currentItem.fieldName = `${parent.name}.${childItem.name}`;
                  currentItem.fieldTitle = `${parent.title}.${childItem.title}`;
                }
              }
            }
          }
        }
      }

      return currentItem;
    },
    dataChange(checked, item) {
      if (item.componentName == "sn_input") {
        if (checked) {
          this.hasSnInput = true;
        } else {
          this.hasSnInput = false;
        }
      }
    },
    addToNew() {
      let index = this.componentList.findIndex(
        (item) => item.key == this.form.key,
      );
      let item = getFormComponents(this.componentList).find((row) => {
        return row.componentName == "sn_input";
      });
      if (item) {
        if (this.hasSnInput) {
          this.$message.error("已存在流水号字段，请重新配置");
          return;
        }
      }

      let list = [];
      let list2 = [];
      if (this.jsonFormName) {
        let parent = this.componentList.find(
          (item) => item.name == this.jsonFormName,
        );
        let parentIndex = this.componentList.findIndex(
          (item) => item.name == this.jsonFormName,
        );
        // let obj = this.componentList[index];

        if (parent) {
          this.filledRule.forEach((item, i) => {
            let obj = this.sourceComponents.find(
              (field) => field.name == item.relyFieldName,
            );
            if (obj) {
              obj = this._.cloneDeep(obj);
              let uuid = this.getUuid();
              obj.key = uuid;
              obj.name = uuid;
              if (inputArr.includes(obj.componentName)) {
                obj.componentName = "input";
              }
              delete obj.rely;
              delete obj.dataLinkage;
              parent.components.splice(parentIndex + i + 1, 0, obj);
              list2.push({
                relyFieldName: item.relyFieldName,
                fieldName: parent.name + "." + obj.name,
              });
            }
          });
        }
      } else {
        this.filledRule.forEach((item) => {
          if (item.relyFieldName.indexOf(".") == -1) {
            // 非子表单

            let obj = this.sourceComponents.find(
              (field) => field.name == item.relyFieldName,
            );
            if (obj) {
              obj = this._.cloneDeep(obj);
              let uuid = this.getUuid();
              obj.key = uuid;
              obj.name = uuid;
              if (inputArr.includes(obj.componentName)) {
                obj.componentName = "input";
              }
              delete obj.rely;
              delete obj.dataLinkage;
              list.push(obj);
              list2.push({
                relyFieldName: item.relyFieldName,
                fieldName: obj.name,
              });
            }
          } else {
            // 子表单
            let names = item.relyFieldName.split(".");
            let parent = this.sourceComponents.find(
              (field) => field.name == names[0],
            );
            let obj = list.find((item) => item.name == parent.name);
            if (!obj) {
              obj = this._.cloneDeep(parent);
              obj.components = [];
              delete obj.rely;
              delete obj.dataLinkage;
            }
            let child = parent.components.find((item) => item.name == names[1]);
            if (child) {
              child = this._.cloneDeep(child);
              let uuid = this.getUuid();
              child.key = uuid;
              child.name = uuid;
              if (inputArr.includes(child.componentName)) {
                child.componentName = "input";
              }
              delete child.rely;
              delete child.dataLinkage;
              obj.components.push(child);
              list2.push({
                relyFieldName: item.relyFieldName,
                fieldName: obj.name + "." + child.name,
              });
              if (list.findIndex((item) => item.name == obj.name) == -1) {
                list.push(obj);
              }
            }
          }
        });
      }

      let getParent = (list) => {
        let node;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];

          if (item.components.find((row) => row.key == this.form.key)) {
            node = item;
            break;
          } else {
            let childs = item.components.filter(
              (row) => row.components && row.componentName != "json_form",
            );
            if (childs && childs.length) {
              node = getParent(childs);
              if (node) {
                break;
              }
            }
          }
        }
        return node;
      };

      if (index >= 0) {
        if (list.length) {
          this.componentList.splice(index + 1, 0, ...list);
        }
      } else if (!this.jsonFormName) {
        let node = getParent(
          this.componentList.filter(
            (item) => item.components && item.componentName != "json_form",
          ),
        );
        if (node && list.length) {
          index = node.components.findIndex((row) => row.key == this.form.key);
          node.components.splice(index + 1, 0, ...list);
        }
      }

      if (list2.length) {
        this.$emit("changComponent", list2);
      }

      this.visible = false;
    },
    getOptions(relyFieldName) {
      let list = [];
      let componentName;
      if (relyFieldName.indexOf(".") == -1) {
        // 非子表单
        let field = this.sourceComponents.find(
          (item) => item.name == relyFieldName,
        );

        if (field) {
          componentName = field.componentName;
          let currComponentList = [];
          if (this.jsonFormName) {
            let parent = this.currComponentList.find(
              (item) => item.name == this.jsonFormName,
            );
            if (parent) {
              currComponentList = parent.components;
            }
          } else {
            currComponentList = this.currComponentList;
          }

          if (inputArr.includes(componentName)) {
            list = currComponentList.filter(
              (item) => item.componentName == "input",
            );
          } else if (
            componentName == "input_number" ||
            componentName == "date_picker" ||
            componentName == "select" ||
            componentName == "sn_input"
          ) {
            list = currComponentList.filter(
              (item) =>
                item.componentName == componentName ||
                item.componentName == "input",
            );
          } else {
            list = currComponentList.filter((item) => {
              if (item.componentName == componentName) {
                return true;
              }
              // 单行可以填充到多行文本
              if (
                componentName == "input" &&
                item.componentName == "text_area"
              ) {
                return true;
              }
              // 单行、多行文本可以填充到富文本
              if (
                (componentName == "text_area" || componentName == "input") &&
                item.componentName == "html_input"
              ) {
                return true;
              }
              // 用户单选可以填充到用户多选
              if (
                componentName == "user_select" &&
                item.componentName == "user_list_select"
              ) {
                return true;
              }
              // 部门单选可以填充到部门多选
              if (
                componentName == "department_select" &&
                item.componentName == "department_list_select"
              ) {
                return true;
              }
              // 复选框和下拉多选可以互相填充
              if (
                multiSelectArr.includes(componentName) &&
                multiSelectArr.includes(item.componentName)
              ) {
                return true;
              }
            });
          }

          if (this.jsonFormName) {
            let parent = this.currComponentList.find(
              (item) => item.name == this.jsonFormName,
            );

            list = this._.cloneDeep(list);
            list.forEach((item) => {
              item.title = `${parent.title}.${item.title}`;
              item.name = `${parent.name}.${item.name}`;
            });
          }
        }
      } else {
        // 子表单
        let names = relyFieldName.split(".");
        let parent = this.sourceComponents.find(
          (item) => item.name == names[0],
        );
        if (parent) {
          let child = parent.components.find((item) => item.name == names[1]);
          if (child) {
            let componentName = child.componentName;
            this.currComponentList
              .filter((item) => item.componentName == "json_form")
              .forEach((item) => {
                if (inputArr.includes(componentName)) {
                  item.components
                    .filter((item) => item.componentName == "input")
                    .forEach((childItem) => {
                      list.push({
                        ...childItem,
                        title: `${item.title}.${childItem.title}`,
                        name: `${item.name}.${childItem.name}`,
                      });
                    });
                } else if (
                  componentName == "input_number" ||
                  componentName == "date_picker"
                ) {
                  item.components
                    .filter(
                      (item) =>
                        item.componentName == componentName ||
                        item.componentName == "input",
                    )
                    .forEach((childItem) => {
                      list.push({
                        ...childItem,
                        title: `${item.title}.${childItem.title}`,
                        name: `${item.name}.${childItem.name}`,
                      });
                    });
                } else {
                  item.components
                    .filter((item) => {
                      if (item.componentName == componentName) {
                        return true;
                      }
                      // 单行可以填充到多行文本
                      if (
                        componentName == "input" &&
                        item.componentName == "text_area"
                      ) {
                        return true;
                      }
                      // 单行、多行文本可以填充到富文本
                      if (
                        (componentName == "text_area" ||
                          componentName == "input") &&
                        item.componentName == "html_input"
                      ) {
                        return true;
                      }
                      // 用户单选可以填充到用户多选
                      if (
                        componentName == "user_select" &&
                        item.componentName == "user_list_select"
                      ) {
                        return true;
                      }
                      // 部门单选可以填充到部门多选
                      if (
                        componentName == "department_select" &&
                        item.componentName == "department_list_select"
                      ) {
                        return true;
                      }
                      // 复选框和下拉多选可以互相填充
                      if (
                        multiSelectArr.includes(componentName) &&
                        multiSelectArr.includes(item.componentName)
                      ) {
                        return true;
                      }
                    })
                    .forEach((childItem) => {
                      list.push({
                        ...childItem,
                        title: `${item.title}.${childItem.title}`,
                        name: `${item.name}.${childItem.name}`,
                      });
                    });
                }
              });
          }
        }
      }
      return list;
    },
    chkDisplayColumn(fieldName) {
      if (this.list.includes(fieldName)) {
        return;
      }
      this.overVisible = false;
      let list = this._.cloneDeep(this.list);
      list.push(fieldName);
      this.list = list;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-edit {
  border: solid 1px #dcdfe6;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.row-between {
  display: flex;
  justify-content: space-between;
}
.row {
  margin: 10px;
  align-items: center;
  .label {
    width: 100px;
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.flex {
  flex: 1;
}
.column {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.column-line {
  width: 1px;
  background-color: #f4f5f7;
  height: 100%;
}
.check-group {
  display: flex;
  flex-direction: column;
}
.chk-item {
  width: max-content;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  ::v-deep {
    .el-checkbox__label {
      font-size: 12px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.tip {
  margin-bottom: 10px;
  font-size: 12px;
}
.weui {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-item {
  line-height: 30px;
  font-size: 14px;

  .item-title {
    padding: 0px 10px;
    &.disabled {
      color: #c3cdda;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.3);
    }

    &.select {
      background-color: #409eff;

      &:hover {
        background-color: #409eff;
      }
    }
  }
}

.pop-scroll {
  max-height: 320px;
  overflow: auto;
}
.field-item {
  height: 28px;
  line-height: 28px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  font-size: 12px;
  padding: 0px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  &.delete {
    color: red;
  }
}
.del-span {
  color: red;
}
.del-sel {
  ::v-deep {
    .el-input__inner {
      color: red;
    }
  }
}
</style>
<style lang="scss">
.popover {
  &.el-popover {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
