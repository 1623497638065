/**
 * 定时触发循环类型
 */
export const timerCronLoopTypeEnums = [
  {
    label: "每小时",
    value: "perHour",
  },
  {
    label: "每天",
    value: "perDay",
  },
  {
    label: "工作日（星期一至星期五）",
    value: "workday",
  },
  {
    label: "每周",
    value: "perWeek",
  },
  {
    label: "每月",
    value: "perMonth",
  },
  {
    label: "每年",
    value: "perYear",
  },
  {
    label: "自定义",
    value: "custom",
  },
];

/**
 * 小时的循环类型
 */
export const hourTriggerTypeEnums = [
  {
    label: "每小时都触发",
    value: "all",
  },
  {
    label: "按范围触发",
    value: "range",
  },
  {
    label: "按固定值触发",
    value: "appoint",
  },
  {
    label: "按一定增量触发",
    value: "increment",
  },
];

/**
 * 天的循环类型
 */
export const dayTriggerTypeEnums = [
  {
    label: "每天都触发",
    value: "all",
  },
  {
    label: "按范围触发",
    value: "range",
  },
  {
    label: "按固定值触发",
    value: "appoint",
  },
  {
    label: "按一定增量触发",
    value: "increment",
  },
  {
    label: "每月的最后一天触发",
    value: "last",
  },
];

/**
 * 月的循环类型
 */
export const monthTriggerTypeEnums = [
  {
    label: "每月都触发",
    value: "all",
  },
  {
    label: "按固定值触发",
    value: "appoint",
  },
];

/**
 * 日期字段-开始执行时间类型
 */
export const startExecuteTimeTypeEnums = [
  {
    label: "在以上日期时间",
    value: "current",
  },
  {
    label: "之前",
    value: "before",
  },
  {
    label: "之后",
    value: "after",
  },
];

/**
 * 日期字段——重复周期
 */
export const timerFieldLoopTypeEnums = [
  {
    label: "不重复",
    value: "once",
  },
  {
    label: "每日",
    value: "perDay",
  },
  {
    label: "每周",
    value: "perWeek",
  },
  {
    label: "每月",
    value: "perMonth",
  },
  {
    label: "每年",
    value: "perYear",
  },
];

/**
 * 节点类型列表
 */
export const nodeTypeList = [
  {
    label: "数据变动触发器",
    value: "trigger_form_data_change",
    icon: "el-icon-s-grid",
    bg: "bg_yellow",
    type: "trigger",
  },
  {
    label: "定时单次",
    value: "trigger_timer_once",
    bg: "bg_blue",
    icon: "el-icon-alarm-clock",
    type: "trigger",
  },
  {
    label: "定时多次",
    value: "trigger_timer_cron",
    bg: "bg_blue",
    icon: "el-icon-alarm-clock",
    type: "trigger",
  },
  {
    label: "按日期字段定时",
    value: "trigger_timer_field",
    bg: "bg_blue",
    icon: "el-icon-date",
    type: "trigger",
  },
  {
    label: "Webhook",
    value: "trigger_webhook",
    bg: "bg_blue",
    icon: "el-icon-date",
    type: "trigger",
  },
  {
    label: "点击触发",
    value: "trigger_click",
    icon: "el-icon-thumb",
    bg: "bg_blue",
    type: "trigger",
  },
  {
    label: "子流程",
    value: "trigger_subflow_collection",
    icon: "iconfont icon-node_tree",
    bg: "bg_gray",
    type: "trigger",
  },
  {
    label: "新增记录",
    value: "action_form_data_create",
    icon: "el-icon-document-add",
    bg: "bg_yellow",
    type: "data",
  },
  {
    label: "更新记录",
    value: "action_form_data_update",
    icon: "el-icon-edit-outline",
    bg: "bg_yellow",
    type: "data",
  },
  {
    label: "删除记录",
    value: "action_form_data_delete",
    icon: "el-icon-delete",
    bg: "bg_yellow",
    type: "data",
  },
  {
    label: "修改或新增记录",
    value: "action_form_data_save",
    icon: "el-icon-document",
    bg: "bg_yellow",
    type: "data",
  },
  {
    label: "获取单条数据",
    value: "query_data_one",
    icon: "el-icon-search",
    bg: "bg_yellow",
    type: "data",
  },
  {
    label: "获取多条数据",
    value: "query_data_list",
    icon: "el-icon-suitcase",
    bg: "bg_yellow",
    type: "data",
  },
  {
    label: "更新流程参数",
    value: "flow_param_update",
    icon: "el-icon-s-operation",
    bg: "bg_yellow",
    type: "data",
  },

  {
    label: "发送短信通知",
    value: "notice_sms",
    icon: "el-icon-chat-dot-square",
    bg: "bg_blue",
    type: "notice",
  },
  {
    label: "发送公众号通知",
    value: "notice_wechat",
    icon: "iconfont icon-Wechat",
    bg: "bg_blue",
    type: "notice",
  },
  {
    label: "插件",
    value: "developer_plugin",
    icon: "iconfont icon-code-s-slash-line",
    bg: "bg_gray",
    type: "developer",
  },
  {
    label: "JSON解析",
    value: "json_parser",
    icon: "iconfont icon-code-box-line",
    bg: "bg_gray",
    type: "developer",
  },
  {
    label: "发送API请求",
    value: "http_request",
    icon: "el-icon-help",
    bg: "bg_gray",
    type: "developer",
  },
  {
    label: "流程审批",
    value: "action_form_data_commit",
    icon: "iconfont icon-stamp-fill",
    bg: "bg_blue",
    type: "agenda",
  },
  {
    label: "分支",
    value: "branch",
    icon: "iconfont icon-tree",
    bg: "bg_gray",
    type: "part",
  },
  {
    label: "分支",
    value: "condition",
    type: "branch",
  },
  {
    label: "子流程",
    value: "subflow_collection",
    icon: "iconfont icon-node_tree",
    bg: "bg_gray",
    type: "part",
  },
  {
    label: "通知",
    value: "render_notice",
    icon: "el-icon-chat-line-square",
    bg: "bg_blue",
    type: "render",
  },
  {
    label: "表单",
    value: "render_form",
    icon: "el-icon-document",
    bg: "bg_blue",
    type: "render",
  },
  {
    label: "打开链接",
    value: "render_url",
    icon: "el-icon-link",
    bg: "bg_blue",
    type: "render",
  },
];
