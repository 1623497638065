<template>
  <div>
    <el-cascader
      :disabled="disabled"
      :options="areaList"
      style="width: 100%"
      placeholder="请选择地址"
      :props="areaTreeProp"
      v-model="area"
    >
    </el-cascader>
    <el-input
      v-if="picker == 'address'"
      type="textarea"
      placeholder="请填写详细地址"
      :rows="2"
      resize="none"
      style="width: 100%; margin-top: 10px"
      :disabled="disabled"
      v-model="address"
    ></el-input>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: [Object, String],
      default() {
        return {};
      },
    },
    areaTree: {
      type: Array,
      default() {
        return [];
      },
    },
    picker: String,
  },
  computed: {
    area: {
      get() {
        let area = [];
        if (
          this.value &&
          typeof this.value === "object" &&
          Object.keys(this.value).length
        ) {
          if (this.picker == "province") {
            area.push(this.value.provinceId);
          } else if (this.picker == "city") {
            area = [this.value.provinceId, this.value.cityId];
          } else {
            area = [
              this.value.provinceId,
              this.value.cityId,
              this.value.countyId,
            ];
          }
        }
        return area;
      },
      set(val) {
        let obj = this._.cloneDeep(this.value);
        if (!obj) {
          obj = {
            province: "",
            provinceId: "",
            city: "",
            cityId: "",
            county: "",
            countyId: "",
            address: "",
          };
        }
        let provinceId = val[0];
        let cityId = val[1];
        let countyId = val[2];
        let provinceItem;
        let cityItem;
        let countyItem;
        if (provinceId) {
          provinceItem = this.areaTree.find((item) => item.id == provinceId);
          obj.provinceId = provinceId;
          obj.province = provinceItem.name;
        }
        if (this.picker != "province") {
          if (cityId) {
            cityItem = provinceItem.children.find((item) => item.id == cityId);
            obj.cityId = cityId;
            obj.city = cityItem.name;
          }
        }
        if (this.picker != "province" && this.picker != "city") {
          if (countyId) {
            countyItem = cityItem.children.find((item) => item.id == countyId);
            obj.countyId = countyId;
            obj.county = countyItem.name;
          }
        }

        if (this.picker != "address") {
          obj.address = "";
        }

        this.$emit("input", obj);
        this.$emit("change", obj);
      },
    },
    address: {
      get() {
        let str = "";
        if (this.value && this.value.address) {
          str = this.value.address;
        }
        return str;
      },
      set(val) {
        let obj = this._.cloneDeep(this.value);
        if (!obj) {
          obj = {
            province: "",
            provinceId: "",
            city: "",
            cityId: "",
            county: "",
            countyId: "",
            address: "",
          };
        }
        obj.address = val;
        this.$emit("input", obj);
        this.$emit("change", obj);
      },
    },
    areaList() {
      let list = [];
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (this.picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (this.picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            if (
              this.picker == "city" &&
              !["北京市", "天津市", "上海市", "重庆市"].includes(city.name)
            ) {
              if (
                city.extAttr &&
                city.extAttr.code &&
                city.extAttr.code.indexOf("EMPTY") == -1
              ) {
                province.children.push(city);
              }
            } else {
              province.children.push(city);
            }
          });
        }
        list.push(province);
      });
      return list;
    },
  },
  data() {
    return {
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
      },
    };
  },
  methods: {
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>