 <template>
  <div>
    <div class="attr-row">
      <div class="attr-label">水平对齐方式</div>
      <el-radio-group v-model="component.align" size="mini">
        <el-radio-button label="left">左对齐</el-radio-button>
        <el-radio-button label="center">居中</el-radio-button>
        <el-radio-button label="right">右对齐</el-radio-button>
      </el-radio-group>
    </div>
    <div class="attr-row">
      <div class="attr-label">垂直对齐方式</div>
      <el-radio-group v-model="component.valign" size="mini">
        <el-radio-button label="top">顶部对齐</el-radio-button>
        <el-radio-button label="middle">居中</el-radio-button>
        <el-radio-button label="bottom">底部对齐</el-radio-button>
      </el-radio-group>
    </div>
    <div class="attr-row">
      <div class="attr-label">高度</div>
      <el-input
        v-model.number="component.height"
        @change="changeHeight"
        placeholder=""
        size="mini"
      ></el-input>
    </div>

    <div class="attr-row">
      <el-checkbox v-model="border"
        ><span style="font-size: 12px">自定义边框</span></el-checkbox
      >
    </div>
    <template v-if="border">
      <div class="attr-row">
        <div class="attr-label">边框宽度</div>
        <el-input
          v-model.number="component.border.width"
          size="mini"
          placeholder=""
          style="width: 100%"
        ></el-input>
      </div>
      <div class="attr-row">
        <div class="attr-label">边框颜色</div>
        <el-color-picker
          size="small"
          v-model="component.border.color"
        ></el-color-picker>
      </div>
      <div class="attr-row">
        <div class="attr-label">边框线型</div>
        <border-style v-model="component.border.style"></border-style>
      </div>

      <div class="attr-row">
        <el-checkbox v-model="borderLeft"
          ><span style="font-size: 12px">左边框</span></el-checkbox
        >
      </div>
      <template v-if="borderLeft">
        <div class="attr-row">
          <div class="attr-label">边框宽度</div>
          <el-input
            v-model.number="component.borderLeft.width"
            size="mini"
            placeholder=""
            style="width: 100%"
          ></el-input>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框颜色</div>
          <el-color-picker
            size="small"
            v-model="component.borderLeft.color"
          ></el-color-picker>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框线型</div>
          <border-style v-model="component.borderLeft.style"></border-style>
        </div>
      </template>
      <div class="attr-row">
        <el-checkbox v-model="borderTop"
          ><span style="font-size: 12px">上边框</span></el-checkbox
        >
      </div>
      <template v-if="borderTop">
        <div class="attr-row">
          <div class="attr-label">边框宽度</div>
          <el-input
            v-model.number="component.borderTop.width"
            size="mini"
            placeholder=""
            style="width: 100%"
          ></el-input>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框颜色</div>
          <el-color-picker
            size="small"
            v-model="component.borderTop.color"
          ></el-color-picker>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框线型</div>
          <border-style v-model="component.borderTop.style"></border-style>
        </div>
      </template>
      <div class="attr-row">
        <el-checkbox v-model="borderRight"
          ><span style="font-size: 12px">右边框</span></el-checkbox
        >
      </div>
      <template v-if="borderRight">
        <div class="attr-row">
          <div class="attr-label">边框宽度</div>
          <el-input
            v-model.number="component.borderRight.width"
            size="mini"
            placeholder=""
            style="width: 100%"
          ></el-input>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框颜色</div>
          <el-color-picker
            size="small"
            v-model="component.borderRight.color"
          ></el-color-picker>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框线型</div>
          <border-style v-model="component.borderRight.style"></border-style>
        </div>
      </template>
      <div class="attr-row">
        <el-checkbox v-model="borderBottom"
          ><span style="font-size: 12px">下边框</span></el-checkbox
        >
      </div>
      <template v-if="borderBottom">
        <div class="attr-row">
          <div class="attr-label">边框宽度</div>
          <el-input
            v-model.number="component.borderBottom.width"
            size="mini"
            placeholder=""
            style="width: 100%"
          ></el-input>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框颜色</div>
          <el-color-picker
            size="small"
            v-model="component.borderBottom.color"
          ></el-color-picker>
        </div>
        <div class="attr-row">
          <div class="attr-label">边框线型</div>
          <border-style v-model="component.borderBottom.style"></border-style>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import BorderStyle from "../component/BorderStyle";
import { componentProps } from "./util";

export default {
  name: "attr-grid-col",
  components: { BorderStyle },
  inject: ["getComponents"],
  props: componentProps,
  computed: {
    border: {
      get() {
        if (this.component.border) {
          return true;
        }
        return false;
      },
      set(val) {
        if (!val) {
          this.$set(this.component, "border", undefined);
          this.$set(this.component, "borderLeft", undefined);
          this.$set(this.component, "borderRight", undefined);
          this.$set(this.component, "borderTop", undefined);
          this.$set(this.component, "borderBottom", undefined);
        } else {
          this.$set(this.component, "border", {
            width: 1,
            color: "#333333",
            style: "solid",
          });
        }
      },
    },
    borderLeft: {
      get() {
        if (this.component.borderLeft) {
          return true;
        }
        return false;
      },
      set(val) {
        if (val) {
          this.$set(
            this.component,
            "borderLeft",
            this._.cloneDeep(this.component.border)
          );
        } else {
          this.$set(this.component, "borderLeft", undefined);
        }
      },
    },
    borderRight: {
      get() {
        if (this.component.borderRight) {
          return true;
        }
        return false;
      },
      set(val) {
        if (val) {
          this.$set(
            this.component,
            "borderRight",
            this._.cloneDeep(this.component.border)
          );
        } else {
          this.$set(this.component, "borderRight", undefined);
        }
      },
    },
    borderTop: {
      get() {
        if (this.component.borderTop) {
          return true;
        }
        return false;
      },
      set(val) {
        if (val) {
          this.$set(
            this.component,
            "borderTop",
            this._.cloneDeep(this.component.border)
          );
        } else {
          this.$set(this.component, "borderTop", undefined);
        }
      },
    },
    borderBottom: {
      get() {
        if (this.component.borderBottom) {
          return true;
        }
        return false;
      },
      set(val) {
        if (val) {
          let border = this._.cloneDeep(this.component.border);
          if (!border) {
            border = {
              width: 1,
              color: "#333333",
              style: "solid",
            };
          }
          this.$set(this.component, "borderBottom", border);
        } else {
          this.$set(this.component, "borderBottom", undefined);
        }
      },
    },
  },
  methods: {
    getParent() {
      let components = this.getComponents();
      let parent;
      let list = components.filter((item) => item.componentName == "grid");
      for (let i = 0; i < list.length; i++) {
        let row = list[i];
        for (let j = 0; j < row.components.length; j++) {
          const col = row.components[j];
          if (col.key == this.component.key) {
            parent = row;
            break;
          }
        }
        if (parent) {
          break;
        }
      }
      return parent;
    },
    changeHeight(value) {
      let parent = this.getParent();
      if (parent) {
        parent.components.forEach((col) => {
          col.height = value;
        });
      }
    },
  },
};
</script>