<template>
  <el-dialog
    title="复制任务"
    :visible="visible"
    width="500px"
    @close="close"
    @open="open"
    :destroy-on-close="true"
    :append-to-body="true"
  >
    <div class="app-container">
      <el-form v-model="copyForm" label-position="top">
        <el-form-item label="看板" prop="boardId">
          <el-select
            filterable
            v-model="copyForm.boardId"
            style="width: 100%"
            placeholder="请选择看板"
          >
            <el-option
              v-for="item in boardList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组" prop="bulletinColumnId">
          <el-select
            v-model="copyForm.bulletinColumnId"
            style="width: 100%"
            placeholder="请选择分组"
          >
            <el-option
              v-for="item in columnList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!card.templated" label="位置" prop="cardIndex">
          <el-select
            v-model="copyForm.cardIndex"
            style="width: 100%"
            placeholder="请选择位置"
          >
            <template v-if="cardList.length > 0">
              <el-option
                v-for="(card, index) in cardList"
                :key="index"
                :value="index"
                :label="index + 1"
              ></el-option>
            </template>

            <el-option v-else :value="0" :label="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="cardCopyCommit">复制并新建</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  bulletinCardCopy,
  bulletinList,
  bulletinColumnList,
  bulletinCardList,
  bulletinSelect,
  bulletinCardSelect,
  bulletinColumnSelect,
} from "@/api/boardManage";
import { param } from "@/utils";

export default {
  props: {
    visible: Boolean,
    card: Object,
    index: Number,
  },
  computed: {
    // cardList() {
    //   let col = this.columnList.find(
    //       (item) => item.id == this.copyForm.columnId
    //   );
    //   if (col && col.cardList) {
    //     return col.cardList;
    //   } else {
    //     return [];
    //   }
    // },
  },
  data() {
    return {
      copyVisible: false,
      copyForm: {
        boardId: "",
        bulletinColumnId: "",
        cardIndex: "",
      },
      boardList: [],
      columnList: [],
      cardList: [],
    };
  },
  destroyed() {
    this.$emit("update:visible", false);
  },
  watch: {
    "copyForm.boardId": {
      deep: true,
      handler(val) {
        console.log(val);
        this.apiGetBulletinColumnList();
      },
    },

    "copyForm.bulletinColumnId": {
      deep: true,
      handler(val) {
        console.log(val);
        if (val) {
          this.apiGetBulletinCardList();
        } else {
          this.copyForm.cardIndex = 0;
          this.cardList = [];
        }
      },
    },
  },
  methods: {
    apiGetBulletinCardList() {
      let params = {
        bulletinId: this.copyForm.boardId,
        bulletinColumnId: this.copyForm.bulletinColumnId,
        archived: false,
      };
      bulletinCardSelect(params).then(({ data }) => {
        this.cardList = data.modelList;
        this.cardList.push([]);
      });
    },
    apiGetBulletinColumnList() {
      bulletinColumnSelect({
        bulletinId: this.copyForm.boardId,
        archived: false,
      }).then((resp) => {
        this.columnList = resp.data.modelList;
        if (this.card.bulletinId != this.copyForm.boardId) {
          if (this.columnList.length != 0) {
            this.copyForm.bulletinColumnId = this.columnList[0].id;
          } else {
            this.copyForm.bulletinColumnId = "";
          }
        } else {
          this.copyForm.bulletinColumnId = this.card.bulletinColumnId;
        }
        // this.copyForm.cardIndex = 0
      });
    },
    async apiGetBoardList() {
      await bulletinSelect().then((resp) => {
        this.boardList = resp.data.modelList;
      });
    },
    async open() {
      console.log(this.card);
      console.log(this.index);
      let form = {
        bulletinColumnId: this.card.bulletinColumnId,
        boardId: this.card.bulletinId,
        cardIndex: this.index + 1,
      };

      await this.apiGetBoardList();
      this.$set(this, "copyForm", form);
      // await this.apiGetBulletinColumnList()
    },
    close() {
      this.$emit("update:visible", false);
    },
    cardCopyCommit() {
      let sortNumber = 1;
      let targetIndex = this.copyForm.cardIndex;
      let n = 1;
      if (targetIndex - 1 >= 0) {
        sortNumber = this.cardList[targetIndex - n].sortNumber + 1;
      } else {
        sortNumber = 0;
      }
      let params = {
        cardId: this.card.id,
        toColumnId: this.copyForm.bulletinColumnId,
        toBulletinId: this.copyForm.boardId,
      };
      if (sortNumber) {
        params.sortNumber = sortNumber;
      } else {
        params.sortNumber = 0;
      }
      bulletinCardCopy(params).then((res) => {
        this.$message.success("复制成功");
        this.$emit("update:visible", false);
        this.$emit("columnRefresh");
      });
    },
  },
};
</script>
