<template>
  <div style="padding: 20px">
    <div>
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
        @keyup.enter.native="search"
        size="small"
      >
        <el-form-item label="搜索：">
          <el-input
            placeholder="搜索单位名"
            v-model="searchForm.keyword"
            clearable
            @clear="search"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search()" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item v-if="hasCopyData" style="margin-left: 20px">
          <el-checkbox v-model="enabledCopyData">复制数据</el-checkbox>
          <el-checkbox v-model="enabledCopyFormTemplate"
            >复制导出模板</el-checkbox
          >
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(50vh)"
        border
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column label="选中项" align="center" width="70">
          <template slot-scope="{ row }">
            <el-radio
              v-model="checkedCompany"
              :label="row.companyId"
              :disabled="isDisabledChecked(row)"
              >{{ "" }}</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="名称"
          align="center"
        ></el-table-column>
      </el-table>
      <pagination
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadingList"
      />
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { listMyCompany } from "@/api/operationCenter";
export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      page: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      searchForm: {
        keyword: "",
      },
      checkedCompany: "",
      enabledCopyFormTemplate: false,
      enabledCopyData: false,
    };
  },
  props: {
    currentCompanyId: {
      default: () => "",
    },
    hasCopyData: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    this.loadingList();
  },
  methods: {
    isDisabledChecked(row) {
      return this.currentCompanyId
        ? row.companyId == this.currentCompanyId
        : false;
    },
    search() {
      this.loadingList();
    },

    loadingList() {
      listMyCompany({
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        keyword: this.searchForm.keyword,
      }).then((res) => {
        this.tableData = res.data.page.list;
        this.page.pageNumber = res.data.page.pageNumber;
        this.page.totalPage = res.data.page.totalPage;
        this.page.totalRow = res.data.page.totalRow;
      });
    },
  },
};
</script>
