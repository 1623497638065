<template>
  <div v-loading="loading" class="address-sync-setup" style="display: flex">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      "
    >
      <el-form
        v-if="!show"
        ref="form"
        :model="form"
        :rules="rules"
        class="form"
        label-width="180px"
      >
        <el-divider content-position="center">部门同步表配置</el-divider>
        <el-form-item label="选择一个部门表单：" prop="sheet">
          <el-select
            v-model="form.sheet"
            :disabled="disabled"
            placeholder="请选择"
            @change="handleSelectForm"
          >
            <el-option
              v-for="(item, index) in departmentFormList"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配部门名称字段：" prop="departmentName">
          <el-select
            v-model="form.departmentName"
            :disabled="disabled"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in departmentFiledList"
              :key="index"
              :label="item.comment"
              :value="item.fieldName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配上级部门字段：" prop="superDepartment">
          <el-select
            v-model="form.superDepartment"
            :disabled="disabled"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in superDepartmentFiledList"
              :key="index"
              :label="item.comment"
              :value="item.fieldName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider content-position="center">人员同步表配置</el-divider>
        <el-form-item label="选择一个人员表单：" prop="unit">
          <el-select
            v-model="form.unit"
            :disabled="disabled"
            placeholder="请选择"
            @change="handleStaffForm"
          >
            <el-option
              v-for="(item, index) in staffFormList"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配姓名字段：" prop="name">
          <el-select
            v-model="form.name"
            :disabled="disabled"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in nameFiledList"
              :key="index"
              :label="item.comment"
              :value="item.fieldName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配手机号字段：" prop="mobile">
          <el-select
            v-model="form.mobile"
            :disabled="disabled"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in mobileFiledList"
              :key="index"
              :label="item.comment"
              :value="item.fieldName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配所属部门字段：" prop="department">
          <el-select
            v-model="form.department"
            :disabled="disabled"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in belongDepartmentFiledList"
              :key="index"
              :label="item.comment"
              :value="item.fieldName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="disabled" type="primary" @click="confirmSync">{{
            disabled ? "该单位通讯录已同步到表单" : "确定同步"
          }}</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-if="show"
        ref="disabledForm"
        :model="form"
        class="disabled-form"
        label-width="180px"
      >
        <el-divider content-position="center">部门同步表配置</el-divider>
        <el-form-item label="选择一个部门表单：">
          <el-input
            v-model="syncFormConfig.departmentFormTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配部门名称字段：">
          <el-input
            v-model="syncFormConfig.departmentNameFieldTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配上级部门字段：">
          <el-input
            v-model="syncFormConfig.employeeDepartmentFieldTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-divider content-position="center">人员同步表配置</el-divider>
        <el-form-item label="选择一个人员表单：">
          <el-input
            v-model="syncFormConfig.employeeFormTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配姓名字段：">
          <el-input
            v-model="syncFormConfig.employeeNameFieldTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配手机号字段：">
          <el-input
            v-model="syncFormConfig.mobileFieldTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配所属部门字段：">
          <el-input
            v-model="syncFormConfig.parentDepartmentFieldTitle"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button disabled type="primary" @click="confirmSync"
            >该单位通讯录已同步到表单</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  companyContactSwitchSyncForm,
  companyContactSyncConfigDetail,
  companyContactSyncFormFieldList,
  companyFormList,
} from "@/api/unit-setup";

export default {
  name: "address-sync-setup",
  data() {
    return {
      form: {
        sheet: "",
        departmentName: "",
        superDepartment: "",
        unit: "",
        name: "",
        mobile: "",
        department: "",
      },
      departmentFormList: [],
      departmentFiledList: [],
      superDepartmentFiledList: [],
      staffFormList: [],
      departmentFormId: "",
      nameFiledList: [],
      mobileFiledList: [],
      belongDepartmentFiledList: [],
      disabled: false,
      show: false,
      rules: {
        sheet: [
          { required: true, message: "请选择一个表单", trigger: "change" },
        ],
        departmentName: [
          { required: true, message: "请选择部门名称字段", trigger: "change" },
        ],
        superDepartment: [
          { required: true, message: "请选择上级部门字段", trigger: "change" },
        ],
        unit: [
          { required: true, message: "请选择一个表单", trigger: "change" },
        ],
        name: [
          { required: true, message: "请选择姓名字段", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请选择手机号", trigger: "change" },
        ],
        department: [
          { required: true, message: "请选择所属部门字段", trigger: "change" },
        ],
      },
      syncFormConfig: {
        departmentFormTitle: "",
        departmentNameFieldTitle: "",
        employeeDepartmentFieldTitle: "",
        employeeFormTitle: "",
        employeeNameFieldTitle: "",
        mobileFieldTitle: "",
        parentDepartmentFieldTitle: "",
      },
      loading: true,
    };
  },
  watch: {
    syncFormConfig: {
      deep: true,
      immediate: true,
      handler(val) {
        this.syncFormConfig = val;
      },
    },
  },
  async created() {
    let res = await companyContactSyncConfigDetail({
      companyId: this.$store.state.user.currentCompanyId,
    }).catch((err) => {
      this.loading = false;
      this.disabled = true;
      console.log(err);
    });
    console.log(res);
    res = res.data.model;
    if (res) {
      this.show = true;
      this.loading = false;
      this.syncFormConfig = res.formConfig;
      // this.disabled = true
      // let resp = await companyFormList({
      //   companyId: res.companyId,
      //   listScene: 'sync_co_contact_department',
      //   pageNumber:1,
      //   pageSize:999
      // })
      // this.departmentFormList = resp.data.page.list
      // let formId = this.departmentFormList[0].id
      //
      // // 请求部门名称字段
      // let resp1 = await companyContactSyncFormFieldList({
      //   companyId: res.companyId,
      //   listScene: 'sync_co_contact_department',
      //   formId,
      // })
      // this.departmentFiledList = resp1.data.fieldList.filter(item=>item.fieldTypeName === 'department_name' && item.fieldCheck)
      // this.superDepartmentFiledList = resp1.data.fieldList.filter(item=>item.fieldTypeName === 'parent_department' && item.fieldCheck)
      // let resp2 = await companyFormList({
      //   companyId: res.companyId,
      //   listScene: 'sync_co_contact_employee',
      //   departmentFormId: res.departmentDataSourceId,
      //   pageNumber:1,
      //   pageSize:999
      // })
      // this.staffFormList = resp2.data.page.list
      //
      // let resp3 = await companyContactSyncFormFieldList({
      //   companyId: res.companyId,
      //   listScene: 'sync_co_contact_employee',
      //   departmentFormId:res.departmentDataSourceId,
      //   formId:res.employeeDataSourceId
      // })
      // this.nameFiledList = resp3.data.fieldList.filter(item=>item.fieldTypeName = "employee_name")
      // this.mobileFiledList = resp3.data.fieldList.filter(item=>item.fieldTypeName = "employee_mobile")
      // this.belongDepartmentFiledList = resp3.data.fieldList.filter(item=>item.fieldTypeName = "employee_department")
    } else {
      this.getCompanyFormList();
    }
  },

  methods: {
    handleSelectForm(id) {
      this.departmentFormId = id;
      let params = {
        companyId: this.$store.state.user.currentCompanyId,
        listScene: "sync_co_contact_department",
        formId: id,
      };
      companyContactSyncFormFieldList(params).then((res) => {
        this.departmentFiledList = res.data.fieldList.filter(
          (item) => item.fieldTypeName === "department_name",
        );
        this.superDepartmentFiledList = res.data.fieldList.filter(
          (item) => item.fieldTypeName === "parent_department",
        );
      });
      this.getStaffFormList(id);
    },
    confirmSync() {
      this.$confirm("同步后将把现有通讯录合并到表单，且不可撤回！", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$refs["form"].validate((valid) => {
            if (valid) {
              let params = {
                companyId: this.$store.state.user.currentCompanyId,
                departmentFormId: this.form.sheet,
                departmentNameFieldName: this.form.departmentName,
                employeeDepartmentFieldName: this.form.department,
                employeeFormId: this.form.unit,
                parentDepartmentFieldName: this.form.superDepartment,
                mobileFieldName: this.form.mobile,
                employeeNameFieldName: this.form.name,
              };
              companyContactSwitchSyncForm(params).then((res) => {
                if (res.data.result) {
                  this.disabled = true;
                  this.$message.success("同步成功！");
                }
              });
            } else {
              return false;
            }
          });
        })
        .catch((err) => {});
    },

    getCompanyFormList() {
      let params = {
        companyId: this.$store.state.user.currentCompanyId,
        listScene: "sync_co_contact_department",
        pageNumber: 1,
        pageSize: 999,
      };
      companyFormList(params).then((res) => {
        this.departmentFormList = res.data.page.list;
        this.loading = false;
      });
    },

    // 获取人员表
    getStaffFormList(departmentFormId) {
      let params = {
        companyId: this.$store.state.user.currentCompanyId,
        listScene: "sync_co_contact_employee",
        departmentFormId,
        pageNumber: 1,
        pageSize: 999,
      };
      companyFormList(params).then((res) => {
        this.staffFormList = res.data.page.list;
      });
    },

    // 获取人员表字段
    handleStaffForm(id) {
      let params = {
        companyId: this.$store.state.user.currentCompanyId,
        listScene: "sync_co_contact_employee",
        formId: id,
        departmentFormId: this.departmentFormId,
      };
      companyContactSyncFormFieldList(params).then((res) => {
        this.nameFiledList = res.data.fieldList.filter(
          (item) => item.fieldTypeName === "employee_name",
        );
        this.mobileFiledList = res.data.fieldList.filter(
          (item) => item.fieldTypeName === "employee_mobile",
        );
        this.belongDepartmentFiledList = res.data.fieldList.filter(
          (item) => item.fieldTypeName === "employee_department",
        );
      });
    },
  },
};
</script>

<style scoped>
.address-sync-setup {
  display: flex;
  justify-content: center;
}

::v-deep .el-input__inner {
  width: 400px;
}

::v-deep .el-divider__text {
  background-color: #f4f5f7;
  color: #42526e;
}
</style>
