<template>
  <div>
    <el-dialog
      title="新建表单"
      append-to-body
      :visible.sync="formVisible"
      width="600px"
      @close="closeForm"
    >
      <div style="padding: 20px">
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item
            label="模版名称"
            prop="title"
            v-if="currentFormTemplate"
          >
            <el-input v-model="currentFormTemplate.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="表单名称" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button @click="formVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="saveLoading"
          @click="saveFormFromTemplate"
          v-if="currentFormTemplate"
          >确定从模版新建</el-button
        >
        <el-button
          type="primary"
          :loading="saveLoading"
          @click="saveForm"
          v-else
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="新建整编"
      append-to-body
      :visible.sync="planVisible"
      width="600px"
      @close="closePlan"
    >
      <div style="padding: 20px">
        <el-form
          :model="planFormModel"
          ref="planFormRef"
          :rules="planFormRules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item
            label="模版名称"
            prop="title"
            v-if="currentPlanTemplate"
          >
            <el-input v-model="currentPlanTemplate.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="整编名称" prop="title">
            <el-input v-model="planFormModel.title"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button @click="planVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="saveLoading"
          @click="savePlanFormTemplate"
          v-if="currentPlanTemplate"
          >确定从模版新建</el-button
        >
        <el-button
          type="primary"
          :loading="saveLoading"
          @click="savePlan"
          v-else
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="新增看板"
      :visible.sync="bulletinVisible"
      width="600px"
      @closed="clearBulletinForm"
      custom-class="bulletinDialog"
      append-to-body
    >
      <el-form
        :model="bulletinForm"
        ref="bulletinForm"
        :rules="bulletinRules"
        label-width="80px"
        :inline="false"
        size="normal"
        label-position="top"
        style="margin: 0 20px"
        class="bulletin-form"
      >
        <el-form-item label="模版名称" v-if="currentBoardTemplate">
          <el-input v-model="currentBoardTemplate.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="看板名称" prop="title">
          <el-input v-model="bulletinForm.title"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="bulletinVisible = false">取消</el-button>
        <el-button
          :loading="saveLoading"
          type="primary"
          @click="saveBulletinFormTemplate"
          v-if="currentBoardTemplate"
          >确定从模版新建</el-button
        >
        <el-button
          :loading="saveLoading"
          type="primary"
          @click="saveBulletin"
          v-else
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { bulletinCardRelationRegister } from "@/api/boardManage";

export default {
  name: "addAssociateModule",
  props: {
    bulletinCardId: [String, Number],
  },
  data() {
    return {
      planVisible: false,
      bulletinVisible: false,
      saveLoading: false,
      bulletinForm: {
        title: "",
        hasColumn: false,
        hasFormPlugin: false,
        hasSchedulePlugin: false,
      },
      bulletinRules: {
        title: [
          {
            required: true,
            message: "请输入看板名称",
          },
        ],
      },
      planFormModel: {
        title: "",
      },
      planFormRules: {
        title: [{ required: true, message: "请输入目录名称" }],
      },
      form: {
        title: "",
      },
      rules: {
        title: [{ required: true, message: "请输入目录名称" }],
      },
      currentBoardTemplate: {},
      currentPlanTemplate: {},
      currentFormTemplate: {},
      formVisible: false,
    };
  },
  methods: {
    closeForm() {},
    saveFormFromTemplate() {
      let params = {
        recordType: "bulletin",
        bulletinCardId: this.bulletinCardId,
        title: this.bulletinForm.title,
        param: JSON.stringify(this.bulletinForm),
      };
      bulletinCardRelationRegister(params).then((resp) => {
        this.bulletinVisible = false;
        this.$emit("update");
      });
    },
    initByPlanTemplate(template) {
      this.planVisible = true;
      this.currentPlanTemplate = template;
      template && (this.planFormModel.title = template.name);
      this.$refs.planFormModel && this.$refs.planFormModel.clearValidate();
    },
    initByFormTemplate(template) {
      this.planVisible = true;
      this.currentFormTemplate = template;
      template && (this.planFormModel.title = template.name);
    },
    initByBoardTemplate(template) {
      this.bulletinVisible = true;
      this.currentBoardTemplate = template;
      template && (this.bulletinForm.title = template.name);
    },
    init() {},
    saveBulletinFormTemplate() {
      let params = {
        recordType: "bulletin",
        bulletinCardId: this.bulletinCardId,
        title: this.bulletinForm.title,
        templateId: this.currentBoardTemplate.extAttr.resId,
      };
      bulletinCardRelationRegister(params).then((resp) => {
        this.bulletinVisible = false;
        this.$emit("update");
      });
    },
    saveBulletin() {
      let params = {
        recordType: "bulletin",
        bulletinCardId: this.bulletinCardId,
        title: this.bulletinForm.title,
        param: JSON.stringify(this.bulletinForm),
      };
      bulletinCardRelationRegister(params).then((resp) => {
        this.bulletinVisible = false;
        this.$emit("update");
      });
    },
    savePlanFormTemplate() {
      let params = {
        recordType: "plan",
        bulletinCardId: this.bulletinCardId,
        templateId: this.currentPlanTemplate.id,
        title: this.planFormModel.title,
      };
      bulletinCardRelationRegister(params).then((resp) => {
        this.planVisible = false;
        this.$emit("update");
      });
    },
    savePlan() {
      let params = {
        recordType: "plan",
        bulletinCardId: this.bulletinCardId,
        title: this.planFormModel.title,
      };
      bulletinCardRelationRegister(params).then((resp) => {
        this.planVisible = false;
        this.$emit("update");
      });
    },
    clearBulletinForm() {
      this.bulletinForm.title = "";
      this.bulletinForm.hasColumn = false;
      this.currentBoardTemplate = {};
      this.$nextTick(() => {
        // this.$refs.bulletinForm && this.$refs.bulletinForm.clearValidate();
        this.$refs.bulletinForm && this.$refs.bulletinForm.resetFields();
      });
    },
    closePlan() {
      this.currentPlanTemplate = {};
      this.planFormModel.title = "";
      this.$nextTick(() => {
        this.$refs.planFormRef && this.$refs.planFormRef.resetFields();
        // this.$refs.planFormRef && this.$refs.planFormRef.clearValidate();
      });
    },
  },
};
</script>

<style scoped>
</style>
