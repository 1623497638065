<template>
  <el-dialog title="视图预览" fullscreen :visible.sync="visible" width="800px">
    <div v-if="form" style="margin: 10px">
      <el-form
        class="cust-form"
        ref="form"
        :label-position="form.extParam.labelPosition"
        :label-width="`${form.extParam.labelWidth}px`"
        :model="searchForm"
      >
        <el-form-item
          v-for="(column, index) in searchFormView.components"
          :key="index"
          :label="form.extParam.showLabel ? column.title : ''"
          :style="`width:${100 / form.extParam.cols}%;`"
        >
          <el-input
            v-if="
              column.field.componentName == 'input' ||
              column.field.componentName == 'input_number' ||
              column.field.componentName == 'text_area' ||
              column.field.componentName == 'sn_input'
            "
            :placeholder="column.field.placeHolder"
            disabled
          >
          </el-input>
          <el-date-picker
            v-else-if="column.field.componentName == 'date_picker'"
            :type="column.field.picker"
            :placeholder="column.field.placeHolder"
            disabled
          ></el-date-picker>
          <el-cascader
            v-else-if="column.field.componentName == 'address_input'"
            :options="[]"
            disabled
            placeholder=""
          >
          </el-cascader>
          <div
            class="outer-selected-user__empty-wrap"
            v-else-if="column.field.componentName == 'department_select'"
            style="height: 32px"
          >
            选择部门
          </div>
          <div
            v-else-if="column.field.componentName == 'department_list_select'"
            class="outer-selected-user__empty-wrap"
          >
            选择部门
          </div>
          <div
            class="outer-selected-user__empty-wrap"
            v-else-if="column.field.componentName == 'user_select'"
            style="height: 32px"
          >
            选择用户
          </div>
          <div
            v-else-if="column.field.componentName == 'user_list_select'"
            class="outer-selected-user__empty-wrap"
          >
            选择用户
          </div>
          <el-select
            v-else-if="
              column.field.componentName == 'radio_group' ||
              column.field.componentName == 'checkbox_group' ||
              column.field.componentName == 'select' ||
              column.field.componentName == 'select_checkbox'
            "
            :value="''"
            :placeholder="column.field.placeHolder"
            disabled
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in getOptions(column.field)"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="search-btn" v-if="searchFormView.components.length">
          <div
            style="height: 20px"
            v-if="
              form.extParam.showLabel &&
              form.extParam.labelPosition == 'top' &&
              form.extParam.cols > 1
            "
          ></div>
          <div class="btn-content">
            <el-button type="primary" size="small">查询</el-button>
          </div>
        </div>
      </el-form>

      <div class="right" v-if="hasCreate">
        <el-button type="primary" size="small">新增</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" :span-method="spanMethod">
        <el-table-column
          v-if="form.showRowNumbers"
          type="index"
          width="50"
          label="序号"
        >
        </el-table-column>
        <el-table-column
          v-for="(col, index) in form.columns"
          :align="col.align"
          :key="index"
          :label="col.title"
          :width="
            col.components && col.components.length && col.width
              ? col.width
              : ''
          "
          :prop="col.components && col.components.length ? '' : col.field.name"
        >
          <template v-if="col.components && col.components.length">
            <el-table-column
              v-for="(subCol, sIndex) in col.components"
              :key="sIndex"
              :align="subCol.align"
              :label="subCol.title"
              :width="subCol.width ? subCol.width : ''"
              :prop="subCol.field.name"
            >
            </el-table-column>
          </template>
        </el-table-column>
        <el-table-column
          type="action"
          width="120"
          v-if="hasEdit || hasDelete"
          label="操作"
        >
          <el-button type="text" v-if="hasEdit">编辑</el-button>
          <el-button type="text" v-if="hasDelete">删除</el-button>
        </el-table-column>
      </el-table>
      <div class="right" v-if="form.hasPageTool">
        <pagination
          :total="page.totalRow"
          :page.sync="page.pageNumber"
          :limit.sync="form.pageSize"
          @pagination="() => {}"
        />
      </div>
    </div>
    <!-- <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </div> -->
  </el-dialog>
</template>
<script>
import { fetchFormDataList } from "../../form/api";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      searchForm: {},
      visible: false,
      form: null,
      searchFormView: {},
      tableData: [],
      actionItemList: [],
      sourceList: {},
      page: {
        pageSize: 10,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
    };
  },
  computed: {
    hasCreate() {
      return (
        this.actionItemList.findIndex((item) => item.clickEvent == "add") >= 0
      );
    },
    hasEdit() {
      return (
        this.actionItemList.findIndex((item) => item.clickEvent == "edit") >= 0
      );
    },
    hasDelete() {
      return (
        this.actionItemList.findIndex((item) => item.clickEvent == "delete") >=
        0
      );
    },
  },
  methods: {
    spanMethod({ row, column, rowIndex, columnIndex }) {
      // if(this.form.columns)
      if (
        this.form.columns.findIndex(
          (col) => col.field.name == column.property,
        ) >= 0 ||
        column.type == "index" ||
        column.type == "action"
      ) {
        if (row.rowSpan) {
          return {
            rowspan: row.rowSpan,
            colspan: 1,
          };
        } else if (row.rowSpan === 0) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    init(form, searchFormView, actionItemList, sourceList) {
      console.log(searchFormView);
      this.form = this._.cloneDeep(form);
      this.searchFormView = searchFormView;
      this.actionItemList = actionItemList;
      this.sourceList = sourceList;
      this.visible = true;
      this.$nextTick(() => {
        // this.loadingList();
      });
    },
    getOptions(item) {
      console.log(item.title);
      if (item.optionSource == "custom") {
        return item.customOptions;
      }

      if (this.sourceList[item.metaFieldOption.tableName]) {
        return this.sourceList[item.metaFieldOption.tableName];
      }
      return [];
    },
    loadingList() {
      fetchFormDataList({
        collection: this.form.formId,
        queryObject: JSON.stringify({
          pageNumber: this.page.pageNumber,
          pageSize: this.form.pageSize,
        }),
      }).then(({ data }) => {
        let arr = this.form.columns.filter(
          (item) => item.components && item.components.length > 0,
        );
        if (!arr.length) {
          this.tableData = data.page.list;
        } else {
          let tableData = [];
          data.page.list.forEach((item) => {
            let lens = [];
            arr.forEach((a) => {
              if (item[a.field.name]) {
                lens.push(item[a.field.name].length);
              }
            });
            if (lens.length > 0) {
              let max = Math.max(...lens);
              for (let index = 0; index < max; index++) {
                let obj = { ...item };
                if (index == 0) {
                  obj.rowSpan = max;
                } else {
                  obj.rowSpan = 0;
                }
                arr.forEach((col) => {
                  let values = item[col.field.name];
                  if (values) {
                    let el = values[index];
                    if (el) {
                      for (const [key, value] of Object.entries(el)) {
                        obj[key] = value;
                      }
                    }
                  }
                });
                tableData.push(obj);
              }
            } else {
              tableData.push(item);
            }

            // tableData.push(item);
          });
          this.tableData = tableData;
        }

        this.page.totalRow = data.page.totalRow;

        this.page.pageNumber = data.page.pageNumber;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cust-form {
  display: flex;
  flex-wrap: wrap;

  &::after {
    display: block;
    content: " ";
    clear: both;
  }

  > &.el-form--label-top {
    .el-form-item__label {
      line-height: 1;
      padding-bottom: 6px;
    }
  }

  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
      padding: 5px;
      // float: left;
      border: transparent dashed 1px;

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100% !important;
      }

      .el-input.is-disabled .el-input__inner,
      .el-textarea.is-disabled .el-textarea__inner {
        background-color: #ffffff;
        cursor: default;
      }
    }
  }
}

.search-btn {
  display: flex;
  margin-bottom: 10px;
  padding: 5px;
  flex-direction: column;

  .btn-content {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 10px;
}

.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  width: unset;
}
</style>
