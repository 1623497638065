<template>
  <div>
    <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
      <el-form-item label="更新参数">
        <!-- 参数项配置 -->
        <div>
          <rpa-subflow-param
            v-for="(item, index) in node.flowParamValues"
            :key="item.param.name"
            :flowParam="item"
            :params="rootFlowParams"
            :nodeKey="node.key"
            :hasBaseRootParam="false"
            @del="del(index)"
          ></rpa-subflow-param>
        </div>
        <div>
          <el-popover
            trigger="click"
            v-model="visibleParams"
            popper-class="subflow-popover"
          >
            <el-button icon="el-icon-plus" size="small" slot="reference"
              >添加参数</el-button
            >
            <div
              v-if="!rootFlowParams.length"
              style="padding: 10px; font-size: 14px"
            >
              没有可用的参数
            </div>
            <div v-else class="params-scroll">
              <div
                v-for="item in rootFlowParams"
                :key="item.name"
                class="param-item"
                :class="{ disabled: isSelectedParam(item.name) }"
                @click="addParamValue(item)"
                v-text="item.title"
              ></div>
            </div>
          </el-popover>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import rpaSubflowParam from "./rpa-subflow-param";
export default {
  name: "rpa-flow-param-update",
  components: { rpaSubflowParam },
  props: {
    node: Object,
    disabled: Boolean,
  },
  inject: {
    getRootParams: {
      default: () => [],
    },
  },
  data() {
    return {
      rootFlowParams: [],
      visibleParams: false,
    };
  },
  created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
  },
  methods: {
    del(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.node.flowParamValues.splice(index, 1);
    },
    isSelectedParam(name) {
      return (
        this.node?.flowParamValues?.findIndex(
          (item) => item.param.name == name,
        ) >= 0 ?? false
      );
    },
    addParamValue(component) {
      let obj = {
        param: this._.cloneDeep(component),
        paramValue: {
          valueType: "custom",
          customValue: "",
          customValueDetail: undefined,
          nodeField: undefined,
          rely: undefined,
          dynamicTime: undefined,
        },
      };

      let flowParamValues = this._.cloneDeep(this.node.flowParamValues);
      if (!flowParamValues) {
        flowParamValues = [];
      }
      flowParamValues.push(obj);
      this.$set(this.node, "flowParamValues", flowParamValues);
      this.visibleParams = false;
    },
    getData(callback) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        if (!isEmpty(this.node.flowParamValues)) {
          let index = this.node.flowParamValues.findIndex((item) => {
            if (item.paramValue.valueType == "custom") {
              return isEmpty(item.paramValue.customValue);
            } else if (item.paramValue.valueType == "nodeField") {
              return isEmpty(item.paramValue.nodeField);
            } else if (item.paramValue.valueType == "rely") {
              return isEmpty(item.paramValue.rely);
            } else if (item.paramValue.valueType == "dynamic") {
              return isEmpty(item.paramValue.dynamicTime);
            } else {
              return false;
            }
          });

          if (index >= 0) {
            this.$message.error("参数值不能为空");
            return;
          }
        } else {
          this.$message.error("请设置要更新的参数");
          return;
        }

        callback(valid);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-label {
  font-size: 12px;
  color: #9e9e9e;
}
.mg {
  margin: 10px 0 10px 24px;
}
.params-scroll {
  max-height: 300px;
  overflow: auto;
  .param-item {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    &.disabled {
      cursor: not-allowed;
      color: #9e9e9e;
    }
  }
}
.robot-item {
  line-height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}
</style>
<style lang="scss">
.subflow-popover {
  padding: 0;
}
</style>
