<template>
  <div class="container" style="position: relative">
    <div class="title-div">
      <span>属性设置</span>
      <i @click="closeSet" class="el-icon-close pointer font-24"></i>
    </div>
    <div class="row">
      <div class="title-label">
        {{ view.componentName == "gantt" ? "图表" : "组件" }}颜色
      </div>
      <div v-if="view.componentName == 'gantt'" class="content-layout">
        <div class="label">配色方案</div>
        <div style="flex: 1">
          <single-color-select
            v-model="colorConfig.borderColor"
            @updateColor="updateBorderColor"
            :background="colorConfig.borderColor"
          />
        </div>
      </div>
    </div>

    <div class="row" v-if="view.componentName !== 'html'">
      <div class="title-label">文字颜色</div>
      <div class="content-layout">
        <div class="label">标题文字</div>
        <div style="flex: 1">
          <single-color-select
            v-if="showS2"
            v-model="colorConfig.titleColor"
            @updateColor="updateTitleColor"
            :background="colorConfig.titleColor"
          />
        </div>
      </div>
      <div v-if="view.componentName == 'gantt'" class="content-layout">
        <div class="label">表头文字</div>
        <div style="flex: 1">
          <single-color-select
            v-model="colorConfig.headColor"
            @updateColor="updateHeadColor"
            :background="colorConfig.headColor"
            :positionLeft="0"
          />
        </div>
      </div>
      <div
        v-if="
          view.componentName == 'button_group' ||
          view.componentName == 'dashboard_filter' ||
          view.componentName == 'gantt'
        "
        class="content-layout"
      >
        <div class="label">
          {{ view.componentName == "button_group" ? "按钮文字" : "内容文字" }}
        </div>
        <div style="flex: 1">
          <single-color-select
            v-if="showS1"
            v-model="colorConfig.contentColor"
            @updateColor="updateContentColor"
            :background="colorConfig.contentColor"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="title-label">卡片设置</div>
      <background-config
        @updateBgColor="updateBgColor"
        :colorConfig="colorConfig"
      ></background-config>
    </div>
  </div>
</template>

<script>
import backgroundConfig from "@/views/lowCode/dashboard/detail-component/background-config";
import singleColorSelect from "@/views/lowCode/dashboard/detail-component/single-color-select";
export default {
  // mixins: [dashboardMixin],
  components: { backgroundConfig, singleColorSelect },
  props: {
    view: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      colorConfig: {},
      showS1: true,
      showS2: true,
    };
  },
  mounted() {
    this.colorConfig = this.view.colorConfig;
  },
  computed: {},
  methods: {
    closeSet() {
      this.$emit("closeSet");
    },
    // updateBgColor(background, backgroundEnum, backgroundImageFillEnum) {
    //   this.$emit(
    //     "updateBgColor",
    //     background,
    //     backgroundEnum,
    //     backgroundImageFillEnum,
    //   );
    // },

    //透视图线框颜色/配色方案
    updateBorderColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.borderColor = background;
      this.$emit("setColorConfig", colorConfig);
    },
    //透视图表头颜色
    updateHeadColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.headColor = background;
      this.$emit("setColorConfig", colorConfig);
    },
    //背景色设置
    updateBgColor(background, backgroundEnum, backgroundImageFillEnum) {
      const colorConfig = this.view.colorConfig;
      if (backgroundEnum == "color") {
        colorConfig.backgroundColor = background;
      } else {
        colorConfig.backgroundImage = background;
      }
      colorConfig.background = background;
      colorConfig.backgroundEnum = backgroundEnum;
      colorConfig.backgroundImageFillEnum = backgroundImageFillEnum;
      this.$emit("setColorConfig", colorConfig);
    },
    //标题颜色
    updateTitleColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.titleColor = background;
      this.$emit("setColorConfig", colorConfig);
    },
    //内容颜色
    updateContentColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.contentColor = background;
      this.$emit("setColorConfig", colorConfig, "content");
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
}
.row {
  padding: 10px;
  .label {
    font-size: 13px;
    min-width: 32%;
  }
}
.title-div {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px -1px 0px 0px #ebecf0 inset;
  font-size: 14px;
}
.pointer {
  cursor: pointer;
}
.font-24 {
  font-size: 18px;
}
.title-label {
  padding: 10px 0 10px 0;
  font-weight: bold;
}
.content-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 20px 0;
  align-items: center;
}
.between {
  justify-content: space-between;
}
</style>
