<template>
  <div class="main">
    <div class="header card">
      <span style="font-size: 25px;font-weight: 700">API Key</span>
      <span class="description">API Key与您的管理员账号具有相同的权限，请务必保密；出于安全考虑，建议您周期性地更换密码。</span>
    </div>
    <div class="container card">
      <el-table
          :data="tableData"
          height="450"
          style="width: 100%">
        <el-table-column
            prop="createdTime"
            label="创建时间"
            min-width="20">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="名称"
            min-width="20">
        </el-table-column>
        <el-table-column
            label="API Key"
            min-width="40">
          <template slot-scope="scope">
            <div style="display: flex;flex-direction: row">
              <el-input
                  :id="scope.row.apiKey"
                  v-model="scope.row.apiKey"
                  readonly
                  type="password">
                <i
                    class="el-icon-view el-input__icon"
                    slot="suffix"
                    @click="handleIconClick(scope.row.apiKey)">
                </i>
              </el-input>
              <el-button @click="handleCopy(scope.row.apiKey)">复制</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="状态"
            min-width="20">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value="20"
                :inactive-value="10"
                active-color="#13ce66"
                @change="changeStatus($event,scope.row)"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            min-width="20">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="handleDelete(scope.row)">
              <i class="el-icon-delete" style="font-size: 25px"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button type="success" class="button" @click="creatKey">创建API KEY</el-button>
  </div>
</template>
<script>
import {changeAuthApiKeyStatus, getAuthApiKeyList, createAuthApiKey, deleteAuthApiKey} from './api.js'

export default {

  data() {
    return {
      tableData:[]
    };
  },
  created() {
      this.getApiKeyList()
  },
  methods: {
    getApiKeyList() {
      getAuthApiKeyList().then((res)=>{
        this.tableData = res.data.page.list
      })
    },
    changeStatus(callback,val) {
      let test = ''
      if(callback == 10){
        test = '禁用'
        val.status = 20
      } else {
        test = '启用'
        val.status = 10
      }
      this.$confirm(`是否${test}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        changeAuthApiKeyStatus({
          id: val.id,
          status: callback
        }).then(()=>{
          val.status = callback
          this.$message({
            type: 'success',
            message: val.status==20?'启用成功':'禁用成功'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: `取消${test}`
        });
      });
    },
    creatKey() {
      this.$prompt('请输入名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        createAuthApiKey({
          remark:value,
        }).then(()=>{
          this.getApiKeyList()
          this.$message({
            type: 'success',
            message: '创建成功'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消创建'
        });
      });
    },
    handleCopy(key) {
      const el = document.createElement('input')
      el.setAttribute('value',key)
      document.body.appendChild(el)
      el.select()
      if (document.execCommand("copy")) {
        this.$message({type: "success", message: "复制到剪贴板"});
        document.body.removeChild(el)
      }
    },
    handleIconClick(key) {
      let el = document.getElementById(key)
      if(el.type == 'password') {
        el.type = 'type'
      } else {
        el.type = 'password'
      }
    },
    handleDelete(item) {
      this.$confirm('此操作将永久删除该key, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteAuthApiKey({
          id:item.id
        }).then(()=>{
          this.getApiKeyList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
</script>
<style scoped lang="scss">
.main {
  margin: 0 100px;
}
.card {
  margin: 20px 0;
  padding: 20px 10px 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: -2px -2px 2px #c6cdd7;
}
.header {
  display: flex;
  flex-direction: row;
  height: 60px;
  overflow: hidden;
  .description {
    padding-top:10px;
    padding-left: 15px;
  }
}
.container {
  height: 500px;
}
.button {

}
</style>
