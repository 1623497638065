<template>
  <div class="attr-row">
    <div class="attr-label">选择器类型</div>
    <el-select
      v-model="component.picker"
      @change="changePicker"
      size="mini"
      style="width: 100%"
    >
      <el-option label="年" value="year"></el-option>
      <el-option label="年月" value="month"></el-option>
      <el-option label="年月日" value="date"></el-option>
      <el-option label="年月日时" value="date_hour"></el-option>
      <el-option label="年月日时分" value="date_minute"></el-option>
      <el-option label="年月日时分秒" value="datetime"></el-option>
    </el-select>
    <div class="attr-label">显示格式</div>
    <el-select
      v-model="component.format"
      size="mini"
      style="width: 100%"
      placeholder=""
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import moment from "moment";
import { componentProps } from "./util";
import { isEmpty } from "@zgg-core-utils/utils";
export default {
  name: "attr-date-picker",
  props: componentProps,
  methods: {
    changePicker() {
      this.component.defaultValue = "";
      this.$set(this.component, "format", this.options[0].value);
    },
  },
  computed: {
    options() {
      if (this.component.picker == "year") {
        return [
          {
            label: moment().format("YYYY"),
            value: "YYYY",
          },
          {
            label: moment().format("YYYY年"),
            value: "YYYY年",
          },
        ];
      } else if (this.component.picker == "month") {
        return [
          {
            label: moment().format("YYYY-MM"),
            value: "YYYY-MM",
          },
          {
            label: moment().format("YYYY年MM月"),
            value: "YYYY年MM月",
          },
          {
            label: moment().format("YYYY.MM"),
            value: "YYYY.MM",
          },
          {
            label: moment().format("YYYY/MM"),
            value: "YYYY/MM",
          },
        ];
      } else if (this.component.picker == "date") {
        return [
          {
            label: moment().format("YYYY-MM-DD"),
            value: "YYYY-MM-DD",
          },
          {
            label: moment().format("YYYY年MM月DD日"),
            value: "YYYY年MM月DD日",
          },
          {
            label: moment().format("YYYY.MM.DD"),
            value: "YYYY.MM.DD",
          },
          {
            label: moment().format("YYYY/MM/DD"),
            value: "YYYY/MM/DD",
          },
        ];
      } else if (this.component.picker == "date_hour") {
        return [
          {
            label: moment().format("YYYY-MM-DD HH"),
            value: "YYYY-MM-DD HH",
          },
          {
            label: moment().format("YYYY年MM月DD日 HH"),
            value: "YYYY年MM月DD日 HH",
          },
          {
            label: moment().format("YYYY.MM.DD HH"),
            value: "YYYY.MM.DD HH",
          },
          {
            label: moment().format("YYYY/MM/DD HH"),
            value: "YYYY/MM/DD HH",
          },
        ];
      } else if (this.component.picker == "date_minute") {
        return [
          {
            label: moment().format("YYYY-MM-DD HH:mm"),
            value: "YYYY-MM-DD HH:mm",
          },
          {
            label: moment().format("YYYY年MM月DD日 HH:mm"),
            value: "YYYY年MM月DD日 HH:mm",
          },
          {
            label: moment().format("YYYY.MM.DD HH:mm"),
            value: "YYYY.MM.DD HH:mm",
          },
          {
            label: moment().format("YYYY/MM/DD HH:mm"),
            value: "YYYY/MM/DD HH:mm",
          },
        ];
      } else if (this.component.picker == "datetime") {
        return [
          {
            label: moment().format("YYYY-MM-DD HH:mm:ss"),
            value: "YYYY-MM-DD HH:mm:ss",
          },
          {
            label: moment().format("YYYY年MM月DD日 HH:mm:ss"),
            value: "YYYY年MM月DD日 HH:mm:ss",
          },
          {
            label: moment().format("YYYY.MM.DD HH:mm:ss"),
            value: "YYYY.MM.DD HH:mm:ss",
          },
          {
            label: moment().format("YYYY/MM/DD HH:mm:ss"),
            value: "YYYY/MM/DD HH:mm:ss",
          },
        ];
      }
    },
  },
  created() {
    if (isEmpty(this.component.format)) {
      this.$set(this.component, "format", this.options[0].value);
    }
  },
};
</script>