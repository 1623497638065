<template>
  <div>
    <template v-if="isEmptyTpl">
      <el-button type="text" @click="createNewTemplate" icon="el-icon-plus"
        >创建新模板</el-button
      >
      <el-select
        :value="smsTemplateId"
        placeholder="选择模板"
        style="width: 100%"
        size="small"
        @visible-change="forSmsTemplateList"
        @change="forSmsTemplateDetail"
      >
        <el-option
          v-for="item in smsTemplateList"
          :key="item.id"
          :label="item.templateName"
          :value="item.id"
        >
          {{ getTemplateInfo(item) }}
        </el-option>
      </el-select>
    </template>
    <template v-else>
      <div class="tmp-name">模板名称：{{ getTemplateInfo(smsTemp) }}</div>
      <div>模版内容：{{ smsTemp.templateContent }}</div>
      <div v-if="!disabled">
        <el-button type="primary" plain @click="reSelect" size="mini"
          >重新选择</el-button
        >
        <el-button type="warning" plain @click="editTpl" size="mini"
          >修改模板</el-button
        >
        <el-button type="danger" plain @click="deleteTemplate" size="mini"
          >删除模板</el-button
        >
      </div>
    </template>
    <el-dialog
      :title="smsTemplate.id ? '编辑模板' : '新建模板'"
      :visible.sync="visible"
      width="800px"
      append-to-body
      destroy-on-close
      @opened="opened"
      @closed="closed"
    >
      <div style="padding: 10px 20px" v-if="visible">
        <el-form ref="tempForm" :model="smsTemplate" label-position="top">
          <el-form-item
            label="模板标题"
            :rules="[{ required: true, message: '请输入模板标题' }]"
            prop="templateName"
          >
            <el-input v-model="smsTemplate.templateName"></el-input>
          </el-form-item>
          <div class="sms-template" style="margin-bottom: 22px">
            <el-form-item
              label="短信内容"
              style="margin-bottom: 15px"
              :rules="[{ required: true, message: '请输入通知内容' }]"
              prop="templateContent"
            >
              <p
                style="margin: 5px 0; color: rgb(155 164 180); line-height: 1.5"
              >
                为保证短信的稳定推送，以下内容须审核后才能使用，尽量不要使用特殊字段，严禁发送违规信息。<br />多于70字（含签名）的短信按67字每条计费。
              </p>
              <div>
                <rpa-codemirror
                  ref="rpaCode"
                  :fieldList="templateFieldList"
                  regType="$"
                  @change="changeCode"
                  :changeCode="changeCode"
                ></rpa-codemirror>
              </div>
            </el-form-item>
            <div>
              <el-button
                type="text"
                icon="el-icon-plus"
                size="small"
                @click="addSMSField"
                >添加字段
              </el-button>
            </div>
            <p
              style="
                background-color: #f5f7fa;
                padding: 6px 10px;
                line-height: 1.5;
              "
            >
              · 短信必须审核通过后才能启用智能助手<br />
              · 审核时间约为2小时，非工作时间提交审核会延长
            </p>
          </div>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="saveTemplate">{{
          buttonStatus
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import {
  deleteSmsTemplate,
  getSmsTemplateDetail,
  getSmsTemplateList,
  saveTemplate,
} from "../assistant/api";
import rpaCodemirror from "./rpa-codemirror";
import { _eq } from "@zgg-core-utils/relyUtil";
import { isIDEmpty } from "@/zgg-core/utils";
export default {
  components: { rpaCodemirror },
  props: {
    value: String, // 短信模板ID
    /** 短信平台 */
    smsPlatForm: {
      type: String,
      default() {
        return "aliyun"; // 目前默认阿里云
      },
    },
    msgNodeFields: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: Boolean,
  },
  computed: {
    smsTemplateId: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    /** 判断是否空模版 */
    isEmptyTpl() {
      return isEmpty(this.smsTemp);
    },
    buttonStatus() {
      if (
        this.smsTemp.templateName == this.smsTemplate.templateName &&
        this.smsTemp.templateContent == this.smsTemplate.templateContent
      ) {
        return "保存";
      } else {
        return "提交审核";
      }
    },
  },
  watch: {
    "smsTemplate.templateContent": {
      deep: true,
      handler(val, old) {
        if (this.visible && this.$refs.tempForm && !_eq(val, old)) {
          this.$refs.tempForm.validateField("templateContent");
        }
      },
    },
  },
  data() {
    return {
      dialogTitle: "",
      visible: false,
      /** 短信模板列表 */
      smsTemplateList: [],
      smsTemplate: {},
      smsTemp: {}, // 原始短信模板
      /** 短信模板字段 */
      templateFieldList: [],
    };
  },
  created() {
    if (!isIDEmpty(this.smsTemplateId)) {
      getSmsTemplateDetail({
        id: this.smsTemplateId,
      }).then((res) => {
        let smsTemplate = res.data.template;
        this.smsTemp = smsTemplate;
        this.changeCode(smsTemplate.templateContent);
        this.changeMsgNodeFields();
        this.templateFieldList.forEach((item) => {
          let field = this.msgNodeFields.find(
            (row) => row.smsTemplateFieldName == item.name,
          );
          if (field) {
            item.nodeKey = field.nodeKey;
            item.fieldName = field.name;
            item.fieldTitle = field.title;
            item.componentName = field.componentName;
          }
        });
      });
    }
  },
  methods: {
    closed() {
      this.smsTemplate = {};
      this.templateFieldList = [];
    },
    deleteTemplate() {
      this.$confirm(
        "该操作可能会导致所有使用此模板的智能助手失效，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        },
      )
        .then(() => {
          deleteSmsTemplate({
            ids: [this.smsTemp.id],
          }).then(() => {
            this.initSms();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    initSms() {
      this.smsTemp = {};
      this.smsTemplate = {};
      this.smsTemplateId = "";
      this.$emit("update:msgNodeFields", []);
    },
    reSelect() {
      this.initSms();
    },
    editTpl() {
      this.smsTemplate = this._.cloneDeep(this.smsTemp);
      this.changeCode(this.smsTemp.templateContent);
      this.visible = true;
    },
    saveTemplate() {
      this.$refs.tempForm.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.buttonStatus == "保存") {
          this.changeMsgNodeFields();
          this.smsTemp = this._.cloneDeep(this.smsTemplate);
          this.visible = false;
        } else {
          if (this.smsTemplate.id) {
            this.$confirm(
              "短信模板重新审核时会导致使用该模板的智能助手不可用，确定要提交修改吗？",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              },
            )
              .then(() => {
                this.commitTemplate();
              })
              .catch(() => {});
          } else {
            this.commitTemplate();
          }
        }
      });
    },
    commitTemplate() {
      let param = {
        smsType: "10",
        templateName: this.smsTemplate.templateName,
        templateContent: this.smsTemplate.templateContent,
      };
      if (this.smsTemplate.id) {
        param.id = this.smsTemplate.id;
      }
      saveTemplate(param).then((res) => {
        this.smsTemp = res.data.template;
        this.smsTemplate = this._.cloneDeep(res.data.template);
        this.smsTemplateId = res.data.template.id;
        this.changeMsgNodeFields();
      });
      this.visible = false;
    },
    changeMsgNodeFields() {
      let list = [];
      this.templateFieldList.forEach((item) => {
        let obj = this.msgNodeFields.find(
          (row) => row.smsTemplateFieldName == item.name,
        );
        if (obj) {
          list.push(obj);
        } else {
          list.push({
            nodeKey: item.nodeKey,
            name: item.fieldName,
            title: item.fieldTitle,
            componentName: item.componentName,
            smsTemplateFieldName: item.name,
          });
        }
      });
      this.$emit("change", list);
    },
    opened() {
      if (this.$refs.rpaCode && !isEmpty(this.smsTemplate.templateContent)) {
        this.$refs.rpaCode.init(this.smsTemplate.templateContent);
      }
    },
    changeCode(code) {
      const rex = /\${[a-zA-Z_0-9]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        result.forEach((item) => {
          let oldName = item.replaceAll("${", "").replaceAll("}", "");
          let nodeKey = "";
          let fieldName = "";
          let componentName = "";
          let fieldTitle = "";
          let field = this.templateFieldList.find((row) => row.name == oldName);
          if (field) {
            nodeKey = field.nodeKey;
            fieldName = field.fieldName;
            componentName = field.componentName;
            fieldTitle = field.fieldTitle;
          }

          let name = `field_${arr.length + 1}`;
          code = code.replace(item, "${" + name + "}");
          arr.push({
            nodeKey,
            fieldName,
            componentName,
            fieldTitle,
            name: name,
            title: name,
            key: "",
          });
        });
      }

      this.$set(this.smsTemplate, "templateContent", code);
      this.$set(this, "templateFieldList", arr);
      return code;
    },
    addSMSField() {
      let item = {
        nodeKey: "",
        fieldName: "",
        componentName: "",
        fieldTitle: "",
        name: `field_${this.templateFieldList.length + 1}`,
        title: `field_${this.templateFieldList.length + 1}`,
      };
      this.templateFieldList.push(item);
      this.$refs.rpaCode.insert(item);
      this.$refs.rpaCode.onBlur();
    },
    getTemplateInfo(item) {
      if (item.templateStatus == "0") {
        return item.templateName + "--(审核中)";
      } else if (item.templateStatus == "1") {
        return item.templateName + "--(审核通过)";
      } else if (item.templateStatus == "2") {
        return item.templateName + "--(审核未通过)";
      }
    },
    forSmsTemplateList(e) {
      if (e) {
        getSmsTemplateList({
          templateStatus: 100, //返回所有数据
        }).then((res) => {
          this.smsTemplateList = res.data.page.list;
        });
      }
    },
    forSmsTemplateDetail(val) {
      getSmsTemplateDetail({
        id: val,
      }).then((res) => {
        let smsTemplate = res.data.template;
        if (smsTemplate.templateStatus == "1") {
          this.$emit("change", []);
          this.smsTemplateId = val;
          this.smsTemp = smsTemplate;
          this.changeCode(smsTemplate.templateContent);
          this.changeMsgNodeFields();
        } else {
          this.$confirm("此模板未审核通过, 是否重新编辑?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.smsTemplateId = val;
              this.smsTemplate = smsTemplate;
              this.smsTemp = this._.cloneDeep(smsTemplate);
              this.changeCode(smsTemplate.templateContent);
              this.visible = true;
            })
            .catch(() => {});
        }
      });
    },
    createNewTemplate() {
      this.visible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.tmp-name {
  background-color: #f5f7fa;
  // padding: 0 10px;
  // line-height: 1.5;
  font-size: 14px;
  // margin-bottom: 10px;
}
.sms-template {
  border: 1px solid #dcdfe6;
  padding: 10px;
}
</style>
