<!-- 按钮组件 -->
<template>
  <div>
    <div class="attr-row">
      <div class="row-between">
        <div class="attr-label">按钮文案：</div>
      </div>
      <el-input
        v-model="component.buttonText"
        placeholder=""
        size="mini"
      ></el-input>
    </div>
    <div class="attr-row">
      <div class="row-between">
        <div class="attr-label">按钮颜色：</div>
      </div>
      <div>
        <attr-color
          v-model="component.buttonStyle.color"
          @changeColor="changeColor"
        >
        </attr-color>
      </div>
    </div>
    <div class="attr-row">
      <div class="attr-label">触发动作：</div>
      <el-select
        v-model="component.buttonEvent"
        size="mini"
        placeholder=""
        style="width: 100%"
        @change="changeButtonEvent"
      >
        <el-option label="新增数据" value="formEvent"></el-option>
        <el-option
          label="执行智能助手"
          value="robotEvent"
          v-if="hasRoleAuth('ops_auth_menu_robot_v2')"
        ></el-option>
        <el-option
          label="触发前端事件"
          value="frontEvent"
          v-if="hasRoleAuth('ops_auth_form_front_event')"
        ></el-option>
        <el-option label="打开链接" value="hrefEvent"></el-option>
      </el-select>
    </div>

    <!-- 新增数据 -->
    <template v-if="component.buttonEvent === 'formEvent'">
      <!-- 选择表单 -->
      <attr-include-form
        :component="component"
        :params="params"
        :componentList="componentList"
        @changeSource="changeSource"
      ></attr-include-form>

      <!-- 设置默认值 -->
      <div style="padding: 0 10px">
        <reference-data-init
          :initFieldValues.sync="component.eventConfig.initFieldValues"
          :currentComponentList="formComponents"
          :componentList="componentList"
          :name="component.name"
        ></reference-data-init>
      </div>
      <!-- 关联表权限 -->
      <attrPermissionType
        :component="component"
        :params="params"
      ></attrPermissionType>
    </template>

    <!-- 智能助手 -->
    <template v-if="component.buttonEvent === 'robotEvent'">
      <div
        class="attr-row robot-event"
        v-if="component.eventConfig.robotId && hasCreatedRobot"
      >
        <span class="button-text">{{ component.buttonText }}</span>
        <span
          style="flex: 1; text-align: center"
          :class="robotDetail.robotWorkflowId > 0 ? 'enable' : 'disable'"
          >{{ robotDetail.robotWorkflowId > 0 ? "开启" : "未开启" }}</span
        >
        <span style="width: 80px; text-align: right">
          <i class="el-icon-edit" @click="editRobot"></i>
          <i class="el-icon-delete" @click="delRobot"></i>
        </span>
      </div>
      <div
        class="attr-row row-between"
        style="cursor: pointer"
        v-else
        @click="createRobot"
      >
        <span>新建智能助手</span>
        <i class="el-icon-plus"></i>
      </div>
    </template>

    <!-- 添加前端事件 -->
    <template v-if="component.buttonEvent === 'frontEvent'">
      <div
        class="attr-row"
        v-if="component.eventConfig?.frontEvents?.length"
        @click="setEvent"
      >
        <el-button style="width: 100%" size="mini"
          >已设置{{
            component.eventConfig?.frontEvents?.length
          }}个前端事件</el-button
        >
      </div>
      <div
        class="attr-row row-between"
        style="cursor: pointer"
        v-else
        @click="setEvent"
      >
        <span>添加前端事件</span>
        <i class="el-icon-plus"></i>
      </div>
      <frontEvent
        :drawer.sync="drawer"
        :component="component"
        :entrySource="'button'"
        :formEvents.sync="formEvents"
        :areaTree="areaTree"
        :formComponent="formComponent"
        @updateFrontEvents="updateFrontEvents"
      ></frontEvent>
    </template>

    <!-- 打开链接 -->
    <template v-if="component.buttonEvent === 'hrefEvent'">
      <div class="attr-row">
        <div class="attr-label">链接地址：</div>
        <el-input
          v-model="component.eventConfig.url"
          type="textarea"
          :rows="4"
          placeholder=""
          @change="changeUrl"
        ></el-input>
      </div>
      <div class="attr-row">
        <div class="attr-label">打开方式：</div>
        <el-radio-group v-model="component.eventConfig.targetEnum">
          <el-radio :label="'blank'">新页面打开</el-radio>
          <el-radio :label="'self'">弹窗打开</el-radio>
        </el-radio-group>
      </div>
      <div class="attr-row" v-if="component.eventConfig.targetEnum === 'self'">
        <div class="attr-label">弹窗大小：</div>
        <el-radio-group v-model="component.eventConfig.openTypeEnum">
          <el-radio :label="'full_screen'">全屏</el-radio>
          <el-radio :label="'custom'">自定义大小</el-radio>
        </el-radio-group>
        <div
          v-if="component.eventConfig.openTypeEnum === 'custom'"
          class="weui"
          style="margin-top: 10px"
        >
          <span style="padding: 0 5px; font-size: 12px">宽</span>
          <el-input-number
            v-model="component.eventConfig.winWidth"
            placeholder="宽"
            controls-position="right"
            size="mini"
            :max="1920"
            :min="600"
            style="width: 100px"
          ></el-input-number>
          <span style="padding: 0 5px; font-size: 12px">高</span>
          <el-input-number
            v-model="component.eventConfig.winHeight"
            placeholder="高"
            controls-position="right"
            size="mini"
            :max="800"
            :min="400"
            style="width: 100px"
          ></el-input-number>
        </div>
      </div>
    </template>

    <!-- 二次确认弹窗 -->
    <div class="attr-row">
      <div class="attr-label">点击时</div>
      <el-checkbox v-model="component.confirm">
        <span style="font-size: 12px">需要二次确认</span>
      </el-checkbox>
    </div>
    <template v-if="component.confirm">
      <div class="attr-row">
        <div class="attr-label">提示标题：</div>
        <el-input
          v-model="component.confirmConfig.title"
          placeholder=""
          size="mini"
        ></el-input>
      </div>
      <div class="attr-row">
        <div class="attr-label">提示内容：</div>
        <el-input
          v-model="component.confirmConfig.description"
          placeholder=""
          size="mini"
        ></el-input>
      </div>
      <div class="attr-row">
        <div class="attr-label">确认按钮文案：</div>
        <el-input
          v-model="component.confirmConfig.okText"
          placeholder=""
          size="mini"
        ></el-input>
      </div>
      <div class="attr-row">
        <div class="attr-label">取消按钮文案：</div>
        <el-input
          v-model="component.confirmConfig.cancelText"
          placeholder=""
          size="mini"
        ></el-input>
      </div>
    </template>
  </div>
</template>

<script>
import { componentProps } from "./util";
import { getFormComponents } from "../utils";
import attrIncludeForm from "@/views/lowCode/form/attribute/attr-include-form";
import ReferenceDataInit from "@/views/lowCode/form/component/ReferenceDataInit";
import attrColor from "@/views/lowCode/form/attribute/attr-color";
import attrPermissionType from "@/views/lowCode/form/attribute/attr-permission-type";
import { getFormDetail } from "../api";
import { fetchMetaFieldComponentList } from "@/api/form";
import frontEvent from "@/views/lowCode/form/component/front-event.vue";
import {
  getRobotDetail,
  findClickTrigger,
  createClickTrigger,
  delRobot,
} from "@/views/lowCode/rpa/api";
import { hasRoleAuth } from "@/utils/roleAuth";

export default {
  name: "attr-button",
  inject: ["getComponents"],
  props: componentProps,
  components: {
    attrIncludeForm,
    ReferenceDataInit,
    attrColor,
    attrPermissionType,
    frontEvent,
  },
  computed: {
    formComponent() {
      let form = this._.cloneDeep(this.form) || {};
      form.components = getFormComponents(this.form.components);
      return form;
    },
    jsonFormName() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      let parentField = components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key,
            ) >= 0,
        );

      if (parentField) {
        return parentField.name;
      }
      return;
    },
    formComponents() {
      return this.getComponents();
    },
  },
  data() {
    return {
      actionVal: "add",
      predefineColors: [
        "#3870ea",
        "#409eff",
        "#654bff",
        "#20b77e",
        "#fec300",
        "#FD7B03",
        "#FE6481",
        "#F14135",
        "#BCBABA",
      ],
      componentList: [],
      drawer: false,
      formEvents: [], // 前端事件集合
      hasCreatedRobot: false, // 是否有创建过智能助手 默认否
      robotId: "", // 智能助手id
      robotDetail: {}, // 智能助手详情
    };
  },
  async created() {
    // 按钮组件增强:新加属性、针对旧数据做下兼容处理
    if (typeof this.component.confirm === "undefined") {
      this.$set(this.component, "confirm", false);
    }
    if (typeof this.component.confirmConfig === "undefined") {
      this.$set(this.component, "confirmConfig", {
        cancelText: "取消",
        okText: "确认",
        title: "",
        description: "",
      });
    }

    // 按钮组件前端事件设置
    if (
      this.component.buttonEvent == "frontEvent" &&
      this.component.eventConfig.frontEvents
    ) {
      this.formEvents = this._.cloneDeep(
        this.component.eventConfig.frontEvents,
      );
    }
    // 新增数据时获取关联表单数据
    if (this.component.eventConfig.formId) {
      let tableName = this.component.eventConfig.formId;
      this.getDetail(tableName);
    }
  },
  async mounted() {
    // 查询是否有创建智能助手
    await this.findClickTriggerRobot();
    if (this.robotId) {
      await this.getRobotDetail(this.robotId);
    }
  },
  methods: {
    hasRoleAuth,
    changeColor(e) {
      if (!e) {
        this.component.buttonStyle.color = "#3870ea";
      }
    },
    changeSource(item) {
      let tableName = item.id;
      this.getDetail(tableName);
    },
    getDetail(tableName) {
      Promise.all([
        getFormDetail(tableName, true),
        fetchMetaFieldComponentList(tableName, [
          "flowStatus",
          "currentFlowNodeTitles",
          "currentFlowWorkUserIds",
        ]),
      ]).then((resp) => {
        this.tableTitle = resp[0].data.form.title;
        this.$set(this, "componentList", resp[1].data.list);
      });
    },
    // 切换触发动作类型
    changeButtonEvent(e) {
      switch (e) {
        case "formEvent": // 新增数据
          this.frontEvents = [];
          var eventConfig = {
            permissionType: "ALL",
          };
          eventConfig.formId = "";
          eventConfig.actionEvent = "add";
          eventConfig.initFieldValues = [];
          this.$set(this.component, "eventConfig", eventConfig);
          break;
        case "robotEvent": // 智能助手
          this.frontEvents = [];
          var eventConfig = {};
          if (!this.hasCreatedRobot) {
            eventConfig.robotId = "";
          } else {
            eventConfig.robotId = this.robotId;
          }
          this.$set(this.component, "eventConfig", eventConfig);
          break;
        case "frontEvent": // 前端事件
          var eventConfig = {
            frontEvents: [],
          };
          this.$set(this.component, "eventConfig", eventConfig);
          break;
        case "hrefEvent": // 打开链接
          this.frontEvents = [];
          var eventConfig = {
            url: "",
            targetEnum: "self", // self:当前窗口打开, blank:新窗口打开
            title: "", // 链接名称
            openTypeEnum: "full_screen", // full-screen:全屏, custom:新窗口打开
            winWidth: 800, // 自定义宽
            winHeight: 600, // 自定义高
          };
          this.$set(this.component, "eventConfig", eventConfig);
          break;
      }
    },
    // 监听链接地址输入
    changeUrl(e) {
      let rex =
        /^(http|https|ftp):\/\/[\w\-]+(\.[\w\-]+)*([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
      if (!rex.test(e)) {
        this.$message.warning("链接地址格式不正确");
        this.$set(this.component.eventConfig, "url", "");
      }
    },
    // 设置前端事件
    setEvent() {
      this.drawer = true;
    },
    // 更新前端事件
    updateFrontEvents(frontEvents) {
      let eventConfig = frontEvents;
      this.$set(this.component.eventConfig, "frontEvents", eventConfig);
    },
    // 创建智能助手
    createRobot() {
      createClickTrigger({
        collection: this.$route.query.id,
        collectionTitle: this.params.formTitle,
        title: this.component.buttonText,
        triggerKey: this.component.key,
        triggerTitle: this.component.title,
      }).then((res) => {
        var eventConfig = {
          robotId: res.data.robot.id,
        };
        this.robotId = res.data.robot.id;
        this.hasCreatedRobot = true;
        this.$set(this.component, "eventConfig", eventConfig);
        let url = this.$router.resolve({
          path: "/lowCode/rpa/edit?id=" + eventConfig.robotId,
        });
        window.open(url.href, "_blank");
      });
    },
    // 查询(是否以该按钮组件key创建过智能助手)
    async findClickTriggerRobot() {
      let params = {
        collection: this.$route.query.id,
        triggerKey: this.component.key,
      };
      await findClickTrigger(params).then((res) => {
        if (res.data.robot) {
          this.hasCreatedRobot = true;
          this.robotId = res.data.robot.id;
        } else {
          if (this.component?.eventConfig?.robotId) {
            var eventConfig = {
              robotId: "",
            };
            this.$set(this.component, "eventConfig", eventConfig);
          }
        }
      });
    },
    // 查询智能助手详情信息
    async getRobotDetail(robotId) {
      await getRobotDetail({
        robotId,
      }).then((res) => {
        if (res.data.robot) {
          this.robotDetail = res.data.robot;
        }
      });
    },
    // 编辑智能助手
    editRobot() {
      let url = this.$router.resolve({
        path: "/lowCode/rpa/edit?id=" + this.robotId,
      });
      window.open(url.href, "_blank");
    },
    // 删除智能助手
    delRobot() {
      this.$confirm("确定要删除吗?", "提示")
        .then(() => {
          delRobot(this.robotId).then((res) => {
            this.$message.success("删除成功");
            let eventConfig = {
              robotId: "",
            };
            this.$set(this.component, "eventConfig", eventConfig);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.robot-event {
  border: #dcdfe6 solid 1px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  i {
    cursor: pointer;
  }
}
.row-between {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-text {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.enable {
  font-size: 12px;
  color: #67c23a;
}

.disable {
  font-size: 12px;
  color: #f56c6c;
}
</style>
