<template>

  <div style="display: flex;flex-direction: row;">
    <el-select
        size="small"
        class="ml"
        v-model="timeNumber"
        :placeholder="`${placeholderPrefix}时间`"
        @change="changeTimeNumber"
        style="flex: 1"
    >
      <el-option
          v-for="(digit,index) in timeNumberArray"
          :key="digit"
          :label="digit"
          :value="timeOffsetType == 'current'?index:digit"
      ></el-option>
    </el-select>
    <el-select
        size="small"
        class="ml"
        v-model="timeUnit"
        :placeholder="`${placeholderPrefix}时间单位`"
        @change="changeTimeUnit"
        style="margin-left: 20px;flex: 1"
    >
      <el-option label="年" value="year"></el-option>
      <el-option label="月" value="month"></el-option>
      <el-option label="天" value="day"></el-option>
      <el-option label="小时" value="hour"></el-option>
      <el-option label="分钟" value="minute"></el-option>
    </el-select>
  </div>
</template>

<script>
import {isEmpty} from "lodash";

export default {
  name: "time-input",
  props: {
    value: Object,
    timeOffsetType: String,
    placeholderPrefix:{
      type:String,
      default(){
        return ""
      }
    }

  },
  data() {
    return {}
  },
  computed: {
    /**
     * 动态生成数量
     * @returns {number}
     */
    timeNumberArray() {
      if (this.timeOffsetType == 'current') {
        return 1
      } else {
        return 99
      }
    },

    timeNumber: {
      get() {
        console.log(this.value)
        if (isEmpty(this.value)) {
          return "";
        }
        let value;
        let values = Object.values(this.value);
        if (values.length) {
          value = "";
          for (const [key, val] of Object.entries(this.value)) {
            value = Math.abs(val);
          }
          return value;
        }
        return ""
      },
      set(val) {
        console.log("timeNumber val", val)
        let obj = {}
        let newVal;
        if (this.timeOffsetType == 'current') {
          newVal = 0
        }
        if (this.timeOffsetType == 'before') {
          newVal = -val
        }
        if (this.timeOffsetType == 'after') {
          newVal = val
        }
        obj[this.timeUnit] = newVal
        this.$emit("input", obj);

      }
    },
    timeUnit: {
      get() {
        if (isEmpty(this.value)) {
          return "";
        }
        let unit = "day";
        for (const [key, value] of Object.entries(this.value)) {
          if (typeof value !== 'undefined') {
            unit = key;
          }
        }
        return unit;
      },
      set(val) {
        let obj = {}
        let newVal;
        if (this.timeOffsetType == 'current') {
          newVal = 0
        }
        if (this.timeOffsetType == 'before') {
          newVal = -this.timeNumber
        }
        if (this.timeOffsetType == 'after') {
          newVal = this.timeNumber
        }
        obj[val] = newVal
        this.$emit("input", obj);
      },
    },
  },
  methods: {
    timeOffsetObject() {
      let obj = {}
      let val = "";
      if (this.timeOffsetType == 'current') {
        val = 0
      }
      if (this.timeOffsetType == 'before') {
        val = -this.timeNumber
      }
      if (this.timeOffsetType == 'after') {
        val = this.timeNumber
      }
      obj[this.timeUnit] = val
      console.log(obj)
      return obj
    },
    changeTimeUnit(val) {
    },
    changeTimeNumber(val) {
      console.log(val)
    },
  },
}
</script>

<style scoped>

</style>
