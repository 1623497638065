<template>
  <div>
    <el-form
      label-position="top"
      size="small"
      ref="form"
      :model="value"
      :show-message="false"
    >
      <el-form-item
        label="触发开始时间"
        style="width: 100%"
        required
        prop="executeTime"
      >
        <el-date-picker
          v-model="value.executeTime"
          type="datetime"
          size="small"
          :default-value="new Date()"
          style="width: 100%"
          placeholder="请选择"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item
        v-if="executeEndTime"
        label="结束执行时间"
        style="width: 100%"
      >
        <el-date-picker
          v-model="value.executeEndTime"
          type="datetime"
          size="small"
          style="width: 100%"
          :default-value="new Date()"
          placeholder="请选择结束执行时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="循环类型"
        style="width: 100%"
        required
        prop="loopType"
      >
        <el-select
          style="width: 100%"
          v-model="value.loopType"
          placeholder="请选择循环类型"
          @change="loopTypeChange"
        >
          <el-option
            v-for="item in timerCronLoopTypeEnums"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="isCustom">
        <el-form-item
          label="小时"
          style="width: 100%"
          v-if="value['cronConfig']['hour']"
          required
          prop="cronConfig.hour.cronValueType"
        >
          <el-select
            style="width: 100%"
            v-model="value['cronConfig']['hour'].cronValueType"
            placeholder="请选择循环类型"
            @change="hourTriggerChange"
          >
            <el-option
              v-for="item in hourTriggerTypeEnums"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <template v-if="value['cronConfig']['hour'].cronValueType == 'range'">
            <div
              style="
                display: flex;
                flex-direction: row;
                margin-top: 8px;
                gap: 8px;
              "
            >
              <el-form-item
                required
                prop="cronConfig.hour.value[0]"
                style="flex: 1"
              >
                <el-input
                  placeholder="请输入"
                  v-model="hourRangeStart"
                  @change="hourRangeStartChange"
                >
                </el-input>
              </el-form-item>

              至
              <el-form-item
                required
                prop="cronConfig.hour.value[1]"
                style="flex: 1"
              >
                <el-input
                  placeholder="请输入"
                  @change="hourRangeEndChange"
                  v-model="hourRangeEnd"
                >
                </el-input>
              </el-form-item>
            </div>
          </template>
          <template
            v-if="value['cronConfig']['hour'].cronValueType == 'appoint'"
          >
            <el-form-item required prop="cronConfig.hour.value" style="flex: 1">
              <el-select
                v-model="value['cronConfig']['hour'].value"
                placeholder="请选择"
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in 24"
                  :key="index"
                  :label="`${index}时`"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template
            v-if="value['cronConfig']['hour'].cronValueType == 'increment'"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                margin-top: 8px;
                gap: 8px;
              "
            >
              从
              <el-form-item
                required
                prop="cronConfig.hour.value[0]"
                style="flex: 1"
              >
                <el-input v-model="hourIncrementStart"> </el-input>
              </el-form-item>
              <span style="width: 230px">时开始，每隔</span>
              <el-form-item
                required
                prop="cronConfig.hour.value[1]"
                style="flex: 1"
              >
                <el-input v-model="hourIncrementEnd"> </el-input>
              </el-form-item>
              <span style="width: 90px">小时</span>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="天/星期" style="width: 100%">
          <div>
            <el-radio v-model="isDay" :label="true" @change="dayChange"
              >天</el-radio
            >
            <el-radio v-model="isWeek" :label="true" @change="weekChange"
              >星期</el-radio
            >
          </div>
          <template v-if="isDay">
            <el-form-item
              required
              prop="cronConfig.date.cronValueType"
              style="flex: 1"
            >
              <el-select
                v-model="value['cronConfig']['date'].cronValueType"
                placeholder="请选择循环类型"
                @change="dateTriggerChange"
              >
                <el-option
                  v-for="item in dayTriggerTypeEnums"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <template
              v-if="value['cronConfig']['date'].cronValueType == 'appoint'"
            >
              <el-form-item
                required
                prop="cronConfig.date.value"
                style="flex: 1"
              >
                <el-select
                  v-model="value['cronConfig']['date'].value"
                  placeholder="请选择"
                  multiple
                >
                  <el-option
                    v-for="(item, index) in 31"
                    :key="item"
                    :label="`${item}日`"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template
              v-if="value['cronConfig']['date'].cronValueType == 'range'"
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  margin-top: 8px;
                  gap: 8px;
                "
              >
                <el-form-item
                  required
                  prop="cronConfig.date.value[0]"
                  style="flex: 1"
                >
                  <el-input
                    placeholder="请输入"
                    @change="dateRangeStartChange"
                    v-model="dateRangeStart"
                  >
                  </el-input>
                </el-form-item>
                至
                <el-form-item
                  required
                  prop="cronConfig.date.value[1]"
                  style="flex: 1"
                >
                  <el-input
                    placeholder="请输入"
                    @change="dateRangeEndChange"
                    v-model="dateRangeEnd"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </template>
            <template
              v-if="value['cronConfig']['date'].cronValueType == 'increment'"
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  margin-top: 8px;
                  gap: 8px;
                "
              >
                从
                <el-form-item
                  required
                  prop="cronConfig.date.value[0]"
                  style="flex: 1"
                >
                  <el-input v-model="dateIncrementStart"> </el-input>
                </el-form-item>
                <span style="width: 230px">日开始，每隔</span>
                <el-form-item
                  required
                  prop="cronConfig.date.value[1]"
                  style="flex: 1"
                >
                  <el-input v-model="dateIncrementEnd"> </el-input>
                </el-form-item>
                日
              </div>
            </template>
          </template>

          <template v-if="isWeek">
            <el-form-item
              required
              prop="cronConfig.week.value"
              style="flex: 1"
              inline-message
            >
              <el-checkbox-group
                v-model="value['cronConfig']['week'].value"
                size="small"
              >
                <el-checkbox-button :label="1"></el-checkbox-button>
                <el-checkbox-button :label="2"></el-checkbox-button>
                <el-checkbox-button :label="3"></el-checkbox-button>
                <el-checkbox-button :label="4"></el-checkbox-button>
                <el-checkbox-button :label="5"></el-checkbox-button>
                <el-checkbox-button :label="6"></el-checkbox-button>
                <el-checkbox-button :label="7"></el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </template>
        </el-form-item>
        <el-form-item label="月" style="width: 100%">
          <el-select
            v-model="value['cronConfig']['month'].cronValueType"
            placeholder="请选择循环类型"
            @change="monthTriggerChange"
            style="width: 100%"
          >
            <el-option
              v-for="item in monthTriggerTypeEnums"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <template
            v-if="value['cronConfig']['month'].cronValueType == 'appoint'"
          >
            <el-form-item
              required
              prop="cronConfig.month.value"
              style="flex: 1"
            >
              <el-select
                v-model="value['cronConfig']['month'].value"
                placeholder="请选择"
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in 12"
                  :key="item"
                  :label="`${item}月`"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import timerTriggerMixin from "@/views/lowCode/assistant/timer-trigger-mixin";
import moment from "moment";

export default {
  mixins: [timerTriggerMixin],
  name: "timer-cron-trigger-setting",
  props: {
    value: Object,
    executeEndTime: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  created() {
    console.log('==========')
    console.log(this.value);
  },
  data() {
    return {
      monthTriggerTypeEnums: [
        {
          label: "每月都触发",
          value: "all",
        },
        {
          label: "按固定值触发",
          value: "appoint",
        },
      ],
      hourTriggerTypeEnums: [
        {
          label: "每小时都触发",
          value: "all",
        },
        {
          label: "按范围触发",
          value: "range",
        },
        {
          label: "按固定值触发",
          value: "appoint",
        },
        {
          label: "按一定增量触发",
          value: "increment",
        },
      ],
      dayTriggerTypeEnums: [
        {
          label: "每天都触发",
          value: "all",
        },
        {
          label: "按范围触发",
          value: "range",
        },
        {
          label: "按固定值触发",
          value: "appoint",
        },
        {
          label: "按一定增量触发",
          value: "increment",
        },
        {
          label: "每月的最后一天触发",
          value: "last",
        },
      ],
    };
  },
  computed: {
    isCustom() {
      return this.value.loopType == "custom" && this.value.cronConfig;
    },
    isDay: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.date) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        if (val) {
          this.$set(this.value.cronConfig, "date", {
            cronValueType: "all",
          });
          this.$delete(this.value.cronConfig, "week");
        } else {
          this.$delete(this.value.cronConfig, "date");
        }
      },
    },
    isWeek: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.week) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        if (val) {
          this.$set(this.value.cronConfig, "week", {
            cronValueType: "appoint",
            value: [],
          });
          this.$delete(this.value.cronConfig, "date");
        } else {
          this.$delete(this.value.cronConfig, "week");
        }
      },
    },
    hourRangeStart: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.hour) {
          return this.value.cronConfig.hour.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.value.cronConfig.hour.value, 0, val);
      },
    },
    hourRangeEnd: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.hour) {
          return this.value.cronConfig.hour.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.value.cronConfig.hour.value, 1, val);
      },
    },
    hourIncrementStart: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.hour) {
          return this.value.cronConfig.hour.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.value.cronConfig.hour.value, 0, val > 23 ? 23 : val);
      },
    },
    hourIncrementEnd: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.hour) {
          return this.value.cronConfig.hour.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        console.log(val);
        if (val !== "") {
          this.$set(this.value.cronConfig.hour.value, 1, val <= 0 ? 1 : val);
        } else {
          this.$set(this.value.cronConfig.hour.value, 1, val);
        }
      },
    },
    dateRangeStart: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.date) {
          return this.value.cronConfig.date.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.value.cronConfig.date.value, 0, val);
      },
    },
    dateRangeEnd: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.date) {
          return this.value.cronConfig.date.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.value.cronConfig.date.value, 1, val);
      },
    },
    dateIncrementStart: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.date) {
          return this.value.cronConfig.date.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        if (val !== "") {
          if (val <= 0) {
            this.$set(this.value.cronConfig.date.value, 0, 1);
          } else if (val > 0) {
            this.$set(this.value.cronConfig.date.value, 0, val > 31 ? 31 : val);
          }
        } else {
          this.$set(this.value.cronConfig.date.value, 0, val);
        }
      },
    },
    dateIncrementEnd: {
      get() {
        if (this.value.cronConfig && this.value.cronConfig.date) {
          return this.value.cronConfig.date.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        console.log(val);
        if (val !== "") {
          this.$set(this.value.cronConfig.date.value, 1, val <= 0 ? 1 : val);
        } else {
          this.$set(this.value.cronConfig.date.value, 1, val);
        }
      },
    },
  },
  methods: {
    dayChange(val) {
      console.log(val);
      console.log(this.value.cronConfig);
    },
    weekChange(val) {
      this.value.cronConfig.week.value = [moment().day()];
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    dateRangeStartChange(val) {
      console.log(val);
      if (this.value.cronConfig && this.value.cronConfig.date) {
        val = this.checkoutRange(val, this.dateRangeEnd, 1, 31, "min");
        this.$set(this.value.cronConfig.date.value, 0, val);
      }
    },
    dateRangeEndChange(val) {
      console.log(val);
      if (this.value.cronConfig && this.value.cronConfig.date) {
        val = this.checkoutRange(val, this.dateRangeStart, 1, 31, "max");
        this.$set(this.value.cronConfig.date.value, 1, val);
      }
    },
    hourRangeStartChange(val) {
      console.log(val);
      if (this.value.cronConfig && this.value.cronConfig.hour) {
        val = this.checkoutRange(val, this.hourRangeEnd, 0, 23, "min");
        this.$set(this.value.cronConfig.hour.value, 0, val);
      }
    },
    hourRangeEndChange(val) {
      console.log(val);
      if (this.value.cronConfig && this.value.cronConfig.hour) {
        val = this.checkoutRange(val, this.hourRangeStart, 0, 23, "max");
        this.$set(this.value.cronConfig.hour.value, 1, val);
      }
    },
    monthTriggerChange(val) {
      console.log("monthTriggerChange", val);
      if (val == "all") {
        this.value.cronConfig.month.value = "";
      }
      if (val == "appoint") {
        this.value.cronConfig.month.value = [];
      }
    },
    /**
     * 处理小时触发类型改变
     * @param val
     */
    dateTriggerChange(val) {
      console.log("dateTriggerChange", val);
      if (val == "range") {
        this.$set(this.value.cronConfig.date, "value", ["", ""]);
        // this.value.cronConfig.date.value = ['', '']
      }
      if (val == "appoint") {
        this.$set(this.value.cronConfig.date, "value", []);
        // this.value.cronConfig.date.value = []
      }
      if (val == "increment") {
        this.$set(this.value.cronConfig.date, "value", ["", ""]);

        // this.value.cronConfig.date.value = ['', '']
      }
    },
    /**
     * 处理小时触发类型改变
     * @param val
     */
    hourTriggerChange(val) {
      if (val == "range") {
        this.value.cronConfig.hour.value = ["", ""];
      }
      if (val == "appoint") {
        this.value.cronConfig.hour.value = [];
      }
      if (val == "increment") {
        this.value.cronConfig.hour.value = [0, 1];
      }
    },
    loopTypeChange(val) {
      console.log(val);
      this.$set(this.value, "cronConfig", {
        hour: {
          cronValueType: "all",
          value: [],
        },
        date: {
          cronValueType: "all",
          value: [],
        },
        month: {
          cronValueType: "all",
          value: [],
        },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>
