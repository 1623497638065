<template>
  <div>
    <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
      <el-form-item prop="dataSource">
        <el-radio-group v-model="dataSource" @change="changeDataSource">
          <el-radio label="form">更新工作表</el-radio>
          <el-radio label="node_data">更新子表</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="node.dataSource == 'node_data'"
        key="node_data"
        label="节点列表"
        :rules="[{ required: true, message: '请选择节点' }]"
        prop="nodeKey"
      >
        <el-select
          v-model="nodeKey"
          placeholder="请选择节点"
          @change="changeNodeKey"
          size="small"
        >
          <el-option
            v-for="item in nodeList"
            :key="item.key"
            :label="item.title"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-else
        key="form"
        label="选择工作表"
        :rules="[{ required: true, message: '请选择工作表' }]"
        prop="formId"
      >
        <el-input
          :value="node.formTitle"
          @focus="visible = true"
          readonly
          size="small"
        ></el-input
      ></el-form-item>

      <!-- 基于多条记录更新 -->
      <el-checkbox v-model="model" @change="changeModel"
        >基于多条记录逐条保存记录</el-checkbox
      >
      <el-form-item v-if="model" prop="baseNodeKey">
        <el-select
          v-model="node.baseNodeKey"
          :placeholder="
            node.dataSource == 'node_data' && !node.nodeKey
              ? '请先选择节点'
              : '请选择数据源'
          "
          size="small"
          :disabled="node.dataSource == 'node_data' && !node.nodeKey"
        >
          <el-option
            v-for="opt in nodeList2"
            :key="opt.key"
            :label="`${opt.title}【${opt.label}】`"
            :value="opt.key"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="修改表单中哪些数据">
        <rpa-action-filter
          :dataFilter="node.dataFilter"
          :componentList="componentList"
          :formId="node.formId"
          :isAddFilter="isAddFilter"
          :disabled="disabled"
          :node-type="node.type"
          :baseNodeKey="node.baseNodeKey"
        ></rpa-action-filter>
      </el-form-item>
      <el-form-item label="对符合过滤条件的数据，进行以下字段的修改">
        <rpa-field-action
          :key="node.baseNodeKey"
          :fields="node.updateFieldValues"
          :componentList="componentList"
          :formId="node.formId"
          abilityKey="fillComponents"
          fieldConfigKey="fillComponents"
          type="edit"
          btnText="添加字段"
          :disabled="disabled"
          :node="node"
        ></rpa-field-action>
      </el-form-item>
      <el-form-item
        label="若找不到符合过滤条件的数据，则根据以下配置在目标表单中新增数据"
      >
        <template v-for="item in node.createFieldValues">
          <rpa-field-setting
            :key="`${item.tableField.fieldName}_${node.baseNodeKey}`"
            :field="item"
            type="add"
            :componentList="componentList"
            :disabled="disabled"
            :node="node"
          ></rpa-field-setting>
        </template>
      </el-form-item>
    </el-form>
    <data-source
      :visible.sync="visible"
      :menus="['form']"
      @changeSource="changeForm"
    ></data-source>
  </div>
</template>
<script>
import dataSource from "@/components/data-source";
import rpaFieldAction from "./rpa-field-action";
import { fetchMetaComponents } from "@/api/form";
import { isEmpty } from "@zgg-core-utils/utils";
import rpaActionFilter from "./rpa-action-filter";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import rpaFieldSetting from "./rpa-field-setting";
import {
  fromNodesMixins,
  isEmptyRpaActionFilter,
  rpaUpdateByNodeMixins,
} from "./rpa-utils";
import { isIDEmpty } from "@/zgg-core/utils";

export default {
  name: "rpa-form-save",
  components: { dataSource, rpaFieldAction, rpaActionFilter, rpaFieldSetting },
  props: {
    node: Object,
    disabled: Boolean,
  },
  mixins: [rpaUpdateByNodeMixins, fromNodesMixins],
  data() {
    return {
      componentList: [],
      visible: false,
      isAddFilter: false,
      model: false,
    };
  },
  computed: {
    nodeList2() {
      if (this.node.dataSource == "node_data" && isEmpty(this.node.nodeKey)) {
        return [];
      }
      let list = this.getParentNodes(["query_data_list"]);
      list = list.filter((item) => item.type == "query_data_list");
      list.forEach((item) => {
        if (item.dataSource == "node_data_field") {
          item.label = item.nodeField.title;
        } else {
          item.label = item.formTitle || item.collectionTitle;
        }
      });
      if (this.node.dataSource == "node_data") {
        list = list.filter((item) => item.key != this.node.nodeKey);
      }

      return list;
    },
  },
  created() {
    if (this.node.dataSource != "node_data" && !isIDEmpty(this.node.formId)) {
      this.fetchMetaComponents(this.node.formId);
    }
    this.isAddFilter = this.node.formId && !this.disabled;
    if (!this.node.dataSource) {
      this.$set(this.node, "dataSource", "form");
    }
    this.model = !isEmpty(this.node.baseNodeKey);
  },
  methods: {
    changeModel() {
      this.$set(this.node, "baseNodeKey", "");
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (
          this.node.updateFieldValues.findIndex(
            (item) =>
              (item.valueType == "custom" && isEmpty(item.value)) ||
              (item.valueType == "nodeField" && isEmpty(item.nodeField.name)) ||
              (item.valueType == "rely" && isEmpty(item.rely)),
          ) >= 0
        ) {
          this.$message.error("请设置更新记录的字段值");
          return;
        }

        if (isEmptyRpaActionFilter(this.node.dataFilter)) {
          this.$message.error("请设置过滤条件的字段值");
          return;
        }
        if (this.node.updateFieldValues.length == 0) {
          this.$message.error("请设置更新记录");
          return;
        }

        if (
          this.node.createFieldValues.findIndex(
            (item) =>
              (item.valueType == "custom" && isEmpty(item.value)) ||
              (item.valueType == "nodeField" && isEmpty(item.nodeField.name)),
          ) >= 0
        ) {
          this.$message.error("请设置新增记录的字段值");
          return;
        }
        callback(valid);
      });
    },
    changeForm({ tableName, tableTitle }) {
      let callback = () => {
        this.node.formId = tableName;
        this.node.formTitle = tableTitle;
        this.$set(this.node, "dataFilter", {
          rel: "and",
          advanceQuery: [],
        });
        this.$set(this.node, "updateFieldValues", []);
        this.$set(this.node, "createFieldValues", []);
        this.fetchMetaComponents(tableName);
      };
      if (this.node.formId != tableName && !isEmpty(this.node.formId)) {
        this.$confirm("切换表单将会清除已设置的配置", "提示")
          .then(callback)
          .catch(() => {});
      } else {
        callback();
      }
    },
    fetchMetaComponents(tableName) {
      fetchMetaComponents(tableName).then((res) => {
        this.componentList = res.data.list;
        this.buildCreateFelds(res.data.list);
      });
    },
    buildCreateFelds(componentList) {
      let createFieldValues = [];
      componentList
        .filter(
          (item) =>
            !!checkFieldAbility(item, "fillComponents") ||
            item.componentName == "json_form",
        )
        .forEach((item) => {
          let field = this.node.createFieldValues.find(
            (row) =>
              row.tableField.fieldName == item.name &&
              row.tableField.collection == this.node.formId,
          );
          let referenceTableName;
          let referenceCollection;
          if (item.componentName == "reference_data") {
            referenceTableName = item.tableName;
          }
          if (item.componentName == "json_form") {
            referenceCollection = item.tableName;
          }

          if (isEmpty(field)) {
            createFieldValues.push({
              tableField: {
                collection: this.node.formId,
                fieldName: item.name,
                fieldTitle: item.title,
                componentName: item.componentName,
                picker: item.picker,
                referenceTableName,
                referenceCollection,
              },
              valueType: "nodeField",
              nodeField: {
                nodeKey: "",
                collection: "", //这个节点的字段来自某个表单
                name: "",
                title: "",
                componentName: "",
              },
              value: "",
            });
          } else {
            createFieldValues.push({
              ...this._.cloneDeep(field),
              referenceTableName,
            });
          }
        });
      this.$set(this.node, "createFieldValues", createFieldValues);
    },
  },
};
</script>
