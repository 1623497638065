<template>
  <gantt-edit
    v-if="view"
    v-loading="saveLoading"
    :gantt="view"
    :areaTree="areaTree"
    @save="saveForm"
  ></gantt-edit>
</template>
<script>
import { getViewDetail, saveViewComponent } from "./api";
import { getAreaTree } from "@/api/contact";
import ganttEdit from "./ganttEdit";

export default {
  components: { ganttEdit },
  data() {
    return {
      saveLoading: false,
      areaTree: [],
      view: null,
    };
  },

  created() {
    this.getAreaTree();
    getViewDetail({ viewId: this.$route.query.id }).then((res) => {
      if (res.code) {
        this.$message.error(res.msg);
      } else {
        let view = res.data.view.component;
        if (!view.xFields) {
          view.xFields = [];
        }
        view.xFields.forEach((item) => {
          item.field.title = item.title;
        });
        if (!view.dataSource) {
          view.dataSource = {
            queryObject: {
              advanceQuery: [],
            },
          };
        }
        if (!view.dataSource.queryObject) {
          view.dataSource.queryObject = {
            advanceQuery: [],
          };
        }
        if (!view.dataSource.queryObject.advanceQuery) {
          view.dataSource.queryObject.advanceQuery = [];
        }
        if (!(view.timeProgresses && view.timeProgresses.length)) {
          view.timeProgresses = [
            {
              startTimeField: null,
              endTimeField: null,
              progressField: null,
              chartLabelFieldName: "",
            },
            {
              startTimeField: null,
              endTimeField: null,
              progressField: null,
              chartLabelFieldName: "",
            },
          ];
        } else if (view.timeProgresses.length == 1) {
          view.timeProgresses.push({
            startTimeField: null,
            endTimeField: null,
            progressField: null,
            chartLabelFieldName: "",
          });
        }
        if (!view.zoomLevel) {
          view.zoomLevel = "week";
        }

        this.$set(this, "view", view);
      }
    });
  },
  methods: {
    getAreaTree() {
      getAreaTree().then((res) => {
        let areaTree = [];
        let buildTree = (soruce) => {
          let obj = {};
          for (const [key, value] of Object.entries(soruce)) {
            if (key != "children") {
              obj[key] = value;
            }
          }
          if (soruce.children && soruce.children.length) {
            obj.children = [];
            soruce.children.forEach((item) => {
              let target = buildTree(item);
              obj.children.push(target);
            });
          }
          return obj;
        };
        res.data.tree.forEach((item) => {
          let target = buildTree(item);
          areaTree.push(target);
        });
        this.areaTree = areaTree;
      });
    },

    saveForm({ view, callback }) {
      let viewId = this.$route.query.id;

      this.saveLoading = true;
      saveViewComponent({
        viewId,
        component: JSON.stringify(view),
      })
        .then((res) => {
          this.saveLoading = false;

          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 10px;

  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.container {
  display: flex;
  height: calc(100vh - 70px);
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.mr {
  margin-right: 10px;
}
.weui-flex {
  display: flex;
  align-items: center;
}
.weui-row {
  margin: 10px 10px 0px;
}
.weui-item {
  flex: 1;
  font-size: 12px;
}
.field-row {
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  min-height: 44px;
  &.n-border {
    border-color: transparent;
  }
}
.col-container {
  display: flex;
  flex-wrap: wrap;
  ::v-deep {
    .el-tooltip {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
}
.field-title {
  font-size: 12px;
  margin-right: 10px;
  width: max-content;
}
.drag-able {
  background-color: rgba($color: #409eff, $alpha: 0.1);
}
.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}
.nav-tab {
  display: flex;

  .tab-txt {
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    padding: 0px 20px;
    flex: 1;
    cursor: pointer;

    &.on {
      color: #409eff;
      cursor: default;
    }
  }
}

.form-body {
  flex: 1;
  overflow: auto;

  .lay-item {
    font-size: 14px;
    border: solid 1px #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
  }
}
.m {
  &:focus {
    outline: none !important;
  }
}
.pop-container {
  padding: 6px 0px;
}
.pop-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  height: 24px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #409eff, $alpha: 0.1);
  }
}
.flex {
  flex: 1;
}
.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}
.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.empty-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.required {
  color: #ff4d4f;
  padding-right: 4px;
}
.is-empty,
.is-error {
  ::v-deep {
    .el-input__inner {
      border-color: #ff4d4f;
      &::placeholder {
        color: #ff4d4f;
      }
    }
  }
}
</style>
<style lang="scss">
.outline-pop {
  padding: 0 !important;
}
</style>
