<template>
  <div>
    <!-- <el-form ref="form" :model="node" label-position="top">
      <rpa-condition-item
        v-for="(item, index) in node.condition.advanceQuery"
        :key="index"
        :rel.sync="node.condition.rel"
        :field="item"
        :index="index"
        :disabled="disabled"
        @del="del(index)"
      ></rpa-condition-item>
    </el-form>

    <div v-if="!disabled">
      <el-button @click="addFilter" type="text" icon="el-icon-plus"
        >添加条件</el-button
      >
    </div> -->
    <zgg-filter
      :dataFilter="node.condition"
      :buildQueryObj="buildQueryObj"
      :disabled="disabled"
    >
      <template slot-scope="{ field }">
        <rpa-condition-item
          :disabled="disabled"
          :field="field"
          style="flex: 1; margin-left: 10px; overflow: hidden"
        ></rpa-condition-item>
      </template>
    </zgg-filter>
  </div>
</template>
<script>
import {
  getAdvanceQuerysByDataFilter,
  isEmpty,
  isEmptyByKey,
} from "@zgg-core-utils/utils";
import rpaConditionItem from "./rpa-condition-item";
export default {
  name: "rpa-condition",
  components: { rpaConditionItem },
  props: {
    node: Object,
    disabled: Boolean,
  },

  methods: {
    del(index) {
      let advanceQuery = this.node.condition.advanceQuery;
      advanceQuery.splice(index, 1);
    },
    getData(callback) {
      if (!isEmptyByKey(this.node, "condition.advanceQuery")) {
        let advanceQuery = getAdvanceQuerysByDataFilter(this.node.condition);
        if (
          advanceQuery.findIndex((item) =>
            isEmptyByKey(item, "paramValue1.nodeField.name"),
          ) >= 0
        ) {
          this.$message.error("请设置过滤条件");
          return;
        }

        if (
          advanceQuery.findIndex(
            (item) =>
              !["nil", "nnil", "dynamic"].includes(item.oper) &&
              isEmptyByKey(item, "paramValue2.customValue") &&
              isEmptyByKey(item, "paramValue2.nodeField.name"),
          ) >= 0
        ) {
          this.$message.error("请设置过滤条件");
          return;
        }
        if (
          advanceQuery.findIndex(
            (item) =>
              ["dynamic"].includes(item.oper) &&
              isEmptyByKey(item, "paramValue2.dynamicTime"),
          ) >= 0
        ) {
          this.$message.error("请设置过滤条件");
          return;
        }
      }
      callback(true);
    },
    buildQueryObj() {
      return {
        paramValue1: {
          valueType: "nodeField",
          nodeField: {
            nodeKey: "",
            collection: "", //这个节点的字段来自某个表单
            name: "",
            title: "",
            componentName: "",
            picker: "",
          },
        },
        oper: "",
        paramValue2: {},
      };
    },
    addFilter() {
      let advanceQuery = this.node.condition.advanceQuery;
      advanceQuery.push(this.buildQueryObj());
    },
  },
};
</script>
