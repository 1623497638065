<template>
  <div>
    <el-dialog
      @close="close"   
      title="引用详情"
      :visible="visible"
      width="560px"
      append-to-body
    >
      <div style="padding: 20px; height: 500px;">
        <div class="reference-header">
          <div class="form-title" :title="referenceTitle">{{ referenceTitle }}</div>
          <div class="refresh" title="刷新" @click="toReferenceDetail">
            <i
              class="iconfont icon-refresh-line"
              style="font-size: 16px; margin: 0px; color:#3870EA"
            ></i>
          </div>
        </div>
        <div class="reference-list">
          <div class="reference-item" v-for="(item, index) in referenceList" :key="index">
            <div class="item-header">
              <div class="item-name" @click="triigerReferenceItem(item, index)">
                <!-- v-if="referenceItemIndex == index" -->
                <i class="iconfont icon-down" style="font-size: 16px;" v-if="item.isOpen"></i>
                <i class="iconfont icon-right" style="font-size: 16px;" v-else></i>
                <i class="iconfont icon-FileText"></i>{{ item.name }}
              </div>
              <div v-if="item?.companyId">
                <el-tooltip class="item" effect="dark" :content="item?.company" placement="top-start">
                  <i class="iconfont icon-home-4-fill" style="font-size: 16px; margin: 0px; color:#3870EA"></i>
                </el-tooltip>
              </div>
              <div v-else>
                <el-button type="text" @click="toEditReference(item)">前往修改</el-button>
              </div>
            </div>
            <!-- v-show="referenceItemIndex == index" -->
            <div class="reference-details-list" v-if="item.isOpen">
              <div class="reference-details-item" v-for="(itemChild, indexChild) in item.detailReferences" :key="indexChild">
                <span v-if="itemChild.title">
                  {{ itemChild.title }} —
                </span>
                {{ scenceEnumConvert(itemChild.scene) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  referenceQueryBrief,
  referenceQueryDetail
} from "./api";
import { getFormScence, getFormFieldScence } from "@/utils/enums";
export default {
    name: "referenceDetail",
    props: {
      visible: {
            default() {
                return false;
            },
        },
        // 引用关系来源 1、表单(form) 2、表单字段(formField)
        source: {
          type: String,
          default() {
            return "form";
          },
        },
        formId: {
          type: String,
          default: ""
        },
        // 引用来源标题
        referenceTitle: {
          type: String,
          default: ""
        },
        // 组件key
        fieldKey: {
          type: String,
          default: ""
        }
    },
    data() {
        return {
            referenceList: []
        };
    },
    created(){
      this.toReferenceDetail()
    },
    methods: {
        // 表单/表单字段场景值枚举转换
        scenceEnumConvert(scence){
          if(this.source == "formField"){
            return getFormFieldScence(scence)
          } else {
            return getFormScence(scence)
          }
        },
        // 查询引用列表
        toReferenceDetail(){
          let params = {
            collection: this.formId
          }
          params.type = "form"
          if(this.source == "aggregate_table" || this.source == "dataflow" || this.source == "robot"){
            params.type = this.source
          }
          if(this.source == "formField"){ // 组件间的引用(特殊)
            params.fieldKey = this.fieldKey
          }
          referenceQueryBrief(params).then(res => {
            this.referenceList = res?.data?.briefReferences;
            if(this.referenceList?.length){
              this.referenceList.forEach(item => {
                this.$set(item, "isOpen", false)
              })
            }
            this.referenceItemIndex = null;
            this.referenceVisible = true
          })
        },
        // 查询引用详情
        triigerReferenceItem(item, index){
          this.$set(item, "isOpen", !item.isOpen)
          if(this.referenceItemIndex == index){
            this.referenceItemIndex = null
          } else {
            this.referenceItemIndex = index
            // 请求引用详情
            let params = {
              briefId: item.briefId
            }
            if(this.source == "formField"){
              params.fieldKey = this.fieldKey
            }
            referenceQueryDetail(params).then(res => {
              this.$set(this.referenceList[index], "detailReferences", res?.data?.detailReferences ?? [])
            })
          }
        },
        // 前往修改引用的数据源
        toEditReference(item){
          let path = ""
          let type = item.type
          switch (type) {
                case "form": // 表单设计
                    path = "/lowCode/form/edit?id="
                    break;
                case "aggregate_table": // 聚合表
                    path = "/lowCode/aggregate-table/edit?id="
                    break;
                case "dataflow": // 数据流
                    path = "/lowCode/dataFactory/edit?id="
                    break
                case "spirit": // 智能助手1.0
                    path = "/lowCode/assistant/edit?id="
                    break
                case "robot": // 智能助手2.0
                    path = "/lowCode/rpa/edit?id="
                    break
                case "dashboard": // 仪表盘
                    path = "/lowCode/dashboard/edit?id="
                    break        
                case "workflow": // 流程
                    path = ""
                    break
            }    
            let url = this.$router.resolve({
                path: path + item.resourceId,
            });
            window.open(url.href, "_blank");
        },
        close(){
            this.$emit("update:visible", false);
        }
    },
}

</script>
<style lang="scss" scoped>
  .reference-header{
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  .form-title{
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .refresh{
    cursor: pointer;
  }
}

.reference-list{
  overflow-y: auto;
  border: 1px solid #ebecee;
  border-radius: 3px;
  max-height: 420px;
  .reference-item{
    .item-header{
      align-items: center;
      background-color: #f5f6f8;
      border-top: 1px solid #ebecee;
      display: flex;
      height: 36px;
      padding: 0 10px;
      justify-content: space-between;
      overflow: hidden;
      .item-name{
        flex: 1;
        line-height: 36px;
        cursor: pointer;
      }
    }
    &:first-child{
      .item-header{
        border-top: none;
      }
    }
  }
  .reference-details-list{
    .reference-details-item{
      height: 32px;
      line-height: 32px;
      margin-left: 32px;
      max-width: 435px;
    }
  }
}
</style>