import request from "@/utils/request";
import qs from "qs";
import axios from "axios";
import store from "@/store";
/**
 * 新建视图
 * @param {*} data
 * @returns
 */
export function createView(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/ui/view/create",
    method: "post",
    data,
  });
}

/**
 * 获取视图列表
 * @param {*} data
 * @returns
 */
export function fetchViewList(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/ui/view/list",
    method: "post",
    data,
  });
}

/**
 * 视图重命名
 * @param {*} data
 * @returns
 */
export function renameView(data) {
  return request({
    url: "/ui/view/rename",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 删除视图
 * @param {*} data
 */
export function deleteView(data) {
  return request({
    url: "/ui/view/delete",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 获取视图详情
 * @param {*} viewId
 * @returns
 */
export function getViewDetail(data) {
  return request({
    url: "/ui/view/detail",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 视图界面保存
 * @param {*} params
 */
export function saveViewComponent(data) {
  return request({
    url: "/ui/view/saveComponent",
    method: "post",
    data: data,
  });
}

/**
 * 获取统计数据
 * @param {*} data
 * @returns
 */
export function getAggreation(data) {
  return request({
    url: "/formdata/aggregation",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 获取统计数据
 * @param {*} data
 * @returns
 */
export function getAggreationNoStringify(data) {
  return request({
    url: "/formdata/aggregation",
    method: "post",
    data: data,
  });
}

/**
 * 视图复制
 * @param {*} data
 * @returns
 */
export function viewDuplicate(data) {
  return request({
    url: "/ui/view/duplicate",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 复制聚合表
 * @param {*} data
 * @returns
 */
export function copyAggregateTable(data) {
  return request({
    url: "/ui/view/duplicateToAggregateTable",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 获取甘特图的数据
 * @param {*} data
 * @returns
 */
export function getGanttData(data) {
  return request({
    url: "/view/data/gantt",
    method: "post",
    data: qs.stringify(data),
  });
}

export async function getGeoJson(areaId) {
  // request({//})

  let id = areaId;
  let data;
  if (areaId == "all") {
    id = "";
  } else {
    data = {
      areaId: id,
    };
  }
  let resp = await request({
    url: "/area/loadGeo",
    method: "post",
    data,
  });
  if (!resp.data.code) {
    let res = await axios({
      method: "get",
      url: resp.data.boundUrl,
    });
    return Promise.resolve({ geoJson: res.data, hasChild: resp.data.hasChild });
  }
  return Promise.reject(resp);
}

/**
 * 聚合表数据
 * @param {*} data
 * @returns
 */
export function aggregateTable(data) {
  return request({
    url: "/view/data/aggregateTable",
    method: "post",
    data,
  });
}

/**
 * 聚合表列表
 * @param {*} data
 * @returns
 */
export function aggregateTableList(data) {
  return request({
    url: "/ui/aggregate/table/list",
    method: "post",
    data,
  });
}

/**
 * 聚合表详情
 * @param {*} data
 * @returns
 */
export function aggregateDetail(data) {
  return request({
    url: "/ui/aggregate/table/detail",
    method: "post",
    data,
  });
}

/**
 * 创建视图统计报表接口
 * @param {*} data
 * @returns
 */
export function dashboardViewCreate(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/ui/view/subViewForNoDirCreate",
    method: "post",
    data,
  });
}

/**
 * 通讯录人员表单 元数据表字段
 * @param {*}
 * @param {String} companyId 【单位ID】
 * @param {Boolean} returnWithSystemField 【是否返回系统字段 默认false】
 * @returns
 */
export function fetchContactFormMetaFieldList(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/contactForm/meta/field/list",
    method: "post",
    data,
  });
}

/**
 * 获取自定义颜色
 * @param {*}
 * @returns
 */
export function getCustomColor(data) {
  return request({
    url: "/define/style/getCustomColor",
    method: "post",
    data,
  });
}
/**
 * 保存自定义颜色
 * @param customColor
 * @returns
 */
export function saveCustomColor(data) {
  return request({
    url: "/define/style/saveCustomColor",
    method: "post",
    data,
  });
}
