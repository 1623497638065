import request from '@/utils/request'
import qs from "qs"
import {getFormModel} from "@/utils/tools"




/**
 * 保存按钮保存计划
 */
export function getMyPlan(data) {
  return request({
    url:  '/plan/listPage',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 获取计划详情
 */
export function planDetail(params) {
  return request({
    url:  '/plan/detail',
    method: 'get',
    params
  })
}
/**
 * 退出计划
 */
export function exitPlan(data) {
  return request({
    url:  '/plan/exit',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 复制计划
 */
export function copyPlan(params) {
  return request({
    url:  '/plan/copy',
    method: 'get',
    params
  })
}
/**
 * 保存计划到计划资料
 */
export function savePlanToLibrary(data) {
  return request({
    url:  '/plan/preserve',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 获取计划资料列表带分页
 */
export function getMyLibraryPlan(data) {
  return request({
    url:  '/plan/listPage',
    method: 'post',
    data:qs.stringify(data)
  })
}


/**
 * 为计划节点添加资料
 */
export function resourceAdd(data) {
  return request({
    url:  '/plan/resourceAdd',
    method: 'post',
    data:qs.stringify(data)
  })
}

/**
 * 删除节点的关联文档
 */
export function deleteAssociate(data) {
  return request({
    url:  '/plan/resourceDelete',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 新建计划或修改计划名
 */
export function addNewPlanTitle(params) {
  return request({
    url:  '/plan/save',
    method: 'post',
    params: getFormModel(params,"plan")
  })
}

/**
 * 添加关联人
 */
export function addUser(data) {
  return request({
    url:  '/plan/addUser',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 删除目录
 */
export function directoryDelete(data) {
  return request({
    url:  '/plan/directoryDelete',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 添加目录
 */
export function directorySave(params) {
  return request({
    url:  '/plan/directorySave',
    method: 'post',
    params: getFormModel(params,"directory")
  })
}
/**
 * 移动目录
 */
export function directoryMove(data) {
  return request({
    url:  '/plan/directoryMove',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 获取计划目录的关联数据
 */
export function resourceListPage(data) {
  return request({
    url:  '/plan/resourceListPage',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 对目录加加锁和解锁
 */
export function directorySwitchLock(data) {
  return request({
    url:  '/directory/switchLock',
    method: 'post',
    data:qs.stringify(data)
  })
}

/**
 * 复制关联计划到
 */
export function copyPlanResource(resourceId) {
  return request({
    url:  '/plan/copyPlanResource',
    method: 'post',
    data:qs.stringify({resourceId})
  })
}
/**
 * 计划撤销操作
 */
export function planUndo(planId) {
  return request({
    url:  '/plan/undo',
    method: 'post',
    data:qs.stringify({planId})
  })
}
/**
 * 计划重做
 */
export function planRedo(planId) {
  return request({
    url:  '/plan/redo',
    method: 'post',
    data:qs.stringify({planId})
  })
}
/**
 * 移动资源
 */
export function resourceMove(data) {
  return request({
    url:  '/plan/resourceMove',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 复制计划内的目录列表
 */
export function copyDirectoryList(data) {
  return request({
    url:  '/plan/copyDirectoryList',
    method: 'post',
    data:qs.stringify(data)
  })
}
/**
 * 切换管理员
 */
export function planSwitchAdmin(data) {
  return request({
    url:  '/plan/switchAdmin',
    method: 'post',
    data:qs.stringify(data)
  })
}



/**
 * 计划目录列表
 * 无参 获取根目录的文件夹和文件列表
 * 带参 folderId 获取某个文件夹下的文件夹和文件列表
 */
export function planFolderList(data) {
  return request({
    url:  '/plan/folderList',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 * 文件夹保存
 * 参数1,新建文件夹到parentId下，0为根目录 {folder.name:"",folder.parentId:0}
 * 参数2，修改id为0的文件夹名{folder.id:0,folder.name:""}
 */
export function planFolderSave(data) {
  return request({
    url:  '/plan/folderSave',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 * 文件夹删除
 * @param data {"folderId":0}
 */
export function planFolderDelete(data) {
  return request({
    url:  '/plan/folderDelete',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 * 资料库文件夹移动
 * @param  postParamsMap.put("folderId", 3);
 postParamsMap.put("parentId", 4);
 postParamsMap.put("siblingId", 0);
 */
export function planFolderMove(data) {
  return request({
    url:  '/plan/folderMove',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 * 资料库文件移动
 * @param  postParamsMap.put("folderId", 3);
 postParamsMap.put("parentId", 4);
 postParamsMap.put("siblingId", 0);
 */
export function planMove(data) {
  return request({
    url:  '/plan/move',
    method: 'POST',
    data:qs.stringify(data)
  })
}

/*------------------新接口-------------------------*/
// /plan/listPage
/**
 * 我参与的整编列表
 * @param data
 * @returns {AxiosPromise}
 */
 export function planListPage(data) {
  return request({
    url:  '/plan/listPage?_version=2.0',
    method: 'POST',
    data: qs.stringify(data)
  })
}