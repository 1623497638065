import { isEmpty } from "@zgg-core-utils/relyUtil";
/**
 * 处理过滤组件
 * @param components
 */
export function handleDataFilterSet(components) {
  let filterComponents = components.filter(
    (item) => item.componentName == "dashboard_filter",
  );
  let dataFilterSet = {};
  filterComponents.forEach((component) => {
    let { fields, fieldQuery, containerKeys } = component;
    containerKeys.forEach((key) => {
      fields.forEach((field) => {
        let { collection, componentName, fieldName } = field;
        let { oper, value } = fieldQuery;
        if (!dataFilterSet[collection]) {
          dataFilterSet[collection] = {};
        }
        let currentDataFilter = dataFilterSet[collection][key];
        if (!currentDataFilter) {
          dataFilterSet[collection][key] = {
            advanceQuery: [],
            rel: "and",
          };
        }
        ///如果是为空和不为空的话，不需要传值，或者除了为空和不为空的话，值不为空时，需要传值
        if (
          (!["nil", "nnil"].includes(oper) && !isEmpty(value)) ||
          ["nil", "nnil"].includes(oper)
        ) {
          if ("between" === oper && Array.isArray(value)) {
            value.forEach((val, index) => {
              if (!val) {
                value[index] = null;
              }
            });
          }
          dataFilterSet[collection][key].advanceQuery.push({
            oper,
            value,
            key: fieldName,
            componentName,
          });
        }
      });
    });
  });
  return dataFilterSet;
}

/**
 * 是否是图表组件(此枚举不包含明细表，主要用作前台的chart组件的判断)
 * @param componentName
 * @returns {boolean}
 */
export const isChartComponent = (componentName) => {
  return [
    "metric_table",
    "chart_column",
    "chart_bar",
    "chart_line",
    "chart_area",
    "chart_pie",
    "chart_multi_axes",
    "pivot_table",
    "chart_process",
    "chart_radar",
  ].includes(componentName);
};
/**
 * 添加组件时，初始化的默认布局大小
 * @type {{title_view: {w: number, h: number}, include: {w: number, h: number}, dashboard_filter: {w: number, h: number}, count_down: {w: number, h: number}, carousel_view: {w: number, h: number}, button_group: {w: number, h: number}}}
 */
export const defaultModuleLayoutGridBundleSize = {
  dashboard_filter: {
    w: 12,
    h: 6,
  },
  title_view: {
    w: 24,
    h: 6,
  },
  count_down: {
    w: 12,
    h: 6,
  },
  carousel_view: {
    w: 12,
    h: 10,
  },
  include: {
    w: 12,
    h: 12,
  },
  button_group: {
    w: 12,
    h: 10,
  },
  dashboard: {
    w: 6,
    h: 18,
  },
  tab: {
    w: 6,
    h: 18,
  },
};

//栅格布局的相关配置
/**
 *    colNumber: 24,
 *       rowHeight: 14,
 *       minW: 2,
 *       minH: 4,
 *       maxH: 60,
 * @type {number}
 */
export const COL_NUM = 24;
export const ROW_HEIGHT = 14;
export const MIN_H = 4;
export const MIN_W = 2;
export const MAX_H = 60;

export const getFilterColorConfig = (view, isH5) => {
  if (isH5) {
    var jsBackgroundColor = "#ffffff";
    var jsContentColor = "#42526e";
    var jsBorderColor = "#dfe1e6";
  }
  if (!isH5) {
    var jsFilterKey = view.key;

    var jsBackgroundColor = "#ffffff";
    if (
      view?.colorConfig?.backgroundColor ||
      view?.colorConfig?.backgroundImage
    ) {
      jsBackgroundColor = "transparent";
    }

    var jsContentColor = view?.colorConfig?.contentColor || "#42526e";
    var jsBorderColor = view?.colorConfig?.contentColor || "#dfe1e6";
    let filter = document.querySelector("#filter_" + jsFilterKey);
    if (filter) {
      filter.style.setProperty("--js-filter-content-color", jsContentColor);
      filter.style.setProperty("--js-filter-border-color", jsBorderColor);
      filter.style.setProperty(
        "--js-filter-background-color",
        jsBackgroundColor,
      );
    }
  }
  document.documentElement?.style.setProperty(
    "--js-filter-content-color",
    jsContentColor,
  );
  document.documentElement?.style.setProperty(
    "--js-filter-border-color",
    jsBorderColor,
  );
  document.documentElement?.style.setProperty(
    "--js-filter-background-color",
    jsBackgroundColor,
  );
};
