<template>
  <div class="FolderSelect">
    <el-tree
      :data="treeData"
      :props="defaultProps"
      highlight-current
      :default-expanded-keys="[0]"
      node-key="id"
      class="my-tree"
      ref="myTree"
      @node-click="handleNodeClick"
      :filter-node-method="filterNode"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span class="node-label">
          <svg-icon
            iconClass="wenjianjia"
            v-if="data.dataType == 'folder'"
            style="margin-right: 5px; font-size: 16px"
          ></svg-icon>
          {{ node.label }}
        </span>
      </span>
    </el-tree>

    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleNodeSure">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { workDirTree } from "@/api/directoryConfig";
export default {
  props: {
    currentNode: Object,
    isCompanyAdmin: Boolean,
    type: [String, Number],
  },
  data() {
    return {
      folderSelectDialogVisible: false,
      treeData: [],
      defaultProps: {
        children: "directoryList",
        label: "title",
        isLeaf: "hasChildList",
      },
      dataId: "",
    };
  },
  computed: {},
  methods: {
    filterNode(value, data) {
      let { dataType, id } = this.currentNode.data;
      if (dataType === "folder") {
        return data.id !== id;
      }
      return true;
    },
    fetchWorkTree() {
      let params = {
        type: this.type,
        fullTree: true,
        onlyFolder: true,
      };
      workDirTree(params).then((resp) => {
        this.treeData = [
          {
            title: "目录",
            id: 0,
            directoryList: resp.data.directoryList,
          },
        ];
        this.$nextTick(() => {
          this.$refs.myTree.filter();
        });
      });
    },
    handleNodeClick(data, node, el) {
      console.log(data);
      this.dataId = data.id;
      //this.$emit("change", data.id);
    },
    handleNodeSure() {
      this.$emit("change", this.dataId);
    },
    handleClose() {
      this.$emit("close");
    },
  },
  mounted() {
    this.fetchWorkTree();
  },
};
</script>
<style lang="less" scoped>
.FolderSelect {
  width: 100%;
  height: 400px;
  overflow: auto;
}
.dialog-footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
