<template>
  <div v-if="loading" v-loading.fullscreen.lock="loading"></div>
  <etl-edit
    v-else
    ref="etlEdit"
    :etl-list.sync="etlList"
    :etl-lines.sync="etlLines"
    :areaTree="areaTree"
    :areaProps="areaProps"
    style="background: #ffffff"
  ></etl-edit>
</template>
<script>
import { previewDocumentByIMM } from "@/utils/ali-oss";
import { fetchFormDataList, fetchFormList, getFormDetail } from "../form/api";
import { mapState } from "vuex";
import { departmentList } from "@/api/directoryConfig";
import { companyEmployeeList, employeeSearch } from "@/api/company";
import { contactListPage, addContact } from "@/api/contact";
import { groupList } from "@/api/group";
import { departmentListByUser, departmentTree } from "@/api/department";
import { getDataflow, saveDataflow, fetchStageData } from "./api";
import { fetchMetaFieldComponentList } from "@/api/form";

import EtlEdit from "./etl/index";
import "./etl/iconfont.scss";

export default {
  components: { EtlEdit },
  computed: { ...mapState("area", ["areaTree", "areaProps"]) },
  provide() {
    return {
      addContact,
      fetchFormList,
      getFormDetail,
      fetchFormDataList,
      previewDocumentByIMM,
      departmentList,
      companyEmployeeList,
      contactListPage,
      groupList,
      employeeSearch(params) {
        return employeeSearch({
          ...params,
          companyId: this.$store.state.user.currentCompanyId,
        });
      },
      departmentTree,
      departmentListByUser,
      fetchMetaFieldComponentList,
      getCurrentUser() {
        return this.$store.state.user;
      },
      loadCompany() {
        return Promise.resolve([
          {
            name: this.$store.state.user.currentCompanyName,
            key: "company-" + this.$store.state.user.currentCompanyId,
            nodeType: "company",
            isLeaf: false,
            id: this.$store.state.user.currentCompanyId,
          },
        ]);
      },
      saveDataflow: this.saveDataflow,
      fetchStageData,
      fetchDataList: fetchStageData,
      fetchAggreation: fetchStageData,
    };
  },

  data() {
    return {
      etlList: [],
      etlLines: [],
      loading: false,
    };
  },
  created() {
    this.loading = true;
    getDataflow(this.$route.query.id)
      .then((resp) => {
        if (resp.data.dataflow && resp.data.dataflow.flow) {
          resp.data.dataflow.flow.stages.forEach((item) => {
            item.top = parseFloat(item.top);
            item.left = parseFloat(item.left);
          });
          this.etlList = resp.data.dataflow.flow.stages;
          this.etlLines = resp.data.dataflow.flow.lines;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    saveDataflow() {
      let dataflowId = this.$route.query.id;
      saveDataflow({
        dataflowId,
        stageFlow: {
          stages: this.etlList,
          lines: this.etlLines,
        },
      }).then((res) => {
        this.$message.success("数据流保存成功");
      });
    },
  },
};
</script>
