<template>
  <div>
    <div class="attr-row">
      <div class="attr-label">关联表</div>
      <div @click.stop="open" slot="reference" class="el-meta-field" :class="isDisabled ? 'disabled' : ''">
        <el-tag v-if="tableType" size="mini">{{ tableType }}</el-tag>
        <div class="meta-text">
          {{ tableTitle }}
        </div>
        <i class="el-icon-arrow-down"></i>
      </div>
    </div>
    <data-source
      :visible.sync="visible"
      v-model="tableName"
      @changeSource="changeSource"
      :menus="menus"
    ></data-source>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/relyUtil";
import dataSource from "@/components/data-source";
export default {
  name: "attr-table-soruce",
  components: { dataSource },
  props: {
    value: String,
    idNotEquals: String,
    tableTitle: String,
    componentName: String,
  },
  computed: {
    tableType() {
      if (!isEmpty(this.value)) {
        if (this.value.indexOf("dataflow_") == 0) {
          return "数据流";
        } else if (this.value.indexOf("at_") == 0) {
          return "聚合表";
        }
        return "表单";
      }
      return "";
    },
    tableName: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.tableNames = val;
      },
    },
    isDisabled() {
      return this.componentName == "reference_data" && this.value == this.formId
    }
  },
  data() {
    return {
      visible: false,
      menus: undefined,
      formId: "",
      tableNames: "",
    };
  },
  created() {
    this.formId = this.$route.query.id;
    if (["reference_data_list"].includes(this.componentName)) {
      //关联多条
      this.menus = ["form", "aggregate_table"];
    } else if (["reference_data"].includes(this.componentName)) {
      //关联数据
      this.menus = [
        "form",
        "aggregate_table",
        "group_form",
        "group_aggregate_table",
      ];
      // this.menus = ["form"];
    } else if (this.componentName == "form_data") {
      // 表单设计的关联查询
      this.menus = [
        "form",
        "dataflow",
        "aggregate_table",
        "group_form",
        "group_dataflow",
        "group_aggregate_table",
      ];
    } else if (this.componentName == "summary_data") {
      // this.menus = ["form"];
    } else {
      // this.menus = ["form", "dataflow"];
    }
  },
  methods: {
    changeSource(data) {
      this.$emit("changeSource", this.tableNames, data);
    },
    open() {
      if(this.isDisabled){
        return
      }
      this.visible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.el-meta-field {
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  padding-right: 10px;
  border-radius: 5px;
  color: #606266;
  cursor: pointer;

  .meta-text {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}
.disabled{
  background: #f6f6f6;
}
</style>
