import { create, all } from 'mathjs'

const config = {
    epsilon: 1e-12,
    matrix: 'Matrix',
    number: 'BigNumber', // 可选值：number BigNumber
    precision: 64,
    predictable: false,
    randomSeed: null
}
const math = create(all, config);

/**
 * 求和(浮点数)
 */
export function sum() {
    let arr = [];
    let value;
    Object.values(arguments).forEach(item => {
        arr.push(math.bignumber(item));
    });
    if (arr.length) {
        value = math.sum(...arr).toNumber();
    }
    return value;
}

/**
 * 减法(浮点数)
 */
export function subtract() {
    let value;
    let arr = [];

    Object.values(arguments).forEach(item => {
        arr.push(item);
    });

    if (arr.length) {
        let str = arr.join("-")
        value = math.evaluate(str);
    }

    return value;
}

/**
 * 乘法(浮点数)
 */
export function product() {
    let value;
    let arr = [];

    Object.values(arguments).forEach(item => {
        arr.push(math.bignumber(item));
    });
    if (arr.length) {
        value = math.prod(...arr).toNumber();

    }
    return value;
}


/**
 * 除法(浮点数)
 * @returns 
 */
export function divide() {
    let value;

    if (arguments.length == 2) {
        value = math.divide(math.bignumber(arguments[0]), math.bignumber(arguments[1])).toNumber()

    }
    return value;
}