var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('el-popover',{attrs:{"placement":"bottom","width":"240","popper-class":"color-select","trigger":"click"}},[_c('div',{staticClass:"cont-div"},[_c('div',{staticClass:"title"},[_vm._v("颜色")]),_c('div',{staticClass:"content"},_vm._l((_vm.colorArr),function(sub,index){return _c('div',{key:index,staticClass:"content-item",style:('text-align:center;background-color:' + sub),on:{"click":function($event){return _vm.selectVal(sub)}}},[(_vm.myColor == sub)?_c('i',{staticClass:"el-icon-check",staticStyle:{"line-height":"30px"},style:(_vm.myColor == `var(--zgg-color-white)`
                  ? 'color:#000'
                  : 'color:#fff')}):_vm._e()])}),0),_c('div',[_c('div',{staticClass:"title"},[_vm._v("自定义颜色")]),_c('div',{staticClass:"content",staticStyle:{"justify-content":"left","padding":"0 6px"}},[_c('div',{staticClass:"color-picker",staticStyle:{"padding":"0 2px"}},[_c('el-color-picker',{staticClass:"form-color-picker",attrs:{"value":"#fff","size":"mini","popper-class":"custom-color-popper","show-alpha":true},on:{"change":_vm.changeCustom}})],1),_vm._l((_vm.customColors),function(sub,index){return _c('div',{key:index,staticClass:"content-item",style:('text-align:center;background-color:' + sub),on:{"click":function($event){return _vm.selectVal(sub)}}},[(_vm.myColor == sub)?_c('i',{staticClass:"el-icon-check",staticStyle:{"line-height":"30px"},style:(_vm.myColor == `var(--zgg-color-white)`
                    ? 'color:#000'
                    : 'color:#fff')}):_vm._e()])})],2)]),(_vm.isBackground)?[_c('div',{staticClass:"title"},[_vm._v("渐变")]),_c('div',{staticClass:"content"},_vm._l((_vm.transitionColorArr),function(item){return _c('div',{key:item,staticClass:"content-item",style:(`text-align:center;background:linear-gradient(${item})`),on:{"click":function($event){return _vm.selectGradient(item)}}},[(_vm.myColor == item)?_c('i',{staticClass:"el-icon-check",staticStyle:{"line-height":"30px","color":"#fff"}}):_vm._e()])}),0)]:_vm._e()],2),_c('div',{staticClass:"select-div father",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"input-div",style:(_vm.myColor && _vm.myColor.includes(',') && !_vm.myColor.includes('rgba')
              ? 'background:linear-gradient(' + _vm.myColor + ')'
              : 'background-color:' + _vm.myColor),on:{"click":function($event){return _vm.showSelect()}}}),_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.reset()}}}),_c('i',{staticClass:"el-icon-arrow-down",on:{"click":function($event){return _vm.showSelect()}}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }