<template>
    <div class="sign">
        <i class="el-icon-edit sign-edit-icon"></i>
        <div class="sign-text">手写签名</div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.sign{
    display: flex;
    align-items: center;
    border: solid 1px #E4E7ED;
    border-radius: 5px;
    padding: 0px 10px;
    width: max-content;
    .sign-edit-icon{
        color: #409eff;
        margin-right: 5px;
    }
    .sign-text{
        font-size: 12px;;
    }
}
</style>