<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 68px;
    "
  >
    <div class="label">钉钉通讯录同步参数设置</div>
    <div class="description">
      请将该单位钉钉开放平台中的AppKey和AppSecret填入以下字段，保存后请先复制回调地址、aes_key、签名token，填入钉钉开放平台后再开启自动同步
    </div>
    <div class="content">
      <el-form
        :model="dingConfig"
        label-position="left"
        label-width="auto"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="开启自动同步">
          <div style="display: flex; align-items: center">
            <el-switch
              v-model="dingConfig.enableSync"
              :disabled="!dingConfig.token"
            ></el-switch>
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="logVisible = true"
              >同步日志</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="AppKey" prop="appKey">
          <el-input
            v-model="dingConfig.appKey"
            placeholder="请输入"
            size="medium"
            :disabled="dingConfig.enableSync"
          ></el-input>
        </el-form-item>
        <el-form-item label="AppSecret" prop="appSecret">
          <el-input
            v-model="dingConfig.appSecret"
            placeholder="请输入"
            size="medium"
            :disabled="dingConfig.enableSync"
          ></el-input>
        </el-form-item>
        <template v-if="dingConfig.token">
          <el-form-item
            :label="item.label"
            v-for="item in disabledArr"
            :key="item.field"
          >
            <div style="display: flex; line-height: 36px">
              <el-input
                v-model="dingConfig[item.field]"
                disabled
                size="medium"
              ></el-input>
              <el-button
                size="mini"
                @click="copy(item.field)"
                style="margin-left: 15px"
                >复制</el-button
              >
            </div>
          </el-form-item>
        </template>
      </el-form>
      <el-button @click="back">取 消</el-button>
      <el-button type="primary" @click="saveDingUserConfig">保 存</el-button>
      <el-button type="primary" @click="manualDingSyncConfig"
        >立即同步</el-button
      >
    </div>
    <el-dialog
      title="同步日志"
      :visible.sync="logVisible"
      append-to-body
      destroy-on-close
      fullscreen
      @open="openLog"
    >
      <div>
        <el-table :data="logList" style="width: 100%">
          <el-table-column
            header-align="center"
            align="center"
            label="同步日期"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.startTime, "YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="开始时间"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.startTime, "HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="结束时间"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.endTime, "HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="更新方式"
          >
            <template slot-scope="{ row }">
              <span>{{
                row.updateType == "dingUpdate"
                  ? "自动更新"
                  : row.updateType == "manualUpdate"
                  ? "手动更新"
                  : row.updateType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作结果"
          >
            <template slot-scope="{ row }">
              <span v-text="row.success ? '成功' : '失败'"></span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="更新内容"
          >
            <template slot-scope="{ row }">
              <el-popover
                placement="left"
                width="400"
                popper-class="ding-update-detail"
                trigger="click"
              >
                <div style="white-space: pre-wrap">{{ row.content }}</div>
                <div style="white-space: pre-wrap; color: red">
                  {{ row.result }}
                </div>
                <el-button slot="reference" type="text">查看详情</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div style="height: 42px; background: #ffffff">
          <pagination
            style="float: right"
            :total="logPage.totalRow"
            :page.sync="logPage.pageNumber"
            :limit.sync="logPage.pageSize"
            @pagination="loadingLogList"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDingUserConfig,
  getDingUserSyncLog,
  manualDingSyncConfig,
  saveDingUserConfig,
} from "@/api/operationCenter";
import { isEmpty } from "lodash";
import moment from "moment";
import Pagination from "@/components/Pagination/index.vue";

export default {
  components: { Pagination },
  computed: {
    companyName() {
      return this.$store.getters.currentCompanyName;
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
  },
  data() {
    return {
      dingConfig: {},
      logPage: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      disabledArr: [
        {
          label: "回调地址",
          field: "url",
        },
        {
          label: "aes_key",
          field: "aesKey",
        },
        {
          label: "签名token",
          field: "token",
        },
      ],
      logList: [],
      logVisible: false,
      rules: {
        appKey: [{ required: true, message: "请输入appKey" }],
        appSecret: [{ required: true, message: "请输入appSecret" }],
      },
    };
  },
  async created() {
    await this.dingUserSyncConfig();
  },
  methods: {
    openLog() {
      this.loadingLogList();
    },
    getTime(value, format) {
      if (!isEmpty(value)) {
        return moment(value).format(format);
      }
    },
    loadingLogList() {
      getDingUserSyncLog({
        companyId: this.companyId,
        pageNumber: this.logPage.pageNumber,
        pageSize: this.logPage.pageSize,
      }).then((res) => {
        this.logList = res.data.page.list;
        this.logPage.pageNumber = res.data.page.pageNumber;
        this.logPage.totalPage = res.data.page.totalPage;
        this.logPage.totalRow = res.data.page.totalRow;
      });
    },
    copy(data) {
      let input = document.createElement("input");
      input.setAttribute("value", this.dingConfig[data]);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("Copy")) {
        this.$message({ type: "success", message: "复制到剪贴板" });
      }
      document.body.removeChild(input);
    },
    back() {
      this.$router.replace("/lowCode/unit-config");
    },
    manualDingSyncConfig() {
      this.saveDingUserConfig("manual");
    },
    saveDingUserConfig(update) {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        saveDingUserConfig({
          appKey: this.dingConfig.appKey,
          appSecret: this.dingConfig.appSecret,
          companyId: this.companyId,
          id: this.dingConfig.id,
          enableSync: this.dingConfig.enableSync,
        }).then((res) => {
          this.$message.success("保存成功");
          this.$set(this, "dingConfig", res.data.syncConfig);
          //是否立即同步
          if (update == "manual") {
            manualDingSyncConfig({
              companyId: this.companyId,
            }).then((res) => {
              this.$message.success("同步成功");
            });
          }
        });
      });
    },
    async dingUserSyncConfig() {
      await getDingUserConfig({
        companyId: this.companyId,
      }).then((res) => {
        if (!isEmpty(res.data)) this.dingConfig = res.data.syncConfig;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--font-text-primary, #172b4d);
}
.content {
  margin-top: 12px;
  padding: 16px;
  border-radius: 10px;
  background: #fff;
}
.description {
  font-size: 14px;
  line-height: 24px;
  color: #6b778c;
}
</style>
