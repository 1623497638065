<template>
    <div>
        <div class="attr-row">
            <div class="attr-label">关联表权限</div>
            <el-radio-group v-model="component.eventConfig.permissionType" v-if="component.componentName === 'button'">
                <el-radio :label="'ALL'">所有权限</el-radio>
                <el-radio :label="'APP_ROLE'">权限组权限</el-radio>
            </el-radio-group>
            <el-radio-group v-model="component.permissionType" v-else>
                <el-radio :label="'ALL'">所有权限</el-radio>
                <el-radio :label="'APP_ROLE'">权限组权限</el-radio>
            </el-radio-group>
            <div class="permission">
                <span v-if="permissionType === 'ALL'">用户访问数据源表单详情时，拥有所有权限</span>
                <span v-else>用户访问数据源表单详情时，仅拥有他在数据源表单所在权限组的权限</span>
            </div>
        </div>
        <!-- 关联查询是否可以行内编辑(todo: 该组件特有属性放在此处没有单独做成属性组件) -->
        <div class="attr-row" v-if="component.componentName === 'form_data'">
            <div class="attr-label">行内编辑</div>
            <el-checkbox v-model="component.inlineEdit">
                <span style="font-size: 12px">允许行内编辑</span>
            </el-checkbox>
        </div>
    </div>
  </template>
  <script>
  import { componentProps} from "./util";
  export default {
    name: "attr-permission-type",
    props: componentProps,
    computed: {
        permissionType(){
            if(this.component.componentName === 'button') {
                return this.component.eventConfig.permissionType || 'ALL';
            } else {
                return this.component.permissionType || 'ALL';
            }
        }
    }
  };
  </script>
  <style lang="scss" scoped>
  .permission{
    font-size: 12px;
    padding: 10px 0 0;
    color: #999
  }
  </style>
  