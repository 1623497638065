<template>
  <div class="flow-item">
    <div class="flow-header">设置执行动作</div>
    <div style="padding: 10px">
      <div style="margin-bottom: 10px">数据处理</div>
      <div class="flow-action">
        <div @click="addAction('form_data_create')" class="action">
          <div class="action-title">新增数据</div>
          <div class="action-desc">在目标表单中新增数据</div>
        </div>
        <div @click="addAction('form_data_update')" class="action">
          <div class="action-title">修改已有数据</div>
          <div class="action-desc">在目标表单中修改某些数据</div>
        </div>
        <div @click="addAction('form_data_delete')" class="action">
          <div class="action-title">删除已有数据</div>
          <div class="action-desc">在目标表单中删除某些数据</div>
        </div>
        <div @click="addAction('form_data_save')" class="action">
          <div class="action-title">修改或新增数据</div>
          <div class="action-desc">修改某些数据，若找不到则新增</div>
        </div>
      </div>
      <div style="margin-bottom: 10px">通知</div>
      <div class="flow-action">
        <div @click="addAction('notice_wechat')" class="action">
          <div class="action-title">发送公众号消息</div>
          <div class="action-desc">发送公众号消息到设置的用户</div>
        </div>
        <div @click="addAction('notice_sms')" class="action">
          <div class="action-title">发送短信</div>
          <div class="action-desc">发送短信到设置的用户</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    addAction: Function,
  },
};
</script>
<style lang="scss" scoped>
.flow-item {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  .flow-header {
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 40px;
    padding: 0 10px;
  }
  .flow-action {
    display: flex;
    flex-wrap: wrap;
    .action {
      width: 250px;
      margin: 0 5px 10px;
      padding: 10px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      cursor: pointer;
      -webkit-transition: all 218ms ease;
      transition: all 218ms ease;
      &:hover {
        border-color: #409eff;
      }
    }
    .action-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .action-desc {
      margin-top: 6px;
      color: #91a1b7;
      font-size: 12px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
