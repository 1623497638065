<template>
  <div>
    <div
      style="margin-bottom: 10px"
      v-if="nodeDataDetail && nodeDataDetail.result"
    >
      <div
        class="node-reslut"
        :class="nodeDataDetail.result.success ? 'node-success' : 'node-fail'"
      >
        <i
          :class="
            nodeDataDetail.result.success ? 'el-icon-success' : 'el-icon-error'
          "
        ></i>
        <span style="font-size: 14px; margin-left: 5px">{{
          nodeDataDetail.result.success ? "执行成功" : "执行失败"
        }}</span>
        <template v-if="!nodeDataDetail.result.success">
          <span style="font-size: 14px; margin-left: 5px"
            >{{ nodeDataDetail.result.msg }}
            {{ nodeDataDetail.result.exception }}</span
          >
        </template>
      </div>
      <div>
        <codemirror
          :options="cmOptions"
          :value="resultData"
          class="node-result-code"
        ></codemirror>
      </div>
    </div>

    <div
      v-if="relyResultList"
      class="node-oup-container"
      style="margin-bottom: 10px"
    >
      <div class="node-header">公式执行结果</div>
      <codemirror
        :options="cmOptions"
        :value="relyResultList"
        class="node-rely"
      ></codemirror>
    </div>

    <div class="node-oup-container">
      <div class="node-header">出参结果</div>
      <codemirror :options="cmOptions" :value="nodeOutputData"></codemirror>
    </div>
  </div>
</template>
<script>
import { getNodeWorkflowData } from "./api";
import "codemirror/theme/monokai.css";
import "codemirror/theme/idea.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/lint/lint.js";
import "codemirror/addon/lint/lint.css";
// 代码高亮
import "codemirror/addon/selection/active-line.js";

// 折叠
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";

export default {
  name: "rpa-workflow-node-data",
  inject: {
    getWorkflowDataId: {
      default: () => {},
    },
  },
  props: {
    nodeKey: String,
  },
  data() {
    return {
      nodeDataDetail: {},
      cmOptions: {
        theme: "idea", // 编辑器主题
        readOnly: true,
        autoRefresh: true,
        tabSize: 4, // tabsize默认为4
        styleActiveLine: true,
        lineNumbers: true,
        matchBrackets: true, // 括号匹配
        line: true,
        autoFormatOnLoad: true,
        mode: "application/json", // 选择代码语言
        lineWrapping: true, // 自动换行
        // theme: 'base16-light', // 主题根据需要自行配置

        foldGutter: true,
        gutters: [
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter",
          "CodeMirror-lint-markers",
        ],
      },
      resultData: "",
      nodeOutputData: "",
      relyResultList: "",
    };
  },
  created() {
    let workflowDataId = this.getWorkflowDataId();

    getNodeWorkflowData({
      nodeKey: this.nodeKey,
      workflowDataId,
    }).then((res) => {
      this.nodeDataDetail = res.data.nodeDataDetail;
      if (res.data.nodeDataDetail) {
        if (res.data.nodeDataDetail.result) {
          this.resultData = JSON.stringify(
            res.data.nodeDataDetail.result.data,
            null,
            4,
          );
        }
        if (res.data.nodeDataDetail.nodeOutputData) {
          this.nodeOutputData = JSON.stringify(
            res.data.nodeDataDetail.nodeOutputData,
            null,
            4,
          );
        }
        if (
          res.data.nodeDataDetail.relyResultList &&
          res.data.nodeDataDetail.relyResultList.length
        ) {
          this.relyResultList = JSON.stringify(
            res.data.nodeDataDetail.relyResultList,
            null,
            4,
          );
        }
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.node-reslut {
  line-height: 1.5;
  display: flex;
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 10px;
  flex-wrap: wrap;
  &.node-success {
    border: solid 1px #67c23a;
    background: linear-gradient(0deg, #f0f9eb, #f0f9eb),
      linear-gradient(0deg, #67c23a, #67c23a);
    i {
      color: #67c23a;
    }
  }
  &.node-fail {
    background: linear-gradient(0deg, #fef0f0, #fef0f0),
      linear-gradient(0deg, #f56c6c, #f56c6c);
    border: 1px solid #f56c6c;
    i {
      color: #f56c6c;
    }
  }
}
.node-result-code {
  border: solid 1px #dcdfe6;
  ::v-deep {
    .CodeMirror {
      height: 100px;
    }
  }
}
.node-rely {
  ::v-deep {
    .CodeMirror {
      height: 200px;
    }
  }
}
.node-oup-container {
  border: solid 1px #dcdfe6;
  border-radius: 4px;
  .node-header {
    font-size: 14px;
    padding: 10px;
    border-bottom: solid 1px #dcdfe6;
  }
}
</style>
