<template>
  <div>
    <div style="margin-bottom: 10px">
      <div>
        <el-tag
          :closable="!disabled"
          v-for="(item, index) in userRange.nodeUserFields"
          :key="item.nodeKey + item.name"
          @close="deleteField(index)"
          ><span v-text="getNodeTitle(item.nodeKey)"></span
          ><span v-text="item.title"></span
        ></el-tag>
      </div>
      <div :key="userKeys">
        <template v-for="(item, index) in userRange.userInfoList">
          <el-tag
            :key="item.id"
            :closable="!disabled"
            @close="delUser(index)"
            type="success"
            >{{ item.name }}</el-tag
          >
        </template>
      </div>
    </div>
    <div v-if="!disabled">
      <el-popover trigger="click">
        <el-button
          type="text"
          icon="el-icon-plus"
          slot="reference"
          :disabled="
            userRange.userInfoList.length > 0 ||
            userRange.nodeUserFields.length > 0
          "
          >添加人员</el-button
        >
        <div>
          <el-tree
            :props="treeProps"
            @node-click="nodeClick"
            :load="loadNode"
            :lazy="true"
          >
          </el-tree>
        </div>
      </el-popover>
    </div>

    <user-popup
      :key="userKeys"
      :visible.sync="visibleUser"
      v-model="userList"
      :tabs="['company']"
      :multi="false"
    ></user-popup>
  </div>
</template>
<script>
import { fetchMetaComponents } from "@/api/form";
import { fromNodesMixins } from "./rpa-utils";
import { userMixin } from "@/mixin/user-dept";
import { getRobotWorkflow } from "./api";
import { isEmpty } from "@/zgg-core/utils";
export default {
  mixins: [fromNodesMixins, userMixin],
  props: {
    userRange: Object,
    disabled: Boolean,
  },
  provide() {
    return {
      getCompanyId: () => {
        return this.currentCompanyId;
      },
    };
  },
  inject: {
    getRootParams: {
      default: () => [],
    },
  },
  data() {
    return {
      visibleUser: false,
      visible: false,
      treeProps: {
        label: (data) => {
          let str = data.title;
          if (data.dataSource == "node_data_field") {
            str += `【${data.nodeField.title}】`;
          } else if (data.formTitle) {
            str += `【${data.formTitle}】`;
          } else if (data.collectionTitle) {
            str += `【${data.collectionTitle}】`;
          }
          return str;
        },
        isLeaf: "leaf",
        currentCompanyId: "",
      },
      rootFlowParams: [],
    };
  },
  computed: {
    userList: {
      get() {
        return this.userRange.userInfoList;
      },
      set(val) {
        this.$set(this.userRange, "userInfoList", val);
        this.$set(
          this.userRange,
          "userIds",
          val.map((item) => item.id),
        );
      },
    },
    userKeys() {
      return this.userRange.userIds.join(",");
    },
  },
  created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
    this.currentCompanyId = this.$store.state.user.currentCompanyId;
    this.parentNodes = this.getCommitParentNodes();
  },
  methods: {
    delUser(index) {
      let id = this.userRange.userInfoList[index].id;
      let newIndex = this.userRange.userIds.findIndex((val) => id == val);
      let userInfoList = this._.cloneDeep(this.userRange.userInfoList);
      let userIds = this._.cloneDeep(this.userRange.userIds);
      userInfoList.splice(index, 1);
      userIds.splice(newIndex, 1);

      this.$set(this.userRange, "userInfoList", userInfoList);
      this.$set(this.userRange, "userIds", userIds);
    },
    deleteField(index) {
      let nodeUserFields = this.userRange.nodeUserFields;
      nodeUserFields.splice(index, 1);
    },
    getNodeTitle(key) {
      let obj = this.parentNodes.find((item) => item.key == key);
      if (obj) {
        return obj.title + ":";
      }
    },
    nodeClick(data, node) {
      if (
        this.userRange.userInfoList.length > 0 ||
        this.userRange.nodeUserFields.length > 0
      ) {
        this.$message.warning("只允许添加一个流程发起人");
        return;
      }
      if (node.isLeaf) {
        if (data.key == "root") {
          return;
        }
        if (data.type == "subflow_collection") {
          // 子流程

          return;
        }
        if (data.key == "company") {
          // 点击从单位添加
          this.visibleUser = true;
        } else {
          // 子节点
          let index = this.userRange.nodeUserFields.findIndex(
            (item) =>
              item.nodeKey == node.parent.data.key && item.name == data.name,
          );
          if (index == -1) {
            let nodeUserFields = this.userRange.nodeUserFields;
            nodeUserFields.push({
              nodeKey: node.parent.data.key,
              name: data.name,
              title: data.title,
              componentName: data.componentName,
              collection:
                node.parent.data.formId || node.parent.data.collection,
            });
          }
        }
      }
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        let list = [
          {
            title: "从单位添加",
            key: "company",
            leaf: true,
          },
        ];
        if (this.rootFlowParams.length) {
          list.push({
            title: "本流程参数",
            key: "root",
          });
        }

        let fromNodes = this.getFromNodes();
        fromNodes = fromNodes.filter(
          (item) =>
            (item.type == "subflow_collection" &&
              item.triggerOneTimes &&
              item.syncExec) ||
            item.dataSource == "node_data_field",
        );

        for (let i = 0; i < fromNodes.length; i++) {
          let node = fromNodes[i];
          if (list.findIndex((item) => item.key == node.key) == -1) {
            list.push({
              ...node,
            });
          }
        }

        resolve(list.concat(this.parentNodes));
      } else {
        if (node.data.key == "root") {
          let list = this._.cloneDeep(this.rootFlowParams);
          list = list.filter((item) =>
            ["user_select", "mobile_input"].includes(item.componentName),
          );
          list.forEach((item) => {
            item.leaf = true;
            item.key = item.name;
          });
          resolve(list);
        } else if (node.data.type == "subflow_collection") {
          getRobotWorkflow({ robotId: node.data.robotId }).then((res) => {
            let flowParams = res.data.workflow?.rpaFlow?.config?.flowParams;

            if (flowParams) {
              flowParams = flowParams.filter((item) =>
                ["user_select", "mobile_input"].includes(item.componentName),
              );
              flowParams.forEach((item) => {
                item.leaf = true;
                item.key = item.name;
              });

              resolve(flowParams);
            } else {
              resolve([]);
            }
          });
        } else {
          let formId = node.data.formId || node.data.collection;
          if (node.data.dataSource == "node_data_field") {
            formId =
              node.data.nodeField.referenceCollection ||
              node.data.nodeField.collection;
          }
          fetchMetaComponents(formId).then((res) => {
            let list = [];
            if (
              node.data.dataSource == "node_data_field" &&
              isEmpty(node.data.nodeField.referenceCollection)
            ) {
              // 非实体子表单节点
              let component = res.data.list.find(
                (item) => item.name == node.data.nodeField.name,
              );
              if (component) {
                list = component.components;
              }
            } else {
              list = res.data.list;
            }
            list = list.filter((item) =>
              ["user_select", "mobile_input"].includes(item.componentName),
            );
            list.forEach((item) => {
              item.leaf = true;
            });
            resolve(list);
          });
        }
      }
    },
  },
};
</script>
