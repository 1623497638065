import { render, staticRenderFns } from "./ding-user-sync-config.vue?vue&type=template&id=21b36ac6&scoped=true"
import script from "./ding-user-sync-config.vue?vue&type=script&lang=js"
export * from "./ding-user-sync-config.vue?vue&type=script&lang=js"
import style0 from "./ding-user-sync-config.vue?vue&type=style&index=0&id=21b36ac6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b36ac6",
  null
  
)

export default component.exports