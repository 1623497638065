<template>
  <el-dialog
    title="基本信息"
    :visible.sync="visible"
    width="500px"
    :append-to-body="true"
    destroy-on-close
    @closed="closed"
  >
    <el-form
      style="padding: 20px"
      v-if="visible && robot"
      ref="form"
      :model="robot"
      size="small"
    >
      <el-form-item
        label="名称"
        prop="title"
        :rules="[{ required: true, message: '请输入名称' }]"
      >
        <el-input v-model="robot.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="说明" prop="remark">
        <el-input
          type="textarea"
          :rows="4"
          v-model="robot.remark"
          placeholder="请输入备注说明"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getRobotDetail, renameRobot } from "./api";
export default {
  data() {
    return {
      visible: false,
      robot: null,
    };
  },
  methods: {
    closed() {
      this.robot = null;
    },
    open(robotId) {
      getRobotDetail({
        robotId,
      }).then((res) => {
        this.robot = res.data.robot;
        this.visible = true;
      });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        renameRobot({
          robotId: this.robot.id,
          title: this.robot.title,
          remark: this.robot.remark,
        }).then((res) => {
          this.$emit("change", {
            robotId: this.robot.id,
            id: res.data.bizDir.id,
            title: this.robot.title,
            remark: this.robot.remark,
          });
          this.$message.success("保存成功");
          this.visible = false;
        });
      });
    },
  },
};
</script>
