export default {
  data() {
    return {
      regionBox: null,
      regionMove: false,
    };
  },
  methods: {
    regionMousedown(event) {
      if (event.buttons !== 1) {
        // 不是鼠标左右按下的则不执行下一步操作
        return;
      }
      this.$set(this, "regionBox", {
        startX: event.clientX / this.zoom,
        startY: event.clientY / this.zoom,
      });

      this.regionMove = false;
      document.addEventListener("mousemove", this.regionMousemove);
      document.addEventListener("mouseup", this.regionMouseup);
    },
    regionMousemove(event) {
      let isIE9 =
        navigator.appName == "Microsoft Internet Explorer" &&
        parseInt(
          navigator.appVersion
            .split(";")[1]
            .replace(/[ ]/g, "")
            .replace("MSIE", ""),
        ) == 9;
      if (!isIE9 && event.buttons != 1) {
        // 鼠标左键没有按住

        return;
      }

      if (!this.regionBox) {
        // 没有框选对象，不再继续
        return;
      }

      let endX = event.clientX / this.zoom;
      let endY = event.clientY / this.zoom;
      let regionBox = this._.cloneDeep(this.regionBox);
      let distanceX = Math.abs(endX - regionBox.startX);
      let distanceY = Math.abs(endY - regionBox.startY);
      if (distanceX < 10 && distanceY < 10) {
        // 偏移太小了不考虑
        return;
      }

      let { left, top } = this.$refs.etlWrapper.getBoundingClientRect();
      regionBox.endX = endX;
      regionBox.endY = endY;
      regionBox.width = distanceX;
      regionBox.height = distanceY;
      regionBox.left = Math.min(regionBox.startX, endX) - left;
      regionBox.top = Math.min(regionBox.startY, endY) - top;
      let x0 = regionBox.left;
      let y0 = regionBox.top;
      let x1 = Math.max(regionBox.startX, endX) - left;
      let y1 = Math.max(regionBox.startY, endY) - top;

      regionBox.points = { x0, y0, x1, y1 };
      this.$set(this, "regionBox", regionBox);
      this.regionMove = true;
    },
    regionMouseup() {
      document.removeEventListener("mousemove", this.regionMousemove);
      document.removeEventListener("mouseup", this.regionMouseup);
      if (this.regionMove) {
        this.checkupNodes();
      }
      this.regionBox = null;
    },
    checkupNodes() {
      let { x0, y0, x1, y1 } = this.regionBox.points;
      let width = 160;
      let height = 40;
      // 判断两条线段是否相交

      this.etlList.forEach((item, index) => {
        let checked = true;
        let points = [
          { x: item.left, y: item.top },
          { x: item.left, y: item.top + height },
          { x: item.left + width, y: item.top + height },
          { x: item.left + width, y: item.top },
        ];

        points.forEach((point) => {
          if (!(point.x > x0 && point.x < x1 && point.y > y0 && point.y < y1)) {
            checked = false;
          }
        });
        item.checked = checked;
      });
    },
    checkCross(line1, line2) {
      let p1 = line1[0],
        p2 = line1[1],
        p3 = line2[0],
        p4 = line2[1];
      let x1 = p1.x,
        y1 = p1.y,
        x2 = p2.x,
        y2 = p2.y,
        x3 = p3.x,
        y3 = p3.y,
        x4 = p4.x,
        y4 = p4.y;
      if (
        !(
          Math.min(x1, x2) <= Math.max(x3, x4) &&
          Math.min(y3, y4) <= Math.max(y1, y2) &&
          Math.min(x3, x4) <= Math.max(x1, x2) &&
          Math.min(y1, y2) <= Math.max(y3, y4)
        )
      ) {
        return false;
      }
      let u, v, w, z;
      u = (x3 - x1) * (y2 - y1) - (x2 - x1) * (y3 - y1);
      v = (x4 - x1) * (y2 - y1) - (x2 - x1) * (y4 - y1);
      w = (x1 - x3) * (y4 - y3) - (x4 - x3) * (y1 - y3);
      z = (x2 - x3) * (y4 - y3) - (x4 - x3) * (y2 - y3);
      let r = u * v <= 0.00000001 && w * z <= 0.00000001;

      return u * v <= 0.00000001 && w * z <= 0.00000001;
    },
    checkLine(points) {
      let checkCross = this.checkCross;
      let { x0, y0, x1, y1 } = this.regionBox.points;
      let line1 = [
        { x: x0, y: y0 },
        { x: x0, y: y1 },
      ];
      let line2 = [
        { x: x0, y: y1 },
        { x: x1, y: y1 },
      ];
      let line3 = [
        { x: x1, y: y1 },
        { x: x1, y: y0 },
      ];
      let line4 = [
        { x: x1, y: y0 },
        { x: x0, y: y0 },
      ];
      let L1 = [points[0], points[1]],
        L2 = [points[1], points[2]],
        L3 = [points[2], points[3]],
        L4 = [points[3], points[0]];
      let c1 =
        checkCross(L1, line1) ||
        checkCross(L1, line2) ||
        checkCross(L1, line3) ||
        checkCross(L1, line4);
      if (c1) {
        return true;
      }
      c1 =
        checkCross(L2, line1) ||
        checkCross(L2, line2) ||
        checkCross(L2, line3) ||
        checkCross(L2, line4);
      if (c1) {
        return true;
      }
      c1 =
        checkCross(L3, line1) ||
        checkCross(L3, line2) ||
        checkCross(L3, line3) ||
        checkCross(L3, line4);
      if (c1) {
        return true;
      }
      c1 =
        checkCross(L4, line1) ||
        checkCross(L4, line2) ||
        checkCross(L4, line3) ||
        checkCross(L4, line4);
      return c1;
    },
  },
};
