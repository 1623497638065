<template>
  <el-form
    ref="form"
    :model="form"
    :label-position="formComponent.labelPosition"
    :style="!isInclude ? 'margin: 10px 20px' : ''"
    :label-width="`${formComponent.labelWidth}px`"
    class="cust-form"
    v-if="formComponent && formComponent.components"
  >
    <template v-for="(item, index) in formComponent.components">
      <form-tpl-item
        v-show="!item.hidden"
        :isShowComponent="isShowComponent"
        :key="index"
        :cols="formComponent.cols"
        :form="form"
        :item="item"
        :source-list="sourceList"
        :area-tree="areaTree"
        formType="add"
        @changeFormData="changeFormData"
        @getMetaOptions="getMetaOptions"
      ></form-tpl-item>
    </template>
  </el-form>
</template>
<script>
import test from "@/utils/test";
import { fetchFormDataList } from "../api";
export default {
  props: {
    areaTree: Array,
    formComponent: Object,
    isInclude: Boolean,
  },
  data() {
    return {
      form: {},
      sourceList: {},
    };
  },
  methods: {
    closed() {
      this.$set(this, "form", {});
    },
    submit() {
      this.$refs.form.validate((valid, errors) => {
        console.log("tag", valid, errors);
      });
    },

    changeFormData({ name, value, type, callback }) {
      if (type == "init") {
        this.$set(this.form, name, value);
      } else {
        let form = this._.cloneDeep(this.form);
        form[name] = value;
        this.$set(this, "form", form);
      }
      if (typeof callback === "function") {
        this.$nextTick(() => {
          callback();
        });
      }
    },
    getMetaOptions(data) {
      if (this.loadingList && this.loadingList[data.collection]) {
        // 关联表数据正在加载中，等待数据回来
        return;
      }
      if (
        this.sourceList[data.collection] &&
        this.sourceList[data.collection].length
      ) {
        // 数据已存在
        return;
      }

      fetchFormDataList(data).then((res) => {
        this.$set(this.sourceList, data.collection, res.data.page.list);
      });
    },

    isShowComponent(node) {
      let isShow = true;
      let fieldDisplayRules = this.formComponent.fieldDisplayRules;
      if (fieldDisplayRules && fieldDisplayRules.length) {
        let obj = fieldDisplayRules.find(
          (item) =>
            item.displayFieldKeys.findIndex((field) => field.key == node.key) >=
            0,
        );
        if (obj) {
          let arr = [];
          obj.conditions.forEach((item) => {
            let info = this.getFieldInfo(item.key);
            if (!info) return;
            let value = this.form[info.name];
            let result;
            if (test.isEmpty(value)) {
              result = item.oper == "nil";
            } else {
              if (item.oper == "eq") {
                if (info.componentName == "checkbox_group") {
                  result =
                    value.length == item.value.length &&
                    value.findIndex((val) => !item.value.includes(val)) == -1;
                } else {
                  result = value == item.value;
                }
              } else if (item.oper == "neq") {
                if (info.componentName == "checkbox_group") {
                  result = !(
                    value.length == item.value.length &&
                    value.findIndex((val) => !item.value.includes(val)) == -1
                  );
                } else {
                  result = value != item.value;
                }
              } else if (item.oper == "like") {
                result = value.indexOf(item.value) >= 0;
              } else if (item.oper == "nlike") {
                result = value.indexOf(item.value) == -1;
              } else if (item.oper == "gt") {
                let value2 = item.value;
                if (test.number(value)) {
                  value = parseFloat(value);
                }
                if (test.number(value2)) {
                  value2 = parseFloat(value2);
                }
                result = value > value2;
              } else if (item.oper == "gte") {
                let value2 = item.value;
                if (test.number(value)) {
                  value = parseFloat(value);
                }
                if (test.number(value2)) {
                  value2 = parseFloat(value2);
                }

                result = value >= value2;
              } else if (item.oper == "lt") {
                let value2 = item.value;
                if (test.number(value)) {
                  value = parseFloat(value);
                }
                if (test.number(value2)) {
                  value2 = parseFloat(value2);
                }
                result = value < value2;
              } else if (item.oper == "lte") {
                let value2 = item.value;
                if (test.number(value)) {
                  value = parseFloat(value);
                }
                if (test.number(value2)) {
                  value2 = parseFloat(value2);
                }
                result = value <= value2;
              } else if (item.oper == "in") {
                result = item.value.includes(value);
              } else if (item.oper == "nin") {
                result = !item.value.includes(value);
              } else if (item.oper == "containAny") {
                result =
                  item.value.findIndex((val) => value.includes(val)) >= 0;
              } else if (item.oper == "contain") {
                result =
                  value.filter((val) => item.value.includes(val)).length ==
                  item.value.length;
              } else if (item.oper == "belong") {
                // console.log("belong", value, item.value);
                if (item.value.length == 1) {
                  result = value.provinceId == item.value[0];
                } else if (item.value.length == 2) {
                  result = value.cityId == item.value[1];
                } else if (item.value.length == 3) {
                  result = value.countyId == item.value[2];
                }
              } else if (item.oper == "nobelong") {
                if (item.value.length == 1) {
                  result = value.provinceId != item.value[0];
                } else if (item.value.length == 2) {
                  result = value.cityId != item.value[1];
                } else if (item.value.length == 3) {
                  result = value.countyId != item.value[2];
                }
              } else if (item.oper == "nil") {
                result = false;
              } else if (item.oper == "nnil") {
                result = true;
              }
            }
            arr.push(!!result);
          });

          if (obj.conditionRel == "and") {
            // 所有
            isShow = arr.every((val) => val == true);
          } else {
            // 任一
            isShow = arr.some((val) => val == true);
          }
        }
      }

      return isShow;
    },
    getFieldInfo(key) {
      let info;
      for (
        let index = 0;
        index < this.formComponent.components.length;
        index++
      ) {
        const element = this.formComponent.components[index];
        if (element.key == key) {
          info = {
            name: element.name,
            componentName: element.componentName,
          };
          break;
        }
        if (element.componentName == "tab") {
          for (let j = 0; j < element.components.length; j++) {
            const tab = element.components[j];
            for (let k = 0; k < tab.components.length; k++) {
              const child = tab.components[k];
              if (child.key == key) {
                info = {
                  name: child.name,
                  componentName: child.componentName,
                };

                break;
              }
            }
            if (info) {
              break;
            }
          }
        }
        if (info) {
          break;
        }
      }
      return info;
    },
  },
};
</script>
<style lang="scss" scoped>
.cust-form {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  &::after {
    display: block;
    content: " ";
    clear: both;
  }

  ::v-deep {
    .un-label {
      margin: 0px !important;
      //padding: 0px !important;
      .el-form-item__content {
        margin: 0px !important;
        //padding: 0px !important;
      }
    }
    .fix-top {
      .el-form-item__label {
        width: max-content;
        float: none;
      }
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
      padding: 5px;
      float: left;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
}

.custom-table {
  > &.el-table {
    th {
      line-height: 1.5;
    }
  }
}

.sub-upload {
  width: 160px;
  height: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  border: solid 1px #e4e7ed;
  border-radius: 5px;

  .el-icon-upload {
    color: #c0c4cc;
    font-size: 24px !important;
  }
}
</style>
