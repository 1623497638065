<template>
  <div>
    <div class="row">
      <!-- 列数 -->
      <div class="label">列数</div>
      <div class="flex">
        <el-input
          v-model="form.cols"
          placeholder=""
          @input="changeNumber('cols', $event)"
          size="mini"
        ></el-input>
      </div>
    </div>
    <!-- 标签位置 -->
    <div class="row">
      <div class="label">标签位置</div>
      <div class="flex">
        <el-select v-model="form.labelPosition" placeholder="" size="mini">
          <el-option label="顶部对齐" value="top"></el-option>
          <el-option label="左对齐" value="left"></el-option>
          <el-option label="右对齐" value="right"></el-option>
        </el-select>
      </div>
    </div>
    <!-- 标签宽度 -->
    <div class="row">
      <div class="label">标签宽度</div>
      <div class="flex">
        <el-input
          v-model="form.labelWidth"
          placeholder=""
          @input="changeNumber('labelWidth', $event)"
          size="mini"
        ></el-input>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    form: Object,
  
  },

  data() {
    return {
    
    
    };
  },

  methods: {
  
   
    changeNumber(key, value) {
      let num = value.replace(/[^\d]/g, "");
      setTimeout(() => {
        this.form[key] = num;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 10px;
  .label {
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.flex {
  flex: 1;
}
.v-row {
  display: flex;
  align-items: center;
  padding: 4px 0px;
  .v-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
  .v-icon {
    display: flex;
    align-items: center;
    i {
      margin-left: 8px;
    }
  }
}
</style>