<template>
  <div>
    <zgg-filter
      :componentList="componentList"
      :dataFilter="condition"
      :disabled="disabled"
    >
      <template slot-scope="{ field }">
        <div class="filter-row">
          <!-- 选择组件 -->
          <field-source
            :componentList="componentList"
            :field="field"
            :formId="formId"
            style="margin-right: 10px"
            :valueType="field.valueType"
            :abilityKey="abilityKey"
          ></field-source>

          <!-- 操作符 -->
          <el-select
            v-model="field.oper"
            size="small"
            :disabled="!field.key || disabled"
            placeholder="请选择"
            style="width: 140px; margin-right: 10px"
            @change="changeOper(field, $event)"
          >
            <el-option
              v-for="opt in getOperQuerys(field)"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            >
            </el-option>
          </el-select>
          <!-- 条件值 -->
          <div class="row-flex">
            <template v-if="field.oper != 'nil' && field.oper != 'nnil'">
              <field-value-type
                :lockValueType="
                  field.componentName == 'cascade_data' ? true : lockValueType
                "
                :field="field"
              ></field-value-type>
              <el-select
                v-if="field.valueType == 'tableField'"
                v-model="field.value"
                placeholder="请选择"
                size="small"
                class="row-flex"
                :disabled="disabled"
                clearable
              >
                <el-option
                  v-for="item in getQueryComponents(field)"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <query-input
                v-else
                :field="field"
                :areaTree="areaTree"
                :areaProps="areaProps"
                :componentList="componentList"
                :disabled="!field.key || disabled"
                style="flex: 1; margin-right: 10px"
                v-model="field.value"
              ></query-input>
            </template>
          </div>
        </div>
      </template>
    </zgg-filter>
  </div>
</template>
<script>
import {
  checkFieldAbility,
  operQuerys,
} from "@zgg-core-utils/component-white-list";
import { isEmpty } from "@zgg-core-utils/utils";
import fieldValueType from "./field-value-type";
import { mapState } from "vuex";
import { companyEmployeeList, employeeSearch } from "@/api/company";
import { departmentList } from "@/api/directoryConfig";
import { contactListPage, addContact } from "@/api/contact";
import { groupList } from "@/api/group";
import { departmentTree } from "@/api/department";

export default {
  provide() {
    return {
      groupList,
      contactListPage,
      departmentList,
      departmentTree,
      addContact,
      companyEmployeeList,
      employeeSearch(params) {
        return employeeSearch({
          ...params,
          companyId: this.$store.state.user.currentCompanyId,
        });
      },
      getCurrentUser() {
        return this.$store.state.user;
      },
      loadCompany() {
        return Promise.resolve([
          {
            name: this.$store.state.user.currentCompanyName,
            key: "company-" + this.$store.state.user.currentCompanyId,
            nodeType: "company",
            isLeaf: false,
            id: this.$store.state.user.currentCompanyId,
          },
        ]);
      },
    };
  },
  components: { fieldValueType },
  props: {
    /** 过滤条件对象，.sync */
    condition: Object,
    /** 过滤条件的组件源 */
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    /** 添加按钮文件 */
    btnText: {
      type: String,
      default() {
        return "添加条件";
      },
    },
    /** 锁定值类型，如果锁定了值类型就不能切换值类型 */
    lockValueType: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /** 过滤值类型:tableField-表单字段,custom-自定义*/
    valueType: {
      type: String,
      default() {
        return "tableField";
      },
    },
    /** 值类型的组件列表 */
    valueOfComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
    formId: String,
    abilityKey: String,
    disabled: Boolean,
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
  },
  data() {
    return {
      /** 过滤值类型列表 */
      valueTypeList: [
        {
          label: "当前表单",
          value: "tableField",
        },
        {
          label: "自定义",
          value: "custom",
        },
      ],
    };
  },
  methods: {
    getQueryComponents(field) {
      let arr = checkFieldAbility(
        {
          componentName: field.componentName,
          name: field.name,
        },
        "filterComponents",
      );
      if (
        ["in", "nin"].includes(field.oper) &&
        field.componentName == "user_select"
      ) {
        arr.push("user_list_select");
      } else if (
        ["contain", "containAny"].includes(field.oper) &&
        field.componentName == "user_list_select"
      ) {
        arr.push("user_select");
      } else if (
        ["in", "nin"].includes(field.oper) &&
        field.componentName == "department_select"
      ) {
        arr.push("department_list_select");
      } else if (
        ["contain", "containAny"].includes(field.oper) &&
        field.componentName == "department_list_select"
      ) {
        arr.push("department_select");
      } else if (["in", "nin"].includes(field.oper)) {
        arr = arr.concat("select_checkbox", "checkbox_group");
      } else if (["contain", "containAny"].includes(field.oper)) {
        if (
          ["select_checkbox", "checkbox_group"].includes(field.componentName)
        ) {
          arr = [
            "select",
            "select_checkbox",
            "radio_group",
            "checkbox_group",
            "input",
          ];
        }
      }
      return this.componentList.filter((item) =>
        arr.includes(item.componentName),
      );
    },
    /** 获取组件的查询操作符 */
    getOperQuerys(item) {
      if (!item.key) {
        return [];
      }
      let list = checkFieldAbility(item, "oper");
      if (!isEmpty(list)) {
        return operQuerys.filter((item) => list.includes(item.value));
      } else {
        return [];
      }
    },

    changeOper(item, val) {
      if (["nil", "nnil"].includes(val)) {
        item.valueType =
          item.componentName == "reference_data" ? "tableField" : "custom";
      } else {
        item.valueType = "custom";
      }
      item.value = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  .row-flex {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}
</style>
