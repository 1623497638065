<template>
  <el-dialog
    title="新建表单"
    :visible.sync="visible"
    width="800px"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
  >
    <div class="import-data_dialog--container" v-loading.lock="loading">
      <el-empty
        description="当前视图有数据正在导入，稍后再试"
        v-if="hasImportingTask"
      ></el-empty>
      <template v-else>
        <div class="step-wrap">
          <el-steps :active="active" finish-status="success" align-center>
            <el-step
              :title="step"
              :key="index"
              v-for="(step, index) in stepEnums"
            ></el-step>
          </el-steps>
        </div>
        <div
          class="step-content__wrap"
          style="height: unset"
          v-show="active == 1"
        >
          <ul class="import-tip">
            <li>
              <div class="tip"></div>
              <span>不能存在合并的单元格</span>
            </li>
            <li>
              <div class="tip"></div>
              <span> 支持 2MB 以内的xls、xlsx格式文件</span>
            </li>
            <li>
              <div class="tip"></div>
              <span
                >文件中数据不能超过50000行、200列（若导入存在部门成员字段，则不能超过10000行、200列）</span
              >
            </li>
          </ul>
          <div>
            <zggUpload
              ref="fileUpload"
              :showFileList="false"
              accept=".xls,.xlsx"
              @success="handleUpLoadChange"
              @change="onUploadFileListChange"
              :beforeAvatarUpload="beforeAvatarUpload"
              :limit="1"
              :multiple="false"
            ></zggUpload>
          </div>
        </div>
        <div class="step-content__wrap" v-show="active == 2">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              height: 50px;
            "
          >
            <span> 工作表 </span>
            <div style="width: 190px; padding: 0 10px">
              <el-select
                v-model="currentSheetName"
                placeholder="请选择"
                size="small"
                @change="changeSheetName"
              >
                <el-option
                  v-for="(item, index) in sheetNameList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <span>
              点击任意一行可将其设置为标题行，标题行之前的数据不导入。
            </span>
          </div>
          <el-empty
            description="该表格内容为空，请切换工作表或返回上一步重新选择Excel表格"
            v-if="!hasImportData"
            style="flex: 1"
          ></el-empty>
          <el-table
            v-else
            :data="sheetDataList"
            size="mini"
            border
            :show-header="false"
            :highlight-current-row="true"
            :row-style="rowStyle"
            ref="sheetDataTableRef"
            height="200px"
            @row-click="previewTableRowClick"
            :cell-style="previewCellStyle"
            :row-class-name="tableRowClassName"
            :span-method="objectSpanMethod"
          >
            <el-table-column align="center" min-width="120px">
              <template>
                <span class="set-header-row"> 标题行 </span>
              </template>
            </el-table-column>
            <!--          过滤掉第一列的数据-->
            <template
              v-for="(item, index) in sheetDataColumnKeys.filter(
                (item) => item > 0,
              )"
            >
              <el-table-column
                :prop="item"
                align="center"
                min-width="170px"
                :key="index"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    effect="light"
                    :content="scope.row[item] ? scope.row[item].value + '' : ''"
                    placement="bottom-end"
                    :enterable="false"
                    :open-delay="700"
                    popper-class="custom-tooltip"
                    :visible-arrow="false"
                  >
                    <span class="column-value">{{
                      scope.row[item] ? scope.row[item].value : ""
                    }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="bottom-tool">
            <el-button style="margin-top: 12px" @click="pre">上一步 </el-button>
            <el-button
              style="margin-top: 12px"
              @click="next"
              type="primary"
              :disabled="!hasImportData"
              >下一步
            </el-button>
          </div>
        </div>
        <div class="step-content__wrap" v-show="active == 3">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              height: 50px;
            "
          >
            <div style="display: flex; flex-direction: row">
              <span style="width: 90px; line-height: 28px">表单名称:</span>
              <el-input v-model="formName" size="mini"></el-input>
            </div>
            <span
              class="import-count"
              :class="importColumnCount == allColumnCount ? 'import-all' : ''"
            >
              {{ "导入" + importColumnCount + "列/共" + allColumnCount + "列" }}
            </span>
          </div>
          <el-table
            :data="sheetDataFormSettingTableList"
            size="mini"
            border
            :show-header="false"
            :highlight-current-row="true"
            :row-style="rowStyle"
            ref="sheetDataTableFormSettingRef"
            height="200px"
            :cell-style="cellStyle"
            :span-method="objectSpanMethod"
          >
            <template v-for="(item, index) in sheetDataColumnKeys2">
              <el-table-column
                :prop="item"
                align="center"
                :min-width="index == 0 ? 100 : 180"
                :key="index"
              >
                <template slot-scope="scope">
                  <!--标题行及表单字段行-->
                  <div
                    v-if="scope.$index < 2 && item != 0"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 4px;
                    "
                  >
                    <el-input
                      v-if="scope.$index == 0"
                      v-model="scope.row[item].value"
                      size="mini"
                    ></el-input>
                    <el-select
                      v-if="scope.$index == 1"
                      v-model="scope.row[item].componentName"
                      placeholder="请选择"
                      size="mini"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in fieldList"
                        :key="index"
                        :label="item.title"
                        :value="item.componentName"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <el-tooltip
                    v-else
                    effect="light"
                    :content="scope.row[item] ? scope.row[item].value + '' : ''"
                    placement="bottom-end"
                    :enterable="false"
                    :open-delay="700"
                    popper-class="custom-tooltip"
                    :visible-arrow="false"
                  >
                    <span class="column-value">{{
                      scope.row[item] ? scope.row[item].value : ""
                    }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="bottom-tool">
            <el-checkbox v-model="isImportData" style="margin: 20px 500px 0 0"
              >复制数据</el-checkbox
            >
            <el-button style="margin-top: 12px" @click="pre">上一步 </el-button>
            <el-button
              style="margin-top: 12px"
              type="primary"
              @click="importData"
              >导入
            </el-button>
          </div>
        </div>
        <div class="step-content__wrap" v-show="active == 4">
          <el-result style="flex: 1">
            <template slot="icon">
              <span
                v-if="hasImportSuccess"
                class="el-icon-success edit-status-icon"
                style="color: #52bd94; font-size: 48px"
              ></span>
              <span
                v-else
                class="el-icon-error edit-status-icon"
                style="color: red; font-size: 48px"
              ></span>
            </template>
            <template slot="title">
              <span v-if="hasImportSuccess">创建表单成功</span>
              <span v-else>创建表单失败，请联系后台处理</span>
            </template>
            <template slot="extra">
              <el-button type="primary" size="medium" @click="visible = false"
                >完成</el-button
              >
            </template>
          </el-result>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import zggUpload from "@/components/Upload/zgg-upload.vue";
import {
  getComponentNameList,
  readExcelSheetData,
  readExcelSheetName,
  doImport,
} from "@/views/lowCode/form/api";

export default {
  name: "addFormByExcel",
  components: { zggUpload },
  mounted() {},
  computed: {
    hasImportData() {
      return this.sheetDataList.length != 0;
    },
    hasImportingTask() {
      return false;
    },
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
    sheetDataColumnKeys() {
      let firstData = this.sheetDataList[0];
      firstData && delete firstData.index;
      return firstData ? Object.keys(firstData) : [];
    },
    sheetDataColumnKeys2() {
      let firstData = this.sheetDataFormSettingTableList[0];
      firstData && delete firstData.index;
      return firstData ? Object.keys(firstData) : [];
    },
    allColumnCount() {
      return (
        this.sheetDataFormSettingTableList[0] &&
        this.sheetDataFormSettingTableList[0].filter((row) => !row.subrow)
          .length - 1
      );
    },
    importColumnCount() {
      return (
        this.sheetDataFormSettingTableList[1] &&
        this.sheetDataFormSettingTableList[1]
          .filter((row) => !row.subrow)
          .filter((item) => item.componentName && item.componentName != "none")
          .length
      );
    },
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dirId: {
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      active: 1,
      loading: false,
      stepEnums: ["选择Excel", "预览数据", "配置表单", "导入数据"],
      templateAttachment: {},
      currentSheetName: "",
      sheetNameList: [],
      sheetDataList: [],
      fieldList: [], //字段列表
      formName: "", //表单名称
      rowStyle: {
        cursor: "pointer",
      },
      titleRowIndex: 0,
      sheetDataFormSettingTableList: [],
      hasImportSuccess: false,
      isImportData: true,
    };
  },
  methods: {
    importData() {
      if (!this.formName) {
        this.$message.error("表单名称不能为空");
        return;
      }
      if (
        !this.sheetDataFormSettingTableList[0] ||
        !this.sheetDataFormSettingTableList[1] ||
        !this.sheetDataFormSettingTableList[1].some(
          (row) =>
            row.componentName && row.componentName != "none" && !row.subrow,
        )
      ) {
        this.$message.error("没有可导入的数据");
        return;
      }
      if (
        this.sheetDataFormSettingTableList[0].some(
          (item) => !item.value && !item.subrow,
        )
      ) {
        this.$message.error("导入的字段名不能为空");
        return;
      }
      let colMap = [];
      this.sheetDataFormSettingTableList[1].forEach((row, index) => {
        if (row.componentName && row.componentName != "none") {
          colMap.push({
            colNo: index,
            componentName: row.componentName,
            fieldName: this.sheetDataFormSettingTableList[0][index].value,
          });
        }
      });
      let param = {
        oprType: "insert",
        title: this.formName,
        attachmentId: this.templateAttachment.id,
        sheetName: this.currentSheetName,
        startRow: this.titleRowIndex + 1,
        companyId: this.$store.getters.currentCompanyId,
        importData: this.isImportData,
        colMap,
        dirId: this.dirId,
      };
      this.loading = true;
      doImport(param)
        .finally((res) => {
          this.active++;
          this.loading = false;
        })
        .then((res) => {
          this.$emit("importSuccess", res.data);
          this.hasImportSuccess = true;
        });
    },
    changeSheetName() {
      this.readSheetData();
    },
    beforeAvatarUpload(file) {
      if (file.size > 2 * 1024 * 1024) {
        this.$message.error("上传文件大小不能超过 2MB!");
        return false;
      }
      return true;
    },
    handleUpLoadChange(fileList) {
      this.loading = false;
      this.templateAttachment = fileList?.[0]?.response;
      this.formName = this.templateAttachment.originalName;
      this.next();
      this.readSheetName();
    },
    /**
     * 获取表格sheetName
     */
    async readSheetName() {
      await readExcelSheetName({
        attachmentId: this.templateAttachment.id,
      }).then((res) => {
        this.sheetNameList = res.data.sheetNameList;
        this.currentSheetName = this.sheetNameList[0];
      });
      this.readSheetData();
    },
    /**
     * 读取模版解析数据
     * @param sheetName
     */
    readSheetData() {
      this.loading = true;
      readExcelSheetData({
        attachmentId: this.templateAttachment.id,
        sheetName: this.currentSheetName,
      })
        .then((res) => {
          //初始化设置标题行的数据
          this.sheetDataList = this.initFirstRowSheetDataList(
            res.data.rowDataList,
          );
          this.$nextTick(() => {
            this.$refs?.sheetDataTableRef?.setCurrentRow(this.sheetDataList[0]);
            this.titleRowIndex = 0;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onUploadFileListChange() {
      this.loading = true;
    },
    previewTableRowClick(row, column, event) {
      this.titleRowIndex = row.index;
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let temp = row[columnIndex];
      if (temp) {
        if (temp.rowspan && temp.colspan) {
          return {
            rowspan: temp.rowspan,
            colspan: temp.colspan,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    previewCellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex < this.titleRowIndex) {
        return {
          color: "#91A1B7",
        };
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      let configRow = this.sheetDataFormSettingTableList[1];
      if (!configRow) return;
      let obj = configRow[columnIndex];
      if (obj && obj.componentName == "none" && rowIndex > 1) {
        return {
          color: "#91A1B7",
          background: "#e9e9e9",
        };
      }
    },
    pre() {
      if (this.active == 2) {
        this.$refs.fileUpload.clearFiles();
      }
      this.active--;
    },
    async next() {
      if (this.active == 2) {
        this.loading = true;
        await readExcelSheetData({
          attachmentId: this.templateAttachment.id,
          sheetName: this.currentSheetName,
          startRow: this.titleRowIndex + 1,
        })
          .then(async (res) => {
            this.sheetDataFormSettingTableList =
              this.initFormSettingSheetDataList(res.data.rowDataList);
            await this.initFormSetting();
          })
          .catch((res) => {
            this.loading = false;
          });
      }
      this.active++;
    },
    async initFormSetting() {
      await getComponentNameList()
        .then((res) => {
          this.fieldList = res.data.componentList;
          this.fieldList.unshift({
            title: "不导入",
            componentName: "none",
          });
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    /**
     * 初始化选择标题行的数据
     * @param sourceSheetDataList
     */
    initFormSettingSheetDataList(sourceSheetDataList) {
      let firstRowSheetDatalist = this._.cloneDeep(sourceSheetDataList);
      firstRowSheetDatalist = firstRowSheetDatalist.slice(this.titleRowIndex);
      //处理合并单元格情况
      if (firstRowSheetDatalist[0]) {
        firstRowSheetDatalist[0].map((item) => {
          item.colspan = item.colspan || 1;
          item.rowspan = 1;
        });
      }
      //创建表单字段行
      let fieldRow = this._.cloneDeep(firstRowSheetDatalist[0]);
      firstRowSheetDatalist.splice(0, 0, fieldRow);
      //先获取初始化行的数据
      for (let i = 0; i < firstRowSheetDatalist.length; i++) {
        let currentRow = firstRowSheetDatalist[i];
        let createFirstColumnCell = {
          rowspan: 1,
          colspan: 1,
        };
        if (i == 0) {
          createFirstColumnCell.value = "标题行列";
        } else if (i == 1) {
          createFirstColumnCell.value = "表单字段";
        } else {
          createFirstColumnCell.value = i - 1;
        }
        currentRow.unshift(createFirstColumnCell);
      }
      return firstRowSheetDatalist;
    },
    /**
     * 初始化选择标题行的数据
     * @param sourceSheetDataList
     */
    initFirstRowSheetDataList(sourceSheetDataList) {
      let firstRowSheetDatalist = this._.cloneDeep(sourceSheetDataList);
      //先获取初始化行的数据
      for (let i = 0; i < firstRowSheetDatalist.length; i++) {
        let currentRow = firstRowSheetDatalist[i];
        let createFirstColumnCell = {
          rowspan: 1,
          colspan: 1,
          value: "标题行列",
        };
        currentRow.unshift(createFirstColumnCell);
      }
      return firstRowSheetDatalist;
    },
  },
};
</script>

<style scoped lang="scss">
.import-data_dialog--container {
  padding: 10px;
  overflow: auto;

  .step-wrap {
    padding: 25px;
  }

  .step-content__wrap {
    height: 470px;
    display: flex;
    flex-direction: column;
  }

  .import-tip {
    font-size: 12px;
    line-height: 20px;
    padding: 10px 0 0 15px;
    color: #5e6d82;

    .download-template {
      margin: 0 5px;
      color: #0db3a6;
      cursor: pointer;
      font-weight: bolder;
    }

    .tip {
      border-radius: 50%;
      background-color: #5e6d82;
      width: 2px;
      height: 2px;
      margin: 8px 5px 0 0;
      float: left;
    }
  }

  .bottom-tool {
    display: flex;
    justify-content: flex-end;
  }
}

.set-header-row {
  display: none;
  width: 85px;
  margin: 0px auto;
  position: relative;
  overflow: visible;
  color: #fff;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -24px;
    bottom: 0;
    border: 12px solid;
    //border-color: transparent transparent transparent #0DB3A6;
  }
}

::v-deep {
  .current-row {
    .set-header-row {
      display: block;
      color: white !important;
      background: #0db3a6 !important;

      &::after {
        border-color: transparent transparent transparent #0db3a6 !important;
      }
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    .set-header-row {
      display: block;
      color: #333333;
      background: white;

      &::after {
        border-color: transparent transparent transparent white;
      }
    }
  }

  .el-table__row + .hover-row {
    .set-header-row {
      display: block;
      color: #333333;
      background: white;

      &::after {
        border-color: transparent transparent transparent white;
      }
    }
  }
}

.import-count {
  line-height: 30px;
  padding: 0 8px;
  background: #fff6d6;
  color: #1f2d3d;
  border-radius: 4px;
}

.import-count.import-all {
  background: #ebf8f2 !important;
}

.custom-tooltip {
  max-width: 810px;
}
ul {
  list-style-type: none;
}
</style>
