<template>
  <div>
    <el-drawer
      title="表单引用视图"
      :visible.sync="drawer"
      direction="btt"
      size="95%"
      :before-close="handleDrawerClose"
    >
      <div style="text-align: right; padding: 10px 20px 10px 10px">
        <el-button
          size="mini"
          @click="zoomOut"
          :disabled="isZoomOut"
          icon="el-icon-minus"
        ></el-button>
        <el-button
          size="mini"
          @click="zoomIn"
          :disabled="isZoomIn"
          icon="el-icon-plus"
        ></el-button>
        <el-button
          size="mini"
          @click="setZoom"
          icon="el-icon-s-help"
        ></el-button>
      </div>
      <div id="jsmind_container"></div>
    </el-drawer>

    <!-- 引用详情 -->
    <el-drawer
      title="引用详情"
      :visible.sync="referenceDetailDrawer"
      direction="rtl"
      size="360px"
      @close="referenceDetailDrawer = false"
    >
      <div class="reference-details-drawer">
        <div class="reference-details">
          <div class="reference-description">
            <div class="reference-header">
              <div class="reference-title" v-html="referenceFormName"></div>
            </div>
            <div class="reference-list">
              <ul>
                <li
                  class="reference-item"
                  v-for="(item, index) in referenceList"
                  :key="index"
                >
                  <span v-if="item.title"> {{ item.title }} — </span>
                  {{ formScenceEnumConvert(item.scene) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { referenceFormView } from "./api";
import "jsmind/style/jsmind.css";
import jsMind from "jsmind/js/jsmind.js";
require("jsmind/js/jsmind.draggable-node.js");
require("jsmind/js/jsmind.screenshot.js");
import { getFormScence } from "@/utils/enums";

export default {
  name: "referenceView",
  props: {
    drawer: {
      default() {
        return false;
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      referenceFormName: "",
      referenceList: [], // 引用详情列表
      referenceDetailDrawer: false,
      isZoomIn: false,
      isZoomOut: false,
      beReferencedBrief: [], // 被引用对象列表
      beReferencedDetailMap: {}, // 被引用对象详情
      referenceBrief: [], // 引用对象列表
      referenceDetailMap: {}, // 引用对象详情
      mind: {
        meta: {
          name: "引用图谱",
          author: "智管工",
          version: "1.0",
        },
        format: "node_tree",
        data: {
          id: "root",
          topic:
            '<div class="form-item">' +
            '<div class="form-info"><i class="iconfont icon-FileText"></i>' +
            '<div class="form-name">' +
            this.form.title +
            "</div>" +
            '</div><div><i class="iconfont icon-bianji_fill" data-type="form" data-resourceid="' +
            this.form.bizId +
            '"></i></div></div>',
          width: 200,
          children: [],
        },
      },
      options: {
        container: "jsmind_container",
        editable: false,
        theme: "", // greensea
        mode: "full",
        support_html: true,
        view: {
          engine: "svg", // 思维导图各节点之间线条的绘制引擎
          hmargin: 100, // 思维导图距容器外框的最小水平距离
          vmargin: 40, // 思维导图距容器外框的最小垂直距离
          line_width: 1, // 思维导图线条的粗细
          line_color: "#555", // 思维导图线条的颜色
          line_style: "curved", // 思维导图线条的样式，直线(straight)或者曲线(curved)
          draggable: true, // 当容器不能完全容纳思维导图时，是否允许拖动画布代替鼠标滚动
          hide_scrollbars_when_draggable: true, // 当设置 draggable = true 时，是否隐藏滚动条
          node_overflow: "auto", // 节点文本过长时的样式
        },
        layout: {
          hspace: 200, // 节点之间的水平间距(不可修改：计算好引用数值的位置定位)
          vspace: 100, // 节点之间的垂直间距(不可修改：计算好引用数值的位置定位)
          pspace: 10, // 节点与连接线之间的水平间距（用于容纳节点收缩/展开控制器）
          cousin_space: 0, // 相邻节点的子节点之间的额外的垂直间距
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.init();
        document.addEventListener("click", this.nodeClick);
      }, 300);
    });
  },
  methods: {
    // 初始化
    init() {
      referenceFormView({
        collection: this.form.bizId,
      }).then((res) => {
        this.beReferencedBrief = res.data?.beReferencedBrief ?? [];
        this.beReferencedDetailMap = res.data?.beReferencedDetailMap ?? {};
        this.referenceBrief = res.data?.referenceBrief ?? [];
        this.referenceDetailMap = res.data?.referenceDetailMap ?? {};
        this.buildFormView(
          this.beReferencedBrief,
          this.referenceBrief,
          this.beReferencedDetailMap,
          this.referenceDetailMap,
        );
      });
    },
    // 构建图谱数据
    buildFormView(
      beReferencedBrief,
      referenceBrief,
      beReferencedDetailMap,
      referenceDetailMap,
    ) {
      // 表单类型图标枚举
      let formTypeIconEnums = {
        form: "icon-FileText", // 表单图标
        aggregate_table: "icon-dataflow", // 聚合表图标
        spirit: "icon-Robot", // 智能助手1.0图标
        robot: "icon-Robot", // 智能助手2.0图标
        dataflow: "icon-Robot", // 数据流图标
        dashboard: "icon-yibiaopan", // 仪表盘图标
      };
      let buildTopic = (item, num, detailMap, classname, classname2) => {
        let topic =
          '<div class="form-box ' +
          classname2 +
          '"><div class="form-item"><div class="form-info">' +
          '<i class="iconfont ' +
          classname +
          '"></i>' +
          '<div class="form-name">' +
          item.name +
          "</div></div>";
        if (item?.companyId) {
          // (有companyId)代表引用跨单位的数据
          topic +=
            '<div title="' +
            item.company +
            '"><i class="iconfont icon-home-4-fill" data-type="' +
            item.type +
            '" data-resourceid="' +
            item.resourceId +
            '"></i></div>';
        } else {
          topic +=
            '<div><i class="iconfont icon-bianji_fill" data-type="' +
            item.type +
            '" data-resourceid="' +
            item.resourceId +
            '"></i></div>';
        }
        topic +=
          '</div><div class="topicDetail" data-company="' +
          item?.company +
          '" data-name="' +
          item.name +
          '" data-briefid="' +
          item.briefId +
          '" data-mapname="' +
          detailMap +
          '">' +
          num +
          "</div></div>";
        return topic;
      };
      if (beReferencedBrief) {
        // 被引用对象
        beReferencedBrief.forEach((item) => {
          if (beReferencedDetailMap[item.briefId]) {
            let referenceNum = beReferencedDetailMap[item.briefId].length;
            this.mind.data.children.push({
              id: item.briefId,
              topic: buildTopic(
                item,
                referenceNum,
                "beReferencedDetailMap",
                formTypeIconEnums[item.type],
                "form-box-left",
              ),
              direction: "left",
              width: 200,
            });
          }
        });
      }
      if (referenceBrief) {
        // 引用对象
        referenceBrief.forEach((item) => {
          if (referenceDetailMap[item.briefId]) {
            let referenceNum = referenceDetailMap[item.briefId].length;
            this.mind.data.children.push({
              id: item.briefId,
              topic: buildTopic(
                item,
                referenceNum,
                "referenceDetailMap",
                formTypeIconEnums[item.type],
                "form-box-right",
              ),
              direction: "right",
              width: 200,
            });
          }
        });
      }
      this.jm = new jsMind(this.options);
      this.jm.show(this.mind);

      // 处理引用数量位置(todo: 算是强制计算好的规律)
      let formItemLeft = document.querySelectorAll(
        ".form-box-left .topicDetail",
      );
      let formItemRight = document.querySelectorAll(
        ".form-box-right .topicDetail",
      );
      let hspace = (this.options.layout.hspace + 20) / 2; // 节点距离加上数量标签(".topicDetail")宽度1/2
      if (formItemLeft.length > 1) {
        let top = 0;
        top = (formItemLeft.length - 1 + formItemLeft.length) * 10;
        formItemLeft.forEach((item, index) => {
          item.style.top = top - index * 40 + "px";
          item.style.right = "-" + hspace + "px";
        });
      } else if (formItemLeft.length == 1) {
        formItemLeft[0].style.top = "10px";
        formItemLeft[0].style.right = "-" + hspace + "px";
      }
      if (formItemRight.length > 1) {
        let top = 0;
        top = (formItemRight.length - 1 + formItemRight.length) * 10;
        formItemRight.forEach((item, index) => {
          item.style.top = top - index * 40 + "px";
          item.style.left = "-" + hspace + "px";
        });
      } else if (formItemRight.length == 1) {
        formItemRight[0].style.top = "10px";
        formItemRight[0].style.left = "-" + hspace + "px";
      }

      window.onresize = () => {
        this.jm.resize();
      };
    },
    // 表单场景值枚举转换
    formScenceEnumConvert(scence) {
      return getFormScence(scence);
    },
    // 查看引用详情
    openReferenceDetail() {
      this.referenceDetailDrawer = true;
    },
    // 跳转编辑引用
    editReference(type, resourceid) {
      let path = "";
      switch (type) {
        case "form": // 表单设计
          path = "/lowCode/form/edit?id=";
          break;
        case "aggregate_table": // 聚合表
          path = "/lowCode/aggregate-table/edit?id=";
          break;
        case "dataflow": // 数据流
          path = "/lowCode/dataFactory/edit?id=";
          break;
        case "spirit": // 智能助手1.0
          path = "/lowCode/assistant/edit?id=";
          break;
        case "robot": // 智能助手2.0
          path = "/lowCode/rpa/edit?id=";
          break;
        case "dashboard": // 仪表盘
          path = "/lowCode/dashboard/edit?id=";
          break;
        case "workflow": // 流程暂不跳转
          path = "";
          break;
      }
      if (path) {
        let url = this.$router.resolve({
          path: path + resourceid,
        });
        window.open(url.href, "_blank");
      }
    },
    handleDrawerClose() {
      this.$emit("update:drawer", false);
    },
    nodeClick(e) {
      const dataset = e.target?.dataset;
      if (e.target.classList.contains("icon-bianji_fill")) {
        this.editReference(dataset?.type, dataset?.resourceid);
      }
      if (e.target.className == "topicDetail") {
        let parentNodeClassList = e.target.parentNode.classList;
        this.referenceList = this[dataset?.mapname][dataset?.briefid];
        if (parentNodeClassList.contains("form-box-left")) {
          if (dataset?.company !== "undefined") {
            this.referenceFormName =
              dataset?.name +
              "→" +
              "<span style='padding: 0 3px;' title='" +
              dataset?.company +
              "'><i class='iconfont icon-home-4-fill' style='margin: 0px; color:#3870EA'></i></span>" +
              this.form.title;
          } else {
            this.referenceFormName = dataset?.name + "→" + this.form.title;
          }
        }
        if (parentNodeClassList.contains("form-box-right")) {
          if (dataset?.company !== "undefined") {
            this.referenceFormName =
              this.form.title +
              "→" +
              "<span style='padding: 0 3px;' title='" +
              dataset?.company +
              "'><i class='iconfont icon-home-4-fill' style='margin: 0px; color:#3870EA'></i></span>" +
              dataset?.name;
          } else {
            this.referenceFormName = this.form.title + "→" + dataset?.name;
          }
        }
        this.openReferenceDetail();
      }
    },
    zoomIn() {
      if (this.jm.view.zoomIn()) {
        this.isZoomOut = false;
      } else {
        this.isZoomIn = true;
      }
    },
    zoomOut() {
      if (this.jm.view.zoomOut()) {
        this.isZoomIn = false;
      } else {
        this.isZoomOut = true;
      }
    },
    setZoom() {
      this.jm.view.setZoom(1);
    },
  },
  destroyed() {
    document.removeEventListener("click", this.nodeClick);
  },
};
</script>
<style lang="scss" scoped>
#jsmind_container {
  width: 100%;
  height: calc(100% - 50px);
}
::v-deep jmnode.root {
  font-size: 14px;
}

::v-deep jmnode {
  padding: 0;
  font: 14px Verdana, Arial, Helvetica, sans-serif;
  box-shadow: none;
  border: var(--zgg-brand-color-7) solid 1px;
  &:hover {
    color: #333 !important;
    border-color: var(--zgg-brand-color-7);
    border-width: 1px;
    background: #fff;
    box-shadow: 0 0 5px var(--zgg-brand-color-7);
  }
  &.selected {
    color: #333 !important;
    border-color: var(--zgg-brand-color-7);
    border-width: 1px;
    background: #fff;
    box-shadow: 0 0 5px var(--zgg-brand-color-7);
  }
}

::v-deep .form-box {
  position: relative;
  .topicDetail {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: var(--zgg-gray-color-5);
    border-radius: 20px;
    color: #fff;
    position: absolute;
    top: -10px;
    right: -10px;
    &:hover {
      background: var(--zgg-brand-color-7);
    }
  }
}

::v-deep .form-item {
  // position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .form-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    .form-name {
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .iconfont {
    color: var(--zgg-brand-color-6);
  }
  .icon-bianji_fill {
    display: none;
    cursor: pointer;
  }
  .icon-home-4-fill {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .icon-bianji_fill {
      display: block;
    }
    .icon-home-4-fill {
      display: block;
    }
  }
}

.el-button--mini {
  padding: 7px;
}
::v-deep .el-drawer__header {
  margin-bottom: 15px;
  span {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  .el-drawer__close-btn .el-icon-close {
    &:hover {
      background-color: #d4d7da;
    }
  }
}

::v-deep .el-drawer__body {
  background-color: #f5f6f8;
}

.reference-details-drawer {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px 0;
  font-size: 14px;
  .reference-description {
    background: #fff;
    border: 1px solid #ebecee;
    border-radius: 4px;
    height: auto;
    width: 290px;
  }
  .reference-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    align-items: center;
    background-color: #f5f6f8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 32px;
    justify-content: flex-start;
    padding: 0 12px;
    .reference-title {
      color: #141e31;
      font-weight: 500;
      max-width: 266px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .reference-list {
    padding: 12px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
      }
      .reference-item {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
