<template>
  <div class="container">
    <div class="fieldList-wrap">
      <div class="tools">
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleDownload"
            >下载模板</el-button
          >
        </el-button-group>
      </div>
      <el-form ref="cellForm" :model="cellProp" :show-message="false">
        <el-form-item label="关联表单字段：" prop="formItemId">
          <el-select
            v-model="cellProp.linkTargetId"
            placeholder="请选择关联表单字段"
            style="width: 100%"
            clearable
            @clear="handleCellBindClear"
            :disabled="cellInfoDisable"
            @change="formFieldSelectChange"
          >
            <el-option
              v-for="item in formFieldList"
              :key="item.id"
              :label="item.comment"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`${
            currentCellBindFormField.component.componentName == 'json_from'
              ? '关联子表单字表'
              : '关联数据列字段'
          }`"
          prop="linkTargetSubFieldName"
          v-if="
            currentCellBindFormField &&
            (currentCellBindFormField.component.componentName == 'json_form' ||
              currentCellBindFormField.component.componentName ==
                'reference_data' ||
              currentCellBindFormField.component.componentName == 'form_data')
          "
        >
          <el-select
            v-model="cellProp.linkTargetSubFieldName"
            :placeholder="`${
              currentCellBindFormField.component.componentName == 'json_from'
                ? '子表单字表'
                : '数据列字段'
            }`"
            style="width: 100%"
            filterable
            :disabled="cellInfoDisable"
            @change="handleCellSave"
          >
            <el-option
              v-for="item in subComponentList"
              :key="item.name"
              :label="item.title"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程版本：">
          <el-select
            v-model="cellProp.workflowId"
            placeholder="请选择流程版本"
            style="width: 100%"
            clearable
            @change="workFlowChange"
          >
            <el-option
              v-for="item in flowList"
              :key="item.workflowId"
              :label="item.title"
              :value="item.workflowId"
              >{{ item.title }}
              <el-tag
                :key="item.workFlowId"
                v-if="item.versionStatus == 'enable'"
                size="mini"
                type="success"
                >启用中
              </el-tag>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程节点：" v-if="cellProp.workflowId">
          <el-select
            v-model="cellProp.nodeKey"
            placeholder="请选择流程节点"
            style="width: 100%"
            clearable
            @change="workFlowNodeChange"
          >
            <el-option
              v-for="item in nodeList.filter(
                (item) => !item.endNode && !item.startNode
              )"
              :key="item.key"
              :label="item.title"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程字段：" v-if="cellProp.nodeKey">
          <el-select
            v-model="cellProp.flowName"
            placeholder="请选择流程字段"
            style="width: 100%"
            clearable
            @change="flowNodeFieldEnumsChange"
          >
            <el-option
              v-for="item in nodeFieldEnums"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-radio-group v-model="cellProp.flowModel" v-if="cellProp.flowName == 'signInfo'" @change="modelChange">
            <el-radio v-model="cellProp.flowModel" :label="0">单人模式</el-radio>
            <el-radio v-model="cellProp.flowModel" :label="1">会签模式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="节点负责人序号：" v-if="cellProp.flowName && cellProp.flowModel != '1'" >
          <el-input v-model="cellProp.flowIndex"
                    placeholder="请输入内容"
                    @change="flowIndexChange"
                    type="number"
                    oninput ="value=value.replace(/^0|[^0-9]/g, '')"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作簿：">
          <el-input v-model="cellProp.sheetIndex" disabled></el-input>
        </el-form-item>
        <el-form-item label="行：">
          <el-input v-model="cellProp.row" disabled></el-input>
        </el-form-item>
        <el-form-item label="列：">
          <el-input v-model="cellProp.column" disabled></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="excel-wrap" style="position: relative">
      <excelRender
        ref="excelRender"
        @cellMousedown="cellMousedown"
        v-show="hasExcel"
        @workbookCreateAfter="workbookCreateAfter"
      ></excelRender>
      <el-empty description="未绑定表格" v-show="!hasExcel"></el-empty>
    </div>
  </div>
</template>
<script>
import {
  excelEditCell,
  excelExport,
  excelUpdateCell,
  getFormDetail,
  formCreateExcel
} from '@/views/lowCode/form/api'
import uploadFromDisk from "@/views/lowCode/form/templateSetting/excel/uploadFromDisk";
import excelRender from "@/views/lowCode/form/templateSetting/excel/excelRender";
import axios from "axios";
import { excelRepositoryUpdateCell } from "@/api/excelRepository";
import {
  companyWorkflowList,
  formDeleteExcel,
  companyWorkflowNodeList,
} from "@/views/lowCode/form/templateSetting/api";
import { fetchMetaFieldList } from "@/api/form";
import { mapGetters } from "vuex";

export default {
  components: {
    uploadFromDisk,
    excelRender,
  },
  data() {
    return {
      formFieldList: [],
      currentField: {},
      currentCell: {},
      cellProp: {
        row: 0,
        column: 0,
        linkTarget: "metaField",
        linkTargetId: "",
        sheetIndex: 0,
        nodeKey: "",
        flowName: "",
        workflowId: "",
        flowIndex: "",
        flowModel: 0,
      },
      cellProps: [],
      currentCellBindFormField: undefined,
      subComponentList: [],
      flowList: [],
      nodeList: [],
      onlyWorkflowId: "", //一个模板仅能存在一个版本信息
      nodeFieldEnums: [
        {
          value: "nodeTitle",
          label: "节点名称",
        },
        {
          value: "opinion",
          label: "审批意见",
        },
        {
          value: "signInfo",
          label: "签名",
        },
        {
          value: "operatorUser",
          label: "操作人",
        },
        {
          value: "action",
          label: "审批操作",
        },
        {
          value: "actionTime",
          label: "审批时间",
        },
      ],
    };
  },
  props: {
    formDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["sysFields"]),
    hasExcel() {
      return this.formDetail.excel;
    },
    cellInfoDisable() {
      return typeof this.cellProp.row != "number" || this.cellProp.row === "";
    },
    formId() {
      return this.formDetail.id;
    },
    excelId() {
      return this.formDetail.excel && this.formDetail.excel.id;
    },
  },
  async created() {
    await this.init();
    await this.fetchWorkFlowList();
    if (this.formDetail.excel) {
      this.initExcelFieldEcho(this.formDetail.excel.cellProps);
    }
  },
  methods: {
    modelChange(e) {
      if(e === 1){
        this.cellProp.flowIndex = ""
        this.handleCellSave()
      }
    },
    flowIndexChange() {
      if(!this.onlyWorkflowId) {
        this.onlyWorkflowId = this.cellProp.workflowId
      }
      this.handleCellSave()
    },
    flowNodeFieldEnumsChange() {
      // this.handleCellSave()
      this.cellProp.flowIndex = ""
      this.cellProp.flowModel = 0
    },
    workFlowNodeChange() {
      this.cellProp.flowName = ""
      this.cellProp.flowModel = 0
    },
    workFlowChange() {
      this.cellProp.linkTargetId = ""
      this.cellProp.nodeKey = ""
      this.cellProp.flowName = ""
      this.cellProp.flowIndex = ""
      this.cellProp.flowModel = 0
      if(this.cellProp.workflowId ){
        if(this.onlyWorkflowId){
          if(this.onlyWorkflowId != this.cellProp.workflowId){
            this.$message.error('同一模板不能同时存在两个流程版本');
            this.cellProp.workflowId = ""
          }
        } else {
          companyWorkflowNodeList({
            workflowId: this.cellProp.workflowId,
          }).then((res) => {
            this.nodeList = res.data.flowNodes;
          });
        }
      } else {
        let { row, column, sheetIndex } = this.cellProp;
        this.$refs.excelRender.setCellValue(row, column, "");
        this.handleCellSave();
      }
    },
    async fetchWorkFlowList() {
      await companyWorkflowList({
        companyId: this.formDetail.companyId,
        isOpen: true,
        formId: this.formId,
      }).then(async (res) => {
        console.log(res);
        this.flowList = res.data.page.list;
        if (this.formDetail.excel) {
          let item = this.formDetail.excel.cellProps.find((item) => {
            return item.workflowId;
          });
          if (item) {
            this.onlyWorkflowId = item.workflowId;
            await companyWorkflowNodeList({
              workflowId: item.workflowId,
            }).then((res) => {
              this.nodeList = res.data.flowNodes;
            });
          }
        }
        console.log(this.flowList);
      });
    },
    handleCellBindClear() {
      console.log(this.currentCellBindFormField);
    },
    formFieldSelectChange(val) {
      this.cellProp.workflowId = "";
      this.currentCellBindFormField = this.getFieldByFieldId(
        this.cellProp.linkTargetId
      );
      this.cellProp.linkTargetSubFieldName = "";
      if (this.cellProp.linkTargetId == "") {
        let { row, column, sheetIndex } = this.cellProp;
        this.$refs.excelRender.setCellValue(row, column, "");
        this.handleCellSave();
        return;
      }
      let { componentName, key } = this.currentCellBindFormField.component;
      console.log(componentName);
      if (componentName == "json_form") {
        this.subComponentList =
          this.currentCellBindFormField.component.components;
        if (this.subComponentList) {
          let snExist = this.subComponentList.some((item) => {
            return item.name == key + "_序号";
          });
          if (!snExist) {
            this.subComponentList.push({
              //excel导出增加序号字段
              name: key + "_序号",
              title: "_序号",
            });
          }
        }
      } else if (componentName === "reference_data") {
        this.subComponentList =
          this.currentCellBindFormField.component.columns.map((item) => {
            return {
              name: item.field.name,
              title: item.title,
            };
          });
      } else if (componentName === "form_data") {
        this.subComponentList =
          this.currentCellBindFormField.component.columns.map((item) => {
            return {
              name: item.field.name,
              title: item.title,
            };
          });
        if (this.subComponentList) {
          let snExist = this.subComponentList.some((item) => {
            return item.name == key + "_序号";
          });
          if (!snExist) {
            this.subComponentList.push({
              //excel导出增加序号字段
              name: key + "_序号",
              title: "_序号",
            });
          }
        }
      } else {
        this.handleCellSave();
      }
    },
    getFieldByFieldId(fieldId) {
      if (!fieldId) return;
      return this.formFieldList.find((item) => item.id == fieldId);
    },
    initExcelFieldEcho(cellProps) {
      this.cellProps = cellProps.map((cellPropsItem) => {
        if (cellPropsItem.workflowId) {
          let nodeLabel = this.nodeFieldEnums.find((item) => {
            return item.value == cellPropsItem.flowName;
          });
          let nodeKey = this.nodeList.find((item) => {
            return item.key == cellPropsItem.nodeKey;
          });
          if (nodeLabel && nodeKey) {
            cellPropsItem.value = `#{${nodeKey.title}.${nodeLabel.label}_${cellPropsItem.flowIndex}}#`;
          }
        }
        let field = this.formFieldList.find(
          (fieldItem) => fieldItem.id == cellPropsItem.linkTargetId
        );
        if (field) {
          if (cellPropsItem.linkTargetSubFieldName) {
            let subFieldList = [];
            if (field.component.componentName == "json_form") {
              subFieldList = field.component.components;
            }
            if (
              field.component.componentName == "reference_data" ||
              field.component.componentName == "form_data"
            ) {
              subFieldList = field.component.columns.map((item) => {
                return {
                  name: item.field.name,
                  title: item.title,
                };
              });
            }
            let findSubField = subFieldList.find(
              (subField) =>
                subField.name == cellPropsItem.linkTargetSubFieldName
            );
            let title = findSubField ? `.${findSubField.title}` : "";
            cellPropsItem.value = `#{${field.comment}${title}}#`;
          } else {
            cellPropsItem.value = "#{" + field.comment + "}#";
          }
        }
        return cellPropsItem;
      });
      this.$nextTick(() => {
        this.$refs["excelRender"] &&
          this.$refs["excelRender"].setCellValueByCellProps(this.cellProps);
      });
    },
    workbookCreateAfter(json) {
      this.cellProp.row = 0;
      this.cellProp.column = 0;
      let params = {
        column: 0,
        excelId: this.excelId,
        row: 0,
        sheetIndex: 0,
      };
      excelEditCell(params).then((resp) => {
        if (resp.data.cellProp) {
          this.cellProp.linkTargetId = resp.data.cellProp.linkTargetId;
          //初始化显示当前的绑定的表单字段
          this.currentCellBindFormField = this.getFieldByFieldId(
            this.cellProp.linkTargetId
          );
          if (this.currentCellBindFormField) {
            this.cellProp.linkTargetSubFieldName =
              resp.data.cellProp.linkTargetSubFieldName;
            console.log(componentName);
            let componentName =
              this.currentCellBindFormField.component.componentName;
            if (componentName === "json_form") {
              this.subComponentList =
                this.currentCellBindFormField.component.components;
            }
            if (
              componentName === "reference_data" ||
              componentName === "form_data"
            ) {
              this.subComponentList =
                this.currentCellBindFormField.component.columns.map((item) => {
                  return {
                    name: item.field.name,
                    title: item.title,
                  };
                });
            }
          }
        }
      });
      this.$refs["excelRender"] &&
        this.$refs["excelRender"].setCellValueByCellProps(this.cellProps);
    },
    //处理表格属性保存
    handleCellSave(data) {
      let validate = this.$refs.cellForm.validate();
      let templateCellProp = Object.assign({}, this.cellProp);
      if (templateCellProp.workflowId) {
        templateCellProp.linkTarget = "";
      } else if (templateCellProp.linkTargetId == "") {
        templateCellProp.linkTarget = "";
      } else {
        templateCellProp.linkTarget = "metaField";
      }
      validate.then((resp) => {
        let params = {
          cellProp: JSON.stringify(templateCellProp),
          excelId: this.excelId,
        };
        excelUpdateCell(params).then(async (resp) => {
          this.$message.success("保存成功");
          let formDetail = await this.apiGetFormDetail();
          if (formDetail.excel.cellProps) {
            let item = formDetail.excel.cellProps.find((item) => {
              return item.workflowId;
            });
            if (!item) {
              this.onlyWorkflowId = "";
            }
          }
          this.$emit("update:formDetail", formDetail);
          this.initExcelFieldEcho(formDetail.excel.cellProps);
        });
      });
    },
    async init() {
      let formDetail = this.formDetail;
      if (formDetail && formDetail.excel) {
        this.apiGetExcelJson(formDetail.excel.content);
      }
      await this.apiGetFormFieldMeta(formDetail.id);
    },
    fieldTableRowClick(row, column, event) {
      this.currentField = row;
    },
    cellMousedown(data) {
      console.log(data);
      this.currentCellBindFormField = undefined;
      this.cellProp.linkTargetId = "";
      this.cellProp.workflowId = "";
      this.cellProp.nodeKey = "";
      this.cellProp.flowName = "";
      this.currentCell = data;
      let { r, c } = data.position;
      let { sheetIndex, sheetName } = data;

      this.cellProp.row = r;
      this.cellProp.column = c;
      this.cellProp.sheetIndex = sheetIndex;
      let params = {
        column: c,
        excelId: this.excelId,
        row: r,
        sheetIndex: sheetIndex,
      };
      excelEditCell(params).then((resp) => {
        let respCellProp = resp.data.cellProp;
        if (respCellProp) {
          this.cellProp.linkTargetId = respCellProp.linkTargetId;
          this.cellProp.workflowId = respCellProp.workflowId
          this.cellProp.nodeKey = respCellProp.nodeKey
          this.cellProp.flowName = respCellProp.flowName
          if(typeof respCellProp.flowModel === 'number'){
            this.cellProp.flowModel = respCellProp.flowModel
          }
          this.cellProp.flowIndex = respCellProp.flowIndex
          if (!respCellProp.linkTargetId) {
            this.cellProp.linkTargetSubFieldName = "";
          } else {
            this.cellProp.linkTargetSubFieldName =
              respCellProp.linkTargetSubFieldName;
          }

          this.currentCellBindFormField = this.getFieldByFieldId(
            this.cellProp.linkTargetId
          );
          let componentName =
            (this.currentCellBindFormField &&
              this.currentCellBindFormField.component.componentName) ||
            "";
          let key =
            (this.currentCellBindFormField &&
              this.currentCellBindFormField.component.key) ||
            "";
          if (componentName == "json_form") {
            this.subComponentList =
              this.currentCellBindFormField.component.components;
            if (this.subComponentList) {
              let snExist = this.subComponentList.some((item) => {
                return item.name == key + "_序号";
              });
              if (!snExist) {
                this.subComponentList.push({
                  //excel导出增加序号字段
                  name: key + "_序号",
                  title: "_序号",
                });
              }
            }
          }
          if (componentName == "reference_data") {
            this.subComponentList =
              this.currentCellBindFormField.component.columns.map((item) => {
                return {
                  name: item.field.name,
                  title: item.title,
                };
              });
          }
          if (componentName == "form_data") {
            this.subComponentList =
              this.currentCellBindFormField.component.columns.map((item) => {
                return {
                  name: item.field.name,
                  title: item.title,
                };
              });
            if (this.subComponentList) {
              let snExist = this.subComponentList.some((item) => {
                return item.name == key + "_序号";
              });
              if (!snExist) {
                this.subComponentList.push({
                  //excel导出增加序号字段
                  name: key + "_序号",
                  title: "_序号",
                });
              }
            }
          }
        }
      });
    },
    /**
     * 获取表格json
     * @param url
     */
    apiGetExcelJson(url) {
      axios
        .get(url)
        .then((resp) => {
          this.$nextTick(() => {
            this.$refs["excelRender"] &&
              this.$refs["excelRender"].handleReadExcel(resp.data);
          });
        })
        .catch((err) => {});
    },
    async apiGetFormDetail() {
      return getFormDetail(
        this.formId,
        false,
        this.formDetail.excel.bizExcelId
      ).then((resp) => {
        return resp.data.form;
      });
    },
    uploadSuccess(attachment) {
      console.log(attachment);
      this.excelId = attachment.excel.id;
      let params = {
        excelId: this.excelId,
        formId: this.formId,
        title: attachment.excel.title,
      };
      formCreateExcel(params).then((resp) => {
        this.init();
      });
    },
    async apiGetFormFieldMeta(formId) {
      await fetchMetaFieldList({
        tableName: formId || this.formId,
        returnWithSystemField: false,
      }).then((resp) => {
        this.formFieldList = resp.data.list;
      });
    },
    handleDownload() {
      if (this.formDetail && this.formDetail.excel) {
        excelExport({ id: this.formDetail.excel.id }).then(({ data }) => {
          let fileName = this.formDetail.title + "模板.xlsx";
          this.handleBlobDownload(data.fileUrl, fileName);
        });
      }
    },
    handleBlobDownload(url, fileName) {
      axios.get(url, { responseType: "blob" }).then((res) => {
        const blob = new Blob([res.data]);
        const downLoadEle = document.createElement("a");
        const href = URL.createObjectURL(blob);
        downLoadEle.href = href;
        downLoadEle.download = fileName;
        document.body.appendChild(downLoadEle);
        downLoadEle.click();
        document.body.removeChild(downLoadEle);
        window.URL.revokeObjectURL(href);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(100vh - 50px);
  padding: 5px;

  & > div {
    display: inline-flex;
    height: 100%;
    flex-direction: column;
  }

  .fieldList-wrap {
    width: 300px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .tools {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .excel-wrap {
    flex: 1;
    padding: 0 5px;
  }
}
</style>
