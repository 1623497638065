<template>
  <div>
    <div style="margin-bottom: 8px">
      <el-switch
        class="form-color-switch"
        v-model="component.enableOptionColor"
        active-text="彩色"
        :active-value="true"
        :inactive-value="false"
        @change="enableOptionColorChange"
      >
      </el-switch>
    </div>
    <!-- 单选 -->
    <draggable
      v-if="['select', 'radio_group'].includes(component.componentName)"
      :list="component.customOptions"
      animation="300"
      handle=".el-icon-rank"
    >
      <div
        class="weui custom-radio"
        v-for="(item, index) in component.customOptions"
        :key="index"
      >
        <el-radio
          :label="item.value"
          v-model="component.defaultValue"
          size="mini"
        ></el-radio>
        <el-input
          v-model="item.label"
          placeholder=""
          size="small"
          @blur="blurCustom(item)"
          @input="changeCustom(item, $event)"
        ></el-input>
        <el-color-picker
          class="form-color-picker"
          v-if="component.enableOptionColor"
          size="mini"
          :predefine="predefineColors"
          v-model="item.color"
        ></el-color-picker>
        <i class="el-icon-rank ml" style="font-size: 16px; cursor: pointer"></i>

        <i
          @click="delOpt(index)"
          class="el-icon-remove ml"
          style="color: red; font-size: 16px"
        ></i>
      </div>
    </draggable>
    <!-- 多选 -->
    <el-checkbox-group
      v-else
      class="custom-radio"
      v-model="component.defaultValue"
    >
      <draggable
        :list="component.customOptions"
        animation="300"
        handle=".el-icon-rank"
      >
        <div
          class="weui"
          v-for="(item, index) in component.customOptions"
          :key="index"
        >
          <el-checkbox :label="item.value"></el-checkbox>
          <el-input
            v-model="item.label"
            @blur="blurCustom(item)"
            @input="changeCustom(item, $event)"
            placeholder=""
            size="small"
          ></el-input>
          <el-color-picker
            class="form-color-picker"
            v-if="component.enableOptionColor"
            size="mini"
            :predefine="predefineColors"
            v-model="item.color"
          ></el-color-picker>
          <i
            class="el-icon-rank ml"
            style="font-size: 16px; cursor: pointer"
          ></i>

          <i
            @click="delOpt(index)"
            class="el-icon-remove ml"
            style="color: red; font-size: 16px"
          ></i>
        </div>
      </draggable>
    </el-checkbox-group>
    <div style="line-height: 20px">
      <span @click="openSelDialog" class="act">添加选项</span>
      <span style="font-size: 12px; margin: 0px 5px">|</span>
      <span @click="clearDefault" class="act">清空默认值</span>
      <span style="font-size: 12px; margin: 0px 5px">|</span>
      <span @click="batchAddDialogVisible = true" class="act">批量添加</span>
    </div>
    <el-checkbox
      v-if="['select', 'select_checkbox'].includes(component.componentName)"
      v-model="hasCreate"
      label="允许用户增加选项"
      style="margin-top: 10px"
    ></el-checkbox>

    <el-dialog
      title="批量添加"
      custom-class="batch-add-dialog"
      :visible.sync="batchAddDialogVisible"
      width="500px"
      @close="batchAddText = ''"
      :close-on-click-modal="false"
    >
      <span style="display: inline-block; margin-bottom: 10px"
        >每行对应一个选项，且选项不重复</span
      >
      <el-input
        style="height: 250px"
        class="batch-add-text"
        type="textarea"
        v-model="batchAddText"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchAddDialogVisible = false" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="batchAdd" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import { componentProps } from "./util";
export default {
  name: "attr-options-custom",
  props: componentProps,
  components: { Draggable },
  computed: {
    hasCreate: {
      get() {
        return (
          this.component.allowActionEvents &&
          this.component.allowActionEvents.findIndex((item) => item == "add") >=
            0
        );
      },
      set(val) {
        let temp = this._.cloneDeep(
          this.component.allowActionEvents
            ? this.component.allowActionEvents
            : [],
        );
        this.$set(this.component, "initFieldValues", []);
        if (val) {
          temp.push("add");
          this.$set(this.component, "allowActionEvents", temp);
        } else {
          if (this.component.allowActionEvents) {
            let index = this.component.allowActionEvents.findIndex(
              (item) => item == "add",
            );
            if (index >= 0) {
              temp.splice(index, 1);
              this.$set(this.component, "allowActionEvents", temp);
            }
          }
        }
      },
    },
  },
  data() {
    return {
      predefineColors: [
        "#44c6fc",
        "#306afa",
        "#654bff",
        "#20b77e",
        "#fec300",
        "#FD7B03",
        "#FE6481",
        "#F14135",
        "#BCBABA",
      ],
      batchAddDialogVisible: false,
      batchAddText: "",
    };
  },
  methods: {
    /**
     * 当开启彩色模式时，初始化彩色
     * @param val
     */
    enableOptionColorChange(val) {
      if (val) {
        if (
          this.component.customOptions &&
          this.component.customOptions.length > 0
        ) {
          this.component.customOptions = this.component.customOptions.map(
            (item, index) => {
              if (!item.color) {
                let color =
                  this.predefineColors[index % this.predefineColors.length];
                this.$set(item, "color", color);
              }
              return item;
            },
          );
        }
      }
    },
    blurCustom(item) {
      item.label = item.value;
    },
    changeCustom(item, value) {
      item.value = value.trim();
      if (
        this.component.componentName == "checkbox_group" ||
        this.component.componentName == "select_checkbox"
      ) {
        this.component.defaultValue = this.component.defaultValue.filter(
          (item) =>
            this.component.customOptions.findIndex(
              (o) => o.value == item.value,
            ) >= 0,
        );
      } else {
        if (
          this.component.customOptions.findIndex(
            (col) => col.value == this.component.defaultValue,
          ) == -1
        ) {
          this.component.defaultValue = "";
        }
      }
    },
    delOpt(index) {
      this.component.customOptions.splice(index, 1);
    },
    openSelDialog() {
      let label = "选项";
      let item = {
        label: label,
        value: label,
      };
      if (this.component.enableOptionColor) {
        item.color =
          this.predefineColors[
            this.component.customOptions.length % this.predefineColors.length
          ];
      }
      this.component.customOptions.push(item);
    },
    clearDefault() {
      if (
        this.component.componentName == "checkbox_group" ||
        this.component.componentName == "select_checkbox"
      ) {
        this.component.defaultValue = [];
      } else {
        this.component.defaultValue = "";
      }
    },
    batchAdd() {
      // 去重并去除空值后的选项数组
      const items = Array.from(
        new Set(
          this.batchAddText
            .split("\n") // 以换行符分割
            .filter((item) => item.replaceAll(" ", "") !== "") // 去除空值item
            .map((item) => {
              let label = item.trim(); // 去除首尾空格
              return {
                // 转为选项对象
                label: label,
                value: label,
              };
            }),
        ),
      );

      // 添加到选项数组
      items.forEach((item) => {
        // 选项不存在时才添加
        if (!this.component.customOptions.some((o) => o.label === item.label)) {
          if (this.component.enableOptionColor) {
            item.color =
              this.predefineColors[
                this.component.customOptions.length %
                  this.predefineColors.length
              ];
          }
          this.component.customOptions.push(item);
        }
      });

      this.batchAddDialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.weui {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.form-color-switch {
  ::v-deep {
    .el-switch__core {
      height: 16px;
      width: 28px !important;
      &:after {
        height: 12px;
        width: 12px;
      }
    }

  }

  &.is-checked {
    ::v-deep {
      .el-switch__core {
        &:after {
          margin-left: -12px;
        }
      }
    }
  }
}
.form-color-picker {
  ::v-deep {
    .el-color-picker__trigger {
      border: none;

      .el-color-picker__color {
        border: none;
      }
    }
  }
}
::v-deep .el-radio-button--mini .el-radio-button__inner {
  padding: 7px 12px;
}
.act {
  color: #409eff;
  font-size: 12px;
  cursor: pointer;
}
.custom-radio {
  width: 100%;

  ::v-deep {
    .el-radio,
    .el-checkbox {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    .el-radio__label,
    .el-checkbox__label {
      padding-left: 0px;
      flex: 1;
      margin-left: 5px;
      border: solid 1px #e6e6e6;
      padding: 5px 10px;
      border-radius: 5px;
      display: none;
    }
  }
}

::v-deep .batch-add-dialog {
  .el-dialog__body {
    padding: 10px 20px;
  }

  .batch-add-text {
    height: 250px;
    textarea {
      height: 250px;
    }
  }
}
</style>
