<template>
  <div>
    <div class="weui-row">
      <!-- 选择组件 -->
      <field-source
        :componentList="componentList"
        :field="filterField"
        :formId="formId"
        style="margin-left: 0px; margin-right: 10px"
        valueType="nodeField"
        abilityKey="inRpaFilter"
        @change="changeField"
      ></field-source>

      <!-- 操作符 -->
      <el-select
        v-model="oper"
        size="small"
        :disabled="!filterField.key"
        placeholder="请选择"
        style="width: 140px; margin-right: 10px"
        @change="changeOper"
      >
        <el-option
          v-for="opt in operQuerys"
          :key="opt.value"
          :label="opt.label"
          :value="opt.value"
        >
        </el-option>
      </el-select>
      <template v-if="!['nil', 'nnil'].includes(filterField.oper)">
        <el-select
          v-if="filterField.oper != 'dynamic'"
          v-model="valueType"
          size="small"
          style="width: 140px; margin-right: 10px"
          placeholder=""
          @change="changeValueType"
          :disabled="!filterField.key"
        >
          <el-option label="节点字段" value="nodeField"></el-option>
          <el-option
            v-if="filterField.componentName != 'id'"
            label="自定义"
            value="custom"
          ></el-option>
        </el-select>
      </template>
      <!-- 删除 -->
      <!-- <i v-if="!disabled" @click="del" class="el-icon-delete"></i> -->
    </div>
    <div v-if="!['nil', 'nnil'].includes(filterField.oper)" class="weui-row">
      <template v-if="filterField.valueType == 'custom'">
        <query-input
          :field="filterField"
          :areaTree="areaTree"
          :areaProps="areaProps"
          :componentList="componentList"
          :disabled="!filterField.key || disabled"
          style="flex: 1; margin-right: 10px"
          v-model="value"
        ></query-input>
      </template>
      <rpa-node-fields
        v-else-if="filterField.valueType == 'nodeField'"
        :nodeField="filterField.nodeField"
        :oper="filterField.oper"
        :tableField="tableField"
        fieldConfigKey="filterComponents"
        :disabled="!filterField.key || disabled"
        :formId="formId"
        :nodeType="nodeType"
        :baseNodeKey="baseNodeKey"
      ></rpa-node-fields>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import {
  checkFieldAbility,
  operQuerys,
} from "@zgg-core-utils/component-white-list";
import { mapState } from "vuex";

import rpaNodeFields from "./rpa-node-fields";
import { userMixin } from "@/mixin/user-dept";
export default {
  props: {
    rel: String,
    index: Number,
    componentList: Array,
    filterField: Object,
    formId: String,
    disabled: Boolean,
    nodeType: String,
    baseNodeKey: {
      type: String,
      default() {
        return "";
      },
    },
  },

  mixins: [userMixin],
  components: { rpaNodeFields },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    tableField() {
      return {
        name: this.filterField.key,
        componentName: this.filterField.componentName,
        referenceTableName: this.filterField.collection,
      };
    },
    operQuerys() {
      if (this.filterField.key) {
        let list = checkFieldAbility(
          {
            componentName: this.filterField.componentName,
            name: this.filterField.key,
          },
          "oper",
        );

        if (this.filterField.key == "_id") {
          if (this.nodeType == "query_data_one") {
            list = ["eq", "neq"];
          } else if (this.nodeType == "query_data_list") {
            list = ["in", "nin"];
          } else {
            list = ["eq", "neq", "in", "nin"];
          }
        }

        if (!isEmpty(list)) {
          return operQuerys.filter((item) => list.includes(item.value));
        }
      }
      return [];
    },
    relStr: {
      get() {
        return this.rel;
      },
      set(val) {
        return this.$emit("update:rel", val);
      },
    },
    oper: {
      get() {
        return this.filterField.oper;
      },
      set(val) {
        this.$set(this.filterField, "oper", val);
      },
    },
    valueType: {
      get() {
        return this.filterField.valueType;
      },
      set(val) {
        this.$set(this.filterField, "valueType", val);
      },
    },
    value: {
      get() {
        return this.filterField.value;
      },
      set(val) {
        this.$set(this.filterField, "value", val);
      },
    },
  },
  created() {
    if (this.filterField.key == "_id" && isEmpty(this.filterField.oper)) {
      if (this.nodeType == "query_data_list") {
        this.$set(this.filterField, "oper", "in");
      } else {
        this.$set(this.filterField, "oper", "eq");
      }
    }
  },

  methods: {
    del() {
      this.$emit("del");
    },
    changeValueType(value) {
      this.$set(this.filterField, "value", "");

      if (value == "nodeField") {
        this.$set(this.filterField, "nodeField", {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
        });
      } else {
        this.$set(this.filterField, "nodeField", undefined);
      }
    },
    changeOper(val) {
      let valueType;

      if (["nil", "nnil"].includes(val)) {
        valueType = "custom";
      } else if (val == "dynamic") {
        valueType = "custom";
      } else {
        valueType = "nodeField";
        this.$set(this.filterField, "nodeField", {
          nodeKey: "",
          title: "",
          name: "",
          componentName: "",
        });
      }
      this.$set(this.filterField, "valueType", valueType);
      this.$set(this.filterField, "value", "");
    },
    changeField() {
      this.$set(this.filterField, "valueType", "nodeField");
      this.$set(this.filterField, "nodeField", {
        nodeKey: "",
        title: "",
        name: "",
        componentName: "",
      });
      this.$set(this.filterField, "value", "");
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
}
</style>
