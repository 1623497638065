<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 68px;
    "
  >
    <div class="block">
      <div class="label">基础信息</div>
      <div class="content">
        <div style="display: flex; align-items: center">
          <img
            :src="unitDetail.logo"
            v-if="unitDetail.logo"
            class="unit-logo"
            style="padding: 0; background-color: transparent"
          />
          <div v-else class="unit-logo">
            <span class="iconfont icon-building-fill unit-icon"></span>
          </div>
          <div style="font-size: 16px; line-height: 24px; margin-left: 20px">
            {{ unitDetail.name }}
          </div>
          <div class="edit-btn" @click.stop="setUnit">
            <span
              class="iconfont icon-bianji_fill"
              style="font-size: 16px"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="label">高级设置</div>
      <div class="content">
        <div class="config-item">
          <div class="title">企业账号URL</div>
          <template v-if="urlSetVisible">
            <div style="display: flex">
              <el-input
                v-model="detail.domain"
                placeholder="支持输入字母、数字，最少五位"
                style="width: 360px"
              >
                <template slot="append">{{ `.${urlSuffix}` }}</template>
              </el-input>
            </div>
            <el-button
              type="primary"
              @click="confirmUrl"
              style="margin-left: 10px"
              size="medium"
              >确 定</el-button
            >
            <el-button @click="urlSetVisible = false" size="medium"
              >取 消</el-button
            >
          </template>
          <template v-else-if="!urlDetail.domain">
            <el-button type="primary" size="small" @click="setUrl"
              >设置</el-button
            >
            <div class="description">当前企业专属登录入口</div>
          </template>
          <template v-else>
            <div style="font-size: 14px; color: #6b778c">
              {{ `http://${urlDetail.domain}.${urlSuffix}` }}
            </div>
            <el-button type="text" style="margin-left: 10px" @click="setUrl"
              >修改</el-button
            >
            <el-button
              type="text"
              @click="copy(`http://${urlDetail.domain}.${urlSuffix}`)"
              >复制</el-button
            >
            <el-button type="text" @click="openUrl">预览</el-button>
          </template>
        </div>
        <div class="config-item">
          <div class="title">自定义登录页</div>
          <el-button type="primary" size="small" @click="setLogin"
            >设置</el-button
          >
          <div class="description">应用于企业账号URL和发布给成员的内链接中</div>
        </div>
        <div class="config-item">
          <div class="title">小程序私有化</div>
          <el-button type="primary" size="small" @click="setApplet"
            >设置</el-button
          >
          <div class="description">
            私有化定制小程序，保护用户数据安全和隐私
          </div>
        </div>
      </div>
    </div>
    <login-page-setting
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      @save="save"
      :loginDetail.sync="detail"
    >
    </login-page-setting>
    <el-dialog
      title="小程序私有化"
      :visible.sync="appSetVisible"
      :close-on-click-modal="false"
      width="600px"
      append-to-body
      destroy-on-close
      :rules="rules"
    >
      <div class="form-body">
        <el-form
          ref="appForm"
          :model="appletDetail"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="小程序原始ID" prop="appSrcId">
            <el-input v-model="appletDetail.appSrcId"></el-input>
          </el-form-item>
          <el-form-item label="小程序AppID" prop="appId">
            <el-input v-model="appletDetail.appId"></el-input>
          </el-form-item>
          <el-form-item label="小程序Secret" prop="appSecret">
            <el-input v-model="appletDetail.appSecret"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="appSetVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmApplet">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改基础信息"
      :visible.sync="unitVisible"
      :close-on-click-modal="false"
      width="600px"
      append-to-body
      destroy-on-close
    >
      <div class="form-body">
        <el-form ref="unitForm" :model="detail">
          <el-form-item prop="name" required>
            <div style="display: flex; padding-bottom: 5px">
              <div class="form-label">企业全称</div>
              <div class="form-tip">用于单位目录、单位设置的显示</div>
            </div>
            <el-input v-model="detail.name"></el-input>
          </el-form-item>
          <el-form-item prop="abbr" required>
            <div style="display: flex; padding-bottom: 5px">
              <div class="form-label">企业简称</div>
              <div class="form-tip">
                用于私有化网站页头、私有化小程序登录页的显示，请尽量简短
              </div>
            </div>
            <el-input v-model="detail.abbr"></el-input>
          </el-form-item>
          <el-form-item prop="logo">
            <div style="display: flex; padding-bottom: 5px">
              <div class="form-label">企业LOGO</div>
              <div class="form-tip">
                推荐尺寸 200*200px，用于分享、签名、企业域名等页面显示
              </div>
            </div>
            <zgg-upload
              :multiple="false"
              :drag="false"
              :show-file-list="false"
              @success="uploadLogoSuccess"
              accept=".jpg,.jpeg,.png,.ico"
            >
              <template slot="default">
                <div class="upload-icon" style="padding: 0" v-if="detail.logo">
                  <img :src="detail.logo" />
                  <span class="edit-icon">
                    <i
                      class="iconfont icon-bianji_fill"
                      style="color: #fff; font-size: 18px; margin-right: 0"
                    ></i>
                  </span>
                </div>
                <div class="upload-icon" v-else>
                  <i class="el-icon-plus"></i>
                </div>
              </template>
            </zgg-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="unitVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  companySettingUrlDetail,
  companySettingUrlModify,
  companySettingLogoModify,
  companySettingLogoDetail,
  companySettingAppletModify,
  companySettingAppletDetail,
  modifyDefineLoginPageFlag,
  renameCompany,
} from "@/api/operationCenter";
import zggUpload from "@/components/Upload/zgg-upload.vue";
import loginPageSetting from "@/views/lowCode/unit-config/login-page-setting.vue";
import { companyDetail } from "@/api/company";
export default {
  components: { zggUpload, loginPageSetting },
  computed: {
    urlSuffix() {
      return process.env.VUE_APP_DOMAIN_SUFFIX;
    },
    companyName() {
      return this.$store.getters.currentCompanyName;
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
    sslPublicFile() {
      return this.detail.sslPublicKeyName
        ? [
            {
              name: this.detail.sslPublicKeyName,
              url: this.detail.sslPublicKey,
            },
          ]
        : [];
    },
    sslPrivateFile() {
      return this.detail.sslPrivateKeyName
        ? [
            {
              name: this.detail.sslPrivateKeyName,
              url: this.detail.sslPrivateKey,
            },
          ]
        : [];
    },
  },
  data() {
    return {
      urlSetVisible: false,
      dialogVisible: false,
      appSetVisible: false,
      unitVisible: false,
      detail: {},
      unitDetail: {},
      urlDetail: {},
      loginDetail: {},
      appletDetail: {},
      rules: {
        appId: [{ validator: this.validateApp, trigger: "blur" }],
        appSrcId: [{ validator: this.validateApp, trigger: "blur" }],
        appSecret: [{ validator: this.validateApp, trigger: "blur" }],
      },
    };
  },
  async mounted() {
    await companyDetail({
      companyId: this.companyId,
    }).then((res) => {
      this.unitDetail = res.data?.result;
      this.defineLoginPageFlag = res.data?.result?.defineLoginPageFlag;
    });
    await this.getUrlDetail();
  },
  methods: {
    setUnit() {
      this.detail = this._.cloneDeep(this.unitDetail);
      this.unitVisible = true;
    },
    setApplet() {
      companySettingAppletDetail({
        companyId: this.companyId,
      }).then((res) => {
        this.appletDetail = res.data?.result;
        this.appSetVisible = true;
      });
    },
    setLogin() {
      companySettingLogoDetail({
        companyId: this.companyId,
      }).then((res) => {
        this.loginDetail = res.data?.result;
        this.detail = this._.cloneDeep(this.loginDetail);
        this.dialogVisible = true;
      });
    },
    openUrl() {
      window.open(`http://${this.urlDetail.domain}.${this.urlSuffix}`);
    },
    setUrl() {
      this.getUrlDetail(() => {
        this.detail = this._.cloneDeep(this.urlDetail);
        this.urlSetVisible = true;
      });
    },
    async getUrlDetail(callback) {
      await companySettingUrlDetail({
        companyId: this.companyId,
      }).then((res) => {
        this.$set(this, "urlDetail", res.data?.result);
        if (callback) callback();
      });
    },
    validateApp(rule, value, callback) {
      let { field } = rule;
      if (
        ["appId", "appSrcId", "appSecret"].some(
          (item) => this.appletDetail[item],
        )
      ) {
        if (!value) {
          return callback(
            new Error(`${field}不能为空(需填写完整小程序信息，可均不填)`),
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    uploadLogoSuccess(fileList, file) {
      this.$set(this.detail, "logo", file?.response?.url);
    },
    uploadFileSuccess(fileList, type) {
      this.$set(this.detail, type, fileList?.[0]?.response?.id);
    },
    removeFileSuccess(fileList, type) {
      if (!fileList?.length) {
        this.detail[type] = "";
        this.detail[type + "Name"] = "";
      }
    },
    save() {
      companySettingLogoModify({
        companyId: this.companyId,
        ...this.detail,
      }).then((res) => {
        this.$set(this, "loginDetail", this.detail);
        this.$message.success("保存成功");
        this.dialogVisible = false;
      });
    },
    confirm() {
      this.$refs.unitForm.validate((valid) => {
        if (valid) {
          renameCompany({
            companyId: this.companyId,
            name: this.detail.name,
            abbr: this.detail.abbr,
            logo: this.detail.logo,
          }).then((res) => {
            this.$set(this, "unitDetail", this.detail);
            this.$message.success("保存成功");
            this.$store.dispatch("user/setCompanyName", this.unitDetail.name);
            this.unitVisible = false;
          });
        }
      });
    },
    confirmApplet() {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          companySettingAppletModify({
            companyId: this.companyId,
            ...this.appletDetail,
          }).then((res) => {
            this.$message.success("保存成功");
            this.appSetVisible = false;
          });
        }
      });
    },
    confirmUrl() {
      if (!/^[A-Za-z0-9]{5,40}$/g.test(this.detail.domain)) {
        this.$message.error("仅支持输入字母、数字，最少五位");
        return;
      }
      companySettingUrlModify({
        companyId: this.companyId,
        ...this.detail,
      }).then((res) => {
        this.$set(this, "urlDetail", this.detail);
        this.$message.success("保存成功");
        this.urlSetVisible = false;
      });
    },
    copy(data) {
      let input = document.createElement("input");
      input.setAttribute("value", data);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("Copy")) {
        this.$message({ type: "success", message: "复制到剪贴板" });
      }
      document.body.removeChild(input);
    },
  },
};
</script>
<style lang="scss" scoped>
.block {
  margin-bottom: 30px;
}
.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--font-text-primary, #172b4d);
}
.content {
  margin-top: 12px;
  padding: 16px;
  border-radius: 10px;
  background: #fff;
}
.unit-icon {
  color: #fff;
  font-size: 24px;
}
.edit-btn {
  height: 24px;
  width: 24px;
  border-radius: 5px;
  padding: 4px;
  margin-left: 20px;
  &:hover {
    background: #f2f6fc;
    color: #3870ea;
  }
}
.config-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding: 6px 0px;
  border-bottom: 1px solid rgba(235, 236, 240, 1);
}
.title {
  margin-right: 25px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(107, 119, 140, 1);
  margin-left: 15px;
}
.form-body {
  padding: 35px;
  //min-height: 272px;
  overflow: auto;
  .form-label {
    font-size: 16px;
    line-height: 24px;
    margin-right: 10px;
  }
  .form-tip {
    font-size: 14px;
    line-height: 24px;
    color: rgba(107, 119, 140, 1);
  }
}
.upload-icon {
  display: flex;
  position: relative;
  width: 48px;
  height: 48px;
  padding: 16px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgba(220, 223, 230, 1);
  img {
    width: 100%;
    height: 100%;
  }
  .edit-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.6);
  }
  &:hover {
    .edit-icon {
      display: block;
    }
  }
}
.unit-logo {
  height: 48px;
  width: 48px;
  background-color: #3870ea;
  border-radius: 5px;
  padding: 12px;
}
</style>
