<template>
  <el-dialog
      :visible.sync="copyToOtherCompanyDialog"
      title="复制到其他单位"
      width="600px"
  >
    <div style="display: flex;flex-direction: column;width: 90%;margin: auto;padding: 30px 0 100px 0">
      <div style="margin-bottom: 8px;font-size: 14px">请选择目标单位</div>
      <el-select v-model="copyToCompanyId" size="mini" @change="handleChange">
        <el-option
            v-for="item in myCompanyList"
            :key="item.companyId"
            :label="item.companyName"
            :value="item.companyId"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="copyToOtherCompanyDialog = false">取 消</el-button>
        <el-button type="primary" @click="copyToOtherCompany">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import { duplicate } from '@/api/form'
import { viewDuplicate } from '@/views/lowCode/view/api'
import { listMyCompany } from '@/api/operationCenter'
export default {
  name: 'copyToDialog',
  props:['api'],
  data(){
    return {
      copyRow:{},
      myCompanyList:[],
      copyToCompanyId:'',
      copyToOtherCompanyDialog:false,
    }
  },
  methods:{
    openCopyToDialog(row,companyPage,searchForm) {
      this.copyRow = row
      this.copyToCompanyId = ''
      this.getMyCompanyList(row,companyPage,searchForm)
      this.copyToOtherCompanyDialog = true
    },

    getMyCompanyList(row,companyPage,searchForm) {
      listMyCompany({
        keyword:searchForm.keyWord,
        pageNumber:companyPage.pageNumber,
        pageSize:companyPage.pageSize
      }).then(({data})=>{
        this.myCompanyList = data.page.list
        console.log(data,'用户所在单位详情')
      }).catch(err=>{
        console.log(err)
      })
    },


    // 选择复制到的单位
    handleChange(companyId){
      this.copyToCompanyId = companyId
    },

    copyToOtherCompany(){
      console.log(this.api)
      if(this.api === 'duplicate') {
        duplicate({
          id:this.copyRow.bizId,
          title:this.copyRow.title,
          companyId:this.copyToCompanyId
        }).then(res=>{
          console.log(res)
          this.$message.success('复制成功！')
          this.copyToOtherCompanyDialog = false
        }).catch(err=>{
          console.log(err)
        })
      } else if(this.api === 'viewDuplicate'|| 'dashboard'){
        console.log(111)
        viewDuplicate({
          id:this.copyRow.bizId,
          title:this.copyRow.title,
          companyId:this.copyToCompanyId
        }).then(res=>{
          console.log(res)
          this.$message.success('复制成功！')
          this.copyToOtherCompanyDialog = false
        }).catch(err=>{
          console.log(err)
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
