<template>
  <div>
    <template v-if="inJsonForm">
      <div
        @click="clickReference"
        class="json-row-cell"
        :style="getLeftWidth()"
      >
        <el-button
          icon="el-icon-coin"
          size="small"
          style="border: 0"
        ></el-button>
      </div>
      <div
        :style="`width:${columnsWidth[item.key + '.' + child.field.name]}px;`"
        v-for="child in item.columns"
        :key="child.field.name"
        class="json-row-cell"
      >
        <el-input
          v-if="child.field.componentName != 'json_form'"
          style="width: 100%"
          placeholder="暂无内容"
          disabled
          size="small"
          :value="getValue(columnData, child.field)"
        ></el-input>
      </div>
    </template>
    <template v-else>
      <el-form-item
        :label="isShowLabel(item) ? item.title : ''"
        :required="!!item.required"
        :class="{ 'un-label': !isShowLabel(item) }"
        :style="`width:${100 / cols}%;`"
        :rules="rules"
      >
        <!-- <el-button @click="dialogVisible = true" size="small">选择数据</el-button> -->
        <el-input
          :value="title"
          placeholder=""
          suffix-icon="el-icon-search"
          clearable
          @focus="dialogVisible = true"
          @clear="clear"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-for="child in item.columns"
        :key="child.field.name"
        :label="child.title"
        :class="{ 'un-label': !item.form }"
        :style="`width:${100 / cols}%;`"
      >
        <el-input
          v-if="child.field.componentName != 'json_form'"
          style="width: 100%"
          placeholder="暂无内容"
          disabled
          size="small"
        ></el-input>
        <el-table v-else :data="[]" style="width: 100%">
          <el-table-column
            min-width="120px"
            v-for="col in child.subColumns"
            :key="col.field.name"
            :label="col.title"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
    </template>

    <el-dialog
      :title="item.title"
      :visible.sync="dialogVisible"
      append-to-body
      destroy-on-close
      @open="open"
      @closed="closed"
    >
      <div style="margin: 0 10px">
        <!-- 查询先不做 -->
        <el-form :inline="true" size="small" ref="form" :model="searchForm">
          <el-form-item>
            <el-input
              style="width: 240px"
              size="small"
              v-model="searchForm.title"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="search" size="small" type="primary"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <div>
          <el-table
            :data="tableData"
            style="width: 100%"
            :span-method="spanMethod"
            @row-click="rowClick"
            border
          >
            <el-table-column prop="_index" label="" width="50">
              <template slot-scope="{ row }">
                <el-radio
                  v-model="key"
                  :label="row._id"
                  class="el-hidden"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column
              v-for="col in item.tableColumns"
              :key="col.field.name"
              :prop="col.field.name"
              :label="col.title"
              :min-width="
                col.subColumns && col.subColumns.length ? '' : '120px'
              "
            >
              <template slot-scope="{ row }">{{
                dealWithFormValue(row, col.field.component)
              }}</template>
              <template v-if="col.subColumns && col.subColumns.length">
                <el-table-column
                  v-for="child in col.subColumns"
                  :key="child.field.name"
                  :label="child.title"
                  :prop="child.field.name"
                  min-width="120px"
                >
                  <template slot-scope="{ row }">{{
                    dealWithFormValue(row, child.field.component)
                  }}</template>
                </el-table-column>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="page.totalRow"
            :page.sync="page.pageNumber"
            :limit.sync="page.pageSize"
            @pagination="loadingList"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { fetchFormDataList } from "../api";
import { dealWithFormValue } from "@/views/lowCode/form/utils";
import { isEmpty } from "@zgg-core-utils/relyUtil";

export default {
  components: { Pagination },
  props: {
    form: Object,
    item: Object,
    rules: Array,
    cols: Number,
    row: Object,
    inJsonForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
    columnsWidth: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    title() {
      let key = this.item.name;
      let row = this.form[`${key}ReferenceData`];

      if (!this.form[key]) {
        return "";
      }
      let label = "";
      if (this.item.tableColumns && this.item.tableColumns) {
        this.item.tableColumns.forEach((col) => {
          if (!isEmpty(row[col.field.name])) {
            if (label != "") {
              label += "|";
            }
            label += dealWithFormValue(row, col.field.component);
          }
        });
      }
      if (isEmpty(label)) {
        if (row.dataTitle) {
          label = row.dataTitle;
        } else {
          let key = Object.keys(row)[0];
          if (key) {
            label = row[key];
          }
        }
      }
      return label;
    },
  },
  data() {
    return {
      columnData: {},
      dialogVisible: false,
      tableData: [],
      page: {
        pageSize: 10,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      key: "",
      searchForm: {},
    };
  },
  methods: {
    dealWithFormValue,
    clickReference() {
      if (this.disabled) {
        return;
      }
      this.dialogVisible = true;
    },
    getLeftWidth() {
      let width = this.columnsWidth[this.item.key];
      let totalWidth = 0;
      this.item.columns.forEach((item) => {
        totalWidth += this.columnsWidth[this.item.key + "." + item.field.name];
      });

      return `width:${width - totalWidth}px;text-align:left;`;
    },
    search() {
      this.page.pageNumber = 1;
      this.loadingList();
    },
    clear() {
      this.$set(this.form, this.item.name + "ReferenceData", null);
      this.key = "";
      this.columnData = {};
      this.$emit("changeFormData", { name: this.item.name, value: "" });
    },
    closed() {
      this.page.pageNumber = 1;
      this.key = "";
    },
    open() {
      this.loadingList();
    },
    isShowLabel(col) {
      return !col.hiddenTitle && (col.componentName == "form_data" || col.form);
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        this.item.columns.findIndex(
          (col) => col.field.name == column.property,
        ) >= 0 ||
        column.property == "_index" ||
        column.type == "action"
      ) {
        if (row.rowSpan) {
          return {
            rowspan: row.rowSpan,
            colspan: 1,
          };
        } else if (row.rowSpan === 0) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    rowClick(row) {
      this.key = row._id;
    },
    loadingList() {
      let advanceQuery = [];
      let queryObject = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
      };
      this.item.relationQuery.forEach((row) => {
        if (row.valueType == "custom") {
          // 自定义的值
          if (row.value.countyId) {
            advanceQuery.push({
              key: row.key + ".countyId", //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value.countyId, //查询值
              componentName: row.componentName,
            });
          } else if (row.value.cityId) {
            advanceQuery.push({
              key: row.key + ".cityId", //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value.cityId, //查询值
              componentName: row.componentName,
            });
          } else if (row.value.provinceId) {
            advanceQuery.push({
              key: row.key + ".provinceId", //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value.provinceId, //查询值
              componentName: row.componentName,
            });
          } else {
            advanceQuery.push({
              key: row.key, //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value, //查询值
              componentName: row.componentName,
            });
          }
        } else {
          // 当前表单字段
          let value = this.form[row.value];
          if (!isEmpty(value)) {
            advanceQuery.push({
              key: row.key,
              oper: row.oper,
              value: value,
              componentName: row.componentName,
            });
          }
        }
      });
      if (advanceQuery.length) {
        queryObject.advanceQuery = advanceQuery;
      }
      fetchFormDataList({
        collection: this.item.tableName,
        queryObject: JSON.stringify(queryObject),
      }).then((res) => {
        this.page.pageNumber = res.data.page.pageNumber;
        this.page.totalRow = res.data.page.totalRow;
        this.page.totalPage = res.data.page.totalPage;
        let data = res.data;

        let arr = this.item.columns.filter(
          (item) => item.subColumns && item.subColumns.length > 0,
        );
        if (!arr.length) {
          let tableData = [];
          data.page.list.forEach((item, index) => {
            tableData.push({
              ...item,
              _index:
                (data.page.pageNumber - 1) * data.page.pageSize + index + 1,
            });
          });
          this.tableData = tableData;
        } else {
          let tableData = [];
          data.page.list.forEach((item, pIndex) => {
            let _index =
              (data.page.pageNumber - 1) * data.page.pageSize + pIndex + 1;
            let lens = [];
            arr.forEach((a) => {
              if (item[a.field.name] && item[a.field.name].length) {
                lens.push(item[a.field.name].length);
              }
            });

            if (lens.length > 0) {
              let max = Math.max(...lens);

              for (let index = 0; index < max; index++) {
                let obj = { ...item };
                if (index == 0) {
                  obj.rowSpan = max;
                } else {
                  obj.rowSpan = 0;
                }
                arr.forEach((col) => {
                  let values = item[col.field.name];
                  if (values) {
                    let el = values[index];
                    if (el) {
                      for (const [key, value] of Object.entries(el)) {
                        obj[key] = value;
                      }
                    }
                  }
                });

                tableData.push({ ...obj, _index: _index });
              }
            } else {
              tableData.push({ ...item, _index: _index });
            }
          });

          this.tableData = tableData;
        }
      });
    },
    save() {
      if (!this.key) {
        this.$message.error("请选择一条数据");
        return;
      }
      let columnsData = this.tableData.find((item) => item._id == this.key);

      if (!columnsData) {
        columnsData = {};
      }

      this.$set(this, "columnData", columnsData);

      if (this.inJsonForm) {
        this.item.filledRule
          .filter((item) => item.relyFieldName.indexOf(".") == -1)
          .forEach((item) => {
            // 非子表单

            let fieldName = item.fieldName.split(".")[1];

            this.$set(this.row, fieldName, columnsData[item.relyFieldName]);
            // let columnDataItem = getColumnDataByName(fieldName, columnsData);

            // if (columnDataItem) {
            //   this.$set(this.row, columnDataItem.name, columnDataItem.data);
            // }
          });
        this.$set(this.row, this.item.name + "ReferenceData", columnsData);
      } else {
        this.item.filledRule
          .filter((item) => item.relyFieldName.indexOf(".") == -1)
          .forEach((item) => {
            // 非子表单
            this.$set(
              this.form,
              item.fieldName,
              columnsData[item.relyFieldName],
            );
          });
        // 子表单
        let list = [];

        this.item.filledRule
          .filter((item) => item.fieldName.indexOf(".") >= 0)
          .forEach((item) => {
            let names = item.fieldName.split(".");
            let relyNames = item.relyFieldName.split(".");
            let obj = list.find((row) => row.fieldName == names[0]);
            if (!obj) {
              obj = {
                fieldName: names[0],
                relyFieldName: relyNames[0],
                columns: [
                  {
                    fieldName: names[1],
                    relyFieldName: relyNames[1],
                  },
                ],
              };
              list.push(obj);
            } else {
              obj.columns.push({
                fieldName: names[1],
                relyFieldName: relyNames[1],
              });
            }
          });

        list.forEach((item) => {
          let data = columnsData[item.relyFieldName];
          if (data) {
            let tableData = [];
            data.forEach((row) => {
              let obj = {};
              item.columns.forEach((col) => {
                obj[col.fieldName] = row[col.relyFieldName];
              });
              tableData.push(obj);
              this.$set(this.form, item.fieldName, tableData);
            });
          }
        });

        this.$set(this.form, this.item.name + "ReferenceData", columnsData);
      }
      this.dialogVisible = false;
    },
    getValue(row, field) {
      let rowObj = row;
      if (field.componentName == "address_input") {
        let obj = rowObj[field.name];
        if (obj) {
          let str = `${obj.province}`;
          if (obj.city) {
            str += obj.city;
          }
          if (obj.county) {
            str += obj.county;
          }
          if (obj.address) {
            str += obj.address;
          }
          return str;
        }
        return;
      } else if (
        field.componentName == "checkbox_group" ||
        field.componentName == "select_checkbox"
      ) {
        let a = rowObj[field.name];

        if (typeof a === "string") {
          return JSON.parse(a).join(",");
        } else if (typeof a === "object") {
          return a.join(",");
        }
      } else if (field.componentName == "position_input") {
        let data = rowObj[field.name];
        if (data) {
          return data.address;
        }
        return;
      } else if (field.componentName == "user_list_select") {
        let value = rowObj[field.name + "UserMap"];
        if (value) {
          let list = Object.values(value).map((item) => item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "user_select") {
        let value = rowObj[field.name + "UserInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_select") {
        let value = rowObj[field.name + "DepartmentInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_list_select") {
        let value = rowObj[field.name + "DepartmentInfoList"];
        if (value) {
          let list = value.map((item) => item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "date_picker") {
        let value = rowObj[field.name];
        if (value) {
          let picker;
          if (field.component) {
            picker = field.component.picker;
          }

          if (picker) {
            value = moment(value).format(this.getMomentFromat(picker));
          }
          return value;
        }
        return;
      } else {
        return rowObj[field.name];
      }
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-hidden {
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep {
    .el-radio__label {
      display: none;
    }
  }
}

.json-row-cell {
  padding: 12px 10px;
  text-align: center;
  box-sizing: border-box;
}
</style>
