<template>
  <div
    class="button-setting-icon-div"
    @click="buttonClick"
    :style="buttonWrapStyle"
    :class="{
      up_down: globalConfig.groupStyle.layout == 'up_down',
      left_right: globalConfig.groupStyle.layout == 'left_right',
    }"
  >
    <div class="button-icon-container">
      <div
        class="button-image-icon"
        v-if="
          itemConfig.buttonStyle && itemConfig.buttonStyle.type === 'ICON_URL'
        "
        :style="{
          'background-image': 'url(' + itemConfig.buttonStyle.icon + ')',
        }"
      ></div>

      <div v-else :style="iconWrapStyle" class="button-icon-wrap">
        <i
          :class="iconClassName"
          style="color: #fff; font-size: 20px; margin-right: 0"
        ></i>
      </div>
    </div>

    <span
      class="button-title"
      :style="isH5 ? '' : `color:${globalConfig?.colorConfig?.contentColor}`"
      :class="{
        up_down: globalConfig.groupStyle.layout == 'up_down',
        left_right: globalConfig.groupStyle.layout == 'left_right',
      }"
    >
      {{ itemConfig.title }}
    </span>
  </div>
</template>

<script>
export default {
  //
  name: "button-group-button-item",
  props: {
    itemConfig: {
      type: Object,
      default() {
        return {
          className: "icon-briefcase-fill",
          color: "#3870EA",
        };
      },
    },
    globalConfig: {
      type: Object,
      default: {
        groupStyle: {
          shape: "round",
          layout: "up_down",
        },
      },
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClassName() {
      return this.buttonStyle.className.startsWith("el")
        ? this.buttonStyle.className
        : "iconfont " + this.buttonStyle.className;
    },
    buttonStyle() {
      return (
        this.itemConfig.buttonStyle || {
          className: "icon-briefcase-fill",
          color: "#3870EA",
        }
      );
    },
    buttonWrapStyle() {
      return {
        "flex-direction":
          this.globalConfig.groupStyle.layout == "up_down" ? "column" : "row",
        width:
          this.globalConfig.groupStyle.layout == "left_right"
            ? "220px"
            : "118px",
        height:
          this.globalConfig.groupStyle.layout == "left_right"
            ? "60px"
            : "114px",
      };
    },
    iconWrapStyle() {
      return {
        "border-radius":
          this.globalConfig.groupStyle.shape == "round" ? "50%" : "8px",
        "background-color": this.buttonStyle.color,
      };
    },
  },
  data() {
    return {};
  },

  methods: {
    buttonClick() {
      console.log("buttonClick");
      this.$emit("buttonClick");
    },
  },
};
</script>

<style scoped lang="scss">
.button-setting-icon-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.up_down {
    padding: 20px 16px 6px;
    gap: 8px;
  }
  &.left_right {
    padding: 10px 16px;
    gap: 8px;
  }
}

.button-icon-container {
  width: 40px;
  height: 40px;
  .button-image-icon {
    width: 100%;
    height: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
  }

  .button-icon-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    transition: all 0.5s ease;
  }
}
.select-icon {
  position: relative;
}
.button-title {
  font-size: 12px;
  color: #666;
  text-align: left;
  &.up_down {
    height: 40px;
    width: 86px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #172b4d;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  &.left_right {
    width: 140px;
    font-size: 14px;
    line-height: 24px;
    color: #172b4d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
