<template>
  <div style="height: 100%">
    <metrics
      v-if="view.componentName == 'metric_table'"
      :isH5="isH5"
      :view="view"
      ref="chart"
    ></metrics>
    <chart-column
      v-else-if="view.componentName == 'chart_column'"
      :isH5="isH5"
      ref="chart"
    ></chart-column>
    <chart-bar
      v-else-if="view.componentName == 'chart_bar'"
      :isH5="isH5"
      ref="chart"
    ></chart-bar>
    <chart-line
      v-else-if="view.componentName == 'chart_line'"
      :isH5="isH5"
      ref="chart"
    ></chart-line>
    <chart-area
      v-else-if="view.componentName == 'chart_area'"
      :isH5="isH5"
      ref="chart"
    ></chart-area>
    <chart-pie
      v-else-if="view.componentName == 'chart_pie'"
      :isH5="isH5"
      ref="chart"
    ></chart-pie>
    <chart-radar
      v-else-if="view.componentName == 'chart_radar'"
      :isH5="isH5"
      ref="chart"
    ></chart-radar>
    <pivot-table
      v-else-if="view.componentName == 'pivot_table'"
      :isH5="isH5"
      ref="chart"
    ></pivot-table>
    <chart-process
      v-else-if="view.componentName == 'chart_process'"
      :isH5="isH5"
      ref="chart"
    ></chart-process>
    <chart-multi-axes
      v-else-if="view.componentName == 'chart_multi_axes'"
      :isH5="isH5"
      ref="chart"
    >
    </chart-multi-axes>
    <gantt-chart
      v-else-if="view.componentName == 'gantt'"
      :isH5="isH5"
      ref="chart"
    ></gantt-chart>
    <chart-map
      v-else-if="view.componentName == 'chart_map'"
      :view="view"
      :isH5="isH5"
      ref="chart"
    ></chart-map>
    <dashboardFilter
      v-else-if="view.componentName == 'dashboard_filter'"
      :isH5="isH5"
      ref="chart"
      :dashboard-filter-model="view"
      :filter-component-list="filterComponentList"
      @refreshDashboard="refreshDashboard"
      @clearFilterCompsValue="clearFilterCompsValue"
    ></dashboardFilter>
    <freeText
      v-else-if="view.componentName == 'html'"
      :isH5="isH5"
      ref="chart"
      :free-text-model="view"
    ></freeText>
    <countDown
      v-else-if="view.componentName == 'count_down'"
      :isH5="isH5"
      ref="chart"
      :count-down-model="view"
    ></countDown>
    <topic
      v-else-if="view.componentName == 'title_view'"
      :isH5="isH5"
      ref="chart"
      :dashboard-topic-model="view"
    ></topic>
    <detail-table
      v-else-if="view.componentName == 'table'"
      :isH5="isH5"
      ref="chart"
    ></detail-table>
    <dashboardCarousel
      v-else-if="view.componentName == 'carousel_view'"
      ref="chart"
      :config="view"
    >
    </dashboardCarousel>
    <dashboard-include
      v-else-if="view.componentName == 'include'"
      ref="chart"
      :config="view"
    >
    </dashboard-include>
    <dashboard-button-group
      v-else-if="view.componentName == 'button_group'"
      :isH5="isH5"
      ref="chart"
      :config="view"
    >
    </dashboard-button-group>
  </div>
</template>
<script>
import metrics from "@/views/lowCode/view/components/metrics.vue";
import chartColumn from "@/views/lowCode/view/components/chartColumn.vue";
import chartBar from "@/views/lowCode/view/components/chartBar.vue";
import chartLine from "@/views/lowCode/view/components/chartLine.vue";
import chartArea from "@/views/lowCode/view/components/chartArea.vue";
import chartPie from "@/views/lowCode/view/components/chartPie.vue";
import chartProcess from "@/views/lowCode/view/components/chartProcess.vue";
import chartRadar from "@/views/lowCode/view/components/chartRadar.vue";
import pivotTable from "@/views/lowCode/view/components/pivotTable.vue";
import ganttChart from "@/views/lowCode/dashboard/ganttChart.vue";
import chartMap from "@/views/lowCode/view/components/chartMap.vue";
import dashboardFilter from "@/views/lowCode/dashboard/dashboard-filter/dashboard-filter.vue";
import freeText from "@/views/lowCode/dashboard/add-component/dashboard-free-text/free-text.vue";
import countDown from "@/views/lowCode/dashboard/add-component/dashboard_count_down/count_down.vue";
import topic from "@/views/lowCode/dashboard/add-component/dashboard_topic/topic.vue";
import detailTable from "@/views/lowCode/view/components/detailTable.vue";
import dashboardCarousel from "@/views/lowCode/dashboard/add-component/dashboard-carousel/dashboard-carousel.vue";
import DashboardInclude from "@/views/lowCode/dashboard/add-component/dashboard-include/dashboard-include.vue";
import DashboardButtonGroup from "@/views/lowCode/dashboard/add-component/dashboard-button-group/dashboard-button-group.vue";
import ChartMultiAxes from "@/views/lowCode/view/components/chartMultiAxes.vue";

export default {
  components: {
    metrics,
    chartColumn,
    chartBar,
    chartLine,
    chartArea,
    chartPie,
    chartProcess,
    ChartMultiAxes,
    chartRadar,
    pivotTable,
    ganttChart,
    chartMap,
    dashboardFilter,
    freeText,
    countDown,
    topic,
    detailTable,
    dashboardCarousel,
    DashboardInclude,
    DashboardButtonGroup,
  },
  props: {
    view: {
      type: Object,
      default: () => {},
    },
    isH5: {
      type: Boolean,
      default: false,
    },
    refreshDashboard: {
      type: Function,
      default() {
        return () => "";
      },
    },
    clearFilterCompsValue: {
      type: Function,
      default() {
        return () => "";
      },
    },
    filterComponentList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
  methods: {
    getData(view, dataFilter) {
      this.$nextTick(() => {
        this.$refs.chart && this.$refs.chart.getData(view, dataFilter);
      });
    },
    resizeChart() {
      if (
        this.$refs.chart &&
        typeof this.$refs.chart.resizeChart === "function"
      ) {
        this.$refs.chart.resizeChart();
      }
    },
    handleFieldQueryOperChange() {
      this.$refs.chart && this.$refs.chart.handleFieldQueryOperChange();
    },
  },
};
</script>
