<template>
  <div v-if="lockValueType" class="weui el-prefix" style="width: 49px">
    <div v-if="field.valueType == 'tableField'" class="icon-form">A</div>
    <i v-else class="el-icon-edit-outline icon-edit"></i>
    <i
      class="el-icon-caret-bottom"
      style="color: #91a1b7; margin-left: 2px"
    ></i>
  </div>

  <el-popover
    popper-class="pop-over"
    placement="bottom-start"
    v-model="field.popverVisible"
    trigger="click"
    v-else
  >
    <div slot="reference" class="weui el-prefix" style="width: 49px">
      <div v-if="field.valueType == 'tableField'" class="icon-form">A</div>
      <i v-else class="el-icon-edit-outline icon-edit"></i>
      <i
        class="el-icon-caret-bottom"
        style="color: #91a1b7; margin-left: 2px"
      ></i>
    </div>
    <div style="padding: 5px 0">
      <div @click="checkItemType('tableField')" class="weui icon-item">
        <div class="icon-form">A</div>
        <div>当前表单</div>
      </div>
      <div @click="checkItemType('custom')" class="weui icon-item">
        <i class="el-icon-edit-outline icon-edit"></i>
        <div>自定义</div>
      </div>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    /** 锁定值类型，如果锁定了值类型就不能切换值类型 */
    lockValueType: {
      type: Boolean,
      default() {
        return false;
      },
    },
    field: Object,
  },
  methods: {
    checkItemType(type) {
      this.$set(this.field, "valueType", type);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui {
  display: flex;
  align-items: center;
}
.el-prefix {
  border: 1px solid #dcdfe6;
  margin-right: 8px;
  height: 32px;
  box-sizing: border-box;
  padding-left: 8px;
  border-radius: 2px;
}
.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}

.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
.icon-item {
  padding: 2px 8px;
  cursor: pointer;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.05);
  }
}
</style>
