<template>
  <div class="formula-codemirror">
    <codemirror
      ref="codeMirror"
      v-model="content"
      :options="options"
      @cursorActivity="onCursorActivity"
    >
    </codemirror>
    <div class="code-mirror-error">
      <div v-if="error" v-text="error" class="mirror-error"></div>
    </div>
  </div>
</template>
<script>
import CodeMirror from "codemirror";
import "codemirror/theme/monokai.css";
import "codemirror/theme/idea.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/lint/lint.js";
import "codemirror/addon/lint/lint.css";
// 代码高亮
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/selection/active-line.js";
import { relyFuns } from "@/zgg-core/relyFuns";
import { isEmpty, uuid } from "@/zgg-core/utils";
// 语法检查

import * as eslint from "eslint-linter-browserify";
import eslintRules from "./eslint-rules";
import {
  fieldRex,
  funRex,
  getFieldNameAndId,
  getFunNameAndId,
} from "./formula";

export default {
  props: {
    height: {
      type: Number,
      default: 200,
    },
    /**
     * 根据name获取字段信息
     */
    getFieldByName: Function,
    /**
     * 自动提示公式关键字
     */
    isRelyAutoTip: {
      type: Boolean,
      default: true,
    },
    jsonFormName: {
      type: String,
      default() {
        return "";
      },
    },
    buildOriginalFormula: Function, // 获取公式树
    getCollectionTitle: Function, // 获取表单名称
    getNodeTitle: Function, // 获取节点名称
    relyType: {
      type: String,
      default() {
        return "formRely";
      },
    },
    disabled: Boolean,
  },
  data() {
    return {
      content: "",
      options: {
        theme: "idea", // 编辑器主题
        lint: true, // 开启语法检查
        // readOnly:this.readOnly,       // 是否只可读
        lineNumbers: false, // 显示行号
        gutters: ["CodeMirror-lint-markers"], // 语法检查器，可提示错误信息
        indentUnit: 2, // 缩进单位为2
        smartIndent: false, // 自动缩进是否开启
        matchBrackets: true, // 括号匹配
        readOnly: this.disabled,
        autoRefresh: true,
        tabSize: 4, // tabsize默认为4
        styleActiveLine: true,
        line: true,
        mode: "javascript", // 选择代码语言
        // extraKeys: { Ctrl: "autocomplete", A: "autocomplete" }, //自动提示配置
        lineWrapping: true, // 自动换行
        // theme: 'base16-light', // 主题根据需要自行配置
        hintOptions: {
          // 自定义提示选项
          hint: this.hint,
        },
      },
      formulaList: [], // 函数关键字列表
      error: "",
      showHint: false,
    };
  },
  created() {
    this.options.height = this.height;
    relyFuns.forEach((item) => {
      item.children.forEach((child) => {
        this.formulaList.push(child.value);
      });
    });
  },
  mounted() {
    let codemirror = this.$refs.codeMirror.codemirror;

    /**
     * 获取函数对象
     * @param {*} name
     */
    let getFunItemByName = (name) => {
      let obj;
      for (let i = 0; i < relyFuns.length; i++) {
        let element = relyFuns[i];
        for (let j = 0; j < element.children.length; j++) {
          const item = element.children[j];
          if (item.value == name) {
            obj = item;
            break;
          }
        }
        if (obj) {
          break;
        }
      }
      return obj;
    };

    codemirror.setSize("auto", this.height);

    codemirror.on("change", (cm, t) => {
      if (t.origin == "paste") {
        // 黏贴动作
        t.text.forEach((line, index) => {
          let old = line;
          // 替换函数

          let funArr = line.match(funRex);
          if (funArr) {
            funArr.forEach((val) => {
              let { id } = getFunNameAndId(val);
              line = line.replace(id, uuid());
            });
          }

          let fieldArr = line.match(fieldRex);
          if (fieldArr) {
            fieldArr.forEach((val) => {
              let { id } = getFieldNameAndId(val);
              line = line.replace(id, uuid());
            });
          }

          let startPos = {
            line: t.from.line + index,
            ch: index == 0 ? t.from.ch : 0,
          };
          let endPos = {
            line: t.from.line + index,
            ch: index ? line.length : t.from.ch + line.length,
          };

          codemirror.replaceRange(line, startPos, endPos, old);
        });
      } else if (t.origin == "complete") {
        let cursor = cm.getCursor();
        let word = t.text[0];
        let replaceStr = `$fun_${uuid()}_${word}$()`;
        let endPos = {
          line: cursor.line,
          ch: t.from.ch + word.length,
        };
        codemirror.replaceRange(replaceStr, t.from, endPos, word);
        codemirror.setCursor({
          line: cm.getCursor().line,
          ch: t.from.ch + replaceStr.length - 1,
        });
      }
    });
    codemirror.on("keyup", (cm, event) => {
      if (
        !cm.state.completionActive &&
        ((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8)
      ) {
        // 所有的字母在键按下之后都将触发自动完成

        CodeMirror.commands.autocomplete(cm, null, { completeSingle: false });
      }
    });

    const linter = new eslint.Linter();

    CodeMirror.registerHelper("lint", "javascript", (text, options) => {
      let fields = text.match(fieldRex);
      let funs = text.match(funRex);
      let gs = {};

      if (funs) {
        funs.forEach((val) => {
          gs[val] = "readonly";
        });
      }
      if (fields) {
        fields.forEach((val) => {
          gs[val] = "readonly";
        });
      }

      const messages = linter.verify(
        text.replace(/\n/gm, ""),
        {
          rules: eslintRules,
          globals: gs,
        },
        { filename: "test.js" },
      );
      let output = [];
      let errorIDs = [];
      messages.forEach((item) => {
        let message = "";
        if (fieldRex.test(item.message) || funRex.test(item.message)) {
          let arr = item.message.match(fieldRex);
          if (!arr) {
            arr = [];
          }
          arr.forEach((val) => {
            let { id } = getFieldNameAndId(val);
            if (!errorIDs.includes(id)) {
              errorIDs.push(id);
            }
          });

          let funArr = item.message.match(funRex);
          if (funArr) {
            funArr.forEach((val) => {
              let { id } = getFunNameAndId(val);
              if (!errorIDs.includes(id)) {
                errorIDs.push(id);
              }
            });
          }

          message = "语法错误，缺少标识符";
        } else {
          message = item.message;
        }

        let error = {
          severity: "error",
          message: message,
          from: CodeMirror.Pos(item.line - 1, item.column - 1),
        };
        let to;
        if (item.endLine !== undefined && item.endColumn !== undefined) {
          to = CodeMirror.Pos(item.endLine - 1, item.endColumn - 1);
        } else {
          if (text.length > item.column) {
            to = CodeMirror.Pos(item.line - 1, text.length - 1);
          } else {
            to = CodeMirror.Pos(item.line - 1, item.column - 1);
            error.from.ch = error.from.ch - 1;
          }
        }
        error.to = to;
        output.push(error);
      });
      if (output && output.length) {
        this.error = output[0].message;
        let lineCount = codemirror.lineCount();
        let errors = [];
        output.forEach((item) => {
          let end = 0;
          for (let i = 0; i < lineCount; i++) {
            let lineText = codemirror.getLine(i);

            let start = end;
            end += lineText.length;

            if (item.from.ch >= end - lineText.length && item.to.ch <= end) {
              // 当前行
              errors.push({
                from: {
                  line: i,
                  ch: item.from.ch - start,
                },
                to: {
                  line: i,
                  ch: item.to.ch - start,
                },

                message: item.message,
                severity: item.severity,
              });
            } else if (
              item.from.ch <= end &&
              item.from.ch >= end - lineText.length
            ) {
              //
              errors.push({
                from: {
                  line: i,
                  ch: item.from.ch - start,
                },
                to: {
                  line: i,
                  ch: end - start,
                },

                message: item.message,
                severity: item.severity,
              });
            } else if (
              item.to.ch <= end &&
              item.to.ch >= end - lineText.length
            ) {
              errors.push({
                from: {
                  line: i,
                  ch: 0,
                },
                to: {
                  line: i,
                  ch: item.to.ch - start,
                },

                message: item.message,
                severity: item.severity,
              });
            }
          }
        });
        output = errors;
      } else {
        // 语法校验通过之后进行函数校验
        let errors = [];
        if (typeof this.buildOriginalFormula === "function") {
          let { tree } = this.buildOriginalFormula();
          let buildFunErrors = (list) => {
            for (let i = 0; i < list.length; i++) {
              let item = list[i];
              if (item.children) {
                let arr = buildFunErrors(item.children);
                if (arr && arr.length) {
                  break;
                } else if (item.type == "fun") {
                  let fun = getFunItemByName(item.value);

                  if (fun && typeof fun.validator === "function") {
                    let errs = fun.validator.bind({
                      jsonFormName: this.jsonFormName,
                    })(...item.children);
                    let id = item.id;
                    errs.forEach((err) => {
                      if (!isEmpty(err.reason)) {
                        if (!errorIDs.includes(id)) {
                          errorIDs.push(id);
                        }
                      } else {
                        if (err.field.type == "field") {
                          if (!errorIDs.includes(err.field.id)) {
                            errorIDs.push(err.field.id);
                          }
                        } else {
                          output.push({
                            severity: "error",
                            message: err.message,
                            from: CodeMirror.Pos(err.line, err.from),
                            to: CodeMirror.Pos(err.line, err.to + 2),
                          });
                        }
                      }
                    });
                    if (errs && errs.length) {
                      errors = errors.concat(errs);
                    } else {
                      // 移除错误信息
                    }
                    if (errs && errs.length) {
                      return errs;
                    }
                  }
                }
              }
            }
          };
          buildFunErrors(tree);
        }
        if (errors && errors.length) {
          this.error = errors[0].message;
        } else {
          this.error = "";
        }
      }

      if (fields) {
        fields.forEach((val) => {
          let { id, fieldName } = getFieldNameAndId(val);
          let $dom = document.getElementById(id);
          let field = this.getFieldByName(fieldName);
          if (!$dom) {
            return;
          }
          if (errorIDs.includes(id)) {
            if (!$dom.classList.contains("code-field-error")) {
              $dom.classList.add("code-field-error");
            }
          } else if (!(field && field.delete)) {
            // 没错，并且字段没有被删除
            if ($dom.classList.contains("code-field-error")) {
              $dom.classList.remove("code-field-error");
            }
          } else {
            // 存在被删除字段
            if (!this.error) {
              this.error = "存在被删除字段";
            }
          }
        });
      }

      if (funs) {
        funs.forEach((val) => {
          let { id } = getFunNameAndId(val);
          let $dom = document.getElementById(id);
          if (!$dom) {
            return;
          }
          if (errorIDs.includes(id)) {
            if (!$dom.classList.contains("code-field-error")) {
              $dom.classList.add("code-field-error");
            }
          } else {
            if ($dom.classList.contains("code-field-error")) {
              $dom.classList.remove("code-field-error");
            }
          }
        });
      }

      return output;
    });
  },
  methods: {
    onCursorActivity(cm) {
      this.domConfig = {};
      this.initFormulaData(this.content);
      this.initFieldData(this.content);
    },

    /**
     * 初始化字段展示
     * @param {*} str
     */
    initFieldData(str) {
      if (isEmpty(str)) {
        return;
      }

      let result = str.match(fieldRex);

      if (result) {
        result = [...new Set(result)];
        result.forEach((name) => {
          let { fieldName, id } = getFieldNameAndId(name);

          let field = this.getFieldByName(fieldName);
          let regex = new RegExp(`\\$field_${id}_${fieldName}\\$`, "g");
          let codemirror = this.$refs.codeMirror.codemirror;

          for (let i = 0; i < codemirror.lineCount(); i++) {
            let line = codemirror.getLine(i); // 获取当前行的文本
            let match;

            while ((match = regex.exec(line))) {
              // 在当前行中查找匹配项

              let startPos = { line: i, ch: match.index }; // 匹配项的起始位置
              let endPos = { line: i, ch: match.index + match[0].length }; // 匹配项的结束位置
              this.domConfig[id] = endPos;
              let dom = document.createElement("span");
              dom.addEventListener("click", () => {
                codemirror.focus();
                let pos = this.domConfig[id];
                codemirror.setCursor({
                  line: pos.line,
                  ch: pos.ch,
                });
              });
              dom.id = id;

              if (field) {
                let text = field.value;
                if (
                  typeof this.getCollectionTitle === "function" &&
                  field.collection
                ) {
                  let title = this.getCollectionTitle(field.collection);
                  if (!isEmpty(title)) {
                    text = title + "." + text;
                  }
                }
                if (typeof this.getNodeTitle === "function" && field.nodeKey) {
                  let title = this.getNodeTitle(field.nodeKey);
                  if (!isEmpty(title)) {
                    text = title + "." + text;
                  }
                }

                if (field.delete) {
                  dom.className = "code-field  code-field-error";
                  dom.innerText = text + "(已删除)";
                } else {
                  dom.className = "code-field";
                  dom.innerText = text;
                }
              } else {
                dom.className = "code-field code-field-error";
                dom.innerText = "field(已删除)";
              }

              this.$refs.codeMirror.codemirror.markText(startPos, endPos, {
                handleMouseEvents: false,
                readonly: true,
                replacedWith: dom,
              });
            }
          }
        });
      }
    },
    /**
     * 初始化函数展示
     * @param {*} str
     */
    initFormulaData(str) {
      if (isEmpty(str)) {
        return;
      }

      let result = str.match(funRex);

      if (result) {
        result = [...new Set(result)];
        let codemirror = this.$refs.codeMirror.codemirror;

        result.forEach((name) => {
          let { funName, id } = getFunNameAndId(name);
          if (this.formulaList.includes(funName)) {
            let regex = new RegExp(`\\$fun_${id}_${funName}\\$`, "g");

            for (let i = 0; i < codemirror.lineCount(); i++) {
              let line = codemirror.getLine(i); // 获取当前行的文本
              let match;

              while ((match = regex.exec(line))) {
                // 在当前行中查找匹配项

                let startPos = { line: i, ch: match.index }; // 匹配项的起始位置
                let endPos = { line: i, ch: match.index + match[0].length }; // 匹配项的结束位置
                let dom = document.createElement("span");
                dom.className = "cm-fun-field";
                dom.innerText = funName;
                dom.id = id;
                this.domConfig[id] = endPos;
                dom.addEventListener("click", () => {
                  codemirror.focus();
                  let pos = this.domConfig[id];
                  codemirror.setCursor({
                    line: pos.line,
                    ch: pos.ch,
                  });
                });

                this.$refs.codeMirror.codemirror.markText(startPos, endPos, {
                  handleMouseEvents: false,
                  readonly: true,
                  replacedWith: dom,
                });
              }
            }
          }
        });
      }
    },
    hint(cm, option) {
      return new Promise((resolve) => {
        setTimeout(() => {
          let cursor = cm.getCursor();
          let line = cm.getLine(cursor.line);

          let start = cursor.ch;
          let end = cursor.ch;

          while (start && /\w/.test(line.charAt(start - 1))) --start;
          while (end < line.length && /\w/.test(line.charAt(end))) ++end;
          var word = line.slice(start, end);

          if (!isEmpty(word)) {
            let list = this.formulaList.filter(
              (val) => val.toLowerCase().indexOf(word.toLowerCase()) >= 0,
            );

            if (list.length) {
              this.showHint = true;
              return resolve({
                list: list,
                from: CodeMirror.Pos(cursor.line, start),
                to: CodeMirror.Pos(cursor.line, end),
              });
            }
          }
          this.showHint = false;
          return resolve(null);
        }, 10);
      });
    },
    insert(row) {
      const pos1 = this.$refs.codeMirror.codemirror.getCursor();
      const pos2 = {};
      pos2.line = pos1.line;
      pos2.ch = pos1.ch;
      let ch;
      if (["fun", "field"].includes(row.type)) {
        let bookMark;
        if (row.type == "fun") {
          bookMark = `$fun_${uuid()}_${row.name}$()`;
          ch = pos2.ch + bookMark.length - 1;
        } else if (row.type == "field") {
          let str = "";

          if (row.fieldType !== "relyField") {
            if (row.collection) {
              str += "collection_" + row.collection;
            }
          }

          let parent = row.parent;
          while (parent) {
            // str =
            if (
              ["json_form", "reference_data"].includes(parent.componentName)
            ) {
              if (str != "") {
                str = "_" + str;
              }
            }

            if (parent.componentName == "json_form") {
              str = `jsonForm_${parent.name}` + str;
            } else if (parent.componentName == "reference_data") {
              str = `referenceData_${parent.name}` + str;
            }

            parent = parent.parent;
          }

          // 智能助手2.0 节点key
          if (!isEmpty(row.nodeKey)) {
            if (!isEmpty(str)) {
              str = `nodeKey_${row.nodeKey}_` + str;
            } else {
              str = `nodeKey_${row.nodeKey}`;
            }
          }

          if (str != "") {
            str += "_";
          }
          str += `fieldName_${row.name}`;
          str = `$field_${uuid()}_${str}$`;
          bookMark = str;
          ch = pos2.ch + bookMark.length;
        }
        if (bookMark) {
          this.$refs.codeMirror.codemirror.replaceRange(bookMark, pos2);

          this.$refs.codeMirror.codemirror.focus();

          this.$refs.codeMirror.codemirror.setCursor({
            line: pos1.line,
            ch: ch,
          });
        }
      }
    },
    getContent() {
      return {
        content: this.content,
      };
    },
    setContent(str) {
      this.content = str;
    },
  },
};
</script>
<style lang="scss" scoped>
.formula-codemirror {
  ::v-deep {
    .code-field-error {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJDw4cOCW1/KIAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHElEQVQI12NggIL/DAz/GdA5/xkY/qPKMDAwAADLZwf5rvm+LQAAAABJRU5ErkJggg==");
      background-position: bottom;
      background-repeat: repeat-x;
    }
    .cm-fun-field {
      color: #3870ea;
      &.code-field-error {
        color: #f04134;
      }
    }
    .code-field {
      background-color: #ebf5ff;
      color: #008dcd;
      padding: 2px 4px;
      margin: 0 3px;
      font-size: 12px;

      &.code-field-error {
        color: #f04134;
        background-color: rgba(254, 246, 246, 1);
      }
    }
    .vue-codemirror {
      border: 0 !important;
    }

    .code-mirror-error {
      border-left: solid #f7f7f7 16px;
    }
    .mirror-error {
      color: #8d3030;
      background: #fdeeee;
      font-size: 14px;
      height: 32px;
      padding: 6px 10px;
    }
  }
}
</style>
<style lang="scss">
.CodeMirror-hints {
  z-index: 99999 !important;
}
.CodeMirror-gutters {
  border-right: 0 !important;
}
</style>
