var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"etl-attribute"},[_c('div',{staticClass:"etl-header"},[_c('div',{staticClass:"weui",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"etl-icon"},[_c('i',{staticClass:"iconfont-fx-pc",class:`icon-data-${_vm.node.stageType}`})]),_c('div',{staticClass:"etl-name",domProps:{"textContent":_vm._s(_vm.nodeTitle)}}),(_vm.node.stageType != 'input' && _vm.node.stageType != 'output')?[_c('div',{staticClass:"etl-tab",class:_vm.tab == 'attribute' ? 'active' : '',on:{"click":function($event){_vm.tab = 'attribute'}}},[_vm._v(" 节点配置 ")]),_c('div',{staticClass:"etl-tab",class:_vm.tab == 'preview' ? 'active' : '',on:{"click":function($event){_vm.tab = 'preview'}}},[_vm._v(" 数据预览 ")])]:_vm._e(),(
          !['count', 'prevConfig'].includes(_vm.errorType) &&
          _vm.node.stageType != 'input'
        )?_c('div',{staticClass:"error-tip",domProps:{"textContent":_vm._s(_vm.errorText)}}):_vm._e()],2),_c('div',{staticClass:"weui"},[_c('div',{staticStyle:{"font-size":"14px","width":"90px"}},[_vm._v("节点名称")]),_c('el-input',{attrs:{"size":"small","placeholder":"请输入节点名称","disabled":_vm.node.stageType == 'input'},model:{value:(_vm.node.title),callback:function ($$v) {_vm.$set(_vm.node, "title", $$v)},expression:"node.title"}})],1)]),_c('div',{staticClass:"etl-attr-body"},[(
        _vm.node.stageType != 'input' &&
        ['count', 'prevConfig'].includes(_vm.errorType)
      )?_c('div',{staticClass:"error-info"},[_c('el-image',{staticStyle:{"width":"225px"},attrs:{"src":_vm.image}}),_c('div',{staticClass:"error-text",domProps:{"textContent":_vm._s(_vm.errorText)}})],1):[_vm._t("default")]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }