<template>
  <div class="attr-row" v-if="isShowAttrValue">
    <div class="attr-label">{{ attrTitle }}</div>
    <div v-if="parentLink || hasFrontEvent" class="input-tip">
      <span v-if="parentLink">以子表单联动为准，不支持设置默认值</span>
      <span v-if="hasFrontEvent">字段由前端事件赋值，无法设置公式联动</span>
    </div>
    <template v-else>
      <el-select
        v-model="valueType"
        placeholder=""
        size="mini"
        style="margin-bottom: 5px; width: 100%"
        :clearable="clearable"
      >
        <el-option
          v-for="item in valueTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <template v-if="valueType == 'custom'">
        <!-- 自定义默认值 -->

        <el-input
          v-if="
            ['input', 'text_area', 'mobile_input'].includes(
              component.componentName,
            )
          "
          v-model="component.defaultValue"
          size="mini"
          placeholder=""
          :type="component.componentName == 'text_area' ? 'textarea' : ''"
        ></el-input>
        <!-- <el-input-number
          v-else-if="component.componentName == 'input_number'"
          v-model="component.defaultValue"
          label="label"
          :controls="false"
          :precision="component.decimalPrecision"
          size="mini"
          style="width: 100%"
          class="text-left"
        ></el-input-number> -->
        <el-input
          v-else-if="component.componentName == 'input_number'"
          v-model="numValue"
          size="mini"
          placeholder=""
          style="width: 100%"
          @input="inputNumber"
        ></el-input>
        <el-select
          v-model="component.defaultCurrentPosition"
          v-else-if="component.componentName == 'position_input'"
          size="mini"
          style="width: 100%"
          placeholder=""
          clearable
        >
          <el-option :value="true" label="自动获取地址"></el-option>
        </el-select>
        <el-date-picker
          v-else-if="component.componentName == 'date_picker'"
          v-model="component.defaultValue"
          :type="
            component.picker == 'date_minute' ? 'datetime' : component.picker
          "
          size="mini"
          style="width: 100%"
        ></el-date-picker>
        <attr-address-value
          v-else-if="component.componentName == 'address_input'"
          :component="component"
          :areaTree="areaTree"
        ></attr-address-value>
        <user-select
          v-else-if="
            ['user_select', 'user_list_select'].includes(
              component.componentName,
            )
          "
          v-model="userValue"
          :tabs="['company']"
          :multi="component.componentName == 'user_list_select'"
          :dataFilter="userSelectFieldDataFilter"
          size="small"
        ></user-select>
        <dept-select
          v-else-if="
            ['department_select', 'department_list_select'].includes(
              component.componentName,
            )
          "
          v-model="deptValue"
          :multi="component.componentName == 'department_list_select'"
          size="small"
        ></dept-select>
      </template>
      <template v-else>
        <el-input
          v-if="isReferceField"
          disabled
          value="字段由关联数据赋值，无法设置公式联动"
          placeholder=""
          size="mini"
        ></el-input>
        <div v-else-if="hasFrontEvent" class="disabled-message">
          字段由前端事件赋值，无法设置公式联动
        </div>
        <!-- 公式 -->
        <template v-else-if="valueType == 'rely'">
          <el-input
            :value="hasRely ? '已设置公式' : 'fx编辑公式'"
            readonly
            suffix-icon="el-icon-edit-outline"
            size="mini"
            @click.native="openFormula"
          ></el-input>
          <!-- <rely-dialog
            :title="component.title"
            :dialogVisible.sync="relyVisible"
            :originComponents="originComponents"
            :name="component.name"
            :isJsonForm="!!parentField"
            :jsonFormName="parentField ? parentField.name : ''"
            :originalFormula="component.rely.originalFormula"
            :fetchMetaFieldComponentList="fetchMetaFieldComponentList"
            @save="saveRely"
          ></rely-dialog> -->
          <formula-dialog
            :title="component.title"
            :originalFormula="component.rely.originalFormula"
            :name="component.name"
            :treeNodeList="originComponents"
            :fetchMetaFieldComponentList="fetchMetaFieldComponentList"
            :isJsonForm="!!parentField"
            :jsonFormName="parentField ? parentField.name : ''"
            @save="saveRely"
            ref="relyDialog"
          ></formula-dialog>
        </template>
        <!-- 数据联动 -->
        <data-linkage
          :form="component"
          :componentList="originComponents"
          :areaTree="areaTree"
          v-else-if="valueType == 'dataLinkage'"
        ></data-linkage>
      </template>
    </template>

    <!-- 人员选择范围userRange -->
    <template
      v-if="
        component.componentName === 'user_select' ||
        component.componentName === 'user_list_select'
      "
    >
      <attrUserRange
        :fieldList="fieldList"
        :dataFilter="userSelectFieldDataFilter"
        :params="filterParams"
        @updateDataFilter="updateDataFilter"
      ></attrUserRange>
    </template>
  </div>
</template>
<script>
import { getFormDetail, getListOfContactForm } from "../api";
import dataLinkage from "../component/dataLinkage";
import { getFormComponents } from "../utils";
import { componentProps } from "./util";
import { mapState } from "vuex";
import attrAddressValue from "@/views/lowCode/form/attribute/attr-address-value";
import { departmentList } from "@/api/directoryConfig";
import { companyEmployeeList, employeeSearch } from "@/api/company";
import { contactListPage } from "@/api/contact";
import { groupList } from "@/api/group";
import { isEmpty } from "@zgg-core-utils/utils";
import { departmentListByUser, departmentTree } from "@/api/department";
import { listDepartmentInfo, listUserInfo } from "../../api";
import { fetchMetaFieldComponentList } from "@/api/form";
import test from "@/utils/test";
import { checkRelyComponentName } from "@/utils/tools";
// import formulaDialog from "@/zgg-core/formula/formula-dialog";
import attrUserRange from "@/components/user-range/index";
const excludeArr = [
  "select",
  "select_checkbox",
  "radio_group",
  "checkbox_group",
];
const passArray = ["divider", "sign_input", "form_data", "sign_list_input"];

export default {
  inject: ["getComponents", "getFormEvents"],
  name: "attr-value",
  props: componentProps,
  components: { dataLinkage, attrAddressValue, attrUserRange },
  provide() {
    return {
      departmentList,
      companyEmployeeList,
      contactListPage,
      departmentListByUser,
      departmentTree,
      getCurrentUser() {
        return this.$store.state.user;
      },
      getCompanyId: () => {
        return this.$store.state.user.currentCompanyId;
      },
      employeeSearch(params) {
        return employeeSearch({
          ...params,
          companyId: this.$store.state.user.currentCompanyId,
        });
      },
      groupList,
      loadCompany() {
        return Promise.resolve([
          {
            name: this.$store.state.user.currentCompanyName,
            key: "company-" + this.$store.state.user.currentCompanyId,
            nodeType: "company",
            isLeaf: false,
            id: this.$store.state.user.currentCompanyId,
          },
        ]);
      },
      getContactFormMetaFieldList: () => {
        return this.contactFormMetaFieldList;
      },
      sourceFormComponents: this.form?.components,
    };
  },
  computed: {
    ...mapState("area", ["areaProps"]),
    hasFrontEvent() {
      if (typeof this.getFormEvents === "function") {
        let formEvents = this.getFormEvents();
        if (formEvents && formEvents.length) {
          let name = this.component.name;
          if (this.parentField) {
            // 如果是子表单
            name = this.parentField.name + "." + name;
          }
          let item = formEvents.find((event) => {
            let index = event.action.findIndex((action) => {
              return action.field == name;
            });
            if (index > -1) {
              return event;
            }
          });
          if (item) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
      return false;
    },
    valueTypeOptions() {
      let list = [];
      let componentName = this.component.componentName;
      if (componentName === "date_picker") {
        list.push({
          value: "defaultCurrentDate",
          label: "填写当时",
        });
      }

      if (!excludeArr.includes(this.component.componentName)) {
        list.push({
          value: "custom",
          label: "自定义",
        });
      } else {
        list.push({
          value: "none",
          label: "未定义",
        });
      }

      if (!["address_input", "position_input"].includes(componentName)) {
        list.push({
          value: "rely",
          label: "公式",
        });
      }

      if (["user_select", "user_list_select"].includes(componentName)) {
        list.push({ value: "currentUser", label: "默认选择当前用户" });
      }

      if (
        ["department_select", "department_list_select"].includes(componentName)
      ) {
        list.push({ value: "currentDept", label: "默认当前用户所处部门" });
      }
      if (this.valueDataLink) {
        list.push({ value: "dataLinkage", label: "数据联动" });
      }
      return list;
    },
    originComponents() {
      let components = this._.cloneDeep(this.getComponents());
      let buildList = (components) => {
        if (components) {
          components.forEach((item) => {
            if (item.componentName == "summary_data") {
              item.form = true;
              item.name = item.key;
            } else if (item.container) {
              buildList(item.components);
            }
          });
        }
      };
      buildList(components);
      let nowComponents = getFormComponents(components);
      return nowComponents;
    },
    hasRely() {
      if (this.valueType != "rely") {
        return false;
      }
      let originalFormula = [];
      if (this.component.rely && this.component.rely.originalFormula) {
        originalFormula = this.component.rely.originalFormula;
      }
      let count = 0;
      originalFormula.forEach((item) => {
        if (item.length) {
          count++;
        }
      });
      if (count) {
        return true;
      }
      return false;
    },
    parentField() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      return components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key,
            ) >= 0,
        );
    },
    parentLink() {
      let isLink = false;
      if (this.parentField) {
        isLink =
          this.parentField.dataLinkage &&
          this.parentField.dataLinkage.subFieldRelyList &&
          this.parentField.dataLinkage.subFieldRelyList.findIndex(
            (col) => col.fieldName == this.component.name,
          ) >= 0;
      }
      return isLink;
    },
    isReferceField() {
      let components;
      if (this.parentField) {
        // 子表单的字段
        components = this.parentField.components;
      } else {
        components = getFormComponents(this.getComponents());
      }
      return (
        components.filter(
          (item) =>
            item.componentName == "reference_data" &&
            item.filledRule.findIndex(
              (col) => col.fieldName == this.component.name,
            ) >= 0,
        ).length > 0
      );
    },
    valueDataLink() {
      let arr = [
        "input",
        "text_area",
        "input_number",
        "mobile_input",
        "date_picker",
        "json_form",
        "address_input",
        "user_select",
        "user_list_select",
        "department_select",
        "department_list_select",
        "position_input",
      ];
      return arr.includes(this.component.componentName);
    },
    valueType: {
      get() {
        let str = "";
        if (this.component.dataLinkage) {
          str = "dataLinkage";
        } else if (this.component.defaultCurrentUser) {
          str = "currentUser";
        } else if (this.component.defaultCurrentUserDepartment) {
          str = "currentDept";
        } else if (this.component.rely && this.component.rely.originalFormula) {
          str = "rely";
        } else if (
          [
            "user_select",
            "user_list_select",
            "department_select",
            "department_list_select",
          ].includes(this.component.componentName)
        ) {
          str = "custom";
        } else if (excludeArr.includes(this.component.componentName)) {
          str = "none";
        } else if (this.component.defaultCurrentDate) {
          str = "defaultCurrentDate";
        } else {
          str = "custom";
        }
        return str;
      },
      set(val) {
        if (val == "") {
          if (!excludeArr.includes(this.component.componentName)) {
            this.component.defaultValue = "";
          }
          this.$set(this.component, "rely", null);
          this.$set(this.component, "dataLinkage", null);
          this.$set(this.component, "autoValueTemplate", null);
          this.$set(this.component, "defaultCurrentDate", false);
        } else {
          if (val == "rely") {
            if (!excludeArr.includes(this.component.componentName)) {
              this.component.defaultValue = "";
            }
            this.$set(this.component, "rely", { originalFormula: [] });
            this.$set(this.component, "dataLinkage", null);
            this.$set(this.component, "autoValueTemplate", null);
            this.$set(this.component, "defaultCurrentDate", false);
          } else if (val == "none") {
            this.$set(this.component, "rely", null);
            this.$set(this.component, "dataLinkage", null);
            this.$set(this.component, "autoValueTemplate", null);
            this.$set(this.component, "defaultCurrentDate", false);
          } else if (val == "custom") {
            this.$set(this.component, "rely", null);
            this.$set(this.component, "dataLinkage", null);
            this.$set(this.component, "autoValueTemplate", null);
            this.$set(this.component, "defaultCurrentDate", false);
            this.component.defaultValue = "";
          } else if (val == "autoValueTemplate") {
            this.$set(this.component, "rely", null);
            this.$set(this.component, "dataLinkage", null);
            this.$set(this.component, "autoValueTemplate", "");
            this.$set(this.component, "defaultCurrentDate", false);
            this.component.defaultValue = "";
          } else if (val == "dataLinkage") {
            this.$set(this.component, "rely", null);
            this.$set(this.component, "autoValueTemplate", null);
            this.$set(this.component, "dataLinkage", {});
            this.$set(this.component, "defaultCurrentDate", false);
            this.component.defaultValue = "";
          } else if (val == "defaultCurrentDate") {
            this.$set(this.component, "defaultCurrentDate", true);
            this.$set(this.component, "rely", null);
            this.$set(this.component, "autoValueTemplate", null);
            this.$set(this.component, "dataLinkage", null);
            this.component.defaultValue = "";
          }
        }
        if (
          ["user_select", "user_list_select"].includes(
            this.component.componentName,
          )
        ) {
          this.$set(this.component, "defaultCurrentUser", val == "currentUser");
          if (this.component.defaultCurrentUser) {
            this.component.defaultValue = "";
            this.$set(this.component, "rely", null);
            this.$set(this.component, "dataLinkage", null);
            this.$set(this.component, "autoValueTemplate", null);
          }
        }
        if (
          ["department_select", "department_list_select"].includes(
            this.component.componentName,
          )
        ) {
          this.$set(
            this.component,
            "defaultCurrentUserDepartment",
            val == "currentDept",
          );
          if (this.component.defaultCurrentUserDepartment) {
            this.component.defaultValue = "";
            this.$set(this.component, "rely", null);
            this.$set(this.component, "dataLinkage", null);
            this.$set(this.component, "autoValueTemplate", null);
          }
        }
      },
    },
    userValue: {
      get() {
        if (this.component.componentName == "user_list_select") {
          return this.userList;
        } else if (this.component.componentName == "user_select") {
          return this.userList[0];
        }
        return "";
      },
      set(val) {
        if (!isEmpty(val)) {
          if (this.component.componentName == "user_list_select") {
            this.component.defaultValue = val.map((item) => item.id);
            this.userList = val;
          } else if (this.component.componentName == "user_select") {
            this.component.defaultValue = val.id;
            this.userList = [val];
          }
        } else {
          this.component.defaultValue = "";
          this.userList = [];
        }
      },
    },
    deptValue: {
      get() {
        if (this.component.componentName == "department_list_select") {
          return this.deptList;
        } else if (this.component.componentName == "department_select") {
          return this.deptList[0];
        }
        return "";
      },
      set(val) {
        if (!isEmpty(val)) {
          if (this.component.componentName == "department_list_select") {
            this.component.defaultValue = val.map((item) => item.departmentId);
            this.deptList = val;
          } else if (this.component.componentName == "department_select") {
            this.component.defaultValue = val.departmentId;
            this.deptList = [val];
          }
        } else {
          this.component.defaultValue = "";
          this.deptList = [];
        }
      },
    },
    numValue: {
      get() {
        return this.component.defaultValue;
      },
      set(val) {
        let value = val;
        //修复输入小数点的时候，就触发校验
        if (value && value.endsWith && !value.endsWith(".")) {
          if (this.component.decimalPrecision && test.number(value)) {
            if (!this._.isEmpty(value)) {
              value = val.toString();
            } else {
              value = "";
            }
            let index = value.indexOf(".");
            let num = this.component.decimalPrecision;

            if (index > 0) {
              value = value.substring(0, index + num + 1);
            } else if (index == 0) {
              value = value.substring(0, index + num + 1);

              if (num == 0) {
                value = 0;
              } else {
                value = "0" + value;
              }
            }
          }
        }

        if (value === this.numValue) {
          return;
        }
        this.component.defaultValue = value;
      },
    },
    attrTitle() {
      let title = "默认值";
      if (excludeArr.includes(this.component.componentName)) {
        title = "公式";
      }
      return title;
    },
    isShowAttrValue() {
      if (
        excludeArr.includes(this.component.componentName) &&
        this.component.optionSource == "dataLinkage"
      ) {
        return false;
      }
      return true;
    },
    clearable() {
      if (excludeArr.includes(this.component.componentName)) {
        if (this.hasRely) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
  },
  data() {
    return {
      relyVisible: false,
      userList: [],
      deptList: [],
      fieldList: [],
      userSelectFieldDataFilter: {
        advanceQuery: [],
        rel: "and",
      },
      filterParams: {},
      contactFormMetaFieldList: [], // 通讯录人员表单元数据表字段
    };
  },
  async created() {
    console.log(this.component);
    let defaultValue = this.component.defaultValue;
    if (this.component.array || this.component.json) {
      if (typeof defaultValue === "string" && !isEmpty(defaultValue)) {
        defaultValue = JSON.parse(defaultValue);
      }
    }
    if (this.valueType == "custom" && !isEmpty(defaultValue)) {
      if (
        ["user_list_select", "user_select"].includes(
          this.component.componentName,
        )
      ) {
        let list = defaultValue;

        if (typeof list === "string") {
          list = [defaultValue];
        }
        listUserInfo({
          userIds: list,
        }).then((res) => {
          if (
            ["user_list_select", "user_select"].includes(
              this.component.componentName,
            )
          ) {
            this.userList = res.data.list;
          }
        });
      } else if (
        ["department_select", "department_list_select"].includes(
          this.component.componentName,
        )
      ) {
        let list = defaultValue;

        if (typeof list === "string") {
          list = [defaultValue];
        }
        listDepartmentInfo({
          departmentIds: list,
        }).then((res) => {
          this.deptList = res.data.list;
        });
      }
    }

    if (
      this.component.componentName === "user_select" ||
      this.component.componentName === "user_list_select"
    ) {
      if (this.component?.userRange?.userSelectFieldDataFilter) {
        this.userSelectFieldDataFilter = this._.cloneDeep(
          this.component?.userRange?.userSelectFieldDataFilter,
        );
      } else {
        this.$set(this.component, "userRange", {
          userSelectFieldDataFilter: {
            advanceQuery: [],
            rel: "and",
          },
        });
      }
      await this.getListOfContactForm();
    }
  },
  methods: {
    // 获取人员同步表字段
    async getListOfContactForm() {
      await Promise.all([
        getListOfContactForm({
          returnWithForm: true,
          returnWithSystemField: true,
        }),
        getListOfContactForm({
          returnWithForm: true,
        }),
      ]).then((resp) => {
        let list = [];
        if (resp?.[0]?.data?.metaFieldList) {
          let componentMetaList = resp?.[0]?.data?.metaFieldList.map(
            (item) => item.component,
          );
          let callback = (componentMetaList, parent) => {
            componentMetaList.forEach((item) => {
              if (item.container) {
                callback(item.components, item);
              } else if (item.form) {
                if (!passArray.includes(item.componentName)) {
                  let obj = this._.cloneDeep(item);
                  if (parent) {
                    obj.name = parent.name + "." + obj.name;
                    obj.title = parent.title + "." + obj.title;
                    obj.parent = this._.cloneDeep(parent);
                  }
                  list.push(obj);
                }
              } else if (item.componentName == "form_data") {
              }
            });
          };
          callback(componentMetaList);
          this.fieldList = list;
        }
        this.$set(this, "filterParams", {
          collection: resp?.[0]?.data?.contactForm?.id,
        });
        if (resp?.[1]?.data?.metaFieldList) {
          this.contactFormMetaFieldList = resp?.[1]?.data?.metaFieldList;
        }
      });
    },
    updateDataFilter(data) {
      this.$set(this.component, "userRange", {
        userSelectFieldDataFilter: this._.cloneDeep(data),
      });
      this.userSelectFieldDataFilter = this._.cloneDeep(data);
    },
    openFormula() {
      this.$refs.relyDialog.open();
    },
    getFormDetail,
    fetchMetaFieldComponentList,
    saveRely(originalFormula, componentNames) {
      let componentName = this.component.componentName;
      if (!checkRelyComponentName(componentName, componentNames)) {
        return;
      }

      this.$set(this.component.rely, "originalFormula", originalFormula);
      this.$refs.relyDialog.close();
    },
    inputNumber(value) {
      let val;
      val = value.replace(/[^\d.]/g, ""); //先把非数字的都替换掉，除了数字和.
      val = val.replace(/^\./g, ""); //必须保证第一个为数字而不是.
      val = val.replace(/\.{2,}/g, "."); //保证只有出现一个.而没有多个.
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");

      this.value = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-left {
  ::v-deep {
    .el-input__inner {
      text-align: left !important;
    }
  }
}
.input-tip {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 28px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  font-size: 12px;
  line-height: 28px;
  padding: 0 10px;
}
.disabled-message {
  color: #141e31;
  font-size: 14px;
  line-height: 20px;
  background: #f5f6f8;
  word-wrap: break-word;
  word-break: break-word;
}
</style>
