var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-drawer',{attrs:{"visible":_vm.drawer,"with-header":false,"append-to-body":true,"destroy-on-close":true,"size":900,"custom-class":"rpa-drawer"},on:{"update:visible":function($event){_vm.drawer=$event},"open":_vm.open}},[_c('div',{staticClass:"rpa-dw-header",class:_vm.bgClass},[_c('i',{class:_vm.typeIcon,staticStyle:{"font-size":"20px"}}),_c('div',{staticStyle:{"flex":"1","margin-left":"10px"}},[(_vm.isEditTitle)?_c('el-input',{attrs:{"placeholder":""},on:{"blur":_vm.endEditTitle},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}):_c('div',{staticClass:"node-title",domProps:{"textContent":_vm._s(_vm.form.title)},on:{"click":_vm.editTitle}})],1),_c('i',{staticClass:"el-icon-close pointer font-24",on:{"click":function($event){_vm.drawer = false}}})]),_c('div',{staticClass:"rpa-scroll"},[(
        [
          'trigger_form_data_change',
          'trigger_timer_once',
          'trigger_timer_cron',
          'trigger_timer_field',
          'trigger_webhook',
          'trigger_click',
        ].includes(_vm.form.type)
      )?_c('div',{staticClass:"trigger-header",class:_vm.bgClass},[_c('div',{staticClass:"rpa-radius",staticStyle:{"background-color":"#ffffff"}},[_c('i',{class:_vm.typeIcon,staticStyle:{"font-size":"24px"},style:(`color:${
            _vm.node.type == 'trigger_form_data_change' ? '#ffa340' : '#2196f3'
          }`)})]),_c('div',{staticStyle:{"color":"#ffffff","margin-top":"10px"},domProps:{"textContent":_vm._s(_vm.triggerTitle)}})]):_vm._e(),(!_vm.isWorkflowData)?_c('div',{staticClass:"rpa-body"},[(_vm.componentName)?_c(_vm.componentName,{ref:"form",tag:"component",attrs:{"node":_vm.form,"disabled":_vm.disabled}}):_vm._e()],1):_c('el-tabs',{staticClass:"drawer-tabs",model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"执行详情","name":"first"}},[_c('rpa-workflow-node-data',{attrs:{"nodeKey":_vm.node.key}})],1),_c('el-tab-pane',{attrs:{"label":"配置详情","name":"second"}},[(_vm.componentName)?_c(_vm.componentName,{ref:"form",tag:"component",attrs:{"node":_vm.form,"disabled":_vm.disabled}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"drawer-btns"},[(!_vm.disabled && _vm.node.type != 'trigger_subflow_collection')?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]):_vm._e(),_c('el-button',{on:{"click":function($event){_vm.drawer = false}}},[_vm._v("取消")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }