import { render, staticRenderFns } from "./metrics.vue?vue&type=template&id=c0613e4a&scoped=true"
import script from "./metrics.vue?vue&type=script&lang=js"
export * from "./metrics.vue?vue&type=script&lang=js"
import style0 from "./metrics.vue?vue&type=style&index=0&id=c0613e4a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0613e4a",
  null
  
)

export default component.exports