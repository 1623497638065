<template>
  <div>
    <el-popover
      popper-class="pop-trigger"
      v-model="visible"
      width="260"
      trigger="click"
    >
      <div class="pop-scroll" v-clickoutside="handleClose">
        <template v-if="filterColumns.length">
          <div
            v-for="item in filterColumns"
            :key="item.key"
            v-text="item.title"
            class="form-item"
            :class="{ disabled: isDisabeld(item.name) }"
            @click="checkComponent(item)"
          ></div>
        </template>
        <div v-else class="pop-empty">表单没有组件</div>
      </div>
      <el-button @click.stop="" slot="reference" type="text" icon="el-icon-plus"
        >添加过滤条件</el-button
      >
    </el-popover>
    <field-setting
      v-for="(item, index) in filterFieldValues"
      :key="item.key"
      :componentList="triggerComponents"
      :field="item"
      :triggerFormId="triggerFormId"
      :type="type"
      :index="index"
      :deleteField="deleteField"
      :checkFieldEnabel="checkFilterField"
      :areaTree="areaTree"
    ></field-setting>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import fieldSetting from "./fieldSetting";
import { spritFilter } from "@zgg-core-utils/whiteList";
export default {
  data() {
    return {
      visible: false,
    };
  },
  directives: { Clickoutside },
  components: { fieldSetting },
  props: {
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    triggerComponents: Array,
    filterFieldValues: Array,
    updateFieldValues: Array,
    formId: String,
    triggerFormId: String,
    type: String,
    deleteFilter: Function,
    areaTree: Array,
  },
  computed: {
    filterColumns() {
      return this.componentList.filter((item) =>
        spritFilter.includes(item.componentName)
      );
    },
  },

  methods: {
    deleteField(index) {
      if (
        this.filterFieldValues[index].tableField.fieldName.indexOf(".") >= 0 &&
        this.filterFieldValues.filter(
          (item) => item.tableField.fieldName.indexOf(".") >= 0
        ).length == 1 &&
        this.updateFieldValues.findIndex(
          (item) => item.tableField.fieldName.indexOf(".") >= 0
        ) >= 0
      ) {
        this.$confirm(
          "此操作会清除部分已设置的字段赋值方式，无法还原。",
          "确定要进行此操作吗？"
        )
          .then(() => {
            let updateFieldValues = this.updateFieldValues.filter(
              (item) => item.tableField.fieldName.indexOf(".") == -1
            );
            this.$emit("update:updateFieldValues", updateFieldValues);
            this.$delete(this.filterFieldValues, index);
          })
          .catch(() => {});
      } else {
        this.$delete(this.filterFieldValues, index);
      }
    },
    isDisabeld(name) {
      return (
        this.filterFieldValues.findIndex(
          (item) => item.tableField.fieldName == name
        ) >= 0
      );
    },
    handleClose() {
      this.visible = false;
    },
    checkComponent(item) {
      if (this.isDisabeld(item.name)) {
        return;
      }

      let tableField = {
        collection: this.formId,
        fieldName: item.name,
        fieldTitle: item.title,
        componentName: item.componentName,
        optionSource: item.optionSource,
        customOptions: item.customOptions,
      };

      if (item.picker) {
        tableField.picker = item.picker;
      }
      if (item.extParam) {
        tableField.extParam = item.extParam;
      }

      if (item.tableName) {
        tableField.referenceTableName = item.tableName;
      }

      let hasMoreChild = false;
      let isTriggerJson = false; //目标表单市非子表单字段但触发表单是否包含子表单字段
      let list = [];
      if (item.name.indexOf(".") >= 0) {
        // 子表单字段
        let pName = item.name.split(".")[0];

        this.filterFieldValues.forEach((field) => {
          if (field.tableField.fieldName.indexOf(".") >= 0) {
            let parentName = field.tableField.fieldName.split(".")[0];
            if (!list.includes(parentName)) {
              list.push(parentName);
            }
          }
        });
        if (list.length && !list.includes(pName)) {
          hasMoreChild = true;
        }
        isTriggerJson =
          this.filterFieldValues.findIndex(
            (field) =>
              field.tableField.fieldName.indexOf(".") == -1 &&
              field.triggerTableField &&
              field.triggerTableField.fieldName.indexOf(".") >= 0
          ) >= 0;
      }
      if (hasMoreChild) {
        this.$confirm(
          "目标表单或触发表单字段仅允许添加同一个子表单中的字段，此操作会清除部分已设置的过滤条件和字段赋值方式，无法还原。",
          "确定要使用该子表单字段吗？"
        )
          .then(() => {
            let parentName = list[0] + ".";
            let filterFieldValues = this.filterFieldValues.filter(
              (item) => item.tableField.fieldName.indexOf(parentName) == -1
            );
            let updateFieldValues = this.updateFieldValues.filter(
              (item) => item.tableField.fieldName.indexOf(".") == -1
            );
            filterFieldValues.push({
              key: this.getUuid(),
              valueType: "tableField",
              tableField: tableField,
            });
            this.$emit("update:filterFieldValues", filterFieldValues);
            this.$emit("update:updateFieldValues", updateFieldValues);
          })
          .catch(() => {});
      } else if (isTriggerJson) {
        this.$confirm(
          "为避免过滤条件互斥，此操作会清除部分已设置的过滤条件和字段赋值方式，无法还原。",
          "确定要进行此操作吗？"
        )
          .then(() => {
            this.filterFieldValues.forEach((field) => {
              if (
                field.tableField.fieldName.indexOf(".") == -1 &&
                field.triggerTableField &&
                field.triggerTableField.fieldName.indexOf(".") >= 0
              ) {
                this.$set(field, "triggerTableField", undefined);
              }
            });
            this.filterFieldValues.push({
              key: this.getUuid(),
              valueType: "tableField",
              tableField: tableField,
            });
          })
          .catch(() => {});
      } else {
        this.filterFieldValues.push({
          key: this.getUuid(),
          valueType: "tableField",
          tableField: tableField,
        });
      }
    },
    checkFilterField(fieldName, name) {
      return new Promise((resolve, reject) => {
        let parentName;
        if (name.indexOf(".") >= 0) {
          parentName = name.split(".")[0];
        }
        if (parentName) {
          if (fieldName.indexOf(".") == -1) {
            // 非子表单
            if (
              this.filterFieldValues.findIndex(
                (item) => item.tableField.fieldName.indexOf(".") >= 0
              ) >= 0
            ) {
              this.$confirm(
                "为避免过滤条件互斥，此操作会清除部分已设置的过滤条件和字段赋值方式，无法还原。",
                "确定要进行此操作吗？"
              )
                .then(() => {
                  // 清除子表单字段
                  let filterFieldValues = this.filterFieldValues.filter(
                    (item) => item.tableField.fieldName.indexOf(".") == -1
                  );
                  this.$emit("update:filterFieldValues", filterFieldValues);
                  resolve(true);
                })
                .catch(() => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          } else {
            // 子表单
            let index = this.filterFieldValues.findIndex(
              (item) =>
                item.tableField.fieldName != fieldName &&
                item.tableField.fieldName.indexOf(".") >= 0 &&
                item.triggerTableField &&
                item.triggerTableField.fieldName.indexOf(parentName) == -1
            );
            if (index >= 0) {
              let title = "",
                msg = "";
              if (
                this.filterFieldValues[
                  index
                ].triggerTableField.fieldName.indexOf(".") >= 0
              ) {
                msg =
                  "目标表单或触发表单字段仅允许添加同一个子表单中的字段，此操作会清除部分已设置的过滤条件和字段赋值方式，无法还原。";
                title = "确定要使用该子表单字段吗？";
              } else {
                msg =
                  "为避免过滤条件互斥，此操作会清除部分已设置的过滤条件和字段赋值方式，无法还原。";
                title = "确定要进行此操作吗？";
              }
              this.$confirm(msg, title)
                .then(() => {
                  this.filterFieldValues.forEach((item) => {
                    if (
                      item.tableField.fieldName.indexOf(".") >= 0 &&
                      item.triggerTableField &&
                      item.triggerTableField.fieldName.indexOf(parentName) == -1
                    ) {
                      this.$set(item, "triggerTableField", undefined);
                    }
                  });
                  resolve(true);
                })
                .catch(() => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          }
        } else {
          if (
            fieldName.indexOf(".") >= 0 &&
            this.filterFieldValues.findIndex(
              (item) =>
                item.triggerTableField &&
                item.triggerTableField.fieldName.indexOf(".") >= 0
            ) >= 0
          ) {
            this.$confirm(
              "为避免过滤条件互斥，此操作会清除部分已设置的过滤条件和字段赋值方式，无法还原。",
              "确定要进行此操作吗？"
            )
              .then(() => {
                this.filterFieldValues.forEach((item) => {
                  if (
                    item.triggerTableField &&
                    item.triggerTableField.fieldName.indexOf(".") >= 0
                  ) {
                    this.$set(item, "triggerTableField", undefined);
                  }
                });
                resolve(true);
              })
              .catch(() => {
                reject(false);
              });
          } else {
            resolve(true);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  &.disabled {
    color: #c3cdda;
    cursor: not-allowed !important;
    &:hover {
      background-color: transparent !important;
      color: #c3cdda;
    }
  }
}
</style>
