<template>
    <el-popover
      @show="open"
      :width="width"
      placement="bottom-start"
      v-model="visible"
    >
      <div ref="reference" slot="reference" class="input-select">
        <div
          class="select-text"
          v-text="userList.map((item) => item.name).join(',')"
        ></div>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div v-clickoutside="closeVisible" class="body-scroll">
        <template v-if="multi">
          <el-input
            placeholder="请输入关键值"
            size="mini"
            suffix-icon="el-icon-search"
            v-model="searchValue"
          >
          </el-input>
          <el-checkbox
            :checked="valueContainsCurrentUser"
            @change="changeUser"
            style="padding: 5px 0px"
            >{{ currentUser }}</el-checkbox
          >
          <div
            v-for="item in searchList ? searchList : contactList"
            :key="item.id"
          >
            <el-checkbox
              style="padding: 5px 0; width: 100%"
              @change="changeChk(item.id, $event)"
              :checked="hasChecked(item.id)"
              >{{ item.name }}</el-checkbox
            >
          </div>
        </template>
        <template v-else>
          <el-input
            placeholder="请输入关键值"
            size="mini"
            suffix-icon="el-icon-search"
            v-model="searchValue"
          >
          </el-input>
          <div class="user-item" @click="changeUser(!valueContainsCurrentUser)">
            <span>{{ currentUser }}</span>
            <i
              v-if="valueContainsCurrentUser === true"
              style="color: var(--zgg-primary-color); font-size: 18px"
              class="el-icon-check"
            ></i>
          </div>
          <div
            class="user-item"
            v-for="item in searchList ? searchList : contactList"
            :key="item.id"
            @click="changeChk(item.id, !hasChecked(item.id))"
          >
            <span v-text="item.name"></span>
            <i
              v-if="hasChecked(item.id)"
              style="color: var(--zgg-primary-color); font-size: 18px"
              class="el-icon-check"
            ></i>
          </div>
        </template>
      </div>
    </el-popover>
  </template>
  <script>
  import Clickoutside from "element-ui/src/utils/clickoutside";
  import { contactListPage } from "@/api/contact";
  import { companyEmployeeList, companyList } from "@/api/company";
  import { listUserInfo } from "@/views/lowCode/api";
  
  export default {
    directives: { Clickoutside },
    props: {
      value: [String, Array],
      componentName: String,
      oper: String,
      valueContainsCurrentUser: {
        typeof: Boolean,
        default() {
          return false;
        },
      },
    },
    inject: {
      isInWorkFlowConfig: {
        default: false,
      },
    },
    watch: {
      oper(val, old) {
        if (this.multi) {
          this.$emit("input", []);
        } else {
          this.$emit("input");
        }
        this.$emit("update:valueContainsCurrentUser", false);
        this.userList = [];
      },
    },
    computed: {
      currentUser() {
        return this.isInWorkFlowConfig ? "流程发起人" : "当前用户";
      },
      multi() {
        return (
          this.componentName == "user_list_select" ||
          ["in", "nin"].includes(this.oper)
        );
      },
      searchValue: {
        get() {
          return this.keyWord;
        },
        set(val) {
          this.keyWord = val;
          var pattern = new RegExp("[A-Za-z]+");
          if (pattern.test(val)) {
            val = val.toLowerCase();
            this.searchList = this.contactList.filter((item) => {
              return item.initial.includes(val);
            });
          } else {
            this.searchList = this.contactList.filter((item) => {
              return item.name.includes(val);
            });
          }
  
          if (!val) {
            this.searchList = "";
          }
        },
      },
    },
    data() {
      return {
        visible: false,
        contactList: [],
        width: "",
        userList: [],
        searchList: "",
        keyWord: "",
      };
    },
    created() {
      this.userList = [];
      if (this.valueContainsCurrentUser) {
        this.userList.push({ id: "currentUser", name: `${this.currentUser}` });
      }
      if (!this._.isEmpty(this.value)) {
        let userIds = [];
        if (this.value instanceof Array) {
          userIds = this.value;
        } else {
          userIds.push(this.value);
        }
  
        listUserInfo({
          userIds,
        }).then((resp) => {
          let list = resp.data.list;
  
          this.userList = this.userList.concat(list);
        });
      }
    },
    mounted() {
      this.$nextTick(() => {
        let width = this.$refs.reference.clientWidth;
        this.width = width;
      });
    },
    methods: {
      changeUser(checked) {
        this.$emit("update:valueContainsCurrentUser", checked);
        if (this.multi) {
          if (checked) {
            this.userList.push({
              id: "currentUser",
              name: `${this.currentUser}`,
            });
          } else {
            this.userList = this.userList.filter(
              (item) => item.id != "currentUser",
            );
          }
        } else {
          this.$emit("input");
          if (checked) {
            this.$set(this, "userList", [
              { id: "currentUser", name: `${this.currentUser}` },
            ]);
          } else {
            this.$set(this, "userList", []);
          }
          this.visible = false;
        }
      },
      changeChk(val, checked) {
        if (this.multi) {
          let list = this._.cloneDeep(this.value);
          if (!list) {
            list = [];
          }
          if (checked) {
            list.push(val);
            let item = this.contactList.find((opt) => opt.id == val);
            this.userList.push(item);
          } else {
            list = list.filter((opt) => opt != val);
            this.userList = this.userList.filter((opt) => opt.id != val);
          }
          this.$emit("input", list);
        } else {
          this.$emit("update:valueContainsCurrentUser", false);
          let value;
          if (checked) {
            value = val;
            let item = this.contactList.find((opt) => opt.id == val);
  
            this.$set(this, "userList", [this._.cloneDeep(item)]);
          } else {
            this.$set(this, "userList", []);
          }
          this.$emit("input", value);
          this.visible = false;
        }
      },
      hasChecked(val) {
        if (this.value instanceof Array) {
          return this.value.includes(val);
        } else {
          return this.value == val;
        }
      },
      closeVisible() {
        this.visible = false;
        this.searchValue = "";
        this.searchList = "";
      },
      async open() {
        if (this.contactList.length) {
          return;
        }
        let res = await contactListPage({
          pageSize: 9999,
          pageNumber: 1,
        });
  
        res.data.contactPage.list.forEach((item) => {
          this.contactList.push({
            ...item,
            id: item.targetUserId,
          });
        });
  
        companyList().then((resp) => {
          let arr = [];
  
          resp.data.companyList.forEach((item) => {
            arr.push(
              companyEmployeeList({
                companyId: item.id,
                pageSize: 9999,
                pageNumber: 1,
              }),
            );
          });
          Promise.all(arr).then((result) => {
            result.forEach((res) => {
              res.data.page.list.forEach((user) => {
                if (
                  this.contactList.findIndex(
                    (item) => item.targetUserId == user.targetUserId,
                  ) == -1
                ) {
                  this.contactList.push({
                    ...user,
                    id: user.targetUserId,
                  });
                }
              });
            });
          });
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .input-select {
    border: 1px solid #dcdfe6;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 10px;
    .select-text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .body-scroll {
    max-height: 320px;
    overflow: auto;
  }
  .user-item {
    display: flex;
    align-items: center;
    height: 30px;
    width: max-content;
    justify-content: space-between;
  }
  </style>
  