<template>
  <el-dialog
    title="复制列"
    :visible="visible"
    width="500px"
    @close="close"
    @open="open"
    :destroy-on-close="true"
    :append-to-body="true"
  >
    <div class="app-container">
      <el-form v-model="copyForm" label-position="top" ref="copyForm">
        <el-form-item label="看板" prop="boardId">
          <el-select
            filterable
            v-model="copyForm.boardId"
            style="width: 100%"
            placeholder="请选择看板"
          >
            <el-option
              v-for="item in boardList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="列位置" prop="columnIndex">
          <el-select
            v-model="copyForm.columnIndex"
            style="width: 100%"
            placeholder="请选择列位置"
          >
            <el-option
              v-for="(col, index) in columnList"
              :key="index"
              :value="index"
              :label="index + 1"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="cardCopyCommit">复制并新建</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  bulletinColumnCopy,
  bulletinSelect,
  bulletinColumnSelect,
} from "@/api/boardManage";

export default {
  props: {
    visible: Boolean,
    col: Object,
    index: Number,
  },
  computed: {
    // cardList() {
    //   let col = this.columnList.find(
    //       (item) => item.id == this.copyForm.columnId
    //   );
    //   if (col && col.cardList) {
    //     return col.cardList;
    //   } else {
    //     return [];
    //   }
    // },
  },
  data() {
    return {
      copyVisible: false,
      copyForm: {
        boardId: "",
        columnIndex: "",
      },
      boardList: [],
      columnList: [],
    };
  },
  destroyed() {
    this.$emit("update:visible", false);
  },
  watch: {
    "copyForm.boardId": {
      deep: true,
      handler(val) {
        console.log(val);
        if (val) {
          this.apiGetBulletinColumnList();
        }
      },
    },
    "copyForm.columnIndex": {
      deep: true,
      handler(val) {
        console.log("copyForm.columnIndex", val);
      },
    },
  },
  methods: {
    apiGetBulletinColumnList() {
      bulletinColumnSelect({
        bulletinId: this.copyForm.boardId,
        archived: false,
      }).then((resp) => {
        this.columnList = resp.data.modelList;
        this.columnList.push([]);
        if (this.col.bulletinId != this.copyForm.boardId) {
          this.copyForm.columnIndex = this.columnList.length;
        }
      });
    },
    async apiGetBoardList() {
      await bulletinSelect().then((resp) => {
        this.boardList = resp.data.modelList;
      });
    },
    async open() {
      console.log(this.col);
      console.log(this.index);
      let form = {
        boardId: this.col.bulletinId,
        columnIndex: this.index + 1,
      };

      await this.apiGetBoardList();
      this.$set(this, "copyForm", form);
      // await this.apiGetBulletinColumnList()
    },
    close() {
      this.$emit("update:visible", false);
      this.copyForm = {
        boardId: "",
        columnIndex: "",
      };
    },
    cardCopyCommit() {
      let sortNumber = 1;
      let targetIndex = this.copyForm.columnIndex;
      let n = 1;
      if (targetIndex - 1 >= 0) {
        sortNumber = this.columnList[targetIndex - n].sortNumber + 1;
      } else {
        sortNumber = 0;
      }
      let params = {
        columnId: this.col.id,
        sortNumber: sortNumber,
        toBulletinId: this.copyForm.boardId,
      };
      bulletinColumnCopy(params).then((res) => {
        this.$message.success("复制成功");
        this.$emit("update:visible", false);
        this.$emit("columnRefresh");
        this.$emit("copyDone");
      });
    },
  },
};
</script>
