<template>
  <div>
    <el-popover trigger="click" v-model="tplVisible" @show="handleTplShow">
      <div>
        <div class="tpl-empty" v-if="!list.length">暂无模板看板</div>
        <div ref="tplList" class="tpl-list" v-infinite-scroll="load" v-else>
          <draggable
              :list="list"
              class="scrollBar"
              :move="onCardMove"
              :scroll="true"
              :set-data="setData"
              @update="cardUpdate"
              @add="cardUpdate"
              group="card"
              animation="500"
              :scroll-sensitivity="200"
              force-fallback="true"
              drag-class="dragClass"
              ghost-class="ghostClass"
              fallback-tolerance="10"
              handle=".tpl-item"
          >
            <div class="tpl-item" v-for="(card, index) in list" :key="card.id">
              <card-item
                  :index="index"
                  :card="card"
                  :columnList="columnList"
                  @columnRefresh="columnRefresh"
                  @updateCard="updateCard"
                  @showMoveColumn="showMoveColumn"
                  @deleteItem="deleteItem"
                  :isFormTpl="true"
              ></card-item>
            </div>

          </draggable>
        </div>
        <div v-if="isAddTpl" class="add-card-item">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              placeholder="请输入任务内容"
              v-model="quickCardTitle"
              resize="none"
              @input="scrollTo"
              @blur="handleQuickAddSave($event)"
              autofocus
              ref="addCardInput"
              @keydown.native="handleQuickAddInputListen($event)"
              clearable
          >
          </el-input>
          <div
              style="
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            "
          >
            <div
                style="
                height: 32px;
                line-height: 32px;
                color: #6b778c;
                font-size: 14px;
                margin-right: 10px;
              "
            >
              <span>取消</span>
            </div>
            <el-button
                class="white-border"
                type="primary"
                size="small"
                icon="el-icon-plus"
            >添加
            </el-button>
          </div>
        </div>
        <el-button
            v-if="!isAddTpl"
            @click="isAddTpl = true"
            size="small"
            style="width: 100%"
        >新建模板
        </el-button
        >
      </div>

      <el-button
          slot="reference"
          style="margin-left: 10px"
          icon="el-icon-more"
      ></el-button>
    </el-popover>
    <move-card
        :visible.sync="visible"
        @removeCard="removeCard"
        :card="moveCard"
        :columnList="columnList"
    ></move-card>
  </div>
</template>
<script>
import {
  addCardByTpl,
  bulletinCardSave, bulletinCardSort,
  fetchBulletinCardList,
} from "@/api/boardManage";
import CardItem from "./CardItem";
import MoveCard from "./MoveCard";
import draggable from "vuedraggable";

let cardId;
export default {
  components: {CardItem, MoveCard,draggable},
  props: {
    columnList: Array,
    bulletinId: [String, Number],
    colId: [String, Number],
    sortNumber: {
      type: Number,
      default() {
        return 0;
      },
    },
    page: Object,
  },
  data() {
    return {
      list: [],
      visible: false,
      isAddTpl: false,
      tplVisible: false,
      quickCardTitle: "",
      moveCard: {},
    };
  },
  mounted() {
    this.fetchColumnCardList()
  },
  methods: {
    //卡片拖拽完成事件,同列排序
    cardUpdate(e) {
      let cardIndex = this.list.findIndex((item) => item.id == cardId);
      //排序改传前一个卡片id
      let preIndex = 0;
      let preCardId = 0;
      if (cardIndex - 1 >= 0) {
        preIndex = cardIndex - 1;
        preCardId = this.list[preIndex].id;
      }
      let params = {
        cardId: cardId,
        preCardId,
        toBulletinId: this.bulletinId,
        toColumnId: this.colId,
      };
      if (!preCardId) {
        params.sortNumber = 1;
      }

      bulletinCardSort(params).then(({data}) => {
      });
    },
    setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/Sortable/issues/1012
      dataTransfer.setData("Text", "");
    },
    onCardMove(e) {
      cardId = e.draggedContext.element.id;
      return true;
    },
    handleTplShow() {
      this.fetchColumnCardList()
    },
    fetchColumnCardList(pageNumber = 1) {
      if (this.loading) {
        return;
      }
      let params = {
        query: JSON.stringify({
          bulletinId: this.bulletinId,
          bulletinColumnId: this.colId,
          archived: false,
          templated: true,
        }),
        pageSize: 999,
        pageNumber,
      };
      this.loading = true;
      fetchBulletinCardList(params)
          .then((res) => {
            this.loading = false;
            let list = res.data.page.list;
            this.page.pageNumber = res.data.page.pageNumber;
            this.page.totalPage = res.data.page.totalPage;
            this.page.totalRow = res.data.page.totalRow;

            if (res.data.page.pageNumber == 1) {
              this.list = list;
            } else {
              list = list.filter(
                  (card) => this.list.findIndex((item) => item.id == card.id) == -1
              );

              if (list.length) {
                this.list = this.list.concat(list);
              } else if (res.data.page.pageNumber < res.data.page.totalPage) {
                // 数据重新请求
                this.fetchColumnCardList(res.data.page.pageNumber + 1);
                return;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
    },
    deleteItem(id) {
      let index = this.list.findIndex((item) => item.id == id);
      this.list.splice(index, 1);
    },
    columnRefresh() {
      this.$emit("fetchList", 1);
    },
    updateCard(model, top) {
      let index = this.list.findIndex((item) => item.id == model.id);

      if (index >= 0) {
        this.$set(this.list, index, model);
      } else {
        if (top) {
          this.list.unshift(model);
        } else {
          this.list.push(model);
        }

        this.$nextTick(() => {
          if (this.$refs.tplList) {
            if (top) {
              this.$refs.tplList.scrollTop = 0;
            } else {
              this.$refs.tplList.scrollTop = this.$refs.tplList.scrollHeight;
            }
          }
        });
      }
    },
    load() {
      if (this.page.pageNumber < this.page.totalPage) {
        // this.$emit("fetchList", this.page.pageNumber + 1);
        this.fetchColumnCardList(this.page.pageNumber + 1)
      }
    },
    showMoveColumn(card) {
      if (this.columnList.length < 2) {
        this.$message.warning("当前看板没有其他目标列可供卡片移动");
        return;
      }
      this.visible = true;
      this.moveCard = this._.cloneDeep(card);
    },

    removeCard(card) {
      let index = this.list.findIndex((item) => item.id == card.id);
      if (index >= 0) {
        this.list.splice(index, 1);
      }
    },

    scrollTo() {
      if (this.sortNumber) {
        // this.$emit("scrollToTop");
      } else {
        // this.$emit("scrollToBottom");
      }
    },
    handleQuickAddSave(event) {
      if (this.quickCardTitle) {
        let data = {
          bulletinId: this.bulletinId,
          bulletinColumnId: this.colId,
          title: this.quickCardTitle,
          templated: true,
          // sortNumber:-1
        };
        // if (this.sortNumber) {
        //   data.sortNumber = this.sortNumber;
        // }
        bulletinCardSave({
          model: JSON.stringify(data),
        }).then((resp) => {
          this.updateCard(resp.data.model, false);
          this.isAddTpl = false;

          this.quickCardTitle = "";
          this.scrollTo();
        });
      } else {
        this.isAddTpl = false;
      }
    },
    handleQuickAddInputListen(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.handleQuickAddSave();

        return false;
      }
    },
    addCard(card) {
      addCardByTpl({templateId: card.id}).then((res) => {
        console.log(res);
      });
      this.tplVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-card-item {
  width: 270px;
  margin: 10px;
  //background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    //background-color: #f4f5f7;
    background-color: #e2f1ff;
  }
}

.tpl-empty {
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}

.tpl-list {
  background-color: #d9ecff;
  max-height: 500px;
  padding: 10px;
  margin-bottom: 10px;
  width: 310px;
  overflow: auto;

  .tpl-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
</style>