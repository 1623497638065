<template>
  <div>
    <div class="weui-row">
      <el-tooltip
        v-if="isDelete"
        effect="dark"
        content="字段已删除"
        placement="top"
      >
        <el-input
          :value="field.tableField.fieldTitle"
          readonly
          size="small"
          style="width: 220px; color: #f56c6c"
          class="is-error"
          placeholder=""
        ></el-input>
      </el-tooltip>
      <el-input
        v-else
        :value="field.tableField.fieldTitle"
        readonly
        size="small"
        style="width: 220px"
        placeholder=""
      ></el-input>

      <el-select
        v-if="isArrayComponent"
        :value="field.arrayUpdateType"
        placeholder=""
        :disabled="disabled"
        style="width: 90px; margin-left: 10px"
        size="small"
        @change="changeArrayUpdateType"
      >
        <el-option :label="typeText" value="replace"></el-option>
        <el-option label="增加" value="add"></el-option>
        <el-option label="移除" value="remove"></el-option>
      </el-select>

      <div
        v-else
        style="font-size: 14px; margin-left: 10px"
        v-text="typeText"
      ></div>
      <el-select
        :value="field.valueType"
        size="small"
        style="width: 140px; margin-left: 10px"
        placeholder=""
        @change="changeValueType"
        :disabled="!field.tableField.fieldName || disabled"
      >
        <el-option label="节点字段" value="nodeField"></el-option>
        <el-option v-if="hasCustom" label="自定义" value="custom"></el-option>
        <el-option
          v-if="
            field.arrayUpdateType != 'add' && field.arrayUpdateType != 'remove'
          "
          label="空值"
          value="nil"
        ></el-option>
        <el-option
          v-if="field.tableField.componentName == 'date_picker'"
          label="动态"
          value="dynamic"
        ></el-option>
        <el-option v-if="hasShowRely" label="公式" value="rely"></el-option>
        <el-option
          v-if="field.tableField.componentName == 'json_form'"
          label="节点"
          value="node"
        ></el-option>
      </el-select>
      <template v-if="!disabled">
        <i
          v-if="type != 'add'"
          @click="del"
          class="el-icon-delete"
          style="margin-left: 10px"
        ></i>
      </template>
    </div>
    <div v-if="valueType != 'nil'" class="weui-row" style="width: 100%">
      <template v-if="valueType == 'rely'">
        <div style="width: 100%; position: relative">
          <el-input
            size="small"
            :value="relyTitle"
            placeholder="请设置公式"
            style="width: 100%"
            readonly
          ></el-input>
          <div
            @click.prevent.stop="openFormula"
            style="
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            "
          ></div>
        </div>

        <!-- <rely-dialog
          title="设置公式"
          :dialogVisible.sync="relyVisible"
          :originComponents="[]"
          relyType="rpaRely"
          :originalFormula="field.rely && field.rely.originalFormula"
          :nodeList="nodeList"
          :fetchMetaFieldComponentList="fetchMetaComponents"
          :name="field.tableField.fieldName"
          :nodeKey="node.key"
          @save="saveRely"
        ></rely-dialog> -->
        <formula-dialog
          title="设置公式"
          :originalFormula="field.rely && field.rely.originalFormula"
          :nodeKey="node.key"
          :treeNodeList="nodeList"
          :fetchMetaFieldComponentList="fetchMetaComponents"
          :fetchRobotParams="fetchRobotParams"
          @save="saveRely"
          ref="relyDialog"
          :disabled="disabled"
          :disabled-funs="['TEXTUSER', 'TEXTDEPT', 'GETUSER', 'RECNO']"
        ></formula-dialog>
      </template>
      <DynamicDatePicker
        v-else-if="
          field.tableField.componentName == 'date_picker' &&
          valueType == 'dynamic'
        "
        v-model="queryField.value"
        placeholder="请选择日期"
        size="small"
        style="width: 100%"
        :picker="datePicker"
        :hasCustom="true"
        :filterTimeModeByPicker="true"
        :hasNow="true"
      >
      </DynamicDatePicker>
      <query-input
        v-else-if="valueType == 'custom'"
        :field="queryField"
        :areaTree="areaTree"
        :areaProps="areaProps"
        :componentList="componentList"
        style="width: 100%"
        v-model="queryField.value"
        :disabled="disabled"
      ></query-input>
      <rpa-node-fields
        v-else-if="valueType == 'nodeField'"
        :nodeField="field.nodeField"
        :tableField="field.tableField"
        :fieldConfigKey="fieldConfigKey"
        :disabled="!field.tableField.fieldName || disabled"
        :baseNodeKey="node.baseNodeKey"
        :node="node"
      ></rpa-node-fields>
      <el-select
        v-else-if="valueType == 'node'"
        placeholder="请选择节点"
        size="small"
        :value="field.node?.key"
        @change="changeFieldNode"
      >
        <el-option
          v-for="item in jsonNodeList"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { userMixin } from "@/mixin/user-dept";
import rpaNodeFields from "./rpa-node-fields";
import {
  fromNodesMixins,
  isCanSetRelyForRpa,
  noCustomComponents,
} from "./rpa-utils";
import { fetchMetaComponents } from "@/api/form";
import { isEmptyByKey } from "@zgg-core-utils/utils";
import { getRobotWorkflow } from "./api";
import { checkRelyComponentName } from "@/utils/tools";
import { isEmpty } from "@/zgg-core/utils";

export default {
  props: {
    /** 源组件 */
    field: Object,
    type: String,
    componentList: Array,
    formId: String,
    abilityKey: String,
    fieldConfigKey: String,
    disabled: Boolean,
    node: Object,
  },
  components: { rpaNodeFields },
  mixins: [userMixin, fromNodesMixins],
  inject: {
    getRootParams: {
      default: () => [],
    },
  },
  data() {
    return {
      relyVisible: false,
      queryField: {
        ...this._.cloneDeep(this.field.tableField),
        value: this.field.value,
        valueDetail: this.field.valueDetail,
        key: this.field.tableField.fieldName,
      },
      rootFlowParams: [],
      // jsonNodeList: [],
    };
  },
  created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
    if (this.isArrayComponent) {
      if (isEmpty(this.field.arrayUpdateType)) {
        this.$set(this.field, "arrayUpdateType", "replace");
      }
    }
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    hasShowRely() {
      return isCanSetRelyForRpa(this.field.tableField.componentName);
    },
    /** 值类型 */
    valueType() {
      return this.field.valueType;
    },
    typeText() {
      let type = this.type;
      if (["filter"].includes(type)) {
        return "等于";
      } else if (type == "add") {
        return "设置为";
      } else if (type == "edit") {
        return "修改为";
      }
      return "";
    },
    datePicker() {
      if (
        !["date_minute", "date_hour"].includes(this.field.tableField.picker)
      ) {
        return this.field.tableField.picker;
      }
      return "datetime";
    },
    isDelete() {
      return (
        this.componentList.findIndex(
          (item) => item.key == this.field.tableField.fieldName,
        ) == -1
      );
    },
    relyTitle() {
      if (!isEmptyByKey(this.field, "rely.originalFormula")) {
        return "已设置公式";
      }
      return "";
    },
    hasCustom() {
      return !noCustomComponents.includes(this.field.tableField.componentName);
    },
    nodeList() {
      let list = this.getParentNodes();
      list.push(this.node);
      let nodeList = list.map((item) => {
        return {
          ...item,
          collection: item.collection || item.formId,
          collectionTitle: item.collectionTitle || item.formTitle,
        };
      });
      if (this.rootFlowParams?.length) {
        nodeList.unshift({
          type: "root",
          key: "root",
          title: "本流程参数",
          components: this.rootFlowParams.map((item) => {
            return {
              ...item,
              key: item.name,
              nodeKey: "root",
            };
          }),
        });
      }

      /**
       * @type Array
       */
      let fromNodeList = this.getParentNodes();
      nodeList = this._.cloneDeep(nodeList);
      nodeList = nodeList.filter((item) => {
        if (item.dataSource == "node_data") {
          return fromNodeList.findIndex((opt) => opt.key == item.nodeKey);
        } else {
          return true;
        }
      });
      nodeList.forEach((item) => {
        if (item.dataSource == "node_data") {
          let node = fromNodeList.find((opt) => opt.key == item.nodeKey);

          if (node) {
            item.nodeField = node.nodeField;
          }
        }
      });
      return nodeList;
    },
    isArrayComponent() {
      return [
        "checkbox_group",
        "select_checkbox",
        "attachment_uploader",
        "image_uploader",
        "reference_data_list",
        "user_list_select",
        "department_list_select",
        "json_form",
      ].includes(this.field.tableField.componentName);
    },
    jsonNodeList() {
      if (typeof this.getParentNodes === "function") {
        let nodeList = this.getParentNodes(["query_data_list"]);
        let arr = ["query_data_one"];
        if (this.node.baseNodeKey) {
          arr.push("query_data_list");
        }

        let component = this.componentList.find(
          (item) => item.name == this.field.tableField.fieldName,
        );
        if (!component) {
          return [];
        }
        let parentNodes = this._.cloneDeep(nodeList);

        nodeList = nodeList.filter((item) => {
          if (
            arr.includes(item.type) &&
            item.dataSource == "node_data_field" &&
            item.nodeField.componentName == "json_form"
          ) {
            if (component.tableName) {
              return component.tableName == item.nodeField.referenceCollection;
            } else {
              return (
                this.field.tableField.collection == item.nodeField.collection &&
                this.field.tableField.fieldName == item.nodeField.name
              );
            }
          } else if (component && component.componentName == "json_form") {
            if (component.tableName) {
              // 子表-实体表
              let collection;
              if (item.dataSource == "node_data") {
                let node = parentNodes.find((opt) => opt.key == item.nodeKey);
                if (node) {
                  collection = node.nodeField.referenceCollection;
                }
              } else if (item.dataSource == "node_data_field") {
                collection = item.nodeField.referenceCollection;
              } else {
                collection = item.formId || item.collection;
              }

              return collection == component.tableName;
            } else {
              // 子表非实体表
              if (item.dataSource == "node_data_field") {
                if (
                  !item.nodeField.referenceCollection &&
                  item.nodeField.name == component.name
                ) {
                  return true;
                }
              } else if (item.dataSource == "node_data") {
                let node = parentNodes.find((opt) => opt.key == item.nodeKey);
                if (
                  node &&
                  node.dataSource == "node_data_field" &&
                  !node.nodeField?.referenceCollection &&
                  node.nodeField.name == component.name
                ) {
                  return true;
                }
              }
            }
          }
          return false;
        });
        if (this.node.baseNodeKey) {
          nodeList = nodeList.filter((item) => {
            if (item.type == "query_data_list") {
              return item.key == this.node.baseNodeKey;
            }
            return true;
          });
        }
        nodeList.forEach((item) => {
          let subTitle = "";
          if (item.dataSource == "node_data_field") {
            subTitle = item.nodeField.title;
          } else {
            subTitle = item.formTitle || item.collectionTitle;
          }
          item.label = item.title;
          if (subTitle) {
            item.label += "【" + subTitle + "】";
          }
        });
        return nodeList;
      }
      return [];
    },
  },
  watch: {
    queryField: {
      deep: true,
      immediate: false,
      handler(val) {
        this.$set(this.field, "value", val.value);
      },
    },
  },

  methods: {
    changeFieldNode(val) {
      let node = this.jsonNodeList.find((item) => item.key == val);
      this.$set(this.field, "node", {
        key: val,
        type: node.type,
      });
    },

    changeArrayUpdateType(val) {
      this.$set(this.field, "arrayUpdateType", val);
      this.$set(this.field, "valueType", "nodeField");
      this.$set(this.field, "nodeField", {
        nodeKey: "",
        collection: "",
        name: "",
        title: "",
        componentName: "",
        picker: "",
      });
      this.$set(this.field, "value", "");
      this.queryField.value = "";
    },
    openFormula() {
      this.$refs.relyDialog.open();
    },
    fetchMetaComponents,
    async fetchRobotParams(robotId) {
      let res = await getRobotWorkflow({ robotId });
      /**
       * @type {Array}
       */
      let flowParams = res.data.workflow?.rpaFlow?.config?.flowParams;
      if (flowParams) {
        flowParams.forEach((item) => {
          item.key = item.name;
        });
        return flowParams;
      }
      return [];
    },
    saveRely(originalFormula, componentNames) {
      let componentName = this.field.tableField.componentName;
      if (!checkRelyComponentName(componentName, componentNames)) {
        return;
      }
      this.$set(this.field, "rely", { originalFormula });
      this.$refs.relyDialog.close();
    },
    del() {
      this.$emit("del");
    },
    changeField(value) {
      let obj = this.componentList.find((item) => item.name == value);
      this.$set(this.field.tableField, "componentName", obj.componentName);
      this.$set(this.field.tableField, "fieldTitle", obj.title);
      if (obj.componentName == "reference_data") {
        this.$set(this.field.tableField, "referenceTableName", obj.tableName);
      }

      this.field.valueType == "nodeField";
      this.$set(this.field, "nodeField", {
        nodeKey: "",
        collection: "",
        name: "",
        title: "",
        componentName: "",
        picker: "",
      });
    },
    changeValueType(value) {
      this.$set(this.field, "valueType", value);
      this.$set(this.field, "value", "");
      this.$set(this.field, "nodeField", undefined);

      if (value === "dynamic") {
        this.$set(this.field, "value", { timeMode: "", range: ["", ""] });
        this.queryField.value = { timeMode: "", range: ["", ""] };
      } else if (value == "nodeField") {
        this.$set(this.field, "nodeField", {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
          picker: "",
        });
        this.$set(this.field, "value", "");
        this.queryField.value = "";
      } else {
        this.$set(this.field, "value", "");
        this.queryField.value = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
  .is-error {
    ::v-deep {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
}
</style>
