<template>
  <div>
    <div class="json-desc">
      JSON 解析器可以把【插件】节点中输出的
      JSON对象解析为后续节点可直接使用的变量和数组参数。
    </div>
    <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
      <el-form-item
        label="获取JSON对象"
        prop="jsonDataNodeKey"
        :rules="[{ required: true, message: '请选择节点' }]"
      >
        <el-select
          v-model="jsonDataNodeKey"
          placeholder="请选择节点"
          size="small"
          @change="changeNodeKey"
        >
          <el-option
            v-for="item in nodeList"
            :key="item.key"
            :label="item.title"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <div v-if="jsonDataNode">
        <el-button size="mini" @click="showJsonParser"
          >查看JSON解析结果</el-button
        >
      </div>
      <template v-if="node.jsonDataNodeKey">
        <el-form-item label="定义输出参数">
          <rpa-json-out-field
            v-for="item in outputFields"
            :key="item.name"
            :field="item"
            :outputFields="outputFields"
          ></rpa-json-out-field>
          <div>
            <el-button @click="addOutputField" icon="el-icon-plus" type="text"
              >输出参数</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="定义错误信息">
          <div
            style="
              font-size: 12px;
              color: #757575;
              line-height: 1;
              margin-bottom: 8px;
            "
          >
            当返回匹配错误结果的 JSON 时，可以自定义中止输出参数时的错误消息
          </div>
          <el-radio-group v-model="failConfig">
            <el-radio label="empty" style="display: block; margin-bottom: 10px"
              >不定义错误信息</el-radio
            >
            <el-radio label="condition" style="display: block"
              >从输出参数中获取返回值作为错误消息</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <template v-if="failConfig == 'condition'">
          <div
            style="
              line-height: 1;
              font-size: 12px;
              color: #757575;
              margin-bottom: 8px;
            "
          >
            当参数返回值满足以下条件时触发错误
          </div>
          <el-form-item label="">
            <rpa-action-filter
              :dataFilter="node.failConfig.failCondition"
              :componentList="componentList"
              :isAddFilter="!disabled"
              :disabled="disabled"
              :node-type="node.type"
            ></rpa-action-filter>
          </el-form-item>
          <el-form-item
            label="指定触发错误时返回的错误消息"
            prop="failConfig.failMsg"
            :rules="[{ required: true, message: '请编辑错误信息' }]"
          >
            <rpa-codemirror
              ref="rpaMsg"
              :fieldList="fieldList"
              @change="changeFailMsg"
              :disabled="disabled"
            ></rpa-codemirror>
            <div v-if="isComplete && !disabled">
              <el-popover v-model="visible" trigger="click">
                <el-button type="text" icon="el-icon-plus" slot="reference"
                  >添加字段</el-button
                >
                <div style="max-height: 320px; overflow: auto">
                  <el-tree
                    :props="treeProps"
                    @node-click="nodeClick"
                    :load="loadNode"
                    :lazy="true"
                  >
                  </el-tree>
                </div>
              </el-popover>
            </div>
          </el-form-item>
          <el-form-item label="触发错误时">
            <el-radio-group v-model="failWay" prop="failWay">
              <el-radio label="keep" style="display: block; margin-bottom: 5px"
                >继续执行</el-radio
              >
              <div
                style="
                  font-size: 12px;
                  color: #757575;
                  line-height: 1;
                  margin-bottom: 10px;
                  margin-left: 22px;
                "
              >
                之后节点在使用本节点对象或数据时将跳过执行
              </div>
              <el-radio label="stop" style="display: block">中止流程</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
      </template>
    </el-form>

    <el-dialog
      title="查看 JSON 解析结果"
      :visible.sync="visible2"
      append-to-body
      destroy-on-close
      width="820px"
    >
      <div style="padding: 10px">
        <div style="font-size: 14px; line-height: 1.5">
          我们根据获取的 JSON 为您解析了对应的 JSON Path，您也可以学习 JSON Path
          的规则来更灵活的获取需要的数据
        </div>
        <el-table
          :data="tableData"
          max-height="400"
          style="width: 100%"
          row-key="name"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            label="名称"
            width="150"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="类型"
            width="150"
            prop="type"
          ></el-table-column>
          <el-table-column
            label="参考值"
            :show-overflow-tooltip="true"
            width="300"
            prop="value"
          ></el-table-column>
          <el-table-column
            label="JSON Path"
            width="200"
            prop="jsonPath"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import rpaJsonOutField from "@/views/lowCode/rpa/rpa-json-out-field";
import { uuid } from "@/zgg-core/utils";
import { isEmpty } from "@/zgg-core/relyUtil";
import rpaCodemirror from "./rpa-codemirror";
import rpaActionFilter from "./rpa-action-filter";

import {
  buildNodeComponentsMap,
  fromNodesMixins,
  isEmptyRpaActionFilter,
  loadNodeLeaf,
} from "./rpa-utils";
export default {
  name: "rpa-json-parse",
  components: { rpaJsonOutField, rpaCodemirror, rpaActionFilter },
  props: {
    node: Object,
    disabled: Boolean,
  },
  mixins: [fromNodesMixins],
  computed: {
    isComplete() {
      return !isEmpty(this.componentsMap);
    },
    nodeList() {
      let nodeList = this.getFromNodes();

      return nodeList.filter((item) =>
        ["developer_plugin", "http_request"].includes(item.type),
      );
      // ||
      //     (item.type == "query_data_one" &&
      //       item.dataSource != "node_data_field"),
    },
    jsonDataNodeKey: {
      get() {
        return this.node.jsonDataNodeKey;
      },
      set(val) {
        this.$set(this.node, "jsonDataNodeKey", val);
      },
    },
    jsonDataNode() {
      let jsonNode = this.nodeList.find(
        (item) =>
          item.key == this.jsonDataNodeKey && item.type == "http_request",
      );
      return jsonNode;
    },
    failConfig: {
      get() {
        if (isEmpty(this.node?.failConfig?.failCondition.advanceQuery)) {
          return "empty";
        } else {
          return "condition";
        }
      },
      set(val) {
        if (val == "empty") {
          this.$set(this.node, "failConfig", {
            failMsg: "",
            failMsgNodeFields: [],
            failWay: "keep",
            failCondition: {
              rel: "and",
              advanceQuery: [],
            },
          });
        } else {
          this.$set(this.node, "failConfig", {
            failMsg: "",
            failMsgNodeFields: [],
            failWay: "keep",
            failCondition: {
              rel: "and",
              advanceQuery: [
                {
                  key: "",
                  title: "",
                  componentName: "",
                  oper: "",
                  value: "",
                  referenceName: "",
                  collection: "",
                  picker: "",
                  valueType: "custom",
                  tableName: "",
                  referenceFieldName: "",
                },
              ],
            },
          });
        }
      },
    },
    failWay: {
      get() {
        return this.node?.failConfig?.failWay;
      },
      set(val) {
        this.$set(this.node.failConfig, "failWay", val);
      },
    },
    componentList() {
      let list = [];
      if (this.outputFields) {
        this.outputFields.forEach((item) => {
          let obj = {
            key: item.name,
            name: item.name,
            title: item.title,
            componentName: item.componentName,
            form: true,
          };
          if (item.componentName == "json_form") {
            let components = [];
            if (item.subFields) {
              item.subFields.forEach((sub) => {
                components.push({
                  key: sub.name,
                  name: sub.name,
                  title: sub.title,
                  componentName: sub.componentName,
                });
              });
            }
            obj.container = true;
            obj.components = components;
          }
          list.push(obj);
        });
      }

      return list;
    },
  },
  data() {
    return {
      visible: false,
      visible2: false,
      outputFields: [],
      fieldList: [],
      treeProps: {
        label: (data) => {
          let str = data.title;
          if (data.formTitle) {
            str += `【${data.formTitle}】`;
          } else if (data.collectionTitle) {
            str += `【${data.collectionTitle}】`;
          }
          return str;
        },
        isLeaf: "leaf",
      },
      componentsMap: {},
      rootFlowParams: [],
      tableData: [],
    };
  },
  async created() {
    let outputFields = this._.cloneDeep(this.node.outputFields);

    this.$set(this, "outputFields", outputFields ?? []);

    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }

    let parentNodes = this.getParentNodes();
    let componentsMap = await buildNodeComponentsMap.bind(this)(parentNodes);

    if (this.rootFlowParams?.length) {
      componentsMap["root"] = this.rootFlowParams;
    }
    this.buildFieldList("root", componentsMap["root"]);

    this.buildDeleteField();
    this.parentNodes = parentNodes;

    this.$set(this, "componentsMap", componentsMap);
    this.$nextTick(() => {
      if (this.$refs.rpaMsg && !isEmpty(this.node?.failConfig?.failMsg)) {
        this.$refs.rpaMsg.init(this.node.failConfig.failMsg);
      }
    });
  },

  methods: {
    showJsonParser() {
      if (
        this.jsonDataNode.responseDataFormat == "json" &&
        !isEmpty(this.jsonDataNode.responseExample)
      ) {
        let buildTableData = (data, parentKey) => {
          let list = [];
          for (const [key, value] of Object.entries(data)) {
            let jsonPath;
            if (!isEmpty(parentKey)) {
              jsonPath = `${parentKey}.${key}`;
            } else {
              jsonPath = `$.${key}`;
            }
            if (typeof value === "object") {
              if (value instanceof Date) {
                // 日期
                list.push({
                  name: key,
                  value,
                  type: "Date",
                  jsonPath: jsonPath,
                });
              } else if (value instanceof Array) {
                // 数组
                if (!isEmpty(value)) {
                  // todo
                  let obj = value[0];
                  if (typeof obj !== "object") {
                    list.push({
                      name: key,
                      value: JSON.stringify(value),
                      type: `array[${typeof obj}]`,
                      jsonPath: jsonPath,
                    });
                  } else {
                    if (obj instanceof Date) {
                      list.push({
                        name: key,
                        value: JSON.stringify(value),
                        type: `array[date]`,
                        jsonPath: jsonPath,
                      });
                    } else {
                      let children = buildTableData(obj, `${jsonPath}[0]`);
                      list.push({
                        name: key,
                        value: JSON.stringify(value),
                        type: `array[object]`,
                        jsonPath: jsonPath,
                        children,
                      });
                    }
                  }
                } else {
                  list.push({
                    name: key,
                    value: JSON.stringify(value),
                    type: "array[string]",
                    jsonPath: jsonPath,
                  });
                }
              } else {
                // 对象
                let children = buildTableData(value, jsonPath);
                list.push({
                  name: key,
                  value: JSON.stringify(value),
                  type: "object",
                  jsonPath: jsonPath,
                  children,
                });
              }
            } else {
              list.push({
                name: key,
                value,
                type: typeof value,
                jsonPath: jsonPath,
              });
            }
          }
          return list;
        };
        let tableData = buildTableData(this.jsonDataNode.responseExample);
        this.tableData = tableData;
        console.log(this._.cloneDeep(tableData));

        this.visible2 = true;
      } else {
        this.$message.error("没有可以用来解析的流程参数");
      }
    },
    nodeClick(data, node) {
      if (node.isLeaf) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }
        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs.rpaMsg.insert(obj);
        this.$refs.rpaMsg.onBlur();
        this.visible = false;
      }
    },
    loadNode: loadNodeLeaf,
    changeFailMsg(e) {
      this.$set(this.node.failConfig, "failMsg", e);
      let code = e;
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }

      this.$set(this.node.failConfig, "failMsgNodeFields", arr);
    },
    /** 构建编辑器用的组件列表 */
    buildFieldList(nodeKey, list) {
      if (list) {
        let tempList = list.map((item) => {
          return {
            nodeKey: nodeKey,
            fieldName: item.name,
            componentName: item.componentName,
            name: nodeKey + "_" + item.name,
            title: item.title,
          };
        });
        this.fieldList = this.fieldList.concat(tempList);
      }
    },
    /** 在fieldList插入已删除的字段列表用于编辑器展示提示 */
    buildDeleteField() {
      if (this.node?.failConfig?.failMsgNodeFields) {
        this.node.failConfig.failMsgNodeFields.forEach((item) => {
          let name = item.nodeKey + "_" + item.name;
          let isDelete =
            this.fieldList.findIndex((row) => row.name == name) == -1;
          if (isDelete) {
            this.fieldList.push({
              nodeKey: item.nodeKey,
              fieldName: item.name,
              componentName: item.componentName,
              name: item.nodeKey + "_" + item.name,
              title: item.title,
              delete: true,
            });
          }
        });
      }
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let outputFields = this._.cloneDeep(this.outputFields);
        let errors = [];
        outputFields.forEach((item) => {
          if (isEmpty(item.title)) {
            errors.push("参数名称不能为空");
          }
          if (isEmpty(item.jsonPath)) {
            errors.push("JSON Path的值不能为空");
          }
          if (item.componentName == "json_form" && item.subFields) {
            item.subFields.forEach((sub) => {
              if (isEmpty(sub.title)) {
                errors.push("参数名称不能为空");
              }
              if (isEmpty(sub.jsonPath)) {
                errors.push("JSON Path的值不能为空");
              }
            });
          }
        });
        if (errors.length) {
          this.$message.error(errors[0]);
          return;
        }

        if (this.failConfig == "condition") {
          if (isEmptyRpaActionFilter(this.node.failConfig.failCondition)) {
            this.$message.error("请设置过滤条件的字段值");
            return;
          }

          let index = this.node.failConfig.failMsgNodeFields.findIndex(
            (item) =>
              this.fieldList.findIndex(
                (row) =>
                  row.delete &&
                  item.name == row.fieldName &&
                  item.nodeKey == row.nodeKey,
              ) >= 0,
          );

          if (index >= 0) {
            this.$message.error("通知内容存在被删除字段，请删除被删除字段");
            return;
          }
        } else {
          this.$set(this.node, "failConfig", {
            failMsg: "",
            failMsgNodeFields: [],
            failWay: "keep",
            failCondition: {
              rel: "and",
              advanceQuery: [],
            },
          });
        }

        this.$set(this.node, "outputFields", outputFields);
        callback(valid);
      });
    },
    changeNodeKey() {
      this.outputFields = [
        { name: uuid(), title: "", componentName: "input", jsonPath: "" },
      ];
      this.$set(this.node, "outputFields", []);
      this.$set(this.node, "failConfig", {
        failMsg: "",
        failMsgNodeFields: [],
        failWay: "keep",
        failCondition: {
          rel: "and",
          advanceQuery: [],
        },
      });
    },
    addOutputField() {
      this.outputFields.push({
        name: uuid(),
        title: "",
        componentName: "input",
        jsonPath: "",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.json-desc {
  background: #f5f5f5;
  border-radius: 3px;
  padding: 21px 16px;
  font-size: 14px;
}
</style>
