<template>
  <div>
    <div class="attr-row">
      <el-popover
        popper-class="form-popover"
        placement="bottom-start"
        v-model="visible"
        width="300"
      >
        <div v-clickoutside="handleClose">
          <div class="pop-scroll">
            <div class="empty-text" v-if="tableData.length == 0">
              暂无表单数据
            </div>
            <template v-else>
              <app-view-select
                :treeData="tableData"
                @select="chkTableName"
              ></app-view-select>
            </template>
          </div>
        </div>
        <div @click.stop="" slot="reference" class="el-meta-field">
          <div class="meta-text">
            {{ name }}
          </div>
          <i class="el-icon-arrow-down"></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import appViewSelect from "@/views/lowCode/dashboard/add-component/dashboard-button-group/app-view-select";
import { dirTree } from "@/views/lowCode/api";

export default {
  name: "current-company-form-list",
  components: { appViewSelect },

  props: {
    name: {
      type: String,
      default: "",
    },
    formId: {
      type: String,
      default: "",
    },
  },

  directives: { Clickoutside },
  data() {
    return {
      visible: false,
      formName: "",
      tableData: [],
      tableLoading: false,
      tableName: "",
    };
  },

  created() {
    this.fetchFormList();
  },
  methods: {
    handleClose() {
      this.visible = false;
      this.formName = "";
    },
    searchForm() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.fetchFormList();
      }, 500);
    },
    fetchFormList(data, callback) {
      this.tableData = [];
      this.tableLoading = true;
      let postData = {
        type: 10,
        fullTree: true,
      };

      dirTree(postData)
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.data.directoryList;

          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    chkTableName(item) {
      // 按钮组件
      this.$emit("changeSource", item);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  line-height: 30px;
  font-size: 14px;
  padding: 0px 10px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  .item-title {
    &.disabled {
      color: #c3cdda;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.3);
    }

    &.select {
      background-color: #409eff;

      &:hover {
        background-color: #409eff;
      }
    }
  }
}

.el-meta-field {
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  padding-right: 10px;
  border-radius: 5px;
  color: #606266;
  cursor: pointer;

  .meta-text {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .empty-text {
    font-size: 12px;
    text-align: center;
    line-height: 28px;
  }
}
</style>
<style lang="scss">
.form-popover {
  padding: 0px !important;
}
</style>
