<template>
  <div class="dialog" v-show="value">
    <div class="d-header">
      <div style="font-size: 14px">执行日志</div>
      <i @click="close" style="cursor: pointer" class="el-icon-close"></i>
    </div>
    <div>
      <el-table :data="list" highlight-current-row style="width: 100%">
        <el-table-column
          header-align="center"
          align="center"
          prop="actionTime"
          width="180"
          label="执行时间"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="userName"
          width="180"
          label="执行人"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="actionType"
          label="执行类型"
        >
          <template slot-scope="scope">
            <span v-text="getTitle(scope.row)"></span>
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          align="center"
          prop="result"
          label="执行结果"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.result.success">执行成功</span>
            <span v-else>执行失败</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadingList"
      />
    </div>
  </div>
</template>
<script>
import { getSpiritLog } from "./api";

import Pagination from "@/components/Pagination/index";
export default {
  components: { Pagination },
  props: {
    spiritId: [String, Number],
    value: Boolean,
  },
  watch: {
    spiritId: {
      immediate: true,
      handler(val, old) {
        if (val && val != old) {
          this.page.pageNumber = 1;
          this.loadingList();
        }
      },
    },
  },
  data() {
    return {
      list: [],
      page: {
        pageNumber: 1,
        pageSize: 20,
        totalRow: 0,
        totalPage: 0,
      },
    };
  },
  methods: {
    getTitle(row) {
      let text = "";
      let title = "";
      if (row.action) {
        title = row.action.title;
        if (row.actionType == "form_data_create") {
          text = `在「${title}」中新增数据`;
        } else if (row.actionType == "form_data_update") {
          text = `修改「${title}」的数据`;
        } else if (row.actionType == "form_data_delete") {
          text = `删除「${title}」的数据`;
        } else if (row.actionType == "form_data_save") {
          text = `在「${title}」中修改或新增数据`;
        }
      } else {
        if (row.actionType == "form_data_create") {
          text = `新增数据`;
        } else if (row.actionType == "form_data_update") {
          text = `修改数据`;
        } else if (row.actionType == "form_data_delete") {
          text = `删除数据`;
        } else if (row.actionType == "form_data_save") {
          text = `修改或新增数据`;
        }
      }

      return text;
    },
    close() {
      this.$emit("input", false);
    },
    loadingList() {
      getSpiritLog({
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        spiritId: this.spiritId,
      }).then((res) => {
        let page = res.data.page;
        this.page.pageNumber = page.pageNumber;
        this.page.pageSize = page.pageSize;
        this.page.totalRow = page.totalRow;
        this.page.totalPage = page.totalPage;
        this.list = page.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #f4f5f7;
  .d-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 40px;
    background-color: #ffffff;
  }
}
</style>
