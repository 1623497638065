<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="800px"
    @open="open"
    @closed="closed"
  >
    <div slot="title" style="font-size: 14px">配置输入源</div>
    <div class="etl-source">
      <div class="main-left">
        <div class="weui align-center" style="margin-bottom: 10px">
          <div style="font-size: 14px; margin-right: 10px">1.选择输入源</div>
          <el-input
            v-model="keyword"
            placeholder="请输入名称来搜索"
            size="small"
            style="flex: 1"
            prefix-icon="el-icon-search"
            @input="changeKeyword"
          ></el-input>
        </div>
        <!-- <div class="form-title" v-if="chkForm" v-text="chkForm.title"></div> -->
        <div>
          <el-collapse v-model="activeCollapse" accordion>
            <el-collapse-item title="表单" name="表单">
              <div
                class="source-list"
                v-infinite-scroll="loadingList"
                :infinite-scroll-distance="10"
              >
                <div
                  class="source-item"
                  :class="chkForm && chkForm.id == item.id ? 'active' : ''"
                  v-for="item in page.list"
                  :key="item.id"
                  @click="handelClick(item)"
                >
                  <div class="source-text" v-text="item.title"></div>
                  <i
                    v-if="chkForm && chkForm.id == item.id"
                    class="el-icon-success"
                    style="color: #0db3a6"
                  ></i>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="集团表单" name="集团表单">
              <template>
                <!-- <el-collapse-item title="集团表单" name="4"> -->
                <!-- <div class="tab-title1">集团表单</div> -->
                <div
                  class="source-list"
                  style="padding: 0px 10px 60px 10px; min-height: 200px"
                >
                  <el-collapse accordion>
                    <el-collapse-item
                      :title="item.companyName"
                      :name="item.companyId"
                      v-for="(item, index) in groupPage.list"
                      :key="index"
                    >
                      <div class="box-padding">
                        <div
                          class="source-item"
                          :class="
                            chkForm && chkForm.id == item.id ? 'active' : ''
                          "
                          v-for="item in item.dataList"
                          :key="item.id"
                          @click="handelClick(item)"
                        >
                          <div class="source-text" v-text="item.title"></div>
                          <i
                            v-if="chkForm && chkForm.id == item.id"
                            class="el-icon-success"
                            style="color: #0db3a6"
                          ></i>
                        </div>
                      </div>
                      <!-- <el-tree
                        node-key="id"
                        :props="props"
                        highlight-current
                        current-node-key="1136670990391173120"
                        :data="item.dataList"
                        @node-click="handelClick"
                        default-expand-all
                      >
                      </el-tree> -->
                    </el-collapse-item>
                  </el-collapse>
                  <div
                    v-if="!groupTableLoading && groupPage.list.length == 0"
                    v-loading="groupTableLoading"
                    element-loading-text="加载中"
                    element-loading-spinner="el-icon-loading"
                    style="height: 100px"
                    class="loading"
                  >
                    <div class="noData">暂无数据</div>
                  </div>
                </div>
                <!-- </el-collapse-item> -->
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="main-right">
        <div class="weui align-center" style="margin-bottom: 10px">
          <div style="font-size: 14px; margin-right: 10px">2.选择字段</div>
          <el-input
            v-model="fieldTitle"
            placeholder="请输入名称来搜索"
            size="small"
            style="width: 200px"
            prefix-icon="el-icon-search"
          ></el-input>
        </div>
        <div class="weui" style="flex: 1; overflow: hidden">
          <div class="box-flex">
            <div
              style="font-size: 14px; height: 40px"
              class="weui align-center"
            >
              <div>主表</div>
              <div class="form-title" v-if="chkForm">{{ chkForm.title }}</div>
            </div>
            <div class="flex-scroll">
              <el-checkbox
                :disabled="!chkForm"
                v-model="allFields"
                :indeterminate="!allFields && selectFields.length > 0"
                style="display: block; margin-bottom: 5px"
                >全选</el-checkbox
              >
              <el-checkbox-group v-if="chkForm" v-model="mainComponents">
                <el-checkbox
                  v-for="item in componentList"
                  :label="item.name"
                  :key="item.name"
                  style="display: block; margin-bottom: 5px"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="box-flex">
            <div class="weui align-center" style="height: 40px">
              <div style="font-size: 14px; margin-right: 10px">子表单</div>
              <el-select
                v-if="chkForm"
                v-model="selectSubField.name"
                placeholder=""
                size="mini"
                :disabled="chkForm.jsonList.length == 0"
                @change="changeSubField"
              >
                <el-option
                  v-for="item in chkForm.jsonList"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
            <div class="flex-scroll">
              <el-checkbox
                v-model="allSubFields"
                :indeterminate="
                  !allSubFields &&
                  selectSubField.jsonFields &&
                  selectSubField.jsonFields.length > 0
                "
                :disabled="!chkForm"
                style="display: block; margin-bottom: 5px"
                >全选</el-checkbox
              >
              <el-checkbox-group v-if="chkForm" v-model="jsonComponents">
                <el-checkbox
                  v-for="item in jsonOptioins"
                  :label="item.name"
                  :key="item.name"
                  style="display: block; margin-bottom: 5px"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button
        size="small"
        @click="dialogVisible = false"
        style="margin-right: 10px"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getFormComponents, isEmpty } from "@/zgg-core/utils";
import { etlWhiteFields } from "@/zgg-core/whiteList";
import { isExistFun } from "./util";
import { groupFormList } from "@/views/lowCode/form/api";
import {
  buildNodeFields,
  changeChildrenData,
  clearChildrenConfig,
} from "./util";
export default {
  inject: ["fetchFormList", "getFormDetail", "fetchMetaFieldComponentList"],

  props: {
    node: Object,
    visible: Boolean,
    etlList: Array,
    etlLines: Array,
  },

  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    mainComponents: {
      get() {
        return this.selectFields.map((item) => item.name);
      },
      set(list) {
        let selectFields = this.selectFields.filter((item) =>
          list.includes(item.name),
        );
        let ids = list.filter(
          (val) => !selectFields.map((item) => item.name).includes(val),
        );
        if (ids && ids.length) {
          let component = this.chkForm.components.find(
            (item) => item.name == ids[0],
          );

          selectFields.push({
            collection: this.chkForm.id,
            name: component.name,
            comment: component.title,
            componentName: component.componentName,
            picker: component.picker,
            component: this._.cloneDeep(component),
          });
        }
        this.selectFields = selectFields;
      },
    },
    jsonComponents: {
      get() {
        let list = [];
        if (this.selectSubField.jsonFields) {
          list = this.selectSubField.jsonFields.map((item) => item.name);
        }
        return list;
      },
      set(list) {
        let jsonFields = [];
        if (this.selectSubField.jsonFields) {
          jsonFields = this.selectSubField.jsonFields.filter((item) =>
            list.includes(item.name),
          );
        }
        let ids = list.filter(
          (val) => !jsonFields.map((item) => item.name).includes(val),
        );
        if (ids && ids.length) {
          let component = this.jsonOptioins.find((item) => item.name == ids[0]);
          jsonFields.push({
            collection: this.chkForm.id,
            name: component.name,
            comment: component.title,
            componentName: component.componentName,
            picker: component.picker,
            component: this._.cloneDeep(component),
          });
        }
        this.selectSubField.jsonFields = jsonFields;
      },
    },
    componentList() {
      console.log(this.fieldTitle);
      if (this.chkForm) {
        if (!isEmpty(this.fieldTitle)) {
          return this.chkForm.components.filter(
            (item) => item.title.indexOf(this.fieldTitle) >= 0,
          );
        }
        return this.chkForm.components;
      }
      return [];
    },
    jsonOptioins() {
      if (this.selectSubField.name) {
        let json = this.chkForm.jsonList.find(
          (item) => item.name == this.selectSubField.name,
        );
        if (json) {
          if (!isEmpty(this.fieldTitle)) {
            return json.components.filter(
              (item) => item.title.indexOf(this.fieldTitle) >= 0,
            );
          }
          return json.components;
        }
      }
      return [];
    },
    allFields: {
      get() {
        let list = [];
        if (this.chkForm) {
          list = this.chkForm.components.map((item) => item.name);
        }
        let list2 = this.selectFields
          .filter((item) => list.includes(item.name))
          .map((item) => item.name);
        return list2.length && list2.length == list.length;
      },
      set(val) {
        if (!this.chkForm) {
          return;
        }
        let selectFields = [];

        if (val) {
          this.chkForm.components.forEach((item) => {
            selectFields.push({
              collection: this.chkForm.id,
              name: item.name,
              comment: item.title,
              componentName: item.componentName,
              picker: item.picker,
              component: this._.cloneDeep(item),
            });
          });
        }
        this.selectFields = selectFields;
      },
    },
    allSubFields: {
      get() {
        let list = [];
        if (this.chkForm) {
          list = this.jsonOptioins.map((item) => item.name);
        }
        let list2 = [];
        if (this.selectSubField.jsonFields) {
          list2 = this.selectSubField.jsonFields
            .filter((item) => list.includes(item.name))
            .map((item) => item.name);
        }
        return list2.length && list.length == list2.length;
      },
      set(val) {
        if (!this.chkForm) {
          return;
        }
        let jsonFields = [];
        if (val) {
          this.jsonOptioins.forEach((item) => {
            jsonFields.push({
              collection: this.chkForm.id,
              name: item.name,
              comment: item.title,
              componentName: item.componentName,
              picker: item.picker,
              component: this._.cloneDeep(item),
            });
          });
        }
        this.$set(this.selectSubField, "jsonFields", jsonFields);
      },
    },
  },
  data() {
    return {
      keyword: "",
      fieldTitle: "",
      loading: false,
      page: {
        pageNumber: 0,
        pageSize: 20,
        totalPage: 0,
        totalRow: 0,
        list: [],
      },
      chkForm: null,
      selectFields: [],
      selectSubField: {},
      groupPage: {
        list: [],
      },
      groupTableLoading: false,
      activeCollapse: "表单",
      props: {
        label: "title",
        isLeaf: "leaf",
        id: "id",
      },
    };
  },
  created() {
    this.isExistFun("getFormDetail", "请求表单详情的方法", false);
    this.isExistFun(
      "fetchMetaFieldComponentList",
      "请求表单元数据组件的方法",
      false,
    );
    this.isExistFun("fetchFormList", "请求表单列表的方法", false);
    this.fetchGroupFormList();
  },

  methods: {
    isExistFun,
    closed() {
      this.chkForm = null;
      this.selectFields = [];
      this.selectSubField = {};
      this.keyword = "";
      this.fieldTitle = "";
      this.$emit("update:isOutside", false);
    },

    async open() {
      this.isExistFun("getFormDetail", "请求表单详情的方法", true);
      this.isExistFun(
        "fetchMetaFieldComponentList",
        "请求表单元数据组件的方法",
        false,
      );

      if (this.node.dataSource && this.node.dataSource.formId) {
        let res = await this.getFormDetail(this.node.dataSource.formId);
        let res2 = await this.fetchMetaFieldComponentList(
          this.node.dataSource.formId,
          [
            "flowStatus",
            "_id",
            "currentFlowNodeTitles",
            "currentFlowWorkUserIds",
          ],
        );
        this.chkForm = {
          id: this.node.dataSource.formId,
          title: res.data.form.title,
          components: [],
          jsonList: [],
        };

        this.buildFormDetail(res2);

        let list = this.chkForm.components.map((item) => item.name);
        let selectFields = this.node.selectFields.filter((item) =>
          list.includes(item.name),
        );
        this.selectFields = selectFields;

        if (this.node.selectSubField) {
          let selectSubField = {};
          let subField = this.chkForm.jsonList.find(
            (item) => item.name == this.node.selectSubField.name,
          );
          if (subField) {
            let jsonList = subField.components.map((item) => item.name);
            selectSubField = this._.cloneDeep(this.node.selectSubField);
            selectSubField.jsonFields = selectSubField.jsonFields.filter(
              (item) => jsonList.includes(item.name),
            );
          }
          this.selectSubField = selectSubField;
        }
      }
    },
    save() {
      if (
        !this.selectFields.length &&
        !(
          this.selectSubField.jsonFields &&
          this.selectSubField.jsonFields.length
        )
      ) {
        this.$message.error("至少选择一个字段");
        return;
      }

      if (this.changeChildren()) {
        this.$confirm(
          "此操作会同步更改后续节点的配置，无法还原？",
          "确定要更改输入源？",
        )
          .then(() => {
            clearChildrenConfig(this.node, this.etlList, this.etlLines);
            this.saveConfig(false);
          })
          .catch(() => {});
      } else {
        this.saveConfig(true);
      }
    },
    saveConfig(isChange) {
      this.$set(this.node, "dataSource", {
        sourceType: "form",
        formId: this.chkForm.id,
      });

      this.$set(this.node, "selectFields", this._.cloneDeep(this.selectFields));
      if (this.selectSubField && !isEmpty(this.selectSubField.jsonFields)) {
        this.$set(
          this.node,
          "selectSubField",
          this._.cloneDeep(this.selectSubField),
        );
      } else {
        this.$set(this.node, "selectSubField", undefined);
      }

      this.$set(this.node, "title", this.chkForm.title);
      this.dialogVisible = false;
      if (isChange) {
        changeChildrenData([this.node], this.etlList, this.etlLines);
      }
      buildNodeFields(this.node, this.etlList, this.etlLines);
    },
    changeSubField(val) {
      let subField = this._.cloneDeep(
        this.chkForm.jsonList.find((item) => item.name == val),
      );
      delete subField.components;
      this.selectSubField.comment = subField.title;
      this.selectSubField.jsonFields = [];
      this.selectSubField.component = subField;
    },
    changeChildren() {
      // 修改子孙节点配置
      console.log("检查是否需要修改子孙节点");
      let isChangeChildren = false; // 是否修改子孙节点的配置
      if (
        this.node.dataSource &&
        this.node.dataSource.formId != this.chkForm.id
      ) {
        // 表单发生了改变
        console.log("新表单");
        isChangeChildren = true;
      }

      return isChangeChildren;
    },
    handelClick(item) {
      if (this.chkForm && this.chkForm.id == item.id) {
        return;
      }
      this.chkForm = {
        id: item.id,
        title: item.title,
        components: [],
        jsonList: [],
      };
      this.selectFields = [];
      this.selectSubField = {};
      this.fetchMetaFieldComponentList(item.id, [
        "flowStatus",
        "_id",
        "currentFlowNodeTitles",
        "currentFlowWorkUserIds",
      ]).then(this.buildFormDetail);
    },
    changeKeyword() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        this.page.pageNumber = 0;
        this.loadingList();
        this.fetchGroupFormList();
      }, 200);
    },
    loadingList() {
      this.isExistFun("fetchFormList", "请求表单列表的方法", true);
      if (this.loading) {
        return;
      }

      let pageNumber = this.page.pageNumber + 1;
      if (pageNumber > 1) {
        if (pageNumber > this.page.totalPage) {
          return;
        }
      }
      let title = this.keyword;
      this.loading = true;
      this.fetchFormList({
        pageSize: this.page.pageSize,
        pageNumber,
        removeComponent: true,
        title,
      })
        .then((res) => {
          let page = res.data.page;
          this.page.pageNumber = page.pageNumber;
          this.page.pageSize = page.pageSize;
          this.page.totalRow = page.totalRow;
          this.page.totalPage = page.totalPage;
          if (page.pageNumber == 1) {
            this.page.list = page.list;
          } else {
            this.page.list = this.page.list.concat(page.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildFormDetail(res) {
      if (res.data.list) {
        let components = getFormComponents(res.data.list);
        let jsonList = components.filter(
          (item) => item.componentName == "json_form",
        );
        jsonList.forEach((item) => {
          item.components = item.components.filter((col) =>
            etlWhiteFields.includes(col.componentName),
          );
        });
        components = components.filter((item) =>
          etlWhiteFields.includes(item.componentName),
        );

        if (jsonList.length) {
          let jsonField = this._.cloneDeep(jsonList[0]);
          delete jsonField.components;
          this.selectSubField = {
            collection: this.chkForm.id,
            name: jsonList[0].name,
            comment: jsonList[0].title,
            componentName: "json_form",
            component: jsonField,
            jsonFields: [],
          };
        }

        this.chkForm.components = components;
        this.chkForm.jsonList = jsonList;
      }
    },
    // 集团表单

    fetchGroupFormList() {
      // if (!this.menus.includes("group_form")) {
      //   // 不存在form
      //   return;
      // }
      this.groupPage.list = [];
      this.groupTableLoading = true;
      let postData = {
        title: this.keyword,
      };
      groupFormList(postData)
        .then((res) => {
          this.groupTableLoading = false;
          this.groupPage.list = res.data.companyList;
          this.groupPage.list.forEach((item) => {
            item.dataList.forEach((sub) => {
              sub.leaf = this.mode == "table";
              sub.sourceType = "group_form";
            });
          });
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.etl-source {
  display: flex;

  // margin-top: 10px;
  // border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  height: 425px;
  overflow: hidden;
  .main-left {
    padding: 10px 10px;
    box-sizing: border-box;
    width: 265px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: solid 1px #e6e6e6;
  }
  .main-right {
    padding: 10px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .form-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    margin-left: 10px;
  }
  .weui {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .source-list {
    flex: 1;
    overflow: auto;
    max-height: 320px;

    .source-item {
      display: flex;
      overflow: hidden;
      align-items: center;
      padding: 5px 5px;
      height: 32px;
      cursor: pointer;

      &:hover {
        background-color: #e6f7f6;
      }
      &.active {
        background-color: #e6f7f6;
        .source-text {
          color: #0db3a6;
        }
      }
      .source-text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }
}
.flex-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.box-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.flex-scroll {
  flex: 1;
  overflow: auto;
}

.noData {
  display: flex;
  min-height: 100px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
::v-deep {
  .el-collapse {
    border-top: 0px solid #f4f5f7;
    border-bottom: 0px solid #f4f5f7;
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
}
.box-padding {
  padding: 0 10px;
  //max-height: 300px;
  overflow: auto;
}
::v-deep {
  .el-collapse-item__header {
    line-height: normal;
  }
}
</style>
