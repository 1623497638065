<template>
  <div class="workflow-line-btn">
    <!-- 添加按钮icon -->
    <i
      v-if="isShowBtnIcon"
      @click.prevent.stop="showActions"
      class="el-icon-circle-plus add-icon"
    ></i>
    <div v-else-if="isAddClick && !isCopy" class="workflow-block">
      选择要执行的动作
      <!-- ，或<span
        @click.prevent.stop="copy"
        class="copy"
        id="copyAction"
        :style="`z-index:${zIndex}`"
        >复制</span
      >已有节点 -->
    </div>
    <div v-else-if="isCopy" class="workflow-block">复制到此处</div>
    <rpa-actions
      :visible.sync="isAddClick"
      :zIndex.sync="zIndex"
      :add="add"
      :addBrach="addBrach"
      :node="node"
    ></rpa-actions>
  </div>
</template>
<script>
import { isEmpty, uuid } from "@zgg-core-utils/utils";
import { nodeTypeList } from "./loopTypeEnums";
import rpaActions from "./rpa-actions";

export default {
  components: { rpaActions },
  provide() {
    return {
      getToNodes: this.getChildNodes,
      getFromNodes: this.getFromNodes,
    };
  },
  props: {
    node: Object,
    isPadding: Boolean,
    isCopying: Boolean,
    copyKeys: Array,
    nodes: Array,
    add: Function,
    addBrach: Function,
    getChildNodes: Function,
    getFromNodes: Function,
  },
  computed: {
    isShowBtnIcon() {
      if (this.isAddClick) {
        return false;
      }
      if (this.isCopy) {
        return false;
      }
      let obj = nodeTypeList.find((item) => item.value == this.node.type);
      if (obj.type !== "trigger") {
        return true;
      }
      let isShowAddIcon = false;
      if (
        ["trigger_form_data_change", "trigger_timer_field"].includes(
          this.node.type,
        )
      ) {
        isShowAddIcon = !isEmpty(this.node.formId);
      } else if (
        ["trigger_timer_once", "trigger_timer_cron"].includes(this.node.type)
      ) {
        isShowAddIcon = !isEmpty(this.node.executeTime);
      } else if (["trigger_webhook"].includes(this.node.type)) {
        isShowAddIcon =
          !isEmpty(this.node.webhookUrl) &&
          !isEmpty([
            ...this.node.header,
            ...this.node.body,
            ...this.node.params,
          ]);
      } else if (["trigger_click"].includes(this.node.type)) {
        // 触发器类型:点击触发
        isShowAddIcon = !isEmpty(this.node.collection);
      } else if (this.node.type == "trigger_subflow_collection") {
        isShowAddIcon = true;
      }

      return isShowAddIcon;
    },
  },
  watch: {
    isAddClick(val) {
      this.$emit("update:isPadding", val);
    },
  },
  data() {
    return {
      isCopy: false,
      isAddClick: false,
      zIndex: 1000,
    };
  },
  methods: {
    showActions() {
      if (!this.isCopying) {
        this.isAddClick = true;
      }
    },
    copy() {
      this.isAddClick = false;
      this.isCopy = true;
      this.$emit("update:isCopying", true);
      let h = this.$createElement;
      let $message = this.$message({
        iconClass: "t",
        customClass: "copy-message",
        offset: 80,
        onClose: () => {
          this.$emit("update:isCopying", false);
        },
        message: h("div", { class: "copy-row" }, [
          h(
            "div",
            { class: "copy-text copy-rpa-node-tip" },
            "选择要复制的节点，依次复制到目标位置",
          ),
          h(
            "div",
            {
              class: "copy-cancel",
              on: {
                click: () => {
                  this.isCopy = false;
                  $message.close();
                },
              },
            },
            "取消",
          ),
          h(
            "div",
            {
              class: "copy-confirm",
              on: {
                click: () => {
                  this.confirmCopy($message);
                },
              },
            },
            "复制",
          ),
        ]),
        duration: 0,
      });
    },
    confirmCopy($msg) {
      if (this.copyKeys.length == 0) {
        this.$message({
          type: "error",
          message: "请选择高亮节点进行复制",
          duration: 2000,
          offset: 150,
        });
        return;
      }
      let list = [];
      this.copyKeys.forEach((key) => {
        let obj = this._.cloneDeep(this.nodes.find((item) => item.key == key));
        if (obj) {
          obj.key = uuid();
          obj.title = obj.title + "-复制";
          list.push(obj);
        }
      });
      list.forEach((item, index) => {
        let fromKey;
        if (index == 0) {
          fromKey = this.node.key;
        } else {
          fromKey = list[index - 1].key;
        }
        this.add(this._.cloneDeep(item), fromKey);
      });
      this.isCopy = false;
      $msg.close();
    },
  },
};
</script>
<style lang="scss">
.copy-message {
  background-color: #3ea4fc;
  border-color: transparent;
  border-radius: 40px;
  padding: 10px 20px;
}
</style>
<style lang="scss" scoped>
.workflow-line-btn {
  padding: 16px 0;
  position: relative;
  width: 274px;
  text-align: center;
  .add-icon {
    background-color: #f4f5f7;
    font-size: 24px;
    color: #cccccc;
    cursor: pointer;
    position: relative;
    z-index: 2;
    &:hover {
      color: #4b4b4b;
    }
  }
}

.workflow-block {
  align-items: center;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 26px #2196f3;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 20px 0;
  position: relative;
  width: 100%;
  font-size: 14px;
  pointer-events: all;
  .copy {
    color: #1e88e5;
    cursor: pointer;
    z-index: 10;
  }
}

.copy-row {
  display: flex;
  align-items: center;
  font-size: 13px;
  flex: 1;
  color: #ffffff;
  .copy-text {
    flex: 1;
    margin-right: 20px;
  }
  .copy-cancel {
    color: #ffffff;
    height: 28px;
    line-height: 28px;
    padding: 0px 18px;
    border-radius: 28px;
    cursor: pointer;
    margin-right: 8px;
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 0.1);
    }
  }
  .copy-confirm {
    cursor: pointer;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    padding: 0px 18px;
    border-radius: 28px;
    color: #3ea4fc;
    background-color: #ffffff;
    font-weight: 600;
    &:hover {
      background-color: rgba($color: #b4dbfc, $alpha: 0.9);
    }
  }
}
</style>
