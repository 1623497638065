<template>
  <div class="container">
    <div class="flow-content">
      <div class="flow-scroll">
        <process-flow
            :node-list="flowNodes"
            :line-list="flowLines"
            ref="processFlow"
            class="flex-body"
            :workflowType="workflowType"
        ></process-flow>
      </div>
    </div>
  </div>
</template>
<script>
import { uuid } from "@zgg-core-utils/utils";
import processFlow from "./process-flow.vue";


export default {
  props: {
    workflow: Object,
    workflowType: {
      type: String,
      default() {
        return "chat";
      },
    },
  },
  components: {
    processFlow,
  },
  data() {
    return {
      flowNodes: [],
      flowLines: [],
      flow: {},
    };
  },
  created() {
    if (this.workflow) {
      this.flowNodes = this._.cloneDeep(this.workflow.flow.flowNodes);
      this.$nextTick(() => {
        let flowLines = this._.cloneDeep(this.workflow.flow.flowLines);
        flowLines.forEach((line) => {
          this.$refs.processFlow.setLineProps(line);
        });
        this.flowLines = flowLines;
        this.flow = this.workflow.flow.flowConfig;
        this.$refs.processFlow.redrawLine();
      });
    }
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.flow-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  background: #f2f6fc;
}
.flex-body {
  flex: 1;
  position: relative;
  overflow: auto;
}
.flow-scroll {
  flex: 1;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}
.flex {
  flex: 1;
}

</style>
