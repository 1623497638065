<!--
 * @Description: 标题组件
 * @Version: 0.1
 * @Autor: zhengxn
 * @Date: 2022-12-08 14:07:00
-->

<template>
  <div class="dashboard-filter__container selectTextarea">
    <div
      class="selectTextarea"
      style="width: 100%;height: 100%;overflow: hidden"
    >
      <div
        v-if="showToolbar"
        class="note-toolbar selectTextarea"
        role="toolbar"
      >
        <div class="note-btn-group note-font selectTextarea">
          <div
            @click="setBold()"
            class="note-btn selectTextarea"
            :class="
              dashboardTopicModel.extParam.weight == 'bold' ? 'active' : ''
            "
            title="粗体"
          >
            <img
              class="selectTextarea"
              src="@/assets/add-component/font/icon1.png"
              alt=""
            />
          </div>
          <div
            @click="setItalic()"
            class="note-btn selectTextarea"
            :class="
              dashboardTopicModel.extParam.italic == 'italic' ? 'active' : ''
            "
            title="斜体"
          >
            <img
              class="selectTextarea"
              src="@/assets/add-component/font/icon2.png"
              alt=""
            />
          </div>
          <div
            @click="setUnderline()"
            class="note-btn selectTextarea"
            :class="
              dashboardTopicModel.extParam.underline == 'underline'
                ? 'active'
                : ''
            "
            title="下划线"
          >
            <img
              class="selectTextarea"
              src="@/assets/add-component/font/icon3.png"
              alt=""
            />
          </div>
          <div class="note-btn selectTextarea" title="段落">
            <!-- <img src="@/assets/add-component/font/icon5.png" alt=""> -->
            <el-dropdown
              class="selectTextarea"
              trigger="click"
              @command="setParagraph"
            >
              <img
                class="selectTextarea"
                src="@/assets/add-component/font/icon5.png"
                alt=""
              />
              <el-dropdown-menu class="selectTextarea" slot="dropdown">
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.align == 'left'
                  }"
                  class="selectTextarea"
                  command="left"
                  >左</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.align == 'center'
                  }"
                  class="selectTextarea"
                  command="center"
                  >中</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.align == 'right'
                  }"
                  class="selectTextarea"
                  command="right"
                  >右</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            @click="showSelect()"
            class="note-btn selectTextarea"
            title="字体颜色"
          >
            <img
              class="selectTextarea"
              src="@/assets/add-component/font/icon4.png"
              alt=""
            />
            <div v-if="show" class="cont-div selectTextarea">
              <div
                v-for="item in colorList"
                :key="item"
                class="content-item selectTextarea"
                :style="'text-align:center;background-color:' + item"
                @click="selectVal(item)"
              >
                <i
                  v-if="dashboardTopicModel.extParam.color == item"
                  class="el-icon-check"
                  :style="
                    `color:${
                      item == '#FFFFFF' ? '#000000' : '#FFFFFF'
                    };line-height:30px;`
                  "
                ></i>
              </div>
            </div>
            <!-- <el-color-picker class="selectTextarea" @change="setColor" size="small" v-model="dashboardTopicModel.extParam.color"></el-color-picker> -->
          </div>
          <div class="note-btn selectTextarea" title="字号">
            <el-dropdown
              class="selectTextarea"
              trigger="click"
              @command="setSize"
            >
              <img
                style="width:100%"
                class="selectTextarea"
                src="@/assets/add-component/font/icon7.png"
                alt=""
              />
              <el-dropdown-menu class="selectTextarea" slot="dropdown">
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '12px'
                  }"
                  command="12px"
                  >12</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '14px'
                  }"
                  command="14px"
                  >14</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '16px'
                  }"
                  command="16px"
                  >16</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '18px'
                  }"
                  command="18px"
                  >18</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '20px'
                  }"
                  command="20px"
                  >20</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '22px'
                  }"
                  command="22px"
                  >22</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '24px'
                  }"
                  command="24px"
                  >24</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '28px'
                  }"
                  command="28px"
                  >28</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '32px'
                  }"
                  command="32px"
                  >32</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '36px'
                  }"
                  command="36px"
                  >36</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '48px'
                  }"
                  command="48px"
                  >48</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '52px'
                  }"
                  command="52px"
                  >52</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '56px'
                  }"
                  command="56px"
                  >56</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '64px'
                  }"
                  command="64px"
                  >64</el-dropdown-item
                >
                <el-dropdown-item
                  :class="{
                    selected: dashboardTopicModel.extParam.size == '128px'
                  }"
                  command="128px"
                  >128</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div @click="setLink" class="note-btn selectTextarea" title="链接">
            <img
              class="selectTextarea"
              src="@/assets/add-component/font/icon6.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <textarea
        class="selectTextarea"
        @click="onClick"
        v-model="dashboardTopicModel.title"
        style="resize:none;"
        :style="
          `background-image: url(${url});line-height: 50%;
     font-weight:${dashboardTopicModel.extParam.weight};color:${
            dashboardTopicModel.extParam.color
          };
     text-align:${dashboardTopicModel.extParam.align};text-decoration:${
            dashboardTopicModel.extParam.underline
          };
     font-size:${dashboardTopicModel.extParam.size};font-style:${
            dashboardTopicModel.extParam.italic
          }`
        "
      ></textarea>
    </div>
    <el-dialog
      title="链接"
      append-to-body
      destroy-on-close
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div
        style="padding: 24px; display: flex; flex-direction: column;"
        v-if="dialogVisible"
      >
        <div class="form-item__wrap">
          <span class="form-item__wrap-title titlespan">链接</span>
          <el-input v-model="link" placeholder="请输入" size="small"></el-input>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import stylebg1 from "@/assets/add-component/topic/style1-bg.png";
import stylebg2 from "@/assets/add-component/topic/style2-bg.png";
import stylebg3 from "@/assets/add-component/topic/style3-bg.png";
import stylebg4 from "@/assets/add-component/topic/style4-bg.png";

export default {
  name: "Topic",
  props: {
    dashboardTopicModel: {
      type: Object,
      default() {
        return {
          extParam: {
            link: "",
            color: "#000",
            align: "center",
            size: "16px",
            weight: "bold",
            italic: "normal",
            underline: "none"
          },
          titleStyle: "",
          title: "",
          componentName: "dashboard_topic"
        };
      }
    }
  },
  data() {
    return {
      stylebg1: stylebg1,
      stylebg2: stylebg2,
      stylebg3: stylebg3,
      stylebg4: stylebg4,
      url: "",
      showToolbar: false,
      dialogVisible: false,
      link: "",
      color: "",
      show: false,
      colorList: [
        "#6BDAAE",
        "#7B67EE",
        "#3870EA",
        "#035885",
        "#08F8E9",
        "#F94144",
        "#D9ED92",
        "#FF9D66",
        "#FF7875",
        "#000000",
        "#FFFFFF"
      ]
    };
  },
  created() {
    if (this.dashboardTopicModel.titleStyle == "topic-style1") {
      this.url = this.stylebg1;
    }
    if (this.dashboardTopicModel.titleStyle == "topic-style2") {
      this.url = this.stylebg2;
    }
    if (this.dashboardTopicModel.titleStyle == "topic-style3") {
      this.url = this.stylebg3;
    }
    if (this.dashboardTopicModel.titleStyle == "topic-style4") {
      this.url = this.stylebg4;
    }
  },
  watch: {
    "dashboardTopicModel.titleStyle"(val) {
      if (this.dashboardTopicModel.titleStyle == "topic-style1") {
        this.url = this.stylebg1;
      }
      if (this.dashboardTopicModel.titleStyle == "topic-style2") {
        this.url = this.stylebg2;
      }
      if (this.dashboardTopicModel.titleStyle == "topic-style3") {
        this.url = this.stylebg3;
      }
      if (this.dashboardTopicModel.titleStyle == "topic-style4") {
        this.url = this.stylebg4;
      }
    }
  },

  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    onClick(e) {
      this.showToolbar = true;
    },
    bodyCloseMenus(e) {
      let self = this;
      console.log(
        e.target.className,
        !e.target.className.includes("selectTextarea")
      );
      if (e.target.className.includes("selectTextarea")) {
        self.showToolbar = true;
      } else {
        self.showToolbar = false;
      }
      if (e.target.className.includes("el-color-picker__icon")) {
        self.showToolbar = true;
      }
    },
    //加粗
    setBold() {
      if (this.dashboardTopicModel.extParam.weight == "bold") {
        this.dashboardTopicModel.extParam.weight = "normal";
      } else {
        this.dashboardTopicModel.extParam.weight = "bold";
      }
    },
    //斜体
    setItalic() {
      if (this.dashboardTopicModel.extParam.italic == "italic") {
        this.dashboardTopicModel.extParam.italic = "normal";
      } else {
        this.dashboardTopicModel.extParam.italic = "italic";
      }
    },
    //下划线
    setUnderline() {
      if (this.dashboardTopicModel.extParam.underline == "underline") {
        this.dashboardTopicModel.extParam.underline = "none";
      } else {
        this.dashboardTopicModel.extParam.underline = "underline";
      }
    },
    //设置段落
    setParagraph(val) {
      this.dashboardTopicModel.extParam.align = val;
    },
    //设置颜色
    // setColor(val){
    //   this.color = val
    //   this.dashboardTopicModel.extParam.color = val
    // },
    showSelect() {
      this.show = !this.show;
    },
    selectVal(val) {
      this.color = val;
      this.dashboardTopicModel.extParam.color = val;
    },
    //设置字体
    setSize(val) {
      this.dashboardTopicModel.extParam.size = val;
    },
    //设置链接
    setLink() {
      this.dialogVisible = true;
    },
    cancel() {
      this.dashboardTopicModel.link = "";
      this.dialogVisible = false;
    },
    saveForm() {
      if (this.link) {
        this.dashboardTopicModel.extParam.link = this.link;
        //this.dashboardTopicModel.extParam.color = "#178cdf"
      } else {
        //this.dashboardTopicModel.extParam.color = this.color
      }
      this.dialogVisible = false;
    },

    getData() {
      console.log("dashboardFilter");
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  }
};
</script>
<style lang="scss" scoped>
.dashboard-filter__container {
  width: 100%;
  height: 100%;
}
textarea {
  background: transparent;
  border: 0;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.note-toolbar {
  position: absolute;
  top: 42px;
  right: 2px;
  display: flex;
  align-items: center;
  //width: 276px;
  width: 250px;
  height: 38px;
  padding: 0 9px;
  background: #fff;
  border: none;
  border-radius: 3px;
  // -webkit-box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
  // box-shadow: 0px 2px 8px 0px rgba(0 0 0 / 15%)
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
}
.note-btn-group {
  display: inline-block;
  display: flex;
  flex-direction: row;
}
.note-btn-group .note-btn {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 35px;
  //  background: red;
  border-radius: 5px;
  margin: 2px;
  cursor: pointer;
}
.note-btn:hover {
  background: #d7d9dc;
}
.active {
  background: #d7d9dc;
}
.el-dropdown {
  right: 0px;
}
.el-color-picker--small .el-color-picker__trigger {
  height: 30px !important;
  width: 30px !important;
}
.cont-div {
  width: 236px;
  display: flex;
  flex-direction: row;
  border: 1px solid #dfe1e6;
  padding: 4px;
  border-radius: 5px;
  background: #ffffff;
  //  width:90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .content {
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
  }
  margin-left: -132px;
}
.content-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  margin: 2px;
  border: 1px solid #ebecf0;
}
.selected {
  color: #409eff;
}
</style>
