import moment from "moment";
import {
  companyWorkflowNodeList,
  formExportWordPreview,
} from "@/views/lowCode/form/templateSetting/api";
import axios from "axios";

export const templateMixin = {
  data() {
    return {
      nodeList: [],
      optionWidth: "",
      isShowTimeSet: false,
      signSortList: [
        {
          value: 0,
          label: "按审批顺序",
        },
        {
          value: 1,
          label: "流程配置顺序",
        },
      ],
      nodeFieldEnums: [
        {
          value: "nodeTitle",
          label: "节点名称",
        },
        {
          value: "opinion",
          label: "审批意见",
        },
        {
          value: "signInfo",
          label: "签名",
        },
        {
          value: "operatorUser",
          label: "操作人",
        },
        {
          value: "action",
          label: "审批操作",
        },
        {
          value: "actionTime",
          label: "审批时间",
        },
      ],
    };
  },
  methods: {
    isShowSelectModel(val) {
      let component;
      if (val.name) {
        component = this.getSubcomponentList(val.metaFieldId).find((item) => {
          return item.name == val.name;
        });
      } else {
        component = this.formFieldList.find((item) => {
          return item.id == val.metaFieldId;
        });
      }
      return [
        "radio_group",
        "checkbox_group",
        "select",
        "select_checkbox",
      ].includes(component?.componentName);
    },
    showDrawer(val) {
      this.currentField = val;
      if (val.flowName == "actionTime") {
        this.isShowTimeSet = true;
      } else {
        let component;
        if (val.name) {
          component = this.getSubcomponentList(val.metaFieldId).find((item) => {
            return item.name == val.name;
          });
        } else {
          component = this.formFieldList.find((item) => {
            return item.id == val.metaFieldId;
          });
        }
        this.isShowTimeSet = ["date_picker"].includes(component?.componentName);
      }
      this.drawer = true;
    },
    changePicker() {
      this.$set(this.currentField, "format", this.options[0].value);
    },
    setOptionWidth(e) {
      this.$nextTick(() => {
        this.optionWidth = e.srcElement.offsetWidth + "px";
      });
    },
    isShowFlowModel(row) {
      return row.flowName && row.nodeKey != "all";
    },
    flowNodeFieldEnumsChange(row) {
      this.$set(row, "flowIndex", 1);
      this.$set(row, "flowModel", 0);
    },
    modelChange(row) {
      if (row.flowModel === 1) {
        this.$set(row, "flowIndex", 1);
        this.$set(row, "signSort", 0);
      }
    },
    handlePreview() {
      formExportWordPreview({
        formId: this.formId,
        appFormExportWordId: this.appFormExportWordId,
      })
        .then((resp) => {
          let { url } = resp.data.attachmentConverter;
          window.open(url);
        })
        .catch((err) => {
          this.$message.warning("预览失败");
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭配置？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleClear(row) {
      if (row.width) {
        Reflect.deleteProperty(row, "width");
      }
      if (row.height) {
        Reflect.deleteProperty(row, "height");
      }
    },
    handleBlobDownload(url, fileName) {
      axios.get(url, { responseType: "blob" }).then((res) => {
        const blob = new Blob([res.data]);
        const downLoadEle = document.createElement("a");
        const href = URL.createObjectURL(blob);
        downLoadEle.href = href;
        downLoadEle.download = fileName;
        document.body.appendChild(downLoadEle);
        downLoadEle.click();
        document.body.removeChild(downLoadEle);
        window.URL.revokeObjectURL(href);
      });
    },
    subformFieldConfigurable(val) {
      let subFieldList = this.getSubcomponentList(val.metaFieldId);
      let subFiledItem = subFieldList.find((item) => {
        return item.name == val.name;
      });
      if (subFiledItem) {
        if (this.hasConfigComponents.includes(subFiledItem.componentName)) {
          return true;
        }
      }
      return false;
    },
    formFieldConfigurable(val) {
      //表单字段
      let filedItem = this.formFieldList.find((item) => {
        return item.id == val.metaFieldId;
      });
      if (filedItem) {
        if (this.hasConfigComponents.includes(filedItem.componentName)) {
          return true;
        }
      }
      return false;
    },
    getWorkflowNodeList(val) {
      companyWorkflowNodeList({
        workflowId: val,
      }).then((res) => {
        this.nodeList = res.data.flowNodes;
        this.nodeList.unshift({
          key: "all",
          title: "全部节点",
        });
      });
    },
  },
  computed: {
    options() {
      if (this.currentField.picker == "year") {
        return [
          {
            label: moment().format("YYYY"),
            value: "YYYY",
          },
          {
            label: moment().format("YYYY年"),
            value: "YYYY年",
          },
        ];
      } else if (this.currentField.picker == "month") {
        return [
          {
            label: moment().format("YYYY-MM"),
            value: "YYYY-MM",
          },
          {
            label: moment().format("YYYY年MM月"),
            value: "YYYY年MM月",
          },
          {
            label: moment().format("YYYY.MM"),
            value: "YYYY.MM",
          },
          {
            label: moment().format("YYYY/MM"),
            value: "YYYY/MM",
          },
        ];
      } else if (this.currentField.picker == "date") {
        return [
          {
            label: moment().format("YYYY-MM-DD"),
            value: "YYYY-MM-DD",
          },
          {
            label: moment().format("YYYY年MM月DD日"),
            value: "YYYY年MM月DD日",
          },
          {
            label: moment().format("YYYY.MM.DD"),
            value: "YYYY.MM.DD",
          },
          {
            label: moment().format("YYYY/MM/DD"),
            value: "YYYY/MM/DD",
          },
        ];
      } else if (this.currentField.picker == "date_hour") {
        return [
          {
            label: moment().format("YYYY-MM-DD HH"),
            value: "YYYY-MM-DD HH",
          },
          {
            label: moment().format("YYYY年MM月DD日 HH"),
            value: "YYYY年MM月DD日 HH",
          },
          {
            label: moment().format("YYYY.MM.DD HH"),
            value: "YYYY.MM.DD HH",
          },
          {
            label: moment().format("YYYY/MM/DD HH"),
            value: "YYYY/MM/DD HH",
          },
        ];
      } else if (this.currentField.picker == "date_minute") {
        return [
          {
            label: moment().format("YYYY-MM-DD HH:mm"),
            value: "YYYY-MM-DD HH:mm",
          },
          {
            label: moment().format("YYYY年MM月DD日 HH:mm"),
            value: "YYYY年MM月DD日 HH:mm",
          },
          {
            label: moment().format("YYYY.MM.DD HH:mm"),
            value: "YYYY.MM.DD HH:mm",
          },
          {
            label: moment().format("YYYY/MM/DD HH:mm"),
            value: "YYYY/MM/DD HH:mm",
          },
        ];
      } else if (this.currentField.picker == "datetime") {
        return [
          {
            label: moment().format("YYYY-MM-DD HH:mm:ss"),
            value: "YYYY-MM-DD HH:mm:ss",
          },
          {
            label: moment().format("YYYY年MM月DD日 HH:mm:ss"),
            value: "YYYY年MM月DD日 HH:mm:ss",
          },
          {
            label: moment().format("YYYY.MM.DD HH:mm:ss"),
            value: "YYYY.MM.DD HH:mm:ss",
          },
          {
            label: moment().format("YYYY/MM/DD HH:mm:ss"),
            value: "YYYY/MM/DD HH:mm:ss",
          },
        ];
      }
      return [];
    },
  },
};
