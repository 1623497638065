<template>
  <div
    class="workflow-box"
    :class="node.type == 'condition' ? 'workflow-condition' : ''"
  >
    <div
      class="workflow-item"
      :class="[
        !isCanCopy && isCopying ? 'is-disabled' : '',
        { workflow_error: isError },
        nodeClass,
      ]"
      @click="showDrawer"
    >
      <div v-if="nodeClass" class="flowed-icon">
        <i
          v-if="nodeClass == 'flowed-success'"
          class="el-icon-success"
          style="color: #67c23a"
        ></i>
        <i v-else class="el-icon-error" style="color: #f56c6c"></i>
      </div>
      <!-- 复制时候可选择的icon -->
      <div v-if="isCanCopy && isCopying" @click="checkNode" class="copy-check">
        <i v-if="copyIndex == -1" class="el-icon-check"></i>
        <span class="copy-number" v-else v-text="copyIndex + 1"></span>
      </div>
      <!-- 头部icon -->
      <div
        class="workflow-avatar"
        v-if="node.type != 'condition'"
        :class="bgClass"
      >
        <i :class="typeIcon" style="color: #ffffff; margin: 0"></i>
      </div>
      <!-- 更多操作 -->
      <el-popover
        v-model="moreVisible"
        class="rpa-node-popover"
        v-if="!disabled"
        trigger="click"
      >
        <i
          @click.prevent.stop=""
          slot="reference"
          class="el-icon-more"
          style="font-size: 14px; padding: 4px 8px"
          :style="{ color: node.type == 'condition' ? '#333' : '#fff' }"
        ></i>
        <div>
          <div @click="openEdit" class="more-item">
            <i class="el-icon-edit"></i>
            <div class="more-text">备注说明</div>
          </div>
          <div v-if="!isTrigger" @click="del" class="more-item">
            <i class="el-icon-delete"></i>
            <div class="more-text">删除</div>
          </div>
        </div>
      </el-popover>

      <!-- 节点名称 -->
      <div
        v-if="
          node.type != 'condition' &&
          node.queryDataExist !== true &&
          node.queryDataExist !== false
        "
        class="workflow-name"
        :class="bgClass"
      >
        <div class="workflow-name-text" v-text="node.title"></div>
      </div>
      <!-- 节点内容 -->
      <div class="workflow-content">
        <!-- 数据变动触发 -->
        <template v-if="node.type == 'trigger_form_data_change'">
          <template v-if="isSetting">
            <div class="workflow-content-info ellipsis info-bg">
              <span class="gray_75">工作表</span>"{{ node.formTitle }}"
            </div>
            <div
              class="workflow-content-info ellipsis"
              v-text="changeMap[node.dataChangeType]"
            ></div>
          </template>
          <div v-else class="workflow-start">设置触发方式</div>
        </template>
        <!-- 子流程触发 -->
        <template v-else-if="node.type == 'trigger_subflow_collection'">
          <div class="workflow-content-info ellipsis info-bg">
            <span class="gray_75">工作表</span>"{{ node.collectionTitle }}"
          </div>
        </template>
        <!-- 定时单次触发 -->
        <template v-else-if="node.type == 'trigger_timer_once'">
          <template v-if="node.executeTime">
            <div
              class="workflow-content-info ellipsis"
              v-text="formatByDate(node.executeTime)"
            ></div>
          </template>
          <div v-else class="workflow-start">设置触发方式</div>
        </template>
        <!-- 定时多次触发 -->
        <template v-else-if="node.type == 'trigger_timer_cron'">
          <template v-if="node.executeTime">
            <div
              class="workflow-content-info ellipsis"
              style="font-size: 12px; color: #787878"
              v-text="timerCronText"
            ></div>
            <div
              class="workflow-content-info ellipsis"
              v-text="timerCronLoopType"
            ></div>
          </template>
          <div v-else class="workflow-start">设置触发方式</div>
        </template>
        <!-- 按日期字段触发 -->
        <template v-else-if="node.type == 'trigger_timer_field'">
          <template v-if="isSetting">
            <div class="workflow-content-info ellipsis info-bg">
              <span class="gray_75">工作表</span>"{{ node.formTitle }}"
            </div>
            <div class="workflow-content-info ellipsis">
              <span v-text="node.field.fieldTitle"></span>
              <span v-text="timeFieldText"></span>
              <span>执行</span>
            </div>
          </template>
          <div v-else class="workflow-start">设置触发方式</div>
        </template>
        <!-- Webhook触发 -->
        <template v-else-if="node.type == 'trigger_webhook'">
          <template v-if="node.webhookUrl && paramsNum > 0">
            <div
              class="workflow-content-info ellipsis"
              style="font-size: 12px; color: #787878"
            >
              从请求范例生成参数列表
            </div>
            <div class="workflow-content-info ellipsis">
              <span style="color: #787878">已配置：</span>{{ paramsNum }}个参数
            </div>
          </template>
          <div v-else class="workflow-start">设置触发方式</div>
        </template>
        <!-- 点击触发 -->
        <template v-else-if="node.type == 'trigger_click'">
          <div class="workflow-content-info ellipsis info-bg">
            <span class="gray_75">{{ node.triggerTitle }}</span>
          </div>
          <div class="workflow-content-info ellipsis">点击按钮后立即执行</div>
        </template>
        <!-- 过滤条件 -->
        <div style="text-align: left" v-else-if="node.type == 'condition'">
          <div
            v-if="node.queryDataExist === true"
            style="font-size: 14px; color: #01ca86"
          >
            有数据
          </div>
          <div
            v-else-if="node.queryDataExist === false"
            style="font-size: 14px; color: #f44336"
          >
            无数据
          </div>
          <template v-else>
            <div
              v-if="node.condition && node.condition.advanceQuery.length"
              style="font-size: 14px"
            >
              已配置过滤条件
            </div>
            <el-button v-else type="text">配置过滤条件</el-button>
          </template>
        </div>
        <!-- 更新数据 -->
        <template
          v-else-if="
            [
              'action_form_data_create',
              'action_form_data_update',
              'action_form_data_delete',
              'action_form_data_save',
            ].includes(node.type)
          "
        >
          <div v-if="!isSetting" class="set-node">设置此节点</div>
          <template v-else>
            <div class="workflow-content-info ellipsis info-bg">
              <span class="gray_75">{{
                node.dataSource == "node_data" ? "节点子表" : "工作表"
              }}</span
              >"{{ getNodeTitle() }}"
            </div>
            <div class="workflow-content-info ellipsis">
              在{{ node.dataSource == "node_data" ? "节点子表" : "工作表" }}中{{
                nodeTypeObj.label
              }}
            </div>
          </template>
        </template>
        <!-- 单条多条 -->
        <template
          v-else-if="['query_data_one', 'query_data_list'].includes(node.type)"
        >
          <div v-if="!isSetting" class="set-node">设置此节点</div>
          <template v-else>
            <div class="workflow-content-info ellipsis info-bg">
              <template v-if="node.nodeField">
                <span class="gray_75">关联表</span>"{{ node.nodeField.title }}"
              </template>
              <template v-else>
                <span class="gray_75">工作表</span>"{{ node.collectionTitle }}"
              </template>
            </div>
            <div class="workflow-content-info ellipsis">
              在工作表中{{ nodeTypeObj.label }}
            </div>
          </template>
        </template>
        <!-- 更新流程参数 -->
        <template v-else-if="node.type == 'flow_param_update'">
          <div
            v-if="node.flowParamValues && node.flowParamValues.length"
            class="workflow-content-info ellipsis"
          >
            更新了{{ node.flowParamValues.length }}个节点
          </div>
          <div v-else class="set-node">设置此节点</div>
        </template>
        <!-- 通知 -->
        <template
          v-else-if="['notice_wechat', 'notice_sms'].includes(node.type)"
        >
          <div
            v-if="
              !node.userRange.userIds.length &&
              !node.userRange.nodeUserFields.length
            "
            class="set-node"
          >
            设置此节点
          </div>
          <div v-else style="font-size: 14px">已设置通知信息</div>
        </template>
        <!-- 插件 -->
        <template v-else-if="['developer_plugin'].includes(node.type)">
          <div v-if="!node.pluginInstallId" class="set-node">设置此节点</div>
          <div v-else style="font-size: 14px">已设置插件</div>
        </template>
        <!-- 审批流程 -->
        <template v-else-if="['action_form_data_commit'].includes(node.type)">
          <div v-if="!node.companyWorkflowId" class="set-node">设置此节点</div>
          <div v-else style="font-size: 14px">已设置流程</div>
        </template>
        <template v-else-if="node.type == 'subflow_collection'">
          <div v-if="node.baseNodeKey && node.robotId" style="font-size: 14px">
            执行子流程：{{ node.robotTitle }}
          </div>
          <div v-else class="set-node">设置此节点</div>
        </template>
        <!-- 界面推送节点：通知 -->
        <template v-else-if="node.type == 'render_notice'">
          <div v-if="node.noticeTitle" style="font-size: 14px">
            {{
              node.renderNoticeType == "tip"
                ? "已设置提示通知"
                : "已设置卡片通知"
            }}
          </div>
          <div v-else class="set-node">设置此节点</div>
        </template>
        <template v-else-if="node.type == 'render_url'">
          <div v-if="node.url" style="font-size: 14px">已设置打开链接</div>
          <div v-else class="set-node">设置此节点</div>
        </template>
        <template v-else-if="node.type == 'render_form'">
          <div
            v-if="node.formId && node.formDataNodeKey"
            style="font-size: 14px"
          >
            {{ node.formPageType == "add" ? "新增" : "查看" }}【{{
              node.formTitle
            }}】记录
          </div>
          <div v-else class="set-node">设置此节点</div>
        </template>
        <!-- JSON解析 -->
        <template v-else-if="node.type == 'json_parser'">
          <div v-if="node.jsonDataNodeKey" style="font-size: 14px">
            {{ node.outputFields?.length ?? 0 }}个输出参数
          </div>
          <div v-else class="set-node">设置此节点</div>
        </template>
        <template v-else-if="node.type == 'http_request'">
          <div v-if="node.url" style="font-size: 14px">发送自定义请求</div>
          <div v-else class="set-node">设置此节点</div>
        </template>
      </div>
    </div>

    <rpa-add-btn
      v-if="isShowAdd"
      :node="node"
      :nodes="nodes"
      :add="add"
      :addBrach="addBrach"
      :copyKeys="copyKeys"
      :isCopying.sync="tempIsCopying"
      :isPadding.sync="tempPadding"
      :getChildNodes="getToNodes"
      :getFromNodes="getFromNodes"
    ></rpa-add-btn>
    <div v-else style="height: 50px"></div>

    <rpa-drawer
      :visible.sync="drawer"
      :node.sync="field"
      :getFromNodes="getFromNodes"
      :getToNodes="getToNodes"
      :disabled="disabled"
      :isWorkflowData="nodeClass != ''"
      @checkNode="validate"
    ></rpa-drawer>
    <rpa-node-rename ref="rpaNodeRename" :node="node"></rpa-node-rename>
  </div>
</template>
<script>
import {
  getValueByKey,
  isEmpty,
  isEmptyByKey,
  isIDEmpty,
} from "@zgg-core-utils/utils";
import { nodeTypeList, timerCronLoopTypeEnums } from "./loopTypeEnums";
import rpaDrawer from "./rpa-drawer";
import rpaAddBtn from "./rpa-add-btn";
import moment from "moment";
import { buildJSONParserComponents, validateFields } from "./rpa-utils";
import { getCompanyWorkflowDetail, getRobotWorkflow } from "./api";
import rpaNodeRename from "./rpa-node-rename";
import { buildDataFilterByQueryChain } from "@/zgg-core/utils";

export default {
  components: { rpaDrawer, rpaAddBtn, rpaNodeRename },
  props: {
    /**当前节点 */
    node: Object,
    nodes: Array,
    /**修改节点信息 */
    changeNode: Function,
    /**是否正在复制节点 */
    isCopying: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /**添加节点 */
    add: Function,
    /**添加分支 */
    addBrach: Function,
    checCopyNode: Function,
    deleteNode: Function,
    isPadding: Boolean,
    copyKeys: Array,
    /** 获取当前节点的所有上级节点 */
    getFromNodes: Function,
    /** 获取当前节点的所有子节点 */
    getToNodes: Function,
    disabled: Boolean,
    /**
     * 已流转的节点列表
     */
    flowedNodes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  inject: {
    /** 获取触发器 */
    getTrigger: {
      default: () => {},
    },
    getRootParams: {
      default: () => [],
    },
  },
  computed: {
    isSetting() {
      if (["query_data_one", "query_data_list"].includes(this.node.type)) {
        return !(
          isIDEmpty(this.node.collection) && isEmpty(this.node.nodeField)
        );
      } else if (this.node.dataSource == "node_data") {
        return !isEmpty(this.node.nodeKey);
      } else {
        return !(
          isIDEmpty(this.node.formId) && isIDEmpty(this.node.collection)
        );
      }
    },
    nodeTypeObj() {
      let obj = nodeTypeList.find((item) => item.value == this.node.type);
      if (obj) {
        return obj;
      }
      return {};
    },
    isTrigger() {
      return this.nodeTypeObj.type == "trigger";
    },
    tempPadding: {
      get() {
        return this.isPadding;
      },
      set(val) {
        this.$emit("update:isPadding", val);
      },
    },
    copyIndex() {
      let index = this.copyKeys.findIndex((val) => val == this.node.key);
      return index;
    },
    isCanCopy() {
      return !["trigger", "branch"].includes(this.nodeTypeObj.type);
    },
    tempIsCopying: {
      get() {
        return this.isCopying;
      },
      set(val) {
        this.$emit("update:isCopying", val);
      },
    },
    field: {
      get() {
        return this._.cloneDeep(this.node);
      },
      set(val) {
        this.changeNode(this._.cloneDeep(val));
      },
    },

    bgClass() {
      return this.nodeTypeObj.bg;
    },
    typeIcon() {
      return this.nodeTypeObj.icon;
    },
    timerCronText() {
      return (
        this.formatByDate(this.node.executeTime) +
        "至" +
        this.formatByDate(this.node.executeEndTime)
      );
    },
    timerCronLoopType() {
      let obj = timerCronLoopTypeEnums.find(
        (item) => item.value == this.node.loopType,
      );
      return getValueByKey(obj, "label");
    },
    timeFieldText() {
      if (this.node.type != "trigger_timer_field") {
        return "";
      }
      if (!isEmpty(this.node.timeOffset)) {
        let list = Object.entries(this.node.timeOffset);
        let arr = list.find(([key, value]) => value != 0);
        let [key, value] = arr;
        let unit = "";
        if (value != 0) {
          let obj = { minute: "分钟", hour: "小时", day: "天" };
          unit = obj[key];
        }
        if (value < 0) {
          return "之前" + Math.abs(value) + unit;
        }
        if (value > 0) {
          return "之后" + Math.abs(value) + unit;
        }
        return "";
      } else {
        return "";
      }
    },
    paramsNum() {
      return [...this.node.header, ...this.node.body, ...this.node.params]
        .length;
    },
    isShowAdd() {
      if (this.disabled) {
        return false;
      }
      if (
        ["render_notice", "render_form", "render_url"].includes(this.node.type)
      ) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      drawer: false,
      isCopy: false,
      moreVisible: false,
      isError: false,
      changeMap: {
        create: "当新增数据时触发",
        update: "当更新数据时触发",
        delete: "当删除数据时触发",
      },
      nodeClass: "",
      rootFlowParams: [],
    };
  },
  async created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }

    let obj = this.flowedNodes.find((item) => item.key == this.node.key);
    if (obj) {
      if (obj.status == "success") {
        this.nodeClass = "flowed-success";
      } else {
        this.nodeClass = "flowed-fail";
      }
    }
  },

  methods: {
    getParentNodes() {
      let list = this.getFromNodes();
      let trigger = this.getTrigger();
      if (
        [
          "trigger_timer_field",
          "trigger_form_data_change",
          "trigger_webhook",
          "trigger_click",
          "trigger_subflow_collection",
        ].includes(trigger.type)
      ) {
        list.push(this._.cloneDeep(trigger));
      }
      // 添加本流程参数
      if (this.rootFlowParams?.length) {
        list.push({
          key: "root",
          title: "本流程参数",
          type: "root",
        });
      }
      return list;
    },
    async validate() {
      let nodeType = this.node.type;
      let nodeKey = this.node.key;
      let fromNodes = this.getParentNodes();

      /**
       * @description 节点错误信息
       * @typedef  nodeError
       * @property {string} message 错误信息描述
       * @property {string} type 节点类型
       * @property {string} key 节点key
       * @property {string} value 错误代码 setting,deleteNode,notSameForm,deleteField
       */

      /**
       * @enum {string} 节点错误信息
       * @property {string} errorValue.setting 完善节点配置
       * @property {string} errorValue.deleteField 存在已删除字段
       * @property {string} errorValue.deleteNode 节点被删除
       * @property {string} errorValue.notSameForm 非同源表单
       * @property {string} errorValue.errorType 类型错误
       * @property {string} errorValue.noneWorkflow 流程不存在
       *
       */
      const errorValue = {
        /**
         * 完善节点配置
         */
        setting: "setting",
        /**
         * 存在已删除字段
         */
        deleteField: "deleteField",
        /**
         * 节点被删除
         */
        deleteNode: "deleteNode",
        /**
         * 非同源表单
         */
        notSameForm: "notSameForm",
        errorType: "errorType",
        noneWorkflow: "noneWorkflow",
      };

      /**
       * 构建错误信息
       * @param {String} value
       * @returns {nodeError}
       */
      let buildError = (value) => {
        let obj = {
          setting: "完善节点配置",
          deleteField: "存在已删除字段",
          deleteNode: "指定对象已删除",
          notSameForm: "非同源表单",
          errorType: "类型错误",
          noneWorkflow: "流程不存在",
        };
        /**
         * @type {nodeError}
         */
        return {
          message: obj[value],
          type: nodeType,
          key: nodeKey,
          value: value,
        };
      };

      /**
       *
       * @param {string} tableName
       * @returns Array
       */
      let buildFieldList = async (tableName) => {
        this._tableName = tableName;

        let list = await this.$store.dispatch(
          "rpaNodeFields/fetchMetaComponents",
          { tableName },
        );
        return list;
      };

      /**
       * 构建节点的输出字段列表
       * @param {*} node
       */
      let buildNodeFieldList = async (node) => {
        let list = [];
        if (!node) {
          return list;
        }

        if (node.type == "developer_plugin") {
          // 插件
          node.responseParams.forEach((res) => {
            list.push({
              componentName: res.dataType,
              key: res.name,
              name: res.name,
              title: res.title,
            });
          });
          return list;
        }

        if (node.type == "trigger_webhook") {
          let arr = ["body", "header", "params"];
          arr.forEach((val) => {
            let tplList = node[val];
            if (!isEmpty(tplList)) {
              list = list.concat(this._.cloneDeep(tplList));
            }
          });

          list.forEach((item) => {
            item.key = item.name;
          });
          return list;
        }
        if (node.type == "subflow_collection") {
          let res = await getRobotWorkflow({
            robotId: node.robotId,
          });

          list = res?.data?.workflow?.rpaFlow?.config?.flowParams ?? [];
          list.forEach((item) => {
            item.key = item.name;
          });
          return list;
        }

        if (node.type == "root") {
          list = this._.cloneDeep(this.rootFlowParams);
          list.forEach((item) => {
            item.key = item.name;
          });
          return list;
        }

        if (node.type == "json_parser") {
          let list = buildJSONParserComponents(node);
          return list;
        }

        if (node.dataSource == "node_data") {
          let parentNode = fromNodes.find((item) => item.key == node.nodeKey);
          if (parentNode) {
            list = await buildNodeFieldList(parentNode);
          }
        } else if (node.dataSource == "node_data_field") {
          let _node = fromNodes.find(
            (item) => item.key == node.nodeField.nodeKey,
          );
          if (_node && _node.type == "json_parser") {
            let components = buildJSONParserComponents(_node);
            let jsonForm = components.find(
              (item) => item.key == node.nodeField.name,
            );
            if (jsonForm) {
              list = jsonForm.components;
            }
          } else if (node.nodeField.collection) {
            let list2 = await buildFieldList(node.nodeField.collection);

            let jsonForm = list2.find(
              (item) => item.key == node.nodeField.name,
            );
            if (jsonForm) {
              list = jsonForm.components;
            }
          }
        } else {
          list = await buildFieldList(node.formId || node.collection);
        }

        return list;
      };

      /**
       * 加工处理过滤条件，获取嵌套中的过滤条件
       * @param {Array} advanceQuery
       */
      let getAdvanceQueryList = (advanceQuery) => {
        let fieldList = [];
        advanceQuery.forEach((item) => {
          if (item.queryChain) {
            Object.values(item.queryChain).forEach((child) => {
              fieldList = fieldList.concat(getAdvanceQueryList(child));
            });
          } else {
            fieldList.push(item);
          }
        });
        return fieldList;
      };

      /**
       * 校验当前节点的过滤条件是否正确
       * @param {Array} advanceQuery 过滤条件
       * @param {*} node 过滤条件所在的节点-用于获取节点的输出字段
       */
      let validAdvanceQuery = async (advanceQuery, node) => {
        if (node) {
          // 条件字段校验
          /**
           * @type Array
           */
          let fieldList = await buildNodeFieldList(node);

          let names = getAdvanceQueryList(advanceQuery).map(
            (item) => item.key ?? item.name,
          );
          let fieldNames = fieldList.map((item) => item.key);

          let firstName = names.filter((val) => val.indexOf(".") == -1);

          if (firstName.filter((val) => !fieldNames.includes(val)).length) {
            return buildError(errorValue.deleteField);
          }

          let jsonNames = names.filter((val) => val.indexOf(".") > 0);
          if (
            jsonNames.length &&
            jsonNames.findIndex((val) => {
              let parentName = val.split(".")[0];
              let fieldName = val.split(".")[1];
              let jsonForm = fieldList.find((item) => item.key == parentName);
              if (!jsonForm) {
                return false;
              }
              return (
                jsonForm.components.findIndex(
                  (item) => item.name == fieldName,
                ) >= 0
              );
            }) == -1
          ) {
            return buildError(errorValue.deleteField);
          }
        }

        // 值字段检验

        let nodeFieldList = advanceQuery.filter(
          (item) => item.valueType == "nodeField",
        );

        /**
         * 错误信息
         * @type {nodeError}
         */
        let error;

        for (let i = 0; i < nodeFieldList.length; i++) {
          let nodeField = nodeFieldList[i].nodeField;
          let parentNode = fromNodes.find(
            (item) => item.key == nodeField.nodeKey,
          );
          if (!parentNode) {
            error = buildError(errorValue.deleteNode);
            break;
          }
          let list = await buildNodeFieldList(parentNode);

          if (list.findIndex((item) => item.key == nodeField.name) == -1) {
            error = buildError(errorValue.deleteField);

            break;
          }
        }

        return error;
      };

      if (["trigger_timer_once", "trigger_timer_cron"].includes(nodeType)) {
        // 触发器-定时单次校验、定时重复校验
        this.isError = isEmpty(this.node.executeTime);
        if (this.isError) {
          return buildError(errorValue.setting);
        }
      } else if (nodeType == "trigger_timer_field") {
        // 触发器-按日期字段触发
        this.isError = isIDEmpty(this.node.formId);
        if (this.isError) {
          return buildError(errorValue.setting);
        } else {
          if (this.node.condition?.advanceQuery?.length) {
            //  校验过滤条件

            if (validateFields(this.node.condition.advanceQuery, fromNodes)) {
              this.isError = true;
              return buildError(errorValue.deleteNode);
            }
            let error = await validAdvanceQuery(
              this.node.condition.advanceQuery,
              this.node,
            );

            if (error) {
              this.isError = true;
              return error;
            }
          }
        }
      } else if (nodeType == "trigger_subflow_collection") {
        // 触发器-子流程
        this.isError = isIDEmpty(this.node.collection);
        if (this.isError) {
          return buildError(errorValue.setting);
        }
      } else if (nodeType == "trigger_form_data_change") {
        // 触发器-数据变动触发
        this.isError = isIDEmpty(this.node.formId);
        if (this.isError) {
          return buildError(errorValue.setting);
        } else {
          if (this.node.triggerFields?.length) {
            // 校验触发字段是否可用
            /**
             * @type Array
             */
            let fieldList = await buildFieldList(this.node.formId);

            let fieldNames = fieldList.map((item) => item.name);
            this.isError =
              this.node.triggerFields.findIndex(
                (item) => !fieldNames.includes(item.fieldName),
              ) >= 0;
            if (this.isError) {
              return buildError(errorValue.deleteField);
            }
          }

          if (this.node.condition?.advanceQuery?.length) {
            // 校验过滤条件字段
            /**
             * @type Array
             */
            let list = await buildFieldList(this.node.formId);
            let queryList = getAdvanceQueryList(
              this.node.condition.advanceQuery,
            );
            let fieldNames = [];
            list.forEach((item) => {
              if (item.componentName == "json_form") {
                item.components.forEach((child) => {
                  fieldNames.push(`${item.name}.${child.name}`);
                });
              } else {
                fieldNames.push(item.name);
              }
            });

            this.isError =
              queryList.findIndex((item) => {
                if (!fieldNames.includes(item.key)) {
                  return true;
                } else if (item.valueType == "tableField") {
                  return !fieldNames.includes(item.value);
                }
                return false;
              }) >= 0;

            if (this.isError) {
              return buildError(errorValue.deleteField);
            }
          }
        }
      } else if (["query_data_one", "query_data_list"].includes(nodeType)) {
        // 单条、多条数据

        if (this.node.dataSource == "node_data_field") {
          // 节点字段-从子表字段获取
          if (isEmpty(this.node.nodeField)) {
            // 节点未配置
            this.isError = true;
            return buildError(errorValue.setting);
          }
          let node = fromNodes.find((item) => item.key == this.node.nodeKey);
          if (!node) {
            // 依赖的节点被删除了

            return buildError(errorValue.deleteNode);
          }

          let collection = node.formId || node.collection;
          if (
            node.type != "json_parser" &&
            collection != this.node.nodeField.collection
          ) {
            // 非同源
            this.isError = true;
            return buildError(errorValue.notSameForm);
          }
        } else {
          // 表单数据
          let isError = isIDEmpty(this.node.collection);
          if (isError) {
            this.isError = true;
            return buildError(errorValue.setting);
          }
        }
        let isDeleteNode = validateFields(
          this.node.dataFilter?.advanceQuery ?? [],
          fromNodes,
        );
        if (isDeleteNode) {
          this.isError = true;
          return buildError(errorValue.deleteNode);
        }

        if (this.node.dataFilter?.advanceQuery?.length) {
          let error = await validAdvanceQuery(
            this.node.dataFilter.advanceQuery,
            this.node,
          );

          if (error) {
            this.isError = true;
            return error;
          }
        }
      } else if (
        [
          "action_form_data_create",
          "action_form_data_update",
          "action_form_data_save",
          "action_form_data_delete",
        ].includes(nodeType)
      ) {
        let tableName = "";
        let parentNode;
        if (this.node.dataSource == "node_data") {
          if (isEmpty(this.node.nodeKey)) {
            this.isError = true;
            return buildError(errorValue.setting);
          }
          parentNode = fromNodes.find((item) => item.key == this.node.nodeKey);
          if (!parentNode) {
            // 依赖的节点被删除了
            this.isError = true;
            return buildError(errorValue.deleteNode);
          }
          tableName = parentNode.nodeField.collection;
        } else {
          if (isIDEmpty(this.node.formId)) {
            this.isError = true;
            return buildError(errorValue.setting);
          }
          tableName = this.node.formId;
        }

        if (isEmpty(tableName)) {
          this.isError = true;
          return buildError(errorValue.setting);
        }

        let fieldList = await buildNodeFieldList(this.node);

        let fieldNames = fieldList.map((item) => item.name);

        /**
         *检查【新增】或【更新】属性是否正常
         * @param {Array} fieldValues
         */
        let chkFieldList = async (fieldValues) => {
          if (
            fieldValues.findIndex(
              (item) => !fieldNames.includes(item.tableField.fieldName),
            ) >= 0
          ) {
            // 原表中存在被删除字段

            return buildError(errorValue.deleteField);
          }

          let list = fieldValues.filter(
            (item) => item.valueType == "nodeField",
          );
          let error;
          for (let i = 0; i < list.length; i++) {
            let nodeField = list[i].nodeField;
            let node2 = fromNodes.find((item) => item.key == nodeField.nodeKey);
            if (!node2) {
              error = buildError(errorValue.deleteNode);
              break;
            }
            /**
             * @type Array
             */
            let nodeFieldList = await buildNodeFieldList(node2);

            if (
              nodeFieldList.findIndex((item) => item.key == nodeField.name) ==
              -1
            ) {
              error = buildError(errorValue.deleteField);
              break;
            }
          }

          if (error) {
            return error;
          }

          let list2 = fieldValues.filter((item) => item.valueType == "node");
          for (let i = 0; i < list2.length; i++) {
            const nodeKey = list2[i].node?.key;
            if (isEmpty(nodeKey)) {
              error = buildError(errorValue.setting);
              break;
            }
            let node = fromNodes.find((item) => item.key == nodeKey);

            if (!node) {
              error = buildError(errorValue.deleteNode);
              break;
            }
            if (node.dataSource == "node_data") {
              node = fromNodes.find((item) => item.key == node.nodeKey);
            }

            if (!node) {
              error = buildError(errorValue.deleteNode);
              break;
            }

            let component = fieldList.find(
              (item) => item.name == list2[i].tableField.fieldName,
            );
            if (!component) {
              error = buildError(errorValue.deleteField);
              break;
            }

            if (node.dataSource == "node_data_field") {
              if (component.tableName) {
                if (node.nodeField.referenceCollection != component.tableName) {
                  error = buildError(errorValue.notSameForm);
                  break;
                }
              } else {
                if (node.nodeField.name != component.name) {
                  error = buildError(errorValue.notSameForm);
                  break;
                }
              }

              if (
                node.type == "query_data_list" &&
                isEmpty(this.node.baseNodeKey)
              ) {
                error = buildError(errorValue.errorType);
                break;
              }
            } else {
              let collection = node.formId || node.collection;
              if (component.tableName) {
                if (collection !== component.tableName) {
                  error = buildError(errorValue.errorType);
                  break;
                }
              }
            }
          }

          return error;
        };

        if (this.node.createFieldValues?.length) {
          // 新增字段列表校验
          let error = await chkFieldList(this.node.createFieldValues);

          if (!isEmpty(error)) {
            this.isError = true;
            return error;
          }
        }

        if (this.node.updateFieldValues?.length) {
          let error = await chkFieldList(this.node.updateFieldValues);

          if (!isEmpty(error)) {
            this.isError = true;
            return error;
          }
        }

        if (this.node.dataFilter?.advanceQuery?.length) {
          let error = await validAdvanceQuery(
            this.node.dataFilter.advanceQuery,
            this.node,
          );

          if (error) {
            this.isError = true;
            return error;
          }
        }
      } else if (nodeType == "subflow_collection") {
        if (isEmpty(this.node.baseNodeKey)) {
          this.isError = true;
          return buildError(errorValue.setting);
        } else if (
          validateFields(
            this.node.flowParamValues.map((item) => item.paramValue),
            fromNodes,
          )
        ) {
          this.isError = true;
          return buildError(errorValue.deleteNode);
        }
      } else if (nodeType == "developer_plugin") {
        // 插件

        if (isEmpty(this.node.functionId)) {
          this.isError = true;
          return buildError(errorValue.setting);
        }
      } else if (["render_notice", "render_url"].includes(nodeType)) {
        // 界面通知、打开链接
        if (nodeType == "render_url" && isEmpty(this.node.url)) {
          this.isError = true;
          return buildError(errorValue.setting);
        }
        if (nodeType == "render_notice" && isEmpty(this.node.noticeTitle)) {
          this.isError = true;
          return buildError(errorValue.setting);
        }
        if (this.node.nodeFields?.length) {
          let error;
          for (let i = 0; i < this.node.nodeFields.length; i++) {
            const nodeField = this.node.nodeFields[i];
            let node = fromNodes.find((item) => item.key == nodeField.nodeKey);
            if (!node) {
              error = buildError(errorValue.deleteNode);
              break;
            }
            let fieldList = await buildNodeFieldList(node);
            if (
              fieldList.findIndex((item) => item.name == nodeField.name) == -1
            ) {
              error = buildError(errorValue.deleteField);
              break;
            }
          }

          if (error) {
            this.isError = true;
            return error;
          }
        }
      } else if (nodeType == "render_form") {
        if (isEmpty(this.node.formDataNodeKey)) {
          this.isError = true;
          return buildError(errorValue.setting);
        }
        let node = fromNodes.find(
          (item) => item.key == this.node.formDataNodeKey,
        );
        if (!node) {
          this.isError = true;
          return buildError(errorValue.setting);
        }
        let dataSource = node.dataSource ?? "form";
        let collection = node.formId || node.collection;
        if (dataSource != "form") {
          this.isError = true;
          return buildError(errorValue.notSameForm);
        }
        if (collection != this.node.formId) {
          this.isError = true;
          return buildError(errorValue.notSameForm);
        }
      } else if (nodeType == "action_form_data_commit") {
        if (
          isEmpty(this.node.commitNodeKey) ||
          isEmpty(this.node.companyWorkflowId)
        ) {
          this.isError = true;
          return buildError(errorValue.setting);
        }

        let node = fromNodes.find(
          (item) => item.key == this.node.commitNodeKey,
        );
        if (!node) {
          this.isError = true;
          return buildError(errorValue.setting);
        }
        let dataSource = node.dataSource ?? "form";
        if (dataSource != "form") {
          this.isError = true;
          return buildError(errorValue.notSameForm);
        }
        let res = await getCompanyWorkflowDetail(this.node.companyWorkflowId);
        let companyWorkflow = res.data.companyWorkflow; //fromId
        if (!companyWorkflow) {
          this.isError = true;
          return buildError(errorValue.noneWorkflow);
        }
        let allowUseInFormIds = companyWorkflow.allowUseInFormIds ?? [];
        let formId = node.formId || node.collection;
        if (!allowUseInFormIds.includes(formId)) {
          this.isError = true;
          return buildError(errorValue.noneWorkflow);
        }
      } else if (nodeType == "condition") {
        if (!this.node.condition?.advanceQuery?.length) {
          this.isError = false;

          return;
        }
        let advanceQueryList = [];
        let list = [];
        let buildAdvanceList = (advanceQuery) => {
          advanceQuery.forEach((item) => {
            if (!isEmpty(item.queryChain)) {
              let dataFilter = buildDataFilterByQueryChain(item.queryChain);
              if (dataFilter?.advanceQuery) {
                buildAdvanceList(dataFilter.advanceQuery);
              }
            } else {
              advanceQueryList.push(item);
            }
          });
        };

        buildAdvanceList(this.node.condition.advanceQuery);
        advanceQueryList.forEach((item) => {
          list.push(this._.cloneDeep(item.paramValue1));
          list.push(this._.cloneDeep(item.paramValue2));
        });

        if (validateFields(list, fromNodes)) {
          this.isError = true;

          return buildError(errorValue.deleteNode);
        }
        let error;

        for (let i = 0; i < advanceQueryList.length; i++) {
          let item = advanceQueryList[i];
          let advanceQuery = [];
          let node = fromNodes.find(
            (opt) => opt.key == item.paramValue1.nodeField.nodeKey,
          );
          let obj = {
            componentName: item.paramValue1.nodeField.componentName,
            key: item.paramValue1.nodeField.name,
            ...item.paramValue2,
          };
          advanceQuery.push(obj);

          let isDeleteField = await validAdvanceQuery(advanceQuery, node);
          if (isDeleteField) {
            error = buildError(errorValue.deleteField);
            break;
          }
        }
        if (error) {
          this.isError = true;
          return error;
        }
      } else if (nodeType == "flow_param_update") {
        if (!this.node.flowParamValues?.length) {
          this.isError = true;

          return buildError(errorValue.setting);
        }
        if (
          validateFields(
            this.node.flowParamValues.map((item) => item.paramValue),
            fromNodes,
          )
        ) {
          this.isError = true;

          return buildError(errorValue.deleteNode);
        }
        let list = [];
        this.node.flowParamValues.forEach((item) => {
          list.push({
            ...item.param,
            key: item.name,
            ...item.paramValue,
          });
        });
        if (list.length) {
          let error = await validAdvanceQuery(list, { type: "root" });

          if (error) {
            this.isError = true;
            return error;
          }
        }
      }

      this.isError = false;
      return;
    },
    del() {
      this.moreVisible = false;
      this.deleteNode();
    },
    openEdit() {
      this.moreVisible = false;
      this.$refs.rpaNodeRename.open();
    },
    checkNode() {
      this.checCopyNode(this.node.key);
      let text = "选择要复制的节点，依次复制到目标位置";
      if (this.copyKeys.length) {
        text = `已选择${this.copyKeys.length}个复制节点`;
      }
      document.querySelector(".copy-rpa-node-tip").innerHTML = text;
    },

    formatByDate(value, format = "YYYY-MM-DD HH:mm:ss") {
      return moment(value).format(format);
    },
    showDrawer() {
      if (this.node.type == "condition") {
        if (
          this.node.queryDataExist === true ||
          this.node.queryDataExist === false ||
          (isEmptyByKey(this.node, "condition.advanceQuery") && this.disabled)
        ) {
          return;
        }
      }
      if (!this.isCopying) {
        this.drawer = true;
      }
    },
    getNodeTitle() {
      let title = "";
      if (this.node.dataSource == "node_data") {
        let list = this.getFromNodes();
        let node = list.find((item) => item.key == this.node.nodeKey);
        if (node) {
          title = node.title;
        } else {
          title = "【节点已删除】";
        }
      } else {
        title = this.node.formTitle;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow-box {
  padding: 20px 36px 0px;
  position: relative;
  &::before {
    background-color: #ccc;
    bottom: 0;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 40px;
    width: 1px;
  }
}
.workflow-item {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba($color: #000000, $alpha: 0.16);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 274px;
  .flowed-icon {
    position: absolute;
    top: 0px;
    right: -28px;
    font-size: 24px;
  }
  &.workflow_error {
    box-shadow: 0 0px 3px 3px red;
  }
  &.flowed-fail {
    box-shadow: 0 0px 1px 3px rgba(245, 108, 108, 1);
    .workflow-avatar {
      border-color: #f56c6c;
    }
  }
  &.flowed-success {
    box-shadow: 0 0px 1px 3px #67c23a;
    //     background: linear-gradient(0deg, #F0F9EB, #F0F9EB),
    // linear-gradient(0deg, #67C23A, #67C23A);
    .workflow-avatar {
      border-color: #67c23a;
    }
  }
  .workflow-avatar {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    justify-content: center;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -20px;
    z-index: 2;
    left: 50%;
    margin-left: -20px;
    border-radius: 40px;
    box-sizing: border-box;
    border: solid 2px #ffffff;
    font-size: 18px;
  }
  .workflow-name {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
    color: #fff;
    font-size: 15px;
    height: 60px;
    padding: 26px 10px 0;
    width: 100%;
    .workflow-name-text {
      border-radius: 3px;
      box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      max-width: 100%;
      padding: 0 6px;
      text-align: center;
    }
  }
  .workflow-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50px;
    padding: 8px;
    .workflow-content-info {
      box-sizing: border-box;
      font-size: 13px;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      width: 100%;
    }
  }
}

.bg_yellow {
  background-color: #ffa340;
}
.bg_blue {
  background-color: #2196f3;
}
.bg_gray {
  background-color: #4c7d9e;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}
.gray_75 {
  color: #757575;
}
.info-bg {
  background-color: #f5f5f5;
}
.workflow-start {
  align-items: center;
  background: #e6f4ff;
  border: 2px solid #2196f3;
  border-radius: 18px;
  box-sizing: border-box;
  color: #2196f3;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  justify-content: center;
  margin: 6px auto;
  width: 140px;
  &:hover {
    background-color: #2196f3;
    color: #ffffff;
  }
}
.is-disabled {
  .workflow-avatar,
  .workflow-name {
    opacity: 0.5;
  }
}

.copy-check {
  position: absolute;
  left: -10px;
  top: -10px;
  font-size: 20px;
  color: #757575;
  background: #ffffff;
  border-radius: 40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 4px rgba($color: #000000, $alpha: 0.16);
  .copy-number {
    background-color: #3ea4fc;
    color: #ffffff;
    display: flex;
    width: 26px;
    height: 26px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  i {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
  }
  &:hover {
    i {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }
}
.workflow-condition {
  .workflow-item {
    .workflow-name {
      color: #333;
      padding: 0px 10px;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #dcdcdc;
      .workflow-name-text {
        text-align: left;
      }
    }
  }
}

.rpa-node-popover {
  position: absolute;
  right: 4px;
  top: 4px;
}
.more-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 4px;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
  .more-text {
    font-size: 12px;
  }
}
.set-node {
  color: #2196f3;
  text-align: left;
  font-size: 13px;
}
</style>
