<template>
  <div class="filter-item">
    <!-- 组件源 -->
    <field-source
      :componentList="componentList"
      :field="filterField"
      :formId="formId"
    ></field-source>
    <!-- 操作符 -->
    <query-oper :field="filterField" @change="changeOper"></query-oper>
    <!-- 值类型 -->
    <FilterValue
      :field="filterField"
      :areaTree="areaTree"
      :areaProps="areaProps"
      :parentName="parentName"
      :isJsonForm="isJsonForm"
      :fieldList="sourceComponentList"
      :componentList="componentList"
    ></FilterValue>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { isEmpty } from "@zgg-core-utils/utils";
export default {
  props: {
    filterField: Object,

    sourceComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    parentName: String,
    isJsonForm: Boolean,
    name: String,

    formId: String,
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
  },
  data() {
    return {};
  },

  methods: {
    changeOper(val) {
      if (["nil", "nnil"].includes(val)) {
        this.filterField.valueType =
          this.filterField.componentName == "reference_data"
            ? "tableField"
            : "custom";
      } else if (this.filterField.key != "flowStatus") {
        this.filterField.valueType = "tableField";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-item {
  display: flex;
  .font13 {
    font-size: 13px;
  }
  .mr {
    margin-right: 8px;
  }
  .tip-box {
    width: 55px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
}
.rel-sel {
  ::v-deep {
    .el-input__inner {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}
</style>
