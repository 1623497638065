<template>
  <div style="display: flex; flex-direction: row; height: 100%">
    <div
      style="
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        transition-property: margin-left;
        transition-duration: 0.5s;
      "
      :style="[
        { background: treeViewVisible ? '#fff' : '#f4f5f7' },
        {
          width: menuWrapWidth + 'px',
          marginLeft: treeViewVisible
            ? wrapMarginLeft + 'px'
            : wrapMarginLeft + 0 + 'px',
        },
      ]"
    >
      <div
        v-if="treeViewVisible"
        class="drag-right"
        @mousedown="dragdown($event)"
        :class="onDraging ? 'onDraging' : ''"
      ></div>
      <i
        style="font-size: 20px"
        :style="treeViewVisible ? 'right: -20px;' : 'right: -26px;'"
        class="toggle-panel custom-icon"
        :class="
          treeViewVisible ? 'iconfont icon-zhedie' : 'iconfont icon-zhankai'
        "
        @click="toggleBoardVisible"
      ></i>

      <el-form
        v-show="show"
        :model="searchForm"
        class="demo-form-inline"
        size="small"
        style="width: 100%"
      >
        <el-form-item class="itemSearchForm">
          <el-input
            v-model="searchForm.title"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            style="width: 85%"
            @input="search"
          >
            <i
              v-if="searchForm.title"
              @click="clearTitle"
              slot="suffix"
              class="el-input__icon el-icon-circle-close"
              style="cursor: pointer"
            ></i>
          </el-input>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
            "
          >
            <span style="font-size: 12px; color: #6b778c">所有视图</span>
            <el-dropdown>
              <i
                class="el-icon-plus add"
                style="
                  font-size: 14px;
                  cursor: pointer;
                  font-weight: 600;
                  padding: 5px;
                  border-radius: 5px;
                "
              ></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="add(0)"
                  >创建新视图
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="addFolder"
                  >新增一级目录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-form-item>
      </el-form>
      <tree
        v-show="show"
        @nodeClick="nodeClick"
        @del="del"
        @edit="edit"
        @viewAddress="viewAddress"
        @copy="copy"
        ref="tree"
        type="20"
      >
        <template slot="more" slot-scope="{ data, node }">
          <div
            @click.stop
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
              <el-tooltip
                effect="dark"
                content="新建"
                placement="top"
                :open-delay="500"
              >
                <i
                  class="el-icon-plus custom-icon"
                  style="cursor: pointer; font-weight: 700"
                ></i>
              </el-tooltip>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="addFolder(data, node)">
                  添加子文件夹
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="add(data.id)">
                  添加视图
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
              <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="renameDir(data, node)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="delDir(data, node)">
                  删除
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="moveTo(data, node)">
                  移动到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="delDirForce(data, node)">
                  强制删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" v-if="data.dataType == 'resource'">
              <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="edit(data, node)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="viewAddress(data, node)">
                  配置地址
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="copy(data, node)">
                  复制
                </el-dropdown-item>
                <!--                <el-dropdown-item @click.native.stop="openCopyToDialog(data, node)">-->
                <!--                  复制到其他单位-->
                <!--                </el-dropdown-item>-->
                <el-dropdown-item @click.native.stop="moveTo(data, node)">
                  移动到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="del(data, node)">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </tree>
    </div>
    <div style="flex: 1; height: 100%; overflow: auto">
      <transition>
        <router-view
          v-if="!isRootRoute"
          :key="key"
          ref="formView"
          :mobileViewBtnVisible="true"
        />
        <el-empty
          v-else
          description="左侧创建视图"
          image="/static/empty-img/view-empty.png"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        ></el-empty>
      </transition>
    </div>

    <!-- 视图编辑弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="visible"
      @closed="close"
      width="560px"
    >
      <div style="padding: 20px">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item v-if="!form.id" label="视图类型" prop="componentName">
            <el-select
              v-model="form.componentName"
              placeholder="请选择视图类型"
              @change="changeComponentName"
            >
              <el-option label="列表" value="table"></el-option>
              <!--              <el-option label="指标" value="metric_table"></el-option>-->
              <!--              <el-option label="柱形图" value="chart_column"></el-option>-->
              <!--              <el-option label="条形图" value="chart_bar"></el-option>-->
              <!--              <el-option label="折线图" value="chart_line"></el-option>-->
              <!--              <el-option label="饼图" value="chart_pie"></el-option>-->
              <!--              <el-option label="透视图" value="pivot_table"></el-option>-->
              <!-- 聚合表应该抽出来和数据工厂一样顶部菜单、 甘特图在仪表盘配置、特殊视图只有一个马跳，所以这三个可以先注释 -->
              <!-- <el-option label="聚合表" value="aggregate_table"></el-option> -->
              <!-- <el-option label="甘特图" value="gantt"></el-option>  -->
              <!-- <el-option label="特殊视图" value="specialView"></el-option> -->
              <!-- <el-option label="树形表格" value="tree_table"></el-option> -->
              <!--              <el-option label="地图" value="chart_map"></el-option>-->
            </el-select>
          </el-form-item>
          <el-form-item label="数据源" prop="formTitle" v-if="!form.id">
            <el-input
              v-model="form.formTitle"
              placeholder="请选择数据源"
              @focus="dialogVisible = true"
            ></el-input>
          </el-form-item>
          <el-form-item label="视图名称" prop="title">
            <el-input
              v-model="form.title"
              @keyup.enter.native="saveForm"
              ref="renameInput"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button :loading="saveLoading" @click="saveForm" type="primary"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <data-source
      :visible.sync="dialogVisible"
      :menus="form.componentName == 'table' ? undefined : ['form']"
      @changeSource="changeSource"
    ></data-source>

    <el-dialog
      title="配置地址"
      :visible.sync="addrVisible"
      width="560px"
      append-to-body
    >
      <div style="padding: 20px">
        <template v-if="componentName == 'table'">
          <div class="row">
            <span>数据列表地址：</span>
            <div class="input">/online/view/{{ viewId }}/list</div>
            <!--          <el-link>查看<i class="el-icon-view" @click="$router.push(`/online/${{ formId }}/list`)"></i> </el-link>-->
          </div>
          <div class="row">
            <span>数据新增地址：</span>
            <div class="input">/online/view/{{ viewId }}/add</div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <span>视图地址：</span>
            <div class="input">/online/view/{{ viewId }}/chart</div>
          </div>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      title="移动"
      :visible.sync="folderSelectDialogVisible"
      width="520px"
      :before-close="handleBeforeCLose"
      class="folderSelectDialog"
    >
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 5px;
        "
      >
        <p>请选择目标文件夹：</p>
        <FolderSelect
          @change="handleFolderSelectChange"
          v-if="folderSelectDialogVisible"
          :type="this.type"
          :current-node="currentActionNode"
        ></FolderSelect>
      </div>
    </el-dialog>
    <copyToDialog :api="api" ref="copyToDialog"></copyToDialog>
  </div>
</template>
<script>
import { fetchFormList } from "@/views/lowCode/form/api";
import {
  createView,
  deleteView,
  fetchViewList,
  renameView,
  viewDuplicate,
} from "./api";
import Pagination from "@/components/Pagination";
import tree from "@/views/lowCode/form/tree";
import {
  dirDelete,
  dirMove,
  dirRegister,
  dirRename,
} from "@/views/lowCode/api";
import FolderSelect from "@/views/lowCode/FolderSelect";
import copyToDialog from "@/views/copyToDialog";
import _ from "lodash";
import { scaleWidth } from "@/mixin/scaleWidth";
import { getCookie, setCookie, removeCookie } from "@/utils/cookie-tools";
import dataSource from "@/components/data-source";

export default {
  components: { Pagination, tree, FolderSelect, copyToDialog, dataSource },
  mixins: [scaleWidth],
  data() {
    return {
      viewId: "",
      componentName: "",
      dialogVisible: false,
      saveLoading: false,
      title: "添加视图",
      visible: false,
      addrVisible: false,

      componentName2: "table",
      form: {
        componentName: "table",
      },
      rules: {
        title: [
          {
            required: true,
            message: "视图名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
        componentName: [
          {
            required: true,
            message: "请选择视图类型",
            trigger: ["change", "blur"],
          },
        ],
        formTitle: [
          {
            required: true,
            message: "请选择数据源",
            trigger: ["change", "blur"],
          },
        ],
      },

      searchForm: {
        title: "",
      },
      type: 20,
      //当前操作的中间变量的目录id
      currentActionDirId: 0,
      folderSelectDialogVisible: false,
      defaultExpandedKeys: [],
      currentActionNode: undefined,
      treeViewVisible: true,
      companyPage: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      show: true,
      api: "",
    };
  },
  created() {},
  mounted() {
    removeCookie("form-view-update");
    window.addEventListener("beforeunload", this.beforeunloadFun);
    this.$nextTick(() => {
      this.$bus.$on("form-view-save", (event, val, callback) => {
        if (event == "menu-link") {
          // menu菜单切换
          this.$refs.formView.saveForm(() => {
            this.$router.push(val);
          });
        } else if (event == "switch-company") {
          // 当前单位切换
          if (typeof callback === "function") {
            this.$refs.formView.saveForm(() => {
              callback(val);
            });
          }
        }
      });
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeunloadFun);
    this.$bus.$off("form-view-save");
  },
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.$refs.tree.reset();
        this.back();
        this.searchForm.title = "";
      },
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isRootRoute() {
      return this.$route.path == "/lowCode/view";
    },
    defaultExpandedKeysUniq: function () {
      return this._.uniq(this.defaultExpandedKeys);
    },
  },
  methods: {
    changeSource(data) {
      this.$set(this.form, "formTitle", data.tableTitle);
      this.$set(this.form, "formId", data.tableName);
      this.$set(this.form, "sourceType", data.sourceType);

      // 如果正在新建视图,则把标题回显到视图名称input
      if (this.visible) {
        this.$set(this.form, "title", this.form.formTitle);
      }
      this.dialogVisible = false;
    },
    beforeunloadFun(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "关闭提示";
      } else {
        return "关闭";
      }
    },
    clearTitle() {
      this.reset();
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      if (this.$route.query.id) {
        let { matched } = this.$router.history.current;
        if (matched.length >= 2) {
          let parentPath = matched[matched.length - 2].path;
          this.$router.push({ path: parentPath });
        } else {
          this.$router.back();
        }
      }
    },
    openCopyToDialog(row, node) {
      console.log(row, node);
      this.api = "viewDuplicate";
      this.$refs.copyToDialog.openCopyToDialog(
        row,
        this.companyPage,
        this.searchForm,
      );
    },

    reset() {
      this.searchForm.title = "";
      this.$refs.tree.reset();
    },

    changeComponentName(val) {
      if (this.componentName2) {
        if (this.componentName2 == "tree_table" || val == "tree_table") {
          this.$set(this.form, "formTitle", "");
          this.$set(this.form, "formId", "");
        }
        this.componentName2 = val;
      }
      if (val == "aggregate_table") {
        this.form.id = "";
        this.form.formTitle = "";
      }
    },
    delDirForce(data, node) {
      this.$confirm("强制删除目录，下面的全部目录和文件将被永久删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.id == 0) {
            //不允许误删除根目录
            return;
          }
          dirDelete({
            dirId: data.id,
            withSubs: true,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleBoardVisible() {
      this.treeViewVisible = !this.treeViewVisible;
      this.show = !this.show;
    },
    moveTo(data, node) {
      this.currentActionNode = node;
      this.folderSelectDialogVisible = true;
    },
    renameDir(data, node) {
      this.$prompt("请输入新文件名", "重命名文件夹", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.title,
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            dirId: data.id,
            title: value,
          };
          dirRename(param).then((resp) => {
            this.$set(node.data, "title", value);
            this.$message.success("保存成功");
          });
        })
        .catch(() => {});
    },
    handleBeforeCLose() {
      this.folderSelectDialogVisible = false;
    },
    /**
     * 处理移动到的相关逻辑
     * @param parentId
     */
    handleFolderSelectChange(parentId) {
      this.folderSelectDialogVisible = false;
      let { dataType, type, id, recordType, recordId } =
        this.currentActionNode.data;
      let params = {
        id,
        parentId,
      };
      dirMove(params).then(async (resp) => {
        this.$refs.tree.$refs.tree.remove(this.currentActionNode.data);
        this.currentActionNode.data.parentId = parentId;
        this.$refs.tree.insertToWorkGroupTree(this.currentActionNode.data);
        this.defaultExpandedKeys.push(parentId);
      });
    },
    delDir(data, node) {
      this.$confirm("删除文件夹?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.directoryList && data.directoryList.length != 0) {
            this.$message.warning("不能删除非空文件夹");
            return;
          }
          dirDelete({
            dirId: data.id,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFolder(data, node) {
      let parentId;
      if (data && data.id) {
        parentId = data.id;
      } else {
        parentId = 0;
      }
      this.$prompt("", "新建", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.addFolderByParent(parentId, value);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFolderByParent(parentId = 0, title = "默认文件夹名") {
      let params = {
        parentId,
        title,
        type: this.type,
      };
      dirRegister(params).then((resp) => {
        console.log(resp);
        console.log(this.$refs);
        // this.$refs.tree.insertToWorkGroupTree(resp.data.model);
        this.$refs.tree.update(parentId);
      });
    },
    nodeClick(data, node) {
      let toEdit = () => {
        let { dataType } = data;
        if (dataType == "resource") {
          this.editForm(data);
        }
      };
      let isUpdate = getCookie("form-view-update");
      if (isUpdate) {
        this.$confirm(
          "您有尚未保存的更改，是否需要保存这些更改？",
          "是否保存已有更改？",
          {
            confirmButtonText: "保存",
            cancelButtonText: "不保存",
            type: "warning",
          },
        )
          .then(() => {
            this.$refs.formView.saveForm(() => {
              toEdit();
              removeCookie("form-view-update");
            });
          })
          .catch(() => {
            removeCookie("form-view-update");
            toEdit();
          });
      } else {
        toEdit();
      }
    },
    search: _.debounce(function () {
      if (!this.searchForm.title) this.$refs.tree.reset();
      if (this.searchForm.title) {
        this.searchForm.title = this.searchForm.title.trim();
        this.$refs.tree.search(this.searchForm.title);
      }
    }, 500),
    copy(row) {
      this.$prompt("请输入新视图名", "复制视图", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.title + "-复制",
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            id: row.bizId,
            title: value,
          };
          viewDuplicate(param).then((resp) => {
            this.searchForm.title = "";
            let { dir, view } = resp.data;
            dir.view = view;
            dir.componentName = row.componentName;
            this.$refs.tree.insertToWorkGroupTree(dir);
          });
        })
        .catch(() => {});
    },
    del(row) {
      this.$confirm("确定要删除吗?", "提示")
        .then(() => {
          deleteView({ ids: JSON.stringify([row.bizId]) }).then((res) => {
            this.$message.success("删除成功");
            this.$refs.tree.$refs.tree.remove(row);
          });
        })
        .catch((err) => {});
    },
    edit(row, node) {
      this.$set(this, "form", {
        title: row.title,
        componentName: row.componentName,
        id: row.bizId,
      });
      this.title = "重命名视图";
      this.visible = true;
      this.currentActionNode = node;
      this.$nextTick(() => {
        this.$refs.renameInput.focus();
      }, 0);
    },
    editForm(row) {
      if (row.componentName == "table") {
        this.$router.push({
          path: "/lowCode/view/edit",
          query: { id: row.bizId },
        });
      } else if (row.componentName == "aggregate_table") {
        // 聚合表
        this.$router.push({
          path: "/lowCode/view/aggregate",
          query: { id: row.bizId },
        });
      } else if (row.componentName == "gantt") {
        this.$router.push({
          path: "/lowCode/view/gantt",
          query: { id: row.bizId },
        });
      } else if (row.componentName == "specialView") {
        this.$router.push({
          path: "/lowCode/view/specialView",
          query: { id: row.bizId },
        });
      } else if (row.componentName == "tree_table") {
        this.$router.push({
          path: "/lowCode/view/treeView",
          query: { id: row.bizId },
        });
      } else {
        this.$message.warning("不允许查看该视图");
        // this.$router.push({
        //   path: "/lowCode/view/chart",
        //   query: { id: row.bizId },
        // });
      }
    },
    viewAddress(row) {
      this.viewId = row.bizId;
      this.componentName = row.componentName;
      this.addrVisible = true;
    },

    cancel() {
      this.visible = false;
    },
    close() {
      this.$set(this, "form", { componentName: "table" });
    },
    add(dirId) {
      this.title = "添加视图";
      //   this.form = {};
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.currentActionDirId = dirId;
      this.visible = true;
    },

    saveForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let form;
        this.saveLoading = true;
        if (this.form.id) {
          form = {
            title: this.form.title,
            id: this.form.id,
          };
          renameView(form).then((resp) => {
            this.$set(this.currentActionNode.data, "title", this.form.title);
            this.saveLoading = false;
            this.visible = false;
            this.$message.success("保存成功");
          });
        } else {
          form = {
            title: this.form.title,
            componentName: this.form.componentName,
            dataSource: JSON.stringify({
              formId: this.form.formId,
              sourceType: this.form.sourceType,
            }),
            dirId: this.currentActionDirId,
          };

          createView(form).then((resp) => {
            this.saveLoading = false;
            this.visible = false;
            this.$message.success("保存成功");
            let dir = resp.data.dir;
            dir.view = resp.data.view;
            dir.componentName = resp.data.view.componentName;
            this.$refs.tree.insertToWorkGroupTree(dir);
            this.editForm(dir);
            this.$refs.tree.$refs.tree.setCurrentKey(dir.id);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.toggle-panel {
  position: absolute;
  z-index: 999;
  right: 2px;
  top: 10px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--zgg-border-light);
  border-radius: 50%;
  background-color: white;
  color: #6b77bc;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 10px;
}

.check {
  color: #409eff;
  font-size: 28px;
}

.el-icon-circle {
  width: 25px;
  height: 25px;
  border-radius: 32px;
  //   border: solid 1px #409eff;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input {
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 5px 20px;
}

::v-deep .el-scrollbar__wrap::-webkit-scrollbar {
  display: none !important;
}

.add:hover {
  background-color: rgb(225, 237, 248);
  color: #3490e6;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 10px;
}

::v-deep .el-dialog__footer {
  margin-top: 10px;
  border-top: 1px solid #d6d6d6;
}

.itemSearchForm {
  padding: 10px 0 0 10px;
  margin-bottom: 0;

  & ::v-deep .el-input__inner {
    border-radius: 10px;
    background-color: #f4f5f7;
    border: none;
  }
}

::v-deep .el-dropdown {
  right: 10px;
}

.demo-form-inline {
  ::v-deep {
    &.el-form-item {
      margin-bottom: 0;
    }
  }
}

.drag-right {
  width: 8px;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 999;

  &:hover {
    border-right: solid 2px rgba(24, 144, 255, 0.6);
  }

  &.onDraging {
    border-right: solid 4px rgba(24, 144, 255, 0.6);
  }
}
</style>
