<template>
  <div>
    <el-form ref="form" :model="node" :rules="rules" label-position="top">
      <el-form-item label="选择发起人" prop="commitUser">
        <rpa-commit-users
          ref="commit-users"
          :userRange="node.commitUser"
          :disabled="disabled"
        ></rpa-commit-users>
      </el-form-item>
      <el-form-item label="选择发起审批的数据对象" prop="commitNodeKey">
        <el-select
          v-model="node.commitNodeKey"
          filterable
          @change="changeNode"
          placeholder="请选择发起审批的数据对象"
          clearable
        >
          <el-option
            v-for="item in commitNodes"
            :key="item.key"
            :label="item.title"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择审批流程" prop="companyWorkflowId">
        <el-select
          v-model="node.companyWorkflowId"
          filterable
          @change="changeWorkflow"
          placeholder="请选择审批流程"
          clearable
        >
          <el-option
            v-for="item in companyWorkflowList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          @click.prevent.stop="preview"
          type="text"
          style="margin-left: 20px"
          >预览
        </el-button>
      </el-form-item>
    </el-form>
    <workflow-preview
      :workflowId="currentWorkflow.workflowId"
      :visible.sync="previewVisible"
      :companyFlow="currentWorkflow"
    >
    </workflow-preview>
  </div>
</template>
<script>
import rpaCommitUsers from "@/views/lowCode/rpa/rpa-commit-users.vue";
import { isEmpty } from "@zgg-core-utils/utils";
import { companyWorkflowList } from "./api";
import workflowPreview from "@/views/lowCode/rpa/rpa-workflow-preview/workflow-preview.vue";

export default {
  name: "rpa-form-data-commit",
  components: { rpaCommitUsers, workflowPreview },
  props: {
    node: Object,
    disabled: Boolean,
  },
  watch: {
    "node.commitUser": {
      deep: true,
      handler() {
        this.$refs.form.validateField("commitUser");
      },
    },
  },
  data() {
    return {
      commitNodes: [],
      companyWorkflowList: [],
      previewVisible: false,
      currentWorkflow: {},
      rules: {
        commitUser: [
          {
            required: true,
            validator(rule, value, callback) {
              if (isEmpty(value.userIds) && isEmpty(value.nodeUserFields)) {
                callback(new Error("请选择发起人"));
              } else {
                callback();
              }
            },
          },
        ],
        commitNodeKey: [
          {
            required: true,
            trigger: "change",
            message: "发起审批的数据对象不能为空",
          },
        ],
        companyWorkflowId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (isEmpty(value)) {
                callback(new Error("审批流程不能为空"));
              } else if (
                this.companyWorkflowList.findIndex(
                  (item) => item.id == value,
                ) == -1
              ) {
                callback(new Error("审批流程不存在"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    let nodes =
      this.$refs["commit-users"] && this.$refs["commit-users"].parentNodes;
    if (nodes) {
      nodes = this._.cloneDeep(nodes);
      nodes.forEach((item) => {
        if (item.dataSource == "node_data_field") {
          item.title = `${item.title}【${item.nodeField.title}】`;
        } else {
          item.title = `${item.title}【${
            item.formTitle || item.collectionTitle
          }】`;
        }
      });
      this.$set(this, "commitNodes", nodes);
    }
    if (this.node.commitNodeKey) {
      this.getWorkflowList(this.node.commitNodeKey);
    }
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        callback(valid);
      });
    },
    getWorkflowList(e) {
      let currentNode = this.commitNodes.find((item) => item.key == e);
      if (currentNode) {
        companyWorkflowList({
          formId: currentNode.collection || currentNode.formId,
          onlyUseInFormId: true,
          pageSize: 99,
          pageNumber: 1,
        }).then((res) => {
          this.$set(this, "companyWorkflowList", res.data.page.list);
          if (this.node.companyWorkflowId) {
            this.currentWorkflow =
              this.companyWorkflowList.find(
                (item) => item.id == this.node.companyWorkflowId,
              ) || {};
          }
        });
      }
    },
    preview() {
      if (isEmpty(this.currentWorkflow)) {
        this.$message.warning("请选择流程");
        return;
      }
      this.previewVisible = true;
    },
    changeNode(e) {
      this.getWorkflowList(e);
      this.node.companyWorkflowId = "";
    },
    changeWorkflow(e) {
      this.currentWorkflow =
        this.companyWorkflowList.find((item) => item.id == e) || {};
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: flex;
}
</style>
