<template>
  <codemirror
      :ref="id"
      v-model="content"
      :options="cmOptions"
      @blur="onBlur"
      @cursorActivity="onCursorActivity"
      @ready="onCmReady">
  </codemirror>
</template>

<script>
// import 'codemirror/mode/javascript/javascript.js'
// 主题css
// import 'codemirror/theme/base16-light.css'
// require active-line.js
import 'codemirror/addon/selection/active-line.js'

import 'codemirror/addon/edit/closebrackets.js'

import 'codemirror/addon/display/autorefresh'


export default {
  props:{
    textareaHeight:String,
    code:String,
    isWrap:Boolean,
    rely:Array,
    formComponent:Object,
    id:String
  },
  data() {
    return {
      content: "",
      cmOptions: {
        autoRefresh: true,
        tabSize: 4, // tabsize默认为4
        styleActiveLine: true,
        lineNumbers: false,
        line: true,
        // mode: 'javascript', // 选择代码语言
        extraKeys: {"Ctrl": "autocomplete"},   //自动提示配置
        lineWrapping: this.isWrap,    // 自动换行
        // theme: 'base16-light', // 主题根据需要自行配置
      }
    }
  },
  created() {
    this.content=this._.cloneDeep(this.code)

  },
  mounted() {
    this.initData(this.content)
  },
  methods: {
    onCursorActivity(cm) {
      const selectedText = cm.getSelection()
      this.initData(this.content)
      // 在此处处理选中文本
    },
    initData(str) {
      const rex= /(\$\{)[\s\S]{0,10}(\})/g
      let result
      if(str) {
        result = str.match(rex)
      }
      let arr
      if(result && result.length>0) {
        let componentMap = {}
        this.formComponent.components.map((item)=>{
          componentMap[item.name] = item.title
        })
        arr = result.map((item)=>{
          item = item.replaceAll("${","").replaceAll("}","")
          let title = componentMap[item]
          if(title) {
            return {
              title,
              name: item
            }
          } else if(this.rely && this.rely.includes(item)) {
            return {
              title: item,
              name: item,
              deleted:true
            }
          }
        })
      }
      if(arr && arr.length>0){
        arr.forEach((item)=>{
          let editor = this.$refs[this.id].codemirror;
          let regex = new RegExp(`(\\$\\{)${item.name}(\\})`, "g"); // 匹配搜索字符串的正则表达式
          for (let i = 0; i < editor.lineCount(); i++) {
            let line = editor.getLine(i) // 获取当前行的文本
            let match
            while (match = regex.exec(line)) { // 在当前行中查找匹配项
              let startPos = { line: i, ch: match.index }; // 匹配项的起始位置
              let endPos = { line: i, ch: match.index + match[0].length }; // 匹配项的结束位置
              let dom = document.createElement("span");
              if(item.deleted) {
                dom.className = "cm-field cm-field-delete";
              } else {
                dom.className = "cm-field";
              }
              dom.innerHTML = item.title;
              dom.id=item.name
              this.createDom(startPos, endPos, dom)
            }
          }
        })
      }

    },
    onBlur() {
      this.$emit("update:code",this.content)
    },
    createDom(startPos, endPos,dom) {
      this.$refs[this.id].codemirror.markText(startPos, endPos, {
        handleMouseEvents: true,
        replacedWith: dom,
      });
    },
    insert(row) {
        //获取codemirror光标位置
        const pos1 = this.$refs[this.id].codemirror.getCursor()
        const pos2 = {};
        pos2.line = pos1.line;
        pos2.ch = pos1.ch;
        let dom = document.createElement("span");
        dom.className = "cm-field";
        dom.innerHTML = row.title;
        dom.id=row.name
        const bookMark = "${"+row.name+"}"
        const endPos = { line: pos2.line, ch: pos2.ch + bookMark.length };
        this.$refs[this.id].codemirror.replaceRange(bookMark, pos2);
        this.createDom(pos2, endPos,dom)
    },
    onCmReady() {
      // 设置代码编辑框宽和高
      this.$refs[this.id].codemirror.setSize("-webkit-fill-available", this.textareaHeight)
    }
  }
}
</script>
<style>
.vue-codemirror {
  line-height: 20px;
  color: #000;
  border: 1px solid #dfe1e6;
}
.cm-field {
  display: inline-block;
  margin: 0 3px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 2px;
  color: rgba(0,0,0,.75);
  background: #f0f1f4;
}
.cm-field-delete {
  color: red!important;
  text-decoration: line-through;
}
.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-break: unset;
  font-family: Menlo,Monaco,Consolas,Courier New,monospace;
  font-size: 12px;
}
.cm-s-default .cm-comment {
  color: #000;
}
.CodeMirror-activeline-background {
  background-color: #fff;
}
</style>
