<template>
  <div>
    <slot></slot>
    <el-dialog
      custom-class="dialog"
      :title="title"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="814px"
      @opened="opened"
      @close="close"
    >
      <!-- 搜索框-->
      <div class="search-container">
        <el-input
          :title="searchValue"
          v-model="searchValue"
          size="small"
          placeholder="输入搜索或直接在下图选择定位中心"
          @input="searchHandle"
          @focus="promptVisible = true"
          @blur="promptVisible = false"
        ></el-input>

        <div
          class="prompt-container"
          v-show="promptVisible && searchValue.length > 0"
        >
          <ul>
            <li
              v-for="tip in tips"
              class="prompt-item"
              :title="tip.district + tip.address + tip.name"
              @mousedown="poiMousedownHandle(tip)"
            >
              {{ tip.name }}
              <span
                style="margin-left: 10px; font-size: 10px; color: #b6b6b6"
                >{{ tip.district }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <!--地图-->
      <div
        id="amapContainer"
        style="width: 100%; height: 300px; margin-bottom: 12px"
      ></div>
      <!--定位范围-->
      <div class="position-range">
        定位范围
        <span>以定位中心为圆心设置定位半径，建议范围500-1000米</span>
      </div>
      <el-input
        v-model="radius"
        size="small"
        style="width: 20%"
        type="number"
        placeholder="输入"
        @input="radiusInputHandle"
        :min="0"
      ></el-input>
      米
      <!--footer-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('update:visible', false)" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="confirm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/** 地图JS API文档: https://lbs.amap.com/api/javascript-api/reference/core **/
import AMapLoader from "@amap/amap-jsapi-loader";
import _ from "lodash";
export default {
  name: "PositionSelect",
  props: {
    editPoi: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    window._AMapSecurityConfig = {
      serviceHost: "https://dev.jarbar.cn/_AMapService/",
    };
  },
  data() {
    return {
      map: null,
      searchValue: "",
      tips: [],
      promptVisible: false,
      autoComplete: null,
      placeSearch: null,
      currMarker: null,
      currCircle: null,
      currPoi: null,
      radius: 500,
    };
  },
  computed: {
    title() {
      return this.isEdit ? "修改定位中心" : "新增定位中心";
    },
    isEdit() {
      return this.editPoi !== null;
    },
  },
  methods: {
    opened() {
      this.init();
    },
    init() {
      AMapLoader.load({
        key: "62ab5cf7f12134f8bd150abd3ccdefe9", //此处填入我们注册账号后获取的Key
        version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      })
        .then((AMap) => {
          this.initMap();
          this.initPlugin();
          this.map.on("click", this.clickHandler);
          if (this.isEdit) {
            this.radius = this.editPoi.radius;
            this.searchValue = this.editPoi.title;
            this.currPoi = {
              name: this.searchValue,
              location: new AMap.LngLat(
                this.editPoi.point.lng,
                this.editPoi.point.lat,
              ),
              radius: this.radius,
            };
            this.drawMarker(this.currPoi.location);
            this.drawCircle();
            this.map.setCenter(this.currPoi.location);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initMap() {
      this.map = new AMap.Map("amapContainer", {
        viewMode: "2D", //地图模式
        zoom: 15, //初始化地图缩放级别
      });
    },
    initPlugin() {
      this.map.plugin(["AMap.AutoComplete", "AMap.PlaceSearch"], () => {
        // 初始化搜索服务
        this.placeSearch = new AMap.PlaceSearch({
          // 兴趣点类别
          type: "汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施",
          pageSize: 1, // 单页显示结果条数
          pageIndex: 1, // 页码
          citylimit: false, //是否强制限制在设置的城市内搜索
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          extensions: "all",
        });
        // 实例化Autocomplete
        const autoOptions = {
          city: "全国",
        };
        this.autoComplete = new AMap.AutoComplete(autoOptions);
      });
    },
    clickHandler(e) {
      this.placeSearch.searchNearBy("", e.lnglat, 500, (status, result) => {
        if (status === "no_data") {
          return;
        } else {
          this.poiMousedownHandle(result.poiList.pois[0]);
        }
      });
    },
    searchHandle: _.debounce(function () {
      if (this.searchValue === "") {
        this.tips = [];
        return;
      }

      this.autoComplete.search(this.searchValue, (status, result) => {
        if (result.tips) {
          this.tips = result.tips.filter((item) => item.location);
        }
      });
    }, 500),
    radiusInputHandle() {
      if (this.currCircle && this.radius) {
        this.map.remove(this.currCircle);
        this.drawCircle();
      }
    },
    poiMousedownHandle(position) {
      const { pname, cityname, adname, address, name, district } = position;
      this.searchValue = district
        ? district + address + name
        : pname + cityname + adname + address + name;
      this.currPoi = position;
      if (this.currMarker) {
        this.map.remove(this.currMarker);
        this.map.remove(this.currCircle);
      }
      this.drawMarker(this.currPoi.location);
      this.drawCircle();
      this.map.setCenter(this.currPoi.location);
    },
    drawCircle() {
      this.currCircle = new AMap.Circle({
        zIndex: 999,
        center: this.currPoi.location, // 圆心位置
        radius: this.radius, // 圆半径
        fillColor: "rgba(113, 154, 243, 0.3)", // 圆形填充颜色
        strokeOpacity: 0,
        bubble: true,
      });
      this.map.add(this.currCircle);
    },
    drawMarker(location) {
      this.map.plugin(["AMap.Marker", "AMap.Icon"], () => {
        this.currMarker = new AMap.Marker({
          position: location,
          icon: new AMap.Icon({
            image:
              "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            size: new AMap.Size(22, 28), //图标所处区域大小
            imageSize: new AMap.Size(22, 28), //图标大小
          }),
          anchor: "bottom-center",
        });
        this.currMarker.setMap(this.map);
      });
    },
    verifyField() {
      if (!this.currPoi || !this.radius) {
        this.$message({
          showClose: true,
          message: "请设置定位中心和定位范围",
          type: "error",
        });
        return false;
      }
      return true;
    },
    confirm() {
      if (!this.verifyField()) {
        return;
      }

      if (this.isEdit) {
        this.$emit(
          "alert",
          { poi: this.currPoi, radius: this.radius },
          this.editPoi.index,
        );
      } else {
        this.$emit("confirm", { poi: this.currPoi, radius: this.radius });
      }
    },
    close() {
      this.searchValue = "";
      this.tips = [];
      this.radius = 500;
      this.currPoi = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .dialog {
  .el-dialog__body {
    padding: 0 24px;
  }

  .el-dialog__footer {
    padding: 12px 24px;
  }
}

.position-range {
  font-size: 14px;
  margin-bottom: 12px;
  span {
    font-size: 12px;
    color: #a5adba;
  }
}

.search-container {
  width: 50%;
  margin-bottom: 12px;
  position: relative;
  .prompt-container {
    position: absolute;
    left: 0;
    top: 32px;
    width: 100%;
    max-height: 138px;
    margin-top: 3px;
    overflow: auto;
    z-index: 1;
    background-color: white;
    .prompt-item {
      width: 100%;
      height: 34px;
      font-size: 14px;
      padding-left: 16px;
      line-height: 34px;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #eef0ff;
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
