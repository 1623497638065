<template>
  <div ref="chart" class="chart-column"></div>
</template>
<script>
import test from "@/utils/test";
import { getAggreation } from "../api";
import {
  dealWithXFieldValue,
  getChartColorConfig,
  chartColorList,
  axisNameConfig,
  getGrid,
  chartFontSize,
  chartLineHeight,
  chartItemWidth,
} from "./chartUtil";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";
import { getTextWidth } from "@/utils/tools";

export default {
  mixins: [chartResize],
  data() {
    return {
      list: [],
      view: null,
      option: null,
      observer: null,
      isGridChart: true,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  destroyed() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  methods: {
    getData(view, dataFilter) {
      this.$set(this, "view", view);
      if (!view.metrics.length) {
        return;
      }
      if (!view.xFields.length) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
      }
      // if (view.dataSource.queryObject && view.dataSource.queryObject.pageSize) {
      //   queryObject.pageSize = view.dataSource.queryObject.pageSize;
      // }

      queryObject.xFields = view.xFields;
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        // console.log("chart data");
        // console.log(res);
        this.list = res.data.list;
        this.buildChart(view);
      });
    },
    buildChart(view) {
      const result = getChartColorConfig(view, this.isH5);
      let textStyle = result.textStyle;
      let grid = getGrid(view);
      let list = this.list;

      // 基于准备好的dom，初始化echarts实例
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(this.$refs.chart);

      let option = {
        textStyle: textStyle,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: (params) => {
            let axisValue = params[0].axisValue;
            let str = "";
            params.forEach((item) => {
              let value = item.data.tooltip.valueFormatter();
              str += `<div class="chart-line-row">
                <div>${item.marker}<span>${item.seriesName}</span></div>
                <div style="margin-left:10px;">${value}</div>
                </div>`;
            });
            return `<div><div style="font-size:14px">${axisValue}</div>${str}</div>`;
          },
        },
        legend: {
          icon: "circle",
        },
        grid: grid,
        xAxis: {
          axisLabel: {},
          data: [],
        },
        yAxis: [],
      };
      dealWithXFieldValue(view.xFields, list, option, "xAxis");
      if (view.xAxis.rotation) {
        option.xAxis.axisLabel.rotate = view.xAxis.rotation;
      } else {
        option.xAxis.axisLabel.rotate = 0;
      }
      if (view.xAxis.step) {
        option.xAxis.axisLabel.interval = 0;
      } else {
        option.xAxis.axisLabel.interval = "auto";
      }
      let series = [];
      let lengedData = [];
      const buildSeries = (yAxis, index) => {
        let colorList;
        if (yAxis.colorScheme) {
          colorList = chartColorList.find(
            (color) => color.value == yAxis.colorScheme,
          ).bg;
        }
        let chartType;
        if (["chart_column", "stacking"].includes(yAxis.chartType)) {
          chartType = "bar";
          option.tooltip.axisPointer.type = "shadow";
        } else if (["chart_line", "chart_area"].includes(yAxis.chartType)) {
          chartType = "line";
        }
        let metrics = [];
        if (index == 1) {
          metrics = view.metrics.filter((col) => col.metricType == "right");
        } else {
          metrics = view.metrics.filter((col) => col.metricType == "left");
        }
        metrics.forEach((item, mIndex) => {
          if (lengedData.findIndex((v) => v === item.title) == -1) {
            lengedData.push(item.title);
          }
          let data = [];
          list.forEach((row, lIndex) => {
            let value = row[item.name];
            let show = view.chartLabel.enable;
            if (typeof value === "undefined") {
              show = false;
            }
            if (
              ["percentageStacking", "stacking"].includes(chartType) &&
              !value
            ) {
              show = false;
            }

            let formatter = () => {
              if (item.numberFormat) {
                return row[item.name + "Format"];
              }
              return value;
            };

            let param = {
              value,
              label: {
                show,
                position: "top",
                formatter,
              },
              tooltip: {
                valueFormatter: formatter,
              },
            };
            data.push(param);
          });

          let obj = {
            name: item.title,
            type: chartType,
            data,
            label: {
              offset: [0, 0],
              textStyle: textStyle,
            },
            yAxisIndex: index,
            barMaxWidth: 80,
          };
          if (yAxis.chartType == "chart_area") {
            obj.areaStyle = {
              opacity: 0.2,
            };
          }
          //柱形图、条形图显示堆积效果
          if (["stacking", "chart_area"].includes(yAxis.chartType)) {
            obj.stack = "stack" + index;
          }
          if (colorList) {
            obj.itemStyle = {
              color: colorList[mIndex] ? colorList[mIndex] : "",
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }
          if (view.chartMarker) {
            obj.smooth = view.chartMarker.curve;
            obj.symbol = view.chartMarker.symbol;
          }
          series.push(obj);
        });
      };
      view.yAxes.forEach((item, index) => {
        let yAxis = {
          id: index,
          splitLine: {
            lineStyle: {
              type: [2, 4],
              dashOffset: 2,
            },
          },
        };
        if (item.title) {
          yAxis.name = item.title;
          Object.assign(yAxis, axisNameConfig(item));
        } else {
          yAxis.name = "";
        }
        let min = item.min;
        let max = item.max;
        if (test.number(min) && min != "") {
          yAxis.min = min;
        } else {
          yAxis.min = function () {
            return null;
          };
        }
        if (test.number(max) && max != "") {
          yAxis.max = max;
        } else {
          yAxis.max = function () {
            return null;
          };
        }
        option.yAxis.push(yAxis);
        let obj = buildSeries(item, index);
        if (obj) {
          series.push(obj);
        }
      });
      if (view.chartLegend && view.chartLegend.enable) {
        let legend = {
          type: "scroll",
          data: lengedData,
          show: true,
          icon: "circle",
          textStyle: {
            fontSize: chartFontSize,
            lineHeight: chartLineHeight,
            ...textStyle,
          },
          itemWidth: chartItemWidth,
        };
        if (view.chartLegend.position == "top") {
          legend.x = "center";
          legend.y = "top";
        }
        if (view.chartLegend.position == "bottom") {
          legend.x = "center";
          legend.y = "bottom";
        }
        if (
          view.chartLegend.position === "left" ||
          view.chartLegend.position === "right"
        ) {
          legend.x = view.chartLegend.position;
          legend.y = "center";
          legend.orient = "vertical";
          let longestStr = "";
          series.forEach((item) => {
            if (item.name.length > longestStr.length) {
              longestStr = item.name;
            }
          });
          // 标签宽度 + 文字宽度 + 留白宽度
          this.longestStr = 40 + getTextWidth(longestStr, "normal", 3);
        }
        Object.assign(option.legend, legend);
      } else {
        option.legend.show = false;
      }
      option.series = series;
      // 使用刚指定的配置项和数据显示图表。
      console.log(option);
      this.myChart.setOption(option);
      this.setChartResize();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-column {
  height: 100%;
}
</style>
<style lang="scss">
.chart-line-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
</style>
