<template>
  <div
    class="button-group-setting-content__wrap"
    ref="buttonGroupSettingContentWrap"
  >
    <div
      v-for="(item, index) in config.buttonList"
      :key="item.key"
      class="button-group-images-container__item"
      :class="{
        up_down: config.groupStyle.layout == 'up_down',
        left_right: config.groupStyle.layout == 'left_right',
      }"
    >
      <div
        class="button-group-button-item__button_container__wrap"
        v-if="index < beginHideIndex"
        :style="{
          cursor: hasClickEvent(item) ? 'pointer' : 'default',
        }"
      >
        <ButtonGroupButtonItem
          :isH5="isH5"
          :item-config="item"
          :global-config="config"
        ></ButtonGroupButtonItem>
      </div>
      <div
        class="button-group-button-item__button_container__wrap"
        v-if="index == beginHideIndex"
        @click="openMoreButtonDialog"
      >
        <ButtonGroupButtonItem
          :item-config="moreButtonConfig"
          :global-config="config"
        ></ButtonGroupButtonItem>
      </div>
    </div>

    <el-dialog
      :visible.sync="moreButtonDialogVisible"
      title="更多"
      append-to-body
      class="custom-fullscreen"
    >
      <div
        class="button-group-setting-content__wrap"
        style="max-height: 500px; margin: 12px"
      >
        <div
          v-for="(item, index) in config.buttonList"
          :key="item.key"
          class="button-group-images-container__item"
          :class="{
            up_down: config.groupStyle.layout == 'up_down',
            left_right: config.groupStyle.layout == 'left_right',
          }"
          v-if="index >= beginHideIndex"
        >
          <div
            class="button-group-button-item__button_container__wrap"
            :style="{
              cursor: hasClickEvent(item) ? 'pointer' : 'default',
            }"
          >
            <ButtonGroupButtonItem
              :item-config="item"
              :global-config="config"
            ></ButtonGroupButtonItem>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty, throttle } from "lodash";
import ButtonGroupButtonItem from "./button-group-button-item";

export default {
  name: "dashboard-button-group",
  provide() {
    return {
      getFieldAuths: () => {
        return [];
      },
      getFormId: () => {
        return this.formId;
      },
    };
  },
  components: {
    ButtonGroupButtonItem,
  },
  data() {
    return {
      formParams: {},
      formDetailVisible: false,
      formId: 0,
      // 隐藏的按钮索引
      beginHideIndex: 0,

      moreButtonDialogVisible: false,
      moreButtonConfig: {
        title: "查看更多",
        buttonStyle: {
          className: "el-icon-more",
          color: "#DFE1E6",
        },
      },
      observer: null,
    };
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          buttonList: [],
        };
      },
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    companyId() {
      if (typeof this.getCompanyId === "function") {
        return this.getCompanyId();
      }
      return 0;
    },
  },
  methods: {
    openMoreButtonDialog() {
      if (this.isH5) {
        this.moreButtonDialogH5Visible = true;
      } else {
        this.moreButtonDialogVisible = true;
      }
    },
    /**
     * 计算是否显示更多
     */
    initialMaxButton() {
      let itemHeight = 0;
      let itemWidth = 0;
      if (this.config.groupStyle.layout == "left_right") {
        itemHeight = 60;
        itemWidth = 220;
      } else {
        itemHeight = 114;
        itemWidth = 118;
      }
      let container = this.$refs.buttonGroupSettingContentWrap;
      if (!container) {
        return;
      }

      console.dir(container);
      //需要减去padding
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      let col = Math.floor(containerWidth / itemWidth);
      //可显示的最大行数
      console.log(containerWidth, containerHeight);
      if (col === 0) {
        col = 1;
      }
      if (col > this.config.buttonList.length) {
        col = this.config.buttonList.length;
      }
      //计算根据当前列数能放下的最大行数
      let maxRow = Math.ceil(this.config.buttonList.length / col);
      console.log("计算根据当前列数能放下的最大行数", maxRow);

      //计算出容器所能放下的最大行数
      let maxButtonNeedRow = Math.floor(containerHeight / itemHeight);
      console.log("计算出容器所能放下的最大行数", maxButtonNeedRow);
      //根据当前列数计算按钮所需要的最大行数
      if (maxRow > maxButtonNeedRow) {
        maxRow = maxButtonNeedRow;
      }
      if (maxRow === 0) {
        maxRow = 1;
      }

      console.log(
        "containerHeight:",
        containerHeight,
        "containerWidth:",
        containerWidth,
        "maxRow:",
        maxRow,
        "col:",
        col,
      );
      this.beginHideIndex =
        maxRow * col < this.config.buttonList.length
          ? maxRow * col - 1
          : maxRow * col;
    },
    isEmpty,
    getData() {},
    resizeChart: throttle(function () {
      this.initialMaxButton();
    }, 100),
    hasClickEvent(button) {
      return button && button.buttonEvent && button.buttonEvent !== "none";
    },
    buttonClick(button) {
      console.log(button);
      if (this.hasClickEvent(button)) {
        // this.$emit("buttonClick", button);
        if (button.buttonEvent && button.buttonEvent == "hrefEvent") {
          this.hrefClick(button);
        }
        if (button.buttonEvent && button.buttonEvent == "recordEvent") {
          this.recordEventClick(button);
        }
        if (button.buttonEvent && button.buttonEvent == "formEvent") {
          this.formEventClick(button);
        }
      }
    },
    formEventClick(button) {
      this.formId = button.eventConfig.formId;
      this.defaultDataShareRange = "platform";
      this.hasAddRoleAuth = true;
      this.hasCreate = true;
      console.log(button.eventConfig);
      this.startAdd();
    },
    hrefClick(button) {
      console.log(button);

      const url = button.eventConfig.url;
      if (!url) {
        this.$message.success("未配置跳转链接");
        return;
      }
      if (button.eventConfig.targetEnum == "blank") {
        window.open(url, "_blank");
      } else {
        window.open(url, "_self");
      }
    },
    recordEventClick(button) {
      this.toDetail(button.eventConfig);
    },
    setChartResize() {
      this.observer = new ResizeObserver(
        _.debounce(this.resizeChart, 50),
      ).observe(this.$refs.buttonGroupSettingContentWrap);
    },
    toDetail(button) {
      const item = button;
      if (!item) return;
      if (!item.recordId || !item.recordType) {
        this.$message.success("请完善配置");
        return;
      }
      let params;
      if (item.recordType == "bulletin") {
        params = {
          path: "/workbench/" + item.recordId,
          query: {
            recordId: item.recordId,
          },
        };
      } else if (item.recordType == "plan") {
        // 整编
        params = {
          path: "/plan/" + item.recordId,
          query: {
            recordId: item.recordId,
            type: "edit",
          },
        };
      } else if (item.recordType == "process_setting") {
        // 看板
        params = {
          path: "/workbenchOA",
          query: {
            bulletinId: item.recordId,
            companyId: item.param.companyId,
            recordType: item.recordType,
            recordId: item.recordId,
          },
        };
      } else if (item.recordType == "app_view") {
        // 列表视图
        params = {
          path: `/view/list/${item.param.chatId}`,
          query: {
            recordId: item.recordId,
          },
        };
      } else if (item.recordType == "workflow_form") {
        params = {
          path: `/workflow/view/list/${item.param.chatId}`,
          query: {
            recordId: item.recordId,
          },
        };
      }

      if (item.targetEnum == "blank") {
        const { href } = this.$router.resolve(params);
        window.open(href, "_blank");
      } else {
        this.$router.push(params);
      }
    },
  },
  mounted() {
    this.setChartResize();
    setTimeout(() => {
      this.initialMaxButton();
    }, 100);
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped lang="scss">
$gap-size: 10px;
$header-height: 76px;
.button-group-setting-content__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
  align-content: flex-start;
  min-height: 114px;
  min-width: 120px;

  > {
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
  }

  .button-group-images-container {
    flex: 1;
    background-color: white;
    padding: 24px;
  }

  .drag-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: $gap-size;
  }

  .button-group-images-setting__wrap {
    width: 256px;
    margin-left: $gap-size;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
}

.button-group-images-container__item {
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease;

  &.up_down {
    width: 118px;
    height: 114px;
  }

  &.left_right {
    width: 220px;
    height: 60px;
  }
}

.button-group-button-item__button_container__wrap {
  width: 100%;
  height: 100%;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(56, 112, 234, 0.06);
  }
}
</style>
