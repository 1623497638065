import request from "@/utils/request";
import store from "@/store";
import qs from "qs";

// 获取登录用户所管理的单位列表接口
export function listMyCompany(data) {
  return request({
    url: "/operatorCompany/listMyCompany",
    method: "POST",
    data,
  });
}

// 切换单位接口
export function switchCompany(data) {
  return request({
    url: "/operatorCompany/switchCompany",
    method: "POST",
    data,
  });
}

// 复制单位
export function duplicateCompanyProject(data) {
  return request({
    url: "/res/duplicateCompanyProject",
    method: "POST",
    data,
  });
}

// 复制单位前的检查
export function checkError(data) {
  return request({
    url: "/res/checkCompanyProjectErrorCollection",
    method: "POST",
    data,
  });
}

// 重命名单位名称
export function renameCompany(data) {
  return request({
    url: "/company/modifyName",
    method: "POST",
    data,
  });
}

// 获取钉钉同步配置
export function getDingUserConfig(data) {
  return request({
    url: "/third/dingUserSyncConfig/list",
    method: "POST",
    data,
  });
}

// 钉钉手动同步
export function manualDingSyncConfig(data) {
  return request({
    url: "/third/ding/manualSync",
    method: "POST",
    data,
  });
}

// 保存钉钉同步配置
export function saveDingUserConfig(data) {
  return request({
    url: "/third/dingUserSyncConfig/save",
    method: "POST",
    data,
  });
}

// 钉钉同步日志
export function getDingUserSyncLog(data) {
  return request({
    url: "/third/dingUserSyncLog/list",
    method: "POST",
    data,
  });
}

// 用户运营中心权限
export function getPrivilegeForMe(data) {
  return request({
    url: "/privilege/role/me",
    method: "POST",
    data,
  });
}

// 获取表单右侧流程动态顺序
export function getLogSortRule(data) {
  return request({
    url: "/companyWorkflow/getLogSortRule",
    method: "POST",
    data,
  });
}

// 保存表单右侧流程动态顺序
export function saveLogSortRule(data) {
  return request({
    url: "/companyWorkflow/saveLogSortRule",
    method: "POST",
    data,
  });
}

// 单位设置信息
export function companySettingDetail(data) {
  return request({
    url: "/company/setting/detail",
    method: "POST",
    data,
  });
}
// 企业账号url修改
export function companySettingUrlModify(data) {
  return request({
    url: "/company/setting/modifyUrl",
    method: "POST",
    data,
  });
}
// 企业账号url详情
export function companySettingUrlDetail(data) {
  return request({
    url: "/company/setting/urlDetail",
    method: "POST",
    data,
  });
}
// 自定义登录页修改
export function companySettingLogoModify(data) {
  return request({
    url: "/company/setting/modifyLogo",
    method: "POST",
    data,
  });
}
// 自定义登录页详情
export function companySettingLogoDetail(data) {
  return request({
    url: "/company/setting/logoDetail",
    method: "POST",
    data,
  });
}
// 小程序私有化设置修改
export function companySettingAppletModify(data) {
  return request({
    url: "/company/setting/modifyApplet",
    method: "POST",
    data,
  });
}
// 小程序私有化设置详情
export function companySettingAppletDetail(data) {
  return request({
    url: "/company/setting/appletDetail",
    method: "POST",
    data,
  });
}
 
/**
 * 商户号列表
 * @param {*}
 * @param {string} 
 */
export function getPayMerchantList() {
  return request({
    url: "/third/payMerchant/list",
    method: "POST",
    data: {
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 生成平台支付商户号
 */
export function genMerchantNum() {
  return request({
    url: "/third/payMerchant/genMerchantNum",
    method: "POST",
    data: {
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 生成商户密钥
 * @param {string} id
 * 
 */
export function genSign(data) {
  return request({
    url: "/third/payMerchant/genSign",
    method: "POST",
    data,
  });
}

/**
 * 商户号启用禁用
 * @param {*}
 * @param {string} id
 */
export function payMerchantEnabled(data) {
  return request({
    url: "/third/payMerchant/enabled",
    method: "POST",
    data,
  });
}

/**
 * 商户号支付配置保存
 * @param {array} payConfigs
 */
export function payConfigSave(data) {
  return request({
    url: "/third/payConfig/save",
    method: "POST",
    data,
  });
}

/**
 * 商户号支付配置列表
 * @param {*}
 * @param {number} pageSize
 * @param {number} pageNumber
 * @param {string} merchantNum 【商户号】
 * @param {String} [payType] (可选)
 * @param {string} [payPlatform] (可选)
 */
export function getPayConfigList(data) {
  return request({
    url: "/third/payConfig/list",
    method: "POST",
    data,
  });
}

/**
 * 商户号支付方式
 * @param {*}
 * @param {string} payPlatform: wechat/alipay
 */
export function getPayConfigPayType(data) {
  return request({
    url: "/third/payConfig/payType",
    method: "POST",
    data,
  });
}

/**
 * 短信平台类型
 * @returns 
 */
export function getSmsPlatformType() {
  return request({
    url: "/sms/platform/type",
    method: "POST",
  });
}

/**
 * 短信平台列表
 * @returns 
 */
export function getSmsPlatformList() {
  return request({
    url: "/sms/platform/list",
    method: "POST",
    data: {
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 短信平台配置保存
 * @param {*} platform
 * @param {String} accessKeyId 【key】
 * @param {String} accessKeySecret 【密钥】
 * @param {String} companyId
 * @param {String} regionId 【区域】
 * @param {String} sdkAppId 【应用id腾讯必填】
 * @param {String} signName 【短信签名腾讯必填】
 * @param {String} smsType 【短信平台类型】
 * @returns 
 */
export function smsPlatformSave(data) {
  data.companyId = store.getters.currentCompanyId;
  return request({
    url: "/sms/platform/save",
    method: "POST",
    data: {
      platform: data
    }
  });
}

/**
 * 短信平台详情
 * @param {*} data
 * @param {String} id
 */
export function smsPlatformDetail(data) {
  return request({
    url: "/sms/platform/detail",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 短信平台开启禁用
 * @param {*} data
 * @param {String} id
 */
export function smsPlatformSwitchEnabled(data) {
  return request({
    url: "/sms/platform/switchEnabled",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 短信平台删除已启用的平台不可删除
 * @param {*} data
 * @param {Array} ids
 */
export function smsPlatformDelete(data) {
  return request({
    url: "/sms/platform/delete",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}