/**********上面是大的分类，需要更细致的分类******/
/**
 * https://hc.jiandaoyun.com/doc/15448
 * 字段类型匹配规则：
 *
 * 字段类型    具体类型    可以互相匹配的字段
 * 文本类
 *    单项    单行文本、单选按钮组、下拉框、流水号、
 *    多项    下拉框、复选框组
 *    多行文本    多行文本
 *    地址    地址、定位
 *    手机    手机
 *    流程（不支持）    流程状态
 * 文件类
 *    文件（不支持）    图片、附件、手写签名、微信头像（系统字段）
 * 成员类
 *    单项    成员单选 、提交人（系统字段）
 *    多项    成员多选
 * 部门类
 *    单项    部门单选
 *    多项    部门多选
 * 数值类
 *    数值    数字字段
 * 时间类
 *    时间    日期时间、提交时间（系统字段）、更新时间（系统字段）
 * @type {string[]}
 */

//单项
const singleTextEnums = ["input", "radio_group", "select", "sn_input"];
//多项
const multiTextEnums = ["select_checkbox", "checkbox_group"];
//多行文本(todo)
const textAreaEnums = ["text_area"];

//地址
const addressEnums = ["address_input", "position_input"];
//手机
const mobileEnums = ["mobile_input"];
//流程

//文件(目前应该是不支持，需要跟服务端沟通是否支持)
const fileEnums = [
  "image_uploader",
  "attachment_uploader",
  "sign_input",
  "sign_list_input"
];

//成员单项
const singleManEnums = ["user_select"];
//成员多项
const multiManEnums = ["user_list_select"];
//部门单项
const singleDepartmentEnums = ["department_select"];
//部门多项
const multiDepartmentEnums = ["department_list_select"];

//数值
const numberEnums = ["input_number"];
//时间
const dateEnums = ["date_picker"];

//全部类型的筛选器
export const allAllowFieldTypeEnums = [
  ...singleTextEnums,
  ...multiTextEnums,
  ...textAreaEnums,
  ...addressEnums,
  ...mobileEnums,
  ...singleManEnums,
  ...multiManEnums,
  ...singleDepartmentEnums,
  ...multiDepartmentEnums,
  ...numberEnums,
  ...dateEnums
];
//全部类型的筛选器的二维数组
export const allAllowFieldTypeEnumsMatrix = [
  singleTextEnums,
  multiTextEnums,
  textAreaEnums,
  addressEnums,
  mobileEnums,
  singleManEnums,
  multiManEnums,
  singleDepartmentEnums,
  multiDepartmentEnums,
  numberEnums,
  dateEnums
];
