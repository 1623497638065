<template>
  <el-dialog
    title="添加组件"
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    width="840px"
    @open="openDialog"
    @close="handleClose"
    :close-on-click-modal="false"
    :show-close="showClose"
  >
    <div style="padding: 20px; display: flex; flex-direction: column">
      <div
        @mousedown="mousedown(item, $event)"
        v-for="item in components"
        :key="item.value"
        class="card"
        :class="actived == item.value ? 'active' : ''"
      >
        <div class="left">
          <img :src="item.img" alt="" />
        </div>
        <div class="right">
          <p class="title">{{ item.label }}</p>
          <p class="desc">{{ item.desc }}</p>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <!-- <el-button type="primary" >确 定</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import img1 from "@/assets/add-component/filler.png";
import img2 from "@/assets/add-component/text.png";
import img3 from "@/assets/add-component/down.png";

export default {
  name: "DashboardFilterSetting",
  props: {
    dataComponentList: Array,
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      components: [
        {
          label: "筛选组件",
          value: "dashboard_filter",
          desc: "筛选仪表盘数据",
          img: img1,
        },
        {
          label: "自定义文本",
          value: "free_text",
          desc: "创建图文内容",
          img: img2,
        },
        {
          label: "倒计时组件",
          value: "count_down",
          desc: "设置目标日，统计剩余时间",
          img: img3,
        },
        // {
        //   label: '标题组件',
        //   value: 'dashboard_topic'
        // }
      ],
      actived: "",
      items: {},
      visibleFiller: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    // selectItem(item,){
    //   this.actived = item.value
    //   this.items = item
    // },
    mousedown(item, event) {
      this.actived = item.value;
      event.preventDefault();
      event.stopPropagation();
      if (event.buttons !== 1) {
        // 不是鼠标左键按下的则不执行一下操作
        return;
      }

      let client = event.currentTarget.getBoundingClientRect();
      let top = client.top - 100;
      let left =
        client.left - this.$el.parentElement.getBoundingClientRect().left;
      if (left < 0) {
        left = 0;
      }
      if (top < 0) {
        top = 0;
      }
      top += this.$parent.$refs.flexBody.scrollTop;

      this.$emit("addElement", {
        left,
        top,
        view: {
          ...item,
        },
      });
      this.moveObj = {
        left,
        top,
        x: event.clientX,
        y: event.clientY,
      };

      document.addEventListener("mousemove", this.mousemove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.mouseup, { passive: false });
      this.visible2 = false;
    },
    mousemove(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.moveObj) {
        return;
      }

      let x2 = event.clientX - this.moveObj.x;
      let y2 = event.clientY - this.moveObj.y;
      if (this.interval) {
        if (this.interval.direction == "up") {
          if (y2 > 0) {
            clearInterval(this.interval.val);
            this.interval = null;
          }
        } else if (this.interval.direction == "down") {
          if (y2 < 0) {
            clearInterval(this.interval.val);
            this.interval = null;
          }
        }
        return;
      }

      let left = this.moveObj.left + x2;
      let top = this.moveObj.top + y2;
      if (left < 0) {
        left = 0;
      }
      if (top < 0) {
        top = 0;
      }

      let clientWidth = this.$el.parentElement.parentElement.clientWidth;
      let clientHeight = this.$el.parentElement.parentElement.clientHeight - 50;
      if (left + this.minWidth > clientWidth - 10) {
        left = clientWidth - this.minWidth - 10;
      }

      this.$emit("moveElement", { left, top });

      let scrollTop = this.$parent.$refs.flexBody.scrollTop;
      let clientHeight2 = this.$parent.$refs.flexBody.clientHeight;
      if (top < scrollTop) {
        this.moveObj.y = event.clientY;
        this.moveObj.top = top;
        this.interval = {
          direction: "up",
          val: setInterval(() => {
            top = top - 1;
            scrollTop = scrollTop - 1;
            if (top <= 0) {
              if (this.interval) {
                clearInterval(this.interval.val);
                this.interval = null;
              }
              top = 0;
            }
            if (scrollTop <= 0) {
              if (this.interval) {
                clearInterval(this.interval.val);
                this.interval = null;
              }
              scrollTop = 0;
            }

            this.moveObj.top = top;
            this.$parent.$refs.flexBody.scrollTop = scrollTop;
            this.$parent.moveObj.top = top;
          }, 1),
        };
      } else if (top + this.minHeight >= scrollTop + clientHeight2) {
        this.moveObj.y = event.clientY;
        this.moveObj.top = top;
        if (y2 > 0) {
          this.interval = {
            direction: "down",
            val: setInterval(() => {
              top = top + 1;
              scrollTop = scrollTop + 1;

              this.moveObj.top = top;
              this.$parent.$refs.flexBody.scrollTop = scrollTop;
              this.$parent.moveObj.top = top;
            }, 1),
          };
        } else if (y2 < 0) {
          this.interval = {
            direction: "up",
            val: setInterval(() => {
              top = top - 1;
              scrollTop = scrollTop - 1;

              let elHeight = top + this.minHeight;
              let $elements =
                this.$parent.$refs.flexBody.querySelectorAll(".dash-item");
              let list = this.$parent.dashboard.components.map(
                (item, index) => {
                  return (
                    $elements[index].offsetTop + $elements[index].clientHeight
                  );
                },
              );

              let max = Math.max(...list);
              if (elHeight < max) {
                if (this.interval) {
                  clearInterval(this.interval.val);
                  this.interval = null;
                  return;
                }
              }

              if (top <= 0) {
                if (this.interval) {
                  clearInterval(this.interval.val);
                  this.interval = null;
                }
                top = 0;
              }
              if (scrollTop <= 0) {
                if (this.interval) {
                  clearInterval(this.interval.val);
                  this.interval = null;
                }
                scrollTop = 0;
              }

              this.moveObj.top = top;
              this.$parent.$refs.flexBody.scrollTop = scrollTop;
              this.$parent.moveObj.top = top;
            }, 1),
          };
        }
      }
    },
    mouseup(event) {
      console.log(event, "8888888", event.target.outerText);
      event.preventDefault();
      event.stopPropagation();
      if (this.interval) {
        clearInterval(this.interval.val);
        this.interval = null;
      }
      if (!this.moveObj) {
        return;
      }
      // const outerText = event.target.outerText
      // if(outerText=='筛选组件'){
      //   this.visible = true
      // }
      // if(outerText=='自定义文本'){
      //   this.visibleZi = true
      // }
      // if(outerText=='倒计时'){
      //   this.visibleDao = true
      // }
      // if(outerText=='标题组件'){
      //   this.visibleBiao = true
      // }
      this.visibleFiller = true;
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
    },
    saveForm() {
      console.log(this.dashboardFilterModel);
      this.validate()
        .then((resp) => {
          console.log("成功");
          this.$emit("save", this.dashboardFilterModel);
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      Object.assign(this.$data, this.$options.data());
    },
    openDialog() {},
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 778px;
  height: 120px;
  border: 1px solid #ebecf0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  .left {
    img {
      width: 150px;
      height: 100px;
    }
  }
  .right {
    color: #172b4d;
    padding-left: 20px;
    .title {
      font-size: 16px;
    }
    .desc {
      color: #6b778c;
    }
  }
}
.active {
  border: 1px solid #3870ea;
}
</style>
<style lang="scss">
::v-deep .el-dialog__header {
  margin-bottom: 0px !important;
}
</style>
