<template>
  <!-- 子表单操作权限 -->
  <div class="attr-row" v-if="component.componentName === 'json_form'">
    <div class="attr-label">字段权限</div>
    <div>
      <el-checkbox v-model="hasCreate"
        ><span style="font-size: 12px">可新增记录</span></el-checkbox
      >
    </div>
    <div>
      <el-checkbox v-model="hasEdit"
        ><span style="font-size: 12px">可编辑已有记录</span></el-checkbox
      >
    </div>
    <div>
      <el-checkbox v-model="hasDelete"
        ><span style="font-size: 12px">可删除已有记录</span></el-checkbox
      >
    </div>
  </div>
  <!-- 非子表单操作权限 -->
  <div class="attr-row" v-else>
    <div class="attr-label">关联表操作</div>
    <div>
      <el-checkbox v-model="hasCreate" :disabled="isDataflow"
        ><span style="font-size: 12px">允许新增关联表数据</span></el-checkbox
      >
    </div>
    <div v-if="component.componentName != 'select' && component.componentName != 'select_checkbox'">
      <el-checkbox v-model="hasEdit" :disabled="isDataflow"
        ><span style="font-size: 12px">允许编辑关联表数据</span></el-checkbox
      >
    </div>
    <div v-if="component.componentName != 'select' && component.componentName != 'select_checkbox'">
      <el-checkbox v-model="hasDetail"
        ><span style="font-size: 12px">允许查看关联表数据</span></el-checkbox
      >
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import { componentProps } from "./util";
export default {
  name: "attr-table-action",
  props: componentProps,
  computed: {
    hasEdit: {
      get() {
        return (
          this.component.allowActionEvents &&
          this.component.allowActionEvents.findIndex(
            (item) => item == "edit"
          ) >= 0
        );
      },
      set(val) {
        let temp = this._.cloneDeep(
          this.component.allowActionEvents
            ? this.component.allowActionEvents
            : []
        );
        if (val) {
          temp.push("edit");
          this.$set(this.component, "allowActionEvents", temp);
        } else {
          if (this.component.allowActionEvents) {
            let index = this.component.allowActionEvents.findIndex(
              (item) => item == "edit"
            );
            if (index >= 0) {
              temp.splice(index, 1);
              this.$set(this.component, "allowActionEvents", temp);
            }
          }
        }
      },
    },
    hasCreate: {
      get() {
        return (
          this.component.allowActionEvents &&
          this.component.allowActionEvents.findIndex((item) => item == "add") >=
            0
        );
      },
      set(val) {
        let temp = this._.cloneDeep(
          this.component.allowActionEvents
            ? this.component.allowActionEvents
            : []
        );
        this.$set(this.component, "initFieldValues", []);
        if (val) {
          temp.push("add");
          this.$set(this.component, "allowActionEvents", temp);
        } else {
          if (this.component.allowActionEvents) {
            let index = this.component.allowActionEvents.findIndex(
              (item) => item == "add"
            );
            if (index >= 0) {
              temp.splice(index, 1);
              this.$set(this.component, "allowActionEvents", temp);
            }
          }
        }
      },
    },
    hasDelete: {
      get() {
        return (
          this.component.allowActionEvents &&
          this.component.allowActionEvents.findIndex(
            (item) => item == "delete"
          ) >= 0
        );
      },
      set(val) {
        let temp = this._.cloneDeep(
          this.component.allowActionEvents
            ? this.component.allowActionEvents
            : []
        );
        if (val) {
          temp.push("delete");
          this.$set(this.component, "allowActionEvents", temp);
        } else {
          if (this.component.allowActionEvents) {
            let index = this.component.allowActionEvents.findIndex(
              (item) => item == "delete"
            );
            if (index >= 0) {
              temp.splice(index, 1);
              this.$set(this.component, "allowActionEvents", temp);
            }
          }
        }
      },
    },
    hasDetail: {
      get() {
        return (
          this.component.allowActionEvents &&
          this.component.allowActionEvents.findIndex(
            (item) => item == "detail"
          ) >= 0
        );
      },
      set(val) {
        let temp = this._.cloneDeep(
          this.component.allowActionEvents
            ? this.component.allowActionEvents
            : []
        );
        if (val) {
          temp.push("detail");
          this.$set(this.component, "allowActionEvents", temp);
        } else {
          if (this.component.allowActionEvents) {
            let index = this.component.allowActionEvents.findIndex(
              (item) => item == "detail"
            );
            if (index >= 0) {
              temp.splice(index, 1);
              this.$set(this.component, "allowActionEvents", temp);
            }
          }
        }
      },
    },
    isDataflow() {
      if (!isEmpty(this.component.tableName)) {
        return (
          this.component.tableName.indexOf("dataflow_") == 0 ||
          this.component.tableName.indexOf("at_") == 0 ||
          this.dataMode == "company_link_form"
        );
      }
      return false;
    },
  },
};
</script>
