<template>
    <el-select
      v-if="component"
      v-model="model"
      placeholder="请选择"
      :style="width ? `width:${width}px;` : 'width:100%;'"
      v-loadmore="loadMore"
      :filterable="true"
      :remote="component.optionSource != 'custom'"
      :loading="loading"
      :remote-method="searchList"
      popper-class="metaSelect"
      :size="size"
      :allow-create="!component.optionSource"
      :default-first-option="!component.optionSource"
      @change="changeSelect"
      :multiple="multi"
      collapse-tags
    >
      <el-option
        v-for="opt in optionList"
        :key="optValue(opt.value)"
        :label="opt.label"
        :value="opt.value"
      >
        <template v-if="isShowSlot">
          <div class="opt-item" :style="width ? `width:${width}px;` : ''">
            <div class="opt-title" v-text="opt.label"></div>
            <div class="sub-title" v-text="getSubTitle(opt)"></div>
          </div>
        </template>
      </el-option>
    </el-select>
    <el-input
      v-else
      :value="inputValue"
      style="width: 100%"
      :size="size"
      readonly
      placeholder=""
    ></el-input>
  </template>
  <script>
  import {
    listDepartmentInfo,
    listUserInfo,
  } from "@/views/lowCode/api";
  import {
    fetchPage,
  } from "@/views/lowCode/form/api";
  import { _eq } from "@zgg-core-utils/relyUtil";
  import moment from "moment";
  import { getMomentFormat, isEmpty } from "@zgg-core-utils/utils";
  
  export default {
    props: {
      value: [String, Number, Array, Object],
      component: Object,
      params: {
        type: Object,
        default() {
          return {};
        },
      },
      size: String,
      oper: {
        type: String,
        default() {
          return "";
        },
      },
      valueSource: {
        type: String,
        default() {
          return "table"; // self 自定义
        },
      },
      valueType: {
        type: String,
        default() {
          return "value";
        },
      },
    },
    computed: {
      multi() {
        return (
          ["in", "nin"].includes(this.oper) ||
          ["select_checkbox", "checkbox_group"].includes(
            this.component.componentName
          )
        );
      },
      model: {
        get() {
          if (this._.isEmpty(this.value)) {
            return this.value;
          }
  
          return this.label;
        },
        set(val) {
          let value = val;
  
          let component;
          if (this.valueSource == "table" && this.component.metaFieldOption) {
            component = this.component.metaFieldOption.field;
          } else {
            component = this.component;
          }
  
          if (component && component.componentName == "input_number") {
            if (typeof val == "string") {
              //先把非数字的都替换掉，除了数字和.
              value = val.replace(/[^\d.]/g, "");
              //必须保证第一个为数字而不是.
              value = value.replace(/^\./g, "");
              //保证只有出现一个.而没有多个.
              value = value.replace(/\.{2,}/g, ".");
              //保证.只出现一次，而不能出现两次以上
              value = value
                .replace(".", "$#$")
                .replace(/\./g, "")
                .replace("$#$", ".");
  
              if (!this._.isEmpty(value)) {
                value = parseFloat(value);
              }
            }
          }
          let row = this.page.list.find((item) => item.value == value);
          if (this.multi) {
            this.label = value;
            this.$emit("input", value);
          } else if (this.valueType == "value" && row) {
            this.label = row.label;
  
            this.$emit("input", row[this.valueFieldName]);
          } else {
            this.label = value;
            this.$emit("input", value);
          }
        },
      },
      inputValue() {
        return this.dealWithFormValue(this.value, "value", this.component);
      },
      isShowSlot() {
        if (this.valueSource == "self") {
          return false;
        }
        let item = this.component;
        return (
          item.optionSource == "metaField" &&
          item.metaFieldOption.tableName &&
          item.metaFieldOption.tableColumns &&
          item.metaFieldOption.tableColumns.length
        );
      },
      optionList() {
        if (this.component.optionSource == "custom") {
          return this.component.customOptions;
        }
        return this.page.list;
      },
      labelFieldName() {
        let labelFieldName;
        if (!this.component) {
          return "";
        }
        if (this.valueSource == "table" && this.component.metaFieldOption) {
          labelFieldName = this.component.metaFieldOption.labelFieldName;
        } else {
          labelFieldName = this.component.name;
        }
  
        if (labelFieldName.indexOf(".") >= 0) {
          labelFieldName = labelFieldName.split(".")[1];
        }
  
        if (this.valueFieldName == "createrId") {
          labelFieldName = "createrName";
        }
        return labelFieldName;
      },
      valueFieldName() {
        let valueFieldName;
        if (!this.component) {
          return "";
        }
        if (this.valueSource == "table" && this.component.metaFieldOption) {
          valueFieldName = this.component.metaFieldOption.valueFieldName;
        } else {
          valueFieldName = this.component.name;
        }
  
        if (valueFieldName.indexOf(".") >= 0) {
          valueFieldName = valueFieldName.split(".")[1];
        }
        return valueFieldName;
      },
    },
    created() {
      console.log(this.component)
      this.initValue();
      this.fetchListFormData();
    },
    mounted() {
      this.$nextTick(() => {
        this.width = this.$el.clientWidth;
      });
    },
    data() {
      return {
        label: "",
        width: 0,
        loading: false,
        query: "",
        page: {
          pageSize: 20,
          pageNumber: 1,
          totalRow: 0,
          totalPage: 0,
          list: [],
        },
      };
    },
  
    methods: {
      /**
       * 获取表单组件值(主要针对人员工、部门选择）
       */
      dealWithFormValue(form, name, component) {
        let value;
        if (name == "value") {
          value = form;
        } else if (form) {
          value = convertToCH(form[name]);
        }
        if (isEmpty(value)) {
          return;
        }
        if (component) {
          if (component.componentName == "date_picker") {
            let picker = component.picker;

            if (value) {
              value = moment(value).format(getMomentFormat(picker));
            }
          } else if (
            component.componentName == "address_input" ||
            component.componentName == "position_input"
          ) {
            let str = "";
            if (typeof value === "string") {
              return value;
            }
            if (value instanceof Object) {
              if (component.componentName == "position_input") {
                str = `${value.province}${value.city}${value.county}${value.address}`;
              } else {
                let picker = component.picker;
                if (picker == "province") {
                  str = `${value.province}`;
                } else if (picker == "city") {
                  str = `${value.province}${value.city}`;
                } else if (picker == "county") {
                  str = `${value.province}${value.city}${value.county}`;
                } else if (picker == "address") {
                  str = `${value.province}${value.city}${value.county}${value.address}`;
                }
              }
            }
            return str;
          } else if (component.componentName == "user_select") {
            if (name == "value") {
              return value;
            }
            let obj = form[`${name}UserInfo`];
            if (obj) {
              return obj.name;
            }
          } else if (component.componentName == "user_list_select") {
            if (name == "value") {
              return value;
            }
            let obj = form[`${name}UserMap`];
            if (obj) {
              let list = Object.values(obj);
              return list.map((item) => item.name).join(",");
            }
          } else if (component.componentName == "department_select") {
            if (name == "value") {
              return value;
            }
            let obj = form[`${name}DepartmentInfo`];
            if (obj) {
              return obj.name;
            }
          } else if (component.componentName == "department_list_select") {
            if (name == "value") {
              return value;
            }
            let list = form[`${name}DepartmentInfoList`];
            if (list) {
              return list.map((item) => item.name).join(",");
            }
          } else if (component.array) {
            if (value instanceof Array) {
              return value.join(",");
            }
          }
        }
        return value;
      },
      /**
       * 获取当前选中的值所属的document
       * @returns {*}
       */
      getCurrentDocument() {
        return this.page.list.find((item) => item.value == this.model);
      },
      initValue() {
        if (
          this.component &&
          this.valueType == "value" &&
          !this._.isEmpty(this.value) &&
          [
            "user_select",
            "user_list_select",
            "department_select",
            "department_list_select",
          ].includes(this.component.componentName)
        ) {
          if (this.component.componentName == "user_select") {
            listUserInfo({
              userIds: [this.value],
            }).then((res) => {
              let list = res.data.list;
              if (list.length) {
                this.label = list[0].name;
              }
            });
          } else if (this.component.componentName == "user_list_select") {
            listUserInfo({
              userIds: this.value,
            }).then((res) => {
              this.label = res.data.list.map((item) => item.name).join(",");
            });
          } else if (this.component.componentName == "department_select") {
            listDepartmentInfo({
              departmentIds: [this.value],
            }).then((res) => {
              if (res.data.list.length) {
                this.label = res.data.list[0].name;
              }
            });
          } else if (this.component.componentName == "department_list_select") {
            listDepartmentInfo({
              departmentIds: this.value,
            }).then((res) => {
              this.label = res.data.list.map((item) => item.name).join(",");
            });
          }
        } else if (this.component && !this._.isEmpty(this.value)) {
          if (this.valueType == "label") {
            this.label = this.value;
          } else {
            this.label = this.dealWithFormValue(this.value, "value", this.component);
          }
          if (this.multi) {
            this.label = this.value;
          }
        }
      },
      optValue(val) {
        return val;
      },
      buildValue(row) {
        if (this.valueSource == "table" && this.component.metaFieldOption) {
          return this.buildDealValue(row, this.component.metaFieldOption.field);
        } else {
          return this.buildDealValue(row, this.component);
        }
      },
      buildLabel(row) {
        if (this.valueSource == "table" && this.component.metaFieldOption) {
          return this.buildDealValue(row, this.component.metaFieldOption.field);
        } else {
          return this.buildDealValue(row, this.component);
        }
      },
  
      buildDealValue(row, component) {
        let name = component && component.name;
        if (this.component.metaFieldOption) {
          if (!name) {
            name = this.component.metaFieldOption.valueFieldName;
          }
        }
  
        if (name.indexOf(".") >= 0) {
          name = name.split(".")[1];
        }
        let value = row[name];
        if (this._.isEmpty(value)) {
          return value;
        }
        if (!component) {
          return value;
        }
  
        if (component.componentName == "date_picker") {
          if (value) {
            value = moment(value).format(getMomentFormat(component));
          }
          return value;
        } else if (
          component.componentName == "address_input" ||
          component.componentName == "position_input"
        ) {
          let str = "";
          if (value instanceof Object) {
            if (component.componentName == "position_input") {
              str = `${value.province}${value.city}${value.county}${value.address}`;
            } else {
              let picker = component.picker;
              if (picker == "province") {
                str = `${value.province}`;
              } else if (picker == "city") {
                str = `${value.province}${value.city}`;
              } else if (picker == "county") {
                str = `${value.province}${value.city}${value.county}`;
              } else if (picker == "address") {
                str = `${value.province}${value.city}${value.county}${value.address}`;
              }
            }
          }
          return str;
        } else if (component.componentName == "user_select") {
          let value2 = row[name + "UserInfo"];
          if (value2) {
            return value2.name;
          }
        } else if (component.componentName == "user_list_select") {
          let value2 = row[name + "UserMap"];
          if (value2) {
            let list = Object.values(value2).map((item) => item && item.name);
            return list.join(",");
          }
        } else if (component.componentName == "department_select") {
          let value2 = row[name + "DepartmentInfo"];
          if (value2) {
            return value2.name;
          }
        } else if (component.componentName == "department_list_select") {
          let value2 = row[name + "DepartmentInfoList"];
          if (value2) {
            let list = value2.map((item) => item && item.name);
            return list.join(",");
          }
        } else if (component.array) {
          if (value instanceof Array) {
            return value.join(",");
          } else {
            try {
              value = JSON.parse(value);
              return value.join(",");
            } catch (error) {}
          }
        }
        return value;
      },
  
      /**
       * 单独将获取列表数据的方法提出来，方便外部手动ref调用，
       */
      fetchListFormData() {
        console.log("fetchListFormData")
        if (!(this.component && this.component.optionSource == "custom")) {
          this.loadingList();
        }
      },
      getSubTitle(opt) {
        let str = "";
        if (this.valueSource == "table") {
          this.component.metaFieldOption.tableColumns.forEach((row) => {
            let value = opt[row.field.name];
            if (!this._.isEmpty(value)) {
              if (str != "") {
                str += "|";
              }
              str += this.dealWithFormValue(opt, row.field.name, row.field.component);
            }
          });
        }
  
        return str;
      },
  
      loadMore() {
        if (this.page.pageNumber < this.page.totalPage) {
          this.page.pageNumber += 1;
          this.loadingList();
        }
      },
      searchList(query) {
        this.page.pageNumber = 1;
        this.query = query;
        this.loadingList();
      },
      changeSelect(v) {
        if (this.query) {
          this.searchList("");
        }
      },
      loadingList() {
        console.log("loadingList")
        let query = this.query;
        let queryObject = {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
        };
        let sortParam = {};
        let item = this.component;
        let advanceQuery = [];
        if (
          !item.referenceName &&
          item.metaFieldOption &&
          this.valueSource == "table"
        ) {
          if (item.metaFieldOption.orderBy == "orderByOptionValueAsc") {
            sortParam[item.metaFieldOption.valueFieldName] = "asc";
          } else if (item.metaFieldOption.orderBy == "orderByOptionValueDesc") {
            sortParam[item.metaFieldOption.valueFieldName] = "desc";
          } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeAsc") {
            sortParam["createdTime"] = "asc";
          } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeDesc") {
            sortParam["createdTime"] = "desc";
          }
          if (Object.keys(sortParam).length) {
            queryObject.sortParam = sortParam;
          }
  
          if (
            item.metaFieldOption.dataFilter &&
            item.metaFieldOption.dataFilter.advanceQuery
          ) {
            item.metaFieldOption.dataFilter.advanceQuery.forEach((row) => {
              if (row.valueType == "custom") {
                advanceQuery.push({
                  key: row.key,
                  oper: row.oper,
                  value: row.value,
                  componentName: row.componentName,
                });
              }
            });
          }
        }
  
        let postData = {};
  
        if (!this._.isEmpty(query)) {
          let arr = [];
          if (
            !item.referenceName &&
            this.valueSource == "table" &&
            item.metaFieldOption
          ) {
            arr.push({
              oper: "like",
              key: item.metaFieldOption.valueFieldName,
              value: query,
            });
            if (item.metaFieldOption.tableColumns) {
              item.metaFieldOption.tableColumns.forEach((col) => {
                arr.push({
                  oper: "like",
                  key: col.field.name,
                  value: query,
                });
              });
            }
            advanceQuery.push({
              queryChain: {
                or: arr,
              },
            });
          } else {
            // 普通
            advanceQuery.push({
              oper: "like",
              key: item.name,
              value: query,
            });
          }
        }
        if (advanceQuery.length) {
          queryObject.advanceQuery = advanceQuery;
        }
        if (this.valueSource == "table") {
          if (item.metaFieldOption) {
            postData = {
              collection: item.metaFieldOption.tableName,
              queryObject: JSON.stringify(queryObject),
            };
          } else if (item.referenceName && item.collection) {
            postData = {
              collection: item.collection,
              queryObject: JSON.stringify(queryObject),
            };
          } else {
            postData = {
              ...this.params,
              queryObject: JSON.stringify(queryObject),
            };
          }
        } else {
          postData = {
            ...this.params,
            queryObject: JSON.stringify(queryObject),
          };
        }
  
        fetchPage(postData).then((res) => {
          let page = res.data.page;
          this.page.pageNumber = page.pageNumber;
          this.page.pageSize = page.pageSize;
          this.page.totalRow = page.totalRow;
          this.page.totalPage = page.totalPage;
  
          let list = [];
          if (page.pageNumber == 1) {
            list = [];
          } else {
            list = this._.cloneDeep(this.page.list);
          }
          page.list.forEach((row) => {
            if (!item.parent) {
              row.label = this.buildLabel(row);
              row.value = this.buildValue(row);
  
              if (
                !this._.isEmpty(row.value) &&
                list.findIndex((obj) => obj.value == row.value) == -1
              ) {
                list.push(row);
              }
            } else if (item.parent) {
              if (item.parent.componentName == "json_form") {
                let jsonRow = row[item.parent.name];
                if (jsonRow) {
                  jsonRow.forEach((opt) => {
                    opt.label = this.buildLabel(opt);
                    opt.value = this.buildValue(opt);
  
                    if (
                      !this._.isEmpty(opt.value) &&
                      list.findIndex((obj) => obj.value == opt.value) == -1
                    ) {
                      list.push(opt);
                    }
                  });
                }
              } else {
                row.label = this.buildLabel(row);
                row.value = this.buildValue(row);
  
                if (
                  !this._.isEmpty(row.value) &&
                  list.findIndex((obj) => obj.value == row.value) == -1
                ) {
                  list.push(row);
                }
              }
            }
          });
  
          this.page.list = list;
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .opt-item {
    font-size: 12px;
    line-height: 20px;
  
    .opt-title {
      color: #333;
      white-space: pre-wrap;
    }
  
    .sub-title {
      color: #666;
      white-space: pre-wrap;
    }
  }
  </style>
  
  <style lang="scss">
  .metaSelect {
    .el-select-dropdown__item {
      padding-top: 5px;
      padding-bottom: 5px;
      height: auto;
      line-height: normal;
    }
  }
  </style>
  