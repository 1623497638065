<template>
  <div class="search-input" :class="isSearchInputFocus ? 'isFocus' : 'isBlur'">
    <el-popover placement="bottom" width="560" trigger="click">
      <el-input
        ref="searchInput"
        slot="reference"
        prefix-icon="el-icon-search"
        size="small"
        style="width: 100%"
        @focus="searchInputFocus"
        clearable
        @clear="searchInputClear"
        @blur="searchInputBlur"
        v-model="searchInputValue"
        @input="search"
      ></el-input>
      <div style="height: 340px; overflow: auto" v-if="searchResultList.length">
        <el-checkbox
          v-if="multiple"
          @change="changeAll"
          :value="allCheck"
          :indeterminate="indeterminate"
          >全选</el-checkbox
        >
        <div
          v-for="(user, index) in searchResultList"
          class="search-result-item__wrap"
        >
          <el-checkbox
            v-model="user.isCheck"
            @change="toggleCheck($event, user, index)"
            :key="user.targetUserId"
          ></el-checkbox>
          <div class="user-name">
            <div
              class="name-avatar"
              style="
                width: 21px;
                height: 21px;
                line-height: 24px;
                margin-right: 5px;
              "
            >
              <span> {{ user.name.substring(user.name.length - 1) }}</span>
            </div>
            {{ user.name }}
          </div>
          <span v-text="user.mobile" class="user-mobile"></span>
        </div>
      </div>
      <el-empty v-else></el-empty>
    </el-popover>
  </div>
</template>

<script>
import { userSearch } from "@/views/lowCode/templateConfig/api";

export default {
  name: "user-select-search",
  data() {
    return {
      isSearchInputFocus: false,
      searchInputValue: "",
      searchResultList: [],
    };
  },
  props: {
    value: Array,
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    allCheck() {
      return !this.searchResultList.every((item) => item.isCheck === false);
    },
    indeterminate() {
      let count = 0;
      this.searchResultList.forEach((item) => (count += item.isCheck ? 1 : 0));
      return count > 0 && count < this.searchResultList.length;
    },
  },
  watch: {},
  methods: {
    changeAll(boolean) {
      // 取消全选
      if (!boolean) {
        this.searchResultList.forEach((user) => {
          user.isCheck = boolean;
        });
        this.value.splice(0, this.value.length);
        return;
      }
      // 全选
      this.searchResultList.forEach((user) => {
        if (user.isCheck !== boolean) {
          user.isCheck = boolean;
          this.emitAddUser(user);
        }
      });
    },
    emitAddUser(user) {
      this.value.push({
        id: user.targetUserId,
        name: user.name,
        mobile: user.mobile,
      });
    },
    emitRemoveUser(user) {
      let newIndex = this.value.findIndex((item) => {
        if (item.id === user.targetUserId) {
          return true;
        }
      });
      this.value.splice(newIndex, 1);
    },
    toggleCheck(e, user, index) {
      console.log(e, user, this.value);
      if (e) {
        console.log(this.searchResultList);
        if (!this.multiple) {
          if (this.value.length > 0) {
            this.value.splice(0);
            this.searchResultList.forEach((item) => {
              if (item.isCheck && item.targetUserId !== user.targetUserId) {
                item.isCheck = false;
              }
            });
          }
        }
        this.emitAddUser(user);
      } else {
        this.emitRemoveUser(user);
      }
    },
    isCheck(user) {
      console.log(
        this.value,
        user,
        this.value.some((item) => item.id === user.targetUserId)
      );
      return this.value.some((item) => item.id === user.targetUserId);
    },
    search(val) {
      if (this.searchInputValue == "") {
        this.searchResultList = [];
        return;
      }
      userSearch({
        keyword: this.searchInputValue,
        companyId: this.$store.getters.currentCompanyId,
      })
        .then((resp) => {
          console.log(resp);
          this.searchResultList = resp.data.page.list.map((user) => {
            user.isCheck = this.isCheck(user);
            return user;
          });
        })
        .catch((err) => {});
    },
    searchInputFocus() {
      this.isSearchInputFocus = true;
    },
    searchInputBlur() {
      if (this.searchInputValue === "") {
        this.isSearchInputFocus = false;
      }
    },
    searchInputClear() {
      this.isSearchInputFocus = false;
      this.searchResultList = [];
      this.$refs.searchInput.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.name-avatar {
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50%;
  background: var(--zgg-brand-color-6);
  color: #fff;
  width: 32px;
  height: 32px;
  font-size: 9.33333px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.delete {
    background-color: #f56c6c;
  }
}

.search-result-item__wrap {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--zgg-font-text-primary);

  .user-name {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
  }

  .user-mobile {
    font-size: 14px;
    line-height: 24px;
    width: 100px;
  }
}
</style>
