var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"weui-row"},[_c('el-input',{staticStyle:{"width":"220px"},attrs:{"size":"small","readonly":"","value":_vm.requestParam.param.title}}),_c('div',{staticStyle:{"font-size":"14px","margin-left":"10px"},domProps:{"textContent":_vm._s('设置为')}}),_c('el-select',{staticStyle:{"width":"140px","margin-left":"10px"},attrs:{"value":_vm.requestParam.paramValue.valueType,"size":"small","placeholder":""},on:{"change":_vm.changeValueType}},[(
          _vm.requestParam.param.componentName != 'object' &&
          _vm.requestParam.param.componentName != 'object_array'
        )?_c('el-option',{attrs:{"label":"节点字段","value":"nodeField"}}):_vm._e(),(
          _vm.requestParam.param.componentName == 'object' ||
          _vm.requestParam.param.componentName == 'object_array'
        )?_c('el-option',{attrs:{"label":"节点","value":"node"}}):_vm._e(),(_vm.hasCustom)?_c('el-option',{attrs:{"label":"自定义","value":"custom"}}):_vm._e(),_c('el-option',{attrs:{"label":"空值","value":"nil"}}),(_vm.requestParam.param.componentName == 'date_picker')?_c('el-option',{attrs:{"label":"动态","value":"dynamic"}}):_vm._e(),(_vm.hasShowRely)?_c('el-option',{attrs:{"label":"公式","value":"rely"}}):_vm._e()],1)],1),(_vm.valueType != 'nil')?_c('div',{staticClass:"weui-row",staticStyle:{"width":"100%"}},[(_vm.valueType == 'rely')?[_c('div',{staticStyle:{"width":"100%","position":"relative"}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"size":"small","value":_vm.relyTitle,"placeholder":"请设置公式","readonly":""}}),_c('div',{staticStyle:{"position":"absolute","left":"0","top":"0","width":"100%","height":"100%"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openFormula.apply(null, arguments)}}})],1),_c('formula-dialog',{ref:"relyDialog",attrs:{"title":"设置公式","originalFormula":_vm.requestParam.paramValue.rely &&
          _vm.requestParam.paramValue.rely.originalFormula,"nodeKey":_vm.node.key,"treeNodeList":_vm.nodeList,"fetchMetaFieldComponentList":_vm.fetchMetaComponents,"disabled":_vm.disabled,"disabled-funs":['TEXTUSER', 'TEXTDEPT', 'GETUSER', 'RECNO']},on:{"save":_vm.saveRely}})]:(
        _vm.requestParam.param.componentName == 'date_picker' &&
        _vm.valueType == 'dynamic'
      )?_c('DynamicDatePicker',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择日期","size":"small","hasCustom":true,"filterTimeModeByPicker":true,"hasNow":true},model:{value:(_vm.queryField.value),callback:function ($$v) {_vm.$set(_vm.queryField, "value", $$v)},expression:"queryField.value"}}):(_vm.valueType == 'custom')?_c('query-input',{staticStyle:{"width":"100%"},attrs:{"field":_vm.queryField,"areaTree":_vm.areaTree,"areaProps":_vm.areaProps,"disabled":_vm.disabled},model:{value:(_vm.queryField.value),callback:function ($$v) {_vm.$set(_vm.queryField, "value", $$v)},expression:"queryField.value"}}):(_vm.valueType == 'nodeField')?_c('rpa-node-fields',{attrs:{"nodeField":_vm.requestParam.paramValue.nodeField,"tableField":_vm.requestParam.param,"fieldConfigKey":"fillComponents","baseNodeKey":_vm.node.baseNodeKey,"disabled":_vm.disabled,"pluginConfig":_vm.requestParam}}):(_vm.valueType == 'node')?_c('el-select',{attrs:{"placeholder":""},on:{"change":_vm.changeKey},model:{value:(_vm.requestParam.paramValue.node.key),callback:function ($$v) {_vm.$set(_vm.requestParam.paramValue.node, "key", $$v)},expression:"requestParam.paramValue.node.key"}},_vm._l((_vm.parentNodes),function(item){return _c('el-option',{key:item.key,attrs:{"label":_vm.getLabel(item),"value":item.key}})}),1):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }