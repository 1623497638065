<template>
  <div>
    <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
      <el-form-item
        label="选择工作表"
        :rules="[{ required: true, message: '请选择工作表' }]"
        prop="formId"
      >
        <el-input
          v-model="node.formTitle"
          @focus="visible = true"
          readonly
        ></el-input>
      </el-form-item>

      <!-- 基于多条记录更新 -->
      <el-checkbox v-model="model">基于多条记录逐条删除记录</el-checkbox>
      <el-form-item v-if="model" prop="baseNodeKey">
        <el-select
          v-model="node.baseNodeKey"
          placeholder="请选择数据源"
          size="small"
        >
          <el-option
            v-for="opt in nodeList2"
            :key="opt.key"
            :label="`${opt.title}【${opt.label}】`"
            :value="opt.key"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="删除表单中哪些数据">
        <rpa-action-filter
          :dataFilter="node.dataFilter"
          :componentList="componentList"
          :formId="node.formId"
          :isAddFilter="isAddFilter"
          :disabled="disabled"
          :node-type="node.type"
          :baseNodeKey="node.baseNodeKey"
        ></rpa-action-filter>
      </el-form-item>
    </el-form>
    <data-source
      :visible.sync="visible"
      :menus="['form']"
      @changeSource="changeForm"
    ></data-source>
  </div>
</template>
<script>
import dataSource from "@/components/data-source";
import { fetchMetaComponents } from "@/api/form";
import rpaActionFilter from "./rpa-action-filter";
import { isEmpty } from "@zgg-core-utils/utils";
import { fromNodesMixins, isEmptyRpaActionFilter } from "./rpa-utils";
export default {
  name: "rpa-form-delete",
  components: { dataSource, rpaActionFilter },
  mixins: [fromNodesMixins],
  props: {
    node: Object,
    disabled: Boolean,
  },
  data() {
    return {
      componentList: [],
      visible: false,
      isAddFilter: false,
      model: false,
    };
  },
  computed: {
    nodeList2() {
      let list = this.getParentNodes(["query_data_list"]);
      list = list.filter((item) => item.type == "query_data_list");
      list.forEach((item) => {
        if (item.dataSource == "node_data_field") {
          item.label = item.nodeField.title;
        } else {
          item.label = item.formTitle || item.collectionTitle;
        }
      });
      if (this.node.dataSource == "node_data") {
        list = list.filter((item) => item.key != this.node.nodeKey);
      }

      return list;
    },
  },
  created() {
    if (this.node.formId) {
      this.fetchMetaComponents(this.node.formId);
    }
    this.isAddFilter = this.node.formId && !this.disabled;
    this.model = !isEmpty(this.node.baseNodeKey);
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (isEmptyRpaActionFilter(this.node.dataFilter)) {
          this.$message.error("请设置过滤条件的字段值");
          return;
        }

        callback(valid);
      });
    },
    changeForm({ tableName, tableTitle }) {
      let callback = () => {
        this.node.formId = tableName;
        this.node.formTitle = tableTitle;
        this.$set(this.node, "dataFilter", {
          rel: "and",
          advanceQuery: [],
        });
        this.fetchMetaComponents(tableName);
      };
      if (this.node.formId != tableName && !isEmpty(this.node.formId)) {
        this.$confirm("切换表单将会清除已设置的配置", "提示")
          .then(callback)
          .catch(() => {});
      } else {
        callback();
      }
    },
    fetchMetaComponents(tableName) {
      fetchMetaComponents(tableName).then((res) => {
        this.componentList = res.data.list;
      });
    },
  },
};
</script>
