<template>
  <div class="etl-pane">
    <div ref="etlScroll" class="etl-scroll">
      <div
        :style="wrapperStyle"
        @click.prevent.stop="wrapperClick"
        class="etl-pane-wrapper"
        ref="etlWrapper"
        @mousedown.prevent.stop="regionMousedown"
      >
        <div
          v-for="(item, index) in etlList"
          :key="item.key"
          class="etl-item"
          :class="[
            {
              'is-select': item.checked,
              'is-error': errorKeys.includes(item.key),
            },
          ]"
          :style="`left:${item.left}px;top:${item.top}px;z-index:${
            moveIndex == index ? 10 : ''
          }`"
          @mousedown.prevent.stop="mousedown(index, $event)"
          @mouseenter="mouseenter($event, item)"
          @mouseleave="mouseleave($event, item)"
          @click.prevent.stop="checkNode($event, item.key)"
        >
          <i class="iconfont-fx-pc" :class="`icon-data-${item.stageType}`"></i>
          <span class="node-title" v-text="item.title"></span>
          <div
            v-if="item.stageType != 'input'"
            class="etl-round input"
            style="left: -8px"
            @mousedown.prevent.stop="drawStart($event, item, 'input')"
          ></div>
          <div
            v-if="item.stageType != 'output'"
            class="etl-round output"
            style="right: -8px"
            @mousedown.prevent.stop="drawStart($event, item, 'output')"
          ></div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          class="svg-process"
        >
          <defs>
            <marker
              id="pointer"
              markerWidth="25"
              markerHeight="25"
              refX="8"
              refY="3"
              orient="auto"
              markerUnits="strokeWidth"
              viewBox="0 0 32 32"
            >
              <path d="M0,0 L3,3 L0,6 L9,3 z" fill="rgb(13, 179, 166)" />
            </marker>
          </defs>
          <g
            @click.prevent.stop="checkLine(line)"
            v-for="line in etlLines"
            :key="line.fromKey + '_' + line.toKey"
          >
            <path
              :d="getD(line)"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 4"
            ></path>
            <path
              class="x-flow-path"
              :d="getD(line)"
              :style="`fill: none;
              stroke:${
                hasCheck(line) ? `rgb(41, 145, 255)` : `rgb(13, 179, 166)`
              };
              stroke-width: 2;
              cursor: pointer;`"
            ></path>
          </g>
          <g v-if="drawObj && drawObj.isMove">
            <path
              :d="linePath"
              style="fill: none; stroke: rgb(255, 255, 255); stroke-width: 4"
            ></path>
            <path
              class="x-flow-path"
              :d="linePath"
              :style="`fill: none;
              stroke:${
                hasCheck(drawObj) ? `rgb(41, 145, 255)` : `rgb(13, 179, 166)`
              };
              stroke-width: 2;
              cursor: pointer;`"
              marker-end="url(#pointer)"
            ></path>
          </g>
        </svg>

        <!-- 框选样式start -->
        <div
          v-if="regionBox"
          class="region-box"
          :style="`left:${regionBox.left}px;top:${regionBox.top}px;width:${regionBox.width}px;height:${regionBox.height}px;`"
        ></div>
        <!-- 框选样式end -->

        <!-- 基准线 -->
        <div
          class="base-line hor"
          v-if="horizontal"
          :style="`left:${horizontal.x}px;top:${horizontal.y}px;width:${horizontal.width}px;height:40px;`"
        ></div>
        <div
          class="base-line ver"
          v-if="vertical"
          :style="`left:${vertical.x}px;top:${vertical.y}px;width:160px;height:${vertical.height}px;`"
        ></div>
      </div>
    </div>
    <etl-node-attribute
      v-if="currentNode"
      :key="currentNode.key"
      :node="currentNode"
      :etlList="etlList"
      :etlLines="etlLines"
    >
      <etl-input
        v-if="
          currentNode &&
          currentNode.stageType == 'input' &&
          currentNode.dataSource &&
          currentNode.dataSource.formId
        "
        :node="currentNode"
        :visible.sync="visibleSource"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-input>
      <etl-join
        v-else-if="currentNode && currentNode.stageType == 'join'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-join>
      <etl-group
        v-else-if="currentNode && currentNode.stageType == 'group'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-group>
      <etl-filter
        v-else-if="currentNode && currentNode.stageType == 'filter'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
        :areaTree="areaTree"
        :areaProps="areaProps"
      ></etl-filter>
      <etl-union
        v-else-if="currentNode && currentNode.stageType == 'union'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-union>
      <etl-output
        v-else-if="currentNode && currentNode.stageType == 'output'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-output>
      <etl-row-to-col
        v-else-if="currentNode && currentNode.stageType == 'rowToCol'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-row-to-col>
      <etl-map
        v-else-if="currentNode && currentNode.stageType == 'map'"
        :node="currentNode"
        :etlList="etlList"
        :etlLines="etlLines"
      >
      </etl-map>
    </etl-node-attribute>
    <etl-source
      v-if="currentNode && currentNode.stageType == 'input'"
      :node="currentNode"
      :etlList="etlList"
      :etlLines="etlLines"
      :visible.sync="visibleSource"
      :isOutside.sync="isOutside"
    ></etl-source>
  </div>
</template>
<script>
import regionCheck from "./regionCheck";
import drawLine from "./drawLine";
import EtlNodeAttribute from "./EtlNodeAttribute";
import EtlSource from "./EtlSource";
import EtlInput from "./EtlInput";
import EtlJoin from "./EtlJoin";
import EtlUnion from "./EtlUnion";
import EtlGroup from "./EtlGroup.vue";
import EtlOutput from "./EtlOutput";
import EtlMap from "./EtlMap";
import { buildInputFields, buildNodeFields, chkNodeConfig } from "./util";
import EtlFilter from "./EtlFilter";
import EtlRowToCol from "./EtlRowToCol";

export default {
  components: {
    EtlNodeAttribute,
    EtlSource,
    EtlInput,
    EtlJoin,
    EtlUnion,
    EtlGroup,
    EtlFilter,
    EtlOutput,
    EtlRowToCol,
    EtlMap,
  },
  name: "EtlPane",
  mixins: [regionCheck, drawLine],
  props: {
    checkLines: {
      type: Array,
      default() {
        return [];
      },
    },
    zoom: {
      type: Number,
      default() {
        return 1;
      },
    },
    etlList: {
      type: Array,
      default() {
        return [];
      },
    },
    etlLines: {
      type: Array,
      default() {
        return [];
      },
    },
    areaProps: Object,
    areaTree: {
      type: Array,
      default() {
        return [];
      },
    },
    errorKeys: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    wrapperStyle() {
      let width;
      let height;
      if (this.clientWidth) {
        width = this.clientWidth;
      }
      if (this.clientHeight) {
        height = this.clientHeight;
      }

      let maxLeft = Math.max(...this.etlList.map((item) => item.left));
      let maxWidth = maxLeft + 160 + 40;
      if (maxWidth > width) {
        width = maxWidth;
      }
      let maxTop = Math.max(...this.etlList.map((item) => item.top));
      let maxHeight = maxTop + 40 + 40;
      if (maxHeight > height) {
        height = maxHeight;
      }
      return `zoom:${this.zoom};width:${width}px;height:${height}px;`;
    },
    linePath() {
      if (this.drawObj && this.drawObj.isMove) {
        let drawObj = this.drawObj;
        return this.buildLinePath(drawObj);
      }
      return "";
    },
    isCanDelete() {
      if (this.visibleSource) {
        return false;
      }
      if (this.isOutside) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      moveIndex: -1,
      moveObj: {
        x: 0,
        y: 0,
        left: 0,
        top: 0,
      },
      isMove: false,
      clientWidth: 0,
      clientHeight: 0,

      horizontal: null,
      vertical: null,
      currentNode: null,
      visibleSource: false,
      isOutside: false,
    };
  },

  destroyed() {
    window.removeEventListener("resize", this.setResize);
  },
  mounted() {
    this.clientWidth = this.$el.clientWidth;
    this.clientHeight = this.$el.clientHeight;

    window.addEventListener("resize", this.setResize);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
    document.removeEventListener("click", this.clickoutside);
  },
  created() {
    document.addEventListener("keydown", this.keydown);
    document.addEventListener("click", this.clickoutside);
  },
  methods: {
    clickoutside() {
      this.isOutside = true;
    },
    addSource(node) {
      this.currentNode = node;
      this.visibleSource = true;
    },
    setResize() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.clientWidth = this.$el.clientWidth;
        this.clientHeight = this.$el.clientHeight;
      }, 100);
    },
    setUndo() {
      this.$emit("setUndo");
    },
    hasCheck(line) {
      return (
        this.checkLines.findIndex(
          (item) => item.fromKey == line.fromKey && item.toKey == line.toKey,
        ) >= 0
      );
    },
    checkLine(line) {
      this.$emit("update:checkLines", [
        {
          fromKey: line.fromKey,
          toKey: line.toKey,
        },
      ]);

      this.etlList.forEach((item) => {
        item.checked = false;
      });
      this.isOutside = false;
    },
    getD(line) {
      let fromNode = this.etlList.find((item) => item.key == line.fromKey);
      let toNode = this.etlList.find((item) => item.key == line.toKey);
      let node = {
        left: parseFloat(fromNode.left) + 160,
        top: parseFloat(fromNode.top) + 20,
        endX: parseFloat(toNode.left),
        endY: parseFloat(toNode.top) + 20,
      };
      let r = this.buildLinePath(node);
      return r;
    },
    buildLinePath(drawObj) {
      let x = (drawObj.endX - drawObj.left) / 2;
      let y = (drawObj.endY - drawObj.top) / 2;
      if (x < -20) {
        x = -20;
      }

      let diffX = Math.abs(x);

      // 开始
      let strPath = `M${drawObj.left},${drawObj.top} L${drawObj.left + diffX},${
        drawObj.top
      }`;

      strPath += ` L${drawObj.left + diffX},${drawObj.top + y}`;

      strPath += ` L${drawObj.endX - diffX},${drawObj.top + y}`;

      // 结束
      strPath += ` L${drawObj.endX - diffX},${drawObj.endY} L${drawObj.endX},${
        drawObj.endY
      }`;

      return strPath;
    },
    mouseenter(event, node) {
      if (this.drawObj) {
        if (this.drawObj.type == "start") {
          if (node.stageType == "input") {
            return;
          }
          let line =
            this.etlLines.findIndex(
              (line) =>
                line.toKey == node.key && line.fromKey == this.drawObj.fromKey,
            ) >= 0;

          this.$set(this.drawObj, "endX", node.left);
          this.$set(this.drawObj, "endY", node.top + 20);
          if (!line) {
            this.$set(this.drawObj, "toKey", node.key);
          }
        } else if (this.drawObj.type == "end") {
          if (node.stageType == "output") {
            return;
          }

          let line =
            this.etlLines.findIndex(
              (line) =>
                line.fromKey == node.key && line.toKey == this.drawObj.toKey,
            ) >= 0;

          this.$set(this.drawObj, "left", node.left + 160);
          this.$set(this.drawObj, "top", node.top + 20);
          if (!line) {
            this.$set(this.drawObj, "fromKey", node.key);
          }
        }
      }
    },
    mouseleave(event, node) {
      if (this.drawObj) {
        let key;
        if (this.drawObj.type == "start") {
          key = "toKey";
        } else if (this.drawObj.type == "end") {
          key = "fromKey";
        }
        if (key) {
          this.$delete(this.drawObj, key);
        }
      }
    },

    wrapperClick() {
      if (!this.regionMove) {
        this.etlList.forEach((item) => {
          item.checked = false;
        });
        this.etlLines.forEach((item) => {
          item.checked = false;
        });
        this.$emit("update:checkLines", []);
        this.currentNode = null;
      }
    },
    deleteNode() {
      this.currentNode = null;
      this.$emit("deleteNodes");
    },
    checkNode(event, key) {
      // if (this.isMove) {
      //   return;
      // }

      let ctrl = event.ctrlKey || event.metaKey;
      this.etlList.forEach((item) => {
        if (!ctrl) {
          item.checked = item.key == key;
        } else {
          if (item.key == key) {
            item.checked = !item.checked;
          }
        }
      });
      let node = this.etlList.find((item) => item.key == key);

      if (
        node &&
        node.checked &&
        this.etlList.filter((item) => item.checked).length == 1
      ) {
        this.currentNode = node;

        if (node.stageType == "input") {
          this.visibleSource = !!chkNodeConfig(
            node,
            this.etlList,
            this.etlLines,
          );
        }
      } else {
        this.currentNode = null;
      }
      this.isOutside = false;
      this.$emit("update:checkLines", []);
      if (node.checked) {
        buildInputFields(node, this.etlList, this.etlLines);
        if (node.stageType == "output") {
          buildNodeFields(node, this.etlList, this.etlLines);
        }
      }

      //
    },

    mousedown(index, event) {
      this.moveObj.x = event.clientX;
      this.moveObj.y = event.clientY;
      this.moveObj.left = this.etlList[index].left;
      this.moveObj.top = this.etlList[index].top;
      this.moveIndex = index;
      this.isMove = false;
      this.undo = {
        etlList: this._.cloneDeep(this.etlList),
        etlLines: this._.cloneDeep(this.etlLines),
      };
      document.addEventListener("mousemove", this.mousemove);
      document.addEventListener("mouseup", this.mouseup);
    },
    mousemove(event) {
      if (this.moveIndex == -1) {
        // 没有拖拽对象就不再继续
        return;
      }

      let isIE9 =
        navigator.appName == "Microsoft Internet Explorer" &&
        parseInt(
          navigator.appVersion
            .split(";")[1]
            .replace(/[ ]/g, "")
            .replace("MSIE", ""),
        ) == 9;
      if (!isIE9 && event.buttons != 1) {
        // 鼠标左键没有按住
        this.mouseup();
        return;
      }

      let x = event.clientX - this.moveObj.x;
      let y = event.clientY - this.moveObj.y;

      // if (x > 0 || y > 0) {
      let node = this.etlList[this.moveIndex];
      let left = this.moveObj.left + x;
      let top = this.moveObj.top + y;
      x = Math.abs(x);
      y = Math.abs(y);
      if (left < 20) {
        left = 20;
      }
      if (top < 20) {
        top = 20;
      }
      let obj = this.buildBaseLine(left, top);
      if (obj) {
        if (obj.top) {
          top = obj.top;
        }
        if (obj.left) {
          left = obj.left;
        }
      }

      node.left = left;
      node.top = top;
      this.isMove = true;
      // }
    },
    mouseup() {
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.clearBaseLine();
      if (this.isMove) {
        this.$emit("setUndo", this.undo);
      }

      this.moveIndex = -1;
    },
    clearBaseLine() {
      this.horizontal = null;
      this.vertical = null;
    },
    buildBaseLine(x, y) {
      // 构建基准线
      let horizontalNodes = this.etlList.filter(
        (node, index) => Math.abs(node.top - y) < 8 && this.moveIndex != index,
      );
      let minY = Math.min(
        ...horizontalNodes.map((item) => Math.abs(item.top - y)),
      );
      horizontalNodes = horizontalNodes.filter(
        (item) => Math.abs(item.top - y) == minY,
      );
      let top;
      if (horizontalNodes.length) {
        // 水平基准线
        if (
          horizontalNodes.filter((item) => item.top >= y).length >
          horizontalNodes.filter((item) => item.top < y).length
        ) {
          horizontalNodes = horizontalNodes.filter((item) => item.top >= y);
        } else {
          horizontalNodes = horizontalNodes.filter((item) => item.top < y);
        }
        let minLeft = Math.min(...horizontalNodes.map((item) => item.left));
        let maxLeft = Math.max(...horizontalNodes.map((item) => item.left));
        let horizontalObj = {
          y: horizontalNodes[0].top,
          x: 0,
          width: 0,
        };
        if (minLeft > x) {
          minLeft = x;
        }
        if (maxLeft < x) {
          maxLeft = x;
        }
        horizontalObj.x = minLeft;
        horizontalObj.width = maxLeft - minLeft;

        this.horizontal = horizontalObj;
        top = horizontalObj.y;
      } else {
        this.horizontal = null;
      }

      // 垂直基准线
      let verticalNodes = this.etlList.filter(
        (node, index) => Math.abs(node.left - x) < 8 && this.moveIndex != index,
      );
      let minX = Math.min(
        ...verticalNodes.map((item) => Math.abs(item.left - x)),
      );
      verticalNodes = verticalNodes.filter(
        (item) => Math.abs(item.left - x) == minX,
      );

      let left;
      if (verticalNodes.length) {
        // 水平基准线
        if (
          verticalNodes.filter((item) => item.left >= x).length >
          verticalNodes.filter((item) => item.left < x).length
        ) {
          verticalNodes = verticalNodes.filter((item) => item.left >= x);
        } else {
          verticalNodes = verticalNodes.filter((item) => item.left < x);
        }
        let minTop = Math.min(...verticalNodes.map((item) => item.top));
        let maxTop = Math.max(...verticalNodes.map((item) => item.top));
        let verticalObj = {
          x: verticalNodes[0].left,
          y: 0,
          height: 0,
        };
        if (minTop > y) {
          minTop = y;
        }
        if (maxTop < y) {
          maxTop = y;
        }
        verticalObj.y = minTop;
        verticalObj.height = maxTop - minTop;

        this.vertical = verticalObj;
        left = verticalObj.x;
      } else {
        this.vertical = null;
      }

      return { top, left };
    },
    keydown() {
      if (!this.isCanDelete) {
        return;
      }
      // event.keyCode 8，46
      let keyCode = event.keyCode;
      if (keyCode != 8 && keyCode != 46) {
        // 非删除键
        return;
      }
      this.deleteNode();
    },
  },
};
</script>
<style lang="scss" scoped>
.etl-pane {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.etl-scroll {
  flex: 1;
  overflow: auto;
}
.base-line {
  position: absolute;
  border-style: dashed;
  border-color: #ff5b37;
  &.hor {
    border-width: 1px 0px;
  }
  &.ver {
    border-width: 0px 1px;
  }
}

.svg-process {
  width: 100%;
  height: 100%;
}
.etl-pane-wrapper {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  background-color: #f0f3fa;
}
.etl-item {
  padding: 0 5px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #f7f9fe;
  border: 1px solid #e1e3e5;
  cursor: move;
  position: absolute;
  box-sizing: border-box;
  z-index: 2;
  .iconfont-fx-pc {
    color: #248af9;
    font-size: 16px;
  }
  .node-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
  }
  &:hover,
  &.is-select {
    background-color: #e3f6ff;
    border-color: #76bbeb;
  }
  &.is-error {
    background-color: #ffeeed;
    border-color: #e64340;
    i {
      color: #f04134;
    }
  }
  .etl-round {
    position: absolute;
    top: 12px;
    width: 16px;
    height: 16px;
    cursor: crosshair;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &.input {
      &::before {
        top: 2px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        background: transparent;
        border-top: 6px solid transparent !important;
        border-right: none;
        border-bottom: 6px solid transparent !important;
        border-left: 12px solid #e9e9e9;
        border-radius: 0;
        position: absolute;
        content: " ";
      }
      &::after {
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        border: 4px solid transparent;
        border-right: none;
        border-left: 8px solid #fff;
        border-radius: 0;
        content: "";
      }
      &:hover {
        &::before {
          border-left-color: #248af9;
        }
      }
    }
    &.output {
      &::before {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        border: solid 1px #e9e9e9;
        background-color: #ffffff;
        border-radius: 50%;
      }
      &:hover {
        &::before {
          border-color: #248af9;
        }
      }
    }
  }
}
.region-box {
  position: absolute;
  z-index: 5;
  border: dashed 1px #dddddd;
  background-color: rgba($color: #000000, $alpha: 0.02);
}
</style>
