import Cookies from 'js-cookie'
import store from '@/store/index';

export function getCookie(key) {
    return Cookies.get(key)
}

/**
 * @param key
 * @param value
 * @returns {*}
 */
export function setCookie(key, value) {
    console.log(key, value)
    return Cookies.set(key, value,{
        expires: 365,
    })
}
/**
 * 为了解决部分第三方对接时，用iframe来加载，所以需要将设置sameSite设置为none
 * @param key
 * @param value
 * @returns {*}
 */
export function setCookieSameSizeNone(key, value) {
    console.log(key, value)
    return Cookies.set(key, value, {
        sameSite: "None",
        secure: true
    })
}


export function removeCookie(key) {
    //移除cookies
    return Cookies.remove(key)
}
