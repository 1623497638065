<template>
  <div>
    <div class="mb">
      <el-form label-position="top" size="small" :model="value" ref="form" :show-message="false">
        <el-form-item label="触发表单" style="width: 100%" required prop="formId" error="请选择触发表单" inline-message>
          <formSelectPopover @getComponents="getComponents" v-model="value"></formSelectPopover>
        </el-form-item>
        <template v-if="dateComponentList && dateComponentList.length">

          <el-form-item label="指定日期字段" style="width: 100%" required prop="field.fieldName">
            <el-select
                v-model="value.field.fieldName"
                placeholder="请选择指定日期字段"
                size="small"
                @change="changeDateField"
                style="width: 100%"
            >
              <el-option
                  v-for="item in dateComponentList"
                  :key="item.name"
                  :value="item.name"
                  :label="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="value.field&&value.field.fieldName">

          <el-form-item label="开始执行时间" style="width: 100%">
            <div style="display: flex;flex-direction: row;gap: 8px">
              <el-select
                  placeholder="请选择指定日期字段"
                  size="small"
                  v-model="timeOffsetType"
              >
                <el-option
                    v-for="item in startExecuteTimeTypeEnums"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                >
                </el-option>
              </el-select>
              <div style="flex: 1;display: flex;flex-direction: row;gap: 8px" v-if="timeOffsetType!=='current'">
                <el-input v-model="timeNumber" @change="timeNumberChange">
                </el-input>
                <el-select
                    size="small"
                    class="ml"
                    v-model="timeUnit"
                >
                  <el-option label="分钟" value="minute" v-show="!isDateWithoutTime"></el-option>
                  <el-option label="小时" value="hour" v-show="!isDateWithoutTime"></el-option>
                  <el-option label="天" value="day"></el-option>
                </el-select>
              </div>
              <div v-if="isDateWithoutTime">
                <el-time-picker
                    v-model="value.executeTimeStr"
                    value-format="HH:mm:ss"
                    placeholder="任意时间点">
                </el-time-picker>
              </div>
            </div>
          </el-form-item>
        </template>
        <template v-if="value.field&&value.field.fieldName">

          <el-form-item label="重复周期" style="width: 100%">
            <el-select
                v-model="value.loopType"
                placeholder="请选择重复周期"
                size="small"
                style="width: 100%"
            >
              <el-option
                  v-for="item in timerFieldLoopTypeEnums"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="value.loopType!=='once'&&dateComponentList && dateComponentList.length">

            <el-form-item label="结束执行时间" style="width: 100%" >
              <el-select
                  v-model="value.endTimeField.fieldName"
                  placeholder="请选择指定日期字段"
                  size="small"
                  @change="changeEndDateField"
                  style="width: 100%"
                  clearable
              >
                <el-option
                    v-for="item in dateComponentList"
                    :key="item.name"
                    :value="item.name"
                    :label="item.title"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </template>
      </el-form>

    </div>
    <assistantDataFilter v-if="value.formId" v-model="value" :componentList="componentList"></assistantDataFilter>
  </div>
</template>

<script>
import formSelectPopover from '@/views/lowCode/assistant/form-select-popover'

import assistantDataFilter from '@/views/lowCode/assistant/assistant-data-filter'
import timerTriggerMixin from '@/views/lowCode/assistant/timer-trigger-mixin'
import { isEmpty } from 'lodash'

export default {
  name: 'timer-field-trigger-setting',
  mixins: [
    timerTriggerMixin
  ],
  components: {
    assistantDataFilter,
    formSelectPopover
  },
  props: {
    value: Object,
    componentsMap: Object,
    componentList: Array
  },
  data() {
    return {
      visible: false,
      searchTitle: '',
      startExecuteTimeTypeEnums: [
        {
          label: '在以上日期时间',
          value: 'current'
        },
        {
          label: '之前',
          value: 'before'
        },
        {
          label: '之后',
          value: 'after'
        }
      ],
      lastTimeOffsetType: ''
    }
  },
  watch: {},
  computed: {
    isDateWithoutTime() {
      let datePicker = ['year','month','date']
      return this.value.field && datePicker.includes(this.value.field.picker)
    },
    //获取日期组件
    dateComponentList() {
      return this.componentList.filter(component => {
        console.log(component.picker)
        let datePicker = ['year','month']
        return component.componentName == 'date_picker'&&component.name.indexOf('.')==-1&&!datePicker.includes(component.picker)
      })
    },
    timeOffsetType: {
      get() {
        if (this.value.timeOffset) {
          let value
          let values = Object.values(this.value.timeOffset)
          values = values.filter(item => item != 0)
          if (values.length) {
            value = values[0]
          }
          console.log('timeOffsetType get', value)
          if (value < 0) {
            this.lastTimeOffsetType = 'before'
            return 'before'
          }
          if (value > 0) {
            this.lastTimeOffsetType = 'after'
            return 'after'
          }
          return this.lastTimeOffsetType
        } else {
          return 'current'
        }

      },
      set(val) {
        console.log(val)

        let obj = {}
        if (val == 'current') {
          obj = undefined
        }
        if (val == 'before') {
          obj['day'] = -1
        }
        if (val == 'after') {
          obj['day'] = 1
        }
        this.value.timeOffset = obj
      }
    },
    timeNumber: {
      get() {
        console.log(this.value.timeOffset)
        if (isEmpty(this.value.timeOffset)) {
          return ''
        }
        let value
        let values = Object.values(this.value.timeOffset)
        if (values.length) {
          value = ''
          for (const [key, val] of Object.entries(this.value.timeOffset)) {
            if (val !== 0) {
              value = Math.abs(val)
              console.log('timeNumber', val, value)
            }
          }
          return value
        }
        return ''
      },
      set(val) {
        console.log(val)
        val = this.inputNumberCheck(val)
        let obj = {}
        let newVal

        if (this.timeOffsetType == 'current') {
          this.value.timeOffset = undefined
          return
        }
        if (this.timeOffsetType == 'before') {
          newVal = -val
        }
        if (this.timeOffsetType == 'after') {
          newVal = val
        }
        obj[this.timeUnit] = newVal
        this.value.timeOffset = obj

      }
    },
    timeUnit: {
      get() {
        if (isEmpty(this.value.timeOffset)) {
          return ''
        }
        let unit = 'day'
        for (const [key, value] of Object.entries(this.value.timeOffset)) {
          if (typeof value !== 'undefined' && value !== 0) {
            unit = key
          }
        }
        return unit
      },
      set(val) {
        let obj = {}
        let newVal
        if (this.timeOffsetType == 'current') {
          newVal = 0
        }
        if (this.timeOffsetType == 'before') {
          newVal = -this.timeNumber
        }
        if (this.timeOffsetType == 'after') {
          newVal = this.timeNumber
        }
        obj[val] = newVal
        this.value.timeOffset = obj
        // this.$emit("input", obj);
      }
    }
  },
  methods: {

    timeNumberChange(val) {
      console.log('timeNumber val', val)
      // val = this.inputNumberCheck(val)

    },
    validateForm() {
      return this.$refs.form.validate()
    },
    changeEndDateField(val) {
      let selectDateField = this.dateComponentList.find(item => item.name == val)
      this.value.endTimeField.fieldTitle = selectDateField.title
      this.value.endTimeField.picker = selectDateField.picker
    },
    changeDateField(val) {
      let selectDateField = this.dateComponentList.find(item => item.name == val)
      this.value.field.fieldTitle = selectDateField.title
      this.value.field.picker = selectDateField.picker
    },
    getComponents(id) {
      this.$emit('getComponents', id)
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped lang="scss">
.trigger-label {
  color: #5e6d82;
  font-size: 14px;
}

.trigger-value {
  background-color: #f1f1f1;
  padding: 5px 10px;
  font-size: 14px;
  margin-left: 10px;
}

.replace {
  font-size: 14px;
  color: #409eff;
  cursor: pointer;
  margin: 0px 5px;
}

.trigger-select {
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  overflow: hidden;

  .inner-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    font-size: 14px;
  }
}

.mb {
  margin-bottom: 12px;
}
</style>
