<template>
  <div
    :id="`filter_${dashboardFilterModel?.key}`"
    class="dashboard-filter__container"
  >
    <div style="width: 100%; display: flex; flex-direction: row">
      <div style="max-width: 140px; margin-right: 10px; min-width: 140px">
        <!-- 操作符start -->
        <el-select
          v-model="dashboardFilterModel.fieldQuery.oper"
          placeholder="操作符"
          size="small"
          style=""
          @change="handleFieldQueryOperChange"
        >
          <el-option
            v-for="opt in getDisplayQueryOpers(firstFilterField.componentName)"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="flex: 1" v-if="!hideFilterValueSetting" class="input">
        <!-- 操作符end -->
        <div
          v-if="
            dashboardFilterModel.fieldQuery.valueType === 'custom' &&
            dashboardFilterModel.fieldQuery.valueContainsCurrentUser === true
          "
        >
          <el-input
            size="small"
            placeholder="请输入内容"
            v-model="currentUserName"
            :disabled="true"
          >
          </el-input>
        </div>
        <query-input
          v-if="
            dashboardFilterModel.fieldQuery.valueType === 'custom' &&
            !dashboardFilterModel.fieldQuery.valueContainsCurrentUser
          "
          :field="dashboardFilterModel.fieldQuery"
          style="width: 100%"
          v-model="dashboardFilterModel.fieldQuery.value"
        ></query-input>
        <address-select
          v-if="
            dashboardFilterModel.fieldQuery.valueType === 'tableField' &&
            isUseAddressComponent
          "
          size="small"
          v-model="dashboardFilterModel.fieldQuery.value"
          :areaTreeList="areaTreeList"
          :areaProps="areaProps"
          style="width: 100%"
          @input="changeFieldValueHandle"
        >
        </address-select>
        <field-value-select
          v-if="
            dashboardFilterModel.fieldQuery.valueType === 'tableField' &&
            !isUseAddressComponent
          "
          :component="dashboardFilterModel"
          :fieldName="dashboardFilterModel.fields[0].fieldName"
          :fetchParams="fetchParams"
          size="small"
          :multi="isMulti"
          v-model="dashboardFilterModel.fieldQuery.value"
          style="width: 100%"
          @input="changeFieldValueHandle"
        ></field-value-select>
      </div>
    </div>
  </div>
</template>

<script>
import { getComponentQueryOper } from "@zgg-core-utils/whiteList.js";
import { fetchFormDataList, getAggreation } from "@/views/lowCode/form/api";
import { mapState } from "vuex";
import { getTreeData } from "@zgg-core-utils/utils";
import {
  handleDataFilterSet,
  getFilterColorConfig,
} from "@/views/lowCode/dashboard/dashboard-util";

export default {
  name: "DashboardFilter",
  provide() {
    return {
      fetchDataList: (a) => {
        console.log(a);
        let param = {
          ...a,
          queryObject: {
            ...a,
          },
          collection: this.dashboardFilterModel.fields[0].collection,
        };
        return fetchFormDataList(param);
      },
      fetchAggreation: (a) => {
        let xFields = [
          {
            field: {
              name: this.dashboardFilterModel.fields[0].fieldName,
              componentName: this.dashboardFilterModel.fields[0].componentName,
            },
          },
        ];
        let advanceQuery = [
          {
            key: this.dashboardFilterModel.fields[0].fieldName,
            oper: "nnil",
          },
        ];
        if (a && a.queryObject) {
          a.queryObject.xFields = a.queryObject.xFields
            ? a.queryObject.xFields.concat(xFields)
            : xFields;
          a.queryObject.advanceQuery = a.queryObject.advanceQuery
            ? a.queryObject.advanceQuery.concat(advanceQuery)
            : advanceQuery;
        }
        let param = {
          ...a,
          collection: this.dashboardFilterModel.fields[0].collection,
          cmd: "page",
          dataFilter: this.dataFilter,
        };
        return getAggreation(param);
      },
    };
  },
  props: {
    dashboardFilterModel: {
      type: Object,
      default() {
        return {
          title: "",
          containerKeys: [],
          filterKeys: [],
          fields: [],
          fieldQuery: {
            valueType: "",
            value: "",
            oper: "",
            valueContainsCurrentUser: false,
          },
          componentName: "dashboard_filter",
        };
      },
    },
    filterComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
    isH5: Boolean,
  },
  data() {
    return {
      currentUserName: "当前用户",
    };
  },
  mounted() {
    getFilterColorConfig(this.dashboardFilterModel, this.isH5);
  },
  watch: {
    "dashboardFilterModel.fieldQuery.value": {
      immediate: false,
      deep: true,
      handler(val, oldVal) {
        if (val === oldVal) return;
        this.$emit("refreshDashboard", this.dashboardFilterModel);
      },
    },
    "dashboardFilterModel.colorConfig.contentColor": {
      immediate: false,
      deep: true,
      handler(val, oldVal) {
        getFilterColorConfig(this.dashboardFilterModel);
      },
    },
  },
  computed: {
    fetchParams() {
      if (this.dashboardFilterModel?.fieldQuery?.valueType === "tableField") {
        let componentName = this.dashboardFilterModel.fieldQuery.componentName;
        return {
          queryObject: {
            advanceQuery: [
              {
                key: this.dashboardFilterModel.fieldQuery.key,
                oper: componentName == "input_number" ? "eq" : "like", //数值组件不支持模糊搜索
                componentName,
                value: "",
              },
            ],
          },
        };
      }
    },
    ...mapState("area", ["areaTree", "areaProps"]),
    firstFilterField() {
      let firstFilterField = this.dashboardFilterModel.fields;
      if (firstFilterField && firstFilterField.length > 0) {
        return firstFilterField[0];
      }
      return {};
    },
    isMulti() {
      return (
        this.dashboardFilterModel.fieldQuery.componentName ===
          "select_checkbox" ||
        (this.dashboardFilterModel.fieldQuery.valueType == "tableField" &&
          ["in", "nin"].includes(this.dashboardFilterModel.fieldQuery.oper))
      );
    },
    /**
     * 是否显示默认值的值设置，当操作符为空和不为空时，隐藏设置
     */
    hideFilterValueSetting() {
      return ["nil", "nnil"].includes(
        this.dashboardFilterModel.fieldQuery.oper,
      );
    },
    areaTreeList() {
      return getTreeData(this.areaTree);
    },
    /**
     * 是否按地址或定位地址过滤
     */
    isUseAddressComponent() {
      const componentName = this.dashboardFilterModel.fields[0].componentName;
      return (
        componentName === "address_input" || componentName === "position_input"
      );
    },
    dataFilter() {
      const filterComps = this.filterComponentList.filter((comp) =>
        this.dashboardFilterModel.filterKeys.includes(comp.key),
      );
      return handleDataFilterSet(filterComps)[
        this.dashboardFilterModel.fields[0].collection
      ];
    },
  },
  methods: {
    changeFieldValueHandle() {
      this.clearLinkageFilterCompValue();
    },
    // 清除联动本组件的筛选组件的值
    clearLinkageFilterCompValue() {
      const keys = [];
      this.filterComponentList.forEach((comp) => {
        if (comp.filterKeys.includes(this.dashboardFilterModel.key)) {
          keys.push(comp.key);
        }
      });
      this.$emit("clearFilterCompsValue", keys);
    },
    getData() {
      console.log("dashboardFilter");
    },
    /**
     * 查询运算符发生改变时，需要初始化值
     * @param val
     */
    handleFieldQueryOperChange(val) {
      if (!val) {
        val = this.dashboardFilterModel.fieldQuery.oper;
      }
      if (["in", "nin"].includes(val)) {
        this.$set(this.dashboardFilterModel.fieldQuery, "value", []);
      } else if (["between"].includes(val)) {
        this.$set(this.dashboardFilterModel.fieldQuery, "value", [null, null]);
      } else if (["dynamic"].includes(val)) {
        this.$set(this.dashboardFilterModel.fieldQuery, "value", {
          timeMode: "custom",
          range: [],
        });
      } else {
        this.$set(this.dashboardFilterModel.fieldQuery, "value", "");
      }
    },
    getDisplayQueryOpers(componentName) {
      if (componentName) {
        let list = getComponentQueryOper(componentName);
        return list;
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-filter__container {
  width: 100%;
}
.input ::v-deep .el-date-editor {
  width: 100%;
}
::v-deep .el-input__inner {
  color: var(--js-filter-content-color);
  border: 1px solid var(--js-filter-border-color);
  background: var(--js-filter-background-color);
}
::v-deep .el-input.is-disabled .el-input__inner {
  color: var(--js-filter-content-color);
  border: 1px solid var(--js-filter-border-color);

  opacity: 0.8;
}
::v-deep .el-input__suffix {
  color: var(--js-filter-border-color);
}
::v-deep .el-select .el-input .el-select__caret {
  //#a5adba
  color: var(--js-filter-border-color);
}
</style>
