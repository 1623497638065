var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"视图预览","fullscreen":"","visible":_vm.visible,"width":"800px"},on:{"update:visible":function($event){_vm.visible=$event}}},[(_vm.form)?_c('div',{staticStyle:{"margin":"10px"}},[_c('el-form',{ref:"form",staticClass:"cust-form",attrs:{"label-position":_vm.form.extParam.labelPosition,"label-width":`${_vm.form.extParam.labelWidth}px`,"model":_vm.searchForm}},[_vm._l((_vm.searchFormView.components),function(column,index){return _c('el-form-item',{key:index,style:(`width:${100 / _vm.form.extParam.cols}%;`),attrs:{"label":_vm.form.extParam.showLabel ? column.title : ''}},[(
            column.field.componentName == 'input' ||
            column.field.componentName == 'input_number' ||
            column.field.componentName == 'text_area' ||
            column.field.componentName == 'sn_input'
          )?_c('el-input',{attrs:{"placeholder":column.field.placeHolder,"disabled":""}}):(column.field.componentName == 'date_picker')?_c('el-date-picker',{attrs:{"type":column.field.picker,"placeholder":column.field.placeHolder,"disabled":""}}):(column.field.componentName == 'address_input')?_c('el-cascader',{attrs:{"options":[],"disabled":"","placeholder":""}}):(column.field.componentName == 'department_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap",staticStyle:{"height":"32px"}},[_vm._v(" 选择部门 ")]):(column.field.componentName == 'department_list_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap"},[_vm._v(" 选择部门 ")]):(column.field.componentName == 'user_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap",staticStyle:{"height":"32px"}},[_vm._v(" 选择用户 ")]):(column.field.componentName == 'user_list_select')?_c('div',{staticClass:"outer-selected-user__empty-wrap"},[_vm._v(" 选择用户 ")]):(
            column.field.componentName == 'radio_group' ||
            column.field.componentName == 'checkbox_group' ||
            column.field.componentName == 'select' ||
            column.field.componentName == 'select_checkbox'
          )?_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":'',"placeholder":column.field.placeHolder,"disabled":""}},_vm._l((_vm.getOptions(column.field)),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1):_vm._e()],1)}),(_vm.searchFormView.components.length)?_c('div',{staticClass:"search-btn"},[(
            _vm.form.extParam.showLabel &&
            _vm.form.extParam.labelPosition == 'top' &&
            _vm.form.extParam.cols > 1
          )?_c('div',{staticStyle:{"height":"20px"}}):_vm._e(),_c('div',{staticClass:"btn-content"},[_c('el-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("查询")])],1)]):_vm._e()],2),(_vm.hasCreate)?_c('div',{staticClass:"right"},[_c('el-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("新增")])],1):_vm._e(),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"span-method":_vm.spanMethod}},[(_vm.form.showRowNumbers)?_c('el-table-column',{attrs:{"type":"index","width":"50","label":"序号"}}):_vm._e(),_vm._l((_vm.form.columns),function(col,index){return _c('el-table-column',{key:index,attrs:{"align":col.align,"label":col.title,"width":col.components && col.components.length && col.width
            ? col.width
            : '',"prop":col.components && col.components.length ? '' : col.field.name}},[(col.components && col.components.length)?_vm._l((col.components),function(subCol,sIndex){return _c('el-table-column',{key:sIndex,attrs:{"align":subCol.align,"label":subCol.title,"width":subCol.width ? subCol.width : '',"prop":subCol.field.name}})}):_vm._e()],2)}),(_vm.hasEdit || _vm.hasDelete)?_c('el-table-column',{attrs:{"type":"action","width":"120","label":"操作"}},[(_vm.hasEdit)?_c('el-button',{attrs:{"type":"text"}},[_vm._v("编辑")]):_vm._e(),(_vm.hasDelete)?_c('el-button',{attrs:{"type":"text"}},[_vm._v("删除")]):_vm._e()],1):_vm._e()],2),(_vm.form.hasPageTool)?_c('div',{staticClass:"right"},[_c('pagination',{attrs:{"total":_vm.page.totalRow,"page":_vm.page.pageNumber,"limit":_vm.form.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.page, "pageNumber", $event)},"update:limit":function($event){return _vm.$set(_vm.form, "pageSize", $event)},"pagination":() => {}}})],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }