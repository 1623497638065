import request from '@/utils/request'

import qs from "qs"
//ali-oss相关接口

/**
 * 获取oss-sts 临时授权的接口
 */
export function ossLoadCredential() {
    return request({
        url: '/oss/loadCredential',
        method: 'POST',
    })
}
