<template>
  <div>
    <div class="weui-col">
      <i
        @click="showAll = !showAll"
        v-if="
          ['json_form', 'reference_data'].includes(item.field.componentName)
        "
        :class="showAll ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
        style="font-size: 12px; margin-right: 5px"
      ></i>
      <div class="weui-title" :style="`color:${item.delete ? 'red' : ''}`">
        {{ item.title }} <span v-if="item.delete">(不存在)</span>
      </div>
      <div class="weui-col" style="font-size: 12px">
        <i class="iconfont icon-yidong" style="font-size: 12px"></i>
        <el-popover
          v-model="visible"
          trigger="click"
          @show="showField(item.title)"
          style="margin-right: 5px"
          width="300"
        >
          <i slot="reference" class="el-icon-edit"></i>
          <div class="row row-inline">
            <div style="font-size: 14px; width: 50px">显示名</div>
            <el-input
              v-model="colName"
              placeholder="请输入显示名称"
              style="width: 160px"
              size="mini"
            ></el-input>
          </div>
          <div class="row-end" style="margin-top: 10px">
            <el-button
              @click="
                colName = '';
                visible = false;
              "
              size="mini"
              style="margin-right: 5px"
              >取消
            </el-button>
            <el-button type="primary" @click="saveColName(-1)" size="mini"
              >确定
            </el-button>
          </div>
        </el-popover>

        <i @click="deleteCol" class="el-icon-delete"></i>
      </div>
    </div>
    <!-- 子表单 -->
    <div
      v-if="['json_form', 'reference_data'].includes(item.field.componentName)"
      v-show="showAll"
      style="margin-left: 30px"
    >
      <draggable
        v-model="item.subColumns"
        handle=".icon-yidong"
        animation="300"
      >
        <transition-group>
          <attr-form-field-item
            v-for="(child, cIndex) in item.subColumns"
            :key="child.field.name"
            :index="cIndex"
            :item="child"
            @deleteCol="deleteChild"
          ></attr-form-field-item>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  name: "attr-form-field-item",
  components: { Draggable },
  props: {
    item: Object,
    index: Number,
  },
  data() {
    return {
      showAll: false,
      visible: false,
      colName: "",
    };
  },
  methods: {
    deleteCol() {
      this.$emit("deleteCol", this.index);
    },
    deleteChild(index) {
      this.item.subColumns.splice(index, 1);
      this.$nextTick(() => {
        if (this.item.subColumns.length == 0) {
          this.deleteCol();
        }
      });
    },
    showField(title) {
      this.colName = title;
    },
    saveColName(childIndex) {
      if (this.colName == "") {
        this.$message.error("显示名称不能为空!");
        return;
      }
      this.$set(this.item, "title", this.colName);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-col {
  display: flex;
  align-items: center;

  .weui-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5;
  }
}
.row-inline {
  display: flex;
  align-items: center;

  &.row {
    .label {
      width: auto;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
}

.row {
  margin: 10px;

  .label {
    width: 100px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}
.row-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon-yidong {
  cursor: move;
}
</style>