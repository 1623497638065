<template>
  <div class="attr-row">
    <div class="attr-label">搜索设置</div>
    <el-checkbox v-model="component.keywordSearched">
      <span style="font-size: 12px">允许搜索</span>
    </el-checkbox>
  </div>
</template>
<script>
import { componentProps } from './util';
export default {
  name: "attr-search",
  props: componentProps
};
</script>