<template xmlns="http://www.w3.org/1999/html">
  <el-dialog
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    fullscreen
    @open="openDialog"
    @close="handleClose"
    :show-close="false"
  >
    <div slot="title" style="display: flex; flex-direction: row">
      <span style="flex: 1; display: flex; align-items: center"
        >编辑轮播图</span
      >
      <div>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </div>
    <div class="carousel-setting-content__wrap">
      <div class="carousel-images-container card-border">
        <draggable
          :list="carouselGlobalConfig.imageList"
          animation="300"
          handle=".drag-handle"
          class="drag-container"
        >
          <div
            v-for="(item, index) in carouselGlobalConfig.imageList"
            :key="item.imageUrl"
            class="carousel-images-container__item drag-handle"
            :class="
              item.imageUrl == carouselImageConfig.imageUrl ? 'active' : ''
            "
            @click="selectImage(item)"
          >
            <div class="carousel-images-container__item__img">
              <img
                :src="item.imageUrl"
                :alt="item.title"
                style="width: 100%; height: 100%; border-radius: 5px"
                :style="{ objectFit: carouselGlobalConfig.fillStyle }"
              />
            </div>
            <div class="carousel-images-container__item__action" @click.stop>
              <el-popconfirm
                title="确定移除图片？"
                @confirm="deleteImage(index)"
              >
                <i slot="reference" class="el-icon-delete"></i>
              </el-popconfirm>
            </div>
          </div>
          <template>
            <div
              class="carousel-images-container__item add-image"
              v-if="isOutOfUploadLimit"
              style="cursor: not-allowed"
            >
              <i class="el-icon-close"></i>不能上传更多，最多十张
            </div>
            <div class="carousel-images-container__item add-image" v-else>
              <upload
                :showFileList="false"
                @successSingle="uploadSuccessSingle"
                ref="upload"
                :multiple="true"
                accept=".jpg,.jpeg,.png,.gif"
                ><div>
                  <i class="el-icon-plus"></i>上传图片（{{
                    carouselGlobalConfig.imageList.length
                  }}/10）
                </div>
              </upload>
            </div>
          </template>
        </draggable>
      </div>
      <div class="card-border carousel-images-setting__wrap">
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
          <el-tab-pane label="设置" name="imageSetting"></el-tab-pane>
          <el-tab-pane label="全局配置" name="globalSetting"></el-tab-pane>
        </el-tabs>
        <div style="padding: 0px 20px">
          <template v-if="activeName == 'imageSetting'">
            <template v-if="carouselImageConfig.imageUrl">
              <el-form
                label-position="top"
                size="small"
                :rules="rules"
                :model="carouselImageConfig"
                ref="carouselImageConfigForm"
              >
                <el-form-item label="图片标题">
                  <el-input
                    v-model="carouselImageConfig.title"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-form-item label="触发操作">
                  <el-select
                    style="width: 100%"
                    v-model="carouselImageConfig.clickEvent"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in actionTypeEnums"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option> </el-select
                ></el-form-item>
                <el-form-item
                  label="链接地址"
                  v-if="isHrefEvent(carouselImageConfig)"
                  prop="eventConfig.url"
                >
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="carouselImageConfig.eventConfig.url"
                  >
                  </el-input
                ></el-form-item>
                <el-form-item v-if="isHrefEvent(carouselImageConfig)">
                  <el-checkbox
                    v-model="carouselImageConfig.eventConfig.isNewPage"
                    >新窗口打开</el-checkbox
                  >
                </el-form-item>
              </el-form>
            </template>
            <template v-else>
              <el-empty description="请从左侧选中一张图片"></el-empty>
            </template>
          </template>
          <template v-if="activeName == 'globalSetting'">
            <p>支持上传jpg、jpeg、png、git格式，单张5MB以内，最多10张。</p>
            <el-form label-position="top" size="small">
              <el-form-item label="填充方式">
                <el-select
                  v-model="carouselGlobalConfig.fillStyle"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in fillStyleEnums"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="其他设置">
                <el-checkbox v-model="carouselGlobalConfig.hiddenImageTitle"
                  >隐藏图片名称</el-checkbox
                >
                <br />
                <el-checkbox v-model="carouselGlobalConfig.extParam.autoplay"
                  >自动切换</el-checkbox
                >
                <br />
                <el-checkbox v-model="carouselGlobalConfig.extParam.loop"
                  >循环播放</el-checkbox
                >
              </el-form-item>
            </el-form>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import upload from "@/components/Upload/zgg-upload.vue";
import Draggable from "vuedraggable";
import test from "@/utils/test";

export default {
  name: "dashboard-carousel-edit",
  components: {
    upload,
    Draggable,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeName: "globalSetting",
      carouselImageConfig: {
        componentName: "carousel_view",
        imageUrl: "",
        title: "",
        clickEvent: "none",
        eventConfig: {
          type: "url",
          isNewPage: true,
          url: "",
        },
      },
      carouselGlobalConfig: {
        imageList: [],
        fillStyle: "cover",
        hiddenImageTitle: true,
        extParam: {
          autoplay: true,
          loop: true,
          card: false,
          interval: 3000,
        },
      },

      fillStyleEnums: [
        {
          value: "cover",
          label: "居中填满",
        },
        {
          value: "fill",
          label: "拉伸填满",
        },
        {
          value: "contain",
          label: "原图居中",
        },
      ],
      actionTypeEnums: [
        {
          value: "hrefEvent",
          label: "打开链接",
        },
        {
          value: "none",
          label: "无",
        },
      ],
      rules: {
        "eventConfig.url": [
          {
            type: "url",
            required: true,
            trigger: "change",
            message: "请输入正确的链接地址",
            // validator: validateTriggerUrl
          },
        ],
      },
    };
  },
  watch: {},
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    isOutOfUploadLimit() {
      return (
        this.carouselGlobalConfig &&
        this.carouselGlobalConfig.imageList &&
        this.carouselGlobalConfig.imageList.length >= 10
      );
    },
  },
  methods: {
    uploadSuccessSingle(attachment) {
      if (this.isOutOfUploadLimit) {
        this.$message.warning("最多只能上传10张");
        return;
      }
      this.uploadSuccess(attachment);
    },
    //切换到图片设置
    switchToImageSetting() {
      this.activeName = "imageSetting";
    },
    //切换到全局设置
    switchToGlobalSetting() {
      this.activeName = "globalSetting";
    },
    isHrefEvent(image) {
      return image.clickEvent == "hrefEvent";
    },
    manualInit(model) {
      console.log(model);
      this.dialogVisible = true;
      this.$set(this, "carouselGlobalConfig", model);
    },
    /**
     * 移除照片
     */
    deleteImage(index) {
      this.carouselGlobalConfig.imageList.splice(index, 1);
    },
    selectImage(image) {
      this.carouselImageConfig = image;
      this.switchToImageSetting();
      this.validateImageSetting();
    },
    validateImageSetting() {
      this.$nextTick(() => {
        this.$refs.carouselImageConfigForm.validate();
      });
    },
    uploadSuccess(file) {
      let carouselImageConfig = {
        imageUrl: file.url,
        title: file.originalName,
        clickEvent: "none",
        eventConfig: {
          type: "url",
          isNewPage: true,
          url: "",
        },
      };
      this.carouselGlobalConfig.imageList.push(carouselImageConfig);
      this.carouselImageConfig = carouselImageConfig;
      this.switchToImageSetting();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },

    confirm() {
      this.$emit("save", this.carouselGlobalConfig);
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      this.$nextTick(() => {
        Object.assign(this.$data, this.$options.data());
      });
    },
    openDialog() {},
  },
};
</script>
<style lang="scss" scoped>
$gap-size: 10px;
$header-height: 76px;

.carousel-setting-content__wrap {
  padding: $gap-size;
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{$header-height});
  background-color: #f2f6fc;
  ::v-deep {
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
  }
  .carousel-images-container {
    flex: 1;
    background-color: white;
    padding: 24px;
  }
  .drag-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: $gap-size;
  }
  .carousel-images-setting__wrap {
    width: 256px;
    margin-left: $gap-size;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
}
.carousel-images-container__item {
  width: 240px;
  height: 135px;
  background: #f2f6fc;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  &:hover {
    .carousel-images-container__item__action {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.active {
    img {
      border: 2px solid #409eff;
      border-radius: 5px;
    }
  }
}
.carousel-images-container__item__img {
  width: 100%;
  height: 100%;
  padding: 2px;
  border-radius: 5px;
}
.carousel-images-container__item__action {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  background: #a5adba;
  border-radius: 0px 5px;
  width: 30px;
  height: 22px;
  color: white;
}
.add-image {
  background: #ffffff;
  /* border/light */

  border: 1px solid #ebecf0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5adba;
  font-size: 14px;
  line-height: 24px;
  ::v-deep {
    .zgg-upload_container {
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .upload-container {
      width: 100%;
      height: 100%;

      .el-upload {
        width: 100%;
        height: 100%;

        .el-upload-dragger {
          border: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  &:hover {
    border: 2px solid #409eff;
    color: #409eff;
  }
}

.card-border {
  border-radius: 10px;
}
</style>
