<template>
  <div>
    <div class="content-layout between">
      <div class="label">{{ label }}</div>
      <div>
        <el-radio-group
          @change="changeEnum"
          v-model="backgroundEnum"
          size="mini"
        >
          <el-radio-button label="color">颜色</el-radio-button>
          <el-radio-button label="image">图片</el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <single-color-select
      v-if="backgroundEnum == 'color'"
      v-model="backgroundColor"
      @updateColor="updateBgColor"
      :background="backgroundColor"
      :isBackground="true"
    />
    <div v-if="backgroundEnum == 'image'">
      <div
        class="image-icon"
        v-if="backgroundImage"
        :style="{
          'background-image': 'url(' + backgroundImage + ')',
        }"
      >
        <div class="delete">
          <i @click="deleteImg()" class="el-icon-delete"></i>
        </div>
      </div>
      <el-upload
        v-if="!backgroundImage"
        class="upload-content"
        action=""
        :http-request="upload"
        :before-upload="beforeUpload"
        :auto-upload="true"
        accept=".jpg,.jpeg,.png"
      >
        <div class="upload-btn">上传图片，5M以内</div>
      </el-upload>

      <div class="content-layout between">
        <div class="label">填充方式</div>
        <div>
          <el-select
            style="width: 100%"
            v-model="backgroundImageFillEnum"
            placeholder="请选择"
            @change="changeFillEnum"
          >
            <el-option
              v-for="item in backgroundImageFillEnums"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import singleColorSelect from "@/views/lowCode/dashboard/detail-component/single-color-select";
import request from "@/utils/request";

export default {
  // mixins: [dashboardMixin],
  components: { singleColorSelect },
  props: {
    colorConfig: {
      type: Object,
      default: () => {
        return {
          backgroundEnum: "color",
          background: "",
          backgroundImageFillEnum: "stretch_fill",
        };
      },
    },
    label: {
      type: String,
      default: "卡片背景",
    },
  },

  data() {
    return {
      showS1: true,
      showS2: true,
      backgroundEnum: "color",
      background: "",
      backgroundImageFillEnum: "stretch_fill",
      backgroundImageFillEnums: [
        {
          value: "center_fill",
          label: "居中填满",
        },
        {
          value: "stretch_fill",
          label: "拉伸填充",
        },
      ],
      backgroundColor: "",
      backgroundImage: "",
    };
  },
  watch: {
    colorConfig() {
      this.backgroundImageFillEnum = this.colorConfig.backgroundImageFillEnum;
      this.backgroundEnum = this.colorConfig.backgroundEnum;
      if (this.colorConfig.backgroundEnum == "color") {
        this.backgroundColor = this.colorConfig.backgroundColor;
      } else {
        this.backgroundImage = this.colorConfig.backgroundImage;
      }
    },
  },
  created() {
    // this.background = this.colorConfig.background;
    this.backgroundImageFillEnum = this.colorConfig.backgroundImageFillEnum;
    this.backgroundEnum = this.colorConfig.backgroundEnum;
    if (this.colorConfig.backgroundEnum == "color") {
      this.backgroundColor = this.colorConfig.backgroundColor;
    } else {
      this.backgroundImage = this.colorConfig.backgroundImage;
    }
  },
  computed: {},
  methods: {
    deleteImg() {
      this.backgroundImage = "";
      this.$emit(
        "updateBgColor",
        "",
        this.backgroundEnum,
        this.backgroundImageFillEnum,
      );
    },
    changeEnum(val) {
      let background = "";
      if (val == "color") {
        background = this.backgroundColor;
      } else {
        background = this.backgroundImage;
      }
      this.backgroundEnum = val;
      this.$emit(
        "updateBgColor",
        background,
        val,
        this.backgroundImageFillEnum,
      );
    },
    closeSet() {
      this.$emit("closeSet");
    },
    changeFillEnum() {
      this.$emit(
        "updateBgColor",
        this.backgroundImage,
        this.backgroundEnum,
        this.backgroundImageFillEnum,
      );
    },
    // setImageIcon(url) {
    //   const iconStyle = { type: "ICON_URL", icon: url };
    //   this.$emit("updateIcon", iconStyle);
    // },
    beforeUpload(file) {
      if (file.size > 5120000) {
        this.$message({
          message: "请选择5MB以内的jpg、png、jpeg或gif图",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    upload(uploadDetail) {
      let params = new FormData();
      params.append("attachmentFile", uploadDetail.file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      request({
        url: "/attachment/upload",
        method: "post",
        headers: config.headers,
        data: params,
      })
        .then((resp) => {
          if (resp.code === 0) {
            this.backgroundImage = resp.data.attachment.url;
            this.$emit(
              "updateBgColor",
              this.backgroundImage,
              this.backgroundEnum,
              this.backgroundImageFillEnum,
            );
          } else {
            this.$message({
              message: "上传失败",
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            message: "上传出错，服务器开小差了呢",
            type: "error",
          });
        });
    },
    updateBgColor(background) {
      this.backgroundColor = background;
      this.$emit("updateBgColor", background, this.backgroundEnum);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
}
.row {
  padding: 10px;
  .label {
    font-size: 13px;
    min-width: 32%;
  }
}
.title-div {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px -1px 0px 0px #ebecf0 inset;
  font-size: 14px;
}
.pointer {
  cursor: pointer;
}
.font-24 {
  font-size: 18px;
}
.title-label {
  padding: 20px 0 10px 0;
  font-weight: bold;
}
.content-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 12px 0;
  align-items: center;
}
.between {
  justify-content: space-between;
}
.upload-content {
  .upload-btn {
    border-radius: 5px;
    border: 1px dashed var(--zgg-border-base);
    background: var(--white);
    display: block;
    font-size: 13px;
    line-height: 24px;
    color: var(--zgg-font-text-regular);
    padding: 4px 0;
    text-align: center;
  }

  ::v-deep .el-upload {
    display: block;
  }
  ::v-deep .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 24px;
  }
}
.image-icon {
  width: 100%;
  height: 54px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.delete {
  border-radius: 0px 0px 0px 5px;
  position: absolute;
  background-color: var(--zgg-font-text-placeholder);
  right: 0px;
  text-align: center;
  cursor: pointer;
  .el-icon-delete {
    color: #fff;
    font-weight: 400;
    padding: 4px 8px;
  }
}
</style>
