<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 68px;
    "
  >
    <div class="label">流程设置</div>
    <div class="description"></div>
    <div class="content" style="display: flex; align-items: center">
      <div style="height: 44px; line-height: 44px">表单右侧流程动态顺序</div>
      <div style="margin-left: 20px" v-loading="loading">
        <el-radio-group v-model="sortRule" @input="changeSort">
          <el-radio label="earliestFirst">按发起节点顺序显示</el-radio>
          <el-radio label="latestFirst">按最新审批节点顺序显示</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import { getLogSortRule, saveLogSortRule } from "@/api/operationCenter";
export default {
  computed: {
    companyName() {
      return this.$store.getters.currentCompanyName;
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
  },
  data() {
    return {
      sortRule: "",
      loading: false,
    };
  },
  async created() {
    await getLogSortRule({
      companyId: this.companyId,
    }).then((res) => {
      this.sortRule = res.data.sortRule;
    });
  },
  methods: {
    changeSort(val) {
      this.loading = true;
      saveLogSortRule({
        companyId: this.companyId,
        sortRule: val,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("修改成功");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--font-text-primary, #172b4d);
}
.content {
  margin-top: 12px;
  padding: 16px;
  border-radius: 10px;
  background: #fff;
}
.description {
  font-size: 14px;
  line-height: 24px;
  color: #6b778c;
}
</style>
