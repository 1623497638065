<template>
  <div class="trigger">
    <div class="crumb">工作表<span>{{node.collectionTitle}}</span></div>
    <div class="title">按钮</div>
    <div class="content">
        <div>{{ node.triggerTitle }}</div>
        <div style="color: #999999">点击按钮后立即执行</div>
    </div>
  </div>
</template>

<script>
export default {
    name: "rpa-trigger-click",
    props: {
        node: Object,
        disabled: Boolean,
    },
    data() {
        return {};
    },
    methods: {
        
    },
}
</script>
<style lang="scss" scoped>
.trigger{
    .crumb{
        font-size: 14px;
        margin-bottom: 20px;
        span{
            margin-left: 10px;
            font-weight: bold;
        }
    }
    .title{
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content{
        font-size: 14px;
        padding: 10px;
        line-height: 22px;
        background-color: #f4f5f7;
        border-radius: 4px;
        border:#e9e8e8 solid 1px;
    }
}
</style>