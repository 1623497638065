<template>
  <div>
    <div class="header flex-between">
      <div style="padding: 0 10px">
        <el-form
          :rules="rules"
          ref="form"
          :model="view.extParam"
          label-width="80px"
        >
          <el-form-item
            label="图表名称"
            :show-message="false"
            style="margin-bottom: 0px"
            prop="title"
          >
            <el-input
              v-model="view.extParam.title"
              placeholder="请输入图表名称"
              size="small"
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex-end">
        <el-button size="mini" @click="close">关闭 </el-button>
        <el-button size="mini" @click="saveForm" type="primary" class="mr"
          >保存
        </el-button>
      </div>
    </div>
    <div class="container">
      <source-list
        v-if="view"
        ref="sourceList"
        :formId="view.dataSource.formId"
        :onMove="onMove"
        :activeName="componentName == 'table' ? 'table' : 'form'"
        :use-type="componentName"
        @changeSource="changeSource"
        @initData="getFormList"
        :switchAble="switchAble"
        viewType="dashboard"
        :isDetailTable="componentName == 'table' ? true : false"
      ></source-list>
      <div class="content">
        <div v-if="view" class="form-body">
          <div class="body-pivot">
            <!-- 维度(行) -->
            <div
              class="pivot-item"
              style="margin: 10px"
              v-if="componentName != 'chart_process'"
            >
              <div class="col-h">
                {{ componentName == "table" ? "显示字段" : "维度（行）" }}
              </div>
              <div class="col-d">
                <draggable
                  class="col-container"
                  :list="view.xFields"
                  :group="colGroup2"
                  name="xFields"
                  animation="300"
                  @add="addXFields"
                  @sort="sortXFields"
                >
                  <el-tooltip
                    v-for="(item, index) in view.xFields"
                    :key="index"
                    effect="dark"
                    :content="item.field.comment"
                    placement="top"
                  >
                    <el-tag
                      class="m"
                      closable
                      @close="closeXFields(index, item)"
                      @click="checkXFields(item)"
                      :type="calFieldStatus('xFields', currNode, item).type"
                      :effect="calFieldStatus('xFields', currNode, item).effect"
                      >{{ item.title }}
                    </el-tag>
                  </el-tooltip>
                </draggable>
              </div>
            </div>
            <!-- 维度(列) -->
            <div
              class="pivot-item"
              style="margin: 10px"
              v-if="componentName == 'pivot_table'"
            >
              <div class="col-h">
                {{ componentName == "table" ? "显示字段" : "维度（列）" }}
              </div>
              <div class="col-d">
                <draggable
                  class="col-container"
                  :list="view.yFields"
                  :group="colGroup2"
                  name="yFields"
                  animation="300"
                  @add="addYFields"
                  @sort="sortYFields"
                >
                  <el-tooltip
                    v-for="(item, index) in view.yFields"
                    :key="index"
                    effect="dark"
                    :content="item.field.comment"
                    placement="top"
                  >
                    <el-tag
                      class="m"
                      closable
                      @close="closeYFields(index, item)"
                      @click="checkYFields(item)"
                      :type="calFieldStatus('yFields', currNode, item).type"
                      :effect="calFieldStatus('yFields', currNode, item).effect"
                      >{{ item.title }}
                    </el-tag>
                  </el-tooltip>
                </draggable>
              </div>
            </div>
            <!-- 指标 -->
            <div
              class="pivot-item"
              v-if="!['table', 'chart_multi_axes'].includes(componentName)"
              style="margin: 10px"
            >
              <div class="col-h">指标</div>
              <div class="col-d">
                <draggable
                  class="col-container"
                  :list="view.metrics"
                  :group="colGroup2"
                  name="metrics"
                  animation="300"
                  @add="addMetrics"
                  @sort="sortMetrics"
                >
                  <el-tooltip
                    v-for="(item, index) in metrics"
                    :key="index"
                    effect="dark"
                    :content="item.field.comment"
                    placement="top"
                  >
                    <el-tag
                      class="m"
                      closable
                      @close="closeMetrics(index)"
                      @click="checkMetrics(item)"
                      :type="calFieldStatus('metrics', currNode, item).type"
                      :effect="calFieldStatus('metrics', currNode, item).effect"
                      >{{ item.title }}({{ getOperStr(item) }})
                    </el-tag>
                  </el-tooltip>
                </draggable>
              </div>
            </div>
            <!-- 双轴图指标 -->
            <template v-if="componentName == 'chart_multi_axes'">
              <div class="pivot-item" style="margin: 10px">
                <div class="col-h">指标(左)</div>
                <div class="col-d">
                  <draggable
                    class="col-container"
                    v-model="metricsLeft"
                    :group="colGroup2"
                    name="metrics"
                    animation="300"
                  >
                    <el-tooltip
                      v-for="(item, index) in metricsLeft"
                      :key="index"
                      effect="dark"
                      :content="item.field.comment"
                      placement="top"
                    >
                      <el-tag
                        class="m"
                        closable
                        @close="closeMultiAxesMetrics(item)"
                        @click="checkMetrics(item)"
                        :type="calFieldStatus('metrics', currNode, item).type"
                        :effect="
                          calFieldStatus('metrics', currNode, item).effect
                        "
                        >{{ item.title }}({{ getOperStr(item) }})
                      </el-tag>
                    </el-tooltip>
                  </draggable>
                </div>
              </div>
              <div class="pivot-item" style="margin: 10px">
                <div class="col-h">指标(右)</div>
                <div class="col-d">
                  <draggable
                    class="col-container"
                    v-model="metricsRight"
                    :group="colGroup2"
                    name="metrics"
                    animation="300"
                  >
                    <el-tooltip
                      v-for="(item, index) in metricsRight"
                      :key="index"
                      effect="dark"
                      :content="item.field.comment"
                      placement="top"
                    >
                      <el-tag
                        class="m"
                        closable
                        @close="closeMultiAxesMetrics(item)"
                        @click="checkMetrics(item)"
                        :type="calFieldStatus('metrics', currNode, item).type"
                        :effect="
                          calFieldStatus('metrics', currNode, item).effect
                        "
                        >{{ item.title }}({{ getOperStr(item) }})
                      </el-tag>
                    </el-tooltip>
                  </draggable>
                </div>
              </div>
            </template>
            <!-- 目标值 -->
            <div
              class="pivot-item"
              v-if="componentName == 'chart_process'"
              style="margin: 10px"
            >
              <div class="col-h">目标值</div>
              <div class="col-d" @click="editTargetValue">
                <el-input
                  @blur="addTargetValue"
                  v-model="fixedValue"
                  ref="targetInput"
                  placeholder="请输入数字"
                  type="number"
                  style="height: 50px; line-height: 50px"
                  v-if="view.targetValues.length < 1 && editStatus"
                ></el-input>
                <draggable
                  v-else
                  class="col-container col-container-empty"
                  :list="view.targetValues"
                  :group="colGroup2"
                  name="targetValues"
                  animation="300"
                  @add="addTargetValues"
                >
                  <el-tooltip
                    v-for="(item, index) in view.targetValues"
                    :key="index"
                    effect="dark"
                    :content="item.title"
                    placement="top"
                  >
                    <el-tag
                      class="m"
                      closable
                      @close="closeTargetValues(index)"
                      @click="checkTargetValues(item)"
                      :type="
                        calFieldStatus('targetValues', currNode, item).type
                      "
                      :effect="
                        calFieldStatus('targetValues', currNode, item).effect
                      "
                      >{{ item.title }}({{
                        item.targetType == "fixedValue"
                          ? item.maxFixedValue
                          : "计数"
                      }})
                    </el-tag>
                  </el-tooltip>
                </draggable>
              </div>
            </div>
            <div class="pivot-item" style="margin: 10px">
              <div class="col-h">过滤条件</div>
              <div class="col-d">
                <draggable
                  class="col-container"
                  :list="view.dataSource.queryObject.advanceQuery"
                  :group="colGroup2"
                  name="filter"
                  animation="300"
                  @add="addFilter"
                  @sort="sortFilter"
                >
                  <el-tag
                    v-for="(item, index) in view.dataSource.queryObject
                      .advanceQuery"
                    :key="index"
                    class="m"
                    closable
                    @close="closeFilter(index)"
                    @click="checkFilter(item)"
                    >{{ item.title }}
                  </el-tag>
                </draggable>
              </div>
            </div>
          </div>
          <div class="body-chart" :style="colorConfigStyle(view)">
            <div class="dash-header">
              <div
                class="title"
                v-if="!view.hiddenTitle"
                :style="`color:${view.colorConfig.titleColor}`"
              >
                {{ view.extParam.title }}
              </div>
            </div>
            <div style="height: 90%">
              <metrics
                v-if="componentName == 'metric_table'"
                :view="view"
                ref="chart"
              ></metrics>
              <chart-column
                v-else-if="
                  componentName == 'chart_column' &&
                  view.xFields.length &&
                  view.metrics.length
                "
                ref="chart"
              ></chart-column>
              <chart-bar
                v-else-if="
                  componentName == 'chart_bar' &&
                  view.xFields.length &&
                  view.metrics.length
                "
                ref="chart"
              ></chart-bar>
              <chart-line
                v-else-if="
                  componentName == 'chart_line' &&
                  view.xFields.length &&
                  view.metrics.length
                "
                ref="chart"
              ></chart-line>
              <chart-area
                v-else-if="
                  componentName == 'chart_area' &&
                  view.xFields.length &&
                  view.metrics.length
                "
                ref="chart"
              ></chart-area>
              <chart-pie
                v-else-if="
                  componentName == 'chart_pie' &&
                  view.xFields.length &&
                  view.metrics.length
                "
                ref="chart"
                :componentName="componentName"
              ></chart-pie>
              <chart-radar
                v-else-if="
                  componentName == 'chart_radar' &&
                  view.xFields.length &&
                  view.metrics.length
                "
                ref="chart"
              >
              </chart-radar>
              <div
                v-else-if="
                  componentName == 'pivot_table' &&
                  (view.xFields.length || view.yFields.length) &&
                  view.metrics.length
                "
              >
                <pivot-table ref="chart"></pivot-table>
              </div>
              <chart-process
                ref="chart"
                v-else-if="
                  componentName == 'chart_process' &&
                  view.targetValues.length &&
                  view.metrics.length
                "
                :componentName="componentName"
              ></chart-process>
              <chart-multi-axes
                ref="chart"
                v-else-if="
                  componentName == 'chart_multi_axes' &&
                  view.xFields.length &&
                  view.metrics.length
                "
              ></chart-multi-axes>
              <chart-map
                ref="chart"
                v-else-if="componentName == 'chart_map'"
                :view="view"
              ></chart-map>
              <div v-else-if="componentName == 'table' && view.xFields.length">
                <detail-table ref="chart"></detail-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="attribute-box">
        <div class="attribute-scroll">
          <chart-attribute
            v-if="view"
            :componentName.sync="componentName"
            :data="currNode"
            :nodeType="nodeType"
            :pageSize.sync="view.dataSource.queryObject.pageSize"
            :view.sync="view"
            @changeOper="changeOper"
            @changeSort="changeSort"
            @setPageTool="setPageTool"
            @resetChartColumn="resetChartColumn"
            @changeBar="changeBar"
            @resizeChart="resizeChart"
            @changeViewType="changeViewType"
          ></chart-attribute>
        </div>

        <chart-field-setting
          v-if="visible"
          :key="fieldUuid"
          :componentName.sync="componentName"
          :visible.sync="visible"
          :data.sync="currNode"
          :view.sync="view"
          :nodeType="nodeType"
          @changeOper="changeOper"
          @changeSort="changeSort"
          @clearSort="clearSort"
          @refershData="refershData"
          :getXFieldData="getXFieldData"
        ></chart-field-setting>
      </div>
      <filterSetting
        v-if="filterDialogVisible"
        :dialog-visible.sync="filterDialogVisible"
        :current-filter-node="currentFilter"
        :form-list="formList"
        :view="view"
        @changeOper="changeOper"
      />
    </div>
  </div>
</template>
<script>
import SourceList from "@/views/lowCode/view/components/Source";
import Draggable from "vuedraggable";
import chartAttribute from "@/views/lowCode/view/components/chartAttribute";
import metrics from "@/views/lowCode/view/components/metrics";
import chartColumn from "@/views/lowCode/view/components/chartColumn";
import chartBar from "@/views/lowCode/view/components/chartBar";
import chartLine from "@/views/lowCode/view/components/chartLine";
import chartArea from "@/views/lowCode/view/components/chartArea.vue";
import chartPie from "./components/chartPie";
import chartRadar from "@/views/lowCode/view/components/chartRadar.vue";
import pivotTable from "./components/pivotTable";
import chartProcess from "@/views/lowCode/view/components/chartProcess.vue";
import chartMultiAxes from "@/views/lowCode/view/components/chartMultiAxes.vue";
import filterSetting from "@/views/lowCode/view/components/filterSetting";
import chartFieldSetting from "@/views/lowCode/view/components/chart-field-setting";

import chartMap from "./components/chartMap";
import detailTable from "./components/detailTable";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import { chartFilterFields, metaFields } from "@zgg-core-utils/whiteList";
import { getColorConfigStyle } from "@/views/lowCode/view/components/chartUtil";
import { uuid } from "@/zgg-core/utils";
export default {
  components: {
    SourceList,
    Draggable,
    chartAttribute,
    metrics,
    chartColumn,
    chartBar,
    chartLine,
    chartArea,
    chartPie,
    chartRadar,
    pivotTable,
    chartProcess,
    chartMultiAxes,
    filterSetting,
    chartMap,
    detailTable,
    chartFieldSetting,
  },

  props: {
    viewId: [Number, String],
    closeFun: Function,
    appView: Object,
    /**
     * 是否允许切换数据源
     */
    switchAble: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    metricsRight: {
      get() {
        if (this.view.metrics.length > 0) {
          return this.view.metrics.filter((row) => row.metricType == "right");
        }
        return [];
      },
      set(val, old) {
        if (old && (old.length == val.length || old.length > val.length))
          return;
        let metrics = this._.cloneDeep(val);
        metrics.forEach((item) => {
          if (isEmpty(item.name)) {
            item.name = this.getUuid();
            item.metricType = "right";
            item.field.comment = item.field.component.title;
            if (!item.oper) {
              item.oper = "count";
            }
            if (
              isEmpty(item.numberFormat) &&
              item.field.componentName == "input_number"
            ) {
              let numberFormat = {
                numberFormatType:
                  item.field.format != "percent" ? "number" : "percentage",
                thousandsSeparator: item.field.thousandsSeparator, // 显示千分位
                numberMagnitude: "empty", //数量级
                decimal: item.field.decimalPrecision, //小数位数
                unit: "",
              };
              item.numberFormat = numberFormat;
            }
          }
        });
        this.$set(this.view, "metrics", [...metrics, ...this.metricsLeft]);
        this.changeViewType();
      },
    },
    metricsLeft: {
      get() {
        if (this.view.metrics.length > 0) {
          return this.view.metrics.filter((row) => row.metricType == "left");
        }
        return [];
      },
      set(val, old) {
        if (old && (old.length == val.length || old.length > val.length))
          return;
        let metrics = this._.cloneDeep(val);

        metrics.forEach((item) => {
          if (isEmpty(item.name)) {
            item.name = this.getUuid();
            item.metricType = "left";
            item.field.comment = item.field.component.title;
            if (!item.oper) {
              item.oper = "count";
            }

            if (
              isEmpty(item.numberFormat) &&
              item.field.componentName == "input_number"
            ) {
              let numberFormat = {
                numberFormatType:
                  item.field.format != "percent" ? "number" : "percentage",
                thousandsSeparator: item.field.thousandsSeparator, // 显示千分位
                numberMagnitude: "empty", //数量级
                decimal: item.field.decimalPrecision, //小数位数
                unit: "",
              };
              item.numberFormat = numberFormat;
            }
          }
        });
        this.$set(this.view, "metrics", [...metrics, ...this.metricsRight]);
        this.changeViewType();
      },
    },
    metrics() {
      if (this.componentName == "chart_process") {
        if (
          this.view.metrics.length > 1 ||
          (this.view.metrics.length > 0 &&
            (this.view.targetValues.length < 1 ||
              this.view.targetValues[0]?.targetType == "fixedValue"))
        ) {
          return [this.view.metrics[0]];
        }
        return [];
      }
      return this.view.metrics;
    },
  },
  async created() {
    let view = this._.cloneDeep(this.appView);
    if (!view.extParam) {
      view.extParam = {};
    }
    if (!view.colorConfig) {
      view.colorConfig = {
        backgroundEnum: "color", //背景样式类型:图片填充image、颜色填充color
        backgroundColor: "",
        backgroundImage: "",
        backgroundImageFillEnum: "stretch_fill", //背景图片填充类型:居中填满center_fill、拉伸填充stretch_fill
        titleColor: "", //标题颜色
        contentColor: "", //内容颜色
      };
    } else {
      if (!view?.colorConfig?.backgroundEnum) {
        view.colorConfig.backgroundEnum = "color"; //背景样式类型:图片填充image、颜色填充color
        view.colorConfig.backgroundImageFillEnum = "stretch_fill"; //背景图片填充类型:居中填满center_fill、拉伸填充stretch_fill
      }
      if (!view?.colorConfig?.backgroundColor) {
        view.colorConfig.backgroundColor = "";
      }
      if (!view?.colorConfig?.backgroundImage) {
        view.colorConfig.backgroundImage = "";
      }
      if (!view?.colorConfig?.titleColor) {
        view.colorConfig.titleColor = "";
      }
      if (!view?.colorConfig?.contentColor) {
        view.colorConfig.contentColor = "";
      }
    }
    if (view.componentName == "metric_table") {
      if (view.backgroundColor && view.colorConfig.backgroundColor == "") {
        view.colorConfig.backgroundColor = view.backgroundColor;
      }
      if (view.valueColor && view.colorConfig.contentColor == "") {
        view.colorConfig.contentColor = view.valueColor;
      }
      if (!view.style) {
        view.style = {
          fontStyleEnum: "scale",
          fontScaleEnum: "big",
          valueFontSize: "48",
          lableFontSize: "16",
          descriptionColor: "", //描述文字颜色
          textAlign: "center", //指标图对齐
        };
      } else {
        if (!view?.style?.descriptionColor) {
          view.style.descriptionColor = view.colorConfig.contentColor;
        }
        if (!view?.style?.textAlign) {
          view.style.textAlign = "center";
        }
      }
    }
    if (
      view.componentName == "table" ||
      view.componentName == "pivot_table" ||
      view.componentName == "gantt"
    ) {
      if (!view.colorConfig.headColor) {
        view.colorConfig.headColor = ""; //表头文字颜色
      }

      if (!view.colorConfig.borderColor) {
        view.colorConfig.borderColor = ""; //线框颜色
      }
      if (!view.colorConfig.headBgColor && view.colorConfig.borderColor) {
        view.colorConfig.headBgColor = view.colorConfig.borderColor; //表头背景颜色
      }
      if (!view.colorConfig.bodyColor) {
        view.colorConfig.bodyColor = ""; //表格背景颜色
      }
      if (!view.colorConfig.enableStripe) {
        view.colorConfig.enableStripe = false; //是否开启斑马线
      }
      if (!view.colorConfig.stripeColor) {
        view.colorConfig.stripeColor = ""; //斑马线颜色
      }
    }
    if (view.componentName == "table") {
      if (!view.hasPageTool) {
        view.hasPageTool = true;
      }
      if (!view.xFields) {
        view.xFields = view.columns;
      }
      if (!view.metrics) {
        view.metrics = [];
      }
      if (!view.showRowNumbers) {
        view.showRowNumbers = false;
      }
      if (!view.actionItems) {
        view.export = false;
        view.preview = false;
        view.actionItems = [];
      } else {
        if (view.actionItems.length > 0) {
          view.actionItems.forEach((item) => {
            if (item.title == "导出") {
              view.export = true;
            }
            if (item.title == "查看") {
              view.preview = true;
            }
          });
        } else {
          view.export = false;
          view.preview = false;
        }
      }
    }
    this.initQueryObject(view);
    if (!view.chartMarker) {
      view.chartMarker = {
        curve: false,
        symbol: "circle",
      };
    }
    if (!view.pieType) {
      view.pieType = "";
    }

    if (view.componentName == "chart_map") {
      if (!view.mapRange) {
        view.mapRange = {
          provinceId: "",
          province: "",
          cityId: "",
          city: "",
          countyId: "",
          county: "",
        };
      }
      if (!view.mapType) {
        view.mapType = "area";
      }
      if (!view.mapDrill) {
        view.mapDrill = "city";
      }
    }
    if (!view.chartLabel) {
      view.chartLabel = {
        displayXField: true,
        displayMetric: false,
        displayMetricPercent: true,
        enable: true,
        displayTarget: false,
        decimalPrecision: 2,
        content: "进度",
      };
    }
    this.componentName = this.appView.componentName;
    this.resetChartColumn(view);

    this.changeOper();
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "请输入图表名称" }],
      },
      view: null,
      componentName: "",
      componentList: [],
      nodeType: "",
      currNode: null,
      colGroup2: {
        name: "column",
        pull: false,
      },
      currentFilter: null,
      filterDialogVisible: false,
      formList: [],
      showMessage: false,
      fixedValue: "",
      editStatus: false,
      targetValue: "",
      visible: false,
      fieldUuid: "",
    };
  },
  methods: {
    addTargetValues() {
      let targetValue = this._.cloneDeep(this.view.targetValues)[0];
      let obj = {
        name: targetValue.field?.name,
        title: targetValue.title,
        type: targetValue.field?.componentName,
        targetType: "fieldValue",
        oper: "count",
      };
      targetValue.name = this.getUuid();
      this.view.metrics.push(targetValue);
      this.$set(this.view, "targetValues", [obj]);
      this.changeBar();
      this.changeFieldKey(false);
    },
    closeTargetValues(index) {
      if (this.view.targetValues[0].targetType == "fieldValue") {
        this.view.metrics.pop();
      }
      this.view.targetValues.splice(index, 1);
      this.changeViewType();
      this.changeFieldKey(false);
    },
    checkTargetValues(item) {
      this.nodeType = "targetValues";
      this.currNode = item;
      this.changeFieldKey();
    },
    addTargetValue() {
      this.editStatus = false;
      if (!/^(\-|\+)?\d+(\.\d+)?$/g.test(this.fixedValue)) return;
      let obj = {
        name: uuid(),
        title: "目标值",
        type: "number",
        targetType: "fixedValue",
        maxFixedValue: parseFloat(this.fixedValue),
        minFixedValue: 0,
      };
      this.fixedValue = "";
      this.$set(this.view, "targetValues", [obj]);
      this.changeBar();
    },
    editTargetValue() {
      this.editStatus = true;
      this.$nextTick(() => {
        this.$refs.targetInput?.focus();
      });
    },
    /**
     * 计算仪表盘组件的容器背景色
     * @param colorConfig
     * @returns {{}}
     */
    colorConfigStyle(view) {
      let style = getColorConfigStyle(view);
      return style;
    },
    getXFieldData() {
      return this.$refs?.chart?.list || [];
    },
    //获取字段是否处于选择状态,改变tag显示状态
    calFieldStatus(sense, currentNode, itemNode) {
      let status = {
        type: "",
        effect: "light",
      };

      if (currentNode) {
        let currentFieldName = "";
        let itemFieldName = "";
        if (sense == "metrics") {
          currentFieldName = currentNode.name;
          itemFieldName = itemNode.name;
        }
        if (sense == "xFields") {
          currentFieldName = currentNode.field.name;
          itemFieldName = itemNode.field.name;
        }
        if (sense == "yFields") {
          currentFieldName = currentNode.field.name;
          itemFieldName = itemNode.field.name;
        }

        if (this.nodeType == sense && currentFieldName == itemFieldName) {
          status.type = "primary";
          status.effect = "dark";
        }
      }

      return status;
    },
    getComponentByName(name, list) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i].field ? list[i].field : list[i];
        if (item.name == name) {
          return item;
        } else if (item.container) {
          // 如果是容器组件: 表格, 表单
          let item2 = this.getComponentByName(name, item.components);
          if (item2) {
            return item2;
          }
        }
      }
    },

    getFormList(formList) {
      this.formList = formList;
    },
    closeFilter(index) {
      this.view.dataSource.queryObject.advanceQuery.splice(index, 1);
      this.changeOper();
    },
    checkFilter(item) {
      this.nodeType = "advanceQuery";
      item.field = {
        component: this.getComponentByName(item.key, this.formList),
      };
      this.currentFilter = item;
      this.currNode = null;
      if (this.currentFilter.valueContainsCurrentUser) {
        if (
          ["containAny", "contain", "nin", "in"].includes(
            this.currentFilter.oper,
          )
        ) {
          this.currentFilter.value.unshift("valueContainsCurrentUser");
        } else {
          this.currentFilter.value = "valueContainsCurrentUser";
        }
      }
      this.filterDialogVisible = true;
    },
    sortFilter() {
      this.view.dataSource.queryObject.advanceQuery = this._.cloneDeep(
        this.view.dataSource.queryObject.advanceQuery,
      );
      // if (this.componentName != "metric_table") {
      //     this.changeBar();
      // }
    },
    addFilter(node) {
      let newIndex = node.newIndex;
      this.changeArrayList(node, this.view.dataSource.queryObject.advanceQuery);
      let advanceQuery = this._.cloneDeep(
        this.view.dataSource.queryObject.advanceQuery,
      );
      let newKey = advanceQuery[newIndex].field.name;
      let newTitle = advanceQuery[newIndex].field.title;
      // let newKeyIndex = advanceQuery.findIndex(item => {
      //     return item.key == newKey
      // })
      delete advanceQuery[node.newIndex].oper;
      advanceQuery[newIndex].field.comment = newTitle;
      advanceQuery[newIndex].key = newKey;
      advanceQuery[newIndex].value = "";
      advanceQuery[newIndex].title = newTitle;
      advanceQuery[newIndex].componentName =
        advanceQuery[newIndex].field.componentName;
      advanceQuery[newIndex].picker = advanceQuery[newIndex].field.picker;
      this.view.dataSource.queryObject.advanceQuery = advanceQuery;
    },
    /**
     * 初始化过滤条件
     * 目前因为新建完视图之后，服务端并没有对queryObject的数据进行初始化，为了前端报错，对数据进行初始化；
     * eg:
     * "queryObject": {
     *     "pageNumber": 1,
     *     "pageSize": 20,
     *     "sortParam": {
     *         "updatedTime": "desc",
     *         "_id": "asc"
     *     },
     *     "advanceQuery": [{
     *            “key":"chatId",
     *            "oper":"eq",
     *            "value":群id
     *       } ]
     *   }
     * @param view
     */
    initQueryObject(view) {
      if (!view.dataSource.queryObject) {
        let queryObject = {
          pageSize: "",
          advanceQuery: [],
        };
        this.$set(view.dataSource, "queryObject", queryObject);
      }
    },
    close() {
      if (typeof this.closeFun === "function") {
        this.closeFun();
      } else {
        this.$router.back();
      }
    },
    resetChartColumn(obj) {
      let view;
      if (obj) {
        view = this._.cloneDeep(obj);
      } else {
        view = this._.cloneDeep(this.view);
      }
      let arr = [
        "chart_column",
        "chart_bar",
        "chart_line",
        "chart_pie",
        "chart_radar",
        "chart_area",
        "chart_multi_axes",
      ];
      if ([...arr, "chart_map", "chart_process"].includes(this.componentName)) {
        if (!view.chartLabel) {
          view.chartLabel = {
            displayXField: true,
            displayMetric: false,
            displayMetricPercent: true,
            enable: true,
            displayTarget: false,
            decimalPrecision: 2,
            content: "进度",
          };
        }
      } else {
        view.chartLabel = null;
      }
      if (arr.includes(this.componentName)) {
        if (
          ["chart_bar", "chart_pie", "chart_radar"].includes(this.componentName)
        ) {
          view.xAxis = null;
        } else {
          if (!view.xAxis) {
            view.xAxis = {
              rotation: "",
              step: false,
            };
          }
        }

        if (
          !["chart_line", "chart_area", "chart_multi_axes"].includes(
            this.componentName,
          )
        ) {
          view.chartMarker = null;
        } else {
          if (!view.chartMarker) {
            view.chartMarker = {
              curve: false,
              symbol: "circle",
            };
          }
        }

        if (this.componentName == "chart_pie") {
          view.yAxis = null;
          if (!view.pieType) {
            view.pieType = "solid";
          }
        } else {
          view.pieType = "";
          if (!view.yAxis) {
            view.yAxis = {
              title: "",
              max: "",
              min: "",
            };
          }
        }
        if (!view.chartLegend) {
          view.chartLegend = {
            enable: false,
            position: "bottom",
          };
        }
      } else {
        view.xAxis = null;
        view.yAxis = null;
        view.chartLegend = null;
      }
      //设置坐标轴系图表的轴标题位置信息
      if (
        [
          "chart_column",
          "chart_line",
          "chart_area",
          "chart_bar",
          "chart_multi_axes",
        ].includes(this.componentName)
      ) {
        if (!view.hasOwnProperty("padding")) {
          this.$set(view, "padding", {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "0%",
          });
        }
        let arr =
          this.componentName == "chart_multi_axes" ? view.yAxes : [view.yAxis];
        arr &&
          arr.forEach((axis) => {
            if (!axis.hasOwnProperty("titleSpacing")) {
              this.$set(
                axis,
                "titleSpacing",
                this.componentName == "chart_bar" ? 24 : 12,
              );
            }
            if (!axis.hasOwnProperty("titlePosition")) {
              this.$set(
                axis,
                "titlePosition",
                this.componentName == "chart_bar" ? "broadside" : "top",
              );
            }
          });
      }
      this.$set(this, "view", view);
    },
    changeSort() {
      if (
        this.componentName == "pivot_table" ||
        this.componentName == "table"
      ) {
        if (this.nodeType == "metrics") {
          this.view.metrics.forEach((item) => {
            item.orderType = "";
          });
          let index = this.view.xFields.length - 1;
          if (index >= 0) {
            this.view.xFields[index].orderType = "";
          }
        } else {
          if (this.componentName == "table") {
            if (this.currNode) {
              const name = this.currNode.field.name;
              const orderType = this.currNode.orderType;
              if (!this.view.dataSource.queryObject.sortParam) {
                this.view.dataSource.queryObject.sortParam = {};
              }
              const sortParam = this.view.dataSource.queryObject.sortParam;
              this.$set(
                this.view.dataSource.queryObject.sortParam,
                name,
                orderType,
              );
            }
          } else {
            if (this.currNode) {
              let index = this.view.xFields.findIndex(
                (item) => item.field.name == this.currNode.field.name,
              );
              if (index == this.view.xFields.length - 1) {
                this.view.metrics.forEach((item) => {
                  item.orderType = "";
                });
              }
            }
          }
        }
      } else {
        this.view.xFields.forEach((item) => {
          item.orderType = "";
        });
        this.view.metrics.forEach((item) => {
          item.orderType = "";
        });
      }
    },
    clearSort(data) {
      if (this.view.componentName == "table") {
        const name = data.field.name;

        if (this.view.dataSource.queryObject.sortParam) {
          const sortParam = this.view.dataSource.queryObject.sortParam;

          this.$delete(sortParam, name);
        }
        this.view.columns = this.view.xFields;
        this.$refs.chart.getData(this.view);
        this.changeOper();
      }
      this.changeViewType(false);
    },
    setPageTool(val) {
      this.$set(this.view, "hasPageTool", val);
    },
    getOperStr(obj) {
      let arr = [
        {
          label: "计数",
          value: "count",
        },
        {
          label: "去重计数",
          value: "countDistinct",
        },
        {
          label: "求和",
          value: "sum",
        },
        {
          label: "平均值",
          value: "avg",
        },
        {
          label: "最大值",
          value: "max",
        },
        {
          label: "最小值",
          value: "min",
        },
        {
          label: "最小值",
          value: "min",
        },
      ];

      let item = arr.find((item) => item.value == obj.oper);

      if (item) {
        return item.label;
      }
    },
    closeMultiAxesMetrics(item) {
      this.view.metrics = this.view.metrics.filter(
        (row) => row.name != item.name,
      );
      this.changeViewType();
      this.changeFieldKey(false);
    },
    closeMetrics(index) {
      this.view.metrics.splice(index, 1);
      this.changeViewType();
      this.changeFieldKey(false);
    },
    checkMetrics(item) {
      this.nodeType = "metrics";
      this.currNode = item;
      this.changeFieldKey();
    },
    closeXFields(index, item) {
      let list = this._.cloneDeep(this.view.xFields);
      list.splice(index, 1);
      this.view.xFields = list;
      if (this.view.componentName == "table") {
        const name = item.field.name;
        if (this.view.dataSource.queryObject.sortParam) {
          const sortParam = this.view.dataSource.queryObject.sortParam;
          this.$delete(sortParam, name);
        }
        this.view.columns = this.view.xFields;
        this.$refs.chart.getData(this.view);
        this.changeOper();
      }
      this.changeViewType();
      this.changeFieldKey(false);
    },
    checkXFields(item) {
      this.nodeType = "xFields";
      this.currNode = item;
      this.changeFieldKey();
    },
    changeFieldKey(visible = true) {
      if (visible) {
        this.visible = true;
        this.fieldUuid = uuid();
      } else {
        this.visible = false;
        this.fieldUuid = "";
      }
    },
    closeYFields(index, item) {
      let list = this._.cloneDeep(this.view.yFields);
      list.splice(index, 1);
      this.view.yFields = list;
      if (this.view.componentName == "table") {
        const name = item.field.name;
        if (this.view.dataSource.queryObject.sortParam) {
          const sortParam = this.view.dataSource.queryObject.sortParam;
          this.$delete(sortParam, name);
        }
        this.view.columns = this.view.yFields;
        this.$refs.chart.getData(this.view);
        this.changeOper();
      }
      this.changeViewType();
      this.changeFieldKey(false);
    },
    checkYFields(item) {
      this.nodeType = "yFields";
      this.currNode = item;
      this.changeFieldKey();
    },
    stopMoveMsg(str) {
      if (!this.showMessage) {
        this.showMessage = true;
        this.$message({
          message: str,
          type: "warning",
          onClose: () => {
            this.showMessage = false;
          },
        });
      }
    },
    onMove(e) {
      let noDragCompoenents = [];
      if (this.view.componentName == "table") {
        noDragCompoenents = [];
      } else {
        noDragCompoenents = ["json_form"];
      }

      if (
        noDragCompoenents.includes(e.draggedContext.element.field.componentName)
      ) {
        return false;
      }
      let index = e.relatedContext.list.findIndex((item) => {
        let name = item.key || item.field?.name;
        return name == e.draggedContext.element.field.name;
      });
      let name = e.relatedContext.component.$attrs.name;
      let componentName = e.draggedContext.element.field.componentName;
      let fieldKey = e.draggedContext.element.field.name;
      if (name == "metrics") {
        if (
          componentName == "select_checkbox" ||
          componentName == "checkbox_group"
        ) {
          this.stopMoveMsg("指标不允许添加该字段");
          return false;
        }
      }
      let tip;
      let arr = [];

      if (name == "xFields" || name == "yFields") {
        if (this.view.componentName == "table") {
          //明细表规则
          arr = [
            "divider",
            "html",
            "html_input",
            "tab_pane",
            "reference_data",
            "cascade_data",
          ];
          tip = "可显示字段不允许添加该字段";
        } else {
          arr = [
            "json_form",
            // "checkbox_group",
            // "address_input",
            "image_uploader",
            "attachment_uploader",
            "sign_input",
            // "user_list_select",
            // "department_list_select",
            // "select_checkbox",
          ];
          if (name == "yFields") {
            var arr1 = ["reference_data_list", "form_data"];
            arr = arr.concat(...arr1);
          }
          tip = "维度不允许添加该字段";
        }
        if (
          index >= 0 ||
          arr.includes(e.draggedContext.element.field.componentName)
        ) {
          this.stopMoveMsg(tip);
          return false;
        }
        // 透视表情况
        if (this.componentName == "pivot_table") {
          let xIndex = this.view.xFields.findIndex((item) => {
            let name = item.key || item.field.name;
            return name == e.draggedContext.element.field.name;
          });
          let yIndex = this.view.yFields.findIndex((item) => {
            let name = item.key || item.field.name;
            return name == e.draggedContext.element.field.name;
          });
          if (xIndex > -1 || yIndex > -1) {
            this.stopMoveMsg("添加失败，同一字段不能重复添加维度");
            return false;
          }
        }
      }
      if (name == "filter") {
        if (
          this.view.dataSource &&
          this.view.dataSource.queryObject &&
          this.view.dataSource.queryObject.advanceQuery
        ) {
          let index = this.view.dataSource.queryObject.advanceQuery.findIndex(
            (item) => {
              item = item.field ? item.field : item;
              return item.name == fieldKey;
            },
          );
          if (index > -1) {
            this.stopMoveMsg("过滤条件不允许设置重复字段");
            return false;
          }
        }
      }
      //过滤条件和维度暂不支持添加关联字段
      if (this.view.componentName == "table") {
        //明细表规则
        if (name == "filter") {
          arr = [
            "divider",
            "html",
            "html_input",
            "tab_pane",
            "form_data",
            "reference_data",
            "json_form",
            "cascade_data",
          ];
          tip = "过滤字段不允许添加该字段";
          if (
            index >= 0 ||
            arr.includes(e.draggedContext.element.field.componentName)
          ) {
            this.stopMoveMsg(tip);
            return false;
          }
        }
      } else {
        if (name == "filter" || name == "xFields" || name == "yFields") {
          if (componentName == "reference_data") {
            this.stopMoveMsg("维度和过滤条件暂不允许添加关联字段");
            return false;
          }
          if (componentName == "cascade_data") {
            this.stopMoveMsg("维度和过滤条件暂不允许添加关联字段");
            return false;
          }
        }
      }

      if (this.componentName == "metric_table" && index >= 0) {
        return false;
      }

      if (this.componentName == "pivot_table") {
        return true;
      }
      if (this.componentName == "table") {
        return true;
      }
      if (name == "xFields") {
        if (e.relatedContext.list.length >= 2) {
          return false;
        }
        if (this.view.metrics.length > 1 && e.relatedContext.list.length) {
          return false;
        }
      }
      if (name == "yFields") {
        if (e.relatedContext.list.length >= 2) {
          return false;
        }
        if (this.view.metrics.length > 1 && e.relatedContext.list.length) {
          return false;
        }
      }

      if (name == "metrics") {
        if (this.view.xFields.length >= 2 && e.relatedContext.list.length) {
          return false;
        }
      }
      if (name == "filter") {
        if (
          !chartFilterFields.includes(
            e.draggedContext.element.field.componentName,
          )
        ) {
          return false;
        }
      }
      //进度图限制
      if (this.componentName == "chart_process") {
        if (name == "targetValues" && this.view.targetValues.length > 0) {
          this.stopMoveMsg("只允许添加一个目标值");
          return false;
        }
        if (name == "metrics" && this.metrics.length > 0) {
          this.stopMoveMsg("只允许添加一个指标值");
          return false;
        }
      }
      return true;
    },
    changeSource(formId) {
      this.view.dataSource.formId = formId;
      this.view.xFields = [];
      this.view.metrics = [];
      this.view.enableDrill = false;
      this.view.drillViewId = "";
      this.view.dataSource.queryObject = {
        pageSize: "",
        advanceQuery: [],
      };
    },
    changeViewType(isResetNode = true) {
      let componentName = this.componentName;
      if (this.view.xFields.length == 0 && this.view.metrics.length == 1) {
        if (this.componentName == "") {
          this.componentName = "metric_table";
        }
      } else if (
        (this.view.metrics.length >= 2 || this.view.xFields.length >= 2) &&
        (this.componentName == "" ||
          this.componentName == "metric_table" ||
          this.componentName == "chart_pie")
      ) {
        this.componentName = "chart_column";
      } else if (
        this.view.xFields.length >= 2 &&
        this.componentName == "chart_multi_axes"
      ) {
        this.componentName = "chart_column";
      } else {
        // this.componentName = "";
      }
      if (componentName != this.componentName && this.componentName != "") {
        this.resetChartColumn();
      }

      this.changeOper();
      //重置当前选中节点状态
      if (isResetNode) {
        this.resetCurrNode();
      }
    },
    /**
     * 重置当前选中节点状态
     */
    resetCurrNode() {
      this.nodeType = "";
      this.currNode = null;
    },
    refershData() {
      if (this.$refs.chart) {
        this.$refs.chart.getData(this.view);
      }
    },
    resizeChart() {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.resizeChart();
        }
      });
    },
    changeOper() {
      if (this.componentName == "table") {
      } else {
        if (!this.view.metrics.length && this.componentName != "chart_map") {
          return;
        }
      }

      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.getData(this.view);
        }
      });
    },
    changeBar() {
      if (this.componentName == "table") {
      } else {
        if (!this.view.metrics.length) {
          return;
        }
      }
      if (this.componentName != "chart_process") {
        if (!this.view.xFields.length && !this.view.yFields.length) {
          return;
        }
      } else {
        if (!this.view.targetValues?.length) return;
      }
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.getData(this.view);
        }
      });
    },
    changeArrayList(node, list) {
      // 调整正确的数组顺序
      let oldIndex = parseInt(node.item.dataset.index, 10);
      let parentIndex = node.item.dataset.parentIndex;
      let oldField;
      if (!isEmpty(parentIndex)) {
        parentIndex = parseInt(parentIndex, 10);
        oldField =
          this.$refs.sourceList.orignColumns[parentIndex].components[oldIndex];
      } else {
        oldField = this.$refs.sourceList.orignColumns[oldIndex];
      }

      let newField = list[node.newIndex];

      if (oldField.field.key != newField.field.key) {
        let index = list.findIndex(
          (item) => item.field.key == oldField.field.key,
        );
        if (index >= 0) {
          let field = list.splice(index, 1)[0];
          if (field) {
            list.splice(node.newIndex, 0, this._.cloneDeep(field));
          }
        }
      }
    },
    addMetrics(node) {
      if (
        this.view.metrics.length > 1 &&
        this.componentName == "chart_process"
      ) {
        this.view.metrics.unshift(this.view.metrics[1]);
        this.view.metrics.pop();
      }
      let metrics = this._.cloneDeep(this.view.metrics);
      this.changeArrayList(node, metrics);
      metrics.forEach((item) => {
        item.name = this.getUuid();
        item.field.comment = item.field.component.title;
      });
      if (!metrics[node.newIndex].oper) {
        metrics[node.newIndex].oper = "count";
      }
      let index = node.newIndex;
      let item = metrics[index];
      let field = item.field;

      if (isEmpty(item.numberFormat) && field.componentName == "input_number") {
        let numberFormat = {
          numberFormatType: field.format != "percent" ? "number" : "percentage",
          thousandsSeparator: field.thousandsSeparator, // 显示千分位
          numberMagnitude: "empty", //数量级
          decimal: field.decimalPrecision, //小数位数
          unit: "",
        };
        item.numberFormat = numberFormat;
      }

      this.view.metrics = metrics;
      this.changeViewType();
      this.changeFieldKey(false);
    },
    sortMetrics(node) {
      //当添加拖拽引起排序时，不重复执行请求
      if (node.pullMode == "clone") return;
      this.view.metrics = this._.cloneDeep(this.view.metrics);
      this.changeViewType();
    },
    addXFields(node) {
      let xFields = this._.cloneDeep(this.view.xFields);
      this.changeArrayList(node, xFields);
      delete xFields[node.newIndex].oper;
      xFields.forEach((item) => {
        item.field.comment = item.field.component.title;
      });
      let obj = xFields[node.newIndex];
      if (obj && obj.field && obj.field.componentName == "date_picker") {
        obj.datePrecision = {
          picker: "date",
          format: "YYYY-MM-DD",
        };
      }
      this.view.xFields = xFields;
      if (this.view.componentName == "table") {
        this.view.columns = xFields;
        this.changeOper();
      } else if (this.view.componentName == "metric_table") {
        this.$refs.chart?.getData(this.view);
      }
      this.changeViewType();
      this.changeFieldKey(false);
    },
    sortXFields(node) {
      //当添加拖拽引起排序时，不重复执行请求
      if (node.pullMode == "clone") return;
      this.view.xFields = this._.cloneDeep(this.view.xFields);
      if (this.componentName != "metric_table") {
        this.changeBar();
      }
      if (this.view.componentName == "table") {
        this.view.columns = this.view.xFields;
        this.changeOper();
      } else if (this.view.componentName == "metric_table") {
        this.$refs.chart.getData(this.view);
      }
    },
    addYFields(node) {
      let yFields = this._.cloneDeep(this.view.yFields);
      this.changeArrayList(node, yFields);
      delete yFields[node.newIndex].oper;
      yFields.forEach((item) => {
        item.field.comment = item.field.component.title;
      });
      let obj = yFields[node.newIndex];
      if (obj && obj.field && obj.field.componentName == "date_picker") {
        obj.datePrecision = {
          picker: "date",
          format: "YYYY-MM-DD",
        };
      }
      this.view.yFields = yFields;
      if (this.view.componentName == "table") {
        this.view.columns = yFields;
        this.changeOper();
      } else if (this.view.componentName == "metric_table") {
        this.$refs.chart.getData(this.view);
      }
      this.changeViewType();
      this.changeFieldKey(false);
    },
    sortYFields(node) {
      //当添加拖拽引起排序时，不重复执行请求
      if (node.pullMode == "clone") return;
      this.view.yFields = this._.cloneDeep(this.view.yFields);
      if (this.componentName != "metric_table") {
        this.changeBar();
      }
      if (this.view.componentName == "table") {
        this.view.columns = this.view.yFields;
        this.changeOper();
      } else if (this.view.componentName == "metric_table") {
        this.$refs.chart.getData(this.view);
      }
    },
    saveForm() {
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message.error("图表名称不能为空");
          return;
        }
        if (this.componentName == "") {
          this.$message.error("请选择视图类型");
          return;
        }
        if (this.view.metrics.length == 0) {
          if (this.componentName == "table") {
            this.view.columns = this.view.xFields;
            this.view.actionItems = [];
            if (this.view.export) {
              const obj = { title: "导出", clickEvent: "export" };
              this.view.actionItems.push(obj);
            }
            // if (
            //   this.view.dataSource.formId.indexOf("dataflow_") != 0 &&
            //   this.view.dataSource.formId.indexOf("at_") != 0
            // ) {

            // }
            if (this.view.preview) {
              const obj = { title: "查看", clickEvent: "preview" };
              this.view.actionItems.push(obj);
            }
          } else {
            this.$message.error("指标不能为空");
            return;
          }
        }
        if (
          this.componentName == "chart_column" &&
          this.view.xFields.length == 0
        ) {
          this.$message.error("柱形图的维度不能为空");
          return;
        }

        if (isEmpty(this.view.extParam.title)) {
          this.$message.error("图表名称不能为空");
          return;
        }

        this.view.componentName = this.componentName;

        this.$emit("saveChart", {
          componentName: this.componentName,
          view: this.view,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  height: calc(100vh - 50px);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--zgg-background-base);
}

.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex {
  flex: 1;
}

.form-body {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 280px;
  overflow: hidden;
  position: relative;
  .attribute-scroll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.mr {
  margin-right: 10px;
}

.col-container {
  min-height: 50px;
  border: solid 1px #f4f5f7;
  padding-bottom: 10px;

  .column-item,
  .sub-col-item {
    background-color: #ecf5ff;
    border-color: #d9ecff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #409eff;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    margin-top: 10px;
    margin-left: 5px;

    i {
      display: none;
    }

    .sub-container {
      display: none;
    }
  }

  .m {
    margin-top: 10px;
    border-width: 1px !important;
    outline: 0 !important;
  }
}

.col-container-empty:empty::before {
  content: "点击输入固定值，或拖动左侧字段到此处来添加目标值";
  color: #838892;
  line-height: 40px;
}
.body-chart {
  margin: 0px 8px 8px 8px;
  padding: 6px 16px 16px 16px;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  overflow: auto;
}
.body-pivot {
  margin: 8px;
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
  .pivot-item {
    display: flex;
    align-items: center;
  }
  .col-h {
    min-width: 100px;
    font-size: 14px;
  }
  .col-d {
    flex: 1;
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    /* 火狐浏览器 */
    ::v-deep input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
.dash-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;

  .title {
    font-size: 16px;
    flex: 1;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba($color: #091e42, $alpha: 0.95);
    font-weight: 600;
  }
  i {
    cursor: pointer;
  }
}
</style>
