<template>
  <div
    v-resize="initTable"
    :ref="tableRef"
    class="uiTable"
    :id="`uiTable_${view?.key}`"
    :class="isH5 ? '' : 'isWeb'"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <!-- 有设置维度列的表格 -->
    <el-table
      :stripe="view?.colorConfig?.enableStripe"
      class="el-table3"
      v-if="view && isReset && view.yFields && view.yFields.length"
      :data="tableData3"
      :span-method="spanMethod3"
      border
      height="200px"
    >
      <pivotTableColumn
        v-for="(item, index) in tableColumns"
        :key="index"
        :columns="item"
      ></pivotTableColumn>
    </el-table>

    <!-- 未设置维度列的表格 -->

    <el-table
      :stripe="view?.colorConfig?.enableStripe"
      class="el-tableee"
      v-if="
        view &&
        isReset &&
        ((view.yFields && view.yFields.length == 0) || !view.yFields)
      "
      :data="tableData2"
      :span-method="spanMethod"
      border
      height="200px"
    >
      <el-table-column
        v-for="item in view.xFields"
        :key="item.field.name"
        :label="item.title"
        :prop="item.field.name"
        :align="item.align"
        :width="
          item.width ? item.width : getTableColumnWidthByColumn(item.field)
        "
        :min-width="getTableColumnWidthByColumn(item.field)"
      >
        <template slot-scope="{ row }">
          <span
            :class="[flowStatusStyle(getCellValue(row, item), item.field.name)]"
          >
            {{ getCellValue(row, item) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in view.metrics"
        :key="item.name"
        :label="item.title"
        :prop="item.numberFormat ? item.name + 'Format' : item.name"
        :align="item.align"
        :width="
          item.width ? item.width : getTableColumnWidthByColumn(item.field)
        "
        :min-width="getTableColumnWidthByColumn(item.field)"
      >
      </el-table-column>
      <el-table-column
        label=""
        :width="tempWidth"
        v-if="tempWidth > 0"
      ></el-table-column>
    </el-table>
    <pagination
      :total="page.totalRow"
      :page.sync="page.pageNumber"
      :limit.sync="page.pageSize"
      @pagination="loadingList"
      layout="total, sizes,prev,pager, next"
    />
  </div>
</template>
<script>
import { _eq } from "@zgg-core-utils/relyUtil";
import { getAggreation } from "../api";
import {
  dealWithChartValue,
  getRealNum,
  getTableColorConfig,
} from "./chartUtil";
import { sum, divide } from "@/utils/number";
import { getTableColumnWidthByColumn } from "@/views/lowCode/utils";
import pivotTableColumn from "./pivotTableColumn";
import { isEmpty, getStrByNumberFormat } from "@/zgg-core/utils";

export default {
  components: {
    pivotTableColumn,
  },
  data() {
    return {
      tableData2: [],
      tableData: [],
      list: [],
      view: null,
      option: null,
      isReset: true,
      dataFilter: {},
      page: {
        pageSize: 50,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      totalData: "",
      tempWidth: 0,
      tableRef: "tableDom" + Math.random(),
      tableColumns: [], // 透视表增加列维度的表头数据
      tableData3: [], // 透视表增加列维度的table数据
      summaryData: [], // 透视表增加列维度右侧的汇总数据
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    let that = this;
    window.addEventListener("resize", () => {
      that.initTable();
    });
  },
  methods: {
    initTable() {
      let that = this;
      getTableColorConfig(this.view, this.isH5); //获取表框头部颜色设置值

      //获取列的总宽度
      let containerWidth = 0;
      if (this.view && this.view.xFields && this.view.metrics) {
        var newArray = this.view.xFields.concat(this.view.metrics);
      }
      if (newArray) {
        newArray.forEach((item) => {
          if (!item.width && item.field) {
            item.width = getTableColumnWidthByColumn(item.field);
          }
          item.width = parseInt(item.width);
          containerWidth += item.width;
        });
      }

      //获取当前表格的宽度
      if (that.$refs[that.tableRef]) {
        var tableDomWidth = that.$refs[that.tableRef].clientWidth;
      }
      //获取两者差
      that.tempWidth = tableDomWidth - containerWidth - 5;
    },
    getTableColumnWidthByColumn,
    //获取汇总数据,count为true
    async getTotalData(view) {
      let queryObject;
      if (view.dataSource && view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
      }
      queryObject.pageNumber = 1;
      queryObject.pageSize = this.page.pageSize;
      if (view.xFields) {
        queryObject.xFields = this._.cloneDeep(view.xFields);
      }
      if (view.yFields) {
        queryObject.yFields = this._.cloneDeep(view.yFields);
      }
      if (view.metrics) {
        queryObject.metrics = this._.cloneDeep(view.metrics);
      }
      await getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        cmd: "page",
        count: true,
        dataFilter: JSON.stringify(this.dataFilter),
      }).then((res) => {
        this.totalData = res.data.page.list;
      });
    },
    loadingList() {
      this.getData(this.view);
    },
    // 获取表格数据
    getData(view, dataFilter) {
      if (dataFilter) {
        this.dataFilter = dataFilter;
      }
      this.$set(this, "view", view);
      if (!view.metrics.length) {
        return;
      }
      if (!view.xFields.length && !view.yFields.length) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
      }
      // if (view.dataSource.queryObject && view.dataSource.queryObject.pageSize) {
      //   queryObject.pageSize = view.dataSource.queryObject.pageSize;
      // }
      queryObject.pageSize = this.page.pageSize;
      queryObject.pageNumber = this.page.pageNumber;
      queryObject.xFields = this._.cloneDeep(view.xFields);
      queryObject.yFields = this._.cloneDeep(view.yFields);
      queryObject.metrics = this._.cloneDeep(view.metrics);
      // queryObject.xFields.forEach((item) => {
      //   item.orderType = "";
      // });
      // queryObject.metrics.forEach((item) => {
      //   item.orderType = "";
      // });
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        cmd: "page",
        dataFilter: JSON.stringify(this.dataFilter),
      }).then((res) => {
        this.list = res.data.page.list;
        if (res.data.summaryData) {
          this.summaryData = res.data.summaryData;
        }
        this.page.totalRow = res.data.page.totalRow;
        this.page.totalPage = res.data.page.totalPage;
        this.page.pageSize = res.data.page.pageSize;
        this.page.pageNumber = res.data.page.pageNumber;
        if (view.yFields && view.yFields.length) {
          this.buildTable3(view);
        } else {
          this.buildChart(view);
        }
      });
    },
    getCellValue(row, item) {
      let component = this._.cloneDeep(item.field.component);
      if (component.componentName == "address_input") {
        if (component.picker == "address") {
          component.picker = "county";
        }
      }

      let value = dealWithChartValue(row, component, {
        datePrecision: item.datePrecision,
      });
      return value;
    },
    async buildChart(view) {
      this.isReset = false;
      let list = this.list;
      this.tableData = list;
      let tableData2 = this._.cloneDeep(list);
      //当对数据量不作限制时，不显示汇总数据
      if (
        this.page.pageNumber == this.page.totalPage &&
        !view.dataSource.queryObject.limit
      ) {
        await this.getTotalData(view);
        let obj = {};
        obj[view.xFields[0].field.name] = "汇总";

        view.metrics.forEach((item) => {
          let value = 0;
          if (item.oper == "min") {
            value = Math.min(...this.tableData.map((row) => row[item.name]));
          } else if (item.oper == "max") {
            value = Math.max(...this.tableData.map((row) => row[item.name]));
          } else if (item.oper == "avg") {
            let total = 0;
            this.tableData.forEach((row) => {
              // total += row[item.name];
              total = sum(total, row[item.name]);
            });
            // 产品定暂时保存8位小数
            value = this.toDecimal(divide(total, this.tableData.length), 8);
          } else {
            let data = this.totalData.find((row) => {
              return row[item.name];
            });
            if (data) {
              value = data[item.name];
            }
          }
          obj[item.name] = value;
          let numberFormat = item.numberFormat;
          if (!isEmpty(numberFormat)) {
            obj[item.name + "Format"] = getStrByNumberFormat(
              value,
              numberFormat,
            );
          }
        });
        tableData2.push(obj);
      }
      this.tableData2 = tableData2;
      this.$nextTick(() => {
        this.isReset = true;
        this.initTable(); //监听页面宽度改变
      });
    },

    // 构建汇总数据(有列维度时)
    buildSummaryData(list, tableData) {
      let obj = {};
      obj[this.view.xFields[0].field.name] = "汇总";

      list.forEach((item) => {
        let value = 0;
        let filterTableData = tableData.filter((row) => row[item.key]);
        if (item.oper == "min") {
          value = Math.min(...filterTableData.map((row) => row[item.key]));
        } else if (item.oper == "max") {
          value = Math.max(...filterTableData.map((row) => row[item.key]));
        } else if (item.oper == "avg") {
          let total = 0;
          filterTableData.forEach((row) => {
            total = sum(total, row[item.key]);
          });
          value = this.toDecimal(divide(total, filterTableData.length), 8);
        } else {
          let total = 0;
          tableData.forEach((row) => {
            if (row[item.key]) {
              total = sum(total, row[item.key]);
            }
          });
          value = total;
        }
        obj[item.key] = value;
        if (item.numberFormat) {
          obj[item.key + "Format"] = getStrByNumberFormat(
            value,
            item.numberFormat,
          );
        }
      });
      return obj;
    },
    toDecimal(x, y) {
      var f = parseFloat(x);
      var s = f.toString();
      if (s.indexOf(".") > -1) {
        var rs = s.substring(s.indexOf(".") + 1);
        if (rs.length > y) {
          f = parseFloat(x).toFixed(y);
        }
      }
      return f;
    },
    // 构建带有维度列的数据
    buildTable3(view) {
      this.isReset = false;
      let xFields = view.xFields;
      let metrics = view.metrics;
      let yFields = view.yFields;
      this.tableData3 = [];
      this.tableColumns = [];
      let list = this.list;
      let tableData = this._.cloneDeep(list);
      let length = yFields.length; // 列维度数量

      // 数据处理
      let columnList = []; // 数据列的集合
      tableData.forEach((item) => {
        let data = item.data;
        let obj = {};
        xFields.forEach((mItem) => {
          obj[mItem.field.name] = item[mItem.field.name];
        });
        data.forEach((dataItem) => {
          columnList.push(dataItem.y);
          metrics.forEach((mItem) => {
            let key = dataItem.y.join("") + mItem.name;
            obj[key] = dataItem[mItem.name];
            if (mItem.numberFormat) {
              obj[key + "Format"] = dataItem[mItem.name + "Format"];
            }
          });
        });
        this.tableData3.push(obj);
      });

      // 构建汇总列数据
      this.summaryData.forEach((item, index) => {
        metrics.forEach((mItem) => {
          this.tableData3[index][mItem.name] = item[mItem.name];
          if (mItem.numberFormat) {
            this.tableData3[index][mItem.name + "Format"] =
              item[mItem.name + "Format"];
          }
        });
      });

      // 构建列维度产生的表头
      let yFieldsHeads = [];
      yFields.forEach((item) => {
        yFieldsHeads.push(item.title);
      });
      let yFieldsTrans = (list, index, child) => {
        let children;
        if (index + 1 < list.length) {
          children = [{}];
        } else {
          // 最后一级时加上行维度
          children = [];
          if (xFields.length) {
            xFields.forEach((item) => {
              children.push({
                label: item.title,
                prop: item.field.name,
              });
            });
          } else {
            // 透视表只存在列维度和指标时
            children.push({
              label: "",
              prop: "summary",
            });
          }
        }
        child.label = list[index];
        child.children = children;
        if (index < length - 1) {
          yFieldsTrans(list, index + 1, child.children[0]);
        }
        return child;
      };

      // 构建数据列表头
      let summaryColumnPropertys = []; // 底部汇总行每列的属性集合
      let trans = (list, index, parent) => {
        let arr = list.map((item) => item[0]);
        arr = [...new Set(arr)];
        let newArr = [];
        arr.forEach((element) => {
          let obj = {
            label: element,
            parent: parent.parent + element,
          };
          if (index == length - 1) {
            obj.children = [];
            metrics.forEach((item) => {
              summaryColumnPropertys.push({
                key: obj.parent + item.name,
                oper: item.oper,
                numberFormat: item.numberFormat,
              });
              obj.children.push({
                label: item.title,
                prop: obj.parent + item.name,
                numberFormat: item.numberFormat,
              });
            });
          }
          if (index < length - 1) {
            var newList = list.filter((val) => val[0] == element);
            newList = newList.map((j) => {
              return j.filter((k, kIndex) => {
                return kIndex > 0;
              });
            });
            obj.children = trans(newList, index + 1, obj);
          }
          newArr.push(obj);
        });
        return newArr;
      };

      // 构建汇总列表头
      let summaryColumn = {
        label: "汇总",
        children: [],
      };
      metrics.forEach((mItem) => {
        summaryColumn.children.push({
          oper: mItem.oper,
          label: mItem.title,
          prop: mItem.name,
          numberFormat: mItem.numberFormat,
        });
      });
      // 遍历获取所有列的属性列表
      summaryColumn.children.forEach((item) => {
        summaryColumnPropertys.push({
          key: item.prop,
          oper: item.oper,
          numberFormat: item.numberFormat,
        });
      });

      let yFieldsColumn = yFieldsTrans(yFieldsHeads, 0, {}); // 列维度产生的表头(列)
      let dataListColumn = trans(columnList, 0, { parent: "" }); // 数据产生的表头(列)
      dataListColumn.unshift(yFieldsColumn); // 添加左侧列维度
      dataListColumn.push(summaryColumn); // 合并右侧汇总列
      if (xFields.length) {
        let summaryData = this.buildSummaryData(
          summaryColumnPropertys,
          this.tableData3,
        ); // 底部汇总数据

        this.tableData3.push(summaryData);
      } else {
        // 透视表只存在列维度和指标时
        this.tableData3[0].summary = "汇总";
      }

      this.tableColumns = dataListColumn;
      this.$nextTick(() => {
        this.isReset = true;
        this.initTable(); //监听页面宽度改变
      });
    },
    buildTable(list, index) {
      let tableData = [];
      let legendData = [];
      let view = this.view;
      let name = view.xFields[index].field.name;
      if (index == view.xFields.length - 1) {
        let orderType;
        let key;
        if (view.xFields[index].orderType) {
          orderType = view.xFields[index].orderType;
          key = view.xFields[index].field.name;
          // 纬度排序
        } else {
          // 指标排序
          let item = view.metrics.find((item) => item.orderType != "");
          if (item) {
            orderType = item.orderType;
            key = item.name;
          }
        }

        list.sort((a, b) => {
          if (a[key] > b[key]) {
            if (orderType == "desc") {
              return -1;
            } else {
              return 1;
            }
          } else if (a[key] < b[key]) {
            if (orderType == "desc") {
              return 1;
            } else {
              return -1;
            }
          }
          return 0;
        });

        return list;
      }
      let orderType = view.xFields[index].orderType;
      list.forEach((row) => {
        let value = row[name];
        if (!legendData.includes(value)) {
          legendData.push(value);
        }
      });

      if (orderType == "asc") {
        legendData.sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        });
      } else if (orderType == "desc") {
        legendData.sort((a, b) => {
          if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          }
          return 0;
        });
      }

      legendData.forEach((key) => {
        let arr = list.filter((item) => item[name] == key);

        let arr2 = this.buildTable(arr, index + 1);
        tableData = tableData.concat(arr2);
      });
      return tableData;
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      let view = this.view;
      if (this.page.pageNumber == this.page.totalPage) {
        // 最后一页
        if (rowIndex == this.tableData2.length - 1) {
          // 最后一行
          if (columnIndex == 0) {
            return {
              rowspan: 1,
              colspan: view.xFields.length,
            };
          } else if (columnIndex < view.xFields.length) {
            return {
              rowspan: 0,
              colspan: 0,
            };
          } else {
            return {
              rowspan: 1,
              colspan: 1,
            };
          }
        }
      }

      let name = column.property;
      if (view.xFields.findIndex((item) => item.field.name == name) >= 0) {
        let rowSpan = 1;
        let colSpan = 1;

        let arr = [];
        for (let index = 0; index <= columnIndex; index++) {
          arr.push(view.xFields[index].field.name);
        }
        let a = this.tableData.filter((item) => {
          let result = true;
          arr.forEach((key) => {
            if (!_eq(item[key], row[key])) {
              result = false;
            }
          });
          return result;
        });

        rowSpan = a.length;
        let index = a.findIndex((item) => {
          let result = true;
          for (const [key, value] of Object.entries(row)) {
            if (!_eq(item[key], value)) {
              result = false;
            }
          }
          return result;
        });

        // console.log(row[name])

        if (index > 0) {
          rowSpan = 0;
          colSpan = 0;
        } else {
          if (rowSpan > 1 && rowIndex > 0) {
            let index = rowIndex - 1;
            let obj = this.tableData2[index];

            if (arr.every((key) => _eq(obj[key], row[key]))) {
              rowSpan = 0;
              colSpan = 0;
            }
          }
        }

        return {
          rowspan: rowSpan,
          colspan: colSpan,
        };
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    spanMethod3({ row, column, rowIndex, columnIndex }) {
      let view = this.view;
      if (view.xFields.length == 0) {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
      if (rowIndex == this.tableData3.length - 1) {
        // 最后一行
        if (columnIndex == 0) {
          return {
            rowspan: 1,
            colspan: view.xFields.length,
          };
        } else if (columnIndex < view.xFields.length) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      let name = column.property;
      if (view.xFields.findIndex((item) => item.field.name == name) >= 0) {
        let rowSpan = 1;
        let colSpan = 1;

        let arr = [];
        for (let index = 0; index <= columnIndex; index++) {
          arr.push(view.xFields[index].field.name);
        }

        let a = this.tableData3.filter((item) => {
          let result = true;
          arr.forEach((key) => {
            if (!_eq(item[key], row[key])) {
              result = false;
            }
          });
          return result;
        });
        rowSpan = a.length;
        let index = a.findIndex((item) => {
          let result = true;
          for (const [key, value] of Object.entries(row)) {
            if (!_eq(item[key], value)) {
              result = false;
            }
          }
          return result;
        });
        if (index > 0) {
          rowSpan = 0;
          colSpan = 0;
        } else {
          if (rowSpan > 1 && rowIndex > 0) {
            let index = rowIndex - 1;
            let obj = this.tableData3[index];
            if (arr.every((key) => _eq(obj[key], row[key]))) {
              rowSpan = 0;
              colSpan = 0;
            }
          }
        }
        return {
          rowspan: rowSpan,
          colspan: colSpan,
        };
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    /** 处理流程状态样式 **/
    flowStatusStyle(flowStatusTitle, fieldName) {
      if (fieldName !== "flowStatus") {
        return "";
      }

      switch (flowStatusTitle) {
        case "进行中":
          return "flow-status is-processing";
        case "已通过":
          return "flow-status is-success";
        case "已拒绝":
          return "flow-status is-reject";
        case "未提交":
          return "flow-status is-not_commit";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --primary-color: #6bdaae;
}

.el-table3 ::v-deep thead.is-group th {
  background: #f6f6f6;
}
.el-table3 ::v-deep th {
  border-bottom: 1px solid #ebecf0;
}
.el-table3 ::v-deep th,
.el-table3 ::v-deep td {
  border-right: 1px solid #ebecf0;
}

.el-tableee ::v-deep .el-table__header-wrapper thead div,
.el-tableee ::v-deep th,
.el-tableee ::v-deep .el-table__fixed-header-wrapper thead div {
  background: var(--js-head-bg-color);
  color: var(--js-head-color);
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
}

::v-deep .el-table thead {
  font-size: 13px;
}
.isWeb {
  ::v-deep .el-table,
  ::v-deep .el-table td {
    border-right: 1px solid var(--js-right-border-color);
    border-bottom: 1px solid var(--js-border-color);
    color: var(--js-content-color);
  }
  ::v-deep .el-table {
    border: 1px solid var(--js-border-color); /* 设置边框颜色 */
    background-color: var(--js-background-color);
  }
  ::v-deep .el-table tr,
  .pagination-container,
  ::v-deep .el-pager li {
    background: transparent;
  }

  ::v-deep .el-pagination.is-background .el-pager li,
  ::v-deep .el-pagination.is-background .btn-prev:disabled,
  ::v-deep .el-pagination.is-background .btn-next,
  ::v-deep .el-pagination .el-select .el-input .el-input__inner {
    background-color: var(--js-background-color);
    border: 1px solid var(--js-border-color);
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--js-head-bg-color);
  }

  ::v-deep .el-table th.is-leaf {
    border-bottom: 1px solid var(--js-border-color);
  }
  .el-tableee ::v-deep th,
  .el-table ::v-deep thead.is-group th,
  .el-tableee ::v-deep .el-table__header-wrapper thead div {
    background: var(--js-head-bg-color);
    color: var(--js-head-color);
  }
  ::v-deep .el-table--group::after,
  ::v-deep .el-table--border::after,
  ::v-deep .el-table::before {
    background-color: var(--js-background-color);
  }
  ::v-deep .el-table__row:hover,
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(56, 112, 234, 0.06);
  }

  ::v-deep .el-pagination__total,
  ::v-deep .el-pagination.is-background .btn-prev:disabled,
  ::v-deep .el-pagination.is-background .btn-next:disabled,
  ::v-deep .el-pagination .el-select .el-input .el-input__inner,
  ::v-deep .el-select .el-input .el-select__caret {
    color: var(--js-content-color);
  }
  ::v-deep {
    .el-table--border th {
      border-right: 1px solid var(--js-right-border-color);
      border-bottom: 1px solid var(--js-right-border-color);
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background: var(--js-stripe-color);
    }
  }
}
.flow-status {
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 50px;
  box-sizing: border-box;
  margin: 0 5px;
  line-height: 14px;
  display: inline-block;

  &.is-reject {
    background-color: #ff4d4f;
    color: #ffffff;
  }

  &.is-success {
    background-color: #52c41a;
    color: #ffffff;
  }

  &.is-processing {
    background-color: #faad14;
    color: #ffffff;
  }

  &.is-not_commit {
    background-color: #919398;
    color: #ffffff;
  }
}

::v-deep .el-table__body-wrapper,
::v-deep .el-pagination {
  overflow: hidden;
}

::v-deep .el-table__body-wrapper:hover,
::v-deep .el-pagination:hover {
  overflow: auto;
}
</style>
