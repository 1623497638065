import request from '@/utils/request'
import qs from "qs"

/**
 * 表单解绑导出模版
 * @param {*} data
 * @returns
 */
export function formDeleteExcel(data) {
    return request({
        url:  '/ui/form/deleteExcel',
        method: 'post',
        data: qs.stringify(data)
    })
}
/**
 * 获取 word 里面的 输入域 列表，给前端去做匹配用(admin端)(ok)
 * @param {*} formId=表单ID(必填)
 * @returns
 */
export function formExportWordListField(data) {
    return request({
        url:  '/app/appFormExportWord/listField',
        method: 'post',
        data
    })
}
/**
 * 删除接口 (admin端)(ok)
 * @param {*} formId=表单ID(必填)
 * @returns
 */
export function formExportWordDelete(data) {
    return request({
        url:  '/app/appFormExportWord/delete',
        method: 'post',
        data
    })
}
/**
 * 保存低代码表单字段和word的占位符的对应关系(  低代码表单输入项 和 word的 输入域 的对应关系)(admin端)
 * @param {*} formId=表单ID(必填)&content=[{"variable":"本周工作内容(word域占位符)","metaFieldId":"低代码表单ID"}]
 * @returns
 */
export function formExportWordSaveFieldMapping(data) {
    return request({
        url:  '/app/appFormExportWord/saveFieldMapping',
        method: 'post',
        data
    })
}
/**
 *预览 (admin端)(ok)
 * @param {*} formId=表单ID(必填)
 * @returns
 */
export function formExportWordPreview(data) {
    return request({
        url:  '/app/appFormExportWord/preview',
        method: 'post',
        data
    })
}

/**
    单位流程列表
 */
export function companyWorkflowList(data) {
    return request({
        url:  '/companyWorkflow/mgr/list',
        method: 'post',
        data
    })
}

/**
    单位节点列表
 */
export function companyWorkflowNodeList(data) {
    return request({
        url:  '/companyWorkflow/mgr/nodeList',
        method: 'post',
        data
    })
}

