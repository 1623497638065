<template>
  <div class="container">
    <div class="market-unit-box" v-loading="loading">
      <div class="market-unit-list" v-if="pluginData.length">
        <div
          class="unit-item"
          @click="showPluginDetail(item)"
          v-for="(item, index) in pluginData"
          :key="index"
        >
          <div class="item-icon">
            <img :src="item.iconUrl" v-if="item.iconUrl" alt="" />
            <img src="../../../assets/icon_plugin.png" v-else alt="" />
          </div>
          <div class="item-text">
            <div class="item-title">
              {{ item.name }}
            </div>
            <div class="item-desc">
              {{ item.overview }}
            </div>
          </div>
          <div class="item-tip installed" v-if="item.installed">已安装</div>
        </div>
      </div>
      <el-empty v-else description="暂无插件"></el-empty>
    </div>

    <!-- 插件详情 -->
    <el-drawer
      :title="pluginTitle"
      :visible.sync="visible"
      direction="btt"
      size="95%"
      @open="openDrawer"
      @close="closeDrawer"
    >
      <div class="drawer-wrapper">
        <div class="drawer-describe">
          <div class="describe-icon">
            <img :src="pluginDetail.iconUrl" alt="" />
          </div>
          <div class="describe-text">
            <div class="text-title-container">{{ pluginDetail.name }}</div>
            <div class="text-detail">{{ pluginDetail.overview }}</div>
          </div>
          <div class="drawer-install-btn" v-if="!pluginDetail.installed">
            <el-button type="primary" @click="pluginInstall">安装</el-button>
          </div>
        </div>
        <div class="recommend-details">
          <div class="drawer-title">插件详情</div>
          <div class="recommend-details-text">
            <div class="plugin-picture-video" v-if="pluginDetail.detailFiles">
              <template v-for="item in pluginDetail.detailFiles.split(',')">
                <img class="media-item" :src="item" alt="" />
              </template>
            </div>
            <el-empty v-else description="暂无插件视频或图片介绍"></el-empty>
            <div class="details-text">
              {{ pluginDetail.details }}
            </div>
          </div>
        </div>
        <!-- <div class="drawer-detail">
            <div class="drawer-title">帮助文档</div>
            <div class="help-url">
              <a :href="pluginDetail.helpUrl" target="_blank">使用说明</a>
            </div>
          </div> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getPluginList, getPluginDetail, pluginInstall } from "./api";
import { isEmptyByKey } from "@zgg-core-utils/utils";
export default {
  name: "",
  data() {
    return {
      loading: true,
      visible: false,
      pluginTitle: "", // 插件标题
      pluginDetail: {}, // 选中插件详情
      pluginData: [],
    };
  },

  components: {},

  computed: {},

  created() {
    this.initData();
  },

  mounted() {},

  methods: {
    initData() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
      };
      getPluginList(params)
        .then((res) => {
          this.loading = false;
          if (!isEmptyByKey(res, "data.page.list")) {
            this.pluginData = res.data.page.list;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openDrawer() {},
    closeDrawer() {},
    // 显示插件详情
    showPluginDetail(item) {
      this.pluginDetail = item;
      this.pluginTitle = item.name;
      this.visible = true;
    },
    // 安装插件
    pluginInstall() {
      pluginInstall({
        pluginId: this.pluginDetail.id,
      }).then((res) => {
        this.$message.success("插件安装成功");
        this.pluginData.forEach((item) => {
          if (item.id == this.pluginDetail.id) {
            item.installed = true;
          }
        });
      });
    },
  },
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.initData();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: calc(100% - 20px);
}
.market-unit-box {
  width: 1200px;
  min-height: 100%;
  background: #ffffff;
  margin: 20px auto 0;
  padding: 20px 20px 0 0;
  border-radius: 10px 10px 0px 0px;
}
.market-unit-list {
  width: 100%;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  overflow: visible;
}
.unit-item {
  position: relative;
  display: inline-block;
  width: calc(33.33333% - 20px);
  height: 80px;
  margin: 0 0 20px 20px;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  border-radius: 6px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  &:hover {
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  .item-icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    vertical-align: top;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .item-text {
    display: inline-block;
    max-width: 210px;
    vertical-align: top;
    .item-title {
      display: inline-block;
      margin: 4px 0;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      max-width: 100%;
      color: #141e31;
      font-weight: 500;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .item-desc {
      color: #6b778c;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .item-tip {
    position: absolute;
    top: 8px;
    right: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 54px;
    height: 20px;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    border-radius: 10px 0 0 10px;
    &.installed {
      background: #52bd94;
    }
  }
}

.drawer-wrapper {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  .drawer-describe {
    .describe-icon {
      display: inline-block;
      width: 80px;
      min-width: 80px;
      height: 80px;
      margin-right: 16px;
      vertical-align: top;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .describe-text {
      display: inline-block;
      max-width: 1000px;
      vertical-align: top;
      .text-title-container {
        display: inline-block;
        width: 1000px;
        color: #223346;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .text-detail {
        margin-top: 6px;
        color: #838892;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .drawer-install-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      float: right;
      margin-top: 12px;
    }
  }
  .recommend-details {
    .drawer-title {
      height: 22px;
      margin-top: 30px;
      margin-bottom: 10px;
      color: #141e31;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .recommend-details-text {
      .plugin-picture-video {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 10px;
        .media-item {
          width: 590px;
          height: 330px;
          margin-bottom: 18px;
        }
      }
      .details-text {
        font-size: 14px;
      }
    }
  }
  .drawer-detail {
    .help-url {
      padding: 10px 0;
      font-size: 14px;
    }
  }
}

::v-deep .el-drawer__header {
  margin-bottom: 15px;
  span {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  .el-drawer__close-btn .el-icon-close {
    &:hover {
      background-color: #d4d7da;
    }
  }
}
::v-deep .el-drawer__body {
  background-color: #f5f6f8;
}
</style>
