<template>
  <el-dialog
    title="编辑 API 测试数据"
    :visible.sync="visible"
    :append-to-body="true"
    :destroy-on-close="true"
    width="700px"
  >
    <div style="padding: 10px 20px">
      <div class="weui-row" style="margin-bottom: 10px">
        <div style="width: 190px; box-sizing: border-box; padding-left: 10px">
          参数名称
        </div>
        <div>参数值</div>
      </div>
      <div style="max-height: 70vh; overflow: auto" class="custom-form">
        <el-form ref="form" :model="form" label-width="190px" size="small">
          <el-form-item
            v-for="item in components"
            :key="item.nodeKey + '_' + item.name"
            :prop="item.nodeKey + '_' + item.name"
            :rules="[{ required: true, message: `请输入${item.title}` }]"
          >
            <template slot="label">
              <el-tooltip :content="item.nodeTitle + '.' + item.title">
                <span class="span-label"
                  >{{ item.nodeTitle }}.{{ item.title }}</span
                >
              </el-tooltip>
            </template>
            <el-input
              v-if="item.componentName == 'input'"
              v-model="form[item.nodeKey + '_' + item.name]"
              :placeholder="'请输入' + item.title"
            ></el-input>
            <el-upload
              v-else-if="item.componentName == 'attachment_uploader'"
              :auto-upload="true"
              action=""
              :limit="1"
              :http-request="upload(item.nodeKey + '_' + item.name)"
              :before-upload="beforeUpload"
            >
              <el-button>上传附件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button type="primary" size="small" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      visible: false,
      form: {},
      components: [],
    };
  },

  methods: {
    close() {
      this.visible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$emit("postApi", this.form);
      });
    },
    upload(name) {
      return (uploadDetail) => {
        let params = new FormData();
        params.append("attachmentFile", uploadDetail.file);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        request({
          url: "/attachment/upload",
          method: "post",
          headers: config.headers,
          data: params,
        })
          .then((resp) => {
            if (resp.code === 0) {
              this.$set(this.form, name, [resp.data.attachment]);
            } else {
              this.$message({
                message: "上传失败",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.$message({
              message: "上传出错，服务器开小差了呢",
              type: "error",
            });
          });
      };
    },
    beforeUpload(file) {
      if (file.size > 2048000) {
        this.$message({
          message: "请选择2MB以内的文件",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    openTestApi(components) {
      this.form = {};
      this.components = components;
      this.visible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
}
.custom-form {
  ::v-deep {
    .el-form-item__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
