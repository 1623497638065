<template>
  <div class="thumb-container">
    <div
      v-for="(attachment, index) in list"
      :key="index"
      class="attachment-icon"
      :title="getTitle(attachment)"
      @click.stop="click"
    >
      <el-image
        v-if="checkFileImage(attachment.extension)"
        :z-index="99999"
        :ref="'elImg_' + index"
        :src="(attachment.url || attachment.attachment.url) + '?x-oss-process=image/resize,m_lfit,h_100,w_100'"
        class="attachment-image"
      ></el-image>
      <SvgIcon
        iconClass="wenjianjia"
        style="width: 24px;height: 24px;cursor: pointer;"
        v-else-if="attachment.dataType==='folder'"
      ></SvgIcon>
      <SvgIcon
        v-else
        :icon-class="getIconClassByExtension(attachment.extension)"
        class="attachment-svg"
      ></SvgIcon>
    </div>
    <el-dialog
      :title="fileType == 'image' ? '图片列表' : '附件列表'"
      :visible.sync="dialogVisible"
      width="600px"
      :append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div style="padding: 10px">
        <attachment-list
          ref="attachmentList"
          style="margin-top: 10px"
          :attachment-list="list"
          :isRename="false"
          :fieldName="fieldName"
          :curRow="curRow"
          :show-btn-name="true"
          @downLoad="handleAttachmentDownload"
        >
          <template slot-scope="scope">
            <el-link
            class="el-dropdown-menu__item"
          style="padding: 0 20px;"
          :underline="false"
              icon="iconfont icon-xiazai_fill"
              @click="handleAttachmentDownload(scope.row)"
              >下载
            </el-link>
          </template>
        </attachment-list>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon/index";
import { downFile } from "@/utils/ali-oss";
import AttachmentList from "@/components/AttachmentList";
import { downloadWithZip } from "@/api/form";
export default {
  inject: {
    getFormId: {
      default: () => "",
    },
    getAppViewId: {
      default: () => "",
    },
  },
  components: { SvgIcon, AttachmentList },
  props: {
    fileType: {
      type: String,
      default() {
        return "";
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    preventClick:{
      type:Boolean,
      default(){
        return false
      }
    },
    // 组件字段名称（这里作记录‘资料attachmentList’用）
    fieldName: {
      type: String,
      default() {
        return "";
      },
    },
    // 当条数据(列表)
    curRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    click(){
      if(!this.preventClick){
        this.dialogVisible = true
      }

    },
    handleAttachmentDownload(att) {
      let isFromForm = this.$refs.attachmentList.isFromForm
      // ‘资料’文件夹下载 todo后续可能还需要支持其他来源
      if(att.dataType === 'folder'){
        let params = {
          formId: this.getFormId(),
          documentId: this.curRow._id,
          folderIds: att.id,
          appViewId: this.getAppViewId()
        }
        downloadWithZip(params).then(res => {
          window.open(res.data.url, '_blank');
        })
      } else {
        if(isFromForm){
          downloadWithZip({
            diskIds: att.id,
            appViewId: this.getAppViewId()
          }).then(res => {
            window.open(res.data.url, '_blank');
          })
        } else {
          if(att.attachment){
            att = att.attachment
          }
          downFile(att);
        }
      }
    },
    getTitle(obj) {
      let str = obj.originalName || obj.title;
      if (obj.extension) {
        str += "." + obj.extension;
      }
      return str;
    },
    checkFileImage(extension) {
      let suffix = ""
      if (extension) {
          suffix = extension.toLowerCase();
      }
      let enums = {
        jpg: "jpg2",
        jpeg: "jpg2",
        png: "png2",
        bmp: "bmp",
        heic: "heic",
        ico: "ico",
      };
      return enums[suffix]
    },
    getIconClassByExtension(extension) {
      let enums = {
        doc: "WORD2",
        docx: "WORD2",
        jpg: "jpg2",
        jpeg: "jpg2",
        html: "html2",
        mp3: "mp32",
        png: "png2",
        dll: "dll2",
        ppt: "PPT",
        pptx: "PPT",
        mp4: "mp42",
        pdf: "pdf1",
        gif: "gif1",
        exe: "exe",
        mpg: "mpg",
        psd: "psd1",
        mkv: "mkv",
        xls: "ECEL",
        xlsx: "ECEL",
        rmvb: "rmvb",
        txt: "txt1",
        wav: "wav",
        swf: "swf1",
        avi: "avi1",
        zip: "zip1",
        rar: "folder2",
        other: "other",
      };
      if (enums[extension]) {
        return enums[extension];
      } else {
        return "other";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.thumb-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.attachment-icon {
  width: 24px;
  height:24px;
  margin-right: 5px;
  .attachment-image {
    height: 24px;
    width: 24px;
  }

  .attachment-svg {
    width: 24px;
    height: 24px;
  }
}
</style>
