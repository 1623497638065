<template>
  <div class="rpa-workflow-detail" v-if="visible">
    <div class="d-header">
      <div style="font-size: 14px">
        【{{ title }}v{{ workflow?.version }}】执行详情
      </div>
      <i @click="close" style="cursor: pointer" class="el-icon-close"></i>
    </div>
    <div class="d-log">
      <template v-if="workflow">
        <rpa-node
          ref="rpaNode"
          :key="workflow.id"
          :node="workflow.rpaFlow.trigger"
          :lines.sync="workflow.rpaFlow.lines"
          :nodes.sync="workflow.rpaFlow.nodes"
          :isPaddingRight="false"
          :disabled="true"
          :flowedNodes="flowedNodes"
        >
          <!-- 结束节点 -->
          <div slot="end" class="rpa-flex-column">
            <div class="workflow-end-box">
              <div class="workflow-end"></div>
              <div class="workflow-end-text">流程结束</div>
            </div>
          </div>
        </rpa-node>
      </template>
    </div>
  </div>
</template>
<script>
import { getRobotWorkflow, getWorkflowDetail } from "./api";
import rpaNode from "./rpa-node";
export default {
  components: { rpaNode },
  provide() {
    return {
      getTrigger: () => {
        return this.workflow.rpaFlow.trigger;
      },
      getWorkflowConfig: () => {
        return this.workflow.rpaFlow.config;
      },
      getWorkFlowId: () => {
        return this.workflow.id;
      },
      getRobotId: () => {
        return this.workflow.robotId;
      },
      getWorkflowDataId: () => {
        return this.workflowDataId;
      },
      /**
       * 获取本流程参数
       */
      getRootParams: () => {
        let flowParams = this.workflow?.rpaFlow?.config?.flowParams;
        if (flowParams) {
          return flowParams;
        }
        return [];
      },
    };
  },
  data() {
    return {
      visible: false,
      workflow: null,
      flowedNodes: [],
      workflowDataId: "",
      title: "",
    };
  },
  methods: {
    async open(workflowDataId, workflowId) {
      let resRobot = await getRobotWorkflow({ workflowId });
      let resWorkflowDetail = await getWorkflowDetail({ workflowDataId });
      this.title = resWorkflowDetail.data.workflowData.title;
      this.flowedNodes = resWorkflowDetail.data.workflowData.flowedNodes;
      this.workflowDataId = workflowDataId;
      this.visible = true;
      this.$nextTick(() => {
        this.workflow = resRobot.data.workflow;
      });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss">
.rpa-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  margin: 0 auto;
  background-color: #f4f5f7;
}
</style>
<style lang="scss" scoped>
.rpa-workflow-detail {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1010;
  width: 100%;
  height: 100%;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
  .d-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 40px;
    background-color: #ffffff;
  }
  .d-log {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
}

.workflow-end-box {
  margin: 0 50px 120px;
  .workflow-end {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAABACAQAAABokzttAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiCAkRLQN+59ZwAAAAfUlEQVRo3u3ZwQ2AIBAAQbFn69ce7qEumWng2BDC49Z1H1s7vz6AQIEC4c/WPfwH13Dg9Nudztv+DQqsE1gnsE5gncA6gXUC6wTWCawTWCewTmCdwLo1XRa8vWOYztv+BgXWCawTWCewTmCdwDqBdQLrBNYJrBNYJ7BOYN0Dws4H6yyzXK8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDgtMDlUMTc6NDU6MDMrMDg6MDBVMAcOAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTA4LTA5VDE3OjQ1OjAzKzA4OjAwJG2/sgAAAABJRU5ErkJggg==)
      no-repeat;
    background-size: 56px 32px;
    height: 32px;
    margin: 0 auto;
    width: 56px;
  }
  .workflow-end-text {
    color: #9e9e9e;
    font-size: 13px;
    text-align: center;
  }
}
</style>
