import request from "@/utils/request";
import store from "@/store";

/**
 * @template T
 * @typedef zggPromise
 * @type {import("../../../utils/request").customPromise<T>}
 */

/**
 * 创建智能助手
 * @param {object} data
 * @param {string} data.companyId 单位id
 * @param {string} [data.dirId] 所在目录
 * @param {title} data.title 名称
 * @param {string} data.triggerType 触发器类型
 * @returns
 */
export function createRobot(data) {
  return request({
    url: "/robot/create",
    method: "post",
    data,
  });
}

/**
 * 重命名智能助手
 * @param {object} data
 * @param {string} data.robotId 智能助手ID
 * @param {string} data.title 智能助手名称
 * @returns
 */
export function renameRobot(data) {
  return request({
    url: "/robot/rename",
    method: "post",
    data,
  });
}

/**
 * 删除智能助手
 * @param {string} robotId 智能助手ID
 * @returns
 */
export function delRobot(robotId) {
  return request({
    url: "/robot/delete",
    method: "post",
    data: { robotId },
  });
}

/**
 * 复制智能助手
 * @param {object} data
 * @param {string} data.duplicateRobotId 复制智能助手ID
 * @param {string} data.title 智能助手名称
 * @returns
 */
export function copyRobot(data) {
  return request({
    url: "/robot/duplicate",
    method: "post",
    data,
  });
}

/**
 * 创建智能助手流程
 * @param {object} data
 * @param {string} [data.duplicateWorkflowId] 复制哪个流程版本
 * @param {string} data.robotId 智能助手ID
 * @returns {zggPromise<import("./rpa-type").workflow>}
 */
export function createRobotWorkflow(data) {
  return request({
    url: "/robot/workflow/create",
    method: "post",
    data,
  });
}

/**
 * 获取智能助手的流程列表
 * @param {object} data
 * @param {number} pageNumber 当前页码
 * @param {number} pageSize 每页记录数
 * @param {string} robotId 智能助手id
 * @returns
 */
export function fetchRobotWorkflowList(data) {
  return request({
    url: "/robot/workflow/list",
    method: "post",
    data,
  });
}

/**
 * 获取智能助手流程详情
 * @param {object} data workflowId,robotId 二选一
 * @param {string} [data.robotId]
 * @param {string} [data.workflowId]
 * @returns {zggPromise<import("./rpa-type").workflow>}
 */
export function getRobotWorkflow(data) {
  return request({
    url: "/robot/workflow/detail",
    method: "post",
    data,
  });
}

/**
 * 保存智能助手流程
 * @param {object} data
 * @param {string} data.workflowId 流程ID
 * @param {import("./rpa-type").rpaFlow} data.rpaFlow
 * @returns
 */
export function saveRobotWorkflow(data) {
  return request({
    url: "/robot/workflow/saveFlow",
    method: "post",
    data,
  });
}

/**
 * 启用智能助手流程
 * @param {string} workflowId 流程ID
 * @returns
 */
export function enableRobotWorkflow(workflowId) {
  return request({
    url: "/robot/workflow/enable",
    method: "post",
    data: { workflowId },
  });
}

/**
 * 停用智能助手流程
 * @param {string} workflowId 流程ID
 * @returns
 */
export function disableRobotWorkflow(workflowId) {
  return request({
    url: "/robot/workflow/disable",
    method: "post",
    data: { workflowId },
  });
}

/**
 * 删除智能助手流程
 * @param {string} workflowId 流程ID
 * @returns
 */
export function delRobotWorkflow(workflowId) {
  return request({
    url: "/robot/workflow/delete",
    method: "post",
    data: { workflowId },
  });
}

/**
 *
 * @param {object} data
 * @param {string} data.masterRobotWorkflowDataId 智能助手流程数据ID
 * @param {number} [data.pageNumber] 当前页码
 * @param {number} [data.pageSize] 每页记录数
 * @returns
 */
export function robotWorkflowLogList(data) {
  return request({
    url: "/robot/workflowDataLog/list",
    method: "post",
    data,
  });
}

/**
 * 获取智能助手流程数据列表
 * @param {object} data
 * @param {string} data.robotId 智能助手ID
 * @param {number} [data.pageNumber] 当前页码
 * @param {number} [data.pageSize] 每页记录数
 * @param {string} [data.title] 流程数据标题
 * @returns
 */
export function fetchRobotFlowDataList(data) {
  return request({
    url: "/robot/workflowData/list",
    method: "post",
    data,
  });
}

/**
 * 单位应用流程列表
 * @returns
 */
export function companyWorkflowList(data) {
  return request({
    url: "/companyWorkflow/list",
    method: "post",
    data: {
      companyId: store.getters.currentCompanyId,
      ...data,
    },
  });
}

/**
 * 根据id获取单位流程详情用于校验是否存在这个流程，不存在返回空
 * @param {*} id
 * @returns
 */
export function getCompanyWorkflowDetail(id) {
  return request({
    url: "/companyWorkflow/detail",
    method: "post",
    data: { id },
  });
}

/**
 * 单位应用流程详情
 * @returns
 */
export function companyWorkflowDetail(data) {
  return request({
    url: "/company/workflow/detail",
    method: "post",
    data,
  });
}

/**
 * 接收webhook
 * @returns
 */
export function getWebhookDetail(data) {
  return request({
    url: `/robot/webhook/${data.robotId}`,
    method: "post",
    data,
  });
}

/**
 *
 * @param {object} data
 * @param {string} data.workflowDataId 流程数据ID
 * @returns
 */
export function getWorkflowDetail(data) {
  return request({
    url: "/robot/workflowData/detail",
    method: "post",
    data,
  });
}

/**
 * 智能助手流程节点数据
 * @param {object} data
 * @param {string} data.nodeKey 流程节点key
 * @param {string} data.workflowDataId 流程数据id
 * @returns
 */
export function getNodeWorkflowData(data) {
  return request({
    url: "/robot/workflowData/nodeData",
    method: "post",
    data,
  });
}

/**
 * 智能助手详情
 * @param {object} data
 * @param {string} data.robotId 智能助手id
 * @returns
 */
export function getRobotDetail(data) {
  return request({
    url: "/robot/detail",
    method: "post",
    data: {
      companyId: store.getters.currentCompanyId,
      ...data,
    },
  });
}

/**
 * 智能助手查找（点击触发类型）
 * @param {object} data
 * @param {string} data.collection
 * @param {string} data.companyId 单位id
 * @param {string} data.triggerKey 触发组件key
 * @returns
 */
export function findClickTrigger(data) {
  return request({
    url: "/robot/findClickTrigger",
    method: "post",
    data: {
      companyId: store.getters.currentCompanyId,
      ...data,
    },
  });
}

/**
 * 智能助手创建(点击触发智能助手)
 * @param {object} data
 * @param {string} collection 数据collection
 * @param {string} collectionTitle 表单标题
 * @param {string} companyId 单位id
 * @param {string} title 名称
 * @param {string} triggerKey 触发组件key
 * @param {string} triggerTitle 触发组件标题
 * @returns
 */
export function createClickTrigger(data) {
  return request({
    url: "/robot/createClickTrigger",
    method: "post",
    data: {
      companyId: store.getters.currentCompanyId,
      ...data,
    },
  });
}

/**
 * 创建子流程
 * @param {object} data
 * @param {string} data.title 精灵名称
 * @param {string} data.collection 数据collection
 * @param {string} data.collectionTitle 表单标题
 * @param {string} data.companyId 单位id
 * @param {string} [data.dirId] 所在目录ID
 * @returns
 */
export function createCollectionTrigger(data) {
  return request({
    url: "/robot/createCollectionTrigger",
    method: "post",
    data: {
      companyId: store.getters.currentCompanyId,
      ...data,
    },
  });
}

/**
 * 获取智能助手列表
 * @param {object} data
 * @param {string} [data.idNotEq] id不等于，主要用于排除自己
 * @param {number} [data.pageNumber] 当前页码
 * @param {number} [data.pageSize] 每页记录数
 * @param {string} [data.title] 智能助手名称
 * @param {string} [data.triggerCollection] 触发数据源
 * @param {string} [data.triggerType] 触发类型
 * @param {string} [data.subRobotId] 包含指定子流程
 * @returns {zggPromise<import("./rpa-type").robotPage>}
 */
export function getRobotList(data) {
  return request({
    url: "/robot/list",
    method: "post",
    data: {
      companyId: store.getters.currentCompanyId,
      ...data,
    },
  });
}

/**
 * 测试节点（自定义请求）
 * @param {object} data
 * @param {object} data.contextData 数据 nodeKey_name:value
 * @param {object} data.node 节点
 * @returns
 */
export function nodeTest(data) {
  return request({
    url: "/robot/node/test",
    method: "post",
    data,
  });
}
