<template>
  <div class="header-row">
    <el-input
      v-model="name"
      @input="input"
      placeholder="请输入"
      size="mini"
      style="width: 150px"
    ></el-input>
    <el-select
      v-if="isShowComponentName"
      v-model="componentName"
      style="margin-left: 10px; width: 100px"
      placeholder="请选择"
      size="mini"
    >
      <el-option label="文本" value="input"></el-option>
      <el-option label="附件" value="attachment_uploader"></el-option>
    </el-select>

    <el-select
      :value="item.paramValue.valueType"
      size="mini"
      style="width: 100px; margin-left: 10px"
      placeholder="请选择"
      @change="changeValueType"
    >
      <template v-if="componentName == 'input'">
        <el-option label="自定义" value="custom"></el-option>
        <el-option label="公式" value="rely"></el-option>
      </template>
      <el-option label="节点字段" value="nodeField"></el-option>
    </el-select>

    <div class="header-row" style="margin-left: 10px; flex: 1">
      <!-- 公式 -->
      <template v-if="item.paramValue.valueType == 'rely'">
        <div style="width: 100%; position: relative">
          <el-input
            size="mini"
            :value="relyTitle"
            placeholder="请设置公式"
            style="width: 100%"
            readonly
          ></el-input>
          <div
            @click.prevent.stop="openFormula"
            style="
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            "
          ></div>
        </div>
        <formula-dialog
          title="设置公式"
          :originalFormula="
            item.paramValue.rely && item.paramValue.rely.originalFormula
          "
          :nodeKey="nodeKey"
          :treeNodeList="nodeList"
          :fetchMetaFieldComponentList="fetchMetaComponents"
          :fetchRobotParams="fetchRobotParams"
          @save="saveRely"
          ref="relyDialog"
          :disabled="disabled"
          :disabled-funs="['GETUSER']"
        ></formula-dialog>
      </template>
      <!-- 自定义 -->
      <el-input
        v-else-if="item.paramValue.valueType == 'custom'"
        v-model="customValue"
        placeholder="请输入"
        size="mini"
      ></el-input>
      <!-- 节点字段 -->
      <rpa-node-fields
        v-else-if="item.paramValue.valueType == 'nodeField'"
        :nodeField="item.paramValue.nodeField"
        :tableField="item.param"
        fieldConfigKey="fillComponents"
        :disabled="disabled"
        :hasBaseRootParam="false"
        :isAnyComponentName="componentName == 'input'"
      ></rpa-node-fields>
    </div>
    <i style="margin-left: 10px" @click="del" class="el-icon-delete"></i>
  </div>
</template>
<script>
import rpaNodeFields from "./rpa-node-fields";
import { fromNodesMixins } from "./rpa-utils";

import { fetchMetaComponents } from "@/api/form";
import { getRobotWorkflow } from "./api";
import { isEmpty } from "@/zgg-core/utils";
export default {
  components: { rpaNodeFields },
  mixins: [fromNodesMixins],
  props: {
    item: Object,
    disabled: Boolean,
    nodeKey: String,
    index: Number,
    isShowComponentName: Boolean,
  },
  computed: {
    relyTitle() {
      if (!isEmpty(this.item.paramValue.rely)) {
        return "已设置公式";
      }
      return "";
    },
    name: {
      get() {
        return this.item.param.name;
      },
      set(val) {
        this.$set(this.item.param, "name", val);
      },
    },
    customValue: {
      get() {
        return this.item.paramValue.customValue;
      },
      set(val) {
        this.$set(this.item.paramValue, "customValue", val);
      },
    },
    nodeList() {
      let list = this.getParentNodes();
      let nodeList = list.map((item) => {
        return {
          ...item,
          collection: item.collection || item.formId,
          collectionTitle: item.collectionTitle || item.formTitle,
        };
      });
      if (this.rootFlowParams?.length) {
        nodeList.unshift({
          type: "root",
          key: "root",
          title: "本流程参数",
          components: this.rootFlowParams.map((item) => {
            return {
              ...item,
              key: item.name,
              nodeKey: "root",
            };
          }),
        });
      }

      /**
       * @type Array
       */
      let fromNodeList = this.getFromNodes();
      nodeList = this._.cloneDeep(nodeList);
      nodeList = nodeList.filter((item) => {
        if (item.dataSource == "node_data") {
          return fromNodeList.findIndex((opt) => opt.key == item.nodeKey);
        } else {
          return true;
        }
      });
      nodeList.forEach((item) => {
        if (item.dataSource == "node_data") {
          let node = fromNodeList.find((opt) => opt.key == item.nodeKey);
          item.nodeField = node.nodeField;
        }
      });

      return nodeList;
    },
    componentName: {
      get() {
        return this.item.param.componentName;
      },
      set(val) {
        let valueType = "custom";
        let nodeField;
        if (val == "attachment_uploader") {
          valueType = "nodeField";
          nodeField = {
            nodeKey: "",
            collection: "",
            name: "",
            title: "",
            componentName: "",
            picker: "",
          };
        }

        this.$set(this.item, "paramValue", {
          valueType: valueType,
          customValue: "",
          customValueDetail: undefined,
          nodeField: nodeField,
          rely: undefined,
        });
        this.$set(this.item.param, "componentName", val);
      },
    },
  },
  data() {
    return {
      rootFlowParams: [],
    };
  },
  created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
  },
  methods: {
    del() {
      this.$emit("del", this.index);
    },
    fetchMetaComponents,
    async fetchRobotParams(robotId) {
      let res = await getRobotWorkflow({ robotId });
      /**
       * @type {Array}
       */
      let flowParams = res.data.workflow?.rpaFlow?.config?.flowParams;
      if (flowParams) {
        flowParams.forEach((item) => {
          item.key = item.name;
        });
        return flowParams;
      }
      return [];
    },
    openFormula() {
      this.$refs.relyDialog.open();
    },
    saveRely(originalFormula) {
      this.$set(this.item.paramValue, "rely", { originalFormula });
      this.$refs.relyDialog.close();
    },
    input(value) {
      this.$set(this.item.param, "title", value);
    },
    changeValueType(value) {
      let paramValue = this._.cloneDeep(this.item.paramValue);
      paramValue.valueType = value;
      paramValue.customValue = "";
      paramValue.nodeField = undefined;
      paramValue.rely = undefined;
      paramValue.dynamicTime = undefined;
      if (value == "nodeField") {
        paramValue.nodeField = {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
          picker: "",
        };
      }

      this.$set(this.item, "paramValue", paramValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.header-row {
  display: flex;
  align-items: center;
}
</style>
