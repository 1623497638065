<template>
  <el-dialog
    v-if="dialogAddFileVisible"
    :close-on-click-modal="false"
    title="添加附件"
    :visible.sync="dialogAddFileVisible"
    width="900px"
    @close="handleDialogAddAssociateFileClose"
    append-to-body
    modal-append-to-body
  >
    <div class="app-container">
      <el-tabs type="border-card" @tab-click="handleTabClick">
        <el-tab-pane label="我的电脑">
          <upload ref="fileUpload" @change="handleUpLoadChange"></upload>
          <el-button type="primary" @click="addUploadFiles">添加</el-button>
        </el-tab-pane>
        <el-tab-pane v-if="false" label="我的网盘">
          <related-disk-table
            ref="personalRelatedDiskTable"
            @addAssociate="handleAddAssociate"
          ></related-disk-table>
        </el-tab-pane>
        <el-tab-pane
          label="单位网盘"
          v-if="false && currentUser && currentUser.companyId != 0"
        >
          <related-disk-table
            ref="companyRelatedDiskTable"
            @addAssociate="handleAddAssociate"
            :company-id="currentUser.companyId"
          ></related-disk-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/indexNew";
import { mapGetters } from "vuex";

import RelatedDiskTable from "@/components/RelatedDiskTable";

export default {
  name: "addAttachment",
  components: { Upload, RelatedDiskTable },
  computed: {
    ...mapGetters(["currentUser"]),
    dialogAddFileVisible: {
      get: function () {
        return this.dialogAddFileVisibleOuter;
      },
      set: function (newValue) {
        this.$emit("update:dialogAddFileVisibleOuter", newValue);
      },
    },
  },
  props: {
    dialogAddFileVisibleOuter: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.$refs.personalRelatedDiskTable.init();
      // this.$refs.companyRelatedDiskTable.init();
    });
  },
  data() {
    return {
      checkedTabLabel: "",
      fileSearchForm: {
        title: "",
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      uploadFileList: [],
    };
  },

  methods: {
    addUploadFiles() {
      this.dialogAddFileVisible = false;
      this.uploadFileList = this.$refs.fileUpload.fileList;
      console.log(this.uploadFileList);
      this.uploadFileList.forEach((item, index) => {
        item.attachmentId = item.id;
        this.handleAddAssociate(item, 30, index);
      });
      this.$refs.fileUpload.clearFiles();
    },
    handleUpLoadChange(files) {
      console.log("files", files);
    },
    handleDialogAddAssociateFileClose() {
      // this.apiGetResourceListPage(this.currentNode.data.id)
      this.$refs.fileUpload.clearFiles();
      this.dialogAddFileVisible = false;
      this.$emit("update:dialogAddFileVisibleOuter", false);
      // this.$emit("addDone",this.uploadFileList)
    },
    handleTabClick(tab) {
      this.checkedTabLabel = tab.label;
    },
    //处理添加关联资料的点击事件
    handleAddAssociate(row, type, index) {
      console.log(row);
      console.log(type);
      this.$emit("addDone", row);
      // if (this.checkedTabLabel === "我的网盘") {
      //   this.$set(this.personalDiskList, index, row);
      // } else if (this.checkedTabLabel === "单位网盘") {
      //   this.$set(this.companyDiskList, index, row);
      // }
      // row.added = true
      // let params = {
      //   "resource.directoryId": this.currentNode.data.id,
      //   "resource.type": type,
      //   "resource.targetId": type === 30 ? row.attachmentId : row.id,
      //   "resource.recordType": this.recordType,
      //   "resource.recordId": this.planForm.id,
      // };
      // if (type === 30) {
      //   // params.diskFolderId = row.id
      //   // params.diskId = row.id;
      //   // delete params['resource.type']
      //   /*if (this.checkedTabLabel === '单位网盘') {
      //     params.companyId = row.companyId
      //   }*/
      // }
      // // 这里有个bug，当在接口回掉中设置行数据的added属性，对应的表格并没有实时渲染
      // resourceAdd(params).then((resp) => {
      //   // row.added = true
      //   this.$set(row, "added", true);
      //   if (type === 20) {
      //     this.$set(this.myLibraryPlanTableList, index, row);
      //   } else if (type === 10) {
      //     this.$set(this.myLibraryFileTableList, index, row);
      //   } else if (type === 30 || type === "folder") {
      //     if (this.checkedTabLabel === "我的网盘") {
      //       this.$set(this.personalDiskList, index, row);
      //     } else if (this.checkedTabLabel === "单位网盘") {
      //       this.$set(this.companyDiskList, index, row);
      //     }
      //   }
      //   this.$message({type: "success", message: "添加关联资料成功！"});
      //   // this.apiGetResourceListPage(this.currentNode.data.id)
      //   this.apiGetPlanDetail();
      //   this.uploadFileList = [];
      // });
    },
  },
};
</script>

<style scoped>
</style>
