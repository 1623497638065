<template>
  <div>
    <div class="attr-row">
      <div class="attr-label">边框宽度</div>
      <el-input
        v-model.number="component.border.width"
        size="mini"
        placeholder=""
        style="width: 100%"
      ></el-input>
    </div>
    <div class="attr-row">
      <div class="attr-label">边框颜色</div>
      <el-color-picker
        size="small"
        v-model="component.border.color"
      ></el-color-picker>
    </div>
    <div class="attr-row">
      <div class="attr-label">边框线型</div>
      <border-style v-model="component.border.style"></border-style>
    </div>
    <div class="attr-row">
      <el-button @click="resetGridBorder" type="primary" size="mini"
        >重置单元格边框
      </el-button>
    </div>
  </div>
</template>
<script>
import BorderStyle from "../component/BorderStyle";
import { componentProps } from "./util";
export default {
  name: "attr-grid",
  components: { BorderStyle },
  props: componentProps,
  methods: {
    resetGridBorder() {
      this.component.components.forEach((row) => {
        row.components.forEach((col) => {
          col.border = undefined;
          col.borderLeft = undefined;
          col.borderRight = undefined;
          col.borderBottom = undefined;
          col.borderTop = undefined;
        });
      });
    },
  },
};
</script>
<style>
  .copy-fill:hover{
    color: #3870EA !important;
  }
  .shanchu_fill:hover{
    color:#D14343 !important;
  }
</style>