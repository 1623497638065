<template>
  <div>
    <div class="json-weui">
      <div :style="`width:${180 - ml}px`">
        <el-input
          v-model="title"
          placeholder="请填写参数名称"
          style="width: 100%"
          size="small"
          @blur="blurName"
        ></el-input>
      </div>

      <el-select
        v-model="componentName"
        placeholder="请选择"
        style="width: 120px; margin-left: 10px"
        size="small"
        @change="changeComponentName"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-if="componentName == 'array'"
        v-model="subComponentName"
        placeholder="请选择"
        style="width: 120px; margin-left: 10px"
        size="small"
      >
        <el-option
          v-for="item in subOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-input
        v-model="jsonPath"
        placeholder="请填写JSON Path值"
        style="flex: 1; margin-left: 10px"
        size="small"
      ></el-input>
      <i
        class="el-icon-delete-solid"
        style="margin-left: 10px; cursor: pointer"
        @click="deleteItem"
      ></i>
      <i
        class="el-icon-plus"
        style="margin-left: 10px; cursor: pointer"
        @click="addFields"
      ></i>
    </div>
    <div
      v-if="
        field.componentName == 'json_form' &&
        field.subFields &&
        field.subFields.length
      "
      style="margin-left: 30px"
    >
      <rpa-json-out-field
        v-for="item in field.subFields"
        :key="item.uuid"
        :field="item"
        :ml="30"
        :outputFields="field.subFields"
      ></rpa-json-out-field>
    </div>
  </div>
</template>
<script>
import { uuid } from "@/zgg-core/utils";
import { isEmpty } from "@/zgg-core/relyUtil";
export default {
  name: "rpa-json-out-field",
  props: {
    field: Object,
    outputFields: Array,
    ml: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    title: {
      get() {
        return this.field.title;
      },
      set(val) {
        this.$set(this.field, "title", val);
      },
    },
    jsonPath: {
      get() {
        return this.field.jsonPath;
      },
      set(val) {
        this.$set(this.field, "jsonPath", val);
      },
    },
    componentName: {
      get() {
        if (
          [
            "checkbox_group",
            "user_list_select",
            "department_list_select",
          ].includes(this.field.componentName)
        ) {
          return "array";
        } else {
          return this.field.componentName;
        }
      },
      set(val) {
        if (val == "array") {
          this.$set(this.field, "componentName", "checkbox_group");
        } else {
          this.$set(this.field, "componentName", val);
        }
      },
    },
    subComponentName: {
      get() {
        return this.field.componentName;
      },
      set(val) {
        this.$set(this.field, "componentName", val);
      },
    },
    options() {
      return [
        {
          label: "文本",
          value: "input",
        },
        {
          label: "数值",
          value: "input_number",
        },
        {
          label: "日期时间",
          value: "date_picker",
        },
        {
          label: "人员",
          value: "user_select",
        },
        {
          label: "部门",
          value: "department_select",
        },
        {
          label: "普通数组",
          value: "array",
        },
        {
          label: "对象数组",
          value: "json_form",
        },
      ];
    },
    subOptions() {
      return [
        {
          label: "文本",
          value: "checkbox_group",
        },
        {
          label: "人员",
          value: "user_list_select",
        },
        {
          label: "部门",
          value: "department_list_select",
        },
      ];
    },
  },
  methods: {
    blurName() {
      if (isEmpty(this.title)) {
        return;
      }
      if (
        this.outputFields.findIndex(
          (item) => item.title == this.title && item.name != this.field.name,
        ) >= 0
      ) {
        this.title = this.title + "_" + uuid();
      }
    },
    changeComponentName(val) {
      if (val == "json_form") {
        this.$set(this.field, "subFields", []);
      } else {
        this.$set(this.field, "subFields", undefined);
      }
    },
    deleteItem() {
      this.$confirm("确定要删除吗", "提示").then(() => {
        let outputFields = this.outputFields;
        let index = outputFields.findIndex(
          (item) => item.nam == this.field.name,
        );

        outputFields.splice(index, 1);
      });
    },

    addFields() {
      let outputFields = this.outputFields;
      if (this.field.componentName == "json_form") {
        outputFields = this.field.subFields;
      }
      outputFields.push({
        name: uuid(),
        title: "",
        componentName: "input",
        jsonPath: "",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.json-weui {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
