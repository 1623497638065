import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import app from "./modules/app";
import settings from "./modules/settings";
import user from "./modules/user";
// import createPersistedState from 'vuex-persistedstate'
import area from "@/store/modules/area";
import bulletin from "@/store/modules/bulletin";
import sysFields from "@/store/modules/sysFields";
import rpaNodeFields from "@/store/modules/rpa-node-fields";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    area,
    bulletin,
    sysFields,
    rpaNodeFields,
  },
  getters,
  // plugins: [createPersistedState()],
});

export default store;
