<template>
  <div>
    <attr-table-source
      v-model="component.tableName"
      :tableTitle="tableTitle"
      :componentName="component.componentName"
      @changeSource="changeSource"
    ></attr-table-source>
    <div class="attr-row">
      <div class="attr-label">显示方式</div>
      <el-select
        v-model="displayMode"
        placeholder=""
        size="small"
        style="width: 100%"
      >
        <el-option label="卡片" value="card"></el-option>
        <el-option label="表格" value="table"></el-option>
      </el-select>
    </div>
    <div class="attr-row">
      <div class="attr-label">数据选择方式</div>
      <el-select
        v-model="chooseMode"
        placeholder=""
        size="small"
        style="width: 100%"
        @change="changeChooseMode"
      >
        <el-option label="弹窗列表" value="popup"></el-option>
        <el-option label="新增数据" value="includeForm"></el-option>
      </el-select>
    </div>
    <!-- 弹窗列表设置 -->
    <attr-view-list
      v-if="chooseMode == 'popup'"
      :key="component.tableName + '_' + chooseMode"
      :component="component"
    ></attr-view-list>
    <!-- 下拉列表中显示字段 -->
    <attr-select-fields
      v-if="chooseMode == 'select'"
      :key="component.tableName + '_' + chooseMode"
      :component="component"
      :columns="componentList"
    ></attr-select-fields>
    <!-- 表单中显示字段 -->
    <attr-form-field
      :component="component"
      :component-list="componentList"
      :key="component.tableName + '_field'"
    ></attr-form-field>
    <!-- 关联数据初始化 -->
    <reference-data-init
      v-if="component.componentName == 'reference_data_list' && hasCreate"
      :initFieldValues.sync="component.initFieldValues"
      :currentComponentList="formComponents"
      :componentList="componentList"
      :name="component.name"
    ></reference-data-init>
    <!-- 过滤条件 -->
    <attr-filter
      :component="component"
      :params="params"
      :tableColumns="componentList"
      :key="component.tableName + '_filter'"
    ></attr-filter>
    <!-- 关联表操作 -->
    <attr-table-action
      :component="component"
      :key="component.tableName + '_action'"
    ></attr-table-action>
  </div>
</template>
<script>
import AttrTableAction from "./attr-table-action.vue";
import AttrTableSource from "./attr-table-source.vue";
import attrFormField from "@/views/lowCode/form/attribute/attr-form-field";
import attrViewList from "@/views/lowCode/form/attribute/attr-view-list";
import { componentProps } from "./util";
import { getFormDetail, getDataflow } from "../api";
import attrFilter from "@/views/lowCode/form/attribute/attr-filter";
import FieldsSelect from "@/components/FieldsSelect";
import attrSelectFields from "@/views/lowCode/form/attribute/attr-select-fields";
import { getFormComponents } from "../utils";
import { isEmpty } from "@zgg-core-utils/utils";
import FilledRule from "../component/FilledRule";
import ReferenceDataInit from "@/views/lowCode/form/component/ReferenceDataInit";
import { fetchMetaFieldComponentList } from "@/api/form";
import { aggregateDetail } from "../../view/api";

export default {
  name: "attr-reference-data-list",

  inject: ["getComponents"],
  props: componentProps,
  components: {
    AttrTableSource,
    AttrTableAction,
    attrFormField,
    attrFilter,
    FieldsSelect,
    attrViewList,
    attrSelectFields,
    FilledRule,
    ReferenceDataInit,
  },
  computed: {
    jsonFormName() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      let parentField = components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key,
            ) >= 0,
        );

      if (parentField) {
        return parentField.name;
      }
      return;
    },
    hasCreate() {
      return (
        this.component.allowActionEvents &&
        this.component.allowActionEvents.findIndex((item) => item == "add") >= 0
      );
    },
    formComponents() {
      return this.getComponents();
    },
    displayMode: {
      get() {
        if (this.component.displayMode) {
          return this.component.displayMode;
        }
        return "card";
      },
      set(val) {
        this.$set(this.component, "displayMode", val);
      },
    },
    chooseMode: {
      get() {
        if (this.component.chooseMode) {
          return this.component.chooseMode;
        }
        return "popup";
      },
      set(val) {
        this.$set(this.component, "chooseMode", val);
      },
    },
    isDataflow() {
      return (
        !isEmpty(this.component.tableName) &&
        this.component.tableName.indexOf("dataflow_") == 0
      );
    },
  },
  data() {
    return {
      tableTitle: "",
      componentList: [],
    };
  },
  created() {
    if (this.component.tableName) {
      this.getDetail(this.component.tableName);
    }
  },
  methods: {
    changeFilledRule(list) {
      this.$set(this.component, "filledRule", list);
    },
    getDetail(tableName) {
      if (tableName.indexOf("dataflow_") == 0) {
        // 数据流
        this.component.allowActionEvents = ["detail"];
        getDataflow(tableName).then((res) => {
          this.tableTitle = res.data.dataflow.title;
          let output = res.data.dataflow.flow.stages.find(
            (item) => item.stageType == "output",
          );

          let componentList = output.fields.map((item) => item.component);
          this.$set(this, "componentList", componentList);
        });
      } else if (tableName.indexOf("at_") == 0) {
        Promise.all([
          aggregateDetail({
            collection: tableName,
            removeComponent: true,
          }),
          fetchMetaFieldComponentList(tableName, []),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.aggregateTable.title;
          this.$set(this, "componentList", resp[1].data.list);
        });
      } else {
        // todo 表单
        Promise.all([
          getFormDetail(tableName, true),
          fetchMetaFieldComponentList(tableName, [
            "flowStatus",
            "createrId",
            "currentFlowNodeTitles",
            "currentFlowWorkUserIds",
          ]),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.form.title;
          this.$set(this, "componentList", resp[1].data.list);
        });
      }
    },
    changeSource(tableName) {
      this.component.columns = [];
      this.component.tableColumns = [];
      this.component.tableName = tableName;
      this.component.filledRule = [];
      this.component.dataFilter = {
        rel: "and",
        advanceQuery: [],
      };
      this.component.initFieldValues = [];

      this.getDetail(tableName);
    },
    changeChooseMode(mode) {
      //如果改为下拉方式，则删除searchFormView
      if (mode == "select" && this.component.searchFormView) {
        delete this.component.searchFormView;
      }
      this.component.tableColumns = [];
    },
  },
};
</script>
