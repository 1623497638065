export function getFileExtension(extension) {
    let suffix = ""
    if (extension) {
        suffix = extension.toLowerCase();
    }
    let enums = {
        jpg: "jpg",
        jpeg: "jpeg",
        png: "png",
        bmp: "bmp",
        heic: "heic",
        ico: "ico",
        pdf: "pdf",
        mp4: "mp4",
        mp3: "mp3",
        m4p: "m4p",
        flv: "flv",
        m3u: "m3u",
        wav: "wav",
        wma: "wma",
        txt: "txt"
    }
    return enums[suffix];
}

export function getRecordCNName(recordType) {
    let enums = {
        division: "项目划分",
        company_project: "项目库",
        company_project_section: "项目目录",
        plan: "资料整编",
        document: "我的文件",
        contract_income: "合同收款",
        contract_payment: "项目支出",
        contract_company: "单位合同",
        material: "清单管理",
        bulletin: "看板详情",
        project_excel: '项目表格',
        project_division: '项目划分'
        // section:"标段",
        // project:"项目库项目"
    }
    return enums[recordType]
}

export function getRecordRouteName(recordType) {
    let enums = {
        division: "项目划分详情",
        company_project_section: "子项目详情",
        company_project: "项目详情",
        contract_income: "收款详情",
        contract_payment: "支出详情",
        contract_company: "合同详情",
        plan:"目录详情",
        material: "清单详情",
        bulletin: "看板详情",
        excel_template: "表格模板详情",
        resource_apply: "数字档案馆",
        project_excel: "项目表格详情",
        project_division: "划分"
        // section:"标段",
    }
    return enums[recordType]
}

// 表单引用图谱:表单场景枚举
export function getFormScence(scence) {
    let enums = {
        referenceData_linkForm: "关联数据:关联表",
        formData_linkForm: "关联查询:关联表",
        referenceDataList_linkForm: "关联多条:关联表",
        option_linkForm: "选项:关联数据 - 关联表单",
        dataLinkage_linkForm: "数据联动:联动表单",
        aggregateTable_input: "聚合表:数据来源",
        dataflow_input: "数据流:输入源 ",
        spirit_trigger_linkForm: "智能助手1.0:触发表单",
        spirit_actions_linkForm: "智能助手1.0:执行动作目标表单",
        robot_trigger_linkForm: "智能助手2.0:触发表单",
        robot_actions_linkForm: "智能助手2.0:执行动作目标表单",
        robot_subflow_linkRobot: "智能助手2.0:子流程",
        button_linkForm: "按钮:关联表",
        button_linkRobot: "按钮:关联智能助手",
        summary_linkForm: "汇总组件:关联表",
        jsonInput_linkForm: "子表单:关联实体表",
        dashboard_input: "仪表盘:数据来源",
        cascadeData_linkForm: "级联数据: 关联表",
    }
    return enums[scence]
}

// 表单引用图谱:字段场景枚举
export function getFormFieldScence(scence) {
    let enums = {
        // 关联数据 - 关联表
        referenceData_columns: "表单显示字段",
        referenceData_tableColumns: "下拉/弹窗列表显示字段",
        referenceData_filter: "过滤条件",
        referenceData_filledRule: "填充取值字段",
        referenceData_initField: "默认值",
        referenceData_searchFormView: "查询表单",
        // 关联多条 - 关联表
        referenceDataList_columns: "表单显示字段",
        referenceDataList_tableColumns: "弹窗列表显示字段",
        referenceDataList_filter: "过滤条件",
        referenceDataList_searchFormView: "查询表单",
        // 关联查询 - 关联表
        formData_columns: "表单显示字段",
        formData_initField: "关联表默认值",
        formData_filter: "过滤条件",
        // 单选框/多选框/下拉 - 选项 - 关联数据 - 关联表单
        option_otherField: "其它表单字段",
        option_tableColumns: "下拉列表显示字段",
        option_filter: "过滤条件",
        // 数据联动 - 联动表单
        dataLinkage_filter: "数据联动 - 联动条件",
        dataLinkage_field: "数据联动 - 联动字段",
        // 按钮 - 关联表
        button_initField: "关联表默认值",
        // 汇总组件 - 关联表
        summary_filter: "过滤条件",
        summary_field: "汇总字段",
        // 聚合表 - 数据来源
        aggregateTable_relations: "数据来源关联字段",
        aggregateTable_groupFields: "单表行表头",
        aggregateTable_relyFields: "指标引用字段",
        aggregateTable_filter: "过滤条件",
        // 数据流 - 输入源 
        dataflow_selectedField: "节点选择字段",
        // 智能助手1.0 - 触发表单
        spirit_trigger_filter: "触发条件",
        spirit_trigger_field: "触发字段",
        // 智能助手1.0 - 执行动作目标表单
        spirit_actions_filter: "执行动作 - 过滤条件",
        spirit_actions_field: "执行动作 - 修改字段",
        // 智能助手2.0 - 触发表单
        robot_trigger_filter: "触发条件",
        robot_trigger_field: "触发字段",
        // 智能助手2.0 - 执行动作目标表单
        robot_actions_filter: "执行动作-过滤条件",
        robot_actions_field: "执行动作-修改字段",
        // 表单引用自己的字段场景
        rely: "公式编辑 - 引用字段",
        formEvent_trigger: "前端事件 - 触发字段",
        formEvent_request_header: "前端事件 - Header/Body-填充字段",
        formEvent_response_fillField: "前端事件 - 返回值-填充字段",
        // 智能助手2.0 - 子流程参数传递字段
        robot_subflow_field: "智能助手2.0:子流程参数传递字段",
        // 智能助手2.0 - 子表字段
        robot_subform_field: "智能助手2.0:子表字段",
        // 级联组件
        cascadeData_referenceField: "级联数据 - 数据字段",
        cascadeData_field: "级联数据 - 下拉框显示字段",
        cascadeData_filter: "级联数据 - 过滤条件",
        jsonInput_columns: "子表单：显示字段",
        // 仪表盘引用字段场景
        dashboard_xfields: "仪表盘 - 图表维度",
        dashboard_metrics: "仪表盘 - 图表指标",
        dashboard_filter: "仪表盘 - 图表过滤条件",
        dashboard_table_columns: "仪表盘 - 明细表显示字段",
        dashboard_gattView_timeProgress: "仪表盘 - 甘特图时间进度字段",
    }
    return enums[scence]
}