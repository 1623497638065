<template>
  <div>
    <div @click="show" class="link-input">
      <div>{{ isLink ? "已设置数据联动" : "数据联动" }}</div>
      <i class="el-icon-edit-outline"></i>
    </div>
    <el-dialog
      title="数据联动"
      :visible.sync="dialogVisible"
      width="800px"
      append-to-body
      destroy-on-close
      @open="open"
      @closed="closed"
    >
      <div style="padding: 5px 20px">
        <div class="data-item">
          <div class="data-label">联动表单</div>
          <!-- <formTree
            v-model="dataLink.tableName"
            @change="changeForm"
          ></formTree> -->
          <el-input
            v-model="tableTitle"
            @click.native="dataSourceVisible = true"
            placeholder="请选择联动表单"
          ></el-input>
          <data-source
            :visible.sync="dataSourceVisible"
            v-model="dataLink.tableName"
            mode="table"
            @changeSource="changeForm"
          ></data-source>
        </div>
        <!-- 条件 -->
        <!-- <query-condition
          label="满足以下所有条件时"
          :relationQuery="dataLink.dataFilter.advanceQuery"
          :rel.sync="dataLink.dataFilter.rel"
          :currentComponentList="componentList"
          :componentList="viewMap[dataLink.tableName]"
          :form="form"
          :areaTree="areaTree"
        ></query-condition> -->
        <ui-advance-filter
          style="margin-bottom: 10px"
          :dataFilter="dataLink.dataFilter"
          :sourceComponentList="sourceComponentList"
          :componentList="viewMap[dataLink.tableName]"
          :name="this.form.name"
        ></ui-advance-filter>
        <div class="data-item" style="margin: 0">
          <div class="data-label">触发以下联动</div>
          <div class="row-center">
            <el-input
              style="width: 200px"
              disabled
              size="small"
              :value="formTitle"
            ></el-input>
            <div style="margin-left: 10px">联动显示</div>
            <el-select
              v-model="dataLink.fieldName"
              placeholder="联动表单字段"
              filterable
              size="small"
              style="margin-left: 10px"
            >
              <el-option
                v-for="item in linkFieldList"
                :key="item.name"
                :label="item.title"
                :value="item.name"
              >
                <i
                  class="iconfont"
                  :class="componentIcons[item.componentName]"
                ></i>
                <span>{{ item.title }}</span>
              </el-option>
            </el-select>
            <div style="margin-left: 10px">的值</div>
          </div>
        </div>
        <div v-if="form.componentName == 'json_form' && dataLink.fieldName">
          <el-popover
            popper-class="pop-over"
            placement="bottom-start"
            v-model="childVisible"
          >
            <el-button
              type="text"
              slot="reference"
              icon="el-icon-plus"
              style="padding: 5px 0px; margin: 10px 0px"
              >添加子字段</el-button
            >
            <div v-clickoutside="handleCloseChild" style="padding: 5px 0">
              <div
                v-for="item in jsonFormFields"
                :key="item.name"
                class="weui icon-item"
                :class="{ disabled: disableChild(item.name) }"
                @click="checkChild(item.name)"
              >
                {{ item.title }}
              </div>
            </div>
          </el-popover>
          <div>
            <div
              class="row-center"
              v-for="(item, subIndex) in dataLink.subFieldRelyList"
              :key="item.fieldName"
              style="margin-bottom: 10px"
            >
              <el-input
                :value="getChildTitle(item.fieldName)"
                style="width: 200px"
                disabled
                placeholder=""
                size="small"
              ></el-input>
              <div style="margin: 0 10px">显示为</div>
              <el-select
                v-model="item.relyFieldName"
                placeholder="被联动子表单子字段"
                size="small"
              >
                <el-option
                  v-for="child in getChildLinkFields(item.fieldName)"
                  :key="child.name"
                  :label="child.title"
                  :value="child.name"
                ></el-option>
              </el-select>
              <i
                style="margin-left: 10px"
                @click="delSubFields(subIndex)"
                class="el-icon-delete"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveDataLink" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { getDataflow, getFormDetail } from "../api";
import QueryCondition from "./QueryCondition";
import formTree from "@/views/lowCode/form/component/formTree";
import dataSource from "@/components/data-source";
import { fetchMetaFieldComponentList } from "@/api/form";
import { aggregateDetail } from "../../view/api";
import { componentsIcon } from "@zgg-core-utils/utils";
import {
  hasTableFiledByFilter,
  isCompleteDataFilter,
  isEmpty,
} from "@zgg-core-utils/utils";

export default {
  directives: { Clickoutside },
  components: {
    QueryCondition,
    formTree,
    dataSource,
  },
  props: {
    form: Object,
    componentList: Array,
    areaTree: Array,
  },
  data() {
    return {
      dataSourceVisible: false,
      childVisible: false,
      dialogVisible: false,
      dataLink: {
        fieldName: "",
        tableName: "",
        dataFilter: {
          rel: "and",
          advanceQuery: [
            {
              key: "",
              oper: "",
              valueType: "tableField",
              value: "",
              popverVisible: false,
            },
          ],
        },

        subFieldRelyList: [],
      },
      viewMap: {},
      tableTitle: "",
      componentIcons: componentsIcon,
    };
  },
  computed: {
    // 设置数据联动时排除被联动字段
    sourceComponentList() {
      let componentList = [];
      if (this.componentList && this.form) {
        componentList = this.componentList.filter(
          (item) => item.name !== this.form.name,
        );
      }
      return componentList;
    },
    fieldList() {
      let componentList = [];
      let tableName = this.dataLink.tableName;
      if (tableName && this.viewMap[tableName]) {
        componentList = this.viewMap[tableName];
      }
      let arr = [
        "json_form",
        "image_uploader",
        "attachment_uploader",
        "divider",
        "sign_input",
        "form_data",
        "sn_input",
        "reference_data",
      ];
      let list = [];
      componentList.forEach((item) => {
        if (!arr.includes(item.componentName)) {
          if (item.componentName == "tab") {
            item.components.forEach((tap) => {
              tap.components.forEach((col) => {
                if (!arr.includes(col.componentName)) {
                  list.push(this._.cloneDeep(col));
                }
              });
            });
          } else {
            list.push(this._.cloneDeep(item));
          }
        }
      });

      return list;
    },
    linkFieldList() {
      let c1 = [
        "input",
        "text_area",
        "radio_group",
        "select",
        "sn_input",
        "summary_data",
      ];
      let arr = [
        "image_uploader",
        "attachment_uploader",
        "divider",
        "sign_input",
        "form_data",

        "reference_data",
      ];
      let componentList = [];
      let tableName = this.dataLink.tableName;
      if (tableName && this.viewMap[tableName]) {
        componentList = this._.cloneDeep(this.viewMap[tableName]);
        // 汇总组件设置为表单字段
        componentList.forEach((item) => {
          if (item.componentName == "summary_data") {
            item.form = true;
            item.name = item.key;
          }
        });
      }
      let list = [];

      let buildFields = (components, title) => {
        components.forEach((item) => {
          if (item.form) {
            if (arr.includes(item.componentName)) {
              return;
            }
            let obj = this._.cloneDeep(item);
            if (title) {
              obj.title = title + "." + obj.title;
            }
            if (c1.includes(this.form.componentName)) {
              if (c1.includes(item.componentName)) {
                list.push(obj);
              }
              if (item.componentName == "summary_data") {
                let componentName;
                if (item.metric.subColumns && item.metric.subColumns.length) {
                  if (
                    item.metric.subColumns[0].field.componentName ==
                    "date_picker"
                  ) {
                    componentName = "date_picker";
                  } else {
                    componentName = "input_number";
                  }
                } else {
                  if (
                    item.metric.field &&
                    item.metric.field.componentName == "date_picker"
                  ) {
                    componentName = "date_picker";
                  } else {
                    componentName = "input_number";
                  }
                }
                if (componentName == this.form.componentName) {
                  list.push(obj);
                }
              }
            } else {
              if (this.form.componentName == item.componentName) {
                list.push(obj);
              }
              if (item.componentName == "summary_data") {
                let componentName;
                if (item.metric.subColumns && item.metric.subColumns.length) {
                  if (
                    item.metric.subColumns[0].field.componentName ==
                    "date_picker"
                  ) {
                    componentName = "date_picker";
                  } else {
                    componentName = "input_number";
                  }
                } else {
                  if (
                    item.metric.field &&
                    item.metric.field.componentName == "date_picker"
                  ) {
                    componentName = "date_picker";
                  } else {
                    componentName = "input_number";
                  }
                }
                if (componentName == this.form.componentName) {
                  list.push(obj);
                }
              }
            }
          } else if (item.container) {
            let str;
            if (item.componentName == "tab_pane") {
              str = item.title;
            }
            buildFields(item.components, item.title);
          }
        });
      };
      buildFields(componentList);

      return list;
    },
    jsonFormFields() {
      let arr = [
        "image_uploader",
        "attachment_uploader",
        "divider",
        "sign_input",
        "form_data",
        "sn_input",
        "json_form",
        "radio_group",
        "checkbox_group",
        "reference_data",
      ];
      let list = this.form.components.filter(
        (item) => !arr.includes(item.componentName),
      );
      return list;
    },

    formTitle() {
      let title = this.form.title;
      this.componentList.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.form.name) {
              title = item.title + "." + title;
            }
          });
        }
        if (item.componentName == "tab") {
          item.components.forEach((tap) => {
            tap.components.forEach((col) => {
              if (col.componentName == "json_form") {
                col.components.forEach((sub) => {
                  if (sub.name == this.form.name) {
                    title = tap.title + "." + col.title + "." + title;
                  }
                });
              }
            });
          });
        }
      });
      return title;
    },
    isLink() {
      return (
        this.form.dataLinkage &&
        this.form.dataLinkage.dataFilter &&
        this.form.dataLinkage.dataFilter.advanceQuery &&
        this.form.dataLinkage.dataFilter.advanceQuery.length
      );
    },
  },
  methods: {
    delSubFields(index) {
      this.$delete(this.dataLink.subFieldRelyList, index);
    },
    disableChild(name) {
      return (
        this.dataLink.subFieldRelyList.findIndex(
          (item) => item.fieldName == name,
        ) >= 0
      );
    },
    checkChild(name) {
      if (
        this.dataLink.subFieldRelyList.findIndex(
          (item) => item.fieldName == name,
        ) >= 0
      ) {
        return;
      }
      this.dataLink.subFieldRelyList.push({
        fieldName: name,
        relyFieldName: "",
      });
      this.childVisible = false;
    },
    getChildTitle(name) {
      let component = this.form.components.find((item) => item.name == name);
      if (component) {
        return component.title;
      } else {
        return "该字段已被删除";
      }
    },
    getChildLinkFields(name) {
      let arr = ["input", "select"];
      let arr2 = ["input", "select", "radio_group"];
      let obj = this.form.components.find((item) => item.name == name) || {};
      let componentList = this.viewMap[this.dataLink.tableName];
      if (!componentList) {
        return [];
      }

      let getComponent = (list) => {
        let $c = list.find((item) => item.name == this.dataLink.fieldName);
        if ($c) {
          return $c;
        }
        let components = list.filter((item) => item.componentName == "tab");
        for (let i = 0; i < components.length; i++) {
          const element = components[i];
          $c = element.components.find(
            (item) => item.name == this.dataLink.fieldName,
          );
          if ($c) {
            break;
          }
        }
        return $c;
      };

      let $parent = getComponent(componentList);

      return $parent.components.filter(
        (item) =>
          (arr.includes(obj.componentName) &&
            arr2.includes(item.componentName)) ||
          obj.componentName == item.componentName,
      );
    },
    handleCloseChild() {
      this.childVisible = false;
    },

    show() {
      this.dialogVisible = true;
    },
    closed() {
      this.$set(this, "dataLink", {
        fieldName: "",
        tableName: "",
        dataFilter: {
          rel: "and",
          advanceQuery: [
            {
              key: "",
              oper: "",
              valueType: "tableField",
              value: "",
            },
          ],
        },
      });
    },
    open() {
      if (this.isLink) {
        let dataLink = this._.cloneDeep(this.form.dataLinkage);
        if (isEmpty(dataLink.dataFilter)) {
          dataLink.dataFilter = {
            rel: "and",
            advanceQuery: [
              {
                key: "",
                oper: "",
                valueType: "tableField",
                value: "",
              },
            ],
          };
        }

        this.$set(this, "dataLink", dataLink);

        if (!this.viewMap[this.dataLink.tableName]) {
          this.getDetail(this.dataLink.tableName);
        }
      }
    },
    getDetail(tableName) {
      if (tableName.indexOf("dataflow_") == 0) {
        // 数据工厂
        fetchMetaFieldComponentList(tableName, []).then((res) => {
          console.log(res);
        });
        getDataflow(tableName).then((res) => {
          this.tableTitle = res.data.dataflow.title;
          let output = res.data.dataflow.flow.stages.find(
            (item) => item.stageType == "output",
          );
          let components = output.fields.map((item) => item.component);
          this.$set(this.viewMap, tableName, components);
        });
      } else if (tableName.indexOf("at_") == 0) {
        // 聚合表
        Promise.all([
          aggregateDetail({ collection: tableName, removeComponent: true }),
          fetchMetaFieldComponentList(tableName, []),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.aggregateTable.title;
          this.$set(this.viewMap, tableName, resp[1].data.list);
        });
      } else {
        // 普通表单

        getFormDetail(tableName).then((res) => {
          this.tableTitle = res.data.form.title;
          this.$set(
            this.viewMap,
            tableName,
            res.data.form.component.components,
          );
        });
      }
    },
    changeForm({ tableName, tableTitle }) {
      this.tableTitle = tableTitle;
      this.dataLink.fieldName = "";
      this.dataLink.dataFilter = {
        rel: "and",
        advanceQuery: [
          {
            key: "",
            oper: "",
            valueType: "tableField",
            value: "",
          },
        ],
      };
      this.dataLink.subFieldRelyList = [];

      if (this.viewMap[tableName]) {
        return;
      }
      this.getDetail(tableName);
    },

    saveDataLink() {
      if (this._.isEmpty(this.dataLink.tableName)) {
        this.$message.error("请选择联动表单");
        return;
      }
      if (this._.isEmpty(this.dataLink.fieldName)) {
        this.$message.error("请设置联动表单字段");
        return;
      }
      let isEmpty = !isCompleteDataFilter(
        this.dataLink.dataFilter.advanceQuery,
      );
      if (isEmpty) {
        this.$message.error("请设置完整的数据联动条件");
        return;
      }
      let has = hasTableFiledByFilter(this.dataLink.dataFilter.advanceQuery);
      if (!has) {
        this.$message.error("联动触发条件至少设置一个当前表单字段");
        return;
      }
      if (this.form.componentName == "json_form") {
        if (this.dataLink.subFieldRelyList.length == 0) {
          this.$message.error("请添加子表单字段");
          return;
        }

        if (
          this.dataLink.subFieldRelyList.findIndex((item) =>
            this._.isEmpty(item.relyFieldName),
          ) >= 0
        ) {
          this.$message.error("请完善子表单联动显示子字段");
          return;
        }
        this.form.components.forEach((item) => {
          item.dataLinkage = undefined;
          let isExist =
            this.dataLink.subFieldRelyList.findIndex(
              (field) => field.fieldName == item.name,
            ) >= 0;
          if (isExist) {
            item.rely = undefined;
            item.autoValue = false;
            item.autoValueTemplate = undefined;
            item.defaultValue = "";
          }
        });
      }

      this.$set(this.form, "dataLinkage", this._.cloneDeep(this.dataLink));
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.link-input {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 12px;
  color: #606266;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.02);
  }
}
.data-item {
  margin-bottom: 20px;
  .data-label {
    margin-bottom: 10px;
  }
  .data-sel {
    width: 100%;
  }
}
.row-center {
  display: flex;
  align-items: center;
}
.weui {
  display: flex;
  align-items: center;
}
.icon-item {
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e6f7f6;
  }
  &.disabled {
    color: #c3cdda;
  }
}
.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}
.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
.icon-text {
  font-size: 14px;
}
.flex {
  flex: 1;
}
.el-value {
  flex: 1;
  ::v-deep {
    .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.el-prefix {
  border: 1px solid #dcdfe6;
  border-right: 0;
  height: 32px;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}
.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
