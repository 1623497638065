<template>
  <el-dialog
    title="设置过滤条件"
    :visible.sync="visible"
    width="600px"
    append-to-body
    :before-close="handleClose()"
    @open="handleDialogOpened"
  >
    <div
      class="has-padding user-list-dialog__content"
      style="padding: 10px; overflow: auto"
      v-if="currentFilterNode"
    >
      {{ currentFilterNode.title }}
      <el-select
        v-model="currentFilterNode.oper"
        size="small"
        style="width: 200px"
        @change="changeOper"
      >
        <el-option
          v-for="item in getDisplayQueryOpers(currentFilterNode.componentName)"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="flex-query" v-if="currentFilterNode.oper != 'nil' && currentFilterNode.oper !='nnil'">
        <el-popover
          popper-class="pop-over"
          placement="bottom-start"
          v-model="popverVisible"
          trigger="click"
        >
          <div slot="reference" class="weui el-prefix" style="width: 49px">
            <div
              v-if="currentFilterNode.valueType == 'currentUserField'"
              class="icon-form"
            >
              C
            </div>
            <i v-else class="el-icon-edit-outline icon-edit"></i>
            <i
              class="el-icon-caret-bottom"
              style="color: #91a1b7; margin-left: 2px"
            ></i>
          </div>
          <div style="padding: 5px 0">
            <div
              @click="checkItemType('currentUserField')"
              class="weui icon-item"
            >
              <div class="icon-form">C</div>
              <div>当前用户</div>
            </div>
            <div 
              @click="checkItemType('custom')" 
              class="weui icon-item"
            >
              <i class="el-icon-edit-outline icon-edit"></i>
              <div>自定义</div>
            </div>
          </div>
        </el-popover>
        <template v-if="currentFilterNode.valueType == 'currentUserField'">
          <el-select
            v-model="currentFilterNode.value"
            size="small"
            style="width: 100%"
          >
            <el-option
              v-for="opt in fieldOptions"
              :key="opt.name"
              :label="'当前用户.' + opt.title"
              :value="opt.name"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <template v-if="currentFilterNode.componentName == 'address_input'">
            <el-cascader
              v-if="
                currentFilterNode.oper != 'nil' &&
                currentFilterNode.oper != 'nnil'
              "
              :options="areaList"
              style="width: 100%"
              placeholder="请选择地址"
              :props="areaProps"
              :value="getAddr(currentFilterNode.value)"
              @change="changeAddr(currentFilterNode, $event)"
              :key="currentFilterNode.oper"
              size="small"
            >
            </el-cascader>
          </template>
          <template
            v-else-if="
              currentFilterNode.componentName == 'date_picker' &&
              currentFilterNode.oper != 'nil' &&
              currentFilterNode.oper != 'nnil'
            "
          >
            <date-picker-interface
              v-model="currentFilterNode.value"
              placeholder="请选择日期"
              size="small"
              style="width: 100%"
              :picker="currentFilterNode.picker"
              :field="currentFilterNode"
              :hasNow="false"
            >
            </date-picker-interface>
          </template>
          <el-select
            v-else-if="
              isCostomOption &&
              currentFilterNode.oper != 'nil' &&
              currentFilterNode.oper != 'nnil'
            "
            v-model="currentFilterNode.value"
            placeholder=""
            :multiple="
              currentFilterNode.oper == 'in' ||
              currentFilterNode.oper == 'nin' ||
              currentFilterNode.oper == 'containAny' ||
              currentFilterNode.oper == 'contain' ||
              isMultiple
            "
            :key="currentFilterNode.oper"
            style="width: 100%"
          >
            <el-option
              v-for="opt in currentFilterNode.field.component.customOptions"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            ></el-option>
          </el-select>
          <el-input
            v-else-if="
              currentFilterNode.oper == 'like' ||
              currentFilterNode.oper == 'nlike'
            "
            v-model="currentFilterNode.value"
            placeholder=""
            size="small"
            :key="currentFilterNode.oper"
          ></el-input>
          <el-select
            v-else-if="
              currentFilterNode.oper != '' &&
              currentFilterNode.oper != 'nil' &&
              currentFilterNode.oper != 'nnil'
            "
            v-model="currentFilterNode.value"
            placeholder=""
            size="small"
            filterable
            allow-create
            v-loadmore="loadMore"
            :remote="true"
            :remote-method="searchList"
            style="width: 100%"
            :multiple="
              currentFilterNode.oper == 'in' ||
              currentFilterNode.oper == 'nin' ||
              currentFilterNode.oper == 'containAny' ||
              currentFilterNode.oper == 'contain' ||
              isMultiple
            "
            :key="currentFilterNode.oper"
            @change="changeValue"
          >
            <el-option
              v-if="
                ['user_select', 'user_list_select'].includes(
                  currentFilterNode.componentName,
                )
              "
              value="valueContainsCurrentUser"
              label="当前用户"
            ></el-option>
            <el-option
              v-for="opt in page.list"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            >
            </el-option>
          </el-select>
        </template>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Draggable from "vuedraggable";
import { getDisplayQueryOpers } from "@/utils/queryOpers";
import { getAggreation, fetchContactFormMetaFieldList } from "@/views/lowCode/view/api";
import { fetchFormDataList, fetchPage } from "../../form/api";
import { dealWithComponentValue } from "./chartUtil";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import dateFilter from "@/views/lowCode/view/components/date-filter";
import { getComponentQueryOper } from "@zgg-core-utils/whiteList";
import { employeeList } from "@/views/company-config/employee-manage/employee-manage-api";
import { departmentList } from "@/api/directoryConfig";
import { mapState } from "vuex";

export default {
  name: "sort",
  components: { Draggable, dateFilter },

  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
    isCostomOption() {
      return (
        this.currentFilterNode &&
        this.currentFilterNode.field &&
        this.currentFilterNode.field.component &&
        this.currentFilterNode.field.component.optionSource == "custom"
      ); // 有自定义配置选的组件用配置选来进行过滤
      // return false;
    },
    isMultiple() {
      if (
        this.currentFilterNode.componentName == "select_checkbox" ||
        this.currentFilterNode.componentName == "checkbox_group"
      ) {
        if (this.currentFilterNode.oper == "eq") {
          return true;
        }
      }
      return false;
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    currentFilterNode: {
      type: Object,
      default() {
        return {};
      },
    },
    formList: {
      type: Array,
      default() {
        return [];
      },
    },
    view: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      dataList: [],
      areaList: [],
      page: {
        pageSize: 20,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
      popverVisible: false,
      fieldOptions: [],
    };
  },
  async created() {
    let obj = this.currentFilterNode;
    if (obj.componentName == "address_input") {
      this.buildAreaList(obj.picker ? obj.picker : "");
    }
    //初始化选择操作符
    if (!obj.oper) {
      let oper = "eq";
      if (obj.componentName == "checkbox_group") {
        oper = "containAny";
      } else if (obj.componentName == "text_area") {
        oper = "like";
      } else if (obj.componentName == "address_input") {
        oper = "belong";
      } else if (
        ["attachment_uploader", "image_uploader"].includes(obj.componentName)
      ) {
        oper = "nil";
      }
      this.$set(this.currentFilterNode, "oper", oper);
    }
    this.handleDialogOpened();
    // 初始化通讯录人员表单元数据表字段
    await this.getFieldOptions();
  },
  methods: {
    getAdvanceValueList(componentList) {
      let list = [];
      let callback = (components, parent) => {
        components.forEach((item) => {
          if (item.container) {
            callback(item.components, item);
          } else if (item.componentName == "reference_data") {
            if (!parent) {
              list.push(this._.cloneDeep(item));
            }
          } else if (item.form) {
            let obj = this._.cloneDeep(item);
            if (parent && parent.componentName == "json_form") {
              obj.title = parent.title + "." + obj.title;
              obj.parent = this._.cloneDeep(parent);
            }
            list.push(obj);
          }
        });
      };
      callback(componentList);
      return list;
    },
    async getFieldOptions() {
      let list = [];
      await fetchContactFormMetaFieldList({
        returnWithSystemField: false
      }).then(resp => {
        let contactFormMetaFieldList = resp?.data?.list || [];
        if (contactFormMetaFieldList.length) {
          let componentList = contactFormMetaFieldList.map(
            (item) => item.component,
          );
          // 匹配细则
          const passArray = ["form_data", "json_form"];
          let fieldColumns = this.getAdvanceValueList(
            componentList.filter(
              (item) => !passArray.includes(item.componentName),
            ),
          );
          let componentName = this.currentFilterNode.componentName;
          if (!componentName) {
            return [];
          }
          let c1 = ["input", "text_area", "radio_group", "select", "sn_input"];
          let c2 = [
            "select",
            "select_checkbox",
            "radio_group",
            "checkbox_group",
            "input",
          ];
          let checkboxComponents = ["select_checkbox", "checkbox_group"]; // 复选组件
          let oper = this.currentFilterNode.oper;
          fieldColumns.forEach((item) => {
            let obj = this._.cloneDeep(item);
            if (c1.includes(componentName)) {
              if (oper == "in" || oper == "nin") {
                if (
                  item.componentName == "checkbox_group" ||
                  item.componentName == "select_checkbox"
                ) {
                  list.push(obj);
                }
              } else {
                if (c1.includes(item.componentName)) {
                  list.push(obj);
                }
              }
            } else {
              if (item.componentName == componentName) {
                if(item.componentName == "cascade_data") {
                  // 级联组件特殊处理： 必须同源组件才可填充
                  if(item.tableName == this.currentFilterNode.tableName){
                    list.push(obj);
                  }
                } else {
                  list.push(obj);
                }
              } else if (
                componentName == "user_list_select" &&
                item.componentName == "user_select"
              ) {
                list.push(obj);
                // 产品要求单行、多行文本可以填充到富文本
              } else if (
                (item.componentName == "text_area" ||
                  item.componentName == "input") &&
                componentName == "html_input"
              ) {
                list.push(obj);
                // 过滤条件中选择下拉复选/多选框组，连接条件为“包含任意一个/同时包含”时，可选择下拉单、复选，单、多选框组，单行文本
              } else if (oper == "containAny" || oper == "contain") {
                if (checkboxComponents.includes(componentName)) {
                  if (c2.includes(item.componentName)) {
                    list.push(obj);
                  }
                }
              }
            }
          });
        }
        this.fieldOptions = list;
      })
    },
    changeValue(e) {
      // 针对当前用户的处理
      if (
        ["containAny", "contain", "nin", "in"].includes(
          this.currentFilterNode.oper,
        )
      ) {
        this.currentFilterNode.valueContainsCurrentUser =
          !!this.currentFilterNode.value.find((item) =>
            item.includes("valueContainsCurrentUser"),
          );
      } else {
        this.currentFilterNode.valueContainsCurrentUser =
          e == "valueContainsCurrentUser" ? true : false;
      }
    },
    changeOper() {
      this.currentFilterNode.value = "";
    },
    getValueFormat(type) {
      if (type == "year") {
        return "yyyy";
      } else if (type == "month") {
        return "yyyy-MM";
      } else if (type == "date") {
        return "yyyy-MM-dd";
      } else if (type == "date_minute") {
        return "yyyy-MM-dd HH:mm";
      } else if (type == "datetime") {
        return "yyyy-MM-dd HH:mm:ss";
      } else {
        return "yyyy-MM-dd HH:mm:ss";
      }
    },
    changeAddr(item, value) {
      let obj = {};
      let provinceId = value[0];
      let cityId = value[1];
      let countyId = value[2];
      let provinceItem = this.areaTree.find((item) => item.id == provinceId);
      obj.provinceId = provinceId;
      obj.province = provinceItem.name;
      if (cityId) {
        let cityItem = provinceItem.children.find((item) => item.id == cityId);
        obj.cityId = cityId;
        obj.city = cityItem.name;
        if (countyId) {
          let countyItem = cityItem.children.find(
            (item) => item.id == countyId,
          );
          obj.countyId = countyId;
          obj.county = countyItem.name;
        }
      }
      this.$set(item, "value", obj);
    },
    getAddr(value) {
      let arr = [];
      if (value) {
        if (value.provinceId) {
          arr.push(value.provinceId);
        }
        if (value.cityId) {
          arr.push(value.cityId);
        }
        if (value.countyId) {
          arr.push(value.countyId);
        }
      }
      return arr;
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }
      return obj;
    },
    buildAreaList(picker = "county") {
      let list = [];
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      this.$set(this, "areaList", list);
    },

    loadMore() {
      if (this.page.pageNumber < this.page.totalPage) {
        this.page.pageNumber += 1;
        this.getData(this.view);
      }
    },
    searchList(query) {
      this.query = query;
      this.page.pageNumber = 1;
      this.getData(this.view);
    },
    getData(view) {
      if (
        ["user_select", "user_list_select"].includes(
          this.currentFilterNode.componentName,
        )
      ) {
        this.page.list = [];
        employeeList({
          companyId: this.$store.state.user.currentCompanyId,
        }).then((res) => {
          res.data.page.list.forEach((row) => {
            this.page.list.push({
              label: row.name,
              value: row.targetUserId,
            });
          });
        });
      } else if (
        ["department_select", "department_list_select"].includes(
          this.currentFilterNode.componentName,
        )
      ) {
        this.page.list = [];
        departmentList({
          companyId: this.$store.state.user.currentCompanyId,
        }).then((res) => {
          res.data.departmentList.forEach((row) => {
            this.page.list.push({
              label: row.name,
              value: row.id,
            });
          });
        });
      } else {
        let collection = view.dataSource.formId;
        if (
          !isEmpty(this.currentFilterNode?.field?.component?.metaFieldOption)
        ) {
          //下拉复选框-数据联动的情况，应该请求联动的表
          collection =
            this.currentFilterNode?.field?.component?.metaFieldOption.tableName;
        }
        let queryObject = {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
        };
        if (this.query) {
          queryObject.advanceQuery = [];
          queryObject.advanceQuery.push({
            oper: "like",
            key: this.currentFilterNode.key,
            value: this.query,
          });
        }

        fetchFormDataList({
          collection: collection,
          queryObject: JSON.stringify(queryObject),
        }).then((res) => {
          let page = res.data.page;
          this.page.pageNumber = page.pageNumber;
          this.page.pageSize = page.pageSize;
          this.page.totalRow = page.totalRow;
          this.page.totalPage = page.totalPage;
          if (page.pageNumber == 1) {
            let list = [];
            let key = this.currentFilterNode.key;
            if (
              !isEmpty(
                this.currentFilterNode?.field?.component?.metaFieldOption,
              )
            ) {
              key =
                this.currentFilterNode?.field?.component?.metaFieldOption
                  .valueFieldName;
            }

            page.list.forEach((item) => {
              let value = item[key];
              let label = dealWithComponentValue(
                item,
                this.currentFilterNode.field.component,
              );
              if (!isEmpty(value)) {
                if (list.findIndex((opt) => opt.value == value) == -1) {
                  list.push({
                    label,
                    value,
                  });
                }
              }
            });

            this.page.list = list;
            if (list.length < 20) {
              this.loadMore();
            }
          } else {
            let list = this._.cloneDeep(this.page.list);
            let key = this.currentFilterNode.key;
            page.list.forEach((item) => {
              let value = item[key];
              let label = dealWithComponentValue(
                item,
                this.currentFilterNode.field.component,
              );
              if (!isEmpty(value)) {
                if (list.findIndex((opt) => opt.value == value) == -1) {
                  list.push({
                    label,
                    value,
                  });
                }
              }
            });

            this.page.list = list;
            if (list.length < 20) {
              this.loadMore();
            }
          }
        });
      }
    },
    // 切换选择类型
    checkItemType(valueType) {
      this.popverVisible = false;
      if (this.currentFilterNode.valueType == valueType) {
        return;
      }
      if(valueType === "currentUserField"){
        this.currentFilterNode.currentCompanyId = this.companyId;
      } else {
        delete this.currentFilterNode.currentCompanyId;
      }
      this.currentFilterNode.valueContainsCurrentUser = false;
      this.currentFilterNode.value = "";
      this.currentFilterNode.valueType = valueType;
    },
    getDisplayQueryOpers(componentName) {
      let opers = [];
      opers = getComponentQueryOper(componentName);
      return opers;
    },
    handleDialogOpened() {
      console.log(this.formList);
      this.getData(this.view);
    },
    handleClose() {},
    handleConfirm() {
      // 针对当前用户的处理
      if (this.currentFilterNode.valueContainsCurrentUser) {
        if (
          ["containAny", "contain", "nin", "in"].includes(
            this.currentFilterNode.oper,
          )
        ) {
          this.currentFilterNode.value = this.currentFilterNode.value.filter(
            (item) => !item.includes("valueContainsCurrentUser"),
          );
        } else {
          this.currentFilterNode.value = "";
        }
      }
      this.visible = false;
      this.$emit("changeOper");
    },
  },
};
</script>

<style scoped lang="scss">
.f-ul {
  margin: 0px;
  padding: 1px;
  overflow: auto;
  height: 400px;

  li {
    border-radius: 4px;
    font-size: 14px;
    margin: 0px;
    padding: 0px 10px 0px 10px;
    list-style: none;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
    }

    &.select {
      background-color: #e6f6f4;
    }

    span {
      flex: 1;
    }
  }
}

.field-list_ul {
  max-height: 250px;
  padding: 4px;
  overflow-y: auto;

  li {
    border-radius: 4px;
    font-size: 14px;
    margin: 0px;
    padding: 0px 10px 0px 10px;
    list-style: none;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.added {
      cursor: unset;
      color: #c3cdda;

      &:hover {
        box-shadow: unset;
      }
    }

    &:hover {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.flex-query{
  display: flex;
  overflow: hidden;
  margin-top: 10px;
  ::v-deep {
    .el-input__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

  }
}
.weui {
  display: flex;
  align-items: center;
}
.icon-item{
  cursor: pointer;
}
.el-prefix {
  border: 1px solid #dcdfe6;
  border-right: 0;
  height: 32px;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 49px;
}
.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}
.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
</style>
