<template>
  <div style="display: flex; flex-direction: row; height: 100%">
    <div
        :style="[{background:treeViewVisible?'#fff':'#f4f5f7'},{width:menuWrapWidth+'px',marginLeft:treeViewVisible?wrapMarginLeft+'px':(wrapMarginLeft+20)+'px'}]"
        style="
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        transition-property:margin-left;
        transition-duration:0.5s;"
    >
      <div v-if="treeViewVisible" :class="onDraging?'onDraging':''" class="drag-right" @mousedown="dragdown($event)">

      </div>
      <i
          :class="treeViewVisible ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"
          class="toggle-panel custom-icon"
          style="width: 20px;height: 20px"
          @click="toggleBoardVisible"
      ></i>

      <div style="margin-top: 40px;display: flex;flex-direction: column;align-items: center">
        <div :class="{active:1===num}" class="addr-config" @click="handleAddrConfig(1)">通讯录同步配置</div>
      </div>

    </div>
    <div class="unit-setup-right">
      <router-view v-if="isRouteActive"></router-view>
    </div>
  </div>

</template>

<script>
import { scaleWidth } from '@/mixin/scaleWidth'

export default {
  name: 'unit-setup-index',
  mixins: [scaleWidth],
  data() {
    return {
      active: false,
      num: 1,
      treeViewVisible: true,
      isRouteActive:true
    }
  },
  watch:{
    '$store.state.user.currentCompanyId': {
      handler(newVal) {
        this.isRouteActive = false
        this.$nextTick(function() {
          this.isRouteActive = true
        })
      }
    },
  },
  methods: {
    toggleBoardVisible() {
      this.treeViewVisible = !this.treeViewVisible
    },
    handleAddrConfig(index) {
      this.num = index
      switch (index) {
        case 1:
          this.$router.push({
            path: '/lowCode/unit-setup/addressSyncSetup'
          })
          break
        default:

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-setup {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .unit-setup-left {
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
    width: 250px;
    height: 100%;
    text-align: center;
    margin-right: 10px;
    border-radius: 10px;

    & > div {
      font-size: 14px;
      padding: 15px 0;
      cursor: pointer;
    }
  }
}

.unit-setup-right {
  flex: 1;
  border-radius: 10px;
  background-color: #f4f5f7;
}

.active {
  background-color: #eef0ff !important;
}

.drag-right {
  width: 8px;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 999;

  &:hover {
    border-right: solid 2px rgba(24, 144, 255, 0.6);
  }

  &.onDraging {
    border-right: solid 4px rgba(24, 144, 255, 0.6);
  }
}

.toggle-panel {
  position: absolute;
  z-index: 999;
  right: 2px;
  top: 16px;
}

.addr-config {
  padding: 10px 0;
  text-align: center;
  width: 85%;
  cursor: pointer;
}
</style>
