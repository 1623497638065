<template>
  <div class="attachment-wrap">
    <div
      class="attachment-item"
      v-for="(attachment, index) in attachmentList"
      :key="index"
    >
      <div class="attachment-icon">
        <el-image
          v-if="checkFileImage(attachment.extension)"
          :z-index="99999"
          :ref="'elImg_' + index"
          :src="
            attachment.url + '?x-oss-process=image/resize,m_lfit,h_100,w_100'
          "
          class="attachment-image"
          @click="reviewImg(index)"
        ></el-image>
        <SvgIcon
          v-if="!checkFileImage(attachment.extension)"
          :icon-class="getIconClassByExtension(attachment.extension)"
          class="attachment-svg"
        ></SvgIcon>
      </div>
      <div class="attachment-info">
        <div class="attachment-name">
          <span class="name"
            >{{ attachment.originalName }}.{{ attachment.extension }}</span
          >
          <span class="size">（{{ attachment.size | formatFileSize }}）</span>
        </div>
        <div style="margin: 10px 0px">
          <span>
            {{ attachment.createdTime }}
          </span>

          <div
            class="attachment-tool"
            style="display: inline-block; margin-left: 10px"
          >
            <!--          <el-link type="primary" icon="el-icon-paperclip" :href="attachment.url" target="_blank"-->
            <!--                   :download="attachment.originalName">下载-->
            <!--          </el-link>-->
            <!--                    <el-link type="primary" icon="el-icon-view">预览</el-link>-->
            <el-link
              v-if="checkoutDocument(attachment)"
              type="primary"
              icon="el-icon-view"
              @click="handleOnlinePreview(attachment)"
              >在线预览
            </el-link>
            <el-link
              type="primary"
              icon="el-icon-edit"
              @click="rename(attachment)"
              >重命名
            </el-link>
            <slot :row="attachment" :index="index"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const documentEnums = {
  doc: "WORD2",
  docx: "WORD2",
  ppt: "PPT",
  pptx: "PPT",
  xls: "ECEL",
  xlsx: "ECEL",
  txt: "txt1",
};
import SvgIcon from "@/components/SvgIcon/index";
import { previewAttachment, renameAttachment } from "@/api/attachment";
import { mapMutations } from "vuex";

export default {
  components: { SvgIcon },
  props: {
    renameFun: Function,
    attachmentList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    attachmentList: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("input", this.attachmentList);
      },
    },
  },
  methods: {
    /**
     * 校验是否需要显示在线预览图标
     */
    checkoutDocument(attachment) {
      return documentEnums[attachment.extension];
    },
    handleOnlinePreview(row) {
      previewAttachment({ attachmentId: row.id })
        .then((resp) => {
          let url = resp.data.attachmentConverter.url;
          window.open(url, "_blank");
        })
        .catch((err) => {
          this.$message.warning("在线预览失败，试试下载或查看");
        });
    },
    ...mapMutations("image", ["showViewer", "setSrcList"]),
    reviewImg(index) {
      this.setSrcList(this.attachmentList.map((item) => item.url));
      this.showViewer(index);
    },
    rename(item) {
      this.$prompt("请输入附件名", "修改附件名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputValue: item.originalName,

        inputValidator: function (val) {
          if (!val) {
            return "附件名不能为空";
          }
        },
      })
        .then(({ value }) => {
          if (this.renameFun && typeof this.renameFun == "function") {
            this.renameFun({
              attachmentId: item.id,
              name: value,
            }).then((res) => {
              item.originalName = value;
              this.$message.success("重命名成功");
              this.$emit("updateBulletinCard");
              this.show = false;
            });
          } else {
            renameAttachment({
              attachmentId: item.id,
              name: value,
            }).then((res) => {
              item.originalName = value;
              this.$message.success("重命名成功");
              this.$emit("updateBulletinCard");
              this.show = false;
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$message({
          //   type: "success",
          //   message: "取消修改",
          // });
        });
    },
    checkFileImage(extension) {
      let enums = {
        jpg: "jpg2",
        jpeg: "jpg2",
        png: "png2",
        bmp: "bmp",
        heic: "heic",
        ico: "ico",
      };
      return enums[extension];
    },
    getIconClassByExtension(extension) {
      let enums = {
        doc: "WORD2",
        docx: "WORD2",
        jpg: "jpg2",
        jpeg: "jpg2",
        html: "html2",
        mp3: "mp32",
        png: "png2",
        dll: "dll2",
        ppt: "PPT",
        pptx: "PPT",
        mp4: "mp42",
        pdf: "pdf1",
        gif: "gif1",
        exe: "exe",
        mpg: "mpg",
        psd: "psd1",
        mkv: "mkv",
        xls: "ECEL",
        xlsx: "ECEL",
        rmvb: "rmvb",
        txt: "txt1",
        wav: "wav",
        swf: "swf1",
        avi: "avi1",
        zip: "zip1",
        rar: "folder2",
        other: "other",
      };
      if (enums[extension]) {
        return enums[extension];
      } else {
        return "other";
      }
    },
  },
};
</script>
<style scoped lang="less">
.attachment-wrap {
  margin-top: 14px;

  .attachment-item {
    border-radius: 4px;
    border: 1px solid #ebeef5;
    transition: 0.3s;
    display: flex;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2px;

    &:hover {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #eeeeee;
    }

    .attachment-icon {
      .attachment-image {
        height: 100px;
        width: 100px;
      }

      .attachment-svg {
        width: 32px;
        height: 32px;
      }
    }

    .attachment-info {
      margin: 0 10px;
      height: 100%;
      flex: 1;

      .attachment-name {
        .name {
          line-height: 30px;
        }

        .size {
          color: #a0a0a0;
        }
      }
    }

    .attachment-tool {
      //flex: 1;
      text-align: right;

      .el-link {
        margin-right: 5px;
      }
    }
  }
}
</style>
