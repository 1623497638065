<template>
  <div class="attr-row">
    <div class="attr-label">描述信息</div>
    <TEditor
      :height="200"
      :isCustomStyle="component.componentName == 'html' ? false : true"
      v-model="component.description"
    ></TEditor>
  </div>
</template>
<script>
import TEditor from "@/components/TEditor.vue";
import { componentProps } from "./util";
export default {
  name: "attr-description",
  components: { TEditor },
  props: componentProps,
};
</script>
