<template>
  <div>
    <template v-if="trigger.isEdit">
      <div style="padding: 10px">
        <div class="weui mb">
          <div class="trigger-label">触发动作</div>
          <div class="trigger-value">
            <span>{{ typeText }}</span
            ><span @click="changeTriggerType" class="replace">更换</span>
          </div>
        </div>
        <template v-if="isFormDataChange">
          <div class="mb">
            <div class="trigger-label" style="margin-bottom: 5px">触发表单</div>
            <formSelectPopover
              @getComponents="getComponents"
              v-model="trigger"
            ></formSelectPopover>
          </div>
          <assistant-data-filter
            v-if="trigger.formId"
            v-model="trigger"
            :componentList="componentList"
            :areaTree="areaTree"
          ></assistant-data-filter>
        </template>
        <timerOnceTriggerSetting
          v-else-if="isTimerOnceTrigger"
          v-model="trigger"
          ref="triggerRef"
        ></timerOnceTriggerSetting>
        <timer-cron-trigger-setting
          v-else-if="isTimerCronTrigger"
          v-model="trigger"
          ref="triggerRef"
        ></timer-cron-trigger-setting>

        <timer-field-trigger-setting
          v-else-if="isTimerFieldTrigger"
          v-model="trigger"
          :componentList="componentList"
          @getComponents="getComponents"
          ref="triggerRef"
        ></timer-field-trigger-setting>
      </div>

      <div class="footer">
        <el-button type="primary" size="small" @click="save">完成</el-button>
      </div>
    </template>
    <div class="trigger-title" v-else>
      <span>{{ typeText }}：{{ triggerTargetTitle }}</span>
      <div @click="editTrigger" class="trigger-edit">
        <i class="el-icon-edit"></i><span>编辑</span>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchFormList } from "../form/api";
import Clickoutside from "element-ui/src/utils/clickoutside";
import test from "@/utils/test";
import { getComponentQueryOper, spritFilter } from "@zgg-core-utils/whiteList";
import timerCronTriggerSetting from "@/views/lowCode/assistant/timer-cron-trigger-setting";
import timerFieldTriggerSetting from "@/views/lowCode/assistant/timer-field-trigger-setting";
import timerOnceTriggerSetting from "@/views/lowCode/assistant/timer-once-trigger-setting";
import assistantDataFilter from "@/views/lowCode/assistant/assistant-data-filter";
import formSelectPopover from "@/views/lowCode/assistant/form-select-popover";
import moment from "moment";
import timerTriggerMixin from "@/views/lowCode/assistant/timer-trigger-mixin";
import {
  getAdvanceQuerysByDataFilter,
  isCompleteDataFilter,
} from "@zgg-core-utils/utils";

export default {
  components: {
    timerCronTriggerSetting,
    timerFieldTriggerSetting,
    timerOnceTriggerSetting,
    assistantDataFilter,
    formSelectPopover,
  },
  directives: { Clickoutside },
  mixins: [timerTriggerMixin],
  props: {
    value: Object,
    componentsMap: Object,
    changeTriggerType: Function,
    areaTree: Array,
  },

  computed: {
    /**
     * 触发动作的title生成
     * @returns {string|*}
     */
    triggerTargetTitle() {
      let str = "";
      if (this.isTimerOnceTrigger) {
        return moment(this.trigger.executeTime).format("YYYY-MM-DD HH:mm:ss");
      } else if (this.isTimerCronTrigger) {
        let executeTime,
          executeEndTime,
          loopType = "";
        if (this.trigger.executeTime) {
          executeTime = moment(this.trigger.executeTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          str += executeTime;
        }
        if (this.trigger.executeEndTime && this.trigger.executeEndTime !== "") {
          executeEndTime = moment(this.trigger.executeEndTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          str += "～" + executeEndTime;
        }
        if (this.trigger.loopType) {
          loopType = this.timerCronLoopTypeEnums.find(
            (item) => item.value == this.trigger.loopType
          ).label;
          str += "、" + loopType;
        }
        return str;
      } else if (this.isTimerFieldTrigger) {
        return "点击查看";
      } else {
        return this.trigger.title;
      }
    },
    isFormDataChange() {
      return this.value.type == "form_data_change";
    },
    isTimerCronTrigger() {
      return this.value.type == "timer_cron";
    },
    isTimerOnceTrigger() {
      return this.value.type == "timer_once";
    },
    isTimerFieldTrigger() {
      return this.value.type == "timer_field";
    },
    typeText() {
      let text = "";
      if (this.value) {
        if (this.isFormDataChange) {
          if (this.value.dataChangeType == "create") {
            text = "新增数据";
          } else if (this.value.dataChangeType == "update") {
            text = "修改数据";
          } else if (this.value.dataChangeType == "delete") {
            text = "删除数据";
          }
        } else if (this.isTimerOnceTrigger) {
          text = "定时单次触发";
        } else if (this.isTimerCronTrigger) {
          text = "定时重复触发";
        } else if (this.isTimerFieldTrigger) {
          text = "按日期字段触发";
        }
      }
      return text;
    },
    componentList() {
      let list = [];
      if (
        this.trigger &&
        this.componentsMap &&
        this.componentsMap[this.trigger.formId]
      ) {
        this.componentsMap[this.trigger.formId].forEach((item) => {
          if (item.componentName == "json_form") {
            item.components.forEach((child) => {
              if (spritFilter.includes(child.componentName)) {
                list.push({
                  ...child,
                  name: item.name + "." + child.name,
                  title: item.title + "." + child.title,
                });
              }
            });
          } else if (spritFilter.includes(item.componentName)) {
            list.push(item);
          }
        });
      }
      return list;
    },
  },
  created() {
    this.trigger = this._.cloneDeep(this.value);
    // if (!this.value.formId) {
    //   this.trigger.isEdit = true;
    // }
  },
  data() {
    return {
      trigger: {
        isEdit: false,
      },
      visible: false,

      list: [],
    };
  },
  methods: {
    getComponents(id) {
      this.$emit("getComponents", id);
    },

    async save() {
      //定时单次、定时重复、按日期字段触发校验逻辑
      if (
        this.isTimerCronTrigger ||
        this.isTimerOnceTrigger ||
        this.isTimerFieldTrigger
      ) {
        let valid = await this.$refs.triggerRef.validateForm().catch((err) => {
          return err;
        });
        if (!valid) {
          this.$message.error("请检查配置必填配置");
          return;
        }
      }
      //数据变动
      if (this.isFormDataChange) {
        if (!this.trigger.formId) {
          this.$message.error("请选择表单");
          return;
        }
        let isPass = isCompleteDataFilter(this.trigger.condition.advanceQuery);
        console.log(isPass, this.trigger.condition);
        if (!isPass) {
          this.$message.error("请完善触发条件的配置");
          return;
        }
        let isClear =
          this.value.formId && this.trigger.formId != this.value.formId;
        this.$emit("changeTrigger", false, isClear);
        this.trigger.isEdit = false;
        this.$emit("input", this._.cloneDeep(this.trigger));
      } else {
        this.trigger.isEdit = false;
        this.$emit("changeTrigger", false, false);
        this.trigger.isEdit = false;
        this.$emit("input", this._.cloneDeep(this.trigger));
      }
    },
    editTrigger() {
      this.trigger.isEdit = true;
      this.$emit("changeTrigger", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.trigger-title {
  line-height: 30px;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .trigger-edit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.05);
    display: none;
  }

  &:hover {
    color: rgba($color: #333, $alpha: 0.45);

    .trigger-edit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      cursor: pointer;
    }
  }
}

.weui {
  display: flex;
  align-items: center;
}

.trigger-label {
  color: #5e6d82;
  font-size: 14px;
}

.trigger-value {
  background-color: #f1f1f1;
  padding: 5px 10px;
  font-size: 14px;
  margin-left: 10px;
}

.replace {
  font-size: 14px;
  color: #409eff;
  cursor: pointer;
  margin: 0px 5px;
}

.trigger-select {
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  overflow: hidden;

  .inner-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    font-size: 14px;
  }
}

.mb {
  margin-bottom: 12px;
}

.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .form-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background-color: rgba($color: #409eff, $alpha: 0.5);
      color: #ffffff;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.2);
    }
  }
}

.weui-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}

.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.flex {
  flex: 1;
  overflow: hidden;
}

.footer {
  border-top: solid 1px #e0e0e0;
  padding: 10px;
}
</style>
<style lang="scss">
.pop-trigger {
  &.el-popover {
    padding: 0;
  }
}
</style>
