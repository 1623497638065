<template>
  <div style="width: 100%">
    <el-table
      :data="page.list"
      :height="page.totalPage > 1 ? 300 : 340"
      style="width: 100%"
    >
      <zgg-table-column
        v-for="item in tableColumns"
        :key="item.name"
        :label="item.comment.toString()"
        :prop="item.name"
        :field="item"
        :is-sortable="false"
        :attachmentAtts="attachmentAtts"
      ></zgg-table-column>
    </el-table>
    <div v-if="page.totalPage > 1" style="height: 42px; background: #ffffff">
      <pagination
        style="float: right"
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadmore"
      />
    </div>
  </div>
</template>
<script>
import { buildNodeFields, isExistFun } from "./util";

export default {
  inject: ["fetchStageData"],
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },
  data() {
    return {
      page: {
        pageSize: 20,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
      attachmentAtts: {
        preview: false,
        disk: false,
        download: false,
      },
    };
  },
  computed: {
    tableColumns() {
      return this.node.fields.filter(
        (item) => !["id"].includes(item.componentName),
      );
    },
  },
  created() {
    buildNodeFields(this.node, this.etlList, this.etlLines);
    this.loadingList(1);
  },
  methods: {
    isExistFun,
    loadmore({ page, limit }) {
      this.page.pageSize = limit;
      this.loadingList(page);
    },
    loadingList(pageNumber = 1) {
      this.isExistFun("fetchStageData", "请求节点数据的方法", false);
      this.fetchStageData({
        pageSize: this.page.pageSize,
        pageNumber,
        stageKey: this.node.key,
        stageFlow: {
          stages: this.etlList,
          lines: this.etlLines,
        },
      }).then((res) => {
        let page = res.data.page;
        this.page.pageSize = page.pageSize;
        this.page.totalRow = page.totalRow;
        this.page.totalPage = page.totalPage;
        this.page.pageNumber = page.pageNumber;
        this.page.list = page.list;
      });
    },
  },
};
</script>
