<template>
  <el-dialog
    @open="open"
    @close="close"
    title="请选择数据源"
    :visible.sync="dialogVisible"
    :close-on-click-modal="closeOnClickModal"
    width="680px"
    append-to-body
    destroy-on-close
  >
    <div style="padding: 10px">
      <slot name="top-header"></slot>
      <div class="tab-container" v-clickoutside="clickoutside">
        <div class="tab-header">
          <div
            class="tab-item"
            :class="item.value == activeName ? 'active' : ''"
            v-for="item in menuList"
            :key="item.value"
            v-text="item.label"
            @click="activeName = item.value"
          ></div>
          <div class="search-input" :class="hide ? 'hide' : ''">
            <el-input
              v-model="keyword"
              prefix-icon="el-icon-search"
              size="mini"
              :placeholder="hide ? '' : '请输入表单名称'"
              style="width: 100%"
              @focus="focus"
              @input="search"
              @clear="clear"
              clearable
            ></el-input>
          </div>
        </div>

        <template v-if="hide">
          <!-- 表单 -->
          <div v-if="activeName == 'form'" class="tab-body">
            <el-tree
              :props="props"
              lazy
              highlight-current
              :node-key="mode == 'table' ? 'id' : ''"
              :current-node-key="mode == 'table' ? tableName : ''"
              :load="loadForm"
              @node-click="nodeClick"
              v-loading="tableLoading"
            >
            </el-tree>
          </div>
          <!-- 数据流 -->
          <div v-else-if="activeName == 'dataflow'" class="tab-body">
            <el-tree
              key="dataflow"
              :props="props"
              lazy
              highlight-current
              :node-key="mode == 'table' ? 'collection' : ''"
              :current-node-key="mode == 'table' ? tableName : ''"
              :load="loadDataflow"
              @node-click="nodeClick"
              v-loading="flowLoading"
            >
            </el-tree>
          </div>
          <div v-else-if="activeName == 'aggregate_table'" class="tab-body">
            <el-tree
              key="aggregate_table"
              :props="props"
              lazy
              highlight-current
              :node-key="mode == 'table' ? 'collection' : ''"
              :current-node-key="mode == 'table' ? tableName : ''"
              :load="loadAggregate"
              @node-click="nodeClick"
              v-loading="atLoading"
            >
            </el-tree>
          </div>

          <!-- 集团表单 -->
          <div v-if="activeName == 'group_form'" class="tab-body">
            <div>
              <el-collapse accordion>
                <el-collapse-item
                  :title="item.companyName"
                  :name="item.companyId"
                  v-for="(item, index) in groupPage.list"
                  :key="index"
                >
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
              <div
                v-if="!groupTableLoading && groupPage.list.length == 0"
                v-loading="groupTableLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                style="height: 100px"
                class="loading"
              >
                <div class="noData">暂无数据</div>
              </div>
            </div>
          </div>
          <!-- 集团数据流 -->
          <div v-else-if="activeName == 'group_dataflow'" class="tab-body">
            <div>
              <el-collapse accordion>
                <el-collapse-item
                  :title="item.companyName"
                  :name="item.companyId"
                  v-for="(item, index) in groupflowPage.list"
                  :key="index"
                >
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
              <div
                v-loading="groupFlowLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                class="loading"
              >
                <div
                  class="noData"
                  v-if="!groupFlowLoading && groupflowPage.list.length == 0"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </div>
          <!-- 集团聚合表 -->
          <div
            v-else-if="activeName == 'group_aggregate_table'"
            class="tab-body"
          >
            <div class="box-padding">
              <el-collapse accordion>
                <el-collapse-item
                  :title="item.companyName"
                  :name="item.companyId"
                  v-for="(item, index) in groupAtPage.list"
                  :key="index"
                >
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
              <!-- <div v-for="(item, index) in groupAtPage.list" :key="index">
                <div class="box-title-padding">
                  {{ item.companyName }}
                </div>
                <div>
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </div>
              </div> -->
              <div
                v-loading="groupAtLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                style="height: 100px"
                class="loading"
              >
                <div
                  class="noData"
                  v-if="!groupAtLoading && groupAtPage.list.length == 0"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 查询展示 -->
        <div v-else class="tab-body">
          <!-- <el-collapse v-model="activeNames"> -->
          <!-- 表单数据 -->
          <template v-if="menus.includes('form') && !searching">
            <!-- <el-collapse-item title="表单" name="1"> -->
            <div class="tab-title">表单</div>
            <el-tree
              :props="props"
              lazy
              highlight-current
              :node-key="mode == 'table' ? 'id' : ''"
              :current-node-key="mode == 'table' ? tableName : ''"
              :load="loadForm"
              @node-click="nodeClick"
              v-loading="tableLoading"
            >
            </el-tree>
            <!-- </el-collapse-item> -->
          </template>
          <!-- 数据流 -->
          <template v-if="menus.includes('dataflow') && !searching">
            <!-- <el-collapse-item title="数据流" name="2"> -->
            <div class="tab-title">数据流</div>
            <el-tree
              key="dataflow"
              :props="props"
              lazy
              highlight-current
              :node-key="mode == 'table' ? 'collection' : ''"
              :current-node-key="mode == 'table' ? tableName : ''"
              :load="loadDataflow"
              v-loading="flowLoading"
              @node-click="nodeClick"
            >
            </el-tree>
            <!-- </el-collapse-item> -->
          </template>
          <!-- 聚合表 -->
          <template v-if="menus.includes('aggregate_table')">
            <!-- <el-collapse-item title="聚合表" name="3"> -->
            <div class="tab-title">聚合表</div>
            <el-tree
              key="aggregate_table"
              :props="props"
              lazy
              highlight-current
              :node-key="mode == 'table' ? 'collection' : ''"
              :current-node-key="mode == 'table' ? tableName : ''"
              :load="loadAggregate"
              @node-click="nodeClick"
              v-loading="atLoading"
            >
            </el-tree>
            <!-- </el-collapse-item> -->
          </template>
          <!-- 集团表单数据 -->
          <template v-if="menus.includes('group_form') && !searching">
            <!-- <el-collapse-item title="集团表单" name="4"> -->
            <div class="tab-title1">集团表单</div>
            <div class="box-padding">
              <el-collapse accordion>
                <el-collapse-item
                  :title="item.companyName"
                  :name="item.companyId"
                  v-for="(item, index) in groupPage.list"
                  :key="index"
                >
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
              <div
                v-if="!groupTableLoading && groupPage.list.length == 0"
                v-loading="groupTableLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                style="height: 100px"
                class="loading"
              >
                <div class="noData">暂无数据</div>
              </div>
            </div>
            <!-- </el-collapse-item> -->
          </template>
          <!-- 集团数据流 -->
          <template v-if="menus.includes('group_dataflow') && !searching">
            <!-- <el-collapse-item title="集团数据流" name="5"> -->
            <div class="tab-title1">集团数据流</div>
            <div class="box-padding">
              <el-collapse accordion>
                <el-collapse-item
                  :title="item.companyName"
                  :name="item.companyId"
                  v-for="(item, index) in groupflowPage.list"
                  :key="index"
                >
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
              <div
                v-if="!groupFlowLoading && groupflowPage.list.length == 0"
                v-loading="groupFlowLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                style="height: 100px"
                class="loading"
              >
                <div class="noData">暂无数据</div>
              </div>
            </div>
            <!-- </el-collapse-item> -->
          </template>

          <!-- 集团聚合表 -->
          <template v-if="menus.includes('group_aggregate_table')">
            <!-- <el-collapse-item title="集团聚合表" name="6"> -->
            <div class="tab-title1">集团聚合表</div>
            <div class="box-padding">
              <el-collapse accordion>
                <el-collapse-item
                  :title="item.companyName"
                  :name="item.companyId"
                  v-for="(item, index) in groupAtPage.list"
                  :key="index"
                >
                  <el-tree
                    :props="props"
                    highlight-current
                    :node-key="mode == 'table' ? 'id' : ''"
                    :current-node-key="mode == 'table' ? tableName : ''"
                    :data="item.dataList"
                    @node-click="nodeClick"
                  >
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
              <!-- <div v-for="(item, index) in groupAtPage.list" :key="index">
                    <div class="box-title-padding">
                      {{ item.companyName }}
                    </div>
                    <div>
                      <el-tree
                        :props="props"
                        highlight-current
                        :node-key="mode == 'table' ? 'id' : ''"
                        :current-node-key="mode == 'table' ? tableName : ''"
                        :data="item.dataList"
                        @node-click="nodeClick"
                      >
                      </el-tree>
                    </div>
                  </div> -->
              <div
                v-loading="groupAtLoading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                style="height: 100px"
                class="loading"
              >
                <div
                  class="noData"
                  v-if="!groupAtLoading && groupAtPage.list.length == 0"
                >
                  暂无数据
                </div>
              </div>
            </div>
            <!-- </el-collapse-item> -->
          </template>
          <!-- </el-collapse> -->
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { getFormComponents, isEmpty } from "@zgg-core-utils/utils";
import {
  fetchDataflowList,
  fetchFormList,
  getDataflow,
  getFormDetail,
  groupFormList,
  fetchGroupDataflowList,
  fetchGroupAggregateList,
} from "@/views/lowCode/form/api";
import _ from "lodash";
import { metaFields } from "@zgg-core-utils/whiteList";
import { aggregateTableList } from "@/views/lowCode/view/api";
import { fetchMetaFieldComponentList, fetchMetaFieldList } from "@/api/form";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
export default {
  name: "data-source",
  directives: { Clickoutside },
  props: {
    value: String,
    visible: Boolean,
    closeOnClickModal: {
      type: Boolean,
      default() {
        return true;
      },
    },
    menus: {
      type: Array,
      default() {
        return ["form", "dataflow", "aggregate_table"];
      },
    },
    mode: {
      type: String,
      default() {
        return "table"; // table选择表单，field选择字段
      },
    },
    idNotEquals: String,
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    menuList() {
      return this.tabs.filter((item) => this.menus.includes(item.value));
    },
  },

  data() {
    return {
      props: {
        label: "title",
        isLeaf: "leaf",
      },
      activeName: "form",
      tabs: [
        {
          label: "表单",
          value: "form",
        },
        {
          label: "数据流",
          value: "dataflow",
        },
        {
          label: "聚合表",
          value: "aggregate_table",
        },
        {
          label: "集团表单",
          value: "group_form",
        },
        {
          label: "集团数据流",
          value: "group_dataflow",
        },
        {
          label: "集团聚合表",
          value: "group_aggregate_table",
        },
      ],
      tableName: "",
      keyword: "",
      tableData: [],
      page: {
        pageNumber: 1,
        pageSize: 400,
        totalRow: 0,
        totalPage: 0,
      },
      tableLoading: false,
      hide: true,
      flowLoading: false,
      dataflowPage: {
        pageNumber: 1,
        pageSize: 400,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
      searching: false,
      node: null,
      tableTitle: "",
      atLoading: false,
      atPage: {
        pageNumber: 1,
        pageSize: 400,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },

      groupPage: {
        list: [],
      },
      groupTableLoading: false,
      groupFlowLoading: false,
      groupflowPage: {
        list: [],
      },
      groupAtLoading: false,
      groupAtPage: {
        list: [],
      },
      dataMode: "",
    };
  },
  watch: {
    activeName(val) {
      if (val == "group_form") {
        this.fetchGroupFormList();
      }

      if (val == "group_dataflow") {
        this.fetchGroupDataflow();
      }
      if (val == "group_aggregate_table") {
        this.fetchGroupAggregate();
      }
    },
    searching(val) {
      if (val) {
        this.fetchGroupFormList();
        this.fetchGroupDataflow();
        this.fetchGroupAggregate();
      }
    },
  },
  methods: {
    clickoutside() {
      this.hide = true;
    },
    nodeClick(data, node) {
      let field;
      if (data.leaf) {
        this.sourceType = data.sourceType;
        if (this.mode == "table") {
          if (data.sourceType == "form" || data.sourceType == "group_form") {
            this.tableName = data.id;
          } else if (
            [
              "dataflow",
              "aggregate_table",
              "group_dataflow",
              "group_aggregate_table",
            ].includes(data.sourceType)
          ) {
            this.tableName = data.collection;
          }
          this.tableTitle = data.title;
          this.dataMode = data.dataMode;
          this.hide = true;
          this.activeName = data.sourceType;
          field = {
            title: data.title,
            id: this.tableName,
          };
        } else {
          this.node = node;
          this.activeName = node.parent.data.sourceType;
          this.sourceType = node.parent.data.sourceType;
          this.hide = true;
          field = {
            title: data.title,
            id: data.name,
            parent: {
              title: node.parent.data.title,
              id:
                node.parent.data.sourceType == "form"
                  ? node.parent.data.id
                  : node.parent.data.collection,
            },
          };
        }
        this.$emit("nodeClick", field);
      }
    },
    loadForm(node, resolve) {
      if (node.level == 0) {
        this.fetchFormList(1, () => {
          resolve(this.tableData);
        });
      } else {
        fetchMetaFieldComponentList(node.data.id).then((res) => {
          let components = res.data.list.filter((item) =>
            checkFieldAbility(item, "isOptionValue"),
          );
          components.forEach((item) => {
            item.leaf = true;
          });
          resolve(components);
        });
      }
    },

    /**
     * 构建表单数的叶子节点
     */
    buildFormTreeNodes(components) {
      // 关联数据时候字段过滤
      let list = [];

      components
        .filter((item) => metaFields.includes(item.componentName))
        .forEach((item) => {
          if (item.components) {
            list = list.concat(this.buildTreeNodes(item.components));
          } else if (item.form) {
            list.push(this._.cloneDeep(item));
          }
        });

      return list;
    },

    loadDataflow(node, resolve) {
      if (node.level == 0) {
        this.fetchDataflow(1, () => {
          resolve(this.dataflowPage.list);
        });
      } else {
        fetchMetaFieldComponentList(node.data.collection).then((res) => {
          let components = res.data.list.filter((item) =>
            checkFieldAbility(item, "isOptionValue"),
          );
          components.forEach((item) => {
            item.leaf = true;
          });
          resolve(components);
        });
        // getDataflow(node.data.collection).then((res) => {
        //   let output = res.data.dataflow.flow.stages.find(
        //     (item) => item.stageType == "output"
        //   );
        //   let components = output.fields.map((item) => item.component);
        //   components = components.filter((item) =>
        //     checkFieldAbility(item, "isOptionValue")
        //   );
        //   components.forEach((item) => {
        //     item.leaf = true;
        //   });
        //   resolve(components);
        // });
      }
    },
    loadAggregate(node, resolve) {
      if (node.level == 0) {
        this.fetchAggregate(1, () => {
          resolve(this.atPage.list);
        });
      } else {
        fetchMetaFieldList({ tableName: node.data.collection }).then((res) => {
          let list = [];
          res.data.list.forEach((item) => {
            let obj = {
              id: item.id,
              title: item.comment,
              name: item.name,
              key: item.name,
              componentName: item.componentName,
              collection: item.tableName,
              form: true,
              leaf: true,
            };
            if (checkFieldAbility(obj, "isOptionValue")) {
              list.push(obj);
            }
          });
          resolve(list);
        });
      }
    },

    save() {
      console.log(this.tableName, "是什么----", this.value);
      if (this.mode == "table") {
        if (this.tableName != this.value) {
          this.$emit("input", this.tableName);
          this.$emit("changeSource", {
            tableName: this.tableName,
            tableTitle: this.tableTitle,
            sourceType: this.sourceType,
            dataMode: this.dataMode,
          });
        }
      } else {
        let data = {};
        if (this.node.parent.data.sourceType == "form") {
          data = {
            tableName: this.node.parent.data.id,
            tableTitle: this.node.parent.data.title,
            field: this.node.data,
            childNodes: this.node.parent.childNodes,
            sourceType: this.sourceType,
          };
        } else if (this.node.parent.data.sourceType == "dataflow") {
          data = {
            tableName: this.node.parent.data.collection,
            tableTitle: this.node.parent.data.title,
            field: this.node.data,
            childNodes: this.node.parent.childNodes,
            sourceType: this.sourceType,
          };
        } else if (this.node.parent.data.sourceType == "aggregate_table") {
          data = {
            tableName: this.node.parent.data.collection,
            tableTitle: this.node.parent.data.title,
            field: this.node.data,
            childNodes: this.node.parent.childNodes,
            sourceType: this.sourceType,
          };
        }
        this.$emit("changeSource", this._.cloneDeep(data));
      }

      this.dialogVisible = false;
    },
    open() {
      this.tableName = this.value;
    },
    close() {
      this.tableName = "";
      this.keyword = "";
      this.hide = true;
      this.$emit("close");
    },
    focus() {
      this.hide = false;
    },

    clear() {
      this.keyword = "";
      this.hide = false;
    },
    changeSource(tableName) {
      this.tableName = tableName;
      this.hide = true;
    },

    search: _.debounce(function () {
      this.searching = true;
      this.$nextTick(() => {
        this.searching = false;
      });
    }, 200),
    fetchFormList(pageNumber = 1, callback) {
      if (!this.menus.includes("form")) {
        // 不存在form
        return;
      }
      this.tableData = [];
      this.tableLoading = true;
      let postData = {
        pageNumber,
        pageSize: this.page.pageSize,
        title: this.keyword,
        removeComponent: true,
      };
      if (!isEmpty(this.idNotEquals)) {
        postData.idNotEquals = this.idNotEquals;
      }

      fetchFormList(postData)
        .then((res) => {
          this.tableLoading = false;
          let page = res.data.page;
          this.page.pageNumber = page.pageNumber;
          this.page.totalRow = page.totalRow;
          this.page.totalPage = page.totalPage;
          page.list.forEach((item) => {
            item.leaf = this.mode == "table";
            item.sourceType = "form";
          });

          if (page.pageNumber > 1) {
            this.tableData = this.tableData.concat(page.list);
          } else {
            this.tableData = page.list;
          }
          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    fetchDataflow(pageNumber = 1, callback) {
      if (!this.menus.includes("dataflow")) {
        // 没有数据流
        return;
      }
      this.flowLoading = true;
      fetchDataflowList({
        pageNumber,
        pageSize: this.page.pageSize,
        companyId: this.$store.getters.currentCompanyId,
        title: this.keyword,
      })
        .then((res) => {
          let page = res.data.page;
          this.dataflowPage.pageNumber = page.pageNumber;
          this.dataflowPage.totalRow = page.totalRow;
          this.dataflowPage.totalPage = page.totalPage;
          page.list.forEach((item) => {
            item.leaf = this.mode == "table";
            item.sourceType = "dataflow";
          });
          if (page.pageNumber > 1) {
            this.dataflowPage.list = this.dataflowPage.list.concat(page.list);
          } else {
            this.dataflowPage.list = page.list;
          }

          if (typeof callback === "function") {
            callback();
          }
        })
        .finally(() => {
          this.flowLoading = false;
        });
    },
    fetchAggregate(pageNumber = 1, callback) {
      if (!this.menus.includes("aggregate_table")) {
        // 没有数据流
        return;
      }
      this.atLoading = true;
      aggregateTableList({
        pageNumber,
        pageSize: 1000,
        companyId: this.$store.getters.currentCompanyId,
        title: this.keyword,
      })
        .then((res) => {
          let page = res.data.page;
          this.atPage.pageNumber = page.pageNumber;
          this.atPage.totalRow = page.totalRow;
          this.atPage.totalPage = page.totalPage;
          page.list.forEach((item) => {
            item.leaf = this.mode == "table";
            item.sourceType = "aggregate_table";
          });
          if (page.pageNumber > 1) {
            this.atPage.list = this.atPage.list.concat(page.list);
          } else {
            this.atPage.list = page.list;
          }

          if (typeof callback === "function") {
            callback();
          }
        })
        .finally(() => {
          this.atLoading = false;
        });
    },

    // 集团表单

    fetchGroupFormList() {
      if (!this.menus.includes("group_form")) {
        // 不存在form
        return;
      }
      this.groupPage.list = [];
      this.groupTableLoading = true;
      let postData = {
        title: this.keyword,
      };
      groupFormList(postData)
        .then((res) => {
          this.groupTableLoading = false;
          this.groupPage.list = res.data.companyList;
          this.groupPage.list.forEach((item) => {
            item.dataList.forEach((sub) => {
              sub.leaf = this.mode == "table";
              sub.sourceType = "group_form";
            });
          });
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    //集团数据流
    fetchGroupDataflow() {
      if (!this.menus.includes("group_dataflow")) {
        // 没有数据流
        return;
      }
      this.groupFlowLoading = true;
      fetchGroupDataflowList({
        title: this.keyword,
      })
        .then((res) => {
          this.groupFlowLoading = false;
          this.groupflowPage.list = res.data.companyList;
          this.groupflowPage.list.forEach((item) => {
            item.dataList.forEach((sub) => {
              sub.leaf = this.mode == "table";
              sub.sourceType = "group_dataflow";
            });
          });
        })
        .finally(() => {
          this.groupFlowLoading = false;
        });
    },
    //集团聚合表
    fetchGroupAggregate() {
      if (!this.menus.includes("group_aggregate_table")) {
        // 没有数据流
        return;
      }
      this.groupAtLoading = true;
      fetchGroupAggregateList({
        title: this.keyword,
      })
        .then((res) => {
          this.groupAtLoading = false;
          this.groupAtPage.list = res.data.companyList;
          this.groupAtPage.list.forEach((item) => {
            item.dataList.forEach((sub) => {
              sub.leaf = this.mode == "table";
              sub.sourceType = "group_aggregate_table";
            });
          });
        })
        .finally(() => {
          this.groupAtLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-container {
  border: solid 1px #dcdfe6;
  display: flex;
  flex-direction: column;
  height: 400px;
  .tab-header {
    border-bottom: solid 1px #dcdfe6;
    display: flex;
    align-items: center;
    position: relative;
    .tab-item {
      font-size: 14px;
      padding: 10px 20px;
      position: relative;
      cursor: pointer;
      &.active {
        cursor: default;
        color: var(--zgg-brand-color-6);
        &::after {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0px;
          left: 10px;
          right: 10px;
          height: 2px;
          background-color: var(--zgg-brand-color-6);
        }
      }
    }
  }
  .tab-body {
    flex: 1;
    overflow: auto;
  }
}
.empty-text {
  padding: 20px;
  text-align: center;
  font-size: 12px;
}
.loading-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.source-item {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-size: 12px;

  cursor: pointer;
  &:hover {
    background-color: rgba($color: #3870ea, $alpha: 0.15);
  }
  &.chk {
    background-color: rgba($color: #3870ea, $alpha: 0.75);
    color: #ffffff;
  }
}
.search-input {
  position: absolute;
  right: 0px;
  width: 100%;
  height: 37px;
  top: 0px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: #ffffff;
  transition-property: width;
  transition-duration: 200;
  ::v-deep {
    .el-input__inner {
      background-color: #eaeced;
      border-radius: 20px;
    }
  }
  &.hide {
    width: 100px;
    background-color: transparent;
  }
}
.tab-title {
  font-size: 14px;
  padding: 10px;
  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
}

.box-padding {
  padding: 0 10px;
}
.box-title-padding {
  padding: 5px 0;
}
.noData {
  display: flex;
  min-height: 100px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

::v-deep {
  .el-collapse {
    border-top: 0px solid #f4f5f7;
    border-bottom: 0px solid #f4f5f7;
    margin: 0 20px;
  }
}
.tab-title1 {
  font-size: 14px;
  padding: 10px;
}
</style>
