import { render, staticRenderFns } from "./FormItem.vue?vue&type=template&id=db328e06&scoped=true"
import script from "./FormItem.vue?vue&type=script&lang=js"
export * from "./FormItem.vue?vue&type=script&lang=js"
import style0 from "./FormItem.vue?vue&type=style&index=0&id=db328e06&prod&lang=scss&scoped=true"
import style1 from "./FormItem.vue?vue&type=style&index=1&id=db328e06&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db328e06",
  null
  
)

export default component.exports