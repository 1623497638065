<template>
  <el-input
    v-model="value"
    placeholder=""
    @focus="isFocus = true"
    @blur="isFocus = false"
  >
    <template
      v-if="
        component.componentName == 'input_number' &&
        component.format == 'percent'
      "
      slot="append"
      >%
    </template>
  </el-input>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import test from "@/utils/test";
import { _text } from "@/zgg-core/relyUtil";
export default {
  props: {
    form: Object,
    component: Object,
  },
  computed: {
    value: {
      get() {
        let value = this.form[this.component.name];
        if (this.isFocus) {
          // 获取到焦点时候
          return value;
        }

        if (!isEmpty(value)) {
          value = value.toString();
        }

        if (this.component.decimalPrecision && test.number(value)) {
          // value =
          let index = value.indexOf(".");
          let num = this.component.decimalPrecision;

          if (index > 0) {
            value = value.substring(0, index + num + 1);
          } else if (index == 0) {
            value = value.substring(0, index + num + 1);

            if (num == 0) {
              value = 0;
            } else {
              value = "0" + value;
            }
          }
          value = new Number(value).toFixed(num);
          value = parseFloat(value);
        } else if (test.number(value) && value != "") {
          value = parseFloat(value);
        }
        if (this.component.thousandsSeparator) {
          value = _text(value, "#,#.#############");
        }
        return value;
      },
      set(val) {
        this.$set(this.form, this.component.name, val);
      },
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
};
</script>
