import request from '@/utils/request'
import qs from "qs"
import store from '@/store'

/**
 * 创建智能助手
 * @param {*} title
 * @returns
 */
export function createSpirit(data) {
    data = {
        ...data,
        companyId:store.getters.currentCompanyId
    }
    return request({
        url:  "/rpa/spirit/create",
        method: 'post',
        data
    })
}

/**
 *
 * @param {*} data
 */
export function fetchSpiritList(data) {
    data = {
        ...data,
        companyId:store.getters.currentCompanyId
    }
    return request({
        url:  "/rpa/spirit/list",
        method: 'post',
        data
    })
}

/**
 * 切换智能助手状态
 * @param {*} spiritId
 * @returns
 */
export function switchSpirit(spiritId) {
    return request({
        url:  '/rpa/spirit/switchEnable',
        method: 'post',
        data: { spiritId }
    })
}

/**
 * 拷贝智能助手
 * @param {*} data
 * @returns
 */
export function copySpirit(data) {
    return request({
        url:  "/rpa/spirit/duplicate",
        method: 'post',
        data
    });
}

/**
 * 删除智能助手
 * @param {*} spiritIds
 * @returns
 */
export function deleteSpirit(spiritIds) {
    return request({
        url:  "/rpa/spirit/delete",
        method: 'post',
        data: { spiritIds }
    })
}

/**
 * 重命名智能助手
 * @param {*} data
 * @returns
 */
export function renameSpirit(data) {
    return request({
        url:  "/rpa/spirit/rename",
        method: 'post',
        data
    });
}

/**
 * 保存智能助手流程
 * @param {*} data
 * @returns
 */
export function saveSpiritFlow(data) {
    return request({
        url:  "/rpa/spirit/saveFlow",
        method: 'post',
        data
    })
}

/**
 * 短信模板提交审核
 * @param {*} data
 * @returns
 */
export function saveTemplate(data) {
    data = {
        ...data,
        companyId:store.getters.currentCompanyId
    }
    return request({
        url:  "/sms/template/save",
        method: 'post',
        data
    })
}

/**
 * 获取短信模板列表
 * @param {*} data
 * @returns
 */
export function getSmsTemplateList(data) {
    data = {
        ...data,
        companyId:store.getters.currentCompanyId
    }
    return request({
        url:  "/sms/template/list",
        method: 'post',
        data
    })
}
/**
 * 获取短信模板详情
 * @param {*} data
 * @returns
 */
export function getSmsTemplateDetail(data) {
    return request({
        url:  "/sms/template/detail",
        method: 'post',
        data
    })
}

/**
 * 删除短信模板
 * @param {*} data
 * @returns
 */
export function deleteSmsTemplate(data) {
    return request({
        url:  "/sms/template/delete",
        method: 'post',
        data
    })
}

/**
 * 短信通知编码
 * @returns 
 */
export function getSmsTplSysCode() {
    return request({
        url: "/sms/template/sysCode",
        method: "POST",
    })
}

/**
 * 短信通知类型
 * @returns 
 */
export function getSmsTplNoticeType() {
    return request({
        url: "/sms/template/noticeType",
        method: "POST",
    })
}

/**
 * 模板审批流程占位符对应字段
 * @param {*} data 
 * @param {string} sysCode 【短信模版类型】 
 * @returns 
 */
export function getSmsTplFlowField(data) {
    return request({
        url: "/sms/template/flowField",
        method: "POST",
        data,
    })
}  

/**
 * 获取智能助手详情
 * @param {*} spiritId
 * @returns
 */
export function getSpiritDetail(spiritId) {
    return request({
        url:  "/rpa/spirit/detail",
        method: 'post',
        data: { spiritId }
    })
}

/**
 * 获取智能助手日志
 * @param {*} data
 * @returns
 */
export function getSpiritLog(data) {
    return request({
        url:  '/rpa/spirit/log/list',
        method: 'post',
        data
    })
}
