<template>
  <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
    <el-form-item label="推送内容">
      <el-input value="打开记录详情" readonly size="small"></el-input>
    </el-form-item>
    <el-form-item label="记录" prop="formDataNodeKey" :rules="rules">
      <el-select v-model="formDataNodeKey" placeholder="请选择记录">
        <el-option
          v-for="item in nodeList"
          :key="`${item.key}_${item.collection}`"
          :label="`${item.title}【${item.collectionTitle}】`"
          :value="`${item.key}_${item.collection}`"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import { fromNodesMixins } from "./rpa-utils";
export default {
  name: "rpa-render-form",
  props: {
    node: Object,
    disabled: Boolean,
  },
  mixins: [fromNodesMixins],
  data() {
    return {
      rules: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (isEmpty(value)) {
              callback(new Error("请选择记录"));
            } else {
              let formDataNodeKey = this.node.formDataNodeKey;
              let collection = this.node.formId;
              let node = this.nodeList.find(
                (item) => item.key == formDataNodeKey,
              );
              if (!node) {
                callback(new Error("节点已删除"));
              } else if (node.collection != collection) {
                callback(new Error("节点表单已发生变化，请重新选择"));
              } else {
                callback();
              }
            }
          },
        },
      ],
      nodeList: [],
    };
  },
  computed: {
    formDataNodeKey: {
      get() {
        if (!isEmpty(this.node.formDataNodeKey)) {
          return this.node.formDataNodeKey + "_" + this.node.formId;
        }
        return "";
      },
      set(val) {
        let node = this.nodeList.find(
          (item) => item.key + "_" + item.collection == val,
        );
        this.$set(this.node, "formDataNodeKey", node.key);
        this.$set(this.node, "formId", node.collection);
        this.$set(this.node, "formTitle", node.collectionTitle);
      },
    },
  },

  created() {
    /**
     * @type Array
     */
    let nodeList = this.getParentNodes();
    nodeList = nodeList.filter((item) => {
      if (
        ["query_data_one", "action_form_data_create"].includes(item.type) &&
        item.dataSource == "form"
      ) {
        return !(
          item.type == "action_form_data_create" && !isEmpty(item.baseNodeKey)
        );
      }
    });
    nodeList.forEach((item) => {
      item.collection = item.formId || item.collection;
      item.collectionTitle = item.formTitle || item.collectionTitle;
    });
    this.nodeList = nodeList;
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (
          this.nodeList.findIndex(
            (item) => item.key == this.node.formDataNodeKey,
          ) == -1
        ) {
          this.$message.error("记录节点被删除了，请重新选择节点");
          return;
        }

        callback(valid);
      });
    },
  },
};
</script>
