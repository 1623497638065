<template>
  <div>
    <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
      <el-form-item prop="dataSource">
        <el-radio-group v-model="dataSource" @change="changeDataSource">
          <el-radio label="form">新增工作表记录</el-radio>
          <el-radio label="node_data">新增子表记录</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="node.dataSource == 'node_data'"
        key="node_data"
        label="节点列表"
        :rules="[{ required: true, message: '请选择节点' }]"
        prop="nodeKey"
      >
        <el-select
          v-model="nodeKey"
          placeholder="请选择节点"
          @change="changeNodeKey"
          size="small"
        >
          <el-option
            v-for="item in nodeList"
            :key="item.key"
            :label="item.title"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-else
        key="form"
        label="选择工作表"
        :rules="[{ required: true, message: '请选择工作表' }]"
        prop="formId"
      >
        <el-input
          :value="node.formTitle"
          @focus="visible = true"
          readonly
          size="small"
        ></el-input
      ></el-form-item>

      <rpa-add-type :node="node"></rpa-add-type>
      <el-form-item label="新增记录">
        <template v-for="item in node.createFieldValues">
          <rpa-field-setting
            :key="item.tableField.fieldName"
            :field="item"
            type="add"
            :componentList="componentList"
            :disabled="disabled"
            :node="node"
          ></rpa-field-setting>
        </template>
      </el-form-item>
      <data-source
        :visible.sync="visible"
        :menus="['form']"
        @changeSource="changeForm"
      ></data-source>
    </el-form>
  </div>
</template>
<script>
import dataSource from "@/components/data-source";
import { getFormDetail } from "../form/api";
import { getFormComponents, isEmpty } from "@zgg-core-utils/utils";
import rpaFieldSetting from "./rpa-field-setting";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import rpaAddType from "@/views/lowCode/rpa/rpa-add-type";
import { rpaUpdateByNodeMixins } from "./rpa-utils";
import { isIDEmpty } from "@/zgg-core/utils";

export default {
  name: "rpa-form-create",
  props: {
    node: Object,
    disabled: Boolean,
  },
  components: {
    dataSource,
    rpaFieldSetting,
    rpaAddType,
  },
  mixins: [rpaUpdateByNodeMixins],
  data() {
    return {
      visible: false,
      componentList: [],
    };
  },

  created() {
    if (!isIDEmpty(this.node.formId)) {
      this.fetchMetaComponents(this.node.formId);
    }
    if (!this.node.dataSource) {
      this.$set(this.node, "dataSource", "form");
    }
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (
          this.node.createFieldValues.findIndex(
            (item) =>
              (item.valueType == "custom" && isEmpty(item.value)) ||
              (item.valueType == "nodeField" && isEmpty(item.nodeField.name)) ||
              (item.valueType == "rely" && isEmpty(item.rely)),
          ) >= 0
        ) {
          this.$message.error("请设置新增记录的字段值");
          return;
        }
        callback(valid);
      });
    },
    changeForm({ tableName, tableTitle }) {
      if (this.node.formId != tableName && !isEmpty(this.node.formId)) {
        this.$confirm("切换表单将会清除已设置的配置", "提示")
          .then(() => {
            // todo 数据变动触发器，数据变动时其他节点也一起变化
            this.$set(this.node, "formId", tableName);
            this.$set(this.node, "formTitle", tableTitle);
            this.$set(this.node, "createFieldValues", []);
            this.fetchMetaComponents(tableName);
          })
          .catch(() => {});
      } else {
        this.$set(this.node, "formId", tableName);
        this.$set(this.node, "formTitle", tableTitle);
        this.fetchMetaComponents(tableName);
      }
    },
    fetchMetaComponents(tableName) {
      getFormDetail(tableName).then((res) => {
        let componentList = getFormComponents(
          res.data.form.component.components,
        );
        // componentList = componentList.filter(
        //   (item) => item.componentName != "json_form"
        // );
        this.componentList = componentList;
        this.buildCreateFelds(componentList);
      });
    },
    buildCreateFelds(componentList) {
      let createFieldValues = [];
      componentList
        .filter(
          (item) =>
            !!checkFieldAbility(item, "fillComponents") ||
            item.componentName == "json_form",
        )
        .forEach((item) => {
          let field = this.node.createFieldValues.find(
            (row) => row.tableField.fieldName == item.name,
          );
          let referenceTableName;
          let referenceCollection;

          if (["reference_data", "cascade_data"].includes(item.componentName)) {
            referenceTableName = item.tableName;
          }
          if (item.componentName == "json_form") {
            referenceCollection = item.tableName;
          }
          if (isEmpty(field)) {
            createFieldValues.push({
              tableField: {
                collection: this.node.formId,
                fieldName: item.name,
                fieldTitle: item.title,
                componentName: item.componentName,
                picker: item.picker,
                referenceTableName: referenceTableName || referenceCollection,
              },
              valueType: "nodeField",
              nodeField: {
                nodeKey: "",
                collection: "",
                name: "",
                title: "",
                componentName: "",
                picker: "",
              },
              value: "",
            });
          } else {
            createFieldValues.push({
              ...this._.cloneDeep(field),
              referenceTableName,
            });
          }
        });
      this.$set(this.node, "createFieldValues", createFieldValues);
    },
  },
};
</script>
<style lang="scss" scoped></style>
