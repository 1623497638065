<template>
  <div ref="chart" class="chart-radar"></div>
</template>
<script>
import { getAggreation } from "../api";
import { chartColors, getChartColorConfig } from "./chartUtil";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";
import { getNumberFormat, isEmpty } from "@/zgg-core/utils";
export default {
  mixins: [chartResize],
  data() {
    return {
      list: [],
      view: null,
      option: null,
      observer: null,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  destroyed() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  methods: {
    getData(view, dataFilter) {
      this.$set(this, "view", view);
      if (!view.metrics.length) {
        return;
      }
      if (!view.xFields.length) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
      }
      // if (view.dataSource.queryObject && view.dataSource.queryObject.pageSize) {
      //   queryObject.pageSize = view.dataSource.queryObject.pageSize;
      // }

      queryObject.xFields = view.xFields;
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        // console.log("chart data");
        // console.log(res);
        this.list = res.data.list;
        this.buildChart(view);
      });
    },
    buildChart(view) {
      const result = getChartColorConfig(view, this.isH5);
      this.remindColor = result.remindColor;
      let textStyle = result.textStyle;
      let list = this.list;

      // 基于准备好的dom，初始化echarts实例
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(this.$refs.chart);

      let option = {
        textStyle: textStyle,
        color: this.remindColor ? this.remindColor : chartColors,
        tooltip: {
          show: true,
        },
        legend: {
          icon: "circle",
        },
        radar: {
          indicator: [],
          splitArea: {
            show: false,
          },
        },
      };
      let min = view.yAxis.min;
      let max = view.yAxis.max;
      let series = [];
      let lengedData = [];
      if (view.xFields.length) {
        let fieldName = view.xFields[0].field.name;
        list.forEach((row) => {
          let indicatorObj = {
            name: row[fieldName],
          };
          if (max) indicatorObj.max = max;
          if (min) indicatorObj.min = min;
          if (
            !option.radar.indicator.some((item) => item.name == row[fieldName])
          ) {
            option.radar.indicator.push(indicatorObj);
          }
        });
      }
      if (view.xFields.length == 1) {
        view.metrics.forEach((item, index) => {
          if (!lengedData.some((v) => v === item.title)) {
            lengedData.push(item.title);
          }
          let data = [];
          let value = [];
          let realVal = [];
          let format = getNumberFormat(item.numberFormat);
          list.forEach((row) => {
            value.push(row[item.name] > max && max ? max : row[item.name]);

            if (format) {
              realVal.push(row[item.name + "Format"]);
            } else {
              realVal.push(row[item.name]);
            }
          });
          let show = view.chartLabel.enable;
          if (typeof value === "undefined") {
            show = false;
          }

          data.push({
            value,

            label: {
              show,
              position: "top",
              formatter: (params) => {
                return realVal[params.dimensionIndex];
              },
            },
          });
          let obj = {
            name: item.title,
            type: "radar",
            data,
            label: {
              textStyle: textStyle,
            },
            colorBy: "series",
            tooltip: {
              trigger: "item",
              formatter: (params) => {
                return `<div style="min-width: 80px">${
                  params.seriesName
                }</div>${option.radar.indicator
                  .map((row, index) => {
                    return `<div>${
                      params.marker +
                      `${row.name}:${realVal[index] ? realVal[index] : ""}`
                    }</div>`;
                  })
                  .join("")}`;
              },
            },
          };
          if (view.radarType != "line") {
            obj.areaStyle = {
              opacity: 0.1,
            };
          }
          if (item.color) {
            obj.itemStyle = {
              color: item.color,
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }

          series.push(obj);
        });
      } else if (view.xFields.length == 2) {
        list.forEach((row) => {
          let legendName = row[view.xFields[1].field.name];
          if (!lengedData.some((v) => v === legendName)) {
            lengedData.push(legendName);
          }
        });
        let name0 = view.xFields[0].field.name;
        let name = view.xFields[1].field.name;
        let metrics = view.metrics[0];
        let metricsName = view.metrics[0].name;
        let format = getNumberFormat(view.metrics[0].numberFormat);
        lengedData.forEach((key, index) => {
          let obj = {
            name: key,
            type: "radar",
            data: [],
            colorBy: "series",
          };
          if (view.radarType != "line") {
            obj.areaStyle = {
              opacity: 0.1,
            };
          }
          if (metrics.color) {
            obj.itemStyle = {
              color: metrics.color,
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }
          let value = [];
          let realVal = [];
          let show = view.chartLabel.enable;
          option.radar.indicator.forEach((row) => {
            let item = list.find(
              (item) => item[name] === key && item[name0] === row.name,
            );
            if (item) {
              value.push(
                item[metricsName] > max && max ? max : item[metricsName],
              );
              if (format) {
                realVal.push(item[metricsName + "Format"]);
              } else {
                realVal.push(item[metricsName]);
              }
            } else {
              value.push(undefined);
              realVal.push(undefined);
            }
          });
          obj.data.push({
            value,
            realVal,
            label: {
              show,
              textStyle: textStyle,
              formatter: (params) => {
                return realVal[params.dimensionIndex];
              },
            },
          });
          (obj.tooltip = {
            trigger: "item",
            formatter: (params) => {
              return `<div style="min-width: 80px">${key}</div>${option.radar.indicator
                .map((row, index) => {
                  return `<div>${
                    params.marker +
                    `${row.name}:${realVal[index] ? realVal[index] : "-"}`
                  }</div>`;
                })
                .join("")}`;
            },
          }),
            series.push(obj);
        });
      }
      if (view.chartLegend && view.chartLegend.enable) {
        let legend = {
          type: "scroll",
          data: lengedData,
          show: true,
        };
        if (view.chartLegend.position == "top") {
          legend.x = "center";
          legend.y = "top";
          option.radar.radius = "60%";
          option.radar.center = ["50%", "55%"];
        }
        if (view.chartLegend.position == "bottom") {
          legend.x = "center";
          legend.y = "bottom";
          option.radar.radius = "60%";
          option.radar.center = ["50%", "45%"];
        }
        if (view.chartLegend.position == "left") {
          legend.x = "left";
          legend.y = "center";
          legend.orient = "vertical";
          option.radar.radius = "60%";
          option.radar.center = ["65%", "50%"];
        }
        if (view.chartLegend.position == "right") {
          legend.x = "right";
          legend.y = "center";
          legend.orient = "vertical";
          option.radar.radius = "60%";
          option.radar.center = ["35%", "50%"];
        }
        Object.assign(option.legend, legend);
      } else {
        option.legend.show = false;
      }

      if (!max) {
        let maxValue = 0;
        series.forEach((row) => {
          row.data.forEach((item) => {
            let arr = item.value
              .filter((val) => !isEmpty(val))
              .map((val) => parseFloat(val));

            maxValue = Math.max(...arr, maxValue);
          });
        });
        option.radar.indicator.forEach((item) => {
          if (!item.max) {
            item.max = maxValue;
          }
        });
      }

      option.series = series;
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
      this.setChartResize();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-radar {
  height: 100%;
}
</style>
