var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"el-meta-field",on:{"click":function($event){$event.stopPropagation();_vm.visible = true}}},[_c('div',{staticClass:"meta-text"},[_vm._v(" "+_vm._s(_vm.component.extParam && _vm.component.extParam.mateLabel ? _vm.component.extParam.mateLabel : "请选择关联表单")+" ")]),_c('i',{staticClass:"el-icon-arrow-down"})]),_c('data-source',{attrs:{"visible":_vm.visible,"idNotEquals":_vm.idNotEquals,"mode":"field"},on:{"update:visible":function($event){_vm.visible=$event},"changeSource":_vm.changeSource}}),(
      _vm.component.componentName == 'select' &&
      _vm.component.extParam &&
      _vm.component.extParam.mateLabel
    )?[_c('attr-select-fields',{staticStyle:{"margin":"10px 0 0"},attrs:{"component":_vm.component.metaFieldOption,"columns":_vm.componentList}})]:_vm._e(),_c('div',{staticClass:"attr-label yui",staticStyle:{"margin-top":"10px"}},[_vm._v("选项排序")]),(_vm.component.metaFieldOption)?_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"","size":"mini"},model:{value:(_vm.component.metaFieldOption.orderBy),callback:function ($$v) {_vm.$set(_vm.component.metaFieldOption, "orderBy", $$v)},expression:"component.metaFieldOption.orderBy"}},[_c('el-option',{attrs:{"label":"按选项的值升序","value":"orderByOptionValueAsc"}}),_c('el-option',{attrs:{"label":"按选项的值降序","value":"orderByOptionValueDesc"}}),_c('el-option',{attrs:{"label":"按提交时间升序","value":"orderByCreatedTimeAsc"}}),_c('el-option',{attrs:{"label":"按提交时间降序","value":"orderByCreatedTimeDesc"}})],1):_vm._e(),(
      _vm.component.componentName == 'select' ||
      _vm.component.componentName == 'select_checkbox'
    )?[(_vm.component.optionSource == 'metaField')?_c('attr-filter',{staticStyle:{"margin":"0px -10px -10px"},attrs:{"component":_vm.component,"params":_vm.params,"tableColumns":_vm.componentList}}):_vm._e(),(_vm.showAction())?_c('attr-table-action',{staticStyle:{"margin":"10px 0"},attrs:{"component":_vm.component}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }