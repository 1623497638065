<template>
  <div class="group-container">
    <template v-if="tab == 'attribute'">
      <div class="container-left">
        <div style="font-size: 14px">分组字段</div>
        <etl-group-fields
          title="添加分组字段"
          :fieldList="xFeildOptions"
          @addItem="addXFields"
        ></etl-group-fields>
        <div class="scroll-body">
          <draggable
            :list="node.xfields"
            animation="300"
            handle=".icon-pc-drag"
            @end="changeChildrenData"
          >
            <etl-group-x-field
              v-for="(item, index) in node.xfields"
              :key="index"
              :node="item"
              :xfields="node.xfields"
              :index="index"
              :isDelete="isDelete"
              @changeChildrenData="changeChildrenData"
            ></etl-group-x-field>
          </draggable>
        </div>
      </div>
      <div class="container-right">
        <div style="font-size: 14px">汇总字段</div>
        <etl-group-fields
          title="添加汇总字段"
          :fieldList="metricOptions"
          @addItem="addMetric"
        ></etl-group-fields>
        <div class="scroll-body" style="width: 500px">
          <draggable
            :list="node.metrics"
            animation="300"
            handle=".icon-pc-drag"
            @end="changeChildrenData"
          >
            <etl-group-metric
              v-for="(item, index) in node.metrics"
              :key="index"
              :node="item"
              :metrics="node.metrics"
              :index="index"
              :isDelete="isDelete"
              @changeChildrenData="changeChildrenData"
            ></etl-group-metric>
          </draggable>
        </div>
      </div>
    </template>
    <template v-else>
      <preview-error v-if="isError"></preview-error>
      <etl-preview
        v-else
        :node="node"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-preview>
    </template>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import nodeMixin from "./nodeMixin";
import PreviewError from "./PreviewError";
import { changeChildrenData, deleteMixins, getFieldListByNode } from "./util";
import EtlGroupXField from "./EtlGroupXField";
import EtlGroupFields from "./EtlGroupFields";
import EtlGroupMetric from "./EtlGroupMetric";
import { groupXFeildFields } from "@/zgg-core/whiteList";
import { uuid } from "@/zgg-core/utils";
import EtlPreview from "./EtlPreview";

export default {
  components: {
    Draggable,
    PreviewError,
    EtlGroupXField,
    EtlGroupFields,
    EtlGroupMetric,
    EtlPreview,
  },
  mixins: [nodeMixin, deleteMixins],
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },

  computed: {
    componentList() {
      let fromKey = this.etlLines.find(
        (item) => item.toKey == this.node.key,
      ).fromKey;
      let fromNode = this.etlList.find((item) => item.key == fromKey);

      return getFieldListByNode(fromNode, this.etlList);
    },
    xFeildOptions() {
      let list = this._.cloneDeep(this.componentList);

      list = list.filter((item) =>
        groupXFeildFields.includes(item.componentName),
      );
      list.forEach((field) => {
        let key = field.name;
        if (this.node.xfields) {
          field.disabled =
            this.node.xfields.findIndex((item) => item.field.name == key) >= 0;
        } else {
          field.disabled = false;
        }
      });

      return list;
    },
    metricOptions() {
      let list = this._.cloneDeep(this.componentList);
      list = list.filter((item) => !item.array && item.componentName != "id");
      return list;
    },
  },
  methods: {
    changeChildrenData() {
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
    addMetric(item) {
      // 添加指标

      let node = {
        field: this.buildField(item),
        name: uuid(),
        title: item.comment,
        oper: item.componentName == "input_number" ? "sum" : "count",
      };

      if (!this.node.metrics) {
        this.$set(this.node, "metrics", []);
      }
      this.node.metrics.push(node);
      this.changeChildrenData();
    },
    addXFields(item) {
      // 添加维度
      let node = {};
      node.title = item.comment;
      if (["address_input", "position_input"].includes(item.componentName)) {
        node.addressPrecision = {
          picker: item.picker == "address" ? "county" : item.picker,
        };
      } else if (item.componentName == "date_picker") {
        node.datePrecision = {
          picker: ["year", "month", "date"].includes(item.picker)
            ? item.picker
            : "date",
        };
      }

      node.field = this.buildField(item);
      if (!this.node.xfields) {
        this.$set(this.node, "xfields", []);
      }
      this.node.xfields.push(node);
      this.changeChildrenData();
    },
    buildField(item) {
      let field = {
        name: item.name,
        comment: item.comment,
        componentName: item.componentName,
        collection: item.collection,
        picker: item.picker,
        component: {
          ...this._.cloneDeep(item.component),
          title: item.comment,
          key: item.name,
          name: item.name,
          componentName: item.componentName,
          picker: item.picker,
        },
      };
      return field;
    },
  },
};
</script>
<style lang="scss" scoped>
.group-container {
  display: flex;
  height: 100%;
  background-color: #ffffff;
  .container-left {
    width: 310px;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .container-right {
    flex: 1;
    height: 100%;
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .scroll-body {
    flex: 1;
    overflow: auto;
  }
}
</style>
