<template>
  <div>
    <zgg-filter :componentList="sourceComponentList" :dataFilter="dataFilter">
      <template slot-scope="{ field }">
        <ui-advance-item
          :filterField="field"
          :sourceComponentList="sourceComponentList"
          :componentList="componentList"
          :isJsonForm="isJsonForm"
          :parentName="parentName"
          :name="name"
          :formId="formId"
        ></ui-advance-item>
      </template>
    </zgg-filter>
  </div>
</template>
<script>
import uiAdvanceItem from "@/components/advance-query/ui-advance-item";
import { getFormComponents } from "@/views/lowCode/form/utils";
import uiAddFilter from "@/components/advance-query/ui-add-filter";
export default {
  name: "ui-advance-filter",
  components: { uiAdvanceItem, uiAddFilter },
  props: {
    /** 所在层级 */
    level: {
      type: Number,
      default() {
        return 1;
      },
    },
    /** 过滤项 */
    dataFilter: {
      type: Object,
      required: true,
    },
    isInclude: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /** 设置过滤条件的组件名称 */
    name: String,
    /**
     * 条件源组件列表
     */
    sourceComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * 第三方表单组件列表
     */
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    index: Number,
    formId: String,
  },
  computed: {
    isJsonForm() {
      let isJson = false;
      let components = getFormComponents(this.sourceComponentList);

      components.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.name) {
              isJson = true;
            }
          });
        }
      });
      return isJson;
    },

    parentName() {
      let name = "";
      let components = getFormComponents(this.sourceComponentList);

      components.forEach((item) => {
        if (item.componentName == "json_form") {
          item.components.forEach((sub) => {
            if (sub.name == this.name) {
              name = item.name;
            }
          });
        }
      });
      return name;
    },
  },
  data() {
    return {};
  },

  methods: {
    delFilter(index) {
      this.dataFilter.advanceQuery.splice(index, 1);
      if (this.dataFilter.advanceQuery.length == 0 && this.isInclude) {
        this.$emit("delParentFilter", this.index);
      }
    },
    copyFilter(index) {
      let item = this._.cloneDeep(this.dataFilter.advanceQuery[index]);
      this.dataFilter.advanceQuery.splice(index + 1, 0, item);
    },
    changeGroup(index) {
      let item = this._.cloneDeep(this.dataFilter.advanceQuery[index]);
      let obj = {
        queryChain: {
          [this.dataFilter.rel]: [item],
        },
      };

      this.dataFilter.advanceQuery.splice(index, 1, obj);
    },
    createFilter(command) {
      let queryObj = {
        key: "",
        oper: "",
        valueType: "tableField",
        value: "",
      };
      if (command == "single") {
        // 单条件
        this.dataFilter.advanceQuery.push(queryObj);
      } else {
        // 条件组

        this.dataFilter.advanceQuery.push({
          queryChain: { [this.dataFilter.rel]: [queryObj] },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ui-filter-group {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 10px 8px;
  margin-left: 10px;
  flex: 1;
}
.ml {
  margin-left: 10px;
}
</style>
