

/**
 * 返回如果是嵌套路由，回到父路由
 */
export function back() {
  let { matched } = this.$router.history.current;
  if (matched.length >= 2) {
    let parentPath = matched[matched.length - 2].path;
    this.$router.push({ path: parentPath });
  } else {
    this.$router.back();
  }
}


export function isSortableComponent(name) {
  let array = ['input', 'text_area', 'input_number', 'date_picker', 'select', 'sn_input', 'radio_group'];
  return array.includes(name);
}
/**
 * 低代码表格列表不同数据类型的最小宽度定义枚举
 */
export function getTableColumnWidthByColumn(field) {
  let {componentName, name} = field
  
  const componentNames = {
    "input": 150,
    "text_area": 300,
    "input_number": 150,
    "date_picker": 200,
    "radio_group": 150,
    "checkbox_group": 150,
    "select": 150,
    "json_form": '',
    "address_input": 300,
    "image_uploader": 150,
    "attachment_uploader": 150,
    "sign_input": 150,
    "form_data": 150,
    "reference_data": 150,
    "sn_input": 150,
    "user_select": 150,
    "user_list_select": 250,
    'department_select': 150,
    "department_list_select": 250,
    "sign_list_input": 250,

  }
  const fieldName = {
    "createdTime": 160,
    "createrName": 150,
    "_id": 60,
    "_index": 76
  }
  return (componentNames[componentName] || fieldName[name] || 150) + 'px'

}

/**
 * document对象的通用默认字段，目前为了处理关联查询的数据填充到主document的时候，会将关联查询子document内的通用字段填充到主document，但目前也有个问题，就是加了多个关联查询，然后他们的关联表都是同一个，显示的列也是同样的，这时候会有覆盖填充的问题，虽然代码上允许，但业务上应该不存在这种逻辑，A表在B表内的关联插叙应该只有一个。
 * @param name
 * @returns {boolean}
 */
export function isDocumentCommonFieldKey(name){
  let array = ['_id', 'createrId', 'createrName', 'companyId', 'chatId', 'bulletinId', 'updaterId','createdTime','updatedTime','sortNumber','recycleData','attachmentList','attachmentListFlag','deleted','workflowData','dataTitle'];
  return array.includes(name);
}