export default [
  {
    title: "输入",
    stageType: "input",
    fields: [],
  },
  {
    title: "输出",
    stageType: "output",
    fields: [],
  },
  {
    title: "横向连接",
    stageType: "join",
    join: "inner",
    joinFields: [
      {
        leftField: {},
        rightField: {},
      },
    ],
    mergeJoinFields: true,
    fields: [],
  },
  {
    title: "追加合并",
    stageType: "union",
    unionFields: [],
    fields: [],
  },
  {
    title: "分组汇总",
    stageType: "group",
    xfields: [],
    metrics: [],
    fields: [],
  },
  {
    title: "数据筛选",
    stageType: "filter",
    dataFilter: {
      rel: "and",
      advanceQuery: [],
    },
    fields: [],
  },
  {
    title: "字段设置",
    stageType: "map",
    mapFields: [],
    fields: [],
  },
  {
    title: "行转列",
    stageType: "rowToCol",
    xfields: [],
    colField: null,
    colValues: [],
    metric: null,
    fields: [],
  },
];
