<template>
  <div>
    <attr-filter
      :component="component"
      :params="params"
      :tableColumns="tableColumns"
    ></attr-filter>
    <attr-table-action
      :component="component"
      :params="params"
      style="margin-top: 0px"
    ></attr-table-action>
  </div>
</template>
<script>
import { componentProps } from "./util";
import attrFilter from "@/views/lowCode/form/attribute/attr-filter";
import attrTableAction from "@/views/lowCode/form/attribute/attr-table-action";
export default {
  inject: ["getComponents"],
  name: "attr-parent-data",
  components: {
    [attrFilter.name]: attrFilter,
    [attrTableAction.name]: attrTableAction,
  },
  props: componentProps,
  computed: {
    tableColumns() {
      return this.getComponents();
    },
  },
};
</script>