<template>
  <div class="etl-input">
    <div class="content-left">
      <div class="block-head">
        <span class="block-title">输入源</span>
        <span class="block-link" @click="changeSource">更改输入源</span>
      </div>
      <div class="block-head">
        <div class="block-title">已选字段</div>
      </div>
      <div class="block-scroll">
        <div
          v-for="item in node.selectFields"
          :key="item.name"
          v-text="item.component.title"
          class="list-item"
          :class="isDelete(item.name) ? 'is-delete' : ''"
        ></div>
        <template
          v-if="
            node.selectSubField &&
            node.selectSubField.jsonFields &&
            node.selectSubField.jsonFields.length
          "
        >
          <div style="border-top: 1px solid #e9e9e9"></div>
          <div
            v-for="item in node.selectSubField.jsonFields"
            :key="item.name"
            v-text="
              node.selectSubField.component.title + '.' + item.component.title
            "
            class="list-item"
            :class="
              isDelete(item.name, node.selectSubField.name) ? 'is-delete' : ''
            "
          ></div>
        </template>
      </div>
    </div>
    <div class="content-right">
      <el-table
        v-if="isRefresh"
        :data="page.list"
        :height="300"
        style="width: 100%"
      >
        <zgg-table-column
          v-for="item in tableColumns"
          :key="item.name"
          :label="item.comment"
          :prop="item.name"
          :field="item"
          :is-sortable="false"
          :attachmentAtts="attachmentAtts"
        ></zgg-table-column>
      </el-table>
      <div style="height: 42px; background: #ffffff">
        <pagination
          style="float: right"
          :total="page.totalRow"
          :page.sync="page.pageNumber"
          :limit.sync="page.pageSize"
          @pagination="loadmore"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getComponents } from "@/zgg-core/utils";
import { isExistFun } from "./util";

export default {
  inject: ["fetchStageData", "fetchMetaFieldComponentList"],
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },
  watch: {
    node: {
      immediate: true,
      deep: true,
      handler() {
        if (this.node) {
          this.tableColumns = this.node.fields.filter(
            (item) => !["id"].includes(item.componentName),
          );
        }
      },
    },
    "node.dataSource": {
      immediate: true,
      handler(val) {
        if (val && val.formId) {
          this.loadingList(1);

          this.isExistFun(
            "fetchMetaFieldComponentList",
            "请求表单详情的方法",
            false,
          );

          this.loading = true;
          this.fetchMetaFieldComponentList(val.formId, [
            "flowStatus",
            "_id",
            "currentFlowNodeTitles",
            "currentFlowWorkUserIds",
          ]).then((res) => {
            this.componentList = getComponents(res.data.list);
            this.loading = false;
          });
        }
      },
    },
  },

  data() {
    return {
      tableColumns: [],
      isRefresh: true,
      page: {
        pageSize: 20,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
      componentList: [],
      attachmentAtts: {
        preview: false,
        disk: false,
        download: false,
      },
    };
  },
  methods: {
    isExistFun,
    isDelete(name, parent) {
      if (this.loading) {
        return false;
      }
      if (!parent) {
        // 主表字段
        return this.componentList.findIndex((item) => item.name == name) == -1;
      } else {
        // 子表单字段
        let jsonField = this.componentList.find((item) => item.name == parent);
        if (jsonField) {
          return (
            jsonField.components.findIndex((item) => item.name == name) == -1
          );
        } else {
          return true;
        }
      }
    },
    changeSource() {
      this.$emit("update:visible", true);
    },
    loadmore({ page, limit }) {
      this.page.pageSize = limit;
      this.loadingList(page);
    },
    loadingList(pageNumber = 1) {
      this.isExistFun("fetchStageData", "请求节点数据的方法", false);
      this.fetchStageData({
        pageSize: this.page.pageSize,
        pageNumber,
        stageKey: this.node.key,
        stageFlow: {
          stages: this.etlList,
          lines: this.etlLines,
        },
      }).then((res) => {
        let page = res.data.page;
        this.page.pageSize = page.pageSize;
        this.page.totalRow = page.totalRow;
        this.page.totalPage = page.totalPage;
        this.page.pageNumber = page.pageNumber;
        this.page.list = page.list;
        this.isRefresh = false;
        this.$nextTick(() => {
          this.isRefresh = true;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.etl-input {
  display: flex;
  height: 100%;
  background-color: #ffffff;
  .content-left {
    width: 260px;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .content-right {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }
  .block-head {
    font-size: 14px;
    padding: 10px 20px 5px;

    .block-title {
      color: #5e6d82;
      float: left;
    }
    .block-link {
      float: right;
      color: #3870ea; // #0db3a6;
      cursor: pointer;
    }
    &::after {
      content: " ";
      clear: both;
    }
  }
  .block-scroll {
    flex: 1;
    overflow: auto;
  }
  .list-item {
    height: 28px;
    padding: 0;
    overflow: hidden;
    color: #1f2d3d;
    line-height: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
    font-size: 14px;
    padding: 0 20px;
    &.is-delete {
      color: #f56c6c;
    }
  }
}
</style>
