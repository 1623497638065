<template>
  <el-dialog
    title="标题组件"
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    width="790px"
    @open="openDialog"
    @close="handleClose"
    :close-on-click-modal="false"
    :show-close="showClose"
  >
    <div
      style="padding: 24px; display: flex; flex-direction: column;"
      v-if="dialogVisible"
    >
     

    <div class="form-item__wrap">
            <span class="form-item__wrap-title titlespan">选择样式</span>
            <div style="display:flex;flex-direction: column;width: 100%;">
            <div @click="selectStyle('topic-style1')" class="img-div" :class="dashboardTopicModel.titleStyle=='topic-style1'?'active':''">
             <img src="@/assets/add-component/topic/style1-text.png" alt="">
             </div>
             <div @click="selectStyle('topic-style2')" class="img-div" :class="dashboardTopicModel.titleStyle=='topic-style2'?'active':''">
             <img src="@/assets/add-component/topic/style2-text.png" alt="">
            </div>
            <div @click="selectStyle('topic-style3')" class="img-div" :class="dashboardTopicModel.titleStyle=='topic-style3'?'active':''">
             <img src="@/assets/add-component/topic/style3-text.png" alt="">
             </div>
             <div @click="selectStyle('topic-style4')" class="img-div" :class="dashboardTopicModel.titleStyle=='topic-style4'?'active':''">
             <img src="@/assets/add-component/topic/style4-text.png" alt="">
            </div>
            </div>
          </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="saveForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import TEditor from "@/components/TEditor.vue";
//文本类型的筛选器
import { fetchFormDataList } from "@/views/lowCode/form/api";
import { isEmpty } from "@zgg-core-utils/relyUtil";

export default {
  name: "DashboardFreeText",
  components: { TEditor },
  props: {
    dataComponentList: Array,
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      title: "",
      components: [
        {
          label: "标题组件",
          value: "title_view",
        },
      ],
      dashboardTopicModel: {
        extParam:{link:"",color:"#000",align:"center",size:"12px",weight:"bold",italic:"normal",underline:"none"},
        titleStyle:"",
        title:"",
        componentName: "title_view",
      },
    };
  },
  watch: {
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },



  },
  methods: {
    selectStyle(type){
    this.dashboardTopicModel.titleStyle = type
    },
    /**
     * 校验要提交的数据
     */
    validate() {
      console.log(this.dashboardTopicModel,33333);
      const { titleStyle } =
        this.dashboardTopicModel;
      return new Promise((resolve, reject) => {

        if (isEmpty(titleStyle)) {
          this.$message.warning("请选择标题样式");
          return reject();
        }
     
        resolve();
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },

    saveForm() {
      console.log(this.dashboardTopicModel);
      this.validate()
        .then((resp) => {
          console.log("成功");
          this.$emit("save", this.dashboardTopicModel);
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      Object.assign(this.$data, this.$options.data());
    },
    openDialog() {},

    manualInit(dataFilterModel) {
      this.dialogVisible = true;
      this.$set(
        this,
        "dashboardTopicModel",
        this._.cloneDeep(dataFilterModel)
      );
    },


  },
};
</script>
<style lang="scss" scoped>

.form-item__wrap {

margin-top: 57px;

&:first-child {
  margin-top: 0;
}

.form-item__wrap-title {
  margin-bottom: 16px;
  color: #1f2d3d;
  font-size: 14px;
  line-height: 40px;
}
}
.titlespan::before {content: '* ';color: red;}
.img-div{
  width: 100%;
margin-top:20px;
border-radius: 5px;
/* 全局/white */

background: #FFFFFF;
/* border/base */

border: 1px solid #DFE1E6;
padding: 30px 95px;
cursor: pointer;
  img{
     width: 100%;
     margin:auto
  }
}
.img-div:first-child{
  margin-top: 10px;
}
.active{
  border: 1px solid #3870EA;
}

</style>
<style>
.el-dialog__header{
  padding-bottom: 15px;
}
</style>