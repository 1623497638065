<template>
  <div class="attr-row">
    <div class="attr-label">类型</div>

    <el-select
      v-model="component.picker"
      placeholder=""
      size="mini"
      @change="changeAreaPicker"
    >
      <el-option label="省" value="province"></el-option>
      <el-option label="省-市" value="city"></el-option>
      <el-option label="省-市-县" value="county"></el-option>
      <el-option label="省-市-县-详细地址" value="address"></el-option>
    </el-select>
  </div>
</template>
<script>
import { componentProps } from "./util";
export default {
  name: "attr-address-picker",
  props: componentProps,
  methods: {
    changeAreaPicker() {
      this.component.defaultValue.provinceId = "";
      this.component.defaultValue.province = "";
      this.component.defaultValue.cityId = "";
      this.component.defaultValue.city = "";
      this.component.defaultValue.countyId = "";
      this.component.defaultValue.county = "";
      this.component.defaultValue.address = "";
    },
  },
};
</script>