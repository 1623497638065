var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"etl-input"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"block-head"},[_c('span',{staticClass:"block-title"},[_vm._v("输入源")]),_c('span',{staticClass:"block-link",on:{"click":_vm.changeSource}},[_vm._v("更改输入源")])]),_vm._m(0),_c('div',{staticClass:"block-scroll"},[_vm._l((_vm.node.selectFields),function(item){return _c('div',{key:item.name,staticClass:"list-item",class:_vm.isDelete(item.name) ? 'is-delete' : '',domProps:{"textContent":_vm._s(item.component.title)}})}),(
          _vm.node.selectSubField &&
          _vm.node.selectSubField.jsonFields &&
          _vm.node.selectSubField.jsonFields.length
        )?[_c('div',{staticStyle:{"border-top":"1px solid #e9e9e9"}}),_vm._l((_vm.node.selectSubField.jsonFields),function(item){return _c('div',{key:item.name,staticClass:"list-item",class:_vm.isDelete(item.name, _vm.node.selectSubField.name) ? 'is-delete' : '',domProps:{"textContent":_vm._s(
            _vm.node.selectSubField.component.title + '.' + item.component.title
          )}})})]:_vm._e()],2)]),_c('div',{staticClass:"content-right"},[(_vm.isRefresh)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.page.list,"height":300}},_vm._l((_vm.tableColumns),function(item){return _c('zgg-table-column',{key:item.name,attrs:{"label":item.comment,"prop":item.name,"field":item,"is-sortable":false,"attachmentAtts":_vm.attachmentAtts}})}),1):_vm._e(),_c('div',{staticStyle:{"height":"42px","background":"#ffffff"}},[_c('pagination',{staticStyle:{"float":"right"},attrs:{"total":_vm.page.totalRow,"page":_vm.page.pageNumber,"limit":_vm.page.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.page, "pageNumber", $event)},"update:limit":function($event){return _vm.$set(_vm.page, "pageSize", $event)},"pagination":_vm.loadmore}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-head"},[_c('div',{staticClass:"block-title"},[_vm._v("已选字段")])])
}]

export { render, staticRenderFns }