var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-view-select__container"},[_c('el-input',{attrs:{"placeholder":"输入关键字进行过滤"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}),_c('el-tree',{ref:"workDirTreeRef",staticClass:"my-plan-tree",attrs:{"data":_vm.treeData,"expand-on-click-node":true,"highlight-current":true,"indent":10,"props":_vm.workDirTreeProps,"node-key":"id","filter-node-method":_vm.filterNode},on:{"node-click":_vm.handleWorkDirTreeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node",staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","width":"100%"}},[_c('span',{staticClass:"node-label",attrs:{"title":data.title}},[(_vm.enabledNewLayout && data.dataType === 'folder')?[(Object.keys(data.param).length != 0 && data.param.iconStyle)?_c('div',{staticClass:"icon-div",style:('background-color:' + data.param.iconStyle.color)},[_c('i',{staticClass:"iconfont",class:data.param.iconStyle.className,staticStyle:{"color":"#fff","font-size":"14px","margin-right":"0"}})]):_c('div',{staticClass:"icon-div",staticStyle:{"background-color":"#3870ea"}},[_c('i',{staticClass:"iconfont icon-briefcase-fill",staticStyle:{"color":"#fff","font-size":"14px","margin-right":"0"}})])]:[(data.dataType === 'folder')?_c('svg-icon',{staticStyle:{"margin-right":"5px","font-size":"16px"},attrs:{"iconClass":"wenjianjia"}}):(
              data.dataType === 'resource' &&
              data.param.componentName === 'table'
            )?_c('i',{staticClass:"iconfont icon-biaodanqun",staticStyle:{"color":"rgb(178, 154, 232)"}}):(
              data.dataType === 'resource' &&
              data.param.componentName === 'plan_form_view'
            )?_c('i',{staticClass:"iconfont icon-biaodanqun",staticStyle:{"color":"rgb(74, 18, 199)"}}):(
              data.dataType === 'resource' &&
              data.param.componentName === 'dashboard'
            )?_c('i',{staticClass:"iconfont icon-yibiaopan",staticStyle:{"color":"#52c41a"}}):_vm._e()],_c('span',[_vm._v(_vm._s(data.title))])],2)])}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }