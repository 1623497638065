<template>
  <div ref="chart" class="chart-column"></div>
</template>
<script>
import test from "@/utils/test";
import { getAggreation } from "../api";
import {
  chartColors,
  dealWithXFieldValue,
  setGuidelines,
  chartOffsetArr,
  getChartColorConfig,
  axisNameConfig,
  getGrid,
  chartFontSize,
  chartLineHeight,
  chartItemWidth,
} from "./chartUtil";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";
import { getTextWidth } from "@/utils/tools";

export default {
  mixins: [chartResize],
  data() {
    return {
      list: [],
      view: null,
      option: null,
      observer: null,
      isGridChart: true,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  destroyed() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  methods: {
    getData(view, dataFilter) {
      this.$set(this, "view", view);
      if (!view.metrics.length) {
        return;
      }
      if (!view.xFields.length) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
      }
      // if (view.dataSource.queryObject && view.dataSource.queryObject.pageSize) {
      //   queryObject.pageSize = view.dataSource.queryObject.pageSize;
      // }

      queryObject.xFields = view.xFields;
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        // console.log("chart data");
        // console.log(res);
        this.list = res.data.list;
        this.buildChart(view);
      });
    },
    buildChart(view) {
      const result = getChartColorConfig(view, this.isH5);
      this.remindColor = result.remindColor;
      let textStyle = result.textStyle;
      let list = this.list;
      let grid = getGrid(view);
      // 基于准备好的dom，初始化echarts实例
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(this.$refs.chart);

      let option = {
        textStyle: textStyle,
        color: this.remindColor ? this.remindColor : chartColors,
        tooltip: {
          show: true,
        },
        legend: {},
        grid: grid,

        yAxis: {
          splitLine: {
            lineStyle: {
              type: [2, 4],

              dashOffset: 2,
            },
          },
          ...axisNameConfig(view.yAxis),
        },
        xAxis: {
          axisLabel: {},
          data: [],
        },
      };
      dealWithXFieldValue(view.xFields, list, option, "xAxis");

      if (view.xAxis.rotation) {
        option.xAxis.axisLabel.rotate = view.xAxis.rotation;
      } else {
        option.xAxis.axisLabel.rotate = 0;
      }
      if (view.xAxis.step) {
        option.xAxis.axisLabel.interval = 0;
      } else {
        option.xAxis.axisLabel.interval = "auto";
      }

      if (view.yAxis.title) {
        option.yAxis.name = view.yAxis.title;
      } else {
        option.yAxis.name = "";
      }
      let min = view.yAxis.min;
      let max = view.yAxis.max;
      if (test.number(min) && min != "") {
        option.yAxis.min = min;
      } else {
        option.yAxis.min = function () {
          return null;
        };
      }
      if (test.number(max) && max != "") {
        option.yAxis.max = max;
      } else {
        option.yAxis.max = function () {
          return null;
        };
      }
      let series = [];
      let lengedData = [];

      if (view.xFields.length == 1) {
        //当为百分比堆积图时，需计算每个维度值的相加最大值
        let maxNums = [];
        if (view.columnarType == "percentageStacking") {
          list.forEach((row) => {
            let value = 0;
            view.metrics.forEach((val) => {
              value += row[val.name];
            });
            maxNums.push(value);
          });
        }

        view.metrics.forEach((item, index) => {
          if (lengedData.findIndex((v) => v === item.title) == -1) {
            lengedData.push(item.title);
          }
          let data = [];
          let offset = 0;
          list.forEach((row, lIndex) => {
            let value = row[item.name];
            let show = view.chartLabel.enable;
            if (typeof value === "undefined") {
              show = false;
            }

            if (
              ["percentageStacking", "stacking"].includes(view.columnarType) &&
              !value
            ) {
              show = false;
            }
            let param = {
              value,
              label: {
                show,
                position: "top",
                formatter: () => {
                  if (item.numberFormat) {
                    return row[item.name + "Format"];
                  } else {
                    return value;
                  }
                },
              },
              tooltip: {
                valueFormatter: () => {
                  if (item.numberFormat) {
                    return row[item.name + "Format"];
                  } else {
                    return value;
                  }
                },
              },
            };
            if (view.columnarType == "percentageStacking") {
              option.yAxis.axisLabel = {
                formatter: "{value} %",
              };
              param.value = Number((value / maxNums[lIndex]) * 100).toFixed(2);
              param.label.formatter = param.value + "%";
            }
            data.push(param);
            var newIndex = index;
            if (index > 0) {
              if (value > 0) {
                let prevHeight = row[view.metrics[index - 1].name];
                let currHeight = value;
                let heightDiff = Math.abs(prevHeight - currHeight);
                // 根据柱子高度差异设置不同的标签偏移量
                if (heightDiff < 100) {
                  // 如果相邻柱子高度差异小于 10，设置一个较小的正负偏移量
                  offset = chartOffsetArr[newIndex % chartOffsetArr.length];
                  newIndex = newIndex + 1;
                } else {
                  // 如果相邻柱子高度差异大于等于 5，重置为默认偏移量 [0,0]
                  offset = 0;
                  newIndex = 0;
                }
              }
            }
          });
          // 根据索引获取对应偏移量值
          // let offset = chartOffsetArr[index % chartOffsetArr.length];
          let obj = {
            name: item.title,
            type: "bar",
            data,
            label: {
              offset: [0, offset],
              textStyle: textStyle,
            },
            barMaxWidth: 80,
          };
          //柱形图、条形图显示堆积效果
          if (view.columnarType) {
            obj.label.offset = [0, 0];
            obj.stack = "stack";
          }
          if (item.color) {
            obj.itemStyle = {
              color: item.color,
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }

          series.push(obj);
        });
      } else {
        list.forEach((row) => {
          let legendName = row[view.xFields[1].field.name];
          if (lengedData.findIndex((v) => v === legendName) == -1) {
            lengedData.push(legendName);
          }
        });
        // option.xAxis.data.forEach(item=>{
        //     list.filter(row=>row.)
        // });

        lengedData.forEach((key, index) => {
          let offset = 0;
          if (index > 0) {
            offset = chartOffsetArr[index % chartOffsetArr.length];
          }
          let obj = {
            name: key,
            type: "bar",
            data: [],
            label: {
              offset: [0, offset],
            },
            barMaxWidth: 80,
          };
          let name0 = view.xFields[0].field.name;
          let name = view.xFields[1].field.name;
          let metrics = view.metrics[0];
          let metricsName = view.metrics[0].name;
          let isNumberFormat = Boolean(view.metrics[0].numberFormat);
          if (metrics.color) {
            obj.itemStyle = {
              color: metrics.color,
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }

          option.xAxis.data.forEach((xKey) => {
            let value = 0;
            let show = view.chartLabel.enable;

            let item = list.find(
              (item) => item[name] === key && item[name0] === xKey,
            );
            if (item) {
              value = item[metricsName];
            } else {
              show = false;
            }

            obj.data.push({
              value,
              label: {
                show,
                position: "top",
                textStyle: textStyle,
                formatter: () => {
                  if (isNumberFormat) {
                    return item[metricsName + "Format"];
                  } else {
                    return value;
                  }
                },
              },
              tooltip: {
                valueFormatter: () => {
                  if (isNumberFormat) {
                    return item[metricsName + "Format"];
                  } else {
                    return value;
                  }
                },
              },
            });
          });
          series.push(obj);
        });
      }
      if (view.chartLegend && view.chartLegend.enable) {
        let legend = {
          type: "scroll",
          data: lengedData,
          show: true,
          icon: "circle",
          textStyle: {
            fontSize: chartFontSize,
            lineHeight: chartLineHeight,
            ...textStyle,
          },
          itemWidth: chartItemWidth,
        };
        if (view.chartLegend.position == "top") {
          legend.x = "center";
          legend.y = "top";
        }
        if (view.chartLegend.position == "bottom") {
          legend.x = "center";
          legend.y = "bottom";
        }
        if (
          view.chartLegend.position === "left" ||
          view.chartLegend.position === "right"
        ) {
          legend.x = view.chartLegend.position;
          legend.y = "center";
          legend.orient = "vertical";
          let longestStr = "";
          series.forEach((item) => {
            if (item.name.length > longestStr.length) {
              longestStr = item.name;
            }
          });
          // 标签宽度 + 文字宽度 + 留白宽度
          this.longestStr = 40 + getTextWidth(longestStr, "normal", 3);
        }
        Object.assign(option.legend, legend);
      } else {
        option.legend.show = false;
      }
      option.series = series;
      //设置辅助线
      if (view) {
        setGuidelines(view, option, "yAxis", this.list);
      }
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
      this.setChartResize();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-column {
  height: 100%;
}
</style>
