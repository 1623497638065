<template>
  <div>
    <div class="attr-row">
      <div class="attr-label">字段属性</div>

      <template v-if="component.componentName != 'include_form'">
        <!-- 校验必填 -->
        <div
          v-if="
            component.form && !['sn_input'].includes(component.componentName)
          "
          class="attr-row"
          style="margin: 0px"
        >
          <el-checkbox v-model="component.required"
            ><span style="font-size: 12px">校验必填</span></el-checkbox
          >
        </div>

        <!-- 是否只读 -->
        <div
          v-if="
            component.form &&
            !['sn_input', 'json_form'].includes(component.componentName)
          "
          class="attr-row"
          style="margin: 0px"
        >
          <el-checkbox v-model="component.readonly"
            ><span style="font-size: 12px">只读</span></el-checkbox
          >
        </div>
        <!-- 是否隐藏 -->
        <div
          v-if="
            component.form ||
            component.componentName == 'html' ||
            component.componentName == 'button' ||
            component.componentName == 'summary_data'
          "
          class="attr-row"
          style="margin: 0px"
        >
          <el-checkbox v-model="component.hidden"
            ><span style="font-size: 12px">隐藏</span></el-checkbox
          >
        </div>

        <div v-if="component.componentName === 'position_input'">
          <div class="item">
            <el-select
              v-model="component.limitRange"
              placeholder=""
              size="mini"
              style="width: 100%"
              @change="$forceUpdate()"
            >
              <el-option label="不限制范围" value="none"></el-option>
              <el-option
                label="通过固定地址限制"
                value="limitByFixedAreas"
              ></el-option>
              <el-option
                label="通过其他定位字段限制"
                value="limitByOtherField"
              ></el-option>
            </el-select>
          </div>

          <template>
            <template v-if="component.limitRange === 'limitByFixedAreas'">
              <div class="item" style="margin-bottom: 8px">
                <position-select
                  :edit-poi="editPoi"
                  :visible.sync="positionVisible"
                  @confirm="saveLocation"
                  @alert="alertLocation"
                  @close="positionVisible = false"
                >
                  <el-button
                    size="mini"
                    style="width: 100%"
                    @click="addLocation"
                    >新增定位中心</el-button
                  >
                </position-select>
              </div>
              <div class="item" v-if="component.limitRange !== 'none'">
                <draggable v-model="component.fixedAreas" animation="300">
                  <div
                    v-for="(item, index) in component.fixedAreas"
                    :key="
                      item.title + item.point.lng + item.point.lat + item.radius
                    "
                    class="drag-field-item"
                  >
                    <span class="item-name" :title="item.title">{{
                      item.title
                    }}</span>
                    <div class="item-operate">
                      <i
                        class="iconfont icon-bianji_fill"
                        style="font-size: 15px"
                        @click="editLocation(index)"
                      ></i>
                      <i
                        class="iconfont icon-delete-bin-line"
                        style="font-size: 15px"
                        @click="deleteLocation(index)"
                      ></i>
                    </div>
                  </div>
                </draggable>
              </div>
            </template>

            <div v-if="component.limitRange === 'limitByOtherField'">
              <el-select
                v-model="component.otherFieldName"
                size="mini"
                style="width: 100%"
              >
                <el-option
                  v-for="comp in positionInputComponentsExcludeSelf"
                  :key="comp.name"
                  :label="comp.title"
                  :value="comp.name"
                ></el-option>
              </el-select>
              <div class="field-position-range">
                在定位位置附近
                <el-input
                  v-model="component.otherFieldRadius"
                  type="number"
                  size="mini"
                  style="flex: 1; margin: 0 5px"
                ></el-input>
                米
              </div>
            </div>
          </template>
        </div>
      </template>
      <!-- 不允许重复值 -->
      <div
        v-if="
          [
            'input',
            'select',
            'mobile_input',
            'department_select',
            'user_select',
            'reference_data',
            'cascade_data',
          ].includes(component.componentName)
        "
        class="attr-row"
        style="margin: 0px"
      >
        <el-checkbox v-model="component.uniqueValue"
          ><span style="font-size: 12px">不允许重复值</span></el-checkbox
        >
      </div>
      <!-- 限制上传数量、允许上传方式 -->
      <template
        v-if="
          component.componentName == 'image_uploader' ||
          component.componentName == 'attachment_uploader'
        "
      >
        <div
          class="attr-row"
          style="margin: 0px"
        >
          <el-checkbox v-model="limitFileCount"
            ><span style="font-size: 12px"
              >限制上传1{{
                component.componentName == "image_uploader" ? "张图片" : "份附件"
              }}</span
            >
          </el-checkbox>
        </div>
        <div
          class="attr-row"
          style="margin: 0px"
        >
          <el-checkbox v-model="component.onlyCameraUpload" @change="changeOnlyCameraUpload">
            <span style="font-size: 12px">仅允许拍照上传</span>
          </el-checkbox>
        </div>
        <div
          class="attr-row"
          style="margin: 0px; padding: 4px 0 0 30px;"
        >
          <el-checkbox-group v-model="component.allowCameras" @change="changeAllowCameras">
            <el-checkbox label="SYSTEM">
              <span style="font-size: 12px"
              >系统相机</span>
            </el-checkbox>
            <el-checkbox label="WATERMARK">
              <span style="font-size: 12px"
              >水印相机</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <!-- 限制字数 -->
      <div
        v-if="component.componentName == 'input'"
        class="attr-row"
        style="margin: 0px"
      >
        <el-checkbox v-model="component.wordNumberLimit.numberLimit">
          <span style="font-size: 12px">限定字数</span>
        </el-checkbox>
      </div>

      <div
        v-if="
          component.componentName == 'input' &&
          component?.wordNumberLimit?.numberLimit
        "
      >
        <br />
        <el-input-number
          :value="minValue"
          placeholder="最少"
          controls-position="right"
          size="mini"
          :min="1"
          @change="changeMinValue"
          style="width: 110px"
        ></el-input-number>
        <span style="padding: 0 5px">~</span>

        <el-input-number
          :value="maxValue"
          placeholder="最多"
          controls-position="right"
          size="mini"
          :min="minValue"
          @change="changeMaxValue"
          style="width: 110px"
        ></el-input-number>
      </div>
    </div>

    <!-- 限定输入格式 -->
    <div class="attr-row" v-if="component.componentName == 'input'">
      <div class="attr-label">限定输入格式</div>
      <div>
        <el-select
          v-model="validatorType"
          placeholder=""
          size="mini"
          style="width: 100%; margin-bottom: 5px"
        >
          <el-option
            v-for="item in validatorTypeList"
            :label="item.remark"
            :value="item.name"
            :key="item.name"
          ></el-option>
        </el-select>
      </div>
      <!-- 自定义正则格式 -->
      <div v-if="validatorType == 'custom'">
        <el-input
          v-model="component.regex"
          placeholder=""
          size="mini"
        ></el-input>
      </div>
    </div>

    <!-- 错误时提示 -->
    <div class="attr-row" v-if="component.componentName == 'input'">
      <div class="attr-label">错误时提示</div>
      <div>
        <el-input
          v-model="component.tips"
          placeholder=""
          size="mini"
        ></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import { getValidatorType } from "../api";
import { getFormComponents } from "../utils";
import { componentProps } from "./util";
import PositionSelect from "@/views/lowCode/form/component/PositionSelect";
import Draggable from "vuedraggable";
export default {
  name: "attr-attribute",
  components: { PositionSelect, Draggable },
  props: componentProps,
  inject: ["getComponents"],
  created() {
    // 兼容旧数据
    if (!this.component.limitRange) {
      this.component.limitRange = "none";
    }
    // 兼容单行文本旧数据(新增格式属性)
    if (this.component.componentName == "input") {
      if (!this.component.wordNumberLimit) {
        let wordNumberLimit = {
          numberLimit: false,
          minimum: 1,
          maximum: undefined,
        };
        this.$set(this.component, "wordNumberLimit", wordNumberLimit);
        this.$set(this.component, "validatorType", "custom");
        this.$set(this.component, "regex", "");
        this.$set(this.component, "tips", "格式错误");
      }
    }
    // 兼容图片、附件就数据(新增格式属性)
    if( this.component.componentName == "image_uploader" || 
      this.component.componentName == "attachment_uploader") {
      if(typeof this.component.onlyCameraUpload === "undefined") {
        this.$set(this.component, "onlyCameraUpload", false);
        this.$set(this.component, "allowCameras", []);
      }
    }

    if (this.component?.wordNumberLimit?.numberLimit) {
      this.minValue = this.component.wordNumberLimit.minimum;
      this.maxValue = this.component.wordNumberLimit.maximum;
    }
    getValidatorType().then((res) => {
      this.validatorTypeList = res?.data?.validatorType;
    });
  },
  data() {
    return {
      editPoi: null,
      positionVisible: false,
      validatorTypeList: [], // 表单字段限定格式集合
      maxValue: undefined,
      minValue: undefined,
    };
  },
  computed: {
    isJsonForm() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return false;
      }
      let r =
        components
          .filter((item) => item.componentName == "json_form")
          .findIndex(
            (item) =>
              item.components.findIndex(
                (child) => child.key == this.component.key,
              ) >= 0,
          ) >= 0;

      return r;
    },
    limitFileCount: {
      get() {
        if (this.component.limitFileCount == 1) {
          return true;
        }

        return false;
      },
      set(val) {
        if (val) {
          this.component.limitFileCount = 1;
        } else {
          this.component.limitFileCount = 0;
        }
      },
    },
    // 获取除了自身的定位组件
    positionInputComponentsExcludeSelf() {
      if (this.component.componentName === "position_input") {
        return this.getComponents().filter((comp) => {
          return (
            comp.componentName === "position_input" &&
            comp.name !== this.component.name
          );
        });
      }
    },
    // 输入格式
    validatorType: {
      get() {
        if (this.component.validatorType) {
          return this.component.validatorType;
        }
        return "custom";
      },
      set(val) {
        if (this.validatorTypeList.length) {
          let tips = this.validatorTypeList
            .filter((item) => item.name == val)
            .map((item) => item.tips);
          // 设置错误提示文案
          this.$set(this.component, "tips", tips[0]);
        }
        this.$set(this.component, "validatorType", val);
        if (val == "custom") {
          this.$set(this.component, "regex", "");
        } else {
          this.$set(this.component, "regex", "");
        }
      },
    },
  },
  methods: {
    changeMinValue(e) {
      if (this.maxValue != undefined && this.maxValue < e) {
        this.$message.warning("最大值应该要大于最小值");
        this.$nextTick(() => {
          this.minValue = undefined;
        });

        return;
      }
      this.minValue = e;
      this.$set(this.component.wordNumberLimit, "minimum", e);
    },
    changeMaxValue(e) {
      if (this.minValue != undefined && e < this.minValue) {
        this.$message.warning("最大值应该要大于最小值");
        this.$nextTick(() => {
          this.maxValue = undefined;
        });
        return;
      }
      this.maxValue = e;
      this.$set(this.component.wordNumberLimit, "maximum", e);
    },
    formatAddressName(poi) {
      const { pname, cityname, adname, address, name, district } = poi;
      return district
        ? [district, address, name].filter((item) => item).join("")
        : [pname, cityname, adname, address, name]
            .filter((item) => item)
            .join("");
    },
    alertLocation(param, index) {
      const fixedArea = {
        title: this.formatAddressName(param.poi),
        point: { lng: param.poi.location.lng, lat: param.poi.location.lat },
        radius: param.radius,
      };
      this.component.fixedAreas.splice(index, 1, fixedArea);
      this.positionVisible = false;
    },
    saveLocation(param) {
      const fixedArea = {
        title: this.formatAddressName(param.poi),
        point: { lng: param.poi.location.lng, lat: param.poi.location.lat },
        radius: param.radius,
      };
      // 兼容旧数据
      if (!this.component.fixedAreas) {
        this.component.fixedAreas = [];
      }
      this.component.fixedAreas.push(fixedArea);
      this.positionVisible = false;
    },
    addLocation() {
      this.editPoi = null;
      this.positionVisible = true;
    },
    editLocation(index) {
      this.editPoi = this.component.fixedAreas[index];
      this.editPoi.index = index;
      this.positionVisible = true;
    },
    deleteLocation(index) {
      this.component.fixedAreas.splice(index, 1);
    },
    changeOnlyCameraUpload(val){
      if (val) {
        this.$set(this.component, "allowCameras", ["SYSTEM", "WATERMARK"]);
      } else {
        this.$set(this.component, "allowCameras", []);
      }
    },
    changeAllowCameras(val){
      if(val.length == 0) {
        this.$set(this.component, "onlyCameraUpload", false);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.drag-field-item {
  line-height: 1;
  display: flex;
  justify-content: space-between;
  margin: 0 0 13px 8px;
  font-size: 12px;

  .item-name {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }

  .item-operate {
    color: #a5adba;
    user-select: none;
    i {
      &:hover {
        color: var(--blue-6);
      }
    }
  }
}

.field-position-range {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
</style>
