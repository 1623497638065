import { render, staticRenderFns } from "./attr-include-form.vue?vue&type=template&id=47be69ff&scoped=true"
import script from "./attr-include-form.vue?vue&type=script&lang=js"
export * from "./attr-include-form.vue?vue&type=script&lang=js"
import style0 from "./attr-include-form.vue?vue&type=style&index=0&id=47be69ff&prod&lang=scss&scoped=true"
import style1 from "./attr-include-form.vue?vue&type=style&index=1&id=47be69ff&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47be69ff",
  null
  
)

export default component.exports