<template>
    <el-popover
      placement="bottom-start"
      :width="width"
      @show="open"
      v-model="visible"
    >
      <div ref="reference" slot="reference" class="input-select">
        <div
          class="select-text"
          v-text="deptList.map((item) => item.name).join(',')"
        ></div>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div v-clickoutside="closeVisible" class="body-scroll">
        <template v-if="multi">
          <div class="dept-item">
            <el-checkbox
              :value="valueContainsCurrentDept"
              @change="changeDept('valueContainsCurrentDept', $event)"
              style="width: 100%"
              >{{ currentDept }}
            </el-checkbox>
          </div>
          <div class="dept-item">
            <el-checkbox
              :value="valueContainsCurrentDeptAndSub"
              @change="changeDept('valueContainsCurrentDeptAndSub', $event)"
              style="width: 100%"
              >{{ currentDeptAndSub }}
            </el-checkbox>
          </div>
        </template>
        <template v-else>
          <div
            @click="
              changeDept('valueContainsCurrentDept', !valueContainsCurrentDept)
            "
            class="dept-item"
          >
            {{ currentDept }}
          </div>
          <div
            @click="
              changeDept(
                'valueContainsCurrentDeptAndSub',
                !valueContainsCurrentDeptAndSub
              )
            "
            class="dept-item"
          >
            {{ currentDeptAndSub }}
          </div>
        </template>
        <el-tree
          :data="deptTree"
          :props="defaultProps2"
          :highlight-current="true"
          :expand-on-click-node="false"
          node-key="id"
          class="my-company-tree"
          ref="myTree"
          default-expand-all
          @node-click="handleNodeClick"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span v-if="data.type == 10">{{ node.label }}</span>
            <template v-else>
              <el-checkbox
                :value="hasCheck(data.id, node.label)"
                :label="true"
                v-if="multi"
                @click.native="clickChk($event, data)"
                >{{ node.label }}
              </el-checkbox>
              <span v-else>{{ node.label }}</span>
            </template>
          </div>
        </el-tree>
      </div>
    </el-popover>
  </template>
  <script>
  import Clickoutside from "element-ui/src/utils/clickoutside";
  import { departmentTree } from "@/api/department";
  import { listDepartmentInfo } from "@/views/lowCode/api";
  
  export default {
    directives: { Clickoutside },
    props: {
      value: [String, Array],
      componentName: String,
      oper: String,
      valueContainsCurrentDept: {
        typeof: Boolean,
        default() {
          return false;
        },
      },
      valueContainsCurrentDeptAndSub: {
        typeof: Boolean,
        default() {
          return false;
        },
      },
    },
    inject: {
      isInWorkFlowConfig: {
        default: false,
      },
    },
    computed: {
      currentDept() {
        return this.isInWorkFlowConfig ? "流程发起人所在部门" : "当前部门";
      },
      currentDeptAndSub() {
        return this.isInWorkFlowConfig
          ? "流程发起人所在部门及下属部门"
          : "当前部门及下属部门";
      },
      multi() {
        return (
          this.componentName == "department_list_select" ||
          ["in", "nin"].includes(this.oper)
        );
      },
    },
    watch: {
      oper(val, old) {
        if (this.multi) {
          this.$emit("input", []);
        } else {
          this.$emit("input");
        }
        this.$emit("update:valueContainsCurrentDept", false);
        this.$emit("update:valueContainsCurrentDeptAndSub", false);
        this.deptList = [];
      },
    },
    data() {
      return {
        visible: false,
        deptTree: [],
        width: "",
        deptList: [],
        defaultProps2: {
          children: "children",
          label: "name",
          isLeaf: function (data, node) {
            return !data.hasChild;
          },
        },
      };
    },
    mounted() {
      this.$nextTick(() => {
        let width = this.$refs.reference.clientWidth;
        this.width = width;
      });
    },
    created() {
      this.deptList = [];
      if (this.valueContainsCurrentDept) {
        this.deptList.push({
          id: "valueContainsCurrentDept",
          name: `${this.currentDept}`,
        });
      }
      if (this.valueContainsCurrentDeptAndSub) {
        this.deptList.push({
          id: "valueContainsCurrentDeptAndSub",
          name: `${this.currentDeptAndSub}`,
        });
      }
      if (!this._.isEmpty(this.value)) {
        let departmentIds = [];
        if (this.value instanceof Array) {
          departmentIds = this.value;
        } else {
          departmentIds.push(this.value);
        }
        //过滤非0的部门
        departmentIds = departmentIds.filter((departmentId) => {
          return departmentId && departmentId != 0;
        });
        listDepartmentInfo({ departmentIds }).then((res) => {
          if (res.data && res.data.list && res.data.list.length) {
            let list = res.data.list;
            list.forEach((item) => {
              item.id = item.departmentId;
            });
            this.deptList = this.deptList.concat(list);
          }
        });
      }
    },
    methods: {
      changeDept(id, val) {
        if (this.multi) {
          this.$emit(`update:${id}`, val);
          if (val) {
            if (id == "valueContainsCurrentDept") {
              this.deptList.push({ id, name: `${this.currentDept}` });
            } else {
              this.deptList.push({ id, name: `${this.currentDeptAndSub}` });
            }
          } else {
            this.deptList = this.deptList.filter((item) => item.id != id);
          }
        } else {
          this.$emit("input");
          if (val) {
            if (id == "valueContainsCurrentDept") {
              this.deptList = [{ id, name: `${this.currentDept}` }];
              this.$emit("update:valueContainsCurrentDeptAndSub", false);
            } else {
              this.deptList = [{ id, name: `${this.currentDeptAndSub}` }];
              this.$emit("update:valueContainsCurrentDept", false);
            }
          } else {
            this.deptList = [];
          }
  
          this.$emit(`update:${id}`, val);
        }
      },
      clickChk(event, data) {
        event.preventDefault();
        event.stopPropagation();
        this.handleNodeClick(data);
      },
  
      closeVisible() {
        this.visible = false;
      },
      handleNodeClick(data) {
        if (data.type == 20) {
          let { name, extAttr, id } = data;
          if (this.multi) {
            let list = this._.cloneDeep(this.value);
            if (!list) {
              list = [];
            }
            if (this.value.includes(id)) {
              list = list.filter((val) => val != id);
              this.deptList = this.deptList.filter((item) => item.id != id);
            } else {
              list.push(id);
              this.deptList.push({ id, name });
            }
            this.$emit("input", list);
          } else {
            this.$emit("update:valueContainsCurrentDept", false);
            this.$emit("update:valueContainsCurrentDeptAndSub", false);
            if (this.value == id) {
              this.$emit("input");
              this.deptList = [];
            } else {
              this.$emit("input", id);
              this.deptList = [{ id, name }];
            }
          }
        }
      },
      hasCheck(val, label) {
        if (this.multi) {
          if (this._.isEmpty(this.value)) {
            return false;
          }
          return this.value.includes(val);
        } else {
          return val == this.value;
        }
      },
      open() {
        if (this.deptTree.length) {
          return;
        }
        departmentTree().then((resp) => {
          this.deptTree = resp.data.list;
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .input-select {
    border: 1px solid #dcdfe6;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 10px;
  
    .select-text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
  .body-scroll {
    max-height: 320px;
    overflow: auto;
  }
  
  .dept-item {
    height: 26px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    width: max-content;
    &:hover {
      background-color: #f5f7fa;
    }
  }
  </style>
  