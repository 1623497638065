<template>
  <div :style="`${componentWidth}`">
    <div
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      v-if="data.componentName == 'html'"
      style="position: relative; width: max-content; padding: 20px"
      :style="realWidth"
    >
      <div v-html="data.description"></div>
      <div class="action-item" v-show="actionVisible">
        <div class="a-round">
          <i
            @click="copyItem"
            @mousedown.stop=""
            class="iconfont icon-file-copy-2-fill copy-fill"
            title="复制"
          ></i>
        </div>
        <div class="a-round">
          <i
            @click="removeItem"
            @mousedown.stop=""
            title="删除"
            class="iconfont icon-shanchu_fill shanchu_fill"
          ></i>
        </div>
      </div>
    </div>

    <el-form-item
      v-else
      @mouseenter.native="mouseenter"
      @mouseleave.native="mouseleave"
      :required="!!data.required"
      :class="{
        'un-label':
          !data.form &&
          data.componentName != 'form_data' &&
          data.componentName != 'summary_data',
        'divider-label': data.componentName == 'divider',
        'fix-top': fixTop,
      }"
      :style="`${realWidth}`"
    >
      <span v-if="data.componentName != 'divider'" slot="label">
        {{
          (data.form ||
            data.componentName == "form_data" ||
            data.componentName == "button" ||
            data.componentName == "summary_data") &&
          !data.hiddenTitle
            ? label
            : ""
        }}
        <!-- <svg-icon icon-class="eye" /> -->
        <i
          v-show="data.hidden"
          title="隐藏"
          class="iconfont icon-eye-off-fill"
          style="
            color: #a5adba;
            font-weight: normal !important;
            padding-right: 12px;
          "
        ></i>
      </span>

      <!--  v-show="actionVisible && !(data.extParam.disableEdit)" -->
      <div
        class="action-item"
        v-show="actionVisible && !data?.extParam?.disableEdit"
      >
        <div v-if="data.extParam && !data.extParam.disableCopy" class="a-round">
          <i
            @click="copyItem"
            @mousedown.stop=""
            class="iconfont icon-file-copy-2-fill copy-fill"
            title="复制"
          ></i>
        </div>
        <div
          class="a-round"
          v-if="data.extParam && !data.extParam.disableDelete"
        >
          <i
            @click="removeItem"
            @mousedown.stop=""
            title="删除"
            class="iconfont icon-shanchu_fill shanchu_fill"
          ></i>
        </div>
      </div>
      <!-- <div class="action-item__hidden" v-show="data.hidden">
      <svg-icon icon-class="eye" />
      <i
      title="隐藏"
      class="iconfont icon-eye-off-fill"
      style="color:#A5ADBA;"
    ></i>
    </div> -->

      <el-input
        v-if="
          data.componentName == 'input' ||
          data.componentName == 'input_number' ||
          data.componentName == 'mobile_input'
        "
        :placeholder="data.placeHolder"
        readonly
      >
      </el-input>
      <el-input
        v-else-if="data.componentName == 'text_area'"
        type="textarea"
        :placeholder="data.placeHolder"
        readonly
      ></el-input>
      <htmlInput
        v-else-if="data.componentName == 'html_input'"
        disabled
      ></htmlInput>
      <el-date-picker
        v-else-if="data.componentName == 'date_picker'"
        :type="data.picker"
        :placeholder="data.placeHolder"
        readonly
      ></el-date-picker>
      <el-select
        v-else-if="data.componentName == 'select'"
        :placeholder="data.placeHolder"
        :value="selectValue"
        disabled
        style="width: 100%"
        @click.native="clickItem"
      >
        <template v-if="data.optionSource == 'custom'">
          <el-option
            v-for="(item, index) in data.customOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </template>
      </el-select>
      <el-select
        v-else-if="data.componentName == 'select_checkbox'"
        :placeholder="data.placeHolder"
        :value="selectValue"
        disabled
        style="width: 100%"
        @click.native="clickItem"
        multiple
        filterable
        collapse-tags
      >
        <template v-if="data.optionSource == 'custom'">
          <el-option
            v-for="(item, index) in data.customOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </template>
      </el-select>
      <el-radio-group
        class="custom-radio"
        v-else-if="data.componentName == 'radio_group'"
        disabled
      >
        <template v-if="data.optionSource == 'custom'">
          <el-radio
            v-for="item in data.customOptions"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}
          </el-radio>
        </template>
      </el-radio-group>
      <el-checkbox-group
        v-model="checkboxValue"
        v-else-if="data.componentName == 'checkbox_group'"
        disabled
        class="custom-checkbox"
      >
        <template v-if="data.optionSource == 'custom'">
          <el-checkbox
            v-for="item in data.customOptions"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}
          </el-checkbox>
        </template>
      </el-checkbox-group>
      <my-divider
        v-else-if="data.componentName == 'divider'"
        :hidden-title="data.hiddenTitle"
        :line-type="data.lineType"
        :title-align="data.titleAlign"
        :dashed="data.dashed"
        :line-color="data.lineColor"
        :description="data.description"
        >{{ data.title }}
      </my-divider>

      <el-button
        v-else-if="
          data.componentName == 'attachment_uploader' ||
          data.componentName == 'image_uploader'
        "
        type="primary"
        size="mini"
        >{{
          data.componentName == "attachment_uploader" ? "上传附件" : "上传图片"
        }}
      </el-button>

      <div v-else-if="data.componentName == 'json_form'" class="json-form">
        <div class="grid" style="width: 50px">
          <div class="th"></div>
          <div class="td" style="text-align: center">1</div>
        </div>

        <el-scrollbar ref="scrollDiv" style="flex: 1; width: 0">
          <div class="grid-flex">
            <draggable
              :list="data.components"
              :group="colGroup2"
              animation="300"
              class="grid-drag"
              name="json_form"
              filter=".row-empty"
              @add="onAdd"
            >
              <sub-component
                v-for="(itemChild, childIndex) in data.components"
                :key="itemChild.key"
                :index="childIndex"
                :data="itemChild"
                :dataMode="data?.dataMode"
                class="grid"
                :class="{
                  'is-checked': currentKey == itemChild.key,
                }"
                @removeItem="removeSubcomponent"
                @copyItem="copySubcomponent"
                @click.native="checkComponent(childIndex, $event)"
              ></sub-component>
              <div
                v-if="data?.dataMode != 'reference_form'"
                class="row-empty"
                :class="{
                  'row-half': data.components && data.components.length > 0,
                }"
              >
                从左侧拖拽来添加字段
              </div>
            </draggable>
          </div>
        </el-scrollbar>
      </div>
      <sign
        v-else-if="
          data.componentName == 'sign_input' ||
          data.componentName == 'sign_list_input'
        "
      ></sign>
      <my-address
        v-else-if="data.componentName == 'address_input'"
        :area-tree="areaTree"
        :picker="data.picker"
        disabled
      ></my-address>

      <!-- 关联查询 -->
      <div v-else-if="data.componentName == 'form_data'">
        <el-table :data="[]">
          <template v-for="(col, index) in data.columns">
            <el-table-column
              v-if="col.field.componentName != 'reference_data'"
              :align="col.align"
              :key="index"
              :label="col.title"
              :width="
                col.components && col.components.length && col.width
                  ? col.width
                  : ''
              "
            >
              <template
                v-if="
                  col.field.componentName == 'json_form' &&
                  col.subColumns &&
                  col.subColumns.length
                "
              >
                <el-table-column
                  v-for="(subCol, sIndex) in col.subColumns"
                  :key="sIndex"
                  :align="subCol.align"
                  :label="subCol.title"
                  :width="subCol.width ? subCol.width : ''"
                >
                </el-table-column>
              </template>
            </el-table-column>
            <template v-else>
              <el-table-column
                v-for="sub in col.subColumns"
                :align="sub.align"
                :key="col.field.name + sub.field.name"
                :label="sub.title"
              >
                <template
                  v-if="
                    sub.field.componentName == 'json_form' &&
                    sub.field.component.components &&
                    sub.field.component.components.length
                  "
                >
                  <template
                    v-for="(subCol, sIndex) in sub.field.component.components"
                  >
                    <el-table-column
                      v-if="subCol.componentName != 'reference_data'"
                      :key="subCol.key + '_' + sIndex"
                      :align="subCol.align"
                      :label="subCol.title"
                      :width="subCol.width ? subCol.width : ''"
                    >
                    </el-table-column>
                    <template v-else>
                      <el-table-column
                        v-for="(child, childIndex) in subCol.columns"
                        :key="child.field.name + '_' + childIndex"
                        :align="child.align"
                        :label="child.title"
                      >
                      </el-table-column>
                    </template>
                  </template>
                </template>
              </el-table-column>
            </template>
          </template>
        </el-table>
      </div>

      <div v-else-if="data.componentName == 'sn_input'">
        <el-input placeholder="自动生成无需填写" disabled readonly></el-input>
      </div>
      <div
        class="outer-selected-user__empty-wrap"
        v-else-if="data.componentName == 'user_select'"
        style="height: 36px"
      >
        选择成员
      </div>
      <div
        v-else-if="data.componentName == 'user_list_select'"
        class="outer-selected-user__empty-wrap"
        style="height: 36px"
      >
        选择成员
      </div>
      <div
        class="outer-selected-user__empty-wrap"
        v-else-if="data.componentName == 'department_select'"
        style="height: 36px"
      >
        选择部门
      </div>
      <div
        v-else-if="data.componentName == 'department_list_select'"
        class="outer-selected-user__empty-wrap"
        style="height: 36px"
      >
        选择部门
      </div>
      <div
        v-else-if="data.componentName == 'position_input'"
        class="fui_location"
      >
        <i class="el-icon-location-information"></i>
        <span>获取定位</span>
      </div>
      <div
        v-else-if="data.componentName == 'include_form'"
        class="include_form"
      >
        嵌入表单区域
      </div>
      <el-cascader
        :options="[]"
        style="width: 100%"
        v-else-if="data.componentName == 'parent_data'"
        disabled
      ></el-cascader>

      <div v-else-if="data.componentName == 'button'" class="button">
        <el-button
          size="medium"
          :style="{
            background: data.buttonStyle.color,
            color: '#fff',
            fontSize: '12px',
          }"
        >
          {{ data.buttonText }}
        </el-button>
      </div>

      <div v-else-if="data.componentName == 'summary_data'">
        <el-input placeholder="汇总数据" disabled readonly></el-input>
      </div>

      <el-select
        v-else-if="data.componentName == 'cascade_data'"
        :placeholder="data.placeHolder"
        :value="selectValue"
        disabled
        style="width: 100%"
        @click.native="clickItem"
      >
        <el-option
          v-for="(item, index) in []"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <!-- 删除组件 -->
      <el-dialog
        :visible.sync="delComponentVisible"
        width="560px"
        append-to-body
      >
        <div
          slot="title"
          style="display: flex; align-items: center; font-size: 18px"
        >
          <i
            class="el-icon-warning"
            style="color: #e6a23c; font-size: 22px; margin-right: 6px"
          ></i
          >确定删除该子表单字段及对应表单数据？
        </div>
        <div style="padding: 20px">
          该字段被引用，确定删除该子表单字段及对应表单数据？
        </div>
        <div slot="footer" class="dialog-footer">
          <el-link type="primary" :underline="false" @click="toReferenceDetail"
            >查看引用详情</el-link
          >
          <div>
            <el-button @click="delComponentVisible = false">取 消</el-button>
            <el-button type="primary" @click="finalRomoveSubcomponent"
              >确 定</el-button
            >
          </div>
        </div>
      </el-dialog>

      <!-- 查看引用详情 -->
      <referenceDetail
        v-if="referenceVisible"
        :visible.sync="referenceVisible"
        :formId="formId"
        :referenceTitle="currNodeTitle"
        :source="'formField'"
        :fieldKey="currNodeKey"
      ></referenceDetail>
    </el-form-item>
  </div>
</template>
<script>
import { referenceRemoveCheck } from "./api";
import SubComponent from "./component/SubComponent";
import Draggable from "vuedraggable";
import MyAddress from "@/components/address";
import sign from "./component/sign";
import htmlInput from "@/views/lowCode/form/component/html-input";
import referenceDetail from "./reference-detail";
export default {
  name: "componentItem",
  components: {
    SubComponent,
    Draggable,
    MyAddress,
    sign,
    htmlInput,
    referenceDetail,
  },
  props: {
    index: Number,
    data: Object,
    isDrag: Boolean,
    areaTree: Array,
    currentKey: String,
    width: String,
    put: {
      type: Boolean,
      default() {
        return true;
      },
    },
    formId: {
      type: String,
      default: "",
    },
  },
  computed: {
    componentWidth() {
      if (this.data.displayBlock) {
        return `width:100%;`;
      }
      return this.width;
    },
    realWidth() {
      if (this.data.displayBlock) {
        return this.width;
      }
      return "width:100%;";
    },
    label() {
      if (this.data.title) {
        return this.data.title;
      }
      return this.data.label;
    },
    colGroup2() {
      if (this.data?.dataMode == "reference_form") {
        return {
          name: "column",
          pull: false,
          put: false,
        };
      } else {
        return {
          name: "column",
          pull: false,
          put: this.put,
        };
      }
    },
    fixTop() {
      let isMultiFormData =
        this.data.componentName == "form_data" &&
        this.data.queryObject.pageSize > 1;
      return (
        ["divider", "json_form"].includes(this.data.componentName) ||
        isMultiFormData
      );
    },
  },
  data() {
    return {
      selectValue: "",
      checkboxValue: [],
      actionVisible: false,
      delComponentVisible: false,
      referenceVisible: false,
      currNodeKey: "",
      currNodeTitle: "",
      currNodeIndex: 0, // 当前子表单下组件索引
    };
  },

  methods: {
    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();

      if (!this.isDrag) {
        this.actionVisible = true;
      }
      if (this.$refs.scrollDiv) {
        this.$nextTick(() => {
          //解决滚动条初始化消失的问题
          this.$refs.scrollDiv.update();
        });
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = false;
      }
    },

    clickItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("changeCurrNode", this.data);
    },
    checkComponent(childIndex, event) {
      if (this.data?.dataMode != "reference_form") {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.$nextTick(() => {
          this.$emit("changeCurrNode", this.data.components[childIndex], event);
        });
      }
    },
    async removeSubcomponent(index) {
      let parentNode = this.$parent.list[this.index];
      this.currNodeKey = parentNode.key + "." + this.data.components[index].key;
      this.currNodeTitle = this.data.components[index].title;
      let params = {
        collection: this.formId,
        type: "form",
        fieldKey: this.currNodeKey,
      };
      let checkResult = await referenceRemoveCheck(params);
      if (checkResult?.data?.beReferred) {
        // 有引用关系
        this.currNodeIndex = index;
        this.delComponentVisible = true;
      } else {
        this.$confirm("确定删除该子表单字段及对应表单数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.data.components.splice(index, 1);
            this.$emit("removeItem", -1);
          })
          .catch(() => {});
      }
    },
    // 存在引用关系时移除
    finalRomoveSubcomponent() {
      this.delComponentVisible = false;
      this.data.components.splice(this.currNodeIndex, 1);
      this.$emit("removeItem", -1);
    },
    // 查看引用详情
    toReferenceDetail() {
      this.referenceVisible = true;
    },
    copySubcomponent(index) {
      let obj = this._.cloneDeep(this.data.components[index]);
      delete obj.name;
      delete obj.key;
      let uuid = this.getUuid();
      obj.key = uuid;
      if (obj.form) {
        obj.name = uuid;
      }
      this.data.components.splice(index + 1, 0, obj);
      this.checkComponent(index + 1);
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
    onAdd(e) {
      this.$nextTick(() => {
        let index = e.newIndex;

        if (!this.data.components[index]) {
          index = this.data.components.findIndex((item) => !item.key);
        }

        if (index >= 0 && !this.data.components[index].key) {
          let uuid = this.getUuid();
          this.$set(this.data.components[index], "key", uuid);
          if (this.data.components[index].form) {
            this.$set(this.data.components[index], "name", uuid);
          }
        }
        this.checkComponent(index);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-checkbox,
.custom-radio {
  text-align: start;
  ::v-deep {
    .el-checkbox,
    .el-radio {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
}

.action-item {
  position: absolute;
  right: 0px;
  top: -16px;
  right: 10px;
  font-size: 16px;
  z-index: 2;
  height: 20px;
  display: flex;
  cursor: default;
  // box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  // padding: 2px 4px;
  // border-radius: 4px;
  .a-round {
    margin: 3px;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
    .iconfont {
      line-height: 24px;
      cursor: pointer;
      color: #a5adba;
      margin: auto;
    }
  }
  .copy-fill:hover {
    color: #3870ea;
  }
  .shanchu_fill:hover {
    color: #d14343;
  }
}
.action-item__hidden {
  position: absolute;
  left: 78px;
  top: 12px;
  font-size: 16px;
  z-index: 2;
  // height: 20px;
  display: flex;
  cursor: default;
  // box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  // padding: 2px 4px;
  // border-radius: 4px;
}

.json-form {
  background-color: #ffffff;
  border: solid 1px #f4f5f7;
  display: flex;

  .grid {
    border-right: solid 1px #f4f5f7;

    &.is-checked {
      // border: springgreen dashed 1px;

      background-color: rgba($color: #409eff, $alpha: 0.08);
    }
  }

  ::v-deep {
    .el-scrollbar__view {
      height: 100%;
    }
  }

  .grid-flex {
    width: max-content;
    display: flex;
    height: 100%;
    min-width: 100%;

    &::after {
      content: " ";
      display: block;
      clear: both;
    }

    .left {
      float: left;
    }
  }

  .grid-drag {
    display: flex;
    min-width: 100px;
  }

  .th {
    width: 50px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    border-bottom: solid 1px #f4f5f7;
  }

  .td {
    width: 50px;
    min-height: 60px;
    padding: 5px;
    box-sizing: border-box;
  }

  .row-empty {
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    width: 160px;
    min-width: 160px;
    height: 100%;
    padding-left: 10px;

    &.row-half {
      width: 80px;
      min-width: 80px;
    }
  }
}

.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
}
.fui_location {
  width: 330px;
  height: 32px;
  line-height: 30px;
  border: 1px solid #e4e7ed;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
  transition: background;
  transition-duration: 0.3s;
  background: #fafafa;
  max-width: 100%;
  color: #333;
}
.include_form {
  background: #fafafa;
  height: 32px;
  line-height: 30px;
  padding: 0 10px;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
::v-deep {
  .el-form-item__content .el-input__inner[readonly], .el-textarea__inner[readonly] {
    background-color: #ffffff;
    cursor: default;
    &:hover {
      border-color: var(--zgg-border-base);
    }
    &:focus {
        outline: none;
        border-color: var(--zgg-border-base);
    }
  }
}
</style>
