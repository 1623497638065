import request from '@/utils/request'
import store from '@/store'
import qs from 'qs'

/**
 * 删除api-Key
 */
export function deleteAuthApiKey(data) {
  return request({
    url:  '/oauth/authApiKey/delete',
    method: 'POST',
    data
  })
}

/**
 * 创建api-Key
 */
export function createAuthApiKey(data) {
  return request({
    url:  '/oauth/authApiKey/save',
    method: 'POST',
    data
  })
}

/**
 * 获取api-Key列表
 */
export function getAuthApiKeyList(data) {
  return request({
    url:  '/oauth/authApiKey/paging',
    method: 'POST',
    data
  })
}

/**
 * api-Key启用状态
 */
export function changeAuthApiKeyStatus(data) {
  return request({
    url:  '/oauth/authApiKey/operStatus',
    method: 'POST',
    data
  })
}

/**
 * 设置字段别名
 */
export function saveaAliasField(data) {
  return request({
    url:  '/ui/meta/field/aliasField',
    method: 'POST',
    data
  })
}

/**
 * 获取表单字段别名
 */
export function getFormAlias(data) {
  return request({
    url:  '/ui/meta/table/detail',
    method: 'POST',
    data
  })
}
/**
 * 保存表单字段别名
 */
export function saveFormAlias(data) {
  return request({
    url:  '/ui/meta/table/saveAlias',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  })
}

/**
 * 插件列表
 * @param {*} data 
 * @returns 
 */
export function getPluginList(data) {
  return request({
    url: '/open/plugin/list',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 获取插件详情
 * @param {*}
 * @returns
 */
export function getPluginDetail(data) {
  return request({
    url: '/open/plugin/pluginDetail',
    method: 'post',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 插件安装列表
 * @param {*}
 * @returns
 */
export function getPluginInstallList(data) {
  return request({
    url: '/open/pluginInstall/list',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 安装插件
 * @param {*}
 * @returns
 */
export function pluginInstall(data) {
  return request({
    url: '/open/pluginInstall/save',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 卸载插件
 * @param {*}
 * @returns
 */
export function pluginUnInstall(data) {
  return request({
    url: '/open/pluginInstall/delete',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 保存安装插件通用参数
 * @param {*} pluginId
 * @returns
 */
export function savePluginInstallParam(data) {
  return request({
    url: '/open/pluginInstall/savePluginInstallParam',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 插件通用参数详情
 * @param {*}
 * @returns
 */
export function pluginInstallDetail(data) {
  return request({
    url: '/open/plugin/pluginInstallDetail',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 启用-禁用插件
 * @param {*}
 * @returns
 */
export function pluginInstallSwitchEnable(data) {
  return request({
    url: '/open/pluginInstall/switchEnable',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

/**
 * 插件安装列表排序
 * @param {*}
 * @returns
 */
export function pluginInstallSort(data) {
  return request({
    url: '/open/pluginInstall/sort',
    method: 'POST',
    data: {
      ...data,
      companyId: store.getters.currentCompanyId
    }
  })
}

