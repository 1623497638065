<template>
  <div style="margin: 10px">
    <el-button size="mini" style="width: 100%" @click="openSettingPanel">设置排序</el-button>
    <sortSettingDialog
        :dialog-visible.sync="sortDialogVisible"
        :order-list.sync="ordersList"
        :field-list="columns"
        @change="handleSortChange"
    ></sortSettingDialog>
  </div>
</template>

<script>
import sortSettingDialog from '@/views/lowCode/form/component/sort-setting/sort-setting-dialog'

export default {
  name: 'sort-setting',
  inject: ["getRelationComponentList"],
  components: {
    sortSettingDialog
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    columns: {
      type: [Array],
      default() {
        return []
      }
    }
  },
  data() {
    return {
      sortDialogVisible: false,
      ordersList: []
    }
  },
  methods: {
    openSettingPanel() {
      this.sortDialogVisible = true
      this.ordersList = []
      let componentList = this.getRelationComponentList()
      for (const [fieldName, orderState] of Object.entries(this.value)) {
        let fieldColumn = componentList.find(item=>item.name==fieldName)
        if(fieldColumn){
          let field = fieldColumn.field
          this.ordersList.push({
            orderColumn: fieldName,
            orderState: orderState,
            label: fieldColumn.title,
          })
        }
      }
      // this.columns.map((item) => {
      //   orderMap[item.orderColumn] = item.orderState;
      // });
      // this.$refs.table.columns.map((column) => {
      //   let newState = orderMap[column.property];
      //   if (newState) {
      //     column.multiOrder = newState;
      //     column.order = newState;
      //   } else {
      //     column.multiOrder = "";
      //     column.order = "";
      //   }
      // });
      // this.ordersList = val;
    },
    handleSortChange(val) {
      this.ordersList = val
      for(var key in this.value){
        delete this.value[key];
      }
      val.forEach(field => {
        let { orderColumn, label, orderState } = field
        this.value[orderColumn] = orderState
      })
      console.log(val, this.value)

    }
  }
}
</script>

<style scoped>

</style>
