<template>
  <el-form
    ref="form"
    :disabled="disabled"
    :model="node"
    label-position="top"
    :rules="rules"
  >
    <el-form-item label="Webhook URL">
      <span class="tip">我们为您生成了一个用来接收请求的URL</span>
      <div style="display: flex">
        <el-input
          ref="WebhookUrl"
          v-model="webhookUrl"
          type="datetime"
          disabled
          style="margin-right: 15px"
        ></el-input>
        <el-form>
          <el-button @click="copyUrl">复制链接</el-button>
        </el-form>
      </div>
    </el-form-item>
    <el-form-item label="生成参数列表" v-show="!radio && !isShowList">
      <span class="tip">系统将根据生成的参数列表来抓取请求中的数据</span>
      <el-radio-group v-model="radio" class="custom-radio">
        <el-radio label="first">从请求范例生成</el-radio>
        <span class="tip">请准备一条GET或POST请求</span>
        <el-radio label="second">从JSON数据范例生成</el-radio>
        <el-radio label="third">从key-value pairs生成</el-radio>
      </el-radio-group>
    </el-form-item>
    <template v-if="!isShowList">
      <el-form-item label="从JSON数据范例生成" v-if="radio == 'second'">
        <span class="tip">请提供一个请求数据示例 （JSON格式）</span>
        <el-input type="textarea" :rows="10" v-model="jsonValue"> </el-input>
      </el-form-item>
      <el-form-item label="从请求范例生成" v-if="radio == 'first'">
        <span class="tip">请在3分钟内向URL发送一条GET或POST请求</span>
        <div
          style="background-color: #f2f6fc; padding-left: 20px; color: #3870ea"
        >
          正在接收请求...
        </div>
      </el-form-item>
      <el-form-item label="从key-value pairs生成" v-if="radio == 'third'">
        <div v-for="(item, index) in list" class="list">
          <el-input
            placeholder="key"
            v-model="item.key"
            style="width: 200px; margin-right: 10px"
          ></el-input>
          <el-select
            v-model="item.type"
            style="width: 120px; margin-right: 10px"
          >
            <el-option
              v-for="(value, key) in componentsTypeMap"
              :key="key"
              :label="value"
              :value="key"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="参考value"
            v-model="item.value"
            style="margin-right: 10px"
          ></el-input>
          <el-button
            type="text"
            icon="el-icon-delete"
            @click="handleClick(index)"
          ></el-button>
        </div>
        <el-button type="text" class="el-icon-plus" @click="handleAdd">
          key-value pairs
        </el-button>
      </el-form-item>
    </template>
    <template v-if="isShowList && !radio">
      <el-form-item label="参数列表">
        <span class="tip"
          >系统将根据以下参数列表来抓取请求中的数据。支持将参数设为必填项，此时如果对方的请求中没有附带该参数时，我们将返回错误消息"msg":
          "参数缺少必填项"。</span
        >
        <div class="webhook-param">
          <span>请求 Body</span>
          <el-table :data="node.body" border>
            <el-table-column
              prop="title"
              label="参数名"
              width="200"
            ></el-table-column>
            <el-table-column prop="componentName" label="类型" width="80">
              <template slot-scope="{ row }">
                {{ getTypeName(row) }}
              </template>
            </el-table-column>
            <el-table-column prop="referValue" label="参考值">
              <template slot-scope="{ row }">
                {{ getReferValue(row) }}
              </template>
            </el-table-column>
            <el-table-column label="必填" width="80">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.required"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
          <span>请求 Params</span>
          <el-table :data="node.params" border>
            <el-table-column
              prop="title"
              label="参数名"
              width="200"
            ></el-table-column>
            <el-table-column prop="componentName" label="类型" width="80">
              <template slot-scope="{ row }">
                {{ getTypeName(row) }}
              </template>
            </el-table-column>
            <el-table-column prop="referValue" label="参考值">
              <template slot-scope="{ row }">
                {{ getReferValue(row) }}
              </template>
            </el-table-column>
            <el-table-column label="必填" width="80">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.required"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
          <span>请求 Header</span>
          <el-table :data="node.header" border>
            <el-table-column
              prop="title"
              label="参数名"
              width="200"
            ></el-table-column>
            <el-table-column prop="componentName" label="类型" width="80">
              <template slot-scope="{ row }">
                {{ getTypeName(row) }}
              </template>
            </el-table-column>
            <el-table-column prop="referValue" label="参考值">
              <template slot-scope="{ row }">
                {{ getReferValue(row) }}
              </template>
            </el-table-column>
            <el-table-column label="必填" width="80"> </el-table-column>
          </el-table>
        </div>
      </el-form-item>
    </template>
    <el-button
      plain
      @click="handleEdit"
      v-show="isShowList"
      style="margin-bottom: 10px"
      >修改</el-button
    >
    <template v-if="isShowList && !radio">
      <el-form-item label="响应方式">
        <el-radio-group v-model="responseWay" style="display: inline-block">
          <el-radio label="quick_success">马上返回成功，不携带参数</el-radio>
          <el-radio label="render_fixed_data"
            >马上返回成功，携带固定参数</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="responseWay == 'render_fixed_data'"
        label="响应固定值"
      >
        <el-radio-group
          v-model="responseFixedType"
          style="display: inline-block"
        >
          <el-radio label="key_value">key-value pairs</el-radio>
          <el-radio label="JSON">JSON</el-radio>
        </el-radio-group>
        <template v-if="responseFixedType == 'key_value'">
          <div>
            <div
              v-for="item in responseFixedDataList"
              :key="item.uuid"
              class="response-item"
            >
              <el-input
                placeholder="请输入key"
                size="small"
                v-model="item.key"
                style="width: 180px; margin-right: 10px"
              ></el-input>
              <el-input
                size="small"
                v-model="item.value"
                placeholder="请输入value"
                style="flex: 1"
              ></el-input>
            </div>
          </div>
          <div>
            <el-button @click="addResponse" type="text" icon="el-icon-plus"
              >添加</el-button
            >
          </div>
        </template>
        <div v-else>
          <codemirror
            :options="cmOptions"
            v-model="resultData"
            class="node-result-code"
          ></codemirror>
          <div>示例：{"key":"value","key1":"value1"}</div>
        </div>
      </el-form-item>
    </template>

    <template v-if="!isShowList && radio">
      <el-button plain @click="handleBack">返回</el-button>
      <!--        <div style="margin-left: 10px;display: inline-block" v-if="radio == 'first'">-->
      <!--          <el-checkbox v-model="isShowAllBodyParams">生成Body全文参数</el-checkbox>-->
      <!--          <el-tooltip content="勾选后，将会生成一个记录Body全文的文本格式参数" placement="top">-->
      <!--            <i class="el-icon-question"></i>-->
      <!--          </el-tooltip>-->
      <!--        </div>-->
      <el-button plain @click="handleNext" v-show="radio != 'first'"
        >下一步</el-button
      >
    </template>
  </el-form>
</template>
<script>
import axios from "axios";
import { getWebhookDetail } from "./api";
import { isEmpty } from "lodash";
import { uuid } from "@/zgg-core/utils";
import jsonlint from "jsonlint";
import "codemirror/theme/monokai.css";
import "codemirror/theme/idea.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/json-lint";
import "codemirror/addon/lint/lint.css";

// 代码高亮
import "codemirror/addon/selection/active-line.js";

// 折叠
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
window.jsonlint = jsonlint;
export default {
  name: "rpa-webhook",
  props: {
    node: Object,
    disabled: Boolean,
  },
  computed: {
    webhookUrl: {
      get() {
        return this.node.webhookUrl;
      },
      set(val) {
        this.$set(this.node, "webhookUrl", val);
      },
    },
    responseWay: {
      get() {
        let responseWay = this.node.responseWay ?? "quick_success";
        return responseWay;
      },
      set(val) {
        this.$set(this.node, "responseWay", val);
        this.$set(this.node, "responseFixedType", "key_value");
        this.$set(this.node, "responseFixedData", "");
      },
    },
    responseFixedType: {
      get() {
        let responseFixedType = this.node.responseFixedType ?? "key_value";
        return responseFixedType;
      },
      set(val) {
        if (val == "key_value") {
          if (!isEmpty(this.resultData)) {
            try {
              let json = JSON.parse(this.resultData);
              let list = [];
              for (const [key, value] of Object.entries(json)) {
                list.push({
                  key,
                  value,
                  uuid: uuid(),
                });
              }
              this.responseFixedDataList = list;
              this.resultData = "";
            } catch (error) {
              this.$message.error("json格式错误");
              return;
            }
          }
        } else {
          if (this.responseFixedDataList.length) {
            let json = {};
            this.responseFixedDataList.forEach((item) => {
              json[item.key] = item.value;
            });
            this.resultData = JSON.stringify(json);
          } else {
            this.resultData = "";
          }
          this.responseFixedDataList = [];
        }
        this.$set(this.node, "responseFixedType", val);
      },
    },
  },
  inject: {
    getRobotId: {
      type: Function,
      default() {
        return () => "";
      },
    },
  },
  data() {
    return {
      rules: { executeTime: [{ required: true, message: "请选择触发时间" }] },
      radio: "",
      jsonValue: "",
      componentsTypeMap: {
        input: "文本",
        select_checkbox: "数组",
        input_number: "数值",
        date_picker: "日期",
      },
      isShowAllBodyParams: false,
      isShowList: false,
      list: [
        {
          key: "",
          type: "input",
          value: "",
        },
      ],
      responseFixedDataList: [],
      cmOptions: {
        theme: "idea", // 编辑器主题
        readOnly: false,
        autoRefresh: true,
        tabSize: 4, // tabsize默认为4
        styleActiveLine: true,
        lineNumbers: true,
        matchBrackets: true, // 括号匹配
        line: true,
        autoFormatOnLoad: true,
        mode: "application/json", // 选择代码语言
        lineWrapping: true, // 自动换行
        // theme: 'base16-light', // 主题根据需要自行配置

        foldGutter: true,
        lint: true,
        gutters: [
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter",
          "CodeMirror-lint-markers",
        ],
      },
      resultData: "",
    };
  },
  watch: {
    radio: {
      handler(val) {
        if (val == "first") {
          //轮询间隔时间，单位毫秒
          const intervalStep = 3000;
          //累计轮询时间
          let intervalTime = 0;
          this.timer = setInterval(() => {
            getWebhookDetail({
              robotId: this.getRobotId(),
              fetchDataFromRecentRequest: true,
              fetchDataFromThisRequest: false,
            }).then((res) => {
              if (!isEmpty(res.data)) {
                clearInterval(this.timer);
                this.$set(this.node, "body", res.data.body);
                this.$set(this.node, "header", res.data.header);
                this.$set(this.node, "params", res.data.params);
                this.radio = "";
                this.isShowList = true;
              }
              //如果成果，停止轮询
              intervalTime += intervalStep;
              if (intervalTime > 180000) {
                clearInterval(this.timer);
              }
            });
          }, intervalStep);
        } else {
          clearInterval(this.timer);
        }
      },
    },
  },
  created() {
    if (
      [...this.node.header, ...this.node.body, ...this.node.params].length > 0
    ) {
      this.isShowList = true;
    }

    if (!isEmpty(this.node.responseFixedData)) {
      if (this.node.responseFixedType == "key_value") {
        let responseFixedDataList = [];
        for (const [key, value] of Object.entries(
          this.node.responseFixedData,
        )) {
          responseFixedDataList.push({
            uuid: uuid(),
            key,
            value,
          });
        }
        this.responseFixedDataList = responseFixedDataList;
      } else {
        this.resultData = JSON.stringify(this.node.responseFixedData);
      }
    }
  },
  methods: {
    changeResponseData() {},
    /**
     * 添加返回参数的key-value项
     */
    addResponse() {
      this.responseFixedDataList.push({
        uuid: uuid(),
        key: "",
        value: "",
      });
    },
    getReferValue(row) {
      if (row.referValue instanceof Array) {
        return row.referValue.toString();
      }
      return row.referValue;
    },
    getTypeName(row) {
      return this.componentsTypeMap[row.componentName] || "文本";
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.responseWay == "render_fixed_data") {
          // 返回值携带固定参数
          if (this.responseFixedType == "key_value") {
            if (!this.responseFixedDataList.length) {
              this.$message.error("请添加响应固定值");
              return;
            }
            if (this.responseFixedDataList.some((item) => isEmpty(item.key))) {
              this.$message.error("响应固定值的key不能为空");
              return;
            }
            if (
              this.responseFixedDataList.some((item) => isEmpty(item.value))
            ) {
              this.$message.error("响应固定值的value不能为空");
              return;
            }
            let responseFixedData = {};
            this.responseFixedDataList.forEach((item) => {
              responseFixedData[item.key] = item.value;
            });
            this.$set(this.node, "responseFixedData", responseFixedData);
          } else if (this.responseFixedType == "JSON") {
            if (isEmpty(this.resultData)) {
              this.$message.error("请输入JSON值");
              return;
            }
            try {
              let json = JSON.parse(this.resultData);
              if (isEmpty(json)) {
                this.$message.error("JSON对象的key-value值不能为空");
                return;
              }
              if (Object.keys(json).some((val) => isEmpty(val))) {
                this.$message.error("JSON对象的key-value值不能为空");
                return;
              }

              if (Object.values(json).some((val) => isEmpty(val))) {
                this.$message.error("JSON对象的value值不能为空");
                return;
              }

              this.$set(this.node, "responseFixedData", json);
            } catch (error) {
              this.$message.error("请输入JSON格式的响应固定值");
              return;
            }
          }
        }

        callback(valid);
      });
    },
    // 复制链接
    copyUrl() {
      let input = document.createElement("input");
      input.setAttribute("value", this.node.webhookUrl);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("Copy")) {
        this.$message({ type: "success", message: "复制到剪贴板" });
      }
      document.body.removeChild(input);
    },
    handleClick(index) {
      this.list.splice(index, 1);
    },
    handleAdd() {
      this.list.push({
        key: "",
        type: "input",
        value: "",
      });
    },
    async urlRequest(data) {
      await axios.post(this.node.webhookUrl, data).then(async (res) => {
        await getWebhookDetail({
          robotId: this.getRobotId(),
          fetchDataFromRecentRequest: true,
          fetchDataFromThisRequest: false,
        }).then((res) => {
          let body = res.data.body;
          if (this.radio == "third") {
            this.list.forEach((item) => {
              if (item.key) {
                body.push({
                  componentName: item.type,
                  name: `body_${item.key}`,
                  picker: "",
                  referValue: item.value,
                  required: false,
                  title: item.key,
                });
              }
            });
          }
          this.$set(this.node, "body", body);
          this.$set(this.node, "header", res.data.header);
          this.$set(this.node, "params", res.data.params);
          this.radio = "";
          this.isShowList = true;
        });
      });
    },
    handleBack() {
      this.radio = "";
    },
    validateJsonValue(data) {
      try {
        let obj = JSON.parse(this.jsonValue);
        Object.assign(data, obj);
        return true;
      } catch (e) {
        if (this.radio == "second") {
          this.$message.error("验证格式失败，请修改");
        }
        return false;
      }
    },
    validateKeyValue() {
      if (!this.list.some((item) => item.key)) {
        this.$message.error("请配置参数");
        return false;
      }
      return true;
    },
    async handleNext() {
      let data = {};
      if (this.radio == "second") {
        if (!this.validateJsonValue(data)) return;
      } else if (this.radio == "third") {
        if (!this.validateKeyValue()) return;
      }
      await this.urlRequest(data);
    },
    handleEdit() {
      this.radio = "";
      this.isShowList = false;
    },
  },
};
</script>

<style scoped lang="scss">
.tip {
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #757575;
}

.list {
  display: flex;
  margin-bottom: 10px;
}
.webhook-param {
  display: flex;
  flex-direction: column;
}
::v-deep .el-table th {
  background-color: #f5f7fa;
}

.custom-radio {
  display: flex;
  flex-direction: column;
  ::v-deep {
    .el-radio {
      margin-top: 10px;
    }
    .el-radio__label {
      color: #42526e !important;
    }
    .el-radio__inner {
      border-color: #dfe1e6 !important;
      background-color: unset !important;
    }
  }
}
.response-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}

.node-result-code {
  border: solid 1px #dcdfe6;
  ::v-deep {
    .CodeMirror {
      height: 200px;
    }
  }
}
</style>
