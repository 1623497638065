import request from "@/utils/request";
import qs from "qs";
import store from "@/store";

/**
 * 保存表单
 * @param {*} data
 * @returns
 */
export function saveForm(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/ui/form/save",
    method: "post",
    data,
  });
}

/**
 * 获取表单分页列表
 * @param {*} data
 * @returns
 */
export function fetchFormList(data) {
  return request({
    url: "/ui/form/list",
    method: "post",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 获取表单详情
 * @param {*} formId
 * @returns
 */
export function getFormDetail(formId, removeComponent = false, bizExcelId) {
  return request({
    url: "/ui/form/detail",
    method: "post",
    data: qs.stringify({ formId, removeComponent, bizExcelId }),
  });
}

/**
 * 保存组件
 * @returns
 */
export function saveComponent(data) {
  return request({
    url: "/ui/form/saveComponent",
    method: "post",
    data: data,
  });
}

/**
 * 获取表单数据列表
 * @param {*} data
 * @returns
 */
export function fetchFormDataList(data) {
  return request({
    url: "/formdata/list",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 获取数据详情
 * @param {*} data
 * @returns
 */
export function getFormData(data) {
  return request({
    url: "/formdata/detail",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 *
 * @param {*} data
 * @returns
 */
export function saveFormData(data) {
  return request({
    url: "/formdata/save",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 数据列表
 * @param {*} data
 * @returns
 */
export function fetchPage(data) {
  return request({
    url: "/formdata/list",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 获取统计数据
 * @param {*} data
 * @returns
 */
export function getAggreation(data) {
  return request({
    url: "/formdata/aggregation",
    method: "post",
    data,
  });
}

/**
 * 视图资源从低代码的视图列表接口
 * @returns
 */
export function fetchViewList(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/ui/view/list",
    method: "post",
    data,
  });
}
/**
 * 元数据表
 * @returns
 */
export function fetchUiMetaTableList(data) {
  return request({
    url: "/ui/meta/table/list",
    method: "post",
    data,
  });
}

/**
 * 新建表单excel
 * @returns
 */
export function formCreateExcel(data) {
  return request({
    url: "/ui/form/createExcel",
    method: "post",
    data,
  });
}
/**
 * 单元格属性保存
 * @returns
 */
export function excelUpdateCell(data) {
  return request({
    url: "/excel/updateCell",
    method: "post",
    data,
  });
}
/**
 * 单元格属性保存
 * @returns
 */
export function excelUpdateCellNew(data) {
  return request({
    url: "/excel/updateCellNew",
    method: "post",
    data,
  });
}
/**
 * 单元格属性保存
 * @returns
 */
export function excelEditCell(data) {
  return request({
    url: "/excel/editCell",
    method: "post",
    data,
  });
}

export function deleteForm(data) {
  return request({
    url: "/ui/form/delete",
    method: "post",
    data,
  });
}
export function formDuplicate(data) {
  return request({
    url: "/ui/form/duplicate",
    method: "post",
    data,
  });
}
export function metaEnvCode(data) {
  return request({
    url: "/ui/meta/envCode",
    method: "post",
    data,
  });
}

/**
 * 下载表单excel模板
 * @param data
 * @returns {*}
 */
export function excelExport(data) {
  return request({
    url: "/excel/export",
    method: "post",
    data,
  });
}

/**
 * 下载表单word模板
 * @param data
 */
export function wordExport(data) {
  return request({
    url: "/app/appFormExportWord/download",
    method: "post",
    data,
  });
}
/**
 * 重命名模板
 * @param data
 */
export function renameTemplate(data) {
  return request({
    url: "/dev/wordsAndExcels/rename",
    method: "post",
    data,
  });
}
/**
 * 删除模板
 * @param data
 */
export function deleteTemplate(data) {
  return request({
    url: "/dev/wordsAndExcels/delete",
    method: "post",
    data,
  });
}
/**
 * 获取模板列表
 * @param data
 */
export function getTemplateList(data) {
  return request({
    url: "/dev/wordsAndExcels/templateList",
    method: "post",
    data,
  });
}

/**
 * 表单转标准群
 * @param data
 * @returns {*}
 */
export function transToPlatform(data) {
  return request({
    url: "/ui/form/transToPlatform",
    method: "post",
    data,
  });
}
/**
 * 跟视图创建接口一样，只是没有真正的创建任何视图数据
 *
 * @param data {
 *   componentName: 'table',
 *   title:"",
 *   dataSource: {
 *     formId: '',
 *     queryObject:{},
 *     sourceType: 'form',
 *   }
 * }
 * @returns {*}
 */
export function uiViewInit(data) {
  return request({
    url: "/ui/view/init",
    method: "post",
    data,
  });
}
/**
 * 获取视图列表
 * @param data {
 *   componentName: 'table',
      dataSourceFormId:""
 * }
 * @returns {*}
 */
export function uiViewList(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/ui/view/list",
    method: "post",
    data,
  });
}

/**
 * 获取应用端数据流列表
 * @param {*} data
 * @returns
 */
export function fetchDataflowList(data) {
  return request({
    url: "/dataflow/list",
    method: "post",
    data,
  });
}

/**
 * 获取应用端工厂详情
 * @param {*} dataflowId
 * @returns
 */
export function getDataflow(dataflowId) {
  let data = {};
  if (dataflowId.indexOf("dataflow_") == 0) {
    data = {
      collection: dataflowId,
    };
  } else {
    data = {
      dataflowId,
    };
  }
  return request({
    url: "/dataflow/detail",
    method: "post",
    data,
  });
}

/**
 * 获取前端事件返回结果
 * @param {*} data
 * @returns
 */
export function getFrontEventResult(data) {
  return request({
    url: "/formdata/event",
    method: "post",
    data,
  });
}

/**
 * 复制表单到其他单位
 * @param {*} data
 * @returns
 */
export function duplicateFormToOtherCompany(data) {
  return request({
    url: "/ui/form/duplicateToTargetCompany",
    method: "post",
    data,
  });
}

/**
 * 复制表单到其他单位
 * @param {*} data
 * @returns
 */
export function duplicateDirToTargetCompany(data) {
  return request({
    url: "/ui/formDir/duplicateToTargetCompany",
    method: "post",
    data,
  });
}

/**
 * 获取表单回收站字段、不返回系统字段
 * @param {*}
 * @returns
 */
export function fetchRecycleFieldList(data) {
  return request({
    url: "/ui/meta/field/list",
    method: "post",
    data: {
      ...data,
      returnRecycleField: true,
      returnWithSystemField: false,
    },
  });
}

/**
 * 还原回收站表单字段
 * @param {*}
 * @returns
 */
export function restoreFormField(data) {
  return request({
    url: "/ui/meta/field/restoreFormField",
    method: "post",
    data,
  });
}

/**
 * 彻底删除回收站表单字段
 * @param {*}
 * @returns
 */
export function thoroughDeleteFormField(data) {
  return request({
    url: "/ui/meta/field/thoroughDeleteFormField",
    method: "post",
    data,
  });
}

/**
 * 获取回收站资源数据
 * @param {*}
 * @returns
 */
export function getRecycleForm(data) {
  return request({
    url: "/dir/recycle/list",
    method: "post",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 还原回收站资源数据
 * @param {*}
 * @returns
 */
export function restoreRecycleForm(data) {
  return request({
    url: "/dir/recycle/restore",
    method: "post",
    data,
  });
}

/**
 * 彻底删除回收站资源
 * @param {*}
 * @returns
 */
export function thoroughDeleteForm(data) {
  return request({
    url: "/dir/recycle/thoroughDelete",
    method: "post",
    data,
  });
}

/**
 * 获取回收站工作目录数据
 * @param {*}
 * @returns
 */
export function getWorkDirRecycleList(data) {
  return request({
    url: "/app/workDir/recycle/list",
    method: "post",
    data,
  });
}
/**
 * 还原回收站工作目录数据
 * @param {*}
 * @returns
 */
export function restoreWorkDirData(data) {
  return request({
    url: "/app/workDir/recycle/restore",
    method: "post",
    data,
  });
}
/**
 * 删除回收站工作目录数据
 * @param {*}
 * @returns
 */
export function thoroughDeleteWorkDirData(data) {
  return request({
    url: "/app/workDir/recycle/thoroughDelete",
    method: "post",
    data,
  });
}

/**
 * 重置流水号计数
 * @param {*} data
 * @returns
 */
export function resetSnInputValue(data) {
  return request({
    url: "/ui/form/resetSnValue",
    method: "post",
    data,
  });
}


/**
 * 表单设计-子表单转换为实体表模式
 * @param {*} data
 * @returns
 */
export function subFormToReferenceFormMode(data) {
  return request({
    url: "/ui/form/subFormToReferenceFormMode",
    method: "post",
    data,
  });
}
/**
 * 读取excel表单的sheetName
 * @param {*} data
 * @returns
 */
export function readExcelSheetName(data) {
  return request({
    url: "/dataImport/readSheetName",
    method: "post",
    data,
  });
}

/**
 * 读取excel表单的sheet数据
 * @param {*} data
 * @returns
 */
export function readExcelSheetData(data) {
  return request({
    url: "/dataImport/readSheetData",
    method: "post",
    data,
  });
}

/**
 * 字段类型列表
 * @param {*} data
 * @returns
 */
export function getComponentNameList(data) {
  return request({
    url: "/dataImport/componentNameList",
    method: "post",
    data,
  });
}

/**
 * 创建表单和导入数据
 * @param {*} data
 * @returns
 */
export function doImport(data) {
  return request({
    url: "/dataImport/doImport",
    method: "post",
    data,
  });
}

/**
 * 表单设计-表单字段限定格式
 * @param {*} data
 * @returns
 */
export function getValidatorType() {
  return request({
    url: "/ui/form/validatorType",
    method: "post",
  });
}

/**
 * 删除数据源前判断是否有被引用
 * @param {*} data
 * @returns
 */
export function referenceRemoveCheck(data) {
  return request({
    url: "/reference/removeCheck",
    method: "post",
    data,
  });
}

/**
 * 查询引用列表
 * @param {*} data
 * @returns
 */
export function referenceQueryBrief(data) {
  return request({
    url: "/reference/queryBrief",
    method: "post",
    data,
  });
}

/**
 * 查询引用详情
 * @param {*} data
 * @returns
 */
export function referenceQueryDetail(data) {
  return request({
    url: "/reference/queryDetail",
    method: "post",
    data,
  });
}

/**
 * 查询表单的引用视图图谱
 * @param {*} data
 * @returns
 */
export function referenceFormView(data) {
  return request({
    url: "/reference/formView",
    method: "post",
    data,
  });
}

/**
 * 获取集团表单分页列表 companyId title
 * @param {*} data
 * @returns
 */
export function groupFormList(data) {
  return request({
    url: "/company/link/form/list",
    method: "post",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 获取集团数据流分页列表 companyId title
 * @param {*} data
 * @returns
 */
export function fetchGroupDataflowList(data) {
  return request({
    url: "/company/link/dataflow/list",
    method: "post",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 获取集团聚合表分页列表 companyId title
 * @param {*} data
 * @returns
 */
export function fetchGroupAggregateList(data) {
  return request({
    url: "/company/link/ui/aggregate/table/list",
    method: "post",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

/**
 * 查看人员同步表字段 
 * @param {*} data
 * @returns
 */
export function getListOfContactForm(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: '/ui/meta/field/listOfContactForm',
    method: 'post',
    data
  })
}

/**
 * 表单树数据
 * @param {*} data
 * @param {string} collection 表单数据源
 * @param {string} fieldName 表单中的字段name[子表单字段xxx.xxx]
 * @returns
 */
export function getFormdataTree(data) {
  return request({
    url: "/formdata/tree",
    method: "post",
    data,
  });
}