export default {
  data() {
    return {
      timerCronLoopTypeEnums: [
        {
          label: "每小时",
          value: "perHour"
        },
        {
          label: "每天",
          value: "perDay"
        },
        {
          label: "工作日（星期一至星期五）",
          value: "workday"
        },
        {
          label: "每周",
          value: "perWeek"
        },
        {
          label: "每月",
          value: "perMonth"
        },
        {
          label: "每年",
          value: "perYear"
        },
        {
          label: "自定义",
          value: "custom"
        },
      ],
      timerFieldLoopTypeEnums: [
        {
          label: "不重复",
          value: "once"
        },
        {
          label: "每日",
          value: "perDay"
        },
        {
          label: "每周",
          value: "perWeek"
        },
        {
          label: "每月",
          value: "perMonth"
        },
        {
          label: "每年",
          value: "perYear"
        },

      ],
    }
  },
  watch: {},
  mounted() {

  },
  methods: {

    checkoutRange(inputValue, compareValue, min, max, checkoutType) {
      if (inputValue < min) {
        inputValue = min
      }
      if (inputValue > max) {
        inputValue = max
      }
      if (checkoutType == 'min') {
        if (compareValue && inputValue > compareValue) {
          inputValue = compareValue
        }
      }
      if (checkoutType == 'max') {
        if (compareValue && inputValue < compareValue) {
          inputValue = compareValue
        }
      }
      return inputValue
    },
    inputNumberCheck(value) {
      let val;
      // val = value.replace(/[^\d]/g, '').replace(/^0{1,}/g, '')
      val = value.replace(/[^\d]/g, '')
      if (val) {
        val = parseInt(val)
      }

      return val
    },
    addTimerTrigger(timerTriggerType) {
      console.log(timerTriggerType)
      const triggerConfig = this.buildTimerTriggerConfigByType(timerTriggerType)
      console.log(triggerConfig)
      this.$set(this.form.flow, "trigger", triggerConfig);
    },
    buildTimerTriggerConfigByType(timerTriggerType) {
      switch (timerTriggerType) {
        case 'timer_cron': {
          return this.buildTimerCronConfig()
          break
        }
        case 'timer_once': {
          return this.buildTimerOnceConfig()
          break
        }
        case 'timer_field': {
          return this.buildTimerFieldConfig()
          break
        }
        default: {
          return {}

        }
      }

    },
    //构建定时单次触发
    buildTimerOnceConfig() {
      let trigger = {
        isEdit: true,
        key: this.getUuid(),
        type: 'timer_once',
        title: "",
      };
      return trigger

    },
    //构建定时重复触发
    buildTimerCronConfig() {
      let trigger = {
        isEdit: true,
        key: this.getUuid(),
        type: "timer_cron",
        title: "",
        executeTime: new Date(),
        executeEndTime: "",
        loopType: "perDay",
        cronConfig: {}
      };
      return trigger
    },
    //构建时间字段触发
    buildTimerFieldConfig() {
      let trigger = {
        isEdit: true,
        key: this.getUuid(),
        type: "timer_field",
        formId: "",
        title: "",
        field: {
          fieldName: "",
          fieldTitle: "",
          picker: ""
        },
        executeTimeStr:"08:00:00",
        condition: {
          rel: "and",
          advanceQuery: []
        },
        timeOffset: undefined,
        endTimeField: {
          fieldName: "",
          fieldTitle: "",
          picker: ""
        },
        loopType: "once"
      };
      return trigger
    },

  }
}
