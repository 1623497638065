<template>
  <div>
    <el-button size="mini" @click="showDialog" style="width: 100%">{{
      value.length ? "已设置过滤条件" : "设置过滤条件"
    }}</el-button>
    <el-dialog
      @open="open"
      title="设置过滤条件"
      :visible.sync="dialogVisible"
      width="600"
    >
      <div class="container">
        <div>
          <el-popover
            placement="bottom-start"
            v-model="filterVisible"
            width="400"
          >
            <div style="max-height: 320px; overflow: auto">
              <div
                class="field-item"
                v-for="view in componentList"
                :key="view.collection"
              >
                <!-- 标题 -->
                <div @click="view.show = !view.show" class="field-title">
                  <i
                    :class="
                      view.show ? 'el-icon-caret-bottom' : 'el-icon-caret-right'
                    "
                  ></i>
                  <div
                    class="field-title-text"
                    v-text="view.title"
                    :title="view.title"
                  ></div>
                </div>

                <!-- 视图组件 -->
                <div v-show="view.show">
                  <div
                    class="field-component"
                    v-for="item in view.components"
                    :key="item.key"
                    v-text="item.title"
                    :title="item.title"
                    @click="checkComponent(item, view.collection)"
                  ></div>
                </div>
              </div>
            </div>
            <el-button
              slot="reference"
              type="text"
              style="padding: 5px 0px"
              icon="el-icon-plus"
              >添加条件</el-button
            >
          </el-popover>
        </div>
        <div>
          <div
            v-for="(item, index) in advanceQuery"
            :key="`${item.collection}_${item.key}`"
            class="weui-row"
          >
            <el-input
              size="small"
              readonly
              :value="renderTitle(item)"
              style="width: 200px"
            ></el-input>
            <!-- 操作符start -->
            <el-select
              v-model="item.oper"
              placeholder="操作符"
              size="small"
              style="margin-left: 10px; width: 140px; margin-right: 10px"
              @change="changeOper(item)"
            >
              <el-option
                v-for="opt in getDisplayQueryOpers(item.componentName)"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value"
              >
              </el-option>
            </el-select>
            <!-- 操作符end -->
            <div class="flex" v-if="!['nil', 'nnil'].includes(item.oper)">
              <query-input
                :field="item"
                style="width: 100%"
                v-model="item.value"
                :componentList="getComponentList(item.collection)"
              ></query-input>
            </div>

            <i
              @click="deleteItem(index)"
              style="margin-left: 10px"
              class="el-icon-delete"
            ></i>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import test from "@/utils/test";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import {
  aggregateFilterFields,
  getComponentQueryOper,
} from "@zgg-core-utils/whiteList";
export default {
  directives: { Clickoutside },
  props: {
    collections: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    areaTree: {
      type: Array,
      default() {
        return [];
      },
    },
    formComponents: Object,
  },
  watch: {
    formComponents: {
      deep: true,
      immediate: true,
      handler(val, old) {
        if (isEmpty(val)) {
          this.$set(this, "componentList", []);
        } else {
          let map = [];
          for (let [key, value] of Object.entries(val)) {
            let list = [];
            value.components.forEach((item) => {
              if (item.componentName == "json_form") {
                item.components.forEach((child) => {
                  if (aggregateFilterFields.includes(child.componentName)) {
                    let obj = this._.cloneDeep(child);
                    obj.name = item.name + "." + obj.name;
                    obj.title = item.title + "." + obj.title;
                    list.push(obj);
                  }
                });
              } else {
                if (aggregateFilterFields.includes(item.componentName)) {
                  let obj = this._.cloneDeep(item);

                  list.push(obj);
                }
              }
            });
            map.push({
              collection: key,
              title: value.title,
              components: list,
              show: false,
            });
          }
          this.$set(this, "componentList", map);
        }
      },
    },
  },

  data() {
    return {
      dialogVisible: false,
      filterVisible: false,
      componentList: [],
      advanceQuery: [],
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
    };
  },

  methods: {
    getComponentList(collection) {
      let view = this.componentList.find(
        (item) => item.collection == collection,
      );

      if (view) {
        return view.components;
      }
      return [];
    },
    save() {
      let error = "";
      this.advanceQuery.forEach((item) => {
        if (isEmpty(item.value) && !["nil", "nnil"].includes(item.oper)) {
          error = "请完善过滤条件";
        }
      });
      if (error) {
        this.$message.error(error);
        return;
      }
      this.$emit("input", this._.cloneDeep(this.advanceQuery));
      this.dialogVisible = false;
    },
    deleteItem(index) {
      this.advanceQuery.splice(index, 1);
    },
    areaList(obj) {
      let list = [];
      let $item = this.getComponent(obj);
      let picker = $item.picker;
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    changeOper(item) {
      item.value = "";
    },
    changeAddr(item, value) {
      let obj = {};
      let provinceId = value[0];
      let cityId = value[1];
      let countyId = value[2];
      let provinceItem = this.areaTree.find((item) => item.id == provinceId);
      obj.provinceId = provinceId;
      obj.province = provinceItem.name;
      if (cityId) {
        let cityItem = provinceItem.children.find((item) => item.id == cityId);
        obj.cityId = cityId;
        obj.city = cityItem.name;
        if (countyId) {
          let countyItem = cityItem.children.find(
            (item) => item.id == countyId,
          );
          obj.countyId = countyId;
          obj.county = countyItem.name;
        }
      }
      this.$set(item, "value", obj);
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
    getAddr(value) {
      let arr = [];
      if (value) {
        if (value.provinceId) {
          arr.push(value.provinceId);
        }
        if (value.cityId) {
          arr.push(value.cityId);
        }
        if (value.countyId) {
          arr.push(value.countyId);
        }
      }
      return arr;
    },
    closeTag(list, index) {
      this.$delete(list, index);
    },
    addModal(item, index) {
      this.$prompt("请输入筛选值", "筛选值", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (value) => {
          return !test.isEmpty(value);
        },
        inputErrorMessage: "请输入筛选值",
      })
        .then(({ value }) => {
          if (!item.value) {
            item.value = [];
          }
          item.value.push(value);
          this.$nextTick(() => {
            let $tagScroll = this.$refs["tagScroll_" + index][0];
            let scrollLeft = $tagScroll.scrollWidth - $tagScroll.clientWidth;
            $tagScroll.scrollLeft = scrollLeft;
          });
        })
        .catch(() => {});
    },
    getDatePicker(item) {
      let obj = this.getComponent(item);
      if (obj && obj.picker != "date_minute") {
        return obj.picker;
      }
      return "datetime";
    },
    getValueFormat(item) {
      let obj = this.getComponent(item);
      let picker;
      if (obj) {
        picker = obj.picker;
      }
      if (!picker) {
        picker = "datetime";
      }

      if (picker == "year") {
        return "yyyy";
      } else if (picker == "month") {
        return "yyyy-MM";
      } else if (picker == "date") {
        return "yyyy-MM-dd";
      } else if (picker == "date_minute") {
        return "yyyy-MM-dd HH:mm";
      } else {
        return "yyyy-MM-dd HH:mm:ss";
      }
    },
    mousedown(event) {
      event.preventDefault();
      document.addEventListener("mousemove", this.mousemove);
      document.addEventListener("mouseup", this.mouseup);
      let clientWidth = event.currentTarget.clientWidth;
      let scrollWidth = event.currentTarget.scrollWidth;
      this.down = scrollWidth > clientWidth;
      if (this.down) {
        this.scrollPosition = {
          startX: event.clientX,
          scrollLeft: event.currentTarget.scrollLeft,
          maxLeft: scrollWidth - clientWidth,
          $el: event.currentTarget,
        };
      }
    },
    mouseup(event) {
      if (event) {
        event.preventDefault();
      }
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.down = false;
    },
    mousemove(event) {
      event.preventDefault();
      if (!this.down) {
        return;
      }

      let endX = event.clientX;
      let scrollLeft =
        this.scrollPosition.scrollLeft + this.scrollPosition.startX - endX;
      if (scrollLeft > this.scrollPosition.maxLeft) {
        scrollLeft = this.scrollPosition.maxLeft;
      } else if (scrollLeft < 0) {
        scrollLeft = 0;
      }

      this.scrollPosition.$el.scrollLeft = scrollLeft;
    },
    isCheck(item, collection) {
      return (
        this.advanceQuery.findIndex(
          (obj) => obj.collection == collection && obj.key == item.key,
        ) >= 0
      );
    },
    renderTitle(item) {
      let view = this.componentList.find(
        (obj) => obj.collection == item.collection,
      );
      if (view) {
        let obj = null;
        if (item.key.indexOf(".") >= 0) {
          let keys = item.key.split(".");
          console.log(view.components);
          obj = view.components.find((opt) => opt.key == keys[1]);
        } else {
          obj = view.components.find((opt) => opt.key == item.key);
        }
        if (obj) {
          return obj.title;
        }
      }
      return item.key;
    },
    getComponent(item) {
      let view = this.componentList.find(
        (obj) => obj.collection == item.collection,
      );
      if (view) {
        let obj = view.components.find((opt) => opt.key == item.key);
        if (obj) {
          return obj;
        }
      }
      return;
    },

    checkComponent(item, collection) {
      let list = getComponentQueryOper(item.componentName);
      this.advanceQuery.push({
        collection,
        key: item.name,
        oper: list[0].value,
        value: "",
        componentName: item.componentName,
      });

      this.filterVisible = false;
    },
    getDisplayQueryOpers(componentName) {
      if (componentName) {
        return getComponentQueryOper(componentName);
      }
      return [];
    },
    showDialog() {
      if (!this.collections.length) {
        this.$message.error("请选择数据源");
        return;
      }
      this.dialogVisible = true;
    },
    open() {
      this.advanceQuery = this._.cloneDeep(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.field-item {
  .field-title {
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 14px;
    .field-title-text {
      flex: 1;
      margin-left: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.field-component {
  padding-left: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #409eff, $alpha: 0.05);
  }
  &.check {
    cursor: not-allowed;
    color: #c3cdda;
    &:hover {
      background-color: transparent;
    }
  }
}

.data-item {
  margin-bottom: 20px;
  .data-label {
    margin-bottom: 10px;
  }
  .data-sel {
    width: 100%;
  }
}

.row-center {
  display: flex;
  align-items: center;
}

.weui {
  display: flex;
  align-items: center;
}
.icon-item {
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e6f7f6;
  }
  &.disabled {
    color: #c3cdda;
  }
}

.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}
.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
.icon-text {
  font-size: 14px;
}
.flex {
  flex: 1;
}
.el-value {
  flex: 1;
  overflow: hidden;
  ::v-deep {
    .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.el-prefix {
  border: 1px solid #dcdfe6;
  border-right: 0;
  height: 32px;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}
.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.weui-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
