<template>
  <div class="join-container">
    <template v-if="tab == 'attribute'">
      <div class="container-left">
        <div style="font-size: 14px; margin-bottom: 10px">1.设置连接方式</div>
        <div class="join-menu">
          <div
            class="menu-item"
            :class="node.join == 'inner' ? 'active' : ''"
            @click="node.join = 'inner'"
          >
            <i class="iconfont-fx-pc icon-inner-join"></i>内连接
          </div>
          <div
            class="menu-item"
            :class="node.join == 'left' ? 'active' : ''"
            @click="node.join = 'left'"
          >
            <i class="iconfont-fx-pc icon-left-join"></i>左连接
          </div>
          <div
            class="menu-item"
            :class="node.join == 'right' ? 'active' : ''"
            @click="node.join = 'right'"
          >
            <i class="iconfont-fx-pc icon-right-join"></i>右连接
          </div>
        </div>
        <div class="join-merge-title">连接字段设置</div>
        <el-checkbox v-model="node.mergeJoinFields">合并连接字段</el-checkbox>
      </div>
      <div class="container-right">
        <div style="font-size: 14px; margin-bottom: 10px">2.添加连接字段</div>
        <div class="row-weui">
          <div class="field-col" style="font-size: 12px">
            <span>左侧表单: </span>
            <i class="iconfont-fx-pc icon-pc-form"></i>
            <span v-text="leftNode.title"></span>
          </div>
          <div class="field-col" style="font-size: 12px">
            <span>右侧表单:</span>
            <i class="iconfont-fx-pc icon-pc-form"></i>
            <span v-text="rightNode.title"></span>
          </div>
        </div>
        <div class="scroll-body">
          <div
            v-for="(item, index) in node.joinFields"
            :key="index"
            class="row-weui"
            style="margin-bottom: 5px"
          >
            <!-- 左连接 -->
            <div class="field-col">
              <field-select
                :node="leftNode"
                :field.sync="item.leftField"
                :otherField="item.rightField"
                :isDeleteField="isDeleteField"
                @changeChildrenData="changeChildrenData"
              ></field-select>
            </div>
            <div class="eq">=</div>
            <!-- 右连接 -->
            <div class="field-col" style="margin-right: 15px">
              <field-select
                :node="rightNode"
                :field.sync="item.rightField"
                :otherField="item.leftField"
                :isDeleteField="isDeleteField"
                @changeChildrenData="changeChildrenData"
              ></field-select>
            </div>
            <el-tooltip effect="dark" content="重置" placement="top">
              <i @click="resetField(index)" class="el-icon-refresh-right"></i>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <i
                @click="deleteField(index)"
                style="margin-left: 10px"
                class="el-icon-delete"
              ></i>
            </el-tooltip>
          </div>
          <div style="margin-bottom: 10px">
            <el-button @click="add" type="text" size="small">添加</el-button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <preview-error v-if="isError"></preview-error>
      <etl-preview
        v-else
        :node="node"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-preview>
    </template>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import FieldSelect from "./FieldSelect";
import nodeMixin from "./nodeMixin";
import PreviewError from "./PreviewError";
import EtlPreview from "./EtlPreview";
import { changeChildrenData, isDeleteField } from "./util";

export default {
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },
  components: { FieldSelect, PreviewError, EtlPreview },
  mixins: [nodeMixin],
  computed: {
    lineKeys() {
      let list = this.etlLines
        .filter((item) => item.toKey == this.node.key)
        .map((item) => item.fromKey);
      return list;
    },
    leftNode() {
      let key = this.lineKeys[0];
      if (!isEmpty(key)) {
        return this.etlList.find((item) => item.key == key);
      }
    },
    rightNode() {
      let key = this.lineKeys[1];
      if (!isEmpty(key)) {
        return this.etlList.find((item) => item.key == key);
      }
    },
  },
  methods: {
    changeChildrenData() {
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
    isDeleteField,
    deleteField(index) {
      this.$delete(this.node.joinFields, index);
    },

    resetField(index) {
      this.node.joinFields[index].leftField = {};
      this.node.joinFields[index].rightField = {};
    },

    add() {
      this.node.joinFields.push({
        leftField: {},
        rightField: {},
      });
      this.changeChildrenData();
    },
  },
};
</script>
<style lang="scss" scoped>
.join-container {
  display: flex;
  height: 100%;
  background-color: #ffffff;
  .container-left {
    width: 310px;
    height: 100%;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
  }
  .container-right {
    flex: 1;
    height: 100%;
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .scroll-body {
      flex: 1;
      overflow: auto;
    }
  }
}
.join-menu {
  display: flex;
  align-items: center;
  border: solid 1px #e0e0e0;
  margin-bottom: 10px;
  .menu-item {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e0e0e0;
    font-size: 14px;
    cursor: pointer;
    flex: 1;
    i {
      margin-right: 5px;
    }
    &:last-child {
      border-right: 0;
    }
    &.active {
      background-color: #248af9;
      color: #ffffff;
      i {
        color: #ffffff;
      }
    }
  }
}
.join-merge-title {
  margin-bottom: 5px;
  color: #91a1b7;
  font-size: 12px;
}
.row-weui {
  display: flex;
  align-items: center;
}
.field-col {
  flex: 1;
  height: 35px;
  display: flex;
  align-items: center;
}
.eq {
  margin: 0 30px;
  color: #2481f9;
}
</style>
