export const fontAwesomenJson = {
  建筑物: [
    { label: "房子", value: "fas fa-house fa-fw" },
    { label: "城市", value: "fas fa-city fa-fw" },
    { label: "店", value: "fas fa-shop fa-fw" },
    { label: "地标", value: "fas fa-landmark fa-fw" },
    { label: "建筑", value: "fas fa-building fa-fw" },
    { label: "建筑", value: "far fa-building fa-fw" },
    { label: "商店", value: "fas fa-store fa-fw" },
    { label: "学校", value: "fas fa-school fa-fw" },
    { label: "旅馆", value: "fas fa-hotel fa-fw" },
    { label: "礼拜场所", value: "fas fa-place-of-worship fa-fw" },
    { label: "医院", value: "fas fa-hospital fa-fw" },
    { label: "医院", value: "far fa-hospital fa-fw" },
    { label: "教堂", value: "fas fa-church fa-fw" },
    { label: "仓库", value: "fas fa-warehouse fa-fw" },
    { label: "戈普拉姆", value: "fas fa-gopuram fa-fw" },
    { label: "工业", value: "fas fa-industry fa-fw" },
    { label: "维哈拉", value: "fas fa-vihara fa-fw" },
    { label: "树城", value: "fas fa-tree-city fa-fw" },
    { label: "塔观察", value: "fas fa-tower-observation fa-fw" },
    { label: "鸟居门", value: "fas fa-torii-gate fa-fw" },
    { label: "便携式厕所", value: "fas fa-toilets-portable fa-fw" },
    { label: "便携式厕所", value: "fas fa-toilet-portable fa-fw" },
    { label: "帐篷", value: "fas fa-tents fa-fw" },
    { label: "帐篷箭头向下", value: "fas fa-tent-arrows-down fa-fw" },
    {
      label: "帐篷箭头左转",
      value: "fas fa-tent-arrow-turn-left fa-fw",
    },
    {
      label: "帐篷箭头左右",
      value: "fas fa-tent-arrow-left-right fa-fw",
    },
    {
      label: "tent-arrow-down-to-line",
      value: "fas fa-tent-arrow-down-to-line fa-fw",
    },
    { label: "帐篷", value: "fas fa-tent fa-fw" },
    { label: "犹太教堂", value: "fas fa-synagogue fa-fw" },
    { label: "店锁", value: "fas fa-shop-lock fa-fw" },
    { label: "学校锁", value: "fas fa-school-lock fa-fw" },
    { label: "校旗", value: "fas fa-school-flag fa-fw" },
    {
      label: "学校圈子-xmark",
      value: "fas fa-school-circle-xmark fa-fw",
    },
    {
      label: "学校-圈子-感叹号",
      value: "fas fa-school-circle-exclamation fa-fw",
    },
    {
      label: "学校圈子检查",
      value: "fas fa-school-circle-check fa-fw",
    },
    { label: "油井", value: "fas fa-oil-well fa-fw" },
    { label: "山城", value: "fas fa-mountain-city fa-fw" },
    { label: "清真寺", value: "fas fa-mosque fa-fw" },
    { label: "纪念碑", value: "fas fa-monument fa-fw" },
    { label: "地标旗帜", value: "fas fa-landmark-flag fa-fw" },
    { label: "地标穹顶", value: "fas fa-landmark-dome fa-fw" },
    { label: "天房", value: "fas fa-kaaba fa-fw" },
    { label: "冰屋", value: "fas fa-igloo fa-fw" },
    {
      label: "房屋-医疗-旗帜",
      value: "fas fa-house-medical-flag fa-fw",
    },
    {
      label: "删除医疗房子",
      value: "fas fa-house-medical-circle-xmark fa-fw",
    },
    {
      label: "感叹号",
      value: "fas fa-house-medical-circle-exclamation fa-fw",
    },
    {
      label: "选择房子",
      value: "fas fa-house-medical-circle-check fa-fw",
    },
    { label: "家庭医疗", value: "fas fa-house-medical fa-fw" },
    { label: "房屋锁", value: "fas fa-house-lock fa-fw" },
    { label: "家旗", value: "fas fa-house-flag fa-fw" },
    { label: "房屋火灾", value: "fas fa-house-fire fa-fw" },
    { label: "房屋裂缝", value: "fas fa-house-crack fa-fw" },
    {
      label: "房子圈子-xmark",
      value: "fas fa-house-circle-xmark fa-fw",
    },
    {
      label: "house-circle-感叹号",
      value: "fas fa-house-circle-exclamation fa-fw",
    },
    {
      label: "房子圈子检查",
      value: "fas fa-house-circle-check fa-fw",
    },
    {
      label: "房屋烟囱窗户",
      value: "fas fa-house-chimney-window fa-fw",
    },
    {
      label: "房屋-烟囱-医疗",
      value: "fas fa-house-chimney-medical fa-fw",
    },
    {
      label: "房屋烟囱裂缝",
      value: "fas fa-house-chimney-crack fa-fw",
    },
    { label: "房屋烟囱", value: "fas fa-house-chimney fa-fw" },
    { label: "医院用户", value: "fas fa-hospital-user fa-fw" },
    { label: "地牢", value: "fas fa-dungeon fa-fw" },
    { label: "野营地", value: "fas fa-campground fa-fw" },
    { label: "建筑小麦", value: "fas fa-building-wheat fa-fw" },
    {
      label: "building-user（建筑用户）",
      value: "fas fa-building-user fa-fw",
    },
    { label: "建筑-联合国", value: "fas fa-building-un fa-fw" },
    { label: "建筑盾构", value: "fas fa-building-shield fa-fw" },
    { label: "建筑-NGO", value: "fas fa-building-ngo fa-fw" },
    { label: "楼宇锁", value: "fas fa-building-lock fa-fw" },
    { label: "建筑标志", value: "fas fa-building-flag fa-fw" },
    { label: "建筑柱", value: "fas fa-building-columns fa-fw" },
    {
      label: "删除大楼",
      value: "fas fa-building-circle-xmark fa-fw",
    },
    {
      label: "建筑-圆-感叹号",
      value: "fas fa-building-circle-exclamation fa-fw",
    },
    {
      label: "建筑-圆-检查",
      value: "fas fa-building-circle-check fa-fw",
    },
    {
      label: "建筑-圆-箭头-右",
      value: "fas fa-building-circle-arrow-right fa-fw",
    },
    {
      label: "向右箭头到城市",
      value: "fas fa-arrow-right-to-city fa-fw",
    },
    { label: "拱道", value: "fas fa-archway fa-fw" },
  ],
  文件: [
    { label: "文件", value: "fas fa-file fa-fw" },
    { label: "文件", value: "far fa-file fa-fw" },
    { label: "文件夹", value: "fas fa-folder fa-fw" },
    { label: "文件夹", value: "far fa-folder fa-fw" },
    { label: "文件夹打开", value: "fas fa-folder-open fa-fw" },
    { label: "文件夹打开", value: "far fa-folder-open fa-fw" },
    { label: "复制", value: "fas fa-copy fa-fw" },
    { label: "复制", value: "far fa-copy fa-fw" },
    { label: "文件-excel", value: "fas fa-file-excel fa-fw" },
    { label: "文件-excel", value: "far fa-file-excel fa-fw" },
    { label: "文件导出", value: "fas fa-file-export fa-fw" },
    { label: "文件-字", value: "fas fa-file-word fa-fw" },
    { label: "文件-字", value: "far fa-file-word fa-fw" },
    { label: "文件导入", value: "fas fa-file-import fa-fw" },
    { label: "文件-pdf", value: "fas fa-file-pdf fa-fw" },
    { label: "文件-pdf", value: "far fa-file-pdf fa-fw" },
    { label: "克隆", value: "fas fa-clone fa-fw" },
    { label: "克隆", value: "far fa-clone fa-fw" },
    { label: "剪刀", value: "fas fa-scissors fa-fw" },
    { label: "照片胶片", value: "fas fa-photo-film fa-fw" },
    { label: "糊", value: "fas fa-paste fa-fw" },
    { label: "笔记粘性", value: "fas fa-note-sticky fa-fw" },
    { label: "文件夹已关闭", value: "fas fa-folder-closed fa-fw" },
    { label: "软盘", value: "fas fa-floppy-disk fa-fw" },
    { label: "文件拉链", value: "fas fa-file-zipper fa-fw" },
    { label: "文件-视频", value: "fas fa-file-video fa-fw" },
    { label: "文件盾", value: "fas fa-file-shield fa-fw" },
    {
      label: "文件-PowerPoint",
      value: "fas fa-file-powerpoint fa-fw",
    },
    { label: "文件笔", value: "fas fa-file-pen fa-fw" },
    { label: "文件行", value: "fas fa-file-lines fa-fw" },
    { label: "文件-图像", value: "fas fa-file-image fa-fw" },
    { label: "文件-CSV", value: "fas fa-file-csv fa-fw" },
    { label: "文件代码", value: "fas fa-file-code fa-fw" },
    {
      label: "文件-circle-xmark",
      value: "fas fa-file-circle-xmark fa-fw",
    },
    {
      label: "文件圈问题",
      value: "fas fa-file-circle-question fa-fw",
    },
    { label: "文件圈加", value: "fas fa-file-circle-plus fa-fw" },
    { label: "文件-圆-减号", value: "fas fa-file-circle-minus fa-fw" },
    {
      label: "文件-圆-感叹号",
      value: "fas fa-file-circle-exclamation fa-fw",
    },
    { label: "文件圈检查", value: "fas fa-file-circle-check fa-fw" },
    { label: "文件-音频", value: "fas fa-file-audio fa-fw" },
    { label: "文件箭头向上", value: "fas fa-file-arrow-up fa-fw" },
    { label: "文件向下箭头", value: "fas fa-file-arrow-down fa-fw" },
    { label: "盒存档", value: "fas fa-box-archive fa-fw" },
    { label: "糊", value: "far fa-paste fa-fw" },
    { label: "笔记粘性", value: "far fa-note-sticky fa-fw" },
    { label: "文件夹已关闭", value: "far fa-folder-closed fa-fw" },
    { label: "软盘", value: "far fa-floppy-disk fa-fw" },
    { label: "文件拉链", value: "far fa-file-zipper fa-fw" },
    { label: "文件-视频", value: "far fa-file-video fa-fw" },
    {
      label: "文件-PowerPoint",
      value: "far fa-file-powerpoint fa-fw",
    },
    { label: "文件行", value: "far fa-file-lines fa-fw" },
    { label: "文件-图像", value: "far fa-file-image fa-fw" },
    { label: "文件代码", value: "far fa-file-code fa-fw" },
    { label: "文件-音频", value: "far fa-file-audio fa-fw" },
  ],
  商务: [
    { label: "电话", value: "fas fa-phone fa-fw" },
    { label: "信封", value: "fas fa-envelope fa-fw" },
    { label: "信封", value: "far fa-envelope fa-fw" },
    { label: "笔尖", value: "fas fa-pen-nib fa-fw" },
    { label: "日历日", value: "fas fa-calendar-days fa-fw" },
    { label: "日历日", value: "far fa-calendar-days fa-fw" },
    { label: "回形针", value: "fas fa-paperclip fa-fw" },
    { label: "文件", value: "fas fa-file fa-fw" },
    { label: "文件", value: "far fa-file fa-fw" },
    { label: "剪贴板", value: "fas fa-clipboard fa-fw" },
    { label: "剪贴板", value: "far fa-clipboard fa-fw" },
    { label: "笔", value: "fas fa-pen fa-fw" },
    { label: "标记", value: "fas fa-tag fa-fw" },
    { label: "书", value: "fas fa-book fa-fw" },
    { label: "打印", value: "fas fa-print fa-fw" },
    { label: "笔到正方形", value: "fas fa-pen-to-square fa-fw" },
    { label: "笔到正方形", value: "far fa-pen-to-square fa-fw" },
    { label: "文件夹", value: "fas fa-folder fa-fw" },
    { label: "文件夹", value: "far fa-folder fa-fw" },
    { label: "文件夹打开", value: "fas fa-folder-open fa-fw" },
    { label: "文件夹打开", value: "far fa-folder-open fa-fw" },
    { label: "图钉", value: "fas fa-thumbtack fa-fw" },
    { label: "球", value: "fas fa-globe fa-fw" },
    { label: "城市", value: "fas fa-city fa-fw" },
    { label: "公文包", value: "fas fa-briefcase fa-fw" },
    { label: "指南针", value: "fas fa-compass fa-fw" },
    { label: "指南针", value: "far fa-compass fa-fw" },
    { label: "地址簿", value: "fas fa-address-book fa-fw" },
    { label: "地址簿", value: "far fa-address-book fa-fw" },
    { label: "营业时间", value: "fas fa-business-time fa-fw" },
    { label: "钢笔式", value: "fas fa-pen-fancy fa-fw" },
    { label: "马克杯碟", value: "fas fa-mug-saucer fa-fw" },
    { label: "地标", value: "fas fa-landmark fa-fw" },
    { label: "图表简单", value: "fas fa-chart-simple fa-fw" },
    { label: "日历", value: "fas fa-calendar fa-fw" },
    { label: "日历", value: "far fa-calendar fa-fw" },
    { label: "铅笔", value: "fas fa-pencil fa-fw" },
    { label: "电话音量", value: "fas fa-phone-volume fa-fw" },
    { label: "复制", value: "fas fa-copy fa-fw" },
    { label: "复制", value: "far fa-copy fa-fw" },
    { label: "桌子", value: "fas fa-table fa-fw" },
    { label: "建筑", value: "fas fa-building fa-fw" },
    { label: "建筑", value: "far fa-building fa-fw" },
    { label: "网络有线", value: "fas fa-network-wired fa-fw" },
    { label: "地址卡", value: "fas fa-address-card fa-fw" },
    { label: "地址卡", value: "far fa-address-card fa-fw" },
    { label: "标签", value: "fas fa-tags fa-fw" },
    { label: "注册", value: "fas fa-registered fa-fw" },
    { label: "注册", value: "far fa-registered fa-fw" },
    { label: "签名", value: "fas fa-signature fa-fw" },
    { label: "网站地图", value: "fas fa-sitemap fa-fw" },
    { label: "标记", value: "fas fa-marker fa-fw" },
    { label: "钱包", value: "fas fa-wallet fa-fw" },
    { label: "版权", value: "fas fa-copyright fa-fw" },
    { label: "版权", value: "far fa-copyright fa-fw" },
    { label: "靶心", value: "fas fa-bullseye fa-fw" },
    { label: "计算器", value: "fas fa-calculator fa-fw" },
    { label: "证书", value: "fas fa-certificate fa-fw" },
    { label: "百分之", value: "fas fa-percent fa-fw" },
    { label: "橡皮擦", value: "fas fa-eraser fa-fw" },
    { label: "眼镜", value: "fas fa-glasses fa-fw" },
    { label: "工业", value: "fas fa-industry fa-fw" },
    { label: "库", value: "fas fa-vault fa-fw" },
    { label: "商标", value: "fas fa-trademark fa-fw" },
    { label: "时间线", value: "fas fa-timeline fa-fw" },
    { label: "表列", value: "fas fa-table-columns fa-fw" },
    { label: "订书机", value: "fas fa-stapler fa-fw" },
    {
      label: "方形-轮询-垂直",
      value: "fas fa-square-poll-vertical fa-fw",
    },
    {
      label: "方形-轮询-水平",
      value: "fas fa-square-poll-horizontal fa-fw",
    },
    { label: "方形手机翻转", value: "fas fa-square-phone-flip fa-fw" },
    { label: "方形电话", value: "fas fa-square-phone fa-fw" },
    { label: "方笔", value: "fas fa-square-pen fa-fw" },
    { label: "方形信封", value: "fas fa-square-envelope fa-fw" },
    { label: "袜子", value: "fas fa-socks fa-fw" },
    { label: "剪刀", value: "fas fa-scissors fa-fw" },
    {
      label: "缩放-不平衡-翻转",
      value: "fas fa-scale-unbalanced-flip fa-fw",
    },
    { label: "规模不平衡", value: "fas fa-scale-unbalanced fa-fw" },
    { label: "规模平衡", value: "fas fa-scale-balanced fa-fw" },
    { label: "电话斜杠", value: "fas fa-phone-slash fa-fw" },
    { label: "手机翻转", value: "fas fa-phone-flip fa-fw" },
    { label: "人-黑板", value: "fas fa-person-chalkboard fa-fw" },
    { label: "笔夹", value: "fas fa-pen-clip fa-fw" },
    { label: "糊", value: "fas fa-paste fa-fw" },
    { label: "笔记粘性", value: "fas fa-note-sticky fa-fw" },
    {
      label: "放大镜图",
      value: "fas fa-magnifying-glass-chart fa-fw",
    },
    {
      label: "放大镜右箭头",
      value: "fas fa-magnifying-glass-arrow-right fa-fw",
    },
    { label: "列表检查", value: "fas fa-list-check fa-fw" },
    { label: "laptop-file", value: "fas fa-laptop-file fa-fw" },
    { label: "家用笔记本电脑", value: "fas fa-house-laptop fa-fw" },
    { label: "荧光笔", value: "fas fa-highlighter fa-fw" },
    { label: "文件夹树", value: "fas fa-folder-tree fa-fw" },
    { label: "文件夹加", value: "fas fa-folder-plus fa-fw" },
    { label: "文件夹减号", value: "fas fa-folder-minus fa-fw" },
    { label: "软盘", value: "fas fa-floppy-disk fa-fw" },
    { label: "文件行", value: "fas fa-file-lines fa-fw" },
    { label: "文件圈加", value: "fas fa-file-circle-plus fa-fw" },
    { label: "传真", value: "fas fa-fax fa-fw" },
    { label: "信封打开", value: "fas fa-envelope-open fa-fw" },
    {
      label: "信封-圆-检查",
      value: "fas fa-envelope-circle-check fa-fw",
    },
    { label: "剪贴板问题", value: "fas fa-clipboard-question fa-fw" },
    { label: "图表饼图", value: "fas fa-chart-pie fa-fw" },
    { label: "图表线", value: "fas fa-chart-line fa-fw" },
    { label: "蛋糕蜡烛", value: "fas fa-cake-candles fa-fw" },
    { label: "扩音器", value: "fas fa-bullhorn fa-fw" },
    { label: "箱装", value: "fas fa-boxes-packing fa-fw" },
    { label: "盒存档", value: "fas fa-box-archive fa-fw" },
    { label: "条形交错", value: "fas fa-bars-staggered fa-fw" },
    { label: "条形图进度", value: "fas fa-bars-progress fa-fw" },
    { label: "箭头到眼睛", value: "fas fa-arrows-to-eye fa-fw" },
    { label: "箭头到点", value: "fas fa-arrows-to-dot fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-spin fa-fw" },
    { label: "糊", value: "far fa-paste fa-fw" },
    { label: "笔记粘性", value: "far fa-note-sticky fa-fw" },
    { label: "软盘", value: "far fa-floppy-disk fa-fw" },
    { label: "文件行", value: "far fa-file-lines fa-fw" },
    { label: "信封打开", value: "far fa-envelope-open fa-fw" },
  ],
  图表: [
    { label: "圆半冲程", value: "fas fa-circle-half-stroke fa-fw" },
    { label: "图表简单", value: "fas fa-chart-simple fa-fw" },
    {
      label: "方形-轮询-垂直",
      value: "fas fa-square-poll-vertical fa-fw",
    },
    {
      label: "方形-轮询-水平",
      value: "fas fa-square-poll-horizontal fa-fw",
    },
    { label: "图后继者", value: "fas fa-diagram-successor fa-fw" },
    { label: "图项目", value: "fas fa-diagram-project fa-fw" },
    { label: "图前身", value: "fas fa-diagram-predecessor fa-fw" },
    { label: "图表-下一个", value: "fas fa-diagram-next fa-fw" },
    { label: "图表饼图", value: "fas fa-chart-pie fa-fw" },
    { label: "图表线", value: "fas fa-chart-line fa-fw" },
    { label: "图表-甘特图", value: "fas fa-chart-gantt fa-fw" },
    { label: "图表柱形图", value: "fas fa-chart-column fa-fw" },
    { label: "图表栏", value: "fas fa-chart-bar fa-fw" },
    { label: "图表区", value: "fas fa-chart-area fa-fw" },
    { label: "条形图进度", value: "fas fa-bars-progress fa-fw" },
    { label: "图表栏", value: "far fa-chart-bar fa-fw" },
  ],
  建筑施工: [
    { label: "油漆滚筒", value: "fas fa-paint-roller fa-fw" },
    { label: "刷", value: "fas fa-brush fa-fw" },
    { label: "垃圾箱火灾", value: "fas fa-dumpster-fire fa-fw" },
    { label: "铅笔", value: "fas fa-pencil fa-fw" },
    { label: "扳手", value: "fas fa-wrench fa-fw" },
    { label: "锤", value: "fas fa-hammer fa-fw" },
    { label: "统治者", value: "fas fa-ruler fa-fw" },
    { label: "标尺-垂直", value: "fas fa-ruler-vertical fa-fw" },
    { label: "卡车皮卡", value: "fas fa-truck-pickup fa-fw" },
    { label: "抹子砖", value: "fas fa-trowel-bricks fa-fw" },
    { label: "镘", value: "fas fa-trowel fa-fw" },
    { label: "工具箱", value: "fas fa-toolbox fa-fw" },
    { label: "便携式厕所", value: "fas fa-toilets-portable fa-fw" },
    { label: "便携式厕所", value: "fas fa-toilet-portable fa-fw" },
    { label: "防水布液滴", value: "fas fa-tarp-droplet fa-fw" },
    { label: "防水布", value: "fas fa-tarp fa-fw" },
    { label: "塑料片材", value: "fas fa-sheet-plastic fa-fw" },
    { label: "螺丝刀-扳手", value: "fas fa-screwdriver-wrench fa-fw" },
    { label: "螺丝刀", value: "fas fa-screwdriver fa-fw" },
    { label: "标尺-水平", value: "fas fa-ruler-horizontal fa-fw" },
    { label: "组合标尺", value: "fas fa-ruler-combined fa-fw" },
    { label: "人挖", value: "fas fa-person-digging fa-fw" },
    { label: "钢笔尺", value: "fas fa-pen-ruler fa-fw" },
    { label: "土堆", value: "fas fa-mound fa-fw" },
    { label: "头盔安全", value: "fas fa-helmet-safety fa-fw" },
    { label: "垃圾箱", value: "fas fa-dumpster fa-fw" },
    { label: "指南针制图", value: "fas fa-compass-drafting fa-fw" },
    { label: "桶", value: "fas fa-bucket fa-fw" },
    { label: "钻孔", value: "fas fa-bore-hole fa-fw" },
    {
      label: "从地下水中向上箭头",
      value: "fas fa-arrow-up-from-ground-water fa-fw",
    },
  ],
  能源: [
    { label: "螺栓", value: "fas fa-bolt fa-fw" },
    { label: "火灾", value: "fas fa-fire fa-fw" },
    { label: "太阳", value: "fas fa-sun fa-fw" },
    { label: "太阳", value: "far fa-sun fa-fw" },
    { label: "水", value: "fas fa-water fa-fw" },
    { label: "断电", value: "fas fa-power-off fa-fw" },
    { label: "叶", value: "fas fa-leaf fa-fw" },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "插头", value: "fas fa-plug fa-fw" },
    { label: "风", value: "fas fa-wind fa-fw" },
    { label: "灯泡", value: "fas fa-lightbulb fa-fw" },
    { label: "灯泡", value: "far fa-lightbulb fa-fw" },
    { label: "太阳能电池板", value: "fas fa-solar-panel fa-fw" },
    { label: "工业", value: "fas fa-industry fa-fw" },
    { label: "塔式广播", value: "fas fa-tower-broadcast fa-fw" },
    { label: "辐射", value: "fas fa-radiation fa-fw" },
    { label: "船尾", value: "fas fa-poop fa-fw" },
    { label: "插件圈-xmark", value: "fas fa-plug-circle-xmark fa-fw" },
    {
      label: "plug-circle-plus（插件圈加）",
      value: "fas fa-plug-circle-plus fa-fw",
    },
    {
      label: "plug-circle-minus",
      value: "fas fa-plug-circle-minus fa-fw",
    },
    {
      label: "plug-circle-感叹号",
      value: "fas fa-plug-circle-exclamation fa-fw",
    },
    { label: "插件圈检查", value: "fas fa-plug-circle-check fa-fw" },
    { label: "塞圈螺栓", value: "fas fa-plug-circle-bolt fa-fw" },
    { label: "油井", value: "fas fa-oil-well fa-fw" },
    { label: "气泵", value: "fas fa-gas-pump fa-fw" },
    { label: "火-火焰-简单", value: "fas fa-fire-flame-simple fa-fw" },
    { label: "火-火焰-弯曲", value: "fas fa-fire-flame-curved fa-fw" },
    { label: "扇", value: "fas fa-fan fa-fw" },
    { label: "爆炸", value: "fas fa-explosion fa-fw" },
    { label: "圆形辐射", value: "fas fa-circle-radiation fa-fw" },
    { label: "充电站", value: "fas fa-charging-station fa-fw" },
    { label: "汽车电池", value: "fas fa-car-battery fa-fw" },
    {
      label: "电池-四分之三",
      value: "fas fa-battery-three-quarters fa-fw",
    },
    { label: "电池四分之一", value: "fas fa-battery-quarter fa-fw" },
    { label: "电池半", value: "fas fa-battery-half fa-fw" },
    { label: "电池充满", value: "fas fa-battery-full fa-fw" },
    { label: "电池电量耗尽", value: "fas fa-battery-empty fa-fw" },
    { label: "原子", value: "fas fa-atom fa-fw" },
    {
      label: "从地下水中向上箭头",
      value: "fas fa-arrow-up-from-ground-water fa-fw",
    },
  ],
  人文: [
    { label: "信封", value: "fas fa-envelope fa-fw" },
    { label: "信封", value: "far fa-envelope fa-fw" },
    { label: "文件", value: "fas fa-file fa-fw" },
    { label: "文件", value: "far fa-file fa-fw" },
    { label: "汽车", value: "fas fa-car fa-fw" },
    { label: "小滴", value: "fas fa-droplet fa-fw" },
    { label: "卡车", value: "fas fa-truck fa-fw" },
    { label: "无线网络", value: "fas fa-wifi fa-fw" },
    { label: "人", value: "fas fa-person fa-fw" },
    { label: "人装", value: "fas fa-person-dress fa-fw" },
    { label: "雪花", value: "fas fa-snowflake fa-fw" },
    { label: "雪花", value: "far fa-snowflake fa-fw" },
    { label: "店", value: "fas fa-shop fa-fw" },
    { label: "地标", value: "fas fa-landmark fa-fw" },
    { label: "衬衫", value: "fas fa-shirt fa-fw" },
    { label: "锚", value: "fas fa-anchor fa-fw" },
    { label: "听诊器", value: "fas fa-stethoscope fa-fw" },
    { label: "卡车前部", value: "fas fa-truck-front fa-fw" },
    { label: "山太阳", value: "fas fa-mountain-sun fa-fw" },
    { label: "建筑", value: "fas fa-building fa-fw" },
    { label: "建筑", value: "far fa-building fa-fw" },
    { label: "旗", value: "fas fa-flag fa-fw" },
    { label: "旗", value: "far fa-flag fa-fw" },
    { label: "学校", value: "fas fa-school fa-fw" },
    { label: "笔记本", value: "fas fa-laptop fa-fw" },
    { label: "旅馆", value: "fas fa-hotel fa-fw" },
    { label: "步话机", value: "fas fa-walkie-talkie fa-fw" },
    { label: "淋浴", value: "fas fa-shower fa-fw" },
    { label: "船", value: "fas fa-ship fa-fw" },
    {
      label: "从正方形向右箭头",
      value: "fas fa-arrow-up-right-from-square fa-fw",
    },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "锤", value: "fas fa-hammer fa-fw" },
    { label: "文件-pdf", value: "fas fa-file-pdf fa-fw" },
    { label: "文件-pdf", value: "far fa-file-pdf fa-fw" },
    { label: "医院", value: "fas fa-hospital fa-fw" },
    { label: "医院", value: "far fa-hospital fa-fw" },
    { label: "病毒", value: "fas fa-virus fa-fw" },
    { label: "收音机", value: "fas fa-radio fa-fw" },
    { label: "插头", value: "fas fa-plug fa-fw" },
    { label: "路", value: "fas fa-road fa-fw" },
    { label: "教堂", value: "fas fa-church fa-fw" },
    { label: "肥皂", value: "fas fa-soap fa-fw" },
    { label: "风", value: "fas fa-wind fa-fw" },
    { label: "婴儿", value: "fas fa-baby fa-fw" },
    { label: "人物箭头", value: "fas fa-people-arrows fa-fw" },
    { label: "耳机", value: "fas fa-headset fa-fw" },
    { label: "戈普拉姆", value: "fas fa-gopuram fa-fw" },
    { label: "xmarks-lines", value: "fas fa-xmarks-lines fa-fw" },
    { label: "蠕虫", value: "fas fa-worm fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    {
      label: "小麦-awn-圆-感叹号",
      value: "fas fa-wheat-awn-circle-exclamation fa-fw",
    },
    { label: "小麦-芒", value: "fas fa-wheat-awn fa-fw" },
    { label: "火山", value: "fas fa-volcano fa-fw" },
    { label: "病毒-新冠病毒", value: "fas fa-virus-covid fa-fw" },
    { label: "维哈拉", value: "fas fa-vihara fa-fw" },
    { label: "小瓶病毒", value: "fas fa-vial-virus fa-fw" },
    {
      label: "小瓶-圆圈-检查",
      value: "fas fa-vial-circle-check fa-fw",
    },
    { label: "用户取景器", value: "fas fa-users-viewfinder fa-fw" },
    { label: "users-矩形", value: "fas fa-users-rectangle fa-fw" },
    { label: "用户射线", value: "fas fa-users-rays fa-fw" },
    { label: "用户行", value: "fas fa-users-line fa-fw" },
    { label: "用户行间", value: "fas fa-users-between-lines fa-fw" },
    { label: "用户受伤", value: "fas fa-user-injured fa-fw" },
    { label: "用户医生", value: "fas fa-user-doctor fa-fw" },
    { label: "卡车-飞机", value: "fas fa-truck-plane fa-fw" },
    { label: "卡车-医疗", value: "fas fa-truck-medical fa-fw" },
    { label: "卡车场-联合国", value: "fas fa-truck-field-un fa-fw" },
    { label: "卡车场", value: "fas fa-truck-field fa-fw" },
    { label: "卡车液滴", value: "fas fa-truck-droplet fa-fw" },
    { label: "卡车箭头右", value: "fas fa-truck-arrow-right fa-fw" },
    { label: "抹子砖", value: "fas fa-trowel-bricks fa-fw" },
    { label: "镘", value: "fas fa-trowel fa-fw" },
    { label: "树城", value: "fas fa-tree-city fa-fw" },
    { label: "垃圾桶", value: "fas fa-trash-can fa-fw" },
    { label: "火车-地铁", value: "fas fa-train-subway fa-fw" },
    { label: "塔观察", value: "fas fa-tower-observation fa-fw" },
    { label: "塔式单元", value: "fas fa-tower-cell fa-fw" },
    { label: "塔式广播", value: "fas fa-tower-broadcast fa-fw" },
    { label: "龙卷风", value: "fas fa-tornado fa-fw" },
    { label: "便携式厕所", value: "fas fa-toilets-portable fa-fw" },
    { label: "便携式厕所", value: "fas fa-toilet-portable fa-fw" },
    { label: "厕所", value: "fas fa-toilet fa-fw" },
    { label: "帐篷", value: "fas fa-tents fa-fw" },
    { label: "帐篷箭头向下", value: "fas fa-tent-arrows-down fa-fw" },
    {
      label: "帐篷箭头左转",
      value: "fas fa-tent-arrow-turn-left fa-fw",
    },
    {
      label: "帐篷箭头左右",
      value: "fas fa-tent-arrow-left-right fa-fw",
    },
    {
      label: "tent-arrow-down-to-line",
      value: "fas fa-tent-arrow-down-to-line fa-fw",
    },
    { label: "帐篷", value: "fas fa-tent fa-fw" },
    {
      label: "温度箭头向上",
      value: "fas fa-temperature-arrow-up fa-fw",
    },
    {
      label: "温度向下箭头",
      value: "fas fa-temperature-arrow-down fa-fw",
    },
    { label: "防水布液滴", value: "fas fa-tarp-droplet fa-fw" },
    { label: "防水布", value: "fas fa-tarp fa-fw" },
    { label: "注射器", value: "fas fa-syringe fa-fw" },
    { label: "日晒植物枯萎病", value: "fas fa-sun-plant-wilt fa-fw" },
    { label: "手提箱-医疗", value: "fas fa-suitcase-medical fa-fw" },
    { label: "法杖蛇", value: "fas fa-staff-snake fa-fw" },
    { label: "方形病毒", value: "fas fa-square-virus fa-fw" },
    {
      label: "方形人被限制",
      value: "fas fa-square-person-confined fa-fw",
    },
    { label: "方形-NFI", value: "fas fa-square-nfi fa-fw" },
    { label: "颅骨交叉骨", value: "fas fa-skull-crossbones fa-fw" },
    { label: "店锁", value: "fas fa-shop-lock fa-fw" },
    { label: "盾心", value: "fas fa-shield-heart fa-fw" },
    { label: "盾牌犬", value: "fas fa-shield-dog fa-fw" },
    { label: "盾牌猫", value: "fas fa-shield-cat fa-fw" },
    { label: "塑料片材", value: "fas fa-sheet-plastic fa-fw" },
    { label: "学校锁", value: "fas fa-school-lock fa-fw" },
    { label: "校旗", value: "fas fa-school-flag fa-fw" },
    {
      label: "学校圈子-xmark",
      value: "fas fa-school-circle-xmark fa-fw",
    },
    {
      label: "学校-圈子-感叹号",
      value: "fas fa-school-circle-exclamation fa-fw",
    },
    {
      label: "学校圈子检查",
      value: "fas fa-school-circle-check fa-fw",
    },
    { label: "规模平衡", value: "fas fa-scale-balanced fa-fw" },
    { label: "卫星天线", value: "fas fa-satellite-dish fa-fw" },
    { label: "帆船", value: "fas fa-sailboat fa-fw" },
    { label: "麻袋-xmark", value: "fas fa-sack-xmark fa-fw" },
    { label: "麻袋美元", value: "fas fa-sack-dollar fa-fw" },
    { label: "地毯", value: "fas fa-rug fa-fw" },
    { label: "路钉", value: "fas fa-road-spikes fa-fw" },
    { label: "路锁", value: "fas fa-road-lock fa-fw" },
    { label: "路-圈-xmark", value: "fas fa-road-circle-xmark fa-fw" },
    {
      label: "路-圈-感叹号",
      value: "fas fa-road-circle-exclamation fa-fw",
    },
    { label: "道路-环-检查", value: "fas fa-road-circle-check fa-fw" },
    { label: "车行桥", value: "fas fa-road-bridge fa-fw" },
    { label: "道路护栏", value: "fas fa-road-barrier fa-fw" },
    { label: "排名之星", value: "fas fa-ranking-star fa-fw" },
    { label: "辐射", value: "fas fa-radiation fa-fw" },
    { label: "泵-肥皂", value: "fas fa-pump-soap fa-fw" },
    { label: "插件圈-xmark", value: "fas fa-plug-circle-xmark fa-fw" },
    {
      label: "plug-circle-plus（插件圈加）",
      value: "fas fa-plug-circle-plus fa-fw",
    },
    {
      label: "plug-circle-minus",
      value: "fas fa-plug-circle-minus fa-fw",
    },
    {
      label: "plug-circle-感叹号",
      value: "fas fa-plug-circle-exclamation fa-fw",
    },
    { label: "插件圈检查", value: "fas fa-plug-circle-check fa-fw" },
    { label: "塞圈螺栓", value: "fas fa-plug-circle-bolt fa-fw" },
    { label: "平板小麦", value: "fas fa-plate-wheat fa-fw" },
    { label: "植物枯萎病", value: "fas fa-plant-wilt fa-fw" },
    { label: "平面上升", value: "fas fa-plane-up fa-fw" },
    { label: "平面锁", value: "fas fa-plane-lock fa-fw" },
    {
      label: "平面-圆-xmark",
      value: "fas fa-plane-circle-xmark fa-fw",
    },
    {
      label: "平面-圆-感叹号",
      value: "fas fa-plane-circle-exclamation fa-fw",
    },
    { label: "平面圆检查", value: "fas fa-plane-circle-check fa-fw" },
    { label: "药", value: "fas fa-pills fa-fw" },
    {
      label: "步行行李",
      value: "fas fa-person-walking-luggage fa-fw",
    },
    {
      label: "person-walking-虚线-arrow-right",
      value: "fas fa-person-walking-dashed-line-arrow-right fa-fw",
    },
    {
      label: "人走箭头右",
      value: "fas fa-person-walking-arrow-right fa-fw",
    },
    {
      label: "人走路箭头环左",
      value: "fas fa-person-walking-arrow-loop-left fa-fw",
    },
    { label: "人行", value: "fas fa-person-walking fa-fw" },
    { label: "人窗式", value: "fas fa-person-through-window fa-fw" },
    { label: "个人庇护所", value: "fas fa-person-shelter fa-fw" },
    { label: "人-步枪", value: "fas fa-person-rifle fa-fw" },
    { label: "人体射线", value: "fas fa-person-rays fa-fw" },
    { label: "怀孕的人", value: "fas fa-person-pregnant fa-fw" },
    {
      label: "人-军人-人",
      value: "fas fa-person-military-to-person fa-fw",
    },
    {
      label: "人-军-步枪",
      value: "fas fa-person-military-rifle fa-fw",
    },
    {
      label: "人-军事-指向",
      value: "fas fa-person-military-pointing fa-fw",
    },
    { label: "人身骚扰", value: "fas fa-person-harassing fa-fw" },
    { label: "人半连衣裙", value: "fas fa-person-half-dress fa-fw" },
    {
      label: "人坠落爆发",
      value: "fas fa-person-falling-burst fa-fw",
    },
    { label: "人跌倒", value: "fas fa-person-falling fa-fw" },
    { label: "溺水者", value: "fas fa-person-drowning fa-fw" },
    {
      label: "人-着装-爆裂",
      value: "fas fa-person-dress-burst fa-fw",
    },
    { label: "人挖", value: "fas fa-person-digging fa-fw" },
    {
      label: "人-圈-xmark",
      value: "fas fa-person-circle-xmark fa-fw",
    },
    {
      label: "人-圈-问题",
      value: "fas fa-person-circle-question fa-fw",
    },
    { label: "人圈加", value: "fas fa-person-circle-plus fa-fw" },
    { label: "人圈减号", value: "fas fa-person-circle-minus fa-fw" },
    {
      label: "人-圆-感叹号",
      value: "fas fa-person-circle-exclamation fa-fw",
    },
    {
      label: "人员-圈子-检查",
      value: "fas fa-person-circle-check fa-fw",
    },
    { label: "人-黑板", value: "fas fa-person-chalkboard fa-fw" },
    { label: "人-手杖", value: "fas fa-person-cane fa-fw" },
    { label: "人突发", value: "fas fa-person-burst fa-fw" },
    { label: "母乳喂养", value: "fas fa-person-breastfeeding fa-fw" },
    {
      label: "person-arrow-up-from-line",
      value: "fas fa-person-arrow-up-from-line fa-fw",
    },
    {
      label: "person-arrow-down-to-line",
      value: "fas fa-person-arrow-down-to-line fa-fw",
    },
    { label: "人顶", value: "fas fa-people-roof fa-fw" },
    { label: "抢劫人口", value: "fas fa-people-robbery fa-fw" },
    { label: "拉人", value: "fas fa-people-pulling fa-fw" },
    { label: "人行", value: "fas fa-people-line fa-fw" },
    { label: "人员组", value: "fas fa-people-group fa-fw" },
    { label: "降落伞箱", value: "fas fa-parachute-box fa-fw" },
    { label: "油井", value: "fas fa-oil-well fa-fw" },
    { label: "山城", value: "fas fa-mountain-city fa-fw" },
    { label: "土堆", value: "fas fa-mound fa-fw" },
    { label: "蚊帐", value: "fas fa-mosquito-net fa-fw" },
    { label: "蚊子", value: "fas fa-mosquito fa-fw" },
    { label: "清真寺", value: "fas fa-mosque fa-fw" },
    { label: "钞票", value: "fas fa-money-bills fa-fw" },
    { label: "钞票-小麦", value: "fas fa-money-bill-wheat fa-fw" },
    {
      label: "货币-钞票-趋势-上升",
      value: "fas fa-money-bill-trend-up fa-fw",
    },
    { label: "汇票转账", value: "fas fa-money-bill-transfer fa-fw" },
    { label: "手机屏幕", value: "fas fa-mobile-screen fa-fw" },
    { label: "手机复古", value: "fas fa-mobile-retro fa-fw" },
    { label: "显微镜", value: "fas fa-microscope fa-fw" },
    { label: "床垫枕头", value: "fas fa-mattress-pillow fa-fw" },
    { label: "面罩-呼吸机", value: "fas fa-mask-ventilator fa-fw" },
    { label: "面膜-脸部", value: "fas fa-mask-face fa-fw" },
    {
      label: "火星和金星爆发",
      value: "fas fa-mars-and-venus-burst fa-fw",
    },
    { label: "火星和金星", value: "fas fa-mars-and-venus fa-fw" },
    {
      label: "放大镜图",
      value: "fas fa-magnifying-glass-chart fa-fw",
    },
    {
      label: "放大镜右箭头",
      value: "fas fa-magnifying-glass-arrow-right fa-fw",
    },
    { label: "肺", value: "fas fa-lungs fa-fw" },
    { label: "蝗", value: "fas fa-locust fa-fw" },
    { label: "位置密码锁定", value: "fas fa-location-pin-lock fa-fw" },
    { label: "线条倾斜", value: "fas fa-lines-leaning fa-fw" },
    { label: "救生圈", value: "fas fa-life-ring fa-fw" },
    { label: "laptop-file", value: "fas fa-laptop-file fa-fw" },
    { label: "地标旗帜", value: "fas fa-landmark-flag fa-fw" },
    { label: "地标穹顶", value: "fas fa-landmark-dome fa-fw" },
    { label: "地雷-ON", value: "fas fa-land-mine-on fa-fw" },
    { label: "厨房套装", value: "fas fa-kitchen-set fa-fw" },
    { label: "水壶洗涤剂", value: "fas fa-jug-detergent fa-fw" },
    { label: "喷气式战斗机", value: "fas fa-jet-fighter-up fa-fw" },
    { label: "罐装小麦", value: "fas fa-jar-wheat fa-fw" },
    { label: "罐", value: "fas fa-jar fa-fw" },
    { label: "身份证", value: "fas fa-id-card fa-fw" },
    { label: "飓风", value: "fas fa-hurricane fa-fw" },
    { label: "房屋海啸", value: "fas fa-house-tsunami fa-fw" },
    { label: "房屋信号", value: "fas fa-house-signal fa-fw" },
    {
      label: "房屋-医疗-旗帜",
      value: "fas fa-house-medical-flag fa-fw",
    },
    {
      label: "删除医疗房子",
      value: "fas fa-house-medical-circle-xmark fa-fw",
    },
    {
      label: "感叹号",
      value: "fas fa-house-medical-circle-exclamation fa-fw",
    },
    {
      label: "选择房子",
      value: "fas fa-house-medical-circle-check fa-fw",
    },
    { label: "家庭医疗", value: "fas fa-house-medical fa-fw" },
    { label: "房屋锁", value: "fas fa-house-lock fa-fw" },
    {
      label: "房屋-洪水-水-圆-箭头-右",
      value: "fas fa-house-flood-water-circle-arrow-right fa-fw",
    },
    { label: "房屋-洪水-水", value: "fas fa-house-flood-water fa-fw" },
    { label: "家旗", value: "fas fa-house-flag fa-fw" },
    { label: "房屋火灾", value: "fas fa-house-fire fa-fw" },
    {
      label: "房子圈子-xmark",
      value: "fas fa-house-circle-xmark fa-fw",
    },
    {
      label: "house-circle-感叹号",
      value: "fas fa-house-circle-exclamation fa-fw",
    },
    {
      label: "房子圈子检查",
      value: "fas fa-house-circle-check fa-fw",
    },
    {
      label: "房屋烟囱裂缝",
      value: "fas fa-house-chimney-crack fa-fw",
    },
    { label: "房屋烟囱", value: "fas fa-house-chimney fa-fw" },
    { label: "山体滑坡", value: "fas fa-hill-rockslide fa-fw" },
    { label: "山崩", value: "fas fa-hill-avalanche fa-fw" },
    { label: "头盔-联合国", value: "fas fa-helmet-un fa-fw" },
    { label: "直升机符号", value: "fas fa-helicopter-symbol fa-fw" },
    { label: "直升机", value: "fas fa-helicopter fa-fw" },
    {
      label: "心形-圆圈-xmark",
      value: "fas fa-heart-circle-xmark fa-fw",
    },
    { label: "心形圆加", value: "fas fa-heart-circle-plus fa-fw" },
    { label: "心形圆减号", value: "fas fa-heart-circle-minus fa-fw" },
    {
      label: "心-圈-感叹",
      value: "fas fa-heart-circle-exclamation fa-fw",
    },
    { label: "心形检查", value: "fas fa-heart-circle-check fa-fw" },
    { label: "心形圆圈螺栓", value: "fas fa-heart-circle-bolt fa-fw" },
    { label: "握手-简单", value: "fas fa-handshake-simple fa-fw" },
    { label: "手牵手圈", value: "fas fa-hands-holding-circle fa-fw" },
    {
      label: "手拉手-儿童",
      value: "fas fa-hands-holding-child fa-fw",
    },
    { label: "手泡", value: "fas fa-hands-bubbles fa-fw" },
    { label: "双手束缚", value: "fas fa-hands-bound fa-fw" },
    { label: "手铐", value: "fas fa-handcuffs fa-fw" },
    { label: "手牵手", value: "fas fa-hand-holding-hand fa-fw" },
    {
      label: "group-arrows-rotate",
      value: "fas fa-group-arrows-rotate fa-fw",
    },
    { label: "玻璃水滴", value: "fas fa-glass-water-droplet fa-fw" },
    { label: "玻璃-水", value: "fas fa-glass-water fa-fw" },
    { label: "气泵", value: "fas fa-gas-pump fa-fw" },
    { label: "烧瓶-小瓶", value: "fas fa-flask-vial fa-fw" },
    { label: "鱼翅", value: "fas fa-fish-fins fa-fw" },
    { label: "火-火焰-简单", value: "fas fa-fire-flame-simple fa-fw" },
    { label: "燃烧器", value: "fas fa-fire-burner fa-fw" },
    { label: "文件盾", value: "fas fa-file-shield fa-fw" },
    { label: "文件笔", value: "fas fa-file-pen fa-fw" },
    { label: "文件-CSV", value: "fas fa-file-csv fa-fw" },
    {
      label: "文件-circle-xmark",
      value: "fas fa-file-circle-xmark fa-fw",
    },
    {
      label: "文件圈问题",
      value: "fas fa-file-circle-question fa-fw",
    },
    { label: "文件圈加", value: "fas fa-file-circle-plus fa-fw" },
    { label: "文件-圆-减号", value: "fas fa-file-circle-minus fa-fw" },
    {
      label: "文件-圆-感叹号",
      value: "fas fa-file-circle-exclamation fa-fw",
    },
    { label: "文件圈检查", value: "fas fa-file-circle-check fa-fw" },
    { label: "渡轮", value: "fas fa-ferry fa-fw" },
    { label: "传真", value: "fas fa-fax fa-fw" },
    { label: "水龙头滴水", value: "fas fa-faucet-drip fa-fw" },
    { label: "爆炸", value: "fas fa-explosion fa-fw" },
    {
      label: "信封-圆-检查",
      value: "fas fa-envelope-circle-check fa-fw",
    },
    { label: "显示", value: "fas fa-display fa-fw" },
    { label: "立方体堆叠", value: "fas fa-cubes-stacked fa-fw" },
    { label: "牛", value: "fas fa-cow fa-fw" },
    { label: "计算机", value: "fas fa-computer fa-fw" },
    { label: "云-阵雨-水", value: "fas fa-cloud-showers-water fa-fw" },
    { label: "多云阵雨", value: "fas fa-cloud-showers-heavy fa-fw" },
    { label: "云-螺栓", value: "fas fa-cloud-bolt fa-fw" },
    { label: "剪贴板用户", value: "fas fa-clipboard-user fa-fw" },
    { label: "剪贴板问题", value: "fas fa-clipboard-question fa-fw" },
    { label: "圆节点", value: "fas fa-circle-nodes fa-fw" },
    { label: "圆圈-H", value: "fas fa-circle-h fa-fw" },
    { label: "孩子", value: "fas fa-children fa-fw" },
    { label: "儿童战斗员", value: "fas fa-child-combatant fa-fw" },
    { label: "汽车隧道", value: "fas fa-car-tunnel fa-fw" },
    { label: "上车", value: "fas fa-car-on fa-fw" },
    { label: "巴士", value: "fas fa-bus fa-fw" },
    { label: "爆裂", value: "fas fa-burst fa-fw" },
    { label: "建筑小麦", value: "fas fa-building-wheat fa-fw" },
    {
      label: "building-user（建筑用户）",
      value: "fas fa-building-user fa-fw",
    },
    { label: "建筑-联合国", value: "fas fa-building-un fa-fw" },
    { label: "建筑盾构", value: "fas fa-building-shield fa-fw" },
    { label: "建筑-NGO", value: "fas fa-building-ngo fa-fw" },
    { label: "楼宇锁", value: "fas fa-building-lock fa-fw" },
    { label: "building-flag", value: "fas fa-building-flag fa-fw" },
    {
      label: "building-columns",
      value: "fas fa-building-columns fa-fw",
    },
    {
      label: "删除大楼",
      value: "fas fa-building-circle-xmark fa-fw",
    },
    {
      label: "建筑-圆-感叹号",
      value: "fas fa-building-circle-exclamation fa-fw",
    },
    {
      label: "建筑-圆-检查",
      value: "fas fa-building-circle-check fa-fw",
    },
    {
      label: "建筑-圆-箭头-右",
      value: "fas fa-building-circle-arrow-right fa-fw",
    },
    { label: "错误", value: "fas fa-bugs fa-fw" },
    { label: "桶", value: "fas fa-bucket fa-fw" },
    { label: "桥水", value: "fas fa-bridge-water fa-fw" },
    { label: "桥锁", value: "fas fa-bridge-lock fa-fw" },
    {
      label: "桥-圈-xmark",
      value: "fas fa-bridge-circle-xmark fa-fw",
    },
    {
      label: "桥-圆-感叹号",
      value: "fas fa-bridge-circle-exclamation fa-fw",
    },
    { label: "桥圈检查", value: "fas fa-bridge-circle-check fa-fw" },
    { label: "桥", value: "fas fa-bridge fa-fw" },
    { label: "箱装", value: "fas fa-boxes-packing fa-fw" },
    { label: "碗饭", value: "fas fa-bowl-rice fa-fw" },
    { label: "碗状食物", value: "fas fa-bowl-food fa-fw" },
    { label: "瓶装水", value: "fas fa-bottle-water fa-fw" },
    { label: "瓶滴", value: "fas fa-bottle-droplet fa-fw" },
    { label: "钻孔", value: "fas fa-bore-hole fa-fw" },
    { label: "书签", value: "fas fa-book-bookmark fa-fw" },
    { label: "生物危害", value: "fas fa-biohazard fa-fw" },
    { label: "床", value: "fas fa-bed fa-fw" },
    { label: "禁止", value: "fas fa-ban fa-fw" },
    { label: "细菌", value: "fas fa-bacterium fa-fw" },
    { label: "向上箭头", value: "fas fa-arrows-up-to-line fa-fw" },
    { label: "箭头转为点", value: "fas fa-arrows-turn-to-dots fa-fw" },
    { label: "箭头-右转", value: "fas fa-arrows-turn-right fa-fw" },
    { label: "箭头到眼睛", value: "fas fa-arrows-to-eye fa-fw" },
    { label: "箭头到点", value: "fas fa-arrows-to-dot fa-fw" },
    { label: "箭头到圆圈", value: "fas fa-arrows-to-circle fa-fw" },
    {
      label: "arrows-split-up-and-left",
      value: "fas fa-arrows-split-up-and-left fa-fw",
    },
    { label: "箭头旋转", value: "fas fa-arrows-spin fa-fw" },
    {
      label: "arrows-left-right-to-line",
      value: "fas fa-arrows-left-right-to-line fa-fw",
    },
    {
      label: "箭头-向下到人",
      value: "fas fa-arrows-down-to-people fa-fw",
    },
    {
      label: "向下到线的箭头",
      value: "fas fa-arrows-down-to-line fa-fw",
    },
    {
      label: "向上向右箭头点",
      value: "fas fa-arrow-up-right-dots fa-fw",
    },
    {
      label: "从水泵向上箭头",
      value: "fas fa-arrow-up-from-water-pump fa-fw",
    },
    {
      label: "从地下水中向上箭头",
      value: "fas fa-arrow-up-from-ground-water fa-fw",
    },
    {
      label: "向右箭头到城市",
      value: "fas fa-arrow-right-to-city fa-fw",
    },
    {
      label: "向下箭头向上锁定",
      value: "fas fa-arrow-down-up-lock fa-fw",
    },
    {
      label: "向下箭头向上跨线",
      value: "fas fa-arrow-down-up-across-line fa-fw",
    },
    { label: "锚锁", value: "fas fa-anchor-lock fa-fw" },
    {
      label: "锚-圆-xmark",
      value: "fas fa-anchor-circle-xmark fa-fw",
    },
    {
      label: "锚-圆-感叹号",
      value: "fas fa-anchor-circle-exclamation fa-fw",
    },
    { label: "锚-圆-检查", value: "fas fa-anchor-circle-check fa-fw" },
    { label: "垃圾桶", value: "far fa-trash-can fa-fw" },
    { label: "救生圈", value: "far fa-life-ring fa-fw" },
    { label: "身份证", value: "far fa-id-card fa-fw" },
  ],
  后勤和物流: [
    { label: "卡车快速", value: "fas fa-truck-fast fa-fw" },
    { label: "汽车", value: "fas fa-car fa-fw" },
    { label: "齿轮", value: "fas fa-gears fa-fw" },
    { label: "卡车", value: "fas fa-truck fa-fw" },
    { label: "chart-simple", value: "fas fa-chart-simple fa-fw" },
    { label: "anchor", value: "fas fa-anchor fa-fw" },
    { label: "truck-front", value: "fas fa-truck-front fa-fw" },
    { label: "train", value: "fas fa-train fa-fw" },
    { label: "road", value: "fas fa-road fa-fw" },
    { label: "warehouse", value: "fas fa-warehouse fa-fw" },
    { label: "xmarks-lines", value: "fas fa-xmarks-lines fa-fw" },
    { label: "truck-plane", value: "fas fa-truck-plane fa-fw" },
    { label: "truck-field-un", value: "fas fa-truck-field-un fa-fw" },
    { label: "truck-field", value: "fas fa-truck-field fa-fw" },
    {
      label: "truck-arrow-right",
      value: "fas fa-truck-arrow-right fa-fw",
    },
    { label: "train-subway", value: "fas fa-train-subway fa-fw" },
    { label: "square-nfi", value: "fas fa-square-nfi fa-fw" },
    { label: "sailboat", value: "fas fa-sailboat fa-fw" },
    { label: "road-lock", value: "fas fa-road-lock fa-fw" },
    {
      label: "road-circle-xmark",
      value: "fas fa-road-circle-xmark fa-fw",
    },
    {
      label: "road-circle-exclamation",
      value: "fas fa-road-circle-exclamation fa-fw",
    },
    {
      label: "road-circle-check",
      value: "fas fa-road-circle-check fa-fw",
    },
    { label: "road-bridge", value: "fas fa-road-bridge fa-fw" },
    { label: "road-barrier", value: "fas fa-road-barrier fa-fw" },
    { label: "plane-lock", value: "fas fa-plane-lock fa-fw" },
    {
      label: "plane-circle-xmark",
      value: "fas fa-plane-circle-xmark fa-fw",
    },
    {
      label: "plane-circle-exclamation",
      value: "fas fa-plane-circle-exclamation fa-fw",
    },
    {
      label: "plane-circle-check",
      value: "fas fa-plane-circle-check fa-fw",
    },
    { label: "pallet", value: "fas fa-pallet fa-fw" },
    { label: "jet-fighter-up", value: "fas fa-jet-fighter-up fa-fw" },
    { label: "头盔安全", value: "fas fa-helmet-safety fa-fw" },
    { label: "直升机符号", value: "fas fa-helicopter-symbol fa-fw" },
    { label: "直升机", value: "fas fa-helicopter fa-fw" },
    { label: "气泵", value: "fas fa-gas-pump fa-fw" },
    { label: "渡轮", value: "fas fa-ferry fa-fw" },
    { label: "洋娃娃", value: "fas fa-dolly fa-fw" },
    { label: "剪贴板问题", value: "fas fa-clipboard-question fa-fw" },
    { label: "剪贴板列表", value: "fas fa-clipboard-list fa-fw" },
    { label: "剪贴板检查", value: "fas fa-clipboard-check fa-fw" },
    { label: "推车平板", value: "fas fa-cart-flatbed fa-fw" },
    { label: "汽车隧道", value: "fas fa-car-tunnel fa-fw" },
    { label: "巴士", value: "fas fa-bus-simple fa-fw" },
    { label: "巴士", value: "fas fa-bus fa-fw" },
    { label: "桥水", value: "fas fa-bridge-water fa-fw" },
    { label: "桥锁", value: "fas fa-bridge-lock fa-fw" },
    {
      label: "桥-圈-xmark",
      value: "fas fa-bridge-circle-xmark fa-fw",
    },
    {
      label: "桥-圆-感叹号",
      value: "fas fa-bridge-circle-exclamation fa-fw",
    },
    { label: "桥圈检查", value: "fas fa-bridge-circle-check fa-fw" },
    { label: "桥", value: "fas fa-bridge fa-fw" },
    { label: "箱子堆叠", value: "fas fa-boxes-stacked fa-fw" },
    { label: "箱装", value: "fas fa-boxes-packing fa-fw" },
    { label: "箱", value: "fas fa-box fa-fw" },
    { label: "锚锁", value: "fas fa-anchor-lock fa-fw" },
    {
      label: "锚-圆-xmark",
      value: "fas fa-anchor-circle-xmark fa-fw",
    },
    {
      label: "锚-圆-感叹号",
      value: "fas fa-anchor-circle-exclamation fa-fw",
    },
    { label: "锚-圆-检查", value: "fas fa-anchor-circle-check fa-fw" },
  ],
  地图: [
    { label: "房子", value: "fas fa-house fa-fw" },
    { label: "放大镜", value: "fas fa-magnifying-glass fa-fw" },
    { label: "图像", value: "fas fa-image fa-fw" },
    { label: "图像", value: "far fa-image fa-fw" },
    { label: "电话", value: "fas fa-phone fa-fw" },
    { label: "位置点", value: "fas fa-location-dot fa-fw" },
    { label: "音乐", value: "fas fa-music fa-fw" },
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "炸弹", value: "fas fa-bomb fa-fw" },
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "购物车购物", value: "fas fa-cart-shopping fa-fw" },
    { label: "圈子信息", value: "fas fa-circle-info fa-fw" },
    { label: "汽车", value: "fas fa-car fa-fw" },
    { label: "伞", value: "fas fa-umbrella fa-fw" },
    { label: "礼物", value: "fas fa-gift fa-fw" },
    { label: "标记", value: "fas fa-tag fa-fw" },
    { label: "书", value: "fas fa-book fa-fw" },
    { label: "书签", value: "fas fa-bookmark fa-fw" },
    { label: "书签", value: "far fa-bookmark fa-fw" },
    { label: "打印", value: "fas fa-print fa-fw" },
    { label: "小滴", value: "fas fa-droplet fa-fw" },
    { label: "加", value: "fas fa-plus fa-fw" },
    { label: "火灾", value: "fas fa-fire fa-fw" },
    { label: "眼睛", value: "fas fa-eye fa-fw" },
    { label: "眼睛", value: "far fa-eye fa-fw" },
    { label: "斜线", value: "fas fa-eye-slash fa-fw" },
    { label: "斜线", value: "far fa-eye-slash fa-fw" },
    { label: "飞机", value: "fas fa-plane fa-fw" },
    { label: "磁铁", value: "fas fa-magnet fa-fw" },
    { label: "钞票", value: "fas fa-money-bill fa-fw" },
    { label: "柠檬", value: "fas fa-lemon fa-fw" },
    { label: "柠檬", value: "far fa-lemon fa-fw" },
    { label: "钥匙", value: "fas fa-key fa-fw" },
    { label: "图钉", value: "fas fa-thumbtack fa-fw" },
    { label: "球", value: "fas fa-globe fa-fw" },
    { label: "卡车", value: "fas fa-truck fa-fw" },
    { label: "票", value: "fas fa-ticket fa-fw" },
    { label: "树", value: "fas fa-tree fa-fw" },
    { label: "无线网络", value: "fas fa-wifi fa-fw" },
    { label: "自行车", value: "fas fa-bicycle fa-fw" },
    { label: "瓶", value: "fas fa-flask fa-fw" },
    { label: "公文包", value: "fas fa-briefcase fa-fw" },
    { label: "人", value: "fas fa-person fa-fw" },
    { label: "浴", value: "fas fa-bath fa-fw" },
    { label: "望远镜", value: "fas fa-binoculars fa-fw" },
    { label: "图层组", value: "fas fa-layer-group fa-fw" },
    { label: "游戏手柄", value: "fas fa-gamepad fa-fw" },
    { label: "马克杯碟", value: "fas fa-mug-saucer fa-fw" },
    { label: "地标", value: "fas fa-landmark fa-fw" },
    { label: "锚", value: "fas fa-anchor fa-fw" },
    { label: "购物袋", value: "fas fa-bag-shopping fa-fw" },
    { label: "山太阳", value: "fas fa-mountain-sun fa-fw" },
    { label: "位置引脚", value: "fas fa-location-pin fa-fw" },
    { label: "信息", value: "fas fa-info fa-fw" },
    { label: "电话音量", value: "fas fa-phone-volume fa-fw" },
    { label: "街景", value: "fas fa-street-view fa-fw" },
    { label: "报纸", value: "fas fa-newspaper fa-fw" },
    { label: "报纸", value: "far fa-newspaper fa-fw" },
    { label: "建筑", value: "fas fa-building fa-fw" },
    { label: "建筑", value: "far fa-building fa-fw" },
    { label: "旗", value: "fas fa-flag fa-fw" },
    { label: "旗", value: "far fa-flag fa-fw" },
    { label: "标志方格", value: "fas fa-flag-checkered fa-fw" },
    { label: "标签", value: "fas fa-tags fa-fw" },
    { label: "火箭", value: "fas fa-rocket fa-fw" },
    { label: "电传机", value: "fas fa-tty fa-fw" },
    { label: "厕所", value: "fas fa-restroom fa-fw" },
    { label: "叶", value: "fas fa-leaf fa-fw" },
    { label: "淋浴", value: "fas fa-shower fa-fw" },
    { label: "船", value: "fas fa-ship fa-fw" },
    { label: "摩托车", value: "fas fa-motorcycle fa-fw" },
    { label: "火车", value: "fas fa-train fa-fw" },
    { label: "扳手", value: "fas fa-wrench fa-fw" },
    { label: "奖杯", value: "fas fa-trophy fa-fw" },
    { label: "医院", value: "fas fa-hospital fa-fw" },
    { label: "医院", value: "far fa-hospital fa-fw" },
    { label: "路线", value: "fas fa-route fa-fw" },
    { label: "插头", value: "fas fa-plug fa-fw" },
    { label: "路", value: "fas fa-road fa-fw" },
    { label: "十字准线", value: "fas fa-crosshairs fa-fw" },
    { label: "回收", value: "fas fa-recycle fa-fw" },
    { label: "灯泡", value: "fas fa-lightbulb fa-fw" },
    { label: "灯泡", value: "far fa-lightbulb fa-fw" },
    { label: "美元符号", value: "fas fa-dollar-sign fa-fw" },
    { label: "图像", value: "fas fa-images fa-fw" },
    { label: "图像", value: "far fa-images fa-fw" },
    { label: "工业", value: "fas fa-industry fa-fw" },
    { label: "酒杯", value: "fas fa-wine-glass fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    { label: "轮椅", value: "fas fa-wheelchair fa-fw" },
    { label: "背心贴片", value: "fas fa-vest-patches fa-fw" },
    { label: "背心", value: "fas fa-vest fa-fw" },
    { label: "用具", value: "fas fa-utensils fa-fw" },
    { label: "卡车-医疗", value: "fas fa-truck-medical fa-fw" },
    { label: "电车-有轨电车", value: "fas fa-train-tram fa-fw" },
    { label: "火车-地铁", value: "fas fa-train-subway fa-fw" },
    { label: "红绿灯", value: "fas fa-traffic-light fa-fw" },
    { label: "门票简单", value: "fas fa-ticket-simple fa-fw" },
    { label: "出租车", value: "fas fa-taxi fa-fw" },
    { label: "手提箱-医疗", value: "fas fa-suitcase-medical fa-fw" },
    { label: "手提箱", value: "fas fa-suitcase fa-fw" },
    { label: "方形加", value: "fas fa-square-plus fa-fw" },
    { label: "方形手机翻转", value: "fas fa-square-phone-flip fa-fw" },
    { label: "方形电话", value: "fas fa-square-phone fa-fw" },
    { label: "方形停车场", value: "fas fa-square-parking fa-fw" },
    { label: "平方-H", value: "fas fa-square-h fa-fw" },
    { label: "勺子", value: "fas fa-spoon fa-fw" },
    { label: "扫雪机", value: "fas fa-snowplow fa-fw" },
    { label: "路标柱", value: "fas fa-signs-post fa-fw" },
    { label: "鞋印", value: "fas fa-shoe-prints fa-fw" },
    {
      label: "缩放-不平衡-翻转",
      value: "fas fa-scale-unbalanced-flip fa-fw",
    },
    { label: "规模不平衡", value: "fas fa-scale-unbalanced fa-fw" },
    { label: "规模平衡", value: "fas fa-scale-balanced fa-fw" },
    { label: "手机翻转", value: "fas fa-phone-flip fa-fw" },
    {
      label: "拄着拐杖走路的人",
      value: "fas fa-person-walking-with-cane fa-fw",
    },
    { label: "爪子", value: "fas fa-paw fa-fw" },
    { label: "纪念碑", value: "fas fa-monument fa-fw" },
    { label: "钞票-1", value: "fas fa-money-bill-1 fa-fw" },
    {
      label: "马提尼酒杯空",
      value: "fas fa-martini-glass-empty fa-fw",
    },
    { label: "地图引脚", value: "fas fa-map-pin fa-fw" },
    { label: "地图", value: "fas fa-map fa-fw" },
    {
      label: "放大镜-plus",
      value: "fas fa-magnifying-glass-plus fa-fw",
    },
    {
      label: "放大镜-减",
      value: "fas fa-magnifying-glass-minus fa-fw",
    },
    {
      label: "放大镜位置",
      value: "fas fa-magnifying-glass-location fa-fw",
    },
    { label: "位置密码锁定", value: "fas fa-location-pin-lock fa-fw" },
    {
      label: "位置十字准线",
      value: "fas fa-location-crosshairs fa-fw",
    },
    { label: "位置箭头", value: "fas fa-location-arrow fa-fw" },
    { label: "救生圈", value: "fas fa-life-ring fa-fw" },
    { label: "地标旗帜", value: "fas fa-landmark-flag fa-fw" },
    { label: "喷气式战斗机", value: "fas fa-jet-fighter fa-fw" },
    { label: "直升机符号", value: "fas fa-helicopter-symbol fa-fw" },
    { label: "直升机", value: "fas fa-helicopter fa-fw" },
    { label: "心跳", value: "fas fa-heart-pulse fa-fw" },
    { label: "毕业帽", value: "fas fa-graduation-cap fa-fw" },
    { label: "木 槌", value: "fas fa-gavel fa-fw" },
    { label: "火-火焰-弯曲", value: "fas fa-fire-flame-curved fa-fw" },
    { label: "灭火器", value: "fas fa-fire-extinguisher fa-fw" },
    { label: "眼部视力低下", value: "fas fa-eye-low-vision fa-fw" },
    { label: "绘制多边形", value: "fas fa-draw-polygon fa-fw" },
    { label: "菱形右转", value: "fas fa-diamond-turn-right fa-fw" },
    { label: "蛋糕蜡烛", value: "fas fa-cake-candles fa-fw" },
    { label: "建筑柱", value: "fas fa-building-columns fa-fw" },
    { label: "桥水", value: "fas fa-bridge-water fa-fw" },
    { label: "桥", value: "fas fa-bridge fa-fw" },
    { label: "书本-阿特拉斯", value: "fas fa-book-atlas fa-fw" },
    { label: "铃铛斜杠", value: "fas fa-bell-slash fa-fw" },
    { label: "啤酒杯空的", value: "fas fa-beer-mug-empty fa-fw" },
    { label: "床", value: "fas fa-bed fa-fw" },
    { label: "购物篮购物", value: "fas fa-basket-shopping fa-fw" },
    { label: "方形加", value: "far fa-square-plus fa-fw" },
    { label: "钞票-1", value: "far fa-money-bill-1 fa-fw" },
    { label: "地图", value: "far fa-map fa-fw" },
    { label: "救生圈", value: "far fa-life-ring fa-fw" },
    { label: "铃铛斜杠", value: "far fa-bell-slash fa-fw" },
  ],

  市场营销: [
    { label: "图表简单", value: "fas fa-chart-simple fa-fw" },
    { label: "靶心", value: "fas fa-bullseye fa-fw" },
    { label: "评论-美元", value: "fas fa-comments-dollar fa-fw" },
    { label: "灯泡", value: "fas fa-lightbulb fa-fw" },
    { label: "灯泡", value: "far fa-lightbulb fa-fw" },
    { label: "时间线", value: "fas fa-timeline fa-fw" },
    {
      label: "方形-轮询-垂直",
      value: "fas fa-square-poll-vertical fa-fw",
    },
    {
      label: "方形-轮询-水平",
      value: "fas fa-square-poll-horizontal fa-fw",
    },
    { label: "矩形广告", value: "fas fa-rectangle-ad fa-fw" },
    { label: "排名之星", value: "fas fa-ranking-star fa-fw" },
    { label: "人体射线", value: "fas fa-person-rays fa-fw" },
    { label: "人员组", value: "fas fa-people-group fa-fw" },
    {
      label: "放大镜位置",
      value: "fas fa-magnifying-glass-location fa-fw",
    },
    {
      label: "放大镜美元",
      value: "fas fa-magnifying-glass-dollar fa-fw",
    },
    {
      label: "放大镜图",
      value: "fas fa-magnifying-glass-chart fa-fw",
    },
    {
      label: "放大镜右箭头",
      value: "fas fa-magnifying-glass-arrow-right fa-fw",
    },
    {
      label: "group-arrows-rotate",
      value: "fas fa-group-arrows-rotate fa-fw",
    },
    {
      label: "filter-circle-dollar",
      value: "fas fa-filter-circle-dollar fa-fw",
    },
    { label: "散装信封", value: "fas fa-envelopes-bulk fa-fw" },
    {
      label: "信封打开文本",
      value: "fas fa-envelope-open-text fa-fw",
    },
    { label: "评论美元", value: "fas fa-comment-dollar fa-fw" },
    { label: "扩音器", value: "fas fa-bullhorn fa-fw" },
    { label: "箭头到眼睛", value: "fas fa-arrows-to-eye fa-fw" },
    { label: "箭头到点", value: "fas fa-arrows-to-dot fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-spin fa-fw" },
  ],
  金钱: [
    { label: "钞票", value: "fas fa-money-bill fa-fw" },
    { label: "地标", value: "fas fa-landmark fa-fw" },
    { label: "信用卡", value: "fas fa-credit-card fa-fw" },
    { label: "信用卡", value: "far fa-credit-card fa-fw" },
    { label: "收银机", value: "fas fa-cash-register fa-fw" },
    { label: "文件发票", value: "fas fa-file-invoice fa-fw" },
    { label: "钱包", value: "fas fa-wallet fa-fw" },
    { label: "百分之", value: "fas fa-percent fa-fw" },
    { label: "评论-美元", value: "fas fa-comments-dollar fa-fw" },
    { label: "硬币", value: "fas fa-coins fa-fw" },
    { label: "收据", value: "fas fa-receipt fa-fw" },
    { label: "美元符号", value: "fas fa-dollar-sign fa-fw" },
    { label: "邮票", value: "fas fa-stamp fa-fw" },
    { label: "日元符号", value: "fas fa-yen-sign fa-fw" },
    { label: "won-sign", value: "fas fa-won-sign fa-fw" },
    { label: "库", value: "fas fa-vault fa-fw" },
    {
      label: "土耳其里拉符号",
      value: "fas fa-turkish-lira-sign fa-fw",
    },
    { label: "坚戈征", value: "fas fa-tenge-sign fa-fw" },
    { label: "英镑标志", value: "fas fa-sterling-sign fa-fw" },
    { label: "谢克尔符号", value: "fas fa-shekel-sign fa-fw" },
    {
      label: "缩放-不平衡-翻转",
      value: "fas fa-scale-unbalanced-flip fa-fw",
    },
    { label: "规模不平衡", value: "fas fa-scale-unbalanced fa-fw" },
    { label: "规模平衡", value: "fas fa-scale-balanced fa-fw" },
    { label: "麻袋-xmark", value: "fas fa-sack-xmark fa-fw" },
    { label: "麻袋美元", value: "fas fa-sack-dollar fa-fw" },
    { label: "印尼盾标志", value: "fas fa-rupiah-sign fa-fw" },
    { label: "卢比符号", value: "fas fa-rupee-sign fa-fw" },
    { label: "卢布符号", value: "fas fa-ruble-sign fa-fw" },
    { label: "存钱罐", value: "fas fa-piggy-bank fa-fw" },
    { label: "比索符号", value: "fas fa-peso-sign fa-fw" },
    { label: "比塞塔标志", value: "fas fa-peseta-sign fa-fw" },
    { label: "奈拉符号", value: "fas fa-naira-sign fa-fw" },
    {
      label: "钱-支票-美元",
      value: "fas fa-money-check-dollar fa-fw",
    },
    { label: "支票", value: "fas fa-money-check fa-fw" },
    { label: "钞票", value: "fas fa-money-bills fa-fw" },
    { label: "钞票-小麦", value: "fas fa-money-bill-wheat fa-fw" },
    { label: "钞票浪潮", value: "fas fa-money-bill-wave fa-fw" },
    {
      label: "货币-钞票-趋势-上升",
      value: "fas fa-money-bill-trend-up fa-fw",
    },
    { label: "汇票转账", value: "fas fa-money-bill-transfer fa-fw" },
    { label: "钞票-1-波浪", value: "fas fa-money-bill-1-wave fa-fw" },
    { label: "钞票-1", value: "fas fa-money-bill-1 fa-fw" },
    { label: "磨坊标志", value: "fas fa-mill-sign fa-fw" },
    { label: "马纳特符号", value: "fas fa-manat-sign fa-fw" },
    { label: "莱特币签名", value: "fas fa-litecoin-sign fa-fw" },
    { label: "里拉符号", value: "fas fa-lira-sign fa-fw" },
    { label: "拉里符号", value: "fas fa-lari-sign fa-fw" },
    { label: "基普标志", value: "fas fa-kip-sign fa-fw" },
    { label: "印度卢比符号", value: "fas fa-indian-rupee-sign fa-fw" },
    { label: "格里夫纳符号", value: "fas fa-hryvnia-sign fa-fw" },
    { label: "手握美元", value: "fas fa-hand-holding-dollar fa-fw" },
    { label: "瓜拉尼标志", value: "fas fa-guarani-sign fa-fw" },
    { label: "法郎符号", value: "fas fa-franc-sign fa-fw" },
    { label: "弗洛林征", value: "fas fa-florin-sign fa-fw" },
    {
      label: "文件-发票-美元",
      value: "fas fa-file-invoice-dollar fa-fw",
    },
    { label: "欧洲符号", value: "fas fa-euro-sign fa-fw" },
    { label: "洞牌", value: "fas fa-dong-sign fa-fw" },
    { label: "克鲁塞罗标志", value: "fas fa-cruzeiro-sign fa-fw" },
    { label: "评论美元", value: "fas fa-comment-dollar fa-fw" },
    { label: "冒号", value: "fas fa-colon-sign fa-fw" },
    {
      label: "圆元到老虎机",
      value: "fas fa-circle-dollar-to-slot fa-fw",
    },
    { label: "图表饼图", value: "fas fa-chart-pie fa-fw" },
    { label: "图表线", value: "fas fa-chart-line fa-fw" },
    { label: "分号", value: "fas fa-cent-sign fa-fw" },
    { label: "塞地符号", value: "fas fa-cedi-sign fa-fw" },
    {
      label: "巴西语-real-sign",
      value: "fas fa-brazilian-real-sign fa-fw",
    },
    { label: "比特币签名", value: "fas fa-bitcoin-sign fa-fw" },
    {
      label: "孟加拉塔卡标志",
      value: "fas fa-bangladeshi-taka-sign fa-fw",
    },
    { label: "泰铢符号", value: "fas fa-baht-sign fa-fw" },
    { label: "南半神征", value: "fas fa-austral-sign fa-fw" },
    { label: "钞票-1", value: "far fa-money-bill-1 fa-fw" },
  ],
  安全: [
    { label: "屏蔽减半", value: "fas fa-shield-halved fa-fw" },
    { label: "锁", value: "fas fa-lock fa-fw" },
    { label: "眼睛", value: "fas fa-eye fa-fw" },
    { label: "眼睛", value: "far fa-eye fa-fw" },
    { label: "斜线", value: "fas fa-eye-slash fa-fw" },
    { label: "斜线", value: "far fa-eye-slash fa-fw" },
    { label: "钥匙", value: "fas fa-key fa-fw" },
    { label: "错误", value: "fas fa-bug fa-fw" },
    { label: "用户密钥", value: "fas fa-user-secret fa-fw" },
    { label: "护照", value: "fas fa-passport fa-fw" },
    { label: "盾", value: "fas fa-shield fa-fw" },
    { label: "开门式", value: "fas fa-door-open fa-fw" },
    { label: "文件协定", value: "fas fa-file-contract fa-fw" },
    { label: "面具", value: "fas fa-mask fa-fw" },
    { label: "指纹", value: "fas fa-fingerprint fa-fw" },
    { label: "开锁", value: "fas fa-unlock fa-fw" },
    { label: "库", value: "fas fa-vault fa-fw" },
    { label: "用户盾", value: "fas fa-user-shield fa-fw" },
    { label: "用户锁", value: "fas fa-user-lock fa-fw" },
    { label: "解锁锁孔", value: "fas fa-unlock-keyhole fa-fw" },
    { label: "塔观察", value: "fas fa-tower-observation fa-fw" },
    {
      label: "方形人被限制",
      value: "fas fa-square-person-confined fa-fw",
    },
    { label: "颅骨交叉骨", value: "fas fa-skull-crossbones fa-fw" },
    { label: "盾心", value: "fas fa-shield-heart fa-fw" },
    { label: "盾牌犬", value: "fas fa-shield-dog fa-fw" },
    { label: "盾牌猫", value: "fas fa-shield-cat fa-fw" },
    { label: "路钉", value: "fas fa-road-spikes fa-fw" },
    { label: "人窗式", value: "fas fa-person-through-window fa-fw" },
    { label: "个人庇护所", value: "fas fa-person-shelter fa-fw" },
    { label: "人-步枪", value: "fas fa-person-rifle fa-fw" },
    {
      label: "人-军人-人",
      value: "fas fa-person-military-to-person fa-fw",
    },
    {
      label: "人-军-步枪",
      value: "fas fa-person-military-rifle fa-fw",
    },
    {
      label: "人-军事-指向",
      value: "fas fa-person-military-pointing fa-fw",
    },
    { label: "人身骚扰", value: "fas fa-person-harassing fa-fw" },
    {
      label: "人坠落爆发",
      value: "fas fa-person-falling-burst fa-fw",
    },
    {
      label: "人-着装-爆裂",
      value: "fas fa-person-dress-burst fa-fw",
    },
    { label: "人突发", value: "fas fa-person-burst fa-fw" },
    { label: "抢劫人口", value: "fas fa-people-robbery fa-fw" },
    { label: "拉人", value: "fas fa-people-pulling fa-fw" },
    {
      label: "火星和金星爆发",
      value: "fas fa-mars-and-venus-burst fa-fw",
    },
    { label: "锁打开", value: "fas fa-lock-open fa-fw" },
    { label: "地雷-ON", value: "fas fa-land-mine-on fa-fw" },
    { label: "身份证夹", value: "fas fa-id-card-clip fa-fw" },
    { label: "身份证", value: "fas fa-id-card fa-fw" },
    { label: "ID徽章", value: "fas fa-id-badge fa-fw" },
    { label: "房屋锁", value: "fas fa-house-lock fa-fw" },
    { label: "房屋火灾", value: "fas fa-house-fire fa-fw" },
    { label: "手牵手圈", value: "fas fa-hands-holding-circle fa-fw" },
    {
      label: "手拉手-儿童",
      value: "fas fa-hands-holding-child fa-fw",
    },
    { label: "双手束缚", value: "fas fa-hands-bound fa-fw" },
    { label: "手铐", value: "fas fa-handcuffs fa-fw" },
    { label: "枪", value: "fas fa-gun fa-fw" },
    { label: "文件签名", value: "fas fa-file-signature fa-fw" },
    { label: "文件盾", value: "fas fa-file-shield fa-fw" },
    { label: "爆炸", value: "fas fa-explosion fa-fw" },
    { label: "地牢", value: "fas fa-dungeon fa-fw" },
    { label: "门关闭", value: "fas fa-door-closed fa-fw" },
    { label: "上车", value: "fas fa-car-on fa-fw" },
    { label: "爆裂", value: "fas fa-burst fa-fw" },
    { label: "建筑盾构", value: "fas fa-building-shield fa-fw" },
    { label: "楼宇锁", value: "fas fa-building-lock fa-fw" },
    { label: "错误斜杠", value: "fas fa-bug-slash fa-fw" },
    { label: "禁止", value: "fas fa-ban fa-fw" },
    { label: "身份证", value: "far fa-id-card fa-fw" },
    { label: "ID徽章", value: "far fa-id-badge fa-fw" },
  ],
  形状: [
    { label: "星", value: "fas fa-star fa-fw" },
    { label: "星", value: "far fa-star fa-fw" },
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "云", value: "fas fa-cloud fa-fw" },
    { label: "评论", value: "fas fa-comment fa-fw" },
    { label: "评论", value: "far fa-comment fa-fw" },
    { label: "文件", value: "fas fa-file fa-fw" },
    { label: "文件", value: "far fa-file fa-fw" },
    { label: "书签", value: "fas fa-bookmark fa-fw" },
    { label: "书签", value: "far fa-bookmark fa-fw" },
    { label: "圆半冲程", value: "fas fa-circle-half-stroke fa-fw" },
    { label: "文件夹", value: "fas fa-folder fa-fw" },
    { label: "文件夹", value: "far fa-folder fa-fw" },
    { label: "位置引脚", value: "fas fa-location-pin fa-fw" },
    { label: "日历", value: "fas fa-calendar fa-fw" },
    { label: "日历", value: "far fa-calendar fa-fw" },
    { label: "圈", value: "fas fa-circle fa-fw" },
    { label: "圈", value: "far fa-circle fa-fw" },
    { label: "玩", value: "fas fa-play fa-fw" },
    { label: "广场", value: "fas fa-square fa-fw" },
    { label: "广场", value: "far fa-square fa-fw" },
    { label: "盾", value: "fas fa-shield fa-fw" },
    { label: "钻石", value: "fas fa-diamond fa-fw" },
    { label: "冠", value: "fas fa-crown fa-fw" },
    { label: "证书", value: "fas fa-certificate fa-fw" },
    { label: "门票简单", value: "fas fa-ticket-simple fa-fw" },
    { label: "形状", value: "fas fa-shapes fa-fw" },
    { label: "线条倾斜", value: "fas fa-lines-leaning fa-fw" },
    { label: "心裂纹", value: "fas fa-heart-crack fa-fw" },
    { label: "立方体堆叠", value: "fas fa-cubes-stacked fa-fw" },
    { label: "三叶草", value: "fas fa-clover fa-fw" },
    { label: "爆裂", value: "fas fa-burst fa-fw" },
  ],
  购物: [
    { label: "星", value: "fas fa-star fa-fw" },
    { label: "星", value: "far fa-star fa-fw" },
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "相机复古", value: "fas fa-camera-retro fa-fw" },
    { label: "卡车快速", value: "fas fa-truck-fast fa-fw" },
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "购物车购物", value: "fas fa-cart-shopping fa-fw" },
    { label: "礼物", value: "fas fa-gift fa-fw" },
    { label: "条形码", value: "fas fa-barcode fa-fw" },
    { label: "标记", value: "fas fa-tag fa-fw" },
    { label: "书签", value: "fas fa-bookmark fa-fw" },
    { label: "书签", value: "far fa-bookmark fa-fw" },
    { label: "照相机", value: "fas fa-camera fa-fw" },
    { label: "竖起大拇指", value: "fas fa-thumbs-up fa-fw" },
    { label: "竖起大拇指", value: "far fa-thumbs-up fa-fw" },
    { label: "竖起大拇指", value: "fas fa-thumbs-down fa-fw" },
    { label: "竖起大拇指", value: "far fa-thumbs-down fa-fw" },
    { label: "钥匙", value: "fas fa-key fa-fw" },
    { label: "卡车", value: "fas fa-truck fa-fw" },
    { label: "握手", value: "fas fa-handshake fa-fw" },
    { label: "握手", value: "far fa-handshake fa-fw" },
    { label: "店", value: "fas fa-shop fa-fw" },
    { label: "衬衫", value: "fas fa-shirt fa-fw" },
    { label: "购物袋", value: "fas fa-bag-shopping fa-fw" },
    { label: "购物车加", value: "fas fa-cart-plus fa-fw" },
    { label: "信用卡", value: "fas fa-credit-card fa-fw" },
    { label: "信用卡", value: "far fa-credit-card fa-fw" },
    { label: "商店", value: "fas fa-store fa-fw" },
    { label: "收银机", value: "fas fa-cash-register fa-fw" },
    { label: "标签", value: "fas fa-tags fa-fw" },
    { label: "奖杯", value: "fas fa-trophy fa-fw" },
    { label: "证书", value: "fas fa-certificate fa-fw" },
    { label: "收据", value: "fas fa-receipt fa-fw" },
    { label: "存储斜杠", value: "fas fa-store-slash fa-fw" },
    { label: "商店斜杠", value: "fas fa-shop-slash fa-fw" },
    { label: "店锁", value: "fas fa-shop-lock fa-fw" },
    { label: "人展台", value: "fas fa-person-booth fa-fw" },
    {
      label: "钱-支票-美元",
      value: "fas fa-money-check-dollar fa-fw",
    },
    { label: "支票", value: "fas fa-money-check fa-fw" },
    { label: "礼物", value: "fas fa-gifts fa-fw" },
    { label: "宝石", value: "fas fa-gem fa-fw" },
    { label: "购物车箭头向下", value: "fas fa-cart-arrow-down fa-fw" },
    { label: "扩音器", value: "fas fa-bullhorn fa-fw" },
    { label: "购物篮购物", value: "fas fa-basket-shopping fa-fw" },
    { label: "宝石", value: "far fa-gem fa-fw" },
  ],
  社交: [
    { label: "用户", value: "fas fa-user fa-fw" },
    { label: "用户", value: "far fa-user fa-fw" },
    { label: "图像", value: "fas fa-image fa-fw" },
    { label: "图像", value: "far fa-image fa-fw" },
    { label: "信封", value: "fas fa-envelope fa-fw" },
    { label: "信封", value: "far fa-envelope fa-fw" },
    { label: "星", value: "fas fa-star fa-fw" },
    { label: "星", value: "far fa-star fa-fw" },
    { label: "位置点", value: "fas fa-location-dot fa-fw" },
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "评论", value: "fas fa-comment fa-fw" },
    { label: "评论", value: "far fa-comment fa-fw" },
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "圈子用户", value: "fas fa-circle-user fa-fw" },
    { label: "圈子用户", value: "far fa-circle-user fa-fw" },
    { label: "照相机", value: "fas fa-camera fa-fw" },
    { label: "视频", value: "fas fa-video fa-fw" },
    { label: "从广场分享", value: "fas fa-share-from-square fa-fw" },
    { label: "从广场分享", value: "far fa-share-from-square fa-fw" },
    { label: "共享", value: "fas fa-share fa-fw" },
    { label: "竖起大拇指", value: "fas fa-thumbs-up fa-fw" },
    { label: "竖起大拇指", value: "far fa-thumbs-up fa-fw" },
    { label: "竖起大拇指", value: "fas fa-thumbs-down fa-fw" },
    { label: "竖起大拇指", value: "far fa-thumbs-down fa-fw" },
    { label: "图钉", value: "fas fa-thumbtack fa-fw" },
    { label: "标签", value: "fas fa-hashtag fa-fw" },
    { label: "用户", value: "fas fa-users fa-fw" },
    { label: "位置引脚", value: "fas fa-location-pin fa-fw" },
    { label: "图标", value: "fas fa-icons fa-fw" },
    { label: "用户加", value: "fas fa-user-plus fa-fw" },
    { label: "图像", value: "fas fa-images fa-fw" },
    { label: "图像", value: "far fa-images fa-fw" },
    { label: "用户组", value: "fas fa-user-group fa-fw" },
    {
      label: "方形共享节点",
      value: "fas fa-square-share-nodes fa-fw",
    },
    {
      label: "方形-轮询-垂直",
      value: "fas fa-square-poll-vertical fa-fw",
    },
    {
      label: "方形-轮询-水平",
      value: "fas fa-square-poll-horizontal fa-fw",
    },
    { label: "共享节点", value: "fas fa-share-nodes fa-fw" },
    { label: "转推", value: "fas fa-retweet fa-fw" },
    { label: "照片胶片", value: "fas fa-photo-film fa-fw" },
    { label: "消息", value: "fas fa-message fa-fw" },
    { label: "蛋糕蜡烛", value: "fas fa-cake-candles fa-fw" },
    { label: "消息", value: "far fa-message fa-fw" },
  ],
  无障碍: [
    { label: "圈子信息", value: "fas fa-circle-info fa-fw" },
    { label: "眼睛", value: "fas fa-eye fa-fw" },
    { label: "眼睛", value: "far fa-eye fa-fw" },
    { label: "问题", value: "fas fa-question fa-fw" },
    { label: "电话音量", value: "fas fa-phone-volume fa-fw" },
    { label: "地址卡", value: "fas fa-address-card fa-fw" },
    { label: "地址卡", value: "far fa-address-card fa-fw" },
    { label: "电传机", value: "fas fa-tty fa-fw" },
    { label: "手", value: "fas fa-hands fa-fw" },
    { label: "音频描述", value: "fas fa-audio-description fa-fw" },
    { label: "隐藏式字幕", value: "fas fa-closed-captioning fa-fw" },
    { label: "隐藏式字幕", value: "far fa-closed-captioning fa-fw" },
    { label: "指纹", value: "fas fa-fingerprint fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    { label: "轮椅", value: "fas fa-wheelchair fa-fw" },
    { label: "通用访问", value: "fas fa-universal-access fa-fw" },
    {
      label: "拄着拐杖走路的人",
      value: "fas fa-person-walking-with-cane fa-fw",
    },
    { label: "人-手杖", value: "fas fa-person-cane fa-fw" },
    { label: "握手角度", value: "fas fa-handshake-angle fa-fw" },
    {
      label: "手语",
      value: "fas fa-hands-asl-interpreting fa-fw",
    },
    { label: "眼部视力低下", value: "fas fa-eye-low-vision fa-fw" },
    { label: "耳朵听", value: "fas fa-ear-listen fa-fw" },
    { label: "耳聋", value: "fas fa-ear-deaf fa-fw" },
    { label: "圆圈问题", value: "fas fa-circle-question fa-fw" },
    { label: "盲文", value: "fas fa-braille fa-fw" },
    { label: "圆圈问题", value: "far fa-circle-question fa-fw" },
  ],
  警示: [
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "圆圈感叹号", value: "fas fa-circle-exclamation fa-fw" },
    { label: "问题", value: "fas fa-question fa-fw" },
    { label: "感叹号", value: "fas fa-exclamation fa-fw" },
    {
      label: "三角形感叹号",
      value: "fas fa-triangle-exclamation fa-fw",
    },
    {
      label: "头骨和交叉的骨头",
      value: "fas fa-skull-crossbones fa-fw",
    },
    { label: "辐射", value: "fas fa-radiation fa-fw" },
    {
      label: "圆形的辐射",
      value: "fas fa-circle-radiation fa-fw",
    },
    { label: "静音", value: "fas fa-bell-slash fa-fw" },
    { label: "静音", value: "far fa-bell-slash fa-fw" },
  ],
  字母: [
    { label: "地址卡", value: "fas fa-address-card fa-fw" },
    { label: "地址卡", value: "far fa-address-card fa-fw" },
    { label: "z", value: "fas fa-z fa-fw" },
    { label: "y", value: "fas fa-y fa-fw" },
    { label: "x", value: "fas fa-x fa-fw" },
    { label: "w", value: "fas fa-w fa-fw" },
    { label: "v", value: "fas fa-v fa-fw" },
    { label: "u", value: "fas fa-u fa-fw" },
    { label: "t", value: "fas fa-t fa-fw" },
    { label: "平方-H", value: "fas fa-square-h fa-fw" },
    { label: "s", value: "fas fa-s fa-fw" },
    { label: "r", value: "fas fa-r fa-fw" },
    { label: "q", value: "fas fa-q fa-fw" },
    { label: "p", value: "fas fa-p fa-fw" },
    { label: "o", value: "fas fa-o fa-fw" },
    { label: "n", value: "fas fa-n fa-fw" },
    { label: "m", value: "fas fa-m fa-fw" },
    { label: "l", value: "fas fa-l fa-fw" },
    { label: "k", value: "fas fa-k fa-fw" },
    { label: "j", value: "fas fa-j fa-fw" },
    { label: "我", value: "fas fa-i fa-fw" },
    { label: "h", value: "fas fa-h fa-fw" },
    { label: "g", value: "fas fa-g fa-fw" },
    { label: "f", value: "fas fa-f fa-fw" },
    { label: "e", value: "fas fa-e fa-fw" },
    { label: "d", value: "fas fa-d fa-fw" },
    { label: "圆圈-H", value: "fas fa-circle-h fa-fw" },
    { label: "c", value: "fas fa-c fa-fw" },
    { label: "b", value: "fas fa-b fa-fw" },
    { label: "一个", value: "fas fa-a fa-fw" },
  ],
  动物: [
    { label: "河马", value: "fas fa-hippo fa-fw" },
    { label: "羽毛", value: "fas fa-feather fa-fw" },
    { label: "鱼", value: "fas fa-fish fa-fw" },
    { label: "龙", value: "fas fa-dragon fa-fw" },
    { label: "水獭", value: "fas fa-otter fa-fw" },
    { label: "奇异鸟", value: "fas fa-kiwi-bird fa-fw" },
    { label: "蠕虫", value: "fas fa-worm fa-fw" },
    { label: "蜘蛛", value: "fas fa-spider fa-fw" },
    { label: "虾", value: "fas fa-shrimp fa-fw" },
    { label: "盾牌犬", value: "fas fa-shield-dog fa-fw" },
    { label: "盾牌猫", value: "fas fa-shield-cat fa-fw" },
    { label: "爪子", value: "fas fa-paw fa-fw" },
    { label: "蚊子", value: "fas fa-mosquito fa-fw" },
    { label: "蝗", value: "fas fa-locust fa-fw" },
    { label: "horse-head", value: "fas fa-horse-head fa-fw" },
    { label: "马", value: "fas fa-horse fa-fw" },
    { label: "青蛙", value: "fas fa-frog fa-fw" },
    { label: "鱼鳍", value: "fas fa-fish-fins fa-fw" },
    { label: "尖锐的羽毛", value: "fas fa-feather-pointed fa-fw" },
    { label: "鸽子", value: "fas fa-dove fa-fw" },
    { label: "狗", value: "fas fa-dog fa-fw" },
    { label: "乌鸦", value: "fas fa-crow fa-fw" },
    { label: "牛", value: "fas fa-cow fa-fw" },
    { label: "猫", value: "fas fa-cat fa-fw" },
    { label: "臭虫", value: "fas fa-bugs fa-fw" },
  ],
  箭头: [
    { label: "下载", value: "fas fa-download fa-fw" },
    { label: "向右箭头", value: "fas fa-arrow-right fa-fw" },
    { label: "插入", value: "fas fa-caret-up fa-fw" },
    { label: "向上箭头", value: "fas fa-arrow-up fa-fw" },
    {
      label: "从括号向上箭头",
      value: "fas fa-arrow-up-from-bracket fa-fw",
    },
    { label: "圆圈", value: "fas fa-circle-up fa-fw" },
    { label: "圆圈", value: "far fa-circle-up fa-fw" },
    { label: "向下圈", value: "fas fa-circle-down fa-fw" },
    { label: "向下圈", value: "far fa-circle-down fa-fw" },
    { label: "向右旋转", value: "fas fa-rotate-right fa-fw" },
    { label: "从广场分享", value: "fas fa-share-from-square fa-fw" },
    { label: "从广场分享", value: "far fa-share-from-square fa-fw" },
    { label: "共享", value: "fas fa-share fa-fw" },
    { label: "右括号", value: "fas fa-right-to-bracket fa-fw" },
    {
      label: "云上传",
      value: "fas fa-cloud-arrow-up fa-fw",
    },
    { label: "向上的箭头", value: "fas fa-chevron-up fa-fw" },
    { label: "上传", value: "fas fa-upload fa-fw" },
    { label: "排序", value: "fas fa-sort fa-fw" },
    { label: "向上角", value: "fas fa-angle-up fa-fw" },
    { label: "向下箭头", value: "fas fa-arrow-down fa-fw" },
    { label: "插入向下", value: "fas fa-caret-down fa-fw" },
    {
      label: "从正方形向右箭头",
      value: "fas fa-arrow-up-right-from-square fa-fw",
    },
    { label: "向左箭头", value: "fas fa-arrow-left fa-fw" },
    { label: "重复", value: "fas fa-repeat fa-fw" },
    { label: "答", value: "fas fa-reply fa-fw" },
    { label: "回收", value: "fas fa-recycle fa-fw" },
    { label: "人字形向下", value: "fas fa-chevron-down fa-fw" },
    {
      label: "从正方形向上",
      value: "fas fa-up-right-from-square fa-fw",
    },
    {
      label: "从中心向上-右-下-左-下",
      value: "fas fa-up-right-and-down-left-from-center fa-fw",
    },
    { label: "上长", value: "fas fa-up-long fa-fw" },
    { label: "上-下-左-右", value: "fas fa-up-down-left-right fa-fw" },
    { label: "上下", value: "fas fa-up-down fa-fw" },
    { label: "开通", value: "fas fa-turn-up fa-fw" },
    { label: "夜床服务", value: "fas fa-turn-down fa-fw" },
    { label: "正上方-右", value: "fas fa-square-up-right fa-fw" },
    { label: "方形插入符号", value: "fas fa-square-caret-up fa-fw" },
    {
      label: "方形插入符号右",
      value: "fas fa-square-caret-right fa-fw",
    },
    {
      label: "方形插入符号左",
      value: "fas fa-square-caret-left fa-fw",
    },
    { label: "方形插入符号", value: "fas fa-square-caret-down fa-fw" },
    {
      label: "右上方方箭头",
      value: "fas fa-square-arrow-up-right fa-fw",
    },
    { label: "分类", value: "fas fa-sort-up fa-fw" },
    { label: "排序", value: "fas fa-sort-down fa-fw" },
    { label: "洗牌", value: "fas fa-shuffle fa-fw" },
    { label: "向左旋转", value: "fas fa-rotate-left fa-fw" },
    { label: "旋转", value: "fas fa-rotate fa-fw" },
    { label: "右长", value: "fas fa-right-long fa-fw" },
    { label: "右-左", value: "fas fa-right-left fa-fw" },
    { label: "括号右边", value: "fas fa-right-from-bracket fa-fw" },
    { label: "转推", value: "fas fa-retweet fa-fw" },
    { label: "全部回复", value: "fas fa-reply-all fa-fw" },
    { label: "最大化", value: "fas fa-maximize fa-fw" },
    { label: "位置箭头", value: "fas fa-location-arrow fa-fw" },
    { label: "从左到右", value: "fas fa-left-right fa-fw" },
    { label: "左长", value: "fas fa-left-long fa-fw" },
    { label: "下长", value: "fas fa-down-long fa-fw" },
    {
      label: "从左下到右上到中心",
      value: "fas fa-down-left-and-up-right-to-center fa-fw",
    },
    {
      label: "云端下载",
      value: "fas fa-cloud-arrow-down fa-fw",
    },
    { label: "时钟向左旋转", value: "fas fa-clock-rotate-left fa-fw" },
    { label: "圆圈-右", value: "fas fa-circle-right fa-fw" },
    { label: "圆圈-左", value: "fas fa-circle-left fa-fw" },
    {
      label: "圆形-V 形向上",
      value: "fas fa-circle-chevron-up fa-fw",
    },
    {
      label: "圆-人字形-右",
      value: "fas fa-circle-chevron-right fa-fw",
    },
    { label: "圆-V 形-左", value: "fas fa-circle-chevron-left fa-fw" },
    {
      label: "圆形-V 形向下",
      value: "fas fa-circle-chevron-down fa-fw",
    },
    { label: "圆圈向上箭头", value: "fas fa-circle-arrow-up fa-fw" },
    {
      label: "圆圈-箭头-右",
      value: "fas fa-circle-arrow-right fa-fw",
    },
    { label: "圆圈箭头左", value: "fas fa-circle-arrow-left fa-fw" },
    { label: "圆圈向下箭头", value: "fas fa-circle-arrow-down fa-fw" },
    { label: "人字形右", value: "fas fa-chevron-right fa-fw" },
    { label: "人字形左", value: "fas fa-chevron-left fa-fw" },
    { label: "插入符号右", value: "fas fa-caret-right fa-fw" },
    { label: "插入符号-左", value: "fas fa-caret-left fa-fw" },
    { label: "向上箭头", value: "fas fa-arrows-up-to-line fa-fw" },
    {
      label: "箭头-上-下-左-右",
      value: "fas fa-arrows-up-down-left-right fa-fw",
    },
    { label: "箭头-上-下", value: "fas fa-arrows-up-down fa-fw" },
    { label: "箭头转为点", value: "fas fa-arrows-turn-to-dots fa-fw" },
    { label: "箭头-右转", value: "fas fa-arrows-turn-right fa-fw" },
    { label: "箭头到眼睛", value: "fas fa-arrows-to-eye fa-fw" },
    { label: "箭头到点", value: "fas fa-arrows-to-dot fa-fw" },
    { label: "箭头到圆圈", value: "fas fa-arrows-to-circle fa-fw" },
    {
      label: "arrows-split-up-and-left",
      value: "fas fa-arrows-split-up-and-left fa-fw",
    },
    { label: "箭头旋转", value: "fas fa-arrows-spin fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-rotate fa-fw" },
    {
      label: "arrows-left-right-to-line",
      value: "fas fa-arrows-left-right-to-line fa-fw",
    },
    { label: "箭头-左-右", value: "fas fa-arrows-left-right fa-fw" },
    {
      label: "向下到线的箭头",
      value: "fas fa-arrows-down-to-line fa-fw",
    },
    { label: "箭头-up-z-a", value: "fas fa-arrow-up-z-a fa-fw" },
    {
      label: "向上箭头-宽-短箭头",
      value: "fas fa-arrow-up-wide-short fa-fw",
    },
    {
      label: "向上箭头-短-宽",
      value: "fas fa-arrow-up-short-wide fa-fw",
    },
    {
      label: "向上向右箭头点",
      value: "fas fa-arrow-up-right-dots fa-fw",
    },
    { label: "箭头向上", value: "fas fa-arrow-up-long fa-fw" },
    { label: "箭头-A-Z", value: "fas fa-arrow-up-a-z fa-fw" },
    { label: "箭头-9-1", value: "fas fa-arrow-up-9-1 fa-fw" },
    { label: "箭头-1-9", value: "fas fa-arrow-up-1-9 fa-fw" },
    { label: "箭头向上", value: "fas fa-arrow-turn-up fa-fw" },
    { label: "箭头调节器", value: "fas fa-arrow-turn-down fa-fw" },
    { label: "箭头趋势向上", value: "fas fa-arrow-trend-up fa-fw" },
    { label: "箭头趋势向下", value: "fas fa-arrow-trend-down fa-fw" },
    {
      label: "向右箭头旋转",
      value: "fas fa-arrow-rotate-right fa-fw",
    },
    {
      label: "arrow-rotate-left",
      value: "fas fa-arrow-rotate-left fa-fw",
    },
    {
      label: "向右箭头到括号",
      value: "fas fa-arrow-right-to-bracket fa-fw",
    },
    { label: "向右箭头", value: "fas fa-arrow-right-long fa-fw" },
    {
      label: "括号右箭头",
      value: "fas fa-arrow-right-from-bracket fa-fw",
    },
    {
      label: "向右箭头向左箭头",
      value: "fas fa-arrow-right-arrow-left fa-fw",
    },
    { label: "箭头指针", value: "fas fa-arrow-pointer fa-fw" },
    { label: "左箭头", value: "fas fa-arrow-left-long fa-fw" },
    { label: "向下箭头-z-a", value: "fas fa-arrow-down-z-a fa-fw" },
    {
      label: "箭头向下-宽-短",
      value: "fas fa-arrow-down-wide-short fa-fw",
    },
    {
      label: "向下箭头向上锁定",
      value: "fas fa-arrow-down-up-lock fa-fw",
    },
    {
      label: "向下箭头向上跨线",
      value: "fas fa-arrow-down-up-across-line fa-fw",
    },
    {
      label: "arrow-down-short-wide",
      value: "fas fa-arrow-down-short-wide fa-fw",
    },
    { label: "向下箭头长", value: "fas fa-arrow-down-long fa-fw" },
    { label: "向下箭头-a-z", value: "fas fa-arrow-down-a-z fa-fw" },
    { label: "向下箭头-9-1", value: "fas fa-arrow-down-9-1 fa-fw" },
    { label: "向下箭头-1-9", value: "fas fa-arrow-down-1-9 fa-fw" },
    { label: "向上倾斜", value: "fas fa-angles-up fa-fw" },
    { label: "右角", value: "fas fa-angles-right fa-fw" },
    { label: "左角", value: "fas fa-angles-left fa-fw" },
    { label: "向下角度", value: "fas fa-angles-down fa-fw" },
    { label: "右角", value: "fas fa-angle-right fa-fw" },
    { label: "左角", value: "fas fa-angle-left fa-fw" },
    { label: "向下角", value: "fas fa-angle-down fa-fw" },
    { label: "方形插入符号", value: "far fa-square-caret-up fa-fw" },
    {
      label: "方形插入符号右",
      value: "far fa-square-caret-right fa-fw",
    },
    {
      label: "方形插入符号左",
      value: "far fa-square-caret-left fa-fw",
    },
    {
      label: "方形插入符号",
      value: "far fa-square-caret-down fa-fw",
    },
    { label: "圆圈-右", value: "far fa-circle-right fa-fw" },
    { label: "圆圈-左", value: "far fa-circle-left fa-fw" },
  ],
  天文学: [
    { label: "球", value: "fas fa-globe fa-fw" },
    { label: "望远镜", value: "fas fa-binoculars fa-fw" },
    { label: "月亮", value: "fas fa-moon fa-fw" },
    { label: "月亮", value: "far fa-moon fa-fw" },
    { label: "用户宇航员", value: "fas fa-user-astronaut fa-fw" },
    { label: "穿梭空间", value: "fas fa-shuttle-space fa-fw" },
    { label: "卫星天线", value: "fas fa-satellite-dish fa-fw" },
    { label: "卫星", value: "fas fa-satellite fa-fw" },
    { label: "流星", value: "fas fa-meteor fa-fw" },
  ],
  汽车: [
    { label: "汽车", value: "fas fa-car fa-fw" },
    { label: "卡车", value: "fas fa-truck fa-fw" },
    { label: "轨距", value: "fas fa-gauge fa-fw" },
    { label: "车侧", value: "fas fa-car-side fa-fw" },
    { label: "摩托车", value: "fas fa-motorcycle fa-fw" },
    { label: "卡车怪兽", value: "fas fa-truck-monster fa-fw" },
    { label: "厢式穿梭巴士", value: "fas fa-van-shuttle fa-fw" },
    { label: "卡车皮卡", value: "fas fa-truck-pickup fa-fw" },
    { label: "卡车-医疗", value: "fas fa-truck-medical fa-fw" },
    { label: "卡车场-联合国", value: "fas fa-truck-field-un fa-fw" },
    { label: "卡车场", value: "fas fa-truck-field fa-fw" },
    { label: "拖车", value: "fas fa-trailer fa-fw" },
    { label: "出租车", value: "fas fa-taxi fa-fw" },
    {
      label: "喷雾罐闪闪发光",
      value: "fas fa-spray-can-sparkles fa-fw",
    },
    { label: "油罐", value: "fas fa-oil-can fa-fw" },
    { label: "仪表-简单-高", value: "fas fa-gauge-simple-high fa-fw" },
    { label: "仪表简单", value: "fas fa-gauge-simple fa-fw" },
    { label: "高标", value: "fas fa-gauge-high fa-fw" },
    { label: "气泵", value: "fas fa-gas-pump fa-fw" },
    { label: "充电站", value: "fas fa-charging-station fa-fw" },
    { label: "商队", value: "fas fa-caravan fa-fw" },
    { label: "汽车隧道", value: "fas fa-car-tunnel fa-fw" },
    { label: "汽车后部", value: "fas fa-car-rear fa-fw" },
    { label: "上车", value: "fas fa-car-on fa-fw" },
    { label: "汽车爆裂", value: "fas fa-car-burst fa-fw" },
    { label: "汽车电池", value: "fas fa-car-battery fa-fw" },
    { label: "巴士", value: "fas fa-bus-simple fa-fw" },
    { label: "巴士", value: "fas fa-bus fa-fw" },
  ],

  露营: [
    { label: "火灾", value: "fas fa-fire fa-fw" },
    { label: "树", value: "fas fa-tree fa-fw" },
    { label: "指南针", value: "fas fa-compass fa-fw" },
    { label: "指南针", value: "far fa-compass fa-fw" },
    { label: "望远镜", value: "fas fa-binoculars fa-fw" },
    { label: "山太阳", value: "fas fa-mountain-sun fa-fw" },
    { label: "路线", value: "fas fa-route fa-fw" },
    { label: "拖车", value: "fas fa-trailer fa-fw" },
    { label: "卫生纸", value: "fas fa-toilet-paper fa-fw" },
    { label: "帐篷", value: "fas fa-tents fa-fw" },
    { label: "帐篷箭头向下", value: "fas fa-tent-arrows-down fa-fw" },
    {
      label: "帐篷箭头左转",
      value: "fas fa-tent-arrow-turn-left fa-fw",
    },
    {
      label: "帐篷箭头左右",
      value: "fas fa-tent-arrow-left-right fa-fw",
    },
    {
      label: "tent-arrow-down-to-line",
      value: "fas fa-tent-arrow-down-to-line fa-fw",
    },
    { label: "帐篷", value: "fas fa-tent fa-fw" },
    { label: "防水布液滴", value: "fas fa-tarp-droplet fa-fw" },
    { label: "防水布", value: "fas fa-tarp fa-fw" },
    { label: "路标柱", value: "fas fa-signs-post fa-fw" },
    { label: "个人庇护所", value: "fas fa-person-shelter fa-fw" },
    { label: "人员徒步旅行", value: "fas fa-person-hiking fa-fw" },
    { label: "人顶", value: "fas fa-people-roof fa-fw" },
    { label: "山", value: "fas fa-mountain fa-fw" },
    { label: "蚊帐", value: "fas fa-mosquito-net fa-fw" },
    { label: "蚊子", value: "fas fa-mosquito fa-fw" },
    { label: "床垫枕头", value: "fas fa-mattress-pillow fa-fw" },
    { label: "地图位置点", value: "fas fa-map-location-dot fa-fw" },
    { label: "地图位置", value: "fas fa-map-location fa-fw" },
    { label: "地图", value: "fas fa-map fa-fw" },
    { label: "套件-医疗", value: "fas fa-kit-medical fa-fw" },
    { label: "青蛙", value: "fas fa-frog fa-fw" },
    { label: "火-火焰-弯曲", value: "fas fa-fire-flame-curved fa-fw" },
    { label: "燃烧器", value: "fas fa-fire-burner fa-fw" },
    { label: "水龙头滴水", value: "fas fa-faucet-drip fa-fw" },
    { label: "龙头", value: "fas fa-faucet fa-fw" },
    { label: "商队", value: "fas fa-caravan fa-fw" },
    { label: "野营地", value: "fas fa-campground fa-fw" },
    { label: "桶", value: "fas fa-bucket fa-fw" },
    { label: "瓶装水", value: "fas fa-bottle-water fa-fw" },
    { label: "地图", value: "far fa-map fa-fw" },
  ],
  慈善: [
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "礼物", value: "fas fa-gift fa-fw" },
    { label: "球", value: "fas fa-globe fa-fw" },
    { label: "握手", value: "fas fa-handshake fa-fw" },
    { label: "握手", value: "far fa-handshake fa-fw" },
    { label: "手牵心", value: "fas fa-hand-holding-heart fa-fw" },
    { label: "叶", value: "fas fa-leaf fa-fw" },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "美元符号", value: "fas fa-dollar-sign fa-fw" },
    { label: "丝带", value: "fas fa-ribbon fa-fw" },
    { label: "存钱罐", value: "fas fa-piggy-bank fa-fw" },
    { label: "降落伞箱", value: "fas fa-parachute-box fa-fw" },
    { label: "握手-简单", value: "fas fa-handshake-simple fa-fw" },
    { label: "握手角度", value: "fas fa-handshake-angle fa-fw" },
    { label: "手牵手圈", value: "fas fa-hands-holding-circle fa-fw" },
    {
      label: "手拉手-儿童",
      value: "fas fa-hands-holding-child fa-fw",
    },
    { label: "手牵手", value: "fas fa-hand-holding-hand fa-fw" },
    { label: "手持液滴", value: "fas fa-hand-holding-droplet fa-fw" },
    { label: "手握美元", value: "fas fa-hand-holding-dollar fa-fw" },
    { label: "鸽子", value: "fas fa-dove fa-fw" },
    {
      label: "圆元到老虎机",
      value: "fas fa-circle-dollar-to-slot fa-fw",
    },
  ],

  童年: [
    { label: "浴", value: "fas fa-bath fa-fw" },
    { label: "游戏手柄", value: "fas fa-gamepad fa-fw" },
    { label: "学校", value: "fas fa-school fa-fw" },
    { label: "机器人", value: "fas fa-robot fa-fw" },
    { label: "孩子", value: "fas fa-child fa-fw" },
    { label: "婴儿", value: "fas fa-baby fa-fw" },
    { label: "拼图", value: "fas fa-puzzle-piece fa-fw" },
    { label: "饼干咬", value: "fas fa-cookie-bite fa-fw" },
    { label: "雪人", value: "fas fa-snowman fa-fw" },
    { label: "形状", value: "fas fa-shapes fa-fw" },
    { label: "母乳喂养", value: "fas fa-person-breastfeeding fa-fw" },
    { label: "人骑车", value: "fas fa-person-biking fa-fw" },
    { label: "手套", value: "fas fa-mitten fa-fw" },
    { label: "冰淇淋", value: "fas fa-ice-cream fa-fw" },
    {
      label: "手拉手-儿童",
      value: "fas fa-hands-holding-child fa-fw",
    },
    { label: "立方体堆叠", value: "fas fa-cubes-stacked fa-fw" },
    { label: "饼干", value: "fas fa-cookie fa-fw" },
    { label: "孩子", value: "fas fa-children fa-fw" },
    { label: "儿童接触", value: "fas fa-child-reaching fa-fw" },
    { label: "童装", value: "fas fa-child-dress fa-fw" },
    { label: "蛋糕蜡烛", value: "fas fa-cake-candles fa-fw" },
    { label: "桶", value: "fas fa-bucket fa-fw" },
    { label: "棒球-球棒-球", value: "fas fa-baseball-bat-ball fa-fw" },
    { label: "婴儿车", value: "fas fa-baby-carriage fa-fw" },
    { label: "整个苹果", value: "fas fa-apple-whole fa-fw" },
  ],
  服装: [
    { label: "衬衫", value: "fas fa-shirt fa-fw" },
    { label: "用户关系", value: "fas fa-user-tie fa-fw" },
    { label: "眼镜", value: "fas fa-glasses fa-fw" },
    { label: "背心贴片", value: "fas fa-vest-patches fa-fw" },
    { label: "背心", value: "fas fa-vest fa-fw" },
    { label: "袜子", value: "fas fa-socks fa-fw" },
    { label: "鞋印", value: "fas fa-shoe-prints fa-fw" },
    { label: "手套", value: "fas fa-mitten fa-fw" },
    { label: "帽子向导", value: "fas fa-hat-wizard fa-fw" },
    { label: "帽子牛仔面", value: "fas fa-hat-cowboy-side fa-fw" },
    { label: "帽子牛仔", value: "fas fa-hat-cowboy fa-fw" },
    { label: "毕业帽", value: "fas fa-graduation-cap fa-fw" },
  ],
  代码: [
    { label: "酒吧", value: "fas fa-bars fa-fw" },
    { label: "屏蔽减半", value: "fas fa-shield-halved fa-fw" },
    { label: "文件", value: "fas fa-file fa-fw" },
    { label: "文件", value: "far fa-file fa-fw" },
    { label: "滤波器", value: "fas fa-filter fa-fw" },
    { label: "齿轮", value: "fas fa-gear fa-fw" },
    { label: "条形码", value: "fas fa-barcode fa-fw" },
    { label: "文件夹", value: "fas fa-folder fa-fw" },
    { label: "文件夹", value: "far fa-folder fa-fw" },
    { label: "文件夹打开", value: "fas fa-folder-open fa-fw" },
    { label: "文件夹打开", value: "far fa-folder-open fa-fw" },
    { label: "齿轮", value: "fas fa-gears fa-fw" },
    { label: "法典", value: "fas fa-code fa-fw" },
    { label: "浴", value: "fas fa-bath fa-fw" },
    { label: "错误", value: "fas fa-bug fa-fw" },
    { label: "马克杯碟", value: "fas fa-mug-saucer fa-fw" },
    { label: "代码比较", value: "fas fa-code-compare fa-fw" },
    { label: "用户密钥", value: "fas fa-user-secret fa-fw" },
    { label: "网络有线", value: "fas fa-network-wired fa-fw" },
    { label: "盾", value: "fas fa-shield fa-fw" },
    { label: "网站地图", value: "fas fa-sitemap fa-fw" },
    { label: "微片", value: "fas fa-microchip fa-fw" },
    { label: "立方体", value: "fas fa-cube fa-fw" },
    { label: "键盘", value: "fas fa-keyboard fa-fw" },
    { label: "键盘", value: "far fa-keyboard fa-fw" },
    { label: "代码分支", value: "fas fa-code-branch fa-fw" },
    { label: "窗口还原", value: "fas fa-window-restore fa-fw" },
    { label: "窗口还原", value: "far fa-window-restore fa-fw" },
    { label: "窗口最小化", value: "fas fa-window-minimize fa-fw" },
    { label: "窗口最大化", value: "fas fa-window-maximize fa-fw" },
    { label: "终端", value: "fas fa-terminal fa-fw" },
    { label: "矩形-xmark", value: "fas fa-rectangle-xmark fa-fw" },
    { label: "二维码", value: "fas fa-qrcode fa-fw" },
    { label: "不定义", value: "fas fa-notdef fa-fw" },
    { label: "笔记本电脑代码", value: "fas fa-laptop-code fa-fw" },
    { label: "字体真棒", value: "fas fa-font-awesome fa-fw" },
    { label: "灭火器", value: "fas fa-fire-extinguisher fa-fw" },
    { label: "文件行", value: "fas fa-file-lines fa-fw" },
    { label: "文件代码", value: "fas fa-file-code fa-fw" },
    { label: "图项目", value: "fas fa-diagram-project fa-fw" },
    { label: "立方体", value: "fas fa-cubes fa-fw" },
    { label: "代码拉取请求", value: "fas fa-code-pull-request fa-fw" },
    { label: "代码合并", value: "fas fa-code-merge fa-fw" },
    { label: "代码分叉", value: "fas fa-code-fork fa-fw" },
    { label: "代码提交", value: "fas fa-code-commit fa-fw" },
    { label: "圆节点", value: "fas fa-circle-nodes fa-fw" },
    { label: "错误斜杠", value: "fas fa-bug-slash fa-fw" },
    { label: "盒存档", value: "fas fa-box-archive fa-fw" },
    { label: "条形交错", value: "fas fa-bars-staggered fa-fw" },
    { label: "窗口最小化", value: "far fa-window-minimize fa-fw" },
    { label: "窗口最大化", value: "far fa-window-maximize fa-fw" },
    { label: "矩形-xmark", value: "far fa-rectangle-xmark fa-fw" },
    { label: "字体真棒", value: "far fa-font-awesome fa-fw" },
    { label: "文件行", value: "far fa-file-lines fa-fw" },
    { label: "文件代码", value: "far fa-file-code fa-fw" },
  ],
  通讯: [
    { label: "电话", value: "fas fa-phone fa-fw" },
    { label: "信封", value: "fas fa-envelope fa-fw" },
    { label: "信封", value: "far fa-envelope fa-fw" },
    { label: "便便", value: "fas fa-poo fa-fw" },
    { label: "评论", value: "fas fa-comment fa-fw" },
    { label: "评论", value: "far fa-comment fa-fw" },
    { label: "面带微笑", value: "fas fa-face-smile fa-fw" },
    { label: "面带微笑", value: "far fa-face-smile fa-fw" },
    { label: "收件箱", value: "fas fa-inbox fa-fw" },
    { label: "视频", value: "fas fa-video fa-fw" },
    { label: "评论", value: "fas fa-comments fa-fw" },
    { label: "评论", value: "far fa-comments fa-fw" },
    { label: "纸飞机", value: "fas fa-paper-plane fa-fw" },
    { label: "纸飞机", value: "far fa-paper-plane fa-fw" },
    { label: "地址簿", value: "fas fa-address-book fa-fw" },
    { label: "地址簿", value: "far fa-address-book fa-fw" },
    { label: "左引号", value: "fas fa-quote-left fa-fw" },
    { label: "电话音量", value: "fas fa-phone-volume fa-fw" },
    { label: "移动", value: "fas fa-mobile fa-fw" },
    { label: "地址卡", value: "fas fa-address-card fa-fw" },
    { label: "地址卡", value: "far fa-address-card fa-fw" },
    { label: "引用权", value: "fas fa-quote-right fa-fw" },
    { label: "视频斜杠", value: "fas fa-video-slash fa-fw" },
    { label: "电传机", value: "fas fa-tty fa-fw" },
    { label: "图标", value: "fas fa-icons fa-fw" },
    { label: "语言", value: "fas fa-language fa-fw" },
    { label: "步话机", value: "fas fa-walkie-talkie fa-fw" },
    { label: "语音信箱", value: "fas fa-voicemail fa-fw" },
    { label: "麦克风斜杠", value: "fas fa-microphone-slash fa-fw" },
    { label: "麦克风", value: "fas fa-microphone fa-fw" },
    { label: "塔式单元", value: "fas fa-tower-cell fa-fw" },
    { label: "方形RSS", value: "fas fa-square-rss fa-fw" },
    { label: "方形手机翻转", value: "fas fa-square-phone-flip fa-fw" },
    { label: "方形电话", value: "fas fa-square-phone fa-fw" },
    { label: "方形信封", value: "fas fa-square-envelope fa-fw" },
    { label: "电话斜杠", value: "fas fa-phone-slash fa-fw" },
    { label: "手机翻转", value: "fas fa-phone-flip fa-fw" },
    {
      label: "mobile-screen-button（手机屏幕按钮）",
      value: "fas fa-mobile-screen-button fa-fw",
    },
    { label: "手机屏幕", value: "fas fa-mobile-screen fa-fw" },
    { label: "手机复古", value: "fas fa-mobile-retro fa-fw" },
    { label: "移动按钮", value: "fas fa-mobile-button fa-fw" },
    {
      label: "麦克风线斜杠",
      value: "fas fa-microphone-lines-slash fa-fw",
    },
    { label: "麦克风线", value: "fas fa-microphone-lines fa-fw" },
    { label: "消息", value: "fas fa-message fa-fw" },
    {
      label: "手语",
      value: "fas fa-hands-asl-interpreting fa-fw",
    },
    { label: "传真", value: "fas fa-fax fa-fw" },
    { label: "脸-meh", value: "fas fa-face-meh fa-fw" },
    { label: "皱眉", value: "fas fa-face-frown fa-fw" },
    { label: "信封打开", value: "fas fa-envelope-open fa-fw" },
    {
      label: "信封-圆-检查",
      value: "fas fa-envelope-circle-check fa-fw",
    },
    { label: "耳朵听", value: "fas fa-ear-listen fa-fw" },
    { label: "评论-短信", value: "fas fa-comment-sms fa-fw" },
    { label: "注释斜杠", value: "fas fa-comment-slash fa-fw" },
    { label: "评论-医疗", value: "fas fa-comment-medical fa-fw" },
    { label: "注释点", value: "fas fa-comment-dots fa-fw" },
    { label: "扩音器", value: "fas fa-bullhorn fa-fw" },
    { label: "搅拌机-电话", value: "fas fa-blender-phone fa-fw" },
    { label: "在", value: "fas fa-at fa-fw" },
    { label: "消息", value: "far fa-message fa-fw" },
    { label: "脸-meh", value: "far fa-face-meh fa-fw" },
    { label: "皱眉", value: "far fa-face-frown fa-fw" },
    { label: "信封打开", value: "far fa-envelope-open fa-fw" },
    { label: "注释点", value: "far fa-comment-dots fa-fw" },
  ],
  连通性: [
    { label: "云", value: "fas fa-cloud fa-fw" },
    { label: "球", value: "fas fa-globe fa-fw" },
    { label: "无线网络", value: "fas fa-wifi fa-fw" },
    {
      label: "云上传",
      value: "fas fa-cloud-arrow-up fa-fw",
    },
    { label: "信号", value: "fas fa-signal fa-fw" },
    { label: "塔式单元", value: "fas fa-tower-cell fa-fw" },
    { label: "塔式广播", value: "fas fa-tower-broadcast fa-fw" },
    { label: "卫星天线", value: "fas fa-satellite-dish fa-fw" },
    { label: "RSS订阅", value: "fas fa-rss fa-fw" },
    { label: "房屋信号", value: "fas fa-house-signal fa-fw" },
    { label: "以太网", value: "fas fa-ethernet fa-fw" },
    {
      label: "cloud-arrow-down（云箭头向下）",
      value: "fas fa-cloud-arrow-down fa-fw",
    },
    { label: "圆节点", value: "fas fa-circle-nodes fa-fw" },
  ],

  设计: [
    {
      label: "魔杖-魔法-闪光",
      value: "fas fa-wand-magic-sparkles fa-fw",
    },
    { label: "笔尖", value: "fas fa-pen-nib fa-fw" },
    { label: "笔", value: "fas fa-pen fa-fw" },
    { label: "圆半冲程", value: "fas fa-circle-half-stroke fa-fw" },
    { label: "小滴", value: "fas fa-droplet fa-fw" },
    { label: "笔到正方形", value: "fas fa-pen-to-square fa-fw" },
    { label: "笔到正方形", value: "far fa-pen-to-square fa-fw" },
    { label: "眼睛", value: "fas fa-eye fa-fw" },
    { label: "眼睛", value: "far fa-eye fa-fw" },
    { label: "斜线", value: "fas fa-eye-slash fa-fw" },
    { label: "斜线", value: "far fa-eye-slash fa-fw" },
    { label: "油漆滚筒", value: "fas fa-paint-roller fa-fw" },
    { label: "刷", value: "fas fa-brush fa-fw" },
    { label: "调色板", value: "fas fa-palette fa-fw" },
    { label: "图层组", value: "fas fa-layer-group fa-fw" },
    { label: "钢笔式", value: "fas fa-pen-fancy fa-fw" },
    { label: "铅笔", value: "fas fa-pencil fa-fw" },
    { label: "复制", value: "fas fa-copy fa-fw" },
    { label: "复制", value: "far fa-copy fa-fw" },
    { label: "图标", value: "fas fa-icons fa-fw" },
    { label: "标记", value: "fas fa-marker fa-fw" },
    { label: "立方体", value: "fas fa-cube fa-fw" },
    { label: "十字准线", value: "fas fa-crosshairs fa-fw" },
    { label: "克隆", value: "fas fa-clone fa-fw" },
    { label: "克隆", value: "far fa-clone fa-fw" },
    { label: "橡皮擦", value: "fas fa-eraser fa-fw" },
    { label: "标尺-垂直", value: "fas fa-ruler-vertical fa-fw" },
    { label: "邮票", value: "fas fa-stamp fa-fw" },
    { label: "魔杖魔法", value: "fas fa-wand-magic fa-fw" },
    { label: "向量平方", value: "fas fa-vector-square fa-fw" },
    { label: "斯沃琪书", value: "fas fa-swatchbook fa-fw" },
    { label: "订书机", value: "fas fa-stapler fa-fw" },
    { label: "喷雾罐", value: "fas fa-spray-can fa-fw" },
    { label: "斑点", value: "fas fa-splotch fa-fw" },
    { label: "剪刀", value: "fas fa-scissors fa-fw" },
    { label: "标尺-水平", value: "fas fa-ruler-horizontal fa-fw" },
    { label: "组合标尺", value: "fas fa-ruler-combined fa-fw" },
    { label: "钢笔尺", value: "fas fa-pen-ruler fa-fw" },
    { label: "笔夹", value: "fas fa-pen-clip fa-fw" },
    { label: "糊", value: "fas fa-paste fa-fw" },
    { label: "画笔", value: "fas fa-paintbrush fa-fw" },
    { label: "对象取消分组", value: "fas fa-object-ungroup fa-fw" },
    { label: "对象组", value: "fas fa-object-group fa-fw" },
    { label: "线条倾斜", value: "fas fa-lines-leaning fa-fw" },
    { label: "荧光笔", value: "fas fa-highlighter fa-fw" },
    { label: "字体真棒", value: "fas fa-font-awesome fa-fw" },
    { label: "软盘", value: "fas fa-floppy-disk fa-fw" },
    { label: "填充滴灌", value: "fas fa-fill-drip fa-fw" },
    { label: "填补", value: "fas fa-fill fa-fw" },
    { label: "滴管", value: "fas fa-eye-dropper fa-fw" },
    { label: "下排斜杠", value: "fas fa-droplet-slash fa-fw" },
    { label: "绘制多边形", value: "fas fa-draw-polygon fa-fw" },
    { label: "立方体", value: "fas fa-cubes fa-fw" },
    { label: "裁剪简单", value: "fas fa-crop-simple fa-fw" },
    { label: "作物", value: "fas fa-crop fa-fw" },
    { label: "指南针制图", value: "fas fa-compass-drafting fa-fw" },
    { label: "圆节点", value: "fas fa-circle-nodes fa-fw" },
    { label: "贝塞尔曲线", value: "fas fa-bezier-curve fa-fw" },
    { label: "糊", value: "far fa-paste fa-fw" },
    { label: "对象取消分组", value: "far fa-object-ungroup fa-fw" },
    { label: "对象组", value: "far fa-object-group fa-fw" },
    { label: "字体真棒", value: "far fa-font-awesome fa-fw" },
    { label: "软盘", value: "far fa-floppy-disk fa-fw" },
  ],
  设备和硬件: [
    { label: "下载", value: "fas fa-download fa-fw" },
    { label: "相机复古", value: "fas fa-camera-retro fa-fw" },
    { label: "耳机", value: "fas fa-headphones fa-fw" },
    { label: "打印", value: "fas fa-print fa-fw" },
    { label: "照相机", value: "fas fa-camera fa-fw" },
    { label: "游戏手柄", value: "fas fa-gamepad fa-fw" },
    { label: "上传", value: "fas fa-upload fa-fw" },
    { label: "数据库", value: "fas fa-database fa-fw" },
    { label: "移动", value: "fas fa-mobile fa-fw" },
    { label: "服务器", value: "fas fa-server fa-fw" },
    { label: "笔记本", value: "fas fa-laptop fa-fw" },
    { label: "断电", value: "fas fa-power-off fa-fw" },
    { label: "桌面", value: "fas fa-desktop fa-fw" },
    { label: "步话机", value: "fas fa-walkie-talkie fa-fw" },
    { label: "微片", value: "fas fa-microchip fa-fw" },
    { label: "插头", value: "fas fa-plug fa-fw" },
    { label: "键盘", value: "fas fa-keyboard fa-fw" },
    { label: "键盘", value: "far fa-keyboard fa-fw" },
    { label: "片", value: "fas fa-tablet fa-fw" },
    { label: "电视", value: "fas fa-tv fa-fw" },
    {
      label: "行车记录仪-数字",
      value: "fas fa-tachograph-digital fa-fw",
    },
    {
      label: "平板电脑屏幕按钮",
      value: "fas fa-tablet-screen-button fa-fw",
    },
    { label: "平板电脑按钮", value: "fas fa-tablet-button fa-fw" },
    { label: "SIM卡", value: "fas fa-sim-card fa-fw" },
    { label: "SD卡", value: "fas fa-sd-card fa-fw" },
    { label: "卫星天线", value: "fas fa-satellite-dish fa-fw" },
    { label: "卫星", value: "fas fa-satellite fa-fw" },
    {
      label: "mobile-screen-button（手机屏幕按钮）",
      value: "fas fa-mobile-screen-button fa-fw",
    },
    { label: "手机屏幕", value: "fas fa-mobile-screen fa-fw" },
    { label: "手机复古", value: "fas fa-mobile-retro fa-fw" },
    { label: "移动按钮", value: "fas fa-mobile-button fa-fw" },
    { label: "记忆", value: "fas fa-memory fa-fw" },
    { label: "laptop-file", value: "fas fa-laptop-file fa-fw" },
    { label: "家用笔记本电脑", value: "fas fa-house-laptop fa-fw" },
    { label: "硬盘", value: "fas fa-hard-drive fa-fw" },
    { label: "软盘", value: "fas fa-floppy-disk fa-fw" },
    { label: "传真", value: "fas fa-fax fa-fw" },
    { label: "以太网", value: "fas fa-ethernet fa-fw" },
    { label: "显示", value: "fas fa-display fa-fw" },
    { label: "电脑鼠标", value: "fas fa-computer-mouse fa-fw" },
    { label: "计算机", value: "fas fa-computer fa-fw" },
    { label: "光盘", value: "fas fa-compact-disc fa-fw" },
    { label: "汽车电池", value: "fas fa-car-battery fa-fw" },
    { label: "搅拌机-电话", value: "fas fa-blender-phone fa-fw" },
    { label: "硬盘", value: "far fa-hard-drive fa-fw" },
    { label: "软盘", value: "far fa-floppy-disk fa-fw" },
  ],
  灾难和危机: [
    { label: "雪花", value: "fas fa-snowflake fa-fw" },
    { label: "雪花", value: "far fa-snowflake fa-fw" },
    { label: "风", value: "fas fa-wind fa-fw" },
    { label: "xmarks-lines", value: "fas fa-xmarks-lines fa-fw" },
    { label: "蠕虫", value: "fas fa-worm fa-fw" },
    {
      label: "小麦-awn-圆-感叹号",
      value: "fas fa-wheat-awn-circle-exclamation fa-fw",
    },
    { label: "火山", value: "fas fa-volcano fa-fw" },
    { label: "龙卷风", value: "fas fa-tornado fa-fw" },
    {
      label: "温度箭头向上",
      value: "fas fa-temperature-arrow-up fa-fw",
    },
    {
      label: "温度向下箭头",
      value: "fas fa-temperature-arrow-down fa-fw",
    },
    { label: "日晒植物枯萎病", value: "fas fa-sun-plant-wilt fa-fw" },
    { label: "辐射", value: "fas fa-radiation fa-fw" },
    { label: "植物枯萎病", value: "fas fa-plant-wilt fa-fw" },
    {
      label: "person-walking-虚线-arrow-right",
      value: "fas fa-person-walking-dashed-line-arrow-right fa-fw",
    },
    {
      label: "人走箭头右",
      value: "fas fa-person-walking-arrow-right fa-fw",
    },
    {
      label: "人走路箭头环左",
      value: "fas fa-person-walking-arrow-loop-left fa-fw",
    },
    { label: "人-步枪", value: "fas fa-person-rifle fa-fw" },
    { label: "溺水者", value: "fas fa-person-drowning fa-fw" },
    { label: "蚊子", value: "fas fa-mosquito fa-fw" },
    { label: "蝗", value: "fas fa-locust fa-fw" },
    { label: "飓风", value: "fas fa-hurricane fa-fw" },
    { label: "房屋海啸", value: "fas fa-house-tsunami fa-fw" },
    {
      label: "房屋-洪水-水-圆-箭头-右",
      value: "fas fa-house-flood-water-circle-arrow-right fa-fw",
    },
    { label: "房屋-洪水-水", value: "fas fa-house-flood-water fa-fw" },
    { label: "房屋火灾", value: "fas fa-house-fire fa-fw" },
    { label: "房屋裂缝", value: "fas fa-house-crack fa-fw" },
    {
      label: "房屋烟囱裂缝",
      value: "fas fa-house-chimney-crack fa-fw",
    },
    { label: "山体滑坡", value: "fas fa-hill-rockslide fa-fw" },
    { label: "山崩", value: "fas fa-hill-avalanche fa-fw" },
    { label: "头盔-联合国", value: "fas fa-helmet-un fa-fw" },
    { label: "云-阵雨-水", value: "fas fa-cloud-showers-water fa-fw" },
    { label: "多云阵雨", value: "fas fa-cloud-showers-heavy fa-fw" },
    { label: "云-螺栓", value: "fas fa-cloud-bolt fa-fw" },
    { label: "圆形辐射", value: "fas fa-circle-radiation fa-fw" },
    { label: "儿童战斗员", value: "fas fa-child-combatant fa-fw" },
    { label: "爆裂", value: "fas fa-burst fa-fw" },
    { label: "错误", value: "fas fa-bugs fa-fw" },
    { label: "生物危害", value: "fas fa-biohazard fa-fw" },
  ],
  编辑器: [
    { label: "检查", value: "fas fa-check fa-fw" },
    { label: "酒吧", value: "fas fa-bars fa-fw" },
    {
      label: "魔杖-魔法-闪光",
      value: "fas fa-wand-magic-sparkles fa-fw",
    },
    { label: "xmark（xmark）", value: "fas fa-xmark fa-fw" },
    { label: "笔尖", value: "fas fa-pen-nib fa-fw" },
    { label: "笔", value: "fas fa-pen fa-fw" },
    { label: "齿轮", value: "fas fa-gear fa-fw" },
    { label: "垃圾", value: "fas fa-trash fa-fw" },
    { label: "圆半冲程", value: "fas fa-circle-half-stroke fa-fw" },
    { label: "笔到正方形", value: "fas fa-pen-to-square fa-fw" },
    { label: "笔到正方形", value: "far fa-pen-to-square fa-fw" },
    { label: "加", value: "fas fa-plus fa-fw" },
    { label: "减去", value: "fas fa-minus fa-fw" },
    { label: "斜线", value: "fas fa-eye-slash fa-fw" },
    { label: "斜线", value: "far fa-eye-slash fa-fw" },
    { label: "滑 块", value: "fas fa-sliders fa-fw" },
    { label: "刷", value: "fas fa-brush fa-fw" },
    { label: "链接", value: "fas fa-link fa-fw" },
    { label: "钢笔式", value: "fas fa-pen-fancy fa-fw" },
    { label: "图表简单", value: "fas fa-chart-simple fa-fw" },
    { label: "铅笔", value: "fas fa-pencil fa-fw" },
    { label: "签名", value: "fas fa-signature fa-fw" },
    { label: "方形格子", value: "fas fa-square-check fa-fw" },
    { label: "方形格子", value: "far fa-square-check fa-fw" },
    { label: "立方体", value: "fas fa-cube fa-fw" },
    { label: "圆圈检查", value: "fas fa-circle-check fa-fw" },
    { label: "圆圈检查", value: "far fa-circle-check fa-fw" },
    { label: "魔杖魔法", value: "fas fa-wand-magic fa-fw" },
    {
      label: "垃圾桶箭头向上",
      value: "fas fa-trash-can-arrow-up fa-fw",
    },
    { label: "垃圾桶", value: "fas fa-trash-can fa-fw" },
    { label: "垃圾桶箭头", value: "fas fa-trash-arrow-up fa-fw" },
    { label: "方笔", value: "fas fa-square-pen fa-fw" },
    { label: "剪刀", value: "fas fa-scissors fa-fw" },
    { label: "旋转", value: "fas fa-rotate fa-fw" },
    { label: "钢笔尺", value: "fas fa-pen-ruler fa-fw" },
    { label: "笔夹", value: "fas fa-pen-clip fa-fw" },
    { label: "画笔", value: "fas fa-paintbrush fa-fw" },
    { label: "链接斜杠", value: "fas fa-link-slash fa-fw" },
    { label: "垂直握把", value: "fas fa-grip-vertical fa-fw" },
    {
      label: "Grip-Lines-垂直",
      value: "fas fa-grip-lines-vertical fa-fw",
    },
    { label: "抓地力线", value: "fas fa-grip-lines fa-fw" },
    { label: "握", value: "fas fa-grip fa-fw" },
    { label: "滴管", value: "fas fa-eye-dropper fa-fw" },
    { label: "椭圆-垂直", value: "fas fa-ellipsis-vertical fa-fw" },
    { label: "省略", value: "fas fa-ellipsis fa-fw" },
    { label: "删除-左", value: "fas fa-delete-left fa-fw" },
    { label: "裁剪简单", value: "fas fa-crop-simple fa-fw" },
    { label: "作物", value: "fas fa-crop fa-fw" },
    { label: "双人检查", value: "fas fa-check-double fa-fw" },
    { label: "绷带", value: "fas fa-bandage fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-rotate fa-fw" },
    { label: "垃圾桶", value: "far fa-trash-can fa-fw" },
  ],
  教育: [
    { label: "音乐", value: "fas fa-music fa-fw" },
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "学校", value: "fas fa-school fa-fw" },
    { label: "奖", value: "fas fa-award fa-fw" },
    { label: "黑板", value: "fas fa-chalkboard fa-fw" },
    { label: "用户毕业生", value: "fas fa-user-graduate fa-fw" },
    { label: "形状", value: "fas fa-shapes fa-fw" },
    { label: "学校锁", value: "fas fa-school-lock fa-fw" },
    { label: "校旗", value: "fas fa-school-flag fa-fw" },
    {
      label: "学校圈子-xmark",
      value: "fas fa-school-circle-xmark fa-fw",
    },
    {
      label: "学校-圈子-感叹号",
      value: "fas fa-school-circle-exclamation fa-fw",
    },
    {
      label: "学校圈子检查",
      value: "fas fa-school-circle-check fa-fw",
    },
    { label: "人-黑板", value: "fas fa-person-chalkboard fa-fw" },
    { label: "显微镜", value: "fas fa-microscope fa-fw" },
    { label: "面具剧院", value: "fas fa-masks-theater fa-fw" },
    { label: "laptop-file", value: "fas fa-laptop-file fa-fw" },
    { label: "笔记本电脑代码", value: "fas fa-laptop-code fa-fw" },
    { label: "毕业帽", value: "fas fa-graduation-cap fa-fw" },
    { label: "黑板用户", value: "fas fa-chalkboard-user fa-fw" },
    {
      label: "book-open-reader",
      value: "fas fa-book-open-reader fa-fw",
    },
    { label: "开书", value: "fas fa-book-open fa-fw" },
    { label: "铃铛斜杠", value: "fas fa-bell-slash fa-fw" },
    { label: "原子", value: "fas fa-atom fa-fw" },
    { label: "整个苹果", value: "fas fa-apple-whole fa-fw" },
    { label: "铃铛斜杠", value: "far fa-bell-slash fa-fw" },
  ],
  表情: [
    { label: "面带微笑", value: "fas fa-face-smile fa-fw" },
    { label: "面带微笑", value: "far fa-face-smile fa-fw" },
    { label: "脸部疲惫", value: "fas fa-face-tired fa-fw" },
    { label: "脸部惊喜", value: "fas fa-face-surprise fa-fw" },
    { label: "脸-微笑-眨眼", value: "fas fa-face-smile-wink fa-fw" },
    { label: "脸-微笑-光束", value: "fas fa-face-smile-beam fa-fw" },
    { label: "脸-悲伤-泪水", value: "fas fa-face-sad-tear fa-fw" },
    { label: "脸-悲伤-哭泣", value: "fas fa-face-sad-cry fa-fw" },
    { label: "翻脸白眼", value: "fas fa-face-rolling-eyes fa-fw" },
    { label: "脸-meh-blank", value: "fas fa-face-meh-blank fa-fw" },
    { label: "脸-meh", value: "fas fa-face-meh fa-fw" },
    { label: "脸-笑-眨眼", value: "fas fa-face-laugh-wink fa-fw" },
    { label: "脸-笑-眯眼", value: "fas fa-face-laugh-squint fa-fw" },
    { label: "脸-笑-光束", value: "fas fa-face-laugh-beam fa-fw" },
    { label: "笑脸笑", value: "fas fa-face-laugh fa-fw" },
    {
      label: "脸-吻-眨眼-心",
      value: "fas fa-face-kiss-wink-heart fa-fw",
    },
    { label: "脸-吻-光束", value: "fas fa-face-kiss-beam fa-fw" },
    { label: "脸吻", value: "fas fa-face-kiss fa-fw" },
    { label: "脸-咧嘴-眨眼", value: "fas fa-face-grin-wink fa-fw" },
    { label: "脸笑得很宽", value: "fas fa-face-grin-wide fa-fw" },
    {
      label: "脸-咧嘴-舌头-眨眼",
      value: "fas fa-face-grin-tongue-wink fa-fw",
    },
    {
      label: "脸-咧嘴-眯舌-眯眼",
      value: "fas fa-face-grin-tongue-squint fa-fw",
    },
    { label: "脸-咧嘴-舌头", value: "fas fa-face-grin-tongue fa-fw" },
    { label: "脸-咧嘴-泪水", value: "fas fa-face-grin-tears fa-fw" },
    { label: "脸-咧嘴-星星", value: "fas fa-face-grin-stars fa-fw" },
    {
      label: "脸-咧嘴-眯眼-泪水",
      value: "fas fa-face-grin-squint-tears fa-fw",
    },
    { label: "脸-咧嘴-眯眼", value: "fas fa-face-grin-squint fa-fw" },
    { label: "脸-咧嘴-心", value: "fas fa-face-grin-hearts fa-fw" },
    {
      label: "脸-咧嘴笑-光束-汗水",
      value: "fas fa-face-grin-beam-sweat fa-fw",
    },
    { label: "脸-咧嘴笑-光束", value: "fas fa-face-grin-beam fa-fw" },
    { label: "面容咧嘴笑", value: "fas fa-face-grin fa-fw" },
    { label: "脸鬼脸", value: "fas fa-face-grimace fa-fw" },
    { label: "皱眉-张开", value: "fas fa-face-frown-open fa-fw" },
    { label: "皱眉", value: "fas fa-face-frown fa-fw" },
    { label: "脸部潮红", value: "fas fa-face-flushed fa-fw" },
    { label: "脸部头晕", value: "fas fa-face-dizzy fa-fw" },
    { label: "脸生气", value: "fas fa-face-angry fa-fw" },
    { label: "脸部疲惫", value: "far fa-face-tired fa-fw" },
    { label: "脸部惊喜", value: "far fa-face-surprise fa-fw" },
    { label: "脸-微笑-眨眼", value: "far fa-face-smile-wink fa-fw" },
    { label: "脸-微笑-光束", value: "far fa-face-smile-beam fa-fw" },
    { label: "脸-悲伤-泪水", value: "far fa-face-sad-tear fa-fw" },
    { label: "脸-悲伤-哭泣", value: "far fa-face-sad-cry fa-fw" },
    { label: "翻脸白眼", value: "far fa-face-rolling-eyes fa-fw" },
    { label: "脸-meh-blank", value: "far fa-face-meh-blank fa-fw" },
    { label: "脸-meh", value: "far fa-face-meh fa-fw" },
    { label: "脸-笑-眨眼", value: "far fa-face-laugh-wink fa-fw" },
    { label: "脸-笑-眯眼", value: "far fa-face-laugh-squint fa-fw" },
    { label: "脸-笑-光束", value: "far fa-face-laugh-beam fa-fw" },
    { label: "笑脸笑", value: "far fa-face-laugh fa-fw" },
    {
      label: "脸-吻-眨眼-心",
      value: "far fa-face-kiss-wink-heart fa-fw",
    },
    { label: "脸-吻-光束", value: "far fa-face-kiss-beam fa-fw" },
    { label: "脸吻", value: "far fa-face-kiss fa-fw" },
    { label: "脸-咧嘴-眨眼", value: "far fa-face-grin-wink fa-fw" },
    { label: "脸笑得很宽", value: "far fa-face-grin-wide fa-fw" },
    {
      label: "脸-咧嘴-舌头-眨眼",
      value: "far fa-face-grin-tongue-wink fa-fw",
    },
    {
      label: "脸-咧嘴-眯舌-眯眼",
      value: "far fa-face-grin-tongue-squint fa-fw",
    },
    {
      label: "脸-咧嘴-舌头",
      value: "far fa-face-grin-tongue fa-fw",
    },
    { label: "脸-咧嘴-泪水", value: "far fa-face-grin-tears fa-fw" },
    { label: "脸-咧嘴-星星", value: "far fa-face-grin-stars fa-fw" },
    {
      label: "脸-咧嘴-眯眼-泪水",
      value: "far fa-face-grin-squint-tears fa-fw",
    },
    {
      label: "脸-咧嘴-眯眼",
      value: "far fa-face-grin-squint fa-fw",
    },
    { label: "脸-咧嘴-心", value: "far fa-face-grin-hearts fa-fw" },
    {
      label: "脸-咧嘴笑-光束-汗水",
      value: "far fa-face-grin-beam-sweat fa-fw",
    },
    {
      label: "脸-咧嘴笑-光束",
      value: "far fa-face-grin-beam fa-fw",
    },
    { label: "面容咧嘴笑", value: "far fa-face-grin fa-fw" },
    { label: "脸鬼脸", value: "far fa-face-grimace fa-fw" },
    { label: "皱眉-张开", value: "far fa-face-frown-open fa-fw" },
    { label: "皱眉", value: "far fa-face-frown fa-fw" },
    { label: "脸部潮红", value: "far fa-face-flushed fa-fw" },
    { label: "脸部头晕", value: "far fa-face-dizzy fa-fw" },
    { label: "脸生气", value: "far fa-face-angry fa-fw" },
  ],

  电影和视频: [
    { label: "影片", value: "fas fa-film fa-fw" },
    { label: "耳机", value: "fas fa-headphones fa-fw" },
    { label: "视频", value: "fas fa-video fa-fw" },
    { label: "票", value: "fas fa-ticket fa-fw" },
    { label: "圈", value: "fas fa-circle fa-fw" },
    { label: "圈", value: "far fa-circle fa-fw" },
    { label: "视频斜杠", value: "fas fa-video-slash fa-fw" },
    { label: "音频描述", value: "fas fa-audio-description fa-fw" },
    { label: "隐藏式字幕", value: "fas fa-closed-captioning fa-fw" },
    { label: "隐藏式字幕", value: "far fa-closed-captioning fa-fw" },
    { label: "麦克风斜杠", value: "fas fa-microphone-slash fa-fw" },
    { label: "麦克风", value: "fas fa-microphone fa-fw" },
    { label: "电视", value: "fas fa-tv fa-fw" },
    { label: "塔式单元", value: "fas fa-tower-cell fa-fw" },
    { label: "塔式广播", value: "fas fa-tower-broadcast fa-fw" },
    { label: "方形RSS", value: "fas fa-square-rss fa-fw" },
    { label: "播客", value: "fas fa-podcast fa-fw" },
    { label: "照片胶片", value: "fas fa-photo-film fa-fw" },
    {
      label: "麦克风线斜杠",
      value: "fas fa-microphone-lines-slash fa-fw",
    },
    { label: "麦克风线", value: "fas fa-microphone-lines fa-fw" },
    { label: "文件-视频", value: "fas fa-file-video fa-fw" },
    { label: "文件-音频", value: "fas fa-file-audio fa-fw" },
    { label: "光盘", value: "fas fa-compact-disc fa-fw" },
    { label: "拍板", value: "fas fa-clapperboard fa-fw" },
    { label: "文件-视频", value: "far fa-file-video fa-fw" },
    { label: "文件-音频", value: "far fa-file-audio fa-fw" },
  ],
  食品和饮料: [
    { label: "杯热", value: "fas fa-mug-hot fa-fw" },
    { label: "柠檬", value: "fas fa-lemon fa-fw" },
    { label: "柠檬", value: "far fa-lemon fa-fw" },
    { label: "瓶", value: "fas fa-flask fa-fw" },
    { label: "鱼", value: "fas fa-fish fa-fw" },
    { label: "马克杯碟", value: "fas fa-mug-saucer fa-fw" },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "酒瓶", value: "fas fa-wine-bottle fa-fw" },
    { label: "酒杯-空", value: "fas fa-wine-glass-empty fa-fw" },
    { label: "酒杯", value: "fas fa-wine-glass fa-fw" },
    { label: "威士忌-玻璃杯", value: "fas fa-whiskey-glass fa-fw" },
    {
      label: "小麦-awn-圆-感叹号",
      value: "fas fa-wheat-awn-circle-exclamation fa-fw",
    },
    { label: "小麦-芒", value: "fas fa-wheat-awn fa-fw" },
    { label: "斯特罗普瓦菲尔", value: "fas fa-stroopwafel fa-fw" },
    { label: "虾", value: "fas fa-shrimp fa-fw" },
    { label: "平板小麦", value: "fas fa-plate-wheat fa-fw" },
    { label: "披萨片", value: "fas fa-pizza-slice fa-fw" },
    { label: "胡椒辣", value: "fas fa-pepper-hot fa-fw" },
    {
      label: "马提尼酒杯空",
      value: "fas fa-martini-glass-empty fa-fw",
    },
    {
      label: "马提尼-玻璃-柑橘",
      value: "fas fa-martini-glass-citrus fa-fw",
    },
    { label: "马提尼酒杯", value: "fas fa-martini-glass fa-fw" },
    { label: "罐装小麦", value: "fas fa-jar-wheat fa-fw" },
    { label: "罐", value: "fas fa-jar fa-fw" },
    { label: "冰淇淋", value: "fas fa-ice-cream fa-fw" },
    { label: "热狗", value: "fas fa-hotdog fa-fw" },
    { label: "玻璃水滴", value: "fas fa-glass-water-droplet fa-fw" },
    { label: "玻璃-水", value: "fas fa-glass-water fa-fw" },
    { label: "鱼翅", value: "fas fa-fish-fins fa-fw" },
    { label: "蛋", value: "fas fa-egg fa-fw" },
    { label: "鸡腿咬合", value: "fas fa-drumstick-bite fa-fw" },
    { label: "立方体堆叠", value: "fas fa-cubes-stacked fa-fw" },
    { label: "饼干", value: "fas fa-cookie fa-fw" },
    { label: "云肉丸", value: "fas fa-cloud-meatball fa-fw" },
    { label: "奶酪", value: "fas fa-cheese fa-fw" },
    { label: "香槟酒杯", value: "fas fa-champagne-glasses fa-fw" },
    { label: "胡萝卜", value: "fas fa-carrot fa-fw" },
    { label: "甘蔗糖", value: "fas fa-candy-cane fa-fw" },
    { label: "蛋糕蜡烛", value: "fas fa-cake-candles fa-fw" },
    { label: "汉堡", value: "fas fa-burger fa-fw" },
    { label: "面包片", value: "fas fa-bread-slice fa-fw" },
    { label: "碗饭", value: "fas fa-bowl-rice fa-fw" },
    { label: "碗状食物", value: "fas fa-bowl-food fa-fw" },
    { label: "瓶装水", value: "fas fa-bottle-water fa-fw" },
    { label: "瓶滴", value: "fas fa-bottle-droplet fa-fw" },
    { label: "骨", value: "fas fa-bone fa-fw" },
    { label: "混合器", value: "fas fa-blender fa-fw" },
    { label: "啤酒杯空的", value: "fas fa-beer-mug-empty fa-fw" },
    { label: "培根", value: "fas fa-bacon fa-fw" },
    { label: "整个苹果", value: "fas fa-apple-whole fa-fw" },
  ],
  水果和蔬菜: [
    { label: "柠檬", value: "fas fa-lemon fa-fw" },
    { label: "柠檬", value: "far fa-lemon fa-fw" },
    { label: "叶", value: "fas fa-leaf fa-fw" },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "胡椒辣", value: "fas fa-pepper-hot fa-fw" },
    { label: "胡萝卜", value: "fas fa-carrot fa-fw" },
    { label: "整个苹果", value: "fas fa-apple-whole fa-fw" },
  ],
  游戏: [
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "屏蔽减半", value: "fas fa-shield-halved fa-fw" },
    { label: "鬼", value: "fas fa-ghost fa-fw" },
    { label: "游戏手柄", value: "fas fa-gamepad fa-fw" },
    { label: "钻石", value: "fas fa-diamond fa-fw" },
    { label: "龙", value: "fas fa-dragon fa-fw" },
    { label: "滚动", value: "fas fa-scroll fa-fw" },
    { label: "拼图", value: "fas fa-puzzle-piece fa-fw" },
    { label: "骰子", value: "fas fa-dice fa-fw" },
    { label: "戒指", value: "fas fa-ring fa-fw" },
    { label: "耳机", value: "fas fa-headset fa-fw" },
    { label: "魔杖闪闪发光", value: "fas fa-wand-sparkles fa-fw" },
    { label: "VR纸板", value: "fas fa-vr-cardboard fa-fw" },
    { label: "方形-满", value: "fas fa-square-full fa-fw" },
    { label: "颅骨交叉骨", value: "fas fa-skull-crossbones fa-fw" },
    { label: "帽子向导", value: "fas fa-hat-wizard fa-fw" },
    { label: "手拳", value: "fas fa-hand-fist fa-fw" },
    { label: "地牢", value: "fas fa-dungeon fa-fw" },
    { label: "骰子-二", value: "fas fa-dice-two fa-fw" },
    { label: "骰子三", value: "fas fa-dice-three fa-fw" },
    { label: "骰子-六", value: "fas fa-dice-six fa-fw" },
    { label: "骰子一", value: "fas fa-dice-one fa-fw" },
    { label: "骰子-四", value: "fas fa-dice-four fa-fw" },
    { label: "骰子-5", value: "fas fa-dice-five fa-fw" },
    { label: "骰子-D6", value: "fas fa-dice-d6 fa-fw" },
    { label: "骰子-D20", value: "fas fa-dice-d20 fa-fw" },
    { label: "国际象棋车", value: "fas fa-chess-rook fa-fw" },
    { label: "国际象棋皇后", value: "fas fa-chess-queen fa-fw" },
    { label: "国际象棋棋子", value: "fas fa-chess-pawn fa-fw" },
    { label: "国际象棋骑士", value: "fas fa-chess-knight fa-fw" },
    { label: "国际象棋王", value: "fas fa-chess-king fa-fw" },
    { label: "棋盘", value: "fas fa-chess-board fa-fw" },
    { label: "国际象棋主教", value: "fas fa-chess-bishop fa-fw" },
    { label: "棋", value: "fas fa-chess fa-fw" },
    { label: "书骷髅头", value: "fas fa-book-skull fa-fw" },
    { label: "方形-满", value: "far fa-square-full fa-fw" },
    { label: "国际象棋车", value: "far fa-chess-rook fa-fw" },
    { label: "国际象棋皇后", value: "far fa-chess-queen fa-fw" },
    { label: "国际象棋棋子", value: "far fa-chess-pawn fa-fw" },
    { label: "国际象棋骑士", value: "far fa-chess-knight fa-fw" },
    { label: "国际象棋王", value: "far fa-chess-king fa-fw" },
    { label: "国际象棋主教", value: "far fa-chess-bishop fa-fw" },
  ],
  性别: [
    { label: "金星-火星", value: "fas fa-venus-mars fa-fw" },
    { label: "金星双倍", value: "fas fa-venus-double fa-fw" },
    { label: "金星", value: "fas fa-venus fa-fw" },
    { label: "跨性人", value: "fas fa-transgender fa-fw" },
    { label: "人半连衣裙", value: "fas fa-person-half-dress fa-fw" },
    { label: "中性", value: "fas fa-neuter fa-fw" },
    { label: "汞", value: "fas fa-mercury fa-fw" },
    { label: "火星中风", value: "fas fa-mars-stroke-up fa-fw" },
    { label: "火星中风右", value: "fas fa-mars-stroke-right fa-fw" },
    { label: "火星中风", value: "fas fa-mars-stroke fa-fw" },
    { label: "火星双", value: "fas fa-mars-double fa-fw" },
    { label: "火星和金星", value: "fas fa-mars-and-venus fa-fw" },
    { label: "火星", value: "fas fa-mars fa-fw" },
    { label: "不分性别", value: "fas fa-genderless fa-fw" },
  ],
  万圣节: [
    { label: "鬼", value: "fas fa-ghost fa-fw" },
    { label: "面具", value: "fas fa-mask fa-fw" },
    { label: "魔杖闪闪发光", value: "fas fa-wand-sparkles fa-fw" },
    { label: "卫生纸", value: "fas fa-toilet-paper fa-fw" },
    { label: "蜘蛛", value: "fas fa-spider fa-fw" },
    { label: "颅骨交叉骨", value: "fas fa-skull-crossbones fa-fw" },
    { label: "头盖骨", value: "fas fa-skull fa-fw" },
    { label: "帽子向导", value: "fas fa-hat-wizard fa-fw" },
    { label: "乌鸦", value: "fas fa-crow fa-fw" },
    { label: "云月，", value: "fas fa-cloud-moon fa-fw" },
    { label: "猫", value: "fas fa-cat fa-fw" },
    { label: "扫帚", value: "fas fa-broom fa-fw" },
    { label: "书骷髅头", value: "fas fa-book-skull fa-fw" },
  ],
  手: [
    { label: "手", value: "fas fa-hand fa-fw" },
    { label: "手", value: "far fa-hand fa-fw" },
    { label: "竖起大拇指", value: "fas fa-thumbs-up fa-fw" },
    { label: "竖起大拇指", value: "far fa-thumbs-up fa-fw" },
    { label: "竖起大拇指", value: "fas fa-thumbs-down fa-fw" },
    { label: "竖起大拇指", value: "far fa-thumbs-down fa-fw" },
    { label: "握手", value: "fas fa-handshake fa-fw" },
    { label: "握手", value: "far fa-handshake fa-fw" },
    { label: "手牵心", value: "fas fa-hand-holding-heart fa-fw" },
    { label: "握手斜杠", value: "fas fa-handshake-slash fa-fw" },
    { label: "手动指向", value: "fas fa-hand-point-up fa-fw" },
    { label: "手动指向", value: "far fa-hand-point-up fa-fw" },
    {
      label: "握手-简单-斜杠",
      value: "fas fa-handshake-simple-slash fa-fw",
    },
    { label: "握手-简单", value: "fas fa-handshake-simple fa-fw" },
    { label: "握手角度", value: "fas fa-handshake-angle fa-fw" },
    { label: "双手祷告", value: "fas fa-hands-praying fa-fw" },
    { label: "手牵手圈", value: "fas fa-hands-holding-circle fa-fw" },
    {
      label: "手拉手-儿童",
      value: "fas fa-hands-holding-child fa-fw",
    },
    { label: "手拉手", value: "fas fa-hands-holding fa-fw" },
    { label: "拍手", value: "fas fa-hands-clapping fa-fw" },
    { label: "手泡", value: "fas fa-hands-bubbles fa-fw" },
    { label: "双手束缚", value: "fas fa-hands-bound fa-fw" },
    { label: "手拍", value: "fas fa-hand-spock fa-fw" },
    { label: "手部闪光", value: "fas fa-hand-sparkles fa-fw" },
    { label: "手剪刀", value: "fas fa-hand-scissors fa-fw" },
    { label: "手指针", value: "fas fa-hand-pointer fa-fw" },
    { label: "手指向右", value: "fas fa-hand-point-right fa-fw" },
    { label: "手指向左", value: "fas fa-hand-point-left fa-fw" },
    { label: "手下", value: "fas fa-hand-point-down fa-fw" },
    { label: "手和平", value: "fas fa-hand-peace fa-fw" },
    { label: "手-中指-", value: "fas fa-hand-middle-finger fa-fw" },
    { label: "手蜥蜴", value: "fas fa-hand-lizard fa-fw" },
    { label: "手持医疗", value: "fas fa-hand-holding-medical fa-fw" },
    { label: "手牵手", value: "fas fa-hand-holding-hand fa-fw" },
    { label: "手持液滴", value: "fas fa-hand-holding-droplet fa-fw" },
    { label: "手握美元", value: "fas fa-hand-holding-dollar fa-fw" },
    { label: "手把手", value: "fas fa-hand-holding fa-fw" },
    { label: "手拳", value: "fas fa-hand-fist fa-fw" },
    { label: "手点", value: "fas fa-hand-dots fa-fw" },
    { label: "手背拳头", value: "fas fa-hand-back-fist fa-fw" },
    { label: "手拍", value: "far fa-hand-spock fa-fw" },
    { label: "手剪刀", value: "far fa-hand-scissors fa-fw" },
    { label: "手指针", value: "far fa-hand-pointer fa-fw" },
    { label: "手指向右", value: "far fa-hand-point-right fa-fw" },
    { label: "手指向左", value: "far fa-hand-point-left fa-fw" },
    { label: "手下", value: "far fa-hand-point-down fa-fw" },
    { label: "手和平", value: "far fa-hand-peace fa-fw" },
    { label: "手蜥蜴", value: "far fa-hand-lizard fa-fw" },
    { label: "手背拳头", value: "far fa-hand-back-fist fa-fw" },
  ],
  假日: [
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "杯热", value: "fas fa-mug-hot fa-fw" },
    { label: "礼物", value: "fas fa-gift fa-fw" },
    { label: "饼干咬", value: "fas fa-cookie-bite fa-fw" },
    { label: "雪人", value: "fas fa-snowman fa-fw" },
    { label: "雪橇", value: "fas fa-sleigh fa-fw" },
    { label: "灯台", value: "fas fa-menorah fa-fw" },
    { label: "冬青浆果", value: "fas fa-holly-berry fa-fw" },
    { label: "礼物", value: "fas fa-gifts fa-fw" },
    {
      label: "脸-吻-眨眼-心",
      value: "fas fa-face-kiss-wink-heart fa-fw",
    },
    { label: "脸-咧嘴-心", value: "fas fa-face-grin-hearts fa-fw" },
    { label: "香槟酒杯", value: "fas fa-champagne-glasses fa-fw" },
    { label: "胡萝卜", value: "fas fa-carrot fa-fw" },
    { label: "甘蔗糖", value: "fas fa-candy-cane fa-fw" },
    {
      label: "脸-吻-眨眼-心",
      value: "far fa-face-kiss-wink-heart fa-fw",
    },
    { label: "脸-咧嘴-心", value: "far fa-face-grin-hearts fa-fw" },
  ],
  家庭: [
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "浴", value: "fas fa-bath fa-fw" },
    { label: "雪花", value: "fas fa-snowflake fa-fw" },
    { label: "雪花", value: "far fa-snowflake fa-fw" },
    { label: "马克杯碟", value: "fas fa-mug-saucer fa-fw" },
    { label: "开门式", value: "fas fa-door-open fa-fw" },
    { label: "淋浴", value: "fas fa-shower fa-fw" },
    { label: "椅子", value: "fas fa-chair fa-fw" },
    { label: "插头", value: "fas fa-plug fa-fw" },
    { label: "肥皂", value: "fas fa-soap fa-fw" },
    { label: "灯泡", value: "fas fa-lightbulb fa-fw" },
    { label: "灯泡", value: "far fa-lightbulb fa-fw" },
    { label: "用具", value: "fas fa-utensils fa-fw" },
    { label: "电视", value: "fas fa-tv fa-fw" },
    { label: "卫生纸斜线", value: "fas fa-toilet-paper-slash fa-fw" },
    { label: "卫生纸", value: "fas fa-toilet-paper fa-fw" },
    { label: "厕所", value: "fas fa-toilet fa-fw" },
    {
      label: "温度箭头向上",
      value: "fas fa-temperature-arrow-up fa-fw",
    },
    {
      label: "温度向下箭头",
      value: "fas fa-temperature-arrow-down fa-fw",
    },
    { label: "楼梯", value: "fas fa-stairs fa-fw" },
    { label: "勺子", value: "fas fa-spoon fa-fw" },
    { label: "沉", value: "fas fa-sink fa-fw" },
    { label: "塑料片材", value: "fas fa-sheet-plastic fa-fw" },
    { label: "地毯", value: "fas fa-rug fa-fw" },
    { label: "泵-肥皂", value: "fas fa-pump-soap fa-fw" },
    { label: "人顶", value: "fas fa-people-roof fa-fw" },
    { label: "床垫枕头", value: "fas fa-mattress-pillow fa-fw" },
    { label: "厨房套装", value: "fas fa-kitchen-set fa-fw" },
    { label: "水壶洗涤剂", value: "fas fa-jug-detergent fa-fw" },
    { label: "罐装小麦", value: "fas fa-jar-wheat fa-fw" },
    { label: "罐", value: "fas fa-jar fa-fw" },
    { label: "房屋用户", value: "fas fa-house-user fa-fw" },
    { label: "房屋信号", value: "fas fa-house-signal fa-fw" },
    { label: "房屋锁", value: "fas fa-house-lock fa-fw" },
    { label: "家用笔记本电脑", value: "fas fa-house-laptop fa-fw" },
    { label: "房屋火灾", value: "fas fa-house-fire fa-fw" },
    {
      label: "房屋烟囱窗户",
      value: "fas fa-house-chimney-window fa-fw",
    },
    {
      label: "房屋烟囱用户",
      value: "fas fa-house-chimney-user fa-fw",
    },
    { label: "燃烧器", value: "fas fa-fire-burner fa-fw" },
    { label: "水龙头滴水", value: "fas fa-faucet-drip fa-fw" },
    { label: "龙头", value: "fas fa-faucet fa-fw" },
    { label: "扇", value: "fas fa-fan fa-fw" },
    { label: "地牢", value: "fas fa-dungeon fa-fw" },
    { label: "门关闭", value: "fas fa-door-closed fa-fw" },
    { label: "沙发", value: "fas fa-couch fa-fw" },
    { label: "计算机", value: "fas fa-computer fa-fw" },
    { label: "盒装纸巾", value: "fas fa-box-tissue fa-fw" },
    { label: "混合器", value: "fas fa-blender fa-fw" },
    { label: "床", value: "fas fa-bed fa-fw" },
    {
      label: "从水泵向上箭头",
      value: "fas fa-arrow-up-from-water-pump fa-fw",
    },
  ],

  航海: [
    { label: "鱼", value: "fas fa-fish fa-fw" },
    { label: "锚", value: "fas fa-anchor fa-fw" },
    { label: "水", value: "fas fa-water fa-fw" },
    { label: "船", value: "fas fa-ship fa-fw" },
    { label: "水獭", value: "fas fa-otter fa-fw" },
    { label: "虾", value: "fas fa-shrimp fa-fw" },
    { label: "帆船", value: "fas fa-sailboat fa-fw" },
    { label: "人游泳", value: "fas fa-person-swimming fa-fw" },
    { label: "鱼翅", value: "fas fa-fish-fins fa-fw" },
    { label: "渡轮", value: "fas fa-ferry fa-fw" },
    { label: "锚锁", value: "fas fa-anchor-lock fa-fw" },
    {
      label: "锚-圆-xmark",
      value: "fas fa-anchor-circle-xmark fa-fw",
    },
    {
      label: "锚-圆-感叹号",
      value: "fas fa-anchor-circle-exclamation fa-fw",
    },
    { label: "锚-圆-检查", value: "fas fa-anchor-circle-check fa-fw" },
  ],

  数学计算: [
    { label: "圆圈-xmark", value: "fas fa-circle-xmark fa-fw" },
    { label: "圆圈-xmark", value: "far fa-circle-xmark fa-fw" },
    { label: "xmark（xmark）", value: "fas fa-xmark fa-fw" },
    { label: "加", value: "fas fa-plus fa-fw" },
    { label: "减去", value: "fas fa-minus fa-fw" },
    { label: "方形xmark", value: "fas fa-square-xmark fa-fw" },
    { label: "计算器", value: "fas fa-calculator fa-fw" },
    { label: "百分之", value: "fas fa-percent fa-fw" },
    { label: "平方-负", value: "fas fa-square-minus fa-fw" },
    { label: "平方-负", value: "far fa-square-minus fa-fw" },
    { label: "不相等", value: "fas fa-not-equal fa-fw" },
    { label: "下标", value: "fas fa-subscript fa-fw" },
    { label: "波方", value: "fas fa-wave-square fa-fw" },
    { label: "上标", value: "fas fa-superscript fa-fw" },
    {
      label: "平方根变量",
      value: "fas fa-square-root-variable fa-fw",
    },
    { label: "正负值", value: "fas fa-plus-minus fa-fw" },
    { label: "不相等", value: "fas fa-less-than-equal fa-fw" },
    { label: "小于", value: "fas fa-less-than fa-fw" },
    { label: "无限", value: "fas fa-infinity fa-fw" },
    { label: "大于等于", value: "fas fa-greater-than-equal fa-fw" },
    { label: "大于", value: "fas fa-greater-than fa-fw" },
    { label: "等于", value: "fas fa-equals fa-fw" },
    { label: "分", value: "fas fa-divide fa-fw" },
    { label: "圆加", value: "fas fa-circle-plus fa-fw" },
    { label: "圆减", value: "fas fa-circle-minus fa-fw" },
  ],
  媒体播放: [
    { label: "音乐", value: "fas fa-music fa-fw" },
    { label: "向右旋转", value: "fas fa-rotate-right fa-fw" },
    { label: "手", value: "fas fa-hand fa-fw" },
    { label: "手", value: "far fa-hand fa-fw" },
    { label: "滑 块", value: "fas fa-sliders fa-fw" },
    { label: "玩", value: "fas fa-play fa-fw" },
    { label: "向后", value: "fas fa-backward fa-fw" },
    { label: "电话音量", value: "fas fa-phone-volume fa-fw" },
    { label: "向前", value: "fas fa-forward fa-fw" },
    { label: "停", value: "fas fa-stop fa-fw" },
    { label: "扩大", value: "fas fa-expand fa-fw" },
    { label: "暂停", value: "fas fa-pause fa-fw" },
    { label: "重复", value: "fas fa-repeat fa-fw" },
    { label: "卷-xmark", value: "fas fa-volume-xmark fa-fw" },
    { label: "音量关闭", value: "fas fa-volume-off fa-fw" },
    { label: "音量低", value: "fas fa-volume-low fa-fw" },
    { label: "高音量", value: "fas fa-volume-high fa-fw" },
    {
      label: "从中心向上-右-下-左-下",
      value: "fas fa-up-right-and-down-left-from-center fa-fw",
    },
    { label: "洗牌", value: "fas fa-shuffle fa-fw" },
    { label: "RSS订阅", value: "fas fa-rss fa-fw" },
    { label: "向左旋转", value: "fas fa-rotate-left fa-fw" },
    { label: "旋转", value: "fas fa-rotate fa-fw" },
    { label: "正负值", value: "fas fa-plus-minus fa-fw" },
    { label: "最小化", value: "fas fa-minimize fa-fw" },
    { label: "最大化", value: "fas fa-maximize fa-fw" },
    { label: "前进", value: "fas fa-forward-step fa-fw" },
    { label: "快速前进", value: "fas fa-forward-fast fa-fw" },
    { label: "弹出", value: "fas fa-eject fa-fw" },
    {
      label: "从左下到右上到中心",
      value: "fas fa-down-left-and-up-right-to-center fa-fw",
    },
    { label: "压缩", value: "fas fa-compress fa-fw" },
    { label: "圆圈停止", value: "fas fa-circle-stop fa-fw" },
    { label: "圆圈游戏", value: "fas fa-circle-play fa-fw" },
    { label: "圆圈暂停", value: "fas fa-circle-pause fa-fw" },
    { label: "后退", value: "fas fa-backward-step fa-fw" },
    { label: "向后快速", value: "fas fa-backward-fast fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-rotate fa-fw" },
    {
      label: "向右箭头旋转",
      value: "fas fa-arrow-rotate-right fa-fw",
    },
    {
      label: "arrow-rotate-left",
      value: "fas fa-arrow-rotate-left fa-fw",
    },
    { label: "圆圈停止", value: "far fa-circle-stop fa-fw" },
    { label: "圆圈游戏", value: "far fa-circle-play fa-fw" },
    { label: "圆圈暂停", value: "far fa-circle-pause fa-fw" },
  ],
  医疗和健康: [
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "小滴", value: "fas fa-droplet fa-fw" },
    { label: "加", value: "fas fa-plus fa-fw" },
    { label: "眼睛", value: "fas fa-eye fa-fw" },
    { label: "眼睛", value: "far fa-eye fa-fw" },
    { label: "瓶", value: "fas fa-flask fa-fw" },
    { label: "听诊器", value: "fas fa-stethoscope fa-fw" },
    { label: "笔记-医疗", value: "fas fa-notes-medical fa-fw" },
    { label: "用户护士", value: "fas fa-user-nurse fa-fw" },
    { label: "瓶", value: "fas fa-vials fa-fw" },
    { label: "脑", value: "fas fa-brain fa-fw" },
    { label: "生命之星", value: "fas fa-star-of-life fa-fw" },
    { label: "医院", value: "fas fa-hospital fa-fw" },
    { label: "医院", value: "far fa-hospital fa-fw" },
    { label: "病毒", value: "fas fa-virus fa-fw" },
    { label: "关节", value: "fas fa-joint fa-fw" },
    { label: "收据", value: "fas fa-receipt fa-fw" },
    { label: "病毒", value: "fas fa-viruses fa-fw" },
    { label: "片", value: "fas fa-tablets fa-fw" },
    { label: "X射线", value: "fas fa-x-ray fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    { label: "轮椅", value: "fas fa-wheelchair fa-fw" },
    { label: "体重秤", value: "fas fa-weight-scale fa-fw" },
    { label: "病毒斜杠", value: "fas fa-virus-slash fa-fw" },
    {
      label: "病毒-covid-斜杠",
      value: "fas fa-virus-covid-slash fa-fw",
    },
    { label: "病毒-新冠病毒", value: "fas fa-virus-covid fa-fw" },
    { label: "小瓶病毒", value: "fas fa-vial-virus fa-fw" },
    {
      label: "小瓶-圆圈-检查",
      value: "fas fa-vial-circle-check fa-fw",
    },
    { label: "小瓶", value: "fas fa-vial fa-fw" },
    { label: "用户医生", value: "fas fa-user-doctor fa-fw" },
    { label: "卡车-医疗", value: "fas fa-truck-medical fa-fw" },
    { label: "卡车液滴", value: "fas fa-truck-droplet fa-fw" },
    { label: "牙", value: "fas fa-tooth fa-fw" },
    { label: "温度计", value: "fas fa-thermometer fa-fw" },
    { label: "牙齿张开", value: "fas fa-teeth-open fa-fw" },
    { label: "牙齿", value: "fas fa-teeth fa-fw" },
    { label: "注射器", value: "fas fa-syringe fa-fw" },
    { label: "手提箱-医疗", value: "fas fa-suitcase-medical fa-fw" },
    { label: "法杖蛇", value: "fas fa-staff-snake fa-fw" },
    { label: "方形病毒", value: "fas fa-square-virus fa-fw" },
    { label: "方形加", value: "fas fa-square-plus fa-fw" },
    { label: "平方-H", value: "fas fa-square-h fa-fw" },
    { label: "吸烟", value: "fas fa-smoking fa-fw" },
    { label: "颅骨交叉骨", value: "fas fa-skull-crossbones fa-fw" },
    { label: "头盖骨", value: "fas fa-skull fa-fw" },
    { label: "盾病毒", value: "fas fa-shield-virus fa-fw" },
    { label: "辐射", value: "fas fa-radiation fa-fw" },
    { label: "泵-医疗", value: "fas fa-pump-medical fa-fw" },
    {
      label: "处方瓶医疗",
      value: "fas fa-prescription-bottle-medical fa-fw",
    },
    { label: "处方瓶", value: "fas fa-prescription-bottle fa-fw" },
    { label: "处方", value: "fas fa-prescription fa-fw" },
    { label: "船尾", value: "fas fa-poop fa-fw" },
    { label: "药", value: "fas fa-pills fa-fw" },
    { label: "人半连衣裙", value: "fas fa-person-half-dress fa-fw" },
    {
      label: "person-dots-from-line",
      value: "fas fa-person-dots-from-line fa-fw",
    },
    { label: "人-手杖", value: "fas fa-person-cane fa-fw" },
    { label: "母乳喂养", value: "fas fa-person-breastfeeding fa-fw" },
    { label: "呼叫器", value: "fas fa-pager fa-fw" },
    { label: "研钵-研杵", value: "fas fa-mortar-pestle fa-fw" },
    { label: "显微镜", value: "fas fa-microscope fa-fw" },
    { label: "面罩-呼吸机", value: "fas fa-mask-ventilator fa-fw" },
    { label: "面膜-脸部", value: "fas fa-mask-face fa-fw" },
    { label: "肺病毒", value: "fas fa-lungs-virus fa-fw" },
    { label: "肺", value: "fas fa-lungs fa-fw" },
    { label: "笔记本电脑医疗", value: "fas fa-laptop-medical fa-fw" },
    { label: "套件-医疗", value: "fas fa-kit-medical fa-fw" },
    { label: "身份证夹", value: "fas fa-id-card-clip fa-fw" },
    {
      label: "房屋-医疗-旗帜",
      value: "fas fa-house-medical-flag fa-fw",
    },
    {
      label: "删除医疗房子",
      value: "fas fa-house-medical-circle-xmark fa-fw",
    },
    {
      label: "感叹号",
      value: "fas fa-house-medical-circle-exclamation fa-fw",
    },
    {
      label: "选择房子",
      value: "fas fa-house-medical-circle-check fa-fw",
    },
    { label: "家庭医疗", value: "fas fa-house-medical fa-fw" },
    {
      label: "房屋-烟囱-医疗",
      value: "fas fa-house-chimney-medical fa-fw",
    },
    { label: "医院用户", value: "fas fa-hospital-user fa-fw" },
    { label: "心跳", value: "fas fa-heart-pulse fa-fw" },
    {
      label: "心形-圆圈-xmark",
      value: "fas fa-heart-circle-xmark fa-fw",
    },
    { label: "心形圆加", value: "fas fa-heart-circle-plus fa-fw" },
    { label: "心形圆减号", value: "fas fa-heart-circle-minus fa-fw" },
    {
      label: "心-圈-感叹",
      value: "fas fa-heart-circle-exclamation fa-fw",
    },
    { label: "心形检查", value: "fas fa-heart-circle-check fa-fw" },
    { label: "心形圆圈螺栓", value: "fas fa-heart-circle-bolt fa-fw" },
    { label: "头侧病毒", value: "fas fa-head-side-virus fa-fw" },
    { label: "头侧面罩", value: "fas fa-head-side-mask fa-fw" },
    {
      label: "头-侧-咳-斜杠",
      value: "fas fa-head-side-cough-slash fa-fw",
    },
    { label: "头部咳嗽", value: "fas fa-head-side-cough fa-fw" },
    { label: "手持医疗", value: "fas fa-hand-holding-medical fa-fw" },
    { label: "手持液滴", value: "fas fa-hand-holding-droplet fa-fw" },
    { label: "手点", value: "fas fa-hand-dots fa-fw" },
    { label: "烧瓶-小瓶", value: "fas fa-flask-vial fa-fw" },
    { label: "火-火焰-简单", value: "fas fa-fire-flame-simple fa-fw" },
    { label: "文件波形", value: "fas fa-file-waveform fa-fw" },
    { label: "文件处方", value: "fas fa-file-prescription fa-fw" },
    { label: "文件医疗", value: "fas fa-file-medical fa-fw" },
    { label: "滴管", value: "fas fa-eye-dropper fa-fw" },
    { label: "下排斜杠", value: "fas fa-droplet-slash fa-fw" },
    { label: "脱氧核糖核酸", value: "fas fa-dna fa-fw" },
    { label: "疾病", value: "fas fa-disease fa-fw" },
    { label: "拐杖", value: "fas fa-crutch fa-fw" },
    { label: "评论-医疗", value: "fas fa-comment-medical fa-fw" },
    { label: "时钟向左旋转", value: "fas fa-clock-rotate-left fa-fw" },
    { label: "剪贴板用户", value: "fas fa-clipboard-user fa-fw" },
    { label: "圆形辐射", value: "fas fa-circle-radiation fa-fw" },
    { label: "圆圈-H", value: "fas fa-circle-h fa-fw" },
    { label: "胶囊", value: "fas fa-capsules fa-fw" },
    { label: "大麻", value: "fas fa-cannabis fa-fw" },
    { label: "公文包-医疗", value: "fas fa-briefcase-medical fa-fw" },
    { label: "书籍-医疗", value: "fas fa-book-medical fa-fw" },
    { label: "奉", value: "fas fa-bong fa-fw" },
    { label: "骨", value: "fas fa-bone fa-fw" },
    { label: "生物危害", value: "fas fa-biohazard fa-fw" },
    { label: "床脉搏", value: "fas fa-bed-pulse fa-fw" },
    { label: "绷带", value: "fas fa-bandage fa-fw" },
    { label: "禁止吸烟", value: "fas fa-ban-smoking fa-fw" },
    { label: "细菌", value: "fas fa-bacterium fa-fw" },
    { label: "细菌", value: "fas fa-bacteria fa-fw" },
    { label: "方形加", value: "far fa-square-plus fa-fw" },
  ],

  移动: [
    { label: "路线", value: "fas fa-route fa-fw" },
    { label: "酒杯", value: "fas fa-wine-glass fa-fw" },
    { label: "卡车坡道箱", value: "fas fa-truck-ramp-box fa-fw" },
    { label: "卡车搬运", value: "fas fa-truck-moving fa-fw" },
    { label: "拖车", value: "fas fa-trailer fa-fw" },
    { label: "磁带", value: "fas fa-tape fa-fw" },
    { label: "手提箱", value: "fas fa-suitcase fa-fw" },
    { label: "挂牌", value: "fas fa-sign-hanging fa-fw" },
    { label: "人提箱", value: "fas fa-people-carry-box fa-fw" },
    { label: "房屋烟囱", value: "fas fa-house-chimney fa-fw" },
    { label: "洋娃娃", value: "fas fa-dolly fa-fw" },
    { label: "沙发", value: "fas fa-couch fa-fw" },
    { label: "商队", value: "fas fa-caravan fa-fw" },
    { label: "箱装", value: "fas fa-boxes-packing fa-fw" },
    { label: "开箱式", value: "fas fa-box-open fa-fw" },
    { label: "盒存档", value: "fas fa-box-archive fa-fw" },
  ],
  音乐和音频: [
    { label: "音乐", value: "fas fa-music fa-fw" },
    { label: "耳机", value: "fas fa-headphones fa-fw" },
    { label: "滑 块", value: "fas fa-sliders fa-fw" },
    { label: "唱片-黑胶唱片", value: "fas fa-record-vinyl fa-fw" },
    { label: "收音机", value: "fas fa-radio fa-fw" },
    { label: "麦克风斜杠", value: "fas fa-microphone-slash fa-fw" },
    { label: "麦克风", value: "fas fa-microphone fa-fw" },
    { label: "波方", value: "fas fa-wave-square fa-fw" },
    { label: "卷-xmark", value: "fas fa-volume-xmark fa-fw" },
    { label: "音量关闭", value: "fas fa-volume-off fa-fw" },
    { label: "音量低", value: "fas fa-volume-low fa-fw" },
    { label: "高音量", value: "fas fa-volume-high fa-fw" },
    {
      label: "麦克风线斜杠",
      value: "fas fa-microphone-lines-slash fa-fw",
    },
    { label: "麦克风线", value: "fas fa-microphone-lines fa-fw" },
    { label: "耳机-简单", value: "fas fa-headphones-simple fa-fw" },
    { label: "吉他", value: "fas fa-guitar fa-fw" },
    { label: "文件-音频", value: "fas fa-file-audio fa-fw" },
    { label: "钢桶", value: "fas fa-drum-steelpan fa-fw" },
    { label: "鼓", value: "fas fa-drum fa-fw" },
    { label: "光盘", value: "fas fa-compact-disc fa-fw" },
    { label: "文件-音频", value: "far fa-file-audio fa-fw" },
  ],
  自然: [
    { label: "火灾", value: "fas fa-fire fa-fw" },
    { label: "树", value: "fas fa-tree fa-fw" },
    { label: "望远镜", value: "fas fa-binoculars fa-fw" },
    { label: "羽毛", value: "fas fa-feather fa-fw" },
    { label: "错误", value: "fas fa-bug fa-fw" },
    { label: "山太阳", value: "fas fa-mountain-sun fa-fw" },
    { label: "水", value: "fas fa-water fa-fw" },
    { label: "叶", value: "fas fa-leaf fa-fw" },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "风", value: "fas fa-wind fa-fw" },
    { label: "蠕虫", value: "fas fa-worm fa-fw" },
    { label: "火山", value: "fas fa-volcano fa-fw" },
    { label: "蜘蛛", value: "fas fa-spider fa-fw" },
    { label: "路标柱", value: "fas fa-signs-post fa-fw" },
    { label: "植物枯萎病", value: "fas fa-plant-wilt fa-fw" },
    { label: "人员徒步旅行", value: "fas fa-person-hiking fa-fw" },
    { label: "山城", value: "fas fa-mountain-city fa-fw" },
    { label: "山", value: "fas fa-mountain fa-fw" },
    { label: "土堆", value: "fas fa-mound fa-fw" },
    { label: "蚊子", value: "fas fa-mosquito fa-fw" },
    { label: "蝗", value: "fas fa-locust fa-fw" },
    { label: "冰柱", value: "fas fa-icicles fa-fw" },
    { label: "青蛙", value: "fas fa-frog fa-fw" },
    { label: "羽毛尖头", value: "fas fa-feather-pointed fa-fw" },
    { label: "三叶草", value: "fas fa-clover fa-fw" },
    { label: "云太阳", value: "fas fa-cloud-sun fa-fw" },
    { label: "大麻", value: "fas fa-cannabis fa-fw" },
    { label: "错误", value: "fas fa-bugs fa-fw" },
  ],
  数字: [
    { label: "9", value: "fas fa-9 fa-fw" },
    { label: "8", value: "fas fa-8 fa-fw" },
    { label: "7", value: "fas fa-7 fa-fw" },
    { label: "6", value: "fas fa-6 fa-fw" },
    { label: "5", value: "fas fa-5 fa-fw" },
    { label: "4", value: "fas fa-4 fa-fw" },
    { label: "3", value: "fas fa-3 fa-fw" },
    { label: "2", value: "fas fa-2 fa-fw" },
    { label: "1", value: "fas fa-1 fa-fw" },
    { label: "0", value: "fas fa-0 fa-fw" },
  ],
  照片和图片: [
    { label: "图像", value: "fas fa-image fa-fw" },
    { label: "图像", value: "far fa-image fa-fw" },
    { label: "相机复古", value: "fas fa-camera-retro fa-fw" },
    { label: "螺栓", value: "fas fa-bolt fa-fw" },
    { label: "影片", value: "fas fa-film fa-fw" },
    { label: "照相机", value: "fas fa-camera fa-fw" },
    { label: "圆半冲程", value: "fas fa-circle-half-stroke fa-fw" },
    { label: "小滴", value: "fas fa-droplet fa-fw" },
    { label: "眼睛", value: "fas fa-eye fa-fw" },
    { label: "眼睛", value: "far fa-eye fa-fw" },
    { label: "斜线", value: "fas fa-eye-slash fa-fw" },
    { label: "斜线", value: "far fa-eye-slash fa-fw" },
    { label: "滑 块", value: "fas fa-sliders fa-fw" },
    { label: "黑板", value: "fas fa-chalkboard fa-fw" },
    { label: "克隆", value: "fas fa-clone fa-fw" },
    { label: "克隆", value: "far fa-clone fa-fw" },
    { label: "图像", value: "fas fa-images fa-fw" },
    { label: "图像", value: "far fa-images fa-fw" },
    { label: "照片胶片", value: "fas fa-photo-film fa-fw" },
    { label: "全景", value: "fas fa-panorama fa-fw" },
    { label: "最小化", value: "fas fa-minimize fa-fw" },
    { label: "图像-人像", value: "fas fa-image-portrait fa-fw" },
    { label: "身份证", value: "fas fa-id-card fa-fw" },
    { label: "ID徽章", value: "fas fa-id-badge fa-fw" },
    { label: "文件-图像", value: "fas fa-file-image fa-fw" },
    { label: "滴管", value: "fas fa-eye-dropper fa-fw" },
    { label: "相机旋转", value: "fas fa-camera-rotate fa-fw" },
    { label: "螺栓闪电", value: "fas fa-bolt-lightning fa-fw" },
    { label: "身份证", value: "far fa-id-card fa-fw" },
    { label: "ID徽章", value: "far fa-id-badge fa-fw" },
    { label: "文件-图像", value: "far fa-file-image fa-fw" },
  ],
  政治: [
    { label: "垃圾箱火灾", value: "fas fa-dumpster-fire fa-fw" },
    { label: "握手", value: "fas fa-handshake fa-fw" },
    { label: "握手", value: "far fa-handshake fa-fw" },
    { label: "奖", value: "fas fa-award fa-fw" },
    {
      label: "缩放-不平衡-翻转",
      value: "fas fa-scale-unbalanced-flip fa-fw",
    },
    { label: "规模不平衡", value: "fas fa-scale-unbalanced fa-fw" },
    { label: "规模平衡", value: "fas fa-scale-balanced fa-fw" },
    { label: "共和", value: "fas fa-republican fa-fw" },
    { label: "存钱罐", value: "fas fa-piggy-bank fa-fw" },
    { label: "人展台", value: "fas fa-person-booth fa-fw" },
    { label: "地标旗帜", value: "fas fa-landmark-flag fa-fw" },
    { label: "地标穹顶", value: "fas fa-landmark-dome fa-fw" },
    { label: "手拳", value: "fas fa-hand-fist fa-fw" },
    { label: "美国国旗", value: "fas fa-flag-usa fa-fw" },
    { label: "鸽子", value: "fas fa-dove fa-fw" },
    { label: "民主主义者", value: "fas fa-democrat fa-fw" },
    {
      label: "圆元到老虎机",
      value: "fas fa-circle-dollar-to-slot fa-fw",
    },
    {
      label: "Check-to-Slot（检查到插槽）",
      value: "fas fa-check-to-slot fa-fw",
    },
    { label: "双人检查", value: "fas fa-check-double fa-fw" },
    { label: "扩音器", value: "fas fa-bullhorn fa-fw" },
    { label: "建筑标志", value: "fas fa-building-flag fa-fw" },
  ],
  标点符与符号: [
    { label: "检查", value: "fas fa-check fa-fw" },
    { label: "加", value: "fas fa-plus fa-fw" },
    { label: "减去", value: "fas fa-minus fa-fw" },
    { label: "圆圈感叹号", value: "fas fa-circle-exclamation fa-fw" },
    { label: "标签", value: "fas fa-hashtag fa-fw" },
    { label: "左引号", value: "fas fa-quote-left fa-fw" },
    { label: "问题", value: "fas fa-question fa-fw" },
    { label: "引用权", value: "fas fa-quote-right fa-fw" },
    { label: "惊叹号", value: "fas fa-exclamation fa-fw" },
    { label: "百分之", value: "fas fa-percent fa-fw" },
    { label: "部分", value: "fas fa-section fa-fw" },
    { label: "小于", value: "fas fa-less-than fa-fw" },
    { label: "大于", value: "fas fa-greater-than fa-fw" },
    { label: "等于", value: "fas fa-equals fa-fw" },
    { label: "圆圈问题", value: "fas fa-circle-question fa-fw" },
    { label: "双人检查", value: "fas fa-check-double fa-fw" },
    { label: "在", value: "fas fa-at fa-fw" },
    { label: "星号", value: "fas fa-asterisk fa-fw" },
    { label: "圆圈问题", value: "far fa-circle-question fa-fw" },
  ],
  宗教: [
    { label: "渡", value: "fas fa-cross fa-fw" },
    { label: "礼拜场所", value: "fas fa-place-of-worship fa-fw" },
    { label: "教堂", value: "fas fa-church fa-fw" },
    { label: "戈普拉姆", value: "fas fa-gopuram fa-fw" },
    { label: "阴阳", value: "fas fa-yin-yang fa-fw" },
    { label: "维哈拉", value: "fas fa-vihara fa-fw" },
    { label: "鸟居门", value: "fas fa-torii-gate fa-fw" },
    { label: "犹太教堂", value: "fas fa-synagogue fa-fw" },
    { label: "大卫之星", value: "fas fa-star-of-david fa-fw" },
    { label: "星月形", value: "fas fa-star-and-crescent fa-fw" },
    {
      label: "意大利面-怪物-飞行",
      value: "fas fa-spaghetti-monster-flying fa-fw",
    },
    { label: "卷轴托拉", value: "fas fa-scroll-torah fa-fw" },
    { label: "人祈祷", value: "fas fa-person-praying fa-fw" },
    { label: "和平", value: "fas fa-peace fa-fw" },
    { label: "嗡嗡", value: "fas fa-om fa-fw" },
    { label: "清真寺", value: "fas fa-mosque fa-fw" },
    { label: "灯台", value: "fas fa-menorah fa-fw" },
    { label: "坎达", value: "fas fa-khanda fa-fw" },
    { label: "天房", value: "fas fa-kaaba fa-fw" },
    { label: "绝地武士", value: "fas fa-jedi fa-fw" },
    { label: "光明节", value: "fas fa-hanukiah fa-fw" },
    { label: "双手祷告", value: "fas fa-hands-praying fa-fw" },
    { label: "哈姆萨", value: "fas fa-hamsa fa-fw" },
    { label: "鸽子", value: "fas fa-dove fa-fw" },
    { label: "法轮", value: "fas fa-dharmachakra fa-fw" },
    { label: "book-tanakh", value: "fas fa-book-tanakh fa-fw" },
    { label: "古兰经", value: "fas fa-book-quran fa-fw" },
    {
      label: "book-journal-whills",
      value: "fas fa-book-journal-whills fa-fw",
    },
    { label: "书籍圣经", value: "fas fa-book-bible fa-fw" },
    { label: "巴哈伊", value: "fas fa-bahai fa-fw" },
    { label: "原子", value: "fas fa-atom fa-fw" },
    { label: "十字架", value: "fas fa-ankh fa-fw" },
  ],
  科学: [
    { label: "滤波器", value: "fas fa-filter fa-fw" },
    { label: "火灾", value: "fas fa-fire fa-fw" },
    { label: "磁铁", value: "fas fa-magnet fa-fw" },
    { label: "瓶", value: "fas fa-flask fa-fw" },
    { label: "瓶", value: "fas fa-vials fa-fw" },
    { label: "脑", value: "fas fa-brain fa-fw" },
    { label: "幼苗", value: "fas fa-seedling fa-fw" },
    { label: "片", value: "fas fa-tablets fa-fw" },
    { label: "小瓶病毒", value: "fas fa-vial-virus fa-fw" },
    {
      label: "小瓶-圆圈-检查",
      value: "fas fa-vial-circle-check fa-fw",
    },
    { label: "小瓶", value: "fas fa-vial fa-fw" },
    { label: "温度低", value: "fas fa-temperature-low fa-fw" },
    { label: "温度高", value: "fas fa-temperature-high fa-fw" },
    { label: "注射器", value: "fas fa-syringe fa-fw" },
    { label: "方形病毒", value: "fas fa-square-virus fa-fw" },
    { label: "颅骨交叉骨", value: "fas fa-skull-crossbones fa-fw" },
    { label: "辐射", value: "fas fa-radiation fa-fw" },
    { label: "处方瓶", value: "fas fa-prescription-bottle fa-fw" },
    { label: "药", value: "fas fa-pills fa-fw" },
    { label: "研钵-研杵", value: "fas fa-mortar-pestle fa-fw" },
    { label: "显微镜", value: "fas fa-microscope fa-fw" },
    { label: "青蛙", value: "fas fa-frog fa-fw" },
    { label: "烧瓶-小瓶", value: "fas fa-flask-vial fa-fw" },
    { label: "火-火焰-简单", value: "fas fa-fire-flame-simple fa-fw" },
    { label: "火-火焰-弯曲", value: "fas fa-fire-flame-curved fa-fw" },
    { label: "滴管", value: "fas fa-eye-dropper fa-fw" },
    { label: "脱氧核糖核酸", value: "fas fa-dna fa-fw" },
    { label: "疾病", value: "fas fa-disease fa-fw" },
    { label: "剪贴板检查", value: "fas fa-clipboard-check fa-fw" },
    { label: "圆形辐射", value: "fas fa-circle-radiation fa-fw" },
    { label: "胶囊", value: "fas fa-capsules fa-fw" },
    { label: "生物危害", value: "fas fa-biohazard fa-fw" },
    { label: "原子", value: "fas fa-atom fa-fw" },
  ],
  科幻小说: [
    { label: "火箭", value: "fas fa-rocket fa-fw" },
    { label: "机器人", value: "fas fa-robot fa-fw" },
    { label: "用户宇航员", value: "fas fa-user-astronaut fa-fw" },
    { label: "绝地武士", value: "fas fa-jedi fa-fw" },
    { label: "手拍", value: "fas fa-hand-spock fa-fw" },
    { label: "爆炸", value: "fas fa-explosion fa-fw" },
    {
      label: "book-journal-whills",
      value: "fas fa-book-journal-whills fa-fw",
    },
    { label: "原子", value: "fas fa-atom fa-fw" },
    { label: "手拍", value: "far fa-hand-spock fa-fw" },
  ],

  旋转图标: [
    { label: "齿轮", value: "fas fa-gear fa-fw" },
    { label: "向右旋转", value: "fas fa-rotate-right fa-fw" },
    { label: "指南针", value: "fas fa-compass fa-fw" },
    { label: "指南针", value: "far fa-compass fa-fw" },
    { label: "雪花", value: "fas fa-snowflake fa-fw" },
    { label: "雪花", value: "far fa-snowflake fa-fw" },
    { label: "调色板", value: "fas fa-palette fa-fw" },
    { label: "太阳", value: "fas fa-sun fa-fw" },
    { label: "太阳", value: "far fa-sun fa-fw" },
    { label: "旋转", value: "fas fa-spinner fa-fw" },
    { label: "斜线", value: "fas fa-slash fa-fw" },
    { label: "证书", value: "fas fa-certificate fa-fw" },
    { label: "十字准线", value: "fas fa-crosshairs fa-fw" },
    { label: "戒指", value: "fas fa-ring fa-fw" },
    { label: "圆形缺口", value: "fas fa-circle-notch fa-fw" },
    { label: "阴阳", value: "fas fa-yin-yang fa-fw" },
    { label: "斯特罗普瓦菲尔", value: "fas fa-stroopwafel fa-fw" },
    { label: "向左旋转", value: "fas fa-rotate-left fa-fw" },
    { label: "旋转", value: "fas fa-rotate fa-fw" },
    { label: "救生圈", value: "fas fa-life-ring fa-fw" },
    { label: "飓风", value: "fas fa-hurricane fa-fw" },
    { label: "扇", value: "fas fa-fan fa-fw" },
    { label: "法轮", value: "fas fa-dharmachakra fa-fw" },
    { label: "光盘", value: "fas fa-compact-disc fa-fw" },
    { label: "巴哈伊", value: "fas fa-bahai fa-fw" },
    { label: "原子", value: "fas fa-atom fa-fw" },
    { label: "星号", value: "fas fa-asterisk fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-spin fa-fw" },
    { label: "箭头旋转", value: "fas fa-arrows-rotate fa-fw" },
    {
      label: "向右箭头旋转",
      value: "fas fa-arrow-rotate-right fa-fw",
    },
    {
      label: "arrow-rotate-left",
      value: "fas fa-arrow-rotate-left fa-fw",
    },
    { label: "救生圈", value: "far fa-life-ring fa-fw" },
  ],
  运动和健身: [
    { label: "心", value: "fas fa-heart fa-fw" },
    { label: "心", value: "far fa-heart fa-fw" },
    { label: "自行车", value: "fas fa-bicycle fa-fw" },
    { label: "奖章", value: "fas fa-medal fa-fw" },
    { label: "哑铃", value: "fas fa-dumbbell fa-fw" },
    { label: "砝码悬挂式", value: "fas fa-weight-hanging fa-fw" },
    { label: "排球", value: "fas fa-volleyball fa-fw" },
    {
      label: "乒乓球-板球",
      value: "fas fa-table-tennis-paddle-ball fa-fw",
    },
    { label: "秒表-20", value: "fas fa-stopwatch-20 fa-fw" },
    { label: "矿泉", value: "fas fa-spa fa-fw" },
    { label: "鞋印", value: "fas fa-shoe-prints fa-fw" },
    { label: "排名之星", value: "fas fa-ranking-star fa-fw" },
    { label: "人行", value: "fas fa-person-walking fa-fw" },
    { label: "人游泳", value: "fas fa-person-swimming fa-fw" },
    { label: "单板滑雪", value: "fas fa-person-snowboarding fa-fw" },
    { label: "人滑-北欧", value: "fas fa-person-skiing-nordic fa-fw" },
    { label: "人滑", value: "fas fa-person-skiing fa-fw" },
    { label: "人滑", value: "fas fa-person-skating fa-fw" },
    { label: "人跑", value: "fas fa-person-running fa-fw" },
    { label: "人员徒步旅行", value: "fas fa-person-hiking fa-fw" },
    { label: "人骑车", value: "fas fa-person-biking fa-fw" },
    { label: "土堆", value: "fas fa-mound fa-fw" },
    { label: "曲棍球-冰球", value: "fas fa-hockey-puck fa-fw" },
    { label: "心跳", value: "fas fa-heart-pulse fa-fw" },
    { label: "高尔夫球发球台", value: "fas fa-golf-ball-tee fa-fw" },
    { label: "足球", value: "fas fa-futbol fa-fw" },
    { label: "足球", value: "fas fa-football fa-fw" },
    { label: "火-火焰-简单", value: "fas fa-fire-flame-simple fa-fw" },
    { label: "火-火焰-弯曲", value: "fas fa-fire-flame-curved fa-fw" },
    { label: "扫帚球", value: "fas fa-broom-ball fa-fw" },
    { label: "保龄球", value: "fas fa-bowling-ball fa-fw" },
    { label: "篮球", value: "fas fa-basketball fa-fw" },
    { label: "棒球-球棒-球", value: "fas fa-baseball-bat-ball fa-fw" },
    { label: "棒球", value: "fas fa-baseball fa-fw" },
    { label: "足球", value: "far fa-futbol fa-fw" },
  ],
  文本格式: [
    { label: "检查", value: "fas fa-check fa-fw" },
    { label: "列表", value: "fas fa-list fa-fw" },
    { label: "字体", value: "fas fa-font fa-fw" },
    { label: "删除线", value: "fas fa-strikethrough fa-fw" },
    { label: "桌子", value: "fas fa-table fa-fw" },
    { label: "图标", value: "fas fa-icons fa-fw" },
    { label: "方形格子", value: "fas fa-square-check fa-fw" },
    { label: "方形格子", value: "far fa-square-check fa-fw" },
    { label: "圆圈检查", value: "fas fa-circle-check fa-fw" },
    { label: "圆圈检查", value: "far fa-circle-check fa-fw" },
    { label: "标题", value: "fas fa-heading fa-fw" },
    { label: "i-光标", value: "fas fa-i-cursor fa-fw" },
    { label: "下标", value: "fas fa-subscript fa-fw" },
    { label: "边框-无", value: "fas fa-border-none fa-fw" },
    { label: "下划线", value: "fas fa-underline fa-fw" },
    { label: "文本宽度", value: "fas fa-text-width fa-fw" },
    { label: "文本斜杠", value: "fas fa-text-slash fa-fw" },
    { label: "文本高度", value: "fas fa-text-height fa-fw" },
    { label: "表列表", value: "fas fa-table-list fa-fw" },
    { label: "表列", value: "fas fa-table-columns fa-fw" },
    {
      label: "table-cells-large",
      value: "fas fa-table-cells-large fa-fw",
    },
    { label: "表单元格", value: "fas fa-table-cells fa-fw" },
    { label: "上标", value: "fas fa-superscript fa-fw" },
    { label: "拼写检查", value: "fas fa-spell-check fa-fw" },
    { label: "矩形列表", value: "fas fa-rectangle-list fa-fw" },
    { label: "段", value: "fas fa-paragraph fa-fw" },
    { label: "缩进", value: "fas fa-outdent fa-fw" },
    { label: "列表-ul", value: "fas fa-list-ul fa-fw" },
    { label: "列表-OL", value: "fas fa-list-ol fa-fw" },
    { label: "列表检查", value: "fas fa-list-check fa-fw" },
    { label: "斜体的", value: "fas fa-italic fa-fw" },
    { label: "订货单", value: "fas fa-indent fa-fw" },
    { label: "荧光笔", value: "fas fa-highlighter fa-fw" },
    {
      label: "filter-circle-xmark（过滤器-圆圈-xmark）",
      value: "fas fa-filter-circle-xmark fa-fw",
    },
    { label: "双人检查", value: "fas fa-check-double fa-fw" },
    { label: "左上角边框", value: "fas fa-border-top-left fa-fw" },
    { label: "全部边框", value: "fas fa-border-all fa-fw" },
    { label: "大胆", value: "fas fa-bold fa-fw" },
    { label: "右对齐", value: "fas fa-align-right fa-fw" },
    { label: "左对齐", value: "fas fa-align-left fa-fw" },
    { label: "对齐-对齐", value: "fas fa-align-justify fa-fw" },
    { label: "居中对齐", value: "fas fa-align-center fa-fw" },
    { label: "矩形列表", value: "far fa-rectangle-list fa-fw" },
  ],
  时间: [
    { label: "日历日", value: "fas fa-calendar-days fa-fw" },
    { label: "日历日", value: "far fa-calendar-days fa-fw" },
    { label: "钟", value: "fas fa-bell fa-fw" },
    { label: "钟", value: "far fa-bell fa-fw" },
    { label: "日历", value: "fas fa-calendar fa-fw" },
    { label: "日历", value: "far fa-calendar fa-fw" },
    { label: "时钟", value: "fas fa-clock fa-fw" },
    { label: "时钟", value: "far fa-clock fa-fw" },
    { label: "沙漏启动", value: "fas fa-hourglass-start fa-fw" },
    { label: "日历周", value: "fas fa-calendar-week fa-fw" },
    { label: "秒表-20", value: "fas fa-stopwatch-20 fa-fw" },
    { label: "秒表", value: "fas fa-stopwatch fa-fw" },
    { label: "沙漏半", value: "fas fa-hourglass-half fa-fw" },
    { label: "沙漏端", value: "fas fa-hourglass-end fa-fw" },
    { label: "沙漏", value: "fas fa-hourglass fa-fw" },
    { label: "日历-xmark", value: "fas fa-calendar-xmark fa-fw" },
    { label: "日历加", value: "fas fa-calendar-plus fa-fw" },
    { label: "日历减去", value: "fas fa-calendar-minus fa-fw" },
    { label: "日历日", value: "fas fa-calendar-day fa-fw" },
    { label: "日历检查", value: "fas fa-calendar-check fa-fw" },
    { label: "铃铛斜杠", value: "fas fa-bell-slash fa-fw" },
    { label: "沙漏半", value: "far fa-hourglass-half fa-fw" },
    { label: "沙漏", value: "far fa-hourglass fa-fw" },
    { label: "日历-xmark", value: "far fa-calendar-xmark fa-fw" },
    { label: "日历加", value: "far fa-calendar-plus fa-fw" },
    { label: "日历减去", value: "far fa-calendar-minus fa-fw" },
    { label: "日历检查", value: "far fa-calendar-check fa-fw" },
    { label: "铃铛斜杠", value: "far fa-bell-slash fa-fw" },
  ],
  切换: [
    { label: "星", value: "fas fa-star fa-fw" },
    { label: "星", value: "far fa-star fa-fw" },
    { label: "无线网络", value: "fas fa-wifi fa-fw" },
    { label: "圈", value: "fas fa-circle fa-fw" },
    { label: "圈", value: "far fa-circle fa-fw" },
    { label: "切换", value: "fas fa-toggle-on fa-fw" },
    { label: "信号", value: "fas fa-signal fa-fw" },
    { label: "靶心", value: "fas fa-bullseye fa-fw" },
    { label: "圆圈检查", value: "fas fa-circle-check fa-fw" },
    { label: "圆圈检查", value: "far fa-circle-check fa-fw" },
    { label: "麦克风斜杠", value: "fas fa-microphone-slash fa-fw" },
    { label: "麦克风", value: "fas fa-microphone fa-fw" },
    { label: "切换关闭", value: "fas fa-toggle-off fa-fw" },
    { label: "星形半冲程", value: "fas fa-star-half-stroke fa-fw" },
    { label: "星半", value: "fas fa-star-half fa-fw" },
    { label: "平面上升", value: "fas fa-plane-up fa-fw" },
    {
      label: "位置十字准线",
      value: "fas fa-location-crosshairs fa-fw",
    },
    { label: "圆点", value: "fas fa-circle-dot fa-fw" },
    { label: "星形半冲程", value: "far fa-star-half-stroke fa-fw" },
    { label: "星半", value: "far fa-star-half fa-fw" },
    { label: "圆点", value: "far fa-circle-dot fa-fw" },
  ],
  交通运输: [
    { label: "购物车购物", value: "fas fa-cart-shopping fa-fw" },
    { label: "汽车", value: "fas fa-car fa-fw" },
    { label: "飞机", value: "fas fa-plane fa-fw" },
    { label: "纸飞机", value: "fas fa-paper-plane fa-fw" },
    { label: "纸飞机", value: "far fa-paper-plane fa-fw" },
    { label: "卡车", value: "fas fa-truck fa-fw" },
    { label: "自行车", value: "fas fa-bicycle fa-fw" },
    { label: "车侧", value: "fas fa-car-side fa-fw" },
    { label: "卡车前部", value: "fas fa-truck-front fa-fw" },
    { label: "缆车", value: "fas fa-cable-car fa-fw" },
    { label: "火箭", value: "fas fa-rocket fa-fw" },
    { label: "船", value: "fas fa-ship fa-fw" },
    { label: "摩托车", value: "fas fa-motorcycle fa-fw" },
    { label: "火车", value: "fas fa-train fa-fw" },
    { label: "路", value: "fas fa-road fa-fw" },
    { label: "卡车怪兽", value: "fas fa-truck-monster fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    { label: "轮椅", value: "fas fa-wheelchair fa-fw" },
    { label: "厢式穿梭巴士", value: "fas fa-van-shuttle fa-fw" },
    { label: "卡车-飞机", value: "fas fa-truck-plane fa-fw" },
    { label: "卡车皮卡", value: "fas fa-truck-pickup fa-fw" },
    { label: "卡车-医疗", value: "fas fa-truck-medical fa-fw" },
    { label: "卡车场-联合国", value: "fas fa-truck-field-un fa-fw" },
    { label: "卡车场", value: "fas fa-truck-field fa-fw" },
    { label: "卡车液滴", value: "fas fa-truck-droplet fa-fw" },
    { label: "卡车箭头右", value: "fas fa-truck-arrow-right fa-fw" },
    { label: "电车-有轨电车", value: "fas fa-train-tram fa-fw" },
    { label: "火车-地铁", value: "fas fa-train-subway fa-fw" },
    { label: "拖拉机", value: "fas fa-tractor fa-fw" },
    { label: "出租车", value: "fas fa-taxi fa-fw" },
    { label: "扫雪机", value: "fas fa-snowplow fa-fw" },
    { label: "雪橇", value: "fas fa-sleigh fa-fw" },
    { label: "穿梭空间", value: "fas fa-shuttle-space fa-fw" },
    { label: "帆船", value: "fas fa-sailboat fa-fw" },
    { label: "路钉", value: "fas fa-road-spikes fa-fw" },
    { label: "道路护栏", value: "fas fa-road-barrier fa-fw" },
    { label: "平面上升", value: "fas fa-plane-up fa-fw" },
    { label: "平面斜线", value: "fas fa-plane-slash fa-fw" },
    { label: "土堆", value: "fas fa-mound fa-fw" },
    { label: "喷气式战斗机", value: "fas fa-jet-fighter-up fa-fw" },
    { label: "喷气式战斗机", value: "fas fa-jet-fighter fa-fw" },
    { label: "马", value: "fas fa-horse fa-fw" },
    { label: "直升机", value: "fas fa-helicopter fa-fw" },
    { label: "渡轮", value: "fas fa-ferry fa-fw" },
    { label: "汽车隧道", value: "fas fa-car-tunnel fa-fw" },
    { label: "汽车后部", value: "fas fa-car-rear fa-fw" },
    { label: "汽车爆裂", value: "fas fa-car-burst fa-fw" },
    { label: "巴士", value: "fas fa-bus-simple fa-fw" },
    { label: "巴士", value: "fas fa-bus fa-fw" },
    { label: "婴儿车", value: "fas fa-baby-carriage fa-fw" },
  ],
  旅行酒店: [
    { label: "汽车", value: "fas fa-car fa-fw" },
    { label: "飞机", value: "fas fa-plane fa-fw" },
    { label: "钥匙", value: "fas fa-key fa-fw" },
    { label: "无线网络", value: "fas fa-wifi fa-fw" },
    { label: "公文包", value: "fas fa-briefcase fa-fw" },
    { label: "浴", value: "fas fa-bath fa-fw" },
    { label: "雪花", value: "fas fa-snowflake fa-fw" },
    { label: "雪花", value: "far fa-snowflake fa-fw" },
    { label: "地球-美洲", value: "fas fa-earth-americas fa-fw" },
    { label: "马克杯碟", value: "fas fa-mug-saucer fa-fw" },
    { label: "缆车", value: "fas fa-cable-car fa-fw" },
    { label: "护照", value: "fas fa-passport fa-fw" },
    { label: "开门式", value: "fas fa-door-open fa-fw" },
    { label: "旅馆", value: "fas fa-hotel fa-fw" },
    { label: "淋浴", value: "fas fa-shower fa-fw" },
    { label: "骰子", value: "fas fa-dice fa-fw" },
    { label: "伞形海滩", value: "fas fa-umbrella-beach fa-fw" },
    { label: "哑铃", value: "fas fa-dumbbell fa-fw" },
    { label: "飞机起飞", value: "fas fa-plane-departure fa-fw" },
    { label: "酒杯-空", value: "fas fa-wine-glass-empty fa-fw" },
    { label: "酒杯", value: "fas fa-wine-glass fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    { label: "轮椅", value: "fas fa-wheelchair fa-fw" },
    { label: "水梯", value: "fas fa-water-ladder fa-fw" },
    { label: "厢式穿梭巴士", value: "fas fa-van-shuttle fa-fw" },
    { label: "用具", value: "fas fa-utensils fa-fw" },
    { label: "电视", value: "fas fa-tv fa-fw" },
    { label: "树城", value: "fas fa-tree-city fa-fw" },
    { label: "电车-有轨电车", value: "fas fa-train-tram fa-fw" },
    { label: "卫生纸", value: "fas fa-toilet-paper fa-fw" },
    { label: "厕所", value: "fas fa-toilet fa-fw" },
    { label: "出租车", value: "fas fa-taxi fa-fw" },
    { label: "手提箱滚动", value: "fas fa-suitcase-rolling fa-fw" },
    { label: "手提箱", value: "fas fa-suitcase fa-fw" },
    { label: "楼梯", value: "fas fa-stairs fa-fw" },
    { label: "矿泉", value: "fas fa-spa fa-fw" },
    { label: "吸烟", value: "fas fa-smoking fa-fw" },
    { label: "平面上升", value: "fas fa-plane-up fa-fw" },
    { label: "平面斜线", value: "fas fa-plane-slash fa-fw" },
    { label: "平面锁", value: "fas fa-plane-lock fa-fw" },
    {
      label: "平面-圆-xmark",
      value: "fas fa-plane-circle-xmark fa-fw",
    },
    {
      label: "平面-圆-感叹号",
      value: "fas fa-plane-circle-exclamation fa-fw",
    },
    { label: "平面圆检查", value: "fas fa-plane-circle-check fa-fw" },
    { label: "飞机到达", value: "fas fa-plane-arrival fa-fw" },
    {
      label: "步行行李",
      value: "fas fa-person-walking-luggage fa-fw",
    },
    { label: "人游泳", value: "fas fa-person-swimming fa-fw" },
    { label: "山城", value: "fas fa-mountain-city fa-fw" },
    { label: "纪念碑", value: "fas fa-monument fa-fw" },
    {
      label: "马提尼酒杯空",
      value: "fas fa-martini-glass-empty fa-fw",
    },
    {
      label: "马提尼-玻璃-柑橘",
      value: "fas fa-martini-glass-citrus fa-fw",
    },
    { label: "马提尼酒杯", value: "fas fa-martini-glass fa-fw" },
    { label: "地图位置点", value: "fas fa-map-location-dot fa-fw" },
    { label: "地图位置", value: "fas fa-map-location fa-fw" },
    { label: "地图", value: "fas fa-map fa-fw" },
    { label: "厨房套装", value: "fas fa-kitchen-set fa-fw" },
    { label: "无限", value: "fas fa-infinity fa-fw" },
    { label: "热水浴缸人", value: "fas fa-hot-tub-person fa-fw" },
    { label: "电梯", value: "fas fa-elevator fa-fw" },
    { label: "地球-大洋洲", value: "fas fa-earth-oceania fa-fw" },
    { label: "地球-欧洲", value: "fas fa-earth-europe fa-fw" },
    { label: "地球-亚洲", value: "fas fa-earth-asia fa-fw" },
    { label: "地球-非洲", value: "fas fa-earth-africa fa-fw" },
    { label: "门关闭", value: "fas fa-door-closed fa-fw" },
    { label: "骰子-5", value: "fas fa-dice-five fa-fw" },
    {
      label: "推车-平板-行李箱",
      value: "fas fa-cart-flatbed-suitcase fa-fw",
    },
    { label: "商队", value: "fas fa-caravan fa-fw" },
    { label: "巴士", value: "fas fa-bus-simple fa-fw" },
    { label: "巴士", value: "fas fa-bus fa-fw" },
    { label: "书本-阿特拉斯", value: "fas fa-book-atlas fa-fw" },
    { label: "门铃礼宾部", value: "fas fa-bell-concierge fa-fw" },
    { label: "床", value: "fas fa-bed fa-fw" },
    { label: "禁止吸烟", value: "fas fa-ban-smoking fa-fw" },
    { label: "婴儿车", value: "fas fa-baby-carriage fa-fw" },
    { label: "拱道", value: "fas fa-archway fa-fw" },
    { label: "地图", value: "far fa-map fa-fw" },
  ],
  用户和人群: [
    { label: "用户", value: "fas fa-user fa-fw" },
    { label: "用户", value: "far fa-user fa-fw" },
    { label: "便便", value: "fas fa-poo fa-fw" },
    { label: "面带微笑", value: "fas fa-face-smile fa-fw" },
    { label: "面带微笑", value: "far fa-face-smile fa-fw" },
    { label: "圈子用户", value: "fas fa-circle-user fa-fw" },
    { label: "圈子用户", value: "far fa-circle-user fa-fw" },
    { label: "人", value: "fas fa-person fa-fw" },
    { label: "人装", value: "fas fa-person-dress fa-fw" },
    { label: "地址簿", value: "fas fa-address-book fa-fw" },
    { label: "地址簿", value: "far fa-address-book fa-fw" },
    { label: "用户", value: "fas fa-users fa-fw" },
    { label: "用户密钥", value: "fas fa-user-secret fa-fw" },
    { label: "用户减号", value: "fas fa-user-minus fa-fw" },
    { label: "街景", value: "fas fa-street-view fa-fw" },
    { label: "地址卡", value: "fas fa-address-card fa-fw" },
    { label: "地址卡", value: "far fa-address-card fa-fw" },
    { label: "用户护士", value: "fas fa-user-nurse fa-fw" },
    { label: "厕所", value: "fas fa-restroom fa-fw" },
    { label: "用户加", value: "fas fa-user-plus fa-fw" },
    { label: "孩子", value: "fas fa-child fa-fw" },
    { label: "用户关系", value: "fas fa-user-tie fa-fw" },
    { label: "婴儿", value: "fas fa-baby fa-fw" },
    { label: "人物箭头", value: "fas fa-people-arrows fa-fw" },
    { label: "轮椅移动", value: "fas fa-wheelchair-move fa-fw" },
    { label: "轮椅", value: "fas fa-wheelchair fa-fw" },
    { label: "用户取景器", value: "fas fa-users-viewfinder fa-fw" },
    { label: "用户斜杠", value: "fas fa-users-slash fa-fw" },
    { label: "users-矩形", value: "fas fa-users-rectangle fa-fw" },
    { label: "用户射线", value: "fas fa-users-rays fa-fw" },
    { label: "用户行", value: "fas fa-users-line fa-fw" },
    { label: "用户齿轮", value: "fas fa-users-gear fa-fw" },
    { label: "用户行间", value: "fas fa-users-between-lines fa-fw" },
    { label: "用户 xmark", value: "fas fa-user-xmark fa-fw" },
    { label: "用户标签", value: "fas fa-user-tag fa-fw" },
    { label: "用户斜杠", value: "fas fa-user-slash fa-fw" },
    { label: "用户盾", value: "fas fa-user-shield fa-fw" },
    { label: "用户笔", value: "fas fa-user-pen fa-fw" },
    { label: "用户忍者", value: "fas fa-user-ninja fa-fw" },
    { label: "用户锁", value: "fas fa-user-lock fa-fw" },
    { label: "用户大斜杠", value: "fas fa-user-large-slash fa-fw" },
    { label: "用户-大型", value: "fas fa-user-large fa-fw" },
    { label: "用户受伤", value: "fas fa-user-injured fa-fw" },
    { label: "用户组", value: "fas fa-user-group fa-fw" },
    { label: "用户毕业生", value: "fas fa-user-graduate fa-fw" },
    { label: "用户齿轮", value: "fas fa-user-gear fa-fw" },
    { label: "用户医生", value: "fas fa-user-doctor fa-fw" },
    { label: "用户时钟", value: "fas fa-user-clock fa-fw" },
    { label: "用户检查", value: "fas fa-user-check fa-fw" },
    { label: "用户宇航员", value: "fas fa-user-astronaut fa-fw" },
    {
      label: "方形人被限制",
      value: "fas fa-square-person-confined fa-fw",
    },
    { label: "头盖骨", value: "fas fa-skull fa-fw" },
    {
      label: "拄着拐杖走路的人",
      value: "fas fa-person-walking-with-cane fa-fw",
    },
    {
      label: "步行行李",
      value: "fas fa-person-walking-luggage fa-fw",
    },
    {
      label: "person-walking-虚线-arrow-right",
      value: "fas fa-person-walking-dashed-line-arrow-right fa-fw",
    },
    {
      label: "人走箭头右",
      value: "fas fa-person-walking-arrow-right fa-fw",
    },
    {
      label: "人走路箭头环左",
      value: "fas fa-person-walking-arrow-loop-left fa-fw",
    },
    { label: "人行", value: "fas fa-person-walking fa-fw" },
    { label: "人窗式", value: "fas fa-person-through-window fa-fw" },
    { label: "人游泳", value: "fas fa-person-swimming fa-fw" },
    { label: "单板滑雪", value: "fas fa-person-snowboarding fa-fw" },
    { label: "人滑-北欧", value: "fas fa-person-skiing-nordic fa-fw" },
    { label: "人滑", value: "fas fa-person-skiing fa-fw" },
    { label: "人滑", value: "fas fa-person-skating fa-fw" },
    { label: "个人庇护所", value: "fas fa-person-shelter fa-fw" },
    { label: "人跑", value: "fas fa-person-running fa-fw" },
    { label: "人-步枪", value: "fas fa-person-rifle fa-fw" },
    { label: "人体射线", value: "fas fa-person-rays fa-fw" },
    { label: "怀孕的人", value: "fas fa-person-pregnant fa-fw" },
    { label: "人祈祷", value: "fas fa-person-praying fa-fw" },
    {
      label: "人-军人-人",
      value: "fas fa-person-military-to-person fa-fw",
    },
    {
      label: "人-军-步枪",
      value: "fas fa-person-military-rifle fa-fw",
    },
    {
      label: "人-军事-指向",
      value: "fas fa-person-military-pointing fa-fw",
    },
    { label: "人员徒步旅行", value: "fas fa-person-hiking fa-fw" },
    { label: "人身骚扰", value: "fas fa-person-harassing fa-fw" },
    { label: "人半连衣裙", value: "fas fa-person-half-dress fa-fw" },
    {
      label: "人坠落爆发",
      value: "fas fa-person-falling-burst fa-fw",
    },
    { label: "人跌倒", value: "fas fa-person-falling fa-fw" },
    { label: "溺水者", value: "fas fa-person-drowning fa-fw" },
    {
      label: "人-着装-爆裂",
      value: "fas fa-person-dress-burst fa-fw",
    },
    {
      label: "person-dots-from-line",
      value: "fas fa-person-dots-from-line fa-fw",
    },
    { label: "人挖", value: "fas fa-person-digging fa-fw" },
    {
      label: "人-圈-xmark",
      value: "fas fa-person-circle-xmark fa-fw",
    },
    {
      label: "人-圈-问题",
      value: "fas fa-person-circle-question fa-fw",
    },
    { label: "人圈加", value: "fas fa-person-circle-plus fa-fw" },
    { label: "人圈减号", value: "fas fa-person-circle-minus fa-fw" },
    {
      label: "人-圆-感叹号",
      value: "fas fa-person-circle-exclamation fa-fw",
    },
    {
      label: "人员-圈子-检查",
      value: "fas fa-person-circle-check fa-fw",
    },
    { label: "人-黑板", value: "fas fa-person-chalkboard fa-fw" },
    { label: "人-手杖", value: "fas fa-person-cane fa-fw" },
    { label: "人突发", value: "fas fa-person-burst fa-fw" },
    { label: "母乳喂养", value: "fas fa-person-breastfeeding fa-fw" },
    { label: "人展台", value: "fas fa-person-booth fa-fw" },
    { label: "人骑车", value: "fas fa-person-biking fa-fw" },
    {
      label: "person-arrow-up-from-line",
      value: "fas fa-person-arrow-up-from-line fa-fw",
    },
    {
      label: "person-arrow-down-to-line",
      value: "fas fa-person-arrow-down-to-line fa-fw",
    },
    { label: "人顶", value: "fas fa-people-roof fa-fw" },
    { label: "抢劫人口", value: "fas fa-people-robbery fa-fw" },
    { label: "拉人", value: "fas fa-people-pulling fa-fw" },
    { label: "人行", value: "fas fa-people-line fa-fw" },
    { label: "人员组", value: "fas fa-people-group fa-fw" },
    { label: "人提箱", value: "fas fa-people-carry-box fa-fw" },
    {
      label: "火星和金星爆发",
      value: "fas fa-mars-and-venus-burst fa-fw",
    },
    { label: "图像-人像", value: "fas fa-image-portrait fa-fw" },
    { label: "身份证夹", value: "fas fa-id-card-clip fa-fw" },
    { label: "身份证", value: "fas fa-id-card fa-fw" },
    { label: "ID徽章", value: "fas fa-id-badge fa-fw" },
    { label: "房屋用户", value: "fas fa-house-user fa-fw" },
    {
      label: "房屋烟囱用户",
      value: "fas fa-house-chimney-user fa-fw",
    },
    { label: "热水浴缸人", value: "fas fa-hot-tub-person fa-fw" },
    { label: "医院用户", value: "fas fa-hospital-user fa-fw" },
    { label: "头侧病毒", value: "fas fa-head-side-virus fa-fw" },
    { label: "头侧面罩", value: "fas fa-head-side-mask fa-fw" },
    {
      label: "头-侧-咳-斜杠",
      value: "fas fa-head-side-cough-slash fa-fw",
    },
    { label: "头部咳嗽", value: "fas fa-head-side-cough fa-fw" },
    { label: "脸-meh", value: "fas fa-face-meh fa-fw" },
    { label: "皱眉", value: "fas fa-face-frown fa-fw" },
    { label: "电梯", value: "fas fa-elevator fa-fw" },
    { label: "剪贴板用户", value: "fas fa-clipboard-user fa-fw" },
    { label: "孩子", value: "fas fa-children fa-fw" },
    { label: "儿童接触", value: "fas fa-child-reaching fa-fw" },
    { label: "童装", value: "fas fa-child-dress fa-fw" },
    { label: "黑板用户", value: "fas fa-chalkboard-user fa-fw" },
    { label: "床", value: "fas fa-bed fa-fw" },
    {
      label: "箭头-向下到人",
      value: "fas fa-arrows-down-to-people fa-fw",
    },
    { label: "身份证", value: "far fa-id-card fa-fw" },
    { label: "ID徽章", value: "far fa-id-badge fa-fw" },
    { label: "脸-meh", value: "far fa-face-meh fa-fw" },
    { label: "皱眉", value: "far fa-face-frown fa-fw" },
  ],
  天气: [
    { label: "云", value: "fas fa-cloud fa-fw" },
    { label: "螺栓", value: "fas fa-bolt fa-fw" },
    { label: "伞", value: "fas fa-umbrella fa-fw" },
    { label: "雪花", value: "fas fa-snowflake fa-fw" },
    { label: "雪花", value: "far fa-snowflake fa-fw" },
    { label: "sun", value: "fas fa-sun fa-fw" },
    { label: "sun", value: "far fa-sun fa-fw" },
    { label: "poo-storm", value: "fas fa-poo-storm fa-fw" },
    { label: "water", value: "fas fa-water fa-fw" },
    { label: "moon", value: "fas fa-moon fa-fw" },
    { label: "moon", value: "far fa-moon fa-fw" },
    { label: "wind", value: "fas fa-wind fa-fw" },
    { label: "火山", value: "fas fa-volcano fa-fw" },
    { label: "龙卷风", value: "fas fa-tornado fa-fw" },
    {
      label: "温度-四分之三",
      value: "fas fa-temperature-three-quarters fa-fw",
    },
    {
      label: "温度四分之一",
      value: "fas fa-temperature-quarter fa-fw",
    },
    { label: "温度低", value: "fas fa-temperature-low fa-fw" },
    { label: "温度高", value: "fas fa-temperature-high fa-fw" },
    { label: "温度-一半", value: "fas fa-temperature-half fa-fw" },
    { label: "满温", value: "fas fa-temperature-full fa-fw" },
    { label: "温度-空", value: "fas fa-temperature-empty fa-fw" },
    {
      label: "温度箭头向上",
      value: "fas fa-temperature-arrow-up fa-fw",
    },
    {
      label: "温度向下箭头",
      value: "fas fa-temperature-arrow-down fa-fw",
    },
    { label: "日晒植物枯萎病", value: "fas fa-sun-plant-wilt fa-fw" },
    { label: "烟雾", value: "fas fa-smog fa-fw" },
    { label: "彩虹", value: "fas fa-rainbow fa-fw" },
    { label: "流星", value: "fas fa-meteor fa-fw" },
    { label: "冰柱", value: "fas fa-icicles fa-fw" },
    { label: "飓风", value: "fas fa-hurricane fa-fw" },
    { label: "房屋海啸", value: "fas fa-house-tsunami fa-fw" },
    { label: "云-太阳-雨", value: "fas fa-cloud-sun-rain fa-fw" },
    { label: "云太阳", value: "fas fa-cloud-sun fa-fw" },
    { label: "云-阵雨-水", value: "fas fa-cloud-showers-water fa-fw" },
    { label: "多云阵雨", value: "fas fa-cloud-showers-heavy fa-fw" },
    { label: "云雨", value: "fas fa-cloud-rain fa-fw" },
    { label: "云-月-雨", value: "fas fa-cloud-moon-rain fa-fw" },
    { label: "云月，", value: "fas fa-cloud-moon fa-fw" },
    { label: "云肉丸", value: "fas fa-cloud-meatball fa-fw" },
    { label: "云-螺栓", value: "fas fa-cloud-bolt fa-fw" },
    { label: "螺栓闪电", value: "fas fa-bolt-lightning fa-fw" },
  ],
  写作: [
    { label: "信封", value: "fas fa-envelope fa-fw" },
    { label: "信封", value: "far fa-envelope fa-fw" },
    { label: "回形针", value: "fas fa-paperclip fa-fw" },
    { label: "文件", value: "fas fa-file fa-fw" },
    { label: "文件", value: "far fa-file fa-fw" },
    { label: "笔", value: "fas fa-pen fa-fw" },
    { label: "书", value: "fas fa-book fa-fw" },
    { label: "书签", value: "fas fa-bookmark fa-fw" },
    { label: "书签", value: "far fa-bookmark fa-fw" },
    { label: "笔到正方形", value: "fas fa-pen-to-square fa-fw" },
    { label: "笔到正方形", value: "far fa-pen-to-square fa-fw" },
    { label: "文件夹", value: "fas fa-folder fa-fw" },
    { label: "文件夹", value: "far fa-folder fa-fw" },
    { label: "文件夹打开", value: "fas fa-folder-open fa-fw" },
    { label: "文件夹打开", value: "far fa-folder-open fa-fw" },
    { label: "图钉", value: "fas fa-thumbtack fa-fw" },
    { label: "纸飞机", value: "fas fa-paper-plane fa-fw" },
    { label: "纸飞机", value: "far fa-paper-plane fa-fw" },
    { label: "左引号", value: "fas fa-quote-left fa-fw" },
    { label: "铅笔", value: "fas fa-pencil fa-fw" },
    { label: "报纸", value: "fas fa-newspaper fa-fw" },
    { label: "报纸", value: "far fa-newspaper fa-fw" },
    { label: "引用权", value: "fas fa-quote-right fa-fw" },
    { label: "博客", value: "fas fa-blog fa-fw" },
    { label: "签名", value: "fas fa-signature fa-fw" },
    { label: "键盘", value: "fas fa-keyboard fa-fw" },
    { label: "键盘", value: "far fa-keyboard fa-fw" },
    { label: "橡皮擦", value: "fas fa-eraser fa-fw" },
    { label: "方笔", value: "fas fa-square-pen fa-fw" },
    { label: "笔夹", value: "fas fa-pen-clip fa-fw" },
    { label: "段", value: "fas fa-paragraph fa-fw" },
    { label: "笔记粘性", value: "fas fa-note-sticky fa-fw" },
    { label: "不定义", value: "fas fa-notdef fa-fw" },
    { label: "文件行", value: "fas fa-file-lines fa-fw" },
    { label: "信封打开", value: "fas fa-envelope-open fa-fw" },
    { label: "盒存档", value: "fas fa-box-archive fa-fw" },
    { label: "书签", value: "fas fa-book-bookmark fa-fw" },
    { label: "笔记粘性", value: "far fa-note-sticky fa-fw" },
    { label: "文件行", value: "far fa-file-lines fa-fw" },
    { label: "信封打开", value: "far fa-envelope-open fa-fw" },
  ],
};
