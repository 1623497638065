var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-wrapper"},[_c('div',{staticClass:"layout-left",style:([
      { background: !_vm.isCollapse ? '#fff' : '#f4f5f7' },
      {
        width: _vm.menuWrapWidth + 'px',
        marginLeft: !_vm.isCollapse
          ? _vm.wrapMarginLeft + 'px'
          : _vm.wrapMarginLeft + 0 + 'px',
      },
    ])},[(!_vm.isCollapse)?_c('div',{staticClass:"drag-right",class:_vm.onDraging ? 'onDraging' : '',on:{"mousedown":function($event){return _vm.dragdown($event)}}}):_vm._e(),_c('i',{staticClass:"toggle-panel custom-icon",class:!_vm.isCollapse ? 'iconfont icon-zhedie' : 'iconfont icon-zhankai',staticStyle:{"font-size":"20px"},style:(!_vm.isCollapse ? 'right: -20px;' : 'right: -26px;'),on:{"click":_vm.toggleBoardVisible}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticStyle:{"height":"100%"}},[_vm._t("tree")],2)]),_c('div',{staticClass:"layout-right"},[_vm._t("body")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }