<template>
  <div class="fun-column">
    <div class="fun-header">函数</div>
    <div class="fun-body">
      <!-- 函数列表容器 -->
      <div class="fun-container">
        <div class="border-bottom">
          <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            size="small"
            style="width: 100%"
            class="no-border"
            v-model="funText"
          >
          </el-input>
        </div>

        <div class="flex-scroll">
          <template v-if="isSearchFun">
            <ul class="f-ul">
              <li
                v-for="col in searchFunList"
                :key="col.value"
                v-text="col.value"
                :class="funTab == col.value ? 'select' : ''"
                @mouseenter="mouseenter(col)"
                @click="selectFun(col)"
              ></li>
            </ul>
          </template>
          <template v-else>
            <div v-for="(item, index) in functions" :key="index">
              <div @click="toggleFun(index)" class="f-header">
                <i
                  :class="
                    item.visible ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                  "
                ></i>
                <div v-text="item.title"></div>
              </div>
              <ul v-show="item.visible" class="f-ul">
                <li
                  v-for="col in item.children"
                  :key="col.value"
                  v-text="col.value"
                  :class="funTab == col.value ? 'select' : ''"
                  @mouseenter="mouseenter(col)"
                  @click="selectFun(col)"
                ></li>
              </ul>
            </div>
          </template>
        </div>
      </div>
      <!-- 函数解释 -->
      <div class="fun-desc">
        <div class="rely-desc-header" v-text="funTab"></div>
        <fun-desc :funName="funTab"></fun-desc>
      </div>
    </div>
  </div>
</template>
<script>
import { relyFuns } from "@/zgg-core/relyFuns";
import { isEmpty } from "@/zgg-core/utils";
import funDesc from "./fun-desc";

export default {
  components: { funDesc },
  data() {
    return {
      funText: "",
      funTab: "",
      functions: [],
    };
  },
  props: {
    disabledFuns: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    let list = this._.cloneDeep(relyFuns);
    list.forEach((row) => {
      row.children = row.children.filter(
        (row) => !this.disabledFuns.includes(row.value),
      );
    });
    this.$set(this, "functions", list);
  },
  computed: {
    isSearchFun() {
      return !isEmpty(this.funText);
    },
    searchFunList() {
      let list = [];
      this.functions.forEach((item) => {
        list = list.concat(
          item.children.filter(
            (opt) =>
              opt.value.toLowerCase().indexOf(this.funText.toLowerCase()) >= 0,
          ),
        );
      });
      return list;
    },
  },
  methods: {
    selectFun(item) {
      this.$emit("insert", item.value);
    },

    toggleFun(i) {
      this.functions.forEach((item, index) => {
        if (i == index) {
          item.visible = !item.visible;
        } else {
          item.visible = false;
        }
      });
    },
    mouseenter(col) {
      this.funTab = col.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.fun-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.fun-body {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.fun-header {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 5px;
}
.fun-container {
  width: 220px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  margin-right: 10px;
}
.fun-desc {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.flex-scroll {
  flex: 1;
  overflow: auto;
}

.border-bottom {
  border-bottom: solid 1px #e0e0e0;
}
.no-border {
  ::v-deep {
    .el-input__inner {
      border: 0;
    }
  }
}
.f-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  margin: 0px 10px;
}
.f-ul {
  margin: 0px;
  padding: 0px;
  li {
    font-size: 12px;
    margin: 0px;
    padding: 0px 10px 0px 22px;
    list-style: none;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    &.select {
      background-color: #e6f6f4;
    }
  }
}
.rely-desc-header {
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  font-size: 12px;
  border-bottom: solid 1px #e0e0e0;
}
.rely-desc-body {
  white-space: pre-wrap;
  overflow: auto;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}
</style>
