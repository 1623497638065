<template>
  <div
    v-if="tab == 'attribute'"
    class="filter-container"
    style="padding: 20px; overflow: auto"
  >
    <zgg-filter
      style="width: 900px"
      :dataFilter="node.dataFilter"
      :componentList="componentList"
      :addFirst="true"
      @addFilter="addFilter"
    >
      <template slot-scope="{ field }">
        <div class="etl-filter-row">
          <el-select
            v-model="field.key"
            placeholder="请选择组件"
            size="small"
            style="width: 180px; margin-left: 10px"
            @change="changeField(field, $event)"
            :class="isDelete(field.key) ? 'etl-error' : ''"
          >
            <el-option
              v-for="opt in componentList"
              :key="opt.name"
              :label="opt.comment"
              :value="opt.name"
            >
            </el-option>
          </el-select>

          <!-- 操作符 -->
          <el-select
            v-model="field.oper"
            size="small"
            style="margin-left: 10px; width: 100px"
            @change="changeOper(field, $event)"
            class="overflow"
          >
            <el-option
              v-for="opt in getOperOptions(field)"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            >
            </el-option>
          </el-select>
          <query-input
            v-if="!['nil', 'nnil'].includes(field.oper)"
            :key="field.key"
            :field="field"
            :areaTree="areaTree"
            :areaProps="areaProps"
            style="flex: 1; margin-left: 10px"
            v-model="field.value"
            :componentList="componentList.map((item) => item.component)"
          ></query-input>
        </div>
      </template>
    </zgg-filter>
  </div>
  <div v-else class="filter-container">
    <preview-error v-if="isError"></preview-error>
    <etl-preview
      v-else
      :node="node"
      :etlList="etlList"
      :etlLines="etlLines"
    ></etl-preview>
  </div>
</template>
<script>
import nodeMixin from "./nodeMixin";
import { advanceFields, getComponentQueryOper } from "@/zgg-core/whiteList";
import { changeChildrenData, deleteMixins, getFieldListByNode } from "./util";
import PreviewError from "./PreviewError";
import EtlPreview from "./EtlPreview";
export default {
  components: { PreviewError, EtlPreview },
  mixins: [nodeMixin, deleteMixins],
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
    areaProps: Object,
    areaTree: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    componentList() {
      let fromKey = this.etlLines.find(
        (item) => item.toKey == this.node.key,
      ).fromKey;
      let fromNode = this.etlList.find((item) => item.key == fromKey);

      let list = getFieldListByNode(fromNode, this.etlList);
      let advanceFieldList = advanceFields.filter(
        (val) => val != "cascade_data",
      );
      list = list.filter((item) =>
        advanceFieldList.includes(item.componentName),
      );
      list.forEach((item) => {
        item.title = item.comment;
      });
      return list;
    },
    relTitle() {
      if (this.node.dataFilter.rel == "and") {
        return "并且";
      } else {
        return "或者";
      }
    },
  },
  methods: {
    getOperOptions(field) {
      let queryList = this.getComponentQueryOper(field.componentName);
      if (field.key == "currentFlowNodeTitles") {
        queryList = queryList.filter((row) => row.value != "eq");
      }
      return queryList;
    },
    getComponentQueryOper,
    deleteRow(index) {
      this.$delete(this.node.dataFilter.advanceQuery, index);
      if (this.node.dataFilter.advanceQuery.length == 0) {
        this.$set(this.node.dataFilter, "rel", "and");
      }
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
    changeOper(item) {
      if (["in", "nin"].includes(item.oper)) {
        item.value = [];
      } else {
        item.value = "";
      }
    },
    changeField(item, val) {
      let field = this.componentList.find((item) => item.name == val);
      let queryList = getComponentQueryOper(field.componentName); // getComponentQueryOper(obj.componentName);
      if (item.key == "currentFlowNodeTitles") {
        queryList = queryList.filter((row) => row.value != "eq");
      }
      let oper = queryList[0].value;
      item.componentName = field.componentName;
      item.picker = field.picker;
      item.title = field.comment;
      item.oper = oper;
      item.collection = field.collection;
      item.referenceName = field.referenceName;

      if (["in", "nin"].includes(item.oper)) {
        item.value = [];
      } else {
        item.value = "";
      }
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
    addFilter() {
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-container {
  height: 100%;
  background-color: #ffffff;
  overflow: auto;
}
.query-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  max-width: 800px;
  .overflow {
    ::v-deep {
      .el-input__inner {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .flex {
    flex: 1;
    margin-left: 10px;
  }
}
.delete-row {
  ::v-deep {
    .el-input__inner {
      color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
.etl-filter-row {
  flex: 1;
  display: flex;
}
</style>
