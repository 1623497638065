<template>
  <div>
    <div class="attr-row">
      <div class="attr-label">{{ title }}</div>
      <el-popover
        popper-class="form-popover"
        placement="bottom-start"
        v-model="visible"
        :disabled="disabled"
        width="300"
      >
        <div v-clickoutside="handleClose">
          <div style="padding: 10px 10px 0px">
            <el-input
              placeholder="请输入表单名称"
              prefix-icon="el-icon-search"
              v-model="formName"
              size="mini"
              @input="searchForm"
            >
            </el-input>
          </div>

          <div class="pop-scroll">
            <div class="empty-text" v-if="tableData.length == 0">
              暂无表单数据
            </div>
            <template v-else>
              <div
                class="form-item"
                :class="{ select: item.id == component.tableName }"
                v-for="(item, index) in tableData"
                :key="index"
                @click="chkTableName(item)"
              >
                {{ item.title }}
              </div>
            </template>
          </div>
        </div>
        <div @click.stop="" slot="reference" class="el-meta-field">
          <div class="meta-text">
            {{ tableName }}
          </div>
          <i class="el-icon-arrow-down"></i>
        </div>
      </el-popover>
    </div>
    <div class="attr-row" v-if="component.componentName != 'button' && component.componentName != 'cascade_data'">
      <div class="attr-label">显示方式</div>
      <el-select
        v-model="component.displayMode"
        placeholder=""
        size="small"
        style="width: 100%"
      >
        <el-option label="内嵌" value="inline"></el-option>
        <el-option label="弹窗" value="popup"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { fetchFormList, getFormDetail } from "../api";
import { componentProps } from "./util";
import Clickoutside from "element-ui/src/utils/clickoutside";
export default {
  name: "attr-include-form",
  props: {
    ...componentProps,
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  directives: { Clickoutside },
  data() {
    return {
      visible: false,
      formName: "",
      tableData: [],
      tableLoading: false,
      tableName: "",
    };
  },
  computed: {
    title() {
      if(this.component.componentName == "cascade_data"){ // 级联组件
        return "关联表"
      }
      return "选择表单"
    }
  },
  created() {
    let formId = ""
    if (this.component.componentName == "button") {
      formId = this.component.eventConfig.formId
    } else if (this.component.componentName == "cascade_data") {
      formId = this.component.tableName
    } else {
      formId = this.component.formId
    }
    if (formId) {
      getFormDetail(formId).then((res) => {
        let title = res.data.form.title;
        let str = title.substring(title.length - 4);
        if (str == "表单视图") {
          title = title.substring(0, title.length - 4);
        }
        this.tableName = title;
      });
    }
    this.fetchFormList();
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    searchForm() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.fetchFormList();
      }, 500);
    },
    fetchFormList(data, callback) {
      this.tableData = [];
      this.tableLoading = true;
      let postData = {
        pageNumber: 1,
        pageSize: 100,
        title: this.formName,
        ...data,
        removeComponent: true,
      };

      // postData.idNotEquals = this.params.formId;

      fetchFormList(postData)
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.data.page.list;

          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    chkTableName(item) {
      if (this.component.formId == item.id) {
        return;
      }
      if (this.component.componentName == "button") {
        // 按钮组件
        this.$emit("changeSource", item);
        this.component.eventConfig.formId = item.id;
      } else if(this.component.componentName == "cascade_data"){
        // 级联组件
        this.$emit("changeSource", item);
      } else {
        this.component.formId = item.id;
      }
      this.tableName = item.title;
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  line-height: 30px;
  font-size: 14px;
  padding: 0px 10px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  .item-title {
    &.disabled {
      color: #c3cdda;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.3);
    }

    &.select {
      background-color: #409eff;

      &:hover {
        background-color: #409eff;
      }
    }
  }
}
.el-meta-field {
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  padding-right: 10px;
  border-radius: 5px;
  color: #606266;
  cursor: pointer;

  .meta-text {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .empty-text {
    font-size: 12px;
    text-align: center;
    line-height: 28px;
  }
}
</style>
<style lang="scss">
.form-popover {
  padding: 0px !important;
}
</style>