<template>
  <el-tooltip
    v-if="isDelete"
    effect="dark"
    content="字段已删除"
    placement="top"
  >
    <div
      class="el-input el-input--small delete-row"
      style="margin-left: 10px; width: 180px"
    >
      <div
        class="el-input__inner"
        style="text-align: center"
        v-text="queryField.title"
      ></div>
    </div>
  </el-tooltip>

  <el-select
    v-else
    style="margin-left: 10px; width: 180px"
    :value="
      queryField.referenceName
        ? `${queryField.referenceName}.${queryField.key}`
        : queryField.key
    "
    placeholder=""
    size="small"
    class="overflow"
    @change="changeField"
  >
    <el-option
      v-for="field in fieldColumns"
      :key="fieldKey(field)"
      :label="field.title"
      :value="fieldKey(field)"
    >
      <i v-if="field.title == '当前节点'" class="iconfont icon-liucheng"></i>
      <i
        v-else
        class="iconfont"
        :class="componentIcons[field.componentName]"
      ></i>

      <span>{{ field.title }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { advanceFields } from "@zgg-core-utils/whiteList";
import { isEmpty, componentsIcon } from "@zgg-core-utils/utils";

export default {
  props: {
    queryField: Object,
    fieldList: Array,
    useDefaultFields: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      componentIcons: componentsIcon,
    };
  },
  computed: {
    fieldColumns() {
      let componentList = this._.cloneDeep(this.fieldList);

      let list = [];

      let callback = (components, parent) => {
        components.forEach((item) => {
          if (item.container) {
            callback(item.components, item);
          } else if (item.componentName == "reference_data") {
            let obj = this._.cloneDeep(item);
            list.push(obj);
            if (!parent) {
              item.columns.forEach((col) => {
                let obj = this._.cloneDeep(col.field.component);
                // 关联数据的子表单字段不进入过滤条件
                if (
                  obj.componentName != "json_form" &&
                  advanceFields.includes(obj.componentName)
                ) {
                  obj.title = item.title + "." + col.title;
                  obj.collection = item.tableName;
                  obj.referenceName = item.name;
                  obj.parent = this._.cloneDeep(item);
                  list.push(obj);
                }
              });
            }
          } else if (item.form) {
            if (advanceFields.includes(item.componentName)) {
              let obj = this._.cloneDeep(item);
              if (parent && parent.componentName == "json_form") {
                obj.name = parent.name + "." + obj.name;
                obj.title = parent.title + "." + obj.title;
                obj.parent = this._.cloneDeep(parent);
              }
              list.push(obj);
            }
          }
        });
      };
      callback(componentList);
      return list;
    },
    fieldComponent() {
      let obj = this.queryField;
      let item = this.fieldColumns.find((field) => {
        // item.name == key2
        if (obj.referenceName) {
          if (field.parent) {
            if (field.parent.componentName == "reference_data") {
              return (
                field.parent.name == obj.referenceName && field.name == obj.key
              );
            } else if (field.parent.componentName == "form_data") {
              return (
                field.parent.key == obj.referenceName && field.name == obj.key
              );
            }
          }
          return false;
        } else {
          return field.name == obj.key;
        }
      });

      if (item) {
        return item;
      }
      return null;
    },
    isDelete() {
      if (isEmpty(this.queryField.key)) {
        return false;
      }
      if (this.fieldComponent) {
        return false;
      }
      return true;
    },
  },
  methods: {
    fieldKey(item) {
      if (item.parent) {
        if (item.parent.componentName == "form_data") {
          return item.parent.key + "." + item.name;
        } else if (item.parent.componentName == "reference_data") {
          return item.parent.name + "." + item.name;
        }
      }
      return item.name;
    },
    changeField(value) {
      this.$emit("changeField", {
        data: this.queryField,
        value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-row {
  ::v-deep {
    .el-input__inner {
      color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>
