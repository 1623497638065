<template>
  <div
    style="white-space: nowrap; overflow: hidden"
    :title="getValue(rowData, colConfig)"
    class="color-select-display__wrap"
  >
    <span
      style="
        margin-right: 4px;
        font-size: 13px;
        align-items: center;
        display: inline-flex;
      "
      class="custom-color-selected"
      v-for="(item, index) in getColValue(rowData, colConfig)"
      :key="index"
      :style="{
        backgroundColor: getBackgroundColor(rowData, item, colConfig.field),
        color: getBackgroundColor(rowData, item, colConfig.field)
          ? 'white'
          : 'black'
      }"
    >
      {{ item }}
    </span>
  </div>
</template>

<script>
import { getRandomColor } from "@zgg-core-utils/utils";
import moment from "moment/moment";
import { getMomentFormat, isEmpty } from "@zgg-core-utils/utils";

export default {
  name: "color-select-display",
  props: {
    rowData: {
      type: Object,
      default: () => {}
    },
    colConfig: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getColValue(row, col) {
      let field = col.field;

      let rowObj = row;
      if (col.referenceName) {
        rowObj = row[col.referenceName + "ReferenceData"];
        if (rowObj instanceof Array) {
          rowObj = rowObj[0];
        }
      }
      if (!rowObj) {
        rowObj = {};
      }

      if (
        field.componentName == "select_checkbox" ||
        field.componentName == "checkbox_group"
      ) {
        let valueArr = rowObj[field.name];
        if (valueArr && valueArr.length > 0) {
          return valueArr;
        }
      } else {
        let value = this.getValue(row, col);
        if (value) {
          if (typeof value === "string") {
            return [value] || [];
          } else if (typeof value === "object") {
            return value;
          }
        }
        return [];
      }
    },
    getBackgroundColor(row, item, field) {
      let color = "";
      if (item == "") return color;
      if (
        field.component.optionSource == "custom" &&
        field.component.customOptions
      ) {
        let isExist = field.component.customOptions.find(o => o.value == item);
        if (isExist) {
          color = isExist.color;
        } else {
          color = getRandomColor(item);
        }
      }
      return color;
    },
    getValue(row, col) {
      let field = col.field;
      let rowObj = row;
      if (col.referenceName) {
        rowObj = row[col.referenceName + "ReferenceData"];
        if (rowObj instanceof Array) {
          rowObj = rowObj[0];
        }
      }
      if (!rowObj) {
        rowObj = {};
      }

      if (field.componentName == "address_input") {
        let obj = rowObj[field.name];
        if (obj) {
          let str = `${obj.province}`;
          if (obj.city) {
            str += obj.city;
          }
          if (obj.county) {
            str += obj.county;
          }
          if (obj.address) {
            str += obj.address;
          }
          return str;
        }
        return;
      } else if (
        field.componentName == "checkbox_group" ||
        field.componentName == "select_checkbox"
      ) {
        let a = rowObj[field.name];
        if (isEmpty(a)) {
          return;
        }

        if (typeof a === "string") {
          return JSON.parse(a).join(",");
        } else if (typeof a === "object") {
          return a.join(",");
        }
      } else if (field.componentName == "position_input") {
        let data = rowObj[field.name];
        if (data) {
          return data.address;
        }
        return;
      } else if (field.componentName == "user_list_select") {
        let value = rowObj[field.name + "UserMap"];
        if (value) {
          let list = Object.values(value).map(item => item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "user_select") {
        let value = rowObj[field.name + "UserInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_select") {
        let value = rowObj[field.name + "DepartmentInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_list_select") {
        let value = rowObj[field.name + "DepartmentInfoList"];
        if (value) {
          let list = value.map(item => item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "date_picker") {
        let value = rowObj[field.name];
        if (value) {
          if (field.component) {
            value = moment(value).format(getMomentFormat(field.component));
          }

          if (value == "Invalid date") {
            value = rowObj[field.name];
          }
          return value;
        }
        return;
      } else if (field.name == "flowStatus") {
      } else {
        return rowObj[field.name];
      }
      return;
    }
  }
};
</script>

<style scoped lang="scss">
.color-select-display__wrap {
  .custom-color-selected {
    height: 24px;
    line-height: 24px;
    padding: 4px 10px;
    border-radius: 50px;
    color: #fff;
    text-overflow: ellipsis;
  }
}
</style>
