import { login, logout, getInfo, codeLogin } from "@/api/user";
import {
  getToken,
  setToken,
  removeToken,
  setRegistered,
  removeRegistered,
} from "@/utils/auth";
import { resetRouter } from "@/router";
import da from "element-ui/src/locale/lang/da";
import { passwordEncryption } from "@/views/login/encrpt-util";

const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: "",
    registered: "",
    id: "",
    currentUser: {},
    currentCompanyId: "",
    currentCompanyName: "",
    companyList: [],
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_USER_ID: (state, id) => {
    state.id = id;
  },
  //registered
  SET_REGISTERED: (state, registered) => {
    state.registered = registered;
  },
  SET_CURRENT_USER: (state, currentUser) => {
    state.currentUser = currentUser;
    state.currentCompanyId = currentUser.companyId;
    state.currentCompanyName = currentUser.companyName;
  },
  SET_COMPANY_ID: (state, companyId) => {
    state.currentCompanyId = companyId;
  },
  SET_COMPANY_NAME: (state, companyName) => {
    state.currentCompanyName = companyName;
  },
  SET_COMPANY_LIST: (state, companyList) => {
    state.companyList = companyList;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    let { loginName, password } = userInfo;
    loginName = passwordEncryption(loginName.toString().trim());
    password = passwordEncryption(password);
    return new Promise((resolve, reject) => {
      login({ loginName, password })
        .then((response) => {
          const { token, name, id } = response.data.currentUser;
          commit("SET_TOKEN", token);
          commit("SET_NAME", name);
          commit("SET_USER_ID", id);
          localStorage.setItem("SET_USER_ID", id);

          setToken(token);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  // user code login
  codeLogin({ commit }, userInfo) {
    const { mobile, code, sid } = userInfo;
    return new Promise((resolve, reject) => {
      codeLogin({ mobile: mobile, code: code, sid })
        .then((response) => {
          const { token, name, id } = response.data.currentUser;
          commit("SET_TOKEN", token);
          commit("SET_NAME", name);
          commit("SET_USER_ID", id);
          localStorage.setItem("SET_USER_ID", id);
          setToken(token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { currentUser } = response.data;
          if (!currentUser) {
            reject("Verification failed, please Login again.");
          }

          const { name, avatar, id } = currentUser;
          console.log("currentUser", currentUser);

          commit("SET_NAME", name);
          commit("SET_AVATAR", avatar);
          commit("SET_USER_ID", id);
          commit("SET_CURRENT_USER", currentUser);

          resolve(currentUser);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          removeToken(); // must remove  token  first
          resetRouter();
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          removeToken(); // must remove  token  first
          resetRouter();
          commit("RESET_STATE");
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },
  setLoginUserInfo({ commit }, user) {
    return new Promise((resolve) => {
      console.log(user);
      const { name, token, id } = user;
      commit("SET_NAME", name);
      commit("SET_TOKEN", token);
      commit("SET_USER_ID", id);
      setToken(token);
      resolve();
    });
  },

  setCurrentUser({ commit }, currentUser) {
    return new Promise((resolve) => {
      commit("SET_CURRENT_USER", currentUser);
      resolve();
    });
  },
  setCompanyId({ commit }, companyId) {
    return new Promise((resolve) => {
      commit("SET_COMPANY_ID", companyId);
      resolve();
    });
  },
  setCompanyName({ commit }, companyName) {
    return new Promise((resolve) => {
      commit("SET_COMPANY_NAME", companyName);
      resolve();
    });
  },
  setCompanyList({ commit }, companyList) {
    return new Promise((resolve) => {
      commit("SET_COMPANY_LIST", companyList);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
