<template>
  <div>
    <el-form :model="node" ref="form" :disabled="disabled" label-position="top">
      <el-form-item label="请求方法" :required="true" prop="method">
        <el-select v-model="method" placeholder="请选择" size="mini">
          <el-option
            v-for="val in httpMethods"
            :key="val"
            :label="val"
            :value="val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="url"
        prop="url"
        :rules="[{ required: true, message: '请输入url地址' }]"
      >
        <rpa-codemirror
          ref="httpUrl"
          :fieldList="fieldList"
          @change="changeUrlMsg"
          :disabled="disabled"
        ></rpa-codemirror>
        <div v-if="isComplete && !disabled">
          <el-popover v-model="visible" trigger="click">
            <el-button type="text" icon="el-icon-plus" slot="reference"
              >添加字段</el-button
            >
            <div style="max-height: 320px; overflow: auto">
              <el-tree
                :props="treeProps"
                @node-click="urlNodeClick"
                :load="loadNode"
                :lazy="true"
              >
              </el-tree>
            </div>
          </el-popover>
        </div>
      </el-form-item>
      <el-form-item label="Headers" prop="header">
        <rpa-http-header
          v-for="(item, index) in headers"
          :key="index"
          :item="item"
          :disabled="disabled"
          :nodeKey="node.key"
          :index="index"
          @del="delHeader"
        ></rpa-http-header>
        <div>
          <el-button
            @click="addHeader"
            :disabled="headers >= 50 || disabled"
            icon="el-icon-plus"
            type="text"
            >header</el-button
          >
        </div>
      </el-form-item>
      <el-form-item v-if="hasBody" label="body">
        <el-radio-group v-model="mediaType">
          <el-radio label="form_data">form_data</el-radio>
          <el-radio label="x_www_form_urlencodedjson"
            >x_www_form_urlencoded</el-radio
          >
          <el-radio label="text">text</el-radio>
          <el-radio label="json">json</el-radio>
          <el-radio label="binary">binary</el-radio>
        </el-radio-group>
        <div
          v-if="
            mediaType == 'form_data' || mediaType == 'x_www_form_urlencodedjson'
          "
        >
          <rpa-http-header
            v-for="(item, index) in params"
            :key="index"
            :item="item"
            :disabled="disabled"
            :nodeKey="node.key"
            :index="index"
            :isShowComponentName="mediaType == 'form_data'"
            @del="delParams"
          ></rpa-http-header>
          <div>
            <el-button
              :disabled="disabled"
              type="text"
              icon="el-icon-plus"
              @click="addParams"
              >{{
                mediaType == "form_data" ? "Form" : "key-value pairs"
              }}</el-button
            >
          </div>
        </div>
        <rpa-node-fields
          v-else-if="mediaType == 'binary'"
          :nodeField="node.body.binaryNodeField"
          :tableField="{ componentName: 'attachment_uploader' }"
          fieldConfigKey="fillComponents"
          :disabled="disabled"
          :hasBaseRootParam="false"
        ></rpa-node-fields>
        <!-- mediaType:text/json -->
        <template v-if="mediaType == 'json' || mediaType == 'text'">
          <rpa-codemirror
            ref="bodyData"
            :fieldList="fieldList"
            @change="changeData"
            :disabled="disabled"
          ></rpa-codemirror>
          <div v-if="isComplete && !disabled">
            <el-popover v-model="visible2" trigger="click">
              <el-button type="text" icon="el-icon-plus" slot="reference"
                >添加字段</el-button
              >
              <div style="max-height: 320px; overflow: auto">
                <el-tree
                  :props="treeProps"
                  @node-click="dataNodeClick"
                  :load="loadNode"
                  :lazy="true"
                >
                </el-tree>
              </div>
            </el-popover>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="请求失败设置" prop="failConfig">
        <div style="font-size: 12px; line-height: 1.2">
          请求成功的 HTTP 状态码
        </div>
        <el-input
          v-model="successCodes"
          placeholder="示例：200,201（多个状态用英文逗号隔开）"
          size="mini"
        ></el-input>
        <div style="font-size: 14px; line-height: 1.2; margin-top: 10px">
          指定HTTP状态码错误消息
        </div>
        <div>
          <rpa-http-code-msg
            v-for="(item, index) in failCodeMsg"
            :key="index"
            :item="item"
            :index="index"
            @del="delFailCodeMsg"
          ></rpa-http-code-msg>
          <div>
            <el-button type="text" icon="el-icon-plus" @click="addFailCodeMsg"
              >状态码</el-button
            >
          </div>
        </div>
        <!-- defaultFailMsg -->
        <div style="font-size: 14px; line-height: 1.2; margin-top: 10px">
          返回其他HTTP状态码时的默认错误消息
        </div>
        <el-input
          v-model="defaultFailMsg"
          placeholder="请输入错误消息"
          size="mini"
        ></el-input>
      </el-form-item>
      <el-form-item label="返回参数列表">
        <div style="font-size: 12px; line-height: 1.2">
          向URL发送请求测试获取参数列表；请求中的动态参数将取测试值
        </div>
        <div>
          <el-button type="default" size="small" @click="testApi"
            >测试API</el-button
          >
        </div>
        <template v-if="node.responseExample">
          <div
            v-text="node.responseRemark"
            style="
              font-size: 13px;
              color: #757575;
              line-height: 1.2;
              margin-top: 10px;
            "
          ></div>
          <div>
            <div
              style="
                font-size: 14px;
                color: #42526e;
                font-weight: 700;
                line-height: 1.2;
                margin-top: 10px;
              "
            >
              响应body
            </div>
            <pre
              v-text="responseExample"
              style="line-height: 1.2; margin-top: 10px; font-size: 13px"
            ></pre>
          </div>
        </template>
        <div></div>
      </el-form-item>
      <el-form-item label="请求超时或请求失败时">
        <el-radio-group v-model="failWay" prop="failWay">
          <el-radio label="keep" style="display: block; margin-bottom: 5px"
            >继续执行</el-radio
          >
          <div
            style="
              font-size: 12px;
              color: #757575;
              line-height: 1;
              margin-bottom: 10px;
              margin-left: 22px;
            "
          >
            之后节点在使用本节点对象或数据时将跳过执行
          </div>
          <el-radio label="stop" style="display: block">中止流程</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <rpa-http-test-api ref="testApi" @postApi="postApi"></rpa-http-test-api>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import rpaCodemirror from "./rpa-codemirror";
import rpaNodeFields from "./rpa-node-fields";

import {
  buildNodeComponentsMap,
  fromNodesMixins,
  loadNodeLeaf,
} from "./rpa-utils";
import RpaHttpHeader from "./rpa-http-header";
import RpaHttpCodeMsg from "./rpa-http-code-msg";
import rpaHttpTestApi from "./rpa-http-test-api";
import { nodeTest } from "./api";
export default {
  name: "rpa-http-request",
  components: {
    rpaCodemirror,
    RpaHttpHeader,
    rpaNodeFields,
    RpaHttpCodeMsg,
    rpaHttpTestApi,
  },
  mixins: [fromNodesMixins],
  props: {
    node: Object,
    disabled: Boolean,
  },
  computed: {
    responseExample() {
      if (this.node.responseExample) {
        return JSON.stringify(this.node.responseExample, null, 4);
      }
      return "";
    },
    isComplete() {
      return !isEmpty(this.componentsMap);
    },
    method: {
      get() {
        return this.node.method;
      },
      set(val) {
        this.$set(this.node, "method", val);

        if (this.hasBody) {
          let body = {
            mediaType: "form_data",
            params: [],
            data: "",
            dataNodeFields: [],
            binaryNodeField: "",
          };
          this.$set(this.node, "body", body);
        } else {
          this.$set(this.node, "body", null);
        }
      },
    },
    hasBody() {
      return ["POST", "PUT", "PATCH"].includes(this.method);
    },
    mediaType: {
      get() {
        return this.node.body?.mediaType;
      },
      set(val) {
        this.$set(this.node.body, "params", []);
        this.$set(this.node.body, "data", "");
        this.$set(this.node.body, "dataNodeFields", []);
        if (val == "binary") {
          this.$set(this.node.body, "binaryNodeField", {
            nodeKey: "",
            collection: "",
            name: "",
            title: "",
            componentName: "",
            picker: "",
          });
        } else {
          this.$set(this.node.body, "binaryNodeField", "");
        }
        if (["json", "text"].includes(val)) {
          if (this.$refs.bodyData) {
            this.$refs.bodyData.init("");
          }
        }

        this.$set(this.node.body, "mediaType", val);
      },
    },
    headers: {
      get() {
        return this.node.header;
      },
    },
    params() {
      return this.node.body?.params ?? [];
    },
    successCodes: {
      get() {
        let successCodes = this.node.failConfig?.successCodes;
        if (!isEmpty(successCodes)) {
          return successCodes.join(",");
        }
        return "";
      },
      set(val) {
        let arr = val.toString().split(",");
        this.$set(this.node.failConfig, "successCodes", arr);
      },
    },
    defaultFailMsg: {
      get() {
        return this.node.failConfig?.defaultFailMsg;
      },
      set(val) {
        this.$set(this.node.failConfig, "defaultFailMsg", val);
      },
    },
    failWay: {
      get() {
        return this.node.failConfig?.failWay;
      },
      set(val) {
        this.$set(this.node.failConfig, "failWay", val);
      },
    },
  },
  data() {
    return {
      visible2: false,
      visible: false,
      fieldList: [],
      httpMethods: ["GET", "POST", "PUT", "DELETE", "HEAD", "PATCH"],
      componentsMap: {},
      rootFlowParams: [],
      treeProps: {
        label: (data) => {
          let str = data.title;
          if (data.formTitle) {
            str += `【${data.formTitle}】`;
          } else if (data.collectionTitle) {
            str += `【${data.collectionTitle}】`;
          }
          return str;
        },
        isLeaf: "leaf",
      },
      failCodeMsg: [],
    };
  },
  async created() {
    if (this.node.failConfig?.failCodeMsg) {
      let failCodeMsg = [];
      for (const [key, value] of Object.entries(
        this.node.failConfig.failCodeMsg,
      )) {
        failCodeMsg.push({
          key,
          value,
        });
      }
      this.failCodeMsg = failCodeMsg;
    }
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }

    let parentNodes = this.getParentNodes();
    let componentsMap = await buildNodeComponentsMap.bind(this)(parentNodes);

    if (this.rootFlowParams?.length) {
      componentsMap["root"] = this.rootFlowParams;
    }
    this.buildFieldList("root", componentsMap["root"]);

    this.buildDeleteField();
    this.parentNodes = parentNodes;

    this.$set(this, "componentsMap", componentsMap);
    this.$nextTick(() => {
      if (this.$refs.httpUrl && !isEmpty(this.node?.url)) {
        this.$refs.httpUrl.init(this.node.url);
      }
      if (this.$refs.bodyData && !isEmpty(this.node?.body.data)) {
        this.$refs.bodyData.init(this.node.body.data);
      }
    });
  },
  methods: {
    validatorNode() {
      // console.log(this.parentNodes);
      if (isEmpty(this.node.url)) {
        this.$message.error("API URL 必填");
        return false;
      }

      /**
       * @typedef error
       * @property {number} index
       * @property {string} message
       * @property {string} error
       */

      /**
       * @type Array<error>
       */
      let errors = [];

      /**
       *
       * @param {Array} list
       * @returns {Array<error>}
       */
      let validateParams = (list) => {
        /**
         * @type Array<error>
         */
        let errs = [];
        list.forEach((item, index) => {
          if (isEmpty(item.param.name)) {
            errs.push({ index, message: "", error: "key" });
          }
          let valueType = item.paramValue.valueType;
          if (valueType == "nodeField") {
            if (isEmpty(item.paramValue.nodeField)) {
              errs.push({
                index,
                message: "",
                error: "value",
              });
            }
          } else if (valueType == "custom") {
            if (isEmpty(item.paramValue.customValue)) {
              errs.push({
                index,
                message: "",
                error: "value",
              });
            }
          } else if (valueType == "rely") {
            if (isEmpty(item.paramValue.rely?.originalFormula)) {
              errs.push({
                index,
                message: "",
                error: "value",
              });
            }
          }
        });
        return errs;
      };
      if (this.node.header) {
        let errs = validateParams(this.node.header);

        errs.forEach((item) => {
          item.message = "请完善header配置";
        });
        errors = errors.concat(errs);
      }
      if (this.node.body?.params) {
        let errs = validateParams(this.node.body.params);
        errs.forEach((item) => {
          item.message = "请完善body参数配置";
        });
        errors = errors.concat(errs);
      }

      if (errors.length) {
        this.$message.error(errors[0].message);
        return false;
      }

      return true;
    },
    testApi() {
      if (!this.validatorNode()) {
        return;
      }

      let list = [];

      let buildFields = (nodeFields, isAllInput = true) => {
        nodeFields.forEach((item) => {
          if (
            list.findIndex(
              (opt) => opt.nodeKey == item.nodeKey && opt.name == item.name,
            ) == -1
          ) {
            list.push({
              componentName: isAllInput ? "input" : item.componentName,
              name: item.name,
              nodeKey: item.nodeKey,
              title: item.title,
            });
          }
        });
      };
      if (this.node.urlNodeFields?.length) {
        // console.log(this.node.url)
        buildFields(this.node.urlNodeFields);
      }
      if (this.node.header) {
        let nodeFields = this.node.header
          .filter((item) => item.paramValue.valueType == "nodeField")
          .map((item) => item.paramValue.nodeField);

        buildFields(nodeFields);
        let relyNodes = [];
        this.node.header
          .filter((item) => item.paramValue.valueType == "rely")
          .forEach((item) => {
            if (!isEmpty(item.paramValue?.rely?.originalFormula)) {
              item.paramValue.rely.originalFormula.forEach((rows) => {
                let fields = rows.filter((col) => col.type == "field");
                fields.forEach((col) => {
                  relyNodes.push({
                    componentName: col.componentName,
                    name: col.name,
                    nodeKey: col.nodeKey,
                    title: col.value,
                  });
                });
              });
            }
          });
        buildFields(relyNodes);
      }
      if (this.node.body) {
        if (this.node.body.dataNodeFields) {
          buildFields(this.node.body.dataNodeFields);
        }
        if (!isEmpty(this.node.body.binaryNodeField)) {
          buildFields([this.node.body.binaryNodeField], false);
        }
        if (this.node.body.params) {
          let nodeFields = this.node.body.params
            .filter((item) => item.paramValue.valueType == "nodeField")
            .map((item) => {
              return {
                ...item.paramValue.nodeField,
                componentName: item.param.componentName,
              };
            });
          buildFields(nodeFields, false);
          let relyNodes = [];
          this.node.body.params
            .filter((item) => item.paramValue.valueType == "rely")
            .forEach((item) => {
              if (!isEmpty(item.paramValue.rely?.originalFormula)) {
                item.paramValue.rely.originalFormula.forEach((rows) => {
                  let fields = rows.filter((col) => col.type == "field");
                  fields.forEach((col) => {
                    relyNodes.push({
                      componentName: col.componentName,
                      name: col.name,
                      nodeKey: col.nodeKey,
                      title: col.value,
                    });
                  });
                });
              }
            });
          buildFields(relyNodes);
        }
      }

      list.forEach((item) => {
        item.key = item.name;
        let node = this.parentNodes.find((opt) => opt.key == item.nodeKey);
        item.nodeTitle = node.title;
      });
      if (list && list.length) {
        // 需要用到节点参数
        this.$refs.testApi.openTestApi(list);
      } else {
        // 没有用到节点参数
        this.postApi();
      }
      // console.log(list);
    },
    postApi(data) {
      let node = this._.cloneDeep(this.node);
      nodeTest({ contextData: data, node }).then((res) => {
        if (res.data.msg == "异常失败") {
          this.$message.error("异常失败");
          return;
        }
        this.$message.success("测试api请求成功");

        this.$set(this.node, "responseExample", res.data.body);
        this.$set(this.node, "responseRemark", res.data.msg);
        this.$refs.testApi.close();

        //
      });
    },
    addFailCodeMsg() {
      this.failCodeMsg.push({
        key: "",
        value: "",
      });
    },
    delFailCodeMsg(index) {
      this.failCodeMsg.splice(index, 1);
    },
    delHeader(index) {
      this.headers.splice(index, 1);
    },
    delParams(index) {
      this.params.splice(index, 1);
    },
    addParams() {
      this.params.push({
        param: {
          componentName: "input",
          name: "",
          titile: "",
        },
        paramValue: {
          valueType: "custom",
          customValue: "",
          customValueDetail: undefined,
          nodeField: undefined,
          rely: undefined,
        },
      });
    },
    addHeader() {
      this.headers.push({
        param: {
          componentName: "input",
          name: "",
          titile: "",
        },
        paramValue: {
          valueType: "custom",
          customValue: "",
          customValueDetail: undefined,
          nodeField: undefined,
          rely: undefined,
        },
      });
    },
    urlNodeClick(data, node) {
      if (node.isLeaf) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }
        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs.httpUrl.insert(obj);
        this.$refs.httpUrl.onBlur();
        this.visible = false;
      }
    },
    dataNodeClick(data, node) {
      if (node.isLeaf) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }
        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs.bodyData.insert(obj);
        this.$refs.bodyData.onBlur();
        this.visible2 = false;
      }
    },
    loadNode: loadNodeLeaf,
    changeUrlMsg(e) {
      this.$set(this.node, "url", e);
      let code = e;
      // eslint-disable-next-line no-useless-escape
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }

      this.$set(this.node, "urlNodeFields", arr);
    },
    changeData(e) {
      this.$set(this.node.body, "data", e);
      let code = e;
      // eslint-disable-next-line no-useless-escape
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }

      this.$set(this.node.body, "dataNodeFields", arr);
    },
    /** 构建编辑器用的组件列表 */
    buildFieldList(nodeKey, list) {
      if (list) {
        let tempList = list.map((item) => {
          return {
            nodeKey: nodeKey,
            fieldName: item.name,
            componentName: item.componentName,
            name: nodeKey + "_" + item.name,
            title: item.title,
          };
        });
        this.fieldList = this.fieldList.concat(tempList);
      }
    },
    /** 在fieldList插入已删除的字段列表用于编辑器展示提示 */
    buildDeleteField() {
      if (this.node?.urlNodeFields) {
        this.node.urlNodeFields.forEach((item) => {
          let name = item.nodeKey + "_" + item.name;
          let isDelete =
            this.fieldList.findIndex((row) => row.name == name) == -1;
          if (isDelete) {
            this.fieldList.push({
              nodeKey: item.nodeKey,
              fieldName: item.name,
              componentName: item.componentName,
              name: item.nodeKey + "_" + item.name,
              title: item.title,
              delete: true,
            });
          }
        });
      }
      if (this.node?.body?.dataNodeFields) {
        this.node.body.dataNodeFields.forEach((item) => {
          let name = item.nodeKey + "_" + item.name;
          let isDelete =
            this.fieldList.findIndex((row) => row.name == name) == -1;
          if (isDelete) {
            this.fieldList.push({
              nodeKey: item.nodeKey,
              fieldName: item.name,
              componentName: item.componentName,
              name: item.nodeKey + "_" + item.name,
              title: item.title,
              delete: true,
            });
          }
        });
      }
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.validatorNode()) {
          return;
        }
        let hasError =
          this.failCodeMsg.findIndex(
            (item) => isEmpty(item.key) || isEmpty(item.value),
          ) >= 0;
        if (hasError) {
          this.$message.error("请完善指定HTTP状态码错误消息");
          return;
        }
        let failCodeMsg;
        if (this.failCodeMsg?.length) {
          failCodeMsg = {};
          this.failCodeMsg.forEach((item) => {
            failCodeMsg[item.key] = item.value;
          });
        }
        this.$set(this.node.failConfig, "failCodeMsg", failCodeMsg);
        callback(valid);
      });
    },
  },
};
</script>
