import _ from "lodash";
import { getGrid } from "@/views/lowCode/view/components/chartUtil";

/** 图标宽度自适应 **/
export default {
  data() {
    return {
      observer: null,
      longestStr: "",
    };
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    setChartResize() {
      this.observer = new ResizeObserver(_.debounce(this.resizeChart, 50));
      this.observer.observe(this.$refs.chart);
    },
    resizeChart() {
      this.myChart &&
        this.myChart.resize({
          height: "auto",
          width: "auto",
        });
      if (this.componentName == "chart_process") {
        this.resizeProcessChart();
      }
      if (this.componentName == "chart_pie") {
        this.resizePieChart();
      }
      if (this.isGridChart) {
        this.resizeGridChart();
      }
    },
    resizeGridChart() {
      if (this.myChart) {
        let domWidth = this.myChart.getWidth();
        let domHeight = this.myChart.getHeight();
        let grid = getGrid(this.view);
        grid.containLabel = true;
        if (this.view?.chartLegend?.enable) {
          if (this.view.chartLegend?.position == "top") {
            grid.top = (parseFloat(grid.top) * domHeight) / 100 + 15;
          }
          if (this.view.chartLegend?.position == "bottom") {
            grid.bottom = (parseFloat(grid.bottom) * domHeight) / 100 + 15;
          }
          if (["left", "right"].includes(this.view.chartLegend?.position)) {
            grid[this.view.chartLegend.position] =
              (parseFloat(grid[this.view.chartLegend.position]) * domWidth) /
                100 +
              this.longestStr;
          }
        }
        this.myChart.setOption({ grid }, { replaceMerge: "grid" });
      }
    },
  },
};
