var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.companyId != 0)?_c('el-select',{staticStyle:{"width":"100%","margin-bottom":"10px"},attrs:{"placeholder":"请选择单位"},on:{"change":_vm.handleCompanySelect},model:{value:(_vm.selectCompanyId),callback:function ($$v) {_vm.selectCompanyId=$$v},expression:"selectCompanyId"}},_vm._l((_vm.companyList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1):_vm._e()],1),_c('div',{staticClass:"breadcrumb"}),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData},on:{"cell-click":_vm.handleCellClick,"selection-change":_vm.diskSelectionChange}},[_c('el-table-column',{staticStyle:{"padding":"0"},attrs:{"width":"80px","label":"类型","align":"center","class-name":"folder-table-type-column"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType == 'folder')?_c('svg-icon',{staticStyle:{"margin-right":"5px","font-size":"25px"},attrs:{"iconClass":"wenjianjia"}}):_c('svg-icon',{staticStyle:{"margin-right":"5px","font-size":"25px"},attrs:{"icon-class":_vm.getIconClassByExtension(scope.row.attachment.extension)}})]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"文件名","align":"left","class-name":"table-name-column"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","max-height":"300px","align-items":"center"}},[(
              scope.row.dataType == 'disk' &&
              _vm.checkFileImage(scope.row.attachment.extension)
            )?_c('el-image',{staticStyle:{"width":"100px","height":"100%"},attrs:{"src":scope.row.attachment.url,"preview-src-list":[scope.row.attachment.url],"z-index":2100}}):_vm._e(),_c('span',{staticClass:"cell"},[_vm._v(_vm._s(scope.row.title))])],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"100px","label":"格式"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType === 'folder')?_c('span',[_vm._v("文件夹")]):_c('span',[_vm._v(_vm._s(scope.row.attachment.extension))])]}}])}),_c('el-table-column',{attrs:{"prop":"updatedTime","align":"center","width":"200px","label":"保存时间"}}),_c('el-table-column',{attrs:{"align":"center","width":"80px","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.dataType != 'folder')?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!scope.row.added),expression:"!scope.row.added"}],attrs:{"type":"text","size":"medium"},on:{"click":function($event){return _vm.handleAddAssociate(
              scope.row,
              scope.row.dataType == 'disk' ? 30 : scope.row.dataType,
              scope.$index
            )}}},[_vm._v("添加 ")]):_vm._e(),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(scope.row.added),expression:"scope.row.added"}],attrs:{"type":"text","size":"medium","disabled":""}},[_vm._v("已添加 ")])]}}])})],1),_c('pagination',{attrs:{"total":_vm.paginationForm.total,"page":_vm.paginationForm.pageNumber,"limit":_vm.paginationForm.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.paginationForm, "pageNumber", $event)},"update:limit":function($event){return _vm.$set(_vm.paginationForm, "pageSize", $event)},"pagination":_vm.fetchData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }