<template>
  <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
    <el-form-item label="推送内容">
      <el-input
        value="打开链接"
        readonly
        size="small"
        placeholder=""
      ></el-input>
    </el-form-item>
    <el-form-item
      label="链接"
      prop="url"
      :rules="[{ required: true, message: '请编辑链接' }]"
    >
      <rpa-codemirror
        ref="rpaUrl"
        :fieldList="fieldList"
        @change="changeCode"
        :disabled="disabled"
      ></rpa-codemirror>
      <div v-if="isComplete && !disabled">
        <el-popover v-model="visible" trigger="click">
          <el-button type="text" icon="el-icon-plus" slot="reference"
            >添加字段</el-button
          >
          <div style="max-height: 320px; overflow: auto">
            <el-tree
              :props="treeProps"
              @node-click="nodeClick"
              :load="loadNode"
              :lazy="true"
            >
            </el-tree>
          </div>
        </el-popover>
      </div>
    </el-form-item>
    <el-form-item label="打开方式" prop="displayMode">
      <el-radio-group v-model="displayMode" size="small">
        <el-radio label="popup">弹窗</el-radio>
        <el-radio label="newPage">打开新页面</el-radio>
      </el-radio-group>
    </el-form-item>
  </el-form>
</template>
<script>
import rpaCodemirror from "./rpa-codemirror";
import {
  buildNodeComponentsMap,
  fromNodesMixins,
  loadNodeLeaf,
} from "./rpa-utils";
import { isEmpty } from "@zgg-core-utils/utils";

export default {
  name: "rpa-render-url",
  props: {
    node: Object,
    disabled: Boolean,
  },
  mixins: [fromNodesMixins],
  components: { rpaCodemirror },
  computed: {
    isComplete() {
      return !isEmpty(this.componentsMap);
    },
    displayMode: {
      get() {
        return this.node.displayMode;
      },
      set(val) {
        this.$set(this.node, "displayMode", val);
      },
    },
  },
  data() {
    return {
      visible: false,
      fieldList: [],
      treeProps: {
        label: (data) => {
          let str = data.title;
          if (data.formTitle) {
            str += `【${data.formTitle}】`;
          } else if (data.collectionTitle) {
            str += `【${data.collectionTitle}】`;
          }
          return str;
        },
        isLeaf: "leaf",
      },
      componentsMap: {},
      nodeList: [],
      rootFlowParams: [],
    };
  },
  async created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }

    let parentNodes = this.getParentNodes();
    let componentsMap = await buildNodeComponentsMap.bind(this)(parentNodes);

    if (this.rootFlowParams?.length) {
      componentsMap["root"] = this.rootFlowParams;
    }
    this.buildFieldList("root", componentsMap["root"]);
    let nodeList = [];
    parentNodes
      .filter((item) =>
        ["query_data_one", "trigger_form_data_change"].includes(item.type),
      )
      .forEach((item) => {
        let formTitle = item.formTitle || item.collectionTitle;
        nodeList.push({
          title: item.title + "【" + formTitle + "】",
          key: item.key,
        });
      });
    this.$set(this, "nodeList", nodeList);
    this.buildDeleteField();
    this.parentNodes = parentNodes;

    this.$set(this, "componentsMap", componentsMap);
    this.$nextTick(() => {
      if (this.$refs.rpaUrl && !isEmpty(this.node.url)) {
        this.$refs.rpaUrl.init(this.node.url);
      }
    });
  },
  methods: {
    nodeClick(data, node) {
      if (node.isLeaf) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }
        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs.rpaUrl.insert(obj);
        this.$refs.rpaUrl.onBlur();
        this.visible = false;
      }
    },
    changeCode(e) {
      this.$set(this.node, "url", e);
      let code = e;
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }

      this.$set(this.node, "nodeFields", arr);
    },
    /** 构建编辑器用的组件列表 */
    buildFieldList(nodeKey, list) {
      if (list) {
        let tempList = list.map((item) => {
          return {
            nodeKey: nodeKey,
            fieldName: item.name,
            componentName: item.componentName,
            name: nodeKey + "_" + item.name,
            title: item.title,
          };
        });
        this.fieldList = this.fieldList.concat(tempList);
      }
    },
    /** 在fieldList插入已删除的字段列表用于编辑器展示提示 */
    buildDeleteField() {
      if (this.node && this.node.nodeFields) {
        this.node.nodeFields.forEach((item) => {
          let name = item.nodeKey + "_" + item.name;
          let isDelete =
            this.fieldList.findIndex((row) => row.name == name) == -1;
          if (isDelete) {
            this.fieldList.push({
              nodeKey: item.nodeKey,
              fieldName: item.name,
              componentName: item.componentName,
              name: item.nodeKey + "_" + item.name,
              title: item.title,
              delete: true,
            });
          }
        });
      }
    },
    loadNode: loadNodeLeaf,
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let index = this.node.nodeFields.findIndex(
          (item) =>
            this.fieldList.findIndex(
              (row) =>
                row.delete &&
                item.name == row.fieldName &&
                item.nodeKey == row.nodeKey,
            ) >= 0,
        );

        if (index >= 0) {
          this.$message.error("通知内容存在被删除字段，请删除被删除字段");
          return;
        }
        callback(valid);
      });
    },
  },
};
</script>
