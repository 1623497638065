<template>
  <div
    @click.stop="showDetail"
    class="card-item"
    @mouseenter="moreVisible = true"
    @mouseleave="moreVisible = false"
  >
    <img v-if="card.coverImage" :src="card.coverImage" class="fill-img" />
    <el-dropdown
      v-if="!card.archived"
      v-show="moreVisible"
      class="top"
      trigger="hover"
    >
      <i class="el-icon-more more"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="!card.templated" @click.native="handleCopyCard"
          >复制任务
        </el-dropdown-item>
        <!-- <el-dropdown-item @click.native="handleCardArchive"
          >归档任务
        </el-dropdown-item> -->
        <el-dropdown-item v-if="hasColumn" @click.native="showMoveColumn"
          >移动到
        </el-dropdown-item>
        <el-dropdown-item @click.native="delCard">删除 </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div
      style="margin: 15px 10px 0; display: flex; flex-wrap: wrap"
      v-if="card.tagList && card.tagList.length"
    >
      <el-tag
        v-for="tag in card.tagList"
        :key="tag.id"
        :color="tag.backgroundColor"
        style="color: #ffffff"
        class="card-tag"
        >{{ tag.title }}
      </el-tag>
    </div>
    <div class="card-title" v-html="renderHtml(card.title)"></div>

    <div v-if="!hasColumn" class="createdTime">{{ card.createdTime }}</div>
    <div>
      <relationList
        :bulletin-and-plan-list="card.bulletinCardRelationList"
        :card-id="card.id"
        :form-list="card.formList"
        :excel-list="card.excelList"
        @updateCard="updateDetail"
      ></relationList>
    </div>
    <div class="attachment">
      <i class="el-icon-paperclip att-icon"></i>
      <el-badge :max="99" :value="imgCount" :hidden="!imgCount">
        <div
          v-loading="upImage"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          class="att-item"
          @click.stop="showAttachment('image')"
        >
          <svg
            t="1620226487502"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2048"
            width="64"
            height="64"
          >
            <path
              d="M0 64v832h1024V64H0z m64 64h896v448L691.2 422.4 358.4 684.8l-147.2-96L64 710.4V128z m0 659.2l153.6-121.6 256 166.4H64v-44.8zM588.8 832L416 716.8l281.6-224L960 640v192H588.8z"
              p-id="2049"
            ></path>
            <path d="M172.8 268.8h64v64h-64v-64z" p-id="2050"></path>
          </svg>
        </div>
      </el-badge>
      <el-badge :max="99" :value="mediaCount" :hidden="!mediaCount">
        <div
          v-loading="upMedia"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          class="att-item"
          @click.stop="showAttachment('midea')"
        >
          <svg
            t="1620226539355"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2815"
            width="64"
            height="64"
          >
            <path
              d="M914.05128205 167.38461538v689.23076924H109.94871795V167.38461538h804.1025641m0-57.43589743H109.94871795c-31.69230769 0-57.43589744 25.74358974-57.43589744 57.43589743v689.23076924c0 31.69230769 25.74358974 57.43589744 57.43589744 57.43589743h804.1025641c31.69230769 0 57.43589744-25.74358974 57.43589744-57.43589743V167.38461538c0-31.69230769-25.74358974-57.43589744-57.43589744-57.43589743z"
              p-id="2816"
            ></path>
            <path
              d="M420.1025641 372.20512821L653.12820513 512 420.1025641 651.79487179V372.20512821m0.10256411-57.53846154c-29.94871795 0-57.53846154 23.8974359-57.53846154 57.53846154v279.58974358c0 33.64102564 27.58974359 57.53846154 57.53846154 57.53846154 9.84615385 0 20-2.56410256 29.43589743-8.3076923L682.66666667 561.23076923c37.23076923-22.35897436 37.23076923-76.20512821 0-98.46153846L449.64102564 322.97435897c-9.43589744-5.74358974-19.58974359-8.30769231-29.43589743-8.3076923z"
              p-id="2817"
            ></path>
          </svg>
        </div>
      </el-badge>
      <el-badge :max="99" :hidden="!documentCount" :value="documentCount">
        <div
          v-loading="upDocument"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          class="att-item"
          @click.stop="showAttachment('other')"
        >
          <svg
            t="1620226590096"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5518"
            width="64"
            height="64"
          >
            <path
              d="M952.830699 896.018664a127.38409 127.38409 0 0 1-126.914825 127.981336H191.128447a127.576062 127.576062 0 0 1-127.128127-127.981336V127.981336a127.597392 127.597392 0 0 1 127.128127-127.981336h551.599559L959.869672 225.289812zM699.001049 63.990668v160.637907a30.992814 30.992814 0 0 0 31.142125 31.376758h159.336764z m190.478889 255.962672h-190.478889a63.713375 63.713375 0 0 1-63.564064-63.990668V63.990668H191.128447a63.734705 63.734705 0 0 0-63.564063 63.990668v767.888016a63.713375 63.713375 0 0 0 63.564063 63.990668h634.787427a63.862687 63.862687 0 0 0 63.564064-63.990668V319.95334z m-148.671652 426.24184H246.373724a32.699231 32.699231 0 0 1 0-63.990668h494.434562a32.699231 32.699231 0 0 1 0 64.054659z m0-165.757161H246.373724a32.699231 32.699231 0 0 1 0-63.990668h494.434562a32.699231 32.699231 0 0 1 0 64.054659z m-208.182974-165.75716H246.373724a31.995334 31.995334 0 0 1 0-62.732185h286.251588a31.995334 31.995334 0 0 1 0 62.796176z"
              p-id="5519"
            ></path>
          </svg>
        </div>
      </el-badge>
      <el-badge
        :max="99"
        :hidden="!card.commentCount"
        :value="card.commentCount"
      >
        <div
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          class="att-item"
        >
          <i
            class="iconfont icon-duihua"
            style="
              width: 16px;
              height: 16px;
              font-size: 17px;
              margin-right: 0px;
            "
          ></i>
        </div>
      </el-badge>
      <div class="coverUpload" @click.stop="stop">
        <upload
          style="padding: 0; margin-left: 0px"
          ref="fileUpload"
          :showFileList="false"
          @uploadSuccess="uploadAttachment"
          @onProgress="onProgress"
        >
          <el-button
            type="primary"
            round
            size="mini"
            icon="el-icon-plus"
          ></el-button>
        </upload>
      </div>
    </div>
    <div class="card-status">
      <div v-if="card.status == 20">
        <i class="el-icon-circle-check"></i>
        已完成
      </div>
      <div v-if="card.privated" style="margin-left: 10px; font-weight: bolder">
        <i class="el-icon-lock"></i>
      </div>
    </div>
    <comment-list
      ref="comment"
      v-if="!hasColumn"
      :cardId="card.id"
    ></comment-list>

    <card-detail
      :hasColumn="hasColumn"
      @copy="handleCopyCard"
      @archive="handleCardArchive"
      @columnRefresh="columnRefresh"
      @updateCard="updateCard"
      @showMoveColumn="showMoveColumn"
      @delCard="delCard"
      :cardId="card.id"
      :visible.sync="cardVisible"
      :isFormTpl="isFormTpl"
    ></card-detail>
    <copy-detail
      :visible.sync="copyVisible"
      :card="card"
      :index="index"
      :columnList="columnList"
      @columnRefresh="columnRefresh"
    ></copy-detail>

    <el-dialog
      title="附件"
      :visible.sync="attVisible"
      width="800px"
      @close="attVisible = false"
      :append-to-body="true"
      :destroy-on-close="true"
    >
      <div style="padding: 10px; padding-top: 0">
        <attachment-list
          ref="attList"
          :attachment-list="attachmentList"
          :renameFun="rename"
          @updateBulletinCard="updateDetail"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              icon="el-icon-paperclip"
              @click="handleAttachmentDownload(scope.row)"
              >下载
            </el-link>
            <el-link
              v-if="
                canPreview(scope.row.extension) &&
                imgExt.indexOf(scope.row.extension) == -1
              "
              type="primary"
              icon="el-icon-view"
              :href="scope.row.url"
              target="_blank"
              :download="scope.row.originalName"
              >查看
            </el-link>
            <el-link
              v-if="imgExt.indexOf(scope.row.extension) >= 0"
              type="primary"
              icon="el-icon-view"
              @click="reviewImage(scope.index)"
              >查看
            </el-link>
            <el-link
              type="danger"
              icon="el-icon-delete"
              @click="handleAttachmentDelete(scope.row)"
              >删除
            </el-link>
          </template>
        </attachment-list>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CommentList from "./CommentList";
import AttachmentList from "@/components/AttachmentList2";
import Upload from "@/components/Upload/indexNew";
import {
  addCardAttachment,
  bulletinCardArchive,
  bulletinCardDeleteAttachment,
  bulletinCardDetail,
  bulletinCardUserDelete,
  bulletinCardUserDeleteBatch,
  bulletinCardUserSaveBatch,
  renameAttachment,
  delCard,
} from "@/api/boardManage";
import CardDetail from "./cardDetail";
import CopyDetail from "./CopyDetail";
import { downFile } from "@/utils/ali-oss";
import { getFileExtension } from "@/utils/enums";

const doc = ["doc", "xls", "ppt", "pdf", "docx", "xlsx", "pptx", "txt"];
const midea = ["mp4", "mp3", "m4p", "flv", "m3u", "wav", "wma"];
const imgExt = ["jpg", "jpeg", "png", "bmp", "heic", "ico"];
import relationList from "@/views/excelLibManage/excelTemplateNew/component/board/relationList";

export default {
  mounted() {},
  components: {
    CardDetail,
    CopyDetail,
    CommentList,
    AttachmentList,
    Upload,
    relationList,
  },
  props: {
    card: Object,
    index: Number,
    columnList: Array,
    hasColumn: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isFormTpl: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    documentCount() {
      let count = 0;
      if (this.card.attachmentList) {
        count = this.card.attachmentList.filter(
          (item) =>
            imgExt.indexOf(item.extension) == -1 &&
            midea.indexOf(item.extension) == -1 &&
            !(item.percent && item.percent != 100),
        ).length;
      }
      return count;
    },
    imgCount() {
      let count = 0;
      if (this.card.attachmentList) {
        count = this.card.attachmentList.filter(
          (item) =>
            imgExt.indexOf(item.extension) >= 0 &&
            !(item.percent && item.percent != 100),
        ).length;
      }
      return count;
    },
    mediaCount() {
      let count = 0;
      if (this.card.attachmentList) {
        count = this.card.attachmentList.filter(
          (item) =>
            midea.indexOf(item.extension) >= 0 &&
            !(item.percent && item.percent != 100),
        ).length;
      }
      return count;
    },
    upDocument() {
      let loading = false;
      if (this.uploadList) {
        loading =
          this.uploadList.findIndex((item) => {
            let index = item.name.lastIndexOf(".");
            let extension = item.name.substring(index + 1);
            return (
              imgExt.indexOf(extension) == -1 && midea.indexOf(extension) == -1
            );
          }) >= 0;
      }
      return loading;
    },
    upImage() {
      let loading = false;
      if (this.uploadList) {
        loading =
          this.uploadList.findIndex((item) => {
            let index = item.name.lastIndexOf(".");
            let extension = item.name.substring(index + 1);
            return imgExt.indexOf(extension) >= 0;
          }) >= 0;
      }
      return loading;
    },
    upMedia() {
      let loading = false;
      if (this.uploadList) {
        loading =
          this.uploadList.findIndex((item) => {
            let index = item.name.lastIndexOf(".");
            let extension = item.name.substring(index + 1);
            return midea.indexOf(extension) >= 0;
          }) >= 0;
      }
      return loading;
    },
    attachmentList() {
      if (!this.card.attachmentList) return [];
      if (this.attType == "image") {
        return this.card.attachmentList.filter(
          (item) =>
            imgExt.indexOf(item.extension) >= 0 &&
            !(item.percent && item.percent != 100),
        );
      } else if (this.attType == "midea") {
        return this.card.attachmentList.filter(
          (item) =>
            midea.indexOf(item.extension) >= 0 &&
            !(item.percent && item.percent != 100),
        );
      } else {
        return this.card.attachmentList.filter(
          (item) =>
            imgExt.indexOf(item.extension) == -1 &&
            midea.indexOf(item.extension) == -1 &&
            !(item.percent && item.percent != 100),
        );
      }
    },
  },
  data() {
    return {
      imgExt,
      moreVisible: false,
      attVisible: false,
      cardVisible: false,
      copyVisible: false,
      fromCard: false,
      membersSelectDialogVisible: false,
      attType: "",
      uploadList: [],
    };
  },
  methods: {
    delCard() {
      this.$confirm("确定要删除卡片吗?", "提示").then(() => {
        delCard(this.card.id).then(() => {
          this.$message.success("卡片删除成功");
          this.cardVisible = false;
          this.$emit("deleteItem", this.card.id);
        });
      });
    },
    rename(data) {
      console.log(data);
      return new Promise((resolve, reject) => {
        renameAttachment({ ...data, id: this.card.id }).then((res) => {
          resolve(res);
        });
      });
    },
    loadingCommentList() {
      this.$refs.comment.loadMore();
    },
    reviewImage(index) {
      this.$refs.attList.reviewImg(index);
    },
    stop() {},
    showMoveColumn() {
      this.$emit("showMoveColumn", this.card);
    },
    renderHtml(val) {
      if (!val) {
        return val;
      }
      let regHtml = /<[^>]+>/g;
      if (regHtml.test(val)) {
        return val;
      }

      let textR = val;
      let reg =
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      let imgSRC = $(".thread_mess img").attr("src");
      if (reg.exec(imgSRC)) {
      } else {
        textR = textR.replace(reg, function (val) {
          return `<a href='${val}' target='_blank' style='color:#409eff;word-break: break-word;'>${val}</a>`;
        });
      }
      return textR;
    },
    onProgress(file) {
      if (this.uploadList.findIndex((item) => file.uid == item.uid) < 0) {
        this.uploadList.push(file);
      }
    },
    uploadAttachment(attachment) {
      addCardAttachment({
        id: this.card.id,
        attachmentId: attachment.id,
      }).then((res) => {
        this.$refs.fileUpload.clearFiles();
        let index = this.uploadList.findIndex(
          (item) => item.md5 == attachment.md5,
        );
        if (index != -1) {
          this.uploadList.splice(index, 1);
        }
        this.updateDetail();
      });
    },
    updateDetail() {
      bulletinCardDetail({ id: this.card.id }).then((res) => {
        this.$emit("updateCard", res.data.model);
      });
    },
    handleAttachmentDownload(att) {
      downFile(att);
    },
    canPreview(extension) {
      return getFileExtension(extension) ? true : false;
    },
    handleAttachmentDelete(att) {
      this.$confirm(`您确认要删除附件:【${att.originalName}】吗？`, "提示", {
        type: "warning",
      }).then(() => {
        bulletinCardDeleteAttachment({
          id: this.card.id,
          attachmentId: att.id,
        }).then((res) => {
          this.$message.success("删除成功！");
          if (this.attachmentList.length == 1) {
            this.attVisible = false;
          }
          this.updateDetail();
        });
      });
    },
    showAttachment(type) {
      console.log(type);
      this.attType = type;
      if (!this.attachmentList.length) {
        return;
      }

      this.attVisible = true;
    },
    updateCard(model) {
      this.$emit("updateCard", model);
    },
    columnRefresh() {
      this.$emit("columnRefresh");
    },
    showDetail() {
      this.cardVisible = true;
    },
    //归档单个卡片
    handleCardArchive(card) {
      this.$confirm("是否归档任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bulletinCardArchive({ id: this.card.id }).then((res) => {
            this.$message.success("归档成功");
            this.$emit("deleteItem", this.card.id);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消归档",
          });
        });
    },

    handleCopyCard() {
      this.copyVisible = true;
    },
    onMembersSelectDialogClose() {
      this.fromCard = false;
    },

    //提交选择成员
    membersAddConfirm(selectMember, card) {
      //要把已经在列表的人员删除，不然后端会重复保存
      if (!card.cardUserList) {
        card.cardUserList = [];
      }
      let userId = selectMember
        .filter(
          (item) =>
            card.cardUserList.findIndex((u) => u.userId == item.userId) == -1,
        )
        .map((u) => u.userId)
        .toString();
      let deleteUserId = card.cardUserList
        .filter(
          (item) =>
            selectMember.findIndex((u) => u.userId == item.userId) == -1,
        )
        .map((u) => u.userId)
        .toString();

      // this.$set(this.taskDetailForm,"members",members)
      let arr = [];
      if (userId) {
        arr.push(
          bulletinCardUserSaveBatch({
            bulletinCardId: card.id,
            userId: userId,
          }),
        );
      }
      if (deleteUserId) {
        arr.push(
          bulletinCardUserDeleteBatch({
            id: card.id,
            userId: deleteUserId,
          }),
        );
      }

      Promise.all(arr).then((res) => {
        this.updateDetail();
        this.membersSelectDialogVisible = false;
      });
    },
    handleCardUserAdd(card) {
      this.membersSelectDialogVisible = true;
      this.$nextTick(() => {
        this.fromCard = true;
        this.$refs.membersSelectDialog.init(card);
      });
    },
    //删除成员
    deleteCardMember(member) {
      bulletinCardUserDelete({
        id: member.id,
        bulletinColumnId: this.card.bulletinColumnId,
      }).then((res) => {
        this.$message.success("删除成员成功");
        this.updateDetail();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-item {
  width: 270px;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    //background-color: #f4f5f7;
    background-color: #e2f1ff;
  }
}

.fill-img {
  width: 100%;
  //height: 200px;
  display: block;
}

.card-title {
  padding: 0 10px;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: pre-wrap;
  color: #333333;
  line-height: 1.6;
}

.card-remark {
  font-size: 12px;
  padding: 0px 10px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  color: #333333;
  line-height: 1.6;
}

.more {
  transform: rotate(90deg);
}

.top {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 10;
  //background-color: rgba($color: #ffffff, $alpha: 0.35);
  //width: 25px;
  //height: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
}

.line {
  display: flex;
  align-items: center;
  margin: 0 10px 10px;

  .icon {
    color: #666666;
  }

  .line-item {
    margin-right: 15px;
    color: #666666;
  }
}

.createdTime {
  color: #666666;
  font-size: 12px;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 15px;
}

.weui-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.c-label {
  flex: 1;
  color: #333333;
  font-size: 14px;
}

.attachment {
  display: flex;
  align-items: center;
  padding: 10px 10px;

  ::v-deep {
    .el-loading-spinner {
      margin-top: -10px;

      i {
        color: #ffffff;
      }
    }
  }
}

.att-icon {
  color: #409eff;
  font-size: 18px;
}

.att-item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #409eff;
  margin-left: 15px;
  color: #ffffff;
  border-radius: 5px;
}

.coverUpload {
  flex: 1;
  justify-content: flex-end;
  text-align: right;

  ::v-deep {
    .el-button--mini.is-round {
      padding: 7px;
    }

    .el-form-item__content {
      line-height: 1;
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }

    .el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: 78px;
      box-sizing: border-box;
    }

    .upload-container {
      .el-upload {
        width: auto;

        .el-upload-dragger {
          height: auto;
          width: auto;
          border: 0;
        }
      }
    }
  }
}

.card-tag {
  border: 0;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
}

.card-status {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  color: rgb(58, 163, 97);
  font-size: 14px;

  i {
    color: rgb(58, 163, 97);
    margin-right: 4px;
  }
}
</style>
