import request from '@/utils/request'
import qs from "qs"
import {getFormModel} from "@/utils/tools"


// 保存 /sys/excel/category/save (参考 TestSysExcelCategorySave.java)
export function excelCategorySave(data) {
    return request({
        url: '/sys/excel/category/save',
        method: 'post',
        data: qs.stringify(getFormModel(data,"model"))
    })
}
// 查询 /sys/excel/category/list (参考  TestSysExcelCategoryList.java)
export function excelCategoryList(data) {
    return request({
        url:'/sys/excel/category/list',
        method:'post',
        data
    })
}
// 编辑 /sys/excel/category/detail (参考 TestSysExcelCategoryDetail.java )
export function excelCategoryDetail(data) {
    return request({
        url:'/sys/excel/category/detail',
        method:'post',
        data:qs.stringify(data)
    })
}
// 删除 /sys/excel/category/delete (参考 TestSysExcelCategoryDelete.java )
export function excelCategoryDelete(data) {
    return request({
        url:'/sys/excel/category/delete',
        method:'post',
        data:qs.stringify(data)
    })
}


/**
 * 获取字典项列表
 */
export function dictItemList(data) {
    return request({
        url: '/dict/itemList',
        method: 'post',
        data:qs.stringify(data)
    })
}
/**
 * 获取表格库列表
 */
export function excelRepositoryListPage(data) {
    return request({
        url: '/sys/excel/repository/listPage',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 查询 /sys/excel/repository/listPage (参考  TestSysExcelRepositoryListPage.java)

// 编辑 /sys/excel/repository/detail (参考 TestSysExcelRepositoryDetail.java )
export function excelRepositoryDetail(data) {
    return request({
        url: '/sys/excel/repository/detail',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 预览 /sys/excel/repository/view (参考 TestSysExcelRepositoryView.java )
export function excelRepositoryView(data) {
    return request({
        url: '/sys/excel/repository/view',
        method: 'post',
        data:qs.stringify(data)
    })
}

// 删除 /sys/excel/repository/delete (参考 TestSysExcelRepositoryDelete.java )
export function excelRepositoryDelete(data) {
    return request({
        url: '/sys/excel/repository/delete',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 下载 /sys/excel/repository/download (参考 TestSysExcelRepositoryDownload.java )
export function excelRepositoryDownload(data) {
    return request({
        url: '/sys/excel/repository/download',
        method: 'post',
        data:qs.stringify(data)
    })
}

/**
 * 保存
 * @param data
 * @returns {AxiosPromise}
 */
export function excelRepositorySave(data) {
    return request({
        url: '/sys/excel/repository/save',
        method: 'post',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data
    })
}




/**
 * 表格库资源-
 * @param data
 * @returns {AxiosPromise}
 */
export function excelRepositoryResource() {
    return request({
        url: '/sys/excel/repository/resource',
        method: 'post',
    })
}
/**
 * 表格设置-保存单元格属性
 * @param data
 * @returns {AxiosPromise}
 */
export function excelRepositoryUpdateCell(data) {
    return request({
        url: '/sys/excel/repository/updateCell',
        method: 'post',
        data:qs.stringify(data)
    })
}
/**
 * 表格设置-全部保存
 * @param data
 * @returns {AxiosPromise}
 */
export function excelRepositoryUpdate(data) {
    return request({
        url: '/sys/excel/repository/update',
        method: 'post',
        data
    })
}
/**
 * 表格设置-保存表格属性
 * @param data
 * @returns {AxiosPromise}
 */
export function excelRepositoryUpdateTable(data) {
    return request({
        url: '/sys/excel/repository/updateTable',
        method: 'post',
        data
    })
}
/**
 * 表格设置-表格库定义单元格编辑
 *
 * @param
 *   column	列		true
    repositoryId	表格id		true
    row	行		true
 * @returns {AxiosPromise}
 */
export function excelRepositoryEditCell(data) {
    return request({
        url: '/sys/excel/repository/editCell',
        method: 'post',
        data: qs.stringify(data)
    })
}
/**
 * 表格设置-表格库定义编辑
 *
 * @param
    {
    repositoryId	表格id		true
    }
 * @returns {AxiosPromise}
 */
export function excelRepositoryEdit(data) {
    return request({
        url: '/sys/excel/repository/edit',
        method: 'post',
        data: qs.stringify(data)
    })
}





/**
 * 卡片与表格实例关联，就是表格保存后，获取到实例ID
 * @param excelRepositoryId
 * @returns {AxiosPromise}
 */
 export function createByExcelRepository(excelRepositoryId) {
    return request({
        url:  '/excel/createByExcelRepository',
        method: 'POST',
        data:{excelRepositoryId}
    })
}
/**
 * 表格内容保存
 * @param id
 * @ content
 * cellProp
 * @returns {AxiosPromise}
 */
export function excelSaveContent(data) {
    return request({
        url:  '/excel/saveContent',
        method: 'POST',
        data
    })
}
/**
 * 表格内容导出
 * @returns
 */
export function excelExport(data) {
    return request({
        url:  '/excel/export',
        method: 'post',
        data
    })
}