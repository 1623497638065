const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  user: (state) => state.user,
  currentUser: (state) => state.user.currentUser,
  currentCompany: (state) => state.user.currentCompany,
  area: (state) => state.area,
  bulletinTagList: (state) => state.bulletin.bulletinTagList,
  currentCompanyId: (state) => state.user.currentCompanyId,
  sysFields: (state) => state.sysFields.sysFields,
  sysFieldNames: (state) => state.sysFields.sysFieldNames,
  currentCompanyName: (state) => state.user.currentCompanyName,
  companyList: (state) => state.user.companyList,
};
export default getters;
