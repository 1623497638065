<template>
  <div>
    <el-drawer
      title="前端事件"
      :visible.sync="drawer"
      direction="btt"
      size="95%"
      :before-close="handleDrawerClose"
    >
      <div class="front-event-body" v-if="!frontEvents.length">
        <span class="description"
          >前端事件可以在填写或编辑数据时，让表单字段值的变动触发一系列自动操作。</span
        >
        <el-button type="primary" class="addBtn" @click="addEvent"
          ><i class="el-icon-plus"></i>添加前端事件</el-button
        >
      </div>
      <div class="form-event-pane" v-else>
        <div class="pane-header">
          <el-button type="primary" class="addBtn" @click="addEvent"
            ><i class="el-icon-plus"></i>添加前端事件</el-button
          >
        </div>
        <div class="pane-list">
          <Draggable
            v-model="frontEvents"
            handle=".sort-item"
            ghostClass="ghost"
            chosenClass="chosen"
            animation="300"
          >
            <transition-group>
              <div class="sort-item" v-for="item in frontEvents" :key="item.id">
                <div class="item-header">
                  <span
                    style="
                      line-height: 28px;
                      margin-bottom: 10px;
                      font-weight: 700;
                    "
                    >{{ item.name }}</span
                  >
                  <div class="header-right">
                    <el-button type="text" size="mini" @click="editEvent(item)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      @click="configEvent(item)"
                      >调试</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="border-right: transparent"
                      @click="deleteEvent(item)"
                      >删除</el-button
                    >
                    <el-switch
                      v-model="item.enabled"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    >
                    </el-switch>
                  </div>
                </div>
                <div class="item-body">
                  <span style="margin-bottom: 10px">{{
                    item.description
                  }}</span>
                  <span>当「{{ getTitle(item.trigger) }}」值发生变动时；</span>
                  <div v-if="item.request.pluginId != 0">
                    执行「{{ item.request.pluginName }}」插件
                  </div>
                  <div v-else>
                    发送{{ item.request.method }}请求,返回值至
                    <template v-if="item.action.length > 0">
                      <span v-for="row in item.action" :key="row.field"
                        >「{{ getTitle(row.field) }}」</span
                      >
                    </template>
                    。
                  </div>
                </div>
              </div>
            </transition-group>
          </Draggable>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="前端事件"
      :visible.sync="dialogVisible"
      class="front-event-config"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="drawerOpen"
      @closed="drawerClosed"
    >
      <div class="front-event-content">
        <el-form
          :model="frontEvent"
          :rules="rules"
          ref="ruleForm"
          :validate-on-rule-change="false"
        >
          <span class="formTitle">事件名称</span>
          <el-form-item prop="name">
            <el-input
              v-model="frontEvent.name"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <span class="formTitle">事件说明（选填）</span>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="frontEvent.description"
          >
          </el-input>
          <el-tabs v-model="activeName" :before-leave="changeTab">
            <el-tab-pane label="触发动作" name="first">
              <div class="triggerBody">
                <span class="formTitle">触发字段</span>
                <el-form-item prop="trigger">
                  <el-select
                    v-model="frontEvent.trigger"
                    :disabled="entrySource == 'button'"
                    placeholder="请选择触发字段"
                  >
                    <el-option
                      v-for="item in getTriggerComponents()"
                      :key="item.key"
                      :label="item.title"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-tab-pane>
            <el-tab-pane label="执行动作" name="second">
              <div class="executeBody">
                <el-select
                  v-model="frontEvent.request.pluginId"
                  filterable
                  @change="changePlugin"
                  placeholder="请选择请求方式"
                >
                  <el-option label="自定义请求" :value="'0'"></el-option>
                  <el-option-group
                    v-for="group in pluginInstallData"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.pluginId"
                      :label="item.name"
                      :value="item.pluginId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
                <template
                  v-if="frontEvent.request.pluginId != '0' && funsData.length"
                >
                  <span class="formTitle">请选择执行函数</span>
                  <el-select
                    v-model="frontEvent.request.funId"
                    @change="changeFun"
                    placeholder="请选择执行函数"
                  >
                    <el-option
                      v-for="item in funsData"
                      :key="item.id"
                      :label="item.funsName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <template v-if="frontEvent.request.funId">
                    <!-- 入参 -->
                    <div class="plugin-default-container" v-if="body.length">
                      <template v-for="(item, index) in body">
                        <span class="formTitle">
                          <span v-if="item.required" style="color: #f56c6c"
                            >*</span
                          >{{ item.fieldName }}
                        </span>
                        <div class="param-item">
                          <div style="width: 120px">
                            <el-select
                              v-model="item.type"
                              size="small"
                              placeholder="请选择"
                              @change="item.value = ''"
                            >
                              <el-option label="自定义" value="1"></el-option>
                              <el-option label="字段值" value="2"></el-option>
                            </el-select>
                          </div>
                          <div
                            class="value-input-wrapper"
                            v-if="item.type == 1"
                            style="padding: 0 0 0 10px"
                          >
                            <el-input
                              v-model="item.value"
                              placeholder=""
                            ></el-input>
                          </div>
                          <div
                            class="value-input-wrapper"
                            v-if="item.type == 2"
                            style="padding: 0 0 0 10px"
                          >
                            <template v-if="item.componentName == 'object'">
                              <el-select
                                v-model="item.value"
                                placeholder="请选择"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="(
                                    item, index
                                  ) in allowInsertObjComponents"
                                  :key="index"
                                  :label="item.title"
                                  :value="item.key"
                                ></el-option>
                              </el-select>
                            </template>
                            <template
                              v-else-if="item.componentName == 'object_array'"
                            >
                              <el-select
                                v-model="item.value"
                                placeholder="请选择"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="(
                                    item, index
                                  ) in allowInsertArrayComponents"
                                  :key="index"
                                  :label="item.title"
                                  :value="item.key"
                                ></el-option>
                              </el-select>
                            </template>
                            <template v-else>
                              <el-select
                                v-model="item.value"
                                placeholder="请选择"
                                style="width: 100%"
                              >
                                <el-option
                                  v-for="(
                                    item, index
                                  ) in allowRequestComponents"
                                  :key="index"
                                  :label="item.title"
                                  :value="item.key"
                                ></el-option>
                              </el-select>
                            </template>
                          </div>
                        </div>
                        <div
                          class="error-tip"
                          v-if="item.required && !item.value && isSaveCheck"
                        >
                          此项为必填项
                        </div>
                      </template>
                    </div>
                    <!-- 返回值 -->
                    <span class="formTitle">设置以下内容和字段的存储关系</span>
                    <div style="margin-bottom: 10px">
                      <el-dropdown
                        trigger="click"
                        :hide-on-click="true"
                        @command="addResponseParam"
                      >
                        <span class="el-dropdown-link">
                          添加<i class="el-icon-arrow-down el-icon-plus"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-for="item in responseParamsData"
                            :key="item.key"
                            :command="item"
                            :disabled="
                              isChoose(item, 'responseParam') ||
                              (item.subParams && item.subParams.length > 0)
                            "
                          >
                            <span
                              v-if="item.subParams && item.subParams.length"
                              style="font-size: 12px"
                              >{{ item.name }}</span
                            >
                            <span v-else>{{ item.name }}</span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    <div
                      class="param-list"
                      v-for="(item, index) in responseParam"
                      :key="index"
                    >
                      <div class="param-item">
                        <el-input
                          v-model="item.fieldName"
                          disabled
                          style="width: 200px"
                        ></el-input>
                        <div class="value-input-wrapper">
                          <div style="line-height: 36px; padding: 0 10px">
                            的值存储到
                          </div>
                          <el-select
                            style="width: 220px"
                            v-model="item.value"
                            placeholder="请选择"
                            @change="changeResponseParam($event, item, index)"
                          >
                            <el-option
                              v-for="(item, index) in allowReturnComponents"
                              :key="index"
                              :label="item.title"
                              :value="item.key"
                              :disabled="
                                responseParam.findIndex(
                                  (col) => col.value == item.name,
                                ) >= 0
                              "
                            ></el-option>
                          </el-select>
                        </div>
                        <el-button
                          type="text"
                          @click="deleteResponseParam(item, index)"
                        >
                          <i class="el-icon-delete"></i
                        ></el-button>
                      </div>
                    </div>
                    <template v-if="pluginReturnValueHasJsonform">
                      <span class="formTitle"
                        >返回的子字段值如何填充到当前表</span
                      >
                      <el-select
                        v-model="frontEvent.subformFillRule"
                        placeholder="请选择"
                        style="width: 50%"
                      >
                        <el-option
                          label="直接填充到对应子字段"
                          value="merge"
                        ></el-option>
                        <el-option
                          label="先清空子表单再填充"
                          value="replace"
                        ></el-option>
                      </el-select>
                    </template>
                  </template>
                </template>

                <template v-if="frontEvent.request.pluginId == '0'">
                  <span class="formTitle">请求类型</span>
                  <el-radio-group v-model="frontEvent.request.method">
                    <el-radio label="get">GET</el-radio>
                    <el-radio label="post">POST</el-radio>
                  </el-radio-group>
                  <div class="item-title" style="margin-top: 10px">
                    <span style="line-height: 40px">URL</span>
                    <el-popover
                      popper-class="field-select-pop-class"
                      placement="bottom"
                      width="218"
                      trigger="click"
                    >
                      <div
                        v-for="(item, index) in allowRequestComponents"
                        :key="index"
                        class="fieldList"
                        @click="addURLField(item)"
                      >
                        <span>{{ item.title }}</span>
                      </div>
                      <el-button type="text" slot="reference"
                        >插入字段</el-button
                      >
                    </el-popover>
                  </div>
                  <el-form-item
                    prop="request.url"
                    style="height: 70px"
                    :show-message="!frontEvent.request.url"
                  >
                    <conditionEdit
                      ref="urlEdit"
                      v-if="dialogVisible"
                      :key="uuid()"
                      :id="uuid()"
                      :rely="frontEvent.requestRely"
                      :isWrap="true"
                      :form-component="formComponent"
                      :code.sync="frontEvent.request.url"
                      textareaHeight="70px"
                    >
                    </conditionEdit>
                  </el-form-item>
                  <span class="formTitle">Header/Body</span>
                  <div class="flex">
                    <el-button
                      style="width: 100%"
                      size="mini"
                      @click="openHeaderBody"
                      >设置</el-button
                    >
                  </div>
                  <span class="formTitle">返回值格式</span>
                  <el-radio-group v-model="frontEvent.request.format">
                    <el-radio label="json">JSON</el-radio>
                    <el-radio label="xml">XML</el-radio>
                  </el-radio-group>
                  <span class="formTitle">返回值设置</span>
                  <div class="flex">
                    <el-button
                      style="width: 100%"
                      size="mini"
                      @click="openReturnValueSet"
                      >设置</el-button
                    >
                  </div>
                </template>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveFrontEvent">保 存</el-button>
        <el-button type="primary" @click="saveDebugging">保存并调试</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Header/Body设置"
      :visible.sync="requestHeaderDialogVisible"
      class="front-action-config"
      append-to-body
      @open="openRequestSet"
      @closed="handleRequestSetClose"
    >
      <div class="front-event-content">
        <span class="formTitle">Header</span>
        <div class="param-list" v-for="(item, index) in header" :key="index">
          <div class="param-item">
            <el-input
              v-model="item.name"
              placeholder="name"
              style="width: 120px; padding: 0 10px 0 0"
            ></el-input>
            <div class="value-input-wrapper">
              <div style="width: 350px">
                <conditionEdit
                  ref="headerEdit"
                  v-if="requestHeaderDialogVisible"
                  :key="uuid()"
                  :id="uuid()"
                  :rely="frontEvent.requestRely"
                  :isWrap="false"
                  :form-component="formComponent"
                  :code.sync="item.value"
                  textareaHeight="35px"
                >
                </conditionEdit>
              </div>
              <el-popover
                popper-class="field-select-pop-class"
                placement="bottom"
                width="218"
                trigger="click"
                slot="append"
              >
                <div
                  v-for="row in allowRequestComponents"
                  class="fieldList"
                  @click="addHeaderField(row, index)"
                >
                  <span>{{ row.title }}</span>
                </div>
                <el-button type="text" slot="reference">插入字段</el-button>
              </el-popover>
            </div>
            <el-button type="text" @click="deleteHeader(item, index)">
              <i class="el-icon-delete"></i
            ></el-button>
          </div>
        </div>
        <el-button
          type="text"
          icon="el-icon-plus delete"
          @click="addHeader"
          style="width: 50px"
          >Herder</el-button
        >
        <div class="error-tip" v-if="!isHeaderRight">请设置完整的Header</div>
        <div class="requestBody" v-if="frontEvent.request.method == 'post'">
          <div class="grid"></div>
          <span class="formTitle">Body</span>
          <el-radio-group
            v-model="frontEvent.request.requestType"
            style="display: block"
          >
            <el-radio label="json">JSON</el-radio>
            <el-radio label="x-www-form-urlencoded"
              >x-www-form-urlencoded</el-radio
            >
          </el-radio-group>
          <div class="param-list" v-for="(item, index) in body">
            <div class="param-item">
              <el-input
                v-model="item.name"
                placeholder="name"
                style="width: 120px; padding: 0 10px 0 0"
              ></el-input>
              <div class="value-input-wrapper">
                <div style="width: 350px">
                  <conditionEdit
                    ref="bodyEdit"
                    :isWrap="false"
                    :key="uuid()"
                    :id="uuid()"
                    :rely="frontEvent.requestRely"
                    v-if="requestHeaderDialogVisible"
                    :form-component="formComponent"
                    :code.sync="item.value"
                    textareaHeight="35px"
                  >
                  </conditionEdit>
                </div>
                <el-popover
                  popper-class="field-select-pop-class"
                  placement="bottom"
                  width="218"
                  trigger="click"
                  slot="append"
                >
                  <div
                    v-for="row in allowRequestComponents"
                    class="fieldList"
                    @click="addBodyField(row, index)"
                  >
                    <span>{{ row.title }}</span>
                  </div>
                  <el-button type="text" slot="reference">插入字段</el-button>
                </el-popover>
              </div>
              <el-button type="text" @click="deleteBody(item, index)">
                <i class="el-icon-delete"></i
              ></el-button>
            </div>
          </div>
          <el-button
            type="text"
            icon="el-icon-plus delete"
            @click="addBody"
            style="width: 50px"
            >Body</el-button
          >
          <div class="error-tip" v-if="!isBodyRight">请设置完整的Body</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="requestHeaderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmHeaderBody">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="返回值设置"
      :visible.sync="returnValueDialogVisible"
      class="front-action-config"
      append-to-body
      @closed="handleResponseSetClose"
    >
      <div class="front-event-content">
        <span class="formTitle">解析返回值并将其赋值给表单字段</span>
        <div class="action-list" v-for="(actionItem, index) in action">
          <div class="action-item" v-if="actionItem">
            <div class="item-title">
              <span
                style="line-height: 40px"
                :class="{ 'delete-field': !actionItem.title }"
                >{{
                  actionItem.title
                    ? "「" + actionItem.title + "」="
                    : actionItem.name
                }}
              </span>
              <div class="title-right">
                <el-popover
                  popper-class="field-select-pop-class"
                  placement="bottom"
                  width="218"
                  trigger="click"
                >
                  <div
                    v-for="row in allowRequestComponents"
                    class="fieldList"
                    @click="addReturnValueField(row, index)"
                  >
                    <span>{{ row.title }}</span>
                  </div>
                  <el-button type="text" slot="reference">插入字段</el-button>
                </el-popover>
                <div class="divider-vertical"></div>
                <el-button
                  type="text"
                  @click="deleteReturnValue(actionItem, index)"
                  ><i class="el-icon-delete"></i
                ></el-button>
              </div>
            </div>
            <!--            <el-input-->
            <!--                type="textarea"-->
            <!--                :rows="3"-->
            <!--                placeholder="请输入内容"-->
            <!--                v-model="actionItem.value">-->
            <!--            </el-input>-->
            <conditionEdit
              ref="returnEdit"
              :isWrap="true"
              :key="uuid()"
              :id="uuid()"
              :rely="frontEvent.actionRely"
              v-if="returnValueDialogVisible"
              :form-component="formComponent"
              :code.sync="actionItem.value"
              textareaHeight="70px"
            >
            </conditionEdit>
            <div class="grid" style="margin-top: 20px"></div>
          </div>
        </div>
        <el-dropdown
          placement="bottom-start"
          trigger="click"
          @command="addFormReturnValue"
        >
          <span class="el-dropdown-link">
            <i class="el-icon-plus el-icon--left"></i>表单字段及对应返回值
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in allowReturnComponents"
              :key="item.name"
              :command="item"
              :disabled="isChoose(item, 'action')"
              >{{ item.title }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <template v-if="returnValueHasJsonform">
          <span class="formTitle">返回的子字段值如何填充到当前表</span>
          <el-select
            v-model="frontEvent.subformFillRule"
            placeholder="请选择"
            style="width: 50%"
          >
            <el-option label="直接填充到对应子字段" value="merge"></el-option>
            <el-option label="先清空子表单再填充" value="replace"></el-option>
          </el-select>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="returnValueDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmReturnValue">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title="前端事件调试"
      :visible.sync="debuggingDrawer"
      v-if="debuggingDrawer"
      direction="btt"
      size="95%"
      @close="handleDebuggingDrawerClose"
    >
      <div class="debug-wrapper">
        <div class="debug-form">
          <span>请填写字段并发送请求</span>
          <div class="debug-body">
            <div class="widgets-wrapper" v-if="debuggingDrawer">
              <form-edit
                :formComponent="relyComponent"
                :areaTree="areaTree"
                ref="form"
                :is-include="false"
              ></form-edit>
            </div>
            <div class="debug-footer">
              <el-button type="primary" @click="getFrontEventResult"
                >发送请求</el-button
              >
            </div>
          </div>
        </div>
        <div class="debug-info">
          <span>调试信息</span>
          <div class="debug-body">
            <div
              class="content-block"
              v-if="
                !(
                  debuggingEvent.request.pluginId &&
                  debuggingEvent.request.pluginId != '0'
                )
              "
            >
              <div class="sub-title">
                请求内容<span class="sub-title-tip"
                  >（触发前端事件后自动刷新）</span
                >
              </div>
              <div class="request-content">
                <div
                  v-if="response"
                  style="display: flex; flex-direction: column"
                >
                  <span class="label">请求类型</span>
                  <span class="value">{{ response.method }}</span>
                  <span class="label">URL</span>
                  <span class="value">{{ response.url }}</span>
                  <span class="label">Header</span>
                  <div
                    class="line"
                    v-for="(val, key) in response.header"
                    :key="key"
                  >
                    {{ `${key}：${val}` }}
                  </div>
                  <div
                    class="line"
                    v-for="(val, key) in response.body"
                    :key="key"
                  >
                    {{ `${key}：${val}` }}
                  </div>
                  <span
                    class="line"
                    v-if="debuggingEvent.request.body.length"
                    >{{
                      `Content-Type：application/${debuggingEvent.request.requestType}`
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="content-block">
              <div class="sub-title">
                返回内容<span class="sub-title-tip"
                  >（触发前端事件后自动刷新）</span
                >
              </div>
              <div class="request-content">
                <pre id="preId"></pre>
                <span style="color: red" v-if="isRight"
                  >配置有误，请检查配置。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import { uuid, isEmptyByKey } from "@zgg-core-utils/utils";
import FormEdit from "./FormEdit";
import { getFrontEventResult } from "../api";
import conditionEdit from "./conditionEdit.vue";
import { fillField, formatRelyValue } from "@/views/lowCode/form/utils";
import {
  getPluginInstallList,
  getPluginDetail,
} from "@/views/lowCode/open-platform/api";
import { isEmpty } from "lodash";
const allowTriggerField = [
  "text_area",
  "input",
  "input_number",
  "date_picker",
  "radio_group",
  "checkbox_group",
  "select",
  "select_checkbox",
  "position_input",
  "attachment_uploader",
  "image_uploader",
  "sign_input",
  "user_select",
  "user_list_select",
  "department_select",
  "department_list_select",
  "user_list_select",
];
const allowInsertRequest = [
  "text_area",
  "input",
  "mobile_input",
  "input_number",
  "radio_group",
  "checkbox_group",
  "select",
  "select_checkbox",
  "address_input",
  "user_select",
  "user_list_select",
  "department_select",
  "attachment_uploader",
  "image_uploader",
  "sign_input",
  "date_picker",
];
const allowInsertReturn = [
  "text_area",
  "input",
  "input_number",
  "select",
  "radio_group",
  "checkbox_group",
  "select_checkbox",
  "date_picker",
];
export default {
  props: {
    // 设置入口来源
    entrySource: {
      type: String,
      default: "",
    },
    component: Object, // 组件
    formComponent: {
      default() {
        return {};
      },
    },
    areaTree: Array,
    drawer: {
      default() {
        return false;
      },
    },
    formEvents: {
      default() {
        return [];
      },
    },
  },
  provide() {
    return {
      isShow: () => {
        return !this.debuggingEvent.trigger.includes(".");
      },
    };
  },
  components: { Draggable, FormEdit, conditionEdit },
  data() {
    return {
      debuggingEvent: {},
      debuggingDrawer: false,
      dialogVisible: false,
      activeName: "first",
      requestHeaderDialogVisible: false,
      returnValueDialogVisible: false,
      header: [],
      body: [],
      action: [],
      isHeaderRight: true,
      isBodyRight: true,
      relyComponent: {},
      frontEvents: [],
      frontEvent: {},
      response: "",
      isRight: false,
      allowRequestComponents: [],
      allowInsertObjComponents: [], // 对应admin端插件设置的对象(关联数据)
      allowInsertArrayComponents: [], // 对应admin端插件设置的对象数组(关联多条、子表单)
      originComponents: [],
      rules: {
        name: [{ required: true, message: "请设置事件名称", trigger: "blur" }],
        "request.url": [
          { required: true, message: "请设置URL", trigger: "blur" },
        ],
        trigger: [
          { required: true, message: "请设置触发字段", trigger: "change" },
        ],
      },
      // 插件
      pluginInstallData: [
        {
          label: "已安装插件",
          options: [],
        },
      ], // 插件列表
      responseParam: [], // 插件返回参数
      responseParamsData: [], // 函数配置的返回参数列表
      funsData: [], // 选择插件包含的函数
      isSaveCheck: false, // 是否保存校验
    };
  },
  created() {
    this.frontEvents = this._.cloneDeep(this.formEvents);
    this.relyComponent = this._.cloneDeep(this.formComponent);
    this.initField(this.formComponent.components);
    this.initFrontEvent();
  },
  computed: {
    returnValueHasJsonform() {
      return (
        this.action.some((row) => {
          return row.key && row.key.includes(".");
        }) && !this.frontEvent.trigger.includes(".")
      );
    },
    pluginReturnValueHasJsonform() {
      return (
        this.responseParam.some((row) => {
          return row.value && row.value.includes(".");
        }) && !this.frontEvent.trigger.includes(".")
      );
    },
    // 插件允许返回的组件列表
    allowReturnComponents() {
      if (this.frontEvent && this.frontEvent.trigger.includes(".")) {
        let arr = this.frontEvent.trigger.split(".");
        return this.originComponents.filter((row) => row.key.includes(arr[0]));
      } else {
        return this.originComponents.filter((item) =>
          allowInsertReturn.includes(item.componentName),
        );
      }
    },
  },
  methods: {
    changeTab(name) {
      if (name == "second" && !this.frontEvent.trigger) {
        this.$message.warning("请选择触发字段");
        return false;
      }
    },
    getTriggerComponents() {
      let list = [];
      if (this.entrySource == "button") {
        // 来源按钮组件
        list = [
          {
            key: this.component.key,
            title: this.component.title,
          },
        ];
        // 如果是按钮组件设置前端事件、触发字段默认选按钮组件且不可更改
        this.frontEvent.trigger = this.component.key;
      } else {
        // 来源表单属性
        this.formComponent.components &&
          this.formComponent.components.forEach((component) => {
            if (allowTriggerField.includes(component.componentName)) {
              list.push({ key: component.key, title: component.title });
            } else if (component.componentName == "json_form") {
              let children = component.components;
              children &&
                children.forEach((child) => {
                  if (allowTriggerField.includes(child.componentName)) {
                    list.push({
                      key: `${component.key}.${child.key}`,
                      title: `${component.title}.${child.title}`,
                    });
                  }
                });
            }
          });
      }
      return list;
    },
    // 选择插件
    changePlugin(e) {
      this.changePluginInitData();
      if (e != "0") {
        // 插件
        getPluginDetail({
          pluginId: e,
        }).then((res) => {
          // todo 目前仅考虑后端函数（产品要求）
          if (!isEmptyByKey(res, "data.plugin.afterFuns")) {
            this.funsData = res.data.plugin.afterFuns;
            this.frontEvent.request.pluginName = res.data.plugin.name;
          }
        });
      } else {
        // 自定义初始化数据
        this.frontEvent.request.pluginId = "0";
        this.frontEvent.request.pluginName = "";
      }
    },
    // 选择插件或自定义请求时、初始化事件数据
    changePluginInitData() {
      this.frontEvent.action = [];
      this.frontEvent.requestRely = [];
      this.frontEvent.actionRely = [];
      this.frontEvent.request.funId = "";
      this.frontEvent.request.body = [];
      this.frontEvent.request.responseParam = [];
      this.frontEvent.request.method = "get";
      this.frontEvent.request.requestType = "";
      this.frontEvent.request.url = "";
      this.frontEvent.request.header = [];
      this.frontEvent.request.format = "json";
      this.frontEvent.subformFillRule = "merge";
      this.body = [];
      this.funsData = [];
      this.responseParam = [];
    },
    // 选择函数
    changeFun(e) {
      if (this.funsData.length) {
        let curFun = this.funsData.find((item) => item.id == e);
        this.initPluginDefault(curFun);
      }
    },
    // 选择函数时初始化请求参数集合及返回参数集合
    initPluginDefault(data) {
      let body = [];
      let responseParam = [];
      if (
        this.frontEvent.request.body &&
        this.frontEvent.request.body.length &&
        this.frontEvent.request.pluginId != "0"
      ) {
        body = this._.cloneDeep(this.frontEvent.request.body);
        body.forEach((item) => {
          if (item.type == "2" && item.value) {
            item.value = item.value.replaceAll("${", "").replaceAll("}", "");
          }
        });
        this.body = body;
      } else {
        if (data.requestParams && data.requestParams.length) {
          data.requestParams.forEach((item) => {
            body.push({
              type: "1",
              fieldName: item.title,
              componentName: item.componentName,
              name: item.id,
              value: item.defaultValue,
              required: item.required,
            });
          });
        }
        this.body = body;
      }
      if (
        this.frontEvent.request.responseParam &&
        this.frontEvent.request.responseParam.length
      ) {
        responseParam = this._.cloneDeep(this.frontEvent.request.responseParam);
        responseParam.forEach((item) => {
          if (item.value) {
            item.value = item.value.replaceAll("${", "").replaceAll("}", "");
          }
        });
        this.responseParam = responseParam;
      } else {
        this.responseParam = [];
      }

      // 获取插件返回值列表
      if (data.responseParams && data.responseParams.length) {
        let responseParamsData = this._.cloneDeep(data.responseParams);
        let arr = [];
        if (responseParamsData && responseParamsData.length) {
          responseParamsData.forEach((item) => {
            if (
              item.dataType != "any" &&
              item.subParams &&
              item.subParams.length
            ) {
              arr.push(item);
              item.subParams.forEach((itemSub) => {
                itemSub.name = item.name + "." + itemSub.name;
                itemSub.id = item.id + "." + itemSub.id;
                arr.push(itemSub);
              });
            } else {
              arr.push(item);
            }
          });
        }
        this.responseParamsData = arr;
      }
    },
    // 添加插件返回值
    addResponseParam(val) {
      let param = this._.cloneDeep(val);
      this.responseParam.push({
        fieldName: param.name,
        fieldKey: param.key,
        field: param.id,
        value: "", // 对应组件key
      });
    },
    // 删除插件返回值
    deleteResponseParam(item, index) {
      item.value = "";
      this.responseParam.splice(index, 1);
    },
    // 选择插件返回值
    changeResponseParam(val, item, index) {
      let node = this._.cloneDeep(
        this.allowReturnComponents.find((item) => item.key == val),
      );
      node.fieldName = item.fieldName;
      node.fieldKey = item.fieldKey;
      node.field = item.field;
      node.value = item.value;
      this.responseParam.splice(index, 1, node);
    },
    // 校验回填插件的参数
    confirmFillPluginBodyResponse() {
      if (!this.triggerIsSetToReturn(this.responseParam, "key")) return false;
      let body = this._.cloneDeep(this.body);
      let responseParam = this._.cloneDeep(this.responseParam);
      let action = this._.cloneDeep(this.responseParam);
      if (body.length) {
        body.forEach((item) => {
          if (item.type == "2" && item.value) {
            item.value = "${" + item.value + "}";
          }
        });
        // 检查请求参数设置必填的是否填写
        if (body.some((item) => item.required && item.value == "")) {
          return false;
        }
        this.frontEvent.request.body = body;
      }
      if (responseParam.length) {
        responseParam.forEach((item) => {
          if (item.value) {
            item.value = "${" + item.value + "}";
          }
        });
        // 判断当前前端事件的返回值是否已被其他事件所绑定
        this.action = [];
        if (this.isAssigned(action)) {
          this.responseParam.forEach((item) => {
            this.action.push({
              field: item.value,
              value: "",
            });
          });
          this.frontEvent.action = this.action;
        } else {
          return false;
        }
      }
      this.frontEvent.request.responseParam = responseParam;

      // 处理requestRely参数
      let componentMap = {};
      this.formComponent.components.map((item) => {
        componentMap[item.name] = item.title;
      });
      const rex = /(\$\{)[\s\S]{0,10}(\})/g;
      let requestArr = [];
      if (this.frontEvent.request) {
        if (this.frontEvent.request.body) {
          requestArr = [...requestArr, ...this.frontEvent.request.body];
        }
      }
      console.log(requestArr);
      if (requestArr.length > 0) {
        this.frontEvent.requestRely = this.getFieldArr(
          requestArr,
          componentMap,
          rex,
        );
      }
      return true;
    },
    //初始化允许添加的字段
    initField(components) {
      this.allowRequestComponents = components.filter((item) => {
        return allowInsertRequest.includes(item.componentName);
      });
      // 请求参数增加数据对象和对象数组
      let allowInsertObjRequest = ["reference_data"];
      let allowInsertArrayRequest = ["json_form", "reference_data_list"];
      this.allowInsertObjComponents = components.filter((item) => {
        return allowInsertObjRequest.includes(item.componentName);
      });
      this.allowInsertArrayComponents = components.filter((item) => {
        return allowInsertArrayRequest.includes(item.componentName);
      });

      let newComponents = this._.cloneDeep(components);
      let list = [];
      newComponents.forEach((item) => {
        if (item.componentName === "json_form") {
          item.components.forEach((itemChild) => {
            itemChild.title = item.title + "." + itemChild.title;
            itemChild.key = item.key + "." + itemChild.key;
            list.push(itemChild);
          });
        } else {
          list.push(item);
        }
      });
      this.originComponents = list;
    },
    isJsonForm(key, list) {
      let item = list.find((row) => row.key == key);
      return item && item.componentName == "json_form";
    },
    getFrontEventResult() {
      let form = this.$refs.form.form;
      let request = {};
      let action = {};
      if (
        this.debuggingEvent.actionRely &&
        this.debuggingEvent.actionRely.length > 0
      ) {
        action = formatRelyValue(
          this.debuggingEvent.actionRely,
          form,
          this.relyComponent.components,
        );
      }
      if (
        this.debuggingEvent.requestRely &&
        this.debuggingEvent.requestRely.length > 0
      ) {
        request = formatRelyValue(
          this.debuggingEvent.requestRely,
          form,
          this.relyComponent.components,
        );
      }
      getFrontEventResult({
        formEvent: this.debuggingEvent,
        rely: request,
        actionRely: action,
      }).then((res) => {
        if (res.data.apiResult) {
          if (res.data.apiResult.request) {
            this.response = res.data.apiResult.request;
          }
          if (res.data.apiResult.responseContent) {
            this.isRight = false;
            document.getElementById("preId").innerHTML = this.formatResponse(
              res.data.apiResult.responseContent,
            );
          } else {
            this.isRight = true;
          }
        } else {
          this.isRight = true;
        }
        if (res.data.value) {
          for (let key in res.data.value) {
            let value = res.data.value[key];
            //处理子表单字段
            if (this.isJsonForm(key, this.formComponent.components)) {
              if (Array.isArray(value)) {
                if (this.debuggingEvent.trigger.includes(".")) {
                  if (value[0] && typeof value[0] === "object") {
                    for (let subKey in value[0]) {
                      this.$set(
                        this.$refs.form.form[key][0],
                        subKey,
                        fillField(
                          `${key}.${subKey}`,
                          value[0][subKey],
                          this.allowReturnComponents,
                        ),
                      );
                    }
                  }
                } else {
                  if (
                    this.debuggingEvent.subformFillRule == "replace" ||
                    !this.$refs.form.form[key]
                  ) {
                    this.$set(this.$refs.form.form, key, []);
                  }
                  value.forEach((val) => {
                    this.$refs.form.form[key].push(val);
                  });
                }
              }
            } else {
              this.$set(
                this.$refs.form.form,
                key,
                fillField(key, value, this.allowReturnComponents),
              );
            }
          }
        }
      });
    },
    //调整第三方接口返回格式
    formatResponse(json) {
      if (typeof json != "string") {
        json = JSON.stringify(json, null, 4);
      }
      json = json.replace(/&/g, "&").replace(/</g, "<").replace(/>/g, ">");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          let cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        },
      );
    },
    openDebugging() {
      let returnValue = [];
      if (this.debuggingEvent.action.length) {
        this.debuggingEvent.action.map((item) => {
          returnValue.push(item.field);
        });
      }
      //去重，得到不重复组件依赖
      let rely = new Set([
        ...this.debuggingEvent.actionRely,
        ...this.debuggingEvent.requestRely,
        ...returnValue,
      ]);

      rely = Array.from(rely);
      let arr = [];
      rely.forEach((item) => {
        let name;
        if (item.includes(".")) {
          name = item.match(/^[A-Za-z0-9]+/g)[0];
        } else {
          name = item;
        }
        if (!arr.includes(name)) arr.push(name);
      });
      let components = arr.map((item) => {
        let obj = this.formComponent.components.find((row) => {
          return row.name == item;
        });
        if (obj) {
          let component = this._.cloneDeep(obj);
          return component;
        }
      });
      this.relyComponent.components = components;
    },
    uuid,
    insertField(ref, row, index) {
      if (this.$refs[ref]) {
        if (Array.isArray(this.$refs[ref])) {
          this.$refs[ref][index].insert(row);
          this.$refs[ref][index].onBlur();
        }
      }
    },
    addReturnValueField(row, index) {
      this.insertField("returnEdit", row, index);
    },
    addHeaderField(row, index) {
      this.insertField("headerEdit", row, index);
    },
    addBodyField(row, index) {
      this.insertField("bodyEdit", row, index);
    },
    addURLField(item) {
      if (this.$refs["urlEdit"]) {
        this.$refs["urlEdit"].insert(item);
        this.$refs["urlEdit"].onBlur();
      }
    },
    // 编辑前端事件
    drawerOpen() {},
    drawerClosed() {
      //重置事件配置
      this.initFrontEvent();
      this.$nextTick(() => {
        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.clearValidate();
        }
      });
    },
    handleDebuggingDrawerClose() {
      document.getElementById("preId").innerHTML = "";
      this.response = "";
      this.isRight = false;
      this.debuggingEvent = {};
    },
    configEvent(item) {
      this.debuggingEvent = item;
      this.openDebugging();
      this.debuggingDrawer = true;
      this.$nextTick(() => {
        // 触发字段为子表单字段时
        if (item.trigger.includes(".")) {
          let arr = item.trigger.split(".");
          this.$set(this.$refs.form.form, arr[0], [{}]);
        }
      });
    },
    // 编辑
    editEvent(item) {
      this.initField(this.formComponent.components);
      this.frontEvent = this._.cloneDeep(item);
      this.dialogVisible = true;
      // 如果是插件还需要初始化插件
      if (this.frontEvent.request.pluginId != 0) {
        getPluginDetail({
          pluginId: this.frontEvent.request.pluginId,
        }).then((res) => {
          if (!isEmptyByKey(res, "data.plugin.afterFuns")) {
            this.funsData = res.data.plugin.afterFuns;
            if (this.funsData.length) {
              let curFun = this.funsData.find(
                (item) => item.id == this.frontEvent.request.funId,
              );
              this.initPluginDefault(curFun);
            }
          }
        });
      }
    },
    deleteEvent(item) {
      this.$confirm(
        "删除前端事件后，字段变动将不再触发后续操作, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        },
      )
        .then(() => {
          this.frontEvents = this.frontEvents.filter((row) => {
            return item.id != row.id;
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 新增初始化
    initFrontEvent() {
      this.frontEvent = {
        name: "",
        description: "",
        trigger: "",
        enabled: false,
        subformFillRule: "merge",
        action: [],
        actionRely: [],
        request: {
          method: "get",
          requestType: "",
          url: "",
          format: "json",
          header: [],
          body: [],
          pluginId: "0", // 默认自定义
          pluginName: "", // 插件名称
          funId: "",
          responseParam: [], // 返回参数(插件)
        },
        requestRely: [],
        requestType: 1,
      };
      this.activeName = "first";
      // 初始化插件
      let params = {
        pageNumber: 1,
        pageSize: 100,
      };
      getPluginInstallList(params).then((res) => {
        if (!isEmptyByKey(res, "data.page.list")) {
          this.pluginInstallData[0].options = res.data.page.list;
        }
      });
    },
    getTitle(val) {
      if (val) {
        let item = this.getTriggerComponents().find((row) => {
          return row.key == val;
        });
        if (item) {
          return item.title;
        }
      }
    },
    saveDebugging() {
      if (this.saveFrontEvent()) {
        this.configEvent(this.debuggingEvent);
      }
    },
    getFieldArr(rely, componentMap, rex) {
      let arr = [];
      rely.forEach((item) => {
        let match;
        while ((match = rex.exec(item.value))) {
          let fieldName = match[0].replaceAll("${", "").replaceAll("}", "");
          if (!arr.includes(fieldName) && componentMap[fieldName]) {
            arr.push(fieldName);
          }
        }
      });
      return arr;
    },
    //获取插入字段的key
    getRely() {
      let componentMap = {};
      this.formComponent.components.map((item) => {
        componentMap[item.name] = item.title;
      });
      const rex = /(\$\{)[\s\S]{0,10}(\})/g;
      if (this.frontEvent.action && this.frontEvent.action.length > 0) {
        this.frontEvent.actionRely = this.getFieldArr(
          this.frontEvent.action,
          componentMap,
          rex,
        );
      }
      let requestArr = [];
      if (this.frontEvent.request) {
        if (this.frontEvent.request.header) {
          requestArr = [...requestArr, ...this.frontEvent.request.header];
        }
        if (this.frontEvent.request.body) {
          requestArr = [...requestArr, ...this.frontEvent.request.body];
        }
        if (this.frontEvent.request.url) {
          requestArr = [...requestArr, { value: this.frontEvent.request.url }];
        }
      }
      if (requestArr.length > 0) {
        this.frontEvent.requestRely = this.getFieldArr(
          requestArr,
          componentMap,
          rex,
        );
      }
    },
    triggerIsSetToReturn(fields, value) {
      if (fields) {
        let actionFields = fields.map((row) => {
          return row[value];
        });
        if (actionFields && actionFields.includes(this.frontEvent.trigger)) {
          this.$message.warning("触发字段不能被设置为返回值或存储值");
          return false;
        }
      }
      return true;
    },
    saveFrontEvent() {
      let dialogVisible = true;
      if (this.frontEvent.request.pluginId == "0") {
        // 自定义请求
        this.getRely();
        if (!this.triggerIsSetToReturn(this.frontEvent.action, "field")) return;
      } else {
        this.isSaveCheck = true;
        dialogVisible = this.confirmFillPluginBodyResponse();
      }
      if (!dialogVisible) {
        return;
      }
      let isValid;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (!this.frontEvent.id) {
            this.frontEvent.id = uuid();
            this.frontEvents.push(this.frontEvent);
          } else {
            let index = this.frontEvents.findIndex((item) => {
              return item.id == this.frontEvent.id;
            });
            this.frontEvents[index] = this.frontEvent;
          }
          this.debuggingEvent = this.frontEvent;
          this.initFrontEvent();
          this.dialogVisible = false;
          isValid = true;
        } else {
          isValid = false;
          return false;
        }
      });
      return isValid;
    },
    isAssigned(actions) {
      //判断当前前端事件的返回值是否已被其他事件所绑定
      let otherEvents;
      if (this.frontEvent.id) {
        otherEvents = this.frontEvents.filter((item) => {
          return item.id != this.frontEvent.id;
        });
      } else {
        otherEvents = this.frontEvents;
      }
      let usedAction;
      let event;
      //返回值的key数组
      let actionRely = [];
      actions.forEach((row) => {
        actionRely.push(row.name);
        let usedEvent = otherEvents.find((item) => {
          if (item.action && item.action.length > 0) {
            let index = item.action.findIndex((col) => {
              return col.field == row.name;
            });
            if (index > -1) {
              return item;
            }
          }
        });
        if (usedEvent) {
          usedAction = row;
          event = usedEvent;
        }
      });
      if (event) {
        this.$confirm(
          `返回值「${usedAction.title}」已被事件「${event.name}」赋值，无法使用当前事件赋值`,
          "字段不能被重复赋值",
          {
            confirmButtonText: "我知道了",
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            type: "warning",
          },
        ).then(() => {
          return false;
        });
      } else {
        //判断当前前端事件的返回值是否已被公式编辑,数据联动,关联数据填充所绑定
        let item = actions.find((row) => {
          if (row.rely || row.dataLinkage) {
            return row;
          }
        });

        if (item || this.isReferenceField(actionRely)) {
          let curRow = null;
          if (item) {
            curRow = item;
          } else {
            let components = this.getReferenceField(actionRely);
            if (components && components.length) {
              curRow = components[0];
            }
          }
          this.$confirm(
            `「${curRow.title}」已设置公式编辑或关联数据填充，无法使用当前事件赋值`,
            "字段不能被重复赋值",
            {
              confirmButtonText: "我知道了",
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              type: "warning",
            },
          ).then(() => {
            return false;
          });
        } else {
          return true;
        }
      }
    },
    // 判断是否为关联数据填充
    isReferenceField(arr) {
      return (
        this.formComponent.components.filter(
          (item) =>
            item.componentName == "reference_data" &&
            item.filledRule.findIndex((col) => arr.includes(col.fieldName)) >=
              0,
        ).length > 0
      );
    },
    // 获取关联数据设置填充组件字段
    getReferenceField(arr) {
      return this.formComponent.components.filter(
        (item) =>
          item.componentName == "reference_data" &&
          item.filledRule.findIndex((col) => arr.includes(col.fieldName)) >= 0,
      );
    },
    confirmReturnValue() {
      //筛选返回值设置不为空的项
      let action = this.action.filter((item) => {
        return item.value;
      });
      //判断返回值是否被公式编辑或是前端事件使用
      if (this.isAssigned(action)) {
        this.frontEvent.action = action.map((row) => {
          return {
            field: row.key,
            value: row.value,
          };
        });
        this.returnValueDialogVisible = false;
      }
    },
    openReturnValueSet() {
      if (this.frontEvent.action.length > 0) {
        this.action = this.frontEvent.action.map((item) => {
          let obj = this.allowReturnComponents.find((val) => {
            return val.key == item.field;
          });
          if (obj && item.field) {
            let actionItem = this._.cloneDeep(obj);
            actionItem.value = item.value;
            return actionItem;
          } else {
            return {
              name: item.field,
            };
          }
        });
      } else {
        this.action = [];
      }
      this.returnValueDialogVisible = true;
    },
    deleteReturnValue(actionItem, index) {
      actionItem.value = "";
      this.action.splice(index, 1);
    },
    //判断返回值是否已被设置
    isChoose(val, type) {
      if (type == "action") {
        if (this.action.length) {
          let obj = this.action.find((item) => {
            return item.name == val.name;
          });
          if (obj) {
            return true;
          }
        }
        return false;
      } else if (type == "responseParam") {
        if (this.responseParam.length) {
          let obj = this.responseParam.find((item) => {
            return item.fieldKey == val.key;
          });
          if (obj) {
            return true;
          }
        }
        return false;
      }
    },
    addFormReturnValue(val) {
      let actionItem = this._.cloneDeep(val);
      this.action.push(actionItem);
    },
    validateData(arr) {
      if (arr.length) {
        let hasSpace = false;
        arr = arr.filter((item) => {
          if ((item.name && !item.value) || (!item.name && item.value)) {
            hasSpace = true;
          }
          return item.name && item.value;
        });
        if (!hasSpace) {
          return arr;
        } else {
          return false;
        }
      } else {
        return [];
      }
    },
    confirmHeaderBody() {
      //验证header与body的设置是否完整
      let header = this.validateData(this.header);
      let body = this.validateData(this.body);
      if (header) {
        this.isHeaderRight = true;
        if (header.length) {
          this.frontEvent.request.header = header;
        }
      } else {
        this.isHeaderRight = false;
      }
      if (body) {
        this.isBodyRight = true;
        if (body.length) {
          this.frontEvent.request.body = body;
        }
      } else {
        this.isBodyRight = false;
      }
      if (header && body) {
        this.requestHeaderDialogVisible = false;
      }
    },
    openHeaderBody() {
      this.requestHeaderDialogVisible = true;
      if (this.frontEvent.request.header.length) {
        this.header = this.frontEvent.request.header;
      } else {
        this.header = [
          {
            name: "",
            value: "",
          },
        ];
      }
      if (this.frontEvent.request.body.length) {
        this.body = this.frontEvent.request.body;
      } else {
        this.body = [
          {
            name: "",
            value: "",
          },
        ];
      }
    },
    deleteBody(item, index) {
      item.value = "";
      this.body.splice(index, 1);
    },
    addBody() {
      this.body.push({
        name: "",
        value: "",
      });
    },
    deleteHeader(item, index) {
      item.value = "";
      this.header.splice(index, 1);
    },
    addHeader() {
      this.header.push({
        name: "",
        value: "",
      });
    },
    handleResponseSetClose() {
      this.action = [];
    },
    openRequestSet() {
      if (this.frontEvent.request.method == "get") {
        this.frontEvent.request.requestType = "";
      }
    },
    handleRequestSetClose() {
      this.isHeaderRight = true;
      this.isBodyRight = true;
      this.requestHeaderDialogVisible = false;
    },
    addEvent() {
      this.initField(this.formComponent.components);
      this.dialogVisible = true;
    },
    handleDrawerClose() {
      this.$emit("update:formEvents", this.frontEvents);
      this.$emit("update:drawer", false);
      this.$emit("updateFrontEvents", this.frontEvents);
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-field {
  color: red;
  text-decoration: line-through;
}
.debug-wrapper {
  display: flex;
  flex-direction: row;
  min-width: 1200px;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  .debug-form {
    position: relative;
    flex: 1 1 60%;
    .debug-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      border-top: 1px solid #ebecee;
    }
  }
  .debug-info {
    position: relative;
    flex: 1 1 40%;
    margin-left: 20px;
  }
}
.debug-body {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #e7eaf1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  .widgets-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 60px;
    left: 0;
    padding: 30px;
    overflow: auto;
  }
}
.form-event-pane {
  width: 780px;
  margin: 20px auto;
  padding: 12px 20px 20px;
  font-size: 14px;
  background: #fff;
  .pane-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #ebecee;
  }
  .pane-list {
    max-height: 600px;
    overflow: auto;
    margin-top: 8px;
    .sort-item {
      padding: 16px;
      border-bottom: 1px solid #ebecee;
      &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      }
      .item-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .header-right {
          ::v-deep .el-button > span {
            border-right: 1px solid #ebecee;
            padding-right: 10px;
          }
        }
      }
      .item-body {
        display: flex;
        flex-direction: column;
        color: #525967;
        line-height: 22px;
      }
    }
  }
}
::v-deep .el-drawer__header {
  margin-bottom: 15px;
  span {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  .el-drawer__close-btn .el-icon-close {
    &:hover {
      background-color: #d4d7da;
    }
  }
}
::v-deep .el-drawer__body {
  background-color: #f5f6f8;
}
.param-item {
  display: flex;
  flex-direction: row;
  .value-input-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    .el-input {
      padding-bottom: 0;
    }
  }
}
.grid {
  border-top: 1px solid #d9d4d4;
  margin: 55px 0px 20px;
}
.error-tip {
  margin-top: 2px;
  color: #e64340;
  font-size: 12px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ::v-deep .el-popover {
    margin: 0;
    padding: 0;
    height: 200px;
    overflow: auto;
  }
}
.divider-vertical {
  display: inline-block;
  height: 1em;
  margin: 0 10px;
  vertical-align: middle;
  border: solid #ebecee;
  border-width: 0 0 0 1px;
}
.ghost {
  opacity: 0.2 !important;
  background-color: #93c4e8;
}
.chosen {
  background-color: #fff;
}
::v-deep .el-input__inner {
  height: 35px;
  line-height: 35px;
}
::v-deep .el-dropdown-menu {
  height: 150px !important;
  overflow: auto !important;
}
</style>

<style lang="scss">
pre {
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: #232121;
  }
}
.field-select-pop-class {
  width: 150px !important;
  margin: 0 !important;
  padding: 0;
  max-height: 200px !important;
  overflow: auto;
  transform: translateX(-20%);
  .fieldList {
    padding: 5px;
    &:hover {
      background-color: #8a8f97;
    }
  }
}
.front-event-body {
  display: flex;
  flex-direction: column;
  padding-left: 40%;
  padding-right: 40%;
  margin-top: 50px;
  .description {
    color: #838892;
    font-size: 14px;
    margin: 16px 0px;
    text-align: center;
  }
  .addBtn {
    margin: auto;
  }
}
.front-event-config .el-dialog {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 550px;
  margin-top: 15vh !important;
  .el-dialog__header {
    font-weight: 700;
    flex: 0 0 auto;
  }
  .el-dialog__body {
    flex: 1 1 auto;
    overflow: auto;
    padding-bottom: 20px;
  }
  .el-dialog__footer {
    flex: 0 0 auto;
    padding: 10px;
    border-top: 1px solid #ebecee;
  }
}
.front-action-config .el-dialog {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 450px;
  margin-top: 20vh !important;
  .el-dialog__header {
    font-weight: 700;
    flex: 0 0 auto;
  }
  .el-dialog__body {
    flex: 1 1 auto;
    overflow: auto;
    padding-bottom: 20px;
  }
  .el-dialog__footer {
    flex: 0 0 auto;
    padding: 10px;
    border-top: 1px solid #ebecee;
  }
}
.front-event-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.formTitle {
  display: block;
  margin: 20px 0 10px 0;
}
.triggerBody {
  display: flex;
  flex-direction: column;
}
.executeBody {
  display: flex;
  flex-direction: column;
}
.content-block {
  flex: 1;
}
.sub-title {
  padding: 0 15px;
  line-height: 45px;
}
.sub-title-tip {
  color: #838892;
  font-size: 12px;
}
.request-content {
  height: calc(100% - 60px);
  overflow: auto;
  margin: 0 15px 15px 15px;
  padding: 0 15px 15px;
  font-size: 12px;
  background: #f5f6f8;
  .label {
    margin: 15px 0 5px;
    color: #141e31;
    font-weight: 500;
  }
  .value {
    color: #525967;
    word-wrap: break-word;
    word-break: break-word;
  }
  .line {
    color: #525967;
    margin-bottom: 5px;
    word-wrap: break-word;
    word-break: break-word;
  }
}
</style>
