import { fetchFormDataList } from "../api";
import { isJsonValue, isArrayValue } from "../utils";
import { isEmpty } from "@zgg-core-utils/relyUtil";

export default {
    data() {
        return {
            watchObj: {},
            watchLoading: {}
        }
    },
    destroyed() {
        Object.values(this.watchObj).forEach((val) => {
            val();
        });
    },
    methods: {
        destroyComponent() {
            Object.values(this.watchObj).forEach((val) => {
                val();
            });
        },
        buildDataLink() {

            // 构建数据联动
            if (
                !(
                    this.item.dataLinkage &&
                    this.item.dataLinkage.relationQuery &&
                    this.item.dataLinkage.relationQuery.length
                )
            ) {
                return;
            }


            let isEmptyField = true;
            let fieldName;
            this.item.dataLinkage.relationQuery
                .filter((item) => item.valueType == 'tableField')
                .forEach((item) => {
                    let key = `form.${item.value}`;
                    if (this.isJsonFormItem) {
                        key = `watchForm.${item.value}`
                    }


                    this.watchObj[item.value] = this.$watch(key, (val, old) => {
                        if (this.watchLoading[item.value]) {
                            return;
                        }
                        if (isEmpty(val) && isEmpty(old)) {
                            return;
                        }
                        if (this.item.componentName == 'select') {
                            if (isArrayValue(this.item.componentName)) {
                                this.value = [];
                            } else if (isJsonValue(this.item.componentName)) {
                                this.value = {};
                            } else {
                                this.value = "";
                            }
                        }
                        if (this.linkTimer) {
                            clearTimeout(this.linkTimer);
                        }
                        this.linkTimer = setTimeout(() => {
                            this.getDataLinkList(item.value);
                        }, 300);


                    });
                    let form;
                    if (this.isJsonFormItem) {
                        form = this.watchForm;
                    } else {
                        form = this.form;
                    }
                    if (!isEmpty(form[item.value])) {
                        isEmptyField = false;
                        fieldName = item.value
                    }


                });
            if (!isEmptyField) {
                this.getDataLinkList(fieldName);
            }
        },
        getDataLinkList(fieldName) {
            let advanceQuery = [];
            let queryObject = {
                pageNumber: 1,
                pageSize: 999,
            };

            this.item.dataLinkage.relationQuery.forEach((row) => {
                if (row.valueType == 'custom') {
                    // 自定义的值
                    if (row.value.countyId) {
                        advanceQuery.push({
                            key: row.key + ".countyId", //查询的字段name属性
                            oper: row.oper, //查询操作符
                            value: row.value.countyId, //查询值
                            componentName: row.componentName
                        });
                    } else if (row.value.cityId) {
                        advanceQuery.push({
                            key: row.key + ".cityId", //查询的字段name属性
                            oper: row.oper, //查询操作符
                            value: row.value.cityId, //查询值
                            componentName: row.componentName
                        });
                    } else if (row.value.provinceId) {
                        advanceQuery.push({
                            key: row.key + ".provinceId", //查询的字段name属性
                            oper: row.oper, //查询操作符
                            value: row.value.provinceId, //查询值
                            componentName: row.componentName
                        });
                    } else {
                        advanceQuery.push({
                            key: row.key, //查询的字段name属性
                            oper: row.oper, //查询操作符
                            value: row.value, //查询值
                            componentName: row.componentName
                        });
                    }
                } else {
                    // 当前表单字段

                    let value;
                    if (this.isJsonFormItem) {
                        value = this.watchForm[row.value];

                    } else {
                        value = this.form[row.value];

                    }
                    advanceQuery.push({
                        key: row.key,
                        oper: row.oper,
                        value: value,
                        componentName: row.componentName
                    });
                }
            });
            if (advanceQuery.length) {
                queryObject.advanceQuery = advanceQuery;
            }

            fetchFormDataList({
                collection: this.item.dataLinkage.tableName,
                queryObject: JSON.stringify(queryObject),
            }).then((res) => {
                if (this.item.componentName == "select") {
                    let list = res.data.page.list.map(
                        (row) => row[this.item.dataLinkage.fieldName]
                    );
                    let set = new Set(list);
                    list = [...set];
                    let customOptions = [];
                    list.forEach((val) => {
                        customOptions.push({
                            label: val,
                            value: val,
                        });
                    });
                    this.$set(this, "optionsList", customOptions);
                } else if (this.item.componentName == "json_form") {
                    // 子表单数据联动
                    let data = res.data.page.list[0];
                    let jsonForm = [];
                    if (data) {
                        let list = data[this.item.dataLinkage.fieldName];

                        if (list && this._.isArray(list)) {
                            list.forEach((col) => {
                                let o = {};
                                this.item.dataLinkage.subFieldRelyList.forEach((sub) => {
                                    o[sub.fieldName] = col[sub.relyFieldName];
                                });
                                jsonForm.push(o);
                            });
                        }
                    }

                    // 子表单联动
                    this.$set(this.form, this.item.name, jsonForm);
                } else {
                    let value;
                    if (res.data.page.list.length) {
                        value = res.data.page.list[0][this.item.dataLinkage.fieldName];
                    }
                    this.value = value;
                }
                setTimeout(() => {
                    this.watchLoading[fieldName] = false;
                }, 100);
            });
        },
    },
}