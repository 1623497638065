<template>
  <div>
    <div class="attr-row">
      <div><el-checkbox v-model="scan">扫码</el-checkbox></div>
      <template v-if="scan">
        <div style="margin-top: 8px" class="attr-label">扫码类型</div>
        <div>
          <el-checkbox-group v-model="scanType">
            <el-checkbox label="1">条形码</el-checkbox>
            <el-checkbox label="2">二维码</el-checkbox>
          </el-checkbox-group>
        </div>
        <div>
          <el-checkbox v-model="component.scanInputConfig.disabledManual"
            >禁止手动输入</el-checkbox
          >
        </div>
        <div>
          <el-checkbox v-model="component.scanInputConfig.disabledAlbum"
            >禁用相册</el-checkbox
          >
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import { componentProps } from "./util";
export default {
  name: "attr-scan-config",
  props: componentProps,
  computed: {
    scan: {
      get() {
        return !isEmpty(this.component.scanInputConfig);
      },
      set(val) {
        if (val) {
          this.$set(this.component, "scanInputConfig", {
            scanType: 0,
            disabledManual: true,
            getAndSave: false,
            oprType: 0,
            disabledAlbum: false,
          });
        } else {
          this.$set(this.component, "scanInputConfig", null);
        }
      },
    },
    scanType: {
      get() {
        if (!isEmpty(this.component.scanInputConfig)) {
          this.component.scanInputConfig.scanType;
          if (this.component.scanInputConfig.scanType == 0) {
            return ["1", "2"];
          } else {
            return [this.component.scanInputConfig.scanType.toString()];
          }
        }
        return [];
      },
      set(val) {
        if (val.length) {
          if (val.some((v) => v == 1) && val.some((v) => v == 2)) {
            this.$set(this.component.scanInputConfig, "scanType", 0);
          } else if (val.every((v) => v == 1)) {
            this.$set(this.component.scanInputConfig, "scanType", 1);
          } else if (val.every((v) => v == 2)) {
            this.$set(this.component.scanInputConfig, "scanType", 2);
          }
        }
      },
    },
  },
};
</script>