<template>
    <div style="height: 100%;">
        <iframe
            :src="docSrc"
            frameborder="0"
            width="100%"
            height="100%"
        ></iframe>
    </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
        docSrc: "https://www.zhiguangong.com"
    }
  },

  components: {},

  computed: {},

  created(){},

  mounted(){},

  methods: {}
}

</script>
<style lang='scss' scoped>
</style>
