<template>
  <div style="margin-bottom: 15px">
    <div class="weui-row">
      <el-input
        size="small"
        readonly
        style="width: 220px"
        :value="requestParam.param.title"
      ></el-input>
      <div style="font-size: 14px; margin-left: 10px" v-text="'设置为'"></div>
      <el-select
        :value="requestParam.paramValue.valueType"
        size="small"
        style="width: 140px; margin-left: 10px"
        placeholder=""
        @change="changeValueType"
      >
        <el-option
          v-if="
            requestParam.param.componentName != 'object' &&
            requestParam.param.componentName != 'object_array'
          "
          label="节点字段"
          value="nodeField"
        ></el-option>
        <el-option
          v-if="
            requestParam.param.componentName == 'object' ||
            requestParam.param.componentName == 'object_array'
          "
          label="节点"
          value="node"
        ></el-option>
        <el-option v-if="hasCustom" label="自定义" value="custom"></el-option>
        <el-option label="空值" value="nil"></el-option>
        <el-option
          v-if="requestParam.param.componentName == 'date_picker'"
          label="动态"
          value="dynamic"
        ></el-option>
        <el-option v-if="hasShowRely" label="公式" value="rely"></el-option>
      </el-select>
    </div>
    <div v-if="valueType != 'nil'" class="weui-row" style="width: 100%">
      <template v-if="valueType == 'rely'">
        <div style="width: 100%; position: relative">
          <el-input
            size="small"
            :value="relyTitle"
            placeholder="请设置公式"
            style="width: 100%"
            readonly
          ></el-input>
          <div
            @click.prevent.stop="openFormula"
            style="
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            "
          ></div>
        </div>

        <!-- <rely-dialog
          title="设置公式"
          :dialogVisible.sync="relyVisible"
          :originComponents="[]"
          relyType="rpaRely"
          :originalFormula="
            requestParam.paramValue.rely &&
            requestParam.paramValue.rely.originalFormula
          "
          :nodeList="nodeList"
          :fetchMetaFieldComponentList="fetchMetaComponents"
          :nodeKey="node.key"
          @save="saveRely"
        ></rely-dialog> -->
        <formula-dialog
          title="设置公式"
          :originalFormula="
            requestParam.paramValue.rely &&
            requestParam.paramValue.rely.originalFormula
          "
          :nodeKey="node.key"
          :treeNodeList="nodeList"
          :fetchMetaFieldComponentList="fetchMetaComponents"
          @save="saveRely"
          ref="relyDialog"
          :disabled="disabled"
          :disabled-funs="['TEXTUSER', 'TEXTDEPT', 'GETUSER', 'RECNO']"
        ></formula-dialog>
      </template>

      <DynamicDatePicker
        v-else-if="
          requestParam.param.componentName == 'date_picker' &&
          valueType == 'dynamic'
        "
        v-model="queryField.value"
        placeholder="请选择日期"
        size="small"
        style="width: 100%"
        :hasCustom="true"
        :filterTimeModeByPicker="true"
        :hasNow="true"
      >
      </DynamicDatePicker>
      <query-input
        v-else-if="valueType == 'custom'"
        :field="queryField"
        :areaTree="areaTree"
        :areaProps="areaProps"
        style="width: 100%"
        v-model="queryField.value"
        :disabled="disabled"
      ></query-input>
      <rpa-node-fields
        v-else-if="valueType == 'nodeField'"
        :nodeField="requestParam.paramValue.nodeField"
        :tableField="requestParam.param"
        fieldConfigKey="fillComponents"
        :baseNodeKey="node.baseNodeKey"
        :disabled="disabled"
        :pluginConfig="requestParam"
      ></rpa-node-fields>
      <el-select
        v-else-if="valueType == 'node'"
        v-model="requestParam.paramValue.node.key"
        placeholder=""
        @change="changeKey"
      >
        <el-option
          v-for="item in parentNodes"
          :key="item.key"
          :label="getLabel(item)"
          :value="item.key"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { userMixin } from "@/mixin/user-dept";
import rpaNodeFields from "./rpa-node-fields";
import { fetchMetaComponents } from "@/api/form";
import {
  fromNodesMixins,
  isCanSetRelyForRpa,
  noCustomComponents,
} from "./rpa-utils";
import { isEmptyByKey } from "@zgg-core-utils/utils";
import { checkRelyComponentName } from "@/utils/tools";

export default {
  props: {
    /** 源组件 */
    requestParam: Object,
    disabled: Boolean,
    node: Object,
  },
  components: { rpaNodeFields },
  mixins: [userMixin, fromNodesMixins],

  data() {
    return {
      relyVisible: false,
      queryField: {
        value: this.requestParam.paramValue.customValue,
        valueDetail: this.requestParam.paramValue.customValueDetail,
      },
    };
  },

  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    hasShowRely() {
      return isCanSetRelyForRpa(this.requestParam.param.componentName);
    },
    /** 值类型 */
    valueType() {
      return this.requestParam.paramValue.valueType;
    },
    hasCustom() {
      return !noCustomComponents.includes(
        this.requestParam.param.componentName,
      );
    },
    relyTitle() {
      if (!isEmptyByKey(this.requestParam.paramValue, "rely.originalFormula")) {
        return "已设置公式";
      }
      return "";
    },
    nodeList() {
      let list = this.getParentNodes().filter(
        (item) => !["developer_plugin", "trigger_webhook"].includes(item.type),
      );
      let nodeList = list.map((item) => {
        return {
          ...item,
          collection: item.collection || item.formId,
          collectionTitle: item.collectionTitle || item.formTitle,
        };
      });

      /**
       * @type Array
       */
      let fromNodeList = this.getFromNodes();
      nodeList = this._.cloneDeep(nodeList);
      nodeList = nodeList.filter((item) => {
        if (item.dataSource == "node_data") {
          return fromNodeList.findIndex((opt) => opt.key == item.nodeKey);
        } else {
          return true;
        }
      });
      nodeList.forEach((item) => {
        if (item.dataSource == "node_data") {
          let node = fromNodeList.find((opt) => opt.key == item.nodeKey);
          item.nodeField = node.nodeField;
        }
      });

      return nodeList;
    },
    parentNodes() {
      /**
       * @type {Array}
       */
      let parentNodes = this.getParentNodes(["query_data_list"]);
      let tableField = this.requestParam.param;
      //  如果请求参数参数是对象/对象数组
      if (tableField?.componentName === "object") {
        // 对象
        let list = parentNodes.filter((item) => item.type === "query_data_one");
        return list;
      }
      if (tableField?.componentName === "object_array") {
        // 对象数组
        let list = parentNodes.filter(
          (item) => item.type === "query_data_list",
        );
        return list;
      }
    },
  },
  watch: {
    queryField: {
      deep: true,
      immediate: false,
      handler(val) {
        this.$set(this.requestParam.paramValue, "customValue", val.value);
      },
    },
  },

  methods: {
    changeKey(value) {
      let node = this.parentNodes.find((item) => item.key == value);
      this.$set(this.requestParam.paramValue.node, "key", value);
      this.$set(this.requestParam.paramValue.node, "type", node.type);
    },
    getLabel(item) {
      if (item.type == "trigger_webhook") {
        return `${item.title}【Webhook触发】`;
      }
      if (item.type == "root") {
        return item.title;
      }

      if (item.dataSource == "node_data_field") {
        return `${item.title}【${item.nodeField.title}】`;
      } else if (item.dataSource == "node_data") {
        let title = item.title;
        let node = this.fromNodeList.find((opt) => opt.key == item.nodeKey);
        if (node) {
          title = `${item.title}【${node.nodeField.title}】`;
        }

        return title;
      } else {
        return `${item.title}【${
          item.formTitle || item.collectionTitle || item.pluginTitle
        }】`;
      }
    },
    fetchMetaComponents,
    openFormula() {
      this.$refs.relyDialog.open();
    },
    saveRely(originalFormula, componentNames) {
      // console.log(relys);
      let componentName = this.requestParam.param.componentName;
      if (!checkRelyComponentName(componentName, componentNames)) {
        return;
      }
      this.$set(this.requestParam.paramValue, "rely", { originalFormula });
      this.$refs.relyDialog.close();
    },

    changeValueType(value) {
      this.$set(this.requestParam.paramValue, "valueType", value);
      this.$set(this.requestParam.paramValue, "customValue", "");
      this.$set(this.requestParam.paramValue, "rely", {});
      this.$set(this.requestParam.paramValue, "nodeField", undefined);
      this.$set(this.requestParam.paramValue, "node", undefined);

      if (value === "dynamic") {
        this.$set(this.requestParam.paramValue, "dynamicTime", {
          timeMode: "",
          range: ["", ""],
        });
        this.queryField.value = { timeMode: "", range: ["", ""] };
      } else if (value == "node") {
        // 节点
        this.$set(this.requestParam.paramValue, "node", {
          key: "",
          type: "",
        });
        this.$set(this.requestParam.paramValue, "customValue", "");
        this.queryField.value = "";
      } else if (value == "nodeField") {
        this.$set(this.requestParam.paramValue, "nodeField", {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
          picker: "",
        });
        this.$set(this.requestParam.paramValue, "customValue", "");
        this.queryField.value = "";
      } else {
        this.$set(this.requestParam.paramValue, "customValue", "");
        this.queryField.value = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
}
</style>
