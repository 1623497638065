<template>
  <div>
    <el-form ref="form" :model="node" :rules="rules" label-position="top">
      <el-form-item label="选择插件" prop="pluginInstallId">
        <el-select
          v-model="node.pluginInstallId"
          filterable
          @change="changePlugin"
          placeholder="请选择插件"
        >
          <el-option
            v-for="item in pluginInstallList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="函数" prop="functionId">
        <el-select
          v-model="node.functionId"
          filterable
          @change="changeFun"
          placeholder="请选择函数"
        >
          <el-option
            v-for="item in funList"
            :key="item.id"
            :label="item.funsName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="isShow">
        <el-form-item label="设置请求参数">
          <template v-for="item in node.requestParams">
            <rpaPluginRequestSetting
              :key="item.name"
              :requestParam="item"
              :disabled="disabled"
              :node="node"
            ></rpaPluginRequestSetting>
          </template>
        </el-form-item>
        <el-form-item label="返回参数列表">
          <el-table :data="node.responseParams" border>
            <el-table-column
              prop="title"
              label="字段名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="componentName"
              label="字段类型"
              align="center"
            ></el-table-column>
          </el-table>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import {
  getPluginDetail,
  getPluginInstallList,
} from "@/views/lowCode/open-platform/api";
import { isEmptyByKey } from "@zgg-core-utils/utils";
import rpaPluginRequestSetting from "@/views/lowCode/rpa/rpa-plugin-request-setting.vue";
import { isEmpty } from "@/zgg-core/utils";
export default {
  name: "rpa-plugin",
  components: { rpaPluginRequestSetting },
  props: {
    node: Object,
    disabled: Boolean,
  },
  data() {
    return {
      pluginInstallList: [],
      funList: [],
      isShow: false,
      currentFunId: "",
      currentPlugin: "",
      rules: {
        pluginInstallId: [
          { required: true, trigger: "change", message: "请选择插件" },
        ],
        functionId: [
          { required: true, trigger: "change", message: "请选择函数" },
        ],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await getPluginInstallList({
        pageNumber: 1,
        pageSize: 100,
      }).then(async (res) => {
        this.pluginInstallList = res.data.page.list;
        if (this.node.pluginInstallId && this.node.functionId) {
          this.currentPlugin = this.pluginInstallList.find(
            (plugin) => plugin.id == this.node.pluginInstallId,
          );
          this.currentFunId = this.node.functionId;
          await this.getPluginDetail(this.currentPlugin.pluginId);
          this.isShow = true;
        }
      });
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let isError = this.node.requestParams.find((row) => {
          let item = row.paramValue;
          let currentFun = this.funList.find(
            (fun) => fun.id == this.currentFunId,
          );
          let detail =
            currentFun &&
            currentFun.requestParams.find((col) => {
              return col.id == row.param.name;
            });
          // todo确保每个参数都必须填写完整(这里比较特殊，不管是否必填字段)
          if (
            (item.valueType == "custom" && isEmpty(item.customValue)) ||
            (item.valueType == "node" && isEmpty(item.node.key)) ||
            (item.valueType == "nodeField" &&
              isEmpty(item.nodeField.nodeKey)) ||
            (item.valueType == "rely" && isEmpty(item.rely)) ||
            (item.valueType == "dynamic" && isEmpty(item.dynamicTime))
          ) {
            return row;
          }
        });
        if (isError) {
          this.$message.error(`${isError.param.title}参数值不能为空`);
          return;
        }
        callback(valid);
      });
    },
    initRequestParams(arr) {
      this.node.requestParams = [];
      arr.forEach((item) => {
        let obj = {
          param: {
            componentName: item.componentName,
            name: item.id,
            title: item.title,
          },
          paramValue: {
            valueType: "custom",
            customValue: "",
            customValueDetail: "",
            dynamicTime: {},
            rely: {},
            nodeField: {},
            tableField: {},
          },
        };
        this.node.requestParams.push(obj);
      });
    },
    async getPluginDetail(pluginId) {
      await getPluginDetail({
        pluginId,
      }).then((res) => {
        if (!isEmptyByKey(res, "data.plugin.afterFuns")) {
          this.funList = res.data.plugin.afterFuns;
        }
      });
    },
    changePlugin(e) {
      this.isShow = false;
      this.currentPlugin = this.pluginInstallList.find((plugin) => {
        return plugin.id == e;
      });
      this.node.pluginTitle = this.currentPlugin.name;
      this.$set(this.node, "functionId", "");
      this.getPluginDetail(this.currentPlugin.pluginId);
    },
    changeFun(e) {
      if (e) {
        this.isShow = false;
        this.funList.forEach((item) => {
          if (item.id == e) {
            this.currentFunId = e;
            if (item.requestParams && item.requestParams instanceof Array) {
              this.initRequestParams(item.requestParams);
              let params = item.responseParams;
              this.$set(this.node, "responseParams", []);
              params.forEach((item) => {
                this.node.responseParams.push({
                  componentName: item.dataType,
                  name: item.id,
                  title: item.name,
                });
              });
            }
            this.isShow = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table th {
  background-color: #f5f7fa;
}
</style>
