<template>
    <div>
<!-- 指标图自定义颜色 -->
      <div v-if="chartType=='table'">
        <div class="select-div" @click="showSelect()">
     <div class="input-div1" :style="'background-color:'+ myColor" >
   
     </div>
     <i class="el-icon-arrow-down"></i>
     </div>
     <div v-if="show"  class="cont-div">
        <div class="content1" v-for="item in colorList" :key="item.value">
          <div class="content-item1" :style="'text-align:center;background-color:'+ sub" @click="selectVal1(sub)" v-for="sub in item.bg">
            <i v-if="myColor==sub" class="el-icon-check" style="color:#fff;line-height:30px;"></i>
          </div>
        </div>
      </div>
      </div>
      <div v-else>
        <div class="select-div" @click="showSelect()">
     <div class="input-div" >
      <div class="content-item" :style="'background-color:'+ sub"  v-for="sub in myColorList">
       </div> 
     </div>
     <i class="el-icon-arrow-down"></i>
     </div>
     <div v-if="show"  class="cont-div">
        <div class="content" v-for="item in colorList" :key="item.value">
          <div class="content-item" :style="'background-color:'+ sub" @click="selectVal(item)" v-for="sub in item.bg">
          </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: "colorSelect",
      model: {
        event: 'updateColor'
      },
      props: {
        //颜色数组
        colorList: {
          type: Array,
          default: () => {
            return [{
              value: "10",
              bg:["#6BDAAE", "#7B67EE", "#3870EA", "#035885", "#08F8E9", "#F94144", "#D9ED92", "#FF9D66", "#FF7875","#000000"] 
            }];
          }
        },
        chartType:{
          type:String,
          default:"chart"
        },
        viewChart:{
          type:Object,
        }
   
      },
      data() {
        return {
          myColorList:[] ,
          show:false,
          myColor:""
        }
      },
      created(){
       if(this.viewChart&&this.viewChart.colorConfig&&this.viewChart.colorConfig.colorScheme){
        let arr = []
         arr = this.colorList.find(
          (item)=>item.value == this.viewChart.colorConfig.colorScheme
         )
         if(arr){
          this.myColorList  = arr.bg
         }     
       }
       if(this.viewChart&&this.viewChart.valueColor){
           this.myColor = this.viewChart.valueColor
       }
       
      },
    
      methods: {
        showSelect(){   
          this.show = !this.show
        },
        selectVal1(sub){
         this.myColor = sub
        //  this.show=false;
         this.$emit('updateColor1', this.myColor);
        },
        selectVal(item){
         this.myColorList = item.bg
        //  this.show=false;
         this.$emit('updateColor',item.value);
        },

      },
    }
  </script>
  
  <style lang="scss" scoped>
  .select-div{
    cursor: pointer;
    width: 258px;
    height: 36px;
    border: 1px solid#DFE1E6;
    display: flex;
    flex-direction: row;
    border-radius: 2px;
    justify-content:  space-around;
    align-items: center;

    .input-div{
      width: 189px;
      display: flex;
        flex-direction:row;
    }
  }
  .cont-div{
    border: 1px solid #DFE1E6;
    padding: 4px;
    border-radius: 5px;
    width: 205px;
    // margin: auto;
    .content{
    border: 1px solid #fff;
    display: flex;
    flex-direction:row;
    padding-top:8px;
 
  }
  }
  .content-item{
      width:22px;
      height: 22px;
      display: block;
    }
    .content1{
      display: flex;
      flex-direction:row;
      flex-wrap:wrap;
  }
  .input-div1{
    height: 22px;
    width: 189px;
  }
  .content-item1{
      margin: 4px;
      width:30px;
      height: 30px;
     border-radius: 50%;
    }
  </style>
  
  
  