<template>
  <div class="addSoruce">
    <el-button @click="addComponent" size="small" class="btn-add"
      ><i class="el-icon-plus"></i>&nbsp;添加组件
    </el-button>
    <el-dialog
      title="添加组件"
      append-to-body
      destroy-on-close
      :visible.sync="dialogVisible"
      @open="openDialog"
      @close="handleClose"
      :close-on-click-modal="false"
      class="custom-fullscreen"
    >
      <div style="padding: 24px; display: flex; flex-direction: column">
        <div
          @click="selectItem(item)"
          v-for="item in components"
          :key="item.value"
          class="card"
          :class="activeItem == item.value ? 'active' : ''"
        >
          <div class="left">
            <img :src="item.img" alt="" />
          </div>
          <div class="right">
            <p class="title">{{ item.label }}</p>
            <p class="desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>
      <div slot="footer" style="display: flex; justify-content: end">
        <el-button @click="cancelClose">取 消</el-button>
        <el-button
          style="margin-left: 10px"
          type="primary"
          @click="confirmAddDashModule"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 筛选组件 -->
    <dashboardFilterSetting
      :visible.sync="visible"
      :data-component-list="dataComponentList"
      :filter-component-list="filterComponentList"
      @save="saveForm"
      @cancel="cancel"
      :show-close="false"
    ></dashboardFilterSetting>
    <!-- 自定义文本 -->
    <dashboardFreeText
      :visible.sync="visibleZi"
      :data-component-list="dataComponentList"
      @save="saveForm"
      @cancel="cancel"
      :show-close="false"
    ></dashboardFreeText>
    <!-- 倒计时组件 -->
    <dashboardCountDown
      :visible.sync="visibleDao"
      :data-component-list="dataComponentList"
      @save="saveForm"
      @cancel="cancel"
      :show-close="false"
    ></dashboardCountDown>
    <!-- 标题组件 -->
    <dashboardTopic
      :visible.sync="visibleBiao"
      :data-component-list="dataComponentList"
      @save="saveForm"
      @cancel="cancel"
      :show-close="false"
    ></dashboardTopic>
    <dashboard-carousel-edit
      :visible.sync="visibleCarousel"
      @save="saveForm"
      @cancel="cancel"
    ></dashboard-carousel-edit>
    <dashboard-include-edit
      :visible.sync="visibleInclude"
      @save="saveForm"
      @cancel="cancel"
    ></dashboard-include-edit>
    <dashboard-button-group-edit
      :visible.sync="visibleButtonGroup"
      @save="saveForm"
      @cancel="cancel"
    ></dashboard-button-group-edit>
  </div>
</template>
<script>
import img1 from "@/assets/add-component/filler.png";
import img2 from "@/assets/add-component/text.png";
import img3 from "@/assets/add-component/down.png";
import img4 from "@/assets/add-component/topics.png";
import container from "@/assets/add-component/container.png";
import Clickoutside from "element-ui/src/utils/clickoutside";
import dashboardFilterSetting from "@/views/lowCode/dashboard/dashboard-filter-setting/dashboard-filter-setting";
import dashboardFreeText from "@/views/lowCode/dashboard/add-component/dashboard-free-text/dashboard-free-text";
import addComponent from "@/views/lowCode/dashboard/add-component/index";
import dashboardCountDown from "@/views/lowCode/dashboard/add-component/dashboard_count_down/dashboard_count_down";
import dashboardTopic from "@/views/lowCode/dashboard/add-component/dashboard_topic/dashboard_topic";
import carouselImage from "@/assets/add-component/carousel.png";
import iframeImage from "@/assets/add-component/iframe.png";
import buttonImage from "@/assets/add-component/button.png";
import dashboardCarousel from "@/views/lowCode/dashboard/add-component/dashboard-carousel/dashboard-carousel-edit.vue";
import DashboardCarouselEdit from "@/views/lowCode/dashboard/add-component/dashboard-carousel/dashboard-carousel-edit.vue";
import DashboardIncludeEdit from "@/views/lowCode/dashboard/add-component/dashboard-include/dashboard-include-edit.vue";
import DashboardInclude from "@/views/lowCode/dashboard/add-component/dashboard-include/dashboard-include.vue";
import DashboardButtonGroupEdit from "@/views/lowCode/dashboard/add-component/dashboard-button-group/dashboard-button-group-edit.vue";

export default {
  components: {
    DashboardButtonGroupEdit,
    DashboardInclude,
    DashboardCarouselEdit,
    addComponent,
    dashboardFilterSetting,
    dashboardFreeText,
    dashboardCountDown,
    dashboardTopic,
    dashboardCarousel,
    DashboardIncludeEdit,
  },
  directives: { Clickoutside },
  props: {
    minWidth: Number,
    minHeight: Number,
    dataComponentList: Array,
    filterComponentList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      title: "",
      formId: "",
      visibleZi: false,
      visibleDao: false,
      visibleBiao: false,
      visibleCarousel: false,
      visibleInclude: false,
      visibleButtonGroup: false,
      components: [
        {
          label: "筛选组件",
          value: "dashboard_filter",
          desc: "筛选仪表盘数据",
          img: img1,
        },
        {
          label: "按钮组件",
          value: "button_group",
          desc: "快速打开工作群、表单、外部链接等",
          img: buttonImage,
        },
        {
          label: "轮播图组件",
          value: "carousel_view",
          desc: "展示图片，点击图片跳转到指定页面",
          img: carouselImage,
        },
        {
          label: "自定义文本",
          value: "free_text",
          desc: "创建图文内容",
          img: img2,
        },
        {
          label: "布局容器组件",
          value: "dashboard",
          desc: "将多个图表、组件放进容器中，以紧凑样式呈现",
          img: container,
        },
        {
          label: "内嵌组件",
          value: "include",
          desc: "插入网页，实时浏览相关数据的网页",
          img: iframeImage,
        },
        {
          label: "标题组件",
          value: "title_view",
          desc: "为仪表盘添加主标题样式",
          img: img4,
        },
        {
          label: "倒计时组件",
          value: "count_down",
          desc: "设置目标日，统计剩余时间",
          img: img3,
        },
      ],
      activeItem: "",
      currentSelectedModule: {},
    };
  },
  created() {
    this.activeItem = "";
  },
  watch: {
    dialogVisible() {
      this.activeItem = "";
    },
  },
  computed: {},
  destroyed() {
    this.visible = false;
    this.dialogVisible = false;
  },
  methods: {
    addComponent() {
      this.dialogVisible = true;
    },
    selectItem(item) {
      this.currentSelectedModule = item;
      this.activeItem = item.value;
    },
    save() {
      this.confirmAddDashModule();
    },
    confirmAddDashModule() {
      console.log(this.currentSelectedModule);
      if (!this.currentSelectedModule) {
        this.$message.warning("请选择新增组件类型");
        return;
      }
      this.$emit("addElement", {
        view: {
          ...this.currentSelectedModule,
        },
      });
      this.dialogVisible = false;
      if (this.currentSelectedModule.value == "dashboard") {
        this.$emit("addFilter", {
          title: "未命名布局容器",
          hiddenTitle: false,
          components: [],
        });
        return;
      }
      this.showModuleSetting();
    },
    showModuleSetting() {
      if (this.currentSelectedModule.value == "dashboard_filter") {
        this.visible = true;
      }
      if (this.currentSelectedModule.value == "free_text") {
        this.visibleZi = true;
      }
      if (this.currentSelectedModule.value == "count_down") {
        this.visibleDao = true;
      }
      if (this.currentSelectedModule.value == "title_view") {
        this.visibleBiao = true;
      }
      if (this.currentSelectedModule.value == "carousel_view") {
        this.visibleCarousel = true;
      }
      if (this.currentSelectedModule.value == "include") {
        this.visibleInclude = true;
      }
      if (this.currentSelectedModule.value == "button_group") {
        this.visibleButtonGroup = true;
      }
    },
    close() {
      if (this.moveObj) {
        this.$emit("closeMove");
      }
    },
    saveForm(val) {
      this.$emit("addFilter", val);
      this.moveObj = null;
      this.dialogVisible = false;
      this.visibleZi = false;
      this.visibleDao = false;
      this.visibleBiao = false;
      this.visibleCarousel = false;
      this.visible = false;
      this.visibleInclude = false;
      this.visibleButtonGroup = false;
    },
    cancel() {
      this.title = "";
      this.formId = "";
      if (this.moveObj) {
        this.$emit("closeMove");
      }
      this.visibleZi = false;
      this.visibleDao = false;
      this.visibleBiao = false;
      this.visibleCarousel = false;
      this.visible = false;
      this.visibleInclude = false;
      this.visibleButtonGroup = false;
    },

    cancelClose() {
      this.handleClose();
    },
    handleClose() {
      this.dialogVisible = false;
      this.currentSelectedModule = null;
    },
    openDialog() {},
  },
};
</script>
<style lang="scss" scoped>
.btn-add {
  color: #1890ff;
  border-color: #1890ff;
}

.node-wrap {
  line-height: 36px;
  height: 36px;
  font-size: 15px;
  vertical-align: middle;
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  flex: 1;
  align-items: center;
  position: relative;

  .node-label-wrap {
    flex: 1;
    width: 0;

    .node-label-content {
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
      line-height: 20px;
      color: rgba(9, 30, 66, 0.95);
    }
  }
}

.component-item {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  padding: 0 10px;

  &:hover {
    background-color: rgba($color: #1890ff, $alpha: 0.2);
  }
}

.card {
  width: 778px;
  height: 120px;
  border: 1px solid #ebecf0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  .left {
    img {
      width: 150px;
      height: 100px;
    }
  }
  .right {
    color: #172b4d;
    padding-left: 20px;
    .title {
      font-size: 16px;
    }
    .desc {
      color: #6b778c;
    }
  }
}
.active {
  border: 1px solid #3870ea;
}

::v-deep .el-dialog__header {
  margin-bottom: 0px !important;
}

.sure-btn {
  text-align: center;
  padding: 8px 20px;
  width: 78px;
  height: 40px;
  line-height: 25px;
  background: #3870ea;
  border-radius: 4px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}
</style>
<style lang="scss">
.pop-over {
  padding: 0px !important;
}
</style>
