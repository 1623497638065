


export const displayQueryOpers = [
    {
        value: "eq",
        label: "等于",
    },
    {
        label: "不等于",
        value: "neq",
    },
    {
        label: "包含",
        value: "like",
    },
    {
        label: "不包含",
        value: "nlike",
    },
    {
        label: "大于",
        value: "gt",
    },
    {
        label: "大于等于",
        value: "gte",
    },
    {
        label: "小于",
        value: "lt",
    },
    {
        label: "小于等于",
        value: "lte",
    },
    {
        label: "等于任意一个",
        value: "in",
    },
    {
        label: "不等于任意一个",
        value: "nin",
    },
    {
        label: "包含任意一个",
        value: "containAny"
    },
    {
        label: '同时包含',
        value: "contain"
    }, {
        label: '属于',
        value: 'belong'
    }, {
        label: '不属于',
        value: 'nobelong'
    },
    {
        label: "为空",
        value: "nil",
    },
    {
        label: "不为空",
        value: "nnil",
    },
]



export function getQuerOper() {
    return displayQueryOpers;
}

export function getDisplayQueryOpers(componentName) {
    let list = [];
    let arr = []

    if (componentName == "input") {
        // 单行文本
        arr = ['eq', 'neq', 'in', 'nin', 'like', 'nlike'];
    } else if (componentName == 'text_area') {
        // 多行文本
        arr = ['like', 'nlike']
    } else if (componentName == 'input_number') {
        // 数字
        arr = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte']
    } else if (componentName == 'date_picker') {
        // 日期
        arr = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte']
    } else if (componentName == 'radio_group') {
        // 单选框组
        arr = ['eq', 'neq']
    } else if (componentName == 'checkbox_group') {
        // 多选框组
        arr = ['eq', 'containAny', 'contain']
    } else if (componentName == 'select') {
        // 下拉选择器
        arr = ['eq', 'neq', 'in', 'nin']
    } else if (componentName == 'address_input') {
        // 地址
        arr = ['belong', 'nobelong']

    } else if (componentName == 'user_select' || componentName == 'department_select') {
        arr = ['eq', 'neq', 'in']
    } else if (componentName == 'user_list_select' || componentName == 'department_list_select') {
        arr = ['contain', 'containAny'];
    } else if (componentName == 'sn_input') {
        arr = ['eq', 'neq'];
    } else if (componentName == 'reference_data') {
        arr = ['eq'];
        list = displayQueryOpers.filter(item => arr.includes(item.value));
        return list;
    }
    arr = arr.concat(['nil', 'nnil'])
    list = displayQueryOpers.filter(item => arr.includes(item.value));
    return list;
}
