<template>
  <gantt-view
    ref="gantt"
    :columns="columns"
    :list="list"
    :isProcess="true"
    :chartLabelFieldName="gantt.chartLabelFieldName"
    :timeProgresses="timeProgresses"
    :cycle="gantt.zoomLevel"
    :colorConfig="gantt.colorConfig"
    v-if="list.length"
    class="body-flex"
    :class="isH5 ? '' : 'isWeb'"
  ></gantt-view>
</template>
<script>
import { getGanttData } from "../view/api";
import { mapFlowStatus } from "@zgg-core-utils/utils";

export default {
  data() {
    return {
      columns: [],
      list: [],
      timeProgresses: [],
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getData(gantt, dataFilter) {
      var jsContentColor = "#3333333";
      var jsHeadBackground = "#c7d8e7";
      var jsHeadText = "#3333333";

      if (gantt?.colorConfig?.contentColor) {
        jsContentColor = gantt?.colorConfig?.contentColor;
      }
      if (gantt?.colorConfig?.borderColor && !this.isH5) {
        jsHeadBackground = gantt?.colorConfig?.borderColor;
      } else {
        gantt.colorConfig.borderColor = "#ddecfa";
      }
      if (gantt?.colorConfig?.headColor) {
        jsHeadText = gantt?.colorConfig?.headColor;
      }
      document.documentElement.style.setProperty(
        "--js-gantt-content-color",
        jsContentColor,
      );
      document.documentElement.style.setProperty(
        "--js-gantt-head-background-color",
        jsHeadBackground,
      );
      document.documentElement.style.setProperty(
        "--js-gantt-head-text-color",
        jsHeadText,
      );
      let columns = [];
      gantt.xFields.forEach((item) => {
        columns.push({
          label: item.title,
          prop: item.field.name,
          width: "",
          field: item.field,
        });
      });
      this.columns = columns;
      let ganttView = this._.cloneDeep(gantt);

      ganttView.xFields.forEach((item) => {
        delete item.visible;
        delete item.sortVisible;
      });
      let timeProgresses = ganttView.timeProgresses.filter(
        (item) => item.startTimeField && item.endTimeField,
      );
      timeProgresses.forEach((item) => {
        delete item.isDiffParent;
        delete item.isEmpty;
      });
      ganttView.timeProgresses = timeProgresses;
      this.gantt = ganttView;

      this.timeProgresses = timeProgresses;
      if (!(gantt.xFields.length && ganttView.timeProgresses.length)) {
        return;
      }

      getGanttData({
        ganttView: JSON.stringify(ganttView),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        let list = res.data.list;
        this.list = list;
        this.list.forEach(
          (item) =>
            (item.info.flowStatus = mapFlowStatus(item.info.flowStatus)),
        );
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.body-flex {
  height: 100%;
}
.isWeb {
  ::v-deep .gantt_layout .gantt_lyt_project .gantt_project_box {
    background-color: transparent;
  }
  ::v-deep .gantt_layout .gantt_lyt_content .gantt_lyt_header {
    background: transparent;
  }
  ::v-deep .gantt_layout .gantt_lyt_project .project-header {
    background-color: var(--js-gantt-head-background-color);
    // background-image: url();
  }
  ::v-deep .gantt_layout .gantt_lyt_project .project_name,
  ::v-deep .gantt_layout .gantt_lyt_content .gantt_lyt_header {
    color: var(--js-gantt-content-color);
  }
  ::v-deep .gantt_layout .gantt_lyt_project .project_title_name {
    color: var(--js-gantt-head-text-color);
  }

  ::v-deep .gantt_layout {
    border: 1px solid var(--js-gantt-head-background-color);
  }
  ::v-deep .gantt_layout .gantt_lyt_content .gantt_lyt_header,
  ::v-deep .gantt_layout .gantt_lyt_project .project-header,
  ::v-deep .gantt_layout .gantt_lyt_content .gantt_lyt_header .cycle {
    border-bottom: 1px solid var(--js-gantt-head-background-color);
  }

  ::v-deep .gantt_layout .gantt_lyt_project .gantt_project_cell {
    border-top: 1px solid var(--js-gantt-head-background-color);
  }
  ::v-deep .gantt_layout .gantt_lyt_project,
  ::v-deep
    .gantt_layout
    .gantt_lyt_content
    .gantt_lyt_header
    .gantt_lyt_timeline {
    border-right: 1px solid var(--js-gantt-head-background-color);
  }

  ::v-deep .gantt_layout .gantt_lyt_content .gantt_lyt_header .cycle::after {
    background: var(--js-gantt-head-background-color);
  }
}
</style>
