<template>
  <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
    <el-form-item label="推送内容" prop="renderNoticeType">
      <el-select
        v-model="renderNoticeType"
        placeholder="请选择"
        @change="changeNoticeType"
      >
        <el-option label="提示通知" value="tip"></el-option>
        <el-option label="卡片通知" value="card"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="提示类型" prop="tipType">
      <div class="tip-types">
        <div
          class="tip-item"
          :class="{ active: node.tipType == 'success' }"
          @click="changeTipType('success')"
        >
          <i
            class="el-icon-success tip-icon"
            style="color: rgba(103, 194, 58, 1)"
          ></i>
          <span class="tip-text">成功</span>
        </div>
        <div
          class="tip-item"
          :class="{ active: node.tipType == 'error' }"
          @click="changeTipType('error')"
        >
          <i
            class="el-icon-error tip-icon"
            style="color: rgba(245, 108, 108, 1)"
          ></i>
          <span class="tip-text">错误</span>
        </div>
        <div
          class="tip-item"
          :class="{ active: node.tipType == 'warn' }"
          @click="changeTipType('warn')"
        >
          <i
            class="el-icon-warning tip-icon"
            style="color: rgba(255, 163, 64, 1)"
          ></i>
          <span class="tip-text">警告</span>
        </div>
        <div
          class="tip-item"
          :class="{ active: node.tipType == 'info' }"
          @click="changeTipType('info')"
        >
          <i
            class="el-icon-info tip-icon"
            style="color: rgba(144, 147, 153, 1)"
          ></i>
          <span class="tip-text">消息</span>
        </div>
      </div>
    </el-form-item>
    <el-form-item
      :key="renderNoticeType + 'title'"
      :label="node.renderNoticeType == 'tip' ? '内容' : '标题'"
      :rules="[
        {
          required: true,
          message: node.renderNoticeType == 'tip' ? '请输入内容' : '请输入标题',
        },
      ]"
      prop="noticeTitle"
    >
      <rpa-codemirror
        ref="rpaTitle"
        :fieldList="fieldList"
        @change="changeTitle"
        :disabled="disabled"
      ></rpa-codemirror>
      <div v-if="isComplete && !disabled">
        <el-popover v-model="visible" trigger="click">
          <el-button type="text" icon="el-icon-plus" slot="reference"
            >添加字段</el-button
          >
          <div style="max-height: 320px; overflow: custom">
            <el-tree
              :props="treeProps"
              @node-click="nodeClick"
              :load="loadNode"
              :lazy="true"
            >
            </el-tree>
          </div>
        </el-popover>
      </div>
    </el-form-item>

    <el-form-item
      v-if="renderNoticeType == 'card'"
      label="描述"
      prop="noticeContent"
      :key="renderNoticeType + 'content'"
    >
      <rpa-codemirror
        ref="rpaContent"
        :fieldList="fieldList"
        @change="changeContent"
        :disabled="disabled"
      ></rpa-codemirror>
      <div v-if="isComplete && !disabled">
        <el-popover v-model="visible2" trigger="click">
          <el-button type="text" icon="el-icon-plus" slot="reference"
            >添加字段</el-button
          >
          <div style="max-height: 320px; overflow: custom">
            <el-tree
              :props="treeProps"
              @node-click="nodeClick2"
              :load="loadNode"
              :lazy="true"
            >
            </el-tree>
          </div>
        </el-popover>
      </div>
    </el-form-item>
    <el-form-item label="关闭方式">
      <el-radio-group
        v-model="closeType"
        size="small"
        @change="changeCloseType"
      >
        <el-radio label="time">指定时间后</el-radio>
        <el-radio label="custom">手动关闭</el-radio>
      </el-radio-group>
      <div v-if="closeType == 'time'">
        <el-input-number
          v-model="duration"
          placeholder="请输入时间"
          :min="1"
          size="small"
        ></el-input-number>
        <span style="margin-left: 5px">秒</span>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
import rpaCodemirror from "./rpa-codemirror";
import {
  buildNodeComponentsMap,
  fromNodesMixins,
  loadNodeLeaf,
} from "./rpa-utils";
import { isEmpty } from "@zgg-core-utils/utils";

export default {
  name: "rpa-render-notice",
  props: {
    node: Object,
    disabled: Boolean,
  },
  mixins: [fromNodesMixins],
  components: { rpaCodemirror },
  computed: {
    isComplete() {
      return !isEmpty(this.componentsMap);
    },
    renderNoticeType: {
      get() {
        return this.node.renderNoticeType;
      },
      set(val) {
        this.$set(this.node, "renderNoticeType", val);
      },
    },
    duration: {
      get() {
        return this.node.duration;
      },
      set(val) {
        this.$set(this.node, "duration", val);
      },
    },
  },
  data() {
    return {
      closeType: "time",
      visible: false,
      visible2: false,
      fieldList: [],
      treeProps: {
        label: (data) => {
          let str = data.title;
          if (data.formTitle) {
            str += `【${data.formTitle}】`;
          } else if (data.collectionTitle) {
            str += `【${data.collectionTitle}】`;
          }
          return str;
        },
        isLeaf: "leaf",
      },
      componentsMap: {},
      nodeList: [],
      rootFlowParams: [],
    };
  },
  async created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
    let closeType = "";
    if (this.node.duration) {
      closeType = "time";
    } else {
      closeType = "custom";
    }
    this.closeType = closeType;
    let parentNodes = this.getParentNodes();
    let componentsMap = await buildNodeComponentsMap.bind(this)(parentNodes);

    if (this.rootFlowParams?.length) {
      componentsMap["root"] = this.rootFlowParams;
    }
    this.buildFieldList("root", componentsMap["root"]);
    let nodeList = [];
    parentNodes
      .filter((item) =>
        ["query_data_one", "trigger_form_data_change"].includes(item.type),
      )
      .forEach((item) => {
        let formTitle = item.formTitle || item.collectionTitle;
        nodeList.push({
          title: item.title + "【" + formTitle + "】",
          key: item.key,
        });
      });
    this.$set(this, "nodeList", nodeList);
    this.buildDeleteField();
    this.parentNodes = parentNodes;

    this.$set(this, "componentsMap", componentsMap);
    this.$nextTick(() => {
      if (this.$refs.rpaTitle && !isEmpty(this.node.noticeTitle)) {
        this.$refs.rpaTitle.init(this.node.noticeTitle);
      }
      if (this.$refs.rpaContent && !isEmpty(this.node.noticeContent)) {
        this.$refs.rpaContent.init(this.node.noticeContent);
      }
    });
  },
  methods: {
    changeCloseType(val) {
      if (val == "time") {
        this.$set(this.node, "duration", 3);
      } else {
        this.$set(this.node, "duration", 0);
      }
    },
    nodeClick(data, node) {
      if (node.isLeaf) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }
        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs.rpaTitle.insert(obj);
        this.$refs.rpaTitle.onBlur();
        this.visible = false;
      }
    },
    changeTitle(e) {
      this.$set(this.node, "noticeTitle", e);
      let code = e.concat(this.node.noticeContent);
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }

      this.$set(this.node, "nodeFields", arr);
    },
    nodeClick2(data, node) {
      if (node.isLeaf) {
        // 子节点
        if (data.type == "subflow_collection") {
          return;
        }
        let obj = {
          title: data.title,
          name: node.parent.data.key + "_" + data.name,
        };
        this.$refs.rpaContent.insert(obj);
        this.$refs.rpaContent.onBlur();
        this.visible2 = false;
      }
    },
    /**
     *
     * @param {String} e
     */
    changeContent(e) {
      this.$set(this.node, "noticeContent", e);
      let code = this.node.noticeTitle.concat(e);
      const rex = /#{[^\}]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        let componentMap = {};
        this.fieldList.forEach((item) => {
          componentMap[item.name] = item.title;
        });
        result.forEach((item) => {
          let name = item.replaceAll("#{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            arr.push({
              nodeKey: field.nodeKey,
              title: field.title,
              name: field.fieldName,
              componentName: field.componentName,
            });
          }
        });
      }

      this.$set(this.node, "nodeFields", arr);
    },
    changeTipType(val) {
      this.$set(this.node, "tipType", val);
    },

    changeNoticeType() {
      this.$set(this.node, "noticeTitle", "");
      this.$set(this.node, "noticeContent", "");
      this.$set(this.node, "nodeFields", []);
      this.$set(this.node, "duration", 0);
      this.$refs.form.clearValidate();
    },
    /** 构建编辑器用的组件列表 */
    buildFieldList(nodeKey, list) {
      if (list) {
        let tempList = list.map((item) => {
          return {
            nodeKey: nodeKey,
            fieldName: item.name,
            componentName: item.componentName,
            name: nodeKey + "_" + item.name,
            title: item.title,
          };
        });
        this.fieldList = this.fieldList.concat(tempList);
      }
    },
    /** 在fieldList插入已删除的字段列表用于编辑器展示提示 */
    buildDeleteField() {
      if (this.node && this.node.nodeFields) {
        this.node.nodeFields.forEach((item) => {
          let name = item.nodeKey + "_" + item.name;
          let isDelete =
            this.fieldList.findIndex((row) => row.name == name) == -1;
          if (isDelete) {
            this.fieldList.push({
              nodeKey: item.nodeKey,
              fieldName: item.name,
              componentName: item.componentName,
              name: item.nodeKey + "_" + item.name,
              title: item.title,
              delete: true,
            });
          }
        });
      }
    },
    loadNode: loadNodeLeaf,
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let index = this.node.nodeFields.findIndex(
          (item) =>
            this.fieldList.findIndex(
              (row) =>
                row.delete &&
                item.name == row.fieldName &&
                item.nodeKey == row.nodeKey,
            ) >= 0,
        );

        if (index >= 0) {
          this.$message.error("通知内容存在被删除字段，请删除被删除字段");
          return;
        }
        if (this.closeType == "time" && !this.node.duration) {
          this.$message.error("请输入指定时间");
          return;
        }
        callback(valid);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-types {
  display: flex;
  align-items: center;
}
.tip-item {
  border: 1px solid rgba(220, 223, 230, 1);
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 2px;
  cursor: pointer;
  &.active {
    border-color: rgba(56, 112, 234, 1);
  }
}
.tip-icon {
  font-size: 20px;
}
.tip-text {
  margin-left: 5px;
}
</style>
