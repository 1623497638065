
import { companyEmployeeList, employeeSearch } from "@/api/company";
import { departmentList } from "@/api/directoryConfig";
import { contactListPage, addContact } from "@/api/contact";
import { groupList } from "@/api/group";

import { departmentListByUser, departmentTree } from "@/api/department";
/**
 * 用户组件proider
 */
export const userMixin = {
  provide() {
    return {
      departmentListByUser,
      departmentTree,
      groupList,
      contactListPage,
      departmentList,
      addContact,
      companyEmployeeList,
      employeeSearch(params) {
        return employeeSearch({
          ...params,
          companyId: this.$store.state.user.currentCompanyId,
        });
      },
      getCurrentUser() {
        return this.$store.state.user;
      },
      loadCompany: () => {
        return Promise.resolve([
          {
            name: this.$store.state.user.currentCompanyName,
            key: "company-" + this.$store.state.user.currentCompanyId,
            nodeType: "company",
            isLeaf: false,
            id: this.$store.state.user.currentCompanyId,
          },
        ]);
      }
    }

  }
}


