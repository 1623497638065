var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowAttrValue)?_c('div',{staticClass:"attr-row"},[_c('div',{staticClass:"attr-label"},[_vm._v(_vm._s(_vm.attrTitle))]),(_vm.parentLink || _vm.hasFrontEvent)?_c('div',{staticClass:"input-tip"},[(_vm.parentLink)?_c('span',[_vm._v("以子表单联动为准，不支持设置默认值")]):_vm._e(),(_vm.hasFrontEvent)?_c('span',[_vm._v("字段由前端事件赋值，无法设置公式联动")]):_vm._e()]):[_c('el-select',{staticStyle:{"margin-bottom":"5px","width":"100%"},attrs:{"placeholder":"","size":"mini","clearable":_vm.clearable},model:{value:(_vm.valueType),callback:function ($$v) {_vm.valueType=$$v},expression:"valueType"}},_vm._l((_vm.valueTypeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),(_vm.valueType == 'custom')?[(
          ['input', 'text_area', 'mobile_input'].includes(
            _vm.component.componentName,
          )
        )?_c('el-input',{attrs:{"size":"mini","placeholder":"","type":_vm.component.componentName == 'text_area' ? 'textarea' : ''},model:{value:(_vm.component.defaultValue),callback:function ($$v) {_vm.$set(_vm.component, "defaultValue", $$v)},expression:"component.defaultValue"}}):(_vm.component.componentName == 'input_number')?_c('el-input',{staticStyle:{"width":"100%"},attrs:{"size":"mini","placeholder":""},on:{"input":_vm.inputNumber},model:{value:(_vm.numValue),callback:function ($$v) {_vm.numValue=$$v},expression:"numValue"}}):(_vm.component.componentName == 'position_input')?_c('el-select',{staticStyle:{"width":"100%"},attrs:{"size":"mini","placeholder":"","clearable":""},model:{value:(_vm.component.defaultCurrentPosition),callback:function ($$v) {_vm.$set(_vm.component, "defaultCurrentPosition", $$v)},expression:"component.defaultCurrentPosition"}},[_c('el-option',{attrs:{"value":true,"label":"自动获取地址"}})],1):(_vm.component.componentName == 'date_picker')?_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":_vm.component.picker == 'date_minute' ? 'datetime' : _vm.component.picker,"size":"mini"},model:{value:(_vm.component.defaultValue),callback:function ($$v) {_vm.$set(_vm.component, "defaultValue", $$v)},expression:"component.defaultValue"}}):(_vm.component.componentName == 'address_input')?_c('attr-address-value',{attrs:{"component":_vm.component,"areaTree":_vm.areaTree}}):(
          ['user_select', 'user_list_select'].includes(
            _vm.component.componentName,
          )
        )?_c('user-select',{attrs:{"tabs":['company'],"multi":_vm.component.componentName == 'user_list_select',"dataFilter":_vm.userSelectFieldDataFilter,"size":"small"},model:{value:(_vm.userValue),callback:function ($$v) {_vm.userValue=$$v},expression:"userValue"}}):(
          ['department_select', 'department_list_select'].includes(
            _vm.component.componentName,
          )
        )?_c('dept-select',{attrs:{"multi":_vm.component.componentName == 'department_list_select',"size":"small"},model:{value:(_vm.deptValue),callback:function ($$v) {_vm.deptValue=$$v},expression:"deptValue"}}):_vm._e()]:[(_vm.isReferceField)?_c('el-input',{attrs:{"disabled":"","value":"字段由关联数据赋值，无法设置公式联动","placeholder":"","size":"mini"}}):(_vm.hasFrontEvent)?_c('div',{staticClass:"disabled-message"},[_vm._v(" 字段由前端事件赋值，无法设置公式联动 ")]):(_vm.valueType == 'rely')?[_c('el-input',{attrs:{"value":_vm.hasRely ? '已设置公式' : 'fx编辑公式',"readonly":"","suffix-icon":"el-icon-edit-outline","size":"mini"},nativeOn:{"click":function($event){return _vm.openFormula.apply(null, arguments)}}}),_c('formula-dialog',{ref:"relyDialog",attrs:{"title":_vm.component.title,"originalFormula":_vm.component.rely.originalFormula,"name":_vm.component.name,"treeNodeList":_vm.originComponents,"fetchMetaFieldComponentList":_vm.fetchMetaFieldComponentList,"isJsonForm":!!_vm.parentField,"jsonFormName":_vm.parentField ? _vm.parentField.name : ''},on:{"save":_vm.saveRely}})]:(_vm.valueType == 'dataLinkage')?_c('data-linkage',{attrs:{"form":_vm.component,"componentList":_vm.originComponents,"areaTree":_vm.areaTree}}):_vm._e()]],(
      _vm.component.componentName === 'user_select' ||
      _vm.component.componentName === 'user_list_select'
    )?[_c('attrUserRange',{attrs:{"fieldList":_vm.fieldList,"dataFilter":_vm.userSelectFieldDataFilter,"params":_vm.filterParams},on:{"updateDataFilter":_vm.updateDataFilter}})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }