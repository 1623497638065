import request from '@/utils/request'

import qs from "qs"


/**
 * 部门
 */

/**
 * 分页列表
 */
export function departmentList(data) {
    return request({
        url:  '/department/list',
        method: 'POST',
        data: qs.stringify(data)
    })
}
/**
 * 保存新建部门
 */
export function departmentSave(data) {
    return request({
        url:  '/department/save',
        method: 'POST',
        data: qs.stringify(data)
    })
}
/**
 * 删除部门
 */
export function departmentDelete(data) {
    return request({
        url:  '/department/delete',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 * 获取部门树
 */
export function departmentTree(data) {
    return request({
        url:  '/company/department/tree',
        method: 'POST',
        data
    })
}

/**
 * 获取当前用户所处单位的部门列表
 */
export function departmentListByUser() {
    return request({
        url:  '/department/listByUser',
        method: 'POST',
    })
}

// 获取登录用户所管理的单位列表接口
export function listMyCompany(data) {
    return request({
        url:  '/operatorCompany/listMyCompany',
        method: 'POST',
        data
    })
}
