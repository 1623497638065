<template>
  <div class="app-view-select__container" style="">
    <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
    <el-tree
      :data="treeData"
      :expand-on-click-node="true"
      :highlight-current="true"
      :indent="10"
      :props="workDirTreeProps"
      class="my-plan-tree"
      node-key="id"
      :filter-node-method="filterNode"
      ref="workDirTreeRef"
      @node-click="handleWorkDirTreeClick"
    >
      <span
        slot-scope="{ node, data }"
        class="custom-tree-node"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        "
      >
        <span :title="data.title" class="node-label">
          <template v-if="enabledNewLayout && data.dataType === 'folder'">
            <div
              v-if="Object.keys(data.param).length != 0 && data.param.iconStyle"
              class="icon-div"
              :style="'background-color:' + data.param.iconStyle.color"
            >
              <i
                class="iconfont"
                :class="data.param.iconStyle.className"
                style="color: #fff; font-size: 14px; margin-right: 0"
              ></i>
            </div>
            <div v-else class="icon-div" style="background-color: #3870ea">
              <i
                class="iconfont icon-briefcase-fill"
                style="color: #fff; font-size: 14px; margin-right: 0"
              ></i>
            </div>
          </template>
          <template v-else>
            <svg-icon
              v-if="data.dataType === 'folder'"
              iconClass="wenjianjia"
              style="margin-right: 5px; font-size: 16px"
            ></svg-icon>
            <i
              v-else-if="
                data.dataType === 'resource' &&
                data.param.componentName === 'table'
              "
              style="color: rgb(178, 154, 232)"
              class="iconfont icon-biaodanqun"
            ></i>
            <i
              v-else-if="
                data.dataType === 'resource' &&
                data.param.componentName === 'plan_form_view'
              "
              style="color: rgb(74, 18, 199)"
              class="iconfont icon-biaodanqun"
            ></i>
            <i
              v-else-if="
                data.dataType === 'resource' &&
                data.param.componentName === 'dashboard'
              "
              class="iconfont icon-yibiaopan"
              style="color: #52c41a"
            ></i>
          </template>
          <span>{{ data.title }}</span>
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
export default {
  name: "app-view-select",
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: "",
      workDirTreeData: [],
      workDirTreeProps: {
        label: "name",
        children: "directoryList",
        isLeaf: function (data, node) {
          return !data.hasChildList;
        },
      },
      enabledNewLayout: false,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.workDirTreeRef.filter(val);
    },
  },
  methods: {
    handleWorkDirTreeClick(data, node, el) {
      if (data.dataType === "resource") {
        this.$emit("select", data);
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
  },
};
</script>

<style scoped lang="scss">
.app-view-select__container {
  width: 300px;
  max-height: 400px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .my-plan-tree {
    overflow: auto;
  }
}
</style>
