<template>
  <div>
    <!-- 配色方案 -->
    <div>
      <el-popover
        placement="bottom"
        width="280"
        popper-class="color-select"
        trigger="click"
      >
        <div class="cont-div">
          <div
            class="content"
            v-for="item in colorList"
            :key="item.value"
            :style="
              myColorName == item.name
                ? 'background-color: var(--zgg-background-base)'
                : ''
            "
            @click="selectVal(item)"
          >
            <div style="width: 25%">{{ item.name }}</div>
            <div
              class="content-item"
              :style="'background-color:' + sub"
              v-for="(sub, index) in item.bg"
              :key="index"
            ></div>
          </div>
        </div>

        <div class="select-div" slot="reference" @click="showSelect()">
          <div class="input-div">
            {{ myColorName }}
            <!-- <div
              class="content-item"
              :style="'background-color:' + sub"
              v-for="(sub, index) in myColorList"
              :key="index"
            ></div> -->
          </div>
          <i class="el-icon-arrow-down"></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { chartColorList } from "@/views/lowCode/view/components/chartUtil";
export default {
  name: "schemeColorSelect",
  model: {
    event: "updateColor",
  },
  props: {
    chartType: {
      type: String,
      default: "chart",
    },
    colorConfig: {
      type: Object,
    },
  },
  data() {
    return {
      colorList: [],
      myColorList: {},
      show: false,
      myColor: "",
      colorArr: [],
      myColorName: "",
    };
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  created() {
    this.colorList = chartColorList;
    if (this.colorConfig) {
      let arr = [];
      arr = this.colorList.find(
        (item) => item.value == this.colorConfig?.colorScheme,
      );
      if (arr) {
        this.myColorList = arr.bg;
        this.myColorName = arr.name;
      }
    }
  },

  methods: {
    reset() {
      if (this.chartType == "table") {
        this.myColor = "#000000";
      } else {
        this.myColor = "#FFFFFF";
      }

      this.$emit("updateColor1", this.myColor, this.chartType);
    },
    //更改popup值
    //点击外层关闭显示框
    close() {
      this.show = false;
    },
    //点击自身显示框仍显示
    closestop() {
      this.show = true;
    },
    bodyCloseMenus(e) {
      let self = this;
      // console.log(
      //   e.target.className,
      //   !e.target.className.includes("selectTextarea"),
      // );
      if (e.target.className.includes("attribute-box")) {
        self.show = false;
      }
    },
    showSelect() {
      this.show = !this.show;
      this.$emit("showSelect");
    },

    selectVal(item) {
      this.myColorList = item?.bg;
      this.myColorName = item.name;
      //  this.show=false;
      this.$emit("updateColor", item.value);
    },
  },
  beforeDestroy() {
    this.show = false;
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="scss" scoped>
.select-div {
  cursor: pointer;
  // width: 258px;
  height: 32px;
  border: 1px solid#DFE1E6;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  padding: 0 2px;
  // width: 96%;
  .input-div {
    //width: 189px;
    display: flex;
    flex-direction: row;
    margin: auto;
  }
}
.cont-div {
  // border: 1px solid #dfe1e6;
  // padding: 4px;
  // border-radius: 5px;
  max-height: 480px;
  overflow: auto;
  margin: auto;
  .content {
    border: 1px solid #fff;
    display: flex;
    flex-direction: row;
    padding: 4px 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.content-item {
  width: 18px;
  height: 22px;
  display: block;
}

.father .el-icon-close {
  display: none;
  margin-right: 4px;
}
.el-icon-arrow-down {
  margin-right: 4px;
}
.father .el-icon-arrow-down {
  display: block;
}

.father:hover .el-icon-close {
  display: block;
}
.father:hover .el-icon-arrow-down {
  display: none;
}
</style>
