<template>
  <div>
    <div class="attr-row">
      <!-- {{component}} -->
      <div class="attr-label">样式选择</div>
      <div class="select-div1 select1" @click="showSelect2()">
        <!-- <div class="input-div1" :style="('background-color:'+ component.lineType)" >
     </div> -->
        <img
          class="select1"
          v-if="component.lineType == 'horizontal'"
          src="@/assets/divider/style1.png"
          alt=""
        />
        <img
          class="select1"
          v-if="component.lineType == 'style2'"
          src="@/assets/divider/style2.png"
          alt=""
        />
        <img
          class="select1"
          v-if="component.lineType == 'style3'"
          src="@/assets/divider/style3.png"
          alt=""
        />
        <img
          class="select1"
          v-if="component.lineType == 'style4'"
          src="@/assets/divider/style4.png"
          alt=""
        />
        <img
          class="select1"
          v-if="component.lineType == 'vertical'"
          src="@/assets/divider/style5.png"
          alt=""
        />
        <img
          class="select1"
          v-if="component.lineType == 'style6'"
          src="@/assets/divider/style6.png"
          alt=""
        />
        <i class="el-icon-arrow-down"></i>
      </div>
      <div v-if="show2" class="cont-div-img select1">
        <img
          class="select1"
          :class="component.lineType == 'horizontal' ? 'boder' : ''"
          @click="selectVal2('horizontal')"
          src="@/assets/divider/style1.png"
          alt=""
        />
        <img
          class="select1"
          :class="component.lineType == 'style6' ? 'boder' : ''"
          @click="selectVal2('style6')"
          src="@/assets/divider/style6.png"
          alt=""
        />
        <img
          class="select1"
          :class="component.lineType == 'style2' ? 'boder' : ''"
          @click="selectVal2('style2')"
          src="@/assets/divider/style2.png"
          alt=""
        />
        <img
          class="select1"
          :class="component.lineType == 'style3' ? 'boder' : ''"
          @click="selectVal2('style3')"
          src="@/assets/divider/style3.png"
          alt=""
        />
        <img
          class="select1"
          :class="component.lineType == 'style4' ? 'boder' : ''"
          @click="selectVal2('style4')"
          src="@/assets/divider/style4.png"
          alt=""
        />
        <img
          class="select1"
          :class="component.lineType == 'vertical' ? 'boder' : ''"
          @click="selectVal2('vertical')"
          src="@/assets/divider/style5.png"
          alt=""
        />
      </div>
      <!-- <el-select
        v-model="component.titleAlign"
        placeholder=""
        style="width: 100%"
        size="mini"
      >
        <el-option label="左" value="left"></el-option>
        <el-option label="中" value="center"></el-option>
        <el-option label="右" value="right"></el-option>
      </el-select> -->
    </div>
    <div class="attr-row">
      <div class="attr-label">主题颜色</div>
      <div class="select-div select3" @click="showSelect()">
        <div
          class="input-div1 select3"
          :style="'background-color:' + component.lineColor"
        ></div>
        <i class="el-icon-arrow-down select3"></i>
      </div>
      <div v-if="show" class="cont-div select3">
        <div
          v-for="item in colorList"
          :key="item"
          class="content-item1 select3"
          :style="'text-align:center;background-color:' + item"
          @click="selectVal(item)"
        >
          <i
            v-if="component.lineColor == item"
            class="el-icon-check"
            :style="`color:${
              item == '#ffffff' ? '#000' : '#fff'
            };line-height:30px;`"
          ></i>
        </div>
      </div>
    </div>
    <div class="attr-row">
      <div class="attr-label">标题颜色</div>
      <div class="select-div select2" @click="showSelect1()">
        <div
          class="input-div1 select2"
          :style="'background-color:' + component.titleAlign"
        ></div>
        <i class="el-icon-arrow-down select2"></i>
      </div>
      <div v-if="show1" class="cont-div select2">
        <div
          v-for="item in colorList1"
          :key="item"
          class="content-item1 select2"
          :style="'text-align:center;background-color:' + item"
          @click="selectVal1(item)"
        >
          <i
            v-if="component.titleAlign == item"
            class="el-icon-check"
            :style="`color:${
              item == '#ffffff' ? '#000' : '#fff'
            };line-height:30px;`"
          ></i>
        </div>
      </div>
      <!-- <el-select
        v-model="component.lineType"
        placeholder=""
        style="width: 100%"
        size="mini"
      >
        <el-option label="水平" value="horizontal"></el-option>
        <el-option label="垂直" value="vertical"></el-option>
      </el-select> -->
    </div>

    <!-- <div  class="attr-row">
      <el-checkbox v-model="component.dashed">是否虚线</el-checkbox>
    </div>
    <div class="attr-row">
      <div class="attr-label">线条颜色</div>
      <el-color-picker
        v-model="component.lineColor"
        size="mini"
      ></el-color-picker>
    </div> -->
  </div>
</template>
<script>
import { componentProps } from "./util";
export default {
  name: "attr-divider",
  props: componentProps,
  data() {
    return {
      colorList1: ["#101840", "#ffffff"],
      show1: false, //标题颜色
      colorList: [
        "#D8DAE5",
        "#3870EA",
        "#52BD94",
        "#25CBD6",
        "#DE7548",
        "#D14343",
        "#101840",
        "#0C56D0",
        "#317159",
        "#10899E",
        "#B25E3A",
        "#A73636",
      ],
      show: false, //主题颜色
      show2: false, //样式选择
    };
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    showSelect1() {
      // 标题颜色
      this.show1 = !this.show1;
      this.show = false;
      this.show2 = false;
    },
    selectVal1(sub) {
      this.component.titleAlign = sub;
      //  this.show=false;
    },
    showSelect() {
      //主题颜色
      this.show = !this.show;
      this.show1 = false;
      this.show2 = false;
    },
    selectVal(sub) {
      this.component.lineColor = sub;
    },
    showSelect2() {
      //样式选择
      this.show2 = !this.show2;
      this.show = false;
      this.show1 = false;
    },
    selectVal2(sub) {
      this.component.lineType = sub;
      //  this.show=false;
    },
    bodyCloseMenus(e) {
      let self = this;
      console.log(e.target.className, e.target.className.includes("select1"));
      if (!e.target.className.includes("select1")) {
        self.show2 = false;
      }
      if (!e.target.className.includes("select2")) {
        self.show1 = false;
      }
      if (!e.target.className.includes("select3")) {
        self.show = false;
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>
<style lang="scss" scoped>
.select-div {
  cursor: pointer;
  // width: 258px;
  height: 36px;
  border: 1px solid#DFE1E6;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;

  .input-div {
    width: 189px;
    display: flex;
    flex-direction: row;
  }
}
.cont-div {
  border: 1px solid #dfe1e6;
  padding: 4px;
  border-radius: 5px;
  //  width:90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .content {
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
  }
}
.content-item {
  width: 22px;
  height: 22px;
  display: block;
}
.content1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.input-div1 {
  height: 22px;
  width: 189px;
}
.content-item1 {
  margin: 4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ebecf0;
}
.cont-div-img {
  border: 1px solid #dfe1e6;
  padding: 4px;
  border-radius: 5px;
  //  width:90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 203px;
    height: 60px;
    margin: 5px;
    // width: 100%;
    border: 1px solid #dfe1e6;
  }
}
.boder {
  border: 1px solid rgb(56, 112, 234) !important;
}
.select-div1 {
  cursor: pointer;
  // width: 258px;
  // height: 36px;
  border: 1px solid#DFE1E6;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  img {
    height: 60px;
    width: 203px;
    margin: 12px;
  }
  .input-div {
    width: 189px;
    display: flex;
    flex-direction: row;
  }
}
</style>
