<template>
  <div
    class="field-item"
    :class="isDeleteField(node) ? 'is-delete' : ''"
    @mouseenter="mouseenter"
  >
    <template v-if="node">
      <div @mousedown="mousedown" class="field-move">
        <el-tooltip effect="dark" :content="node.comment" placement="top">
          <div class="field-title" v-text="node.comment"></div>
        </el-tooltip>
      </div>
      <i @click.prevent.stop="delItem" class="el-icon-close field-del"></i>
    </template>
    <div
      v-else-if="
        dragField &&
        dragField.index == index &&
        dragField.collection == collection
      "
      class="field-move"
    >
      <div class="field-title" v-text="dragField.comment"></div>
    </div>
    <el-popover v-else class="add-column" v-model="visible">
      <el-button
        slot="reference"
        style="width: 130px; text-align: center"
        type="text"
        icon="el-icon-plus"
        >添加字段</el-button
      >
      <div v-clickoutside="clickoutside" class="field-scroll">
        <div
          v-for="item in componentList"
          :key="item.name"
          v-text="item.comment"
          class="field-li"
          @click="changeStateField(item)"
        ></div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
let arr = ["input", "radio_group", "select", "sn_input"];
let arr2 = ["checkbox_group", "select_checkbox"];
let arr3 = ["reference_data", "cascade_data"];
export default {
  props: {
    unionField: Object,
    index: Number,
    dragField: Object,
    fromNode: Object,
    unionFields: Array,
    isDeleteField: Function,
  },

  directives: { Clickoutside },
  computed: {
    node() {
      return this.unionField.stageFields.find(
        (item) => item.collection == this.collection,
      );
    },
    collection() {
      return this.fromNode.key;
    },
    componentList() {
      // let list = this.unionFields
      let list = [];
      this.unionFields.forEach((item) => {
        item.stageFields
          .filter((col) => col.collection == this.collection)
          .forEach((row) => {
            list.push(row.name);
          });
      });

      let componentList = this.fromNode.fields.filter(
        (item) => !list.includes(item.name),
      );

      if (arr.includes(this.unionField.field.componentName)) {
        componentList = componentList.filter((item) =>
          arr.includes(item.componentName),
        );
      } else if (arr2.includes(this.unionField.field.componentName)) {
        componentList = componentList.filter((item) =>
          arr2.includes(item.componentName),
        );
      } else if (arr3.includes(this.unionField.field.componentName)) {
        componentList = componentList.filter((item) =>
          arr3.includes(item.componentName),
        );
      } else {
        componentList = componentList.filter(
          (item) => item.componentName == this.unionField.field.componentName,
        );
      }

      if (
        ["reference_data", "cascade_data"].includes(
          this.unionField.field.componentName,
        )
      ) {
        // 关联数据、级联数据必须同源
        componentList = componentList.filter(
          (item) =>
            item.component.tableName ==
            this.unionField.field.component.tableName,
        );
      }

      return componentList;
    },
  },
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    changeStateField(field) {
      this.unionField.stageFields.push(this._.cloneDeep(field));
      this.unionField.field.comment = field.comment;
      this.visible = false;
      this.$emit("changeChildrenData");
    },
    delItem() {
      let index = this.unionField.stageFields.findIndex(
        (item) => item.collection == this.collection,
      );
      this.$delete(this.unionField.stageFields, index);
      if (this.unionField.stageFields.length == 0) {
        this.$emit("deleteNode", this.index);
      }
      this.$emit("changeChildrenData");
    },

    clickoutside() {
      this.visible = false;
    },
    mouseenter() {
      if (!this.dragField) {
        // 不存在拖拽对象

        return;
      }
      if (this.dragField.collection != this.collection) {
        // 不是同源，不能跨行拖拽
        return;
      }

      if (this.node) {
        return;
      }

      this.dragField.index = this.index;

      let componentName = this.unionField.field.componentName;
      if (
        arr.includes(componentName) &&
        arr.includes(this.dragField.componentName)
      ) {
        this.dragField.insert = true;
      } else if (
        arr2.includes(componentName) &&
        arr2.includes(this.dragField.componentName)
      ) {
        this.dragField.insert = true;
      } else if (componentName == this.dragField.componentName) {
        this.dragField.insert = true;
      } else {
        this.dragField.insert = false;
      }

      if (
        arr3.includes(componentName) &&
        arr3.includes(this.dragField.componentName)
      ) {
        this.dragField.insert =
          this.unionField.field.component.tableName ==
          this.dragField.component.tableName;
      }
    },

    mousedown(event) {
      let rect = this.$el.getBoundingClientRect();
      let $dom = this.$el.cloneNode(true);
      $dom.style = `top:${rect.top}px;left:${rect.left}px;`;
      $dom.classList.add("drag-field");
      document.body.appendChild($dom);

      this.move = {
        x: event.clientX,
        y: event.clientY,
        top: rect.top,
        left: rect.left,
      };
      this.$dom = $dom;
      document.addEventListener("mousemove", this.mousemove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.mouseup, {
        passive: false,
      });
      let index = this.unionField.stageFields.findIndex(
        (item) => item.collection == this.collection,
      );
      let dragField = this._.cloneDeep(this.node);
      dragField.index = -1;
      dragField.old = this.index;
      dragField.insert = false;
      this.$emit("update:dragField", dragField);

      this.$delete(this.unionField.stageFields, index);
    },
    mousemove(event) {
      event.preventDefault();
      event.stopPropagation();
      let x = event.clientX - this.move.x;
      let y = event.clientY - this.move.y;
      let left = this.move.left + x;
      let top = this.move.top + y;
      this.$dom.style.left = left + "px";
      this.$dom.style.top = top + "px";
    },
    mouseup(event) {
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.$dom.remove();
      if (this.dragField) {
        let field = this._.cloneDeep(this.dragField);
        let index = this.dragField.index;
        let old = this.dragField.old;
        delete field.index;
        delete field.insert;
        delete field.old;
        if (!(this.dragField.insert && index >= 0)) {
          index = old;
        }
        this.$emit("insertNode", { index, field, old });
      }
      this.$emit("update:dragField");
      this.$emit("changeChildrenData");
    },
  },
};
</script>
<style lang="scss" scoped>
.field-item {
  width: 130px;
  height: 50px;
  position: relative;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-left: 0;
  overflow: hidden;
  font-size: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #f4f6f9;
  &.drag-field {
    border: 0;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
    pointer-events: none;
    position: absolute;
  }
  .field-del {
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 18px;
    display: none;
    cursor: pointer;
  }
  .add-column {
    display: none;
  }
  &:hover {
    .field-del {
      display: block;
    }
    .add-column {
      display: block;
    }
  }
  &.is-delete {
    .field-move {
      //   border-color: #f56c6c;
      color: #f00;
      background-color: rgba($color: #f56c6c, $alpha: 0.1);
    }
  }
}
.field-move {
  background-color: #e8f7f6;
  width: 130px;
  height: 50px;
  padding: 0 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: move;
}
.field-title {
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.field-scroll {
  background-color: #ffffff;
  max-height: 150px;
  overflow: auto;
  .field-li {
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
