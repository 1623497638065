<template>
  <div class="var-container" style="margin-right: 10px">
    <div class="var-font">可用变量</div>
    <div class="var-field-body">
      <div class="border-bottom">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 100%"
          v-model="keyword"
          @input="changeKeyword"
          class="no-border"
        >
        </el-input>
      </div>
      <div class="flex-scroll">
        <el-tree
          ref="tree"
          :default-expand-all="true"
          :data="columns"
          :props="treeProps"
          @node-click="nodeClick"
          node-key="key"
          :filter-node-method="filterNode"
        >
          <div class="rely-item" slot-scope="{ node, data }">
            <div
              class="rely-text"
              v-text="node.label"
              :title="node.label"
            ></div>
            <div
              v-if="node.isLeaf && !data.components"
              class="rely-tip"
              :class="getClass(data)"
              v-text="getClassText(data)"
            ></div>
          </div>
        </el-tree>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import { getClass, getClassText } from "./formula";
export default {
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    isJsonForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
    jsonFormName: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      keyword: "",
      treeProps: {
        children: "components",
        label: "title",
      },
    };
  },
  methods: {
    getClass,
    getClassText(obj) {
      let isJsonForm = false;
      let componentName = obj && obj.parent && obj.parent.componentName;
      if (componentName == "json_form") {
        isJsonForm = obj.parent.name == this.jsonFormName;
      }
      return getClassText(obj, isJsonForm);
    },
    filterNode(value, data) {
      if (isEmpty(value)) {
        return true;
      }
      return data.title.indexOf(value) !== -1;
    },
    changeKeyword(v) {
      this.$refs.tree.filter(v);
    },
    nodeClick(data, node) {
      if (node.isLeaf && !data.components) {
        this.$emit("insert", data, node);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.no-border {
  ::v-deep {
    .el-input__inner {
      border: 0;
    }
  }
}
.var-font {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
}
.var-container {
  width: 220px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.var-field-body {
  flex: 1;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
}
.rely-item {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex: 1;
  margin-right: 10px;
  // &:hover {
  //   background-color: #e6f6f4;
  // }
  .rely-text {
    flex: 1;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rely-tip {
    padding: 2px 4px;
    border-radius: 2px;
  }
  .text-tip {
    color: #248af9;
    background: rgba(36, 138, 249, 0.1);
  }
  .number-tip {
    color: #f4b700;
    background: rgba(244, 183, 0, 0.15);
  }
  .date-tip {
    color: #8e24f9;
    background: rgba(142, 36, 249, 0.15);
  }
  .array-tip {
    color: #dc38dc;
    background: rgba(220, 56, 220, 0.15);
  }
  .address-tip {
    color: #05d1d1;
    background: rgba(5, 209, 209, 0.15);
  }
}
.flex-scroll {
  flex: 1;
  overflow: auto;
}
.border-bottom {
  border-bottom: solid 1px #e0e0e0;
}
</style>
