<template>
  <div ref="etlContainer" class="etl-container">
    <div class="etl-config-menu">
      <div style="padding: 12px">
        <div style="line-height: 36px; font-size: 14px">输入输出</div>
        <div
          v-for="item in sourceList.filter((col) =>
            ['input', 'output'].includes(col.stageType),
          )"
          :key="item.stageType"
          class="menu-item"
          :class="
            etlList.findIndex((col) => col.stageType == 'output') >= 0 &&
            item.stageType == 'output'
              ? 'no-drag'
              : ''
          "
          @mousedown="mousedown(item, $event)"
        >
          <i :class="`iconfont-fx-pc icon-data-${item.stageType}`"></i>
          <span v-text="item.title"></span>
        </div>
        <div style="line-height: 36px; font-size: 14px; margin-top: 15px">
          数据处理
        </div>
        <div
          v-for="item in sourceList.filter(
            (col) => !['input', 'output'].includes(col.stageType),
          )"
          :key="item.stageType"
          class="menu-item"
          :class="item.stageType == 'output' ? 'no-drag' : ''"
          @mousedown="mousedown(item, $event)"
        >
          <i :class="`iconfont-fx-pc icon-data-${item.stageType}`"></i>
          <span v-text="item.title"></span>
        </div>
      </div>
    </div>
    <div class="etl-body">
      <div class="etl-header">
        <div class="etl-header-tool">
          <div
            @click="undo"
            class="icon-action"
            :class="undoList.length == 0 ? 'disabled' : ''"
          >
            <i class="iconfont-fx-pc icon-undo"></i>
          </div>
          <div
            @click="redo"
            class="icon-action"
            :class="redoList.length == 0 ? 'disabled' : ''"
          >
            <i class="iconfont-fx-pc icon-redo"></i>
          </div>
          <div class="x-divider"></div>
          <el-popover
            v-model="visible"
            popper-class="etl-pop"
            placement="bottom"
            trigger="click"
          >
            <div class="pop-wrapper">
              <div class="align-item" @click="setAlignment('left')">
                <i class="iconfont-fx-pc icon-align-left-thick"></i
                ><span>左对齐</span>
              </div>
              <div class="align-item" @click="setAlignment('center')">
                <i class="iconfont-fx-pc icon-align-center-thick"></i
                ><span>水平剧中对齐</span>
              </div>
              <div class="align-item" @click="setAlignment('right')">
                <i class="iconfont-fx-pc icon-align-right-thick"></i
                ><span>右对齐</span>
              </div>
              <div class="divider"></div>
              <div class="align-item" @click="setAlignment('top')">
                <i class="iconfont-fx-pc icon-vertical-align-top-thick"></i
                ><span>顶部对齐</span>
              </div>
              <div class="align-item" @click="setAlignment('middle')">
                <i class="iconfont-fx-pc icon-vertical-align-middle-thick"></i
                ><span>垂直剧中对齐</span>
              </div>
              <div class="align-item" @click="setAlignment('bottom')">
                <i class="iconfont-fx-pc icon-vertical-align-bottom-thick"></i
                ><span>底部对齐</span>
              </div>
            </div>
            <div class="btn-action" slot="reference">
              <i class="iconfont-fx-pc icon-align-left-thick"></i
              ><span>对齐方式</span>
            </div>
          </el-popover>
          <el-popover
            v-model="visible2"
            popper-class="etl-pop"
            placement="bottom"
            trigger="click"
          >
            <div class="pop-wrapper">
              <div class="align-item" @click="setDirection('horizontal')">
                <i class="iconfont-fx-pc icon-horizontal-isometric-thick"></i
                ><span>水平等距分布</span>
              </div>

              <div class="align-item" @click="setDirection('vertical')">
                <i class="iconfont-fx-pc icon-vertical-isometric-thick"></i
                ><span>垂直等距分布</span>
              </div>
            </div>
            <div class="btn-action" slot="reference">
              <i class="iconfont-fx-pc icon-horizontal-isometric-thick"></i
              ><span>分布方式</span>
            </div>
          </el-popover>
          <div class="x-divider"></div>
          <div
            @click="addZoom(-0.1)"
            class="icon-action"
            :class="zoom <= 0.5 ? 'disabled' : ''"
          >
            <i class="el-icon-minus" style="color: #248af9"></i>
          </div>
          <div style="width: 60px; font-size: 14px; text-align: center">
            {{ parseInt(zoom * 100, 10) }}%
          </div>
          <div
            @click="addZoom(0.1)"
            class="icon-action"
            :class="zoom >= 1.5 ? 'disabled' : ''"
          >
            <i class="el-icon-plus" style="color: #248af9"></i>
          </div>
          <div class="x-divider"></div>
          <div @click="deleteNodes" class="btn-action delete">
            <i class="iconfont-fx-pc icon-trash"></i>
          </div>
        </div>
        <el-button type="primary" @click="save" size="small">保存</el-button>
      </div>
      <etl-pane
        ref="etlPane"
        :zoom="zoom"
        :etl-list="etlList"
        :etlLines="etlLines"
        :checkLines.sync="checkLines"
        :areaTree="areaTree"
        :areaProps="areaProps"
        :errorKeys="errorKeys"
        @deleteNodes="deleteNodes"
        @setUndo="setUndo"
      ></etl-pane>
    </div>
    <div
      v-if="copyItem"
      class="etl-item"
      :style="`left:${copyItem.left}px;top:${copyItem.top}px;`"
    >
      <i :class="`iconfont-fx-pc icon-data-${copyItem.stageType}`"></i>
      <div class="node-title" v-text="copyItem.title"></div>
    </div>
  </div>
</template>
<script>
import sourceList from "./sourceList";
import { changeChildrenData, chkNodeConfig, isExistFun } from "./util";
import EtlPane from "./EtlPane";

export default {
  components: { EtlPane },
  name: "EtlEdit",
  inject: ["saveDataflow"],
  props: {
    etlList: {
      type: Array,
      default() {
        return [];
      },
    },
    etlLines: {
      type: Array,
      default() {
        return [];
      },
    },
    areaProps: Object,
    areaTree: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      zoom: 1,
      undoList: [],
      redoList: [],
      checkLines: [],
      sourceList,
      copyItem: null,
      moveObj: null,
      visible: false,
      visible2: false,
      errorKeys: [],
    };
  },
  created() {
    if (this.etlList.length == 0) {
      this.etlList.push({
        key: this.getUuid(),
        title: "输入",
        stageType: "input",
        left: 20,
        top: 20,
        checked: false,
      });
      this.etlList.push({
        key: this.getUuid(),
        title: "输出",
        stageType: "output",
        left: 400,
        top: 20,
        checked: false,
      });
    }
  },
  methods: {
    isExistFun,
    save() {
      this.isExistFun("saveDataflow", "保存数据工厂配置的方法", false);
      let fromKeys = this.etlList
        .filter((item) => item.stageType == "input")
        .map((item) => item.key);
      let toKeys = this.etlList
        .filter((item) => item.stageType == "output")
        .map((item) => item.key);
      let keys = this.etlList
        .filter((item) => !["input", "output"].includes(item.stageType))
        .map((item) => item.key);

      if (fromKeys.length == 0) {
        this.$message.error("当前数据流没有输入节点");
        return;
      }

      if (toKeys.length == 0) {
        this.$message.error("当前数据流没有输出节点");
        return;
      }

      let errorKeys = [];
      errorKeys = errorKeys.concat(
        fromKeys.filter(
          (val) => this.etlLines.findIndex((item) => item.fromKey == val) == -1,
        ),
      );
      errorKeys = errorKeys.concat(
        toKeys.filter(
          (val) => this.etlLines.findIndex((item) => item.toKey == val) == -1,
        ),
      );

      errorKeys = errorKeys.concat(
        keys.filter(
          (val) =>
            this.etlLines.findIndex((item) => item.fromKey == val) == -1 ||
            this.etlLines.findIndex((item) => item.toKey == val) == -1,
        ),
      );

      if (errorKeys.length) {
        this.errorKeys = errorKeys;
        this.$message.error("当前数据流存在孤点或孤立流程");
        return;
      }

      this.etlList.forEach((item) => {
        let error = chkNodeConfig(item, this.etlList, this.etlLines);
        if (error) {
          errorKeys.push(item.key);
        }
      });

      if (errorKeys.length) {
        this.errorKeys = errorKeys;
        this.$message.error("请完善节点配置");
        return;
      }
      this.errorKeys = errorKeys;

      this.etlList.forEach((item) => {
        delete item.checked;
      });
      let list = [];
      this.etlList.forEach((item) => {
        if (item.stageType == "input") {
          list.push(item);
        }
      });
      changeChildrenData(list, this.etlList, this.etlLines);

      this.saveDataflow();
    },
    setUndo(params) {
      if (params) {
        let { etlList, etlLines } = params;
        this.undoList.push({
          etlList: this._.cloneDeep(etlList),
          etlLines: this._.cloneDeep(etlLines),
        });
      } else {
        let etlList = this._.cloneDeep(this.etlList);
        let etlLines = this._.cloneDeep(this.etlLines);
        this.undoList.push({
          etlList,
          etlLines,
        });
      }
      this.redoList = [];
    },
    undo() {
      if (!this.undoList.length) {
        return;
      }
      let obj = this.undoList.pop();
      this.redoList.push({
        etlList: this._.cloneDeep(this.etlList),
        etlLines: this._.cloneDeep(this.etlLines),
      });
      this.$emit("update:etlLines", this._.cloneDeep(obj.etlLines));
      this.$emit("update:etlList", this._.cloneDeep(obj.etlList));
    },
    redo() {
      if (!this.redoList.length) {
        return;
      }
      let obj = this.redoList.pop();
      this.undoList.push({
        etlList: this._.cloneDeep(this.etlList),
        etlLines: this._.cloneDeep(this.etlLines),
      });
      this.$emit("update:etlLines", this._.cloneDeep(obj.etlLines));
      this.$emit("update:etlList", this._.cloneDeep(obj.etlList));
    },
    setAlignment(type) {
      let list = this.etlList.filter((item) => item.checked);
      this.visible = false;
      if (!list.length) {
        return;
      }
      if (type == "left") {
        // 左对齐
        let left = Math.min(...list.map((item) => item.left));
        this.etlList.forEach((item) => {
          if (list.findIndex((col) => item.key == col.key) >= 0) {
            item.left = left;
          }
        });
      } else if (type == "right") {
        // 右对齐
        let left = Math.max(...list.map((item) => item.left));
        this.etlList.forEach((item) => {
          if (list.findIndex((col) => item.key == col.key) >= 0) {
            item.left = left;
          }
        });
      } else if (type == "center") {
        // 水平剧中对齐
        let min = Math.min(...list.map((item) => item.left));
        let max = Math.max(...list.map((item) => item.left));
        let left = (min + max) / 2;
        this.etlList.forEach((item) => {
          if (list.findIndex((col) => item.key == col.key) >= 0) {
            item.left = left;
          }
        });
      } else if (type == "top") {
        // 顶部对齐
        let top = Math.min(...list.map((item) => item.top));
        this.etlList.forEach((item) => {
          if (list.findIndex((col) => item.key == col.key) >= 0) {
            item.top = top;
          }
        });
      } else if (type == "bottom") {
        // 底部对齐
        let top = Math.max(...list.map((item) => item.top));
        this.etlList.forEach((item) => {
          if (list.findIndex((col) => item.key == col.key) >= 0) {
            item.top = top;
          }
        });
      } else if (type == "middle") {
        // 垂直居中对齐
        let min = Math.min(...list.map((item) => item.top));
        let max = Math.max(...list.map((item) => item.top));
        let top = (min + max) / 2;
        this.etlList.forEach((item) => {
          if (list.findIndex((col) => item.key == col.key) >= 0) {
            item.top = top;
          }
        });
      }
    },
    setDirection(type) {
      this.visible2 = false;
      let list = this.etlList.filter((item) => item.checked);
      if (list.length < 3) {
        return;
      }
      if (type == "horizontal") {
        // 水平等距分布
        let min = Math.min(...list.map((item) => item.left));
        let max = Math.max(...list.map((item) => item.left));
        let step = (max - min) / (list.length - 1);
        list = list.sort(function (a, b) {
          return a.left - b.left;
        });
        list.forEach((item, index) => {
          if (index > 0 && index < list.length - 1) {
            item.left = min + index * step;
          }
        });
        this.etlList.forEach((item) => {
          let node = list.find((col) => col.key == item.key);
          if (node) {
            item.left = node.left;
          }
        });
      } else if (type == "vertical") {
        // 垂直等距分布
        let min = Math.min(...list.map((item) => item.top));
        let max = Math.max(...list.map((item) => item.top));
        let step = (max - min) / (list.length - 1);
        list = list.sort(function (a, b) {
          return a.top - b.top;
        });
        list.forEach((item, index) => {
          if (index > 0 && index < list.length - 1) {
            item.top = min + index * step;
          }
        });
        this.etlList.forEach((item) => {
          let node = list.find((col) => col.key == item.key);
          if (node) {
            item.top = node.top;
          }
        });
      }
    },
    handelDelete() {},
    deleteNodes() {
      if (
        this.etlList.findIndex((item) => item.checked) == -1 &&
        this.checkLines.length == 0
      ) {
        return;
      }
      this.setUndo();
      if (this.etlList.findIndex((item) => item.checked) >= 0) {
        let nodes = this.etlList
          .filter((item) => item.checked)
          .map((item) => item.key);
        let etlList = this.etlList.filter((item) => !item.checked);

        let lines = this.etlLines.filter(
          (item) =>
            !nodes.includes(item.fromKey) && !nodes.includes(item.toKey),
        );
        this.$emit("update:etlLines", lines);
        this.$emit("update:etlList", etlList);
      } else if (this.checkLines.length) {
        this.checkLines.forEach((line) => {
          let index = this.etlLines.findIndex(
            (item) => item.fromKey == line.fromKey && item.toKey == line.toKey,
          );
          if (index >= 0) {
            this.$delete(this.etlLines, index);
          }
        });
      }

      this.checkLines = [];
    },

    addZoom(step) {
      let zoom = this.zoom + step;
      zoom = new Number(zoom).toFixed(1);
      zoom = parseFloat(zoom);

      if (zoom < 0.5) {
        zoom = 0.5;
      } else if (zoom > 1.5) {
        zoom = 1.5;
      }
      this.zoom = zoom;
    },
    mousedown(item, event) {
      event.preventDefault();
      event.stopPropagation();

      if (
        item.stageType == "output" &&
        this.etlList.findIndex((col) => col.stageType == "output") >= 0
      ) {
        return;
      }
      let copyItem = this._.cloneDeep(item);
      copyItem.left = event.currentTarget.offsetLeft;
      copyItem.top = event.currentTarget.offsetTop;
      this.copyItem = copyItem;
      this.moveObj = {
        x: event.clientX,
        y: event.clientY,
        left: copyItem.left,
        top: copyItem.top,
      };

      document.addEventListener("mousemove", this.mousemove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.mouseup, {
        passive: false,
      });
    },
    mousemove(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (!this.copyItem) {
        return;
      }

      let isIE9 =
        navigator.appName == "Microsoft Internet Explorer" &&
        parseInt(
          navigator.appVersion
            .split(";")[1]
            .replace(/[ ]/g, "")
            .replace("MSIE", ""),
        ) == 9;
      if (!isIE9 && event.buttons != 1) {
        // 鼠标左键没有按住
        this.mouseup();
        return;
      }
      let x = event.clientX - this.moveObj.x;
      let y = event.clientY - this.moveObj.y;
      let left = this.moveObj.left + x;
      let top = this.moveObj.top + y;
      let minLeft = this.$refs.etlPane.$el.offsetLeft + 20;
      let minTop = this.$refs.etlPane.$el.offsetTop + 20;

      if (this.copyItem.left >= minLeft && this.copyItem.top >= minTop) {
        let scrollLeft = this.$refs.etlPane.$refs.etlScroll.scrollLeft;
        let scrollTop = this.$refs.etlPane.$refs.etlScroll.scrollTop;
        let obj = this.$refs.etlPane.buildBaseLine(
          left - minLeft + 20 + scrollLeft,
          top - minTop + 20 + scrollTop,
        );
        if (obj) {
          if (obj.left) {
            left = obj.left + minLeft - 20 - scrollLeft;
          }
          if (obj.top) {
            top = obj.top + minTop - 20 - scrollTop;
          }
        }
      }

      this.copyItem.left = left;
      this.copyItem.top = top;
    },
    mouseup(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      let minLeft = this.$refs.etlPane.$el.offsetLeft + 20;
      let minTop = this.$refs.etlPane.$el.offsetTop + 20;
      if (this.copyItem.left >= minLeft && this.copyItem.top >= minTop) {
        this.setUndo();
        this.etlList.push({
          ...this.copyItem,
          key: this.getUuid(),
          left:
            this.copyItem.left -
            minLeft +
            20 +
            this.$refs.etlPane.$refs.etlScroll.scrollLeft,
          top:
            this.copyItem.top -
            minTop +
            20 +
            this.$refs.etlPane.$refs.etlScroll.scrollTop,
          checked: false,
          fields: [],
        });
        if (this.copyItem.stageType == "input") {
          this.$refs.etlPane.addSource(this.etlList[this.etlList.length - 1]);
        }
      }

      this.copyItem = null;
      this.$refs.etlPane.clearBaseLine();
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
    },
  },
};
</script>
<style lang="scss" scoped>
i.iconfont-fx-pc {
  margin-right: 8px;
  margin-left: 4px;
  color: #248af9;
  font-size: 16px;
}

.etl-container {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  .etl-config-menu {
    width: 150px;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #e9e9e9;
  }
  .etl-header {
    display: flex;
    height: 50px;
    padding: 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e9e9;
    border-radius: 2px;
    align-items: center;
    justify-content: space-between;
    .etl-header-tool {
      display: flex;
      align-items: center;
    }
  }

  .etl-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .menu-item {
    height: 30px;
    margin-top: 10px;
    padding: 0 4px;
    color: #1f2d3d;
    font-size: 12px;
    line-height: 28px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: move;
  }
  .no-drag {
    i.iconfont-fx-pc {
      color: #c3cdda;
    }
    color: #c3cdda;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  .x-divider {
    height: 20px;
    margin: 0 10px;
    border-color: #c3cdda;
    border-style: solid;
    border-width: 0 0 0 1px;
  }
  .icon-action {
    width: 28px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #f4f4f4;
    }
  }
  .btn-action {
    padding: 0 6px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
    }
    &.delete {
      &:hover {
        .iconfont-fx-pc {
          color: red;
        }
      }
    }
  }
}
.disabled {
  cursor: not-allowed;
  i {
    color: #c3cdda !important;
  }
  &:hover {
    background-color: #ffffff !important;
  }
}
.pop-wrapper {
  // color: #1f2d3d;
  font-size: 12px;
  // line-height: 18px;
  background: #fff;
  border-radius: 3px;
  .divider {
    width: 100%;
    margin: 12px 0;
    border: solid #e9e9e9;
    border-width: 1px 0 0;
  }
  .align-item {
    margin: 0px 4px;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:not(.disabled):hover {
      background: #e7f7f6;
    }
  }
}

.etl-item {
  padding: 0 5px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #e3f6ff;
  border: 1px solid #76bbeb;
  cursor: move;
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  .iconfont-fx-pc {
    color: #248af9;
    margin: 0px;
  }
  .node-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
  }
}
</style>
<style lang="scss">
.elt-pop {
  padding-left: 0;
  padding-right: 0;
}
.etl-error {
  .el-input__inner {
    border-color: #f56c6c;
    color: #f00;
    background-color: rgba($color: #f56c6c, $alpha: 0.1);
  }
}
.etl-attribute-container {
  display: flex;
  height: 100%;
  background-color: #ffffff;
}
</style>
