<template>
  <div class="container">
    <div class="content" style="width: 100%">
      <div class="header flex-end">
        <div style="flex: 1;text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;">
          {{ view.title }}
        </div>
        <el-button
          @click="saveForm"
          type="primary"
          size="mini"
          class="mr"
        >保存
        </el-button>
        <el-button type="primary" size="mini" class="mr" @click="back"
        >关闭
        </el-button>
      </div>
      <div class="form-body">
        <el-form :model="extParamForm" ref="extParamForm">
          <el-form-item
            v-for="(paramKey,index) in Object.keys(extParamForm)"
            :label="paramKey"
            :key="index"
            :prop="paramKey"
          >
            <el-input type="textarea" v-model="view.extParam[paramKey]"></el-input>
            <el-button @click.prevent="removeDomain(paramKey)">删除</el-button>
            <el-button @click.prevent="editKey(paramKey)">编辑键</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="addDomain">新增配置项</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {getViewDetail, saveViewComponent} from "@/views/lowCode/view/api";

export default {
  directives: {},
  components: {},
  data() {
    return {
      view: {},
      extParamForm: {}
    };
  },
  computed: {},
  async created() {
    await this.init()

  },
  methods: {
    removeDomain(key) {
      delete this.extParamForm[key]
    },
    editKey(sourceKey) {
      this.$prompt('请输入键', '编辑键', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: sourceKey
      }).then(({value}) => {
        Object.keys(this.extParamForm).map(keyItem => {
          if (keyItem == sourceKey) {
            this.$set(this.extParamForm, value, this.extParamForm[sourceKey]);
            delete this.extParamForm[keyItem]

          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });

    },
    addDomain() {
      this.$prompt('请输入键', '新建键值对配置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        this.$set(this.extParamForm, value, "");
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });


    },
    async init() {
      let viewId = this.$route.query.id;
      if (this.viewId) {
        viewId = this.viewId;
      }

      let res = await getViewDetail({viewId: viewId});
      this.view = res.data.view.component
      if (!this.view.extParam) {
        this.view.extParam = {}
      }
      this.extParamForm = this.view.extParam
    },
    saveForm() {

      let viewId = this.$route.query.id;
      this.view.extParam = Object.assign(this.view.extParam,this.extParamForm)
    
      this.saveLoading = true;
      saveViewComponent({
        viewId,
        component: JSON.stringify(this.view),
      })
        .then((res) => {
          this.saveLoading = false;
          this.$message.success("视图保存成功");
          // this.$router.back();
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      let {matched} = this.$router.history.current
      if (matched.length >= 2) {
        let parentPath = matched[matched.length - 2].path
        this.$router.push({path: parentPath});
      } else {
        this.$router.back()
      }

    },

  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  height: calc(100vh - 50px);
  padding: 0 10px;
}


</style>
