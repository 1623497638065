<template>
  <div class="dashboard">
    <div class="header">
      <div class="module-menu__wrap">
        <add-soruce
          @addElement="addElement"
          @addComponents="addComponents"
          :minHeight="minHeight"
          :minWidth="minWidth"
        ></add-soruce>
        &nbsp;
        <add-dash-module
          v-if="dashboard"
          @addElement="addElement"
          @addFilter="addFilter"
          :minHeight="minHeight"
          :minWidth="minWidth"
          :data-component-list="dataComponentList"
          :filter-component-list="filterComponentList"
        ></add-dash-module>
        <div class="mobile-layout">
          <span class="iconfont icon-mobile-phone"></span>
          <el-button type="text" @click="setLayout">移动端布局</el-button>
        </div>
        <div class="set-layout" :class="showPageSet ? 'active' : ''">
          <span class="iconfont icon-settings-fill"></span>
          <el-button type="text" @click="showSet">页面设置</el-button>
        </div>
      </div>
      <div>
        <el-button @click="back()">取消</el-button>
        <el-button @click="save" type="primary">保存</el-button>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; overflow: hidden; flex: 1">
      <div ref="flexBody" class="flex-body" :style="backgroundSet">
        <!-- <el-scrollbar style="height: 100%">
      </el-scrollbar> -->
        <template v-if="dashboard">
          <dashboard-components
            ref="chart_container"
            :components.sync="dashboard.components"
            :componentList="componentList"
            :showColorConfig="showColorConfig"
            :current.sync="currentKey"
            :filterComponentList="filterComponentList"
            :areaTree="areaTree"
            :dataComponentList="dataComponentList"
            :containers="containers"
            :dashboard.sync="dashboard"
            :calDashItemContainerStyle="calDashItemContainerStyle"
            :getBlankPosition="getBlankPosition"
            @refreshDashboard="refreshDashboard"
            @refreshChart="refreshChart"
          >
            <template v-slot:container="slotData">
              <group-container
                :config="slotData.config"
                :ref="'chart_' + slotData.config.key"
                :components.sync="slotData.config.components"
                @delete="delItem"
                @showColorConfig="showColorConfig"
                @fetchDashboardData="fetchDashboardData"
              >
                <template v-slot:group="data">
                  <dashboard-components
                    :ref="'chart_container' + slotData.config.key"
                    :parent="slotData.config"
                    :components.sync="data.components"
                    :componentList="componentList"
                    :showColorConfig="showColorConfig"
                    :current.sync="currentKey"
                    :filterComponentList="filterComponentList"
                    :areaTree="areaTree"
                    :dataComponentList="dataComponentList"
                    :containers="containers"
                    :dashboard.sync="dashboard"
                    :calDashItemContainerStyle="calDashItemContainerStyle"
                    :getBlankPosition="getBlankPosition"
                    @refreshDashboard="refreshDashboard"
                    @refreshChart="refreshChart"
                  >
                  </dashboard-components>
                </template>
              </group-container>
            </template>
          </dashboard-components>
        </template>
      </div>

      <div v-show="showPageSet" class="setBody">
        <page-config
          v-if="showPageSet"
          @closeSet="showPageSet = false"
          @updateBgColor="updateBgColor"
          :colorConfig="pageConfigObj"
        ></page-config>
      </div>
      <div v-show="showSubColorConfig" class="setBody">
        <page-color-config
          v-if="showSubColorConfig"
          @closeSet="showSubColorConfig = false"
          @setColorConfig="setColorConfig"
          :view="currentColorConfigView"
        ></page-color-config>
      </div>
    </div>
    <mobile-layout-config
      :visible.sync="mobileLayoutConfigVisible"
      v-if="dashboard && mobileLayoutConfigVisible"
      :components.sync="dashboard.components"
      :dashboard="dashboard"
      :isShowTitle="isShowTitle"
      :calDashItemContainerStyle="calDashItemContainerStyle"
    ></mobile-layout-config>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/relyUtil";
import { getViewDetail, saveViewComponent } from "../view/api";
import addSoruce from "./addSoruce";
import { contactListPage, getAreaTree } from "@/api/contact";
import addDashModule from "@/views/lowCode/dashboard/add-dash-module";
import mobileLayoutConfig from "@/views/lowCode/dashboard/mobile-layout-config.vue";
import dashboardComponents from "@/views/lowCode/dashboard/dashboard-components.vue";
import pageConfig from "@/views/lowCode/dashboard/page-config";
import pageColorConfig from "@/views/lowCode/dashboard/detail-component/page-color-config";
import groupContainer from "@/views/lowCode/dashboard/add-component/dashboard-container/group-container.vue";

import { fetchFormList } from "@/views/lowCode/form/api";
import { fetchFormDataList, getFormDetail } from "@/views/lowCode/form/api";
import { previewDocumentByIMM } from "@/utils/ali-oss";
import {
  departmentList,
  departmentListByUser,
  departmentTree,
} from "@/api/department";
import { companyEmployeeList, employeeSearch } from "@/api/company";
import { groupList } from "@/api/group";
import {
  COL_NUM,
  defaultModuleLayoutGridBundleSize,
  MIN_W,
  ROW_HEIGHT,
} from "@/views/lowCode/dashboard/dashboard-util";
import { dashboardMixin } from "@/views/lowCode/dashboard/dashboardMixin";
//支持自动刷新的组件
export default {
  mixins: [dashboardMixin],
  components: {
    addSoruce,
    addDashModule,
    mobileLayoutConfig,
    pageConfig,
    pageColorConfig,
    dashboardComponents,
    groupContainer,
  },
  data() {
    return {
      currentKey: "",
      addObj: null,
      dashboard: null,
      minWidth: 325,
      minHeight: 150,
      chart: null,
      // vertical: null,
      // horizontal: null,
      areaTree: [],
      minW: MIN_W,
      layoutMargin: [10, 10],
      rowHeight: ROW_HEIGHT,
      colNumber: COL_NUM,
      isResizing: false,
      mobileLayoutConfigVisible: false,
      showPageSet: false, //页面设置
      showSubColorConfig: false, //
      currentColorConfigView: {}, //当前打开属性设置
      pageConfigObj: {
        backgroundEnum: "color",
        backgroundColor: "",
        backgroundImage: "",
        backgroundImageFillEnum: "stretch_fill",
      },
      backgroundSet: "",
      showButton: true, //为了更新内容文字颜色，局部刷新按钮组件
      showFilter: true, //为了更新内容文字颜色，局部刷新过滤组件
    };
  },
  computed: {
    // 容器组件
    containers() {
      let arr = this.dashboard.components.filter((row) =>
        ["dashboard", "tab"].includes(row.componentName),
      );
      arr.unshift({
        key: "dashboard",
        extParam: {
          title: "默认画布",
        },
      });
      return arr;
    },
    /**
     * 过滤出数据组件
     * @returns {*}
     */
    dataComponentList() {
      return this.componentList.filter(
        (item) => item.componentName != "dashboard_filter",
      );
    },
    // 图表的筛选组件
    filterComponentList() {
      return this.componentList.filter(
        (item) => item.componentName === "dashboard_filter",
      );
    },
  },
  created() {
    this.getAreaTree();
  },

  provide() {
    return {
      fetchFormList,
      getFormDetail,
      fetchFormDataList,
      previewDocumentByIMM,
      departmentList,
      companyEmployeeList,
      contactListPage,
      groupList,
      employeeSearch(params) {
        return employeeSearch({
          ...params,
          companyId: this.$store.state.user.currentCompanyId,
        });
      },
      departmentTree,
      departmentListByUser,
      getCurrentUser() {
        return this.$store.state.user;
      },
      loadCompany() {
        return Promise.resolve([
          {
            name: this.$store.state.user.currentCompanyName,
            key: "company-" + this.$store.state.user.currentCompanyId,
            nodeType: "company",
            isLeaf: false,
            id: this.$store.state.user.currentCompanyId,
          },
        ]);
      },
    };
  },
  async mounted() {
    let viewId = this.$route.query.id;
    let res = await getViewDetail({ viewId });
    if (res.code) {
      this.$message.error(res.msg);
      return;
    }

    let dashboard = res.data.view.component;
    let colorConfig = dashboard?.colorConfig;

    if (colorConfig?.backgroundImage || colorConfig?.backgroundColor) {
      this.pageConfigObj = colorConfig;
      if (colorConfig?.backgroundEnum == "image") {
        this.backgroundSet = `background-image: url(${
          colorConfig?.backgroundImage
        }); background-size:${
          colorConfig?.backgroundImageFillEnum == "center_fill"
            ? "cover"
            : "100% 100%"
        };background-position: center;background-repeat: no-repeat;`;
      } else {
        if (
          colorConfig?.backgroundColor &&
          colorConfig?.backgroundColor.includes(",")
        ) {
          this.backgroundSet = `background:linear-gradient(${colorConfig?.backgroundColor})`;
        } else {
          this.backgroundSet = `background-color:${colorConfig?.backgroundColor}`;
        }
      }
    } else {
      dashboard.colorConfig = this.pageConfigObj;
    }
    let fun = (item, parent) => {
      if (!item.key) {
        item.key = this.getUuid();
      }
      if (isEmpty(item.left)) {
        item.left = "0";
      }
      if (isEmpty(item.top)) {
        item.top = "0";
      }
      if (isEmpty(item.width)) {
        item.width = this.minWidth + "";
      }
      if (isEmpty(item.height)) {
        item.height = this.minHeight + "";
      }
      item.h = this.transformData(item.height, "field-h", parent);
      item.w = this.transformData(item.width, "field-w", parent);
      item.x = this.transformData(item.left, "field-x", parent);
      item.y = this.transformData(item.top, "field-y", parent);
      item.i = item.key;

      if (isEmpty(item.refreshConfig)) {
        item.refreshConfig = {
          autoRefresh: false,
          autoRefreshSeconds: 30,
        };
      }
    };
    dashboard.components.forEach((component) => {
      if (component.componentName == "dashboard") {
        component.components.forEach((col) => {
          fun(col, component);
        });
      } else if (component.componentName == "tab") {
        component.components.forEach((item) => {
          item.components.forEach((row) => {
            fun(row, component);
          });
        });
      }
      fun(component);
    });
    this.dashboard = dashboard;
    this.fetchDashboardData(this.componentList, "dashboard");
    document.addEventListener("click", this.bodyCloseSubColorConfig);
  },
  methods: {
    transformData(val, field, parent) {
      let clientHeight = this.$refs.flexBody.clientHeight;
      let clientWidth = this.$refs.flexBody.clientWidth;
      if (parent) {
        clientWidth =
          (this.$refs.flexBody.clientWidth * parseFloat(parent.width)) / 100;
      }

      if (field == "field-h") {
        let height = parseFloat(val);

        if (val.indexOf("%") >= 0) {
          height = (clientHeight * parseFloat(val)) / 100;
        }

        return Math.round(
          (height + this.layoutMargin[1]) /
            (this.rowHeight + this.layoutMargin[1]),
        );
      } else if (field == "field-x") {
        return Math.round(
          ((clientWidth * parseFloat(val)) / 100 - this.layoutMargin[0]) /
            ((clientWidth - this.layoutMargin[0]) / this.colNumber),
        );
      } else if (field == "field-y") {
        let top = parseFloat(val);

        if (val.indexOf("%") >= 0) {
          top = (clientHeight * parseFloat(val)) / 100 - this.layoutMargin[1];
        } else {
          top = top - this.layoutMargin[1];
        }

        return Math.round(top / (this.rowHeight + this.layoutMargin[1]));
      } else if (field == "field-w") {
        return Math.round(
          ((clientWidth * parseFloat(val)) / 100 + this.layoutMargin[0]) /
            ((clientWidth - this.layoutMargin[0]) / this.colNumber),
        );
      }
    },
    delItem(componentKey, event) {
      event.preventDefault();
      event.stopPropagation();
      let index = this.dashboard.components.findIndex(
        (row) => row.key == componentKey,
      );
      this.dashboard.components.splice(index, 1);
    },
    bodyCloseSubColorConfig(e) {
      let self = this;
      var arr = ["container", "attribute-box", "title-label", "content-layout"];
      if (
        e.target.className.includes("vue-grid-layout") ||
        e.target.className.includes("head")
      ) {
        self.showSubColorConfig = false;
        self.showPageSet = false;
      }
    },
    setLayout() {
      this.mobileLayoutConfigVisible = true;
    },

    isShowTitle(item) {
      return !(
        item.hiddenTitle ||
        [
          "html",
          "title_view",
          "carousel_view",
          "include",
          "dashboard",
          "tab",
        ].includes(item.componentName)
      );
    },
    refreshDashboard(model, parent) {
      let containerKeys = [];
      if (model && model.containerKeys) {
        containerKeys = model.containerKeys;
      }
      this.fetchDashboardData(this.componentList, parent, containerKeys);
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      let { matched } = this.$router.history.current;
      if (matched.length >= 2) {
        let parentPath = matched[matched.length - 2].path;
        this.$router.push({ path: parentPath });
      } else {
        this.$router.back();
      }
    },

    getAreaTree() {
      getAreaTree().then((res) => {
        let areaTree = [];
        let buildTree = (soruce) => {
          let obj = {};
          for (const [key, value] of Object.entries(soruce)) {
            if (key != "children") {
              obj[key] = value;
            }
          }
          if (soruce.children && soruce.children.length) {
            obj.children = [];
            soruce.children.forEach((item) => {
              let target = buildTree(item);
              obj.children.push(target);
            });
          }
          return obj;
        };
        res.data.tree.forEach((item) => {
          let target = buildTree(item);
          areaTree.push(target);
        });
        this.areaTree = areaTree;
      });
    },
    save() {
      for (let key in this.$refs) {
        if (key.includes("chart_container")) {
          this.$refs[key]?.save();
        }
      }
      saveViewComponent({
        viewId: this.$route.query.id,
        componentName: this.dashboard.componentName,
        component: this.dashboard,
      }).then((res) => {
        this.$message.success("仪表盘保存成功!");
        // this.back()
      });
      this.showSubColorConfig = false;
      this.showPageSet = false;
    },
    addElement(obj) {
      this.addObj = {
        componentName: obj.view.value,
        text: obj.view.label,
      };
    },
    getBlankPosition(obj, layout) {
      let view = this.addPosition(obj, layout);
      // 确定边界
      let maxY = 0,
        edgeX = 0,
        edgeY = 0;
      layout.forEach((item) => {
        if (item.y + item.h > maxY) {
          maxY = item.y + item.h;
        }
      });
      edgeX = this.colNumber;
      edgeY = maxY;
      // 使用二维数组生成地图
      let gridMap = new Array();
      for (let x = 0; x < edgeX; x++) {
        gridMap[x] = new Array();
        for (let y = 0; y < edgeY; y++) {
          gridMap[x][y] = 0;
        }
      }
      // 标记占位
      layout.map((item) => {
        // 将layout中卡片所占区域标记为1
        for (let x = item.x; x < item.x + item.w; x++) {
          for (let y = item.y; y < item.y + item.h; y++) {
            gridMap[x][y] = 1;
          }
        }
      });
      // 遍历地图，申请位置
      for (let y = 0; y < edgeY; y++) {
        for (let x = 0; x < edgeX; x++) {
          // 申请所需空间
          if (edgeX - x >= view.w && edgeY - y >= view.h) {
            if (gridMap[x][y] < 1) {
              let itemSignArr = [];
              for (let a = x; a < x + view.w; a++) {
                for (let b = y; b < y + view.h; b++) {
                  itemSignArr.push(gridMap[a][b]);
                }
              }
              if (itemSignArr.indexOf(1) < 0) {
                view.x = x;
                view.y = y;
                return view;
              }
            }
          }
        }
      }
      return view;
    },
    addPosition(obj, components) {
      let maxY = 0;
      components.forEach((item) => {
        if (item.y + item.h > maxY) {
          maxY = item.y + item.h;
        }
      });
      obj.x = 0;
      obj.y = maxY;
      obj.w = obj.w || this.minW;
      obj.h = obj.h || 6;
      obj.i = obj.key;
      obj = {
        ...obj,
        ...defaultModuleLayoutGridBundleSize[obj.componentName],
      };
      return obj;
    },
    addFilter(opt) {
      if (!this.addObj) {
        return;
      }

      let obj = {
        key: this.getUuid(),
        container: false,
        componentName: this.addObj.componentName,
        extParam: {
          title: opt.title,
        },
        form: false,
        left: "",
        top: "",
        height: "",
        width: "",
        metrics: [],
        xFields: [],
        yFields: [],
        refreshConfig: {
          autoRefresh: false,
          autoRefreshSeconds: 30,
        },
        ...opt,
      };
      obj = this.getBlankPosition(obj, this.dashboard.components);
      this.currentKey = obj.key;
      this.dashboard.components.push(obj);
      this.addObj = null;
    },
    addComponents(opt) {
      if (!this.addObj) {
        return;
      }
      let obj = {
        key: this.getUuid(),
        container: false,
        componentName: this.addObj.componentName,
        dataSource: opt.dataSource,
        extParam: {
          title: opt.title,
        },
        form: false,
        left: "",
        top: "",
        height: "",
        width: "",
        metrics: [],
        xFields: [],
        yFields: [],
        refreshConfig: {
          autoRefresh: false,
          autoRefreshSeconds: 30,
        },
      };
      if (obj.componentName == "chart_column") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.xAxis = null;
        obj.yAxis = null;
      } else if (obj.componentName == "chart_bar") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.yAxis = null;
      } else if (obj.componentName == "chart_line") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.xAxis = null;
        obj.yAxis = null;
        obj.chartMarker = null;
      } else if (obj.componentName == "chart_area") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.xAxis = null;
        obj.yAxis = null;
        obj.chartMarker = null;
      } else if (obj.componentName == "chart_pie") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.xAxis = null;
        obj.yAxis = null;
        obj.pieType = null;
      } else if (obj.componentName == "pivot_table") {
        obj.fixedColumn = false;
        obj.fixedRow = false;
      } else if (obj.componentName == "table") {
        obj.fixedColumn = false;
        obj.fixedRow = false;
        obj.columns = [];
      } else if (obj.componentName == "gantt") {
        obj = {
          key: this.getUuid(),
          chartLegend: { enable: true, position: "top" },
          componentName: "gantt",
          container: false,
          extParam: {
            title: opt.title,
          },
          dataSource: opt.dataSource,
          form: false,
          height: "",
          width: "",
          timeProgresses: [],
          xFields: [],
          yFields: [],
          zoomLevel: "week",
        };
      } else if (obj.componentName == "chart_process") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.targetValues = [];
      } else if (obj.componentName == "chart_radar") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.yAxis = null;
      } else if (obj.componentName == "chart_multi_axes") {
        obj.chartLabel = null;
        obj.chartLegend = null;
        obj.xAxis = null;
        obj.chartMarker = null;
        obj.yAxes = [
          { chartType: "chart_column", colorScheme: "scheme_c" },
          { chartType: "chart_line", colorScheme: "scheme_a" },
        ];
      }
      obj = this.getBlankPosition(obj, this.dashboard.components);
      this.currentKey = obj.key;
      this.dashboard.components.push(obj);
      this.addObj = null;
    },
    /**
     * 计算仪表盘组件的容器样式
     * @param item
     * @returns {{}}
     */
    calDashItemContainerStyle(item) {
      let style = {};
      if (item.componentName == "metric_table") {
        style = {
          ...style,
        };
        if (item.hiddenTitle) {
          style = {
            ...style,
            "padding-bottom": "0px",
          };
        }
      }
      if (item.componentName == "title_view") {
        style = {
          ...style,
          padding: "0",
          "background-color": "transparent",
        };
      }
      if (item.componentName == "html") {
        style = {
          ...style,
          padding: "6px 12px",
        };
      }
      if (item.componentName == "carousel_view") {
        style = {
          ...style,
          padding: "0",
        };
      }
      if (["include", "dashboard", "tab"].includes(item.componentName)) {
        style = {
          ...style,
          padding: "0",
        };
      }
      if (item.componentName == "button_group") {
        style = {
          ...style,
          padding: "2px 12px 12px 12px",
        };
      }
      if (
        item.hiddenTitle &&
        !["dashboard", "tab", "metric_table"].includes(item.componentName)
      ) {
        style = {
          ...style,
          paddingTop: "12px",
        };
      }
      return style;
    },

    /**
     * 打开仪表盘背景设置
     */
    showSet() {
      this.showPageSet = !this.showPageSet;
      this.showSubColorConfig = false;
    },
    /**
     * 更新仪表盘背景
     */
    updateBgColor(background, backgroundEnum, backgroundImageFillEnum) {
      if (backgroundEnum == "image" && background) {
        this.dashboard.colorConfig.backgroundEnum = "image";
        this.dashboard.colorConfig.backgroundImage = background;
        this.dashboard.colorConfig.backgroundImageFillEnum =
          backgroundImageFillEnum;
        this.backgroundSet = `background-image: url(${background}); background-size:${
          backgroundImageFillEnum == "center_fill" ? "cover" : "100% 100%"
        };background-position: center;background-repeat: no-repeat;`;
      } else if (backgroundEnum == "color" && background) {
        this.dashboard.colorConfig.backgroundEnum = "color";
        this.dashboard.colorConfig.backgroundColor = background;
        this.dashboard.colorConfig.backgroundImageFillEnum = "stretch_fill";
        if (
          background &&
          background.includes(",") &&
          !background.includes("rgba")
        ) {
          this.backgroundSet = `background:linear-gradient(${background})`;
        } else {
          this.backgroundSet = `background-color:${background}`;
        }
      } else {
        this.dashboard.colorConfig.backgroundEnum = "";
        this.dashboard.colorConfig.backgroundImage = "";
        this.dashboard.colorConfig.backgroundColor = "";
        this.dashboard.colorConfig.backgroundImageFillEnum = "stretch_fill";
        this.backgroundSet = "";
      }
    },
    /**
     *按钮组件，筛选组件等打开仪表盘背景设置
     */
    showColorConfig(view, parentComponent) {
      this.showSubColorConfig = false;
      this.$nextTick(() => {
        this.showSubColorConfig = true;
      });
      this.showPageSet = false;
      this.currentColorConfigView = view;
    },
    setColorConfig(colorConfig, type) {
      let view = this.currentColorConfigView;
      this.currentColorConfigView.colorConfig = colorConfig;
      let currentColorConfigIndex = this.dashboard.components.findIndex(
        (row) => row.key == view.key,
      );
      this.$set(
        this.dashboard.components,
        currentColorConfigIndex,
        this.currentColorConfigView,
      );
      if (type == "content" && view.componentName == "button_group") {
        this.showButton = false;
        this.$nextTick(() => {
          this.showButton = true;
        });
      }
      if (view.componentName == "gantt") {
        this.refreshChart(view);
        // this.showFilter = false;
        // this.$nextTick(() => {
        //   this.showFilter = true;
        // });
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseSubColorConfig);
  },
};
</script>
<style lang="scss" scoped>
$header-height: 76px;
@import "~@/styles/mixin.scss";
.dashboard {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.flex-body {
  flex: 1;
  display: flex;
  overflow: auto;
  padding-bottom: 10px;
  position: relative;
  background-color: rgba(9, 30, 66, 0.05);
  box-sizing: border-box;
}

.view-list {
  padding: 20px;
  border-right: solid 1px #f4f5f7;
  overflow: auto;
}

.view-item {
  margin-bottom: 10px;
  font-size: 14px;
  border: solid 1px #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
}

.header {
  height: 50px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 45px 0 58px;
  box-sizing: border-box;

  .module-menu__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 220px;
  }

  .btn-add {
    color: #1890ff;
    border-color: #1890ff;
  }
}

.move-item {
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;

  .move-container {
    border-radius: 5px;
    padding: 8px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
  }

  .move-header {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .move-body {
    height: 92px;
    box-sizing: border-box;
    background-color: rgba(9, 30, 66, 0.05);
  }
}

.dash-edit {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #ffffff;
  z-index: 100;
}

//.vertical {
//  position: absolute;
//  top: 0px;
//  border-left: dashed 1px #1890ff;
//}
//
//.horizontal {
//  position: absolute;
//  left: 0px;
//  right: 0px;
//  border-top: dashed 1px #1890ff;
//}
// .vue-grid-item {
//   background: white !important;
// }

.mobile-layout,
.set-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 16px;
  .el-button--text,
  .iconfont {
    color: #42526e;
  }
}
.active {
  .el-button--text,
  .iconfont {
    color: var(--blue-6);
  }
}
.centerBody {
  // display: flex;
  // flex-direction: row;
}
.setBody {
  width: 240px;
  background: #ffffff;
}
</style>
<style lang="scss">
.no-header-dialog .el-dialog__header {
  display: none;
}
</style>
