<template>
  <div>
    <div v-if="!value.length" class="upload" @click="visible = true">
      <div class="label" v-text="label"></div>
      <i class="el-icon-upload"></i>
    </div>
    <div v-else class="att-list">
      <div
        v-for="(attachment, index) in value"
        :key="index"
        class="attachment-icon"
        :title="getTitle(attachment)"
        @click="dialogVisible = true"
      >
        <el-image
          v-if="checkFileImage(attachment.extension)"
          :z-index="99999"
          :ref="'elImg_' + index"
          :src="attachment.url + '?x-oss-process=image/resize,m_lfit,h_40,w_40'"
          class="attachment-image"
        ></el-image>
        <SvgIcon
          v-if="!checkFileImage(attachment.extension)"
          :icon-class="getIconClassByExtension(attachment.extension)"
          class="attachment-svg"
        ></SvgIcon>
      </div>
    </div>

    <el-dialog
      :title="fileType == 'image' ? '图片列表' : '附件列表'"
      :visible.sync="dialogVisible"
      width="600px"
      :append-to-body="true"
      :destroy-on-close="true"
    >
      <div style="padding: 10px">
        <el-button
          v-if="isShowUpload && !disabled"
          @click="visible = true"
          size="mini"
          type="primary"
          style="width: 100%"
          >{{ fileType == "image" ? "上传图片" : "上传附件" }}
        </el-button>
        <attachment-list :attachment-list="value">
          <template slot-scope="scope">
            <el-link
              type="primary"
              icon="el-icon-paperclip"
              @click="handleAttachmentDownload(scope.row)"
              >下载
            </el-link>
            <el-link
              v-if="canPreview(scope.row.extension)"
              type="primary"
              icon="el-icon-view"
              :href="scope.row.url"
              target="_blank"
              :download="scope.row.originalName"
              >查看
            </el-link>
            <el-link
              type="danger"
              icon="el-icon-delete"
              @click="handleAttachmentDelete(scope.row)"
              >删除
            </el-link>
          </template>
        </attachment-list>
      </div>
    </el-dialog>

    <el-dialog
      :title="label"
      :visible.sync="visible"
      width="600px"
      append-to-body
    >
      <div style="padding: 20px">
        <upload
          ref="fileUpload"
          @change="handleUpLoadChange"
          :accept="accept"
          :limit="limitFileCount"
        ></upload>
        <el-button type="primary" @click="addUploadFiles">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Upload from "@/components/Upload/indexNew";
import SvgIcon from "@/components/SvgIcon/index";
import AttachmentList from "@/components/AttachmentList2";
import { getFileExtension } from "@/utils/enums";
import { downFile } from "@/utils/ali-oss";
export default {
  components: { Upload, SvgIcon, AttachmentList },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    fileType: String,
    limitFileCount: {
      type: Number,
      default() {
        return 0;
      },
    },
    isShowInColumn: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    label() {
      if (this.fileType == "image") {
        return "添加图片";
      } else {
        return "添加附件";
      }
    },
    accept: function () {
      return this.fileType == "image" ? "image/*" : "";
    },
    isShowUpload() {
      if (this.limitFileCount == 0) {
        return true;
      }
      return this.value.length < this.limitFileCount;
    },
  },
  data() {
    return {
      dialogVisible: false,
      visible: false,
      uploadFileList: [],
      innerFileCount: 0,
    };
  },
  methods: {
    canPreview(extension) {
      return getFileExtension(extension) ? true : false;
    },
    handleAttachmentDownload(att) {
      downFile(att);
    },
    getTitle(obj) {
      let str = obj.originalName;
      if (obj.extension) {
        str += "." + obj.extension;
      }
      return str;
    },
    checkFileImage(extension) {
      let enums = {
        jpg: "jpg2",
        jpeg: "jpg2",
        png: "png2",
        bmp: "bmp",
        heic: "heic",
        ico: "ico",
      };
      return enums[extension];
    },
    getIconClassByExtension(extension) {
      let enums = {
        doc: "WORD2",
        docx: "WORD2",
        jpg: "jpg2",
        jpeg: "jpg2",
        html: "html2",
        mp3: "mp32",
        png: "png2",
        dll: "dll2",
        ppt: "PPT",
        pptx: "PPT",
        mp4: "mp42",
        pdf: "pdf1",
        gif: "gif1",
        exe: "exe",
        mpg: "mpg",
        psd: "psd1",
        mkv: "mkv",
        xls: "ECEL",
        xlsx: "ECEL",
        rmvb: "rmvb",
        txt: "txt1",
        wav: "wav",
        swf: "swf1",
        avi: "avi1",
        zip: "zip1",
        rar: "folder2",
        other: "other",
      };
      if (enums[extension]) {
        return enums[extension];
      } else {
        return "other";
      }
    },
    handleUpLoadChange(files) {},
    addUploadFiles() {
      // if (
      //   this.limitFileCount !== 0 &&
      //   this.innerFileCount > this.limitFileCount
      // ) {
      //   this.$message.error("最多只能添加" + this.limitFileCount + "个附件");
      //   return;
      // }
      this.uploadFileList = this.$refs.fileUpload.fileList;
      // console.log(this.uploadFileList);
      this.uploadFileList.forEach((item, index) => {
        item.attachmentId = item.id;
        this.handleAddAssociate(item, 30, index);
      });
      this.$refs.fileUpload.clearFiles();
      this.visible = false;
    },
    handleAttachmentDelete(att) {
      let index = this.value.findIndex((item) => item.id == att.id);

      if (index >= 0) {
        let list = this._.cloneDeep(this.value);
        let item = list[index];
        this.$confirm(`您确认要删除【${item.originalName}】吗`, "提示")
          .then(() => {
            list.splice(index, 1);
            this.$emit("input", list);
          })
          .catch(() => {});
      }
    },
    handleAddAssociate(row, type) {
      console.log(row);
      console.log(type);
      let list = this._.cloneDeep(this.value);
      if (!list) {
        list = [];
      }
      list.push(row);
      this.$emit("input", list);
    },
  },
};
</script>
<style lang="scss" scoped>
.upload {
  display: flex;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  border: dashed 1px #dcdfe6;
  border-radius: 5px;
  padding: 5px;
  .label {
    flex: 1;
    font-size: 12px;
  }
}
.attachment-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;

  .attachment-image {
    height: 30px;
    width: 30px;
  }

  .attachment-svg {
    width: 30px;
    height: 30px;
  }
}
.att-list{
  display: flex;
  flex-wrap: wrap;
}
</style>