<template>
  <!-- <old-edit></old-edit> -->
  <dashboard></dashboard>
</template>
<script>
import dashboard from "./dashboard";

export default {
  components: {  dashboard },
};
</script>
<style lang="scss" scoped>
</style>
