<template>
  <div>
    <div>
      <el-select
        style="width: 100%; margin-bottom: 10px"
        v-if="companyId != 0"
        placeholder="请选择单位"
        v-model="selectCompanyId"
        @change="handleCompanySelect"
      >
        <el-option
          v-for="item in companyList"
          :label="item.name"
          :value="item.id"
          :key="item.id"
        ></el-option>
      </el-select>
    </div>
    <div class="breadcrumb">
      <!--      <el-breadcrumb separator-class="el-icon-arrow-right">-->
      <!--        <el-breadcrumb-item v-for="(item,index) in breadcrumbList" :key="item.id" style="margin-bottom: 15px"><a-->
      <!--            @click="breadcrumbClick(item,index)"><span class="breadcrumb-span">{{ item.name }}</span></a>-->
      <!--        </el-breadcrumb-item>-->
      <!--      </el-breadcrumb>-->
    </div>
    <el-table
      :data="tableData"
      @cell-click="handleCellClick"
      @selection-change="diskSelectionChange"
      style="width: 100%"
    >
      <el-table-column
        width="80px"
        label="类型"
        align="center"
        style="padding: 0"
        class-name="folder-table-type-column"
      >
        <template slot-scope="scope">
          <svg-icon
            iconClass="wenjianjia"
            style="margin-right: 5px; font-size: 25px"
            v-if="scope.row.dataType == 'folder'"
          ></svg-icon>
          <svg-icon
            v-else
            :icon-class="
              getIconClassByExtension(scope.row.attachment.extension)
            "
            style="margin-right: 5px; font-size: 25px"
          ></svg-icon>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="文件名"
        align="left"
        class-name="table-name-column"
      >
        <template slot-scope="scope">
          <div style="display: flex; max-height: 300px; align-items: center">
            <el-image
              style="width: 100px; height: 100%"
              :src="scope.row.attachment.url"
              :preview-src-list="[scope.row.attachment.url]"
              :z-index="2100"
              v-if="
                scope.row.dataType == 'disk' &&
                checkFileImage(scope.row.attachment.extension)
              "
            >
            </el-image>
            <span class="cell">{{ scope.row.title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="100px" label="格式">
        <template slot-scope="scope">
          <span v-if="scope.row.dataType === 'folder'">文件夹</span>
          <span v-else>{{ scope.row.attachment.extension }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="updatedTime"
        align="center"
        width="200px"
        label="保存时间"
      >
      </el-table-column>
      <el-table-column align="center" width="80px" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click="
              handleAddAssociate(
                scope.row,
                scope.row.dataType == 'disk' ? 30 : scope.row.dataType,
                scope.$index
              )
            "
            v-if="scope.row.dataType != 'folder'"
            v-show="!scope.row.added"
            >添加
          </el-button>
          <el-button type="text" size="medium" disabled v-show="scope.row.added"
            >已添加
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="paginationForm.total"
      :page.sync="paginationForm.pageNumber"
      :limit.sync="paginationForm.pageSize"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { companyList } from "@/api/company";
import { mapGetters } from "vuex";
import { diskListPage } from "@/api/disk";

export default {
  name: "RelatedDiskTable",
  components: { Pagination },
  props: {
    // tableData: {
    //   type: Array,
    //   default: []
    // },
    companyId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      tableData: [],
      selectCompanyId: this.companyId,
      companyList: [],
      breadcrumbList: [{ id: 0, name: "网盘" }],
      paginationForm: {
        total: 0,
        pageNumber: 1,
        pageSize: 10,
      },
      // tablePageList:[],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.companyId != 0) {
        this.getCompanyInfo();
        console.log("单位网盘");
      }
      this.fetchData();
    },
    fetchData(folderId) {
      let params = {
        pageNumber: this.paginationForm.pageNumber,
        pageSize: this.paginationForm.pageSize,
        query: JSON.stringify({ folderId: folderId || 0 }),
      };
      if (this.companyId) {
        params.companyId = this.selectCompanyId;
      }
      diskListPage(params)
        .then((resp) => {
          this.tableData = resp.data.recordPage.list;
          this.paginationForm.total = resp.data.recordPage.totalRow;
          this.paginationForm.pageNumber = resp.data.recordPage.pageNumber;
        })
        .catch((err) => {});
    },
    checkFileImage(extension) {
      let enums = {
        jpg: "jpg2",
        jpeg: "jpg2",
        png: "png2",
        bmp: "bmp",
        heic: "heic",
        ico: "ico",
      };
      return enums[extension];
    },
    getCompanyInfo() {
      return companyList().then(({ data }) => {
        this.companyList = data.companyList;
        this.$nextTick(() => {
          this.selectCompanyId = this.currentUser.companyId;
        });
      });
    },
    handleCompanySelect(val) {
      // this.$emit("folderClick", 0, val)
      this.fetchData();
    },
    breadcrumbClick(item, index) {
      this.breadcrumbList.splice(index + 1, 999);
      // this.$emit("folderClick", item.id, this.selectCompanyId)
      this.fetchData(item.id);
    },

    handleCellClick(row, column, cell, event) {
      if (column.label === "文件名") {
        if (row.dataType === "folder") {
          let breadcrumb = {
            id: row.id,
            name: row.title,
          };
          this.breadcrumbList.push(breadcrumb);
          // this.$emit("folderClick", row.id, this.selectCompanyId)
          this.fetchData(row.id);
        }
      }
    },
    diskSelectionChange() {},
    handleAddAssociate(row, type, index) {
      console.log(row, type, index);
      row.added = true;
      this.$set(this.tableData, index, row);
      this.$forceUpdate();
      this.$emit("addAssociate", row, type, index);
    },
    getIconClassByExtension(extension) {
      let enums = {
        doc: "WORD2",
        docx: "WORD2",
        jpg: "jpg2",
        jpeg: "jpg2",
        html: "html2",
        mp3: "mp32",
        png: "png2",
        dll: "dll2",
        ppt: "PPT",
        pptx: "PPT",
        mp4: "mp42",
        pdf: "pdf1",
        gif: "gif1",
        exe: "exe",
        mpg: "mpg",
        psd: "psd1",
        mkv: "mkv",
        xls: "ECEL",
        xlsx: "ECEL",
        rmvb: "rmvb",
        txt: "txt1",
        wav: "wav",
        swf: "swf1",
        avi: "avi1",
        zip: "zip1",
        rar: "folder2",
        other: "other",
      };
      if (enums[extension]) {
        return enums[extension];
      } else {
        return "other";
      }
    },
  },
};
</script>

<style scoped>
</style>
