<template>
  <div style="margin-bottom: 10px">
    <div class="group-item">
      <div style="flex: 1; margin-right: 10px">
        <el-input
          :class="isDelete(node.field.name) ? 'etl-error' : ''"
          v-model="node.title"
          size="small"
          placeholder="请输入"
          style="width: 100%"
          @blur="saveTitle"
          @keydown.native.enter="saveTitle"
          :readonly="!isEdit"
          ref="input"
        ></el-input>
      </div>

      <i
        @click="edit"
        class="iconfont-fx-pc icon-pc-edit"
        style="rgba(9, 30, 66, 0.66)"
      ></i>
      <i
        class="iconfont-fx-pc icon-pc-drag"
        style="rgba(9, 30, 66, 0.66);margin:0 5px"
      ></i>
      <i
        @click="deleteRow"
        class="iconfont-fx-pc icon-trash"
        style="rgba(9, 30, 66, 0.66)"
      ></i>
    </div>
    <div
      v-if="
        ['address_input', 'date_picker', 'position_input'].includes(
          node.field.componentName,
        )
      "
      class="row-weui"
      style="margin-top: 5px"
    >
      <div style="font-size: 14px; margin-right: 5px">分组方式</div>
      <el-select v-model="picker" size="small" placeholder="请选择分组方式">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
export default {
  props: {
    node: Object,
    xfields: Array,
    index: Number,
    isDelete: Function,
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    picker: {
      get() {
        if (
          ["address_input", "position_input"].includes(
            this.node.field.componentName,
          )
        ) {
          return this.node.addressPrecision.picker;
        } else if (this.node.field.componentName == "date_picker") {
          return this.node.datePrecision.picker;
        }
        return "";
      },
      set(val) {
        if (
          ["address_input", "position_input"].includes(
            this.node.field.componentName,
          )
        ) {
          this.node.addressPrecision.picker = val;
        } else if (this.node.field.componentName == "date_picker") {
          this.node.datePrecision.picker = val;
        }
      },
    },
    options() {
      if (
        ["address_input", "position_input"].includes(
          this.node.field.componentName,
        )
      ) {
        return [
          {
            label: "省",
            value: "province",
          },
          {
            label: "省-市",
            value: "city",
          },
          {
            label: "省-市-县",
            value: "county",
          },
        ];
      } else if (this.node.field.componentName == "date_picker") {
        return [
          {
            label: "年",
            value: "year",
          },
          {
            label: "年季",
            value: "quarter",
          },
          {
            label: "年月",
            value: "month",
          },
          {
            label: "年周",
            value: "week",
          },
          {
            label: "年月日",
            value: "date",
          },
        ];
      }
      return [];
    },
  },
  methods: {
    saveTitle() {
      if (isEmpty(this.node.title)) {
        this.$message.error("请输入分组字段标题");
        return;
      }
      this.isEdit = false;
      this.$emit("changeChildrenData");
    },

    edit() {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    deleteRow() {
      this.$delete(this.xfields, this.index);
      this.$emit("changeChildrenData");
    },
  },
};
</script>
<style lang="scss" scoped>
.group-item {
  display: flex;
  align-items: center;
}
.row-weui {
  display: flex;
  align-items: center;
}
</style>
