<template>
  <div style="display: flex; flex-direction: column">
    <div class="container-heard">
      <div style="color: #6b778c; padding-left: 30px">
        正在编辑：{{ view.title }}
      </div>
      <div class="header flex-end">
        <div
          class="mobile-view-container"
          v-if="mobileViewBtnVisible"
          @click="showMobileView"
        >
          <i class="iconfont icon-mobile-phone" style="color: #b29ae8"></i>
          移动端视图
        </div>
        <el-button @click="review" class="mr" v-if="!manualInit"
          >预览
        </el-button>
        <el-button class="mr" @click="back" v-if="!manualInit">关闭</el-button>
        <el-button
          :loading="saveLoading"
          @click="saveForm"
          type="primary"
          class="mr"
          >{{ manualInit ? "确定" : "保存" }}
        </el-button>
      </div>
    </div>
    <div class="container">
      <source-list
        v-if="form.formId"
        :formId="form.formId"
        :onMove="onMove"
        :activeName="activeName"
        :use-type="useType"
        @changeSource="changeSource"
        @initData="initData"
        @getOptionSourceList="getOptionSourceList"
        @onStart="onSourceListDragStart"
        :selectArray="selectArray"
        :switch-able="!manualInit"
        :disabled="useType == 'tree_table' && activeName == 'table'"
      ></source-list>
      <div class="content">
        <div class="form-body">
          <el-tabs v-model="activeName" class="view-edit-tabs__wrap">
            <el-tab-pane name="table">
              <span slot="label" style="padding: 0px 10px">表格</span>
              <div style="padding: 0px 10px">
                <div v-if="useType != 'tree_table'" class="col-h">列字段</div>
                <div v-if="useType != 'tree_table'">
                  <draggable
                    class="col-container"
                    :list="columns"
                    :group="colGroup2"
                    animation="300"
                    @add="add"
                    @sort="sort"
                  >
                    <el-tooltip
                      v-for="(column, index) in columns"
                      :key="index"
                      effect="dark"
                      :content="column.field.title || column.title"
                      placement="top"
                    >
                      <el-tag
                        class="m"
                        :closable="useType != 'tree_table'"
                        @close="handleClose(index)"
                        @click="checkCol(column)"
                        :type="handleSearchFormItemStatus(column)"
                      >
                        {{ column.title }}
                      </el-tag>
                    </el-tooltip>
                  </draggable>
                </div>

                <div
                  v-if="columns && columns.length && isShowTable"
                  style="margin: 10px 0px"
                >
                  <div class="left">
                    <el-button v-if="hasCreate" size="small" type="primary"
                      >新增
                    </el-button>
                  </div>
                  <template v-if="useType == 'table'">
                    <el-table :data="[{}]">
                      <el-table-column
                        v-if="form.showRowNumbers"
                        type="index"
                        width="50"
                        label="序号"
                      >
                      </el-table-column>
                      <template v-for="(col, index) in columns">
                        <el-table-column
                          v-if="showTableCol(col)"
                          :align="col.align"
                          :key="index"
                          :label="col.title"
                          :width="
                            col.components && col.components.length && col.width
                              ? col.width
                              : ''
                          "
                        >
                          <template
                            v-if="col.components && col.components.length"
                          >
                            <el-table-column
                              v-for="(subCol, sIndex) in col.components.filter(
                                (item) => !item.hidden,
                              )"
                              :key="sIndex"
                              :align="subCol.align"
                              :label="subCol.title"
                              :width="subCol.width ? subCol.width : ''"
                            >
                            </el-table-column>
                          </template>
                        </el-table-column>
                      </template>
                      <el-table-column
                        width="120"
                        v-if="hasEdit || hasDelete"
                        label="操作"
                      >
                        <el-button type="text" v-if="hasEdit">编辑</el-button>
                        <el-button type="text" v-if="hasDelete">删除</el-button>
                      </el-table-column>
                    </el-table>

                    <div class="left" v-if="form.hasPageTool">
                      <pagination
                        :total="page.totalRow"
                        :page.sync="page.pageNumber"
                        :limit.sync="pageSize"
                        @pagination="() => {}"
                      />
                    </div>
                  </template>
                  <template v-else-if="useType == 'tree_table'">
                    <el-table :data="[{}]">
                      <template v-for="col in columns">
                        <template
                          v-for="(item, index) in col.components.filter(
                            (item) => !item.hidden,
                          )"
                        >
                          <el-table-column
                            :label="item.title"
                            :key="col.key + '_' + index + '_' + item.field.key"
                          ></el-table-column>
                        </template>
                      </template>
                    </el-table>
                  </template>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="form">
              <span slot="label" style="padding: 0px 10px">查询表单</span>
              <div style="padding: 0px 10px">
                <div class="col-h">拖动左侧字段到此处来添加查询字段</div>
                <div>
                  <draggable
                    class="col-container"
                    :list="searchFormView.components"
                    :group="colGroup2"
                    animation="300"
                    @add="addForm"
                  >
                    <el-tooltip
                      v-for="(column, index) in searchFormView.components"
                      :key="index"
                      effect="dark"
                      :content="column.field.title"
                      placement="top"
                    >
                      <el-tag
                        class="m"
                        closable
                        @close="delSearchCol(index)"
                        @click="checkCol(column)"
                        :type="handleSearchFormItemStatus(column)"
                      >
                        {{ column.title }}
                      </el-tag>
                    </el-tooltip>
                  </draggable>
                </div>
                <!-- form -->
                <div style="font-size: 14px; margin: 10px 0px">表单预览</div>
                <div>
                  <el-form
                    class="cust-form"
                    ref="form"
                    :label-position="form.extParam.labelPosition"
                    :label-width="`${form.extParam.labelWidth}px`"
                  >
                    <el-form-item
                      v-for="(column, index) in searchFormView.components"
                      :key="index"
                      :label="form.extParam.showLabel ? column.title : ''"
                    >
                      <el-input
                        v-if="
                          column.field.componentName == 'input' ||
                          column.field.componentName == 'input_number' ||
                          column.field.componentName == 'text_area' ||
                          column.field.componentName == 'sn_input'
                        "
                        :placeholder="column.field.placeHolder"
                        disabled
                      >
                      </el-input>
                      <el-date-picker
                        v-else-if="
                          column.field.componentName == 'date_picker' &&
                          (!column.field.extParam ||
                            (column.field.extParam &&
                              !column.field.extParam.range))
                        "
                        :type="column.field.picker"
                        :placeholder="column.field.placeHolder"
                        disabled
                      ></el-date-picker>
                      <div
                        v-else-if="
                          column.field.componentName == 'date_picker' &&
                          column.field.extParam &&
                          column.field.extParam.range
                        "
                        style="display: flex; flex-direction: row"
                      >
                        <el-date-picker
                          :type="column.field.picker"
                          :placeholder="column.field.placeHolder"
                          disabled
                        ></el-date-picker>
                        至
                        <el-date-picker
                          :type="column.field.picker"
                          :placeholder="column.field.placeHolder"
                          disabled
                        ></el-date-picker>
                      </div>
                      <el-cascader
                        v-else-if="
                          column.field.componentName == 'address_input'
                        "
                        :options="[]"
                        disabled
                        placeholder=""
                      >
                      </el-cascader>
                      <div
                        class="outer-selected-user__empty-wrap"
                        v-else-if="
                          column.field.componentName == 'user_select' ||
                          column.field.componentName == 'user_list_select'
                        "
                      >
                        选择成员
                      </div>
                      <el-select
                        v-else
                        :value="''"
                        :placeholder="column.field.placeHolder"
                        disabled
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in getOptions(column.field)"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <div
                      class="search-btn"
                      v-if="searchFormView.components.length"
                    >
                      <div
                        style="height: 50px"
                        v-if="
                          form.extParam.showLabel &&
                          form.extParam.labelPosition == 'top' &&
                          form.extParam.cols > 1
                        "
                      ></div>
                      <div class="btn-content">
                        <el-button
                          type="primary"
                          size="small"
                          style="height: 40px"
                          >查询</el-button
                        >
                      </div>
                    </div>
                  </el-form>
                </div>

                <!--  -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div v-if="activeName == 'table'" class="attribute-box">
        <div class="header nav-tab">
          <div
            class="tab-txt"
            @click="switchAttTab('components')"
            :class="{ on: attTab == 'components' }"
          >
            组件属性
          </div>
          <div
            class="tab-txt"
            @click="switchAttTab('table')"
            :class="{ on: attTab == 'table' }"
            v-if="!manualInit"
          >
            表格属性
          </div>
        </div>
        <div class="form-body">
          <template v-if="attTab == 'components'">
            <column-attribute
              v-if="useType == 'table'"
              :form="currNode"
            ></column-attribute>
            <template v-else>
              <parent-attribute
                v-if="isFinishInitTree"
                :columns="columns"
                :components="treeComponents"
                :hasParentComponent="hasParentComponent"
              ></parent-attribute>
            </template>
          </template>

          <div v-else>
            <template v-if="useType == 'tree_table'">
              <div class="row">
                <div class="label">数据操作</div>
                <div class="flex">
                  <el-checkbox v-model="hasCreate"
                    ><span style="font-size: 12px">新增数据</span></el-checkbox
                  >
                  <el-checkbox v-model="hasEdit"
                    ><span style="font-size: 12px">编辑数据</span></el-checkbox
                  >
                  <el-checkbox v-model="hasDelete"
                    ><span style="font-size: 12px">删除数据</span></el-checkbox
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="label">数据显示</div>
                <div class="flex">
                  <el-checkbox v-model="form.showRowNumbers"
                    ><span style="font-size: 12px">显示序号</span></el-checkbox
                  >
                </div>
              </div>
              <div class="row">
                <div class="label">汇总行显示</div>
                <div class="flex">
                  <el-checkbox v-model="form.showFilledCount"
                    ><span style="font-size: 12px"
                      >显示汇总行</span
                    ></el-checkbox
                  >
                </div>
              </div>
              <div class="row">
                <div class="label">行内编辑</div>
                <div class="flex">
                  <el-checkbox v-model="form.inlineEdit"
                    ><span style="font-size: 12px"
                      >允许行内编辑</span
                    ></el-checkbox
                  >
                </div>
              </div>
              <div class="row page-tool">
                <div class="item">
                  <div class="label">分页</div>
                  <div class="flex">
                    <el-checkbox
                      v-model="form.hasPageTool"
                      @change="changePageTool"
                      ><span style="font-size: 12px"
                        >开启分页</span
                      ></el-checkbox
                    >
                  </div>
                </div>
                <div v-if="form.hasPageTool" class="item">
                  <div class="label">默认每页条数</div>
                  <div class="flex">
                    <el-select v-model="pageSize" placeholder="" size="mini">
                      <el-option
                        v-for="(item, index) in pageSizes"
                        :key="index"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <!-- <div class="row">
                <div class="label">数据操作</div>
                <div class="flex">
                  <el-checkbox v-model="hasCreate" :disabled="disableAction"
                    ><span style="font-size: 12px">新增数据</span></el-checkbox
                  >
                  <el-checkbox v-model="hasEdit" :disabled="disableAction"
                    ><span style="font-size: 12px">编辑数据</span></el-checkbox
                  >
                  <el-checkbox v-model="hasDelete" :disabled="disableAction"
                    ><span style="font-size: 12px">删除数据</span></el-checkbox
                  >
                </div>
              </div>
              <div class="row">
                <div class="label">操作权限</div>
                <div class="flex">
                  <el-checkbox v-model="hasExport"
                    ><span style="font-size: 12px"
                      >可导出数据</span
                    ></el-checkbox
                  >
                </div>
                <div class="flex">
                  <el-checkbox v-model="hasImportData" :disabled="disableAction"
                    ><span style="font-size: 12px"
                      >可导入数据</span
                    ></el-checkbox
                  >
                </div>
              </div> -->
              <div class="row">
                <div class="label">报表模板</div>

                <el-popover
                  popper-class="pop-trigger"
                  placement="bottom-start"
                  v-model="formPopVisible"
                  width="300"
                  :disabled="disableAction"
                >
                  <div v-clickoutside="handlePopClose">
                    <div style="padding: 10px 10px 0px">
                      <el-input
                        placeholder="请输入关联表单名称"
                        prefix-icon="el-icon-search"
                        v-model="formName"
                        size="mini"
                        @input="searchForm"
                      >
                      </el-input>
                    </div>

                    <div class="pop-scroll">
                      <div
                        class="empty-text"
                        v-if="tableData && tableData.length == 0"
                      >
                        暂无表单数据
                      </div>
                      <template v-else>
                        <div
                          class="form-item"
                          :class="{ select: item.id == form.tableName }"
                          v-for="(item, index) in tableData"
                          :key="index"
                          @click="chkTableName(item)"
                        >
                          <div>{{ item.title }}</div>
                          <div>
                            <i
                              v-if="item.checked"
                              class="el-icon-check"
                              style="color: #66b1ff"
                            ></i>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <el-button
                    @click.stop=""
                    slot="reference"
                    style="width: 100%"
                    size="mini"
                    :disabled="disableAction"
                    >选择表单
                  </el-button>
                </el-popover>
                <div class="row">
                  <div class="flex">
                    <div
                      v-for="item in form.reportForms"
                      :key="item.id"
                      class="template-item"
                    >
                      <div
                        style="
                          width: 75%;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                        "
                        :title="item.title"
                      >
                        {{ item.title }}
                      </div>
                      <div>
                        <el-button type="text" @click="deleteReportForm(item)"
                          >删除
                        </el-button>
                        <el-button type="text" @click="reportFormPreview(item)"
                          >预览
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 设计统计图表 -->
              <div class="row">
                <div class="label">统计图表</div>
                <el-button
                  @click="dashboardViewCreate"
                  slot="reference"
                  style="width: 100%"
                  size="mini"
                  :disabled="disableAction"
                  >设置
                </el-button>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="attribute-box">
        <div class="header nav-tab">
          <div
            class="tab-txt"
            @click="switchAttTab('components')"
            :class="{ on: attTab == 'components' }"
          >
            组件属性
          </div>
          <div
            class="tab-txt"
            @click="switchAttTab('form')"
            :class="{ on: attTab == 'form' }"
            v-if="!manualInit"
          >
            表单属性
          </div>
        </div>
        <div class="form-body">
          <div v-if="attTab == 'form'">
            <form-attribute :form="form.extParam" type="table"></form-attribute>
            <div class="row">
              <div class="flex">
                <el-checkbox v-model="form.extParam.showLabel"
                  >显示标签
                </el-checkbox>
              </div>
            </div>
          </div>

          <div v-else-if="currNode">
            <div class="row">
              <div class="label">标题：</div>
              <div class="flex">
                <el-input
                  v-model="currNode.title"
                  placeholder=""
                  size="mini"
                ></el-input>
              </div>
            </div>

            <div class="row">
              <div class="label">占位内容</div>
              <div class="flex">
                <el-input
                  v-model="currNode.field.placeHolder"
                  placeholder=""
                  size="mini"
                ></el-input>
              </div>
            </div>

            <!-- 默认值 -->
            <div v-if="false" class="row">
              <div class="label">默认值</div>
              <div class="flex">
                <el-date-picker
                  v-if="currNode.field.componentName == 'date_picker'"
                  v-model="currNode.defaultValue"
                  :type="form.picker"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="mini"
                ></el-date-picker>
                <el-input
                  v-else-if="
                    ['text_area', 'input', 'input_number', 'sn_input'].includes(
                      currNode.field.componentName,
                    )
                  "
                  v-model="currNode.defaultValue"
                  placeholder="请输入默认值"
                  size="mini"
                ></el-input>
                <el-cascader
                  v-else-if="currNode.field.componentName == 'address_input'"
                  :options="areaList(currNode)"
                  placeholder="请选择默认值"
                  size="mini"
                  :props="areaTreeProp"
                  v-model="currNode.defaultValue"
                  clearable
                >
                </el-cascader>
                <div
                  class="outer-selected-user__empty-wrap"
                  v-else-if="
                    currNode.field.componentName == 'user_select' ||
                    currNode.field.componentName == 'user_list_select'
                  "
                >
                  无
                </div>
                <el-select
                  v-else
                  v-model="currNode.defaultValue"
                  placeholder="请选择默认值"
                  size="mini"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in getOptions(currNode.field)"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <!-- 查询操作符 -->
            <div class="row">
              <div class="label">查询操作符</div>
              <div class="flex">
                <el-select
                  v-model="currNode.field.queryOper"
                  placeholder="请选择操作符"
                  size="mini"
                >
                  <el-option
                    v-for="item in getQuerOper(currNode.field.componentName)"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 查询操作符 -->
            <div
              class="row"
              v-if="
                ['address_input', 'position_input'].includes(
                  currNode.field.componentName,
                )
              "
            >
              <div class="label">查询精读</div>
              <div class="flex">
                <el-select
                  v-model="currNode.field.picker"
                  placeholder=""
                  size="mini"
                >
                  <el-option label="省" value="province"></el-option>
                  <el-option label="省-市" value="city"></el-option>
                  <el-option label="省-市-县" value="county"></el-option>
                </el-select>
              </div>
            </div>
            <div
              class="row"
              v-if="currNode.field.componentName == 'date_picker'"
            >
              <div class="flex">
                <el-checkbox v-model="currNode.field.extParam.range"
                  >是否范围查询
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <preview ref="preview"></preview>
      <form-preview
        ref="formPreview"
        :area-tree="areaTree"
        title="报表模板预览"
      ></form-preview>
    </div>
    <mobile-view
      v-if="mobileViewVisible"
      :visible.sync="mobileViewVisible"
      :data-title-cols="dataTitleCols"
      :columns="columns"
      :wap-config.sync="view.component.wapConfig"
    ></mobile-view>

    <!-- 统计图表 -->
    <dashboardView
      v-if="chartAddVisible"
      :drawer.sync="chartAddVisible"
      :dashboardViewId.sync="dashboardViewId"
      :dashboard="dashboard"
      :view="view"
    >
    </dashboardView>
  </div>
</template>
<script>
import { getViewDetail, saveViewComponent } from "./api";
import {
  fetchFormDataList,
  fetchFormList,
  getFormDetail,
  uiViewInit,
} from "../form/api";
import Pagination from "@/components/Pagination";
import Draggable from "vuedraggable";
import columnAttribute from "@/views/lowCode/view/components/columnAttribute";
import FormAttribute from "./components/formAttribute";
import preview from "./components/preview";
import { getAreaTree } from "@/api/contact";
import SourceList from "./components/Source";
import { isEmpty } from "lodash";
import { column } from "mathjs";
import Clickoutside from "element-ui/src/utils/clickoutside";
import FormPreview from "@/views/lowCode/form/component/FormPreview";
import {
  parentData,
  getComponentQueryOper,
  viewSearchFilter,
  tableFields,
} from "@zgg-core-utils/whiteList";
import parentAttribute from "./components/parentAttribute";
import mobileView from "@/views/lowCode/view/components/mobileView";
import { getCookie, setCookie, removeCookie } from "@/utils/cookie-tools";
import dashboardView from "./dashboardView";
import moment from "moment";
export default {
  props: {
    /**
     * 是否手动初始化，
     * 目前用在关联数据查询时的临时视图的配置弹窗，复用视图设置的组件
     */
    manualInit: {
      type: Boolean,
      default: false,
    },
    saveFunction: Function,
    useType: {
      type: String,
      default() {
        return "table";
      },
    },
    mobileViewBtnVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormPreview,
    Pagination,
    Draggable,
    columnAttribute,
    FormAttribute,
    preview,
    SourceList,
    parentAttribute,
    mobileView,
    dashboardView,
  },
  directives: { Clickoutside },
  data() {
    return {
      layout: "total, sizes, prev, pager, next, jumper",
      isShowTable: false,
      saveLoading: false,
      activeName: "table",
      attTab: "components",
      view: {},
      colGroup2: {
        name: "column",
        pull: false,
      },
      columns: [],
      searchFormView: {
        componentName: "form",
        components: [],
      },
      currNode: null,
      form: {
        formId: 0,
        showRowNumbers: true,
        showFilledCount: false, // 显示汇总行
        inlineEdit: true, // 行内编辑
        hasPageTool: true,
        extParam: {
          cols: 4,
          labelPosition: "top",
          labelWidth: 200,
          showLabel: true,
        },
        reportForms: [],
      },
      pageSize: 20,
      sourceList: {},
      page: {
        pageSize: 10,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      actionItems: [],
      areaTree: [],
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
      },
      formPopVisible: false,
      formName: "",
      tableData: [],
      tableLoading: false,
      treeComponents: [],
      isFinishInitTree: false,
      hasParentComponent: false,
      guardIndex: 0, // 监听视图改变执行次数
      pageSizes: [5, 10, 20, 30, 50, 100, 200, 500],
      mobileViewVisible: false,
      dataTitleCols: [],
      chartAddVisible: false, // 统计图表新增入口
      dashboardViewId: "",
      dashboard: {},
    };
  },
  watch: {
    activeName() {
      this.attTab = "components";
      this.currNode = null;
    },
    form: {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        this.guardIndex++;
        if (this.guardIndex > 2) {
          setCookie("form-view-update", true);
        }
      },
    },
    searchFormView: {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        if (this.guardIndex >= 2) {
          this.guardIndex++;
        }
        if (this.guardIndex > 3) {
          setCookie("form-view-update", true);
        }
      },
    },
    columns: {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        if (this.guardIndex >= 2) {
          this.guardIndex++;
        }
        if (this.guardIndex > 4) {
          setCookie("form-view-update", true);
        }
      },
    },
    // $route: {
    //   deep: true,
    //   immediate: true,
    //   handler(val, oldVal) {
    //     this.initViewSettingByViewId(this.$route.query.id);
    //   },
    // },
  },
  computed: {
    disableAction() {
      let result =
        this.form.formId.indexOf("dataflow_") == 0 ||
        this.form.formId.indexOf("at_") == 0;
      return result;
    },
    selectArray() {
      if (this.activeName == "table") {
        return this.columns;
      } else if (this.activeName == "form") {
        return this.searchFormView.components;
      } else {
        return [];
      }
      // return this.activeName == 'table' ? this.columns : this.searchFormView.components
    },
    hasCreate: {
      get() {
        return (
          this.actionItems.findIndex((item) => item.clickEvent == "add") >= 0
        );
      },
      set(val) {
        setCookie("form-view-update", true);
        if (val) {
          this.actionItems.push({
            icon: "",
            title: "新增",
            tip: "点击创建一条新的记录",
            clickEvent: "add",
          });
        } else {
          let index = this.actionItems.findIndex(
            (item) => item.clickEvent == "add",
          );
          if (index >= 0) {
            this.actionItems.splice(index, 1);
          }
        }
      },
    },
    hasEdit: {
      get() {
        return (
          this.actionItems.findIndex((item) => item.clickEvent == "edit") >= 0
        );
      },
      set(val) {
        setCookie("form-view-update", true);
        this.isShowTable = false;
        if (val) {
          this.actionItems.push({
            icon: "",
            title: "编辑",
            tip: "",
            clickEvent: "edit",
          });
        } else {
          let index = this.actionItems.findIndex(
            (item) => item.clickEvent == "edit",
          );
          if (index >= 0) {
            this.actionItems.splice(index, 1);
          }
        }
        this.$nextTick(() => {
          this.isShowTable = true;
        });
      },
    },
    hasDelete: {
      get() {
        return (
          this.actionItems.findIndex((item) => item.clickEvent == "delete") >= 0
        );
      },
      set(val) {
        setCookie("form-view-update", true);
        this.isShowTable = false;
        if (val) {
          this.actionItems.push({
            icon: "",
            title: "删除",
            tip: "",
            clickEvent: "delete",
          });
        } else {
          let index = this.actionItems.findIndex(
            (item) => item.clickEvent == "delete",
          );
          if (index >= 0) {
            this.actionItems.splice(index, 1);
          }
        }
        this.$nextTick(() => {
          this.isShowTable = true;
        });
      },
    },
    hasExport: {
      get() {
        return (
          this.actionItems.findIndex((item) => item.clickEvent == "export") >= 0
        );
      },
      set(val) {
        setCookie("form-view-update", true);
        this.isShowTable = false;
        if (val) {
          this.actionItems.push({
            icon: "",
            title: "导出",
            tip: "",
            clickEvent: "export",
          });
        } else {
          let index = this.actionItems.findIndex(
            (item) => item.clickEvent == "export",
          );
          if (index >= 0) {
            this.actionItems.splice(index, 1);
          }
        }
        this.$nextTick(() => {
          this.isShowTable = true;
        });
      },
    },
    hasImportData: {
      get() {
        return (
          this.actionItems.findIndex(
            (item) => item.clickEvent == "importData",
          ) >= 0
        );
      },
      set(val) {
        setCookie("form-view-update", true);
        this.isShowTable = false;
        if (val) {
          this.actionItems.push({
            icon: "",
            title: "导入",
            tip: "",
            clickEvent: "importData",
          });
        } else {
          let index = this.actionItems.findIndex(
            (item) => item.clickEvent == "importData",
          );
          if (index >= 0) {
            this.actionItems.splice(index, 1);
          }
        }
        this.$nextTick(() => {
          this.isShowTable = true;
        });
      },
    },
  },
  async created() {
    if (this.manualInit) {
      console.log("手动初始化");
    } else {
      await this.initViewSettingByViewId(this.$route.query.id);
    }
  },
  provide() {
    return {
      getComponents: this.getComponents,
    };
  },

  methods: {
    /**
     * 创建统计图表
     */
    dashboardViewCreate() {
      this.chartAddVisible = true;
    },
    getSourceType(tableName) {
      if (tableName.indexOf("dataflow_") == 0) {
        return "dataflow";
      } else if (tableName.indexOf("at_") == 0) {
        return "aggregate_table";
      } else {
        return "form";
      }
    },
    /**
     * 手动传入
     */
    async initViewSettingByViewId(viewId) {
      let res = await getViewDetail({ viewId });
      this.getArea();
      if (res.code) {
        this.$message.error(res.msg);
      } else {
        console.log("initViewSettingByViewId");
        this.view = res.data.view;
        if (res.data.subViews && res.data.subViews.length) {
          this.dashboardViewId = res.data.subViews[0].id;
          this.dashboard = res.data.subViews[0].component;
          if(this.dashboard?.components?.length){
            this.dashboard.components.forEach((item) => {
              let colorConfig = item?.colorConfig;
              if (colorConfig?.backgroundImage || colorConfig?.backgroundColor) {
                if (colorConfig?.backgroundEnum == "image") {
                  item.backgroundSet = {
                    "background-image": url(colorConfig?.backgroundImage),
                    "background-size":
                      colorConfig?.backgroundImageFillEnum == "center_fill"
                        ? "cover"
                        : "100% 100%",
                    "background-position": center,
                    "background-repeat": no - repeat,
                  };
                } else {
                  if (
                    colorConfig?.backgroundColor &&
                    colorConfig?.backgroundColor.includes(",")
                  ) {
                    item.backgroundSet = {
                      background: `linear-gradient(${colorConfig?.backgroundColor})`,
                    };
                  } else {
                    item.backgroundSet = {
                      backgroundColor: colorConfig?.backgroundColor,
                    };
                  }
                }
              }
            });
          }
        }
        this.form.formId = this.view.component.dataSource.formId;
        this.form.reportForms =
          (this.view.component.reportForms &&
            this.view.component.reportForms.map((item) => {
              item.id = item.formId;
              return item;
            })) ||
          [];
        this.fetchFormList();
      }
    },
    /**
     * 根据formId初始化view对象，然后根据外部传入的columns和searchFormView来初始化整个视图配置
     * @param form
     */
    manualBuildViewConfig(form) {
      let {
        tableName,
        title,
        columns,
        relationQuery,
        tableColumns,
        searchFormView,
      } = form;
      let param = {
        componentName: "table",
        title: title,
        dataSource: {
          formId: tableName,
          sourceType: this.getSourceType(tableName),
        },
      };

      uiViewInit(param).then((resp) => {
        /**
         *         兼容关联数据组件配置时，
         *         tableColumns为表格中显示的字段,赋值给当前的view.component.columns
         *         searchFormView为搜索表单的配置，赋值给当前的view.component.searchFormView
         */
        let tempView = resp.data.view;
        this.form.formId = tempView.component.dataSource.formId;
        tempView.component.columns = tableColumns;
        tempView.component.searchFormView = searchFormView;
        this.view = tempView;
      });
    },
    //删除报表模板
    deleteReportForm(item) {
      this.form.reportForms.splice(item, 1);
      this.tableData.length &&
        this.tableData.forEach((t) => {
          if (t.id == item.id) {
            t.checked = false;
          }
        });
    },
    getComponents() {
      return this.form.components;
    },
    handlePopClose() {
      this.formPopVisible = false;
    },
    //预览报表模板
    reportFormPreview(item) {
      getFormDetail(item.formId).then((res) => {
        this.$refs.formPreview.init(res.data.form.component);
      });
    },
    //查询表单
    searchForm() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.fetchFormList();
      }, 500);
    },
    //选中/取消表单作为报表模板
    chkTableName(item) {
      this.$set(item, "checked", !item.checked);
      item.formId = item.id;
      if (item.checked) {
        this.form.reportForms.push(item);
      } else {
        this.form.reportForms.splice(this.form.reportForms.indexOf(item), 1);
      }
    },
    //获取可以作为报表模板的表单列表
    fetchFormList(data, callback) {
      this.tableData = [];
      this.tableLoading = true;
      fetchFormList({
        pageNumber: 1,
        pageSize: 100,
        title: this.formName,
        formDataTableName: this.form.formId,
        ...data,
        removeComponent: true,
      })
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.data.page.list.map((item) => {
            item.checked =
              this.form.reportForms &&
              this.form.reportForms.findIndex((i) => i.formId == item.id) >= 0;
            return item;
          });

          if (typeof callback === "function") {
            callback();
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    onSourceListDragStart(dragObject) {
      console.log(dragObject);
      let noDragCompoenents = [
        "image_uploader",
        "attachment_uploader",
        "sign_input",
        "html_input",
      ];
      if (
        this.activeName == "form" &&
        noDragCompoenents.includes(dragObject.field.componentName)
      ) {
        this.$message.warning("不允许将该字段添加到于查询表单中");
        return false;
      }
      if (dragObject.field.componentName == "reference_data") {
        return;
      }

      // 关联查询特殊处理(如果显示项只有子表单时不能添加)
      if (dragObject.field.componentName === "form_data") {
        let components = dragObject.components;
        if (!components.length) {
          this.$message.warning("该关联查询的字段无法在列表中显示");
          return false;
        }
      }

      if (
        this.activeName == "form" &&
        dragObject.field.name == "attachmentList"
      ) {
        this.$message.warning("不允许将该字段添加到于查询表单中");
        return false;
      }
      if (
        dragObject.field.componentName == "json_form" &&
        dragObject.field.components.length == 0
      ) {
        this.$message.warning("不允许添加该字段");
        return false;
      }
      if (
        this.activeName == "form" &&
        !viewSearchFilter.includes(dragObject.field.componentName)
      ) {
        this.$message.warning("不允许将该字段添加到于查询表单中");
        return;
      }
      if (this.activeName == "table") {
        let filterField = this.columns.find((item) => {
          if (dragObject.referenceName) {
            return (
              item.referenceName == dragObject.referenceName &&
              item.field.name === dragObject.field.name
            );
          } else {
            return (
              !item.referenceName && item.field.name === dragObject.field.name
            );
          }
        });
        if (filterField) {
          this.$message.warning("该字段已存在于表格列字段中");
        } else if (tableFields.includes(dragObject.field.componentName)) {
          let columns = this._.cloneDeep(this.columns);
          columns.push(this._.cloneDeep(dragObject));
          console.log("add", dragObject);
          this.$set(this, "columns", columns);
          this.isShowTable = false;
          this.$nextTick(() => {
            this.isShowTable = true;
          });
        } else {
          this.$message.error(
            `不允许将该【${dragObject.field.title}】添加到于列表中`,
          );
        }
      }
      if (this.activeName == "form") {
        this.searchFormView.components.push(dragObject);
        this.addForm({
          newIndex: this.searchFormView.components.length - 1,
        });
      }
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      let { matched } = this.$router.history.current;
      if (matched.length >= 2) {
        let parentPath = matched[matched.length - 2].path;
        this.$router.push({ path: parentPath });
      } else {
        this.$router.back();
      }
    },
    getQuerOper(componentName) {
      let list = getComponentQueryOper(componentName);
      return list.filter((item) => !["nil", "nnil"].includes(item.value));
    },
    handleSearchFormItemStatus(column) {
      if (
        this.currNode &&
        this.currNode.field.key == column.field.key &&
        !column.deleted
      ) {
        return "success";
      } else {
        if (column.deleted) {
          return "danger";
        }
        if (column.title !== column.field.title) {
          return "warning";
        }
        return "";
      }
    },
    showTableCol(col) {
      if (!col.components) {
        return true;
      }
      return col.components.filter((item) => !item.hidden).length > 0;
    },
    initColumns: function (orignColumns, sourceColumns) {
      console.log("sourceColumns", sourceColumns);
      let columns = this._.cloneDeep(sourceColumns);
      if (!columns) {
        columns = [];
      }
      columns.forEach((item) => {
        let obj;
        if (item.referenceName) {
          let parent = orignColumns.find(
            (col) => col.field.name == item.referenceName,
          );
          if (parent) {
            obj = parent.components.find(
              (col) => col.field.name == item.field.name,
            );
          }
        } else {
          obj = orignColumns.find((col) => col.field.name == item.field.name);
        }

        if (obj) {
          const wapConfigTemp = item.field.component.wapConfig;
          if (wapConfigTemp) {
            obj.field.component.wapConfig = wapConfigTemp;
          }
          item.field = this._.cloneDeep(obj.field);
          if (item.subColumns && item.subColumns.length) {
            item.components = [];
            item.subColumns.forEach((sub) => {
              item.components.push(sub);
            });
          }
        } else {
          item.deleted = true;
        }
      });
      return columns;
    },
    buildParentDataColumns(orignColumns) {
      console.log(this.useType);
      if (this.useType != "tree_table") {
        return;
      }
      let list = orignColumns.filter((item) =>
        parentData.includes(item.field.componentName),
      );

      this.treeComponents = this._.cloneDeep(list);
      this.$nextTick(() => {
        this.isFinishInitTree = true;
      });
      // 构建父级组件视图
      let obj = this._.cloneDeep(
        orignColumns.find((item) => item.field.componentName == "parent_data"),
      );
      if (!obj) {
        // 没有父级组件
        this.hasParentComponent = false;
        this.$message.warning("该视图没有父级组件!");
        return;
      }
      this.hasParentComponent = true;

      if (this.view.component.columns && this.view.component.columns.length) {
        this.view.component.columns.forEach((item) => {
          item.key = this.getUuid();
          let list = this.treeComponents.filter(
            (node) =>
              item.subColumns &&
              item.subColumns.findIndex(
                (col) => node.field.name == col.field.name,
              ) == -1,
          );
          list.forEach((col) => {
            col.hidden = true;
          });
          if (list) {
            if (item.subColumns) {
              item.subColumns = item.subColumns.concat(this._.cloneDeep(list));
            } else {
              item.subColumns = this._.cloneDeep(list);
            }
          }
        });
        // 已有组件
        console.log("return");
        return;
      }

      obj.key = this.getUuid();

      obj.components = this._.cloneDeep(list);
      obj.components.forEach((item) => {
        item.hidden = false;
      });

      let columns = [];

      columns.push(this._.cloneDeep(obj));

      this.$set(this.view.component, "columns", columns);
    },
    async initData(orignColumns) {
      this.buildParentDataColumns(orignColumns);
      let searchComponents = [];
      if (this.view.component.searchFormView) {
        this.view.component.searchFormView.components.forEach((item, index) => {
          let obj;
          if (item.extParam && item.extParam.referenceName) {
            let parent = orignColumns.find(
              (col) => col.field.name == item.extParam.referenceName,
            );
            if (parent) {
              obj = parent.components.find(
                (col) => col.field.name == item.name,
              );
            }
          } else if (item.name.indexOf(".") >= 0) {
            let names = item.name.split(".");
            let parent = orignColumns.find((col) => col.field.name == names[0]);
            if (parent) {
              obj = parent.components.find(
                (col) => col.field.name == item.name,
              );
            }
          } else {
            obj = orignColumns.find((col) => col.field.name == item.name);
          }
          let defaultValue = item.defaultValue;
          if (item.componentName == "address_input") {
            if (defaultValue && defaultValue != "") {
              defaultValue = JSON.parse(defaultValue);
            }
          }
          let key = item.key;
          if (!key) {
            key = this.getUuid();
          }
          let searchItem = {
            key: key,
            title: item.title,
            width: item.width,
            align: item.align,
            defaultValue: defaultValue,
          };
          if (obj) {
            searchItem.field = { ...item, title: obj.title };
          } else {
            searchItem.field = { ...item };
            searchItem.deleted = true;
          }
          searchComponents.push(searchItem);
        });
      }

      this.$set(this.searchFormView, "components", searchComponents);
      if (this.view.component.dataSource.queryObject) {
        this.pageSize = this.view.component.dataSource.queryObject.pageSize;
      }

      this.form.showRowNumbers = this.view.component.showRowNumbers;
      // 兼容显示汇总行旧数据（原来没有属性设置false）
      if (typeof this.view.component.showFilledCount === "undefined") {
        this.form.showFilledCount = false;
      } else {
        this.form.showFilledCount = this.view.component.showFilledCount;
      }
      // 兼容显示允许行内编辑（原来没有属性设置true）
      if (typeof this.view.component.inlineEdit === "undefined") {
        this.form.inlineEdit = true;
      } else {
        this.form.inlineEdit = this.view.component.inlineEdit;
      }

      this.form.hasPageTool = this.view.component.hasPageTool;
      if (this.view.component.extParam) {
        this.$set(this.form, "extParam", this.view.component.extParam);
      }
      this.actionItems = this.view.component.actionItems
        ? this.view.component.actionItems
        : [];
      this.columns = this.initColumns(
        orignColumns,
        this.view.component.columns,
      );
      this.isShowTable = this.columns.length > 0;
      this.getFormDataTitle(orignColumns);
    },
    getArea() {
      getAreaTree().then((res) => {
        let areaTree = [];
        let buildTree = (soruce) => {
          let obj = {};
          for (const [key, value] of Object.entries(soruce)) {
            if (key != "children") {
              obj[key] = value;
            }
          }
          if (soruce.children && soruce.children.length) {
            obj.children = [];
            soruce.children.forEach((item) => {
              let target = buildTree(item);
              obj.children.push(target);
            });
          }
          return obj;
        };
        res.data.tree.forEach((item) => {
          let target = buildTree(item);
          areaTree.push(target);
        });
        this.areaTree = areaTree;
      });
    },
    areaList(node) {
      let list = [];
      let picker = node.field.picker;

      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
    //提交前校验
    prefixCheck() {
      let index = this.columns.findIndex((item) => item.title == "");
      if (index >= 0) {
        this.$message.error("列字段标题不能为空");
        return;
      }
      index = this.searchFormView.components.findIndex(
        (item) => item.title == "",
      );

      if (index >= 0 && this.form.extParam.showLabel) {
        this.$message.error("查询表单标签标题不能为空");
        return;
      }
      index = this.columns.findIndex((item) => item.deleted == true);
      if (index >= 0) {
        this.activeName = "table";
        this.$message.error("请移除已被删除并标红的表单标签");
        return;
      }
      index = this.searchFormView.components.findIndex(
        (item) => item.deleted == true,
      );
      if (index >= 0) {
        this.activeName = "form";
        this.$message.error("请移除已被删除的表单标签");
        return;
      }
      return true;
    },
    //构建表格提交保存的数据
    buildParamBeforeSave() {
      let columns = [];
      this.columns.forEach((item) => {
        let obj = {
          ...item,
          align: item.align,
          field: item.field,
          title: item.title,
          width: item.width,
        };
        if (item.collection) {
          obj.collection = item.collection;
        }
        if (item.referenceName) {
          obj.referenceName = item.referenceName;
        }
        if (item.components && item.components.length) {
          let subColumns = [];
          item.components.forEach((sub) => {
            subColumns.push({
              ...sub,
              align: sub.align,
              field: sub.field,
              title: sub.title,
              width: sub.width,
              hidden: sub.hidden,
            });
          });
          obj.subColumns = subColumns;
        }
        columns.push(obj);
      });

      let dataSource = {
        sourceType: this.getSourceType(this.form.formId),
        formId: this.form.formId,
        queryObject: {
          pageNumber: 1,
          pageSize: this.pageSize,
          sortParam: {},
          advanceQuery: [],
        },
      };

      let searchFormView = {
        componentName: "form",
        components: [],
      };

      this.searchFormView.components.forEach((item) => {
        let obj = {
          ...item.field,
          title: item.title,
          width: item.width,
          align: item.align,
          key: item.key,
          defaultValue: item.defaultValue,
        };
        if (item.field.componentName == "radio_group") {
          obj.componentName = "select";
        }

        if (item.collection && item.referenceName) {
          obj.extParam = {
            collection: item.collection,
            referenceName: item.referenceName,
          };
        }

        searchFormView.components.push(obj);
      });
      let form = {
        componentName: this.view.componentName,
        container: false,
        form: false,
        hasPageTool: this.form.hasPageTool,
        showRowNumbers: this.form.showRowNumbers,
        showFilledCount: this.form.showFilledCount,
        inlineEdit: this.form.inlineEdit,
        extParam: this.form.extParam,
        dataSource,
        searchFormView,
        wapConfig: this.view.component.wapConfig,
        columns,
        actionItems: this.actionItems,
        reportForms: this.form.reportForms,
      };
      return form;
    },
    saveForm(callback) {
      //提交前校验
      if (!this.prefixCheck()) {
        return;
      }
      //构建提交的表单
      let form = this.buildParamBeforeSave();
      let viewId = this.$route.query.id;
      if (this.saveFunction) {
        this.saveFunction(form);
        return;
      }
      this.saveLoading = true;

      saveViewComponent({
        viewId,
        component: form,
      })
        .then((res) => {
          this.saveLoading = false;
          this.$message.success("视图保存成功");
          removeCookie("form-view-update");
          if (typeof callback === "function") {
            callback();
          }
          // this.$router.back();
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    changePageTool(val) {
      if (val) {
        this.pageSize = 10;
      }
    },
    changePageSize(value) {
      let num = value.replace(/[^\d]/g, "");
      setTimeout(() => {
        this.pageSize = num;
      }, 100);
    },
    getOptions(item) {
      if (item.optionSource == "custom") {
        return item.customOptions;
      }

      if (
        item.metaFieldOption &&
        item.metaFieldOption.tableName &&
        this.sourceList[item.metaFieldOption.tableName]
      ) {
        return this.sourceList[item.metaFieldOption.tableName];
      }
      return [];
    },
    addForm(node) {
      // this.$set()
      let components = this._.cloneDeep(this.searchFormView.components);
      if (!components[node.newIndex].key) {
        let key = this.getUuid();
        components[node.newIndex].key = key;
        components[node.newIndex].field.key = key;
      }

      this.searchFormView.components = components;
    },
    add() {
      this.columns = this._.cloneDeep(this.columns);
    },
    sort() {
      this.isShowTable = false;
      this.columns = this._.cloneDeep(this.columns);
      this.$nextTick(() => {
        this.isShowTable = true;
      });
    },
    async getOptionSourceList(item) {
      let arr = ["radio_group", "checkbox_group", "select"];
      if (
        arr.includes(item.componentName) &&
        item.optionSource == "metaField" &&
        item.metaFieldOption.tableName &&
        !(
          this.sourceList[item.metaFieldOption.tableName] &&
          this.sourceList[item.metaFieldOption.tableName].length
        )
      ) {
        let queryObject = {
          pageNumber: 1,
          pageSize: 999,
        };

        let sortParam = {};
        if (item.metaFieldOption.orderBy == "orderByOptionValueAsc") {
          sortParam[item.metaFieldOption.valueFieldName] = "asc";
        } else if (item.metaFieldOption.orderBy == "orderByOptionValueDesc") {
          sortParam[item.metaFieldOption.valueFieldName] = "desc";
        } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeAsc") {
          sortParam["createdTime"] = "asc";
        } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeDesc") {
          sortParam["createdTime"] = "desc";
        }
        if (Object.keys(sortParam).length) {
          queryObject.sortParam = sortParam;
        }

        let res = await fetchFormDataList({
          collection: item.metaFieldOption.tableName,
          queryObject: JSON.stringify(queryObject),
        });
        let list = [];
        res.data.page.list.forEach((col) => {
          list.push({
            label: col[item.metaFieldOption.labelFieldName],
            value: col[item.metaFieldOption.valueFieldName],
          });
        });

        this.$set(this.sourceList, item.metaFieldOption.tableName, list);
      }
    },
    checkCol(col) {
      if (isEmpty(col.field.extParam)) {
        this.$set(col.field, "extParam", {});
      }
      //初始化字段
      if (isEmpty(col.field.extParam.range)) {
        this.$set(col.field.extParam, "range", false);
      }
      this.currNode = col;
    },
    handleClose(index) {
      this.columns.splice(index, 1);
      this.isShowTable = false;
      this.$nextTick(() => {
        this.isShowTable = true;
      });
      this.currNode = null;
    },
    delSearchCol(index) {
      this.searchFormView.components.splice(index, 1);
      this.currNode = null;
    },
    getClass(col) {
      if (
        this.columns.findIndex((item) => item.field.name == col.field.name) >=
          0 &&
        this.activeName == "table"
      ) {
        return "disabled";
      }
      return "";
    },
    onMove(e) {
      // if (this.useType == "tree_table") {
      //   if (this.activeName == "table") {
      //     return false;
      //   }
      // }
      // return this.checkField(e.draggedContext.element);
      return false;
    },
    checkField(element) {
      let field = element.field;
      let noDragCompoenents = [
        "json_form",
        "image_uploader",
        "attachment_uploader",
        "sign_input",
      ];
      if (field.componentName == "reference_data") {
        return false;
      }
      if (field.componentName == "json_form" && field.components.length == 0) {
        return false;
      }
      if (
        this.activeName == "form" &&
        noDragCompoenents.includes(field.componentName)
      ) {
        return false;
      }
      if (this.activeName == "table") {
        if (
          this.columns.findIndex((item) => {
            if (element.referenceName) {
              return (
                item.referenceName == element.referenceName &&
                item.field.name == element.field.name
              );
            } else {
              return (
                !item.referenceName && item.field.name == element.field.name
              );
            }
          }) >= 0
        ) {
          return false;
        }
      } else {
        if (
          this.searchFormView.components.findIndex(
            (item) => item.field.name == element.field.name,
          ) >= 0
        ) {
          // return false;
        }
      }

      return true;
    },
    changeSource(formId) {
      this.form.formId = formId;
      this.columns = [];
      this.view.component.columns = [];
      this.view.component.actionItems = [
        { clickEvent: "add", title: "新增" },
        { clickEvent: "edit", title: "编辑" },
        { clickEvent: "delete", title: "删除" },
        { clickEvent: "importData", title: "导入" },
      ];
      this.view.component.dataSource.formId = formId;
      this.activeName = "table";
      this.attTab = "components";
      this.pageSize = 10;
      this.currNode = null;
      this.searchFormView.components = [];
      this.form = {
        ...this.form,

        showRowNumbers: true,
        showFilledCount: false,
        inlineEdit: true,
        hasPageTool: true,
        extParam: {
          cols: 4,
          labelPosition: "top",
          labelWidth: 200,
          showLabel: true,
        },
      };
      console.log(this._.cloneDeep(this.view));
    },
    review() {
      // columns ,searchFormView,pageSize,form
      if (!this.columns.length) {
        this.$message.error("请先设置列字段");
        return;
      }
      let index = this.searchFormView.components.findIndex(
        (item) => item.deleted == true,
      );
      if (index >= 0) {
        this.activeName = "form";
        this.$message.error("请移除已被删除的表单标签");
        return;
      }
      this.$refs.preview.init(
        {
          ...this.form,
          columns: this.columns,
          pageSize: this.pageSize,
        },
        this.searchFormView,
        this.actionItems,
        this.sourceList,
      );
    },
    getFormDataTitle(orignColumns) {
      if (this.disableAction) {
        this.dataTitleCols = [];
        return;
      }

      getFormDetail(this.form.formId).then((formDetailResp) => {
        let dataTitleTemplate =
          formDetailResp.data.form.component.dataTitle &&
          formDetailResp.data.form.component.dataTitle.template;
        if (dataTitleTemplate) {
          let patt1 = /#{[a-zA-Z0-9]+}/gm;
          let arr = dataTitleTemplate.match(patt1);
          if (!arr) {
            arr = [];
          }
          // 获取数据标题中的列字段
          this.dataTitleCols = orignColumns.filter((column) =>
            arr.includes("#{" + column.field.name + "}"),
          );
        }
      });
    },
    switchAttTab(name) {
      if (this.attTab == name) {
        return;
      }
      this.attTab = name;
    },
    showMobileView() {
      this.mobileViewVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/zgg-design-token.scss";

.container-heard {
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  box-sizing: border-box;
  margin: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  background-color: #fff;
}

.container {
  display: flex;
  height: calc(100vh - 116px);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 5px 5px 0;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  background-color: #fff;
  border-radius: 5px;
}

.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 5px;
}

.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;

  .mobile-view-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #42526e;
    margin-right: 16px;
    user-select: none;
    cursor: pointer;
  }
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tab-txt {
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  padding: 0px 20px;
  flex: 1;
  cursor: pointer;

  &.on {
    color: var(--zgg-brand-color-6);
    cursor: default;
    border-bottom: 1px solid var(--zgg-brand-color-6);
  }
}

.nav-tab {
  display: flex;
}

.form-body {
  flex: 1;
  overflow: auto;
}

.mr {
  margin-right: 10px;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 10px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 10px;
}

.check {
  color: var(--zgg-brand-color-6);
  font-size: 28px;
}

.el-icon-circle {
  width: 25px;
  height: 25px;
  border-radius: 32px;
}

.col-h {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
}

.col-container {
  min-height: 50px;
  border: solid 1px #f4f5f7;
  //margin-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;

  .column-item,
  .sub-col-item {
    background-color: #ecf5ff;
    border-color: #d9ecff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: var(--zgg-brand-color-6);
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    margin-top: 10px;
    margin-left: 5px;

    i {
      display: none;
    }

    .sub-container {
      display: none;
    }
  }

  .m {
    margin-top: 10px;
    border-width: 1px !important;
    outline: 0 !important;
  }
}

.row {
  margin: 10px;

  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.page-tool {
  display: flex;
  flex-direction: row;
}
.flex {
  flex: 1;
}

.cust-form {
  display: flex;
  flex-wrap: wrap;

  &::after {
    display: block;
    content: " ";
    clear: both;
  }

  > &.el-form--label-top {
    .el-form-item__label {
      line-height: 1;
      padding-bottom: 6px;
    }
  }

  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
      padding: 5px;
      // float: left;
      border: transparent dashed 1px;

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100% !important;
      }

      .el-input.is-disabled .el-input__inner,
      .el-textarea.is-disabled .el-textarea__inner {
        background-color: #ffffff;
        cursor: default;
      }
    }
  }
}

.search-btn {
  display: flex;
  margin-bottom: 10px;
  border: transparent dashed 1px;
  padding: 5px;
  flex-direction: column;

  .btn-content {
    flex: 1;
    // display: flex;
    // align-items: center;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 10px;
}

.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  width: unset;
}

.empty-text {
  font-size: 12px;
  text-align: center;
  line-height: 28px;
}

.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .form-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
    margin: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.active {
      background-color: rgba($color: $--zgg-brand-color-6, $alpha: 0.5);
      color: #ffffff;
    }

    &.disabled {
      color: rgba($color: #000000, $alpha: 0.28);
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: rgba($color: $--zgg-brand-color-6, $alpha: 0.2);
    }
  }
}

.template-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-edit-tabs__wrap {
  ::v-deep {
    .el-tabs__nav-scroll {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .el-tabs__header {
      margin: 0;

      .el-tabs__nav-wrap::after {
        //background-color: unset;
      }
    }
  }
}

::v-deep .el-drawer__header {
  margin-bottom: 15px;
  .el-drawer__close-btn .el-icon-close {
    &:hover {
      background-color: #d4d7da;
    }
  }
}
.add-chart ::v-deep .el-drawer__body {
  background-color: #f5f6f8;
}

.drawer-body {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  .description {
    color: #838892;
    font-size: 14px;
    margin: 10px 0px;
    text-align: center;
  }
  .addBtn {
    margin: auto;
  }
}
</style>
