<template>
  <div id="lucky-sheet-container">
    <div id="luckysheetRelation"
    ></div>
  </div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector";

export default {
  name: "ExcelRender",
  props:{

  },
  data() {
    return {
      cellPropsData:[],
      hook: {
        rowTitleCellRenderBefore: function (rowNum, postion, ctx) {
          // console.log(rowNum);
        },
        rowTitleCellRenderAfter: function (rowNum, postion, ctx) {
          // console.log(ctx);
        },
        columnTitleCellRenderBefore: function (columnAbc, postion, ctx) {
          // console.log(columnAbc);
        },
        columnTitleCellRenderAfter: function (columnAbc, postion, ctx) {
          // console.log(postion);
        },
        cellRenderBefore: function (cell, postion, sheetFile, ctx) {
          // console.log(cell,postion,sheetFile,ctx);
        },
        cellRenderAfter: function (cell, postion, sheetFile, ctx) {
          // console.log(postion);
        },
        cellMousedownBefore: function (cell, postion, sheetFile, ctx) {
          // console.log(postion);
        },
        cellMousedown: (cell, position, sheetFile, ctx) => {
          console.log(cell);
          console.log(position);
          console.log(ctx);
          console.log(sheetFile);
          this.$bus.$emit("cellMousedown", {cell, position, ctx, sheetFile})
        },
        sheetMousemove: function (cell, postion, sheetFile, moveState, ctx) {
          // console.log(cell,postion,sheetFile,moveState,ctx);
        },
        sheetMouseup: function (cell, postion, sheetFile, moveState, ctx) {
          // console.log(cell,postion,sheetFile,moveState,ctx);
        },
        cellAllRenderBefore: function (data, sheetFile, ctx) {
          // console.info(data,sheetFile,ctx)
        },
        updated: function (operate) {
          // console.info(operate)
        },
        cellUpdateBefore: function (r, c, value, isRefresh) {
          // console.info('cellUpdateBefore',r,c,value,isRefresh)
        },
        cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
          // console.info('cellUpdated',r,c,oldValue, newValue, isRefresh)
        },
        sheetActivate: function (index, isPivotInitial, isNewSheet) {
          // console.info(index, isPivotInitial, isNewSheet)
        },
        rangeSelect: function (index, sheet) {
          // console.info(index, sheet)
        },
        commentInsertBefore: function (r, c) {
          // console.info(r, c)
        },
        commentInsertAfter: function (r, c, cell) {
          // console.info(r, c, cell)
        },
        commentDeleteBefore: function (r, c, cell) {
          // console.info(r, c, cell)
        },
        commentDeleteAfter: function (r, c, cell) {
          // console.info(r, c, cell)
        },
        commentUpdateBefore: function (r, c, value) {
          // console.info(r, c, value)
        },
        commentUpdateAfter: function (r, c, oldCell, newCell) {
          // console.info(r, c, oldCell, newCell)
        },
        cellEditBefore: function (range) {
          // console.info(range)
        },
        workbookCreateAfter:  (json) =>{
          console.info(json)
          // this.$emit("workbookCreateAfter",json)
          this.setCellValueByCellProps(this.cellPropsData)
        },
        rangePasteBefore: function (range, data) {
          // console.info('rangePasteBefore',range,data)
          // return false; //Can intercept paste
        },
      }
    }
  },
  mounted() {

    this.init()
    this.listen()

  },
  beforeDestroy() {
    this.handleDestroy()
  },
  methods: {
    //根据表格的cellProp来设置单元格数据
    setCellValueByCellProps(cellProps) {
      cellProps.map(item => {
        if(item.value){
          luckysheet.setCellValue(item.row, item.column, item.value)
        }

      })
    },
    /**
     * 设置单元格数据
     * r 行
     * c 列
     * v 值
     */
    setCellValue(r,c,v){
      luckysheet.setCellValue(r, c, v)
    },
    getExcelData() {
      window.luckysheet.exitEditMode()
      return window.luckysheet.getAllSheets()
      // return window.luckysheet.getluckysheetfile()
    },
    listen() {
      const _this = this;
      const erd = elementResizeDetectorMaker();
      erd.listenTo(document.getElementById("lucky-sheet-container"), element => {
        _this.$nextTick(() => {
          //监听到事件后执行的业务逻辑
          luckysheet.resize()
        });
      });
    },
    //初始化表格
    init() {
      //配置项
      var options = {
        container: 'luckysheetRelation', // 设定DOM容器的id
        title: '智管工', // 设定表格名称
        lang: 'zh', // 设定表格语言
        showinfobar: false,
        hook: this.hook,
        showtoolbar:false
      }
      luckysheet.create(options)
    },
    //测试数据的回显
    handleReadExcel(sheetsData) {
      if(typeof sheetsData === 'string'){
        sheetsData = JSON.parse(sheetsData)
      }
      luckysheet.destroy();
      luckysheet.create({
        container: 'luckysheetRelation', //luckysheet is the container id
        data: sheetsData,
        title: "测试回显",
        lang: 'zh', // 设定表格语言
        showinfobar: false,
        hook: this.hook,
        showtoolbar:false

      });
      // this.listen()
    },
    //测试数据的回显
    handleReadExcelAndInitCell(sheetsData,cellPropsData) {

      if(typeof sheetsData === 'string'){
        sheetsData = JSON.parse(sheetsData)
      }
      luckysheet.destroy();
      luckysheet.create({
        container: 'luckysheetRelation', //luckysheet is the container id
        data: sheetsData,
        title: "测试回显",
        lang: 'zh', // 设定表格语言
        showinfobar: false,
        hook: this.hook,
        showtoolbar:false

      });
      this.cellPropsData = cellPropsData
      // this.listen()
    },
    handleDestroy() {
      luckysheet.destroy();
    },
    //处理单元格回显
    handleEchoCell(cellObjs) {
      let rows = []
      let columns = []
      cellObjs.map(item => {
        let {column, row} = item
        rows.push(row)
        columns.push(column)
      })

      // luckysheet.setCellFormat(r, c, "bg", "#ff0000")
      // luckysheet.clearRange()
      // luckysheet.exitEditMode()
      luckysheet.setRangeShow({row: rows, column: columns}, {show: true})


    },
    //推出编辑模式
    exitEditMode() {
      luckysheet.exitEditMode()
    },
    handleDivResize() {
      console.log("resize")
    }

  },
  updated() {
    luckysheet.resize()
  }
}
</script>

<style scoped lang="less">
#lucky-sheet-container {
  height: 100%;
  position: relative;

  .header-tool {
    height: 50px;
  }

  #luckysheetRelation {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: relative;
  }
}
</style>
