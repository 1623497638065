<template>
  <div style="padding: 0 8px">
    <div class="header nav-tab">
      <div
        class="tab-txt"
        @click="switchAttTab('dataForm')"
        :class="{ on: attTab == 'dataForm' }"
      >
        功能
      </div>
      <div
        class="tab-txt"
        @click="switchAttTab('colorForm')"
        :class="{ on: attTab == 'colorForm' }"
      >
        样式
      </div>
    </div>
    <template v-if="attTab == 'dataForm'">
      <div class="chart-container" style="justify-content: space-between">
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>指标图</div>
            <div>0个纬度，1个指标</div>
            <div>1个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('metric_table')"
            :class="{ check: componentName == 'metric_table' }"
          >
            <div
              class="chart-image chart-type-0"
              :class="{ disabled: metricsDisabled || chartDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>进度图</div>
            <div>0个纬度，1个指标，1个目标值</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_process')"
            :class="{ check: componentName == 'chart_process' }"
          >
            <div
              class="chart-image chart-type-process"
              :class="{ disabled: progressDisabled || chartDisabled }"
            ></div>
          </div>
        </el-popover>

        <el-popover trigger="hover">
          <div class="pop-text">
            <div>透视图</div>
            <div>1个纬度，1个或多个指标</div>
            <div>多个纬度，1个或多个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('pivot_table')"
            :class="{ check: componentName == 'pivot_table' }"
          >
            <div
              class="chart-image chart-type-1"
              :class="{ disabled: chartDisabled }"
            ></div>
          </div>
        </el-popover>

        <el-popover trigger="hover">
          <div class="pop-text">
            <div>柱形图</div>
            <div>1个纬度，1个或者多个指标</div>
            <div>2个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_column')"
            :class="{ check: componentName == 'chart_column' }"
          >
            <div
              class="chart-image chart-type-2"
              :class="{ disabled: chartDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>条形图</div>
            <div>1个纬度，1个或者多个指标</div>
            <div>2个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_bar')"
            :class="{ check: componentName == 'chart_bar' }"
          >
            <div
              class="chart-image chart-type-3"
              :class="{ disabled: chartDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>折线图</div>
            <div>1个纬度，1个或者多个指标</div>
            <div>2个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_line')"
            :class="{ check: componentName == 'chart_line' }"
          >
            <div
              class="chart-image chart-type-4"
              :class="{ disabled: chartDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>面积图</div>
            <div>1个纬度，1个或者多个指标</div>
            <div>2个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_area')"
            :class="{ check: componentName == 'chart_area' }"
          >
            <div
              class="chart-image chart-type-area"
              :class="{ disabled: chartDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>双轴图</div>
            <div>1个纬度，1个或者多个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_multi_axes')"
            :class="{ check: componentName == 'chart_multi_axes' }"
          >
            <div
              class="chart-image chart-type-multi_axes"
              :class="{ disabled: chartDisabled || multiAxesDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>饼图</div>
            <div>1个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_pie')"
            :class="{ check: componentName == 'chart_pie' }"
          >
            <div
              class="chart-image chart-type-7"
              :class="{ disabled: chartDisabled || chartPieDisabled }"
            ></div>
          </div>
        </el-popover>
        <el-popover trigger="hover"
          ><div class="pop-text">
            <div>地图</div>
            <div>1个地理纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_map')"
            :class="{ check: componentName == 'chart_map' }"
          >
            <div
              class="chart-image chart-type-9"
              :class="{ disabled: chartMapDisabled || chartDisabled }"
            ></div></div
        ></el-popover>
        <el-popover trigger="hover"
          ><div class="pop-text">
            <div>明细表</div>
            <!-- <div>1个地理纬度，1个指标</div> -->
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('table')"
            :class="{ check: componentName == 'table' }"
            style="
              display: flex;
              align-content: center;
              justify-content: center;
            "
          >
            <div
              class="chart-image chart-type-detail"
              :class="{ disabled: !chartDisabled }"
            ></div></div
        ></el-popover>
        <el-popover trigger="hover">
          <div class="pop-text">
            <div>雷达图</div>
            <div>1个纬度，1个或者多个指标</div>
            <div>2个纬度，1个指标</div>
          </div>
          <div
            slot="reference"
            class="chart-type"
            @click="changeViewType('chart_radar')"
            :class="{ check: componentName == 'chart_radar' }"
          >
            <div
              class="chart-image chart-type-radar"
              :class="{ disabled: chartDisabled }"
            ></div>
          </div>
        </el-popover>
      </div>
      <template v-if="componentName">
        <template v-if="componentName == 'chart_pie'">
          <div class="row bottom-line">
            <div class="label">饼图类型</div>
            <div class="chart-container">
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>实心</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changePieType('solid')"
                  :class="{ check: view.pieType == 'solid' }"
                >
                  <div class="chart-image chart-type-pie-1"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>粗圆环</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changePieType('thick')"
                  :class="{ check: view.pieType == 'thick' }"
                >
                  <div class="chart-image chart-type-pie-2"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>细圆环</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changePieType('thin')"
                  :class="{ check: view.pieType == 'thin' }"
                >
                  <div class="chart-image chart-type-pie-3"></div>
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-if="componentName == 'chart_multi_axes'">
          <div class="row bottom-line">
            <div class="label">双轴图类型</div>
            <div
              class="chart-container"
              v-for="item in yAxes"
              :key="item.id"
              style="flex-direction: column"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 4px;
                  font-size: 12px;
                "
              >
                {{ item.label }}
              </div>
              <div style="display: flex; justify-content: space-between">
                <el-popover
                  trigger="hover"
                  v-for="row in item.types"
                  :key="row.key"
                >
                  <div class="pop-text">
                    <div>{{ row.name }}</div>
                  </div>
                  <div
                    slot="reference"
                    class="chart-type"
                    @click="changeMultiAxesType(item.id, row.key)"
                    :class="{
                      check:
                        (!view.yAxes?.[item.id]?.chartType &&
                          row.key == 'chart_column') ||
                        view.yAxes?.[item.id]?.chartType === row.key,
                    }"
                  >
                    <div
                      class="chart-image"
                      :class="{ [row.class]: true }"
                    ></div>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </template>
        <template v-if="componentName == 'chart_process'">
          <div class="row bottom-line">
            <div class="label">进度图类型</div>
            <div class="chart-container">
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>环形进度图</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeProcessType('gauge_circle')"
                  :class="{
                    check:
                      !view.processType || view.processType == 'gauge_circle',
                  }"
                >
                  <div class="chart-image chart-type-process-1"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>半环形进度图</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeProcessType('gauge_semicircle')"
                  :class="{ check: view.processType == 'gauge_semicircle' }"
                >
                  <div class="chart-image chart-type-process-2"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>进度条图</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeProcessType('process')"
                  :class="{ check: view.processType == 'process' }"
                >
                  <div class="chart-image chart-type-process-3"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>水位图</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeProcessType('liquid')"
                  :class="{ check: view.processType == 'liquid' }"
                >
                  <div class="chart-image chart-type-process-4"></div>
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-if="componentName == 'chart_radar'">
          <div class="row bottom-line">
            <div class="label">雷达图类型</div>
            <div class="chart-container">
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>填充</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeRadarType('fill')"
                  :class="{
                    check: !view.radarType || view.radarType == 'fill',
                  }"
                >
                  <div class="chart-image chart-type-radar-1"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>线条</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeRadarType('line')"
                  :class="{ check: view.radarType == 'line' }"
                >
                  <div class="chart-image chart-type-radar-2"></div>
                </div>
              </el-popover>
            </div>
          </div>
        </template>
        <!-- 柱状图，条形图类型 -->
        <template v-if="['chart_bar', 'chart_column'].includes(componentName)">
          <div class="row bottom-line">
            <div class="label">
              {{ componentName == "chart_bar" ? "条形图类型" : "柱形图类型" }}
            </div>
            <div style="display: flex">
              <template v-for="(item, index) in columnarTypes">
                <el-popover trigger="hover" :key="index">
                  <div class="pop-text">
                    <div>{{ item.label }}</div>
                  </div>
                  <div
                    slot="reference"
                    class="chart-type"
                    @click="changeColumnarType(item.value)"
                    :class="{
                      check:
                        view.columnarType == item.value ||
                        (item.value == 'normal' && !view.columnarType),
                    }"
                  >
                    <div
                      class="chart-image"
                      :class="{
                        [item.class()]: true,
                      }"
                    ></div>
                  </div>
                </el-popover>
              </template>
            </div>
          </div>
        </template>
        <template v-if="componentName != 'metric_table'">
          <div v-if="hasChartMarker(componentName)" class="row bottom-line">
            <div class="label">线条样式</div>
            <div class="flex weui">
              <div
                class="tip"
                style="width: 55px; text-align: right; margin-right: 15px"
              >
                线性
              </div>
              <el-radio-group
                @change="changeBar"
                v-model="view.chartMarker.curve"
              >
                <el-radio :label="false"
                  ><span style="font-size: 12px">普通</span></el-radio
                >
                <el-radio :label="true"
                  ><span style="font-size: 12px">曲线</span></el-radio
                >
              </el-radio-group>
            </div>
            <div class="weui">
              <div
                class="tip"
                style="width: 55px; text-align: right; margin-right: 15px"
              >
                标记点
              </div>
              <el-checkbox
                @change="changeBar"
                v-model="view.chartMarker.symbol"
                true-label="circle"
                false-label="none"
                ><span style="font-size: 12px"
                  >显示数据标记点</span
                ></el-checkbox
              >
            </div>
          </div>
          <div v-if="view.xAxis" class="row bottom-line">
            <div class="label">坐标X轴</div>
            <div class="flex weui">
              <div class="tip">标签文字显示方向(角度)</div>
              <el-input
                v-model="view.xAxis.rotation"
                size="mini"
                placeholder=""
                style="width: 80px"
                @change="blurRotation"
              ></el-input>
            </div>
            <div class="weui">
              <el-checkbox
                @change="changeBar"
                v-model="view.xAxis.step"
                size="mini"
                ><span style="font-size: 12px"
                  >强制显示所有标签</span
                ></el-checkbox
              >
              <el-popover
                placement="top-start"
                trigger="hover"
                content="勾选此项会导致标签重叠，影响展示效果，请谨慎"
              >
                <i
                  class="el-icon-info"
                  style="color: #f0ad4e; font-size: 12px"
                ></i>
              </el-popover>
            </div>
          </div>
          <div v-if="view.yAxis" class="row bottom-line">
            <div class="label">坐标Y轴</div>
            <template v-if="componentName == 'chart_multi_axes'">
              <div v-for="item in yAxes" :key="item.id">
                <div class="tip" style="margin: 10px 0">
                  {{ item.label }}设置
                </div>
                <div class="flex weui" style="margin-bottom: 10px">
                  <div class="tip" style="width: 65px; text-align: right">
                    标题：
                  </div>
                  <el-input
                    style="flex: 1"
                    v-model="view.yAxes[item.id].title"
                    placeholder=""
                    size="mini"
                    @change="changeBar"
                  ></el-input>
                </div>
                <div class="flex weui" style="margin-bottom: 10px">
                  <div class="tip" style="width: 65px; text-align: right">
                    位置：
                  </div>
                  <el-radio-group
                    v-model="view.yAxes[item.id].titlePosition"
                    size="mini"
                    class="position-radio"
                    @change="changeTitlePosition($event, view.yAxes[item.id])"
                  >
                    <el-radio-button label="top" type="text"
                      >顶部</el-radio-button
                    >
                    <el-radio-button label="broadside" type="text"
                      >侧边</el-radio-button
                    >
                  </el-radio-group>
                </div>
                <div class="flex weui" style="margin-bottom: 10px">
                  <div class="tip" style="width: 65px; text-align: right">
                    距离：
                  </div>
                  <el-input
                    style="width: 55px"
                    v-model="view.yAxes[item.id].titleSpacing"
                    placeholder=""
                    size="mini"
                    type="number"
                    @change="changeBar"
                  ></el-input>
                  <div style="margin-left: 8px">PX</div>
                </div>
                <div class="flex weui" style="margin-bottom: 10px">
                  <div class="tip" style="width: 65px; text-align: right">
                    最大值：
                  </div>
                  <el-input
                    style="flex: 1"
                    v-model="view.yAxes[item.id].max"
                    placeholder=""
                    size="mini"
                    type="number"
                    @blur="blurMaxValue('yAxes', item.id)"
                  ></el-input>
                </div>
                <div class="flex weui">
                  <div class="tip" style="width: 65px; text-align: right">
                    最小值：
                  </div>
                  <el-input
                    style="flex: 1"
                    v-model="view.yAxes[item.id].min"
                    placeholder=""
                    size="mini"
                    type="number"
                    @blur="blurMinValue('yAxes', item.id)"
                  ></el-input>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="flex weui"
                style="margin-bottom: 10px"
                v-if="componentName != 'chart_radar'"
              >
                <div class="tip" style="width: 65px; text-align: right">
                  标题：
                </div>
                <el-input
                  style="flex: 1"
                  v-model="view.yAxis.title"
                  placeholder=""
                  size="mini"
                  @change="changeBar"
                ></el-input>
              </div>
              <template
                v-if="
                  [
                    'chart_column',
                    'chart_line',
                    'chart_area',
                    'chart_bar',
                  ].includes(componentName)
                "
              >
                <div class="flex weui" style="margin-bottom: 10px">
                  <div class="tip" style="width: 65px; text-align: right">
                    位置：
                  </div>
                  <el-radio-group
                    v-model="view.yAxis.titlePosition"
                    size="mini"
                    class="position-radio"
                    @change="changeTitlePosition($event, view.yAxis)"
                  >
                    <el-radio-button label="top" type="text"
                      >顶部</el-radio-button
                    >
                    <el-radio-button label="broadside" type="text"
                      >侧边</el-radio-button
                    >
                  </el-radio-group>
                </div>
                <div class="flex weui" style="margin-bottom: 10px">
                  <div class="tip" style="width: 65px; text-align: right">
                    距离：
                  </div>
                  <el-input
                    style="width: 55px"
                    v-model="view.yAxis.titleSpacing"
                    placeholder=""
                    size="mini"
                    type="number"
                    @change="changeBar"
                  ></el-input>
                  <div style="margin-left: 8px">PX</div>
                </div>
              </template>
              <div class="flex weui" style="margin-bottom: 10px">
                <div class="tip" style="width: 65px; text-align: right">
                  最大值：
                </div>
                <el-input
                  style="flex: 1"
                  v-model="view.yAxis.max"
                  placeholder=""
                  size="mini"
                  type="number"
                  @change="blurMaxValue('yAxis')"
                ></el-input>
              </div>
              <div class="flex weui">
                <div class="tip" style="width: 65px; text-align: right">
                  最小值：
                </div>
                <el-input
                  style="flex: 1"
                  v-model="view.yAxis.min"
                  placeholder=""
                  size="mini"
                  type="number"
                  @blur="blurMinValue('yAxis')"
                ></el-input>
              </div>
            </template>
          </div>

          <div v-if="view.chartLabel" class="row bottom-line">
            <div class="label">数据标签</div>
            <div class="flex">
              <el-checkbox
                @change="changeLabelEnable"
                v-model="view.chartLabel.enable"
                ><span style="font-size: 12px">显示数据标签</span></el-checkbox
              >
              <div
                v-if="
                  ['chart_map', 'chart_pie', 'chart_process'].includes(
                    componentName,
                  )
                "
                style="padding-left: 20px"
              >
                <template v-if="componentName != 'chart_process'">
                  <div>
                    <el-checkbox
                      @change="changeLabel"
                      v-model="view.chartLabel.displayXField"
                      style="height: 32px; line-height: 32px"
                    >
                      <span style="font-size: 12px">显示维度值</span>
                    </el-checkbox>
                  </div>
                  <div>
                    <el-checkbox
                      @change="changeLabel"
                      v-model="view.chartLabel.displayMetric"
                      style="height: 32px; line-height: 32px"
                    >
                      <span style="font-size: 12px">显示指标值</span>
                    </el-checkbox>
                  </div>
                </template>
                <div
                  v-if="['chart_pie', 'chart_process'].includes(componentName)"
                >
                  <el-checkbox
                    v-model="displayMetricPercent"
                    style="height: 32px; line-height: 32px"
                  >
                    <span style="font-size: 12px">显示百分比</span>
                  </el-checkbox>
                  <template v-if="componentName == 'chart_process'">
                    <div style="padding-left: 10px">
                      <div
                        style="display: flex; align-items: center; height: 32px"
                      >
                        <el-checkbox v-model="decimalPrecision">
                          <span style="font-size: 12px"
                            >小数位数</span
                          > </el-checkbox
                        ><el-input
                          v-if="decimalPrecision != undefined"
                          v-model="view.chartLabel.decimalPrecision"
                          type="number"
                          size="mini"
                          placeholder="1-5"
                          style="margin: 2px 10px"
                          @blur="changePrecision"
                        ></el-input>
                      </div>
                      <div
                        style="display: flex; align-items: center; height: 32px"
                      >
                        <el-checkbox v-model="content">
                          <span style="font-size: 12px">显示名称</span>
                        </el-checkbox>
                        <el-input
                          v-if="content != undefined"
                          v-model="view.chartLabel.content"
                          size="mini"
                          style="margin: 2px 10px"
                          @blur="changeContent"
                        ></el-input>
                      </div>
                    </div>
                    <div>
                      <el-checkbox
                        @change="changeLabel"
                        v-model="view.chartLabel.displayMetric"
                        style="height: 32px; line-height: 32px"
                      >
                        <span style="font-size: 12px">显示指标值</span>
                      </el-checkbox>
                    </div>
                    <el-checkbox
                      @change="changeLabel"
                      v-model="view.chartLabel.displayTarget"
                      style="height: 32px; line-height: 32px"
                    >
                      <span style="font-size: 12px">显示目标值</span>
                    </el-checkbox>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-if="view.chartLegend" class="row bottom-line">
            <div class="label">图例</div>
            <div class="flex">
              <el-checkbox @change="changeBar" v-model="view.chartLegend.enable"
                ><span style="font-size: 12px">显示图例</span></el-checkbox
              >
            </div>
            <div class="flex weui">
              <div class="tip">图例显示在图标</div>
              <el-select
                v-model="view.chartLegend.position"
                :disabled="!view.chartLegend.enable"
                placeholder=""
                size="mini"
                @change="changeBar"
              >
                <el-option label="底部" value="bottom"> </el-option>
                <el-option label="顶部" value="top"> </el-option>
                <el-option label="左侧" value="left"> </el-option>
                <el-option label="右侧" value="right"> </el-option>
              </el-select>
            </div>
          </div>
        </template>
        <div class="row bottom-line">
          <div
            class="label"
            v-if="
              ['metric_table', 'table'].includes(componentName) ||
              isShowLimitSize
            "
          >
            数据显示
          </div>
          <div
            v-if="componentName == 'table'"
            class="flex weui"
            style="font-size: 12px"
          >
            <el-checkbox
              @change="changeBar"
              v-model="view.showRowNumbers"
            ></el-checkbox>
            <span style="font-size: 12px; margin-left: 10px">显示序号</span>
          </div>
          <div class="flex weui" style="font-size: 12px" v-if="isShowLimitSize">
            <el-checkbox
              @change="handleCheckedChange"
              v-model="pageSizeEnable"
            ></el-checkbox>
            <span style="font-size: 12px; margin-left: 10px">显示前</span>
            <el-input
              v-model="limitSize"
              @blur="blurPageSize"
              placeholder=""
              style="width: 80px; margin: 0px 10px"
              size="mini"
              :disabled="!pageSizeEnable"
            ></el-input>
            <div>条数据</div>
          </div>

          <template v-if="componentName == 'metric_table'">
            <br />

            <div class="label" style="width: 100px">添加统计数值说明</div>
            <el-input
              v-model="description"
              @input="changeDescription"
              placeholder="请输入"
            ></el-input>
            <br />
            <br />
          </template>
        </div>
        <div
          class="row bottom-line"
          v-if="componentName == 'pivot_table' || componentName == 'table'"
        >
          <div class="label">操作权限</div>
          <div
            class="flex weui"
            style="font-size: 12px; color: #3870ea; height: 20px"
          >
            <el-checkbox v-model="exportExcel"></el-checkbox>
            <span style="font-size: 12px; margin-left: 10px">可导出数据</span>
          </div>
          <div
            v-if="componentName == 'table'"
            class="flex weui"
            style="font-size: 12px; color: #3870ea; height: 20px"
          >
            <el-checkbox v-model="view.preview"></el-checkbox>
            <span style="font-size: 12px; margin-left: 10px">可查看数据源</span>
          </div>
        </div>
        <template v-if="componentName == 'chart_map'">
          <div class="row bottom-line">
            <div class="label">地图类型</div>
            <div class="chart-container">
              <el-popover trigger="hover">
                <div class="pop-text">
                  <div>区域地图</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeMapType('area')"
                  :class="{ check: view.mapType == 'area' }"
                >
                  <div class="chart-image chart-type-10"></div>
                </div>
              </el-popover>
              <el-popover trigger="hover"
                ><div class="pop-text">
                  <div>气泡地图</div>
                </div>
                <div
                  slot="reference"
                  class="chart-type"
                  @click="changeMapType('bubble')"
                  :class="{ check: view.mapType == 'bubble' }"
                >
                  <div class="chart-image chart-type-10"></div></div
              ></el-popover>
            </div>
          </div>
          <div class="row bottom-line">
            <div class="label">地图显示范围</div>
            <el-cascader
              :options="mapRange"
              placeholder="请选择地图显示范围"
              :props="areaTreeProp"
              v-model="area"
              size="small"
              :show-all-levels="false"
            >
            </el-cascader>
          </div>
          <div class="row bottom-line">
            <div class="label">地图钻取</div>
            <el-select
              v-model="view.mapDrill"
              @change="changeMapDrill"
              size="small"
            >
              <el-option
                v-if="area.length < 3"
                label="可钻取到省、市"
                value="city"
              >
              </el-option>
              <el-option
                v-if="area.length == 1"
                label="可钻取到省"
                value="province"
              >
              </el-option>
              <el-option label="不启用" value="disabled"> </el-option>
            </el-select>
          </div>
        </template>
        <template>
          <div class="row bottom-line">
            <el-checkbox v-model="view.hiddenTitle">
              <span style="font-size: 12px">隐藏图表标题</span></el-checkbox
            >
          </div>
        </template>
        <!-- 饼图配置直径占比-->
        <template v-if="['chart_pie'].includes(componentName)">
          <div class="row bottom-line">
            <div class="label">配置直径占比</div>
            <div style="font-size: 12px">
              {{ `直径占比:${view.piePercentage}%` }}
            </div>
            <el-slider
              v-model="view.piePercentage"
              @input="changePieRadius"
            ></el-slider>
          </div>
        </template>
        <!-- 图形边距占比-->
        <template
          v-if="
            [
              'chart_column',
              'chart_line',
              'chart_area',
              'chart_bar',
              'chart_multi_axes',
            ].includes(componentName)
          "
        >
          <div class="row bottom-line">
            <div class="label">水平内边距</div>
            <el-slider
              v-model="horizontalDistance"
              :max="20"
              :format-tooltip="formatTooltip"
            ></el-slider>
            <div class="label">垂直内边距</div>
            <el-slider
              v-model="verticalDistance"
              :max="20"
              :format-tooltip="formatTooltip"
            ></el-slider>
          </div>
        </template>
        <!-- 原始数据下钻 -->
        <template
          v-if="
            [
              'metric_table',
              'chart_pie',
              'chart_line',
              'chart_area',
              'chart_bar',
              'chart_column',
              'pivot_table',
              'chart_process',
              'chart_radar',
              'chart_multi_axes',
            ].includes(componentName) && viewList.length
          "
        >
          <div class="row bottom-line">
            <div class="label">原始数据</div>
            <el-checkbox
              v-model="view.enableDrill"
              @change="changeDrill"
              size="mini"
              ><span style="font-size: 12px">查看原始数据</span></el-checkbox
            >
            <el-select
              v-if="view.enableDrill"
              v-model="view.drillViewId"
              placeholder="请选择下钻视图"
              style="width: 100%; margin-top: 10px"
              size="mini"
            >
              <el-option
                v-for="item in viewList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </template>
        <!-- 辅助线设置 -->
        <template
          v-if="
            ['chart_line', 'chart_bar', 'chart_column', 'chart_area'].includes(
              componentName,
            )
          "
        >
          <div class="row bottom-line">
            <div class="label">辅助线设置</div>
            <el-button
              type="text"
              icon="el-icon-plus"
              style="font-size: 12px"
              @click="addGuideline"
              >添加辅助线</el-button
            >
            <template v-if="view.guidelines">
              <div
                v-for="(guideline, index) in view.guidelines"
                class="guideline-item"
              >
                <div class="guideline-title">{{ guideline.label }}</div>
                <div>
                  <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click="editGuideline(guideline, index)"
                  ></el-button>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    @click="deleteGuideline(index)"
                  ></el-button>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
    </template>
    <template v-if="attTab == 'colorForm'">
      <div>
        <div class="row">
          <div style="position: relative">
            <div
              v-if="!['metric_table', 'chart_process'].includes(componentName)"
              class="title-label"
            >
              图表颜色
            </div>
            <div
              v-if="!['metric_table', 'chart_process'].includes(componentName)"
            >
              <template v-if="componentName == 'chart_multi_axes'">
                <div class="content-layout" v-for="row in yAxes" :key="row.id">
                  <div class="label">{{ row.label }}主题色</div>
                  <div style="flex: 1">
                    <scheme-color-select
                      :colorConfig="view.yAxes[row.id]"
                      @updateColor="updateColor($event, row.id)"
                    />
                  </div>
                </div>
              </template>
              <div class="content-layout" v-else>
                <div class="label">
                  <span v-if="['table', 'pivot_table'].includes(componentName)"
                    >表头背景</span
                  >
                  <span v-else>主题色</span>
                </div>
                <div style="flex: 1">
                  <single-color-select
                    v-if="
                      componentName == 'table' || componentName == 'pivot_table'
                    "
                    v-model="colorConfig.headBackgroundColor"
                    @updateColor="updateHeadBgColor"
                    :background="colorConfig.headBackgroundColor"
                  />
                  <single-color-select
                    v-else-if="componentName == 'chart_map'"
                    v-model="colorConfig.borderColor"
                    @updateColor="updateBorderColor"
                    :background="colorConfig.borderColor"
                  />
                  <scheme-color-select
                    v-else
                    :colorConfig="view.colorConfig"
                    @updateColor="updateColor"
                  />
                </div>
              </div>
              <!-- 表格背景 -->
              <div
                v-if="['table', 'pivot_table'].includes(componentName)"
                class="content-layout"
              >
                <div class="label">表格背景</div>
                <div style="flex: 1">
                  <single-color-select
                    v-model="colorConfig.contentBackgroundColor"
                    @updateColor="updateBodyColor"
                    :background="colorConfig?.contentBackgroundColor"
                  />
                </div>
              </div>
              <!-- 表格线框  -->
              <div
                v-if="['table', 'pivot_table'].includes(componentName)"
                class="content-layout"
              >
                <div class="label">表格线框</div>
                <div style="display: flex; flex-direction: column; flex: 1">
                  <single-color-select
                    v-model="colorConfig.borderColor"
                    @updateColor="updateBorderColor"
                    :background="colorConfig.borderColor"
                  />

                  <el-checkbox
                    @change="changeVerticaLine"
                    v-model="colorConfig.hiddenVerticaLine"
                    ><span style="font-size: 12px">隐藏竖线</span></el-checkbox
                  >
                </div>
              </div>

              <!-- 斑马纹  -->
              <div
                v-if="['table', 'pivot_table'].includes(componentName)"
                class="content-layout"
              >
                <div
                  class="label"
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div style="line-height: 20px">斑马纹&nbsp;&nbsp;</div>
                  <el-switch
                    @change="changeEnableStripe"
                    v-model="colorConfig.enableStripe"
                  >
                  </el-switch>
                </div>
                <div style="flex: 1" v-if="colorConfig.enableStripe">
                  <single-color-select
                    v-model="colorConfig.stripeColor"
                    @updateColor="updateStripeColor"
                    :background="colorConfig.stripeColor"
                  />
                </div>
              </div>
            </div>

            <template v-if="componentName == 'chart_process'">
              <div class="title-label">进度条颜色</div>
              <div class="content-layout">
                <div class="label">进度条</div>
                <div style="flex: 1">
                  <single-color-select
                    @updateColor="updateProgressColor($event, 'processColor')"
                    :background="view.processColor"
                    :positionLeft="0"
                    :isBackground="true"
                  />
                </div>
              </div>
              <div class="content-layout">
                <div class="label">底色</div>
                <div style="flex: 1">
                  <single-color-select
                    @updateColor="
                      updateProgressColor($event, 'backgroundColor')
                    "
                    :background="view.backgroundColor"
                    :positionLeft="0"
                  />
                </div>
              </div>
            </template>
            <div class="style-line"></div>
            <div class="title-label">文字颜色</div>
            <div class="content-layout">
              <div class="label">标题文字</div>
              <div style="flex: 1">
                <single-color-select
                  v-if="showS2"
                  v-model="colorConfig.titleColor"
                  @updateColor="updateTitleColor"
                  :background="colorConfig.titleColor"
                  :positionLeft="0"
                />
              </div>
            </div>
            <div
              v-if="componentName == 'table' || componentName == 'pivot_table'"
              class="content-layout"
            >
              <div class="label">表头文字</div>
              <div style="flex: 1">
                <single-color-select
                  v-model="colorConfig.headColor"
                  @updateColor="updateHeadColor"
                  :background="colorConfig.headColor"
                  :positionLeft="0"
                />
              </div>
            </div>

            <div class="content-layout">
              <div class="label">
                <span v-if="componentName == 'metric_table'">数值文字</span
                ><span v-else>内容文字</span>
              </div>
              <div style="flex: 1">
                <single-color-select
                  v-if="showS1"
                  v-model="colorConfig.contentColor"
                  @updateColor="updateContentColor"
                  :background="colorConfig.contentColor"
                  :positionLeft="0"
                />
              </div>
            </div>

            <div class="content-layout" v-if="componentName == 'metric_table'">
              <div class="label">说明文字</div>
              <div style="flex: 1">
                <single-color-select
                  v-if="showS1"
                  v-model="view.style.descriptionColor"
                  @updateColor="updateDescriptionColor"
                  :background="view.style.descriptionColor"
                  :positionLeft="0"
                />
              </div>
            </div>
          </div>
          <div class="style-line"></div>
          <div v-if="componentName == 'metric_table'">
            <div class="title-label">文字设置</div>
            <div v-if="view.style">
              <div class="content-layout">
                <div class="label">对齐方式</div>
                <div>
                  <el-radio-group
                    size="mini"
                    v-model="view.style.textAlign"
                    @input="changeOper"
                  >
                    <el-radio-button label="center">
                      <div style="width: 47px">
                        <i
                          class="iconfont icon-wd-align-center"
                          :style="
                            view.style.textAlign == 'center'
                              ? 'color: #ffffff'
                              : 'color:#42526e'
                          "
                        ></i>
                      </div>
                    </el-radio-button>
                    <el-radio-button label="left">
                      <div style="width: 47px">
                        <i
                          class="iconfont icon-wd-align-left"
                          :style="
                            view.style.textAlign == 'left'
                              ? 'color: #ffffff'
                              : 'color:#42526e'
                          "
                        ></i></div
                    ></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="content-layout">
                <div class="label">大小设置</div>
                <div>
                  <el-radio-group
                    size="mini"
                    v-model="view.style.fontStyleEnum"
                    @input="changeOper"
                  >
                    <el-radio-button label="scale">比例缩放</el-radio-button>
                    <el-radio-button label="fixed">固定大小</el-radio-button>
                  </el-radio-group>
                </div>
              </div>

              <!-- <div
                v-if="view.style.fontStyleEnum == 'none'"
                class="content-layout"
              >
                <div class="label">尺寸</div>
                <div>
                  <el-radio-group
                    @input="changeOper"
                    size="mini"
                    v-model="view.style.fontScaleEnum"
                  >
                    <el-radio-button label="big">大</el-radio-button>
                    <el-radio-button label="normal">小</el-radio-button>
                  
                  </el-radio-group>
                </div>
              </div> -->

              <div v-if="view.style.fontStyleEnum == 'fixed'">
                <div class="content-layout">
                  <div class="label">数值大小</div>
                  <div>
                    <el-select
                      size="small"
                      v-model="view.style.valueFontSize"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in fontsizeNumberOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="content-layout">
                  <div class="label">说明文字</div>
                  <div>
                    <el-select
                      size="small"
                      v-model="view.style.lableFontSize"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in fontsizeNumberOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="title-label">卡片设置</div>
            <background-config
              @updateBgColor="updateBgColor"
              :colorConfig="view.colorConfig"
            ></background-config>
          </div>
        </div>
      </div>
    </template>
    <el-dialog
      title="辅助线"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="30%"
      append-to-body
      class="guideline-config-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="guideline"
        :rules="rules"
        ref="guidelineForm"
        :validate-on-rule-change="false"
      >
        <el-form-item label="辅助线名称" prop="label">
          <el-input
            v-model="guideline.label"
            autocomplete="off"
            placeholder="请输入辅助线名称，最多20个字符"
            :maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="guideline.type">
            <el-radio label="fixed">固定辅助线</el-radio>
            <el-radio label="dynamic">动态辅助线</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="value" v-if="guideline.type == 'fixed'">
          <el-input
            v-model="guideline.value"
            autocomplete="off"
            placeholder="请输入数值"
            type="number"
            class="guideline-value"
            onmousewheel="return false"
          ></el-input>
        </el-form-item>
        <el-form-item prop="metricFieldName" v-else>
          <el-select v-model="guideline.metricFieldName" placeholder="请选择">
            <el-option
              v-for="item in view.metrics"
              :key="item.name"
              :label="item.title"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select v-model="guideline.groupOper" placeholder="请选择">
            <el-option
              v-for="item in groupOpers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示内容">
          <el-radio-group v-model="guideline.displayOption">
            <el-radio label="label">名称</el-radio>
            <el-radio label="value">数值</el-radio>
            <el-radio label="labelAndValue">名称+数值</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="颜色">
          <el-popover
            width="380"
            placement="bottom-start"
            popper-class="guideline-color-list"
            trigger="click"
          >
            <div class="color-list">
              <div
                class="color-list-item"
                :style="'text-align:center;background-color:' + sub"
                @click="guideline.color = sub"
                v-for="(sub, index) in guidelinesColorList"
                :key="index"
              >
                <i
                  v-if="guideline.color == sub"
                  class="el-icon-check"
                  style="line-height: 30px; color: #fff"
                ></i>
              </div>
            </div>
            <div class="guideline-color" slot="reference">
              <div
                :style="{ 'background-color': guideline.color }"
                style="height: 100%; width: 100%"
              ></div>
            </div>
          </el-popover>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmGuideline">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import test from "@/utils/test";
import { getAreaTree } from "@/api/contact";
import schemeColorSelect from "./detail/scheme-color-select.vue";
import backgroundConfig from "@/views/lowCode/dashboard/detail-component/background-config";
import singleColorSelect from "@/views/lowCode/dashboard/detail-component/single-color-select";
import { guidelinesColorList, FONTSIZE_NUMBER } from "./chartUtil";

import { fetchViewList } from "../api";

export default {
  props: {
    componentName: [String, Number],
    data: Object,
    nodeType: String,
    pageSize: [Number, String],
    view: Object,
  },
  components: {
    schemeColorSelect,
    backgroundConfig,
    singleColorSelect,
  },
  watch: {
    data: {
      handler(val, oldVal) {
        if (val && val.customSort && val.orderType != "custom") {
          val.orderType = "custom";
        }
      },
      deep: true,
      immediate: true,
    },
    limitSize: {
      handler(val, oldVal) {
        if (
          this.view &&
          this.view.dataSource &&
          this.view.dataSource.queryObject
        ) {
          this.view.dataSource.queryObject.limit = val;
        }
      },
    },
  },
  computed: {
    verticalDistance: {
      get() {
        if (this.view.padding) {
          return parseFloat(this.view.padding.top);
        }
      },
      set(val) {
        if (this.view.padding) {
          this.$set(this.view.padding, "top", `${val}%`);
          this.$set(this.view.padding, "bottom", `${val}%`);
          this.$nextTick(() => {
            this.$emit("resizeChart");
          });
        }
      },
    },
    horizontalDistance: {
      get() {
        if (this.view.padding) {
          return parseFloat(this.view.padding.left);
        }
      },
      set(val) {
        if (this.view.padding) {
          this.$set(this.view.padding, "left", `${val}%`);
          this.$set(this.view.padding, "right", `${val}%`);
          this.$nextTick(() => {
            this.$emit("resizeChart");
          });
        }
      },
    },
    yAxesTypes() {
      if (this.componentName == "chart_multi_axes") {
        return [
          {
            name: "柱形图",
            class: "chart-type-column-1",
            key: "chart_column",
          },
          {
            name: "折线图",
            class: "chart-type-4",
            key: "chart_line",
          },
          {
            name: "堆积柱形图",
            class: "chart-type-column-1",
            key: "stacking",
          },
          {
            name: "面积图",
            class: "chart-type-area",
            key: "chart_area",
          },
        ];
      }
      return [];
    },
    yAxes() {
      if (this.componentName == "chart_multi_axes") {
        return [
          {
            id: 0,
            label: "左轴",
            types: this.yAxesTypes,
          },
          {
            id: 1,
            label: "右轴",
            types: this.yAxesTypes,
          },
        ];
      }
      return [];
    },
    displayMetricPercent: {
      get() {
        return this.view.chartLabel.displayMetricPercent;
      },
      set(val) {
        if (val) {
          this.$set(this.view.chartLabel, "decimalPrecision", 2);
          this.$set(this.view.chartLabel, "content", "进度");
        } else {
          this.$set(this.view.chartLabel, "decimalPrecision", undefined);
          this.$set(this.view.chartLabel, "content", undefined);
        }
        this.$set(this.view.chartLabel, "displayMetricPercent", val);
        this.changeLabel();
      },
    },
    decimalPrecision: {
      get() {
        return this.view.chartLabel.decimalPrecision != undefined;
      },
      set(val) {
        if (val) {
          this.$set(this.view.chartLabel, "displayMetricPercent", val);
          this.$set(this.view.chartLabel, "decimalPrecision", 2);
        } else {
          this.$set(this.view.chartLabel, "decimalPrecision", undefined);
        }
        this.changeBar();
      },
    },
    content: {
      get() {
        return this.view.chartLabel.content != undefined;
      },
      set(val) {
        if (val) {
          this.$set(this.view.chartLabel, "displayMetricPercent", val);
          this.$set(this.view.chartLabel, "content", "进度");
        } else {
          this.$set(this.view.chartLabel, "content", undefined);
        }
        this.changeBar();
      },
    },

    isShowLimitSize() {
      //支持显示前N条设置的图表：透视图、柱状图、条状图、明细表
      //规则：透视图、柱状图、条状图仅1个维度时，支持显示N条设置；多个维度不支持显示N条设置
      const arr = ["chart_column", "chart_bar", "pivot_table", "table"];
      if (this.view && arr.includes(this.componentName)) {
        if (this.componentName === "table") {
          return true;
        } else {
          if (this.view.xFields.length < 2) {
            return true;
          }
        }
      }
      return false;
    },
    exportExcel: {
      get() {
        if (this.view) {
          return this.view.export;
        } else {
          return false;
        }
      },
      set(val) {
        let view = this._.cloneDeep(this.view);
        view.export = val;
        this.$emit("update:view", view);
      },
    },

    chartMapDisabled() {
      return (
        this.view.xFields.findIndex(
          (item) =>
            !["position_input", "address_input"].includes(
              item.field.componentName,
            ),
        ) >= 0
      );
    },
    chartPieDisabled() {
      return this.view.metrics.length > 1 || this.view.xFields.length > 1;
    },
    metricsDisabled() {
      return this.view.metrics.length > 1 || this.view.xFields.length > 1;
    },
    progressDisabled() {
      return this.view.xFields.length > 0;
    },
    multiAxesDisabled() {
      return this.view.xFields.length > 1;
    },
    chartDisabled() {
      let arr = ["table"];
      return arr.includes(this.componentName);
    },

    pieType: {
      get() {
        return this.view.pieType;
      },
      set(val) {
        this.$set(this.view, "pieType", val);
      },
    },
    area: {
      get() {
        if (this.view.mapRange.provinceId && this.view.mapRange.cityId) {
          return [-2, this.view.mapRange.provinceId, this.view.mapRange.cityId];
        } else if (this.view.mapRange.provinceId) {
          return [-1, this.view.mapRange.provinceId];
        } else {
          return [0];
        }
      },
      set(val) {
        if (val[0] == 0) {
          this.$set(this.view, "mapRange", {
            provinceId: "",
            province: "",
            cityId: "",
            city: "",
            countyId: "",
            county: "",
          });
          this.$set(this.view, "mapDrill", "city");
        } else if (val[0] == -1) {
          this.$set(this.view, "mapRange", {
            provinceId: val[1],
            province: this.mapRange[1].children.find(
              (item) => item.id == val[1],
            ).name,
            cityId: "",
            city: "",
            countyId: "",
            county: "",
          });
          this.$set(this.view, "mapDrill", "city");
        } else if (val[0] == -2) {
          let province = this.mapRange[2].children.find(
            (item) => item.id == val[1],
          );
          let city = province.children.find((item) => item.id == val[2]);
          this.$set(this.view, "mapRange", {
            provinceId: val[1],
            province: province.name,
            cityId: val[2],
            city: city.name,
            countyId: "",
            county: "",
          });
          this.$set(this.view, "mapDrill", "disabled");
        }
        this.changeOper();
      },
    },
  },
  data() {
    return {
      pageSizeEnable: false,
      limitSize: "",

      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
      },
      mapRange: [
        {
          name: "中国",
          id: 0,
          hasChild: false,
        },
        {
          name: "省份",
          id: -1,
          hasChild: true,
          children: [],
        },
        {
          name: "城市",
          id: -2,
          hasChild: true,
          children: [],
        },
      ],
      attTab: "dataForm",
      remindColorTypeCode: [],
      description: "",
      viewList: [],
      showS1: true,
      showS2: true,
      dialogVisible: false,
      guideline: {},
      groupOpers: [
        {
          label: "最大值",
          value: "max",
        },
        {
          label: "最小值",
          value: "min",
        },
        {
          label: "平均值",
          value: "avg",
        },
      ],
      rules: {
        label: [{ required: true, message: "请输入名称", trigger: "blur" }],
        value: [{ required: true, message: "请输入数值", trigger: "blur" }],
        metricFieldName: [
          { required: true, message: "请选择指标", trigger: "change" },
        ],
      },
      currentGuideline: -1,
      guidelinesColorList: guidelinesColorList,
      fontsizeNumberOption: FONTSIZE_NUMBER,
      colorConfig: {},

      columnarTypes: [
        {
          label:
            this.componentName == "chart_column" ? "普通柱形图" : "普通条形图",
          value: "normal",
          class: () => {
            return this.componentName == "chart_column"
              ? "chart-type-column-1"
              : "chart-type-bar-1";
          },
        },
        {
          label:
            this.componentName == "chart_column" ? "堆积柱形图" : "堆积条形图",
          value: "stacking",
          class: () => {
            return this.componentName == "chart_column"
              ? "chart-type-column-2"
              : "chart-type-bar-2";
          },
        },
        {
          label:
            this.componentName == "chart_column"
              ? "百分比堆积柱形图"
              : "百分比堆积条形图",
          value: "percentageStacking",
          class: () => {
            return this.componentName == "chart_column"
              ? "chart-type-column-3"
              : "chart-type-bar-3";
          },
        },
      ],
    };
  },

  created() {
    if (
      this.view &&
      this.view.dataSource &&
      this.view.dataSource.queryObject &&
      this.view.dataSource.queryObject.limit
    ) {
      this.limitSize = this.view.dataSource.queryObject.limit;
    }
    if (this.limitSize > 0) {
      this.pageSizeEnable = true;
    }
    if (this.view) {
      if (this.view.description) {
        this.description = this.view.description;
      }
      this.colorConfig = this.view.colorConfig;
      if (!this.view.piePercentage) {
        this.$set(this.view, "piePercentage", 75);
      }
    }

    this.getAreaTree();
    if (this.view.dataSource?.formId) {
      fetchViewList({
        componentNames: ["table"],
        dataSourceFormId: this.view.dataSource.formId,
        pageSize: 200,
      }).then((res) => {
        this.viewList = res.data.page.list;
      });
    }
  },

  methods: {
    formatTooltip(val) {
      if (val == 0) {
        return "自适应";
      } else {
        return `${val}%`;
      }
    },
    changeTitlePosition(val, yAxis) {
      if (val == "top") {
        yAxis.titleSpacing = 12;
      } else {
        yAxis.titleSpacing = 24;
      }
      this.changeBar();
    },
    changePieRadius() {
      this.changeOper();
    },
    hasChartMarker(componentName) {
      if (componentName == "chart_multi_axes") {
        return this.view.yAxes.some((row) =>
          ["chart_line", "chart_area"].includes(row.chartType),
        );
      }
      return (
        ["chart_line", "chart_area"].includes(componentName) &&
        this.view.chartMarker
      );
    },
    changeColumnarType(val) {
      if (val == this.view.columnarType) {
        return;
      }
      this.$set(this.view, "columnarType", val != "normal" ? val : "");
      this.changeBar();
    },
    changePrecision() {
      if (!/^[1-5]$/g.test(this.view.chartLabel.decimalPrecision)) {
        this.$set(this.view.chartLabel, "decimalPrecision", 2);
      }
      this.changeBar();
    },
    changeContent() {
      if (!this.view.chartLabel?.content) {
        this.$set(this.view.chartLabel, "content", "进度");
      }
      this.changeBar();
    },

    editGuideline(guideline, index) {
      this.currentGuideline = index;
      this.guideline = this.deepClone(guideline);
      this.dialogVisible = true;
    },
    deleteGuideline(index) {
      this.view.guidelines.splice(index, 1);
      this.changeBar();
    },
    initGuideLineData() {
      this.currentGuideline = -1;
      this.guideline = {
        label: "",
        type: "fixed",
        value: "",
        metricFieldName: "",
        groupOper: "avg",
        displayOption: "labelAndValue",
        color: "#3870EA",
      };
    },
    confirmGuideline() {
      this.$refs["guidelineForm"].validate((valid) => {
        if (valid) {
          if (this.view.guidelines) {
            if (this.currentGuideline > -1) {
              this.view.guidelines[this.currentGuideline] = this.guideline;
            } else {
              this.view.guidelines.push(this.guideline);
            }
          } else {
            this.$set(this.view, "guidelines", [this.guideline]);
          }
          this.dialogVisible = false;
          this.changeBar();
        }
      });
    },
    addGuideline() {
      this.initGuideLineData();
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    /** 下钻视图 */
    changeDrill(val) {
      if (val) {
        if (this.viewList.length) {
          this.view.drillViewId = this.viewList[0].id;
        }
      } else {
        this.view.drillViewId = "";
      }
    },
    //柱状图、条形图、折线图、饼状图、双轴图自定义颜色
    updateColor(value, index) {
      if (this.componentName == "chart_multi_axes") {
        this.$set(this.view.yAxes[index], "colorScheme", value);
      } else {
        const colorConfig = this.view.colorConfig;
        colorConfig.colorScheme = value;
        this.$set(this.view, "colorConfig", colorConfig);
      }
      this.changeOper();
    },

    //背景色设置
    updateBgColor(background, backgroundEnum, backgroundImageFillEnum) {
      const colorConfig = this.view.colorConfig;
      if (!background && this.componentName == "metric_table") {
        background = "#ffffff";
      }
      if (backgroundEnum == "color") {
        colorConfig.backgroundColor = background;
      } else {
        colorConfig.backgroundImage = background;
      }
      colorConfig.backgroundEnum = backgroundEnum;
      colorConfig.backgroundImageFillEnum = backgroundImageFillEnum;
      this.$set(this.view, "colorConfig", colorConfig);
      // this.changeOper();
    },
    //进度条颜色
    updateProgressColor(color, label) {
      this.$set(this.view, label, color);
      this.changeBar();
    },
    //标题颜色
    updateTitleColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.titleColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
    },
    //内容颜色 /指标图对应数值颜色
    updateContentColor(background) {
      if (!background && this.componentName == "metric_table") {
        background = "#42526e";
      }
      const colorConfig = this.view.colorConfig;
      colorConfig.contentColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //指标图描述文字颜色
    updateDescriptionColor(background) {
      const colorConfig = this.view.style;
      colorConfig.descriptionColor = background;
      this.$set(this.view, "style", colorConfig);
      this.changeOper();
    },

    //表格表头颜色
    updateHeadColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.headColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //表格表头背景/配色方案
    updateHeadBgColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.headBackgroundColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //表格背景颜色
    updateBodyColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.contentBackgroundColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //表格线框颜色
    updateBorderColor(background) {
      const colorConfig = this.view.colorConfig;
      colorConfig.borderColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //斑马线颜色
    updateStripeColor(background) {
      if (background === "") {
        background = "var(--zgg-background-base)";
      }
      const colorConfig = this.view.colorConfig;
      colorConfig.stripeColor = background;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //隐藏竖线
    changeVerticaLine(val) {
      const colorConfig = this.view.colorConfig;
      colorConfig.hiddenVerticaLine = val;
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    changeEnableStripe(val) {
      const colorConfig = this.view.colorConfig;
      colorConfig.stripeColor = "var(--zgg-background-base)";
      this.$set(this.view, "colorConfig", colorConfig);
      this.changeOper();
    },
    //指标图添加统计数值说明
    changeDescription() {
      this.$set(this.view, "description", this.description);

      this.changeOper();
    },
    changeMapDrill() {
      this.changeOper();
    },
    getAreaTree() {
      getAreaTree().then((res) => {
        res.data.tree.forEach((province) => {
          this.mapRange[1].children.push({
            name: province.name,
            id: province.id,
            hasChild: false,
          });
          let p2 = {
            name: province.name,
            id: province.id,
            hasChild: true,
            children: [],
          };

          if (
            ["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
              province.id,
            )
          ) {
            // 四个直辖市和港澳台地区特殊处理
            p2.children.push({
              name: province.name,
              id: province.id,
              hasChild: false,
            });
          } else {
            province.children.forEach((city) => {
              if (
                city.extAttr &&
                city.extAttr.code &&
                city.extAttr.code.indexOf("EMPTY") == -1
              ) {
                p2.children.push({
                  name: city.name,
                  id: city.id,
                  hasChild: false,
                });
              }
            });
          }

          this.mapRange[2].children.push(p2);
        });
      });
    },
    changeLabelEnable(val) {
      if (
        ["chart_pie", "chart_map", "chart_process"].includes(this.componentName)
      ) {
        for (let key in this.view.chartLabel) {
          if (!["enable", "decimalPrecision", "content"].includes(key)) {
            this.$set(this.view.chartLabel, key, val);
          }
        }
      }
      this.changeBar();
      // this.view.chartLabel
    },
    changeMapType(mapType) {
      this.view.mapType = mapType;
      this.changeOper();
    },
    changeLabel(val) {
      let values = [];
      for (let key in this.view.chartLabel) {
        let excludes = ["enable", "decimalPrecision", "content"];
        if (this.componentName == "chart_process") {
          excludes = [...excludes, "displayXField"];
        } else if (this.componentName == "chart_map") {
          excludes = [...excludes, "displayTarget", "displayMetricPercent"];
        } else if (this.componentName == "chart_pie") {
          excludes = [...excludes, "displayTarget"];
        }
        if (!excludes.includes(key)) {
          values.push(this.view.chartLabel[key]);
        }
      }
      if (!values.some((val) => val)) {
        this.$set(this.view.chartLabel, "enable", false);
      } else if (!values.some((val) => !val)) {
        this.$set(this.view.chartLabel, "enable", true);
      }
      this.changeBar();
    },
    changeBar() {
      this.$nextTick(() => {
        this.$emit("changeBar");
      });
    },
    blurRotation() {
      if (!test.number(this.view.xAxis.rotation)) {
        this.view.xAxis.rotation = "";
      }
      this.changeBar();
    },
    blurMaxValue(prop, index) {
      if (this.componentName == "chart_multi_axes") {
        let value = this.view[prop][index].max;
        if (!/^(\-|\+)?\d+(\.\d+)?$/g.test(value)) {
          this.$set(this.view[prop][index], "max", "");
        } else {
          this.$set(this.view[prop][index], "max", parseFloat(value));
        }
      } else {
        let value = this.view[prop].max;
        if (!/^(\-|\+)?\d+(\.\d+)?$/g.test(value)) {
          this.$set(this.view[prop], "max", "");
        } else {
          this.$set(this.view[prop], "max", parseFloat(value));
        }
      }
      this.changeBar();
    },
    blurMinValue(prop, index) {
      if (this.componentName == "chart_multi_axes") {
        let value = this.view[prop][index].min;
        if (!/^(\-|\+)?\d+(\.\d+)?$/g.test(value)) {
          this.$set(this.view[prop][index], "min", "");
        } else {
          this.$set(this.view[prop][index], "min", parseFloat(value));
        }
      } else {
        let value = this.view[prop].min;
        if (!/^(\-|\+)?\d+(\.\d+)?$/g.test(value)) {
          this.$set(this.view[prop], "min", "");
        } else {
          this.$set(this.view[prop], "min", parseFloat(value));
        }
      }
      this.changeBar();
    },

    changeOper() {
      this.$nextTick(() => {
        this.$emit("changeOper");
      });
    },
    blurPageSize() {
      if (
        test.digits(this.limitSize) &&
        this.limitSize >= 1 &&
        this.limitSize <= 1000
      ) {
        this.limitSize = parseInt(this.limitSize, 10);
      } else {
        this.limitSize = "";
        this.$message.warning("仅支持1～1000的正整数");
      }

      this.changeOper();
    },
    handleCheckedChange(val) {
      if (!val) {
        this.limitSize = "";
        this.changeOper();
      }
    },
    changePieType(pieType) {
      if (pieType == this.view.pieType) {
        return;
      }
      this.$set(this.view, "pieType", pieType);
      this.changeBar();
    },
    changeMultiAxesType(index, type) {
      if (this.view.yAxes[index].chartType == type) return;
      this.$set(this.view.yAxes[index], "chartType", type);
      this.changeBar();
    },
    changeProcessType(processType) {
      if (processType == this.view.processType) {
        return;
      }
      this.$set(this.view, "processType", processType);
      this.changeBar();
    },
    changeRadarType(pieType) {
      if (pieType == this.view.pieType) {
        return;
      }
      this.$set(this.view, "radarType", pieType);
      this.changeBar();
    },
    changeViewType(componentName) {
      if (componentName == this.componentName) {
        return;
      }
      if (componentName == "metric_table" && this.metricsDisabled) {
        return;
      }
      if (componentName == "chart_map" && this.chartMapDisabled) {
        return;
      }
      if (componentName == "table" && !this.chartDisabled) {
        return;
      }
      if (componentName == "chart_process" && this.progressDisabled) {
        return;
      }
      if (componentName == "chart_pie" && this.chartPieDisabled) {
        return;
      }
      if (componentName == "chart_multi_axes" && this.multiAxesDisabled) {
        return;
      }
      if (this.chartDisabled) {
        return;
      }
      if (componentName == "chart_map") {
        this.$set(this.view, "chartLabel", {
          displayXField: true,
          displayMetric: true,
          displayMetricPercent: true,
          enable: true,
        });
        this.$set(this.view, "mapType", "area");
        this.$set(this.view, "mapDrill", "city");
        this.$set(this.view, "mapRange", {
          provinceId: "",
          province: "",
          cityId: "",
          city: "",
          countyId: "",
          county: "",
        });
      }
      if (componentName == "chart_process") {
        this.$set(this.view, "chartLabel", {
          displayXField: true,
          displayMetric: false,
          displayMetricPercent: true,
          enable: true,
          displayTarget: false,
          decimalPrecision: 2,
          content: "进度",
        });
        this.$set(this.view, "metrics", []);
      }
      if (componentName == "chart_multi_axes") {
        this.$set(this.view, "yAxes", [
          { chartType: "chart_column", colorScheme: "scheme_c" },
          { chartType: "chart_line", colorScheme: "scheme_a" },
        ]);
        this.view.metrics.forEach((item, index) => {
          if (index == 0) {
            item.metricType = "left";
          } else {
            item.metricType = "right";
          }
        });
      }
      // 切换图表类型清空列维度
      this.$set(this.view, "yFields", []);
      // 切换图表类型清空目标值
      this.$set(this.view, "targetValues", []);
      if (
        this.view.dataSource.queryObject &&
        this.view.dataSource.queryObject.yFields
      ) {
        this.view.dataSource.queryObject.yFields = [];
      }
      this.$emit("update:componentName", componentName);

      this.$emit("changeSort");
      this.$emit("resetChartColumn");

      this.$emit("changeOper");
      //emit changeViewType事件到父组件，为了解决切换视图类型时，触发指标和纬度
      this.$emit("changeViewType");
    },
    switchAttTab(name) {
      if (this.attTab == name) {
        return;
      }
      this.attTab = name;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .guideline-value input::-webkit-outer-spin-button,
::v-deep .guideline-value input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .guideline-value input[type="number"] {
  -moz-appearance: textfield;
}
.guideline-config-dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
}
.color-list {
  display: flex;
  .color-list-item {
    margin: 4px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ebecf0;
    cursor: pointer;
  }
}
.guideline-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 12px;
  width: 189px;
  height: 36px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  cursor: pointer;
}
.guideline-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;
  font-family: "PingFang SC";
  padding-bottom: 10px;
  .guideline-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
  .el-button--text {
    color: unset;
    &:hover {
      color: #3870ea;
    }
  }
  .el-button {
    padding: 0;
  }
}
::v-deep .el-color-picker__trigger {
  width: 200px;
}
.el-form-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
  ::v-deep .el-form-item__label {
    text-align: left;
  }
  ::v-deep .el-form-item__content {
    line-height: unset;
  }
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
.pop-text {
  font-size: 12px;
  line-height: 1.5;
}
.chart-type {
  width: 44px;
  height: 44px;
  //   border: solid 2px transparent;
  box-sizing: border-box;
  margin: 0px 5px 5px 0px;
  position: relative;
  &.check {
    &::after {
      display: block;
      content: " ";
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      border: solid 2px #3870ea;
    }
  }
}
.chart-image {
  width: 44px;
  height: 44px;
  background-image: url("../../../../assets/dash_chart_types.png");
  background-size: 660px 132px;
}
.chart-type-detail {
  background-position-x: -132px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -132px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-0 {
  background-position-x: 0px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: 0px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-process {
  background-position-x: -44px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -44px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-1 {
  background-position-x: -88px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -88px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-2 {
  background-position-x: -176px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -176px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-3 {
  background-position-x: -220px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -220px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-4 {
  background-position-x: -264px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -264px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-area {
  background-position-x: -308px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -308px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-multi_axes {
  background-position-x: -482px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -482px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-7 {
  background-position-x: -352px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -352px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-9 {
  background-position-x: -438px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -438px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-radar {
  background-position-x: -396px;
  background-position-y: 0px;
  &.disabled {
    background-position-x: -396px;
    background-position-y: -44px;
    cursor: not-allowed;
  }
}
.chart-type-process-1 {
  background-position-x: 0px;
  background-position-y: -88px;
}
.chart-type-process-2 {
  background-position-x: -44px;
  background-position-y: -88px;
}
.chart-type-process-3 {
  background-position-x: -88px;
  background-position-y: -88px;
}
.chart-type-process-4 {
  background-position-x: -132px;
  background-position-y: -88px;
}
.chart-type-column-1 {
  background-position-x: -176px;
  background-position-y: -88px;
}
.chart-type-column-2 {
  background-position-x: -220px;
  background-position-y: -88px;
}
.chart-type-column-3 {
  background-position-x: -264px;
  background-position-y: -88px;
}
.chart-type-bar-1 {
  background-position-x: -308px;
  background-position-y: -88px;
}
.chart-type-bar-2 {
  background-position-x: -352px;
  background-position-y: -88px;
}
.chart-type-bar-3 {
  background-position-x: -396px;
  background-position-y: -88px;
}
.chart-type-pie-1 {
  background-position-x: -440px;
  background-position-y: -88px;
}
.chart-type-pie-2 {
  background-position-x: -484px;
  background-position-y: -88px;
}
.chart-type-pie-3 {
  background-position-x: -528px;
  background-position-y: -88px;
}
.chart-type-radar-1 {
  background-position-x: -572px;
  background-position-y: -88px;
}
.chart-type-radar-2 {
  background-position-x: -616px;
  background-position-y: -88px;
}

.chart-type-10 {
  background-position-x: -438px;
}
.chart-type-11 {
  background-position-x: -482px;
}
.chart-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}
.row {
  margin: 10px;
  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
    margin: 5px 0px;
    color: var(--zgg-font-text-regular);
  }
}
.flex {
  flex: 1;
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /* 火狐浏览器 */
  ::v-deep input[type="number"] {
    -moz-appearance: textfield;
  }
}
.weui {
  display: flex;
  align-items: center;
}
.tip {
  font-size: 12px;
  margin-right: 5px;
}
.bottom-line {
  border-bottom: solid 1px #f4f5f7;
  padding-bottom: 10px;
}

.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
  padding: 0 20px;
}
.tab-txt {
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  padding: 0px 20px;
  flex: 1;
  cursor: pointer;

  &.on {
    color: var(--zgg-brand-color-6);
    cursor: default;
    border-bottom: 1px solid var(--zgg-brand-color-6);
  }
}

.nav-tab {
  display: flex;
}
.title-label {
  padding: 20px 0 10px 0;
  font-weight: bold;
  color: var(--zgg-font-text-primary);
}
.content-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 0;
  align-items: center;
}

::v-deep .el-slider__runway {
  margin: 10px 0;
}
.position-radio {
  padding: 4px;
  border: 1px solid #ebecf0;
  border-radius: 5px;
  ::v-deep .el-radio-button__inner {
    border: unset;
    border-radius: 4px;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #eef0ff;
    color: rgba(56, 112, 234, 1);
    box-shadow: unset;
  }
}
::v-deep {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 10px;
  }
}
.style-line {
  background: #ebecf0;
  height: 1px;
  margin-top: 12px;
}
</style>
<style lang="scss">
.guideline-color-list {
  padding: 4px;
}
</style>
