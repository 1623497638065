<template>
  <el-dialog
    @open="open"
    title="甘特图预览"
    :visible.sync="dialogVisible"
    width="1000px"
    append-to-body
  >
    <div class="body">
      <gantt-view
        ref="gantt"
        :columns="columns"
        :list="list"
        :isProcess="true"
        :chartLabelFieldName="gantt.chartLabelFieldName"
        :timeProgresses="timeProgresses"
        :cycle="gantt.zoomLevel"
        v-if="dialogVisible"
        class="body-flex"
      ></gantt-view>
    </div>
  </el-dialog>
</template>
<script>
import { getGanttData } from "../api";

export default {
  props: {
    gantt: Object,
    value: Boolean,
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      columns: [],
      list: [],
      timeProgresses: [],
    };
  },
  destroyed() {
    this.dialogVisible = false;
  },

  methods: {
    open() {
      let columns = [];
      this.gantt.xFields.forEach((item) => {
        columns.push({
          label: item.title,
          prop: item.field.name,
          width: "",
          field: item.field,
        });
      });
      this.columns = columns;
      let ganttView = this._.cloneDeep(this.gantt);

      ganttView.xFields.forEach((item) => {
        delete item.visible;
        delete item.sortVisible;
      });
      let timeProgresses = ganttView.timeProgresses.filter(
        (item) => item.startTimeField && item.endTimeField
      );
      timeProgresses.forEach((item) => {
        delete item.isDiffParent;
        delete item.isEmpty;
      });
      ganttView.timeProgresses = timeProgresses;
      this.timeProgresses = timeProgresses;

      getGanttData({ ganttView: JSON.stringify(ganttView) }).then((res) => {
        let list = res.data.list;
        this.list = list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  padding: 10px;
  max-height: 500px;
  overflow: hidden;
  box-sizing: border-box;
  .body-flex {
    height: 480px;
  }
}
</style>