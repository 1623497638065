<template>
  <div>
    <el-button
      style="width: 100%"
      class="white-border"
      plain
      size="small"
      icon="el-icon-plus"
      @click="visible = true"
      >添加任务
    </el-button>
    <el-dialog
      width="768px"
      :visible.sync="visible"
      :append-to-body="true"
      @closed="closed"
      @close="close"
      :before-close="closeTask"
      @open="open"
      class="cardDialog"
      destroy-on-close
    >
      <div class="dia-header" slot="title">
        <div class="card-header"><b>添加任务</b></div>
      </div>
      <div
        style="padding: 0px 25px 10px"
        tabindex="0"
        @paste="paste"
        ref="main"
      >
        <el-form
          class="card-form"
          :model="card"
          ref="cardForm"
          :rules="rules"
          label-position="top"
        >
          <el-form-item
            prop="bulletinFormId"
            label="任务类型"
            v-if="this.currentBulletin.hasFormPlugin"
          >
            <el-select
              v-model="card.bulletinFormId"
              placeholder="请选择"
              style="width: 100%"
              @change="handleBulletinFormIdChange"
            >
              <el-option key="0" label="通用" value="0"> </el-option>
              <el-option
                v-for="item in bulletinPluginFormOptions"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <FormItem ref="dynamicForm"></FormItem>
          <el-form-item prop="title" label="任务名称">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入任务名称"
              v-model="card.title"
              resize="none"
              style="font-size: 20px; min-height: 42px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              placeholder="请输入备注"
              v-model="card.remark"
              resize="none"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="责任人">
            <el-tag
              type="success"
              style="margin-bottom: 10px; margin-right: 10px"
              v-for="(user, index) in card.cardUserList"
              :key="user.id"
              closable
              @close="deleteUser(index)"
              >{{ user.userName }}
            </el-tag>

            <el-button
              type="primary"
              size="mini"
              style="margin-right: 10px; height: 30px"
              @click="addUser"
              >添加责任人
            </el-button>
          </el-form-item>
          <el-form-item label="标签">
            <el-tag
              v-for="(tag, index) in card.tagList"
              :key="index"
              closable
              :color="tag.backgroundColor"
              class="eltag"
              @click="editTag(tag)"
              @close="deleteTag(index)"
              style="margin-right: 10px"
              >{{ tag.title }}
            </el-tag>
            <el-button
              @click="editVisible = true"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              class="add-tag"
            ></el-button>
          </el-form-item>
          <el-form-item label="封面">
            <div
              v-if="card.coverImage"
              class="image"
              @mouseenter="coverVisible = true"
              @mouseleave="coverVisible = false"
            >
              <el-image
                ref="imageCover"
                style="width: 80px; height: 80px"
                :src="card.coverImage"
                :preview-src-list="[card.coverImage]"
                :z-index="9999"
              >
              </el-image>
              <div class="cover-image-container" v-if="coverVisible">
                <i
                  class="el-icon-view"
                  @click="previewCover"
                  style="font-size: 18px; color: #ffffff"
                ></i>
                <i
                  class="el-icon-delete"
                  @click="deleteCover"
                  style="margin-left: 15px; font-size: 18px; color: #ffffff"
                ></i>
              </div>
            </div>
            <upload
              v-else
              style="padding: 0; margin-left: 10px"
              ref="imageUpload"
              @uploadSuccess="handleUploadChange"
              :multiple="false"
              accept="image/*"
              list-type="picture-card"
              class="coverUpload"
              :limit="1"
            >
              <i class="el-icon-plus"></i>
            </upload>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item
                :required="true"
                prop="endTime"
                label="截止日期"
                v-if="this.currentBulletin.hasSchedulePlugin"
              >
                <el-date-picker
                  v-model="card.endTime"
                  type="datetime"
                  style="width: 100%"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :required="true"
                prop="remindMinutes"
                label="提醒设置"
                v-if="this.currentBulletin.hasSchedulePlugin"
              >
                <el-select
                  v-model="card.remindMinutes"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in remindOptions"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="附件">
            <div>
              <upload
                style="padding: 0; margin-left: 0px"
                ref="fileUpload"
                @uploadSuccess="uploadAttachment"
                class="coverUpload"
              >
                <el-button type="primary" size="mini">添加附件</el-button>
              </upload>

              <attachment-list :attachment-list="card.attachmentList">
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    icon="el-icon-paperclip"
                    @click="handleAttachmentDownload(scope.row)"
                    >下载
                  </el-link>
                  <el-link
                    v-if="canPreview(scope.row.extension)"
                    type="primary"
                    icon="el-icon-view"
                    :href="scope.row.url"
                    target="_blank"
                    :download="scope.row.originalName"
                    >查看
                  </el-link>
                  <el-link
                    type="danger"
                    icon="el-icon-delete"
                    @click="handleAttachmentDelete(scope.row)"
                    >删除
                  </el-link>
                </template>
              </attachment-list>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <el-dialog
        title="编辑标签"
        :append-to-body="true"
        :destroy-on-close="true"
        :visible.sync="editVisible"
        width="600px"
        custom-class="tagDilog"
      >
        <div style="padding: 10px 20px 0px 0px">
          <el-form
            :model="tagForm"
            ref="tagForm"
            :rules="rules"
            label-width="80px"
            :inline="false"
            size="normal"
          >
            <el-form-item label="名称" prop="label">
              <el-input v-model="tagForm.label"></el-input>
            </el-form-item>
            <el-form-item>
              <ul class="tag-list">
                <li
                  @click="choseTag(tag)"
                  class="tag-item"
                  :style="`background-color:${tag.value}`"
                  v-for="tag in tagList"
                  :key="tag.value"
                >
                  <i
                    v-if="tag.value == tagForm.value"
                    class="el-icon-check"
                    style="color: #ffffff"
                  ></i>
                </li>
              </ul>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer">
          <el-button @click="cancelEdit" size="small">取消</el-button>
          <el-button @click="saveTag" type="primary" size="small"
            >确定</el-button
          >
        </span>
      </el-dialog>
      <span slot="footer">
        <el-button @click="closeTask">取消</el-button>
        <el-button @click="saveCard" :loading="loading" type="primary"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addCardAttachment,
  bulletinCardDetail,
  bulletinCardSave,
  bulletinCardSetTime,
  bulletinCardUserSaveBatch,
  bulletinDetail,
  bulletinPluginFormList,
  saveBulletinTag,
  setCardCoverImage,
} from "@/api/boardManage";
import { mapGetters } from "vuex";
import { deepClone } from "@/utils";
import Upload from "@/components/Upload/indexNew";
import AttachmentList from "@/components/AttachmentList2";
import { downFile } from "@/utils/ali-oss";
import { getFileExtension } from "@/utils/enums";
import FormItem from "./FormItem";
import { upload as uploadFile } from "@/utils/upload";

export default {
  components: { Upload, AttachmentList, FormItem },
  computed: {
    ...mapGetters(["currentUser"]),
    disabled() {
      return this.currentUser.id != this.card.creatorId;
    },
  },
  props: {
    cardId: [String, Number],
    bulletinId: [String, Number],
    bulletinColumnId: [String, Number],
  },
  mounted() {},
  data() {
    return {
      loading: false,
      coverVisible: false,
      visible: false,
      commentLoading: false,
      showComment: false,
      comment: "",
      commentList: [],
      editVisible: false,
      tagVisible: false,
      tagForm: {},
      rules: {
        label: [{ required: true, message: "请输入名称" }],
        title: [{ required: true, message: "请输入任务名称" }],
        endTime: [{ required: true, message: "截止日期不能为空" }],
        remindMinutes: [{ required: true, message: "提醒设置不能为空" }],
      },
      tagList: [
        {
          label: "紧急",
          value: "#DC143C",
        },
        {
          label: "重要",
          value: "#f2d600",
        },
      ],
      card: {},

      membersSelectDialogVisible: false,
      currentBulletin: {},
      bulletinPluginFormOptions: [],
      itemList: [],
      formId: 0,
      formInstanceId: 0,
      /**
       *
       0 表示准时提醒 (在到期时间)
       -1 表示不提醒(无)
       5 表示 提前5分钟
       60 表示提前 1小时
       1440 表示提前1天
       2880 表示提前2天
       */
      remindOptions: [
        {
          id: -1,
          title: "无",
        },
        {
          id: 0,
          title: "准时",
        },
        {
          id: 5,
          title: "提前五分钟",
        },
        {
          id: 60,
          title: "提前1小时",
        },
        {
          id: 1440,
          title: "提前1天",
        },
        {
          id: 2880,
          title: "提前2天",
        },
      ],
    };
  },
  methods: {
    paste(e) {
      if (!(e.clipboardData && e.clipboardData.items)) {
        alert("该浏览器不支持操作");
        return;
      }

      for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
        let item = e.clipboardData.items[i];
        if (item.kind === "file" && item.type.indexOf("image") >= 0) {
          let pasteFile = item.getAsFile();
          uploadFile({ file: pasteFile }, (res) => {
            if (!this.card.coverImage) {
              // 没有封面当封面
              this.handleUploadChange(res);
            } else {
              // 有封面当附件
              this.uploadAttachment(res);
            }
          });
        }
        // console.log(item);
      }
    },
    /**
     * 监听表单
     */
    handleBulletinFormIdChange(val) {
      this.$forceUpdate();
      if (val != 0) {
        let filterOptionItem = this.bulletinPluginFormOptions.find(
          (item) => item.id == val,
        );
        // this.formId = filterOptionItem && filterOptionItem.formId
        this.$refs.dynamicForm.initByFormId(
          filterOptionItem && filterOptionItem.formId,
        );
      } else {
        this.$refs.dynamicForm.resetForm();
      }
    },

    /**
     * 获取看板详情
     */
    apiGetBulletinDetail() {
      bulletinDetail({ id: this.bulletinId }).then((resp) => {
        this.currentBulletin = resp.data.model;
        if (this.currentBulletin.hasFormPlugin) {
          this.apiGetBulletinPluginFormList();
        }
      });
    },
    /**
     * 获取看板表单设置列表
     */
    apiGetBulletinPluginFormList() {
      bulletinPluginFormList().then((resp) => {
        this.bulletinPluginFormOptions = resp.data.modelList;
        //默认选择通用表单
        this.card.bulletinFormId = "0";
      });
    },
    previewCover() {
      this.$refs.imageCover.showViewer = true;
    },
    deleteCover() {
      this.$confirm("确定要删除封面图片吗?", "提示")
        .then((res) => {
          this.$set(this.card, "coverImage", "");
        })
        .catch(() => {});
    },
    async saveCard() {
      let _self = this;
      let promiseArr = [];
      if (this.card.bulletinFormId != 0) {
        let dynamicFormValid = _self.$refs.dynamicForm.submitForm();
        promiseArr.push(dynamicFormValid);
      }

      let cardFormValid = new Promise((resolve, reject) => {
        _self.$refs.cardForm.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
      promiseArr.push(cardFormValid);
      Promise.all(promiseArr)
        .then((resp) => {
          if (resp[0]) {
            this.formInstanceId = resp[0].id;
          }
          this.handleFormDataSave();
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    },
    handleFormDataSave() {
      let data = {
        bulletinId: this.bulletinId,
        bulletinColumnId: this.bulletinColumnId,
        title: this.card.title,
        remark: this.card.remark,
      };

      if (this.card.attachmentList && this.card.attachmentList.length) {
        let attachment = [];
        this.card.attachmentList.forEach((item) => {
          attachment.push({ attachmentId: item.id });
        });
        data.attachment = JSON.stringify(attachment);
      }

      // Promise.all([]);
      this.loading = true;
      bulletinCardSave({
        model: JSON.stringify(data),
        bulletinPluginFormId: this.card.bulletinFormId,
        formInstanceId: this.formInstanceId,
      }).then((res) => {
        let arr = [];
        if (this.card.cardUserList) {
          let userId = this.card.cardUserList
            // .filter((item) => item.userId != res.data.model.creatorId)
            .map((u) => u.userId)
            .toString();
          if (userId != "") {
            arr.push(
              bulletinCardUserSaveBatch({
                bulletinCardId: res.data.model.id,
                userId: userId,
              }),
            );
          }
        }

        if (this.card.tagList) {
          this.card.tagList.forEach((tag) => {
            arr.push(
              saveBulletinTag({
                title: tag.title,
                backgroundColor: tag.backgroundColor,
                bulletinCardId: res.data.model.id,
              }),
            );
          });
        }
        if (this.card.coverImage) {
          arr.push(
            setCardCoverImage({
              id: res.data.model.id,
              imageUrl: this.card.coverImage,
            }),
          );
        }
        if (this.currentBulletin.hasSchedulePlugin) {
          arr.push(
            bulletinCardSetTime({
              id: res.data.model.id,
              endTime: this.card.endTime,
              remindMinutes: this.card.remindMinutes,
            }),
          );
        }

        if (this.card.attachmentList && this.card.attachmentList.length) {
          let attachmentId = this.card.attachmentList
            .map((item) => item.id)
            .toString();
          arr.push(
            addCardAttachment({
              attachmentId,
              id: res.data.model.id,
            }),
          );
          // this.card.attachmentList.forEach((item) => {
          //   arr.push(
          //     addCardAttachment({
          //       attachmentId: item.id,
          //       id: res.data.model.id,
          //     })
          //   );
          // });
        }

        this.isSave = true;
        if (arr.length) {
          Promise.all(arr)
            .then((res2) => {
              this.updateDetail(res.data.model.id);
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.updateDetail(res.data.model.id);
        }
      });
    },
    updateDetail(id) {
      bulletinCardDetail({ id: id })
        .then((res) => {
          this.$emit("updateCard", res.data.model);
          this.loading = false;
          this.visible = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    uploadAttachment(attachment) {
      this.$refs.fileUpload.clearFiles();
      if (this.card.attachmentList) {
        this.$set(
          this.card.attachmentList,
          this.card.attachmentList.length,
          attachment,
        );
      } else {
        this.$set(this.card, "attachmentList", [attachment]);
      }
    },
    handleAttachmentDelete(att) {
      let index = this.card.attachmentList.find((item) => item.id == att.id);
      this.card.attachmentList.splice(index, 1);
    },
    handleAttachmentDownload(att) {
      downFile(att);
    },
    canPreview(extension) {
      return getFileExtension(extension) ? true : false;
    },

    handleUploadChange(file) {
      this.$set(this.card, "coverImage", file.url);
    },
    editTag(tag) {
      if (this.disabled) {
        return;
      }
      this.$set(this, "tagForm", {
        id: tag.id,
        label: tag.title,
        value: tag.backgroundColor,
      });
      this.editVisible = true;
    },
    deleteTag(index) {
      this.card.tagList.splice(index, 1);
    },
    cancelEdit() {
      this.editVisible = false;
      this.tagForm = {};
    },
    saveTag() {
      this.$refs.tagForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.editVisible = false;
        let index = -1;
        if (this.card.tagList) {
          index = this.card.tagList.findIndex(
            (item) => item.backgroundColor == this.tagForm.value,
          );
        }
        if (index >= 0) {
          this.$set(this.card, index, {
            backgroundColor: this.tagForm.value,
            title: this.tagForm.label,
          });
        } else {
          if (!this.card.tagList) {
            this.$set(this.card, "tagList", [
              {
                backgroundColor: this.tagForm.value,
                title: this.tagForm.label,
              },
            ]);
          } else {
            this.$set(this.card.tagList, this.card.tagList.length, {
              backgroundColor: this.tagForm.value,
              title: this.tagForm.label,
            });
          }
        }
      });
    },
    choseTag(tag) {
      this.editVisible = true;
      this.tagForm = deepClone(tag);
    },

    onMembersSelectDialogClose() {},
    addUser() {
      this.membersSelectDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.membersSelectDialog.init({
          bulletinId: this.bulletinId,
          cardUserList: this.card.cardUserList ? this.card.cardUserList : [],
        });
      });
    },
    //提交选择成员
    membersAddConfirm(selectMember, card) {
      this.$set(this.card, "cardUserList", selectMember);
    },
    deleteUser(index) {
      this.card.cardUserList.splice(index, 1);
    },
    closeTask() {
      this.closeType = "close";
      this.visible = false;
    },
    close() {
      if (!this.isSave && this.closeType != "close") {
        this.saveCard();
      } else {
        // if (this.$refs.cardForm) {
        //   this.$refs.cardForm.clearValidate();
        // }
      }
    },
    closed() {
      this.card = {};
      if (this.$refs.cardForm) {
        this.$refs.cardForm.clearValidate();
      }

      if (this.$refs.imageUpload) {
        this.$refs.imageUpload.clearFiles();
      }
    },

    open() {
      this.closeType = "";
      this.isSave = false;
      this.$nextTick(() => {
        this.$refs.cardForm.clearValidate();
        this.$refs.main.focus();
      });
      this.apiGetBulletinDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.white-border {
  border-color: #ffffff;
}

.card-form {
}

.align-center {
  align-items: center;
}

.weui-flex {
  display: flex;
}

.form-item-flex {
  flex: 1;
  margin-left: 15px;
}

.cardDialog {
  ::v-deep {
    .el-dialog__header {
      border-bottom: 0;
      padding-left: 25px;
      padding-right: 25px;

      .el-dialog__headerbtn {
        right: 25px;
      }
    }
  }
}

.dia-header {
  display: flex;
  align-items: center;
  padding-right: 50px;
  height: 30px;
}

.card-header {
  font-size: 14px;
}

.createdTime {
  flex: 1;
  color: #666666;
  font-size: 12px;
  margin-left: 20px;
}

.more {
  transform: rotate(90deg);
}

.label {
  font-size: 14px;
  margin-bottom: 10px;
}

.form-item {
  margin-bottom: 20px;
}

.tag-list {
  display: flex;
  align-items: center;

  .tag-item {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}

.eltag {
  color: #fff;
  border: 0;

  ::v-deep {
    .el-tag__close {
      color: #ffffff;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }
}

.coverUpload {
  ::v-deep {
    .el-form-item__content {
      line-height: 1;
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }

    .el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: 78px;
      box-sizing: border-box;
    }

    .upload-container {
      .el-upload {
        width: auto;

        .el-upload-dragger {
          height: auto;
          width: 80px;
          border: 0;
        }
      }
    }
  }
}

.coverImage {
  border: 1px solid #c0ccda;
  border-radius: 6px;
  width: 80px;
  height: 80px;
}

.comment-textarea {
  border: 1px solid #dcdfe6;
  border-radius: 6px;

  .comment-border {
    margin-bottom: 10px;

    ::v-deep {
      .el-textarea__inner {
        border: 0;
      }
    }
  }

  .comment-btn {
    text-align: right;
    padding: 10px;
  }
}

.comment-ul {
  margin-top: 15px;

  .comment-item {
    display: flex;
    margin-bottom: 15px;

    .comment-user {
      background-color: #409eff;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
      font-size: 12px;
      border-radius: 30px;
      margin-right: 10px;
    }

    .comment-content {
      flex: 1;

      .c-user {
        font-size: 14px;
        color: #333333;
        margin-bottom: 5px;
      }

      .c-content {
        font-size: 12px;
        color: #333333;
        line-height: 1.5;
      }

      .c-time {
        color: #666;
        font-size: 12px;
        margin-top: 5px;
      }
    }

    .comment-delete {
      margin-top: 10px;
    }

    .cmt-del {
      color: #999;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

::v-deep {
  .tagDilog {
    margin-top: 50vh !important;
    top: -160px;
    height: 320px;
  }
}

.cover-image-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  margin-left: 10px;
  position: relative;
  width: 80px;
  height: 80px;
  display: inline-block;
}

.add-tag {
  transform: translateY(2px);
  height: 32px;
}
</style>
