<template>
  <div ref="chart" class="chart-process"></div>
</template>
<script>
import { getAggreation } from "../api";
import {
  textColorArr,
  getProgressColor,
  getLiquidOutlineColor,
  getRealNum,
} from "@/views/lowCode/view/components/chartUtil";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";
import { getStrByNumberFormat, isNumber } from "@/zgg-core/utils";
export default {
  mixins: [chartResize],
  data() {
    return {
      list: [],
      view: null,
      option: null,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
    componentName: {
      type: String,
      default: "chart_process",
    },
  },
  destroyed() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  created() {},
  methods: {
    getData(view, dataFilter) {
      this.$set(this, "view", view);

      if (!view.metrics.length) {
        return;
      }
      if (!view.targetValues.length) {
        return;
      }
      if (
        view.metrics.length == 1 &&
        view.targetValues[0]?.targetType == "fieldValue"
      ) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        if (view.dataSource.queryObject.pageSize) {
          queryObject.pageSize = view.dataSource.queryObject.pageSize;
        }
        if (view.dataSource.queryObject.advanceQuery) {
          queryObject.advanceQuery = view.dataSource.queryObject.advanceQuery;
        }
      }
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        // console.log("chart data");
        // console.log(res);
        this.list = res.data.list;
        this.buildChart(view);
      });
    },
    buildChart(view) {
      let list = this.list;
      // 基于准备好的dom，初始化echarts实例
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(this.$refs.chart);

      let option = {};
      let targetValue;
      let metricValue;
      let metricLabel;
      let targetLabel;
      if (view.targetValues[0].targetType == "fixedValue") {
        targetValue = view.targetValues[0].maxFixedValue;
      }

      list.forEach((row) => {
        if (view.metrics[0]) {
          metricValue = row[view.metrics[0].name];
          metricLabel = metricValue;
          if (view.metrics[0].numberFormat) {
            metricLabel = row[view.metrics[0].name + "Format"];
          }
        }
        if (view.metrics[1]) {
          targetValue = row[view.metrics[1].name];
        }
      });
      let obj;
      let chartLabel = view.chartLabel;
      let calValue = parseFloat((metricValue / targetValue) * 100).toFixed(
        chartLabel?.decimalPrecision ? chartLabel.decimalPrecision : 0,
      );
      targetLabel = targetValue;
      if (view.metrics[0]?.numberFormat) {
        targetLabel = getStrByNumberFormat(
          targetValue,
          view.metrics[0].numberFormat,
        );
      }
      if (!isNumber(calValue)) return;
      let textColor = "#000";
      if (view?.colorConfig?.contentColor) {
        textColor = !view?.colorConfig?.contentColor.includes("rgba")
          ? textColorArr[view?.colorConfig?.contentColor]
          : view?.colorConfig?.contentColor;
      }
      //let textColor = textColorArr[view?.colorConfig?.contentColor] || "#000";
      if (view.processType == "liquid") {
        (option.title = {
          id: "title",
          text: "", // 主标题文本，支持使用 \n 换行
          left: "center",
          textStyle: {},
        }),
          (obj = {
            id: "liquidFill",
            type: "liquidFill",
            amplitude: 10, //圆形内 水滴角度
            backgroundStyle: {
              color:
                view?.backgroundColor && !view?.backgroundColor.includes("rgba")
                  ? textColorArr[view.backgroundColor]
                  : view.backgroundColor || "#D8DAE5",
            },
            data: [
              {
                value: parseFloat(metricValue / targetValue).toFixed(2),
                direction: "left",
                itemStyle: {
                  normal: {
                    color: getProgressColor(view.processColor),
                    shadowBlur: "",
                  },
                },
              },
            ],
            label: {
              normal: {
                position: ["50%", "50%"],
                textStyle: {
                  fontSize: 14, //设置字体大小
                  fontWeight: "normal",
                  color: textColor,
                },
                rich: {
                  a: {
                    color: textColor,
                    fontSize: 20,
                    padding: [2, 0, 0, 0],
                  },
                  b: {
                    color: textColor,
                    fontSize: 14,
                  },
                },
              },
            },
            outline: {
              // 轮廓设置
              show: true,
              itemStyle: {
                borderWidth: 3, //轮廓大小
                borderColor: getLiquidOutlineColor(view.processColor),
              },
              borderDistance: 0, // 轮廓间距
            },
          });
        let displayText = [];
        if (chartLabel.enable) {
          if (chartLabel.content) {
            displayText.push(`{b|${chartLabel.content}}`);
          }
          if (chartLabel.displayMetricPercent) {
            displayText.push(`{a|${calValue}%}`);
          }
          if (chartLabel.displayMetric) {
            option.title.text += `${view.metrics[0].title}:${metricLabel}   `;
          }
          if (chartLabel.displayTarget) {
            option.title.text += `${view.targetValues[0].title}:${targetLabel}`;
          }
        }
        obj.label.normal.formatter = () => {
          return `${displayText.join("\n")}`;
        };
        option.series = [obj];
      } else if (view.processType == "process") {
        option = {
          grid: {
            top: "0",
            left: "10",
            right: (calValue.length + 1) * 9,
            bottom: "0",
          },
          xAxis: {
            type: "value",
            splitLine: {
              show: false,
            }, //坐标轴在 grid 区域中的分隔线
            axisLabel: {
              show: false,
            }, //坐标轴刻度标签
            axisTick: {
              show: false,
            }, //坐标轴刻度
            axisLine: {
              show: false,
            }, //坐标轴轴线
          },
          yAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            data: ["进度条"],
          },
          series: [
            {
              name: "进度条", //系列名称
              type: "bar", //柱状、条形图
              barWidth: 16, //柱条的宽度,默认自适应
              data: [metricValue > targetValue ? targetValue : metricValue], //系列中数据内容数组
              label: {
                //图形上的文本标签
                show: true,
                position: [0, 8], //标签的位置
                offset: [0, -30], //标签文字的偏移
                formatter: function (data) {
                  let displayText = [];
                  if (chartLabel.enable) {
                    if (chartLabel.content) {
                      displayText.push(`{a|${chartLabel.content}}`);
                    } else {
                      displayText.push("{a|}");
                    }
                    let text = "";
                    if (chartLabel.displayMetric) {
                      text += `{c|${view.metrics[0].title}:${metricLabel}}   `;
                    }
                    if (chartLabel.displayTarget) {
                      text += `{c|${view.targetValues[0].title}:${targetLabel}}`;
                    }
                    displayText.push(text);
                  }
                  return displayText.join("\n");
                },
                rich: {
                  a: {
                    color: textColor,
                    fontSize: 14,
                  },
                  c: {
                    color: textColor,
                    fontSize: 14,
                    padding: [32, 0, 0, 0],
                  },
                },
                color: textColor, //标签字体颜色
                fontSize: 14, //标签字号
              },
              itemStyle: {
                normal: {
                  color: getProgressColor(view.processColor),
                },
              },
              zlevel: 1,
            },
            {
              name: "进度条背景",
              type: "bar",
              label: {
                //图形上的文本标签
                show: chartLabel.enable && chartLabel.displayMetricPercent,
                position: "right", //标签的位置
                offset: [0, 0], //标签文字的偏移
                formatter: function (data) {
                  return `{a|${calValue}%}`;
                },
                textStyle: {
                  rich: {
                    a: {
                      fontSize: 14,
                      color: textColor,
                    },
                  },
                },
              },
              barGap: "-100%",
              barWidth: 16,
              data: [targetValue],
              color:
                view.backgroundColor && !view.backgroundColor.includes("rgba")
                  ? textColorArr[view.backgroundColor]
                  : view.backgroundColor || "#D8DAE5",
            },
          ],
        };
      } else {
        obj = {
          id: "gauge",
          type: "gauge",
          data: [
            {
              value: metricValue,
              name: "",
            },
          ],
          min: view.targetValues[0].minFixedValue || 0,
          max: targetValue,
          itemStyle: {
            shadowBlur: "",
          },
          title: {
            fontSize: 14,
            color: textColor,
          },
          progress: {
            show: true,
            roundCap: true,
            itemStyle: {
              color: getProgressColor(view.processColor),
            },
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              color: [
                [
                  1,
                  view.backgroundColor && !view.backgroundColor.includes("rgba")
                    ? textColorArr[view.backgroundColor]
                    : view.backgroundColor || "#D8DAE5",
                ],
              ],
            },
          },
          pointer: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        };
        let displayText = [];
        if (chartLabel.enable) {
          if (chartLabel.content) {
            displayText.push(`{a|${chartLabel.content}}`);
          }
          if (chartLabel.displayMetricPercent) {
            displayText.push(`{b|${calValue}%}`);
          }
          if (chartLabel.displayMetric) {
            obj.data[0].name += `${view.metrics[0].title}:${metricLabel}   `;
          }
          if (chartLabel.displayTarget) {
            obj.data[0].name += `${view.targetValues[0].title}:${targetLabel}`;
          }
          obj.detail = {
            backgroundColor: "transparent",
            valueAnimation: true,
            formatter: displayText.join("\n"),

            rich: {
              a: {
                color: textColor,
                fontSize: 14,
                lineHeight: 14,
              },
              b: {
                color: textColor,
                fontSize: 20,
                lineHeight: 20,
                padding: [2, 0, 0, 0],
              },
            },
          };
        }
        if (!view.processType || view.processType === "gauge_circle") {
          obj.startAngle = 90;
          obj.endAngle = -270;
          if (obj.detail) {
            obj.detail.offsetCenter = [0, 0];
          }
        } else if (view.processType == "gauge_semicircle") {
          obj.startAngle = 180;
          obj.endAngle = 0;
          if (obj.detail) {
            obj.detail.offsetCenter = [0, -30];
          }
        }
        option.series = [obj];
      }
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
      this.setChartResize();
      this.resizeChart();
    },
    resizeProcessChart() {
      if (this.myChart) {
        let domWidth = this.myChart.getWidth();
        let domHeight = this.myChart.getHeight();
        let val = Math.min(domWidth, domHeight);
        //指标值目标值所占高度
        let h =
          this.view.chartLabel.displayMetric ||
          this.view.chartLabel.displayTarget
            ? 24
            : 0;
        if (
          ["gauge_circle", "gauge_semicircle"].includes(
            this.view.processType,
          ) ||
          !this.view.processType
        ) {
          let obj = {
            series: [
              {
                id: "gauge",
                title: {
                  fontSize: 14,
                  color:
                    this.view?.colorConfig?.contentColor &&
                    !this.view?.colorConfig?.contentColor.includes("rgba")
                      ? textColorArr[this.view?.colorConfig?.contentColor]
                      : this.view?.colorConfig?.contentColor || "#000",
                },
                progress: {
                  show: true,
                  width: parseFloat(val * 0.05),
                  roundCap: true,
                  itemStyle: {
                    color: getProgressColor(this.view.processColor),
                  },
                },
                axisLine: {
                  roundCap: true,
                  lineStyle: {
                    color: [
                      [
                        1,
                        this.view.backgroundColor &&
                        !this.view.backgroundColor.includes("rgba")
                          ? textColorArr[this.view.backgroundColor]
                          : this.view.backgroundColor || "#D8DAE5",
                      ],
                    ],
                    width: parseFloat(val * 0.05),
                  },
                },
              },
            ],
          };
          if (
            !this.view.processType ||
            this.view.processType === "gauge_circle"
          ) {
            obj.series[0].radius = parseFloat((val - h) / 2);
            obj.series[0].center = ["50%", parseFloat(domHeight / 2 - h / 2)];
            obj.series[0].title.offsetCenter = [
              0,
              parseFloat((val - h) / 2 + 16),
            ];
          } else {
            if (domWidth >= parseFloat(2 * (domHeight - h))) {
              obj.series[0].radius = parseFloat(val - h);
              obj.series[0].center = ["50%", parseFloat(val - h)];
              obj.series[0].title.offsetCenter = [0, 16];
            } else {
              obj.series[0].radius = parseFloat(domWidth / 2 - val * 0.05);
              if (parseFloat((domHeight - h) / 2 >= domWidth / 2)) {
                obj.series[0].center = [
                  "50%",
                  parseFloat((domHeight - h - domWidth / 2) / 2 - h / 2),
                ];
              } else {
                let value = parseFloat(domWidth / 2 - (domHeight - h) / 2);
                obj.series[0].center = [
                  "50%",
                  parseFloat(
                    (domHeight - h - domWidth / 2) / 2 -
                      h / 2 +
                      value +
                      (domHeight - h) / 2,
                  ),
                ];
              }
              obj.series[0].title.offsetCenter = [0, 16];
            }
          }
          this.myChart.setOption(obj, { replaceMerge: "series" });
        } else if (this.view.processType == "liquid") {
          let obj = {
            title: {
              id: "title",
              left: "center",
              textStyle: {
                // 文本样式
                fontSize: 14,
                fontWeight: "normal",
                color:
                  this.view?.colorConfig?.contentColor &&
                  !this.view?.colorConfig?.contentColor.includes("rgba")
                    ? textColorArr[this.view?.colorConfig?.contentColor]
                    : this.view?.colorConfig?.contentColor || "#000",
              },
            },
            series: [
              {
                id: "liquidFill",
              },
            ],
          };
          obj.series[0].radius = parseFloat(val - h - 3); //3为外轮廓
          obj.series[0].center = ["50%", parseFloat(domHeight / 2 - h / 2)];
          obj.title.bottom = parseFloat(
            domHeight - (domHeight / 2 - h / 2 + h + (val - h - 3) / 2),
          );
          this.myChart.setOption(obj, { replaceMerge: ["series", "title"] });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-process {
  height: 100%;
}
</style>
