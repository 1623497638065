<template>
  <el-popover popper-class="pop-trigger" width="260" v-model="visible">
    <div v-clickoutside="handleClose">
      <el-input
        placeholder="请输入关联表单名称"
        prefix-icon="el-icon-search"
        v-model="searchTitle"
        size="mini"
        @input="searchForm"
      >
      </el-input>
      <div
        class="pop-scroll"
        v-infinite-scroll="loadingList"
        :infinite-scroll-distance="10"
        :infinite-scroll-disabled="!isMore"
      >
        <div
          v-for="item in list"
          :key="item.id"
          v-text="item.title"
          class="form-item"
          :class="{ active: item.id == value.formId }"
          @click="checkForm(item)"
        ></div>
      </div>
    </div>
    <div @click.stop="" slot="reference" class="trigger-select">
      <div class="inner-text" v-text="value.title"></div>
      <i class="el-icon-arrow-down"></i>
    </div>
  </el-popover>
</template>

<script>
import { fetchFormList } from "@/views/lowCode/form/api";
import Clickoutside from "element-ui/src/utils/clickoutside";

export default {
  name: "form-select-popover",
  directives: { Clickoutside },
  data() {
    return {
      searchTitle: "",
      visible: false,
      timeout: null,
      page: {
        pageNumber: 0,
        pageSize: 20,
        totalPage: 0,
        totalRow: 0,
      },
      list: [],
      isMore: true,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          formId: "",
          title: "",
        };
      },
    },
  },
  created() {
    this.loadingList();
  },
  methods: {
    checkForm(item) {
      if (item.id == this.value.formId) {
        return;
      }
      this.value.formId = item.id;
      this.value.title = item.title;
      this.visible = false;
      this.value.condition.advanceQuery = [];
      this.$emit("getComponents", item.id);
    },
    searchForm() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.page.pageNumber = 0;
        this.loadingList();
      }, 200);
    },
    handleClose() {
      this.visible = false;
    },
    loadingList() {
      if (this.loading) {
        return;
      }
      if (this.page.pageNumber && this.page.pageNumber >= this.page.totalPage) {
        return;
      }
      this.loading = true;

      fetchFormList({
        pageSize: this.page.pageSize,
        pageNumber: this.page.pageNumber + 1,
        title: this.searchTitle,
      })
        .then((res) => {
          let page = res.data.page;
          this.page.pageNumber = page.pageNumber;
          this.page.pageSize = page.pageSize;
          this.page.totalPage = page.totalPage;
          this.page.totalRow = page.totalRow;
          this.isMore = page.pageNumber < page.totalPage;
          if (page.pageNumber == 1) {
            this.list = page.list;
          } else {
            this.list = this.list.concat(page.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.trigger-select {
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  overflow: hidden;

  .inner-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    font-size: 14px;
  }
}
.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .form-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background-color: rgba($color: #409eff, $alpha: 0.5);
      color: #ffffff;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.2);
    }
  }
}
</style>
<style lang="scss">
.pop-trigger {
  &.el-popover {
    padding: 0;
  }
}
</style>
