<template>
  <el-table-column
    :prop="name"
    :label="columns.label && columns.label"
    align="left"
  >
    <template v-if="columns.children">
      <PivotTableColumn
        v-for="(item, index) in columns.children"
        :key="index"
        :columns="item"
      ></PivotTableColumn>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "PivotTableColumn",
  props: {
    columns: {
      type: Object,
    },
  },
  computed: {
    name() {
      if (!this.columns.prop) {
        return "";
      }
      if (this.columns.numberFormat) {
        return this.columns.prop + "Format";
      }
      return this.columns.prop;
    },
  },
};
</script>
<style scoped></style>
