<template>
  <div
    v-if="
      parentField &&
      component.componentName != 'reference_data' &&
      component.extParam
    "
    class="attr-row"
  >
    <div class="attr-label">字段宽度</div>
    <el-radio-group v-model="widthIsDefault" size="mini" @input="changeValue">
      <el-radio-button label="1/2">1/2</el-radio-button>
      <el-radio-button label="1/1">默认</el-radio-button>
      <el-radio-button label="2">2倍</el-radio-button>
      <el-radio-button label="custom">自定义</el-radio-button>
    </el-radio-group>
    <div v-if="widthIsDefault === 'custom'" style="margin-top: 10px">
      <el-input-number
        size="small"
        v-model="component.extParam.customWidth"
        controls-position="right"
        :min="50"
        :step="10"
      ></el-input-number>
      px
    </div>
  </div>
</template>
<script>
import { getFormComponents } from "@zgg-core-utils/utils";
import { componentProps } from "./util";
import { getTableColumnWidthByColumn } from "@/views/lowCode/utils";
export default {
  name: "attr-field-width-json-form",
  inject: ["getComponents"],
  props: componentProps,
  data() {
    return {
      widthIsDefault: "1/1",
    };
  },
  computed: {
    parentField() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      return components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key,
            ) >= 0,
        );
    },
  },
  created() {
    if (
      this.parentField &&
      this.component.componentName != "reference_data" &&
      this.component.extParam
    ) {
      let width = getTableColumnWidthByColumn(this.component);
      width = parseInt(width);
      let customWidth = this.component.extParam.customWidth;
      if (customWidth === "1/1") {
        this.component.extParam.customWidth = width;
      } else if (customWidth == width / 2) {
        this.widthIsDefault = "1/2";
      } else if (customWidth == 2 * width) {
        this.widthIsDefault = "2";
      }
      if (
        customWidth !== "1/1" &&
        customWidth != 2 * width &&
        customWidth != width / 2 &&
        customWidth != width
      ) {
        this.widthIsDefault = "custom";
      }
    }
  },
  methods: {
    changeValue(val) {
      if (this.component.extParam) {
        let width = getTableColumnWidthByColumn(this.component);
        let customWidth = this.component.extParam.customWidth;
        width = parseInt(width);
        customWidth = parseInt(customWidth);
        if (val == "1/1") {
          this.component.extParam.customWidth = width;
        } else if (val == "1/2") {
          this.component.extParam.customWidth = width / 2;
        } else if (val == "2") {
          this.component.extParam.customWidth = width * 2;
        } else {
          this.component.extParam.customWidth = customWidth;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 12px;
  }
}
</style>
