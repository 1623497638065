<template>
  <div class="zgg-carousel-container">
    <el-carousel
      height="100%"
      :autoplay="config.extParam.autoplay"
      :loop="config.extParam.loop"
    >
      <el-carousel-item v-for="item in config.imageList" :key="item.imageUrl">
        <img
          :src="item.imageUrl"
          :alt="item.title"
          style="width: 100%; height: 100%; border-radius: 5px"
          :style="{
            objectFit: config.fillStyle,
            cursor: hasClickEvent(item) ? 'pointer' : 'default',
          }"
        />
        <span
          class="carousel-item-label"
          v-if="!config.hiddenImageTitle && !isEmpty(item.title)"
          >{{ item.title }}</span
        >
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { isEmpty } from "lodash";

export default {
  name: "dashboard-carousel",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          imageList: [],
          extParam: {
            autoplay: true,
            loop: true,
            card: "",
            interval: 3000,
          },
          fillStyle: "cover",
          hiddenImageTitle: true,
        };
      },
    },
  },
  computed: {},
  methods: {
    isEmpty,
    getData() {},
    resizeChart() {},
    carouselItemClick(item) {
      console.log(item);
      if (item.clickEvent && item.clickEvent == "hrefEvent") {
        if (!item.eventConfig.url) {
          this.$message.success("未配置跳转链接");
          return;
        }
        if (item.eventConfig.isNewPage) {
          window.open(item.eventConfig.url, "_blank");
        } else {
          window.open(item.eventConfig.url, "_self");
        }
      }
    },
    hasClickEvent(image) {
      return image && image.clickEvent && image.clickEvent != "none";
    },
  },
  mounted() {
    console.log(this.config);
  },
};
</script>

<style scoped lang="scss">
.zgg-carousel-container {
  height: 100%;
  ::v-deep .el-carousel {
    height: 100%;
    .el-carousel__button {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }
}
.carousel-item-label {
  position: absolute;
  display: block;
  border: none;
  outline: none;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  padding: 2px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    360deg,
    rgba(64, 64, 64, 0.17) -9.26%,
    rgba(99, 99, 99, 0) 100%
  );
  color: #ffffff;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 52px;
  width: 100%;
}
</style>
