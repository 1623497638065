import { render, staticRenderFns } from "./uploadWordFromDisk.vue?vue&type=template&id=66c2cd8a"
import script from "./uploadWordFromDisk.vue?vue&type=script&lang=js"
export * from "./uploadWordFromDisk.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports