var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-container",class:_vm.data.dataType === 'folder' ? '' : 'resource-icon'},[(_vm.enabledNewLayout)?[(_vm.data?.param?.iconStyle?.type === 'ICON_URL')?_c('div',{staticClass:"image-icon resource-image-icon",style:({
        'background-image': 'url(' + _vm.data?.param?.iconStyle?.icon + ')',
      })}):(_vm.data?.param?.iconStyle?.type == 'EMOJI')?_c('div',{staticClass:"icon-div"},[_c('div',{staticStyle:{"font-size":"18px","z-index":"1"}},[_vm._v(" "+_vm._s(_vm.data?.param?.iconStyle?.icon)+" ")])]):_c('div',{staticClass:"icon-div",style:(_vm.data?.param?.iconStyle?.shape == 'FILLED'
          ? ''
          : `background-color:${_vm.data?.param?.iconStyle?.color}`)},[_c('i',{class:!_vm.data?.param?.iconStyle?.className.includes('fa')
            ? 'iconfont ' + _vm.data?.param?.iconStyle?.className
            : _vm.data?.param?.iconStyle?.className,staticStyle:{"margin-right":"0","font-size":"18px"},style:(_vm.data?.param?.iconStyle?.shape == 'FILLED'
            ? `color:${_vm.data?.param?.iconStyle?.color}`
            : `color:#fff;font-size:12px`)})])]:[(_vm.data?.param?.iconStyle?.type === 'ICON_URL')?_c('div',{staticClass:"image-icon resource-image-icon",style:({
        'background-image': 'url(' + _vm.data?.param?.iconStyle?.icon + ')',
      })}):(_vm.data?.param?.iconStyle?.type == 'EMOJI')?_c('div',{staticClass:"icon-div"},[_c('div',{staticStyle:{"font-size":"16px","z-index":"1"}},[_vm._v(" "+_vm._s(_vm.data?.param?.iconStyle?.icon)+" ")])]):_c('div',{staticClass:"icon-div"},[_c('i',{class:!_vm.data?.param?.iconStyle?.className.includes('fa')
            ? 'iconfont ' + _vm.data?.param?.iconStyle?.className
            : _vm.data?.param?.iconStyle?.className,staticStyle:{"margin-right":"0","font-size":"16px"},style:(`color:${_vm.data?.param?.iconStyle?.color}`)})])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }