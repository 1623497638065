<template>
  <el-drawer
    :visible.sync="drawer"
    :with-header="false"
    :append-to-body="true"
    :destroy-on-close="true"
    :size="900"
    custom-class="rpa-drawer"
    @open="open"
  >
    <div class="rpa-dw-header" :class="bgClass">
      <i :class="typeIcon" style="font-size: 20px"></i>
      <div style="flex: 1; margin-left: 10px">
        <el-input
          v-if="isEditTitle"
          @blur="endEditTitle"
          v-model="title"
          placeholder=""
        ></el-input>
        <div
          @click="editTitle"
          class="node-title"
          v-else
          v-text="form.title"
        ></div>
      </div>
      <i @click="drawer = false" class="el-icon-close pointer font-24"></i>
    </div>
    <div class="rpa-scroll">
      <div
        v-if="
          [
            'trigger_form_data_change',
            'trigger_timer_once',
            'trigger_timer_cron',
            'trigger_timer_field',
            'trigger_webhook',
            'trigger_click',
          ].includes(form.type)
        "
        class="trigger-header"
        :class="bgClass"
      >
        <div class="rpa-radius" style="background-color: #ffffff">
          <i
            :class="typeIcon"
            style="font-size: 24px"
            :style="`color:${
              node.type == 'trigger_form_data_change' ? '#ffa340' : '#2196f3'
            }`"
          ></i>
        </div>
        <div
          style="color: #ffffff; margin-top: 10px"
          v-text="triggerTitle"
        ></div>
      </div>
      <div v-if="!isWorkflowData" class="rpa-body">
        <component
          v-if="componentName"
          v-bind:is="componentName"
          :node="form"
          :disabled="disabled"
          ref="form"
        ></component>
      </div>
      <el-tabs v-else v-model="activeName" class="drawer-tabs">
        <el-tab-pane label="执行详情" name="first">
          <rpa-workflow-node-data :nodeKey="node.key"></rpa-workflow-node-data>
        </el-tab-pane>
        <el-tab-pane label="配置详情" name="second">
          <component
            v-if="componentName"
            v-bind:is="componentName"
            :node="form"
            :disabled="disabled"
            ref="form"
          ></component>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="drawer-btns">
      <el-button
        v-if="!disabled && node.type != 'trigger_subflow_collection'"
        type="primary"
        @click="save"
        >保存</el-button
      >
      <el-button @click="drawer = false">取消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import rpaTrigger from "./rpa-trigger";
import rpaTimerOnce from "./rpa-timer-once";
import rpaTimerCron from "./rpa-timer-cron";
import rpaTimerField from "./rpa-timer-field";
import rpaFormCreate from "./rpa-form-create";
import { nodeTypeList } from "./loopTypeEnums";
import rpaFormUpdate from "./rpa-form-update";
import rpaFormDelete from "./rpa-form-delete";
import rpaFormSave from "./rpa-form-save";
import rpaQueryDataOne from "./rpa-query-data-one";
import rpaQueryDataList from "./rpa-query-data-list";
import rpaCondition from "./rpa-condition";
import rpaNoticeWechat from "./rpa-notice-wechat";
import rpaNoticeSms from "./rpa-notice-sms";
import rpaPlugin from "@/views/lowCode/rpa/rpa-plugin";
import rpaFormDataCommit from "@/views/lowCode/rpa/rpa-form-data-commit";
import rpaWebhook from "@/views/lowCode/rpa/rpa-webhook";
import rpaTriggerClick from "./rpa-trigger-click";
import rpaWorkflowNodeData from "./rpa-workflow-node-data";
import rpaSubflowCollection from "./rpa-subflow-collection";
import rpaSubflowTrigger from "./rpa-subflow-trigger";
import rpaFlowParamUpdate from "./rpa-flow-param-update";
import rpaRenderUrl from "./rpa-render-url";
import rpaRenderNotice from "./rpa-render-notice";
import rpaRenderForm from "./rpa-render-form";
import rpaJsonParse from "@/views/lowCode/rpa/rpa-json-parse";
import rpaHttpRequest from "./rpa-http-request";

export default {
  components: {
    [rpaTrigger.name]: rpaTrigger,
    [rpaTimerOnce.name]: rpaTimerOnce,
    [rpaTimerCron.name]: rpaTimerCron,
    [rpaTimerField.name]: rpaTimerField,
    [rpaFormCreate.name]: rpaFormCreate,
    [rpaFormUpdate.name]: rpaFormUpdate,
    [rpaFormDelete.name]: rpaFormDelete,
    [rpaFormSave.name]: rpaFormSave,
    [rpaQueryDataOne.name]: rpaQueryDataOne,
    [rpaQueryDataList.name]: rpaQueryDataList,
    [rpaCondition.name]: rpaCondition,
    [rpaNoticeWechat.name]: rpaNoticeWechat,
    [rpaNoticeSms.name]: rpaNoticeSms,
    [rpaPlugin.name]: rpaPlugin,
    [rpaFormDataCommit.name]: rpaFormDataCommit,
    [rpaWebhook.name]: rpaWebhook,
    [rpaTriggerClick.name]: rpaTriggerClick,
    [rpaWorkflowNodeData.name]: rpaWorkflowNodeData,
    [rpaSubflowCollection.name]: rpaSubflowCollection,
    [rpaSubflowTrigger.name]: rpaSubflowTrigger,
    [rpaFlowParamUpdate.name]: rpaFlowParamUpdate,
    [rpaRenderUrl.name]: rpaRenderUrl,
    [rpaRenderNotice.name]: rpaRenderNotice,
    [rpaRenderForm.name]: rpaRenderForm,
    [rpaJsonParse.name]: rpaJsonParse,
    [rpaHttpRequest.name]: rpaHttpRequest,
  },
  provide() {
    return {
      getFromNodes: this.getFromNodes,
      getToNodes: this.getToNodes,
    };
  },
  props: {
    visible: Boolean,
    node: Object,
    /** 获取所有上级节点 */
    getFromNodes: Function,
    /** 获取所有子节点 */
    getToNodes: Function,
    disabled: Boolean,
    isWorkflowData: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    drawer: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    bgClass() {
      let obj = nodeTypeList.find((item) => item.value == this.node.type);
      if (obj && obj.bg) {
        return obj.bg;
      }
      return "bg_yellow";
    },
    typeIcon() {
      let obj = nodeTypeList.find((item) => item.value == this.node.type);
      if (obj) {
        return obj.icon;
      }
      return "";
    },
    triggerTitle() {
      if (this.node.type == "trigger_form_data_change") {
        return "工作表";
      } else if (this.node.type == "trigger_timer_once") {
        return "定时单次";
      } else if (this.node.type == "trigger_timer_cron") {
        return "定时多次";
      } else if (this.node.type == "trigger_timer_field") {
        return "按日期字段";
      } else if (this.node.type == "trigger_webhook") {
        return "Webhook";
      } else if (this.node.type == "trigger_click") {
        return "点击触发";
      }
      return "";
    },
    componentName() {
      let type = this.node.type;
      if (type == "trigger_form_data_change") {
        return rpaTrigger.name;
      } else if (type == "trigger_timer_once") {
        return rpaTimerOnce.name;
      } else if (type == "trigger_timer_cron") {
        return rpaTimerCron.name;
      } else if (type == "trigger_timer_field") {
        return rpaTimerField.name;
      } else if (type == "trigger_webhook") {
        return rpaWebhook.name;
      } else if (type == "trigger_click") {
        return rpaTriggerClick.name;
      } else if (type == "action_form_data_create") {
        return rpaFormCreate.name;
      } else if (type == "action_form_data_update") {
        return rpaFormUpdate.name;
      } else if (type == "action_form_data_delete") {
        return rpaFormDelete.name;
      } else if (type == "action_form_data_save") {
        return rpaFormSave.name;
      } else if (type == "query_data_one") {
        return rpaQueryDataOne.name;
      } else if (type == "query_data_list") {
        return rpaQueryDataList.name;
      } else if (type == "condition") {
        return rpaCondition.name;
      } else if (type == "notice_wechat") {
        return rpaNoticeWechat.name;
      } else if (type == "notice_sms") {
        return rpaNoticeSms.name;
      } else if (type == "developer_plugin") {
        return rpaPlugin.name;
      } else if (type == "action_form_data_commit") {
        return rpaFormDataCommit.name;
      } else if (type == "subflow_collection") {
        return rpaSubflowCollection.name;
      } else if (type == "trigger_subflow_collection") {
        return rpaSubflowTrigger.name;
      } else if (type == "flow_param_update") {
        return rpaFlowParamUpdate.name;
      } else if (type == "render_url") {
        return rpaRenderUrl.name;
      } else if (type == "render_notice") {
        return rpaRenderNotice.name;
      } else if (type == "render_form") {
        return rpaRenderForm.name;
      } else if (type == "json_parser") {
        return rpaJsonParse.name;
      } else if (type == "http_request") {
        return rpaHttpRequest.name;
      }
      return "";
    },
  },
  data() {
    return {
      isEditTitle: false,
      title: "",
      form: {},
      activeName: "first",
    };
  },
  methods: {
    open() {
      this.form = this._.cloneDeep(this.node);
    },
    save() {
      if (this.$refs.form.getData) {
        this.$refs.form.getData((valid) => {
          if (!valid) {
            return;
          }

          this.$emit("update:node", this._.cloneDeep(this.form));
          this.$nextTick(() => {
            this.$emit("checkNode");
          });

          this.drawer = false;
        });
      } else {
        this.drawer = false;
      }
    },
    editTitle() {
      this.title = this.form.title;
      this.isEditTitle = true;
    },
    endEditTitle() {
      if (!isEmpty(this.title)) {
        this.form.title = this.title;
      }
    },
  },
};
</script>
<style lang="scss">
.rpa-drawer {
  .el-drawer__body {
    display: flex;
    flex-direction: column;
  }
}
</style>
<style lang="scss" scoped>
.rpa-scroll {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.rpa-body {
  padding: 20px 24px;

  ::v-deep {
    .is-error {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
    .el-form-item {
      margin-bottom: 10px;
      &.is-error {
        .rpa-checkbox-group {
          .el-checkbox-button__inner {
            border-color: #f56c6c !important;
          }
        }
      }
    }
    .el-form-item__label {
      padding: 0px;
      line-height: 30px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
.bg_yellow {
  background-color: #ffa340;
}
.bg_blue {
  background-color: #2196f3;
}
.bg_gray {
  background-color: #4c7d9e;
}
.node-title {
  padding: 8px;
  &:hover {
    background-color: rgba($color: #ffffff, $alpha: 0.1);
  }
}
.rpa-dw-header {
  display: flex;
  align-items: center;
  color: #fff;
  height: 55px;
  padding: 0 20px;
}
.font-24 {
  font-size: 24px;
}
.pointer {
  cursor: pointer;
}
.trigger-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height: 150px;
  justify-content: center;
}
.rpa-radius {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 68px;
  width: 68px;
  justify-content: center;
  pointer-events: none;
  border-radius: 68px;
  box-sizing: border-box;
}
.drawer-btns {
  padding: 10px;
  background-color: #f4f5f7;
}
.drawer-tabs {
  flex: 1;
  margin: 0 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ::v-deep {
    .el-tabs__content {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
