import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/findPwd",
    name: "找回密码",
    component: () => import("@/views/findPwd/index"),
  },

  {
    path: "/",
    name: "Main",
    component: () => import("@/layout/Main.vue"),
    redirect: "/lowCode/form",
    hidden: false,
    children: [
      {
        path: "/templateConfig",
        name: "目录配置",
        meta: { title: "目录配置", icon: "form2" },
        component: () => import("@/views/lowCode/templateConfig/index"),
        hidden: false,
      },
    ],
  },
  {
    path: "/lowCode",
    component: () => import("@/layout/Main.vue"),
    meta: { title: "零代码", icon: "menu" },
    redirect: "noRedirect",
    alwaysShow: true,
    children: [
      {
        path: "form",
        meta: { title: "表单设计", icon: "form2" },
        component: () => import("@/views/lowCode/form/index"),
        children: [
          {
            path: "edit",
            meta: { title: "表单界面设计" },
            component: () => import("@/views/lowCode/form/edit"),
            hidden: true,
          },
          {
            path: "templateSetting",
            name: "表单模版绑定",
            meta: { title: "表单模版绑定" },
            component: () => import("@/views/lowCode/form/templateSetting"),
            hidden: true,
            children: [
              {
                path: "excel",
                name: "绑定表格",
                meta: { title: "绑定表格" },
                component: () =>
                  import(
                    "@/views/lowCode/form/templateSetting/excel/excelEdit"
                  ),
                hidden: true,
              },
              {
                path: "word",
                name: "绑定文档",
                meta: { title: "绑定文档" },
                component: () =>
                  import("@/views/lowCode/form/templateSetting/word/index"),
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        path: "view",
        name: "视图设计",
        meta: { title: "视图设计", icon: "form2" },
        component: () => import("@/views/lowCode/view/index"),
        hidden: false,
        children: [
          {
            path: "edit",
            meta: { title: "视图界面设计" },
            component: () => import("@/views/lowCode/view/edit"),
            hidden: true,
          },
          {
            path: "chart",
            meta: { title: "图表设计" },
            component: () => import("@/views/lowCode/view/chart"),
            hidden: true,
          },

          {
            path: "gantt",
            meta: { title: "甘特图" },
            component: () => import("@/views/lowCode/view/gantt"),
            hidden: true,
          },
          {
            path: "specialView",
            meta: { title: "特殊视图" },
            component: () => import("@/views/lowCode/view/specialView"),
            hidden: true,
          },
          {
            path: "treeView",
            meta: { title: "树形视图" },
            component: () => import("@/views/lowCode/view/treeView"),
            hidden: true,
          },
        ],
      },
      {
        path: "view/chart",
        name: "图表设计",
        meta: { title: "图表设计" },
        component: () => import("@/views/lowCode/view/chart"),
        hidden: true,
      },
      {
        path: "dashboard",
        name: "仪表盘",
        meta: { title: "仪表盘", icon: "form2" },
        component: () => import("@/views/lowCode/dashboard/dashboard-index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "编辑仪表盘",
            meta: { title: "编辑仪表盘" },
            component: () => import("@/views/lowCode/dashboard/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "aggregate-table",
        name: "聚合表",
        meta: { title: "聚合表", icon: "form2" },
        component: () =>
          import("@/views/lowCode/aggregate-table/aggregate-table-index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "编辑聚合表",
            meta: { title: "编辑聚合表" },
            component: () => import("@/views/lowCode/view/aggregate"),
            hidden: true,
          },
        ],
      },
      {
        path: "assistant",
        name: "智能助手",
        meta: { title: "智能助手" },
        component: () => import("@/views/lowCode/assistant/assistant-index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "智能助手编辑",
            meta: { title: "智能助手编辑", icon: "form2" },
            component: () => import("@/views/lowCode/assistant/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "rpa",
        name: "智能助手2.0",
        meta: { title: "智能助手2.0" },
        component: () => import("@/views/lowCode/rpa/index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "智能助手2.0编辑",
            meta: { title: "智能助手编辑", icon: "form2" },
            component: () => import("@/views/lowCode/rpa/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "unit-setup",
        name: "单位设置",
        meta: { title: "单位设置", icon: "form2" },
        component: () => import("@/views/lowCode/unit-setup/unit-setup-index"),
        hidden: false,
        children: [
          {
            path: "addressSyncSetup",
            name: "通讯录同步配置",
            meta: { title: "通讯录同步配置" },
            component: () =>
              import("@/views/lowCode/unit-setup/address-sync-setup"),
            hidden: true,
          },
        ],
      },
      {
        path: "dataFactory",
        name: "数据工厂",
        meta: { title: "数据工厂", icon: "form2" },
        component: () => import("@/views/lowCode/dataFactory/index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "数据工厂编辑",
            meta: { title: "数据工厂编辑", icon: "form2" },
            component: () => import("@/views/lowCode/dataFactory/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "open-platform",
        name: "开放平台",
        meta: { title: "开放平台", icon: "form2" },
        component: () =>
          import("@/views/lowCode/open-platform/open-platform-index.vue"),
        hidden: false,
        redirect: "open-platform/unit",
        children: [
          {
            path: "unit",
            name: "插件管理",
            meta: { title: "插件管理", icon: "form2" },
            component: () => import("@/views/lowCode/open-platform/unit"),
            hidden: true,
          },
          {
            path: "market",
            name: "插件市场",
            meta: { title: "插件市场", icon: "form2" },
            component: () => import("@/views/lowCode/open-platform/market"),
            hidden: true,
          },
          {
            path: "doc",
            name: "开发文档",
            meta: { title: "开发文档", icon: "form2" },
            component: () => import("@/views/lowCode/open-platform/doc"),
            hidden: true,
          },
          {
            path: "apikey",
            name: "单位接口密钥",
            meta: { title: "单位接口密钥", icon: "form2" },
            component: () => import("@/views/lowCode/open-platform/api-key"),
            hidden: true,
          },
        ],
      },
      {
        path: "unit-config",
        name: "单位配置",
        meta: { title: "单位配置", icon: "form2" },
        component: () =>
          import("@/views/lowCode/unit-config/unit-config-index.vue"),
        hidden: false,
        children: [
          {
            path: "unit-info",
            name: "企业信息",
            meta: { title: "企业信息", icon: "form2" },
            component: () => import("@/views/lowCode/unit-config/unit-info"),
            hidden: true,
          },
          {
            path: "flow-setting",
            name: "流程设置",
            meta: { title: "流程设置", icon: "form2" },
            component: () => import("@/views/lowCode/unit-config/flow-setting"),
            hidden: true,
          },
          {
            path: "ding-user-sync-config",
            name: "钉钉人员同步",
            meta: { title: "钉钉人员同步", icon: "form2" },
            component: () =>
              import("@/views/lowCode/unit-config/ding-user-sync-config"),
            hidden: true,
          },
          {
            path: "unit-copy",
            name: "跨单位复制",
            meta: { title: "跨单位复制", icon: "form2" },
            component: () => import("@/views/lowCode/unit-config/unit-copy"),
            hidden: true,
          },
          {
            path: "pay-setting",
            name: "支付设置",
            meta: { title: "支付设置", icon: "form2" },
            component: () => import("@/views/lowCode/unit-config/pay-setting"),
            hidden: true,
          },
          {
            path: "sms-setting",
            name: "短信平台设置",
            meta: { title: "短信平台设置", icon: "form2" },
            component: () => import("@/views/lowCode/unit-config/sms-setting"),
            hidden: true,
          },
        ],
      },
      {
        path: "company-config",
        name: "单位配置",
        meta: { title: "单位配置", icon: "form2" },
        component: () => import("@/views/company-config/company-config.vue"),
        hidden: false,
        redirect: "company-config/employee-manage",
        children: [
          {
            path: "employee-manage",
            name: "单位人员管理",
            meta: { title: "单位人员管理" },
            component: () =>
              import(
                "@/views/company-config/employee-manage/employee-manage.vue"
              ),
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    path: "/agreement",
    name: "服务协议",
    component: () => import("@/views/privacyAgreement/agreement/index"),
  },
  {
    path: "/privacy",
    name: "隐私条款",
    component: () => import("@/views/privacyAgreement/privacy/index"),
  },

  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },

  {
    path: "/redirect/online/:formId/edit",
    name: "表单数据详情",
    component: () => import("@/views/lowCode/edit"),
  },
  {
    path: "/redirect/board/:bulletinId",
    name: "看板预览",
    component: () =>
      import("@/views/excelLibManage/excelTemplateNew/component/BoardDetail2"),
  },
  {
    path: "/codemirror",
    name: "公式编辑器",
    component: () => import("@/views/lowCode/form/formula"),
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
