<template>
  <div id="write" class="">
    <h1>
      <a
        :name="`${environment.platformName}用户服务协议`"
        class="md-header-anchor"
      ></a
      ><span>{{ environment.platformName }}用户服务协议</span>
    </h1>
    <p><span>更新时间：2022年07月19日</span></p>
    <p><span>生效时间：2022年07月19日</span></p>
    <h1><a name="一导言" class="md-header-anchor"></a><span>一、导言</span></h1>
    <p>
      <span
        >“{{ environment.platformName }}”是{{
          environment.platformFullName
        }}（下称“{{
          environment.platformName
        }}”、“我们”）提供的互联网技术服务，包括但不限于任务管理、资料整编、智能报表、综合OA等。{{
          environment.platformName
        }}相关网站、服务、应用程序与内容，在《{{
          environment.platformName
        }}用户服务协议》（以下简称“本协议”）中统称为“本服务”。</span
      >
    </p>
    <p>
      <span
        >请仔细阅读并充分理解本协议各条款，如您无法准确理解或不同意本协议的任一内容，请停止下一步操作。本协议的免除或者限制责任条款、违约行为认定处理等重要内容可能以加粗或下划线的形式提示您重点注意。请您审慎阅读并选择接受或不接受本协议。若您是未满18周岁的自然人，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本服务。</span
      >
    </p>
    <h1>
      <a name="二用户与帐号" class="md-header-anchor"></a
      ><span>二、用户与帐号</span>
    </h1>
    <p>
      <span
        >2.1 您在使用本服务前，需要注册{{
          environment.platformName
        }}帐号。您须选择合法且符合本服务要求的字符组合作为自己帐号的名称，并对帐号设置符合安全要求的密码。</span
      >
    </p>
    <p>
      <span
        >2.2
        您应自行妥善保管帐号和密码，在任何情况下不向他人透露，若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知我们。在丢失帐号或遗忘密码时，您可遵照我们提供的申诉途径及时申诉请求找回帐号或密码。{{
          environment.platformName
        }}不对用户帐号和密码的保管承担任何责任。</span
      >
    </p>
    <p>
      <span
        >2.3
        您设置的帐号不得违反国家法律法规及本服务相关规则，您的帐号名称、头像等用于使用本服务的信息及其他向我们提供的信息中不得出现违法或不良信息，未经他人许可不得使用他人名义（包括但不限于通过冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）注册帐号，不得恶意注册帐号。您在帐号使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益或者有害社会良好道德风尚的行为。我们有权对您提交的注册信息进行审核，对于违反本条款约定的注册信息，我们有权视情况采取不予注册、屏蔽或删除违法违规内容、不经通知暂停使用、冻结帐号、注销或收回帐号等措施，您应自行承担因此导致不能使用帐号或与之相关的服务与功能所造成的损失。</span
      >
    </p>
    <p>
      <span
        >2.4
        您充分了解并同意，您应为自己帐号下的一切行为负责，包括该帐号所发表的任何内容以及由此产生的任何后果。您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。我们无法且不会对您因前述风险而导致的任何损失或损害承担责任。</span
      >
    </p>
    <p>
      <span
        >2.5
        您的帐号在如下情况可能被冻结或被限制全部或部分权限、功能（如帐号被限制资金转出或领取红包等功能）：</span
      >
    </p>
    <p><span>（1） 违反国家法律、法规、政策、法律文书的规定的；</span></p>
    <p>
      <span
        >（2）国家有权机关（包括但不限于法院、检察机关、公安机关等）要求进行冻结的；</span
      >
    </p>
    <p>
      <span
        >（3）基于本服务运行和交易安全的需要，如您发生或可能发生破坏或试图破坏我们及关联公司公平交易环境或正常交易秩序的，或任何使用含有我们及关联公司名称、品牌且对他人有误导嫌疑或任何使用某种中英文(全称或简称)、数字、域名等意图表示或映射与我们及关联公司具有某种关联的；</span
      >
    </p>
    <p>
      <span
        >（4）您遭到他人投诉，且对方已经提供了相关证据，而您未按照我们的要求提供相反证据的；</span
      >
    </p>
    <p>
      <span
        >（5） 我们根据合理分析判断您的帐号操作、收益、兑换等存在异常的；</span
      >
    </p>
    <p><span>（6）帐号归属存在争议的；</span></p>
    <p><span>（7） 违反本协议、规则以及其他相关协议的；</span></p>
    <p>
      <span
        >（8）我们合理判断，您发生与如上行为性质相同或产生如上类似风险的其他情况的。</span
      >
    </p>
    <h1>
      <a name="三合法使用" class="md-header-anchor"></a
      ><span>三、合法使用</span>
    </h1>
    <p><span>3.1 您承诺不对本服务从事以下行为：</span></p>
    <p>
      <span
        >（1）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上传、张贴、发送或以其他方式传送；</span
      >
    </p>
    <p>
      <span
        >（2）干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；</span
      >
    </p>
    <p>
      <span
        >（3）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span
      >
    </p>
    <p>
      <span
        >（4）自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰；</span
      >
    </p>
    <p>
      <span
        >（5）未经许可，企图探查、扫描、测试本服务系统或网络的弱点或其它实施破坏网络安全的行为；</span
      >
    </p>
    <p><span>（6） 伪造TCP/IP数据包名称或部分名称；</span></p>
    <p>
      <span
        >（7）
        复制、模仿、修改、翻译、改编、出借、出售、转许可、在信息网络上传播或转让相关服务，或对本服务及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本服务的源代码；</span
      >
    </p>
    <p><span>（8）恶意注册帐号，包括但不限于频繁、批量注册帐号。</span></p>
    <h1>
      <a name="四行为规范" class="md-header-anchor"></a
      ><span>四、行为规范</span>
    </h1>
    <p><span>4.1 您在使用本服务过程中，不得从事下列行为：</span></p>
    <p><span>（1）提交、发布虚假信息，或冒充、利用他人名义的；</span></p>
    <p><span>（2）诱导其他用户点击链接页面或分享信息的；</span></p>
    <p><span>（3）虚构事实、隐瞒真相以误导、欺骗他人的；</span></p>
    <p>
      <span>（4）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权益的；</span>
    </p>
    <p>
      <span
        >（5）未经{{ environment.platformName }}书面许可利用{{
          environment.platformName
        }}帐号和任何功能，进行推广或互相推广的；</span
      >
    </p>
    <p>
      <span
        >（6）利用{{
          environment.platformName
        }}帐号或本软件及服务从事任何违法犯罪活动的；</span
      >
    </p>
    <p>
      <span
        >（7）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</span
      >
    </p>
    <p>
      <span
        >（8）从事违反法律法规规定，侵犯其他用户合法权益、干扰产品正常运营或{{
          environment.platformName
        }}未明示授权的行为；</span
      >
    </p>
    <p>
      <span
        >（9）其他违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为。</span
      >
    </p>
    <h1>
      <a name="五内容相关规则" class="md-header-anchor"></a
      ><span>五、内容相关规则</span>
    </h1>
    <p>
      <span
        >5.1
        作为内容的发表者，禁止使用本服务制作、复制、发布含有下列内容的违法信息：</span
      >
    </p>
    <p><span>（1）反对宪法所确定的基本原则的；</span></p>
    <p>
      <span
        >（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
      >
    </p>
    <p><span>（3）损害国家荣誉和利益的；</span></p>
    <p>
      <span
        >（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</span
      >
    </p>
    <p>
      <span
        >（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</span
      >
    </p>
    <p><span>（6）煽动民族仇恨、民族歧视，破坏民族团结的；</span></p>
    <p><span>（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>
    <p><span>（8）散布谣言，扰乱经济秩序和社会秩序的；</span></p>
    <p>
      <span>（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span>
    </p>
    <p>
      <span>（10）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</span>
    </p>
    <p><span>（11）法律、行政法规禁止的其他内容。</span></p>
    <h1>
      <a name="六数据隐私与安全" class="md-header-anchor"></a
      ><span>六、数据隐私与安全</span>
    </h1>
    <p>
      <span
        >6.1 本服务按《{{
          environment.platformName
        }}隐私条款》的规定收集、使用、储存和分享您的个人信息。您使用本服务，即代表您已阅读、理解并接受该隐私政策全部条款。如果您未满18周岁，在使用本服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意该隐私政策。</span
      >
    </p>
    <p>
      <span
        >6.2 《{{
          environment.platformName
        }}隐私条款》可能会不时更新，以反映适用法律、法规、标准、行业规范等文件的更改，或反映本服务的更改、更新或新功能。《{{
          environment.platformName
        }}隐私条款》有任何更新后，若您继续访问或使用本服务即代表您已阅读、理解并接受了这些更新。</span
      >
    </p>
    <p>
      <span
        >6.3
        我们与您一同致力于您个人信息的保护，我们会在符合商业常理之范围内，尽力采取与本服务相匹配的技术措施或其他安全措施保护您的个人信息安全。尽管如此，我们无法保证您在网络上或通过网络进行信息传输的安全性或保密性。</span
      >
    </p>
    <h1>
      <a name="七知识产权申明" class="md-header-anchor"></a
      ><span>七、知识产权申明</span>
    </h1>
    <p>
      <span
        >7.1
        本服务包含的产品、技术、软件、程序、数据及其他所有信息内容，包括但不限于著作、源代码、图片、档案、资讯、资料、网站架构、网页设计、应用程序编程接口（API）等所有知识产权及相关权利归{{
          environment.platformName
        }}公司所有。</span
      >
    </p>
    <p>
      <span
        >7.2 您在{{
          environment.platformName
        }}平台所创建的独创性数据归属该用户所有，您有权对前述数据进行任何形式的处置，包括从平台中复制，导出和删除。</span
      >
    </p>
    <p>
      <span
        >7.3
        您应当确保其公开或存储（方式包括但不限于：上传、保存、公布、发布等）于本网站及相关服务器的内容不存在任何侵犯其他第三方知识产权的情形。若存在本条所述情形，我们有权根据具体情节针对上述内容，采取包括但不限于限制、屏蔽、删除、修改等手段，由此给其他用户造成的损失由您自行承担若我们因您存在本条所述行为而向其他第三方承担法律责任的，我们有权向您追偿全部损失。</span
      >
    </p>
    <h1>
      <a name="八违约与赔偿" class="md-header-anchor"></a
      ><span>八、违约与赔偿</span>
    </h1>
    <p>
      <span
        >8.1
        如果您违反本协议和/或其他您应遵守的服务条款与规则，我们有权根据独立判断，视情况在不经事先通知您的情况下采取合理的处置措施，包括但不限于预先警示、限制、暂停、终止您使用本服务的部分或全部功能，屏蔽或删除您上传、传播或提供的内容、限制帐号部分或者全部功能、冻结或永久关闭帐号等，您需自行承担由此导致的后果及损失。</span
      >
    </p>
    <p>
      <span
        >8.2
        如果您违反本协议和/或其他您应遵守的服务条款与规则，引起第三方投诉、诉讼索赔等情况的，您应当自行处理并承担全部法律责任。若因您的违法或违约行为导致我们及我们的关联方、合作方等主体遭受任何损失（包括但不限于律师费用与支出）或行政处罚的，您应足额赔偿。</span
      >
    </p>
    <h1>
      <a name="九不可抗力及免责申明" class="md-header-anchor"></a
      ><span>九、不可抗力及免责申明</span>
    </h1>
    <p>
      <span
        >9.1
        您理解并同意，本服务是按照现有技术和条件所能达到的现状提供的。但我们不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力（包括但不限于自然灾害如台风、洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等），大规模的病毒、木马和黑客攻击，系统不稳定，政府管制等原因可能导致的服务中断、数据丢失以及其他的损失和风险，我们不承担任何法律责任及经济赔偿。</span
      >
    </p>
    <p><span>9.2 我们无法对包括但不限于下述内容进行保证：</span></p>
    <p>
      <span
        >（1）我们将尽最大努力保障客户数据的安全备份，但无法承诺100%的数据恢复，对因数据丢失带来的任何直接或间接损失不承担任何责任；</span
      >
    </p>
    <p>
      <span
        >（2）我们将尽可能采取一切措施保护用户数据及个人信息资料安全。但用户通过使用或安装第三方应用打开{{
          environment.platformName
        }}中的文件，该文件会在第三方应用中打开，由于第三方应用而造成的损失，我们不承担任何法律责任及经济赔偿；</span
      >
    </p>
    <p>
      <span
        >（3）因您误操作导致的数据丢失、数据外泄等行为，我们不承担任何法律责任及经济赔偿；</span
      >
    </p>
    <p>
      <span
        >（4）鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途，我们不承担任何法律责任及经济赔偿。</span
      >
    </p>
    <h1>
      <a name="十一合约变更" class="md-header-anchor"></a
      ><span>十一、合约变更</span>
    </h1>
    <p>
      <span
        >11.1
        本合约服务条款的最终解释权归本网站官方所有，本网站有权随时对本合约的条款进行修订并公示，一旦本合约的内容发生变更，本网站将会在修订生效日前一个工作日更新。</span
      >
    </p>
    <p>
      <span
        >如用户继续使用网络服务，则视为用户接受本网站对合约相关条款所做的修订。</span
      >
    </p>
    <h1>
      <a name="十二管辖与法律适用" class="md-header-anchor"></a
      ><span>十二、管辖与法律适用</span>
    </h1>
    <p>
      <span
        >12.1
        本合约的订立、执行和解释以及争议的解决均应适用中华人民共和国法律并受中国法院管辖。</span
      >
    </p>
    <p>
      <span
        >12.2
        协议双方就本合约内容或其执行发生任何争议，应首先力争友好协商解决；协商不成时，任何一方均可向{{
          environment.platformName
        }}公司注册地所在的人民法院提起诉讼。</span
      >
    </p>
    <h1>
      <a name="十三其他" class="md-header-anchor"></a><span>十三、其他</span>
    </h1>
    <p>
      <span
        >13.1
        我们向您发出的任何通知，可采用页面公开区域公告、个人网络区域提示、手机短信或常规信件等方式，且该等通知应自发送之日视为已向用户送达并生效。</span
      >
    </p>
    <p>
      <span
        >14.1
        我们暂时未主张或强制执行本协议的任何约定，不应被视为我们豁免该等约定或放弃该等权利。</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style scoped>
.mac-os-11 {
  --title-bar-height: 28px;
}
html {
  font-size: 14px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0px;
  padding: 0px;
  height: auto;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  font-size: 1rem;
  line-height: 1.42857;
  overflow-x: hidden;
  background: inherit;
  tab-size: 4;
}
iframe {
  margin: auto;
}
a.url {
  word-break: break-all;
}
a:active,
a:hover {
  outline: 0px;
}
.in-text-selection,
::selection {
  text-shadow: none;
  background: var(--select-text-bg-color);
  color: var(--select-text-font-color);
}
#write {
  margin: 0px auto;
  height: auto;
  width: inherit;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  overflow-x: visible;
  padding-top: 36px;
}
#write.first-line-indent p {
  text-indent: 2em;
}
#write.first-line-indent li p,
#write.first-line-indent p * {
  text-indent: 0px;
}
#write.first-line-indent li {
  margin-left: 2em;
}
.for-image #write {
  padding-left: 8px;
  padding-right: 8px;
}
body.typora-export {
  padding-left: 30px;
  padding-right: 30px;
}
.typora-export .footnote-line,
.typora-export li,
.typora-export p {
  white-space: pre-wrap;
}
.typora-export .task-list-item input {
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  body.typora-export {
    padding-left: 0px;
    padding-right: 0px;
  }
  #write {
    padding-left: 20px;
    padding-right: 20px;
  }
  .CodeMirror-sizer {
    margin-left: 0px !important;
  }
  .CodeMirror-gutters {
    display: none !important;
  }
}
#write li > figure:last-child {
  margin-bottom: 0.5rem;
}
#write ol,
#write ul {
  position: relative;
}
img {
  max-width: 100%;
  vertical-align: middle;
  image-orientation: from-image;
}
button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
}
input[type="checkbox"],
input[type="radio"] {
  line-height: normal;
  padding: 0px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
  width: inherit;
}
#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
  position: relative;
}
p {
  line-height: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
  break-inside: avoid;
  orphans: 4;
}
p {
  orphans: 4;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.2rem;
}
h6 {
  font-size: 1rem;
}
.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.hidden {
  display: none;
}
.md-blockmeta {
  color: rgb(204, 204, 204);
  font-weight: 700;
  font-style: italic;
}
a {
  cursor: pointer;
}
sup.md-footnote {
  padding: 2px 4px;
  background-color: rgba(238, 238, 238, 0.7);
  color: rgb(85, 85, 85);
  border-radius: 4px;
  cursor: pointer;
}
sup.md-footnote a,
sup.md-footnote a:hover {
  color: inherit;
  text-transform: inherit;
  text-decoration: inherit;
}
#write input[type="checkbox"] {
  cursor: pointer;
  width: inherit;
  height: inherit;
}
figure {
  overflow-x: auto;
  margin: 1.2em 0px;
  max-width: calc(100% + 16px);
  padding: 0px;
}
figure > table {
  margin: 0px;
}
tr {
  break-inside: avoid;
  break-after: auto;
}
thead {
  display: table-header-group;
}
table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
  overflow: auto;
  break-inside: auto;
  text-align: left;
}
table.md-table td {
  min-width: 32px;
}
.CodeMirror-gutters {
  border-right: 0px;
  background-color: inherit;
}
.CodeMirror-linenumber {
  user-select: none;
}
.CodeMirror {
  text-align: left;
}
.CodeMirror-placeholder {
  opacity: 0.3;
}
.CodeMirror pre {
  padding: 0px 4px;
}
.CodeMirror-lines {
  padding: 0px;
}
div.hr:focus {
  cursor: none;
}
#write pre {
  white-space: pre-wrap;
}
#write.fences-no-line-wrapping pre {
  white-space: pre;
}
#write pre.ty-contain-cm {
  white-space: normal;
}
.CodeMirror-gutters {
  margin-right: 4px;
}
.md-fences {
  font-size: 0.9rem;
  display: block;
  break-inside: avoid;
  text-align: left;
  overflow: visible;
  white-space: pre;
  background: inherit;
  position: relative !important;
}
.md-diagram-panel {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 8px;
  overflow-x: auto;
}
#write .md-fences.mock-cm {
  white-space: pre-wrap;
}
.md-fences.md-fences-with-lineno {
  padding-left: 0px;
}
#write.fences-no-line-wrapping .md-fences.mock-cm {
  white-space: pre;
  overflow-x: auto;
}
.md-fences.mock-cm.md-fences-with-lineno {
  padding-left: 8px;
}
.CodeMirror-line,
twitterwidget {
  break-inside: avoid;
}
.footnotes {
  opacity: 0.8;
  font-size: 0.9rem;
  margin-top: 1em;
  margin-bottom: 1em;
}
.footnotes + .footnotes {
  margin-top: 0px;
}
.md-reset {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  vertical-align: top;
  background: 0px 0px;
  text-decoration: none;
  text-shadow: none;
  float: none;
  position: static;
  width: auto;
  height: auto;
  white-space: nowrap;
  cursor: inherit;
  -webkit-tap-highlight-color: transparent;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  box-sizing: content-box;
  direction: ltr;
}
li div {
  padding-top: 0px;
}
blockquote {
  margin: 1rem 0px;
}
li .mathjax-block,
li p {
  margin: 0.5rem 0px;
}
li blockquote {
  margin: 1rem 0px;
}
li {
  margin: 0px;
  position: relative;
}
blockquote > :last-child {
  margin-bottom: 0px;
}
blockquote > :first-child,
li > :first-child {
  margin-top: 0px;
}
.footnotes-area {
  color: rgb(136, 136, 136);
  margin-top: 0.714rem;
  padding-bottom: 0.143rem;
  white-space: normal;
}
#write .footnote-line {
  white-space: pre-wrap;
}
@media print {
  body,
  html {
    border: 1px solid transparent;
    height: 99%;
    break-after: avoid;
    break-before: avoid;
    font-variant-ligatures: no-common-ligatures;
  }
  #write {
    margin-top: 0px;
    padding-top: 0px;
    border-color: transparent !important;
  }
  .typora-export * {
    -webkit-print-color-adjust: exact;
  }
  .typora-export #write {
    break-after: avoid;
  }
  .typora-export #write::after {
    height: 0px;
  }
  .is-mac table {
    break-inside: avoid;
  }
}
.footnote-line {
  margin-top: 0.714em;
  font-size: 0.7em;
}
a img,
img a {
  cursor: pointer;
}
pre.md-meta-block {
  font-size: 0.8rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
  background: rgb(204, 204, 204);
  display: block;
  overflow-x: hidden;
}
p > .md-image:only-child:not(.md-img-error) img,
p > img:only-child {
  display: block;
  margin: auto;
}
#write.first-line-indent p > .md-image:only-child:not(.md-img-error) img {
  left: -2em;
  position: relative;
}
p > .md-image:only-child {
  display: inline-block;
  width: 100%;
}
#write .MathJax_Display {
  margin: 0.8em 0px 0px;
}
.md-math-block {
  width: 100%;
}
.md-math-block:not(:empty)::after {
  display: none;
}
.MathJax_ref {
  fill: currentcolor;
}
[contenteditable="true"]:active,
[contenteditable="true"]:focus,
[contenteditable="false"]:active,
[contenteditable="false"]:focus {
  outline: 0px;
  box-shadow: none;
}
.md-task-list-item {
  position: relative;
  list-style-type: none;
}
.task-list-item.md-task-list-item {
  padding-left: 0px;
}
.md-task-list-item > input {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: -1.2em;
  margin-top: calc(1em - 10px);
  border: none;
}
.math {
  font-size: 1rem;
}
.md-toc {
  min-height: 3.58rem;
  position: relative;
  font-size: 0.9rem;
  border-radius: 10px;
}
.md-toc-content {
  position: relative;
  margin-left: 0px;
}
.md-toc-content::after,
.md-toc::after {
  display: none;
}
.md-toc-item {
  display: block;
  color: rgb(65, 131, 196);
}
.md-toc-item a {
  text-decoration: none;
}
.md-toc-inner:hover {
  text-decoration: underline;
}
.md-toc-inner {
  display: inline-block;
  cursor: pointer;
}
.md-toc-h1 .md-toc-inner {
  margin-left: 0px;
  font-weight: 700;
}
.md-toc-h2 .md-toc-inner {
  margin-left: 2em;
}
.md-toc-h3 .md-toc-inner {
  margin-left: 4em;
}
.md-toc-h4 .md-toc-inner {
  margin-left: 6em;
}
.md-toc-h5 .md-toc-inner {
  margin-left: 8em;
}
.md-toc-h6 .md-toc-inner {
  margin-left: 10em;
}
@media screen and (max-width: 48em) {
  .md-toc-h3 .md-toc-inner {
    margin-left: 3.5em;
  }
  .md-toc-h4 .md-toc-inner {
    margin-left: 5em;
  }
  .md-toc-h5 .md-toc-inner {
    margin-left: 6.5em;
  }
  .md-toc-h6 .md-toc-inner {
    margin-left: 8em;
  }
}
a.md-toc-inner {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.footnote-line a:not(.reversefootnote) {
  color: inherit;
}
.md-attr {
  display: none;
}
.md-fn-count::after {
  content: ".";
}
code,
pre,
samp,
tt {
  font-family: var(--monospace);
}
kbd {
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: rgb(36, 39, 41);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(173, 179, 185);
  border-radius: 3px;
  box-shadow: rgba(12, 13, 14, 0.2) 0px 1px 0px,
    rgb(255, 255, 255) 0px 0px 0px 2px inset;
  white-space: nowrap;
  vertical-align: middle;
}
.md-comment {
  color: rgb(162, 127, 3);
  opacity: 0.8;
  font-family: var(--monospace);
}
code {
  text-align: left;
  vertical-align: initial;
}
a.md-print-anchor {
  white-space: pre !important;
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  display: inline-block !important;
  position: absolute !important;
  width: 1px !important;
  right: 0px !important;
  outline: 0px !important;
  background: 0px 0px !important;
  text-decoration: initial !important;
  text-shadow: initial !important;
}
.md-inline-math .MathJax_SVG .noError {
  display: none !important;
}
.html-for-mac .inline-math-svg .MathJax_SVG {
  vertical-align: 0.2px;
}
.md-math-block .MathJax_SVG_Display {
  text-align: center;
  margin: 0px;
  position: relative;
  text-indent: 0px;
  max-width: none;
  max-height: none;
  min-height: 0px;
  min-width: 100%;
  width: auto;
  overflow-y: hidden;
  display: block !important;
}
.MathJax_SVG_Display,
.md-inline-math .MathJax_SVG_Display {
  width: auto;
  margin: inherit;
  display: inline-block !important;
}
.MathJax_SVG .MJX-monospace {
  font-family: var(--monospace);
}
.MathJax_SVG .MJX-sans-serif {
  font-family: sans-serif;
}
.MathJax_SVG {
  display: inline;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  zoom: 90%;
  text-indent: 0px;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  overflow-wrap: normal;
  white-space: nowrap;
  float: none;
  direction: ltr;
  max-width: none;
  max-height: none;
  min-width: 0px;
  min-height: 0px;
  border: 0px;
  padding: 0px;
  margin: 0px;
}
.MathJax_SVG * {
  transition: none 0s ease 0s;
}
.MathJax_SVG_Display svg {
  vertical-align: middle !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.os-windows.monocolor-emoji .md-emoji {
  font-family: "Segoe UI Symbol", sans-serif;
}
.md-diagram-panel > svg {
  max-width: 100%;
}
[lang="flow"] svg,
[lang="mermaid"] svg {
  max-width: 100%;
  height: auto;
}
[lang="mermaid"] .node text {
  font-size: 1rem;
}
table tr th {
  border-bottom: 0px;
}
video {
  max-width: 100%;
  display: block;
  margin: 0px auto;
}
iframe {
  max-width: 100%;
  width: 100%;
  border: none;
}
.highlight td,
.highlight tr {
  border: 0px;
}
mark {
  background: rgb(255, 255, 0);
  color: rgb(0, 0, 0);
}
.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
  color: inherit;
}
mark .md-meta {
  color: rgb(0, 0, 0);
  opacity: 0.3 !important;
}
@media print {
  .typora-export h1,
  .typora-export h2,
  .typora-export h3,
  .typora-export h4,
  .typora-export h5,
  .typora-export h6 {
    break-inside: avoid;
  }
}
.md-diagram-panel .messageText {
  stroke: none !important;
}
.md-diagram-panel .start-state {
  fill: var(--node-fill);
}
.md-diagram-panel .edgeLabel rect {
  opacity: 1 !important;
}
.md-require-zoom-fix foreignobject {
  font-size: var(--mermaid-font-zoom);
}

:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
}

@include-when-export url(https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext);

/* open-sans-regular - latin-ext_latin */
/* open-sans-italic - latin-ext_latin */
/* open-sans-700 - latin-ext_latin */
/* open-sans-700italic - latin-ext_latin */
html {
  font-size: 16px;
}

body {
  font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: rgb(51, 51, 51);
  line-height: 1.6;
}

#write {
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}

#write > ul:first-child,
#write > ol:first-child {
  margin-top: 30px;
}

a {
  color: #4183c4;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.4;
  cursor: text;
}
h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  text-decoration: none;
}
h1 tt,
h1 code {
  font-size: inherit;
}
h2 tt,
h2 code {
  font-size: inherit;
}
h3 tt,
h3 code {
  font-size: inherit;
}
h4 tt,
h4 code {
  font-size: inherit;
}
h5 tt,
h5 code {
  font-size: inherit;
}
h6 tt,
h6 code {
  font-size: inherit;
}
h1 {
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}
h2 {
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

/*@media print {
    .typora-export h1,
    .typora-export h2 {
        border-bottom: none;
        padding-bottom: initial;
    }

    .typora-export h1::after,
    .typora-export h2::after {
        content: "";
        display: block;
        height: 100px;
        margin-top: -96px;
        border-top: 1px solid #eee;
    }
}*/

h3 {
  font-size: 1.5em;
  line-height: 1.43;
}
h4 {
  font-size: 1.25em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: 1em;
  color: #777;
}
p,
blockquote,
ul,
ol,
dl,
table {
  margin: 0.8em 0;
}
li > ol,
li > ul {
  margin: 0 0;
}
hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}
ul,
ol {
  padding-left: 30px;
}
ul:first-child,
ol:first-child {
  margin-top: 0;
}
ul:last-child,
ol:last-child {
  margin-bottom: 0;
}
blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777777;
}
blockquote blockquote {
  padding-right: 0;
}
table {
  padding: 0;
  word-break: initial;
}
table tr {
  border-top: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}
table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}
table th {
  font-weight: bold;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}
table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}
table th:first-child,
table td:first-child {
  margin-top: 0;
}
table th:last-child,
table td:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0px 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

@media print {
  html {
    font-size: 13px;
  }
  table,
  pre {
    page-break-inside: avoid;
  }
  pre {
    word-wrap: break-word;
  }
}

.md-fences {
  background-color: #f8f8f8;
}
#write pre.md-meta-block {
  padding: 1rem;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 3px;
  color: #777777;
  margin-top: 0 !important;
}

.mathjax-block > .code-tooltip {
  bottom: 0.375rem;
}

.md-mathjax-midline {
  background: #fafafa;
}

#write > h3.md-focus:before {
  left: -1.5625rem;
  top: 0.375rem;
}
#write > h4.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}
#write > h5.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}
#write > h6.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}
.md-image > .md-meta {
  /*border: 1px solid #ddd;*/
  border-radius: 3px;
  padding: 2px 0px 0px 4px;
  font-size: 0.9em;
  color: inherit;
}

.md-tag {
  color: #a7a7a7;
  opacity: 1;
}

.md-toc {
  margin-top: 20px;
  padding-bottom: 20px;
}

.sidebar-tabs {
  border-bottom: none;
}

#typora-quick-open {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

#typora-quick-open-item {
  background-color: #fafafa;
  border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
  border-style: solid;
  border-width: 1px;
}

/** focus mode */
.on-focus-mode blockquote {
  border-left-color: rgba(85, 85, 85, 0.12);
}

header,
.context-menu,
.megamenu-content,
footer {
  font-family: "Segoe UI", "Arial", sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
  visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
  background-color: #fafafa;
  background-color: var(--side-bar-bg-color);
}

.md-lang {
  color: #b4654d;
}

.html-for-mac .context-menu {
  --item-hover-bg-color: #e6f0fe;
}

#md-notification .btn {
  border: 0;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
}

.ty-preferences .window-content {
  background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
  color: white;
  background: #999;
}
p {
  line-height: 1.6 !important;
}
:root {
  --mermaid-font-zoom: 1.25em;
}
</style>
