import request from "@/utils/request";

/**
 * 单位员工列表
 * @param data
 * @returns {*}
 */
export function employeeList(data) {
  return request({
    url: "/company/employee/list",
    method: "post",
    data,
  });
}
/**
 * 员工保存接口
 * @param data
 * @returns {*}
 */
export function employeeSave(data) {
  return request({
    url: "/employee/save",
    method: "post",
    data,
  });
}
/**
 * 员工切换管理员
 * @param data
 * @returns {*}
 */
export function employeeSwitchAdmin(data) {
  return request({
    url: "/employee/switchAdmin",
    method: "post",
    data,
  });
}
/**
 * 重置员工密码
 * @param data
 * @returns {*}
 */
export function employeeResetPassword(data) {
  return request({
    url: "/employee/resetPassword",
    method: "post",
    data,
  });
}
/**
 * 删除员工
 * @param data
 * @returns {*}
 */
export function employeeDelete(data) {
  return request({
    url: "/employee/delete",
    method: "post",
    data,
  });
}
/**
 * 启用员工
 * @param data
 * @returns {*}
 */
export function employeeSwitchUserActive(data) {
  return request({
    url: "/employee/switchUserActive",
    method: "post",
    data,
  });
}
/**
 * 设置密码
 *   companyId:'',
 *   employeeId:'',
 *   psw:''
 *
 * @returns {*}
 * @param data
 */
export function employeeSetPassword(data) {
  return request({
    url: "/employee/setPassword",
    method: "post",
    data,
  });
}
