import { advanceFields } from "@zgg-core-utils/whiteList";
import {
  isEmpty,
  getMomentFormat,
  mapFlowStatus,
  getStrByNumberFormat,
} from "@zgg-core-utils/utils";
import _ from "lodash";
import { convertToCH } from "@/utils/convertToCH";
import moment from "moment";

export function isJsonValue(name) {
  let array = [
    "checkbox_group",
    "json_form",
    "image_uploader",
    "attachment_uploader",
    "address_input",
  ];
  return array.includes(name);
}

export function isArrayValue(name) {
  let array = [
    "checkbox_group",
    "json_form",
    "image_uploader",
    "attachment_uploader",
  ];
  return array.includes(name);
}

export function isSortableComponent(name) {
  let array = [
    "input",
    "text_area",
    "input_number",
    "date_picker",
    "select",
    "sn_input",
    "radio_group",
  ];
  return array.includes(name);
}

export function isExportFileName(name) {
  let array = [
    "input",
    "input_number",
    "date_picker",
    "select",
    "radio_group",
    "mobile_input",
    "user_select",
    "department_select",
  ];
  return array.includes(name);
}
/**
 * 获取非容器的表单组件
 */
export function getFormComponents(componentList) {
  let list = [];
  if (componentList) {
    componentList
      .filter(() => true)
      .forEach((row) => {
        if (row.form) {
          list.push(row);
        } else if (row.container) {
          list = list.concat(getFormComponents(row.components));
        }
      });
  }
  return list;
}
/**
 * 获取非容器的表单组件(不排除关联数据)
 */
export function getFormComponentsWithFormData(componentList) {
  let list = [];
  if (componentList) {
    componentList
      .filter(() => true)
      .forEach((row) => {
        if (row.form) {
          list.push(row);
        } else if (row.container) {
          list = list.concat(getFormComponentsWithFormData(row.components));
        } else if (row.componentName == "form_data") {
          list.push(row);
        }
      });
  }
  return list;
}
/**
 * 判断组件是否存在
 * @param {*} fieldName
 * @param {*} componentList
 */
export function existInComponentsByName(fieldName, componentList) {
  //因关联数据表格列字段和搜索字段现在允许通过视图直接来读取，所以有可能存在如下三个字段固定字段，如遇如下3个字段，直接认为存在，返回true
  if (isEmpty(fieldName)) {
    return true;
  }
  const alwaysExistFieldName = ["createdTime", "createrName", "updatedTime"];
  if (alwaysExistFieldName.includes(fieldName)) {
    return true;
  }
  let list = getFormComponents(componentList).concat(
    componentList.filter((row) => !row.form),
  );

  let result = false;
  if (fieldName) {
    if (fieldName.indexOf(".") == -1) {
      // 非子表单
      result = list.findIndex((row) => row.name == fieldName) >= 0;
      if (!result) {
        let list2 = list.filter((row) => row.componentName == "json_form");
        result =
          list2.findIndex(
            (item) =>
              item.components.findIndex((row) => row.name == fieldName) >= 0,
          ) >= 0;
      }
    } else {
      // 子表单或关联数据
      let names = fieldName.split(".");
      result =
        list.findIndex((row) => {
          if (row.name == names[0]) {
            let children;
            if (row.componentName == "reference_data") {
              children = row.columns;
              return (
                children.findIndex((child) => child.field.name == names[1]) >= 0
              );
            } else {
              children = row.components;
              return children.findIndex((child) => child.name == names[1]) >= 0;
            }
          }
        }) >= 0;
    }
  }
  return result;
}

/**
 * 传入field来判断组件是否存在
 * 此方法目前为了处理配置了关联数据内的字段时，无法通过existInComponentsByName校验
 * @param {*} fieldName
 * @param {*} componentList
 */
export function existInComponentsByField(
  { fieldName, referenceName },
  componentList,
) {
  //todo 此处需要再处理关联数据内的子表单的字段校验
  // const fieldName = field.field.name
  // const referenceName = field.referenceName
  if (fieldName == "flowStatus") {
    return true;
  }
  if (referenceName) {
    let allFormFieldList = getFormComponents(componentList);
    let findIndex = allFormFieldList.findIndex(
      (field) => field.name === referenceName,
    );
    const referenceField = findIndex >= 0 && allFormFieldList[findIndex];
    if (referenceField) {
      return (
        referenceField.columns.findIndex(
          (column) => column.field.name === fieldName,
        ) !== -1
      );
    } else {
      return false;
    }
  } else {
    return existInComponentsByName(fieldName, componentList);
  }
}

/**
 * 获取表单中允许成为过滤条件的组件列表
 * @param {*} componentList
 * @returns
 */
export function getAdvanceFieldList(componentList) {
  let list = [];

  let callback = (components, parent) => {
    components.forEach((item) => {
      if (item.container) {
        callback(item.components, item);
      } else if (item.componentName == "reference_data") {
        if (!parent) {
          list.push(_.cloneDeep(item));
          item.columns.forEach((col) => {
            let obj = _.cloneDeep(col.field.component);
            // 关联数据的子表单字段不进入过滤条件
            if (
              obj.componentName != "json_form" &&
              advanceFields.includes(obj.componentName)
            ) {
              obj.title = item.title + "." + col.title;
              obj.collection = item.tableName;
              obj.referenceName = item.name;
              obj.parent = _.cloneDeep(item);
              list.push(obj);
            }
          });
        }
      } else if (item.componentName == "form_data") {
        item.columns.forEach((col) => {
          if (
            col.field.componentName != "json_form" &&
            advanceFields.includes(col.field.componentName)
          ) {
            let obj;
            if (col.field.component) {
              obj = _.cloneDeep(col.field.component);
            } else {
              obj = _.cloneDeep(col.field);
            }
            obj.title = item.title + "." + col.title;
            obj.collection = item.tableName;
            obj.referenceName = item.key;
            obj.parent = _.cloneDeep(item);
            list.push(obj);
          }
        });
      } else if (item.form) {
        if (advanceFields.includes(item.componentName)) {
          let obj = _.cloneDeep(item);
          if (parent && parent.componentName == "json_form") {
            obj.name = parent.name + "." + obj.name;
            obj.title = parent.title + "." + obj.title;
            obj.parent = _.cloneDeep(parent);
          }
          list.push(obj);
        }
      }
    });
  };
  callback(componentList);

  return list;
}

/**
 * 获取表单中允许成为过滤条件值的组件列表
 * @param {*} componentList
 * @returns
 */
export function getAdvanceValueList(componentList) {
  let list = [];

  let callback = (components, parent) => {
    components.forEach((item) => {
      if (item.container) {
        callback(item.components, item);
      } else if (item.componentName == "reference_data") {
        if (!parent) {
          list.push(_.cloneDeep(item));
        }
      } else if (item.form) {
        if (advanceFields.includes(item.componentName)) {
          let obj = _.cloneDeep(item);
          if (parent && parent.componentName == "json_form") {
            obj.name = obj.name;
            obj.title = parent.title + "." + obj.title;
            obj.parent = _.cloneDeep(parent);
          }
          list.push(obj);
        }
      }
    });
  };
  callback(componentList);

  return list;
}
/**
 * 前端事件返回值填充
 */
export function fillField(key, value, componentList) {
  let component = componentList.filter((item) => {
    return item.key == key;
  });
  if (component.length > 0) {
    component = component[0];
    if (
      component.componentName == "checkbox_group" ||
      component.componentName == "select_checkbox"
    ) {
      if (Array.isArray(value)) {
        let options = value.filter((row) => {
          let option = component.customOptions.find((item) => {
            return item.value == row;
          });
          if (option) {
            return option;
          }
        });
        return options;
      } else if (typeof value == "string") {
        let option = component.customOptions.find((item) => {
          return item.value == value;
        });
        if (option) {
          return [value];
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else if (component.componentName == "input_number") {
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return value;
      }
      return;
    } else {
      if (typeof value == "string" || typeof value == "number") {
        return value;
      }
      return;
    }
  }
}

/**
 * 前端事件格式化请求参数
 */
export function formatRelyValue(relyArr, form, componentList) {
  let rely = {};
  relyArr.forEach((row) => {
    let component = componentList.filter((item) => {
      return item.name == row;
    });
    if (component && component.length) {
      component = component[0];
      if (form[row]) {
        if (component.componentName == "select") {
          rely[row] = form[row].name || "";
        } else if (
          component.componentName == "checkbox_group" ||
          component.componentName == "select_checkbox"
        ) {
          // let str = "";
          // let length = form[row].length
          // form[row].map((row, index) => {
          //   if (length > index + 1) {
          //     str += row + ",";
          //   } else {
          //     str += row;
          //   }
          // });
          rely[row] = form[row].join(",");
        } else if (component.componentName == "address_input") {
          rely[row] =
            form[row].province +
            form[row].city +
            form[row].county +
            form[row].address;
        } else if (component.componentName == "user_select") {
          rely[row] = form[row].id;
        } else if (component.componentName == "department_select") {
          rely[row] = form[row].departmentId;
        } else if (
          component.componentName == "attachment_uploader" ||
          component.componentName == "image_uploader"
        ) {
          let str = "";
          form[row].forEach((item, index) => {
            if (form[row].length > index + 1) {
              str += item.url + ",";
            } else {
              str += item.url;
            }
          });
          rely[row] = str;
        } else if (component.componentName == "sign_input") {
          rely[row] = form[row].url;
        } else if (component.componentName == "reference_data") {
          // 关联数据
          let name = row + "ReferenceData";
          rely[row] = form[name];
        } else if (component.componentName == "reference_data_list") {
          // 关联数据多条
          let name = row + "ReferenceDataList";
          rely[row] = form[name];
        } else {
          rely[row] = form[row];
        }
      } else {
        rely[row] = "";
      }
    }
  });
  return rely;
}

/**
 * 获取表单组件值(主要针对人员工、部门选择）
 */
export function dealWithFormValue(form, component, extraParams = {}) {
  let value;
  if (form) {
    value = convertToCH(form[component.name]);
  }
  //为空值，返回空字符串
  if (isEmpty(value)) {
    return "";
  }
  // 处理流程状态, 如果value是流程状态则处理成中文, 不是则原样返回
  value = mapFlowStatus(value);

  //透视图存在二次添加汇总字段，直接返回字符串
  if (value == "汇总") {
    return value;
  }
  if (component) {
    if (component.componentName == "date_picker") {
      if (value) {
        if (extraParams.datePrecision) {
          // 当维度为日期且存在汇总方式时，改变时间显示精度
          let format;
          //由于服务端day日期前台导出format参数只支持小写dd，故format数据保存为dd，渲染时转换为DD
          if (extraParams.datePrecision.picker == "date") {
            let newDatePrecision = _.cloneDeep(extraParams.datePrecision);
            if (newDatePrecision.format) {
              newDatePrecision.format =
                extraParams.datePrecision.format.replaceAll("d", "D");
            }
            format = getMomentFormat(newDatePrecision);
          } else {
            format = getMomentFormat(extraParams.datePrecision);
          }
          value = moment(value).format(format);
        } else {
          value = moment(value).format(getMomentFormat(component));
        }
      }

      if (value == "Invalid date") {
        value = form[component.name];
      }
    } else if (
      component.componentName == "address_input" ||
      component.componentName == "position_input"
    ) {
      let str = "";
      if (typeof value === "string") {
        return value;
      }
      if (value instanceof Object) {
        if (component.componentName == "position_input") {
          str = `${value.province}${value.city}${value.county}${value.address}`;
        } else {
          let picker = component.picker;
          if (picker == "province") {
            str = `${value.province}`;
          } else if (picker == "city") {
            str = `${value.province}${value.city}`;
          } else if (picker == "county") {
            str = `${value.province}${value.city}${value.county}`;
          } else if (picker == "address") {
            str = `${value.province}${value.city}${value.county}${value.address}`;
          }
        }
      }
      return str;
    } else if (component.componentName == "user_select") {
      let obj = form[`${component.name}UserInfo`];
      if (obj) {
        return obj.name;
      }
    } else if (component.componentName == "user_list_select") {
      let obj = form[`${component.name}UserMap`];
      if (obj) {
        let list = Object.values(obj);
        if (list.length) {
          return list.map((item) => item.name).join(",");
        }
        return "";
      } else {
        return "";
      }
    } else if (component.componentName == "department_select") {
      let obj = form[`${component.name}DepartmentInfo`];
      if (obj) {
        return obj.name;
      }
    } else if (component.componentName == "department_list_select") {
      let list = form[`${component.name}DepartmentInfoList`];
      if (list) {
        let v = list.map((item) => item.name).join(",");

        return v;
      } else {
        return "";
      }
    } else if (component.componentName == "input_number") {
      if (!isEmpty(value)) {
        let numberFormat = {
          thousandsSeparator: component.thousandsSeparator,
          decimal: component.decimalPrecision,
        };

        value = getStrByNumberFormat(value, numberFormat);
      }
    } else if (component.array) {
      if (value instanceof Array) {
        return value.join(",");
      }
    }
  }

  return value;
}

/**
 * 获取表单组件的宽度
 * @param {object} component 组件
 * @param {number} cols 全局列数
 * @returns
 */
export function getComponentWidth(component, cols) {
  if (
    [
      "json_form",
      "tab",
      "grid",
      "reference_data_list",
      "divider",
      "html",
      "html_input",
    ].includes(component.componentName)
  ) {
    return `width:${100 * eval("1/1")}%;`;
  }
  if (!component.extParam || !component.extParam.customWidth) {
    return `width:${100 / cols}%;`;
  } else {
    return `width:${100 * eval(component.extParam.customWidth)}%;`;
  }
}
