<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState("user", ["token"]),
  },
  mounted() {
    window.addEventListener("storage", (event) => {
      let { key, newValue, oldValue } = event;
      if (key == "SET_USER_ID") {
        if (oldValue !== newValue) {
          this.$alert("当前登录身份异常，请刷新后重试", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$router.replace("/");
            },
          });
        }
      }
    });
  },
  created() {
    if (this.token) {
      this.$store.dispatch("area/getAreaTreee");
      this.$store.dispatch("sysFields/fetchSysFields");
    }
    // console.log();
  },
};
</script>
