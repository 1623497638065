<template>
  <div class="login-container">
    <el-form
      ref="codeLoginForm"
      :model="codeLoginForm"
      :rules="codeLoginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
      v-if="loginType === 'code'"
    >
      <div class="title-container">
        <h3 class="title">{{ environment.platformName }}运营中心</h3>
      </div>

      <div class="login-tab-change">
        <div
          v-if="environment.hasSmsConfig"
          class="tab-item"
          :class="loginType === 'code' ? 'active' : ''"
          @click="codeLoginModelClick"
        >
          验证码登录
        </div>
        <div
          class="tab-item"
          :class="loginType === 'userName' ? 'active' : ''"
          @click="handleUsernameLoginSwitch"
        >
          密码登录
        </div>
      </div>

      <el-form-item prop="mobile">
        <el-input
          v-model.number="codeLoginForm.mobile"
          placeholder="请输入手机号"
          name="mobile"
          type="tel"
          tabindex="1"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          auto-complete="on"
        />
      </el-form-item>

      <el-form-item prop="code">
        <el-row>
          <el-col :span="17">
            <el-input
              v-model="codeLoginForm.code"
              placeholder="请输入验证码"
              name="code"
              tabindex="2"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              @keyup.enter.native="handleLogin"
            />
          </el-col>
          <el-col :span="7">
            <span v-if="codeCount !== 0" class="code-count-span"
              >{{ codeCount }}s</span
            >
            <span
              v-if="codeCount === 0"
              @click="handleGetLoginCode"
              class="code-count-span"
              >获取验证码</span
            >
          </el-col>
        </el-row>
      </el-form-item>

      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click="handleCodeLogin"
        >登录
      </el-button>
      <div class="forget" @click="forgetPwd">忘记密码</div>
    </el-form>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
      v-if="loginType === 'userName'"
    >
      <div class="title-container">
        <h3 class="title">{{ environment.platformName }}运营中心</h3>
      </div>

      <div class="login-tab-change">
        <div
          v-if="environment.hasSmsConfig"
          class="tab-item"
          :class="loginType === 'code' ? 'active' : ''"
          @click="codeLoginModelClick"
        >
          验证码登录
        </div>
        <div
          class="tab-item"
          :class="loginType === 'userName' ? 'active' : ''"
          @click="handleUsernameLoginSwitch"
        >
          密码登录
        </div>
      </div>

      <el-form-item prop="loginName">
        <el-input
          ref="loginName"
          v-model="loginForm.loginName"
          placeholder="请输入手机号/用户名"
          name="loginName"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="请输入密码"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" @click="showPwd">
          <svg-icon
            :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
          />
        </span>
      </el-form-item>

      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click="handleLogin"
        >登录
      </el-button>
      <div class="forget" @click="forgetPwd" v-if="environment.hasSmsConfig">
        忘记密码
      </div>
    </el-form>
    <div class="statement">
      注册/登录代表您已同意{{ environment.platformName }}的
      <a @click.prevent="openAgreement">《服务协议》</a> 和
      <a @click.prevent="openPrivacy">《隐私协议》</a>
    </div>
  </div>
</template>

<script>
import { getLoginCode, getRegisterCode, reg } from "@/api/user";
export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      const codeP = /^[0-9]*$/;
      if (!codeP.test(value)) {
        callback(new Error("验证码只能为数字"));
      } else {
        if (value.length !== 4) {
          this.validateCodeOk = false;
          callback(new Error("请输入正确的验证码"));
        } else {
          this.validateCodeOk = true;
          callback();
        }
      }
    };

    const validatePhone = (rule, value, callback) => {
      if (value.toString().length !== 11) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        loginName: "",
        password: "",
      },
      loginRules: {
        loginName: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      codeLoginForm: {
        mobile: "",
        code: "",
        sid: "",
      },
      codeLoginRules: {
        mobile: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
      },
      regForm: {
        name: "",
        orgName: "",
        password: "",
        confirmPassword: "",
      },
      regRules: {},

      loading: false,
      passwordType: "password",
      redirect: undefined,
      loginType: environment.hasSmsConfig ? "code" : "userName",
      codeCount: 0,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
    loginType: function (val) {
      if (val === "code") {
        this.$refs["loginForm"].clearValidate();
      } else if (val === "userName") {
        this.$refs["codeLoginForm"].clearValidate();
      }
    },
  },
  methods: {
    handleUsernameLoginSwitch() {
      this.loginForm.loginName = this.codeLoginForm.mobile;
      this.loginType = "userName";
    },
    //新用户注册
    newUserRegModelClick(formType) {
      let mobile;
      if (formType === "code") {
        mobile = this.codeLoginForm.mobile;
      } else if (formType === "username") {
        mobile = this.loginForm.loginName;
      }
      console.log(this.codeLoginForm.mobile);
      this.$router.push({
        name: "注册",
        params: { mobile },
      });
    },
    //验证码登录菜单的点击事件
    codeLoginModelClick() {
      this.loginType = "code";
      this.codeLoginForm.mobile = this.loginForm.loginName;
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      console.log("re");
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.loginForm)
            .then((data) => {
              this.$router.push({ path: this.redirect || "/" });
              this.$store.dispatch("area/getAreaTreee");
              this.$store.dispatch("sysFields/fetchSysFields");
              this.$store.dispatch(
                "user/setCurrentUser",
                data.data.currentUser,
              );
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              //todo 如果
              if (err.code === 1001) {
                this.loginType = "code";
                this.codeLoginForm.phone = this.loginForm.username;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //点击获取验证码按钮
    handleGetLoginCode() {
      //判断已经输入手机号
      //todo 需要做前端手机号的校验，目前先不加，可能需要用非标准的呃手机号和验证码进行测试
      //todo 将手机号发给后台，发送短信验证码
      let data = {
        mobile: this.codeLoginForm.mobile,
      };
      getLoginCode(data).then((resp) => {
        let { seconds, sid } = resp.data;
        this.codeCount = seconds;
        this.codeLoginForm.sid = sid;
        let timer = setInterval(() => {
          if (this.codeCount === 0) {
            clearInterval(timer);
          } else {
            this.codeCount -= 1;
          }
        }, 1000);
      });
    },
    handleCodeLogin() {
      //如果未注册，跳转到完善信息的界面
      //如果已注册，直接跳转到工作台

      this.$refs.codeLoginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/codeLogin", this.codeLoginForm)
            .then((resp) => {
              // if (resp.data.token.registered) {
              //     this.$router.push({path: this.redirect || '/'})
              // } else {
              //     this.loginType = "reg"
              // }
              this.$router.push({ path: this.redirect || "/" });
              this.$store.dispatch("area/getAreaTreee");
              this.$store.dispatch("sysFields/fetchSysFields");
              this.$store.dispatch(
                "user/setCurrentUser",
                resp.data.currentUser,
              );
              this.loading = false;
            })
            .catch((error) => {
              // this.$message({type: "error", message: error.message})
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 忘记密码
    forgetPwd() {
      this.$router.push({
        path: "/findPwd",
      });
    },

    openAgreement() {
      let url = this.$router.resolve({
        path: "/agreement",
      });
      window.open(url.href, "_blank");
    },
    openPrivacy() {
      let url = this.$router.resolve({
        path: "/privacy",
      });
      window.open(url.href, "_blank");
    },
  },
  mounted() {
    let mobile = this.$route.params.mobile;
    if (mobile) {
      this.codeLoginForm.mobile = mobile;
    }
    console.log(environment);
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .statement {
    margin-top: 20px;
    font-size: 12px;

    & > a:hover {
      color: #3488ff;
      text-decoration: underline;
    }
  }

  .el-form {
    background-color: #fff;
    border-radius: 20px;
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      color: #000;
      height: 45px;
      caret-color: #2a2a2a;

      &:-webkit-autofill {
        //box-shadow: 0 0 0px 1000px $bg inset !important;
        //-webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }

  .code-count-span {
    display: flex;
    height: 47px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
$bg: #f4f5f7;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;

  .login-form {
    position: relative;
    width: 420px;
    padding: 0 50px;
    max-width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #172b4d;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}

.bottom-layer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #bbb;
  font-size: 12px;
  margin: 5px;
}

::v-deep .el-form-item {
  border: none;
  background: none;

  & > .el-form-item__content {
    background-color: #f2f6fc;
    border-radius: 10px;
  }
}

.login-tab-change {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  margin-bottom: 30px;

  .tab-item {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(9, 30, 66, 0.3);
    height: 30px;
    cursor: pointer;

    &:hover {
    }
  }

  .tab-item.active {
    color: rgba(9, 30, 66, 0.95);
    position: relative;
  }

  .tab-item.active::after {
    position: absolute;
    content: "";
    width: 24.23px;
    height: 4px;
    background-color: #3488ff;
    border-radius: 13px;
    bottom: 0;
    left: 0;
  }
}

.forget {
  color: #a5adba;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
}
</style>
