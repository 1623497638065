<template>
  <div>
    <div class="select-div" @click="showSelect()">
        <div class="input-div" :style="'background-color:' + value"></div>
            <i class="el-icon-arrow-down"></i>
        </div>
        <div v-if="show" class="cont-div">
        <div class="content" v-for="item in colorList" :key="item.value">
            <div
                class="content-item"
                :style="'text-align:center;background-color:' + sub"
                @click="selectVal(sub)"
                v-for="(sub, index) in item.bg"
                :key="index"
            >
            <i
                v-if="myColor == sub"
                class="el-icon-check"
                style="color: #fff; line-height: 30px"
            ></i>
            </div>
        </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    value: String,
    //颜色数组
    colorList: {
      type: Array,
      default: () => {
        return [
          {
            value: "9",
            bg: [
                "#3870ea",
                "#409eff",
                "#654bff",
                "#20b77e",
                "#fec300",
                "#FD7B03",
                "#FE6481",
                "#F14135",
                "#BCBABA",
            ],
          },
        ];
      },
    },
  },
  data() {
    return {
      show: false,
      myColor: "",
    };
  },
  created() {
    this.myColor = this.value
  },

  methods: {
    showSelect() {
      this.show = !this.show;
    },
    selectVal(sub) {
      console.log(sub)
      this.myColor = sub;
      this.show = false
      this.$emit("input", this.myColor);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.select-div {
  cursor: pointer;
  height: 30px;
  border: 1px solid#DFE1E6;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;

  .input-div {
    width: 230px;
    display: flex;
    flex-direction: row;
  }
}
.cont-div {
  border: 1px solid #dfe1e6;
  padding: 4px;
  border-radius: 5px;
  width: 205px;
  margin-top: 6px;
  .content {
    border: 1px solid #fff;
    display: flex;
    flex-direction: row;
  }
}
.content-item {
  width: 22px;
  height: 22px;
  display: block;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.input-div {
  height: 22px;
  width: 230px;
}
.content-item {
  margin: 4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>
  
  
  