<template>
  <div v-if="node.flowRule == 'query_result'" class="condition-result"></div>
  <div
    v-else
    class="rpa-branch"
    :class="isOpen ? '' : 'branch-hide'"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <el-tooltip effect="dark" :content="toolContent" placement="bottom">
      <div class="rp-branch" @click="toggleOpen">
        <div
          v-if="!isOpen"
          class="flows-count"
          v-text="node.flows.length"
        ></div>
        <template v-else>
          <div v-if="node.flowRule == 'condition_all'" class="rule-all"></div>
          <i
            v-else
            class="el-icon-plus"
            style="color: #ffffff; font-size: 22px; font-weight: bolder"
          ></i>
        </template>
      </div>
    </el-tooltip>
    <template v-if="isOpen && isShow">
      <div @click="changeFlowRule" class="rpa-branch-round" style="left: 0px">
        <el-tooltip effect="dark" :content="flowRuleText" placement="top">
          <i class="el-icon-sort sort-rpa"></i>
        </el-tooltip>
      </div>
      <div @click="addBranch" class="rpa-branch-round" style="right: 0px">
        <el-tooltip effect="dark" content="添加分支" placement="top">
          <i class="el-icon-plus"></i>
        </el-tooltip>
      </div>
    </template>
  </div>
</template>
<script>
import { uuid } from "@zgg-core-utils/utils";
export default {
  props: {
    node: Object,
    isOpen: Boolean,
    disabled: Boolean,
  },
  computed: {
    flowRuleText() {
      if (this.node.flowRule == "condition_single") {
        return "转为包容分支：满足条件直接流转";
      } else {
        return "转位唯一分支：只执行第一个满足条件的分支下的节点序列，其他分支均不执行";
      }
    },
    toolContent() {
      let text = "包容分支";
      if (this.node.flowRule == "condition_single") {
        text = "唯一分支";
      }
      if (this.isOpen) {
        text += "（点击折叠）";
      } else {
        text += "（点击展开）";
      }
      return text;
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    validate() {
      return "";
    },
    toggleOpen() {
      this.$emit("update:isOpen", !this.isOpen);
    },
    changeFlowRule() {
      if (this.node.flowRule == "condition_all") {
        this.node.flowRule = "condition_single";
      } else {
        this.node.flowRule = "condition_all";
      }
    },
    addBranch() {
      this.isShow = false;
      let node = {
        nodes: [
          {
            key: uuid(),
            type: "condition",
            title: "分支",
            condition: { advanceQuery: [], rel: "and" },
          },
        ],
        lines: [],
      };
      this.node.flows.push(node);
    },
    mouseenter() {
      if (!this.disabled) {
        this.isShow = true;
      }
    },
    mouseleave() {
      if (!this.disabled) {
        this.isShow = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rpa-branch {
  z-index: 2;
  background-color: transparent;
  position: relative;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.rp-branch {
  width: 32px;
  height: 32px;
  background-color: #2196f3;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 4px rgba($color: #000000, $alpha: 0.16);
  .rule-all {
    width: 20px;
    height: 20px;
    border: solid 3px #ffffff;
    border-radius: 20px;
  }
}
.rpa-branch-round {
  position: absolute;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  top: 4px;
  .sort-rpa {
    transform: rotate(90deg);
  }
  i {
    color: #8d8d8d;
  }
}
.flows-count {
  color: #ffffff;
  transform: rotate(-45deg);
}
.condition-result {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &::after {
    content: " ";
    display: block;
    width: 1px;
    height: 15px;
    background-color: #ccc;
  }
}
</style>