<template>
  <div class="attr-row" style="margin: 24px 0">
    <div class="attr-label">选择范围</div>
    <div>
      <el-button
        style="width: 100%; margin-bottom: 5px"
        size="mini"
        @click="visible = true"
      >
        <span
          v-text="
            dataFilter?.advanceQuery?.length
              ? '已设置用户选择范围'
              : '设置用户选择范围'
          "
        ></span>
      </el-button>
    </div>

    <!-- 选择弹窗 -->
    <el-dialog
      title="数据过滤条件"
      :visible.sync="visible"
      width="840px"
      @open="open"
      append-to-body
      destroy-on-close
    >
      <div style="padding: 20px">
        <zgg-filter
          :componentList="fieldColumns"
          :dataFilter="copyDataFilter"
          :addFirst="true"
        >
          <template slot-scope="{ field }">
            <ui-query-filter-item
              :fieldList="fieldList"
              :filterField="field"
              :params="params"
              :valueType="valueType"
            ></ui-query-filter-item>
          </template>
        </zgg-filter>
      </div>
      <div slot="footer">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveFilters" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import uiQueryFilterItem from "./ui-query-filter-item";
import { advanceFields } from "@zgg-core-utils/whiteList";
import { getAdvanceQuerysByDataFilter } from "@zgg-core-utils/utils";

export default {
  name: "ui-front-query-filter",
  components: { uiQueryFilterItem },
  props: {
    fieldList: Array,
    dataFilter: Object,
    /** 嵌套层级 */
    level: {
      type: Number,
      default() {
        return 1;
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    valueType: {
      type: String,
      default() {
        return "form";
      },
    },
  },
  computed: {
    isMaxGroup() {
      return this.level >= 3;
    },
    fieldColumns() {
      let componentList = this._.cloneDeep(this.fieldList);

      let list = [];

      let callback = (components, parent) => {
        components.forEach((item) => {
          if (item.container) {
            callback(item.components, item);
          } else if (item.componentName == "reference_data") {
            let obj = this._.cloneDeep(item);
            list.push(obj);
            if (!parent) {
              item.columns.forEach((col) => {
                let obj = this._.cloneDeep(col.field.component);
                // 关联数据的子表单字段不进入过滤条件
                if (
                  obj.componentName != "json_form" &&
                  advanceFields.includes(obj.componentName)
                ) {
                  obj.title = item.title + "." + col.title;
                  obj.collection = item.tableName;
                  obj.referenceName = item.name;
                  obj.parent = this._.cloneDeep(item);
                  list.push(obj);
                }
              });
            }
          } else if (item.form) {
            if (advanceFields.includes(item.componentName)) {
              let obj = this._.cloneDeep(item);
              if (parent && parent.componentName == "json_form") {
                obj.name = parent.name + "." + obj.name;
                obj.title = parent.title + "." + obj.title;
                obj.parent = this._.cloneDeep(parent);
              }
              list.push(obj);
            }
          }
        });
      };
      callback(componentList);

      return list;
    },
  },
  data() {
    return {
      visible: false,
      copyDataFilter: {},
    };
  },
  methods: {
    open() {
      this.copyDataFilter = this._.cloneDeep(this.dataFilter);
    },
    saveFilters() {
      this.visible = false;
      this.$emit("updateDataFilter", this.copyDataFilter);
    },
    getComponent(obj) {
      let item = this.fieldColumns.find((field) => {
        // item.name == key2
        if (obj.referenceName) {
          if (field.parent) {
            if (field.parent.componentName == "reference_data") {
              return (
                field.parent.name == obj.referenceName && field.name == obj.key
              );
            } else if (field.parent.componentName == "form_data") {
              return (
                field.parent.key == obj.referenceName && field.name == obj.key
              );
            }
          }
          return false;
        } else {
          return field.name == obj.key;
        }
      });

      if (item) {
        return item;
      }
      return null;
    },
    isDelete(field) {
      if (this._.isEmpty(field)) {
        return false;
      }
      let item = this.getComponent(field);
      if (item) {
        return false;
      }
      return true;
    },
    hasDelete() {
      let advanceQuery = getAdvanceQuerysByDataFilter(this.dataFilter);
      return advanceQuery.some((item) => this.isDelete(item));
    },
    hasEmpty() {
      let advanceQuery = getAdvanceQuerysByDataFilter(this.dataFilter);
      let item = advanceQuery.find((item) => this._.isEmpty(item.value));
      if (item && item.oper != "nil" && item.oper != "nnil") {
        if (
          ![
            "user_list_select",
            "user_select",
            "department_list_select",
            "department_select",
          ].includes(item.componentName)
        ) {
          return item.title + "的值不能为空";
        } else if (
          !item.valueContainsCurrentDept &&
          !item.valueContainsCurrentDeptAndSub &&
          !item.valueContainsCurrentUser
        ) {
          return item.title + "的值不能为空";
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.ui-filter-group {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 10px 8px;
  margin-left: 10px;
  flex: 1;
}
</style>
