<template>
  <div>
    <div
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      @mousedown.stop=""
      class="sub-reference"
      v-if="data.componentName == 'reference_data'"
    >
      <div class="sub-remove" v-show="actionVisible">
        <!-- <i
          @click="copyItem"
          @mousedown.stop=""
          class="el-icon-copy-document"
          title="复制1"
          style="margin-right: 5px"
        ></i>
        <i
          @click="removeItem"
          @mousedown.stop=""
          title="删除"
          class="el-icon-delete"
        ></i> -->
        <i
          @click="copyItem"
          @mousedown.stop=""
          class="iconfont icon-file-copy-2-fill copy-fill"
          title="复制"
          style="margin-right: 5px;cursor: pointer;color:#A5ADBA;"
        ></i>
        <i
          @click="removeItem"
          @mousedown.stop=""
          title="删除"
          class="iconfont icon-shanchu_fill shanchu_fill"
          style="cursor: pointer;color:#A5ADBA;"
        ></i>
      </div>

      <div>
        <div class="sub-th">
          <div v-if="data.required" style="color: #f56c6c">*</div>
          <div class="sub-text">
            <div
              v-text="data.columns && data.columns.length ? '' : label"
              :style="
                data.columns && data.columns.length
                  ? 'width: 50px'
                  : 'width:150px;'
              "
            ></div>
          </div>
        </div>
        <div class="sub-td">
          <i class="el-icon-coin"></i>
        </div>
      </div>
      <template v-if="data.columns">
        <div
          style="min-width: 160px"
          v-for="child in data.columns"
          :key="child.field.name"
        >
          <div class="sub-th">
            <div class="sub-text" v-text="child.title"></div>
          </div>
          <sub-input class="sub-td" :data="child.field"></sub-input>
        </div>
      </template>
    </div>
    <div
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      @mousedown.stop=""
      style="min-width: 50px"
      :style="
        data.extParam && data.extParam.customWidth
          ? 'width:' + data.extParam.customWidth + 'px'
          : ''
      "
      v-else
    >
      <!-- <el-form-item :label="label" :required="!!data.required"> -->
      <div class="sub-th">
        <div v-if="data.required" style="color: #f56c6c">*</div>
        <div class="sub-text" v-text="label"></div>
        <!-- <i
              @click="removeItem"
              @mousedown.stop=""
              class="el-icon-remove sub-remove"
            ></i> -->
        <div class="sub-remove" v-show="actionVisible">
          <!-- <i
            @click="copyItem"
            @mousedown.stop=""
            class="el-icon-copy-document"
            title="复制2"
            style="margin-right: 5px"
          ></i> -->
          <i
            @click="copyItem"
            @mousedown.stop=""
            class="iconfont icon-file-copy-2-fill copy-fill"
            title="复制"
            style="margin-right: 5px;cursor: pointer;color:#A5ADBA;"
          ></i>
          <!-- <i
            @click="removeItem"
            @mousedown.stop=""
            title="删除"
            class="el-icon-delete"
          ></i> -->
          <i
            @click="removeItem"
            @mousedown.stop=""
            title="删除"
            class="iconfont icon-shanchu_fill shanchu_fill"
            style="cursor: pointer;color:#A5ADBA;"
          ></i>
        </div>
      </div>
      <sub-input class="sub-td" :data="data"></sub-input>
    </div>

    <!--  -->
    <!-- </el-form-item> -->
  </div>
</template>
<script>
import SubInput from "./SubInput";

export default {
  name: "subComponent",
  components: { SubInput },
  props: {
    index: Number,
    data: Object,
    dataMode: {
      type: String,
      default() {
        return "sub_form"; // 默认子表单关联独立表模式
      },
    },
  },
  computed: {
    label() {
      if (this.data.title) {
        return this.data.title;
      }
      return this.data.label;
    },
  },

  data() {
    return {
      actionVisible: false,
    };
  },
  methods: {
    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();
      if(this.dataMode != 'reference_form'){
        this.actionVisible = true;
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.actionVisible = false;
    },
    clickItem(event) {
      this.$emit("clickItem", this.index, event);
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-th {
  height: 40px;
  line-height: 30px;
  text-align: left;
  padding: 5px;
  box-sizing: border-box;
  border-bottom: solid 1px #f4f5f7;
  position: relative;
  display: flex;
  align-items: center;

  .sub-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.sub-td {
  min-height: 60px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.sub-remove {
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 16px;
  height: 18px;
  z-index: 2;
  padding: 2px 4px;
  cursor: default;
}
.sub-reference {
  position: relative;
  display: flex;
}
.copy-fill:hover {
  color: #3870ea !important;
}
.shanchu_fill:hover {
  color: #d14343 !important;
}
</style>
