<template>
  <div>
    <el-form ref="form" :model="node" :disabled="disabled" label-position="top">
      <el-form-item>
        <el-radio-group v-model="node.dataSource" @change="changeDataSource">
          <el-radio label="form">工作表</el-radio>
          <el-radio label="node_data_field">从子表获取</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="node.dataSource == 'form'"
        label="选择工作表"
        :rules="[{ required: true, message: '请选择工作表' }]"
        prop="collection"
      >
        <el-input
          v-model="node.collectionTitle"
          @focus="visible = true"
          readonly
        ></el-input>
      </el-form-item>
      <template v-else-if="node.dataSource == 'node_data_field'">
        <el-form-item
          label="当前流程节点"
          key="node_data_field"
          :rules="[{ required: true, message: '请选择节点' }]"
          prop="nodeKey"
        >
          <el-select
            :value="nodeKeyValue"
            placeholder="请选择节点"
            @change="changeNode"
          >
            <el-option
              v-for="item in nodeList"
              :key="item.key"
              :label="item.label"
              :value="getValue(item)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择子表字段"
          key="data_field"
          :rules="[{ required: true, message: '请选择字段' }]"
          prop="nodeField"
        >
          <el-select
            :value="nodeFieldValue"
            placeholder="请选择字段"
            @change="changeNodeField"
          >
            <el-option
              v-for="item in nodeFieldList"
              :key="item.name"
              :label="item.title"
              :value="
                item.collection ? item.collection + '_' + item.name : item.name
              "
            ></el-option>
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="限制数量">
        <div style="line-height: 1.5; font-size: 13px; color: #ccc">
          最少1条记录，最多1000条
        </div>
        <el-input-number
          v-model="node.limitSize"
          :min="1"
          :max="1000"
          :precision="0"
          size="small"
          @change="changeNumber"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="筛选条件">
        <rpa-action-filter
          :dataFilter="node.dataFilter"
          :componentList="componentList"
          :formId="node.collection"
          :isAddFilter="node.collection && !disabled"
          :disabled="disabled"
          :node-type="node.type"
        ></rpa-action-filter>
      </el-form-item>
      <el-form-item label="排序规则" prop="sortParam">
        <rpa-query-sort
          :node="node"
          :componentList="componentList"
        ></rpa-query-sort>
      </el-form-item>
      <el-form-item label="未获取到数据时">
        <el-radio-group v-model="node.fetchNodeDataFailWay">
          <el-radio label="keep">继续执行</el-radio>
          <el-radio label="stop">中止流程</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="获取方式">
        <el-radio-group v-model="node.fetchNodeDataWay">
          <el-radio label="nodeTimeData">流入本节点当时产生的节点数据</el-radio>
          <el-radio label="realTimeData"
            >根据节点配置重新获取最新的数据</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
    <data-source
      :visible.sync="visible"
      :menus="['form']"
      @changeSource="changeForm"
    ></data-source>
  </div>
</template>
<script>
import dataSource from "@/components/data-source";
import { isEmpty } from "@zgg-core-utils/utils";
import rpaActionFilter from "./rpa-action-filter";
import { isEmptyRpaActionFilter, rpaQueryDataMixins } from "./rpa-utils";
import rpaQuerySort from "./rpa-query-sort";

export default {
  name: "rpa-query-data-list",
  components: { rpaActionFilter, dataSource, rpaQuerySort },
  mixins: [rpaQueryDataMixins],
  props: {
    node: Object,
    disabled: Boolean,
  },
  data() {
    return {
      visible: false,
      componentList: [],
    };
  },

  methods: {
    getValue(item) {
      let formId = item.formId || item.collection;
      if (!isEmpty(formId)) {
        return `${item.key}_${formId}`;
      }
      return item.key;
    },
    changeNumber(val) {
      if (isEmpty(val)) {
        this.node.limit = 100;
      }
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (isEmptyRpaActionFilter(this.node.dataFilter)) {
          this.$message.error("请设置过滤条件的字段值");
          return;
        }

        callback(valid);
      });
    },
    changeForm({ tableName, tableTitle }) {
      let callback = () => {
        this.node.collection = tableName;
        this.node.collectionTitle = tableTitle;
        this.$set(this.node, "dataFilter", {
          rel: "and",
          advanceQuery: [],
        });

        this.fetchMetaComponents(tableName);
      };
      if (this.node.formId != tableName && !isEmpty(this.node.formId)) {
        this.$confirm("切换表单将会清除已设置的配置", "提示")
          .then(callback)
          .catch(() => {});
      } else {
        callback();
      }
    },
  },
};
</script>
