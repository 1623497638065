import moment from "moment";
import { isEmpty, average } from "@zgg-core-utils/relyUtil";
import { convertToCH } from "@/utils/convertToCH";
import { getMomentFormat, mapFlowStatus } from "@zgg-core-utils/utils";
import _ from "lodash";

export function dealWithChartValue(form, component, extraParams = {}) {
  let value;
  if (form) {
    value = convertToCH(form[component.name]);
  }
  //为空值，返回空字符串
  if (isEmpty(value)) {
    return "";
  }
  // 处理流程状态, 如果value是流程状态则处理成中文, 不是则原样返回
  value = mapFlowStatus(value);

  //透视图存在二次添加汇总字段，直接返回字符串
  if (value == "汇总") {
    return value;
  }
  if (component) {
    if (["address_input", "position_input"].includes(component.componentName)) {
      let str = "";
      if (typeof value === "string") {
        return value;
      }
      if (value instanceof Object) {
        if (component.componentName == "position_input") {
          str = `${value.province}${value.city}${value.county}${value.address}`;
        } else {
          let picker = component.picker;
          if (picker == "province") {
            str = `${value.province}`;
          } else if (picker == "city") {
            str = `${value.province}${value.city}`;
          } else if (picker == "county") {
            str = `${value.province}${value.city}${value.county}`;
          } else if (picker == "address") {
            str = `${value.province}${value.city}${value.county}${value.address}`;
          }
        }
      }
      return str;
    } else if (component.array) {
      if (value instanceof Array) {
        return value.join(",");
      }
    }
  }

  return value;
}

/**
 * @typedef numberFormat 指标字段数字格式化配置对象
 * @property {string} numberFormatType 数值格式 number：数值，percentage:百分比
 * @property {boolean} thousandsSeparator 是否显示千分符
 * @property {number} decimal 小数位数
 * @property {string} numberMagnitude 数量级
 * @property {string} unit 单位
 */

/**
 * 数值根据数量级转换
 * @param {number} num 数值
 * @param {string} numberMagnitude 数量级
 */
export function getRealNum(num, numberMagnitude) {
  if (numberMagnitude) {
    let numberMap = {
      thousand: 1000,
      tenThousand: 10000,
      million: 1000000,
      hundredMillion: 100000000,
      billion: 1000000000,
    };
    let b = numberMap[numberMagnitude];
    if (b) {
      return num / b;
    }
  }
  return num;
}

export function dealWithComponentValue(form, component, extraParams = {}) {
  let value;
  if (form) {
    value = convertToCH(form[component.name]);
  }
  //为空值，返回空字符串
  if (isEmpty(value)) {
    return "";
  }
  // 处理流程状态, 如果value是流程状态则处理成中文, 不是则原样返回
  value = mapFlowStatus(value);

  //透视图存在二次添加汇总字段，直接返回字符串
  if (value == "汇总") {
    return value;
  }
  if (component) {
    if (component.componentName == "date_picker") {
      if (value) {
        if (extraParams.datePrecision) {
          // 当维度为日期且存在汇总方式时，改变时间显示精度
          let format;
          //由于服务端day日期前台导出format参数只支持小写dd，故format数据保存为dd，渲染时转换为DD
          if (extraParams.datePrecision.picker == "date") {
            let newDatePrecision = _.cloneDeep(extraParams.datePrecision);
            if (newDatePrecision.format) {
              newDatePrecision.format =
                extraParams.datePrecision.format.replaceAll("d", "D");
            }
            format = getMomentFormat(newDatePrecision);
          } else {
            format = getMomentFormat(extraParams.datePrecision);
          }
          format = format.replaceAll("d", "D");
          value = moment(value).format(format);
        } else {
          value = moment(value).format(getMomentFormat(component));
        }
      }

      if (value == "Invalid date") {
        value = form[component.name];
      }
    } else if (
      component.componentName == "address_input" ||
      component.componentName == "position_input"
    ) {
      let str = "";
      if (typeof value === "string") {
        return value;
      }
      if (value instanceof Object) {
        if (component.componentName == "position_input") {
          str = `${value.province}${value.city}${value.county}${value.address}`;
        } else {
          let picker = component.picker;
          if (picker == "province") {
            str = `${value.province}`;
          } else if (picker == "city") {
            str = `${value.province}${value.city}`;
          } else if (picker == "county") {
            str = `${value.province}${value.city}${value.county}`;
          } else if (picker == "address") {
            str = `${value.province}${value.city}${value.county}${value.address}`;
          }
        }
      }
      return str;
    } else if (component.componentName == "user_select") {
      // console.log("===========user_select==========");
      // console.log(component.title);
      let obj = form[`${component.name}UserInfo`];
      //console.log(obj);
      if (obj) {
        return obj.name;
      }
    } else if (component.componentName == "user_list_select") {
      let obj = form[`${component.name}UserMap`];
      if (obj) {
        let list = Object.values(obj);
        if (list.length) {
          return list.map((item) => item.name).join(",");
        }
        return "";
      } else {
        let obj2 = form[`${component.name}UserInfo`];
        if (obj2) {
          return obj2.name;
        }
        return "";
      }
    } else if (component.componentName == "department_select") {
      let obj = form[`${component.name}DepartmentInfo`];
      if (obj) {
        return obj.name;
      }
    } else if (component.componentName == "department_list_select") {
      let list = form[`${component.name}DepartmentInfoList`];
      if (list) {
        let v = list.map((item) => item.name).join(",");
        return v;
      } else {
        let obj2 = form[`${component.name}DepartmentInfo`];
        if (obj2) {
          return obj2.name;
        }
        return "";
      }
    } else if (component.array) {
      if (value instanceof Array) {
        return value.join(",");
      }
    }
  }

  return value;
}
export function axisNameConfig(yAxis) {
  let nameLocation = "end";
  let nameGap = 4;
  if (yAxis.hasOwnProperty("titleSpacing")) {
    nameGap = yAxis.titleSpacing;
  }
  if (yAxis?.titlePosition == "broadside") {
    nameLocation = "middle";
  }
  return {
    nameGap,
    nameLocation,
  };
}

export function getGrid(view) {
  let grid = {
    left: "3%",
    right: "4%",
    bottom: "8%",
    top: "8%",
    containLabel: true,
  };
  if (view.padding) {
    let val = _.cloneDeep(view.padding);
    if (parseFloat(val.top)) {
      grid.top = val.top;
      grid.bottom = val.bottom;
    }
    if (parseFloat(val.left)) {
      grid.left = val.left;
      grid.right = val.right;
    }
  }
  return grid;
}

export const chartFontSize = 12;
export const chartLineHeight = 16;
export const chartItemWidth = 12;
export const chartOffsetArr = [0, 8, 0, 8, 0, 8, 0, 8, 0, 8];
export const chartColors = [
  "#6BDAAE",
  "#678AF7",
  "#74DDE5",
  "#8F59EF",
  "#FD8ADC",
  "#FAC86B",
  "#70B0FF",
  "#FF9D66",
  "#FF7875",
];
export const chartColorList = [
  {
    value: "scheme_1",
    name: "默认主题",
    bg: [
      "#19A8E2",
      "#2FD7DB",
      "#FCB198",
      "#8C97DB",
      "#DE88BC",
      "#D7B8E0",
      "#78CC97",
      "#9ACBEB",
      "#75A5F0",
    ],
  },
  {
    value: "scheme_2",
    name: "活力复古",
    bg: [
      "#4379CE",
      "#99B8F1",
      "#FC8602",
      "#FADB37",
      "#FFA748",
      "#72B5EB",
      "#78CDED",
      "#AC9AE2",
      "#FD7F7F",
    ],
  },
  {
    value: "scheme_3",
    name: "摩登大楼",
    bg: [
      "#06AED5",
      "#086788",
      "#428068",
      "#7C9848",
      "#F0C808",
      "#F8DD6C",
      "#FFF1D0",
      "#F7BCA3",
      "#EE8775",
    ],
  },
  {
    value: "scheme_4",
    name: "午夜海滩",
    bg: [
      "#084B83",
      "#007EA7",
      "#00A5CF",
      "#42BFDD",
      "#9AD1D4",
      "#89A1EF",
      "#EF9CDA",
      "#F4D2E6",
      "#D9DCD6",
    ],
  },
  {
    value: "scheme_5",
    name: "拿铁咖啡",
    bg: [
      "#BC6C25",
      "#DDA15E",
      "#F4D58D",
      "#84A59D",
      "#F3D5B5",
      "#C8CC92",
      "#B6AD90",
      "##9A8C98",
      "#5E503F",
    ],
  },
  {
    value: "scheme_6",
    name: "夏日玫瑰",
    bg: [
      "#E54B4B",
      "#FFA987",
      "#9FD989",
      "#83C5BE",
      "#006D77",
      "#E29578",
      "#02C39A",
      "#F2B5D4",
      "#CFDBD5",
    ],
  },
  {
    value: "scheme_7",
    name: "林荫大道",
    bg: [
      "#028D6D",
      "#95CE76",
      "#FFCC40",
      "#00A6BB",
      "#93E0D9",
      "#EA7795",
      "#66A08F",
      "#276E5C",
      "#BDD6D4",
    ],
  },
  {
    value: "scheme_8",
    name: "紫色花瓣",
    bg: [
      "#D958A6",
      "#D799E9",
      "#8276E9",
      "#A19AEB",
      "#75A5F0",
      "#87CDA0",
      "#F1D43B",
      "#19A8E2",
      "#F2845F",
    ],
  },
  {
    value: "scheme_9",
    name: "丰收",
    bg: [
      "#FF7F50",
      "#FFA500",
      "#DB8780",
      "#FFE5B4",
      "#1F7A8C",
      "#A3CEF1",
      "#8B8C89",
      "#8B8C89",
      "#6096BA",
    ],
  },
  {
    value: "scheme_10",
    name: "北极光",
    bg: [
      "#3D5A80",
      "#98C1D9",
      "#EE6C4D",
      "#EA9AB2",
      "#BFC0C0",
      "#EAF2D7",
      "#D8E2DC",
      "#FFCAD4",
      "#B3DEE2",
    ],
  },
  {
    value: "scheme_11",
    name: "冰川白",
    bg: [
      "#F2ECDE",
      "#F3A068",
      "#FAD161",
      "#469374",
      "#B0C76F",
      "#535865",
      "#E68653",
      "#E7D99D",
      "#BECC8D",
    ],
  },
  {
    value: "scheme_a",
    name: "面朝大海",
    bg: [
      "#6BDAAE",
      "#678AF7",
      "#74DDE5",
      "#8F59EF",
      "#FD8ADC",
      "#FAC86B",
      "#70B0FF",
      "#FF9D66",
      "#FF7875",
    ],
  },

  {
    value: "scheme_b",
    name: "湖上日出",
    bg: [
      "#7B67EE",
      "#5B8FF9",
      "#CDDDFD",
      "#1AAF8B",
      "#61DDAA",
      "#CDF3E4",
      "#F6BD16",
      "#CED4DE",
      "#78D3F8",
    ],
  },

  {
    value: "scheme_d",
    name: "未来蓝",
    bg: [
      "#035885",
      "#076699",
      "#0077B6",
      "#1888C3",
      "#00B4D8",
      "#48CAE4",
      "#90E0EF",
      "#ADE8F4",
      "#CAF0F8",
    ],
  },
  {
    value: "scheme_g",
    name: "雾中风景",
    bg: [
      "#D9ED92",
      "#B5E48C",
      "#99D98C",
      "#76C893",
      "#52B69A",
      "#34A0A4",
      "#168AAD",
      "#1A759F",
      "#1E6091",
    ],
  },
  {
    value: "scheme_c",
    name: "复古记忆",
    bg: [
      "#3870EA",
      "#26DDFF",
      "#F75165",
      "#FF8041",
      "#6648FE",
      "#1DAD92",
      "#9DC6C8",
      "#F3DD65",
      "#FFA8A8",
    ],
  },
  {
    value: "scheme_e",
    name: "大洋之光",
    bg: [
      "#08F8E9",
      "#1774EA",
      "#E0B783",
      "#37A4B5",
      "#85C5DC",
      "#34B084",
      "#A39FB0",
      "#F47EC2",
      "#7A8FDB",
    ],
  },
  {
    value: "scheme_f",
    name: "盛开花田",
    bg: [
      "#F94144",
      "#F3722C",
      "#F9844A",
      "#F8961E",
      "#F9C74F",
      "#90BE6D",
      "#43AA8B",
      "#4D908E",
      "#577590",
    ],
  },
];
// 字体大小
export const FONTSIZE_NUMBER = [
  {
    label: "12px",
    value: "12",
  },
  {
    label: "14px",
    value: "14",
  },
  {
    label: "16px",
    value: "16",
  },
  {
    label: "18px",
    value: "18",
  },
  {
    label: "20px",
    value: "20",
  },
  {
    label: "22px",
    value: "22",
  },
  {
    label: "24px",
    value: "24",
  },
  {
    label: "28px",
    value: "28",
  },
  {
    label: "30px",
    value: "30",
  },
  {
    label: "32px",
    value: "32",
  },
  {
    label: "36px",
    value: "36",
  },
  {
    label: "48px",
    value: "48",
  },
  {
    label: "52px",
    value: "52",
  },
  {
    label: "56px",
    value: "56",
  },
  {
    label: "64px",
    value: "64",
  },
  {
    label: "128px",
    value: "128",
  },
];

//辅助线的颜色
export const guidelinesColorList = [
  "#3870EA",
  "#7B67EE",
  "#1DAD92",
  "#90BE6D",
  "#F47EC2",
  "#F8961E",
  "#F3722C",
  "#EB5757",
  "#1888C3",
  "#577590",
];
//设置辅助线
export function setGuidelines(view, option, axis, list) {
  if (view.guidelines) {
    let guidelines = view.guidelines;
    let maxFixedLineValue = 0;
    guidelines.map((guideline) => {
      let formatter = `${guideline.label}:{c}`;
      if (guideline.displayOption == "label") {
        formatter = `${guideline.label}`;
      } else if (guideline.displayOption == "value") {
        formatter = `{c}`;
      }
      let markLine = {
        symbol: "none", //去掉箭头
        silent: true,
        emphasis: {
          disabled: true, //hover不显示高亮
        },
        label: {
          position: axis == "yAxis" ? "insideMiddleTop" : "insideMiddleBottom",
          formatter,
          color: "#172B4D",
        },
        lineStyle: {
          color: guideline.color,
        },
      };
      let obj = {};
      if (guideline.type == "dynamic") {
        let value = 0;
        if (guideline.groupOper == "min") {
          value = Math.min(
            ...list.map((item) => item[guideline.metricFieldName]),
          );
        } else if (guideline.groupOper == "max") {
          value = Math.max(
            ...list.map((item) => item[guideline.metricFieldName]),
          );
        } else {
          value = average(list.map((item) => item[guideline.metricFieldName]));
        }
        //原指标字段被删除或数据错误，不显示辅助线
        if (typeof value == "undefined") return;
        obj.name = "动态值";
        obj[axis] = value;
      } else if (guideline.type == "fixed") {
        obj.name = "固定值";
        obj[axis] = guideline.value;
        if (guideline.value > maxFixedLineValue) {
          maxFixedLineValue = guideline.value;
        }
      }
      //获取最大坐标值
      let maxValue = 0;
      if (view.metrics) {
        view.metrics.map((item) => {
          list.map((row) => {
            if (row[item.name] && row[item.name] > maxValue) {
              maxValue = row[item.name];
            }
          });
        });
      }
      if (maxValue < maxFixedLineValue) {
        option.series.push({
          name: "virtualData",
          type: "line",
          symbol: "none",
          data: [maxFixedLineValue],
        });
      }
      markLine.data = [obj];
      option.series.push({
        name: guideline.label,
        type: "line",
        markLine,
      });
    });
  }
}

//当存在多个维度时，对数据进行处理
export function dealWithXFieldValue(xFields, list, option, axis) {
  xFields.forEach((xField) => {
    let xfieldComponent = _.cloneDeep(xField.field.component);
    // 当维度为日期且存在汇总方式时
    let datePrecision = xField.datePrecision;
    if (xfieldComponent.componentName == "date_picker") {
      if (datePrecision?.format) {
        datePrecision.format = datePrecision.format.replaceAll("d", "D");
      }
    }

    if (
      xfieldComponent.componentName === "address_input" &&
      xfieldComponent.picker === "address"
    ) {
      xfieldComponent.picker = "county";
    }
    list.forEach((row) => {
      let value = dealWithChartValue(row, xfieldComponent, {
        datePrecision,
      });
      row[xField.field.name] = value;
      //第一个维度展示在x轴
      if (
        option[axis].data.findIndex((v) => v === row[xFields[0].field.name]) ==
        -1
      ) {
        option[axis].data.push(value);
      }
    });
  });
}

/**
 * 返回背景颜色和标题颜色设置
 * @param view
 * @returns {}
 */
export const getColorConfigStyle = (view) => {
  let style = {};

  if (!view.colorConfig) {
    view.colorConfig = {
      backgroundEnum: "color", //背景样式类型:图片填充image、颜色填充color
      backgroundColor: "",
      backgroundImage: "",
      backgroundImageFillEnum: "stretch_fill", //背景图片填充类型:居中填满center_fill、拉伸填充stretch_fill
      titleColor: "", //标题颜色
      contentColor: "", //内容颜色
    };
  } else {
    if (!view?.colorConfig?.backgroundEnum) {
      view.colorConfig.backgroundEnum = "color"; //背景样式类型:图片填充image、颜色填充color
      view.colorConfig.backgroundImageFillEnum = "stretch_fill"; //背景图片填充类型:居中填满center_fill、拉伸填充stretch_fill
    }
    if (!view?.colorConfig?.backgroundColor) {
      view.colorConfig.backgroundColor = "";
    }
    if (!view?.colorConfig?.backgroundImage) {
      view.colorConfig.backgroundImage = "";
    }
    if (!view?.colorConfig?.titleColor) {
      view.colorConfig.titleColor = "";
    }
    if (!view?.colorConfig?.contentColor) {
      view.colorConfig.contentColor = "";
    }
  }
  if (view.componentName == "metric_table") {
    if (view?.backgroundColor && view?.colorConfig?.backgroundColor == "") {
      view.colorConfig.backgroundColor = view.backgroundColor;
    }
    if (view?.valueColor && view?.colorConfig?.contentColor == "") {
      view.colorConfig.contentColor = view.valueColor;
    }
  }
  var colorConfig = view?.colorConfig;
  if (colorConfig?.backgroundColor || colorConfig?.backgroundImage) {
    if (colorConfig?.backgroundEnum == "image") {
      style = `background-color:transparent;background-image: url(${
        colorConfig?.backgroundImage
      }); background-size:${
        colorConfig?.backgroundImageFillEnum == "center_fill"
          ? "cover"
          : "100% 100%"
      };background-position: center;background-repeat: no-repeat;`;
    } else {
      if (
        colorConfig?.backgroundColor &&
        colorConfig?.backgroundColor.includes(",") &&
        !colorConfig?.backgroundColor.includes("rgba")
      ) {
        style = `background:linear-gradient(${colorConfig?.backgroundColor})`;
      } else {
        style = `background-color:${colorConfig?.backgroundColor}`;
      }
    }
  } else {
    style = {};
  }
  return style;
};
/**
 * 返回图表文字/配色方案颜色设置
 * @param view
 * @returns {}
 */
export const getChartColorConfig = (view, isH5) => {
  let textStyle = {};
  let remindColor = "";
  if (view.colorConfig) {
    //获取自定义配色方案
    let myColorList = [];
    if (!view.colorConfig.colorScheme) {
      view.colorConfig.colorScheme = "scheme_1";
    }
    myColorList = chartColorList.find(
      (item) => item.value == view.colorConfig.colorScheme,
    );
    remindColor = myColorList?.bg;
    if (view?.colorConfig?.contentColor) {
      let color = view?.colorConfig?.contentColor;
      if (!view?.colorConfig?.contentColor.includes("rgba")) {
        color = textColorArr[view?.colorConfig?.contentColor];
      }
      textStyle = {
        color: color, // 文字颜色设置
      };
    }
  }
  if (isH5) {
    textStyle = { color: "#333" };
  }
  const result = { textStyle: textStyle, remindColor: remindColor };

  return result;
};
/**
 * 返回表格表头，线框等颜色设置
 * @param view
 * @returns {}
 */
export const getTableColorConfig = (view, isH5) => {
  let jsBackgroundColor = "#ffffff";
  let jsBgColor = "#ffffff";
  let jsBorderColor = "#f4f5f7";
  let jsRightBorderColor = "#f4f5f7";
  let jsHeadBgColor = "#f4f5f7";
  let jsContentColor = "#42526e";
  let jsHeadColor = "rgba(9, 30, 66, 0.95)";
  let jsActiveColor = "#3870EA";
  let jsStripeColor = "#ffffff";
  if (isH5) {
    jsBackgroundColor = "#ffffff";
    jsBorderColor = "#f4f5f7";
    jsContentColor = "#42526e";
    jsHeadColor = "rgba(9, 30, 66, 0.95)";
  }
  if (!isH5) {
    let jsTableKey = view?.key;
    if (
      view?.colorConfig?.enableStripe &&
      view?.colorConfig?.contentBackgroundColor
    ) {
      jsBgColor = "#ffffff";
    } else if (
      view?.colorConfig?.borderColor &&
      !view?.colorConfig?.enableStripe
    ) {
      jsBgColor = "transparent";
    }
    if (
      view?.colorConfig?.borderColor &&
      !view?.colorConfig?.headBackgroundColor
    ) {
      view.colorConfig.headBackgroundColor = view?.colorConfig?.borderColor;
    }

    jsBorderColor = view?.colorConfig?.borderColor || "#f4f5f7";
    jsHeadBgColor = view?.colorConfig?.headBackgroundColor || "#f4f5f7";
    jsContentColor = view?.colorConfig?.contentColor || "#42526e";
    jsHeadColor = view?.colorConfig?.headColor || "rgba(9, 30, 66, 0.95)";
    jsActiveColor = view?.colorConfig?.borderColor || "#3870EA";

    jsBackgroundColor = view?.colorConfig?.contentBackgroundColor || jsBgColor;
    jsStripeColor = view?.colorConfig?.stripeColor; //分割线

    jsRightBorderColor = jsBorderColor;

    if (view?.colorConfig?.hiddenVerticaLine) {
      //隐藏竖线
      jsRightBorderColor = "transparent";
    }
    let uitable = document.querySelector("#uiTable_" + jsTableKey);
    if (uitable) {
      uitable.style.setProperty("--js-background-color", jsBackgroundColor);
      uitable.style.setProperty("--js-border-color", jsBorderColor);
      uitable.style.setProperty("--js-head-bg-color", jsHeadBgColor);
      uitable.style.setProperty("--js-stripe-color", jsStripeColor);
      uitable.style.setProperty("--js-content-color", jsContentColor);
      uitable.style.setProperty("--js-head-color", jsHeadColor);
      uitable.style.setProperty("--js-active-color", jsActiveColor);
      uitable.style.setProperty("--js-right-border-color", jsRightBorderColor);
    }
  }

  document.documentElement.style.setProperty(
    "--js-background-color",
    jsBackgroundColor,
  );

  document.documentElement.style.setProperty(
    "--js-border-color",
    jsBorderColor,
  );
  document.documentElement.style.setProperty(
    "--js-right-border-color",
    jsRightBorderColor,
  );
  document.documentElement.style.setProperty(
    "--js-head-bg-color",
    jsHeadBgColor,
  );
  document.documentElement.style.setProperty(
    "--js-stripe-color",
    jsStripeColor,
  );

  document.documentElement.style.setProperty(
    "--js-content-color",
    jsContentColor,
  );
  document.documentElement.style.setProperty(
    "--js-active-color",
    jsActiveColor,
  );
  document.documentElement.style.setProperty("--js-head-color", jsHeadColor);
};
export const textColorArr = {
  "var(--zgg-color-white)": "#FFF",
  "var(--neutral-5)": "#EDEFF5",
  "var(--chart-c-3)": "#F75165",
  "var(--chart-c-9)": "#FFA8A8",
  "var(--chart-c-8)": "#F3DD65",
  "var(--chart-b-9)": "#78D3F8",
  "var(--chart-f-4)": "#F8961E",
  "var(--chart-a-2)": "#678AF7",
  "var(--chart-e-5)": "#85C5DC",
  "var(--chart-c-6)": "#1DAD92",
  "var(--chart-b-1)": "#7B67EE",
  "var(--chart-c-1)": "#3870EA",
  "var(--teal-6)": "#10899E",
  "var(--chart-e-3)": "#E0B783",
  "var(--violet-6)": "#6E62B6",
  "var(--chart-f-9)": "#577590",
  "var(--chart-g-9)": "#1E6091",
  "var(--neutral-12)": "#101840",

  "var(--chart-a-1)": "#6BDAAE",
  "var(--chart-d-1)": "#035885",
  "var(--chart-e-1)": "#08F8E9",
  "var(--chart-f-1)": "#F94144",
  "var(--chart-g-1)": "#D9ED92",
  "var(--chart-a-8)": "#FF9D66",
  "var(--chart-a-9)": "#FF7875",
  "var(--zgg-color-black)": "#000",
  "var(--neutral-7)": "#D8DAE5",
};

export const progressColor = {
  "var(--zgg-color-white)": "#ffffff",
  "var(--red-2)": "#f9dada",
  "var(--yellow-2)": "#ffefd2",
  "var(--teal-2)": "#d3f5f7",
  "var(--blue-2)": "#dae2ff",
  "var(--green-3)": "#dcf2ea",
  "var(--green-4)": "#a3e6cd",
  "var(--blue-3)": "#b2c5ff",
  "var(--teal-3)": "#a8eaef",
  "var(--chart-g-1)": "#d9ed92",
  "var(--yellow-3)": "#ffdfa6",
  "var(--pink-2)": "#fbddf3",
  "var(--chart-c-1)": "#3870ea",
  "var(--chart-b-9)": "#78d3f8",
  "var(--chart-c-3)": "#f75165",
  "var(--chart-f-4)": "#f8961e",
  "var(--chart-b-1)": "#7b67ee",
  "var(--chart-c-6)": "#1dad92",
  "var(--chart-c-7)": "#9dc6c8",
  "var(--chart-c-8)": "#f3dd65",
  "var(--chart-c-9)": "#ffa8a8",
  "var(--teal-6)": "#10899e",
  "var(--chart-f-9)": "#577590",
  "var(--chart-g-9)": "#1e6091",
  "var(--neutral-3)": "#f4f6fa",
  "var(--neutral-5)": "#edeff5",
  "var(--neutral-7)": "#d8dae5",
  "var(--neutral-10)": "#696f8c",
  "var(--neutral-11)": "#474d66",
  "var(--neutral-12)": "#101840",
};
export const transitionProgressColor = {
  "96deg, #6A83FE -8.08%, #28CBFD 94.72%": ["#6A83FE", "#28CBFD"],
  "96deg, #877DFB -8.08%, #F783ED 94.72%": ["#877DFB", "#F783ED"],
  "96deg, #FF938B -8.08%, #FF6B88 94.72%": ["#FF938B", "#FF6B88"],
  "96deg, #0FBDD9 -8.08%, #28F498 94.72%": ["#0FBDD9", "#28F498"],
  "135deg, #FCCF31 0%, #F55555 100%": ["#FCCF31", "#F55555"],
  "264deg, #FFF500 -21.63%, #FF0FBC 104.73%": ["#FFF500", "#FF0FBC"],
  "135deg, #F0FF00 0%, #58CFFB 100%": ["#F0FF00", "#58CFFB"],
  "96deg, #FE9F6A -8.08%, #EFDD3A 94.72%": ["#FE9F6A", "#EFDD3A"],
  "225deg, #FFEAF6 -5.57%, #FF9DE4 105.55%": ["#FFEAF6", "#FF9DE4"],
  "221deg, #FFF6EB 11.23%, #DFD1C5 96.32%": ["#FFF6EB", "#DFD1C5"],
  "135deg, #DDE4FF 0%, #8DA2EE 100%": ["#DDE4FF", "#8DA2EE"],
  "214deg, #C5EDF5 13.58%, #4A879A 150.31%": ["#C5EDF5", "#4A879A"],
};

export function getProgressColor(color) {
  if (color) {
    if (color.includes(",") && !color.includes("rgba")) {
      return {
        type: "linear",
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: transitionProgressColor[color][0],
          },
          {
            offset: 1,
            color: transitionProgressColor[color][1],
          },
        ],
      };
    } else {
      return progressColor[color];
    }
  }
  return "#3870ea";
}
export function getLiquidOutlineColor(color) {
  if (color) {
    if (color.includes(",") && !color.includes("rgba")) {
      return transitionProgressColor[color][0];
    } else {
      return progressColor[color];
    }
  }
  return "#3870ea";
}
