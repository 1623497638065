<template>
  <div>
    <el-dialog
      :title="isFromJsonForm ? '子字段显隐规则' : '字段显隐规则'"
      custom-class="field-dialog"
      :visible.sync="visible"
      fullscreen
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="open"
    >
      <div v-if="!fieldDisplayRules.length" class="empty-container">
        <div>让字段在特定条件成立时显示，反之隐藏。</div>
        <div style="margin-top: 20px">
          <el-button type="primary" @click="visible2 = true" icon="el-icon-plus"
            >添加显隐规则</el-button
          >
        </div>
      </div>
      <div v-else class="rules-body">
        <div class="btn-line">
          <el-button type="primary" @click="visible2 = true" icon="el-icon-plus"
            >添加显隐规则</el-button
          >
        </div>
        <div>
          <div
            class="weui-rule"
            v-for="(item, index) in fieldDisplayRules"
            :key="index"
          >
            <div class="flex" style="margin-right: 20px">
              <div class="rule-row">当满足此条件时:</div>
              <div class="rule-row">
                <span>显示</span>
                <template v-for="item in item.displayFieldKeys">
                  <el-tooltip
                    v-if="isDelete(item)"
                    effect="dark"
                    content="已删除"
                    placement="top"
                    :key="item.key"
                    style="margin-left: 4px"
                  >
                    <span class="isDelete">[{{ getFieldTitle(item) }}]</span>
                  </el-tooltip>
                  <span v-else style="margin-left: 4px" :key="item.key"
                    >[{{ getFieldTitle(item) }}]</span
                  >
                </template>
              </div>
            </div>
            <div>
              <el-button @click="editRule(index)" type="text">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button @click="deleteRule(index)" type="text">删除</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="isFromJsonForm ? '子字段显隐规则' : '字段显隐规则'"
      :visible.sync="visible2"
      width="860px"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @closed="closeVisible2"
    >
      <div style="padding: 0 20px">
        <div style="max-height: 500px; overflow: auto">
          <ui-advance-filter
            style="margin-bottom: 10px"
            :dataFilter="dataFilter"
            :sourceComponentList="originComponents"
            :componentList="conditionList2"
          ></ui-advance-filter>
        </div>
        <div>
          <div style="margin-bottom: 10px">显示以下字段</div>
          <el-select
            :disabled="currFieldRules.conditions.length == 0"
            v-model="displayFieldKeys"
            :placeholder="
              currFieldRules.conditions.length == 0
                ? '请先添加条件'
                : '请选择显示字段'
            "
            size="medium"
            style="width: 100%"
            filterable
            multiple
          >
            <el-option
              v-for="item in fieldList"
              :key="item.key"
              :label="item.title"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="visible2 = false">取 消</el-button>
        <el-button type="primary" @click="saveRule">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import test from "@/utils/test";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import {
  displayQueryOpers,
  getComponentQueryOper,
} from "@zgg-core-utils/whiteList";
import { mapGetters } from "vuex";
const passArray = [
  "json_form",
  "divider",
  "form_data",
  "reference_data",
  "department_select",
  "department_list_select", //部门组件待后续处理
];
export default {
  directives: { Clickoutside },
  props: {
    originComponents: Array,
    areaTree: Array,
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    fieldDisplayRules: {
      type: Array,
      default() {
        return [];
      },
    },
    // 当前子表单显示的组件字段
    jsonFormComponents: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否来自子表单子字段设置
    isFromJsonForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters(["sysFields"]),
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
    fieldList() {
      let list = [];
      let conditions = this.currFieldRules.conditions;
      if (conditions.length) {
        if (this.isFromJsonForm) {
          list = this._.cloneDeep(this.jsonFormComponents);
        } else {
          list = this.orignFieldList.filter((item, index) => {
            return !this.fieldDisplayRules
              .filter((col, colIndex) => colIndex != this.editIndex)
              .some((col) =>
                col.displayFieldKeys.some((field) => field.key == item.key),
              );
          });
        }
      }
      return list;
    },
    displayFieldKeys: {
      get() {
        let list = [];
        if (this.currFieldRules.displayFieldKeys) {
          list = this.currFieldRules.displayFieldKeys.map((item) => item.key);
        }
        return list;
      },
      set(val) {
        let list = this.fieldList.filter((item) => val.includes(item.key));
        let arr = [];
        list.forEach((item) => {
          arr.push({
            key: item.key,
            title: item.title,
          });
        });
        this.$set(this.currFieldRules, "displayFieldKeys", arr);
      },
    },
    conditionList2() {
      if (this.isFromJsonForm) {
        return this.conditionList;
      } else {
        let list = this.sysFields.filter((item) =>
          [
            "currentFlowNodeTitles",
            "currentFlowWorkUserIds",
            "flowStatus",
          ].includes(item.name),
        );
        return list.concat(this.conditionList);
      }
    },
  },
  watch: {
    dataFilter: {
      deep: true,
      handler(val) {
        this.currFieldRules.conditions = [...val.advanceQuery];
        this.currFieldRules.conditionRel = val.rel;
      },
    },
  },
  data() {
    return {
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
      visible2: false,
      currFieldRules: {
        conditions: [],
        conditionRel: "and",
        displayFieldKeys: [],
      },
      conditionList: [],
      orignFieldList: [],
      areaList: [],
      editIndex: -1,
      dataFilter: {
        rel: "and",
        advanceQuery: [],
      },
    };
  },
  created() {},
  methods: {
    editRule(index) {
      this.editIndex = index;
      this.$set(
        this,
        "currFieldRules",
        this._.cloneDeep(this.fieldDisplayRules[index]),
      );
      this.dataFilter.rel = this.currFieldRules.conditionRel;
      this.dataFilter.advanceQuery = [...this.currFieldRules.conditions];
      this.visible2 = true;
    },
    deleteRule(index) {
      this.$delete(this.fieldDisplayRules, index);
      this.$emit("update:fieldDisplayRules", this.fieldDisplayRules);
    },
    closeVisible2() {
      this.editIndex = -1;
      this.currFieldRules = {
        conditions: [],
        conditionRel: "and",
        displayFieldKeys: [],
      };
      this.dataFilter = {
        rel: "and",
        advanceQuery: [],
      };
    },
    buildAreaList(picker) {
      let list = [];
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      this.$set(this, "areaList", list);
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
    getSourceList(val) {
      let list = this.sysFields.concat(this.conditionList);
      let item = list.find((item) => {
        if (val.referenceName) {
          //复制表单的key及name一样，故存在字段key相同的情况下，需要加入关联表单referenceName进一步判断。
          return item.key == val.key && item.referenceName == val.referenceName;
        } else {
          return item.key == val.key;
        }
      });
      if (item && item.customOptions) {
        return item.customOptions;
      } else {
        return [];
      }
    },
    closeTag(list, index) {
      this.$delete(list, index);
    },
    getComponentName(key) {
      let list = this.sysFields.concat(this.conditionList);
      let item = list.find((item) => item.key == key);

      if (item) {
        return item.componentName;
      }
      return "";
    },
    getFieldTitle(obj) {
      let title;
      let item;
      if (obj.referenceName) {
        let parent = this.orignFieldList.find(
          (item) => item.key == obj.referenceName,
        );
        if (parent) {
          title = parent.title;
          let row = parent.columns.find(
            (col) => col.field.component.key == obj.key,
          );
          if (row) {
            item = row.field.component;
          }
        }
      } else {
        item = this.orignFieldList.find((item) => item.key == obj.key);
      }
      if (item) {
        if (item.title) {
          return item.title;
        }
      }
      if (!isEmpty(obj.title)) {
        return obj.title;
      }

      return obj.key;
    },
    isDelete(obj) {
      // let key = obj.key;
      // let item = this.orignFieldList.find((item) => item.key == key);
      let item;
      if (obj.referenceName) {
        let parent = this.orignFieldList.find(
          (item) => item.key == obj.referenceName,
        );
        if (parent) {
          let row = parent.columns.find(
            (col) => col.field.component.key == obj.key,
          );
          if (row) {
            item = row.field.component;
          }
        }
      } else {
        item = this.orignFieldList.find((item) => item.key == obj.key);
        if (!item) {
          item = this.sysFields.find((node) => node.key == obj.key);
        }
      }
      if (item) {
        return false;
      }
      return true;
    },
    buildConditionList(componentList) {
      let list = [];
      componentList.forEach((item) => {
        if (item.form) {
          if (item.componentName == "reference_data") {
            item.columns
              .filter((col) => !col.delete)
              .forEach((col) => {
                if (
                  !passArray.includes(col.field.component.componentName) &&
                  col.field.component.optionSource != "metaField"
                ) {
                  list.push({
                    ...this._.cloneDeep(col.field.component),
                    referenceName: item.key,
                    collection: item.tableName,
                    title: item.title + "." + col.title,
                    parent: item,
                  });
                }
              });
          } else if (
            !passArray.includes(item.componentName) &&
            item.optionSource != "metaField"
          ) {
            list.push(this._.cloneDeep(item));
          }
        } else if (item.components) {
          list = list.concat(this.buildConditionList(item.components));
        }
      });
      return list;
    },
    buildOrignFieldList(componentList) {
      let list = [];
      componentList.forEach((item) => {
        if (item.components && item.componentName != "json_form") {
          if (["tab_pane", "grid"].includes(item.componentName)) {
            let fieldItem = {};
            for (const [key, value] of Object.entries(item)) {
              if (key != "components") {
                fieldItem[key] = value;
              }
            }
            list.push(fieldItem);
          }
          list = list.concat(this.buildOrignFieldList(item.components));
        } else {
          list.push(this._.cloneDeep(item));
        }
      });
      return list;
    },

    open() {
      let list = this.buildConditionList(this.originComponents);
      let orignFieldList = this.buildOrignFieldList(this.originComponents);

      this.$set(this, "conditionList", list);
      this.$set(this, "orignFieldList", orignFieldList);
    },
    validateRules(data) {
      if (data.length > 0) {
        let valid = [];
        data.forEach((item) => {
          if (item.queryChain) {
            if (item.queryChain.or) {
              valid.push(this.validateRules(item.queryChain.or));
            } else if (item.queryChain.and) {
              valid.push(this.validateRules(item.queryChain.and));
            }
          } else {
            valid.push(
              item.oper == "nil" ||
                item.oper == "nnil" ||
                !test.isEmpty(item.value),
            );
          }
        });
        return valid.every((val) => val == true);
      }
      return false;
    },
    saveRule() {
      if (
        !(
          this.currFieldRules.displayFieldKeys.length &&
          this.validateRules(this.currFieldRules.conditions)
        )
      ) {
        this.$message.warning("请设置完整的条件");
        return;
      }
      if (this.editIndex == -1) {
        this.fieldDisplayRules.push(this._.cloneDeep(this.currFieldRules));
      } else {
        this.$set(
          this.fieldDisplayRules,
          this.editIndex,
          this._.cloneDeep(this.currFieldRules),
        );
      }
      this.$emit("update:fieldDisplayRules", this.fieldDisplayRules);

      this.visible2 = false;
    },
  },
};
</script>
<style lang="scss">
.field-dialog {
  display: flex;
  flex-direction: column;
  .el-dialog__header {
    border-bottom: solid 1px #ececec;
  }
  .el-dialog__body {
    flex: 1;
    overflow: auto;
    background-color: #f4f5f7;
  }
}
.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.weui-rule {
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.rule-row {
  line-height: 1.5;
}
.flex {
  flex: 1;
  overflow: hidden;
}
.rules-body {
  width: 800px;
  margin: 20px auto;
  max-height: calc(100vh - 105px);
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  border: 1px solid #e7eaf1;
}
.btn-line {
  padding: 10px;
  border-bottom: solid 1px #dcdfe6;
}
.isDelete {
  color: red;
}
</style>
