<template>
  <div v-if="isShow" class="tabs">
    <div style="color: #ffffff; font-size: 12px">tabs</div>
    <template v-if="data.components && data.components.length">
      <el-tabs v-model="activeName" type="card">
        <template v-for="(item, index) in data.components">
          <el-tab-pane
            :key="index"
            :label="item.title"
            :name="index + ''"
            v-if="isShowComponent(item)"
          >
            <template v-for="(col, cIndex) in item.components">
              <form-tpl-item
                :isShowComponent="isShowComponent"
                :key="cIndex"
                :cols="cols"
                :form="form"
                :item="col"
                :formType="formType"
                :source-list="sourceList"
                :area-tree="areaTree"
                @changeFormData="changeFormData"
                @getMetaOptions="getMetaOptions"
              ></form-tpl-item>
            </template>
          </el-tab-pane>
        </template>
      </el-tabs>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    formType: String,
    formId: [String, Number],
    cols: Number,
    form: Object,
    data: Object,
    areaTree: Array,
    sourceList: {
      type: Object,
      default() {
        return [];
      },
    },
    isShowComponent: Function,
  },
  data() {
    return {
      activeName: 0,
    };
  },
  computed: {
    isShow() {
      let show =
        this.data.components.findIndex((item) => this.isShowComponent(item)) >=
        0;
      return show;
    },
  },
  methods: {
    getMetaOptions(data) {
      // 获取select,radiogroup,checkGroup的关联其他表单数据
      this.$emit("getMetaOptions", data);
    },
    changeFormData({ name, value, type, callback }) {
      this.$emit("changeFormData", { name, value, type, callback });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  position: relative;
  margin-bottom: 10px;
  transform: translateY(-12px);
  clear: both;
  ::v-deep {
    .el-tabs__content {
      border-color: #e4e7ed;
      border-width: 0px 1px 1px;
      border-style: solid;
      padding-top: 15px;
    }
    .el-tabs__header {
      margin-bottom: 0px !important;
    }
  }
}
</style>
