import { JSEncrypt } from 'jsencrypt'
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCPg39VxAYQt9swDDFXxWRDYrUBw9+UKw/0rmv0EPLE4mNcFbIV3HkBiJOXvzx1Ny1YTpKROtTXarClfN01X77JKZ4Had5jYhQtBCk6GU9Ibt/1LBrhcP8bieOjbNSBQgd3gnD8KQ5F+nkPRh1HBFlndEPlq+8R1b3NkbtbA4geewIDAQAB"
/**
 * 加密
 * @param {String}  需要加密的参数
 */
export function passwordEncryption (param) {
  // 后台给的公钥
  let encryptor = new JSEncrypt() // 新建JSEncrypt对象
  encryptor.setPublicKey(publicKey) // 设置公钥
  let passwordEncryp = encryptor.encrypt(param) // 对密码进行加密
  return passwordEncryp
}
