export default {
  data() {
    return {
      drawObj: null,
    };
  },
  methods: {
    drawStart(event, item, stageType) {
      let line;
      if (this.checkLines.length) {
        line = this.checkLines[0];
      }

      if (line && line.toKey == item.key && stageType == "input") {
        let node = this.etlList.find((obj) => obj.key == line.fromKey);
        this.drawObj = {
          left: node.left + 160,
          top: node.top + 20,
          fromKey: line.fromKey,
          toKey: item.key,
          type: "start",
        };
      } else if (line && line.fromKey == item.key && stageType == "output") {
        let node = this.etlList.find((obj) => obj.key == line.toKey);
        this.drawObj = {
          endX: node.left,
          endY: node.top + 20,
          fromKey: line.fromKey,
          toKey: line.toKey,
          type: "end",
        };
      } else if (stageType == "output") {
        this.drawObj = {
          left: item.left + 160,
          top: item.top + 20,
          fromKey: item.key,
          type: "start",
        };
      }

      document.addEventListener("mousemove", this.drawMove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.drawEnd, {
        passive: false,
      });
    },
    drawMove(event) {
      if (!this.drawObj) {
        return;
      }
      let isIE9 =
        navigator.appName == "Microsoft Internet Explorer" &&
        parseInt(
          navigator.appVersion
            .split(";")[1]
            .replace(/[ ]/g, "")
            .replace("MSIE", ""),
        ) == 9;
      if (!isIE9 && event.buttons != 1) {
        // 鼠标左键没有按住
        this.mouseup();
        return;
      }

      if (this.drawObj.toKey && this.drawObj.fromKey) {
        let index = this.etlLines.findIndex(
          (item) =>
            item.fromKey == this.drawObj.fromKey &&
            item.toKey == this.drawObj.toKey,
        );
        if (index >= 0) {
          this.$delete(this.etlLines, index);
          if (this.drawObj.type === "start") {
            this.$delete(this.drawObj, "toKey");
          } else if (this.drawObj.type === "end") {
            this.$delete(this.drawObj, "fromKey");
          }
        }
      }
      let { left, top } = this.$refs.etlWrapper.getBoundingClientRect();
      let endX = event.clientX - left;
      let endY = event.clientY - top;

      if (this.drawObj.type == "start" && !this.drawObj.toKey) {
        this.$set(this.drawObj, "endX", endX);
        this.$set(this.drawObj, "endY", endY);
      } else if (this.drawObj.type == "end" && !this.drawObj.fromKey) {
        this.$set(this.drawObj, "left", endX);
        this.$set(this.drawObj, "top", endY);
      }
      this.$set(this.drawObj, "isMove", true);
    },
    drawEnd(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      document.removeEventListener("mousemove", this.drawMove);
      document.removeEventListener("mouseup", this.drawEnd);
      if (!this.drawObj) {
        return;
      }
      if (
        this.drawObj &&
        this.drawObj.fromKey &&
        this.drawObj.toKey &&
        this.etlLines.findIndex(
          (item) =>
            item.fromKey == this.drawObj.fromKey &&
            item.toKey == this.drawObj.toKey,
        ) == -1
      ) {
        if (this.isLoop(this.drawObj)) {
          this.$message.error("节点连线不能形成环");
        } else if (this.isFromTo(this.drawObj.fromKey, this.drawObj.toKey)) {
          this.$message.error("输入节点不能直接连接输出节点");
        } else {
          let node = this.etlList.find(
            (item) => item.key == this.drawObj.toKey,
          );
          let isLimit = false;
          if (node.stageType == "union") {
            // 2-10 个节点连接至本节点
            isLimit =
              this.etlLines.filter((item) => item.toKey == node.key).length >=
              10;
          } else if (node.stageType == "join") {
            // 2 个节点连接至本节点
            isLimit =
              this.etlLines.filter((item) => item.toKey == node.key).length >=
              2;
          } else {
            // 1个节点
            isLimit =
              this.etlLines.filter((item) => item.toKey == node.key).length >=
              1;
          }
          if (isLimit) {
            this.$message.error("节点输入连线超出限制");
          } else {
            this.$emit("setUndo");
            this.etlLines.push({
              fromKey: this.drawObj.fromKey,
              toKey: this.drawObj.toKey,
            });
            // todo 字段设置节点设置mapFields
          }
        }
      }
      this.drawObj = null;
    },
    isFromTo(fromKey, toKey) {
      let fromNode = this.etlList.find((item) => item.key == fromKey);
      let toNode = this.etlList.find((item) => item.key == toKey);
      return fromNode.stageType == "input" && toNode.stageType == "output";
    },
    isLoop(node) {
      // 是否闭环判断
      let closed = false;
      let nextNode = this._.cloneDeep(node);

      do {
        if (nextNode) {
          if (nextNode.toKey == node.fromKey) {
            closed = true;
            nextNode = undefined;
          } else {
            nextNode = this.etlLines.find(
              (item) => item.fromKey == nextNode.toKey,
            );
          }
        }
      } while (nextNode);

      if (node.fromKey == node.toKey) {
        closed = true;
      }

      return closed;
    },
  },
};
