var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"form",staticClass:"cust-form",staticStyle:{"width":"778px","margin":"10px auto"},attrs:{"model":_vm.form,"label-position":_vm.formComponent.labelPosition,"label-width":`${_vm.formComponent.labelWidth}px`,"rules":_vm.rules}},[(_vm.formComponent.components)?_vm._l((_vm.formComponent.components),function(item,index){return _c('el-form-item',{key:index,style:(`width:${100 / _vm.formComponent.cols}%;`),attrs:{"label":item.title,"prop":item.name}},[(
            item.componentName == 'input' ||
            item.componentName == 'input_number'
          )?_c('el-input',{attrs:{"placeholder":item.placeHolder,"disabled":_vm.disabled},on:{"blur":function($event){return _vm.blurInput(item, $event)}},model:{value:(_vm.form[item.name]),callback:function ($$v) {_vm.$set(_vm.form, item.name, $$v)},expression:"form[item.name]"}},[(
              item.componentName == 'input_number' && item.format == 'percent'
            )?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2):(item.componentName == 'text_area')?_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3 },"placeholder":item.placeHolder,"disabled":_vm.disabled},model:{value:(_vm.form[item.name]),callback:function ($$v) {_vm.$set(_vm.form, item.name, $$v)},expression:"form[item.name]"}}):(item.componentName == 'date_picker')?_c('el-date-picker',{attrs:{"type":item.picker,"placeholder":item.placeHolder,"disabled":_vm.disabled,"value-format":_vm.getValueFormat(item.picker)},model:{value:(_vm.form[item.name]),callback:function ($$v) {_vm.$set(_vm.form, item.name, $$v)},expression:"form[item.name]"}}):(item.componentName == 'select')?_c('el-select',{attrs:{"placeholder":item.placeHolder,"disabled":_vm.disabled},model:{value:(_vm.form[item.name]),callback:function ($$v) {_vm.$set(_vm.form, item.name, $$v)},expression:"form[item.name]"}},_vm._l((_vm.getOptions(item)),function(opt,index){return _c('el-option',{key:index,attrs:{"label":item.optionSource == 'custom'
                ? opt.label
                : opt[item.metaFieldOption.labelFieldName],"value":item.optionSource == 'custom'
                ? opt.value
                : opt[item.metaFieldOption.valueFieldName]}})}),1):(item.componentName == 'radio_group')?_c('el-radio-group',{staticClass:"custom-radio",attrs:{"disabled":_vm.disabled},model:{value:(_vm.form[item.name]),callback:function ($$v) {_vm.$set(_vm.form, item.name, $$v)},expression:"form[item.name]"}},_vm._l((_vm.getOptions(item)),function(opt,index){return _c('el-radio',{key:index,attrs:{"label":item.optionSource == 'custom'
                ? opt.value
                : opt[item.metaFieldOption.valueFieldName]}},[_vm._v(_vm._s(item.optionSource == "custom" ? opt.label : opt[item.metaFieldOption.labelFieldName]))])}),1):(item.componentName == 'checkbox_group')?_c('el-checkbox-group',{staticClass:"custom-checkbox",attrs:{"disabled":_vm.disabled},model:{value:(_vm.form[item.name]),callback:function ($$v) {_vm.$set(_vm.form, item.name, $$v)},expression:"form[item.name]"}},_vm._l((_vm.getOptions(item)),function(opt,index){return _c('el-checkbox',{key:index,attrs:{"label":item.optionSource == 'custom'
                ? opt.value
                : opt[item.metaFieldOption.valueFieldName]}},[_vm._v(_vm._s(item.optionSource == "custom" ? opt.label : opt[item.metaFieldOption.labelFieldName]))])}),1):_vm._e(),(item.componentName == 'json_form')?_c('div',[_c('el-table',{staticClass:"custom-table",attrs:{"data":_vm.form[item.name]}},[_vm._l((item.components),function(obj,cIndex){return _c('el-table-column',{key:cIndex,attrs:{"label":obj.title ? obj.title : obj.label},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
                    obj.componentName == 'input' ||
                    obj.componentName == 'input_number'
                  )?_c('el-input',{attrs:{"placeholder":item.placeHolder,"disabled":_vm.disabled},on:{"blur":function($event){return _vm.blurInput(obj, $event)}},model:{value:(row[obj.name]),callback:function ($$v) {_vm.$set(row, obj.name, $$v)},expression:"row[obj.name]"}},[(
                      obj.componentName == 'input_number' &&
                      obj.format == 'percent'
                    )?_c('template',{slot:"append"},[_vm._v("%")]):_vm._e()],2):(obj.componentName == 'text_area')?_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3 },"placeholder":obj.placeHolder,"disabled":_vm.disabled},model:{value:(row[obj.name]),callback:function ($$v) {_vm.$set(row, obj.name, $$v)},expression:"row[obj.name]"}}):(obj.componentName == 'date_picker')?_c('el-date-picker',{attrs:{"type":obj.picker,"placeholder":obj.placeHolder,"disabled":_vm.disabled,"value-format":_vm.getValueFormat(item.picker)},model:{value:(row[obj.name]),callback:function ($$v) {_vm.$set(row, obj.name, $$v)},expression:"row[obj.name]"}}):(obj.componentName == 'select')?_c('el-select',{attrs:{"placeholder":obj.placeHolder,"disabled":_vm.disabled},model:{value:(row[obj.name]),callback:function ($$v) {_vm.$set(row, obj.name, $$v)},expression:"row[obj.name]"}},_vm._l((_vm.getOptions(obj)),function(opt,index){return _c('el-option',{key:index,attrs:{"label":obj.optionSource == 'custom'
                        ? opt.label
                        : opt[obj.metaFieldOption.labelFieldName],"value":obj.optionSource == 'custom'
                        ? opt.value
                        : opt[obj.metaFieldOption.valueFieldName]}})}),1):(obj.componentName == 'radio_group')?_c('el-radio-group',{staticClass:"custom-radio",attrs:{"disabled":_vm.disabled},model:{value:(row[obj.name]),callback:function ($$v) {_vm.$set(row, obj.name, $$v)},expression:"row[obj.name]"}},_vm._l((_vm.getOptions(obj)),function(opt,index){return _c('el-radio',{key:index,attrs:{"label":obj.optionSource == 'custom'
                        ? opt.value
                        : opt[obj.metaFieldOption.valueFieldName]}},[_vm._v(_vm._s(obj.optionSource == "custom" ? opt.label : opt[obj.metaFieldOption.labelFieldName]))])}),1):(obj.componentName == 'checkbox_group')?_c('el-checkbox-group',{staticClass:"custom-checkbox",attrs:{"disabled":_vm.disabled},model:{value:(row[obj.name]),callback:function ($$v) {_vm.$set(row, obj.name, $$v)},expression:"row[obj.name]"}},_vm._l((_vm.getOptions(obj)),function(opt,index){return _c('el-checkbox',{key:index,attrs:{"label":obj.optionSource == 'custom'
                        ? opt.value
                        : opt[obj.metaFieldOption.valueFieldName]}},[_vm._v(_vm._s(obj.optionSource == "custom" ? opt.label : opt[obj.metaFieldOption.labelFieldName]))])}),1):_vm._e()]}}],null,true)})}),_c('el-table-column',{attrs:{"width":"100","fixed":"right","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({ index }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteRow(index, _vm.form[item.name])}}},[_vm._v("删除")])]}}],null,true)})],2),_c('el-button',{staticStyle:{"margin-top":"10px"},attrs:{"type":"text","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addRow(item)}}},[_vm._v("添加")])],1):_vm._e()],1)}):_vm._e(),_c('el-form-item',{staticClass:"block"},[(!_vm.$route.query.routeNotBack)?_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v("取消")]):_vm._e(),(!_vm.disabled)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveForm}},[_vm._v("保存")]):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }