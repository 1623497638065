<template>
  <div
    style="padding: 20px"
    v-loading="loading"
    element-loading-text="复制检查中"
  >
    <div>
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
        @keyup.enter.native="search"
        size="small"
      >
        <el-form-item label="搜索：">
          <el-input
            placeholder="搜索单位名"
            v-model="searchForm.keyword"
            clearable
            @clear="search"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search()" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 190px)"
        border
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="名称"
          align="center"
        ></el-table-column>
        <el-table-column prop="hasTemplate" label="操作" align="center">
          <template slot-scope="{ row }">
            <el-tag size="mini" @click="editConfig(row)" style="cursor: pointer"
              >复制</el-tag
            >
            <el-tag
              size="mini"
              @click="renameCompany(row)"
              style="cursor: pointer"
              >修改名称</el-tag
            >
            <el-tag
              size="mini"
              @click="dingUserSyncConfig(row)"
              style="cursor: pointer"
              >钉钉人员同步</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadingList"
      />
    </div>
    <div v-if="dialogFormVisible" class="dialog-copy-form">
      <el-dialog
        title="复制到"
        :visible.sync="dialogFormVisible"
        append-to-body
      >
        <company-select-list
          v-if="dialogFormVisible"
          :currentCompanyId="current.companyId"
          ref="companySelectList"
        ></company-select-list>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelCopy">取 消</el-button>
          <el-button type="primary" @click="copyData">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog
      title="检查错误，请修改后再复制"
      :visible.sync="dialogErrorVisible"
      width="30%"
      append-to-body
    >
      <div class="error-body">
        <div v-for="(item, index) in errorInfoList" :key="index">
          {{ item }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogErrorVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="钉钉通讯录同步参数设置"
      :visible.sync="dingConfigVisible"
      append-to-body
      destroy-on-close
    >
      <div class="config-container">
        <div class="title">
          请将该单位钉钉开放平台中的AppKey和AppSecret填入以下字段，保存后请先复制回调地址、aes_key、签名token，填入钉钉开放平台后再开启自动同步
        </div>
        <el-form
          :model="dingConfig"
          label-position="left"
          label-width="auto"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="开启自动同步">
            <div style="display: flex; align-items: center">
              <el-switch
                v-model="dingConfig.enableSync"
                :disabled="!dingConfig.token"
              ></el-switch>
              <el-button
                style="margin-left: 10px"
                type="text"
                @click="logVisible = true"
                >同步日志</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="AppKey" prop="appKey">
            <el-input
              v-model="dingConfig.appKey"
              placeholder="请输入"
              size="medium"
              :disabled="dingConfig.enableSync"
            ></el-input>
          </el-form-item>
          <el-form-item label="AppSecret" prop="appSecret">
            <el-input
              v-model="dingConfig.appSecret"
              placeholder="请输入"
              size="medium"
              :disabled="dingConfig.enableSync"
            ></el-input>
          </el-form-item>
          <template v-if="dingConfig.token">
            <el-form-item
              :label="item.label"
              v-for="item in disabledArr"
              :key="item.field"
            >
              <div style="display: flex; line-height: 36px">
                <el-input
                  v-model="dingConfig[item.field]"
                  disabled
                  size="medium"
                ></el-input>
                <el-button size="mini" @click="copy(item.field)"
                  >复制</el-button
                >
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dingConfigVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDingUserConfig">保 存</el-button>
        <el-button type="primary" @click="manualDingSyncConfig"
          >立即同步</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="同步日志"
      :visible.sync="logVisible"
      append-to-body
      destroy-on-close
      fullscreen
      @open="openLog"
    >
      <div>
        <el-table :data="logList" style="width: 100%">
          <el-table-column
            header-align="center"
            align="center"
            label="同步日期"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.startTime, "YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="开始时间"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.startTime, "HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="结束时间"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.endTime, "HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="更新方式"
          >
            <template slot-scope="{ row }">
              <span>{{
                row.updateType == "dingUpdate"
                  ? "自动更新"
                  : row.updateType == "manualUpdate"
                  ? "手动更新"
                  : row.updateType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作结果"
          >
            <template slot-scope="{ row }">
              <span v-text="row.success ? '成功' : '失败'"></span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="更新内容"
          >
            <template slot-scope="{ row }">
              <el-popover
                placement="left"
                width="400"
                popper-class="ding-update-detail"
                trigger="click"
              >
                <div style="white-space: pre-wrap">{{ row.content }}</div>
                <div style="white-space: pre-wrap; color: red">
                  {{ row.result }}
                </div>
                <el-button slot="reference" type="text">查看详情</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div style="height: 42px; background: #ffffff">
          <pagination
            style="float: right"
            :total="logPage.totalRow"
            :page.sync="logPage.pageNumber"
            :limit.sync="logPage.pageSize"
            @pagination="loadingLogList"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  listMyCompany,
  duplicateCompanyProject,
  renameCompany,
  getDingUserConfig,
  manualDingSyncConfig,
  saveDingUserConfig,
  getDingUserSyncLog,
  checkError,
} from "@/api/operationCenter";
import CompanySelectList from "./companySelectList.vue";
import { isEmpty } from "lodash";
import moment from "moment/moment";
export default {
  components: { Pagination, CompanySelectList },
  data() {
    return {
      tableData: [],
      page: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      logPage: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      current: {},
      searchForm: {
        keyword: "",
      },
      dialogFormVisible: false,
      dingConfigVisible: false,
      dialogErrorVisible: false,
      dingConfig: {},
      logList: [],
      errorInfoList: [],
      logVisible: false,
      loading: false,
      disabledArr: [
        {
          label: "回调地址",
          field: "url",
        },
        {
          label: "aes_key",
          field: "aesKey",
        },
        {
          label: "签名token",
          field: "token",
        },
      ],
      rules: {
        appKey: [{ required: true, message: "请输入appKey" }],
        appSecret: [{ required: true, message: "请输入appSecret" }],
      },
    };
  },
  created() {
    this.loadingList();
  },
  methods: {
    openLog() {
      this.loadingLogList();
    },
    getTime(value, format) {
      if (!isEmpty(value)) {
        return moment(value).format(format);
      }
    },
    loadingLogList() {
      getDingUserSyncLog({
        companyId: this.current.companyId,
        pageNumber: this.logPage.pageNumber,
        pageSize: this.logPage.pageSize,
      }).then((res) => {
        this.logList = res.data.page.list;
        this.logPage.pageNumber = res.data.page.pageNumber;
        this.logPage.totalPage = res.data.page.totalPage;
        this.logPage.totalRow = res.data.page.totalRow;
      });
    },
    manualDingSyncConfig() {
      this.saveDingUserConfig("manual");
    },
    saveDingUserConfig(update) {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        saveDingUserConfig({
          appKey: this.dingConfig.appKey,
          appSecret: this.dingConfig.appSecret,
          companyId: this.current.companyId,
          id: this.dingConfig.id,
          enableSync: this.dingConfig.enableSync,
        }).then((res) => {
          this.$message.success("保存成功");
          this.$set(this, "dingConfig", res.data.syncConfig);
          //是否立即同步
          if (update == "manual") {
            manualDingSyncConfig({
              companyId: this.current.companyId,
            }).then((res) => {
              this.$message.success("同步成功");
            });
          }
        });
      });
    },
    copy(data) {
      let input = document.createElement("input");
      input.setAttribute("value", this.dingConfig[data]);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("Copy")) {
        this.$message({ type: "success", message: "复制到剪贴板" });
      }
      document.body.removeChild(input);
    },
    dingUserSyncConfig(row) {
      this.dingConfig = {};
      this.current = row;
      getDingUserConfig({
        companyId: row.companyId,
      }).then((res) => {
        if (!isEmpty(res.data)) this.dingConfig = res.data.syncConfig;
        this.dingConfigVisible = true;
      });
    },
    renameCompany(row) {
      this.$prompt("单位名称", "修改单位名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "单位名称不能为空",
        inputValue: row.companyName,
        closeOnClickModal: false,
      }).then(({ value }) => {
        renameCompany({
          companyId: row.companyId,
          name: value,
        }).then(() => {
          this.$message.success("修改成功");
          this.loadingList();
        });
      });
    },
    cancelCopy() {
      this.dialogFormVisible = false;
      if (
        this.$refs.companySelectList &&
        this.$refs.companySelectList.checkedCompany
      ) {
        this.$refs.companySelectList.checkedCompany = "";
        this.$refs.companySelectList.page.pageNumber = 1;
      }
    },
    copyData() {
      if (
        this.$refs.companySelectList &&
        this.$refs.companySelectList.checkedCompany
      ) {
        duplicateCompanyProject({
          fromCompanyId: this.current.companyId,
          toCompanyId: this.$refs.companySelectList.checkedCompany,
          enabledCopyData: this.$refs.companySelectList.enabledCopyData,
          enabledCopyFormTemplate:
            this.$refs.companySelectList.enabledCopyFormTemplate,
        })
          .then(() => {
            this.cancelCopy();
            this.$message.success("复制成功,数据正在复制，请稍后查看");
          })
          .catch(() => {});
      } else {
        this.$message.warning("请选择复制单位");
      }
    },
    editConfig(row) {
      this.loading = true;
      this.current = row;
      checkError({
        companyId: this.current.companyId,
      })
        .then((res) => {
          this.errorInfoList = res.data.errorInfoList;
          this.loading = false;
          if (this.errorInfoList && this.errorInfoList.length > 0) {
            this.dialogErrorVisible = true;
          } else {
            this.$confirm("检查完成，是否开始复制?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                this.dialogFormVisible = true;
              })
              .catch(() => {
                this.$message.info("取消复制");
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    search() {
      this.loadingList();
    },

    loadingList() {
      listMyCompany({
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        keyword: this.searchForm.keyword,
      }).then((res) => {
        this.tableData = res.data.page.list;
        this.page.pageNumber = res.data.page.pageNumber;
        this.page.totalPage = res.data.page.totalPage;
        this.page.totalRow = res.data.page.totalRow;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-icon-circle {
  width: 25px;
  height: 25px;
  border-radius: 32px;
  //   border: solid 1px #409eff;
}

.dialog-copy-form {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 10px;
  }

  ::v-deep .el-dialog__body .el-table {
    min-height: 40vh;
    max-height: 50vh;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #d6d6d6;
    margin-top: 10px;
  }

  ::v-deep .el-table::before {
    z-index: inherit;
  }
}
.config-container {
  padding: 10px 30px;
  .title {
    font-size: 16px;
    line-height: 22px;
    color: #606266;
    margin-bottom: 15px;
  }
}
.error-body {
  padding: 5px 15px;
  height: 400px;
  overflow: auto;
  color: red;
}
</style>
<style lang="scss">
.ding-update-detail {
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
}
</style>
