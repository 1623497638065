import { fetchMetaComponents } from "@/api/form";

import { isEmpty } from "@zgg-core-utils/utils";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    loadingStatus: {},
    componentsMap: {},
    funList: {},
  },
  actions: {
    fetchMetaComponents({ commit, state }, { tableName }) {
      return new Promise(async (resolve, reject) => {
        if (!isEmpty(state.componentsMap[tableName])) {
          resolve(state.componentsMap[tableName]);
        } else if (state.loadingStatus[tableName]) {
          commit("SET_FUN", {
            tableName,
            callback: (list) => {
              resolve(list);
            },
          });
        } else {
          commit("SET_LOADING", { key: tableName, val: true });
          let res = await fetchMetaComponents(tableName);
          commit("SET_LOADING", { key: tableName, val: false });

          commit("SET_COMPONENTS", {
            tableName,
            list: res.data.list,
          });

          resolve(res.data.list);
          if (state.funList[tableName] && state.funList[tableName].length) {
            state.funList[tableName].forEach((fun) => {
              fun(res.data.list);
            });
            commit("clearList", tableName);
          }
        }
      });
    },
  },
  mutations: {
    SET_LOADING: (state, { key, val }) => {
      state.loadingStatus[key] = val;
    },
    SET_FUN(state, { tableName, callback }) {
      if (state.funList[tableName]) {
        state.funList[tableName].push(callback);
      } else {
        state.funList[tableName] = [callback];
      }
    },
    SET_COMPONENTS: (state, { tableName, list }) => {
      state.componentsMap[tableName] = list;
    },
    clearList: (state, tableName) => {
      delete state.funList[tableName];
    },
    clearItemByTableName(state, tableName) {
      delete state.loadingStatus[tableName];
      delete state.componentsMap[tableName];
      delete state.funList[tableName];
    },
    clearAll(state) {
      state.loadingStatus = {};
      state.componentsMap = {};
      state.funList = {};
    },
  },
};
