<template>
  <div class="company-config__container">
    <el-menu
      default-active="2"
      router
      mode="vertical"
      class="company-config__menu__wrap"
    >
      <el-menu-item index="/lowCode/company-config/employee-manage">
        人员管理
      </el-menu-item>
    </el-menu>
    <div style="flex: 1">
      <router-view> </router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "company-config",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.company-config__container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.company-config__menu__wrap {
  width: 200px;
  height: 100%;
}
</style>
