<template>
  <div
    class="rpa-flex-column"
    :class="[node.type == 'branch' ? 'node-branch' : '']"
  >
    <template v-if="node.type == 'condition'">
      <div class="top-line"></div>
    </template>
    <!--  分支节点样式 -->
    <rpa-node-branch
      :node="node"
      :isOpen.sync="isOpen"
      ref="rpaNodeOther"
      v-if="node.type == 'branch'"
      :disabled="disabled"
    ></rpa-node-branch>
    <!-- 其他节点样式 -->
    <rpa-node-other
      v-else
      :node="node"
      :nodes="nodes"
      ref="rpaNodeOther"
      :isPadding.sync="isPadding"
      :checCopyNode="checCopyNode"
      :changeNode="changeNode"
      :add="add"
      :addBrach="addBrach"
      :copyKeys="copyKeys"
      :isCopying.sync="tempIsCopying"
      :deleteNode="deleteNode"
      :getFromNodes="getFromNodes"
      :getToNodes="getToNodes"
      :disabled="disabled"
      :flowedNodes="flowedNodes"
    ></rpa-node-other>
    <!-- 分支子节点 -->
    <template v-if="node.type == 'branch'">
      <div class="flex-row" v-show="isOpen">
        <template v-for="(flow, index) in node.flows">
          <rpa-node
            :ref="`rpaNode_${flow.nodes[0].key}`"
            class="node-condition"
            :class="getConditionClass(index)"
            :key="flow.nodes[0].key"
            :node="flow.nodes[0]"
            :lines.sync="flow.lines"
            :nodes.sync="flow.nodes"
            :flowedNodes="getFlowedNodes(flow.nodes[0].key)"
            :isCopying.sync="tempIsCopying"
            :isPaddingRight.sync="isPadding"
            :changeNode="changeBranch"
            :checCopyNode="checCopyNode"
            :copyKeys="copyKeys"
            :add="add"
            :addBrach="addBrach"
            @delBranchNode="delBranchNode"
            :getParentNodes="getFromNodes"
            :getChildNodes="getToNodes"
            :disabled="disabled"
          ></rpa-node>
        </template>
      </div>
      <!-- 分支节点底部按钮 -->
      <rpa-add-btn
        v-if="isShowAdd"
        :node="node"
        :nodes="nodes"
        :add="add"
        :addBrach="addBrach"
        :copyKeys="copyKeys"
        :isCopying.sync="tempIsCopying"
        :isPadding.sync="isPadding"
        :getChildNodes="getToNodes"
        :getFromNodes="getFromNodes"
        class="branch-line"
      ></rpa-add-btn>
      <div v-else class="btn-add-none"></div>
    </template>

    <!-- 下一行节点 -->
    <template v-if="nodeChildren && nodeChildren.length"
      ><rpa-node
        v-for="opt in nodeChildren"
        :ref="`rpaNode_${opt.key}`"
        :key="opt.key"
        :node="opt"
        :lines.sync="tmpLines"
        :nodes.sync="tmpNodes"
        :flowedNodes="flowedNodes"
        :isCopying.sync="tempIsCopying"
        :isPaddingRight.sync="isPadding"
        :changeNode="changeChildren"
        :checCopyNode="checCopyNode"
        :copyKeys="copyKeys"
        :add="add"
        :addBrach="addBrach"
        :getParentNodes="getParentNodes"
        :getChildNodes="getChildNodes"
        :disabled="disabled"
      ></rpa-node>
    </template>
    <slot name="end"></slot>
  </div>
</template>
<script>
import rpaNodeOther from "@/views/lowCode/rpa/rpa-node-other";
import rpaNodeBranch from "@/views/lowCode/rpa/rpa-node-branch";
import rpaAddBtn from "@/views/lowCode/rpa/rpa-add-btn";
import { getValueByKey, isEmpty } from "@zgg-core-utils/utils";
export default {
  name: "rpaNode",
  inheritAttrs: false,
  components: { rpaNodeOther, rpaNodeBranch, rpaAddBtn },
  props: {
    node: Object,
    nodes: Array,
    lines: Array,
    changeNode: Function,
    checCopyNode: Function,
    getParentNodes: Function,
    getChildNodes: Function,
    isPaddingRight: Boolean,
    copyKeys: Array,
    isCopying: Boolean,
    disabled: Boolean,
    flowedNodes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  provide() {
    return {
      getAllNodes: () => {
        return this.nodes;
      },
    };
  },
  computed: {
    tempIsCopying: {
      get() {
        return this.isCopying;
      },
      set(val) {
        this.$emit("update:isCopying", val);
      },
    },
    nodeChildren() {
      // 子节点列表
      let list = [];
      let keys = this.lines
        .filter((item) => item.fromKey == this.node.key)
        .map((item) => item.toKey);
      keys.forEach((key) => {
        let obj = this.nodes.find((item) => item.key == key);
        if (obj) {
          list.push(obj);
        }
      });

      return list;
    },
    isPadding: {
      get() {
        return this.isPaddingRight;
      },
      set(val) {
        this.$emit("update:isPaddingRight", val);
      },
    },
    hasBottomLine() {
      return this.nodes.findIndex((item) => item.type == "branch") == -1;
    },
    tmpNodes: {
      get() {
        return this.nodes;
      },
      set(val) {
        this.$emit("update:nodes", val);
      },
    },
    tmpLines: {
      get() {
        return this.lines;
      },
      set(val) {
        this.$emit("update:lines", val);
      },
    },
    isShowAdd() {
      if (this.disabled) {
        return false;
      }
      // 有渲染界面节点
      if (
        this.node.flows.findIndex(
          (item) =>
            item.nodes.findIndex((opt) =>
              ["render_notice", "render_form", "render_url"].includes(opt.type),
            ) >= 0,
        ) >= 0
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    getFlowedNodes(key) {
      // 获取分支的已执行节点
      let flowedNode = this.flowedNodes.find(
        (item) => item.key == this.node.key,
      );
      if (flowedNode && flowedNode.branchWorkflowDataList) {
        let data = flowedNode.branchWorkflowDataList.find(
          (item) => item.flowedNodes[0].key == key,
        );
        if (data) {
          return data.flowedNodes;
        }
      }
      return [];
    },
    async validateNode() {
      let list = [];
      if (this.$refs.rpaNodeOther) {
        let info = await this.$refs.rpaNodeOther.validate();
        if (!isEmpty(info)) {
          list.push(info);
        }
      }
      if (this.node.type == "branch") {
        // 分支
        for (let index = 0; index < this.node.flows.length; index++) {
          let flow = this.node.flows[index];
          let key = flow.nodes[0].key;
          let info = await this.$refs[`rpaNode_${key}`][0].validateNode();
          if (!isEmpty(info)) {
            list = list.concat(info);
          }
        }
      }

      for (let k = 0; k < this.nodeChildren.length; k++) {
        let item = this.nodeChildren[k];
        let info = await this.$refs[`rpaNode_${item.key}`][0].validateNode();
        if (!isEmpty(info)) {
          list = list.concat(info);
        }
      }

      return list;
    },
    changeBranch(node) {
      let index = this.node.flows.findIndex(
        (item) => item.nodes[0].key == node.key,
      );
      if (index >= 0) {
        this.$set(this.node.flows[index].nodes, 0, node);
      }
    },
    changeChildren(node) {
      let index = this.nodes.findIndex((item) => item.key == node.key);
      if (index >= 0) {
        this.$set(this.nodes, index, node);
      }
    },
    getFromNodes() {
      let list = [];
      // 获取分支下所有的节点
      let buildBranchNodes = (node) => {
        let children = [];
        node.flows.forEach((flow) => {
          let childs = flow.nodes.filter((item) => item.type != "condition");
          childs.forEach((item) => {
            if (item.type == "branch") {
              children = children.concat(buildBranchNodes(item));
            } else {
              children.push(this._.cloneDeep(item));
            }
          });
        });
        return children;
      };
      // 递归获取节点的所有上级节点
      let buildParentNodes = (key) => {
        let line = this.lines.find((item) => item.toKey == key);

        if (line) {
          let obj = this.nodes.find((item) => item.key == line.fromKey);

          if (obj) {
            if (obj.type == "branch") {
              list = list.concat(buildBranchNodes(obj));
              buildParentNodes(obj.key);
            } else if (obj.type == "condition") {
              if (typeof this.getParentNodes === "function") {
                list = list.concat(this.getParentNodes());
              }
            } else {
              list.push(this._.cloneDeep(obj));
              buildParentNodes(obj.key);
            }
          }
        }
      };

      if (this.node.type == "condition") {
        if (typeof this.getParentNodes === "function") {
          list = list.concat(this.getParentNodes());
        }
      } else {
        buildParentNodes(this.node.key);
      }

      return list;
    },
    getToNodes() {
      let list = [];
      let buildBranchNodes = (node) => {
        let children = [];
        node.flows.forEach((flow) => {
          flow.nodes.forEach((item) => {
            if (item.type == "branch") {
              children = children.concat(buildBranchNodes(item));
            } else {
              children.push(this._.cloneDeep(item));
            }
          });
        });
        return children;
      };

      let buildChildNodes = (key) => {
        let line = this.lines.find((item) => item.fromKey == key);
        if (line) {
          let obj = this.nodes.find((item) => item.key == line.toKey);
          if (obj) {
            if (obj.type == "branch") {
              // 分支
              list = list.concat(buildBranchNodes(obj));
              buildChildNodes(obj.key);
            } else if (obj.type == "condition") {
              if (typeof this.getChildNodes === "function") {
                list = list.concat(this.getChildNodes());
              }
            } else {
              list.push(this._.cloneDeep(obj));
              buildChildNodes(obj.key);
            }
          }
        } else {
          if (typeof this.getChildNodes === "function") {
            list = list.concat(this.getChildNodes());
          }
        }
      };
      buildChildNodes(this.node.key);
      if (this.node.type == "condition") {
        if (typeof this.getChildNodes === "function") {
          list = list.concat(this.getChildNodes());
        }
      }

      return list;
    },
    delBranchNode(node) {
      let index = this.node.flows.findIndex(
        (item) => item.nodes.findIndex((obj) => obj.key == node.key) >= 0,
      );
      if (this.node.flows.length == 2) {
        // 只有两个分支时候
        let otherFlow;

        if (index == 0) {
          otherFlow = this.node.flows[1];
        } else {
          otherFlow = this.node.flows[0];
        }

        if (otherFlow.lines.length) {
          // 另一个分支有数据时候
          let start = otherFlow.nodes[0];
          // 新的节点
          let nodes = otherFlow.nodes.filter((item) => item.key != start.key);
          let lines = otherFlow.lines.filter(
            (item) => item.fromKey != start.key,
          );
          let startKey = getValueByKey(
            otherFlow.lines.find((item) => item.fromKey == start.key),
            "toKey",
          );
          let endKey;
          if (startKey) {
            let getEndKey = (key) => {
              let line = lines.find((item) => item.fromKey == key);
              if (line) {
                endKey = line.toKey;
                getEndKey(endKey);
              }
            };
            getEndKey(startKey);
            this.lines.forEach((item) => {
              if (item.toKey == this.node.key) {
                item.toKey = startKey;
              }
            });
            if (endKey) {
              this.lines.forEach((item) => {
                if (item.fromKey == this.node.key) {
                  item.fromKey = endKey;
                }
              });
            }
          }
          nodes.forEach((node) => {
            this.nodes.push(node);
          });
          lines.forEach((line) => {
            this.lines.push(line);
          });
        }
        // 删除总分支节点
        this.delNodeByNode(this.node);
      } else {
        // 多个分支
        this.node.flows.splice(index, 1);
      }
    },
    deleteNode() {
      let text = "确定删除此节点吗";
      if (this.node.type == "condition") {
        text = "同时删除分支下所有节点";
      }
      this.$confirm(text, "提示")
        .then(() => {
          if (this.node.type != "condition") {
            // 非分支节点删除
            this.delNodeByNode(this.node);
          } else {
            // 分支节点删除
            this.$emit("delBranchNode", this.node);
          }
        })
        .catch(() => {});
    },
    delNodeByNode(node) {
      let lines = this.lines.filter(
        (item) => item.fromKey != node.key && item.toKey != node.key,
      );
      let lineFrom = this.lines.find((item) => item.fromKey == node.key);
      let lineTo = this.lines.find((item) => item.toKey == node.key);
      if (lineFrom && lineTo) {
        let line = {
          fromKey: lineTo.fromKey,
          toKey: lineFrom.toKey,
        };
        lines.push(line);
      }
      let index = this.nodes.findIndex((item) => item.key == node.key);
      this.$delete(this.nodes, index);

      this.$emit("update:lines", lines);
    },
    getConditionClass(index) {
      if (index == 0) {
        // 第一个
        return "start-top";
      } else if (index == this.node.flows.length - 1) {
        // 最后一个
        return "end-top";
      } else {
        // 中间部分
        return "middle-top";
      }
    },
    add(obj, fromKey) {
      this.nodes.push(obj);
      this.lines.forEach((item) => {
        if (item.fromKey == fromKey) {
          item.fromKey = obj.key;
        }
      });
      this.lines.push({
        fromKey,
        toKey: obj.key,
      });
    },
    addBrach(obj, fromKey, position) {
      // 分支节点插入

      if (position == "bottom") {
        // 不移动、等待分支汇集后再执行下方节点（底部）
        // 分支根节点插入
        this.add(obj, fromKey);
      } else if (["left", "right"].includes(position)) {
        // 左侧
        let index = 0;
        if (position == "right") {
          index = 1;
        }

        let keys = [];
        let getChildrenKeys = (key) => {
          let line = this.lines.find((item) => item.fromKey == key);
          if (line) {
            keys.push(line.toKey);
            getChildrenKeys(line.toKey);
          }
        };
        getChildrenKeys(fromKey);

        let nodes = this.nodes.filter((item) => keys.includes(item.key));
        let lines = this.lines.filter((item) => keys.includes(item.fromKey));
        obj.flows[index].nodes = obj.flows[index].nodes.concat(nodes);
        if (keys.length) {
          lines.push({
            fromKey: obj.flows[index].nodes[0].key,
            toKey: keys[0],
          });
        }

        obj.flows[index].lines = lines;

        let tmpNodes = this.nodes.filter((item) => !keys.includes(item.key));
        let tmpLines = this.lines.filter((item) => !keys.includes(item.toKey));
        tmpLines.push({
          fromKey,
          toKey: obj.key,
        });
        tmpNodes.push(obj);
        this.$emit("update:nodes", tmpNodes);
        this.$emit("update:lines", tmpLines);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  width: max-content;
}
.node-condition {
  position: relative;
  padding-top: 20px;
  &::before {
    position: absolute;
    content: " ";
    display: block;
    background-color: #ccc;
    height: 1px;
    top: -16px;
  }
  &::after {
    position: absolute;
    content: " ";
    display: block;
    background-color: #ccc;
    height: 1px;
    bottom: 0px;
  }
  &.start-top {
    &::before,
    &::after {
      left: 50%;
      right: 0;
    }
  }
  &.end-top {
    &::before,
    &::after {
      left: 0;
      right: 50%;
    }
  }
  &.middle-top {
    &::before,
    &::after {
      left: 0;
      right: 0;
    }
  }
  .top-line {
    width: 1px;
    position: absolute;
    background-color: #ccc;
    left: 50%;
    bottom: 0;
    top: -16px;
  }
  .bottom-line {
    width: 1px;
    position: absolute;
    background-color: #ccc;
    bottom: 0;
    top: 0;
  }
  .workflow-box {
    &::before {
      display: none;
    }
  }
}
.node-branch {
  // position: relative;
}
.branch-line {
  position: relative;
  &::before {
    position: absolute;
    left: 50%;
    width: 1px;
    top: 0px;
    bottom: 0px;
    background-color: #ccc;
    display: block;
    content: " ";
  }
}
.btn-add-none {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: flex-start;
  &::after {
    display: block;
    content: " ";
    width: 1px;
    height: 30px;
    background-color: #ccc;
  }
}
</style>
