<template>
  <div>
    <el-cascader
      :options="areaList"
      v-model="area"
      :props="areaProps"
      style="margin-bottom: 10px; width: 100%"
      size="mini"
      clearable
    >
    </el-cascader>
    <el-input
      v-model="address"
      placeholder="请输入详细地址"
      type="textarea"
      size="mini"
      :disabled="component.picker != 'address'"
    ></el-input>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { componentProps } from "./util";
export default {
  name: "attr-address-value",
  props: componentProps,
  computed: {
    ...mapState("area", ["areaProps"]),
    areaList() {
      let list = [];
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (this.component.picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (this.component.picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    area: {
      get() {
        let area = [];
        if (
          this.component.defaultValue &&
          typeof this.component.defaultValue === "object" &&
          Object.keys(this.component.defaultValue).length
        ) {
          if (this.component.picker == "province") {
            area.push(this.component.defaultValue.provinceId);
          } else if (this.component.picker == "city") {
            area = [
              this.component.defaultValue.provinceId,
              this.component.defaultValue.cityId,
            ];
          } else {
            area = [
              this.component.defaultValue.provinceId,
              this.component.defaultValue.cityId,
              this.component.defaultValue.countyId,
            ];
          }
        }
        return area;
      },
      set(val) {
        if (!this.component.defaultValue) {
          this.component.defaultValue = {};
        }
        let provinceId = val[0];
        let cityId = val[1];
        let countyId = val[2];
        let provinceItem;
        let cityItem;
        let countyItem;
        if (provinceId) {
          provinceItem = this.areaTree.find((item) => item.id == provinceId);
          this.component.defaultValue.provinceId = provinceId;
          this.component.defaultValue.province = provinceItem.name;
        }
        if (this.component.picker != "province") {
          if (cityId) {
            cityItem = provinceItem.children.find((item) => item.id == cityId);
            this.component.defaultValue.cityId = cityId;
            this.component.defaultValue.city = cityItem.name;
          }
        }
        if (
          this.component.picker != "province" &&
          this.component.picker != "city"
        ) {
          if (countyId) {
            countyItem = cityItem.children.find((item) => item.id == countyId);
            this.component.defaultValue.countyId = countyId;
            this.component.defaultValue.county = countyItem.name;
          }
        }
      },
    },
    address: {
      get() {
        let str = "";
        if (
          this.component.defaultValue &&
          this.component.defaultValue.address
        ) {
          str = this.component.defaultValue.address;
        }
        return str;
      },
      set(val) {
        if (!this.component.defaultValue) {
          this.component.defaultValue = {};
        }
        this.component.defaultValue.address = val;
      },
    },
  },
  methods: {
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
  },
};
</script>