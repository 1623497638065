<template>
  <div>
    <el-upload
      ref="upload"
      :multiple="false"
      class="upload-container"
      :file-list.sync="fileList"
      :http-request="Upload"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
      :on-preview="handlePreview"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :on-progress="handleProgress"
      :limit="1"
      action
      :show-file-list="false"
      accept=".docx"
    >
      <div v-if="$slots.default">
        <slot> </slot>
      </div>
      <el-button size="small" type="primary" v-else>上传表格</el-button>
    </el-upload>
  </div>
</template>

<script>
import axios from "axios";
import request from "@/utils/request";

export default {
  components: {},
  data() {
    return {
      fileList: [],
    };
  },
  props: {
    formId: [String],
    templateId: {
      type: String,
      default: () => "",
    },
  },
  computed: {},
  methods: {
    getFileList() {
      return this.fileList;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`每次只能上传1个文件`);
    },
    handlePreview(file) {
      let rootSrc = "";
      let filePreview = rootSrc + file.response.url;
      window.open(filePreview, "_blank");
    },
    // 删除文件之前的钩子
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      file.raw && file.raw.source && file.raw.source.cancel("中止上传！");
      this.fileList = fileList;
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      this.$refs.upload.clearFiles();
    },
    handleProgress(event, file, fileList) {},
    //文件上传前的校验
    beforeAvatarUpload(file) {},
    //todo 未来需要添加删除附件的功能
    Upload(params) {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      let form = new FormData();
      form.append("attachmentFile", params.file);
      form.append("formId", this.formId);
      if (this.templateId) {
        form.append("wordTemplateId", this.templateId);
      }
      request({
        url: "/app/appFormExportWord/upload",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: source.token,
        data: form,
        onUploadProgress: (progressEvent) => {
          params.file.percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          params.file.source = source;
          params.onProgress(params.file);
        },
      })
        .then((resp) => {
          params.onSuccess(resp.data);
          this.$emit("uploadSuccess", resp.data, this.templateId);
        })
        .catch((error) => {});
    },
  },
  mounted() {},
};
</script>

<style lang="less"></style>
