<!--
 * @Description: 倒计时组件展示
 * @Version: 0.1
 * @Autor: zhengxn
 * @Date: 2022-12-08 14:10:54
-->

<template>
  <div class="dashboard-filter__container">
    <div
      class="count-down"
      style="width: 100%"
      :style="'color:' + countDownModel.valueColor"
    >
      <div ref="centerNumber" class="center">
        <span style="align-self: center; padding-right: 10px">{{
          textTip
        }}</span>
        <span class="count">{{ days }}</span>
        <span style="align-self: center; padding-left: 10px">天</span>
      </div>
      <div class="foot">
        目标日：{{ countDownModel.targetDate }}
        {{ getWeekDay(countDownModel.targetDate) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  props: {
    countDownModel: {
      type: Object,
      default() {
        return {
          title: "",
          targetDate: "",
          valueColor: "",
          componentName: "count_down",
        };
      },
    },
  },
  data() {
    return {
      days: "",
      textTip: "还剩",
      scale: 1,
    };
  },
  created() {
    this.getResidueDate();
  },
  watch: {
    countDownModel() {
      this.getResidueDate();
    },
  },
  methods: {
    resizeChart() {
      let clientWidth = this.$el.parentElement.clientWidth;

      let clientHeight = this.$el.parentElement.clientHeight;
      if (clientWidth <= clientHeight || clientWidth == undefined) {
        clientWidth = clientHeight * 2;
      }
      let numberRef = this.$refs.centerNumber;
      if (numberRef) {
        let width = numberRef.clientWidth + 150;
        let height = numberRef.clientHeight + 150;
        let x1 = 1,
          x2 = 1;
        if (width > clientWidth) {
          x1 = clientWidth / width;
        }
        if (height > clientHeight) {
          x2 = clientHeight / height;
        }
        let x = Math.min(...[x1, x2]);
        if (x < 1) {
          numberRef.style.transform = `scale(${x})`;
        } else {
          numberRef.style.transform = `scale(${x})`;
        }
      }
    },
    getResidueDate() {
      this.residue =
        new Date(this.countDownModel.targetDate).getTime() - new Date();
      this.days = this.addZero(Math.floor(this.residue / 1000 / 60 / 60 / 24)); //天
      // this.hours = this.addZero(
      //     Math.floor((this.residue / 1000 / 60 / 60) % 24)
      // ); //时
      // this.minutes = this.addZero(
      //     Math.floor((this.residue / 1000 / 60) % 60)
      // ); //分
      // this.seconds = this.addZero(Math.floor((this.residue / 1000) % 60)); //秒
    },
    start() {
      // let _this = this;
    },
    addZero(d) {
      console.log(d, 666666);
      if (d < 0) {
        this.textTip = "已经";
      } else {
        this.textTip = "还剩";
      }
      return Math.abs(d + 1);
    },

    getWeekDay(date) {
      date = new Date(date);
      var week;
      if (date.getDay() == 0) week = "星期日";
      if (date.getDay() == 1) week = "星期一";
      if (date.getDay() == 2) week = "星期二";
      if (date.getDay() == 3) week = "星期三";
      if (date.getDay() == 4) week = "星期四";
      if (date.getDay() == 5) week = "星期五";
      if (date.getDay() == 6) week = "星期六";
      return week;
    },
    getData() {
      console.log("dashboardFilter");
      this.$nextTick(() => {
        this.resizeChart();
      });
      this.getResidueDate();
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-filter__container {
  width: 100%;
  height: 100%;
  text-align: center;
}
.count-down {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  vertical-align: middle;
  .center {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    white-space: nowrap;
    // padding:13px 60px;
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 30px;

    .count {
      font-size: 200px;
    }
  }
  .foot {
    padding: 16px 10px 0px;
    box-shadow: inset 0px 1px 0px #ebecf0;
    font-size: 16px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    font-weight: 600;
  }
}
</style>
