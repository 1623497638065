<template>
  <div ref="flexBody" style="position: relative; overflow: auto; flex: 1">
    <grid-layout
      :layout.sync="components"
      :col-num="colNumber"
      :row-height="14"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="layoutMargin"
      :use-css-transforms="true"
    >
      <template v-for="(item, index) in components">
        <grid-item
          @resize="resizeEvent(item)"
          @resized="resizedEvent(item)"
          :minH="calcComponentMinHeight(item.componentName)"
          :minW="calcComponentMinWidth(item.componentName)"
          :maxH="maxH"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.key"
        >
          <div
            class="dash-item"
            @click.stop="currentKey = item.key"
            @mouseover="mouseOver(item.key)"
            @mouseleave="mouseLeave"
            :style="colorConfigStyle(item)"
          >
            <div
              :class="{
                active: item.key == currentKey,
                'dash-container-outLine': isShowEdit == item.key,
              }"
              class="dash-container"
              :style="calDashItemContainerStyle(item)"
            >
              <template
                v-if="!['dashboard', 'tab'].includes(item.componentName)"
              >
                <div class="dash-header" v-if="isShowTitle(item)">
                  <div
                    class="title"
                    :style="`color:${item.colorConfig?.titleColor}`"
                  >
                    {{ item.extParam.title }}
                  </div>
                </div>
                <div v-show="isShowEdit == item.key" class="header-edit">
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    @command="handleCommand"
                  >
                    <span class="el-dropdown-link">
                      <i
                        class="iconfont icon-arrow-left-right-line"
                        style="
                          color: rgba(9, 30, 66, 0.66);
                          padding: 2px;
                          font-size: 16px;
                          margin-right: 0;
                        "
                      ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <template v-for="(row, index) in containers">
                        <el-dropdown-item
                          :key="index"
                          :command="{ container: row, component: item }"
                          :disabled="row.key == parent.key"
                          ><el-dropdown
                            v-if="row.componentName == 'tab'"
                            placement="right-start"
                            @command="handleCommand"
                            :show-timeout="0"
                          >
                            <div
                              class="el-dropdown-link"
                              style="line-height: 36px"
                            >
                              移动到{{ row.extParam?.title
                              }}<i
                                class="el-icon-arrow-right el-icon--right"
                              ></i>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                              <template
                                v-for="(tab, tabIndex) in row.components"
                                ><el-dropdown-item
                                  :key="tabIndex"
                                  :command="{
                                    container: row,
                                    component: item,
                                    tab,
                                  }"
                                  :disabled="
                                    tab.components.some(
                                      (col) => col.key == item.key,
                                    )
                                  "
                                  >{{ tab.title }}</el-dropdown-item
                                ></template
                              >
                            </el-dropdown-menu> </el-dropdown
                          ><span v-else
                            >移动到{{ row.extParam?.title }}</span
                          ></el-dropdown-item
                        >
                      </template>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <i
                    @click="editItem(item, parent, $event)"
                    class="el-icon-edit-outline"
                    style="color: rgba(9, 30, 66, 0.66); padding: 2px"
                  ></i>
                  <el-popover placement="bottom" trigger="click">
                    <div
                      class="dashboard-item-edit__wrap"
                      v-if="item.refreshConfig"
                    >
                      <div class="row">
                        <span>自动刷新</span>
                        <el-switch
                          v-model="item.refreshConfig.autoRefresh"
                          :active-value="true"
                          :inactive-value="false"
                        ></el-switch>
                      </div>
                      <div class="row">
                        <span>每隔</span>
                        <el-input-number
                          v-model="item.refreshConfig.autoRefreshSeconds"
                          style="width: 100px"
                          size="mini"
                          controls-position="right"
                          :min="1"
                        ></el-input-number>
                        <span>秒，前台自动刷新当前组件</span>
                      </div>
                    </div>
                    <i
                      v-if="hasAutoRefreshComponent(item.componentName)"
                      slot="reference"
                      class="el-icon-s-tools"
                      style="color: rgba(9, 30, 66, 0.66); padding: 2px"
                    ></i>
                  </el-popover>
                  <i
                    v-if="includeComponent(item.componentName)"
                    @click="showColorConfig(item, parent)"
                    class="iconfont icon-paint-fill"
                    style="
                      color: rgba(9, 30, 66, 0.66);
                      padding: 2px;
                      font-size: 16px;
                      margin-right: 0;
                    "
                  ></i>
                  <i
                    @click="copyItem(item, $event)"
                    class="el-icon-copy-document"
                    style="color: rgba(9, 30, 66, 0.66); padding: 2px"
                  ></i>
                  <i
                    @click="delItem(item.key, $event)"
                    class="el-icon-delete"
                    style="color: rgba(9, 30, 66, 0.66); padding: 2px"
                  ></i>
                </div>
              </template>
              <div class="dash-body">
                <slot
                  v-if="['dashboard', 'tab'].includes(item.componentName)"
                  name="container"
                  :config="item"
                  :ref="'chart_' + item.key"
                >
                </slot>
                <chart-component
                  v-else
                  :view="item"
                  :ref="'chart_' + item.key"
                  :refreshDashboard="refreshDashboard"
                  :filter-component-list="filterComponentList"
                  :clearFilterCompsValue="clearFilterCompsValue"
                ></chart-component>
              </div>
            </div>
          </div>
        </grid-item>
      </template>
    </grid-layout>
    <dashboard-filter-setting
      ref="dashboardFilterSetting"
      :data-component-list="dataComponentList"
      :filter-component-list="filterComponentList"
      :visible.sync="dashboardFilterSettingVisible"
      @save="saveFilter"
    ></dashboard-filter-setting>
    <dashboard-free-text
      ref="dashboardFreeText"
      :data-component-list="dataComponentList"
      :visible.sync="dashboardFreeTextVisible"
      @save="saveFilter"
    ></dashboard-free-text>
    <dashboard-count-down
      ref="dashboardCountDown"
      :data-component-list="dataComponentList"
      :visible.sync="dashboardCountDownVisible"
      @save="saveFilter"
    ></dashboard-count-down>
    <dashboard-topic
      ref="dashboardTopic"
      :data-component-list="dataComponentList"
      :visible.sync="dashboardTopicVisible"
      @save="saveFilter"
    ></dashboard-topic>
    <dashboard-carousel-edit
      ref="dashboardCarousel"
      :visible.sync="dashboardCarouselVisible"
      @save="saveFilter"
    ></dashboard-carousel-edit>
    <dashboard-include-edit
      ref="dashboardInclude"
      :visible.sync="dashboardIncludeVisible"
      @save="saveFilter"
    ></dashboard-include-edit>
    <dashboard-button-group-edit
      ref="dashboardButtonGroup"
      :visible.sync="dashboardButtonGroupVisible"
      @save="saveFilter"
    ></dashboard-button-group-edit>
    <div class="dash-edit" v-if="showBody && chart">
      <el-dialog
        custom-class="no-header-dialog"
        append-to-body
        destroy-on-close
        :visible.sync="showBody"
        fullscreen
        :show-close="false"
        :show-header="false"
      >
        <gantt-edit
          v-if="chart.componentName == 'gantt'"
          :gantt="chart"
          :areaTree="areaTree"
          :closeFun="closeFun"
          @save="saveForm"
        ></gantt-edit>
        <chart-edit
          v-else
          :appView="chart"
          :closeFun="closeFun"
          @saveChart="saveForm"
        ></chart-edit>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  COL_NUM,
  handleDataFilterSet,
  isChartComponent,
  MAX_H,
  MIN_H,
  MIN_W,
  ROW_HEIGHT,
} from "@/views/lowCode/dashboard/dashboard-util";
import { getColorConfigStyle } from "@/views/lowCode/view/components/chartUtil";
import VueGridLayout from "vue-grid-layout";
import chartComponent from "@/views/lowCode/dashboard/chart-component.vue";
import dashboardFilterSetting from "@/views/lowCode/dashboard/dashboard-filter-setting/dashboard-filter-setting.vue";
import dashboardFreeText from "@/views/lowCode/dashboard/add-component/dashboard-free-text/dashboard-free-text.vue";
import dashboardCountDown from "@/views/lowCode/dashboard/add-component/dashboard_count_down/dashboard_count_down.vue";
import dashboardTopic from "@/views/lowCode/dashboard/add-component/dashboard_topic/dashboard_topic.vue";
import dashboardCarouselEdit from "@/views/lowCode/dashboard/add-component/dashboard-carousel/dashboard-carousel-edit.vue";
import DashboardButtonGroupEdit from "@/views/lowCode/dashboard/add-component/dashboard-button-group/dashboard-button-group-edit.vue";
import DashboardIncludeEdit from "@/views/lowCode/dashboard/add-component/dashboard-include/dashboard-include-edit.vue";
import ganttEdit from "@/views/lowCode/view/ganttEdit.vue";
import chartEdit from "@/views/lowCode/view/chartEdit.vue";
import { isEmpty } from "@/zgg-core/relyUtil";
export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    chartComponent,
    dashboardFilterSetting,
    dashboardFreeText,
    dashboardCountDown,
    dashboardTopic,
    dashboardCarouselEdit,
    DashboardButtonGroupEdit,
    DashboardIncludeEdit,
    ganttEdit,
    chartEdit,
  },
  computed: {
    currentKey: {
      get() {
        return this.current;
      },
      set(val) {
        this.$emit("update:current", val);
      },
    },
  },
  data() {
    return {
      isResizing: false,
      minW: MIN_W,
      minH: MIN_H,
      maxH: MAX_H,
      colNumber: COL_NUM,
      layoutMargin: [10, 10],
      rowHeight: ROW_HEIGHT,
      isShowEdit: "",
      chart: null,
      dashboardFilterSettingVisible: false,
      dashboardFreeTextVisible: false,
      dashboardCountDownVisible: false,
      dashboardTopicVisible: false,
      dashboardCarouselVisible: false,
      dashboardIncludeVisible: false,
      dashboardButtonGroupVisible: false,
      showBody: false,
    };
  },
  props: {
    containers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    areaTree: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dataComponentList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    components: {
      type: Array,
      default: () => {
        return [];
      },
    },
    componentList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dashboard: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: Object,
      default: () => {
        return { key: "dashboard" };
      },
    },
    showColorConfig: {
      type: Function,
      default() {
        return () => "";
      },
    },
    current: {
      type: String,
      default: () => "",
    },
    filterComponentList: {
      type: Array,
      default: () => [],
    },
    calDashItemContainerStyle: {
      type: Function,
      default() {
        return () => "";
      },
    },
    getBlankPosition: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  methods: {
    save() {
      this.components.forEach((item) => {
        this.changePosition(item);
      });
    },
    changePosition(obj) {
      let clientHeight = this.$refs.flexBody.clientHeight;
      let clientWidth = this.$refs.flexBody.clientWidth;
      // 高度固定咯，就要取消top和height的百分比计算方式
      // 取消top百分比
      obj.top = parseFloat(
        obj.y * (this.rowHeight + this.layoutMargin[1]) + this.layoutMargin[1],
      );

      // 取消高度百分比
      // obj.height =
      //   ((obj.h * (this.rowHeight + this.layoutMargin[1]) -
      //     this.layoutMargin[1]) *
      //     100) /
      //     clientHeight +
      //   "%";
      obj.height = parseFloat(
        obj.h * (this.rowHeight + this.layoutMargin[1]) - this.layoutMargin[1],
      );
      obj.left =
        ((obj.x * ((clientWidth - this.layoutMargin[0]) / this.colNumber) +
          this.layoutMargin[0]) *
          100) /
          clientWidth +
        "%";
      obj.width =
        ((obj.w * ((clientWidth - this.layoutMargin[0]) / this.colNumber) -
          this.layoutMargin[0]) *
          100) /
          clientWidth +
        "%";
      return obj;
    },
    delItem(componentKey, event) {
      event.preventDefault();
      event.stopPropagation();
      let index = this.components.findIndex((row) => row.key == componentKey);
      this.components.splice(index, 1);
    },
    copyItem(item, event) {
      event.preventDefault();
      event.stopPropagation();
      let view = this._.cloneDeep(item);
      view.key = this.getUuid();
      view.extParam.title = "拷贝_" + view.extParam.title;
      this.currentKey = view.key;
      view = this.getBlankPosition(view, this.components);
      this.$set(this.components, this.components.length, view);
      this.$nextTick(() => {
        this.$emit("refreshChart", view, this.parent.key);
      });
    },
    editItem(currentComponent, parent, event) {
      event.preventDefault();
      event.stopPropagation();
      let { key, componentName } = currentComponent;
      this.currentKey = key;
      if (componentName === "dashboard_filter") {
        this.$nextTick(() => {
          this.$refs.dashboardFilterSetting.manualInit(currentComponent);
        });
      } else if (componentName === "html") {
        this.$nextTick(() => {
          this.$refs.dashboardFreeText.manualInit(currentComponent);
        });
      } else if (componentName === "count_down") {
        this.$nextTick(() => {
          this.$refs.dashboardCountDown.manualInit(currentComponent);
        });
      } else if (componentName === "title_view") {
        this.$nextTick(() => {
          this.$refs.dashboardTopic.manualInit(currentComponent);
        });
      } else if (componentName === "carousel_view") {
        this.$nextTick(() => {
          this.$refs.dashboardCarousel.manualInit(currentComponent);
        });
      } else if (componentName === "include") {
        this.$nextTick(() => {
          this.$refs.dashboardInclude.manualInit(currentComponent);
        });
      } else if (componentName === "button_group") {
        this.$nextTick(() => {
          this.$refs.dashboardButtonGroup.manualInit(currentComponent);
        });
      } else {
        this.chart = this._.cloneDeep(currentComponent);
        // 临时处理透视表旧数据，不存在列维度增补属性 后续后端会进行数据修复
        if (currentComponent.componentName == "pivot_table") {
          if (!this.chart.yFields) {
            this.chart.yFields = [];
          }
        }
      }
      this.showBody = true;
    },
    closeFun() {
      this.showBody = false;
      this.chart = null;
    },
    saveForm({ componentName, view, callback, review }) {
      let index = this.components.findIndex((row) => row.key == view.key);
      this.$set(this.components, index, this._.cloneDeep(view));
      this.$nextTick(() => {
        this.$emit("refreshChart", view, this.parent.key);
        if (typeof callback === "function") {
          callback();
        }
        if (review != "preview") {
          this.chart = null;
        }
      });
    },
    /**
     * 编辑之后保存过滤器
     * @param filterSettingModel
     */
    saveFilter(filterSettingModel) {
      let index = this.components.findIndex(
        (row) => row.key == filterSettingModel.key,
      );
      this.$set(this.components, index, filterSettingModel);
      this.$emit("refreshDashboard", filterSettingModel, "dashboard");
    },
    buildChart(view, dataFilter) {
      this.$nextTick(() => {
        let $el = this.$refs?.["chart_" + view.key]?.[0];
        if ($el && typeof $el.getData === "function") {
          $el.getData(view, dataFilter);
        }
      });
    },
    refreshDashboard(model) {
      this.$emit("refreshDashboard", model, "dashboard");
    },
    clearFilterCompsValue(keys) {
      keys.forEach((key) => {
        let $el = this.$refs["chart_" + key][0];
        $el.handleFieldQueryOperChange();
      });
    },
    includeComponent(componentName) {
      return [
        "dashboard_filter",
        "button_group",
        "html",
        "count_down",
        "gantt",
      ].includes(componentName);
    },
    //是否支持设置自动刷新
    hasAutoRefreshComponent(componentName) {
      return isChartComponent(componentName);
    },
    handleCommand(command) {
      let { container, component, tab } = command;
      if (container.componentName == "tab" && !tab) {
        return;
      }
      let arr = this.dashboard.components;
      if (container.key == "dashboard") {
        component = this.getBlankPosition(component, this.dashboard.components);
        arr.push(component);
      } else {
        let obj = arr.find((item) => item.key == container.key);
        if (!tab) {
          component = this.getBlankPosition(component, obj.components);
          obj.components.push(component);
        } else {
          let tabPane = obj.components.find((item) => item.key == tab.key);
          component = this.getBlankPosition(component, tabPane.components);
          tabPane.components.push(component);
        }
      }
      let index = this.components.findIndex((row) => row.key == component.key);
      this.components.splice(index, 1);
      let dataFilterSet = handleDataFilterSet(this.componentList);
      let chartDataFilter = dataFilterSet?.[component?.dataSource?.formId];
      let dataFilter = chartDataFilter?.[component.key];
      this.$nextTick(() => {
        this.$emit("refreshChart", component, container.key, dataFilter);
      });
    },
    isShowTitle(item) {
      return !(
        item.hiddenTitle ||
        item.componentName == "html" ||
        item.componentName == "title_view" ||
        item.componentName == "carousel_view" ||
        item.componentName == "include"
      );
    },
    /**
     * 计算仪表盘组件的容器背景色
     * @param item
     * @returns {{}}
     */
    colorConfigStyle(item) {
      let style = getColorConfigStyle(item);
      if (
        item.componentName == "title_view" ||
        (item.componentName == "carousel_view" &&
          this.parent.key != "dashboard")
      ) {
        style = {
          ...style,
          "background-color": "transparent",
        };
      }
      return style;
    },
    // 移入
    mouseOver(key) {
      this.isShowEdit = key;
    },
    // 移出
    mouseLeave() {
      this.isShowEdit = "";
    },
    /**
     * 全局唯一组件gridLayout的最小宽度枚举
     * @param componentName
     * @returns {*|number}
     */
    calcComponentMinWidth(componentName) {
      //最小宽度常量枚举
      const layoutGridMinWidth = {
        button_group: 3,
        dashboard: 4,
        tab: 4,
      };
      return layoutGridMinWidth[componentName] || this.minW;
    },
    /**
     * 全局唯一组件gridLayout的最小高度枚举
     * @param componentName
     * @returns {*|number}
     */
    calcComponentMinHeight(componentName) {
      //最小高度常量枚举
      const layoutGridMinHeight = {
        title_view: 2,
        html: 2,
      };
      return layoutGridMinHeight[componentName] || this.minH;
    },
    mousemove(e) {
      if (this.isResizing) {
        if (e.y > this.$refs?.flexBody?.clientHeight) {
          if (!this.timer) {
            this.timer = setTimeout(() => {
              this.$refs.flexBody.scrollTop += 20;
              this.timer = null;
            }, 50);
          }
        }
      }
    },
    resizeEvent(item) {
      let $el = this.$refs["chart_" + item.key]?.[0];
      if ($el?.resizeChart) {
        this.$nextTick(() => {
          $el.resizeChart();
        });
      }
      if (this.isResizing) {
        window.addEventListener("mousemove", this.mousemove);
      }
      this.isResizing = true;
    },
    resizedEvent(item) {
      let $el = this.$refs["chart_" + item.key]?.[0];
      if ($el?.resizeChart) {
        this.$nextTick(() => {
          $el.resizeChart();
        });
      }
      window.removeEventListener("mousemove", this.mousemove);
      this.isResizing = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-item-edit__wrap {
  width: 315px;
  font-size: 14px;
  line-height: 22px;
  color: #172b4d;
  .row:not(:last-child) {
    margin-bottom: 10px;
  }
}
.vue-grid-item.resizing {
  opacity: 1 !important;
}
.dash-item {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 4px;
  .dash-container-outLine {
    outline: solid 2px rgba($color: #1890ff, $alpha: 0.6);
  }

  .drag-right {
    width: 4px;
    position: absolute;
    right: 8px;
    top: 10px;
    bottom: 15px;
    cursor: ew-resize;
  }

  .drag-bottom {
    height: 4px;
    position: absolute;
    left: 10px;
    right: 15px;
    bottom: 8px;
    cursor: ns-resize;
  }

  .drag {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: nwse-resize;

    &::after {
      display: block;
      content: " ";
      width: 10px;
      height: 10px;
      border-color: #e0e0e0;
      border-style: solid;
      border-width: 0 1px 1px 0;
    }
  }

  .dash-container {
    width: 100%;
    height: 100%;
    //background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 2px 12px 12px 12px;
    border-radius: 4px;
    position: relative;

    &.active {
      outline: solid 2px rgba($color: #1890ff, $alpha: 0.6);
    }
  }

  .dash-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    .title {
      font-size: 16px;
      flex: 1;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba($color: #091e42, $alpha: 0.95);
      font-weight: 600;
    }
    i {
      cursor: pointer;
    }
  }
  .no-header {
    justify-content: flex-end;
    height: 20px;
    position: absolute;
    right: 2px;
    top: 2px;
  }
  .header-edit {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border-radius: 4px;
    padding: 2px;
    cursor: default;
    z-index: 999;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
    white-space: nowrap;
  }
  .el-dropdown {
    font-size: 16px;
    right: 0;
  }
  .dash-body {
    flex: 1;
    overflow: hidden;
    box-sizing: border-box;
  }
}
::v-deep .is-disabled {
  .el-dropdown-link {
    color: #bbb !important;
  }
}
::v-deep .el-dropdown-menu__item {
  padding: 0 10px;
}
</style>
<style lang="scss">
.vue-grid-item {
  touch-action: none;
}
.vue-grid-item.vue-grid-placeholder {
  background: var(--zgg-diver) !important;
}
.vue-grid-item > .vue-resizable-handle {
  z-index: 999;
}
</style>
