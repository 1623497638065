<template>
  <div class="addSoruce">
    <!-- <el-popover popper-class="pop-over" v-model="visible2">
      <div v-clickoutside="handleClose">
        <div
          v-for="item in components"
          :key="item.value"
          class="component-item"
          v-text="item.label"
          @mousedown="mousedown(item, $event)"
        ></div>
      </div> -->
    <!-- <el-tooltip
        slot="reference"
        effect="dark"
        content="长按拖动组件"
        placement="top"
      > -->
    <el-button size="small" @click="addData" class="btn-add">
      <i class="el-icon-plus"></i>&nbsp; 添加图表
      <!-- <i :class="visible2 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          ></i> -->
    </el-button>
    <!-- </el-tooltip> -->
    <!-- </el-popover> -->
    <el-dialog
      title="选择图表"
      append-to-body
      destroy-on-close
      :visible.sync="dialogVisible"
      width="904px"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        style="
          padding: 24px 48px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
        "
      >
        <!-- @mousedown="mousedown(item, $event)" -->
        <div
          @click="selectItem(item, $event)"
          v-for="item in components"
          :key="item.value"
          class="card"
          :class="actived == item.value ? 'active' : ''"
        >
          <div class="center">
            <img :src="item.img" alt="" />
          </div>
          <div class="bottom">
            <span class="title">{{ item.label }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" style="display: flex; justify-content: end">
        <el-button @click="cancelClose">取 消</el-button>
        <div class="sure-btn" @mousedown="mousedown(items, $event)">确 定</div>
        <!-- <el-button type="primary" @mousedown="mousedown(items, $event)">确 定</el-button> -->
      </div>
    </el-dialog>
    <data-source
      :visible.sync="visible"
      mode="table"
      :close-on-click-modal="false"
      @close="close"
      @nodeClick="nodeClick"
      @changeSource="saveForm"
      :menus="menus"
    >
      <div slot="top-header"></div>
    </data-source>
  </div>
</template>
<script>
import metric from "@/assets/chart/metric.png";
import column from "@/assets/chart/column.png";
import bar from "@/assets/chart/bar.png";
import line from "@/assets/chart/line.png";
import pie from "@/assets/chart/pie.png";
import table from "@/assets/chart/table.png";
import gantt from "@/assets/chart/gantt.png";
import map from "@/assets/chart/map.png";
import detail from "@/assets/chart/detail.png";
import radar from "@/assets/chart/radar.png";
import area from "@/assets/chart/area.png";
import process from "@/assets/chart/process.png";
import multi_axes from "@/assets/chart/multi_axes.png";
import { dirTree } from "../api";
import Clickoutside from "element-ui/src/utils/clickoutside";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import dataSource from "@/components/data-source";
export default {
  directives: { Clickoutside },
  components: { dataSource },
  props: {
    minWidth: Number,
    minHeight: Number,
  },
  data() {
    return {
      visible: false,
      title: "",
      formId: "",
      components: [
        {
          label: "指标",
          value: "metric_table",
          img: metric,
        },
        {
          label: "进度图",
          value: "chart_process",
          img: process,
        },
        {
          label: "透视图",
          value: "pivot_table",
          img: table,
        },
        {
          label: "明细表",
          value: "table",
          img: detail,
        },
        {
          label: "柱形图",
          value: "chart_column",
          img: column,
        },
        {
          label: "条形图",
          value: "chart_bar",
          img: bar,
        },
        {
          label: "折线图",
          value: "chart_line",
          img: line,
        },
        {
          label: "面积图",
          value: "chart_area",
          img: area,
        },
        {
          label: "双轴图",
          value: "chart_multi_axes",
          img: multi_axes,
        },
        {
          label: "饼图",
          value: "chart_pie",
          img: pie,
        },
        {
          label: "雷达图",
          value: "chart_radar",
          img: radar,
        },
        {
          label: "甘特图",
          value: "gantt",
          img: gantt,
        },
        {
          label: "地图",
          value: "chart_map",
          img: map,
        },
      ],
      formTitle: "",
      treeData: [],
      defaultProps: {
        children: "directoryList",
        label: "title",
        isLeaf: function (data, node) {
          return !data.hasChildList || data.dataType == "resource";
        },
      },
      dialogVisible: false,
      actived: "",
      items: {},
      events: "",
      menus: [
        "form",
        "dataflow",
        "aggregate_table",
        "group_form",
        "group_dataflow",
        "group_aggregate_table",
      ],
    };
  },
  created() {
    this.actived = "";
  },
  watch: {
    dialogVisible() {
      this.actived = "";
    },
  },
  destroyed() {
    this.visible = false;
    // this.visible2 = false;
  },
  methods: {
    addData() {
      this.dialogVisible = true;
    },
    selectItem(item, event) {
      this.items = item;
      this.events = event;
      this.actived = item.value;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    cancelClose() {
      this.dialogVisible = false;
    },
    nodeClick(data) {
      this.title = data.title;
      this.formId = data.id;
    },
    filterNode(value, data) {
      let { dataType, title } = data;
      if (title.indexOf(value) == -1) {
        return false;
      }
      return true;
    },
    search(val) {
      this.$refs.tree.filter(val);
    },
    handleNodeClick(data, node) {
      if (node.isLeaf) {
        this.formId = data.bizId;
        this.title = data.title;
      } else {
        this.formId = "";
      }
    },
    openTree() {
      dirTree({
        type: 10,
        parentId: 0,
        fullTree: true,
      }).then((res) => {
        this.treeData = res.data.directoryList;
      });
    },
    mousedown(item, event) {
      if (Object.keys(item).length == 0) {
        this.$message.warning("请选择图表");
        return false;
      }
      event.preventDefault();
      event.stopPropagation();
      if (event.buttons !== 1) {
        // 不是鼠标左键按下的则不执行一下操作
        return;
      }
      this.$emit("addElement", {
        view: {
          ...item,
        },
      });
      document.addEventListener("mouseup", this.mouseup, {
        passive: false,
      });
    },
    mouseup(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.interval) {
        clearInterval(this.interval.val);
        this.interval = null;
      }
      this.visible = true;
      document.removeEventListener("mouseup", this.mouseup);
    },
    close() {
      this.title = "";
      this.formId = "";
      // if (this.moveObj) {
      //   this.$emit("closeMove");
      // }
    },
    saveForm() {
      if (isEmpty(this.title)) {
        this.$message.error("请输入视图名称");
        return;
      }
      if (isEmpty(this.formId)) {
        this.$message.error("请选择数据源");
        return;
      }

      this.$emit("addComponents", {
        title: this.title,
        dataSource: {
          formId: this.formId,
          queryObject: null,
          sourceId: 0,
          sourceType: "form",
        },
      });
      // this.moveObj = null;
      this.visible = false;
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-add {
  color: #1890ff;
  border-color: #1890ff;
}
.node-wrap {
  line-height: 36px;
  height: 36px;
  font-size: 15px;
  vertical-align: middle;
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  flex: 1;
  align-items: center;
  position: relative;

  .node-label-wrap {
    flex: 1;
    width: 0;

    .node-label-content {
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
      line-height: 20px;
      color: rgba(9, 30, 66, 0.95);
    }
  }
}
.component-item {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    background-color: rgba($color: #1890ff, $alpha: 0.2);
  }
}

.card {
  // width: 778px;
  // height: 120px;
  border: 1px solid #ebecf0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 10px;
  padding: 10px;
  cursor: pointer;
  .center {
    img {
      width: 150px;
      height: 100px;
    }
  }
  .bottom {
    color: #172b4d;
    text-align: center;
    padding-top: 10px;
    .title {
      font-size: 16px;
    }
  }
}
.active {
  border: 1px solid #3870ea;
}
.sure-btn {
  text-align: center;
  padding: 8px 20px;
  width: 78px;
  height: 40px;
  line-height: 25px;
  background: #3870ea;
  border-radius: 4px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}
</style>
<style lang="scss">
.pop-over {
  padding: 0px !important;
}
</style>
