<template>
  <div>
    <div>
      <div ref="jsonForm" class="json-form">
        <div class="json-row json-head">
          <template v-if="isShowOprBtn">
            <div class="json-cell" style="width: 100px"></div>
            <div
              style="width: 100px"
              class="json-action"
              :style="`transform: translateX(${left}px);`"
            >
              操作
            </div>
          </template>

          <template v-for="col in componentList">
            <div
              :key="col.key"
              v-if="col.componentName == 'reference_data'"
              :style="`width:${componentWidth[col.key]}px;`"
              class="json-ref json-head-cell"
            >
              <div class="json-left-cell" :style="getLeftWidth(col)">
                <span
                  v-text="
                    col.columns && col.columns.length
                      ? ''
                      : col.title
                      ? col.title
                      : col.label
                  "
                ></span>
              </div>
              <div
                class="json-ref-cell"
                v-for="child in col.columns"
                :key="child.field.name"
                :style="`width:${
                  componentWidth[col.key + '.' + child.field.name]
                }px;`"
              >
                <i class="el-icon-connection"></i>
                <span v-text="child.title"></span>
              </div>
            </div>
            <div
              v-else
              :key="col.key"
              class="json-head-cell"
              :style="`width:${componentWidth[col.key]}px;`"
            >
              <span v-if="col.required" style="color: #f56c6c">*</span>
              <span v-text="col.title ? col.title : col.label"></span>
            </div>
          </template>
        </div>

        <draggable
          v-if="form[item.name] && form[item.name].length"
          class="json-body"
          :list="form[item.name]"
          animation="300"
          handle=".move"
        >
          <div
            v-for="(row, index) in form[item.name]"
            :key="index"
            class="json-row"
          >
            <template v-if="isShowOprBtn">
              <div class="json-cell" style="width: 100px"></div>
              <div
                class="json-action"
                style="width: 100px"
                :style="`transform: translateX(${left}px);`"
              >
                <el-button
                  style="padding: 8px; border: 0px; width: 30px; height: 30px"
                  icon="el-icon-rank"
                  class="move"
                ></el-button>
                <el-button
                  @click="deleteRow(index, form[item.name], item)"
                  style="
                    padding: 8px;
                    border: 0px;
                    width: 30px;
                    height: 30px;
                    margin-left: 0px;
                  "
                  icon="el-icon-delete"
                ></el-button>

                <el-popover
                  v-model="visibleObj[index]"
                  popper-class="pop-over"
                  width="150"
                  trigger="hover"
                  class="row-more"
                >
                  <el-button
                    slot="reference"
                    style="padding: 8px; border: 0px; width: 30px; height: 30px"
                    icon="el-icon-more"
                    :class="{ active: visibleObj[index] }"
                  ></el-button>
                  <!-- <i
                      slot="reference"
                      class="el-icon-more row-more"
                      :class="{ active: visibleObj[index] }"
                    ></i> -->
                  <div class="row-pop">
                    <div @click="copyRow(index, 'next')" class="row-item">
                      复制到下一行
                    </div>
                    <div @click="copyRow(index, 'last')" class="row-item">
                      复制到最后一行
                    </div>
                    <div @click="addRow(-1, index)" class="row-item">
                      向上插入一行
                    </div>
                    <div @click="addRow(1, index)" class="row-item">
                      向下插入一行
                    </div>
                  </div>
                </el-popover>
              </div>
            </template>

            <template v-for="col in componentList">
              <reference-data-view
                class="json-ref"
                :item="col"
                :form="getForm(row)"
                :componentList="item.components"
                :row="row"
                @changeFormData="changeFormData(index, $event)"
                :key="col.key"
                v-if="col.componentName == 'reference_data'"
                :inJsonForm="true"
                :style="`width:${componentWidth[col.key]}px;`"
                :columnsWidth="componentWidth"
                :disabled="isInitValue"
              ></reference-data-view>
              <div
                :key="col.key"
                class="json-row-cell"
                :style="`width:${componentWidth[col.key]}px;`"
                v-else
              >
                <form-item
                  :item="col"
                  :form="row"
                  :parentForm="form"
                  :watchForm="getForm(row)"
                  :ref="`formItem_${col.name}_${index}`"
                  :source-list="sourceList"
                  :area-tree="areaTree"
                  :isJsonFormItem="true"
                  :isInitValue="true"
                  :rowIndex="index"
                  :parentName="item.name"
                  @changeFormData="changeFormData(index, $event)"
                  @getMetaOptions="getMetaOptions"
                ></form-item>
              </div>
            </template>
          </div>
        </draggable>
        <div v-else class="json-body">
          <div
            class="el-table__empty-text"
            style="text-align: center; width: 100%"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>

    <el-button
      style="margin-top: 10px"
      @click="addRow(0)"
      type="text"
      icon="el-icon-plus"
      v-if="isShowOprBtn"
      >添加
    </el-button>
  </div>
</template>
<script>
import dataLinkMixin from "./dataLinkMixin";
getTableColumnWidthByColumn;
import { isEmpty } from "@zgg-core-utils/relyUtil";
import Draggable from "vuedraggable";
import ReferenceDataView from "./ReferenceDataView";
import { getTableColumnWidthByColumn } from "@zgg-core-utils/utils";

export default {
  components: { Draggable, ReferenceDataView },
  inject: {
    isShow: {
      type: Function,
      default() {
        return () => true;
      },
    },
  },
  props: {
    isInitValue: {
      type: Boolean,
      default() {
        return false;
      },
    },
    roleList: {
      type: Array,
      default() {
        return [];
      },
    },
    accessControl: Boolean,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    formType: String,
    form: Object,
    item: Object,
    areaTree: Array,
    sourceList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [dataLinkMixin],
  data() {
    return {
      componentWidth: {},
      left: 0,
      visibleObj: [],
      isShowOprBtn: this.isShow(),
    };
  },
  computed: {
    componentList() {
      return this.item.components;
    },
  },
  created() {
    this.initValue();
    this.buildDataLink();
  },
  mounted() {
    this.$nextTick(() => {
      let componentWidth = {};
      let maxWidth = 0;
      this.$el.querySelectorAll(".json-head-cell").forEach((el, index) => {
        let obj = this.componentList[index];
        let clientWidth = el.clientWidth;
        let width = getTableColumnWidthByColumn(obj);
        width = parseFloat(width.replace("px"));

        if (obj.componentName == "reference_data") {
          let cWidth = el.querySelector(".json-left-cell").clientWidth;

          if (width < cWidth) {
            width = cWidth;
          }
          if (obj.columns && obj.columns.length) {
            width = 50;
          }
          // componentWidth[obj.key+'_header'] = cWidth;
          el.querySelectorAll(".json-ref-cell").forEach((ul, i) => {
            let sub = obj.columns[i];
            let clientWidth2 = ul.clientWidth;
            let width2 = getTableColumnWidthByColumn(sub.field);
            width2 = parseFloat(width2.replace("px"));
            if (clientWidth2 < width2) {
              clientWidth2 = width2;
            }
            width += clientWidth2;
            componentWidth[obj.key + "." + sub.field.name] = clientWidth2;
          });
        }

        if (clientWidth < width) {
          clientWidth = width;
        }

        componentWidth[obj.key] = clientWidth;
        maxWidth += clientWidth;
      });
      let clientWidth = this.$refs.jsonForm.clientWidth;

      clientWidth = clientWidth - 100;

      if (maxWidth < clientWidth) {
        Object.keys(componentWidth).forEach((key) => {
          let avgWidth = clientWidth * (componentWidth[key] / maxWidth);
          componentWidth[key] = avgWidth;
        });
      }
      this.componentWidth = componentWidth;

      this.$refs.jsonForm.addEventListener("scroll", () => {
        this.left = this.$refs.jsonForm.scrollLeft;
      });
      if (!this.form[this.item.name]) {
        return;
      }
      this.form[this.item.name].forEach((item, index) => {
        this.componentList.forEach((field) => {
          let $ref = this.$refs[`formItem_${field.key}_${index}`];
          if ($ref && $ref[0]) {
            $ref[0].destroyComponent();
          }
        });
      });
    });
  },
  beforeDestroy() {
    if (this.form[this.item.name]) {
      this.form[this.item.name].forEach((item, index) => {
        this.componentList.forEach((field) => {
          let $ref = this.$refs[`formItem_${field.key}_${index}`];
          if ($ref && $ref[0]) {
            $ref[0].destroyComponent();
          }
        });
      });
    }
  },

  methods: {
    getTableColumnWidthByColumn,
    getLeftWidth(obj) {
      let width = this.componentWidth[obj.key];
      let totalWidth = 0;
      obj.columns.forEach((item) => {
        totalWidth += this.componentWidth[obj.key + "." + item.field.name];
      });

      return `width:${width - totalWidth}px;`;
    },
    initValue() {
      if (this.formType == "add") {
        if (!isEmpty(this.item.defaultValue)) {
          this.$emit("changeFormData", {
            name: this.item.name,
            value: this._.cloneDeep(this.item.defaultValue),
          });
          return;
        }
      }
      if (isEmpty(this.form[this.item.name])) {
        this.$emit("changeFormData", {
          name: this.item.name,
          value: [],
          type: "init",
        });
      }
    },
    getForm(row) {
      let form = this._.cloneDeep(this.form);

      for (const [key, value] of Object.entries(row)) {
        form[key] = value;
      }
      return form;
    },
    changeFormData(index, { name, value, type, callback }) {
      let list = this._.cloneDeep(this.form[this.item.name]);
      list[index][name] = value;
      this.$emit("changeFormData", {
        name: this.item.name,
        value: list,
        type,
        callback,
      });
    },
    getMetaOptions(data) {
      this.$emit("getMetaOptions", data);
    },
    deleteRow(index, list) {
      this.item.components.forEach((field) => {
        let $ref = this.$refs[`formItem_${field.key}_${index}`];
        if ($ref && $ref[0]) {
          $ref[0].destroyComponent();
        }
      });

      let list2 = this._.cloneDeep(list);

      list2.splice(index, 1);
      this.$emit("changeFormData", {
        name: this.item.name,
        value: list2,
      });
    },
    copyRow(rowIndex, type) {
      this.$set(this.visibleObj, rowIndex, false);
      let list = this._.cloneDeep(this.form[this.item.name]);
      let obj = this._.cloneDeep(list[rowIndex]);
      if (type == "next") {
        list.splice(rowIndex + 1, 0, obj);
      } else {
        list.push(obj);
      }
      this.$emit("changeFormData", {
        name: this.item.name,
        value: list,
      });
      this.$nextTick(() => {
        let index2 = this.form[this.item.name].length - 1;
        this.componentList.forEach((field) => {
          let $ref = this.$refs[`formItem_${field.key}_${index2}`];
          if ($ref && $ref[0]) {
            $ref[0].destroyComponent();
          }
        });
      });
    },
    addRow(index, rowIndex) {
      this.$set(this.visibleObj, rowIndex, false);
      let obj = {};

      let list = this._.cloneDeep(this.form[this.item.name]);
      if (!list) {
        list = [];
      }

      if (index == -1) {
        list.splice(rowIndex, 0, obj);
      } else if (index == 1) {
        list.splice(rowIndex + 1, 0, obj);
      } else {
        list.push(obj);
      }

      this.$emit("changeFormData", {
        name: this.item.name,
        value: list,
      });
      this.$nextTick(() => {
        let index2 = this.form[this.item.name].length - 1;
        this.componentList.forEach((field) => {
          let $ref = this.$refs[`formItem_${field.key}_${index2}`];
          if ($ref && $ref[0]) {
            $ref[0].destroyComponent();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.json-form {
  overflow: auto;
}
.json-row {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  position: relative;
  width: max-content;
  &:last-child {
    border-bottom: 0px;
  }
}

.json-head {
  border-bottom: 1px solid #ebeef5;
  background: #f5f7fa;
  color: rgba(9, 30, 66, 0.95);
  text-align: center;
  box-sizing: border-box;
  .json-action {
    background: #f5f7fa;
  }
}
.json-body {
  .json-action {
    background: #fff;
    display: flex;
  }
}
.json-head-cell,
.json-row-cell,
.json-cell,
.json-action {
  padding: 12px 10px;
  text-align: center;
  box-sizing: border-box;
}
.json-row-cell {
  .el-radio-group,
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
  }
  .el-date-editor .el-input {
    width: 180px;
  }
}
.json-ref {
  display: flex;
  width: max-content;
  padding: 0 !important;
}
.json-ref-cell,
.json-left-cell {
  padding: 12px 10px;
  text-align: center;
  box-sizing: border-box;
}
.json-left-cell {
  text-align: left;
}

.json-action {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 2;
}
.row-more {
  ::v-deep {
    .el-icon-more {
      transform: rotate(90deg);
    }
    .el-popover__reference-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  // padding: 2px 1px;
  .active {
    color: #409eff;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }
}
.row-pop {
  .row-item {
    line-height: 30px;
    font-size: 14px;
    padding: 0 10px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.3);
    }
  }
}
</style>
<style lang="scss">
.pop-over {
  padding: 0px !important;
}
</style>
