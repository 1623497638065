<template>
  <div style="height: 100%; position: relative">
    <tree-layout>
      <!-- 左侧树区 -->
      <div slot="tree" class="rpa-left">
        <el-form size="small" :model="searchForm" style="width: 100%">
          <el-form-item class="itemSearchForm" style="margin-bottom: 10px">
            <el-input
              prefix-icon="el-icon-search"
              placeholder="搜索"
              v-model="searchForm.title"
              style="width: 85%"
              @input="search"
            >
              <i
                v-if="searchForm.title"
                @click="clearTitle"
                slot="suffix"
                class="el-input__icon el-icon-circle-close"
                style="cursor: pointer"
              ></i>
            </el-input>

            <div class="rpa-tab">
              <div
                class="rpa-tab-item"
                :class="{ active: folderType == 31 }"
                @click="changeFolderType(31)"
              >
                主流程
              </div>
              <div
                class="rpa-tab-item"
                :class="{ active: folderType == 32 }"
                @click="changeFolderType(32)"
              >
                子流程
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 0px;
              "
            >
              <span style="font-size: 12px; color: #6b778c">所有智能助手</span>
              <el-dropdown>
                <i
                  class="el-icon-plus add"
                  style="
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 600;
                    padding: 5px;
                    border-radius: 5px;
                  "
                ></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native.stop="add(0)"
                    >新建智能助手</el-dropdown-item
                  >
                  <el-dropdown-item @click.native.stop="addFolder"
                    >新增文件夹</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-form-item>
        </el-form>
        <!-- 树 -->
        <tree
          ref="tree"
          :type="folderType"
          :key="folderType"
          @nodeClick="nodeClick"
          :currentNodeKey="currentNodeKey"
          :nodeKey="'id'"
        >
          <template slot="label" slot-scope="{ data, node }">
            <div :class="data.enable ? 'enable' : 'disable'">
              {{ data.enable ? "已启用" : "已停用" }}
            </div>
          </template>
          <template slot="more" slot-scope="{ data, node }">
            <div
              @click.stop
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
              "
            >
              <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
                <el-tooltip
                  effect="dark"
                  content="新建"
                  placement="top"
                  :open-delay="500"
                >
                  <i
                    class="el-icon-plus custom-icon"
                    style="cursor: pointer; font-weight: 700"
                  ></i>
                </el-tooltip>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native.stop="addFolder(data, node)">
                    添加子文件夹
                  </el-dropdown-item>
                  <el-dropdown-item @click.native.stop="add(data.id)">
                    添加智能助手2.0
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
                <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native.stop="renameDir(data, node)">
                    重命名
                  </el-dropdown-item>
                  <el-dropdown-item @click.native.stop="delDir(data, node)">
                    删除
                  </el-dropdown-item>
                  <el-dropdown-item @click.native.stop="moveTo(data, node)">
                    移动到
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native.stop="delDirForce(data, node)"
                  >
                    强制删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown trigger="click" v-if="data.dataType == 'resource'">
                <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native.stop="edit(data, node)">
                    重命名
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native.stop="copy(data, node)"
                    v-if="data.triggerType !== 'trigger_click'"
                  >
                    复制
                  </el-dropdown-item>
                  <!-- <el-dropdown-item @click.native.stop="switchEnable(data, node)">
                  {{ data.enable ? "停用" : "启用" }}
                </el-dropdown-item> -->
                  <el-dropdown-item @click.native.stop="showWorkflowData(data)">
                    智能助手数据
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click.native.stop="moveTo(data, node)"
                    v-if="data.triggerType !== 'trigger_click'"
                  >
                    移动到
                  </el-dropdown-item>
                  <el-dropdown-item @click.native.stop="del(data, node)">
                    删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </tree>
        <!-- 添加智能助手自动化 -->
        <el-dialog
          append-to-body
          destroy-on-close
          title="添加智能助手"
          :visible.sync="visible"
          width="560px"
          @closed="closed"
        >
          <div style="padding: 10px 20px">
            <el-form ref="form" :model="form" label-position="top">
              <el-form-item
                label="名称"
                prop="title"
                :rules="[{ required: true, message: '请输入名称' }]"
              >
                <el-input
                  ref="renameInput"
                  v-model="form.title"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="智能助手类型"
                :rules="[{ required: true, message: '请选择类型' }]"
              >
                <el-select
                  v-model="form.triggerType"
                  placeholder="请选择类型"
                  style="width: 100%"
                >
                  <template v-if="folderType == 31">
                    <el-option
                      label="数据变动触发"
                      value="trigger_form_data_change"
                    ></el-option>
                    <el-option
                      label="定时单次触发"
                      value="trigger_timer_once"
                    ></el-option>
                    <el-option
                      label="定时多次触发"
                      value="trigger_timer_cron"
                    ></el-option>
                    <el-option
                      label="按日期字段触发"
                      value="trigger_timer_field"
                    ></el-option>
                    <el-option
                      label="Webhook触发"
                      value="trigger_webhook"
                    ></el-option>
                  </template>
                  <el-option
                    v-else-if="folderType == 32"
                    label="子流程"
                    value="trigger_collection_subflow"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                v-if="folderType == 32"
                label="数据源表单"
                :rules="[{ required: true, message: '请选择表单' }]"
              >
                <el-input
                  :value="form.collectionTitle"
                  readonly
                  placeholder="请选择表单"
                  suffix-icon="el-icon-arrow-down"
                  @click.native="visibleForm = true"
                ></el-input>
              </el-form-item>
              <el-form-item label="说明">
                <el-input
                  type="textarea"
                  :rows="4"
                  v-model="form.remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div slot="footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="saveForm">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 移动端 -->
        <el-dialog
          title="移动"
          :visible.sync="folderSelectDialogVisible"
          width="520px"
          :before-close="handleBeforeCLose"
          class="folderSelectDialog"
          append-to-body
          destroy-on-close
        >
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 5px;
            "
          >
            <p>请选择目标文件夹：</p>
            <FolderSelect
              @change="handleFolderSelectChange"
              v-if="folderSelectDialogVisible"
              :type="this.folderType"
              :current-node="currenMoveNode"
            ></FolderSelect>
          </div>
        </el-dialog>
      </div>
      <transition slot="body">
        <router-view
          v-if="$route.path != '/lowCode/rpa'"
          ref="routerView"
          :key="$route.fullPath"
          @changeTree="changeTree"
          @changeTreeTitleRemark="changeTreeTitleRemark"
        />
      </transition>
    </tree-layout>
    <rpa-workflow-data ref="rpaFlowData"></rpa-workflow-data>
    <data-source
      :visible.sync="visibleForm"
      :menus="['form']"
      @changeSource="changeForm"
    ></data-source>

    <!-- 删除智能助手 -->
    <el-dialog :visible.sync="delVisible" width="560px" append-to-body>
      <div
        slot="title"
        style="
          display: flex;
          align-items: center;
          font-size: 18px;
          padding-right: 20px;
        "
      >
        <i
          class="el-icon-warning"
          style="color: #e6a23c; font-size: 22px; margin-right: 6px"
        ></i
        >确定要删除"{{ form.title }}"吗?
      </div>
      <div style="padding: 20px">该智能助手被引用，请谨慎操作！</div>
      <div slot="footer" class="dialog-footer">
        <el-link type="primary" :underline="false" @click="openReferenceDetail"
          >查看引用详情</el-link
        >
        <div>
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button type="primary" @click="finalDel">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 查看引用详情 -->
    <referenceDetail
      v-if="referenceVisible"
      :visible.sync="referenceVisible"
      :formId="form.id"
      :referenceTitle="form.title"
      :source="'robot'"
    ></referenceDetail>
    <!-- 修改名称、备注 -->
    <rpa-edit-robot
      ref="rpaEditRobot"
      @change="editRobotTitle"
    ></rpa-edit-robot>
  </div>
</template>
<script>
import treeLayout from "@/components/tree-layout";
import _ from "lodash";
import { dirDelete, dirMove, dirRegister, dirRename } from "../api";
import { referenceRemoveCheck } from "@/views/lowCode/form/api";
import tree from "@/views/lowCode/form/tree";
import {
  copyRobot,
  createCollectionTrigger,
  createRobot,
  delRobot,
  renameRobot,
} from "./api";
import { isEmpty } from "@zgg-core-utils/utils";
import FolderSelect from "@/views/lowCode/FolderSelect";
import rpaWorkflowData from "./rpa-workflow-data";
import dataSource from "@/components/data-source";
import referenceDetail from "@/views/lowCode/form/reference-detail";
import RpaEditRobot from "./rpa-edit-robot.vue";

export default {
  components: {
    treeLayout,
    tree,
    FolderSelect,
    rpaWorkflowData,
    dataSource,
    referenceDetail,
    RpaEditRobot,
  },
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.$refs.tree.reset();
        this.back(this.$route.query.id);
        this.searchForm.title = "";
      },
    },
  },
  data() {
    return {
      searchForm: {
        title: "",
      },
      folderType: 31, // 新建文件夹时，文件夹类型：流程自动化
      visible: false,
      form: {},
      currentActionDirId: 0,
      folderSelectDialogVisible: false,
      currenMoveNode: undefined,
      saveLoading: false,
      visibleForm: false,
      delVisible: false,
      referenceVisible: false,
      curRow: {},
    };
  },
  computed: {
    currentNodeKey() {
      return this.$route.query.id || "";
    },
  },
  methods: {
    changeForm({ tableName, tableTitle }) {
      this.$set(this.form, "collection", tableName);
      this.$set(this.form, "collectionTitle", tableTitle);
    },
    changeFolderType(folderType) {
      if (this.folderType == folderType) {
        return;
      }

      this.folderType = folderType;
    },
    changeTree({ id, enable }) {
      let $children = this.$refs.tree.$refs.tree.$children;
      let list = $children.map((item) => item.$options.propsData.node);
      let getNode = (children) => {
        let obj;
        for (let i = 0; i < children.length; i++) {
          if (children[i].data.bizId == id) {
            obj = children[i];
            break;
          }
          if (
            !obj &&
            children[i].childNodes &&
            children[i].childNodes.length > 0
          ) {
            obj = getNode(children[i].childNodes);
            if (obj) {
              break;
            }
          }
        }
        return obj;
      };

      let node = getNode(list);

      if (node) {
        node.data.enable = enable;
      }
    },
    changeTreeTitleRemark({ id, title }) {
      this.$refs.tree.$refs.tree.getNode(id).data.title = title;
    },
    showWorkflowData(data) {
      this.$refs.rpaFlowData.open(data.bizId, data.title);
    },
    closed() {
      this.form = {};
    },
    nodeClick(data, node) {
      let { dataType } = data;
      if (dataType == "resource") {
        this.editForm(data);
      }
    },
    editForm(row) {
      this.$router.push({
        path: "/lowCode/rpa/edit",
        query: { id: row.bizId },
      });
    },
    handleBeforeCLose() {
      this.folderSelectDialogVisible = false;
    },
    moveTo(data, node) {
      this.currenMoveNode = node;
      this.folderSelectDialogVisible = true;
    },
    /**
     * 处理移动到的相关逻辑
     * @param parentId
     */
    handleFolderSelectChange(parentId) {
      this.folderSelectDialogVisible = false;
      let { dataType, type, id, recordType, recordId } =
        this.currenMoveNode.data;
      let params = {
        id,
        parentId,
      };
      dirMove(params).then(async (resp) => {
        this.$refs.tree.$refs.tree.remove(this.currenMoveNode.data);
        this.currenMoveNode.data.parentId = parentId;
        this.$refs.tree.insertToWorkGroupTree(this.currenMoveNode.data);
      });
    },
    edit(data) {
      // this.$prompt("请输入新的名称", "重命名智能助手2.0", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputValue: data.title,
      //   inputValidator: (val) => {
      //     if (isEmpty(val)) {
      //       return "智能助手名称不能为空";
      //     }
      //   },
      // })
      //   .then(({ value }) => {
      //     renameRobot({
      //       robotId: data.bizId,
      //       title: value,
      //     }).then((resp) => {
      //       this.$refs.tree.$refs.tree.getNode(resp.data.bizDir.id).data.title =
      //         value;
      //       this.$message.success("保存成功");
      //     });
      //   })
      //   .catch(() => {});
      this.$refs.rpaEditRobot.open(data.bizId);
    },
    editRobotTitle({ id, title, robotId }) {
      this.$refs.tree.$refs.tree.getNode(id).data.title = title;
      if (
        this.$refs.routerView?.updateRobot &&
        robotId == this.$route.query.id
      ) {
        this.$refs.routerView.updateRobot();
      }
    },
    copy(row) {
      this.$prompt("请输入新智能助手名", "复制智能助手2.0", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.title + "-复制",
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            duplicateRobotId: row.bizId,
            title: value,
          };
          copyRobot(param).then(() => {
            this.$refs.tree.reset();
          });
        })
        .catch(() => {});
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back(currentBizId) {
      if (this.$route.query.id && this.$route.query.id == currentBizId) {
        let { matched } = this.$router.history.current;
        if (matched.length >= 2) {
          let parentPath = matched[matched.length - 2].path;
          this.$router.push({ path: parentPath });
        } else {
          this.$router.back();
        }
      }
    },
    async del(data) {
      this.$set(this, "form", {
        title: data.title,
        id: data.bizId,
      });
      this.curRow = data;
      let params = {
        collection: data.bizId,
        type: "robot",
      };
      // 删除表单前检查表单是否有被引用
      let checkResult = await referenceRemoveCheck(params);
      if (checkResult?.data?.beReferred) {
        // 有引用关系
        this.delVisible = true;
      } else {
        this.$confirm("确定要删除吗?", "提示").then(() => {
          delRobot(data.bizId).then((res) => {
            this.$message.success("删除成功");
            this.$set(this, "form", {});
            this.$refs.tree.$refs.tree.remove(data);
            this.back(data.bizId);
          });
        });
      }
    },
    // 存在引用关系时的删除
    finalDel() {
      delRobot(this.form.id)
        .then((res) => {
          this.$message.success("删除成功");
          this.$set(this, "form", {});
          this.delVisible = false;
          this.$refs.tree.$refs.tree.remove(this.curRow);
          this.back(this.form.id);
        })
        .catch((err) => {
          this.delVisible = false;
        });
    },
    // 查看引用详情
    openReferenceDetail() {
      this.referenceVisible = true;
    },
    renameDir(data, node) {
      this.$prompt("请输入新文件夹名", "重命名文件夹", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.title,
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            dirId: data.id,
            title: value,
          };
          dirRename(param).then((resp) => {
            this.$set(node.data, "title", value);
            this.$message.success("保存成功");
          });
        })
        .catch(() => {});
    },
    delDir(data, node) {
      this.$confirm("删除文件夹?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.directoryList && data.directoryList.length != 0) {
            this.$message.warning("不能删除非空文件夹");
            return;
          }
          dirDelete({
            dirId: data.id,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {});
    },
    delDirForce(data, node) {
      this.$confirm("强制删除目录，下面的全部目录和文件将被永久删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.id == 0) {
            //不允许误删除根目录
            return;
          }
          dirDelete({
            dirId: data.id,
            withSubs: true,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {});
    },
    saveForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (this.form.id) {
          // 重命名
        } else {
          // 新建智能助手
          this.saveLoading = true;
          let callback = (res) => {
            this.visible = false;
            this.$message.success("新增成功");
            this.$refs.tree.update(this.currentActionDirId);

            this.editForm(res.data.bizDir);
            this.$refs.tree.$refs.tree.setCurrentKey(res.data.bizDir.id);
          };
          if (this.folderType == 31) {
            createRobot({
              companyId: this.$store.getters.currentCompanyId,
              ...this.form,
              dirId: this.currentActionDirId,
            })
              .then(callback)
              .finally(() => {
                this.saveLoading = false;
              });
          } else if (this.folderType == 32) {
            createCollectionTrigger({
              title: this.form.title,
              collection: this.form.collection,
              collectionTitle: this.form.collectionTitle,
              dirId: this.currentActionDirId,
            })
              .then(callback)
              .finally(() => {
                this.saveLoading = false;
              });
          }
        }
      });
    },
    //添加智能助手2.0到根目录
    add(dirId) {
      this.title = "添加智能助手";
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.currentActionDirId = dirId;
      this.visible = true;
    },
    addFolder(data, node) {
      let parentId;
      if (data && data.id) {
        parentId = data.id;
      } else {
        parentId = 0;
      }
      this.$prompt("", "新建", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.addFolderByParent(parentId, value);
        })
        .catch((err) => {});
    },
    addFolderByParent(parentId = 0, title = "默认文件夹名") {
      let params = {
        parentId,
        title,
        type: this.folderType,
      };
      dirRegister(params).then((resp) => {
        this.$refs.tree.update(parentId);
      });
    },
    clearTitle() {
      this.reset();
    },
    reset() {
      this.searchForm.title = "";
      // this.$refs.tree.reset();
    },
    // 搜索防抖
    search: _.debounce(function () {
      if (!this.searchForm.title) this.$refs.tree.reset();
      if (this.searchForm.title) {
        this.searchForm.title = this.searchForm.title.trim();
        this.$refs.tree.search(this.searchForm.title);
      }
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
.rpa-tab {
  display: flex;
  align-items: center;
  .rpa-tab-item {
    flex: 1;
    padding: 5px 0;
    position: relative;
    text-align: center;
    cursor: pointer;
    &.active {
      color: #67c23a;
      cursor: not-allowed;
      &::after {
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 2px;
        width: 100%;
        background-color: #67c23a;
        content: " ";
        display: block;
      }
    }
  }
}
.rpa-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.demo-form-inline {
  ::v-deep {
    &.el-form-item {
      margin-bottom: 0;
    }
  }
}
.itemSearchForm {
  padding: 10px 0 0 10px;

  &::v-deep .el-input__inner {
    border-radius: 10px;
    background-color: #f4f5f7;
    border: none;
  }
}
::v-deep .el-dropdown {
  right: 10px;
}
.add:hover {
  background-color: rgb(225, 237, 248);
  color: #3490e6;
}

.enable {
  font-size: 12px;
  transform: scale(0.8);
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: content-box;
  border-radius: 51px;
  padding: 0 4px;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a;
}

.disable {
  font-size: 12px;
  transform: scale(0.8);
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: content-box;
  border-radius: 51px;
  padding: 0 4px;
  background-color: #fef0f0;
  border-color: #fde2e2;
  color: #f56c6c;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
