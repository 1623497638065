<template>
  <div class="filter-item">
    <!-- 组件源 -->
    <query-components
      :queryField="filterField"
      :fieldList="fieldList"
      :useDefaultFields="true"
      @changeField="changeField"
    ></query-components>
    <!-- 操作符 -->
    <el-select
      v-model="filterField.oper"
      size="small"
      style="margin-left: 10px; width: 100px"
      @change="changeOper"
      class="overflow"
    >
      <el-option
        v-for="opt in operOptions"
        :key="opt.value"
        :label="opt.label"
        :value="opt.value"
      >
      </el-option>
    </el-select>
    <div
      :key="filterField.oper"
      class="flex-query-item fdd"
      style="overflow: hidden; display: flex"
      v-if="!['nil', 'nnil'].includes(filterField.oper)"
    >
      <template
        v-if="!isAutoCustom && (isInWorkFlowConfig || isCurrentUserField)"
      >
        <el-popover
          popper-class="pop-over"
          placement="bottom-start"
          v-model="popverVisible"
          trigger="click"
        >
          <div slot="reference" class="weui el-prefix" style="width: 49px">
            <div v-if="filterField.valueType == 'tableField'" class="icon-form">
              A
            </div>
            <div
              v-else-if="
                filterField.valueType == 'currentUserField' &&
                isCurrentUserField
              "
              class="icon-form"
            >
              C
            </div>
            <i v-else class="el-icon-edit-outline icon-edit"></i>
            <i
              class="el-icon-caret-bottom"
              style="color: #91a1b7; margin-left: 2px"
            ></i>
          </div>
          <div style="padding: 5px 0">
            <div
              v-if="isCurrentUserField"
              @click="checkItemType('currentUserField')"
              class="weui icon-item"
            >
              <div class="icon-form">C</div>
              <div>当前用户</div>
            </div>
            <div @click="checkItemType('tableField')" class="weui icon-item">
              <div class="icon-form">A</div>
              <div>当前表单</div>
            </div>
            <div @click="checkItemType('custom')" class="weui icon-item">
              <i class="el-icon-edit-outline icon-edit"></i>
              <div>自定义</div>
            </div>
          </div>
        </el-popover>
      </template>
      <el-select
        v-model="filterField.value"
        size="small"
        style="width: 100%"
        v-if="filterField.valueType == 'tableField'"
      >
        <el-option
          v-for="opt in tableFields"
          :key="opt.name"
          :label="opt.title"
          :value="opt.name"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="filterField.value"
        size="small"
        style="width: 100%"
        v-else-if="filterField.valueType == 'currentUserField'"
      >
        <el-option
          v-for="opt in fieldOptions"
          :key="opt.name"
          :label="'当前用户.' + opt.title"
          :value="opt.name"
        >
        </el-option>
      </el-select>
      <template
        v-else-if="['position_input', 'address_input'].includes(componentName)"
      >
        <el-cascader
          v-if="filterField.oper != 'nil' && filterField.oper != 'nnil'"
          :options="getAreaList(filterField.key)"
          style="width: 100%"
          placeholder="请选择地址"
          :props="areaProps"
          :value="addrValue"
          @change="changeAddr(filterField, $event)"
          :key="filterField.oper"
          size="small"
        >
        </el-cascader>
      </template>
      <template v-else-if="componentName == 'date_picker'">
        <date-picker-interface
          v-model="filterField.value"
          placeholder="请选择日期"
          size="small"
          style="width: 100%"
          :picker="filterField.picker"
          :field="filterField"
          :hasNow="false"
        >
        </date-picker-interface>
      </template>
      <user-condition
        v-else-if="['user_list_select', 'user_select'].includes(componentName)"
        v-model="filterField.value"
        :oper="filterField.oper"
        style="width: 100%"
        :componentName="componentName"
        :valueContainsCurrentUser.sync="filterField.valueContainsCurrentUser"
      ></user-condition>
      <dept-condition
        v-else-if="
          ['department_list_select', 'department_select'].includes(
            componentName,
          )
        "
        v-model="filterField.value"
        :oper="filterField.oper"
        style="width: 100%"
        :componentName="componentName"
        :valueContainsCurrentDept.sync="filterField.valueContainsCurrentDept"
        :valueContainsCurrentDeptAndSub.sync="
          filterField.valueContainsCurrentDeptAndSub
        "
      ></dept-condition>
      <template v-else-if="componentName == 'cascade_data'">
        <el-cascader
          :value="cascadeValue"
          style="width: 100%"
          :options="cascadeOptions"
          :props="cascadeProps"
          @change="cascadeChange(filterField, $event)"
          size="small"
          :key="filterField.oper"
        ></el-cascader>
      </template>
      <template v-else>
        <template v-if="valueType == 'form'">
          <el-input
            v-if="isUseInput"
            v-model="filterField.value"
            placeholder=""
            size="small"
            :key="filterField.oper"
            style="width: 100%"
          ></el-input>
          <select-filter
            v-else
            :component="filterComponent"
            :valueSource="valueSource"
            v-model="filterField.value"
            :params="params"
            size="small"
            :oper="filterField.oper"
            style="width: 100%"
          ></select-filter>
        </template>
        <template v-else>
          <query-input
            v-model="filterField.value"
            :field="filterField"
            :componentList="fieldList"
            style="width: 100%"
          ></query-input>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import QueryComponents from "./QueryComponents";
import userCondition from "./userCondition";
import deptCondition from "./deptCondition";
import selectFilter from "./selectFilter";
import {
  advanceFields,
  getComponentQueryOper,
} from "@zgg-core-utils/whiteList";
import { mapState } from "vuex";
import {
  checkFieldAbility,
  operQuerys,
} from "@zgg-core-utils/component-white-list";
import { isEmpty } from "@zgg-core-utils/utils";
import { getFormdataTree } from "@/views/lowCode/form/api";

export default {
  components: { QueryComponents, userCondition, deptCondition, selectFilter },
  props: {
    fieldList: Array,
    filterField: Object,
    index: Number,
    level: Number,
    rel: String,
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    valueType: {
      type: String,
      default() {
        return "form";
      },
    },
    delFilter: Function,
    copyFilter: Function,
    changeGroup: Function,
  },
  inject: {
    isInWorkFlowConfig: {
      default: false,
    },
    sourceFormComponents: {
      default: () => [],
    },
    // 当前用户字段值(过滤组筛选)
    isCurrentUserField: {
      default: true,
    },
    getContactFormMetaFieldList: {
      default: () => [],
    },
    getCompanyId: {
      default: () => "",
    },
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    isAutoCustom() {
      const customOperArray = ["between", "dynamic"];
      return (
        this.filterField.oper && customOperArray.includes(this.filterField.oper)
      );
    },
    fieldColumns() {
      let componentList = this._.cloneDeep(this.fieldList);

      let list = [];

      let callback = (components, parent) => {
        components.forEach((item) => {
          if (item.container) {
            callback(item.components, item);
          } else if (item.componentName == "reference_data") {
            let obj = this._.cloneDeep(item);
            list.push(obj);
            if (!parent) {
              item.columns.forEach((col) => {
                let obj = this._.cloneDeep(col.field.component);
                // 关联数据的子表单字段不进入过滤条件
                if (
                  obj.componentName != "json_form" &&
                  advanceFields.includes(obj.componentName)
                ) {
                  obj.title = item.title + "." + col.title;
                  obj.collection = item.tableName;
                  obj.referenceName = item.name;
                  obj.parent = this._.cloneDeep(item);
                  list.push(obj);
                }
              });
            }
          } else if (item.form) {
            if (advanceFields.includes(item.componentName)) {
              let obj = this._.cloneDeep(item);
              if (parent && parent.componentName == "json_form") {
                obj.name = parent.name + "." + obj.name;
                obj.title = parent.title + "." + obj.title;
                obj.parent = this._.cloneDeep(parent);
              }
              list.push(obj);
            }
          }
        });
      };
      callback(componentList);

      return list;
    },
    addrValue() {
      let value = this.filterField.value;
      let arr = [];
      if (value) {
        if (value.provinceId) {
          arr.push(value.provinceId);
        }
        if (value.cityId) {
          arr.push(value.cityId);
        }
        if (value.countyId) {
          arr.push(value.countyId);
        }
      }
      return arr;
    },
    // 级联组件值
    cascadeValue() {
      let value = this.filterField.valueDetail ?? [];
      return value;
    },
    isUseInput() {
      let component = this.filterComponent;
      if (["like", "nlike"].includes(this.filterField.oper)) {
        return true;
      } else if (component) {
        let r =
          component.optionSource == "dataLinkage" ||
          (component.parent && component.parent.componentName == "form_data");
        return r;
      }
      return false;
    },
    componentName() {
      if (this.filterField.componentName) {
        return this.filterField.componentName;
      } else if (this.filterComponent) {
        return this.filterComponent.componentName;
      }

      return "";
    },
    filterComponent() {
      let obj = this.filterField;
      let item = this.fieldColumns.find((field) => {
        // item.name == key2
        if (obj.referenceName) {
          if (field.parent) {
            if (field.parent.componentName == "reference_data") {
              return (
                field.parent.name == obj.referenceName && field.name == obj.key
              );
            } else if (field.parent.componentName == "form_data") {
              return (
                field.parent.key == obj.referenceName && field.name == obj.key
              );
            }
          }
          return false;
        } else {
          return field.name == obj.key;
        }
      });

      console.log(item);

      if (item) {
        return item;
      }
      return null;
    },
    valueSource() {
      let queryComponent = this.filterComponent;
      if (
        queryComponent &&
        (queryComponent.optionSource == "metaField" ||
          queryComponent.collection)
      ) {
        return "table";
      }
      return "self";
    },
    operOptions() {
      let list = checkFieldAbility(
        { componentName: this.componentName },
        "oper",
      );
      if (this.componentName === "reference_data") {
        list = ["nil", "nnil"];
      }
      return operQuerys.filter((opt) => list.includes(opt.value));
    },
    fieldOptions() {
      let list = [];
      if (typeof this.getContactFormMetaFieldList === "function") {
        let contactFormMetaFieldList = this.getContactFormMetaFieldList();
        list = this.getFilterComponents(contactFormMetaFieldList);
      }
      return list;
    },
    tableFields() {
      let list = [];
      if (this?.sourceFormComponents?.length) {
        console.log(111111);
        list = this.getFilterComponents(this.sourceFormComponents);
      }
      return list;
    },
  },
  data() {
    return {
      dataFilter: null,
      popverVisible: false,
      relOptions: [
        {
          label: "或",
          value: "or",
        },
        {
          label: "且",
          value: "and",
        },
      ],
      contactFormMetaFieldList: [],
      cascadeOptions: [],
      cascadeProps: {
        label: "name",
        value: "id",
        checkStrictly: true,
      },
    };
  },
  created() {
    if (this.isCurrentUserField) {
      if (typeof this.getCompanyId === "function") {
        let companyId = this.getCompanyId();
        this.$set(this.filterField, "currentCompanyId", companyId);
      }
    }
    if (this.componentName === "reference_data") {
      this.filterField.oper = "nil";
    }
    // 级联组件
    if (this.componentName === "cascade_data") {
      this.getCascadeOptions();
    }
  },
  destroyed() {
    // if (this.unWatch) {
    //   this.unWatch();
    // }
  },
  methods: {
    // 根据组件类型获取匹配组件
    getFilterComponents(components) {
      let list = [];
      if (components.length) {
        let componentList = components.map((item) => item.component ?? item);
        // 匹配细则
        const passArray = ["form_data", "json_form"];
        let fieldColumns = this.getAdvanceValueList(
          componentList.filter(
            (item) => !passArray.includes(item.componentName),
          ),
        );
        let componentName = this.filterField.componentName;
        if (!componentName) {
          return [];
        }
        let c1 = ["input", "text_area", "radio_group", "select", "sn_input"];
        let c2 = [
          "select",
          "select_checkbox",
          "radio_group",
          "checkbox_group",
          "input",
        ];
        let checkboxComponents = ["select_checkbox", "checkbox_group"]; // 复选组件
        let oper = this.filterField.oper;
        fieldColumns.forEach((item) => {
          let obj = this._.cloneDeep(item);
          if (c1.includes(componentName)) {
            if (oper == "in" || oper == "nin") {
              if (
                item.componentName == "checkbox_group" ||
                item.componentName == "select_checkbox"
              ) {
                list.push(obj);
              }
            } else {
              if (c1.includes(item.componentName)) {
                list.push(obj);
              }
            }
          } else {
            if (item.componentName == componentName) {
              if (item.componentName == "cascade_data") {
                // 级联组件特殊处理： 必须同源组件才可填充
                if (item.tableName == this.filterField.tableName) {
                  list.push(obj);
                }
              } else {
                list.push(obj);
              }
            } else if (
              componentName == "user_list_select" &&
              item.componentName == "user_select"
            ) {
              list.push(obj);
              // 产品要求单行、多行文本可以填充到富文本
            } else if (
              (item.componentName == "text_area" ||
                item.componentName == "input") &&
              componentName == "html_input"
            ) {
              list.push(obj);
              // 过滤条件中选择下拉复选/多选框组，连接条件为“包含任意一个/同时包含”时，可选择下拉单、复选，单、多选框组，单行文本
            } else if (oper == "containAny" || oper == "contain") {
              if (checkboxComponents.includes(componentName)) {
                if (c2.includes(item.componentName)) {
                  list.push(obj);
                }
              }
            }
          }
        });
      }
      return list;
    },
    getAdvanceValueList(componentList) {
      let list = [];
      let callback = (components, parent) => {
        components.forEach((item) => {
          if (item.container) {
            callback(item.components, item);
          } else if (item.componentName == "reference_data") {
            if (!parent) {
              list.push(this._.cloneDeep(item));
            }
          } else if (item.form) {
            let obj = this._.cloneDeep(item);
            if (parent && parent.componentName == "json_form") {
              obj.title = parent.title + "." + obj.title;
              obj.parent = this._.cloneDeep(parent);
            }
            list.push(obj);
          }
        });
      };
      callback(componentList);
      return list;
    },
    checkItemType(valueType) {
      this.popverVisible = false;
      if (this.filterField.valueType == valueType) {
        return;
      }
      this.filterField.value = "";
      this.filterField.valueType = valueType;
    },
    initDataFilter() {
      if (isEmpty(this.filterField.queryChain)) {
        return;
      }
      let queryChain = this._.cloneDeep(this.filterField.queryChain);
      let dataFilter = {
        rel: "",
        empty: false,
        advanceQuery: [],
      };
      for (const [key, value] of Object.entries(queryChain)) {
        dataFilter.rel = key;
        dataFilter.advanceQuery = value;
        dataFilter.empty = isEmpty(value);
      }
      this.dataFilter = dataFilter;
      this.unWatch = this.$watch(
        "dataFilter",
        (val) => {
          let queryChain = {};
          queryChain[val.rel] = val.advanceQuery;

          this.$set(this.filterField, "queryChain", queryChain);
        },
        { deep: true },
      );
    },
    changeOper(val) {
      this.popverVisible = false;
      if (["in", "nin"].includes(this.filterField.oper)) {
        this.$set(this.filterField, "value", []);
      } else if (
        this.filterField.componentName == "date_picker" &&
        this.filterField.oper == "between"
      ) {
        this.$set(this.filterField, "value", ["", ""]);
      } else {
        this.$set(this.filterField, "value", "");
      }
      if (["between", "dynamic"].includes(this.filterField.oper)) {
        this.$set(this.filterField, "valueType", "custom");
      }
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }
      return obj;
    },
    getAreaList(key) {
      let list = [];
      let picker = "county";
      let field = this.fieldColumns.find((item) => item.name == key);
      if (field) {
        picker = field.picker;
      }

      if (picker == "address") {
        picker = "county";
      }

      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },

    changeField({ data, value }) {
      console.log(data, value);

      this.popverVisible = false;
      let obj = this.fieldColumns.find((item) => {
        // item.name == value
        let name = item.name;
        if (item.parent) {
          if (item.parent.componentName == "form_data") {
            name = item.parent.key + "." + item.name;
          } else if (item.parent.componentName == "reference_data") {
            name = item.parent.name + "." + item.name;
          }
        }
        if (item.componentName == "cascade_data") {
          item.picker = item.referenceFieldName;
          item.collection = item.tableName;
        }

        return name == value;
      });

      if (obj && data) {
        data.key = obj.name;
        data.componentName = obj.componentName;
        data.title = obj.title;
        let list = getComponentQueryOper(obj.componentName);
        if (this.componentName === "reference_data") {
          list = list.filter((row) => ["nil", "nnil"].includes(row.value));
        }
        data.oper = list[0].value;
        if (obj.referenceName) {
          data.referenceName = obj.referenceName;
        } else {
          data.referenceName = "";
        }
        if (obj.collection) {
          data.collection = obj.collection;
        } else {
          data.collection = "";
        }

        if (obj.picker) {
          data.picker = obj.picker;
        } else {
          data.picker = "";
        }

        if (obj.tableName) {
          data.tableName = obj.tableName;
        } else {
          data.tableName = "";
        }

        if (obj.referenceFieldName) {
          data.referenceFieldName = obj.referenceFieldName;
        } else {
          data.referenceFieldName = "";
        }
      }
      if (data) {
        data.value = "";
        data.valueDetail = [];
      }
    },
    // 构建级联组件数据
    buildTreeData(data) {
      let cascadeOptions = this._.cloneDeep(data.treeList);
      let buildData = (list) => {
        list.forEach((item) => {
          if (!item.hasChild) {
            delete item.children;
          } else {
            buildData(item.children);
          }
        });
      };
      if (cascadeOptions) {
        buildData(cascadeOptions);
        this.cascadeOptions = cascadeOptions;
      }
    },
    // 获取级联组件数据
    getCascadeOptions() {
      getFormdataTree({
        collection: this.params?.collection,
        fieldName: this.filterField.key,
      }).then((resp) => {
        this.buildTreeData(resp.data);
      });
    },
    // 级联组件切换
    cascadeChange(item, val) {
      this.$set(this.filterField, "valueDetail", val);
      if (val.length) {
        this.$set(item, "value", val[val.length - 1]);
      } else {
        this.$set(item, "value", "");
      }
    },
    changeAddr(item, value) {
      let obj = {};
      let provinceId = value[0];
      let cityId = value[1];
      let countyId = value[2];
      let provinceItem = this.areaTree.find((item) => item.id == provinceId);
      obj.provinceId = provinceId;
      obj.province = provinceItem.name;
      if (cityId) {
        let cityItem = provinceItem.children.find((item) => item.id == cityId);
        obj.cityId = cityId;
        obj.city = cityItem.name;
        if (countyId) {
          let countyItem = cityItem.children.find(
            (item) => item.id == countyId,
          );
          obj.countyId = countyId;
          obj.county = countyItem.name;
        }
      }
      this.$set(item, "value", obj);
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-item {
  display: flex;
  .font13 {
    font-size: 13px;
  }
  .mr {
    margin-right: 8px;
  }
  .tip-box {
    width: 55px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
}
.flex-query-item {
  flex: 1;
  margin-left: 10px;
  ::v-deep {
    .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.rel-sel {
  ::v-deep {
    .el-input__inner {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}
.weui {
  display: flex;
  align-items: center;
}
.el-prefix {
  border: 1px solid #dcdfe6;
  border-right: 0;
  height: 32px;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 49px;
}
.icon-form {
  font-size: 12px;
  font-weight: bold;
  border: solid 2px #91a1b7;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: #91a1b7;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);
}

.icon-edit {
  color: #91a1b7;
  font-size: 19px;
  font-weight: bold;
}
</style>
