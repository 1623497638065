import request from '@/utils/request'
import qs from "qs"

const RC = '/rc'

//---------------------表格模板目录接口-------------------------------------
/**
 *表格模板列表
 */
export function excelTemplateDirList(data) {
    return request({
        url: RC + '/excelTemplate/dir/list',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 *  表格模板保存
 */
export function excelTemplateDirSave(data) {
    return request({
        url: RC + '/excelTemplate/dir/save',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 *  表格模板详情
 */
export function excelTemplateDirEdit(data) {
    return request({
        url: RC + '/excelTemplate/dir/detail',
        method: 'POST',
        data: qs.stringify(data)
    })
}
/**
 *  表格模板删除
 */
export function excelTemplateDirDelete(data) {
    return request({
        url:  '/excelTemplate/dir/delete',
        method: 'POST',
        data: qs.stringify(data)
    })
}

//---------------------表格模板文件接口-------------------------------------
/**
 *  表格模板文件列表
 * @deprecated
 */
export function excelTemplateList(data) {
    return request({
        url: RC + '/excelTemplate/list',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 *  表格模板文件编辑
 */
export function excelTemplateEdit(data) {
    return request({
        url: RC + '/excelTemplate/detail',
        method: 'POST', data: qs.stringify(data)
    })
}

/**
 *  表格模板文件预览
 */
export function excelTemplatePreview(data) {
    return request({
        url: RC + '/excelTemplate/preview',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 *  表格模板文件保存
 */
export function excelTemplateSave(data) {
    return request({
        url: RC + '/excelTemplate/save',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 *  表格模板文件删除
 */
export function excelTemplateDelete(data) {
    return request({
        url:  '/excelTemplate/delete',
        method: 'POST',
        data
    })
}


/**
 *  平台模板库
 */
export function excelTemplateDirPlatformList(data) {
    return request({
        url: RC + '/excelTemplate/dir/platformList',
        method: 'POST',
        data: qs.stringify(data)
    })
}



/**
 *  模版树
 */
export function excelTemplateTree(data) {
    return request({
        url: '/excelTemplate/tree',
        method: 'POST',
        data
    })
}


/**
 *  模板文件创建
 */
export function excelTemplateCreate(data) {
    return request({
        url: '/excelTemplate/create',
        method: 'POST',
        data
    })
}
/**
 *  模板目录重命名
 */
export function excelTemplateDirRename(data) {
    return request({
        url: '/excelTemplate/dir/rename',
        method: 'POST',
        data
    })
}
/**
 *  模板目录创建
 */
export function excelTemplateDirCreate(data) {
    return request({
        url: '/excelTemplate/dir/create',
        method: 'POST',
        data
    })
}



/**
 *  模版树（非懒加载）
 */
export function excelTemplateFullTree(data) {
    return request({
        url: '/excelTemplate/fullTree',
        method: 'POST',
        data
    })
}
/**
 *  模板目录移动
 */
export function excelTemplateDirMove(data) {
    return request({
        url: '/excelTemplate/dir/move',
        method: 'POST',
        data
    })
}
/**
 *  模板文件移动
 */
export function excelTemplateMove(data) {
    return request({
        url: '/excelTemplate/move',
        method: 'POST',
        data
    })
}

/**
 * 创建看板
 */
export function createBulletin(data){
    return request({
        url:'/excelTemplate/createBulletin',
        method:'POST',
        data
    })
}
/**
 * 资源创建
 * dirId 目录ID
 * res 资源类型：10-表格 20-看板 30-表单
 * resId 资源主键ID
 * resName 资源名称
 */
export function resCreate(data){
    return request({
        url:'/res/create',
        method:'POST',
        data
    })
}
/**
 * 资源创建
 * dirId 目录ID
 * res 资源类型：10-表格 20-看板 30-表单
 * resId 资源主键ID
 * resName 资源名称
 */
export function resRename(data){
    return request({
        url:'/res/rename',
        method:'POST',
        data
    })
}

/**
 * 为平台模板-添加整个文件夹,从视图和表单模板库(bizDir)选择文件夹加人平台模板库(是不同表的操作)
 * ?dirId=目录ID?res=资源类型：30表单 40视图&selectBizDirId=选择的视图或表单模板文件夹ID
 */
export function resAddSelectionBizDir(data){
    return request({
        url:'/res/addSelectionBizDir',
        method:'POST',
        data
    })
}


