import { getAreaTree } from "@/api/contact";

export default {
  namespaced: true,
  state: {
    areaTree: [],
    areaProps: {
      label: "name",
      value: "id",
      leaf: "hasChild",
      checkStrictly: true,
    },
  },
  actions: {
    getAreaTreee({ commit }) {
      getAreaTree().then((res) => {
        // 注意：
        // 如果 res 为 undefined，JSON.stringify(res) 返回是 'undefined' 字符串，
        // 但是 JSON.parse('undefined') 和 JSON.parse(undefined) 都会抛出异常。
        // 而 JSON.parse(null) 则不会抛出异常，返回值为 null。
        localStorage.setItem("AREA_TREE", JSON.stringify(res ?? null));
        commit("SET_AREA_TREE", res.data.tree);
      });
    },
  },
  mutations: {
    SET_AREA_TREE: (state, tree) => {
      state.areaTree = tree;
    },
  },
};
