<template>
  <div>
    <el-input
      readonly
      @click.native="showSource = true"
      placeholder="请配置数据源"
      size="mini"
      :value="title"
    ></el-input>
    <el-dialog
      custom-class="aggregate-dialog"
      title="数据来源"
      :visible.sync="showSource"
      width="600px"
      @open="open"
    >
      <div style="margin: 0 20px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="单表数据" name="sign">
            <div class="tab-content">
              <div class="text-label">数据表</div>
              <el-popover
                placement="bottom-start"
                width="560"
                v-model="signVisible"
              >
                <div v-clickoutside="handleClose">
                  <div>
                    <el-input
                      size="small"
                      placeholder="搜索"
                      prefix-icon="el-icon-search"
                      v-model="signTitle"
                      @input="changeSignTitle"
                    ></el-input>
                  </div>
                  <div>
                    <el-collapse v-model="activeCollapse" accordion>
                      <el-collapse-item title="表单" name="表单">
                        <div
                          v-infinite-scroll="loadFormList"
                          :infinite-scroll-distance="10"
                          class="pop-scroll"
                          ref="signScroll"
                        >
                          <div
                            v-for="item in signPage.list"
                            :key="item.id"
                            v-text="item.title"
                            class="form-item"
                            :class="formId == item.id ? 'active' : ''"
                            @click="checkForm(item)"
                          ></div>
                          <div
                            v-loading="loadingSign"
                            element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading"
                            style="height: 20px"
                            class="loading"
                          ></div>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item title="集团表单" name="集团表单">
                        <template>
                          <!-- <el-collapse-item title="集团表单" name="4"> -->
                          <!-- <div class="tab-title1">集团表单</div> -->
                          <div
                            style="
                              padding: 0 10px;
                              max-height: 400px;
                              min-height: 200px;
                              overflow: auto;
                            "
                          >
                            <el-collapse accordion>
                              <el-collapse-item
                                :title="mainItem.companyName"
                                :name="mainItem.companyId"
                                v-for="(mainItem, index) in groupPage.list"
                                :key="index"
                              >
                                <div class="box-padding">
                                  <div
                                    v-for="item in mainItem.dataList"
                                    :key="item.id"
                                    v-text="item.title"
                                    :class="formId == item.id ? 'active' : ''"
                                    @click="checkForm(item)"
                                  ></div>
                                </div>
                                <!-- <el-tree
                                  node-key="id"
                                  :props="props"
                                  highlight-current
                                  :current-node-key="formId"
                                  :data="item.dataList"
                                  @node-click="checkForm"
                                >
                                </el-tree> -->
                              </el-collapse-item>
                            </el-collapse>
                            <div
                              v-if="
                                !groupTableLoading && groupPage.list.length == 0
                              "
                              v-loading="groupTableLoading"
                              element-loading-text="加载中"
                              element-loading-spinner="el-icon-loading"
                              style="height: 100px"
                              class="loading"
                            >
                              <div class="noData">暂无数据</div>
                            </div>
                          </div>
                          <!-- </el-collapse-item> -->
                        </template>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </div>
                <el-input
                  size="small"
                  slot="reference"
                  readonly
                  placeholder="请选择数据源"
                  :value="formTitle"
                  class="text-hidden"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-arrow-down"
                  ></i>
                </el-input>
              </el-popover>
            </div>
          </el-tab-pane>
          <el-tab-pane label="多表关联" name="multi">
            <div class="tab-content">
              <div class="text-label">关联表</div>
              <el-popover
                placement="bottom-start"
                width="560"
                v-model="multiVisible"
                @hide="hideMultiVisible"
              >
                <div v-clickoutside="handleClose2">
                  <div>
                    <el-input
                      v-model="multiTitle"
                      size="small"
                      placeholder="搜索"
                      prefix-icon="el-icon-search"
                      @input="changeMultiTitle"
                    ></el-input>
                  </div>
                  <div>
                    <el-collapse v-model="activeCollapse" accordion>
                      <el-collapse-item title="表单" name="表单">
                        <div
                          v-infinite-scroll="loadMultiList"
                          :infinite-scroll-distance="10"
                          class="pop-scroll"
                          ref="multiScroll"
                        >
                          <el-checkbox-group v-model="collections">
                            <el-checkbox
                              v-for="item in multiPage.list"
                              :label="item.id"
                              :key="item.id"
                              class="vertical-chk"
                              @change="checkMulteForm(item, $event)"
                              >{{ item.title }}</el-checkbox
                            >
                          </el-checkbox-group>
                          <div
                            v-loading="loadingSign"
                            element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading"
                            style="height: 20px"
                            class="loading"
                          ></div>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item title="集团表单" name="集团表单">
                        <template>
                          <!-- <el-collapse-item title="集团表单" name="4"> -->
                          <!-- <div class="tab-title1">集团表单</div> -->
                          <div
                            style="
                              padding: 0 10px;
                              max-height: 400px;
                              min-height: 200px;
                              overflow: auto;
                            "
                          >
                            <el-collapse accordion>
                              <el-collapse-item
                                :title="mainItem.companyName"
                                :name="mainItem.companyId"
                                v-for="(mainItem, index) in groupPage.list"
                                :key="index"
                              >
                                <el-checkbox-group
                                  class="box-padding"
                                  v-model="collections"
                                >
                                  <el-checkbox
                                    v-for="item in mainItem.dataList"
                                    :label="item.id"
                                    :key="item.id"
                                    class="vertical-chk"
                                    @change="checkMulteForm(item, $event)"
                                    >{{ item.title }}</el-checkbox
                                  >
                                </el-checkbox-group>
                              </el-collapse-item>
                            </el-collapse>
                            <div
                              v-if="
                                !groupTableLoading && groupPage.list.length == 0
                              "
                              v-loading="groupTableLoading"
                              element-loading-text="加载中"
                              element-loading-spinner="el-icon-loading"
                              style="height: 100px"
                              class="loading"
                            >
                              <div class="noData">暂无数据</div>
                            </div>
                          </div>
                          <!-- </el-collapse-item> -->
                        </template>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </div>
                <el-input
                  size="small"
                  slot="reference"
                  readonly
                  placeholder="请选择关联表"
                  :value="multiFormTitle"
                  class="text-hidden"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-arrow-down"
                  ></i>
                </el-input>
              </el-popover>

              <div class="text-label" style="margin-top: 20px">
                请选择表单字段建立关联
              </div>
              <div v-if="collentionMap.length" class="collection-container">
                <el-table
                  row-key="key"
                  @selection-change="selectionChange"
                  :data="tableData"
                  class="table"
                  height="230"
                  border
                >
                  <el-table-column type="selection" width="40" label="" fixed>
                  </el-table-column>
                  <el-table-column
                    v-for="(item, index) in collentionMap"
                    :key="item.id"
                    width="212"
                  >
                    <template slot="header">
                      <div
                        :title="item.title"
                        v-text="item.title"
                        class="text-overflow"
                      ></div>
                    </template>
                    <template slot-scope="{ row }">
                      <el-select
                        v-if="formSourceMap[item.id]"
                        size="mini"
                        placeholder=""
                        v-model="row.value[index].fieldName"
                        @change="changeField(row.value[index], item.id, $event)"
                      >
                        <el-option
                          v-for="field in formSourceMap[item.id]"
                          :key="field.name"
                          :label="field.title"
                          :value="field.name"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" width="40">
                    <template slot-scope="scope">
                      <i
                        @click="deleteRow(scope.$index)"
                        class="el-icon-delete"
                      ></i>
                    </template>
                  </el-table-column>
                </el-table>
                <div>
                  <el-button @click="addRow" type="text" icon="el-icon-plus"
                    >添加</el-button
                  >
                  <el-button
                    @click="batchDelete"
                    :disabled="checkRows.length == 0"
                    type="text"
                    icon="el-icon-delete"
                    >批量删除</el-button
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer">
        <el-button size="small" @click="showSource = false">取 消</el-button>
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { uuid } from "@zgg-core-utils/utils";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { fetchFormList, groupFormList } from "@/views/lowCode/form/api";
export default {
  directives: { Clickoutside },
  props: {
    unionCollections: {
      type: Array,
      default() {
        return [];
      },
    },
    relations: {
      type: Array,
      default() {
        return [];
      },
    },
    formComponents: Object,
  },
  data() {
    return {
      showSource: false,
      signVisible: false,
      activeName: "sign",
      loadingSign: false, // 加载loading提示
      loadingMulti: false,
      signPage: {
        pageSize: 20,
        pageNumber: 0,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
      signTitle: "",
      multiVisible: false,
      multiPage: {
        pageSize: 20,
        pageNumber: 0,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
      multiTitle: "",
      formId: "",
      collections: [],
      formTitle: "",
      collentionMap: [],
      tableData: [],
      checkRows: [],
      groupPage: {
        list: [],
      },
      groupTableLoading: false,
      activeCollapse: "表单",
      props: {
        label: "title",
        isLeaf: "leaf",
        id: "id",
      },
    };
  },
  computed: {
    multiFormTitle() {
      return this.collentionMap.map((item) => item.title).join(",");
    },
    title() {
      return this.unionCollections.map((item) => item.title).join(",");
    },
    formSourceMap() {
      if (isEmpty(this.formComponents)) {
        return {};
      }
      let map = {};
      for (let [key, value] of Object.entries(this.formComponents)) {
        let list = [];
        // 临时先过滤掉子表单
        value.components
          .filter((item) => checkFieldAbility(item, "inAggregateHeader"))
          .forEach((item) => {
            if (item.componentName == "json_form") {
              let components = item.components.filter((c) =>
                checkFieldAbility(c, "inAggregateHeader"),
              );
              components.forEach((child) => {
                let obj = this._.cloneDeep(child);
                obj.title = item.title + "." + child.title;
                obj.name = item.name + "." + child.name;
                list.push(obj);
              });
            } else {
              list.push(this._.cloneDeep(item));
            }
            map[key] = list;
          });
      }
      return map;
    },
  },
  watch: {
    activeName() {
      this.fetchGroupFormList();
    },
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      this.collections = [];
      this.collentionMap = [];
      if (this.unionCollections) {
        this.activeName = "sign";
        if (this.unionCollections.length == 1) {
          this.formId = this.unionCollections[0].collection;
          this.formTitle = this.unionCollections[0].title;
          // this.title = this.formTitle;
        } else {
          if (this.unionCollections.length > 1) {
            this.activeName = "multi";
          }
          this.unionCollections.forEach((item) => {
            this.collections.push(item.collection);
            this.collentionMap.push({
              id: item.collection,
              title: item.title,
            });
          });
          // this.title = this.multiFormTitle;
        }
      }

      if (this.relations) {
        let tableData = [];
        this.relations.forEach((item) => {
          tableData.push({
            key: uuid(),
            value: this._.cloneDeep(item),
          });
        });

        this.$set(this, "tableData", tableData);
      }
    },
    batchDelete() {
      if (this.checkRows.length == 0) {
        return;
      }
      // [].includes
      this.tableData = this.tableData.filter(
        (item) => !this.checkRows.includes(item.key),
      );
    },
    selectionChange(rows) {
      let checkRows = rows.map((item) => item.key);
      this.$set(this, "checkRows", checkRows);
    },
    deleteRow(index) {
      this.$delete(this.tableData, index);
    },
    changeField(field, collection, value) {
      let components = this.formSourceMap[collection];
      let obj = components.find((item) => item.name == value);
      let title = obj.title;
      field.fieldTitle = title;
    },
    hideMultiVisible() {
      console.log("hideMultiVisible");
      this.collections.forEach((val) => {
        if (!this.formSourceMap[val]) {
          this.$emit("getComponents", val);
        }
      });
    },
    addRow() {
      let arr = [];
      this.collentionMap.forEach((item) => {
        arr.push({
          collection: item.id,
          fieldName: "",
          fieldTitle: "",
        });
      });

      this.tableData.push({
        key: uuid(),
        value: arr,
      });
    },
    save() {
      if (this.activeName == "sign") {
        // 单表
        if (isEmpty(this.formId)) {
          this.$message.error("请选择数据源");
          return;
        }
        this.$emit("update:unionCollections", [
          { collection: this.formId, title: this.formTitle },
        ]);
        this.$emit("update:relations", []);
        this.showSource = false;
        this.$emit("getComponents", this.formId);
      } else {
        // 多表关联
        if (this.collections.length == 0) {
          this.$message.error("请选择关联表");
          return;
        }
        if (this.tableData.length == 0) {
          this.$message.error("请建立表单间的联系");
          return;
        }
        let isError = false;
        this.tableData.forEach((item) => {
          item.value.forEach((row) => {
            if (isEmpty(row.fieldName)) {
              isError = true;
            }
          });
        });
        if (isError) {
          this.$message.error("请完善表单间的联系");
          return;
        }

        let collections = [];
        this.collentionMap.forEach((item) => {
          collections.push({
            collection: item.id,
            title: item.title,
          });
        });
        let relations = [];
        this.tableData.forEach((item) => {
          relations.push(this._.cloneDeep(item.value));
        });
        this.$emit("update:unionCollections", collections);
        this.$emit("update:relations", relations);
        this.showSource = false;
      }
      this.$emit("clearData");
    },
    checkForm(item) {
      this.formId = item.id;
      this.formTitle = item.title;
      this.signVisible = false;
    },
    checkMulteForm(item, value) {
      if (value) {
        this.collentionMap.push({
          id: item.id,
          title: item.title,
        });
      } else {
        let index = this.collentionMap.findIndex((obj) => obj.id == item.id);
        if (index >= 0) {
          this.collentionMap.splice(index, 1);
        }
      }
      this.tableData = [];
      this.checkRows = [];
    },
    handleClose() {
      this.signVisible = false;
    },
    handleClose2() {
      this.multiVisible = false;
    },
    open() {
      this.init();
      this.signPage.pageNumber = 0;
      this.signPage.totalRow = 0;
      this.signPage.totalPage = 0;
      this.multiPage.pageNumber = 0;
      this.multiPage.totalRow = 0;
      this.multiPage.totalPage = 0;
      this.checkRows = [];
      this.loadFormList();
      this.loadMultiList();
      this.fetchGroupFormList();
    },

    loadFormList() {
      if (this.loadingSign) {
        true;
      }
      if (
        this.signPage.pageNumber &&
        this.signPage.pageNumber >= this.signPage.totalPage
      ) {
        return;
      }
      this.loadingSign = true;
      fetchFormList({
        pageSize: this.signPage.pageSize,
        pageNumber: this.signPage.pageNumber + 1,
        title: this.signTitle,
        removeComponent: true,
      })
        .then((res) => {
          this.loadingSign = false;
          this.signPage.pageNumber = res.data.page.pageNumber;
          this.signPage.totalPage = res.data.page.totalPage;
          this.signPage.totalRow = res.data.page.totalRow;
          if (res.data.page.pageNumber == 1) {
            this.$set(this.signPage, "list", res.data.page.list);
          } else {
            this.signPage.list = this.signPage.list.concat(res.data.page.list);
          }
        })
        .catch(() => {
          this.loadingSign = false;
        });
    },
    loadMultiList() {
      if (this.loadingMulti) {
        return;
      }
      if (
        this.multiPage.pageNumber &&
        this.multiPage.pageNumber >= this.multiPage.totalPage
      ) {
        return;
      }

      this.loadingMulti = true;

      fetchFormList({
        pageSize: this.multiPage.pageSize,
        pageNumber: this.multiPage.pageNumber + 1,
        title: this.multiTitle,
        removeComponent: true,
      })
        .then((res) => {
          this.loadingMulti = false;
          this.multiPage.pageNumber = res.data.page.pageNumber;
          this.multiPage.totalPage = res.data.page.totalPage;
          this.multiPage.totalRow = res.data.page.totalRow;
          if (res.data.page.pageNumber == 1) {
            this.$set(this.multiPage, "list", res.data.page.list);
          } else {
            this.multiPage.list = this.multiPage.list.concat(
              res.data.page.list,
            );
          }
        })
        .catch(() => {
          this.loadingMulti = false;
        });
    },
    changeMultiTitle() {
      if (this.timerMulti) {
        clearTimeout(this.timerMulti);
      }

      this.timerMulti = setTimeout(() => {
        this.multiPage.pageNumber = 0;
        this.multiPage.totalRow = 0;
        this.multiPage.totalPage = 0;
        this.loadMultiList();
        this.fetchGroupFormList(this.multiTitle);
      }, 500);
    },
    changeSignTitle() {
      if (this.timerSign) {
        clearTimeout(this.timerSign);
      }
      this.timerSign = setTimeout(() => {
        this.signPage.pageNumber = 0;
        this.signPage.totalRow = 0;
        this.signPage.totalPage = 0;
        this.loadFormList();
        this.fetchGroupFormList(this.signTitle);
      }, 500);
    },
    // 集团表单

    fetchGroupFormList(title) {
      // if (!this.menus.includes("group_form")) {
      //   // 不存在form
      //   return;
      // }
      console.log(title, "获取绝对是=-====");
      let searchTitle = "";
      if (title) {
        searchTitle = title;
      }
      this.groupPage.list = [];
      this.groupTableLoading = true;
      let postData = {
        title: searchTitle,
      };
      groupFormList(postData)
        .then((res) => {
          this.groupTableLoading = false;
          this.groupPage.list = res.data.companyList;
          this.groupPage.list.forEach((item) => {
            item.dataList.forEach((sub) => {
              sub.leaf = this.mode == "table";
              sub.sourceType = "group_form";
            });
          });
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.aggregate-dialog {
  .el-dialog__header {
    // padding-top: 10px;
    border-bottom: solid 1px #e6e6e6;
  }
  .el-dialog__headerbtn {
    //   top: 10px;
  }
}
</style>
<style lang="scss" scoped>
.tab-content {
  min-height: 400px;
}
.pop-scroll {
  max-height: 320px;
  overflow: auto;
  margin-top: 10px;

  .empty-text {
    font-size: 12px;
    text-align: center;
    line-height: 28px;
  }
}
.loading {
  ::v-deep {
    .el-loading-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      margin-top: 0%;
    }
  }
}
.form-item {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #409eff, $alpha: 0.05);
  }
  &.active {
    background-color: #409eff;
    color: #ffffff;
  }
}
.vertical-chk {
  display: block;
  height: 36px;
  line-height: 36px;
  margin-right: 0;
}
.text-hidden {
  ::v-deep {
    .el-input__inner {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.text-label {
  margin-bottom: 10px;
  font-size: 14px;
}
.text-overflow {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row-index {
  ::v-deep {
    .el-icon-delete {
      display: none;
      &:hover {
        .el-icon-delete {
          display: block;
        }
      }
    }
  }
}
.table {
  max-width: 100%;
  width: fit-content;
}

.noData {
  display: flex;
  min-height: 100px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
::v-deep {
  .el-collapse {
    border-top: 0px solid #f4f5f7;
    border-bottom: 0px solid #f4f5f7;
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
}
.box-padding {
  padding: 0 10px;
  max-height: 300px;
  overflow: auto;
}
</style>
