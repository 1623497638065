<template>
  <div class="rpa-codemirror-edit">
    <codemirror
      v-if="!disabled"
      ref="codeEdit"
      v-model="content"
      :options="cmOptions"
      @blur="onBlur"
      @cursorActivity="onCursorActivity"
      @ready="onCmReady"
    >
    </codemirror>
    <div
      class="vue-codemirror"
      v-else
      style="padding: 4px 8px"
      :style="!strHtml ? { height: '31px' } : ''"
    >
      <div style="line-height: 1.5" v-html="strHtml"></div>
    </div>
  </div>
</template>
<script>
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/display/autorefresh";
export default {
  props: {
    /** 编辑器高度 */
    textareaHeight: {
      type: String,
      default() {
        return "90px";
      },
    },
    /** 编辑器用到的的字段列表 {title,name} */
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
    regType: {
      type: String,
      default() {
        return "#"; // #:#{},$:${}
      },
    },
    changeCode: Function,
    disabled: Boolean,
  },
  data() {
    return {
      content: "",
      cmOptions: {
        autoRefresh: true,
        tabSize: 4, // tabsize默认为4
        styleActiveLine: true,
        lineNumbers: false,
        line: true,
        // mode: 'javascript', // 选择代码语言
        extraKeys: { Ctrl: "autocomplete" }, //自动提示配置
        lineWrapping: true, // 自动换行
        // theme: 'base16-light', // 主题根据需要自行配置
      },
      strHtml: "",
    };
  },

  methods: {
    onBlur() {
      this.$emit("change", this.content);
    },
    onCursorActivity(cm) {
      const selectedText = cm.getSelection();
      if (typeof this.changeCode === "function") {
        let content = this.changeCode(this.content);
        this.content = content;
      }
      this.initData(this.content);

      // 在此处处理选中文本
    },
    init(str) {
      this.content = str;
      this.initData(str);
    },

    initData(str) {
      const rex = this.regType == "#" ? /#{[^\}]+}/gm : /\${[a-zA-Z0-9_]+}/gm;
      let result;
      if (str) {
        result = str.match(rex);
      }

      if (this.disabled) {
        let str_reg = str;
        result &&
          result.forEach((name) => {
            let name2 = name
              .replaceAll(this.regType + "{", "")
              .replaceAll("}", "");
            let field = this.fieldList.find((row) => row.name == name2);
            if (field) {
              str_reg = str_reg.replaceAll(
                name,
                `<span class="cm-field ${field.delete ? "cm-delete" : ""}">${
                  field.title
                }</span>`,
              );
            }
          });
        this.strHtml = str_reg.replaceAll("\n", "<br/>");

        return;
      }

      if (result && result.length > 0) {
        result.forEach((name) => {
          name = name.replaceAll(this.regType + "{", "").replaceAll("}", "");
          let field = this.fieldList.find((row) => row.name == name);
          if (field) {
            let editor = this.$refs.codeEdit.codemirror;

            let regex = new RegExp(
              `(\\${this.regType}\\{)${field.name}(\\})`,
              "g",
            ); // 匹配搜索字符串的正则表达式
            for (let i = 0; i < editor.lineCount(); i++) {
              let line = editor.getLine(i); // 获取当前行的文本
              let match;
              while ((match = regex.exec(line))) {
                // 在当前行中查找匹配项
                let startPos = { line: i, ch: match.index }; // 匹配项的起始位置
                let endPos = { line: i, ch: match.index + match[0].length }; // 匹配项的结束位置
                let dom = document.createElement("span");
                let className = "cm-field";
                let title = field.title;
                if (field.delete) {
                  className += " cm-delete";
                  title += "(已删除)";
                }
                dom.className = className;
                dom.innerHTML = title;
                dom.id = field.name;

                this.createDom(startPos, endPos, dom);
              }
            }
          }
        });
      }
    },
    insert(row) {
      //获取codemirror光标位置
      if (this.disabled) {
        return;
      }
      const pos1 = this.$refs.codeEdit.codemirror.getCursor();
      const pos2 = {};
      pos2.line = pos1.line;
      pos2.ch = pos1.ch;
      let dom = document.createElement("span");
      dom.className = "cm-field";
      dom.innerHTML = row.title;
      dom.id = row.name;
      const bookMark = `${this.regType}{${row.name}}`;
      const endPos = { line: pos2.line, ch: pos2.ch + bookMark.length };
      this.$refs.codeEdit.codemirror.replaceRange(bookMark, pos2);
      this.createDom(pos2, endPos, dom);
    },

    createDom(startPos, endPos, dom) {
      if (this.disabled) {
        return;
      }
      this.$refs.codeEdit.codemirror.markText(startPos, endPos, {
        handleMouseEvents: true,
        replacedWith: dom,
      });
    },
    onCmReady() {
      // 设置代码编辑框宽和高
      this.$refs.codeEdit.codemirror.setSize(
        "-webkit-fill-available",
        this.textareaHeight,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rpa-codemirror-edit {
  position: relative;
}
.vue-codemirror {
  line-height: 20px;
  color: #000;
  border: 1px solid #dfe1e6;
  ::v-deep {
    .cm-field {
      display: inline-block;
      margin: 0 3px;
      padding: 0 5px;
      font-size: 12px;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.75);
      background: #f0f1f4;
      &.cm-delete {
        color: #f04134;
        background-color: rgba(254, 246, 246, 1);
      }
    }
  }
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-break: unset;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  font-size: 12px;
}
.cm-s-default .cm-comment {
  color: #000;
}
.CodeMirror-activeline-background {
  background-color: #fff;
}
</style>
