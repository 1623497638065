<template>
  <div>
    <div style="margin: 24px 20px 12px 20px" class="attr-row">
      <div class="row-between">
        <div class="attr-label"><span style="color: red">*</span>标题</div>
        <el-select
          v-if="isAllowConvertComp"
          :value="component.componentName"
          @change="compTypeChangeHandle"
          size="mini"
          style="width: 100px; font-size: 12px"
          :disabled="disabled"
          :title="
            disabled
              ? '字段中选项来源于其他表单数据/包含数据联动，无法转换'
              : ''
          "
        >
          <el-option
            v-for="comp in convertComps"
            :key="comp.name"
            :label="comp.label"
            :value="comp.componentName"
            style="font-size: 12px"
          >
          </el-option>
        </el-select>
        <div v-else>
          <el-tag size="small">{{ componentName }}</el-tag>
          <el-tag  v-if="component?.dataMode == 'reference_form'" size="small" effect="dark">实体表</el-tag>
          <el-button
            v-if="component.componentName == 'json_form' && component?.dataMode != 'reference_form'"
            type="primary" 
            size="mini"
            @click="convertToEntity"
            style="margin-left: 10px; padding: 5px 12px;"
          >
            转为实体表
          </el-button>
        </div>
      </div>
      <el-input v-model="component.title" placeholder="" size="mini"></el-input>
    </div>
    <div
      v-if="component?.dataMode == 'reference_form'"
      class="row-between" 
      style="margin: 0 20px 10px;font-size: 12px;"
    >
      <div>
        <i class="el-icon-connection"></i>工作表
      </div>
      <div 
        class="reference-form-name"
        :title="referenceForm?.title"
        @click="toReferenceForm"
      >
        {{referenceForm?.title}}
      </div>
    </div>
    <div
      class="attr-row"
      style="margin: 0px 20px"
      v-if="component.componentName !== 'html'"
    >
      <el-checkbox v-model="component.hiddenTitle">
        <span style="font-size: 12px">隐藏标题</span></el-checkbox
      >
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import { conponents, CONVERT_COMPONENT_GROUPS } from "../const";
import { componentProps, getCompObjByName, getCompObjsByNames } from "./util";
import { getAggreationNoStringify } from "@/views/lowCode/view/api";
import { subFormToReferenceFormMode, getFormDetail } from "@/views/lowCode/form/api";
import { param } from '@/utils';

export default {
  name: "attr-title",
  props: componentProps,
  data() {
    return {
      label: this.componentName,
      convertComps: [],
      referenceForm: {}, // 关联表(针对子表单转实体表)
    };
  },
  computed: {
    componentName() {
      let callback = (list) => {
        let obj = list.find(
          (item) => item.componentName == this.component.componentName
        );
        if (!obj) {
          list
            .filter((item) => item.components)
            .forEach((item) => {
              let o = callback(item.components);
              if (o) {
                obj = o;
              }
            });
        }
        return obj;
      };

      let c = callback(conponents);
      if (c) {
        return c.label;
      }
      return "";
    },
    // 是否是可转换的组件
    isAllowConvertComp() {
      let is = false;
      CONVERT_COMPONENT_GROUPS.forEach((names) => {
        if (names.includes(this.component.componentName)) {
          this.convertComps = getCompObjsByNames(names);
          is = true;
        }
      });
      return is;
    },
    disabled() {
      return (
        this.component.optionSource === "dataLinkage" ||
        this.component.optionSource === "metaField" ||
        this.component.dataLinkage
      );
    },
  },
  created() {
    if (!isEmpty(this.component.tableName) && this.component.componentName == "json_form") {
      this.getDetail(this.component.tableName);
    }
  },
  methods: {
    getDetail(tableName){
      getFormDetail(tableName, false).then(res => {
        console.log(this.component.components);
        this.referenceForm = res.data?.form
      })
    },
    async compTypeChangeHandle(val) {
      let targetCompObj = getCompObjByName(val);
      let transformedComp = await this.transformComp(
        this.component,
        targetCompObj
      );
      this.$emit("changeComponent", transformedComp);
    },

    async transformComp(currComp, targetComp) {
      let transformedComp = { ...targetComp, ...currComp };
      if (currComp.componentName === "input") {
        const options = await this.getOptionsByInputValues();
        if (options.length > 0) transformedComp.customOptions = options;
      }
      transformedComp.componentName = targetComp.componentName;
      transformedComp.label = targetComp.label;
      return transformedComp;
    },

    // 获取前台input组件已经填写的值
    async getOptionsByInputValues() {
      const res = await getAggreationNoStringify({
        collection: this.params.formId,
        queryObject: {
          xFields: [
            {
              field: {
                name: this.component.name,
                comment: this.componentName,
                componentName: this.component.componentName,
              },
            },
          ],
        },
        distinctFieldName: this.component.name,
      });
      return res.data.list
        .map((item) => {
          const fieldValue = item[this.component.name];
          return {
            label: fieldValue,
            value: fieldValue,
          };
        })
        .filter((item) => item.label !== undefined && item.label.trim() !== "");
    },

    titleInputOnBlur() {
      if (!isEmpty(this.component.title)) {
        this.component.title = "字段名称";
      }
    },
    // 转为实体表确认窗
    convertToEntity(){
      this.$confirm('将空白创建的子表单转为实体表，子表的数据将保存在实体表中。转换后子表中配置的公式、数据联动，前端事件等可能无法使用，该操作不可逆！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.subFormToReferenceFormMode()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消转换'
        });          
      });
    },
    // 转为实体表
    subFormToReferenceFormMode(){
      let params = {
        fieldName: this.component.key, // 子表单字段
        id: this.$route.query.id, // 表单ID
      }
      subFormToReferenceFormMode(params).then(res => {
        let tableName = res.data?.referenceFormDetail?.id
        this.$set(this.component, "dataMode", "reference_form");
        this.$set(this.component, "tableName", tableName);
        this.$set(this.component, "form", false);
        // 刷新表单目录树
        this.$bus.$emit("resetFormTree");
        // 转换同步关联表信息
        this.$bus.$emit("syncReferenceForm", tableName);
        this.$message.success("转换成功!");
      })
    },
    // 跳转关联表
    toReferenceForm() {
      if(this.referenceForm.id){
        let url = this.$router.resolve({
          path: "/lowCode/form/edit?id=" + this.referenceForm.id
        });
        window.open(url.href, "_blank");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.row-between {
  margin-bottom: 5px;
}
.reference-form-name{
  max-width: 220px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3870EA;
}
</style>
