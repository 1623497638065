<template>
  <div class="attr-container">
    <component
      v-for="item in list"
      :key="item"
      v-bind:is="item"
      :component="component"
      @changeComponent="changeComponent"
      :params="params"
      :form="form"
      :area-tree="areaTree"
    ></component>
    <div class="attr-row">
      <div class="attr-label">备注</div>
      <div>
        <el-input
          type="textarea"
          :rows="4"
          v-model="remark"
          placeholder=""
        ></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import attrAttribute from "@/views/lowCode/form/attribute/attr-attribute";
import attrDescription from "@/views/lowCode/form/attribute/attr-description";
import attrFieldWidth from "@/views/lowCode/form/attribute/attr-field-width";
import attrFieldWidthJsonForm from "@/views/lowCode/form/attribute/attr-field-width-json-form";
import attrSearch from "@/views/lowCode/form/attribute/attr-search";
import attrTitle from "@/views/lowCode/form/attribute/attr-title";
import attrValue from "@/views/lowCode/form/attribute/attr-value";
import attrPlaceholder from "@/views/lowCode/form/attribute/attr-placeholder";
import attrNumberCheck from "@/views/lowCode/form/attribute/attr-number-check";
import attrNumberFormat from "@/views/lowCode/form/attribute/attr-number-format";
import attrDatePicker from "@/views/lowCode/form/attribute/attr-date-picker";
import attrAddressPicker from "@/views/lowCode/form/attribute/attr-address-picker";
import attrDivider from "@/views/lowCode/form/attribute/attr-divider";
import attrSnRule from "@/views/lowCode/form/attribute/attr-sn-rule";
import attrPosition from "@/views/lowCode/form/attribute/attr-position";
import attrGrid from "@/views/lowCode/form/attribute/attr-grid";
import attrGridCol from "@/views/lowCode/form/attribute/attr-grid-col";
import attrIncludeForm from "@/views/lowCode/form/attribute/attr-include-form";
import attrParentData from "@/views/lowCode/form/attribute/attr-parent-data";
import attrTabs from "@/views/lowCode/form/attribute/attr-tabs";
import attrOptions from "@/views/lowCode/form/attribute/attr-options";
import attrFormData from "@/views/lowCode/form/attribute/attr-form-data";
import attrReferenceData from "@/views/lowCode/form/attribute/attr-reference-data";
import attrJsonForm from "@/views/lowCode/form/attribute/attr-json-form";
import attrReferenceDataList from "@/views/lowCode/form/attribute/attr-reference-data-list";
import attrButton from "@/views/lowCode/form/attribute/attr-button";
import attrSummaryData from "@/views/lowCode/form/attribute/attr-summary-data";
import attrSascadeData from "@/views/lowCode/form/attribute/attr-cascade-data";
import attrPermissionType from "@/views/lowCode/form/attribute/attr-permission-type";
import attrScanConfig from "./attr-scan-config";
import attrReferenceMoreConfig from "@/views/lowCode/form/attribute/attr-reference-more-config.vue";
import { componentMap } from "./util";

export default {
  components: {
    [attrAttribute.name]: attrAttribute,
    [attrDescription.name]: attrDescription,
    [attrFieldWidth.name]: attrFieldWidth,
    [attrFieldWidthJsonForm.name]: attrFieldWidthJsonForm,
    [attrSearch.name]: attrSearch,
    [attrTitle.name]: attrTitle,
    [attrValue.name]: attrValue,
    [attrPlaceholder.name]: attrPlaceholder,
    [attrNumberFormat.name]: attrNumberFormat,
    [attrNumberCheck.name]: attrNumberCheck,
    [attrDatePicker.name]: attrDatePicker,
    [attrAddressPicker.name]: attrAddressPicker,
    [attrDivider.name]: attrDivider,
    [attrSnRule.name]: attrSnRule,
    [attrPosition.name]: attrPosition,
    [attrGrid.name]: attrGrid,
    [attrGridCol.name]: attrGridCol,
    [attrIncludeForm.name]: attrIncludeForm,
    [attrParentData.name]: attrParentData,
    [attrTabs.name]: attrTabs,
    [attrOptions.name]: attrOptions,
    [attrFormData.name]: attrFormData,
    [attrReferenceData.name]: attrReferenceData,
    [attrReferenceMoreConfig.name]: attrReferenceMoreConfig,
    [attrJsonForm.name]: attrJsonForm,
    [attrReferenceDataList.name]: attrReferenceDataList,
    [attrButton.name]: attrButton,
    [attrSummaryData.name]: attrSummaryData,
    [attrScanConfig.name]: attrScanConfig,
    [attrPermissionType.name]: attrPermissionType,
    [attrSascadeData.name]: attrSascadeData,
  },
  props: {
    component: Object,
    params: Object,
    form: Object,
    areaTree: Array,
  },
  watch: {
    component: {
      handler: function (val, oldVal) {
        this.list = componentMap[this.component.componentName];
      },
      deep: true,
    },
  },
  computed: {
    remark: {
      get() {
        return this.component.remark;
      },
      set(val) {
        this.$set(this.component, "remark", val);
      },
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.list = componentMap[this.component.componentName];
  },

  methods: {
    init() {},
    changeComponent(component) {
      this.$emit("alterCurrNode", component);
    },
  },
};
</script>
<style lang="scss" scoped>
.attr-container {
  padding-bottom: 20px;
  ::v-deep {
    .attr-row {
      margin: 20px 20px;
      .item {
        margin-bottom: 5px;
      }
    }
    .attr-label {
      font-size: 14px;
      line-height: 24px;
    }
    .row-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .ml {
      margin-left: 5px;
    }
  }
}
</style>
