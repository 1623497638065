var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"width":"400px","height":"40px","background-color":"transparent","top":"0px","position":"absolute"},on:{"click":function($event){_vm.showSelect = false}}}),_c('div',{staticClass:"add-folder-dialog",on:{"click":function($event){_vm.showSelect = false}}},[_c('div',{staticClass:"select-icon"},[_c('div',{staticClass:"icon-container vue-hover-mask"},[_c('span',{staticClass:"vue-hover-mask_action",on:{"click":function($event){$event.stopPropagation();_vm.showSelect = !_vm.showSelect}}},[_c('i',{staticClass:"iconfont icon-bianji_fill",staticStyle:{"color":"#fff","font-size":"16px","margin-right":"0"}})]),(_vm.iconStyle && _vm.iconStyle.type !== null)?[(_vm.iconStyle.type === 'ICON_URL')?_c('div',{staticClass:"image-icon",style:({
              'background-image': 'url(' + _vm.iconStyle.icon + ')',
            })}):(_vm.iconStyle.type == 'EMOJI')?_c('div',{staticClass:"icon-div"},[_c('div',{staticStyle:{"font-size":"16px","z-index":"1","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();_vm.showSelect = !_vm.showSelect}}},[_vm._v(" "+_vm._s(_vm.iconStyle.icon)+" ")])]):_c('div',{staticClass:"icon-div",style:(_vm.setBgColor)},[_c('i',{class:!_vm.iconStyle.className.includes('fa')
                  ? 'iconfont ' + _vm.iconStyle.className
                  : _vm.iconStyle.className,staticStyle:{"font-size":"16px","margin-right":"0"},style:(_vm.setIconColor)})])]:[_c('div',{staticClass:"icon-div"},[(_vm.folderData.dataType === 'folder')?_c('svg-icon',{staticStyle:{"margin-right":"5px","font-size":"16px"},attrs:{"iconClass":"wenjianjia"}}):(
                _vm.folderData.dataType === 'resource' &&
                _vm.folderData.param.componentName === 'table'
              )?_c('i',{staticClass:"iconfont icon-biaodanqun",staticStyle:{"color":"rgb(178, 154, 232)"}}):(
                _vm.folderData.dataType === 'resource' &&
                _vm.folderData.param.componentName === 'plan_form_view'
              )?_c('i',{staticClass:"iconfont icon-biaodanqun",staticStyle:{"color":"rgb(74, 18, 199)"}}):(
                _vm.folderData.dataType === 'resource' &&
                _vm.folderData.param.componentName === 'dashboard'
              )?_c('i',{staticClass:"iconfont icon-yibiaopan",staticStyle:{"color":"#52c41a"}}):_vm._e()],1)]],2),(_vm.showSelect)?_c('style-select',{attrs:{"iconStyle":_vm.iconStyle,"enabledNewLayout":_vm.enabledNewLayout,"dataType":_vm.folderData.dataType},on:{"updateIcon":_vm.updateIcon}}):_vm._e()],1),(!_vm.isHomepage)?_c('div',[_c('el-input',{staticStyle:{"width":"360px","margin-left":"10px"},attrs:{"required":"","size":"small"},on:{"blur":_vm.updateName},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }