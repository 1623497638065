<template>
  <div>
    <!-- 关联独立表 -->
    <template v-if="component?.dataMode == 'reference_form'">
      <div class="attr-row">
        <div class="attr-label">关联表</div>
        <div class="el-meta-field">
          <div class="meta-text">
            {{ tableTitle }}
          </div>
        </div>
      </div>
      <attr-form-field
        :component="component"
        :component-list="componentList"
        title="子表单显示的字段"
        :key="component.tableName + '_field'"
      ></attr-form-field>
    </template>
    <!-- 子表原始模式-->
    <div class="attr-row" v-else>
      <div class="attr-label">子字段</div>
      <draggable
        :list="component.components"
        animation="300"
        handle=".el-icon-rank"
      >
        <div
          class="weui"
          v-for="(item, index) in component.components"
          :key="index"
        >
          <el-input
            :value="item.title ? item.title : item.label"
            size="small"
            readonly
          ></el-input>
          <i
            class="el-icon-rank ml"
            style="font-size: 16px; cursor: pointer"
          ></i>

          <i
            class="el-icon-remove ml"
            style="color: red; font-size: 16px"
            @click="delComponent(index)"
          ></i>
        </div>
      </draggable>
      <el-popover v-model="subVisible">
        <div class="pop-scroll">
          <div
            class="component-item"
            v-for="item in componentList"
            :key="item.componentName"
            v-text="item.label"
            @click="addComponent(item)"
          ></div>
        </div>
        <el-button slot="reference" type="text" icon="el-icon-plus"
          >添加子字段
        </el-button>
      </el-popover>
    </div>

    <!-- 子表实体表时设置关联表默认值 -->
    <reference-data-init
      v-if="component?.dataMode === 'reference_form'"
      :initFieldValues.sync="component.initFieldValues"
      :currentComponentList="formComponents"
      :componentList="componentList"
      :name="component.name"
      :componentName="component.componentName"
    ></reference-data-init>

    <!-- 默认值 -->
    <div class="attr-row">
      <div class="attr-label">默认值</div>

      <div style="margin-bottom: 10px">
        <el-select
          v-model="valueType"
          placeholder=""
          size="mini"
          style="width: 100%; margin-bottom: 5px"
        >
          <el-option label="自定义" value="custom"></el-option>
          <el-option label="数据联动" value="dataLinkage"></el-option>
        </el-select>
      </div>
      <data-linkage
        v-if="valueType == 'dataLinkage'"
        :form="component"
        :componentList="formComponents"
        :areaTree="areaTree"
      ></data-linkage>
      <json-value
        v-else-if="valueType == 'custom'"
        :node="component"
        :areaTree="areaTree"
        v-model="defaultValue"
      ></json-value>
    </div>
    <!-- 子字段显隐规则  -->
    <div class="attr-row">
      <div class="attr-label">
        {{
          component.fieldDisplayRules && component.fieldDisplayRules.length
            ? `已设置${component.fieldDisplayRules.length}个显隐规则`
            : "添加显隐规则"
        }}
        <el-tooltip
          content="让子表单的子字段在特定条件成立时显示，反之隐藏"
          placement="top"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <el-button
        @click="editFieldVisible"
        style="width: 100%; margin-bottom: 5px"
        size="mini"
      >
        添加显隐规则
      </el-button>
    </div>
    <display-field
      :dialogVisible.sync="fieldVisible"
      :originComponents="originComponents"
      :areaTree="areaTree"
      :fieldDisplayRules.sync="component.fieldDisplayRules"
      :jsonFormComponents="component.components"
      :isFromJsonForm="true"
    ></display-field>
    <!-- 关联表操作权限 -->
    <attr-table-action
      :component="component"
      :key="component.tableName + '_action'"
    ></attr-table-action>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { componentProps } from "./util";
import Draggable from "vuedraggable";
import { conponents as componentList } from "../const";
import dataLinkage from "../component/dataLinkage";
import JsonValue from "../component/JsonValue";
import { isEmpty, uuid } from "@zgg-core-utils/utils";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import AttrTableAction from "./attr-table-action.vue";
import attrFormField from "@/views/lowCode/form/attribute/attr-form-field";
import { getFormDetail } from "../api";
import displayField from "@/views/lowCode/form/component/displayField";
import ReferenceDataInit from "@/views/lowCode/form/component/ReferenceDataInit";
export default {
  name: "attr-json-form",
  inject: ["getComponents"],
  components: {
    Draggable,
    dataLinkage,
    JsonValue,
    AttrTableAction,
    attrFormField,
    displayField,
    ReferenceDataInit,
  },
  props: componentProps,
  data() {
    return {
      subVisible: false,
      tableTitle: "",
      formComponentList: [], // 关联表单组件列表
      fieldVisible: false,
    };
  },
  computed: {
    ...mapState("area", ["areaProps"]),
    formComponents() {
      return this.getComponents();
    },
    componentList() {
      if (this.component?.dataMode == "reference_form") {
        return this.formComponentList.filter((item) => {
          return checkFieldAbility(item, "inJsonForm");
        });
      } else {
        return componentList.filter((item) => {
          return checkFieldAbility(item, "inJsonForm");
        });
      }
    },
    valueType: {
      get() {
        let rely = this.component.rely;
        if (rely && rely.originalFormula) {
          return "rely";
        }
        if (this.component.autoValueTemplate != null) {
          return "autoValueTemplate";
        }
        if (this.component.dataLinkage != null) {
          return "dataLinkage";
        }

        return "custom";
      },
      set(val) {
        if (val == "custom") {
          this.$set(this.component, "rely", null);
          this.$set(this.component, "dataLinkage", null);
          this.$set(this.component, "autoValueTemplate", null);
          this.component.defaultValue = "";
        } else if (val == "dataLinkage") {
          this.$set(this.component, "rely", null);
          this.$set(this.component, "autoValueTemplate", null);
          this.$set(this.component, "dataLinkage", {});
          this.component.defaultValue = "";
        }
      },
    },
    defaultValue: {
      get() {
        let list = [];
        let value = this._.cloneDeep(this.component.defaultValue);
        if (!isEmpty(value)) {
          if (typeof value === "string") {
            list = JSON.parse(value);
          } else {
            list = value;
          }
        }
        return list;
      },
      set(val) {
        this.$set(this.component, "defaultValue", val);
      },
    },
    originComponents() {
      if (!isEmpty(this.component.tableName)) {
        return this.formComponentList;
      } else {
        return this.component?.components ?? [];
      }
    },
  },
  created() {
    console.log(this.component);
    if (!isEmpty(this.component.tableName)) {
      this.getDetail(this.component.tableName);
    } else {
      if (!this.component.fieldDisplayRules) {
        this.$set(this.component, "fieldDisplayRules", []);
      }
    }
  },
  mounted() {
    // 子表单转换为实体表同步信息
    this.$bus.$on("syncReferenceForm", (tableName) => {
      if (tableName) {
        this.getDetail(tableName);
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("syncReferenceForm");
  },
  methods: {
    getDetail(tableName) {
      getFormDetail(tableName, false).then((res) => {
        this.tableTitle = res.data?.form?.title;
        this.formComponentList = res.data?.form?.component?.components;
        if (
          res.data?.form?.component?.fieldDisplayRules &&
          !this.component.fieldDisplayRules
        ) {
          this.$set(
            this.component,
            "fieldDisplayRules",
            res.data?.form?.component?.fieldDisplayRules ?? [],
          );
        }
      });
    },
    delComponent(index) {
      this.component.components.splice(index, 1);
    },
    addComponent(item) {
      let obj = this._.cloneDeep(item);
      obj.title = obj.label;
      let name = uuid();
      obj.key = name;
      obj.name = name;
      this.component.components.push(obj);
      this.subVisible = false;
    },
    editFieldVisible() {
      this.fieldVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.weui {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.ml {
  margin-left: 8px;
}
.input-tip {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 28px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  font-size: 12px;
  line-height: 28px;
  padding: 0 10px;
}
.component-item {
  padding: 4px 0px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}
.el-meta-field {
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  padding-right: 10px;
  border-radius: 5px;
  color: #606266;
  cursor: pointer;

  .meta-text {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}
</style>
