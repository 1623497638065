<template>
  <div
    class="el-form-item tabs"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    @mousedown="mousedown"
  >
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane
        v-for="(item, index0) in data.components"
        :key="index0"
        :label="item.title"
        :name="index0 + ''"
      >
        <draggable
          :list="item.components"
          :group="colGroup2"
          animation="700"
          :scroll-sensitivity="500"
          fallback-tolerance="500"
          :scroll-speed="1000"
          style="min-height: 200px; display: flex; flex-wrap: wrap"
          name="tab_pane"
          :forceFallback="false"
          @choose="chooseDrag"
          @end="dragEnd"
          @add="onAdd"
        >
          <template v-for="(col, cIndex) in item.components">
            <reference-data
              :ref="`cpt_${col.key}`"
              :key="`${col.key}`"
              v-if="col.componentName == 'reference_data'"
              :index="cIndex"
              :data="col"
              :style="handleItemWidth(col)"
              class="custom-form-item"
              :isDrag="isDrag"
              :class="{
                'is-checked': currentKey == col.key,
                'copy-move': col.isCopyMove,
              }"
              @removeItem="removeTabItem"
              @copyItem="copyTabItem"
              @click.native="changeCurrNode(col, $event)"
              @changeCurrNode="changeCurrNode"
              :width="handleItemWidth(col)"
            ></reference-data>
            <edit-grid
              v-else-if="col.componentName == 'grid'"
              :key="col.key"
              :ref="`cpt_${col.key}`"
              :component="col"
              :index="cIndex"
              class="custom-form-item"
              :put="put"
              :isDrag="isDrag"
              :cols="cols"
              :getError="getError"
              :buildCopyItem="buildCopyItem"
              :currentKey="currentKey"
              :pasteSource="pasteSource"
              :components="conponents"
              @changeCurrNode="changeCurrNode"
              @removeItem="removeTabItem"
              @copyItem="copyTabItem"
              @choose="chooseDrag"
              @changePasteSource="changePasteSource"
              :class="{
                'is-checked': currentKey == col.key,
                'is-error': !!getError(col) && isSave,
                'copy-move': col.isCopyMove,
              }"
            ></edit-grid>
            <component-item
              v-else
              :ref="`cpt_${col.key}`"
              :key="col.key"
              :index="cIndex"
              :data="col"
              :put="put"
              :formId="formId"
              :width="handleItemWidth(col)"
              class="custom-form-item"
              :isDrag="isDrag"
              :class="{
                'is-checked': currentKey == col.key,
                'copy-move': col.isCopyMove,
              }"
              @removeItem="removeTabItem"
              @copyItem="copyTabItem"
              @click.native="changeCurrNode(col, $event)"
              @changeCurrNode="changeCurrNode"
            ></component-item>
          </template>
        </draggable>
      </el-tab-pane>
    </el-tabs>

    <div class="action-item" v-show="actionVisible">
      <div class="a-round">
        <i
          @click="copyItem"
          @mousedown.stop=""
          class="iconfont icon-file-copy-2-fill copy-fill"
          title="复制"
          style="margin-right: 5px"
        ></i>
      </div>
      <div class="a-round">
        <i
          @click="removeItem"
          @mousedown.stop=""
          title="删除"
          class="iconfont icon-shanchu_fill shanchu_fill"
        ></i>
      </div>
    </div>

    <!-- 删除组件 -->
    <el-dialog :visible.sync="delComponentVisible" width="560px" append-to-body>
      <div
        slot="title"
        style="display: flex; align-items: center; font-size: 18px"
      >
        <i
          class="el-icon-warning"
          style="color: #e6a23c; font-size: 22px; margin-right: 6px"
        ></i
        >确定删除该字段及对应表单数据？
      </div>
      <div style="padding: 20px">
        该字段被引用，确定删除该字段及对应表单数据？(删除后可在字段回收站中恢复)
      </div>
      <div slot="footer" class="dialog-footer">
        <el-link type="primary" :underline="false" @click="toReferenceDetail"
          >查看引用详情</el-link
        >
        <div>
          <el-button @click="delComponentVisible = false">取 消</el-button>
          <el-button type="primary" @click="finalRemoveTabItem"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 查看引用详情 -->
    <referenceDetail
      v-if="referenceVisible"
      :visible.sync="referenceVisible"
      :formId="formId"
      :referenceTitle="currNodeTitle"
      :source="'formField'"
      :fieldKey="currNodeKey"
    ></referenceDetail>

    <!-- 添加子表单 -->
    <el-dialog
      title="添加子表单"
      :visible.sync="addJsonFormVisible"
      width="600px"
      custom-class="dialog-field"
      :append-to-body="true"
    >
      <div style="padding: 20px; height: 160px">
        <el-radio-group v-model="dataMode" class="custom-radio">
          <el-radio :label="'sub_form'">添加空白表</el-radio>
          <el-radio :label="'reference_form'">将已有表单作为子表单</el-radio>
        </el-radio-group>
        <template v-if="dataMode == 'reference_form'">
          <el-form
            ref="jsonForm"
            :model="jsonForm"
            label-width="80px"
            :rules="rules"
            style="padding-top: 10px"
          >
            <el-form-item label="表单名称" prop="value">
              <el-select
                v-model="jsonForm.value"
                style="width: 460px"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in formList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <div slot="footer">
        <el-button @click="addJsonFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addJsonForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { referenceRemoveCheck, fetchFormList } from "../api";
import Draggable from "vuedraggable";
import { getCookie, setCookie, removeCookie } from "@/utils/cookie-tools";
import { conponents } from "../const";
import referenceDetail from "../reference-detail";
import { getComponentWidth } from "../utils";

export default {
  components: { Draggable, referenceDetail },
  props: {
    index: Number,
    data: Object,
    isDrag: Boolean,
    areaTree: Array,
    cols: {
      type: Number,
      default() {
        return 1;
      },
    },
    currentKey: String,
    put: Boolean,
    getError: Function,
    isSave: Boolean,
    pasteSource: [Object, Array],
    formId: String,
  },
  computed: {},
  data() {
    return {
      conponents,
      actionVisible: false,
      activeName: 0,
      colGroup2: {
        name: "column",
      },
      isCreated: false, // 页面周期开始标记
      delComponentVisible: false,
      referenceVisible: false,
      currNodeKey: "",
      currNodeTitle: "",
      currNodeIndex: 0, // 当前组件索引
      draggableObjNewIndex: {}, // 标签组件索引对象
      addComponent: null,
      formList: [],
      addJsonFormVisible: false,
      dataMode: "sub_form", // 数据模式 默认sub_form:子表原始模式
      jsonForm: {},
      rules: {
        value: [
          {
            required: true,
            message: "表单名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  created() {
    this.isCreated = true;
  },
  watch: {
    activeName: {
      immediate: false,
      handler(val) {
        this.$set(this.data, "activeName", val);
        if (this.isCreated) {
          this.isCreated = false;
          setTimeout(() => {
            removeCookie("form-edit-update");
          }, 100);
        }
      },
    },
  },
  methods: {
    getFormList() {
      fetchFormList({
        pageSize: 999,
      }).then((res) => {
        this.formList = res.data?.page?.list;
      });
    },
    // 添加子表单
    addJsonForm() {
      if (this.dataMode == "sub_form") {
        // 空白表单
        if (this.draggableObjNewIndex[this.activeName] == 0) {
          this.data.components[this.activeName].components.unshift(
            this.addComponent,
          );
        } else {
          this.data.components[this.activeName].components.splice(
            this.draggableObjNewIndex[this.activeName],
            0,
            this.addComponent,
          );
        }
        this.addJsonFormVisible = false;
        this.$emit("changeCurrNode", this.addComponent);
      } else {
        this.$refs.jsonForm.validate((valid) => {
          if (!valid) {
            return;
          }
          let obj = this._.cloneDeep(this.addComponent);
          obj.dataMode = "reference_form"; // reference_form:关联独立表  数据模式
          obj.tableName = this.jsonForm.value;
          obj.form = false;
          if (this.draggableObjNewIndex[this.activeName] == 0) {
            this.data.components[this.activeName].components.unshift(obj);
          } else {
            this.data.components[this.activeName].components.splice(
              this.draggableObjNewIndex[this.activeName],
              0,
              obj,
            );
          }
          this.addJsonFormVisible = false;
          this.$emit("changeCurrNode", obj);
        });
      }
    },
    /**
     * 处理表单项宽度
     *  如果表单项单项已经设置了宽度，则获取配置的数据，如果没有配置单项，则获取表单全局设置的列数来计算宽度
  
     * @param formItemConfig
     * @returns {string}
     */
    handleItemWidth(formItemConfig) {
      return getComponentWidth(formItemConfig, this.cols);
    },
    onAdd(e) {
      let addComponent =
        this.data.components[this.activeName].components[e.newIndex];
      this.$set(this.draggableObjNewIndex, [this.activeName], e.newIndex);
      if (addComponent.componentName == "tab") {
        this.$message.warning("不允许将标签页置入标签页中");
        this.$delete(
          this.data.components[this.activeName].components,
          e.newIndex,
        );
      } else {
        if (addComponent.componentName == "json_form") {
          this.addComponent = this._.cloneDeep(addComponent);
          this.$delete(
            this.data.components[this.activeName].components,
            e.newIndex,
          );
          this.getFormList();
          this.addJsonFormVisible = true;
          this.dataMode = "sub_form";
          this.jsonForm = {};
        } else {
          this.$emit(
            "changeCurrNode",
            this.data.components[this.activeName].components[e.newIndex],
          );
        }

        this.$emit("isOverflowSn", (res) => {
          if (res) {
            this.$message.warning("表单中仅可添加一个流水号字段");
            this.$delete(
              this.data.components[this.activeName].components,
              e.newIndex,
            );
          }
        });
      }
    },
    dragEnd(e) {
      this.$emit(
        "changeCurrNode",
        this.data.components[this.activeName].components[e.newIndex],
      );
    },
    chooseDrag() {
      this.$emit("choose");
    },
    changeCurrNode(node, event) {
      this.$emit("changeCurrNode", node, event);
    },

    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = true;
      } else {
        this.$emit("mouseenter", this.index, event);
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = false;
      } else {
        this.$emit("mouseleave", this.index, event);
      }
    },
    mousedown(event) {
      let classList = event.target.classList;

      if (classList.contains("el-tabs__item")) {
        return;
      }
      this.$emit("mousedown", this.index, event);
    },
    changePasteSource(obj) {
      this.$emit("changePasteSource", obj);
    },
    // 删除tab内的组件
    async removeTabItem(index) {
      // 标签的里面常规组件index>=0 子表单内的组件index = -1
      if (index >= 0) {
        this.currNodeKey =
          this.data.components[this.activeName].components[index].key;
        this.currNodeTitle =
          this.data.components[this.activeName].components[index].title;
        this.currNodeIndex = index;
        let params = {
          collection: this.formId,
          type: "form",
          fieldKey: this.currNodeKey,
        };
        let checkResult = await referenceRemoveCheck(params);
        if (checkResult?.data?.beReferred) {
          // 有引用关系
          this.delComponentVisible = true;
        } else {
          this.data.components[this.activeName].components.splice(index, 1);
          this.$emit("removeItem", -1);
        }
      } else {
        this.$emit("removeItem", -1);
      }
    },
    // 存在引用关系时删除组件
    finalRemoveTabItem() {
      this.delComponentVisible = false;
      this.data.components[this.activeName].components.splice(
        this.currNodeIndex,
        1,
      );
      this.$emit("removeItem", -1);
    },
    // 查看引用详情
    toReferenceDetail() {
      this.referenceVisible = true;
    },
    copyTabItem(index) {
      let obj = this._.cloneDeep(
        this.data.components[this.activeName].components[index],
      );
      this.buildCopyItem(obj);
      this.data.components[this.activeName].components.splice(
        index + 1,
        0,
        obj,
      );
      this.$emit("isOverflowSn", (res) => {
        if (res) {
          this.$message.warning("表单中仅可添加一个流水号字段");
          this.$delete(this.data.components[this.activeName].components, index);
        }
      });
    },
    buildCopyItem(obj) {
      delete obj.name;
      delete obj.key;
      let uuid = this.getUuid();
      obj.key = uuid;
      if (obj.form) {
        obj.name = uuid;
      }
      if (obj.componentName === "json_form") {
        if (obj?.dataMode !== "reference_form") {
          if (obj.components) {
            obj.components.forEach((item) => {
              this.buildCopyItem(item);
            });
          }
        }
      } else {
        if (obj.components) {
          obj.components.forEach((item) => {
            this.buildCopyItem(item);
          });
        }
      }
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  clear: both;

  ::v-deep {
    .el-tabs__content {
      border-color: #e4e7ed;
      border-width: 0px 1px 1px;
      border-style: solid;
      padding-top: 15px;
    }

    .el-tabs__header {
      margin-bottom: 0px !important;
    }
  }
}

.action-item {
  position: absolute;
  right: 0px;
  top: -16px;
  right: 10px;
  font-size: 16px;
  z-index: 2;
  height: 20px;
  display: flex;
  cursor: default;
  // box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  // padding: 2px 4px;
  // border-radius: 4px;
  .a-round {
    margin: 3px;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
    .iconfont {
      line-height: 24px;
      cursor: pointer;
      color: #a5adba;
      margin: auto;
    }
  }
  .copy-fill:hover {
    color: #3870ea;
  }
  .shanchu_fill:hover {
    color: #d14343;
  }
}
.custom-form-item {
  position: relative;
  float: left;
  &.is-checked {
    // border: #409eff dashed 1px;

    background-color: rgba($color: #409eff, $alpha: 0.1) !important;
    border-radius: 5px;
  }
  &.is-error {
    border: red dashed 1px;
    border-radius: 5px;
  }

  ::v-deep {
    .el-form-item__content {
      position: unset;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.custom-radio {
  ::v-deep {
    .el-radio {
      display: block;
      height: 30px;
      line-height: 30px;
      &.is-checked {
        background: none !important;
        border-radius: 5px;
      }
    }
  }
}
</style>
