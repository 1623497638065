<template>
  <div>
    <ul class="cmt-list">
      <li class="cmt-item" v-for="item in commentList" :key="item.id">
        <div class="cmt-user">
          {{ item.fromUser.name.substring(item.fromUser.name.length - 1) }}
        </div>
        <div class="cmt-content">
          <div class="weui-flex">
            <div class="cmt-name">{{ item.fromUser.name }}</div>
            <div class="cmt-time">{{ item.time }}</div>
          </div>
          <div class="cmt-remark">{{ item.content }}</div>
        </div>
        <i
          v-if="item.isDel"
          class="el-icon-delete"
          @click.stop="deleteComment(item.id)"
        ></i>
      </li>
    </ul>
    <div class="comment-box" @click.stop="stop">
      <el-input
        type="textarea"
        :autosize="true"
        v-model="comment"
        placeholder="请输入评论"
      ></el-input>
      <el-button
        :loading="loading"
        @click.stop="send"
        size="mini"
        style="margin-left: 10px; height: 33px"
        >发送
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  deleteComment,
  fetchCommentList,
  saveComment,
} from "@/api/boardManage";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    cardId: [String, Number],
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      loading: false,
      commentList: [],
      comment: "",
      pageNumber: 1,
      pageSize: 10,
      totalPage: 1,
      totalRow: 0,
    };
  },
  created() {
    this.loadingCommentList();
  },
  methods: {
    stop() {
      console.log("stop");
    },
    send() {
      if (!this.comment) {
        this.$message.error("请输入评论内容");

        return;
      }
      this.loading = true;
      saveComment({
        topicId: this.cardId,
        contentType: 10,
        content: this.comment,
      })
        .then((res) => {
          this.loading = false;
          this.comment = "";
          this.loadingCommentList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadMore() {
      if (this.pageNumber < this.totalPage) {
        this.loadingCommentList(this.pageNumber + 1);
      }
    },
    loadingCommentList(pageNumber = 1) {
      if (this.loadingComment) {
        return;
      }
      this.loadingComment = true;
      fetchCommentList({
        pageSize: this.pageSize,
        pageNumber: pageNumber,
        topicId: this.cardId,
      })
        .then((res) => {
          this.loadingComment = false;
          let now = moment();
          this.pageNumber = res.data.page.pageNumber;
          this.totalPage = res.data.page.totalPage;
          this.totalRow = res.data.page.totalRow;

          res.data.page.list.forEach((item) => {
            item.fromUser = JSON.parse(item.fromUser);
            let time = moment(item.createdTime);
            item.time = time.format("YYYY/MM/DD/ HH:mm:ss");
            let diff = now.diff(time);
            let min = moment.duration(diff).asMinutes();
            item.isDel = min <= 2 && item.createrId == this.currentUser.id;
          });

          if (res.data.page.pageNumber == 1) {
            this.commentList = res.data.page.list;
          } else {
            let list = res.data.page.list.filter(
              (comment) =>
                this.commentList.findIndex((item) => item.id == comment.id) ==
                -1
            );
            if (list.length) {
              this.commentList = this.commentList.concat(list);
            } else if (this.pageNumber < this.totalPage) {
              this.loadingCommentList(res.data.page.pageNumber + 1);
            }
          }

          this.$nextTick(() => {
            if (this.commentList.filter((item) => item.isDel)) {
              if (this.interval) {
                clearInterval(this.interval);
              }
              this.interval = setInterval(() => {
                now = moment();
                this.commentList.forEach((item) => {
                  if (item.isDel) {
                    let time = moment(item.createdTime);
                    let diff = now.diff(time);
                    let min = moment.duration(diff).asMinutes();
                    item.isDel =
                      min <= 2 && item.createrId == this.currentUser.id;
                  }
                });
                if (this.commentList.filter((item) => item.isDel).length == 0) {
                  if (this.interval) {
                    clearInterval(this.interval);
                  }
                }
              }, 1000);
            } else {
              if (this.interval) {
                clearInterval(this.interval);
              }
            }
          });
        })
        .catch(() => {
          this.loadingComment = false;
        });
    },
    deleteComment(id) {
      this.$confirm("确定要删除这条评论吗?", "提示")
        .then((res) => {
          deleteComment(id).then((res) => {
            this.loadingCommentList();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.cmt-list {
  margin-top: 10px;
}

.comment-box {
  display: flex;
  padding: 0px 10px 10px;
}

.weui-flex {
  display: flex;
  align-items: center;
}

.cmt-item {
  display: flex;
  padding: 0px 10px;
  margin-bottom: 15px;

  .cmt-user {
    background-color: #409eff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    font-size: 12px;
    border-radius: 30px;
    margin-right: 10px;
  }

  .cmt-content {
    //flex: 1;
    margin-right: 10px;
    width: calc(100% - 50px);
  }

  .cmt-name {
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
  }

  .cmt-time {
    font-size: 12px;
    color: #666666;
    margin-left: 15px;
  }

  .cmt-remark {
    font-size: 14px;
    color: #333333;
    line-height: 1.25;
    //white-space: pre-wrap;
    word-break: break-all;
    width: 100%;
    white-space: break-spaces;
  }
}
</style>
