<template>
  <div :style="`${componentWidth}`">
    <div
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      class="ref-container"
      :style="realWidth"
    >
      <div class="action-item" v-show="actionVisible">
        <div class="a-round">
          <i
            @click="copyItem"
            @mousedown.stop=""
            class="iconfont icon-file-copy-2-fill copy-fill"
            title="复制"
          ></i>
        </div>
        <div class="a-round">
          <i
            @click="removeItem"
            @mousedown.stop=""
            title="删除"
            class="iconfont icon-shanchu_fill shanchu_fill"
          ></i>
        </div>
      </div>
      <el-form-item
        :label="label"
        :required="!!data.required"
        :class="{ 'un-label': !data.form }"
        style="width: 100%"
      >
        <span slot="label">
          {{ label }}
          <i
            v-show="data.hidden"
            title="隐藏"
            class="iconfont icon-eye-off-fill"
            style="
              color: #a5adba;
              font-weight: normal !important;
              padding-right: 12px;
            "
          ></i>
        </span>
        <el-button size="small">选择数据</el-button>
      </el-form-item>
      <el-form-item
        v-for="child in data.columns"
        :key="child.field.name"
        :class="{ 'un-label': !data.form }"
        style="width: 100%"
      >
        <span slot="label"
          ><i class="el-icon-connection"></i><span v-text="child.title"></span
        ></span>
        <el-input
          v-if="child.field.componentName != 'json_form'"
          style="width: 100%"
          placeholder="暂无内容"
          disabled
          size="small"
        ></el-input>
        <el-table v-else :data="[]" style="width: 100%">
          <el-table-column
            min-width="120px"
            v-for="col in child.subColumns"
            :key="col.field.name"
            :label="col.title"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: Number,
    data: Object,
    isDrag: Boolean,
    areaTree: Array,
    width: String,
  },
  computed: {
    componentWidth() {
      if (this.data.displayBlock) {
        return `width:100%;`;
      }
      return this.width;
    },
    realWidth() {
      if (this.data.displayBlock) {
        return this.width;
      }
      return "width:100%;";
    },
    label() {
      if (this.data.title) {
        return this.data.title;
      }
      return this.data.label;
    },
  },
  data() {
    return {
      selectValue: "",
      checkboxValue: [],
      currIndex: -1,
      actionVisible: false,
    };
  },
  methods: {
    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = true;
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = false;
      }
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
.ref-container {
  position: relative;
}

.action-item {
  position: absolute;
  right: 0px;
  top: -16px;
  right: 10px;
  font-size: 16px;
  z-index: 2;
  height: 20px;
  display: flex;
  cursor: default;
  // box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  // padding: 2px 4px;
  // border-radius: 4px;
  .a-round {
    margin: 3px;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
    .iconfont {
      line-height: 24px;
      cursor: pointer;
      color: #a5adba;
      margin: auto;
    }
  }
  .copy-fill:hover {
    color: #3870ea;
  }
  .shanchu_fill:hover {
    color: #d14343;
  }
}
</style>
