<template>
  <div class="rely-desc-body">
    <!-- 绝对值描述 -->
    <template v-if="funName == 'ABS'">
      <div>ABS函数可以获取一个数的绝对值</div>
      <div>
        <span>用法：</span><span class="formula-name">ABS</span>(数字)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">ABS</span>(-8)可以返回8，也就是-8的绝对值。
      </div>
    </template>
    <!-- 平均值 -->
    <template v-else-if="funName == 'AVERAGE'">
      <div>AVERAGE函数可以获取一组数值的算术平均值。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">AVERAGE</span>(数字1,数字2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">AVERAGE</span>(<span
          class="formula-field"
          >语文成绩</span
        >,<span class="formula-field">数学成绩</span>,
        <span class="formula-field">英语成绩</span>)返回三门课程的平均分。
      </div>
    </template>
    <!-- 计数 -->
    <template v-else-if="funName == 'COUNT'">
      <div>COUNT函数可以获取参数的数量。</div>
      <div>
        <span>用法：</span><span class="formula-name">COUNT</span>(值,值,...)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">COUNT</span
        >(小明,小王,小张,小李)返回4，也就是人员的数量。
      </div>
    </template>
    <!-- 最大值 -->
    <template v-else-if="funName == 'MAX'">
      <div>MAX函数可以获取一组数值的最大值。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">MAX</span>(数字1,数字2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">MAX</span>(<span
          class="formula-field"
          >语文成绩</span
        >,<span class="formula-field">数学成绩</span
        ><span class="formula-field">英语成绩</span>)返回三门课程最高分。
      </div>
    </template>
    <!-- 最小值 -->
    <template v-else-if="funName == 'MIN'">
      <div>MIN函数可以获取一组数值的最小值。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">MIN</span>(数字1,数字2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">MIN</span>(<span
          class="formula-field"
          >语文成绩</span
        >,<span class="formula-field">数学成绩</span
        ><span class="formula-field">英语成绩</span>)返回三门课程最低分。
      </div>
    </template>
    <!-- 求余 -->
    <template v-else-if="funName == 'MOD'">
      <div>MOD函数可以获取两数相除的余数。</div>
      <div>
        <span>用法：</span><span class="formula-name">MOD</span>(被除数,除数)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">MOD</span>(4,3)也就是4/3的余数1。
      </div>
    </template>
    <!-- 求幂 -->
    <template v-else-if="funName == 'POWER'">
      <div>POWER函数可以获取数字乘幂的结果。</div>
      <div>
        <span>用法：</span><span class="formula-name">POWER</span>(数字,指数)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">POWER</span>(3,2)返回9，也就是3的2次方。
      </div>
    </template>
    <!-- 乘积 -->
    <template v-else-if="funName == 'PRODUCT'">
      <div>PRODUCT函数可以获取一组数值的乘积。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">PRODUCT</span>(数字1,数字2)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">PRODUCT</span>(<span
          class="formula-field"
          >单价</span
        >,<span class="formula-field">数量</span
        >)获取总价，也就是单价和数量的乘积。
      </div>
    </template>
    <!-- 除法 -->
    <template v-else-if="funName == 'DIVIDE'">
      <div>DIVIDE函数可以求两个数的除值</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">DIVIDE</span>(被除数,除数)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">DIVIDE</span>(4,2)返回2。
      </div>
    </template>
    <!-- 四舍五入 -->
    <template v-else-if="funName == 'ROUND'">
      <div>ROUND函数可以将数字四舍五入到指定的位数。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">ROUND</span>(数字,数字位数)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">ROUND</span>(3.1485,2)返回3.15。
      </div>
    </template>
    <!-- 正平方根 -->
    <template v-else-if="funName == 'SQRT'">
      <div>SQRT函数可以获取一个数字的正平方根。</div>
      <div>
        <span>用法：</span><span class="formula-name">SQRT</span>(数字)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">SQRT</span>(9)返回3，也就是9的正平方根。
      </div>
    </template>
    <!-- 总和 -->
    <template v-else-if="funName == 'SUM'">
      <div>SUM函数可以获取一组数值的总和。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SUM</span>(数字1,数字2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">SUM</span>(<span
          class="formula-field"
          >语文成绩</span
        >,<span class="formula-field">数学成绩</span
        ><span class="formula-field">英语成绩</span>)返回三门课程总分。
      </div>
    </template>
    <!-- 减法 -->
    <template v-else-if="funName == 'SUBTRACT'">
      <div>SUM函数可以获取一组数值的总和。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SUBTRACT</span>(数字1,数字2,...)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">SUBTRACT</span>(10,3,3)返回4。
      </div>
    </template>
    <!-- INT 将数字向下舍入取整 -->
    <template v-else-if="funName == 'INT'">
      <div>将数字向下舍入到最近的整数。</div>
      <div>
        <span>用法：</span><span class="formula-name">INT</span>(数字)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">INT</span>(3.5)返回3。
      </div>
    </template>
    <!-- ROUNDUP 以数值大的方向取舍 -->
    <template v-else-if="funName == 'ROUNDUP'">
      <div>以数值大的方向取舍</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">ROUNDUP</span>(数字,位数)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">ROUNDUP</span>(3.224,2,)返回3.23,<span
          class="formula-name"
          >ROUNDUP</span
        >(-2.1164,2)返回-2.11。
      </div>
    </template>
    <!-- ROUNDDOWN 以数值小的方向取舍 -->
    <template v-else-if="funName == 'ROUNDDOWN'">
      <div>以数值小的方向取舍</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">ROUNDDOWN</span>(数字,位数)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">ROUNDDOWN</span>(3.224,2)返回3.22,<span
          class="formula-name"
          >ROUNDUP</span
        >(-2.1164,2)返回-2.12。
      </div>
    </template>
    <!-- COUNTIF 统计满足条件的参数个数  -->
    <template v-else-if="funName == 'COUNTIF'">
      <div>
        统计满足条件的参数个数，value 代表不同的值，criteria
        为判断条件，支持比较运算符：==、!=、&lt;、&gt;、&lt;=、&gt;=。
      </div>
      <div>
        <span>用法：</span><span class="formula-name">COUNTIF</span>(value1,
        value2, …, criteria)。
      </div>
      <div>
        <span>示例一：</span>
        <span class="formula-name">COUNTIF</span>
        <span>([1,2,3,4],'&gt;2') 返回2。</span>
      </div>
      <div>
        <span>示例二：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(1,2,3,4,'&gt;2') 返回2。</span>
      </div>
      <div>
        <span>示例三：</span>
        <span class="formula-name">COUNTIF</span
        ><span>([1,2,3,4],'==2') 返回1。</span>
      </div>
      <div>
        <span>示例四：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(["A","B","C"],'B') 返回1。</span>
      </div>
      <div>
        <span>示例五：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(["A","B","C"],'!=B') 返回2。</span>
      </div>
      <div>
        <span>示例六：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(["A","B","C"],'&gt;=B') 返回2。</span>
      </div>
      <div>
        <span>示例七：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(["A","","C"],'!=""') 返回2。</span>
      </div>
      <div>
        <span>示例八：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(["A","","C"],'') 返回1。</span>
      </div>
      <div>
        <span>示例九：</span>
        <span class="formula-name">COUNTIF</span
        ><span>(["&lt;","==","&gt;"],'=="&lt;"') 返回1。</span>
      </div>
    </template>
    <!-- CEILING -->
    <template v-else-if="funName == 'CEILING'">
      <div>返回将参数 number 向上/向下舍入为最接近的指定基数的倍数</div>
      <div>
        <span>用法：</span><span class="formula-name">CEILING</span>(number,
        significance)，Number: 必需,要舍入的值;significance:
        必需,要舍入到的倍数。对于不同符号的参数搭配，具体计算方式如下：<br />当
        number 为正时，不管 significance 为正或为负，对值按远离 0
        的方向进行向上舍入；<br />当 number 为负，significance
        为正时，对值按朝向 0 的方向进行向上舍入；<br />当 number 和 significance
        都为负时，对值按远离 0 的方向进行向下舍入。
      </div>

      <div>
        <span>示例：</span
        ><span class="formula-name">CEILING</span>(10,3)返回12。
      </div>
    </template>
    <!-- FLOOR -->
    <template v-else-if="funName == 'FLOOR'">
      <div>将参数 number 向下舍入为最接近的significance的倍数</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">FLOOR</span
        >(number,significance)，number:必需,要舍入的数值;significance:必需,要舍入到的倍数。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">FLOOR</span>(10，3)返回9。
      </div>
    </template>
    <!-- FIXED -->
    <template v-else-if="funName == 'FIXED'">
      <div>
        将数字舍入到指定的小数位数，以十进制数格式对该数进行格式设置，并以文本形式返回结果
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">FIXED</span
        >(number,[decimals])，number:必需,要进行舍入并转换为文本的数字;decimals:可选,小数点右边的位数。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">FIXED</span>(3.657,2)返回3.66。
      </div>
    </template>
    <!-- LARGE -->
    <template v-else-if="funName == 'LARGE'">
      <div>返回数据集中第 k 个最大值</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">LARGE</span>(array,k)，array:必需,需要确定第
        k 个最大值的数组或数据区域;k:必需,返回值在数组中的位置(从大到小排)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">LARGE</span>([89,98,76],1)返回98。
      </div>
    </template>
    <!-- LOG -->
    <template v-else-if="funName == 'LOG'">
      <div>根据指定底数返回数字的对数</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">LOG</span
        >(number,[base])，number:必需,想要计算其对数的正实数;base:可选,对数的底数,如果省略
        base，则假定其值为 10。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">LOG</span
        >(100,10)返回2，也就是以10为底数100的对数。
      </div>
    </template>
    <!-- RAND -->
    <template v-else-if="funName == 'RAND'">
      <div>
        返回大于等于 0 且小于 1 的均匀分布随机实数。每一次触发计算都会变化
      </div>
      <div><span>用法：</span><span class="formula-name">RAND</span>()。</div>
      <div>
        <span>示例：</span
        ><span class="formula-name">RAND</span>()返回0.657285。
      </div>
    </template>
    <!-- SUMPRODUCT -->
    <template v-else-if="funName == 'SUMPRODUCT'">
      <div>在给定的数组中，将数组间对应的元素相乘，并返回乘积之和</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SUMPRODUCT</span
        >(array1,[array2])，array：可选，多个数字数组。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">SUMPRODUCT</span
        >([1,2,3],[0.1,0.2,0.3])返回1.4，也就是 1×0.1 + 2×0.2 + 3×0.3的值。
      </div>
    </template>
    <!-- SUMIF -->
    <template v-else-if="funName == 'SUMIF'">
      <div>函数可用于计算子表单中满足某一条件的数字相加并返回和</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SUMIF</span>(数组_判断区域, 逻辑表达式,
        数组_求和区域)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">SUMIF</span>(<span
          class="formula-field"
          >子表单.水果种类</span
        >, "苹果",
        <span class="formula-field">子表单.数字</span
        >)，可对子表单中水果种类填的是苹果的对应数字进行求和；<span
          class="formula-name"
          >SUMIF</span
        >([A,B,C,D,A], "==A", [1,1,1,1,1])，可得到A种类对应的数字求和结果为2。
      </div>
    </template>
    <!-- SUMIFS -->
    <template v-else-if="funName == 'SUMIFS'">
      <div>使同时满足多个条件的数字相加并返回和。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SUMIFS</span>(数组_求和区域, 数组_判断区域1,
        逻辑表达式1, [数组_判断区域2, 逻辑表达式2], ...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">SUMIFS</span>(<span
          class="formula-field"
          >子表单.数字</span
        >, <span class="formula-field">子表单.水果种类</span>, "==苹果",
        <span class="formula-field">子表单.细分种类</span>,
        "==红富士")，可对子表单中水果种类填的是苹果并且细分种类填的是红富士的对应数字进行求和；SUMIFS([1,1,1,1,1],
        [A,B,C,D,A], "==A", [a,b,c,d,e],
        "==a")，可得到既满足A种类又满足a种类对应的数字求和结果为1。。
      </div>
    </template>
    <!-- AND -->
    <template v-else-if="funName == 'AND'">
      <div>如果所有参数都为真，返回布尔值true，否则返回布尔值 false。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">AND</span>(逻辑表达式1,逻辑表达式2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">AND</span>(<span
          class="formula-field"
          >语文成绩</span
        >>90,<span class="formula-field">数学成绩</span>>90,<span
          class="formula-field"
          >英语成绩</span
        >>90)，如果三门课成绩都> 90，返回true，否则返回false。
      </div>
    </template>
    <!-- FALSE -->
    <template v-else-if="funName == 'FALSE'">
      <div>返回布尔值false。</div>
      <div><span>用法：</span><span class="formula-name">FALSE</span>()。</div>
      <div><span>示例：</span>略。</div>
    </template>
    <!-- TRUE -->
    <template v-else-if="funName == 'TRUE'">
      <div>返回布尔值true。</div>
      <div><span>用法：</span><span class="formula-name">TRUE</span>()。</div>
      <div><span>示例：</span>略。</div>
    </template>
    <!-- IF -->
    <template v-else-if="funName == 'IF'">
      <div>
        判断一个条件能否满足；如果满足返回一个值，如果不满足则返回另外一个值。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">IF</span
        >(逻辑表达式,为true时返回的值,为false时返回的值)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">IF</span>(<span
          class="formula-field"
          >语文成绩</span
        >&gt;60,"及格","不及格")，当语文成绩>60时返回及格，否则返回不及格。
      </div>
    </template>
    <!-- IFS -->
    <template v-else-if="funName == 'IFS'">
      <div>
        检查是否满足一个或多个条件，且返回符合第一个TRUE条件的值，可以取代多个嵌套IF语句。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">IFS</span
        >(逻辑表达式1,逻辑表达式1为true返回该值,逻辑表达式2,逻辑表达式2为true返回该值,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">IFS</span>(<span
          class="formula-field"
          >语文成绩</span
        >&gt;90,"优秀",<span class="formula-field">语文成绩</span
        >&gt;80,"良好",<span class="formula-field">语文成绩</span
        >&gt;&equals;60,"及格",<span class="formula-field">语文成绩</span
        >&lt;60,"不及格")，根据成绩返回对应的评价。
      </div>
    </template>
    <!-- NOT -->
    <template v-else-if="funName == 'NOT'">
      <div>NOT函数返回与指定表达式相反的布尔值。</div>
      <div>
        <span>用法：</span><span class="formula-name">NOT</span>(逻辑表达式)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">NOT</span>(<span
          class="formula-field"
          >语文成绩</span
        >&gt;60)，如果语文成绩大于60返回false，否则返回true。
      </div>
    </template>
    <!-- OR -->
    <template v-else-if="funName == 'OR'">
      <div>
        如果任意参数为真，返回布尔值true；如果所有参数为假，返回布尔值false。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">OR</span>(逻辑表达式1,逻辑表达式2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">OR</span>(<span
          class="formula-field"
          >语文成绩</span
        >&gt;90,<span class="formula-field">数学成绩</span>&gt;90,<span
          class="formula-field"
          >英语成绩</span
        >&gt;90)，任何一门课成绩> 90，返回true，否则返回false。
      </div>
    </template>
    <!-- XOR -->
    <template v-else-if="funName == 'XOR'">
      <div>返回所有参数的异或值。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">XOR</span>(逻辑表达式1,逻辑表达式2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">XOR</span>(<span
          class="formula-field"
          >语文成绩</span
        >&gt;90,<span class="formula-field">数学成绩</span>&gt;90,<span
          class="formula-field"
          >英语成绩</span
        >&gt;90)，如果两门成绩都&gt;90,返回false；如果两门成绩都&lt;90，返回false；如果其中一门&gt;90，另外一门&lt;90，返回true。
      </div>
    </template>
    <!-- INCLUDES -->
    <template v-else-if="funName == 'INCLUDES'">
      <div>INCLUDES函数返回第一个参数是否包含其他所有参数</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">INCLUDES</span>(数组,值1,值2,...)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">INCLUDES</span>(<span
          class="formula-field"
          >多选框</span
        >,2,3)，如果多选框的值是[2,3]，返回true；如果多选框的值是[2,4,5]，返回false。
      </div>
    </template>
    <!-- EQ -->
    <template v-else-if="funName == 'EQ'">
      <div>EQ函数,返回第一个参数是否等于其他所有参数</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">EQ</span>(参数1,参数2,...)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">EQ</span
        >(参数1,'2')，如果参数1的值是‘2’返回true，否则返回false，如果参数1是[2],也是返回2，如果是[2,3]返回false；<br /><span
          class="formula-name"
          >EQ</span
        >(参数1,'2','3')，参数1值为[2,3]，不分先后顺序，才返回true；<br /><span
          class="formula-name"
          >EQ</span
        >(参数1,参数2,'a')，参数1的值是数组并且包含了参数2和‘a’的值，并且不能用其他值，比如说参数2是[2,3],那么参数1必须是[2,3,a]才返回true
      </div>
    </template>
    <!-- ISEMPTY -->
    <template v-else-if="funName == 'ISEMPTY'">
      <div>用来判断值是否为空文本、空对象或者空数组。</div>
      <div>
        <span>用法：</span><span class="formula-name">ISEMPTY</span>(参数)。
      </div>
      <div><span>示例：</span>略。</div>
    </template>
    <!-- CONCATENATE -->
    <template v-else-if="funName == 'CONCATENATE'">
      <div>将多个文本合并成一个文本。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">CONCATENATE</span>(文本1,文本2,...)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">CONCATENATE</span
        >("三年二班","周杰伦")会返回"三年二班周杰伦"。
      </div>
    </template>
    <!-- TEXT -->
    <template v-else-if="funName == 'TEXT'">
      <div>将数字转化成文本。</div>
      <div>
        <span>用法：</span><span class="formula-name">TEXT</span>(数字)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">TEXT</span>(3.1415)返回"3.1415"。
      </div>
    </template>
    <!-- LEFT -->
    <template v-else-if="funName == 'LEFT'">
      <div>从一个文本的第一个字符开始返回指定个数的字符。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">LEFT</span>(文本,文本长度)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">LEFT</span
        >("三年二班周杰伦",2)返回"三年"，也就是"三年二班周杰伦"的从左往右的前2个字符。
      </div>
    </template>
    <!-- RIGHT -->
    <template v-else-if="funName == 'RIGHT'">
      <div>获取由给定文本右端指定数量的字符构成的文本值。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">RIGHT</span>(文本,文本长度)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">RIGHT</span
        >("三年二班周杰伦",3)返回"周杰伦"，也就是"三年二班周杰伦"从右往左的前3个字符。
      </div>
    </template>
    <!-- LEN -->
    <template v-else-if="funName == 'LEN'">
      <div>获取文本中的字符个数。</div>
      <div>
        <span>用法：</span><span class="formula-name">LEN</span>(文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">LEN</span
        >("朝辞白帝彩云间")返回7，因为这句诗中有7个字符。
      </div>
    </template>
    <!-- REPT -->
    <template v-else-if="funName == 'REPT'">
      <div>REPT函数可以将文本重复一定次数。</div>
      <div>
        <span>用法：</span><span class="formula-name">REPT</span>(文本,
        重复次数)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">REPT</span>("智管工", 3)
        返回"智管工智管工智管工"。
      </div>
    </template>
    <!-- PAD -->
    <template v-else-if="funName == 'PAD'">
      <div>
        PAD函数可以将文本填充到指定长度，如果文本长度超过指定长度，则不做任何处理
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">PAD</span
        >(文本,长度,填充文本,位置)，其中位置可以穿left，right，默认left。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">PAD</span>("abc",6,"_-_","right")
        返回abc_-_。
      </div>
    </template>
    <!-- UUID -->
    <template v-else-if="funName == 'UUID'">
      <div>UUID函数随机码生成器。可适用于随机流水号的使用场景等</div>
      <div><span>用法：</span><span class="formula-name">UUID</span>()。</div>
      <div>
        <span>示例：</span><span class="formula-name">UUID</span>(),返回{{
          UUID
        }}
      </div>
    </template>
    <!-- RMBCAP -->
    <template v-else-if="funName == 'RMBCAP'">
      <div>RMBCAP函数可以将金额小写转换为人民币大写金额形式。</div>
      <div>
        <span>用法：</span><span class="formula-name">RMBCAP</span>(数字)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">RMBCAP</span
        >(12.23)返回人民币壹拾贰元贰角叁分。
      </div>
    </template>
    <!-- CHAR -->
    <template v-else-if="funName == 'CHAR'">
      <div>将计算机字符集的数字代码转换为对应字符。</div>
      <div>
        <span>用法：</span><span class="formula-name">CHAR</span>(数字)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">CHAR</span>(10)会返回换行字符。
      </div>
    </template>
    <!-- EXACT -->
    <template v-else-if="funName == 'EXACT'">
      <div>比较两个文本是否完全相同，完全相同则返回true，否则返回false。</div>
      <div>
        <span>用法：</span><span class="formula-name">EXACT</span>(文本1,
        文本2)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">EXACT</span>(<span
          class="formula-field"
          >手机号</span
        >,
        <span class="formula-field">中奖手机号</span
        >)，如果两者相同，返回true，如果不相同，返回false。
      </div>
    </template>
    <!-- LOWER -->
    <template v-else-if="funName == 'LOWER'">
      <div>将一个文本中的所有大写字母转换为小写字母。</div>
      <div>
        <span>用法：</span><span class="formula-name">LOWER</span>(文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">LOWER</span>("JAYZ")返回"jayz"。
      </div>
    </template>
    <!-- MID -->
    <template v-else-if="funName == 'MID'">
      <div>返回文本中从指定位置开始的指定数目的字符。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">MID</span>(文本,开始位置_数字,指定数目)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">MID</span>("应用定制工具",3,2)返回"定制"。
      </div>
    </template>
    <!-- REPLACE -->
    <template v-else-if="funName == 'REPLACE'">
      <div>返回文本中从指定位置开始的指定数目的字符。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">REPLACE</span
        >(文本,开始位置,替换长度,新文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">REPLACE</span
        >("应用定制工具",1,6,"企业数据管理平台")返回"企业数据管理平台"。
      </div>
    </template>
    <!-- SEARCH -->
    <template v-else-if="funName == 'SEARCH'">
      <div>
        返回第一个文本字符串在第二个文本字符串中第一次出现的位置序号，从左到右查找，忽略英文字母的大小写；返回
        0 则表示未查找到。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SEARCH</span
        >(find_text,within_text,[start_num])，find_text:
        必需，要查找的文本；within_text: 必需，字符所在文本；start_num:
        可选，within_text 中从第几个字符开始查找；未填写则默认从第一个开始查找。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">SEARCH</span>("n","print")会返回4。
      </div>
    </template>
    <!-- SPLIT -->
    <template v-else-if="funName == 'SPLIT'">
      <div>将文本按指定分割符分割成数组。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">SPLIT</span>(文本,分隔符_文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">SPLIT</span
        >("应用定制-工具","-")返回"应用定制","工具"。
      </div>
    </template>
    <!-- JOIN -->
    <template v-else-if="funName == 'JOIN'">
      <div>将数组中的所有元素按指定分隔符拼接在一起。</div>
      <div>
        <span>用法：</span><span class="formula-name">JOIN</span>(数组, 分隔符)
      </div>
      <div>
        <span>示例一：</span><span class="formula-name">JOIN</span>(人员多选,
        "-") 返回: "张三-李四-王五"。
      </div>
      <div>
        <span>示例二：</span><span class="formula-name">JOIN</span>(部门多选,
        ",") 返回: "研发部,人事部,行政部"。
      </div>
      <div>
        <span>示例三：</span><span class="formula-name">JOIN</span>([123, 456,
        789], "") 返回: "123456789"。
      </div>
      <div>
        <span>示例四：</span><span class="formula-name">JOIN</span>("ab", "cd",
        "ef", "|") 返回: "ab|cd|ef"。
      </div>
    </template>
    <!-- TRIM -->
    <template v-else-if="funName == 'TRIM'">
      <div>
        删除字符串首尾的空格;如果字符串内部存在连续多个空格，还会删除至仅剩一个空格。
      </div>
      <div>
        <span>用法：</span><span class="formula-name">TRIM</span>(文本)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">TRIM</span>(" A B C
        ")会返回A B C。
      </div>
    </template>
    <!-- UNION -->
    <template v-else-if="funName == 'UNION'">
      <div>合并多个文本数组，重复文本只显示一次。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">UNION</span
        >(文本1，文本2，文本3，文本4)或<span class="formula-field">UNION</span
        >([数组])。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">UNION</span
        >("张三","李四","王五","张三","李四")返回值为["张三","李四","王五"]；<br />(支持人员，部门组件)。
      </div>
    </template>
    <!-- UPPER -->
    <template v-else-if="funName == 'UPPER'">
      <div>将一个文本中的所有小写字母转换为大写字母。</div>
      <div>
        <span>用法：</span><span class="formula-name">UPPER</span>(文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">UPPER</span>("jayz")返回"JAYZ"。
      </div>
    </template>
    <!-- VALUE -->
    <template v-else-if="funName == 'VALUE'">
      <div>将文本转化为数字。</div>
      <div>
        <span>用法：</span><span class="formula-name">VALUE</span>(文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">VALUE</span>("3.1415")返回3.1415。
      </div>
    </template>
    <!-- VALUES -->
    <template v-else-if="funName == 'VALUES'">
      <div>将文本按指定分隔符分割成数值数组。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">VALUES</span
        >(文本,分隔符_文本)，分隔符默认为英文逗号。
      </div>
      <div>
        <span>示例一：</span
        ><span class="formula-name">VALUES</span>("一串数字")
        假如"一串数字"为"32,24,35", 返回数组 [32,24,35]。
      </div>
      <div>
        <span>示例二：</span
        ><span class="formula-name">VALUES</span>("一串数字","|") 指定分割符为
        "|"，假如 "一串数字" 为 "32|24|35"，返回数组 [32,24,35]。
      </div>
      <div>
        <span>示例三：</span
        ><span class="formula-name">VALUES</span>("一串数字") 假如 "一串数字" 为
        "one|32|three|35"，返回数组 [,32,,35]。
      </div>
    </template>
    <!-- DATE -->
    <template v-else-if="funName == 'DATE'">
      <div>DATE函数可以将时间戳或字符串转换为日期对象</div>
      <div>
        <span>用法：</span><span class="formula-name">DATE</span>(文本)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">DATE</span>('2023-02-10')，返回{{
          DATE("2023-02-10")
        }}
      </div>
    </template>
    <!-- DATEDELTA -->
    <template v-else-if="funName == 'DATEDELTA'">
      <div>将指定日期加/减指定天数。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">DATEDELTA</span
        >DATEDELTA(指定日期,需要加减的天数,[单位])，<br />【单位】可以是 "y"
        、"M"、"d"、"h"、"m"、"s"，不传默认是"d"天数<br />【需要加减的天数】可以是【数字】也可以是【数字组件】。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">DATEDELTA</span>(<span
          class="formula-field"
          >下单时间</span
        >,2,"y")，如果下单时间是2022-08-02，计算得到2024-08-02
      </div>
    </template>
    <!-- DATEDIF -->
    <template v-else-if="funName == 'DATEDIF'">
      <div>计算两个日期时间相差的年数、月数、天数、小时数、分钟数、秒数。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">DATEDIF</span
        >(开始时间,结束时间,[单位])，单位可以是 "y" 、"M"、"d"、"h"、"m"、"s"。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">DATEDIF</span>(<span
          class="formula-field"
          >开始时间</span
        >,<span class="formula-field">结束时间</span
        >,"h")，如果开始时间是9:00，结束时间为当天10:30，计算得到的小时差为1.5。
      </div>
    </template>
    <!-- NOW -->
    <template v-else-if="funName == 'NOW'">
      <div>获取当前时间。</div>
      <div><span>用法：</span><span class="formula-name">NOW</span>()</div>
      <div>
        <span>示例：</span><span class="formula-name">NOW</span>()，返回{{
          NOW
        }}
      </div>
    </template>
    <!-- YEAR -->
    <template v-else-if="funName == 'YEAR'">
      <div>返回某日期的年份。</div>
      <div>
        <span>用法：</span><span class="formula-name">YEAR</span>(时间戳)
      </div>
      <div>
        <span>示例：</span><span class="formula-field">YEAR</span>(<span
          class="formula-field"
          >生产日期</span
        >)，即 2023-02-10 对应的年份为 2023。
      </div>
    </template>
    <!-- MONTH -->
    <template v-else-if="funName == 'MONTH'">
      <div>返回某日期的月份。</div>
      <div>
        <span>用法：</span><span class="formula-name">MONTH</span>(时间戳)
      </div>
      <div>
        <span>示例：</span><span class="formula-field">MONTH</span>(<span
          class="formula-field"
          >生产日期</span
        >)，即 2023-02-10 对应的月份为 2。
      </div>
    </template>
    <!-- DAY -->
    <template v-else-if="funName == 'DAY'">
      <div>获取某日期是当月的第几日。</div>
      <div>
        <span>用法：</span><span class="formula-name">DAY</span>(时间戳)
      </div>
      <div>
        <span>示例：</span><span class="formula-field">DAY</span>(<span
          class="formula-field"
          >生产日期</span
        >)，即 2023-02-10 对应的天数为 10。
      </div>
    </template>
    <!-- HOUR -->
    <template v-else-if="funName == 'HOUR'">
      <div>返回某日期的小时数。</div>
      <div>
        <span>用法：</span><span class="formula-name">HOUR</span>(时间戳)
      </div>
      <div>
        <span>示例：</span><span class="formula-field">HOUR</span>(<span
          class="formula-field"
          >打卡时间</span
        >)，即 2023-01-01 14:38:01 对应的小时数为 14。
      </div>
    </template>
    <!-- MINUTE -->
    <template v-else-if="funName == 'MINUTE'">
      <div>返回某日期的分钟数。</div>
      <div>
        <span>用法：</span><span class="formula-name">MINUTE</span>(时间戳)
      </div>
      <div>
        <span>示例：</span><span class="formula-field">MINUTE</span>(<span
          class="formula-field"
          >打卡时间</span
        >)，即 2023-01-01 14:38:01 对应的分钟数为 38。
      </div>
    </template>
    <!-- SECOND -->
    <template v-else-if="funName == 'SECOND'">
      <div>返回某日期的秒数。</div>
      <div>
        <span>用法：</span><span class="formula-name">SECOND</span>(时间戳)
      </div>
      <div>
        <span>示例：</span><span class="formula-field">SECOND</span>(<span
          class="formula-field"
          >打卡时间</span
        >)，即 2023-01-01 14:38:41 对应的秒数为 41。
      </div>
    </template>
    <!-- DAYS -->
    <template v-else-if="funName == 'DAYS'">
      <div>返回两个日期之间相差的天数。</div>
      <div>
        <span>用法：</span><span class="formula-name">DAYS</span>(end_timestamp,
        start_timestamp),end_timestamp: 必需，结束日期；start_timestamp:
        必需，起始日期。
      </div>
      <div>
        <span>示例：</span><span class="formula-field">DAYS</span>(<span
          class="formula-field"
          >结束日期</span
        >,<span class="formula-field">开始日期</span
        >)，结束日期为2023-01-04，开始日期为2023-01-01，返回结果是3
      </div>
    </template>
    <!-- DAYS360 -->
    <template v-else-if="funName == 'DAYS360'">
      <div>
        DAYS360 函数可按照一年 360 天的算法（每个月 30 天，一年 12
        个月），返回两个日期间相差的天数
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">DAYS360</span>(end_timestamp,
        start_timestamp, method),end_timestamp: 必需，结束日期；start_timestamp:
        必需，起始日期；method:可选，用于指定在计算中采用美国方法或欧洲方法。
      </div>
      <div>
        <span>示例：</span><span class="formula-field">DAYS360</span>(<span
          class="formula-field"
          >结束日期</span
        >,<span class="formula-field">开始日期</span
        >)，结束日期为2023-01-31，开始日期为2023-01-05，返回结果是26
      </div>
    </template>
    <!-- ISOWEEKNUM -->
    <template v-else-if="funName == 'ISOWEEKNUM'">
      <div>返回指定日期在全年中的ISO周数。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">ISOWEEKNUM</span>(指定日期)。
      </div>
      <div>
        <span>示例：</span><span class="formula-field">ISOWEEKNUM</span>(<span
          class="formula-field"
          >下单日期</span
        >)，下单日期为2023-01-05，返回结果是1
      </div>
    </template>
    <!-- NETWORKDAYS -->
    <template v-else-if="funName == 'NETWORKDAYS'">
      <div>
        获取两个日期之间的工作日数，工作日不包括周末和任何其他指定日期。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">NETWORKDAYS</span
        >(开始日期,结束日期,[节假日1,节假日2, ...])。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-field">NETWORKDAYS</span
        >(开始时间,结束时间,[DATE("2023-06-22"),DATE("2023-06-23")])，开始时间是2023-06-19，结束时间是2023-06-23，返回结果是3
      </div>
    </template>
    <!-- TIMESTAMP -->
    <template v-else-if="funName == 'TIMESTAMP'">
      <div>将日期对象转换成时间戳。</div>
      <div>
        <span>用法：</span><span class="formula-name">TIMESTAMP</span>(日期)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">TIMESTAMP</span>(NOW())，返回
        {{ TIMESTAMP }}
      </div>
    </template>
    <!-- TIME -->
    <template v-else-if="funName == 'TIME'">
      <div>
        返回特定时间的十进制数字。时间值为日期值的一部分，并用十进制数表示（例如
        12:00 PM 可表示为 0.5，因为此时是一天的一半）
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">TIME</span>(时_数字,分_数字,秒_数字)。
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">TIME</span>(10,20,30)，返回 {{ TIME }}
      </div>
    </template>
    <!-- TODAY -->
    <template v-else-if="funName == 'TODAY'">
      <div>获取今日的日期时间，可精确到秒，时间为今日的起始时间。</div>
      <div><span>用法：</span><span class="formula-name">TODAY</span>()。</div>
      <div>
        <span>示例：</span><span class="formula-name">TODAY</span>()，返回
        {{ TODAY }}
      </div>
    </template>
    <!-- WEEKNUM -->
    <template v-else-if="funName == 'WEEKNUM'">
      <div>返回指定日期在当年是第几周。</div>
      <div>
        <span>用法：</span><span class="formula-name">WEEKNUM</span>(timestamp,
        return_type)，timestamp: 必需，指定日期；return_type:
        可选参数，确定一周从哪一天开始。1/17/为空：星期日；​2/11：星期一；12：星期二；13：星期三
        14：星期四；15：星期五：16：星期六
      </div>
      <div>
        <span>示例：</span
        ><span class="formula-name">WEEKNUM</span>('2023-02-10')，返回{{
          WEEKNUM
        }}
      </div>
    </template>
    <!-- WORKDAY -->
    <template v-else-if="funName == 'WORKDAY'">
      <div>
        获取给定日期之前或者之后指定工作日数的日期，工作日不包括周末和任何其他指定日期。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">WORKDAY</span>(日期,工作日数,[节假日1,
        节假日2, ...])。。
      </div>
      <div><span>示例：</span>略</div>
    </template>
    <!-- GETUSER -->
    <template v-else-if="funName == 'GETUSER'">
      <div>
        通过参数获取当前用户的ID，姓名和手机号(取当前用户当前单位的姓名)
      </div>
      <div>
        <span>用法：</span><span class="formula-name">GETUSER</span>("id"),<span
          class="formula-name"
          >GETUSER</span
        >("name"),<span class="formula-name">GETUSER</span>("mobile")
      </div>
      <div><span>示例：</span>略</div>
    </template>
    <!-- INDEX -->
    <template v-else-if="funName == 'INDEX'">
      <div>返回数组中指定位置的值，位置用整数表示。</div>
      <div>
        <span>用法：</span><span class="formula-name">INDEX</span>(数组,位置)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">INDEX</span>(<span
          class="formula-field"
          >复选框组</span
        >,1)，则返回复选框组中正数第1个值，<span class="formula">INDEX</span
        >(<span class="formula-field">复选框组</span
        >,-1)，则返回复选框组中倒数第1个值。
      </div>
    </template>
    <!-- RECNO -->
    <template v-else-if="funName == 'RECNO'">
      <div>依据当前表单被新打开的次数进行不断累计，起始值为1。</div>
      <div><span>用法：</span><span class="formula-name">RECNO</span>()。</div>
      <div><span>示例：</span>略</div>
    </template>
    <!-- TEXTDEPT -->
    <template v-else-if="funName == 'TEXTDEPT'">
      <div>获取部门的部门名称或是部门编号。</div>

      <div>
        <span>用法：</span><span class="formula-name">TEXTDEPT</span>(部门字段,
        "name")或<span class="formula-name">TEXTDEPT</span>(部门字段,
        "deptno")。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">TEXTDEPT</span>(<span
          class="formula-field"
          >部门单选</span
        >,
        "name")会返回所选部门的名称，例如“销售部”。<span>TEXTDEPT</span>(<span
          class="formula-field"
          >部门单选</span
        >, "deptno")会返回所选部门的编号（企业内唯一）。
      </div>
    </template>
    <!-- TEXTLOCATION -->
    <template v-else-if="funName == 'TEXTLOCATION'">
      <div>
        获取「定位」和「地址」字段中的完整地址、省、市、区、详细地址；还可以获取定位字段的经纬度信息。
      </div>
      <div>
        <span>用法：</span
        ><span class="formula-name">TEXTLOCATION</span>(地址/定位字段,
        "返回值精度")，精度可以是"address"、"province"、"city"、"district"、"detail",
        定位字段的精度还可支持"lng"、"lat"。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">TEXTLOCATION</span>(<span
          class="formula-field"
          >地址字段</span
        >, "province" ) ，返回字段的「省」信息，如江苏省。
      </div>
    </template>
    <!-- TEXTUSER -->
    <template v-else-if="funName == 'TEXTUSER'">
      <div>获取成员的昵称或是编号（取用户当前单位的姓名）。</div>
      <div>
        <span>用法：</span
        ><span class="formula-name">TEXTUSER</span>(成员单选字段,"name"),<span
          class="formula-name"
          >TEXTUSER</span
        >(成员单选字段,"id")
      </div>
      <div>
        <span>示例：</span><span class="formula-name">TEXTUSER</span>(<span
          class="formula-field"
          >成员单选</span
        >, "name")会返回所选成员的昵称，例如“张三”。<span class="formula-name"
          >TEXTUSER</span
        >(<span class="formula-field">成员单选</span>,
        "id")会返回所选成员的编号。
      </div>
    </template>
    <!-- DISTANCE -->
    <template v-else-if="funName == 'DISTANCE'">
      <div>返回两个位置间的直线距离，单位m(米)。</div>
      <div>
        <span>用法：</span><span class="formula-name">DISTANCE</span>(位置1,
        位置2)。
      </div>
      <div>
        <span>示例：</span><span class="formula-name">DISTANCE</span>(<span
          class="formula-field"
          >位置1</span
        >,<span class="formula-field">位置2</span
        >)，返回两个定位位置间的距离，如23000。
      </div>
    </template>
  </div>
</template>
<script>
import {
  _date,
  UUID,
  now,
  timestamp,
  time,
  today,
  weeknum,
} from "@/zgg-core/relyUtil";
export default {
  props: {
    funName: String,
  },
  computed: {
    UUID() {
      return UUID();
    },
    NOW() {
      return now();
    },
    TIMESTAMP() {
      return timestamp(this.NOW);
    },
    TIME() {
      return time(10, 20, 30);
    },
    TODAY() {
      return today();
    },
    WEEKNUM() {
      return weeknum("2023-02-10");
    },
  },
  methods: {
    DATE(str) {
      return _date(str);
    },
  },
};
</script>
<style lang="scss" scoped>
.rely-desc-body {
  white-space: pre-wrap;
  overflow: auto;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}
.formula-name {
  color: #c643e0;
}
.formula-field {
  background: #e6f7fa;
  border-radius: 3px;
  color: #00aed1;
  padding: 2px 4px;
}
</style>
