<template>
  <div id="write" class="">
    <h1>
      <a
        :name="`${environment.platformName}隐私条款`"
        class="md-header-anchor"
      ></a
      ><span>{{ environment.platformName }}隐私条款</span>
    </h1>
    <p><span>更新时间：2022年07月19日</span></p>
    <p><span>生效时间：2022年07月19日</span></p>
    <p>
      <span
        >“{{ environment.platformName }}”是{{
          environment.platformFullName
        }}（下称“{{
          environment.platformName
        }}”、“我们”）提供的互联网技术服务。我们深知个人信息对您的重要性，我们将依照法律法规的规定，保护您的个人信息及隐私安全。我们希望通过本隐私条款告知您我们是如何收集、使用、储存、共享和保护您的相关信息。我们建议您仔细阅读并理解本条款的全部内容，做出您认为适当的选择。其中，有关您个人信息权益的重要内容我们将用加粗形式提示，请特别关注。</span
      >
    </p>
    <h1>
      <a name="一本条款适用范围" class="md-header-anchor"></a
      ><span>一、本条款适用范围</span>
    </h1>
    <p>
      <span
        >1.1 本隐私条款适用于{{ environment.platformName }}产品及服务，包括{{
          environment.platformName
        }}微信小程序、{{
          environment.platformName
        }}网站、供第三方网站和应用程序使用的{{
          environment.platformName
        }}软件开发工具包（SDK）和应用程序编程接口（API）以及其他由我们提供且使用本隐私条款的所有产品及服务（在本隐私条款中统称为“本服务”或“{{
          environment.platformName
        }}”）。</span
      >
    </p>
    <p>
      <span
        >1.2
        本隐私条款不适用于其他第三方向你提供的服务，你在选择使用第三方服务前应充分了解第三方服务的产品功能及隐私保护条款，再选择是否使用相应功能或服务。</span
      >&#x20;
    </p>
    <h1>
      <a name="二我们如何收集和使用个人信息" class="md-header-anchor"></a
      ><span>二、我们如何收集和使用个人信息</span>
    </h1>
    <p><span>2.1 注册、登录</span></p>
    <p>
      <span
        >2.1.1
        使用本服务前，您需要通过手机号创建帐号，手机号码是履行国家法律法规关于网络实名制要求的必要信息，如果您不提供手机号码用于注册、登录，我们可能无法为您提供本服务。</span
      >
    </p>
    <p>
      <span
        >2.1.2 您可以使用微信登录并使用{{
          environment.platformName
        }}，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与{{
          environment.platformName
        }}帐号绑定，使您可以直接登录并使用本产品和相关服务。如果您使用微信登录或将您的账号与微信绑定，我们还将收集您的微信OpenID。在使用第三方账号进行登录时，可能需要将实现登录所必需的信息在剪切板中写入与读取。这些信息仅供实现登录相关的目的所使用。</span
      >
    </p>
    <p><span>2.2 帐号公开信息</span></p>
    <p>
      <span
        >您可以根据需求，自主填写个人信息，这些您主动填写或设置个人资料将用于向其他用户展示。 </span
      >&#x20;
    </p>
    <p><span>2.3 创建、加入单位 </span>&#x20;</p>
    <p>
      <span
        >2.3.1
        当您创建单位时，您需要向我们提供您的单位名称，您还可以选择向我们提供您单位的地址，以完善您的组织信息。 </span
      >&#x20;
    </p>
    <p>
      <span
        >2.3.2
        当您添加单位成员时，您需要向我们提供成员姓名和手机号，您也可以向我们提供成员的所属部门、职位，以完善其个人信息。 </span
      >&#x20;
    </p>
    <p><span>2.4 单位流程管理 </span>&#x20;</p>
    <p>
      <span
        >当您使用单位流程时，您需要选择流程负责人和抄送人，以便实现流程管理。我们还会收集您的流程管理日志信息（包括发起人姓名、操作时间、以及负责人、抄送人的成员姓名） </span
      >&#x20;
    </p>
    <p><span>2.5 创建工作群</span></p>
    <p>
      <span
        >2.5.1
        当您创建看板、整编、工作表、仪表盘时，您需要向我们提供看板、整编、工作表、仪表盘名称，以便我们帮助您创建工作群。 </span
      >&#x20;
      <span
        >2.5.2
        工作群支持多人查看、编辑，为了使协同人清晰知晓工作群被编辑的信息，在您添加任务、编辑表单、评论时，我们将展示您的姓名，以及发布的内容。</span
      >
    </p>
    <p>
      <span
        >2.5.3
        当您在工作群配置权限时，我们会收集群管理员的姓名、组管理员姓名、成员姓名，权限组名称、权限内容。</span
      >
    </p>
    <p><span>2.6 创建任务、创建表单记录</span></p>
    <p>
      <span
        >2.6.1
        当您创建任务时，我们会收集您的任务名称、备注、参与人、时间、标签、封面、附件，帮助您更好的管理任务。</span
      >
    </p>
    <p>
      <span
        >2.6.2
        当您创建表单记录时，我们会收集您的表单信息，以便帮助您管理数据。</span
      >
    </p>
    <p><span>2.7 上传文件</span></p>
    <p>
      <span
        >当您在网盘、看板、整编、工作表等群上传文件时，我们会收集您上传的信息，以便帮助您实现上传并管理文件。</span
      >
    </p>
    <p><span>2.8 消息通知</span></p>
    <p>
      <span
        >您知悉并同意，对于你在使用本服务过程中提供的你的联系方式（例如：联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证等用途。</span
      >
    </p>
    <p><span>2.9 搜索</span></p>
    <p>
      <span
        >您使用搜索功能时，我们会收集您的搜索关键字信息、日志记录，以便向您展示搜索结果内容。</span
      >
    </p>
    <p><span>2.10 安全运行</span></p>
    <p><span>2.10.1 运营与安全保障</span></p>
    <p>
      <span
        >我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护我们服务的正常运行，保护您或其他用户或公众的合法利益免受损失，我们会收集用于维护产品或服务安全稳定运行的必要信息。 </span
      >&#x20; <span>2.10.2 日志信息</span>
    </p>
    <p>
      <span
        >我们可能使用您的帐号信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以提供的信息，用于判断账户及交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。</span
      >
    </p>
    <p><span>2.11 收集、使用个人信息目的变更</span></p>
    <p>
      <span
        >请您了解，随着我们业务的发展，可能会对{{
          environment.platformName
        }}的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用你的个人信息，会再次向您进行告知说明，并征得您的同意。</span
      >
    </p>
    <h1>
      <a name="三我们如何使用cookie和同类技术" class="md-header-anchor"></a
      ><span>三、我们如何使用Cookie和同类技术</span>
    </h1>
    <p>
      <span
        >3.1
        当您使用本服务时，我们可能会使用相关技术向你的设备发送一个或多个Cookie或匿名标识符，以收集和存储你访问、使用本服务时的信息。我们承诺，不会将Cookie用于本隐私条款所述目的之外的任何其他用途。我们使用Cookie和同类技术主要为了实现以下功能或服务：</span
      >
    </p>
    <p>
      <span
        >3.1.1 记住您的身份。例如：Cookie
        有助于我们辨认您作为我们的注册用户的身份。</span
      >
    </p>
    <p>
      <span
        >3.1.2
        帮助您获得更轻松的访问体验：使用此类技术可以帮助您省去重复你填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。</span
      >
    </p>
    <p>
      <span
        >3.2 您可以通过浏览器设置拒绝或管理 Cookie。但请注意，如果停用
        Cookie，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。</span
      >
    </p>
    <h1>
      <a
        name="四数据使用过程中涉及的合作方以及转移公开个人信息"
        class="md-header-anchor"
      ></a
      ><span>四、数据使用过程中涉及的合作方以及转移、公开个人信息</span>
    </h1>
    <p><span>4.1 数据使用过程中涉及的合作方</span></p>
    <p><span>4.1.1 原则</span></p>
    <ol start="">
      <li>
        <span
          >合法原则：与合作方合作过程中涉及数据使用活动的，必须具有合法目的、符合法定的合法性基础。如果合作方使用信息不再符合合法原则，则其不应再使用您的个人信息，或在获得相应合法性基础后再行使用。</span
        >
      </li>
      <li>
        <span
          >正当与最小必要原则：数据使用必须具有正当目的，且应以达成目的必要为限。</span
        >
      </li>
      <li>
        <span
          >安全审慎原则：
          我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。</span
        >
      </li>
    </ol>
    <p><span>4.1.2 委托处理</span></p>
    <p>
      <span
        >对于委托处理个人信息的场景，我们会与受托合作方根据法律规定签署相关处理协议，并对其个人信息使用活动进行监督。</span
      >
    </p>
    <p><span>4.1.3 共同处理</span></p>
    <p>
      <span
        >对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。</span
      >
    </p>
    <p><span>4.1.4 合作方的范围</span></p>
    <p>
      <span
        >若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。获得您的明确同意后，我们可能会与第三方共享您的个人信息。关于我们的合作方（第三方SDK）收集个人信息类型及收集目的，详情请参阅
        <a href="/bus/#/external-sdk-list">第三方SDK列表</a>。</span
      >
    </p>
    <p><span>4.1.5 实现功能或服务的数据使用</span></p>
    <ol start="">
      <li>
        <span
          >在您使用身份认证的功能或相关服务所需时，根据相关法律法规、安全保障要求可能需要完成实名认证以验证您的身份。在实名认证的过程中，与我们合作的认证服务机构需要使用您的真实姓名、身份证号码、手机号码及人脸信息。部分信息是个人敏感信息，拒绝上述使用将导致您无法完成身份认证，但不会影响{{
            environment.platformName
          }}其他功能的正常使用。这些信息仅供实名认证及法律法规所规定的用途，未经您的明示授权，不会用作其他目的。</span
        >
      </li>
      <li>
        <span
          >客户服务：为及时处理您的投诉、建议以及其他诉求，客户服务提供商需要使用您的帐号及所涉及事件的相关信息，以及时了解、处理和响应相关问题。</span
        >
      </li>
    </ol>
    <p><span>4.1.6 实现安全与分析统计的数据使用</span></p>
    <ol start="">
      <li>
        <span
          >保障使用安全：我们非常重视帐号、服务及内容安全，为保障您和其他用户的帐号与财产安全，使您和我们的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、帐号及日志信息。</span
        >
      </li>
      <li>
        <span
          >分析产品情况：为分析我们产品的使用和表现情况，我们的合作方可能需要使用该产品使用情况（崩溃、闪退）、设备标识等信息。</span
        >
      </li>
      <li>
        <span
          >为提升相关领域的科研能力，促进科技发展水平，我们在确保数据安全与目的正当的前提下，可能会与合作的科研院所、高校等机构使用去标识化或匿名化的数据。</span
        >
      </li>
    </ol>
    <p><span>4.2 转移</span></p>
    <p>
      <span
        >随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私条款所要求的安全标准继续保护或要求个人信息的继受方继续保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。</span
      >
    </p>
    <p><span>4.3 公开</span></p>
    <p>
      <span
        >我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的必要信息。</span
      >
    </p>
    <p><span>4.4 依法豁免征得同意共享、转让、公开披露的个人信息</span></p>
    <p>
      <span
        >请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：</span
      >
    </p>
    <ol start="">
      <li>
        <span
          >为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</span
        >
      </li>
      <li><span>为履行法定职责或者法定义务所必需；</span></li>
      <li><span>与国家安全、国防安全直接相关的；</span></li>
      <li><span>与刑事侦查、起诉、审判和判决执行等直接相关的</span></li>
      <li>
        <span
          >为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span
        >
      </li>
      <li>
        <span
          >为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span
        >
      </li>
      <li>
        <span
          >依照相关法律规定处理您自行公开或者其他已经合法公开的个人信息；</span
        >
      </li>
      <li>
        <span
          >从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span
        >
      </li>
      <li><span>法律、行政法规规定的其他情形。</span></li>
    </ol>
    <h1>
      <a
        name="五我们如何存储个人信息及保护个人信息的安全"
        class="md-header-anchor"
      ></a
      ><span>五、我们如何存储个人信息及保护个人信息的安全</span>
    </h1>
    <p><span>5.1 信息的存储和安全</span></p>
    <p>
      <span
        >我们使用阿里云提供的云服务托管我们收集的信息，并使用技术措施来保护您的数据。尽管我们极尽可能地保护您的信息安全，但由于互联网的固有特性，没有任何系统是绝对安全的，我们无法保证数据在通过互联网传输过程中和存储在我们系统时的绝对安全。我们会尽一切可能防止安全入侵事件的发生。但如果发生该类事件，我们将在合理期限内第一时间告知您。</span
      >
    </p>
    <p><span>5.2 存储期限</span></p>
    <p>
      <span
        >5.2.1
        您在使用本服务期间，我们将持续保存您的个人信息，保存期限将以不超过为您提供服务所必须的期间为原则。如我们停止运营本服务，我们将在合理期限内依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。在您终止使用本服务或关闭相应授权后，我们会对您的信息进行删除或做匿名化处理，但以下情况除外：</span
      >
    </p>
    <ol start="">
      <li>
        <span
          >遵从法律法规有关信息留存的要求（例如：《网络安全法》规定：采取监测、记录网络运行状态、网络安全事件的技术措施，并按照规定留存相关的网络日志不少于六个月）。</span
        >
      </li>
      <li><span>出于财务、审计、争议解决等目的需要合理延长期限的。</span></li>
    </ol>
    <h1>
      <a name="六管理您的个人信息" class="md-header-anchor"></a
      ><span>六、管理您的个人信息</span>
    </h1>
    <p>
      <span
        >6.1 您可以登录{{
          environment.platformName
        }}网站查询、管理（修改、删除）使用本服务时而提交的基本用户信息。为保障您的账号安全、合法权益等，不同的信息查询、修改和删除可能有不同的要求，并且，基于技术逻辑、法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除（例如您无法更改已开通服务的账号
        ID）。对于{{
          environment.platformName
        }}提供的产品或者服务所必需的用户信息，修改或删除后可能会影响到相应的产品或者服务的正常使用，我们有权拒绝或停止提供受到影响的产品或者服务。</span
      >
    </p>
    <p>
      <span
        >6.2
        对于您无法自行查阅的个人信息，您可以向我们提出查阅或复制请求，为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span
      >
    </p>
    <p>
      <span>6.3 在以下情形中，您可以向我们提出更正或删除用户信息的请求：</span>
    </p>
    <p><span>6.3.1 如果我们处理用户信息的行为违反法律法规；</span></p>
    <p>
      <span>6.3.2 如果我们收集、使用您的用户信息，却未征得您的明确同意；</span>
    </p>
    <p><span>6.3.3 如果我们处理个人信息的行为严重违反了与您的约定；</span></p>
    <p>
      <span
        >6.3.4 如果我们收集、存储的您的信息有错误，且您无法自行更正的；</span
      >
    </p>
    <p>
      <span
        >6.3.5
        如果针对您的信息的处理目的已实现、无法实现或者为实现处理目的不再必要的；</span
      >
    </p>
    <p>
      <span
        >6.3.6
        如果我们已经停止向您提供相应的产品或者服务，或者保存期限已届满的；</span
      >
    </p>
    <p><span>6.3.7 如果您撤回同意的。</span></p>
    <p>
      <span
        >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span
      >
    </p>
    <p>
      <span
        >6.4 您可以注销{{
          environment.platformName
        }}账号。具体操作步骤如下：</span
      >
    </p>
    <p>
      <span
        >6.4.1
        发送邮箱至：contact@zhiguangong.com，我们将协助您申请注销您的账户。</span
      >
    </p>
    <p>
      <span
        >6.4.2
        注销成功后，该账号下所有服务、数据将被删除且无法恢复。在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除您的个人信息，或对其进行匿名化处理。</span
      >
    </p>
    <h1>
      <a name="七自启动和关联启动说明" class="md-header-anchor"></a
      ><span>七、自启动和关联启动说明</span>
    </h1>
    <p>
      <span
        >为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为是因实现功能及服务所必要的。</span
      >
    </p>
    <h1>
      <a name="八未成年人条款" class="md-header-anchor"></a
      ><span>八、未成年人条款</span>
    </h1>
    <p>
      <span
        >{{
          environment.platformName
        }}产品及服务主要面向成年人，如果发现16岁以下的儿童向我们提供了个人信息，我们将采取措施删除此类信息。如果您认为我们在无意间收集了未获得许可的未成年人信息，请通过文末的【联系我们】与我们联系以便我们可以尽快删除这些信息。</span
      >
    </p>
    <h1>
      <a name="九隐私条款的修订和通知" class="md-header-anchor"></a
      ><span>九、隐私条款的修订和通知</span>
    </h1>
    <p>
      <span
        >9.1
        为了给您提供更好的服务，本服务将不时更新与变化，我们会适时对本隐私条款进行修订，这些修订构成本隐私条款的一部分并具有等同于本隐私条款的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私条款所应享受的权利。</span
      >
    </p>
    <p>
      <span
        >9.2
        本隐私条款更新后，我们会发出更新版本，并在更新后的条款生效前通过适当的方式提醒您更新的内容，以便您及时了解本隐私条款的最新版本。如果您在本隐私条款更新之后继续使用本服务，即视为您接受更新后的隐私条款。</span
      >
    </p>
    <h1>
      <a name="十联系我们" class="md-header-anchor"></a
      ><span>十、联系我们</span>
    </h1>
    <p>
      <span
        >如果您对本隐私条款有任何疑问、意见或建议，请通过以下方式与我们联系，邮箱：contact@zhiguangong.com。</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style scoped>
.mac-os-11 {
  --title-bar-height: 28px;
}

html {
  font-size: 14px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0px;
  padding: 0px;
  height: auto;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  font-size: 1rem;
  line-height: 1.42857;
  overflow-x: hidden;
  background: inherit;
  tab-size: 4;
}

iframe {
  margin: auto;
}

a.url {
  word-break: break-all;
}

a:active,
a:hover {
  outline: 0px;
}

.in-text-selection,
::selection {
  text-shadow: none;
  background: var(--select-text-bg-color);
  color: var(--select-text-font-color);
}

#write {
  margin: 0px auto;
  height: auto;
  width: inherit;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  overflow-x: visible;
  padding-top: 36px;
}

#write.first-line-indent p {
  text-indent: 2em;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
  text-indent: 0px;
}

#write.first-line-indent li {
  margin-left: 2em;
}

.for-image #write {
  padding-left: 8px;
  padding-right: 8px;
}

body.typora-export {
  padding-left: 30px;
  padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
  white-space: pre-wrap;
}

.typora-export .task-list-item input {
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  body.typora-export {
    padding-left: 0px;
    padding-right: 0px;
  }

  #write {
    padding-left: 20px;
    padding-right: 20px;
  }

  .CodeMirror-sizer {
    margin-left: 0px !important;
  }

  .CodeMirror-gutters {
    display: none !important;
  }
}

#write li > figure:last-child {
  margin-bottom: 0.5rem;
}

#write ol,
#write ul {
  position: relative;
}

img {
  max-width: 100%;
  vertical-align: middle;
  image-orientation: from-image;
}

button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  line-height: normal;
  padding: 0px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
  width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
  position: relative;
}

p {
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
  break-inside: avoid;
  orphans: 4;
}

p {
  orphans: 4;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}

.md-blockmeta {
  color: rgb(204, 204, 204);
  font-weight: 700;
  font-style: italic;
}

a {
  cursor: pointer;
}

sup.md-footnote {
  padding: 2px 4px;
  background-color: rgba(238, 238, 238, 0.7);
  color: rgb(85, 85, 85);
  border-radius: 4px;
  cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
  color: inherit;
  text-transform: inherit;
  text-decoration: inherit;
}

#write input[type="checkbox"] {
  cursor: pointer;
  width: inherit;
  height: inherit;
}

figure {
  overflow-x: auto;
  margin: 1.2em 0px;
  max-width: calc(100% + 16px);
  padding: 0px;
}

figure > table {
  margin: 0px;
}

tr {
  break-inside: avoid;
  break-after: auto;
}

thead {
  display: table-header-group;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
  overflow: auto;
  break-inside: auto;
  text-align: left;
}

table.md-table td {
  min-width: 32px;
}

.CodeMirror-gutters {
  border-right: 0px;
  background-color: inherit;
}

.CodeMirror-linenumber {
  user-select: none;
}

.CodeMirror {
  text-align: left;
}

.CodeMirror-placeholder {
  opacity: 0.3;
}

.CodeMirror pre {
  padding: 0px 4px;
}

.CodeMirror-lines {
  padding: 0px;
}

div.hr:focus {
  cursor: none;
}

#write pre {
  white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
  white-space: pre;
}

#write pre.ty-contain-cm {
  white-space: normal;
}

.CodeMirror-gutters {
  margin-right: 4px;
}

.md-fences {
  font-size: 0.9rem;
  display: block;
  break-inside: avoid;
  text-align: left;
  overflow: visible;
  white-space: pre;
  background: inherit;
  position: relative !important;
}

.md-diagram-panel {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 8px;
  overflow-x: auto;
}

#write .md-fences.mock-cm {
  white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
  padding-left: 0px;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
  white-space: pre;
  overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
  padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
  break-inside: avoid;
}

.footnotes {
  opacity: 0.8;
  font-size: 0.9rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.footnotes + .footnotes {
  margin-top: 0px;
}

.md-reset {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  vertical-align: top;
  background: 0px 0px;
  text-decoration: none;
  text-shadow: none;
  float: none;
  position: static;
  width: auto;
  height: auto;
  white-space: nowrap;
  cursor: inherit;
  -webkit-tap-highlight-color: transparent;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  box-sizing: content-box;
  direction: ltr;
}

li div {
  padding-top: 0px;
}

blockquote {
  margin: 1rem 0px;
}

li .mathjax-block,
li p {
  margin: 0.5rem 0px;
}

li blockquote {
  margin: 1rem 0px;
}

li {
  margin: 0px;
  position: relative;
}

blockquote > :last-child {
  margin-bottom: 0px;
}

blockquote > :first-child,
li > :first-child {
  margin-top: 0px;
}

.footnotes-area {
  color: rgb(136, 136, 136);
  margin-top: 0.714rem;
  padding-bottom: 0.143rem;
  white-space: normal;
}

#write .footnote-line {
  white-space: pre-wrap;
}

@media print {
  body,
  html {
    border: 1px solid transparent;
    height: 99%;
    break-after: avoid;
    break-before: avoid;
    font-variant-ligatures: no-common-ligatures;
  }

  #write {
    margin-top: 0px;
    padding-top: 0px;
    border-color: transparent !important;
  }

  .typora-export * {
    -webkit-print-color-adjust: exact;
  }

  .typora-export #write {
    break-after: avoid;
  }

  .typora-export #write::after {
    height: 0px;
  }

  .is-mac table {
    break-inside: avoid;
  }
}

.footnote-line {
  margin-top: 0.714em;
  font-size: 0.7em;
}

a img,
img a {
  cursor: pointer;
}

pre.md-meta-block {
  font-size: 0.8rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
  background: rgb(204, 204, 204);
  display: block;
  overflow-x: hidden;
}

p > .md-image:only-child:not(.md-img-error) img,
p > img:only-child {
  display: block;
  margin: auto;
}

#write.first-line-indent p > .md-image:only-child:not(.md-img-error) img {
  left: -2em;
  position: relative;
}

p > .md-image:only-child {
  display: inline-block;
  width: 100%;
}

#write .MathJax_Display {
  margin: 0.8em 0px 0px;
}

.md-math-block {
  width: 100%;
}

.md-math-block:not(:empty)::after {
  display: none;
}

.MathJax_ref {
  fill: currentcolor;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus,
[contenteditable="false"]:active,
[contenteditable="false"]:focus {
  outline: 0px;
  box-shadow: none;
}

.md-task-list-item {
  position: relative;
  list-style-type: none;
}

.task-list-item.md-task-list-item {
  padding-left: 0px;
}

.md-task-list-item > input {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: -1.2em;
  margin-top: calc(1em - 10px);
  border: none;
}

.math {
  font-size: 1rem;
}

.md-toc {
  min-height: 3.58rem;
  position: relative;
  font-size: 0.9rem;
  border-radius: 10px;
}

.md-toc-content {
  position: relative;
  margin-left: 0px;
}

.md-toc-content::after,
.md-toc::after {
  display: none;
}

.md-toc-item {
  display: block;
  color: rgb(65, 131, 196);
}

.md-toc-item a {
  text-decoration: none;
}

.md-toc-inner:hover {
  text-decoration: underline;
}

.md-toc-inner {
  display: inline-block;
  cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
  margin-left: 0px;
  font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
  margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
  margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
  margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
  margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
  margin-left: 10em;
}

@media screen and (max-width: 48em) {
  .md-toc-h3 .md-toc-inner {
    margin-left: 3.5em;
  }

  .md-toc-h4 .md-toc-inner {
    margin-left: 5em;
  }

  .md-toc-h5 .md-toc-inner {
    margin-left: 6.5em;
  }

  .md-toc-h6 .md-toc-inner {
    margin-left: 8em;
  }
}

a.md-toc-inner {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
  color: inherit;
}

.md-attr {
  display: none;
}

.md-fn-count::after {
  content: ".";
}

code,
pre,
samp,
tt {
  font-family: var(--monospace);
}

kbd {
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: rgb(36, 39, 41);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(173, 179, 185);
  border-radius: 3px;
  box-shadow: rgba(12, 13, 14, 0.2) 0px 1px 0px,
    rgb(255, 255, 255) 0px 0px 0px 2px inset;
  white-space: nowrap;
  vertical-align: middle;
}

.md-comment {
  color: rgb(162, 127, 3);
  opacity: 0.8;
  font-family: var(--monospace);
}

code {
  text-align: left;
  vertical-align: initial;
}

a.md-print-anchor {
  white-space: pre !important;
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  display: inline-block !important;
  position: absolute !important;
  width: 1px !important;
  right: 0px !important;
  outline: 0px !important;
  background: 0px 0px !important;
  text-decoration: initial !important;
  text-shadow: initial !important;
}

.md-inline-math .MathJax_SVG .noError {
  display: none !important;
}

.html-for-mac .inline-math-svg .MathJax_SVG {
  vertical-align: 0.2px;
}

.md-math-block .MathJax_SVG_Display {
  text-align: center;
  margin: 0px;
  position: relative;
  text-indent: 0px;
  max-width: none;
  max-height: none;
  min-height: 0px;
  min-width: 100%;
  width: auto;
  overflow-y: hidden;
  display: block !important;
}

.MathJax_SVG_Display,
.md-inline-math .MathJax_SVG_Display {
  width: auto;
  margin: inherit;
  display: inline-block !important;
}

.MathJax_SVG .MJX-monospace {
  font-family: var(--monospace);
}

.MathJax_SVG .MJX-sans-serif {
  font-family: sans-serif;
}

.MathJax_SVG {
  display: inline;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  zoom: 90%;
  text-indent: 0px;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  overflow-wrap: normal;
  white-space: nowrap;
  float: none;
  direction: ltr;
  max-width: none;
  max-height: none;
  min-width: 0px;
  min-height: 0px;
  border: 0px;
  padding: 0px;
  margin: 0px;
}

.MathJax_SVG * {
  transition: none 0s ease 0s;
}

.MathJax_SVG_Display svg {
  vertical-align: middle !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.os-windows.monocolor-emoji .md-emoji {
  font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel > svg {
  max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
  max-width: 100%;
  height: auto;
}

[lang="mermaid"] .node text {
  font-size: 1rem;
}

table tr th {
  border-bottom: 0px;
}

video {
  max-width: 100%;
  display: block;
  margin: 0px auto;
}

iframe {
  max-width: 100%;
  width: 100%;
  border: none;
}

.highlight td,
.highlight tr {
  border: 0px;
}

mark {
  background: rgb(255, 255, 0);
  color: rgb(0, 0, 0);
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
  color: inherit;
}

mark .md-meta {
  color: rgb(0, 0, 0);
  opacity: 0.3 !important;
}

@media print {
  .typora-export h1,
  .typora-export h2,
  .typora-export h3,
  .typora-export h4,
  .typora-export h5,
  .typora-export h6 {
    break-inside: avoid;
  }
}

.md-diagram-panel .messageText {
  stroke: none !important;
}

.md-diagram-panel .start-state {
  fill: var(--node-fill);
}

.md-diagram-panel .edgeLabel rect {
  opacity: 1 !important;
}

.md-require-zoom-fix foreignobject {
  font-size: var(--mermaid-font-zoom);
}

:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
}

@include-when-export url(https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext);

/* open-sans-regular - latin-ext_latin */
/* open-sans-italic - latin-ext_latin */
/* open-sans-700 - latin-ext_latin */
/* open-sans-700italic - latin-ext_latin */
html {
  font-size: 16px;
}

body {
  font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: rgb(51, 51, 51);
  line-height: 1.6;
}

#write {
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}

#write > ul:first-child,
#write > ol:first-child {
  margin-top: 30px;
}

a {
  color: #4183c4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.4;
  cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  text-decoration: none;
}

h1 tt,
h1 code {
  font-size: inherit;
}

h2 tt,
h2 code {
  font-size: inherit;
}

h3 tt,
h3 code {
  font-size: inherit;
}

h4 tt,
h4 code {
  font-size: inherit;
}

h5 tt,
h5 code {
  font-size: inherit;
}

h6 tt,
h6 code {
  font-size: inherit;
}

h1 {
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}

h2 {
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

/*@media print {
        .typora-export h1,
        .typora-export h2 {
            border-bottom: none;
            padding-bottom: initial;
        }

        .typora-export h1::after,
        .typora-export h2::after {
            content: "";
            display: block;
            height: 100px;
            margin-top: -96px;
            border-top: 1px solid #eee;
        }
    }*/

h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 1em;
  color: #777;
}

p,
blockquote,
ul,
ol,
dl,
table {
  margin: 0.8em 0;
}

li > ol,
li > ul {
  margin: 0 0;
}

hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}

ul,
ol {
  padding-left: 30px;
}

ul:first-child,
ol:first-child {
  margin-top: 0;
}

ul:last-child,
ol:last-child {
  margin-bottom: 0;
}

blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777777;
}

blockquote blockquote {
  padding-right: 0;
}

table {
  padding: 0;
  word-break: initial;
}

table tr {
  border-top: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}

table th {
  font-weight: bold;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}

table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}

table th:first-child,
table td:first-child {
  margin-top: 0;
}

table th:last-child,
table td:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0px 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

@media print {
  html {
    font-size: 13px;
  }

  table,
  pre {
    page-break-inside: avoid;
  }

  pre {
    word-wrap: break-word;
  }
}

.md-fences {
  background-color: #f8f8f8;
}

#write pre.md-meta-block {
  padding: 1rem;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 3px;
  color: #777777;
  margin-top: 0 !important;
}

.mathjax-block > .code-tooltip {
  bottom: 0.375rem;
}

.md-mathjax-midline {
  background: #fafafa;
}

#write > h3.md-focus:before {
  left: -1.5625rem;
  top: 0.375rem;
}

#write > h4.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h5.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h6.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

.md-image > .md-meta {
  /*border: 1px solid #ddd;*/
  border-radius: 3px;
  padding: 2px 0px 0px 4px;
  font-size: 0.9em;
  color: inherit;
}

.md-tag {
  color: #a7a7a7;
  opacity: 1;
}

.md-toc {
  margin-top: 20px;
  padding-bottom: 20px;
}

.sidebar-tabs {
  border-bottom: none;
}

#typora-quick-open {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

#typora-quick-open-item {
  background-color: #fafafa;
  border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
  border-style: solid;
  border-width: 1px;
}

/** focus mode */
.on-focus-mode blockquote {
  border-left-color: rgba(85, 85, 85, 0.12);
}

header,
.context-menu,
.megamenu-content,
footer {
  font-family: "Segoe UI", "Arial", sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
  visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
  background-color: #fafafa;
  background-color: var(--side-bar-bg-color);
}

.md-lang {
  color: #b4654d;
}

.html-for-mac .context-menu {
  --item-hover-bg-color: #e6f0fe;
}

#md-notification .btn {
  border: 0;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
}

.ty-preferences .window-content {
  background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
  color: white;
  background: #999;
}

p {
  line-height: 1.6;
}

:root {
  --mermaid-font-zoom: 1.25em;
}
</style>
