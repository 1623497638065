<template>
  <el-dialog
    custom-class="no-header-dialog"
    append-to-body
    destroy-on-close
    :visible.sync="visible"
    fullscreen
    :show-close="false"
    :show-header="false"
  >
    <div>
      <div class="header">
        <div style="padding: 0 10px">自定义登录页设置</div>
        <div class="flex-end">
          <el-button size="mini" @click="visible = false">关闭 </el-button>
          <el-button size="mini" @click="save" type="primary">保存 </el-button>
        </div>
      </div>
      <div class="container">
        <div class="body" :style="backgroundStyle">
          <img
            class="image"
            :src="loginDetail.logo || '@/assets/LOGO.png'"
            :style="`height:${config.logoSize}px`"
          />
          <div class="main-body">
            <div
              class="picture"
              v-if="config.show"
              :style="homepageStyle"
            ></div>
            <div class="login" :style="config.show ? '' : 'border-radius:8px'">
              <div class="login-tab-change">
                <div class="tab-item active">验证码登录</div>
                <div class="tab-item">密码登录</div>
              </div>
              <el-form
                style="width: 100%; margin-bottom: 24px"
                ref="codeLoginForm"
                label-position="left"
              >
                <el-form-item prop="mobile">
                  <el-input
                    placeholder="请输入手机号"
                    type="tel"
                    auto-complete="on"
                    disabled
                  />
                </el-form-item>

                <el-form-item prop="code">
                  <div style="display: flex; flex-direction: row">
                    <div style="flex: 1">
                      <el-input placeholder="请输入验证码" disabled />
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 16px;
                      "
                    >
                      <span class="code-count-span">获取验证码</span>
                    </div>
                  </div>
                </el-form-item>
                <el-button type="primary" class="login-button">登录 </el-button>
              </el-form>
              <div
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  margin-bottom: 24px;
                "
              >
                <div style="display: flex" v-if="loginDetail.userRegisterFlag">
                  <div style="color: #909399">没有账号？</div>
                  <el-link type="primary" :underline="false">立即注册</el-link>
                </div>
                <div v-else></div>
                <el-link type="primary" :underline="false">忘记密码</el-link>
              </div>
              <div
                v-if="loginDetail.thirdPartLoginFlag"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                "
              >
                <div class="wechat-login-tip">
                  <div class="tip-line"></div>
                  <span class="tip-label">微信登陆</span>
                  <div class="tip-line"></div>
                </div>
                <div
                  style="
                    width: 46px;
                    height: 46px;
                    background: #07c160;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                  "
                >
                  <i
                    class="iconfont icon-Wechat"
                    style="color: white; margin-right: 0px; font-size: 24px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="setting-box">
          <div class="setting-item">
            <div class="label">企业LOGO</div>
            <zgg-upload
              :multiple="false"
              :drag="false"
              :show-file-list="false"
              :beforeAvatarUpload="beforeUpload"
              @success="uploadLogoSuccess"
              accept=".jpg,.jpeg,.png"
            >
              <template slot="default">
                <div class="upload-btn" v-if="loginDetail.logo">
                  <img :src="loginDetail.logo" />
                  <span class="edit-icon">
                    <i
                      class="iconfont icon-bianji_fill"
                      style="color: #fff; font-size: 18px; margin-right: 0"
                    ></i>
                  </span>
                </div>
                <div class="upload-btn" v-else>上传图片，5M以内</div>
              </template>
            </zgg-upload>
            <div class="config-item">
              <div class="label" style="margin-bottom: 0">LOGO大小</div>
              <el-input-number
                v-model="config.logoSize"
                :precision="0"
                :step="1"
                size="small"
                :min="1"
                :max="999"
              ></el-input-number>
            </div>
          </div>
          <div class="setting-item">
            <div class="label">宣传图<span>建议尺寸：450*520px</span></div>
            <el-radio-group v-model="config.show" style="margin-bottom: 4px">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">隐藏</el-radio>
            </el-radio-group>
            <div v-show="config.show">
              <zgg-upload
                :multiple="false"
                :drag="false"
                :show-file-list="false"
                :beforeAvatarUpload="beforeUpload"
                @success="uploadHomePageImgSuccess"
                accept=".jpg,.jpeg,.png"
              >
                <template slot="default">
                  <div class="upload-btn" v-if="loginDetail.homePageImg">
                    <img :src="loginDetail.homePageImg" />
                    <span class="edit-icon">
                      <i
                        class="iconfont icon-bianji_fill"
                        style="color: #fff; font-size: 18px; margin-right: 0"
                      ></i>
                    </span>
                  </div>
                  <div class="upload-btn" v-else>上传图片，5M以内</div>
                </template>
              </zgg-upload>
              <div class="config-item">
                <div class="label" style="margin-bottom: 0">填充方式</div>
                <el-select
                  v-model="config.fillStyle"
                  size="small"
                  @change="changeStyle"
                >
                  <el-option label="居中填满" value="cover"></el-option>
                  <el-option label="拉伸填满" value="fill"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="setting-item">
            <div class="label">背景设置</div>
            <background-config
              @updateBgColor="updateBgColor"
              label="页面背景"
              :colorConfig="this.config.colorConfig"
            ></background-config>
          </div>
          <div class="setting-item">
            <div class="label">第三方账号登录</div>
            <el-radio-group v-model="loginDetail.thirdPartLoginFlag">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">隐藏</el-radio>
            </el-radio-group>
          </div>
          <div class="setting-item">
            <div class="label">注册入口</div>
            <el-radio-group v-model="loginDetail.userRegisterFlag">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">隐藏</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import request from "@/utils/request";
import zggUpload from "@/components/Upload/zgg-upload.vue";
import backgroundConfig from "@/views/lowCode/dashboard/detail-component/background-config.vue";
import radio from "element-ui/packages/radio";

export default {
  components: { zggUpload, backgroundConfig },
  data() {
    return {
      config: {
        logoSize: 36,
        show: true,
        fillStyle: "fill",
      },
      backgroundStyle: "",
      homepageStyle: "",
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    loginDetail: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    radio() {
      return radio;
    },
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
  },
  created() {
    if (!this.loginDetail.hasOwnProperty("thirdPartLoginFlag")) {
      this.$set(this.loginDetail, "thirdPartLoginFlag", true);
    }
    if (!this.loginDetail.hasOwnProperty("userRegisterFlag")) {
      this.$set(this.loginDetail, "userRegisterFlag", true);
    }
    if (this.loginDetail.config) {
      Object.assign(this.config, this.loginDetail.config);
    }
    this.changeStyle(this.config.fillStyle);
    let colorConfig = this.config.colorConfig;
    if (colorConfig) {
      this.updateBgColor(
        colorConfig.backgroundEnum == "image"
          ? colorConfig.backgroundImage
          : colorConfig.backgroundColor,
        colorConfig.backgroundEnum,
        colorConfig.backgroundImageFillEnum,
      );
    }
  },
  methods: {
    changeStyle(value) {
      this.homepageStyle = `background-image: url(${
        this.loginDetail.homePageImg
      }); background-size:${
        value == "cover" ? "cover" : "100% 100%"
      };background-position: center;background-repeat: no-repeat;`;
    },
    updateBgColor(background, backgroundEnum, backgroundImageFillEnum) {
      if (background) {
        if (backgroundEnum == "image") {
          this.config.colorConfig = {
            backgroundEnum: "image",
            backgroundImage: background,
            backgroundImageFillEnum: backgroundImageFillEnum || "stretch_fill",
          };
          this.backgroundStyle = `background-image: url(${background}); background-size:${
            backgroundImageFillEnum == "center_fill" ? "cover" : "100% 100%"
          };background-position: center;background-repeat: no-repeat;`;
        } else if (backgroundEnum == "color") {
          this.config.colorConfig = {
            backgroundEnum: "color",
            backgroundColor: background,
            backgroundImage: "",
          };
          if (background.includes(",") && !background.includes("rgba")) {
            this.backgroundStyle = `background:linear-gradient(${background})`;
          } else {
            this.backgroundStyle = `background-color:${background}`;
          }
        }
      } else {
        this.config.colorConfig = {
          backgroundEnum: backgroundEnum,
          backgroundImageFillEnum: backgroundImageFillEnum,
        };
        this.backgroundStyle = "";
      }
    },
    uploadLogoSuccess(fileList, file) {
      this.$set(this.loginDetail, "logo", file?.response?.url);
    },
    uploadHomePageImgSuccess(fileList, file) {
      this.$set(this.loginDetail, "homePageImg", file?.response?.url);
      this.changeStyle(this.config.fillStyle);
    },
    save() {
      this.$set(this.loginDetail, "config", this.config);
      this.$emit("save");
    },
    beforeUpload(file) {
      if (file.size > 5120000) {
        this.$message({
          message: "请选择5MB以内的jpg、png、jpeg或gif图",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    upload(uploadDetail) {
      let params = new FormData();
      params.append("attachmentFile", uploadDetail.file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      request({
        url: "/attachment/upload",
        method: "post",
        headers: config.headers,
        data: params,
      })
        .then((resp) => {
          if (resp.code === 0) {
            this.backgroundImage = resp.data.attachment.url;
            this.$emit(
              "updateBgColor",
              this.backgroundImage,
              this.backgroundEnum,
              this.backgroundImageFillEnum,
            );
          } else {
            this.$message({
              message: "上传失败",
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            message: "上传出错，服务器开小差了呢",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
$blue: #3488ff;
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}
.container {
  display: flex;
  height: calc(100vh - 50px);
}
.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: var(--zgg-background-base);
  .image {
    position: absolute;
    top: 24px;
    left: 24px;
    width: auto;
  }
}
.setting-box {
  border-left: solid 1px #f4f5f7;
  width: 220px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 24px 16px;
  .setting-item {
    margin-bottom: 24px;
  }
  .label {
    color: var(--font-text-primary, #172b4d);
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
  }
}
.main-body {
  display: flex;
  height: 520px;
  box-shadow: 0px 0px 8px rgba(9, 30, 66, 0.08),
    0px 1px 8px rgba(9, 30, 66, 0.08);
  border-radius: 15px;
  .picture {
    width: 450px;
    height: 100%;
    background: rgba(193, 203, 219, 1);
    border-radius: 8px 0 0 8px;
  }
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 450px;
    padding: 56px 80px;
    background: #ffffff;
    border-radius: 0 8px 8px 0;
    .login-tab-change {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      width: 100%;
      margin-bottom: 24px;

      .tab-item {
        display: flex;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: var(--zgg-font-text-secondary);
        height: 30px;
        cursor: pointer;

        &:hover {
        }
      }

      .tab-item.active {
        color: rgba(9, 30, 66, 0.95);
        position: relative;
      }

      .tab-item.active::after {
        position: absolute;
        content: "";
        width: 24.23px;
        height: 4px;
        background-color: var(--blue-6);
        border-radius: 13px;
        bottom: 0;
        left: 0;
      }
    }
    ::v-deep {
      .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;

        input {
          background: transparent;
          border: 0px;
          -webkit-appearance: none;
          border-radius: 0px;
          height: 45px;
          &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px #fafafa inset !important;
          }
        }
      }

      .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #fafafa;
        border-radius: 4px;
        color: #454545;
      }

      .code-count-span {
        color: $blue;
        display: flex;
        height: 32px;
        width: 80px;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid $blue;
        border-radius: 4px;
        font-size: 12px;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }

      .login-button {
        height: 46px;

        background: var(--blue-6);
        box-shadow: 0px 3px 6px -4px #3488ff,
          0px 6px 16px rgba(52, 136, 255, 0.08),
          0px 9px 28px 8px rgba(52, 136, 255, 0.05);
        border-radius: 5px;
        width: 100%;
      }
    }
    .wechat-login-tip {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 12px 0;
    }
    .tip-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(9, 30, 66, 0.65);
    }
    .tip-line {
      width: 60px;
      height: 0;
      border-bottom: 1px solid rgba(9, 30, 66, 0.25);
    }
  }
}
.upload-btn {
  display: flex;
  position: relative;
  width: 187px;
  height: 34px;
  font-size: 13px;
  line-height: 34px;
  color: var(--zgg-font-text-regular);
  border-radius: 5px;
  border: 1px dashed var(--zgg-border-base);
  background: var(--white);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  .edit-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.6);
  }
  &:hover {
    .edit-icon {
      display: block;
    }
  }
}
.config-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  ::v-deep .el-input-number--small {
    width: 110px;
  }
  ::v-deep .el-input--small {
    width: 110px;
  }
  ::v-deep .el-input-number .el-input__inner {
    padding: 0 44px;
  }
}
</style>
<style lang="scss">
.no-header-dialog .el-dialog__header {
  display: none;
}
</style>
