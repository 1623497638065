<template>
  <el-popover :width="width" placement="bottom-start" v-model="visible">
    <el-button slot="reference" type="text" icon="el-icon-plus">{{
      title
    }}</el-button>
    <div>
      <el-input
        style="margin-bottom: 5px"
        v-model="keyword"
        size="small"
        placeholder=""
        prefix-icon="el-icon-search"
      ></el-input>
      <div class="field-scroll">
        <div
          v-for="item in componentList"
          :key="item.name"
          v-text="item.comment"
          class="field-item"
          :class="item.disabled ? 'disabled' : ''"
          :title="item.comment"
          @click="addItem(item)"
        ></div>
      </div>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: {
    title: String,
    fieldList: Array,
    width: {
      type: Number,
      default() {
        return 200;
      },
    },
  },
  computed: {
    componentList() {
      if (this.keyword) {
        return this.fieldList.filter(
          (item) => item.comment.indexOf(this.keyword) >= 0,
        );
      }
      return this.fieldList;
    },
  },
  data() {
    return {
      keyword: "",
      visible: false,
    };
  },
  methods: {
    addItem(item) {
      if (item.disabled) {
        return;
      }
      this.$emit("addItem", this._.cloneDeep(item));
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.field-scroll {
  max-height: 240px;
  overflow: auto;
}
.field-item {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
  padding: 3px 0;
  cursor: pointer;
  &:hover {
    background-color: #e3f6ff;
  }
  &.disabled {
    color: #c3cdda;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #ffffff;
  }
}
</style>
