<template>
  <div class="left-container">
    <div class="source-box">
      <div class="source-header">
        <div>数据源</div>
        <div class="action" @click="dialogVisible = true" v-if="switchAble">
          更改数据源
        </div>
      </div>
      <div style="font-size: 14px">{{ formTitle }}</div>
    </div>
    <div class="column-list">
      <draggable
        :list="orignColumns"
        :options="options1"
        :move="onMove"
        dragClass="drag-item"
        animation="300"
        filter=".sub-col-item"
        @start="onStart"
        @end="onEnd"
        @choose="choose"
        @unchoose="unchoose"
        :disabled="disabled"
      >
        <template v-for="(column, index) in orignColumns">
          <!-- v-if="!(activeName == 'table' && column.field.name == 'flowStatus')" -->
          <div
            v-show="
              !(
                (isDetailTable && column.field.name == 'attachmentList') ||
                column.field.name == 'commentCount'
              )
            "
            class="column-item"
            :key="index"
            :data-index="index"
            :style="{
              background: isSelect(column)
                ? 'var(--zgg-brand-color-2)'
                : 'unset',
            }"
          >
            <i
              v-if="
                ((column.field.componentName == 'json_form' &&
                  activeName == 'form') ||
                  column.field.componentName == 'reference_data') &&
                (column.field.componentName != 'reference_data' ||
                  viewType != 'dashboard')
              "
              :class="
                column.subVisible ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
              "
              class="sub-col-item"
              @click="column.subVisible = !column.subVisible"
            ></i>
            <i
              v-if="column.title == '当前节点'"
              class="iconfont icon-liucheng"
            ></i>
            <i
              v-else
              class="iconfont"
              :class="componentIcons[column.field.componentName]"
            ></i>
            <span v-text="column.title"></span>
            <draggable
              :list="column.components"
              :options="options1"
              :move="onMove"
              dragClass="drag-item"
              animation="300"
              v-show="column.subVisible"
              class="sub-container"
              @start="onStartSub($event, index)"
              @end="onEnd"
              @choose="choose"
              @unchoose="unchooseSub($event, index)"
              v-if="
                (column.field.componentName == 'json_form' &&
                  activeName == 'form') ||
                column.field.componentName == 'reference_data'
              "
            >
              <!--仪表盘关联字段不显示子字段-->
              <div
                class="sub-col-item"
                v-if="
                  column.field.componentName != 'reference_data' ||
                  viewType != 'dashboard'
                "
                v-for="(col, cIndex) in column.components"
                :key="cIndex"
                :data-parent-index="index"
                :data-index="cIndex"
                v-text="col.title"
                :style="{
                  background: isSelect(col)
                    ? 'var(--zgg-brand-color-2)'
                    : 'unset',
                }"
              ></div>
            </draggable>
          </div>
        </template>
      </draggable>
    </div>
    <!-- 选择数据源 -->
    <data-source
      :visible.sync="dialogVisible"
      v-model="tableName"
      :menus="menus"
    ></data-source>
  </div>
</template>
<script>
import { getDataflow, getFormDetail } from "../../form/api";
import Draggable from "vuedraggable";
import Pagination from "@/components/Pagination";
import { ganttFilterFields } from "@zgg-core-utils/whiteList";
import dataSource from "@/components/data-source";
import { fetchMetaFieldComponentList } from "@/api/form";
import { aggregateDetail, getViewDetail } from "../api";
import { componentsIcon } from "@zgg-core-utils/utils";
export default {
  components: { Draggable, Pagination, dataSource },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    formId: [String, Number],
    activeName: String,
    onMove: Function,
    useType: {
      type: String,
      default() {
        return "table";
      },
    },
    selectArray: {
      type: Array,
      default() {
        return [];
      },
    },
    viewType: {
      type: String,
      default() {
        return "viewList";
      },
    },
    /**
     * 是否允许切换数据源
     */
    switchAble: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //是否明细表
    isDetailTable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    tableName: {
      get() {
        return this.formId;
      },
      set(val) {
        if (val == this.formId) {
          return;
        }
        this.confirmRow(val);
      },
    },
  },
  data() {
    return {
      formTitle: "",
      dialogVisible: false,
      orignColumns: [],

      options1: {
        group: {
          name: "column",
          pull: "clone",
          put: false,
        },
        sort: false,
      },

      unChooseEvenFlag: false,
      menus: [],
      componentIcons: componentsIcon,
    };
  },
  created() {
    this.getFormView(this.formId, (orignColumns) => {
      this.$emit("initData", orignColumns);
    });
    if (["viewList"].includes(this.viewType)) {
      this.menus = ["form", "dataflow", "aggregate_table"];
    } else if (["dashboard"].includes(this.viewType)) {
      this.menus = [
        "form",
        "dataflow",
        "aggregate_table",
        "group_form",
        "group_dataflow",
        "group_aggregate_table",
      ];
    } else {
      this.menus = ["form"];
    }
  },
  destroyed() {
    this.dialogVisible = false;
  },
  methods: {
    isSelect(row) {
      let result = this.selectArray.some((item) => {
        if (row.referenceName) {
          return (
            item.referenceName == row.referenceName &&
            item.field.name == row.field.name
          );
        } else {
          return !item.referenceName && item.field.name == row.field.name;
        }
      });

      return result;
    },
    onStartSub(e, originColumnIndex) {
      this.unChooseEvenFlag = false;
      this.$emit(
        "onStart",
        this.orignColumns[originColumnIndex].components[e.oldIndex],
        undefined,
        true,
      );
    },
    unchooseSub(e, originColumnIndex) {
      if (this.unChooseEvenFlag) {
        this.$emit(
          "onStart",
          this.orignColumns[originColumnIndex].components[e.oldIndex],
          e,
          true,
        );
      }
    },
    unchoose(e) {
      if (this.useType == "tree_table" && this.activeName == "table") {
        return;
      }
      if (this.unChooseEvenFlag) {
        this.$emit("onStart", this.orignColumns[e.oldIndex], e);
      }
    },
    choose(e) {
      this.unChooseEvenFlag = true;
    },
    onEnd() {
      this.$emit("onEnd");
      this.unChooseEvenFlag = false;
    },
    onStart(e) {
      this.unChooseEvenFlag = false;
      this.$emit("onStart", this.orignColumns[e.oldIndex]);
    },

    async getFormView(formId, callback) {
      let componentList = [];

      if (formId.indexOf("dataflow_") == 0) {
        let resForm = await getDataflow(formId);
        this.formTitle = resForm.data.dataflow.title;
        let output = resForm.data.dataflow.flow.stages.find(
          (item) => item.stageType == "output",
        );
        componentList = output.fields.map((item) => {
          item.component.key = item.name;
          item.component.name = item.name;
          item.component.title = item.comment;
          return item.component;
        });
      } else if (formId.indexOf("at_") == 0) {
        let resp = await Promise.all([
          aggregateDetail({ collection: formId, removeComponent: true }),
          fetchMetaFieldComponentList(formId, []),
        ]);
        let resForm = resp[0];
        this.formTitle = resForm.data.aggregateTable.title;
        componentList = resp[1].data.list;
      } else {
        let arr = [
          "updaterId",
          "createrId",
          "createdTime",
          "flowStatus",
          "currentFlowNodeTitles",
          "currentFlowWorkUserIds",
        ];
        if (this.useType == "table") {
          arr = ["attachmentList", "updatedTime", ...arr];
        }

        let resp = await Promise.all([
          getFormDetail(formId, true),
          fetchMetaFieldComponentList(formId, arr),
        ]);

        let resForm = resp[0];
        if (resForm.code) {
          this.$message.error(resForm.msg);
          return;
        }

        componentList = resp[1].data.list;

        this.formTitle = resForm.data.form.title;
      }

      let list = [];
      componentList.forEach((item) => {
        if (item.componentName == "json_form") {
          //需求要求放开子表单中关联数据的限制  task_2022092800020 by 徐文翔 陈鹏 郑忠安
          // item.components = item.components.filter(
          //     (opt) => opt.componentName != 'reference_data'
          // )
        }
      });

      let buildItem = (item) => {
        let picker = item.picker;
        if (["table", "tree_table"].includes(this.useType)) {
          //领导要求，文本类型的自动选择“包含”
          if (item.componentName == "input") {
            item.queryOper = "like";
          } else if (
            ["position_input", "address_input"].includes(item.componentName)
          ) {
            item.queryOper = "belong";
            if (item.picker == "address") {
              picker = "county";
            }
            if (!picker) {
              picker = "county";
            }
          } else {
            item.queryOper = "eq";
          }
        }

        let obj = {
          key: "",
          title: item.title,
          width: "",
          field: {
            ...item,
            picker,
            component: this._.cloneDeep(item),
            name: item.name || item.key,
          },
          align: "center",
        };
        if (!["table", "tree_table"].includes(this.useType)) {
          obj.orderType = "";
          if (item.componentName == "input_number") {
            obj.oper = "sum";
          } else {
            obj.oper = "count";
          }
        }
        this.$emit("getOptionSourceList", item);

        if (item.componentName == "json_form") {
          obj.subVisible = true;
          obj.components = [];
          item.components.forEach((sub) => {
            this.$emit("getOptionSourceList", sub);
            sub.name = item.name + "." + sub.name;
            let picker = sub.picker;
            if (["table", "tree_table"].includes(this.useType)) {
              //领导要求，文本类型的自动选择“包含”
              if (sub.componentName == "input") {
                sub.queryOper = "like";
              } else if (
                ["position_input", "address_input"].includes(sub.componentName)
              ) {
                sub.queryOper = "belong";
                if (sub.picker == "address") {
                  picker = "county";
                }
                if (!picker) {
                  picker = "county";
                }
              } else {
                sub.queryOper = "eq";
              }
            }

            let child = {
              key: "",
              title: sub.title,
              width: "",
              field: { ...sub, picker, component: this._.cloneDeep(sub) },
              align: "center",
            };
            if (!["table", "tree_table"].includes(this.useType)) {
              child.orderType = "";
              if (sub.componentName == "input_number") {
                child.oper = "sum";
              } else {
                child.oper = "count";
              }
            }

            obj.components.push(child);
          });
        } else if (item.componentName == "reference_data") {
          obj.subVisible = true;
          obj.components = [];

          if (item.columns) {
            item.columns.forEach((col) => {
              let queryOper = "";
              let picker = col.field.component.picker;
              if (col.field.component.componentName == "input") {
                queryOper = "like";
              } else if (
                ["position_input", "address_input"].includes(
                  col.field.component.componentName,
                )
              ) {
                queryOper = "belong";
                if (col.field.component.picker == "address") {
                  picker = "county";
                }
                if (!picker) {
                  picker = "county";
                }
              } else {
                queryOper = "eq";
              }
              let child = {
                key: "",
                title: col.title,
                width: "",
                field: {
                  ...col.field.component,
                  picker,
                  queryOper: queryOper,
                  title: col.title,
                  component: col.field.component,
                },
                align: "center",
                collection: item.tableName,
                referenceName: item.name,
              };

              if (col.field.componentName == "json_form") {
                child.components = this._.cloneDeep(col.subColumns);
                child.subColumns = this._.cloneDeep(col.subColumns);
              }
              if (!["table", "tree_table"].includes(this.useType)) {
                child.orderType = "";
                if (col.componentName == "input_number") {
                  col.oper = "sum";
                } else {
                  col.oper = "count";
                }
              }

              obj.components.push(child);
            });
          }
        }
        return obj;
      };

      let buildItemByList = (componentList) => {
        componentList
          .filter((item) => item.componentName != "include_form")
          .forEach((item) => {
            if (item.form) {
              if (
                !(
                  !["table", "tree_table", "gantt"].includes(this.useType) &&
                  item.componentName == "json_form"
                )
              ) {
                let obj = this._.cloneDeep(buildItem(item));
                list.push(obj);
              }
            } else if (item.container) {
              buildItemByList(item.components);
            } else if (
              item.componentName == "form_data" ||
              item.componentName == "summary_data"
            ) {
              if (item.componentName == "form_data") {
                // 关联查询组件在列表中展示过滤掉子表单
                item.columns = item.columns.filter(
                  (child) => child.field.componentName != "json_form",
                );
              }
              let obj = this._.cloneDeep(buildItem(item));
              obj.components = this._.cloneDeep(obj.field.columns);
              list.push(obj);
            }
          });
      };

      if (this.useType == "gantt") {
        let buildList = (componentList, parent) => {
          componentList
            .filter((item) => item.componentName != "include_form")
            .forEach((item) => {
              if (
                item.form &&
                !item.container &&
                ganttFilterFields.includes(item.componentName)
              ) {
                let obj = {
                  fieldType: "formField",
                  field: this._.cloneDeep(item),
                };

                if (parent && parent.componentName == "json_form") {
                  obj.name = parent.name + "." + item.name;
                  obj.title = parent.title + "." + item.title;
                  obj.field.name = obj.name;
                  obj.field.title = obj.title;
                } else {
                  obj.name = item.name;
                  obj.title = item.title;
                }
                list.push(obj);
              } else if (item.container) {
                buildList(item.components, item);
              }
            });
        };
        buildList(componentList);
      } else {
        buildItemByList(componentList);
      }

      this.orignColumns = list;

      if (typeof callback === "function") {
        callback(list);
      }
    },
    confirmRow(formId) {
      this.$confirm(
        "切换数据源会导致默认配置都失效，确认要修改数据源吗？",
        "提示",
      )
        .then(() => {
          this.getFormView(formId, (orignColumns) => {
            this.$emit("changeSource", formId, orignColumns);
            if (this.useType == "tree_table") {
              this.$emit("initData", orignColumns);
            }
          });

          this.dialogVisible = false;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.left-container {
  width: 220px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.sub-container {
  margin-left: 20px;

  .sub-col-item {
    font-size: 12px;
    padding: 6px 5px;
    line-height: 18px;
    background-color: #ffffff;
    margin-bottom: 2px;
    border-radius: 4px;
  }
}

.source-box {
  border-bottom: solid 1px #f4f5f7;
  padding: 0px 20px 10px;
}

.source-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  font-size: 14px;
  margin-bottom: 5px;
}

.action {
  color: var(--zgg-brand-color-6);
  cursor: pointer;
}

.column-list {
  flex: 1;
  overflow: auto;
  padding: 10px 20px;

  .column-item {
    font-size: 12px;
    padding: 6px 5px;
    line-height: 18px;
    cursor: pointer;
    border: solid 1px #f4f5f7;
    background-color: #ffffff;
    margin-bottom: 2px;
    border-radius: 4px;

    &.disabled {
      background-color: rgba($color: #000000, $alpha: 0.05);
      cursor: not-allowed;
    }
  }

  .drag-item {
    i {
      display: none;
    }

    .sub-container {
      display: none;
    }
  }
}
.iconfont {
  font-size: 12px;
  color: var(--zgg-font-text-secondary);
}
</style>
