<template>
  <div>
    <!-- 自定义颜色 -->
    <div>
      <el-popover
        placement="bottom"
        width="240"
        popper-class="color-select"
        trigger="click"
      >
        <div class="cont-div">
          <div class="title">颜色</div>
          <div class="content">
            <div
              class="content-item"
              @click="selectVal(sub)"
              v-for="(sub, index) in colorArr"
              :style="'text-align:center;background-color:' + sub"
              :key="index"
            >
              <i
                v-if="myColor == sub"
                class="el-icon-check"
                style="line-height: 30px"
                :style="
                  myColor == `var(--zgg-color-white)`
                    ? 'color:#000'
                    : 'color:#fff'
                "
              ></i>
            </div>
          </div>
          <!-- 自定义颜色 -->
          <div>
            <div class="title">自定义颜色</div>
            <div class="content" style="justify-content: left; padding: 0 6px">
              <!-- <div
                class="content-item"
                @click="addVal()"
                style="text-align: center"
              >
                <i
                  class="el-icon-plus"
                  size="36"
                  style="line-height: 30px; color: #C1CBD"
                ></i>
              </div> -->
              <div class="color-picker" style="padding: 0 2px">
                <el-color-picker
                  value="#fff"
                  class="form-color-picker"
                  size="mini"
                  popper-class="custom-color-popper"
                  :show-alpha="true"
                  @change="changeCustom"
                ></el-color-picker>
              </div>
              <div
                class="content-item"
                @click="selectVal(sub)"
                v-for="(sub, index) in customColors"
                :style="'text-align:center;background-color:' + sub"
                :key="index"
              >
                <i
                  v-if="myColor == sub"
                  class="el-icon-check"
                  style="line-height: 30px"
                  :style="
                    myColor == `var(--zgg-color-white)`
                      ? 'color:#000'
                      : 'color:#fff'
                  "
                ></i>
              </div>
            </div>
          </div>
          <template v-if="isBackground">
            <div class="title">渐变</div>
            <div class="content">
              <div
                class="content-item"
                v-for="item in transitionColorArr"
                :style="`text-align:center;background:linear-gradient(${item})`"
                :key="item"
                @click="selectGradient(item)"
              >
                <i
                  v-if="myColor == item"
                  class="el-icon-check"
                  style="line-height: 30px; color: #fff"
                ></i>
              </div>
            </div>
          </template>
        </div>

        <div slot="reference" class="select-div father">
          <div
            @click="showSelect()"
            class="input-div"
            :style="
              myColor && myColor.includes(',') && !myColor.includes('rgba')
                ? 'background:linear-gradient(' + myColor + ')'
                : 'background-color:' + myColor
            "
          ></div>
          <i @click="reset()" class="el-icon-close"></i>
          <i @click="showSelect()" class="el-icon-arrow-down"></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { saveCustomColor, getCustomColor } from "@/views/lowCode/view/api";

export default {
  name: "singleColorSelect",

  props: {
    background: {
      type: String,
      default: "",
    },
    isBackground: {
      type: Boolean,
      default: false,
    },
    positionLeft: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      myColorList: [],
      show: false,
      myColor: "",
      colorArr: [],
      colorChange: {
        "#6BDAAE": "var(--chart-a-1)",
        "#7B67EE": "var(--chart-b-1)",
        "#3870EA": "var(--chart-c-1)",
        "#035885": "var(--chart-d-1)",
        "#08F8E9": "var(--chart-e-1)",
        "#F94144": "var(--chart-f-1)",
        "#D9ED92": "var(--chart-g-1)",
        "#FF9D66": "var(--chart-a-8)",
        "#FF7875": "var(--chart-a-9)",
        "#000": "var(--zgg-color-black)",
        "#FFF": "var(--zgg-color-white)",
        "#D8DAE5": "var(--neutral-7)",
      },
      textColorArr: [
        `var(--zgg-color-white)`,
        `var(--neutral-5)`,
        `var(--chart-c-3)`,
        `var(--chart-c-9)`,
        `var(--chart-c-8)`,
        `var(--chart-b-9)`,
        `var(--chart-f-4)`,
        `var(--chart-a-2)`,
        `var(--chart-e-5)`,
        `var(--chart-c-6)`,
        `var(--chart-b-1)`,
        `var(--chart-c-1)`,
        `var(--teal-6)`,
        `var(--chart-e-3)`,
        `var(--violet-6)`,
        `var(--chart-f-9)`,
        `var(--chart-g-9)`,
        `var(--neutral-12)`,
      ],
      bgColorArr: [
        `var(--zgg-color-white)`,
        `var(--neutral-5)`,
        `var(--chart-c-3)`,
        `var(--chart-c-9)`,
        `var(--chart-c-8)`,
        `var(--chart-b-9)`,
        `var(--chart-f-4)`,
        `var(--chart-a-2)`,
        `var(--chart-e-5)`,
        `var(--chart-c-6)`,
        `var(--chart-b-1)`,
        `var(--chart-c-1)`,
        `var(--teal-6)`,
        `var(--chart-e-3)`,
        `var(--violet-6)`,
        `var(--chart-f-9)`,
        `var(--chart-g-9)`,
        `var(--neutral-12)`,
      ],
      transitionColorArr: [
        `96deg, #6A83FE -8.08%, #28CBFD 94.72%`,
        `96deg, #877DFB -8.08%, #F783ED 94.72%`,
        `96deg, #FF938B -8.08%, #FF6B88 94.72%`,
        `96deg, #0FBDD9 -8.08%, #28F498 94.72%`,
        `135deg, #FCCF31 0%, #F55555 100%`,
        `264deg, #FFF500 -21.63%, #FF0FBC 104.73%`,
        `135deg, #F0FF00 0%, #58CFFB 100%`,
        `96deg, #FE9F6A -8.08%, #EFDD3A 94.72%`,
        `225deg, #FFEAF6 -5.57%, #FF9DE4 105.55%`,
        `221deg, #FFF6EB 11.23%, #DFD1C5 96.32%`,
        `135deg, #DDE4FF 0%, #8DA2EE 100%`,
        `214deg, #C5EDF5 13.58%, #4A879A 150.31%`,
      ], //背景渐变色
      customColors: [],
    };
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  watch: {
    background() {
      if (
        this.background &&
        this.background.includes("#") &&
        !this.background.includes(",")
      ) {
        //含，是渐变色
        //兼容指标图旧数据
        this.myColor = this.colorChange[this.background];
      } else {
        this.myColor = this.background;
      }
    },
    isBackground(val) {
      if (val) {
        this.colorArr = this.bgColorArr;
      } else {
        this.colorArr = this.textColorArr;
      }
    },
  },
  created() {
    if (
      this.background &&
      this.background.includes("#") &&
      !this.background.includes(",")
    ) {
      //兼容指标图旧数据
      this.myColor = this.colorChange[this.background];
    } else {
      this.myColor = this.background;
    }

    if (this.isBackground == true) {
      this.colorArr = this.bgColorArr;
    } else {
      this.colorArr = this.textColorArr;
    }
    this.getCustomColor();
  },

  methods: {
    reset() {
      this.myColor = "";
      this.$emit("updateColor", "", "color");
      //this.show = false;
    },
    //更改popup值
    //点击外层关闭显示框
    close() {
      this.show = false;
    },
    //点击自身显示框仍显示
    closestop() {
      this.show = true;
    },
    bodyCloseMenus(e) {
      let self = this;
      var arr = ["container", "attribute-box", "title-label", "content-layout"];
      if (
        e.target.className.includes("container") ||
        e.target.className.includes("attribute-box") ||
        e.target.className.includes("title-label") ||
        e.target.className.includes("content-layout")
      ) {
        self.show = false;
      }
    },
    showSelect() {
      this.show = !this.show;
      this.$emit("showSelect");
    },
    selectVal(sub) {
      this.myColor = sub;
      this.$emit("updateColor", sub, "color");
    },
    selectGradient(sub) {
      this.myColor = sub;
      this.$emit("updateColor", sub, "color");
    },
    addVal() {},
    getCustomColor() {
      getCustomColor().then((res) => {
        this.customColors = res.data.customColors;
      });
    },
    changeCustom(val) {
      if (val) {
        let data = {};
        data.customColor = val;
        saveCustomColor(data).then((res) => {
          this.getCustomColor();
          this.selectVal(val);
        });
      }
    },
  },
  beforeDestroy() {
    this.show = false;
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="scss" scoped>
.select-div {
  cursor: pointer;
  // width: 258px;
  height: 32px;
  border: 1px solid#DFE1E6;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  .input-div {
    //width: 189px;
    display: flex;
    flex-direction: row;
    margin: auto;
  }
}
.cont-div {
  // border: 1px solid #dfe1e6;
  //padding: 4px;
  // border-radius: 5px;
  width: 240px;
  margin: auto;
}
.content-item {
  width: 22px;
  height: 22px;
  display: block;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.input-div {
  height: 80%;
  width: 88%;
  margin: 0 4px;
}
.father .el-icon-close {
  display: none;
  margin-right: 4px;
}
.el-icon-arrow-down {
  margin-right: 4px;
}
.father .el-icon-arrow-down {
  display: block;
}

.father:hover .el-icon-close {
  display: block;
}
.father:hover .el-icon-arrow-down {
  display: none;
}
.content-item {
  margin: 4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ebecf0;
}
.title {
  text-align: left;
  padding: 10px 6px;
}

::v-deep {
  .color-picker {
    .el-color-picker__trigger {
      padding: 0;
    }
    .el-color-picker__trigger,
    .el-color-picker__color-inner,
    .el-color-picker__color {
      border-radius: 50%;
    }
    .el-color-picker {
      margin-top: 4px;
    }
    .el-icon-arrow-down:before,
    .el-icon-close:before {
      content: "\e6d9";
      color: #c1cbdb;
    }
    .el-color-picker__color {
      border: 0;
    }
    .el-color-picker--small .el-color-picker__trigger {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
