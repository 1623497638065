<template>
  <div class="weui-row" :class="field.signTip == 'delete' ? 'sign-red' : ''">
    <el-input
      size="small"
      :value="field.tableField.fieldTitle"
      readonly
      style="width: 200px"
      placeholder=""
    ></el-input>
    <div style="font-size: 14px; margin-left: 10px" v-text="typeText"></div>
    <el-select
      :value="field.valueType"
      size="small"
      style="width: 140px; margin-left: 10px"
      placeholder=""
      @change="changeValueType"
    >
      <el-option label="触发表单字段" value="tableField"></el-option>
      <el-option label="自定义" value="custom"></el-option>
      <el-option label="空值" value="nil"></el-option>
      <el-option
        v-if="field.tableField.componentName == 'date_picker'"
        label="动态"
        value="dynamic"
      ></el-option>
    </el-select>
    <div
      class="over-flex"
      style="margin-left: 10px"
      v-if="field.valueType != 'nil'"
    >
      <el-select
        v-if="field.valueType == 'tableField'"
        size="small"
        placeholder="触发表单字段"
        :value="field.triggerTableField && field.triggerTableField.fieldName"
        @change="changeTriggerField"
        style="width: 100%"
      >
        <el-option
          v-for="item in getCurrFileds()"
          :key="item.key"
          :label="item.title"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <div style="width: 100%; overflow: hidden" v-else>
        <template v-if="field.tableField.componentName == 'address_input'">
          <my-address
            v-model="field.value"
            :field="field"
            :areaTree="areaTree"
          ></my-address>
        </template>
        <template v-else-if="field.tableField.componentName == 'date_picker'">
          <el-date-picker
            v-if="field.valueType !== 'dynamic'"
            v-model="field.value"
            placeholder="请选择日期"
            :type="datePicker"
            size="small"
            style="width: 100%"
          ></el-date-picker>
          <DynamicDatePicker
            v-else
            v-model="field.value"
            placeholder="请选择日期"
            size="small"
            style="width: 100%"
            :picker="datePicker"
            :hasCustom="isHasCustom"
            :filterTimeModeByPicker="filterTimeModeByPicker"
            :hasNow="isHasNow"
          >
          </DynamicDatePicker>
        </template>

        <template v-else>
          <template
            v-if="
              customsOpts.includes(field.tableField.componentName) &&
              field.tableField.optionSource == 'custom'
            "
          >
            <el-select
              v-model="field.value"
              size="small"
              style="width: 100%"
              :key="field.key"
              :multiple="
                ['checkbox_group', 'select_checkbox'].includes(
                  field.tableField.componentName,
                )
              "
              placeholder=""
            >
              <el-option
                v-for="item in field.tableField.customOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else>
            <div class="tag-container" v-if="isMulti">
              <div class="tag-scroll" ref="tagScroll" @mousedown="mousedown">
                <el-tag
                  type="success"
                  closable
                  v-for="(tag, index2) in field.value"
                  :key="index2"
                  size="mini"
                  @close="closeTag(index2)"
                  >{{ tag }}
                </el-tag>
              </div>

              <el-button
                type="text"
                @click="addModal"
                size="mini"
                icon="el-icon-plus"
                style="margin-left: 5px"
                >添加筛选值
              </el-button>
            </div>
            <el-input
              v-else-if="field.tableField.componentName != ''"
              v-model="field.value"
              class="flex"
              placeholder="自定义值"
              size="small"
              clearable
              @blur="blurInput"
            ></el-input>
          </template>
        </template>
      </div>
    </div>
    <i
      v-if="type != 'add'"
      style="margin-left: 10px"
      class="el-icon-delete"
      @click="deleteField(index)"
    ></i>
  </div>
</template>
<script>
import test from "@/utils/test";
import address from "./address";

export default {
  components: { myAddress: address },
  props: {
    type: String,
    componentList: Array,
    field: Object,
    triggerFormId: String,
    areaTree: Array,
    checkFieldEnabel: Function,
    checkValueType: Function,
    deleteField: Function,
    index: Number,
    getJsonFormName: Function,
  },
  computed: {
    /**
     * 是否显示动态日期组件的自定义选项
     * 目前定义
     */
    isHasCustom() {
      return this.type != "edit" && this.type != "add";
    },
    /**
     * 是否显示此时
     * 当且仅当需要填充的时候才显示
     */
    isHasNow() {
      return this.type == "edit" || this.type == "add";
    },
    /**
     * 是否根据picker来显示动态日期组件
     */
    filterTimeModeByPicker() {
      return this.type == "edit" || this.type == "add";
    },
    typeText() {
      let type = this.type;
      if (["filter", "form_data_update", "form_data_save"].includes(type)) {
        return "等于";
      } else if (type == "add") {
        return "设置为";
      } else if (type == "edit") {
        return "修改为";
      }
    },
    areaList() {
      let list = [];
      let picker = this.field.tableField.picker;
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    getAddr() {
      let arr = [];
      let value = this.field.value;
      if (value) {
        if (value.provinceId) {
          arr.push(value.provinceId);
        }
        if (value.cityId) {
          arr.push(value.cityId);
        }
        if (value.countyId) {
          arr.push(value.countyId);
        }
      }
      return arr;
    },
    datePicker() {
      if (this.field.tableField.picker != "date_minute") {
        return this.field.tableField.picker;
      }
      return "datetime";
    },
    isMulti() {
      let arr = [
        "checkbox_group",
        "user_list_select",
        "department_list_select",
        "select_checkbox",
      ];
      return arr.includes(this.field.tableField.componentName);
    },
  },
  data() {
    return {
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
      popverVisible: false,
      customsOpts: [
        "radio_group",
        "checkbox_group",
        "select",
        "select_checkbox",
      ],
    };
  },
  methods: {
    changeOper(val) {
      this.$set(this.field, "oper", val);
      console.log(this.field);
    },
    blurInput(event) {
      let value = event.currentTarget.value;
      let tableField = this.field.tableField;
      if (
        tableField.componentName == "input_number" &&
        tableField.decimalPrecision &&
        test.number(value)
      ) {
        // value =
        let index = value.indexOf(".");
        let num = tableField.decimalPrecision;

        if (index > 0) {
          value = value.substring(0, index + num + 1);
        } else if (index == 0) {
          value = value.substring(0, index + num + 1);

          if (num == 0) {
            value = 0;
          } else {
            value = "0" + value;
          }
        }
        value = new Number(value).toFixed(num);
        this.$set(this.field, "value", parseFloat(value));
      } else if (
        tableField.componentName == "input_number" &&
        test.number(value) &&
        value != ""
      ) {
        this.$set(this.field, "value", parseFloat(value));
      }
    },
    changeValueType(value) {
      if (
        this.field.tableField.fieldName.indexOf(".") >= 0 &&
        value == "tableField" &&
        typeof this.checkValueType === "function"
      ) {
        this.checkValueType()
          .then(() => {
            this.field.valueType = value;
          })
          .catch(() => {});
      } else {
        this.field.valueType = value;
      }
    },
    mousedown(event) {
      event.preventDefault();
      document.addEventListener("mousemove", this.mousemove);
      document.addEventListener("mouseup", this.mouseup);
      let clientWidth = event.currentTarget.clientWidth;
      let scrollWidth = event.currentTarget.scrollWidth;
      this.down = scrollWidth > clientWidth;
      if (this.down) {
        this.scrollPosition = {
          startX: event.clientX,
          scrollLeft: event.currentTarget.scrollLeft,
          maxLeft: scrollWidth - clientWidth,
          $el: event.currentTarget,
        };
      }
    },
    mouseup(event) {
      if (event) {
        event.preventDefault();
      }
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.down = false;
    },
    mousemove(event) {
      event.preventDefault();
      if (!this.down) {
        return;
      }

      let endX = event.clientX;
      let scrollLeft =
        this.scrollPosition.scrollLeft + this.scrollPosition.startX - endX;
      if (scrollLeft > this.scrollPosition.maxLeft) {
        scrollLeft = this.scrollPosition.maxLeft;
      } else if (scrollLeft < 0) {
        scrollLeft = 0;
      }

      this.scrollPosition.$el.scrollLeft = scrollLeft;
    },
    closeTag(index) {
      this.$delete(this.field.value, index);
    },
    addModal() {
      this.$prompt("请输入筛选值", "筛选值", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (value) => {
          return !test.isEmpty(value);
        },
        inputErrorMessage: "请输入筛选值",
      })
        .then(({ value }) => {
          if (!this.field.value) {
            this.$set(this.field, "value", [value]);
          } else {
            this.field.value.push(value);
          }

          this.$nextTick(() => {
            let $tagScroll = this.$refs.tagScroll;
            let scrollLeft = $tagScroll.scrollWidth - $tagScroll.clientWidth;
            $tagScroll.scrollLeft = scrollLeft;
          });
        })
        .catch(() => {});
    },
    changeAddr(value) {
      let obj = {};
      let provinceId = value[0];
      let cityId = value[1];
      let countyId = value[2];
      let provinceItem = this.areaTree.find((item) => item.id == provinceId);
      obj.provinceId = provinceId;
      obj.province = provinceItem.name;
      if (cityId) {
        let cityItem = provinceItem.children.find((item) => item.id == cityId);
        obj.cityId = cityId;
        obj.city = cityItem.name;
        if (countyId) {
          let countyItem = cityItem.children.find(
            (item) => item.id == countyId,
          );
          obj.countyId = countyId;
          obj.county = countyItem.name;
        }
      }
      this.$set(this.field, "value", obj);
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
    changeTriggerField(value) {
      // todo 需要根据类型来赋值
      let obj = this.componentList.find((item) => item.name == value);
      if (value == "_id") {
        obj = {
          key: "_id",
          name: "_id",
          title: "当前触发表单的数据",
        };
      }

      let triggerTableField = {
        collection: this.triggerFormId,
        fieldName: obj.name,
        fieldTitle: obj.title,
        componentName: obj.componentName,
      };

      // todo 需要根据关联类型来赋值
      if (obj.componentName == "reference_data" && obj.tableName) {
        triggerTableField.referenceTableName = obj.tableName;
      }

      if (typeof this.checkFieldEnabel === "function") {
        this.checkFieldEnabel(this.field.tableField.fieldName, obj.name)
          .then(() => {
            this.$set(this.field, "triggerTableField", triggerTableField);
          })
          .catch(() => {});
      } else {
        this.$set(this.field, "triggerTableField", triggerTableField);
      }
    },
    getCurrFileds() {
      let arr = ["input", "text_area", "radio_group", "select", "sn_input"];
      let list = [];
      let parentName = "";
      if (typeof this.getJsonFormName === "function") {
        parentName = this.getJsonFormName();
      }
      this.componentList.forEach((item) => {
        if (["add", "form_data_save"].includes(this.type)) {
          if (
            this.field.tableField.fieldName.indexOf(".") >= 0 &&
            item.name &&
            item.name.indexOf(".") == -1
          ) {
            return;
          }
        }

        if (parentName) {
          if (
            item.name.indexOf(".") > 0 &&
            item.name.indexOf(parentName) == -1
          ) {
            // 过滤非同一个已选中的子表单选
            return;
          }
        }

        if (
          arr.includes(this.field.tableField.componentName) &&
          arr.includes(item.componentName)
        ) {
          list.push(item);
        } else if (this.field.tableField.componentName == "reference_data") {
          //如果是关联数据字段，触发的表单字段，只能是关联了同一个关联表的字段
          if (
            item.componentName == "reference_data" &&
            this.field.tableField.referenceTableName == item.tableName
          ) {
            list.push(item);
          }
        } else if (this.field.tableField.componentName == item.componentName) {
          list.push(item);
        }
      });
      //如果是关联数据字段，触发的表单字段，只能是关联了同一个关联表的字段
      if (this.field.tableField.referenceTableName == this.triggerFormId) {
        list.push({
          key: "_id",
          name: "_id",
          title: "当前触发表单的数据",
        });
      }

      return list;
    },
    checkItemType(type) {
      this.popverVisible = false;
      if (this.field.type == type) {
        return;
      }

      this.field.value = "";
      this.field.valueType = type;
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}

.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.over-flex {
  flex: 1;
  overflow: hidden;
}
.sign-red {
  color: red;
  ::v-deep .el-input--small .el-input__inner {
    color: red;
  }
}
</style>
