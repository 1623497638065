<template>
  <div class="weui-row">
    <el-input
      v-model="key"
      placeholder="示例：500"
      size="mini"
      style="width: 180px"
      :disabled="disabled"
    ></el-input>
    <el-input
      v-model="value"
      placeholder="请输入错误信息"
      size="mini"
      style="margin-left: 10px"
      :disabled="disabled"
    ></el-input>
    <i style="margin-left: 10px" @click="del" class="el-icon-delete"></i>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    disabled: Boolean,
    index: Number,
  },
  computed: {
    key: {
      get() {
        return this.item.key;
      },
      set(val) {
        this.$set(this.item, "key", val);
      },
    },
    value: {
      get() {
        return this.item.value;
      },
      set(val) {
        this.$set(this.item, "value", val);
      },
    },
  },
  methods: {
    del() {
      this.$emit("del", this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
}
</style>
