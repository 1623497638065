import moment from "moment";
import {uuid} from "@zgg-core-utils/utils";

/**
 * 获取文件后缀名
 * @param fullName
 * @returns {string}
 */
export function getFileExtension(fullName){
    if (fullName.indexOf(".") == -1) {
        return "";
    }
    return fullName.substring(fullName.lastIndexOf('.')+1,fullName.length).toLowerCase()
}
/**
 * 获取不带后缀的文件名
 * @param fullName
 * @returns {string}
 */
export function getFileOriginalName(fullName){
    return fullName.substring(0,fullName.lastIndexOf('.'))
}


//拼接上传文件的目录
export function getUploadPathByName(fileName) {
    return moment(new Date()).format("YYYYMMDD") + "/" + uuid() + "." + getFileExtension(fileName)
}
