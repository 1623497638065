<template>
  <div
    v-resize="initTable"
    :ref="tableRef"
    :id="`uiTable_${viewDetail?.key}`"
    :class="isH5 ? '' : 'isWeb'"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <el-table
      :stripe="viewDetail?.colorConfig?.enableStripe"
      class="el-tableee"
      v-loading="isTableLoading"
      size="mini"
      v-if="viewDetail && isReset"
      :data="tableData2"
      ref="table"
      style="width: 100%"
      border
      row-key="_key"
      :span-method="spanMethod"
      height="200px"
      :width="getTableColumnWidthByColumn({ name: '_index' })"
    >
      <el-table-column
        v-if="viewDetail.showRowNumbers"
        prop="_index"
        width="50"
        align="center"
        label="序号"
      >
      </el-table-column>
      <template
        v-for="(col, index) in viewDetail.columns"
        style="position: relative"
      >
        <table-column
          v-if="showTableCol(col)"
          :key="index"
          :col="col"
          :index="index"
          :isViewList="true"
          :isSub="true"
          :showRowNumbers="viewDetail.showRowNumbers"
        ></table-column>
      </template>
      <el-table-column
        label=""
        :width="tempWidth"
        v-if="tempWidth > 0"
      ></el-table-column>
    </el-table>
    <pagination
      v-if="viewDetail.hasPageTool"
      :total="page.totalRow"
      :page.sync="page.pageNumber"
      :limit.sync="page.pageSize"
      @pagination="loadingList"
      layout="total, sizes,prev,pager, next"
    />
  </div>
</template>
<script>
import {
  getTableColumnWidthByColumn,
  isDocumentCommonFieldKey,
} from "@/views/lowCode/utils";

import TableColumn from "./detail/TableColumn";
import { getAggreation } from "../api";
import { _eq } from "@zgg-core-utils/relyUtil";
import { getTableColorConfig } from "./chartUtil";

export default {
  props: {
    isH5: Boolean,
    view: Object,
    width: Number,
    height: Number,
    appViewId: String,
    darkmode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData2: [],
      tableData: [],
      list: [],
      option: null,
      isReset: true,
      page: {
        pageSize: 50,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      resetTextStyle: "",

      dataFilter: {},
      totalData: "",
      col: [],
      viewDetail: {},
      isTableLoading: true, // tabel数据loading
      tempWidth: 0,
      tableRef: "tableDom" + Math.random(),
    };
  },
  components: {
    TableColumn,
  },
  created() {},
  computed: {
    field() {
      if (this.isViewList) {
        let field = this._.cloneDeep(this.col.field);

        return field;
      } else {
        return this.col;
      }
    },
  },
  mounted() {
    let that = this;
    window.addEventListener("resize", () => {
      that.initTable();
    });
  },
  methods: {
    initTable() {
      let that = this;
      //获取列的总宽度
      getTableColorConfig(this.viewDetail, this.isH5); //获取表框头部颜色设置值
      if (that.viewDetail && that.viewDetail.columns) {
        var containerWidth = that.viewDetail.showRowNumbers ? 50 : 0;
        that.viewDetail.columns.forEach((item) => {
          if (item.width === 0 && item.field) {
            const width = getTableColumnWidthByColumn(item.field);
            item.width = parseInt(width);
          }
          containerWidth += item.width;
        });
      }

      //获取当前表格的宽度
      if (that.$refs[that.tableRef]) {
        var tableDomWidth = that.$refs[that.tableRef].clientWidth;
      }
      //获取两者差
      that.tempWidth = tableDomWidth - containerWidth - 5;
    },
    showTableCol(col) {
      this.col = col;
      if (!col.components) {
        return true;
      }
      return col.components.filter((item) => !item.hidden).length > 0;
    },

    getTableColumnWidthByColumn,
    loadingList() {
      let queryObject = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
      };
      let view = this.viewDetail;
      if (view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
        if (this.viewDetail.hasPageTool) {
          if (view.dataSource.queryObject.pageSize == "") {
            queryObject.pageNumber = 1;
            queryObject.pageSize = 50;
          } else {
            queryObject.pageNumber = this.page.pageNumber;
            queryObject.pageSize = this.page.pageSize;
          }
        }
      }
      //queryObject.xFields = this._.cloneDeep(view.xFields);
      //queryObject.columns = this._.cloneDeep(view.columns);
      //queryObject.advanceQuery = view.dataSource.queryObject.advanceQuery;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        bizType: "appView",
        bizParam: JSON.stringify({ appViewId: this.appViewId }),
        cmd: "page",
        dataFilter: JSON.stringify(this.dataFilter),
        tableView: true,
        componentName: "table",
        component: JSON.stringify(view.columns),
      }).then((res) => {
        let data = res.data;
        this.list = data.page.list;
        this.page.totalRow = data.page.totalRow;
        this.page.pageSize = data.page.pageSize;
        this.page.totalPage = data.page.totalPage;
        this.page.pageNumber = data.page.pageNumber;
        this.isTableLoading = false;
        this.buildData(res);
        //this.buildChart(view);
      });
    },
    getData(view, dataFilter = {}) {
      this.$set(this, "viewDetail", view);
      if (view.columns) {
        view.columns.forEach((item) => {
          if (item.field) {
            if (item.field.component) {
              if (item.field.componentName == "json_form") {
                if (item.subColumns) {
                  this.$set(item, "subColumns", item.subColumns);
                } else {
                  this.$set(
                    item,
                    "subColumns",
                    item.field.component.components,
                  );
                  if (item && item.subColumns) {
                    item.subColumns.forEach((col) => {
                      if (item.field.componentName == "json_form") {
                        this.$set(col, "field", {
                          name: col.name,
                          componentName: col.componentName,
                          component: { format: col.format },
                        });
                      }
                    });
                  }
                }
              }
              if (item.field.componentName == "form_data") {
                this.$set(item, "subColumns", item.field.component.columns);
                if (item && item.subColumns) {
                  item.subColumns.forEach((col) => {
                    if (item.field.componentName == "form_data") {
                      this.$set(col, "field", col.field);
                    }
                  });
                }
              }
            }
          }
        });
      }
      if (!view.metrics) {
        view.metrics = [];
      }
      if (!view.xFields) {
        view.xFields = view.columns;
      }
      this.dataFilter = dataFilter;
      this.loadingList();
    },
    async buildChart(view) {
      this.isReset = false;
      let list = this.list;
      this.tableData = list;
      let tableData2 = this._.cloneDeep(list);
      this.tableData2 = tableData2;

      this.$nextTick(() => {
        this.isReset = true;
      });
    },
    /**
     *
     * @param data
     * @returns {[]}
     * 2022-02-28 添加_key作为唯一主键，为_id加上子表单的index
     */
    buildData({ data }) {
      if (data && data.page) {
        this.pageList = data.page.list;
      }

      try {
        //如果是导出表格，直接打开并return
        if (data.fileUrl) {
          window.open(data.fileUrl);
          return;
        }
        let arr = this.viewDetail.columns.filter(
          (item) => item && item.subColumns && item.subColumns.length > 0,
        );

        if (!data.page) return;
        if (!arr.length) {
          let tableData = [];
          data.page.list.forEach((item, index) => {
            tableData.push({
              ...item,
              _index:
                (data.page.pageNumber - 1) * data.page.pageSize + index + 1,
              _key: item._id,
            });
          });
          this.tableData2 = tableData;
        } else {
          let tableData = [];
          let _key;
          data.page &&
            data.page.list.forEach((item, pIndex) => {
              let _index =
                (data.page.pageNumber - 1) * data.page.pageSize + pIndex + 1;
              let lens = [];
              arr.forEach((a) => {
                if (a.referenceName) {
                  let obj = item[a.referenceName + "ReferenceData"];

                  if (obj && obj[a.field.name] && obj[a.field.name].length) {
                    lens.push(obj[a.field.name].length);
                  }
                } else {
                  if (item[a.field.name] && item[a.field.name].length) {
                    lens.push(item[a.field.name].length);
                  }
                }
              });

              if (lens.length > 0) {
                let max = Math.max(...lens);

                for (let index = 0; index < max; index++) {
                  let obj = { ...item };
                  if (index == 0) {
                    obj.rowSpan = max;
                  } else {
                    obj.rowSpan = 0;
                  }
                  arr.forEach((col) => {
                    let values;
                    if (col.referenceName) {
                      let referenceData =
                        item[col.referenceName + "ReferenceData"];
                      if (referenceData) {
                        values = referenceData[col.field.name];
                      }
                    } else {
                      values = item[col.field.name];
                    }
                    if (values) {
                      _key = item._id + "-" + index;
                      let el = values[index];
                      if (el) {
                        for (const [key, value] of Object.entries(el)) {
                          //如果是通用字段，不再次将子表单中的值填充进去
                          if (!isDocumentCommonFieldKey(key)) {
                            obj[col.field.name + "." + key] = value;
                          }
                        }
                      }
                    }
                  });
                  tableData.push({ ...obj, _index: _index, _key });
                }
              } else {
                _key = item._id;
                tableData.push({ ...item, _index: _index, _key });
              }
            });

          this.tableData2 = tableData;
        }

        this.page.totalRow = data.page.totalRow;
        this.page.pageSize = data.page.pageSize;
        this.page.pageNumber = data.page.pageNumber;

        setTimeout(() => {
          this.$refs.table && this.$refs.table.doLayout();
        }, 1);
      } catch (err) {
        console.log(err);
      }
      this.isReset = false;

      this.$nextTick(() => {
        this.isReset = true;
        this.initTable();
      });
      return this.tableData2;
    },
    buildTable(list, index) {
      let tableData = [];
      let legendData = [];
      let view = this.viewDetail;
      let name = view.xFields[index].field.name;
      if (index == view.xFields.length - 1) {
        let orderType;
        let key;
        if (view.xFields[index].orderType) {
          orderType = view.xFields[index].orderType;
          key = view.xFields[index].field.name;
          // 纬度排序
        } else {
        }

        list.sort((a, b) => {
          if (a[key] > b[key]) {
            if (orderType == "desc") {
              return -1;
            } else {
              return 1;
            }
          } else if (a[key] < b[key]) {
            if (orderType == "desc") {
              return 1;
            } else {
              return -1;
            }
          }
          return 0;
        });

        return list;
      }
      let orderType = view.xFields[index].orderType;
      list.forEach((row) => {
        let value = row[name];
        if (!legendData.includes(value)) {
          legendData.push(value);
        }
      });

      if (orderType == "asc") {
        legendData.sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        });
      } else if (orderType == "desc") {
        legendData.sort((a, b) => {
          if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          }
          return 0;
        });
      }

      legendData.forEach((key) => {
        let arr = list.filter((item) => item[name] == key);

        let arr2 = this.buildTable(arr, index + 1);
        tableData = tableData.concat(arr2);
      });
      return tableData;
    },

    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        this.viewDetail.columns.findIndex(
          (col) => col && col.field.name == column.property,
        ) >= 0 ||
        column.property == "_index" ||
        column.type == "action" ||
        column.type == "selection" ||
        column.property == "workflowData.flowStatus"
      ) {
        if (row.rowSpan) {
          return {
            rowspan: row.rowSpan,
            colspan: 1,
          };
        } else if (row.rowSpan === 0) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table--border .el-table__cell {
  border-right: 1px solid #ebecf0;
}
.el-tableee ::v-deep .el-table__header-wrapper thead div,
.el-tableee ::v-deep th,
.el-tableee ::v-deep .el-table__fixed-header-wrapper thead div {
  background: #f5f7fa;
  color: rgba(9, 30, 66, 0.95);
  padding: 0 10px;
}
::v-deep .el-table thead {
  font-size: 13px;
}
.isWeb {
  ::v-deep .el-table th.is-center,
  ::v-deep .el-table td.is-center {
    text-align: center;
  }
  ::v-deep .el-table--border th:first-child .cell,
  ::v-deep .el-table--border td:first-child .cell {
    white-space: nowrap;
  }

  ::v-deep .el-table,
  ::v-deep .el-table td {
    border-right: 1px solid var(--js-right-border-color);
    border-bottom: 1px solid var(--js-border-color);
    color: var(--js-content-color);
  }
  ::v-deep .el-table {
    border: 1px solid var(--js-border-color); /* 设置边框颜色 */
    background-color: var(--js-background-color);
  }
  ::v-deep .el-table tr,
  .pagination-container,
  ::v-deep .el-pager li {
    background: transparent;
  }
  ::v-deep .el-table tr {
    height: 36px !important;
    line-height: 36px !important;
  }
  ::v-deep .el-pagination.is-background .el-pager li,
  ::v-deep .el-pagination.is-background .btn-prev:disabled,
  ::v-deep .el-pagination.is-background .btn-next,
  ::v-deep .el-pagination .el-select .el-input .el-input__inner {
    background-color: var(--js-background-color);
    border: 1px solid var(--js-border-color);
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--js-head-bg-color);
  }

  ::v-deep .el-table th.is-leaf {
    border-bottom: 1px solid var(--js-border-color);
  }

  .el-tableee ::v-deep th,
  .el-table ::v-deep thead.is-group th,
  .el-tableee ::v-deep .el-table__header-wrapper thead div {
    background: var(--js-head-bg-color);
    color: var(--js-head-color);
  }
  ::v-deep .el-table--group::after,
  ::v-deep .el-table--border::after,
  ::v-deep .el-table::before {
    background-color: var(--js-background-color);
  }

  ::v-deep .el-table__row:hover,
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(56, 112, 234, 0.06);
  }

  ::v-deep .el-pagination__total,
  ::v-deep .el-pagination.is-background .btn-prev:disabled,
  ::v-deep .el-pagination.is-background .btn-next:disabled,
  ::v-deep .el-pagination .el-select .el-input .el-input__inner,
  ::v-deep .el-select .el-input .el-select__caret {
    color: var(--js-content-color);
  }

  ::v-deep {
    .el-table--border th {
      border-right: 1px solid var(--js-right-border-color);
      border-bottom: 1px solid var(--js-right-border-color);
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background: var(--js-stripe-color);
    }
  }
}
::v-deep .el-table__body-wrapper,
::v-deep .el-pagination {
  overflow: hidden;
}

::v-deep .el-table__body-wrapper:hover,
::v-deep .el-pagination:hover {
  overflow: auto;
}
</style>
