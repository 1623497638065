<template>
  <div class="filter-row">
    <div style="width: 74px">
      <div v-if="index == 0" class="el-input el-input--small">
        <div class="el-input__inner" style="text-align: center">当</div>
      </div>
      <el-select
        v-else-if="index == 1"
        v-model="new_rel"
        size="small"
        placeholder=""
      >
        <el-option label="并且" value="and"></el-option>
        <el-option label="或者" value="or"></el-option>
      </el-select>
      <div v-else class="el-input el-input--small">
        <div class="el-input__inner" style="text-align: center">
          {{ rel == "and" ? "并且" : rel == "or" ? "或者" : rel }}
        </div>
      </div>
    </div>
    <!-- 组件源 -->
    <field-source
      :componentList="componentList"
      :field="queryField"
      :formId="formId"
    ></field-source>

    <!-- 操作符 -->
    <query-oper
      :field="queryField"
      :key="'oper_' + queryField.key"
      @change="changeOper"
    ></query-oper>

    <!-- 值类型 -->
    <FilterValue
      :field="queryField"
      :key="'v_' + queryField.key"
      :areaTree="areaTree"
      :areaProps="areaProps"
      :parentName="parentName"
      :isJsonForm="isJsonForm"
      :fieldList="fieldList"
      :componentList="componentList"
    ></FilterValue>

    <i style="margin-left: 10px" @click="deleteRow" class="el-icon-delete"></i>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },

    queryField: Object,
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
    rel: {
      type: String,
      default() {
        return "and";
      },
    },
    formId: String,
    parentName: String,
    isJsonForm: Boolean,
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    new_rel: {
      get() {
        return this.rel;
      },
      set(val) {
        this.$emit("update:rel", val);
      },
    },
  },

  methods: {
    deleteRow() {
      this.$emit("deleteRow", this.index);
    },
    changeOper(val) {
      if (["nil", "nnil"].includes(val)) {
        this.queryField.valueType =
          this.queryField.componentName == "reference_data"
            ? "tableField"
            : "custom";
      } else {
        this.queryField.valueType = "tableField";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
