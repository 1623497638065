<template>
  <div class="attr-row">
    <div class="attr-label" style="width: 100%">{{title}}</div>
    <!-- 子表单转实体表时的设置表单字段 -->
    <template v-if="component.componentName == 'json_form'">
      <fields-select
        title="选择在表单中显示的字段"
        v-model="component.components"
        :columns="formColumns"
        :componentName="component.componentName"
        :includeJsonForm="false"
      >
        <el-button
          style="width: 100%; margin-bottom: 5px"
          slot="reference"
          size="mini"
          >设置{{ title }}
        </el-button>
      </fields-select>
    </template>
    <template v-else>
      <fields-select
        title="选择在表单中显示的字段"
        v-model="component.columns"
        :columns="formColumns"
        :componentName="component.componentName"
        :includeJsonForm="!parentField"
      >
        <el-button
          style="width: 100%; margin-bottom: 5px"
          slot="reference"
          size="mini"
          >设置{{ title }}
        </el-button>
      </fields-select>
    </template>

    <div
      v-if="component.columns && component.columns.length"
      style="margin: 0px 5px"
    >
      <draggable
        v-model="component.columns"
        handle=".icon-yidong"
        animation="300"
      >
        <transition-group>
          <attr-form-field-item
            v-for="(item, index) in component.columns"
            :key="item.field.name"
            :index="index"
            :item="item"
            @deleteCol="deleteCol"
          ></attr-form-field-item>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import FieldsSelect from "@/components/FieldsSelect";
import { getFormComponents } from "../utils";
import Draggable from "vuedraggable";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import attrFormFieldItem from "./attr-form-field-item";
export default {
  name: "attr-form-field",
  inject: ["getComponents"],
  components: { FieldsSelect, Draggable, attrFormFieldItem },
  props: {
    component: Object,
    componentList: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "表单中显示字段设置",
    }
  },
  computed: {
    parentField() {
      // 子表单
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      return components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key
            ) >= 0
        );
    },
    formColumns() {
      let list = [];
      let componentList = this._.clone(this.componentList);
      componentList.forEach((item) => {
        if (checkFieldAbility(item, "isReferenceFormField")) {
          if (item.componentName == "json_form") {
            item.components = item.components.filter(
              (child) =>
                checkFieldAbility(child, "isReferenceFormField") &&
                child.componentName != "reference_data"
            );
          } else if (item.componentName == "reference_data") {
            item.columns = item.columns.filter(
              (child) =>
                checkFieldAbility(
                  child.field.component,
                  "isReferenceFormField"
                ) && child.field.component.componentName != "json_form"
            );
          } else if (item.componentName == "reference_data_list") {
            return;
          }
          list.push(item);
        }
      });

      return list;
    },
  },
  data() {
    return {
      colName: "",
    };
  },
  methods: {
    deleteCol(index) {
      this.component.columns.splice(index, 1);
    },
  },
};
</script>
