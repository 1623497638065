<template>
  <div>
    <zgg-filter
      :componentList="componentList"
      :dataFilter="dataFilter"
      :disabled="!isAddFilter"
    >
      <template slot-scope="{ field }">
        <rpa-action-filter-item
          :formId="formId"
          :componentList="componentList"
          :filterField="field"
          @del="del(index)"
          :disabled="disabled"
          class="action-filter-row"
          :node-type="nodeType"
          :baseNodeKey="baseNodeKey"
        ></rpa-action-filter-item>
      </template>
    </zgg-filter>
  </div>
</template>
<script>
import rpaActionFilterItem from "@/views/lowCode/rpa/rpa-action-filter-item";
export default {
  components: {
    rpaActionFilterItem,
  },
  props: {
    dataFilter: Object,
    componentList: Array,
    formId: String,
    isAddFilter: Boolean,
    disabled: Boolean,
    nodeType: String,
    baseNodeKey: {
      type: String,
      default() {
        return "";
      },
    },
  },

  methods: {
    del(index) {
      this.dataFilter.advanceQuery.splice(index, 1);
    },
    addFilter() {
      this.dataFilter.advanceQuery.push({
        key: "",
        value: "",
        oper: "",
        collection: "",
        title: "",
        componentName: "",
        valueType: "",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.action-filter-row {
  flex: 1;
}
</style>
