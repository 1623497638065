<template>
  <el-form :model="form" ref="formRef" :rules="rules" v-loading.lock="loading">
    <el-form-item
      v-for="item in formDetail.itemList"
      :label="item.fldCnName"
      :key="item.id"
      :required="item.required"
      :prop="item.id"
    >
      <el-input
        v-if="item.inputType >= 30 && item.inputType <= 31"
        :type="inputType(item)"
        :placeholder="getPlaceholder(item)"
        v-model="form[item.id]"
      />
      <el-input
        v-if="item.inputType > 31 && item.inputType <= 41"
        :type="inputType(item)"
        :placeholder="getPlaceholder(item)"
        v-model.number="form[item.id]"
      />
      <el-radio-group v-if="item.inputType == 10" v-model="form[item.id]">
        <el-radio
          v-for="(rd, index) in item.optionList"
          :key="index"
          :label="rd.title"
        >
          {{ rd.title }}
        </el-radio>
      </el-radio-group>

      <el-checkbox-group v-if="item.inputType == 20" v-model="form[item.id]">
        <div>
          <el-checkbox
            v-for="(rd, index) in item.optionList"
            :key="index"
            :label="rd.title"
          ></el-checkbox>
        </div>
      </el-checkbox-group>
      <el-date-picker
        v-if="item.inputType == 61"
        v-model="form[item.id]"
        :placeholder="getPlaceholder(item)"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        style="width: 100%"
        placeholder="选择日期"
      >
      </el-date-picker>
      <el-time-select
        v-if="item.inputType == 60"
        v-model="form[item.id]"
        :placeholder="getPlaceholder(item)"
        value-format="HH:mm:ss"
        format="HH:mm:ss"
        style="width: 100%"
        placeholder="选择时间"
      >
      </el-time-select>
      <!--      <div v-if="item.inputType==70">-->
      <!--        <upload-->
      <!--            style="padding: 0; margin-left: 0px"-->
      <!--            ref="fileUpload"-->
      <!--            @uploadSuccess="uploadAttachment"-->
      <!--            class="coverUpload"-->
      <!--        >-->
      <!--          <el-button type="primary" size="mini">添加附件</el-button>-->
      <!--        </upload>-->

      <!--        <attachment-list :attachment-list="card.attachmentList">-->
      <!--          <templateSetting slot-scope="scope">-->
      <!--            <el-link-->
      <!--                type="primary"-->
      <!--                icon="el-icon-paperclip"-->
      <!--                @click="handleAttachmentDownload(scope.row)"-->
      <!--            >下载-->
      <!--            </el-link>-->
      <!--            <el-link-->
      <!--                v-if="canPreview(scope.row.extension)"-->
      <!--                type="primary"-->
      <!--                icon="el-icon-view"-->
      <!--                :href="scope.row.url"-->
      <!--                target="_blank"-->
      <!--                :download="scope.row.originalName"-->
      <!--            >查看-->
      <!--            </el-link>-->
      <!--            <el-link-->
      <!--                type="danger"-->
      <!--                icon="el-icon-delete"-->
      <!--                @click="handleAttachmentDelete(scope.row)"-->
      <!--            >删除-->
      <!--            </el-link>-->
      <!--          </templateSetting>-->
      <!--        </attachment-list>-->
      <!--      </div>-->
    </el-form-item>
  </el-form>
</template>

<script>
import { formGetById, saveForm } from "@/api/oa";
import { getFormInstance } from "@/api/form";

export default {
  name: "Formitem",
  data() {
    return {
      form: {},
      formDetail: {},
      rules: {},
      loading: false,
      formInstanceId: 0,
      formId: 0,
      /*
       * 根据 formId 初始化，用于初始化新增表单的时候
       * 根据 formInstanceId 初始化表单，用于已填表单的回显
       */
      initType: "formId",
    };
  },
  props: {},
  watch: {},
  methods: {
    /**
     * 重置表单
     */
    resetForm() {
      this.formId = 0;
      this.formDetail = {};
    },
    /**
     * 根据表单实例初始化数据,处理逻辑跟根据formId初始化的不一样
     */
    async initByFormInstance(formInstanceId) {
      this.initType = "formInstanceId";
      this.formInstanceId = formInstanceId;
      this.loading = true;
      await getFormInstance({
        instanceId: formInstanceId || this.formInstanceId,
      }).then(async (resp) => {
        let { content, formId } = resp.data.instance;
        this.formId = formId;
        await this.init(this.formId);
        content.forEach((item) => {
          if (item.value) {
            this.form[item.id] = item.value;
          }
        });
      });
      this.loading = false;
    },
    async initByFormId(formId) {
      this.formId = formId;
      await this.init(formId);
    },
    /**
     * todo 需要优化
     */
    submitForm() {
      if (this.formId != 0) {
        return new Promise((resolve, reject) => {
          this.$refs.formRef.validate((valid) => {
            if (valid) {
              let formData = [];
              this.formDetail.itemList &&
                this.formDetail.itemList.forEach((item) => {
                  let obj = {
                    id: item.id,
                    fldEnName: item.fldEnName,
                    fldCnName: item.fldCnName,
                    value: "",
                  };
                  if (item.inputType == 70) {
                    // todo 附件相关的逻辑后续再处理
                    // obj.value = this.card.attachmentList.map((item) => item.id).toString();
                  } else if (item.inputType == 20) {
                    // obj.value = this.form[item.id].toString();
                    obj.value = this.form[item.id];
                  } else {
                    obj.value = this.form[item.id];
                  }
                  formData.push(obj);
                });
              saveForm({
                values: formData,
                formId: this.formDetail.form.id,
                instanceId: this.formInstanceId,
              }).then((resp) => {
                resolve(resp.data.model);
              });
            } else {
              reject();
            }
          });
        });
      }
    },
    async init(formId) {
      await formGetById(formId).then((resp) => {
        this.formDetail = resp.data;
        let itemList = this.formDetail.itemList;
        let rules = {};
        let form = {};
        itemList.forEach((item) => {
          let rule = [];
          form[item.id] = "";
          if (item.inputType == 20) {
            form[item.id] = [];
          }
          if (
            item.inputType != 60 &&
            item.inputType != 61 &&
            item.inputType != 70
          ) {
            if (item.required) {
              rule.push({
                required: true,
                message: item.fldCnName + "不能为空",
                trigger: ["change", "blur"],
              });
              rules[item.id] = rule;
            }

            if (item.dataType == 20) {
              rule.push({
                type: "number",
                message: "请输入数字类型",
                trigger: ["change", "blur"],
              });
              rules[item.id] = rule;
            }
            if (item.dataType == 30) {
              rule.push({
                type: "date",
                message: "请输入日期类型",
                trigger: ["change", "blur"],
              });
              rules[item.id] = rule;
            }
          }

          if (item.inputType == 20) {
            item.optionList.forEach((opt) => {
              opt.checked = false;
              opt.disabled = false;
            });
          }
        });

        this.$set(this, "form", form);
        this.$set(this, "rules", rules);
        if (this.initType === "formInstanceId") {
          this.$nextTick(() => {
            this.$refs.cardForm && this.$refs.cardForm.clearValidate();
          });
        }
      });
    },
    inputType(item) {
      if (item.dataType == 20) {
        return "number";
      }
      if (item.inputType == 31) {
        return "textarea";
      }
      return "text";
    },
    /**
     * 处理占位提示符
     */
    getPlaceholder(item) {
      if (item.hint) {
        return item.hint;
      }
      return item.fldCnName;
    },
  },
  mounted() {},
};
</script>
