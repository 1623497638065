import request from '@/utils/request';

/**
 * 创建数据工厂
 * @param {*} data 
 * @returns 
 */
export function createDataflow(data) {
  return request({
    url: '/dataflow/mgr/create',
    method: 'post',
    data
  })
}

/**
 * 重命名数据工厂
 * @param {*} data 
 * @returns 
 */
export function renameDataflow(data) {
  return request({
    url: '/dataflow/mgr/rename',
    method: 'post',
    data
  })
}

/**
 * 获取数据流分页列表
 * @param {*} data 
 * @returns 
 */
export function fetchDataflowList(data) {
  return request({
    url: '/dataflow/mgr/list',
    method: 'post',
    data
  })
}

/**
 * 保存数据工厂配置
 * @param {*} data 
 * @returns 
 */
export function saveDataflow(data) {
  return request({
    url: '/dataflow/mgr/saveStageFlow',
    method: 'post',
    data
  })
}

/**
 * 保存自动更新规则
 * @param {*} data 
 * @returns 
 */
export function saveAutoUpdateRule(data) {
  return request({
    url: '/dataflow/mgr/saveAutoUpdateRule',
    method: "post",
    data
  })
}

/**
 * 保存定时更新规则
 * @param {*} data 
 * @returns 
 */
export function saveCronUpdatedRule(data) {
  return request({
    url: '/dataflow/mgr/saveCronUpdatedRule',
    method: 'post',
    data
  })
}

/**
 * 工厂复制
 * @param {*} data 
 * @returns 
 */
export function copyDataflow(data) {
  return request({
    url: '/dataflow/mgr/duplicate',
    method: 'post',
    data
  })
}

/**
 * 删除工厂
 * @param {*} dataflowId 
 * @returns 
 */
export function deleteDataflow(dataflowId) {
  return request({
    url: '/dataflow/mgr/delete',
    method: 'post',
    data: { dataflowId }
  })
}

/**
 * 手动刷新数据
 * @param {*} dataflowId 
 * @returns 
 */
export function refreshData(dataflowId) {
  return request({
    url: '/dataflow/mgr/refreshData',
    method: 'post',
    data: { dataflowId }
  })
}

/**
 * 获取工厂详情
 * @param {*} dataflowId 
 * @returns 
 */
export function getDataflow(dataflowId) {
  let data = {};
  if (dataflowId.indexOf('dataflow_') == 0) {
    data = {
      collection: dataflowId
    }
  } else {
    data = {
      dataflowId
    }
  }
  return request({
    url: '/dataflow/mgr/detail',
    method: 'post',
    data
  })
}

/**
 * 获取节点输出字段
 * @param {*} data 
 * @returns 
 */
export function listStageField(data) {
  return request({
    url: '/dataflow/mgr/listStageField',
    method: 'post',
    data
  })
}

/**
 * 获取节点输出数据
 * @param {*} data 
 * @returns 
 */
export function fetchStageData(data) {
  return request({
    url: '/dataflow/mgr/paginateStageData',
    method: 'post',
    data
  });
}

/**
 * 获取输出流数据
 * @param {*} data 
 * @returns 
 */
export function fetchDataflowData(data) {
  return request({
    url: '/dataflow/mgr/paginateFlowData',
    method: 'post',
    data
  })
}

/**
 * 获取数据工厂日志
 * @param {*} data 
 * @returns 
 */
export function fetchListLog(data) {
  return request({
    url: '/dataflow/mgr/listLog',
    method: 'post',
    data
  })
}