<template>
  <el-form
    ref="form"
    :disabled="disabled"
    :model="node"
    label-position="top"
    :rules="rules"
  >
    <el-form-item label="触发时间" prop="executeTime">
      <el-date-picker
        v-model="node.executeTime"
        type="datetime"
        placeholder="请选择触发时间"
      ></el-date-picker>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: "rpa-timer-once",
  props: {
    node: Object,
    disabled: Boolean,
  },
  data() {
    return {
      rules: { executeTime: [{ required: true, message: "请选择触发时间" }] },
    };
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        callback(valid);
      });
    },
  },
};
</script>