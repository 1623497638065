var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"buttonGroupSettingContentWrap",staticClass:"button-group-setting-content__wrap"},[_vm._l((_vm.config.buttonList),function(item,index){return _c('div',{key:item.key,staticClass:"button-group-images-container__item",class:{
      up_down: _vm.config.groupStyle.layout == 'up_down',
      left_right: _vm.config.groupStyle.layout == 'left_right',
    }},[(index < _vm.beginHideIndex)?_c('div',{staticClass:"button-group-button-item__button_container__wrap",style:({
        cursor: _vm.hasClickEvent(item) ? 'pointer' : 'default',
      })},[_c('ButtonGroupButtonItem',{attrs:{"isH5":_vm.isH5,"item-config":item,"global-config":_vm.config}})],1):_vm._e(),(index == _vm.beginHideIndex)?_c('div',{staticClass:"button-group-button-item__button_container__wrap",on:{"click":_vm.openMoreButtonDialog}},[_c('ButtonGroupButtonItem',{attrs:{"item-config":_vm.moreButtonConfig,"global-config":_vm.config}})],1):_vm._e()])}),_c('el-dialog',{staticClass:"custom-fullscreen",attrs:{"visible":_vm.moreButtonDialogVisible,"title":"更多","append-to-body":""},on:{"update:visible":function($event){_vm.moreButtonDialogVisible=$event}}},[_c('div',{staticClass:"button-group-setting-content__wrap",staticStyle:{"max-height":"500px","margin":"12px"}},_vm._l((_vm.config.buttonList),function(item,index){return (index >= _vm.beginHideIndex)?_c('div',{key:item.key,staticClass:"button-group-images-container__item",class:{
          up_down: _vm.config.groupStyle.layout == 'up_down',
          left_right: _vm.config.groupStyle.layout == 'left_right',
        }},[_c('div',{staticClass:"button-group-button-item__button_container__wrap",style:({
            cursor: _vm.hasClickEvent(item) ? 'pointer' : 'default',
          })},[_c('ButtonGroupButtonItem',{attrs:{"item-config":item,"global-config":_vm.config}})],1)]):_vm._e()}),0)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }