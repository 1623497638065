<template>
  <div class="templateSetting-container">
    <div class="templateSetting-aside">
      <el-button
        @click="$router.back()"
        type=""
        plain
        size="small"
        style="width: 100%; margin-bottom: 10px"
        icon="el-icon-arrow-left"
        >返回</el-button
      >
      <uploadWordFromDisk
        @uploadSuccess="uploadWordSuccess"
        class="uploadButton"
        :form-id="formId"
      >
        <el-button
          type="primary"
          @click="handleUploadWord"
          icon="el-icon-upload"
          size="small"
          style="width: 100%"
          >上传Word模版</el-button
        >
      </uploadWordFromDisk>
      <uploadFromDisk @uploadSuccess="uploadSuccess" class="uploadButton">
        <el-button
          type="primary"
          @click="handleUploadExcel"
          icon="el-icon-upload"
          size="small"
          style="width: 100%"
          >上传Excel模版</el-button
        >
      </uploadFromDisk>
      <ul style="padding: 0; margin: 0">
        <li
          v-for="item in templateList"
          :key="item.id"
          @click="handleRowClick(item)"
          class="template-item"
          :class="{ active: isActive == item.id }"
        >
          <svg class="icon" aria-hidden="true" v-if="item.type == 'word'">
            <use xlink:href="#icon-WORD2"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-if="item.type == 'excel'">
            <use xlink:href="#icon-ECEL"></use>
          </svg>
          <!--            <i-->
          <!--                class="iconfont icon-ECEL"-->
          <!--                v-if="item.type == 'excel' "-->
          <!--            ></i>-->
          <!--            <i-->
          <!--                class="iconfont icon-WORD2"-->
          <!--                v-if="item.type == 'word' "-->
          <!--            ></i>-->
          <el-tooltip
            placement="bottom"
            :content="item.name"
            :disabled="item.name.length > 6 ? false : true"
          >
            <span class="content">
              {{ item.name }}
            </span>
          </el-tooltip>
          <el-dropdown trigger="click" placement="bottom-start">
            <el-button class="n-btn" size="mini" key="export" @click.stop>
              <i
                class="iconfont icon-icon-more"
                style="font-size: 16px; margin: 0px"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="iconfont icon-bianji_fill"
                @click.native="handleRenameTemplate(item)"
                >重命名</el-dropdown-item
              >
              <el-dropdown-item
                class="iconfont icon-bianji_fill"
                @click.native="handleExportFileName(item)"
                >导出文件名设置</el-dropdown-item
              >
              <el-dropdown-item class="iconfont icon-bianji_fill">
                <uploadWordFromDisk
                  v-if="item.type == 'word'"
                  @uploadSuccess="uploadWordSuccess"
                  class="updateButton"
                  :form-id="formId"
                  :template-id="item.id"
                >
                  <el-button type="text">更新模版</el-button>
                </uploadWordFromDisk>
                <uploadFromDisk
                  v-else
                  @uploadSuccess="uploadSuccess"
                  class="updateButton"
                  :template-id="item.excelId"
                >
                  <el-button type="text">更新模版</el-button>
                </uploadFromDisk>
              </el-dropdown-item>
              <el-dropdown-item
                class="iconfont icon-shanchu_fill"
                @click.native="handleDeleteTemplate(item)"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <el-dialog title="导出文件名设置" :visible.sync="dialogVisible" width="25%">
      <el-select v-model="nodeName" placeholder="默认(表单名)">
        <el-option label="默认(表单名)" value=""> </el-option>
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.comment"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleExportTemplateRename()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--        <div class="tools" v-if="!hasExcel&&!hasWord" >-->
    <!--            <el-button-group>-->
    <!--                <el-button @click="$router.back()" type="" plain icon="el-icon-arrow-left">返回</el-button>-->
    <!--            </el-button-group>-->
    <!--        </div>-->
    <!--        <div v-if="!hasExcel&&!hasWord" class="upload-wrap">-->
    <!--            <uploadFromDisk @uploadSuccess="uploadSuccess" style="display: inline-flex">-->
    <!--                <el-button type="primary" plain @click="handleUploadExcel" icon="el-icon-upload">上传Excel模版</el-button>-->
    <!--            </uploadFromDisk>-->
    <!--            <uploadWordFromDisk @uploadSuccess="uploadWordSuccess" style="display: inline-flex"-->
    <!--                                :form-id="this.formDetail.id">-->
    <!--                <el-button type="primary" plain @click="handleUploadWord" icon="el-icon-upload">上传Word模版</el-button>-->
    <!--            </uploadWordFromDisk>-->
    <!--        </div>-->
    <template>
      <excelEdit
        v-if="isExcel && excelDetail.excel && !excelDetail.excel.templatePath"
        :formDetail.sync="excelDetail"
      />
      <word
        v-if="isWord"
        :form-detail="currentTemplate"
        :key="currentTemplate.id"
      />
      <excel
        v-if="isExcel && excelDetail.excel && excelDetail.excel.templatePath"
        :formDetail.sync="excelDetail.excel"
        :formId="formId"
        :templateName="currentTemplate.name"
      />
    </template>
  </div>
</template>

<script>
import uploadFromDisk from "@/views/lowCode/form/templateSetting/excel/uploadFromDisk";
import uploadWordFromDisk from "@/views/lowCode/form/templateSetting/word/uploadWordFromDisk";
import excelEdit from "@/views/lowCode/form/templateSetting/excel/excelEdit";
import word from "@/views/lowCode/form/templateSetting/word";
import excel from "@/views/lowCode/form/templateSetting/excel/excel";
import {
  getFormDetail,
  formCreateExcel,
  getTemplateList,
  renameTemplate,
  deleteTemplate,
} from "@/views/lowCode/form/api";
import { fetchMetaFieldList } from "@/api/form";
import { isExportFileName } from "../utils";

export default {
  name: "index",
  components: {
    excelEdit,
    word,
    uploadFromDisk,
    uploadWordFromDisk,
    excel,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      templateList: [],
      currentTemplate: {},
      formId: "",
      excelDetail: {},
      isWord: false,
      isExcel: false,
      dialogVisible: false,
      nodeName: "",
      options: [],
      exportTemplate: {}, //当前要设置导出名称的模板
    };
  },
  computed: {
    isActive() {
      return this.currentTemplate.id;
    },
  },
  watch: {
    templateList: {
      deep: true,
      handler(val) {
        console.log(val);
      },
    },
  },
  methods: {
    handleExportTemplateRename(item) {
      renameTemplate({
        id: this.exportTemplate.id,
        name: this.exportTemplate.name,
        type: this.exportTemplate.type,
        exportTitle: this.nodeName,
      })
        .then((res) => {
          this.$message.success("设置成功");
          this.init();
          this.dialogVisible = false;
        })
        .catch((err) => {});
    },
    handleExportFileName(item) {
      fetchMetaFieldList({
        tableName: this.formId,
        returnWithSystemField: false,
      }).then((res) => {
        this.exportTemplate = item;
        // todo 最好使用组件功能清单checkFieldAbility
        let options = res.data.list.filter((item) => {
          return isExportFileName(item.componentName);
        });
        this.options = options;
        if (item.exportTitle) {
          this.nodeName = item.exportTitle;
        } else {
          this.nodeName = "";
        }
        this.dialogVisible = true;
      });
    },
    handleRenameTemplate(item) {
      this.$prompt("请输入模板名称", "模板重命名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputValue: item.name,
        inputValidator: function (val) {
          if (!val) {
            return "模板名称不能为空";
          }
          // if (val.replace(/[^\x00-\xff]/g,"xx").length >14){
          //   return "名称长度超出限制";
          // }
        },
      })
        .then(({ value }) => {
          renameTemplate({
            id: item.id,
            name: value,
            type: item.type,
          }).then((res) => {
            this.$message.success("重命名成功");
            item.name = value;
          });
        })
        .catch((err) => {});
    },

    handleDeleteTemplate(val) {
      const h = this.$createElement;
      this.$confirm("", {
        message: h("div", null, [
          h("i", {
            class: "el-icon-question",
            style: "color:#f90;font-size:18px;",
          }),
          h(
            "span",
            {
              style:
                "margin-left:10px;font-size:18px;line-height:20px;font-weight:700;vertical-align:top;",
            },
            "删除模板",
          ),
          h("p", { style: "margin:14px 0 14px 30px;" }, "是否删除该模板"),
        ]),
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "del-model",
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then((res) => {
          deleteTemplate({
            id: val.id,
            type: val.type,
          }).then((res) => {
            this.$message.success("模板删除成功");
            if (this.currentTemplate && this.currentTemplate.id == val.id) {
              if (val.type == "excel") {
                this.isExcel = false;
              } else if (val.type == "word") {
                this.isWord = false;
              }
              this.currentTemplate = {};
            }
            let index = this.templateList.findIndex((item) => {
              return item.id == val.id;
            });
            this.templateList.splice(index, 1);
          });
        })
        .catch(() => {});
    },
    handleRowClick(data) {
      this.isWord = false;
      this.isExcel = false;
      if (data.type == "excel") {
        getFormDetail(this.formId, false, data.id).then((resp) => {
          this.excelDetail = resp.data.form;
          this.$nextTick(() => {
            this.isExcel = true;
            this.currentTemplate = data;
          });
        });
      } else if (data.type == "word") {
        this.$nextTick(() => {
          this.isWord = true;
          this.currentTemplate = data;
        });
      }
    },
    uploadWordSuccess(attachment, templateId) {
      this.init(templateId);
    },
    uploadSuccess(attachment, templateId) {
      if (templateId) {
        this.init(templateId);
      } else {
        let params = {
          excelId: attachment.excel.id,
          formId: this.formId,
          title: attachment.excel.title,
        };
        formCreateExcel(params).then((resp) => {
          this.init(templateId);
        });
      }
    },
    updateForm() {
      this.isWord = false;
      this.isExcel = false;
      this.currentTemplate = {};
      this.init();
    },
    async init(templateId) {
      this.formId = this.$route.query.id;
      this.templateList = await this.apiGetFormDetail();
      if (templateId && this.currentTemplate.type) {
        let data = this.templateList.find((item) => {
          return this.currentTemplate.type == "excel"
            ? templateId == item.excelId
            : templateId == item.id;
        });
        if (data) this.handleRowClick(data);
      }
    },
    handleUploadWord() {},
    handleUploadExcel() {},
    async apiGetFormDetail(formId) {
      return getTemplateList({
        formId: this.formId,
        isAll: true,
      }).then((resp) => {
        return resp.data.wordsAndExcels;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.templateSetting-container {
  display: flex;
  flex-direction: row;
  .templateSetting-aside {
    height: calc(100vh - 50px);
    width: 150px;
    margin-left: 10px;
    padding: 32px 10px 32px 10px;
    background-color: #ffffff;
  }
  .container {
    flex: 1;
  }
  .upload-wrap {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.uploadButton {
  display: inline-flex;
  width: 130px;
  margin-bottom: 10px;
  ::v-deep .upload-container {
    width: 130px;
  }
}
.updateButton {
  display: inline-flex;
}
.n-btn {
  padding: 4px !important;
  border: 0px !important;
  margin: 6px 0px 6px 0px;
  border-radius: 5px;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  margin-right: 2px;
}
.template-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background-color: #f0f7ff;
    cursor: pointer;
  }
  &.active {
    background-color: #f0f7ff;
  }
}
.el-dropdown {
  right: 0px;
}

.content {
  width: 88px;
  font-family: "Microsoft YaHei";
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-dropdown-menu {
  margin-top: 0;
  padding: 0 0;
}
.el-dropdown-menu__item {
  margin-right: 0px;
  font-size: 14px;
  color: #091e42;
  .el-button {
    padding: 10px 0;
  }
  .el-button--text {
    font-weight: 400;
    color: #091e42;
  }
  &:hover {
    .el-button--text {
      color: #608dee;
    }
  }
}
.groupSelectDialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-dialog {
  margin-top: 30vh !important;
  .el-dialog__header {
    border-bottom: none;
  }
  .el-dialog__footer {
    border-top: none;
  }
  .el-select {
    padding: 0 20px;
    width: 100%;
  }
}
</style>
