<template>
  <div class="attr-row">
    <div class="attr-label">占位内容</div>
    <el-input
      :type="component.componentName == 'text_area' ? 'textarea' : ''"
      v-model="component.placeHolder"
      placeholder=""
      size="mini"
    ></el-input>
  </div>
</template>
<script>
import { componentProps } from "./util";
export default {
  name: "attr-placeholder",
  props: componentProps,
};
</script>