<template>
  <div class="container">
    <div class="tools">
      <el-button-group>
        <el-button type="primary" icon="el-icon-check" @click="handleSave"
          >保存
        </el-button>
        <el-button type="primary" icon="el-icon-view" @click="handlePreview"
          >预览
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="handleDownload"
          >下载模板
        </el-button>
      </el-button-group>
    </div>
    <el-table :data="wordFieldList" height="10" style="width: 100%" border>
      <el-table-column prop="variable" label="word字段"></el-table-column>
      <el-table-column label="表单字段">
        <template slot-scope="{ row, $index }">
          <div style="display: flex; flex-direction: row">
            <el-select
              v-model="row.metaFieldId"
              placeholder="请选择关联表格字段"
              style="width: 100%"
              clearable
              @clear="handleClear(row)"
              @change="handleFieldChange(row, $index)"
              @focus="setOptionWidth"
            >
              <template v-for="item in formFieldList">
                <el-option
                  v-if="item.componentName != 'reference_data_list'"
                  :key="item.id"
                  :label="item.comment"
                  :value="item.id"
                  :style="{ width: optionWidth }"
                >
                </el-option>
              </template>
            </el-select>
            <el-button
              style="margin: 0 10px"
              type="text"
              @click="showDrawer(row)"
              v-if="row.metaFieldId && formFieldConfigurable(row)"
              >配置</el-button
            >
          </div>
          <div
            style="display: flex; flex-direction: row"
            v-if="getSubcomponentList(row.metaFieldId).length != 0"
          >
            <el-select
              v-model="row.name"
              placeholder="请选择关联子表格字段"
              style="width: 100%"
              @focus="setOptionWidth"
            >
              <template v-for="item in getSubcomponentList(row.metaFieldId)">
                <el-option
                  v-if="item.componentName != 'reference_data_list'"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                  :style="{ width: optionWidth }"
                >
                </el-option>
              </template>
            </el-select>
            <el-button
              style="margin: 0 10px"
              type="text"
              @click="showDrawer(row)"
              v-if="row.name && subformFieldConfigurable(row)"
              >配置</el-button
            >
          </div>
          <el-radio-group
            style="padding: 10px"
            v-model="row.selectModel"
            v-if="isShowSelectModel(row)"
          >
            <el-radio :label="0">只选中项</el-radio>
            <el-radio :label="1">平铺选项</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="流程字段">
        <template slot="header" slot-scope="{ row }">
          流程字段
          <el-select v-model="workFlowId" placeholder="选择流程" size="small">
            <el-option
              v-for="item in flowList"
              :key="item.workflowId"
              :label="item.title"
              :value="item.workflowId"
              >{{ item.title }}
              <el-tag
                :key="item.workFlowId"
                v-if="item.versionStatus == 'enable'"
                size="mini"
                type="success"
                >启用中
              </el-tag>
            </el-option>
          </el-select>
        </template>
        <template slot-scope="{ row, $index }">
          <el-select
            v-model="row.nodeKey"
            placeholder="请选择流程节点"
            style="width: 100%"
            clearable
            @change="handleFlowFieldChange(row, $index)"
          >
            <el-option
              v-for="item in nodeList.filter(
                (item) => !item.endNode && !item.startNode,
              )"
              :key="item.key"
              :label="item.title"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div style="display: flex; flex-direction: row">
            <el-select
              v-if="row.nodeKey"
              v-model="row.flowName"
              placeholder="请选择流程字段"
              style="width: 100%"
              @change="flowNodeFieldEnumsChange(row)"
              clearable
            >
              <el-option
                v-for="item in nodeFieldEnums"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              style="margin: 0 10px"
              type="text"
              @click="showDrawer(row)"
              v-if="
                row.flowName &&
                ['signInfo', 'actionTime'].includes(row.flowName)
              "
              >配置</el-button
            >
          </div>
          <template v-if="isShowFlowModel(row)">
            <el-radio-group
              v-model="row.flowModel"
              @change="modelChange(row)"
              style="padding: 10px"
            >
              <el-radio :label="0">单人模式</el-radio>
              <el-radio :label="1">会签模式</el-radio>
            </el-radio-group>
            <el-select
              v-if="row.flowModel == 1"
              v-model="row.signSort"
              placeholder="请选择流程字段"
              style="width: 100%"
            >
              <el-option
                v-for="item in signSortList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="row.flowModel == 0"
              v-model="row.flowIndex"
              placeholder="请输入节点负责人序号"
              type="number"
              onmousewheel="return false;"
              oninput="value=value.replace(/^0|[^0-9]/g, '')"
            ></el-input>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
      :title="'表单字段配置(' + currentField.variable + ')'"
      :visible.sync="drawer"
      direction="rtl"
      size="25%"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="fieldSetting">
        <template v-if="isShowTimeSet">
          <span class="subTitle">时间类型</span>
          <el-select
            v-model="currentField.picker"
            size="mini"
            style="width: 100%"
            @change="changePicker"
          >
            <el-option label="年" value="year"></el-option>
            <el-option label="年月" value="month"></el-option>
            <el-option label="年月日" value="date"></el-option>
            <el-option label="年月日时" value="date_hour"></el-option>
            <el-option label="年月日时分" value="date_minute"></el-option>
            <el-option label="年月日时分秒" value="datetime"></el-option>
          </el-select>
          <span class="subTitle">显示格式</span>
          <el-select
            v-model="currentField.dateFormat"
            size="mini"
            style="width: 100%"
            placeholder=""
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <span>图片宽高设置:</span>
          <span class="subTitle">宽:</span>
          <el-input
            v-model="currentField.width"
            placeholder="最小值10；默认60"
            type="number"
            onmousewheel="return false;"
            size="mini"
            :min="10"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input>
          <span class="subTitle">高:</span>
          <el-input
            v-model="currentField.height"
            placeholder="最小值10；默认60"
            type="number"
            onmousewheel="return false;"
            size="mini"
            :min="10"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input>
        </template>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { wordExport } from "@/views/lowCode/form/api";
import uploadFromDisk from "@/views/lowCode/form/templateSetting/excel/uploadFromDisk";
import excelRender from "@/views/lowCode/form/templateSetting/excel/excelRender";
import {
  formExportWordListField,
  formExportWordSaveFieldMapping,
  companyWorkflowList,
} from "@/views/lowCode/form/templateSetting/api";
import { fetchMetaFieldList } from "@/api/form";
import { templateMixin } from "@/views/lowCode/form/templateSetting/templateMixin";
export default {
  mixins: [templateMixin],
  components: {
    uploadFromDisk,
    excelRender,
  },
  data() {
    return {
      formFieldList: [],
      wordTableData: [],
      wordFieldList: [],
      flowList: [],
      drawer: false,
      currentField: {},
      hasConfigComponents: [
        "image_uploader",
        "attachment_uploader",
        "sign_list_input",
        "sign_input",
        "date_picker",
      ],
    };
  },
  props: {
    formDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    formId() {
      return this.formDetail.formId;
    },
    appFormExportWordId() {
      return this.formDetail.id;
    },
    workFlowId: {
      get() {
        let item = this.wordFieldList.find((item) => {
          return item.workflowId;
        });
        return (item && item.workflowId) || "";
      },
      set(val) {
        this.wordFieldList.forEach((item) => {
          item.workflowId = val;
          item.nodeKey = "";
          item.flowName = "";
          this.$set(item, "flowModel", 0);
          this.$set(item, "flowIndex", 1);
        });
      },
    },
  },
  watch: {
    workFlowId(val) {
      this.getWorkflowNodeList(val);
    },
    appFormExportWordId() {
      this.initTableData();
    },
  },
  async created() {
    await this.init();
    this.initTableData();
    await this.fetchWorkFlowList();
  },
  methods: {
    handleFlowFieldChange(row, $index) {
      this.wordFieldList[$index].metaFieldId = "";
      row.flowName = "";
      row.flowModel = 0;
      row.flowIndex = 1;
    },
    fetchWorkFlowList() {
      companyWorkflowList({
        companyId: this.$store.getters.currentCompanyId,
        isOpen: true,
        formId: this.formId,
      }).then((res) => {
        this.flowList = res.data.page.list;
      });
    },
    handleFieldChange(row, $index) {
      row.flowName = "";
      this.$set(row, "flowIndex", 1);
      this.$set(row, "flowModel", 0);
      this.$set(row, "name", "");
      this.wordFieldList[$index].nodeKey = "";
      this.handleClear(row);
    },
    getSubcomponentList(metaId) {
      let formFiled = this.formFieldList.find((filed) => filed.id == metaId);
      if (formFiled && formFiled.component) {
        if (formFiled && formFiled.component.componentName == "json_form") {
          return formFiled.component.components;
        } else if (
          formFiled &&
          (formFiled.component.componentName == "reference_data" ||
            formFiled.component.componentName == "form_data")
        ) {
          return formFiled.component.columns.map((item) => {
            return {
              componentName: item.field.componentName,
              name: item.field.name,
              title: item.title,
            };
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    handleSave() {
      formExportWordSaveFieldMapping({
        formId: this.formId,
        appFormExportWordId: this.appFormExportWordId,
        content: JSON.stringify(this.wordFieldList),
      })
        .then((resp) => {
          this.$message.success("保存成功");
        })
        .catch((err) => {
          this.$message.warning("保存失败");
        });
    },
    async init() {
      await this.apiGetFormFieldMeta(this.formDetail.formId);
    },
    initTableData() {
      formExportWordListField({
        formId: this.formId,
        appFormExportWordId: this.appFormExportWordId,
      }).then((resp) => {
        this.wordFieldList = resp.data.content.map((item) => {
          if (!item.metaFieldId) {
            item.metaFieldId = "";
          }
          if (!item.workflowId) {
            item.workflowId = "";
          }
          if (!item.flowName) {
            item.flowName = "";
          }
          if (!item.nodeKey) {
            item.nodeKey = "";
          }
          return item;
        });
        this.$nextTick(() => {
          //更新模板下新增字段workFlowId需重新赋值,处理旧数据需重新保存
          if (this.workFlowId) {
            this.wordFieldList.forEach((item) => {
              item.workflowId = this.workFlowId;
            });
          }
        });
      });
    },
    async apiGetFormFieldMeta(formId) {
      let params = {
        tableName: formId || this.formId,
        returnWithSystemField: true,
      };

      await fetchMetaFieldList(params, ["attachmentList", "shareLink"]).then(
        (resp) => {
          this.formFieldList = resp.data.list;
        },
      );
    },
    handleDownload() {
      if (this.formDetail && this.formDetail.id) {
        wordExport({ id: this.formDetail.id }).then(({ data }) => {
          let fileName =
            this.formDetail.title || this.formDetail.name + "模板.docx";
          this.handleBlobDownload(data.fileUrl, fileName);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 50px);
  padding: 5px;
  overflow: auto;

  .tools {
    padding: 10px 0;
  }
}
.fieldSetting {
  display: flex;
  flex-direction: column;
}
::v-deep .el-drawer__body {
  padding: 0 20px;
  font-size: 14px;
}
.subTitle {
  padding: 10px 0;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
