<template>
  <el-dialog
    title="移动卡片"
    :append-to-body="true"
    :visible.sync="visible"
    width="600px"
    @close="close"
    @opened="closeMove"
    @open="open"
  >
    <el-form
      :model="moveCard"
      ref="moveCard"
      :rules="rules"
      style="padding: 20px"
    >
      <el-form-item label="卡片名称">
        <div>{{ moveCard.title }}</div>
      </el-form-item>
      <el-form-item label="卡片原始栏目">
        <div>{{ columnName }}</div>
      </el-form-item>
      <el-form-item label="目标栏目" size="normal" prop="targetColumnId">
        <el-select
          v-model="moveCard.targetColumnId"
          placeholder="请选择目标栏目"
        >
          <el-option
            v-for="item in columnList.filter(
              (item) => item.id != moveCard.bulletinColumnId
            )"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="close" size="small">取消</el-button>
      <el-button
        @click="saveColumnSort"
        type="primary"
        :loading="sortColumnLoading"
        size="small"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { bulletinCardSort } from "@/api/boardManage";
export default {
  props: {
    visible: Boolean,
    card: Object,
    columnList: Array,
  },
  computed: {
    columnName() {
      let item = this.columnList.find(
        (item) => item.id == this.card.bulletinColumnId
      );

      if (item) {
        return item.title;
      }
      return "";
    },
  },
  data() {
    return {
      moveCard: {},
      sortColumnLoading: false,
      rules: {
        targetColumnId: [{ required: true, message: "请选择目标栏目" }],
      },
    };
  },
  methods: {
    saveColumnSort() {
      this.$refs.moveCard.validate((valid) => {
        if (!valid) {
          return;
        }
        let column = this.columnList.find(
          (item) => item.id == this.moveCard.targetColumnId
        );

        let params = {
          cardId: this.moveCard.id,
          sortNumber: 0,
          toBulletinId: this.bulletinId,
          toColumnId: this.moveCard.targetColumnId,
        };

        this.sortColumnLoading = true;
        bulletinCardSort(params)
          .then(({ data }) => {
            this.$emit("removeCard", this.card);
            this.$refs.moveCard.resetFields();

            this.sortColumnLoading = false;
            this.$message.success(`卡片成功移动到【${column.title}】栏目中`);
            this.close();
          })
          .catch(() => {
            this.sortColumnLoading = false;
          });
      });
    },
    close() {
      this.$emit("update:visible", false);
    },
    open() {
      this.moveCard = this._.cloneDeep(this.card);
    },
    closeMove() {
      if (this.$refs.moveCard) {
        this.$refs.moveCard.clearValidate();
      }
    },
  },
};
</script>