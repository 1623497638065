<template>
  <div>
    <el-form
      ref="form"
      :model="node"
      label-position="top"
      :show-message="false"
      :disabled="disabled"
    >
      <el-form-item label="选择工作表" required prop="formTitle">
        <el-input
          v-model="node.formTitle"
          @focus="visible = true"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="指定日期字段" required prop="field.fieldName">
        <el-select
          v-model="node.field.fieldName"
          placeholder="请选择指定日期字段"
          @change="changeDateField"
          clearable
        >
          <el-option
            v-for="item in timeFields"
            :key="item.name"
            :label="item.title"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 选定日期字段 -->
      <template v-if="node.field && node.field.fieldName">
        <div>
          <div
            class="el-form-item__label"
            style="font-size: 14px; font-weight: bold"
          >
            <span style="color: #f56c6c; margin-right: 4px">*</span>开始执行时间
          </div>
          <div class="row-weui row-flex">
            <el-form-item :class="timeOffSetClass">
              <el-select v-model="timeOffsetType" placeholder="placeholder">
                <el-option
                  v-for="opt in startExecuteTimeTypeEnums"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <template v-if="timeOffsetType != 'current'">
              <el-form-item :error="timerNumberError" style="margin-left: 10px">
                <el-input v-model="timeNumber"> </el-input>
              </el-form-item>
              <el-form-item style="width: 180px; margin-left: 10px">
                <el-select size="small" class="ml" v-model="timeUnit">
                  <el-option
                    label="分钟"
                    value="minute"
                    v-show="!isDateWithoutTime"
                  ></el-option>
                  <el-option
                    label="小时"
                    value="hour"
                    v-show="!isDateWithoutTime"
                  ></el-option>
                  <el-option label="天" value="day"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </div>
        </div>

        <div
          class="row-weui"
          v-if="node.field.picker == 'date'"
          style="margin-bottom: 10px"
        >
          <el-form-item
            required
            style="width: 180px; margin-bottom: 0"
            prop="executeTimeStr"
          >
            <el-time-picker
              v-model="node.executeTimeStr"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59',
              }"
              placeholder="任意时间点"
              value-format="HH:mm:ss"
              :clearable="false"
            >
            </el-time-picker>
          </el-form-item>
          <div style="font-size: 14px; margin-left: 4px">执行</div>
        </div>
        <!-- 重复周期 -->
        <el-form-item label="重复周期" prop="loopType">
          <el-select
            v-model="node.loopType"
            placeholder="请选择重复周期"
            @change="changeLoopType"
          >
            <el-option
              v-for="opt in timerFieldLoopTypeEnums"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="node.loopType != 'once'"
          label="结束执行时间"
          prop="endTimeField"
        >
          <el-select
            v-model="node.endTimeField.fieldName"
            @change="changeEndTimeField"
            :clearable="true"
          >
            <el-option
              v-for="item in endTimeFields"
              :key="item.name"
              :label="item.title"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 过滤条件 -->
        <div>
          <field-condition
            v-if="node.formId"
            :componentList="componentList"
            :condition.sync="node.condition"
            btnText="添加触发条件"
            abilityKey="inRpaFilter"
            :formId="node.formId"
            :lockValueType="true"
            valueType="custom"
          ></field-condition>
        </div>
      </template>
    </el-form>
    <data-source
      :visible.sync="visible"
      :menus="['form']"
      @changeSource="changeForm"
    ></data-source>
  </div>
</template>
<script>
import dataSource from "@/components/data-source";
import { fetchMetaComponents } from "@/api/form";
import fieldCondition from "@/components/AdvanceQuery/field-condition";
import {
  getValueByKey,
  isEmpty,
  isEmptyByKey,
  isIDEmpty,
} from "@zgg-core-utils/utils";
import {
  startExecuteTimeTypeEnums,
  timerFieldLoopTypeEnums,
} from "./loopTypeEnums";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { mapState } from "vuex";
import { isEmptyRpaActionFilter } from "./rpa-utils";

export default {
  name: "rpa-timer-field",
  components: { dataSource, fieldCondition },
  props: {
    node: Object,
    disabled: Boolean,
  },
  data() {
    return {
      startExecuteTimeTypeEnums,
      timerFieldLoopTypeEnums,
      timeFields: [],
      componentList: [],
      visible: false,
      timeType: "",
      timerNumberError: "",
    };
  },

  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    endTimeFields() {
      return this.timeFields.filter(
        (item) => item.name != this.node.field.fieldName,
      );
    },
    isDateWithoutTime() {
      let datePicker = ["year", "month", "date"];
      return this.node.field && datePicker.includes(this.node.field.picker);
    },
    timeOffSetClass() {
      console.log(
        this.timeOffsetType,
        getValueByKey(this.node, "field.picker"),
      );
      if (this.timeOffsetType == "current") {
        return "time-flex";
      } else {
        return "time-width";
      }
    },
    timeOffsetType: {
      get() {
        if (!isEmpty(this.timeType)) {
          return this.timeType;
        }
        if (!isEmpty(this.node.timeOffset)) {
          let value;
          let values = Object.values(this.node.timeOffset);
          values = values.filter((item) => item != 0);
          if (values.length) {
            value = values[0];
          }
          if (value < 0) {
            return "before";
          }
          if (value > 0) {
            return "after";
          }
          return "";
        } else {
          return "current";
        }
      },

      set(val) {
        let obj = {};
        if (val == "current") {
          obj = undefined;
          this.timerNumberError = "";
        }
        if (val == "before") {
          obj["day"] = -1;
        }
        if (val == "after") {
          obj["day"] = 1;
        }
        this.timeType = val;
        this.$set(this.node, "timeOffset", obj);
      },
    },
    timeNumber: {
      get() {
        if (isEmpty(this.node.timeOffset)) {
          return "";
        }
        let value;
        let values = Object.values(this.node.timeOffset);
        if (values.length) {
          value = "";
          for (const [key, val] of Object.entries(this.node.timeOffset)) {
            if (val !== 0) {
              value = Math.abs(val);
            }
          }
          return value;
        }
        return "";
      },
      set(val) {
        val = this.inputNumberCheck(val);
        let obj = {};
        let newVal;

        let timeOffsetType = this.timeOffsetType;

        if (timeOffsetType == "current") {
          this.node.timeOffset = undefined;
          return;
        }
        if (timeOffsetType == "before") {
          newVal = -val;
        }
        if (timeOffsetType == "after") {
          newVal = val;
        }
        obj[this.timeUnit] = newVal;
        if (isIDEmpty(newVal)) {
          this.timerNumberError = "不能为空";
        } else {
          this.timerNumberError = "";
        }
        this.$set(this.node, "timeOffset", obj);
      },
    },
    timeUnit: {
      get() {
        if (isEmpty(this.node.timeOffset)) {
          return "";
        }
        let unit = "day";
        for (const [key, value] of Object.entries(this.node.timeOffset)) {
          if (typeof value !== "undefined" && value !== 0) {
            unit = key;
          }
        }
        return unit;
      },
      set(val) {
        let obj = {};
        let newVal;
        if (this.timeOffsetType == "current") {
          newVal = 0;
        }
        if (this.timeOffsetType == "before") {
          newVal = -this.timeNumber;
        }
        if (this.timeOffsetType == "after") {
          newVal = this.timeNumber;
        }
        obj[val] = newVal;
        this.$set(this.node, "timeOffset", obj);
      },
    },
  },
  created() {
    if (isEmpty(this.node.field)) {
      this.$set(this.node, "field", {
        fieldName: "",
        fieldTitle: "",
        picker: "",
      });
    }

    if (this.node.loopType != "once" && isEmpty(this.node.endTimeField)) {
      this.$set(this.node, "endTimeField", {
        fieldName: "",
        fieldTitle: "",
        picker: "",
      });
    }
    console.log(this._.cloneDeep(this.node));

    if (this.node.formId) {
      this.fetchMetaComponents(this.node.formId);
    }
  },

  methods: {
    changeLoopType(val) {
      this.$set(this.node, "executeEndTime", "");
      if (val === "once") {
        this.$set(this.node, "endTimeMode", "");
        this.$set(this.node, "endTimeField", null);
      } else {
        this.$set(this.node, "endTimeMode", "datePickerField");
        this.$set(this.node, "endTimeField", {
          fieldName: "",
          fieldTitle: "",
          picker: "",
        });
      }
    },
    inputNumberCheck(value) {
      let val;
      // val = value.replace(/[^\d]/g, '').replace(/^0{1,}/g, '')
      val = value.replace(/[^\d]/g, "");
      if (val) {
        val = parseInt(val);
      }

      return val;
    },
    changeDateField(name) {
      let field = this.timeFields.find((item) => item.name == name);
      this.$set(this.node.field, "fieldTitle", field.title);
      this.$set(this.node.field, "picker", field.picker);
      this.$set(this.node, "executeTimeStr", "");
    },
    changeEndTimeField(name) {
      if (name) {
        let field = this.endTimeFields.find((item) => item.name == name);
        this.$set(this.node.endTimeField, "fieldTitle", field.title);
        this.$set(this.node.endTimeField, "picker", field.picker);
      } else {
        this.$set(this.node.endTimeField, "fieldTitle", "");
        this.$set(this.node.endTimeField, "picker", "");
      }
    },
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.timeOffsetType != "current" && this.timerNumberError) {
          return;
        }

        if (isEmptyRpaActionFilter(this.node.condition)) {
          this.$message.error("条件的值不能为空，请为条件赋值");
          return;
        }

        callback(valid);
      });
    },
    changeForm({ tableName, tableTitle }) {
      let callback = () => {
        this.node.formId = tableName;
        this.node.formTitle = tableTitle;
        this.node.field = {
          fieldName: "",
          fieldTitle: "",
          picker: "",
        };
        this.node.condition = {
          advanceQuery: [],
          rel: "and",
        };
        if (this.node.loopType === "once") {
          this.node.endTimeField = null;
        } else {
          this.$set(this.node, "endTimeField", {
            fieldName: "",
            fieldTitle: "",
            picker: "",
          });
        }
        //
      };
      if (this.node.formId != tableName) {
        if (!isIDEmpty(this.node.formId)) {
          this.$confirm("切换表单将会清除已设置的配置", "提示")
            .then(callback)
            .catch(() => {});
        } else {
          callback();
        }
      }

      this.fetchMetaComponents(tableName);
    },
    fetchMetaComponents(tableName) {
      this.$store
        .dispatch("rpaNodeFields/fetchMetaComponents", {
          tableName: tableName,
        })
        .then((list) => {
          this.timeFields = list.filter(
            (item) =>
              item.componentName == "date_picker" &&
              ["date", "datetime", "date_hour", "date_minute"].includes(
                item.picker,
              ),
          );
          this.componentList = list.filter((item) =>
            checkFieldAbility(item, "inRpaFilter"),
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.required {
  &::before {
    content: "*";
    color: #f56c6c;
    font-size: 14px;
    margin-right: 4px;
    font-weight: bold;
  }
}
.row-weui {
  display: flex;
  align-items: center;
}
.row-flex {
  flex: 1;
}
.time-flex {
  width: 100%;
}
.time-width {
  width: 180px;
}
</style>
