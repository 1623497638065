<template>
  <div>
    <div class="rpa-dialog" v-show="visible">
      <div class="rpa-dialog-header">
        <div>【{{ title }}】智能助手数据</div>
        <i
          @click="visible = false"
          class="el-icon-close"
          style="font-size: 20px; cursor: pointer"
        ></i>
      </div>
      <div class="rpa-dialog-body">
        <el-table
          :data="page.list"
          ref="table"
          style="width: 100%"
          height="200px"
        >
          <el-table-column prop="title" label="流程标题"> </el-table-column>
          <el-table-column prop="status" label="流程状态" width="200">
            <template slot-scope="scope">
              <span v-text="getStatus(scope.row)"></span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="当前节点">
              <template slot-scope="scope">
                <span v-text="getNodes(scope.row.currentNodes)"></span>
              </template>
            </el-table-column> -->
          <el-table-column prop="startTime" label="流程开始时间">
          </el-table-column>
          <el-table-column prop="endTime" label="流程结束时间">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showLog(scope.row.id)"
                >日志</el-button
              >
              <el-button
                type="text"
                @click="showDetail(scope.row.id, scope.row.robotWorkflowId)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination
            :total="page.totalRow"
            :page.sync="page.pageNumber"
            :limit.sync="page.pageSize"
            @pagination="fetchData"
          />
        </div>
      </div>
    </div>
    <rpa-log ref="rpaLog"></rpa-log>
    <rpa-workflow-detail ref="rpaWorkflow"></rpa-workflow-detail>
  </div>
</template>
<script>
import { fetchRobotFlowDataList } from "./api";
import Pagination from "@/components/Pagination";
import rpaLog from "./rpa-log";
import rpaWorkflowDetail from "./rpa-workflow-detail";

export default {
  components: { Pagination, rpaLog, rpaWorkflowDetail },
  data() {
    return {
      title: "",
      visible: false,
      robotId: "",
      logVisible: false,
      page: {
        pageNumber: 1,
        pageSize: 20,
        totalPage: 1,
        totalRow: 0,
      },
    };
  },
  methods: {
    showLog(workflowDataId) {
      this.$refs.rpaLog.open(workflowDataId);
    },
    showDetail(workflowDataId, robotWorkflowId) {
      this.$refs.rpaWorkflow.open(workflowDataId, robotWorkflowId);
    },
    getNodes(list) {
      // return list.map(item=>item.)
    },
    getStatus(row) {
      //  queue("排队中"),
      // processing("进行中"),
      // exception("异常中止,这个状态下可以手动进行尝试"),
      // fail("人工拒绝/手动中止/流程没有走到最终节点都算失败"),
      // success("完成"),
      let obj = {
        queue: "排队中",
        processing: "进行中",
        exception: "异常中止",
        fail: "失败",
        success: "完成",
      };
      if (obj[row.status]) {
        return obj[row.status];
      }
      return row.status;
    },
    fetchData({ page, limit }) {
      this.page.pageSize = limit;
      this.fetchFlowList(page);
    },
    open(robotId, title) {
      this.robotId = robotId;
      this.visible = true;
      this.title = title;

      this.$nextTick(() => {
        this.fetchFlowList(1);
      });
    },
    fetchFlowList(pageNumber = 1) {
      fetchRobotFlowDataList({
        robotId: this.robotId,
        pageNumber,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.$set(this, "page", res.data.page);
        this.$nextTick(() => {
          this.$refs.table.doLayout();
          console.log("test");
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rpa-dialog {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
}
.rpa-dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px;
}
.rpa-dialog-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.flow-table {
  flex: 1;
  overflow: auto;
}
</style>
