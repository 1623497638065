<template>
  <div class="el-form-item" style="margin: 0; padding: 0; width: 100%">
    <label
      class="el-form-item__label"
      v-if="!item.hiddenTitle"
      style="padding: 0 5px"
      v-text="item.title"
    ></label>
    <form-edit
      v-if="item.displayMode == 'inline'"
      :isInclude="true"
      :formComponent="formComponent"
      :areaTree="areaTree"
    ></form-edit>
    <template v-else>
      <div class="el-input__inner">
        <el-button @click="visible = true" type="text" style="padding: 0"
          >查看详情</el-button
        >
      </div>
      <el-dialog
        title="表单预览"
        :visible.sync="visible"
        width="1000px"
        append-to-body
        destroy-on-close
      >
        <form-edit
          :formComponent="formComponent"
          :areaTree="areaTree"
          ref="form"
        ></form-edit>

        <div style="height: 20px"></div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import { getFormDetail } from "../api";
import FormEdit from "./FormEdit";

export default {
  components: { FormEdit },
  props: {
    item: Object,
    areaTree: Array,
    form: Object,
  },
  data() {
    return {
      formComponent: {},
      visible: false,
    };
  },
  created() {
    getFormDetail(this.item.formId).then((res) => {
      this.formComponent = res.data.form.component;
    });
  },
};
</script>