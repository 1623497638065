<template>
  <div>
    <el-form
      ref="form"
      :model="node"
      label-width="80px"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="指定通知人" prop="userRange">
        <rpa-notice-users
          :userRange="node.userRange"
          :disabled="disabled"
        ></rpa-notice-users>
      </el-form-item>
      <el-form-item
        label="通知内容"
        prop="smsTemplateId"
        :rules="[{ required: true, message: '请完善通知内容' }]"
      >
        <sms-edit
          v-model="node.smsTemplateId"
          :msgNodeFields.sync="node.msgNodeFields"
          @change="changeMsgNodeFields"
          :disabled="disabled"
        >
        </sms-edit>
      </el-form-item>
      <el-form-item prop="msgNodeFields" style="margin-top: 16px">
        <el-table :data="node.msgNodeFields" height="300">
          <el-table-column
            property="smsTemplateFieldName"
            label="占位符"
            width="150"
          ></el-table-column>
          <el-table-column label="字段属性">
            <template slot-scope="{ row }">
              <div class="sms-row">
                <el-select
                  v-model="row.nodeKey"
                  placeholder="请选择节点"
                  size="small"
                  @change="changeNode(row, $event)"
                >
                  <el-option
                    v-for="item in parentNodes"
                    :key="item.key"
                    :label="item.title"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="row.name"
                  placeholder="请选择字段"
                  :disabled="!row.nodeKey"
                  size="small"
                  style="margin-left: 10px"
                >
                  <el-option
                    v-for="item in getFieldList(row.nodeKey)"
                    :key="item.name"
                    :label="item.title"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import rpaNoticeUsers from "./rpa-notice-users";
import smsEdit from "@/views/lowCode/rpa/sms-edit";
import { buildJSONParserComponents, fromNodesMixins } from "./rpa-utils";
import { getFormDetail } from "../form/api";
import { getFormComponents, isEmpty } from "@zgg-core-utils/utils";
import _ from "lodash";
import { getRobotWorkflow } from "./api";
export default {
  name: "rpa-notice-sms",
  components: {
    rpaNoticeUsers,
    smsEdit,
  },
  mixins: [fromNodesMixins],
  props: {
    node: Object,
    disabled: Boolean,
  },
  inject: {
    getRootParams: {
      default: () => [],
    },
  },
  watch: {
    "node.userRange": {
      deep: true,
      handler(val) {
        this.$refs.form.validateField("userRange");
      },
    },
  },
  data() {
    return {
      rules: {
        userRange: [
          {
            required: true,
            validator(rule, value, callback) {
              if (isEmpty(value.userIds) && isEmpty(value.nodeUserFields)) {
                callback(new Error("请选择通知人"));
              } else {
                callback();
              }
            },
          },
        ],
        msgNodeFields: [
          {
            validator(rule, value, callback) {
              if (value) {
                if (
                  value.some((item) => {
                    return isEmpty(item.name);
                  })
                ) {
                  callback(new Error("请完善占位符与字段属性的映射关系"));
                  return;
                }
              }
              callback();
            },
            trigger: "change",
          },
        ],
      },
      componentsMap: {},

      visible: false,
      rootFlowParams: [],
      parentNodes: [],
    };
  },
  async created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
    let parentNodes = this.getParentNodes();
    let componentsMap = {};
    parentNodes = parentNodes.filter((item) => item.dataSource != "node_data");

    for (let i = 0; i < parentNodes.length; i++) {
      let node = parentNodes[i];
      if (["developer_plugin", "trigger_webhook"].includes(node.type)) {
        if (node.type == "trigger_webhook") {
          let list = [];
          let arr = ["body", "header", "params"];
          arr.forEach((row) => {
            node[row].forEach((item) => {
              let obj = _.cloneDeep(item);
              obj.title = `${row}.${item.title}`;
              list.push(obj);
            });
          });
          componentsMap[node.key] = list;
        } else {
          componentsMap[node.key] = node.responseParams;
        }
      } else if (node.type == "subflow_collection") {
        if (!componentsMap[node.robotId]) {
          let res = await getRobotWorkflow({ robotId: node.robotId });
          let flowParams = res.data.workflow?.rpaFlow?.config?.flowParams ?? [];
          componentsMap[node.robotId] = flowParams;
        }
      } else if (node.type == "json_parser") {
        if (!componentsMap[node.key]) {
          let list = buildJSONParserComponents(node);
          componentsMap[node.key] = list;
        }
      } else if (
        node.dataSource == "node_data_field" &&
        isEmpty(node.nodeField.collection) &&
        isEmpty(node.nodeField.referenceCollection)
      ) {
        let _node = parentNodes.find(
          (item) => item.key == node.nodeField.nodeKey,
        );
        if (_node && _node.type == "json_parse") {
          if (!componentsMap[node.nodeField.nodeKey]) {
            componentsMap[node.nodeField.nodeKey] =
              buildJSONParserComponents(_node);
          }
        }
      } else {
        let formId = node.formId || node.collection;
        if (node.dataSource == "node_data_field") {
          formId =
            node.nodeField.referenceCollection || node.nodeField.collection;
        }

        if (formId) {
          if (!componentsMap[formId]) {
            let res = await getFormDetail(formId);
            let list = getFormComponents(res.data.form.component.components);
            if (
              !(
                node.dataSource == "node_data_field" &&
                isEmpty(node.nodeField.referenceCollection)
              )
            ) {
              list = list.filter(
                (item) =>
                  item.form &&
                  !["json_form", "text_area", "reference_data"].includes(
                    item.componentName,
                  ),
              );
            }

            componentsMap[formId] = list;
          }
        }
      }
    }

    if (this.rootFlowParams?.length) {
      parentNodes.unshift({
        type: "root",
        key: "root",
        title: "本流程参数",
      });
      componentsMap["root"] = this.rootFlowParams;
    }

    this.parentNodes = parentNodes;

    this.$set(this, "componentsMap", componentsMap);
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.node.msgNodeFields && this.node.msgNodeFields.length) {
          let isError = false;
          this.node.msgNodeFields.forEach((item) => {
            if (
              isEmpty(item.nodeKey) ||
              isEmpty(item.name) ||
              isEmpty(item.smsTemplateFieldName)
            ) {
              isError = true;
            }
          });
          if (isError) {
            this.$message.error("请完善短信模板字段的映射关系");
            return;
          }
        }

        callback(valid);
      });
    },
    changeMsgNodeFields(list) {
      this.$set(this.node, "msgNodeFields", list);
    },
    changeNode(model, val) {
      this.$set(model, "name", "");
    },
    changeField(model, val) {
      let node = this.parentNodes.find((item) => item.key == model.nodeKey);
      let formId = node.formId || node.collection;
      if (node.dataSource == "node_data_field") {
        formId =
          node.nodeField.referenceCollection || node.nodeField.collection;
      }
      let list = this.componentsMap[formId];
      if (
        node.dataSource == "node_data_field" &&
        isEmpty(node.nodeField.referenceCollection)
      ) {
        let component = list.find((item) => item.name == node.nodeField.name);
        if (component) {
          list = component.components.filter(
            (item) =>
              item.form &&
              !["json_form", "text_area", "reference_data"].includes(
                item.componentName,
              ),
          );
        } else {
          list = [];
        }
      }
      if (list) {
        let field = list.find((item) => item.name == val);
        if (field) {
          this.$set(model, "componentName", field.componentName);
          this.$set(model, "fieldTitle", field.title);
        }
      }
    },
    getFieldList(nodeKey) {
      if (nodeKey) {
        let node = this.parentNodes.find((item) => item.key == nodeKey);
        if (node) {
          if (["developer_plugin", "trigger_webhook"].includes(node.type)) {
            if (this.componentsMap[node.key]) {
              return this.componentsMap[node.key];
            }
          } else if (node.type == "subflow_collection") {
            return this.componentsMap[node.robotId];
          } else if (node.type == "root") {
            return this.componentsMap["root"];
          } else if (node.type == "json_parser") {
            return this.componentsMap[node.key].filter(
              (item) => item.componentName != "json_form",
            );
          } else if (
            node.dataSource == "node_data_field" &&
            isEmpty(node.nodeField.collection) &&
            isEmpty(node.nodeField.referenceCollection)
          ) {
            let list = this.componentsMap[node.nodeField.nodeKey];
            if (list) {
              let component = list.find(
                (item) => item.name == node.nodeField.name,
              );
              if (component) {
                return component.components;
              }
            }
            return [];
          } else {
            let formId = node.formId || node.collection;
            if (node.dataSource == "node_data_field") {
              formId =
                node.nodeField.referenceCollection || node.nodeField.collection;
            }
            let list = this.componentsMap[formId];
            if (list) {
              if (
                node.dataSource == "node_data_field" &&
                isEmpty(node.nodeField.referenceCollection)
              ) {
                let component = list.find(
                  (item) => item.name == node.nodeField.name,
                );

                if (component) {
                  list = component.components.filter(
                    (item) =>
                      item.form &&
                      !["json_form", "text_area", "reference_data"].includes(
                        item.componentName,
                      ),
                  );
                } else {
                  list = [];
                }
              }
              return list;
            }
          }
        }
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.sms-row {
  display: flex;
}
</style>
