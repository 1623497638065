import request from "@/utils/request";

import { getFormModel } from "@/utils/tools";

import qs from "qs";
import { isEmpty } from "@zgg-core-utils/utils";

/**
 * 添加联系人
 * @param data
 * @returns {AxiosPromise}
 */
export function addContact(params) {
  return request({
    url: "/contact/save",
    method: "post",
    params: getFormModel(params, "contact"),
  });
}

/**
 * 获取联系人列表
 * @returns {AxiosPromise}
 */
export function contactList() {
  return request({
    url: "/contact/listAll",
    method: "get",
  });
}
/**
 * 获取带分页带搜索的联系人列表
 * @returns {AxiosPromise}
 */
export function contactListQuery(data) {
  return request({
    url: "/contact/listPage",
    method: "post",
    data: qs.stringify(data),
  });
}

/**
 * 删除联系人
 * @returns {AxiosPromise}
 */
export function deleteContact(contactId) {
  return request({
    url: "/contact/delete",
    method: "post",
    params: { contactId },
  });
}

/**
 * 获取省市区树
 * @returns
 */
export function getAreaTree() {
  let areaTree = localStorage.getItem("AREA_TREE");
  if (!isEmpty(areaTree)) {
    return new Promise((resolve) => {
      try {
        areaTree = JSON.parse(areaTree);
      } catch (e) {
        areaTree = {};
      }
      resolve(areaTree);
    });
  } else {
    return request({
      url: "/area/fullTree",
      method: "post",
    });
  }
}
/**
 * 获取联系人分页列表
 * @param data
 * @returns {AxiosPromise}
 */
export function contactListPage(data) {
  return request({
    url: "/contact/listPage",
    method: "post",
    data: qs.stringify(data),
  });
}
