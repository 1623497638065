import store from "@/store";

//运营中心权限列表(对照使用)
const authMap = {
  ops_auth_all: 1000, //"所有权限",
  ops_auth_form_cmp_reference_data: 1010, //"关联数据字段",
  ops_auth_form_cmp_reference_data_list: 1011, //"关联查询字段",
  ops_auth_form_cmp_form_data: 1012, //"关联多条字段",
  ops_auth_form_cmp_summary_data: 1013, //"汇总组件",
  ops_auth_form_cmp_button: 1014, //"按钮组件",
  ops_auth_form_front_event: 1015, //"前端事件",
  ops_auth_form_recycle: 1016, //"字段回收站",
  ops_auth_menu_aggregation: 1110, //"聚合表",
  ops_auth_menu_robot: 1111, //"智能助手",
  ops_auth_menu_robot_v2: 1112, //"智能助手2.0",
  ops_auth_menu_open: 1113, //"开发平台",
  ops_auth_menu_data_factory: 1114, //"数据工厂",
  ops_auth_menu_company_config: 1115, //"单位配置",
  ops_auth_menu_res_recycle: 1116, //"资源回收站",
  ops_auth_menu_workflow_data_delete: 2010, //"流程数据删除",
};
/**
 * 判断运营权限
 * @param
 * @returns {boolean}
 */
export function hasRoleAuth(val) {
  let authItems = store.state.authItems;
  if (authItems) {
    let value = authMap[val];
    if (authItems.includes(1000)) return true;
    return authItems.includes(value);
  }
  return true;
}
