<template>
  <div>
    <el-dropdown class="ui-add-fliter" @command="createFilter">
      <span class="el-dropdown-link">
        <i class="el-icon-plus"></i> 添加筛选条件<i
          class="el-icon-arrow-down el-icon--right"
        ></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="single">添加筛选条件</el-dropdown-item>
        <el-dropdown-item v-if="!isMaxGroup" command="group"
          >添加筛选条件组</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  props: {
    createFilter: Function,
    level: Number,
  },
  computed: {
    isMaxGroup() {
      return this.level >= 3;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  height: max-content !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>