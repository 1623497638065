<template>
  <div class="container">
    <div class="content">
      <div class="header flex-end">
        <el-button
          :loading="saveLoading"
          @click="saveForm"
          type="primary"
          size="mini"
          class="mr"
          >保存
        </el-button>
        <el-button @click="back" type="primary" size="mini" class="mr"
          >关闭
        </el-button>
      </div>
      <div class="form-body">
        <el-table v-if="isShowTabel" :data="tableData" style="width: 100%">
          <el-table-column
            v-for="item in view.groupFields"
            :key="item.key"
            :label="item.title"
            width="180"
          >
            <template slot-scope="{ row }">
              {{ getColValue(row, item) }}</template
            >
          </el-table-column>
          <el-table-column
            v-for="item in view.relyFields"
            :key="item.key"
            :label="item.title"
            :prop="item.name"
            width="180"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="attribute-box">
      <div class="scroll">
        <div class="row">
          <div class="label">数据来源</div>
          <div class="flex">
            <aggregate-source
              :unionCollections.sync="view.unionCollections"
              :relations.sync="view.relations"
              :formComponents="formComponents"
              @clearData="clearData"
              @getComponents="getComponents"
            ></aggregate-source>
          </div>
        </div>
        <el-divider class="mg-1"></el-divider>
        <union-query-filter
          class="row"
          :collections="view.unionCollections"
          :formComponents="componentMap"
          :areaTree="areaTree"
          v-model="view.advanceQuery"
        ></union-query-filter>
        <el-divider class="mg-1"></el-divider>
        <div class="row">
          <div class="weui between">
            <div class="label">行表头</div>
            <el-popover
              width="320"
              placement="bottom-start"
              v-model="fieldVisible"
            >
              <div
                v-clickoutside="closeField"
                style="max-height: 320px; overflow: auto"
              >
                <template
                  v-if="
                    !(view.unionCollections && view.unionCollections.length > 1)
                  "
                >
                  <div
                    class="field-item"
                    v-for="item in componentList"
                    :key="item.name"
                    v-text="item.title"
                    :title="item.title"
                    :class="isCheck(item.name) ? 'check' : ''"
                    @click="checkComponent(item)"
                  ></div>
                </template>
                <template v-else>
                  <div
                    class="field-item"
                    v-for="(item, index) in view.relations"
                    :key="index"
                    v-text="item.map((opt) => opt.fieldTitle).join('-')"
                    :title="item.map((opt) => opt.fieldTitle).join('-')"
                    :class="isCheck(item[0].fieldName) ? 'check' : ''"
                    @click="checkRelationField(item)"
                  ></div>
                </template>
              </div>
              <div slot="reference"><i class="el-icon-plus"></i></div>
            </el-popover>
          </div>
          <div class="flex">
            <div
              v-for="(item, index) in view.groupFields"
              :key="item.key"
              class="group-field"
            >
              <div class="field-title">
                <span v-if="isCanSearch(item.key)" style="font-size: 12px"
                  >(可查询)</span
                >
                <span
                  class="field-text"
                  v-text="item.title"
                  :title="item.title"
                ></span>
              </div>
              <div class="field-action rely-btns">
                <el-popover
                  placement="bottom-end"
                  width="320"
                  v-model="item.visible"
                  @show="fieldTitle = item.title"
                  @hide="closeFieldTitle(item)"
                >
                  <div>
                    <div class="field-input">
                      <div style="font-size: 12px; margin-right: 10px">
                        显示名
                      </div>
                      <el-input
                        v-model="fieldTitle"
                        placeholder="请输入显示名"
                        size="mini"
                        class="field-flex"
                      ></el-input>
                    </div>
                    <div class="flex-btns">
                      <el-button
                        @click="item.visible = false"
                        size="mini"
                        style="margin-right: 10px"
                        >取消</el-button
                      >
                      <el-button
                        @click="saveTitle(item)"
                        size="mini"
                        type="primary"
                        >确定</el-button
                      >
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-edit"></i>
                </el-popover>
                <i
                  @click="showQueryVisible(item, 'field')"
                  class="el-icon-setting"
                ></i>
                <i @click="deleteField(index)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </div>
        <el-divider class="mg-1"></el-divider>
        <div class="row">
          <div class="weui between">
            <div class="label">指标</div>
            <div @click="showRely"><i class="el-icon-plus"></i></div>
          </div>
          <div class="flex">
            <div
              class="group-field"
              v-for="(item, index) in view.relyFields"
              :key="item.key"
            >
              <div class="field-title">
                <span v-if="isCanSearch(item.key)" style="font-size: 12px"
                  >(可查询)</span
                >
                <span
                  class="field-text"
                  v-text="item.title"
                  :title="item.title"
                ></span>
              </div>
              <div class="field-action rely-btns">
                <i @click="editRely(item, index)" class="el-icon-edit"></i>
                <i
                  @click="showQueryVisible(item, 'rely')"
                  class="el-icon-setting"
                ></i>
                <i @click="deleteRely(index)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </div>
        <el-divider class="mg-1"></el-divider>
        <div class="row">
          <div class="label">数据提交校验</div>
          <!-- formCommitValidates -->
          <div>
            <div
              class="group-field"
              v-for="(item, index) in view.formCommitValidates"
              :key="index"
            >
              <div class="field-title">
                <span
                  v-if="isDeleteRely(item)"
                  class="field-text"
                  style="color: #e64340"
                  >存在已删除字段，请及时修改</span
                >
                <span
                  v-else
                  class="field-text"
                  v-text="getItemTitle(item)"
                ></span>
              </div>
              <div class="field-action rely-btns">
                <i @click="editValidate(index)" class="el-icon-edit"></i>
                <i @click="deleteValidate(index)" class="el-icon-delete"></i>
              </div>
            </div>
          </div>
          <div>
            <el-button @click="showValidate" size="small" style="width: 100%"
              >添加校验条件</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <rely-dialog
      :title="relyObj.title"
      :dialogVisible.sync="relyVisible"
      :formComponents="componentMap"
      :originComponents="relyComponents"
      :relyType="relyType"
      :visibleFun="false"
      :isJsonForm="false"
      jsonFormName=""
      :errMessage="relyObj.rely && relyObj.rely.errMessage"
      :originalFormula="relyObj.rely && relyObj.rely.originalFormula"
      @save="saveRely"
    ></rely-dialog> -->
    <aggregate-formula
      :title="relyObj.title"
      :isJsonForm="false"
      :fetchMetaFieldComponentList="fetchMetaFieldComponentList"
      @save="saveRely"
      @closed="closed"
      ref="relyDialog"
    >
      <template slot="header">
        <div
          v-if="relyType == 'unionRely'"
          class="weui"
          style="margin-bottom: 10px"
        >
          <el-input
            size="small"
            class="aggr-input"
            v-model="inputTitle"
            style="flex: 1; margin-right: 10px"
          ></el-input
          >=
        </div>
        <div v-else class="weui" style="margin-bottom: 10px">
          <div style="margin-right: 10px">不满足校验条件时提示文字</div>
          <el-input
            v-model="inputTitle"
            placeholder=""
            style="flex: 1"
            size="small"
          ></el-input>
        </div>
      </template>
      <template slot="footer">
        <div
          v-if="relyType == 'unionRely'"
          style="font-size: 12px; line-height: 1.5"
        >
          <div>请从左侧面板选择包含的字段名</div>
          <div>
            公式编辑样式举例：<span class="cm-span cm-field">进货数量</span
            >-<span class="cm-span cm-field">出货数量</span>
          </div>
          <div>只能支持加减法</div>
        </div>
      </template>
    </aggregate-formula>
    <el-dialog title="字段查询设置" :visible.sync="settingVisible" width="600">
      <div style="padding: 0px 20px">
        <el-form
          ref="form"
          :model="form"
          label-position="left"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item label="是否可查询">
            <el-switch
              size="small"
              v-model="form.enable"
              :active-value="true"
              :inactive-value="false"
            ></el-switch>
          </el-form-item>
          <template v-if="form.enable">
            <el-form-item label="标题" prop="title">
              <el-input
                size="small"
                v-model="form.title"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="占位内容">
              <el-input
                size="small"
                v-model="form.placeholder"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="hasDefault(form.componentName)" label="默认值">
              <el-date-picker
                v-if="form.componentName == 'date_picker'"
                v-model="form.defaultValue"
                :type="form.picker == 'date_minute' ? 'datetime' : form.picker"
                :format="getValueFormat(form.picker)"
                size="small"
                style="width: 300px"
              ></el-date-picker>

              <el-cascader
                v-else-if="form.componentName == 'address_input'"
                :options="areaList(form.picker)"
                placeholder="请选择默认值"
                size="mini"
                :props="areaTreeProp"
                v-model="form.defaultValue"
                clearable
                style="width: 300px"
              >
              </el-cascader>

              <el-input
                v-else
                v-model="form.defaultValue"
                placeholder="请输入默认值"
                size="small"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="form.componentName != 'address_input'"
              label="查询操作符"
              prop="queryOper"
            >
              <el-select
                size="small"
                v-model="form.queryOper"
                style="width: 300px"
              >
                <el-option
                  v-for="item in getQuerOper(form.componentName)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer">
        <el-button size="small" @click="settingVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="saveSearchForm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import aggregateSource from "@/views/lowCode/view/components/aggregateSource";
import {
  aggregateDetail,
  aggregateTable,
  getViewDetail,
  saveViewComponent,
} from "./api";
import Clickoutside from "element-ui/src/utils/clickoutside";
import { isEmpty, _eq } from "@zgg-core-utils/relyUtil";
import unionQueryFilter from "./components/unionQueryFilter";

import { getAreaTree } from "@/api/contact";
import { getFormDetail } from "../form/api";
import { getComponentQueryOper } from "@zgg-core-utils/whiteList";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { fetchMetaFieldComponentList } from "@/api/form";
import { dealWithFormValue } from "@/views/lowCode/form/utils";
export default {
  directives: { Clickoutside },
  components: { aggregateSource, unionQueryFilter },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "标题不能为空" }],
        queryOper: [{ required: true, message: "请选择查询操作符" }],
      },
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
      view: {},
      fieldVisible: false,
      sourceFormId: "",
      fieldTitle: "",
      relyVisible: false,
      relyIndex: -1,
      relyObj: {
        title: "公式",
        rely: { fields: [], formula: "", originalFormula: [] },
      },
      saveLoading: false,
      settingVisible: false,
      form: { enable: false },
      areaTree: [],
      formComponents: {},
      relyType: "unionRely",
      tableData: [],
      treeNodeList: [],
      inputTitle: "",
    };
  },
  computed: {
    isShowTabel() {
      return this.view.groupFields && this.view.groupFields.length;
    },
    componentMap() {
      let map = {};
      if (this.view.unionCollections) {
        this.view.unionCollections.forEach((item) => {
          if (this.formComponents[item.collection]) {
            let obj = this._.cloneDeep(this.formComponents[item.collection]);
            // obj.components = obj.components.filter(
            //   (item) => item.componentName != "json_form"
            // );
            map[item.collection] = obj;
          }
        });
      }
      return map;
    },
    componentList() {
      if (
        this.view.unionCollections &&
        this.view.unionCollections.length == 1
      ) {
        let collectioin = this.view.unionCollections[0].collection;
        if (this.formComponents[collectioin]) {
          let list = []
          this.formComponents[collectioin].components.filter((item) =>
            checkFieldAbility(item, "inAggregateHeader")
          ).forEach(item => {
            if (item.componentName == "json_form") {
              let components = item.components.filter(c => checkFieldAbility(c, "inAggregateHeader"))
              components.forEach(child => {
                let obj = this._.cloneDeep(child);
                obj.title = item.title + "." + child.title;
                obj.name = item.name + "." + child.name;
                list.push(obj);
              });
            } else {
              list.push(this._.cloneDeep(item));
            }
          });
          return list
        }
      }
      return [];
    },
    relyComponents() {
      if (isEmpty(this.view.relyFields)) {
        return [];
      }
      let list = [];
      this.view.relyFields.forEach((item) => {
        list.push({
          componentName: item.component.componentName,
          key: item.key,
          name: item.name,
          title: item.title,
          array: false,
          json: false,
          form: true,
        });
      });
      return list;
    },
    tempView() {
      return Object.assign({}, this._.cloneDeep(this.view));
    },
  },
  watch: {
    tempView: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (isEmpty(val)) {
          this.tableData = [];

          return;
        }
        if (!this.tempView.unionCollections.length) {
          // this.$message.error("请选择数据源");
          this.tableData = [];

          return;
        }
        if (!this.tempView.groupFields.length) {
          // this.$message.error("请添加行标头");
          this.tableData = [];

          return;
        }
        if (!this.tempView.relyFields.length) {
          // this.$message.error("请添加指标");
          this.tableData = [];

          return;
        }
        if (_eq(val, oldVal)) {
          return;
        }
        let deleteFieldValidate = false;
        if (this.tempView.formCommitValidates) {
          this.tempView.formCommitValidates.forEach((item) => {
            if (this.isDeleteRely(item)) {
              deleteFieldValidate = true;
            }
          });
        }
        if (deleteFieldValidate) {
          // this.$message.error("聚合校验公式配置不正确");

          return;
        }

        aggregateTable({
          component: val,
        }).then((res) => {
          this.tableData = res.data.page.list;
          console.log(res);
        });
      },
    },
  },
  async created() {
    console.log("crated");
    this.getAreaTree();
    let viewId = this.$route.query.id;
    if (this.viewId) {
      viewId = this.viewId;
    }

    let res = await getViewDetail({ viewId: viewId });
    if (res.code) {
      this.$message.error(res.msg);
    } else {
      let view = res.data.view.component;
      if (!view.advanceQuery) {
        view.advanceQuery = [];
      }
      if (!view.unionCollections) {
        view.unionCollections = [];
      }
      if (!view.groupFields) {
        view.groupFields = [];
      }
      if (!view.relyFields) {
        view.relyFields = [];
      }
      if (!view.relations) {
        view.relations = [];
      }
      if (!view.searchFormView) {
        view.searchFormView = {
          componentName: "form",
          components: [],
        };
      }
      view.unionCollections.forEach((item) => {
        this.getComponents(item.collection);
      });
      this.$set(this, "view", view);
    }
  },
  methods: {
    fetchMetaFieldComponentList,
    closed() {
      this.inputTitle = "";
    },
    getColValue(row, col) {
      let field = this._.cloneDeep(col.component);
      field.key = col.key;
      field.name = col.key;
      return dealWithFormValue(row, field);
    },
    isDeleteRely(item) {
      let deleteField = false;
      item.originalFormula.forEach((rows) => {
        rows.forEach((col) => {
          if (col.type == "field") {
            let relyField = this.view.relyFields.find(
              (node) => node.name == col.name
            );
            if (!relyField) {
              deleteField = true;
            }
          }
        });
      });
      return deleteField;
    },
    getItemTitle(item) {
      let text = "";
      item.originalFormula.forEach((rows) => {
        rows.forEach((col) => {
          if (col.type == "field") {
            let relyField = this.view.relyFields.find(
              (node) => node.name == col.name
            );
            if (relyField) {
              text += relyField.title;
            } else {
              text += col.value;
            }
          } else {
            text += col.value;
          }
        });
      });
      return text;
    },
    isCanSearch(key) {
      return (
        this.view.searchFormView.components.findIndex(
          (item) => item.name == key
        ) >= 0
      );
    },
    saveSearchForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let index = this.view.searchFormView.components.findIndex(
          (item) => item.name == this.form.key
        );
        let obj = this._.cloneDeep(this.form);
        if (obj.enable) {
          delete obj.enable;
          if (index >= 0) {
            this.$set(this.view.searchFormView, index, obj);
          } else {
            this.view.searchFormView.components.push(obj);
          }
        } else {
          if (index >= 0) {
            this.view.searchFormView.components.splice(index, 1);
          }
        }
        this.settingVisible = false;
      });
    },
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
    areaList(picker) {
      let list = [];
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    getValueFormat(type) {
      if (type == "year") {
        return "yyyy";
      } else if (type == "month") {
        return "yyyy-MM";
      } else if (type == "date") {
        return "yyyy-MM-dd";
      } else if (type == "date_minute") {
        return "yyyy-MM-dd HH:mm";
      } else if (type == "datetime") {
        return "yyyy-MM-dd HH:mm:ss";
      } else {
        return "yyyy-MM-dd HH:mm:ss";
      }
    },
    getComponents(formId) {
      if (this.formComponents[formId]) {
        return;
      }
      getFormDetail(formId).then((res) => {
        let buildComponents = (componentList) => {
          let list = [];
          componentList.forEach((item) => {
            if (item.form) {
              list.push(this._.cloneDeep(item));
            } else if (item.container) {
              list = list.concat(buildComponents(item.components));
            }
          });
          return list;
        };
        let componentList = buildComponents(res.data.form.component.components);
        this.$set(this.formComponents, formId, {
          components: componentList,
          title: res.data.form.title,
          id: formId,
        });
      });
    },
    getAreaTree() {
      getAreaTree().then((res) => {
        let areaTree = [];
        let buildTree = (soruce) => {
          let obj = {};
          for (const [key, value] of Object.entries(soruce)) {
            if (key != "children") {
              obj[key] = value;
            }
          }
          if (soruce.children && soruce.children.length) {
            obj.children = [];
            soruce.children.forEach((item) => {
              let target = buildTree(item);
              obj.children.push(target);
            });
          }
          return obj;
        };
        res.data.tree.forEach((item) => {
          let target = buildTree(item);
          areaTree.push(target);
        });
        this.areaTree = areaTree;
      });
    },
    showQueryVisible(item, type) {
      let form;
      if (this.view.searchFormView && this.view.searchFormView.components) {
        form = this.view.searchFormView.components.find(
          (opt) => opt.name == item.key
        );
        if (form) {
          form.enable = true;
        }
      }
      if (!form) {
        form = {};

        if (type == "field") {
          let collection = this.view.unionCollections[0].collection;
          let list = this.formComponents[collection].components;

          if (item.name.indexOf(".") > 0) {
            let parentName = item.name.split(".")[0];
            let name = item.name.split(".")[1];
            let jsonForm = list.find((opt) => opt.name == parentName);
            if (jsonForm) {
              let obj = jsonForm.components.find((child) => child.name == name);
              if (obj) {
                form = {
                  ...this._.cloneDeep(obj),
                };
              }
            }
          } else {
            let obj = list.find((opt) => opt.name == item.name);
            if (obj) {
              form = {
                ...this._.cloneDeep(obj),
              };
            }
          }
        } else {
          form.componentName = "input_number";
        }
        form.enable = false;
        form.defaultValue = "";
        form.queryOper = "eq";
        form.name = item.key;
        form.key = this.getUuid();
        form.title = item.title;
      }

      this.$set(this, "form", this._.cloneDeep(form));
      this.settingVisible = true;
    },
    getQuerOper: getComponentQueryOper,
    hasDefault(componentName) {
      return ![
        "image_uploader",
        "attachment_uploader",
        "sign_input",
        "reference_data",
        "user_select",
        "user_list_select",
        "department_select",
        "department_list_select",
        "position_input",
      ].includes(componentName);
    },
    saveForm() {
      if (!this.view.unionCollections.length) {
        this.$message.error("请选择数据源");
        return;
      }
      if (!this.view.groupFields.length) {
        this.$message.error("请添加行标头");
        return;
      }
      if (!this.view.relyFields.length) {
        this.$message.error("请添加指标");
        return;
      }
      let deleteFieldValidate = false;
      if (this.view.formCommitValidates) {
        this.view.formCommitValidates.forEach((item) => {
          if (this.isDeleteRely(item)) {
            deleteFieldValidate = true;
          }
        });
      }
      if (deleteFieldValidate) {
        this.$message.error("聚合校验公式配置不正确");
        return;
      }
      let viewId = this.$route.query.id;
      let view = this._.cloneDeep(this.view);
      view.advanceQuery.forEach((item) => {
        delete item.visible;
        delete item.show;
      });
      view.advanceQuery.forEach((item) => {
        delete item.visible;
        delete item.show;
      });
      this.saveLoading = true;
      saveViewComponent({
        viewId,
        component: view,
      })
        .then((res) => {
          this.$message.success("视图保存成功");
          this.saveLoading = false;
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    back() {
      let { matched } = this.$router.history.current;
      if (matched.length >= 2) {
        let parentPath = matched[matched.length - 2].path;
        this.$router.push({ path: parentPath });
      } else {
        this.$router.back();
      }
    },
    isCheck(name) {
      return this.view.groupFields.findIndex((item) => item.name == name) >= 0;
    },
    showRely() {
      this.relyType = "unionRely";
      if (this.view.unionCollections && this.view.unionCollections.length) {
        this.relyIndex = -1;
        this.$set(this, "relyObj", {
          title: "公式",
          rely: { fields: [], formula: "", originalFormula: [] },
        });
        // this.relyVisible = true;

        let treeNodeList = Object.values(this.componentMap);
        this.inputTitle = "公式";
        // relyObj.rely.originalFormula
        this.$refs.relyDialog.open(treeNodeList, [], "unionRely");
      } else {
        this.$message.error("请选择数据源");
      }
    },
    editRely(item, index) {
      this.relyType = "unionRely";
      this.relyObj = item;
      this.relyIndex = index;
      // this.relyVisible = true;
      this.inputTitle = item.title;
      let originalFormula = this._.cloneDeep(item.rely.originalFormula);
      if (isEmpty(originalFormula)) {
        originalFormula = [];
      }
      let treeNodeList = Object.values(this.componentMap);
      this.$refs.relyDialog.open(treeNodeList, originalFormula, "unionRely");
    },
    deleteRely(index) {
      let field = this.view.relyFields.splice(index, 1);
      let sIndex = this.view.searchFormView.components.findIndex(
        (item) => item.name == field.key
      );
      if (sIndex >= 0) {
        this.view.searchFormView.components.splice(sIndex, 1);
      }
    },
    showValidate() {
      if (this.view.relyFields.length == 0) {
        this.$message.error("请先配指标");
        return;
      }
      this.relyType = "formValidate";
      this.relyIndex = -1;
      this.$set(this, "relyObj", {
        title: "数据校验条件",
        rely: { fields: [], formula: "", originalFormula: [], errMessage: "" },
      });
      // this.relyVisible = true;

      this.$refs.relyDialog.open(this.relyComponents, [], "formValidate");
    },
    deleteValidate(index) {
      this.$delete(this.view.formCommitValidates, index);
    },
    editValidate(index) {
      let item = this.view.formCommitValidates[index];
      this.relyType = "formValidate";
      this.relyIndex = index;
      this.$set(this, "relyObj", {
        title: "数据校验条件",
        rely: {
          fields: [],
          formula: "",
          originalFormula: this._.cloneDeep(item.originalFormula),
          errMessage: item.errMessage,
        },
      });
      this.inputTitle = item.errMessage;
      // this.relyVisible = true;

      this.$refs.relyDialog.open(
        this.relyComponents,
        this._.cloneDeep(item.originalFormula),
        "formValidate"
      );
    },

    saveRely(originalFormula) {
      if (this.relyType == "unionRely") {
        this.saveMetrics(originalFormula);
      } else if (this.relyType == "formValidate") {
        this.saveValidate(originalFormula);
      }
    },
    saveMetrics(originalFormula) {
      let title = this.inputTitle;
      if (isEmpty(title)) {
        this.$message.error("请输入标题");
        return;
      }
      let key = this.getUuid();
      let fields = [];
      let formula = "";
      originalFormula.forEach((rows) => {
        rows.forEach((col) => {
          if (col.type == "field") {
            fields.push(col.name);
            formula += `'$${col.name}'`;
          } else {
            formula += col.value;
          }
        });
      });
      let relyTableFields = [];

      originalFormula.forEach((row) => {
        row
          .filter((item) => item.type == "field")
          .forEach((item) => {
            relyTableFields.push({
              collection: item.collection,
              fieldName: item.name,
            });
          });
      });

      if (this.relyIndex == -1) {
        let obj = {
          key: key, //前端生成
          fieldType: "relyField", //表明这个是公式字段
          component: {
            componentName: "input_number",
          },
          name: key,
          title: title,
          rely: {
            fields: fields,
            formula: formula,
            originalFormula,
          },
          relyTableFields,
        };
        this.view.relyFields.push(obj);
      } else {
        this.relyObj.title = title;
        this.relyObj.rely = {
          fields: fields,
          formula: formula,
          originalFormula,
        };
        this.relyObj.relyTableFields = relyTableFields;
        this.$set(
          this.view.relyFields,
          this.relyIndex,
          this._.cloneDeep(this.relyObj)
        );
      }
      this.$refs.relyDialog.close();
    },
    saveValidate(originalFormula) {
      let errorMsg = this.inputTitle;
      if (isEmpty(errorMsg)) {
        this.$message.error("请输入错误提示信息");
        return;
      }
      let formula = "";
      let collectionList = [];
      originalFormula.forEach((rows) => {
        rows.forEach((col) => {
          if (col.type == "field") {
            let relyField = this.view.relyFields.find(
              (node) => node.name == col.name
            );
            relyField.relyTableFields.forEach((item) => {
              if (!collectionList.includes(item.collection)) {
                collectionList.push(item.collection);
              }
            });
            formula += `'$${col.name}'`;
          } else {
            formula += col.value;
          }
        });
      });

      let obj = {
        formula,
        collectionList,
        originalFormula,
        errMessage: errorMsg,
      };

      if (this.relyIndex == -1) {
        if (!this.view.formCommitValidates) {
          this.$set(this.view, "formCommitValidates", []);
        }
        this.view.formCommitValidates.push(obj);
      } else {
        this.$set(this.view.formCommitValidates, this.relyIndex, obj);
      }
      this.$refs.relyDialog.close();
    },
    clearData() {
      this.view.groupFields = [];
      this.view.advanceQuery = [];
      this.view.relyFields = [];
      this.view.advanceQuery = [];

      this.view.searchFormView.components = [];
    },
    saveTitle(item) {
      if (isEmpty(this.fieldTitle)) {
        this.$message.error("请输入显示名");
        return;
      }
      this.$set(item, "title", this.fieldTitle);
      this.$set(item, "visible", false);
    },

    showFieldEdit(item) {
      this.$set(item, "visible", true);
    },
    deleteField(index) {
      let field = this.view.groupFields.splice(index, 1)[0];
      let sIndex = this.view.searchFormView.components.findIndex(
        (item) => item.name == field.key
      );
      if (sIndex >= 0) {
        this.view.searchFormView.components.splice(sIndex, 1);
      }
    },
    checkComponent(item) {
      if (this.isCheck(item.name)) {
        return;
      }
      let key = this.getUuid();
      let obj = {
        key,
        fieldType: "formField",
        name: item.name,
        title: item.title,
        visible: false,
        show: false,
        component: this._.cloneDeep(item),
      };
      this.view.groupFields.push(obj);
      // this.fieldVisible = false;
    },
    checkRelationField(item) {
      if (this.isCheck(item[0].fieldName)) {
        return;
      }
      let key = this.getUuid();

      let field;
      if (item[0].fieldName.indexOf(".") != -1) {
        let parentName = item[0].fieldName.split(".")[0];
        let name = item[0].fieldName.split(".")[1];
        let parent = this.formComponents[item[0].collection].components.find(
          (opt) => opt.name == parentName
        );
        field = parent.components.find((opt) => opt.name == name);
      } else {
        field = this.formComponents[item[0].collection].components.find(
          (opt) => opt.name == item[0].fieldName
        );
      }

      let obj = {
        key,
        fieldType: "formField",
        name: item[0].fieldName,
        title: item.map((opt) => opt.fieldTitle).join("-"),
        component: this._.cloneDeep(field),
      };
      this.view.groupFields.push(obj);
      // this.fieldVisible = false;
    },
    closeField() {
      this.fieldVisible = false;
    },

    closeFieldTitle(item) {
      this.$set(item, "show", false);
    },

    getCommponentList(componentList, parent) {
      let list = [];
      componentList.forEach((item) => {
        if (item.container) {
          list = list.concat(this.getCommponentList(item.components, item));
        } else if (item.form) {
          if (!parent) {
            list.push({ ...this._.cloneDeep(item) });
          }
        }
      });
      return list;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  height: calc(100vh - 50px);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.form-body {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 20px;
}
.scroll {
  flex: 1;
  overflow: auto;
  width: 100%;
}
.mr {
  margin-right: 10px;
}
.row {
  margin: 10px;
  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 700;
  }
}
.mg-1 {
  margin: 8px 10px;
}
.weui {
  display: flex;
  align-items: center;
}
.between {
  justify-content: space-between;
}
.field-item {
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #409eff, $alpha: 0.05);
  }
  &.check {
    cursor: not-allowed;
    color: #c3cdda;
    &:hover {
      background-color: transparent;
    }
  }
}
.group-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 30px;

  .field-title {
    width: 70%;
    margin-right: 20px;
    font-size: 12px;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .field-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
  }
  .rely-btns {
    display: none;
  }
  &:hover {
    .rely-btns {
      display: block;
    }
  }
}
.field-input {
  display: flex;
  align-items: center;
  .field-flex {
    flex: 1;
  }
}
.flex-btns {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-end;
}
.cm-span {
  background: #ebf5ff;
  color: #008dcd;
  padding: 2px 4px;
  margin: 0 3px;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
}
</style>
