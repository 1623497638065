<template>
  <div>
    <div>我是公式编辑器</div>
    <div style="width: 800px;">
      <formula ref="formula"></formula>
    </div>

    <div>
      <el-button type="primary" @click="insert">插入</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </div>
</template>
<script>
import formula from "@/components/formula/index";
import { isNumber } from "@/zgg-core/utils";
export default {
  components: { formula },
  methods: {
    insert() {
      this.$refs.formula.insert({
        value: "ABS",
        label: "ABS",
        type: "fun",
      });
    },
    save() {
      let { content } = this.$refs.formula.getContent();

      // let str = content.replace(/\n/g, "");
      const rex = /#fun{[a-zA-Z0-9_]+}/gm;
      let funList = content.match(rex);
      funList = [...new Set(funList)];

      // 替换字符串
      let strList = content.match(
        /(\"(.|\n)*?\")|(\'(.|\n)*?\')|(\`(.|\n)*?\`)/g
      );
      strList = new Array(...new Set(strList));
      let indexList = [];
      let str = content;
      strList.forEach((val, index) => {
        strList[index] = val.substring(1, val.length - 1);
        // let reg = new RegExp(val, "g");
        str = str.replaceAll(val, "$zgg_str_" + index + "$");
        indexList.push(`$zgg_str_${index}$`);
      });
      funList.forEach((val, index) => {
        let name = val.replaceAll("#fun{", "").replaceAll("}", "");
        funList[index] = name;
        let regex = new RegExp(val, "g");
        str = str.replace(regex, name);
      });

      // str = str.replace(/\n/g, "$zgg_br$");

      let splitArr = [
        "===",
        "!==",
        "==",
        "!=",
        ">=",
        "<=",
        ">>",
        "<<",
        ">",
        "<",
        "!",
        "&&",
        "||",
        "&",
        "|",
        "^",
        "+",
        "-",
        "*",
        "/",
        "%",
        "(",
        ")",
        ",",
        "=",
        "?",
        ":",
        "~",
        "[",
        "]",
        "{",
        "}",
        "\n",
      ];

      let operatorArr = [
        "===",
        "!==",
        "==",
        "!=",
        ">=",
        "<=",
        ">>",
        "<<",
        ">",
        "<",
        "!",
        "&&",
        "||",
        "&",
        "|",
        "^",
        "+",
        "-",
        "*",
        "/",
        "%",
        "=",
        "?",
        ":",
        "~",
      ];

      let regStr = "";

      splitArr.forEach((val) => {
        if (regStr != "") {
          regStr += "|";
        }
        if (val == "||") {
          regStr += "\\|\\|";
        } else if (
          ["(", ")", "+", "*", "|", "?", "^", "[", "]", "{", "}"].includes(val)
        ) {
          regStr += "\\" + val;
        } else {
          regStr += val;
        }
      });

      // 获取数据val 包括函数
      regStr = new RegExp(regStr, "g");
      let valArr = str.split(regStr).filter((val) => val != "");

      // ** 替换内容 后续内容如果包含前面内容可能会把前面内容替换，所以替换一次之后，字符串自动往后移动
      let strIndex;
      let startStr = "";
      let endStr = str;
      valArr.forEach((val, index) => {
        strIndex = endStr.indexOf(val);
        if (strIndex >= 0) {
          let start_str = endStr.substring(0, strIndex);
          let end_str = endStr.substring(strIndex);
          let newStr = "$zgg_val_" + index + "$";
          end_str = end_str.replace(val, newStr);
          endStr = start_str + end_str;
          startStr += endStr.substring(0, strIndex + newStr.length);
          endStr = endStr.substring(strIndex + newStr.length);
        }
      });
      str = startStr + endStr;

      // 替换分隔符
      splitArr.forEach((val, index) => {
        let reg = val;
        if (
          ["+", "*", "(", ")", "|", "^", "?", "[", "]", "{", "}"].includes(val)
        ) {
          reg = "\\" + val;
        } else if (val == "||") {
          reg = "\\|\\|";
        }

        reg = new RegExp(reg, "g");

        if (str.match(reg)) {
          str = str.replace(reg, "$zgg_split_" + index + "$");
          indexList.push(`$zgg_split_${index}$`);
        }
      });

      let tempList = str.match(
        /\$zgg_split_[0-9]+\$|\$zgg_str_[0-9]+\$|\$zgg_val_[0-9]+\$|\$zgg_field_[0-9]\$/g
      );

      let fieldReg = /\$zgg_field_[0-9]+\$/g;
      let strReg = /\$zgg_str_[0-9]+\$/g;
      let valReg = /\$zgg_val_[0-9]+\$/g;
      let splitReg = /\$zgg_split_[0-9]+\$/g;

      let tempTree = [];
      let fieldList = [];
      let tempData = [[]];

      tempList.forEach((val) => {
        let children = tempData[tempData.length - 1];
        let obj;
        if (val.match(valReg)) {
          let index = val.match(/[0-9]+/g)[0];
          let value = valArr[index];
          let componentName = "";
          if (value.match(fieldReg)) {
            //
            index = value.match(/[0-9]+/g)[0];

            value = fieldList[index].value;
            componentName = fieldList[index].componentName;
            if (value === null || value === undefined) {
              value = '""';
            }
          } else if (value.match(strReg)) {
            index = value.match(/[0-9]+/g)[0];
            value = strList[index];
            componentName = "input";
          } else if (isNumber(value)) {
            value = parseFloat(value);
            componentName = "input_number";
          }

          if (typeof value === "string") {
            obj = {
              type: funList.includes(value.trim()) ? "fun" : "val",
              value: value.trim(),
              componentName,
            };
          } else {
            obj = {
              type: "val",
              value: value,
              componentName,
            };
          }
        } else if (val.match(splitReg)) {
          let index = val.match(/[0-9]+/g)[0];

          let value = splitArr[index];
          if (value != "\n") {
            obj = {
              type: operatorArr.includes(value) ? "operator" : "split",
              value: value,
            };
          } else {
            tempData.push([]);
          }
        }
        if (obj) {
          tempTree.push(obj);
          children.push(obj);
        }
      });

      let tree = [];
      let stack = []; // 栈

      tempTree.forEach((item, index) => {
        let obj = {
          ...item,
        };
        if (!stack.length) {
          if (obj.type == "split") {
            if (["(", "[", "{"].includes(obj.value)) {
              let parent = {
                type: "rely",
                children: [],
              };
              tree.push(parent);
              stack.push(parent);
              parent.children.push(obj);
              let child = {
                type: "rely",
                children: [],
              };
              parent.children.push(child);
              stack.push(child);
            } else {
              tree.push(obj);
              let child = {
                type: "rely",
                children: [],
              };
              tree.push(child);
              stack.push(child);
            }
          } else {
            if (obj.type == "fun" || obj.type == "operator") {
              if (obj.value == "!") {
                let parent = {
                  type: "rely",
                  children: [],
                };
                tree.push(parent);
                stack.push(parent);
                parent.children.push(obj);
              } else {
                if (obj.type == "fun") {
                  obj.children = [];
                  stack.push(obj);
                }
                tree.push(obj);
              }
            } else {
              if (
                index > 0 &&
                [
                  "===",
                  "!==",
                  "==",
                  "!=",
                  ">=",
                  "<=",
                  ">",
                  "<",
                  "||",
                  "&&",
                  "?",
                  ":",
                ].includes(tempTree[index - 1].value)
              ) {
                let parent = {
                  type: "rely",
                  children: [],
                };
                tree.push(parent);
                stack.push(parent);
                parent.children.push(obj);
              } else {
                tree.push(obj);
              }
            }
          }
        } else {
          let elem = stack[stack.length - 1];

          if (
            elem &&
            elem.children &&
            elem.children.length &&
            elem.children[0].value == "!"
          ) {
            stack.pop();
          }
          if (["(", "[", "{"].includes(obj.value) && obj.type == "split") {
            let child = {
              type: "rely",
              children: [],
            };
            if (elem.type == "fun" && elem.children.length == 0) {
              elem.children.push(obj);

              elem.children.push(child);

              stack.push(child);
            } else {
              let parent = {
                type: "rely",
                children: [],
              };
              parent.children.push(obj);
              parent.children.push(child);
              elem.children.push(parent);
              stack.push(parent);
              stack.push(child);
            }
          } else if (
            [")", "]", "}"].includes(obj.value) &&
            obj.type == "split"
          ) {
            if (
              !(
                obj.value == ")" &&
                elem.type == "fun" &&
                elem.children.filter((child) => child.value == "(").length == 1
              )
            ) {
              stack.pop();
              elem = stack[stack.length - 1];
            }

            if (elem) {
              elem.children.push(obj);
              stack.pop();
            } else {
              tree.push(obj);
            }
          } else if (obj.type == "split") {
            if (["?", ":"].includes(obj.value)) {
              stack.pop();
              elem -= stack[stack.length - 1];
            } else {
              if (obj.value == "," && elem.type == "rely") {
                stack.pop();
                elem = stack[stack.length - 1];
              }
            }

            if (elem) {
              elem.children.push(obj);
            } else {
              tree.push(obj);
            }

            if (obj.value == "," && elem.type == "rely") {
              let child = {
                type: "rely",
                children: [],
              };
              if (elem) {
                elem.children.push(child);
                stack.push(child);
              }
            }
          } else if (obj.type == "fun") {
            obj.children = [];
            elem.children.push(obj);
            stack.push(obj);
          } else {
            elem.children.push(obj);
          }
        }

        if (index == tempTree.length - 1) {
          stack.pop();
        }
      });

      function rebuildTree(tree) {
        let list = [];
        let child;
        for (let index = 0; index < tree.length; index++) {
          let item = tree[index];
          if (item.type != "split") {
            if (item.children && item.children.length) {
              item.children = rebuildTree(item.children);
            }
            if (child) {
              child.children.push(_.cloneDeep(item));
            } else {
              list.push(_.cloneDeep(item));
            }
          } else {
            // 构建表达式节点
            if (
              !(index == 0 && ["(", "["].includes(item.value)) &&
              ["(", "[", ","].includes(item.value)
            ) {
              child = {
                type: "rely",
                children: [],
              };
              list.push(child);
            } else {
              child = undefined;
            }
          }
        }
        return list;
      }
      tree = rebuildTree(tree);
      console.log(tempTree, tempList);
      console.log(tree);
      console.log(tempData);
    },
  },
};
</script>