<template xmlns="http://www.w3.org/1999/html">
  <el-dialog
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    fullscreen
    @open="openDialog"
    @close="handleClose"
    :show-close="false"
  >
    <div slot="title" style="display: flex; flex-direction: row">
      <span style="flex: 1; display: flex; align-items: center">编辑按钮</span>
      <div>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </div>
    <div class="button-group-setting-content__wrap">
      <div class="button-group-images-container card-border">
        <draggable
          :list="buttonGroupGlobalConfig.buttonList"
          animation="300"
          handle=".drag-handle"
          class="drag-container"
        >
          <div
            v-for="(item, index) in buttonGroupGlobalConfig.buttonList"
            :key="item.key"
            class="button-group-images-container__item drag-handle"
            :class="{
              active: item.key == buttonGroupButtonConfig.key,
              up_down: buttonGroupGlobalConfig.groupStyle.layout == 'up_down',
              left_right:
                buttonGroupGlobalConfig.groupStyle.layout == 'left_right',
            }"
            @click="selectButton(item, index)"
          >
            <div class="button-group-images-container__item__img">
              <ButtonGroupButtonItem
                :item-config="item"
                :global-config="buttonGroupGlobalConfig"
              ></ButtonGroupButtonItem>
            </div>
            <div
              class="button-group-images-container__item__action"
              @click.stop
            >
              <el-popconfirm title="确定移除？" @confirm="deleteButton(index)">
                <i slot="reference" class="el-icon-delete"></i>
              </el-popconfirm>
            </div>
          </div>
          <template>
            <div
              class="button-group-images-container__item add-image"
              :class="{
                up_down: buttonGroupGlobalConfig.groupStyle.layout == 'up_down',
                left_right:
                  buttonGroupGlobalConfig.groupStyle.layout == 'left_right',
              }"
              @click="addButton"
            >
              <i class="el-icon-plus"></i>添加
            </div>
          </template>
        </draggable>
      </div>
      <div class="card-border button-group-images-setting__wrap">
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
          <el-tab-pane label="设置按钮" name="buttonSetting"></el-tab-pane>
          <el-tab-pane label="全局配置" name="globalSetting"></el-tab-pane>
        </el-tabs>
        <div style="padding: 0px 20px">
          <template v-if="activeName == 'buttonSetting'">
            <template v-if="buttonGroupButtonConfig">
              <el-form
                label-position="top"
                size="small"
                :rules="rules"
                :model="buttonGroupButtonConfig"
                ref="buttonGroupButtonConfigForm"
              >
                <el-form-item label="按钮名称">
                  <el-input
                    v-model="buttonGroupButtonConfig.title"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
                <el-form-item label="选择图标">
                  <el-popover
                    popper-class="button-icon-setting"
                    @hide="styleSelectHideEvent"
                  >
                    <style-select
                      :iconStyle="buttonGroupButtonConfig.buttonStyle"
                      @updateIcon="updateIcon"
                      v-if="showSelect"
                    ></style-select>
                    <button-group-button-item-setting
                      :iconStyle="buttonGroupButtonConfig.buttonStyle"
                      slot="reference"
                      @buttonClick="showSelect = true"
                    >
                    </button-group-button-item-setting>
                  </el-popover>
                </el-form-item>
                <el-form-item label="触发操作">
                  <el-select
                    style="width: 100%"
                    v-model="buttonGroupButtonConfig.buttonEvent"
                    placeholder="请选择"
                    @change="changeButtonEvent"
                  >
                    <el-option
                      v-for="item in actionTypeEnums"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <template v-if="isShowTargetEnum">
                  <el-form-item label="打开方式">
                    <el-select
                      style="width: 100%"
                      v-model="buttonGroupButtonConfig.eventConfig.targetEnum"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in targetEnums"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>

                <template
                  v-if="buttonGroupButtonConfig.buttonEvent == 'recordEvent'"
                >
                  <el-form-item
                    label="工作群"
                    prop="eventConfig.recordId"
                    required
                  >
                    <el-popover
                      popper-class="button-icon-setting"
                      placement="bottom-start"
                      v-model="appViewSelectVisible"
                    >
                      <app-view-select
                        :tree-data="workDirTreeData"
                        @select="appViewSelect"
                      >
                      </app-view-select>
                      <el-input
                        v-model="buttonGroupButtonConfig.eventConfig.title"
                        readonly
                        slot="reference"
                        suffix-icon="el-icon-arrow-down"
                      >
                      </el-input>
                    </el-popover>
                  </el-form-item>
                </template>
                <template
                  v-if="buttonGroupButtonConfig.buttonEvent == 'hrefEvent'"
                >
                  <el-form-item
                    label="链接地址"
                    prop="eventConfig.url"
                    required
                  >
                    <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入内容"
                      v-model="buttonGroupButtonConfig.eventConfig.url"
                    >
                    </el-input
                  ></el-form-item>
                </template>
                <template
                  v-if="buttonGroupButtonConfig.buttonEvent == 'formEvent'"
                >
                  <el-form-item label="表单" prop="eventConfig.formId" required>
                    <current-company-form-list
                      @changeSource="changeSource"
                      :name="buttonGroupButtonConfig.eventConfig.title"
                      :form-id="buttonGroupButtonConfig.eventConfig.formId"
                    ></current-company-form-list>
                  </el-form-item>
                </template>
              </el-form>
            </template>
            <template v-else>
              <el-empty description="请从左侧选中一个按钮"></el-empty>
            </template>
          </template>
          <template v-if="activeName == 'globalSetting'">
            <el-form label-position="top" size="small">
              <el-form-item
                label="标题"
                v-if="buttonGroupGlobalConfig.extParam"
              >
                <el-input
                  v-model="buttonGroupGlobalConfig.extParam.title"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="buttonGroupGlobalConfig.hiddenTitle"
                  >隐藏头部标题</el-checkbox
                >
              </el-form-item>
              <el-form-item label="按钮样式">
                <div class="row-item">
                  <span class="item-title">形状</span>
                  <el-radio-group
                    v-model="buttonGroupGlobalConfig.groupStyle.shape"
                  >
                    <el-radio-button label="rectangle">矩形</el-radio-button>
                    <el-radio-button label="round">圆形</el-radio-button>
                  </el-radio-group>
                </div>
                <div class="row-item">
                  <span class="item-title">方向</span>
                  <el-radio-group
                    v-model="buttonGroupGlobalConfig.groupStyle.layout"
                  >
                    <el-radio-button label="up_down">上下</el-radio-button>
                    <el-radio-button label="left_right">左右</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-form>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Draggable from "vuedraggable";
import StyleSelect from "./styleSelect.vue";
import ButtonGroupButtonItemSetting from "@/views/lowCode/dashboard/add-component/dashboard-button-group/button-group-button-item-setting.vue";
import ButtonGroupButtonItem from "@/views/lowCode/dashboard/add-component/dashboard-button-group/button-group-button-item.vue";
import { mapState } from "vuex";
import { workDirTree } from "@/api/directoryConfig";
import appViewSelect from "@/views/lowCode/dashboard/add-component/dashboard-button-group/app-view-select.vue";
import CurrentCompanyFormList from "@/views/lowCode/dashboard/add-component/dashboard-button-group/current-company-form-list.vue";

export default {
  name: "dashboard-button-group-edit",
  components: {
    CurrentCompanyFormList,
    ButtonGroupButtonItemSetting,
    ButtonGroupButtonItem,
    Draggable,
    StyleSelect,
    appViewSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: "globalSetting",
      buttonGroupButtonConfig: {
        title: "",
        buttonEvent: "none",
        buttonStyle: {
          type: "class",
          color: "#3870EA",
          className: "icon-briefcase-fill", //icon的className
          icon: "",
        },
        eventConfig: {
          targetEnum: "self",
        },
      },

      buttonGroupGlobalConfig: {
        componentName: "button_group",
        buttonList: [],
        groupStyle: {
          shape: "rectangle",
          layout: "up_down",
          perlineNumbers: 4,
        },
        extParam: { title: "未命名" },
      },
      actionTypeEnums: [
        {
          value: "recordEvent",
          label: "打开工作群",
        },
        {
          value: "hrefEvent",
          label: "打开链接",
        },
        {
          value: "formEvent",
          label: "新增表单",
        },
        {
          value: "none",
          label: "无",
        },
      ],
      targetEnums: [
        {
          value: "self",
          label: "当前窗口",
        },
        {
          value: "blank",
          label: "新窗口",
        },
      ],
      workDirTreeData: [],
      rules: {
        "eventConfig.url": [
          {
            type: "url",
            required: true,
            message: "请输入正确的链接地址",
          },
        ],
        "eventConfig.recordId": [
          {
            type: "string",
            required: true,
            message: "请选择一个工作群",
          },
        ],
        "eventConfig.formId": [
          {
            type: "string",
            required: true,
            message: "请选择一个表单",
          },
        ],
      },
      showSelect: false,
      workDirTreeProps: {
        label: "name",
        children: "directoryList",
        isLeaf: function (data, node) {
          return !data.hasChildList;
        },
      },
      appViewSelectVisible: false,
    };
  },
  watch: {
    "buttonGroupButtonConfig.buttonEvent": {
      handler(val) {
        if (val === "recordEvent") {
          this.fetchDashboardList();
        }
      },
      immediate: true,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    //是否显示打开方式
    isShowTargetEnum() {
      const hasTargetEnum = ["hrefEvent", "formEvent"];
      return ["hrefEvent", "recordEvent"].includes(
        this.buttonGroupButtonConfig.buttonEvent,
      );
    },
  },
  methods: {
    changeSource(val) {
      console.log(val);
      let { bizId, title } = val;
      this.$set(this.buttonGroupButtonConfig.eventConfig, "formId", bizId);
      this.$set(this.buttonGroupButtonConfig.eventConfig, "title", title);
      this.validateButtonSetting();
    },

    appViewSelect(val) {
      let { recordId, recordType, title, param } = val;
      this.$set(this.buttonGroupButtonConfig.eventConfig, "recordId", recordId);
      this.$set(this.buttonGroupButtonConfig.eventConfig, "title", title);
      this.$set(
        this.buttonGroupButtonConfig.eventConfig,
        "recordType",
        recordType,
      );
      this.$set(this.buttonGroupButtonConfig.eventConfig, "param", param);
      this.appViewSelectVisible = false;
    },
    //改变按钮事件的回调
    changeButtonEvent(val) {
      console.log(val);

      //先清楚上一次的校验
      this.$refs.buttonGroupButtonConfigForm.clearValidate();
      //开始新的校验
      this.$refs.buttonGroupButtonConfigForm.validate();
      this.buttonGroupButtonConfig.eventConfig.title = "";
    },
    fetchDashboardList() {
      workDirTree().then((res) => {
        this.workDirTreeData = res.data.directoryList;
      });
    },
    styleSelectHideEvent() {
      console.log("styleSelectHideEvent");
      this.showSelect = false;
    },
    updateIcon(iconStyle) {
      this.$set(this.buttonGroupButtonConfig, "buttonStyle", {
        ...this.buttonGroupButtonConfig.buttonStyle,
        ...iconStyle,
      });
    },
    /**
     * 添加按钮
     */
    addButton() {
      const button = {
        title: "按钮" + (this.buttonGroupGlobalConfig.buttonList.length + 1),
        buttonStyle: {
          type: "class",
          color: "#3870EA",
          className: "icon-briefcase-fill", //icon的className
        },
        buttonEvent: "none",
        eventConfig: {
          targetEnum: "self",
        },
        key: this.getUuid(),
      };
      this.buttonGroupGlobalConfig.buttonList.push(button);
      this.buttonGroupButtonConfig = button;
      this.switchToButtonSetting();
    },
    //切换到图片设置
    switchToButtonSetting() {
      this.activeName = "buttonSetting";
    },
    //切换到全局设置
    switchToGlobalSetting() {
      this.activeName = "globalSetting";
    },
    isHrefEvent(image) {
      return image.clickEvent == "hrefEvent";
    },
    manualInit(model) {
      console.log(model);
      this.dialogVisible = true;
      //初始化图片列表，需要指定一个uuid来做唯一标识
      model.buttonList.forEach((item) => {
        this.$set(item, "key", this.getUuid());
      });
      if (!model.extParam) {
        this.$set(model, "extParam", {});
      }
      console.log(model);
      this.$set(this, "buttonGroupGlobalConfig", model);
    },
    /**
     * 移除照片
     */
    deleteButton(index) {
      this.buttonGroupGlobalConfig.buttonList.splice(index, 1);
    },
    selectButton(button, index) {
      console.log(button);
      this.buttonGroupButtonConfig = button;
      this.switchToButtonSetting();
      this.validateButtonSetting();
    },
    validateButtonSetting() {
      this.$nextTick(() => {
        this.$refs.buttonGroupButtonConfigForm.validate();
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },

    confirm() {
      this.$emit("save", this.buttonGroupGlobalConfig);
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      this.$nextTick(() => {
        Object.assign(this.$data, this.$options.data());
      });
    },
    openDialog() {},
  },
};
</script>
<style lang="scss" scoped>
$gap-size: 10px;
$header-height: 76px;

.button-group-setting-content__wrap {
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{$header-height});
  background-color: #f2f6fc;
  ::v-deep {
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
  }
  .button-group-images-container {
    flex: 1;
    background-color: white;
    padding: 24px;
  }
  .drag-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: $gap-size;
  }
  .button-group-images-setting__wrap {
    width: 256px;
    margin-left: $gap-size;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
}
.button-group-images-container__item {
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  &:hover {
    .button-group-images-container__item__action {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.active {
    .button-group-images-container__item__img {
      border: 2px solid #409eff;
    }
  }
  &.up_down {
    width: 118px;
    height: 114px;
  }
  &.left_right {
    width: 220px;
    height: 60px;
  }
}
.button-group-images-container__item__img {
  width: 100%;
  height: 100%;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-group-images-container__item__action {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  background: #a5adba;
  border-radius: 0px 5px;
  width: 30px;
  height: 22px;
  color: white;
}
.add-image {
  background: #ffffff;
  /* border/light */

  border: 1px solid #ebecf0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5adba;
  font-size: 14px;
  line-height: 24px;
  &:hover {
    border: 2px solid #409eff;
    color: #409eff;
  }
}

.card-border {
  border-radius: 10px;
}
.row-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .item-title {
    font-size: 13px;
    line-height: 22px;
    color: #172b4d;
  }
  ::v-deep {
    .el-radio-button--small .el-radio-button__inner {
      padding: 9px 28px;
    }
  }
}
</style>
<style lang="scss">
.button-icon-setting {
  padding: 0 !important;
  .cont-div {
    position: unset !important;
    margin: 0 !important;
  }
}
</style>
