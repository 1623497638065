<template>
  <div>
    <action-item v-if="action.isRebuild" :addAction="addAction"></action-item>
    <template v-else>
      <template v-if="action.isEdit">
        <div class="flow-header">
          <div>设置触发动作</div>
          <i @click="deleteAction" class="el-icon-delete"></i>
        </div>
        <div style="padding: 10px">
          <div class="weui mb">
            <div class="trigger-label">执行动作</div>
            <div class="trigger-value">
              <span>{{ typeText }}</span
              ><span @click="replaceAction" class="replace">更换</span>
            </div>
          </div>
          <div
            class="mb"
            v-if="
              action.type !== 'notice_wechat' && action.type !== 'notice_sms'
            "
          >
            <div class="trigger-label" style="margin-bottom: 5px">目标表单</div>
            <el-popover
              popper-class="pop-trigger"
              width="260"
              v-model="visible"
            >
              <div v-clickoutside="handleClose">
                <el-input
                  placeholder="请输入关联表单名称"
                  prefix-icon="el-icon-search"
                  v-model="searchTitle"
                  size="mini"
                  @input="searchForm"
                >
                </el-input>
                <div
                  class="pop-scroll"
                  v-infinite-scroll="loadingList"
                  :infinite-scroll-distance="10"
                  :infinite-scroll-disabled="!isMore"
                >
                  <div
                    v-for="item in list"
                    :key="item.id"
                    v-text="item.title"
                    class="form-item"
                    :class="{ active: item.id == action.formId }"
                    @click="checkForm(item)"
                  ></div>
                </div>
              </div>
              <div @click.stop="" slot="reference" class="trigger-select">
                <div class="inner-text" v-text="action.title"></div>
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </div>
          <div
            class="mb"
            v-if="action.type == 'form_data_create' && action.formId"
          >
            <div class="trigger-label" style="margin-bottom: 5px">
              在目标表单中新增数据，字段值设置如下
            </div>
            <field-setting
              v-for="item in action.createFieldValues"
              :key="item.key"
              :componentList="triggerComponents"
              :field="item"
              :triggerFormId="triggerFormId"
              type="add"
              :checkFieldEnabel="checkCreateField"
              :checkValueType="checkValueType"
              :getJsonFormName="getJsonFormName"
            ></field-setting>
          </div>
          <div
            class="mb"
            v-if="action.type == 'form_data_delete' && action.formId"
          >
            <div class="trigger-label" style="margin-bottom: 5px">
              删除目标表单中的哪些数据
            </div>
            <delete-field
              :componentList="componentList"
              :triggerComponents="triggerComponents"
              :formId="action.formId"
              :triggerFormId="triggerFormId"
              :filterFieldValues="action.filterFieldValues"
            ></delete-field>
          </div>
          <div
            class="mb"
            v-if="
              ['form_data_update', 'form_data_save'].includes(action.type) &&
              action.formId
            "
          >
            <div class="trigger-label" style="margin-bottom: 5px">
              修改目标表单中的哪些数据
            </div>
            <filter-field
              :componentList="componentList"
              :triggerComponents="triggerComponents"
              :formId="action.formId"
              :triggerFormId="triggerFormId"
              :filterFieldValues.sync="action.filterFieldValues"
              :updateFieldValues.sync="action.updateFieldValues"
              :type="action.type"
              :areaTree="areaTree"
            ></filter-field>
            <div class="trigger-label" style="margin-bottom: 5px">
              对符合过滤条件的数据，进行以下字段的修改
            </div>
            <update-field
              :componentList="
                componentList.filter(
                  (item) =>
                    !sysFieldNames.includes(item.name) &&
                    item.componentName !== 'sn_input',
                )
              "
              :triggerComponents="
                triggerComponents.filter(
                  (item) => item.componentName !== 'sn_input',
                )
              "
              :formId="action.formId"
              :triggerFormId="triggerFormId"
              :filterFieldValues.sync="action.filterFieldValues"
              :updateFieldValues.sync="action.updateFieldValues"
              :areaTree="areaTree"
            ></update-field>
            <template v-if="action.type == 'form_data_save'">
              <div
                class="trigger-label"
                style="margin-bottom: 5px; margin-top: 20px"
              >
                若找不到符合过滤条件的数据，则根据以下配置在目标表单中新增数据
              </div>
              <field-setting
                v-for="item in action.createFieldValues"
                :key="item.key"
                :componentList="triggerComponents"
                :field="item"
                :triggerFormId="triggerFormId"
                type="add"
                :checkFieldEnabel="checkCreateField"
                :checkValueType="checkValueType"
                :areaTree="areaTree"
                :getJsonFormName="getJsonFormName"
              ></field-setting>
            </template>
          </div>
          <template
            v-if="action.type == 'notice_wechat' || action.type == 'notice_sms'"
          >
            <actionTargetSendMessage
              v-model="action"
              :triggerComponents="triggerComponents"
              :trigger-form-id="triggerFormId"
            ></actionTargetSendMessage>
          </template>
        </div>
        <div class="footer">
          <el-button type="primary" size="small" @click="save">完成</el-button>
        </div>
      </template>
      <div class="trigger-title" v-else>
        <span>{{ typeText }}：{{ action.title }}</span>
        <div @click="editAction" class="trigger-edit">
          <i class="el-icon-edit"></i><span>编辑</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { fetchFormList } from "../form/api";
import Clickoutside from "element-ui/src/utils/clickoutside";
import fieldSetting from "./fieldSetting";
import deleteField from "./deleteField";
import ActionItem from "./ActionItem";
import filterField from "./filterField";
import updateField from "./updateField";
import { isEmpty, _eq } from "@zgg-core-utils/relyUtil";
import actionTargetSendMessage from "@/views/lowCode/assistant/action-target-send-message";
import { mapGetters } from "vuex";

export default {
  directives: { Clickoutside },
  components: {
    fieldSetting,
    deleteField,
    ActionItem,
    filterField,
    updateField,
    actionTargetSendMessage,
  },
  props: {
    value: Object,
    componentsMap: Object,
    triggerFormId: String,
    areaTree: Array,
  },
  watch: {
    value: {
      deep: true,
      handler(val, old) {
        if (!_eq(val, old)) {
          this.action = this._.cloneDeep(val);
        }
      },
    },
  },
  computed: {
    ...mapGetters(["sysFieldNames"]),
    //不触发表单字段的组件
    forbidFieldSetting() {
      return ["summary_data", "form_data", "sn_input"];
    },
    typeText() {
      let text = "";
      if (this.action) {
        if (this.action.type == "form_data_create") {
          text = "新增数据";
        } else if (this.action.type == "form_data_update") {
          text = "修改已有数据";
        } else if (this.action.type == "form_data_delete") {
          text = "删除已有数据";
        } else if (this.action.type == "form_data_save") {
          text = "修改或新增数据";
        } else if (this.action.type == "notice_wechat") {
          text = "发送公众号消息";
        } else if (this.action.type == "notice_sms") {
          text = "发送短信";
        }
      }
      return text;
    },
    componentList() {
      let list = [];
      if (this.componentsMap && this.componentsMap[this.action.formId]) {
        this.componentsMap[this.action.formId].forEach((item) => {
          if (!["position_input"].includes(item.componentName)) {
            if (item.container) {
              item.components.forEach((child) => {
                list.push({
                  ...child,
                  name: item.name + "." + child.name,
                  title: item.title + "." + child.title,
                });
              });
            } else {
              list.push(item);
            }
          }
        });
      }
      return list;
    },
    triggerComponents() {
      let list = [];
      if (this.componentsMap && this.componentsMap[this.triggerFormId]) {
        this.componentsMap[this.triggerFormId]
          .filter((item) => !this.sysFieldNames.includes(item.name))
          .forEach((item) => {
            if (item.container) {
              item.components.forEach((child) => {
                list.push({
                  ...child,
                  name: item.name + "." + child.name,
                  title: item.title + "." + child.title,
                });
              });
            } else {
              list.push(item);
            }
          });
      }
      return list;
    },
  },
  data() {
    return {
      parentName: "",
      visible: false,
      action: {
        isEdit: false,
        isRebuild: false,
      },
      list: [],
      searchTitle: "",
      isMore: false,
      page: {
        pageNumber: 0,
        pageSize: 20,
        totalPage: 0,
        totalRow: 0,
      },
    };
  },
  created() {
    this.action = this._.cloneDeep(this.value);
    this.getData();
    this.loadingList();
  },
  methods: {
    getData() {
      let newArry = [];
      let arry = [];
      let list = [];
      if (["form_data_save", "form_data_create"].includes(this.action.type)) {
        this.componentList
          .filter(
            (item) =>
              !this.sysFieldNames.includes(item.name) &&
              !this.forbidFieldSetting.includes(item.componentName),
          )
          .forEach((item) => {
            let tableField = {
              collection: this.action.formId,
              fieldName: item.name,
              fieldTitle: item.title,
              componentName: item.componentName,
              optionSource: item.optionSource,
              customOptions: item.customOptions,
            };
            if (item.picker) {
              tableField.picker = item.picker;
            }
            if (item.extParam) {
              tableField.extParam = item.extParam;
            }

            if (item.componentName == "reference_data" && item.tableName) {
              tableField.referenceTableName = item.tableName;
            }
            list.push({
              valueType: "tableField",
              tableField,
            });
          });
        let actionCreateFieldValues = this.action.createFieldValues || [];
        if (this.action) {
          actionCreateFieldValues = this.action.createFieldValues;
        } else {
          actionCreateFieldValues = [];
        }
        actionCreateFieldValues.forEach((item) => {
          item.signTip = "delete";
        });
        list.forEach((item) => {
          item.signTip = "new";
        });
        arry = [...list, ...actionCreateFieldValues]; //合并
        newArry = this.arrayUnique(arry, "fieldName"); //去重
        newArry = this.sortByKey(newArry, "signTip"); //排序
        console.log("新数据：", list, "旧数据：", actionCreateFieldValues);
        this.$set(this.action, "createFieldValues", newArry);
        console.log(this.action.createFieldValues, "改变后值是什么");
      }
    },
    //数组去重方法,arr传要去重的数组,name为需要去重的字段
    arrayUnique(arr, name) {
      let hash = {};
      return arr.reduce((acc, cru, index) => {
        if (!hash[cru["tableField"][name]]) {
          hash[cru["tableField"][name]] = { index: acc.length };
          if ((hash[cru["tableField"][name]] = { index: acc.length })) {
          } else {
            cru.signTip = "new";
          }
          //cru.signTip = "delete";
          acc.push(cru);
        } else {
          cru.signTip = "new";
          acc.splice(hash[cru["tableField"][name]]["index"], 1, cru);
        }
        return acc;
      }, []);
    },
    //数组里的对象根据某个字段排序
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    },
    deleteAction() {
      this.$emit("deleteAction");
    },
    replaceAction() {
      this.$set(this.action, "isRebuild", true);
      this.$set(this.value, "isRebuild", true);
    },
    addAction(type) {
      let obj = {
        key: this.action.key,
        type,
        title: "",
        formId: "",
        isEdit: true,
        isRebuild: false,
      };
      this.$set(this.value, "isRebuild", false);
      if (type == "form_data_create") {
        obj.createFieldValues = [];
      } else if (type == "form_data_update") {
        obj.filterFieldValues = [];
        obj.updateFieldValues = [];
      } else if (type == "form_data_delete") {
        obj.filterFieldValues = [];
      } else if (type == "form_data_save") {
        obj.filterFieldValues = [];
        obj.updateFieldValues = [];
        obj.createFieldValues = [];
      } else if (type == "notice_wechat") {
        obj.userRange = {
          userIds: [],
          userFields: [],
          userInfoList: [],
        };
        obj.msgTemplate = "";
        obj.msgTemplate2 = "";
        obj.msgTemplate3 = "";
        obj.formId = this.triggerFormId;
        obj.title = "发送公众号消息";
      } else if (type == "notice_sms") {
        obj.userRange = {
          userIds: [],
          userFields: [],
          userInfoList: [],
        };
        obj.msgTemplate = [];
        obj.smsTemplateId = "";
        obj.smsPlatForm = "aliyun";
        obj.formId = this.triggerFormId;
        obj.title = "发送短信";
      }
      this.$set(this, "action", obj);
    },
    checkForm(item) {
      if (item.id == this.action.formId) {
        return;
      }
      this.action.formId = item.id;
      this.action.title = item.title;
      this.visible = false;
      this.$emit("getComponents", item.id, () => {
        if (["form_data_save", "form_data_create"].includes(this.action.type)) {
          let list = [];
          this.componentList
            .filter(
              (item) =>
                !this.sysFieldNames.includes(item.name) &&
                !this.forbidFieldSetting.includes(item.componentName),
            )
            .forEach((item) => {
              let tableField = {
                collection: this.action.formId,
                fieldName: item.name,
                fieldTitle: item.title,
                componentName: item.componentName,
                optionSource: item.optionSource,
                customOptions: item.customOptions,
              };

              if (item.picker) {
                tableField.picker = item.picker;
              }
              if (item.extParam) {
                tableField.extParam = item.extParam;
              }

              if (item.componentName == "reference_data" && item.tableName) {
                tableField.referenceTableName = item.tableName;
              }
              list.push({
                valueType: "tableField",
                tableField,
              });
            });
          this.$set(this.action, "createFieldValues", list);
          this.$set(this.action, "filterFieldValues", []);
          this.$set(this.action, "updateFieldValues", []);
        } else if (this.action.type == "form_data_update") {
          this.$set(this.action, "filterFieldValues", []);
          this.$set(this.action, "updateFieldValues", []);
        } else if (this.action.type == "form_data_delete") {
          this.$set(this.action, "filterFieldValues", []);
        }
      });
    },
    handleClose() {
      this.visible = false;
    },
    save() {
      if (
        this.action.type !== "notice_wechat" &&
        this.action.type !== "notice_sms" &&
        !this.action.formId
      ) {
        this.$message.error("请选择目标表单");
        return;
      }
      let chkFilter = (list, str) => {
        let item = list.find(
          (item) =>
            item.valueType != "nil" &&
            isEmpty(item.value) &&
            isEmpty(item.triggerTableField),
        );
        if (item) {
          this.$message.error(
            str + "【" + item.tableField.fieldTitle + "】不能为空",
          );
          return true;
        }
        return false;
      };

      if (this.action.type == "form_data_create") {
        if (chkFilter(this.action.createFieldValues, "新增数据，字段")) {
          return;
        }
      } else if (
        this.action.type !== "notice_wechat" &&
        this.action.type !== "notice_sms"
      ) {
        if (
          !(
            this.action.filterFieldValues &&
            this.action.filterFieldValues.length
          )
        ) {
          this.$message.error("请设置过滤条件");
          return;
        } else if (
          !this.action.filterFieldValues.some(
            (row) => !row.tableField.fieldName.includes("."),
          )
        ) {
          this.$confirm(
            "当前过滤条件中只有子表字段间的过滤关系，你需要再添加至少一条主表字段的过滤条件。",
            "过滤条件不完整",
            {
              confirmButtonText: "确定",
              showCancelButton: false,
              type: "warning",
            },
          );
          return;
        }
        if (chkFilter(this.action.filterFieldValues, "过滤条件中，字段")) {
          return;
        }
        if (["form_data_update", "form_data_save"].includes(this.action.type)) {
          if (
            !(
              this.action.updateFieldValues &&
              this.action.updateFieldValues.length
            )
          ) {
            this.$message.error("请设置要修改的字段");
            return;
          }
          if (
            chkFilter(this.action.updateFieldValues, "目标表单中，修改字段")
          ) {
            return;
          }
        }
        if (this.action.type == "form_data_save") {
          if (chkFilter(this.action.createFieldValues, "新增数据，字段")) {
            return;
          }
        }
      }
      if (this.action.type == "notice_sms") {
        if (
          this.action.msgTemplate.some((item) => {
            return isEmpty(item.title);
          })
        ) {
          this.$message.error("请完善占位符与字段属性的映射关系");
          return;
        }
      }
      this.action.isEdit = false;
      this.action.isRebuild = false;
      this.$emit("updateAction", this._.cloneDeep(this.action));
    },
    editAction() {
      this.$set(this.action, "isEdit", true);
      this.$emit("updateAction", this._.cloneDeep(this.action));
    },
    searchForm() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.page.pageNumber = 0;
        this.loadingList();
      }, 200);
    },
    loadingList() {
      if (this.loading) {
        return;
      }
      if (this.page.pageNumber && this.page.pageNumber >= this.page.totalPage) {
        return;
      }
      this.loading = true;

      fetchFormList({
        pageSize: this.page.pageSize,
        pageNumber: this.page.pageNumber + 1,
        title: this.searchTitle,
      })
        .then((res) => {
          let page = res.data.page;
          this.page.pageNumber = page.pageNumber;
          this.page.pageSize = page.pageSize;
          this.page.totalPage = page.totalPage;
          this.page.totalRow = page.totalRow;
          this.isMore = page.pageNumber < page.totalPage;
          if (page.pageNumber == 1) {
            this.list = page.list;
          } else {
            this.list = this.list.concat(page.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkValueType() {
      return new Promise((resolve, reject) => {
        let hasConfirm =
          this.action.createFieldValues.findIndex(
            (item) =>
              item.tableField.fieldName.indexOf(".") == -1 &&
              item.triggerTableField &&
              item.triggerTableField.fieldName.indexOf(".") >= 0,
          ) >= 0;
        if (hasConfirm) {
          this.$confirm(
            "目标表单主字段和子字段不能同时使用子字段赋值。此操作可能会清除部分已设置的字段赋值方式，无法还原。",
            "确定要使用子表单字段赋值吗？",
          )
            .then(() => {
              this.action.createFieldValues.forEach((item) => {
                if (item.triggerTableField) {
                  this.$set(item, "triggerTableField", undefined);
                }
              });
              resolve(true);
            })
            .catch(() => {
              reject(false);
            });
        } else {
          resolve(true);
        }
      });
    },
    getJsonFormName() {
      let parentName = "";
      let obj = this.action.createFieldValues.find(
        (item) =>
          item.valueType == "tableField" &&
          !isEmpty(item.triggerTableField) &&
          item.triggerTableField.fieldName.indexOf(".") > 0,
      );
      if (obj) {
        parentName = obj.triggerTableField.fieldName.split(".")[0];
      }
      return parentName;
    },

    checkCreateField(fieldName, name) {
      // 检测新增数据时字段是否符合要求
      return new Promise((resolve, reject) => {
        let parentName;
        if (name.indexOf(".") >= 0) {
          parentName = name.split(".")[0];
        }
        if (parentName) {
          // 子表单
          if (fieldName.indexOf(".") == -1) {
            // 非子表单

            if (
              this.action.createFieldValues.findIndex(
                (item) =>
                  item.valueType == "tableField" &&
                  item.tableField.fieldName.indexOf(".") >= 0,
              ) >= 0
            ) {
              this.$confirm(
                "目标表单主字段和子字段不能同时使用子字段赋值。此操作可能会清除部分已设置的字段赋值方式，无法还原。",
                "确定要使用子表单字段赋值吗？",
              )
                .then(() => {
                  this.action.createFieldValues.forEach((item) => {
                    if (
                      item.tableField.fieldName.indexOf(".") >= 0 &&
                      item.valueType == "tableField"
                    ) {
                      item.valueType = "custom";
                      this.$set(item, "value", "");
                      this.$set(item, "triggerTableField", undefined);
                    }
                  });
                  resolve(true);
                })
                .catch(() => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          } else {
            //  子表单
            let firstName = fieldName.split(".")[0];
            let list = [];
            this.action.createFieldValues
              .filter(
                (item) =>
                  item.tableField.fieldName.indexOf(firstName + ".") == 0,
              )
              .forEach((item) => {
                if (item.triggerTableField) {
                  let pName = item.triggerTableField.fieldName.split(".")[0];
                  if (!list.includes(pName)) {
                    list.push(pName);
                  }
                }
              });
            if (list.length && !list.includes(parentName)) {
              this.$confirm(
                "主字段或单个子表单不能使用不同子表单字段赋值。此操作会清除部分已设置的字段赋值方式，无法还原。",
                "确定要使用子表单字段赋值吗？",
              )
                .then(() => {
                  this.action.createFieldValues.forEach((item) => {
                    if (
                      item.tableField.fieldName.indexOf(firstName + ".") == 0
                    ) {
                      if (item.triggerTableField) {
                        this.$set(item, "triggerTableField", undefined);
                      }
                    }
                  });
                  resolve(true);
                })
                .catch(() => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          }
        } else {
          resolve(true);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.trigger-title {
  line-height: 30px;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .trigger-edit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.05);
    display: none;
  }

  &:hover {
    color: rgba($color: #333, $alpha: 0.45);

    .trigger-edit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      cursor: pointer;
    }
  }
}

.weui {
  display: flex;
  align-items: center;
}

.trigger-label {
  color: #5e6d82;
  font-size: 14px;
}

.trigger-value {
  background-color: #f1f1f1;
  padding: 5px 10px;
  font-size: 14px;
  margin-left: 10px;
}

.replace {
  font-size: 14px;
  color: #409eff;
  cursor: pointer;
  margin: 0px 5px;
}

.trigger-select {
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  overflow: hidden;

  .inner-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    font-size: 14px;
  }
}

.mb {
  margin-bottom: 12px;
}

.weui-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}

.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.flex {
  flex: 1;
}

.footer {
  border-top: solid 1px #e0e0e0;
  padding: 10px;
}
</style>
<style lang="scss">
.pop-trigger {
  &.el-popover {
    padding: 0;
  }

  .pop-scroll {
    max-height: 320px;
    overflow: auto;

    .form-item {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &.active {
        background-color: rgba($color: #409eff, $alpha: 0.5);
        color: #ffffff;
      }

      &:hover {
        background-color: rgba($color: #409eff, $alpha: 0.2);
      }
    }
  }
}

.flow-header {
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
