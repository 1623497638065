<template>
  <div class="body">
    <div class="weui-flex">
      <!-- <el-form inline>
        <el-form-item label="名称" style="width: 300px">
          <el-input size="small" v-model="form.title"></el-input>
        </el-form-item>
      </el-form> -->

      <div style="font-size: 14px; padding-left: 30px">
        名称:{{ form.title }}
      </div>

      <div>
        <el-button @click="back()">取消</el-button>
        <el-button @click="saveSprit" type="primary">保存 </el-button>
      </div>
    </div>

    <div style="width: 800px; margin: 0px auto; font-size: 14px">
      <div style="padding: 10px">当以下动作发生时</div>
      <div class="flow-item mb">
        <div class="flow-header">设置触发动作</div>

        <template v-if="!form.flow.trigger">
          <div style="margin: 10px">数据变动时触发</div>
          <div class="flow-action">
            <div class="action" @click="addTrigger('create')">
              <div class="action-title">新增数据</div>
              <div class="action-desc">在触发表单中填报新的数据</div>
            </div>
            <div class="action" @click="addTrigger('update')">
              <div class="action-title">修改数据</div>
              <div class="action-desc">
                在触发表单中修改已有数据，如流程状态等
              </div>
            </div>
            <div class="action" @click="addTrigger('delete')">
              <div class="action-title">删除数据</div>
              <div class="action-desc">
                在触发表单中删除某条数据，不包含批量删除
              </div>
            </div>
          </div>
          <div style="margin: 10px">时间触发</div>
          <div class="flow-action">
            <div class="action" @click="addTimerTrigger('timer_cron')">
              <div class="action-title">定时重复</div>
              <div class="action-desc">在指定时间范围内，按照指定周期触发</div>
            </div>
            <div class="action" @click="addTimerTrigger('timer_once')">
              <div class="action-title">定时单次</div>
              <div class="action-desc">在指定时间触发一次</div>
            </div>
            <div class="action" @click="addTimerTrigger('timer_field')">
              <div class="action-title">按日期字段触发</div>
              <div class="action-desc">
                根据表单的日期字段，按照指定周期触发
              </div>
            </div>
          </div>
        </template>
        <trigger-target
          v-else
          v-model="form.flow.trigger"
          :componentsMap="componentsMap"
          :changeTriggerType="changeTriggerType"
          @getComponents="getComponents"
          @changeTrigger="changeTrigger"
          :areaTree="areaTree"
        ></trigger-target>
      </div>
      <div style="margin: 10px 0">
        <i class="el-icon-bottom"></i>
      </div>
      <div style="margin-bottom: 10px">执行以下动作</div>
      <div>
        <div class="trigger-empty" v-if="!showActionTarget">
          请先设置触发动作，再设置执行动作
        </div>
        <template v-else>
          <actions-target
            v-for="(item, index) in form.flow.actions"
            :key="item.key"
            :triggerFormId="form.flow.trigger.formId"
            :value="item"
            :areaTree="areaTree"
            :componentsMap="componentsMap"
            @getComponents="getComponents"
            class="flow-item"
            style="margin-bottom: 20px"
            @updateAction="updateAction(index, $event)"
            @deleteAction="deleteAction(index)"
          ></actions-target>
          <action-item
            style="margin-bottom: 20px"
            v-if="isCreateAction"
            :addAction="addAction"
          ></action-item>
          <template v-else-if="form.flow.actions.length">
            <div
              @click="addActionItem"
              class="action-line"
              style="cursor: pointer"
              v-if="form.flow.actions.findIndex((item) => item.isRebuild) == -1"
            >
              <i class="el-icon-plus"></i><span>添加执行动作</span>
            </div>
          </template>

          <div v-else class="trigger-empty">
            请先设置触发动作，再设置执行动作
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { getAreaTree } from "@/api/contact";
import actionsTarget from "./actionsTarget";
import triggerTarget from "./triggerTarget";
import ActionItem from "./ActionItem";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import { getSpiritDetail, renameSpirit, saveSpiritFlow } from "./api";
import { getFormDetail } from "../form/api";
import timerTriggerMixin from "@/views/lowCode/assistant/timer-trigger-mixin";
import { fetchMetaFieldComponentList } from "@/api/form";

const customsOpts = [
  "radio_group",
  "checkbox_group",
  "select",
  "select_checkbox",
];
export default {
  mixins: [timerTriggerMixin],
  components: { triggerTarget, actionsTarget, ActionItem },
  data() {
    return {
      areaTree: [],
      componentsMap: {},

      form: {
        title: "",
        flow: {
          actions: [],
        },
      },
      isCreateAction: false,
    };
  },
  computed: {
    showActionTarget() {
      //!(form.flow.trigger && form.flow.trigger.formId)
      let noNeedToSettingFormTriggerEnums = ["timer_once", "timer_cron"];
      if (
        this.form.flow.trigger &&
        this.form.flow.trigger.type &&
        noNeedToSettingFormTriggerEnums.includes(this.form.flow.trigger.type)
      ) {
        return true;
      } else {
        return this.form.flow.trigger && this.form.flow.trigger.formId;
      }
    },
  },
  created() {
    this.getFlowDetail();
    getAreaTree().then((res) => {
      let areaTree = [];
      let buildTree = (soruce) => {
        let obj = {};
        for (const [key, value] of Object.entries(soruce)) {
          if (key != "children") {
            obj[key] = value;
          }
        }
        if (soruce.children && soruce.children.length) {
          obj.children = [];
          soruce.children.forEach((item) => {
            let target = buildTree(item);
            obj.children.push(target);
          });
        }
        return obj;
      };
      res.data.tree.forEach((item) => {
        let target = buildTree(item);
        areaTree.push(target);
      });
      this.areaTree = areaTree;
    });
  },
  methods: {
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      let { matched } = this.$router.history.current;
      if (matched.length >= 2) {
        let parentPath = matched[matched.length - 2].path;
        this.$router.push({ path: parentPath });
      } else {
        this.$router.back();
      }
    },
    getFlowDetail() {
      let id = this.$route.query.id;
      if (!id) {
        return;
      }
      getSpiritDetail(id).then((res) => {
        let spirit = res.data.spirit;
        this.form.id = spirit.id;
        this.form.title = spirit.title;
        if (spirit.flow) {
          spirit.flow.trigger.isEdit = false;
          if (!spirit.flow.trigger.condition) {
            spirit.flow.trigger.condition = {
              rel: "and",
              advanceQuery: [],
            };
          } else {
            if (!spirit.flow.trigger.condition.rel) {
              spirit.flow.trigger.condition.rel = "and";
            }
            if (!spirit.flow.trigger.condition.advanceQuery) {
              spirit.flow.trigger.condition.advanceQuery = [];
            }
          }
          let list = [];
          if (spirit.flow.trigger.formId) {
            list = [spirit.flow.trigger.formId];
          }

          spirit.flow.actions.forEach((item) => {
            if (item.formId && !list.includes(item.formId)) {
              list.push(item.formId);
            }
          });
          if (list.length) {
            let arr = [];
            list.forEach((val) => {
              if (val) {
                arr.push(
                  fetchMetaFieldComponentList(val, [
                    "flowStatus",
                    "attachmentList",
                    "createrId",
                    "createdTime",
                    "updaterId",
                    "updatedTime",
                    "currentFlowNodeTitles",
                    "currentFlowWorkUserIds",
                  ]),
                );
              }
            });

            Promise.all(arr).then((result) => {
              result.forEach((resp, index) => {
                let formId = list[index];
                let list2 = resp.data.list;
                if (
                  spirit.flow.trigger &&
                  spirit.flow.trigger.formId == formId
                ) {
                  spirit.flow.trigger.condition.advanceQuery.forEach((item) => {
                    if (customsOpts.includes(item.componentName)) {
                      let opt = list2.find((o) => o.name == item.key);
                      if (opt && opt.optionSource == "custom") {
                        item.optionSource = opt.optionSource;
                        item.customOptions = opt.customOptions;
                      }
                    }
                  });
                }

                this.$set(this.componentsMap, formId, list2);
              });
              let buildFields = (list, componentList) => {
                list.forEach((field) => {
                  let opt;

                  if (field.tableField.fieldName.indexOf(".") == -1) {
                    opt = componentList.find(
                      (item) => item.name == field.tableField.fieldName,
                    );
                  } else {
                    let parentName = field.tableField.fieldName.split(".")[0];
                    let name = field.tableField.fieldName.split(".")[1];
                    let parent = componentList.find(
                      (item) => item.name == parentName,
                    );
                    if (parent) {
                      opt = parent.components.find((item) => item.name == name);
                    }
                  }
                  if (opt) {
                    if (
                      customsOpts.includes(opt.componentName) &&
                      opt.optionSource == "custom"
                    ) {
                      this.$set(
                        field.tableField,
                        "optionSource",
                        opt.optionSource,
                      );
                      this.$set(
                        field.tableField,
                        "customOptions",
                        opt.customOptions,
                      );
                    }
                    if (opt.picker) {
                      this.$set(field.tableField, "picker", opt.picker);
                    }
                    if (opt.extParam) {
                      this.$set(field.tableField, "extParam", opt.extParam);
                    }
                  }
                });
              };
              spirit.flow.actions.forEach((item) => {
                this.$set(item, "isEdit", false);
                this.$set(item, "isRebuild", false);

                let componentList = this.componentsMap[item.formId];
                if (item.createFieldValues) {
                  buildFields(item.createFieldValues, componentList);
                }
                if (item.filterFieldValues) {
                  buildFields(item.filterFieldValues, componentList);
                }
                if (item.updateFieldValues) {
                  buildFields(item.updateFieldValues, componentList);
                }
              });
              this.$set(this.form, "flow", spirit.flow);
            });
          } else {
            this.$set(this.form, "flow", spirit.flow);
          }
        }
      });
    },
    deleteAction(index) {
      this.form.flow.actions.splice(index, 1);
      if (this.form.flow.actions.length == 0) {
        this.isCreateAction = true;
      }
    },
    updateAction(index, item) {
      this.$set(this.form.flow.actions, index, item);
    },
    addActionItem() {
      this.isCreateAction = true;
    },
    addAction(type) {
      let obj = {
        key: this.getUuid(),
        type,
        title: "",
        formId: "",
        isEdit: true,
        isRebuild: false,
      };
      if (type == "form_data_create") {
        obj.createFieldValues = [];
      } else if (type == "form_data_update") {
        obj.filterFieldValues = [];
        obj.updateFieldValues = [];
      } else if (type == "form_data_delete") {
        obj.filterFieldValues = [];
      } else if (type == "form_data_save") {
        obj.filterFieldValues = [];
        obj.updateFieldValues = [];
        obj.createFieldValues = [];
      } else if (type == "notice_wechat") {
        obj.userRange = {
          userIds: [],
          userFields: [],
          userInfoList: [],
        };
        obj.msgTemplate = "";
        obj.msgTemplate2 = "";
        obj.msgTemplate3 = "";
        obj.title = "发送公众号消息";
        obj.formId = this.form.flow.trigger.formId;
      } else if (type == "notice_sms") {
        obj.userRange = {
          userIds: [],
          userFields: [],
          userInfoList: [],
        };
        obj.msgTemplate = [];
        obj.smsTemplateId = "";
        obj.smsPlatForm = "aliyun";
        obj.title = "发送短信";
        obj.formId = this.form.flow.trigger.formId;
      }
      this.form.flow.actions.push(obj);
      this.isCreateAction = false;
    },
    changeTrigger(isEdit, isClear) {
      if (isClear) {
        this.form.flow.actions = [];
      }
      if (this.form.flow.actions.length == 0) {
        this.isCreateAction = !isEdit;
      }
    },
    changeTriggerType() {
      this.$confirm("更换触发动作后，执行动作将全部清空，确定更换吗？")
        .then(() => {
          this.form.flow.trigger = null;
          this.form.flow.actions = [];
        })
        .catch(() => {});
    },
    addTrigger(type) {
      let trigger = {
        isEdit: true,
        key: this.getUuid(),
        type: "form_data_change",
        dataChangeType: type,
        formId: "",
        title: "",
        condition: {
          rel: "and",
          advanceQuery: [],
        },
      };
      this.$set(this.form.flow, "trigger", trigger);
    },
    getComponents(formId, callback) {
      if (this.componentsMap[formId]) {
        if (typeof callback === "function") {
          callback();
        }
        return;
      }

      // fix 采用表单元数据接口，流程状态
      fetchMetaFieldComponentList(formId, [
        "flowStatus",
        "attachmentList",
        "createrId",
        "createdTime",
        "updaterId",
        "updatedTime",
        "currentFlowNodeTitles",
        "currentFlowWorkUserIds",
      ]).then((res) => {
        let list = res.data.list;
        this.$set(this.componentsMap, formId, list);
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    buildComponents(componentList) {
      let list = [];
      componentList.forEach((item) => {
        if (item.form) {
          list.push(item);
        } else if (item.container) {
          list = list.concat(this.buildComponents(item.components));
        }
      });
      return list;
    },
    saveSprit() {
      if (isEmpty(this.form.title)) {
        this.$message.error("智能助手的名称不能为空");
        return;
      }
      if (!this.form.flow.trigger) {
        this.$message.error("请设置触发动作");
        return;
      }
      if (this.form.flow.trigger.isEdit) {
        this.$message.error("请完成触发动作的设置");
        return;
      }
      if (this.form.flow.actions.length == 0) {
        this.$message.error("请设置执行动作");
        return;
      }
      if (
        this.form.flow.actions.findIndex(
          (item) => item.isEdit || item.isRebuild,
        ) >= 0
      ) {
        this.$message.error("请完成执行动作的设置");
        return;
      }

      let flow = this._.cloneDeep(this.form.flow);
      let lines = [];
      delete flow.trigger.isEdit;

      this.form.flow.actions.forEach((item) => {
        delete item.isEdit;
        delete item.isRebuild;
        lines.push({
          fromKey: this.form.flow.trigger.key,
          toKey: item.key,
        });
      });
      flow.lines = lines;
      saveSpiritFlow({
        spiritId: this.form.id,
        flow,
        title: this.form.title,
      }).then((res) => {
        this.$message.success("保存成功");
        // this.$router.back();
        // this.back()
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  background-color: #f4f5f7;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.weui-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px;

  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}

.flow-item {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);

  .flow-header {
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 40px;
    padding: 0 10px;
  }

  .flow-action {
    display: flex;
    flex-wrap: wrap;

    .action {
      width: 250px;
      margin: 0 5px 10px;
      padding: 10px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      cursor: pointer;
      -webkit-transition: all 218ms ease;
      transition: all 218ms ease;

      &:hover {
        border-color: #409eff;
      }
    }

    .action-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .action-desc {
      margin-top: 6px;
      color: #91a1b7;
      font-size: 12px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.trigger-empty {
  color: #c3cdda;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

.action-line {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}
</style>
<style lang="scss">
.pop-empty {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 10px;
}
</style>
