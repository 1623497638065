<template>
  <chart-edit
    :loading="saveLoading"
    v-if="view"
    :appView="view.component"
    :closeFun="closeFun"
    @saveChart="saveForm"
  ></chart-edit>
</template>
<script>
import { getViewDetail, saveViewComponent } from "./api";
import chartEdit from "./chartEdit";

export default {
  components: { chartEdit },
  props: {
    viewId: [Number, String],
    closeFun: Function,
  },
  async created() {
    let viewId = this.$route.query.id;
    if (this.viewId) {
      viewId = this.viewId;
    }
    let res = await getViewDetail({ viewId: viewId });
    if (res.code) {
      this.$message.error(res.msg);
    } else {
      this.$set(this, "view", res.data.view);
    }
  },
  data() {
    return {
      saveLoading: false,
      view: null,
    };
  },
  methods: {
    saveForm({ componentName, view }) {
      this.saveLoading = true;
      let viewId = this.$route.query.id;
      if (this.viewId) {
        viewId = this.viewId;
      }

      saveViewComponent({
        viewId: viewId,
        componentName,
        component: JSON.stringify(view),
      })
        .then((res) => {
          this.saveLoading = false;
          this.$message.success("视图保存成功");
          if (this.viewId) {
            this.$emit("saveChart", this.viewId);
          } else {
            // this.$router.back();
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  height: calc(100vh - 50px);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.form-body {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mr {
  margin-right: 10px;
}

.col-h {
  font-size: 14px;
}

.col-container {
  min-height: 50px;
  border: solid 1px #f4f5f7;
  margin-top: 10px;
  padding-bottom: 10px;

  .column-item,
  .sub-col-item {
    background-color: #ecf5ff;
    border-color: #d9ecff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #409eff;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    margin-top: 10px;
    margin-left: 5px;

    i {
      display: none;
    }

    .sub-container {
      display: none;
    }
  }

  .m {
    margin-top: 10px;
    border-width: 1px !important;
    outline: 0 !important;
  }
}
</style>
