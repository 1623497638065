import request from '@/utils/request'

import qs from "qs"



/**
 * 公司
 */

/**
 * 分页列表
 */
export function companyList(data) {
    return request({
        url:  '/company/list',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 * 单位注册
 */
export function companyRegister(data) {
    return request({
        url:  '/company/register',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 * 单位修改
 */
export function companyModify(data) {
    return request({
        url:  '/company/modify',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 * 获取单位详情
 */
export function companyDetail(data) {
    return request({
        url:  '/company/detail',
        method: 'POST',
        data: qs.stringify(data)
    })
}


/**
 * /company/project/detail 单位项目详情
 */
export function companyProjectDetail(data) {
    return request({
        url:  '/company/project/detail',
        method: 'POST',
        data: qs.stringify(data)
    })
}

/**
 * 单位项目分页列表
 */
export function companyProjectListPage(data) {
    return request({
        url:  '/company/project/listPage',
        method: 'POST',
        data: qs.stringify(data)
    })
}

//  单位项目保存
export function companyProjectSave(data) {
    return request({
        url:  '/company/project/save',
        method: 'POST',
        data: qs.stringify(data)
    })
}

// /company/project/section/detail 单位项目分段详情
export function companyProjectSectionDetail(data) {
    return request({
        url:  '/company/project/section/detail',
        method: 'POST',
        data: qs.stringify(data)
    })
}

//  单位项目分段保存
export function companyProjectSectionSave(data) {
    return request({
        url:  '/company/project/section/save',
        method: 'POST',
        data: qs.stringify(data)
    })
}
//  单位模版设置模块获取单位列表
export function companyPaging(data) {
    return request({
        url:  '/company/paging',
        method: 'POST',
        data
    })
}

/**
 * 添加选择的模板
 * @param data
 * /res/addSelection?dirId=目录ID(必填)&res=资源类型：20看板 40视图 50整编(必填)&resId=资源ID(上面截图的resId，必填)&resName=资源名称(上面截图的名称)(比填)
 * @returns {*}
 */
export function resourceAddSelection(data) {
    return request({
        url:  '/res/addSelection',
        method: 'POST',
        data
    })
}

/**
 * 重命名已选的模板(旧接口)
 * @param data
 * @returns {*}
 */
export function resourceRename(data) {
    return request({
        url:  '/res/rename',
        method: 'POST',
        data
    })
}

/**
 * 删除模版(旧接口)
 * @param data
 * @returns {*}
 */
export function resourceDelete(data) {
    return request({
        url:  '/res/delete',
        method: 'POST',
        data
    })
}
/**
 * 添加选择的目录接口：
 * @param data
 * @returns {*}
 */
export function resourceAddSelectionDir(data) {
    return request({
        url:  '/res/addSelectionDir',
        method: 'POST',
        data
    })
}


//员工列表
export function companyEmployeeList(data) {
    return request({
        url: '/company/employee/list',
        method: 'post',
        data: qs.stringify(data)
    })
}

// 用户搜索
export function employeeSearch(data) {
  return request({
      url:  '/employee/search',
      method: 'POST',
      data
  })
}