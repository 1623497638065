var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-container"},[_c('div',{staticClass:"source-box"},[_c('div',{staticClass:"source-header"},[_c('div',[_vm._v("数据源")]),(_vm.switchAble)?_c('div',{staticClass:"action",on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v(" 更改数据源 ")]):_vm._e()]),_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.formTitle))])]),_c('div',{staticClass:"column-list"},[_c('draggable',{attrs:{"list":_vm.orignColumns,"options":_vm.options1,"move":_vm.onMove,"dragClass":"drag-item","animation":"300","filter":".sub-col-item","disabled":_vm.disabled},on:{"start":_vm.onStart,"end":_vm.onEnd,"choose":_vm.choose,"unchoose":_vm.unchoose}},[_vm._l((_vm.orignColumns),function(column,index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !(
              (_vm.isDetailTable && column.field.name == 'attachmentList') ||
              column.field.name == 'commentCount'
            )
          ),expression:"\n            !(\n              (isDetailTable && column.field.name == 'attachmentList') ||\n              column.field.name == 'commentCount'\n            )\n          "}],key:index,staticClass:"column-item",style:({
            background: _vm.isSelect(column)
              ? 'var(--zgg-brand-color-2)'
              : 'unset',
          }),attrs:{"data-index":index}},[(
              ((column.field.componentName == 'json_form' &&
                _vm.activeName == 'form') ||
                column.field.componentName == 'reference_data') &&
              (column.field.componentName != 'reference_data' ||
                _vm.viewType != 'dashboard')
            )?_c('i',{staticClass:"sub-col-item",class:column.subVisible ? 'el-icon-arrow-down' : 'el-icon-arrow-right',on:{"click":function($event){column.subVisible = !column.subVisible}}}):_vm._e(),(column.title == '当前节点')?_c('i',{staticClass:"iconfont icon-liucheng"}):_c('i',{staticClass:"iconfont",class:_vm.componentIcons[column.field.componentName]}),_c('span',{domProps:{"textContent":_vm._s(column.title)}}),(
              (column.field.componentName == 'json_form' &&
                _vm.activeName == 'form') ||
              column.field.componentName == 'reference_data'
            )?_c('draggable',{directives:[{name:"show",rawName:"v-show",value:(column.subVisible),expression:"column.subVisible"}],staticClass:"sub-container",attrs:{"list":column.components,"options":_vm.options1,"move":_vm.onMove,"dragClass":"drag-item","animation":"300"},on:{"start":function($event){return _vm.onStartSub($event, index)},"end":_vm.onEnd,"choose":_vm.choose,"unchoose":function($event){return _vm.unchooseSub($event, index)}}},_vm._l((column.components),function(col,cIndex){return (
                column.field.componentName != 'reference_data' ||
                _vm.viewType != 'dashboard'
              )?_c('div',{key:cIndex,staticClass:"sub-col-item",style:({
                background: _vm.isSelect(col)
                  ? 'var(--zgg-brand-color-2)'
                  : 'unset',
              }),attrs:{"data-parent-index":index,"data-index":cIndex},domProps:{"textContent":_vm._s(col.title)}}):_vm._e()}),0):_vm._e()],1)]})],2)],1),_c('data-source',{attrs:{"visible":_vm.dialogVisible,"menus":_vm.menus},on:{"update:visible":function($event){_vm.dialogVisible=$event}},model:{value:(_vm.tableName),callback:function ($$v) {_vm.tableName=$$v},expression:"tableName"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }