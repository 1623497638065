<template>
  <div
      v-if="oper == 'between'"
      class="date-picker-range__wrap"
  >
    <el-date-picker
        style="flex: 1"
        :type="datePickerAdapt()"
        placeholder="开始时间`"
        v-model="innerValue[0]"
        :format="getValueFormat(picker)"
        clearable
        @change="changeDate"
        :picker-options="startDatePicker"
        size="small"
    ></el-date-picker>
    <span style="margin: 0 5px">至</span>
    <el-date-picker
        style="flex: 1"
        :type="datePickerAdapt()"
        placeholder="结束时间`"
        v-model="innerValue[1]"
        :format="getValueFormat(picker)"
        clearable
        @change="changeDate"
        :picker-options="endDatePicker"
        size="small"
    ></el-date-picker>
  </div>
  <div
      v-else-if="oper == 'dynamic'"
      class="date-picker-range__wrap"
      style="display: flex;flex-direction: column;align-items: flex-start"
  >
    <el-select
        v-model="innerValue.timeMode"
        size="small"
        style=" width: 100%"
        @change=""
    >
      <el-option
          v-for="item in dynamicTimeModeEnums"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      >
      </el-option>
    </el-select>
    <div v-if="innerValue.timeMode=='custom'" style="display: flex;flex-direction: column;width: 100%">
      <el-select
          v-model="timeOffsetType"
          size="small"
          class="ml"
          placeholder="时间范围类型"
          @change="changeTrigger"
      >
        <el-option label="当前" value="current"></el-option>
        <el-option label="过去" value="before"></el-option>
        <el-option label="未来" value="after"></el-option>
      </el-select>
      <div style="display: inline-flex;flex-direction: column;">
        <time-input v-model="innerValue.range[0]['timeOffset']" :time-offset-type="timeOffsetType" placeholder-prefix="开始">

        </time-input>
        <time-input v-model="innerValue.range[1]['timeOffset']" :time-offset-type="timeOffsetType" placeholder-prefix="结束">

        </time-input>
      </div>
    </div>

  </div>

  <el-date-picker
      v-else-if="oper != 'nil' && oper != 'nnil'"
      :type="
                picker == 'date_minute'
                  ? 'datetime'
                  : picker
                  ? picker
                  : 'datetime'
              "
      :format="getValueFormat(picker)"
      style="width: 100%"
      :key="oper"
      size="medium"
      v-model="innerValue"
      @change="changeDate"
      placeholder="请选择日期"
  >
  </el-date-picker>
</template>

<script>
import timeInput from "@/views/lowCode/view/components/time-input";

export default {
  name: "date-filter",
  components: {
    timeInput
  },
  props: {
    oper: String,
    picker: String,
    value: {
      type: [Array, String, Object,Date],
      default() {

      },
    },

  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    },
    timeOffsetType: {
      get() {
        if (this.oper == 'dynamic' && this.value && this.value.timeMode == 'custom' && this.value.range && this.value.range.length && this.value.range[0] && this.value.range[0].timeOffset) {
          let timeNumber = Object.values(this.value.range[0].timeOffset)[0]
          if (timeNumber == 0) return 'current'
          if (timeNumber < 0) return 'before'
          if (timeNumber > 0) return 'after'
        }
      },
      set(val) {
        let ranges = this.value.range
        ranges.map(range => {
          console.log(range)
        })
      }
    }
  },

  data() {
    return {
      startDatePicker: this.beginDate,
      endDatePicker: this.processDate,
      dynamicTimeModeEnums: [
        {
          label: "自定义",
          value: "custom"
        },
        {
          label: "今天",
          value: "today"
        },
        {
          label: "昨天",
          value: "yesterday"
        },
        {
          label: "明天",
          value: "tomorrow"
        },
        {
          label: "本周",
          value: "thisWeek"
        },
        {
          label: "上周",
          value: "lastWeek"
        },

      ],

    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        console.log("val", val, "oldVal", oldVal)

      }
    },
    oper: {
      handler(val, oldVal) {
        this.initValueByOperator()

      }
    }
  },

  methods: {

    changeTrigger(val) {
      console.log(val, this.value)
      if (val == "current") {
        this.$set(this.value.range[0], "timeOffset", {
          "day": 0
        });
        this.$set(this.value.range[1], "timeOffset", {
          "day": 0
        });
      } else if (val == "after") {
        this.$set(this.value.range[0], "timeOffset", {
          "day": 1
        });
        this.$set(this.value.range[1], "timeOffset", {
          "day": 1
        });
      } else if(val == "before") {
        this.$set(this.value.range[0], "timeOffset", {
          "day": -1
        });
        this.$set(this.value.range[1], "timeOffset", {
          "day": -1
        });
      }
    },
    buildDynamicCustom() {

    },
    getValueByOperator() {

      if (this.oper == 'between') {
        return ['', ''];
      } else if (this.oper == 'dynamic') {
        return {
          timeMode: "custom",
          range: [{
            timeOffset: {}
          }, {
            timeOffset: {}
          }]
        };
      } else {
        return ""
      }
    },
    initValueByOperator() {
      console.log("initValueByOperator", this.getValueByOperator())
      this.$emit("input", this.getValueByOperator())
    },
    datePickerAdapt() {
      return this.picker == 'date_minute' ? 'datetime' : this.picker
    },
    beginDate() {
      return {
        disabledDate: (time) => {
          return time.getTime() > new Date(this.value[1]).getTime();
        },
      };
    },
    //提出结束时间必须大于提出开始时间
    processDate() {
      return {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.value[0]).getTime();
        },
      };
    },
    changeDate(val) {
      console.log(val)
    },
    getValueFormat(type) {
      if (type == "year") {
        return "yyyy";
      } else if (type == "month") {
        return "yyyy-MM";
      } else if (type == "date") {
        return "yyyy-MM-dd";
      } else if (type == "date_minute") {
        return "yyyy-MM-dd HH:mm";
      } else if (type == "datetime") {
        return "yyyy-MM-dd HH:mm:ss";
      } else {
        return "yyyy-MM-dd HH:mm:ss";
      }
    },
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.date-picker-range__wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 50px;
}
</style>
