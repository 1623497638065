import { render, staticRenderFns } from "./attr-reference-more-config.vue?vue&type=template&id=46a304e1&scoped=true"
import script from "./attr-reference-more-config.vue?vue&type=script&lang=js"
export * from "./attr-reference-more-config.vue?vue&type=script&lang=js"
import style0 from "./attr-reference-more-config.vue?vue&type=style&index=0&id=46a304e1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a304e1",
  null
  
)

export default component.exports