<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :label-position="formComponent.labelPosition"
      style="width: 778px; margin: 10px auto"
      :label-width="`${formComponent.labelWidth}px`"
      :rules="rules"
      class="cust-form"
    >
      <template v-if="formComponent.components">
        <el-form-item
          v-for="(item, index) in formComponent.components"
          :key="index"
          :label="item.title"
          :style="`width:${100 / formComponent.cols}%;`"
          :prop="item.name"
        >
          <el-input
            v-if="
              item.componentName == 'input' ||
              item.componentName == 'input_number'
            "
            v-model="form[item.name]"
            :placeholder="item.placeHolder"
            :disabled="disabled"
            @blur="blurInput(item, $event)"
          >
            <template
              v-if="
                item.componentName == 'input_number' && item.format == 'percent'
              "
              slot="append"
              >%</template
            >
          </el-input>
          <el-input
            v-else-if="item.componentName == 'text_area'"
            v-model="form[item.name]"
            type="textarea"
            :autosize="{ minRows: 3 }"
            :placeholder="item.placeHolder"
            :disabled="disabled"
          ></el-input>
          <el-date-picker
            v-else-if="item.componentName == 'date_picker'"
            v-model="form[item.name]"
            :type="item.picker"
            :placeholder="item.placeHolder"
            :disabled="disabled"
            :value-format="getValueFormat(item.picker)"
          ></el-date-picker>
          <el-select
            v-else-if="item.componentName == 'select'"
            :placeholder="item.placeHolder"
            v-model="form[item.name]"
            :disabled="disabled"
          >
            <el-option
              v-for="(opt, index) in getOptions(item)"
              :key="index"
              :label="
                item.optionSource == 'custom'
                  ? opt.label
                  : opt[item.metaFieldOption.labelFieldName]
              "
              :value="
                item.optionSource == 'custom'
                  ? opt.value
                  : opt[item.metaFieldOption.valueFieldName]
              "
            ></el-option>
          </el-select>
          <el-radio-group
            :disabled="disabled"
            v-model="form[item.name]"
            v-else-if="item.componentName == 'radio_group'"
            class="custom-radio"
          >
            <el-radio
              v-for="(opt, index) in getOptions(item)"
              :key="index"
              :label="
                item.optionSource == 'custom'
                  ? opt.value
                  : opt[item.metaFieldOption.valueFieldName]
              "
              >{{
                item.optionSource == "custom"
                  ? opt.label
                  : opt[item.metaFieldOption.labelFieldName]
              }}</el-radio
            >
          </el-radio-group>
          <el-checkbox-group
            v-model="form[item.name]"
            v-else-if="item.componentName == 'checkbox_group'"
            :disabled="disabled"
            class="custom-checkbox"
          >
            <el-checkbox
              v-for="(opt, index) in getOptions(item)"
              :label="
                item.optionSource == 'custom'
                  ? opt.value
                  : opt[item.metaFieldOption.valueFieldName]
              "
              :key="index"
              >{{
                item.optionSource == "custom"
                  ? opt.label
                  : opt[item.metaFieldOption.labelFieldName]
              }}</el-checkbox
            >
          </el-checkbox-group>

          <div v-if="item.componentName == 'json_form'">
            <el-table class="custom-table" :data="form[item.name]">
              <el-table-column
                v-for="(obj, cIndex) in item.components"
                :key="cIndex"
                :label="obj.title ? obj.title : obj.label"
              >
                <template slot-scope="{ row }">
                  <el-input
                    v-if="
                      obj.componentName == 'input' ||
                      obj.componentName == 'input_number'
                    "
                    v-model="row[obj.name]"
                    :placeholder="item.placeHolder"
                    :disabled="disabled"
                    @blur="blurInput(obj, $event)"
                  >
                    <template
                      v-if="
                        obj.componentName == 'input_number' &&
                        obj.format == 'percent'
                      "
                      slot="append"
                      >%</template
                    >
                  </el-input>
                  <el-input
                    v-else-if="obj.componentName == 'text_area'"
                    v-model="row[obj.name]"
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    :placeholder="obj.placeHolder"
                    :disabled="disabled"
                  ></el-input>
                  <el-date-picker
                    v-else-if="obj.componentName == 'date_picker'"
                    v-model="row[obj.name]"
                    :type="obj.picker"
                    :placeholder="obj.placeHolder"
                    :disabled="disabled"
                    :value-format="getValueFormat(item.picker)"
                  ></el-date-picker>
                  <el-select
                    v-else-if="obj.componentName == 'select'"
                    :placeholder="obj.placeHolder"
                    v-model="row[obj.name]"
                    :disabled="disabled"
                  >
                    <el-option
                      v-for="(opt, index) in getOptions(obj)"
                      :key="index"
                      :label="
                        obj.optionSource == 'custom'
                          ? opt.label
                          : opt[obj.metaFieldOption.labelFieldName]
                      "
                      :value="
                        obj.optionSource == 'custom'
                          ? opt.value
                          : opt[obj.metaFieldOption.valueFieldName]
                      "
                    ></el-option>
                  </el-select>
                  <el-radio-group
                    :disabled="disabled"
                    v-model="row[obj.name]"
                    v-else-if="obj.componentName == 'radio_group'"
                    class="custom-radio"
                  >
                    <el-radio
                      v-for="(opt, index) in getOptions(obj)"
                      :key="index"
                      :label="
                        obj.optionSource == 'custom'
                          ? opt.value
                          : opt[obj.metaFieldOption.valueFieldName]
                      "
                      >{{
                        obj.optionSource == "custom"
                          ? opt.label
                          : opt[obj.metaFieldOption.labelFieldName]
                      }}</el-radio
                    >
                  </el-radio-group>
                  <el-checkbox-group
                    v-model="row[obj.name]"
                    v-else-if="obj.componentName == 'checkbox_group'"
                    :disabled="disabled"
                    class="custom-checkbox"
                  >
                    <el-checkbox
                      v-for="(opt, index) in getOptions(obj)"
                      :label="
                        obj.optionSource == 'custom'
                          ? opt.value
                          : opt[obj.metaFieldOption.valueFieldName]
                      "
                      :key="index"
                      >{{
                        obj.optionSource == "custom"
                          ? opt.label
                          : opt[obj.metaFieldOption.labelFieldName]
                      }}</el-checkbox
                    >
                  </el-checkbox-group>
                </template>
              </el-table-column>
              <el-table-column width="100" fixed="right" label="操作">
                <template slot-scope="{ index }">
                  <el-button
                    @click="deleteRow(index, form[item.name])"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-button
              style="margin-top: 10px"
              @click="addRow(item)"
              type="text"
              icon="el-icon-plus"
              >添加</el-button
            >
          </div>
        </el-form-item>
      </template>

      <el-form-item class="block">
        <el-button v-if="!$route.query.routeNotBack" @click="handleCancel"
          >取消</el-button
        >
        <el-button @click="saveForm" v-if="!disabled" type="primary"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  getFormData,
  getFormDetail,
  saveFormData,
  fetchPage,
} from "./form/api";
import test from "@/utils/test";
export default {
  data() {
    return {
      formComponent: {},

      form: {},
      rules: {},
      sourceList: {},
      /**
       * 别的模块复用的时候，防止非路由加载的时候保存之后路由后退
       */
      routeNotBack: false,
    };
  },
  computed: {
    disabled() {
      return this.$route.name == "表单数据详情";
    },
  },
  mounted() {
    if (this.$route.query.routeNotBack) {
      this.routeNotBack = true;
    }
  },
  async created() {
    // todo 表单详情修改
    let res = await getFormDetail(this.$route.params.formId);
    this.formComponent = res.data.form.formComponent;

    let arr = ["radio_group", "checkbox_group", "select"];
    this.formComponent.components.forEach(async (item) => {
      if (item.componentName == "checkbox_group") {
        this.$set(this.form, item.name, []);
      }
      if (item.defaultValue) {
        let defaultValue = item.defaultValue;
        if (defaultValue && item.componentName == "checkbox_group") {
          defaultValue = JSON.parse(defaultValue);
        }
        this.$set(this.form, item.name, defaultValue);
      }

      if (item.required || item.componentName == "input_number") {
        let rule = [];
        if (item.required) {
          rule.push({
            required: true,
            message: `${item.title}不能为空`,
          });
        }
        if (item.componentName == "input_number") {
          rule.push({
            validator: (rule, value, callback) => {
              if ((!item.required && test.empty(value)) || test.number(value)) {
                callback();
              } else {
                callback("请输入数字");
              }
            },
          });
        }
        this.$set(this.rules, item.name, rule);
      }

      if (
        arr.includes(item.componentName) &&
        item.optionSource == "metaField" &&
        item.metaFieldOption &&
        item.metaFieldOption.tableName &&
        !(
          this.sourceList[item.metaFieldOption.tableName] &&
          this.sourceList[item.metaFieldOption.tableName].length
        )
      ) {
        let queryObject = {
          pageNumber: 1,
          pageSize: 999,
        };

        let sortParam = {};
        if (item.metaFieldOption.orderBy == "orderByOptionValueAsc") {
          sortParam[item.metaFieldOption.valueFieldName] = "asc";
        } else if (item.metaFieldOption.orderBy == "orderByOptionValueDesc") {
          sortParam[item.metaFieldOption.valueFieldName] = "desc";
        } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeAsc") {
          sortParam["createdTime"] = "asc";
        } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeDesc") {
          sortParam["createdTime"] = "desc";
        }
        if (Object.keys(sortParam).length) {
          queryObject.sortParam = sortParam;
        }

        let res = await fetchPage({
          collection: item.metaFieldOption.tableName,
          queryObject: JSON.stringify(queryObject),
        });
        this.$set(
          this.sourceList,
          item.metaFieldOption.tableName,
          res.data.page.list,
        );
        // this.tableData = data.page.list;
      }

      if (item.componentName == "json_form") {
        this.$set(this.form, item.name, []);
        let o = {};
        item.components.forEach(async (obj) => {
          if (obj.defaultValue) {
            o[obj.name] = obj.defaultValue;
          }
          if (obj.componentName == "checkbox_group") {
            if (typeof obj.defaultValue === "string") {
              o[obj.name] = JSON.parse(obj.defaultValue);
            }
          }

          if (
            arr.includes(obj.componentName) &&
            obj.optionSource == "metaField" &&
            obj.metaFieldOption &&
            obj.metaFieldOption.tableName &&
            !(
              this.sourceList[obj.metaFieldOption.tableName] &&
              this.sourceList[obj.metaFieldOption.tableName].length
            )
          ) {
            let queryObject = {
              pageNumber: 1,
              pageSize: 999,
            };

            let sortParam = {};
            if (obj.metaFieldOption.orderBy == "orderByOptionValueAsc") {
              sortParam[obj.metaFieldOption.valueFieldName] = "asc";
            } else if (
              obj.metaFieldOption.orderBy == "orderByOptionValueDesc"
            ) {
              sortParam[obj.metaFieldOption.valueFieldName] = "desc";
            } else if (obj.metaFieldOption.orderBy == "orderByCreatedTimeAsc") {
              sortParam["createdTime"] = "asc";
            } else if (
              obj.metaFieldOption.orderBy == "orderByCreatedTimeDesc"
            ) {
              sortParam["createdTime"] = "desc";
            }
            if (Object.keys(sortParam).length) {
              queryObject.sortParam = sortParam;
            }

            let res2 = await fetchPage({
              collection: obj.metaFieldOption.tableName,
              queryObject: JSON.stringify(queryObject),
            });
            this.$set(
              this.sourceList,
              obj.metaFieldOption.tableName,
              res2.data.page.list,
            );
            // this.tableData = data.page.list;
          }

          // this.$set(this.form, item.name, [o]);
        });

        this.form[item.name].push(o);
      }
    });

    if (this.$route.name != "表单数据新增") {
      this.getFormData();
    }
  },
  methods: {
    handleCancel() {
      if (!this.routeNotBack) {
        this.$router.back();
      }
    },
    getValueFormat(type) {
      if (type == "year") {
        return "yyyy";
      } else if (type == "month") {
        return "yyyy-MM";
      } else if (type == "date") {
        return "yyyy-MM-dd";
      } else {
        return "yyyy-MM-dd HH:mm:ss";
      }
    },
    deleteRow(index, list) {
      list.splice(index, 1);
    },
    addRow(item) {
      let obj = {};
      item.components.forEach((o) => {
        if (
          o.componentName == "checkbox_group" ||
          o.componentName == "json_form"
        ) {
          obj[o.name] = [];
        } else {
          obj[o.name] = "";
        }
      });
      this.form[item.name].push(obj);
    },
    getOptions(item) {
      if (item.optionSource == "custom") {
        return item.customOptions;
      }

      if (this.sourceList[item.metaFieldOption.tableName]) {
        return this.sourceList[item.metaFieldOption.tableName];
      }
      return [];
    },
    blurInput(obj, event) {
      let value = event.currentTarget.value;
      if (
        obj.componentName == "input_number" &&
        obj.decimalPrecision &&
        test.number(value)
      ) {
        // value =
        let index = value.indexOf(".");
        let num = obj.decimalPrecision;

        if (index > 0) {
          value = value.substring(0, index + num + 1);
        } else if (index == 0) {
          value = value.substring(0, index + num + 1);

          if (num == 0) {
            value = 0;
          } else {
            value = "0" + value;
          }
        }
        value = new Number(value).toFixed(num);
        this.form[obj.name] = value;
      }
    },
    getFormData() {
      getFormData({
        collection: this.$route.params.formId,
        id: this.$route.query.id,
      }).then((res) => {
        let form = this._.cloneDeep(this.form);
        this.form = {
          ...form,
          ...res.data.document,
        };
        // this.form = res.data.document;
      });
    },
    saveForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let error;
        for (let i = 0; i < this.formComponent.components.length; i++) {
          const element = this.formComponent.components[i];
          if (element.componentName != "json_form") {
            continue;
          }

          for (let k = 0; k < element.components.length; k++) {
            const item = element.components[k];
            if (item.required) {
              let isEmpty = this.form[element.name].filter((row) =>
                test.empty(row[item.name]),
              ).length;
              if (isEmpty) {
                error = `【${element.title}】的【${item.title}】不能为空！`;
                break;
              }
            }
            if (item.componentName == "input_number") {
              let isNoPass = this.form[element.name].filter((row) => {
                let value = row[item.name];
                if (!test.empty(value)) {
                  return !test.number(value);
                }
                return false;
              }).length;
              if (isNoPass) {
                error = `【${element.title}】的【${item.title}】输入数字`;
              }
            }
          }
          if (error) {
            break;
          }
        }
        if (error) {
          this.$message.error(error);
          return;
        }

        let data = {
          collection: this.$route.params.formId,
          document: JSON.stringify(this.form),
        };

        saveFormData(data).then((res) => {
          this.$message.success("表单数据保存成功");
          if (!this.routeNotBack) {
            this.$router.back();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cust-form {
  &::after {
    display: block;
    content: " ";
    clear: both;
  }

  ::v-deep &.el-form--label-top {
    .el-form-item__label {
      line-height: 1;
      padding-bottom: 6px;
    }
  }

  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
      padding: 5px;
      float: left;
      &.block {
        width: 100% !important;
        clear: both;
        text-align: center;
      }
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100% !important;
    }
  }
}

.custom-table {
  ::v-deep &.el-table {
    th {
      line-height: 1.5;
    }
  }
}

.custom-checkbox,
.custom-radio {
  ::v-deep {
    .el-checkbox,
    .el-radio {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
