<template>
  <div>
    <el-form label-position="top" size="small" ref="form" :model="value" :show-message="false">
      <el-form-item label="触发时间" style="width: 100%" required prop="executeTime">
        <el-date-picker
            v-model="value.executeTime"
            type="datetime"
            size="small"
            placeholder="请选择触发时间">
        </el-date-picker>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
export default {
  name: "timer-once-trigger-setting",
  props: {
    value: Object,
  },
  data() {
    return {}
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate()
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
