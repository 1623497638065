<template>
  <div class="rely-body" @click="click" @mousedown="mousedown">
    <pre
      class="cm-pre"
      v-for="(item, index) in spans"
      :key="index"
    ><span style="padding-right:0.1px"><span class="cm-span" :class="{'cm-field':row.type=='field','cm-fun':row.type=='fun','cm-error':hasDelete(row)}" v-for="(row,index2) in item" :key="index2" >{{row.value}}{{hasDelete(row)?'(已删除)':''}}</span></span></pre>
    <div
      class="market"
      v-show="show"
      ref="market"
      :style="{ left: left + 'px', top: top + 'px' }"
    ></div>
    <div style="height: 0; overflow: hidden; width: 3px">
      <el-input
        ref="textarea"
        type="textarea"
        v-model="inputValue"
        placeholder=""
        @keydown.native="keydown"
        @input="input"
        @focus="show = true"
        @blur="show = false"
        style="min-height: 0px"
        :disabled="disabled"
      ></el-input>
    </div>
  </div>
</template>
<script>
import { _eq } from "@zgg-core-utils/relyUtil";
const fields = ["field", "fun"];
export default {
  data() {
    return {
      left: 0,
      top: 0,
      spans: [],
      rows: 0,
      cols: 0,
      charIndex: 0,
      inputValue: "",
      show: false,
      scrollLeft: 0,
    };
  },
  props: {
    columns: Array,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    textType: {
      type: String,
      default() {
        return "textarea";
      },
    },
    operator: {
      type: Array,
      default() {
        return [];
      },
    },
    split: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    actionType: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val, old) {
        if (!_eq(val, this.spans)) {
          this.spans = this._.cloneDeep(val);
        }
      },
    },
    spans: {
      deep: true,
      handler(val, old) {
        console.log(val)
        this.$emit("input", val);
      },
    },
    columns: {
      deep: true,
      handler(val) {
        if(this.actionType != "notice_sms"){
        let spans = this._.cloneDeep(this.spans);
        if (spans.length) {
          for (let i = 0; i < spans.length; i++) {
            let rows = spans[i].filter(
              (item) =>
                item.type == "val" ||
                (item.type == "field" &&
                  val.findIndex((obj) => {
                    if(obj.componentName == "reference_data" && obj.columns) {
                      let column = obj.columns.find((column) => {
                        let name = `${obj.name}.${column.field.name}`
                        return name == item.name
                      })
                      return column ? true : false
                    }
                    return obj.name == item.name
                  }) >= 0)
            );
            spans[i] = rows;
          }
          // spans = spans.filter((item) => item.length);
          this.spans = spans;
        }
        }
      },
    },
  },

  methods: {
    addColumn(item) {
      let obj = {
        name: item.name,
        value: item.title,
        type: "field",
      };
      if (item.parentName) {
        obj.parentName = item.parentName;
      }
      if (!this.spans[this.rows]) {
        this.$set(this.spans, this.rows, [obj]);
      } else {
        this.spans[this.rows].splice(this.cols + 1, 0, obj);
        this.cols = this.cols + 1;
      }
      this.charIndex = obj.value.length;
      //   this.$emit("input", this.spans);
      this.$nextTick(() => {
        setTimeout(() => {
          this.setCustorPosition(this.rows, this.cols, this.charIndex);
        }, 5);
        this.$refs.textarea.focus();
      });
    },
    mousedown(event) {
      //   event.preventDefault();
      //   event.stopPropagation();

      this.moveObj = {
        clientX: event.clientX,
        clientY: event.clientY,
        scrollLeft: this.$el.scrollLeft,
      };
      document.addEventListener("mousemove", this.mousemove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.mouseup, {
        passive: false,
      });
    },
    mousemove(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      let x = this.moveObj.clientX - event.clientX;
      let maxScrollLeft = this.$el.scrollWidth - this.$el.clientWidth;
      let scrollLeft = this.moveObj.scrollLeft + x;
      if (scrollLeft < 0) {
        scrollLeft = 0;
      } else if (scrollLeft > maxScrollLeft) {
        scrollLeft = maxScrollLeft;
      }
      this.$el.scrollLeft = scrollLeft;
    },
    mouseup(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.moveObj = null;
    },
    hasDelete(row) {
      if(this.actionType == "notice_sms"){
        return false
      } else {
        let index = this.columns.findIndex((item) => {
          if(item.componentName == "reference_data" && item.columns) {
            let column = item.columns.find((column) => {
              let name = `${item.name}.${column.field.name}`
              return name == row.name
            })
            return column ? true : false
          }
          return item.name == row.name
        });
        return index == -1 && row.type == "field";
      }
    },
    click(event) {
      var txt = window.getSelection
        ? window.getSelection()
        : document.selection.createRange().text;
      let index = txt.anchorOffset;
      let anchorNode = txt.anchorNode;
      let scrollLeft = this.$el.scrollLeft;

      document.querySelectorAll(".cm-pre").forEach((item, index) => {
        let $parentNode;
        if (event.target.parentNode.nodeName == "PRE") {
          $parentNode = event.target.parentNode;
        } else {
          $parentNode = event.target.parentNode.parentNode;
        }
        if (item == event.target || item == $parentNode) {
          this.rows = index;

          this.cols = 0;
          this.charIndex = 0;
          if (event.target.nodeName == "SPAN") {
            item.querySelectorAll(".cm-span").forEach((col, cols) => {
              if (col == event.target) {
                this.cols = cols;
              }
            });
          }
        }
      });

      if (this.spans.length == 0) {
        this.left = 0;
        this.top = 0;

        this.$refs.textarea.focus();
        return;
      }

      if (event.target.nodeName == "DIV") {
        this.rows = 0;
        this.cols = 0;
        this.charIndex = 0;
        if (this.spans.length) {
          this.rows = this.spans.length - 1;
          if (this.spans[this.rows] && this.spans[this.rows].length) {
            this.cols = this.spans[this.rows].length - 1;
          } else {
            this.left = 0;
            this.top =
              document.querySelectorAll(".cm-pre")[this.rows].offsetTop;
            this.$refs.textarea.focus();
            return;
          }
        }
      }

      if (event.target.nodeName == "PRE") {
        if (!this.spans[this.rows].length) {
          this.cols = 0;
          this.charIndex = 0;
          this.left = 0;
          this.top = event.target.offsetTop;
          this.$refs.textarea.focus();
          return;
        }
      }

      if (!anchorNode) {
        return;
      }

      document
        .querySelectorAll(".cm-pre")
        [this.rows].querySelectorAll(".cm-span")
        .forEach((item, cols) => {
          if (item == anchorNode.parentNode) {
            this.cols = cols;
          }
        });

      this.charIndex = index;
      let col = this.spans[this.rows][this.cols];

      this.setCustorPosition(this.rows, this.cols, this.charIndex);

      //

      this.$refs.textarea.focus();
      setTimeout(() => {
        this.$el.scrollLeft = scrollLeft;
      }, 1);
    },
    setCustorPosition(rows, cols, charIndex) {
      if (
        this.spans[rows] &&
        this.spans[rows][cols] &&
        fields.includes(this.spans[rows][cols].type)
      ) {
        let $span = document
          .querySelectorAll(".cm-pre")
          [rows].querySelectorAll(".cm-span")[cols];
        if (this.charIndex == 0) {
          this.left = $span.offsetLeft;
        } else {
          this.left = $span.offsetLeft + $span.offsetWidth;
          this.charIndex = this.spans[rows][cols].value.length;
        }

        this.top = $span.offsetTop - 1;
      } else {
        let { left, top } = this.getPosition(rows, cols, charIndex);
        this.left = left;
        this.top = top;
      }
    },
    getPosition(rows, cols, charIndex) {
      if (this.spans.length == 0) {
        return { left: 0, top: 0 };
      }
      let col = this.spans[rows][cols];
      let doubleCount = 0;
      let signCount = 0;

      if (col) {
        if (fields.includes(col.type)) {
          let $span = document
            .querySelectorAll(".cm-pre")
            [rows].querySelectorAll(".cm-span")[cols];
          let left = $span.offsetLeft;
          let top = $span.offsetTop - 1;
          if (charIndex > 0) {
            left += $span.offsetWidth;
          }
          return { left, top };
        }

        for (let index = 0; index < charIndex; index++) {
          const char = col.value[index];
          if (typeof char !== "undefined") {
            if (char.charCodeAt(0) > 255) {
              // 双字节
              doubleCount += 1;
            } else {
              // 单字节
              signCount += 1;
            }
          }
        }
      }

      let char_width =
        Math.floor(signCount / 9) * (2 * 8 + 7 * 7) +
        Math.floor(doubleCount / 9) * (2 * 13 + 7 * 12);
      let mod = signCount % 9;
      if (mod >= 5) {
        char_width += 8 + (mod - 1) * 7;
      } else {
        char_width += mod * 7;
      }

      let mod2 = doubleCount % 9;
      if (mod2 >= 5) {
        char_width += 12 + (mod2 - 1) * 11;
      } else {
        char_width += mod2 * 11;
      }

      let $el = document.querySelectorAll(".cm-pre")[rows];
      let $span = $el.querySelectorAll(".cm-span")[cols];

      let left, top;

      if ($span) {
        left = $span.offsetLeft + char_width;
        top = $span.offsetTop - 3;
        let height = $span.offsetHeight + 6;

        let width = $span.offsetWidth;
        let count = height / 20;

        if (count > 1 && left > width) {
          // 多行光标定位
          let a = 0;
          let b = 0;
          char_width = 0;
          let arr = [];

          for (let index = 0; index < charIndex; index++) {
            const char = col.value[index];
            if (typeof char !== "undefined") {
              if (char.charCodeAt(0) > 255) {
                // 双字节
                // doubleCount += 1;
                b += 1;
                if (b == 5 || b == 9) {
                  char_width += 13;
                  if (b == 9) {
                    b = 0;
                  }
                } else {
                  char_width += 12;
                }
                if (arr.length) {
                  left = char_width;
                } else {
                  left = $span.offsetLeft + char_width;
                }
                if (left > width) {
                  a = 0;
                  b = 1;
                  char_width = 12;
                  arr.push(index);
                }
              } else {
                // 单字节
                a += 1;
                if (a == 5 || a == 9) {
                  char_width += 8;
                  if (a == 9) {
                    a = 0;
                  }
                } else {
                  char_width += 7;
                }

                if (arr.length) {
                  left = char_width;
                } else {
                  left = $span.offsetLeft + char_width;
                }
                if (left > width) {
                  a = 1;
                  b = 0;
                  char_width = 7;
                  arr.push(index);
                }
              }
            }
            left = char_width;
            top = 20 * arr.length + $span.offsetTop - 3;
          }
        }
      } else {
        left = 0;
        top = $el.offsetTop;
      }
      return { left, top };
    },
    keydown(event) {
      let keyCodes = [8, 13, 35, 36, 37, 38, 39, 40, 46];

      if (keyCodes.includes(event.keyCode)) {
        event.preventDefault();
        if (event.keyCode == 8) {
          // backspace
          if (this.spans.length == 0) {
            return;
          }
          if (this.rows == 0 && this.cols == 0 && this.charIndex == 0) {
            return;
          }

          if (this.spans[this.rows].length == 0) {
            // 空行删除
            if (this.spans[this.rows]) {
              this.spans.splice(this.rows, 1);

              if (this.rows > 0) {
                this.rows = this.rows - 1;
              }
              if (this.spans.length == 0) {
                this.cols = 0;
                this.charIndex = 0;
              } else {
                this.cols = this.spans[this.rows].length - 1;
                this.charIndex = this.spans[this.rows][this.cols]
                  ? this.spans[this.rows][this.cols].value.length
                  : 0;
              }
            }
            this.setCustorPosition(this.rows, this.cols, this.charIndex);
            return;
          }

          let callback = (rows, cols, charIndex) => {
            let col = this.spans[rows][cols];
            if (!fields.includes(col.type) && col.value.length > 1) {
              let arr = col.value.split("");
              arr.splice(charIndex - 1, 1);
              col.value = arr.join("");
              this.charIndex = charIndex - 1;
            } else {
              this.spans[rows].splice(cols, 1);
              if (cols > 0) {
                this.cols = cols - 1;
                this.charIndex = this.spans[rows][this.cols]
                  ? this.spans[rows][this.cols].value.length
                  : 0;
              } else {
                this.charIndex = 0;
              }
            }
          };

          if (this.charIndex > 0) {
            callback(this.rows, this.cols, this.charIndex);
          } else {
            if (this.cols == 0) {
              // 合并行
              let next = this.spans[this.rows][this.cols];
              let prev =
                this.spans[this.rows - 1][this.spans[this.rows - 1].length - 1];
              let row = this.spans.splice(this.rows, 1)[0];
              if (
                next.type == prev.type &&
                ["val", "operator", "space"].includes(next.type)
              ) {
                // 收尾合并
                this.rows = this.rows - 1;
                this.cols = this.spans[this.rows].length - 1;
                this.charIndex = this.spans[this.rows][this.cols]
                  ? this.spans[this.rows][this.cols].value.length
                  : 0;
                prev.value = prev.value + next.value;
                row.splice(0, 1);
                let arr = this.spans[this.rows].concat(...row);
                this.$set(this.spans, this.rows, arr);
              } else {
                //
                this.rows = this.rows - 1;
                this.cols = this.spans[this.rows].length - 1;
                this.charIndex = this.spans[this.rows][this.cols]
                  ? this.spans[this.rows][this.cols].value.length
                  : 0;
                let arr = this.spans[this.rows].concat(...row);
                this.$set(this.spans, this.rows, arr);
              }
            } else {
              let col = this.spans[this.rows][this.cols - 1];
              callback(this.rows, this.cols - 1, col.value.length);
            }
          }
          this.setCustorPosition(this.rows, this.cols, this.charIndex);
        } else if (event.keyCode == 46) {
          // delete
          if (this.spans.length == 0) {
            return;
          }
          if (!this.spans[this.rows].length) {
            // 空行删除
            if (this.spans.length > this.rows + 1) {
              this.spans.splice(this.rows, 1);
              this.cols = 0;
              this.charIndex = 0;
            }
          } else {
            if (
              !(
                this.charIndex ==
                  this.spans[this.rows][this.cols].value.length &&
                this.cols == this.spans[this.rows].length - 1
              )
            ) {
              if (
                this.charIndex < this.spans[this.rows][this.cols].value.length
              ) {
                let col = this.spans[this.rows][this.cols];
                let arr = col.value.split("");
                arr.splice(this.charIndex, 1);
                if (!fields.includes(col.type) && arr.length) {
                  this.spans[this.rows][this.cols].value = arr.join("");
                } else {
                  this.spans[this.rows].splice(this.cols, 1);
                  if (this.cols) {
                    this.cols = this.cols - 1;
                    this.charIndex = this.spans[this.rows][this.cols]
                      ? this.spans[this.rows][this.cols].value.length
                      : 0;
                  } else {
                    if (this.spans.length - 1 > this.rows) {
                      this.spans.splice(this.rows, 1);
                      this.cols = 0;
                      this.charIndex = 0;
                    }
                  }
                }
              } else {
                if (
                  fields.includes(this.spans[this.rows][this.cols + 1].type)
                ) {
                  this.spans[this.rows].splice(this.cols + 1, 1);
                } else {
                  let arr =
                    this.spans[this.rows][this.cols + 1].value.split("");
                  arr.splice(0, 1);
                  if (arr.length) {
                    this.spans[this.rows][this.cols + 1].value = arr.join("");
                  } else {
                    this.spans[this.rows].splice(this.cols + 1, 1);
                  }
                }
              }
            } else {
              if (this.spans[this.rows].length - 1 == this.cols) {
                // 最后列最后位置
                if (this.spans.length - 1 > this.rows) {
                  // 不是最后一行，合并下一行
                  let prev = this.spans[this.rows][this.cols];
                  let next = this.spans[this.rows + 1][0];
                  let row = this.spans.splice(this.rows + 1, 1)[0];
                  if (next && prev) {
                    if (
                      next.type == prev.type &&
                      ["val", "operator", "space"].includes(next.type)
                    ) {
                      // 收尾合并
                      prev.value = prev.value + next.value;
                      row.splice(0, 1);
                      let arr = this.spans[this.rows].concat(...row);
                      this.$set(this.spans, this.rows, arr);
                    } else {
                      //
                      let arr = this.spans[this.rows].concat(...row);
                      this.$set(this.spans, this.rows, arr);
                    }
                  }
                }
              }
            }
          }

          this.setCustorPosition(this.rows, this.cols, this.charIndex);
        } else if (event.keyCode == 13) {
          // 回车
          if (this.textType == "textarea") {
            if (this.spans.length) {
              if (this.spans[this.rows].length == 0) {
                this.spans.splice(this.rows + 1, 0, []);
                this.rows = this.rows + 1;
                this.cols = 0;
                this.charIndex = 0;
                this.$nextTick(() => {
                  this.setCustorPosition(this.rows, this.cols, this.charIndex);
                });
              } else {
                let newRow = [];
                if (this.cols == 0 && this.charIndex == 0) {
                  this.spans.splice(this.rows, 0, []);
                } else if (
                  this.cols == this.spans[this.rows].length - 1 &&
                  this.charIndex ==
                    this.spans[this.rows][this.cols].value.length
                ) {
                  this.spans.splice(this.rows + 1, 0, []);
                  this.rows = this.rows + 1;
                  this.cols = 0;
                  this.charIndex = 0;
                  this.$nextTick(() => {
                    this.setCustorPosition(
                      this.rows,
                      this.cols,
                      this.charIndex
                    );
                  });
                } else {
                  do {
                    let obj = this.spans[this.rows].splice(this.cols, 1)[0];
                    newRow.push(obj);
                  } while (this.spans[this.rows].length > this.cols);
                  let str = newRow[0].value;
                  let obj = {
                    value: str.substr(0, this.charIndex),
                    type: newRow[0].type,
                  };
                  newRow[0].value = str.substring(this.charIndex);
                  this.$set(this.spans[this.rows], this.cols, obj);
                  this.spans.splice(this.rows + 1, 0, newRow);
                  this.rows = this.rows + 1;
                  this.cols = 0;
                  this.charIndex = 0;
                  this.$nextTick(() => {
                    this.setCustorPosition(
                      this.rows,
                      this.cols,
                      this.charIndex
                    );
                  });
                }
              }
            } else {
              this.$set(this, "spans", [[], []]);
              this.$nextTick(() => {
                let top =
                  document.querySelectorAll(".cm-pre")[this.spans.length - 1]
                    .offsetTop;
                this.top = top;
                this.left = 0;
                this.rows = this.spans.length - 1;
                this.cols = 0;
                this.charIndex = 0;
              });
            }
          }
        } else if (event.keyCode == 37) {
          // 向左
          if (!this.spans.length) {
            return;
          }
          if (this.rows == 0 && this.cols == 0 && this.charIndex == 0) {
            return;
          }

          let rows = this.rows;
          let cols = this.cols;
          let charIndex = this.charIndex - 1;
          if (charIndex <= 0) {
            cols = cols - 1;
            if (cols < 0) {
              rows = rows - 1;
              if (rows < 0) {
                rows = 0;
                cols = 0;
                charIndex = 0;
              } else {
                cols = this.spans[rows].length - 1;
                if (this.spans[rows][cols]) {
                  charIndex = this.spans[rows][cols].value.length;
                } else {
                  charIndex = 0;
                }
              }
            } else {
              if (this.spans[rows][cols]) {
                charIndex = this.spans[rows][cols].value.length;
              } else {
                charIndex = 0;
              }
            }
          }
          if (cols < 0) {
            cols = 0;
          }

          if (
            this.spans[rows] &&
            this.spans[rows][cols] &&
            fields.includes(this.spans[rows][cols].type) &&
            charIndex < this.spans[rows][cols].value.length
          ) {
            if (cols) {
              this.rows = rows;
              this.cols = cols - 1;
              this.charIndex = this.spans[rows][cols - 1].value.length;
            } else {
              this.rows = rows;
              this.cols = cols;
              this.charIndex = 0;
            }
          } else {
            this.rows = rows;
            this.cols = cols;
            this.charIndex = charIndex;
          }

          this.setCustorPosition(this.rows, this.cols, this.charIndex);
        } else if (event.keyCode == 39) {
          // 向右
          if (!this.spans.length) {
            return;
          }
          let rows = this.rows;
          let cols = this.cols;
          let charIndex = this.charIndex + 1;
          if (
            (this.spans[rows][cols] &&
              this.spans[rows][cols].value.length < charIndex) ||
            !this.spans[rows][cols]
          ) {
            if (this.spans[rows].length > cols + 1) {
              cols += 1;
              charIndex = 1;
            } else {
              if (this.spans.length > rows + 1) {
                rows += 1;
                cols = 0;
                charIndex = 1;
              } else {
                charIndex = charIndex - 1;
              }
            }
          }

          this.rows = rows;
          this.cols = cols;
          this.charIndex = charIndex;
          this.setCustorPosition(rows, cols, charIndex);
        } else if (event.keyCode == 38) {
          if (this.textType != "textarea") {
            return;
          }
          // 向上
          if (!this.spans.length) {
            return;
          }
          let $el = document.querySelectorAll(".cm-pre")[this.rows];
          if (this.top == 0) {
            return;
          }
          let rows = this.rows;
          let cols = 0;
          let charIndex = 0;
          if (rows > 0) {
            rows = rows - 1;
          }
          for (let i = 0; i <= rows; i++) {
            let top = document.querySelectorAll(".cm-pre")[i].offsetTop;

            if (this.spans[i].length == 0) {
              if (top == this.top - 20) {
                rows = i;
                cols = 0;
                charIndex = 0;
                break;
              }
            } else if (top <= this.top) {
              for (let j = 0; j < this.spans[i].length; j++) {
                let isBreak = false;
                for (let k = 0; k < this.spans[i][j].value.length; k++) {
                  let { left, top } = this.getPosition(i, j, k);

                  if (top == this.top - 20) {
                    if (this.charIndex == 0) {
                      rows = i;
                      cols = j;
                      charIndex = 0;
                      isBreak = true;
                      break;
                    } else if (
                      left >= this.left ||
                      left + 7 >= this.left ||
                      (j == this.spans[i].length - 1 &&
                        k == this.spans[i][j].value.length - 1)
                    ) {
                      rows = i;
                      cols = j;
                      charIndex = k + 1;
                      isBreak = true;
                      break;
                    }
                  }
                }
                if (isBreak) {
                  break;
                }
              }
            } else {
              rows = -1;
            }
          }

          if (rows >= 0) {
            this.rows = rows;
            this.cols = cols;
            this.charIndex = charIndex;
            this.setCustorPosition(rows, cols, charIndex);
          }
        } else if (event.keyCode == 40) {
          if (this.textType != "textarea") {
            return;
          }
          // 向下
          if (this.spans.length == 0) {
            return;
          }

          let $el = document.querySelectorAll(".cm-pre")[this.spans.length - 1];
          let height = $el.offsetHeight;
          let offsetTop = $el.offsetTop;
          if (this.top == offsetTop + (height / 20 - 1) * 20) {
            return;
          }

          let rows = this.rows;
          let cols = 0;
          let charIndex = 0;
          for (let i = rows; i < this.spans.length; i++) {
            if (this.spans[i].length == 0) {
              let top = document.querySelectorAll(".cm-pre")[i].offsetTop;

              if (top == this.top + 20) {
                rows = i;
                cols = 0;
                charIndex = 0;
                break;
              }
            } else {
              for (let j = 0; j < this.spans[i].length; j++) {
                let isBreak = false;
                for (let k = 0; k < this.spans[i][j].value.length; k++) {
                  let { left, top } = this.getPosition(i, j, k);
                  if (top == this.top + 20) {
                    if (this.charIndex == 0) {
                      rows = i;
                      cols = j;
                      charIndex = 0;
                      isBreak = true;
                      break;
                    } else if (
                      left >= this.left ||
                      left + 7 >= this.left ||
                      (j == this.spans[i].length - 1 &&
                        k == this.spans[i][j].value.length - 1)
                    ) {
                      rows = i;
                      cols = j;
                      charIndex = k + 1;
                      isBreak = true;
                      break;
                    }
                  }
                }
                if (isBreak) {
                  break;
                }
              }
            }
          }

          if (rows >= 0) {
            this.rows = rows;
            this.cols = cols;
            this.charIndex = charIndex;
            this.setCustorPosition(rows, cols, charIndex);
          }
        } else if (event.keyCode == 36) {
          // home
          let rows = this.rows;
          let arr = [];
          for (let i = 0; i < this.spans[rows].length; i++) {
            let isBreak = false;
            for (let j = 0; j < this.spans[rows][i].value.length; j++) {
              // const element = array[j];
              let { left, top } = this.getPosition(rows, i, j);

              if (top == this.top) {
                arr.push({
                  left,
                  cols: i,
                  charIndex: j,
                });
              }
            }
          }

          this.cols = arr[0].cols;
          this.charIndex = arr[0].charIndex;
          this.left = 0;
        } else if (event.keyCode == 35) {
          // end
          let rows = this.rows;
          let arr = [];
          for (let i = 0; i < this.spans[rows].length; i++) {
            for (let j = 0; j < this.spans[rows][i].value.length; j++) {
              let { left, top } = this.getPosition(rows, i, j + 1);
              if (top == this.top) {
                arr.push({
                  left,
                  cols: i,
                  charIndex: j + 1,
                });
              }
            }
          }
          this.cols = arr[arr.length - 1].cols;
          this.charIndex = arr[arr.length - 1].charIndex;
          this.left = arr[arr.length - 1].left;
        }
      }
    },
    input(v) {
      let scrollLeft = this.$el.scrollLeft;
      let obj;
      let rows = this.rows;
      let cols = this.cols;
      let charIndex = this.charIndex;
      let length = v.length;
      let type = this.operator.includes(v)
        ? "operator"
        : this.split.includes(v)
        ? "splice"
        : v == " "
        ? "space"
        : "val";
      let row = this.spans[rows];
      if (row) {
        let col = row[this.cols];
        if (col) {
          if (type == "splice" || type != col.type) {
            if (this.charIndex == col.value.length || this.charIndex == 0) {
              obj = {
                value: v,
                type,
              };
              if (this.charIndex == col.value.length) {
                cols += 1;
              }
              row.splice(cols, 0, obj);
              this.cols = cols;
              charIndex = length;
              this.charIndex = charIndex;
            } else {
              // 拆数据
              let value = col.value;
              let v1 = col.value.substr(0, charIndex);
              let v2 = col.value.substring(charIndex);
              col.value = v1;
              let obj = {
                value: v,
                type,
              };
              row.splice(cols + 1, 0, obj);
              row.splice(cols + 2, 0, {
                value: v2,
                type: col.type,
              });
              this.cols = cols + 1;
              this.charIndex = 1;
            }
          } else {
            let value = col.value;
            let arr = value.split("");
            arr.splice(charIndex, 0, v);
            obj = {
              value: arr.join(""),
              type,
            };
            charIndex += length;
            this.charIndex = charIndex;
            this.$set(row, cols, obj);
          }
        } else {
          obj = { value: v, type: type };
          this.$set(row, this.cols, obj);
          this.charIndex = length;
        }
      } else {
        obj = [{ value: v, type: type }];
        this.$set(this.spans, 0, obj);
        this.charIndex = length;
      }
      this.inputValue = "";

      this.$nextTick(() => {
        this.setCustorPosition(this.rows, this.cols, this.charIndex);
        // this.$emit("input", this.spans);
        setTimeout(() => {
          let left = scrollLeft + this.$el.clientWidth;

          let scrollLeft2 = this.left - left;

          if (scrollLeft2 > 0) {
            this.$el.scrollLeft = scrollLeft + scrollLeft2 + 3;
          } else {
            this.$el.scrollLeft = scrollLeft;
          }
        }, 1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rely-body {
  height: 100%;
  box-sizing: border-box;
  line-height: 20px;
  position: relative;
  font-size: 12px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  overflow-x: hidden;
  overflow-y: auto;

  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
    margin: 0px;
    font-size: inherit;
    font-family: inherit;
    font-weight: normal;
  }
}
.market {
  width: 0px;
  height: 20px;
  border-left: solid 1px #000;
  position: absolute;
  animation: fadeOut 1s infinite;
}
.cm-field {
  background: #ebf5ff;
  color: #008dcd;
  padding: 2px 4px;
  margin-right: 2px;
}
.cm-fun {
  color: #781287;
}
.cm-error {
  color: #f04134;
  background-color: rgba(254, 246, 246, 1);
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
