<template>
  <div>
    <el-dialog
      title="更新规则和日志"
      :visible.sync="dialogVisible"
      append-to-body
      destroy-on-close
      fullscreen
      @open="open"
    >
      <div style="padding: 10px">
        <!-- 更新规则 -->
        <el-card v-if="dataflow" class="box-card">
          <div slot="header" class="row-header">
            <div>更新规则</div>
            <el-button @click="logVisible = true" type="text"
              >更新日志</el-button
            >
          </div>
          <div class="row">
            <el-button
              type="primary"
              :loading="refreshLoading"
              @click="refreshData"
              size="mini"
              >手动更新</el-button
            >
          </div>
          <div class="row">
            <div style="font-size: 14px">自动更新</div>
            <el-switch
              v-model="dataflow.autoUpdated"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
              @change="changeAuto"
              style="margin: 0px 10px"
            ></el-switch>
            <div style="color: #91a1b7; font-size: 14px">
              输入源数据发生变动后自动更新，更新间隔为1小时
            </div>
          </div>
          <div class="row">
            <div style="font-size: 14px">定时更新</div>
            <el-switch
              v-model="dataflow.cronUpdated"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
              @change="changeCron"
              style="margin: 0px 10px"
            ></el-switch>
          </div>
          <div v-if="dataflow.cronUpdated">
            <timer-cron-trigger-setting
              v-model="timerCron"
              :executeEndTime="false"
              ref="triggerRef"
            ></timer-cron-trigger-setting>
            <div style="margin-top: 10px">
              <el-button type="primary" size="small" @click="save"
                >确定</el-button
              >
            </div>
          </div>
        </el-card>
      </div>
    </el-dialog>
    <el-dialog
      title="更新日志"
      :visible.sync="logVisible"
      append-to-body
      destroy-on-close
      fullscreen
      @open="openLog"
    >
      <div>
        <el-table :data="page.list" style="width: 100%">
          <el-table-column
            header-align="center"
            align="center"
            label="更新日期"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.startTime, "YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="开始时间"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.startTime, "HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="结束时间"
          >
            <template slot-scope="{ row }">
              <span>{{ getTime(row.endTime, "HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="更新时长"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="更新方式"
          >
            <template slot-scope="{ row }">
              <span>{{
                row.updateType == "autoUpdate"
                  ? "自动更新"
                  : row.updateType == "cronUpdate"
                  ? "定时更新"
                  : row.updateType == "manualUpdate"
                  ? "手动更新"
                  : row.updateType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="userName"
            label="操作人"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作结果"
          >
            <template slot-scope="{ row }">
              <span v-text="row.success ? '成功' : '失败'"></span>
            </template>
          </el-table-column>
        </el-table>
        <div
          v-if="page.totalPage > 1"
          style="height: 42px; background: #ffffff"
        >
          <pagination
            style="float: right"
            :total="page.totalRow"
            :page.sync="page.pageNumber"
            :limit.sync="page.pageSize"
            @pagination="loadmore"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  fetchListLog,
  getDataflow,
  refreshData,
  saveAutoUpdateRule,
  saveCronUpdatedRule,
} from "./api";
import timerCronTriggerSetting from "@/views/lowCode/assistant/timer-cron-trigger-setting";
import { isEmpty } from "@zgg-core-utils/utils";
import moment from "moment";

export default {
  components: { timerCronTriggerSetting },
  props: {
    visible: Boolean,
    dataflowId: [String, Number],
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  data() {
    return {
      dataflow: null,
      timerVisible: false,
      timerCron: null,
      logVisible: false,
      refreshLoading: false,
      page: {
        pageSize: 20,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
    };
  },
  methods: {
    getTime(value, format) {
      if (!isEmpty(value)) {
        return moment(value).format(format);
      }
    },
    open() {
      getDataflow(this.dataflowId).then((res) => {
        this.dataflow = res.data.dataflow;
        this.timerCron = this._.cloneDeep(this.dataflow.timerCron);
      });
    },
    openLog() {
      this.loadingList(1);
    },
    changeCron(val) {
      let isSave = true;
      if (val) {
        if (isEmpty(this.timerCron)) {
          this.timerCron = {
            key: this.getUuid(),
            type: "timer_once",
            title: "",
          };
          isSave = false;
        }
      }

      if (isSave) {
        let data = {
          dataflowId: this.dataflowId,
          enabled: val,
        };
        if (val) {
          data.timerCron = this.dataflow.timerCron;
        }
        saveCronUpdatedRule(data).then((res) => {
          if (data.timerCron) {
            this.timerCron = this._.cloneDeep(this.dataflow.timerCron);
          }
        });
      }
    },
    changeAuto(val) {
      saveAutoUpdateRule({
        enabled: val,
        dataflowId: this.dataflowId,
      }).then(() => {});
    },
    async save() {
      let valid = await this.$refs.triggerRef.validateForm().catch((err) => {
        return err;
      });
      if (!valid) {
        this.$message.error("请检查配置必填配置");
        return;
      }
      saveCronUpdatedRule({
        dataflowId: this.dataflowId,
        enabled: true,
        timerCron: this.timerCron,
      }).then((res) => {
        console.log(res);
      });
    },
    loadmore({ page, limit }) {
      this.page.pageSize = limit;
      this.loadingList(page);
    },
    refreshData() {
      this.refreshLoading = true;
      refreshData(this.dataflowId).then((res) => {
        this.refreshLoading = false;
        this.$message.success("数据刷新成功");
      });
    },
    loadingList(pageNumber = 1) {
      fetchListLog({
        dataflowId: this.dataflowId,
        pageNumber,
        pageSize: this.page.pageSize,
      }).then((res) => {
        let page = res.data.page;
        this.page.pageSize = page.pageSize;
        this.page.totalRow = page.totalRow;
        this.page.totalPage = page.totalPage;
        this.page.pageNumber = page.pageNumber;
        this.page.list = page.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.row-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>