<template>
  <div class="chart-container">
    <div class="chart-picker" v-if="address">
      <div class="chart-address">
        <div
          class="address-item active"
          v-if="address.value"
          v-text="address.label"
          @click="changeMap('all')"
        ></div>
        <div class="address-split" v-if="address.value && address.province">
          /
        </div>
        <div
          class="address-item"
          :class="{ active: address.cityId }"
          v-if="address.provinceId"
          v-text="address.province"
          @click="changeMap('province')"
        ></div>
        <div class="address-split" v-if="address.province && address.city">
          /
        </div>
        <div
          class="address-item"
          :class="{ active: address.countyId }"
          v-if="address.cityId"
          v-text="address.city"
          @click="changeMap('city')"
        ></div>
        <div class="address-split" v-if="address.city && address.county">/</div>
        <div
          class="address-item"
          v-if="address.countyId"
          v-text="address.county"
        ></div>
      </div>
    </div>
    <div ref="chart" class="chart-bar"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { _eq } from "@zgg-core-utils/relyUtil";
import { getGeoJson, getAggreation } from "../api";
import { chartColors, textColorArr } from "./chartUtil";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";

export default {
  mixins: [chartResize],
  props: {
    view: Object,
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapJson: {},
      registerMap: [],
      viewObj: null,
      picker: "",
      currAreaId: "",
      address: null,
    };
  },
  watch: {
    view: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!_eq(val, this.viewObj)) {
          this.currAreaId = "";
          this.picker = "";
          this.viewObj = this._.cloneDeep(val);
          this.address = null;
        }
      },
    },
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    areaId() {
      if (this.currAreaId) {
        return this.currAreaId;
      }
      if (this.view && this.view.mapRange) {
        if (this.view.mapRange.countyId) {
          return this.view.mapRange.countyId;
        } else if (this.view.mapRange.cityId) {
          return this.view.mapRange.cityId;
        } else if (this.view.mapRange.provinceId) {
          return this.view.mapRange.provinceId;
        }
      }
      return "all";
    },
    area() {
      let index = this.areaTree.findIndex((item) => item.id == this.areaId);
      if (index >= 0) {
        return this._.cloneDeep(this.areaTree[index]);
      }
      let obj;
      for (let i = 0; i < this.areaTree.length; i++) {
        let item = this.areaTree[i];
        if (item.id == this.areaId) {
          obj = this._.cloneDeep(item);
          break;
        }
        for (let j = 0; j < item.children.length; j++) {
          const element = item.children[j];
          if (element.id == this.areaId) {
            obj = this._.cloneDeep(obj);
            break;
          }
          for (let k = 0; k < element.children.length; k++) {
            const child = element.children[k];
            if (child.id == this.areaId) {
              obj = this._.cloneDeep(child);
              break;
            }
          }
          if (obj) {
            break;
          }
        }
        if (obj) {
          break;
        }
      }
      return obj;
    },
  },
  methods: {
    changeMap(type) {
      if (type == "all") {
        this.currAreaId = "";
        this.picker = "province";
        this.address = null;
      } else if (type == "province") {
        if (!this.address.cityId) {
          return;
        }
        this.currAreaId = this.address.provinceId;
        if (this.viewObj.mapRange.provinceId && !this.viewObj.mapRange.cityId) {
          this.address = null;
        } else {
          let address = this._.cloneDeep(this.address);
          delete address.cityId;
          delete address.city;
          delete address.countyId;
          delete address.county;
          this.address = address;
        }

        this.picker = "city";
      } else if (type == "city") {
        if (!this.address.countyId) {
          return;
        }
        this.currAreaId = this.address.cityId;
        let address = this._.cloneDeep(this.address);

        delete address.countyId;
        delete address.county;
        this.address = address;
        this.picker = "county";
      }
      this.getData(this._.cloneDeep(this.viewObj));
    },
    async getData(view) {
      if (!(view.metrics && view.metrics.length)) {
        this.buildChart(view);
        return;
      }
      if (!(view.xFields && view.xFields.length)) {
        this.buildChart(view);
        return;
      }
      let hasChild;
      // 基于准备好的dom，初始化echarts实例
      if (!this.mapJson[this.areaId]) {
        let geoData = await getGeoJson(this.areaId);
        this.mapJson[this.areaId] = {
          geoJson: geoData.geoJson,
          hasChild: geoData.hasChild,
        };
        echarts.registerMap(this.areaId, geoData.geoJson);
      } else {
        hasChild = this.mapJson[this.areaId].hasChild;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        queryObject = view.dataSource.queryObject;
      }
      // if (view.dataSource.queryObject && view.dataSource.queryObject.pageSize) {
      //   queryObject.pageSize = view.dataSource.queryObject.pageSize;
      // }

      queryObject.xFields = this._.cloneDeep(view.xFields);
      if (!this.picker) {
        if (view.mapRange.cityId) {
          this.picker = "county";
          if (!hasChild) {
            this.picker = "city";
          }
        } else if (view.mapRange.provinceId) {
          this.picker = "city";
          if (!hasChild) {
            this.picker = "province";
          }
        } else {
          this.picker = "province";
        }

        if (
          ["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
            view.mapRange.provinceId,
          )
        ) {
          this.picker = "county";
        }
      }

      queryObject.xFields[0].addressPrecision = {
        picker: this.picker,
      };

      queryObject.metrics = view.metrics;
      let arr = [];
      arr.push(
        getAggreation({
          collection: view.dataSource.formId,
          queryObject: JSON.stringify(queryObject),
        }),
      );

      if (
        !["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
          this.areaId,
        ) &&
        this.areaId != "all" &&
        this.area &&
        this.area.type == 10 &&
        this.area.children.findIndex(
          (opt) =>
            opt.extAttr &&
            opt.extAttr.code &&
            opt.extAttr.code.indexOf("_EMPTY") >= 0,
        )
      ) {
        console.log("直辖");
        queryObject.xFields[0].addressPrecision = {
          picker: "county",
        };
        arr.push(
          getAggreation({
            collection: view.dataSource.formId,
            queryObject: JSON.stringify(queryObject),
          }),
        );
      }

      Promise.all(arr).then((result) => {
        let res = result[0];
        let res2 = result[1];

        let list;

        let xFieldName = view.xFields[0].field.name;

        if (
          ["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
            this.areaId,
          )
        ) {
          list = res.data.list.filter(
            (item) =>
              item[xFieldName] && item[xFieldName].provinceId == this.areaId,
          );
        } else if (this.picker == "city") {
          list = res.data.list.filter(
            (item) =>
              item[xFieldName] && item[xFieldName].provinceId == this.areaId,
          );
        } else if (this.picker == "county") {
          list = res.data.list.filter(
            (item) =>
              item[xFieldName] && item[xFieldName].cityId == this.areaId,
          );
        } else {
          list = res.data.list;
        }

        if (res2) {
          let obj = this.area.children.find(
            (item) =>
              item.extAttr &&
              item.extAttr.code &&
              item.extAttr.code.indexOf("_EMPTY") >= 0,
          );
          if (obj) {
            let ids = obj.children.map((item) => item.id);
            let list2 = res2.data.list.filter(
              (item) =>
                item[xFieldName] &&
                item[xFieldName].provinceId == this.areaId &&
                ids.includes(item[xFieldName].countyId),
            );
            list = list.concat(list2);
          }
        }

        this.list = list;

        this.buildChart(view);
        if (!this.viewObj) {
          this.viewObj = this._.cloneDeep(view);
        }
      });
    },
    async buildChart(view) {
      let textColor = "black";
      let borderColor = "black";
      let backgroundColor =
        view?.colorConfig?.backgroundColor || view?.colorConfig?.backgroundImage
          ? "transparent"
          : "#eeeeee";
      if (view?.colorConfig?.contentColor) {
        textColor =
          view?.colorConfig?.contentColor &&
          !view?.colorConfig?.contentColor.includes("rgba")
            ? textColorArr[view?.colorConfig?.contentColor]
            : view?.colorConfig?.contentColor; // 文字颜色设置
      }
      if (view?.colorConfig?.borderColor) {
        borderColor =
          view?.colorConfig?.borderColor &&
          !view?.colorConfig?.borderColor.includes("rgba")
            ? textColorArr[view?.colorConfig?.borderColor]
            : view?.colorConfig?.borderColor; // 文字颜色设置
      }
      if (this.isH5) {
        textColor = "black";
        backgroundColor = "#eeeeee";
        borderColor = "black";
      }
      if (!this.picker) {
        if (view.mapRange.cityId) {
          this.picker = "county";
        } else if (view.mapRange.provinceId) {
          this.picker = "city";
        } else {
          this.picker = "province";
        }

        if (
          ["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
            view.mapRange.provinceId,
          )
        ) {
          this.picker = "county";
        }
      }
      // 基于准备好的dom，初始化echarts实例
      if (!this.mapJson[this.areaId]) {
        let geoData = await getGeoJson(this.areaId);
        this.mapJson[this.areaId] = {
          geoJson: geoData.geoJson,
          hasChild: geoData.hasChild,
        };
        echarts.registerMap(this.areaId, geoData.geoJson);
      }

      let xFieldName = view.xFields[0].field.name;
      let name = view.metrics[0].name;
      let fieldName = name;
      if (view.metrics[0].numberFormat) {
        fieldName += "Format";
      }

      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.chart);

        this.myChart.on("click", async (params) => {
          if (view.mapDrill == "disabled") {
            // 不可钻取
            return;
          }
          if (this.picker == "county") {
            // 数据已经钻取到区县一级
            return;
          }
          if (this.picker == "city" && view.mapDrill == "province") {
            // 可钻取到省，并且数据已经取到市一级
            return;
          }
          if (
            ["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
              this.areaId,
            )
          ) {
            // 北京、天津、上海、重庆、台湾、香港、澳门没有细化到市级
            return;
          }

          // if (params.componentSubType == "map") {
          if (this.picker == "province") {
            // 点击省份
            let obj = this.list.find(
              (item) =>
                item[xFieldName] && item[xFieldName].province == params.name,
            );
            if (obj) {
              this.currAreaId = obj[xFieldName].provinceId;
              if (
                ["1", "22", "865", "2476", "3521", "3522", "3523"].includes(
                  this.currAreaId,
                )
              ) {
                this.picker = "county";
              } else {
                this.picker = "city";
              }

              this.getData(this._.cloneDeep(this.viewObj));
              let address = this._.cloneDeep(obj[xFieldName]);
              this.address = {
                label: "中国",
                value: "all",
                provinceId: address.provinceId,
                province: address.province,
              };
            }
          } else if (this.picker == "city") {
            // 点击城市
            let obj = this.list.find(
              (item) =>
                item[xFieldName] && item[xFieldName].city == params.name,
            );

            if (obj) {
              this.currAreaId = obj[xFieldName].cityId;
              this.picker = "county";
              this.getData(this._.cloneDeep(this.viewObj));
              if (this.address) {
                this.$set(this.address, "cityId", obj[xFieldName].cityId);
                this.$set(this.address, "city", obj[xFieldName].city);
              } else {
                this.address = {
                  provinceId: obj[xFieldName].provinceId,
                  province: obj[xFieldName].province,
                  cityId: obj[xFieldName].cityId,
                  city: obj[xFieldName].city,
                };
              }
            } else {
              let opt = this.area.children.find(
                (item) =>
                  item.extAttr &&
                  item.extAttr.code &&
                  item.extAttr.code.indexOf("_EMPTY") >= 0,
              );
              if (opt) {
                obj = opt.children.find((item) => item.name == params.name);

                // this.mapJson[this.areaId] = {
                //   geoJson: geoData.geoJson,
                //   hasChild: geoData.hasChild,
                // };
                // echarts.registerMap(this.areaId, geoData.geoJson);
              }
            }
          }
          // } else if (params.componentSubType == "scatter") {
          //   console.log(params);
          // } else if (params.componentType == "geo") {
          //   console.log(params);
          // }
          // console.log(params);
        });
      }

      let nameMap = {};

      let min = 0;
      let max = 0;
      if (this.list.length) {
        min = Math.min(...this.list.map((item) => item[name]));
        max = Math.max(...this.list.map((item) => item[name]));
        if (min && min == max) {
          if (min > 0) {
            min = 0;
          } else {
            max = 0;
          }
        }
      }

      let option = {
        color: chartColors,
        label: {
          show: view.chartLabel.enable,
        },
        tooltip: {
          show: false,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // borderColor: "transparent",
          borderWidth: 0,
          textStyle: {
            color: "#ffffff",
          },
        },

        visualMap: {
          min,
          max,
          text: ["High", "Low"],
          realtime: false,
          calculable: true,
          inRange: {
            color: ["#5CDBD3", "#FFF566", "#FF7875"],
          },
          textStyle: {
            color: textColor,
          },
        },
      };

      if (view.mapType == "area") {
        let data = [];

        this.list.forEach((item) => {
          let text;

          let obj = item[xFieldName];
          if (obj) {
            if (obj.county) {
              text = obj.county;
            } else if (obj.city) {
              text = obj.city;
            } else {
              text = obj.province;
            }

            let label = {
              show: view.chartLabel.enable,
            };
            let formatter = "";
            if (label.show) {
              if (view.chartLabel.displayXField) {
                formatter += "{b}";
              }
              if (view.chartLabel.displayMetric) {
                if (formatter != "") {
                  formatter += "\n";
                }
                formatter += item[fieldName];
              }
            }
            label.formatter = formatter;
            data.push({
              name: text,
              value: item[name],
              label: label,
              tooltip: {
                show: true,
                formatter: `{b}<br/>${view.metrics[0].title}:{c}`,
              },
            });
            if (!view.mapRange.provinceId) {
              if (item[xFieldName].province == "北京") {
                if (!nameMap["北京市"]) {
                  nameMap["北京市"] = "北京";
                }
              }
              if (item[xFieldName].province == "天津") {
                if (!nameMap["天津市"]) {
                  nameMap["天津市"] = "天津";
                }
              }
              if (item[xFieldName].province == "上海") {
                if (!nameMap["上海市"]) {
                  nameMap["上海市"] = "上海";
                }
              }
              if (item[xFieldName].province == "重庆") {
                if (!nameMap["重庆市"]) {
                  nameMap["重庆市"] = "重庆";
                }
              }
              if (item[xFieldName].province == "香港") {
                if (!nameMap["香港特别行政区"]) {
                  nameMap["香港特别行政区"] = "香港";
                }
              }
              if (item[xFieldName].province == "澳门") {
                if (!nameMap["澳门特别行政区"]) {
                  nameMap["澳门特别行政区"] = "澳门";
                }
              }
            }
          }
        });

        option.nameMap = nameMap;
        option.series = {
          type: "map",
          map: this.areaId,
          roam: true,
          // selectedMode: "multiple",
          select: {
            disabled: true,
          },
          scaleLimit: {
            min: 1,
            max: 6,
          },
          label: {
            normal: {
              textStyle: {
                color: textColor,
              },
            },
          },
          itemStyle: {
            normal: {
              areaColor: backgroundColor, // 设置地图形状的颜色
              borderColor: borderColor, // 设置地图形状的边框颜色
            },
          },
          // silent: true,
          data,
        };
      } else {
        let geoMap = this.mapJson[this.areaId].geoJson;

        let data = [];
        this.list.forEach((item) => {
          let text;
          let obj = item[xFieldName];
          if (obj.county) {
            text = obj.county;
          } else if (obj.city) {
            text = obj.city;
          } else {
            text = obj.province;
            if (text == "北京") {
              text = "北京市";
            }
            if (text == "天津") {
              text = "天津市";
            }
            if (text == "上海") {
              text = "上海市";
            }
            if (text == "重庆") {
              text = "重庆市";
            }
            if (text == "香港") {
              text = "香港特别行政区";
            }
            if (text == "澳门") {
              text = "澳门特别行政区";
            }
          }
          let point = geoMap.features.find(
            (item) => item.properties.name == text,
          );

          let label = {
            show: view.chartLabel.enable,
          };
          let formatter = "";
          if (label.show) {
            if (view.chartLabel.displayXField) {
              formatter += "{b}";
            }
            if (view.chartLabel.displayMetric) {
              if (formatter != "") {
                formatter += "\n";
              }

              formatter += item[fieldName];
            }
          }
          label.formatter = formatter;

          let center = point.properties.center ?? [];

          data.push({
            value: [...center, item[name]],

            name: text,
            label: label,
            tooltip: {
              show: true,
              formatter: `{b}<br/>${view.metrics[0].title}:${item[name]}`,
            },
          });
        });

        option.nameMap = nameMap;

        option.geo = {
          tooltip: {
            show: false,
          },
          map: this.areaId,
          roam: true,
          scaleLimit: {
            min: 1,
            max: 8,
          },
        };

        option.series = {
          type: "scatter",
          coordinateSystem: "geo",
          geoIndex: 0,
          // silent: true,
          symbolSize: function (params) {
            let x = Math.abs(min);
            if (x > Math.abs(max)) {
              x = Math.abs(max);
            }
            if (x == 0) {
              x = 100;
            }
            let r = Math.abs((params[2] / x) * 4 + 5);
            if (r < 15) {
              r = 15;
            } else if (r > 35) {
              r = 35;
            }
            return r;
          },
          itemStyle: {
            color: "#b02a02",
          },
          encode: {
            tooltip: 2,
          },
          data: data,
        };
      }

      this.myChart.setOption(option, {
        notMerge: true,
      });

      this.setChartResize();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.chart-bar {
  flex: 1;
}
.chart-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  .chart-address {
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px rgba($color: #000000, $alpha: 0.2);
    padding: 10px;
    .address-item,
    .address-split {
      font-size: 12px;
      &.active {
        color: #409eff;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .address-split {
      margin: 0 5px;
    }
  }
}
</style>
