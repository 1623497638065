<template>
  <div>
    <el-input
      v-model="tableName"
      @click.native="dialogVisible = true"
      placeholder="请选择联动表单"
    ></el-input>
    <el-dialog
      title="数据联动"
      :visible.sync="dialogVisible"
      width="800px"
      append-to-body
      destroy-on-close
      @open="open"
      @closed="closed"
    >
      <div
        style="
          padding: 5px 20px;
          height: 500px;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
        "
        v-loading.lock="loading"
      >
        <el-input
          v-model="keyWord"
          @input="filter"
          placeholder="请输入搜索关键字"
          clearable
        ></el-input>

        <el-scrollbar style="flex: 1" class="work-tree-container">
          <el-tree
            :key="lazy ? 'lazy' : 'search'"
            v-if="isShow"
            ref="formTree"
            :data="treeData"
            :highlight-current="true"
            :auto-expand-parent="true"
            node-key="id"
            :props="defaultProps"
            :default-expand-all="!lazy"
            class="my-work-tree"
            :expand-on-click-node="true"
            :accordion="false"
            :indent="10"
            @node-click="handleNodeClick"
            :draggable="true"
            :lazy="lazy"
            :load="loadMoreNode"
          >
            <div class="node-wrap" slot-scope="{ node, data }">
              <svg-icon
                iconClass="wenjianjia"
                v-if="data.dataType == 'folder'"
                style="margin-right: 5px; font-size: 16px"
              ></svg-icon>
              <i
                class="iconfont icon-biaodanqun"
                style="color: #b29ae8"
                v-if="data.dataType == 'resource'"
              ></i>
              <div class="node-label-wrap">
                <div class="node-label-content">
                  {{ node.label }}
                </div>
              </div>
              <div class="node-button-manage">
                <slot name="more" :node="node" :data="data"> </slot>
              </div>
            </div>
          </el-tree>
        </el-scrollbar>
      </div>

      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="save" size="small">确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dirTree } from "@/views/lowCode/api";
import { getFormDetail } from "@/views/lowCode/form/api";
import { isEmpty } from "@zgg-core-utils/relyUtil";

const RESOURCE = "resource";
const FOLDER = "folder";
export default {
  name: "tree",
  components: {},
  data() {
    return {
      defaultProps: {
        children: "directoryList",
        label: "title",
        isLeaf: function (data, node) {
          return !data.hasChildList || data.dataType == "resource";
        },
      },
      treeData: [],
      dialogVisible: false,
      tableName: "",
      keyWord: "",
      currentFormData: undefined,
      loading: false,
      lazy: true,
      isShow: true,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      async handler(val, oldVal) {
        if (!val) return;
        let res = await getFormDetail(val);
        this.tableName = res.data.form.title;
      },
    },
  },
  props: {
    type: {
      type: [String, Number],
      default: 10,
    },
    value: [String],
  },
  computed: {},
  methods: {
    async loadMoreNode(node, resolve) {
      if (this.lazy) {
        let parentId = 0;
        if (node.level !== 0) {
          parentId = node.data.id;
        }
        let params = {
          type: this.type,
          parentId,
        };
        await dirTree(params).then((resp) => {
          return resolve(resp.data.directoryList);
        });
      }
    },
    filter(val) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.keyWord = val;
        this.lazy = isEmpty(val);
        if (!this.lazy) {
          this.fetchTree();
        }

        this.isShow = false;
        this.$nextTick(() => {
          this.isShow = true;
        });
      }, 500);

      // this.$refs.formTree.filter();
    },
    async open() {
      this.loading = true;
      // await this.fetchTree();
      // this.$refs.formTree.filter();
      this.loading = false;
    },
    closed() {},
    /**
     * 树过滤
     * @param value
     * @param data
     * @returns {boolean}
     */
    filterNode(value, data) {
      let { dataType, title, bizId } = data;
      if (title.indexOf(this.keyWord) == -1) {
        return false;
      }
      if (bizId == this.value) {
        return false;
      }
      return true;
    },
    //参数有三种情况：'prev'、'inner' 和 'next'，
    async fetchTree(parentId = 0) {
      let params = {
        type: this.type,
        parentId,
        fullTree: true,
        keyword: this.keyWord,
      };
      await dirTree(params)
        .then((resp) => {
          this.treeData = resp.data.directoryList;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleNodeClick(data, node) {
      if (data.bizId != 0) {
        this.currentFormData = data;
      } else {
        this.currentFormData = undefined;
      }
    },
    save() {
      if (!this.currentFormData) {
        this.$message.warning("请选择一个表单");
        return;
      }
      let bizId = this.currentFormData.bizId;
      if (bizId != this.value) {
        this.$emit("change", bizId);
        this.$emit("input", bizId);
      }
      this.dialogVisible = false;
    },
  },

  mounted() {},
};
</script>

<style lang="scss">
.my-work-tree {
  width: calc(100% - 10px);

  .el-tree-node {
    line-height: normal;
  }

  .el-tree-node__content {
    height: 100%;
    line-height: 35px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
  }

  .el-tree-node__content > .el-tree-node__expand-icon {
    display: flex;
    align-items: center;
  }

  .el-tree-node__expand-icon.expanded {
    transform-origin: center;
  }

  .el-tree-node__content:hover {
    .node-button-manage {
      display: unset;
    }
  }

  .el-tree-node.is-current {
    .node-label-content:first-of-type {
      //font-weight: 700 !important;
      //font-size: 13px !important;
      //color: #348fe4 !important;
    }
  }

  .el-tree-node.is-current {
    background-color: #f0f7ff;
    border-radius: 5px;
  }

  .node-wrap {
    line-height: 36px;
    height: 36px;
    font-size: 15px;
    vertical-align: middle;
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    flex: 1;
    align-items: center;
    position: relative;

    .node-label-wrap {
      flex: 1;
      width: 0;

      .node-label-content {
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 13px;
        line-height: 20px;
        color: rgba(9, 30, 66, 0.95);
      }
    }
  }

  .node-button-manage {
    font-style: inherit;
    display: none;

    .el-button [class*="el-icon-"] + span {
      margin-left: 0;
    }
  }

  .el-form-item__content {
    line-height: normal !important;
  }

  .el-tree-node:focus > .el-tree-node__content {
    /*background-color: #409EFF;*/
    /*margin-bottom: 1px;*/
  }

  .el-icon-caret-right:before {
    content: "\e6e0";
    /*color: #001528;*/
  }

  .el-tree-node.is-drop-inner > .el-tree-node__content .node-wrap {
    background-color: #409eff !important;
    color: #fff !important;
    border-radius: 2px;
    padding-left: 10px;
  }

  .el-tree__drop-indicator {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
  }

  .el-tree-node.is-expanded > .el-tree-node__children {
    display: contents;
  }
}
</style>
