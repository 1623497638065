<template>
  <div class="find-pwd">
    <div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        "
      >
        <span
          style="
            font-size: 24px;
            line-height: 36px;
            color: rgba(9, 30, 66, 0.95);
            font-weight: 700;
          "
          >欢迎加入{{ environment.platformName }}</span
        >
        <el-button plain size="medium" @click="handleLoginRoute"
          >登录</el-button
        >
      </div>
      <div class="login-tab-change">
        <div class="tab-item active">找回密码</div>
      </div>
      <el-form
        ref="codeLoginForm"
        :model="codeLoginForm"
        :rules="codeLoginRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
        style="width: 100%"
      >
        <el-form-item prop="mobile">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            v-model.number="codeLoginForm.mobile"
            placeholder="请输入手机号"
            name="mobile"
            type="tel"
            tabindex="1"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            auto-complete="on"
          />
        </el-form-item>
        <el-form-item prop="code">
          <div style="display: flex; flex-direction: row">
            <div style="flex: 1">
              <el-input
                prefix-icon="el-icon-message"
                v-model="codeLoginForm.code"
                placeholder="请输入验证码"
                name="code"
                tabindex="2"
                onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
              />
            </div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <span type="text" v-if="codeCount !== 0" class="code-count-span"
                >{{ codeCount }}s</span
              >
              <el-button
                type="text"
                v-if="codeCount === 0"
                @click="handleGetLoginCode"
                class="code-count-span"
                >获取验证码
              </el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            prefix-icon="el-icon-lock"
            :key="passwordType"
            ref="password"
            v-model="codeLoginForm.newPassword"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @focus="showCard"
            @blur="hideCard"
          />
        </el-form-item>
        <!-- 校验密码卡片 -->
        <el-card
          class="box-card"
          v-if="isShowCard && codeLoginForm.newPassword"
        >
          <div class="box-card-item">
            <i
              class="iconfont icon-wanchengzhuangtai1"
              :class="{
                successPwd:
                  this.codeLoginForm.newPassword.length >= 8 &&
                  this.codeLoginForm.newPassword.length < 30,
              }"
            ></i>
            <span>密码长度为8~30个字符</span>
          </div>
          <div class="box-card-item">
            <i
              class="iconfont icon-wanchengzhuangtai1"
              :class="{ successPwd: includeNum }"
            ></i>
            <span>至少包含一个数字</span>
          </div>

          <div class="box-card-item">
            <i
              class="iconfont icon-wanchengzhuangtai1"
              :class="{ successPwd: includeLower }"
            ></i>
            <span>至少包含一个小写字母</span>
          </div>
          <div class="box-card-item">
            <i
              class="iconfont icon-wanchengzhuangtai1"
              :class="{ successPwd: includeUpper }"
            ></i>
            <span>至少包含一个大写字母</span>
          </div>
        </el-card>
        <el-button
          :loading="loading"
          type="primary"
          class="login-button"
          @click="handleCodeLogin"
          >确定
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getVerCode, resetPwd } from "@/api/user";

export default {
  name: "Login",

  data() {
    const validatePassword = (rule, value, callback) => {
      const codeP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,30}$/;
      if (value.length === 0) {
        callback(new Error("密码不能为空"));
      } else if (value.length < 8 || value.length > 30) {
        callback(new Error("密码只能在8~30位！"));
      } else if (!codeP.test(value)) {
        callback(new Error("密码格式不对！"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      const codeP = /^[0-9]*$/;
      if (!codeP.test(value)) {
        callback(new Error("验证码只能为数字"));
      } else {
        if (value.length !== 4) {
          this.validateCodeOk = false;
          callback(new Error("请输入正确的验证码"));
        } else {
          this.validateCodeOk = true;
          callback();
        }
      }
    };

    const validatePhone = (rule, value, callback) => {
      if (value.toString().length !== 11) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        loginName: "",
        password: "",
      },

      codeLoginForm: {
        mobile: "",
        code: "",
        sid: "",
        newPassword: "",
      },
      codeLoginRules: {
        mobile: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
        newPassword: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      regForm: {
        name: "",
        orgName: "",
        password: "",
        confirmPassword: "",
      },
      regRules: {},

      loading: false,
      passwordType: "password",
      redirect: undefined,

      codeCount: 0,
      // 控制校验密码卡片显示隐藏
      isShowCard: false,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  computed: {
    // 判断密码是否包含数字
    includeNum() {
      const regNumber = /\d/;
      return regNumber.test(this.codeLoginForm.newPassword);
    },
    // 判断密码是否包含大写字母
    includeUpper() {
      const regUpper = /[A-Z]/;
      return regUpper.test(this.codeLoginForm.newPassword);
    },
    // 判断密码是否包含小写字母
    includeLower() {
      const regLower = /[a-z]/;
      return regLower.test(this.codeLoginForm.newPassword);
    },
    passwordLength() {
      return this.codeLoginForm.newPassword.length;
    },
  },
  methods: {
    // 获取焦点显示卡片
    showCard() {
      this.isShowCard = true;
    },

    // 失去焦点隐藏卡片
    hideCard() {
      this.isShowCard = false;
    },

    //处理登录点击事件
    handleLoginRoute() {
      this.$router.push({
        name: "登录",
        params: { mobile: this.codeLoginForm.mobile },
      });
    },
    back() {
      this.$router.back();
    },

    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    //点击获取验证码按钮
    handleGetLoginCode() {
      //判断已经输入手机号
      //todo 需要做前端手机号的校验，目前先不加，可能需要用非标准的呃手机号和验证码进行测试
      //todo 将手机号发给后台，发送短信验证码
      let data = {
        mobile: this.codeLoginForm.mobile,
      };
      getVerCode(data).then((resp) => {
        let { seconds, sid } = resp.data;
        this.codeCount = seconds;
        this.codeLoginForm.sid = sid;
        let timer = setInterval(() => {
          if (this.codeCount === 0) {
            clearInterval(timer);
          } else {
            this.codeCount -= 1;
          }
        }, 1000);
      });
    },
    handleCodeLogin() {
      this.$refs.codeLoginForm.validate((valid) => {
        if (valid) {
          //如果未获取验证码，提示并不允许提交
          if (!this.codeLoginForm.sid) {
            this.$message.warning("先获取验证码");
            return false;
          }
          this.loading = true;
          resetPwd(this.codeLoginForm)
            .then((resp) => {
              this.$message.success("密码重置成功");
              this.$router.push({ path: "/login" });

              this.loading = false;
            })
            .catch((error) => {
              // this.$message({type: "error", message: error.message})
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    let mobile = this.$route.params.mobile;
    if (mobile) {
      this.codeLoginForm.mobile = mobile;
    }
  },
};
</script>

<style lang="scss">
.find-pwd {
  background-color: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  & > div {
    width: 420px;
    border-radius: 10px;
    background-color: #fff;
    padding: 0 50px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
      0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  }
}

.tab-item.active {
  color: rgba(9, 30, 66, 0.95);
  position: relative;
  margin: 15px 0;
}

.tab-item.active::after {
  position: absolute;
  content: "";
  width: 24.23px;
  height: 4px;
  background-color: #3488ff;
  border-radius: 13px;
  bottom: -5px;
  left: 0;
}

.code-count-span {
  color: #3488ff;
  position: absolute;
  top: 0;
  right: 10px;
}
.login-button {
  width: 100%;
}
.box-card {
  position: absolute;
  top: 67.5%;
  transform: translateY(-30%);
}
.el-card__body {
  padding: 10px;

  .box-card-item i {
    color: #091e4229;
  }

  .box-card-item span {
    font-size: 12px;
    line-height: 20px;
  }
}

.successPwd {
  color: #52c41a !important;
}
</style>
