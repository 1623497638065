<template>
  <div class="weui-row">
    <div v-if="parentNodes.length == 0" style="font-size: 14px; color: #f56c6c">
      暂无可用上级节点，请切换其他的数据类型
    </div>
    <template v-else>
      <el-select
        v-model="nodeField.nodeKey"
        placeholder="请选择节点"
        size="small"
        style="width: 220px; margin-right: 10px"
        @change="changeNokeKey"
        :disabled="disabled"
        :class="{ 'is-error': isDeleteNode }"
      >
        <el-option
          v-for="item in parentNodes"
          :key="item.key"
          :label="getLabel(item)"
          :value="item.key"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="nodeField.name"
        placeholder="请选择"
        size="small"
        class="row-flex"
        :class="{ 'is-error': isDelete }"
        :disabled="disabled"
        @change="changeField"
        clearable
      >
        <el-option
          v-for="item in componentList"
          :key="item.name"
          :label="item.title"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </template>
  </div>
</template>
<script>
import { buildJSONParserComponents, fromNodesMixins } from "./rpa-utils";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { fetchMetaComponents } from "@/api/form";
import { mapGetters } from "vuex";
import { isEmpty } from "@zgg-core-utils/utils";
import { getRobotWorkflow } from "./api";
import { conponents as componentArray } from "@/views/lowCode/form/const";

const allowPluginReturn = [
  "text_area",
  "input",
  "input_number",
  "select",
  "radio_group",
  "checkbox_group",
  "select_checkbox",
  "date_picker",
];

export default {
  mixins: [fromNodesMixins],
  inject: {
    getRootParams: {
      default: () => [],
    },
  },

  props: {
    /** 节点字段 */
    nodeField: Object,
    /** 目标表字段 */
    tableField: Object,
    isAnyComponentName: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /** 获取组件可用配置的key */
    fieldConfigKey: String,
    disabled: Boolean,
    /** 基于多条记录逐条新增记录时才有的多条记录节点的key */
    baseNodeKey: String,
    oper: String,
    formId: String,
    hasBaseRootParam: {
      type: Boolean,
      default() {
        return true;
      },
    },
    /** 节点 */
    node: Object,
  },
  data() {
    return {
      componentList: [],
      isDeleteNode: false,
      rootFlowParams: [],
      fromNodeList: [],
    };
  },
  watch: {
    nodeField: {
      deep: true,
      immediate: false,
      handler(now, old) {
        if (now.nodeKey != old.nodeKey) {
          this.componentList = [];
        }
      },
    },
  },
  created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
    if (typeof this.getFromNodes === "function") {
      this.fromNodeList = this.getFromNodes();
    }
    if (this.nodeField?.nodeKey) {
      let obj = this.parentNodes.find(
        (item) => item.key == this.nodeField.nodeKey,
      );
      this.isDeleteNode = !obj;

      if (obj) {
        if (obj.type == "root") {
          // 本流程参数
          this.buildComponentList(this.rootFlowParams, "");
        } else if (obj.type == "trigger_webhook") {
          this.buildComponentList([], "");
        } else if (obj.type == "subflow_collection") {
          // 子流程参数
          getRobotWorkflow({
            robotId: obj.robotId,
          }).then((res) => {
            this.buildComponentList(
              res?.data?.workflow?.rpaFlow?.config?.flowParams ?? [],
              "",
            );
          });
        } else if (obj.type == "json_parser") {
          let list = buildJSONParserComponents(obj);
          this.buildComponentList(list, "");
        } else {
          let collection = obj.formId || obj.collection;
          /**
           * 数据源为node_data_field时的节点
           */
          let _node;
          if (obj.dataSource == "node_data_field") {
            collection = obj.nodeField.collection;
            _node = this.parentNodes.find(
              (item) => item.key == obj.nodeField.nodeKey,
            );
          } else if (obj.dataSource == "node_data") {
            obj = this.fromNodeList.find((item) => item.key == obj.nodeKey);
            collection = obj.nodeField.collection;
          }
          this._tableName = collection;
          if (collection) {
            this.$store
              .dispatch("rpaNodeFields/fetchMetaComponents", {
                tableName: collection,
              })
              .then((list) => {
                if (obj.nodeField?.componentName == "json_form") {
                  let component = list.find(
                    (item) => item.name == obj.nodeField.name,
                  );
                  this.buildComponentList(component.components, collection);
                } else {
                  this.buildComponentList(list, collection);
                }
              });
          } else if (_node) {
            let components = buildJSONParserComponents(_node);
            let component = components.find(
              (item) => item.name == obj.nodeField.name,
            );
            if (component) {
              this.buildComponentList(component.components, "");
            }
          }
        }
      }
    }
  },
  destroyed() {
    if (this._tableName) {
      this.$store.commit("rpaNodeFields/clearItemByTableName", this._tableName);
    }
  },
  computed: {
    ...mapGetters(["sysFieldNames"]),
    isDelete() {
      if (!this.nodeField.name) {
        return false;
      }
      return (
        this.componentList.findIndex(
          (item) => item.name == this.nodeField.name,
        ) == -1
      );
    },
    parentNodes() {
      /**
       * @type {Array}
       */
      let parentNodes;
      if (!isEmpty(this.baseNodeKey)) {
        parentNodes = this.getParentNodes(["query_data_list"]);
        parentNodes = parentNodes.filter(
          (item) =>
            item.type != "query_data_list" ||
            (item.type == "query_data_list" && item.key == this.baseNodeKey),
        );
      } else {
        parentNodes = this.getParentNodes();
      }

      // 更新，新增或保存、新增三个节点的更新子表时，排除当前子表的节点作为值的源头进行更新，避免自己给自己赋值
      if (
        this.node &&
        [
          "action_form_data_update",
          "action_form_data_save",
          "action_form_data_create",
        ].includes(this.node.type) &&
        this.node.dataSource == "node_data"
      ) {
        parentNodes = parentNodes.filter(
          (item) => item.key != this.node.nodeKey,
        );
      }

      // 添加本流程参数
      if (this.hasBaseRootParam) {
        if (this.rootFlowParams?.length) {
          parentNodes.unshift({
            key: "root",
            title: "本流程参数",
            type: "root",
          });
        }
      }

      parentNodes = parentNodes.filter((item) => {
        if (item.dataSource == "node_data") {
          return (
            this.fromNodeList.findIndex((opt) => opt.key == item.nodeKey) >= 0
          );
        } else {
          return true;
        }
      });

      return parentNodes;
    },

    filterFields() {
      if (
        this.tableField.componentName == "id" &&
        this.fieldConfigKey == "filterComponents"
      ) {
        // 当使用当前数据作为条件时
        if (["eq", "neq"].includes(this.oper)) {
          // 等于和不等于的操作符匹配当前数据和关联数据
          return this.componentList.filter((item) =>
            ["id", "reference_data"].includes(item.componentName),
          );
        } else {
          // 等于任意一个和不等于任意一个匹配关联多条
          return this.componentList.filter(
            (item) => item.componentName == "reference_data_list",
          );
        }
      } else if (
        this.tableField.componentName == "json_form" &&
        this.fieldConfigKey == "fillComponents"
      ) {
        // 子表单字段新增赋值时候，匹配另一个节点字段的过滤条件

        return this.componentList.filter((item) => {
          if (item.componentName != "json_form") {
            return false;
          }
          if (item.dataMode == "reference_form") {
            // 实体子表单只要同源
            return (
              this.tableField.collection &&
              item.tableName &&
              this.tableField.collection == item.tableName
            );
          } else {
            // 非实体子表单必须是同一个表达的同一个字段
            return (
              this.tableField.collection &&
              this.nodeField.collection &&
              this.tableField.collection == this.nodeField.collection &&
              this.tableField.fieldName == item.name
            );
          }
        });
      } else {
        return this.componentList;
      }
    },
  },

  methods: {
    getLabel(item) {
      if (item.type == "trigger_webhook") {
        return `${item.title}【Webhook触发】`;
      }
      if (item.type == "root") {
        return item.title;
      }

      if (item.dataSource == "node_data_field") {
        return `${item.title}【${item.nodeField.title}】`;
      } else if (item.dataSource == "node_data") {
        let title = item.title;
        let node = this.fromNodeList.find((opt) => opt.key == item.nodeKey);
        if (node) {
          title = `${item.title}【${node.nodeField.title}】`;
        }

        return title;
      } else {
        let subTitle =
          item.formTitle || item.collectionTitle || item.pluginTitle;
        if (subTitle) {
          return `${item.title}【${subTitle}】`;
        } else {
          return item.title;
        }
      }
    },

    changeNokeKey(value) {
      let node = this.parentNodes.find((item) => item.key == value);

      if (node.dataSource == "node_data") {
        node = this.parentNodes.find((item) => item.key == node.nodeKey);
      }
      let nodeField;
      let collection = "";
      let tableName;

      if (
        node.dataSource == "node_data_field" &&
        node.nodeField?.componentName == "json_form" &&
        !node.nodeField?.referenceCollection
      ) {
        // 节点数据，原始模式子表单
        collection = node.nodeField.collection;
        tableName = collection;
        this.$set(this.nodeField, "collection", "");
      } else {
        if (
          node.dataSource == "node_data_field" &&
          node.nodeField?.referenceCollection
        ) {
          collection = node.nodeField.referenceCollection;
          tableName = node.nodeField.collection;
          this.$set(this.nodeField, "collection", collection);
        } else if (node.dataSource == "node_data") {
          let node2 = this.fromNodeList.find(
            (item) => item.key == node.nodeKey,
          );
          if (node2) {
            collection =
              node2.nodeField.referenceCollection || node2.nodeField.collection;
            nodeField = node2.nodeField;
            tableName = node2.collection;
            if (node2.nodeField.referenceCollection) {
              this.$set(this.nodeField, "collection", collection);
            } else {
              this.$set(this.nodeField, "collection", "");
            }
          } else {
            collection = "";
            this.$set(this.nodeField, "collection", collection);
          }
        } else {
          collection = node.formId || node.collection;
          tableName = collection;
          this.$set(this.nodeField, "collection", collection);
        }
      }

      this.$set(this.nodeField, "componentName", "");
      this.$set(this.nodeField, "title", "");
      this.$set(this.nodeField, "name", "");
      this.isDeleteNode = false;

      if (node.type == "root") {
        // 本流程参数
        this.buildComponentList(this.rootFlowParams, "");
      } else if (node.type == "trigger_webhook") {
        this.buildComponentList([], "");
      } else if (node.type == "subflow_collection") {
        // 子流程参数
        getRobotWorkflow({
          robotId: node.robotId,
        }).then((res) => {
          this.buildComponentList(
            res?.data?.workflow?.rpaFlow?.config?.flowParams ?? [],
            "",
          );
        });
      } else if (node.type == "developer_plugin") {
        let list = [];
        node.responseParams.forEach((res) => {
          list.push({
            componentName: res.dataType,
            key: res.name,
            name: res.name,
            title: res.title,
          });
        });
        this.buildComponentList(list, "");
      } else if (node.type == "json_parser") {
        let list = buildJSONParserComponents(node);
        this.buildComponentList(list);
      } else {
        if (tableName) {
          fetchMetaComponents(tableName).then((res) => {
            if (
              node.dataSource == "node_data_field" &&
              node.nodeField?.componentName == "json_form"
            ) {
              let component = res.data.list.find(
                (item) => item.name == node.nodeField.name,
              );
              this.buildComponentList(component.components, tableName);
            } else if (node.dataSource == "node_data") {
              let component = res.data.list.find(
                (item) => item.name == nodeField.name,
              );
              this.buildComponentList(component.components, tableName);
            } else {
              this.buildComponentList(res.data.list, tableName);
            }
          });
        } else if (node.dataSource == "node_data_field") {
          // json_parser
          let _node = this.parentNodes.find(
            (item) => item.key == node.nodeField.nodeKey,
          );
          if (_node && _node.type == "json_parser") {
            let components = buildJSONParserComponents(_node);
            let jsonForm = components.find(
              (item) => item.name == node.nodeField.name,
            );
            if (jsonForm) {
              this.buildComponentList(jsonForm.components, "");
            }
          }
        }
      }
    },
    buildComponentList(list, collection) {
      if (
        this.tableField.name == "_id" &&
        this.fieldConfigKey == "filterComponents"
      ) {
        let components = list.filter((item) => {
          if (["id"].includes(item.componentName)) {
            return this.formId == collection;
          } else if (
            ["reference_data", "reference_data_list", "cascade_data"].includes(
              item.componentName,
            )
          ) {
            return item.tableName == this.formId;
          } else {
            return false;
          }
        });

        this.componentList = components;
        return;
      }

      let arr = checkFieldAbility(
        {
          componentName: this.tableField.componentName,
          name: this.tableField.name,
        },
        this.fieldConfigKey,
      );
      if (this.tableField.componentName == "json_form") {
        arr = ["json_form"];
      }

      // 特殊处理: 过滤条件中选择下拉复选/多选框组，连接条件为“包含任意一个/同时包含”时，可选择下拉单、复选，单、多选框组，单行文本
      let c1 = [
        "select",
        "select_checkbox",
        "radio_group",
        "checkbox_group",
        "input",
      ];
      let checkboxComponents = ["select_checkbox", "checkbox_group"]; // 复选组件
      if (this.oper == "containAny" || this.oper == "contain") {
        if (checkboxComponents.includes(this.tableField.componentName)) {
          arr = c1;
        }
      }
      //特殊处理: 过滤条件中为以下c2字段时，连接条件为“等于任意一个/不等于任意一个”时，可选择复选组件
      let c2 = ["input", "text_area", "radio_group", "select", "sn_input"];
      if (this.oper == "in" || this.oper == "nin") {
        if (c2.includes(this.tableField.componentName)) {
          arr = [...arr, ...checkboxComponents];
        }
      }

      if (this.isAnyComponentName) {
        arr = componentArray
          .filter(
            (item) =>
              item.form &&
              !["json_form", "html_input"].includes(item.componentName),
          )
          .map((item) => item.componentName);
      }

      let componentList = [];

      list.forEach((item) => {
        if (
          this.tableField.componentName == "user_select" &&
          ["in", "nin"].includes(this.oper) &&
          ["user_select", "user_list_select"].includes(item.componentName)
        ) {
          componentList.push(item);
        } else if (
          this.tableField.componentName == "user_list_select" &&
          ["containAny", "contain"].includes(this.oper) &&
          ["user_select", "user_list_select"].includes(item.componentName)
        ) {
          componentList.push(item);
        } else if (
          this.tableField.componentName == "department_select" &&
          ["in", "nin"].includes(this.oper) &&
          ["department_select", "department_list_select"].includes(
            item.componentName,
          )
        ) {
          componentList.push(item);
        } else if (
          this.tableField.componentName == "department_list_select" &&
          ["containAny", "contain"].includes(this.oper) &&
          ["department_select", "department_list_select"].includes(
            item.componentName,
          )
        ) {
          componentList.push(item);
        } else if (this.sysFieldNames.includes(item.name)) {
          // 系统字段

          if (arr.includes(item.name)) {
            if (
              ["reference_data", "cascade_data"].includes(
                this.tableField.componentName,
              ) &&
              collection === this.tableField.referenceTableName
            ) {
              componentList.push(item);
            } else if (
              !["reference_data", "cascade_data"].includes(
                this.tableField.componentName,
              )
            ) {
              componentList.push(item);
            }
          }
        } else if (arr.includes(item.componentName)) {
          if (
            ["reference_data", "cascade_data"].includes(
              this.tableField.componentName,
            ) &&
            this.tableField.referenceTableName === item.tableName
          ) {
            componentList.push(item);
          } else if (
            !["reference_data", "cascade_data"].includes(
              this.tableField.componentName,
            )
          ) {
            componentList.push(item);
          }
        }
      });
      let node = this.parentNodes.find(
        (item) => item.key == this.nodeField.nodeKey,
      );
      if (allowPluginReturn.includes(this.tableField.componentName)) {
        if (node.type == "developer_plugin") {
          node.responseParams.forEach((res) => {
            componentList.push({
              componentName: res.dataType,
              name: res.name,
              title: res.title,
            });
          });
        }
      }
      if (node.type == "trigger_webhook") {
        let arr1 = ["body", "header", "params"];
        arr1.forEach((row) => {
          node[row].forEach((item) => {
            if (arr.includes(item.componentName)) {
              componentList.push({
                componentName: item.componentName,
                name: item.name,
                title: `${row}.${item.title}`,
              });
            }
          });
        });
      }

      if (this.tableField.componentName == "json_form") {
        if (this.tableField.referenceTableName) {
          componentList = componentList.filter(
            (item) => item.tableName == this.tableField.referenceTableName,
          );
        } else {
          componentList = componentList.filter(
            (item) =>
              item.name == this.tableField.fieldName &&
              this.tableField.collection == collection,
          );
        }
      }
      this.componentList = componentList;
    },
    changeField(value) {
      let obj = this.componentList.find((item) => item.name == value);
      if (obj) {
        this.$set(this.nodeField, "componentName", obj.componentName);
        this.$set(this.nodeField, "title", obj.title);
      } else {
        this.$set(this.nodeField, "componentName", "");
        this.$set(this.nodeField, "title", "");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.row-flex {
  flex: 1;
}
</style>
