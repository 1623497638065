<template>
  <el-dialog
    :visible.sync="visible"
    title="公式编辑器"
    width="890px"
    @closed="closed"
    destroy-on-close
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div style="padding: 20px">
      <slot name="header"></slot>
      <div class="formula-body">
        <div class="formula-header">{{ title }}=</div>
        <formula
          v-if="visible"
          ref="formula"
          :height="150"
          :disabled="disabled"
          :getFieldByName="getFieldByName"
          :jsonFormName="jsonFormName"
          :buildOriginalFormula="buildOriginalFormula"
          :getNodeTitle="getNodeTitle"
        ></formula>
      </div>
      <div class="formula-bottom">
        <!-- 可用变量 -->
        <field-list
          @insert="nodeClick"
          :isJsonForm="isJsonForm"
          :jsonFormName="jsonFormName"
          :columns="columns"
        ></field-list>

        <!-- 函数列表 -->
        <fun-list @insert="insertFun" :disabledFuns="disabledFuns"></fun-list>
      </div>
    </div>
    <div slot="footer">
      <el-button
        v-if="!disabled"
        type="default"
        @click="visible = false"
        style="margin-right: 10px"
        >取消</el-button
      >
      <button
        v-else
        class="el-button el-button--default"
        @click="visible = false"
      >
        取消
      </button>
      <el-button type="primary" @click="save" v-if="!disabled">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// 公式编辑器
import formula from "./index";
import { isEmpty, isNumber } from "@/zgg-core/utils";

import funList from "./fun-list";
import fieldList from "./field-list";
import { getContentByOriginalFormula } from "./formula";
import formula_mixin from "./formula_mixin";
import { getArgComponentNames } from "@/zgg-core/relyFuns";

export default {
  name: "FormulaDialog",
  components: { formula, funList, fieldList },
  mixins: [formula_mixin],
  props: {
    originalFormula: Array, // 公式列表数据,
    title: {
      type: String,
      default() {
        return "公式";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    }, // 设置公式的表单字段
    treeNodeList: Array, // 树节点列表
    nodeKey: String, // 智能助手2.0 某节点字段设置公式专用
    isJsonForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
    jsonFormName: {
      type: String,
      default() {
        return "";
      },
    },
    disabledFuns: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data() {
    return {
      treeProps: {
        children: "components",
        label: "title",
      },
      visible: false,
      columns: [],

      keyword: "", // 可用变量关键字
    };
  },
  created() {
    if (this.originalFormula) {
      this.originalFormula.forEach((rows) => {
        rows.forEach((item) => {
          if (item.type == "val") {
            if (isNumber(item.value)) {
              item.componentName = "input_number";
              item.value = parseFloat(item.value);
            } else if (typeof item.value === "string") {
              item.componentName = "input";
            }
          }
        });
      });
    }
  },
  mounted() {},
  methods: {
    getNodeTitle(nokeKey) {
      let obj = this.columns.find((item) => item.key == nokeKey);
      if (obj) {
        return obj.title;
      }
      return "";
    },

    async open() {
      let columns = [];
      if (this.nodeKey) {
        columns = await this.buildRpaComponents();
      } else {
        columns = await this.buildFormComponents(
          this._.cloneDeep(this.treeNodeList),
        );
      }

      this.columns = columns;
      this.visible = true;
      this.$nextTick(() => {
        let content = getContentByOriginalFormula(this.originalFormula);
        this.$refs.formula.setContent(content);
      });
    },

    close() {
      this.visible = false;
    },
    closed() {
      this.$emit("closed");
    },
    /**
     * 插入函数
     * @param {*} val
     */
    insertFun(val) {
      if (this.disabled) {
        return;
      }
      this.$refs.formula.insert({
        name: val,
        type: "fun",
      });
    },

    nodeClick(data, node) {
      if (this.disabled) {
        return;
      }
      if (node.isLeaf) {
        this.$refs.formula.insert({
          ...data,
          type: "field",
        });
      }
    },
    save() {
      if (this.$refs.formula.error) {
        this.$message.error("请先处理错误信息");
        return;
      }
      let { originalFormula, tree } = this.buildOriginalFormula();
      if (isEmpty(originalFormula)) {
        this.$message.error("请输入公式");
        return;
      }
      let componentNames = getArgComponentNames({
        type: "rely",
        children: tree,
      });

      this.$emit("save", originalFormula, componentNames);
    },
  },
};
</script>
<style lang="scss" scoped>
.formula-body {
  border: 1px solid #e0e0e0;
  .formula-header {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    background-color: #f3f8fb;
    padding: 0 10px;
  }
}
.formula-bottom {
  margin-top: 10px;
  display: flex;
  height: 270px;
  overflow: hidden;
}
</style>
