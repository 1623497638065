import request from '@/utils/request'
import qs from "qs"



/**
 * 账号密码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function login(params) {
  return request({
    url:  '/user/login',
    method: 'post',
    params,
    headers:{
      encrypted:true
    }
  })
}

/**
 * 验证码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function codeLogin(params) {
  return request({
    url:  '/user/loginByCode',
    method: 'post',
    params
  })
}
/**
 * 注册
 * @param params
 * @returns {AxiosPromise}
 */
export function reg(params) {
  return request({
    url:  '/user/register',
    method: 'post',
    params
  })
}

/**
 * 获取注册验证码
 * @param params
 * @returns {AxiosPromise}
 */
export function getRegisterCode(params) {
  return request({
    url:  '/verify/sms/register',
    method: 'post',
    data: qs.stringify(params)
  })
}
/**
 * 获取登录验证码
 * @param params
 * @returns {AxiosPromise}
 */
export function getLoginCode(params) {
  return request({
    url:  '/verify/sms/login',
    method: 'post',
    params
  })
}

/**
 * 获取用户信息
 * @param token
 * @returns {AxiosPromise}
 */
export function getInfo(token) {
  return request({
    url:  '/user/current',
    method: 'post',
    params: { token }
  })
}

export function logout() {
  return request({
    url:  '/user/logout',
    method: 'post'
  })
}
export function queryUserByPhone(query) {
  return request({
    url:  '/user/queryUserByPhone',
    method: 'post',
    params:{query}
  })
}

/**
 * 校验手机号
 * @param data
 * @returns {AxiosPromise}
 */
export function checkMobile(data) {
  return request({
    url:  '/user/checkMobile',
    method: 'post',
    data:qs.stringify(data)
  })
}

// 选择用户: /sys/user/listUserPage (参考 TestSysUserListUserPage)
export function listUserPage(data) {
  return request({
    url:  '/sys/user/listUserPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
//
// 选择单位: /sys/user/listCompanyPage(参考 TestSysUserListCompanyPage)
export function listCompanyPage(data) {
  return request({
    url:  '/sys/user/listCompanyPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取用户列表
export function userPaging(data) {
  return request({
    url:  '/user/paging',
    method: 'post',
    data
  })
}
// 日活统计接口
export function homeLoginLogStats(data) {
  return request({
    url:  '/home/loginLogStats',
    method: 'post',
    data
  })
}
// 注册用户统计接口：
export function homeStats(data) {
  return request({
    url:  '/home/stats',
    method: 'post',
    data
  })
}
// 工作群统计接口：：
export function homeOtherStats(data) {
  return request({
    url:  '/home/otherStats',
    method: 'post',
    data
  })
}
// 图表接口：
// /home/userRegisterChart：：
export function homeUserRegisterChart(data) {
  return request({
    url:  '/home/userRegisterChart',
    method: 'post',
    data
  })
}

// 获取验证码接口
export function getVerCode(data) {
  return request({
    url:  '/verify/sms/findPwd',
    method: 'post',
    data
  })
}

// 重置密码接口
export function resetPwd(data) {
  return request({
    url:  '/user/resetPwd',
    method: 'post',
    data
  })
}
