<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      class="svg-process"
      :class="{ 'svg-point': workflowType != 'company' }"
      :style="getLineStyle(line)"
  >
    <defs>
      <marker
          :id="uuid"
          markerWidth="25"
          markerHeight="25"
          refX="8"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
          viewBox="0 0 30 30"
      >
        <path d="M0,0 L3,3 L0,6 L9,3 z" :fill="color" />
      </marker>
    </defs>
    <polyline
        :points="line.points"
        style="fill: transparent; pointer-events: auto"
        :style="`stroke:${color};`"
        stroke-width="2"
        :marker-end="`url(#${uuid})`"
    />
  </svg>
</template>
<script>
import { uuid } from "@zgg-core-utils/utils";
export default {
  props: {
    workflowType: String,
    line: Object,
  },
  data() {
    return {
      uuid: uuid(),
    };
  },
  computed: {
    color() {
      if (this.line.lineColor) {
        return this.line.lineColor;
      }
      return "#043746";
    },
  },
  methods: {
    getLineStyle(line) {
      // 获取线svg的样式
      let style = `left:${line.left}px;top:${line.top}px;`;
      if (line.width) {
        style += `width:${line.width}px;`;
      }
      if (line.height) {
        style += `height:${line.height};`;
      }
      return style;
    },
  },
};
</script>
<style lang="scss" scoped>
.svg-move,
.svg-process {
  pointer-events: none;
  position: absolute;
  overflow: visible;
}
.svg-point {
  cursor: pointer;
}
</style>
