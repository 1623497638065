<template>
  <div class="button-setting-icon-container" @click="buttonClick">
    <div
      class="image-icon"
      v-if="iconStyle.type === 'ICON_URL'"
      :style="{
        'background-image': 'url(' + iconStyle.icon + ')',
      }"
    ></div>

    <div
      class="button-setting-icon-div"
      v-else
      :style="'background-color:' + iconStyle.color"
    >
      <i
        class="iconfont"
        :class="iconStyle.className"
        style="color: #fff; font-size: 14px; margin-right: 0"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  //按钮设置这边的图标组件
  name: "button-group-button-item-setting",
  props: {
    iconStyle: {
      type: Object,
      default() {
        return {
          className: "icon-briefcase-fill",
          color: "#3870EA",
        };
      },
    },
  },
  methods: {
    buttonClick() {
      console.log("buttonClick");
      this.$emit("buttonClick");
    },
  },
};
</script>

<style scoped lang="scss">
.button-setting-icon-container {
  &:hover {
    opacity: 0.9;
  }

  .image-icon {
    width: 100%;
    height: 36px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
  }

  .button-setting-icon-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 36px;
    background: #3870ea;
    border-radius: 5px;
    cursor: pointer;
    opacity: 1;
  }
}
.select-icon {
  position: relative;
}
</style>
