<template>
  <el-dialog
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    fullscreen
    @close="handleClose"
    :show-close="false"
  >
    <div slot="title" style="display: flex; flex-direction: row">
      <span style="flex: 1; display: flex; align-items: center"
        >移动端视图设置</span
      >
      <div>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">保 存</el-button>
      </div>
    </div>
    <div class="body">
      <div class="body-left card-border">
        <div class="body-contain">
          <el-card class="body-contain-card" body-style="padding: 0;">
            <h5 class="card-title">{{ dataTitle }}</h5>
            <ul class="filed-list">
              <template v-if="setting.wapConfig.columnSelectType === 'with_pc'">
                <li class="filed" v-for="item in columnFields" :key="item.key">
                  <span>{{ item.title }}</span>
                </li>
              </template>
              <template v-if="setting.wapConfig.columnSelectType === 'custom'">
                <li
                  class="filed"
                  v-for="item in setting.columns"
                  :key="item.key"
                >
                  <span>{{ item.title }}</span>
                </li>
              </template>
            </ul>
          </el-card>
        </div>
      </div>
      <div class="card-border body-right">
        <el-tabs v-model="tab">
          <el-tab-pane label="设置" name="setting"></el-tab-pane>
        </el-tabs>
        <div class="setting-container">
          <el-form label-position="top" label-width="80px" :model="setting">
            <el-form-item label="卡片标题" class="setting-item title">
              <el-input :disabled="true" :value="dataTitle"></el-input>
            </el-form-item>
            <el-form-item label="显示字段" class="setting-item show-field">
              <el-select
                v-model="setting.wapConfig.columnSelectType"
                placeholder="请选择"
              >
                <el-option label="和电脑端一致" value="with_pc"></el-option>
                <el-option label="自定义" value="custom"></el-option>
              </el-select>
              <div
                class="drag-field-container"
                v-if="setting.wapConfig.columnSelectType === 'custom'"
              >
                <fields-select
                  title="选择在卡片上显示的字段"
                  v-model="setting.columns"
                  :columns="columnFields"
                  componentName="form_data"
                  :includeJsonForm="false"
                >
                  <div class="add-show-field" slot="reference">
                    添加显示字段
                  </div>
                </fields-select>
                <draggable
                  v-model="setting.columns"
                  handle=".icon-yidong"
                  animation="300"
                >
                  <div
                    v-for="(item, index) in setting.columns"
                    :key="item.field.key"
                    class="drag-field-item"
                  >
                    <span class="item-name" :title="item.title">{{
                      item.title
                    }}</span>
                    <div class="item-operate">
                      <i
                        class="iconfont icon-yidong"
                        style="font-size: 15px"
                      ></i>
                      <i
                        class="iconfont icon-delete-bin-line"
                        style="font-size: 15px"
                        @click="deleteField(index)"
                      ></i>
                    </div>
                  </div>
                </draggable>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import Draggable from "vuedraggable";
import FieldsSelect from "@/components/FieldsSelect";
import _ from "lodash";

export default {
  name: "mobile-view",
  components: { Draggable, FieldsSelect },
  props: {
    dataTitleCols: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    wapConfig: {
      type: Object,
      default: () => {
        return { columnSelectType: "with_pc" };
      },
    },
  },
  data() {
    return {
      draggableList: [],
      hiddenComponentList: [],
      setting: {
        wapConfig: {},
        columns: [],
      },
      tab: "setting",
    };
  },
  created() {
    this.setting.wapConfig = _.cloneDeep(this.wapConfig);
    this.initCustomColumnData();
  },
  computed: {
    columnFields() {
      // 移动端视图不可显示的字段
      const mobileViewHideFields = [
        "html_input",
        "json_form",
        "form_data",
        "divider",
        "grid",
        "tab",
        "reference_data_list",
        "button",
      ];
      const filterResult = [];
      for (let i = 0; i < this.columns.length; i++) {
        let item = this.columns[i];
        if (!mobileViewHideFields.includes(item.field.componentName)) {
          filterResult.push({
            ...item.field,
            title: item.title || item.field.title,
          });
        }
      }
      return filterResult;
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    dataTitle() {
      const title = this.dataTitleCols
        .map((col) => col.title)
        .join("")
        .trim();
      return title === "" ? "无标题" : title;
    },
  },
  methods: {
    initCustomColumnData() {
      if (this.setting.wapConfig.columnSelectType === "custom") {
        this.columns.forEach((item) => {
          let wapConfig = item.field.component.wapConfig;
          if (wapConfig && !wapConfig.hidden) {
            this.setting.columns[wapConfig.sortNumber] = item;
          }
        });
        // 清除空的元素
        this.setting.columns = this.setting.columns.filter(
          (item) => item !== null,
        );
      }
    },
    deleteField(index) {
      this.setting.columns.splice(index, 1);
    },
    cancel() {
      this.dialogVisible = false;
    },
    confirm() {
      this.saveCustomField();
      this.dialogVisible = false;
    },
    // 将自定义字段的更改同步到视图设计组件
    saveCustomField() {
      const fieldMap = new Map();
      this.setting.columns.forEach((item, index) => {
        fieldMap.set(item.field.key, { hidden: false, sortNumber: index });
      });

      const defaultWapConfig = { hidden: true, sortNumber: -1 };
      this.columns.forEach((item) => {
        item.field.component.wapConfig = fieldMap.has(item.field.key)
          ? fieldMap.get(item.field.key)
          : defaultWapConfig;
      });
      this.$emit("update:wapConfig", this.setting.wapConfig);
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$header-height: 76px;
$gap-size: 10px;

.body {
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{$header-height});
  background-color: #f2f6fc;

  .body-left {
    display: flex;
    justify-content: space-around;
    flex: 1;
    padding: 24px;
    .body-contain {
      height: 100%;
      width: 375px;
      padding: 24px 12px;
      border: 10px solid white;
      border-radius: 32px;
      background-color: #f4f6fa;
      overflow: auto;

      &::-webkit-scrollbar-track {
        margin: 25px 0;
      }

      .body-contain-card {
        padding: 16px;
        .card-title {
          color: #172b4d;
          font-size: 14px;
          font-weight: 500;
          margin: 4px 0 8px 0;
        }

        .filed-list {
          margin: 0;
          padding: 0;
          .filed {
            font-size: 13px;
            line-height: 22px;
            color: #a5adba;
            padding: 0;
            margin-bottom: 2px;
            list-style: none;
          }
        }
      }
    }
  }

  .body-right {
    width: 256px;
    margin-left: $gap-size;
    background-color: white;
    display: flex;
    flex-direction: column;

    .setting-container {
      padding: 0 20px;
      overflow: auto;
      .setting-item {
        margin-bottom: 8px;
        ::v-deep .el-form-item__label {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #172b4d;
          padding: 0;
          margin-bottom: 4px;
        }
      }

      .title {
        ::v-deep .el-input,
        ::v-deep .el-input__inner {
          height: 32px;
        }

        ::v-deep .el-input__inner {
          font-size: 13px;
          line-height: 22px;
          color: #172b4d;
        }
      }

      .show-field {
        ::v-deep .el-select {
          width: 100%;
        }

        ::v-deep .el-input__inner {
          height: 32px;
          color: #172b4d;
          font-size: 13px;
          line-height: 22px;
        }

        .drag-field-container {
          .add-show-field {
            line-height: 1;
            color: var(--blue-6);
            text-align: center;
            cursor: pointer;
            user-select: none;
            margin: 13px 0 18px 0;
          }

          .drag-field-item {
            line-height: 1;
            display: flex;
            justify-content: space-between;
            margin: 0 0 13px 8px;

            .item-name {
              max-width: 150px;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              white-space: nowrap;
            }

            .item-operate {
              user-select: none;
              i {
                &:hover {
                  color: var(--blue-6);
                }
              }
            }
          }
        }
      }
    }
  }
}

.hidden-view-list {
  position: relative;
  padding: 10px;
  height: 100%;
  overflow: auto;
}

::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
::v-deep .el-card.is-always-shadow {
  box-shadow: none;
}
</style>
