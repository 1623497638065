<template>
  <el-dialog
    title="倒计时组件"
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    width="790px"
    @open="openDialog"
    @close="handleClose"
    :close-on-click-modal="false"
    :show-close="showClose"
  >
    <div
      style="padding: 24px; display: flex; flex-direction: column;"
      v-if="dialogVisible"
    >
     

    <div class="form-item__wrap">
            <span class="form-item__wrap-title titlespan">组件名称</span>
            <el-input
              v-model="countDownModel.extParam.title"
              placeholder="请输入"
              size="small"
            ></el-input>
          </div>
          <div class="form-item__wrap">
            <span class="form-item__wrap-title titlespan">目标日</span>
            <el-date-picker
              v-model="countDownModel.targetDate"
              type="date"
              placeholder="请选择目标日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
          <div class="form-item__wrap">
            <span class="form-item__wrap-title titlespan">数值颜色</span>
            <colorSelect v-model="countDownModel.valueColor" :viewChart="{'valueColor':countDownModel.valueColor}" @updateColor1="updateColor"  chartType="table"></colorSelect>
          </div>

  
    </div>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="saveForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import colorSelect from "./../colorSelect.vue"
//文本类型的筛选器
import { fetchFormDataList } from "@/views/lowCode/form/api";
import { isEmpty } from "@zgg-core-utils/relyUtil";

export default {
  name: "DashboardCountDown",
  components: { colorSelect },
  props: {
    dataComponentList: Array,
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      title: "",
      components: [
        {
          label: "倒计时组件",
          value: "count_down",
        },
      ],
      countDownModel: {
        extParam:{title:""},
        targetDate:"",
        valueColor:"#6BDAAE",
        componentName: "count_down",
      },
    };
  },
  watch: {
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
         //自定义颜色
         updateColor(val){
  this.countDownModel.valueColor = val
   },
    /**
     * 校验要提交的数据
     */
    validate() {
      console.log(this.countDownModel);
      const { extParam,targetDate,valueColor } =
        this.countDownModel;
      return new Promise((resolve, reject) => {

        if (isEmpty(extParam.title)) {
          this.$message.warning("标题不能为空");
          return reject();
        }
        if (isEmpty(targetDate)) {
          this.$message.warning("请选择目标日");
          return reject();
        }
        if (isEmpty(valueColor)) {
          this.$message.warning("请选择数值颜色");
          return reject();
        }
        resolve();
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },

    saveForm() {
      console.log(this.countDownModel);
      this.validate()
        .then((resp) => {
          console.log("成功");
          this.$emit("save", this.countDownModel);
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      Object.assign(this.$data, this.$options.data());
    },
    openDialog() {},

    manualInit(dataFilterModel) {
      this.dialogVisible = true;
      this.$set(
        this,
        "countDownModel",
        this._.cloneDeep(dataFilterModel)
      );
    },


  },
};
</script>
<style lang="scss" scoped>

.form-item__wrap {
display: flex;
flex-direction: column;
margin-top: 57px;

&:first-child {
  margin-top: 0;
}

.form-item__wrap-title {
  margin-bottom: 16px;
  color: #1f2d3d;
  font-size: 14px;

}
.el-date-editor.el-input, .el-date-editor.el-input__inner{
  width: auto;
}
}
.titlespan::before {content: '* ';color: red;}

</style>
<style>
.el-dialog__header{
  padding-bottom: 15px;
}
</style>