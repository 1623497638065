<template>
  <div class="FolderSelect">
    <el-tree
      :data="treeData"
      :props="defaultProps"
      highlight-current
      :expand-on-click-node="false"
      :default-expanded-keys="[0]"
      node-key="id"
      class="my-tree"
      ref="myTree"
      @node-click="handleNodeClick"
      :filter-node-method="filterNode"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span class="node-label">
          <svg-icon
            iconClass="wenjianjia"
            v-if="data.dataType == 'folder'"
            style="margin-right: 5px; font-size: 16px"
          ></svg-icon>
          {{ node.label }}
        </span>
      </span>
    </el-tree>
  </div>
</template>

<script>
import { dirTree } from "@/views/lowCode/api";

export default {
  props: {
    currentNode: Object,
    isCompanyAdmin: Boolean,
    type: [String, Number],
  },
  data() {
    return {
      targetFolder: [0],
      folderSelectDialogVisible: false,
      treeData: [],
      defaultProps: {
        children: "directoryList",
        label: "title",
        isLeaf: "hasChildList",
      },
    };
  },
  computed: {},
  methods: {
    filterNode(value, data) {
      let { dataType, id } = this.currentNode.data;
      if (dataType == "folder") {
        return data.id != id;
      }
      return true;
    },
    fetchWorkTree() {
      let params = {
        type: this.type,
        fullTree: true,
        onlyFolder: true,
      };
      dirTree(params).then((resp) => {
        this.treeData = [
          {
            title: "我的目录",
            id: 0,
            directoryList: resp.data.directoryList,
          },
        ];
        this.$nextTick(() => {
          this.$refs.myTree.filter();
        });
      });
    },
    handleNodeClick(data, node, el) {
      if (data.id === 0) {
        return;
      }
      this.$emit("change", data.id);
    },
  },
  mounted() {
    this.fetchWorkTree();
  },
};
</script>
<style lang="less" scoped>
.FolderSelect {
  width: 100%;
  height: 400px;
  overflow: auto;
}
</style>
