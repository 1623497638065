<template>
  <div class="right-wrap pd-24">
    <div class="table-header-tool-bar">
      <span>{{ currentCompanyName }} </span>
      <el-input
        placeholder="搜索人员"
        v-model="searchForm.keyword"
        style="width: 300px; margin-right: 14px"
        clearable
        @clear="search"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
        ></el-button>
      </el-input>
      <el-button
        v-if="isOcManege"
        type="primary"
        @click="handleAddEmployee()"
        icon="el-icon-plus"
        >新增员工
      </el-button>
      <el-button
        v-if="isOcManege"
        :disabled="modifyPasswordEmployeeIds.length === 0"
        type="primary"
        @click="batchModifyPassword()"
        icon="el-icon-plus"
        >批量修改密码
      </el-button>
    </div>
    <div style="height: 100%; overflow: hidden" class="draggable">
      <el-table
        :data="employeeList"
        height="100%"
        row-key="id"
        @selection-change="handleSelectionChange"
        ref="employeeListRef"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" align="left">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="left">
        </el-table-column>

        <el-table-column
          prop="mobile"
          label="手机号"
          align="left"
          min-width="120"
        >
        </el-table-column>
        <el-table-column align="left" width="500px" label="操作">
          <template slot-scope="scope">
            <el-link
              v-if="isOcManege"
              type="primary"
              style="margin-right: 10px"
              size="small"
              @click="handleEmployeeRowEdit(scope.row)"
              :underline="false"
              >编辑
            </el-link>
            <el-link
              type="primary"
              style="margin-right: 10px"
              size="small"
              @click="handleEmployeeSwitchAdmin(scope.row)"
              v-if="isOcManege && scope.row.role === 100"
              :underline="false"
              >取消单位管理员
            </el-link>
            <el-link
              type="primary"
              style="margin-right: 10px"
              size="small"
              @click="handleEmployeeSwitchAdmin(scope.row)"
              v-if="isOcManege && scope.row.role === 10"
              :underline="false"
              >设置为单位管理员
            </el-link>
            <el-link
              v-if="isOcManege"
              type="primary"
              style="margin-right: 10px"
              size="small"
              @click="modifyPassword(scope.row)"
              :underline="false"
              >设置密码
            </el-link>
            <el-link
              v-if="isOcManege"
              type="primary"
              style="margin-right: 10px"
              size="small"
              @click="handleResetPassword(scope.row)"
              :underline="false"
              >重置密码
            </el-link>
            <el-link
              v-if="isOcManege"
              type="danger"
              style="margin-right: 10px"
              size="small"
              @click="handleEmployeeRowDelete(scope.row)"
              :underline="false"
              >删除
            </el-link>
            <el-link
              v-if="isOcManege"
              type="danger"
              style="margin-right: 10px"
              size="small"
              @click="handleEmployeeRowActive(scope.row)"
              :underline="false"
              >{{ scope.row.activated ? "已启用" : "已禁用" }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="dialogVisible" title="编辑">
        <div style="padding: 20px">
          <el-form :model="currentEmployee" ref="employeeForm">
            <el-form-item label="id" prop="id" hidden> </el-form-item>
            <el-form-item label="姓名" required prop="name">
              <el-input
                placeholder="请输入姓名"
                v-model="currentEmployee.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" required prop="mobile">
              <el-input
                placeholder="请输入手机号"
                v-model="currentEmployee.mobile"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelEmployee">取 消</el-button>
          <el-button type="primary" @click="saveEmployee">确定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="modifyPwdDialogVisible"
        title="设置密码"
        width="500px"
      >
        <div style="padding: 20px">
          <el-form
            ref="modifyPasswordRef"
            :model="modifyByOldPasswordForm"
            :rules="modifyByOldPasswordFormRules"
            class="login-form"
            auto-complete="on"
            label-position="left"
            style="width: 100%"
          >
            <el-form-item prop="newPassword">
              <el-input
                prefix-icon="el-icon-lock"
                ref="password"
                v-model="modifyByOldPasswordForm.newPassword"
                placeholder="请输入新密码"
                tabindex="2"
                auto-complete="off"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item prop="secondaryNewPassword">
              <el-input
                prefix-icon="el-icon-lock"
                ref="password"
                v-model="modifyByOldPasswordForm.secondaryNewPassword"
                placeholder="请再次输入新密码"
                tabindex="2"
                auto-complete="off"
                autocomplete="off"
              />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleModifyPasswordConfirm"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  employeeDelete,
  employeeList,
  employeeResetPassword,
  employeeSave,
  employeeSetPassword,
  employeeSwitchAdmin,
  employeeSwitchUserActive,
} from "@/views/company-config/employee-manage/employee-manage-api";
import { mapGetters } from "vuex";

export default {
  name: "employee-manage",
  data() {
    const validatePassword = (rule, value, callback) => {
      const codeP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,30}$/;
      if (value.length === 0) {
        callback(new Error("密码不能为空"));
      } else if (value.length < 8 || value.length > 30) {
        callback(new Error("密码只能在8~30位！"));
      } else if (!codeP.test(value)) {
        callback(new Error("密码格式不对！"));
      } else {
        callback();
      }
    };
    const validateSecondPassword = (rule, value, callback) => {
      const codeP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,30}$/;
      if (value.length === 0) {
        callback(new Error("密码不能为空"));
      } else if (value.length < 8 || value.length > 30) {
        callback(new Error("密码只能在8~30位！"));
      } else if (!codeP.test(value)) {
        callback(new Error("密码格式不对！"));
      } else if (value !== this.modifyByOldPasswordForm.newPassword) {
        callback(new Error("两次密码不一致！"));
      } else {
        callback();
      }
    };
    return {
      employeeList: [],
      searchForm: {
        keyword: "",
      },
      dialogVisible: false,
      currentEmployee: {
        name: "",
        mobile: "",
        id: "",
      },
      modifyPwdDialogVisible: false,
      //
      modifyByOldPasswordForm: {
        newPassword: "", //新密码
        secondaryNewPassword: "", //确认新密码
      },
      modifyByOldPasswordFormRules: {
        newPassword: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        secondaryNewPassword: [
          {
            required: true,
            trigger: "blur",
            validator: validateSecondPassword,
          },
        ],
      },
      /**
       * 当前待修改密码的员工数组id
       */
      modifyPasswordEmployeeIds: [],
    };
  },
  computed: {
    ...mapGetters(["currentCompanyId", "currentCompanyName", "companyList"]),
    /**
     * 是否是运营管理员
     */
    isOcManege() {
      return !!this.companyList.find(
        (companyItem) =>
          companyItem.companyId === this.currentCompanyId &&
          companyItem.role === 100,
      );
    },
  },
  watch: {
    currentCompanyId(val, oldVal) {
      this.fetchEmployeeList();
    },
  },
  methods: {
    /**
     * 批量修改
     */
    batchModifyPassword() {
      this.modifyPwdDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.modifyPasswordRef.resetFields();
      });
    },
    /**
     * 确认修改密码
     */
    handleModifyPasswordConfirm() {
      //校验modifyPasswordRef的表单校验
      this.$refs.modifyPasswordRef.validate((result) => {
        if (result) {
          const modifyPasswordParam = {
            companyId: this.currentCompanyId,
            psw: this.modifyByOldPasswordForm.newPassword,
            employeeId: this.modifyPasswordEmployeeIds.toString(),
          };
          console.log("modifyPasswordParam", modifyPasswordParam);
          employeeSetPassword(modifyPasswordParam).then((resp) => {
            console.log("resp", resp);
            this.$message.success("修改密码成功");
            this.modifyPwdDialogVisible = false;

            //清除多选
            this.$refs.employeeListRef.clearSelection();
          });
        }
      });
    },
    /**
     * 修改密码
     */
    modifyPassword(row) {
      console.log("row", row);
      this.modifyPwdDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.modifyPasswordRef.resetFields();
        this.modifyPasswordEmployeeIds = [row.id];
      });
    },
    handleEmployeeRowActive(row) {
      const activeFunc = () => {
        employeeSwitchUserActive({
          companyId: this.currentCompanyId,
          employeeId: row.id,
        }).then((resp) => {
          this.refreshEmployeeList();
        });
      };

      this.$alert("此操作将改变用户启用状态，是否继续？", "修改用户状态", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action === "confirm") {
            activeFunc();
          }
        },
      });
    },
    handleSelectionChange(rows) {
      console.log(rows);
      this.modifyPasswordEmployeeIds = rows.map((item) => item.id);
    },
    cancelEmployee() {
      console.log("cancelEmployee");
    },
    saveEmployee() {
      console.log("saveEmployee");
      employeeSave({
        companyId: this.currentCompanyId,
        "employee.name": this.currentEmployee.name,
        "employee.mobile": this.currentEmployee.mobile,
        "employee.id": this.currentEmployee.id,
      }).then((resp) => {
        this.dialogVisible = false;
        this.refreshEmployeeList();
      });
    },
    /**
     * 重置密码
     * @param row
     */
    handleResetPassword(row) {
      let { id } = row;
      const resetFunc = () => {
        employeeResetPassword({
          companyId: this.currentCompanyId,
          employeeId: id,
        }).then((resp) => {
          console.log(resp);
          const newPassword = resp.data.newPassword;
          this.$alert(newPassword, "重置密码", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
        });
      };
      this.$alert("此操作将重置该用户的密码，是否继续？", "开始重置密码", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action === "confirm") {
            resetFunc();
          }
        },
      });
    },
    handleEmployeeRowEdit(row) {
      this.dialogVisible = true;
      let { name, mobile, id } = row;
      this.currentEmployee.name = name;
      this.currentEmployee.mobile = mobile;
      this.currentEmployee.id = id;
    },
    handleEmployeeRowDelete(row) {
      const deleteFunc = () => {
        employeeDelete({
          companyId: this.currentCompanyId,
          employeeId: row.id,
        }).then((resp) => {
          this.refreshEmployeeList();
        });
      };
      this.$alert("此操作将删除该用户，是否继续？", "开始删除用户", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action === "confirm") {
            deleteFunc();
          }
        },
      });
    },
    /**
     * 刷新员工列表
     */
    refreshEmployeeList() {
      this.fetchEmployeeList();
    },
    handleEmployeeSwitchAdmin(row) {
      let { id, name, mobile } = row;
      employeeSwitchAdmin({
        companyId: this.currentCompanyId,
        employeeId: id,
      }).then((resp) => {
        this.refreshEmployeeList();
      });
    },
    resetCurrentEmployee() {
      this.currentEmployee = {
        name: "",
        mobile: "",
        id: "",
      };
      this.$refs.employeeForm && this.$refs.employeeForm.resetFields();
    },
    /**
     * 添加员工
     */
    handleAddEmployee() {
      this.dialogVisible = true;
      this.resetCurrentEmployee();
    },
    search() {
      this.fetchEmployeeList();
    },
    /**
     * 获取员工列表
     */
    fetchEmployeeList() {
      employeeList({
        companyId: this.currentCompanyId,
        keyword: this.searchForm.keyword,
      }).then((resp) => {
        console.log(resp);
        this.employeeList = resp.data.page.list;
      });
    },
  },
  mounted() {
    this.fetchEmployeeList();
  },
};
</script>

<style scoped lang="scss">
.right-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
</style>
