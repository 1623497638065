import { render, staticRenderFns } from "./button-group-button-item-setting.vue?vue&type=template&id=4ab4d01b&scoped=true"
import script from "./button-group-button-item-setting.vue?vue&type=script&lang=js"
export * from "./button-group-button-item-setting.vue?vue&type=script&lang=js"
import style0 from "./button-group-button-item-setting.vue?vue&type=style&index=0&id=4ab4d01b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab4d01b",
  null
  
)

export default component.exports