<template>
  <div ref="layBox" class="lay-box">
    <span class="lay-box__title">基础字段</span>
    <draggable
      :list="baseFieldComponentList"
      :options="options1"
      animation="300"
      :scroll-sensitivity="100"
      fallback-tolerance="10"
      :move="onMove"
      @start="start($event, baseFieldComponentList)"
      @end="end($event, baseFieldComponentList)"
      @choose="choose($event, baseFieldComponentList)"
      @unchoose="unchoose($event, baseFieldComponentList)"
      @clone="clone($event, baseFieldComponentList)"
      class="lay-box-fields__wrap"
    >
      <div
        class="lay-item"
        v-for="item in baseFieldComponentList"
        :key="item.componentName"
      >
        <i class="iconfont" :class="componentIcons[item.componentName]"></i>
        <span>{{ item.label }}</span>
      </div>
    </draggable>

    <span class="lay-box__title">增强字段</span>
    <draggable
      :list="advanceFieldComponentList"
      :options="options1"
      animation="300"
      :scroll-sensitivity="100"
      fallback-tolerance="10"
      :move="onMove"
      @start="start($event, advanceFieldComponentList)"
      @end="end($event, advanceFieldComponentList)"
      @choose="choose($event, advanceFieldComponentList)"
      @unchoose="unchoose($event, advanceFieldComponentList)"
      @clone="clone($event, advanceFieldComponentList)"
      class="lay-box-fields__wrap"
    >
      <div
        class="lay-item"
        v-for="item in advanceFieldComponentList"
        :key="item.componentName"
      >
        <i class="iconfont" :class="componentIcons[item.componentName]"></i>
        <span>{{ item.label }}</span>
      </div>
    </draggable>
    <span class="lay-box__title">部门/成员组件</span>
    <draggable
      :list="userAndDepartmentFieldComponentList"
      :options="options1"
      animation="300"
      :scroll-sensitivity="100"
      fallback-tolerance="10"
      :move="onMove"
      @start="start($event, userAndDepartmentFieldComponentList)"
      @end="end($event, userAndDepartmentFieldComponentList)"
      @choose="choose($event, userAndDepartmentFieldComponentList)"
      @unchoose="unchoose($event, userAndDepartmentFieldComponentList)"
      @clone="clone($event, userAndDepartmentFieldComponentList)"
      class="lay-box-fields__wrap"
    >
      <div
        class="lay-item"
        v-for="item in userAndDepartmentFieldComponentList"
        :key="item.componentName"
      >
        <i class="iconfont" :class="componentIcons[item.componentName]"></i>
        <span>{{ item.label }}</span>
      </div>
    </draggable>
    <span class="lay-box__title">高级组件（试用）</span>

    <draggable
      :list="seniorFieldComponentList"
      :options="options1"
      animation="300"
      :scroll-sensitivity="100"
      fallback-tolerance="10"
      :move="onMove"
      @start="start($event, seniorFieldComponentList)"
      @end="end($event, seniorFieldComponentList)"
      @choose="choose($event, seniorFieldComponentList)"
      @unchoose="unchoose($event, seniorFieldComponentList)"
      @clone="clone($event, seniorFieldComponentList)"
      class="lay-box-fields__wrap"
    >
      <div
        class="lay-item"
        v-for="item in seniorFieldComponentList"
        :key="item.componentName"
      >
        <i class="iconfont" :class="componentIcons[item.componentName]"></i>
        <span>{{ item.label }}</span>
      </div>
    </draggable>
  </div>
</template>
<script>
import { conponents as components } from "./const";
import Draggable from "vuedraggable";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { hasRoleAuth } from "@/utils/roleAuth";
import { componentsIcon } from "@zgg-core-utils/utils";
export default {
  components: { Draggable },
  data() {
    return {
      list: [],
      options1: {
        group: {
          name: "column",
          pull: "clone",
          put: false,
        },
        sort: false,
      },
      unChooseEvenFlag: false,
      componentIcons: componentsIcon,
    };
  },
  computed: {
    seniorFieldComponentList() {
      let seniorFieldComponentNameEnums = ["grid"];
      if (this.hasFieldAuth("ops_auth_form_cmp_form_data")) {
        seniorFieldComponentNameEnums.push("reference_data_list");
      }
      if (this.hasFieldAuth("ops_auth_form_cmp_button")) {
        seniorFieldComponentNameEnums.push("button");
      }
      if (this.hasFieldAuth("ops_auth_form_cmp_summary_data")) {
        seniorFieldComponentNameEnums.push("summary_data");
      }
      return this.list.filter((item) =>
        seniorFieldComponentNameEnums.includes(item.componentName),
      );
    },
    baseFieldComponentList() {
      let baseFieldComponentNameEnums = [
        "input",
        "text_area",
        "input_number",
        "date_picker",
        "radio_group",
        "checkbox_group",
        "select",
        "divider",
        "select_checkbox",
        "html",
        "address_input",
        "mobile_input",
        "attachment_uploader",
        "image_uploader",
        "html_input",
      ];
      return this.list.filter((item) =>
        baseFieldComponentNameEnums.includes(item.componentName),
      );
    },
    advanceFieldComponentList() {
      let baseFieldComponentNameEnums = [
        "sn_input",
        "json_form",
        "sign_input",
        "sign_list_input",
        "tab",
        "include_form",
        "parent_data",
        "position_input",
        "cascade_data",
      ];
      if (this.hasFieldAuth("ops_auth_form_cmp_reference_data")) {
        baseFieldComponentNameEnums.push("reference_data");
      }
      if (this.hasFieldAuth("ops_auth_form_cmp_reference_data_list")) {
        baseFieldComponentNameEnums.push("form_data");
      }
      return this.list.filter(
        (item) =>
          (process.env.NODE_ENV === "development" ||
            (process.env.NODE_ENV !== "development" && !item.disabled)) &&
          baseFieldComponentNameEnums.includes(item.componentName),
      );
    },
    userAndDepartmentFieldComponentList() {
      let baseFieldComponentNameEnums = [
        "department_list_select",
        "department_select",
        "user_list_select",
        "user_select",
      ];
      return this.list.filter((item) =>
        baseFieldComponentNameEnums.includes(item.componentName),
      );
    },
  },
  created() {
    this.list = this._.cloneDeep(components);
  },

  methods: {
    hasFieldAuth(val) {
      return hasRoleAuth(val);
    },
    onMove(e) {
      let name = e.relatedContext.component.$attrs.name;
      console.log(name);
      if (name == "json_form") {
        let componentName = e.draggedContext.element.componentName;
        return checkFieldAbility({ componentName }, "inJsonForm");
      } else if (name == "tab_pane") {
        let componentName = e.draggedContext.element.componentName;
        return checkFieldAbility({ componentName }, "inTabPane");
      } else if (name == "grid_col") {
        let componentName = e.draggedContext.element.componentName;

        return checkFieldAbility({ componentName }, "inGridCol");
      }
      return true;
    },
    end(e) {
      console.log("end", e);
      if (e.pullMode == "clone") {
        this.list = this._.cloneDeep(components);
        this.$emit("clone", e);
      }
      this.unChooseEvenFlag = false;
    },
    choose(e, list) {
      this.unChooseEvenFlag = true;
    },
    buildItem(component) {
      let uuid = this.getUuid();
      if (component.componentName == "parent_data") {
        uuid = "parentId";
      }
      component.key = uuid;
      component.isAdd = true;
      if (component.form) {
        component.name = uuid;
      }
      let callback = (list) => {
        if (list) {
          list.forEach((item) => {
            let uuid = this.getUuid();
            this.$set(item, "key", uuid);
            if (item.form) {
              this.$set(item, "name", uuid);
            }
            if (item.container) {
              callback(item.components);
            }
          });
        }
      };
      if (component.container) {
        callback(component.components);
      }
    },
    unchoose(e, list) {
      if (this.unChooseEvenFlag) {
        let index = e.oldIndex;
        let component = this._.cloneDeep(list[index]);
        let buildComponent = () => {
          this.buildItem(component);
          return this._.cloneDeep(component);
        };

        this.$emit("unchoose", component.componentName, buildComponent);
      }
    },

    clone(e, list) {
      let index = e.oldIndex;
      let component = list[index];
      this.buildItem(component);
    },
    start(e) {
      this.unChooseEvenFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.iconfont {
  color: #6b778c;
}
.lay-box {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  margin: 5px;
  border-radius: 10px;
  padding: 10px 10px 30px 10px;
  width: 240px;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .lay-item {
    //margin-bottom: 10px;
    font-size: 13px;
    //padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #f4f5f7;
    border: 1px solid #f4f5f7;
    margin: 5px;
    display: inline-block;
    width: 100px;
    text-align: left;
    height: 34px;
    line-height: 34px;
    color: #172b4d;
    text-indent: 10px;
  }
  .lay-item:hover {
    border: 1px solid var(--zgg-border-base);
  }
  .lay-item:active {
    background-color: var(--zgg-border-base);
    border: 1px solid var(--zgg-border-base);
  }
  .choose {
    background-color: var(--zgg-border-base);
    border: 1px solid var(--zgg-border-base);
  }

  .lay-box__title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #172b4d;
    height: 22px;
    text-indent: 4px;
  }

  .lay-box-fields__wrap {
    //width: max-content
    margin-bottom: 10px;
  }
}
.sortable-ghost {
  background-color: var(--zgg-border-base) !important;
  border: 1px solid var(--zgg-border-base) !important;
}
</style>
