<template>
  <div>
    <draggable :list="form.rules" animation="300" handle=".el-icon-rank">
      <div class="weui" v-for="(item, index) in form.rules" :key="index">
        <el-input
          v-if="item.ruleType !== 'fixedChars'"
          :value="initRuleInput(item)"
          size="small"
          class="rule-input-row"
          readonly
          @focus="handleRuleSetting(item, index)"
        >
          <template slot="prepend">{{
            ruleTypeEnums[item.ruleType].name
          }}</template>
          <el-link
            slot="append"
            icon="el-icon-setting"
            @click="handleRuleSetting(item, index)"
            :underline="false"
          ></el-link>
        </el-input>
        <el-input
          v-else
          v-model="item.chars"
          size="small"
          class="rule-input-row"
        >
          <template slot="prepend">{{
            ruleTypeEnums[item.ruleType].name
          }}</template>
        </el-input>

        <i
          class="el-icon-rank ml"
          style="font-size: 16px; cursor: pointer; margin: 0 5px"
        ></i>

        <i
          class="el-icon-remove ml"
          v-if="item.ruleType !== 'incNumber'"
          @click="deleteRule(item, index)"
          style="color: red; font-size: 16px; margin: 0 5px; cursor: pointer"
        ></i>
        <i v-else ml style="width: 20px;margin: 0 5px;'"></i>
      </div>
    </draggable>
    <el-dropdown trigger="click" size="small" @command="handleSnInputAddRule">
      <div class="el-dropdown-link">
        <el-link icon="el-icon-plus" :underline="false" type="primary"
          >添加
        </el-link>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :disabled="isCanAddCreateTime" command="addCreateTime"
          >提交日期</el-dropdown-item
        >
        <el-dropdown-item command="addFixedChars">固定字符</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="attr-label">流水号重置</div>
    <div class="attr-content">
      {{
        `${
          preAutoIncrementValue == null
            ? "暂未开始记数"
            : `已计数到${preAutoIncrementValue}`
        },下一条数据提交时计数值为${nextAutoIncrementValue}`
      }}
    </div>
    <el-button type="text" @click="resetData">重置</el-button>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="visible"
      width="560px"
      @open="open"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="sn-input-setting-dialog">
        <el-form
          ref="form"
          :model="currentRule.rule"
          label-width="80px"
          label-position="top"
          size="small"
        >
          <template v-if="currentRule.rule.ruleType == 'incNumber'">
            <el-form-item label="计数位数">
              <el-input-number
                v-model="currentRule.rule.digitsNum"
                :min="1"
                :max="10"
                label="计数位数"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="位数固定">
              <el-switch
                v-model="currentRule.rule.fixedLength"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
              <span class="form-item-tip"
                >开启后，会根据计数位数显示计数值。例如计数位数是5，1会显示为00001</span
              >
            </el-form-item>
            <el-form-item label="重置周期">
              <el-select
                v-model="currentRule.rule.resetDuration"
                placeholder="重置周期"
              >
                <el-option
                  v-for="item in resetDurationEnums"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="初始值">
              <el-input
                v-model="currentRule.rule.startValue"
                placeholder="请输入初始值"
              ></el-input>
              <span class="form-item-tip"
                >仅当计数达到最大值时，从0开始重新计数。</span
              >
            </el-form-item>
          </template>
          <template v-if="currentRule.rule.ruleType == 'createTime'">
            <el-form-item label="">
              <el-radio
                v-model="currentRule.rule.dateFormatType"
                @input="changeDateFormat"
                :label="10"
                >预定义格式</el-radio
              >
              <el-select
                v-if="currentRule.rule.dateFormatType == 10"
                v-model="currentRule.rule.dateFormat"
                placeholder="预定义格式"
                style="display: block; margin-left: 20px"
              >
                <el-option
                  v-for="item in dateFormatEnums"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-else
                value=""
                disabled
                placeholder="预定义格式"
                style="display: block; margin-left: 20px"
              >
                <el-option
                  v-for="item in dateFormatEnums"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-radio
                v-model="currentRule.rule.dateFormatType"
                @input="changeDateFormat"
                :label="20"
                >自定义格式</el-radio
              >
              <el-input
                v-model="currentRule.rule.dateFormat"
                :disabled="currentRule.rule.dateFormatType == 10"
                style="display: block; margin-left: 20px; width: auto"
              ></el-input>
              <span class="form-item-tip">格式预览：{{ dateTitle }}</span>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import moment from "moment";
import { isEmpty } from "@zgg-core-utils/utils";
import { resetSnInputValue } from "../api";

export default {
  mounted() {
    this.preAutoIncrementValue = this.form.preAutoIncrementValue;
    this.nextAutoIncrementValue = this.form.nextAutoIncrementValue;
  },
  name: "snInputSetting",
  components: { Draggable },
  data() {
    return {
      ruleTypeEnums: {
        incNumber: {
          name: "自动计数",
        },
        createTime: {
          name: "提交日期",
        },
        fixedChars: {
          name: "固定字符",
        },
      },
      resetDurationEnums: [
        {
          value: "none",
          label: "不自动重置",
        },
        {
          value: "every_day",
          label: "每日重置",
        },
        {
          value: "every_week",
          label: "每周重置",
        },
        {
          value: "every_month",
          label: "每月重置",
        },
        {
          value: "every_year",
          label: "每年重置",
        },
      ],
      dateFormatEnums: [
        {
          value: "yyyy",
          label: "2015",
        },
        {
          value: "yyyyMM",
          label: "201501",
        },
        {
          value: "yyyy-MM",
          label: "2015-01",
        },
        {
          value: "yyyy/MM",
          label: "2015/01",
        },
        {
          value: "yyyyMMDD",
          label: "20150131",
        },
        {
          value: "yyyy-MM-DD",
          label: "2015-01-31",
        },
        {
          value: "yyyy/MM/DD",
          label: "2015/01/31",
        },
        {
          value: "MMDD",
          label: "0131",
        },
        {
          value: "MM-DD",
          label: "01-31",
        },
        {
          value: "MM/DD",
          label: "01/31",
        },
      ],
      title: "ceshi",
      visible: false,
      currentRule: {
        rule: {},
        index: 0,
      },
    };
  },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    nextAutoIncrementValue: {
      get() {
        return this.form.nextAutoIncrementValue || 1;
      },
      set(val) {
        this.form.nextAutoIncrementValue = val;
      },
    },
    preAutoIncrementValue: {
      get() {
        return this.form.preAutoIncrementValue;
      },
      set(val) {
        this.form.preAutoIncrementValue = val;
      },
    },
    //是否可以添加提交日期
    isCanAddCreateTime() {
      return (
        this.form.rules &&
        this.form.rules.findIndex((item) => item.ruleType == "createTime") !==
          -1
      );
    },
    dialogTitle() {
      if ("incNumber" == this.currentRule.rule.ruleType) {
        return "计数设置";
      }
      if ("createTime" == this.currentRule.rule.ruleType) {
        return "日期格式设置";
      }
      return "设置";
    },
    dateTitle() {
      if (
        this.currentRule.rule.dateFormatType == 20 &&
        !isEmpty(this.currentRule.rule.dateFormat)
      ) {
        return moment().format(this.currentRule.rule.dateFormat);
      } else {
        return "";
      }
    },
  },
  methods: {
    resetData() {
      this.$confirm("此操作重置流水号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        resetSnInputValue({
          formId: this.$route.query.id,
          fieldName: this.form.name,
        }).then((res) => {
          this.preAutoIncrementValue = null;
          this.nextAutoIncrementValue = res.data.nextAutoIncrementValue;
        });
      });
    },
    changeDateFormat(value) {
      this.currentRule.rule.dateFormat = "";
    },
    deleteRule(item, index) {
      this.$confirm("删除流水号规则，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(this.form.rules, index);
        })
        .catch(() => {});
    },
    save() {
      this.$set(this.form.rules, this.currentRule.index, this.currentRule.rule);
      this.visible = false;
    },
    initRuleInput(rule) {
      if ("incNumber" == rule.ruleType) {
        return `${rule.digitsNum}位数字，${
          this.resetDurationEnums.find(
            (item) => item.value === rule.resetDuration,
          ).label
        }`;
      }
      if ("createTime" == rule.ruleType) {
        return `格式：${moment().format(rule.dateFormat)}`;
      }
    },
    handleRuleSetting(row, index) {
      if (row.ruleType !== "fixedChars") {
        this.visible = true;
        this.currentRule.rule = this._.cloneDeep(row);
        this.currentRule.index = index;
      }
    },
    open() {},
    handleSnInputAddRule(command) {
      if (command == "addCreateTime") {
        this.addCreateTime();
      } else if (command == "addFixedChars") {
        this.addFixedChars();
      }
    },
    addCreateTime() {
      this.form.rules.push({
        dateFormat: "yyyyMMDD",
        dateFormatType: 10,
        ruleType: "createTime",
      });
    },
    addFixedChars() {
      this.form.rules.push({
        chars: "",
        ruleType: "fixedChars",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.weui {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sn-input-setting-dialog {
  margin: 0px 20px;

  .form-item-tip {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #c3cdda;
  }
}

::v-deep {
  .rule-input-row .el-input-group__append {
    padding: 0 5px;

    .el-button {
      margin: 0px;
    }
  }

  .rule-input-row .el-input-group__prepend {
    padding: 0 5px;
  }

  .rule-input-row .el-input__inner {
    padding: 0 5px;
  }

  .rule-input-row .el-button {
    padding: 0;
  }
}
.attr-label {
  font-size: 14px;
  line-height: 24px;
  margin: 5px 0;
}

.attr-content {
  font-size: 14px;
  line-height: 24px;
  color: #42526e;
}
</style>
