<template>
  <div>
    <el-popover
      popper-class="pop-trigger"
      v-model="visible"
      width="260"
      trigger="click"
    >
      <div class="pop-scroll" v-clickoutside="handleClose">
        <template v-if="components && components.length">
          <div
            v-for="item in components"
            :key="item.key"
            v-text="item.title"
            class="form-item"
            :class="{ disabled: isDisabeld(item.name) }"
            @click="checkComponent(item)"
          ></div>
        </template>
        <div v-else class="pop-empty">表单没有组件</div>
      </div>
      <el-button @click.stop="" slot="reference" type="text" icon="el-icon-plus"
        >添加过滤条件</el-button
      >
    </el-popover>
    <field-setting
      v-for="(item, index) in filterFieldValues"
      :key="item.key"
      :componentList="sourceComponents"
      :field="item"
      :triggerFormId="triggerFormId"
      type="filter"
      :index="index"
      :deleteField="deleteField"
    ></field-setting>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";

import fieldSetting from "./fieldSetting";
export default {
  directives: { Clickoutside },
  components: { fieldSetting },
  props: {
    componentList: Array,
    triggerComponents: Array,
    filterFieldValues: Array,
    formId: String,
    triggerFormId: String,
  },
  computed: {
    components() {
      return this.componentList.filter((item) => item.name&&(item.name.indexOf(".") == -1));
    },
    sourceComponents() {
      return this.triggerComponents.filter(
        (item) => item.name&&(item.name.indexOf(".") == -1)
      );
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    deleteField(index) {
      this.$delete(this.filterFieldValues, index);
    },
    isDisabeld(name) {
      return (
        this.filterFieldValues.findIndex(
          (item) => item.tableField.fieldName == name
        ) >= 0
      );
    },
    handleClose() {
      this.visible = false;
    },
    checkComponent(item) {
      if (this.isDisabeld(item.name)) {
        return;
      }
      let tableField = {
        collection: this.formId,
        fieldName: item.name,
        fieldTitle: item.title,
        componentName: item.componentName,
        optionSource: item.optionSource,
        customOptions: item.customOptions,
      };
      if (item.picker) {
        tableField.picker = item.picker;
      }
      if (item.extParam) {
        tableField.extParam = item.extParam;
      }

      // todo 需要根据关联类型来赋值
      if(item.componentName=='reference_data'&&item.tableName){
        tableField.referenceTableName=item.tableName
      }


      this.filterFieldValues.push({
        key: this.getUuid(),
        valueType: "tableField",
        tableField: tableField,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  &.disabled {
    color: #c3cdda;
    cursor: not-allowed !important;
    &:hover {
      background-color: transparent !important;
      color: #c3cdda;
    }
  }
}
</style>
