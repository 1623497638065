<template>
  <div style="margin-bottom: 10px">
    <div class="weui-row" style="margin-bottom: 5px">
      <!-- 数据源 -->
      <el-select
        v-model="field.paramValue1.nodeField.nodeKey"
        placeholder="请选择节点"
        size="small"
        style="width: 220px; margin-right: 10px"
        :class="{ 'is-error': isDeleteNode }"
        @change="changeNokeKey"
        :disabled="disabled"
      >
        <el-option
          v-for="item in parentNodes"
          :key="item.key"
          :label="getLabel(item)"
          :value="item.key"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="field.paramValue1.nodeField.name"
        placeholder="请选择字段"
        @change="changeNodeField"
        style="width: 220px; margin-right: 10px"
        size="small"
        :class="{ 'is-error': isDelete }"
        :disabled="disabled"
      >
        <el-option
          v-for="item in componentList"
          :key="item.name"
          :label="item.title"
          :value="item.name"
        >
        </el-option>
      </el-select>

      <!-- 操作符 -->
      <el-select
        v-model="field.oper"
        size="small"
        :disabled="!field.paramValue1.nodeField.name || disabled"
        placeholder="请选择"
        style="width: 140px; margin-right: 10px"
        @change="changeOper"
      >
        <el-option
          v-for="opt in operQuerys"
          :key="opt.value"
          :label="opt.label"
          :value="opt.value"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="!['nil', 'nnil'].includes(field.oper)" class="weui-row">
      <el-select
        v-if="field.oper != 'dynamic'"
        v-model="field.paramValue2.valueType"
        size="small"
        style="width: 140px; margin-right: 10px"
        placeholder="请选择"
        @change="changeValueType"
        :disabled="!field.paramValue1.nodeField.name || disabled"
      >
        <el-option
          v-if="
            !(
              filterField.componentName == 'date_picker' &&
              field.oper == 'between'
            )
          "
          label="节点字段"
          value="nodeField"
        ></el-option>
        <el-option
          v-if="filterField.componentName != 'id'"
          label="自定义"
          value="custom"
        ></el-option>
      </el-select>
      <rpa-node-fields
        v-if="field.paramValue2.valueType == 'nodeField'"
        :nodeField="field.paramValue2.nodeField"
        :tableField="tableField"
        :oper="field.oper"
        fieldConfigKey="filterComponents"
        :disabled="!field.paramValue1.nodeField.name || disabled"
        :formId="field.paramValue1.nodeField.collection"
      ></rpa-node-fields>
      <query-input
        v-else
        :field="filterField"
        :areaTree="areaTree"
        :areaProps="areaProps"
        :componentList="componentList"
        :disabled="!field.paramValue1.nodeField.name || disabled"
        style="flex: 1; margin-right: 10px"
        v-model="customValue"
      ></query-input>
    </div>
  </div>
</template>
<script>
import { fetchMetaComponents } from "@/api/form";
import {
  checkFieldAbility,
  operQuerys,
} from "@zgg-core-utils/component-white-list";

import { buildJSONParserComponents, fromNodesMixins } from "./rpa-utils";
import { getValueByKey, isEmpty, isEmptyByKey } from "@zgg-core-utils/utils";
import { mapState } from "vuex";
import { userMixin } from "@/mixin/user-dept";

import rpaNodeFields from "./rpa-node-fields";
import { listDepartmentInfo, listUserInfo } from "../api";
export default {
  mixins: [fromNodesMixins, userMixin],
  components: { rpaNodeFields },
  props: {
    rel: String,
    field: Object,
    index: Number,
    disabled: Boolean,
  },
  data() {
    return {
      parentNodes: [],
      componentList: [],
      filterField: {},
    };
  },

  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    tableField() {
      let obj = this.componentList.find(
        (item) => item.name == this.field.paramValue1.nodeField.name,
      );
      return {
        name: this.field.paramValue1.nodeField.name,
        componentName: this.field.paramValue1.nodeField.componentName,
        referenceTableName: obj && obj.tableName,
      };
    },
    isDeleteNode() {
      if (!isEmptyByKey(this.field, "paramValue1.nodeField.nodeKey")) {
        return (
          this.parentNodes.findIndex(
            (item) =>
              item.key ==
              getValueByKey(this.field, "paramValue1.nodeField.nodeKey"),
          ) == -1
        );
      }
      return false;
    },
    isDelete() {
      if (!isEmptyByKey(this.field, "paramValue1.nodeField.name")) {
        return (
          this.componentList.findIndex(
            (item) =>
              item.name ==
              getValueByKey(this.field, "paramValue1.nodeField.name"),
          ) == -1
        );
      }
      return false;
    },
    operQuerys() {
      if (this.field?.paramValue1?.nodeField?.name) {
        let list = [];
        if (this.field.paramValue1.nodeField.componentName == "id") {
          list = ["eq", "neq", "in", "nin"];
        } else {
          list = checkFieldAbility(
            {
              componentName: this.field.paramValue1.nodeField.componentName,
              name: this.field.paramValue1.nodeField.name,
            },
            "oper",
          );
        }

        if (!isEmpty(list)) {
          return operQuerys.filter((item) => list.includes(item.value));
        }
      }
      return [];
    },
    customValue: {
      get() {
        let v;
        if (
          this.filterField.componentName == "date_picker" &&
          this.field.oper == "dynamic"
        ) {
          v = getValueByKey(this.field, "paramValue2.dynamicTime");
        } else {
          v = getValueByKey(this.field, "paramValue2.customValue");
        }
        return v;
        //
      },
      set(val) {
        if (
          this.filterField.componentName == "date_picker" &&
          this.field.oper == "dynamic"
        ) {
          this.$set(this.field.paramValue2, "dynamicTime", val);
        } else {
          this.$set(this.field.paramValue2, "customValue", val);
        }
        this.$set(this.filterField, "value", val);
      },
    },
  },
  async created() {
    this.parentNodes = this.getParentNodes();

    let obj = this.parentNodes.find(
      (item) => item.key == this.field.paramValue1.nodeField.nodeKey,
    );
    await this.buildComponentListByNode(obj);

    let value = this.field.paramValue2.customValue;
    if (
      this.field.oper == "dynamic" &&
      this.field.paramValue1.nodeField.componentName == "date_picker"
    ) {
      value = getValueByKey(this.field, "paramValue2.dynamicTime");
    }
    this.$set(this, "filterField", {
      key: this.field.paramValue1.nodeField.name,
      title: this.field.paramValue1.nodeField.title,
      componentName: this.field.paramValue1.nodeField.componentName,
      picker: this.field.paramValue1.nodeField.picker,
      oper: this.field.oper,
      value: value,
      valueDetail: this.field.paramValue2.customValueDetail,
    });

    // 用户、部门组件获取展示信息
    if (
      [
        "user_select",
        "user_list_select",
        "department_select",
        "department_list_select",
      ].includes(this.filterField.componentName)
    ) {
      if (!isEmpty(this.filterField.value)) {
        let isArr = this.filterField.value instanceof Array;
        if (
          ["user_select", "user_list_select"].includes(
            this.filterField.componentName,
          )
        ) {
          let userIds = [];
          if (isArr) {
            userIds = this.filterField.value;
          } else {
            userIds = [this.filterField.value];
          }
          let res = await listUserInfo({ userIds });

          if (isArr) {
            this.$set(this.filterField, "valueDetail", res.data.list);
          } else {
            if (["in", "nin"].includes(this.filterField.oper)) {
              this.$set(this.filterField, "value", [this.filterField.value]);
              this.$set(this.filterField, "valueDetail", res.data.list);
            } else {
              this.$set(this.filterField, "valueDetail", res.data.list[0]);
            }
          }
        } else if (
          ["department_select", "department_list_select"].includes(
            this.filterField.componentName,
          )
        ) {
          let deptIds;
          if (isArr) {
            deptIds = this.filterField.value;
          } else {
            deptIds = [this.filterField.value];
          }
          let res = await listDepartmentInfo({
            departmentIds: deptIds,
          });
          if (isArr) {
            this.$set(this.filterField, "valueDetail", res.data.list);
          } else {
            if (["in", "nin"].includes(this.filterField.oper)) {
              this.$set(this.filterField, "value", [this.filterField.value]);
              this.$set(this.filterField, "valueDetail", res.data.list);
            } else {
              this.$set(this.filterField, "valueDetail", res.data.list[0]);
            }
          }
        }
      }
    }
  },
  destroyed() {
    if (this._tableName) {
      this.$store.commit("rpaNodeFields/clearItemByTableName", this._tableName);
    }
  },
  methods: {
    getLabel(item) {
      if (item.type == "trigger_webhook") {
        return `${item.title}【Webhook触发】`;
      }
      if (item.type == "json_parser") {
        let parentNode = this.parentNodes.find(
          (opt) => opt.key == item.jsonDataNodeKey,
        );
        let str = item.title;
        if (parentNode) {
          str += `【${parentNode.title}】`;
        }
        return str;
      }

      if (item.dataSource == "node_data_field") {
        return `${item.title}【${item.nodeField.title}】`;
      } else if (item.dataSource == "node_data") {
        let parentNode = this.parentNodes.find(
          (opt) => opt.key == item.nodeKey,
        );
        if (parentNode) {
          return `${item.title}【${parentNode.nodeField.title}】`;
        } else {
          return item.title;
        }
      } else {
        let collectionTitle =
          item.formTitle || item.collectionTitle || item.pluginTitle;
        if (collectionTitle) {
          return `${item.title}【${collectionTitle}】`;
        } else {
          return item.title;
        }
      }
    },
    async buildComponentListByNode(node) {
      /**
       * @type Array
       */
      let tplList = await this.buildNodeFieldList(node);
      let list = this.buildHookComponents(node);
      let componentList = [];
      tplList.forEach((item) => {
        let obj = checkFieldAbility(item, "inRpaFilter");
        if (obj) {
          if (obj === true || obj.self) {
            componentList.push(item);
          } else if (obj.children) {
            if (item.components) {
              item.components.forEach((child) => {
                componentList.push({
                  ...child,
                  key: item.key + "." + child.key,
                  name: item.name + "." + child.name,
                  title: item.title + "." + child.title,
                });
              });
            }
          }
        }
      });

      componentList = componentList.concat(list);
      this.componentList = componentList;
    },
    async buildNodeFieldList(node) {
      let list = [];
      if (!node) {
        return list;
      }
      if (node.type == "json_parser") {
        list = buildJSONParserComponents(node);
        return list;
      }

      if (node.dataSource == "node_data") {
        let parentNode = this.parentNodes.find(
          (item) => item.key == node.nodeKey,
        );

        if (parentNode) {
          list = await this.buildNodeFieldList(parentNode);
        }
      } else if (node.dataSource == "node_data_field") {
        let parentNode = this.parentNodes.find(
          (item) => item.key == node.nodeKey,
        );
        let list2 = [];
        if (parentNode && parentNode.type == "json_parser") {
          list2 = buildJSONParserComponents(parentNode);
        } else {
          list2 = await this.buildFieldList(node.nodeField.collection);
        }

        let jsonForm = list2.find((item) => item.key == node.nodeField.name);
        if (jsonForm) {
          list = jsonForm.components;
        }
      } else {
        list = await this.buildFieldList(node.formId || node.collection);
      }

      return list;
    },
    async buildFieldList(tableName) {
      this._tableName = tableName;

      let list = await this.$store.dispatch(
        "rpaNodeFields/fetchMetaComponents",
        { tableName },
      );
      return list;
    },
    del() {
      this.$emit("del");
    },
    changeValueType(value) {
      this.$set(this.field.paramValue2, "customValue", "");

      if (value == "nodeField") {
        this.$set(this.field.paramValue2, "nodeField", {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
        });
      } else {
        this.$set(this.field.paramValue2, "nodeField", undefined);
      }
    },
    changeOper(val) {
      this.filterField.oper = val;
      if (["nil", "nnil"].includes(val)) {
        this.$set(this.field.paramValue2, "valueType", "custom");
        this.$set(this.field.paramValue2, "customValue", "");
        this.$set(this.field.paramValue2, "nodeField", undefined);
        this.$set(this.field.paramValue2, "dynamicTime", undefined);
        this.filterField.value = "";
        this.filterField.valueDetail = "";
      } else if (val == "dynamic") {
        this.$set(this.field.paramValue2, "valueType", "dynamic");
        this.$set(this.field.paramValue2, "customValue", "");
        this.$set(this.field.paramValue2, "nodeField", undefined);
        this.$set(this.field.paramValue2, "dynamicTime", {});
        this.filterField.value = "";
        this.filterField.valueDetail = "";
      } else {
        let valueType = "nodeField";
        if (
          this.filterField.componentName == "date_picker" &&
          val == "between"
        ) {
          valueType = "custom";
        }
        this.$set(this.field.paramValue2, "valueType", valueType);
        this.$set(this.field.paramValue2, "customValue", "");
        this.$set(this.field.paramValue2, "nodeField", {});
        this.$set(this.field.paramValue2, "dynamicTime", {});
        this.filterField.value = "";
        this.filterField.valueDetail = "";
      }
    },
    changeNodeField(value) {
      let obj = this.componentList.find((item) => item.name == value);
      this.$set(
        this.field.paramValue1.nodeField,
        "componentName",
        obj.componentName,
      );

      this.$set(this.field.paramValue2, "valueType", "");
      this.$set(this.field.paramValue2, "customValue", "");
      this.$set(this.field.paramValue2, "nodeField", {});
      this.$set(this.field.paramValue2, "dynamicTime", {});
      this.filterField.value = "";
      this.filterField.valueDetail = "";
      this.filterField.key = value;
      this.filterField.title = obj.title;
      this.filterField.componentName = obj.componentName;
      this.$set(this.field.paramValue1.nodeField, "title", obj.title);

      if (obj.componentName == "id") {
        this.$set(this.field, "oper", "eq");
      }

      if (obj.picker) {
        this.$set(this.field.paramValue1.nodeField, "picker", obj.picker);
        this.filterField.picker = obj.picker;
      }
    },

    buildHookComponents(node) {
      let componentList = [];
      if (node?.type == "trigger_webhook") {
        let arr1 = ["body", "header", "params"];
        arr1.forEach((row) => {
          node[row].forEach((item) => {
            componentList.push({
              componentName: item.componentName,
              name: item.name,
              key: item.name,
              picker: item.picker,
              title: `${row}.${item.title}`,
            });
          });
        });
      }
      return componentList;
    },
    async changeNokeKey(value) {
      let node = this.parentNodes.find((item) => item.key == value);
      let collection = node.formId || node.collection;

      if (node.dataSource == "node_data_field") {
        collection = node.nodeField.referenceCollection;
      }

      this.$set(this.field.paramValue1.nodeField, "collection", collection);
      this.$set(this.field.paramValue1.nodeField, "name", "");
      this.$set(this.field.paramValue1.nodeField, "componentName", "");
      this.$set(this.field.paramValue1.nodeField, "title", "");

      this.$set(this.field.paramValue2, "valueType", "");
      this.$set(this.field.paramValue2, "customValue", "");
      this.$set(this.field.paramValue2, "nodeField", {});
      this.$set(this.field.paramValue2, "dynamicTime", {});
      this.filterField.value = "";
      this.filterField.valueDetail = "";

      await this.buildComponentListByNode(node);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
}
</style>
