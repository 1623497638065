<template>
  <div>
    <el-cascader
      v-if="picker != 'address'"
      :options="areaList"
      style="width: 100%"
      placeholder="请选择地址"
      :props="areaTreeProp"
      v-model="area"
      size="small"
    >
    </el-cascader>
    <el-popover v-else :width="width" v-model="visible">
      <div>
        <el-cascader
          :options="areaList"
          style="width: 100%"
          placeholder="请选择地址"
          :props="areaTreeProp"
          v-model="area"
          size="small"
        >
        </el-cascader>
        <el-input
          v-if="picker == 'address'"
          type="textarea"
          placeholder="请填写详细地址"
          :rows="2"
          resize="none"
          style="width: 100%; margin-top: 10px"
          v-model="address"
        ></el-input>
      </div>
      <div class="el-input el-input--small el-input--suffix" slot="reference">
        <input
          type="text"
          readonly="readonly"
          autocomplete="off"
          placeholder="请选择地址"
          class="el-input__inner"
          :value="addrText"
        />
        <span class="el-input__suffix"
          ><span class="el-input__suffix-inner"
            ><i class="el-input__icon el-icon-arrow-down"></i
          ></span>
        </span>
      </div>
    </el-popover>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
export default {
  directives: { Clickoutside },
  props: {
    value: Object,
    field: Object,
    areaTree: Array,
  },
  computed: {
    picker() {
      return this.field.tableField.picker;
    },
    area: {
      get() {
        let area = [];
        if (
          this.value &&
          typeof this.value === "object" &&
          Object.keys(this.value).length
        ) {
          if (this.picker == "province") {
            area.push(this.value.provinceId);
          } else if (this.picker == "city") {
            area = [this.value.provinceId, this.value.cityId];
          } else {
            area = [
              this.value.provinceId,
              this.value.cityId,
              this.value.countyId,
            ];
          }
        }
        return area;
      },
      set(val) {
        let obj = this._.cloneDeep(this.value);
        if (!obj) {
          obj = {
            province: "",
            provinceId: "",
            city: "",
            cityId: "",
            county: "",
            countyId: "",
            address: "",
          };
        }
        let provinceId = val[0];
        let cityId = val[1];
        let countyId = val[2];
        let provinceItem;
        let cityItem;
        let countyItem;
        if (provinceId) {
          provinceItem = this.areaTree.find((item) => item.id == provinceId);
          obj.provinceId = provinceId;
          obj.province = provinceItem.name;
        }
        if (this.picker != "province") {
          if (cityId) {
            cityItem = provinceItem.children.find((item) => item.id == cityId);
            obj.cityId = cityId;
            obj.city = cityItem.name;
          }
        }
        if (this.picker != "province" && this.picker != "city") {
          if (countyId) {
            countyItem = cityItem.children.find((item) => item.id == countyId);
            obj.countyId = countyId;
            obj.county = countyItem.name;
          }
        }

        if (this.picker != "address") {
          obj.address = "";
        }

        this.$emit("input", obj);
        this.$emit("change", obj);
      },
    },
    address: {
      get() {
        let str = "";
        if (this.value && this.value.address) {
          str = this.value.address;
        }
        return str;
      },
      set(val) {
        let obj = this._.cloneDeep(this.value);
        if (!obj) {
          obj = {
            province: "",
            provinceId: "",
            city: "",
            cityId: "",
            county: "",
            countyId: "",
            address: "",
          };
        }
        obj.address = val;
        this.$emit("input", obj);
        this.$emit("change", obj);
      },
    },
    areaList() {
      let list = [];
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (this.picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (this.picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    addrText() {
      let str = "";
      if (
        this.value &&
        typeof this.value === "object" &&
        Object.keys(this.value).length
      ) {
        if (this.value.province) {
          str += this.value.province;
        }
        if (this.value.city) {
          str += "/";
          str += this.value.city;
        }
        if (this.value.county) {
          str += "/";
          str += this.value.county;
        }
      }
      if (this.address) {
        str += "/";
        str += this.address;
      }
      return str;
    },
  },
  data() {
    return {
      width: 0,
      visible: false,
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.width = this.$el.clientWidth;
    });
  },
  methods: {
    buildTree(soruce) {
      let obj = {};
      for (const [key, value] of Object.entries(soruce)) {
        if (key != "children") {
          obj[key] = value;
        }
      }

      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>