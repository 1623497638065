<template>
  <el-dialog
    title="添加筛选器"
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    width="790px"
    @open="openDialog"
    @close="handleClose"
    :close-on-click-modal="false"
    :show-close="showClose"
  >
    <div
      style="padding: 20px; display: flex; flex-direction: row"
      v-if="dialogVisible"
    >
      <div class="filter-step__wrap">
        <span class="filter-step-title">1.选择需要筛选的图表</span>
        <div class="filter-step-content__wrap">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            size="medium"
            >全选
          </el-checkbox>

          <el-checkbox-group
            v-model="dashboardFilterModel.containerKeys"
            @change="handleCheckedCitiesChange"
            size="medium"
          >
            <el-checkbox
              v-for="item in chartComponents"
              :key="item.key"
              :label="item.key"
              >{{ item.extParam.title }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="filter-step__wrap">
        <span class="filter-step-title">2.选择筛选字段</span>
        <div class="filter-step-content__wrap" v-if="formSet.length">
          <div
            v-for="(form, index) in formSet"
            class="form-item__wrap"
            :key="form.formId"
          >
            <span class="form-item__wrap-title">{{ form.formName }}</span>
            <el-select
              placeholder="请选择"
              size="small"
              v-model="dashboardFilterModel.fields[index].fieldName"
              @change="handleFilterFieldChange($event, index, form)"
              :disabled="
                index > 0 && !dashboardFilterModel.fields[index - 1].fieldName
              "
            >
              <el-option
                v-for="item in handleAllowFieldList(form.formFields, index)"
                :key="item.name"
                :label="item.title"
                :value="item.name"
              >
                <i
                  v-if="item.title == '当前节点'"
                  class="iconfont icon-liucheng"
                ></i>
                <i
                  v-else
                  class="iconfont"
                  :class="componentIcons[item.componentName]"
                ></i>
                <span>{{ item.title }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="filter-step__wrap">
        <span class="filter-step-title"> 3.设置筛选器 </span>
        <div class="filter-step-content__wrap" v-if="firstFilterField">
          <div class="form-item__wrap">
            <span class="form-item__wrap-title">筛选器名称</span>
            <el-input
              v-model="dashboardFilterModel.title"
              placeholder="请输入"
              size="small"
            ></el-input>
          </div>
          <div class="form-item__wrap" v-if="allowChangeFilterDataType">
            <span class="form-item__wrap-title">筛选方式</span>
            <el-radio-group
              v-model="dashboardFilterModel.fieldQuery.valueType"
              size="small"
              @change="dashboardFilterValueTypeChange"
              style="width: 100%"
            >
              <el-radio-button label="tableField">选择选项</el-radio-button>
              <el-radio-button label="custom">输入文本</el-radio-button>
            </el-radio-group>
          </div>
          <div class="form-item__wrap">
            <div>
              <span class="form-item__wrap-title">默认值</span>
              <!-- 操作符start -->
              <el-select
                v-model="dashboardFilterModel.fieldQuery.oper"
                placeholder="操作符"
                size="small"
                style="margin-left: 10px; width: 140px; margin-right: 10px"
                @change="handleFieldQueryOperChange"
                class="oper_select_wrap"
              >
                <el-option
                  v-for="opt in getDisplayQueryOpers(
                    firstFilterField.componentName,
                  )"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option>
              </el-select>

              <!-- 默认值设置 -->
              <div
                v-if="
                  !hideFilterValueSetting &&
                  (dashboardFilterModel.fieldQuery.componentName ===
                    'user_select' ||
                    dashboardFilterModel.fieldQuery.componentName ===
                      'user_list_select')
                "
              >
                <el-select
                  v-model="
                    dashboardFilterModel.fieldQuery.valueContainsCurrentUser
                  "
                  placeholder=""
                  size="mini"
                  style="margin-bottom: 5px; width: 100%"
                >
                  <el-option
                    v-for="item in valueTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="form-item__wrap"
                v-if="
                  !hideFilterValueSetting &&
                  !dashboardFilterModel.fieldQuery.valueContainsCurrentUser
                "
              >
                <!-- 操作符end -->
                <query-input
                  v-if="dashboardFilterModel.fieldQuery.valueType === 'custom'"
                  :field="dashboardFilterModel.fieldQuery"
                  style="flex: 1; width: 100%"
                  v-model="dashboardFilterModel.fieldQuery.value"
                ></query-input>
                <address-select
                  v-if="
                    dashboardFilterModel.fieldQuery.valueType ===
                      'tableField' && isUseAddressComponent
                  "
                  size="small"
                  v-model="dashboardFilterModel.fieldQuery.value"
                  :areaTreeList="areaTreeList"
                  :areaProps="areaProps"
                  style="width: 100%"
                >
                </address-select>
                <field-value-select
                  v-if="
                    dashboardFilterModel.fieldQuery.valueType ===
                      'tableField' && !isUseAddressComponent
                  "
                  :fieldName="dashboardFilterModel.fields[0].fieldName"
                  :fetchParams="fetchParams"
                  size="small"
                  :multi="isMulti"
                  style="flex: 1"
                  v-model="dashboardFilterModel.fieldQuery.value"
                  ref="fieldValueSelect"
                ></field-value-select>
              </div>
            </div>
          </div>
          <div class="form-item__wrap">
            <span class="form-item__wrap-title">筛选联动</span>
            <span v-show="filterComponents.length <= 0" style="color: #b5b8be"
              >没有可以联动的筛选组件</span
            >
            <el-select
              v-show="filterComponents.length > 0"
              v-model="dashboardFilterModel.filterKeys"
              placeholder="请选择"
              multiple
              style="width: 99%"
              size="small"
            >
              <el-option
                v-for="item in filterComponents"
                :key="item.key"
                :label="item.title"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="saveForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
//文本类型的筛选器
import { fetchFormDataList, getAggreation } from "@/views/lowCode/form/api";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import { getComponentQueryOper } from "@zgg-core-utils/whiteList";
import { fetchMetaFieldComponentList } from "@/api/form";

import {
  allAllowFieldTypeEnums,
  allAllowFieldTypeEnumsMatrix,
} from "./dashboard-filter-field-category-const";
import { isChartComponent } from "@/views/lowCode/dashboard/dashboard-util";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { getTreeData, componentsIcon } from "@zgg-core-utils/utils";

export default {
  name: "DashboardFilterSetting",
  props: {
    dataComponentList: Array,
    filterComponentList: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      fetchDataList: (a) => {
        console.log(a);
        let param = {
          ...a,
          queryObject: {
            ...a,
          },
          collection: this.dashboardFilterModel.fields[0].collection,
        };
        return fetchFormDataList(param);
      },
      fetchAggreation: (a) => {
        let xFields = [
          {
            field: {
              name: this.dashboardFilterModel.fields[0].fieldName,
              componentName: this.dashboardFilterModel.fields[0].componentName,
            },
          },
        ];
        let advanceQuery = [
          {
            key: this.dashboardFilterModel.fields[0].fieldName,
            oper: "nnil",
          },
        ];
        if (a && a.queryObject) {
          a.queryObject.xFields = a.queryObject.xFields
            ? a.queryObject.xFields.concat(xFields)
            : xFields;
          a.queryObject.advanceQuery = a.queryObject.advanceQuery
            ? a.queryObject.advanceQuery.concat(advanceQuery)
            : advanceQuery;
        }
        let param = {
          ...a,
          collection: this.dashboardFilterModel.fields[0].collection,
          cmd: "page",
        };
        return getAggreation(param);
      },
    };
  },
  data() {
    return {
      title: "",
      formId: "",
      components: [
        {
          label: "筛选组件",
          value: "dashboard_filter",
        },
      ],
      checkAll: false,
      isIndeterminate: false,
      formSet: [],
      //custom 输入文本  item 选择选项
      filterDataType: "custom",
      dashboardFilterModel: {
        title: "",
        containerKeys: [],
        filterKeys: [],
        fields: [],
        fieldQuery: {
          valueType: "",
          value: "",
          oper: "",
        },
        componentName: "dashboard_filter",
      },
      formFieldCache: {},
      valueTypeOptions: [
        {
          value: false,
          label: "自定义",
        },
        {
          value: true,
          label: "默认选择当前用户",
        },
      ],
      componentIcons: componentsIcon,
    };
  },
  watch: {
    "dashboardFilterModel.title": {
      handler(val) {
        if (this.dashboardFilterModel.extParam) {
          this.dashboardFilterModel.extParam.title = val;
        }
      },
    },
    /**
     * 处理被选中的图表的数组的变化
     */
    "dashboardFilterModel.containerKeys": {
      deep: true,
      handler(val) {
        this.fetchFormDetail();
      },
    },
    formSet: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log("formSet", val);
        //如果有两个及以上的筛选字段，只允许自定义输入，不允许选择本表字段数据
        // if (val.length > 1) {
        //   this.dashboardFilterModel.fieldQuery.valueType = 'custom'
        // }
      },
    },
    hideFilterValueSetting: {
      handler(val) {
        if (val) {
          this.dashboardFilterModel.fieldQuery.valueContainsCurrentUser = false;
        }
      },
    },
    /**
     * 仪表盘过滤组件的对象模型
     */
    dashboardFilterModel: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        console.log("dashboardFilterModel", val, oldVal);
      },
    },
    "dashboardFilterModel.fieldQuery.valueType": {
      immediate: false,
      handler(val, oldVal) {
        console.log("dashboardFilterModel.fieldQuery.valueType", val, oldVal);
        if (
          !isEmpty(this.dashboardFilterModel.fieldQuery.value) &&
          val &&
          oldVal
        ) {
          this.handleFieldQueryOperChange();
        }
      },
    },
    "dashboardFilterModel.fieldQuery": {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        console.log("dashboardFilterModel.fieldQuery", val, oldVal);
        if (val.componentName == "date_picker") {
          this.dashboardFilterModel.fieldQuery.valueType = "custom";
        }
      },
    },
    "dashboardFilterModel.fieldQuery.componentName": {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        console.log(
          "dashboardFilterModel.fieldQuery.componentName",
          val,
          oldVal,
        );
        if (val && oldVal) {
          this.dashboardFilterModel.fieldQuery.oper = "";
        }
      },
    },
    "dashboardFilterModel.fieldQuery.valueContainsCurrentUser": {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        if (
          ["user_list_select", "user_select"].includes(
            this.dashboardFilterModel.fieldQuery.componentName,
          )
        ) {
          if (val) {
            // 当前用户
            this.dashboardFilterModel.fieldQuery.value = this.currentUser.id;
            this.dashboardFilterModel.fieldQuery.valueDetail = {};
          } else {
            this.dashboardFilterModel.fieldQuery.value = "";
          }
        }
      },
    },
    filterComponents: {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        // 剔除掉无效的key
        this.dashboardFilterModel.filterKeys =
          this.dashboardFilterModel.filterKeys.filter((key) =>
            this.filterComponents.some((comp) => comp.key === key),
          );
      },
    },
  },
  computed: {
    fetchParams() {
      if (this.dashboardFilterModel?.fieldQuery?.valueType === "tableField") {
        let componentName = this.dashboardFilterModel.fieldQuery.componentName;
        return {
          queryObject: {
            advanceQuery: [
              {
                key: this.dashboardFilterModel.fieldQuery.key,
                oper: componentName == "input_number" ? "eq" : "like", //数值组件不支持模糊搜索
                componentName,
                value: "",
              },
            ],
          },
        };
      }
    },
    ...mapState("area", ["areaTree", "areaProps"]),
    ...mapGetters(["currentUser"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    /**
     * 第一个过滤字段
     * @returns {{}|*}
     */
    firstFilterField() {
      let firstFilterField = this.dashboardFilterModel.fields;
      if (
        firstFilterField &&
        firstFilterField.length > 0 &&
        firstFilterField[0].fieldName
      ) {
        return firstFilterField[0];
      }
      return null;
    },
    /**
     * 是否允许切换筛选方式
     * 目前仅当筛选字段数量为一个时，且该筛选字段非人员
     * @returns {boolean}
     */
    allowChangeFilterDataType() {
      let fieldLength = this.dashboardFilterModel.fields.length;
      return (
        fieldLength > 0 &&
        ![
          "user_select",
          "user_list_select",
          "department_select",
          "department_list_select",
          "date_picker",
        ].includes(this.dashboardFilterModel.fields[0].componentName)
      );
    },
    isMulti() {
      return (
        this.dashboardFilterModel.fieldQuery.valueType == "tableField" &&
        ["in", "nin"].includes(this.dashboardFilterModel.fieldQuery.oper)
      );
    },
    /**
     * 是否显示默认值的值设置，当操作符为空和不为空时，隐藏设置
     */
    hideFilterValueSetting() {
      return ["nil", "nnil"].includes(
        this.dashboardFilterModel.fieldQuery.oper,
      );
    },
    /**
     * 只显示图表组件
     */
    chartComponents() {
      return this.dataComponentList.filter(
        (component) =>
          isChartComponent(component.componentName) ||
          component.componentName === "table",
      );
    },
    areaTreeList() {
      return getTreeData(this.areaTree);
    },
    /**
     * 只显示除了自己外的过滤组件
     */
    filterComponents() {
      return this.filterComponentList.filter((comp) => {
        return (
          comp.key !== this.dashboardFilterModel.key &&
          this.allowLinkAge(this.dashboardFilterModel, comp)
        );
      });
    },
    /**
     * 是否按地址或定位地址过滤
     */
    isUseAddressComponent() {
      const componentName = this.dashboardFilterModel.fields[0].componentName;
      return (
        componentName === "address_input" || componentName === "position_input"
      );
    },
  },
  methods: {
    /**
     * 校验要提交的数据
     */
    validate() {
      console.log(this.dashboardFilterModel);
      const { containerKeys, fieldQuery, fields, title } =
        this.dashboardFilterModel;
      return new Promise((resolve, reject) => {
        if (containerKeys.length == 0) {
          this.$message.warning("请选择需要筛选的图表");
          return reject();
        }
        if (fields.length == 0) {
          this.$message.warning("请选择筛选字段");
          return reject();
        } else {
          if (fields.find((field) => isEmpty(field.fieldName))) {
            this.$message.warning("请选择筛选字段");
            return reject();
          }
        }
        if (isEmpty(title)) {
          this.$message.warning("标题不能为空");
          return reject();
        }
        if (isEmpty(fieldQuery.oper)) {
          this.$message.warning("操作符不能为空");
          return reject();
        }
        resolve();
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },
    /**
     * 查询运算符发生改变时，需要初始化值
     * @param val
     */
    handleFieldQueryOperChange(val) {
      if (!val) {
        val = this.dashboardFilterModel.fieldQuery.oper;
      }
      if (["in", "nin"].includes(val)) {
        this.dashboardFilterModel.fieldQuery.value = [];
      } else if (["between"].includes(val)) {
        this.dashboardFilterModel.fieldQuery.value = [null, null];
      } else {
        this.dashboardFilterModel.fieldQuery.value = "";
      }
    },
    saveForm() {
      console.log(this.dashboardFilterModel);
      this.validate()
        .then((resp) => {
          console.log("成功");
          this.$emit("save", this.dashboardFilterModel);
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      Object.assign(this.$data, this.$options.data());
    },
    openDialog() {},
    /**
     * 处理筛选字段值的变化
     */
    handleFilterFieldChange(e, index, form) {
      let { formFields } = form;
      let currentField = formFields.find((field) => field.name == e);
      if (currentField) {
        this.dashboardFilterModel.fields[index].componentName =
          currentField.componentName;
      }
      //手动初始化valueType，如果只选择了一个表单项，可以选择不同的默认值类型，如果有多个表单项，则只能用custom
      if (this.dashboardFilterModel.fields.length > 1) {
        this.dashboardFilterModel.fieldQuery.valueType = "custom";
      } else {
        if (!this.dashboardFilterModel.fieldQuery.valueType) {
          this.dashboardFilterModel.fieldQuery.valueType = "tableField";
        }
      }
      if (
        [
          "user_select",
          "user_list_select",
          "department_select",
          "department_list_select",
        ].includes(this.dashboardFilterModel.fields[0].componentName)
      ) {
        this.dashboardFilterModel.fieldQuery.valueType = "custom";
      }
      this.dashboardFilterModel.fieldQuery.valueContainsCurrentUser = false;
      console.log(e, index, form, currentField);
      if (isEmpty(this.dashboardFilterModel.title)) {
        this.dashboardFilterModel.title = currentField.title;
      }
      //如果第一个字段发生变化，则需要重新别的筛选字段
      if (index == 0) {
        this.dashboardFilterModel.fieldQuery = {
          ...this.dashboardFilterModel.fieldQuery,
          ...currentField,
        };
        this.dashboardFilterModel.fields.forEach((item, index) => {
          if (index > 0) {
            item.fieldName = "";
            item.componentName = "";
          }
        });
        let operVal = this.dashboardFilterModel.fieldQuery.oper;
        if (["in", "nin"].includes(operVal)) {
          this.dashboardFilterModel.fieldQuery.value = [];
        } else {
          this.dashboardFilterModel.fieldQuery.value = "";
        }
      }
      this.$nextTick(() => {
        this.$refs.fieldValueSelect &&
          this.$refs.fieldValueSelect.loadingList();
      });
    },
    dashboardFilterValueTypeChange() {
      console.log("dashboardFilterValueTypeChange");
    },
    manualInit(dataFilterModel) {
      this.dialogVisible = true;
      this.$set(
        this,
        "dashboardFilterModel",
        this._.cloneDeep(dataFilterModel),
      );
    },
    getDisplayQueryOpers(componentName) {
      if (componentName) {
        let list = getComponentQueryOper(componentName);
        if (
          this.dashboardFilterModel.fields.some(
            (item) => item.fieldName == "currentFlowNodeTitles",
          )
        ) {
          list = list.filter((row) => row.value != "eq");
        }
        return list;
      }
      return [];
    },
    /**
     * 处理允许展示的表单项列表
     * @param sourceFields
     * @param index
     * @returns {*}
     */
    handleAllowFieldList(sourceFields, index) {
      sourceFields = sourceFields.filter((item) => {
        return allAllowFieldTypeEnums.includes(item.componentName);
      });

      //如果是非第一个
      if (index != 0 && this.dashboardFilterModel.fields.length) {
        const firstComponentName =
          this.dashboardFilterModel.fields[0].componentName;
        //获取当前表单项的分类数组
        let currentFieldCategoryList =
          this.getCurrentFieldCategoryList(firstComponentName);
        sourceFields = sourceFields.filter(
          (item) =>
            currentFieldCategoryList &&
            currentFieldCategoryList.includes(item.componentName),
        );
      }
      return sourceFields;
    },
    /**
     * 根据上面的表单项分类，获取对应数组，然后判断传入的componentName是否属于哪个表单项分类，获取同类型的表单项
     */
    getCurrentFieldCategoryList(componentName) {
      for (let i = 0; i < allAllowFieldTypeEnumsMatrix.length; i++) {
        let item = allAllowFieldTypeEnumsMatrix[i];
        if (item.includes(componentName)) {
          return item;
        }
      }
    },

    /**
     * 获取表单详情
     * @returns {Promise<void>}
     */
    async fetchFormDetail() {
      let formSet = {};
      //获取选中的仪表盘
      this.dashboardFilterModel.containerKeys.forEach((dataModuleItemKey) => {
        let dataComponentItem = this.chartComponents.find(
          (item) => item.key == dataModuleItemKey,
        );
        if (dataComponentItem) {
          let formId = dataComponentItem.dataSource.formId;

          if (!formSet[formId]) {
            formSet[formId] = {
              formId,
              formName: dataComponentItem.extParam.title,
              formFields: [],
            };
          }
        }
      });
      let keys = Object.keys(formSet);
      let formSetArray = [];
      let promiseArray = [];
      //去重
      let formIds = [];
      keys.forEach((formId) => {
        if (!this.formFieldCache[formId]) {
          formIds.push(formId);
          let arr = [];
          if (
            formId.indexOf("dataflow_") == -1 &&
            formId.indexOf("at_") == -1
          ) {
            arr = arr.concat([
              "flowStatus",
              "currentFlowNodeTitles",
              "currentFlowWorkUserIds",
            ]);
          }
          promiseArray.push(fetchMetaFieldComponentList(formId, arr));
        }
      });

      await Promise.all(promiseArray).then((res) => {
        res.forEach((item, index) => {
          let formId = formIds[index];
          this.formFieldCache[formId] = item.data.list;
        });
      });
      Object.keys(formSet).forEach((formId) => {
        let list = this.formFieldCache[formId];
        if (list) {
          let formFields = list;
          let formName = formSet[formId].formName;

          formSetArray.push({
            formId,
            formName,
            formFields,
          });
        }
      });

      //根据formSet初始化fields
      let fields = this._.cloneDeep(this.dashboardFilterModel.fields);
      let newFields = [];
      formSetArray.forEach((form) => {
        let { formId, formName } = form;
        //获取已有的配置
        let filterFieldConfig = fields.find(
          (item) => item.collection == formId,
        );
        if (filterFieldConfig) {
          newFields.push(filterFieldConfig);
        } else {
          newFields.push({
            collection: formId,
            fieldName: "",
            componentName: "",
          });
        }
      });
      this.dashboardFilterModel.fields = newFields;
      this.$set(this, "formSet", formSetArray);
    },
    handleCheckAllChange(val) {
      this.dashboardFilterModel.containerKeys = val
        ? this.chartComponents.map((item) => item.key)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.chartComponents.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.chartComponents.length;
    },
    /**
     * 判断两个过滤组件是否可以筛选联动
     * a: 当前组件
     * b: 被联动的组件
     */
    allowLinkAge(a, b) {
      // a筛选的图表包含b则b可以作为a的筛选联动组件
      return b.containerKeys.every((item) => a.containerKeys.includes(item));
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-step__wrap {
  width: 33%;
  height: 424px;
  padding: 0 10px;
  vertical-align: top;
  border-right: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;

  &:last-child {
    border-right: none;
  }

  .filter-step-title {
    color: #1f2d3d;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .filter-step-content__wrap {
    margin: 4px 0;
    flex: 1;
    height: 0;
    overflow: auto;

    .el-checkbox-group {
      display: flex;
      flex-direction: column;

      .el-checkbox {
        padding: 5px 0;
        line-height: 20px;
      }
    }
  }

  .form-item__wrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    .form-item__wrap-title {
      margin-bottom: 6px;
      color: #1f2d3d;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.oper_select_wrap {
  ::v-deep .el-input__inner {
    border: none;
    box-shadow: none;
  }
}
</style>
