<template>
  <div class="container">
    <preview-error v-if="isError"></preview-error>
    <etl-preview
      v-else
      :node="node"
      :etlList="etlList"
      :etlLines="etlLines"
    ></etl-preview>
  </div>
</template>
<script>
import PreviewError from "./PreviewError";
import EtlPreview from "./EtlPreview";
import nodeMixin from "./nodeMixin";
export default {
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },
  components: { PreviewError, EtlPreview },
  mixins: [nodeMixin],
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
}
</style>
