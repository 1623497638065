<template>
  <div class="container">
    <div class="sidebar">
      <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @select="changePath"
      >
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">数据名称</span>
        </el-menu-item>
<!--        <el-menu-item index="2">-->
<!--          <i class="el-icon-setting"></i>-->
<!--          <span slot="title">数据推送</span>-->
<!--        </el-menu-item>-->
      </el-menu>
    </div>
    <div class="detail">
      <div class="dataAlias" v-if="index=='1'">
        <span class="title">表单别名</span>
        <p class="tip">通过设置表单别名，使得他们在API、WebHook等场景使用的时候更有辨识度。</p>
        <span class="tip comment" style="padding: 0 0 10px 0">注：表单别名仅允许使用
        <h4>「字母」「数字」</h4>和
        <h4>「下划线」</h4>组合，且必须以
        <h4>「小写字母」</h4>开头。</span>
        <el-input v-model="formAlias"
                  placeholder="请输入内容"
                  style="width: 300px"
                  clearable
                  @input="validateAlias($event,'')"
                  @blur="saveFormAlias"
        ></el-input>
        <span class="title">字段别名</span>
        <p class="tip">通过设置字段别名，使得他们在API、WebHook等场景使用的时候更有辨识度。</p>
        <el-button type="primary"style="width: 120px" size="medium" @click="setAlias">设置字段别名</el-button>
      </div>
    </div>
    <el-dialog title="设置字段别名" :visible.sync="dialogTableVisible">
      <span class="comment">注：字段别名仅允许使用
        <h4>「字母」「数字」</h4>和
        <h4>「下划线」</h4>组合，且必须以
        <h4>「小写字母」</h4>开头。</span>
      <el-table :data="gridData" height="600px" border>
        <el-table-column property="comment" label="字段名称" width="150"></el-table-column>
        <el-table-column property="name" label="字段ID" width="200"></el-table-column>
        <el-table-column property="fieldType" label="字段类型"></el-table-column>
        <el-table-column label="字段别名">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.aliasName"
                @blur="checkAliasName(scope.row)"
                @input="validateAlias($event,scope.row)"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fetchMetaFieldList } from '@/api/form'
import { saveaAliasField, getFormAlias, saveFormAlias } from "./api"

export default {

  data() {
    return {
      formAlias:'',
      index:"1",
      dialogTableVisible:false,
      gridData:[]
    };
  },
  created() {
    getFormAlias({
      tableName:this.$route.query.id
    }).then((res)=>{
      let item = res.data.metaTable
      if(item && item.aliasName){
        this.formAlias = item.aliasName
      }
    })

  },
  methods: {
    validateAlias(e,row) {
      let strArray = e.match(/^[a-z][a-zA-Z0-9_]*$/g)
      if(row) {
        row.aliasName = strArray?strArray[0]:''
      } else {
        this.formAlias = strArray?strArray[0]:''
      }
    },
    saveFormAlias() {
      if(!this.formAlias) {
        this.$message.warning("表单别名不能为空")
        return
      }
      saveFormAlias({
        tableName:this.$route.query.id,
        aliasName:this.formAlias
      }).then(()=>{
        this.$message.success("设置表单别名成功")
      })
    },
    checkAliasName(val) {
      let item = this.gridData.find((row)=>{
        return row.aliasName == val.aliasName && row.aliasName && row.name != val.name
      })
      if(item){
        this.$message.warning("字段别名不能重复")
      }
    },
    save() {
      let data = this.gridData.map((item)=>{
          return {
            fieldName:item.name,
            aliasName:item.aliasName
          }
      })
      saveaAliasField({
        tableName:this.$route.query.id,
        fieldContent:data
      }).then(()=>{
        this.dialogTableVisible = false
      })
    },
    setAlias() {
      fetchMetaFieldList({
        tableName:this.$route.query.id,
        returnWithSystemField:false
      }).then((res)=>{
        this.gridData = res.data.list
        this.dialogTableVisible = true
      })

    },
    changePath(index) {
      this.index = index
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  display: flex;
  height: calc(100vh - 111px);
  position: relative;
}
.sidebar {
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px 0 10px 10px;
}
.detail {
  flex: 5;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px;
}
.el-menu {
  flex-direction: column;
  border-right:none;
  margin: 20px;
}
.dataAlias {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.tip {
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #A1A1A1;
}
.title {
  margin-top: 10px;
  font-family: Noto Sans SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #333333;
}
.comment {
  display: flex;
  flex-direction: row;
  padding: 10px;
  color: #A1A1A1;
  h4 {
    color: black;
    margin: 0;
  }
}
</style>
