<template>
  <div>
    <el-card class="column-item" id="boardSaveCard" shadow="always">
      <el-form v-if="visible" :model="form" ref="form" :rules="rules">
        <el-form-item prop="title">
          <el-input
            ref="formTitle"
            v-model="form.title"
            placeholder="请输入分组名称"
            @keyup.enter.native="confirm"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-link
        style="width: 100%"
        v-else
        :underline="false"
        icon="el-icon-plus"
        @click="visible = true"
        >添加分组
      </el-link>
    </el-card>
    <div v-show="visible" class="column-btn">
      <el-button @click="cancel" size="small" style="margin-right: 15px"
        >取消
      </el-button>
      <el-button @click="confirm" :loading="loading" size="small" type="primary"
        >确定
      </el-button>
    </div>
  </div>
</template>
<script>
import { bulletinColumnSave } from "@/api/boardManage";

export default {
  props: {
    bulletinId: [String, Number],
  },
  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      rules: {
        title: [
          {
            required: true,
            message: "请输入分组名称",
          },
        ],
      },
    };
  },
  watch: {
    visible: {
      handler(val, oldVal) {
        if (val) {
          setTimeout(() => {
            this.$refs.formTitle && this.$refs.formTitle.focus();
          }, 1);
        }
      },
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.form = {};
      //   this.$refs.form.clearValidate
    },
    confirm() {
      console.log("confirm");
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        this.loading = true;
        bulletinColumnSave({
          model: JSON.stringify({
            title: this.form.title,
            bulletinId: this.bulletinId,
          }),
        })
          .then((res) => {
            this.loading = false;
            this.visible = false;
            document.getElementById("boardSaveCard").scrollIntoView();
            this.$emit("addColumn", res.data.model);
            this.form.title = ""; //清空上一次的title
          })
          .catch(() => {
            this.loading = false;
            this.form.title = ""; //清空上一次的title
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.column-item {
  width: 300px;
}

.column-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
