import request from "@/utils/request";
import store from "@/store";
import qs from "qs";

/**
 * 保存抽屉设置
 * @param data
 * @returns {*}
 */
export function workDirSaveSetting(data) {
  return request({
    url: "/app/workDir/saveSetting",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}
/**
 * 获取抽屉设置
 * @param data
 * @returns {*}
 */
export function workDirLoadSetting(data) {
  return request({
    url: "/app/workDir/loadSetting",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}
// 获取文件夹树
export function workDirTree(data) {
  return request({
    url: "/app/workDir/tree",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

// 新建文件夹
export function workDirRegister(data) {
  return request({
    url: "/app/workDir/register",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

// 重命名
export function workDirRename(data) {
  return request({
    url: "/app/workDir/rename",
    method: "POST",
    data,
  });
}

// 修改文件夹名和图标
export function modifyTitleAndIconDir(data) {
  return request({
    url: "app/workDir/modifyTitleAndIconDir",
    method: "POST",
    data,
  });
}

// 移动
export function workDirMove(data) {
  return request({
    url: "/app/workDir/move",
    method: "POST",
    data,
  });
}

// 删除
export function workDirDelete(data) {
  return request({
    url: "/app/workDir/delete",
    method: "POST",
    data,
  });
}
// 删除单位目录资源
export function workDirDeleteRes(data) {
  return request({
    url: "/app/workDir/deleteRes",
    method: "POST",
    data,
  });
}

// 群操作接口

// 获取群类型接口
export function listRecordType(data) {
  return request({
    url: "/app/workDir/listRecordType",
    method: "POST",
    data,
  });
}

// 重命名资源
export function renameRes(data) {
  return request({
    url: "/app/workDir/renameRes",
    method: "POST",
    data,
  });
}

// 移动资源
export function moveRes(data) {
  return request({
    url: "/app/workDir/moveRes",
    method: "POST",
    data,
  });
}

// 新建(实例化)
export function viewCreate(data) {
  return request({
    url: "/app/view/create?_version=2.0",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}
//新建智能填表
export function planFormViewCreate(data) {
  return request({
    url: "/app/view/createByPlanForm",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}

// 单位通讯录列表

export function companyList(data) {
  return request({
    url: "/company/list",
    method: "POST",
    data,
  });
}

//员工列表
export function companyEmployeeList(data) {
  return request({
    url: "/company/employee/list",
    method: "post",
    data: qs.stringify(data),
  });
}

export function departmentList(data) {
  return request({
    url: "/company/department/list",
    method: "POST",
    data: qs.stringify(data),
  });
}

/*
recordType=app_view(必填，此处固定值app_view)&recordId=视图ID(必填)&userIds=用户ID数组(举例: [1,3])(必填)
设置管理员
 */
export function resetAdmin(data) {
  return request({
    url: "/record/resetAdmin",
    method: "POST",
    data: qs.stringify(data),
  });
}

// 获取文件夹首页列表
export function workDirHomePageTree(data) {
  return request({
    url: "/app/workDir/homepageTree",
    method: "POST",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}
// 设置为首页
export function workDirResToHomepage(data) {
  return request({
    url: "/app/workDir/resToHomepage",
    method: "POST",
    data,
  });
}
// 取消设置为首页
export function workDirCancelHomepage(data) {
  return request({
    url: "/app/workDir/cancelHomepage",
    method: "POST",
    data,
  });
}

// 首页排序
export function workDirSortHomepage(data) {
  return request({
    url: "/app/workDir/sortHomepage",
    method: "POST",
    data,
  });
}

// 修改资源名和图标
export function modifyTitleAndIconDirRes(data) {
  return request({
    url: "app/workDir/modifyTitleAndIconDirRes",
    method: "POST",
    data,
  });
}

// 修改资源名和图标  "companyId": 0, "dirIds": [],"hidden": false,"resIds": []
export function setHidden(data) {
  return request({
    url: "app/workDir/setHidden",
    method: "POST",
    data,
  });
}
