<template>
  <div>
    <el-button
      v-if="hasDeleteColumn"
      type="danger"
      size="small"
      style="margin: 10px"
      @click="handleDelField"
      >清除已删除字段</el-button
    >
    <div class="row weui between">
      <div class="label" style="font-size: 13px">层级配置</div>
      <el-button
        v-if="hasParentComponent"
        @click="addLevel"
        type="text"
        icon="el-icon-plus"
        size="small"
        >添加层级</el-button
      >
    </div>
    <div style="padding: 0px 10px">
      <div v-for="(row, index) in columns" :key="row.key">
        <div class="row weui between">
          <div
            class="label"
            style="margin-bottom: 8px; font-size: 12px"
            v-text="index + 1 + '级'"
          ></div>
          <i
            @click="deleteColumn(index)"
            v-if="columns.length > 1 && hasParentComponent"
            class="el-icon-delete"
          ></i>
        </div>

        <div style="padding: 0 10px">
          <el-checkbox-group
            class="custom-radio"
            :value="getItemValue(row.components)"
          >
            <draggable
              :list="row.components"
              handle=".el-icon-rank"
              animation="300"
              :disabled="!hasParentComponent"
            >
              <div
                v-for="item in row.components"
                :key="item.field.name + '_' + index"
                class="weui"
                :class="{ 'is-error': isDeleteField(item.field.name) }"
                style="margin-bottom: 8px"
              >
                <el-checkbox
                  :label="item.field.name"
                  style="margin-right: 5px"
                  @change="change($event, item, index)"
                  :disabled="!hasParentComponent"
                ></el-checkbox>
                <el-input
                  v-model="item.title"
                  :placeholder="item.field.title"
                  size="small"
                  :disabled="!hasParentComponent"
                ></el-input>
                <i
                  v-if="hasParentComponent"
                  class="el-icon-rank ml"
                  style="font-size: 16px; cursor: pointer"
                ></i>
              </div>
            </draggable>
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  components: { Draggable },
  props: {
    components: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    hasParentComponent: Boolean,
  },
  computed: {
    hasDeleteColumn() {
      let has = false;
      this.columns.forEach((item) => {
        if (item.components) {
          item.components.forEach((field) => {
            if (
              this.components.findIndex(
                (col) => col.field.name == field.field.name,
              ) == -1
            ) {
              has = true;
            }
          });
        }
      });
      return has;
    },
  },
  data() {
    return {
      values: [],
      treeNodes: [],
    };
  },

  methods: {
    deleteColumn(index) {
      this.$confirm(`确定要删除${index + 1}级吗?`, "提示")
        .then(() => {
          this.$delete(this.columns, index);
        })
        .catch(() => {});
    },
    isDeleteField(fieldName) {
      return (
        this.components.findIndex((item) => item.field.name == fieldName) == -1
      );
    },
    handleDelField() {
      this.columns.forEach((item) => {
        item.components = item.components.filter(
          (field) =>
            this.components.findIndex(
              (col) => col.field.name == field.field.name,
            ) >= 0,
        );
      });
    },
    addLevel() {
      let item = this._.cloneDeep(this.columns[0]);
      delete item.components;
      item.components = this._.cloneDeep(this.components);
      item.components.forEach((element) => {
        element.hidden = false;
      });
      item.key = this.getUuid();
      this.columns.push(item);
    },
    getItemValue(subColumns) {
      let values = subColumns
        .filter((item) => !item.hidden)
        .map((item) => item.field.name);

      return values;
    },

    change(val, row, index) {
      row.hidden = !val;
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 10px;
  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
  }
}
.weui {
  display: flex;
  align-items: center;
}
.between {
  justify-content: space-between;
}
.ml {
  margin-left: 10px;
}
.is-error {
  ::v-deep {
    .el-input__inner {
      border-color: #f56c6c;
      color: #f56c6c;
    }
  }
}

.custom-radio {
  width: 100%;
  ::v-deep {
    .el-radio,
    .el-checkbox {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    .el-radio__label,
    .el-checkbox__label {
      padding-left: 0px;
      flex: 1;
      margin-left: 5px;
      border: solid 1px #e6e6e6;
      padding: 5px 10px;
      border-radius: 5px;
      display: none;
    }
  }
}
</style>
