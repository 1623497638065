<template>
    <div
      style="
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 32px 68px;
      "
    >
        <div class="label">短信平台设置</div>
        <div class="description"></div>
        <div class="content">
            <div v-if="platformList.length < 2" style="padding-bottom: 20px;">
                <el-button
                    type="primary"
                    @click="addPlatform"
                    >添加短信平台
                </el-button>
            </div>
            <div class="form-item" v-for="(item, index) in platformList" :key="index">
                <el-form
                    :model="item"
                    label-position="right"
                    label-width="100px"
                    :rules="rules"
                    ref="form"
                    class="form-content"
                >
                    <el-form-item label="平台类型" prop="smsType">
                        <el-select v-model="item.smsType" placeholder="请选择" :disabled="item.id != '' && item.id != null">
                            <el-option
                                v-for="opt in getPlatformTypes()"
                                :key="opt.name"
                                :label="opt.remark"
                                :value="opt.code"
                                :disabled="opt.disabled"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="item.id" label="是否启用">
                        <el-switch
                            v-model="item.enabled"
                            @change="changeEnabled(item, $event)"
                        ></el-switch>
                    </el-form-item>
                    <el-form-item
                        label="key"
                        prop="accessKeyId"
                    >
                        <el-input
                        placeholder="请输入内容"
                        v-model="item.accessKeyId"
                        :disabled="item.isDisabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="密钥"
                        prop="accessKeySecret"
                    >
                        <el-input
                        placeholder="请输入内容"
                        v-model="item.accessKeySecret"
                        :disabled="item.isDisabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="区域"
                        prop="regionId"
                    >
                        <el-input
                        placeholder="请输入内容"
                        v-model="item.regionId"
                        :disabled="item.isDisabled"
                        ></el-input>
                    </el-form-item>
                    <template v-if="item.smsType == 20">
                        <el-form-item
                            label="应用ID"
                            prop="sdkAppId"
                        >
                            <el-input
                            placeholder="请输入内容"
                            v-model="item.sdkAppId"
                            :disabled="item.isDisabled"
                            ></el-input>
                        </el-form-item>
                    </template>
                    <el-form-item
                        label="短信签名"
                        prop="signName"
                    >
                        <el-input
                        placeholder="请输入内容"
                        v-model="item.signName"
                        :disabled="item.isDisabled"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div style="padding-left: 100px;">
                    <el-button type="primary" @click="savePlatformConfig(item, index)">
                        {{ !item.isDisabled ? "保 存" : "编 辑"  }}
                    </el-button>
                    <el-button v-if="item.id" type="primary" @click="smsSetting(item)">配置短信模版</el-button>
                    <!-- <el-button v-if="item.id" type="danger" @click="deletePlatformConfig(item)">删 除</el-button> -->
                </div>
            </div>
        </div>

        <!-- 短信模板 -->
        <el-dialog
            title="短信模板"
            :visible.sync="smsDialogVisible"
        >
            <div style="padding: 10px 20px 20px;">
                <div v-if="isEmptyTpl">
                    <div>
                        短信平台:  {{ smsType === 10 ? '阿里云' : '腾讯云'}}
                    </div>
                    <el-button type="text" @click="createNewTemplate" icon="el-icon-plus">创建新模板</el-button>
                    <el-table :data="smsTemplateList" height="300" border>
                        <el-table-column property="sysCodeName" label="短信通知类型"></el-table-column>
                        <el-table-column property="templateName" label="模板标题"></el-table-column>
                        <el-table-column property="templateStatusName" label="审核状态"></el-table-column>
                        <el-table-column property="reason" label="失败原因"></el-table-column>
                        <el-table-column label="操作" align="center">
                        <template slot-scope="{ row }">
                            <el-tag
                                type="danger"
                                size="mini"
                                style="cursor: pointer"
                                @click="deleteTemplate(row)"
                            >删除
                            </el-tag>
                            <el-tag
                                v-if="row.templateStatus != 0"
                                size="mini"
                                style="cursor: pointer"
                                @click="forSmsTemplateDetail(row)"
                            >
                            {{ row.templateStatus == 1 ? '查看' : '编辑'}}
                            </el-tag>
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="template-detail" v-else>
                    <div style="background-color: #f5f7fa">
                        <span style="padding-left: 15px; line-height: 30px;">
                        模板名称：{{ this.currentSmsTemplate.templateName }}({{smsTemplateStatus}})
                        </span>
                    </div>
                    <div class="template-content">
                        <span style="line-height: 30px;">模板内容: {{ this.currentSmsTemplate.templateContent }}</span>
                        <el-row>
                        <el-button type="primary" plain size="mini" @click="reSelect"
                            >重新选择</el-button
                        >
                        <el-button type="warning" plain size="mini" @click="reEdit" :disabled="currentSmsTemplate.templateStatus == 1"
                            >修改模板</el-button
                        >
                        <el-button type="danger" plain size="mini" @click="deleteTemplate"
                            >删除模板</el-button
                        >
                        </el-row>
                    </div>
                    <el-table :data="msgTemplate" height="300">
                        <el-table-column property="name" label="占位符"></el-table-column>
                        <el-table-column label="字段属性">
                        <template slot-scope="{ row }">
                            <template>
                            <el-select v-model="row.title" size="small" placeholder="请选择" :disabled="currentSmsTemplate.templateStatus == 1">
                                <el-option
                                v-for="item in msgTemplateComponentList"
                                :key="item.name"
                                :label="item.remark"
                                :value="item.name"
                                >
                                </el-option>
                            </el-select>
                            </template>
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="smsDialogVisible = false">关闭</el-button>
                <el-button v-if="sysCode" type="primary" @click="saveSmsTemplate">
                {{ buttonStatus }}
                </el-button>
            </div>
        </el-dialog>

        <!-- 编辑短信模板 -->
        <el-dialog
            title="编辑短信模板"
            :visible.sync="smsTplDialogVisible"
            :before-close="handleCloseTemplate"
            :close-on-click-modal="false"
        >
            <div class="sms-contianer">
                <div>
                    短信通知类型
                    <el-select 
                        v-model="sysCode" 
                        placeholder="请选择短信通知类型"
                        style="margin: 20px 0; width: 100%;"
                        @change="changeSysCode"
                    >
                        <el-option
                            v-for="item in sysCodeList"
                            :key="item.code"
                            :label="item.remark"
                            :value="item.code"
                            :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    模板标题
                    <el-input
                        v-model="templateName"
                        placeholder="请输入模板标题"
                        style="margin: 20px 0"
                    ></el-input>
                </div>
                <div class="sms-template">
                    <span>短信内容</span>
                    <p style="margin: 5px 0; color: rgb(155 164 180)">
                        为保证短信的稳定推送，以下内容须审核后才能使用，尽量不要使用特殊字段，严禁发送违规信息。<br />多于70字（含签名）的短信按67字每条计费。
                    </p>
                    <rpaCodemirror
                        v-if="smsTplDialogVisible"
                        ref="rpaSMSCode"
                        :fieldList="templateFieldList"
                        @change="changeSMSCode"
                        regType="$"
                    ></rpaCodemirror>
                    <el-button
                        type="text"
                        icon="el-icon-plus"
                        size="small"
                        @click.stop="addSMSColumn()"
                        >添加字段
                    </el-button>
                    <p style="background-color: #f5f7fa; padding: 6px 10px">
                        · 短信必须审核通过后才能启用智能助手<br />
                        · 审核时间约为2小时，非工作时间提交审核会延长
                    </p>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCloseTemplate">取 消</el-button>
                <el-button type="primary" @click="cacheSmsTemplate">确定</el-button>
            </div>
        </el-dialog>


    </div>
</template>
<script>
import { 
    getSmsTemplateList,
    getSmsTemplateDetail,
    saveTemplate,
    deleteSmsTemplate,
    getSmsTplSysCode,
    getSmsTplNoticeType,
    getSmsTplFlowField,
} from "@/views/lowCode/assistant/api";
import { 
    getSmsPlatformType,
    getSmsPlatformList,
    smsPlatformSave,
    smsPlatformDetail,
    smsPlatformSwitchEnabled,
    smsPlatformDelete,
} from "@/api/operationCenter";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import rpaCodemirror from "@/views/lowCode/rpa/rpa-codemirror.vue";
export default {
    components: {
        rpaCodemirror
    },
    data() {
        return {
            platformList: [],
            platformTypes: [], // 短信平台类型
            aliyunSmsForm: {
                smsType: undefined,
                enabled: false,
                accessKeyId: "",
                accessKeySecret: "",
                regionId: "",
                id: "",
                signName: "",
            },
            tencentSmsForm: {
                smsType: undefined,
                enabled: false,
                accessKeyId: "",
                accessKeySecret: "",
                regionId: "",
                sdkAppId: "",
                signName: "",
            },
            rules: {
                smsType: [
                    { required: true, message: '请选择平台类型', trigger: 'change' }
                ],
                accessKeyId: [
                    { required: true, message: '请填写key', trigger: 'blur' }
                ],
                accessKeySecret: [
                    { required: true, message: '请填写密钥', trigger: 'blur' }
                ],
                regionId: [
                    { required: true, message: '请填写区域', trigger: 'blur' }
                ],
                sdkAppId: [
                    { required: true, message: '请填写应用ID', trigger: 'blur' }
                ],
                signName: [
                    { required: true, message: '请填写短信签名', trigger: 'blur' }
                ]
            },
            smsType: undefined, // 短信平台类型 10：阿里云 20：腾讯云
            smsDialogVisible: false, // 短信平台配置弹窗
            smsValue: "", // 选择的模版
            sysCode: "", // 短信通知类型
            sysCodeList: [], // 短信通知编码列表
            smsDialogVisible: false,
            smsTemplateList: [], // 短信模版列表
            smsTplDialogVisible: false,
            templateFieldList: [], // 暂位字段
            templateName: "",
            templateContent: "",
            currentSmsTemplate: {},
            smsTemplateId: "", // 当前短信模版ID
            msgTemplate: [],
            msgTemplateComponentList: [],
        };
    },
    async created() {
        this.getSmsPlatformList();
        await this.getSmsPlatformType();
    },
    computed: {
        /** 判断是否空模版 */
        isEmptyTpl() {
            return isEmpty(this.currentSmsTemplate);
        },
        buttonStatus() {
            if (
                this.templateName == this.currentSmsTemplate.templateName &&
                `${this.templateContent}` ==
                `${this.currentSmsTemplate.templateContent}`
            ) {
                return "保存";
            } else {
                return "提交审核";
            }
        },
        smsTemplateStatus() {
            if (this.currentSmsTemplate.templateStatus == "0") {
                return "审核中";
            } else if (this.currentSmsTemplate.templateStatus == "1") {
                return "审核通过";
            } else if (this.currentSmsTemplate.templateStatus == "2") {
                return "审核未通过";
            }
        },
    },
    methods: {
        // 获取短信平台配置信息
        getSmsPlatformList() {
            getSmsPlatformList().then((resp) => {
                if(resp?.data?.page?.list?.length){
                    this.platformList = resp.data.page.list;
                    this.platformList.forEach(item => {
                        item.isDisabled = true;
                    })
                    console.log(this.platformList)
                }
            })
        },
        // 获取短信模板
        async getSmsPlatformType(){
            await getSmsPlatformType().then(resp => {
                this.platformTypes = resp?.data?.list;
            })
        },
        getPlatformTypes(){
            let platformTypes = this._.cloneDeep(this.platformTypes);
            let types = this.platformList.map(item => item.smsType);
            if(types?.length) {
                types.forEach(item => {
                    platformTypes.forEach(item2 => {
                        if(item2.code === item) {
                            item2.disabled = true;
                        }
                    })
                })
            }
            return platformTypes;
        },
        // 添加短信平台
        addPlatform(){
            this.platformList.push({
                id: "",
                smsType: "",
                enabled: false,
                accessKeyId: "",
                accessKeySecret: "",
                regionId: "",
                sdkAppId: "",
                signName: "",
            });
        },
        // 平台是否启用
        changeEnabled(item, val) {
            let id = item?.id;
            smsPlatformSwitchEnabled({
                id,
            }).then(resp => {
                this.$message.success("修改成功");
                this.$set(item, "enabled", val)
            }).catch(err => {
                this.$set(item, "enabled", !val)
            })
        },
        // 保存短信平台设置
        savePlatformConfig(item, index) {
            if(item.isDisabled) {
                this.$set(item, "isDisabled", false);
                this.$forceUpdate();
                return
            } else {
                this.$refs.form[index].validate((valid) => {
                    if (valid) {
                        let platform = {
                            id: item?.id,
                            smsType: item?.smsType,
                            enabled: item?.enabled,
                            accessKeyId: item?.accessKeyId,
                            accessKeySecret: item?.accessKeySecret,
                            regionId: item?.regionId,
                            sdkAppId: item?.sdkAppId,
                            signName: item?.signName,
                        }
                        smsPlatformSave(platform).then(resp => {
                            this.$message.success("修改成功");
                            this.getSmsPlatformList();
                            this.isDisabled = true;
                        })
                    }
                })
            }
        },
        // 删除短信平台
        deletePlatformConfig(item){
            this.$confirm("确认要删除该短信平台?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                smsPlatformDelete({
                    ids: item.id
                }).then(resp => {
                    this.$message.success("删除成功");
                    this.getSmsPlatformList();
                })
            })
            .catch(() => {});
        },
        smsSetting(item) {
            this.currentSmsTemplate = {};
            this.smsValue = "";
            this.smsType = item.smsType;
            this.smsDialogVisible = true;
            getSmsTemplateList({
                templateStatus: 100,
                templateType: 4, // 4: 系统
                smsType: item.smsType
            }).then(resp => {
                this.smsTemplateList = resp?.data?.page?.list;
            })
        },
        // 重新选择模版
        reSelect(){
            this.smsValue = ""; // 选择的模版
            this.sysCode = ""; // 短信通知类型
            this.currentSmsTemplate = {}; // 当前短信模版信息
            this.templateName = ""; // 模板名称
            this.templateContent = ""; // 模版内容
            this.smsTemplateId = ""; // 当前短信模版id
            this.templateFieldList = []; // 暂位字段
        },
        // 修改模版
        reEdit() {
            this.getSmsTplSysCode(true);
        },
        // 删除模版
        deleteTemplate(row) {
            let ids = [];
            if(row) {
                ids = [row.id]
            } else {
                ids = [this.currentSmsTemplate.id]
            }
            this.$confirm("删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                deleteSmsTemplate({
                    ids,
                }).then(resp => {
                    this.reSelect();
                    this.forSmsTemplateList();
                })
            });
        },
        handleCloseTemplate() {
            this.smsValue = "";
            this.smsTplDialogVisible = false;
        },
        // 模板审批流程占位符对应字段组件
        getSmsTplFlowField(sysCode){
            getSmsTplFlowField({
                sysCode,
            }).then(resp => {
                this.msgTemplateComponentList = resp?.data?.list
            });
        },
        // 选择短信模版类型
        changeSysCode(val) {
            this.getSmsTplFlowField(val);
        },
        forSmsTemplateDetail(row) {
            getSmsTemplateDetail({
                id: row.id,
            }).then((resp) => {
                let smsTemplate = resp.data.template;
                if (smsTemplate.templateStatus == "1") {
                    this.currentSmsTemplate = smsTemplate;
                    this.smsTemplateId = this.currentSmsTemplate.id;
                    this.sysCode = this.currentSmsTemplate.sysCode;
                    this.templateName = this.currentSmsTemplate.templateName;
                    this.templateContent = this.currentSmsTemplate.templateContent;
                    this.changeSMSCode(this.templateContent);
                    this.getMsgTemplate(this.currentSmsTemplate.fieldConfig);
                    this.getSmsTplFlowField(this.sysCode);
                } else {
                    this.$confirm("此模板未审核通过, 是否重新编辑?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        this.currentSmsTemplate = smsTemplate;
                        this.smsTemplateId = this.currentSmsTemplate.id;
                        this.sysCode = this.currentSmsTemplate.sysCode;
                        this.templateName = this.currentSmsTemplate.templateName;
                        this.templateContent = this.currentSmsTemplate.templateContent;
                        this.changeSMSCode(this.templateContent);
                        this.getMsgTemplate(this.currentSmsTemplate.fieldConfig);
                        this.getSmsTplFlowField(this.sysCode);
                        this.getSmsTplSysCode();
                        this.smsTplDialogVisible = true;
                        this.initSMSEdit();
                    })
                    .catch(() => {
                        this.reSelect();
                        this.forSmsTemplateList();
                    });
                }
            });
        },
         // 获取短信模板列表
        forSmsTemplateList() {
            getSmsTemplateList({
                templateStatus: 100,
                templateType: 4, // 4: 系统
                smsType: this.smsType
            }).then(resp => {
                this.smsTemplateList = resp?.data?.page?.list;
            })
        },
        getMsgTemplate(fieldConfig) {
            let arr = [];
            this.templateFieldList.forEach((item) => {
                let obj = this.msgTemplate.find((row) => row.name == item.name);
                if (obj) {
                    arr.push(obj);
                } else {
                    arr.push({
                        name: item.name,
                        title: fieldConfig ? fieldConfig[item.name] : "",
                    });
                }
            });
            this.msgTemplate = arr;
        },
        // 获取短信通知类型
        getSmsTplSysCode(isEdit = false){
            getSmsTplSysCode().then(resp => {
                this.smsTplDialogVisible = true;
                let sysCodeList = resp?.data?.list;
                sysCodeList = sysCodeList.filter(item => item.code != 25);
                let codes = this.smsTemplateList.map(item => item.sysCode)
                if(codes?.length) {
                    codes.forEach(item => {
                        sysCodeList.forEach(sys => {
                            if(sys.code == item) {
                                sys.disabled = true;
                            }
                        })
                    })
                }
                this.sysCodeList = sysCodeList;
                if(isEdit){
                    this.initSMSEdit();
                }
            })
        },
        createNewTemplate() {
            this.reSelect();
            this.getSmsTplSysCode();
        },
        // 初始化短信内容
        initSMSEdit() {
            this.$nextTick(() => {
                this.changeSMSCode(this.templateContent);
                if (this.$refs.rpaSMSCode && !isEmpty(this.templateContent)) {
                    this.$refs.rpaSMSCode.init(this.templateContent);
                }
            });
        },
        changeSMSCode(code) {
            const rex = /\${[a-zA-Z_0-9]+}/gm;
            let result;
            if (!isEmpty(code)) {
                result = code.match(rex);
            }
            let arr = [];
            if (result && result.length > 0) {
                result.forEach((item) => {
                let name = "";
                if(this.smsType == 10) { // 阿里云
                    name = `field_${arr.length + 1}`;
                } else if(this.smsType == 20){
                    name = `${arr.length + 1}`;
                }  
                code = code.replace(item, "${" + name + "}");
                arr.push({
                    name: name,
                    title: name,
                });
                });
            }
            this.$set(this, "templateContent", code);
            this.$set(this, "templateFieldList", arr);
        },
        addSMSColumn() {
            let item = {}
            if(this.smsType == 10) { // 阿里云
                item = {
                name: `field_${this.templateFieldList.length + 1}`,
                title: `field_${this.templateFieldList.length + 1}`,
                };
            } else if(this.smsType == 20){
                item = {
                name: `${this.templateFieldList.length + 1}`,
                title: `${this.templateFieldList.length + 1}`,
                };
            }  
            this.templateFieldList.push(item);
            this.$refs.rpaSMSCode.insert(item);
            this.$refs.rpaSMSCode.onBlur();
        },
        // 暂存短信模版
        cacheSmsTemplate() {
            if (!this.sysCode) {
                this.$message.error("请选择短信通知编码");
                return;
            }
            if (!this.templateName) {
                this.$message.error("请输入模板名称");
                return;
            }
            if (!this.templateContent) {
                this.$message.error("请输入短信内容");
                return;
            }
            if(!this.smsTemplateId){ // 新增
                this.$set(this.currentSmsTemplate, "sysCode", this.sysCode);
                this.$set(this.currentSmsTemplate, "templateName", this.templateName);
                this.$set(this.currentSmsTemplate, "templateContent", this.templateContent);
                this.changeSMSCode(this.templateContent);
            }
            this.getMsgTemplate();
            this.smsTplDialogVisible = false;
        },
        // 保存短信模板
        saveSmsTemplate(){
            let fieldConfig = {}
            this.msgTemplate.forEach(item => {
                fieldConfig[item.name] = item.title
            })
            for (let key in fieldConfig) {
                if (fieldConfig.hasOwnProperty(key)) {
                    console.log(key, fieldConfig[key]);
                    if(isEmpty(fieldConfig[key])){
                        this.$message.error(`请选择${key}占位符`);
                        return;
                    }
                }
            }
            let smsTemplate = {
                sysCode: this.sysCode,
                templateContent: this.templateContent,
                templateName: this.templateName,
                templateType: 4,
                smsType: this.smsType,
                fieldConfig: fieldConfig,
            }
            if(this.smsTemplateId){
                smsTemplate.id = this.smsTemplateId
            }
            saveTemplate(smsTemplate).then(resp => {
                this.$message.success("保存成功");
                this.reSelect();
                this.forSmsTemplateList();
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--font-text-primary, #172b4d);
}
.content {
    margin-top: 12px;
    padding: 16px;
    border-radius: 10px;
    background: #fff;
}
.description {
    font-size: 14px;
    line-height: 24px;
    color: #6b778c;
}

.form-content{
    width: 600px;
    ::v-deep .el-input{
        width: 400px;
    }
}

.form-item{
    padding: 20px 0;
    margin-bottom: 20px;
    border: 1px solid var(--zgg-border-light);
    border-radius: 10px;
}

.sms-contianer {
  margin: 20px;
  .sms-template {
    border: 1px solid #dcdfe6;
    padding: 10px;
  }
}

.template-detail {
  margin-top: 20px;
  border: 1px solid #f5f7fa;
  .template-content {
    padding: 10px 15px;
  }
}


</style>
  