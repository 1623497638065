<template>
  <div ref="processBody">
    <flow-item
        v-for="item in nodeList"
        :key="item.key"
        :node="item"
        :ref="`node_${item.key}`"
    ></flow-item>
    <svg-line
        v-for="(item, index) in lineList"
        :key="index"
        :line="item"
    ></svg-line>

    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        v-if="drawFrom && drawTo"
        class="svg-move"
        :style="drawStyle"
    >
      <defs>
        <marker
            id="arrow"
            markerWidth="25"
            markerHeight="25"
            refX="8"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
            viewBox="0 0 15 15"
        >
          <path d="M0,0 L3,3 L0,6 L9,3 z" fill="#043746" />
        </marker>
      </defs>
      <polyline
          :points="drawPoints"
          style="fill: transparent; stroke: #043746; pointer-events: none"
          stroke-width="1"
          marker-end="url(#arrow)"
      />
    </svg>

    <div class="notes">
      <div style="padding: 16px 16px">
        <div class="notes-line">
          <div class="kuang k1"></div>
          <span class="cont">已处理节点</span>
        </div>
        <div class="notes-line" style="margin-top: 15px">
          <div class="kuang k2"></div>
          <span class="cont">处理中节点</span>
        </div>
        <div class="notes-line" style="margin-top: 15px">
          <div class="kuang k3"></div>
          <span class="cont">未流转节点</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flowItem from "./flow-item.vue";
import svgLine from "./svgLine.vue";
export default {
  components: { flowItem, svgLine },
  props: {
    workflowType: {
      type: String,
      default() {
        return "chat";
      },
    },
    nodeList: Array,
    lineList: Array,
  },
  data() {
    return {
      drawFrom: null,
      drawTo: null,
      drawEnd: null,
    };
  },
  computed: {
    drawStyle() {
      let style = "";
      if (!(this.drawFrom && this.drawTo)) {
        return style;
      }
      let width = Math.abs(this.drawFrom.startX - this.drawTo.endX);
      let height = Math.abs(this.drawFrom.startY - this.drawTo.endY);
      let left = this.$el.getBoundingClientRect().left;
      let top = this.$el.getBoundingClientRect().top;

      if (this.drawFrom.startX < this.drawTo.endX) {
        left = this.drawFrom.startX - left;
      } else {
        left = this.drawTo.endX - left;
      }
      left = this.drawFrom.startX;

      if (this.drawFrom.startY < this.drawTo.endY) {
        top = this.drawFrom.startY - top;
      } else {
        top = this.drawTo.endY - top;
      }

      left = left + this.$refs.processBody.scrollLeft;
      top = top + this.$refs.processBody.scrollTop;

      if (this.drawFrom.startX > this.drawTo.endX) {
        left -= width;
      }
      style = `left:${left}px;top:${top}px;`;
      if (width) {
        style += `width:${width}px;`;
      }
      if (height) {
        style += `height:${height}px;`;
      }

      return style;
    },
    drawPoints() {
      let points = "";
      if (!(this.drawFrom && this.drawTo)) {
        return points;
      }

      let width = Math.abs(this.drawFrom.startX - this.drawTo.endX);
      let height = Math.abs(this.drawFrom.startY - this.drawTo.endY);
      let x1, y1, x2, y2;
      if (this.drawFrom.startX < this.drawTo.endX) {
        x1 = 0;
        x2 = width;
      } else {
        x1 = width;
        x2 = 0;
      }
      if (this.drawFrom.startY < this.drawTo.endY) {
        y1 = 0;
        y2 = height;
      } else {
        y2 = 0;
        y1 = height;
      }
      return `${x1},${y1} ${x2},${y2}`;
    },
  },
  methods: {
    redrawLine() {
      // 根据线的头尾，重画头尾重合的线

      let computedPoint = (count, index, size) => {
        return (count / 2 - (index + 1)) * size + size / 2;
      };
      let callback = () => {
        let lines = this.deepClone(this.lineList);
        lines.forEach((line) => {
          let points = line.points.split(" ");
          line.x1 = new Number(line.left) + new Number(points[0].split(",")[0]);
          line.y1 = new Number(line.top) + new Number(points[0].split(",")[1]);
          line.x2 =
              new Number(line.left) +
              new Number(points[points.length - 1].split(",")[0]);
          line.y2 =
              new Number(line.top) +
              new Number(points[points.length - 1].split(",")[1]);
        });

        let rePoints = [];

        lines.forEach((line) => {
          lines
              .filter(
                  (n) =>
                      !(
                          n.fromNodeKey == line.fromNodeKey &&
                          n.toNodeKey == line.toNodeKey
                      )
              )
              .forEach((node) => {
                if (
                    (line.x1 == node.x1 && line.y1 == node.y1) ||
                    (line.x1 == node.x2 && line.y1 == node.y2)
                ) {
                  let point = `${line.x1},${line.y1}`;
                  if (!rePoints.includes(point)) {
                    rePoints.push(point);
                  }
                } else if (
                    (line.x2 == node.x2 && line.y2 == node.y2) ||
                    (line.x2 == node.x1 && line.y2 == node.y2)
                ) {
                  let point = `${line.x2},${line.y2}`;
                  if (!rePoints.includes(point)) {
                    rePoints.push(point);
                  }
                }
              });
        });

        rePoints.forEach((point) => {
          let reLines = lines.filter(
              (node) =>
                  point == `${node.x1},${node.y1}` ||
                  point == `${node.x2},${node.y2}`
          );
          reLines.sort((a, b) => {
            if (a.left < b.left) {
              return -1;
            } else {
              return 1;
            }
          });

          reLines.forEach((node, index) => {
            if (point == `${node.x1},${node.y1}`) {
              // 起始节点重合

              let $from = this.$refs[`node_${node.fromNodeKey}`][0].$el;
              let from = this.nodeList.find((i) => i.key == node.fromNodeKey);

              let offsetWidth = $from.offsetWidth;
              let offsetHeight = $from.offsetHeight;
              if (!offsetWidth) {
                offsetWidth = this.offsetWidth;
              }
              if (!offsetHeight) {
                offsetHeight = this.offsetHeight;
              }

              let halfWidth = offsetWidth / 2;
              let halfHeight = offsetHeight / 2;
              let left = from.left;
              let top = from.top;

              let computHorizontal = () => {
                let width = offsetWidth / (reLines.length + 1);
                let w = computedPoint(reLines.length, index, width);
                let points = node.points.split(" ");
                points[0] =
                    points[0].split(",")[0] - w + "," + points[0].split(",")[1];
                points[1] =
                    points[1].split(",")[0] - w + "," + points[1].split(",")[1];
                node.points = points.join(" ");
              };

              let computVertical = () => {
                let height = offsetHeight / (reLines.length + 1);
                let h = computedPoint(reLines.length, index, height);
                let points = node.points.split(" ");
                points[0] =
                    points[0].split(",")[0] + "," + (points[0].split(",")[1] - h);
                points[1] =
                    points[1].split(",")[0] + "," + (points[1].split(",")[1] - h);
                node.points = points.join(" ");
              };

              if (node.x1 == left + halfWidth && node.y1 == top) {
                // 顶部
                computHorizontal();
              } else if (
                  node.x1 == left + halfWidth &&
                  node.y1 == top + offsetHeight
              ) {
                // 底部
                computHorizontal();
              } else if (node.x1 == left && node.y1 == top + halfHeight) {
                // 左边
                computVertical();
              } else if (
                  node.x1 == left + offsetWidth &&
                  node.y1 == top + halfHeight
              ) {
                // 右边
                computVertical();
              }
            } else {
              // 终止节点重合
              let $to = this.$refs[`node_${node.toNodeKey}`][0].$el;
              let to = this.nodeList.find((i) => i.key == node.toNodeKey);
              let offsetWidth = $to.offsetWidth;
              let offsetHeight = $to.offsetHeight;
              if (!offsetWidth) {
                offsetWidth = this.offsetWidth;
              }
              if (!offsetHeight) {
                offsetHeight = this.offsetHeight;
              }

              let halfWidth = offsetWidth / 2;
              let halfHeight = offsetHeight / 2;
              let left = to.left;
              let top = to.top;

              let computHorizontal = () => {
                let width = offsetWidth / (reLines.length + 1);
                let w = computedPoint(reLines.length, index, width);
                let points = node.points.split(" ");
                let pIndex = points.length - 1;
                points[pIndex] =
                    points[pIndex].split(",")[0] -
                    w +
                    "," +
                    points[pIndex].split(",")[1];
                points[pIndex - 1] =
                    points[pIndex - 1].split(",")[0] -
                    w +
                    "," +
                    points[pIndex - 1].split(",")[1];
                node.points = points.join(" ");
              };

              let computVertical = () => {
                let height = offsetHeight / (reLines.length + 1);
                let h = computedPoint(reLines.length, index, height);
                let points = node.points.split(" ");
                let pIndex = points.length - 1;
                points[pIndex] =
                    points[pIndex].split(",")[0] +
                    "," +
                    (points[pIndex].split(",")[1] - h);
                points[pIndex - 1] =
                    points[pIndex - 1].split(",")[0] +
                    "," +
                    (points[pIndex - 1].split(",")[1] - h);
                node.points = points.join(" ");
              };

              if (node.x2 == left + halfWidth && node.y2 == top) {
                // 顶部
                computHorizontal();
              } else if (
                  node.x2 == left + halfWidth &&
                  node.y2 == top + offsetHeight
              ) {
                // 底部
                computHorizontal();
              } else if (node.x2 == left && node.y2 == top + halfHeight) {
                // 左边
                computVertical();
              } else if (
                  node.x2 == left + offsetWidth &&
                  node.y2 == top + halfHeight
              ) {
                // 右边
                computVertical();
              }
            }

            let line = this.lineList.find(
                (line) =>
                    line.fromNodeKey == node.fromNodeKey &&
                    line.toNodeKey == node.toNodeKey
            );
            if (line) {
              line.points = node.points;
            }
          });
        });
      };
      this.$nextTick(callback);
    },
    setLineProps(line) {
      let startIndex = this.nodeList.findIndex(
          (item) => item.key == line.fromNodeKey
      );
      let endIndex = this.nodeList.findIndex(
          (item) => item.key == line.toNodeKey
      );
      let startNode = this.nodeList.find(
          (item) => item.key == line.fromNodeKey
      );
      let endNode = this.nodeList.find((item) => item.key == line.toNodeKey);

      // console
      let $startNode = this.$refs["node_" + line.fromNodeKey][0].$el;
      let $endNode = this.$refs["node_" + line.toNodeKey][0].$el;
      let distance = 15;
      let min = 10;

      let height, width;
      let startNodeWidth = $startNode.offsetWidth
          ? $startNode.offsetWidth
          : this.offsetWidth;
      let startNodeHeight = $startNode.offsetHeight
          ? $startNode.offsetHeight
          : this.offsetHeight;
      let endNodeWidth = $endNode.offsetWidth
          ? $endNode.offsetWidth
          : this.offsetWidth;
      let endNodeHeight = $endNode.offsetHeight
          ? $endNode.offsetHeight
          : this.offsetHeight;

      if (endNode.top > startNode.top + startNodeHeight + distance) {
        // 起始节点位于上方

        height = endNode.top - startNode.top - startNodeHeight;
        width =
            startNode.left +
            startNodeWidth / 2 -
            (endNode.left + endNodeWidth / 2);
        width = Math.abs(width);
        line.width = width;
        line.top = startNode.top + startNodeHeight;
        line.height = height;
        if (startNode.left < endNode.left) {
          line.left = startNode.left + startNodeWidth / 2;
          line.points = `0,0 0,${height / 2} ${width},${
              height / 2
          } ${width},${height}`;
        } else {
          line.left = endNode.left + endNodeWidth / 2;
          line.points = `${width},0 ${width},${height / 2} 0,${
              height / 2
          } 0,${height}`;
        }
      } else if (startNode.top > endNode.top + endNodeHeight + distance) {
        // 起始节点位于下方
        height = startNode.top - endNode.top - endNodeHeight;
        width =
            startNode.left +
            startNodeWidth / 2 -
            (endNode.left + endNodeWidth / 2);
        line.top = endNode.top + endNodeHeight;
        line.height = height;
        width = Math.abs(width);
        line.width = width;

        if (startNode.left < endNode.left) {
          line.left = startNode.left + startNodeWidth / 2;
          line.points = `0,${height} 0,${height / 2} ${width},${
              height / 2
          } ${width},0`;
        } else {
          line.left = endNode.left + endNodeWidth / 2;
          line.points = `${width},${height} ${width},${height / 2} 0,${
              height / 2
          } 0,0`;
        }
      } else if (endNode.left > startNode.left + startNodeWidth + distance) {
        // 起始节点位于左侧
        width = endNode.left - startNode.left - startNodeWidth;
        height =
            startNode.top +
            startNodeHeight / 2 -
            (endNode.top + endNodeHeight / 2);
        height = Math.abs(height);
        line.left = startNode.left + startNodeWidth;
        line.width = width;
        line.height = height;
        if (startNode.top < endNode.top) {
          line.top = startNode.top + startNodeHeight / 2;
          line.points = `0,0 ${width / 2},0 ${
              width / 2
          },${height} ${width},${height}`;
        } else {
          line.top = endNode.top + endNodeHeight / 2;
          line.points = `0,${height} ${width / 2},${height} ${
              width / 2
          },0 ${width},0`;
        }
      } else if (startNode.left > endNode.left + endNodeWidth + distance) {
        // 起始节点位于右侧
        width = startNode.left - endNode.left - endNodeWidth;
        height =
            startNode.top +
            startNodeHeight / 2 -
            (endNode.top + endNodeHeight / 2);
        height = Math.abs(height);
        line.left = endNode.left + endNodeWidth;
        line.width = width;
        line.height = height;
        if (startNode.top < endNode.top) {
          line.top = startNode.top + startNodeHeight / 2;
          line.points = `${width},0 ${width / 2},0 ${
              width / 2
          },${height} 0,${height}`;
        } else {
          line.top = endNode.top + endNodeHeight / 2;
          line.points = `${width},${height} ${width / 2},${height} ${
              width / 2
          },0 0,0`;
        }
      } else {
        // 当做重合处理
        if (startIndex < endIndex) {
          // 起始节点index靠前
          if (startNode.top < endNode.top) {
            // 起始节点top靠前
            height = min + endNode.top - startNode.top + endNodeHeight / 2;
            if (startNode.left > endNode.left) {
              width = min + startNode.left + startNodeWidth / 2 - endNode.left;
              line.left = endNode.left - min;
              line.points = `${width},${min} ${width},0 0,0 0,${height} ${min},${height}`;
            } else {
              width = min + endNode.left + endNodeWidth / 2 - startNode.left;
              line.left = startNode.left + startNodeWidth / 2;
              line.points = `0,${min} 0,0 ${width},0 ${width},${height} ${
                  width - min
              },${height}`;
            }
            line.width = width;
            line.height = height;
            line.top = startNode.top - min;
          } else {
            // 起始节点top靠后
            height = startNode.top - endNode.top + min + startNodeHeight / 2;
            if (startNode.left > endNode.left) {
              width = startNode.left - endNode.left + min + startNodeWidth / 2;
              line.points = `${width},${
                  height - min
              } ${width},${height} 0,${height} 0,0 ${min},0`;
              line.left = endNode.left - min;
            } else {
              width =
                  min +
                  endNode.left +
                  endNodeWidth -
                  startNode.left -
                  startNodeWidth +
                  startNodeWidth / 2;
              line.points = `0,${
                  height - min
              } 0,${height} ${width},${height} ${width},0 ${width - min},0`;
              line.left = startNode.left + startNodeWidth / 2;
            }
            line.width = width;
            line.height = height;
            line.top = endNode.top + endNodeHeight / 2;
          }
        } else {
          // 起始节点index在后
          if (startNode.top < endNode.top) {
            // 起始节点top靠前
            height = endNode.top - startNode.top + min + endNodeHeight / 2;
            if (startNode.left > endNode.left) {
              width = min + startNode.left - endNode.left + startNodeWidth / 2;
              line.points = `${
                  width - min
              },0 ${width},0 ${width},${height} 0,${height} 0,${height - min}`;
              line.left = endNode.left + endNodeWidth / 2;
            } else {
              width = min + endNode.left - startNode.left + endNodeWidth / 2;
              line.points = `${min},0 0,0 0,${height} ${width},${height} ${width},${
                  height - min
              }`;
              line.left = startNode.left - min;
            }
            line.width = width;
            line.height = height;
            line.top = startNode.top + startNodeHeight / 2;
          } else {
            // 起始节点top靠后
            height = min + startNode.top + startNodeHeight / 2 - endNode.top;
            if (startNode.left > endNode.left) {
              width = min + startNode.left + startNodeWidth / 2 - endNode.left;
              line.left = endNode.left + endNodeWidth / 2;
              line.points = `${
                  width - min
              },${height} ${width},${height} ${width},0 0,0 0,${min}`;
            } else {
              width = min + endNode.left + endNodeWidth / 2 - startNode.left;
              line.left = startNode.left - min;
              line.points = `${min},${height} 0,${height} 0,0 ${width},0 ${width},${min}`;
            }
            line.width = width;
            line.height = height;
            line.top = endNode.top - min;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.svg-move {
  pointer-events: none;
  position: absolute;
  overflow: visible;
  z-index: 3;
}
.notes {
  position: absolute;
  // width: auto;
  // height: 112px;
  right: 34px;
  top: 34px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
  0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  border-radius: 10px;
  .notes-line {
    display: flex;
    flex-direction: row;
  }
  .kuang {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: 4px;
  }
  .k1 {
    background: #f2fcfd;
    border: 1px solid #25cbd6;
  }
  .k2 {
    background: #3870ea;
    border: 1px solid #3870ea;
  }
  .k3 {
    background: #ffffff;
    border: 1px solid #ebecf0;
  }
  .cont {
    color: #172b4d;
  }
}
</style>
