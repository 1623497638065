<template>
  <div>
    <el-form ref="form" :model="node" label-position="top" :disabled="disabled">
      <el-form-item
        label="选择数据对象"
        prop="baseNodeKey"
        :rules="[{ required: true, message: '请选择数据对象' }]"
      >
        <div class="gray-label">当前流程中的节点对象，作为子流程数据源</div>
        <el-select
          v-model="node.baseNodeKey"
          placeholder=""
          size="small"
          @change="changeBaseNodeKey"
        >
          <el-option
            v-for="item in list"
            :key="item.key"
            :label="`【${item.title}】${item.collectionTitle}`"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="node.baseNodeKey && node.triggerOneTimes === false"
        label="多条数据执行方式"
        prop="executeType"
      >
        <div class="gray-label">
          您选择了多条数据对象，将根据数据的条数执行多条子流程
        </div>
        <el-radio-group v-model="node.executeType">
          <div>
            <el-radio label="parallel">并行</el-radio>
            <div class="gray-label mg">
              多个数据对象将同时执行各自的流程。即便某条子流程运行中止，也不影响其他子流程的执行。
            </div>
          </div>
          <div>
            <el-radio label="serial">串行</el-radio>
            <div class="gray-label mg">
              多个数据对象将依次执行流程。每条子流程需要等前一条子流程通过后再开始触发；如果某条子流程运行中止，则后续的子流程都不再触发。
            </div>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="执行子流程"
        :rules="[{ required: true, message: '请选择子流程' }]"
        prop="robotId"
      >
        <el-popover
          placement="bottom-start"
          popper-class="subflow-popover"
          trigger="manual"
          v-model="visible"
          @after-leave="afterLeave"
        >
          <el-input
            :value="node.robotTitle"
            placeholder="节点保存后，将自动创建一个新的子流程"
            readonly
            size="small"
            slot="reference"
            @click.native="showRobotList"
            :suffix-icon="visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          ></el-input>
          <div style="width: 852px" v-clickoutside="closeRobotList">
            <div style="margin: 10px; display: flex">
              <el-input
                v-model="keyword"
                placeholder=""
                style="width: 100%"
                size="small"
                @input="searchRobotList"
              ></el-input>
              <el-button type="primary" @click="clearNodeRobot" size="small"
                >清除选择</el-button
              >
            </div>

            <div
              style="max-height: 300px; overflow: auto"
              :infinite-scroll-immediate="false"
              :infinite-scroll-distance="30"
              v-infinite-scroll="loadMore"
            >
              <div
                v-for="item in robotPage.list"
                :key="item.id"
                v-text="item.title"
                @click="checkRobot(item)"
                class="robot-item"
              ></div>
            </div>
          </div>
        </el-popover>
      </el-form-item>
      <el-form-item v-if="node.triggerOneTimes" prop="syncExec">
        <el-checkbox v-model="node.syncExec" style="line-height: 1.5">
          <div>子流程执行完毕后，再开始下一个节点</div>
        </el-checkbox>
        <div class="gray-label" style="line-height: 1; margin-left: 24px">
          勾选后，当子流程数据源为单条对象，之后节点可使用子流程中的参数
        </div>
      </el-form-item>
      <el-form-item style="position: relative">
        <div
          slot="label"
          style="
            display: flex;

            align-items: center;
            width: 850px;
          "
        >
          <div style="flex: 1">
            <label>参数传递</label>
          </div>

          <div
            style="cursor: pointer; color: #3870ea"
            v-if="node.robotId"
            @click="visibleConfig = true"
          >
            参数设置
          </div>
        </div>

        <div class="gray-label" style="line-height: 1.5">
          向子流程的流程参数传递初始值，供子流程执行时使用
        </div>

        <!-- 参数项配置 -->
        <div>
          <rpa-subflow-param
            v-for="(item, index) in node.flowParamValues"
            :key="item.param.name"
            :flowParam="item"
            :params="paramList"
            :nodeKey="node.key"
            @del="del(index)"
          ></rpa-subflow-param>
        </div>

        <div>
          <el-popover
            trigger="click"
            v-model="visibleParams"
            popper-class="subflow-popover"
          >
            <el-button
              :disabled="!isAddParam"
              icon="el-icon-plus"
              size="small"
              slot="reference"
              >添加参数</el-button
            >
            <div
              v-if="!paramList.length"
              style="padding: 10px; font-size: 14px"
            >
              没有可用的参数
            </div>
            <div v-else class="params-scroll">
              <div
                v-for="item in paramList"
                :key="item.name"
                class="param-item"
                :class="{ disabled: isSelectedParam(item.name) }"
                @click="addParamValue(item)"
                v-text="item.title"
              ></div>
            </div>
          </el-popover>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      v-if="subflow.id"
      title="参数设置"
      width="500px"
      :visible.sync="visibleConfig"
      append-to-body
      destroy-on-close
    >
      <div style="height: 300px; overflow: auto">
        <rpa-flow-config
          ref="rpaFlowConfig"
          :config.sync="subflow.rpaFlow.config"
          @save="saveParams"
        ></rpa-flow-config>
      </div>
      <div slot="footer">
        <el-button size="small" @click="visibleConfig = false">取消</el-button>
        <el-button type="primary" size="small" @click="confirmParams"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import rpaFlowConfig from "./rpa-flow-config";
import rpaSubflowParam from "./rpa-subflow-param";
import {
  createCollectionTrigger,
  getRobotList,
  getRobotWorkflow,
  saveRobotWorkflow,
} from "./api";
import Clickoutside from "element-ui/src/utils/clickoutside";

export default {
  directives: { Clickoutside },
  name: "rpa-subflow-collection",
  components: { rpaFlowConfig, rpaSubflowParam },
  props: {
    node: Object,
    disabled: Boolean,
  },
  inject: {
    /** 获取触发器 */
    getTrigger: {
      default: () => [],
    },
    getFromNodes: {
      default: () => [],
    },
  },
  computed: {
    isAddParam() {
      if (isEmpty(this.node.baseNodeKey)) {
        return false;
      }
      // todo
      // if (isEmpty(this.node.robotId)) {
      //   return false;
      // }
      return true;
    },
    paramList() {
      let flowParams = this.subflow?.rpaFlow?.config?.flowParams;
      if (flowParams) {
        return flowParams;
      }
      return [];
    },
  },
  data() {
    return {
      list: [],
      visible: false,
      visibleConfig: false,
      visibleParams: false,
      config: {},
      loadingRobot: false,
      keyword: "",
      robotPage: {
        pageNumber: 1,
        pageSize: 20,
        totalPage: 0,
        totalRow: 0,
        list: [],
      },
      subflow: {},
    };
  },
  created() {
    /**
     * @type {Array}
     */
    let list = this.getFromNodes();
    let trigger = this.getTrigger();
    list.push(trigger);
    let arr = [
      "trigger_form_data_change",
      "action_form_data_create",
      "action_form_data_update",
      "action_form_data_save",
      "query_data_one",
      "query_data_list",
    ];
    list = this._.cloneDeep(list.filter((item) => arr.includes(item.type)));
    list.forEach((item) => {
      if (item.formId) {
        item.collection = item.formId;
      }
      if (item.formTitle) {
        item.collectionTitle = item.formTitle;
      }
    });
    this.list = list;
    if (this.node.robotId) {
      getRobotWorkflow({
        robotId: this.node.robotId,
      }).then((res) => {
        this.subflow = res.data.workflow;
      });
    }
  },
  methods: {
    /**
     * 选择子流程
     */
    checkRobot(subflow) {
      if (this.node.robotId == subflow.id) {
        this.visible = false;
        return;
      }
      this.$set(this.node, "robotId", subflow.id);
      this.$set(this.node, "robotTitle", subflow.title);
      this.visible = false;
      getRobotWorkflow({
        robotId: subflow.id,
      }).then((res) => {
        this.subflow = res.data.workflow;
      });
    },
    /**
     * 清除节点的子流程配置
     */
    clearNodeRobot() {
      this.$set(this.node, "robotId", "");
      this.$set(this.node, "robotTitle", "");
      this.$set(this.node, "flowParamValues", []);
      this.subflow = {};
      this.visible = false;
    },
    closeRobotList() {
      this.timer = setTimeout(() => {
        this.visible = false;
      }, 100);
    },
    afterLeave() {
      this.keyword = "";
    },
    searchRobotList() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        this.getRobotlist(1);
      }, 500);
    },
    async showRobotList() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.node.baseNodeKey) {
        this.$message.error("请选择数据源");
        return;
      }
      await this.getRobotlist();
      this.visible = true;
    },
    async getRobotlist(pageNumber = 1) {
      let trigger = this.getTrigger();
      let idNotEq;
      if (trigger && trigger.type == "trigger_subflow_collection") {
        idNotEq = this.$route.query.id;
      }
      this.loadingRobot = true;

      let res = await getRobotList({
        triggerType: "trigger_subflow_collection",
        triggerCollection: this.node.collection,
        title: this.keyword,
        idNotEq,
        pageNumber,
        pageSize: 10,
      });

      this.loadingRobot = false;

      if (res.data.page.pageNumber == 1) {
        this.robotPage = res.data.page;
      } else {
        res.data.page.list = this.robotPage.list.concat(res.data.page.list);
        this.robotPage = res.data.page;
      }
    },
    loadMore() {
      if (this.loadingRobot) {
        return;
      }
      if (this.robotPage.pageNumber >= this.robotPage.totalPage) {
        return;
      }

      this.getRobotlist(this.robotPage.pageNumber + 1);
    },
    getData(callback) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        if (!isEmpty(this.node.flowParamValues)) {
          let index = this.node.flowParamValues.findIndex((item) => {
            if (item.paramValue.valueType == "custom") {
              return isEmpty(item.paramValue.customValue);
            } else if (item.paramValue.valueType == "nodeField") {
              return isEmpty(item.paramValue.nodeField);
            } else if (item.paramValue.valueType == "rely") {
              return isEmpty(item.paramValue.rely);
            } else if (item.paramValue.valueType == "dynamic") {
              return isEmpty(item.paramValue.dynamicTime);
            } else {
              return false;
            }
          });

          if (index >= 0) {
            this.$message.error("参数值不能为空");
            return;
          }
        }

        if (!this.node.robotId) {
          // 新建一个子流程
          let res = await createCollectionTrigger({
            title: "未命名子流程",
            collection: this.node.collection,
            collectionTitle: this.node.collectionTitle,
          });
          this.$set(this.node, "robotId", res.data.robot.id);
          this.$set(this.node, "robotTitle", res.data.robot.title);
        }

        callback(valid);
      });
    },
    del(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.node.flowParamValues.splice(index, 1);
    },
    isSelectedParam(name) {
      return (
        this.node.flowParamValues.findIndex(
          (item) => item.param.name == name,
        ) >= 0
      );
    },
    saveParams() {
      this.visibleConfig = false;
      saveRobotWorkflow({
        workflowId: this.subflow.id,
        rpaFlow: this.subflow.rpaFlow,
      }).then((res) => {
        this.$message.success("参数保存成功");
      });
    },
    confirmParams() {
      this.$refs.rpaFlowConfig.saveParams();
    },
    addParamValue(component) {
      let obj = {
        param: this._.cloneDeep(component),
        paramValue: {
          valueType: "custom",
          customValue: "",
          customValueDetail: undefined,
          nodeField: undefined,
          rely: undefined,
          dynamicTime: undefined,
        },
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.node.flowParamValues.push(obj);
      this.visibleParams = false;
    },
    changeBaseNodeKey(val) {
      let item = this.list.find((item) => item.key == val);
      if (item) {
        this.$set(this.node, "collection", item.collection);
        this.$set(this.node, "collectionTitle", item.collectionTitle);
        if (
          [
            "action_form_data_update",
            "action_form_data_save",
            "query_data_list",
          ].includes(item.type)
        ) {
          this.$set(this.node, "triggerOneTimes", false);
          this.$set(this.node, "syncExec", false);
        } else if (
          ["trigger_form_data_change", "query_data_one"].includes(item.type)
        ) {
          this.$set(this.node, "triggerOneTimes", true);
        } else if (item.type == "action_form_data_create") {
          this.$set(this.node, "triggerOneTimes", isEmpty(item.baseNodeKey));
        }
        this.$set(this.node, "executeType", "parallel");

        //
      }
      // console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.gray-label {
  font-size: 12px;
  color: #9e9e9e;
}
.mg {
  margin: 10px 0 10px 24px;
}
.params-scroll {
  max-height: 300px;
  overflow: auto;
  .param-item {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    &.disabled {
      cursor: not-allowed;
      color: #9e9e9e;
    }
  }
}
.robot-item {
  line-height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }
}
</style>
<style lang="scss">
.subflow-popover {
  padding: 0;
}
</style>
