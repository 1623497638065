import request from '@/utils/request'

//部门表获取表单列表
export function companyFormList(data){
  return request({
    url:'/company/form/list',
    method:'post',
    data
  })
}

// 获取单位通讯录同步表单字段列表
export function companyContactSyncFormFieldList(data){
  return request({
    url:'/company/contact/syncFormFieldList',
    method:'post',
    data
  })
}


// 获取单位通讯录同步配置详情
export function companyContactSyncConfigDetail(data){
  return request({
    url:'/company/contact/syncConfigDetail',
    method:'post',
    data
  })
}


// 开关通讯录同步表单
export function companyContactSwitchSyncForm(data){
  return request({
    url:'/company/contact/switchSyncForm',
    method:'post',
    data
  })
}
