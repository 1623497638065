import request from '@/utils/request'

import qs from "qs";

/**
 * 获取看板栏数据
 */
export function fetchBulletinColumn(data) {
    return request({
        url: '/app/bulletinColumn/paging',
        method: 'POST',
        data: qs.stringify(data)
    });
}

/**
 * 保存看板栏数据
 */
export function saveBulletinColumn(data) {
    return request({
        url: '/app/bulletinColumn/save',
        method: 'POST',
        data: qs.stringify(data)
    });
}

/**
 * 栏排序
 */
export function bulletinColumnSort(data) {
    return request({
        url: '/app/bulletinColumn/sort',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 删除栏
 */
export function deleteBulletinColumn(data) {
    return request({
        url: '/app/bulletinColumn/delete',
        method: 'post',
        data: qs.stringify(data)
    });
}


/**
 * 保存任务卡片
 */
export function saveBulletinCard(data) {
    return request({
        url: '/app/bulletinCard/save',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 获取任务列表
 */
export function fetchBulletingCart(data){
    return request({
        url:'/app/bulletinCard/paging',
        method:'post',
        data:qs.stringify(data)
    })
}

/**
 * 保存任务拖拽排序
 * @param {*} data
 * @returns
 */
export function saveBulletingCardSort(data){
    return request({
        url:'/app/bulletinCard/sort',
        method:'post',
        data:qs.stringify(data)
    })
}

export function deleteBulletingCard(data){
    return request({
        url:'/app/bulletinCard/delete',
        method:'post',
        data:qs.stringify(data)
    })
}

/**
 * 设为私密或取消私密 接口
 * id=卡片ID(必填)&privated=true|false(是否私密,必填)
 * @returns
 */
export function bulletinCardSwitchPrivate(data) {
    return request({
        url:  '/app/bulletinCard/switchPrivated',
        method: 'POST',
        data: qs.stringify(data)
    })
}

//获取新建标签时的默认颜色列表
export function bulletinTagDefaultColor(data) {
    return request({
        url:  "/app/bulletinTag/listDefaultColor",
        method: "post",
        data
    })
}
//获取标签模板列表
export function bulletinTagListTemplate(data) {
    return request({
        url:   "/app/bulletinTag/listTemplate",
        method: "post",
        data
    })
}
//  新增/编辑（带模板id)看板标签模板
export function bulletinTagRegister(data) {
    return request({
        url: "/app/bulletinTag/register",
        method: "post",
        data: qs.stringify(data)
    })
}
// 卡片绑定标签
export function bulletinTagBind(data) {
    return request({
        url: "/app/bulletinTag/bind",
        method: "post",
        data: qs.stringify(data)
    })
}
//卡片解绑标签
export function bulletinTagUnbind(data) {
    return request({
        url: "/app/bulletinTag/unbind",
        method: "post",
        data: qs.stringify(data)
    })
}
/**
 * 移动标签接口
 * /app/bulletinTag/sort
 * @param {*}id=标签id值(必填)&preId=前一标签id值,如果排到第一个，那么传0(必填)
 * @returns
 */
export function bulletinTagSort(data) {
    return request({
        url: "/app/bulletinTag/sort",
        method: 'POST',
        data
    })
}
