<template>
  <div ref="chart" class="chart-area"></div>
</template>
<script>
import test from "@/utils/test";
import { getAggreation } from "../api";
import {
  chartColors,
  dealWithXFieldValue,
  setGuidelines,
  getChartColorConfig,
  axisNameConfig,
  getGrid,
  chartFontSize,
  chartLineHeight,
  chartItemWidth,
} from "./chartUtil";
import chartResize from "@/views/lowCode/view/components/mixins/chartResize";
import { getTextWidth } from "@/utils/tools";
import { getStrByNumberFormat, isEmpty } from "@/zgg-core/utils";
export default {
  mixins: [chartResize],
  data() {
    return {
      list: [],
      view: null,
      option: null,
      isGridChart: true,
    };
  },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  destroyed() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  },
  methods: {
    getData(view, dataFilter) {
      this.$set(this, "view", view);
      if (!view.metrics.length) {
        return;
      }
      if (!view.xFields.length) {
        return;
      }

      let queryObject = {};
      if (view.dataSource.queryObject) {
        if (view.dataSource.queryObject.pageSize) {
          queryObject.pageSize = view.dataSource.queryObject.pageSize;
        }
        if (view.dataSource.queryObject.advanceQuery) {
          queryObject.advanceQuery = view.dataSource.queryObject.advanceQuery;
        }
      }
      queryObject.xFields = view.xFields;
      queryObject.metrics = view.metrics;
      getAggreation({
        collection: view.dataSource.formId,
        queryObject: JSON.stringify(queryObject),
        dataFilter: JSON.stringify(dataFilter ? dataFilter : {}),
      }).then((res) => {
        // console.log("chart data");
        // console.log(res);
        this.list = res.data.list;
        this.buildChart(view);
      });
    },
    buildChart(view) {
      const result = getChartColorConfig(view, this.isH5);
      this.remindColor = result.remindColor;
      let textStyle = result.textStyle;
      let grid = getGrid(view);
      let list = this.list;
      // 基于准备好的dom，初始化echarts实例
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(this.$refs.chart);

      let option = {
        textStyle: textStyle,
        color: this.remindColor ? this.remindColor : chartColors,
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: (params) => {
            let axisValue = params[0].axisValue;
            let str = "";
            params.forEach((item) => {
              let value = item.data.tooltip.valueFormatter();
              str += `<div class="chart-line-row">
                <div>${item.marker}<span>${item.seriesName}</span></div>
                <div style="margin-left:10px;">${value}</div>
                </div>`;
            });
            return `<div><div style="font-size:14px">${axisValue}</div>${str}</div>`;
          },
        },
        legend: {},
        grid: grid,

        yAxis: {
          splitLine: {
            lineStyle: {
              type: [2, 4],
              dashOffset: 2,
            },
          },
          ...axisNameConfig(view.yAxis),
        },
        xAxis: {
          axisLabel: {},
          data: [],
        },
      };
      dealWithXFieldValue(view.xFields, list, option, "xAxis");

      if (view.xAxis.rotation) {
        option.xAxis.axisLabel.rotate = view.xAxis.rotation;
      } else {
        option.xAxis.axisLabel.rotate = 0;
      }
      if (view.xAxis.step) {
        option.xAxis.axisLabel.interval = 0;
      } else {
        option.xAxis.axisLabel.interval = "auto";
      }

      if (view.yAxis.title) {
        option.yAxis.name = view.yAxis.title;
      } else {
        option.yAxis.name = "";
      }
      let min = view.yAxis.min;
      let max = view.yAxis.max;
      if (test.number(min) && min != "") {
        option.yAxis.min = min;
      } else {
        option.yAxis.min = function () {
          return null;
        };
      }
      if (test.number(max) && max != "") {
        option.yAxis.max = max;
      } else {
        option.yAxis.max = function () {
          return null;
        };
      }

      let series = [];
      let lengedData = [];

      if (view.xFields.length == 1) {
        view.metrics.forEach((item) => {
          if (lengedData.findIndex((v) => v === item.title) == -1) {
            lengedData.push(item.title);
          }
          let data = [];

          list.forEach((row) => {
            let value = row[item.name];
            let show = view.chartLabel.enable;
            if (typeof value === "undefined") {
              show = false;
            }
            data.push({
              value,
              label: {
                show,
                position: "top",
                textStyle: textStyle,
                formatter() {
                  if (item.numberFormat) {
                    return row[item.name + "Format"];
                  }
                  return value;
                },
              },
              tooltip: {
                show: typeof value !== "undefined",
                valueFormatter() {
                  if (item.numberFormat) {
                    return row[item.name + "Format"];
                  }
                  return value;
                },
              },
              symbol: typeof value !== "undefined" ? "emptyCircle" : "none",
            });
          });
          let obj = {
            name: item.title,
            type: "line",
            data,
            stack: "Total",
            areaStyle: {
              opacity: 0.35,
            },
          };
          if (item.color) {
            obj.itemStyle = {
              color: item.color,
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }

          if (view.chartMarker) {
            obj.smooth = view.chartMarker.curve;
            obj.symbol = view.chartMarker.symbol;
          }
          series.push(obj);
        });
      } else {
        list.forEach((row) => {
          let legendName = row[view.xFields[1].field.name];
          if (lengedData.findIndex((v) => v === legendName) == -1) {
            lengedData.push(legendName);
          }
        });
        // option.xAxis.data.forEach(item=>{
        //     list.filter(row=>row.)
        // });

        let name0 = view.xFields[0].field.name;
        let name = view.xFields[1].field.name;
        let metrics = view.metrics[0];
        let metricsName = view.metrics[0].name;
        let numberFormat = view.metrics[0].numberFormat;

        lengedData.forEach((key) => {
          let obj = {
            name: key,
            type: "line",
            data: [],
            stack: "Total",
            areaStyle: {
              opacity: 0.35,
            },
          };

          if (metrics.color) {
            obj.itemStyle = {
              color: metrics.color,
            };
          } else {
            obj.itemStyle = {
              color: "",
            };
          }
          if (view.chartMarker) {
            obj.smooth = view.chartMarker.curve;
            obj.symbol = view.chartMarker.symbol;
          }
          obj.symbolSize = 8;

          option.xAxis.data.forEach((xKey) => {
            let value = 0;
            let show = view.chartLabel.enable;
            let item = list.find(
              (item) => item[name] === key && item[name0] === xKey,
            );
            if (item) {
              value = item[metricsName];
            } else {
              show = false;
            }

            obj.data.push({
              value,
              label: {
                show,
                position: "top",
                formatter() {
                  if (!isEmpty(numberFormat)) {
                    if (item) {
                      return item[metricsName + "Format"];
                    }
                    return getStrByNumberFormat(0, numberFormat);
                  } else {
                    return value;
                  }
                },
              },
              tooltip: {
                show: !!item,
                valueFormatter() {
                  if (!isEmpty(numberFormat)) {
                    if (item) {
                      return item[metricsName + "Format"];
                    }
                    return getStrByNumberFormat(0, numberFormat);
                  } else {
                    return value;
                  }
                },
              },
              symbol: item ? "emptyCircle" : "none",
            });
          });

          series.push(obj);
        });
      }
      if (view.chartLegend && view.chartLegend.enable) {
        let legend = {
          type: "scroll",
          data: lengedData,
          show: true,
          icon: "circle",
          textStyle: {
            fontSize: chartFontSize,
            lineHeight: chartLineHeight,
            ...textStyle,
          },
          itemWidth: chartItemWidth,
        };
        if (view.chartLegend.position == "top") {
          legend.x = "center";
          legend.y = "top";
        }
        if (view.chartLegend.position == "bottom") {
          legend.x = "center";
          legend.y = "bottom";
        }
        if (
          view.chartLegend.position === "left" ||
          view.chartLegend.position === "right"
        ) {
          legend.x = view.chartLegend.position;
          legend.y = "center";
          legend.orient = "vertical";
          let longestStr = "";
          series.forEach((item) => {
            if (item.name.length > longestStr.length) {
              longestStr = item.name;
            }
          });
          // 标签宽度 + 文字宽度 + 留白宽度
          this.longestStr = 40 + getTextWidth(longestStr, "normal", 3);
        }
        Object.assign(option.legend, legend);
      } else {
        option.legend.show = false;
      }
      option.series = series;
      //设置辅助线
      if (view) {
        setGuidelines(view, option, "yAxis", this.list);
      }

      // 使用刚指定的配置项和数据显示图表。

      this.myChart.setOption(option);
      this.setChartResize();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-area {
  height: 100%;
}
</style>
<style lang="scss">
.chart-line-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
</style>
