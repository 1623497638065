<template>
  <div class="attr-row">
    <div class="attr-label">校验</div>

    <el-checkbox
      @change="changeLimit"
      v-model="component.limitValueRange"
      style="margin-right: 10px"
    >
      限定数值范围
    </el-checkbox>

    <div class="weui" style="margin-top: 10px">
      <br />
      <el-input-number
        :value="minValue"
        :disabled="disabledNum"
        placeholder="不限"
        controls-position="right"
        size="mini"
        @change="changeMinValue"
        style="width: 110px"
      ></el-input-number>
      <span style="padding: 0 5px">~</span>

      <el-input-number
        :value="maxValue"
        :disabled="disabledNum"
        placeholder="不限"
        controls-position="right"
        size="mini"
        @change="changeMaxValue"
        style="width: 110px"
      ></el-input-number>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "@zgg-core-utils/utils";
import { componentProps } from "./util";
export default {
  name: "attr-number-check",
  props: componentProps,
  computed: {},
  data() {
    return {
      maxValue: undefined,
      minValue: undefined,
    };
  },
  computed: {
    disabledNum() {
      return !this.component.limitValueRange;
    },
  },
  created() {
    if (this.component.limitValueRange) {
      this.maxValue = this.component.maxValue;
      this.minValue = this.component.minValue;
    }
  },
  methods: {
    changeLimit(val) {
      if (val === false) {
        this.minValue = undefined;
        this.maxValue = undefined;
        this.$set(this.component, "minValue", "");
        this.$set(this.component, "maxValue", "");
      }
    },
    changeMinValue(e) {
      if (this.maxValue != undefined && this.maxValue < e) {
        this.$message.warning("最大值应该要大于最小值");
        this.$nextTick(() => {
          this.minValue = undefined;
        });

        return;
      }
      this.minValue = e;
      this.$set(this.component, "minValue", e);
    },
    changeMaxValue(e) {
      if (this.minValue != undefined && e < this.minValue) {
        this.$message.warning("最大值应该要大于最小值");
        this.$nextTick(() => {
          this.maxValue = undefined;
        });
        return;
      }
      this.maxValue = e;
      this.$set(this.component, "maxValue", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.weui {
  display: flex;
  align-items: center;
}
</style>
