<template>
  <fixed-scroll v-if="tab == 'attribute'" class="union-body">
    <div class="left-item" slot="left-header" style="margin-top: 10px">
      合并结果
    </div>
    <div slot="left">
      <etl-union-left
        v-for="item in inputList"
        :node="item"
        :unionFields.sync="node.unionFields"
        :key="item.key"
        @changeChildrenData="changeChildrenData"
      ></etl-union-left>
    </div>
    <div slot="right-header" class="right-top">
      <template v-if="node.unionFields">
        <etl-union-title
          v-for="item in node.unionFields"
          :key="item.field.name"
          :field="item.field"
          @changeChildrenData="changeChildrenData"
        ></etl-union-title>
      </template>
    </div>
    <div slot="scroll" class="scroll-body">
      <div
        class="input-row"
        v-for="row in inputList"
        :key="row.key"
        style="margin-bottom: 10px"
      >
        <template v-if="node.unionFields">
          <etl-union-field
            v-for="(item, index) in node.unionFields"
            :key="item.field.name"
            :unionField="item"
            :fromNode="row"
            :unionFields.sync="node.unionFields"
            :index="index"
            :isDeleteField="isDeleteField"
            :dragField.sync="dragField"
            @deleteNode="deleteNode"
            @insertNode="insertNode"
            @changeChildrenData="changeChildrenData"
          ></etl-union-field>
        </template>
      </div>
    </div>
  </fixed-scroll>
  <div v-else>
    <preview-error v-if="isError"></preview-error>
    <etl-preview
      v-else
      :node="node"
      :etlList="etlList"
      :etlLines="etlLines"
    ></etl-preview>
  </div>
</template>
<script>
import nodeMixin from "./nodeMixin";
import PreviewError from "./PreviewError";
import { isEmpty, uuid } from "@/zgg-core/utils";
import EtlUnionTitle from "./EtlUnionTitle";
import EtlUnionField from "./EtlUnionField";
import EtlUnionLeft from "./EtlUnionLeft";
import EtlPreview from "./EtlPreview";
import { changeChildrenData, isDeleteField } from "./util";

export default {
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },

  components: {
    PreviewError,
    EtlUnionTitle,
    EtlUnionField,
    EtlUnionLeft,
    EtlPreview,
  },
  mixins: [nodeMixin],
  computed: {
    inputList() {
      let fromKeys = this.etlLines
        .filter((item) => item.toKey == this.node.key)
        .map((item) => item.fromKey);

      let list = [];
      fromKeys.forEach((key) => {
        let node = this.etlList.find((item) => item.key == key);
        list.push(this._.cloneDeep(node));
      });
      return list;
    },
  },
  data() {
    return {
      dragField: null,
    };
  },
  created() {
    this.initNodeAttribute();
  },
  methods: {
    isDeleteField,
    changeChildrenData() {
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
    deleteNode(index) {
      this.$delete(this.node.unionFields, index);
    },
    initNodeAttribute() {
      if (!isEmpty(this.node.unionFields)) {
        this.rebuildAttribute();
      }
      let collections = [];
      let unionFields = [];
      if (!isEmpty(this.node.unionFields)) {
        unionFields = this._.cloneDeep(this.node.unionFields);

        this.node.unionFields.forEach((item) => {
          item.stageFields.forEach((row) => {
            if (!collections.includes(row.collection)) {
              collections.push(row.collection);
            }
          });
        });
      }

      let inputList = this.inputList.filter(
        (item) => !collections.includes(item.key),
      );
      inputList.forEach((item) => {
        item.fields
          .filter((field) => field.componentName != "id") //当前数据不加入追加合并
          .forEach((field) => {
            let obj = unionFields.find(
              (col) =>
                col.stageFields.findIndex((row) => {
                  let isExist =
                    row.componentName == field.componentName &&
                    row.comment == field.comment &&
                    row.collection != field.collection;

                  if (isExist) {
                    if (row.componentName == "reference_data") {
                      return (
                        row.component.tableName == field.component.tableName
                      );
                    }
                  }
                  return isExist;
                }) >= 0,
            );
            let count = unionFields.filter(
              (col) =>
                col.stageFields.findIndex(
                  (row) =>
                    row.componentName == field.componentName &&
                    row.comment == field.comment &&
                    row.collection == field.collection,
                ) >= 0,
            ).length;

            if (!obj || count) {
              obj = {
                field: {
                  collection: this.node.key,
                  name: uuid(),
                  componentName: field.componentName,
                  picker: field.picker,
                  comment: field.comment,
                  component: this._.cloneDeep(field.component),
                },
                stageFields: [],
              };
              if (field.name == "flowStatus") {
                obj.field.name = uuid() + "flowStatus";
              }
              obj.stageFields.push(this._.cloneDeep(field));
              unionFields.push(obj);
            } else {
              obj.stageFields.push(this._.cloneDeep(field));
              // console.log(this._.cloneDeep(obj));
            }
          });
      });

      let arr = ["input", "radio_group", "select", "sn_input"];
      let arr2 = ["checkbox_group", "select_checkbox"];
      unionFields.forEach((item) => {
        if (arr.includes(item.field.componentName)) {
          item.field.componentName = "input";
        } else if (arr2.includes(item.field.componentName)) {
          item.field.componentName = "select_checkbox";
        }
      });

      this.$set(this.node, "unionFields", unionFields);
    },
    insertNode({ index, field, old }) {
      this.node.unionFields[index].stageFields.push(field);
      this.node.unionFields[index].field.comment = field.comment;
      if (!this.node.unionFields[old].stageFields.length) {
        this.deleteNode(old);
      }
    },
    rebuildAttribute() {
      this.node.unionFields.forEach((item) => {
        item.stageFields = item.stageFields.filter(
          (row) => !this.isDeleteField(row),
        );
      });
      this.node.unionFields = this.node.unionFields.filter(
        (item) => item.stageFields.length > 0,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.union-body {
  height: 100%;
  overflow: hidden;
}
.left-item {
  width: 160px;
  height: 50px;
  padding: 0 8px;
  line-height: 50px;
  background: #fff;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
}

.col-header {
  width: 126px;
  height: 50px;
  padding: 0 8px;
  line-height: 50px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-left: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.right-top {
  height: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
}

.input-row {
  display: flex;
  width: max-content;
  flex-wrap: nowrap;
}
</style>
