<template>
  <div>
    <div class="weui-row">
      <el-tooltip
        v-if="isDelete"
        content="参数已删除"
        placement="top"
        effect="dark"
      >
        <el-input
          readonly
          :value="flowParam.param.title"
          size="small"
          style="width: 220px; color: #f56c6c"
          class="is-error"
        ></el-input>
      </el-tooltip>
      <el-input
        v-else
        readonly
        :value="flowParam.param.title"
        size="small"
        style="width: 220px"
      ></el-input>
      <div style="font-size: 14px; margin-left: 10px">设置为</div>
      <!-- 值类型切换 -->
      <el-select
        :value="flowParam.paramValue.valueType"
        size="small"
        style="width: 140px; margin-left: 10px"
        placeholder=""
        @change="changeValueType"
        :disabled="disabled"
      >
        <el-option label="节点字段" value="nodeField"></el-option>
        <el-option v-if="hasCustom" label="自定义" value="custom"></el-option>
        <el-option label="空值" value="nil"></el-option>
        <el-option
          v-if="flowParam.param.componentName == 'date_picker'"
          label="动态"
          value="dynamic"
        ></el-option>
        <el-option v-if="hasShowRely" label="公式" value="rely"></el-option>
      </el-select>
      <i @click="del" class="el-icon-delete" style="margin-left: 10px"></i>
    </div>
    <div v-if="flowParam.paramValue.valueType != 'nil'" class="weui-row">
      <!-- 公式 -->
      <template v-if="flowParam.paramValue.valueType == 'rely'">
        <div style="width: 100%; position: relative">
          <el-input
            size="small"
            :value="relyTitle"
            placeholder="请设置公式"
            style="width: 100%"
            readonly
          ></el-input>
          <div
            @click.prevent.stop="openFormula"
            style="
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            "
          ></div>
        </div>

        <formula-dialog
          title="设置公式"
          :originalFormula="
            flowParam.paramValue.rely &&
            flowParam.paramValue.rely.originalFormula
          "
          :nodeKey="nodeKey"
          :treeNodeList="nodeList"
          :fetchMetaFieldComponentList="fetchMetaComponents"
          :fetchRobotParams="fetchRobotParams"
          @save="saveRely"
          ref="relyDialog"
          :disabled="disabled"
          :disabled-funs="['TEXTUSER', 'TEXTDEPT', 'GETUSER']"
        ></formula-dialog>
      </template>
      <!-- 动态日期 -->
      <DynamicDatePicker
        v-else-if="
          flowParam.param.componentName == 'date_picker' &&
          flowParam.paramValue.valueType == 'dynamic'
        "
        v-model="queryField.dynamicTime"
        placeholder="请选择日期"
        size="small"
        style="width: 100%"
        :picker="datePicker"
        :hasCustom="true"
        :filterTimeModeByPicker="true"
        :hasNow="true"
      >
      </DynamicDatePicker>
      <!-- 自定义 -->
      <query-input
        v-else-if="flowParam.paramValue.valueType == 'custom'"
        :field="queryField"
        :areaTree="areaTree"
        :areaProps="areaProps"
        :componentList="params"
        style="width: 100%"
        v-model="queryField.value"
        :disabled="disabled"
      ></query-input>
      <!-- 节点字段 -->
      <rpa-node-fields
        v-else-if="flowParam.paramValue.valueType == 'nodeField'"
        :nodeField="flowParam.paramValue.nodeField"
        :tableField="flowParam.param"
        fieldConfigKey="fillComponents"
        :disabled="disabled"
        :hasBaseRootParam="hasBaseRootParam"
      ></rpa-node-fields>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import {
  fromNodesMixins,
  isCanSetRelyForRpa,
  noCustomComponents,
} from "./rpa-utils";
import { fetchMetaComponents } from "@/api/form";
import { userMixin } from "@/mixin/user-dept";
import rpaNodeFields from "./rpa-node-fields";
import { mapState } from "vuex";
import { getRobotWorkflow } from "./api";
export default {
  mixins: [userMixin, fromNodesMixins],
  components: { rpaNodeFields },
  props: {
    flowParam: Object,
    params: Array,
    disabled: Boolean,
    nodeKey: String,
    hasBaseRootParam: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  inject: {
    getRootParams: {
      default: () => [],
    },
  },
  computed: {
    ...mapState("area", ["areaTree", "areaProps"]),
    relyTitle() {
      if (!isEmpty(this.flowParam.paramValue.rely)) {
        return "已设置公式";
      }
      return "";
    },
    datePicker() {
      if (!["date_minute", "date_hour"].includes(this.flowParam.param.picker)) {
        return this.flowParam.param.picker;
      }
      return "datetime";
    },
    isDelete() {
      return (
        this.params.findIndex(
          (item) => item.name == this.flowParam.param.name,
        ) == -1
      );
    },
    hasCustom() {
      return !noCustomComponents.includes(this.flowParam.param.componentName);
    },
    hasShowRely() {
      return isCanSetRelyForRpa(this.flowParam.param.componentName);
    },
    nodeList() {
      let list = this.getParentNodes();
      let nodeList = list.map((item) => {
        return {
          ...item,
          collection: item.collection || item.formId,
          collectionTitle: item.collectionTitle || item.formTitle,
        };
      });
      if (this.rootFlowParams?.length) {
        nodeList.unshift({
          type: "root",
          key: "root",
          title: "本流程参数",
          components: this.rootFlowParams.map((item) => {
            return {
              ...item,
              key: item.name,
              nodeKey: "root",
            };
          }),
        });
      }

      /**
       * @type Array
       */
      let fromNodeList = this.getFromNodes();
      nodeList = this._.cloneDeep(nodeList);
      nodeList = nodeList.filter((item) => {
        if (item.dataSource == "node_data") {
          return fromNodeList.findIndex((opt) => opt.key == item.nodeKey);
        } else {
          return true;
        }
      });
      nodeList.forEach((item) => {
        if (item.dataSource == "node_data") {
          let node = fromNodeList.find((opt) => opt.key == item.nodeKey);
          item.nodeField = node.nodeField;
        }
      });

      return nodeList;
    },
  },
  watch: {
    queryField: {
      deep: true,
      immediate: false,
      handler(val) {
        if (this.flowParam.paramValue.valueType == "custom") {
          this.$set(this.flowParam.paramValue, "customValue", val.value);
          this.$set(
            this.flowParam.paramValue,
            "customValueDetail",
            val.valueDetail,
          );
        } else if (this.flowParam.paramValue.valueType == "dynamic") {
          this.$set(this.flowParam.paramValue, "dynamicTime", val.dynamicTime);
        }
      },
    },
  },
  data() {
    return {
      queryField: {
        ...this._.cloneDeep(this.flowParam.param),
        value: this.flowParam.paramValue.customValue,
        valueDetail: this.flowParam.paramValue.customValueDetail,
        key: this.flowParam.param.name,
      },
      rootFlowParams: [],
    };
  },
  created() {
    if (typeof this.getRootParams === "function") {
      this.rootFlowParams = this.getRootParams();
    }
  },
  methods: {
    fetchMetaComponents,
    async fetchRobotParams(robotId) {
      let res = await getRobotWorkflow({ robotId });
      /**
       * @type {Array}
       */
      let flowParams = res.data.workflow?.rpaFlow?.config?.flowParams;
      if (flowParams) {
        flowParams.forEach((item) => {
          item.key = item.name;
        });
        return flowParams;
      }
      return [];
    },
    openFormula() {
      this.$refs.relyDialog.open();
    },
    saveRely(originalFormula) {
      this.$set(this.flowParam.paramValue, "rely", { originalFormula });
      this.$refs.relyDialog.close();
    },
    del() {
      this.$emit("del");
    },
    changeValueType(value) {
      let paramValue = this._.cloneDeep(this.flowParam.paramValue);
      paramValue.valueType = value;
      paramValue.customValue = "";
      paramValue.nodeField = undefined;
      paramValue.rely = undefined;
      paramValue.dynamicTime = undefined;

      if (value == "dynamic") {
        // 日期动态
        paramValue.dynamicTime = {
          timeMode: "",
        };
      } else if (value == "nodeField") {
        paramValue.nodeField = {
          nodeKey: "",
          collection: "",
          name: "",
          title: "",
          componentName: "",
          picker: "",
        };
      }

      this.$set(this.flowParam, "paramValue", paramValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-row {
  display: flex;
  align-items: center;
  .is-error {
    ::v-deep {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
}
</style>
