<!--
 * @Description: 自定义文本组件
 * @Version: 0.1
 * @Autor: zhengxn
 * @Date: 2022-12-08 09:05:16
-->

<template>
  <div class="dashboard-filter__container">
    <div ref="centerNumber" v-html="freeTextModel.description"></div>
  </div>
</template>

<script>
export default {
  name: "FreeText",
  props: {
    freeTextModel: {
      type: Object,
      default() {
        return {
          extParam: { title: "", imgWidth: "", imgHeight: "" },
          title: "",
          description: "",
          componentName: "html",
        };
      },
    },
  },
  created() {
    this.$nextTick(() => {
      let numberRef = this.$refs.centerNumber.querySelectorAll("img");
      if (numberRef.length > 0) {
        console.log();
        numberRef.forEach((item, index) => {
          if (this.freeTextModel.extParam.imgWidth) {
            item.style.width = this.freeTextModel.extParam.imgWidth[index];
          }
          if (this.freeTextModel.extParam.imgHeight) {
            item.style.height = this.freeTextModel.extParam.imgHeight[index];
          }
        });
      }
    });
  },
  methods: {
    getData() {
      console.log("dashboardFilter");
    },
    resizeChart() {
      let clientWidth = this.$el.parentElement.clientWidth;
      let clientHeight = this.$el.parentElement.clientHeight;
      let numberRef = this.$refs.centerNumber.querySelectorAll("img");
      let imgHeightArr = [];
      let imgWidthArr = [];
      if (numberRef) {
        numberRef.forEach((item, index) => {
          let width = item?.offsetWidth;
          let height = item?.offsetHeight;

          let width1 = item?.attributes[1]?.value;
          let height1 = item?.attributes[2]?.value;

          if (typeof width1 == "string" && width1.indexOf("px") != -1) {
            width1 = item?.naturalWidth;
          }
          if (!height1) {
            height1 = item?.naturalHeight;
          }
          width1 = parseInt(width1);
          let scaleDown = height1 / width1;
          if (clientWidth > width1) {
            clientWidth = width1;
          }
          if (item) {
            item.style.width = parseInt(clientWidth) + "px";
            item.style.height =
              parseInt(Math.ceil(clientWidth * scaleDown)) + "px";
          }

          let imgHeight = parseInt(Math.ceil(clientWidth * scaleDown)) + "px";
          let imgWidth = parseInt(clientWidth) + "px";
          imgHeightArr.push(imgHeight);
          imgWidthArr.push(imgWidth);
        });
        this.freeTextModel.extParam.imgHeight = imgHeightArr;
        this.freeTextModel.extParam.imgWidth = imgWidthArr;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-filter__container {
  ::v-deep img {
    max-width: 100%;
  }
}
.input ::v-deep .el-date-editor {
  width: 100%;
}
</style>
