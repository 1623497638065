<template>
  <div class="zgg-upload_container">
    <el-upload
      ref="upload"
      class="upload-container"
      :show-file-list="showFileList"
      :list-type="listType"
      :file-list.sync="list"
      :http-request="Upload"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
      :on-preview="handlePreview"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :on-progress="handleProgress"
      :accept="accept"
      :limit="limit ? limit : 0"
      :drag="drag"
      action
      :multiple="multiple"
    >
      <div v-if="!$slots.default">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
      <slot></slot>
    </el-upload>
  </div>
</template>

<script>
import axios from "axios";
import request from "@/utils/request";
import { ossLoadCredential } from "@/api/oss";
import OSS from "ali-oss";
import { hashFile } from "@/utils/tools";
import { attachmentCheckExits, attachmentSave } from "@/api/attachment";

import { getFileOriginalName, getUploadPathByName } from "@/utils/fileNameUtil";

let credentials = null; // STS凭证
let ossClient = null; // oss客户端实例
const bucket = "yunguangong-file"; // bucket名称
const region = "oss-cn-beijing"; // oss服务区域名称
const partSize = 1 * 1024 * 1024; // 每个分片大小
const parallel = 10; // 同时上传的分片数
const checkpoints = {}; // 所有分片上传文件的检查点

export default {
  props: {
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
    drag: {
      type: Boolean,
      default() {
        return true;
      },
    },
    beforeAvatarUpload: {
      type: Function,
      default() {
        return () => true;
      },
    },
    showFileList: {
      type: Boolean,
      default() {
        return true;
      },
    },
    limit: {
      type: Number,
      default() {
        return 0;
      },
    },
    listType: {
      type: String,
      default() {
        return "text";
      },
    },
    accept: {
      type: String,
      default() {
        return "";
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  components: {},
  data() {
    return {
      isUploading: false,
      successCount: 0,
      fileOssObjectList: [],
    };
  },
  computed: {
    list: {
      get() {
        return this.fileList;
      },
      set(val) {
        this.$emit("update:fileList", val);
      },
    },
  },

  methods: {
    clearFiles() {
      this.$refs.upload.clearFiles();
      this.list = [];
    },
    //处理上传组件的上传列表的变化的回调
    //【需求】优化附件上传时，文件主题默认为第一个附件的名称
    handleChange(file, fileList) {
      if (file.status == "ready") return;
      // this.fileList = fileList;
      this.$emit("change", fileList);
    },
    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(`每次只能上传 ${this.limit} 个文件`);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      // let rootSrc = ""
      // let filePreview = rootSrc + file.response.url
      // window.open(filePreview, "_blank")
    },
    // 删除文件之前的钩子
    beforeRemove(file, fileList) {
      if (file.status == "ready") return;

      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      if (file.status == "ready") return;
      // file.raw && file.raw.source && file.raw.source.cancel("中止上传！")
      // this.fileList = fileList
      this.$emit("change", fileList);
      //
      // if (file.status === 'uploading') {
      //   this.isUploading = this.successCount !== fileList.length;
      // } else if (file.status === 'success') {
      //   this.successCount -= 1
      //   this.isUploading = this.successCount !== fileList.length;
      // }
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      // file.id = response.id
      // this.successCount += 1
      // this.isUploading = this.successCount !== fileList.length;
      this.$emit("success", fileList, file);
      this.$emit("successSingle", response);
    },
    handleProgress(file, param, fileList) {
      // this.isUploading = this.successCount !== fileList.length;
      // console.log(file)
      // file.percent = 0
      // param.onProgress(file)
      this.$emit("onProgress", file);
    },
    //todo 未来需要添加删除附件的功能
    Upload(params) {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      let form = new FormData();
      form.append("attachmentFile", params.file);
      form.append(
        "originalName",
        params.file.name.substring(0, params.file.name.lastIndexOf(".")),
      );

      request({
        url: "/attachment/upload",
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        cancelToken: source.token,
        data: form,
        onUploadProgress: (progressEvent) => {
          params.file.percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          params.file.source = source;
          params.onProgress(params.file);
        },
      })
        .then((resp) => {
          params.onSuccess(resp.data.attachment);
        })
        .catch((error) => {});
    },
  },
  async mounted() {},
};
</script>

<style lang="less">
.hide-upload {
  display: none;
}
</style>
