<template>
  <div style="margin-top: 10px">
    <div @click.stop="visible = true" class="el-meta-field">
      <div class="meta-text">
        {{
          component.extParam && component.extParam.mateLabel
            ? component.extParam.mateLabel
            : "请选择关联表单"
        }}
      </div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <data-source
      :visible.sync="visible"
      :idNotEquals="idNotEquals"
      mode="field"
      @changeSource="changeSource"
    ></data-source>

    <!-- 下拉选择器、关联表数据 -->
    <template
      v-if="
        component.componentName == 'select' &&
        component.extParam &&
        component.extParam.mateLabel
      "
    >
      <!-- 下拉列表中显示字段 -->
      <attr-select-fields
        :component="component.metaFieldOption"
        :columns="componentList"
        style="margin: 10px 0 0"
      ></attr-select-fields>
    </template>

    <div class="attr-label yui" style="margin-top: 10px">选项排序</div>
    <el-select
      v-if="component.metaFieldOption"
      v-model="component.metaFieldOption.orderBy"
      placeholder=""
      size="mini"
      style="width: 100%"
    >
      <el-option
        label="按选项的值升序"
        value="orderByOptionValueAsc"
      ></el-option>
      <el-option
        label="按选项的值降序"
        value="orderByOptionValueDesc"
      ></el-option>
      <el-option
        label="按提交时间升序"
        value="orderByCreatedTimeAsc"
      ></el-option>
      <el-option
        label="按提交时间降序"
        value="orderByCreatedTimeDesc"
      ></el-option>
    </el-select>
    <template
      v-if="
        component.componentName == 'select' ||
        component.componentName == 'select_checkbox'
      "
    >
      <attr-filter
        style="margin: 0px -10px -10px"
        v-if="component.optionSource == 'metaField'"
        :component="component"
        :params="params"
        :tableColumns="componentList"
      ></attr-filter>

      <attr-table-action
        v-if="showAction()"
        style="margin: 10px 0"
        :component="component"
      ></attr-table-action>
    </template>
  </div>
</template>
<script>
import { componentProps } from "./util";
import Clickoutside from "element-ui/src/utils/clickoutside";
import { fetchFormList, getDataflow, getFormDetail } from "../api";
import { metaFields } from "@zgg-core-utils/whiteList";
import FieldsSelect from "@/components/FieldsSelect";
import attrFilter from "@/views/lowCode/form/attribute/attr-filter";
import attrTableAction from "@/views/lowCode/form/attribute/attr-table-action";
import dataSource from "@/components/data-source";
import { fetchMetaComponents, fetchMetaFieldComponentList } from "@/api/form";
import attrSelectFields from "@/views/lowCode/form/attribute/attr-select-fields";
export default {
  name: "attr-options-reference",
  inject: ["getComponents"],
  directives: { Clickoutside },
  components: {
    FieldsSelect,
    attrFilter,
    attrTableAction,
    dataSource,
    attrSelectFields,
  },
  props: componentProps,
  data() {
    return {
      visible: false,
      formName: "",
      tableData: [],
      tableLoading: false,
      props: {
        label: "title",
        isLeaf: "leaf",
      },
      componentList: [],
      idNotEquals: "",
    };
  },
  computed: {},

  created() {
    this.idNotEquals = this.$route.query.id;
    this.fetchFormList();
    if(this.component.metaFieldOption) {
      let tableName = this.component.metaFieldOption.tableName;
      //设置下拉项默认排序方式
      if(!this.component.metaFieldOption.orderBy){
        this.component.metaFieldOption.orderBy = "orderByOptionValueAsc"
      }
      if (tableName) {
        this.getFormDetail(tableName);
      }
    }
  },
  methods: {
    showAction() {
      if(this.component.metaFieldOption) {
        let tableName = this.component.metaFieldOption.tableName;
        if (tableName) {
          return (
              tableName.indexOf("dataflow_") == -1 && tableName.indexOf("at_") == -1
          );
        }
      }
      return false;
    },
    buildTreeNodes(components) {
      // 关联数据时候字段过滤
      let list = [];

      components
        .filter((item) => metaFields.includes(item.componentName))
        .forEach((item) => {
          if (item.components) {
            list = list.concat(this.buildTreeNodes(item.components));
          } else if (item.form) {
            list.push(this._.cloneDeep(item));
          }
        });

      return list;
    },

    handleClose() {
      this.visible = false;
    },
    searchForm(value) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.fetchFormList();
      }, 500);
    },
    fetchFormList(data, callback) {
      this.tableData = [];
      this.tableLoading = true;
      let postData = {
        pageNumber: 1,
        pageSize: 100,
        title: this.formName,
        ...data,
        removeComponent: true,
      };

      fetchFormList(postData)
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.data.page.list;

          if (typeof callback === "function") {
            callback();
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    changeSource(data) {
      this.getFormDetail(data.tableName);
      let metaFieldOption = {};
      if (this.component.metaFieldOption) {
        metaFieldOption = this._.cloneDeep(this.component.metaFieldOption);
      }
      if (data.tableName != this.component.metaFieldOption.tableName) {
        metaFieldOption.tableColumns = [];
        metaFieldOption.relationQuery = [];
      }
      metaFieldOption.tableName = data.tableName;
      metaFieldOption.labelFieldName = data.field.name;
      metaFieldOption.valueFieldName = data.field.name;

      this.mateLabel = data.tableTitle + "--" + data.field.title;
      metaFieldOption.field = this._.cloneDeep(data.field);

      if (this.component.componentName == "select") {
        metaFieldOption.relationQuery = [];
      }
      if (!this.component.extParam) {
        this.$set(this.component, "extParam", { mateLabel: this.mateLabel });
      } else {
        this.$set(this.component.extParam, "mateLabel", this.mateLabel);
      }
      this.$set(this.component, "metaFieldOption", metaFieldOption);
      console.log('jjkkll')
    },

    getFormDetail(formId) {
      fetchMetaComponents(formId).then((res) => {
        this.componentList = res.data.list;
      });
    },
    deleteMetaColumns(index, cIndex) {
      if (cIndex == -1) {
        this.component.metaFieldOption.tableColumns.splice(index, 1);
      } else {
        this.component.metaFieldOption.tableColumns[index].subColumns.splice(
          cIndex,
          1
        );
        this.$nextTick(() => {
          if (
            this.component.metaFieldOption.tableColumns[index].subColumns
              .length == 0
          ) {
            this.component.metaFieldOption.tableColumns.splice(index, 1);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-meta-field {
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  padding-right: 10px;
  border-radius: 5px;
  color: #606266;
  cursor: pointer;

  .meta-text {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}
.weui-col {
  display: flex;
  align-items: center;

  .weui-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5;
  }
}
.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .empty-text {
    font-size: 12px;
    text-align: center;
    line-height: 28px;
  }
}
</style>
