<template>
  <div>
    <el-table :data="tableData" style="width: 100%" :span-method="spanMethod">
      <el-table-column
        v-for="(col, index) in column.columns"
        :align="col.align"
        :key="index"
        :label="col.title"
        :width="
          col.field.componentName == 'json_form' &&
          col.subColumns &&
          col.subColumns.length &&
          col.width
            ? col.width
            : ''
        "
        :prop="col.subColumns && col.subColumns.length ? '' : col.field.name"
      >
        <template slot-scope="{ row }">
          <attachment-thumb
            v-if="
              col.field.componentName == 'attachment_uploader' ||
              col.field.componentName == 'image_uploader'
            "
            :file-type="
              col.field.componentName == 'image_uploader' ? 'image' : ''
            "
            :list="row[col.field.name]"
          ></attachment-thumb>

          <el-image
            v-else-if="
              col.field.componentName == 'sign_input' && row[col.field.name]
            "
            :src="'data:image/png;base64,' + row[col.field.name].signText"
            class="code-image"
            fit="scale-down"
            @click="reviewSign(row[col.field.name])"
          ></el-image>

          <span v-else>{{ getValue(row, col) }}</span>
        </template>
        <template
          v-if="
            col.field.componentName == 'json_form' &&
            col.subColumns &&
            col.subColumns.length
          "
        >
          <el-table-column
            v-for="(subCol, sIndex) in col.subColumns"
            :key="sIndex"
            :align="subCol.align"
            :label="subCol.title"
            :width="subCol.width ? subCol.width : ''"
            :prop="subCol.field.name"
          >
            <template slot-scope="scope">
              <attachment-thumb
                v-if="
                  subCol.field.componentName == 'attachment_uploader' ||
                  subCol.field.componentName == 'image_uploader'
                "
                :file-type="
                  col.field.componentName == 'image_uploader' ? 'image' : ''
                "
                :list="
                  scope.row && scope.row[subCol.field.name]
                    ? scope.row[subCol.field.name]
                    : []
                "
              ></attachment-thumb>

              <el-image
                v-else-if="
                  subCol.field.componentName == 'sign_input' &&
                  scope.row[subCol.field.name]
                "
                :src="
                  'data:image/png;base64,' +
                  scope.row[subCol.field.name].signText
                "
                class="code-image"
                fit="scale-down"
                @click="reviewSign(scope.row[subCol.field.name])"
              ></el-image>

              <span v-else>{{ getValue(scope.row, subCol) }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="right"
      v-if="
        column.queryObject.pageSize > 1 &&
        (column.hasPageTool == undefined || column.hasPageTool)
      "
    >
      <pagination
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadingList"
        :pageSizes="[]"
      />
    </div>
  </div>
</template>
<script>
import { fetchPage } from "../api";
import { mapMutations } from "vuex";
import Pagination from "@/components/Pagination";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import moment from "moment";
export default {
  components: { Pagination },
  props: {
    column: Object,
    data: Object,
    form: Object,
  },

  data() {
    return {
      page: {
        pageSize: 10,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      tableData: [],
      watchObj: {},
      watchLoading: {},
    };
  },
  destroyed() {
    Object.values(this.watchObj).forEach((val) => {
      val();
    });
  },
  created() {
    let isAuto =
      this.column.relationQuery.findIndex(
        (item) => item.valueType == "tableField"
      ) == -1;
    let isEmptyField = true;
    let fieldName;

    this.column.relationQuery
      .filter((item) => item.valueType == "tableField")
      .forEach((item) => {
        let key = `form.${item.value}`;
        if (!isEmpty(this.form[item.value])) {
          isEmptyField = false;
          fieldName = item.value;
        }
        this.watchObj[item.value] = this.$watch(key, (val, old) => {
          if (this.watchLoading[item.value]) {
            return;
          }
          if (isEmpty(val) && isEmpty(old)) {
            return;
          }

          if (this.linkTimer) {
            clearTimeout(this.linkTimer);
          }
          this.linkTimer = setTimeout(() => {
            this.loadingList(item.value);
          }, 300);
        });
      });
    if (isAuto || !isEmptyField) {
      this.loadingList(fieldName);
    }
  },
  methods: {
    ...mapMutations("image", ["showViewer", "setSrcList"]),
    reviewSign(obj) {
      this.setSrcList(["data:image/png;base64," + obj.signText]);
      this.showViewer(0);
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        this.column.columns.findIndex(
          (col) => col.field.name == column.property
        ) >= 0 ||
        column.type == "index" ||
        column.type == "action"
      ) {
        if (row.rowSpan) {
          return {
            rowspan: row.rowSpan,
            colspan: 1,
          };
        } else if (row.rowSpan === 0) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    getMomentFromat(type) {
      if (type == "year") {
        return "YYYY";
      } else if (type == "month") {
        return "YYYY-MM";
      } else if (type == "date") {
        return "YYYY-MM-DD";
      } else if (type == "date_minute") {
        return "YYYY-MM-DD HH:mm";
      } else {
        return "YYYY-MM-DD HH:mm:ss";
      }
    },
    getValue(row, col) {
      let field = col.field;
      let rowObj = row;

      if (field.componentName == "address_input") {
        let obj = rowObj[field.name];
        if (obj) {
          let str = `${obj.province}`;
          if (obj.city) {
            str += obj.city;
          }
          if (obj.county) {
            str += obj.county;
          }
          if (obj.address) {
            str += obj.address;
          }
          return str;
        }
        return;
      } else if (
        field.componentName == "checkbox_group" ||
        field.componentName == "select_checkbox"
      ) {
        let a = rowObj[field.name];

        if (typeof a === "string") {
          return JSON.parse(a).join(",");
        } else if (typeof a === "object") {
          return a.join(",");
        }
      } else if (field.componentName == "position_input") {
        let data = rowObj[field.name];
        if (data) {
          return data.address;
        }
        return;
      } else if (field.componentName == "user_list_select") {
        let value = rowObj[field.name + "UserMap"];
        if (value) {
          let list = Object.values(value).map((item) => item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "user_select") {
        let value = rowObj[field.name + "UserInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_select") {
        let value = rowObj[field.name + "DepartmentInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_list_select") {
        let value = rowObj[field.name + "DepartmentInfoList"];
        if (value) {
          let list = value.map((item) => item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "date_picker") {
        let value = rowObj[field.name];
        if (value) {
          let picker;
          if (field.component) {
            picker = field.component.picker;
          }
          if (picker) {
            value = moment(value).format(this.getMomentFromat(picker));
          }
          return value;
        }
        return;
      } else {
        return rowObj[field.name];
      }
      return;
    },
    loadingList(fieldName) {
      let advanceQuery = [];
      let queryObject = {
        pageNumber: this.page.pageNumber,
        pageSize: this.column.queryObject.pageSize,
      };
      this.column.relationQuery.forEach((row) => {
        if (row.valueType == "custom") {
          // 自定义的值
          if (row.value.countyId) {
            advanceQuery.push({
              key: row.key + ".countyId", //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value.countyId, //查询值
              componentName: row.componentName,
            });
          } else if (row.value.cityId) {
            advanceQuery.push({
              key: row.key + ".cityId", //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value.cityId, //查询值
              componentName: row.componentName,
            });
          } else if (row.value.provinceId) {
            advanceQuery.push({
              key: row.key + ".provinceId", //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value.provinceId, //查询值
              componentName: row.componentName,
            });
          } else {
            advanceQuery.push({
              key: row.key, //查询的字段name属性
              oper: row.oper, //查询操作符
              value: row.value, //查询值
              componentName: row.componentName,
            });
          }
        } else if (row.valueType == "tableField") {
          // 当前表单字段
          let value = this.form[row.value];

          advanceQuery.push({
            key: row.key,
            oper: row.oper,
            value: value,
            componentName: row.componentName,
          });
        }
      });
      if (advanceQuery.length) {
        queryObject.advanceQuery = advanceQuery;
      }

      fetchPage({
        collection: this.column.tableName,
        queryObject: JSON.stringify(queryObject),
      }).then(({ data }) => {
        let arr = this.column.columns.filter(
          (item) => item.subColumns && item.subColumns.length > 0
        );
        if (!arr.length) {
          this.tableData = data.page.list;
        } else {
          let tableData = [];
          data.page.list.forEach((item) => {
            let lens = [];
            arr.forEach((a) => {
              if (item[a.field.name]) {
                lens.push(item[a.field.name].length);
              }
            });
            if (lens.length > 0) {
              let max = Math.max(...lens);
              for (let index = 0; index < max; index++) {
                let obj = { ...item };
                if (index == 0) {
                  obj.rowSpan = max;
                } else {
                  obj.rowSpan = 0;
                }
                arr.forEach((col) => {
                  let values = item[col.field.name];
                  if (values) {
                    let el = values[index];
                    if (el) {
                      for (const [key, value] of Object.entries(el)) {
                        obj[key] = value;
                      }
                    }
                  }
                });
                tableData.push(obj);
              }
            } else {
              tableData.push(item);
            }
          });

          this.tableData = tableData;
        }

        this.page.totalRow = data.page.totalRow;
        this.page.pageSize = data.page.pageSize;

        this.page.pageNumber = data.page.pageNumber;
        if (fieldName) {
          setTimeout(() => {
            this.watchLoading[fieldName] = false;
          }, 500);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px;
}
</style>