<template>
  <el-dialog
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    fullscreen
    @close="handleClose"
    :show-close="false"
  >
    <div slot="title" style="display: flex; flex-direction: row">
      <span style="flex: 1; display: flex; align-items: center"
        >移动端布局设计</span
      >
      <div>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">保 存</el-button>
      </div>
    </div>
    <div class="body">
      <div class="body-right card-border">
        <div class="body-contain">
          <div class="draggable-contain">
            <draggable
              v-model="draggableList"
              animation="300"
              group="column"
              @add="addSuccess"
            >
              <transition-group>
                <div
                  v-for="(item, index) in draggableList"
                  class="draggable-card"
                  :class="{
                    'draggable-container-card': ['dashboard', 'tab'].includes(
                      item.componentName,
                    ),
                  }"
                  :key="item.key"
                  :style="calDashItemContainerHeight(item)"
                >
                  <span
                    v-if="!['dashboard', 'tab'].includes(item.componentName)"
                    class="iconfont icon-eye-off-fill"
                    @click="handleHideView(item, index)"
                  ></span>
                  <div class="title" v-if="isShowTitle(item)">
                    {{ item.extParam.title }}
                  </div>
                  <div
                    class="draggable-card-content"
                    style="
                      position: relative;
                      height: 0;
                      flex: 1;
                      overflow: auto;
                    "
                  >
                    <div
                      v-if="['dashboard', 'tab'].includes(item.componentName)"
                      style="min-height: 100px; height: 100%"
                    >
                      <mobile-layout-config-container
                        :calDashItemContainerHeight="calDashItemContainerHeight"
                        :isShowTitle="isShowTitle"
                        :parent="item"
                        :hiddenComponentList.sync="hiddenComponentList"
                        @fetchDashboardData="fetchDashboardData"
                      >
                        <template v-slot:chart="slotData">
                          <chart-component
                            style="flex: 1"
                            :isH5="true"
                            :view="slotData.component"
                            :ref="'chart_' + slotData.component.key"
                            :refreshDashboard="refreshDashboard"
                          ></chart-component>
                        </template>
                      </mobile-layout-config-container>
                    </div>
                    <template v-else>
                      <div
                        style="
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          z-index: 999;
                        "
                      ></div>
                      <chart-component
                        :isH5="true"
                        :view="item"
                        :ref="'chart_' + item.key"
                        :refreshDashboard="refreshDashboard"
                      >
                      </chart-component>
                    </template>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
      <div class="card-border body-left">
        <el-tabs :stretch="true">
          <el-tab-pane label="隐藏的组件" name="hiddenView"></el-tab-pane>
        </el-tabs>
        <div class="hidden-view-list">
          <draggable
            v-model="hiddenComponentList"
            animation="300"
            :group="group"
            @end="onDragEnd"
            filter=".forbid"
          >
            <transition-group>
              <div
                class="hidden-view-card"
                v-for="(item, index) in hiddenComponentList"
                :key="item.key"
                :class="item.parentContianer ? 'forbid' : ''"
              >
                <div class="dashboard-title">
                  <el-tooltip
                    :content="getTitle(item)"
                    placement="bottom"
                    effect="light"
                  >
                    <span v-html="getTitle(item)"></span>
                  </el-tooltip>
                </div>
                <span
                  class="iconfont icon-yulan_fill"
                  @click="handleShowView(item, index)"
                ></span>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Draggable from "vuedraggable";
import chartComponent from "@/views/lowCode/dashboard/chart-component.vue";
import { dashboardMixin } from "./dashboardMixin";
import mobileLayoutConfigContainer from "@/views/lowCode/dashboard/mobile-layout-config-container.vue";

export default {
  mixins: [dashboardMixin],
  components: {
    Draggable,
    chartComponent,
    mobileLayoutConfigContainer,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    components: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Object,
      default: () => {},
    },
    isShowTitle: {
      type: Function,
      default: () => true,
    },
    calDashItemContainerStyle: {
      type: Function,
      default: () => "",
    },
  },

  data() {
    return {
      draggableList: [],
      hiddenComponentList: [],
      group: {
        name: "column",
        pull: true,
        put: false,
      },
    };
  },
  mounted() {
    let fun = (item) => {
      if (!item.wapConfig) {
        item.wapConfig = {
          hidden: false,
        };
      }
      if (item.wapConfig.hidden) {
        this.hiddenComponentList.push(item);
      }
    };
    this.components.forEach((item) => {
      fun(item);
      if (!item.wapConfig.hidden) {
        this.draggableList.push(item);
      }
      if (item.componentName == "dashboard") {
        item.components.forEach((row) => {
          row.parentContianer = item.key;
          fun(row);
        });
      } else if (item.componentName == "tab") {
        item.components.forEach((tab) => {
          tab.components.forEach((col) => {
            col.parentContianer = item.key;
            fun(col);
          });
        });
      }
    });
    this.fetchDashboardData(this.componentList);
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    addSuccess(e) {
      this.draggableList[e.newIndex].wapConfig.hidden = false;
    },
    onDragEnd(e) {
      if (this.draggableList[e.newDraggableIndex]) {
        this.updateChart(this.draggableList[e.newDraggableIndex]);
      }
    },
    getTitle(item) {
      //数据组件及按钮组件显示标题
      if (item.dataSource || item.componentName === "button_group") {
        return item.extParam.title || "";
      } else if (item.componentName == "dashboard_filter") {
        return `筛选器:${item.title}`;
      } else if (item.componentName == "carousel_view") {
        return "轮播图";
      } else if (item.componentName == "include") {
        return `内嵌:${item.url}`;
      } else if (item.componentName == "title_view") {
        return `标题:${item.title}`;
      } else if (item.componentName == "html") {
        return "自定义文本";
      } else if (item.componentName == "count_down") {
        return `倒计时:${item.extParam.title}`;
      } else if (["dashboard", "tab"].includes(item.componentName)) {
        return `容器组件:${item.extParam.title}`;
      }
    },
    /**
     * 计算仪表盘组件的容器样式
     * @param item
     * @returns {{}}
     */
    calDashItemContainerHeight(item) {
      let style = this.calDashItemContainerStyle(item);
      style = {
        ...style,
        display: "flex",
        "flex-direction": "column",
        "box-sizing": "border-box",
      };
      let arr1 = ["pivot_table", "table", "gantt"];
      let arr2 = ["carousel_view", "metric_table"];
      if (arr1.includes(item.componentName)) {
        style = {
          ...style,
          height: "300px",
        };
      } else if (
        item.componentName == "dashboard_filter" ||
        item.componentName === "button_group"
      ) {
        style = {
          ...style,
        };
      } else if (["dashboard", "tab"].includes(item.componentName)) {
        style = {
          ...style,
          height: "100%",
        };
      } else if (item.componentName == "title_view") {
        style = {
          ...style,
          height: "40px",
        };
      } else if (item.componentName == "html") {
        style = {
          ...style,
          "max-height": "232px",
        };
      } else if (arr2.includes(item.componentName)) {
        let style2 = {};
        if (item.componentName == "metric_table") {
          let colorConfig = item.colorConfig;
          if (colorConfig?.backgroundColor || colorConfig?.backgroundImage) {
            if (colorConfig?.backgroundEnum == "image") {
              style2 = {
                "background-color": "transparent",
                "background-image": `url(${colorConfig?.backgroundImage})`,
                "background-size": `${
                  colorConfig?.backgroundImageFillEnum == "center_fill"
                    ? "cover"
                    : "100% 100%"
                }`,
                "background-position": "center",
                "background- repeat": "no-repeat",
              };
            } else {
              if (
                colorConfig?.backgroundColor &&
                colorConfig?.backgroundColor.includes(",")
              ) {
                style2 = {
                  background: `linear-gradient(${colorConfig?.backgroundColor})`,
                };
              } else {
                style2 = {
                  "background-color": `${colorConfig?.backgroundColor}`,
                };
              }
            }
          }
        }
        style = {
          ...style,
          ...style2,
          height: "180px",
        };
      } else {
        style = {
          ...style,
          height: "232px",
        };
      }
      return style;
    },
    handleHideView(item, index) {
      this.draggableList.splice(index, 1);
      this.hiddenComponentList.push(item);
      item.wapConfig.hidden = true;
    },
    handleShowView(item, index) {
      this.hiddenComponentList.splice(index, 1);
      if (!item.parentContianer) {
        this.draggableList.push(item);
      }
      item.wapConfig.hidden = false;
      this.updateChart(item);
    },
    updateChart(item) {
      this.fetchDashboardData([item]);
    },
    refreshDashboard() {
      this.fetchDashboardData(this.componentList);
    },
    cancel() {
      this.dialogVisible = false;
    },
    confirm() {
      let arr = [...this.draggableList];
      this.hiddenComponentList.forEach((item) => {
        if (!item.parentContianer) {
          arr.push(item);
        }
      });
      this.$emit("update:components", arr);
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$gap-size: 10px;
$header-height: 76px;
.body {
  padding: $gap-size;
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{$header-height});
  background-color: #f2f6fc;
  .body-right {
    display: flex;
    justify-content: space-around;
    flex: 1;
    background-color: white;
    padding: 24px;
    .body-contain {
      margin-bottom: auto;
      margin-top: auto;
      height: 100%;
      width: 356px;
      padding: 10px;
      border: 3px solid #f2f6fc;
      border-radius: 20px;
    }
  }
  .body-left {
    width: 256px;
    margin-left: $gap-size;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
}
.card-border {
  border-radius: 10px;
}
.draggable-card {
  position: relative;
  width: 310px;
  margin: 10px 0 10px 10px;
  padding: 0 12px 12px 12px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid transparent;
  &:hover {
    cursor: move;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #2323e3;
    .iconfont {
      cursor: pointer;
      display: inline-block;
      z-index: 9999;
    }
  }
}
.draggable-container-card {
  padding: 0 !important;
  .draggable-card-content {
    flex: unset !important;
    overflow: unset !important;
    height: 100% !important;
  }
  &:hover {
    border: 0.5px solid #3636e1;
  }
}
.iconfont {
  display: none;
  position: absolute;
  margin-right: 0px;
  top: 0;
  right: 0;
  padding: 2px;
  border-radius: 2px;
  background-color: #fff;
  z-index: 11;
  &:hover {
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
.draggable-contain {
  position: relative;
  background-color: rgb(242, 246, 252);
  height: 100%;
  overflow: auto;
}
.hidden-view-list {
  position: relative;
  padding: 10px;
  height: 100%;
  overflow: auto;
}
.hidden-view-card {
  position: relative;
  padding: 10px;
  margin-bottom: 8px;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(166, 166, 170, 0.25);
  &:hover {
    cursor: move;
    .iconfont {
      cursor: pointer;
      display: block;
    }
  }
}
.dashboard-title {
  height: 100%;
  line-height: 60px;
  text-align: center;
  padding: 10px;
  background-color: #f2f6fc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.title {
  display: flex;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgba($color: #091e42, $alpha: 0.95);
  font-weight: 600;
}
.draggable-card {
  ::v-deep .selectTextarea {
    font-size: 14px !important;
  }
}
</style>
