<template>
    <div
      style="
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 32px 68px;
      "
    >
        <div class="label">支付设置</div>
        <div class="description"></div>
        <div class="content">
            <div v-if="payMerchantList.length == 0">
                <el-button
                    type="primary"
                    @click="createMerchant"
                    >创建商户
                </el-button>
            </div>
            <template v-else>
                <el-form
                    label-position="left"
                    label-width="auto"
                    ref="form"
                    class="form-content"
                >
                    <div v-for="(item, index) in payMerchantList" :key="index">
                        <el-form-item label="是否开启">
                            <el-switch
                                v-model="item.enabled"
                                @change="changeSwitch(item)"
                            ></el-switch>
                        </el-form-item>
                        <el-form-item label="商户号" prop="merchantNum">
                            <el-input
                                v-model="item.merchantNum" 
                                size="medium"
                                :ref="`merchantNum_${index}`"
                                readonly
                            ></el-input>
                            <el-button 
                                @click.prevent="copyInfo(`merchantNum_${index}`)"  
                                size="medium"
                                style="margin-left: 10px;"
                            >复制</el-button>
                        </el-form-item> 
                        <el-form-item label="签名" prop="sign">
                            <el-input
                                v-model="item.sign"
                                type="password"
                                :ref="`sign_${index}`"
                                id="sign"
                                size="medium"
                                readonly
                            >
                                <i
                                    class="el-icon-view el-input__icon"
                                    slot="suffix"
                                    @click="handleIconClick('sign')">
                                </i>
                            </el-input>
                            <el-button 
                                @click.prevent="copyInfo(`sign_${index}`)"  
                                size="medium"
                                style="margin-left: 10px;"
                            >复制</el-button>
                            <el-button 
                                type="text"
                                @click="reGenSign(item)"  
                                size="medium"
                            >重新生成</el-button>
                        </el-form-item>
                    </div>
                </el-form>
                <!-- 支付方式 -->
                <div class="pay-list">
                    <div class="pay-item">
                        <span class="name">支付宝</span>
                        <el-button
                            @click="editPayType('alipay')"
                            type="primary"
                            size="medium"
                        >设置</el-button>
                    </div>
                    <div class="pay-item">
                        <span class="name">微信支付</span>
                        <el-button 
                            @click="editPayType('wechat')"
                            type="primary"  
                            size="medium"
                        >设置</el-button>
                    </div>
                </div>
            </template>

        </div>

        <!-- 支付宝商户配置/微信商户配置 -->
        <el-dialog
            :title="title"
            :visible.sync="visible"
            @closed="close"
            width="650px"
        >
            <div style="padding: 20px">
                <el-button
                    v-if="payTypeOptions.length > 0 && payTypeOptions.length > payConfigs.length"
                    type="primary"
                    @click="addPayType"  
                    size="medium"
                >添加支付方式</el-button>
                <div style="max-height: 450px; overflow: auto; padding: 0 10px;">
                    <el-form ref="payConfigs" label-width="120px" style="margin-top: 20px;">
                        <div v-for="(item, index) in payConfigs" 
                            :key="index" 
                            class="payConfig"
                            :class="{
                                'is-border': !item?.deleted,
                                'is-error': errorIndex == index && isSave,
                            }"
                        >
                            <template v-if="!item?.deleted">
                                <el-form-item label="支付方式" prop="type">
                                    <el-select v-model="item.payType" placeholder="请选择" style="width: 100%;">
                                        <el-option
                                            v-for="opt in getPayTypes()"
                                            :key="opt.name"
                                            :label="opt.remark"
                                            :value="opt.name"
                                            :disabled="opt.disabled">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="应用ID" prop="appid">
                                    <el-input
                                        v-model="item.appid"
                                        placeholder="请填写应用ID"
                                    ></el-input>
                                </el-form-item>
                                <template v-if="item.payPlatform == 'alipay'"> 
                                    <el-form-item label="公钥" prop="publicKey">
                                        <el-input
                                            v-model="item.publicKey"
                                            type="textarea"
                                            placeholder="请填写公钥"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="私钥" prop="privateKey">
                                        <el-input
                                            v-model="item.privateKey"
                                            type="textarea"
                                            placeholder="请填写私钥"
                                        ></el-input>
                                    </el-form-item>
                                </template>
                                <template v-else-if="item.payPlatform == 'wechat'">
                                    <el-form-item label="微信平台商户号" prop="wxMerchantId">
                                        <el-input
                                            v-model="item.wxMerchantId"
                                            placeholder="请填写微信平台商户号"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="微信api私钥路径" prop="privateKeyAttachmentId">
                                        <div
                                            class="image-icon"
                                            v-if="item.privateKeyCertName"
                                        >
                                            <div class="delete">
                                                {{ item.privateKeyCertName }}
                                                <i @click="deletePrivateKeyAttachmentId(item)" class="el-icon-delete"></i>
                                            </div>
                                        </div>
                                        <el-upload
                                            v-else
                                            class="upload-content"
                                            action=""
                                            :http-request="upload"
                                            :auto-upload="true"
                                            accept=".pem"
                                        >
                                            <div class="upload-btn" @click="uploadClick(item)">上传证书(.pem格式)</div>
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item label="商户证书序列号" prop="mchSerialNumber">
                                        <el-input
                                            v-model="item.mchSerialNumber"
                                            placeholder="请填写商户证书序列号"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="商户APIV3密钥" prop="apiV3Key">
                                        <el-input
                                            v-model="item.apiV3Key"
                                            placeholder="请填写商户APIV3密钥"
                                        ></el-input>
                                    </el-form-item>
                                </template>
                                <i @click="removePayType(item)" class="el-icon-remove"></i>
                            </template>
                        </div>
                    </el-form>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button
                    :loading="saveLoading"
                    type="primary"
                    @click="savePayConfig"
                >确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { 
    getPayMerchantList,
    genMerchantNum,
    genSign, 
    payMerchantEnabled,
    getPayConfigList,
    getPayConfigPayType,
    payConfigSave,
} from "@/api/operationCenter";
import request from "@/utils/request";
export default {
    data() {
        return {
            payMerchantList: [], // 商户列表
            merchantNum: "", // 商户号
            merchantId: "", // 商户ID
            title: "",
            visible: false,
            saveLoading: false,
            isSave: false,
            payPlatform: "", // 支付平台
            payConfigs: [], // 支付配置列表
            payTypeOptions: [], // 支付方式
            errorIndex: undefined,
        };
    },
    created() {
        getPayMerchantList().then(resp => {
            this.payMerchantList = resp?.data?.page?.list;
            this.merchantNum = this.payMerchantList?.[0]?.merchantNum;
            this.merchantId = this.payMerchantList?.[0]?.id;
        })
    },
    methods: {
        /**
         * 创建商户
         */
        createMerchant() {
            genMerchantNum().then(resp => {
                if(resp?.data?.payMerchant){
                    this.payMerchantList.push(resp.data.payMerchant || []);
                }
            })
        },
        /**
         * 重新生成商户号
         */
        reGenSign(item) {
            genSign({
                id: item.id
            }).then(resp => {
                this.$message.success("修改成功");
                this.$set(item, "sign", resp?.data?.sign || "");
            })
        },
        handleIconClick(key) {
            let el = document.getElementById(key)
            if(el.type == 'password') {
                el.type = 'type'
            } else {
                el.type = 'password'
            }
        },
        copyInfo(el) {
            this.$refs[el][0].select()
            if (document.execCommand('Copy')) {
                this.$message({type:"success",message:"复制到剪贴板"})
            }
        },
        /**
         * 商户开启/关闭
         */
        changeSwitch(item) {
            payMerchantEnabled({
                id: item.id
            }).then(resp => {
                this.$message.success("修改成功");
            })
        },
        /**
         * 编辑支付方式配置
         */
        async editPayType(type) {
            if(type == "alipay"){
                this.title = "支付宝商户配置";
            } else if (type == "wechat"){
                this.title = "微信商户配置";
            }
            this.payPlatform = type;
            let promiseArray = [];
            promiseArray.push(getPayConfigPayType({payPlatform: type}))
            let params = {
                merchantNum: this.merchantNum,
                payPlatform: type,
            }
            promiseArray.push(getPayConfigList(params))
            await Promise.all(promiseArray).then((resp) => {
                this.payTypeOptions = resp[0]?.data?.payType;
                this.payConfigs = resp[1]?.data?.page?.list;
            })
            this.visible = true;
        },
        cancel() {
            this.title = "";
            this.payConfigs = [];
            this.visible = false;
        },
        close() {
            this.cancel();
        },
        getPayTypes(){
            let payTypeOptions = this._.cloneDeep(this.payTypeOptions);
            let types = this.payConfigs.map(item => item.payType);
            if(types?.length) {
                types.forEach(item => {
                    payTypeOptions.forEach(item2 => {
                        if(item2.name === item) {
                            item2.disabled = true;
                        }
                    })
                })
            }
            return payTypeOptions;
        },
        /**
         * 添加支付方式
         */
        addPayType() {
            this.isSave = false;
            let payConfig = {
                merchantId: this.merchantId,
                payPlatform: this.payPlatform,
                payType: "", // 支付方式
                appid: "", // 应用id
            }
            if (this.payPlatform == "alipay"){
                payConfig.publicKey = "";
                payConfig.privateKey = "";
            } else if(this.payPlatform == "wechat") {
                payConfig.wxMerchantId = "";
                payConfig.privateKeyAttachmentId = "";
                payConfig.privateKeyCertName = "";
                payConfig.mchSerialNumber = "";
                payConfig.apiV3Key = "";
            }
            this.payConfigs.push(payConfig);
        },
        /**
         * 移除支付方式
         */
        removePayType(item) {
            this.$confirm("确定删除该支付方式？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$set(item, "deleted", true)
            }).catch(() => {});
        },
        uploadClick(item){
            this.item = item;
        },
        upload(uploadDetail) {
            let params = new FormData();
            params.append("attachmentFile", uploadDetail.file);
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            request({
                url: "/attachment/upload",
                method: "post",
                headers: config.headers,
                data: params,
            }).then((resp) => {
                if (resp.code === 0) {
                    this.$set(this.item, "privateKeyAttachmentId", resp?.data?.attachment?.id);
                    this.$set(this.item, "privateKeyCertName", resp?.data?.attachment?.originalName);
                } else {
                    this.$message({
                        message: "上传失败",
                        type: "error",
                    });
                }
            }).catch(() => {
                this.$message({
                    message: "上传出错，服务器开小差了呢",
                    type: "error",
                });
            });
        },
        deletePrivateKeyAttachmentId(item){
            this.$set(item, "privateKeyAttachmentId", "");
            this.$set(item, "privateKeyCertName", "");
        },
        checkPayConfig(){
            let payConfig = {
                payType: "支付方式",
                appid: " 应用ID",
            }
            if (this.payPlatform == "alipay"){
                payConfig.publicKey = "公钥";
                payConfig.privateKey = "私钥";
            } else if(this.payPlatform == "wechat") {
                payConfig.wxMerchantId = "微信平台商户号";
                payConfig.privateKeyAttachmentId = "微信api私钥路径";
                payConfig.mchSerialNumber = "商户证书序列号";
                payConfig.apiV3Key = "商户APIV3密钥";
            }
            let error = "";
            this.errorIndex = undefined;
            let payConfigs = this.payConfigs.filter(item => !item.deleted);
            for(let i = 0; i < payConfigs.length; i ++) {
                let el = payConfigs[i];
                for(let key in payConfig) {
                    if(this._.isEmpty(el[key])){
                        this.errorIndex = i;
                        error = `请配置【第${i+1}个】支付配置的${payConfig[key]}`;
                        break;
                    }
                }
                if(error){
                    break;
                }
            }
            return error;
        },
        /**
         * 保存支付方式配置
         */
        savePayConfig() {
            this.isSave = true
            let error = this.checkPayConfig();
            if(error) {
                this.$message.error(error);
                return
            }
            this.saveLoading = true;
            let payConfigs = this.payConfigs.filter(item => !(item.deleted && !item.id));
            payConfigSave({
                payConfigs,
            }).then(resp => {
                this.isSave = false;
                this.saveLoading = false;
                this.$message.success("修改成功");
                this.$set(this, "payConfigs", resp?.data?.payConfigs);
            }).catch(err => {
                this.isSave = false;
                this.saveLoading = false;
            })
        }
    },
    watch: {
        payConfigs: {
            immediate: true,
            deep: true,
            handler() {
                this.checkPayConfig()
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--font-text-primary, #172b4d);
}
.content {
    margin-top: 12px;
    padding: 16px;
    border-radius: 10px;
    background: #fff;
}
.description {
    font-size: 14px;
    line-height: 24px;
    color: #6b778c;
}
.form-content{
    width: 600px;
    ::v-deep .el-input{
        width: 300px;
    }
}

.pay-list{
    .pay-item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: var(--zgg-border-light) solid 1px;
        .name{
            width: 100px;
        }
    }
}

.payConfig{
    position: relative;
    &.is-border{
        border-radius: 10px;
        padding: 10px; 
        margin-bottom: 10px; 
        border: #ddd solid 1px; 
    }
    &.is-error{
        border: red dashed 1px;
    }
    .el-icon-remove{
        font-size: 20px;
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
        color: #F56C6C;
    }    
}

.upload-content {
  .upload-btn {
    border-radius: 5px;
    border: 1px dashed var(--zgg-border-base);
    background: var(--white);
    display: block;
    font-size: 13px;
    line-height: 28px;
    color: var(--zgg-font-text-regular);
    padding: 4px 0;
    text-align: center;
  }

  ::v-deep.el-upload {
    display: block;
  }
  ::v-deep.el-radio-button--mini .el-radio-button__inner {
    padding: 7px 24px;
  }
}

</style>
  