import request from '@/utils/request'
import qs from 'qs';


/**
 * 添加用户组
 * @param data
 * @returns {AxiosPromise}
 */
export function addGroup(data) {
  return request({
    url:  '/team/save',
    method: 'post',
    data:qs.stringify(data)
  })
}

/**
 * 获取联系人列表
 * @returns {AxiosPromise}
 */
export function groupList(data) {
  return request({
    url:  '/team/listPage',
    method: 'post',
    data:qs.stringify(data)
  })
}

/**
 * 删除联系人
 * @returns {AxiosPromise}
 */
export function deleteGroup(teamId) {
  return request({
    url:  '/team/delete/',
    method: 'get',
    params:{teamId}
  })
}
/**
 * 获取详情
 * @returns {AxiosPromise}
 */
export function teamDetail(teamId) {
  return request({
    url:  '/team/detail/',
    method: 'get',
    params:{teamId}
  })
}



