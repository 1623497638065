<template>
  <div style="display: flex; flex-direction: row; height: 100%">
    <div
      style="
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        transition-property: margin-left;
        transition-duration: 0.5s;
      "
      :style="[
        { background: treeViewVisible ? '#fff' : '#f4f5f7' },
        {
          width: menuWrapWidth + 'px',
          marginLeft: treeViewVisible
            ? wrapMarginLeft + 'px'
            : wrapMarginLeft + 0 + 'px',
        },
      ]"
    >
      <div
        v-if="treeViewVisible"
        class="drag-right"
        @mousedown="dragdown($event)"
        :class="onDraging ? 'onDraging' : ''"
      ></div>
      <i
        style="font-size: 20px"
        :style="treeViewVisible ? 'right: -20px;' : 'right: -26px;'"
        class="toggle-panel custom-icon"
        :class="
          treeViewVisible ? 'iconfont icon-zhedie' : 'iconfont icon-zhankai'
        "
        @click="toggleBoardVisible"
      ></i>

      <el-form
        v-show="show"
        :model="searchForm"
        size="small"
        style="width: 100%"
        class="demo-form-inline"
      >
        <el-form-item class="itemSearchForm">
          <el-input
            prefix-icon="el-icon-search"
            placeholder="搜索"
            v-model="searchForm.title"
            @input="search"
            style="width: 85%"
            class="rename-input"
          >
            <i
              v-if="searchForm.title"
              @click="clearTitle"
              slot="suffix"
              class="el-input__icon el-icon-circle-close"
              style="cursor: pointer"
            ></i>
          </el-input>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              width: 100%;
            "
          >
            <span style="font-size: 12px; color: #6b778c">所有表单</span>
            <el-dropdown>
              <i
                class="el-icon-plus add"
                style="
                  font-size: 14px;
                  cursor: pointer;
                  font-weight: 600;
                  padding: 5px;
                  border-radius: 5px;
                "
              ></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="add(0)"
                  >新增空白表单
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="addFromExcel()"
                  >从Excel创建表单
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="addFolder"
                  >新增文件夹
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-form-item>
      </el-form>

      <tree
        v-show="show"
        @nodeClick="nodeClick"
        @del="del"
        @edit="edit"
        @viewAddress="viewAddress"
        @copy="copy"
        @editExcel="editExcel"
        ref="tree"
      >
        <template slot="more" slot-scope="{ data, node }">
          <div
            @click.stop
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
              <el-tooltip
                effect="dark"
                content="新建"
                placement="top"
                :open-delay="500"
              >
                <i
                  class="el-icon-plus custom-icon"
                  style="cursor: pointer; font-weight: 700"
                ></i>
              </el-tooltip>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="addFolder(data, node)">
                  添加子文件夹
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="add(data.id)">
                  添加空白表单
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="addFromExcel(data.id)"
                  >从Excel创建表单
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
              <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="renameDir(data, node)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="delDir(data, node)">
                  删除
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native.stop="copyToOtherCompany(data, node)"
                >
                  复制到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="moveTo(data, node)">
                  移动到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="delDirForce(data, node)">
                  强制删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" v-if="data.dataType == 'resource'">
              <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="edit(data, node)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native.stop="referenceGraphDetail(data, node)"
                >
                  查看引用视图
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="moveTo(data, node)">
                  移动到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="copy(data, node)">
                  复制
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native.stop="copyToOtherCompany(data, node)"
                >
                  复制到
                </el-dropdown-item>
                <!--                <el-dropdown-item @click.native.stop="openCopyToDialog(data, node)">-->
                <!--                  复制到其他单位-->
                <!--                </el-dropdown-item>-->
                <el-dropdown-item @click.native.stop="editExcel(data, node)">
                  配置导出模板
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="viewAddress(data, node)">
                  配置地址
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="del(data, node)">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </tree>
    </div>
    <div style="flex: 1; height: 100%; overflow: auto">
      <!--      <el-dialog-->
      <!--          title="表单设计"-->
      <!--          :visible="dialogVisible"-->
      <!--          @close="close"-->
      <!--          fullscreen-->
      <!--      >-->
      <!--        <router-view v-if="!isRootRoute" :key="key"/>-->
      <!--      </el-dialog>-->
      <transition>
        <router-view v-if="!isRootRoute" :key="key" ref="formEdit" />
        <el-empty
          v-else
          description="从左侧选择或新建一张表单"
          image="/static/empty-img/form-empty.png"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        ></el-empty>
      </transition>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="visible"
      @closed="close"
      width="560px"
    >
      <div style="padding: 20px">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="表单名称" prop="title">
            <el-input
              v-model="form.title"
              @keyup.enter.native="saveForm('platform')"
              ref="renameInput"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          :loading="saveLoading"
          type="primary"
          @click="saveForm('platform')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="配置地址"
      :visible.sync="addrVisible"
      width="560px"
      append-to-body
    >
      <div style="padding: 20px">
        <div class="row">
          <span>数据列表地址：</span>
          <div class="input">/online/{{ formId }}/list</div>
          <!--          <el-link>查看<i class="el-icon-view" @click="$router.push(`/online/${{ formId }}/list`)"></i> </el-link>-->
        </div>
        <div class="row">
          <span>数据新增地址：</span>
          <div class="input">/online/{{ formId }}/add</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="移动"
      :visible.sync="folderSelectDialogVisible"
      width="520px"
      :before-close="handleBeforeCLose"
      class="folderSelectDialog"
    >
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 5px;
        "
      >
        <p>请选择目标文件夹：</p>
        <FolderSelect
          @change="handleFolderSelectChange"
          v-if="folderSelectDialogVisible"
          :type="this.type"
          :current-node="currenMoveNode"
        ></FolderSelect>
      </div>
    </el-dialog>
    <el-dialog title="复制到" :visible.sync="dialogFormVisible" append-to-body>
      <company-select-list
        v-if="dialogFormVisible"
        :hasCopyData="false"
        ref="companySelectList"
      ></company-select-list>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelCopy">取 消</el-button>
        <el-button type="primary" @click="copyData">确 定</el-button>
      </div>
    </el-dialog>
    <copyToDialog :api="api" ref="copyToDialog"></copyToDialog>

    <!-- 查看引用视图 -->
    <referenceView
      v-if="referenceDrawer"
      :drawer.sync="referenceDrawer"
      :form="form"
    ></referenceView>

    <!-- 删除表单 -->
    <el-dialog :visible.sync="delVisible" width="560px" append-to-body>
      <div
        slot="title"
        style="
          display: flex;
          align-items: center;
          font-size: 18px;
          padding-right: 20px;
        "
      >
        <i
          class="el-icon-warning"
          style="color: #e6a23c; font-size: 22px; margin-right: 6px"
        ></i
        >确定要删除"{{ form.title }}"吗?
      </div>
      <div style="padding: 20px">
        <div style="padding-bottom: 10px">
          此表单被引用，有
          <b style="color: #e6a23c; font-size: 18px">{{ referenceDataCount }}</b
          >条数据！删除表单将清空数据，请谨慎操作。
        </div>
        <div class="row">如确定删除，请输入表单名称：</div>
        <div class="row" style="line-height: 22px">
          <el-input v-model="formTitle"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-link type="primary" :underline="false" @click="openReferenceDetail"
          >查看引用详情</el-link
        >
        <div>
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="finalDel"
            :disabled="formTitle != form.title"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 查看引用详情 -->
    <referenceDetail
      v-if="referenceVisible"
      :visible.sync="referenceVisible"
      :formId="form.id"
      :referenceTitle="form.title"
    ></referenceDetail>

    <add-form-by-excel
      v-if="addFormByExcelVisible"
      :dirId="currentActionDirId"
      :dialogVisible.sync="addFormByExcelVisible"
      @importSuccess="importSuccess"
    ></add-form-by-excel>
  </div>
</template>
<script>
import {
  saveForm,
  deleteForm,
  formDuplicate,
  transToPlatform,
  duplicateFormToOtherCompany,
  referenceRemoveCheck,
  referenceQueryBrief,
  referenceQueryDetail,
  duplicateDirToTargetCompany,
} from "./api";
import Pagination from "@/components/Pagination";
import tree from "@/views/lowCode/form/tree";
import copyToDialog from "@/views/copyToDialog";
import {
  dirDelete,
  dirMove,
  dirRegister,
  dirRename,
} from "@/views/lowCode/api";
import FolderSelect from "@/views/lowCode/FolderSelect";
import _ from "lodash";
import { scaleWidth } from "@/mixin/scaleWidth";
import { getCookie, setCookie, removeCookie } from "@/utils/cookie-tools";
import CompanySelectList from "@/components/companySelectList.vue";
import referenceView from "./reference-view";
import referenceDetail from "./reference-detail";
import addFormByExcel from "@/views/lowCode/form/component/addFormByExcel.vue";

export default {
  components: {
    Pagination,
    tree,
    FolderSelect,
    copyToDialog,
    CompanySelectList,
    referenceView,
    referenceDetail,
    addFormByExcel,
  },
  mixins: [scaleWidth],
  data() {
    return {
      show: true,
      formId: "",
      saveLoading: false,
      title: "添加表单",
      visible: false,
      addrVisible: false,
      form: {},
      defaultDataShareRange: "",
      rules: {
        title: [
          {
            required: true,
            message: "表单名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      searchForm: {
        title: "",
      },
      type: 10,
      //当前操作的中间变量的目录id
      currentActionDirId: 0,
      folderSelectDialogVisible: false,
      defaultExpandedKeys: [],
      currenMoveNode: undefined,
      treeViewVisible: true,
      searchResultList: [],
      api: "",
      companyPage: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      dialogFormVisible: false,
      // menuWrapWidth:250,
      // onDraging:false
      referenceDrawer: false, // 引用图谱
      delVisible: false, // 删除
      referenceVisible: false, // 引用详情
      formTitle: "", // 表单名称
      referenceList: [], // 引用详情列表
      referenceItemIndex: null,
      referenceDataCount: 0,
      addFormByExcelVisible: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("beforeunload", this.beforeunloadFun);
    this.$nextTick(() => {
      this.$bus.$on("form-edit-save", (event, val, callback) => {
        if (event == "menu-link") {
          // menu菜单切换
          this.$refs.formEdit.saveForm(() => {
            this.$router.push(val);
          });
        } else if (event == "switch-company") {
          // 当前单位切换
          if (typeof callback === "function") {
            this.$refs.formEdit.saveForm(() => {
              callback(val);
            });
          }
        }
      });
      // 子表单转实体表需要更新表单目录树
      this.$bus.$on("resetFormTree", () => {
        this.$refs.tree.reset();
      });
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeunloadFun);
    this.$bus.$off("form-edit-save");
    this.$bus.$off("resetFormTree");
  },
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.$refs.tree.reset();
        this.back();
        this.searchForm.title = "";
      },
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isRootRoute() {
      return this.$route.path == "/lowCode/form";
    },
    defaultExpandedKeysUniq: function () {
      return this._.uniq(this.defaultExpandedKeys);
    },
    dialogVisible: {
      get() {
        return !this.isRootRoute;
      },
      set(val) {
        console.log(val);
      },
    },
  },
  methods: {
    // 查看引用视图
    referenceGraphDetail(row) {
      this.$set(this, "form", row);
      this.referenceDrawer = true;
    },
    importSuccess({ dir, form }) {
      this.$message.success("新增成功");
      dir.defaultDataShareRange = form.defaultDataShareRange;
      this.$refs.tree.insertToWorkGroupTree(dir);
      this.editForm(dir);
      this.$refs.tree.$refs.tree.setCurrentKey(dir.id);
    },
    addFromExcel(id) {
      this.currentActionDirId = id;
      this.addFormByExcelVisible = true;
    },
    copyToOtherCompany(row) {
      this.$confirm(
        "表单复制后会清除表单内的关联类型字段配置，请复制后重新设置！",
        "提示",
        {
          confirmButtonText: "继续复制",
          cancelButtonText: "取消",
          type: "warning",
        },
      )
        .then(() => {
          this.$set(this, "form", {
            title: row.title,
            id: row.bizId,
            dirId: row.id,
            dataType: row.dataType,
          });
          this.dialogFormVisible = true;
        })
        .catch(() => {
          this.$message.info("取消复制");
        });
    },
    //复制表单到其他单位
    copyData() {
      let params = {
        toCompanyId: this.$refs.companySelectList.checkedCompany,
      };
      let duplicateToTargetCompany;
      if (this.form.dataType == "folder") {
        params.dirId = this.form.dirId;
        duplicateToTargetCompany = duplicateDirToTargetCompany;
      } else if (this.form.dataType == "resource") {
        params.id = this.form.id;
        duplicateToTargetCompany = duplicateFormToOtherCompany;
      }
      duplicateToTargetCompany &&
        duplicateToTargetCompany(params).then(() => {
          this.$message.success("复制成功");
          this.dialogFormVisible = false;
        });
    },
    //取消复制表单到其他单位
    cancelCopy() {
      if (
        this.$refs.companySelectList &&
        this.$refs.companySelectList.checkedCompany
      ) {
        this.$refs.companySelectList.checkedCompany = "";
        this.$refs.companySelectList.page.pageNumber = 1;
      }
      this.dialogFormVisible = false;
    },
    beforeunloadFun(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "关闭提示";
      } else {
        return "关闭";
      }
    },
    clearTitle() {
      this.reset();
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      if (this.$route.query.id) {
        let { matched } = this.$router.history.current;
        if (matched.length >= 2) {
          let parentPath = matched[matched.length - 2].path;
          this.$router.push({ path: parentPath });
        } else {
          this.$router.back();
        }
      }
    },
    // shareRadioClick(label) {
    //   this.defaultDataShareRange == label
    //     ? (this.defaultDataShareRange = "")
    //     : (this.defaultDataShareRange = label);
    // },
    reset() {
      this.searchForm.title = "";
      this.$refs.tree.reset();
    },
    searchChange(e) {
      let data = {
        bizId: e,
      };
      this.editForm(data);
    },

    toggleBoardVisible() {
      this.treeViewVisible = !this.treeViewVisible;
      this.show = !this.show;
    },
    moveTo(data, node) {
      console.log(data);
      console.log("-------------------");
      console.log(node);
      this.currenMoveNode = node;
      this.folderSelectDialogVisible = true;
    },

    openCopyToDialog(row) {
      this.api = "duplicate";
      this.$refs.copyToDialog.openCopyToDialog(
        row,
        this.companyPage,
        this.searchForm,
      );
    },

    tansToPlatform(data, node) {
      this.$confirm(`确认将表单【${data.title}】转换为标准表单吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          transToPlatform({ formId: data.bizId }).then((res) => {
            this.$message.success("转换成功");
            this.$set(node.data, "defaultDataShareRange", "platform");
          });
        })
        .catch(() => {});
    },
    renameDir(data, node) {
      this.$prompt("请输入新文件夹名", "重命名文件夹", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.title,
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            dirId: data.id,
            title: value,
          };
          dirRename(param).then((resp) => {
            this.$set(node.data, "title", value);
            this.$message.success("保存成功");
          });
        })
        .catch(() => {});
    },
    handleBeforeCLose() {
      this.folderSelectDialogVisible = false;
    },
    /**
     * 处理移动到的相关逻辑
     * @param parentId
     */
    handleFolderSelectChange(parentId) {
      this.folderSelectDialogVisible = false;
      let { dataType, type, id, recordType, recordId } =
        this.currenMoveNode.data;
      let params = {
        id,
        parentId,
      };
      dirMove(params).then(async (resp) => {
        this.$refs.tree.$refs.tree.remove(this.currenMoveNode.data);
        this.currenMoveNode.data.parentId = parentId;
        this.$refs.tree.insertToWorkGroupTree(this.currenMoveNode.data);
        this.defaultExpandedKeys.push(parentId);
      });
    },
    delDir(data, node) {
      this.$confirm("删除文件夹?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.directoryList && data.directoryList.length != 0) {
            this.$message.warning("不能删除非空文件夹");
            return;
          }
          dirDelete({
            dirId: data.id,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delDirForce(data, node) {
      this.$confirm("强制删除目录，下面的全部目录和文件将被永久删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.id == 0) {
            //不允许误删除根目录
            return;
          }
          dirDelete({
            dirId: data.id,
            withSubs: true,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFolder(data, node) {
      console.log(data);
      console.log("---------------");
      console.log(node);
      let parentId;
      if (data && data.id) {
        parentId = data.id;
      } else {
        parentId = 0;
      }
      console.log(parentId);
      this.$prompt("", "新建", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.addFolderByParent(parentId, value);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFolderByParent(parentId = 0, title = "默认文件夹名") {
      let params = {
        parentId,
        title,
        type: this.type,
      };
      dirRegister(params).then((resp) => {
        console.log(resp);
        console.log(this.$refs);
        this.$refs.tree.update(parentId);
      });
    },
    nodeClick(data, node) {
      let toEdit = () => {
        let { dataType } = data;
        if (dataType == "resource") {
          this.editForm(data);
        }
      };
      let isUpdate = getCookie("form-edit-update");
      if (isUpdate) {
        this.$confirm(
          "您有尚未保存的更改，是否需要保存这些更改？",
          "是否保存已有更改？",
          {
            confirmButtonText: "保存",
            cancelButtonText: "不保存",
            type: "warning",
          },
        )
          .then(() => {
            this.$refs.formEdit.saveForm(() => {
              toEdit();
              removeCookie("form-edit-update");
            });
          })
          .catch(() => {
            removeCookie("form-edit-update");
            toEdit();
          });
      } else {
        toEdit();
      }
    },
    copy(row) {
      this.$prompt("请输入新表单名", "复制表单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.title + "-复制",
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            id: row.bizId,
            title: value,
          };
          formDuplicate(param).then((resp) => {
            this.searchForm.title = "";
            resp.data.dir.defaultDataShareRange =
              resp.data.form.defaultDataShareRange;
            this.$refs.tree.insertToWorkGroupTree(resp.data.dir);
          });
        })
        .catch(() => {});
    },

    // 搜索防抖
    search: _.debounce(function () {
      if (!this.searchForm.title) this.$refs.tree.reset();
      if (this.searchForm.title) {
        this.searchForm.title = this.searchForm.title.trim();
        this.$refs.tree.search(this.searchForm.title);
      }
    }, 500),
    // 删除表单
    async del(row) {
      this.formTitle = "";
      this.$set(this, "form", {
        title: row.title,
        id: row.bizId,
      });
      let params = {
        collection: row.bizId,
        type: "form",
      };
      // 删除表单前检查表单是否有被引用
      let checkResult = await referenceRemoveCheck(params);
      this.referenceDataCount = checkResult?.data.dataCount;
      if (checkResult?.data?.beReferred) {
        // 有引用关系
        this.curRow = row; // 当前操作表单数据
        this.delVisible = true;
      } else {
        this.$confirm(
          "确定要删除吗? 删除表单将清空数据，请谨慎操作。",
          "提示",
          {
            type: "warning",
          },
        )
          .then(() => {
            deleteForm({ ids: JSON.stringify([row.bizId]) }).then((res) => {
              this.$message.success("删除成功");
              this.$set(this, "form", {});
              this.$refs.tree.$refs.tree.remove(row);
            });
          })
          .catch((err) => {});
      }
    },
    // 存在引用关系时的删除
    finalDel() {
      let params = {
        ids: JSON.stringify([this.curRow.bizId]),
      };
      deleteForm(params)
        .then((res) => {
          this.$message.success("删除成功");
          this.$set(this, "form", {});
          this.delVisible = false;
          this.$refs.tree.$refs.tree.remove(this.curRow);
        })
        .catch((err) => {
          this.delVisible = false;
        });
    },
    // 查看引用详情
    openReferenceDetail() {
      this.referenceVisible = true;
    },
    editExcel(row) {
      this.$router.push({
        path: "/lowCode/form/templateSetting",
        query: { id: row.bizId },
      });
    },
    edit(row) {
      this.$set(this, "form", {
        title: row.title,
        id: row.bizId,
      });
      this.title = "重命名表单";
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.renameInput.focus();
      }, 0);
    },
    editForm(row) {
      this.$router.push({
        path: "/lowCode/form/edit",
        query: { id: row.bizId },
      });
    },
    viewAddress(row) {
      this.formId = row.bizId;
      this.addrVisible = true;
    },
    cancel() {
      this.visible = false;
    },
    close() {
      this.$set(this, "form", {});
      this.$set(this, "defaultDataShareRange", "");
    },
    //添加表单到根目录
    add(dirId) {
      this.title = "添加表单";
      //   this.form = {};
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.currentActionDirId = dirId;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.renameInput.focus();
      }, 0);
    },
    saveForm(label) {
      this.defaultDataShareRange == label
        ? (this.defaultDataShareRange = "")
        : (this.defaultDataShareRange = label);
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.saveLoading = true;
        saveForm({
          form: JSON.stringify(this.form),
          dirId: this.currentActionDirId,
          defaultDataShareRange: this.defaultDataShareRange,
        })
          .then((res) => {
            this.saveLoading = false;
            this.visible = false;
            if (this.form.id) {
              this.$message.success("保存成功");
              this.$refs.tree.$refs.tree.getNode(res.data.dir.id).data.title =
                this.form.title;
            } else {
              this.$message.success("新增成功");
              res.data.dir.defaultDataShareRange =
                res.data.form.defaultDataShareRange;
              this.$refs.tree.insertToWorkGroupTree(res.data.dir);
              this.editForm(res.data.dir);
              this.$refs.tree.$refs.tree.setCurrentKey(res.data.dir.id);
            }
          })
          .catch(() => {
            this.saveLoading = false;
          });
        // console.log(this.form);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
  ::v-deep {
    &.el-form-item {
      margin-bottom: 0;
    }
  }
}
.toggle-panel {
  position: absolute;
  z-index: 999;
  right: -20px;
  top: 10px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--zgg-border-light);
  border-radius: 50%;
  background-color: white;
  color: #6b77bc;
}
.custom-icon::after {
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input {
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 5px 20px;
}

::v-deep .el-scrollbar__wrap::-webkit-scrollbar {
  display: none !important;
}

::v-deep .el-form-item__content .el-input__inner {
  border-radius: 4px;
  background-color: #f4f5f7;
}

.add:hover {
  background-color: rgb(225, 237, 248);
  color: #3490e6;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 10px;
}

::v-deep .el-dialog__footer {
  margin-top: 10px;
  border-top: 1px solid #d6d6d6;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.itemSearchForm {
  padding: 10px 0 0 10px;

  ::v-deep .el-input__inner {
    border-radius: 10px;
    background-color: #f4f5f7;
    border: none;
  }
}

::v-deep .el-dropdown {
  right: 10px;
}

.drag-right {
  width: 8px;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 999;

  &:hover {
    border-right: solid 2px rgba(24, 144, 255, 0.6);
  }
  &.onDraging {
    border-right: solid 4px rgba(24, 144, 255, 0.6);
  }
}

::v-deep .el-dialog .el-input__inner {
  background-color: #fff;
}

.reference-header {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  .form-title {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .refresh {
    cursor: pointer;
  }
}

.reference-list {
  overflow-y: auto;
  border: 1px solid #ebecee;
  border-radius: 3px;
  max-height: 420px;
  .reference-item {
    .item-header {
      align-items: center;
      background-color: #f5f6f8;
      border-top: 1px solid #ebecee;
      display: flex;
      height: 36px;
      padding: 0 10px;
      justify-content: space-between;
      overflow: hidden;
      .item-name {
        flex: 1;
        line-height: 36px;
        cursor: pointer;
      }
    }
    &:first-child {
      .item-header {
        border-top: none;
      }
    }
  }
  .reference-details-list {
    .reference-details-item {
      height: 32px;
      line-height: 32px;
      margin-left: 32px;
      max-width: 435px;
    }
  }
}

::v-deep .upload-demo {
  .el-upload-dragger {
    width: 610px;
  }
}
.upload-tip {
  padding: 10px 0;
  font-size: 14px;
  color: #606266;
  margin-top: 10px;
  line-height: 22px;
}
</style>
