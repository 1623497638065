<template>
  <div>
    <div @click="click">
      <slot name="reference"></slot>
    </div>

    <el-dialog
      @open="open"
      :title="title"
      :visible.sync="visible"
      width="600px"
      custom-class="dialog-field"
      :append-to-body="true"
    >
      <div style="padding: 10px 30px; max-height: 400px; overflow: auto">
        <div v-if="componentList.length == 0">没有可用字段</div>
        <div v-for="item in componentList" :key="item.key">
          <div class="form-item" @click="chkItem(item)">
            <div class="item-title" v-text="item.title"></div>
            <el-checkbox
              v-if="
                !(
                  componentName == 'summary_data' &&
                  item.componentName == 'json_form'
                )
              "
              :value="isChk(item)"
              :indeterminate="isIndeter(item)"
              @change="changeChk($event, item)"
              :disabled="item.disabled"
            ></el-checkbox>
          </div>

          <template
            v-if="
              (includeJsonForm && item.componentName == 'json_form') ||
              item.componentName == 'reference_data'
            "
          >
            <div
              class="form-item"
              style="padding-left: 22px"
              v-for="sub in item.components"
              :key="sub.key"
            >
              <div
                @click="chkItem(item, sub)"
                class="item-title"
                v-text="sub.title"
              ></div>
              <el-checkbox
                :value="isChk(item, sub)"
                :disabled="sub.disabled"
                @change="changeChk($event, item, sub)"
              ></el-checkbox>
            </div>
          </template>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="visible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
export default {
  directives: { Clickoutside },
  props: {
    value: {
      type: Array,
      Object,
      default() {
        return [];
      },
    },
    componentName: String,
    title: String,
    columns: Array,
    includeJsonForm: Boolean, // 是否包含子表单组件
    //是否是多选字段
    isMultiSelect: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      visible: false,
      chkList: [],
    };
  },
  computed: {
    componentList() {
      let list = [];
      this.columns.forEach((item) => {
        let obj = this._.cloneDeep(item);
        if (
          (this.componentName == "form_data" &&
            item.componentName == "reference_data") ||
          (this.componentName == "reference_data" &&
            item.componentName == "reference_data") ||
          (this.componentName == "json_form" &&
            item.componentName == "reference_data")
        ) {
          let components = [];
          item.columns.forEach((col) => {
            if (col.field.component.componentName != "json_form") {
              components.push({
                ...col.field.component,
                title: col.title,
              });
            }
          });
          obj.components = components;
          list.push(obj);
        } else if (item.componentName != "reference_data") {
          list.push(obj);
        }
      });
      if (this.includeJsonForm) {
        return list;
      }
      return list.filter((item) => item.componentName != "json_form");
    },
  },

  methods: {
    open() {
      this.chkList = this._.cloneDeep(this.value);
    },
    save() {
      this.$emit("input", this._.cloneDeep(this.chkList));
      this.visible = false;
    },
    isIndeter(row) {
      if(this.componentName === "json_form"){ // 子表单转实体表时的设置表单字段
        if (!["reference_data"].includes(row.componentName)) {
          return false;
        }
        let parent = this.chkList.find((item) => item.name == row.name);
        if (parent && parent.columns) {
          let length = row.components.filter(
            (item) =>
              parent.columns.findIndex((col) => col.field.name == item.name) >=
              0
          ).length;
          return length && length < row.components.length;
        }
        return false;
      } else {
        if (!["json_form", "reference_data"].includes(row.componentName)) {
          return false;
        }
        let parent = this.chkList.find((item) => item.field.name == row.name);
        if (parent && parent.subColumns) {
          let length = row.components.filter(
            (item) =>
              parent.subColumns.findIndex((col) => col.field.name == item.name) >=
              0
          ).length;
          return length && length < row.components.length;
        }
        return false;
      }  
    },
    isChk(col, child) {
      if(this.componentName === "json_form"){ // 子表单转实体表时的设置表单字段
        let r = false;
        if (!child) {
          r = this.chkList.findIndex((item) => item.name == col.name) >= 0;
        } else {
          let parent = this.chkList.find((item) => item.name == col.name);
          if (parent && parent.columns) {
            r =
              parent.columns.findIndex(
                (item) => item.field.name == child.name
              ) >= 0;
          }
        }
        return r
      } else {
        let r = false;
  
        if (!child) {
          if (["json_form", "reference_data"].includes(col.componentName)) {
            let obj = this.chkList.find((item) => item.field.name == col.name);
  
            if (obj && obj.subColumns) {
              r = obj.subColumns.length == col.components.length;
            }
          } else {
            r =
              this.chkList.findIndex((item) => item.field.name == col.name) >= 0;
          }
        } else {
          let parent = this.chkList.find((item) => item.field.name == col.name);
          if (parent && parent.subColumns) {
            r =
              parent.subColumns.findIndex(
                (item) => item.field.name == child.name
              ) >= 0;
          }
        }
  
        return r;
      }
    },
    click() {
      this.visible = true;
    },
    chkItem(col, child) {
      if (col.disabled) {
        return;
      }
      //如果是单选模式，清空已选todo 此处需要处理子表单的情况
      if (!this.isMultiSelect && this.chkList.length > 0) {
        this.chkList = [];
      }
      if (!child) {
        if(this.componentName === "json_form"){ // 子表单转实体表时的设置表单字段
          let index = this.chkList.findIndex(
            (item) => item.name == col.name
          );
          if (index >= 0) {
            // 存在，删除
            if (["reference_data"].includes(col.componentName)) {
              if (
                col.components.length == this.chkList[index].columns.length
              ) {
                // 关联数据全在，删除
                this.chkList.splice(index, 1);
              } else {
                // 关联数据部分，全选
                let components = this._.cloneDeep(col.components);
                components
                .filter(
                  (item) =>
                    this.chkList[index].columns.findIndex(
                      (sub) => sub.field.name == item.name
                    ) == -1
                )
                .forEach((item) => {
                  this.chkList[index].columns.push({
                    field: { ...item, component: item },
                    title: item.title,
                    align: "left",
                  });
                });
              }
            } else {
              this.chkList.splice(index, 1);
            }
          } else {
            // 不存在，添加
            this.chkList.push(col)
          }
        } else {
          let index = this.chkList.findIndex(
            (item) => item.field.name == col.name
          );
          if (index >= 0) {
            // 存在，删除
            if (["json_form", "reference_data"].includes(col.componentName)) {
              if (
                col.components.length == this.chkList[index].subColumns.length
              ) {
                // 子表单全在，删除
                this.chkList.splice(index, 1);
              } else {
                // 子表单部分，全选
  
                this.chkList[index].showAll = true;
                let components = this._.cloneDeep(col.components);
                // let arr = [];
                components
                  .filter(
                    (item) =>
                      this.chkList[index].subColumns.findIndex(
                        (sub) => sub.field.name == item.name
                      ) == -1
                  )
                  .forEach((item) => {
                    this.chkList[index].subColumns.push({
                      field: { ...item, component: item },
                      title: item.title,
                      align: "left",
                    });
                  });
                // this.chkList[index].subColumns = arr;
              }
            } else {
              this.chkList.splice(index, 1);
            }
          } else {
            // 不存在，添加
            let field = this._.cloneDeep(col);
            let obj = {
              field: { ...field, component: field },
              title: field.title,
              align: "left",
              showAll: true,
            };
            if (["json_form", "reference_data"].includes(field.componentName)) {
              obj.subColumns = [];
              let components = this._.cloneDeep(col.components);
              components.forEach((item) => {
                obj.subColumns.push({
                  field: { ...item, component: item },
                  title: item.title,
                  align: "left",
                });
              });
            }
            this.chkList.push(obj);
          }
        }
        return;
      }

      if(this.componentName === "json_form"){ // 子表单转实体表时的设置表单字段
        let obj = this.chkList.find((item) => item.name == col.name);
        if (!obj) {
          let field = this._.cloneDeep(col);
          obj = field
          obj.columns = []
          this.chkList.push(obj);
        }
        let index = obj.columns.findIndex(
          (item) => item.field.name == child.name
        );
        if (index >= 0) {
          // 存在，删除
          obj.columns.splice(index, 1);
          if (obj.columns.length == 0) {
            // 关联数据里的子组件全删除了，则删除关联数据
            let pIndex = this.chkList.findIndex(
              (item) => item.name == col.name
            );
            if (pIndex >= 0) {
              this.chkList.splice(pIndex, 1);
            }
          }
        } else {
          // 不存在，添加
          let field = this._.cloneDeep(child);
          obj.columns.push({
            title: field.title,
            align: "left",
            field: { ...field, component: field },
          });
        }
      } else {
        // 子表单
        let obj = this.chkList.find((item) => item.field.name == col.name);
        if (!obj) {
          // 子表单不存在
          let field = this._.cloneDeep(col);
          obj = {
            field: { ...field, component: field },
            title: field.title,
            align: "left",
            subColumns: [],
            showAll: true,
          };
          this.chkList.push(obj);
        }
        let index = obj.subColumns.findIndex(
          (item) => item.field.name == child.name
        );
        if (index >= 0) {
          // 存在，删除
          obj.subColumns.splice(index, 1);
          if (obj.subColumns.length == 0) {
            // 子表单里的子组件全删除了，则删除子表单
            let pIndex = this.chkList.findIndex(
              (item) => item.field.name == col.name
            );
            if (pIndex >= 0) {
              this.chkList.splice(pIndex, 1);
            }
          }
        } else {
          // 不存在，添加
          let field = this._.cloneDeep(child);
  
          obj.subColumns.push({
            title: field.title,
            align: "left",
            field: { ...field, component: field },
          });
        }
      }
    },
    changeChk(checked, col, child) {
      this.chkItem(col, child);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #409eff, $alpha: 0.3);
  }
  .item-title {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    padding-right: 10px;
  }
}
</style>
<style lang="scss">
.dialog-field {
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-dialog__header {
    padding-top: 10px;
  }
  .el-dialog__footer {
    padding-bottom: 10px;
  }
}
</style>
