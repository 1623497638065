<template>
  <div v-if="!parentField" class="attr-row">
    <div class="attr-label">字段宽度</div>
    <el-radio-group v-model="component.extParam.customWidth" size="mini">
      <el-radio-button label="1/4">1/4</el-radio-button>
      <el-radio-button label="1/3">1/3</el-radio-button>
      <el-radio-button label="1/2">1/2</el-radio-button>
      <el-radio-button label="2/3">2/3</el-radio-button>
      <el-radio-button label="3/4">3/4</el-radio-button>
      <el-radio-button label="1/1">整行</el-radio-button>
    </el-radio-group>
    <div v-if="displayBlock" style="margin-top: 10px">
      <el-checkbox v-model="component.displayBlock"
        ><span style="font-size: 12px">是否独占一行</span></el-checkbox
      >
    </div>
  </div>
</template>
<script>
import { getFormComponents } from "@zgg-core-utils/utils";
import { componentProps } from "./util";

export default {
  name: "attr-field-width",
  inject: ["getComponents"],
  props: componentProps,
  computed: {
    displayBlock() {
      if (this.parentField) {
        return false;
      }
      return ![
        "json_form",
        "html_input",
        "grid",
        "reference_data_list",
        "html",
      ].includes(this.component.componentName);
    },
    parentField() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      return components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key,
            ) >= 0,
        );
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 12px;
  }
}
</style>
