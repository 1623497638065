<template>
  <div class="attr-row">
    <div class="attr-label">流水号规则</div>
    <snInputSetting :form="component"></snInputSetting>
  </div>
</template>
<script>
import snInputSetting from "../component/snInputSetting";
import { componentProps } from "./util";

export default {
  name: "attr-sn-rule",
  components: { snInputSetting },
  props: componentProps,
};
</script>