<template>
  <div class="container">
    <source-list
      v-if="view"
      :formId="view.dataSource.formId"
      :onMove="onMove"
      activeName="form"
      use-type="gantt"
      @changeSource="changeSource"
      @initData="getFormList"
      @onStart="onStart"
      @onEnd="onEnd"
    ></source-list>
    <div class="content">
      <div class="header">
        <div style="padding: 0 10px; margin-top: auto; margin-bottom: auto">
          <el-form
            :rules="rules"
            ref="form"
            :model="view.extParam"
            label-width="80px"
          >
            <el-form-item
              label="图表名称"
              :show-message="false"
              style="margin-bottom: 0px"
              prop="title"
            >
              <el-input
                v-model="view.extParam.title"
                placeholder="请输入图表名称"
                size="small"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex-end">
          <el-button
            type="primary"
            @click="saveForm('preview')"
            class="mr"
            size="mini"
            >预览</el-button
          >
          <el-button @click="saveForm" type="primary" size="mini" class="mr"
            >保存
          </el-button>
          <el-button type="primary" size="mini" class="mr" @click="close"
            >关闭
          </el-button>
        </div>
      </div>
      <div class="weui-row field-row" :class="xFieldsAble ? 'drag-able' : ''">
        <div class="field-title">维度</div>
        <div class="weui-item">
          <draggable
            v-if="view"
            class="col-container"
            :list="view.xFields"
            :group="colGroup2"
            name="xFields"
            animation="300"
            @add="addXFields"
            @sort="sortXFields"
          >
            <el-tooltip
              v-for="(item, index) in view.xFields"
              :key="index"
              effect="dark"
              :content="item.title"
              placement="top"
            >
              <el-popover
                popper-class="outline-pop"
                placement="bottom-start"
                width="150"
                v-model="item.visible"
              >
                <div class="pop-container">
                  <div @click="editTitle(item)" class="pop-item">显示名</div>
                  <el-popover
                    popper-class="outline-pop"
                    placement="right-start"
                    width="150"
                    v-model="item.sortVisible"
                    trigger="hover"
                  >
                    <div class="pop-container">
                      <div @click="sortCol(item)" class="pop-item">
                        默认<i v-if="!item.orderType" class="el-icon-check"></i>
                      </div>
                      <div @click="sortCol(item, 'asc')" class="pop-item">
                        升序<i
                          v-if="item.orderType == 'asc'"
                          class="el-icon-check"
                        ></i>
                      </div>
                      <div @click="sortCol(item, 'desc')" class="pop-item">
                        降序<i
                          v-if="item.orderType == 'desc'"
                          class="el-icon-check"
                        ></i>
                      </div>
                    </div>
                    <div class="pop-item" slot="reference">
                      <span>排序</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </el-popover>
                </div>
                <el-tag
                  slot="reference"
                  class="m"
                  closable
                  @close="closeXFields(index)"
                  :type="isDelete(item.field.name) ? 'danger' : ''"
                  >{{ item.title }}
                </el-tag>
              </el-popover>
            </el-tooltip>
          </draggable>
        </div>
      </div>
      <template v-if="view && view.timeProgresses">
        <div
          v-for="(row, index) in view.timeProgresses"
          :key="index"
          class="weui-flex weui-row"
          :class="{ 'is-error': row.isDiffParent }"
        >
          <div
            class="weui-item field-row n-border"
            :class="{ 'is-empty': row.isEmpty && !row.startTimeField }"
          >
            <div class="field-title">
              <span class="required">*</span>开始时间
            </div>
            <div class="weui-item">
              <el-select
                :value="row.startTimeField && row.startTimeField.name"
                clearable
                size="small"
                @change="changeStartTime(row, $event)"
              >
                <el-option
                  v-for="item in formList.filter(
                    (item) => item.field.componentName == 'date_picker',
                  )"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div
            class="weui-item field-row n-border"
            :class="{ 'is-empty': row.isEmpty && !row.endTimeField }"
            style="margin: 0 10px"
          >
            <div class="field-title">
              <span class="required">*</span>结束时间
            </div>
            <div class="weui-item">
              <el-select
                :value="row.endTimeField && row.endTimeField.name"
                clearable
                size="small"
                @change="changeEndTime(row, $event)"
              >
                <el-option
                  v-for="item in formList.filter(
                    (item) => item.field.componentName == 'date_picker',
                  )"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="weui-item field-row n-border" style="margin-right: 10px">
            <div class="field-title">进度</div>
            <div class="weui-item">
              <el-select
                :value="row.progressField && row.progressField.name"
                clearable
                size="small"
                @change="changeProgress(row, $event)"
              >
                <el-option
                  v-for="item in formList.filter(
                    (item) => item.field.componentName == 'input_number',
                  )"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="weui-item field-row n-border">
            <div class="field-title">数据标签</div>
            <div class="weui-item">
              <el-select
                v-model="row.chartLabelFieldName"
                clearable
                size="small"
                @change="changeChartLabel(row, $event)"
              >
                <el-option
                  v-for="item in formList"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </template>

      <div class="weui-row field-row" :class="filterEnable ? 'drag-able' : ''">
        <div class="field-title">过滤条件</div>
        <div class="weui-item">
          <draggable
            v-if="view"
            class="col-container"
            :list="view.dataSource.queryObject.advanceQuery"
            :group="colGroup2"
            name="advanceQuery"
            animation="300"
            @add="addQuery"
          >
            <el-tooltip
              v-for="(item, index) in view.dataSource.queryObject.advanceQuery"
              :key="index"
              effect="dark"
              :content="item.title"
              placement="top"
            >
              <el-tag
                class="m"
                @click="showFilter(item, index)"
                closable
                @close="closeQuery(index)"
                :type="
                  isDelete(item.key)
                    ? 'danger'
                    : !['nil', 'nnil'].includes(item.oper) &&
                      isEmpty(item.value)
                    ? 'warning'
                    : ''
                "
                >{{ item.title }}
              </el-tag>
            </el-tooltip>
          </draggable>
        </div>
      </div>
      <div v-if="view" class="weui-row field-row n-border">
        <!-- 循环周期 -->
        <div class="field-title">缩放等级</div>
        <el-select v-model="view.zoomLevel" size="small" placeholder="">
          <el-option label="天" value="week"> </el-option>
          <el-option label="月" value="year"> </el-option>
        </el-select>
      </div>
      <div class="flex">
        <div class="empty-error" v-if="diffXField">
          子表单的字段无法作为维度
        </div>
        <div class="empty-error" v-else-if="diffJson">
          [开始时间]、[结束时间]、[进度]以及[数据标签]中子表单字段必须同属于一个子表单
        </div>

        <div v-else>
          <!-- 可预览 -->
        </div>
      </div>
    </div>

    <el-dialog
      title="设置过滤条件"
      :visible.sync="dialogVisible"
      width="600"
      append-to-body
    >
      <div class="weui-flex" style="padding: 20px" v-if="currFilterField">
        <el-input
          :value="currFilterField.title"
          readonly
          size="small"
          style="width: 200px"
        ></el-input>
        <!-- 操作符start -->
        <el-select
          v-model="currFilterField.oper"
          placeholder="操作符"
          size="small"
          style="margin-left: 10px; width: 140px; margin-right: 10px"
          @change="changeOper(currFilterField)"
        >
          <el-option
            v-for="opt in getDisplayQueryOpers(currFilterField.componentName)"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>
        <!-- 操作符end -->
        <div
          v-if="
            !(currFilterField.oper == 'nil' || currFilterField.oper == 'nnil')
          "
          class="flex"
        >
          <el-cascader
            v-if="currFilterField.componentName == 'address_input'"
            :options="areaList(currFilterField)"
            style="width: 100%"
            placeholder="请选择地址"
            :props="areaTreeProp"
            :value="getAddr(currFilterField.value)"
            @change="changeAddr(currFilterField, $event)"
            :key="currFilterField.oper"
            size="small"
          >
          </el-cascader>
          <el-date-picker
            v-else-if="currFilterField.componentName == 'date_picker'"
            v-model="currFilterField.value"
            placeholder="请选择日期"
            :type="getDatePicker(currFilterField)"
            size="small"
            style="width: 100%"
          ></el-date-picker>
          <template v-else>
            <div
              class="tag-container"
              v-if="
                !(
                  currFilterField.oper != 'in' &&
                  currFilterField.oper != 'nin' &&
                  currFilterField.oper != 'containAny' &&
                  currFilterField.oper != 'contain'
                ) || currFilterField.componentName == 'checkbox_group'
              "
            >
              <div class="tag-scroll" ref="tagScroll" @mousedown="mousedown">
                <template v-if="currFilterField.value">
                  <el-tag
                    type="success"
                    closable
                    v-for="(tag, index2) in currFilterField.value"
                    :key="index2"
                    size="mini"
                    @close="closeTag(currFilterField.value, index2)"
                    >{{ tag }}</el-tag
                  >
                </template>
              </div>

              <el-button
                type="text"
                @click="addModal(currFilterField)"
                size="mini"
                icon="el-icon-plus"
                style="margin-left: 5px"
                >添加筛选值</el-button
              >
            </div>
            <el-input
              v-else-if="currFilterField.componentName != ''"
              v-model="currFilterField.value"
              class="flex"
              placeholder="自定义值"
              size="small"
            ></el-input>
          </template>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveQueryValue">确 定</el-button>
      </div>
    </el-dialog>
    <gantt-preview v-model="preview" :gantt="view"></gantt-preview>
  </div>
</template>
<script>
import SourceList from "@/views/lowCode/view/components/Source";
import Draggable from "vuedraggable";
import ganttPreview from "@/views/lowCode/view/components/ganttPreview";
import test from "@/utils/test";
import { isEmpty } from "@zgg-core-utils/relyUtil";
import { getComponentQueryOper } from "@zgg-core-utils/whiteList";
const xFieldsComponents = [
  "input",
  "input_number",
  "date_picker",
  "radio_group",
  "select",
  "user_select",
  "department_select",
];
export default {
  components: { SourceList, Draggable, ganttPreview },
  props: {
    gantt: Object,
    areaTree: Array,
    closeFun: Function,
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "请输入图表名称" }],
      },
      loadingForm: false,
      preview: false,
      dialogVisible: false,
      areaTreeProp: {
        label: "name",
        value: "id",
        leaf: "hasChild",
        checkStrictly: true,
      },
      currFilterField: null,

      attTab: "column",
      timeProgresses: [],
      xFieldsAble: false,
      filterEnable: false,
      view: null,
      formId: 0,
      formList: [],
      colGroup2: {
        name: "column",
        pull: false,
      },
    };
  },
  computed: {
    startTimes: {
      get() {
        if (this.view.startTimeField) {
          return [this.view.startTimeField];
        }
        return [];
      },
      set(val) {
        if (val.length) {
          let obj = this._.cloneDeep(val[0]);
          this.$set(this.view, "startTimeField", {
            key: obj.key,
            name: obj.name,
            title: obj.title,
            fieldType: obj.fieldType,
            componentName: obj.field.componentName,
            component: this._.cloneDeep(obj),
          });
        } else {
          this.view.startTimeField = null;
        }
      },
    },
    endTimes: {
      get() {
        if (this.view.endTimeField) {
          return [this.view.endTimeField];
        }
        return [];
      },
      set(val) {
        if (val.length) {
          let obj = this._.cloneDeep(val[0]);
          this.$set(this.view, "endTimeField", {
            key: obj.key,
            name: obj.name,
            title: obj.title,
            fieldType: obj.fieldType,
            componentName: obj.field.componentName,
            component: this._.cloneDeep(obj),
          });
        } else {
          this.view.endTimeField = null;
        }
      },
    },
    progressField: {
      get() {
        if (this.view.progressField) {
          return [this.view.progressField];
        }
        return [];
      },
      set(val) {
        if (val.length) {
          let obj = this._.cloneDeep(val[0]);
          this.$set(this.view, "progressField", {
            key: obj.key,
            name: obj.name,
            title: obj.title,
            fieldType: obj.fieldType,
            componentName: obj.field.componentName,
            component: this._.cloneDeep(obj),
          });
        } else {
          this.view.progressField = null;
        }
      },
    },
    diffXField() {
      if (!(this.view && this.view.xFields)) {
        return false;
      }
      let list = [];
      this.view.xFields
        .filter((item) => item.field.name.indexOf(".") > 0)
        .forEach((item) => {
          let parentName = item.field.name.split(".")[0];
          if (!list.includes(parentName)) {
            list.push(parentName);
          }
        });
      return list.length > 0;
    },
    diffJson() {
      if (!(this.view && this.view.xFields)) {
        return false;
      }
      return (
        this.view.timeProgresses.findIndex((item) => item.isDiffParent) >= 0
      );
    },
  },
  created() {
    let view = this._.cloneDeep(this._.cloneDeep(this.gantt));
    if (!view.dataSource.queryObject) {
      view.dataSource.queryObject = {
        advanceQuery: [],
        limit: 0,
        limitStart: 0,
        pageNumber: 0,
        pageSize: 0,
        queryParam: {},
        sortParam: {},
        sortString: "",
      };
    }
    if (!view.dataSource.queryObject.advanceQuery) {
      view.dataSource.queryObject.advanceQuery = [];
    }
    if (view.timeProgresses.length == 0) {
      view.timeProgresses = [
        {
          startTimeField: null,
          endTimeField: null,
          progressField: null,
          chartLabelFieldName: "",
        },
        {
          startTimeField: null,
          endTimeField: null,
          progressField: null,
          chartLabelFieldName: "",
        },
      ];
    } else if (view.timeProgresses.length == 1) {
      view.timeProgresses.push({
        startTimeField: null,
        endTimeField: null,
        progressField: null,
        chartLabelFieldName: "",
      });
    }
    this.view = view;
  },
  methods: {
    isEmpty,
    changeChartLabel(row, value) {
      let list = [];
      if (row.endTimeField) {
        let pName = "";
        if (row.endTimeField.name.indexOf(".") >= 0) {
          pName = row.endTimeField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (row.startTimeField) {
        let pName = "";
        if (row.startTimeField.name.indexOf(".") >= 0) {
          pName = row.startTimeField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (row.progressField) {
        let pName = "";
        if (row.progressField.name.indexOf(".") >= 0) {
          pName = row.progressField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }
      if (value && value.indexOf(".") >= 0) {
        let pName = value.split(".")[0];
        if (!list.includes(pName)) {
          list.push(pName);
        }
      }
      let isDiffParent = list.length > 1;
      let isEmpty =
        (!row.startTimeField || !row.endTimeField) &&
        (row.startTimeField ||
          row.endTimeField ||
          row.progressField ||
          row.chartLabelFieldName);

      this.$set(row, "isDiffParent", isDiffParent);
      this.$set(row, "isEmpty", isEmpty);
    },
    changeStartTime(row, value) {
      let list = [];
      if (row.endTimeField) {
        let pName = "";
        if (row.endTimeField.name.indexOf(".") >= 0) {
          pName = row.endTimeField.name.split(".")[0];
        }
        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (row.progressField) {
        let pName = "";
        if (row.progressField.name.indexOf(".") >= 0) {
          pName = row.progressField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (value) {
        let obj = this._.cloneDeep(
          this.formList.find((item) => item.name == value),
        );
        let pName = "";
        if (obj.name.indexOf(".") >= 0) {
          pName = obj.name.split(".")[0];
        }
        if (!list.includes(pName)) {
          list.push(pName);
        }

        this.$set(row, "startTimeField", {
          key: this.getUuid(),
          name: obj.name,
          title: obj.title,
          fieldType: obj.fieldType,
          componentName: obj.field.componentName,
          component: this._.cloneDeep(obj.field),
        });
      } else {
        this.$set(row, "startTimeField", null);
      }
      let isDiffParent = list.length > 1;
      let isEmpty =
        (!row.startTimeField || !row.endTimeField) &&
        (row.startTimeField ||
          row.endTimeField ||
          row.progressField ||
          row.chartLabelFieldName);

      this.$set(row, "isDiffParent", isDiffParent);
      this.$set(row, "isEmpty", isEmpty);
    },
    changeEndTime(row, value) {
      let list = [];
      if (row.startTimeField) {
        let pName = "";
        if (row.startTimeField.name.indexOf(".") >= 0) {
          pName = row.startTimeField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (row.progressField) {
        let pName = "";
        if (row.progressField.name.indexOf(".") >= 0) {
          pName = row.progressField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }
      if (value) {
        let obj = this._.cloneDeep(
          this.formList.find((item) => item.name == value),
        );
        let pName = "";
        if (obj.name.indexOf(".") >= 0) {
          pName = obj.name.split(".")[0];
        }
        if (!list.includes(pName)) {
          list.push(pName);
        }

        this.$set(row, "endTimeField", {
          key: this.getUuid(),
          name: obj.name,
          title: obj.title,
          fieldType: obj.fieldType,
          componentName: obj.field.componentName,
          component: this._.cloneDeep(obj.field),
        });
      } else {
        this.$set(row, "endTimeField", null);
      }
      let isDiffParent = list.length > 1;
      let isEmpty =
        (!row.startTimeField || !row.endTimeField) &&
        (row.startTimeField ||
          row.endTimeField ||
          row.progressField ||
          row.chartLabelFieldName);

      this.$set(row, "isDiffParent", isDiffParent);
      this.$set(row, "isEmpty", isEmpty);
    },
    changeProgress(row, value) {
      let list = [];
      if (row.startTimeField) {
        let pName = "";
        if (row.startTimeField.name.indexOf(".") >= 0) {
          pName = row.startTimeField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (row.endTimeField) {
        let pName = "";
        if (row.endTimeField.name.indexOf(".") >= 0) {
          pName = row.endTimeField.name.split(".")[0];
        }

        if (!list.includes(pName)) {
          list.push(pName);
        }
      }

      if (value) {
        let obj = this._.cloneDeep(
          this.formList.find((item) => item.name == value),
        );
        let pName = "";
        if (obj.name.indexOf(".") >= 0) {
          pName = obj.name.split(".")[0];
        }
        if (!list.includes(pName)) {
          list.push(pName);
        }
        this.$set(row, "progressField", {
          key: this.getUuid(),
          name: obj.name,
          title: obj.title,
          fieldType: obj.fieldType,
          componentName: obj.field.componentName,
          component: this._.cloneDeep(obj.field),
        });
      } else {
        this.$set(row, "progressField", null);
      }
      let isDiffParent = list.length > 1;
      let isEmpty =
        (!row.startTimeField || !row.endTimeField) &&
        (row.startTimeField ||
          row.endTimeField ||
          row.progressField ||
          row.chartLabelFieldName);

      this.$set(row, "isDiffParent", isDiffParent);
      this.$set(row, "isEmpty", isEmpty);
    },

    isDelete(name) {
      if (!this.loadingForm) {
        return false;
      }
      return this.formList.findIndex((item) => item.name == name) == -1;
    },
    saveQueryValue() {
      console.log(this.currFilterField);
      if (
        !["nil", "nnil"].includes(this.currFilterField.oper) &&
        isEmpty(this.currFilterField.value)
      ) {
        this.$message.error("值不能为空");
        return;
      }
      this.$set(
        this.view.dataSource.queryObject.advanceQuery,
        this.currIndex,
        this._.cloneDeep(this.currFilterField),
      );
      this.dialogVisible = false;
    },
    showFilter(item, index) {
      this.currFilterField = this._.cloneDeep(item);
      this.currIndex = index;
      this.dialogVisible = true;
    },
    mousedown(event) {
      event.preventDefault();
      document.addEventListener("mousemove", this.mousemove);
      document.addEventListener("mouseup", this.mouseup);
      let clientWidth = event.currentTarget.clientWidth;
      let scrollWidth = event.currentTarget.scrollWidth;
      this.down = scrollWidth > clientWidth;
      if (this.down) {
        this.scrollPosition = {
          startX: event.clientX,
          scrollLeft: event.currentTarget.scrollLeft,
          maxLeft: scrollWidth - clientWidth,
          $el: event.currentTarget,
        };
      }
    },
    mouseup(event) {
      if (event) {
        event.preventDefault();
      }
      document.removeEventListener("mousemove", this.mousemove);
      document.removeEventListener("mouseup", this.mouseup);
      this.down = false;
    },
    mousemove(event) {
      event.preventDefault();
      if (!this.down) {
        return;
      }

      let endX = event.clientX;
      let scrollLeft =
        this.scrollPosition.scrollLeft + this.scrollPosition.startX - endX;
      if (scrollLeft > this.scrollPosition.maxLeft) {
        scrollLeft = this.scrollPosition.maxLeft;
      } else if (scrollLeft < 0) {
        scrollLeft = 0;
      }

      this.scrollPosition.$el.scrollLeft = scrollLeft;
    },
    closeTag(list, index) {
      this.$delete(list, index);
    },
    addModal(item) {
      this.$prompt("请输入筛选值", "筛选值", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (value) => {
          return !test.isEmpty(value);
        },
        inputErrorMessage: "请输入筛选值",
      })
        .then(({ value }) => {
          if (!item.value) {
            item.value = [];
          }
          item.value.push(value);
          this.$nextTick(() => {
            let $tagScroll = this.$refs.tagScroll;
            let scrollLeft = $tagScroll.scrollWidth - $tagScroll.clientWidth;
            $tagScroll.scrollLeft = scrollLeft;
          });
        })
        .catch(() => {});
    },

    getComponent(item) {
      return this.formList.find((obj) => obj.name == item.key);
    },
    getValueFormat(item) {
      let obj = this.getComponent(item);
      let picker;
      if (obj) {
        picker = obj.picker;
      }
      if (!picker) {
        picker = "datetime";
      }

      if (picker == "year") {
        return "yyyy";
      } else if (picker == "month") {
        return "yyyy-MM";
      } else if (picker == "date") {
        return "yyyy-MM-dd";
      } else if (picker == "date_minute") {
        return "yyyy-MM-dd HH:mm";
      } else {
        return "yyyy-MM-dd HH:mm:ss";
      }
    },
    getDatePicker(item) {
      let obj = this.getComponent(item);
      if (obj && obj.field.picker != "date_minute") {
        return obj.field.picker;
      }
      return "datetime";
    },
    changeAddr(item, value) {
      let obj = {};
      let provinceId = value[0];
      let cityId = value[1];
      let countyId = value[2];
      let provinceItem = this.areaTree.find((item) => item.id == provinceId);
      obj.provinceId = provinceId;
      obj.province = provinceItem.name;
      if (cityId) {
        let cityItem = provinceItem.children.find((item) => item.id == cityId);
        obj.cityId = cityId;
        obj.city = cityItem.name;
        if (countyId) {
          let countyItem = cityItem.children.find(
            (item) => item.id == countyId,
          );
          obj.countyId = countyId;
          obj.county = countyItem.name;
        }
      }
      this.$set(item, "value", obj);
    },
    getAddr(value) {
      let arr = [];
      if (value) {
        if (value.provinceId) {
          arr.push(value.provinceId);
        }
        if (value.cityId) {
          arr.push(value.cityId);
        }
        if (value.countyId) {
          arr.push(value.countyId);
        }
      }
      return arr;
    },
    areaList(obj) {
      let list = [];
      let $item = this.getComponent(obj);
      let picker = $item.picker;
      this.areaTree.forEach((item) => {
        let province = this.buildTree(item);
        if (picker != "province" && item.children) {
          province.children = [];
          item.children.forEach((item2) => {
            let city = this.buildTree(item2);

            if (picker != "city" && item2.children) {
              city.children = [];

              item2.children.forEach((sub) => {
                let county = this.buildTree(sub);
                city.children.push(county);
              });
            }

            province.children.push(city);
          });
        }
        list.push(province);
      });
      return list;
    },
    changeOper(item) {
      item.value = "";
    },
    getDisplayQueryOpers(componentName) {
      if (componentName) {
        return getComponentQueryOper(componentName);
      }
      return [];
    },
    isCheckSort(item, sort) {
      let obj;
      if (this.view.dataSource.queryObject) {
        obj = this.view.dataSource.queryObject.sortParam;
      }

      if (!obj) {
        obj = {};
      }
      let val = obj[item.field.name];
      return val == sort;
    },
    sortCol(item, sort) {
      item.sortVisible = false;
      item.visible = false;
      this.$set(item, "orderType", sort);
      // if (this.view.dataSource.queryObject) {
      //   if (!this.view.dataSource.queryObject.sortParam) {
      //     this.$set(this.view.dataSource.queryObject, "sortParam", {});
      //   }
      // } else {
      //   this.$set(this.view.dataSource, "queryObject", {
      //     sortParam: {},
      //   });
      // }
      // if (sort) {
      //   this.$set(
      //     this.view.dataSource.queryObject.sortParam,
      //     item.field.name,
      //     sort
      //   );
      // } else {
      //   delete this.view.dataSource.queryObject.sortParam[item.field.name];
      // }
    },
    editTitle(item) {
      item.visible = false;
      this.$prompt("请输入显示名称", "修改显示名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputValue: item.title,
        inputPlaceholder: "请输入显示名称",
        inputValidator: function (val) {
          if (!val) {
            return "显示名称不能为空";
          }
        },
      })
        .then(({ value }) => {
          item.title = value;
        })
        .catch((error) => {});
    },
    onEnd(e) {
      this.xFieldsAble = false;
      this.filterEnable = false;
    },
    onStart(item) {
      item.key = this.getUuid();
      this.xFieldsAble =
        this.view.xFields.findIndex((obj) => obj.field.name == item.name) ==
          -1 && xFieldsComponents.includes(item.field.componentName);
      if (item.name.indexOf(".") >= 0) {
        this.xFieldsAble = false;
      }
      this.filterEnable = true;
    },
    closeXFields(index) {
      let item = this.view.xFields.splice(index, 1)[0];
      if (
        this.view.dataSource.queryObject &&
        this.view.dataSource.queryObject.sortParam
      ) {
        delete this.view.dataSource.queryObject.sortParam[item.field.name];
      }
    },
    addXFields(node) {
      let xFields = this._.cloneDeep(this.view.xFields);
      this.view.xFields = xFields;
    },

    sortXFields() {},

    addQuery(node) {
      let newIndex = node.newIndex;
      let advanceQuery = this._.cloneDeep(
        this.view.dataSource.queryObject.advanceQuery,
      );
      let item = advanceQuery[newIndex];
      let oper = getComponentQueryOper(item.field.componentName)[0];
      // console.log("oper", oper);
      let obj = {
        key: item.name,
        oper: oper.value,
        value: "",
        title: item.title,
        componentName: item.field.componentName,
      };
      advanceQuery[newIndex] = obj;

      this.view.dataSource.queryObject.advanceQuery = advanceQuery;

      this.currFilterField = this._.cloneDeep(obj);
      this.currIndex = newIndex;
      this.dialogVisible = true;
    },
    closeQuery(index) {
      this.view.dataSource.queryObject.advanceQuery.splice(index, 1);
    },

    saveForm(type) {
      if (this.diffXField || this.diffJson) {
        return;
      }
      if (isEmpty(this.view.extParam.title)) {
        this.$message.error("图表名称不能为空");
        return;
      }
      if (this.view.xFields.length == 0) {
        this.$message.error("请设置维度");
        return;
      }

      if (!this.view.timeProgresses.length) {
        this.$message.error("请设置开始时间和结束时间");
        return;
      }
      if (
        this.view.timeProgresses.filter(
          (item) => item.startTimeField && item.endTimeField,
        ).length == 0
      ) {
        this.$message.error("请设置开始时间和结束时间");
        return;
      }

      // 请移除已被删除并标红的表单标签
      let isReturn = false;
      for (let i = 0; i < this.view.xFields.length; i++) {
        const item = this.view.xFields[i];
        if (this.isDelete(item.field.name)) {
          this.$message.error("请移除已被删除并标红的维度标签");
          isReturn = true;
          break;
        }
      }

      for (let i = 0; i < this.view.timeProgresses.length; i++) {
        const item = this.view.timeProgresses[i];
        if (item.isEmpty) {
          if (!item.endTimeField) {
            this.$message.error("请设置开始时间");
            isReturn = true;
            break;
          }
          if (!item.endTimeField) {
            this.$message.error("请设置结束时间");
            isReturn = true;
            break;
          }
        } else if (item.isDiffParent) {
          if (item.chartLabelFieldName.indexOf(".") >= 0) {
            this.$message.error(
              "开始时间、结束时间、进度和数据标签必须是同一个子表单",
            );
          } else {
            this.$message.error("开始时间、结束时间、进度必须是同一个子表单");
          }
          isReturn = true;
          break;
        } else {
          if (item.startTimeField && this.isDelete(item.startTimeField.name)) {
            this.$message.error("开始时间字段已被删除,请重新设置开始时间");
            isReturn = true;
            break;
          } else if (
            item.endTimeField &&
            this.isDelete(item.endTimeField.name)
          ) {
            this.$message.error("结束时间字段已被删除,请重新设置开始时间");
            isReturn = true;
            break;
          } else if (
            item.progressField &&
            this.isDelete(item.progressField.name)
          ) {
            this.$message.error("进度字段已被删除，请重新设置进度字段");
            isReturn = true;
            break;
          } else if (
            item.chartLabelFieldName &&
            this.isDelete(item.chartLabelFieldName)
          ) {
            this.$message.error("数据标签字段已被删除，请重新设置数据标签");
            isReturn = true;
            break;
          }
        }
      }

      if (isReturn) {
        return;
      }

      let error = "";
      if (this.view.dataSource.queryObject.advanceQuery) {
        for (
          let i = 0;
          i < this.view.dataSource.queryObject.advanceQuery.length;
          i++
        ) {
          const item = this.view.dataSource.queryObject.advanceQuery[i];
          if (this.isDelete(item.key)) {
            error = `请移除已被删除并标红的过滤标签`;
            break;
          }
          if (isEmpty(item.value) && !["nil", "nnil"].includes(item.oper)) {
            error = `请设置${item.title}的过滤值`;
            break;
          }
        }
      }
      if (error) {
        this.$message.error(error);
        return;
      }

      let view = this._.cloneDeep(this.view);
      view.xFields.forEach((item) => {
        delete item.visible;
        delete item.sortVisible;
      });
      let timeProgresses = view.timeProgresses.filter(
        (item) => item.startTimeField && item.endTimeField,
      );
      timeProgresses.forEach((item) => {
        delete item.isDiffParent;
        delete item.isEmpty;
      });
      view.timeProgresses = timeProgresses;
      this.timeProgresses = timeProgresses;

      this.$emit("save", {
        review: type,
        view,
        callback: () => {
          if (type == "preview") {
            this.preview = true;
          } else {
            this.$message.success("视图保存成功");
          }
        },
      });
    },
    close() {
      if (typeof this.closeFun === "function") {
        this.closeFun();
      } else {
        this.$router.back();
      }
    },
    getFormList(formList) {
      console.log("formList");
      this.formList = formList;
      this.loadingForm = true;
    },
    onMove(e) {
      let name = e.relatedContext.component.$attrs.name;
      if (name == "startTimeField") {
        if (e.draggedContext.element.field.componentName != "date_picker") {
          return false;
        }
        return this.startTimes.length == 0;
      }
      if (name == "endTimeField") {
        if (e.draggedContext.element.field.componentName != "date_picker") {
          return false;
        }
        return this.endTimes.length == 0;
      }
      if (name == "progressField") {
        if (e.draggedContext.element.field.componentName != "input_number") {
          return false;
        }
        return this.progressField.length == 0;
      }
      if (name == "xFields") {
        if (e.draggedContext.element.field.name.indexOf(".") >= 0) {
          return false;
        }
        return (
          e.relatedContext.list.findIndex(
            (item) => item.field.name == e.draggedContext.element.name,
          ) == -1 &&
          xFieldsComponents.includes(
            e.draggedContext.element.field.componentName,
          )
        );
      }
      console.log(name);
      return true;
    },
    changeSource(formId, componentList) {
      this.view.dataSource = {
        formId: formId,
        queryObject: null,
        sourceId: 0,
        sourceType: "form",
        queryObject: {
          advanceQuery: [],
          limit: 0,
          limitStart: 0,
          pageNumber: 0,
          pageSize: 0,
          queryParam: {},
          sortParam: {},
          sortString: "",
        },
      };
      this.view.xFields = [];
      this.view.metrics = [];
      this.view.startTimeField = null;
      this.view.endTimeField = null;
      this.view.progressField = null;
      this.view.chartLabelFieldName = "";
      this.view.chartLegend = {
        enable: false,
        position: "top",
      };

      this.formList = componentList;
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 10px;

  .label {
    width: 100px;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.container {
  display: flex;
  height: calc(100vh - 70px);
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.mr {
  margin-right: 10px;
}
.weui-flex {
  display: flex;
  align-items: center;
}
.weui-row {
  margin: 10px 10px 0px;
}
.weui-item {
  flex: 1;
  font-size: 12px;
}
.field-row {
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  min-height: 44px;
  &.n-border {
    border-color: transparent;
  }
}
.col-container {
  display: flex;
  flex-wrap: wrap;
  ::v-deep {
    .el-tooltip {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
}
.field-title {
  font-size: 12px;
  margin-right: 10px;
  width: max-content;
}
.drag-able {
  background-color: rgba($color: #409eff, $alpha: 0.1);
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
}
.nav-tab {
  display: flex;

  .tab-txt {
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    padding: 0px 20px;
    flex: 1;
    cursor: pointer;

    &.on {
      color: #409eff;
      cursor: default;
    }
  }
}

.form-body {
  flex: 1;
  overflow: auto;

  .lay-item {
    font-size: 14px;
    border: solid 1px #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
  }
}
.m {
  &:focus {
    outline: none !important;
  }
}
.pop-container {
  padding: 6px 0px;
}
.pop-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  height: 24px;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #409eff, $alpha: 0.1);
  }
}
.flex {
  flex: 1;
}
.tag-container {
  height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  padding: 0px 4px;
}
.tag-scroll {
  overflow: hidden;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.empty-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.required {
  color: #ff4d4f;
  padding-right: 4px;
}
.is-empty,
.is-error {
  ::v-deep {
    .el-input__inner {
      border-color: #ff4d4f;
      &::placeholder {
        color: #ff4d4f;
      }
    }
  }
}
</style>
<style lang="scss">
.outline-pop {
  padding: 0 !important;
}
</style>
