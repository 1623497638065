<template>
  <el-dialog :visible.sync="dialogVisible" :title="title" destroy-on-close>
    <div style="padding: 10px">
      <div class="operation-btns">
        <el-button type="success" size="medium" @click="restoreData"
          >还原</el-button
        >
        <el-button type="danger" size="medium" @click="deleteData"
          >彻底删除</el-button
        >
        <el-button size="medium" @click="clearRecycle">清空回收站</el-button>
      </div>
      <el-table
        :data="tableData"
        row-key="id"
        @select-all="handleSelectAll"
        @select="handleSelect"
        height="600px"
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
          key="selection"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="page.totalRow"
        :page.sync="page.pageNumber"
        :limit.sync="page.pageSize"
        @pagination="loadingList"
      />
    </div>
  </el-dialog>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  fetchRecycleFieldList,
  restoreFormField,
  thoroughDeleteFormField,
  getRecycleForm,
  restoreRecycleForm,
  thoroughDeleteForm,
  getWorkDirRecycleList,
  restoreWorkDirData,
  thoroughDeleteWorkDirData,
} from "./api";

export default {
  components: { Pagination },
  data() {
    return {
      page: {
        pageSize: 20,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      selectionList: [],
      tableData: [],
    };
  },
  computed: {
    columns() {
      if (this.type == "field") {
        return [
          {
            label: "字段名称",
            prop: "comment",
          },
          {
            label: "删除人",
            prop: "updater",
          },
          {
            label: "删除时间",
            prop: "updatedTime",
          },
        ];
      } else if (["resource", "workDir"].includes(this.type)) {
        return [
          {
            label: "名称",
            prop: "title",
          },
          {
            label: "删除人",
            prop: "updater",
          },
          {
            label: "删除时间",
            prop: "updatedTime",
          },
        ];
      }
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    selectionIds() {
      return this.selectionList.map((item) => item.id);
    },
  },
  props: {
    type: {
      type: String,
      default: "field",
    },
    title: {
      type: String,
      default: "回收站",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    resourceType: {
      type: [String, Number],
      default: "",
    },
  },
  created() {
    this.openDialog();
  },
  methods: {
    openDialog() {
      this.selectionList = [];
      this.loadingList();
    },
    loadingList(refresh) {
      let getData = () => {
        if (this.type == "field") {
          return fetchRecycleFieldList({
            pageNumber: this.page.pageNumber,
            pageSize: this.page.pageSize,
            tableName: this.$route.query.id,
          });
        } else if (this.type == "resource") {
          return getRecycleForm({
            pageNumber: this.page.pageNumber,
            pageSize: this.page.pageSize,
            type: this.resourceType,
          });
        } else if (this.type == "workDir") {
          return getWorkDirRecycleList({
            pageNumber: this.page.pageNumber,
            pageSize: this.page.pageSize,
            companyId: this.$store.state.user.currentCompanyId,
          });
        }
      };
      getData().then((res) => {
        this.$set(this, "tableData", res.data.page.list);
        this.page.totalRow = res.data.page.totalRow;
        if (refresh) {
          this.$emit("refresh");
        }
      });
    },
    confirm(isShowMsg, check, callback, refresh) {
      if (this.tableData.length < 1) return;
      if (check && this.selectionIds.length < 1) {
        this.$message.warning("请选择数据");
        return;
      }
      if (isShowMsg) {
        this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          callback().then(() => {
            this.loadingList(refresh);
          });
        });
      } else {
        callback().then(() => {
          this.loadingList(refresh);
        });
      }
    },
    restoreData() {
      let callback;
      if (this.type == "field") {
        callback = () => {
          return restoreFormField({
            tableName: this.$route.query.id,
            ids: this.selectionIds,
          });
        };
      } else if (this.type == "resource") {
        callback = () => {
          return restoreRecycleForm({
            ids: this.selectionIds,
          });
        };
      } else if (this.type == "workDir") {
        callback = () => {
          return restoreWorkDirData({
            ids: this.selectionIds,
          });
        };
      }
      this.confirm(false, true, callback, true);
    },
    deleteData() {
      let callback;
      if (this.type == "field") {
        callback = () => {
          return thoroughDeleteFormField({
            ids: this.selectionIds,
          });
        };
      } else if (this.type == "resource") {
        callback = () => {
          return thoroughDeleteForm({
            ids: this.selectionIds,
            type: this.resourceType,
          });
        };
      } else if (this.type == "workDir") {
        callback = () => {
          return thoroughDeleteWorkDirData({
            ids: this.selectionIds,
          });
        };
      }
      this.confirm(true, true, callback, false);
    },
    clearRecycle() {
      let callback;
      if (this.type == "field") {
        callback = () => {
          return thoroughDeleteFormField({
            tableName: this.$route.query.id,
          });
        };
      } else if (this.type == "resource") {
        callback = () => {
          return thoroughDeleteForm({
            companyId: this.$store.state.user.currentCompanyId,
            type: this.resourceType,
          });
        };
      } else if (this.type == "workDir") {
        callback = () => {
          return thoroughDeleteWorkDirData({
            companyId: this.$store.state.user.currentCompanyId,
          });
        };
      }
      this.confirm(true, false, callback, false);
    },
    handleSelect(selections, row) {
      if (this.selectionList.some((item) => item.id == row.id)) {
        this.selectionList = this.selectionList.filter(
          (item) => item.id != row.id,
        );
      } else {
        this.selectionList.push(row);
      }
    },
    handleSelectAll(selections) {
      if (selections.length > 0) {
        let arr = [...this.selectionList, ...selections];
        let arr1 = [];
        this.selectionList = [];
        arr.forEach((item) => {
          if (!arr1.includes(item.id)) {
            arr1.push(item.id);
            this.selectionList.push(item);
          }
        });
      } else {
        this.selectionList = this.selectionList.filter((item) => {
          return !this.tableData.some((row) => {
            return row.id == item.id;
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.operation-btns {
  margin-bottom: 10px;
}
::v-deep .el-table th {
  background-color: #f5f7fa;
}
</style>
