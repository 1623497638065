<template>
  <el-dialog
      :title="title"
      fullscreen
      append-to-body
      destroy-on-close
      :visible.sync="dialogVisible"
      @open="open"
      @closed="closed"
      :close-on-click-modal="false"
  ><div class="body" v-if="workflow">
    <data-flow
        v-if="dialogVisible"
        workflowType="company"
        :workflow.sync="workflow"
    ></data-flow>
  </div>
  </el-dialog>
</template>
<script>
import dataFlow from "./data-flow.vue";
import { companyWorkflowDetail } from "../api";

export default {
  components: { dataFlow },
  props: {
    companyFlow: Object,
    visible: Boolean,
    workflowId: [String, Number],
  },
  computed: {
    title() {
      let val = this.companyFlow
          ? this.companyFlow.title || this.companyFlow.formTitle
          : "";
      return `流程预览：${val}`;
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  data() {
    return {
      workflow: null,
    };
  },
  methods: {
    closed() {
      this.workflow = null;
    },
    open() {
      companyWorkflowDetail({ workflowId: this.workflowId }).then((res) => {
        this.workflow = res.data.workflow;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  height: calc(100vh - 45px);
  overflow: auto;
}
</style>
