<template>
  <div
    style="display: flex; flex-direction: row; height: 100%; position: relative"
  >
    <div
      style="
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        transition-property: margin-left;
        transition-duration: 0.5s;
        border-right: 1px solid #e9e9e9;
      "
      :style="[
        { background: treeViewVisible ? '#fff' : '#f4f5f7' },
        {
          width: menuWrapWidth + 'px',
          marginLeft: treeViewVisible
            ? wrapMarginLeft + 'px'
            : wrapMarginLeft + 0 + 'px',
        },
      ]"
    >
      <div
        v-if="treeViewVisible"
        class="drag-right"
        @mousedown="dragdown($event)"
        :class="onDraging ? 'onDraging' : ''"
      ></div>
      <i
        style="font-size: 20px"
        :style="treeViewVisible ? 'right: -20px;' : 'right: -26px;'"
        class="toggle-panel custom-icon"
        :class="
          treeViewVisible ? 'iconfont icon-zhedie' : 'iconfont icon-zhankai'
        "
        @click="toggleBoardVisible"
      ></i>

      <el-form
        v-show="show"
        :model="searchForm"
        class="demo-form-inline"
        size="small"
        style="width: 100%"
      >
        <el-form-item class="itemSearchForm">
          <el-input
            prefix-icon="el-icon-search"
            placeholder="搜索"
            v-model="searchForm.title"
            style="width: 85%"
            @input="search"
          >
            <i
              v-if="searchForm.title"
              @click="clearTitle"
              slot="suffix"
              class="el-input__icon el-icon-circle-close"
              style="cursor: pointer"
            ></i>
          </el-input>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
            "
          >
            <span style="font-size: 12px; color: #6b778c">所有数据流</span>
            <el-dropdown>
              <i
                class="el-icon-plus add"
                style="
                  font-size: 14px;
                  cursor: pointer;
                  font-weight: 600;
                  padding: 5px;
                  border-radius: 5px;
                "
              ></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="add(0)"
                  >新建数据流</el-dropdown-item
                >
                <el-dropdown-item @click.native.stop="addFolder"
                  >新增文件夹</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-form-item>
      </el-form>

      <tree
        v-show="show"
        @nodeClick="nodeClick"
        @del="del"
        @edit="edit"
        @viewAddress="viewAddress"
        @copy="copy"
        ref="tree"
        :type="type"
      >
        <!-- <template slot="label" slot-scope="{ data, node }">
          <div :class="data.enable ? 'enable' : 'disable'">
            {{ data.enable ? "已启用" : "已停用" }}
          </div>
        </template> -->
        <template slot="more" slot-scope="{ data, node }">
          <div
            @click.stop
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            "
          >
            <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
              <el-tooltip
                effect="dark"
                content="新建"
                placement="top"
                :open-delay="500"
              >
                <i
                  class="el-icon-plus custom-icon"
                  style="cursor: pointer; font-weight: 700"
                ></i>
              </el-tooltip>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="addFolder(data, node)">
                  添加子文件夹
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="add(data.id)">
                  添加数据流
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" v-if="data.dataType == 'folder'">
              <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="renameDir(data, node)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="delDir(data, node)">
                  删除
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="moveTo(data, node)">
                  移动到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="delDirForce(data, node)">
                  强制删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="click" v-if="data.dataType == 'resource'">
              <i class="el-icon-more custom-icon" style="cursor: pointer"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native.stop="edit(data, node)">
                  重命名
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="copy(data, node)">
                  复制
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="showLog(data.bizId)">
                  更新规则和日志
                </el-dropdown-item>

                <el-dropdown-item @click.native.stop="moveTo(data, node)">
                  移动到
                </el-dropdown-item>
                <el-dropdown-item @click.native.stop="del(data, node)">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </tree>
    </div>
    <div style="flex: 1; height: 100%; overflow: auto">
      <!--            <el-dialog-->
      <!--                    :title="title"-->
      <!--                    :visible="true"-->
      <!--                    fullscreen-->
      <!--                    @closed="close"-->
      <!--                    width="560px"-->
      <!--                    v-if="!isRootRoute"-->
      <!--            >-->
      <!--                <router-view :key="key"/>-->
      <!--            </el-dialog>-->
      <transition>
        <router-view v-if="!isRootRoute" :key="key" />
        <!--        <el-empty v-else description="描述文字"></el-empty>-->
      </transition>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="visible"
      @closed="close"
      width="560px"
    >
      <div style="padding: 20px">
        <el-form ref="form" :model="form" label-width="120px" :rules="rules">
          <el-form-item label="数据流名称" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
          :loading="saveLoading"
          type="primary"
          @click="saveForm('platform')"
          >确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="配置地址"
      :visible.sync="addrVisible"
      width="560px"
      append-to-body
    >
      <div style="padding: 20px">
        <div class="row">
          <span>数据列表地址：</span>
          <div class="input">/online/{{ formId }}/list</div>
          <!--          <el-link>查看<i class="el-icon-view" @click="$router.push(`/online/${{ formId }}/list`)"></i> </el-link>-->
        </div>
        <div class="row">
          <span>数据新增地址：</span>
          <div class="input">/online/{{ formId }}/add</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="移动"
      :visible.sync="folderSelectDialogVisible"
      width="520px"
      :before-close="handleBeforeCLose"
      class="folderSelectDialog"
    >
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 5px;
        "
      >
        <p>请选择目标文件夹：</p>
        <FolderSelect
          @change="handleFolderSelectChange"
          v-if="folderSelectDialogVisible"
          :type="this.type"
          :current-node="currenMoveNode"
        ></FolderSelect>
      </div>
    </el-dialog>
    <dataflow-log
      :visible.sync="logVisible"
      :dataflow-id="dataflowId"
    ></dataflow-log>

    <!-- 删除数据流 -->
    <el-dialog :visible.sync="delVisible" width="560px" append-to-body>
      <div
        slot="title"
        style="
          display: flex;
          align-items: center;
          font-size: 18px;
          padding-right: 20px;
        "
      >
        <i
          class="el-icon-warning"
          style="color: #e6a23c; font-size: 22px; margin-right: 6px"
        ></i
        >确定要删除"{{ form.title }}"吗?
      </div>
      <div style="padding: 20px">
        该数据流被引用，删除数据流会同时删除该数据流的数据使用权限，请谨慎操作！
      </div>
      <div slot="footer" class="dialog-footer">
        <el-link type="primary" :underline="false" @click="openReferenceDetail"
          >查看引用详情</el-link
        >
        <div>
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button type="primary" @click="finalDel">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 查看引用详情 -->
    <referenceDetail
      v-if="referenceVisible"
      :visible.sync="referenceVisible"
      :formId="form.id"
      :referenceTitle="form.title"
      :source="'dataflow'"
    ></referenceDetail>
  </div>
</template>
<script>
import {
  copyDataflow,
  createDataflow,
  deleteDataflow,
  renameDataflow,
} from "./api";
import Pagination from "@/components/Pagination";
import tree from "@/views/lowCode/form/tree";
import {
  dirDelete,
  dirMove,
  dirRegister,
  dirRename,
} from "@/views/lowCode/api";
import { referenceRemoveCheck } from "@/views/lowCode/form/api";
import FolderSelect from "@/views/lowCode/FolderSelect";
import _ from "lodash";
import { scaleWidth } from "@/mixin/scaleWidth";
import DataflowLog from "@/views/lowCode/dataFactory/DataflowLog";
import referenceDetail from "@/views/lowCode/form/reference-detail";

export default {
  components: { Pagination, tree, FolderSelect, DataflowLog, referenceDetail },
  mixins: [scaleWidth],
  data() {
    return {
      show: true,
      formId: "",
      saveLoading: false,
      title: "添加数据流",
      visible: false,
      addrVisible: false,
      form: {},
      rules: {
        title: [
          {
            required: true,
            message: "数据流名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      searchForm: {
        title: "",
      },
      type: 50,
      //当前操作的中间变量的目录id
      currentActionDirId: 0,
      folderSelectDialogVisible: false,
      defaultExpandedKeys: [],
      currenMoveNode: undefined,
      treeViewVisible: true,
      searchResultList: [],
      dataflowId: 0,
      logVisible: false,
      delVisible: false,
      referenceVisible: false,
      curRow: {},
    };
  },

  created() {},
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.$refs.tree.reset();
        this.back(this.$route.query.id);
        this.searchForm.title = "";
      },
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isRootRoute() {
      return this.$route.path == "/lowCode/assistant";
    },
    defaultExpandedKeysUniq: function () {
      return this._.uniq(this.defaultExpandedKeys);
    },
    companyId() {
      return this.$store.getters.currentCompanyId;
    },
  },
  methods: {
    clearTitle() {
      this.reset();
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back(currentBizId) {
      if (this.$route.query.id && this.$route.query.id == currentBizId) {
        let { matched } = this.$router.history.current;
        if (matched.length >= 2) {
          let parentPath = matched[matched.length - 2].path;
          this.$router.push({ path: parentPath });
        } else {
          this.$router.back();
        }
      }
    },

    reset() {
      this.searchForm.title = "";
      this.$refs.tree.reset();
    },
    searchChange(e) {
      let data = {
        bizId: e,
      };
      this.editForm(data);
    },

    toggleBoardVisible() {
      this.treeViewVisible = !this.treeViewVisible;
      this.show = !this.show;
    },
    moveTo(data, node) {
      this.currenMoveNode = node;
      this.folderSelectDialogVisible = true;
    },
    renameDir(data, node) {
      this.$prompt("请输入新文件夹名", "重命名文件夹", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.title,
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            dirId: data.id,
            title: value,
          };
          dirRename(param).then((resp) => {
            this.$set(node.data, "title", value);
          });
        })
        .catch(() => {});
    },
    handleBeforeCLose() {
      this.folderSelectDialogVisible = false;
    },
    /**
     * 处理移动到的相关逻辑
     * @param parentId
     */
    handleFolderSelectChange(parentId) {
      this.folderSelectDialogVisible = false;
      let { dataType, type, id, recordType, recordId } =
        this.currenMoveNode.data;
      let params = {
        id,
        parentId,
      };
      dirMove(params).then(async (resp) => {
        this.$refs.tree.$refs.tree.remove(this.currenMoveNode.data);
        this.currenMoveNode.data.parentId = parentId;
        this.$refs.tree.insertToWorkGroupTree(this.currenMoveNode.data);
        this.defaultExpandedKeys.push(parentId);
      });
    },
    delDir(data, node) {
      this.$confirm("删除文件夹?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.directoryList && data.directoryList.length != 0) {
            this.$message.warning("不能删除非空文件夹");
            return;
          }
          dirDelete({
            dirId: data.id,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delDirForce(data, node) {
      this.$confirm("强制删除目录，下面的全部目录和文件将被永久删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.id == 0) {
            //不允许误删除根目录
            return;
          }
          dirDelete({
            dirId: data.id,
            withSubs: true,
          }).then((resp) => {
            this.$refs.tree.$refs.tree.remove(node);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFolder(data, node) {
      let parentId;
      if (data && data.id) {
        parentId = data.id;
      } else {
        parentId = 0;
      }
      console.log(parentId);
      this.$prompt("", "新建", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.addFolderByParent(parentId, value);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addFolderByParent(parentId = 0, title = "默认文件夹名") {
      let params = {
        parentId,
        title,
        type: this.type,
      };
      dirRegister(params).then(() => {
        this.$refs.tree.update(parentId);
      });
    },
    nodeClick(data, node) {
      let { dataType } = data;
      if (dataType == "resource") {
        this.editForm(data);
      }
    },
    showLog(id) {
      this.logVisible = true;
      this.dataflowId = id;
    },
    copy(row) {
      this.$prompt("请输入新数据流名", "复制数据流", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.title + "-复制",
      })
        .then(({ value }) => {
          if (!value) return;
          let param = {
            dataflowId: row.bizId,
            title: value,
          };
          copyDataflow(param).then((resp) => {
            this.searchForm.title = "";
            this.$refs.tree.insertToWorkGroupTree(resp.data.dir);
          });
        })
        .catch(() => {});
    },

    // 搜索防抖
    search: _.debounce(function () {
      if (!this.searchForm.title) this.$refs.tree.reset();
      if (this.searchForm.title) {
        this.searchForm.title = this.searchForm.title.trim();
        this.$refs.tree.search(this.searchForm.title);
      }
    }, 500),
    // 删除
    async del(row) {
      this.$set(this, "form", {
        title: row.title,
        id: row.bizId,
      });
      this.curRow = row;
      let params = {
        collection: row.bizId,
        type: "dataflow",
      };
      // 删除表单前检查表单是否有被引用
      let checkResult = await referenceRemoveCheck(params);
      if (checkResult?.data?.beReferred) {
        // 有引用关系
        this.delVisible = true;
      } else {
        this.$confirm("确定要删除吗?", "提示").then(() => {
          deleteDataflow(row.bizId).then((res) => {
            this.$message.success("删除成功");
            this.$refs.tree.$refs.tree.remove(row);
            this.back(row.bizId);
          });
        });
      }
    },
    // 存在引用关系时的删除
    finalDel() {
      deleteDataflow(this.form.id)
        .then((res) => {
          this.$message.success("删除成功");
          this.delVisible = false;
          this.$refs.tree.$refs.tree.remove(this.curRow);
          this.back(this.form.id);
        })
        .catch((err) => {
          this.delVisible = false;
        });
    },
    // 查看引用详情
    openReferenceDetail() {
      this.referenceVisible = true;
    },
    edit(row) {
      this.$set(this, "form", {
        title: row.title,
        id: row.bizId,
      });
      this.title = "重命名数据流";
      this.visible = true;
    },
    editForm(row) {
      this.$router.push({
        path: "/lowCode/assistant/edit",
        query: { id: row.bizId },
      });
    },
    viewAddress(row) {
      this.formId = row.bizId;
      this.addrVisible = true;
    },
    cancel() {
      this.visible = false;
    },
    close() {
      this.$set(this, "form", {});
    },
    //添加数据流到根目录
    add(dirId) {
      this.title = "添加数据流";
      //   this.form = {};
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.currentActionDirId = dirId;
      this.visible = true;
    },
    saveForm(label) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.saveLoading = true;
        if (this.form.id) {
          renameDataflow({
            title: this.form.title,
            dataflowId: this.form.id,
          }).then((resp) => {
            this.saveLoading = false;
            this.visible = false;
            this.$refs.tree.$refs.tree.getNode(resp.data.bizDir.id).data.title =
              this.form.title;
          });
        } else {
          createDataflow({
            title: this.form.title,
            dirId: this.currentActionDirId,
            companyId: this.companyId,
          })
            .then((res) => {
              this.saveLoading = false;
              this.visible = false;
              this.$message.success("新增成功");

              this.$refs.tree.update(this.currentActionDirId);
              this.editForm(res.data.dir);
              this.$refs.tree.$refs.tree.setCurrentKey(res.data.dir.id);
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    editForm(row) {
      this.$router.push({
        path: "/lowCode/dataFactory/edit",
        query: { id: row.bizId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
  ::v-deep {
    &.el-form-item {
      margin-bottom: 0;
    }
  }
}
.work-right-toggle {
  margin-left: -230px;
  transition: all 0.3s;
}

.toggle-panel {
  position: absolute;
  z-index: 999;
  right: 2px;
  top: 0px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input {
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 5px 20px;
}

.itemSearchForm {
  padding: 10px 0 0 10px;

  &::v-deep .el-input__inner {
    border-radius: 10px;
    background-color: #f4f5f7;
    border: none;
  }
}

::v-deep .el-dropdown {
  right: 10px;
}
.add:hover {
  background-color: rgb(225, 237, 248);
  color: #3490e6;
}

.toggle-panel {
  position: absolute;
  z-index: 999;
  right: 2px;
  top: 16px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--zgg-border-light);
  border-radius: 50%;
  background-color: white;
  color: #6b77bc;
}
::v-deep .el-scrollbar__wrap::-webkit-scrollbar {
  display: none !important;
}

.enable {
  font-size: 12px;
  transform: scale(0.8);
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: content-box;
  border-radius: 51px;
  padding: 0 4px;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a;
}

.disable {
  font-size: 12px;
  transform: scale(0.8);
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  box-sizing: content-box;
  border-radius: 51px;
  padding: 0 4px;
  background-color: #fef0f0;
  border-color: #fde2e2;
  color: #f56c6c;
}
.drag-right {
  width: 8px;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 999;

  &:hover {
    border-right: solid 2px rgba(24, 144, 255, 0.6);
  }
  &.onDraging {
    border-right: solid 4px rgba(24, 144, 255, 0.6);
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
