<template>
  <div class="container" style="position: relative">
    <div class="title-div">
      <span>页面设置</span>
      <i @click="closeSet" class="el-icon-close pointer font-24"></i>
    </div>
    <div class="row">
      <div class="title-label">仪表盘样式</div>
      <background-config
        @updateBgColor="updateBgColor"
        :colorConfig="colorConfig"
      ></background-config>
    </div>
  </div>
</template>

<script>
import backgroundConfig from "@/views/lowCode/dashboard/detail-component/background-config";

export default {
  // mixins: [dashboardMixin],
  components: { backgroundConfig },
  props: {
    colorConfig: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    closeSet() {
      this.$emit("closeSet");
    },
    updateBgColor(background, backgroundEnum, backgroundImageFillEnum) {
      this.$emit(
        "updateBgColor",
        background,
        backgroundEnum,
        backgroundImageFillEnum,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
}
.row {
  padding: 10px;
  .label {
    font-size: 13px;
    min-width: 32%;
  }
}
.title-div {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px -1px 0px 0px #ebecf0 inset;
  font-size: 14px;
}
.pointer {
  cursor: pointer;
}
.font-24 {
  font-size: 18px;
}
</style>
