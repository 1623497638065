import request from '@/utils/request'

import qs from "qs"


/**
 * 网盘控制器
 */

/**
 * 分页列表
 */
export function diskListPage(data) {
  return request({
    url:  '/disk/listPage',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 新增文档
 */
export function diskAdd(data) {
  return request({
    url:  '/disk/add',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 重命名
 */
export function diskRename(data) {
  return request({
    url:  '/disk/rename',
    method: 'POST',
    data:qs.stringify(data)
  })
}
/**
 删除文档
 */
export function diskDelete(data) {
  return request({
    url:  '/disk/delete',
    method: 'POST',
    data:qs.stringify(data)
  })
}





