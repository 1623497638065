<template>
  <div class="container">
    <div class="tools">
      <el-button-group>
        <el-button type="primary" icon="el-icon-check" @click="handleSave"
          >保存
        </el-button>
        <!--        <el-button type="primary" icon="el-icon-view" @click="handlePreview"-->
        <!--        >预览-->
        <!--        </el-button>-->
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="handleDownload"
          >下载模板
        </el-button>
      </el-button-group>
    </div>
    <el-table
      :data="formDetail.contentField"
      height="10"
      style="width: 100%"
      border
    >
      <el-table-column prop="variable" label="excel字段"></el-table-column>
      <el-table-column label="表单字段">
        <template slot-scope="{ row, $index }">
          <div style="display: flex; flex-direction: row">
            <el-select
              v-model="row.metaFieldId"
              placeholder="请选择关联表格字段"
              style="width: 100%"
              clearable
              @clear="handleClear(row)"
              @change="handleFieldChange(row)"
              @focus="setOptionWidth"
            >
              <template v-for="item in formFieldList">
                <el-option
                  v-if="item.componentName != 'reference_data_list'"
                  :key="item.id"
                  :label="item.comment"
                  :value="item.id"
                  :style="{ width: optionWidth }"
                >
                </el-option>
              </template>
            </el-select>
            <el-button
              style="margin: 0 10px"
              type="text"
              @click="showDrawer(row)"
              v-if="row.metaFieldId && formFieldConfigurable(row)"
              >配置</el-button
            >
          </div>
          <div
            style="display: flex; flex-direction: row"
            v-if="getSubcomponentList(row.metaFieldId).length != 0"
          >
            <el-select
              v-model="row.name"
              placeholder="请选择关联子表格字段"
              style="width: 100%"
              @focus="setOptionWidth"
            >
              <template v-for="item in getSubcomponentList(row.metaFieldId)">
                <el-option
                  v-if="item.componentName != 'reference_data_list'"
                  :key="item.name"
                  :label="item.title"
                  :value="item.name"
                  :style="{ width: optionWidth }"
                >
                </el-option>
              </template>
            </el-select>
            <el-button
              style="margin: 0 10px"
              type="text"
              @click="showDrawer(row)"
              v-if="row.name && subformFieldConfigurable(row)"
              >配置</el-button
            >
          </div>
          <el-radio-group
            style="padding: 10px"
            v-model="row.selectModel"
            v-if="isShowSelectModel(row)"
          >
            <el-radio :label="0">只选中项</el-radio>
            <el-radio :label="1">平铺选项</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="流程字段">
        <template slot="header" slot-scope="{ row }">
          流程字段
          <el-select
            v-model="workflowId"
            placeholder="选择流程"
            size="small"
            @change="workFlowChange"
          >
            <el-option
              v-for="item in flowList"
              :key="item.workflowId"
              :label="item.title"
              :value="item.workflowId"
              >{{ item.title }}
              <el-tag
                :key="item.workflowId"
                v-if="item.versionStatus == 'enable'"
                size="mini"
                type="success"
                >启用中
              </el-tag>
            </el-option>
          </el-select>
        </template>
        <template slot-scope="{ row, $index }">
          <el-select
            v-model="row.nodeKey"
            placeholder="请选择流程节点"
            style="width: 100%"
            clearable
            @change="handleFlowFieldChange(row)"
          >
            <el-option
              v-for="item in nodeList.filter(
                (item) => !item.endNode && !item.startNode,
              )"
              :key="item.key"
              :label="item.title"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <div style="display: flex; flex-direction: row">
            <el-select
              v-if="row.nodeKey"
              v-model="row.flowName"
              placeholder="请选择流程字段"
              style="width: 100%"
              @change="flowNodeFieldEnumsChange(row)"
              clearable
            >
              <el-option
                v-for="item in nodeFieldEnums"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              style="margin: 0 10px"
              type="text"
              @click="showDrawer(row)"
              v-if="row.flowName && ['actionTime'].includes(row.flowName)"
              >配置</el-button
            >
          </div>
          <template v-if="isShowFlowModel(row)">
            <el-radio-group
              v-model="row.flowModel"
              @change="modelChange(row)"
              style="padding: 10px"
            >
              <el-radio :label="0">单人模式</el-radio>
              <el-radio :label="1">会签模式</el-radio>
            </el-radio-group>
            <el-select
              v-if="row.flowModel == 1"
              v-model="row.signSort"
              placeholder="请选择流程字段"
              style="width: 100%"
            >
              <el-option
                v-for="item in signSortList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="row.flowModel == 0"
              v-model="row.flowIndex"
              placeholder="请输入节点负责人序号"
              type="number"
              onmousewheel="return false;"
              oninput="value=value.replace(/^0|[^0-9]/g, '')"
            ></el-input>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
      :title="'表单字段配置(' + currentField.variable + ')'"
      :visible.sync="drawer"
      direction="rtl"
      size="25%"
      :modal="false"
      :before-close="handleClose"
    >
      <div class="fieldSetting">
        <template v-if="isShowTimeSet">
          <span class="subTitle">时间类型</span>
          <el-select
            v-model="currentField.picker"
            size="mini"
            style="width: 100%"
            @change="changePicker"
          >
            <el-option label="年" value="year"></el-option>
            <el-option label="年月" value="month"></el-option>
            <el-option label="年月日" value="date"></el-option>
            <el-option label="年月日时" value="date_hour"></el-option>
            <el-option label="年月日时分" value="date_minute"></el-option>
            <el-option label="年月日时分秒" value="datetime"></el-option>
          </el-select>
          <span class="subTitle">显示格式</span>
          <el-select
            v-model="currentField.dateFormat"
            size="mini"
            style="width: 100%"
            placeholder=""
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <span>图片宽高设置:</span>
          <span class="subTitle">宽:</span>
          <el-input
            v-model="currentField.width"
            placeholder="最小值10；默认60"
            type="number"
            onmousewheel="return false;"
            size="mini"
            :min="10"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input>
          <span class="subTitle">高:</span>
          <el-input
            v-model="currentField.height"
            placeholder="最小值10；默认60"
            type="number"
            onmousewheel="return false;"
            size="mini"
            :min="10"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input>
        </template>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { excelExport, excelUpdateCellNew } from "@/views/lowCode/form/api";
import { companyWorkflowList } from "@/views/lowCode/form/templateSetting/api";
import { fetchMetaFieldList } from "@/api/form";
import { templateMixin } from "@/views/lowCode/form/templateSetting/templateMixin";
export default {
  mixins: [templateMixin],
  data() {
    return {
      formFieldList: [],
      flowList: [],
      workflowId: "",
      drawer: false,
      currentField: {},
      hasConfigComponents: ["date_picker"],
    };
  },
  props: {
    formDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    formId: {
      type: String,
      default() {
        return "";
      },
    },
    templateName: {
      type: String,
      default() {
        return "";
      },
    },
  },

  async created() {
    await this.init();
    await this.fetchWorkFlowList();
  },
  methods: {
    workFlowChange(val) {
      this.formDetail.contentField.forEach((row) => {
        row.nodeKey = "";
        row.flowName = "";
      });
      this.getWorkflowNodeList(val);
    },
    handleFlowFieldChange(row, $index) {
      row.metaFieldId = "";
      row.flowName = "";
      row.flowModel = 0;
      row.flowIndex = 1;
      if (row.nodeKey) {
        row.workflowId = this.workflowId;
      } else {
        row.workflowId = 0;
      }
    },
    async fetchWorkFlowList() {
      await companyWorkflowList({
        companyId: this.$store.getters.currentCompanyId,
        isOpen: true,
        formId: this.formId,
      }).then(async (res) => {
        this.flowList = res.data?.page?.list;
        if (this.formDetail) {
          let item = this.formDetail.contentField.find((item) => {
            return item.workflowId;
          });
          if (item) {
            this.workflowId = item.workflowId;
            await this.getWorkflowNodeList(this.workflowId);
          }
        }
      });
    },
    handleFieldChange(row) {
      row.nodeKey = "";
      row.flowName = "";
      row.flowModel = 0;
      row.flowIndex = 1;
      if (row.name) {
        row.name = "";
      }
      this.handleClear(row);
    },
    getSubcomponentList(metaId) {
      let formFiled = this.formFieldList.find((filed) => filed.id == metaId);
      if (formFiled && formFiled.component) {
        if (formFiled && formFiled.component.componentName == "json_form") {
          if (formFiled.component.components) {
            let snExist = formFiled.component.components.some((item) => {
              return item.name == formFiled.component.key + "_序号";
            });
            if (!snExist) {
              formFiled.component.components.push({
                //excel导出增加序号字段
                name: formFiled.component.key + "_序号",
                title: "_序号",
              });
            }
          }
          return formFiled.component.components;
        } else if (
          formFiled &&
          formFiled.component.componentName == "reference_data"
        ) {
          return formFiled.component.columns.map((item) => {
            return {
              componentName: item.field.componentName,
              name: item.field.name,
              title: item.title,
            };
          });
        } else if (
          formFiled &&
          formFiled.component.componentName == "form_data"
        ) {
          let subComponentList = formFiled.component.columns.map((item) => {
            return {
              componentName: item.field.componentName,
              name: item.field.name,
              title: item.title,
            };
          });
          subComponentList.push({
            //excel导出增加序号字段
            name: formFiled.component.key + "_序号",
            title: "_序号",
          });
          return subComponentList;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    handleSave() {
      excelUpdateCellNew({
        formId: this.formId,
        excelId: this.formDetail.id,
        content: JSON.stringify(this.formDetail.contentField),
      })
        .then((resp) => {
          console.log(resp);
          this.$message.success("保存成功");
        })
        .catch((err) => {
          this.$message.warning("保存失败");
        });
    },
    async init() {
      await this.apiGetFormFieldMeta(this.formDetail.bizId);
    },
    async apiGetFormFieldMeta(formId) {
      let params = {
        tableName: formId || this.formId,
        returnWithSystemField: true,
      };
      await fetchMetaFieldList(params, ["shareLink"]).then((resp) => {
        this.formFieldList = resp.data.list;
      });
    },
    handleDownload() {
      if (this.formDetail && this.formDetail.id) {
        excelExport({ id: this.formDetail.id }).then(({ data }) => {
          let fileName = this.templateName + "模板.xlsx";
          this.handleBlobDownload(data.fileUrl, fileName);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 50px);
  padding: 5px;
  overflow: auto;

  .tools {
    padding: 10px 0;
  }
}
.fieldSetting {
  display: flex;
  flex-direction: column;
}
::v-deep .el-drawer__body {
  padding: 0 20px;
  font-size: 14px;
}
.subTitle {
  padding: 10px 0;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
