<template>
  <div>
    <attr-table-source
      v-model="component.tableName"
      :tableTitle="tableTitle"
      :idNotEquals="isNotEquals"
      :componentName="component.componentName"
      @changeSource="changeSource"
    ></attr-table-source>
    <attr-form-field
      :component="component"
      :component-list="componentList"
      :key="component.tableName + '_field'"
    ></attr-form-field>
    <!-- 关联数据初始化 -->
    <reference-data-init
      v-if="component.componentName == 'form_data' && hasCreate"
      :initFieldValues.sync="component.initFieldValues"
      :currentComponentList="formComponents"
      :componentList="componentList"
      :name="component.name"
    ></reference-data-init>
    <attr-filter
      :component="component"
      :params="params"
      :tableColumns="componentList"
      :key="component.tableName + '_filter'"
    ></attr-filter>
    <div class="attr-row">
      <div class="attr-label">显示数据条数</div>
      <el-radio-group v-model="multi">
        <el-radio :label="false">单条</el-radio>
        <el-radio :label="true">多条</el-radio>
      </el-radio-group>
      <div v-if="multi">
        <el-checkbox
          v-model="notPaging"
          :checked="component.hasPageTool === false"
          ><span style="font-size: 12px">无需分页</span></el-checkbox
        >
      </div>
    </div>
    <div class="attr-row" :key="component.tableName + '_tj'">
      <div class="attr-label" style="width: 100%">数据列表统计行设置</div>
      <div>
        <fields-select
          title="选择在底部行统计的字段"
          v-model="sumFields"
          :columns="sumFieldsColumns"
          :includeJsonForm="!parentField"
        >
          <el-button
            style="width: 100%; margin-bottom: 5px"
            slot="reference"
            size="mini"
            >设置列表中统计行字段
          </el-button>
        </fields-select>
        <div v-for="(item, index) in sumFields" :key="index">
          <div class="weui-col" style="font-size: 12px">
            <i
              v-if="item.componentName == 'json_form'"
              class="el-icon-arrow-down"
              style="font-size: 12px; margin-right: 5px"
            ></i>
            <div class="weui-title">{{ item.title }}</div>
            <div>
              <i class="el-icon-delete" @click="sumFieldsDelete(index, -1)"></i>
            </div>
          </div>
          <div
            v-if="item.componentName == 'json_form'"
            v-show="item.showAll"
            style="margin-left: 30px"
          >
            <div
              v-for="(child, cIndex) in item.subColumns"
              :key="cIndex"
              class="weui-col"
            >
              <div class="weui-title">{{ child.title }}</div>
              <div class="weui-col" style="font-size: 12px">
                <i
                  @click="sumFieldsDelete(index, cIndex)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sort-setting
      v-if="component.columns.length"
      :columns="component.columns"
      v-model="component.queryObject.sortParam"
      :key="component.tableName + '_sort'"
    ></sort-setting>

    <attr-table-action
      :component="component"
      :dataMode="dataModes"
      :key="component.tableName + '_action'"
    ></attr-table-action>
  </div>
</template>
<script>
import AttrTableAction from "./attr-table-action.vue";
import AttrTableSource from "./attr-table-source.vue";
import attrFormField from "@/views/lowCode/form/attribute/attr-form-field";
import { componentProps } from "./util";
import { getFormDetail, getDataflow } from "../api";
import attrFilter from "@/views/lowCode/form/attribute/attr-filter";
import FieldsSelect from "@/components/FieldsSelect";
import sortSetting from "@/views/lowCode/form/component/sort-setting/sort-setting";
import { getFormComponents } from "../utils";
import { isEmpty } from "@zgg-core-utils/utils";
import ReferenceDataInit from "@/views/lowCode/form/component/ReferenceDataInit";
import { fetchMetaComponents, fetchMetaFieldComponentList } from "@/api/form";
import { aggregateDetail } from "../../view/api";
export default {
  name: "attr-form-data",
  props: componentProps,
  provide() {
    return {
      getRelationComponentList: () => {
        // 获取关联表组件
        return this.componentList;
      },
    };
  },
  inject: ["getComponents"],
  components: {
    AttrTableSource,
    AttrTableAction,
    attrFormField,
    attrFilter,
    FieldsSelect,
    sortSetting,
    ReferenceDataInit,
  },
  data() {
    return {
      isNotEquals: "",
      tableTitle: "",
      componentList: [],
      notPaging: false,
      dataModes: "",
    };
  },
  watch: {
    notPaging(val) {
      let queryObject = this._.cloneDeep(this.component.queryObject);
      if (val) {
        queryObject.pageSize = 999;
      } else {
        queryObject.pageSize = 10;
      }
      this.$set(this.component, "queryObject", queryObject);
      this.$set(this.component, "hasPageTool", !val);
    },
  },
  computed: {
    multi: {
      get() {
        return this.component.queryObject.pageSize > 1;
      },
      set(val) {
        if (!val) {
          this.component.queryObject.pageSize = 1;
          this.component.hasPageTool = false;
        } else {
          this.component.queryObject.pageSize = 10;
          this.component.hasPageTool = true;
        }
      },
    },
    parentField() {
      let components = getFormComponents(this.getComponents());
      if (components.findIndex((item) => item.key == this.component.key) >= 0) {
        return;
      }
      return components
        .filter((item) => item.componentName == "json_form")
        .find(
          (item) =>
            item.components.findIndex(
              (child) => child.key == this.component.key
            ) >= 0
        );
    },
    sumFields: {
      get() {
        let allTableColumns = this.componentList;
        let fields = [];
        if (this.component.sumFields) {
          this.component.sumFields.forEach((item) => {
            if (allTableColumns && allTableColumns.length) {
              let field = this.getComponentByFieldName(
                item.fieldName,
                allTableColumns
              );
              // let field = allTableColumns.find(
              //     (col) => col.name == item.fieldName
              // );
              let subColumns =
                field && field.components && field.components.length
                  ? field.components
                      .filter((sub) => {
                        if (item.subFieldNames) {
                          return item.subFieldNames.includes(sub.name);
                        }
                        return false;
                      })
                      .map((s) => {
                        return { field: s, ...s };
                      })
                  : [];
              if (field) {
                fields.push({
                  field: field,
                  subColumns,
                  showAll: true,
                  ...field,
                });
              }
            }
          });
        }
        return fields;
      },
      set(val) {
        let sumFields = val.map((item) => {
          let sumFields = {
            fieldName: item.field.name,
          };
          if (item.subColumns && item.subColumns.length) {
            sumFields.subFieldNames = item.subColumns.map(
              (comp) => comp.field.name
            );
          }
          return sumFields;
        });
        this.$set(this.component, "sumFields", sumFields);
      },
    },
    sumFieldsColumns() {
      let list = [];
      this.component.columns.forEach((item) => {
        if (item.field.componentName == "input_number") {
          list.push({
            ...item.field.component,
            title: item.title,
          });
        } else if (item.field.componentName == "json_form") {
          let components = [];
          item.subColumns.forEach((child) => {
            if (child.field.componentName == "input_number") {
              components.push({
                ...child.field.component,
                title: child.title,
              });
            }
          });
          if (components.length) {
            list.push({
              ...item.field.component,
              title: item.title,
              components,
            });
          }
        }
      });
      return list;
    },
    formComponents() {
      return this.getComponents();
    },
    hasCreate() {
      return (
        this.component.allowActionEvents &&
        this.component.allowActionEvents.findIndex((item) => item == "add") >= 0
      );
    },
  },
  created() {
    this.isNotEquals = this.params.formId;
    if (!isEmpty(this.component.tableName)) {
      this.getDetail(this.component.tableName);
    }
  },
  methods: {
    /**
     * 根据name从formDetail中获取field配置
     * @param obj
     * @param list
     * @returns {*|string}
     */
    getComponentByFieldName(name, list) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.name == name) {
          return item;
        } else if (item.container) {
          let component = this.getComponentByFieldName(name, item.components);
          if (component) {
            return component;
          }
        }
      }
    },
    sumFieldsDelete(index, cIndex) {
      if (cIndex == -1) {
        this.component.sumFields.splice(index, 1);
      } else {
        this.component.sumFields[index].subFieldNames.splice(cIndex, 1);
        this.$nextTick(() => {
          if (this.component.sumFields[index].subFieldNames.length == 0) {
            this.component.sumFields.splice(index, 1);
          }
        });
      }
    },
    getDetail(tableName) {
      this.dataModes = this.component.dataMode;
      if (tableName.indexOf("dataflow_") == 0) {
        // 数据流
        this.component.allowActionEvents = ["detail"];
        getDataflow(tableName).then((res) => {
          this.tableTitle = res.data.dataflow.title;
          let output = res.data.dataflow.flow.stages.find(
            (item) => item.stageType == "output"
          );
          this.componentList = output.fields.map((item) => item.component);
        });
      } else if (tableName.indexOf("at_") == 0) {
        // 聚合表
        Promise.all([
          aggregateDetail({ collection: tableName, removeComponent: true }),
          fetchMetaComponents(tableName, true),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.aggregateTable.title;
          this.$set(this, "componentList", resp[1].data.list);
        });
      } else {
        Promise.all([
          getFormDetail(tableName, true),
          fetchMetaComponents(tableName, true),
        ]).then((resp) => {
          this.tableTitle = resp[0].data.form.title;
          this.$set(this, "componentList", resp[1].data.list);
        });
      }
    },

    changeSource(tableName, data) {
      if (data.dataMode) {
        this.dataModes = data.dataMode;
        this.component.dataMode = data.dataMode;
      } else {
        this.component.dataMode = this.dataModes = "";
      }
      this.component.columns = [];
      this.component.dataFilter = {
        rel: "and",
        advanceQuery: [],
      };
      this.component.tableColumns = [];
      this.component.queryObject.advanceQuery = [];
      this.component.tableName = tableName;

      this.getDetail(tableName);
    },
  },
};
</script>
<style lang="scss" scoped>
.weui-col {
  display: flex;
  align-items: center;

  .weui-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1.5;
  }
}
</style>
