<template>
  <div class="center">
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgb(0, 0, 0);
      "
    >
      <div class="logo">
        <img alt="" :src="avatar" />
      </div>

      <div style="display: flex; flex: 1; position: relative">
        <el-menu
          v-resize="calMore"
          style="
            height: 56px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            padding-right: 150px;
            flex: 1;
            width: 0;
          "
          active-text-color="#FFFFFF"
          background-color="#000000"
          class="el-menu-demo mainMenu"
          :default-active="defaultActive"
          mode="horizontal"
          :router="false"
          text-color="#C5C6D0"
          @select="handleSelect"
        >
          <template v-for="item in computedMenuList">
            <el-menu-item
              :key="item.id"
              class="menuItem mainMenuItem"
              :index="item.url"
            >
              <div
                class="menu-div"
                :style="
                  item.url == defaultActive
                    ? 'border-bottom: 2px solid #fff;'
                    : ''
                "
              >
                <div style="overflow: hidden; text-overflow: ellipsis">
                  {{ item.name }}
                </div>
              </div>
            </el-menu-item>
          </template>
        </el-menu>
        <el-menu
          v-if="isb"
          ref="subMenuRef"
          :menu-trigger="'click'"
          active-text-color="#FFFFFF"
          background-color="#000000"
          class="el-menu-demo menuList sub-menu-list"
          :default-active="defaultActive"
          mode="horizontal"
          :router="false"
          text-color="#C5C6D0"
          :style="{ left: left + 20 + 'px' }"
          style="
            width: 93px;
            height: 56px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 0;
            position: absolute;
          "
          @select="handleSelect"
        >
          <el-submenu class="menuItem" index="9999">
            <template slot="title">更多</template>
            <template v-for="(subItem, subIndex) in computedMenuList">
              <el-menu-item
                :key="subItem.id"
                :index="subItem.url"
                class="sub-menu-item"
                style="display: flex; justify-content: space-between"
                v-if="subIndex >= existing"
              >
                <span
                  :title="subItem.name"
                  style="overflow: hidden; text-overflow: ellipsis"
                >
                  {{ subItem.name }}
                </span>
              </el-menu-item>
            </template>
          </el-submenu>
        </el-menu>
      </div>
      <!-- <el-menu
        active-text-color="#FFFFFF"
        background-color="#000000"
        class="el-menu-demo"
        :default-active="defaultActive"
        mode="horizontal"
        :router="false"
        text-color="#C5C6D0"
        @select="handleSelect"
      >
        <el-menu-item index="/lowCode/form">表单设计</el-menu-item>
        <el-menu-item index="/lowCode/view">视图设计</el-menu-item>
        <el-menu-item v-if="showDash" index="/templateConfig"
          >目录配置</el-menu-item
        >
        <el-menu-item v-if="showDash" index="/lowCode/dashboard"
          >仪表盘</el-menu-item
        >
        <template v-if="!showMore">
          <el-menu-item
            index="/lowCode/aggregate-table"
            v-if="hasRoleAuth('ops_auth_menu_aggregation')"
            >聚合表</el-menu-item
          >
          <el-menu-item
            index="/lowCode/assistant"
            v-if="hasRoleAuth('ops_auth_menu_robot')"
            >智能助手</el-menu-item
          >
          <el-menu-item
            index="/lowCode/rpa"
            v-if="hasRoleAuth('ops_auth_menu_robot_v2')"
            >智能助手2.0</el-menu-item
          >
          <el-menu-item
            index="/lowCode/company-config"
            >单位设置</el-menu-item
          >
          <el-menu-item
            index="/lowCode/dataFactory"
            v-if="hasRoleAuth('ops_auth_menu_data_factory')"
            >数据工厂</el-menu-item
          >
          <el-menu-item
            index="/lowCode/open-platform"
            v-if="hasRoleAuth('ops_auth_menu_open')"
            >开放平台</el-menu-item
          >
          <el-menu-item
            index="/lowCode/unit-config"
            v-if="hasRoleAuth('ops_auth_menu_company_config')"
            >单位配置</el-menu-item
          >
        </template>
        <el-submenu v-else index="more">
          <template slot="title">更多</template>
          <el-menu-item v-if="!showDash" index="/templateConfig"
            >目录配置</el-menu-item
          >
          <el-menu-item v-if="!showDash" index="/lowCode/dashboard"
            >仪表盘</el-menu-item
          >
          <el-menu-item
            index="/lowCode/aggregate-table"
            v-if="hasRoleAuth('ops_auth_menu_aggregation')"
            >聚合表</el-menu-item
          >
          <el-menu-item
            index="/lowCode/assistant"
            v-if="hasRoleAuth('ops_auth_menu_robot')"
            >智能助手</el-menu-item
          >
          <el-menu-item
            index="/lowCode/rpa"
            v-if="hasRoleAuth('ops_auth_menu_robot_v2')"
            >智能助手2.0</el-menu-item
          >
          <el-menu-item index="/lowCode/company-config">单位设置</el-menu-item>
          <el-menu-item
            index="/lowCode/dataFactory"
            v-if="hasRoleAuth('ops_auth_menu_data_factory')"
            >数据工厂</el-menu-item
          >
          <el-menu-item
            index="/lowCode/open-platform"
            v-if="hasRoleAuth('ops_auth_menu_open')"
            >开放平台</el-menu-item
          >
          <el-menu-item
            index="/lowCode/unit-config"
            v-if="hasRoleAuth('ops_auth_menu_company_config')"
            >单位配置</el-menu-item
          >
        </el-submenu>
      </el-menu> -->
      <div class="nav-header">
        <template>
          <span style="font-size: 13px; color: #fff">当前单位：</span>
          <el-select
            @change="handleChange"
            v-model="currentCompanyId"
            size="medium"
            style="margin-right: 20px; width: 220px"
          >
            <el-option
              v-for="item in myCompanyList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
        </template>
        <div class="nav-right">
          <el-dropdown class="avatar-container" trigger="click">
            <div class="avatar-wrapper">
              <div class="name-avatar">{{ name.slice(-2) }}</div>
              <i
                style="color: #fff; margin-left: 3px"
                class="el-icon-caret-bottom"
              />
            </div>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
              <el-dropdown-item>
                {{ name }}
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">
                <span style="display: block">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-main class="main">
      <router-view></router-view>
    </el-main>
    <el-dialog title="单位配置" :visible.sync="dialogConfigVisible">
      <unit-config> </unit-config>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getPrivilegeForMe,
  listMyCompany,
  switchCompany,
} from "@/api/operationCenter";
import { getCookie, setCookie, removeCookie } from "@/utils/cookie-tools";
import unitConfig from "@/components/unitConfig.vue";
import { hasRoleAuth } from "@/utils/roleAuth";

export default {
  name: "Main",
  components: { unitConfig },
  data() {
    return {
      avatar: window.environment.platformLogo || "/static/avatar.png",
      active: "/directoryConfig",
      myCompanyList: [],
      defaultActive: "/lowCode/form",
      searchForm: {
        keyWord: "",
      },
      companyPage: {
        pageSize: 999,
        totalRow: 0,
        pageNumber: 1,
        totalPage: 1,
      },
      currentUser: {},
      currentCompanyId: "",
      showMore: false,
      showDash: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      status: "", //下拉选中
      dialogConfigVisible: false,
      menuList: [
        {
          id: 1,
          name: "表单设计",
          url: "/lowCode/form",
          roleAuth: "0",
        },
        {
          id: 2,
          name: "视图设计",
          url: "/lowCode/view",
          roleAuth: "0",
        },
        {
          id: 3,
          name: "目录配置",
          url: "/templateConfig",
          roleAuth: "0",
        },
        {
          id: 4,
          name: "仪表盘",
          url: "/lowCode/dashboard",
          roleAuth: "0",
        },
        {
          id: 5,
          name: "聚合表",
          url: "/lowCode/aggregate-table",
          roleAuth: "ops_auth_menu_aggregation",
        },
        {
          id: 6,
          name: "智能助手",
          url: "/lowCode/assistant",
          roleAuth: "ops_auth_menu_robot",
        },
        {
          id: 7,
          name: "智能助手2.0",
          url: "/lowCode/rpa",
          roleAuth: "ops_auth_menu_robot_v2",
        },
        {
          id: 8,
          name: "单位设置",
          url: "/lowCode/company-config",
          roleAuth: "",
        },
        {
          id: 9,
          name: "数据工厂",
          url: "/lowCode/dataFactory",
          roleAuth: "ops_auth_menu_data_factory",
        },
        {
          id: 10,
          name: "开放平台",
          url: "/lowCode/open-platform",
          roleAuth: "ops_auth_menu_open",
        },
        {
          id: 11,
          name: "单位配置",
          url: "/lowCode/unit-config",
          roleAuth: "ops_auth_menu_company_config",
        },
      ],

      isb: false, //导航栏显示更多
      existing: 0,
      left: 0,
      computedMenuList: [],
    };
  },
  async created() {
    this.defaultActive = this.$route.path;
    this.getMyCompanyList();
    this.currentUser = this.$store.state.user.currentUser;
    this.currentCompanyId = this.$store.state.user.currentCompanyId;
    await this.getRoleAuth(this.currentCompanyId);
  },
  mounted() {
    var _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      //_this.screenWidth = document.documentElement.clientWidth; //
      _this.calMore();
    };
    document.addEventListener("click", this.bodyCloseMenus);
  },

  computed: {
    ...mapGetters(["sidebar", "name"]),
  },
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.currentCompanyId = newVal;
      },
    },
    screenWidth: {
      handler: function (val) {
        if (val < 1600) {
          this.showMore = true;
          this.showDash = val >= 1200;
          //此刻屏幕宽度小于1600
        } else {
          this.showDash = true; //显示仪表盘
          this.showMore = false;
          //此刻屏幕宽度大于1600
        }
      },
      immediate: true,
    },
  },
  methods: {
    getMenuList() {
      const menuList = this.menuList;
      let menu = [];
      menuList.forEach((item) => {
        if (item.name == "单位设置") {
            menu.push(item);
        } else {
          console.log(
            this.hasRoleAuth(item.roleAuth),
            "HUSDF =-------------2222222",
          );
          if (
            item.roleAuth == "0" ||
            (item.roleAuth && this.hasRoleAuth(item.roleAuth))
          ) {
            menu.push(item);
          }
        }
      });

      this.computedMenuList = menu;
    },
    async getRoleAuth(companyId) {
      if (companyId) {
        await getPrivilegeForMe({
          companyId,
        }).then((res) => {
          this.$store.state.authItems = res.data?.model?.authItems;
          this.getMenuList();
          this.calMore();
          this.$forceUpdate();
        });
      }
    },
    hasRoleAuth(val) {
      return hasRoleAuth(val);
    },
    unitConfig() {
      this.dialogConfigVisible = true;
    },
    triggerException() {
      console.log("触发异常的", a.n.s);
      let underfinedValue = a.s.gbafd;
      console.log(underfinedValue);
      //写一个报错的代码
      a.push(1);
    },
    // 获取用户单位列表
    getMyCompanyList() {
      listMyCompany({
        keyword: this.searchForm.keyWord,
        pageNumber: this.companyPage.pageNumber,
        pageSize: this.companyPage.pageSize,
      })
        .then(({ data }) => {
          this.myCompanyList = data.page.list;
          this.$store.dispatch("user/setCompanyList", this.myCompanyList);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 切换单位
    async handleChange(companyId) {
      await this.getRoleAuth(companyId);
      let isEditUpdate = getCookie("form-edit-update"); // 表单设计是否修改
      let isViewUpdate = getCookie("form-view-update"); // 视图设计是否修改
      // 表单设计是否修改 || 视图设计是否修改
      if (isEditUpdate || isViewUpdate) {
        this.$confirm(
          "您有尚未保存的更改，是否需要保存这些更改？",
          "是否保存已有更改？",
          {
            confirmButtonText: "保存",
            cancelButtonText: "不保存",
            type: "warning",
          },
        )
          .then(() => {
            if (isEditUpdate) {
              this.$bus.$emit(
                "form-edit-save",
                "switch-company",
                companyId,
                this.switchCompany,
              );
            } else {
              this.$bus.$emit(
                "form-view-save",
                "switch-company",
                companyId,
                this.switchCompany,
              );
            }
          })
          .catch(() => {
            if (isEditUpdate) {
              removeCookie("form-edit-update");
            } else {
              removeCookie("form-view-update");
            }
            this.switchCompany(companyId);
          });
      } else {
        this.switchCompany(companyId);
      }
    },

    // 获取切换单位信息
    switchCompany(companyId) {
      switchCompany({
        companyId,
      })
        .then((res) => {
          console.log(res);
          this.$store.dispatch("user/setCompanyId", res.data.model.id);
          this.$store.dispatch("user/setCompanyName", res.data.model.name);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSelect(key, keyPath, type) {
      console.log(key, keyPath, type, "888888888");
      this.defaultActive = key;
      if (type != 1) {
        this.status = "";
      }

      let isEditUpdate = getCookie("form-edit-update"); // 表单设计是否修改
      let isViewUpdate = getCookie("form-view-update"); // 视图设计是否修改
      // 表单设计是否修改 || 视图设计是否修改
      if (isEditUpdate || isViewUpdate) {
        this.$confirm(
          "您有尚未保存的更改，是否需要保存这些更改？",
          "是否保存已有更改？",
          {
            confirmButtonText: "保存",
            cancelButtonText: "不保存",
            type: "warning",
          },
        )
          .then(() => {
            if (isEditUpdate) {
              this.$bus.$emit("form-edit-save", "menu-link", key);
            } else {
              this.$bus.$emit("form-view-save", "menu-link", key);
            }
          })
          .catch(() => {
            if (isEditUpdate) {
              removeCookie("form-edit-update");
            } else {
              removeCookie("form-view-update");
            }
            this.$router.push(key);
          });
      } else {
        this.$router.push(key);
      }
    },
    async logout() {
      localStorage.removeItem("AREA_TREE");
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login`);
    },

    /**
     * 计算是否显示更多
     */
    calMore() {
      this.$nextTick(() => {
        let menuitem = document.getElementsByClassName("mainMenuItem");
        let allWidth = 0;
        for (let i = 0; i < menuitem.length; i++) {
          const currentOffsetTop = menuitem[i].offsetTop;
          const currentOffsetWidth = menuitem[i].offsetWidth;
          if (currentOffsetTop > 0) {
            this.left = allWidth;
            this.existing = i;
            this.isb = true;
            break;
          } else {
            allWidth += currentOffsetWidth;
            this.isb = false;
          }
        }
        //处理更多中的子菜单被选中高亮后，移除的时候，更多高亮无法取消
        this.$refs.subMenuRef &&
          this.$refs.subMenuRef.updateActiveIndex(this.defaultActive);
      });
    },
    //点击空白处隐藏下拉菜单
    bodyCloseMenus(e) {
      if (this.isb) {
        if (
          (e.target.className && e.target.className === "el-submenu__title") ||
          e.target.className === "el-submenu__icon-arrow el-icon-arrow-down"
        ) {
          return;
        } else {
          this.isb = false;
          this.$nextTick(() => {
            this.isb = true;
          });
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.bodyCloseMenus);
  },
};
</script>

<style lang="scss" scoped>
.nav-right {
  margin-right: 20px;

  & > .avatar-wrapper {
    display: flex;
  }
}

.avatar-wrapper {
  display: flex;
  align-items: center;

  & > .name-avatar {
    background: var(--zgg-brand-color-6);
    width: 32px;
    height: 32px;
    border-radius: 50px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 32px;
  }
}

::v-deep .el-menu-demo.el-menu {
  display: flex;
  justify-content: center;
}

.main {
  //height: 100%;
  flex: 1;
  padding: 0;
  overflow: scroll;
  background-color: #f4f5f7;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.center {
  position: relative;
  height: 100%;
  min-width: var(--zgg-screen-lg);
  display: flex;
  flex-direction: column;
}

.logo {
  //position: absolute;
  //top: 15px;
  //left: 15px;
  z-index: 10;
  width: 300px;
  padding: 0 30px;

  & > img {
    width: 30px;
    height: 30px;
  }
}

.nav-header {
  display: flex;
  //position: absolute;
  //right: 0;
  color: #c5c6d0;
  //top: 15px;
  align-items: center;

  & ::v-deep .el-input__inner {
    background: none;
    border: 1px solid #757780;
    color: #fff;
  }
}
.el-dropdown {
  color: rgb(197, 198, 208);
}
.selected1 {
  color: #fff;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 10px;
}

::v-deep .el-dialog__body .el-table {
  min-height: 40vh;
  max-height: 50vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: solid 0px;
}

::v-deep .el-menu--horizontal > .el-menu-item {
  line-height: 56px;
  height: 56px;
  max-width: 25%;
  min-width: 100px;
  //overflow: hidden;
  text-overflow: ellipsis;
  // font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-color: transparent !important;
}

// ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title,
// ::v-deep .el-submenu__title i,
// .el-menu-item i:not(.el-icon-caret-bottom) {
//   font-size: 15px;
//   color: var(--zgg-font-text-primary);
// }

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  line-height: 56px;
  height: 56px;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  color: #fff !important;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  //  border-bottom: 2px solid #3870ea;
  color: #fff;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active i,
.el-menu-item i:not(.el-icon-caret-bottom) {
  color: #fff;
}
.el-icon-caret-bottom {
  color: #42526e;
  font-size: 15px;
}
.el-icon-caret-bottom:hover {
  //color: #3870ea;
}
::v-deep .el-menu-item [class^="el-icon-"] {
  width: auto;
}
.el-divider--vertical {
  margin: 16px 8px;
}

.flex-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-div {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.el-menu--horizontal {
  max-height: 500px;
  overflow: auto;
}
</style>
