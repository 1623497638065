<template>
  <div>
    <div style="margin-bottom: 10px">
      <el-radio-group
        v-model="model"
        @change="changeRadio"
        :disabled="disabled"
      >
        <el-radio label="one" style="display: block; margin-bottom: 8px"
          >新增一条记录</el-radio
        >
        <el-radio label="more" style="display: block"
          >基于多条记录逐条新增记录</el-radio
        >
      </el-radio-group>
    </div>
    <el-form-item v-if="model == 'more'" label="选择数据源">
      <el-select
        v-model="baseNodeKey"
        :placeholder="
          node.dataSource == 'node_data' && !node.nodeKey
            ? '请先选择节点'
            : '请选择数据源'
        "
        size="small"
        :disabled="node.dataSource == 'node_data' && !node.nodeKey"
      >
        <el-option
          v-for="opt in nodeList"
          :key="opt.key"
          :label="`${opt.title}【${opt.label}】`"
          :value="opt.key"
        ></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/utils";
import { fromNodesMixins } from "./rpa-utils";

export default {
  props: {
    node: Object,
  },
  mixins: [fromNodesMixins],
  computed: {
    disabled() {
      return this.$parent.disabled;
    },
    nodeList() {
      if (this.node.dataSource == "node_data" && isEmpty(this.node.nodeKey)) {
        return [];
      }
      let list = this.getParentNodes(["query_data_list"]);

      list = list.filter((item) => item.type == "query_data_list");
      list.forEach((item) => {
        if (item.dataSource == "node_data_field") {
          item.label = item.nodeField.title;
        } else {
          item.label = item.formTitle || item.collectionTitle;
        }
      });
      if (this.node.dataSource == "node_data") {
        list = list.filter((item) => item.key != this.node.nodeKey);
      }

      return list;
    },
    baseNodeKey: {
      get() {
        return this.node.baseNodeKey;
      },
      set(val) {
        this.$set(this.node, "baseNodeKey", val);
      },
    },
  },
  data() {
    return {
      model: "one",
    };
  },
  created() {
    if (!isEmpty(this.node.baseNodeKey)) {
      this.model = "more";
    } else {
      this.model = "one";
    }
  },
  methods: {
    changeRadio() {
      this.$set(this.node, "baseNodeKey", "");
    },
  },
};
</script>
