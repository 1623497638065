<template>
  <div style="display: flex; flex-direction: column">
    <div class="container-heard">
      <div class="header-title" v-if="activeName == 'first'" :title="formTitle">
        正在编辑：{{ formTitle }}
      </div>
      <div class="form-tab">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑字段" name="first"></el-tab-pane>
          <el-tab-pane label="表单设置" name="second"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="header flex-end" v-if="activeName == 'first'">
        <uploadExcelFromDisk
          @uploadSuccess="uploadSuccess"
          size="large"
          buttonType="default"
          style="margin-right: 10px"
        ></uploadExcelFromDisk>
        <el-button @click="previewForm" class="btn-mr">预览 </el-button>
        <el-button class="btn-mr" @click="back">取消 </el-button>

        <el-button
          @click="saveForm"
          :loading="saveLoading"
          type="primary"
          class="btn-mr"
          >保存
        </el-button>
      </div>
    </div>
    <div class="container" v-if="activeName == 'first'">
      <component-list @unchoose="unchoose2"></component-list>
      <div class="recycle-btn" v-if="hasFieldRecycleAuth()">
        <el-button
          type="text"
          class="iconfont icon-a-recycle-line"
          @click="showRecycleBin"
          >字段回收站</el-button
        >
      </div>
      <div class="content">
        <div
          class="form-body"
          id="formBody"
          style="padding: 32px 12px; min-height: 100%"
        >
          <el-form
            ref="form"
            :label-position="formComponent.labelPosition"
            style="height: 100%"
            :label-width="`${formComponent.labelWidth}px`"
            class="cust-form"
          >
            <draggable
              :list="this.formComponent.components"
              :group="colGroup2"
              animation="500"
              :scroll-sensitivity="100"
              fallback-tolerance="10"
              @choose="chooseDrag"
              @end="dragEnd"
              class="drag-flex"
              @add="onAdd"
            >
              <template v-for="(item, index) in formComponent.components">
                <edit-tabs
                  v-if="item.componentName == 'tab'"
                  :ref="`cpt_${item.key}`"
                  :key="item.key"
                  :index="index"
                  :data="item"
                  :style="`width:100%;`"
                  class="custom-form-item"
                  :isDrag="!!dragObj"
                  :cols="formComponent.cols"
                  :currentKey="currentKey"
                  :put="put"
                  :getError="getError"
                  :isSave="isSave"
                  :pasteSource="pasteSource"
                  :formId="formId"
                  @removeItem="removeItem"
                  @copyItem="copyItem"
                  :class="{
                    'is-checked': currentKey == item.key,
                    'is-error': !!getError(item) && isSave,
                    'copy-move': item.isCopyMove,
                  }"
                  @click.native="changeCurrNode(item, $event)"
                  @changeCurrNode="changeCurrNode"
                  @choose="chooseDrag"
                  @changePasteSource="changePasteSource"
                  @isOverflowSn="isOverflowSn"
                ></edit-tabs>
                <edit-grid
                  v-else-if="item.componentName == 'grid'"
                  :key="item.key"
                  :ref="`cpt_${item.key}`"
                  :component="item"
                  :index="index"
                  class="custom-form-item"
                  :put="put"
                  :isDrag="!!dragObj"
                  :form-config="formComponent"
                  :getError="getError"
                  :buildCopyItem="buildCopyItem"
                  :currentKey="currentKey"
                  :pasteSource="pasteSource"
                  :components="conponents"
                  :formId="formId"
                  @changeCurrNode="changeCurrNode"
                  @removeItem="removeItem"
                  @copyItem="copyItem"
                  @choose="chooseDrag"
                  @changePasteSource="changePasteSource"
                  :class="{
                    'is-checked': currentKey == item.key,
                    'is-error': !!getError(item) && isSave,
                    'copy-move': item.isCopyMove,
                  }"
                ></edit-grid>

                <reference-data
                  :key="item.key"
                  :ref="`cpt_${item.key}`"
                  v-else-if="
                    item.componentName == 'reference_data' ||
                    item.componentName == 'reference_data_list'
                  "
                  :index="index"
                  :data="item"
                  :isDrag="!!dragObj"
                  @removeItem="removeItem"
                  @copyItem="copyItem"
                  class="custom-form-item"
                  :class="{
                    'is-checked': currentKey == item.key,
                    'is-error': !!getError(item) && isSave,
                    'copy-move': item.isCopyMove,
                  }"
                  @click.native="changeCurrNode(item, $event)"
                  @changeCurrNode="changeCurrNode"
                  :width="handleItemWidth(formComponent, item)"
                ></reference-data>
                <component-item
                  v-else
                  :ref="`cpt_${item.key}`"
                  :key="item.key"
                  :index="index"
                  :data="item"
                  :width="handleItemWidth(formComponent, item)"
                  class="custom-form-item"
                  :isDrag="!!dragObj"
                  :put.sync="put"
                  :formId="formId"
                  @removeItem="removeItem"
                  @copyItem="copyItem"
                  :class="{
                    'is-checked': currentKey == item.key,
                    'is-error': !!getError(item) && isSave,
                    'copy-move': item.isCopyMove,
                  }"
                  :currentKey="currentKey"
                  @click.native="changeCurrNode(item, $event)"
                  @changeCurrNode="changeCurrNode"
                ></component-item>
              </template>
            </draggable>
          </el-form>
        </div>
      </div>
      <div class="attribute-box">
        <div class="header nav-tab">
          <div
            class="tab-txt"
            @click="switchAttTab('column')"
            :class="{ on: attTab == 'column' }"
          >
            字段属性
          </div>
          <div
            class="tab-txt"
            @click="switchAttTab('form')"
            :class="{ on: attTab == 'form' }"
          >
            表单属性
          </div>
        </div>
        <div class="form-body" style="margin-bottom: 24px">
          <form-attribute
            v-if="attTab == 'form'"
            :form="formComponent"
            :area-tree="areaTree"
            :componentList="formComponent.components"
            ref="formAttribute"
          ></form-attribute>
          <fieldDetail
            ref="colAttribute"
            v-else-if="currNode && attTab == 'column'"
            :component="currNode"
            :key="currNode.key"
            :params="{
              formId: $route.query.id,
              changeColumn: changeColumn,
              formTitle: formTitle,
            }"
            :form="formComponent"
            :area-tree="areaTree"
            @alterCurrNode="alterCurrNode"
          ></fieldDetail>
        </div>
      </div>
      <!-- copy的拖拽组件 -->
      <div
        v-if="dragObj && !dragObj.isNode"
        class="drag-obj"
        :style="`left:${dragObj.left}px;top:${dragObj.top}px;width:${
          dragObj.width + 3
        }px;`"
        v-text="dragObj.data.label"
      ></div>
      <form-preview ref="formPreview" :area-tree="areaTree"></form-preview>
    </div>
    <fieldAlias v-else></fieldAlias>
    <recycle-bin-dialog
      :visible.sync="recycleBinVisible"
      v-if="recycleBinVisible"
      title="字段回收站"
      type="field"
      @refresh="fetchDetail"
    ></recycle-bin-dialog>
    <!-- 添加子表单 -->
    <el-dialog
      title="添加子表单"
      :visible.sync="addJsonFormVisible"
      width="600px"
      custom-class="dialog-field"
      :append-to-body="true"
    >
      <div style="padding: 20px; height: 160px">
        <el-radio-group v-model="dataMode" class="custom-radio">
          <el-radio :label="'sub_form'">添加空白表</el-radio>
          <el-radio :label="'reference_form'">将已有表单作为子表单</el-radio>
        </el-radio-group>
        <template v-if="dataMode == 'reference_form'">
          <el-form
            ref="jsonForm"
            :model="jsonForm"
            label-width="80px"
            :rules="rules"
            style="padding-top: 10px"
          >
            <el-form-item label="表单名称" prop="value">
              <el-select
                v-model="jsonForm.value"
                style="width: 460px"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in formList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <div slot="footer">
        <el-button @click="addJsonFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addJsonForm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 删除组件 -->
    <el-dialog :visible.sync="delComponentVisible" width="560px" append-to-body>
      <div
        slot="title"
        style="display: flex; align-items: center; font-size: 18px"
      >
        <i
          class="el-icon-warning"
          style="color: #e6a23c; font-size: 22px; margin-right: 6px"
        ></i
        >确定删除该字段及对应表单数据？
      </div>
      <div style="padding: 20px">
        该字段被引用，确定删除该字段及对应表单数据？(删除后可在字段回收站中恢复)
      </div>
      <div slot="footer" class="dialog-footer">
        <el-link type="primary" :underline="false" @click="toReferenceDetail"
          >查看引用详情</el-link
        >
        <div>
          <el-button @click="delComponentVisible = false">取 消</el-button>
          <el-button type="primary" @click="finalRemoveItem">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 查看引用详情 -->
    <referenceDetail
      v-if="referenceVisible"
      :visible.sync="referenceVisible"
      :formId="formId"
      :referenceTitle="currNodeTitle"
      :source="'formField'"
      :fieldKey="currNodeKey"
    ></referenceDetail>
  </div>
</template>
<script>
import {
  getDataflow,
  getFormDetail,
  saveComponent,
  referenceRemoveCheck,
  fetchFormList,
} from "./api";
import FormAttribute from "./component/formAttribute";
import ComponentList from "./componentList";
import test from "@/utils/test";
import FormPreview from "./component/FormPreview";
import { getAreaTree } from "@/api/contact";
import Draggable from "vuedraggable";
import {
  existInComponentsByField,
  existInComponentsByName,
  getComponentWidth,
} from "./utils";
import uploadExcelFromDisk from "@/components/uploadExcelFromDisk";
import { getComponents, isEmpty } from "@zgg-core-utils/utils";
import fieldDetail from "@/views/lowCode/form/attribute/field-detail";
import { getCookie, setCookie, removeCookie } from "@/utils/cookie-tools";
import { fetchMetaFieldComponentList } from "@/api/form";
import { mapGetters } from "vuex";
import { checkFieldAbility } from "@zgg-core-utils/component-white-list";
import { conponents } from "./const";
import fieldAlias from "@/views/lowCode/open-platform/field-alias.vue";
import { _eq } from "@zgg-core-utils/relyUtil";
import { deepCopy } from "ali-oss/lib/common/utils/deepCopy";
import recycleBinDialog from "@/views/lowCode/form/recycle-bin-dialog.vue";
import referenceDetail from "./reference-detail";
import { hasRoleAuth } from "@/utils/roleAuth";

export default {
  components: {
    ComponentList,
    FormAttribute,
    FormPreview,
    Draggable,
    uploadExcelFromDisk,
    fieldDetail,
    fieldAlias,
    recycleBinDialog,
    referenceDetail,
  },
  data() {
    return {
      conponents,
      pasteSource: null,
      saveLoading: false,
      ismouseenter: false,
      isSave: false,
      isMousedown: false,
      dragObj: undefined,
      attTab: "column",

      formComponent: {
        cols: 1,
        labelPosition: "top",
        labelWidth: 84,
        displayFieldType: "keep",
        components: [],
        fieldDisplayRules: [],
        formCommitValidates: [],
        dataTitle: {
          type: "default",
          template: "",
        },
        dataIndexs: [
          {
            name: "",
            value: [],
            tips: "",
            type: "unique", // 暂时只有unique
            comment: "",
          },
        ],
        uniqueLabels: [],
      },
      currNode: undefined,
      areaTree: [],
      colGroup2: {
        name: "column",
      },
      formTitle: "",
      put: true,
      isCreated: false, // 页面周期开始标记
      hasSaved: false, // 是否点击保存
      activeName: "first",
      recycleBinVisible: false,
      addJsonFormVisible: false,
      dataMode: "sub_form", // 数据模式 默认sub_form:子表原始模式
      jsonForm: {},
      rules: {
        value: [
          {
            required: true,
            message: "表单名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      delComponentVisible: false,
      referenceVisible: false,
      currNodeKey: "",
      currNodeTitle: "",
      currNodeIndex: 0, // 当前组件索引
      draggableNewIndex: 0, // 拖动元素索引
    };
  },
  created() {
    //
    this.formId = this.$route.query.id;
    this.isCreated = true;
    this.getFormList();
    getAreaTree().then((res) => {
      let areaTree = [];
      let buildTree = (soruce) => {
        let obj = {};
        for (const [key, value] of Object.entries(soruce)) {
          if (key != "children") {
            obj[key] = value;
          }
        }
        if (soruce.children && soruce.children.length) {
          obj.children = [];
          soruce.children.forEach((item) => {
            let target = buildTree(item);
            obj.children.push(target);
          });
        }
        return obj;
      };
      res.data.tree.forEach((item) => {
        let target = buildTree(item);
        areaTree.push(target);
      });
      this.areaTree = areaTree;
    });
  },
  computed: {
    ...mapGetters(["sysFields"]),
    currentKey() {
      if (this.currNode) {
        return this.currNode.key;
      }
      return "";
    },
  },
  provide() {
    return {
      getComponents: this.getComponents,
      getFormEvents: this.getFormEvents,
      // 删除数据源前判断是否有被引用
      referenceRemoveCheck(params) {
        return referenceRemoveCheck(params);
      },
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        this.fetchDetail();
      },
    },
    formComponent: {
      deep: true,
      immediate: false,
      handler(val, oldVal) {
        if (this.isCreated) {
          this.isCreated = false;
        } else {
          setTimeout(() => {
            setCookie("form-edit-update", true);
          }, 99);
          if (this.hasSaved) {
            this.hasSaved = false;
            removeCookie("form-edit-update");
          }
        }
      },
    },
  },
  methods: {
    getFormList() {
      fetchFormList({
        pageSize: 999,
      }).then((res) => {
        this.formList = res.data?.page?.list;
      });
    },
    hasFieldRecycleAuth() {
      return hasRoleAuth("ops_auth_form_recycle");
    },
    showRecycleBin() {
      this.recycleBinVisible = true;
    },
    /**
     * 处理表单项宽度
     *  如果表单项单项已经设置了宽度，则获取配置的数据，如果没有配置单项，则获取表单全局设置的列数来计算宽度
     * @param formComponent
     * @param formItemConfig
     * @returns {string}
     */
    handleItemWidth(formComponent, formItemConfig) {
      return getComponentWidth(formItemConfig, formComponent.cols);
    },
    getComponents() {
      return this.formComponent.components;
    },
    getFormEvents() {
      return this.formComponent.formEvents ? this.formComponent.formEvents : [];
    },
    uploadSuccess(data) {
      let grid = data.grid;
      this.formComponent.components = [grid];
    },
    /**
     * 返回如果是嵌套路由，回到父路由
     */
    back() {
      let { matched } = this.$router.history.current;
      if (matched.length >= 2) {
        let parentPath = matched[matched.length - 2].path;
        this.$router.push({ path: parentPath });
      } else {
        this.$router.back();
      }
    },
    onClone() {},
    onStart() {},
    dragEnd(e) {
      this.currNode = this.formComponent.components[e.newIndex];
    },
    // 根据components中的name寻找并替换原组件
    replaceComponentByCompName(searchComponents, replaceComponent) {
      return searchComponents.find((item, index) => {
        if (item.name === replaceComponent.name) {
          this.$set(searchComponents, index, replaceComponent);
          return true;
        }

        if (item.components && item.components.length) {
          return this.replaceComponentByCompName(
            item.components,
            replaceComponent,
          );
        }
      });
    },
    alterCurrNode(component) {
      this.currNode = component;
      this.replaceComponentByCompName(this.formComponent.components, component);
    },
    onAdd(e) {
      this.draggableNewIndex = e.newIndex;
      this.isOverflowSn((res) => {
        if (res) {
          this.$message.warning("表单中仅可添加一个流水号字段");
          this.$delete(this.formComponent.components, e.newIndex);
        } else {
          let index = e.newIndex;
          this.currNode = this.initExtParam(
            this.formComponent.components[index],
          );
        }
      });

      this.isOverflowParent((res) => {
        if (res) {
          this.$message.warning("表单中仅可添加一个父级组件字段");
          this.$delete(this.formComponent.components, e.newIndex);
        }
      });

      // 子表单拖拽完要先弹窗选择
      if (this.currNode.componentName == "json_form") {
        let currNode = this._.cloneDeep(this.currNode);
        this.$delete(this.formComponent.components, e.newIndex);
        this.getFormList();
        this.currNode = this.formComponent.components[e.newIndex - 1];
        this.addJsonFormVisible = true;
        this.dataMode = "sub_form";
        this.jsonForm = {};
        this.componentName = currNode.componentName;
        this.getComponent = () => {
          return currNode;
        };
      }
    },
    /** 检测流水号组件是否超出数量限制 **/
    isOverflowSn(cb = () => {}) {
      let snInputComponents = 0;
      const res = this.formComponent.components.some((item) => {
        if (item.componentName == "sn_input") {
          snInputComponents += 1;
        }
        if (item.componentName == "tab") {
          item.components.some((pane) => {
            pane.components.some((component) => {
              if (component.componentName == "sn_input") {
                snInputComponents += 1;
              }
              return snInputComponents > 1;
            });
            return snInputComponents > 1;
          });
        }
        return snInputComponents > 1;
      });
      cb(res);
    },
    /** 检测父级组件字段是否超出数量限制 **/
    isOverflowParent(cb = () => {}) {
      const res =
        this.formComponent.components.filter(
          (item) => item.componentName == "parent_data",
        ).length > 1;
      cb(res);
    },
    onUpdate() {
      console.log("update");
    },
    changeColumn(index, node, name) {
      this.currNode = this.currNode = this.initExtParam(node);
    },
    // 删除组件
    async removeItem(index) {
      this.currNodeIndex = index;
      if (index >= 0) {
        // 非子表单
        let currNode = this.formComponent.components[index];
        this.currNodeKey = currNode.key;
        this.currNodeTitle = currNode.title;
        let params = {
          collection: this.$route.query.id,
          type: "form",
          fieldKey: currNode.key,
        };
        let checkResult = await referenceRemoveCheck(params);
        if (checkResult?.data?.beReferred) {
          // 有引用关系
          this.delComponentVisible = true;
        } else {
          this.$confirm("确定删除该字段及对应表单数据？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.formComponent.components.splice(index, 1);
              this.currNode = undefined;
            })
            .catch(() => {});
        }
      } else {
        // 子表单
        this.currNode = undefined;
      }
    },
    // 存在引用关系时删除组件
    finalRemoveItem() {
      this.delComponentVisible = false;
      this.formComponent.components.splice(this.currNodeIndex, 1);
      this.currNode = undefined;
    },
    // 查看引用详情
    toReferenceDetail() {
      this.referenceVisible = true;
    },
    copyItem(index) {
      let obj = this._.cloneDeep(this.formComponent.components[index]);
      obj["title"] = this.copyDuplicateNameHandle(obj["title"]);
      this.buildCopyItem(obj);
      this.formComponent.components.splice(index + 1, 0, obj);
      this.currNode = this.formComponent.components[index + 1];
      this.isOverflowSn((res) => {
        if (res) {
          this.$message.warning("表单中仅可添加一个流水号字段");
          this.$delete(this.formComponent.components, index);
        }
      });
    },
    /** 复制重名处理 **/
    copyDuplicateNameHandle(title) {
      while (true) {
        const isDuplicate = this.formComponent.components.some(
          (componentDetail) => componentDetail.title === title,
        );

        if (isDuplicate) {
          title += "-复制";
        } else {
          return title;
        }
      }
    },
    buildCopyItem(obj) {
      delete obj.name;
      let uuid = this.getUuid();
      obj.key = uuid;
      if (obj.form) {
        obj.name = uuid;
      }
      if (obj.componentName === "json_form") {
        if (obj?.dataMode !== "reference_form") {
          if (obj.components) {
            obj.components.forEach((item) => {
              this.buildCopyItem(item);
            });
          }
        }
      } else {
        if (obj.components) {
          obj.components.forEach((item) => {
            this.buildCopyItem(item);
          });
        }
      }
    },
    previewForm() {
      if (!this.formComponent.components.length) {
        this.$message.error("请先添加表单组件");
        return;
      }
      this.$refs.formPreview.init(this.formComponent);
    },
    getError(item) {
      if (
        !["tab", "grid_col", "grid_row"].includes(item.componentName) &&
        isEmpty(item.title)
      ) {
        return "组件标题不能为空";
      }
      // if (test.isEmpty(item.name)) {
      //   return "表单name不能为空";
      // }
      if (item.componentName == "date_picker") {
        if (test.isEmpty(item.picker)) {
          return "日期选择器类型不能为空";
        }
      }

      if (item.componentName == "form_data") {
        if (test.isEmpty(item.tableName)) {
          return `请设置【${item.title}】关联表`;
        }
        if (!item.columns.length) {
          return `请设置【${item.title}】表单中显示字段`;
        }

        if (item.columns.findIndex((item) => item.delete) >= 0) {
          let col = item.columns.find((item) => item.delete);
          return `【${item.title}】关联查询的显示字段【${col.title}】被删除`;
        }
        if (
          item.dataFilter &&
          item.dataFilter.advanceQuery &&
          item.dataFilter.advanceQuery.findIndex(
            (item) => item.relyFieldDelete,
          ) >= 0
        ) {
          return `【${item.title}】关联表的条件字段被删除`;
        }
        if (
          item.dataFilter &&
          item.dataFilter.advanceQuery &&
          item.dataFilter.advanceQuery.findIndex((item) => item.fieldDelete) >=
            0
        ) {
          return `【${item.title}】的数据过滤条件中当前表单字段被删除`;
        }
      }
      if (item.componentName == "reference_data") {
        if (test.isEmpty(item.tableName)) {
          return `请设置【${item.title}】关联表`;
        }

        // if (!item.columns.length && item.chooseMode != "select") {
        //   return `请设置【${item.title}】表单中显示字段`;
        // }

        if (item.chooseMode != "includeForm") {
          let str = "下拉列表";
          if (item.chooseMode == "popup") {
            str = "弹窗列表";
          }
          if (!(item.tableColumns && item.tableColumns.length)) {
            return `请设置【${item.title}】${str}字段`;
          }
          if (item.tableColumns.findIndex((item) => item.delete) >= 0) {
            return `【${item.title}】关联表的${str}字段被删除`;
          }
        }

        if (item.columns.findIndex((item) => item.delete) >= 0) {
          return `【${item.title}】关联表的显示字段被删除`;
        }
        if (
          item.dataFilter &&
          item.dataFilter.advanceQuery &&
          item.dataFilter.advanceQuery.findIndex(
            (item) => item.relyFieldDelete,
          ) >= 0
        ) {
          return `【${item.title}】关联表的条件字段被删除`;
        }

        if (
          item.dataFilter &&
          item.dataFilter.advanceQuery &&
          item.dataFilter.advanceQuery.findIndex((item) => item.fieldDelete) >=
            0
        ) {
          return `【${item.title}】当前表单的查询字段被删除`;
        }
        if (item.filledRule.findIndex((item) => item.relyFieldDelete) >= 0) {
          return `【${item.title}】关联表的填充字段被删除`;
        }
        if (item.filledRule.findIndex((item) => item.fieldDelete) >= 0) {
          return `【${item.title}】当前表单的被填充字段被删除`;
        }
      }
      if (item.componentName == "reference_data_list") {
        if (test.isEmpty(item.tableName)) {
          return `请设置【${item.title}】关联表`;
        }

        // if (!item.columns.length && item.chooseMode != "select") {
        //   return `请设置【${item.title}】表单中显示字段`;
        // }

        if (item.chooseMode != "includeForm") {
          let str = "下拉列表";
          if (item.chooseMode == "popup") {
            str = "弹窗列表";
          }
          if (!(item.tableColumns && item.tableColumns.length)) {
            return `请设置【${item.title}】${str}字段`;
          }
          if (item.tableColumns.findIndex((item) => item.delete) >= 0) {
            return `【${item.title}】关联表的${str}字段被删除`;
          }
        }

        if (item.columns.findIndex((item) => item.delete) >= 0) {
          return `【${item.title}】关联表的显示字段被删除`;
        }
        if (
          item.dataFilter &&
          item.dataFilter.advanceQuery &&
          item.dataFilter.advanceQuery.findIndex(
            (item) => item.relyFieldDelete,
          ) >= 0
        ) {
          return `【${item.title}】关联表的条件字段被删除`;
        }

        if (
          item.dataFilter &&
          item.dataFilter.advanceQuery &&
          item.dataFilter.advanceQuery.findIndex((item) => item.fieldDelete) >=
            0
        ) {
          return `【${item.title}】当前表单的查询字段被删除`;
        }
      }

      // 按钮组件提交校验
      if (item.componentName == "button") {
        if (test.isEmpty(item.buttonText)) {
          return `请设置【${item.title}】文案`;
        }

        let buttonEvent = item.buttonEvent;
        // 新增数据
        if (buttonEvent == "formEvent") {
          if (test.isEmpty(item.eventConfig.formId)) {
            return `请设置【${item.title}】关联表`;
          }
        }

        // 执行智能助手
        if (buttonEvent == "robotEvent") {
          if (test.isEmpty(item.eventConfig.robotId)) {
            return `请设置【${item.title}】智能助手`;
          }
        }

        // 添加前端事件
        if (buttonEvent == "frontEvent") {
          if (item.eventConfig.length == 0) {
            return `请设置【${item.title}】前端事件`;
          }
        }

        // 打开链接
        if (buttonEvent == "hrefEvent") {
          if (test.isEmpty(item.eventConfig.url)) {
            return `请设置【${item.title}】链接地址`;
          }
        }

        // 勾选二次确认时校验的内容
        if (item.confirm) {
          if (test.isEmpty(item.confirmConfig.title)) {
            return `请设置【${item.title}】提示标题`;
          }
          if (test.isEmpty(item.confirmConfig.description)) {
            return `请设置【${item.title}】提示内容`;
          }
          if (test.isEmpty(item.confirmConfig.okText)) {
            return `请设置【${item.title}】确认按钮文案`;
          }
          if (test.isEmpty(item.confirmConfig.cancelText)) {
            return `请设置【${item.title}】取消按钮文案`;
          }
        }
      }

      if (item.componentName == "summary_data") {
        if (test.isEmpty(item.tableName)) {
          return `请设置【${item.title}】关联表`;
        }

        // todo 待优化
        if (item.metric.field) {
          if (test.isEmpty(item.metric.field.comment)) {
            return `请设置【${item.title}】汇总字段`;
          }
        } else {
          return `请设置【${item.title}】汇总字段`;
        }

        if (test.isEmpty(item.metric.oper)) {
          return `请设置【${item.title}】汇总方式`;
        }
      }

      // 级联组件校验
      if (item.componentName == "cascade_data") {
        if (test.isEmpty(item.tableName)) {
          return `请设置【${item.title}】关联表`;
        }
        if (test.isEmpty(item.referenceFieldName)) {
          return `请设置【${item.title}】数据字段`;
        }
        if (test.isEmpty(item?.field?.name)) {
          return `请设置【${item.title}】下拉框显示字段`;
        }
      }

      let arr = ["select", "radio_group", "checkbox_group", "select_checkbox"];
      if (arr.includes(item.componentName)) {
        if (item.optionSource == "custom") {
          // 静态数据
          if (item.customOptions.length == 0) {
            return `【${item.title}】静态数据源不能为空`;
          }
          let isRepeat = false;
          for (let index = 0; index < item.customOptions.length; index++) {
            const element = item.customOptions[index];
            isRepeat =
              item.customOptions.filter((o) => o.value == element.value)
                .length >= 2;
            if (isRepeat) {
              break;
            }
          }
          if (isRepeat) {
            return `【${item.title}】静态数据有重复的数据选`;
          }
        } else if (item.optionSource == "metaField") {
          // 关联表单
          if (!item.metaFieldOption.tableName) {
            return `【${item.title}请选择关联的表单】`;
          }
        }
      }

      if (item.componentName == "json_form") {
        if (item.components.length == 0) {
          return `请配置【${item.title}的子字段】`;
        }
        let error;
        for (let i = 0; i < item.components.length; i++) {
          const element = item.components[i];
          error = this.getError(element);
          if (error) {
            break;
          }
        }
        return error;
      }
      if (item.container && item.components) {
        let error;
        for (let i = 0; i < item.components.length; i++) {
          const element = item.components[i];
          error = this.getError(element);

          if (error) {
            break;
          }
        }
        return error;
      }
      return;
    },
    buildColumns(list) {
      let names = [
        "input",
        "text_area",
        "input_number",
        "date_picker",
        "radio_group",
        "checkbox_group",
        "select",
        "address_input",
      ];
      let arr = [];

      list.forEach((item) => {
        if (
          names.includes(item.componentName) &&
          item.name &&
          item.name != this.name
        ) {
          arr.push(this._.cloneDeep(item));
        } else if (item.componentName == "json_form") {
          item.components
            .filter((col) => col.name && col.name != this.name)
            .forEach((col) => {
              if (names.includes(col.componentName)) {
                arr.push({
                  ...this._.cloneDeep(col),
                  title: item.title + "." + col.title,
                  parentName: item.name,
                });
              }
            });
        } else if (item.componentName == "tab") {
          item.components.forEach((tab) => {
            arr = arr.concat(this.buildColumns(tab.components));
          });
        }
      });

      return arr;
    },
    saveForm(callback) {
      if (!this.formComponent.cols) {
        this.$message.error("请在【表单属性】中输入【表单列数】");
        return;
      }
      if (!this.formComponent.labelPosition) {
        this.$message.error("请在【表单属性】中选择【标签位置】");
        return;
      }
      if (!this.formComponent.labelWidth) {
        this.$message.error("请在【表单属性】中输入【标签宽度】");
        return;
      }
      if (this.formComponent.components.length == 0) {
        this.$message.error("请先设计表单组件");
        return;
      }

      this.isSave = true;
      let error;
      for (
        let index = 0;
        index < this.formComponent.components.length;
        index++
      ) {
        const element = this.formComponent.components[index];
        error = this.getError(element);
        if (error) {
          break;
        }
      }

      if (error) {
        this.$message.error(error);
        return;
      }

      if (
        this.formComponent.dataTitle &&
        this.formComponent.dataTitle.type == "default"
      ) {
        let item = this.getTpl(this.formComponent.components);
        if (item) {
          this.formComponent.dataTitle.template = `#{${item.name}}`;
        } else {
          this.formComponent.dataTitle.template = `#{createrId}`;
        }
      } else {
        if (
          this.formComponent.dataTitle &&
          test.isEmpty(this.formComponent.dataTitle.template)
        ) {
          this.attTab = "form";
          this.$message.error("请设置数据标题");
          return;
        }
        let patt1 = /#{[a-zA-Z0-9.]+}/gm;
        let arr = this.formComponent.dataTitle.template.match(patt1);
        if (!arr) {
          arr = [];
        }
        arr = arr.map((val) => val.substring(2, val.length - 1));

        if (arr.length == 0) {
          this.attTab = "form";
          this.$message.error("自定义的数据标题必须包含一个表单字段");
          return;
        }
        let isDelete = false;

        arr.forEach((name) => {
          if (
            !(
              ["createrId", "createdTime", "updatedTime"].includes(name) ||
              existInComponentsByName(name, this.formComponent.components)
            )
          ) {
            isDelete = true;
          }
        });
        if (isDelete) {
          this.attTab = "form";
          this.$message.error(
            "自定义的数据标题包含一个已删除的表单字段，请重新设置数据标题",
          );
          return;
        }
      }

      let components = this._.cloneDeep(this.formComponent.components);
      let columns = this.buildColumns(components);

      let resetComponents = (list) => {
        list.forEach((item) => {
          if (item.dataLinkage && Object.keys(item.dataLinkage).length == 0) {
            this.$set(item, "dataLinkage", null);
            // delete item.dataLinkage;
          }
          if (item.rely && item.rely.originalFormula) {
            if (item.rely.originalFormula.length == 0) {
              this.$set(item, "rely", null);
            }
          }
          if (this.currNode && this.currNode.key == item.key) {
            this.currNode = item;
          }
          if (item.componentName == "json_form") {
            resetComponents(item.components);
          } else if (item.componentName == "tab") {
            item.components.forEach((row) => {
              resetComponents(row.components);
            });
          }
        });
      };

      resetComponents(this.formComponent.components);

      let rebuildComponents = (list) => {
        list.forEach((item) => {
          delete item.label;
          if (item.dataLinkage && Object.keys(item.dataLinkage).length == 0) {
            item.dataLinkage = null;
          }
          if (item.rely && item.rely.originalFormula) {
            if (item.rely.originalFormula.length) {
              item.rely.originalFormula.forEach((row) => {
                row.forEach((child) => {
                  if (child.type == "field") {
                    let obj = columns.find((col) => col.name == child.name);
                    if (obj) {
                      child.value = obj.title;
                    }
                  }
                });
              });
            } else {
              item.rely = null;
            }
          }
          if (item.componentName == "json_form") {
            rebuildComponents(item.components);
          } else if (item.componentName == "tab") {
            item.components.forEach((row) => {
              rebuildComponents(row.components);
            });
          }
        });
      };
      rebuildComponents(components);

      let component = {
        ...this.formComponent,
        components,
        componentName: "form",
      };
      if (
        this.$refs["formAttribute"] &&
        this.$refs["formAttribute"].formEvents
      ) {
        component.formEvents = this.$refs["formAttribute"].formEvents;
      }

      // 组合字段唯一值与提示, 转成后端需要的格式
      const copyUniqueGroup = deepCopy(this.formComponent.dataIndexs[0]);
      copyUniqueGroup.value = copyUniqueGroup.value.join(",");
      copyUniqueGroup.comment = this.formComponent.uniqueLabels.join(",");
      if (copyUniqueGroup.name === "") {
        copyUniqueGroup.name = this.getUuid();
      }
      component.dataIndexs = [copyUniqueGroup];
      let data = {
        formId: this.$route.query.id,
        component: component,
      };

      this.saveLoading = true;
      saveComponent(data)
        .then((res) => {
          this.isSave = false;
          this.saveLoading = false;
          this.$message.success("表单保存成功");
          this.fetchDetail();
          removeCookie("form-edit-update");
          this.hasSaved = true;
          if (typeof callback === "function") {
            callback();
          }
          // this.$router.back();
        })
        .catch(() => {
          this.isSave = false;
          this.saveLoading = false;
        });
    },
    changeCurrNode(node, event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      // todo 后台设置组件不可编辑[开发人员数据库设置](目前只会针对用户单选，暂时处理选择则字段属性隐藏)
      if (node?.extParam && node?.extParam.disableEdit) {
        this.currNode = undefined;
      } else {
        this.currNode = this.initExtParam(node);
      }
    },
    initExtParam(node) {
      let customWidth;

      if (!(node.extParam && node.extParam.customWidth)) {
        if (["json_form", "tab"].includes(node.componentName)) {
          customWidth = "1/1";
        } else if (this.formComponent.cols) {
          customWidth = 1 + "/" + this.formComponent.cols;
        }
      }
      if (!node.extParam) {
        this.$set(node, "extParam", {
          customWidth: customWidth,
        });
      }
      if (!node.extParam.customWidth) {
        this.$set(node.extParam, "customWidth", customWidth);
      }

      // 需要支持搜索的组件
      let needSearchArr = [
        "input",
        "text_area",
        "input_number",
        "radio_group",
        "checkbox_group",
        "select",
        "sn_input",
        "select_checkbox",
        "mobile_input",
      ];
      if (
        needSearchArr.includes(node.componentName) &&
        typeof node.keywordSearched == "undefined"
      ) {
        this.$set(node, "keywordSearched", true);
      }
      return node;
    },
    getTpl(componentList) {
      if (!componentList) return;
      let obj;
      let exitArr = [
        "json_form",
        "image_uploader",
        "attachment_uploader",
        "sign_input",
        "reference_data",
        "parent_data",
      ];
      for (let i = 0; i < componentList.length; i++) {
        let element = componentList[i];
        if (element.form) {
          if (!exitArr.includes(element.componentName)) {
            obj = element;
            break;
          }
        } else if (element.container) {
          let tpl = this.getTpl(element.components);
          if (tpl) {
            obj = tpl;
            break;
          }
        }
      }
      return obj;
    },
    switchAttTab(name) {
      if (this.attTab == name) {
        return;
      }
      this.attTab = name;
    },
    /**
     * todo 修复配置错误，后期删除
     * @param item
     */
    fixConfigError(item) {
      //修复json和array初始值初始化错误
      if (
        item.defaultValue &&
        item.json &&
        this._.isArray(item.defaultValue) &&
        this._.isEmpty(item.defaultValue)
      ) {
        item.defaultValue = {};
      }
      if (
        item.defaultValue &&
        item.array &&
        this._.isPlainObject(item.defaultValue) &&
        this._.isEmpty(item.defaultValue)
      ) {
        item.defaultValue = [];
      }
      //修复子表单的json属性为true的bug
      if (item.componentName == "json_form" && item.json) {
        item.json = false;
      }
    },
    async fetchDetail() {
      let res = await getFormDetail(this.$route.query.id);
      res.data.form.component.components.forEach((item) => {
        if (
          !this._.isEmpty(item.defaultValue) &&
          typeof item.defaultValue === "string"
        ) {
          if (item.array || item.json) {
            item.defaultValue = JSON.parse(item.defaultValue);
          }
        }
        // 修复默认值为数组或对象时，组件类型不正确的问题
        this.fixConfigError(item);
        //*************修复结束代码
        if (typeof item.defaultValue === "undefined") {
          if (item.array) {
            item.defaultValue = [];
          } else if (item.json) {
            item.defaultValue = {};
          } else {
            item.defaultValue = "";
          }
        }
        if (item.componentName == "input_number") {
          if (typeof item.format === "undefined") {
            item.format = "";
          }
        }
      });

      if (res.data.form.component) {
        this.formComponent = {
          ...this.formComponent,
          ...res.data.form.component,
        };
        if (!this.formComponent.dataTitle) {
          this.formComponent.dataTitle = {
            type: "default",
            template: "",
          };
        }
        // 组合字段唯一值与提示, 格式化数据
        this.formComponent.dataIndexs[0].value =
          this.formComponent.dataIndexs[0].value.length === 0
            ? []
            : this.formComponent.dataIndexs[0].value.split(",");

        this.formComponent.uniqueLabels =
          this.formComponent.dataIndexs[0].comment.length === 0
            ? []
            : this.formComponent.dataIndexs[0].comment.split(",");
      }

      if (!this.formComponent.components) {
        this.formComponent.components = [];
      }
      //添加表单详情
      let components = this.formComponent.components;

      if (components && components.length) {
        let list = [];
        let buildList = (componentList) => {
          if (componentList) {
            componentList.forEach((item) => {
              if (
                item.componentName == "reference_data" ||
                item.componentName == "form_data"
              ) {
                list.push(item.tableName);
              } else if (item.container) {
                buildList(item.components);
              }
            });
          }
        };
        buildList(this.formComponent.components);

        let set = new Set(list);
        list = [...set];
        let viewList = {};
        if (list.length) {
          let arr = [];
          list.forEach((val) => {
            if (val.indexOf("dataflow_") == 0) {
              arr.push(getDataflow(val));
            } else if (val.indexOf("at_") == 0) {
              arr.push(fetchMetaFieldComponentList(val, []));
            } else {
              arr.push(
                fetchMetaFieldComponentList(
                  val,
                  this.sysFields.map((item) => item.name),
                ),
              );
            }
          });
          try {
            let resultArr = await Promise.all(arr);
            resultArr.forEach((res, index) => {
              let formId = list[index];
              if (formId.indexOf("dataflow_") == 0) {
                // 数据流
                let output = res.data.dataflow.flow.stages.find(
                  (item) => item.stageType == "output",
                );

                let componentList = output.fields.map((item) => item.component);
                viewList[list[index]] = componentList;
              } else if (formId.indexOf("at_") == 0) {
                // 聚合表
                let componentList = res.data.list;
                viewList[list[index]] = componentList;
              } else {
                // 表单
                viewList[list[index]] = res.data.list;
              }
            });
          } catch (error) {}
          this.viewList = viewList;
        }

        this.buildRefrenceItem(components);

        components.forEach((item) => {
          if (item.componentName == "input_number") {
            if (!item.extParam) {
              item.extParam = {};
            }
          }
          if (
            item.componentName == "checkbox_group" ||
            item.componentName == "address_input" ||
            item.componentName == "select_checkbox"
          ) {
            if (
              typeof item.defaultValue === "string" &&
              !this._.isEmpty(item.defaultValue)
            ) {
              item.defaultValue = JSON.parse(item.defaultValue);
            }
          }
          if (item.componentName == "form_data") {
            item.columns.forEach((col) => {
              col.showAll = true;
            });
          }
          if (
            item.componentName === "position_input" &&
            item.defaultCurrentPosition === false
          ) {
            item.defaultCurrentPosition = "";
          }
        });

        this.formComponent.components = components ? components : [];
        if (this.currNode) {
          let currNode = this.getCurrNode(components);
          this.$set(this, "currNode", currNode);
        }
      }
      this.formTitle = res.data.form.title;
      setTimeout(() => {
        removeCookie("form-edit-update");
      }, 110);
    },
    getCurrNode(componentList) {
      let currNode = componentList.find(
        (item) => item.key == this.currNode.key,
      );
      if (currNode) {
        return currNode;
      } else {
        let list = componentList.filter((item) => item.components);
        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          currNode = this.getCurrNode(element.components);
          if (currNode) {
            break;
          }
        }
        return currNode;
      }
    },
    buildRefrenceItem(components) {
      components.forEach((item) => {
        if (!item.key) {
          item.key = this.getUuid();
        }
        if (item.components) {
          this.buildRefrenceItem(item.components);
        } else {
          if (["reference_data", "form_data"].includes(item.componentName)) {
            // 初始化检查关联表字段是否被删除
            let sourceComponents;
            if (this.viewList) {
              sourceComponents = this.viewList[item.tableName];
            } else {
              sourceComponents = [];
            }
            if (!sourceComponents) {
              sourceComponents = [];
            }
            if (item.componentName == "reference_data") {
              item.filledRule.forEach((row) => {
                row.relyFieldDelete = !existInComponentsByName(
                  row.relyFieldName,
                  sourceComponents,
                );
              });
              item.columns.forEach((row) => {
                row.delete = !existInComponentsByField(
                  {
                    fieldName: row.field.name,
                    referenceName: row.referenceName,
                  },
                  sourceComponents,
                );

                if (row.field.componentName == "json_form") {
                  row.subColumns.forEach((child) => {
                    if (row.delete) {
                      child.delete = true;
                    } else {
                      let parent = sourceComponents.find(
                        (child) => child.name == row.field.name,
                      );
                      child.delete =
                        parent.components.findIndex(
                          (c) => c.name == child.field.name,
                        ) == -1;
                    }
                  });
                }
              });
              if (item.tableColumns) {
                item.tableColumns.forEach((row) => {
                  row.delete = !existInComponentsByField(
                    {
                      fieldName: row.field.name,
                      referenceName: row.referenceName,
                    },
                    sourceComponents,
                  );
                });
              }

              if (item.dataFilter && item.dataFilter.advanceQuery) {
                item.dataFilter.advanceQuery.forEach((row) => {
                  row.relyFieldDelete = !existInComponentsByField(
                    { fieldName: row.key, referenceName: row.referenceName },
                    sourceComponents,
                  );
                });
              }
            } else if (item.componentName == "form_data") {
              item.columns.forEach((row) => {
                row.delete = !existInComponentsByField(
                  {
                    fieldName: row.field.name,
                    referenceName: row.referenceName,
                  },
                  sourceComponents,
                );
              });
            }
            if (item.dataFilter && item.dataFilter.advanceQuery) {
              item.dataFilter.advanceQuery.forEach((row) => {
                if (
                  row.valueType == "tableField" &&
                  ![
                    "_id",
                    "defaultCurrentUser",
                    "defaultCurrentUserDepartment",
                  ].includes(row.value) &&
                  !isEmpty(row.value)
                ) {
                  row.fieldDelete = !existInComponentsByName(
                    row.value,
                    this.formComponent.components,
                  );
                }
              });
            }
          }
        }
      });
    },
    chooseDrag() {
      let arr = [
        "divider",
        "html",
        "html_input",
        "tab",
        "form_data",
        "reference_data_list",
        "summary_data",
        "grid",
        "button",
        "json_form",
      ];
      if (arr.includes(this.currNode?.componentName)) {
        //子表单无法使用拖拽方式添加以上组件
        this.put = false;
      } else {
        this.put = true;
      }
    },
    changePasteSource(obj) {
      this.$set(this, "pasteSource", obj);
    },
    getParent(componentList, key) {
      if (componentList.findIndex((item) => item.key == key) >= 0) {
        return true;
      }
      let obj;
      componentList
        .filter((item) => item.components)
        .forEach((item) => {
          let o = this.getParent(item.components, key);
          if (o) {
            if (o === true) {
              obj = item;
            } else {
              obj = o;
            }
          }
        });
      return obj;
    },
    // 添加子表单
    addJsonForm() {
      if (this.dataMode == "sub_form") {
        // 空白表单
        this.addJsonFormVisible = false;
        this.unchoose2(this.componentName, this.getComponent, false);
      } else {
        this.$refs.jsonForm.validate((valid) => {
          if (!valid) {
            return;
          }
          let obj = this.getComponent();
          obj.dataMode = "reference_form"; // reference_form:关联独立表  数据模式
          obj.tableName = this.jsonForm.value;
          obj.form = false;
          let getComponent = () => {
            return obj;
          };
          this.addJsonFormVisible = false;
          this.unchoose2(this.componentName, getComponent, false);
        });
      }
    },
    unchoose2(componentName, getComponent, flag = true) {
      // 子表单字段特殊处理
      if (componentName == "json_form" && flag) {
        this.addJsonFormVisible = true;
        this.dataMode = "sub_form";
        this.jsonForm = {};
        this.componentName = componentName;
        this.getComponent = getComponent;
        return;
      }
      if (componentName == "sn_input") {
        let snInputComponents = this.formComponent.components.filter(
          (item) => item.componentName == "sn_input",
        );
        if (snInputComponents.length > 0) {
          this.$message.warning("表单中仅可添加一个流水号字段");
          return;
        }
      }
      let components = getComponents(this.formComponent.components);

      if (
        componentName == "parent_data" &&
        components.findIndex((item) => item.componentName == componentName) >= 0
      ) {
        this.$message.warning("表单中仅可添加一个父级组件字段");
        return;
      }

      let addToList = (key, list) => {
        let index = list.findIndex((item) => item.key == key);
        let obj = getComponent();
        if (index >= 0) {
          list.splice(index + 1, 0, obj);
        } else {
          list.push(obj);
        }
        this.currNode = obj;
      };

      let addComponent = (node, key) => {
        if (
          node.componentName == "json_form" &&
          checkFieldAbility({ componentName }, "inJsonForm")
        ) {
          // 如果鼠标选中子表单里的组件
          const isSubComp = node.components.some((comp) => comp.key === key);
          if (isSubComp) {
            // 添加到子表单里
            addToList(key, node.components);
          } else {
            // 添加到子表单外
            addToList(node.key, this.formComponent.components);
          }
        } else if (
          node.componentName == "tab" &&
          checkFieldAbility({ componentName }, "inTabPane")
        ) {
          let activeName = node.activeName;
          if (!activeName) {
            activeName = 0;
          }
          addToList(key, node.components[activeName].components);
        } else if (
          node.componentName == "tab_pane" &&
          checkFieldAbility({ componentName }, "inTabPane")
        ) {
          addToList(key, node.components);
        } else if (
          node.componentName == "grid" &&
          checkFieldAbility({ componentName }, "inGridCol")
        ) {
          addToList(key, node.components[0].components[0].components);
        } else if (
          node.componentName == "grid_col" &&
          checkFieldAbility({ componentName }, "inGridCol")
        ) {
          addToList(key, node.components);
        } else {
          let parent = this.getParent(this.formComponent.components, node.key);

          if (typeof parent === "object") {
            addComponent(parent, node.key);
          } else {
            addToList(node.key, this.formComponent.components);
          }
        }
      };

      if (this.currNode) {
        addComponent(this.currNode);
      } else {
        if (componentName == "json_form" && this.draggableNewIndex == 0) {
          let obj = getComponent();
          this.formComponent.components.unshift(obj);
          this.currNode = obj;
        } else {
          let obj = getComponent();
          this.formComponent.components.push(obj);
          this.currNode = obj;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.drag-flex {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.container-heard {
  background-color: #fff;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;

  .header-title {
    width: 220px;
    color: #6b778c;
    padding-left: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.container {
  display: flex;
  height: calc(100vh - 111px);
  position: relative;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  margin: 5px 5px 5px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  padding: 20px 0;
}

.header {
  height: 50px;
  border-bottom: solid 1px #f4f5f7;
  padding: 0 20px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tab-txt {
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  padding: 0px 20px;
  flex: 1;
  cursor: pointer;

  &.on {
    color: var(--zgg-brand-color-6);
    cursor: default;
    border-bottom: 1px solid var(--zgg-brand-color-6);
  }
}

.attribute-box {
  border-left: solid 1px #f4f5f7;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  margin: 5px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
}

.drag-obj {
  font-size: 14px;
  border: solid 1px #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  box-sizing: border-box;
  background-color: #ffffff;
  pointer-events: none;
}

.form-body {
  flex: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    //display: none; /* Chrome Safari */
  }

  .lay-item {
    font-size: 14px;
    border: solid 1px #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
  }
}

.nav-tab {
  display: flex;
}

.cust-form {
  .lay-item {
    font-size: 14px;
    border: solid 1px #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
  }

  //style="width: max-content"

  &::after {
    display: block;
    content: " ";
    clear: both;
  }
  .divider-label {
    ::v-deep .el-form-item__label {
      width: 0px !important;
    }
  }

  ::v-deep {
    .un-label {
      margin: 0px !important;
      .el-form-item__content {
        margin: 0px !important;
      }
    }
    .fix-top {
      .el-form-item__label {
        width: max-content;
        float: none;
      }
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }
  }

  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  &.el-form--label-top {
    ::v-deep .el-form-item__label {
      line-height: 36px !important;
      padding-bottom: 0px;
    }
  }
  &.el-form--label-right {
    ::v-deep {
      .el-form-item__label {
        line-height: 22px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: right;
        min-height: 36px;
      }
    }
  }

  &.el-form--label-left {
    ::v-deep {
      .el-form-item__label {
        line-height: 22px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: left;
        min-height: 36px;
      }
    }
  }
  ::v-deep {
    .el-upload-dragger {
      width: auto !important;
      height: auto !important;
      padding: 0px 20px;

      .el-icon-upload {
        margin: 10px 0px 0px;
        font-size: 30px;
        line-height: 30px;
      }
    }

    .el-form-item {
      padding: 12px;
      float: left;
      margin-bottom: 0;
      border: transparent dashed 1px;
      position: relative;

      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100% !important;
      }

      &:hover {
        cursor: move;
      }

      &.is-checked {
        background-color: rgba($color: #409eff, $alpha: 0.08);
        border-radius: 5px;
      }

      &.is-error {
        border: red dashed 1px;
        border-radius: 5px;
      }

      &.copy-move,
      .move-line {
        border: solid 1px #f56c6c;
        border-radius: 5px;
        // background-color: rgba($color: #F56C6C, $alpha: .4);
      }

      &.move-item {
        border: solid 1px #f56c6c;
        border-radius: 5px;
        position: absolute;
        transform: rotate(1deg);
        pointer-events: none;
      }

      .el-input.is-disabled .el-input__inner,
      .el-textarea.is-disabled .el-textarea__inner {
        background-color: #ffffff;
        cursor: default;
      }
    }
  }
}

.btn-mr {
  margin-right: 10px;
  //background-color: #3870ea;
}

.custom-form-item {
  position: relative;
  float: left;

  &.is-checked {
    // border: #409eff dashed 1px;
    // background-color: rgba($color:var(--zgg-background-base), $alpha: 0.08);
    background-color: var(--zgg-background-base);
    border-radius: 5px;
  }
  &.is-error {
    border: red dashed 1px;
    border-radius: 5px;
  }

  ::v-deep {
    .el-form-item__content {
      position: unset;
    }
  }
}
.is-checked {
  background-color: var(--zgg-background-base) !important;
  border-radius: 5px !important;
}
.custom-form-item:hover {
  background: var(--zgg-border-lighter);
  border-radius: 5px;
}
.work-tree-container {
  ::v-deep {
    .el-scrollbar__view {
      min-height: 100%;
    }

    .el-scrollbar__bar {
      right: 0 !important;
    }
  }
}
.form-tab {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.recycle-btn {
  position: fixed;
  bottom: 5px;
  margin-left: 5px;
  width: 220px;
  background-color: #fff;
  text-align: center;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #dcdfe6;
  .el-button--text {
    color: #172b4d;
  }
  & :hover {
    opacity: 0.7;
  }
}
.custom-radio {
  ::v-deep {
    .el-radio {
      display: block;
      height: 30px;
      line-height: 30px;
      &.is-checked {
        background: none !important;
        border-radius: 5px;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
