<template>
  <div class="my-divider-line">
    <!-- line_01 -->
    <div v-if="lineType == 'horizontal'" class="alginc">
      <fieldset
        class="line_01"
        :style="'color:' + titleAlign + ';border-top: 1px solid' + lineColor"
      >
        <legend v-if="!hiddenTitle" class="inner"><slot></slot></legend>
      </fieldset>
      <div v-html="description"></div>
    </div>

    <!-- line_02 -->
    <div v-if="lineType == 'style2' && show" class="line_02">
      <div class="ti" :style="'border-bottom: 30px solid' + lineColor">
        <div v-if="!hiddenTitle" class="ct" :style="'color:' + titleAlign">
          <slot></slot>
        </div>
        <div
          class="pingxing"
          :style="
            `background: linear-gradient(90deg,transparent 50%, ${lineColor} 0);background-size: 12px 12px;`
          "
        ></div>
      </div>
      <!-- <div class="left-icon" :style="('color:'+ lineColor)">
    <i class="el-icon-arrow-left"></i>
    <i class="el-icon-arrow-left"></i>
    <i class="el-icon-arrow-left"></i>
    <i class="el-icon-arrow-left"></i>
    <i class="el-icon-arrow-left"></i>
    <i class="el-icon-arrow-left"></i>
   </div> -->
      <div class="line" :style="'background: ' + lineColor"></div>
      <div v-html="description"></div>
    </div>

    <!-- line_03 -->
    <div v-if="lineType == 'style3'">
      <div class="line_03">
        <div class="ti" :style="'border-bottom: 32px solid' + lineColor">
          <div v-if="!hiddenTitle" class="ct" :style="'color:' + titleAlign">
            <div class="ct-text"><slot></slot></div>
          </div>
        </div>
        <div class="line" :style="'background: ' + lineColor"></div>
      </div>
      <div v-html="description"></div>
    </div>

    <!-- line_04 -->
    <div v-if="lineType == 'style4'">
      <div class="line_04">
        <div class="ti" :style="'border-bottom: 36px solid' + lineColor">
          <div v-if="!hiddenTitle" class="ct" :style="'color:' + titleAlign">
            <slot></slot>
          </div>
        </div>
        <div class="line" :style="'background: ' + lineColor"></div>
      </div>
      <div style="text-align: left !important" v-html="description"></div>
    </div>

    <!-- line_05 -->
    <div v-if="lineType == 'vertical'" class="line_05">
      <div v-if="!hiddenTitle" class="titles" :style="'color:' + titleAlign">
        <slot></slot>
      </div>
      <div
        :style="'color:' + titleAlign + ';border-top: 1px solid' + lineColor"
      ></div>
      <div v-html="description"></div>
    </div>
    <!-- line_06 -->
    <div v-if="lineType == 'style6'" class="line_06">
      <div
        v-if="!hiddenTitle"
        class="titles"
        :style="'color:' + titleAlign"
        style="display: flex; flex-direction: row; align-items: center"
      >
        <div
          class="line-vertical"
          :style="'background-color:' + lineColor"
        ></div>
        <slot></slot>
      </div>
      <div v-html="description"></div>
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    lineColor(val) {
      console.log(val);
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 1);
    },
  },
  computed: {
    style() {
      let sh = this.dashed ? "dashed" : "solid";
      let x = this.lineType == "horizontal" ? "top" : "left";
      let str = `border-${x}:1px ${this.lineColor} ${sh}`;

      return str;
    },
  },
  props: {
    description: {
      type: String,
      default() {
        return "";
      },
    },
    titleAlign: {
      type: String,
      default() {
        return "left";
      },
    },
    lineColor: {
      type: String,
      default() {
        return "#D8DAE5";
      },
    },
    lineType: {
      type: String,
      default() {
        return "horizontal";
      },
    },
    dashed: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hiddenTitle: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.line_01 {
  font-size: 14px;
  border: 0;
  padding: 0;

  .inner {
    margin: 0 auto;
    padding: 0 24px;
  }
  legend {
    max-width: 80%;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline;
  }
}
.line_02 {
  width: 100%;
  margin: auto;
  .ti {
    width: auto;
    position: relative;
    text-align: center;
    z-index: 10;
    height: 0;
    border-right: 40px solid transparent;
    display: inline-block;
    max-width: 90%;
  }
  .ct {
    padding: 0px 16px 0 16px;

    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    margin-top: -4px;
  }
  .pingxing {
    position: absolute;
    width: 40px;
    height: 30px;
    transform: skew(53deg);
    top: 0px;
    right: -60px;
  }
  .left-icon {
    float: right;
    font-size: 18px;
  }
  .line {
    width: 100%;
    height: 3px;
    z-index: 1;
    margin-top: -18px;
  }
}
.line_03 {
  width: 100%;
  margin: auto;
  height: 47px;
  .ti {
    width: auto;
    position: relative;
    text-align: center;
    z-index: 10;
    height: 0;
    transform: skew(-20deg); /*设置倾斜度为-30*/
    display: inline-block;
    left: 1.8%;
    top: 8px;
    max-width: 90%;
  }
  .ti::before {
    content: "";
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 9px solid #8f95b2;
    position: absolute;
    left: -6px;
    bottom: -33px;
    -webkit-transform: rotateX(180deg) skew(-20deg);
    transform: rotateX(180deg) skew(-24deg);
  }
  .ti::after {
    content: "";
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 9.5px solid #8f95b2;
    position: absolute;
    right: -6px;
    bottom: -9px;
    -webkit-transform: skew(20deg);
    transform: skew(24deg);
  }
  .ct {
    padding: 3px 16px 3px 16px;
    /*强制文本在一行内显示*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ct-text {
    transform: skew(20deg);
    line-height: 26px;
  }
  .line {
    position: relative;
    width: 100%;
    height: 16px;
    bottom: 30px;
    opacity: 0.5;
    z-index: 1;
  }
}
.line_04 {
  width: 100%;
  margin: auto;
  text-align: center;
  height: 49px;
  .ti {
    width: auto;
    position: relative;

    z-index: 10;
    transform: rotateX(180deg);
    height: 0;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    display: inline-block;
    // left: 40%;
    top: 5px;
    max-width: 90%;
  }
  .ti::before {
    content: "";
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 10px solid #8f95b2;
    position: absolute;
    left: -15px;
    bottom: -36px;
    -webkit-transform: skew(7deg) rotateX(180deg);
    transform: skew(7deg) rotateX(180deg);
  }
  .ti::after {
    content: "";
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 10px solid #8f95b2;
    position: absolute;
    right: -14.9px;
    bottom: -36.3px;
    -webkit-transform: skew(-3deg) rotateX(180deg);
    transform: skew(-7deg) rotateX(180deg);
  }

  .ct {
    /*强制文本在一行内显示*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width:80%;

    transform: rotateX(180deg);
    padding: 0px 16px;
  }
  .line {
    position: relative;
    width: 100%;
    height: 16px;
    bottom: 40px;
    opacity: 0.5;
    z-index: 1;
  }
}
.line_05 {
  font-size: 14px;
  border: 0;
  padding: 0;
  .titles {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.alginc {
  text-align: center;
}
.desc {
  color: #6b778c;
  font-size: 12px;
}
.my-divider {
  position: relative;
  padding: 0px 20px;
  .line {
    position: absolute;
  }
  .line-horizontal {
    left: 0px;
    top: 50%;
    width: 100%;
  }
  .line-vertical {
    height: 1em;
    position: static;
  }
  .divider-text {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    font-weight: 600;
  }
  &.is-left {
    text-align: left;
  }
  &.is-center {
    text-align: center;
  }
  &.is-right {
    text-align: right;
  }
}

.line_06 {
  .line-vertical {
    width: 5px;
    height: 16px;
    margin-right: 6px;
  }
}
</style>
