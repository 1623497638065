import request from "@/utils/request";
import qs from "qs";
import store from "@/store";

/**
 * 获取表单树和视图树
 * https://dev.jarbar.cn/srv/doc.html#/06-%E5%8D%8F%E5%90%8C-%E6%95%B4%E7%BC%96/%E5%8D%95%E4%BD%8D%E6%B5%81%E7%A8%8B%E5%88%86%E7%BB%84%E7%AE%A1%E7%90%86/treeUsingGET
 * @param {*} data
 * @returns
 */
export function dirTree(data) {
  return request({
    url: "/dir/tree",
    method: "post",
    data: {
      ...data,
      companyId: store.getters.currentCompanyId,
    },
  });
}
/**
 * 1，添加一级目录和添加子目录接口 (新接口)
 /dir/register?title=文件夹名称(必填)&parentId=父文件夹ID,顶级就为空&type=类型 10:表单库 20:视图库(必填)
 * @param {*} data
 * @returns
 */
export function dirRegister(data) {
  data = {
    ...data,
    companyId: store.getters.currentCompanyId,
  };
  return request({
    url: "/dir/register",
    method: "post",
    data,
  });
}

/**
 4，删除目录接口(新接口) (注意要把其下的资源放到顶层目录)
 /dir/delete?dirId=文件夹ID(必填)
 * @param {*} data
 * @returns
 */
export function dirDelete(data) {
  return request({
    url: "/dir/delete",
    method: "post",
    data,
  });
}

/**
 移动目录或资源接口
 id=树节点id值(必填)&parentId=父文件夹id值(必填，顶级为0)
 返回 model
 * @param {*} data
 * @returns
 */
export function dirMove(data) {
  return request({
    url: "/dir/move",
    method: "post",
    data,
  });
}

/**
 5.1，排序目录或资源接口
 /dir/sort?id=树节点id值(必填)&preId=前一文件夹或资源id值(必填)&parentId=父文件夹id值(必填，顶级为0)
 * @param {*} data
 * @returns
 */
export function dirSort(data) {
  return request({
    url: "/dir/sort",
    method: "post",
    data,
  });
}

/**

 3，重命名目录接口 (新接口)
 /dir/rename?dirId=文件夹ID(必填)&title=新文件夹名称(必填)
 * @param {*} data
 * @returns
 */
export function dirRename(data) {
  return request({
    url: "/dir/rename",
    method: "post",
    data,
  });
}

/**
 * 批量获取用户信息
 * @param {*} userIds
 * @returns
 */
export function listUserInfo(data) {
  return request({
    url: "/user/temp/listUserInfo",
    method: "post",
    data,
  });
}

/**
 * 批量获取部门信息
 * @param {*} departmentIds
 * @returns
 */
export function listDepartmentInfo(data) {
  return request({
    url: "/user/temp/listDepartmentInfo",
    method: "post",
    data,
  });
}
