<template>
  <div
      class="attachment-wrap"
  >

    <div class="attachment-item" v-for="(attachment,index) in attachmentList">
      <div class="attachment-icon">
        <el-image
            v-if="checkFileImage(attachment.extension)"

            :src="attachment.url+'?x-oss-process=image/resize,m_lfit,h_100,w_100'" class="attachment-image"
            :preview-src-list="[attachment.url]"
            fit="scale-down"></el-image>
        <SvgIcon v-if="!checkFileImage(attachment.extension)"
                 :icon-class="getIconClassByExtension(attachment.extension)"
                 class="attachment-svg"></SvgIcon>
      </div>
      <div class="attachment-info">
        <div class="attachment-name">
          <span class="name">{{ attachment.originalName }}</span>
          <span class="size">（{{ attachment.size|formatFileSize }}）</span>
        </div>
        <div class="attachment-tool">
<!--          <el-link type="primary" icon="el-icon-paperclip" :href="attachment.url" target="_blank"-->
<!--                   :download="attachment.originalName">下载-->
<!--          </el-link>-->
          <!--                    <el-link type="primary" icon="el-icon-view">预览</el-link>-->
        <slot :row="attachment" :index="index">

        </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon/index"

export default {
  components:{SvgIcon},
  props: {
    attachmentList: {
      type: Array,
      default: function () {
        return []
      }
    }
  }, data() {
    return {}
  },
  methods: {
    checkFileImage(extension) {
      let enums = {

        jpg: "jpg2",
        jpeg: "jpg2",
        png: "png2",
        bmp: "bmp",
        heic: "heic",
        ico: "ico"


      }
      return enums[extension];
    },
    getIconClassByExtension(extension) {
      let enums = {
        doc: "WORD2",
        docx: "WORD2",
        jpg: "jpg2",
        jpeg: "jpg2",
        html: "html2",
        mp3: "mp32",
        png: "png2",
        dll: "dll2",
        ppt: "PPT",
        pptx: "PPT",
        mp4: "mp42",
        pdf: "pdf1",
        gif: "gif1",
        exe: "exe",
        mpg: "mpg",
        psd: "psd1",
        mkv: "mkv",
        xls: "ECEL",
        xlsx: "ECEL",
        rmvb: "rmvb",
        txt: "txt1",
        wav: "wav",
        swf: "swf1",
        avi: "avi1",
        zip: "zip1",
        rar: "folder2",
        other: "other",
      }
      if (enums[extension]) {
        return enums[extension]
      } else {
        return "other"
      }
    },
  }
}
</script>
<style scoped lang="less">
.attachment-wrap {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 14px;
  width: 100%;
  box-sizing: border-box;

  .attachment-item {
    border-radius: 4px;
    border: 1px solid #ebeef5;
    transition: .3s;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 10px;
    cursor: pointer;
    line-height: normal;

    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
      border: 1px solid #eeeeee;

    }

    .attachment-icon {

      .attachment-image {
        height: 100px;
        width: 100px;
      }

      .attachment-svg {
        width: 32px;
        height: 32px;
      }
    }

    .attachment-info {
      margin: 0 10px;
      height: 100%;

      .attachment-name {
        .name {
          line-height: 30px;
        }

        .size {
          color: #a0a0a0;
        }
      }

      .attachment-tool {
        .el-link {
          margin-right: 10px;
        }
      }
    }

  }

}
</style>
