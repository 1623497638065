<template>
  <div
    class="el-input el-input--small"
    :class="isDeleteField(field) ? 'etl-error' : ''"
  >
    <div @click="showPop" class="el-input__inner field-input">
      <div class="input-flex" v-text="field.comment"></div>
      <div
        class="field-raidus"
        :class="getClass(field.componentName)"
        v-text="getClassText(field.componentName)"
      ></div>
      <i :class="visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
    </div>

    <div
      v-if="visible"
      ref="popSelect"
      class="pop-select"
      :style="`bottom:${bottom}px;left:${left}px;`"
      v-clickoutside="clickoutside"
    >
      <div class="pop-arrow"></div>
      <el-input
        prefix-icon="el-icon-search"
        v-model="keyword"
        size="small"
        placeholder=""
      ></el-input>
      <div class="field-scroll">
        <div
          @click="changeField(item)"
          v-for="item in componentList"
          :key="item.name"
          class="field-item"
          :class="item.name == field.name ? 'active' : ''"
        >
          <div class="field-title" v-text="item.comment"></div>
          <div
            class="field-raidus"
            :class="getClass(item.componentName)"
            v-text="getClassText(item.componentName)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
import { getComponentClass, getFieldListByNode, fieldClassMap } from "./util";
import Clickoutside from "element-ui/src/utils/clickoutside";
import { joinWhiteFields } from "@/zgg-core/whiteList";
export default {
  props: {
    node: Object, // 节点
    field: {
      type: Object,
      default() {
        return {};
      },
    }, // 字段
    isDeleteField: Function,
    otherField: Object,
  },

  directives: { Clickoutside },
  data() {
    return {
      visible: false,
      keyword: "",
      bottom: 0,
      left: 0,
    };
  },
  computed: {
    componentList() {
      let list = getFieldListByNode(this.node);
      list = list.filter((item) =>
        joinWhiteFields.includes(item.componentName),
      );
      if (!isEmpty(this.keyword)) {
        list = list.filter((item) => item.comment.indexOf(this.keyword) >= 0);
      }
      if (!isEmpty(this.otherField)) {
        if (
          ["id", "reference_data", "cascade_data"].includes(
            this.otherField.componentName,
          )
        ) {
          list = list.filter(
            (item) =>
              !["id", "reference_data", "cascade_data"].includes(
                item.componentName,
              ) ||
              (["id", "reference_data", "cascade_data"].includes(
                item.componentName,
              ) &&
                item.component.tableName ==
                  this.otherField.component.tableName),
          );
        }
        // list = list.filter(
        //   (item) => getComponentClass(item.componentName) == classText
        // );
      }

      return list;
    },
  },
  beforeDestroy() {
    if (this.$refs.popSelect) {
      document.body.removeChild(this.$refs.popSelect);
    }
    this.visible = false;
  },
  methods: {
    clickoutside() {
      this.visible = false;
    },
    showPop() {
      this.visible = true;
      this.$nextTick(() => {
        this.bottom =
          document.documentElement.clientHeight -
          this.$el.getBoundingClientRect().top +
          12;
        this.left = this.$el.getBoundingClientRect().left + 12;

        document.body.append(this.$refs.popSelect);
      });
    },
    changeField(item) {
      let field = this._.cloneDeep(item);
      this.$emit("update:field", field);
      this.visible = false;
      this.$emit("changeChildrenData");
    },
    getClass(componentName) {
      if (componentName) {
        return getComponentClass(componentName);
      }
      return;
    },
    getClassText(componentName) {
      if (componentName) {
        let key = this.getClass(componentName);
        return fieldClassMap[key];
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pop-select {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  .pop-arrow {
    bottom: 1px;
    left: 10%;
    margin-right: 3px;
    border-width: 6px 6px 0;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
    width: 0;
    height: 0;
    border-color: transparent transparent;
    border-style: solid;
    position: absolute;
    &::after {
      content: " ";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 6px 6px 0;
      top: 1px;
      margin-left: -6px;
    }
  }
}
.field-scroll {
  max-height: 360px;
  overflow: auto;
}
.text-tip {
  color: #248af9;
  background: rgba(36, 138, 249, 0.1);
}
.number-tip {
  color: #f4b700;
  background: rgba(244, 183, 0, 0.15);
}
.member-tip {
  color: #fc9b3b;
  background: rgba(252, 155, 59, 0.15);
}
.dept-tip {
  color: #dd4390;
  background: rgba(221, 67, 144, 0.15);
}
.date-tip {
  color: #8e24f9;
  background: rgba(142, 36, 249, 0.15);
}
.array-tip {
  color: #dc38dc;
  background: rgba(220, 56, 220, 0.15);
}
.address-tip {
  color: #05d1d1;
  background: rgba(5, 209, 209, 0.15);
}
.textarea-tip {
  color: #76af24;
  background: rgba(30, 195, 30, 0.15);
}
.reference-tip {
  color: #9f24af;
  background: rgba(162, 30, 195, 0.15);
}
.id-tip {
  color: #9f24af;
  background: rgba(162, 30, 195, 0.15);
}
.field-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  height: 30px;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  &.active {
    background-color: #e6f6f4;
  }
}
.field-raidus {
  padding: 0px 8px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 1.5;
}
.field-input {
  display: flex;
  align-items: center;
  .input-flex {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
