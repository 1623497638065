<template>
  <el-table-column
    :align="col.align"
    :label="col.title"
    :width="col.width ? col.width : getTableColumnWidthByColumn(field)"
    :min-width="getTableColumnWidthByColumn(field)"
    :prop="columns && columns.length ? '' : field.name"
    :index="treeIndex"
    ref="tableColumn"
    :fixed="freezeStatus"
  >
    <template v-slot:header="scope">
      <span>{{ col.title }}</span>
      <span v-if="hasSub(scope.column)"></span>
    </template>
    <template v-if="col.referenceName" slot="header">
      <i class="el-icon-connection"></i>
      <span v-text="col.title"></span>
    </template>
    <template slot-scope="{ row }">
      <attachment-thumb
        v-if="
          field.componentName == 'attachment_uploader' ||
          field.componentName == 'image_uploader'
        "
        :file-type="field.componentName == 'image_uploader' ? 'image' : ''"
        :fieldName="field.name"
        :curRow="row"
        :list="getValue(row, col)"
      ></attachment-thumb>
      <template v-else-if="field.componentName == 'sign_input'">
        <el-image
          v-if="getValue(row, col) && getValue(row, col).url"
          :src="getValue(row, col).url"
          class="code-image"
          fit="scale-down"
          style="height: 26px"
        ></el-image>
      </template>
      <template v-else-if="field.componentName == 'sign_list_input'">
        <div
          v-if="getValue(row, col) && getValue(row, col).length > 0"
          class="sign-list__wrap"
        >
          <div
            v-for="(item, index) in getValue(row, col)"
            :key="index"
            class="weui code-center"
            style="border:solid 1px #e4e7ed,borderRadius:0,padding:0"
          >
            <template>
              <el-image
                :src="item.url"
                class="code-image"
                fit="scale-down"
                style="height: 30px"
              ></el-image>
            </template>
          </div>
        </div>
      </template>
      <template
        v-else-if="field.component && field.component.enableOptionColor"
      >
        <color-select-display
          :row-data="row"
          :col-config="col"
        ></color-select-display>
      </template>
      <span
        class="flow-status"
        v-else-if="
          field.name && field.name.includes('flowStatus') && getValue(row, col)
        "
        :class="`is-${getValue(row, col)}`"
      >
        {{
          getValue(row, col) == "reject"
            ? "已拒绝"
            : getValue(row, col) == "success"
            ? "已通过"
            : getValue(row, col) == "processing"
            ? "进行中"
            : getValue(row, col) == "not_commit"
            ? "未提交"
            : getValue(row, col)
        }}
      </span>
      <el-tooltip
        v-else-if="!isEmpty(getValue(row, col))"
        effect="light"
        :content="getValue(row, col) + ''"
        placement="bottom-end"
        :enterable="false"
        :open-delay="500"
        popper-class="custom-tooltip"
        :visible-arrow="false"
      >
        <span class="column-value">{{ getValue(row, col) }}</span>
      </el-tooltip>
      <span v-else v-text="getValue(row, col)" class="column-value"></span>
    </template>
    <template v-if="columns && columns.length">
      <table-column
        v-for="(subCol, sIndex) in columns"
        :isViewList="isViewList"
        :key="sIndex"
        :col="subCol"
        :is-sub="true"
      ></table-column>
    </template>
  </el-table-column>
</template>
<script>
import AttachmentThumb from "./AttachmentThumb";
import colorSelectDisplay from "./color-select-display";
import { mapMutations } from "vuex";
import {
  isSortableComponent,
  getTableColumnWidthByColumn,
} from "@/views/lowCode/utils";
import { getRandomColor } from "@zgg-core-utils/utils";

import moment from "moment";
import test from "@/utils/test";
import {
  getMomentFormat,
  isEmpty,
  getStrByNumberFormat,
} from "@zgg-core-utils/utils";

export default {
  name: "TableColumn",
  components: {
    AttachmentThumb,
    colorSelectDisplay,
  },
  props: {
    col: Object,
    freezeStatus: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isViewList: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isSub: {
      type: Boolean,
      default() {
        return false;
      },
    },
    treeIndex: Number,
    index: Number,
    documentId: [String, Number],
    freezeIndex: Number,
    // 是否显示序号
    showRowNumbers: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    field() {
      if (this.isViewList) {
        let field = this._.cloneDeep(this.col.field);

        return field;
      } else {
        return this.col;
      }
    },
    columns() {
      if (this.isViewList) {
        let subColumns = this._.cloneDeep(this.col.subColumns);
        if (subColumns) {
          subColumns.forEach((item) => {
            let name = this.col.field.name + "." + item.field.name;
            item.field.name = name;
          });
        }
        if (subColumns) {
          return subColumns.filter((item) => !item.hidden);
        }

        return subColumns;
      } else {
        let subColumns = this._.cloneDeep(this.col.components);
        if (subColumns) {
          subColumns.forEach((item) => {
            if (item.name) {
              let name = this.col.field.name + "." + item.name;
              item.field.name = name;
            } else if (item.field) {
              let name = this.col.field.name + "." + item.field.name;
              item.field.name = name;
            }
          });
        }
        return subColumns;
      }
    },
    isSortable() {
      return !this.isSub && isSortableComponent(this.field.componentName);
    },
  },
  methods: {
    //判断是否多级表头，若是则重新计算宽度=子标题宽度之和
    hasSub(val) {
      let realWidth = 0;
      if (val) {
        if (val.children) {
          val.children.forEach((item) => {
            realWidth += item.realWidth;
          });
          val.realWidth = realWidth;
        }
      }
      return true;
    },

    isEmpty: test.isEmpty,
    getTableColumnWidthByColumn,
    ...mapMutations("image", ["showViewer", "setSrcList", "setFillColorClass"]),
    reviewSign(obj) {
      this.setSrcList([obj.url]);
      this.setFillColorClass("sign-white-bg");
      this.showViewer(0);
    },

    getValue(row, col) {
      let field = this.field;
      // console.log("==========", this._.cloneDeep(field));
      if (
        field.componentName == "json_form" ||
        field.componentName == "form_data"
      ) {
        return "";
      }

      let rowObj = row;
      let referenceName = this.col.referenceName;
      //关联数据需要单独处理
      if (referenceName) {
        //如果是子表单内的关联数据，需要裁切referenceName，因为是这样的结构 'e593f02d68.la812e2bc0'
        if (this.isSub) {
          let referenceNameArray = referenceName.split(".");
          if (referenceNameArray.length && referenceNameArray[1]) {
            referenceName = referenceNameArray[1];
          }
        }
        rowObj = row[referenceName + "ReferenceData"];
        if (rowObj instanceof Array) {
          rowObj = rowObj[0];
        }
      }
      if (!rowObj) {
        rowObj = {};
      }

      if (field.componentName == "address_input") {
        let obj = rowObj[field.name];
        if (obj) {
          let str = `${obj.province}`;
          if (obj.city) {
            str += obj.city;
          }
          if (obj.county) {
            str += obj.county;
          }
          if (obj.address) {
            str += obj.address;
          }
          return str;
        }
        return;
      } else if (
        field.componentName == "checkbox_group" ||
        field.componentName == "select_checkbox"
      ) {
        let a = rowObj[field.name];
        if (isEmpty(a)) {
          return;
        }

        if (typeof a === "string") {
          return JSON.parse(a).join(",");
        } else if (typeof a === "object") {
          return a.join(",");
        }
      } else if (field.componentName == "position_input") {
        let data = rowObj[field.name];
        if (data) {
          return data.address;
        }
        return;
      } else if (field.componentName == "user_list_select") {
        let valueUserMap = rowObj[field.name + "UserMap"];
        let value = rowObj[field.name];
        if (value && valueUserMap) {
          value = value.map((item) => valueUserMap[item]);
          let list = Object.values(value).map((item) => item && item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "user_select") {
        let value = rowObj[field.name + "UserInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_select") {
        let value = rowObj[field.name + "DepartmentInfo"];
        if (value) {
          return value.name;
        }
        return;
      } else if (field.componentName == "department_list_select") {
        let value = rowObj[field.name + "DepartmentInfoList"];
        if (value) {
          let list = value.map((item) => item && item.name);
          return list.join(",");
        }
        return;
      } else if (field.componentName == "date_picker") {
        let value = rowObj[field.name];
        if (value) {
          if (field.component) {
            value = moment(value).format(getMomentFormat(field.component));
          }

          if (value == "Invalid date") {
            value = rowObj[field.name];
          }
          return value;
        }
        return;
      } else if (field.componentName == "input_number") {
        let value = rowObj[field.name];
        if (!isEmpty(value)) {
          let component = field.component;
          let numberFormat = {
            thousandsSeparator: component.thousandsSeparator,
            decimal: component.decimalPrecision,
          };

          value = getStrByNumberFormat(value, numberFormat);
        }
        return value;
      } else {
        return rowObj[field.name];
      }
      return rowObj[field.name];
    },
    getColValue(row, field) {
      if (field.componentName == "select_checkbox") {
        let valueArr = row[field.name];
        if (valueArr && valueArr.length > 0) {
          return valueArr;
        }
      } else {
        let value = this.getValue(row);
        if (value) {
          if (typeof value === "string") {
            return [value] || [];
          } else if (typeof value === "object") {
            return value;
          }
        }
        return [];
      }
    },
    getBackgroundColor(row, item) {
      let color = "";
      if (item == "") return color;
      if (
        this.field.component.optionSource == "custom" &&
        this.field.component.customOptions
      ) {
        let isExist = this.field.component.customOptions.find(
          (o) => o.value == item,
        );
        if (isExist) {
          color = isExist.color;
        } else {
          color = getRandomColor(item);
        }
      }
      return color;
    },
  },
};
</script>
<style lang="scss" scoped>
.column-value {
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  font-weight: 400;
  overflow: hidden;
  font-size: 13px;
  line-height: 20px;
  //color: rgba(9, 30, 66, 0.95);
  height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.code-image {
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep {
    img {
      width: 50px;
    }
  }
}

.flow-status {
  padding: 5px 12px;
  border-radius: 50px;
  box-sizing: border-box;
  margin: 0 5px;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;

  &.is-reject {
    background-color: #ff4d4f;
    color: #ffffff;
  }

  &.is-success {
    background-color: #52c41a;
    color: #ffffff;
  }

  &.is-processing {
    background-color: #faad14;
    color: #ffffff;
  }

  &.is-not_commit {
    background-color: #919398;
    color: #ffffff;
  }
}
.n-btn {
  padding: 2px !important;
  border: 0px !important;
  border-radius: 5px;
  background-color: #f2f6fc;
  &:hover {
    background-color: #e1e4f3;
  }
}
.freezeBtn {
  display: none;
  //float: right;
  position: absolute;
  right: 2px;
  top: 2px;
}
.el-dropdown-menu {
  margin-top: 0;
  padding: 0 0;
  ::v-deep .popper__arrow {
    overflow: auto;
    border: none;
  }
}
.el-dropdown-menu__item {
  margin-right: 0px;
  font-size: 14px;
  color: #091e42;
}

.sign-list__wrap {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
</style>
<style lang="scss">
.custom-tooltip {
  max-width: 810px;
}
</style>
