<template>
  <div class="field-item">
    <template v-if="!isEdit">
      <el-tooltip effect="dark" :content="field.comment" placement="top">
        <div v-text="field.comment"></div>
      </el-tooltip>
      <i @click="editTitle" class="el-icon-edit field-edit"></i>
    </template>
    <el-input
      v-else
      v-model="title"
      class="field-edit-input"
      @blur="blurInput"
      placeholder=""
    ></el-input>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
export default {
  props: {
    field: Object,
  },
  data() {
    return {
      isEdit: false,
      title: "",
    };
  },
  methods: {
    editTitle() {
      this.title = this.field.comment;
      this.isEdit = true;
    },
    blurInput() {
      if (!isEmpty(this.title)) {
        this.field.comment = this.title;
      }
      this.isEdit = false;
      this.$emit("changeChildrenData");
    },
  },
};
</script>
<style lang="scss" scoped>
.field-item {
  width: 130px;
  height: 50px;
  padding: 0 8px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-left: 0;
  overflow: hidden;
  font-size: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  .field-edit {
    display: none;
    position: absolute;
    right: 10px;
    top: 18px;
    cursor: pointer;
  }
  &:hover {
    .field-edit {
      display: block;
    }
  }
  .field-edit-input {
    ::v-deep {
      .el-input__inner {
        padding: 0;
        border: 0;
      }
    }
  }
}
.field-title {
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
