<template>
  <div class="layout-wrapper">
    <div
      class="layout-left"
      :style="[
        { background: !isCollapse ? '#fff' : '#f4f5f7' },
        {
          width: menuWrapWidth + 'px',
          marginLeft: !isCollapse
            ? wrapMarginLeft + 'px'
            : wrapMarginLeft + 0 + 'px',
        },
      ]"
    >
      <div
        v-if="!isCollapse"
        class="drag-right"
        @mousedown="dragdown($event)"
        :class="onDraging ? 'onDraging' : ''"
      ></div>
      <i
        style="font-size: 20px"
        :style="!isCollapse ? 'right: -20px;' : 'right: -26px;'"
        class="toggle-panel custom-icon"
        :class="!isCollapse ? 'iconfont icon-zhedie' : 'iconfont icon-zhankai'"
        @click="toggleBoardVisible"
      ></i>
      <div v-show="show" style="height: 100%">
        <slot name="tree"></slot>
      </div>
    </div>
    <div class="layout-right">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    wrapMarginLeft() {
      if (!this.isCollapse) {
        return 0;
      } else {
        return -this.menuWrapWidth;
      }
    },
  },
  data() {
    return {
      isCollapse: false,
      menuWrapWidth: 250,
      onDraging: false,
      show: true,
    };
  },
  methods: {
    toggleBoardVisible() {
      this.isCollapse = !this.isCollapse;
      this.show = !this.show;
    },
    dragmove(event) {
      event.preventDefault();
      event.stopPropagation();
      //最小宽度为250
      if (event.clientX < 250 || event.clientX > 450) return;
      this.menuWrapWidth = event.clientX + 4;
    },
    dragup(event) {
      event.preventDefault();
      event.stopPropagation();
      this.onDraging = false;
      document.removeEventListener("mousemove", this.dragmove);
      document.removeEventListener("mouseup", this.dragup);
    },
    dragdown(event) {
      event.preventDefault();
      event.stopPropagation();

      if (event.buttons !== 1) {
        // 不是鼠标左键按下的则不执行一下操作
        return;
      }
      this.onDraging = true;
      let element = event.currentTarget.parentElement;
      document.addEventListener("mousemove", this.dragmove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.dragup, {
        passive: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}
.layout-left {
  width: 250px;
  height: 100%;
  position: relative;
  transition-property: margin-left;
  transition-duration: 0.5s;
}
.layout-right {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.drag-right {
  width: 8px;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 99;

  &:hover {
    border-right: solid 2px rgba(24, 144, 255, 0.6);
  }
  &.onDraging {
    border-right: solid 4px rgba(24, 144, 255, 0.6);
  }
}
.toggle-panel {
  position: absolute;
  z-index: 99;
  right: 2px;
  top: 16px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--zgg-border-light);
  border-radius: 50%;
  background-color: white;
  color: #6b77bc;
}
</style>