<template>
  <div class="pivot-col">
    <div class="pivot-edit">
      <el-input
        v-if="isEdit"
        v-model="node.comment"
        ref="input"
        @blur="changeInput"
        @keydown.enter="changeInput"
        placeholder=""
        style="width: 100%"
      ></el-input>
      <template v-else>
        <div class="pivot-drag">
          <div
            style="flex: 1"
            :title="node.comment"
            v-text="node.comment"
          ></div>
          <i
            @click="editRow"
            class="el-icon-edit etl-icon"
            style="margin-right: 4px"
          ></i>
          <i @click="deleteRow" class="etl-icon el-icon-delete"></i>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { isEmpty } from "@/zgg-core/utils";
export default {
  props: {
    node: Object,
    index: Number,
    editColIndex: Number,
    values: Array,
  },
  computed: {
    isEdit() {
      return this.index == this.editColIndex;
    },
  },
  mounted() {
    if (this.$refs.input) {
      this.$refs.input.focus();
    }
  },
  methods: {
    changeInput() {
      this.$emit("update:editColIndex", -1);

      if (isEmpty(this.node.comment)) {
        this.$emit("deleteValues", this.index);
      }
      if (
        this.values.filter((item) => item.comment == this.node.comment).length >
        1
      ) {
        this.$message.warning("内容已存在");
        this.$emit("deleteValues", this.index);
      }
      this.$emit("changeChildrenData");
    },
    editRow() {
      this.$emit("update:editColIndex", this.index);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    deleteRow() {
      this.$emit("deleteValues", this.index);
      this.$emit("changeChildrenData");
    },
  },
};
</script>
<style lang="scss" scoped>
.pivot-col {
  width: 150px;
  border-right: solid 1px #e0e0e0;
  cursor: move;
}
.pivot-edit {
  height: 40px;
  box-sizing: border-box;
  border-bottom: solid 1px #e0e0e0;
  ::v-deep {
    .el-input__inner {
      // border: 0;
      height: 39px;
      line-height: 39px;
      box-shadow: 1px 1px 3px 1px rgba($color: #000000, $alpha: 0.1);
      border-radius: 0;
    }
  }
}
.pivot-drag {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 8px;
  box-sizing: border-box;
  height: 40px;
  font-size: 14px;
  .etl-icon {
    display: none;
  }
  &:hover {
    .etl-icon {
      display: block;
      cursor: pointer;
    }
  }
}
</style>
