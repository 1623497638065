<template>
  <div>
    <div class="attr-row">
      <div class="attr-label">选项</div>
      <el-select
        @change="changeOptSource"
        v-model="component.optionSource"
        size="mini"
        placeholder=""
        style="width: 100%"
      >
        <el-option label="静态数据" value="custom"></el-option>
        <el-option label="关联数据" value="metaField"></el-option>
        <el-option
          v-if="isShowDataLinkage"
          label="数据联动"
          value="dataLinkage"
        ></el-option>
      </el-select>
      <attr-options-custom
        v-if="component.optionSource == 'custom'"
        :component="component"
        style="margin-top: 10px"
      ></attr-options-custom>
      <attr-options-reference
        v-else-if="component.optionSource == 'metaField'"
        :component="component"
        :params="params"
      ></attr-options-reference>
      <data-linkage
        style="margin-top: 10px"
        v-else-if="component.optionSource == 'dataLinkage'"
        :form="component"
        :componentList="originComponents"
        :areaTree="areaTree"
      ></data-linkage>
    </div>
  </div>
</template>
<script>
import { componentProps } from "./util";
import attrOptionsCustom from "@/views/lowCode/form/attribute/attr-options-custom";
import attrOptionsReference from "@/views/lowCode/form/attribute/attr-options-reference";
import dataLinkage from "@/views/lowCode/form/component/dataLinkage";
import { mapState } from "vuex";
import { getFormComponents } from "../utils";
const excludeArr = ["select", "select_checkbox"];

export default {
  name: "attr-options",
  inject: ["getComponents"],
  components: {
    [attrOptionsCustom.name]: attrOptionsCustom,
    [attrOptionsReference.name]: attrOptionsReference,
    dataLinkage,
  },
  props: componentProps,
  computed: {
    ...mapState("area", ["areaProps"]),
    originComponents() {
      let components = getFormComponents(this.getComponents());
      return components;
    },
    isShowDataLinkage() {
      if (excludeArr.includes(this.component.componentName)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    changeOptSource(value) {
      if (excludeArr.includes(this.component.componentName)) {
        this.$set(this.component, "rely", null);
        this.$set(this.component, "dataLinkage", null);
        this.$set(this.component, "autoValueTemplate", null);
      }
      if (
        this.component.componentName == "checkbox_group" ||
        this.component.componentName == "select_checkbox"
      ) {
        this.component.defaultValue = [];
      } else {
        this.component.defaultValue = "";
      }
      this.component.enableOptionColor = false;

      if (value == "metaField") {
        this.$set(this.component, "metaFieldOption", {});
        this.$set(this.component, "dataLinkage", null);
        this.$set(this.component, "customOptions", null);
      } else if (value == "dataLinkage") {
        this.$set(this.component, "metaFieldOption", null);
        this.$set(this.component, "dataLinkage", {});
        this.$set(this.component, "customOptions", null);
        if (this.component.extParam.mateLabel) {
          delete this.component.extParam.mateLabel;
        }
      } else if (value == "custom") {
        if (this.component.extParam && this.component.extParam.mateLabel) {
          delete this.component.extParam.mateLabel;
        }
        this.$set(this.component, "customOptions", null);
        this.$set(this.component, "metaFieldOption", null);
        this.$set(this.component, "dataLinkage", null);
        this.$set(this.component, "customOptions", [
          {
            label: "选项1",
            value: "选项1",
          },
          {
            label: "选项2",
            value: "选项2",
          },
          {
            label: "选项3",
            value: "选项3",
          },
        ]);
      }
    },
  },
};
</script>
