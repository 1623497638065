<template>
  <table class="grid-table">
    <colgroup>
      <col
        :width="col.width"
        v-for="(col, index) in grid.columns"
        :key="index"
      />
    </colgroup>
    <tr v-for="row in grid.components" :key="row.key">
      <template v-for="col in row.components">
        <td
          :valign="col.valign ? col.valign : 'middle'"
          :align="col.align"
          v-if="col.rowspan !== 0 && col.colspan !== 0"
          :rowspan="col.rowspan"
          :colspan="col.colspan"
          :key="col.key"
          :style="borderStyle(col)"
        >
          <form-tpl-item
            v-for="item in col.components"
            v-show="!item.hidden"
            :isShowComponent="isShowComponent"
            :key="item.key"
            :cols="cols"
            :form="form"
            :item="item"
            :source-list="sourceList"
            :area-tree="areaTree"
            :formType="formType"
            @changeFormData="changeFormData"
            @getMetaOptions="getMetaOptions"
          ></form-tpl-item>
        </td>
      </template>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    formType: String,
    grid: Object,
    cols: Number,
    form: Object,
    areaTree: Array,
    sourceList: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowComponent: Function,
  },
  methods: {
    borderStyle(col) {
      let width = {};
      let style = {};
      let color = {};
      if (col.border) {
        width = {
          top: col.border.width,
          left: col.border.width,
          right: col.border.width,
          bottom: col.border.width,
        };
        style = {
          top: col.border.style,
          left: col.border.style,
          right: col.border.style,
          bottom: col.border.style,
        };
        color = {
          top: col.border.color,
          left: col.border.color,
          right: col.border.color,
          bottom: col.border.color,
        };
      } else if (this.grid.border) {
        width = {
          top: this.grid.border.width,
          left: this.grid.border.width,
          right: this.grid.border.width,
          bottom: this.grid.border.width,
        };
        style = {
          top: this.grid.border.style,
          left: this.grid.border.style,
          right: this.grid.border.style,
          bottom: this.grid.border.style,
        };
        color = {
          top: this.grid.border.color,
          left: this.grid.border.color,
          right: this.grid.border.color,
          bottom: this.grid.border.color,
        };
      }
      if (col.borderTop && Object.keys(col.borderTop).length) {
        width.top = col.borderTop.width;
        style.top = col.borderTop.style;
        color.top = col.borderTop.color;
      }
      if (col.borderRight && Object.keys(col.borderRight).length) {
        width.right = col.borderRight.width;
        style.right = col.borderRight.style;
        color.right = col.borderRight.color;
      }
      if (col.borderBottom && Object.keys(col.borderBottom).length) {
        width.bottom = col.borderBottom.width;
        style.bottom = col.borderBottom.style;
        color.bottom = col.borderBottom.color;
      }
      if (col.borderLeft && Object.keys(col.borderLeft).length) {
        width.left = col.borderLeft.width;
        style.left = col.borderLeft.style;
        color.left = col.borderLeft.color;
      }
      let str = `border-top:${width.top}px ${style.top} ${color.top};
      border-right:${width.right}px ${style.right} ${color.right};
      border-bottom:${width.bottom}px ${style.bottom} ${color.bottom};
      border-left:${width.left}px ${style.left} ${color.left};`;
      if (col.height) {
        str += `height:${col.height}px;`;
      }
      return str;
    },
    getMetaOptions(data) {
      // 获取select,radiogroup,checkGroup的关联其他表单数据
      this.$emit("getMetaOptions", data);
    },
    changeFormData({ name, value, type, callback }) {
      this.$emit("changeFormData", { name, value, type, callback });
    },
  },
};
</script>
<style lang="scss" scoped>
.grid-table {
  border-collapse: collapse;
  width: max-content;
  td {
    padding: 10px;
    ::v-deep {
      .el-form-item {
        padding: 0;
        .el-date-editor {
          input {
            padding-left: 30px;
          }
        }
        input,
        textarea {
          border: 0;
          padding: 10px;
          background-color: rgba(64, 158, 255, 0.08);
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
