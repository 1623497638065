<template>
  <div style="padding: 0 10px 10px 10px">
    <div v-if="parent.componentName == 'tab'">
      <el-tabs
        @tab-click="handleTabClick"
        v-model="activeName"
        :style="`color:${titleColor}`"
      >
        <template v-for="(tab, index) in parent.components">
          <el-tab-pane
            :label="tab.title"
            :key="tab.key"
            :name="`${index}`"
          ></el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div
      v-else-if="!parent.hiddenTitle"
      class="title"
      :style="`color:${titleColor}`"
    >
      {{ parent.extParam.title }}
    </div>
    <draggable
      v-model="list"
      animation="300"
      :group="`container-column${parent.key}`"
    >
      <transition-group>
        <template v-for="component in list">
          <div
            v-if="!component.wapConfig.hidden"
            class="draggable-container-card"
            :key="component.key"
            :style="calDashItemContainerHeight(component)"
          >
            <div class="draggable-container-card-content">
              <span
                class="iconfont icon-eye-off-fill"
                @click="handleHideView(component)"
              ></span>
              <div
                class="title"
                v-if="isShowTitle(component)"
                style="padding-left: 10px"
              >
                {{ component.extParam.title }}
              </div>
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  z-index: 999;
                  top: 0;
                  left: 0;
                "
              ></div>
              <div style="height: 0; flex: 1">
                <slot name="chart" :component="component"></slot>
              </div>
            </div>
          </div>
        </template>
      </transition-group>
    </draggable>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  data() {
    return {
      activeName: "0",
    };
  },
  components: {
    Draggable,
  },
  props: {
    parent: {
      type: Object,
      default: () => {},
    },
    hiddenComponentList: {
      type: Array,
      default: () => [],
    },
    calDashItemContainerHeight: {
      type: Function,
      default() {
        return () => "";
      },
    },
    isShowTitle: {
      type: Function,
      default() {
        return () => "";
      },
    },
  },
  methods: {
    handleTabClick() {
      this.$nextTick(() => {
        this.$emit("fetchDashboardData", this.list);
      });
    },
    handleHideView(item) {
      this.hiddenComponentList.push(item);
      item.wapConfig.hidden = true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const activeBar = this.$el.querySelector("div.el-tabs__active-bar");
      if (activeBar && this.titleColor) {
        activeBar.style.backgroundColor = this.titleColor;
      }
    });
  },
  computed: {
    titleColor() {
      return this.parent?.colorConfig?.titleColor;
    },
    list: {
      get() {
        if (this.parent.componentName == "tab") {
          let tab = this.parent.components[this.activeName];
          if (tab) {
            return tab.components;
          }
          return [];
        } else {
          return this.parent.components;
        }
      },
      set(val) {
        if (this.parent.componentName == "tab" && this.parent.components[0]) {
          this.parent.components[0].components = val;
        } else {
          this.parent.components = val;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.draggable-container-card {
  position: relative;
  margin-top: 10px;
  border: 1px solid transparent;
  &:hover {
    cursor: move;
    border: 1px solid #2323e3;
    .iconfont {
      cursor: pointer;
      display: inline-block;
      z-index: 9999;
    }
  }
  .draggable-container-card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.iconfont {
  display: none;
  position: absolute;
  margin-right: 0px;
  top: 0;
  right: 0;
  padding: 2px;
  border-radius: 2px;
  background-color: #fff;
  z-index: 11;
  &:hover {
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
.title {
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  color: rgba($color: #091e42, $alpha: 0.95);
  font-weight: 600;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item {
  color: unset;
  &:hover {
    color: unset;
  }
}
::v-deep .el-tabs__item.is-active {
  color: unset;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0.5px;
}
::v-deep .el-tabs__active-bar {
  background-color: #172b4d;
}
</style>
