<template xmlns="http://www.w3.org/1999/html">
  <el-dialog
    append-to-body
    destroy-on-close
    :visible.sync="dialogVisible"
    fullscreen
    @open="openDialog"
    @close="handleClose"
    :show-close="false"
  >
    <div slot="title" style="display: flex; flex-direction: row">
      <span style="flex: 1; display: flex; align-items: center"
        >编辑嵌入组件</span
      >
      <div>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </div>
    <div class="include-setting-content__wrap">
      <div class="include-images-container card-border">
        <iframe
          ref="includeFrame"
          v-if="isShowIframe"
          frameborder="0"
          style="height: 100%; width: 100%; border-radius: 4px"
        ></iframe>
      </div>
      <div class="card-border include-images-setting__wrap">
        <el-tabs :stretch="true">
          <el-tab-pane label="设置" name="imageSetting"></el-tab-pane>
        </el-tabs>
        <div style="padding: 0px 20px">
          <el-form
            label-position="top"
            size="small"
            :rules="rules"
            :model="config"
            ref="includeImageConfigForm"
          >
            <el-form-item label="链接地址" prop="includeType">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="config.url"
              >
              </el-input
            ></el-form-item>
            <el-button type="primary" @click="previewIncludeFrame"
              >预览页面</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "dashboard-include-edit",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      config: {
        title: "",
        includeType: "url",
        url: "",
      },
      isShowIframe: false,
      rules: {
        "config.url": [
          {
            type: "url",
            required: true,
            trigger: "change",
            message: "请输入正确的链接地址",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    refreshIframe() {
      this.isShowIframe = false;
      this.$nextTick(() => {
        this.isShowIframe = true;
        this.$nextTick(() => {
          this.$refs.includeFrame.src = this.config.url;
        });
      });
    },
    previewIncludeFrame() {
      this.refreshIframe();
    },
    manualInit(model) {
      console.log(model);
      this.dialogVisible = true;
      this.$set(this, "config", this._.cloneDeep(model));
      this.refreshIframe();
    },
    validateImageSetting() {
      this.$nextTick(() => {
        this.$refs.includeImageConfigForm.validate();
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.$emit("cancel");
    },

    confirm() {
      this.$emit("save", this.config);
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
      this.$nextTick(() => {
        Object.assign(this.$data, this.$options.data());
      });
    },
    openDialog() {},
  },
};
</script>
<style lang="scss" scoped>
$gap-size: 10px;
$header-height: 76px;

.include-setting-content__wrap {
  padding: $gap-size;
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{$header-height});
  background-color: #f2f6fc;
  ::v-deep {
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
  }
  .include-images-container {
    flex: 1;
    background-color: white;
    padding: 10px;
  }
  .drag-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: $gap-size;
  }
  .include-images-setting__wrap {
    width: 256px;
    margin-left: $gap-size;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
}
.include-images-container__item {
  width: 240px;
  height: 135px;
  background: #f2f6fc;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  &:hover {
    .include-images-container__item__action {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.active {
    img {
      border: 2px solid #409eff;
      border-radius: 5px;
    }
  }
}
.include-images-container__item__img {
  width: 100%;
  height: 100%;
  padding: 2px;
  border-radius: 5px;
}
.include-images-container__item__action {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  background: #a5adba;
  border-radius: 0px 5px;
  width: 30px;
  height: 22px;
  color: white;
}
.add-image {
  background: #ffffff;
  /* border/light */

  border: 1px solid #ebecf0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5adba;
  font-size: 14px;
  line-height: 24px;
  ::v-deep {
    .zgg-upload_container {
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .upload-container {
      width: 100%;
      height: 100%;

      .el-upload {
        width: 100%;
        height: 100%;

        .el-upload-dragger {
          border: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  &:hover {
    border: 2px solid #409eff;
    color: #409eff;
  }
}

.card-border {
  border-radius: 10px;
}
</style>
