<template>
  <div>
    <div  class="custom-dialog-title-wrap">
      <span class="custom-dialog-title-content">编辑看板-{{ $route.query.title }}</span>
      <el-link icon="el-icon-setting" :underline="false" @click="openBoardSetting"></el-link>
    </div>
      <div class="cart-container">
        <draggable
            :list="columnList"
            :scroll="true"
            animation="700"
            @update="boardUpdate"
            :move="onBoardMove"
            handle=".col-title"
            :scroll-sensitivity="300"
            force-fallback="true"
            ghost-class="ghostClass"
            chosen-class="chosenClass"
            drag-class="dragClass"
            scroll-speed="20"
            fallback-tolerance="20"
        >
          <column-item
              v-for="(col, index) in columnList"
              :key="index"
              :index="index"
              :col="col"
              :columnList="columnList"
              @columnRefresh="fetchColumnList"
              @updateColumn="updateColumn"
              @showMoveColumn="showMoveColumn"
              @loadMoreCard="fetchColumnCardList"
              @boardRefresh="boardRefresh"
          ></column-item>
        </draggable>
        <column-add
            :bulletinId="bulletinId"
            @addColumn="updateColumn"
        ></column-add>
      </div>
    <el-dialog
        title="移动卡片"
        :append-to-body="true"
        :visible.sync="moveColumn"
        width="600px"
        @opened="closeMove"
    >
      <el-form
          :model="moveCard"
          ref="moveCard"
          :rules="rules"
          style="padding: 20px"
      >
        <el-form-item label="卡片名称">
          <div>{{ moveCard.title }}</div>
        </el-form-item>
        <el-form-item label="卡片原始栏目">
          <div>{{ columnName }}</div>
        </el-form-item>
        <el-form-item label="目标栏目" size="normal" prop="targetColumnId">
          <el-select
              v-model="moveCard.targetColumnId"
              placeholder="请选择目标栏目"
          >
            <el-option
                v-for="item in columnList.filter(
                (item) => item.id != moveCard.bulletinColumnId
              )"
                :key="item.id"
                :label="item.title"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="moveColumn = false" size="small">取消</el-button>
        <el-button
            @click="saveColumnSort"
            type="primary"
            :loading="sortColumnLoading"
            size="small"
        >确定</el-button
        >
      </span></el-dialog
    >
    <el-drawer
        title="设置"
        :visible.sync="boardSettingVisible"
        @open="boardSettingDataInit"
    >
      <div style="margin: 0 10px">
        <el-form >
          <el-form-item label="是否仅通知卡片参与人">
            <el-switch
                @change="handleSettingChange"
                v-model="setting.onlyNoticeToCardUser"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
        </el-form>
      </div>


    </el-drawer>
  </div>
</template>
<script>
import {fetchBulletinColumn, fetchBulletingCart} from "@/api/board";
import {
  bulletinCardList,
  bulletinColumnList,
  bulletinColumnSort,
  bulletinPage,
  fetchBulletinCardList,
  resetBulletinColumn,
  bulletinCardSort,
  bulletinDetail, bulletinSaveSetting, bulletinLoadSetting,
} from "@/api/boardManage";
import columnAdd from "./board/columnAdd";
import columnItem from "./board/columnItem";
import Draggable from "vuedraggable";

export default {
  components: {
    columnAdd,
    Draggable,
    columnItem,
  },
  props: {
  },
  computed: {
    columnName() {
      let item = this.columnList.find(
          (item) => item.id == this.moveCard.bulletinColumnId
      );

      if (item) {
        return item.title;
      }
      return "";
    },
  },
  mounted(){
    // alert(this.$route.params.bulletinId)
    this.bulletinId = this.$route.params.bulletinId
    document.title = this.$route.query.title
    this.getBulletinColumnList();
    this.apiLoadSetting()
  },
  data() {
    return {
      newColumnTitle: "",
      moveColumn: false,
      boardFormVisible: false,
      taskFormVisible: false,
      addingCol: "",
      taskForm: {},
      columnList: [],
      sortColumnLoading: false,
      moveCard: {
        targetColumnId: "",
      },
      rules: {
        targetColumnId: [{required: true, message: "请选择目标栏目"}],
      },
      boardSettingVisible: false,
      setting:{
        onlyNoticeToCardUser:false
      },
      bulletinId:''
    };
  },
  methods: {
    handleSettingChange(val){
        let param = {
          id:this.bulletinId,
          setting:this.setting
        }
        bulletinSaveSetting(param).then(resp=>{
          this.$message.success("操作成功")
        })
    },
    boardSettingDataInit(){

    },
    apiLoadSetting(){

        bulletinLoadSetting({
          id:this.bulletinId
        }).then(resp=>{
          this.setting = resp.data.setting?resp.data.setting:{}
        })
    },
    openBoardSetting() {
      this.boardSettingVisible = true
    },
    saveColumnSort() {
      this.$refs.moveCard.validate((valid) => {
        if (!valid) {
          return;
        }
        let column = this.columnList.find(
            (item) => item.id == this.moveCard.targetColumnId
        );

        //排序改传前一个卡片id

        let preCardId = 0;
        if (column.cardList && column.cardList.length) {
          preCardId = column.cardList[0].id;
        }

        let params = {
          cardId: this.moveCard.id,
          preCardId: preCardId,
          toBulletinId: this.bulletinId,
          toColumnId: column.id,
        };

        if (!preCardId) {
          params.sortNumber = 1;
        }

        this.sortColumnLoading = true;
        bulletinCardSort(params)
            .then(({data}) => {
              let oldColumn = this.columnList.find(
                  (item) => item.id == this.moveCard.bulletinColumnId
              );
              let cardIndex = oldColumn.cardList.findIndex(
                  (item) => item.id == this.moveCard.id
              );

              let card = oldColumn.cardList.splice(cardIndex, 1)[0];
              card.bulletinColumnId = column.id;
              column.cardList.splice(0, 0, card);
              this.$refs.moveCard.resetFields();
              this.moveColumn = false;
              this.sortColumnLoading = false;
              this.$message.success(`卡片成功移动到【${column.title}】栏目中`);
            })
            .catch(() => {
              this.sortColumnLoading = false;
            });
      });
    },
    closeMove() {
      if (this.$refs.moveCard) {
        this.$refs.moveCard.clearValidate();
      }
    },
    //分组列表列拖拽完成事件
    onBoardMove(e) {
      this.draggedColumn = e.draggedContext.element;
    },

    boardUpdate(e) {
      let columnIndex = this.columnList.findIndex(
          (item) => item.id == this.draggedColumn.id
      );
      this.boardColumnSort(this.draggedColumn.id, columnIndex);
    },
    boardColumnSort(columnId, columnIndex, callback) {
      let preIndex = 0;
      let sortNumber = 1;
      let preColumnId = 0;
      if (columnIndex - 1 >= 0) {
        preIndex = columnIndex - 1;
        // sortNumber = this.columnList[preIndex].sortNumber + 1;
        preColumnId = this.columnList[preIndex].id;
      }
      let params = {
        columnId,
        toBulletinId: this.bulletinId,
        preColumnId,
      };
      if (!preColumnId) {
        params.sortNumber = 1;
      }

      bulletinColumnSort(params).then((res) => {
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    boardRefresh() {
      //todo 暂时先刷新当前的刷新方式，
      let id = this.$route.query.bulletinId;
      if (!id) {
        return;
      }

      bulletinDetail({id}).then((res) => {
        this.bulletinDetail = res.data.model;
        this.bulletinId = id;
        if (
            !this.category &&
            this.bulletinDetail.param &&
            this.bulletinDetail.param.category
        ) {
          this.$emit("row-detail", {
            recordId: id,
            category: this.bulletinDetail.param.category,
          });
        }
        // if (res.data.model.hasColumn) {
        this.fetchColumnList(id);
        // } else {
        //   this.fetchCardList({ bulletinId: id }, 1);
        // }
      });
    },
    showMoveColumn(card) {
      if (this.columnList.length < 2) {
        this.$message.warning("当前看板没有其他目标列可供卡片移动");
        return;
      }
      this.moveColumn = true;
      this.moveCard = this._.cloneDeep(card);
    },
    updateColumn(model) {
      let index = this.columnList.findIndex((item) => item.id == model.id);
      if (index == -1) {
        model.cardList = [];
        this.columnList.push(model);
      } else {
        this.$set(this.columnList, index, model);
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
    closed() {
      this.$set(this, "columnList", []);
    },
    open() {
      this.getBulletinColumnList();
      this.apiLoadSetting()
    },
    fetchColumnList(bulletinId, callback) {
      this.loading = true;
      bulletinColumnList({
        query: JSON.stringify({
          bulletinId,
          archived: false,
        }),
      }).then((res) => {
        this.columnList = res.data.modelList;
        this.loading = false;
        this.$nextTick(() => {
          if (typeof callback === "function") {
            callback();
          }
          this.columnList.forEach((item, index) => {
            let params = {
              query: JSON.stringify({
                bulletinId: bulletinId,
                bulletinColumnId: item.id,
                archived: false,
              }),
            };

            this.fetchColumnCardList(bulletinId, item.id, 1);

            // bulletinCardList(params).then(({ data }) => {
            //   this.$set(this.columnList[index], "cardList", data.modelList);
            // });
          });
        });
      });
    },
    getBulletinColumnList() {
      fetchBulletinColumn({
        pageSize: 9999,
        pageNumber: 1,
        query: JSON.stringify({
          bulletinId: this.bulletinId,
          archived: false,
        }),
      }).then((res) => {
        let columnList = res.data.page.list;

        this.$set(this, "columnList", columnList);
        this.$nextTick(() => {
          this.columnList.forEach((item) => {
            this.fetchColumnCardList(this.bulletinId, item.id, 1);
          });
        });
      });
    },
    fetchColumnCardList(bulletinId, bulletinColumnId, pageNumber = 1) {
      let column = this.columnList.find((item) => item.id == bulletinColumnId);
      if (column.loading) {
        return;
      }
      let params = {
        query: JSON.stringify({
          bulletinId: bulletinId,
          bulletinColumnId: bulletinColumnId,
          archived: false,
        }),
        pageSize: 15,
        pageNumber,
      };
      this.$set(column, "loading", true);
      fetchBulletingCart(params)
          .then((res) => {
            this.$set(column, "loading", false);
            let list = res.data.page.list;

            this.$set(column, "pageNumber", res.data.page.pageNumber);
            this.$set(column, "totalPage", res.data.page.totalPage);
            this.$set(column, "totalRow", res.data.page.totalRow);
            if (res.data.page.pageNumber == 1) {
              this.$set(column, "cardList", list);
            } else {
              list = list.filter(
                  (card) =>
                      column.cardList.findIndex((item) => item.id == card.id) == -1
              );

              if (list.length) {
                column.cardList = column.cardList.concat(list);
              } else if (res.data.page.pageNumber < res.data.page.totalPage) {
                // 数据重新请求
                this.fetchColumnCardList(
                    bulletinId,
                    bulletinColumnId,
                    res.data.page.pageNumber + 1
                );
                return;
              }
            }
          })
          .catch(() => {
            this.$set(column, "loading", false);
          });
    },
  },
};
</script>
<style lang="scss" scoped>
.cart-container {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background: #ecf5ff;
  padding: 20px;
  display: flex;
  height: calc(100vh - 0px);
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto hidden;
}

.ghostClass {
  //border: solid 2px #3089dc !important;
  //transform: rotate(7deg);
  opacity: 0.2 !important;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3090dc;
    z-index: 9999;
    border: solid 1px #3089dc !important;
  }
}

.dragClass {
  border: solid 2px #3089dc !important;
  transform: rotate(3deg);
  opacity: 1 !important;
  //background-color: orange;
}

.custom-dialog-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 30px;
  height: 30px;
  align-items: center;

  .custom-dialog-title-content {
    flex: 1;
  }
}
</style>
