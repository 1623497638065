<template>
  <div class="item">
    <div class="column-header">
      <el-input
          v-model="title"
          size="small"
          placeholder="请输入分组名称"
          v-show="isEdit && !isDisable"
          @blur="columnTitleSave"
          @keyup.enter.native="columnTitleSave"
          autofocus
          ref="inputRef"
          class="column-header-title"
      ></el-input>

      <div
          v-show="!isEdit || !isDisable"
          @click="
          title = col.title;
          isEdit = true;
          handleColumnTitleEditTrigger();
        "
          class="col-title"
      >
        {{ col.title }}
      </div>
      <el-dropdown v-if="!isEdit" v-show="!isDisable" trigger="hover">
        <i class="el-icon-more more"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="copyColumn"
          >复制列
          </el-dropdown-item>
          <el-dropdown-item @click.native="editTitle"
          >修改名称
          </el-dropdown-item>
          <el-dropdown-item @click.native="delColumn">删除</el-dropdown-item>
          <!-- <el-dropdown-item @click.native="archiveColumn"
            >归档本组
          </el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div
        class="add-position"
        v-if="
        !col.archived && !isAdding2 && col.cardList && col.cardList.length > 0
      "
    >
      <el-button
          class="white-border btn-flex"
          plain
          size="small"
          icon="el-icon-plus"
          @click="handleAddCardButtonClick2"
      >添加任务
      </el-button>
      <card-tpl
          :key="col.id"
          :columnList="columnList"
          :bulletinId="col.bulletinId"
          :colId="col.id"
          :list="list"
          :page="page"
          @fetchList="fetchColumnCardList"
      ></card-tpl>
    </div>

    <div
        class="column-scroll"
        :native="false"
        :wrap-class="'board-scroll-wrap'"
        ref="elScroller"
        v-infinite-scroll="loadMoreCard"
    >
      <div v-if="isAdding2" class="add-card-item">
        <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            placeholder="请输入任务内容"
            v-model="quickCardTitle2"
            resize="none"
            @input="scrollToTop"
            @blur="handleQuickAddSave2($event)"
            autofocus
            ref="addCardInput2"
            @keydown.native="handleQuickAddInputListen2($event)"
            clearable
        >
        </el-input>
        <div
            style="
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          "
        >
          <div
              style="
              height: 32px;
              line-height: 32px;
              color: #6b778c;
              font-size: 14px;
              margin-right: 10px;
            "
          >
            <span @mousedown="handleQuickAddCancel2">取消</span>
          </div>
          <el-button
              class="white-border"
              type="primary"
              size="small"
              icon="el-icon-plus"
          >添加
          </el-button>
        </div>
      </div>
      <draggable
          v-if="!isDisable"
          :list="col.cardList"
          class="scrollBar"
          :move="onCardMove"
          :scroll="true"
          :set-data="setData"
          @update="cardUpdate"
          @add="cardUpdate"
          group="card"
          animation="500"
          :scroll-sensitivity="200"
          force-fallback="true"
          drag-class="dragClass"
          ghost-class="ghostClass"
          fallback-tolerance="10"
          handle=".card-item"
      >
        <card-item
            v-for="(card, index) in col.cardList"
            :key="card.id"
            :index="index"
            :card="card"
            :columnList="columnList"
            @columnRefresh="columnRefresh"
            @updateCard="updateCard"
            @deleteItem="deleteItem"
            @showMoveColumn="showMoveColumn"
        ></card-item>
        <div v-if="isAdding" class="add-card-item">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              placeholder="请输入任务内容"
              v-model="quickCardTitle"
              resize="none"
              @input="scrollToBottom"
              @blur="handleQuickAddSave($event)"
              autofocus
              ref="addCardInput"
              @keydown.native="handleQuickAddInputListen($event)"
              clearable
          >
          </el-input>
          <div
              style="
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            "
          >
            <div
                style="
                height: 32px;
                line-height: 32px;
                color: #6b778c;
                font-size: 14px;
                margin-right: 10px;
              "
            >
              <span @mousedown="handleQuickAddCancel">取消</span>
            </div>
            <el-button
                class="white-border"
                type="primary"
                size="small"
                icon="el-icon-plus"
            >添加
            </el-button>
          </div>
        </div>
        <div v-if="!(col.cardList && col.cardList.length)"></div>
      </draggable>
    </div>
    <div v-if="isDisable">
      <card-item
          v-for="(card, index) in col.cardList"
          :key="card.id"
          :card="card"
          :index="index"
          :columnList="columnList"
          @columnRefresh="columnRefresh"
          @updateCard="updateCard"
          @deleteItem="deleteItem"
          @showMoveColumn="showMoveColumn"
      ></card-item>
      <div v-if="!(col.cardList && col.cardList.length)"></div>
    </div>
    <div v-if="isDisable">
      <card-item
          v-for="(card, index) in col.cardList"
          :key="card.id"
          :index="index"
          :card="card"
          :columnList="columnList"
          @columnRefresh="columnRefresh"
          @updateCard="updateCard"
          @deleteItem="deleteItem"
          @showMoveColumn="showMoveColumn"
      ></card-item>
    </div>

    <div class="add-position" v-if="!col.archived && !isAdding">
      <el-button
          class="white-border btn-flex"
          plain
          size="small"
          icon="el-icon-plus"
          @click="handleAddCardButtonClick"
      >添加任务
      </el-button>
      <card-tpl
          :key="col.id"
          :columnList="columnList"
          :bulletinId="col.bulletinId"
          :colId="col.id"
          :list="list"
          :page="page"
          @fetchList="fetchColumnCardList"
      ></card-tpl>
    </div>
    <copy-column
        :visible.sync="copyVisible"
        :col="col"
        :index="index"
        @copyDone="copyDone"
    ></copy-column>
  </div>
</template>
<script>
import {
  bulletinCardSave,
  bulletinCardSort,
  bulletinColumnArchive,
  bulletinColumnCopy,
  bulletinColumnSave,
  fetchBulletinCardList,
  delColumn,
} from "@/api/boardManage";
import draggable from "vuedraggable";
import CardItem from "./CardItem";
import addCard from "./addCard";

import CopyColumn from "./CopyColumn";
import CardTpl from "./cardTpl";

let cardId;

export default {
  components: {draggable, CardItem, addCard, CopyColumn, CardTpl},
  props: {
    col: Object,
    index: Number,
    columnList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    isDisable() {
      return this.col && this.col.archived;
    },
  },
  created() {
    this.fetchColumnCardList(1);
  },
  data() {
    return {
      tplVisible: false,
      isEdit: false,
      title: "",
      isAdding: false,
      isAdding2: false,
      quickCardTitle: "",
      quickCardTitle2: "",
      copyVisible: false,
      page: {
        pageSize: 15,
        pageNumber: 1,
        totalPage: 0,
        totalRow: 0,
      },
      list: [],
      loading: false,
    };
  },
  watch: {
    isAdding: {
      handler(val, oldVal) {
        this.handleHeight(val);
      },
    },
    isAdding2: {
      handler(val, oldVal) {
        this.handleHeight2(val);
      },
    },
  },
  mounted(){

  },
  methods: {
    delColumn() {
      this.$confirm("确定要删除吗", "提示").then(() => {
        delColumn(this.col.id).then((res) => {
          this.$message.success("删除成功");
          this.columnList.splice(this.index, 1);
        });
      });
    },
    fetchColumnCardList(pageNumber = 1) {
      if (this.loading) {
        return;
      }
      let params = {
        query: JSON.stringify({
          bulletinId: this.col.bulletinId,
          bulletinColumnId: this.col.id,
          archived: false,
          templated: true,
        }),
        pageSize: 15,
        pageNumber,
      };
      this.loading = true;
      fetchBulletinCardList(params)
          .then((res) => {
            this.loading = false;
            let list = res.data.page.list;
            this.page.pageNumber = res.data.page.pageNumber;
            this.page.totalPage = res.data.page.totalPage;
            this.page.totalRow = res.data.page.totalRow;
            if (res.data.page.pageNumber == 1) {
              this.list = list;
            } else {
              list = list.filter(
                  (card) => this.list.findIndex((item) => item.id == card.id) == -1
              );

              if (list.length) {
                console.log(this.list)
                this.list = this.list.concat(list);
                console.log(this.list)
              } else if (res.data.page.pageNumber < res.data.page.totalPage) {
                // 数据重新请求
                this.fetchColumnCardList(res.data.page.pageNumber + 1);
                return;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
    },
    copyDone() {
      this.$emit("boardRefresh");
    },
    copyColumn() {
      console.log(this.col);
      this.copyVisible = true;
      return;
      bulletinColumnCopy(params).then((res) => {
        this.$message.success("复制成功");
      });
    },
    handleHeight(val) {
      if (val) {
        this.$refs.elScroller.style.maxHeight = "calc(100vh - 175px - 50px)";
      } else {
        this.$refs.elScroller.style.maxHeight = "calc(100vh - 215px - 42px)";
      }
    },
    handleHeight2(val) {
      if (val) {
        this.$refs.elScroller.style.maxHeight = "calc(100vh - 175px - 50px)";
      } else {
        this.$refs.elScroller.style.maxHeight = "calc(100vh - 215px - 42px)";
      }
    },
    handleQuickAddCancel() {
      this.quickCardTitle = "";
      this.isAdding = false;
    },
    handleQuickAddCancel2() {
      this.quickCardTitle2 = "";
      this.isAdding2 = false;
    },
    handleQuickAddInputListen(event) {
      if (event.keyCode === 13) {
        this.handleQuickAddSave();
        event.preventDefault();
        return false;
      }
    },
    handleQuickAddInputListen2(event) {
      if (event.keyCode === 13) {
        this.handleQuickAddSave2();
        event.preventDefault();
        return false;
      }
    },
    handleQuickAddSave(e) {
      if (this.quickCardTitle) {
        let data = {
          bulletinId: this.col.bulletinId,
          bulletinColumnId: this.col.id,
          title: this.quickCardTitle,
        };
        bulletinCardSave({
          model: JSON.stringify(data),
        }).then((resp) => {
          this.updateCard(resp.data.model);
          // this.isAdding = false
          this.quickCardTitle = "";
          this.scrollToBottom();
          setTimeout(() => {
            this.$refs.addCardInput.focus();
            this.handleHeight(this.isAdding);
          }, 1);
        });
      } else {
        this.isAdding = false;
      }
    },
    handleQuickAddSave2(e) {
      if (this.quickCardTitle2) {
        let data = {
          bulletinId: this.col.bulletinId,
          bulletinColumnId: this.col.id,
          title: this.quickCardTitle2,
          sortNumber: -1,
        };
        bulletinCardSave({
          model: JSON.stringify(data),
        }).then((resp) => {
          this.updateCard(resp.data.model, true);
          // this.isAdding = false
          this.quickCardTitle2 = "";
          this.scrollToTop();
          setTimeout(() => {
            this.$refs.addCardInput2.focus();
            // this.handleHeight(this.isAdding)
          }, 1);
        });
      } else {
        this.isAdding2 = false;
      }
    },
    handleAddCardButtonClick() {
      this.isAdding = true;
      this.scrollToBottom();
      setTimeout(() => {
        this.$refs.addCardInput.focus();
      }, 1);
    },
    handleAddCardButtonClick2() {
      this.isAdding2 = true;
      this.scrollToTop();
      setTimeout(() => {
        this.$refs.addCardInput2 && this.$refs.addCardInput2.focus();
      }, 1);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs["elScroller"]) {
          this.$refs["elScroller"].scrollTop =
              this.$refs["elScroller"].scrollHeight;
        }
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        if (this.$refs["elScroller"]) {
          this.$refs["elScroller"].scrollTop = 0;
        }
      });
    },
    handleColumnTitleEditTrigger() {
      setTimeout(() => {
        this.$refs.inputRef.focus();
      }, 1);
    },
    showMoveColumn(card) {
      this.$emit("showMoveColumn", card);
    },
    deleteItem(id) {
      let index = this.col.cardList.findIndex((item) => item.id == id);
      this.col.cardList.splice(index, 1);
    },
    columnTitleSave() {
      if (this.title && this.title != this.col.title) {
        let params = {
          id: this.col.id,
          bulletinId: this.col.bulletinId,
          title: this.title,
        };
        bulletinColumnSave({model: JSON.stringify(params)}).then((res) => {
          this.isEdit = false;
          this.col.title = this.title;
          this.$message.success("分组名称修改成功");
        });
      } else {
        this.isEdit = false;
      }
    },
    updateColumn(model) {
    },
    updateCard(model, top) {
      let index =
          this.col.cardList &&
          this.col.cardList.findIndex((item) => item.id == model.id);

      if (index >= 0) {
        this.$set(this.col.cardList, index, model);
      } else {
        if (!this.col.cardList) {
          this.$set(this.col, "cardList", [model]);
        } else {
          if (top) {
            this.col.cardList.unshift(model);
          } else {
            this.col.cardList.push(model);
          }
        }

        this.$nextTick(() => {
          if (this.$refs["elScroller"] && this.$refs["elScroller"].wrap) {
            if (top) {
              this.$refs["elScroller"].wrap.scrollTop = 0;
            } else {
              this.$refs["elScroller"].wrap.scrollTop =
                  this.$refs["elScroller"].wrap.scrollHeight;
            }
          }
        });
      }
    },
    //卡片拖拽完成事件,同列排序
    cardUpdate(e) {
      console.log(this.col.cardList);
      let cardIndex = this.col.cardList.findIndex((item) => item.id == cardId);
      //排序改传前一个卡片id
      let preIndex = 0;
      let preCardId = 0;
      if (cardIndex - 1 >= 0) {
        preIndex = cardIndex - 1;
        preCardId = this.col.cardList[preIndex].id;
      }
      let params = {
        cardId: cardId,
        preCardId,
        toBulletinId: this.col.bulletinId,
        toColumnId: this.col.id,
      };
      if (!preCardId) {
        params.sortNumber = 1;
      }

      bulletinCardSort(params).then(({data}) => {
      });
    },

    setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/Sortable/issues/1012
      dataTransfer.setData("Text", "");
    },
    onCardMove(e) {
      cardId = e.draggedContext.element.id;
      return true;
    },
    columnRefresh() {
      this.$emit("columnRefresh", this.col.bulletinId);
    },
    editTitle() {
      this.$prompt("请输入分组名称", "修改分组名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputValue: this.col.title,
        // inputType: "textarea",
        inputValidator: function (val) {
          if (!val) {
            return "分组名不能为空";
          }
        },
      })
          .then(({value}) => {
            let params = {
              id: this.col.id,
              bulletinId: this.col.bulletinId,
              title: value,
            };
            bulletinColumnSave({
              model: JSON.stringify(params),
            }).then((res) => {
              this.col.title = value;
              this.$message.success("分组名称修改成功");
            });
          })
          .catch((error) => {
            this.$message.success("取消修改");
          });
    },
    archiveColumn() {
      this.$confirm("是否归档分组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            bulletinColumnArchive({id: this.col.id}).then((res) => {
              this.$message.success("归档成功");
              this.columnRefresh();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消归档",
            });
          });
    },
    loadMoreCard() {
      if (this.col.loading) {
        return;
      }
      if (this.col.pageNumber >= this.col.totalPage) {
        return;
      }
      if (!this.col.pageNumber) {
        this.col.pageNumber = 0;
      }
      this.$emit(
          "loadMoreCard",
          this.col.bulletinId,
          this.col.id,
          this.col.pageNumber + 1
      );
      this.scrollToBottom();
    },
  },
};
</script>
<style lang="scss" scoped>
.ghostClass {
  opacity: 0.2 !important;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3090dc;
    z-index: 9999;
    border: solid 1px #3089dc !important;
  }
}

.dragClass {
  border: solid 2px #3089dc !important;
  transform: rotate(3deg);
  //background-color: orange;
}

.add-card-item {
  width: 270px;
  margin: 10px;
  //background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    //background-color: #f4f5f7;
    background-color: #e2f1ff;
  }
}

.item {
  width: 290px;
  border-radius: 5px;
  background-color: #d9ecff;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.add-position {
  // position: absolute;
  // right: 0px;
  // left: 0px;
  // bottom: 0px;
  text-align: center;
  background-color: #d9ecff;
  //padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.btn-flex {
  flex: 1;
}

.column-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
}

.col-title {
  text-align: left;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  padding: 2px 0;
}

.more {
  transform: rotate(90deg);
}

.board-scroll-wrap {
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrollBar {
  // padding-bottom: 55px;
  min-height: 20px;
}
</style>
<style lang="less">
.column-header-title {
  height: 33px;

  .el-input__inner {
    padding: 0 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.column-scroll {
  flex: 1;
  overflow: auto;
  margin-bottom: 10px;
  max-height: calc(100vh - 215px - 42px);

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
  }
}
</style>
