import { checkFieldAbility } from "@/zgg-core/component-white-list";
import { getFormComponents, isEmpty, isNumber } from "@/zgg-core/utils";
import {
  buildFormulaObj,
  fieldRex,
  funRex,
  getFieldNameAndId,
  getFunNameAndId,
} from "./formula";

export default {
  props: {
    fetchMetaFieldComponentList: Function, // 获取表单元数据
    fetchRobotParams: Function, // 获取智能助手流程参数
  },
  data() {
    return {
      loading: false,
      formMap: {}, // 关联表的组件列表
    };
  },
  methods: {
    /** 构建流程自动化节点组件列表 */
    async buildRpaComponents() {
      let nodeList = this._.cloneDeep(this.treeNodeList);

      this.loading = true;
      for (let i = 0; i < nodeList.length; i++) {
        let node = nodeList[i];

        if (node.type == "root") {
          // root
          continue;
        }

        if (node.type == "subflow_collection") {
          let obj = nodeList.find(
            (item) =>
              item.robotId == node.robotId &&
              item.type == "subflow_collection" &&
              item.components &&
              item.components.length,
          );
          if (obj) {
            // 关联同一个robotId的可以不用继续请求数据了
            node.components = obj.components;
          } else {
            /**
             * @type {Array}
             */
            let flowParams = await this.fetchRobotParams(node.robotId);
            flowParams.forEach((item) => {
              item.nodeKey = node.key;
            });
            node.components = flowParams;
          }
          continue;
        } else if (node.type == "developer_plugin") {
          let list = [];
          node.responseParams.forEach((res) => {
            list.push({
              componentName: res.dataType,
              key: res.name,
              name: res.name,
              title: res.title,
            });
          });
          node.components = list;
          continue;
        } else if (node.type == "json_parser") {
          let list = [];
          node.outputFields
            .filter(
              (item) =>
                ![
                  "reference_data",
                  "json_form",
                  "form_data",
                  "reference_data_list",
                ].includes(item.componentName),
            )
            .forEach((item) => {
              let obj = {
                key: item.name,
                name: item.name,
                title: item.title,
                componentName: item.componentName,
                form: true,
              };
              list.push(obj);
            });

          node.components = list;
          continue;
        }

        let tableName;

        if (
          ["node_data_field", "node_data"].includes(node.dataSource) &&
          node.nodeField.componentName == "json_form" &&
          isEmpty(node.nodeField.referenceCollection)
        ) {
          // 非实体子表单模式

          let jsonParserNode = nodeList.find(
            (item) =>
              item.key == node.nodeField.nodeKey && item.type == "json_parser",
          );
          if (jsonParserNode) {
            let component = jsonParserNode.outputFields.find(
              (item) => item.name == node.nodeField.name,
            );
            if (component && component.subFields) {
              let list = [];
              component.subFields.forEach((sub) => {
                list.push({
                  key: sub.name,
                  name: sub.name,
                  title: sub.title,
                  componentName: sub.componentName,
                  form: true,
                });
              });
              node.components = list;
            }
            continue;
          }

          tableName = node.nodeField.collection;

          let obj = nodeList.find(
            (item) =>
              ["node_data_field", "node_data"].includes(item.dataSource) &&
              item.nodeField.componentName == "json_form" &&
              isEmpty(item.nodeField.referenceCollection) &&
              tableName &&
              tableName == item.nodeField.collection &&
              item.nodeField.name == node.nodeField.name &&
              item.components &&
              item.components.length,
          );

          if (obj) {
            node.components = this._.cloneDeep(obj.components);
            node.components.forEach((item) => {
              item.nodeKey = node.key;
            });
            continue;
          }
        } else {
          // 非子表模式或者非原始子表单模式的node设置组件列表

          if (["node_data_field", "node_data"].includes(node.dataSource)) {
            tableName = node.nodeField.collection;
          } else {
            tableName = node.collection;
          }
        }

        let list = this.formMap[tableName];
        if (!list) {
          let res = await this.fetchMetaFieldComponentList(tableName);
          list = res.data.list;
          this.formMap[tableName] = res.data.list;
        }

        if (
          ["node_data_field", "node_data"].includes(node.dataSource) &&
          node.nodeField.componentName == "json_form"
        ) {
          let component = list.find((item) => item.name == node.nodeField.name);

          if (component) {
            list = this._.cloneDeep(component.components);
          } else {
            list = [];
          }
        }

        list = list.filter(
          (item) =>
            ![
              "reference_data",
              "json_form",
              "form_data",
              "reference_data_list",
            ].includes(item.componentName),
        );

        list.forEach((item) => {
          item.form = true;
        });

        list = await this.buildFormComponents(list, node.key);
        list.forEach((item) => {
          if (
            ["node_data_field", "node_data"].includes(node.dataSource) &&
            node.nodeField.componentName == "json_form" &&
            isEmpty(node.nodeField.referenceCollection)
          ) {
            item.collection = "";
          } else {
            item.collection = tableName;
          }

          item.nodeKey = node.key;
        });

        node.components = list;
      }
      this.loading = false;
      return nodeList;
    },
    async buildFormComponents(originComponents, nodeKey) {
      let columns = getFormComponents(originComponents);
      if (nodeKey) {
        columns = columns.filter(
          (item) =>
            checkFieldAbility(item, "rely") &&
            (nodeKey != this.nodeKey || item.name !== this.name),
        );
      } else {
        columns = columns.filter(
          (item) => checkFieldAbility(item, "rely") && item.name !== this.name,
        );
      }

      columns = columns.filter(
        (item) =>
          !(item.componentName == "reference_data" && isEmpty(item.tableName)),
      );

      for (let i = 0; i < columns.length; i++) {
        let item = columns[i];
        if (item.componentName == "reference_data") {
          let list = await this.buildReferenceFields(item, "reference_data");
          item.components = list;
        } else if (item.componentName == "json_form") {
          item.components = item.components.filter(
            (col) => checkFieldAbility(col, "rely") && col.name != this.name,
          );
          item.components = item.components.filter(
            (col) =>
              !(
                col.componentName == "reference_data" && isEmpty(col.tableName)
              ),
          );

          let parent = {
            key: item.key,
            name: item.name,
            title: item.title,
            componentName: item.componentName,
            components: this._.cloneDeep(item.components),
          };

          for (let j = 0; j < item.components.length; j++) {
            let child = item.components[j];
            if (child.componentName == "reference_data") {
              let jsonList = await this.buildReferenceFields(
                child,
                "json_form",
                this._.cloneDeep(parent),
              );
              child.components = jsonList;
            }
            child.parent = this._.cloneDeep(parent);
          }
        }
      }
      columns = columns.filter(
        (item) =>
          !(item.componentName == "json_form" && isEmpty(item.components)),
      );

      return this._.cloneDeep(columns);
    },
    /**
     * 构建关联数据全字段列表
     */
    async buildReferenceFields(node, type, parent) {
      let list = this._.cloneDeep(this.formMap[node.tableName]); // 关联数据组件的关联表全字段中子表单的关联数据的组件列表
      if (!list) {
        let res = await this.fetchMetaFieldComponentList(node.tableName); // 不存在组件字段列表通过接口请求
        list = getFormComponents(res.data.list);
        this.formMap[node.tableName] = this._.cloneDeep(list);
      }

      let components = [];

      let parent_first = {
        key: node.key,
        name: node.name,
        title: node.title,
        componentName: node.componentName,
      };
      if (!isEmpty(parent)) {
        parent_first.parent = this._.cloneDeep(parent);
      }
      if (!isEmpty(node.components)) {
        parent_first.components = node.components;
      }

      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let isContinueRely;
        if (type == "json_form") {
          isContinueRely =
            item.componentName != "json_form" &&
            checkFieldAbility(item, "rely");
        } else {
          isContinueRely =
            item.componentName != "reference_data" &&
            checkFieldAbility(item, "rely");
        }
        if (isContinueRely) {
          if (item.componentName == "json_form") {
            item.components = item.components.filter(
              (col) =>
                checkFieldAbility(col, "rely") &&
                col.componentName != "reference_data",
            );
            let parent_node = {
              key: item.key,
              name: item.name,
              title: item.title,
              componentName: item.componentName,

              parent: this._.cloneDeep(parent_first),
            };
            if (!isEmpty(item.components)) {
              parent_node.components = this._.cloneDeep(item.components);
            }

            for (let j = 0; j < item.components.length; j++) {
              let child = item.components[j];

              if (child.componentName == "reference_data") {
                let jsonList = await this.buildReferenceFields(
                  child,
                  "json_form",
                  this._.cloneDeep(parent_node),
                );

                child.components = this._.cloneDeep(jsonList);
              }
              child.parent = this._.cloneDeep(parent_node);
            }
          }
          let obj = this._.cloneDeep(item);
          obj.parent = this._.cloneDeep(parent_first);
          components.push(obj);
        }
      }
      return components;
    },
    getFieldByName(name) {
      let columns = this.columns;

      // let arr = name.match(/[a-z-A-Z0-9]+_[a-zA-Z0-9]+/gm);

      let obj;
      /**
       * @type {Array}
       */
      let arr = name.split("_");
      let keywords = [
        "fieldName",
        "nodeKey",
        "collection",
        "jsonForm",
        "referenceData",
      ];
      let key = "";
      let value = "";

      for (let i = 0; i < arr.length; i++) {
        let val = arr[i];
        if (keywords.includes(val) || i == arr.length - 1) {
          if (!keywords.includes(val)) {
            if (value != "") {
              value += "_";
            }
            value += val;
          }

          if (key) {
            // 执行
            if (key == "nodeKey") {
              // 节点
              obj = columns.find((item) => item.key == value);
            } else if (key == "collection") {
              if (!obj) {
                obj = columns.find((item) => item.collection == value);
              }
            } else {
              if (obj) {
                obj = obj.components.find((item) => item.name == value);
              } else {
                obj = columns.find((item) => item.name == value);
              }
            }
            if (!obj) {
              break;
            }
          }
          if (keywords.includes(val)) {
            key = val;
            value = "";
          }
        } else {
          if (value != "") {
            value += "_";
          }
          value += val;
        }
      }

      if (obj) {
        let o = buildFormulaObj(obj);
        return o;
      } else {
        // 字段已删除不存在
        let nodeKey;
        let collection;
        let fieldName;
        let key = "";
        let value = "";
        for (let i = 0; i < arr.length; i++) {
          let val = arr[i];
          if (keywords.includes(val) || i == arr.length - 1) {
            if (!keywords.includes(val)) {
              if (value != "") {
                value += "_";
              }
              value += val;
            }

            if (key) {
              // 执行
              if (key == "nodeKey") {
                nodeKey = value;
              } else if (key == "collection") {
                collection = value;
              } else if (key == "fieldName") {
                fieldName = value;
              }
            }
            if (keywords.includes(val)) {
              key = val;
              value = "";
            }
          } else {
            if (value != "") {
              value += "_";
            }
            value += val;
          }
        }

        for (let i = 0; i < this.originalFormula.length; i++) {
          let rows = this.originalFormula[i];
          for (let j = 0; j < rows.length; j++) {
            let item = rows[j];
            if (item.type == "field") {
              if (nodeKey) {
                if (item.nodeKey == nodeKey && item.name == fieldName) {
                  obj = item;
                }
              } else if (collection) {
                if (item.collection == collection && item.name == fieldName) {
                  obj = item;
                }
              } else {
                if (item.name == fieldName) {
                  obj = item;
                }
              }

              if (obj) {
                obj = this._.cloneDeep(obj);
                obj.delete = true;
                break;
              }
            }
          }
          if (obj) {
            break;
          }
        }

        return obj;
      }
    },
    /**
     * 构建入库数据格式
     * @returns
     */
    buildOriginalFormula() {
      if (!this.$refs.formula) {
        return { tree: [], originalFormula: [] };
      }
      let { content } = this.$refs.formula.getContent();

      let splitKey = "splice";
      // let str = content.replace(/\n/g, "");
      let funList = content.match(funRex);
      funList = [...new Set(funList)];
      // eslint-disable-next-line no-useless-escape
      let reg1 = /(\"(.|\n)*?\")|(\'(.|\n)*?\')|(\`(.|\n)*?\`)/g;
      // 替换字符串
      let strList = content.match(reg1);
      strList = new Array(...new Set(strList));
      let indexList = [];
      let str = content;
      strList.forEach((val, index) => {
        strList[index] = val.substring(1, val.length - 1);
        // let reg = new RegExp(val, "g");
        str = str.replaceAll(val, "$zgg_str_" + index + "$");
        indexList.push(`$zgg_str_${index}$`);
      });
      funList.forEach((val, index) => {
        str = str.replaceAll(val, `$zgg_fun_${index}$`);
      });

      let fieldList = [];
      let fields = content.match(fieldRex);
      fields = [...new Set(fields)];

      let rebuildField = (field) => {
        let obj = this._.cloneDeep(field);
        delete obj.rely;
        delete obj.dataLinkage;
        delete obj.components;
        if (obj.parent) {
          obj.parent = rebuildField(obj.parent);
        }
        return obj;
      };

      fields.forEach((name, index) => {
        // let fieldName = name.replaceAll("$field_", "").replaceAll("$", "");
        let { fieldName, id } = getFieldNameAndId(name);
        let field = this.getFieldByName(fieldName);
        field.id = id;

        let regex = new RegExp(`\\$field_${id}_${fieldName}\\$`, "g");
        str = str.replace(regex, "$zgg_field_" + index + "$");

        fieldList.push(field);
      });

      let splitArr = [
        "===",
        "!==",
        "==",
        "!=",
        ">=",
        "<=",
        ">>",
        "<<",
        ">",
        "<",
        "!",
        "&&",
        "||",
        "&",
        "|",
        "^",
        "+",
        "-",
        "*",
        "/",
        "%",
        "(",
        ")",
        ",",
        "=",
        "?",
        ":",
        "~",
        "[",
        "]",
        "{",
        "}",
        "\n",
        "\\s",
      ];

      let operatorArr = [
        "===",
        "!==",
        "==",
        "!=",
        ">=",
        "<=",
        ">>",
        "<<",
        ">",
        "<",
        "!",
        "&&",
        "||",
        "&",
        "|",
        "^",
        "+",
        "-",
        "*",
        "/",
        "%",
        "=",
        "?",
        ":",
        "~",
      ];

      let regStr = "";

      splitArr.forEach((val) => {
        if (regStr != "") {
          regStr += "|";
        }
        if (val == "||") {
          regStr += "\\|\\|";
        } else if (
          ["(", ")", "+", "*", "|", "?", "^", "[", "]", "{", "}"].includes(val)
        ) {
          regStr += "\\" + val;
        } else {
          regStr += val;
        }
      });

      // 获取数据val 包括函数
      regStr = new RegExp(regStr, "g");
      let valArr = str.split(regStr).filter((val) => val != "");

      // ** 替换内容 后续内容如果包含前面内容可能会把前面内容替换，所以替换一次之后，字符串自动往后移动
      let strIndex;
      let startStr = "";
      let endStr = str;
      valArr.forEach((val, index) => {
        strIndex = endStr.indexOf(val);
        if (strIndex >= 0) {
          let start_str = endStr.substring(0, strIndex);
          let end_str = endStr.substring(strIndex);
          let newStr = "$zgg_val_" + index + "$";
          end_str = end_str.replace(val, newStr);
          endStr = start_str + end_str;
          startStr += endStr.substring(0, strIndex + newStr.length);
          endStr = endStr.substring(strIndex + newStr.length);
        }
      });
      str = startStr + endStr;

      // 替换分隔符
      splitArr.forEach((val, index) => {
        let reg = val;
        if (
          ["+", "*", "(", ")", "|", "^", "?", "[", "]", "{", "}"].includes(val)
        ) {
          reg = "\\" + val;
        } else if (val == "||") {
          reg = "\\|\\|";
        }

        reg = new RegExp(reg, "g");

        if (str.match(reg)) {
          str = str.replace(reg, "$zgg_split_" + index + "$");
          indexList.push(`$zgg_split_${index}$`);
        }
      });

      let tempList = str.match(
        /\$zgg_split_[0-9]+\$|\$zgg_str_[0-9]+\$|\$zgg_val_[0-9]+\$|\$zgg_field_[0-9]\$/g,
      );

      let fieldReg = /\$zgg_field_[0-9]+\$/g;
      let strReg = /\$zgg_str_[0-9]+\$/g;
      let valReg = /\$zgg_val_[0-9]+\$/g;
      let splitReg = /\$zgg_split_[0-9]+\$/g;
      let funReg = /\$zgg_fun_[0-9]+\$/g;

      let tempTree = [];

      let tempData = [[]];

      if (!tempList) {
        return { tree: [], originalFormula: [] };
      }
      let line = 0;
      let ch = 0;

      tempList.forEach((val) => {
        let children = tempData[tempData.length - 1];
        let obj;
        let type;
        if (val.match(valReg)) {
          let index = val.match(/[0-9]+/g)[0];
          let value = valArr[index];
          let componentName = "";
          let funId;

          if (value.match(fieldReg)) {
            //
            index = value.match(/[0-9]+/g)[0];
            value = fieldList[index];
            type = "field";
          } else if (value.match(funReg)) {
            index = value.match(/[0-9]+/g)[0];

            let { funName, id } = getFunNameAndId(funList[index]);
            value = funName;
            funId = id;
            type = "fun";
          } else if (value.match(strReg)) {
            index = value.match(/[0-9]+/g)[0];
            value = strList[index];
            componentName = "input";
          } else if (isNumber(value)) {
            value = parseFloat(value);
            componentName = "input_number";
          }

          if (typeof value === "string") {
            if (!type) {
              type = "val";
            }
            if (type == "val") {
              value = `'${value.trim()}'`;
            }
            obj = {
              type: type,
              value: value,
            };
            if (!isEmpty(componentName)) {
              obj.componentName = componentName;
            }
          } else {
            if (value && value.type == "field") {
              obj = rebuildField(value);
            } else {
              if (!type) {
                type = "val";
              }
              obj = {
                type: type,
                value: value,
                componentName,
              };
            }
          }

          if (type == "fun") {
            obj.id = funId;
          }
        } else if (val.match(splitReg)) {
          let index = val.match(/[0-9]+/g)[0];

          let value = splitArr[index];
          if (value != "\n") {
            if (value == "\\s") {
              obj = {
                type: splitKey,
                value: " ",
              };
            } else {
              obj = {
                type: operatorArr.includes(value) ? "operator" : splitKey,
                value: value,
              };
            }
          } else {
            line++;
            ch = 0;
            tempData.push([]);
          }
        }
        if (obj) {
          let start = ch;
          let end;

          if (["fun", "field"].includes(obj.type)) {
            let index = val.match(/[0-9]+/g)[0];
            let str = valArr[index];
            if (str.match(fieldReg)) {
              //
              let index = str.match(/[0-9]+/g)[0];
              str = fields[index];
            } else {
              let index = str.match(/[0-9]+/g)[0];
              str = funList[index];
            }

            end = ch + str.length;
          } else {
            let str = obj.value;
            end = ch + str.toString().length;
          }

          tempTree.push({
            ...obj,
            line,
            start,
            end,
          });
          let cloneObj = this._.cloneDeep(obj);
          delete cloneObj.id;
          delete cloneObj.delete;
          children.push(cloneObj);
          ch = end;
        }
      });

      let tree = [];
      let stack = []; // 栈
      tempTree.forEach((item, index) => {
        let obj = {
          ...item,
        };

        if (!stack.length) {
          if (obj.type == splitKey) {
            if (["(", "[", "{"].includes(obj.value)) {
              let parent = {
                type: "rely",
                children: [],
              };
              tree.push(parent);
              stack.push(parent);
              parent.children.push(obj);
              let child = {
                type: "rely",
                children: [],
              };
              parent.children.push(child);
              stack.push(child);
            } else {
              tree.push(obj);
              let child = {
                type: "rely",
                children: [],
              };
              tree.push(child);
              stack.push(child);
            }
          } else {
            if (obj.type == "fun" || obj.type == "operator") {
              if (obj.value == "!") {
                let parent = {
                  type: "rely",
                  children: [],
                };
                tree.push(parent);
                stack.push(parent);
                parent.children.push(obj);
              } else {
                if (obj.type == "fun") {
                  obj.children = [];
                  stack.push(obj);
                }
                tree.push(obj);
              }
            } else {
              if (
                index > 0 &&
                [
                  "===",
                  "!==",
                  "==",
                  "!=",
                  ">=",
                  "<=",
                  ">",
                  "<",
                  "||",
                  "&&",
                  "?",
                  ":",
                ].includes(tempTree[index - 1].value)
              ) {
                let parent = {
                  type: "rely",
                  children: [],
                };
                tree.push(parent);
                stack.push(parent);
                parent.children.push(obj);
              } else {
                tree.push(obj);
              }
            }
          }
        } else {
          let elem = stack[stack.length - 1];

          if (
            elem &&
            elem.children &&
            elem.children.length &&
            elem.children[0].value == "!"
          ) {
            stack.pop();
          }

          if (["(", "[", "{"].includes(obj.value) && obj.type == splitKey) {
            let child = {
              type: "rely",
              children: [],
            };
            if (elem.type == "fun" && elem.children.length == 0) {
              elem.children.push(obj);

              elem.children.push(child);

              stack.push(child);
            } else {
              let parent = {
                type: "rely",
                children: [],
              };
              parent.children.push(obj);
              parent.children.push(child);
              elem.children.push(parent);
              stack.push(parent);
              stack.push(child);
            }
          } else if (
            [")", "]", "}"].includes(obj.value) &&
            obj.type == splitKey
          ) {
            if (
              !(
                obj.value == ")" &&
                elem.type == "fun" &&
                elem.children.filter((child) => child.value == "(").length == 1
              )
            ) {
              stack.pop();
              elem = stack[stack.length - 1];
            }

            if (elem) {
              elem.children.push(obj);
              stack.pop();
            } else {
              tree.push(obj);
            }
          } else if (obj.type == splitKey) {
            if (["?", ":"].includes(obj.value)) {
              stack.pop();
              elem -= stack[stack.length - 1];
            } else {
              if (obj.value == "," && elem.type == "rely") {
                stack.pop();
                elem = stack[stack.length - 1];
              }
            }

            if (elem) {
              elem.children.push(obj);
            } else {
              tree.push(obj);
            }

            if (obj.value == "," && elem.type == "rely") {
              let child = {
                type: "rely",
                children: [],
              };
              if (elem) {
                elem.children.push(child);
                stack.push(child);
              }
            }
          } else if (obj.type == "fun") {
            obj.children = [];
            elem.children.push(obj);
            stack.push(obj);
          } else if (obj.type == "operator" && obj.value == "!") {
            //
            let parent = {
              type: "rely",
              children: [],
            };
            elem.children.push(parent);
            stack.push(parent);
            parent.children.push(obj);
          } else {
            elem.children.push(obj);
          }
        }

        if (index == tempTree.length - 1) {
          stack.pop();
        }
      });

      let rebuildTree = (tree) => {
        let list = [];
        let child;
        for (let index = 0; index < tree.length; index++) {
          let item = tree[index];
          if (item.type != splitKey) {
            if (item.children && item.children.length) {
              item.children = rebuildTree(item.children);
            }
            if (child) {
              child.children.push(this._.cloneDeep(item));
            } else {
              list.push(this._.cloneDeep(item));
            }
          } else {
            // 构建表达式节点
            if (["(", "[", ","].includes(item.value)) {
              let tplChild = list[list.length - 1];
              if (tplChild?.valueType === "array") {
                child = {
                  type: "rely",
                  children: [],
                };
                if (item.value == "[") {
                  child.valueType = "array";
                }
                tplChild.children.push(child);
              } else {
                child = {
                  type: "rely",
                  children: [],
                };
                if (item.value == "[") {
                  child.valueType = "array";
                }
                list.push(child);
              }
            } else {
              child = undefined;
            }
          }
        }
        return list;
      };

      tree = rebuildTree(tree);

      let buildFinalyTree = (tree) => {
        let list = [];
        tree.forEach((item) => {
          if (item.children) {
            if (item.type == "fun") {
              item.children = buildFinalyTree(item.children);
              list.push(this._.cloneDeep(item));
            } else {
              let arr = buildFinalyTree(item.children);
              if (arr.length == 1) {
                if (item.valueType == "array") {
                  list.push({
                    type: "rely",
                    valueType: "array",
                    children: arr,
                  });
                } else {
                  list.push(this._.cloneDeep(arr[0]));
                }
              } else if (arr.length) {
                item.children = this._.cloneDeep(arr);
                list.push(this._.cloneDeep(item));
              }
            }
          } else {
            list.push(this._.cloneDeep(item));
          }
        });

        if (
          list.length == 1 &&
          list[0].type == "rely" &&
          list[0].children &&
          list[0].valueType != "array"
        ) {
          return list[0].children;
        }
        return list;
      };

      tree = buildFinalyTree(tree);

      return { originalFormula: tempData, tree };
    },
  },
};
