<template>
  <div class="attr-row">
    <div class="attr-label">设置弹窗列表视图</div>
    <div style="display: flex; flex-direction: row; gap: 8px">
      <el-button
        style="width: 100%; margin-bottom: 5px"
        slot="reference"
        size="mini"
        @click="openViewEdit"
        >设置
      </el-button>
      <el-popover
        v-if="!isDataflow"
        popper-class="form-popover"
        placement="bottom-start"
        v-model="viewListVisible"
        width="300"
      >
        <div v-clickoutside="(viewListVisible = false)">
          <div class="pop-scroll">
            <div class="empty-text" v-if="viewList.length == 0">
              暂无表单数据
            </div>
            <template v-else>
              <div
                class="form-item"
                v-for="(item, index) in viewList"
                :key="index"
                @click="openViewEditFromView(item)"
              >
                {{ item.title }}
              </div>
            </template>
          </div>
        </div>
        <el-button slot="reference" size="mini" @click="openViewList">
          选择视图
        </el-button>
      </el-popover>
    </div>
    <el-dialog
      :visible.sync="viewSettingVisible"
      v-if="viewSettingVisible"
      title="视图设置"
      width="100%"
      class="custom-fullscreen"
      append-to-body
      destroy-on-close
    >
      <ViewEdit
        :key="component.tableName"
        style="width: 100%"
        ref="viewEdit"
        :manualInit="true"
        :save-function="saveFunction"
      ></ViewEdit>
    </el-dialog>
  </div>
</template>
<script>
import { uiViewList } from "../api";
import ViewEdit from "@/views/lowCode/view/edit";
import Clickoutside from "element-ui/src/utils/clickoutside";
import { isEmpty } from "@zgg-core-utils/utils";
export default {
  name: "attr-view-list",
  directives: { Clickoutside },
  props: {
    component: Object,
  },
  components: { ViewEdit },
  data() {
    return {
      viewListVisible: false,
      viewList: [],
      viewSettingVisible: false,
      viewId: "",
    };
  },
  computed: {
    isDataflow() {
      // 数据流或则聚合表
      return (
        !isEmpty(this.component.tableName) &&
        (this.component.tableName.indexOf("dataflow_") == 0 ||
          this.component.tableName.indexOf("at_") == 0)
      );
    },
  },
  methods: {
    openViewEdit() {
      if (!this.component.tableName) {
        this.$message.warning("请先选择关联表");
        return;
      }
      this.viewId = "";
      this.viewSettingVisible = true;
      this.$nextTick(() => {
        this.$refs.viewEdit.manualBuildViewConfig(this.component);
      });
    },
    openViewEditFromView(view) {
      this.viewId = view.id;
      this.viewSettingVisible = true;
      //手动初始化打开视图设置页面
      this.$nextTick(() => {
        this.$refs.viewEdit.initViewSettingByViewId(view.id);
      });
    },
    openViewList() {
      if (!this.component.tableName) {
        this.$message.warning("请先选择关联表");
        return;
      }
      let params = {
        componentNames: ["table"],
        dataSourceFormId: this.component.tableName,
        pageSize: 999,
      };
      uiViewList(params).then((resp) => {
        this.viewList = resp.data.page.list;
      });
    },
    /**
     * 关联表唤起视图编辑页，保存之后的回调方法
     * @param form
     */
    saveFunction(form) {
      let { searchFormView, columns } = form;
      this.component.searchFormView = searchFormView;
      this.component.tableColumns = columns;
      this.viewSettingVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  line-height: 30px;
  font-size: 14px;
  padding: 0px 10px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  .item-title {
    &.disabled {
      color: #c3cdda;
    }

    &:hover {
      background-color: rgba($color: #409eff, $alpha: 0.3);
    }

    &.select {
      background-color: #409eff;

      &:hover {
        background-color: #409eff;
      }
    }
  }
}
.pop-scroll {
  max-height: 320px;
  overflow: auto;

  .empty-text {
    font-size: 12px;
    text-align: center;
    line-height: 28px;
  }
}
</style>