<template>
  <div
    class="icon-container"
    :class="data.dataType === 'folder' ? '' : 'resource-icon'"
  >
    <template v-if="enabledNewLayout">
      <div
        class="image-icon resource-image-icon"
        v-if="data?.param?.iconStyle?.type === 'ICON_URL'"
        :style="{
          'background-image': 'url(' + data?.param?.iconStyle?.icon + ')',
        }"
      ></div>

      <div class="icon-div" v-else-if="data?.param?.iconStyle?.type == 'EMOJI'">
        <div style="font-size: 18px; z-index: 1">
          {{ data?.param?.iconStyle?.icon }}
        </div>
      </div>

      <div
        v-else
        class="icon-div"
        :style="
          data?.param?.iconStyle?.shape == 'FILLED'
            ? ''
            : `background-color:${data?.param?.iconStyle?.color}`
        "
      >
        <i
          :class="
            !data?.param?.iconStyle?.className.includes('fa')
              ? 'iconfont ' + data?.param?.iconStyle?.className
              : data?.param?.iconStyle?.className
          "
          style="margin-right: 0; font-size: 18px"
          :style="
            data?.param?.iconStyle?.shape == 'FILLED'
              ? `color:${data?.param?.iconStyle?.color}`
              : `color:#fff;font-size:12px`
          "
        ></i>
      </div>
    </template>
    <template v-else>
      <div
        class="image-icon resource-image-icon"
        v-if="data?.param?.iconStyle?.type === 'ICON_URL'"
        :style="{
          'background-image': 'url(' + data?.param?.iconStyle?.icon + ')',
        }"
      ></div>

      <div class="icon-div" v-else-if="data?.param?.iconStyle?.type == 'EMOJI'">
        <div style="font-size: 16px; z-index: 1">
          {{ data?.param?.iconStyle?.icon }}
        </div>
      </div>

      <div v-else class="icon-div">
        <i
          :class="
            !data?.param?.iconStyle?.className.includes('fa')
              ? 'iconfont ' + data?.param?.iconStyle?.className
              : data?.param?.iconStyle?.className
          "
          style="margin-right: 0; font-size: 16px"
          :style="`color:${data?.param?.iconStyle?.color}`"
        ></i>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "resource-icon",

  model: {
    event: "updateColor",
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    enabledNewLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    console.log();
  },
};
</script>
<style lang="scss" scoped>
.icon-container {
  width: 22px;
  height: 22px;
  margin-right: 4px;

  .image-icon {
    width: 100%;
    height: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
  }

  .icon-div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
}
.resource-icon {
  width: 16px !important;
  height: 16px !important;
}
</style>
