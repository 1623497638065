<template>
  <div class="config-container">
    <div v-if="isAuth" style="margin-bottom: 20px">
      <div style="font-size: 16px; font-weight: bold; margin-bottom: 5px">
        智能助手权限
      </div>
      <el-form v-model="model">
        <el-form-item
          label="递归调用次数"
          prop="limitChainCount"
          style="margin-bottom: 0px"
        >
          <el-input-number
            v-model="model.limitChainCount"
            placeholder=""
            :min="1"
            :max="1000"
            size="small"
          ></el-input-number>
          <span style="line-height: 1.5; font-size: 13px; margin-left: 10px"
            >不能超过1000次</span
          >
        </el-form-item>
        <el-form-item
          label="定时循环最小间隔开放到每分钟"
          prop="loopTypeEnablePerMinute"
          style="margin-bottom: 0px"
        >
          <el-switch v-model="model.loopTypeEnablePerMinute"></el-switch>
        </el-form-item>
        <el-form-item
          label="更新数据节点限制记录数"
          prop="limitUpdateSize"
          style="margin-bottom: 0"
        >
          <el-input-number
            v-model="model.limitUpdateSize"
            placeholder=""
            :min="1"
            :max="10000"
            size="small"
          ></el-input-number>
          <span style="line-height: 1.5; font-size: 13px; margin-left: 10px"
            >不能超过10000条</span
          >
        </el-form-item>
      </el-form>
    </div>

    <div v-if="activeName" style="margin-bottom: 10px">
      <div style="font-size: 16px; font-weight: bold; margin-bottom: 5px">
        参数对象
      </div>
      <div style="font-size: 14px">
        流程开始运行时生成，可以用以执行运算，或实现两个流程间的数据传递
      </div>
    </div>
    <div class="config-body">
      <div class="weui-param">
        <div style="width: 120px; font-size: 14px; margin-right: 10px">
          参数类型
        </div>
        <div style="width: 200px; font-size: 14px">参数名称</div>
      </div>
      <div
        v-for="(item, index) in flowParams"
        :key="item.name"
        class="weui-param"
      >
        <el-select
          v-model="item.componentName"
          style="width: 120px; margin-right: 10px"
          size="small"
          placeholder="参数类型"
          :disabled="isDisabledComponent(item.name)"
          @change="changeComponentName($event, item)"
        >
          <el-option
            v-for="opt in components"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          ></el-option>
        </el-select>
        <div style="position: relative">
          <el-input
            v-model="item.title"
            placeholder="参数名称"
            size="small"
            style="width: 200px; margin-right: 10px"
            :disabled="disabled"
          ></el-input>
          <div v-if="checkRepeatTitle(item.title, index)" class="param-error">
            参数名称不允许重复
          </div>
        </div>

        <i
          v-if="!disabled"
          class="el-icon-delete-solid"
          style="color: #9e9e9e; cursor: pointer"
          @click="deleteParam(index)"
        ></i>
      </div>
      <div v-if="!disabled">
        <el-button @click="addParam" icon="el-icon-plus" size="small"
          >新参数</el-button
        >
      </div>
    </div>
    <div v-if="!disabled && activeName">
      <el-button type="primary" size="small" @click="saveParams"
        >保存</el-button
      >
      <el-button size="small" @click="cancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import { isEmpty, uuid } from "@/zgg-core/utils";

export default {
  props: {
    config: Object,
    activeName: String,
    disabled: Boolean,
  },
  computed: {
    /**
     * 拥有操作智能助手权限，1000是有全部权限
     */
    isAuth() {
      /**
       * @type Array
       */
      let arr = this.$store.state?.authItems ?? [];
      return arr.some((val) => [1000, 1117].includes(val));
    },
  },
  data() {
    return {
      flowParams: [],
      model: {
        limitChainCount: 10,
        loopTypeEnablePerMinute: false,
        limitUpdateSize: 1000,
      },
      components: [
        {
          label: "单行文本",
          value: "input",
        },
        {
          label: "多行文本",
          value: "text_area",
        },
        {
          label: "数字",
          value: "input_number",
        },
        {
          label: "日期",
          value: "date_picker",
        },
        {
          label: "手机号",
          value: "mobile_input",
        },
        {
          label: "用户单选",
          value: "user_select",
        },
        {
          label: "用户多选",
          value: "user_list_select",
        },
        {
          label: "部门单选",
          value: "department_select",
        },
        {
          label: "部门多选",
          value: "department_list_select",
        },
      ],
    };
  },
  created() {
    if (this.config) {
      if (this.config.flowParams) {
        this.flowParams = this._.cloneDeep(this.config.flowParams);
      }
      let limitChainCount = this.config.limitChainCount ?? 10;
      let loopTypeEnablePerMinute =
        this.config.loopTypeEnablePerMinute ?? false;
      let limitUpdateSize = this.config.limitUpdateSize ?? 1000;

      this.$set(this, "model", {
        limitChainCount,
        loopTypeEnablePerMinute,
        limitUpdateSize,
      });
    }
  },
  methods: {
    changeComponentName(val, item) {
      if (val == "date_picker") {
        this.$set(item, "picker", "datetime");
      } else {
        this.$set(item, "picker", undefined);
      }
    },
    isDisabledComponent(name) {
      if (this.disabled) {
        return true;
      }
      if (this.config && this.config.flowParams) {
        return (
          this.config.flowParams.findIndex((item) => item.name == name) >= 0
        );
      }
      return false;
    },
    saveParams() {
      let config = this._.cloneDeep(this.config);
      if (!config) {
        config = {};
      }
      /**
       * @type {Array}
       */
      config.flowParams = this._.cloneDeep(this.flowParams);
      if (config.flowParams.filter((item) => isEmpty(item.title)).length) {
        this.$message.error("参数名称不能为空");
        return;
      }
      for (const [key, value] of Object.entries(this.model)) {
        config[key] = value;
      }
      this.$emit("update:config", config);
      this.$emit("save");
    },
    cancel() {
      this.$emit("update:activeName", "flow");
    },
    checkRepeatTitle(title, index) {
      if (isEmpty(title)) {
        return false;
      }
      let i = this.flowParams.findIndex((item) => item.title == title);
      return i != index;
    },
    deleteParam(index) {
      this.flowParams.splice(index, 1);
    },
    addParam() {
      let key = uuid();
      let item = {
        componentName: "input",
        name: key,
        title: "",
      };
      this.flowParams.push(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.config-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
}
.config-body {
  flex: 1;
  overflow: auto;
}
.weui-param {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.param-error {
  position: absolute;
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  z-index: 10;
  padding: 4px 10px;
  bottom: 30px;
  left: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  &::after {
    content: " ";
    display: block;
    background: transparent;
    border: 6px solid transparent;
    border-top-color: #f44313;
    bottom: -12px;
    left: 10px;
    position: absolute;
  }
}
</style>
