<template>
  <div>
    <el-form label-position="top">
      <el-form-item label="数据源">
        <span style="font-size: 14px">工作表：{{ node.collectionTitle }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "rpa-subflow-trigger",
  props: {
    node: Object,
  },
};
</script>
