<template>
  <div @click.stop class="cont-div">
    <el-tabs v-model="activeName" style="position: relative">
      <el-tab-pane label="图标" name="CLASS">
        <!-- <span class="fontSize12">选择图标颜色</span> -->
        <!-- <div class="content">
          <div
            class="content-item"
            :style="'text-align:center;background-color:' + item"
            v-for="item in colorList"
            :key="item"
            @click="selectColor(item)"
          >
            <i
              v-if="myColor == item"
              class="el-icon-check"
              style="color: #fff; line-height: 30px"
            ></i>
          </div>
        </div> -->

        <template v-if="enabledNewLayout && this.dataType == 'folder'">
          <div class="fontSize12">图标风格</div>
          <div class="content">
            <el-radio @input="changeShape" v-model="shape" label="SQUARE"
              >方形</el-radio
            >
            <el-radio @input="changeShape" v-model="shape" label="FILLED"
              >填充</el-radio
            >
          </div>
        </template>
        <div class="fontSize12">选择图标</div>
        <div
          class="search"
          style="display: flex; flex-direction: row; align-items: center"
        >
          <el-input
            size="mini"
            placeholder="搜索"
            style="width: 90%"
            prefix-icon="el-icon-search"
            v-model="searchKeyWord"
            @input="searchIcon"
            clearable
          ></el-input>

          <el-popover
            width="210"
            placement="bottom"
            title="选择颜色"
            trigger="manual"
            v-model="visible"
          >
            <div class="pop-scroll color-content">
              <div
                class="content-item"
                :style="'text-align:center;background-color:' + item"
                v-for="item in colorList"
                :key="item"
                @click="selectColor(item)"
              >
                <i
                  v-if="myColor == item"
                  class="el-icon-check"
                  style="color: #fff; line-height: 30px"
                ></i>
              </div>
            </div>

            <div
              slot="reference"
              style="
                border: 1px solid #dfe1e6;
                width: 32px;
                height: 32px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 2px;
                cursor: pointer;
              "
              @click="visible = !visible"
            >
              <div
                class="content-show"
                :style="
                  ' width: 20px; height: 20px; border-radius: 50%;text-align:center;background-color:' +
                  myColor
                "
              ></div>
            </div>
          </el-popover>
        </div>
        <div class="content" style="padding-bottom: 5px">
          <div
            v-for="stype in fontAwesomenType"
            :key="stype"
            style="width: 100%"
          >
            <div>
              <div
                style="color: #a5adba; font-size: 12px; line-height: 20px"
                v-if="fontAwesomen[stype] && fontAwesomen[stype].length > 0"
              >
                {{ stype }}
              </div>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                <div
                  v-for="(item, index) in fontAwesomen[stype]"
                  class="icon-div"
                  :class="myClassName == item.value ? 'borderCheck' : ''"
                  :style="setBgColor"
                  :key="index"
                  @click="selectIcon(item.value)"
                  :title="item.label"
                >
                  <i
                    :data-search="item.value"
                    :data-class="item.value"
                    :class="item.value"
                    style="font-size: 16px; margin-right: 0"
                    :style="setIconColor"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            v-for="(item, index) in iconArr"
            :key="index"
            class="icon-div"
            :class="myClassName == item.className ? 'borderCheck' : ''"
            :style="'background-color:' + myColor"
            @click="selectIcon(item)"
          >
            <i
              class="iconfont"
              :class="item.className"
              style="color: #fff; font-size: 24px; margin-right: 0"
            ></i>
          </div> -->
        </div>
      </el-tab-pane>
      <el-tab-pane label="表情符号" name="EMOJI">
        <picker
          :include="['people', 'Smileys']"
          :showSearch="false"
          :showPreview="false"
          :showCategories="false"
          @select="addEmoji"
        />
      </el-tab-pane>
      <el-tab-pane label="自定义" name="ICON_URL">
        <div class="upload-icon-container">
          <el-upload
            class="upload-content"
            action=""
            :http-request="upload"
            :before-upload="beforeUpload"
            :auto-upload="true"
            accept=".jpg,.jpeg,.png"
          >
            <div class="upload-btn">上传自定义图标</div>
          </el-upload>
          <span class="upload-tips"
            >请选择2MB以内的jpg、jpeg或png图片，建议尺寸200*200像素</span
          >
        </div></el-tab-pane
      >
    </el-tabs>
    <el-button
      @click.native="resetStyle"
      style="
        position: absolute;
        right: 12px;
        top: 12px;
        color: #3870ea;
        cursor: pointer;
      "
      size="mini"
      ><i class="el-icon-refresh-left"></i>恢复默认</el-button
    >
  </div>
</template>

<script>
import request from "@/utils/request";
import "./fontawesome/fontawesome-5.11.2/css/all.min.css";
import "./fontawesome/iconpicker-1.5.0.css";
import { fontAwesomenJson } from "./fontawesome/fontawesome";
import { Picker } from "emoji-mart-vue";

export default {
  name: "styleSelect",
  components: {
    Picker,
  },
  model: {
    event: "updateColor",
  },

  props: {
    iconStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dataType: String,
    enabledNewLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorList: [
        "#3870EA",
        "#7B67EE",
        "#1DAD92",
        "#90BE6D",
        "#F47EC2",
        "#F8961E",
        "#F3722C",
        "#EB5757",
        "#1888C3",
        "#577590",
      ],
      iconArr: [
        { type: "class", className: "icon-briefcase-fill", color: "#3870EA" },
        { type: "class", className: "icon-bookmark-fill", color: "#3870EA" },
        { type: "class", className: "icon-home-4-fill", color: "#3870EA" },
        { type: "class", className: "icon-pie-chart-fill", color: "#3870EA" },
        { type: "class", className: "icon-folder-open-fill", color: "#3870EA" },
        { type: "class", className: "icon-folder-user-fill", color: "#3870EA" },

        { type: "class", className: "icon-slideshow-fill", color: "#3870EA" },
        { type: "class", className: "icon-draft-fill", color: "#3870EA" },
        { type: "class", className: "icon-oa-fill", color: "#3870EA" },
        { type: "class", className: "icon-crm-fill", color: "#3870EA" },
        {
          type: "class",
          className: "icon-contacts-book-fill",
          color: "#3870EA",
        },
        { type: "class", className: "icon-id-card-fill", color: "#3870EA" },

        { type: "class", className: "icon-worker-fill", color: "#3870EA" },
        { type: "class", className: "icon-customer-fill", color: "#3870EA" },
        {
          type: "class",
          className: "icon-customer-service-fill",
          color: "#3870EA",
        },
        { type: "class", className: "icon-group-fill", color: "#3870EA" },
        { type: "class", className: "icon-service-fill", color: "#3870EA" },
        {
          type: "class",
          className: "icon-shield-check-fill",
          color: "#3870EA",
        },

        {
          type: "class",
          className: "icon-money-cny-circle-fill",
          color: "#3870EA",
        },
        { type: "class", className: "icon-funds-fill", color: "#3870EA" },
        { type: "class", className: "icon-maintain-fill", color: "#3870EA" },
        { type: "class", className: "icon-profile-fill", color: "#3870EA" },
        { type: "class", className: "icon-building-fill1", color: "#3870EA" },
        { type: "class", className: "icon-service-fill1", color: "#3870EA" },

        { type: "class", className: "icon-car-fill", color: "#3870EA" },
        { type: "class", className: "icon-restaurant-fill", color: "#3870EA" },
        { type: "class", className: "icon-stamp-fill", color: "#3870EA" },
        { type: "class", className: "icon-truck-fill", color: "#3870EA" },
        {
          type: "class",
          className: "icon-shopping-cart-fill",
          color: "#3870EA",
        },
        { type: "class", className: "icon-key-fill", color: "#3870EA" },

        {
          type: "class",
          className: "icon-organization-fill",
          color: "#3870EA",
        },
        { type: "class", className: "icon-gift-fill", color: "#3870EA" },
        {
          type: "class",
          className: "icon-alarm-warning-fill",
          color: "#3870EA",
        },
        { type: "class", className: "icon-trophy-fill", color: "#3870EA" },
        { type: "class", className: "icon-map-pin-fill-1", color: "#3870EA" },
        {
          type: "class",
          className: "icon-calendar-event-fill",
          color: "#3870EA",
        },

        { type: "class", className: "icon-sun-fill", color: "#3870EA" },
        { type: "class", className: "icon-star-fill", color: "#3870EA" },
        { type: "class", className: "icon-heart-fill", color: "#3870EA" },
        { type: "class", className: "icon-vip-diamond-fill", color: "#3870EA" },
        { type: "class", className: "icon-water-flash-fill", color: "#3870EA" },
        {
          type: "class",
          className: "icon-emotion-happy-fill",
          color: "#3870EA",
        },

        { type: "class", className: "icon-thumb-up-fill", color: "#3870EA" },
        { type: "class", className: "icon-pushpin-fill", color: "#3870EA" },
        { type: "class", className: "icon-rocket-fill", color: "#3870EA" },
        { type: "class", className: "icon-calculator-fill", color: "#3870EA" },
        { type: "class", className: "icon-map-pin-fill", color: "#3870EA" },
        { type: "class", className: "icon-compass-3-fill", color: "#3870EA" },
      ],
      myColor: "#3870EA",
      myClassName: "fas fa-briefcase fa-fw",
      activeName: "CLASS",
      shape: "SQUARE",
      fontAwesomen: [],
      original: [],
      native: "",
      fontAwesomenType: [],
      searchKeyWord: "",
      visible: false,
    };
  },
  created() {
    this.original = this.fontAwesomen = fontAwesomenJson;

    this.fontAwesomenType = Object.keys(fontAwesomenJson).map(function (i) {
      return i;
    }); //对象转化为数组
    if (Object.keys(this.iconStyle).length != 0) {
      this.myClassName = this.iconStyle.className;
      this.myColor = this.iconStyle.color || "#3870EA";
      this.shape = this.iconStyle.shape;
      this.activeName = this.iconStyle.type;
      if (!this.activeName) {
        this.activeName = "CLASS";
      }
      if (this.activeName == "class") {
        this.activeName = "CLASS";
      }
      if (!this.shape) {
        this.shape = "SQUARE";
      }
      if (
        !this.enabledNewLayout ||
        (this.enabledNewLayout && this.dataType !== "folder")
      ) {
        this.shape = "FILLED";
      }
    }
  },

  computed: {
    setBgColor() {
      let style = "background-color:" + this.myColor;
      if (this.shape == "FILLED") {
        style = "";
      }
      return style;
    },
    setIconColor() {
      let style = "color:#fff";
      if (this.shape == "FILLED") {
        style = "color:" + this.myColor;
      }
      return style;
    },
  },
  methods: {
    resetStyle() {
      let iconStyle = {
        type: null,
        color: "",
        className: "",
        shape: "",
        icon: "",
      };
      if (this.enabledNewLayout && this.dataType == "folder") {
        iconStyle = {
          type: "CLASS",
          className: "fas fa-briefcase fa-fw",
          color: "#3870EA",
          icon: "",
          shape: "SQUARE",
        };
      }
      this.$emit("updateIcon", iconStyle, "reset");
    },
    addEmoji(e) {
      this.native = e.native;
      this.$emit("updateIcon", {
        type: "EMOJI",
        icon: e.native,
      });
    },
    changeShape(val) {
      this.$emit("updateIcon", {
        type: "CLASS",
        color: this.myColor,
        className: this.myClassName,
        shape: val,
      });
    },
    selectColor(sub) {
      this.visible = false;
      this.myColor = sub;
      this.$emit("updateIcon", {
        type: "CLASS",
        color: this.myColor,
        className: this.myClassName,
        shape: this.shape,
      });
    },
    selectIcon(item) {
      this.myClassName = item;
      //  this.show=false;
      this.$emit("updateIcon", {
        type: "CLASS",
        color: this.myColor,
        className: this.myClassName,
        shape: this.shape,
      });
    },
    setImageIcon(url) {
      const iconStyle = { type: "ICON_URL", icon: url };
      this.$emit("updateIcon", iconStyle);
    },
    beforeUpload(file) {
      if (file.size > 2048000) {
        this.$message({
          message: "请选择2MB以内的jpg、jpeg或png图片",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    upload(uploadDetail) {
      let params = new FormData();
      params.append("attachmentFile", uploadDetail.file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      request({
        url: "/attachment/upload",
        method: "post",
        headers: config.headers,
        data: params,
      })
        .then((resp) => {
          if (resp.code === 0) {
            this.setImageIcon(resp.data.attachment.url);
          } else {
            this.$message({
              message: "上传失败",
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            message: "上传出错，服务器开小差了呢",
            type: "error",
          });
        });
    },
    searchIcon(val) {
      let newArr = this.fuzzyQuery(this.original, val);
      let newObj = Object.assign({}, newArr);
      this.fontAwesomen = newObj;
    },
    /**
     * 使用indexof方法实现模糊查询
     * @param  {Array}  list     进行查询的数组
     * @param  {String} keyWord  查询的关键词
     * @return {Array}           查询的结果
     */
    fuzzyQuery(list, keyWord) {
      /// console.log(list, keyWord);
      const englishRegex = /^[a-zA-Z]+$/;
      let field = "label";
      if (englishRegex.test(keyWord)) {
        field = "value";
      }
      var arr = [];
      this.fontAwesomenType.forEach((type) => {
        for (var i = 0; i < list[type].length; i++) {
          if (list[type][i][field] && list[type][i][field].includes(keyWord)) {
            arr.push({ type: type, val: list[type][i] });
          }
        }
      });
      return this.groupArr(arr);
    },
    groupArr(list) {
      var data = [];

      for (var i = 0; i < list.length; i++) {
        if (!data[list[i].type]) {
          var arr = [];
          arr.push(list[i].val);
          data[list[i].type] = arr;
        } else {
          data[list[i].type].push(list[i].val);
        }
      }

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-div {
  cursor: pointer;
  width: 258px;
  height: 36px;
  border: 1px solid#DFE1E6;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;

  .input-div {
    width: 189px;
    display: flex;
    flex-direction: row;
  }
}
.cont-div {
  position: absolute;
  background: #ffffff;
  width: 406px;
  min-height: 200px;
  margin-top: 6px;
  padding: 4px 12px;
  box-shadow: 0px 0px 4px rgba(9, 30, 66, 0.08),
    0px 2px 6px rgba(9, 30, 66, 0.06), 0px 4px 8px 2px rgba(9, 30, 66, 0.04);
  .content {
    border: 1px solid #fff;
    display: flex;
    flex-wrap: wrap;
    max-height: 260px;
    overflow: auto;
    margin: 6px 0;

    .icon-div {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 4px;
      width: 32px;
      height: 32px;
      border-radius: 6px;
      border: 2px solid #ffffff;
    }
    .borderCheck {
      border: 2px solid #dfe1e6;
    }
  }
  .upload-icon-container {
    margin: 8px 4px 4px 4px;

    .upload-content {
      .upload-btn {
        display: block;
        background: #f6f6f6;
        border-radius: 5px;
        font-size: 14px;
        line-height: 24px;
        color: #42526e;
        padding: 4px 0;
        text-align: center;
      }

      ::v-deep .el-upload {
        display: block;
      }
    }

    ::v-deep .el-upload-list {
      display: none;
    }

    .file-list {
      display: none;
    }

    .upload-tips {
      display: inline-block;
      color: #a5adba;
      font-size: 12px;
      line-height: 20px;
      margin-top: 4px;
    }
  }
}
.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 420px;
  color: #ffffff !important;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}
.emoji-mart-scroll {
  padding: 0 !important;
}
.emoji-mart {
  width: 385px !important;
}
::v-deep .emoji-mart-category-label span {
  padding: 0 !important;
}
.search {
  ::v-deep {
    .el-input--mini .el-input__inner {
      border-radius: 14px;
    }
  }
}
::v-deep {
  .el-tabs__header {
    margin: 0 0 8px;
  }
  .el-button--mini {
    padding: 6px 4px;
    font-size: 12px;
    border-radius: 3px;
    border: 0px solid #dfe1e6 !important;
  }
}
.color-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .content-item {
    cursor: pointer;
    margin: 4px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  .content-show {
    cursor: pointer;
    margin: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: #42526e;
  }
}
.fontSize12 {
  font-size: 12px;
  padding: 6px 0 2px 0;
  color: #6b778c;
}
</style>
