<template>
  <div
    @click.stop="handleUserSelectDialogOpen"
    class="form-user-select__wrap"
    :style="{ display: hideOuter ? 'none' : 'unset' }"
  >
    <div
      class="outer-selected-user__wrap"
      v-if="
        (outerSelectUser.length != 0 && !hideOuter) ||
        containsNodeUsers ||
        containsFlowStarter ||
        userFields.length
      "
    >
      <div class="tag" v-if="containsNodeUsers">
        <div class="name-avatar">节点</div>
        <span>节点负责人</span>
        <i
          v-if="!readOnly"
          @click.prevent.stop="closeNodeUsers"
          class="el-icon-close tag-close"
        ></i>
      </div>
      <div class="tag" v-if="containsFlowStarter">
        <div class="name-avatar">发起</div>
        <span>流程发起人</span>
        <i
          v-if="!readOnly"
          @click.prevent.stop="closedFlowStarter"
          class="el-icon-close tag-close"
        ></i>
      </div>

      <div
        class="tag"
        v-for="(opt, index) in outerSelectUser"
        :key="opt.id"
        :style="{ height: smallTag }"
      >
        <div class="name-avatar">
          {{ opt.name.substring(opt.name.length - 2) }}
        </div>
        <span v-text="opt.name"></span>
        <i
          v-if="!readOnly && !hideOuterClose"
          @click.prevent.stop="handleOuterTagClose(opt, index)"
          class="el-icon-close tag-close"
        ></i>
      </div>

      <div class="tag" v-for="(opt, index) in userFields" :key="opt.fieldName">
        <div class="name-avatar" :class="opt.delete ? 'delete' : ''">
          {{ opt.fieldTitle.substring(opt.fieldTitle.length - 2) }}
        </div>
        <span v-text="opt.fieldTitle"></span>
        <i
          v-if="!readOnly && !hideOuterClose"
          @click.prevent.stop="deleteUserFields(index)"
          class="el-icon-close tag-close"
        ></i>
      </div>
    </div>
    <div
      class="outer-selected-user__empty-wrap"
      v-else-if="outerSelectUser == 0 && !readOnly && !hideOuter"
      :style="{ height: small }"
    >
      选择成员
    </div>
    <div
      class="outer-selected-user__empty-wrap"
      v-else-if="outerSelectUser == 0 && readOnly && !hideOuter"
      style="cursor: unset"
      :style="{ height: small }"
    >
      无
    </div>
    <el-dialog
      v-if="dialogVisible"
      :title="dialogTitle || (multiple ? '用户多选' : '用户单选')"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      @open="handleDialogOpened"
      @close="handleClose"
    >
      <user-select-search
        class="search-container"
        v-model="innerSelectUser"
        :multiple="multiple"
      ></user-select-search>
      <div class="has-padding user-list-dialog__content">
        <div class="user-list-tab-container">
          <el-tabs v-model="activeName">
            <el-tab-pane name="单位通讯录">
              <span slot="label"> 单位通讯录</span>
              <div class="tab-container">
                <div class="tab-container__group">
                  <el-tree
                    :data="companyTreeList"
                    :props="defaultProps2"
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    node-key="id"
                    class="my-company-tree"
                    ref="myTree"
                    lazy
                    :load="loadCompanyNode"
                    @node-click="handleNodeClick"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <div class="node-label">
                        <i
                          v-if="data.nodeType == 'company'"
                          class="el-icon-s-home"
                          style="font-size: 16px"
                        ></i>
                        <i
                          v-else
                          class="el-icon-s-help"
                          style="font-size: 16px"
                        ></i>
                        {{ node.label }}
                      </div>
                    </div>
                  </el-tree>
                </div>
                <div class="tab-container__member">
                  <div class="list-header">
                    <el-checkbox
                      v-if="multiple"
                      @change="changeAll"
                      :value="allCheck"
                      :indeterminate="indeterminate"
                      >全选</el-checkbox
                    >
                  </div>
                  <div class="list-scroll">
                    <template v-if="employeeList.length">
                      <div
                        class="row"
                        style="height: 40px; cursor: pointer"
                        v-for="row in employeeList"
                        :key="row.id"
                        @click="handleMyContactMemberClick(row)"
                      >
                        <div class="row-left">
                          <el-checkbox
                            @click.stop.prevent.native="
                              handleMyContactMemberClick(row)
                            "
                            :value="handleCheckStatus(row)"
                          ></el-checkbox>
                          <div class="name-avatar">
                            {{ row.name.substring(row.name.length - 2) }}
                          </div>
                          <div
                            class="name-text row-flex"
                            v-text="row.name"
                          ></div>
                        </div>
                        <div class="row-right">
                          {{ row.mobile }}
                        </div>
                      </div>
                    </template>
                    <div
                      v-else
                      class="el-table__empty-block"
                      style="height: 100%"
                    >
                      <span class="el-table__empty-text">暂无数据</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <template v-if="!onlyCompany">
              <el-tab-pane name="联系人">
                <span slot="label" style="position: relative"
                  >联系人
                  <el-popover
                    placement="right"
                    width="400"
                    trigger="click"
                    v-model="popoverVisible"
                  >
                    <el-form
                      :model="contact"
                      :rules="rules"
                      name="contact"
                      ref="contactForm"
                      label-width="70px"
                      class="demo-ruleForm"
                    >
                      <el-form-item label="姓名" prop="name">
                        <el-input
                          v-model="contact.name"
                          autocomplete="off"
                          name="name"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="手机号" prop="mobile" width="200px">
                        <el-input
                          v-model.number="contact.mobile"
                          autocomplete="off"
                          name="mobile"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="单位" prop="organizationName">
                        <el-input
                          v-model="contact.organizationName"
                          name="organizationName"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          size="mini"
                          type="primary"
                          @click="handleAddDialogConfirm"
                          >确定
                        </el-button>
                      </el-form-item>
                    </el-form>
                    <el-tooltip
                      effect="dark"
                      content="添加联系人"
                      placement="bottom"
                      slot="reference"
                    >
                      <i class="el-icon-plus custom-icon"></i>
                    </el-tooltip> </el-popover
                ></span>

                <el-table
                  ref="contactTable"
                  :data="contactList"
                  tooltip-effect="dark"
                  style="width: 100%; overflow: auto"
                  size="mini"
                  border
                  :row-style="{
                    cursor: 'pointer',
                  }"
                  @row-click="handleMyContactMemberClick"
                  height="360px"
                >
                  <el-table-column width="50px">
                    <template slot-scope="{ row }">
                      <div class="name-avatar">
                        {{ row.name.substring(row.name.length - 2) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="姓名" align="center">
                  </el-table-column>

                  <el-table-column prop="mobile" label="手机号" align="center">
                  </el-table-column>
                  <el-table-column
                    prop="organizationName"
                    label="单位"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column width="50px">
                    <template slot-scope="{ row }">
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <i
                          class="el-icon-check"
                          style="font-size: 18px"
                          v-if="handleCheckStatus(row)"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane name="用户组">
                <span slot="label"> 用户组</span>
                <div class="tab-container">
                  <div class="tab-container__group">
                    <el-table
                      ref="groupList"
                      :data="groupTreeList"
                      tooltip-effect="dark"
                      style="width: 100%"
                      highlight-current-row
                      @current-change="handleCurrentChange"
                      size="mini"
                      :show-header="false"
                      :row-style="{
                        cursor: 'pointer',
                      }"
                      height="100%"
                    >
                      <el-table-column prop="title"></el-table-column>
                    </el-table>
                  </div>
                  <div class="tab-container__member">
                    <el-table
                      ref="groupList"
                      :data="currentGroup ? currentGroup.contactList : []"
                      tooltip-effect="dark"
                      style="width: 100%; overflow: auto"
                      size="mini"
                      border
                      :show-header="false"
                      :row-style="{
                        cursor: 'pointer',
                      }"
                      @row-click="handleMyContactMemberClick"
                      height="100%"
                    >
                      <el-table-column width="50px">
                        <template slot-scope="{ row }">
                          <div class="name-avatar">
                            {{ row.name.substring(row.name.length - 2) }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="name" align="center">
                      </el-table-column>
                      <el-table-column
                        label="手机号"
                        prop="mobile"
                        min-width="100"
                      ></el-table-column>

                      <el-table-column width="50px">
                        <template slot-scope="{ row }">
                          <div
                            style="
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            <i
                              class="el-icon-check"
                              style="font-size: 18px"
                              v-if="handleCheckStatus(row)"
                            ></i>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </el-tab-pane>
            </template>
            <el-tab-pane v-if="hasAsyncPane" name="动态负责人">
              <span slot="label"> 动态负责人</span>
              <slot name="pane-async"></slot>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="selected-user__wrap">
          <div class="member-tip">已选{{ memberCount }}个人员</div>
          <div class="user-container">
            <div class="user-item" v-if="containsNodeUsers">
              <div class="name-avatar">节点</div>
              <div class="name-text">节点负责人</div>
              <i
                @click="closeNodeUsers"
                class="el-icon-circle-close"
                style="color: #c1cbdb; cursor: pointer"
              ></i>
            </div>
            <div class="user-item" v-if="containsFlowStarter">
              <div class="name-avatar">流程</div>
              <div class="name-text">流程发起人</div>
              <i
                @click="closedFlowStarter"
                class="el-icon-circle-close"
                style="color: #c1cbdb; cursor: pointer"
              ></i>
            </div>
            <div
              class="user-item"
              v-for="(tag, index) in innerSelectUser"
              :key="tag.id"
            >
              <div class="name-avatar">
                {{ tag.name.substring(tag.name.length - 1) }}
              </div>
              <div class="name-text">{{ tag.name }}</div>
              <i
                @click="handleInnerTagClose(tag, index)"
                class="el-icon-circle-close"
                style="color: #c1cbdb; cursor: pointer"
              ></i>
            </div>
            <el-tag
              v-for="(tag, index) in userFields"
              :key="tag.fieldName"
              closable
              :disable-transitions="true"
              :hit="true"
              :type="tag.delete ? 'danger' : ''"
              effect="plain"
              size="medium"
              @close="deleteUserFields(index)"
              style="margin-bottom: 5px"
            >
              <div
                style="
                  display: inline-flex;
                  flex-direction: row;
                  align-items: center;
                "
              >
                <div
                  class="name-avatar"
                  :class="tag.delete ? 'delete' : ''"
                  style="
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    margin-right: 5px;
                  "
                >
                  <span>
                    {{
                      tag.fieldTitle.substring(tag.fieldTitle.length - 1)
                    }}</span
                  >
                </div>
                {{ tag.fieldTitle }}
              </div>
            </el-tag>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addContact, contactListPage } from "@/api/contact";
import { groupList } from "@/api/group";
import { companyEmployeeList, companyList } from "@/api/company";
import { departmentList } from "@/api/directoryConfig";
import { mapGetters } from "vuex";
import { listUserInfo } from "@/views/lowCode/api";
import { _eq } from "@zgg-core-utils/relyUtil";
import { userSearch } from "@/views/lowCode/templateConfig/api";
import userSelectSearch from "@/views/lowCode/templateConfig/user-select-search";

export default {
  name: "FormUserSelect",
  components: {
    userSelectSearch,
  },

  destroyed() {},
  mounted() {
    this.handleAddCurrentUser();
  },
  inject: {
    getCompanyId: {
      default: () => "",
    },
  },
  watch: {
    activeName() {
      this.$emit("changePane");
    },
    value: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        console.log(val, "val");
        if (this.multiple && val && val.length == 0) {
          this.innerSelectUser = [];
          this.outerSelectUser = [];
        } else if (!this.multiple && !val) {
          this.innerSelectUser = [];
          this.outerSelectUser = [];
        }
      },
    },
    outerSelectUser: {
      handler(val, oldVal) {},
    },
    innerSelectUser: {
      deep: true,
      handler(val, oldVal) {
        this.innerSelectUser = val;
        // if(this.directlySubmit){
        //   this.$emit(
        //     "input",
        //     this.innerSelectUser.map((item) => item.id)
        //   );
        // }
      },
    },
    userCustomMap: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        //如果两个前后两个值相等，不触发值的再次初始化
        if (
          val &&
          oldVal &&
          this._.differenceBy(Object.keys(val), Object.keys(oldVal)).length == 0
        ) {
          return;
        }
        if (this.value && typeof this.value == "object") {
          if (
            val.__proto__ == Object.prototype &&
            Object.keys(val).length != 0
          ) {
            let temp = this.value.filter((item) => {
              return val[item];
            });
            this.$set(
              this,
              "outerSelectUser",
              temp.map((item) => val[item]),
            );
          } else {
            if (this.value.length != 0) {
              let companyId;
              if (typeof this.getCompanyId === "function") {
                companyId = this.getCompanyId();
              }
              // listUserInfo({
              //   userIds: this.value,
              //   companyId: companyId
              // }).then((resp) => {
              //   let list = resp.data.list
              //   if (list.length != 0) {
              //     this.outerSelectUser = list
              //   } else {
              //     this.outerSelectUser = []
              //   }
              // })
            }
          }
        }
      },
    },
    userCustomInfo: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        //如果两个前后两个值相等，不触发值的再次初始化
        // if (val && oldVal && val.id == oldVal.id) return;
        if (_eq(val, oldVal)) return;
        if (this.value && typeof this.value == "string") {
          if (val && val.id) {
            this.outerSelectUser = [val];
          } else {
            if (this.value) {
              let companyId;
              if (typeof this.getCompanyId === "function") {
                companyId = this.getCompanyId();
              }
              // listUserInfo({
              //   userIds: [this.value],
              //   companyId: companyId
              // }).then((resp) => {
              //   let list = resp.data.list
              //   if (list.length == 1) {
              //     this.outerSelectUser = list
              //   } else {
              //     this.outerSelectUser = []
              //   }
              // })
            }
          }
        }
      },
    },
  },
  props: {
    hasAsyncPane: false,
    value: {
      type: [Array, String, Number],
      default() {
        if (this.multiple) {
          return [];
        } else {
          return "";
        }
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
    userCustomMap: {
      type: Object,
      default() {
        return {};
      },
    },
    userCustomInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    defaultCurrentUser: {
      type: Boolean,
      default() {
        return false;
      },
    },
    formType: String,
    placeholder: {
      type: String,
      default() {
        return "选择成员";
      },
    },
    hideOuter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //是否直接保存呢，不需要点击确定
    directlySubmit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dialogTitle: String,
    /**
     * 是否只显示单位通讯录
     */
    onlyCompany: {
      type: Boolean,
      default() {
        return false;
      },
    },
    /**
     * 当有值时，过滤出数组内的companyId
     */
    companyIds: {
      type: Array,
      default() {
        return [];
      },
    },
    resetOnClose: {
      type: Boolean,
      default() {
        return false;
      },
    },
    size: {
      type: String,
    },
    containsNodeUsers: {
      type: Boolean,
      default() {
        return false;
      },
    },
    containsFlowStarter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    userFields: {
      type: Array,
      default() {
        return [];
      },
    },
    hideOuterClose: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else if (value.toString().length !== 11) {
        return callback(new Error("手机号只能为11位"));
      } else {
        callback();
      }
    };

    let validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      popoverVisible: false,
      contact: {
        mobile: "",
        name: "",
        organizationName: "",
      },
      contactPageForm: {
        total: 0,
        pageSize: 999,
        pageNumber: 1,
      },
      contactList: [],
      groupTreeList: [],
      companyTreeList: [],
      defaultProps: {
        children: "children",
        label: "label",
        isLeaf: "leaf",
      },
      defaultProps2: {
        children: "childList",
        label: "name",
        isLeaf: "isLeaf",
      },
      rules: {
        mobile: [{ required: true, validator: validatePhone, trigger: "blur" }],
        name: [{ required: true, validator: validateName, trigger: "blur" }],
      },
      currentGroup: {},
      employeeList: [],
      innerSelectUser: [],
      outerSelectUser: [],
      activeName: "单位通讯录",
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentCompany"]),
    // dynamicParamList(){
    //   let list = []
    //   if(this.defaultCurrentUser){
    //     let {id,name,mobile} = this.currentUser
    //     let row = {
    //       label:"当前用户",
    //       name,
    //       mobile,
    //       targetUserId:id
    //
    //     }
    //     list.push(row)
    //     return list
    //   }
    //   return [];
    // }
    allCheck() {
      let ids = this.employeeList.map((item) => item.name);

      return !!(
        this.employeeList.length &&
        this.innerSelectUser.filter((item) => ids.includes(item.name))
          .length === this.employeeList.length
      );
    },
    small() {
      return this.size === "small" ? 32 + "px" : 40 + "px";
    },
    smallTag() {
      return this.size === "small" ? 20 + "px" : 28 + "px";
    },
    indeterminate() {
      let ids = this.employeeList.map((item) => item.name);
      let list = this.innerSelectUser.filter((item) => ids.includes(item.name));
      return !!(list.length && list.length < ids.length);
    },
    memberCount() {
      let count = this.innerSelectUser.length + this.userFields.length;
      if (this.containsNodeUsers) count += 1;
      if (this.containsFlowStarter) count += 1;
      return count;
    },
  },
  methods: {
    changeAll(val) {
      if (val) {
        // 全选
        this.employeeList.forEach((item) => {
          let index = this.innerSelectUser.findIndex(
            (row) => row.name == item.name,
          );
          if (index == -1) {
            this.innerSelectUser.push({
              name: item.name,
              id: this.getRowId(item),
              mobile: item.mobile,
            });
          }
        });
      } else {
        // 取消全选
        this.employeeList.forEach((item) => {
          let index = this.innerSelectUser.findIndex(
            (row) => row.name == item.name,
          );
          if (index >= 0) {
            this.$delete(this.innerSelectUser, index);
          }
        });
      }
    },
    closeNodeUsers() {
      this.$emit("update:containsNodeUsers", false);
    },
    closedFlowStarter() {
      this.$emit("update:containsFlowStarter", false);
    },
    deleteUserFields(index) {
      this.$delete(this.userFields, index);
    },
    /**
     * 重置内部数据
     */
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    /**
     * 手动初始化
     */
    manualInit(value) {
      if (value) {
        this.innerSelectUser = value.userRange.userInfoList;
      }
      this.dialogVisible = true;
    },
    handleAddCurrentUser() {
      if (this.defaultCurrentUser && this.formType == "add") {
        let { id, name, mobile } = this.currentUser;
        if (this.outerSelectUser.findIndex((item) => item.id == id) == -1) {
          this.outerSelectUser.push({
            name,
            id,
            mobile,
          });
        }
        if (this.innerSelectUser.findIndex((item) => item.id == id) == -1) {
          this.innerSelectUser.push({
            name,
            id,
            mobile,
          });
        }

        this.$nextTick(() => {
          if (this.multiple) {
            this.$emit(
              "input",
              this.innerSelectUser.map((item) => item.id),
            );
          } else {
            this.$emit("input", this.innerSelectUser[0].id);
          }
        });
      }
    },
    handleOuterTagClose(tag, index) {
      this.outerSelectUser.splice(index, 1);
      if (this.multiple) {
        this.$emit(
          "input",
          this.outerSelectUser.map((item) => item.id),
        );
        this.$emit("change", tag);
      } else {
        this.$emit("input", "");
        this.$emit("change", tag);
      }
      if (!this.multiple) {
        this.$emit("update:userCustomInfo", {});
        // this.userCustomInfo = {};
      }
    },
    handleInnerTagClose(tag, index) {
      this.innerSelectUser.splice(index, 1);
      if (!this.multiple) {
        this.userCustomInfo = {};
      }
    },
    handleConfirm() {
      if (this.multiple) {
        console.log(
          this.innerSelectUser,
          this.innerSelectUser.map((item) => item.id),
        );
        this.$emit(
          "input",
          this.innerSelectUser.map((item) => item.id),
        );
        this.outerSelectUser = this.deepClone(this.innerSelectUser);
      } else {
        if (this.innerSelectUser.length != 0) {
          this.$emit("input", this.innerSelectUser[0].id);
          this.outerSelectUser = this.deepClone(this.innerSelectUser);
        } else {
          this.$emit("input", "");
          this.outerSelectUser = [];
        }
      }
      this.$emit(
        "changeUsers",
        this._.cloneDeep(this.innerSelectUser),
        this.value,
      );
      this.dialogVisible = false;
    },
    handleMyContactMemberClick(row, column, event) {
      let { name, targetUserId, mobile } = row;
      if (row.checked) {
        let deleteIndex = this.innerSelectUser.findIndex(
          (item) => item.id == this.getRowId(row),
        );
        this.innerSelectUser.splice(deleteIndex, 1);
      } else {
        /**
         * 多选模式
         */
        if (!this.multiple) {
          this.innerSelectUser.splice(0, 1);
        }
        this.innerSelectUser.push({
          name,
          id: this.getRowId(row),
          mobile,
        });
      }
    },
    /**
     * 兼容角色
     * @param row
     * @returns {*}
     */
    getRowId(row) {
      if (row.type == "role") {
        return row.id;
      } else {
        return row.targetUserId;
      }
    },
    handleCheckStatus(row) {
      return (row.checked = !!this.innerSelectUser.find(
        (item) => item.id == this.getRowId(row),
      ));
    },
    handleNodeClick(data, node, el) {
      if (data.nodeType && data.nodeType == "company") {
        this.fetchData(data.id, 0);
      } else {
        this.fetchData(data.companyId, data.id);
      }
    },
    fetchData(companyId, departmentId) {
      let params = {
        pageSize: 9999,
        companyId,
        departmentId,
      };
      companyEmployeeList(params)
        .then((resp) => {
          this.employeeList = resp.data.page.list;
        })
        .catch((err) => {});
    },
    async loadCompanyNode(node, resolve) {
      console.log("loadCompanyNode");
      let { data, key, label } = node;
      if (data.nodeType == "company") {
        let departmentResp = await this.apiGetDepartmentList(data.id, 0);
        resolve(departmentResp);
      } else if (data.companyId) {
        let departmentResp = await this.apiGetDepartmentList(
          data.companyId,
          data.id,
        );
        resolve(departmentResp);
      } else {
        resolve([
          {
            name: this.$store.state.user.currentCompanyName,
            key: "company-" + this.$store.state.user.currentCompanyId,
            nodeType: "company",
            isLeaf: false,
            id: this.$store.state.user.currentCompanyId,
          },
        ]);
      }
    },
    apiGetDepartmentList(companyId, parentId) {
      let params = {
        companyId,
        parentId,
      };
      return departmentList(params).then((resp) => {
        return resp.data.departmentList.map((item) => {
          item.isLeaf = item.childList.length == 0;
          return item;
        });
      });
    },
    handleCurrentChange(val) {
      this.currentGroup = val;
    },
    //处理弹窗关闭时确认的点击事件
    handleAddDialogConfirm() {
      this.$refs["contactForm"].validate((valid) => {
        if (valid) {
          addContact(this.contact)
            .then((resp) => {
              this.$message({
                type: "success",
                message: "操作成功！",
              });
              this.apiGetContactList();
              this.popoverVisible = false;
              this.$refs["contactForm"].resetFields();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    handleUserSelectDialogOpen() {
      if (this.readOnly) return;
      this.dialogVisible = true;
    },
    handleDialogOpened() {
      this.activeName = "单位通讯录";
      this.innerSelectUser = this.deepClone(this.outerSelectUser);
      if (!this.onlyCompany) {
        this.apiGetContactList();
        this.apiGetGroupList();
      }
      this.apiGetCompanyList();
    },
    handleClose() {
      this.$emit("changePane");
      if (this.resetOnClose) {
        this.reset();
      }
    },
    apiGetContactList() {
      contactListPage(this.contactPageForm).then((resp) => {
        this.contactList = resp.data.contactPage.list;
      });
    },
    //获取所有联系人
    apiGetGroupList() {
      let params = {
        pageNumber: 1,
        pageSize: 9999,
      };
      groupList(params)
        .then((resp) => {
          this.groupTreeList = resp.data.teamPage.list;
        })
        .catch((err) => {});
    },
    apiGetCompanyList() {
      // companyList().then((resp) => {
      //   this.companyTreeList = resp.data.companyList.map((item) => {
      //     item.key = 'company-' + item.id
      //     item.nodeType = 'company'
      //     item.isLeaf = false
      //     return item
      //   })
      //   if (this.companyIds.length > 0) {
      //     this.companyTreeList = this.companyTreeList.filter((item) => {
      //       return this.companyIds.includes(item.id)
      //     })
      //   }
      // })
    },
  },
};
</script>

<style scoped lang="scss">
.form-user-select__wrap {
  width: 100%;
  overflow: auto;
  line-height: inherit;

  ::v-deep {
    .el-tag .el-icon-close {
      font-size: 16px;
      top: -3px;
    }
  }
}

.has-padding {
  padding-top: 10px;
  padding-right: 32px;
  padding-left: 32px;
}

.user-list-dialog__content {
  .selected-user__wrap {
    flex: none;
    min-height: 116px;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px dashed #dfe1e6;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 6px 0 16px;
    flex-direction: column;

    .user-container {
      display: flex;
      flex-wrap: wrap;

      .user-item {
        background-color: #f2f6fc;
        border-top-right-radius: 28px;
        border-bottom-right-radius: 28px;
        padding-right: 5px;
        height: 28px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        .name-avatar {
          background-color: var(--zgg-brand-color-6);
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: var(--zgg-color-white);
          margin-right: 6px;
          border-radius: 30px;
        }
        .name-text {
          color: var(--zgg-font-text-primary);
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }

  .user-list-tab-container {
    height: 410px;
    //border: 1px solid #dcdfe6;
    border-radius: 4px;
    position: relative;

    ::v-deep {
      .el-tabs__header {
        margin: 0;
      }
    }
  }
}

.outer-selected-user__wrap {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  // height: 40px;
}

.tab-container {
  height: 360px;
  display: flex;
  //border: 1px solid #e0e0e0;
  margin-top: 10px;

  .node-label i {
    color: var(--zgg-brand-color-6);
  }

  .tab-container__group {
    width: 45%;
    border-right: 1px solid #e0e0e0;
    overflow: auto;
  }

  .tab-container__member {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    .list-scroll {
      flex: 1;
    }
  }
}

.name-avatar {
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50%;
  background: var(--zgg-brand-color-6);
  color: #fff;
  width: 32px;
  height: 32px;
  font-size: 9.33333px;
  margin: 0 4px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.delete {
    background-color: #f56c6c;
  }
}

.user-tag {
  margin-bottom: 5px;
  max-width: calc(100% - 10px);
}

.user-line {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 15px);
  overflow: hidden;

  &.disabled {
    width: 100%;
  }
}

.user-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag {
  height: 28px;
  box-sizing: border-box;
  padding: 2px 8px 2px 2px;
  background-color: rgba(245, 246, 248, 1);
  font-size: 14px;
  color: #323233;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  display: flex;
  align-items: center;

  .name-avatar {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    box-sizing: border-box;
    padding: 2px;
  }

  .tag-close {
    background-color: rgba(9, 30, 66, 0.2);
    border-radius: 16px;
    margin-left: 5px;
    color: #ffffff;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .row-left {
    display: flex;
    align-items: center;
  }
}

.search-container {
  margin: 0 38px;
}

.member-tip {
  color: var(--zgg-font-text-secondary);
  font-size: 12px;
  margin-bottom: 6px;
}

::v-deep .el-dialog__header {
  border-bottom: none;
}
</style>
