import {
  getAdvanceQuerysByDataFilter,
  isEmpty,
  isEmptyByKey,
  getFormComponents,
  isIDEmpty,
} from "@zgg-core-utils/utils";
import { fetchMetaComponents } from "@/api/form";
import { getFormDetail } from "../form/api";

import { getRobotWorkflow } from "./api";
import _ from "lodash";

export const fromNodesMixins = {
  inject: {
    /** 获取触发器 */
    getTrigger: {
      default: () => [],
    },
    getFromNodes: {
      default: () => [],
    },
    getToNodes: {
      default: () => [],
    },
    getRootParams: {
      default: () => [],
    },
  },

  methods: {
    getChildNodes() {
      if (typeof this.getToNodes === "function") {
        let list = this.getToNodes();
        return list;
      }
      return [];
    },
    getCommitParentNodes() {
      if (typeof this.getFromNodes === "function") {
        let list = this.getFromNodes();
        let trigger = this.getTrigger();
        if (
          trigger.type == "trigger_form_data_change" &&
          ["update", "create"].includes(trigger.dataChangeType)
        ) {
          list.push(this._.cloneDeep(trigger));
        }
        // 按钮点击触发器类型
        if (trigger.type == "trigger_click") {
          list.push(this._.cloneDeep(trigger));
        }
        let typeList = [
          "trigger_form_data_change",
          "trigger_click",
          "action_form_data_create",
          "query_data_one",
        ];
        return list.filter(
          (item) =>
            typeList.includes(item.type) &&
            (item.formId || item.collection) &&
            !item.baseNodeKey,
        );
      }
      return [];
    },
    getParentNodes(nodeTypes = []) {
      if (typeof this.getFromNodes === "function") {
        let list = this.getFromNodes();
        let trigger = this.getTrigger();
        if (
          [
            "trigger_form_data_change",
            "trigger_click",
            "trigger_timer_field",
            "trigger_webhook",
            "trigger_subflow_collection",
          ].includes(trigger.type)
        ) {
          list.push(this._.cloneDeep(trigger));
        }
        let typeList = [
          "trigger_form_data_change",
          "trigger_click",
          "action_form_data_create",
          "query_data_one",
          "trigger_timer_field",
          "trigger_subflow_collection",
        ];
        typeList = typeList.concat(nodeTypes);
        typeList = [...new Set(typeList)];

        let nodeList = list.filter((item) => {
          let a =
            (typeList.includes(item.type) &&
              (item.formId || item.collection || item.nodeField)) ||
            (item.type == "json_parser" && item.jsonDataNodeKey) ||
            (item.type == "action_form_data_create" &&
              (item.formId || item.nodeKey));
          let b = ["developer_plugin", "trigger_webhook"].includes(item.type);
          let c =
            item.type == "subflow_collection" &&
            item.triggerOneTimes &&
            item.syncExec;
          return a || b || c;
        });
        nodeList = nodeList.filter((item) => {
          if (item.type == "action_form_data_create") {
            // 新增记录节点排除基于多条记录的新增方式
            return isEmpty(item.baseNodeKey);
          } else {
            return true;
          }
        });
        return nodeList;
      }

      return [];
    },
    /**
     * 获取含有关联数据、关联多条
     */
    getReferenceJsonNodes() {
      let trigger = this.getTrigger();
      /**
       * @type {Array}
       */
      let nodes = this.getFromNodes();
      let list = [];
      if (
        trigger.type == "trigger_form_data_change" &&
        ["create", "update"].includes(trigger.dataChangeType)
      ) {
        list.push({
          ...trigger,
          label: trigger.title + "【" + trigger.formTitle + "】",
        });
      }
      nodes = nodes.filter(
        (item) => isEmpty(item.dataSource) || item.dataSource == "form",
      );
      nodes.forEach((item) => {
        if (item.type == "query_data_one" && item.collection) {
          // 单条数据
          list.push({
            ...item,
            label: item.title + "【" + item.collectionTitle + "】",
          });
        } else if (
          item.type == "action_form_data_create" &&
          item.formId &&
          isEmpty(item.baseNodeKey)
        ) {
          // 新增数据
          list.push({
            ...item,
            label: item.title + "【" + item.formTitle + "】",
          });
        } else if (item.type == "json_parser") {
          list.push({
            ...item,
            label: item.title,
          });
        }
      });

      return list;
    },
  },
};

/**
 * 智能助手2.0，单条多条设置子表字段的混入
 */
export const rpaQueryDataMixins = {
  data() {
    return {
      nodeList: [],
      nodeFieldList: [],
    };
  },
  mixins: [fromNodesMixins],
  computed: {
    nodeKeyValue() {
      let str = this.node.nodeKey;
      if (this.node.nodeField?.collection) {
        if (!isEmpty(str)) {
          str += "_";
        }
        str += this.node.nodeField.collection;
      }
      return str;
    },
    nodeFieldValue() {
      let str = "";
      if (this.node.nodeField?.collection && this.node.nodeField?.name) {
        str = this.node.nodeField.collection + "_" + this.node.nodeField.name;
      } else if (this.node.nodeField?.name) {
        str = this.node.nodeField.name;
      }
      return str;
    },
  },
  created() {
    if (this.node.dataSource == "form" && this.node.collection) {
      this.fetchMetaComponents(this.node.collection);
    } else if (
      this.node.dataSource == "node_data_field" &&
      this.node.nodeField
    ) {
      this.nodeList = this.getReferenceJsonNodes();
      let node = this.nodeList.find((item) => item.key == this.node.nodeKey);

      if (node) {
        if (node.type == "json_parser") {
          let list = buildJSONParserComponents(node);
          list = list.filter((item) => item.componentName == "json_form");
          this.nodeFieldList = list;
        } else {
          let arr = ["json_form"];
          let tableName = node.formId || node.collection;
          if (tableName == this.node.nodeField.collection) {
            fetchMetaComponents(tableName, false).then((res) => {
              let list = [];

              if (
                node.nodeField &&
                node.nodeField.componentName == "json_form" &&
                !node.nodeField.referenceCollection
              ) {
                // 原始模式子表单
                let jsonForm = res.data.list.find(
                  (item) => item.name == node.nodeField.name,
                );
                if (jsonForm) {
                  list = jsonForm.components.filter((item) =>
                    arr.includes(item.componentName),
                  );
                }
              } else {
                list = res.data.list.filter((item) =>
                  arr.includes(item.componentName),
                );
              }
              list.forEach((item) => {
                item.collection = tableName;
              });

              this.nodeFieldList = list;
            });
          }
        }
      }
      if (node && node.type == "json_parser") {
        let list = buildJSONParserComponents(node);
        let component = list.find(
          (item) => item.name == this.node.nodeField.name,
        );
        this.componentList = component?.components ?? [];
      } else if (this.node.nodeField.collection) {
        this.fetchMetaComponents(this.node.nodeField.collection);
      }
    }
  },
  methods: {
    /**
     *
     * @param {String} val
     */
    async changeNodeField(val) {
      let name;
      if (val.indexOf("_") >= 0) {
        name = val.split("_")[1];
      } else {
        name = val;
      }

      let component = this.nodeFieldList.find((item) => item.name == name);
      let node = this.nodeList.find((item) => item.key == this.node.nodeKey);

      let collection;
      if (
        node.type == "query_data_one" &&
        node.dataSource == "node_data_field"
      ) {
        collection = node.nodeField.collection;
      } else {
        collection = node.formId || node.collection;
      }
      let field = {
        title: component.title,
        name: component.name,
        componentName: component.componentName,
        nodeKey: this.node.nodeKey,
        collection,
      };

      if (["json_form"].includes(field.componentName)) {
        if (
          field.componentName == "json_form" &&
          component.dataMode != "reference_form"
        ) {
          // 子表原始模式，非独立实体表
          this.$set(this.node, "nodeField", field);
          this.componentList = this._.cloneDeep(component.components);
        } else {
          field.referenceCollection = component.tableName;
          this.$set(this.node, "nodeField", field);
          this.fetchMetaComponents(field.collection);
        }
      }
      this.$set(this.node, "dataFilter", { rel: "and", advanceQuery: [] });
    },
    /**
     *
     * @param {String} val
     */
    changeNode(val) {
      let nodeKey = val.split("_")[0];
      let node = this.nodeList.find((item) => item.key == nodeKey);
      this.$set(this.node, "nodeKey", nodeKey);

      this.$set(this.node, "dataFilter", { rel: "and", advanceQuery: [] });

      if (node) {
        if (node.type == "json_parser") {
          let list = buildJSONParserComponents(node);
          list = list.filter((item) => item.componentName == "json_form");

          this.nodeFieldList = list;
          this.$set(this.node, "nodeField", { collection: "" });
        } else {
          let tableName;
          if (node.dataSource == "node_data_field") {
            if (node.nodeField.referenceCollection) {
              tableName = node.nodeField.referenceCollection;
            } else {
              tableName = node.nodeField.collection;
            }
          } else {
            tableName = node.formId || node.collection;
          }
          this.$set(this.node, "nodeField", { collection: tableName });
          let arr = ["json_form"];

          fetchMetaComponents(tableName, false).then((res) => {
            let list = [];

            if (
              node.nodeField &&
              node.nodeField.componentName == "json_form" &&
              !node.nodeField.referenceCollection
            ) {
              // 原始模式子表单
              let jsonForm = res.data.list.find(
                (item) => item.name == node.nodeField.name,
              );
              if (jsonForm) {
                list = jsonForm.components.filter((item) =>
                  arr.includes(item.componentName),
                );
              }
            } else {
              list = res.data.list.filter((item) =>
                arr.includes(item.componentName),
              );
            }
            list.forEach((item) => {
              item.collection = tableName;
            });

            this.nodeFieldList = list;
          });
        }
      }
    },
    /**
     * 当改变节点的数据源类型时的事件
     * @param {String} val
     */
    changeDataSource(val) {
      this.$set(this.node, "collectionTitle", "");
      this.$set(this.node, "collection", "");
      this.$set(this.node, "dataFilter", { rel: "and", advanceQuery: [] });
      this.$set(this.node, "nodeKey", "");
      this.$set(this.node, "nodeField", "");
      if (val == "node_data_field") {
        this.nodeList = this.getReferenceJsonNodes();
      }
    },
    /**
     * 获取表单的字段
     * @param {String} tableName
     */
    fetchMetaComponents(tableName) {
      fetchMetaComponents(tableName).then((res) => {
        if (
          this.node.dataSource == "node_data_field" &&
          this.node.nodeField?.componentName == "json_form"
        ) {
          // 节点字段，原始模式子表
          let jsonForm = res.data.list.find(
            (item) => item.name == this.node.nodeField.name,
          );
          if (jsonForm) {
            this.componentList = jsonForm.components;
          }
        } else {
          this.componentList = res.data.list;
        }
      });
    },
  },
};

/**
 * 智能助手2.0 新增、更新选择节点（子表单）时的公用混入方法
 */
export const rpaUpdateByNodeMixins = {
  inject: {
    getFromNodes: {
      default: () => [],
    },
  },
  computed: {
    dataSource: {
      get() {
        return this.node.dataSource;
      },
      set(val) {
        this.$set(this.node, "dataSource", val);
      },
    },
    nodeKey: {
      get() {
        return this.node.nodeKey;
      },
      set(val) {
        this.$set(this.node, "nodeKey", val);
      },
    },
  },
  data() {
    return {
      nodeList: [],
    };
  },
  created() {
    if (this.node.dataSource == "node_data") {
      this.buildNodeList();
      this.buildNodeCreateFields(this.node.nodeKey);
    }
  },
  methods: {
    changeDataSource(val) {
      this.$set(this.node, "formTitle", "");
      this.$set(this.node, "formId", "");
      this.$set(this.node, "nodeKey", "");
      this.$set(this.node, "nodeCollection", "");
      if (
        ["action_form_data_create", "action_form_data_save"].includes(
          this.node.type,
        )
      ) {
        this.$set(this.node, "createFieldValues", []);
      }
      if (
        ["action_form_data_update", "action_form_data_save"].includes(
          this.node.type,
        )
      ) {
        this.$set(this.node, "updateFieldValues", []);
        this.$set(this.node, "dataFilter", {
          rel: "and",
          advanceQuery: [],
        });
      }

      if (val == "node_data") {
        this.buildNodeList();
      }
    },
    buildNodeList() {
      let list = [];
      if (typeof this.getFromNodes === "function") {
        list = this.getFromNodes();
      }
      list = list.filter(
        (item) =>
          ["query_data_one", "query_data_list"].includes(item.type) &&
          item.dataSource == "node_data_field" &&
          (item.nodeField.collection || item.nodeField.referenceCollection),
      );

      this.nodeList = list;
    },
    changeNodeKey(val) {
      let nodeCollection = this.buildNodeCreateFields(val);
      this.$set(this.node, "nodeCollection", nodeCollection);
      if (
        ["action_form_data_create", "action_form_data_save"].includes(
          this.node.type,
        )
      ) {
        this.$set(this.node, "createFieldValues", []);
      }

      if (
        ["action_form_data_update", "action_form_data_save"].includes(
          this.node.type,
        )
      ) {
        this.$set(this.node, "updateFieldValues", []);
        this.$set(this.node, "dataFilter", {
          rel: "and",
          advanceQuery: [],
        });
      }
    },
    buildNodeCreateFields(nodeKey) {
      let node = this.nodeList.find((item) => item.key == nodeKey);
      if (node) {
        let nodeCollection = node?.nodeField?.referenceCollection ?? "";
        let collection = node.nodeField.collection;
        getFormDetail(collection).then((res) => {
          let componentList = [];
          componentList = getFormComponents(res.data.form.component.components);
          // if (!nodeCollection) {
          let component = componentList.find(
            (item) => item.name == node.nodeField.name,
          );
          componentList = component.components;
          // }
          this.componentList = componentList;

          if (
            ["action_form_data_create", "action_form_data_save"].includes(
              this.node.type,
            )
          ) {
            this.buildCreateFelds(componentList);
          }
        });
        return nodeCollection;
      }
      return "";
    },
  },
};

/**
 * 检查过滤条件值是否有被删除的节点
 * @param {*} fields
 * @param {*} nodes
 * @returns
 */
export function validateFields(fields = [], nodes = []) {
  let isDeleteNode = false;
  let list = fields.filter((item) => item.valueType == "nodeField");

  for (let index = 0; index < list.length; index++) {
    const item = list[index];
    let node = nodes.find((obj) => obj.key == item.nodeField.nodeKey);
    if (!node) {
      isDeleteNode = true;
      break;
    }
    if (node.dataSource == "node_data") {
      let parentNode = nodes.find((obj) => obj.key == node.nodeKey);
      if (!parentNode) {
        isDeleteNode = true;
        break;
      }
      if (parentNode.dataSource == "node_data_field") {
        isDeleteNode =
          nodes.findIndex((obj) => obj.key == parentNode.nodeField?.nodeKey) ==
          -1;
      }
    } else if (node.dataSource == "node_data_field") {
      let parentNode = nodes.find((obj) => obj.key == node.nodeField.nodeKey);
      if (!parentNode) {
        isDeleteNode = true;
        break;
      }
    }
  }

  return isDeleteNode;
}

/**
 *
 * @param {Array} parentNodes
 */
export async function buildNodeComponentsMap(parentNodes) {
  let componentsMap = {};
  parentNodes = parentNodes.filter((item) => item.dataSource != "node_data");
  for (let i = 0; i < parentNodes.length; i++) {
    let node = parentNodes[i];
    let id;
    if (["developer_plugin", "trigger_webhook"].includes(node.type)) {
      id = node.key;
      if (node.type == "trigger_webhook") {
        let list = [];
        let arr = ["body", "header", "params"];
        arr.forEach((row) => {
          node[row].forEach((item) => {
            let obj = this._.cloneDeep(item);
            obj.title = `${row}.${item.title}`;
            list.push(obj);
          });
        });
        componentsMap[id] = list;
      } else {
        componentsMap[id] = node.responseParams;
      }
      this.buildFieldList(node.key, componentsMap[id]);
    } else if (node.type == "subflow_collection") {
      if (!componentsMap[node.robotId]) {
        id = node.robotId;
        let res = await getRobotWorkflow({ robotId: node.robotId });
        let flowParams = res.data.workflow?.rpaFlow?.config?.flowParams ?? [];
        componentsMap[node.robotId] = flowParams;
        this.buildFieldList(node.robotId, componentsMap[node.robotId]);
      }
    } else if (node.type == "json_parser") {
      id = node.key;
      let list = buildJSONParserComponents(node);
      componentsMap[id] = list.filter(
        (item) => item.componentName != "json_form",
      );
      this.buildFieldList(node.key, componentsMap[id]);
    } else if (
      node.dataSource == "node_data_field" &&
      isEmpty(node.nodeField.referenceCollection && node.nodeField.collection)
    ) {
      let _node = parentNodes.find(
        (item) => item.key == node.nodeField.nodeKey,
      );
      let id = node.nodeField.nodeKey;
      if (_node && _node.type == "json_parser") {
        id = node.key;
        let components = buildJSONParserComponents(_node);

        let component = components.find(
          (item) => item.name == node.nodeField.name,
        );

        if (component) {
          let list = component.components.filter(
            (item) =>
              item.form &&
              !["json_form", "text_area", "reference_data"].includes(
                item.componentName,
              ),
          );

          componentsMap[id] = list;
          this.buildFieldList(node.key, list);
        }
      } else {
        componentsMap[id] = [];
      }
    } else {
      if (node.dataSource == "node_data_field") {
        id = node.nodeField.referenceCollection || node.nodeField.collection;
      } else {
        id = node.formId || node.collection;
      }

      if (id) {
        if (!componentsMap[id]) {
          let res = await getFormDetail(id);
          let list = getFormComponents(res.data.form.component.components);
          if (
            !(
              node.dataSource == "node_data_field" &&
              isEmpty(node.nodeField.referenceCollection)
            )
          ) {
            list = list.filter(
              (item) =>
                item.form &&
                !["text_area", "reference_data"].includes(item.componentName),
            );
          }

          componentsMap[id] = list;
        }
        if (
          node.dataSource == "node_data_field" &&
          isEmpty(node.nodeField.referenceCollection)
        ) {
          let component = componentsMap[id].find(
            (item) => item.name == node.nodeField.name,
          );
          let list = [];
          if (component) {
            list = component.components.filter(
              (item) =>
                item.form &&
                !["json_form", "text_area", "reference_data"].includes(
                  item.componentName,
                ),
            );
          }
          this.buildFieldList(node.key, list);
        } else {
          this.buildFieldList(node.key, componentsMap[id]);
        }
      }
    }
  }
  return componentsMap;
}

/**
 * 加载节点数的叶子字段节点
 * @param {*} node
 * @param {*} resolve
 */
export function loadNodeLeaf(node, resolve) {
  if (node.level === 0) {
    let nodeList = [];
    if (this.rootFlowParams?.length) {
      nodeList.push({
        type: "root",
        key: "root",
        title: "本流程参数",
      });
    }

    let list = nodeList.concat(this.parentNodes);
    list = this._.cloneDeep(list);
    list.forEach((item) => {
      item.leaf = false;
    });
    resolve(list);
  } else {
    let list;
    if (
      ["developer_plugin", "trigger_webhook", "json_parser"].includes(
        node.data.type,
      )
    ) {
      list = this.componentsMap[node.data.key];
    } else if (node.data.type == "subflow_collection") {
      list = this.componentsMap[node.data.robotId];
    } else if (node.data.type == "root") {
      list = this.componentsMap["root"];
    } else {
      let formId;

      if (node.data.dataSource == "node_data_field") {
        formId =
          node.data.nodeField.referenceCollection ||
          node.data.nodeField.collection;
        if (!formId) {
          formId = node.data.key;
        }
      } else {
        formId = node.data.formId || node.data.collection;
      }

      if (
        node.data.dataSource == "node_data_field" &&
        isEmpty(node.data.nodeField.referenceCollection) &&
        isEmpty(node.data.nodeField.collection)
      ) {
        list = this.componentsMap[formId];
      } else {
        list = this.componentsMap[formId];

        if (
          node.data.dataSource == "node_data_field" &&
          isEmpty(node.data.nodeField.referenceCollection)
        ) {
          let component = list.find(
            (item) => item.name == node.data.nodeField.name,
          );
          if (component) {
            list = component.components.filter(
              (item) =>
                item.form &&
                !["json_form", "text_area", "reference_data"].includes(
                  item.componentName,
                ),
            );
          } else {
            list = [];
          }
        }
      }
    }
    list = list.filter((item) => item.componentName != "json_form");
    list.forEach((item) => {
      item.leaf = true;
    });
    resolve(list);
  }
}

/**
 * 不能使用自定义值的组件
 */
export const noCustomComponents = [
  "image_uploader",
  "attachment_uploader",
  "sign_input",
  "sign_list_input",
  "reference_data",
  "reference_data_list",
  "cascade_data",
  "sn_input",
  "parent_data",
  "json_form",
];

/**
 * 判断出分支外其他action类型的过滤条件是否为空
 * @param {*} dataFilter
 * @returns
 */
export function isEmptyRpaActionFilter(dataFilter) {
  if (!isEmptyByKey(dataFilter, "advanceQuery")) {
    let advanceQuery = getAdvanceQuerysByDataFilter(dataFilter);
    if (
      advanceQuery.findIndex(
        (item) =>
          isEmpty(item.key) ||
          (!["nil", "nnil"].includes(item.oper) &&
            ((item.valueType == "custom" && isEmpty(item.value)) ||
              (item.valueType == "nodeField" && isEmpty(item.nodeField.name)))),
      ) >= 0
    ) {
      return true;
    }
  }
  return false;
}

export function rapNodeValidate() {
  let nodeType = this.node.type;
  let nodeKey = this.node.key;

  /**
   * @description 节点错误信息
   * @typedef {Object} nodeError
   * @property {string} message 错误信息描述
   * @property {string} type 节点类型
   * @property {string} key 节点key
   * @property {string} value 错误代码 setting,deleteNode,notSameForm,deleteField
   */

  /**
   * @type {nodeError}
   */
  let error;
  if (["trigger_timer_once", "trigger_timer_cron"].includes(nodeType)) {
    // 触发器-定时单次校验、定时重复校验
    if (isEmpty(this.node.executeTime)) {
      error = {
        message: `请设置【${this.node.title}】触发时间`,
        type: nodeType,
        key: nodeKey,
        value: "setting",
      };
    }
  } else if (nodeType == "trigger_timer_field") {
    if (isIDEmpty(this.node.formId)) {
      error = {
        message: "完善节点配置",
        type: this.node.type,
        key: this.node.key,
        value: "setting",
      };
    }
  }
  this.isError = !isEmpty(error);
  return error;
}

/**
 * 构建JSONParser节点的输出控件列表
 * @param {object} node
 */
export function buildJSONParserComponents(node) {
  let list = [];
  if (node.type != "json_parser") {
    return list;
  }
  node.outputFields.forEach((item) => {
    let obj = {
      key: item.name,
      name: item.name,
      title: item.title,
      componentName: item.componentName,
      form: true,
    };
    if (item.componentName == "json_form") {
      let components = [];
      if (item.subFields) {
        item.subFields.forEach((sub) => {
          components.push({
            key: sub.name,
            name: sub.name,
            title: sub.title,
            componentName: sub.componentName,
            form: true,
          });
        });
      }
      obj.container = true;
      obj.components = components;
    }
    list.push(obj);
  });
  return list;
}

/**
 * 智能助手2.0——检查组件是否可以设置公式
 * @param {string} componentName
 * @returns {boolean}
 */
export function isCanSetRelyForRpa(componentName) {
  return [
    "input",
    "text_area",
    "input_number",
    "date_picker",
    "mobile_input",
    "radio_group",
    "checkbox_group",
    "select",
    "select_checkbox",
  ].includes(componentName);
}
