var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-setting-icon-div",class:{
    up_down: _vm.globalConfig.groupStyle.layout == 'up_down',
    left_right: _vm.globalConfig.groupStyle.layout == 'left_right',
  },style:(_vm.buttonWrapStyle),on:{"click":_vm.buttonClick}},[_c('div',{staticClass:"button-icon-container"},[(
        _vm.itemConfig.buttonStyle && _vm.itemConfig.buttonStyle.type === 'ICON_URL'
      )?_c('div',{staticClass:"button-image-icon",style:({
        'background-image': 'url(' + _vm.itemConfig.buttonStyle.icon + ')',
      })}):_c('div',{staticClass:"button-icon-wrap",style:(_vm.iconWrapStyle)},[_c('i',{class:_vm.iconClassName,staticStyle:{"color":"#fff","font-size":"20px","margin-right":"0"}})])]),_c('span',{staticClass:"button-title",class:{
      up_down: _vm.globalConfig.groupStyle.layout == 'up_down',
      left_right: _vm.globalConfig.groupStyle.layout == 'left_right',
    },style:(_vm.isH5 ? '' : `color:${_vm.globalConfig?.colorConfig?.contentColor}`)},[_vm._v(" "+_vm._s(_vm.itemConfig.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }