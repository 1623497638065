
// 表单设计/仪表盘/视图/智能助手/左侧树缩放功能
export const scaleWidth = {
  data(){
    return {
      menuWrapWidth:250,
      onDraging:false
    }
  },
  computed:{
    wrapMarginLeft() {
      if (this.treeViewVisible) {
        return 0
      } else {
        return -this.menuWrapWidth
      }
    }
  },
  methods:{
    dragmove(event) {
      event.preventDefault();
      event.stopPropagation();
      //最小宽度为250
      if( event.clientX<250||event.clientX>450) return
      this.menuWrapWidth = event.clientX + 4

    },
    dragup(event) {
      event.preventDefault();
      event.stopPropagation();
      this.onDraging = false
      document.removeEventListener("mousemove", this.dragmove);
      document.removeEventListener("mouseup", this.dragup);
    },
    dragdown(event) {
      event.preventDefault();
      event.stopPropagation();

      if (event.buttons !== 1) {
        // 不是鼠标左键按下的则不执行一下操作
        return;
      }
      this.onDraging = true
      let element = event.currentTarget.parentElement;
      document.addEventListener("mousemove", this.dragmove, {
        passive: false,
      });
      document.addEventListener("mouseup", this.dragup, {
        passive: false,
      });
    },
  }
}
