<template>
  <div class="chart-drawer">
    <div style="display: flex; justify-content: flex-end">
      <span style="padding: 10px">
        <i
          @click="show = false"
          class="el-icon-close"
          style="font-size: 24px; cursor: pointer"
        ></i>
      </span>
    </div>
    <div>
      <div class="row bottom-line">
        <div class="label">标题：</div>
        <div class="flex">
          <el-input
            v-model="title"
            @change="changeBar"
            placeholder=""
            size="mini"
          ></el-input>
        </div>
      </div>

      <div
        v-if="
          ['table'].includes(componentName) &&
          ['json_form'].includes(data.field.componentName)
        "
        class="row bottom-line"
        style="margin-top: 16px"
      >
        <div class="label">显示字段：</div>
        <div class="flex show-field">
          <el-select
            v-model="data.subColumnMode"
            size="mini"
            placeholder="请选择"
          >
            <el-option label="默认" value="DEFAULT"></el-option>
            <el-option label="自定义" value="CUSTOM"></el-option>
          </el-select>
          <div
            class="drag-field-container"
            v-if="data.subColumnMode === 'CUSTOM'"
          >
            <fields-select
              title="选择在卡片上显示的字段"
              v-model="subColumns"
              :columns="data.field.component.components"
              componentName="form_data"
              :includeJsonForm="false"
            >
              <div class="add-show-field" slot="reference">添加显示字段</div>
            </fields-select>
            <draggable
              v-model="subColumns"
              handle=".icon-yidong"
              animation="300"
            >
              <div
                v-for="(item, index) in subColumns"
                :key="item.field.key"
                class="drag-field-item"
              >
                <span class="item-name" :title="item.title">{{
                  item.title
                }}</span>
                <div class="item-operate">
                  <i class="iconfont icon-yidong" style="font-size: 15px"></i>
                  <i
                    class="iconfont icon-delete-bin-line"
                    style="font-size: 15px"
                    @click="deleteField(index)"
                  ></i>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <!-- 明细表可排序字段：单行文本、多行文本、数字、日期选择器、单选框、下拉选择器、流水号、用户单选 -->
      <div
        v-if="(chartDisabled && isShowSort) || !chartDisabled"
        class="row bottom-line"
      >
        <div class="label">排序：</div>
        <el-select
          clearable
          v-model="orderType"
          placeholder=""
          size="mini"
          @clear="clearSort"
          @change="changeSort"
        >
          <el-option label="升序" value="asc"> </el-option>
          <el-option label="降序" value="desc"> </el-option>
          <el-option label="自定义排序" value="custom" v-if="isShowCustomSort">
          </el-option>
        </el-select>
        <el-button
          v-if="data.orderType == 'custom'"
          style="width: 100%; margin-top: 5px"
          size="mini"
          @click="setCustomSort"
          >设置排序
        </el-button>
      </div>
      <!-- 明细表，透视表自定义宽度，对齐方式 -->
      <div
        v-if="['table', 'pivot_table'].includes(componentName) && !isShowWidth"
      >
        <div class="row bottom-line">
          <div class="label">宽度：</div>
          <el-radio-group v-model="widthIsDefault">
            <el-radio :label="false"
              ><span style="font-size: 12px">默认宽度</span></el-radio
            >
            <el-radio :label="true"
              ><span style="font-size: 12px">自定义</span></el-radio
            >
          </el-radio-group>
          <br />
          <template v-if="widthIsDefault">
            <el-input-number
              size="small"
              v-model="data.width"
              controls-position="right"
              :min="50"
              :step="10"
            ></el-input-number>
            px
          </template>
          <!-- <div class="flex">
              <el-input
                v-model="data.width"
                placeholder=""
                size="mini"
                @input="changeNumber('width', $event)"
              ></el-input>
            </div> -->
        </div>
        <div class="row bottom-line">
          <div class="label">对齐方式：</div>
          <div class="flex">
            <el-select v-model="data.align" size="mini">
              <el-option label="左对齐" value="left"></el-option>
              <el-option label="右对齐" value="right"></el-option>
              <el-option label="居中对齐" value="center"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 指标 -->
      <template v-if="nodeType == 'metrics'">
        <div class="row bottom-line">
          <div class="label">汇总方式：</div>
          <div class="flex">
            <el-select
              v-model="data.oper"
              placeholder="请选择汇总方式"
              size="mini"
              @change="changeOper"
            >
              <el-option
                v-for="(item, index) in operList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="label" style="margin-top: 10px">数据格式</div>
          <metric-format
            :numberFormat.sync="data.numberFormat"
            @refershData="refershData"
          ></metric-format>
        </div>
      </template>

      <div
        class="row bottom-line"
        v-if="
          nodeType == 'xFields' &&
          data.field.componentName == 'date_picker' &&
          view.componentName != 'table'
        "
      >
        <div class="label">汇总方式：</div>
        <div class="flex">
          <el-select
            v-model="datePicker"
            placeholder="请选择汇总方式"
            size="mini"
            @change="changeDatePicker"
          >
            <el-option
              v-for="(item, index) in dateOperator"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="label">显示格式</div>
        <div class="flex">
          <el-select
            v-model="dateFormat"
            placeholder="请选择汇总方式"
            size="mini"
            @change="changeOper"
          >
            <el-option
              v-for="(item, index) in formatOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="sortDialogVisible"
      title="自定义排序"
      :visible.sync="sortDialogVisible"
      width="600px"
      append-to-body
    >
      <div class="draggble-body">
        <draggable :list="sortList" animation="300" handle=".icon-yidong">
          <div v-for="(item, index) in sortList" :key="index" class="sort-item">
            <i class="iconfont icon-yidong" style="font-size: 15px"></i
            ><span class="sort-title">{{ item }}</span>
          </div>
        </draggable>
      </div>
      <span slot="footer">
        <el-button @click="sortDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getTableColumnWidthByColumn } from "@/views/lowCode/utils";
import { isEmpty } from "@/zgg-core/utils";
import moment from "moment";
import metricFormat from "./metric-format";
import Draggable from "vuedraggable";
import FieldsSelect from "@/components/FieldsSelect";
export default {
  components: { metricFormat, Draggable, FieldsSelect },
  props: {
    componentName: [String, Number],
    view: Object,
    visible: Boolean,
    data: Object,
    nodeType: String,
    getXFieldData: {
      type: Function,
      default() {
        return () => [];
      },
    },
  },
  data() {
    return {
      sortList: [],
      dateOperator: [
        {
          label: "年",
          value: "year",
        },
        {
          label: "年-季",
          value: "quarter",
        },
        {
          label: "年-月",
          value: "month",
        },
        {
          label: "年-周",
          value: "week",
        },
        {
          label: "年-月-日",
          value: "date",
        },
        {
          label: "年月日时",
          value: "hour",
        },
        {
          label: "年月日时分",
          value: "minute",
        },
      ],
      oper: [
        {
          label: "计数",
          value: "count",
        },
        {
          label: "去重计数",
          value: "countDistinct",
        },
        {
          label: "求和",
          value: "sum",
        },
        {
          label: "平均值",
          value: "avg",
        },
        {
          label: "最大值",
          value: "max",
        },
        {
          label: "最小值",
          value: "min",
        },
      ],
      sortDialogVisible: false,
      subColumns: [],
    };
  },
  watch: {
    "data.subColumnMode": {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          this.$set(this.data, "subColumnMode", "DEFAULT");
        }
        if (this.data.subColumnMode !== "CUSTOM") {
          this.$set(
            this.data,
            "subColumns",
            this.data.field.component.components,
          );
          this.changeOper();
        }
      },
    },
    subColumns: {
      handler(val) {
        if (this.data.subColumnMode == "CUSTOM" && val.length > 0) {
          let arr = [];
          val.forEach((item) => {
            if (item.align) {
              arr.push(item.field);
            } else {
              arr.push(item);
            }
          });

          this.$set(this.data, "subColumns", arr);
          this.changeOper();
        }
      },
    },
  },
  computed: {
    title: {
      get() {
        return this.data.title;
      },
      set(val) {
        this.$set(this.data, "title", val);
      },
    },
    operList() {
      if (this.data.field.componentName == "input_number") {
        return this.oper;
      } else {
        return this.oper.filter(
          (item) => item.value == "count" || item.value == "countDistinct",
        );
      }
    },
    formatOptions() {
      if (this.datePicker == "year") {
        // 年
        return [
          {
            label: moment().format("YYYY"),
            value: "yyyy",
          },
          {
            label: moment().format("YYYY年"),
            value: "yyyy年",
          },
        ];
      } else if (this.datePicker == "quarter") {
        // 年-季
        return [
          {
            label: moment().format("YYYY-Q季度"),
            value: "yyyy-Q季度",
          },
          {
            label: moment().format("YYYY年Q季度"),
            value: "yyyy年Q季度",
          },

          {
            label: moment().format("YYYY/Q季度"),
            value: "yyyy/Q季度",
          },
        ];
      } else if (this.datePicker == "month") {
        // 年-月
        return [
          {
            label: moment().format("YYYY-MM"),
            value: "yyyy-MM",
          },
          {
            label: moment().format("YYYY年MM月"),
            value: "yyyy年MM月",
          },
          {
            label: moment().format("YYYY.MM"),
            value: "yyyy.MM",
          },
          {
            label: moment().format("YYYY/MM"),
            value: "yyyy/MM",
          },
        ];
      } else if (this.datePicker == "week") {
        // 年-周
        return [
          {
            label: moment().format("YYYY-W周"),
            value: "yyyy-W周",
          },
          {
            label: moment().format("YYYY年W周"),
            value: "yyyy年W周",
          },

          {
            label: moment().format("YYYY/W周"),
            value: "yyyy/W周",
          },
        ];
      } else if (this.datePicker == "date") {
        // 年月日
        return [
          {
            label: moment().format("YYYY-MM-DD"),
            value: "yyyy-MM-dd",
          },
          {
            label: moment().format("YYYY年MM月DD日"),
            value: "yyyy年MM月dd日",
          },
          {
            label: moment().format("YYYY.MM.DD"),
            value: "yyyy.MM.dd",
          },
          {
            label: moment().format("YYYY/MM/DD"),
            value: "yyyy/MM/dd",
          },
        ];
      } else if (this.datePicker == "hour") {
        // 年月日时
        return [
          {
            label: moment().format("YYYY-MM-DD HH"),
            value: "yyyy-MM-dd HH",
          },
          {
            label: moment().format("YYYY年MM月DD日HH时"),
            value: "yyyy年MM月dd日HH时",
          },
          {
            label: moment().format("YYYY.MM.DD HH"),
            value: "yyyy.MM.dd HH",
          },
          {
            label: moment().format("YYYY/MM/DD HH"),
            value: "yyyy/MM/dd HH",
          },
        ];
      } else if (this.datePicker == "minute") {
        // 年月日时分
        return [
          {
            label: moment().format("YYYY-MM-DD HH:mm"),
            value: "yyyy-MM-dd HH:mm",
          },
          {
            label: moment().format("YYYY年MM月DD日HH时mm分"),
            value: "yyyy年MM月dd日HH时mm分",
          },
          {
            label: moment().format("YYYY.MM.DD HH:mm"),
            value: "yyyy.MM.dd HH:mm",
          },
          {
            label: moment().format("YYYY/MM/DD HH:mm"),
            value: "yyyy/MM/dd HH:mm",
          },
        ];
      }

      return [];
    },
    dateFormat: {
      get() {
        let format = "";
        if (this.data.datePrecision) {
          format = this.data.datePrecision.format;
        }
        return format;
      },
      set(val) {
        if (isEmpty(this.datePicker)) {
          this.datePicker = "date";
        }
        this.$set(this.data.datePrecision, "format", val);
      },
    },
    datePicker: {
      get() {
        let picker;
        if (this.data.datePrecision) {
          picker = this.data.datePrecision.picker;
        }
        return picker;
      },
      set(val) {
        if (this.data.datePrecision) {
          this.$set(this.data.datePrecision, "picker", val);
        } else {
          let datePrecision = {
            picker: val,
            format: "",
          };
          this.$set(this.data, "datePrecision", datePrecision);
        }
      },
    },
    widthIsDefault: {
      get() {
        let isDefault = false;
        if (this.data.width > 0) {
          isDefault = true;
        }
        return isDefault;
      },
      set(val) {
        if (val === false) {
          this.$set(this.data, "width", 0);
        } else {
          let width = getTableColumnWidthByColumn(this.data.field);
          width = parseInt(width, 10);
          this.$set(this.data, "width", width);
        }
      },
    },
    orderType: {
      get() {
        return this.data.orderType;
      },
      set(val) {
        this.$set(this.data, "orderType", val);
      },
    },
    isShowWidth() {
      let arr = ["json_form", "form_data"];
      return arr.includes(this.data.field.componentName);
    },
    isShowCustomSort() {
      return (
        this.nodeType == "xFields" &&
        this.componentName != "table" &&
        ["input", "select", "radio_group", "sn_input"].includes(
          this.data?.field?.componentName,
        )
      );
    },
    isShowSort() {
      let arr = [
        "input",
        "text_area",
        "input_number",
        "date_picker",
        "radio_group",
        "user_select",
        "user_select",
        "sn_input",
        "select",
      ];
      return arr.includes(this.data.field.componentName);
    },
    chartDisabled() {
      let arr = ["table"];
      return arr.includes(this.componentName);
    },
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
        this.$emit("update:data", null);
      },
    },
  },
  created() {
    if (this.data.subColumnMode == "CUSTOM") {
      this.subColumns = this.data.subColumns;
    }
  },
  methods: {
    changeDatePicker() {
      this.dateFormat = this.formatOptions[0].value;
      this.changeOper();
    },
    refershData() {
      this.$emit("refershData");
    },
    //清除
    clearSort() {
      this.$emit("clearSort", this.data);
    },
    changeSort(val) {
      this.$emit("changeSort");
      this.$set(this.data, "orderType", val);
      if (val == "custom") return;
      this.$set(this.data, "customSort", "");
      this.changeOper();
    },
    changeBar() {
      this.$nextTick(() => {
        this.$emit("changeBar");
      });
    },
    changeOper() {
      this.$nextTick(() => {
        this.$emit("changeOper");
      });
    },
    setCustomSort() {
      let list = this._.cloneDeep(this.getXFieldData());
      this.sortList = [];
      list.forEach((item) => {
        let val = item?.[this.data?.field?.name];
        if (val && !this.sortList.includes(val)) {
          this.sortList.push(val);
        }
      });
      this.sortDialogVisible = true;
    },
    handleConfirm() {
      this.$set(this.data, "customSort", {
        [this.data.field.name]: this.sortList,
      });
      this.changeOper();
      this.sortDialogVisible = false;
    },

    deleteField(index) {
      this.subColumns.splice(index, 1);
      this.$set(this.data, "subColumns", this.subColumns);
      this.changeOper();
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-drawer {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 100;
  animation: rtl-drawer-in 0.3s 1ms;
}
.row {
  margin: 10px;
  .label {
    width: 80px;
    text-align: left;
    font-size: 12px;
    margin: 5px 0px;
  }
}
.draggble-body {
  padding: 20px 12px;
  overflow: auto;
  height: 400px;
  .icon-yidong {
    width: 16px;
    height: 16px;
    color: #a5adba;
    &:hover {
      cursor: move;
    }
  }
  .sort-item {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 6px;
    border-radius: 5px;
    &:hover {
      background: var(--background-base, #f2f6fc);
    }
  }
  .sort-title {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.show-field {
  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-input__inner {
    font-size: 13px;
  }

  .drag-field-container {
    .add-show-field {
      line-height: 1;
      color: var(--blue-6);
      text-align: center;
      cursor: pointer;
      user-select: none;
      margin: 13px 0 18px 0;
    }
    .el-input__suffix {
      top: 6px;
    }
    .drag-field-item {
      line-height: 1;
      display: flex;
      justify-content: space-between;
      margin: 0 0 13px 8px;

      .item-name {
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }

      .item-operate {
        user-select: none;
        i {
          &:hover {
            color: var(--blue-6);
          }
        }
      }
    }
  }
}
</style>
