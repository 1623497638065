<template>
  <div class="container">
    <div class="open-platform-unit">
      <div class="unit-header">已安装插件</div>
      <div class="unit-table">
        <div class="table-header">
          <div class="field field-name">插件</div>
          <!-- <div class="field field-version">版本</div> -->
          <div class="field field-status">状态</div>
          <div class="field field-operate">操作</div>
        </div>
        <div class="table-body" v-loading="loading">
          <Draggable
            v-model="pluginInstallData"
            handle=".table-item"
            ghostClass="ghost"
            chosenClass="chosen"
            @update="pluginUpdate"
            @add="pluginUpdate"
            animation="300"
          >
            <div
              class="table-item"
              v-for="(item, index) in pluginInstallData"
              :key="index"
              @click="showPluginDetai(item)"
            >
              <div class="field field-name">
                <i
                  class="iconfont icon-yidong"
                  style="cursor: pointer; color: #999"
                ></i>
                <div class="icon-url">
                  <img :src="item.iconUrl" v-if="item.iconUrl" alt="" />
                  <img src="../../../assets/icon_plugin.png" v-else alt="" />
                </div>
                <div class="name-con">
                  <div class="name-title">{{ item.name }}</div>
                  <div class="name-overview">{{ item.overview }}</div>
                </div>
              </div>
              <!-- <div class="field field-version">v1.0</div> -->
              <div class="field field-status" @click.stop="stop">
                <el-switch
                  v-model="item.enable"
                  @change="handleSettingChange($event, item)"
                  active-color="#3870EA"
                  inactive-color="#C1CBDB"
                >
                </el-switch>
              </div>
              <div class="field field-operate">
                <el-button
                  type="text"
                  v-if="item.params && item.params.length"
                  @click.native.stop="setPluginInstallParam(item)"
                  style="margin-right: 10px"
                  ><i class="el-icon-setting"></i
                ></el-button>
                <el-button type="text" @click.native.stop="deletePlugin(item)"
                  ><i class="el-icon-delete"></i
                ></el-button>
              </div>
            </div>
          </Draggable>
        </div>
        <el-empty
          v-if="pluginInstallData.length == 0"
          description="暂未安装插件"
        ></el-empty>
      </div>
    </div>

    <!-- 插件详情 -->
    <el-drawer
      :title="pluginTitle"
      :visible.sync="visible"
      direction="btt"
      size="95%"
      @open="openDrawer"
      @close="closeDrawer"
    >
      <div class="drawer-wrapper">
        <div class="drawer-describe">
          <div class="describe-icon">
            <img :src="pluginDetail.iconUrl" alt="" />
          </div>
          <div class="describe-text">
            <div class="text-title-container">{{ pluginDetail.name }}</div>
            <div class="text-detail">{{ pluginDetail.overview }}</div>
          </div>
        </div>
        <div class="recommend-details">
          <div class="drawer-title">插件详情</div>
          <div class="recommend-details-text">
            <div class="plugin-picture-video" v-if="pluginDetail.detailFiles">
              <img
                class="media-item"
                src="https://images.jiandaoyun.com/FhX-AJMXIu_2h-tKER5XSSGHJeWX"
                alt=""
              />
            </div>
            <el-empty v-else description="暂无插件视频或图片介绍"></el-empty>
            <div class="details-text">
              {{ pluginDetail.details }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 设置通用参数 -->
    <el-dialog
      title="设置通用参数"
      :visible.sync="dialogVisible"
      @closed="closeDialog"
      width="560px"
    >
      <div style="padding: 20px">
        <el-form ref="paramsForm" label-width="80px">
          <el-form-item
            :label="item.title"
            v-for="(item, index) in paramsForm"
            :key="index"
          >
            <el-input v-model="item.value"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button
          :loading="saveLoading"
          type="primary"
          @click="savePluginInstallParam"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPluginInstallList,
  getPluginDetail,
  pluginInstallSwitchEnable,
  pluginUnInstall,
  pluginInstallDetail,
  savePluginInstallParam,
  pluginInstallSort,
} from "./api";
import Draggable from "vuedraggable";
import { isEmptyByKey } from "@zgg-core-utils/utils";
export default {
  name: "",
  data() {
    return {
      loading: true,
      visible: false,
      dialogVisible: false,
      pluginTitle: "", // 插件标题
      pluginDetail: {}, // 选中插件详情
      pluginInstallData: [], // 插件data
      saveLoading: false,
      paramsForm: [], // 通用参数
    };
  },
  components: {
    Draggable,
  },
  computed: {},
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    // 初始化数据
    initData() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
      };
      this.pluginInstallData = [];
      getPluginInstallList(params)
        .then((res) => {
          this.loading = false;
          if (!isEmptyByKey(res, "data.page.list")) {
            this.pluginInstallData = res.data.page.list;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 显示插件详情
    showPluginDetai(item) {
      this.pluginDetail = item;
      this.pluginTitle = item.name;
      this.visible = true;
    },
    // 设置通用参数
    setPluginInstallParam(item) {
      this.pluginDetail = item;
      this.dialogVisible = true;
      pluginInstallDetail({
        pluginInstallId: this.pluginDetail.id,
      }).then((res) => {
        if (!isEmptyByKey(res, "data.pluginInstall.params")) {
          this.paramsForm = res.data.pluginInstall.params;
        }
      });
    },
    // 保存通用参数
    savePluginInstallParam() {
      let params = {
        pluginInstallId: this.pluginDetail.id,
        params: this.paramsForm,
      };
      savePluginInstallParam(params).then((res) => {
        this.$message({
          type: "success",
          message: "设置成功!",
        });
      });
    },
    // 更新插件排序
    pluginUpdate(e) {
      console.log(e);
      let dragPlugin = this.pluginInstallData[e.newIndex];
      if (!dragPlugin) {
        return;
      }
      let params = {
        id: dragPlugin.id,
      };
      let preId = 0;
      if (e.newIndex > 0) {
        preId = this.pluginInstallData[e.newIndex - 1].id;
      }
      params.preId = preId;
      pluginInstallSort(params).then(() => {
        this.initData();
      });
    },
    // 卸载插件
    deletePlugin(item) {
      this.$confirm(
        "插件将停止运行，请确保您的工作不受影响",
        "确定要删除插件吗？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        },
      )
        .then(() => {
          pluginUnInstall({
            pluginInstallId: item.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.pluginInstallData = this.pluginInstallData.filter((row) => {
              return item.id != row.id;
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    stop() {},
    // 启用-禁用
    handleSettingChange(e, item) {
      pluginInstallSwitchEnable({
        pluginInstallId: item.id,
      }).then((res) => {});
    },
    openDrawer() {},
    closeDrawer() {},
    closeDialog() {},
    cancelDialog() {
      this.dialogVisible = false;
    },
  },
  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.initData();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
}
.open-platform-unit {
  width: 1200px;
  margin: 20px auto 0;
  .unit-header {
    font-size: 20px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .unit-table {
    .field-name {
      width: 360px;
    }
    .field-version {
      width: 240px;
    }
    .field-status {
      width: 300px;
    }
    .field-operate {
      width: 200px;
    }
    .table-header {
      height: 48px;
      padding: 16px 26px 16px 16px;
      background-color: #fff;
      border: 1px solid #ebecee;
      border-radius: 6px 6px 0 0;
      font-size: 0;
      .field {
        font-size: 14px;
        display: inline-block;
      }
      .field-name {
        // padding-left: 20px;
      }
      .field-version {
        text-align: center;
      }
      .field-status {
        text-align: center;
      }
      .field-operate {
      }
    }
    .table-body {
      position: relative;
      background: #fff;
      border-bottom: 1px solid #ebecee;
      cursor: pointer;
      .table-item {
        display: flex;
        height: 80px;
        padding: 20px 26px 20px 16px;
        background: #fff;
        border-bottom: 1px solid #ebecee;
        .field {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 100%;
        }
        .field-name {
          .icon-url {
            width: 46px;
            min-width: 46px;
            height: 46px;
            margin-right: 16px;
            border-radius: 5px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
          .name-con {
            width: 260px;
            .name-title {
              display: inline-block;
              width: auto;
              max-width: 80%;
              color: #223346;
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-wrap: break-word;
              word-break: break-word;
            }
            .name-overview {
              margin-top: 4px;
              color: #838892;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .field-version {
          font-size: 14px;
          justify-content: center;
        }
        .field-status {
          justify-content: center;
        }
        .field-operate {
        }
      }
    }
  }
}

.ghost {
  opacity: 0.2 !important;
  background-color: #93c4e8;
}
.chosen {
  background-color: #fff;
}
.drawer-wrapper {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  .drawer-describe {
    .describe-icon {
      display: inline-block;
      width: 80px;
      min-width: 80px;
      height: 80px;
      margin-right: 16px;
      vertical-align: top;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .describe-text {
      display: inline-block;
      max-width: 1000px;
      vertical-align: top;
      .text-title-container {
        display: inline-block;
        width: 1000px;
        color: #223346;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .text-detail {
        margin-top: 6px;
        color: #838892;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .drawer-install-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      float: right;
      margin-top: 12px;
    }
  }
  .recommend-details {
    .drawer-title {
      height: 22px;
      margin-top: 30px;
      margin-bottom: 10px;
      color: #141e31;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .recommend-details-text {
      .plugin-picture-video {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 10px;
        .media-item {
          width: 590px;
          height: 330px;
          margin-bottom: 18px;
        }
      }
      .details-text {
        font-size: 14px;
      }
    }
  }
  .drawer-detail {
    .help-url {
      padding: 10px 0;
      font-size: 14px;
    }
  }
}
::v-deep .el-drawer__header {
  margin-bottom: 15px;
  span {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  .el-drawer__close-btn .el-icon-close {
    &:hover {
      background-color: #d4d7da;
    }
  }
}
::v-deep .el-drawer__body {
  background-color: #f5f6f8;
}
</style>
