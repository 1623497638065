<template>
  <div>
    <div class="attr-row">
      <div
        @click="visible = true"
        class="attr-label row-between"
        style="cursor: pointer"
      >
        <span v-text="isSetting() ? '已添加过滤条件' : '数据过滤条件'"></span>
        <i :class="isSetting() ? 'el-icon-edit-outline' : 'el-icon-plus'"></i>
      </div>
    </div>
    <el-dialog
      title="数据过滤条件"
      :visible.sync="visible"
      width="840px"
      @open="open"
      append-to-body
      destroy-on-close
    >
      <div style="padding: 20px">
        <!-- <query-condition
          label="添加过滤条件来限定关联数据范围"
          :relationQuery="dataFilter.advanceQuery"
          :rel.sync="dataFilter.rel"
          :currentComponentList="originComponents"
          :componentList="tableColumns"
          :form="component"
          :formId="params.formId"
        ></query-condition> -->
        <ui-advance-filter
          style="margin-bottom: 10px"
          :dataFilter="dataFilter"
          :sourceComponentList="originComponents"
          :componentList="tableColumns"
          :name="this.component.name"
          :formId="params.formId"
        ></ui-advance-filter>
      </div>
      <div slot="footer">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveFilters" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { isEmpty } from "@zgg-core-utils/relyUtil";
import QueryCondition from "../component/QueryCondition";
import { isCompleteDataFilter } from "@zgg-core-utils/utils";

export default {
  name: "attr-filter",
  inject: ["getComponents"],
  components: { QueryCondition },
  props: {
    component: Object,
    params: Object,
    tableColumns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    originComponents() {
      return this.getComponents();
    },
  },
  data() {
    return {
      visible: false,
      dataFilter: {
        rel: "and",
        advanceQuery: [],
      },
    };
  },

  methods: {
    isSetting() {
      let dataFilter;
      if (
        this.component.componentName == "select" ||
        this.component.componentName == "select_checkbox"
      ) {
        dataFilter = this.component.metaFieldOption.dataFilter;
      } else {
        dataFilter = this.component.dataFilter;
      }
      console.log(dataFilter);

      let r = !isEmpty(dataFilter) && !isEmpty(dataFilter.advanceQuery);
      console.log(r);
      return r;
    },
    open() {
      let dataFilter;
      if (
        this.component.componentName == "select" ||
        this.component.componentName == "select_checkbox"
      ) {
        dataFilter = this.component.metaFieldOption.dataFilter;
      } else {
        dataFilter = this.component.dataFilter;
      }
      if (!isEmpty(dataFilter)) {
        this.dataFilter = this._.cloneDeep(dataFilter);
      }
    },
    saveFilters() {
      // 保存查询条件

      let hasError = !isCompleteDataFilter(this.dataFilter.advanceQuery);

      if (hasError) {
        this.$message.warning("请设置完整的过滤条件");
        return;
      }
      this.$set(
        this.dataFilter,
        "empty",
        this.dataFilter.advanceQuery.length == 0,
      );
      if (
        this.component.componentName == "select" ||
        this.component.componentName == "select_checkbox"
      ) {
        this.$set(
          this.component.metaFieldOption,
          "dataFilter",
          this._.cloneDeep(this.dataFilter),
        );
      } else {
        this.$set(
          this.component,
          "dataFilter",
          this._.cloneDeep(this.dataFilter),
        );
      }

      this.visible = false;
    },
  },
};
</script>
