<template>
  <el-scrollbar style="height: 100%; flex: 1" class="work-tree-container">
    <el-tree
      :key="1"
      v-if="lazy"
      ref="tree"
      :highlight-current="true"
      :auto-expand-parent="true"
      :node-key="nodeKey"
      :show-checkbox="showCheckbox"
      :props="defaultProps"
      :default-expand-all="false"
      class="my-work-tree"
      :expand-on-click-node="true"
      :accordion="false"
      :indent="10"
      @node-click="handleNodeClick"
      @check-change="handleCheckChange"
      :draggable="true"
      :allow-drag="handleAllowDrag"
      :allow-drop="handleAllowDrop"
      @node-drag-enter="nodeDragEnter"
      :filter-node-method="filterNode"
      :current-node-key="currentNodeKey"
      @node-drop="handleDrop"
      lazy
      :load="loadMoreNode"
    >
      <div class="node-wrap" slot-scope="{ node, data }">
        <svg-icon
          iconClass="wenjianjia"
          id="folder"
          v-if="data.dataType == 'folder'"
          style="margin-right: 5px; font-size: 16px"
        ></svg-icon>
        <i
          class="iconfont icon-FileText"
          style="color: #3c73ea"
          v-if="data.dataType == 'resource' && data.type == 10"
        ></i>
        <i
          class="iconfont icon-biaodanqun"
          style="color: #9b51e0"
          v-if="data.dataType == 'resource' && data.type == 20"
        ></i>
        <i
          class="iconfont icon-Robot"
          v-if="data.dataType == 'resource' && [30, 50].includes(data.type)"
        ></i>
        <i
          class="iconfont icon-yibiaopan"
          style="color: #52c41a"
          v-if="data.dataType == 'resource' && data.type == 40"
        ></i>
        <div class="node-label-wrap">
          <div class="node-label-content">
            {{ node.label }}
          </div>
          <slot
            v-if="data.dataType == 'resource'"
            name="label"
            :node="node"
            :data="data"
          >
          </slot>
          <!--          <div v-if="data.defaultDataShareRange == 'platform'" style="transform:scale(0.8);height:20px;line-height:20px;white-space:nowrap;background: #f0f9eb;border: 1px solid #e1f3d8;box-sizing: content-box;border-radius: 51px;color: #67c23a;font-size: 10px;padding: 0 4px">标准</div>-->
        </div>
        <div class="node-button-manage">
          <slot name="more" :node="node" :data="data"> </slot>
        </div>
      </div>
    </el-tree>

    <el-tree
      v-else
      :key="2"
      ref="tree"
      :show-checkbox="showCheckbox"
      :highlight-current="true"
      :auto-expand-parent="true"
      node-key="id"
      :props="defaultProps"
      :default-expand-all="true"
      class="my-work-tree"
      :expand-on-click-node="true"
      :accordion="false"
      :indent="10"
      @node-click="handleNodeClick"
      :filter-node-method="filterNode"
      @node-drop="handleDrop"
      @check-change="handleCheckChange"
      :data="treeData"
    >
      <div class="node-wrap" slot-scope="{ node, data }">
        <svg-icon
          iconClass="wenjianjia"
          v-if="data.dataType == 'folder'"
          style="margin-right: 5px; font-size: 16px"
        ></svg-icon>
        <i
          class="iconfont icon-FileText"
          style="color: #3c73ea"
          v-if="data.dataType == 'resource' && data.type == 10"
        ></i>
        <i
          class="iconfont icon-biaodanqun"
          style="color: #9b51e0"
          v-if="data.dataType == 'resource' && data.type == 20"
        ></i>
        <i
          class="iconfont icon-Robot"
          v-if="data.dataType == 'resource' && data.type == 30"
        ></i>
        <i
          class="iconfont icon-yibiaopan"
          style="color: #52c41a"
          v-if="data.dataType == 'resource' && data.type == 40"
        ></i>
        <div class="node-label-wrap">
          <div
            class="node-label-content"
            :class="{ 'rpa-enable': data.type == 31 && data.enable }"
          >
            {{ node.label }}
          </div>
          <slot
            v-if="data.dataType == 'resource'"
            name="label"
            :node="node"
            :data="data"
          >
          </slot>
          <!--          <div v-if="data.defaultDataShareRange == 'platform'" style="transform:scale(0.8);height:20px;line-height:20px;white-space:nowrap;background: #f0f9eb;border: 1px solid #e1f3d8;box-sizing: content-box;border-radius: 51px;color: #67c23a;font-size: 10px;padding: 0 4px">标准</div>-->
        </div>
        <div class="node-button-manage">
          <slot name="more" :node="node" :data="data"> </slot>
        </div>
      </div>
    </el-tree>
    <div class="recycle-btn" v-if="hasResRecycleAuth()">
      <el-button
        type="text"
        class="iconfont icon-a-recycle-line"
        @click="showRecycleBin"
        >回收站</el-button
      >
    </div>
    <recycle-bin-dialog
      :visible.sync="recycleBinVisible"
      v-if="recycleBinVisible"
      title="回收站"
      type="resource"
      :resourceType="type"
      @refresh="refreshTree"
    ></recycle-bin-dialog>
  </el-scrollbar>
</template>

<script>
import { dirMove, dirSort, dirTree } from "@/views/lowCode/api";
import recycleBinDialog from "@/views/lowCode/form/recycle-bin-dialog.vue";
import { hasRoleAuth } from "@/utils/roleAuth";

const RESOURCE = "resource";
const FOLDER = "folder";
export default {
  name: "tree",
  components: { recycleBinDialog },
  props: {
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [String, Number],
      default: 10,
    },
    lazyProps: {
      type: Boolean,
      default: true,
    },
    // 唯一标识的属性
    nodeKey: {
      type: String,
      default: "id",
    },
    // 当前选中节点(处理url带数据选中树节点回显)
    currentNodeKey: [String, Number],
  },
  data() {
    return {
      defaultProps: {
        children: "directoryList",
        label: "title",
        isLeaf: function (data, node) {
          return !data.hasChildList || data.dataType == "resource";
        },
        disabled: (data, node) => {
          return data.dataType === "folder";
        },
      },
      treeData: [],
      keyWord: "",
      lazy: true,
      recycleBinVisible: false,
    };
  },
  watch: {
    // '$store.state.user.currentCompanyId':{
    //   handler(newVal) {
    //     this.$refs.tree.reset()
    //   }
    // }
  },
  computed: {},

  methods: {
    hasResRecycleAuth() {
      return hasRoleAuth("ops_auth_menu_res_recycle");
    },
    showRecycleBin() {
      this.recycleBinVisible = true;
    },
    async refreshTree() {
      await this.fetchTree();
      this.$refs.tree.root.setData(this.treeData);
    },
    setChecked(nodeOrKey, status) {
      this.$refs.tree.setChecked(nodeOrKey, status);
    },
    reset() {
      console.log("reset");
      this.lazy = true;
      this.keyWord = "";
      this.update(0);
      // let params = {
      //   type: this.type,
      //   parentId:0,
      // }
      //  dirTree(params).then(resp => {
      //  this.$refs.tree.updateKeyChildren(0,resp.data.directoryList)
      // })
    },
    search(keyWord) {
      this.keyWord = keyWord;
      this.lazy = false;

      this.fetchTree();
    },
    async loadMoreNode(node, resolve) {
      let parentId = 0;
      if (node.level !== 0) {
        parentId = node.data.id;
      }
      let params = {
        type: this.type,
        parentId,
      };
      await dirTree(params).then((resp) => {
        return resolve(resp.data.directoryList);
      });
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      if (dropType == "inner") {
        let param = {
          id: draggingNode.data.id,
          parentId: dropNode.data.id,
        };
        dirMove(param).then((resp) => {
          // this.fetchWorkTree()
          this.update(dropNode.data.id);
        });
      } else {
        let childNode = dropNode.parent.childNodes.filter(
          (row) => row.data.dataType == draggingNode.data.dataType,
        );
        let index = childNode.findIndex(
          (item) => item.data.id == draggingNode.data.id,
        );
        let preId;
        if (index >= 1) {
          preId = childNode[index - 1].data.id;
        } else {
          preId = 0;
        }
        let param = {
          id: draggingNode.data.id,
          parentId: dropNode.parent.level != 0 ? dropNode.parent.data.id : 0,
          preId: preId,
        };
        dirSort(param).then((resp) => {});
      }
    },
    filter(val) {
      this.$refs.tree.filter(val);
    },
    /**
     * 树过滤
     * @param value
     * @param data
     * @returns {boolean}
     */
    filterNode(value, data) {
      let { dataType, title } = data;
      if (title.indexOf(value) == -1) {
        return false;
      }
      return true;
    },
    nodeDragEnter(dragNode, dropNode, type, e) {},
    handleAllowDrag(draggingNode) {
      return true;
    },
    //处理目录节点是否允许置入
    //参数有三种情况：'prev'、'inner' 和 'next'，
    handleAllowDrop(draggingNode, dropNode, type) {
      let dragNodeData = draggingNode.data;
      let dropNodeData = dropNode.data;
      //置入节点的下一个兄弟节点
      let dropNodeNextSibling = dropNode.nextSibling;
      let dropNodeNextSiblingData =
        dropNodeNextSibling && dropNodeNextSibling.data;
      //置入节点的下一个兄弟节点
      let dropNodePreSibling = dropNode.previousSibling;
      let dropNodePreSiblingData =
        dropNodePreSibling && dropNodePreSibling.data;
      //不允许置入到群节点
      if (dropNodeData.dataType == RESOURCE && type == "inner") {
        return false;
      }
      if (
        type == "next" &&
        dropNodeNextSibling &&
        dropNodeData.dataType == dropNodeNextSiblingData.dataType &&
        dragNodeData.dataType != dropNodeData.dataType
      ) {
        return false;
      }
      if (
        type == "prev" &&
        dropNodePreSibling &&
        dropNodeData.dataType == dropNodePreSiblingData.dataType &&
        dragNodeData.dataType != dropNodeData.dataType
      ) {
        return false;
      }
      if (
        !dropNodePreSibling &&
        dragNodeData.dataType == RESOURCE &&
        dropNodeData.dataType == FOLDER &&
        type == "prev"
      ) {
        return false;
      }
      if (
        !dropNodeNextSibling &&
        dragNodeData.dataType == FOLDER &&
        dropNodeData.dataType == RESOURCE &&
        type == "next"
      ) {
        return false;
      }
      return true;
    },
    async fetchTree(parentId = 0) {
      console.log(this.type);
      let params = {
        type: this.type,
        parentId,
        keyword: this.keyWord,
      };
      if (this.keyWord) {
        params.fullTree = true;
      }
      await dirTree(params).then((resp) => {
        this.treeData = resp.data.directoryList;
      });
    },
    handleCheckChange(obj, status) {
      this.$emit("checkChange", obj, status);
    },
    handleNodeClick(data, node) {
      this.$emit("nodeClick", data, node);
    },
    /**
     * 离线更新节点
     * @param model
     */
    insertToWorkGroupTree(model) {
      console.log(model);
      /**
       * dataType 来区别是文件夹还是工作群(资源) ，dataType=folder|resource 群列表接口，当顶部选择群方式显示时，还跟原来一样，按照更新时间逆序 (ok)
       * type=文件夹类型,类型 10:公有,即单位的 20:私有,即普通的
       */
      let { id, parentId, title, type, dataType } = model;
      let parentNode;
      if (parentId == "0") {
        parentNode = this.$refs.tree.root;
      } else {
        parentNode = this.$refs.tree.getNode(parentId);
      }
      if (!parentNode) return;
      let childNode = parentNode.childNodes;
      let currentNode = this.$refs.tree.getNode(id);
      //如果存在，就是更新节点数据，比如重命名
      if (currentNode) {
        this.$set(currentNode, "data", model);
        return;
      }
      //处理文件夹
      if (dataType == "folder") {
        if (childNode.length == 0) {
          this.$refs.tree.append(model, parentId);
        } else {
          let { dataType, type } = model;
          console.log(model);
          this.$refs.tree.insertBefore(model, childNode[0].data.id);
        }
        //处理文件
      } else if (dataType == "resource") {
        if (childNode.length == 0) {
          this.update(parentId);
          this.$refs.tree.append(model, parentId);
        } else {
          let findLastIndex = this._.findLastIndex(
            childNode,
            (item) => item.data.dataType == "folder",
          );
          if (findLastIndex != -1) {
            let siblingNode = childNode[findLastIndex];
            this.$refs.tree.insertAfter(model, siblingNode);
          } else {
            this.$refs.tree.insertBefore(model, childNode[0].data.id);
          }
        }
      }
    },
    update(key) {
      console.log(key);
      let node;
      if (key == 0) {
        node = this.$refs.tree.root;
        node.loaded = false;
        node.expand();
      } else {
        node = this.$refs.tree.getNode(key);
        if (node) {
          // 请求
          // 修改的关键代码，会重新触发loadNode函数
          node.loaded = false;
          node.expand();
        }
      }
    },
  },

  mounted() {
    // this.lazy = this.lazyProps;
  },
};
</script>

<style lang="scss">
.my-work-tree {
  padding-bottom: 50px;
  width: calc(100% - 10px);

  .el-tree-node {
    line-height: normal;
  }

  .el-tree-node__content {
    height: 100%;
    line-height: 35px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
  }

  .el-tree-node__content > .el-tree-node__expand-icon {
    display: flex;
    align-items: center;
  }

  .el-tree-node__expand-icon.expanded {
    transform-origin: center;
  }

  .el-tree-node__content:hover {
    .node-button-manage {
      display: unset;
    }
  }

  .el-tree-node.is-current {
    .node-label-content:first-of-type {
      //font-weight: 700 !important;
      //font-size: 13px !important;
      //color: #348fe4 !important;
    }
  }

  .el-tree-node.is-current {
    background-color: #f0f7ff;
    border-radius: 5px;
  }

  .node-wrap {
    line-height: 36px;
    height: 36px;
    font-size: 15px;
    vertical-align: middle;
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    flex: 1;
    align-items: center;
    position: relative;

    .node-label-wrap {
      flex: 1;
      width: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .node-label-content {
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 13px;
        line-height: 20px;
        color: rgba(9, 30, 66, 0.95);
      }
      .rpa-enable {
      }
    }
  }

  .node-button-manage {
    font-style: inherit;
    display: none;

    .el-button [class*="el-icon-"] + span {
      margin-left: 0;
    }
  }

  .el-form-item__content {
    line-height: normal !important;
  }

  .el-tree-node:focus > .el-tree-node__content {
    /*background-color: #409EFF;*/
    /*margin-bottom: 1px;*/
  }

  .el-icon-caret-right:before {
    content: "\e6e0";
    /*color: #001528;*/
  }

  .el-tree-node.is-drop-inner > .el-tree-node__content .node-wrap {
    background-color: #409eff !important;
    color: #fff !important;
    border-radius: 2px;
    padding-left: 10px;
  }

  .el-tree__drop-indicator {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
  }

  .el-tree-node.is-expanded > .el-tree-node__children {
    display: contents;
  }
}
.recycle-btn {
  position: fixed;
  bottom: 0px;
  width: 248px;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid #dcdfe6;
  .el-button--text {
    color: #172b4d;
  }
  & :hover {
    opacity: 0.7;
  }
}
</style>
