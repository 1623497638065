import { Message } from "element-ui";
import SparkMD5 from "spark-md5";
/**
 *
 * @param sourceObject
 * @param prefix
 * @returns {*}
 */
export function getFormModel(sourceObject, prefix) {
  let data = {};
  Object.keys(sourceObject).map((key) => {
    data[prefix + "." + key] = sourceObject[key];
  });
  return data;
}

/**
 * @param text 需要计算长度的文本
 * @param font 使用的字体
 * @param WordSpacing 字间距
 * @returns Number
 */
export function getTextWidth(text, font, WordSpacing) {
  let canvas = null; // 防止重复创建canvas以提高性能
  return (() => {
    canvas = canvas || document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const width = context.measureText(text).width;
    return text.length > 1 ? width + (text.length - 1) * WordSpacing : width;
  })();
}

/**
 * 用spark-md5计算文件md5
 * @param file
 */
export function hashFile(file, callback) {
  var blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice,
    chunkSize = 2097152, // read in chunks of 2MB
    chunks = Math.ceil(file.size / chunkSize),
    currentChunk = 0,
    spark = new SparkMD5.ArrayBuffer(),
    frOnload = function (e) {
      spark.append(e.target.result); // append array buffer
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        callback(spark.end());
      }
    },
    frOnerror = function () {
      console.log("err");
    };

  function loadNext() {
    var fileReader = new FileReader();
    fileReader.onload = frOnload;
    fileReader.onerror = frOnerror;
    var start = currentChunk * chunkSize,
      end = start + chunkSize >= file.size ? file.size : start + chunkSize;
    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  }
  loadNext();
}

/**
 * 检查公式返回值组件类型是否匹配
 * @param {*} componentName
 * @param {*} componentNames
 * @returns
 */
export function checkRelyComponentName(componentName, componentNames) {
  if (componentName == "input_number") {
    if (
      !componentNames.every((value) =>
        ["input_number", "empty"].includes(value),
      )
    ) {
      let str = "";
      if (componentNames.length > 1) {
        str = "公式返回值可能存在非数字类型，请检查您的公式配置";
      } else {
        str = "公式返回值是非数字类型，请检查您的公式配置";
      }
      Message.error(str);

      return false;
    }
  }

  if (componentName == "date_picker") {
    if (
      !componentNames.every((value) => ["date_picker", "empty"].includes(value))
    ) {
      let str = "";
      if (componentNames.length > 1) {
        str = "公式返回值可能存在非日期类型，请检查您的公式配置";
      } else {
        str = "公式返回值是非日期类型，请检查您的公式配置";
      }
      Message.error(str);

      return false;
    }
  }

  return true;
}

/**
 * 枚举类型的对象map转换为label-value 的对象数组
 * @param {object} data
 * @returns {Array<{label:string,value:string}>}
 */
export function transferMapToList(data) {
  let list = [];
  if (!data) {
    return list;
  }
  for (const [key, value] of Object.entries(data)) {
    list.push({
      label: key,
      value: value,
    });
  }
  return list;
}
