<template>
  <el-dialog
    width="768px"
    :visible="visible"
    :append-to-body="true"
    @close="close"
    @open="open"
    class="cardDialog"
    top="48px"
  >
    <!-- <div> -->
    <div class="dia-header" slot="title">
      <!-- <div class="card-status">
        <el-checkbox
          v-model="card.status"
          @change="handleCardStatusChange"
          :true-label="20"
          :false-label="10"
        >
          {{ card.status == 10 || card.status == 0 ? "" : "已完成" }}
        </el-checkbox>
      </div> -->
      <div class="card-header">
        <i class="iconfont icon-renwuguanli"></i>任务详情
      </div>
      <div class="createdTime">
        任务创建：{{ card.creatorName }} {{ card.createdTime }}
      </div>
      <div class="card-status" v-if="isFormTpl">
        <el-checkbox
          v-model="card.privated"
          @change="handleCardPrivateChange"
          true-label="true"
          false-label="false"
        >
          {{ card.privated ? "隐私" : "公开" }}
        </el-checkbox>
      </div>
      <el-dropdown
        :hide-on-click="false"
        trigger="click"
        @visible-change="openTemplateList"
        @command="handleRelationAddClick"
      >
        <i
          class="el-icon-copy-document"
          style="cursor: pointer; margin-right: 10px"
        ></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="sheet">
            <span class="el-dropdown-link"> 添加表格 </span>
          </el-dropdown-item>
          <el-dropdown-item command="form">
            <span class="el-dropdown-link"> 添加表单 </span>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-dropdown
              :tabindex="11"
              trigger="click"
              placement="bottom-start"
              @command="handleBoardTemplateChange"
            >
              <span class="el-dropdown-link">
                添加看板<i class="el-icon-arrow-right el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                <el-dropdown-item command="0">默认看板</el-dropdown-item>
                <el-dropdown-item command="boardTemplate" divided
                  >看板模版
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-dropdown
              :tabindex="12"
              trigger="click"
              placement="bottom-start"
              @command="handlePlanTemplateChange"
            >
              <span class="el-dropdown-link">
                添加整编<i class="el-icon-arrow-right el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                <el-dropdown-item command="0" divided
                  >默认整编
                </el-dropdown-item>
                <el-dropdown-item command="planTemplate" divided
                  >整编模版
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown v-if="!notAllow">
        <i class="el-icon-more more" style="cursor: pointer"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-if="!card.templated"
            @click.native="handleCopyCard"
            >复制任务
          </el-dropdown-item>
          <!-- <el-dropdown-item @click.native="handleCardArchive"
            >归档任务
          </el-dropdown-item> -->
          <el-dropdown-item v-if="hasColumn" @click.native="showMoveColumn"
            >移动到
          </el-dropdown-item>
          <el-dropdown-item @click.native="delCard">删除 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div @paste="paste" style="padding: 0px 25px 10px">
      <el-collapse
        value="1"
        v-show="card.hasFormPlugin && card.bulletinCardPlugin"
      >
        <el-collapse-item
          :title="card.bulletinCardPlugin && card.bulletinCardPlugin.formName"
          name="1"
        >
          <FormItem ref="dynamicFormItem"></FormItem>
          <div style="width: 100%; display: flex; justify-content: flex-end">
            <el-button
              type="primary"
              size="mini"
              @click="handleSubmitDynamicForm"
              >保存
            </el-button>
          </div>
        </el-collapse-item>
      </el-collapse>

      <div
        v-if="editContent != 'title' || notAllow"
        v-html="renderHtml(card.title)"
        class="form-input form-item"
        style="
          margin-bottom: 10px;
          font-size: 20px;
          line-height: 1.2;
          white-space: pre-wrap;
          padding: 2px;
          font-weight: bolder;
        "
        @click="showEdit('title')"
      ></div>
      <el-input
        v-if="editContent == 'title' && !notAllow"
        class="form-item form-input card_item_detail-header-title"
        type="textarea"
        autosize
        ref="title"
        placeholder="请输入任务内容"
        v-model="card.title"
        autofocus
        @input="changeTitle = true"
        @blur="saveTitle('title')"
        style="font-size: 20px"
        resize="none"
      >
      </el-input>

      <div class="form-item weui-flex">
        <div class="label">
          <i class="iconfont icon-switch label-icon"></i
          ><span class="label-text">备注：</span>
        </div>
        <div
          v-show="editContent != 'remark' || notAllow"
          v-html="renderHtml(card.remark)"
          class="form-item-flex form-input remark"
          style="
            font-size: 14px;
            line-height: 1.5;
            padding: 6px 6px 6px 15px;
            cursor: text;
          "
          @click="showEdit('remark')"
        ></div>
        <el-input
          v-show="editContent == 'remark' && !notAllow"
          ref="remark"
          class="form-item-flex form-input"
          type="textarea"
          style="font-size: 14px"
          autosize
          autofocus
          placeholder="请输入备注"
          v-model="card.remark"
          @input="changeRemark = true"
          @blur="saveTitle('remark')"
          resize="none"
        >
        </el-input>
      </div>
      <!-- <div class="form-item weui-flex">
        <div class="label">
          <i class="iconfont icon-flow-interview label-icon"></i
          ><span class="label-text">责任人：</span>
        </div>
        <div class="form-item-flex">
          <el-tag
            type="success"
            style="margin-bottom: 10px; margin-right: 10px"
            v-for="(user, index) in card.cardUserList"
            :key="user.id"
            :closable="!notAllow"
            @close="deleteUser(index)"
            >{{ user.userName }}
          </el-tag>

          <el-button
            v-if="!notAllow"
            type="primary"
            size="mini"
            style="margin-right: 10px; height: 30px"
            @click="addUser"
            >添加责任人
          </el-button>
        </div>
      </div> -->
      <div class="form-item weui-flex" v-if="relationBoardList.length != 0">
        <div class="label">
          <i class="iconfont icon-yulan label-icon"></i
          ><span class="label-text">看板：</span>
        </div>
        <div style="padding-left: 15px">
          <el-table
            :data="relationBoardList"
            :show-header="false"
            size="mini"
            style="width: 100%"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  style=""
                  @click="handleRelationClick(scope.row)"
                >
                  {{ scope.row.title }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column width="50px">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  icon="el-icon-delete"
                  :underline="false"
                  style="font-size: 19px"
                  @click="handleRelationDelete(scope.row)"
                ></el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="form-item weui-flex" v-if="relationPlanList.length != 0">
        <div class="label">
          <i class="iconfont icon-yulan label-icon"></i
          ><span class="label-text">整编：</span>
        </div>
        <div style="padding-left: 15px">
          <el-table
            :data="relationPlanList"
            :show-header="false"
            size="mini"
            style="width: 100%"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  style=""
                  @click="handleRelationClick(scope.row)"
                >
                  {{ scope.row.title }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column width="50px">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  icon="el-icon-delete"
                  :underline="false"
                  style="font-size: 19px"
                  @click="handleRelationDelete(scope.row)"
                ></el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        class="form-item weui-flex"
        v-if="card.excelList && card.excelList.length != 0"
      >
        <div class="label">
          <i class="iconfont icon-yulan label-icon"></i
          ><span class="label-text">表格：</span>
        </div>
        <div style="padding-left: 15px">
          <el-table
            :data="card.excelList"
            :show-header="false"
            size="mini"
            style="width: 100%"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  style=""
                  @click="handleRelationExcelClick(scope.row)"
                >
                  {{ scope.row.title }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column width="50px">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  icon="el-icon-delete"
                  :underline="false"
                  style="font-size: 19px"
                  @click="handleRelationExcelDelete(scope.row)"
                ></el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="form-item weui-flex" v-if="formList.length != 0">
        <div class="label">
          <i class="iconfont icon-yulan label-icon"></i
          ><span class="label-text">表单：</span>
        </div>
        <div style="padding-left: 15px">
          <el-table
            :data="formList"
            :show-header="false"
            size="mini"
            style="width: 100%"
          >
            <el-table-column>
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  style=""
                  @click="handleRelationFormClick(scope.row)"
                >
                  {{ scope.row.title }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column width="50px">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  icon="el-icon-delete"
                  :underline="false"
                  style="font-size: 19px"
                  @click="handleRelationFormDelete(scope.row)"
                ></el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-row :gutter="10" v-if="card.hasSchedulePlugin">
        <el-col :span="12">
          <div class="form-item weui-flex">
            <div class="label">
              <i class="iconfont icon-flow-interview label-icon"></i
              ><span class="label-text"
                >截止日期：
                <!--              <el-link type="primary" @click="apiClearTime">清除所有</el-link>-->
              </span>
            </div>
            <div class="form-item-flex">
              <el-date-picker
                v-model="card.endTime"
                type="datetime"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                @change="apiSetTime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="form-item weui-flex">
            <div class="label">
              <i class="iconfont icon-flow-interview label-icon"></i
              ><span class="label-text">提醒设置：</span>
            </div>
            <div class="form-item-flex">
              <el-select
                v-model="card.remindMinutes"
                placeholder="请选择"
                style="width: 100%"
                @change="apiSetTime"
              >
                <el-option
                  v-for="item in remindOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="form-item weui-flex">
        <div class="label">
          <i class="iconfont icon-guanli label-icon"></i
          ><span class="label-text">标签：</span>
        </div>
        <div class="form-item-flex weui-flex content">
          <el-tag
            v-for="tag in card.tagList"
            :key="tag.id"
            :color="tag.backgroundColor"
            class="eltag card-tag"
            :closable="!notAllow"
            @click="editTag(tag)"
            @close="deleteTag(tag)"
            >{{ tag.title }}
          </el-tag>
          <el-button
            v-if="!notAllow"
            @click="toAddTag()"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            style="height: 32px"
          ></el-button>
        </div>

        <!-- <el-tag ></el-tag> -->
      </div>
      <div class="form-item weui-flex">
        <div class="label">
          <i class="iconfont icon-yulan label-icon"></i
          ><span class="label-text">封面：</span>
        </div>
        <div
          v-if="card.coverImage"
          class="image"
          @mouseenter="coverVisible = true"
          @mouseleave="coverVisible = false"
        >
          <el-image
            ref="imageCover"
            style="width: 200px"
            :src="card.coverImage"
            :preview-src-list="[card.coverImage]"
            :z-index="9999"
          >
          </el-image>
          <div class="cover-image-container" v-if="coverVisible">
            <i
              class="el-icon-view"
              @click="previewCover"
              style="font-size: 18px; color: #ffffff"
            ></i>
            <i
              class="el-icon-delete"
              @click="deleteCover"
              style="margin-left: 15px; font-size: 18px; color: #ffffff"
            ></i>
          </div>
        </div>

        <upload
          v-else-if="!notAllow"
          style="padding: 0; margin-left: 10px"
          ref="imageUpload"
          @uploadSuccess="handleUploadChange"
          :multiple="false"
          accept="image/*"
          list-type="picture-card"
          class="coverUpload"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </upload>
      </div>

      <div class="form-item">
        <div
          class="label"
          style="display: flex; flex-direction: row; line-height: 30px"
        >
          <i class="iconfont icon-fujian label-icon"></i
          ><span class="label-text">附件：</span>
          <!--          <upload-->
          <!--              v-if="!notAllow"-->
          <!--              style="padding: 0; margin-left: 10px; width: calc(100% - 100px)"-->
          <!--              ref="fileUpload"-->
          <!--              @uploadSuccess="uploadAttachment"-->
          <!--              class="coverUpload"-->
          <!--          >-->
          <!--            <el-button type="primary" size="mini">添加附件</el-button>-->
          <!--          </upload>-->
          <el-button
            type="primary"
            size="mini"
            @click="handleAddAttachment"
            v-if="!notAllow"
            >添加附件</el-button
          >
        </div>

        <attachment-list
          style="margin-top: 0"
          :attachment-list="card.attachmentList"
          :renameFun="rename"
          @updateBulletinCard="updateBulletinCard"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              icon="el-icon-paperclip"
              @click="handleAttachmentDownload(scope.row)"
              >下载
            </el-link>
            <el-link
              v-if="canPreview(scope.row.extension)"
              type="primary"
              icon="el-icon-view"
              :href="scope.row.url"
              target="_blank"
              :download="scope.row.originalName"
              >查看
            </el-link>

            <el-link
              type="danger"
              icon="el-icon-delete"
              @click="handleAttachmentDelete(scope.row)"
              v-if="!notAllow"
              >删除
            </el-link>
          </template>
        </attachment-list>
      </div>
      <el-tabs v-if="false" :value="tabActivePane" @tab-click="handleTabClick">
        <el-tab-pane name="comment">
          <div class="label" slot="label">
            <i class="iconfont icon-duihua label-icon"></i
            ><span class="label-text">评论</span>
          </div>
          <div v-if="!notAllow" class="comment-textarea">
            <el-input
              resize="none"
              class="comment-border"
              v-model="comment"
              type="textarea"
              placeholder="请输入评论"
              :autosize="{ minRows: 3 }"
              clearable
              @focus="showComment = true"
              @blur="handleCommentAreaBlur"
            ></el-input>
            <div class="comment-btn" v-if="showComment">
              <el-button
                size="mini"
                style="margin-right: 10px"
                @click="cancelComment"
                >取消
              </el-button>
              <el-button
                :loading="commentLoading"
                type="primary"
                size="mini"
                @click="saveComment"
                >确定
              </el-button>
            </div>
          </div>

          <ul class="comment-ul">
            <li class="comment-item" v-for="item in commentList" :key="item.id">
              <div class="comment-user">
                {{
                  item.fromUser.name.substring(item.fromUser.name.length - 1)
                }}
              </div>
              <div class="comment-content">
                <div class="c-user">{{ item.fromUser.name }}</div>
                <div class="c-content">{{ item.content }}</div>
                <div class="c-time">{{ item.time }}</div>
              </div>
              <div class="comment-delete">
                <span
                  v-if="item.isDel"
                  @click="deleteComment(item.id)"
                  class="cmt-del"
                  >删除</span
                >
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane
          style="padding-top: 5px; max-height: 357px; overflow: auto"
          name="logs"
        >
          <div class="label" slot="label">
            <i class="iconfont icon-wendang label-icon"></i
            ><span class="label-text">动态</span>
          </div>
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in cardLogList"
              :key="index"
              :timestamp="item.updatedTime"
            >
              {{ item.content }}
            </el-timeline-item>
          </el-timeline>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- </div> -->
    <el-dialog
      :title="tagForm.id || tagForm.groupName ? '更改标签' : '创建标签'"
      :append-to-body="true"
      :destroy-on-close="true"
      :visible.sync="tagTemplateDialogVisible"
      width="400px"
      custom-class="tagDialog"
      @close="handleTagTemplateDialogClose"
    >
      <div style="padding: 10px 20px 0px 0px; margin-bottom: 10px">
        <el-form
          :model="tagForm"
          ref="tagForm"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="名称" prop="title">
            <el-input v-model="tagForm.title" :maxlength="49"></el-input>
          </el-form-item>
          <el-form-item required prop="backgroundColor">
            <ul class="tag-list">
              <li
                @click="selectTagTemplate(tag)"
                class="tag-item"
                :style="`background-color:${tag.color}`"
                v-for="(tag, index) in tagCardColorEnums"
                :key="tag.value"
              >
                <i
                  v-if="tag.color == tagForm.backgroundColor"
                  class="el-icon-check"
                  style="color: #ffffff"
                ></i>
              </li>
            </ul>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="tagTemplateDialogVisible = false" size="small"
          >取消</el-button
        >
        <el-button
          v-if="tagForm.id || tagForm.groupName"
          type="danger"
          size="small"
          @click="deleteTagTemplate"
          >删除</el-button
        >
        <el-button @click="saveTagTemplate" type="primary" size="small">{{
          tagForm.id || tagForm.groupName ? "保存" : "创建"
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="标签"
      :append-to-body="true"
      :destroy-on-close="true"
      :visible.sync="tagEditDailogVisible"
      width="400px"
      :center="true"
      custom-class="tagDialog"
    >
      <div
        style="
          padding: 10px 20px 0px 20px;
          margin-bottom: 10px;
          max-height: 500px;
          overflow: auto;
        "
      >
        <ul class="color-bar-container">
          <draggable
            :list="tagTemplateList"
            animation="500"
            :scroll-sensitivity="200"
            drag-class="dragClass"
            ghost-class="ghostClass"
            fallback-tolerance="10"
            :scroll="true"
            @update="tagSortUpdate($event, tagTemplateList)"
          >
            <li v-for="(tag, index) in tagTemplateList" :key="tag.groupName">
              <div
                @click="toggleBindTag(tag, index)"
                class="color-bar"
                :style="`background-color:${tag.backgroundColor}`"
              >
                <div>{{ tag.title }}</div>
                <div>
                  <i
                    v-if="tag.selected"
                    class="el-icon-check"
                    style="color: #ffffff"
                  ></i>
                </div>
              </div>
              <div
                class="tag-edit"
                @click.stop="toSaveTagTemplate(tag)"
                style="cursor: pointer"
              >
                <i class="el-icon-edit-outline" style="font-size: 18px"></i>
              </div>
            </li>
          </draggable>
        </ul>
      </div>
      <span slot="footer">
        <!--        <el-button @click="cancelEdit" size="small">取消</el-button>-->
        <el-button
          @click="toSaveTagTemplate(undefined)"
          type="info"
          class="full-width-btn"
          >新建标签</el-button
        >
      </span>
    </el-dialog>
    <!--    <el-dialog-->
    <!--      title="编辑标签"-->
    <!--      :append-to-body="true"-->
    <!--      :destroy-on-close="true"-->
    <!--      :visible.sync="editVisible"-->
    <!--      width="600px"-->
    <!--      custom-class="tagDilog"-->
    <!--    >-->
    <!--      <div style="padding: 10px 20px 0px 0px">-->
    <!--        <el-form-->
    <!--          :model="tagForm"-->
    <!--          ref="tagForm"-->
    <!--          :rules="rules"-->
    <!--          label-width="80px"-->
    <!--          :inline="false"-->
    <!--          size="normal"-->
    <!--        >-->
    <!--          <el-form-item label="名称" prop="label">-->
    <!--            <el-input v-model="tagForm.label"></el-input>-->
    <!--          </el-form-item>-->
    <!--          <el-form-item>-->
    <!--            <ul class="tag-list">-->
    <!--              <li-->
    <!--                @click="choseTag(tag)"-->
    <!--                class="tag-item"-->
    <!--                :style="`background-color:${tag.value}`"-->
    <!--                v-for="tag in tagList"-->
    <!--                :key="tag.value"-->
    <!--              >-->
    <!--                <i-->
    <!--                  v-if="tag.value == tagForm.value"-->
    <!--                  class="el-icon-check"-->
    <!--                  style="color: #ffffff"-->
    <!--                ></i>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </el-form-item>-->
    <!--        </el-form>-->
    <!--      </div>-->
    <!--      <span slot="footer">-->
    <!--        <el-button @click="cancelEdit" size="small">取消</el-button>-->
    <!--        <el-button @click="saveTag" type="primary" size="small">确定</el-button>-->
    <!--      </span>-->
    <!--    </el-dialog>-->
    <addAttachment
      :dialogAddFileVisibleOuter.sync="dialogAddFileVisibleOuter"
      @addDone="handleAddDone"
    ></addAttachment>
    <addAssociateModule
      ref="addAssociateModule"
      :bulletinCardId="card.id"
      @update="updateBulletinCard"
    ></addAssociateModule>

    <el-dialog
      :fullscreen="true"
      :title="relationDialogTitle"
      append-to-body
      :visible.sync="relationDialogVisible"
    >
      <iframe
        :src="relationIframeSrc"
        frameborder="0"
        width="100%"
        style="height: calc(100vh - 50px)"
      ></iframe>
    </el-dialog>
    <el-dialog
      title="已有整编"
      append-to-body
      :visible.sync="addExistPlanDialogVisible"
      width="500px"
    >
      <div class="app-container">
        <el-table
          :data="myPlanList"
          style="width: 100%"
          max-height="500px"
          size="mini"
        >
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleRelationMyExistPlan(scope)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="已有看板"
      append-to-body
      :visible.sync="addExistBulletinDialogVisible"
      width="500px"
    >
      <div class="app-container">
        <el-table
          :data="myBulletinList"
          style="width: 100%"
          max-height="500px"
          size="mini"
        >
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleRelationMyExistBulletin(scope)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="表格"
      append-to-body
      :visible.sync="addRelationSheetDialogVisible"
      width="600px"
      @close="apiGetBulletinCardListExcel"
    >
      <div class="app-container" style="height: 600px">
        <el-scrollbar style="height: 100%" :wrap-class="'tree-scrollbar__wrap'">
          <el-tree
            :highlight-current="true"
            ref="tree"
            class="platform-excel-tree"
            node-key="id"
            :props="defaultProps"
            :load="loadNode"
            lazy
            :indent="10"
            :expand-on-click-node="true"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="node-label" :title="data.remark">
                <i class="el-icon-folder" v-if="data.type === 10"></i>
                <i class="el-icon-document" v-if="data.type === 20"></i>
                <span>{{ data.name }}</span>
                <span class="node-button-manage">
                  <el-link
                    type="primary"
                    size="mini"
                    :underline="false"
                    icon="el-icon-plus"
                    v-if="data.type === 20"
                    @click.stop="handleAddExcelToCard(node)"
                  >
                    添加
                  </el-link>
                </span>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
    </el-dialog>
    <el-dialog
      :title="previewExcelTitle"
      :fullscreen="true"
      :close-on-click-modal="false"
      append-to-body
      :visible.sync="excelPreviewDialogVisible"
      @close="handleExcelDestroy"
    >
      <div style="height: calc(100vh - 50px - 70px)">
        <excel-render ref="excelRender" />
      </div>
      <div slot="footer">
        <el-button-group style="margin-bottom: 5px">
          <el-button type="primary" @click="handleExcelSave()">
            保存
          </el-button>
          <el-button type="warning" @click="handleExcelClose()">
            关闭
          </el-button>
        </el-button-group>
      </div>
    </el-dialog>
    <el-dialog
      title="模版"
      append-to-body
      :visible.sync="addRelationDialogVisible"
      width="600px"
    >
      <div
        class="app-container"
        style="height: 600px"
        v-if="addRelationDialogVisible"
      >
        <el-scrollbar style="height: 100%" :wrap-class="'tree-scrollbar__wrap'">
          <el-tree
            :highlight-current="true"
            ref="tree"
            class="platform-excel-tree"
            node-key="id"
            :props="defaultProps"
            :load="loadNode"
            lazy
            :indent="10"
            :expand-on-click-node="true"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="node-label" :title="data.remark">
                <i class="el-icon-folder" v-if="data.type === 10"></i>
                <i class="el-icon-document" v-if="data.type === 20"></i>
                <span>{{ data.name }}</span>
                <span class="node-button-manage">
                  <el-link
                    :type="node.isAdded ? 'info' : 'primary'"
                    size="mini"
                    :underline="false"
                    icon="el-icon-plus"
                    v-if="templateParentCode == '1' && data.type === 20"
                    @click.stop="handleAddExcelToCard(node)"
                  >
                    {{ node.isAdded ? "本次已添加" : "添加表格" }}
                  </el-link>
                  <el-link
                    type="primary"
                    size="mini"
                    :underline="false"
                    icon="el-icon-plus"
                    v-if="templateParentCode == 'form' && data.type === 20"
                    @click.stop="handleFormTemplateChange(node)"
                  >
                    添加表单
                  </el-link>
                  <el-link
                    type="primary"
                    size="mini"
                    :underline="false"
                    icon="el-icon-plus"
                    v-if="templateParentCode == 'kanban' && data.type === 20"
                    @click.stop="handleAddKanBanTemplate(node)"
                  >
                    添加看板
                  </el-link>
                  <el-link
                    type="primary"
                    size="mini"
                    :underline="false"
                    icon="el-icon-plus"
                    v-if="templateParentCode == 'plan'"
                    @click.stop="handleAddPlanTemplate(node)"
                  >
                    添加整编
                  </el-link>
                </span>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="formVisible"
      width="600px"
      :modal="false"
    >
      <div style="padding: 10px">
        <el-form ref="addForm" :model="addForm" label-width="120px">
          <el-form-item label="表单名称">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="是否内嵌展示">
            <el-switch v-model="addForm.displayInCard"></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" :loading="addFormLoading" @click="saveAddForm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {
  addCardAttachment,
  bulletinCardDeleteAttachment,
  bulletinCardDetail,
  bulletinCardSave,
  bulletinCardUserDelete,
  bulletinCardUserSaveBatch,
  deleteBulletinTag,
  deleteComment,
  fetchBulletinCardLogs,
  fetchCommentList,
  saveBulletinTag,
  saveComment,
  setCardCoverImage,
  setCoverImage,
  renameAttachment,
  bulletinCardSetTime,
  bulletinCardClearTime,
  bulletinCardSwitchStatus,
  bulletinCardRelationDelete,
  bulletinCardRelationRegister,
  bulletinPage,
  bulletinCardAddExcel,
  bulletinCardListExcel,
  bulletinCardDeleteExcel,
  bulletinCardListForm,
  bulletinCardDeleteForm,
  bulletinCardAddForm,
} from "@/api/boardManage";
import { mapGetters } from "vuex";
import { deepClone } from "@/utils";
import Upload from "@/components/Upload/indexNew";
import moment from "moment";
import AttachmentList from "@/components/AttachmentList2";
import { downFile } from "@/utils/ali-oss";
import { getFileExtension } from "@/utils/enums";
import FormItem from "./FormItem";
import { upload as uploadFile } from "@/utils/upload";
import addAttachment from "./addAttachment";
import { excelTemplateFullTree, excelTemplateTree } from "@/api/excelTemplate";
import addAssociateModule from "./addAssociateModule";
import { planListPage } from "@/api/plan";
import {
  createByExcelRepository,
  excelSaveContent,
} from "@/api/excelRepository";
import axios from "axios";
import ExcelRender from "./excelRender";
import {
  bulletinCardSwitchPrivate,
  bulletinTagBind,
  bulletinTagDefaultColor,
  bulletinTagListTemplate,
  bulletinTagRegister,
  bulletinTagSort,
  bulletinTagUnbind,
} from "@/api/board";
import draggable from "vuedraggable";
export default {
  components: {
    Upload,
    AttachmentList,
    FormItem,
    addAttachment,
    addAssociateModule,
    ExcelRender,
    draggable,
  },
  computed: {
    ...mapGetters(["user", "bulletinTagList"]),
    disabled() {
      return this.user.id != this.card.creatorId;
    },
    notAllow() {
      return this.card.archived;
    },

    relationPlanList() {
      if (this.card && this.card.bulletinCardRelationList) {
        return this.card.bulletinCardRelationList.filter(
          (item) => item.recordType == "plan",
        );
      } else {
        return [];
      }
    },
    relationBoardList() {
      if (this.card && this.card.bulletinCardRelationList) {
        return this.card.bulletinCardRelationList.filter(
          (item) => item.recordType == "bulletin",
        );
      } else {
        return [];
      }
    },
    formList() {
      if (this.card.formList) {
        // return this.card.formList.filter(
        //   (item) => !(item.param && item.param.displayInCard == true)
        // );
        return this.card.formList;
      }
      return [];
    },
  },
  props: {
    visible: Boolean,
    cardId: [String, Number],
    hasColumn: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isFormTpl: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    bulletinTagList(val) {
      if (!val) {
        return;
      }
      this.tagList &&
        this.tagList.map((item) => {
          let index = val.findIndex((i) => i.groupName == item.groupName);
          if (index != -1) {
            let tag = val[index];
            item.title = tag.title;
            return item;
          }
        });
    },
  },
  data() {
    return {
      addForm: {
        id: "",
        formId: "",
        title: "",
        displayInCard: false,
      },
      formVisible: false,
      addFormLoading: false,
      addRelationDialogVisible: false,
      templateParentCode: "kanban",
      /**
       * 模版树枚举
       */
      parentCodeEnums: {
        excel: "1",
        bulletin: "kanban",
        plan: "plan",
        form: "form",
      },
      editContent: "",
      changeTitle: false,
      changeRemark: false,
      coverVisible: false,
      commentLoading: false,
      showComment: false,
      comment: "",
      commentList: [],
      editVisible: false,
      tagVisible: false,
      tagForm: {},
      rules: {
        label: [{ required: true, message: "请输入名称" }],
      },
      tagList: [
        {
          label: "紧急",
          value: "#DC143C",
        },
        {
          label: "重要",
          value: "#f2d600",
        },
      ],
      card: {},
      membersSelectDialogVisible: false,
      /**
       * 卡片动态列表
       */
      cardLogList: [],
      formInstanceId: 0,
      showDynamicFormSave: false,
      /**
       *
       0 表示准时提醒 (在到期时间)
       -1 表示不提醒(无)
       5 表示 提前5分钟
       60 表示提前 1小时
       1440 表示提前1天
       2880 表示提前2天
       */
      remindOptions: [
        {
          id: -1,
          title: "无",
        },
        {
          id: 0,
          title: "准时",
        },
        {
          id: 5,
          title: "提前五分钟",
        },
        {
          id: 60,
          title: "提前1小时",
        },
        {
          id: 1440,
          title: "提前1天",
        },
        {
          id: 2880,
          title: "提前2天",
        },
      ],
      tabActivePane: "comment",
      dialogAddFileVisibleOuter: false,
      boardTemplateList: [],
      planTemplateList: [],
      formTemplateList: [],
      relationDialogVisible: false,
      relationIframeSrc: "",
      relationDialogTitle: "提示",
      //添加已存在整编的弹窗
      addExistPlanDialogVisible: false,
      myPlanList: [],
      //添加已存在看板的弹窗
      addExistBulletinDialogVisible: false,
      myBulletinList: [],
      sheetTemplateTreeData: [],
      addRelationSheetDialogVisible: false,
      defaultProps: {
        label: "name",
        children: "children",
        isLeaf: function (data, node) {
          return !data.hasChild;
        },
      },
      bulletinListExcel: [],
      bulletinListForm: [],
      excelPreviewDialogVisible: false,
      currentExcel: {},
      previewExcelTitle: "",
      tagEditDailogVisible: false,
      tagTemplateList: [],
      tagTemplateDialogVisible: false,
      tagCardColorEnums: [],
    };
  },
  methods: {
    handleAddKanBanTemplate(node) {
      // let tpl = this.boardTemplateList.find((item) => item.id == v);
      let tpl = node.data;
      this.currentBoardTemplate = tpl;
      this.$refs.addAssociateModule.initByBoardTemplate(tpl);
    },
    handleAddPlanTemplate(node) {
      // let tpl = this.boardTemplateList.find((item) => item.id == v);
      let tpl = node.data;
      this.currentPlanTemplate = tpl;
      this.$refs.addAssociateModule.initByPlanTemplate(tpl);
    },
    handleCardPrivateChange(val) {
      bulletinCardSwitchPrivate({
        id: this.card.id,
        privated: val,
      }).then((resp) => {
        this.fetchDetail((model) => {
          this.$emit("updateCard", model);
        });
        this.$emit("updatePrivate");
      });
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        let list = await this.fetchTemplateNodeData({
          parentCode: this.templateParentCode,
        });
        return resolve(list);
      }
      if (node.level > 0) {
        let list = await this.fetchTemplateNodeData({
          parentId: node.data.id,
        });
        return resolve(list);
      }
    },
    /**
     * 获取模版结点树
     */
    fetchTemplateNodeData(params) {
      return excelTemplateTree(params).then(({ data }) => {
        return data.list;
      });
    },
    delCard() {
      this.$emit("delCard");
    },
    /**
     * 处理表格关闭的事件回调
     * 1、让表格渲染器推出编辑模式，否则会出现填写框残留
     * 2、调用表格渲染器的销毁方法
     * 3、将当前容器中的表格视图模式调整为false
     */
    handleExcelClose() {
      this.$refs.excelRender.exitEditMode();
      this.$refs.excelRender.handleDestroy();
      this.excelPreviewDialogVisible = false;
      // this.excelViewMode = false
    },
    //处理表格保存
    handleExcelSave() {
      let content = this.$refs.excelRender.getExcelData();
      let params = {
        content: JSON.stringify(content),
        id: this.currentExcel.id,
        cellProp: [],
      };
      excelSaveContent(params).then((resp) => {
        this.$message.success("保存成功");
        this.excelPreviewDialogVisible = false;
        this.apiGetBulletinCardListExcel();
      });
    },
    handleExcelDestroy() {
      this.$refs.excelRender.handleDestroy();
    },
    handleRelationFormClick(row) {
      this.relationDialogTitle = row.title;
      const { href } = this.$router.resolve({
        path: `/redirect/online/${row.formId}/edit`,
        query: {
          id: row.documentId,
          routeNotBack: true,
        },
      });
      // window.open(href, "_blank")
      console.log(href);
      this.relationIframeSrc = href;
      this.relationDialogVisible = true;
    },
    handleRelationFormDelete(row) {
      let param = {
        id: this.cardId,
        documentId: row.documentId,
      };
      bulletinCardDeleteForm(param).then((resp) => {
        this.$message.success("删除成功");
        // this.apiGetBulletinCardListForm();
        this.updateBulletinCard();
      });
    },
    apiGetBulletinCardListForm() {
      let param = {
        id: this.cardId,
      };
      this.updateBulletinCard();
      // bulletinCardListForm(param).then((resp) => {
      //   this.bulletinListForm = resp.data.formList;
      // });
    },
    handleRelationExcelClick(row) {
      this.currentExcel = row;
      this.previewExcelTitle = row.title;
      axios
        .get(row.content)
        .then((resp) => {
          // this.$refs["excelRender"].handleReadExcel(resp.data)
          this.excelPreviewDialogVisible = true;
          this.$nextTick(() => {
            this.$refs["excelRender"].handleReadExcel(resp.data);
          });
        })
        .catch((err) => {});
    },
    /**
     * 关联表格删除
     */
    handleRelationExcelDelete(row) {
      let param = {
        id: this.cardId,
        excelId: row.excelId,
      };
      bulletinCardDeleteExcel(param).then((resp) => {
        this.$message.success("删除成功");
        this.updateBulletinCard();
        // this.apiGetBulletinCardListExcel();
      });
    },
    apiGetBulletinCardListExcel() {
      let param = {
        id: this.cardId,
      };
      bulletinCardListExcel(param).then((resp) => {
        this.card.excelList = resp.data.excelList;
      });
    },
    /*
      添加表格到卡片
    **/
    handleAddExcelToCard(node) {
      let title = node.data.name;
      let resId = node.data.extAttr.resId;
      createByExcelRepository(resId).then((resp) => {
        let excelId = resp.data.excel.id;

        if (excelId) {
          let param = {
            id: this.cardId,
            excelId,
            title,
          };
          bulletinCardAddExcel(param).then((resp) => {
            this.$message.success("添加成功");
            this.updateBulletinCard();
            this.$set(node, "isAdded", true);
          });
        }
      });
    },
    apiGetExcelTemplateList() {
      excelTemplateFullTree({ parentCode: 1 }).then((resp) => {
        this.sheetTemplateTreeData = resp.data.list;
      });
    },
    handleRelationAddClick(command) {
      if (command && command == "sheet") {
        this.templateParentCode = this.parentCodeEnums.excel;
        this.addRelationDialogVisible = true;
      }
      if (command && command == "form") {
        this.templateParentCode = this.parentCodeEnums.form;
        this.addRelationDialogVisible = true;
      }
      if (command && command == "approval") {
        this.addApprovalVisible = true;
      }
      if (command && command == "video") {
        this.videoFormDialogType = "add";
        this.addVideoDialogVisible = true;
      }
    },
    openTemplateList(val) {
      if (val) {
        this.apiGetBoardTemplate();
        this.apiGetPlanTemplate();
        this.apiGetFormTemplate();
      }
    },
    handleRelationMyExistPlan({ row }) {
      this.$prompt("请输入整编名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: "邮箱格式不正确",
        inputValue: row.title,
      })
        .then(({ value }) => {
          let params = {
            recordType: "plan",
            recordId: row.id,
            bulletinCardId: this.card.id,
            title: value,
          };
          bulletinCardRelationRegister(params).then((resp) => {
            // this.$emit("update")
            this.updateBulletinCard();
          });
        })
        .catch(() => {});
    },
    handleRelationMyExistBulletin({ row }) {
      this.$prompt("请输入看板名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: "邮箱格式不正确",
        inputValue: row.title,
      })
        .then(({ value }) => {
          let params = {
            recordType: "bulletin",
            recordId: row.id,
            bulletinCardId: this.card.id,
            title: value,
          };
          bulletinCardRelationRegister(params).then((resp) => {
            this.updateBulletinCard();
          });
        })
        .catch(() => {});
    },
    //获取我参与的整编列表接口
    apiGetPlanListPage() {
      planListPage({
        pageSize: 9999,
      }).then((resp) => {
        this.myPlanList = resp.data.planPage.list;
      });
    },
    //获取我参与的整编列表接口
    apiGetBulletinListPage() {
      bulletinPage({
        pageSize: 9999,
      }).then((resp) => {
        this.myBulletinList = resp.data.page.list;
      });
    },
    /**
     *处理关联点击
     */
    handleRelationClick(row) {
      console.log(row);
      this.relationDialogTitle = row.title;
      let { recordId, recordType } = row;
      if (recordType == "bulletin") {
        const { href } = this.$router.resolve({
          path: "/redirect/board",
          query: {
            bulletinId: recordId,
          },
        });
        // window.open(href, "_blank")
        this.relationIframeSrc = href;
        this.relationDialogVisible = true;
      }
      if (recordType == "plan") {
        const { href } = this.$router.resolve({
          path: "/redirect/plan",
          query: {
            planId: recordId,
            type: "view",
          },
        });
        // window.open(href, "_blank")
        this.relationIframeSrc = href;
        this.relationDialogVisible = true;
      }
    },
    /**
     * 处理关联删除
     *
     */
    handleRelationDelete(row) {
      this.$confirm("确认解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          bulletinCardRelationDelete({ id: row.id }).then((resp) => {
            this.updateBulletinCard();
          });
        })
        .catch(() => {});
    },
    handleFormTemplateChange(node) {
      let tpl = node.data;
      this.addForm.title = tpl.name;
      this.addForm.displayInCard = false;
      this.addForm.id = this.cardId;
      this.addForm.formId = tpl.extAttr.resId;
      this.formVisible = true;
      this.addRelationDialogVisible = false;

      // let tpl = node.data;
      // this.$prompt("请输入表单名称", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputValue: tpl.name,
      // })
      //   .then(({ value }) => {
      //     let param = {
      //       id: this.cardId,
      //       formId: tpl.extAttr.resId,
      //       title: value,
      //     };
      //     bulletinCardAddForm(param).then((resp) => {
      //       this.$message.success("添加成功");
      //       this.updateBulletinCard();
      //     });
      //   })
      //   .catch(() => {});
    },
    saveAddForm() {
      if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      }
      this.addFormLoading = true;
      bulletinCardAddForm(this.addForm)
        .then((resp) => {
          this.addFormLoading = false;
          this.$message.success("添加成功");
          this.apiGetBulletinCardListForm();
          this.formVisible = false;
        })
        .catch(() => {
          this.addFormLoading = false;
        });
    },
    handleBoardTemplateChange(v) {
      if (v < 0) {
        this.apiGetBulletinListPage();
        this.addExistBulletinDialogVisible = true;
      }
      if (v == 0) {
        let tpl = this.boardTemplateList.find((item) => item.id == v);
        this.currentBoardTemplate = tpl;
        this.$refs.addAssociateModule.initByBoardTemplate(tpl);
      }
      if (v == "boardTemplate") {
        this.templateParentCode = this.parentCodeEnums.bulletin;
        this.addRelationDialogVisible = true;
      }
    },
    handlePlanTemplateChange(v) {
      if (v < 0) {
        this.apiGetPlanListPage();
        this.addExistPlanDialogVisible = true;
      }
      if (v == 0) {
        let tpl = this.planTemplateList.find((item) => item.id == v);
        this.currentPlanTemplate = tpl;
        this.$refs.addAssociateModule.initByPlanTemplate(tpl);
      }
      if (v == "planTemplate") {
        this.templateParentCode = this.parentCodeEnums.plan;
        this.addRelationDialogVisible = true;
      }
    },
    apiGetBoardTemplate() {
      excelTemplateTree({ parentCode: "kanban" }).then((res) => {
        this.boardTemplateList = res.data.list;
      });
    },
    apiGetPlanTemplate() {
      excelTemplateTree({ parentCode: "plan" }).then((res) => {
        this.planTemplateList = res.data.list;
      });
    },
    apiGetFormTemplate() {
      excelTemplateTree({ parentCode: "form" }).then((res) => {
        this.formTemplateList = res.data.list;
        console.log(this.formTemplateList);
      });
    },
    /**
     * 处理卡片状态改变
     */
    handleCardStatusChange(val) {
      console.log(val);

      bulletinCardSwitchStatus({
        id: this.card.id,
        status: val,
      }).then((resp) => {
        this.fetchDetail((model) => {
          this.$emit("updateCard", model);
        });
      });
    },
    handleAddDone(attachment) {
      this.uploadAttachment(attachment);
    },
    handleAddAttachment() {
      this.dialogAddFileVisibleOuter = true;
    },

    /**
     * 处理tab选中时触发请求
     */
    handleTabClick(e) {
      let { name } = e;
      if (name === "logs") {
        this.loadingCardLogList();
      } else if (name === "comment") {
        this.loadingCommentList();
      }
    },
    paste(e) {
      if (!(e.clipboardData && e.clipboardData.items)) {
        alert("该浏览器不支持操作");
        return;
      }

      for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
        let item = e.clipboardData.items[i];
        if (item.kind === "file" && item.type.indexOf("image") >= 0) {
          let pasteFile = item.getAsFile();
          uploadFile({ file: pasteFile }, (res) => {
            if (!this.card.coverImage) {
              // 没有封面当封面
              this.handleUploadChange(res);
            } else {
              // 有封面当附件
              this.uploadAttachment(res);
            }
          });
        }
        // console.log(item);
      }
    },
    /**
     * 通过api清除看板卡片的时间
     */
    apiClearTime() {
      bulletinCardClearTime({
        id: this.card.id,
      }).then((resp) => {});
    },
    /**
     * 通过api单独设置时间
     */
    apiSetTime(val) {
      if (val) {
        bulletinCardSetTime({
          id: this.card.id,
          endTime: this.card.endTime,
          remindMinutes: this.card.remindMinutes,
        }).then((resp) => {});
      } else {
        this.apiClearTime();
      }
    },
    /**
     * 处理动态表单的保存
     */
    handleSubmitDynamicForm() {
      this.$refs.dynamicFormItem.submitForm().then((resp) => {
        this.$message.success("保存成功");
      });
    },
    rename(data) {
      console.log(data);
      return new Promise((resolve, reject) => {
        renameAttachment({ ...data, id: this.card.id }).then((res) => {
          resolve(res);
        });
      });
    },
    showMoveColumn() {
      this.$emit("showMoveColumn");
    },
    showEdit(type) {
      if (this.notAllow) {
        return;
      }
      this.editContent = type;
      this.$nextTick(() => {
        this.$refs[type].focus();
      });
    },
    renderHtml(val) {
      if (!val) {
        return "请输入备注";
      }

      let regHtml = /<[^>]+>/g;
      if (regHtml.test(val)) {
        return val;
      }
      let textR = val;
      let reg =
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      let imgSRC = $(".thread_mess img").attr("src");
      if (reg.exec(imgSRC)) {
      } else {
        textR = textR.replace(reg, function (val) {
          return `<a href='${val}' target='_blank' style='color:#409eff;'>${val}</a>`;
        });
      }
      return textR;
    },
    uploadAttachment(attachment) {
      addCardAttachment({
        id: this.cardId,
        attachmentId: attachment.attachmentId || attachment.id,
      }).then((res) => {
        // this.$refs.fileUpload.clearFiles();
        this.fetchDetail((model) => {
          this.$emit("updateCard", model);
        });
      });
    },
    handleAttachmentDelete(att) {
      this.$confirm(`您确认要删除附件:【${att.originalName}】吗？`, "提示", {
        type: "warning",
      }).then(() => {
        bulletinCardDeleteAttachment({
          id: this.card.id,
          attachmentId: att.id,
        }).then((res) => {
          this.$message.success("删除成功！");
          this.fetchDetail((model) => {
            this.$emit("updateCard", model);
          });
        });
      });
    },
    handleAttachmentDownload(att) {
      downFile(att);
    },
    canPreview(extension) {
      return getFileExtension(extension) ? true : false;
    },
    cancelComment() {
      this.comment = "";
      this.showComment = false;
    },
    /**
     * 处理评论看blur的逻辑
     * 如果评论为空，自动退出编辑模式
     */
    handleCommentAreaBlur() {
      if (this.comment == "") {
        this.showComment = false;
      }
    },
    saveComment() {
      if (!this.comment) {
        this.$message.warning("请输入评论内容");
        return;
      }
      this.commentLoading = true;
      saveComment({
        topicId: this.cardId,
        contentType: 10,
        content: this.comment,
      })
        .then((res) => {
          this.commentLoading = false;
          this.comment = "";
          this.showComment = false;
          this.loadingCommentList();
          this.fetchDetail((model) => {
            this.$emit("updateCard", model);
          });
        })
        .catch(() => {
          this.commentLoading = false;
        });
    },
    updateBulletinCard() {
      this.fetchDetail((model) => {
        this.$emit("updateCard", model);
      });
    },
    deleteComment(id) {
      this.$confirm("确定要删除这条评论吗?", "提示")
        .then((res) => {
          deleteComment(id).then((res) => {
            this.loadingCommentList();
          });
        })
        .catch(() => {});
    },
    handleUploadChange(file) {
      setCardCoverImage({
        id: this.cardId,
        imageUrl: file.url,
      }).then((res) => {
        this.$set(this.card, "coverImage", file.url);
        this.$emit("updateCard", this.card);
      });
      // console.log(fileList);
      // this.uploadFileList = fileList.map(item => item.response)
    },
    editTag(tag) {
      console.log(this.notAllow);
      if (this.notAllow) {
        return;
      }
      this.$set(this, "tagForm", {
        id: tag.id,
        label: tag.title,
        value: tag.backgroundColor,
      });
      this.tagEditDailogVisible = true;
    },
    deleteTag(tag) {
      if (this.notAllow) {
        return;
      }
      this.$confirm("确定要删除标签吗?", "提示")
        .then((res) => {
          let params = {
            bulletinCardId: this.cardId,
            id: tag.id,
            groupName: tag.groupName,
          };
          bulletinTagUnbind(params).then((res) => {
            this.$message.success("成功删除标签");
            this.fetchDetail((model) => {
              this.$emit("updateCard", model);
            });
          });
        })
        .catch(() => {});
    },
    cancelEdit() {
      this.editVisible = false;
      this.tagForm = {};
    },
    saveTag() {
      this.$refs.tagForm.validate((valid) => {
        if (!valid) {
          return;
        }
        saveBulletinTag({
          title: this.tagForm.label,
          backgroundColor: this.tagForm.value,
          bulletinCardId: this.cardId,
          id: this.tagForm.id,
        })
          .then(() => {
            this.$message.success("标签保存成功");
            this.editVisible = false;
            this.fetchDetail((model) => {
              this.$emit("updateCard", model);
            });
          })
          .catch((error) => {});
      });
    },
    choseTag(tag) {
      this.editVisible = true;
      this.tagForm = deepClone(tag);
    },

    onMembersSelectDialogClose() {},
    addUser() {
      this.membersSelectDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.membersSelectDialog.init(this.card);
      });
    },
    //提交选择成员
    membersAddConfirm(selectMember, card) {
      //要把已经在列表的人员删除，不然后端会重复保存
      let userId = selectMember
        .filter(
          (item) =>
            card.cardUserList.findIndex((u) => u.userId == item.userId) == -1,
        )
        .map((u) => u.userId)
        .toString();

      // this.$set(this.taskDetailForm,"members",members)
      bulletinCardUserSaveBatch({
        bulletinCardId: card.id,
        userId: userId,
      }).then((res) => {
        this.membersSelectDialogVisible = false;
        this.fetchDetail((model) => {
          this.$emit("updateCard", model);
        });
      });
    },
    deleteUser(index) {
      let user = this.card.cardUserList[index];
      bulletinCardUserDelete({
        id: user.id,
        bulletinColumnId: this.card.bulletinColumnId,
      }).then((res) => {
        this.$message.success(`${user.userName}成功移除`);
        this.card.cardUserList.splice(index, 1);
        this.$nextTick(() => {
          this.$emit("updateCard", this.card);
        });
      });
    },
    saveTitle(type) {
      if (!this.changeTitle && !this.changeRemark) {
        this.editContent = "";
        return;
      }
      if (this.card.title == "") {
        this.$message.error("任务名称不能为空!");
        return;
      }
      bulletinCardSave({
        model: JSON.stringify({
          id: this.card.id,
          title: this.card.title,
          bulletinId: this.card.bulletinId,
          remark: this.card.remark,
          bulletinColumnId: this.card.bulletinColumnId,
        }),
      }).then((res) => {
        this.changeTitle = false;
        this.changeRemark = false;
        this.editContent = "";
        this.$message.success(
          type == "remark" ? "任务描述修改成功" : "任务名称修改成功",
        );
        this.$emit("updateCard", this.card);
      });
    },

    handleCopyCard() {
      this.$emit("copy");
    },
    handleCardArchive() {
      this.$emit("archive");
    },

    close() {
      this.$emit("update:visible", false);
      if (this.$refs.imageUpload) {
        this.$refs.imageUpload.clearFiles();
      }
    },
    fetchDetail(callback) {
      bulletinCardDetail({ id: this.cardId })
        .then((res) => {
          if (res.data.model.tagList) {
            this.tagList = res.data.model.tagList;
          }
          this.$set(this, "card", res.data.model);
          if (this.card.bulletinCardPlugin) {
            this.$refs.dynamicFormItem &&
              this.$refs.dynamicFormItem.initByFormInstance(
                this.card.bulletinCardPlugin.formInstanceId,
              );
          }

          if (typeof callback === "function") {
            callback(res.data.model);
          }
        })
        .catch((err) => {
          this.$emit("update:visible", false);
        });
    },
    loadingCommentList() {
      fetchCommentList({
        pageSize: 999,
        pageNumber: 1,
        topicId: this.cardId,
      }).then((res) => {
        let now = moment();
        res.data.page.list.forEach((item) => {
          item.fromUser = JSON.parse(item.fromUser);
          let time = moment(item.createdTime);
          item.time = time.format("YYYY-MM-DD HH:mm:ss");
          let diff = now.diff(time);
          let min = moment.duration(diff).asMinutes();
          item.isDel = min <= 2 && item.createrId == this.currentUser.id;
        });

        this.commentList = res.data.page.list;
        this.$nextTick(() => {
          if (this.commentList.filter((item) => item.isDel)) {
            this.interval = setInterval(() => {
              now = moment();
              this.commentList.forEach((item) => {
                if (item.isDel) {
                  let time = moment(item.createdTime);
                  let diff = now.diff(time);
                  let min = moment.duration(diff).asMinutes();
                  item.isDel =
                    min <= 2 && item.createrId == this.currentUser.id;
                }
              });
              if (this.commentList.filter((item) => item.isDel).length == 0) {
                if (this.interval) {
                  clearInterval(this.interval);
                }
              }
            }, 1000);
          } else {
            if (this.interval) {
              clearInterval(this.interval);
            }
          }
        });
      });
    },
    mouseenter() {},
    mouseleave() {},
    previewCover() {
      this.$refs.imageCover.showViewer = true;
    },
    deleteCover() {
      this.$confirm("确定要删除封面图片吗?", "提示")
        .then((res) => {
          setCardCoverImage({
            id: this.cardId,
            imageUrl: "",
          }).then((res) => {
            this.$set(this.card, "coverImage", "");
            this.$emit("updateCard", this.card);
          });
        })
        .catch(() => {});
    },
    /**
     * 获取卡片动态列表
     */
    loadingCardLogList() {
      fetchBulletinCardLogs({
        pageSize: 999,
        pageNumber: 1,
        bulletinCardId: this.cardId,
      }).then((resp) => {
        this.cardLogList = resp.data.page.list;
      });
    },
    open() {
      this.fetchDetail();
      // this.loadingCommentList();
      this.tabActivePane = "comment";
      // this.loadingCardLogList();
      this.apiGetBulletinCardListExcel();
      this.apiGetBulletinCardListForm();
    },
    //打开标签弹窗(获取看板标签模板列表)
    toAddTag() {
      this.apiGetTagTempList().then(() => {
        this.tagEditDailogVisible = true;
      });
    },
    apiGetTagTempList() {
      return bulletinTagListTemplate({ bulletinCardId: this.cardId }).then(
        ({ data }) => {
          console.log("apiGetTagTempList");
          this.tagTemplateList = data.modelList;
          this.apiGetBoarTagTempList();
        },
      );
    },
    apiGetBoarTagTempList() {
      return bulletinTagListTemplate({ bulletinId: this.card.bulletinId }).then(
        ({ data }) => {
          console.log("apiGetBoarTagTempList");
          // this.changeBulletinTag(data.modelList);
        },
      );
    },
    //打开添加标签弹窗
    toSaveTagTemplate(tag) {
      bulletinTagDefaultColor().then(({ data }) => {
        this.tagCardColorEnums = data.colorList;
        this.tagTemplateDialogVisible = true;
        this.$nextTick(() => {
          if (tag) {
            this.tagForm = deepClone(tag);
          } else {
            this.tagForm.backgroundColor = this.tagCardColorEnums[0].color;
          }
        });
      });
    },
    saveTagTemplate() {
      this.$refs.tagForm.validate((valid) => {
        if (!valid) {
          return;
        }
        let params = {
          bulletinId: this.card.bulletinId,
          title: this.tagForm.title,
          backgroundColor: this.tagForm.backgroundColor,
          id: this.tagForm.id,
          groupName: this.tagForm.groupName,
        };
        bulletinTagRegister(params).then(({ data }) => {
          const bulletinTagList = this.bulletinTagList;
          let tag = data.model;
          let index = bulletinTagList.findIndex(
            (item) => item.groupName == tag.groupName,
          );
          if (!this.tagForm.id && !this.tagForm.groupName) {
            this.apiGetBoarTagTempList().then(() => {
              console.log("apiGetBoarTagTempList then");
              this.tagTemplateDialogVisible = false;
              if (index == -1) {
                this.tagTemplateList.push(tag);
              }
              if (!tag.selected) {
                //如果是已有的标签，且已经绑定则不处理
                this.toggleBindTag(tag, index);
              }
            });
          } else {
            this.apiGetTagTempList().then(() => {
              this.tagTemplateDialogVisible = false;
            });
          }
        });
      });
    },
    toggleBindTag(tag, index) {
      let params = { bulletinCardId: this.cardId, groupName: tag.groupName };
      if (tag.selected) {
        bulletinTagUnbind(params).then((res) => {
          tag.selected = false;
          this.$set(this.tagTemplateList, index, tag);
          this.fetchDetail((model) => {
            this.$emit("updateCard", model);
          });
        });
      } else {
        bulletinTagBind(params).then((res) => {
          tag.selected = true;
          this.$set(this.tagTemplateList, index, tag);
          this.fetchDetail((model) => {
            this.$emit("updateCard", model);
          });
        });
      }
    },
    deleteTagTemplate() {
      this.$confirm("确定要删除看板的标签吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteBulletinTag({
            id: this.tagForm.id,
            groupName: this.tagForm.groupName,
            bulletinId: this.card.bulletinId,
          }).then(() => {
            this.$message.success("删除成功");
            this.apiGetTagTempList();
            this.tagTemplateDialogVisible = false;
          });
        })
        .catch(() => {});
    },
    selectTagTemplate(tag) {
      this.tagForm.backgroundColor = tag.color;
    },
    handleTagTemplateDialogClose() {
      this.$refs.tagForm.resetFields();
      this.tagForm = { id: "", title: "", backgroundColor: "" };
    },
    //卡片拖拽完成事件,同列排序
    tagSortUpdate(e, tagList) {
      let { oldIndex, newIndex } = e;
      let dragTagItem = tagList[newIndex];
      let preId = newIndex > 0 ? tagList[newIndex - 1].id : 0;
      let { id } = dragTagItem;
      let params = {
        id,
        preId,
      };
      bulletinTagSort(params).then(({ data }) => {
        this.updateBulletinCard();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-tag {
  border: 0;
  margin-right: 12px;
  margin-bottom: 5px;
}

.align-center {
  align-items: center;
}

.weui-flex {
  display: flex;
}

.form-item-flex {
  flex: 1;
  //margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
}

.cardDialog {
  ::v-deep {
    .el-dialog__header {
      border-bottom: 0;
      padding-left: 25px;
      padding-right: 25px;

      .el-dialog__headerbtn {
        right: 25px;
      }
    }

    .el-textarea.is-disabled .el-textarea__inner {
      color: #333333;
    }
  }
}

.dia-header {
  display: flex;
  align-items: center;
  padding-right: 30px;
  height: 30px;
}

.card-header {
  font-size: 14px;
  color: #42526e;
  font-weight: bolder;
}

.createdTime {
  flex: 1;
  color: #666666;
  font-size: 12px;
  margin-left: 20px;
}

.more {
  transform: rotate(90deg);
}

.label {
  font-size: 14px;
  margin-bottom: 10px;
  color: #42526e;
  font-weight: bolder;
}

.form-item {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;

  .content {
    flex: 1;
    width: 0;
    //display: flex;
    //flex-wrap: wrap;
    ::v-deep {
      .el-table--mini td,
      .el-table--mini th {
        padding: 2px 0;
      }
    }
  }
}

.tag-list {
  display: flex;
  align-items: center;
  padding: 0px;
  .tag-item {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    list-style: none;
  }
}

.eltag {
  color: #fff;

  ::v-deep {
    .el-tag__close {
      color: #ffffff;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }
}

.coverUpload {
  ::v-deep {
    .el-form-item__content {
      line-height: 1;
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }

    .el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: 78px;
      box-sizing: border-box;
    }

    .upload-container {
      .el-upload {
        width: auto;

        .el-upload-dragger {
          height: auto;
          width: 80px;
          border: 0;
        }
      }
    }
  }
}

.coverImage {
  border: 1px solid #c0ccda;
  border-radius: 6px;
  width: 80px;
  height: 80px;
}

.comment-textarea {
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  margin-bottom: 15px;

  .comment-border {
    margin-bottom: 10px;

    ::v-deep {
      .el-textarea__inner {
        border: 0;
      }
    }
  }

  .comment-btn {
    text-align: right;
    padding: 10px;
  }
}

.comment-ul {
  .comment-item {
    display: flex;
    margin-bottom: 15px;

    &:first-child {
      //margin-top: 15px;
    }

    .comment-user {
      background-color: #409eff;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
      font-size: 12px;
      border-radius: 30px;
      margin-right: 10px;
    }

    .comment-content {
      flex: 1;

      .c-user {
        font-size: 14px;
        color: #333333;
        margin-bottom: 5px;
        font-weight: 500;
      }

      .c-content {
        font-size: 13px;
        color: #333333;
        line-height: 1.5;
        //white-space: pre;当评论字符过多发生样式异常，由忠安移除，
      }

      .c-time {
        color: #5e6c84;
        font-size: 12px;
        margin-top: 5px;
      }
    }

    .comment-delete {
      margin-top: 10px;
    }

    .cmt-del {
      color: #999;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.image {
  margin-left: 10px;
  position: relative;
}

::v-deep {
  .tagDilog {
    margin-top: 50vh !important;
    top: -160px;
    height: 320px;
  }
}

.form-input {
  ::v-deep {
    .el-textarea__inner {
      border-color: transparent;

      &:focus {
        border-color: #409eff;
      }
    }
  }
}

.remark {
  white-space: pre-wrap;
}

.cover-image-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-status {
  border-radius: 8px;
  padding: 6px 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: rgba(3, 14, 44, 0.85);
  background: rgba(3, 14, 44, 0.04);
  margin-right: 16px;
  line-height: normal;
}
.full-width-btn {
  width: 100%;
}
.color-bar-container {
  padding: 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .color-bar {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      color: #ffffff;
      margin-right: 10px;
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      border-radius: 5px;
      opacity: 0.9;
      transition: all 0.3s;

      &:hover {
        opacity: 1;
        box-shadow: -8px 0 #97a0af;
        padding-left: 25px;
      }
    }

    .tag-edit {
      &:hover {
        color: #3a8ee6;
      }
    }
  }
}
</style>
<style lang="less">
.card_item_detail-header-title {
  .el-input__inner {
    padding: 0 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .el-textarea__inner {
    padding: 0 1px;
    font-size: 20px;
    //font-weight: 600;
    line-height: 1.2;
  }
}
</style>
