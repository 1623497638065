<template>
  <div class="etl-attribute-container">
    <div class="etl-map-container" v-if="tab == 'attribute'">
      <div class="map-header">
        <el-popover>
          <div class="map-field" slot="reference">
            <i
              class="iconfont-fx-pc icon-pc-page-view"
              style="color: #248af9"
            ></i
            ><span> 显示原始字段</span>
          </div>
          <div class="pop-fields">
            <div style="margin-bottom: 5px">
              <el-input
                v-model="keyword"
                placeholder=""
                size="small"
                prefix-icon="el-icon-search"
              ></el-input>
            </div>
            <div style="margin-bottom: 5px">
              <el-checkbox v-model="allChecks">全选</el-checkbox>
            </div>
            <div class="pop-fields-scroll">
              <el-checkbox
                style="display: block; margin-bottom: 5px"
                v-for="item in fields"
                :key="item.name"
                :value="isCheck(item.name)"
                @change="changeChk($event, item.name)"
                >{{ item.comment }}</el-checkbox
              >
            </div>
          </div>
        </el-popover>

        <el-button
          @click="addField"
          type="text"
          icon="el-icon-plus"
          size="small"
          >添加计算字段</el-button
        >
      </div>
      <div class="etl-map-scroll">
        <draggable
          class="etl-map"
          :list="node.mapFields"
          @end="changeChildrenData"
        >
          <div
            v-for="(item, index) in node.mapFields"
            :key="item.name"
            class="map-item"
          >
            <div
              class="map-title"
              :title="item.title"
              v-text="item.title"
            ></div>
            <div class="etl-action">
              <i @click="edit(index)" class="el-icon-edit"></i
              ><i
                v-if="item.fieldType == 'formField'"
                @click="delItem(index)"
                class="el-icon-delete"
              ></i>
              <template v-else>
                <el-popover>
                  <div slot="reference">
                    <i class="iconfont icon-dots-vertical"></i>
                  </div>
                  <div>
                    <div @click="editItem(index)" class="a-item">编辑字段</div>
                    <div @click="delItem(index)" class="a-item">删除字段</div>
                  </div>
                </el-popover>
              </template>
            </div>
            <div class="etl-input" v-if="editIndex == index">
              <el-input
                :key="index"
                ref="inputTitle"
                v-model="title"
                placeholder=""
                @blur="save"
                @keydown.enter.native="save"
                autofocus
              ></el-input>
            </div>
          </div>
        </draggable>
      </div>

      <formula-dialog
        title="公式"
        :originalFormula="originalFormula"
        :treeNodeList="originComponents"
        :fetchMetaFieldComponentList="fetchMetaFieldComponentList"
        @save="saveRely"
        ref="relyDialog"
        :disabled-funs="['TEXTUSER', 'TEXTDEPT', 'GETUSER']"
      >
        <template v-if="computeField" slot="header">
          <div style="margin-bottom: 10px; display: flex; align-items: center">
            <el-input
              v-model="computeField.comment"
              placeholder="请输入计算字段名称"
              size="small"
              style="margin-right: 10px"
            ></el-input>
            <el-select
              size="small"
              v-model="computeField.componentName"
              placeholder=""
            >
              <el-option label="数字类型" value="input_number"> </el-option>
              <el-option label="文本类型" value="input"> </el-option>
            </el-select>
          </div>
        </template>
      </formula-dialog>
    </div>
    <template v-else>
      <preview-error v-if="isError"></preview-error>
      <etl-preview
        v-else
        :node="node"
        :etlList="etlList"
        :etlLines="etlLines"
      ></etl-preview>
    </template>
  </div>
</template>
<script>
import EtlPreview from "./EtlPreview";
import PreviewError from "./PreviewError";
import nodeMixin from "./nodeMixin";
import { buildNodeFields, changeChildrenData, deleteMixins } from "./util";
import { isEmpty, uuid } from "@/zgg-core/utils";
import Draggable from "vuedraggable";

export default {
  name: "EtlMap",
  components: { EtlPreview, PreviewError, Draggable },
  mixins: [nodeMixin, deleteMixins],
  props: {
    node: Object,
    etlList: Array,
    etlLines: Array,
  },
  data() {
    return {
      editIndex: -1,
      title: "",
      keyword: "",
      originalFormula: [],
      computeField: null,
      relyVisible: false,
      visible2: false,
      computeIndex: -1,
      curEditField: null,
    };
  },
  computed: {
    fields() {
      let list = [];
      let line = this.etlLines.find((item) => item.toKey == this.node.key);
      if (line) {
        let fromKey = line.fromKey;
        let fromNode = this.etlList.find((item) => item.key == fromKey);
        if (fromNode) {
          list = fromNode.fields;
        }
      }
      if (!isEmpty(this.keyword)) {
        list = list.filter((item) => item.comment.indexOf(this.keyword) >= 0);
      }
      return list;
    },
    originComponents() {
      let list = [];
      let line = this.etlLines.find((item) => item.toKey == this.node.key);
      if (line) {
        let fromKey = line.fromKey;
        let fromNode = this.etlList.find((item) => item.key == fromKey);
        if (fromNode) {
          list = fromNode.fields;
        }
      }
      list = list.map((item) => item.component);
      // 添加的字段
      let setRelyComponentList = this.node.mapFields.filter(
        (item) => item.rely,
      );
      if (setRelyComponentList.length) {
        // 编辑添加的字段
        if (this.curEditField) {
          setRelyComponentList = setRelyComponentList.filter(
            (item) => item.name != this.curEditField.name,
          );
        }
        list = list.concat(setRelyComponentList);
      }
      // 可参与字段设置的公式计算字段要排除关联数据字段
      return list.filter((item) => item.componentName != "reference_data");
    },
    allChecks: {
      get() {
        let list = this.node.mapFields.filter(
          (item) => item.fieldType == "formField",
        );
        let names = this.fields.map((item) => item.name);
        if (names.length && list.length) {
          let count = list.filter((item) => names.includes(item.name)).length;
          return count == names.length;
        }
        return false;
      },
      set(val) {
        let names = this.node.mapFields
          .filter((item) => item.fieldType == "formField")
          .map((item) => item.name);
        let list = this.fields.filter((item) => !names.includes(item.name));
        list.forEach((item) => {
          item.fieldType = "formField";
          item.key = item.name;
          item.title = item.comment;
          this.node.mapFields.push(item);
        });
        this.changeChildrenData();
      },
    },
  },
  methods: {
    chkBeforeSave() {
      if (isEmpty(this.computeField.comment)) {
        this.$message.error("计算字段的名称不能为空");
        return false;
      }
      return true;
    },
    // 检查是否存在公式嵌套
    chkRelyNest(originalFormula) {
      let hasFieldFormula = []; // 参与当前字段公式计算的字段列表
      originalFormula.forEach((rows) => {
        rows.forEach((row) => {
          if (row.type == "field") {
            hasFieldFormula.push(row.name);
          }
        });
      });
      if (hasFieldFormula.length) {
        hasFieldFormula = new Array(...new Set(hasFieldFormula));
      }
      let count = 0;
      if (this.curEditField) {
        let fieldName = this.curEditField.name;
        let setRelyComponentList = this.node.mapFields.filter(
          (item) => item.rely && item.name != fieldName,
        ); // 新增的字段排除自身
        let hasRelyComponentList = [];
        // 1、判断当前字段是否在其他字段的公式里
        setRelyComponentList.forEach((item) => {
          let name = item.name;
          let elOriginalFormula = item.rely.originalFormula;
          elOriginalFormula.forEach((rows) => {
            rows.forEach((row) => {
              // 当前字段在其他字段的公式计算里
              if (row.type == "field" && row.name == fieldName) {
                hasRelyComponentList.push(name);
              }
            });
          });
        });
        // 2、判断当前字段公式是否包含其他字段
        if (hasRelyComponentList.length) {
          hasRelyComponentList = new Array(...new Set(hasRelyComponentList));
        }
        if (hasFieldFormula.length && hasRelyComponentList.length) {
          hasFieldFormula.forEach((item) => {
            if (hasRelyComponentList.includes(item)) {
              count += 1;
            }
          });
        }
      }
      if (count) {
        this.$message.error("公式嵌套出现循环");
        return false;
      }
      return true;
    },
    saveRely(originalFormula, componentNames) {
      if (!this.chkBeforeSave()) {
        return;
      }
      if (!this.chkRelyNest(originalFormula)) {
        return;
      }

      if (this.computeField.componentName == "input_number") {
        if (
          !componentNames.every((value) =>
            ["input_number", "empty"].includes(value),
          )
        ) {
          let str = "";
          if (componentNames.length > 1) {
            str = "公式返回值可能存在非数字类型，请检查您的公式配置";
          } else {
            str = "公式返回值是非数字类型，请检查您的公式配置";
          }
          this.$message.error(str);

          return;
        }
      }

      let rely = {
        // formula,
        originalFormula: originalFormula,
      };
      if (this.computeIndex == -1) {
        // 新增计算字段
        let name = uuid();
        let field = {
          ...this.computeField,
          collection: this.node.key,
          fieldType: "relyField",
          key: name,
          name,
          form: true,
          title: this.computeField.comment,
          component: {
            componentName: this.computeField.componentName,
            form: true,
            key: name,
            name,
            title: this.computeField.comment,
          },
          rely,
        };
        this.node.mapFields.push(field);
      } else {
        let field = this.node.mapFields[this.computeIndex];
        field.componentName = this.computeField.componentName;
        field.comment = this.computeField.comment;
        field.title = this.computeField.comment;
        field.component.title = field.title;
        field.component.componentName = field.componentName;
        field.rely = rely;
      }
      this.computeIndex = -1;
      this.originalFormula = [];
      this.computeField = null;
      console.log("save rely");
      console.log(rely);
      this.$refs.relyDialog.close();
    },
    addField() {
      this.computeIndex = -1;
      this.originalFormula = [];
      this.computeField = {
        componentName: "input_number",
        comment: "",
      };
      // this.relyVisible = true;
      this.$nextTick(() => {
        this.$refs.relyDialog.open();
      });
    },
    editItem(index) {
      this.computeIndex = index;
      let field = this.node.mapFields[index];
      this.curEditField = field;
      this.originalFormula = this._.cloneDeep(field.rely.originalFormula);
      this.computeField = {
        componentName: field.componentName,
        comment: field.title,
      };

      // this.relyVisible = true;
      this.$nextTick(() => {
        this.$refs.relyDialog.open();
      });
    },
    changeChk(value, name) {
      if (value) {
        let node = this._.cloneDeep(
          this.fields.find((item) => item.name == name),
        );
        node.fieldType = "formField";
        node.key = node.name;
        node.title = node.comment;
        this.node.mapFields.push(node);
      } else {
        let index = this.node.mapFields.findIndex((item) => item.name == name);
        this.node.mapFields.splice(index, 1);
      }
      this.changeChildrenData();
    },
    isCheck(name) {
      return this.node.mapFields.findIndex((item) => item.name == name) >= 0;
    },
    save() {
      if (this.editIndex == -1) {
        return;
      }
      if (!isEmpty(this.title)) {
        this.node.mapFields[this.editIndex].title = this.title;
      }
      this.editIndex = -1;
    },
    edit(index) {
      this.editIndex = index;
      this.title = this.node.mapFields[index].title;
      this.$nextTick(() => {
        if (this.$refs.inputTitle && this.$refs.inputTitle[0]) {
          this.$refs.inputTitle[0].focus();
        }
      });
    },
    delItem(index) {
      this.node.mapFields.splice(index, 1);
      buildNodeFields(this.node, this.etlList, this.etlLines);
    },
    changeChildrenData() {
      buildNodeFields(this.node, this.etlList, this.etlLines);
      changeChildrenData([this.node], this.etlList, this.etlLines);
    },
  },
};
</script>
<style lang="scss" scoped>
.etl-map-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.map-header {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 40px;
}
.map-field {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
}
.etl-map-scroll {
  flex: 1;
  overflow: auto;
}
.etl-map {
  display: flex;
  width: max-content;
  .map-item {
    width: 170px;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e9e9e9;
    border-right: solid 1px #e9e9e9;
    box-sizing: border-box;
    padding: 0 8px;
    position: relative;
    &:hover {
      .etl-action {
        display: flex;
      }
    }
  }
  .etl-input {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .map-title {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .etl-action {
    display: none;
    align-content: center;
    i {
      margin-right: 8px;
      color: #248af9;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.pop-fields {
  height: 300px;
  display: flex;
  flex-direction: column;
  .pop-fields-scroll {
    flex: 1;
    overflow: auto;
  }
}
.weui {
  display: flex;
  align-items: center;
}
.a-item {
  font-size: 14px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    color: var(--zgg-brand-color-6);
  }
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
