<template>
  <div>
    <el-form
      ref="form"
      :model="node"
      label-position="top"
      :show-message="false"
      :rules="rules"
      :disabled="disabled"
    >
      <el-form-item label="触发开始时间" prop="executeTime">
        <el-date-picker
          v-model="node.executeTime"
          type="datetime"
          placeholder="请选择触发开始时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束执行时间" prop="executeEndTime">
        <el-date-picker
          v-model="node.executeEndTime"
          type="datetime"
          placeholder="请选择结束执行时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="循环类型" prop="loopType">
        <el-select
          v-model="node.loopType"
          placeholder="请选择循环类型"
          @change="changeLooType"
        >
          <el-option
            v-for="item in timerCronLoopTypeEnums"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <template v-if="node.loopType == 'custom'">
        <!-- 小时 -->
        <el-form-item label="小时" prop="cronConfig.hour.cronValueType">
          <el-select
            v-model="node.cronConfig.hour.cronValueType"
            placeholder=""
            @change="hourTriggerChange"
          >
            <el-option
              v-for="item in hourTriggerTypeEnums"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="node.cronConfig.hour.cronValueType == 'range'">
          <div class="form-item-row">
            <el-form-item
              required
              prop="cronConfig.hour.value[0]"
              style="flex: 1"
            >
              <el-input
                placeholder="请输入"
                v-model="hourRangeStart"
                @change="hourRangeStartChange"
              >
              </el-input>
            </el-form-item>
            <span style="margin-top: 10px">至</span>
            <el-form-item
              required
              prop="cronConfig.hour.value[1]"
              style="flex: 1"
            >
              <el-input
                placeholder="请输入"
                @change="hourRangeEndChange"
                v-model="hourRangeEnd"
              >
              </el-input>
            </el-form-item>
          </div>
        </template>
        <el-form-item
          v-if="node.cronConfig.hour.cronValueType == 'appoint'"
          required
          prop="cronConfig.hour.value"
        >
          <el-select
            v-model="node['cronConfig']['hour'].value"
            placeholder="请选择"
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in 24"
              :key="index"
              :label="`${index}时`"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="node.cronConfig.hour.cronValueType == 'increment'">
          <div class="form-item-row">
            <span style="margin-top: 10px">从</span>
            <el-form-item
              required
              prop="cronConfig.hour.value[0]"
              style="flex: 1"
            >
              <el-input v-model="hourIncrementStart"> </el-input>
            </el-form-item>
            <span style="margin-top: 10px">时开始，每隔</span>
            <el-form-item
              required
              prop="cronConfig.hour.value[1]"
              style="flex: 1"
            >
              <el-input v-model="hourIncrementEnd"> </el-input>
            </el-form-item>
            <span style="margin-top: 10px">小时</span>
          </div>
        </template>

        <!-- 天/星期 -->
        <el-form-item label="天/星期" style="margin-bottom: 10px">
          <el-radio-group v-model="dayWeek">
            <el-radio label="date">天</el-radio>
            <el-radio label="week">星期</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 天的配置 -->
        <template v-if="dayWeek == 'date'">
          <el-form-item required prop="cronConfig.date.cronValueType">
            <el-select
              v-model="node.cronConfig.date.cronValueType"
              placeholder="请选择循环类型"
              @change="dateTriggerChange"
            >
              <el-option
                v-for="item in dayTriggerTypeEnums"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 天：范围 -->
          <div
            v-if="node.cronConfig.date.cronValueType == 'range'"
            class="form-item-row"
          >
            <el-form-item
              required
              prop="cronConfig.date.value[0]"
              style="flex: 1"
            >
              <el-input
                placeholder="请输入"
                @change="dateRangeStartChange"
                v-model="dateRangeStart"
              >
              </el-input>
            </el-form-item>
            <span style="margin-top: 10px">至</span>
            <el-form-item
              required
              prop="cronConfig.date.value[1]"
              style="flex: 1"
            >
              <el-input
                placeholder="请输入"
                @change="dateRangeEndChange"
                v-model="dateRangeEnd"
              >
              </el-input>
            </el-form-item>
          </div>
          <!-- 天：固定值 -->
          <template
            v-if="node['cronConfig']['date'].cronValueType == 'appoint'"
          >
            <el-form-item required prop="cronConfig.date.value">
              <el-select
                v-model="node['cronConfig']['date'].value"
                placeholder="请选择"
                multiple
              >
                <el-option
                  v-for="item in 31"
                  :key="item"
                  :label="`${item}日`"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- 天：按一定增量触发 -->
          <template
            v-if="node['cronConfig']['date'].cronValueType == 'increment'"
          >
            <div class="form-item-row">
              <div style="margin-top: 10px">从</div>
              <el-form-item
                required
                prop="cronConfig.date.value[0]"
                style="flex: 1"
              >
                <el-input v-model="dateIncrementStart"> </el-input>
              </el-form-item>
              <span style="margin-top: 10px">日开始，每隔</span>
              <el-form-item
                required
                prop="cronConfig.date.value[1]"
                style="flex: 1"
              >
                <el-input v-model="dateIncrementEnd"> </el-input>
              </el-form-item>
              <div style="margin-top: 10px">日</div>
            </div>
          </template>
        </template>
        <!-- 星期的配置 -->
        <template v-else-if="dayWeek == 'week'">
          <el-form-item
            :rules="[{ required: true, message: '请选择' }]"
            prop="cronConfig.week.value"
            :show-message="true"
          >
            <el-checkbox-group
              v-model="node.cronConfig.week.value"
              size="small"
              class="rpa-checkbox-group"
            >
              <el-checkbox-button :label="1"></el-checkbox-button>
              <el-checkbox-button :label="2"></el-checkbox-button>
              <el-checkbox-button :label="3"></el-checkbox-button>
              <el-checkbox-button :label="4"></el-checkbox-button>
              <el-checkbox-button :label="5"></el-checkbox-button>
              <el-checkbox-button :label="6"></el-checkbox-button>
              <el-checkbox-button :label="7"></el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
        </template>

        <!-- 月 -->
        <el-form-item label="月">
          <el-select
            v-model="node['cronConfig']['month'].cronValueType"
            placeholder="请选择循环类型"
            @change="monthTriggerChange"
            style="width: 100%"
          >
            <el-option
              v-for="item in monthTriggerTypeEnums"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="node.cronConfig.month.cronValueType == 'appoint'"
          required
          prop="cronConfig.month.value"
          ><el-select
            v-model="node['cronConfig']['month'].value"
            placeholder="请选择"
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="item in 12"
              :key="item"
              :label="`${item}月`"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import { isEmptyByKey } from "@zgg-core-utils/utils";
import {
  hourTriggerTypeEnums,
  timerCronLoopTypeEnums,
  dayTriggerTypeEnums,
  monthTriggerTypeEnums,
} from "./loopTypeEnums";
import moment from "moment";
export default {
  name: "rpa-timer-cron",
  props: {
    node: Object,
    disabled: Boolean,
  },
  computed: {
    hourRangeStart: {
      get() {
        if (!isEmptyByKey(this.node, "cronConfig.hour.value")) {
          return this.node.cronConfig.hour.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.node.cronConfig.hour.value, 0, val);
      },
    },
    hourRangeEnd: {
      get() {
        if (!isEmptyByKey(this.node, "cronConfig.hour.value")) {
          return this.node.cronConfig.hour.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.node.cronConfig.hour.value, 1, val);
      },
    },
    hourIncrementStart: {
      get() {
        if (this.node.cronConfig && this.node.cronConfig.hour) {
          return this.node.cronConfig.hour.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.node.cronConfig.hour.value, 0, val > 23 ? 23 : val);
      },
    },
    hourIncrementEnd: {
      get() {
        if (this.node.cronConfig && this.node.cronConfig.hour) {
          return this.node.cronConfig.hour.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        if (val !== "") {
          this.$set(this.node.cronConfig.hour.value, 1, val <= 0 ? 1 : val);
        } else {
          this.$set(this.node.cronConfig.hour.value, 1, val);
        }
      },
    },
    dateRangeStart: {
      get() {
        if (this.node.cronConfig && this.node.cronConfig.date) {
          return this.node.cronConfig.date.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.node.cronConfig.date.value, 0, val);
      },
    },
    dateRangeEnd: {
      get() {
        if (this.node.cronConfig && this.node.cronConfig.date) {
          return this.node.cronConfig.date.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        this.$set(this.node.cronConfig.date.value, 1, val);
      },
    },

    dateIncrementStart: {
      get() {
        if (this.node.cronConfig && this.node.cronConfig.date) {
          return this.node.cronConfig.date.value[0];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        if (val !== "") {
          if (val <= 0) {
            this.$set(this.node.cronConfig.date.value, 0, 1);
          } else if (val > 0) {
            this.$set(this.node.cronConfig.date.value, 0, val > 31 ? 31 : val);
          }
        } else {
          this.$set(this.node.cronConfig.date.value, 0, val);
        }
      },
    },
    dateIncrementEnd: {
      get() {
        if (this.node.cronConfig && this.node.cronConfig.date) {
          return this.node.cronConfig.date.value[1];
        } else {
          return 0;
        }
      },
      set(val) {
        val = this.inputNumberCheck(val);
        if (val !== "") {
          this.$set(this.node.cronConfig.date.value, 1, val <= 0 ? 1 : val);
        } else {
          this.$set(this.node.cronConfig.date.value, 1, val);
        }
      },
    },
    dayWeek: {
      get() {
        if (!isEmptyByKey(this.node, "cronConfig.date")) {
          return "date";
        } else if (!isEmptyByKey(this.node, "cronConfig.week")) {
          return "week";
        }
        return;
      },
      set(val) {
        if (val == "date") {
          this.$set(this.node.cronConfig, "date", {
            cronValueType: "all",
          });
          this.$delete(this.node.cronConfig, "week");
        } else if (val == "week") {
          this.$set(this.node.cronConfig, "week", {
            cronValueType: "appoint",
            value: [moment().day()],
          });
          this.$delete(this.node.cronConfig, "date");
        }
      },
    },
  },
  inject: {
    getWorkflowConfig: {
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      rules: {
        executeTime: [{ required: true, message: "请选择触发开始时间" }],
        // executeEndTime: [{ required: true, message: "请选择结束执行时间" }],
        loopType: [{ required: true, message: "请选择循环类型" }],
        "cronConfig.hour.cronValueType": [
          { required: true, message: "请选择循环类型" },
        ],
      },
      timerCronLoopTypeEnums: [],
      hourTriggerTypeEnums,
      dayTriggerTypeEnums,
      monthTriggerTypeEnums,
    };
  },
  created() {
    /**
     * @type Array
     */
    let list = this._.cloneDeep(timerCronLoopTypeEnums);
    if (typeof this.getWorkflowConfig === "function") {
      let config = this.getWorkflowConfig();

      if (config && config.loopTypeEnablePerMinute) {
        list.unshift({ label: "每分钟", value: "perMinute" });
      }
    }
    this.timerCronLoopTypeEnums = list;
  },
  methods: {
    getData(callback) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        callback(valid);
      });
    },
    dateRangeStartChange(val) {
      if (this.node.cronConfig && this.node.cronConfig.date) {
        val = this.checkoutRange(val, this.dateRangeEnd, 1, 31, "min");
        this.$set(this.node.cronConfig.date.value, 0, val);
      }
    },
    dateRangeEndChange(val) {
      if (this.node.cronConfig && this.node.cronConfig.date) {
        val = this.checkoutRange(val, this.dateRangeStart, 1, 31, "max");
        this.$set(this.node.cronConfig.date.value, 1, val);
      }
    },
    monthTriggerChange(val) {
      if (val == "all") {
        this.node.cronConfig.month.value = "";
      }
      if (val == "appoint") {
        this.node.cronConfig.month.value = [];
      }
    },
    /**
     * 处理小时触发类型改变
     * @param val
     */
    hourTriggerChange(val) {
      if (val == "range") {
        this.node.cronConfig.hour.value = ["", ""];
      }
      if (val == "appoint") {
        this.node.cronConfig.hour.value = [];
      }
      if (val == "increment") {
        this.node.cronConfig.hour.value = [0, 1];
      }
    },
    /**
     * 处理天触发类型改变
     * @param val
     */
    dateTriggerChange(val) {
      console.log("dateTriggerChange", val);
      if (val == "range") {
        this.$set(this.node.cronConfig.date, "value", ["", ""]);
      }
      if (val == "appoint") {
        this.$set(this.node.cronConfig.date, "value", []);
      }
      if (val == "increment") {
        this.$set(this.node.cronConfig.date, "value", ["", ""]);
      }
    },
    inputNumberCheck(value) {
      let val;
      // val = value.replace(/[^\d]/g, '').replace(/^0{1,}/g, '')
      val = value.replace(/[^\d]/g, "");
      if (val) {
        val = parseInt(val);
      }

      return val;
    },
    checkoutRange(inputValue, compareValue, min, max, checkoutType) {
      if (inputValue < min) {
        inputValue = min;
      }
      if (inputValue > max) {
        inputValue = max;
      }
      if (checkoutType == "min") {
        if (compareValue && inputValue > compareValue) {
          inputValue = compareValue;
        }
      }
      if (checkoutType == "max") {
        if (compareValue && inputValue < compareValue) {
          inputValue = compareValue;
        }
      }
      return inputValue;
    },
    changeLooType(val) {
      if (val == "custom") {
        let cronConfig = {
          hour: {
            cronValueType: "all",
            value: [],
          },
          date: {
            cronValueType: "all",
            value: [],
          },
          week: {},
          month: {
            cronValueType: "all",
            value: [],
          },
        };
        this.$set(this.node, "cronConfig", cronConfig);
      } else {
        this.node.cronConfig = {};
      }
    },
    hourRangeStartChange(val) {
      if (this.node.cronConfig && this.node.cronConfig.hour) {
        val = this.checkoutRange(val, this.hourRangeEnd, 0, 23, "min");
        this.$set(this.node.cronConfig.hour.value, 0, val);
      }
    },
    hourRangeEndChange(val) {
      if (this.node.cronConfig && this.node.cronConfig.hour) {
        val = this.checkoutRange(val, this.hourRangeStart, 0, 23, "max");
        this.$set(this.node.cronConfig.hour.value, 1, val);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
}
</style>
