<template>
  <div>
    <el-form label-position="top" size="small">
      <el-form-item label="指定通知人" style="width: 100%">
        <div
          v-if="value.userRange.userFields.length"
          style="display: flex; flex-direction: row; margin-bottom: 12px"
        >
          <el-tag
            v-for="(tag, index) in value.userRange.userFields"
            :key="`${index}+${tag.fieldName}`"
            closable
            type="info"
            :disable-transitions="true"
            @close="removeUserField(index)"
          >
            {{
              tag.componentName == "mobile_input" ? "手机号组件" : "用户组件"
            }}：{{ tag.fieldTitle }}
          </el-tag>
        </div>

        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
          <el-tag
            v-for="(tag, index) in value.userRange.userInfoList"
            :key="tag.id"
            closable
            :disable-transitions="true"
            @close="removeUserInfo(index)"
            type="success"
            style="margin-bottom: 5px"
          >
            用户：{{ tag.name }}
          </el-tag>
        </div>
        <el-dropdown trigger="click" style="margin-left: 10px">
          <el-link icon="el-icon-plus" :underline="false">添加通知人</el-link>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native.stop="initAddCompanyUser"
              >从单位添加</el-dropdown-item
            >
            <el-dropdown-item
              @click.native.stop="initAddFormUserField"
              :disabled="!triggerFormId"
              >从表单添加
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <template v-if="value.type != 'notice_sms'">
        <div style="color: #e54f4f">
          提示：由于微信公众平台规则修改，每个通知字段限制为20个字符，如果通知内容过长，请分开配置。
        </div>
        <el-form-item
          v-for="content in noticeContentList"
          :label="content.label"
          :key="content.label"
          style="width: 100%"
        >
          <rpa-codemirror
            :ref="content.ref"
            :fieldList="msgTemplateComponentList"
            @change="changeCode($event, content)"
            textareaHeight="30px"
          ></rpa-codemirror>
          <el-popover
            popper-class="pop-trigger"
            width="320"
            v-model="content.columnVisible"
          >
            <div v-clickoutside="handleClose" class="pop-scroll">
              <div
                v-for="item in msgTemplateComponentList"
                :key="item.key"
                v-text="item.title"
                class="form-item"
                :class="{ disabled: isDisabled(item.name, content) }"
                @click="addColumn(item, content)"
              ></div>
            </div>
            <el-button
              slot="reference"
              type="text"
              icon="el-icon-plus"
              size="small"
              @click.stop=""
              :disabled="!triggerFormId"
              >添加字段
            </el-button>
          </el-popover>
        </el-form-item>
      </template>
      <el-form-item style="width: 100%" v-if="value.type == 'notice_sms'">
        <div v-if="isEmptyTpl">
          <div>
            <el-link
              icon="el-icon-plus"
              :underline="false"
              @click="creatNewTemplate"
              >创建新模板</el-link
            >
          </div>
          <div>
            <el-select
              v-model="smsValue"
              placeholder="选择模板"
              style="width: 100%"
              size="small"
              @visible-change="forSmsTemplateList"
              @change="forSmsTemplateDetail"
            >
              <el-option
                v-for="item in smsTemplateList"
                :key="item.id"
                :label="item.templateName"
                :value="item.id"
              >
                {{ getTemplateInfo(item) }}
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="template-detail" v-else>
          <div style="background-color: #f5f7fa">
            <span style="padding-left: 15px"
              >模板名称：{{ this.currentSmsTemplate.templateName }}({{
                smsTemplateStatus
              }})</span
            >
          </div>
          <div class="template-content">
            <span>模板内容: {{ this.currentSmsTemplate.templateContent }}</span>
            <el-row>
              <el-button type="primary" plain size="mini" @click="reSelect"
                >重新选择</el-button
              >
              <el-button type="warning" plain size="mini" @click="reEdit"
                >修改模板</el-button
              >
              <el-button type="danger" plain size="mini" @click="deleteTemplate"
                >删除模板</el-button
              >
            </el-row>
          </div>
          <el-table :data="value.msgTemplate" height="300">
            <el-table-column property="name" label="占位符"></el-table-column>
            <el-table-column label="字段属性">
              <template slot-scope="{ row }">
                <template>
                  <el-select v-model="row.title" placeholder="请选择">
                    <el-option
                      v-for="item in msgTemplateComponentList"
                      :key="item.key"
                      :label="item.title"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
    </el-form>
    <userSelect
      dialogTitle="添加成员"
      ref="formUserSelect"
      :multiple="true"
      @changeUsers="changeUsers"
      size="small"
      :only-company="true"
      :hide-outer="true"
      :company-ids="[$store.state.user.currentCompanyId]"
    ></userSelect>
    <el-dialog
      title="目标表单成员、手机号字段"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      @open="handleDialogOpened"
      @close="handleClose"
    >
      <div style="overflow: auto; height: 480px" class="edit-bulletin-view">
        <ul class="f-ul" v-if="userFiledComponentList.length != 0">
          <li
            class="f-li"
            v-for="(field, index) in userFiledComponentList"
            :key="field.key"
            @click="selectUserField(field)"
            :class="checkSelect(field) ? 'select' : ''"
          >
            <span> {{ field.title }}</span>
          </li>
        </ul>
        <el-empty v-else description="该目标表单无用户组件字段"></el-empty>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑短信模板"
      :visible.sync="dialogTableVisible"
      :before-close="handleCloseTemplate"
      :close-on-click-modal="false"
    >
      <div class="sms-contianer">
        模板标题
        <el-input
          v-model="templateName"
          placeholder="请输入模板标题"
          style="margin: 20px 0"
        ></el-input>
        <div class="sms-template">
          <span>短信内容</span>
          <p style="margin: 5px 0; color: rgb(155 164 180)">
            为保证短信的稳定推送，以下内容须审核后才能使用，尽量不要使用特殊字段，严禁发送违规信息。<br />多于70字（含签名）的短信按67字每条计费。
          </p>
          <rpa-codemirror
            v-if="dialogTableVisible"
            ref="rpaSMSCode"
            :fieldList="templateFieldList"
            @change="changeSMSCode"
            regType="$"
          ></rpa-codemirror>
          <el-button
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="addSMSColumn()"
            :disabled="!triggerFormId"
            >添加字段
          </el-button>
          <p style="background-color: #f5f7fa; padding: 6px 10px">
            · 短信必须审核通过后才能启用智能助手<br />
            · 审核时间约为2小时，非工作时间提交审核会延长
          </p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseTemplate">取 消</el-button>
        <el-button type="primary" @click="uploadReview">{{
          buttonStatus
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import test from "@/utils/test";
import Clickoutside from "element-ui/src/utils/clickoutside";
import userSelect from "@/views/lowCode/templateConfig/user-select";
import {
  saveTemplate,
  getSmsTemplateList,
  getSmsTemplateDetail,
  deleteSmsTemplate,
} from "./api";
import { isEmpty, isIDEmpty } from "@zgg-core-utils/utils";
import rpaCodemirror from "@/views/lowCode/rpa/rpa-codemirror.vue";

export default {
  name: "action-target-send-message",
  directives: { Clickoutside },

  components: {
    userSelect,
    rpaCodemirror,
  },
  props: {
    value: Object,
    triggerComponents: Array,
    triggerFormId: String,
  },
  data() {
    return {
      dialogVisible: false,
      smsTemplateList: [],
      smsValue: "",
      dialogTableVisible: false,
      templateFieldList: [],
      msgTemplateComponent: "",
      templateName: "",
      templateContent: "",
      currentSmsTemplate: {},
      noticeContentList: [
        {
          ref: "rpaCode1",
          label: "通知内容1",
          template: "msgTemplate",
          columnVisible: false,
        },
        {
          ref: "rpaCode2",
          label: "通知内容2",
          template: "msgTemplate2",
          columnVisible: false,
        },
        {
          ref: "rpaCode3",
          label: "通知内容3",
          template: "msgTemplate3",
          columnVisible: false,
        },
      ],
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val);
      },
    },
    "value.userRange": {
      handler(val) {
        console.log(val);
        //如果通知用户有变化，则需要修改执行动作的title
        this.value.title = this.initActionTitle();
      },
      deep: true,
      immediate: true,
    },
    "value.userRange.userInfoList": {
      handler(val) {
        this.value.userRange.userIds = val.map((item) => item.id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (!isIDEmpty(this.value.smsTemplateId)) {
      getSmsTemplateDetail({
        id: this.value.smsTemplateId,
      }).then((res) => {
        this.currentSmsTemplate = res.data.template;
        this.templateName = this.currentSmsTemplate.templateName;
        this.templateContent = this.currentSmsTemplate.templateContent;
        this.changeSMSCode(this.templateContent);
        this.getMsgTemplate();
        this.dialogTableVisible = false;
        this.initSMSEdit();
      });
    }
    this.$nextTick(() => {
      this.noticeContentList.forEach((item) => {
        if (this.$refs[item.ref] && this.value[item.template]) {
          this.$refs[item.ref][0].init(this.value[item.template]);
        }
      });
    });
  },
  computed: {
    /** 判断是否空模版 */
    isEmptyTpl() {
      return isEmpty(this.currentSmsTemplate);
    },
    buttonStatus() {
      if (
        this.templateName == this.currentSmsTemplate.templateName &&
        `${this.templateContent}` ==
          `${this.currentSmsTemplate.templateContent}`
      ) {
        return "保存";
      } else {
        return "提交审核";
      }
    },
    smsTemplateStatus() {
      if (this.currentSmsTemplate.templateStatus == "0") {
        return "审核中";
      } else if (this.currentSmsTemplate.templateStatus == "1") {
        return "审核通过";
      } else if (this.currentSmsTemplate.templateStatus == "2") {
        return "审核未通过";
      }
    },
    userFiledComponentList() {
      return this.triggerComponents.filter((item) => {
        if (this.value.type == "notice_sms") {
          return (
            item.componentName == "user_select" ||
            item.componentName == "user_list_select" ||
            item.componentName == "mobile_input"
          );
        } else {
          return (
            item.componentName == "user_select" ||
            item.componentName == "user_list_select"
          );
        }
      });
    },
    msgTemplateComponentList() {
      return this.triggerComponents.filter(
        (item) =>
          item.name &&
          !["text_area", "reference_data"].includes(item.componentName) &&
          item.name.indexOf(".") == -1,
      );
    },
  },
  methods: {
    initSMSEdit() {
      this.$nextTick(() => {
        this.changeSMSCode(this.templateContent);
        if (this.$refs.rpaSMSCode && !isEmpty(this.templateContent)) {
          this.$refs.rpaSMSCode.init(this.templateContent);
        }
      });
    },
    changeSMSCode(code) {
      const rex = /\${[a-zA-Z_0-9]+}/gm;
      let result;
      if (!isEmpty(code)) {
        result = code.match(rex);
      }

      let arr = [];
      if (result && result.length > 0) {
        result.forEach((item) => {
          let name = `field_${arr.length + 1}`;
          code = code.replace(item, "${" + name + "}");
          arr.push({
            name: name,
            title: name,
          });
        });
      }
      this.$set(this, "templateContent", code);
      this.$set(this, "templateFieldList", arr);
    },
    changeCode(code, content) {
      this.value[content.template] = code;
    },
    getTemplateInfo(item) {
      if (item.templateStatus == "0") {
        return item.templateName + "--(审核中)";
      } else if (item.templateStatus == "1") {
        return item.templateName + "--(审核通过)";
      } else if (item.templateStatus == "2") {
        return item.templateName + "--(审核未通过)";
      }
    },
    creatNewTemplate() {
      this.templateName = "";
      this.templateContent = "";
      this.currentSmsTemplate = {};
      this.dialogTableVisible = true;
    },
    handleCloseTemplate() {
      this.smsValue = "";
      this.dialogTableVisible = false;
    },
    reEdit() {
      this.dialogTableVisible = true;
      this.initSMSEdit();
    },
    deleteTemplate() {
      deleteSmsTemplate({
        ids: [this.currentSmsTemplate.id],
      }).then(() => {
        this.smsValue = "";
        this.currentSmsTemplate = {};
        this.templateName = "";
        this.templateContent = "";
        this.value.smsTemplateId = "";
      });
    },
    reSelect() {
      this.smsValue = "";
      this.currentSmsTemplate = {};
      this.templateName = "";
      this.templateContent = "";
      this.value.smsTemplateId = "";
    },
    forSmsTemplateDetail(val) {
      getSmsTemplateDetail({
        id: val,
      }).then((res) => {
        let smsTemplate = res.data.template;
        if (smsTemplate.templateStatus == "1") {
          this.$set(this.value, "msgTemplate", []);
          this.currentSmsTemplate = smsTemplate;
          this.templateName = this.currentSmsTemplate.templateName;
          this.templateContent = this.currentSmsTemplate.templateContent;
          this.changeSMSCode(this.templateContent);
          this.getMsgTemplate();
        } else {
          this.$confirm("此模板未审核通过, 是否重新编辑?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.dialogTableVisible = true;
              this.initSMSEdit();
            })
            .catch(() => {
              this.reSelect();
            });
        }
      });
    },
    forSmsTemplateList(e) {
      if (e) {
        getSmsTemplateList({
          templateStatus: 100, //返回所有数据
        }).then((res) => {
          this.smsTemplateList = res.data.page.list;
        });
      }
    },
    getMsgTemplate() {
      let arr = [];
      this.templateFieldList.forEach((item) => {
        let obj = this.value.msgTemplate.find((row) => row.name == item.name);
        if (obj) {
          arr.push(obj);
        } else {
          arr.push({
            name: item.name,
            title: "",
          });
        }
      });
      this.$set(this.value, "msgTemplate", arr);
    },
    uploadReview() {
      if (!this.templateName) {
        this.$message.error("请输入模板名称");
        return;
      }
      if (!this.templateContent) {
        this.$message.error("请输入短信内容");
        return;
      }
      if (this.buttonStatus == "保存") {
        this.value.smsTemplateId = this.currentSmsTemplate.id;
        this.dialogTableVisible = false;
        this.getMsgTemplate();
      } else {
        if (this.currentSmsTemplate.id) {
          this.$confirm(
            "短信模板重新审核时会导致使用该模板的智能助手不可用，确定要提交修改吗？",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            },
          )
            .then(() => {
              this.confirmSaveTemplate();
            })
            .catch(() => {});
        } else {
          this.confirmSaveTemplate();
        }
      }
    },
    confirmSaveTemplate() {
      let param = {
        smsType: "10",
        templateName: this.templateName,
        templateContent: this.templateContent,
      };
      if (this.currentSmsTemplate.id) {
        param.id = this.currentSmsTemplate.id;
      }
      saveTemplate(param).then((res) => {
        this.currentSmsTemplate = res.data.template;
        this.value.smsTemplateId = res.data.template.id;
        this.getMsgTemplate();
      });
      this.dialogTableVisible = false;
    },
    /**
     * 检查表单成员字段是否已经勾选
     * @param field
     * @returns {boolean}
     */
    checkSelect(field) {
      if (
        this.value.userRange.userFields &&
        this.value.userRange.userFields.length > 0
      ) {
        let user = this.value.userRange.userFields.find(
          (item) => item.fieldName == field.name,
        );
        if (user) {
          return true;
        }
      }
      return false;
    },
    removeUserField(index) {
      this.value.userRange.userFields.splice(index, 1);
    },
    removeUserInfo(index) {
      console.log(index);
      this.value.userRange.userInfoList.splice(index, 1);
      console.log(this.value.userRange.userInfoList);
    },

    initActionTitle() {
      console.log(this.value);
      let title = "";
      this.value.userRange.userInfoList.forEach((item) => {
        title += item.name + "、";
      });
      this.value.userRange.userFields.forEach((item) => {
        title += item.fieldTitle + "、";
      });
      title = title.replace(/、$/, "");
      console.log(title);
      return title;
    },
    addColumn(item, content) {
      if (this.isDisabled(item.name, content)) {
        return;
      }
      this.$refs[content.ref][0].insert(item);
      this.$refs[content.ref][0].onBlur();
      content.columnVisible = false;
    },
    addSMSColumn() {
      let item = {
        name: `field_${this.templateFieldList.length + 1}`,
        title: `field_${this.templateFieldList.length + 1}`,
      };
      this.templateFieldList.push(item);
      this.$refs.rpaSMSCode.insert(item);
      this.$refs.rpaSMSCode.onBlur();
    },
    isDisabled(name, content) {
      let patt1 = /#{[a-zA-Z0-9]+}/gm;
      let arr =
        this.value[content.template] &&
        this.value[content.template].match(patt1);
      if (!arr) {
        arr = [];
      }
      arr = arr.map((val) => val.substring(2, val.length - 1));
      return arr.includes(name);
    },
    selectUserField(field) {
      console.log(field);
      if (this.checkSelect(field)) {
        this.$message.warning("该字段已经勾选");
        return;
      }
      this.dialogVisible = false;
      this.value.userRange.userFields.push({
        fieldName: field.name,
        fieldTitle: field.title,
        componentName: field.componentName,
        collection: this.triggerFormId,
      });
    },
    handleClose() {},
    handleDialogOpened() {
      console.log(this.triggerComponents);
    },

    initAddCompanyUser() {
      this.$refs.formUserSelect.manualInit(this.value);
    },
    initAddFormUserField() {
      console.log("初始化从表单添加", this.triggerFormId);
      if (!this.triggerFormId) {
        this.$message.warning("请先选择目标表单");
        return;
      }
      this.dialogVisible = true;
    },
    changeUsers(users) {
      console.log(users);
      // this.value.userRange.userIds = users.map(user => user.id)
      this.value.userRange.userInfoList = users;
    },
  },
};
</script>

<style scoped lang="scss">
.edit-bulletin-view {
  display: flex;
  flex-direction: column;

  .el-radio-group {
    .el-radio {
      height: 34px;
      line-height: 34px;
    }
  }

  .f-ul {
    margin: 0px;
    padding: 1px;
    overflow: auto;
    //height: 400px;
    height: 0px;
    flex: 1;

    li {
      border-radius: 10px;
      font-size: 14px;
      margin: 0px;
      padding: 0px 14px;
      list-style: none;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px;
      cursor: pointer;

      &:hover {
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
      }

      &.select {
        background-color: #e6f6f4;
      }

      span {
        padding: 0 10px;
        box-sizing: border-box;
        flex: 1;
      }
    }
  }
}

.codeEdit {
  min-width: 100%;
  overflow-y: hidden !important;
  overflow-x: auto !important;

  height: 20px;

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  ::v-deep {
    pre {
      white-space: nowrap !important;
      word-wrap: normal !important;
      width: max-content;
    }
  }
}

.sms-contianer {
  margin: 20px;
  .sms-template {
    border: 1px solid #dcdfe6;
    padding: 10px;
  }
}
.template-detail {
  margin-top: 20px;
  border: 1px solid #f5f7fa;
  .template-content {
    padding: 10px 15px;
  }
}
.fieldTable {
  border: 1px solid #f5f7fa;
  margin: 0 15px;
  max-height: 300px;
  overflow: auto;
}
</style>
