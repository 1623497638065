<template>
  <div class="attr-row">
    <div class="control" @click="handleCollapse">
      <i
        :class="`iconfont icon-${
          isCollapse ? 'xialajiantou' : 'xialajiantou-1'
        }`"
      ></i>
      <span class="attr-label">更多设置</span>
    </div>
    <div v-show="!isCollapse">
      <div class="config">
        <span class="attr-label">添加按钮文案</span>
        <el-input
          v-model="customAddText"
          size="small"
          @blur="handleAddBlur"
        ></el-input>
      </div>

      <template v-if="component.componentName == 'reference_data_list'">
        <div class="config">
          <span class="attr-label">关联记录按钮文案</span>
          <el-input
            v-model="customReferText"
            size="small"
            @blur="handleReferBlur"
          ></el-input>
        </div>
        <div class="config">
          <span class="attr-label">取消按钮文案</span>
          <el-input
            v-model="customCancelReferText"
            size="small"
            @blur="handleCancelReferBlur"
          ></el-input>
        </div>
      </template>
      <div class="config" v-if="isShow">
        <div class="attr-label">卡片展示列</div>
        <el-radio-group v-model="cardDisplayColumn">
          <el-radio :label="2">两列</el-radio>
          <el-radio :label="1">一列</el-radio>
        </el-radio-group>
      </div>
      <!-- 自动刷新 -->
      <template v-if="isShowAutoRefresh">
        <div class="config">
          <span class="attr-label"
            >自动刷新 &nbsp;<el-switch v-model="autoRefresh"> </el-switch
          ></span>
          <br />
          <br />
          <el-radio-group v-if="autoRefresh" v-model="autoRefreshRule">
            <el-radio label="custom">自定义</el-radio>
            <el-radio label="numberField">数字字段</el-radio>
          </el-radio-group>
        </div>
        <div v-if="autoRefresh" class="config">
          <span class="attr-label">刷新间隔</span>
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-input
              v-if="autoRefreshRule == 'custom'"
              type="number"
              min="10"
              v-model="autoRefreshSeconds"
              size="small"
            ></el-input>
            <el-select
              v-else
              style="width: 100%"
              placeholder="请选择数字组件"
              v-model="autoRefreshNumberField"
            >
              <el-option
                v-for="item in numberColumn"
                :label="item.title"
                :value="item.name"
                :key="item.name"
              ></el-option>
            </el-select>
            <span> &nbsp;秒</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { componentProps } from "@/views/lowCode/form/attribute/util";

export default {
  name: "attr-reference-more-config",
  props: componentProps,
  computed: {
    isShowAutoRefresh() {
      //关联查询单条自动刷新设置
      if (
        this.component.componentName == "form_data" &&
        this.component.queryObject.pageSize > 1
      ) {
        return true;
      }
      return false;
    },
    isShow() {
      if (
        (this.component.componentName == "reference_data_list" &&
          this.component.displayMode &&
          this.component.displayMode != "card") ||
        (this.component.componentName == "form_data" &&
          this.component.queryObject.pageSize > 1)
      ) {
        return false;
      }
      return true;
    },
    customAddText: {
      get() {
        if (this.component.hasOwnProperty("customAddText")) {
          return this.component.customAddText;
        }
        return "添加";
      },
      set(val) {
        this.$set(this.component, "customAddText", val);
      },
    },
    customReferText: {
      get() {
        if (this.component.hasOwnProperty("customReferText")) {
          return this.component.customReferText;
        }
        return "+关联记录";
      },
      set(val) {
        this.$set(this.component, "customReferText", val);
      },
    },
    customCancelReferText: {
      get() {
        if (this.component.hasOwnProperty("customCancelReferText")) {
          return this.component.customCancelReferText;
        }
        return "取消关联";
      },
      set(val) {
        this.$set(this.component, "customCancelReferText", val);
      },
    },
    cardDisplayColumn: {
      get() {
        if (this.component.hasOwnProperty("refreshConfig")) {
          return this.component.refreshConfig.cardDisplayColumn;
        }
        return 2;
      },
      set(val) {
        this.$set(this.component.refreshConfig, "cardDisplayColumn", val);
      },
    },
    autoRefresh: {
      get() {
        console.log(
          this.component.hasOwnProperty("refreshConfig"),
          "获取地方就=======",
        );
        if (this.component.hasOwnProperty("refreshConfig")) {
          return this.component.refreshConfig.autoRefresh;
        }
        return false;
      },
      set(val) {
        let refreshConfig = {
          autoRefresh: val,
          autoRefreshRule: this.autoRefreshRule,
          autoRefreshSeconds: this.autoRefreshSeconds,
          autoRefreshNumberField: this.autoRefreshNumberField,
        };
        this.$set(this.component, "refreshConfig", refreshConfig);
      },
    },
    autoRefreshRule: {
      get() {
        if (this.component.hasOwnProperty("refreshConfig")) {
          return this.component.refreshConfig.autoRefreshRule;
        }
        return "custom"; //numberField
      },
      set(val) {
        this.component.refreshConfig.autoRefreshRule = val;
        let refreshConfig = {
          autoRefresh: this.autoRefresh,
          autoRefreshRule: val,
          autoRefreshSeconds: this.autoRefreshSeconds,
          autoRefreshNumberField: this.autoRefreshNumberField,
        };
        this.$set(this.component, "refreshConfig", refreshConfig);
      },
    },
    autoRefreshSeconds: {
      get() {
        if (this.component.hasOwnProperty("refreshConfig")) {
          return this.component.refreshConfig.autoRefreshSeconds;
        }
        return 10;
      },
      set(val) {
        if (val < 10) {
          val = 10;
        }
        let refreshConfig = {
          autoRefresh: this.autoRefresh,
          autoRefreshRule: this.autoRefreshRule,
          autoRefreshSeconds: val,
          autoRefreshNumberField: this.autoRefreshNumberField,
        };
        this.$set(this.component, "refreshConfig", refreshConfig);
      },
    },
    autoRefreshNumberField: {
      get() {
        if (this.component.hasOwnProperty("refreshConfig")) {
          return this.component.refreshConfig.autoRefreshNumberField;
        }
        return "";
      },
      set(val) {
        let refreshConfig = {
          autoRefresh: this.autoRefresh,
          autoRefreshRule: this.autoRefreshRule,
          autoRefreshSeconds: this.autoRefreshSeconds,
          autoRefreshNumberField: val,
        };
        this.$set(this.component, "refreshConfig", refreshConfig);
      },
    },
    numberColumn() {
      if (this.form && this.form.components) {
        return this.form.components.filter(
          (item) => item.componentName == "input_number",
        );
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  created() {
    console.log(this.component, "蝴蝶飞=====", this.numberColumn);
  },
  methods: {
    handleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleAddBlur() {
      if (!this.customAddText) {
        this.$message.warning("添加按钮文案不能为空");
        this.customAddText = "添加";
      }
    },
    handleReferBlur() {
      if (!this.customReferText) {
        this.$message.warning("关联记录按钮文案");
        this.customReferText = "+ 关联记录";
      }
    },
    handleCancelReferBlur() {
      if (!this.customCancelReferText) {
        this.$message.warning("取消按钮文案");
        this.customCancelReferText = "取消关联";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.control {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.config {
  padding-left: 20px;
  margin: 10px 0;
}
</style>
