<template>
  <div style="background-color: #fff; height: 100%">
    <div class="templateConfig">
      <div class="header">
        <div class="header-left">
          <h1 style="color: #172b4d">单位目录</h1>
          <!-- <span style="color:#6B778C">前台体验新版布局 </span>
          &nbsp;&nbsp;
          <el-switch
            @change="saveSetting"
            v-model="enabledNewLayout"
            active-color="#3870EA"
          >
          </el-switch> -->
          <el-tooltip
            class="item"
            effect="dark"
            content="选择样式"
            placement="top-start"
          >
            <el-popover
              placement="bottom-start"
              v-model="visibleSelect"
              title="选择样式"
              trigger="click"
            >
              <div>
                <div class="select-mode">
                  <div class="item" @click="enabledNewLayout1 = true">
                    <img
                      :class="enabledNewLayout1 == true ? 'actived' : ''"
                      src="@/assets/mode/application.png"
                      alt=""
                    />
                    <el-radio
                      style="margin-top: 8px"
                      v-model="enabledNewLayout1"
                      :label="true"
                      >应用模式</el-radio
                    >
                  </div>
                  <div
                    class="item"
                    @click="enabledNewLayout1 = false"
                    style="margin-left: 32px"
                  >
                    <img
                      :class="enabledNewLayout1 == false ? 'actived' : ''"
                      src="@/assets/mode/tree.png"
                      alt=""
                    />
                    <el-radio
                      style="margin-top: 8px"
                      v-model="enabledNewLayout1"
                      :label="false"
                      >树状模式</el-radio
                    >
                  </div>
                </div>
                <div class="btns">
                  <el-button size="small" @click="cancelSetting" block
                    >取消
                  </el-button>
                  <el-button
                    size="small"
                    style="margin-left: 12px"
                    @click="saveSetting"
                    type="primary"
                    >确定
                  </el-button>
                </div>
              </div>
              <el-button style="border: 0; padding: 6px 10px" slot="reference">
                <i
                  class="iconfont icon-list-settings-fill"
                  style="font-size: 24px; margin-right: 0"
                ></i
              ></el-button>
            </el-popover>
          </el-tooltip>
          <div class="enabledText">
            （{{ enabledNewLayout1 ? "当前为应用模式" : "当前为树状模式" }}）
          </div>
        </div>
        <div class="header-right">
          <el-input
            placeholder="搜索"
            prefix-icon="el-icon-search"
            v-model="titleKeyword"
            size="small"
            @input="search"
            clearable
            style="margin-right: 10px"
          >
          </el-input>
          <div v-if="enabledNewLayout">
            <el-button
              @click.native="addFolderAplication('add')"
              size="large"
              style="margin-right: 20px"
              type="primary"
            >
              新建
            </el-button>
          </div>
          <div v-else>
            <el-dropdown>
              <el-button size="large" style="margin-right: 20px" type="primary">
                新建<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="addWorkGroup"
                  >添加工作群
                </el-dropdown-item>
                <el-dropdown-item @click.native="addFolder"
                  >添加文件夹
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-label">
          设置首页（选择应用下的仪表盘群设为首页）
        </div>
        <el-tree
          :data="homePageTreeData"
          :expand-on-click-node="true"
          :highlight-current="true"
          :indent="10"
          :auto-expand-parent="false"
          :props="defaultProps"
          class="my-plan-tree"
          node-key="id"
          :allow-drop="allowDrop"
          @node-drop="handleHomePageDrop"
          draggable
        >
          <span
            slot-scope="{ node, data }"
            class="custom-tree-node"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <span title="选择图标" class="node-label">
              <div @click="modifyIcon(data, node, 'homepage')">
                <template
                  v-if="
                    data?.param?.iconStyle &&
                    data?.param?.iconStyle?.type !== null
                  "
                >
                  <resource-icon
                    :data="data"
                    :enabledNewLayout="enabledNewLayout1"
                  ></resource-icon>
                </template>

                <template v-else>
                  <template v-if="data.dataType === 'folder'">
                    <svg-icon
                      iconClass="wenjianjia"
                      style="margin-right: 5px; font-size: 16px"
                    ></svg-icon>
                  </template>
                  <i
                    v-if="data.param.componentName === 'dashboard'"
                    class="iconfont icon-yibiaopan"
                    style="color: #52c41a"
                  ></i>
                </template>
              </div>
              <span>{{ data.title }} </span>
            </span>
            <div
              :class="`content-right right-${data.id}`"
              v-if="data.param.componentName == 'dashboard' || enabledNewLayout"
            >
              <el-dropdown
                trigger="click"
                @visible-change="visibleChange($event, data, 'dots')"
              >
                <span class="el-dropdown-link" @click.stop>
                  <i
                    class="iconfont icon-dots-vertical custom-icon"
                    style="cursor: pointer"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="editHomePageInfo('homepage', data, node)"
                    v-if="data.param.componentName != 'dashboard'"
                    >修改信息</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="cancelSetAsHomePage(data, node)"
                    v-if="data.param.componentName === 'dashboard'"
                    >取消首页</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </span>
        </el-tree>
        <div class="content-label">应用</div>
        <el-tree
          ref="resTree"
          :data="treeData"
          :expand-on-click-node="true"
          :highlight-current="true"
          :indent="10"
          :auto-expand-parent="false"
          :props="defaultProps"
          class="my-plan-tree"
          node-key="id"
          :default-expanded-keys="defaultExpandedKeysUniq"
          @node-expand="handleNodeExpand"
          @node-collapse="handleNodeCollapse"
          draggable
          @node-drop="handleDrop"
          :allow-drag="handleAllowDrag"
          :allow-drop="handleAllowDrop"
          @node-drag-enter="handleNodeDragEnter"
          @node-drag-start="handleNodeDragStart"
          :filter-node-method="filterNode"
        >
          <span
            slot-scope="{ node, data }"
            class="custom-tree-node"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <span title="选择图标" class="node-label">
              <div
                @click="modifyIcon(data, node)"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div
                  style="color: #b3b3b3; font-size: 30px"
                  v-if="data.dataType !== 'folder'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                    data-id="nb3CbPo8394vPLoGksNmsR"
                    data-database-id=""
                    data-favorite="false"
                    data-share="false"
                    data-area="tree-menu"
                  >
                    <circle cx="10" cy="10" r="1.5" fill="#b3b3b3"></circle>
                  </svg>
                </div>
                <template
                  v-if="
                    data?.param?.iconStyle &&
                    data?.param?.iconStyle.type != null
                  "
                >
                  <resource-icon
                    :data="data"
                    :enabledNewLayout="enabledNewLayout1"
                  ></resource-icon>
                </template>
                <template v-else>
                  <svg-icon
                    v-if="data.dataType === 'folder'"
                    iconClass="wenjianjia"
                    style="margin-right: 5px; font-size: 16px"
                  ></svg-icon>
                  <i
                    v-else-if="
                      data.dataType === 'resource' &&
                      data.param.componentName === 'table'
                    "
                    style="color: rgb(178, 154, 232)"
                    class="iconfont icon-biaodanqun"
                  ></i>
                  <i
                    v-else-if="
                      data.dataType === 'resource' &&
                      data.param.componentName === 'plan_form_view'
                    "
                    style="color: rgb(74, 18, 199)"
                    class="iconfont icon-biaodanqun"
                  ></i>
                  <i
                    v-else-if="
                      data.dataType === 'resource' &&
                      data.param.componentName === 'dashboard'
                    "
                    class="iconfont icon-yibiaopan"
                    style="color: #52c41a"
                  ></i>
                </template>
              </div>
              <span>{{ data.title }}</span
              >&nbsp;

              <span
                v-if="data.hidden"
                style="color: #a5adba"
                class="iconfont icon-eye-off-fill"
              ></span>
            </span>

            <div :class="`content-right right-${data.id}`">
              <el-dropdown
                trigger="click"
                @visible-change="visibleChange($event, data, 'plus')"
                v-if="data.dataType === 'folder'"
              >
                <span class="el-dropdown-link" @click.stop>
                  <i
                    class="el-icon-plus custom-icon"
                    style="cursor: pointer; font-weight: 700"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="addWorkGroup(data, node)"
                    >添加工作群</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="!enabledNewLayout"
                    @click.native="addChildFolder(data, node)"
                    >添加子文件夹</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                trigger="click"
                @visible-change="visibleChange($event, data, 'dots')"
              >
                <span class="el-dropdown-link" @click.stop>
                  <i
                    class="iconfont icon-dots-vertical custom-icon"
                    style="cursor: pointer"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="data.dataType === 'resource' && data.userId == 0"
                    @click.native="setAdministrator(data, node)"
                    >设置管理员</el-dropdown-item
                  >
                  <template
                    v-if="enabledNewLayout && data.dataType === 'folder'"
                  >
                    <el-dropdown-item
                      @click.native="addFolderAplication('rename', data, node)"
                      >图标和名称</el-dropdown-item
                    >
                  </template>
                  <template v-else>
                    <el-dropdown-item @click.native="modifyIcon(data, node)"
                      >图标和名称</el-dropdown-item
                    >
                  </template>
                  <el-dropdown-item @click.native="handleMove(data, node)"
                    >移动到</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="setAsHomePage(data, node)"
                    v-if="data.param.componentName === 'dashboard'"
                  >
                    <span style="color: #bbb" v-if="hasSetHomepage(data)"
                      >已设首页</span
                    >
                    <template v-else>
                      <span :style="data.hidden ? 'color: #bbb' : ''"
                        >设为首页</span
                      >
                    </template>
                    &nbsp;
                    <el-tooltip class="item" effect="dark" placement="right">
                      <div slot="content" style="line-height: 20px">
                        设为首页后，该仪表盘将<br />不会在应用/文件夹下显示
                      </div>
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-dropdown-item>

                  <el-dropdown-item
                    :disabled="hasSetHidden(data, node)"
                    @click.native="handleHidden(data, node)"
                  >
                    <span v-if="data.hidden">取消隐藏</span>
                    <span v-else>从导航中隐藏</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="data.dataType === 'folder' || data.userId == 0"
                    @click.native="handleDelete(data, node)"
                    >删除</el-dropdown-item
                  >
                  <!--              || (data.dataType === 'resource' && data.userId == 0)  删除资源  以后可能会添加-->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </span>
        </el-tree>
      </div>

      <!--        <el-empty description="暂无数据" v-if=""></el-empty>-->
      <!--        <el-tree-->
      <!--            ref="tree"-->
      <!--            :expand-on-click-node="false"-->
      <!--            :highlight-current="true"-->
      <!--            :indent="10"-->
      <!--            :props="defaultProps"-->
      <!--            class="platform-excel-tree"-->
      <!--            node-key="id"-->
      <!--            show-checkbox-->
      <!--            @check-change="handleCheckChange"-->
      <!--        >-->
      <!--        <span slot-scope="{ node, data }" class="custom-tree-node">-->
      <!--          <span :title="data.title" class="node-label">-->
      <!--            &lt;!&ndash;          <i class="el-icon-folder" v-if="data.type === 10"></i>&ndash;&gt;-->
      <!--            <svg-icon-->
      <!--                v-if="data.type === 10"-->
      <!--                iconClass="wenjianjia"-->
      <!--                style="margin-right: 5px;font-size: 16px;"-->
      <!--            ></svg-icon>-->
      <!--            <i v-if="data.type === 20" class="el-icon-document"></i>-->
      <!--            <span>{{ data.title }}</span>-->
      <!--          </span>-->
      <!--        </span>-->
      <!--        </el-tree>-->

      <!--    添加工作群-->
      <el-dialog
        class="addWorkGroup"
        :visible.sync="addWorkGroupVisible"
        :close-on-click-modal="false"
        title="添加工作群"
        width="600px"
        :before-close="handleClose"
      >
        <div class="add-group-dialog">
          <div style="width: 90%">
            <p>群类型</p>
            <el-select
              @change="handleSelectGroup"
              v-model="groupType"
              placeholder="请选择"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in groupTypeList"
                :key="item.recordName"
                :label="item.recordName"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="select-work-group"
            style="width: 90%"
            v-if="groupType && groupType != 80"
          >
            <p>选择工作群</p>
            <el-select
              ref="workGroupSelector"
              class="work-group-selector"
              @click.native="handleWorkGroupClick"
              v-model="workGroupValue"
              size="small"
              readonly
              multiple
              style="width: 100%"
              placeholder="请选择"
            >
            </el-select>
          </div>
          <div
            class="select-work-group"
            style="width: 90%"
            v-if="groupType && groupType == 80"
          >
            <p>标题</p>
            <el-input size="small" v-model="viewTitle"></el-input>
          </div>
          <div style="width: 90%">
            <p>设置群管理员</p>
            <userSelect
              v-if="addWorkGroupVisible"
              dialogTitle="选择管理员"
              :multiple="false"
              v-model="adminUserId"
              :user-custom-info="currentUserMap"
              size="small"
              :only-company="true"
              :company-ids="[$store.state.user.currentCompanyId]"
              :hideOuterClose="true"
            ></userSelect>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeGroupVisible">取 消</el-button>
          <el-button
            size="small"
            type="primary"
            :loading="addWorkGrouping"
            @click="handleNewGroup"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <userSelect
        dialogTitle="选择管理员"
        ref="formUserSelect"
        :multiple="false"
        @changeUsers="handleUserChange"
        v-model="adminUserId"
        :user-custom-info="currentUserMap"
        size="small"
        :only-company="true"
        :hide-outer="true"
        :company-ids="[$store.state.user.currentCompanyId]"
      ></userSelect>

      <el-dialog
        title="移动"
        :visible.sync="folderSelectDialogVisible"
        width="520px"
        :before-close="handleBeforeCLose"
        class="folderSelectDialog"
      >
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px;
          "
        >
          <p>请选择目标文件夹：</p>
          <selectFolder
            @change="handleFolderSelectChange"
            @close="handleFolderSelectClose"
            v-if="folderSelectDialogVisible"
            :type="type"
            :current-node="currenMoveNode"
          ></selectFolder>
        </div>
      </el-dialog>

      <!--      选择工作群-->
    </div>
    <el-dialog
      v-if="selectWorkGroupDialog"
      title="选择工作群"
      :visible.sync="selectWorkGroupDialog"
      width="600px"
      class="select-word-group-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="workgroup-body">
        <div class="left">
          <i
            class="el-icon-search"
            style="position: absolute; top: 15px; left: 15px"
          ></i>
          <input
            v-model="filterValue"
            @input="filterHandler"
            class="workgroup-search"
            type="text"
            placeholder="输入名称搜索"
          />
          <el-scrollbar style="flex: 1">
            <el-tree
              ref="tree"
              show-checkbox
              :highlight-current="true"
              :auto-expand-parent="true"
              node-key="id"
              :props="workGroupTreeProps"
              :default-checked-keys="saveWorkGroupIds"
              class="my-work-tree"
              :expand-on-click-node="true"
              :accordion="false"
              :indent="10"
              :filter-node-method="filterNode"
              @check-change="nodeCheckChange"
              :data="workGroupTreeData"
            >
              <div class="node-wrap" slot-scope="{ node, data }">
                <svg-icon
                  iconClass="wenjianjia"
                  id="folder"
                  v-if="data.dataType == 'folder'"
                  style="margin-right: 5px; font-size: 16px"
                ></svg-icon>
                <i
                  class="iconfont icon-FileText"
                  style="color: #3c73ea"
                  v-if="data.dataType == 'resource' && data.type == 10"
                ></i>
                <i
                  class="iconfont icon-biaodanqun"
                  style="color: #9b51e0"
                  v-if="data.dataType == 'resource' && data.type == 20"
                ></i>
                <i
                  class="iconfont icon-Robot"
                  v-if="
                    data.dataType == 'resource' && [30, 50].includes(data.type)
                  "
                ></i>
                <i
                  class="iconfont icon-yibiaopan"
                  style="color: #52c41a"
                  v-if="data.dataType == 'resource' && data.type == 40"
                ></i>
                <div class="node-label-wrap">
                  <div class="node-label-content" :title="node.label">
                    {{ node.label }}
                  </div>
                  <slot
                    v-if="data.dataType == 'resource'"
                    name="label"
                    :node="node"
                    :data="data"
                  >
                  </slot>
                  <!--          <div v-if="data.defaultDataShareRange == 'platform'" style="transform:scale(0.8);height:20px;line-height:20px;white-space:nowrap;background: #f0f9eb;border: 1px solid #e1f3d8;box-sizing: content-box;border-radius: 51px;color: #67c23a;font-size: 10px;padding: 0 4px">标准</div>-->
                </div>

                <div class="node-button-manage">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      right: 10px;
                    "
                  ></div>
                </div>
              </div>
            </el-tree>
          </el-scrollbar>
        </div>
        <div class="right">
          <el-scrollbar>
            <draggable
              v-model="selectedWorkGroup"
              handle=".icon-yidong"
              animation="300"
            >
              <div
                v-for="item in selectedWorkGroup"
                :key="item.id"
                class="drag-field-item"
              >
                <span class="item-name" :title="item.title">
                  <i
                    class="iconfont icon-FileText"
                    style="color: #3c73ea"
                    v-if="item.dataType == 'resource' && item.type == 10"
                  ></i>
                  <i
                    class="iconfont icon-biaodanqun"
                    style="color: #9b51e0"
                    v-if="item.dataType == 'resource' && item.type == 20"
                  ></i>
                  <i
                    class="iconfont icon-Robot"
                    v-if="
                      item.dataType == 'resource' &&
                      [30, 50].includes(item.type)
                    "
                  ></i>
                  <i
                    class="iconfont icon-yibiaopan"
                    style="color: #52c41a"
                    v-if="item.dataType == 'resource' && item.type == 40"
                  ></i>
                  {{ item.title }}</span
                >
                <div class="item-operate">
                  <i class="iconfont icon-yidong" style="font-size: 15px"></i>
                  <i
                    class="iconfont icon-delete-bin-line"
                    style="font-size: 15px"
                    @click="deleteNode(item, item.id)"
                  ></i>
                </div>
              </div>
            </draggable>
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="mini">取消</el-button>
        <el-button type="primary" @click="ok" size="mini">确定</el-button>
      </span>
    </el-dialog>

    <!--应用模式新建文件夹-->
    <el-dialog
      :title="addFolderTitle"
      :visible.sync="addFolderDialogVisible"
      width="450px"
      :before-close="handleCLoseAddFolder"
      class="folderSelectDialog"
    >
      <add-aplication-folder
        v-if="addFolderDialogVisible"
        @chageIcon="chageIcon"
        @updateName="updateName"
        :folderData="folderData"
        :isHomepage="isHomepage"
        :enabledNewLayout="enabledNewLayout1"
        :opName="opName"
      ></add-aplication-folder>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCLoseAddFolder">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="addFolderAplicationSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="recycle-btn">
      <el-button
        type="text"
        class="iconfont icon-a-recycle-line"
        @click="showRecycleBin"
        v-if="hasResRecycleAuth()"
        >回收站</el-button
      >
    </div>
    <recycle-bin-dialog
      :visible.sync="recycleBinVisible"
      v-if="recycleBinVisible"
      title="回收站"
      type="workDir"
      @refresh="getWorkDirTree"
    ></recycle-bin-dialog>
  </div>
</template>

<script>
import {
  workDirTree,
  workDirRegister,
  workDirRename,
  workDirMove,
  workDirDelete,
  workDirDeleteRes,
  listRecordType,
  moveRes,
  renameRes,
  viewCreate,
  planFormViewCreate,
  companyList,
  companyEmployeeList,
  resetAdmin,
  workDirSaveSetting,
  workDirLoadSetting,
  modifyTitleAndIconDir,
  modifyTitleAndIconDirRes,
  workDirHomePageTree,
  workDirResToHomepage,
  workDirCancelHomepage,
  workDirSortHomepage,
  setHidden,
} from "@/api/directoryConfig";
import selectFolder from "@/views/lowCode/templateConfig/selectFolder";
import addAplicationFolder from "@/views/lowCode/templateConfig/floder-icon/add-aplication-folder";
import tree from "@/views/lowCode/form/tree";
import _ from "lodash";
import { mapGetters } from "vuex";
import userSelect from "@/views/lowCode/templateConfig/user-select";
import {
  workDirMoveResource,
  workDirSort,
  workDirSortRes,
} from "@/views/lowCode/templateConfig/api";
import Draggable from "vuedraggable";
import { dirTree } from "@/views/lowCode/api";
import recycleBinDialog from "@/views/lowCode/form/recycle-bin-dialog.vue";
import { hasRoleAuth } from "@/utils/roleAuth";
import resourceIcon from "@/views/lowCode/templateConfig/floder-icon/resource-icon";
import dataLinkageVue from "../form/component/dataLinkage.vue";
const RESOURCE = "resource";
const FOLDER = "folder";
const COMPANY_FOLDER = 10;
const PERSONAL_FOLDER = 20;
export default {
  name: "index",
  components: {
    selectFolder,
    tree,
    userSelect,
    addAplicationFolder,
    Draggable,
    recycleBinDialog,
    resourceIcon,
  },
  props: {
    defaultProps: {
      type: Object,
      default: () => {
        return {
          label: "name",
          children: "directoryList",
          isLeaf: function (data, node) {
            return !data.hasChildList;
          },
        };
      },
    },
  },
  data() {
    return {
      workGroupTreeProps: {
        children: "directoryList",
        label: "title",
        isLeaf: function (data, node) {
          return !data.hasChildList || data.dataType == "resource";
        },
        // disabled: (data, node) => {
        //   return data.dataType === "folder";
        // },
      },
      filterValue: "",
      workGroupValue: "",
      selectWorkGroupDialog: false,
      workGroup: "",
      groupType: "",
      groupTypeList: [],
      workGroupTreeData: [],
      folderSelectDialogVisible: false,
      addWorkGroupVisible: false,
      setAdministratorDialog: false,
      activeName: "",
      keyWord: "",
      renameValue: "",
      treeData: [],
      homePageTreeData: [],
      type: 10,
      currentFolderId: "",
      currenMoveNode: undefined,
      dataType: "",
      defaultExpandedKeys: [],
      focusIpt: false,
      viewId: "",
      dirId: "",
      employeeList: [],
      innerSelectUser: [],
      companyTreeList: [],
      defaultProps2: {
        children: "childList",
        label: "name",
        isLeaf: "isLeaf",
      },
      adminUserId: "",
      typeNum: "",
      viewTitle: "",
      adminName: "",
      recordId: "",
      currentUserMap: {},
      lastPushId: undefined,
      enabledNewLayout: false, //体验新版布局
      addFolderDialogVisible: false, //应用模式新增文件夹弹出窗
      folderData: {},
      iconStyle: {},
      folderTitle: "",
      opName: "新建",
      visibleSelect: false,
      enabledNewLayout1: false,
      isHomepage: false,
      selectedWorkGroup: [],
      saveWorkGroup: [],
      recycleBinVisible: false,
      showRight: -1,
      addWorkGrouping: false,
      titleKeyword: "",
    };
  },

  watch: {
    "$store.state.user.currentCompanyId": {
      handler(newVal) {
        this.reset();
      },
    },
  },

  created() {
    // 请求数据
    this.loadSetting();
    this.getWorkDirTree();
    this.adminUserId = this.$store.state.user.currentUser.id;
  },

  computed: {
    saveWorkGroupIds() {
      return this.saveWorkGroup.map((item) => item.id);
    },
    saveWorkGroupKeys() {
      return this.saveWorkGroup.map((item) => item.bizId);
    },
    defaultExpandedKeysUniq: function () {
      return this._.uniq(this.defaultExpandedKeys);
    },
    ...mapGetters(["name"]),
    addFolderTitle() {
      return this.opName == "homepage"
        ? "修改信息"
        : this.opName == "rename"
        ? "修改图标和名称"
        : this.opName == "modifyIcon"
        ? "修改图标和名称"
        : "新建";
    },
  },
  methods: {
    search() {
      this.timer = null;
      this.timer = setTimeout(() => {
        this.$refs?.resTree?.filter(this.titleKeyword);
      }, 500);
    },
    hasResRecycleAuth() {
      return hasRoleAuth("ops_auth_menu_res_recycle");
    },
    showRecycleBin() {
      this.recycleBinVisible = true;
    },
    filterNode(value, data) {
      let { dataType, title } = data;
      if (title.toUpperCase().indexOf(value.toUpperCase()) == -1) {
        return false;
      }
      return true;
    },
    filterHandler() {
      this.$refs.tree.filter(this.filterValue);
    },
    clearSelectWorkGroup() {
      this.saveWorkGroup = [];
      this.workGroupValue = [];
    },
    cancel() {
      this.filterValue = "";
      this.selectWorkGroupDialog = false;
    },
    ok() {
      this.saveWorkGroup = this.selectedWorkGroup;
      this.workGroupValue = this.saveWorkGroup.map((item) => item.title);
      this.filterValue = "";
      this.selectWorkGroupDialog = false;
    },
    hasSetHomepage(data) {
      if (this.homePageTreeData[0]) {
        let homepageList = this.homePageTreeData[0].directoryList;
        if (homepageList) {
          return homepageList.some((item) => item.id == data.id);
        }
      }
    },
    hasSetHidden(data) {
      let { parentId, dataType } = data;
      if (dataType == "folder") {
        return false;
      } else {
        let parentNode = this.findNodeById(this.treeData, parentId);
        if (parentNode?.hidden) {
          return true;
        } else {
          if (this.hasSetHomepage(data)) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    //查找父级node
    findNodeById(treeData, id) {
      for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i];

        if (node.id === id) {
          return node;
        } else if (Array.isArray(node.children)) {
          const result = this.findNodeById(node.children, id);
          if (result !== null) {
            return result;
          }
        }
      }

      // 若未找到符合条件的节点，则返回null
      return null;
    },

    //首页排序
    handleHomePageDrop(draggingNode, dropNode, dropType, event) {
      let preResId;
      if (dropType == "after") {
        preResId = dropNode.data.id;
      } else {
        preResId = "0";
      }
      workDirSortHomepage({
        resId: draggingNode.data.id,
        preResId,
      });
    },
    //首页不允许拖拽至目标节点内
    allowDrop(draggingNode, dropNode, type) {
      return type === "inner" ? false : true;
    },
    editHomePageInfo(name, data) {
      this.isHomepage = true;
      this.folderData = data;
      this.opName = name;
      this.addFolderDialogVisible = true;
    },
    cancelSetAsHomePage(data) {
      workDirCancelHomepage({
        resId: data.id,
      }).then(() => {
        this.$message.success("取消成功");
        this.getHomePageTreeData();
      });
    },
    setAsHomePage(data) {
      if (this.hasSetHomepage(data) || data.hidden) {
        return false;
      }
      workDirResToHomepage({
        resId: data.id,
      }).then(() => {
        this.$message.success("设置成功");
        this.getHomePageTreeData();
      });
    },

    getHomePageTreeData() {
      let params = {
        layoutTypeStr: this.enabledNewLayout ? "horizontal_layout" : "",
      };
      workDirHomePageTree(params).then((res) => {
        this.homePageTreeData = res.data.directoryList;
      });
    },

    pushExpandedKey(id) {
      this.removeExpandedKey(this.lastPushId);
      this.defaultExpandedKeys.push(id);
      this.lastPushId = id;
    },
    removeExpandedKey(id) {
      let index2 = this.defaultExpandedKeys.indexOf(id);
      if (index2 != -1) {
        this.defaultExpandedKeys.splice(index2, 1);
      }
    },
    handleNodeDragStart(node, event) {
      this.removeExpandedKey(node.data.id);
      this.pushExpandedKey(node.data.parentId);
    },
    findNearestComponent(element, componentName) {
      let target = element;
      while (target && target.tagName !== "BODY") {
        if (target.__vue__ && target.__vue__.$options.name === componentName) {
          return target.__vue__;
        }
        target = target.parentNode;
      }
      return null;
    },
    handleNodeDragEnter(dragNode, dropNode, event) {
      // if (dropNode.key === dragNode.key) {
      //   return
      // }
      // const dropNode2 = this.findNearestComponent(event.target, 'ElTreeNode')
      // const iconEl = dropNode2.$el.querySelector('.el-tree-node__expand-icon')
      // const targetPosition = dropNode2.$el
      //     .querySelector('.el-tree-node__content')
      //     .getBoundingClientRect()
      // iconEl.style.height = targetPosition.height - 5 + 'px'
    },
    //处理节点是否允许拖拽
    handleAllowDrag(draggingNode) {
      // if (
      //     !this.isCompanyAdmin &&
      //     draggingNode.data.dataType == FOLDER &&
      //     draggingNode.data.type == COMPANY_FOLDER
      // ) {
      //   return false
      // }

      return true;
    },
    //处理目录节点是否允许置入
    //参数有三种情况：'prev'、'inner' 和 'next'，
    handleAllowDrop(draggingNode, dropNode, type) {
      let dragNodeData = draggingNode.data;
      let dropNodeData = dropNode.data;
      /** 应用模式下, 文件夹不能拖到另一个文件夹里 **/
      if (this.enabledNewLayout1) {
        if (
          dragNodeData.dataType == FOLDER &&
          dropNodeData.dataType == FOLDER &&
          type == "inner"
        ) {
          return false;
        }
        if (
          dragNodeData.dataType == FOLDER &&
          dropNodeData.dataType == RESOURCE &&
          dropNodeData.parentId != "0"
        ) {
          return false;
        }
      }
      //不允许置入到群节点
      if (dropNodeData.dataType == RESOURCE && type == "inner") {
        return false;
      }
      if (
        dropNodeData.dataType == FOLDER &&
        dragNodeData.dataType == RESOURCE &&
        type == "inner"
      ) {
        return true;
      }
      if (
        dropNodeData.dataType == FOLDER &&
        dragNodeData.dataType == FOLDER &&
        dragNodeData.type != dropNodeData.type
      ) {
        return false;
      }
      return true;
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log(999999);
      let draggingNodeRecordType = draggingNode.data.dataType;
      let dropNodeRecordType = dropNode.data.dataType;
      //如果是工作群，只能用move接口
      if (draggingNodeRecordType == "resource") {
        let param;
        if (dropType == "inner") {
          param = {
            resId: draggingNode.data.id,
            dirId: dropNode.data.id,
          };
          param.companyId = this.$store.getters.currentCompanyId || 0;
          workDirMoveResource(param).then((resp) => {
            // this.fetchWorkTree()
          });
        } else {
          let childNode = dropNode.parent.childNodes.filter(
            (item) => item.data.dataType == "resource",
          );
          let index = childNode.findIndex(
            (item) => item.data.id == draggingNode.data.id,
          );
          let preId;
          if (index >= 1) {
            preId = childNode[index - 1].data.id;
          } else {
            preId = 0;
          }
          param = {
            resId: draggingNode.data.id,
            parentId: dropNode.parent.level != 0 ? dropNode.parent.data.id : 0,
            preResId: preId,
          };
          workDirSortRes(param).then((resp) => {
            this.getWorkDirTree();
          });
        }
      } else if (draggingNodeRecordType == "folder") {
        let param;
        if (dropType == "inner") {
          param = {
            dirId: draggingNode.data.id,
            parentId: dropNode.data.id,
          };
          workDirMove(param).then((resp) => {});
        } else {
          let childNode = dropNode.parent.childNodes.filter(
            (item) => item.data.dataType == "folder",
          );
          let index = childNode.findIndex(
            (item) => item.data.id == draggingNode.data.id,
          );
          let preId;
          if (index >= 1) {
            preId = childNode[index - 1].data.id;
          } else {
            preId = 0;
          }
          param = {
            dirId: draggingNode.data.id,
            parentId: dropNode.parent.level != 0 ? dropNode.parent.data.id : 0,
            preDirId: preId,
          };
          workDirSort(param).then((resp) => {
            this.getWorkDirTree();
          });
        }
      }
    },
    /**
     * 处理设置管理员的用户选择弹窗的确认返回
     * @param val
     */
    handleUserChange(val) {
      if (val && val.length > 0) {
        this.adminUserId = val[0].id;
        resetAdmin({
          recordType: "app_view",
          recordId: this.recordId,
          userIds: JSON.stringify([this.adminUserId]),
        })
          .then((resp) => {
            this.getWorkDirTree();
            this.$message.success("设置成功");
          })
          .catch((err) => {
            this.$message.warning("设置失败");
          });
      }
    },
    selectAdmin(row) {
      this.adminUserId = row.targetUserId;
      this.adminName = row.name;
    },
    reset() {
      this.loadSetting();
      this.getWorkDirTree();
    },

    handleClose() {
      this.groupType = "";
      this.workGroupValue = "";
      this.viewId = "";
      this.clearSelectWorkGroup();
      this.addWorkGroupVisible = false;
    },
    closeGroupVisible() {
      this.handleClose();
    },

    handleNodeClick(data, node, el) {
      if (data.nodeType && data.nodeType === "company") {
        this.fetchData(data.id, 0);
      } else {
        this.fetchData(data.companyId, data.id);
      }
    },
    fetchData(companyId, departmentId) {
      let params = {
        companyId,
        departmentId,
      };
      companyEmployeeList(params)
        .then((res) => {
          this.employeeList = res.data.page.list;
        })
        .catch((err) => {});
    },

    handleCheckStatus(row) {
      if (this.innerSelectUser.find((item) => item.id == this.getRowId(row))) {
        row.checked = true;
      } else {
        row.checked = false;
      }
      return row.checked;
    },

    handleNewGroup() {
      let createNewGroup;
      if (this.groupType == 80) {
        createNewGroup = planFormViewCreate;
      } else {
        createNewGroup = viewCreate;
      }

      this.addWorkGrouping = true;
      createNewGroup({
        title: this.workGroupValue.join(","),
        viewId: this.saveWorkGroupKeys.join(","),
        dirId: this.dirId,
        adminUserId: this.adminUserId,
        // intelligentCollection:选填字段
      })
        .then((res) => {
          this.getWorkDirTree();
          this.$message.success("添加成功!");
          this.handleClose();
          this.clearSelectWorkGroup();
        })
        .catch((err) => {
          this.clearSelectWorkGroup();
        })
        .finally(() => (this.addWorkGrouping = false));
    },

    handleFocus() {
      this.focusIpt = true;
    },

    handleBlur() {
      this.focusIpt = false;
    },
    deleteNode(node, key) {
      if (!node && !key) {
        return;
      }
      this.selectedWorkGroup = this.selectedWorkGroup.filter(
        (item) => item.id !== key,
      );
      this.$refs.tree.setChecked(key || node, false);
    },
    nodeCheckChange(obj, status) {
      this.selectedWorkGroup = [];
      let nodes = this.$refs.tree
        .getCheckedNodes()
        .filter((node) => node.dataType === "resource");
      this.selectedWorkGroup.push(...nodes);
    },
    nodeClick(data, node) {
      let { dataType, title, bizId } = data;
      if (dataType === "resource") {
        this.workGroupValue = title;
        this.viewTitle = this.workGroupValue;
        this.viewId = bizId;
        this.selectWorkGroupDialog = false;
      }
    },
    handleWorkGroupClick() {
      this.selectedWorkGroup = this.saveWorkGroup;
      this.selectWorkGroupDialog = true;
      this.$refs.workGroupSelector.blur();
    },
    handleBeforeCLose() {
      this.folderSelectDialogVisible = false;
    },
    // 选择群类型
    handleSelectGroup(type) {
      this.workGroupValue = "";
      this.viewId = "";
      this.typeNum = type;
      this.groupType = type;
      this.clearSelectWorkGroup();
      this.fetchTree();
    },
    async fetchTree(parentId = 0) {
      let params = {
        type: this.typeNum,
        parentId,
        fullTree: true,
        keyword: this.keyWord,
      };
      await dirTree(params).then((resp) => {
        this.workGroupTreeData = resp.data.directoryList;
      });
    },
    handleFolderSelectChange(parentId) {
      if (this.dataType === "folder") {
        workDirMove({
          dirId: this.currentFolderId,
          parentId,
        }).then((res) => {
          this.getWorkDirTree();
        });
      } else if (this.dataType === "resource") {
        let parentNode = this.findNodeById(this.treeData, parentId);
        let isHidden = this.currenMoveNode.data.hidden;

        if (parentNode.hidden && !isHidden) {
          this.$confirm("移动后，受上级权限影响，将从导航中隐藏", {
            title: "可见权限变更",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              moveRes({
                resId: this.currentFolderId,
                dirId: parentId,
              }).then((res) => {
                this.getWorkDirTree();
              });
              this.folderSelectDialogVisible = false;
            })
            .catch((err) => {
              return;
            });
        } else {
          moveRes({
            resId: this.currentFolderId,
            dirId: parentId,
          }).then((res) => {
            this.getWorkDirTree();
          });
          this.folderSelectDialogVisible = false;
        }
      }
    },
    handleFolderSelectClose() {
      this.folderSelectDialogVisible = false;
    },
    getWorkDirTree() {
      let params = {
        layoutTypeStr: this.enabledNewLayout ? "horizontal_layout" : "",
      };
      workDirTree(params).then((res) => {
        this.treeData = res.data.directoryList;
      });
      this.getHomePageTreeData();
    },

    // 添加文件夹
    addFolder() {
      this.$prompt("", "新建", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          workDirRegister({
            title: value,
          }).then((res) => {
            this.$message.success("创建成功!");
            this.getWorkDirTree();
          });
        })
        .catch((err) => {});
    },
    //添加用模式添加文件夹
    addFolderAplication(name, data) {
      if (name == "rename") {
        this.folderData = data;
      } else {
        this.folderData = {};
      }
      this.opName = name;
      this.addFolderDialogVisible = true;
    },
    chageIcon(iconStyle, title) {
      console.log(iconStyle, "获取的时间覅=====");
      this.iconStyle = iconStyle;
      this.folderTitle = title;
    },
    updateName(iconStyle, title) {
      this.iconStyle = iconStyle;
      this.folderTitle = title;
    },
    addFolderAplicationSubmit() {
      if (this.folderTitle == "" && !this.isHomepage) {
        this.$message.warning("请输入名称");
        return false;
      }
      const param = {};
      param.title = this.folderTitle;
      param.iconStyle = this.iconStyle;
      if (
        this.opName == "rename" ||
        (this.opName == "modifyIcon" && this.folderData.dataType == "folder")
      ) {
        param.dirId = this.folderData.id;
        modifyTitleAndIconDir(param).then((res) => {
          this.$message.success("修改成功!");
          this.getWorkDirTree();
        });
      }
      if (this.opName == "homepage") {
        this.isHomepage = false;
        param.dirId = this.folderData.id;
        param.title = "首页";
        modifyTitleAndIconDir(param).then((res) => {
          this.$message.success("修改成功!");
          this.getHomePageTreeData();
        });
      }
      if (this.opName == "add") {
        workDirRegister(param).then((res) => {
          this.$message.success("创建成功!");
          this.getWorkDirTree();
        });
      }
      if (
        this.opName == "modifyIcon" &&
        this.folderData.dataType !== "folder"
      ) {
        param.resId = this.folderData.id;
        modifyTitleAndIconDirRes(param).then((res) => {
          this.$message.success("修改成功!");
          this.getWorkDirTree();
        });
      }
      this.addFolderDialogVisible = false;
    },
    handleCLoseAddFolder() {
      this.isHomepage = false;
      this.addFolderDialogVisible = false;
    },
    // 添加工作群
    addWorkGroup(data, node) {
      let { title, id } = data;
      this.dirId = id;

      this.workGroupValue = "";
      this.currentUserMap = this.$store.state.user.currentUser;
      listRecordType().then((res) => {
        this.groupTypeList = res.data.list;
        this.addWorkGroupVisible = true;
      });
    },

    // 添加子文件夹
    addChildFolder(data, node) {
      this.$prompt("", "新建", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          workDirRegister({
            title: value,
            parentId: data.id,
          }).then((res) => {
            this.getWorkDirTree();
            this.$message.success("创建成功!");
          });
        })
        .catch((err) => {});
    },

    // 设置管理员
    setAdministrator(data, node) {
      this.recordId = data.recordId;
      if (this.$refs.formUserSelect) {
        this.$refs.formUserSelect.reset();
        this.$refs.formUserSelect.manualInit();
      }
    },
    // 重命名
    handleRename(data, node) {
      let { dataType, id, title } = data;
      if (dataType === "folder") {
        this.$prompt("", "重命名", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: title,
        })
          .then(({ value }) => {
            workDirRename({
              dirId: id,
              title: value,
            }).then((res) => {
              this.getWorkDirTree();
            });
          })
          .catch((err) => {});
      } else if (dataType === "resource") {
        this.$prompt("", "重命名", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: title,
        })
          .then(({ value }) => {
            renameRes({
              resId: id,
              title: value,
            }).then((res) => {
              this.getWorkDirTree();
            });
          })
          .catch((err) => {});
      }
    },
    //从导航中隐藏
    handleHidden(data, node) {
      let { id, dataType, hidden } = data;
      let companyId = this.$store.getters.currentCompanyId || 0;
      if (dataType === "folder") {
        let dirIds = [];
        dirIds = [id.toString()];
        setHidden({
          hidden: !hidden,
          dirIds: dirIds,
          companyId: companyId,
        }).then((res) => {
          this.getWorkDirTree();
        });
      } else if (dataType === "resource") {
        let resIds = [];
        resIds = [id.toString()];
        setHidden({
          hidden: !hidden,
          companyId: companyId,
          resIds: resIds,
        }).then((res) => {
          this.getWorkDirTree();
        });
      }
    },
    // 移动到
    handleMove(data, node) {
      this.currentFolderId = data.id;
      this.currenMoveNode = node;
      this.dataType = data.dataType;
      this.folderSelectDialogVisible = true;
    },

    // 删除
    handleDelete(data, node) {
      let { title, id, dataType, directoryList } = data;
      if (dataType === "folder") {
        if (directoryList && directoryList[0]) {
          this.$message.error("该文件夹存在子文件夹或工作群，不能删除");
        } else {
          this.$confirm(`是否删除${title}`, "删除", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              workDirDelete({
                dirId: id,
              }).then((res) => {
                this.getWorkDirTree();
                this.$message.success("删除成功！");
              });
            })
            .catch((err) => {});
        }
      } else if (dataType === "resource") {
        this.$confirm(`是否删除${title}`, "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            workDirDeleteRes({
              resId: id,
            }).then((res) => {
              this.getWorkDirTree();
              this.$message.success("删除成功！");
            });
          })
          .catch((err) => {});
      }
    },
    // 复选框
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleTabClick(tab, event) {
      console.log(tab, event);
    },

    //处理节点张开
    handleNodeExpand(data, node) {
      this.defaultExpandedKeys.push(data.id);
    },
    //处理节点收起
    handleNodeCollapse(data, node) {
      this.defaultExpandedKeys = this.defaultExpandedKeys.filter((id) => {
        return id !== data.id;
      });
    },
    // 获取抽屉设置
    loadSetting() {
      workDirLoadSetting({}).then((res) => {
        if (res.data && res.data.workDirSetting) {
          this.enabledNewLayout = res.data.workDirSetting.enabledNewLayout;
          this.enabledNewLayout1 = this.enabledNewLayout;
          this.getWorkDirTree();
        }
      });
    },
    // 是否切换新版布局
    saveSetting() {
      this.enabledNewLayout = this.enabledNewLayout1;
      workDirSaveSetting({
        enabledNewLayout: this.enabledNewLayout1,
      }).then((res) => {
        this.getWorkDirTree();
      });
      this.visibleSelect = false;
    },
    cancelSetting() {
      this.enabledNewLayout1 = this.enabledNewLayout;
      this.visibleSelect = false;
    },
    visibleChange(val, data, type) {
      this.showRight = data.id;
      let oldId = localStorage.getItem("old_id");
      let oldRight = document.querySelector(".right-" + oldId);
      if (oldId && oldRight) {
        oldRight.setAttribute("class", "content-right right-" + oldId);
      }

      let right = document.querySelector(".right-" + data.id);

      const childElsDots = right.querySelector(".icon-dots-vertical");
      const childElsPlus = right.querySelector(".el-icon-plus");

      if (val == true) {
        right.setAttribute("class", "right-" + data.id);
        if (type == "dots") {
          childElsDots.setAttribute(
            "class",
            "iconfont icon-dots-vertical custom-icon icon-active",
          );
          if (childElsPlus) {
            childElsPlus.setAttribute("class", "el-icon-plus custom-icon");
          }
        } else {
          childElsPlus.setAttribute(
            "class",
            "el-icon-plus custom-icon icon-active",
          );
          childElsDots.setAttribute(
            "class",
            "iconfont icon-dots-vertical custom-icon",
          );
        }
      }
      localStorage.setItem("old_id", data.id);
    },

    modifyIcon(data, node, name) {
      if (name == "homepage") {
        this.isHomepage = true;
      }
      console.log(data, "获取到什么====");
      this.opName = "modifyIcon";
      this.folderData = data;
      this.addFolderDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.templateConfig {
  height: 95%;
  margin: 0 auto;
  padding-top: 100px;
  max-width: 920px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .header-left {
    display: flex;
    align-items: center;

    & > span {
      color: #6b778c;
      position: relative;

      &::before {
        display: block;
        content: "";
        height: 15px;
        width: 1px;
        position: absolute;
        background-color: #6b778c;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    & > h1 {
      margin-right: 20px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
  }
}

.content {
  overflow: scroll;
  height: 90%;
  .content-label {
    font-size: 14px;
    color: #6b778c;
    line-height: 24px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

.node-button-manage {
  position: absolute;
  right: 20px;

  & > .el-link {
    margin: 0 5px;
  }
}

::v-deep .el-dialog {
  & > .el-dialog__header {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #d9d9d9;

    & > .el-dialog__headerbtn {
      top: 15px;
    }
  }

  & > .el-dialog__body {
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  & > .el-dialog__footer {
    border-top: 1px solid #d9d9d9;
    padding: 10px;
  }
}

.content-right {
  display: none;
}

.custom-tree-node:hover .content-right {
  display: block;
}

.node-label {
  ::v-deep .el-input__inner {
    height: 25px;
    line-height: 25px;
    position: absolute;
    padding-left: 5px;
    top: -18px;
    left: 0;
  }
}

.selectMember {
  position: relative;

  ::v-deep .el-input__inner {
    border-radius: 50px;
  }
}

.add-group-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

::v-deep .el-dialog > .el-dialog__body {
  min-height: 400px;
}

::v-deep .select-work-group {
  & ::v-deep .el-input__inner {
    cursor: pointer;
  }
  .work-group-selector {
    .el-select-dropdown {
      display: none;
    }
  }
  .el-tag__close {
    display: none;
  }
}
.name-avatar {
  background: #3870ea;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 32px;
  position: relative;
  z-index: 10;
}

.name-avatar-all {
  display: inline-block;
  font-size: 13px;
  background-color: #f2f6fc;
  padding: 5px 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: 25px;
}

.focusIpt {
  width: 560px !important;
  z-index: 10;
  transition: all 0.2s;

  &::after {
    display: block;
    content: "";
    height: 2px;
    position: absolute;
    background: #ebecf0;
    width: 100%;
    bottom: -10px;
    left: 0;
  }
}

.tab-container {
  height: 360px;
  display: flex;

  .node-label i {
    color: var(--zgg-brand-color-6);
  }

  .tab-container__group {
    width: 45%;
    border-right: 1px solid #e0e0e0;
    overflow: auto;
  }

  .tab-container__member {
    flex: 1;
  }
}

.alreadySelect {
  min-height: 60px;
  width: 100%;
  border-radius: 10px;
  border: 1px dotted #ddd;
  margin: 15px 0;
  box-sizing: border-box;
  padding: 10px 20px;
}

.select-member {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 0 15px;
  }
}

.my-plan-tree {
  flex: 1;
  width: 100%;
  line-height: 35px;

  ::v-deep {
    .el-tree-node__content {
      height: 100%;
      line-height: 35px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      border-radius: 8px;
    }
  }

  .node-label {
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    flex: 1;
    display: flex;
    align-content: center;
    align-items: center;

    .icon-container {
      width: 24px;
      height: 24px;
      margin-right: 4px;

      .image-icon {
        width: 100%;
        height: 100%;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px;
      }

      .icon-div {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
    }
    .resource-icon {
      width: 16px !important;
      height: 16px !important;
    }
    .resource-image-icon {
      border-radius: 3px !important;
    }
  }

  .custom-tree-node {
    line-height: 39px;
    flex: 1;

    &:hover .node-button-manage {
      display: inline-block;
    }
  }

  .node-button-manage {
    font-style: inherit;
    display: none;

    .el-button [class*="el-icon-"] + span {
      margin-left: 0;
    }
  }

  .el-form-item__content {
    line-height: normal !important;
  }

  .el-tree-node.is-drop-inner > .el-tree-node__content .custom-tree-node {
    background-color: var(--zgg-brand-color-6) !important;
    color: #fff !important;
    border-radius: 2px;
    transition: all 0.2s;
    padding-left: 10px;
  }

  .el-tree__drop-indicator {
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    /*background-color: red;*/
    transition: all 0.2s;
  }
}

.select-word-group-dialog {
  & ::v-deep .el-dialog__body {
    padding: 16px 24px;
  }
}

.folderSelectDialog {
  ::v-deep {
    .el-dialog {
      margin-top: 14% !important;
    }
    .el-dialog > .el-dialog__body {
      min-height: 80px;
    }
    .el-dialog > .el-dialog__header {
      border-bottom: 0px solid #d9d9d9;
    }
    .el-dialog > .el-dialog__footer {
      border-top: 0px solid #d9d9d9;
    }
  }
}

.select-mode {
  display: flex;
  flex-direction: row;
  padding: 8px;
  .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 180px;
      height: 120px;
      background: #f2f6fc;
      border: 1px solid #ebecf0;

      border-radius: 10px;
    }
    .actived {
      border: 1px solid #3870ea;
      box-shadow: 0px 0px 0px 2px rgba(56, 112, 234, 0.25);
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: inset 0px 1px 0px #f0f0f0;
  background-color: #ffffff;
  padding-top: 10px;
}

.workgroup-body {
  height: 488px;
  border: 1px solid #dfe1e6;
  display: flex;
  .left {
    position: relative;
    width: 316px;
    border-right: 1px solid #dfe1e6;
    display: flex;
    flex-direction: column;
    padding: 8px 6px;
    .workgroup-search {
      height: 28px;
      padding-left: 30px;
      border-radius: 61px;
      border: none;
      background-color: #f6f6f6;
      font-size: 13px;
      outline: none;
      margin-bottom: 5px;
      :focus {
        border: none;
        outline: none;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    width: 316px;
    padding: 8px 6px;
    .drag-field-item {
      display: flex;
      justify-content: space-between;
      margin: 0 0 13px 8px;

      .item-name {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }

      .item-operate {
        margin-right: 5px;
        user-select: none;
        i {
          &:hover {
            color: var(--blue-6);
          }
        }
      }
    }
  }
}
.recycle-btn {
  border-top: unset;
  width: 100px;
}
.icon-active {
  color: #348fe4 !important;
  text-decoration: none;
  background: var(--zgg-brand-color-2);
}
.enabledText {
  font-size: 13px;
  color: var(--zgg-font-text-secondary);
  line-height: 1px;
}
</style>
