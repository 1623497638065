export default {
    namespaced: true,
    state: {
        bulletinTagList: [],
    },
    mutations: {
        changeBulletinTag(state, tagTemplateList) {
            state.bulletinTagList = tagTemplateList
        }
    }

}
