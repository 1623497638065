<template>
  <div>
    <el-popover
      popper-class="pop-trigger"
      v-model="visible"
      width="260"
      trigger="click"
    >
      <div class="pop-scroll" v-clickoutside="handleClose">
        <template v-if="components && components.length">
          <div
            v-for="item in components"
            :key="item.key"
            v-text="item.title"
            class="form-item"
            :class="{ disabled: isDisabeld(item.name) }"
            @click="checkComponent(item)"
          ></div>
        </template>
        <div v-else class="pop-empty">表单没有组件</div>
      </div>
      <el-button @click.stop="" slot="reference" type="text" icon="el-icon-plus"
        >添加字段</el-button
      >
    </el-popover>
    <field-setting
      v-for="(item, index) in updateFieldValues"
      :key="item.key"
      :componentList="components2"
      :field="item"
      :triggerFormId="triggerFormId"
      type="edit"
      :index="index"
      :deleteField="deleteField"
      :areaTree="areaTree"
    ></field-setting>
  </div>
</template>
<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import fieldSetting from "./fieldSetting";
export default {
  directives: { Clickoutside },
  components: { fieldSetting },
  props: {
    componentList: Array,
    triggerComponents: Array,
    updateFieldValues: Array,
    filterFieldValues: Array,
    formId: String,
    triggerFormId: String,
    areaTree: Array,
  },
  computed: {
    components() {
      let parentName;
      let obj = this.filterFieldValues.find(
        (item) => item.tableField.fieldName&&item.tableField.fieldName.indexOf(".") >= 0
      );
      if (obj&&obj.tableField.fieldName) {
        parentName = obj.tableField.fieldName.split(".")[0];
      }
      if (parentName) {
        return this.componentList.filter(
          (item) =>
              (item.name&&item.name.indexOf(".") == -1) || (item.name&&item.name.indexOf(parentName) == 0)
        );
      } else {
        return this.componentList.filter(
          (item) => item.name&&(item.name.indexOf(".") == -1)
        );
      }
    },
    components2() {
      let parentName;
      let obj = this.filterFieldValues.find(
        (item) =>
          item.tableField.fieldName.indexOf(".") >= 0 &&
          item.triggerTableField &&
          item.triggerTableField.fieldName.indexOf(".") >= 0
      );
      if (obj) {
        parentName = obj.triggerTableField.fieldName.split(".")[0];
      }
      if (parentName) {
        let list = this.triggerComponents.filter(
          (item) =>
              (item.name&&item.name.indexOf(parentName) == 0) || (item.name&&item.name.indexOf(".") == -1)
        );
        return list;
      } else {
        return this.triggerComponents.filter(
          (item) => item.name&&(item.name.indexOf(".") == -1)
        );
      }
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    deleteField(index) {
      this.$delete(this.updateFieldValues, index);
    },
    isDisabeld(name) {
      return (
        this.updateFieldValues.findIndex(
          (item) => item.tableField.fieldName == name
        ) >= 0
      );
    },
    handleClose() {
      this.visible = false;
    },
    checkComponent(item) {
      if (this.isDisabeld(item.name)) {
        return;
      }

      let tableField = {
        collection: this.formId,
        fieldName: item.name,
        fieldTitle: item.title,
        componentName: item.componentName,
        optionSource: item.optionSource,
        customOptions: item.customOptions,
      };
      if (item.picker) {
        tableField.picker = item.picker;
      }
      if (item.extParam) {
        tableField.extParam = item.extParam;
      }

      //如果是关联数据，需要添加关联表单的表单id
      if (item.componentName=='reference_data'&&item.tableName) {
        tableField.referenceTableName = item.tableName;
      }

      this.updateFieldValues.push({
        key: this.getUuid(),
        valueType: "tableField",
        tableField: tableField,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
